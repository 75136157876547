import { useState } from 'react';
import { GoBookmark } from 'react-icons/go';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { estadosCadastro, removerElemento, copiarObjeto } from '../../../../../../../Common';

import {
  ButtonExcluirTable,
  ButtonNovo,
  Modal,
  If,
  ButtonEditarTable,
  NenhumRegistroEncontrado,
  Grid,
} from '../../../../../../../components';

import PessoaCnaeForm from './Form';
import { jaExisteMesmoCnae } from './Util/validacoes.js';

function PessoaCnae(props) {
  const { estadoCadastro, podeEditar, value, appendTo } = props;

  const [registroSelecionado, setRegistroSelecionado] = useState(null);
  const [modalFormVisible, setModalFormVisible] = useState(false);

  const desabilitarCampos = estadoCadastro === estadosCadastro.EDICAO && !podeEditar;

  function novo() {
    if (props.setHideBackground) {
      props.setHideBackground(true);
    }
    setRegistroSelecionado(null);
    setModalFormVisible(true);
  }

  function onEditar(row) {
    if (props.setHideBackground) {
      props.setHideBackground(true);
    }
    setRegistroSelecionado(row);
    setModalFormVisible(true);
  }

  function renderOpcoes(row) {
    return (
      <div>
        <ButtonEditarTable onClick={() => onEditar(row)} />
        <ButtonExcluirTable onClick={() => excluirRegistro(row)} podeExcluir={!desabilitarCampos} />
      </div>
    );
  }

  function hideModal() {
    if (props.setHideBackground) {
      props.setHideBackground(false);
    }

    setRegistroSelecionado(null);
    setModalFormVisible(false);
  }

  function atualizarRegistro(dadosFormulario) {
    const cnaes = copiarObjeto(props.value);
    const result =
      cnaes &&
      cnaes.map(item => {
        if (item.idTemporario || item.id) {
          if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
            return dadosFormulario;
          } else if (item.id && item.id === dadosFormulario.id) {
            return dadosFormulario;
          }

          if (dadosFormulario.favorito) {
            item.favorito = false;
          }

          return item;
        } else {
          return dadosFormulario;
        }
      });
    setRegistroSelecionado(dadosFormulario);
    props.onChange(result);
  }

  function inserirRegistro(dadosFormulario) {
    let cnaes = copiarObjeto(props.value);
    props.onChange(mergeCnaeIntoCnaes(dadosFormulario, cnaes));
    setRegistroSelecionado(dadosFormulario);
  }

  function mergeCnaeIntoCnaes(cnae, cnaes) {
    if (cnae.favorito) {
      cnaes.map(tempCnae => {
        return (tempCnae.favorito = false);
      });
    }
    return [...cnaes, cnae];
  }

  function excluirRegistro(dadosFormulario) {
    props.onChange(removerElemento(props.value, dadosFormulario));
    setRegistroSelecionado(null);
  }

  function validarMesmoCnae(cnae) {
    return jaExisteMesmoCnae(props.value, cnae);
  }

  function marcarComoFavorito() {
    const cnaes = copiarObjeto(props.value);
    let existemFavoritos = false;

    cnaes?.forEach(cnae => {
      if (cnae.favorito) {
        existemFavoritos = true;
      }
    });

    return !existemFavoritos;
  }

  function renderCnae(row) {
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {row.codigo}
        {row.favorito ? (
          <span>
            <GoBookmark size={22} color="#fbc02d" title="CNAE favorito" />
          </span>
        ) : null}
      </span>
    );
  }

  return (
    <>
      <Modal visible={modalFormVisible} header="Cadastro de CNAE" onHide={hideModal} container={appendTo}>
        <If test={modalFormVisible}>
          <PessoaCnaeForm
            registroSelecionado={registroSelecionado}
            onNovoClick={() => setRegistroSelecionado(null)}
            inserirRegistro={inserirRegistro}
            atualizarRegistro={atualizarRegistro}
            excluirRegistro={excluirRegistro}
            marcarComoFavorito={marcarComoFavorito}
            podeEditar={!desabilitarCampos}
            podeExcluir={!desabilitarCampos}
            onHide={hideModal}
            validarMesmoCnae={validarMesmoCnae}
          />
        </If>
      </Modal>
      <Grid style={{ padding: '0.5rem 0rem' }}>
        <ButtonNovo label="Novo CNAE" enableShortCut={false} onClick={novo} podeInserir={!desabilitarCampos} />
      </Grid>
      <DataTable
        className="table"
        responsive
        value={value}
        emptyMessage={<NenhumRegistroEncontrado message="Nenhum CNAE encontrado" />}
      >
        <Column field="codigo" body={renderCnae} header="CNAE" sortable={true} style={{ width: '12em' }} />
        <Column field="descricao" header="Descrição" sortable={true} />
        <Column body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
      </DataTable>
    </>
  );
}

export default PessoaCnae;
