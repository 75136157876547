import { useEffect, useState } from 'react';
import { withFormik, Field } from 'formik';
import { withRouter } from 'react-router';
import * as Yup from 'yup';

import {
  Button,
  Grid,
  ButtonNovo,
  estadosBotaoNovo,
  ButtonSalvar,
  estadosBotaoSalvar,
  ButtonCancelar,
  estadosBotaoCancelar,
  InputField,
  Prompt,
  ButtonExcluir,
  Form,
  FormActions,
  FormContent,
  Dropdown,
  Tutorial,
  tutorialStepsTecnico,
} from '../../../../components';

import {
  mensagensDeValidacao,
  recursos,
  permissoes,
  estadosCadastro,
  validarUUID,
  usuarioPossuiPermissao,
  keyFilterConsultaRsql,
  salvarConfiguracaoUsuario,
  configuracoesUsuario,
  buscarConfiguracaoUsuario,
  copiarObjeto,
} from '../../../../Common';

import {
  asyncDeleteTecnico,
  asyncUpdateTecnico,
  asyncGetTecnicoMesmoNome,
  asyncCreateTecnico,
  asyncGetTecnico,
} from '../Requests';
import { confirmarExclusao } from '../../../Util/ExclusaoDeRegistros';
import { atualizarUrl, metodosAtualizarUrl, voltarParaAPesquisa, validarFormulario } from '../../../Util';
import { helpTecnicoForm } from './Help';

const initialValues = {
  id: '',
  nome: '',
  situacao: 'ATIVO',
};

const sitacaoOptions = [
  { label: 'Ativo', value: 'ATIVO' },
  { label: 'Inativo', value: 'INATIVO' },
];

const CADASTROURL = '/tecnicos/cadastro';
const PESQUISAURL = '/tecnicos';

function TecnicoForm(props) {
  const { values, history, resetForm, dirty, isModal, setFieldValue } = props;
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const [deveExibirTutorial, setDeveExibirTutorial] = useState(
    buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_TECNICO)
  );
  const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.TECNICOS, permissoes.INSERIR));
  const [podeEditar, setPodeEditar] = useState(usuarioPossuiPermissao(recursos.TECNICOS, permissoes.EDITAR));
  const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.TECNICOS, permissoes.EXCLUIR));

  const informacoesPermissoes = {
    estadoCadastro: buscarEstadoCadastro(),
    podeInserir: podeInserir,
    podeEditar: podeEditar,
    podeExcluir: podeExcluir,
  };

  const estadoBotaoSalvar = !dirty && isModal && values.id ? estadosBotaoSalvar.CONFIRMAR : estadosBotaoSalvar.SALVAR;
  const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

  useEffect(() => {
    const id = props.match.params.id;

    if (deveExibirTutorial !== false) {
      setTutorialVisible(true);
      salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_TECNICO, false, null, false);
    }

    if (validarUUID(id) && !props.isModal) {
      asyncSelectRegistro(id);
    }

    setTimeout(() => {
      document.getElementById('TecnicosInputFieldNome')?.focus();
    }, 500);
  }, []);

  function onClickNovo() {
    dirty ? salvar(novo) : novo();
  }

  function buscarEstadoCadastro() {
    return values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
  }

  async function novo() {
    atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
    await resetForm({ values: initialValues });
  }

  function excluir() {
    confirmarExclusao(asyncDeleteRegistro);
  }

  async function salvar(novoOnSuccess) {
    props.handleSubmit();

    if (await validarFormulario(props)) {
      if (await validarNome()) {
        let dadosFormulario = values;
        if (values.id) {
          asyncUpdateRegistro(dadosFormulario, novoOnSuccess);
        } else {
          asyncCreateRegistro(dadosFormulario, novoOnSuccess);
        }
      } else {
        props.setFieldError('nome', 'O nome informado já existe');
      }
    }
  }

  async function validarNome() {
    let nomeJaExiste = false;
    await asyncGetTecnicoMesmoNome(values.nome, e => {
      if (e.data.content.length > 0 && e.data.content[0].id !== values.id) {
        nomeJaExiste = true;
      }
    });
    return !nomeJaExiste;
  }

  function cancelar() {
    if (dirty) {
      resetForm({ values: initialValues });
    } else if (isModal) {
      fecharModal();
    } else {
      voltarParaAPesquisa(props.history, PESQUISAURL);
    }
  }

  function fecharModal(dados = null) {
    if (dados) {
      props.hideModal(dados);
    } else {
      props.hideModal();
    }
  }

  async function asyncDeleteRegistro() {
    await asyncDeleteTecnico(values.id, () => {
      props.resetForm();
      voltarParaAPesquisa(props.history, PESQUISAURL);
    });
  }

  async function asyncUpdateRegistro(dadosFormulario, novoOnSuccess) {
    await asyncUpdateTecnico(dadosFormulario, () => {
      if (novoOnSuccess) {
        novoOnSuccess();
      } else {
        props.resetForm({ values: values });
      }
    });
  }

  async function asyncCreateRegistro(dadosFormulario, novoOnSuccess) {
    await asyncCreateTecnico(dadosFormulario, ({ data: tecnico }) => {
      if (novoOnSuccess) {
        novoOnSuccess();
      } else {
        props.resetForm({ values: { ...values, id: tecnico.id } });
        if (!isModal) {
          atualizarUrl(props.history, CADASTROURL, tecnico.id, metodosAtualizarUrl.POP);
        } else {
          fecharModal({ ...values, id: tecnico.id });
        }
      }
    });
  }

  async function asyncSelectRegistro(idTecnico) {
    await asyncGetTecnico(
      idTecnico,
      ({ data: tecnico }) => {
        props.resetForm({ values: tecnico });

        if (!props.isModal) {
          atualizarUrl(props.history, CADASTROURL, tecnico.id, metodosAtualizarUrl.POP);
        }
      },
      () => {
        novo();
      }
    );
  }

  return (
    <>
      <Prompt dirty={dirty} isModal={isModal} />
      <Tutorial
        steps={tutorialStepsTecnico}
        showSkipButton
        continuous
        visible={tutorialVisible}
        onHide={() => setTutorialVisible(false)}
      />
      <Form className="card-default screen-max-width" header="Cadastro de técnico" isModal={isModal}>
        <FormActions className="screen-max-width">
          <ButtonCancelar
            {...informacoesPermissoes}
            hidden={isModal && values.id != '' && !dirty}
            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
            onClick={() => cancelar()}
          />
          <ButtonSalvar
            {...informacoesPermissoes}
            estadoBotao={estadoBotaoSalvar}
            disabled={!dirty && estadoBotaoSalvar === estadosBotaoSalvar.SALVAR}
            onClick={() => salvar()}
          />
          <ButtonNovo
            {...informacoesPermissoes}
            estadoBotao={estadoBotaoNovo}
            hidden={(!dirty && !values.id) || isModal}
            onClick={() => onClickNovo()}
          />
          <ButtonExcluir hidden={!values.id || isModal} onClick={excluir} {...informacoesPermissoes} />
          <Button
            className="p-button-secondary"
            type="button"
            label="Opções"
            icon="fa fa-list"
            hidden={true}
            onClick={e => menuOpcoes.toggle(e)}
          />
        </FormActions>
        <FormContent>
          <Grid>
            <Field
              sm="12"
              md="9"
              lg="9"
              xl="9"
              component={InputField}
              label="Nome"
              obrigatorio
              keyfilter={keyFilterConsultaRsql}
              name="nome"
              helpMessage={helpTecnicoForm.nome}
              size={60}
              id="TecnicosInputFieldNome"
              {...informacoesPermissoes}
            />
            <Field
              sm="12"
              md="3"
              lg="3"
              xl="3"
              component={Dropdown}
              label="Situação"
              name="situacao"
              helpMessage={helpTecnicoForm.situacao}
              onChange={event => setFieldValue('situacao', event.value)}
              options={sitacaoOptions}
              obrigatorio
              showClear={false}
              {...informacoesPermissoes}
            />
          </Grid>
        </FormContent>
      </Form>
    </>
  );
}

TecnicoForm = withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,

  mapPropsToValues() {
    return initialValues;
  },

  validationSchema: Yup.object().shape({
    nome: Yup.string()
      .nullable()
      .required(mensagensDeValidacao.OBRIGATORIO)
      .max(60, 'O campo não pode ter mais de 60 caracteres.'),
  }),

  handleSubmit: () => {},
})(TecnicoForm);

export default withRouter(TecnicoForm);
