import { Modal } from '../../../../../../components';
import FormVinculo from './FormVinculo';

function ModalVinculoFornecedor(props) {
	const { onHide, visible, isMobile, isFinalizado, itens } = props;

	return (
		<>
			<Modal
				showCloseIcon={false}
				header="Vínculo produtos com fornecedor"
				onHide={onHide}
				visible={visible}
				styleModal={{
					minWidth: '60%',
					height: isMobile ? '85%' : 'auto',
				}}
				isMobile={isMobile}
			>
				<FormVinculo {...props} isFinalizado={isFinalizado} itens={itens} />
			</Modal>
		</>
	);
}

export default ModalVinculoFornecedor;
