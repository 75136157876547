import { get, post, services } from '../../../../Common';

export async function asyncConsultaUltimoSped(onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/sped/filial_logada`, null, onSuccess, onError);
}

export async function asyncPostGerarSped(data, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/sped/gerar`, data, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function asyncPostGerarSpedEEnviarPorEmail(data, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/sped/gerar/enviar_email`, data, null, onSuccess, onError);
}

export async function asyncGetFilialConectada(onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/filiais/conectada`, null, onSuccess, onError);
}
