import { useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { Form, FormActions, FormContent, Prompt, Tutorial, tutorialStepsVeiculo } from 'components';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	copiarObjeto,
	estadosCadastro,
	mensagensDeValidacao,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';

import { atualizarUrl, metodosAtualizarUrl } from '../../../../Util';
import { readVeiculo } from '../Requests';
import { CADASTRO_URL, INITIAL_VALUES } from '../Util/constantes';
import { converterVeiculoParaFormulario } from '../Util/transporteVeiculoConverter';
import { ActionButtons } from './components/ActionButtons';
import { FormProprietario } from './components/FormProprietario';
import { FormVeiculos } from './components/FormVeiculos';

function VeiculosFormImpl({ history, match, isModal, hideModal }) {
	const { values, dirty, resetForm } = useFormikContext();
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_VEICULO);
	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EXCLUIR);

	const informacoesPermissoes = {
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		const id = match?.params?.id;

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_VEICULO, false, null, false);
		}

		if (validarUUID(id) && !isModal) {
			fetchRegistro(id);
		}

		setTimeout(() => {
			document.getElementById('input-placa')?.focus();
		}, 500);
	}, []);

	async function fetchRegistro(id) {
		await readVeiculo(id, ({ data: veiculo }) => {
			resetForm({ values: converterVeiculoParaFormulario(veiculo) });

			if (!isModal) {
				atualizarUrl(history, CADASTRO_URL, veiculo.id, metodosAtualizarUrl.POP);
			}
		});
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsVeiculo}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form className="card-default screen-max-width" header="Cadastro de veículos" isModal={isModal}>
				<FormActions className="screen-max-width">
					<ActionButtons
						informacoesPermissoes={informacoesPermissoes}
						isModal={isModal}
						hideModal={hideModal}
						history={history}
					/>
				</FormActions>
				<FormContent>
					<FormVeiculos informacoesPermissoes={informacoesPermissoes} isModal={isModal} />
					<FormProprietario informacoesPermissoes={informacoesPermissoes} />
				</FormContent>
			</Form>
		</>
	);
}

const VeiculosForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props?.tipo) {
			return { ...copiarObjeto(INITIAL_VALUES), tipo: props.tipo };
		} else {
			return INITIAL_VALUES;
		}
	},

	validationSchema: Yup.object().shape({
		placa: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		renavam: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.min(9, 'Tamanho mínimo de 9 dígitos')
			.max(11, 'Tamanho máximo de 11 dígitos'),
		uf: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		situacao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		tara: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO).min(1, 'Valor mínimo é 1'),
		capacidade: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		rodado: Yup.string()
			.nullable()
			.when('tipo', {
				is: (e) => e === 'TRACAO',
				then: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
			}),
		carroceria: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(VeiculosFormImpl);

export { VeiculosForm };
