import { useCallback, useEffect, useRef, useState } from 'react';
import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import { format } from 'date-fns';
import { ColorsCard, OperacaoStatus, optionsFiltroAvancado, TipoManifestacao } from './Util/constantes';

import {
	removerCaracteres,
	configuracoesUsuario,
	buscarConfiguracaoUsuario,
	construirUrl,
	permissoes,
	recursos,
	usuarioPossuiPermissao,
	services,
	useStateCallback,
	salvarConfiguracaoUsuario,
} from '../../../Common';

import {
	Button,
	DateInterval,
	DescricaoFiltroAvancado,
	Grid,
	Col,
	PesquisaAvancada,
	InputSearch,
	Form,
	FormActions,
	FormContent,
	Tutorial,
	InputMask,
	notify,
	ToastTypes,
} from '../../../components';

import CardTotalizadorListagem from './components/CardTotalizadorListagem';
import TabelaNotas from './components/TabelaNotas';

import {
	asyncGetConsultaSefaz,
	asyncGetConsultaSefazChaveAcesso,
	asyncGetRegistros,
	asyncGetTotalizadores,
} from './Requests';
import { Icon } from '@iconify/react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { tutorialStepsMde } from '../../../components/Tutorial/steps/Mde';
import { connect } from 'react-redux';

function currentMonth() {
	let date = new Date();
	let firstDay = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
	let lastDay = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
	let formatedDate = `dataEmissao>=${firstDay};dataEmissao<=${lastDay}`;

	return formatedDate;
}

const defaultRecord = [
	{
		chaveAcesso: '12345678901234567890123456789012345678901234',
		numero: '80',
		serie: '921',
		emitenteCpfCnpj: '12345678901234',
		emitenteNome: 'Emitente da NF-e',
		dataEmissao: format(new Date(), 'yyyy-MM-dd'),
		total: 100,
		tipoOperacao: 'SAIDA',
		dataHoraAutorizacao: format(new Date(), 'yyyy-MM-dd'),
		situacaoNfe: 'TRANSMITIDA',
		manifestacoes: [
			{
				id: 'd982737c-2e9d-4a82-8524-0511a80ea799',
				sequencial: 1,
				tipo: 'CIENCIA_DA_OPERACAO',
			},
		],
	},
];

function ManifestacaoDestinatario(props) {
	const { isMobile } = props;

	const [registros, setRegistros] = useState([]);
	const [filtroData, setFiltroData] = useState('');
	const [valorPesquisa, setValorPesquisa] = useStateCallback('');
	const [filtroAvancado, setFiltroAvancado] = useState('');
	const [descricaoFiltroAvancado, setDescricaoFiltroAvancado] = useState('');
	const [cardTotalSelected] = useState('');
	const [totalElements, setTotalElements] = useState(0);
	const [rows, setRows] = useState(10);
	const [page, setPage] = useState(0);
	const [sortOrder] = useState(-1);
	const [sortField] = useState('dataEmissao');
	const [interval, setInterval] = useState({
		dataInicial: startOfMonth(new Date()),
		dataFinal: endOfMonth(new Date()),
	});
	const [cards, setCards] = useState([]);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.COMPRAS_MANIFESTACAO, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.COMPRAS_MANIFESTACAO, permissoes.EDITAR));
	const [podeInserirNotaEntrada] = useState(usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.INSERIR));
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [useDefaultRecord, setUseDefaultRecord] = useState(false);
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_MDE));
	const [chaveAcessoNfe, setChaveAcessoNfe] = useState(null);
	const [selectedCard, setSelectedCard] = useState('');
	const [valorCard, setValorCard] = useState('');

	const panelDetalhes = useRef(null);

	const pesquisarCallback = useCallback(() => {
		pesquisar();
	});

	useEffect(() => {
		if (deveExibirTutorial !== false) {
			if (registros?.length === 0) {
				setTimeout(() => {
					setRegistros(defaultRecord);
					setUseDefaultRecord(true);
				}, 1000);
			}
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_MDE, false, null, false);
		}
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [interval, cardTotalSelected, filtroAvancado, rows, page, selectedCard]);

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function handleChangeInterval(interval) {
		setPage(0), setInterval(interval);
		setFiltroData(
			`dataEmissao>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};dataEmissao<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	async function pesquisar() {
		const filtro = buscarFiltro();

		const url = construirUrl(
			`${services.GESTOR}/v1/mde/resumo`,
			filtro || '?',
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		asyncGetTotalizadores(interval, ({ data: totais }) => {
			let localCards = {};

			totais &&
				totais.map((item) => {
					if (item.situacao === TipoManifestacao.CIENCIA_DA_OPERACAO) {
						localCards = {
							...localCards,
							cienciaDaOperacao: {
								...item,
							},
						};
					} else if (item.situacao === TipoManifestacao.OPERACAO_NAO_REALIZADA) {
						localCards = {
							...localCards,
							operacaoNaoRealizada: {
								...item,
							},
						};
					} else if (item.situacao === TipoManifestacao.DESCONHECIMENTO_DA_OPERACAO) {
						localCards = {
							...localCards,
							desconhecimentoDaOperacao: {
								...item,
							},
						};
					} else if (item.situacao === TipoManifestacao.CONFIRMACAO_DA_OPERACAO) {
						localCards = {
							...localCards,
							confirmacaoDaOperacao: {
								...item,
							},
						};
					} else if (item.situacao === TipoManifestacao.SEM_MANIFESTACAO) {
						localCards = {
							...localCards,
							semManifestacao: {
								...item,
							},
						};
					}
				});
			setCards(localCards);
		});

		asyncGetRegistros(url, ({ data: notas }) => {
			setRegistros(notas.content);
			setTotalElements(notas.totalElements);
		});
	}

	function onPesquisar() {
		setPage(0);
		pesquisar();
	}

	function buscarFiltro() {
		const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.', '-', '/']);

		let filtroRSQL = String('?query=(')
			.concat(`serie=contains="*${pesquisaCodigo.replaceAll('&', '')}*",`)
			.concat(`numero=contains="*${pesquisaCodigo.replaceAll('&', '')}*",`)
			.concat(`situacaoNfe=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`chaveAcesso=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`emitenteNome=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroData) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroData})`);
		} else {
			filtroRSQL = filtroRSQL.concat(`;(${currentMonth()})`);
		}

		if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}

		if (valorCard) {
			filtroRSQL = filtroRSQL.concat(`;(${valorCard})`);
		}

		return filtroRSQL;
	}

	async function consultaSefaz() {
		await asyncGetConsultaSefaz(
			() => {
				pesquisar();
			},
			({ response }) => {
				if (response.data) {
					response.data?.details[0]?.startsWith('SEFAZ:') || response.data?.details[0]?.startsWith('Status:')
						? notify(response.data.details[0], ToastTypes.WARNING, 10)
						: notify(response.data.details[0], ToastTypes.ERROR, 10);
				}
			}
		);
	}

	function openConsultaPorChaveAcesso(e) {
		panelDetalhes.current.toggle(e);
		setTimeout(() => {
			document.getElementById('input-chave-acesso-mde')?.focus();
		}, 250);
	}

	async function consultaPorChaveAcesso(chaveAcesso) {
		if (chaveAcesso?.length === 44) {
			await asyncGetConsultaSefazChaveAcesso(
				chaveAcesso,
				async () => {
					await pesquisar();
					document.getElementsByClassName('p-overlaypanel-close p-link')[0]?.click();
					setChaveAcessoNfe(null);
				},
				({ response }) => {
					response.data?.details[0]?.startsWith('SEFAZ:') || response.data?.details[0]?.startsWith('Status:')
						? notify(response.data.details[0], ToastTypes.WARNING, 10)
						: notify(response.data.details[0], ToastTypes.ERROR, 10);
				}
			);
		} else {
			notify('Chave de acesso inválida', ToastTypes.ERROR, 10);
		}
	}

	function handleSelectCardSemManifestacao(){
		if (selectedCard === OperacaoStatus.SEMMANIFESTACAO) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(OperacaoStatus.SEMMANIFESTACAO);
			setValorCard(`situacao==SEM_MANIFESTACAO`);
		}	
	}

	function handleSelectCardCienciaOperacao(){
		if (selectedCard === OperacaoStatus.CIENCIA) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(OperacaoStatus.CIENCIA);
			setValorCard(`situacao==CIENCIA_DA_OPERACAO`);
		}	
	}

	function handleSelectCardCorfirmadaOperacao(){
		if (selectedCard === OperacaoStatus.CONFIRMADA) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(OperacaoStatus.CONFIRMADA);
			setValorCard(`situacao==CONFIRMACAO_DA_OPERACAO`);
		}	
	}
 
	function handleSelectCardDesconhecimentoOperacao(){
		if (selectedCard === OperacaoStatus.DESCONHECIMENTO) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(OperacaoStatus.DESCONHECIMENTO);
			setValorCard(`situacao==DESCONHECIMENTO_DA_OPERACAO`);
		}	
	}

	function handleSelectCardOperacaoNaoRealizada(){
		if (selectedCard === OperacaoStatus.NAOREALIZADA) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(OperacaoStatus.NAOREALIZADA);
			setValorCard(`situacao==OPERACAO_NAO_REALIZADA`);
		}	
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsMde}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => {
					setTutorialVisible(false);
					if (useDefaultRecord) {
						setRegistros([]);
						setUseDefaultRecord(false);
					}
				}}
			/>
			<Form header={isMobile ? 'Manifestação do destinatário' : 'Manifestação do destinatário (MD-e)'}>
				<FormActions style={isMobile ? { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' } : null}>
					<Button
						className="step-busca-sefaz"
						icon={<Icon icon="charm:refresh" style={{ marginTop: '0.08rem', marginRight: '0.2rem' }} />}
						style={{ boxShadow: 'none', paddingRight: '1rem' }}
						label="Buscar documentos da SEFAZ"
						disabled={!podeInserir}
						onClick={() => consultaSefaz()}
					/>
					<Button
						className="step-consulta-chave-acesso"
						icon={
							<Icon
								icon="ant-design:file-search-outlined"
								style={{ marginTop: '0.08rem', marginRight: '0.2rem', fontSize: '1.2rem' }}
							/>
						}
						style={{ boxShadow: 'none' }}
						label="Consulta pela chave de acesso"
						disabled={!podeInserir}
						onClick={(e) => openConsultaPorChaveAcesso(e)}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter style={{ marginBottom: '0.8rem' }}>
						<DateInterval onChange={(e) => handleChangeInterval(e)} interval={interval} />
						<InputSearch
							value={valorPesquisa}
							onPesquisar={() => onPesquisar()}
							onChange={(value) => setValorPesquisa(value)}
							removerEComercial={false}
							className="step-listagem-input-search"
							id="mde-input-search"
						/>
						<Col
							sm="12"
							md="4"
							lg="3"
							xl="3"
							className="step-listagem-filtro-avancado"
							style={{ width: 'fit-content' }}
						>
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(descricao) => setDescricaoFiltroAvancado(descricao)}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} style={{ marginBottom: '0.5rem' }} />
					<Grid justifyCenter>
						<Col xs="12" sm="6" md="6" lg="2" xl="2">
							<CardTotalizadorListagem
								name="semManifestacao"
								title="Sem manifestação"
								numberOfElements={cards.semManifestacao ? cards.semManifestacao.quantidade : 0}
								colors={ColorsCard.SEM_MANIFESTACAO}
								showValue={false}
								selectable
								selected={selectedCard === OperacaoStatus.SEMMANIFESTACAO}								
								onSelect={handleSelectCardSemManifestacao}								
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="2" xl="2">
							<CardTotalizadorListagem
								name="cienciaDaOperacao"
								title="Ciência da operação"
								numberOfElements={cards.cienciaDaOperacao ? cards.cienciaDaOperacao.quantidade : 0}
								colors={ColorsCard.CIENCIA_OPERACAO}
								showValue={false}
								selectable
								selected={selectedCard === OperacaoStatus.CIENCIA}								
								onSelect={handleSelectCardCienciaOperacao}								
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="2" xl="2">
							<CardTotalizadorListagem
								name="confirmadaOperacao"
								title="Confirmada a operação"
								numberOfElements={cards.confirmacaoDaOperacao ? cards.confirmacaoDaOperacao.quantidade : 0}
								colors={ColorsCard.CONFIRMACAO_OPERACAO}
								showValue={false}
								selectable
								selected={selectedCard === OperacaoStatus.CONFIRMADA}								
								onSelect={handleSelectCardCorfirmadaOperacao}								
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="2" xl="2">
							<CardTotalizadorListagem
								name="desconhecimentoDaOperacao"
								title="Desconhecimento da operação"
								numberOfElements={cards.desconhecimentoDaOperacao ? cards.desconhecimentoDaOperacao.quantidade : 0}
								colors={ColorsCard.DESCONHECIMENTO_OPERACAO}
								showValue={false}
								selectable
								selected={selectedCard === OperacaoStatus.DESCONHECIMENTO}								
								onSelect={handleSelectCardDesconhecimentoOperacao}								
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="2" xl="2">
							<CardTotalizadorListagem
								name="operacaoNaoRealizada"
								title="Operação não realizada"
								numberOfElements={cards.operacaoNaoRealizada ? cards.operacaoNaoRealizada.quantidade : 0}
								colors={ColorsCard.OPERACAO_NAO_REALIZADA}
								showValue={false}
								selectable
								selected={selectedCard === OperacaoStatus.NAOREALIZADA}								
								onSelect={handleSelectCardOperacaoNaoRealizada}								
							/>
						</Col>
					</Grid>
					<TabelaNotas
						sortField={sortField}
						sortOrder={sortOrder}
						registros={registros}
						setRegistros={setRegistros}
						totalElements={totalElements}
						setTotalElements={setTotalElements}
						rows={rows}
						setRows={setRows}
						page={page}
						setPage={setPage}
						history={props.history}
						podeEditar={podeEditar}
						podeInserirNotaEntrada={podeInserirNotaEntrada}
						pesquisar={pesquisar}
					/>
				</FormContent>
			</Form>
			<OverlayPanel
				ref={panelDetalhes}
				style={{
					width: isMobile ? '19rem' : '30.5rem',
					paddingRight: isMobile ? '2rem' : '3rem',
					marginTop: '0px',
				}}
				onHide={() => setChaveAcessoNfe(null)}
				showCloseIcon
			>
				<div className="p-inputgroup">
					<InputMask
						id="input-chave-acesso-mde"
						label="Chave de acesso"
						name="chaveAcessoNfe"
						value={chaveAcessoNfe}
						onChange={(event) => setChaveAcessoNfe(event.target.value)}
						mask="0000 0000 0000 0000 0000 0000 0000 0000 0000 0000 0000"
						style={{
							width: '100%',
							borderRadius: '3px 0px 0px 3px',
						}}
						colStyle={{
							paddingRight: '0px',
						}}
					/>
					<Button
						type="button"
						icon="fa fa-search"
						title="Consultar"
						onClick={() => consultaPorChaveAcesso(chaveAcessoNfe)}
						style={{
							height: '32px',
							marginTop: '1.83rem',
							borderRadius: '0px 3px 3px 0px',
							boxShadow: 'none',
						}}
					/>
				</div>
			</OverlayPanel>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(ManifestacaoDestinatario);
