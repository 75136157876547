import { gerarUUID } from 'Common';
import { setNullForIdProperties } from 'Common/Array';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	If,
	NormalButton,
} from 'components';
import ModalHistorico from 'components/ModalHistorico';
import { NOVO_SERVICO } from 'components/Servicos/Utils';
import { formatISO } from 'date-fns';
import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { useContextNFSe } from '../../../Context';
import {
	asyncCreateNfse,
	asyncDeleteNfse,
	asyncGetNfse,
	asyncPostCancelarNFSe,
	asyncTransmitirNFse,
	asyncUpdateNfse,
} from '../../../Requests';
import { Situacao } from '../../../Util/constantes';
import { novaNotaServico, Operacao } from '../../Util/constantes';
import { montarMensagemToast } from '../../Util/functions';
import { converterToApi, converterToForm } from '../../Util/NFSeConverter';
import { ModalCancelamento } from '../ModalCancelamento';

export default function ActionButtons(props) {
	const { values, dirty, resetForm, handleReset, dadosLocais, setValues, validateForm } = useFormikContext();
	const { setExibirLoadingTransmissao, errosTransmissao, setErrosTransmissao } = useContextNFSe();
	const {
		informacoesPermissoes,
		dadosBase,
		existeParcelaRecebida,
		history,
		desabilitarBotaoTransmitir,
		asyncSelectRegistro,
		resetFormWithFavoritos,
	} = props;

	const [visibleModalHistorico, setVisibleModalHistorico] = useState(false);
	const [visibleModalCancelamento, setVisibleModalCancelamento] = useState(false);
	const isCancelado = Boolean(values.situacao === Situacao.CANCELADO);
	const isFinalizado = Boolean(values.situacao === Situacao.TRANSMITIDO);

	const menuOpcoes = useRef(null);
	const itensOpcoes = getItensOpcoes();

	function getItensOpcoes() {
		const itens = [];

		itens.push({
			label: 'Duplicar',
			icon: 'fa fa-copy',
			command: () => duplicarNFSe(values.id),
			visible: !dirty && values.id,
		});

		itens.push({
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setVisibleModalHistorico(true),
			visible: true,
		});

		itens.push({
			separator: true,
			visible: isFinalizado,
		});

		itens.push({
			label: isCancelado ? 'Motivo do cancelamento' : 'Cancelar NFS-e',
			icon: isCancelado ? 'pi pi-file-excel' : 'fa fa-times',
			command: () => setVisibleModalCancelamento(true),
			visible: isFinalizado,
		});

		return itens.filter((element) => element.visible);
	}

	async function duplicarNFSe(id) {
		await asyncGetNfse(id, async (nota) => {
			atualizarUrl(history, '/nfses/cadastro', null, metodosAtualizarUrl.POP);

			let convertedData = converterToForm(nota.data);
			addEmptyServico(convertedData);
			convertedData = clearOrdemServico(convertedData);

			setValues(convertedData);
		});
	}

	function clearOrdemServico(values) {
		setNullForIdProperties(values, ['registro', 'tomador', 'municipio', 'setor', 'vendedor']);
		return {
			...values,
			numero: null,
			situacao: Situacao.PENDENTE,
			dataEmissao: formatISO(new Date()),
			dataFechamento: null,
			horaFechamento: null,
			informacoesComplementares: {
				...values.informacoesComplementares,
				numeroRPS: null,
				loteRPS: null,
				protocolo: null,
			},
		};
	}

	function addEmptyServico(convertedData) {
		function addEmptyServico() {
			convertedData.servicos.push({
				...NOVO_SERVICO,
				item: convertedData.servicos.length + 1,
				operacaoFiscal: convertedData.operacaoFiscal ?? null,
			});
		}

		if (convertedData.situacao === Situacao.PENDENTE) {
			addEmptyServico();
		} else if (convertedData.servicos.length === 0) {
			addEmptyServico();
		}
	}

	async function onCancelarNFSe(motivoCancelamento) {
		await asyncPostCancelarNFSe(values.id, motivoCancelamento, () => {
			const newValues = {
				...values,
				situacao: Situacao.CANCELADO,
				motivoCancelamento,
			};

			resetForm({ values: newValues });
		});
	}

	function cancelar() {
		if (dirty) {
			handleReset();
		} else {
			voltarParaAPesquisa(history, '/nfses');
		}
	}

	async function salvar(novoOnSuccess) {
		const valuesNfse = { ...values };
		if (valuesNfse.servicos?.length > 0) {
			valuesNfse.servicos = valuesNfse.servicos.filter((servico) => servico.servico);

			if (valuesNfse.servicos?.length === 0) {
				valuesNfse.servicos = [{ ...NOVO_SERVICO, id: gerarUUID() }];
			}

			for (let i = 0; i < valuesNfse.servicos.length; i++) {
				valuesNfse.servicos[i].item = i + 1;
			}
		}

		const comissaoServico = calcularComissaoNfse(valuesNfse);
		valuesNfse.comissaoTotalPercentual = comissaoServico.percentual;
		valuesNfse.comissaoTotalValor = comissaoServico.valor;

		setValues(valuesNfse);

		if (await validarFormulario({ validateForm, values: valuesNfse })) {
			if (values.id) {
				updateNFSe({
					...values,
					novoOnSuccess,
				});
			} else {
				createNFSe({ ...values }, novoOnSuccess);
			}
		}
	}

	function getTitleButtonTransmitir() {
		if (dadosLocais?.filialConectada?.parametrosFiscalNFse) {
			if (!dadosLocais?.filialConectada?.parametrosFiscalCertificado) {
				return 'Filial sem certificado digital configurado para transmissão de NF-e';
			} else if (!dadosLocais?.filialConectada?.parametrosFiscalNFe.serieNfse) {
				return 'Série da nota fiscal não configurada';
			}
		}
		return '';
	}

	function excluir() {
		const mensagem = montarMensagemToast(dadosBase.status, Operacao.EXCLUIR);
		confirm('Atenção', 'Tem certeza que deseja excluir?', async () => {
			await asyncDeleteNfse(values.id, mensagem, () => {
				voltarParaAPesquisa(props.history, '/nfses');
			});
		});
	}

	function onClickNovo() {
		if (dirty) {
			salvar(newNfse);
		} else {
			newNfse();
		}
	}

	function newNfse() {
		atualizarUrl(history, '/nfses/cadastro', null, metodosAtualizarUrl.POP);
		resetForm({ values: novaNotaServico });
		resetFormWithFavoritos(novaNotaServico);
	}

	async function updateNFSe(values, novoOnSuccess) {
		if (values) {
			const mensagem = montarMensagemToast(props.dadosBase.status, Operacao.EDITAR);

			await asyncUpdateNfse(converterToApi(values), mensagem, ({ data: nfse }) => {
				if (typeof novoOnSuccess === 'function') {
					novoOnSuccess();
				} else {
					resetForm({
						values: {
							...props.values,
							id: nfse.id,
						},
					});
					resetForm(values);
				}
			});
		}
	}

	async function createNFSe(values, novoOnSuccess) {
		if (values) {
			const mensagem = montarMensagemToast(dadosBase.status, Operacao.INSERIR);

			await asyncCreateNfse(converterToApi(values), mensagem, ({ data: nfse }) => {
				if (typeof novoOnSuccess === 'function') {
					novoOnSuccess();
				} else {
					atualizarUrl(history, `/nfses/cadastro`, nfse.id, metodosAtualizarUrl.POP);
					resetForm({
						values: {
							...values,
							id: nfse.id,
						},
					});
					resetForm(values);
				}
			});
		}
	}

	async function transmitirNfse() {
		setExibirLoadingTransmissao(true);
		await asyncTransmitirNFse(
			values.id,
			async () => {
				setErrosTransmissao({});
				setExibirLoadingTransmissao(false);
				await asyncSelectRegistro(values.id);
			},
			async (error) => {
				const errorData = error.response.data;
				setExibirLoadingTransmissao(false);
				await asyncSelectRegistro(values.id);
				if (errorData.details) {
					errorData.details.forEach((error) => {
						setErrosTransmissao({ ...errosTransmissao, error });
					});
				}
			}
		);
	}

	function calcularComissaoNfse(nfse) {
		const comissao = { percentual: 0, valor: 0 };
		if (nfse.servicos?.length > 0) {
			const percentualTotal =
				nfse.servicos.reduce((total, item) => total + item.comissaoPercentual || 0, 0) / nfse.servicos.length;

			comissao.percentual = parseFloat(percentualTotal.toFixed(2));
			comissao.valor = parseFloat((nfse.totalizadores.valorLiquido * (percentualTotal / 100)).toFixed(2));
		}

		return comissao;
	}

	return (
		<>
			<Menu model={itensOpcoes} popup ref={menuOpcoes} />
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar disabled={!dirty || existeParcelaRecebida} onClick={salvar} {...informacoesPermissoes} />
			<ButtonNovo
				onClick={onClickNovo}
				hidden={!dirty && !values.id}
				estadoBotao={dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir hidden={!values.id} onClick={excluir} {...informacoesPermissoes} />
			<NormalButton
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={dirty || !values.id}
				onClick={(event) => menuOpcoes.current.toggle(event)}
				{...informacoesPermissoes}
			/>
			<Button
				className="p-button-success"
				label="Transmitir NFS-e"
				icon="fa fa-send"
				title={getTitleButtonTransmitir()}
				onClick={transmitirNfse}
				style={{ margin: '5px' }}
				hidden={values.situacao === Situacao.TRANSMITIDO || values.situacao === Situacao.CANCELADO}
				disabled={desabilitarBotaoTransmitir}
				{...informacoesPermissoes}
			/>
			<If test={visibleModalHistorico}>
				<ModalHistorico
					header="Histórico da nota fiscal de serviço"
					visible={visibleModalHistorico}
					onHide={() => setVisibleModalHistorico(false)}
					mapping="nfses"
					id={values.id}
				/>
			</If>
			<If test={visibleModalCancelamento}>
				<ModalCancelamento
					visible={visibleModalCancelamento}
					onHide={() => setVisibleModalCancelamento(false)}
					onCancelar={onCancelarNFSe}
					isCanceled={isCancelado}
					motivoCancelamento={values.motivoCancelamento}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</If>
		</>
	);
}
