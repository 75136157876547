import { buscarConfiguracaoUsuario, configuracoesUsuario, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { createContext, useContext, useState } from 'react';
import { COLORS, DEFAULT_TOTAIS, SITUACAO_ORDEM_SERVICO, TAB_ITENS } from '../Util/constantes';

const ContextOrdemServico = createContext();

export default function OrdemServicoProvider(props) {
	const { children } = props;

	const [color, setColor] = useState(COLORS.PENDENTE);
	const [tabParcelaSelecionada, setTabParcelaSelecionada] = useState(0);
	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.INSERIR)
	);
	const [podeEditar, setPodeEditar] = useState(
		usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.EDITAR)
	);
	const [podeExcluir, setPodeExcluir] = useState(
		usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.EXCLUIR)
	);
	const [situacaoOS, setSituacaoOS] = useState(SITUACAO_ORDEM_SERVICO.PENDENTE);
	const [disabledFields, setDisabledFields] = useState(false);
	const [clienteUsaPrecoAtacado, setClienteUsaPrecoAtacado] = useState(false);
	const [totais, setTotais] = useState(DEFAULT_TOTAIS);
	const [indexRecalcularRateioParcela, setIndexRecalcularRateioParcela] = useState(null);
	const [atualizarTotais, setAtualizarTotais] = useState(false);
	const [municipioId, setMunicipioId] = useState(null);
	const [ufId, setUfId] = useState(null);
	const [functionsForm, setFunctionsForm] = useState({
		handleChangeInput: () => {},
		handleChangeSelect: () => {},
		updateStatesContext: () => {},
	});
	const [functionsModalServico, setFunctionsModalServico] = useState({
		handleChangeInput: () => {},
		handleChangeSelect: () => {},
	});
	const [functionsModalProduto, setFunctionsModalProduto] = useState({
		handleChangeInput: () => {},
		handleChangeSelect: () => {},
	});
	const [selectedCard, setSelectedCard] = useState(null);
	const [hasRejeicaoNFSe, setHasRejeicaoNFSe] = useState(false);
	const [hasRejeicaoNFe, setHasRejeicaoNFe] = useState(false);
	const [hasParcelaRecebida, setHasParcelaRecebida] = useState(false);

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial, setDeveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_ORDEM_SERVICO)
	);
	const [hasBoletoGerado, setHasBoletoGerado] = useState(false);
	const [hasNFSeGerada, setHasNFSeGerada] = useState(false);
	const [hasNFeGerada, setHasNFeGerada] = useState(false);
	const [hasNFSeConfigurada, setHasNFSeConfigurada] = useState(false);
	const [hasNFeConfigurada, setHasNFeConfigurada] = useState(false);
	const [parcelasContaAReceber, setParcelasContaAReceber] = useState([]);
	const [tabItensIndex, setTabItensIndex] = useState(TAB_ITENS.SERVICOS);
	const [tabAdicionaisIndex, setTabAdicionaisIndex] = useState(0);
	const [recalcularTodosOsServicosComTabelaPreco, setRecalcularTodosOsServicosComTabelaPreco] = useState(false);
	const [recalcularTodosOsProdutosComTabelaPreco, setRecalcularTodosOsProdutosComTabelaPreco] = useState(false);
	const [favoritos, setFavoritos] = useState({
		conta: null,
		formaPagamento: null,
		condicaoPagamento: null,
		categoria: null,
	});

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	return (
		<ContextOrdemServico.Provider
			value={{
				podeInserir,
				setPodeInserir,
				podeEditar,
				setPodeEditar,
				podeExcluir,
				setPodeExcluir,
				informacoesPermissoes,
				tabParcelaSelecionada,
				setTabParcelaSelecionada,
				color,
				setColor,
				situacaoOS,
				setSituacaoOS,
				disabledFields,
				setDisabledFields,
				clienteUsaPrecoAtacado,
				setClienteUsaPrecoAtacado,
				totais,
				setTotais,
				indexRecalcularRateioParcela,
				setIndexRecalcularRateioParcela,
				atualizarTotais,
				setAtualizarTotais,
				municipioId,
				setMunicipioId,
				ufId,
				setUfId,
				functionsForm,
				setFunctionsForm,
				functionsModalServico,
				setFunctionsModalServico,
				functionsModalProduto,
				setFunctionsModalProduto,
				selectedCard,
				setSelectedCard,
				hasRejeicaoNFSe,
				setHasRejeicaoNFSe,
				hasRejeicaoNFe,
				setHasRejeicaoNFe,
				hasParcelaRecebida,
				setHasParcelaRecebida,
				tutorialVisible,
				setTutorialVisible,
				deveExibirTutorial,
				setDeveExibirTutorial,
				hasBoletoGerado,
				setHasBoletoGerado,
				hasNFSeGerada,
				setHasNFSeGerada,
				hasNFeGerada,
				setHasNFeGerada,
				hasNFSeConfigurada,
				setHasNFSeConfigurada,
				hasNFeConfigurada,
				setHasNFeConfigurada,
				parcelasContaAReceber,
				setParcelasContaAReceber,
				tabItensIndex,
				setTabItensIndex,
				tabAdicionaisIndex,
				setTabAdicionaisIndex,
				recalcularTodosOsServicosComTabelaPreco,
				setRecalcularTodosOsServicosComTabelaPreco,
				recalcularTodosOsProdutosComTabelaPreco,
				setRecalcularTodosOsProdutosComTabelaPreco,
				favoritos,
				setFavoritos,
			}}
		>
			{children || null}
		</ContextOrdemServico.Provider>
	);
}

export const useContextOrdemServico = () => useContext(ContextOrdemServico);
