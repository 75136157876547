import { Field, useFormikContext } from 'formik';
import { services, parseFloatNumber } from '../../../../../../Common';
import {
	InputDate,
	InputField,
	InputMask,
	InputMoney,
	LinkVerDados,
	SingleSelectOperacaoFiscal,
	SingleSelectPessoa,
	SingleSelectModeloFiscal,
	Col,
	If,
} from '../../../../../../components';
import { TipoPessoa } from '../../../../../cadastros/Pessoas/Util/constantes';
import { asyncGetPessoa } from '../../../Requests';
import { converterEmitente } from '../../../Util/notaConverter';
import { calcularTotal } from '../../../Util/functions';

function CabecalhoNotaEntrada({
	isFinalizado,
	podeEditar,
	setModalDadosFornecedor,
	setIsUserInput,
	isUserInput,
	isMobile,
}) {
	const { values, setFieldValue } = useFormikContext();

	function handleKeyPress(e) {
		setIsUserInput(true);
	}

	function onChangeValoresExternosItens(campo, value) {
		setIsUserInput(true);
		setFieldValue(`totais.${campo}`, value);
		calcularRateioCustos(values.itens, campo, value);
	}

	function calcularRateioCustos(produtos, campo, valor) {
		let itens = produtos;
		let somaRateio = 0;
		let totalProdutos = 0;

		if (isUserInput) {
			itens.map((item) => {
				totalProdutos += item.quantidadeConvertida * item.valorUnitario;
				item[campo] = parseFloatNumber((item?.quantidadeConvertida * item?.valorUnitario * valor) / totalProdutos);
				somaRateio += item[campo];
				item.subTotal = calcularTotal(
					item.operacaoFatorConversao ?? 0,
					item.valorUnitario ?? 0,
					item.quantidade ?? 0,
					item.valorIcmsSt ?? 0,
					item.valorIpi ?? 0,
					item.valorFrete ?? 0,
					item.valorSeguro ?? 0,
					item.valorAcessorias ?? 0,
					item.valorDesconto ?? 0
				);
			});

			if (valor - somaRateio !== 0) {
				if (itens.length > 0) {
					itens[0][campo] = parseFloatNumber(itens[0][campo] - parseFloatNumber(somaRateio - valor));
				}
			}
			setFieldValue('itens', itens);
		} else {
			setFieldValue('itens', itens);
		}
	}

	async function changeEmitente(item) {
		let novoEmitente = values;

		if (item) {
			await asyncGetPessoa(item, ({ data: pessoa }) => {
				novoEmitente = {
					...novoEmitente.emitente,
					pessoa: pessoa,
				};
			});
			setFieldValue('emitente', montarEmitente(converterEmitente(novoEmitente)));
		} else {
			setFieldValue('emitente', null);
		}
	}

	function montarEmitente(value) {
		let labelEmitente = value.codigo + ' - ' + value.nome;
		return {
			...value,
			label: labelEmitente,
		};
	}

	return (
		<>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				id="Numero"
				obrigatorio
				component={InputField}
				label="Número"
				name="numero"
				type="number"
				size={50}
				keyfilter="pint"
				value={values.numero}
				onChange={(e) => setFieldValue('numero', e.target?.value)}
				disabled={isFinalizado || values.xmlImportado || (values.id && !podeEditar)}
			/>
			<Field
				sm="12"
				md="1"
				lg="1"
				xl="1"
				obrigatorio
				component={InputField}
				size={3}
				label="Série"
				name="serie"
				keyfilter="alphanum"
				value={values.serie}
				onChange={(e) => setFieldValue('serie', e.target?.value)}
				disabled={isFinalizado || values.xmlImportado || (values.id && !podeEditar)}
			/>
			<Field
				sm="12"
				md="1"
				lg="2"
				xl="2"
				obrigatorio
				component={SingleSelectModeloFiscal}
				label="Modelo"
				name={'modelo'}
				isClearable={false}
				value={values.modelo}
				isMobile={isMobile}
				onChange={(e) => setFieldValue('modelo', { label: e.label, value: e.value })}
				disabled={isFinalizado || values.xmlImportado || (values.id && !podeEditar)}
				styles={{
					menu: (base, state) => ({
						...base,
						minWidth: '25rem',
					}),
					placeholder: (defaultStyles) => {
						return {
							...defaultStyles,
							paddingBottom: '3px',
							fontSize: '13px',
						};
					},
				}}
				menuPortalTarget={document.body}
			/>
			<Col sm="12" md="8" lg="7" xl="7">
				<Field
					obrigatorio
					colStyle={{ padding: '0px' }}
					component={SingleSelectPessoa}
					label="Fornecedor"
					name={'emitente'}
					value={values.emitente}
					isMobile={isMobile}
					onChange={(e) => changeEmitente(e?.value)}
					isClearable={false}
					url={`${services.GESTOR}/v1/pessoas/fornecedores`}
					disabled={isFinalizado || values.xmlImportado || (values.id && !podeEditar)}
					tipoPessoa={TipoPessoa.FORNECEDOR}
					styles={{
						placeholder: (defaultStyles) => {
							return {
								...defaultStyles,
								paddingBottom: '3px',
								fontSize: '13px',
							};
						},
					}}
				/>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
					}}
				>
					<If test={values.emitente?.pessoa}>
						<LinkVerDados
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								position: 'relative',
								margin: '2px 25px -20px 0',
							}}
							label="Ver dados do fornecedor"
							onClick={() => setModalDadosFornecedor(true)}
						/>
					</If>
				</div>
			</Col>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={InputDate}
				label="Data/Hora emissão"
				name="dataEmissao"
				value={values.dataEmissao}
				onChange={(e) => setFieldValue('dataEmissao', e.target?.value)}
				disabled={isFinalizado || values.xmlImportado || (values.id && !podeEditar)}
			/>
			<Field
				sm="12"
				md="1"
				lg="1"
				xl="1"
				component={InputField}
				type="time"
				name="horaEmissao"
				value={values.horaEmissao}
				onChange={(e) => setFieldValue('horaEmissao', e.target?.value)}
				style={{ marginTop: '17px' }}
				disabled={isFinalizado || values.xmlImportado || (values.id && !podeEditar)}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={InputDate}
				label="Data/Hora entrada"
				name="dataEntrada"
				value={values.dataEntrada}
				onChange={(e) => setFieldValue('dataEntrada', e.target?.value)}
				disabled={isFinalizado || (values.id && !podeEditar)}
			/>
			<Field
				sm="12"
				md="1"
				lg="1"
				xl="1"
				component={InputField}
				type="time"
				name="horaEntrada"
				value={values.horaEntrada}
				onChange={(e) => setFieldValue('horaEntrada', e.target?.value)}
				style={{ marginTop: '17px' }}
				disabled={isFinalizado || (values.id && !podeEditar)}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputMask}
				label="Chave de acesso"
				name="chaveAcesso"
				mask="0000 0000 0000 0000 0000 0000 0000 0000 0000 0000 0000"
				value={values.chaveAcesso}
				onChange={(e) => setFieldValue('chaveAcesso', e.target?.value)}
				obrigatorio={values.modelo?.value === 'a4067392-505f-47e4-81c0-2f89c55484c9'}
				disabled={isFinalizado || values.xmlImportado || (values.id && !podeEditar)}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Frete"
				name="valorFrete"
				id="valorFrete"
				value={values.totais.valorFrete}
				onKeyDown={(e) => handleKeyPress(e)}
				onChange={(e) => {
					onChangeValoresExternosItens('valorFrete', e.target?.value);
				}}
				allowNegative={false}
				disabled={isFinalizado || !values.itens.length > 0 || values.xmlImportado || (values.id && !podeEditar)}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Seguro"
				name="valorSeguro"
				id="valorSeguro"
				value={values.totais.valorSeguro}
				onKeyDown={(e) => handleKeyPress(e)}
				onChange={(e) => {
					onChangeValoresExternosItens('valorSeguro', e.target?.value);
				}}
				allowNegative={false}
				disabled={isFinalizado || !values.itens.length > 0 || values.xmlImportado || (values.id && !podeEditar)}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Acessórias"
				name="valorAcessorias"
				id="valorAcessorias"
				value={values.totais.valorAcessorias}
				onKeyDown={(e) => handleKeyPress(e)}
				onChange={(e) => {
					onChangeValoresExternosItens('valorAcessorias', e.target?.value);
				}}
				allowNegative={false}
				disabled={isFinalizado || !values.itens.length > 0 || values.xmlImportado || (values.id && !podeEditar)}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Desconto"
				name="valorDesconto"
				id="valorDesconto"
				value={values.totais.valorDesconto}
				onKeyDown={(e) => handleKeyPress(e)}
				onChange={(e) => {
					onChangeValoresExternosItens('valorDesconto', e.target?.value);
				}}
				allowNegative={false}
				disabled={isFinalizado || !values.itens.length > 0 || values.xmlImportado || (values.id && !podeEditar)}
			/>
			<Field
				sm="12"
				md="12"
				lg="4"
				xl="4"
				obrigatorio
				component={SingleSelectOperacaoFiscal}
				label="Operação fiscal"
				name={'operacaoFiscal'}
				tipoOperacao={'ENTRADA'}
				value={values.operacaoFiscal}
				onChange={(e) => setFieldValue('operacaoFiscal', { label: e.label, value: e.value })}
				isClearable={false}
				disabled={isFinalizado || (values.id && !podeEditar)}
				styles={{
					placeholder: (defaultStyles) => {
						return {
							...defaultStyles,
							paddingBottom: '3px',
							fontSize: '13px',
						};
					},
				}}
			/>
		</>
	);
}

export default CabecalhoNotaEntrada;
