import { del, get, post, put, services, exibirToast } from 'Common';
import { ToastTypes, notify } from 'components';

export async function readTabelasPreco(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function readTabelaPreco(idTabela, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabela}`, null, onSuccess, onError);
}

export async function createTabelaPreco(tabelaPreco, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/tabelas_preco`,
		tabelaPreco,
		null,
		exibirToast(onSuccess, 'Registro criado com sucesso'),
		onError
	);
}

export async function updateTabelaPreco(tabelaPreco, onSuccess, onError) {
	return await put(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${tabelaPreco.id}`,
		tabelaPreco,
		null,
		exibirToast(onSuccess, 'Registro atualizado com sucesso'),
		onError
	);
}

export async function deleteTabelaPreco(idTabelaPreco, onSuccess, onError) {
	return await del(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabelaPreco}`,
		null,
		exibirToast(onSuccess, 'Registro excluído'),
		onError
	);
}

export async function duplicarTabelaPreco(idTabela, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabela}/duplicar`, null, null, onSuccess, onError);
}

export async function readTabelaPrecoProdutos(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function updateTabelaPrecoProdutos(url, produtos, onSuccess, onError) {
	return await put(url, { produtosEdicao: produtos }, null, onSuccess, onError, true);
}

export async function deleteTabelaPrecoProdutos(url, produtos, onSuccess, onError) {
	return await post(url, { produtosExclusao: produtos }, null, onSuccess, onError, true);
}

export async function createTabelaPrecoProduto(url, onSuccess, onError) {
	return post(
		url,
		null,
		null,
		onSuccess,
		({ response }) => {
			console.warn('response:', response);
			if (response.status === 400) {
				notify(
					response.data?.details?.length > 0 ? response.data?.details[0] : 'Ocorreu um erro ao adicionar o produto',
					ToastTypes.ERROR,
					5
				);
			}

			typeof onError === 'function' && onError();
		},
		true,
		false
	);
}

export async function readTabelaPrecoProdutoFiltro(onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/produtos?size=2000&sort=nome,asc`, null, onSuccess, onError, false);
}

export async function readTabelaPrecoProdutoGrupoFiltro(onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/produtos/grupos?size=2000&sort=nivel,asc`, null, onSuccess, onError, false);
}

export async function importarTodosProdutosTabelaPreco(idTabela, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabela}/importar_todos_produtos`,
		null,
		null,
		onSuccess,
		onError
	);
}

export async function importarTodosServicosTabelaPreco(idTabela, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabela}/importar_todos_servicos`,
		null,
		null,
		onSuccess,
		onError
	);
}

export async function importarTodosProdutosEServicosTabelaPreco(idTabela, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabela}/importar_todos_produtos_e_servicos`,
		null,
		null,
		onSuccess,
		onError
	);
}

export async function importarProdutosPorGrupoTabelaPreco(idTabela, grupos, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabela}/importar_produtos_por_grupo`,
		{ grupos: grupos },
		null,
		onSuccess,
		onError
	);
}

export async function importarProdutosDeOutraTabelaPreco(idTabelaDestino, idTabelaOrigem, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabelaDestino}/importar_produtos_outra_tabela/${idTabelaOrigem}`,
		null,
		null,
		onSuccess,
		onError
	);
}

export async function replicarProdutosParaOutrasTabelasPreco(idTabelaOrigem, tabelasDestino, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabelaOrigem}/replicar_produtos_para_outras_tabelas`,
		tabelasDestino,
		null,
		onSuccess,
		onError
	);
}

export async function alterarLucroPercentualEmLote(idTabela, alteracaoEmLote, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabela}/produtos/manutencao_em_lote/alterar_lucro`,
		alteracaoEmLote,
		null,
		onSuccess,
		onError
	);
}

export async function alterarPrecoVendaEmLote(idTabela, alteracaoEmLote, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabela}/produtos/manutencao_em_lote/alterar_preco_venda`,
		alteracaoEmLote,
		null,
		onSuccess,
		onError
	);
}

export async function alterarComissaoPercentualEmLote(idTabela, alteracaoEmLote, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabela}/produtos/manutencao_em_lote/alterar_comissao`,
		alteracaoEmLote,
		null,
		onSuccess,
		onError
	);
}

export async function alterarDescontoMaximoPercentualEmLote(idTabela, alteracaoEmLote, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabela}/produtos/manutencao_em_lote/alterar_desconto_maximo`,
		alteracaoEmLote,
		null,
		onSuccess,
		onError
	);
}

export async function atualizarCustoTodosOsProdutosTabelaPreco(data, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/tabelas_preco/${data.idTabelaPreco}/produtos/manutencao_em_lote/atualizar_custo`,
		{ tipoCusto: data.tipoCusto },
		null,
		onSuccess,
		onError
	);
}

export async function asyncBuscarTabelaPreco(dadosRequest, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/vendas/tabelas_preco/buscar_tabela_a_utilizar?municipioId=${
			dadosRequest?.municipioId ?? ''
		}&clienteId=${dadosRequest?.clienteId ?? ''}&vendedorId=${dadosRequest?.vendedorId ?? ''}`,
		null,
		onSuccess,
		onError
	);
}
