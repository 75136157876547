export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é seu cadastro de regiões de venda, nele você pode adicionar regiões específicas e seus municípios associados.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-regiao-nome',
		content: 'No campo nome você pode inserir o nome da região de venda',
		placement: 'top',
	},
	{
		target: '.step-regiao-situacao',
		content: 'No campo situação você pode ativar ou desativar a região atual.',
		placement: 'top',
	},
	{
		target: '.step-regiao-select-municipio',
		content:
			'Neste campo você pode adicionar um município específico a região de venda. Este campo ficará desativado caso a região ainda não possuir um nome. A região será salva automaticamente com esta ação.',
		placement: 'top',
	},
	{
		target: '.step-regiao-select-estado',
		content:
			'Neste campo você pode selecionar todos os municípios de um estado específico. Este campo ficará desativado caso a região ainda não possuir um nome. A região será salva automaticamente com esta ação.',
		placement: 'top',
	},
	{
		target: '.step-regiao-listagem-municipios',
		content:
			'Nesta lista, você pode ver todos os municípios associados a região de vendas. Ao remover um município, a região será salva automaticamente.',
		placement: 'top',
	},
];
