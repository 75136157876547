export function converterModeloCodigoServicoParaApi(cabecalho, tributacoes) {
	return {
		descricao: cabecalho.nome,
		tributacoes: converterTributacoesParaApi(tributacoes),
	};
}

function converterTributacoesParaApi(tributacoes) {
	let listaTributacoes = tributacoes.map(tributacao => {
		return {
			operacaoFiscal: { id: tributacao.operacaoFiscal.value },
			tributacaoMunicipal: { id: tributacao.tributacaoMunicipal.value },
		};
	});
	return listaTributacoes;
}
