import { formatarNcm, gerarUUID } from 'Common';
import { CONTA_RECEBER_STATUS, SITUACAO_ORDEM_SERVICO } from './constantes';

function converterOrdemServicoParaFormulario(value) {
	return {
		id: value?.id ?? null,
		numero: value.numero ?? null,
		situacao: SITUACAO_ORDEM_SERVICO[value.situacao],
		cliente: converterValueParaSelect(
			value.cliente,
			`${value.cliente?.codigo} - ${value.cliente?.nome} - ${value.cliente?.cpf ?? value.cliente?.cnpj}`
		),
		dataAbertura: value.dataAbertura ?? null,
		horaAbertura: converterHoraParaFormulario(value.horaAbertura) ?? null,
		dataFechamento: value.dataFechamento ?? null,
		horaFechamento: converterHoraParaFormulario(value.horaFechamento) ?? null,
		objetoServico: converterValueParaSelect(
			value.objetoServico,
			`${value.objetoServico?.codigo} - ${value.objetoServico?.nome}`
		),
		operacaoFiscal: converterValueParaSelect(
			value.operacaoFiscal,
			`${value.operacaoFiscal?.codigo} - ${value.operacaoFiscal?.descricao}`
		),
		quilometragemAtual: value.quilometragemAtual ?? null,
		servicos: converterServicosParaFormulario(value.servicos),
		produtos: converterProdutosParaFormulario(value.produtos),
		totalServicos: converterTotalServicoParaFormulario(value.totalServico),
		totalProdutos: converterTotalProdutoParaFormulario(value.totalProduto),
		comissaoTotalPercentual: value.comissaoTotalPercentual,
		comissaoTotalValor: value.comissaoTotalValor,
		valorTotal: value.valorTotal ?? 0,
		pagamentos: converterPagamentoParaFormulario(value.pagamentos, value.cliente),
		informacoesComplementares: converterInformacaoComplementarParaFormulario(value),
		nfe: converterNfeNfseParaFormulario(value.nfe),
		nfse: converterNfeNfseParaFormulario(value.nfse),
		gerouEstoque: value.gerouEstoque ?? false,
		motivoCancelamento: value.motivoCancelamento ?? null,
		criadoEm: value.criadoEm ?? null,
		alteradoEm: value.alteradoEm ?? null,
		problemaRelatado: value.problemaRelatado ?? null,
		situacaoOrcamento: value.situacaoOrcamento ?? null,
	};
}

function converterServicosParaFormulario(values) {
	let servicos = [];
	if (values.length > 0) {
		values.forEach((value) => {
			servicos.push({
				id: value.id ?? null,
				item: value.item ?? null,
				servico: converterValueParaSelect(value.servico, `${value.servico?.codigo} - ${value.servico?.nome}`),
				descricao: value.descricao ?? null,
				quantidade: value.quantidade ?? 0,
				valor: value.valor ?? 0,
				valorOriginal: value.valorOriginal ?? 0,
				desconto: value.desconto ?? 0,
				subtotal: value.subtotal ?? 0,
				baseCalculo: 0,
				operacaoFiscal: converterValueParaSelect(
					value.operacaoFiscal,
					`${value.operacaoFiscal?.codigo} - ${value.operacaoFiscal?.descricao}`
				),
				codigoServico: converterValueParaSelect(
					value.codigoServico,
					`${value.codigoServico?.codigo} - ${value.codigoServico?.descricao}`
				),
				cfop: converterValueParaSelect(value.cfop, `${value.cfop?.codigo}`),
				unidadeMedida: converterValueParaSelect(
					value?.unidadeMedida,
					`${value?.unidadeMedida?.unidade} - ${value?.unidadeMedida?.descricao}`
				),
				impostoServico: {
					id: value.impostoServico.id ?? null,
				},
				tributos: {
					iss: converterIssParaFormulario(value.impostoServico),
					ir: converterIrParaFormulario(value.impostoServico),
					csll: converterCsllParaFormulario(value.impostoServico),
					inss: converterInssParaFormulario(value.impostoServico),
					pis: converterPisParaFormulario(value.impostoServico),
					cofins: converterCofinsParaFormulario(value.impostoServico),
				},

				utilizouTabelaPromocional: value.utilizouTabelaPromocional ?? false,
				descontoMaximoPercentual: value.descontoMaximoPercentual ?? 0,
				descontoMaximoVendedor: value.descontoMaximoVendedor ?? 0,
				descontoMaximoTabela: value.descontoMaximoTabela ?? 0,
				comissaoValor: value.comissaoValor ?? 0,
				comissaoPercentual: value.comissaoPercentual ?? 0,
				adicionalTabelaPreco: {
					percentual: value.percentualAdicionalTabelaPreco ?? 0,
					tipo: value.tipoPercentualAdicionalTabelaPreco,
				},
			});
		});
	}
	return servicos;
}

function converterIssParaFormulario(value) {
	return {
		cst: converterValueParaSelect(value.cstIss, `${value.cstIss?.codigo} - ${value.cstIss?.descricao}`),
		percentualBase: value.percentualBaseCalculoIss ?? 0,
		valorBase: value.valorBaseCalculoIss ?? 0,
		percentual: value.aliquotaIss ?? 0,
		valor: value.valorIss ?? 0,
	};
}

function converterIrParaFormulario(value) {
	return {
		percentualBase: value.percentualBaseCalculoIr ?? 0,
		valorBase: value.valorBaseCalculoIr ?? 0,
		percentual: value.aliquotaIr ?? 0,
		valor: value.valorIr ?? 0,
	};
}

function converterCsllParaFormulario(value) {
	return {
		percentualBase: value.percentualBaseCalculoCsll ?? 0,
		valorBase: value.valorBaseCalculoCsll ?? 0,
		percentual: value.aliquotaCsll ?? 0,
		valor: value.valorCsll ?? 0,
	};
}

function converterInssParaFormulario(value) {
	return {
		percentualBase: value.percentualBaseCalculoInss ?? 0,
		valorBase: value.valorBaseCalculoInss ?? 0,
		percentual: value.aliquotaInss ?? 0,
		valor: value.valorInss ?? 0,
	};
}

function converterPisParaFormulario(value) {
	return {
		cst: converterValueParaSelect(value.cstPis, `${value.cstPis?.codigo} - ${value.cstPis?.descricao}`),
		percentualBase: value.percentualBaseCalculoPis ?? null,
		valorBase: value.valorBaseCalculoPis ?? null,
		percentual: value.aliquotaPis ?? null,
		valor: value.valorPis ?? null,
	};
}

function converterCofinsParaFormulario(value) {
	return {
		cst: converterValueParaSelect(value.cstCofins, `${value.cstCofins?.codigo} - ${value.cstCofins?.descricao}`),
		percentualBase: value.percentualBaseCalculoCofins ?? null,
		valorBase: value.valorBaseCalculoCofins ?? null,
		percentual: value.aliquotaCofins ?? null,
		valor: value.valorCofins ?? null,
	};
}

function converterProdutosParaFormulario(values) {
	let produtos = [];
	if (values.length > 0) {
		values.forEach((value) => {
			produtos.push({
				id: value.id ?? null,
				item: value.item ?? index + 1,
				produto: converterValueParaSelect(value.produto, `${value.produto?.codigo} - ${value.produto?.nome}`),
				descricao: value.descricao,
				quantidade: value.quantidade ?? 0,
				valor: value.valor ?? 0,
				valorOriginal: value.valorOriginal ?? 0,
				subtotal: value.subtotal ?? 0,
				baseCalculo: 0,
				desconto: value.desconto ?? 0,
				acessorias: value.acessorias ?? 0,
				frete: value.frete ?? 0,
				seguro: value.seguro ?? 0,
				codigoBarras: value.codigoBarras ?? null,
				codigoBeneficiario: value.codigoBeneficiario ?? null,
				numeroPedidoCompra: value.numeroPedidoCompra ?? null,
				itemPedidoCompra: value.numeroItemPedidoCompra ?? null,
				operacaoFiscal: converterValueParaSelect(
					value.operacaoFiscal,
					`${value.operacaoFiscal?.codigo} - ${value.operacaoFiscal?.descricao}`
				),
				unidadeMedida: converterValueParaSelect(
					value.unidadeMedida,
					`${value.unidadeMedida?.unidade} - ${value.unidadeMedida?.descricao}`
				),
				ncm: converterNcmParaFormulario(value.ncm),
				cest: converterValueParaSelect(value.cest, `${value.cest?.codigo} - ${value.cest?.descricao}`),
				cfop: converterValueParaSelect(value.cfop, `${value.cfop?.codigo}`),
				tributos: {
					icms: converterIcmsParaFormulario(value.icms),
					ipi: converterIpiParaFormulario(value.ipi),
					pis: converterPisProdutoParaFormulario(value.pis),
					cofins: converterCofinsProdutoParaFormulario(value.cofins),
				},
				utilizouTabelaPromocional: value.utilizouTabelaPromocional ?? false,
				descontoMaximoPercentual: value.descontoMaximoPercentual ?? 0,
				descontoMaximoVendedor: value.descontoMaximoVendedor ?? 0,
				descontoMaximoTabela: value.descontoMaximoTabela ?? 0,
				comissaoValor: value.comissaoValor ?? 0,
				comissaoPercentual: value.comissaoPercentual ?? 0,
				adicionalTabelaPreco: {
					percentual: value.percentualAdicionalTabelaPreco ?? 0,
					tipo: value.tipoPercentualAdicionalTabelaPreco,
				},
			});
		});
	}
	return produtos;
}

function converterNcmParaFormulario(value) {
	let codigo = formatarNcm(value?.codigo);

	if (value?.extencao) {
		codigo = `${codigo}-${value.extencao}`;
	}

	return converterValueParaSelect(value, `${codigo} - ${value?.descricao}`);
}

function converterIcmsParaFormulario(value) {
	return {
		id: value.id ?? null,
		cstCsosn: converterValueParaSelect(value.cstCsosn, `${value.cstCsosn?.codigo} - ${value.cstCsosn?.descricao}`),
		origemProduto: value.origem ? value.origem : 'NACIONAL',
		modalidadeCalculo: value.modalidadeCalculo ?? null,
		percentualBaseIcms: value.percentualBaseCalculo ?? 0,
		percentualReducaoBaseIcms: value.percentualReducaoBaseCalculo ?? 0,
		baseIcms: value.valorBaseCalculo ?? 0,
		percentualIcms: value.aliquota ?? 0,
		valorIcms: value.valor ?? 0,
		modalidadeCalculoSt: value.modalidadeCalculoSt ?? null,
		percentualBaseIcmsSt: value.percentualBaseCalculoSt ?? 0,
		percentualReducaoBaseIcmsSt: value.percentualReducaoBaseCalculoSt ?? 0,
		baseIcmsSt: value.valorBaseCalculoSt ?? 0,
		percentualIcmsSt: value.aliquotaSt ?? 0,
		valorIcmsSt: value.valorSt ?? 0,
		percentualMva: value.percentualMva ?? 0,
		valorPauta: value.valorPauta ?? 0,
		percentualBaseIcmsStRetido: value.percentualBaseCalculoStRetido ?? 0,
		baseRetidoSt: value.valorBaseCalculoStRetido ?? 0,
		percentualIcmsStRetido: value.aliquotaStRetido ?? 0,
		valorRetidoSt: value.valorStRetido ?? 0,
		percentualDiferimento: value.percentualDiferido ?? 0,
		valorDiferimento: value.valorDiferido ?? 0,
		baseFundoCombatePobreza: value.valorBaseCalculoFcPobreza ?? 0,
		percentualFundoCombatePobreza: value.percentualFcPobreza ?? 0,
		valorFundoCombatePobreza: value.valorFcPobreza ?? 0,
		percentualCreditoSimplesNacional: value.aliquotaCreditoSimplesNacional ?? 0,
		valorCreditoSimplesNacional: value.valorCreditoSimplesNacional ?? 0,
		valorIcmsSubstituto: value.valorSubstituto ?? 0,
		quantidadeBaseIcmsMonofasicoRetido: value.quantidadeBaseMonofasicoRetido ?? 0,
		percentualIcmsMonofasicoRetido: value.percentualMonofasicoRetido ?? 0,
		valorIcmsMonofasicoRetido: value.valorMonofasicoRetido ?? 0,
		baseCalculo: 0,
	};
}

function converterIpiParaFormulario(value) {
	return {
		id: value.id ?? null,
		cst: converterValueParaSelect(value.cst, `${value.cst?.codigo} - ${value.cst?.descricao}`),
		enquadramentoIpi: converterValueParaSelect(
			value.enquadramento,
			`${value.enquadramento?.codigo} - ${value.enquadramento?.descricao}`
		),
		percentualBaseIpi: value.percentualBaseCalculo ?? 0,
		baseIpi: value.valorBaseCalculo ?? 0,
		percentualIpi: value.aliquota ?? 0,
		valorIpi: value.valor ?? 0,
	};
}

function converterPisProdutoParaFormulario(value) {
	return {
		id: value.id ?? null,
		cst: converterValueParaSelect(value.cst, `${value.cst?.codigo} - ${value.cst?.descricao}`),
		percentualBasePis: value.percentualBaseCalculo ?? 0,
		basePis: value.valorBaseCalculo ?? 0,
		percentualPis: value.aliquota ?? 0,
		valorPis: value.valor ?? 0,
		percentualBasePisSt: value.percentualBaseCalculoSt ?? 0,
		basePisSt: value.valorBaseCalculoSt ?? 0,
		percentualPisSt: value.aliquotaSt ?? 0,
		valorPisSt: value.valorSt ?? 0,
	};
}

function converterCofinsProdutoParaFormulario(value) {
	return {
		id: value.id ?? null,
		cst: converterValueParaSelect(value.cst, `${value.cst?.codigo} - ${value.cst?.descricao}`),
		percentualBaseCofins: value.percentualBaseCalculo ?? 0,
		baseCofins: value.valorBaseCalculo ?? 0,
		percentualCofins: value.aliquota ?? 0,
		valorCofins: value.valor ?? 0,
		percentualBaseCofinsSt: value.percentualBaseCalculoSt ?? 0,
		baseCofinsSt: value.valorBaseCalculoSt ?? 0,
		percentualCofinsSt: value.aliquotaSt ?? 0,
		valorCofinsSt: value.valorSt ?? 0,
	};
}

function converterTotalServicoParaFormulario(value) {
	return {
		id: value.id ?? null,
		valorBruto: value.valorBruto ?? 0,
		tipoDesconto: value.tipoDesconto,
		desconto: value.desconto ?? 0,
		valorLiquido: value.valorLiquido ?? 0,
		baseIss: value.baseIss ?? 0,
		valorIss: value.valorIss ?? 0,
		baseIr: value.baseIr ?? 0,
		valorIr: value.valorIr ?? 0,
		baseCsll: value.baseCsll ?? 0,
		valorCsll: value.valorCsll ?? 0,
		baseInss: value.baseInss ?? 0,
		valorInss: value.valorInss ?? 0,
		basePis: value.basePis ?? 0,
		valorPis: value.valorPis ?? 0,
		baseCofins: value.baseCofins ?? 0,
		valorCofins: value.valorCofins ?? 0,
	};
}

function converterTotalProdutoParaFormulario(value) {
	return {
		id: value.id ?? null,
		valorBruto: value.valorBruto ?? 0,
		frete: value.frete ?? 0,
		seguro: value.seguro ?? 0,
		acessorias: value.acessorias ?? 0,
		tipoDesconto: value.tipoDesconto,
		desconto: value.desconto ?? 0,
		valorLiquido: value.valorLiquido ?? 0,
		baseIcms: value.baseIcms ?? 0,
		valorIcms: value.valorIcms ?? 0,
		baseSt: value.baseSt ?? 0,
		valorSt: value.valorSt ?? 0,
		baseIpi: value.baseIpi ?? 0,
		valorIpi: value.valorIpi ?? 0,
		basePis: value.basePis ?? 0,
		valorPis: value.valorPis ?? 0,
		baseCofins: value.baseCofins ?? 0,
		valorCofins: value.valorCofins ?? 0,
	};
}

function converterPagamentoParaFormulario(pagamentos, cliente) {
	const newPagamentos = [];

	if (pagamentos.length > 0) {
		pagamentos.forEach((pagamento) => {
			newPagamentos.push({
				id: pagamento.id,
				tempKey: gerarUUID(),
				sequencial: pagamento.sequencial,
				condicaoPagamento: converterCondicaoPagamentoParaFormulario(pagamento.condicaoPagamento),
				conta: converterValueParaSelect(pagamento.conta),
				formaPagamento: converterValueParaSelect(pagamento.formaPagamento),
				categoria: converterValueParaSelect(pagamento.categoria),
				quantidadeParcelas:
					pagamento.quantidadeParcelas > 0
						? {
								label: `${pagamento.quantidadeParcelas}x`,
								value: pagamento.quantidadeParcelas,
							}
						: null,
				valor: pagamento.valor ?? 0,
				valorPago: pagamento.valorPago ?? 0,
				parcelas: converterParcelasParaFormulario(pagamento.parcelas, cliente),
			});
		});
	}

	return newPagamentos;
}

function converterCondicaoPagamentoParaFormulario(condicao) {
	if (condicao) {
		return {
			label: condicao.descricao,
			value: condicao.id,
			registro: condicao,
		};
	}
	return null;
}

function converterParcelasParaFormulario(parcelas, cliente) {
	let newParcelas = [];

	if (parcelas.length > 0) {
		parcelas.forEach((parcela, index) => {
			newParcelas.push({
				id: parcela.id ?? null,
				tempKey: gerarUUID(),
				sequencial: parcela.sequencial ?? index + 1,
				temBoleto: parcela.temBoleto ?? false,
				temPix: parcela.temPix ?? false,
				vencimento: parcela.vencimento ?? null,
				formaPagamento: converterValueParaSelect(parcela.formaPagamento),
				conta: converterValueParaSelect(parcela.conta),
				descricao: parcela.descricao ?? null,
				valor: parcela.valor ?? 0,
				financeiroIdentificador: parcela.financeiroIdentificador ?? null,
				financeiroId: parcela.financeiroId ?? null,
				financeiroStatus: parcela.financeiroStatus ?? null,
				valorRestante: parcela.valorRestante ?? 0,
				pessoaNome: cliente.nome,
				pessoaCodigo: cliente.codigo,
				pessoaEmail: converterClienteEmailParaFormulario(cliente.emails),
			});
		});
	}

	return newParcelas;
}

function converterClienteEmailParaFormulario(emails) {
	let emailFavorito = '';
	if (emails?.length > 0) {
		emails?.forEach((email) => {
			emailFavorito = email.favorito === true ? `${email.email}` : null;
		});
	}

	if (emailFavorito) {
		return emailFavorito;
	} else if (emails?.length > 0) {
		return emails[0]?.email;
	}
	return null;
}

function converterInformacaoComplementarParaFormulario(value) {
	let informacaoComplementar = value.informacaoComplementar;
	return {
		id: informacaoComplementar.id ?? null,
		setor: converterValueParaSelect(informacaoComplementar.setor),
		vendedor: converterValueParaSelect(
			informacaoComplementar.vendedor,
			`${informacaoComplementar.vendedor?.codigo} - ${informacaoComplementar.vendedor?.nome}`
		),
		tabelaPreco: converterValueParaSelect(
			informacaoComplementar.tabelaPreco,
			`${informacaoComplementar.tabelaPreco?.codigo} - ${informacaoComplementar.tabelaPreco?.nome}`
		),
		tecnico: converterValueParaSelect(value.tecnico),
		servico: converterInfoComplServicoParaFormulario(informacaoComplementar),
		produto: converterInfoComplProdutoParaFormulario(informacaoComplementar),
	};
}

function converterInfoComplServicoParaFormulario(value) {
	return {
		informacoesComplementares: value.servicoInformacaoComplementar ?? null,
		observacaoInterna: value.servicoObservacaoInterna ?? null,
		municipio: converterValueParaSelect(
			value.municipio,
			`${value.municipio?.nome} - ${value.municipio?.estado?.sigla}`
		),
	};
}

function converterInfoComplProdutoParaFormulario(value) {
	return {
		informacoesComplementares: value.produtoInformacaoComplementar ?? null,
		observacaoInterna: value.produtoObservacaoInterna ?? null,
	};
}

function converterNfeNfseParaFormulario(value) {
	return {
		id: value?.id ?? null,
		numero: value?.numero ?? null,
		serie: value?.serie ?? null,
		status: value?.status ?? null,
		rejeicao: value?.rejeicao ?? null,
	};
}

function converterValueParaSelect(value, label) {
	return value
		? {
				value: value.id ?? null,
				label: label ?? value.nome ?? value.descricao,
				registro: value,
			}
		: null;
}

function converterHoraParaFormulario(hora) {
	return hora ? hora.replace(/^(\d{2}:\d{2}).*/, '$1') : null;
}

function calcularValorAReceber(parcela, values) {
	const resultado = parcela.valorAReceber - values.valor;

	if (resultado <= 0) return 0;

	return parseFloat(resultado).toFixed(2);
}

function converterRecebimentoParaFormulario(parcela, values) {
	return {
		...parcela,
		status:
			calcularValorAReceber(parcela, values) === 0
				? CONTA_RECEBER_STATUS.RECEBIDA
				: CONTA_RECEBER_STATUS.PARCIALMENTE_RECEBIDA,
		valorAReceber: calcularValorAReceber(parcela, values),
	};
}

export {
	converterOrdemServicoParaFormulario,
	converterValueParaSelect,
	converterHoraParaFormulario,
	converterRecebimentoParaFormulario,
	converterCondicaoPagamentoParaFormulario,
};
