import { atualizarUrl } from "views/Util";
import { itensBreadCrumb } from "../../../config/Rotas";

export function criarItensBreadcrumb(hash, history) {
  let valor;
  let casas;
  let resultado = [];
  let agregado = "";

  hash = hash.replace("#/", "");
  itensBreadCrumb.forEach((element) => {
    if (
      element.valor === hash.split("?")[0] ||
      String(hash.split("?")[0]).includes(element.valor)
    ) {
      valor = element.valor;
    }
  });

  if (!valor) {
    return [];
  }
  casas = valor.split("/");

  for (let i = 0; i < casas.length; i++) {
    agregado += (i > 0 ? "/" : "") + casas[i];

    // eslint-disable-next-line
    itensBreadCrumb.forEach((element) => {
      if (element.valor === agregado) {
        resultado.push({
          label: element.descricao,
          command: () => {
            atualizarUrl(history, `/${element.valor}`);
          },
        });
        return;
      }
    });
  }

  return resultado;
}
