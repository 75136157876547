export const helpDocumentoSignatarioForm = {
	signatarioCadastrado: 'Permite a seleção de signatários que já foram cadastrados no sistema.',
	pessoa: 'Pessoa que irá assinar o documento',
	nome: 'Nome completo do signatário que irá assinar o documento.',
	email:
		'E-mail do signatário que irá assinar o documento. É para este e-mail que será enviado o documento para assinatura eletrônica ou digital.',
	papel: 'Identifica o papel do signatário em relação ao documento.',
	telefone: 'Telefone  do signatário que irá assinar o documento.',
};

export const INITIAL_VALUES = {
	signatariosPadrao: [],
};

export const INITIAL_VALUES_EDIT = {
	tempId: null,
	id: null,
	pessoa: null,
	email: null,
	nome: null,
	papel: 'PARTE',
	telefone: null,
	pessoaEmail: null,
	pessoaTelefone: null,
	signatarioCadastrado: false,
};
