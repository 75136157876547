import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';

import { Grid, TabView } from 'components';

import { useContextVendedores } from 'views/cadastros/Vendedores/Context';
import { TIPO_VENDEDORES } from '../../Utils/constantes';

import { TabConfiguracoes } from './components/TabConfiguracoes';
import { TabRegioes } from './components/TabRegioes';
import { TabTabelaPrecoFilial } from './components/TabTabelaPrecoFilial';

function TabsVendedores({ isModal }) {
	const { values } = useFormikContext();
	const { activeTabIndex, setActiveTabIndex, utilizaTabelaPrecoPorRegiao } = useContextVendedores();

	const hasMultipleFiliais = values.filiais?.length > 1;

	return (
		<Grid>
			<TabView
				className="tab-view "
				activeIndex={activeTabIndex}
				onTabChange={({ index }) => setActiveTabIndex(index)}
				renderActiveOnly={false}
			>
				<TabPanel header="Configurações">
					<TabConfiguracoes hasMultipleFiliais={hasMultipleFiliais} isModal={isModal} />
				</TabPanel>
				<TabPanel header="Regiões" disabled={!utilizaTabelaPrecoPorRegiao || values.tipo !== TIPO_VENDEDORES.VENDEDOR}>
					<TabRegioes />
				</TabPanel>
				<TabPanel header="Tabela de preço por filial" headerStyle={!hasMultipleFiliais ? { display: 'none' } : null}>
					<TabTabelaPrecoFilial />
				</TabPanel>
			</TabView>
		</Grid>
	);
}

export { TabsVendedores };
