import React from 'react';

export default function FalhaAoCarregarAsInformacoes(props) {
	return (
		<div
			style={{
				...props.style,
				fontSize: '14px',
				textAlign: 'center',
				opacity: '0.5',
				width: '100%',
				padding: '28px 0px',
			}}
		>
			Ocorreu algum problema ao carregar as informações
		</div>
	);
}
