export function converterCodigoServicoParaApi(codigoServico) {
	const codigoServicoConvertido = {
		id: codigoServico.id,
		codigoServico: {
			id: codigoServico.codigoServico.value ?? null,
		},
		descricao: codigoServico.descricao,
		situacao: codigoServico.situacao,
		tributacoes: !!codigoServico.tributacoes?.length ? converteTributacoesParaApi(codigoServico.tributacoes) : [],
	};

	return codigoServicoConvertido;
}

function converteTributacoesParaApi(tributacoes) {
	let tributacoesConvetidas = [];
	tributacoes.forEach(tributacao => {
		tributacoesConvetidas.push({
			operacaoFiscal: { id: tributacao.operacaoFiscal.value },
			tributacaoMunicipal: { id: tributacao.tributacaoMunicipal.value },
		});
	});
	return tributacoesConvetidas;
}

export function converterCodigoServicoParaFormulario(codigoServico) {
	return {
		id: codigoServico.id,
		codigoServico: {
			label: codigoServico.codigoServico.codigo,
			value: codigoServico.codigoServico.id,
			registro: { ...codigoServico.codigoServico },
		},
		descricao: codigoServico.descricao,
		situacao: codigoServico.situacao,
		tributacoes: !!codigoServico.tributacoes?.length
			? converteTributacoesParaFormulario(codigoServico.tributacoes)
			: [],
	};
}

function converteTributacoesParaFormulario(tributacoes) {
	let tributacoesConvetidas = [];
	tributacoes.forEach(tributacao => {
		tributacoesConvetidas.push({
			operacaoFiscal: {
				label: tributacao.codigoOperacao + ' - ' + tributacao.descricaoOperacao,
				value: tributacao.idOperacao,
				situacao: tributacao.situacaoOperacao,
			},
			tributacaoMunicipal: {
				label: tributacao.codigoTributacao + ' - ' + tributacao.descricaoTributacao,
				value: tributacao.idTributacao,
				situacao: tributacao.situacaoTributacao,
			},
		});
	});
	return tributacoesConvetidas;
}
