/* eslint-disable no-unused-vars */
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { buscarDadosLoginLocalStorage, copiarObjeto, services } from 'Common';
import { ButtonExcluirTable, Grid, InputMoney, InputPercentage, NenhumRegistroEncontrado, Paginacao } from 'components';

import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { useContextTabelaPreco } from 'views/cadastros/TabelaPreco/Context';

import './Styles/index.css';
import { deleteTabelaPrecoProdutos, updateTabelaPrecoProdutos } from 'views/cadastros/TabelaPreco/Requests';

function ListaProdutos({ totalElements, setTotalElements, rows, setRows, page, setPage, cabecalhoTabelaPreco }) {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes } = useContextTabelaPreco();
	const decimaisPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	function addProdutoEdicao(produto) {
		const arrayEdicao = copiarObjeto(values.produtosEdicao);

		if (arrayEdicao?.length > 0) {
			const naoExisteNoArray = arrayEdicao?.filter((item) => item.id === produto.id).length === 0;

			if (naoExisteNoArray) {
				arrayEdicao.push(produto);
			} else {
				const index = arrayEdicao.findIndex((item) => item.id === produto.id);

				arrayEdicao[index] = produto;
			}
		} else {
			arrayEdicao.push(produto);
		}

		setFieldValue('produtosEdicao', arrayEdicao);
	}

	function handleChangePage(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function renderInput(row, index, field, isValor = false) {
		function onChangeInput(event) {
			const { value } = event.target;
			const produto = copiarObjeto(row);
			const produtos = copiarObjeto(values.produtos);
			let addEditarProduto = false;

			switch (field) {
				case 'custo': {
					produto.custo !== value ? (addEditarProduto = true) : (addEditarProduto = false);
					produto.custo = value ?? 0;
					break;
				}
				case 'lucroPercentual': {
					produto.lucroPercentual !== value ? (addEditarProduto = true) : (addEditarProduto = false);
					produto.lucroPercentual = value ?? 0;
					break;
				}
				case 'precoVenda': {
					produto.precoVenda !== value ? (addEditarProduto = true) : (addEditarProduto = false);
					produto.precoVenda = value ?? 0;
					break;
				}
				case 'comissao': {
					produto.comissao !== value ? (addEditarProduto = true) : (addEditarProduto = false);
					produto.comissao = value ?? 0;
					break;
				}
				case 'descontoMaximo': {
					produto.descontoMaximo !== value ? (addEditarProduto = true) : (addEditarProduto = false);
					produto.descontoMaximo = value ?? 0;
					break;
				}
				default: {
					break;
				}
			}

			produtos?.forEach((produtoArray, produtoIndex) => {
				if (produtoArray.id === produto.id) {
					produtos[produtoIndex] = produto;
				}
			});
			setFieldValue(`produtos`, produtos);

			if (addEditarProduto) {
				addProdutoEdicao(produto);
			}
		}

		function atualizarValoresAdicionais(field) {
			const produto = copiarObjeto(row);
			const hasCusto = produto.custo > 0;
			const hasLucroPercentual = produto.lucroPercentual > 0;
			const hasPrecoVenda = produto.precoVenda > 0;
			let addEditarProduto = false;

			switch (field) {
				case 'custo': {
					if (hasCusto > 0 && hasLucroPercentual) {
						const precoVenda = produto.custo + (produto.lucroPercentual / 100) * produto.custo;
						produto.precoVenda = precoVenda;
						addEditarProduto = true;
					}
					break;
				}
				case 'lucroPercentual': {
					if (hasCusto && hasLucroPercentual) {
						const precoVenda = produto.custo + (produto.lucroPercentual / 100) * produto.custo;
						produto.precoVenda = precoVenda;
						addEditarProduto = true;
					}
					break;
				}
				case 'precoVenda': {
					if (hasCusto && hasPrecoVenda) {
						const lucroPercentual = ((produto.precoVenda - produto.custo) / produto.custo) * 100;
						produto.lucroPercentual = lucroPercentual;
						addEditarProduto = true;
					}
					break;
				}
				default: {
					break;
				}
			}

			setFieldValue(`produtos[${index}]`, produto);
			if (addEditarProduto) {
				addProdutoEdicao(produto);
			}
		}

		if (isValor) {
			return (
				<span style={{ width: '9rem' }}>
					<InputMoney
						name={field}
						value={row[field]}
						onChange={onChangeInput}
						onBlur={() => atualizarValoresAdicionais(field)}
						allowNegative={false}
						decimalScale={decimaisPreco}
						{...informacoesPermissoes}
					/>
				</span>
			);
		} else {
			return (
				<span style={{ width: '9rem' }}>
					<InputPercentage
						name={field}
						value={row[field]}
						onChange={onChangeInput}
						onBlur={() => atualizarValoresAdicionais(field)}
						suffix=" %"
						placeholder="0 %"
						allowNegative={false}
						{...informacoesPermissoes}
					/>
				</span>
			);
		}
	}

	function renderOptions(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonExcluirTable onClick={() => handleClickExcluir(row)} podeExcluir={informacoesPermissoes.podeExcluir} />
			</div>
		);
	}

	function handleClickExcluir(row) {
		confirmarExclusao(() => excluirProduto(row));
	}

	async function excluirProduto(row) {
		const url = `${services.GESTOR}/v1/vendas/tabelas_preco/${cabecalhoTabelaPreco?.id}/produtos`;
		const arrayExclusao = copiarObjeto(values.produtosExclusao);
		const arrayTemp = values.produtos?.filter((produto) => produto.id !== row.id);

		arrayExclusao.push(row);

		if (values.produtosEdicao?.length > 0) {
			await updateTabelaPrecoProdutos(`${url}/alterar`, values.produtosEdicao, () => {
				setFieldValue('produtosEdicao', []);
			});
		}
		if (arrayExclusao.length > 0) {
			await deleteTabelaPrecoProdutos(`${url}/excluir`, arrayExclusao, () => {
				setFieldValue('produtosExclusao', []);
			});
		}

		setFieldValue('produtos', arrayTemp);
		setTotalElements(totalElements - 1);
	}

	return (
		<Grid className="step-tabela-preco-produtos">
			<DataTable
				className="table"
				rowClassName="table-row"
				cellClassName="tabela-preco-produtos table-row-cell"
				responsive
				value={values.produtos}
				emptyMessage={<NenhumRegistroEncontrado />}
				style={{ width: '100%', marginTop: '12px' }}
			>
				<Column field="produtoCodigo" header="Código" body={(row) => row.produto?.codigo} style={{ width: '6rem' }} />
				<Column field="produtoNome" header="Nome" body={(row) => row.produto?.nome} />
				<Column
					header="Custo"
					field="custo"
					body={(row, index) => renderInput(row, index.rowIndex, 'custo', true)}
					style={{ width: '9rem' }}
				/>
				<Column
					header="Lucro %"
					field="lucroPercentual"
					body={(row, index) => renderInput(row, index.rowIndex, 'lucroPercentual')}
					style={{ width: '9rem' }}
				/>
				<Column
					header="Preço de venda"
					field="precoVenda"
					body={(row, index) => renderInput(row, index.rowIndex, 'precoVenda', true)}
					style={{ width: '9rem' }}
				/>
				<Column
					header="Comissão %"
					field="comissaoPercentual"
					body={(row, index) => renderInput(row, index.rowIndex, 'comissao')}
					style={{ width: '9rem' }}
				/>
				<Column
					header="Desc. máx %"
					field="descontoMaximoPercentual"
					body={(row, index) => renderInput(row, index.rowIndex, 'descontoMaximo')}
					style={{ width: '9rem' }}
				/>
				<Column className="step-listagem-acoes" body={renderOptions} header="Ações" style={{ width: '3rem' }} />
			</DataTable>
			<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={handleChangePage} />
		</Grid>
	);
}

export { ListaProdutos };
