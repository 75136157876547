export function converterCategoriaParaFormulario(values) {
	return {
		...values,
		categoriaSuperior: converterCategoriaSuperiorParaFormulario(values),
	};
}

function converterCategoriaSuperiorParaFormulario(values) {
	if (!values.categoriaSuperior) {
		return null;
	}
	return {
		label: values.categoriaSuperior.nome,
		value: values.categoriaSuperior.id,
		registro: values.categoriaSuperior,
	};
}

export function converterCategoriaParaApi(values) {
	return {
		...values,
		aparencia: values.aparencia,
		categoriaSuperior: values.categoriaSuperior ? { id: values.categoriaSuperior.value } : null,
	};
}
