import { Field, useFormikContext } from 'formik';

import {
	ButtonAdicionarItem,
	Card,
	Col,
	Divider,
	Dropdown,
	Grid,
	NenhumRegistroEncontrado,
	SingleSelectCategoria,
	Subtitle,
} from 'components';
import { copiarObjeto, gerarUUID, services } from 'Common';

import { useContextCondicaoPagamento } from 'views/cadastros/CondicaoPagamento/Context';
import {
	CONDICAO_PAGAMENTO_TAXA_TIPO,
	CONDICAO_PAGAMENTO_TAXA_TIPO_OPTIONS,
	CONDICAO_PAGAMENTO_TIPO,
} from 'views/cadastros/CondicaoPagamento/Util/constantes';
import { AlteracaoPercentualOuValorPorQuantidade } from './components/AlteracaoPercentualOuValorPorQuantidade';

function Taxas() {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, isMobile } = useContextCondicaoPagamento();

	function onChangeTaxaTipoAplicado(value) {
		setFieldValue('taxaTipoAplicado', value);

		if (value === CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL) {
			const taxas = values.taxas ? values.taxas?.map((taxa) => ({ ...taxa, valorAplicado: 0 })) : [];
			setFieldValue('taxas', taxas);
		} else if (value === CONDICAO_PAGAMENTO_TAXA_TIPO.VALOR) {
			const taxas = values.taxas ? values.taxas?.map((taxa) => ({ ...taxa, percentualAplicado: 0 })) : [];
			setFieldValue('taxas', taxas);
		}
	}

	function deleteItem(index) {
		const arrayTemp = copiarObjeto(values.taxas);
		arrayTemp?.splice(index, 1);

		arrayTemp.forEach((_, index) => {
			arrayTemp[index].item = index + 1;
		});

		setFieldValue('taxas', arrayTemp);
	}

	function addItem() {
		const arrayTemp = copiarObjeto(values.taxas);
		const novaTaxa = {
			id: null,
			idTemporario: gerarUUID(),
			item: values.taxas.length + 1,
			valorInicial: null,
			valorFinal: null,
			percentualAplicado: 0,
			valorAplicado: 0,
		};

		if (values.tipo === CONDICAO_PAGAMENTO_TIPO.A_VISTA) {
			novaTaxa.valorInicial = 1;
			novaTaxa.valorFinal = 1;
		}

		arrayTemp?.push(novaTaxa);

		setFieldValue('taxas', arrayTemp);
	}

	return (
		<>
			<Grid>
				<Subtitle subtitle="Taxas" style={{ margin: '1em 8px 0 0.5em' }} />
			</Grid>
			<Card>
				<Grid>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						label="Tipo de taxa aplicada"
						name="taxaTipoAplicado"
						value={values.taxaTipoAplicado}
						onChange={(event) => onChangeTaxaTipoAplicado(event?.value)}
						showClear={false}
						options={CONDICAO_PAGAMENTO_TAXA_TIPO_OPTIONS}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="8"
						lg="8"
						xl="8"
						name="taxaCategoria"
						label="Categoria taxa"
						component={SingleSelectCategoria}
						style={{ flexDirection: 'row', width: '100%' }}
						value={values.taxaCategoria}
						onChange={(e) => setFieldValue('taxaCategoria', e)}
						url={`${services.GESTOR}/v1/condicoes_pagamento/relacoes/categorias`}
					/>
				</Grid>

				<Divider styleLine={{ color: '#9E9E9E' }} styleContainer={{ margin: '12px 0px' }} />
				<div style={{ marginLeft: `${isMobile ? '0px' : '36px'}` }}>
					{values.taxas?.length > 0 ? (
						values.taxas?.map((taxa, index) => (
							<AlteracaoPercentualOuValorPorQuantidade
								key={`${taxa.idTemporario || taxa.id}`}
								isAVista={values.tipo === CONDICAO_PAGAMENTO_TIPO.A_VISTA}
								isValor={values.taxaTipoAplicado === CONDICAO_PAGAMENTO_TAXA_TIPO.VALOR}
								taxa={taxa}
								indexTaxa={index}
								quantidadeRegistros={values.taxas?.length}
								onChangeValorInicial={(e) => {
									setFieldValue(`taxas[${index}].valorInicial`, e);
								}}
								onChangeValorFinal={(e) => {
									setFieldValue(`taxas[${index}].valorFinal`, e);
								}}
								onChangePercentualAplicado={(e) => {
									setFieldValue(`taxas[${index}].percentualAplicado`, e);
								}}
								onChangeValorAplicado={(e) => {
									setFieldValue(`taxas[${index}].valorAplicado`, e);
								}}
								excluirItem={() => deleteItem(index)}
								quantidadeMinima={values.parcelaMinima}
								quantidadeMaxima={values.parcelaMaxima}
							/>
						))
					) : (
						<NenhumRegistroEncontrado />
					)}
				</div>
				<Divider styleLine={{ color: '#9E9E9E' }} styleContainer={{ margin: '12px 0px' }} />

				<Grid>
					<Col sm="4" md="4" lg="4" xl="4">
						<ButtonAdicionarItem
							onClick={() => addItem()}
							{...informacoesPermissoes}
							disabled={values.tipo === CONDICAO_PAGAMENTO_TIPO.A_VISTA && values.taxas?.length >= 1}
						/>
					</Col>
				</Grid>
			</Card>
		</>
	);
}

export { Taxas };
