import { useFormikContext } from 'formik';
import { addMonths, formatISO, isValid, parseISO } from 'date-fns';

import { copiarObjeto } from 'Common';
import { Col, Grid, InputDate, InputDouble, validarValorNegativo } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';

import { ExcluirParcela } from './components/ExcluirParcela';

const styleNumeracaoDefault = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '16px',
	width: '30px',
};

function Parcelas({ parcela, index, excluirDisabled, errors, canTransmitir, setIndexRecalcularRateioParcela }) {
	const { informacoesPermissoes, isMobile } = useContextMDFe();
	const { values, setFieldValue } = useFormikContext();

	const exibirLabels = index === 0;

	function handleChangeVencimento(event) {
		let parcelasTemp = copiarObjeto(values.prazos);

		if (index === 0 && isValid(parseISO(event.target.value))) {
			parcelasTemp = parcelasTemp.map((parcela, index) => ({
				...parcela,
				vencimento: formatISO(addMonths(parseISO(event.target.value), index)),
			}));
		} else {
			parcelasTemp[index] = {
				...parcelasTemp[index],
				vencimento: event.target.value,
			};
		}
		setFieldValue('prazos', parcelasTemp);
	}

	function handleChangeValor(event) {
		const parcelasTemp = copiarObjeto(values.prazos);

		if (parcelasTemp[index].valor !== event.target.value) {
			parcelasTemp[index].valor = validarValorNegativo(event.target.value);

			setFieldValue('prazos', parcelasTemp);
			setIndexRecalcularRateioParcela(index);
		}
	}

	return (
		<Grid
			style={{
				margin: '0px -15px',
				paddingBottom: errors ? '10px' : null,
				alignItems: 'center',
			}}
		>
			<Col
				style={{
					...styleNumeracaoDefault,
					padding: '2px 0px 0px 10px',
					margin: exibirLabels ? '10px 0px 0px 0px' : '0px 0px 4px 0px',
				}}
			>
				{`${index + 1}ª `}
			</Col>
			<Col col="12" sm="12" md="4" lg="4" xl="4">
				<InputDate
					label={exibirLabels ? 'Vencimento' : ''}
					helpMessage={exibirLabels ? 'Data do vencimento desta parcela' : ''}
					name="pagamento.parcela.vencimento"
					onChange={handleChangeVencimento}
					value={parcela.vencimento}
					errors={errors && errors.vencimento}
					useFormTouched={false}
					touched
					obrigatorio={exibirLabels}
					disabled={!canTransmitir}
					{...informacoesPermissoes}
				/>
			</Col>
			<Col col="12" sm="12" md="4" lg="4" xl="4">
				<InputDouble
					colStyle={{
						padding: '0px 5px',
						marginBottom: errors && errors.valor ? '12px' : undefined,
					}}
					label={exibirLabels ? 'Valor' : ''}
					name="valor"
					value={parcela.valor}
					onChange={handleChangeValor}
					placeholder="R$ 0,00"
					prefix="R$ "
					size={13}
					helpMessage={exibirLabels && !isMobile ? 'Valor da parcela' : ''}
					errors={errors && errors.valor}
					useFormTouched={false}
					touched
					allowNegative={false}
					obrigatorio={exibirLabels}
					disabled={!canTransmitir}
					{...informacoesPermissoes}
				/>
			</Col>
			<ExcluirParcela exibirLabels={exibirLabels} excluirDisabled={excluirDisabled} index={index} />
		</Grid>
	);
}

export { Parcelas };
