import { useEffect, useRef, useState } from 'react';
import { Field } from 'formik';
import { format, isBefore, parseISO, startOfDay } from 'date-fns';
import { Menu } from 'primereact/menu';
import { OverlayPanel } from 'primereact/overlaypanel';
import { services } from '../../../../../Common';
import { Button, Col, notify, SingleSelectConta, ToastTypes } from '../../../../../components';

import {
	asyncPostGerarCobrancas,
	asyncPostDescartarCobrancas,	
	asyncPostImprimirCobrancas,
} from '../../Requests';
import { validarFormulario } from '../../../../Util';
import { getIdCobrancasSelected } from '../../Util/functions';

function ActionButtons(props) {
	const {
		podeInserir,
		podeEditar,
		podeVisualizar,
		values,
		isMobile,
		setExibirModalContaReceber,
		setExibirModalEnviarPorEmail,
		setFieldValue,
		setExibirLoading,
		setMessageLoading,
		setDadosPessoaParaEmail,
		pesquisar,
		propsForm,
		buscarContaFavorita,
	} = props;

	const [menu, setMenu] = useState(null);
	const [optionContaConvenios, setOptionContaConvenios] = useState([]);
	const panelEmissaoPixCobranca = useRef(null);
	const itensMenu = montarItensMenu();
	const [cobrancasSelecionadas, setCobrancasSelecionadas] = useState([]);

	useEffect(() => {
		if (values.selectedCobrancas?.length > 0) {
			const matchedRegistros = values.registros?.filter(registro => {
				return cobrancasSelecionadas.some(cobranca => cobranca.id === registro.id);
			});
			matchedRegistros
				? setFieldValue('selectedCobrancas', matchedRegistros)
				: setFieldValue('selectedCobrancas', []);
		}
	}, [values.registros]);

	function montarItensMenu() {
		let itens = [];

		itens.push({
			label: 'Gerar conta a receber',
			icon: 'pi pi-plus-circle',
			command: () => setExibirModalContaReceber(true),
			disabled: !podeInserir,
		});

		itens.push({
			label: 'Descartar cobrança(s)',
			icon: 'pi pi-minus-circle',
			command: () => onClickDescartarCobrancas(true),
			disabled: !podeEditar,
		});

		return itens;
	}
	useEffect(() => {
		mountConveniosOptions(values.conta?.registro?.convenios ?? []);
	}, [values.conta]);

	function openEmissaoPixCobranca(e) {
		panelEmissaoPixCobranca.current?.toggle(e);

		setTimeout(() => {
			document.getElementById('select-conta-emissao-cobrancas')?.getElementsByTagName('input')[0]?.focus();
		}, 250);
	}

	function handleChangeConta(e) {
		setFieldValue('conta', e);

		mountConveniosOptions(e?.registro?.convenios ?? []);
	}

	async function mountConveniosOptions(convenios) {
		let convenioOptions = [];
		let primeiroConvenio = {};

		if (convenios?.length > 0) {
			convenioOptions = await convenios
				?.filter(convenio => convenio.situacao === 'ATIVA')
				?.map(convenio => {
					return {
						label: `${convenio.descricao} - ${convenio.numero}`,
						registro: convenio,
						value: convenio.id,
					};
				})
				?.sort((a, b) => a.label.localeCompare(b.label));

			if (convenioOptions.length > 0) {
				primeiroConvenio = {
					label: `${convenioOptions[0]?.registro?.descricao} - ${convenioOptions[0]?.registro?.numero}`,
					registro: convenioOptions[0]?.registro,
					value: convenioOptions[0]?.registro?.id,
				};
			}
		}

		setOptionContaConvenios(convenioOptions);
		setFieldValue('convenio', Object.keys(primeiroConvenio).length > 0 ? primeiroConvenio : null);
	}

	function handleChangeConvenio(e) {
		Object.keys(e)?.length > 0 ? setFieldValue('convenio', e) : setFieldValue('convenio', {});
	}

	function allHasPixGerado() {
		let result = {
			value: true,
			countNotHas: 0,
		};

		if (values.selectedCobrancas.length > 0) {
			values.selectedCobrancas.forEach(registro => {
				if (!registro?.pix) {
					result.value = false;
					result.countNotHas++;
				}
			});
		}

		return result;
	}

	function allNotHasPixGerado() {
		let result = {
			value: true,
			countNotHas: 0,
		};

		if (values.selectedCobrancas.length > 0) {
			values.selectedCobrancas.forEach(cobranca => {
				if (cobranca?.pix && !['EXPIRADO', 'PENDENTE'].includes(cobranca.pix.status)) {
					result.value = false;
					result.countNotHas++;
				}
			});
		}

		return result;
	}

	function allNotHasPixGeradoWithPendente() {
		let result = {
			value: true,
			countNotHas: 0,
		};

		if (values.selectedCobrancas.length > 0) {
			values.selectedCobrancas.forEach(registro => {
				if (!['EXPIRADO', 'PENDENTE'].includes(registro?.pix?.status)) {
					result.value = false;
					result.countNotHas++;
				}
			});
		}

		return result;
	}

	async function emitirCobrancas() {
		await props.handleSubmit();

		if (await validarFormulario(propsForm)) {
			const existePixsGerado = allNotHasPixGerado();

			if (existePixsGerado.countNotHas > 0) {
				notify('Existem registros selecionados com pix já gerados', ToastTypes.ERROR);
				return;
			}

			const cobrancas = getIdCobrancasSelected(values.selectedCobrancas);

			if (cobrancas.length > 0) {
				setExibirLoading(true);
				setMessageLoading(`Gerando ${cobrancas.length === 1 ? 'cobrança' : 'cobranças'}...`);

				asyncPostGerarCobrancas(
					values.conta.value,
					cobrancas,
					async ({ data: pdf }) => {
						let arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						let arquivoURL = URL.createObjectURL(arquivoPDF);
						let cobranca = window.open(arquivoURL);
						if (cobranca) {
							cobranca.onload = () => {
								setTimeout(() => {
									cobranca.document.title = cobrancas.length === 1 ? 'Cobrança' : 'Cobranças';
								}, 250);
							};
						}
						setExibirLoading(false);

						await pesquisar();
						setCobrancasSelecionadas(values.selectedCobrancas);
						document
							.getElementById('overlayPanel-emitir-cobrancas')
							?.getElementsByClassName('p-overlaypanel-close p-link')[0]
							?.click();
					},
					() => {
						setCobrancasSelecionadas(values.selectedCobrancas);						
						document
							.getElementById('overlayPanel-emitir-cobrancas')
							?.getElementsByClassName('p-overlaypanel-close p-link')[0]
							?.click();
						setExibirLoading(false);
					}
				);
			}
		} else {
			validarEmissaoCobrancas();
		}
	}

	function validarEmissaoCobrancas() {
		if (values.selectedCobrancas) {
			let errorPessoa = 0;
			let errorVencimento = 0;
			let errorVencimentoMenorQueEmissao = 0;
			let erroVencimentoMenorQueHoje = 0;

			if (values.selectedCobrancas.length > 0) {
				values.selectedCobrancas?.forEach(cobranca => {
					if (!cobranca.pessoa) {
						errorPessoa++;
					}
					if (!cobranca.vencimento) {
						errorVencimento++;
					}
					if (isBefore(parseISO(cobranca.vencimento), parseISO(cobranca.emissao))) {
						errorVencimentoMenorQueEmissao++;
					}
					if (isBefore(parseISO(cobranca.vencimento), startOfDay(new Date()))) {
						erroVencimentoMenorQueHoje++;
					}
				});

				if (errorPessoa > 0) {
					notify(
						errorPessoa === 1
							? 'Registro selecionado sem pessoa vinculada'
							: 'Existem registros selecionados sem pessoa vinculada',
						ToastTypes.ERROR
					);
				} else if (errorVencimento > 0) {
					notify(
						errorVencimento === 1
							? 'Registro selecionado sem data de vencimento'
							: 'Existem registros selecionados sem data de vencimento',
						ToastTypes.ERROR
					);
				} else if (errorVencimentoMenorQueEmissao > 0) {
					notify(
						errorVencimentoMenorQueEmissao === 1
							? 'Registro selecionado com data de vencimento menor que a emissão'
							: 'Existem registros selecionados com data de vencimento menor que a emissão',
						ToastTypes.ERROR
					);
				} else if (erroVencimentoMenorQueHoje > 0) {
					notify(
						erroVencimentoMenorQueHoje === 1
							? 'Registro selecionado com data de vencimento menor que hoje'
							: 'Existem registros selecionados com data de vencimento menor que hoje',
						ToastTypes.ERROR
					);
				}
			} else {
				notify('Nenhum registro selecionado para emissão', ToastTypes.ERROR);
			}
		}
	}

	function existeClientesDiferentes() {
		let clientesPresentes = [];

		values.selectedCobrancas?.forEach(cobranca => {
			if (!clientesPresentes?.includes(cobranca.pessoaCodigo)) {
				clientesPresentes.push(cobranca.pessoaCodigo);
			}
		});

		return clientesPresentes.length > 1;
	}

	function onClickEnviarEmail() {
		const cobrancas = getIdCobrancasSelected(values.selectedCobrancas);

		if (cobrancas.length > 0) {
			const pixGerado = allHasPixGerado();

			if (!existeClientesDiferentes()) {
				if (pixGerado.value) {
					setDadosPessoaParaEmail({
						pessoaNome: values.selectedCobrancas[0]?.pessoa,
						pessoaCodigo: values.selectedCobrancas[0]?.pessoaCodigo,
						pessoaEmail: values.selectedCobrancas[0]?.pessoaEmail,
					});
					setExibirModalEnviarPorEmail(true);
				} else {
					notify(
						pixGerado.countNotHas === 1
							? 'Registro selecionado sem pix gerado'
							: 'Existem registros selecionados sem pix gerado',
						ToastTypes.ERROR
					);
				}
			} else {
				notify('Registros selecionados com clientes diferentes', ToastTypes.ERROR);
			}
		} else {
			notify('Selecione ao menos um registro', ToastTypes.ERROR);
		}
	}

	async function onClickImprimir() {
		const cobrancas = getIdCobrancasSelected(values.selectedCobrancas);

		if (cobrancas.length > 0) {
			const cobrancasGeradas = allHasPixGerado();

			if (cobrancasGeradas.value) {
				setExibirLoading(true);
				setMessageLoading(`Gerando ${cobrancas.length === 1 ? 'impressão' : 'impressões'}...`);

				await asyncPostImprimirCobrancas(
					cobrancas,
					({ data: pdf }) => {
						let arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						let arquivoURL = URL.createObjectURL(arquivoPDF);
						let impresso = window.open(arquivoURL);
						if (impresso) {
							impresso.onload = () => {
								setTimeout(() => {
									impresso.document.title = cobrancas.length === 1 ? 'Cobrança' : 'Cobranças';
								}, 250);
							};
						}
						setExibirLoading(false);
					},
					() => setExibirLoading(false)
				);
			} else {
				notify(
					cobrancasGeradas.countNotHas === 1
						? 'Registro selecionado sem pix gerado'
						: 'Existem registros selecionados sem pix gerado',
					ToastTypes.ERROR
				);
			}
		} else {
			notify('Selecione ao menos um registro', ToastTypes.ERROR);
		}
	}

	async function onClickDescartarCobrancas() {
		const cobrancas = getIdCobrancasSelected(values.selectedCobrancas);

		if (cobrancas.length > 0) {
			const pixGerado = allHasPixGerado();
			const pixNaoRecebido = allNotHasPixGeradoWithPendente();

			if (pixGerado.value) {
				if (pixNaoRecebido.value) {
					setExibirLoading(true);
					setMessageLoading(`Descartando ${cobrancas.length === 1 ? 'cobrança' : 'cobranças'}...`);

					await asyncPostDescartarCobrancas(
						cobrancas,
						async () => {
							await pesquisar();
							setExibirLoading(false);
						},
						() => setExibirLoading(false)
					);
				} else {
					notify(
						pixNaoRecebido.countNotHas === 1
							? 'Cobrança selecionada com situação diferente de expirado ou gerado'
							: 'Existem cobranças selecionadas com situação diferente de expirado ou gerado',
						ToastTypes.ERROR
					);
				}
			} else {
				notify(
					pixGerado.countNotHas === 1
						? 'Registro selecionado sem pix gerado'
						: 'Existem registros selecionados sem pix gerado',
					ToastTypes.ERROR
				);
			}
		} else {
			notify('Selecione ao menos um registro', ToastTypes.ERROR);
		}
	}

	return (
		<>
			<>
				<Button
					className="step-listagem-novo"
					label="Gerar cobranças"
					color="success"
					icon="fa fa-plus"
					disabled={!podeEditar}
					onClick={e => openEmissaoPixCobranca(e)}
				/>
				<Button
					className="step-listagem-novo"
					label="Imprimir cobranças"
					color="info"
					icon="fa fa-print"
					disabled={!podeVisualizar}
					onClick={onClickImprimir}
				/>
				<Button
					className="step-listagem-novo"
					label={isMobile ? 'Enviar e-mail' : 'Enviar por e-mail'}
					color="info"
					icon="fa fa-paper-plane"
					disabled={!podeVisualizar}
					onClick={onClickEnviarEmail}
				/>
				<Button
					className="p-button-primary"
					label="Opções"
					icon="fa fa-list"
					style={{ margin: '5px' }}
					aria-controls="popup_menu"
					aria-haspopup={true}
					onClick={event => {
						menu.toggle(event);
					}}
				/>
			</>
			<Menu model={itensMenu} popup={true} style={{ width: '220px' }} ref={elemento => setMenu(elemento)} />
			<OverlayPanel
				dismissable={false}
				ref={panelEmissaoPixCobranca}
				id="overlayPanel-emitir-cobrancas"
				style={{
					width: isMobile ? '90%' : '100%',
					maxWidth: '30.5rem',
					marginLeft: isMobile ? '1rem' : null,
					marginTop: '0px',
				}}
				onHide={() => {
					buscarContaFavorita();
				}}
				showCloseIcon
			>
				<div className="p-inputgroup" style={{ flexDirection: 'column' }}>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						name="conta"
						label="Conta"
						component={SingleSelectConta}
						style={{ flexDirection: 'row', width: '100%' }}
						isClearable={false}
						obrigatorio
						onlyRecebePix
						value={values.conta}
						onChange={e => handleChangeConta(e)}
						url={`${services.GESTOR}/v1/contas_receber/relacoes/contas`}
					/>				
					<Col sm="12" md="12" lg="12" xl="12">
						<Field
							style={{ width: '100%' }}
							label="Gerar pix cobrança"
							color="success"
							component={Button}
							disabled={!podeInserir}
							onClick={() => emitirCobrancas()}
						/>
					</Col>
				</div>
			</OverlayPanel>
		</>
	);
}

export default ActionButtons;
