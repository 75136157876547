import { setNullForIdProperties } from "Common/Array";

function converterEtiquetaParaFormulario(etiquetaApi) {
  return {
    id: etiquetaApi.id,
    codigo: etiquetaApi.codigo,
    descricao: etiquetaApi.descricao,
    tipoPapel: etiquetaApi.tipoPapel,

    alturaPagina: etiquetaApi.alturaPagina,
    larguraPagina: etiquetaApi.larguraPagina,
    linhasPorPagina: etiquetaApi.linhasPorPagina,
    etiquetasPorLinha: etiquetaApi.etiquetasPorLinha,
    espacamentoColuna: etiquetaApi.espacamentoColuna,
    espacamentoLinha: etiquetaApi.espacamentoLinha,
    margemSuperior: etiquetaApi.margemSuperior,
    margemInferior: etiquetaApi.margemInferior,
    margemDireita: etiquetaApi.margemDireita,
    margemEsquerda: etiquetaApi.margemEsquerda,

    alturaEtiqueta: etiquetaApi.alturaEtiqueta,
    larguraEtiqueta: etiquetaApi.larguraEtiqueta,
    exibirContornoCampos: etiquetaApi.exibirContornoCampos,
    exibirContornoEtiqueta: etiquetaApi.exibirContornoEtiqueta,

    campos: converterCamposEtiquetaParaFormulario(etiquetaApi?.campos),
  };
}

function converterCamposEtiquetaParaFormulario(camposApi) {
  const produtos = camposApi?.map((campos) => {
    return {
      id: campos.id,
      campo: converterObjectParaSelect(
        campos.campo,
        `${campos.campo?.descricao}`
      ),
      textoLivre: campos.textoLivre ?? null,
      posicaoVertical: campos.posicaoTopo,
      posicaoHorizontal: campos.posicaoEsquerda,
      altura: campos.altura,
      largura: campos.largura,
      tamanhoFonte: campos.tamanhoFonte,
      rotacao: campos.rotacao ?? 0,
      negrito: campos.negrito,
      italico: campos.italico,
      sublinhado: campos.sublinhado,
      quebraLinha: campos.quebraLinha,
      alinhamento: campos.alinhamento,
      tamanhoAutomatico: campos.tamanhoAutomatico,
      tipoCodigoDeBarra: campos.tipoBarras,
      zoom: campos.zoom ?? 0,
      mostrarCodigoNaBarra: campos.mostrarCodigoNaBarra,
    };
  });

  return sortCampos(produtos);
}

function converterEtiquetaParaApi(etiqueta) {
  return {
    id: etiqueta.id,
    codigo: etiqueta.codigo,
    descricao: etiqueta.descricao,
    tipoPapel: etiqueta.tipoPapel,

    alturaPagina: etiqueta.alturaPagina,
    larguraPagina: etiqueta.larguraPagina,
    linhasPorPagina: etiqueta.linhasPorPagina,
    etiquetasPorLinha: etiqueta.etiquetasPorLinha,
    espacamentoColuna: etiqueta.espacamentoColuna,
    espacamentoLinha: etiqueta.espacamentoLinha,
    margemSuperior: etiqueta.margemSuperior,
    margemInferior: etiqueta.margemInferior,
    margemDireita: etiqueta.margemDireita,
    margemEsquerda: etiqueta.margemEsquerda,

    alturaEtiqueta: etiqueta.alturaEtiqueta,
    larguraEtiqueta: etiqueta.larguraEtiqueta,
    exibirContornoCampos: etiqueta.exibirContornoCampos,
    exibirContornoEtiqueta: etiqueta.exibirContornoEtiqueta,

    campos: converterCamposEtiquetaParaApi(etiqueta?.campos),
  };
}
function converterCamposEtiquetaParaApi(camposFormulario) {
  const produtos = camposFormulario?.map((campos) => {
    return {
      id: campos.id,
      campo: converterSelectParaApi(campos.campo),
      textoLivre: campos.textoLivre ?? null,
      posicaoTopo: campos.posicaoVertical,
      posicaoEsquerda: campos.posicaoHorizontal,
      altura: campos.altura,
      largura: campos.largura,
      tamanhoFonte: campos.tamanhoFonte,
      rotacao: campos.rotacao ?? 0,
      negrito: campos.negrito,
      italico: campos.italico,
      sublinhado: campos.sublinhado,
      quebraLinha: campos.quebraLinha,
      alinhamento: campos.alinhamento,
      tipoBarras: campos.tipoCodigoDeBarra,
      tamanhoAutomatico: campos.tamanhoAutomatico,
      zoom: campos.zoom ?? 0,
      mostrarCodigoNaBarra: campos.mostrarCodigoNaBarra,
    };
  });

  return produtos;
}

function converterObjectParaSelect(object, label) {
  return object
    ? {
        value: object.id ?? null,
        label: label ?? object.descricao ?? object.nome,
        registro: object,
      }
    : null;
}

function converterSelectParaApi(object) {
  return object
    ? {
        id:
          object.value ??
          object.id ??
          object.registro?.id ??
          object.registro?.value,
      }
    : null;
}

function converterEtiquetaParaNovoRegistro(values) {
  let convertedData = converterEtiquetaParaFormulario(values);
  setNullForIdProperties(convertedData, ["campo"]);
  convertedData.codigo = null;
  return convertedData;
}

function sortCampos(campos) {
  return Array.from(campos).sort((a, b) => {
    if (a.posicaoVertical < b.posicaoVertical) {
      return -1;
    }
    if (a.posicaoVertical > b.posicaoVertical) {
      return 1;
    }

    if (a.posicaoHorizontal < b.posicaoHorizontal) {
      return -1;
    }
    if (a.posicaoHorizontal > b.posicaoHorizontal) {
      return 1;
    }

    return 0;
  });
}

export {
  converterEtiquetaParaFormulario,
  converterEtiquetaParaApi,
  converterEtiquetaParaNovoRegistro,
};
