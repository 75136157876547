import { Field, useFormikContext } from 'formik';

import {
	Grid,
	InputField,
	SingleSelectMunicipio,
	SingleSelectSetor,
	SingleSelectVendedorListagem,
	TextArea,
} from 'components';
import { copiarObjeto, services } from 'Common';

import { useContextNFSe } from 'views/fiscal/servicos/NFSe/Context';
import { Situacao } from 'views/fiscal/servicos/NFSe/Util/constantes';

function InformacoesComplementares({
	informacoesPermissoes,
	utilizaTabelaPreco,
	buscarTabelaPreco,
	buscarMunicipioIdPessoa,
}) {
	const { disableFields } = useContextNFSe();
	const { values, errors, setFieldValue } = useFormikContext();

	const disableRPS = values.situacao !== Situacao.REJEITADO;
	
	async function onChangeVendedor(event) {
		setFieldValue('informacoesComplementares.vendedor', event);

		if (utilizaTabelaPreco) {
			const municipioId = buscarMunicipioIdPessoa(values.tomador);
			await buscarTabelaPreco(
				{
					vendedorId: event?.value,
					clienteId: values.tomador?.value,
					municipioId: municipioId,
				},
				({ data: tabelaPrecoApi }) => {
					setFieldValue('informacoesComplementares.tabelaPreco', {
						label: `${tabelaPrecoApi.codigo} - ${tabelaPrecoApi.nome}`,
						registro: tabelaPrecoApi,
						value: tabelaPrecoApi.id,
					});
				}
			);
			if (!event.registro?.descontoMaximoSuperior){
				const servicos = copiarObjeto(values.servicos);		
				servicos.forEach((servico, index) => { 					
						servicos[index].descontoMaximoVendedor = event.registro?.descontoMaximo
						if (servico.descontoMaximoTabela < event.registro?.descontoMaximo){
							servicos[index].descontoMaximoPercentual = servico.descontoMaximoTabela
						}else{
							servicos[index].descontoMaximoPercentual = event.registro?.descontoMaximo;
						}
					}	
				);
				setFieldValue('servicos', servicos);
			}
		}
	}

	return (
		<Grid>
			<Field
				sm="12"
				md={utilizaTabelaPreco ? '12' : '6'}
				lg={utilizaTabelaPreco ? '4' : '6'}
				xl={utilizaTabelaPreco ? '4' : '6'}
				component={SingleSelectVendedorListagem}
				name="informacoesComplementares.vendedor"
				label="Vendedor responsável"
				obrigatorio
				disabled={disableFields}
				isClearable={false}
				url={`${services.GESTOR}/v1/nfses/relacoes/vendedores`}
				value={values.informacoesComplementares.vendedor}
				onChange={(event) => onChangeVendedor(event)}
				esconderBotao
				errors={errors.informacoesComplementares?.vendedor}
				touched
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md={utilizaTabelaPreco ? '12' : '6'}
				lg={utilizaTabelaPreco ? '4' : '6'}
				xl={utilizaTabelaPreco ? '4' : '6'}
				component={SingleSelectSetor}
				name="setor"
				label="Setor"
				obrigatorio
				url={`${services.GESTOR}/v1/nfses/relacoes/setores`}
				value={values.informacoesComplementares.setor}
				onChange={(e) => setFieldValue('informacoesComplementares.setor', e)}
				esconderBotao
				isClearable={false}
				disabled={disableFields}
				id="selectSetor"
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="4"
				xl="4"
				component={InputField}
				name="tabelaPreco"
				label="Tabela preço aplicada"
				disabled
				value={values.informacoesComplementares?.tabelaPreco?.label}
				esconderBotao
				isClearable={false}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={TextArea}
				placeholder="Escreva informações que sejam importantes para você e seu cliente"
				label="Informações complementares"
				name="informacaoComplementar"
				maxLength={4096}
				disabled={disableFields}
				rows={4}
				value={values.informacoesComplementares.informacaoComplementar}
				onChange={(event) => setFieldValue('informacoesComplementares.informacaoComplementar', event.target.value)}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={TextArea}
				placeholder="Escreva observações para uso interno"
				label="Observação interna"
				name="observacaoInterna"
				maxLength={4096}
				disabled={disableFields}
				rows={4}
				value={values.informacoesComplementares.observacaoInterna}
				onChange={(event) => setFieldValue('informacoesComplementares.observacaoInterna', event.target.value)}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={SingleSelectMunicipio}
				obrigatorio
				label="Município de prestação de serviço"
				name="municipio"
				value={values.informacoesComplementares.municipio}
				onChange={(event) => setFieldValue('informacoesComplementares.municipio', event)}
				isClearable={false}
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputField}
				name="numeroRPS"
				label="Número RPS"
				disabled={disableFields || disableRPS}
				value={values.informacoesComplementares.numeroRPS}
				onChange={(event) => setFieldValue('informacoesComplementares.numeroRPS', event.target.value)}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputField}
				name="loteRPS"
				label="Lote RPS"
				disabled={disableFields || disableRPS}
				value={values.informacoesComplementares.loteRPS}
				onChange={(event) => setFieldValue('informacoesComplementares.loteRPS', event.target.value)}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputField}
				name="protocolo"
				label="Protocolo"
				disabled={disableFields || disableRPS}
				value={values.informacoesComplementares.protocolo}
				onChange={(event) => setFieldValue('informacoesComplementares.protocolo', event.target.value)}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { InformacoesComplementares };
