import { useState } from 'react';

import { Modal, If } from 'components';

import AssinaturaEletronicaForm from '../Form';

export function ModalAssinatura(props) {
	const { visible, onHide, history, idDocumento } = props;
	const [hideBackground, setHideBackground] = useState(false);

	return (
		<Modal
			className={visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de documento"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1200px' }}
			showCloseIcon={false}
			closeOnEsc={false}
			hideBackground={hideBackground}
		>
			<If test={visible}>
				<AssinaturaEletronicaForm
					hideModal={onHide}
					isModal={true}
					history={history}
					idDocumento={idDocumento}
					setHideBackground={setHideBackground}
				/>
			</If>
		</Modal>
	);
}

export default ModalAssinatura;
