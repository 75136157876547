import { useEffect } from 'react';
import useStateCallback from '../../../Common/Hooks/useStateCallback';
import { buscarHiddenDeAcordoComAsPermissoes } from '../../../Common/Autorizacao/ManipulacaoDeComponentes';
import { renderizarValidacao } from '../../../Common/TratamentoDeErro/validacoesDeCampos';
import { aplicarPercentual } from '../../../views/Util';
import Col from '../../Col';
import If from '../../If';
import InputDouble from '../InputDouble';
import './Styles/index.css';

export const tipos = {
	VALOR: 'VALOR',
	PERCENTUAL: 'PERCENTUAL',
};

function InputSelectPercentualOrValor(props) {
	const {
		label,
		name,
		value,
		errors,
		colId,
		colClassName,
		col,
		sm,
		md,
		lg,
		xl,
		style,
		helpMessage,
		labelSize,
		obrigatorio,
		disabled,
		sizeValor,
		sizePercentual,
		restart,
		onRestart,
		informacoesPermissoes,
		maxValuePercental = 100,
	} = props;

	const [tipo, setTipo] = useStateCallback(tipos.VALOR);
	const [percentual, setPercentual] = useStateCallback(0);
	const [valor, setValor] = useStateCallback(0);

	const inputStyle = {
		width: 'calc(100% - 32px)',
		padding: '0px',
	};

	const alteraPercentualOuValor = {
		fontSize: '16px',
		height: '32px',
		width: '32px',
		borderRadius: '0 3px 3px 0',
	};

	const styleDiv = {
		alignItems: 'flex-end',
	};

	const styleInput = {
		borderRadius: '3px 0 0 3px',
	};

	useEffect(() => {
		if (props.tipo) {
			setTipo(props.tipo);
			setPercentual(props.value);
		}
		setValor(value ?? 0);
	}, []);

	useEffect(() => {
		if (restart && onRestart) {
			setTipo(tipos.VALOR);
			setPercentual(0);
			onRestart();
		}
	});

	useEffect(() => {
		if (valor !== value) {
			setValor(value ?? 0);
			if (!props.onChange) {
				setTipo(tipos.VALOR);
			}
		}
	}, [value]);

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden))
			return { display: 'none', ...props.colStyle };
		return props.colStyle;
	}

	function onChangeTipo(e) {
		if (tipo === tipos.VALOR) {
			setTipo(tipos.PERCENTUAL);
			if (typeof props.onChange === 'function') {
				props.onChange(tipos.PERCENTUAL, 0, 0);
			}
			if (typeof props.onBlur === 'function') {
				props.onBlur(tipos.PERCENTUAL, 0, 0);
			}
		} else {
			setTipo(tipos.VALOR);
			if (typeof props.onChange === 'function') {
				props.onChange(tipos.VALOR, 0, 0);
			}
			if (typeof props.onBlur === 'function') {
				props.onBlur(tipos.VALOR, 0, 0);
			}
		}
		setValor(0);
		setPercentual(0);
		setTimeout(() => {
			document.getElementById(`inputSelectPercentualOrValor-${name ? name : props.field?.name}`)?.select();
		}, 200);
	}

	function changeValor(e) {
		let valorTemp = e.target.value ?? 0;
		setValor(valorTemp);
		if (typeof props.onChange === 'function') {
			props.onChange(tipo, valorTemp, 0);
		}
	}

	function blurValor(e) {
		if (typeof props.onBlur === 'function') {
			props.onBlur(tipo, valor, 0);
		}
	}

	function changePercentual(e) {
		let valorTemp = aplicarPercentual(props.valueBase, e.target.value ?? 0);
		if (typeof props.onChange === 'function') {
			props.onChange(tipo, valorTemp, e.target.value ?? 0);
		}
		setPercentual(e.target.value ?? 0);
	}

	function blurPercentual(e) {
		let valorTemp = aplicarPercentual(props.valueBase, percentual);
		if (typeof props.onBlur === 'function') {
			props.onBlur(tipo, valorTemp, percentual);
		}
		setPercentual(percentual);
	}

	return (
		<Col id={colId} className={colClassName} col={col} sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			<div className="p-inputgroup" style={styleDiv}>
				<If test={tipo === tipos.VALOR}>
					<InputDouble
						id={`inputSelectPercentualOrValor-${name ? name : props.field?.name}`}
						colStyle={inputStyle}
						name={name}
						obrigatorio={obrigatorio}
						label={label}
						labelSize={labelSize}
						value={valor}
						onChange={changeValor}
						onBlur={blurValor}
						style={{ ...styleInput, ...style }}
						size={sizeValor}
						helpMessage={helpMessage}
						prefix="R$ "
						placeholder="R$ 0,00"
						touched
						allowNegative={false}
						disabled={disabled}
						{...informacoesPermissoes}
						autoComplete="off"
					/>
				</If>
				<If test={tipo === tipos.PERCENTUAL}>
					<InputDouble
						id={`inputSelectPercentualOrValor-${name ? name : props.field?.name}`}
						colStyle={inputStyle}
						name={name}
						label={label}
						obrigatorio={obrigatorio}
						labelSize={labelSize}
						value={percentual}
						maxValue={maxValuePercental}
						onChange={changePercentual}
						onBlur={blurPercentual}
						style={{ ...styleInput, ...style }}
						size={sizePercentual}
						helpMessage={helpMessage}
						suffix=" %"
						placeholder="0,00 %"
						touched
						allowNegative={false}
						disabled={disabled}
						{...informacoesPermissoes}
						autoComplete="off"
					/>
				</If>
				<span
					color="secondary"
					onClick={disabled ? null : onChangeTipo}
					style={style ? style : alteraPercentualOuValor}
					className="altera-percentual-ou-valor"
					title="Clique aqui para alternar modo de entrada"
				>
					<>
						{tipo === tipos.VALOR ? (
							<span className="altera-percentual-ou-valor-text">R$</span>
						) : (
							<span className="altera-percentual-ou-valor-text">%</span>
						)}
						<i className="fa fa-refresh" aria-hidden="true" />
					</>
				</span>
			</div>
			{renderizarValidacao(errors, errors ? true : false)}
		</Col>
	);
}

InputSelectPercentualOrValor.defaultProps = {
	sizePercentual: 6,
	sizeValor: 15,
};

export default InputSelectPercentualOrValor;
