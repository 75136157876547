import { Field } from 'formik';
import { Grid, InputMoney, Dropdown, Subtitle, SingleSelectTabelaPreco } from '../../../../../../../components';

import { PRECO_PRATICADO } from '../../../../Util/constantes';
import { helpPessoaForm } from '../../../Help';
import { buscarDadosLoginLocalStorage, copiarObjeto, gerarUUID, services } from 'Common';

export default function PessoaConfiguracoes(props) {
	const { values, setFieldValue, informacoesPermissoes, appendTo } = props;
	const { filialConectada } = buscarDadosLoginLocalStorage();

	const usaTabelaPreco = filialConectada.parametrosVendas?.utilizaTabelaPreco;
	const isDOCsDigitais = buscarDadosLoginLocalStorage().organizacao?.aplicacao === 'DOCS_DIGITAIS' ? true : false;

	function onChangeTabelaPreco(event) {
		const filiais = copiarObjeto(values.filiais) ?? [];

		if (filiais?.length > 0) {
			const indexFilialConectada = filiais?.findIndex(
				(filial) => filial.filial?.id === filialConectada.id || (!filial.id && filial.idTemporario)
			);

			setFieldValue(`pessoaFilialConectada.tabelaPreco`, event);
		} else {
			const novaInformacoesFilial = {
				id: null,
				idTemporario: gerarUUID(),
				filial: {
					id: filialConectada?.id,
					codigo: filialConectada?.codigo,
					nome: filialConectada?.nome,
					nomeApresentacao: filialConectada?.nomeApresentacao,
				},
				tabelaPreco: event ? event : null,
			};

			filiais.push(novaInformacoesFilial);
			setFieldValue(`pessoaFilialConectada`, novaInformacoesFilial);
		}
	}

	return (
		<div style={{ fontSize: '14px' }}>
			<Grid>
				<Subtitle subtitle="Configurações para venda" />

				<Field
					sm="12"
					md="6"
					lg="4"
					xl="4"
					component={Dropdown}
					label="Preço praticado"
					name="configPrecoPraticado"
					helpMessage={helpPessoaForm.configPrecoPraticado}
					onChange={(e) => {
						setFieldValue('configPrecoPraticado', e.value);
					}}
					options={PRECO_PRATICADO}
					value={values.configPrecoPraticado}
					showClear={false}
					disabled={!values.campoObrigatorioParaDocumentoFiscal}
					id="PessoasConfiguracoesSelectPrecoPraticado"
					menuPortalTarget={appendTo}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="2"
					xl="2"
					component={InputMoney}
					label="Limite de crédito"
					name="limiteCredito"
					size={12}
					allowNegative={false}
					value={values.limiteCredito}
					onChange={(e) => setFieldValue('limiteCredito', e.target.value)}
					disabled={!values.campoObrigatorioParaDocumentoFiscal}
					helpMessage={helpPessoaForm.limiteCredito}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={SingleSelectTabelaPreco}
					name="tabelaPreco"
					label="Tabela de preço"
					isClearable
					url={`${services.GESTOR}/v1/pessoas/relacoes/tabela_preco`}
					value={values?.pessoaFilialConectada?.tabelaPreco}
					onChange={(event) => onChangeTabelaPreco(event)}
					esconderBotao
					touched
					useFormErrors={false}
					disabled={!values.campoObrigatorioParaDocumentoFiscal || !usaTabelaPreco}
					helpMessage={helpPessoaForm.tabelaPreco}
					{...informacoesPermissoes}
				/>
			</Grid>
		</div>
	);
}
