import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IoCloseSharp } from 'react-icons/io5';
import {
	Button,
	Form,
	FormActions,
	FormContent,
	If,
	SelectToggle,
	Tutorial,
	tutorialStepsEtiquetaProdutoImpressao,
} from 'components';
import { buscarConfiguracaoUsuario, configuracoesUsuario, salvarConfiguracaoUsuario } from 'Common';
import { useContextEtiqueta } from './Context';
import { COLORS, OPTIONS_TIPO_CONSULTA } from './Util/constantes';
import { InputProdutoNotaEntrada } from './components/InputProdutoNotaEntrada';
import { ActionsButtons } from './components/ActionsButtons';
import { DataTableProdutos } from './components/DataTableProdutos';

function EtiquetasImpl({ isMobile, isTablet, history }) {
	const {
		tipoConsultaSelected,
		setTipoConsultaSelected,
		setValueInput,
		registros,
		setRegistros,
		notasImportadas,
		setNotasImportadas,
		podeGerar,
	} = useContextEtiqueta();

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_ETIQUETA_PRODUTOS)
	);

	useEffect(() => {
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_ETIQUETA_PRODUTOS, false, null, false);
		}
	}, []);

	function handleChangeTipoConsulta(e) {
		if (e.value) {
			setTipoConsultaSelected(e.value);
			setValueInput('');
		}
	}

	function renderNotasImportadas() {
		if (notasImportadas.length) {
			let label = notasImportadas.length > 0 ? 'Notas de entrada: ' : 'Nota de entrada: ';

			notasImportadas.forEach((nota, index) => {
				const concatedValue = `${label}${nota.numero}/${nota.serie}`;
				label = index === notasImportadas.length - 1 ? `${concatedValue};` : `${concatedValue}, `;
			});

			return (
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<span style={{ padding: '3px 11px', color: COLORS.DARK_BLUE }}>{label}</span>
				</div>
			);
		}

		return null;
	}

	function handleClickLimparLista() {
		setRegistros([]);
		setValueInput('');
		setNotasImportadas([]);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsEtiquetaProdutoImpressao}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Etiquetas">
				<FormActions>
					<ActionsButtons
						isMobile={isMobile}
						isTablet={isTablet}
						history={history}
						limparLista={handleClickLimparLista}
					/>
				</FormActions>
				<FormContent>
					<div
						style={{
							display: 'flex',
							flexDirection: isMobile || isTablet ? 'column' : 'row',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<SelectToggle
							className="step-etiqueta-impressao-produto-ou-nota"
							value={tipoConsultaSelected}
							options={OPTIONS_TIPO_CONSULTA}
							onChange={handleChangeTipoConsulta}
							disabled={!podeGerar}
							title={!podeGerar ? 'Usuário sem permissão para gerar etiquetas' : ''}
						/>
						<InputProdutoNotaEntrada isMobile={isMobile} />
						<Button
							label="Limpar lista"
							onClick={handleClickLimparLista}
							hidden={!registros.length}
							icon={<IoCloseSharp color={COLORS.DARK_RED} size={20} />}
							style={{
								gap: '5px',
								height: '33px',
								color: COLORS.DARK_RED,
								backgroundColor: COLORS.LIGHT_RED,
								border: `1px solid ${COLORS.DARK_RED}`,
							}}
						/>
					</div>
					{renderNotasImportadas()}
					<DataTableProdutos isMobile={isMobile} isTablet={isTablet} />
					<If test={registros.length > 0}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								padding: '16px 0 4px',
							}}
						>
							<span style={{ whiteSpace: 'nowrap' }}>{`${registros.length} registro(s) encontrado(s)`}</span>
						</div>
					</If>
				</FormContent>
			</Form>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export const Etiquetas = connect(mapStateToProps)(EtiquetasImpl);
