import { Field, withFormik } from 'formik';
import {
  AutoProgressBar,
  ButtonCancelar,
  ButtonExcluir,
  ButtonSalvar,
  Form,
  FormActions,
  FormContent,
  Grid,
  If,
  Modal,
  SingleSelectMunicipio,
  SingleSelectServicoCst,
  estadosBotaoCancelar,
  estadosBotaoSalvar,
  typeIntegracaoServico,
} from 'components';
import { validarFormulario } from 'views/Util';
import { mensagensDeValidacao } from 'Common';
import { FieldsModalImpostosTributacaoMunicipal } from './FieldsModalImpostosTributacaoMunicipal';
import { HelpTributacaoMunicipalForm } from '../../Help';
import { integracoes } from 'Common/Constantes/integracoes';

const initialValue = {
  id: null,
  municipio: null,
  servicoCst: null,
  issBase: 100,
  issPercentual: 0,
  irBase: 100,
  irPercentual: 0,
  csllBase: 100,
  csllPercentual: 0,
  inssBase: 100,
  inssPercentual: 0,
};

function ModalTributacaoMunicipioView(props) {
  const {
    header,
    visible,
    onHide,
    values,
    dirty,
    handleReset,
    handleSubmit,
    setFieldValue,
    selectTributacaoMunicipio,
    salvarNovaTributacao,
    atualizarTributacaoMunicipios,
    excluirTributacaoMunicipio,
    validacaoNovaTributacao,
    tipoIntegracao,
  } = props;

  function handleClickCancelar() {
    dirty ? handleReset() : onHide();
  }

  async function handleClickSalvar() {
    handleSubmit();
    if (await validarFormulario(props)) {
      if (selectTributacaoMunicipio) {
        atualizarTributacaoMunicipios(values);
      } else {
        handleReset();
        salvarNovaTributacao(values);
      }
    }
  }

  async function handleClickExcluir() {
    excluirTributacaoMunicipio(values);
  }

  function handleChangeMunicipio(event) {
    setFieldValue('municipio', {
      value: event.value,
      label: event.label,
      registro: event.registro,
    });
  }

  function handleChangeServicoCst(event) {
    setFieldValue('servicoCst', {
      value: event.value,
      label: event.label,
      registro: event.registro,
    });
  }

  return (
    <Modal header={header} visible={visible} onHide={onHide}>
      <AutoProgressBar />
      <Form>
        <FormActions>
          <Grid style={{ margin: '0' }}>
            <ButtonCancelar
              estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
              onClick={handleClickCancelar}
            />
            <ButtonSalvar estadoBotao={estadosBotaoSalvar.SALVAR} disabled={!dirty} onClick={handleClickSalvar} />
            <ButtonExcluir
              onClick={handleClickExcluir}
              disabled={validacaoNovaTributacao || dirty || (!values.id && !values.idTemporario)}
            />
          </Grid>
        </FormActions>
        <FormContent>
          <Grid>
            <Field
              sm="12"
              md="6"
              lg="6"
              xl="6"
              component={SingleSelectMunicipio}
              obrigatorio
              label="Município destino"
              name="municipio"
              value={values.municipio}
              onChange={handleChangeMunicipio}
              isClearable={false}
              disabled={validacaoNovaTributacao}
              helpMessage={HelpTributacaoMunicipalForm.municipio}
            />
            <If test={tipoIntegracao === integracoes.IPM_V1 || tipoIntegracao === integracoes.IPM_V2}>
              <Field
                sm="12"
                md="6"
                lg="6"
                xl="6"
                component={SingleSelectServicoCst}
                obrigatorio
                type={typeIntegracaoServico[tipoIntegracao]}
                label="Situação tributária"
                name="servicoCst"
                value={values.servicoCst}
                onChange={handleChangeServicoCst}
                isClearable={false}
                disabled={validacaoNovaTributacao}
                helpMessage={HelpTributacaoMunicipalForm.servicoCst}
              />
            </If>
            <FieldsModalImpostosTributacaoMunicipal
              values={values}
              setFieldValue={setFieldValue}
              validacaoNovaTributacao={validacaoNovaTributacao}
            />
          </Grid>
        </FormContent>
      </Form>
    </Modal>
  );
}

export const ModalTributacaoMunicipio = withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: false,

  mapPropsToValues(props) {
    if (props.selectTributacaoMunicipio?.id || props.selectTributacaoMunicipio?.idTemporario) {
      return props.selectTributacaoMunicipio;
    } else {
      return initialValue;
    }
  },

  validate(values, props) {
    let errors = {};
    let municipioJaSelecionado = false;

    if (!values.municipio) {
      errors.municipio = mensagensDeValidacao.OBRIGATORIO;
    }

    if (props.tipoIntegracao === integracoes.IPM_V1 || props.tipoIntegracao === integracoes.IPM_V2) {
      if (!values.servicoCst) {
        errors.servicoCst = mensagensDeValidacao.OBRIGATORIO;
      }
    }

    props.listaTributacaoMunicipios?.forEach(tributacao => {
      tributacao.municipio?.value === values.municipio?.value ? (municipioJaSelecionado = true) : null;
    });

    if (municipioJaSelecionado && values.municipio?.value !== props.selectTributacaoMunicipio?.municipio?.value) {
      errors.municipio = 'Município já utilizado';
    }

    return errors;
  },

  handleSubmit: () => {},
})(ModalTributacaoMunicipioView);
