import { buscarDadosLoginLocalStorage, exibirToast, get, post, services } from '../../../../../Common';
import { localServices } from '../../../../../Common/Constantes/api';

export async function asyncTransmitirESalvarNFCe(payload, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/nfce/transmitir`,
		payload,
		null,
		onSuccess,
		onError,
		false,
		true,
		'Erro ao transmitir NFC-e'
	);
}

export async function asyncCancelarNfce(idNFCe, payload, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/nfce/${idNFCe}/cancelar`,
		payload,
		null,
		onSuccess,
		onError,
		false,
		true,
		'Erro ao cancelar NFC-e'
	);
}

export async function asyncGetNfce(idNFCe, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/nfce/${idNFCe}`, null, onSuccess, onError);
}

export async function asyncGetResumoNfce(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function asyncGetHistorico(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function imprimirXMLNfce(idNfce, onSuccess, onError) {
	return get(`${services.GESTOR}/v1/nfce/${idNfce}/xml`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function imprimirDANFE(idNfce, impressaoDireta, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/nfce/${idNfce}/pdf?impressaoDireta=${impressaoDireta}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function buscarVendedorInicial(onSuccess) {
	await get(
		`${services.GESTOR}/v1/nfce/relacoes/vendedores?filtro=&page=0&size=500&sort=codigo,asc`,
		null,
		({ data }) => {
			if (data && data.content && data.content.length > 0) {
				let indexFavorito =
					Math.max(
						data.content?.findIndex((vendedor) => vendedor.favorito === true),
						0
					) ?? 0;

				onSuccess({
					label: `${data.content[indexFavorito]?.codigo} - ${data.content[indexFavorito]?.nome}`,
					registro: data.content[indexFavorito],
					value: data.content[indexFavorito].id,
				});
			} else {
				onSuccess(null);
			}
		}
	);
}

export async function asyncGetContasReceber(pessoaId, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/contas_receber/financeiro_por_pessoa/${pessoaId}`, null, onSuccess, onError);
}

export async function asyncGetLogotipo(onSuccess, onError) {
	await get(`${services.GESTOR}/v1/empresa/logotipo`, { responseType: 'blob' }, onSuccess, onError, true, false);
}

export async function asyncGetPesoBalancaServicoLocal(onSuccess, onError) {
	await get(
		`${localServices.MSYS_GESTOR_SERVICE}/msysgestor/servico/balanca/lerpeso`,
		null,
		onSuccess,
		onError,
		true,
		false
	);
}

export async function asyncImprimirServicoLocal(formData, isFullScreen, onSuccess, onError) {
	await post(
		`${localServices.MSYS_GESTOR_SERVICE}/msysgestor/servico/impressao/imprimir?isFullScreen=${isFullScreen}`,
		formData,
		{ timeout: 10000 },
		onSuccess,
		onError,
		false,
		false,
		'Erro ao imprimir NFC-e'
	);
}

export async function asyncGetIdentificacaoPAFNFCe(onSucess, onError) {
	await get(`${services.GESTOR}/v1/nfce/paf_nfce/identificacao`, null, onSucess, onError);
}

export async function asyncGetRegistrosPAFNFCe(dataInicial, dataFinal, estoque, listaProdutos, onSucess, onError) {
	await get(
		`${services.GESTOR}/v1/nfce/paf_nfce/registros?datainicial=${dataInicial}&datafinal=${dataFinal}&estoque=${estoque}&listaprodutos=${listaProdutos}`,
		null,
		onSucess,
		onError
	);
}

export async function asyncGetSaidasIdentificadas(
	dataInicial,
	dataFinal,
	filtroAdquerinte,
	documento,
	onSucess,
	onError
) {
	await get(
		`${services.GESTOR}/v1/nfce/paf_nfce/saidas_identificadas?datainicial=${dataInicial}&datafinal=${dataFinal}&filtroAdquerinte=${filtroAdquerinte}&documento=${documento}`,
		null,
		onSucess,
		onError
	);
}

export async function asyncGetRequisicoesExternas(dataInicial, dataFinal, onSucess, onError) {
	await get(
		`${services.GESTOR}/v1/nfce/paf_nfce/requisicoes_externas?datainicial=${dataInicial}&datafinal=${dataFinal}`,
		null,
		onSucess,
		onError
	);
}

export async function asyncGetControleDavs(dataInicial, dataFinal, onSucess, onError) {
	await get(
		`${services.GESTOR}/v1/nfce/paf_nfce/controle_davs?datainicial=${dataInicial}&datafinal=${dataFinal}`,
		null,
		onSucess,
		onError
	);
}

export async function asyncGetDownloadXmls(filtro, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/nfce/xmls/download${filtro}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncEnviarNfcePorEmail(nfceId, body, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/nfce/${nfceId}/enviar_por_email`,
		body,
		null,
		exibirToast(onSuccess, 'Email(s) enviado(s)'),
		onError
	);
}

export async function buscarCondicaoPagamentoFavorita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfce/relacoes/condicoes_pagamento?query=favorita=="true";situacao=="ATIVO";tipo!=SEM_PAGAMENTO;movimentacao==SAIDA`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarFormaPagamentoFavorita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfce/relacoes/formas_pagamento?query=conta.favoritaReceita=="true"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarFormaPagamentoDinheiro(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfce/relacoes/formas_pagamento?query=(formaPagamentoFiscal==DINHEIRO)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarContaFavorita(onSucess, onError) {
	await get(`${services.GESTOR}/v1/nfce/relacoes/contas?query=favoritaReceita==true`, null, onSucess, onError);
}

export async function buscarCategoriaFavoritaReceita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfce/relacoes/categorias/receitas?query=favoritaReceita=="true";ativa=="true"`,
		null,
		onSuccess,
		onError,
		false
	);
}
