import { copiarObjeto, gerarUUID, validarUUID } from 'Common';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	If,
	ModalLoadingTransmissao,
	NormalButton,
	ToastTypes,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	notify,
} from 'components';
import ModalHistorico from 'components/ModalHistorico';
import { NOVO_PRODUTO_VENDA } from 'components/Produtos/Util/constantes';
import { NOVO_SERVICO } from 'components/Servicos/Utils';
import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { useContextOrdemServico } from '../../../Context';
import {
	asyncCreateOrdemServico,
	asyncDeleteOrdemServico,
	asyncPostAtualizarSituacaoOrcamentoOrdemServico,
	asyncPostCancelarOrdemServico,
	asyncPostEstornarCancelamentoOrdemServico,
	asyncPostEstornarOrdemServico,
	asyncPostFinalizarOrdemServico,
	asyncPostGerarDocumentosFiscais,
	asyncPostTransmitirDocumentosFiscais,
	asyncUpdateOrdemServico,
} from '../../../Requests';
import {
	DEFAULT_TOTAIS,
	INITIAL_VALUE,
	ORDEM_SERVICO_ORCAMENTO_SITUACAO,
	SITUACAO_ORDEM_SERVICO,
	TAB_ITENS,
} from '../../../Util/constantes';
import { hasProduto, hasServico } from '../../../Util/funtions';
import { converterOrdemServicoParaApi } from '../../../Util/ordemServicoConverterApi';
import { converterOrdemServicoParaFormulario } from '../../../Util/ordemServicoConverterFormulario';
import { ModalCancelamento } from './ModalCancelamento';

export function ActionButtons(props) {
	const {
		history,
		resetFormWithFavoritos,
		duplicateOrdemServico,
		setTabParcelaSelecionada,
		isMobile,
		isTablet,
		isLessHd,
		atualizarSituacaoOrcamentoOrdemServico,
		situacaoOrdemServico,
		situacaoOrcamentoOrdemServico,
	} = props;
	const {
		totais,
		setTotais,
		informacoesPermissoes,
		functionsForm,
		hasBoletoGerado,
		podeEditar,
		hasNFSeGerada,
		hasNFeGerada,
		hasNFSeConfigurada,
		hasNFeConfigurada,
		setTabItensIndex,
		setAtualizarTotais,
	} = useContextOrdemServico();
	const { values, dirty, resetForm, validateForm, handleSubmit, setValues, initialValues } = useFormikContext();

	const [visibleModalHistorico, setVisibleModalHistorico] = useState(false);
	const [visibleModalCancelamento, setVisibleModalCancelamento] = useState(false);
	const [visibleLoadingTransmitir, setVisibleLoadingTransmitir] = useState(false);
	const menuOpcoes = useRef(null);
	const menuAcessarDocumentos = useRef(null);

	const hasNFeTransmitida = Boolean(hasNFeGerada && values?.nfe?.status !== 'NAO_ENVIADA');
	const hasNFSeTransmitida = Boolean(hasNFSeGerada && values?.nfse?.status !== 'PENDENTE');
	const isPendente = Boolean(values.situacao === SITUACAO_ORDEM_SERVICO.PENDENTE);
	const isFinalizado = Boolean(values.situacao === SITUACAO_ORDEM_SERVICO.FINALIZADO);
	const isCancelado = Boolean(values.situacao === SITUACAO_ORDEM_SERVICO.CANCELADO);
	const hasNotaGerada = hasNFSeGerada || hasNFeGerada;
	const hasNotaTransmitida = hasNFeTransmitida || hasNFSeTransmitida;

	const itensOpcoes = getItensOpcoes();
	const itensBuscarDocumentos = getItensBuscarDocumentos();

	function getItensOpcoes() {
		const itens = [];

		itens.push({
			label: 'Duplicar',
			icon: 'fa fa-copy',
			command: () => duplicateOrdemServico(values.id),
			visible: !dirty && values.id,
		});

		itens.push({
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setVisibleModalHistorico(true),
			visible: true,
		});

		itens.push({
			separator: true,
			visible: !hasNotaTransmitida,
		});

		itens.push({
			label: 'Orçamento enviado',
			icon: 'fa fa-calendar-o',
			command: () => handleClickOrcamentoEnviado(),
			visible:
				situacaoOrdemServico === SITUACAO_ORDEM_SERVICO.PENDENTE &&
				situacaoOrcamentoOrdemServico === ORDEM_SERVICO_ORCAMENTO_SITUACAO.NAO_ENVIADO,
			disabled: !podeEditar,
		});

		itens.push({
			label: 'Orçamento aprovado',
			icon: 'fa fa-calendar-check-o',
			command: () => handleClickOrcamentoAprovado(),
			visible:
				situacaoOrdemServico === SITUACAO_ORDEM_SERVICO.PENDENTE &&
				situacaoOrcamentoOrdemServico === ORDEM_SERVICO_ORCAMENTO_SITUACAO.AGUARDANDO_APROVACAO,
			disabled: !podeEditar,
		});

		itens.push({
			label: 'Orçamento rejeitado',
			icon: 'fa fa-calendar-times-o',
			command: () => handleClickOrcamentoRejeitado(),
			visible:
				situacaoOrdemServico === SITUACAO_ORDEM_SERVICO.PENDENTE &&
				situacaoOrcamentoOrdemServico === ORDEM_SERVICO_ORCAMENTO_SITUACAO.AGUARDANDO_APROVACAO,
			disabled: !podeEditar,
		});

		itens.push({
			label: 'Estornar orçamento',
			icon: 'fa fa-calendar-minus-o',
			command: () => handleClickOrcamentoNaoEnviado(),
			visible:
				situacaoOrdemServico === SITUACAO_ORDEM_SERVICO.PENDENTE &&
				situacaoOrcamentoOrdemServico === ORDEM_SERVICO_ORCAMENTO_SITUACAO.REJEITADO,
			disabled: !podeEditar,
		});

		itens.push({
			separator: true,
			visible:
				situacaoOrdemServico === SITUACAO_ORDEM_SERVICO.PENDENTE &&
				situacaoOrcamentoOrdemServico !== ORDEM_SERVICO_ORCAMENTO_SITUACAO.APROVADO,
		});

		itens.push({
			label: isCancelado ? 'Motivo do cancelamento' : 'Cancelar ordem',
			icon: isCancelado ? 'pi pi-file-excel' : 'fa fa-times',
			command: () => setVisibleModalCancelamento(true),
			visible: !hasNFeGerada && !hasNFSeGerada && !hasBoletoGerado,
			disabled: !podeEditar,
		});

		itens.push({
			label: 'Estornar ordem',
			icon: 'fa fa-reply',
			command: handleClickEstornar,
			visible: isFinalizado && !hasNFeTransmitida && !hasNFSeTransmitida && !hasBoletoGerado,
		});

		itens.push({
			label: 'Finalizar ordem',
			icon: 'fa fa-check',
			disabled: !hasServico(values.servicos) || !podeEditar,
			command: handleClickFinalizar,
			visible: !dirty && isPendente && !hasBoletoGerado,
		});

		return itens.filter((element) => element.visible);
	}

	function getItensBuscarDocumentos() {
		const itens = [];

		itens.push({
			label: 'Acessar NFS-e',
			icon: 'fa fa-file-text',
			command: () => atualizarUrl(history, '/nfses/cadastro', values.nfse.id),
			visible: hasNFSeGerada,
		});

		itens.push({
			label: 'Acessar NF-e',
			icon: 'fa fa-file-text-o',
			command: () => atualizarUrl(history, '/nfes/cadastro', values.nfe.id),
			visible: hasNFeGerada,
		});

		return itens.filter((element) => element.visible);
	}

	function handleClickVoltarCancelar() {
		if (dirty) {
			if (validarUUID(initialValues.id)) {
				resetForm({ values: copiarObjeto(initialValues) });
			} else {
				resetFormWithFavoritos({
					...copiarObjeto(INITIAL_VALUE),
					produtos: [copiarObjeto(NOVO_PRODUTO_VENDA)],
					servicos: [copiarObjeto(NOVO_SERVICO)],
				});
			}

			setAtualizarTotais(true);
		} else {
			voltarParaAPesquisa(history, '/ordem_servico');
		}
	}

	async function salvarOrdemServico(callback) {
		const valuesWithStateTotais = {
			...values,
			...totais,
		};

		if (valuesWithStateTotais.servicos?.length > 0) {
			valuesWithStateTotais.servicos = valuesWithStateTotais.servicos.filter((servico) => servico.servico);

			if (valuesWithStateTotais.servicos?.length === 0) {
				valuesWithStateTotais.servicos = [{ ...copiarObjeto(NOVO_SERVICO), id: gerarUUID() }];
			}

			for (let i = 0; i < valuesWithStateTotais.servicos.length; i++) {
				valuesWithStateTotais.servicos[i].item = i + 1;
			}
		}

		if (valuesWithStateTotais.produtos?.length > 0) {
			valuesWithStateTotais.produtos = valuesWithStateTotais.produtos.filter((produto) => produto.produto);

			if (valuesWithStateTotais.produtos?.length === 0) {
				valuesWithStateTotais.produtos = [{ ...copiarObjeto(NOVO_PRODUTO_VENDA), id: gerarUUID() }];
			}

			for (let i = 0; i < valuesWithStateTotais.produtos.length; i++) {
				valuesWithStateTotais.produtos[i].item = i + 1;
			}
		}

		const comissao = calcularComissaoOrdemServico(valuesWithStateTotais);
		valuesWithStateTotais.comissaoTotalPercentual = comissao.percentual;
		valuesWithStateTotais.comissaoTotalValor = comissao.valor;

		setValues(valuesWithStateTotais);

		if (await validarFormulario({ values: valuesWithStateTotais, validateForm })) {
			if (valuesWithStateTotais.id) {
				await updateOrdemServico(valuesWithStateTotais, callback);
			} else {
				await createOrderServico(valuesWithStateTotais, callback);
			}

			if (callback) {
				callback();
			}
		} else {
			handleSubmit();
		}
	}

	async function updateOrdemServico(values, callback) {
		const convertedData = converterOrdemServicoParaApi({
			...values,
			...totais,
		});

		await asyncUpdateOrdemServico(convertedData, ({ data: response }) => {
			if (callback !== undefined && callback === 'function') {
				callback();
			} else {
				resetForm({ values: { ...values, ...response } });
			}
		});
	}

	async function createOrderServico(values, callback) {
		const convertedData = converterOrdemServicoParaApi({
			...values,
			...totais,
		});

		await asyncCreateOrdemServico(convertedData, ({ data: response }) => {
			atualizarUrl(history, '/ordem_servico/cadastro', response.id);
			if (callback !== undefined && callback === 'function') {
				callback();
			} else {
				resetForm({ values: { ...values, ...response } });
			}
		});
	}

	function handleClickSalvar() {
		salvarOrdemServico();
	}

	function newOrdemServico() {
		atualizarUrl(history, '/ordem_servico/cadastro', null, metodosAtualizarUrl.POP);
		resetForm({ values: copiarObjeto(INITIAL_VALUE) });
		setTotais(copiarObjeto(DEFAULT_TOTAIS));
		resetFormWithFavoritos(copiarObjeto(INITIAL_VALUE));
	}

	function handleClickNovo() {
		dirty ? salvarOrdemServico(newOrdemServico) : newOrdemServico();
	}

	async function handleClickExcluir() {
		confirm('Atenção', 'Tem certeza que deseja excluir?', async () => {
			await asyncDeleteOrdemServico(values.id, 'Ordem de serviço excluída com sucesso', () => {
				voltarParaAPesquisa(history, '/ordem_servico');
			});
		});
	}

	async function handleClickGerarDocumentosFiscais() {
		setTabItensIndex(TAB_ITENS.SERVICOS);
		await asyncPostGerarDocumentosFiscais(values.id, ({ data: response }) => {
			const NFSeGerada = Boolean(response.nfse?.id);
			const NFeGerada = Boolean(response.nfe?.id);

			const convertedData = converterOrdemServicoParaFormulario(response);

			resetForm({ values: convertedData });

			functionsForm.updateStatesContext(convertedData);

			let label = 'NF-e gerada';
			if (NFSeGerada && NFeGerada) {
				label = 'Notas geradas';
			} else if (NFSeGerada && !NFeGerada) {
				label = 'NFS-e gerada';
			}

			notify(label);
		});
	}

	async function handleClickTransmitirDocumentosFiscais() {
		setVisibleLoadingTransmitir(true);
		await asyncPostTransmitirDocumentosFiscais(
			values.id,
			({ data: response }) => {
				const NFSeTransmitida = response.nfse?.status === 'TRANSMITIDO';
				const NFSeRejeitada = ['REJEITADO', 'AGUARDANDO'].includes(response.nfse?.status);

				const NFeTransmitida = response.nfe?.status === 'TRANSMITIDA';
				const NFeRejeitada = ['REJEITADA', 'DENEGADA', 'AGUARDANDO_AUTORIZACAO'].includes(response.nfe?.status);

				if (NFSeTransmitida || NFeTransmitida) {
					let label = 'NF-e transmitida';
					if (NFSeTransmitida && NFeTransmitida) {
						label = 'Notas transmitidas';
					} else if (NFSeTransmitida && !NFeTransmitida) {
						label = 'NFS-e transmitida';
					}

					notify(label);
				}

				if (NFSeRejeitada || NFeRejeitada) {
					let label = 'NF-e rejeitada';
					if (NFSeRejeitada && NFeRejeitada) {
						label = 'Notas rejeitadas';
					} else if (NFSeRejeitada && !NFeRejeitada) {
						label = 'NFS-e rejeitada';
					}

					notify(label, ToastTypes.ERROR);
				}

				const newValues = {
					...values,
					nfe: {
						...values.nfe,
						...response.nfe,
					},
					nfse: {
						...values.nfse,
						...response.nfse,
					},
				};

				resetForm({ values: newValues });
				functionsForm.updateStatesContext(newValues);
				setVisibleLoadingTransmitir(false);
			},
			() => setVisibleLoadingTransmitir(false)
		);
	}

	async function handleClickEstornar() {
		await asyncPostEstornarOrdemServico(values.id, () => {
			const newValues = {
				...values,
				situacao: SITUACAO_ORDEM_SERVICO.PENDENTE,
				dataFechamento: null,
				horaFechamento: null,
				nfe: {
					id: null,
					numero: null,
					serie: null,
					status: null,
				},
				nfse: {
					id: null,
					numero: null,
					serie: null,
					status: null,
				},
			};
			resetForm({ values: newValues });
			functionsForm.updateStatesContext(newValues);
			setTabParcelaSelecionada(0);
		});
	}

	async function handleClickOrcamentoEnviado() {
		const situacaoOrcamento = {
			situacaoOrcamento: ORDEM_SERVICO_ORCAMENTO_SITUACAO.AGUARDANDO_APROVACAO,
		};
		await asyncPostAtualizarSituacaoOrcamentoOrdemServico(values.id, situacaoOrcamento, () => {
			notify('Ordem de serviço atualizada com sucesso');
			atualizarSituacaoOrcamentoOrdemServico(ORDEM_SERVICO_ORCAMENTO_SITUACAO.AGUARDANDO_APROVACAO);
		});
	}

	async function handleClickOrcamentoAprovado() {
		const situacaoOrcamento = {
			situacaoOrcamento: ORDEM_SERVICO_ORCAMENTO_SITUACAO.APROVADO,
		};
		await asyncPostAtualizarSituacaoOrcamentoOrdemServico(values.id, situacaoOrcamento, () => {
			notify('Ordem de serviço atualizada com sucesso');
			atualizarSituacaoOrcamentoOrdemServico(ORDEM_SERVICO_ORCAMENTO_SITUACAO.APROVADO);
		});
	}

	async function handleClickOrcamentoRejeitado() {
		const situacaoOrcamento = {
			situacaoOrcamento: ORDEM_SERVICO_ORCAMENTO_SITUACAO.REJEITADO,
		};
		await asyncPostAtualizarSituacaoOrcamentoOrdemServico(values.id, situacaoOrcamento, () => {
			atualizarSituacaoOrcamentoOrdemServico(ORDEM_SERVICO_ORCAMENTO_SITUACAO.REJEITADO);
			notify('Ordem de serviço atualizada com sucesso');
			confirm(
				'Confirmação',
				'Deseja cancelar a ordem de serviço?',
				() => {
					setVisibleModalCancelamento(true);
				},
				null,
				'Sim',
				'Não'
			);
		});
	}

	async function handleClickOrcamentoNaoEnviado() {
		const situacaoOrcamento = {
			situacaoOrcamento: ORDEM_SERVICO_ORCAMENTO_SITUACAO.NAO_ENVIADO,
		};
		await asyncPostAtualizarSituacaoOrcamentoOrdemServico(values.id, situacaoOrcamento, () => {
			notify('Ordem de serviço atualizada com sucesso');
			atualizarSituacaoOrcamentoOrdemServico(ORDEM_SERVICO_ORCAMENTO_SITUACAO.NAO_ENVIADO);
		});
	}

	async function handleClickFinalizar() {
		await asyncPostFinalizarOrdemServico(values.id, ({ data: response }) => {
			const convertedData = converterOrdemServicoParaFormulario(response);

			resetForm({ values: convertedData });
			functionsForm.updateStatesContext(convertedData);
		});
	}

	async function onEstornarOrdemServico() {
		await asyncPostEstornarCancelamentoOrdemServico(values.id, () => {
			const newValues = {
				...values,
				situacao: SITUACAO_ORDEM_SERVICO.PENDENTE,
				motivoCancelamento: null,
			};

			resetForm({ values: newValues });
			functionsForm.updateStatesContext(newValues);
		});
	}

	async function onCancelarOrdemServico(motivoCancelamento) {
		await asyncPostCancelarOrdemServico(values.id, motivoCancelamento, () => {
			const newValues = {
				...values,
				situacao: SITUACAO_ORDEM_SERVICO.CANCELADO,
				motivoCancelamento,
			};

			resetForm({ values: newValues });
			functionsForm.updateStatesContext(newValues);
		});
	}

	function calcularComissaoOrdemServico(ordemServico) {
		const comissao = { percentual: 0, valor: 0 };

		function calcularComissao(itens) {
			if (!itens?.length) {
				return { percentual: 0, valor: 0 };
			}

			const percentualTotal = itens.reduce((total, item) => total + item.comissaoPercentual || 0, 0) / itens.length;
			const percentual = parseFloat(percentualTotal.toFixed(2));
			const valor = parseFloat((ordemServico.valorTotal * (percentualTotal / 100)).toFixed(2));

			return { percentual, valor };
		}

		const comissaoServico = calcularComissao(ordemServico.servicos);
		const comissaoProduto = calcularComissao(ordemServico.produtos);

		comissao.percentual = comissaoServico.percentual + comissaoProduto.percentual;
		comissao.valor = comissaoServico.valor + comissaoProduto.valor;

		return comissao;
	}

	function handleClickAcessarDocumentos(event) {
		if (hasNFSeGerada && hasNFeGerada) {
			menuAcessarDocumentos.current.toggle(event);
		} else if (hasNFSeGerada) {
			atualizarUrl(history, '/nfses/cadastro', values.nfse.id);
		} else {
			atualizarUrl(history, '/nfes/cadastro', values.nfe.id);
		}
	}

	function getMessageLoadingTransmissao() {
		let messageLoadingTransmissao = 'NF-e';
		if (hasNFeGerada && hasProduto(values.produtos) && hasNFSeGerada) {
			messageLoadingTransmissao = 'notas';
		} else if (hasNFSeGerada) {
			messageLoadingTransmissao = 'NFS-e';
		} else {
			messageLoadingTransmissao = 'NF-e';
		}

		return messageLoadingTransmissao;
	}

	function getLabelButtonAcessarDocumentos() {
		let labelButtonAcessarDocumentos = 'Acessar NFS-e';
		if (hasNFSeGerada && hasNFeGerada) {
			labelButtonAcessarDocumentos = 'Acessar documentos';
		} else if (hasNFSeGerada) {
			labelButtonAcessarDocumentos = 'Acessar NFS-e';
		} else {
			labelButtonAcessarDocumentos = 'Acessar NFS-e';
		}

		return labelButtonAcessarDocumentos;
	}

	function getLabelButtonGerarDocumentosFiscais() {
		let labelButtonGerarDocumentosFiscais = 'Gerar NFS-e';
		if (!hasNFeGerada && hasProduto(values.produtos) && !hasNFSeGerada) {
			labelButtonGerarDocumentosFiscais = 'Gerar fiscal';
		} else if (hasNFSeGerada) {
			labelButtonGerarDocumentosFiscais = 'Gerar NFS-e';
		} else {
			labelButtonGerarDocumentosFiscais = 'Gerar NFS-e';
		}

		return labelButtonGerarDocumentosFiscais;
	}

	function getLabelButtonTransmitirDocumentosFiscais() {
		let labelButtonTransmitirDocumentosFiscais = 'Transmitir NFS-e';
		if (hasNFeGerada && hasProduto(values.produtos) && hasNFSeGerada) {
			labelButtonTransmitirDocumentosFiscais = 'Transmitir notas';
		} else if (hasNFSeGerada) {
			labelButtonTransmitirDocumentosFiscais = 'Transmitir NFS-e';
		} else {
			labelButtonTransmitirDocumentosFiscais = 'Transmitir NFS-e';
		}

		return labelButtonTransmitirDocumentosFiscais;
	}

	return (
		<>
			<Menu model={itensOpcoes} popup ref={menuOpcoes} style={{ minWidth: '210px' }} />
			<Menu model={itensBuscarDocumentos} popup ref={menuAcessarDocumentos} />
			<ModalLoadingTransmissao
				visible={visibleLoadingTransmitir}
				message={`Transmitindo ${getMessageLoadingTransmissao()}...`}
			/>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={handleClickVoltarCancelar}
				{...informacoesPermissoes}
			/>

			<ButtonSalvar disabled={!dirty} onClick={handleClickSalvar} {...informacoesPermissoes} />
			<ButtonNovo
				onClick={handleClickNovo}
				hidden={!dirty && !values.id}
				estadoBotao={dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir hidden={!values.id || !isPendente} onClick={handleClickExcluir} {...informacoesPermissoes} />
			<NormalButton
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={dirty || !values.id}
				onClick={(event) => menuOpcoes.current.toggle(event)}
			/>
			<Button
				type="button"
				label={getLabelButtonAcessarDocumentos()}
				icon="fa fa-link"
				style={{
					margin: '5px',
				}}
				hidden={!hasNFeGerada && !hasNFSeGerada}
				onClick={handleClickAcessarDocumentos}
			/>
			<Button
				type="button"
				color="success"
				disabled={!hasServico(values.servicos)}
				label={getLabelButtonGerarDocumentosFiscais()}
				icon="fa fa-file-text-o"
				style={{
					margin: '5px',
				}}
				hidden={dirty || !values.id || hasNFeGerada || hasNFSeGerada || !isPendente}
				onClick={handleClickGerarDocumentosFiscais}
			/>
			<Button
				type="button"
				color="success"
				label={getLabelButtonTransmitirDocumentosFiscais()}
				icon="fa fa-send"
				style={{
					margin: '5px',
				}}
				hidden={dirty || !isFinalizado || !hasNotaGerada || hasNotaTransmitida}
				disabled={(hasNFSeGerada && !hasNFSeConfigurada) || (hasNFeGerada && !hasNFeConfigurada)}
				onClick={handleClickTransmitirDocumentosFiscais}
			/>
			<If test={visibleModalHistorico}>
				<ModalHistorico
					header="Histórico da ordem de serviço"
					visible={visibleModalHistorico}
					onHide={() => setVisibleModalHistorico(false)}
					mapping="ordem_servico"
					id={values.id}
					showIcon={false}
				/>
			</If>
			<If test={visibleModalCancelamento}>
				<ModalCancelamento
					visible={visibleModalCancelamento}
					onHide={() => setVisibleModalCancelamento(false)}
					onCancelar={onCancelarOrdemServico}
					onEstornar={onEstornarOrdemServico}
					isCanceled={isCancelado}
					motivoCancelamento={values.motivoCancelamento}
					isMobile={isMobile}
					isTablet={isTablet}
					isLessHd={isLessHd}
				/>
			</If>
		</>
	);
}
