import { mensagensDeValidacao, services } from 'Common';
import {
	Grid,
	InputDate,
	ModalRelatorio,
	MultiSelectFiliais,
	SingleSelectFormaPagamento,
	SingleSelectPessoa,
	tiposCategoria,
} from 'components';
import { format, formatISO, isValid, parseISO, startOfMonth } from 'date-fns';
import { Field, withFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { asyncBaixarRelatorioContasAReceberPorPeriodo } from '../../Requests';
import { helpMessage } from './util/constantes';

const initialValue = {
	dataInicial: formatISO(startOfMonth(new Date())),
	dataFinal: new Date().toISOString(),
	pessoa: null,
	filiaisSelecionadas: [],
	formaPagamento: null,
};

function ContasReceber(props) {
	const { visible, onHide, values, handleSubmit, isValid, setFieldValue } = props;

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('InputDataInicial')?.focus();
		}, 500);
	}, []);

	async function newValidate() {
		return new Promise((resolve, reject) => {
			const errors = {};
			if (values.filiaisSelecionadas.length === 0) {
				errors.filiaisSelecionadas = mensagensDeValidacao.OBRIGATORIO;
				reject(errors);
			} else {
				resolve();
			}
		});
	}

	async function imprimir() {
		await handleSubmit();

		try {
			await newValidate();
			const contaSituacao = 'TODAS';
			const dataInicialFormatada = format(parseISO(values.dataInicial), 'yyyy-MM-dd');
			const dataFinalFormatada = format(parseISO(values.dataFinal), 'yyyy-MM-dd');
			const devedorId = values.pessoa?.value;
			const filiaisSelecionadas = [];
			const formaPagamento = values.formaPagamento?.value ?? '';

			values.filiaisSelecionadas.forEach((filial) => {
				filiaisSelecionadas.push(filial.value);
			});

			if (isValid) {
				await asyncBaixarRelatorioContasAReceberPorPeriodo(
					dataInicialFormatada,
					dataFinalFormatada,
					contaSituacao,
					devedorId,
					filiaisSelecionadas,
					formaPagamento,
					({ data: pdf }) => {
						const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						const arquivoURL = URL.createObjectURL(arquivoPDF);
						const relatorio = window.open(arquivoURL);
						if (relatorio) {
							relatorio.onload = () => {
								setTimeout(() => {
									relatorio.document.title = 'Relatório contas a receber';
								}, 250);
							};
						}
						onHide();
					}
				);
			}
		} catch (e) {
			console.error(e);
		}
	}

	const parametros = (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={InputDate}
				label="Data inicial"
				name="dataInicial"
				obrigatorio
				helpMessage={helpMessage.dataInicial}
				value={values.dataInicial}
				touched
				id="InputDataInicial"
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={InputDate}
				label="Data final"
				name="dataFinal"
				obrigatorio
				helpMessage={helpMessage.dataFinal}
				value={values.dataFinal}
				touched
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				label="Pessoa (devedor)"
				component={SingleSelectPessoa}
				name="pessoa"
				esconderBotao
				showClear
				onChange={(e) => setFieldValue('pessoa', e)}
				value={values.pessoa}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={MultiSelectFiliais}
				name="filiaisSelecionadas"
				obrigatorio
				startConnectedFilial
				onlyUserFiliais
				onChange={(e) => setFieldValue('filiaisSelecionadas', e)}
				value={values.filiaisSelecionadas}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				name="formaPagamento"
				label="Forma de pagamento"
				helpMessage={helpMessage.formaPagamento}
				tipoCategoria={tiposCategoria.RECEITA}
				component={SingleSelectFormaPagamento}
				value={values.formaPagamento}
				onChange={(e) => setFieldValue('formaPagamento', e)}
				url={`${services.GESTOR}/v1/contas_receber/relacoes/formas_pagamento`}
			/>
		</Grid>
	);
	return (
		<ModalRelatorio
			header="Relatório contas a receber"
			visible={visible}
			onHide={onHide}
			onImprimir={imprimir}
			parametros={parametros}
			isValid={isValid}
		/>
	);
}

const ModalContasReceber = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		const errors = {};

		if (!values.dataInicial || values.dataInicial === '') {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataFinal || values.dataFinal === '') {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.filiaisSelecionadas.length <= 0) {
			errors.filiaisSelecionadas = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.dataInicial && !isValid(parseISO(values.dataInicial))) {
			errors.dataInicial = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.dataFinal && !isValid(parseISO(values.dataFinal))) {
			errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		dataInicial: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		dataFinal: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ContasReceber);

export default ModalContasReceber;
