import { useCallback } from 'react';
import { If, Message } from '../../../../../../../components';
import { useFormikContext } from 'formik';

function ValidateMessages(props) {
	const { existeParcelaRecebida, credencial, history, acessarNfe } = props;
	const { values, errors } = useFormikContext();

	function organizacaoSemEndereco() {
		if (!credencial.filialConectada?.endereco) {
			return true;
		}
		return false;
	}

	function buscarMensagemOrganizacaoSemEndereco() {
		return (
			<span>
				Organizacao sem endereço cadastrado.
				<div>
					<b
						style={{ cursor: 'pointer' }}
						onClick={() =>
							history.push({
								pathname: '/configuracoes_gerais',
							})
						}
					>
						Clique aqui para configurar
					</b>
				</div>
			</span>
		);
	}

	const validateFields = useCallback(() => {
		if (errors.conta) {
			return true;
		}

		return false;
	});

	function buscarMensagemValidateFields() {
		let text = '';

		if (errors.conta) {
			text = 'Campo Conta na aba Pagamentos não pode ser vazia';
		}

		return <span>{text}</span>;
	}

	return (
		<>
			<If test={organizacaoSemEndereco()}>
				<Message severity="error" text={buscarMensagemOrganizacaoSemEndereco()} />
			</If>

			<If test={validateFields()}>
				<Message severity="error" text={buscarMensagemValidateFields()} />
			</If>

			<If test={existeParcelaRecebida}>
				<Message
					colStyle={{ padding: '0px' }}
					severity="info"
					text="Não é possível editar uma venda com valores já recebidos"
				/>
			</If>

			<If test={values.nfe && (values.nfe.status === 'REJEITADA' || values.nfe.status === 'NAO_ENVIADA')}>
				<Message
					colStyle={{ padding: '0px', marginTop: '5px' }}
					severity="info"
					text={
						<>
							<span>Venda com Nota Fiscal gerada, para editar será necessário excluir a Nota Fiscal.</span>
							<div onClick={acessarNfe} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
								Clique para acessar a nota
							</div>
						</>
					}
				/>
			</If>
		</>
	);
}

export default ValidateMessages;
