import ReactSelect from './components/ReactSelect';

export default function MultipleSelect(props) {
	const { field, form, value, esconderBotao = true, converterOptions, ...rest } = props;

	return (
		<>
			<ReactSelect
				{...field}
				{...rest}
				dirty={form && form.dirty}
				errors={form && form.errors[field.name]}
				touched={form && form.touched[field.name]}
				value={value}
				esconderBotao={esconderBotao}
				converterOptions={converterOptions}
			/>
		</>
	);
}
