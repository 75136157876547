export const tutorialStepsEtiquetaProdutoImpressao = [
  {
    target: "body",
    content: `Este é o módulo de impressão de etiquetas de produtos, nesse módulo é possível imprimir etiquetas totalmente configuráveis`,
    disableBeacon: true,
    placement: "center",
  },
  {
    target: ".step-etiqueta-impressao-produto-ou-nota",
    content:
      "Nesse botão, é possível selecionar o que você quer pesquisar: Diretamente os produtos, ou então buscar os produtos pela nota de entrada",
    placement: "top",
  },
  {
    target: ".step-etiqueta-impressao-input-search",
    content:
      "Neste campo, é possível pesquisar o registro desejado segundo o informado no botão anterior",
    placement: "top",
  },
  {
    target: ".step-etiqueta-impressao-quantidade",
    content:
      "Após buscar os produtos, é possível editar a quantidade de etiquetas de cada um aqui",
    placement: "top",
  },
  {
    target: ".step-etiqueta-impressao-imprimir",
    content:
      "Neste botão, após selecionar os produtos, é possível visualizá-los em PDF ou então imprimi-los diretamente em sua impressora",
    placement: "top",
  },
  {
    target: ".step-etiqueta-impressao-configuracao",
    content:
      "Aqui é onde serão configuradas as etiquetas segundo a sua necessidade",
    placement: "top",
  },
  {
    target: ".step-etiqueta-impressao-configuracao-impressora",
    content:
      "Por fim, nesse botão é possível selecionar a impressora padrão de impressão",
    placement: "top",
  },
];
