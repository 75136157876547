export const helpMessage = {
	dataInicial: 'Data inicial da impressão',
	dataFinal: 'Data final da impressão',
	conta: 'Conta bancária para impressão',
	formaPagamento: 'Forma de pagamento para impressão',
};

export const opcoesCodigo = [
	{ label: 'Código NCM', value: 'NCM' },
	{ label: 'Código Produto', value: 'CODIGO' },
	{ label: 'Código Referência', value: 'REFERENCIA' },
];

export const opcoesPreco = [
	{ label: 'Último Custo', value: 'ULTIMO_CUSTO' },
	{ label: 'Custo Médio', value: 'CUSTO_MEDIO' },
	{ label: 'Preço Venda', value: 'PRECO_VENDA' },
];

export const opcoesSaldo = [
	{ label: 'Todos', value: 'TODOS' },
	{ label: 'Positivos', value: 'POSITIVOS' },
	{ label: 'Negativos', value: 'NEGATIVOS' },
	{ label: 'Zerados', value: 'ZERADOS' },
	{ label: 'Negativos/Zerados', value: 'NEGATIVOS_E_ZERADOS' },
	{ label: 'Positivos/Zerados', value: 'POSITIVOS_E_ZERADOS' },
];

export const opcoesOrdenacao = [
	{ label: 'Código Produto', value: 'CODIGO' },
	{ label: 'Nome Produto', value: 'NOME' },
];
