import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { Message } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';

function ErrosValidacoes() {
	const { errors, submitCount } = useFormikContext();
	const { setActiveTabComplementares, setActiveTabPrincipais, setActiveTabServicos } = useContextMDFe();

	useEffect(() => {
		if (Object.keys(errors)?.length > 0) {
			if (errors?.total) {
				setActiveTabComplementares(0);
			}

			if (errors.total?.quantidadeDocumento) {
				setActiveTabPrincipais(0);
			} else if (errors?.condutores) {
				setActiveTabPrincipais(1);
			}

			if (errors?.seguradoras) {
				setActiveTabServicos(0);
			}

			if (errors?.ciot) {
				setActiveTabServicos(3);
			}

			if (errors?.produtoPredominante) {
				setActiveTabServicos(4);
			}
		}
	}, [errors, submitCount]);

	function montarMensagemValidacoes() {
		if (errors.ufsPercurso) {
			return 'UF de carregamento e descarregamento não são adjacentes, obrigatório informar UF de percurso';
		} else if (errors.condutores) {
			return errors.condutores;
		}

		if (errors.ciot) {
			return 'Informações inválidas na aba "CIOT"';
		}

		if (errors.produtoPredominante) {
			return 'Informações inválidas na aba "Produto predominante"';
		}

		if (errors.seguradoras) {
			return errors.seguradoras;
		}

		if (errors.total?.quantidadeDocumento) {
			return 'Informe ao menos um documento no MDF-e';
		} else if (errors.total?.pesoBruto) {
			return 'Aba Totalizadores: Peso total é obrigatório';
		} else if (errors.total?.valorTotal) {
			return 'Aba Totalizadores: Valor total é obrigatório';
		}

		return null;
	}

	return <Message severity="warn" text={montarMensagemValidacoes()} />;
}

export { ErrosValidacoes };
