import { DADOSLOCALSTORAGE, buscarItemLocalStorage, services } from 'Common';
import { Grid, SingleSelectSetor, SingleSelectUsuario, TabView, TextArea } from 'components';
import { Field } from 'formik';
import { TabPanel } from 'primereact/tabview';

import { statusDocumento } from 'views/documentos/AssinaturaEletronica/Util/constantes';
import { helpMessage } from '../../Util/constantes';
import DocumentoConfirmacao from './components/DocumentoConfirmacao';
import DocumentoSignatarios from './components/DocumentoSignatarios';

function TabsAssinaturaEletronica(props) {
	const {
		tabSelecionada,
		setTabSelecionada,
		values,
		setFieldValue,
		informacoesPermissoes,
		isMobile,
		isTablet,
		estadoCadastro,
		desabilitarCamposDocumentoEnviadoOuExpirado,
	} = props;

	const desabilitarCamposSensiveis = !(
		values.status === statusDocumento.PENDENTE || values.status === statusDocumento.ARQUIVO_ADICIONADO
	);

	function tabChange(event) {
		setTabSelecionada(event.index);

		setTimeout(() => {
			switch (event.index) {
				case 2:
					document.getElementById('AssinaturaSelectSetor')?.getElementsByTagName('input')[0]?.focus();
					break;
				case 3:
					document.getElementById('AssinaturaTextAreaObs')?.focus();
					break;
				default:
					break;
			}
		});
	}

	return (
		<TabView className="tab-view" onTabChange={(event) => tabChange(event)} activeIndex={tabSelecionada}>
			<TabPanel header="Signatários" headerClassName="step-documento-signatarios">
				<DocumentoSignatarios
					value={values.signatarios}
					onChange={(e) => setFieldValue('signatarios', e)}
					estadoCadastro={estadoCadastro}
					disabled={desabilitarCamposSensiveis}
					informacoesPermissoes={informacoesPermissoes}
					documentoStatus={values.status}
					isMobile={isMobile}
					isTablet={isTablet}
				/>
			</TabPanel>
			<TabPanel header="Confirmações para assinatura" headerClassName="step-documento-confirmacoes">
				<DocumentoConfirmacao
					value={values.confirmacoes}
					idDocumento={values.id}
					onChange={(confirmacoes) => setFieldValue('confirmacoes', confirmacoes)}
					estadoCadastro={estadoCadastro}
					disabled={desabilitarCamposSensiveis}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</TabPanel>
			<TabPanel header="Responsável">
				<Grid>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						component={SingleSelectUsuario}
						name="usuario"
						label="Usuário "
						obrigatorio
						disabled={desabilitarCamposDocumentoEnviadoOuExpirado}
						helpMessage={helpMessage.usuario}
						url={`${services.GESTOR}/v1/documentos/relacoes/usuarios`}
						value={values.usuario}
						onChange={(e) => setFieldValue('usuario', e)}
						esconderBotao={true}
						isClearable={false}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						component={SingleSelectSetor}
						name="setor"
						label="Setor "
						obrigatorio
						url={`${services.GESTOR}/v1/documentos/relacoes/setores`}
						helpMessage={helpMessage.setor}
						onChange={(e) => setFieldValue('setor', e)}
						value={values.setor}
						esconderBotao={true}
						disabled={desabilitarCamposDocumentoEnviadoOuExpirado}
						id="AssinaturaSelectSetor"
						isClearable={false}
						{...informacoesPermissoes}
					/>
				</Grid>
			</TabPanel>
			<TabPanel header="Observações">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={TextArea}
					placeholder="Escreva suas observações aqui..."
					name="observacao"
					rows={5}
					disabled={desabilitarCamposDocumentoEnviadoOuExpirado}
					onChange={(e) => setFieldValue('observacao', e.target.value)}
					id="AssinaturaTextAreaObs"
					{...informacoesPermissoes}
				/>
			</TabPanel>
		</TabView>
	);
}

export { TabsAssinaturaEletronica };
