import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types';
import { services } from '../../../Common/Constantes/api';

export default function SingleSelectMunicipio({ label, uf, ...props }) {
	function buscarUrlPesquisa(pesquisa, page) {
		let url = `${services.GESTOR}/v1/municipios?query=nome=contains="*${pesquisa}*"`;

		if (typeof uf === 'string') {
			url = url.concat(`;estado.sigla=="${uf}"`);
		} else if (typeof uf === 'object' && uf?.length > 0) {
			url = url.concat(`;estado.sigla=in=${gerarListaUfs(uf)}`);
		} else {
			url = url.concat(`,estado.sigla=contains="*${pesquisa}*"`);
		}

		return url.concat(`&page=${page}&size=20&sort=nome`);
	}

	function gerarListaUfs(uf) {
		let ufs = '(';

		if (uf?.length > 0) {
			uf.forEach((e, i) => {
				ufs = `${ufs}${i !== 0 ? ',' : ''}"${e}"`;
			});
		}

		return `${ufs})`;
	}

	function montarLabel(row) {
		if (row && row.estado) {
			return row.nome + ' - ' + row.estado.sigla;
		} else {
			return row.nome;
		}
	}

	return (
		<>
			<SingleSelect
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				label={label}
				onChange={props.onChange}
				esconderBotao
				{...props}
			/>
		</>
	);
}

SingleSelectMunicipio.defaultProps = {
	label: 'Municipio',
};

SingleSelectMunicipio.propTypes = {
	service: propTypes.string,
};
