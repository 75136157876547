import { buscarDadosLoginLocalStorage } from 'Common';
import {
	Button,
	ButtonCancelar,
	Col,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	NenhumRegistroEncontrado,
	estadosBotaoCancelar,
} from 'components';
import { useState } from 'react';
import { ModalAtualizarIbptax } from './components/ModalAtualizarIbptax';
import { ModalPopularTabelas } from './components/ModalPopularTabelas';

function PainelHelpDesk(props) {
	const { history } = props;

	const [mostrarModalAtualizarIbptax, setMostrarModalAtualizarIbptax] = useState(false);
	const [mostrarModalPopularTabelas, setMostrarModalPopularTabelas] = useState(false);

	const localStorage = buscarDadosLoginLocalStorage();
	const isHelpdesk = localStorage?.autoridade === 'HELPDESK';
	const isAdmin = localStorage?.autoridade === 'ADMIN';

	return (
		<>
			<Form header="Painel help desk">
				<FormActions>
					<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={history.goBack} />
				</FormActions>
				<FormContent>
					<Grid style={{ padding: '0rem 0rem 1rem 0rem' }}>
						<If test={isAdmin}>
							<Col sm={12} md={6} lg={4} xl={4}>
								<Button
									label="Atualizar IBPTax"
									onClick={() => setMostrarModalAtualizarIbptax(true)}
									style={{ width: '100%' }}
								/>
							</Col>
							<Col sm={12} md={6} lg={4} xl={4}>
								<Button
									label="Popular Tabelas"
									onClick={() => setMostrarModalPopularTabelas(true)}
									style={{ width: '100%' }}
								/>
							</Col>
						</If>
						<If test={isHelpdesk}>
							<NenhumRegistroEncontrado />
						</If>
					</Grid>
				</FormContent>
			</Form>
			<If test={mostrarModalAtualizarIbptax}>
				<ModalAtualizarIbptax
					visible={mostrarModalAtualizarIbptax}
					setMostrarModalAtualizarIbptax={setMostrarModalAtualizarIbptax}
				/>
			</If>
			<If test={mostrarModalPopularTabelas}>
				<ModalPopularTabelas
					visible={mostrarModalPopularTabelas}
					setMostrarModalPopularTabelas={setMostrarModalPopularTabelas}
				/>
			</If>
		</>
	);
}

export { PainelHelpDesk };
