import React, { createContext, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { endOfMonth, startOfMonth } from 'date-fns';

const ContextPesquisa = createContext();

export default function PesquisaProvider({ children }) {
	const location = useLocation();

	function regexFilter(text) {
		const regex = /^.*(?=(?=\/cadastro))/;

		const match = regex.exec(text);

		if (match) {
			return match[0];
		} else {
			return text;
		}
	}

	const [currentPath, setCurrentPath] = useState(regexFilter(location.pathname));

	const newPath = regexFilter(location.pathname);
	const isNewPath = newPath !== currentPath;

	useEffect(() => {
		if (newPath !== currentPath) {
			setCurrentPath(newPath);
			resetStates();
		}
	}, [location]);

	const resetStates = () => {
		setValorPesquisa('');
		setInterval({
			dataInicial: startOfMonth(new Date()),
			dataFinal: endOfMonth(new Date()),
		});
		setSortField('');
		setSortOrder(0);
		setPage(0);
		setRows(10);
		setSize(10);
		setTotalRecords(0);
		setFiltroAvancado('');
		setDescricaoFiltroAvancado('');
		setSelectedCard(null);
		setFiltroData('');
		setExibirBloqueadas(false);
		setFiltros([]);
		setFiltroSelecionado([]);
		setValorCard('');
	};

	const [valorPesquisa, setValorPesquisa] = useState('');
	const [interval, setInterval] = useState({
		dataInicial: startOfMonth(new Date()),
		dataFinal: endOfMonth(new Date()),
	});
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState(0);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState(10);
	const [size, setSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [filtroAvancado, setFiltroAvancado] = useState('');
	const [descricaoFiltroAvancado, setDescricaoFiltroAvancado] = useState('');
	const [filtros, setFiltros] = useState([]);
	const [filtroSelecionado, setFiltroSelecionado] = useState([]);
	const [selectedCard, setSelectedCard] = useState(null);
	const [filtroData, setFiltroData] = useState('');
	const [exibirBloqueadas, setExibirBloqueadas] = useState(false);
	const [valorCard, setValorCard] = useState('');

	return !isNewPath ? (
		<ContextPesquisa.Provider
			value={{
				valorPesquisa,
				setValorPesquisa,
				interval,
				setInterval,
				sortField,
				setSortField,
				sortOrder,
				setSortOrder,
				page,
				setPage,
				rows,
				setRows,
				size,
				setSize,
				totalRecords,
				setTotalRecords,
				filtroAvancado,
				setFiltroAvancado,
				descricaoFiltroAvancado,
				setDescricaoFiltroAvancado,
				selectedCard,
				setSelectedCard,
				filtroData,
				setFiltroData,
				exibirBloqueadas,
				setExibirBloqueadas,
				filtros,
				setFiltros,
				filtroSelecionado,
				setFiltroSelecionado,
				valorCard,
				setValorCard,
			}}
		>
			{children}
		</ContextPesquisa.Provider>
	) : (
		<ContextPesquisa.Provider
			value={{
				valorPesquisa: '',
				setValorPesquisa,
				interval: {
					dataInicial: startOfMonth(new Date()),
					dataFinal: endOfMonth(new Date()),
				},
				setInterval,
				sortField: '',
				setSortField,
				sortOrder: -1,
				setSortOrder,
				page: 0,
				setPage,
				rows: 10,
				setRows,
				size: 10,
				setSize,
				totalRecords: 0,
				setTotalRecords,
				filtroAvancado: '',
				setFiltroAvancado,
				descricaoFiltroAvancado: '',
				setDescricaoFiltroAvancado,
				selectedCard: null,
				setSelectedCard,
				filtroData: '',
				setFiltroData,
				exibirBloqueadas: false,
				setExibirBloqueadas,
				filtros: [],
				setFiltros,
				filtroSelecionado: [],
				setFiltroSelecionado,
				valorCard: '',
				setValorCard,
			}}
		>
			{children}
		</ContextPesquisa.Provider>
	);
}

export const useContextPesquisa = () => useContext(ContextPesquisa);
