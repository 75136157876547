export function jaExisteMesmoEmail(listaEmails, values) {
    let mesmoEmail = false;
    if (listaEmails.length > 0) {
        for (let i = 0; i < listaEmails.length; i++) {
            const element = listaEmails[i];

            if (element.email === values.email) {
                if(element.id !== values.id) {
                    mesmoEmail = true;
                } else {
                    mesmoEmail = false;
                }
            }
        }
    }
    return mesmoEmail;
}