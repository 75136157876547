import { Badge } from 'components';

const Colors = {
	kBgLightGreen: '#dcedc8',
	kTextGreen: '#1b5e20',
	kBgLightRed: '#ffcdd2',
	kTextRed: '#b71c1c',
};

function renderFilialInativa(row) {
	const styleBackground = {
		borderRadius: '20px',
		padding: row.situacao === 'ATIVA' ? '0.2rem 2.1rem' : '0.2rem 1.77rem',
	};

	if (row.situacao == 'INATIVA') {
		return Badge(Colors.kTextRed, Colors.kBgLightRed, 'Inativo', styleBackground);
	} else {
		return Badge(Colors.kTextGreen, Colors.kBgLightGreen, 'Ativo', styleBackground);
	}
}

function formatCPF(cpf) {
	let cpfAsString = cpf.toString();
	cpfAsString = cpfAsString.replace(/\D/g, '');
	cpfAsString = cpfAsString.replace(/(\d{3})(\d)/, '$1.$2');
	cpfAsString = cpfAsString.replace(/(\d{3})(\d)/, '$1.$2');
	cpfAsString = cpfAsString.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	return cpfAsString;
}

function formatCNPJ(cnpj) {
	let cnpjAsString = cnpj.toString();
	cnpjAsString = cnpjAsString.replace(/\D/g, '');
	cnpjAsString = cnpjAsString.replace(/^(\d{2})(\d)/, '$1.$2');
	cnpjAsString = cnpjAsString.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
	cnpjAsString = cnpjAsString.replace(/\.(\d{3})(\d)/, '.$1/$2');
	cnpjAsString = cnpjAsString.replace(/(\d{4})(\d)/, '$1-$2');
	return cnpjAsString;
}

function renderDocumento(row) {
	if (row.cpf) {
		return formatCPF(row.cpf);
	} else if (row.cnpj) {
		return formatCNPJ(row.cnpj);
	} else {
		return null;
	}
}

export { renderFilialInativa, renderDocumento };
