import { useFormikContext } from 'formik';

import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	estadosBotaoSalvar,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';

import { createVeiculo, deleteVeiculo, updateVeiculo } from '../../../Requests';
import { CADASTRO_URL, INITIAL_VALUES, PESQUISA_URL } from '../../../Util/constantes';
import { converterVeiculoParaApi } from '../../../Util/transporteVeiculoConverter';

function ActionButtons({ history, informacoesPermissoes, isModal, hideModal }) {
	const { values, dirty, handleReset, handleSubmit, validateForm, resetForm } = useFormikContext();

	const estadoBotaoSalvar = !dirty && isModal && values.id ? estadosBotaoSalvar.CONFIRMAR : estadosBotaoSalvar.SALVAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else if (isModal) {
			closeModal();
		} else {
			voltarParaAPesquisa(history, PESQUISA_URL);
		}
	}

	async function handleClickSalvar(novoOnSuccess) {
		handleSubmit();

		if (await validarFormulario({ validateForm, values })) {
			const dadosFormulario = values;
			if (values.id) {
				updateRegistro(dadosFormulario, novoOnSuccess);
			} else {
				createRegistro(dadosFormulario, novoOnSuccess);
			}
		}
	}

	function handleClickNovo() {
		if (dirty) {
			handleClickSalvar(onNovo);
		} else {
			onNovo();
		}
	}

	function handleClickExcluir() {
		confirmarExclusao(deleteRegistro);
	}

	async function updateRegistro(dadosFormulario, novoOnSuccess) {
		await updateVeiculo(converterVeiculoParaApi(dadosFormulario), () => {
			if (typeof novoOnSuccess === 'function') {
				novoOnSuccess();
			} else {
				resetForm({ values: dadosFormulario });
			}
		});
	}

	async function createRegistro(dadosFormulario, novoOnSuccess) {
		await createVeiculo(converterVeiculoParaApi(dadosFormulario), ({ data: veiculo }) => {
			if (typeof novoOnSuccess === 'function') {
				novoOnSuccess();
			} else {
				resetForm({ values: { ...dadosFormulario, id: veiculo.id } });
				if (!isModal) {
					atualizarUrl(history, CADASTRO_URL, veiculo.id, metodosAtualizarUrl.POP);
				} else {
					closeModal({ ...dadosFormulario, id: veiculo.id });
				}
			}
		});
	}

	async function deleteRegistro() {
		await deleteVeiculo(values.id, () => {
			voltarParaAPesquisa(history, PESQUISA_URL);
		});
	}

	function closeModal(dados = null) {
		if (dados) {
			hideModal(dados);
		} else {
			hideModal();
		}
	}

	function onNovo() {
		atualizarUrl(history, CADASTRO_URL, null, metodosAtualizarUrl.POP);
		resetForm({ values: INITIAL_VALUES });
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={handleClickCancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar
				estadoBotao={estadoBotaoSalvar}
				disabled={!dirty && estadoBotaoSalvar === estadosBotaoSalvar.SALVAR}
				onClick={handleClickSalvar}
				{...informacoesPermissoes}
			/>
			<ButtonNovo
				estadoBotao={estadoBotaoNovo}
				hidden={(!dirty && !values.id) || isModal}
				onClick={handleClickNovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir hidden={!values.id || isModal} onClick={handleClickExcluir} {...informacoesPermissoes} />
		</>
	);
}

export { ActionButtons };
