import { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { isAfter, isBefore, parseISO } from 'date-fns';

import { permissoes, recursos, services, usuarioPossuiPermissao } from 'Common';
import {
	Badge,
	Col,
	If,
	InputDate,
	InputField,
	InputMoney,
	SingleSelectCategoria,
	SingleSelectConta,
	SingleSelectFormaPagamento,
	SingleSelectPessoa,
	tiposCategoria,
} from 'components';

import { CONTA_PAGAR_STATUS } from 'views/financas/components/ModalReparcelamentoTitulos/Utils/constantes';
import { COLORS_SITUACAO, CONDICAO_PAGAMENTO_TAXA_TIPO, helpMessage } from '../../Util/constantes';

function FormBodyContaPagar({ informacoesPermissoes, isReparcelamento, isReparcelada, disabledFieldReparcelamento }) {
	const { values, setFieldValue, errors } = useFormikContext();

	const [podeVisualizarFinancasCategorias] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.VISUALIZAR)
	);
	const [podeVisualizarFinancasContas] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.VISUALIZAR)
	);
	const [podeVisualizarFinancasFormasPagamento] = useState(
		usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.VISUALIZAR)
	);

	function onBlurValor() {
		if (values.tipoTaxa === CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL && values.taxaAliquota) {
			setFieldValue('taxaValor', (values.valor * values.taxaAliquota) / 100);
		} else if (values.tipoTaxa === CONDICAO_PAGAMENTO_TAXA_TIPO.VALOR && values.taxaValor) {
			setFieldValue('taxaAliquota', (values.taxaValor * 100) / values.valor);
		}
	}

	function renderSituacao(contaPagar) {
		const styleBackground = {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '32px',
			borderRadius: '20px',
			alignSelf: 'center',
			marginTop: '15px',
		};

		const vencimentoDate = parseISO(contaPagar.vencimento);
		const currentDate = new Date();
		const isToday = currentDate.toDateString() === vencimentoDate.toDateString();

		if (contaPagar.status === CONTA_PAGAR_STATUS.NAO_PAGA && (isAfter(vencimentoDate, currentDate) || isToday)) {
			return Badge(COLORS_SITUACAO.INFO.text, COLORS_SITUACAO.INFO.bg, 'Pendente', styleBackground);
		}
		if (contaPagar.status === CONTA_PAGAR_STATUS.NAO_PAGA && isBefore(vencimentoDate, currentDate)) {
			return Badge(COLORS_SITUACAO.DANGER.text, COLORS_SITUACAO.DANGER.bg, 'Pendente / Vencida', styleBackground);
		}
		if (
			contaPagar.status === CONTA_PAGAR_STATUS.PARCIALMENTE_PAGA &&
			(isAfter(vencimentoDate, currentDate) || isToday)
		) {
			return Badge(COLORS_SITUACAO.WARNING.text, COLORS_SITUACAO.WARNING.bg, 'Parcialmente paga', styleBackground);
		}
		if (contaPagar.status === CONTA_PAGAR_STATUS.PARCIALMENTE_PAGA && isBefore(vencimentoDate, currentDate)) {
			return Badge(
				COLORS_SITUACAO.DANGER.text,
				COLORS_SITUACAO.DANGER.bg,
				'Parcialmente paga / Vencida',
				styleBackground
			);
		}
		if (contaPagar.status === CONTA_PAGAR_STATUS.PAGA) {
			return Badge(COLORS_SITUACAO.SUCCESS.text, COLORS_SITUACAO.SUCCESS.bg, 'Paga', styleBackground);
		}
		if (contaPagar.status === CONTA_PAGAR_STATUS.REPARCELADA) {
			return Badge(COLORS_SITUACAO.NEUTRAL.text, COLORS_SITUACAO.NEUTRAL.bg, 'Reparcelada', styleBackground);
		}

		return Badge(COLORS_SITUACAO.INFO.text, COLORS_SITUACAO.INFO.bg, 'Pendente', styleBackground);
	}

	return (
		<>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputMoney}
				autofocus
				label="Valor"
				name="valor"
				obrigatorio
				size={15}
				helpMessage={helpMessage.valor}
				onChange={(e) => setFieldValue('valor', e.target.value)}
				onBlur={onBlurValor}
				value={values.valor}
				allowNegative={false}
				id="ContasPagarInputMoneyValor"
				disabled={values.pagamentos?.length > 0 || isReparcelamento || isReparcelada}
				errors={errors?.valor}
				touched={!!errors.valor}
				useFormErrors={false}
				useFormTouched={false}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputDate}
				label="Emissão"
				name="emissao"
				obrigatorio
				helpMessage={helpMessage.emissao}
				onChange={(e) => setFieldValue('emissao', e.target.value)}
				onBlur={(e) => setFieldValue('dataDocumento', e.target.value)}
				value={values.emissao}
				disabled={values.pagamentos?.length > 0}
				errors={errors?.emissao}
				touched={!!errors.emissao}
				useFormErrors={false}
				useFormTouched={false}
				{...informacoesPermissoes}
			/>

			<If test={!isReparcelamento}>
				<Col sm="12" md="4" lg="4" xl="4">
					{renderSituacao(values)}
				</Col>
			</If>

			<Field
				sm="12"
				md={isReparcelamento ? '4' : '6'}
				lg={isReparcelamento ? '4' : '6'}
				xl={isReparcelamento ? '4' : '6'}
				component={InputDate}
				label="Vencimento"
				name="vencimento"
				obrigatorio
				helpMessage={helpMessage.vencimento}
				onChange={(e) => setFieldValue('vencimento', e.target.value)}
				onBlur={(e) => setFieldValue('competencia', e.target.value)}
				value={values.vencimento}
				disabled={values.pagamentos?.length > 0}
				errors={errors?.vencimento}
				touched={!!errors.vencimento}
				useFormErrors={false}
				useFormTouched={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				name="pessoa"
				label="Pessoa (credor)"
				component={SingleSelectPessoa}
				value={values.pessoa}
				helpMessage={helpMessage.pessoa}
				onChange={(e) => setFieldValue('pessoa', e)}
				url={`${services.GESTOR}/v1/contas_pagar/relacoes/pessoas`}
				disabled={values.pagamentos?.length > 0 || disabledFieldReparcelamento}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md={isReparcelamento ? '6' : '12'}
				lg={isReparcelamento ? '6' : '12'}
				xl={isReparcelamento ? '6' : '12'}
				component={InputField}
				label="Descrição"
				name="descricao"
				helpMessage={helpMessage.descricao}
				size={255}
				disabled={values.pagamentos?.length > 0}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				name="formaPagamento"
				label="Forma de pagamento"
				helpMessage={helpMessage.formaPagamento}
				component={SingleSelectFormaPagamento}
				value={values.formaPagamento}
				onChange={(e) => setFieldValue('formaPagamento', e)}
				url={`${services.GESTOR}/v1/contas_pagar/relacoes/formas_pagamento`}
				disabledButton={!podeVisualizarFinancasFormasPagamento || values.pagamentos?.length > 0}
				disabled={values.pagamentos?.length > 0}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				name="conta"
				label="Conta"
				helpMessage={helpMessage.conta}
				component={SingleSelectConta}
				value={values.conta}
				onChange={(e) => setFieldValue('conta', e)}
				url={`${services.GESTOR}/v1/contas_pagar/relacoes/contas`}
				disabledButton={!podeVisualizarFinancasContas || values.pagamentos?.length > 0}
				disabled={values.pagamentos?.length > 0}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				name="categoria"
				label="Categoria"
				tipoCategoria={tiposCategoria.DESPESA}
				helpMessage={helpMessage.categoria}
				component={SingleSelectCategoria}
				value={values.categoria}
				onChange={(e) => setFieldValue('categoria', e)}
				url={`${services.GESTOR}/v1/contas_pagar/relacoes/categorias/despesas`}
				disabledButton={!podeVisualizarFinancasCategorias || values.pagamentos?.length > 0}
				disabled={values.pagamentos?.length > 0}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export { FormBodyContaPagar };
