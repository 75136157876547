/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { If, Message } from 'components';
import { useContextNFCe } from '../../../../../Context';
import { asyncGetLogotipo } from '../../../../../Requests';

export function Logo(props) {
	const { isFullScreen, isTablet, idNFCe, isLessHd, credencial, history } = props;
	const { widthMessage, setWidthMessage, didRequestLogo, logoUrl, setLogoUrl, setPodeInserir } = useContextNFCe();

	const [logoWidth, setLogoWidth] = useState(widthMessage);
	const [filialPodeTransmitirNfce, setFilialPodeTransmitirNfce] = useState(true);

	useEffect(() => {
		const elementDivProduto = document.getElementById('id-nfce-produto');
		if (!didRequestLogo.current) {
			carregarLogo();
		}

		filialConectadaPodeTransmitirNFCe();

		const heightObserver = new ResizeObserver(() => {
			if (elementDivProduto) {
				const newWidth = elementDivProduto.offsetWidth;
				if (newWidth !== widthMessage) {
					setLogoWidth(newWidth);
				}
			}
		});

		heightObserver.observe(elementDivProduto);

		return () => {
			if (elementDivProduto) {
				heightObserver.unobserve(elementDivProduto);
			} else {
				heightObserver.disconnect();
			}
		};
	}, []);

	useEffect(() => {
		setWidthMessage(logoWidth);
	}, [logoWidth]);

	async function carregarLogo() {
		await asyncGetLogotipo(({ data: logo }) => {
			setLogoUrl(URL.createObjectURL(logo));
		});
		didRequestLogo.current = true;
	}

	function getHeightLogo() {
		return isLessHd && isFullScreen && !idNFCe
			? 340
			: isLessHd && isFullScreen
				? 380
				: isLessHd && !idNFCe
					? 390
					: isLessHd
						? 433
						: !isFullScreen && !isTablet
							? 390
							: isFullScreen && !isTablet
								? 338
								: isTablet
									? 555
									: 390;
	}

	function filialConectadaPodeTransmitirNFCe() {
		if (credencial?.filialConectada?.parametrosFiscalNFCe) {
			if (
				!credencial.filialConectada.parametrosFiscalCertificado ||
				!credencial.filialConectada.parametrosFiscalNFCe.serieNfce
			) {
				setPodeInserir(false);
				setFilialPodeTransmitirNfce(false);
			} else {
				setFilialPodeTransmitirNfce(true);
			}
		}
	}

	function buscarMensagemOrganizacaoPodeTransmitirNfe() {
		return (
			<span style={{ fontSize: '14px' }}>
				{getTitleButtonFinalizar()}
				<div>
					<b
						style={{ cursor: 'pointer' }}
						onClick={() =>
							history.push({
								pathname: '/configuracoes_gerais',
								state: { configurarSerieNfce: true },
							})
						}
					>
						Clique aqui para configurar
					</b>
				</div>
			</span>
		);
	}

	function getTitleButtonFinalizar() {
		if (credencial?.filialConectada?.parametrosFiscalNFCe) {
			if (!credencial?.filialConectada?.parametrosFiscalCertificado) {
				return 'Organização sem certificado digital configurado para transmissão de NFC-e';
			} else if (!credencial.filialConectada.parametrosFiscalNFCe.serieNfce) {
				return 'Série ou número da NFC-e não configurada';
			}
		}
		return '';
	}

	return (
		<>
			<If test={filialPodeTransmitirNfce === false}>
				<div
					style={{
						position: 'absolute',
						width: widthMessage,
						top: document.getElementById('id-logo-nfce')?.offsetTop,
						zIndex: '1',
					}}
				>
					<Message
						severity="error"
						text={buscarMensagemOrganizacaoPodeTransmitirNfe()}
						colStyle={{ padding: '0px', paddingRight: '1rem' }}
					/>
				</div>
			</If>
			<div
				id="id-logo-nfce"
				style={{
					height: `${useWindowSize().height - getHeightLogo()}px`,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '0.7rem',
				}}
			>
				<img src={logoUrl} alt="Logo" style={{ height: '100%', width: '100%', opacity: '0.5', objectFit: 'contain' }} />
			</div>
		</>
	);
}
