import PropTypes from 'prop-types';

export default function Box(props) {
	function montarClassName() {
		const { stretched, disabled } = props;

		let classe = 'box';

		if (stretched) {
			classe += ' box-stretched';
		}
		if (disabled) {
			classe += ' p-disabled';
		}

		return classe;
	}

	const { children, style, hidden, id, onClick } = props;

	const classe = montarClassName(props);

	return (
		<div id={id} style={style} hidden={hidden} className={classe} onClick={onClick}>
			{children}
		</div>
	);
}

Box.propTypes = {
	/** Define se a caixa irá ocupar mais de uma coluna */
	stretched: PropTypes.bool,
	/** Define o id do componente */
	id: PropTypes.string,
	/** Define o nome do componente */
	style: PropTypes.object,
	/** Esconde o componente */
	hidden: PropTypes.bool,
	/** Esconde o componente */
	children: PropTypes.any,
	/** Clique */
	onClick: PropTypes.func,
	/** Escurece o componente */
	disabled: PropTypes.bool,
};
