import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';
import { useFormikContext, withFormik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import StringMask from 'string-mask';

import {
	actionTypes,
	buscarDadosLoginLocalStorage,
	dispatchAction,
	manterApenasNumeros,
	mensagensDeValidacao,
	permissoes,
	recursos,
	salvarDadosLoginLocalStorage,
	usuarioPossuiPermissao,
} from 'Common';

import { Form, FormActions, FormContent, Grid, If, Prompt, ToastTypes, notify } from 'components';

import { validarFormulario } from 'views/Util';
import { useEffect } from 'react';
import semImagemImg from './Images/sem_imagem.svg';
import {
	asyncBuscarInformacoesCredencial,
	asyncGetConfiguracoesEmpresa,
	asyncGetLogotipoEmpresa,
	asyncUpdateConfiguracoesEmpresa,
	asyncUploadCertificado,
	asyncUploadLogotipoEmpresa,
} from './Requests';
import {
	converterConfiguracoesGeraisParaApi,
	converterConfiguracoesGeraisParaFormulario,
} from './Util/configuracoesGeraisConverter';
import ModalAliquotaSimples from './components/ModalAliquotaSimples';
import ModalExcluirEmpresa from './components/ModalExcluirEmpresa';

import { useContextConfiguracoesGerais } from './Context';
import { INITIAL_VALUES, TABS } from './Util/constantes';
import { ActionButtons } from './components/ActionButtons';
import { ModalContador } from './components/ModalContador';
import { TabsConfiguracoesGerais } from './components/TabsConfiguracoesGerais';

function ConfiguracoesGeraisViews({ location, history, isModal, isMobile, isTablet, ...props }) {
	const { values, errors, dirty, initialValues, resetForm, handleSubmit, submitCount } = useFormikContext();

	const {
		setPodeVisualizarVendas,
		novoLogotipo,
		setNovoLogotipo,
		modalExcluirEmpresaVisible,
		setModalExcluirEmpresaVisible,
		modalConfigurarAliquotaSimplesVisible,
		setModalConfigurarAliquotaSimplesVisible,
		marcarCheckboxEmiteNfe,
		setMarcarCheckboxEmiteNfe,
		marcarCheckboxEmiteNfce,
		setMarcarCheckboxEmiteNfce,
		marcarCheckboxEmiteNfse,
		setMarcarCheckboxEmiteNfse,
		marcarCheckboxEmiteMdfe,
		setMarcarCheckboxEmiteMdfe,
		setAlterarNumeroInicialESerieNfe,
		setAlterarNumeroInicialESerieNfce,
		setAlterarNumeroInicialESerieNfse,
		setAlterarNumeroInicialESerieMdfe,
		visibleModalContador,
		setVisibleModalContador,
		informacoesPermissoes,
		setTabConfiguracoesGerais,
		setTabConfiguracoesFiscais,
	} = useContextConfiguracoesGerais();

	const estadosDosCheckbox = {
		checkNfe: marcarCheckboxEmiteNfe,
		checkNfce: marcarCheckboxEmiteNfce,
		checkNfse: marcarCheckboxEmiteNfse,
		checkMdfe: marcarCheckboxEmiteMdfe,
	};

	useEffectOnce(() => {
		if (location.state && location.state.configurarSerieNfe) {
			setTabConfiguracoesGerais(3);
			setTabConfiguracoesFiscais(0);
			setMarcarCheckboxEmiteNfe(true);
			estadosDosCheckbox.checkNfe = true;
		} else if (location.state && location.state.configurarSerieNfce) {
			setTabConfiguracoesGerais(3);
			setTabConfiguracoesFiscais(1);
			setMarcarCheckboxEmiteNfce(true);
			estadosDosCheckbox.checkNfce = true;
		} else if (location.state && location.state.configurarSerieNfse) {
			setTabConfiguracoesGerais(3);
			setTabConfiguracoesFiscais(2);
			setMarcarCheckboxEmiteNfse(true);
			estadosDosCheckbox.checkNfse = true;
		} else if (location.state && location.state.configurarSerieMdfe) {
			setTabConfiguracoesGerais(3);
			setTabConfiguracoesFiscais(3);
			setMarcarCheckboxEmiteMdfe(true);
			estadosDosCheckbox.checkMdfe = true;
		} else if (location.state && location.state.configurarPreferencias) {
			setTabConfiguracoesGerais(3);
		}

		asyncSelectRegistro(
			estadosDosCheckbox.checkNfe,
			estadosDosCheckbox.checkNfce,
			estadosDosCheckbox.checkNfse,
			estadosDosCheckbox.checkMdfe
		);

		setTimeout(() => {
			document.getElementById('ConfiguracoesInputNome')?.focus();
		}, 500);
	});

	useEffect(() => {
		if (errors.tab || errors.tab === 0) {
			setTabConfiguracoesGerais(errors.tab);
		}
	}, [errors, submitCount]);

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues, errors: {} });
		} else {
			history.goBack();
		}
	}

	async function salvar() {
		handleSubmit();
		if (await validarFormulario(props)) {
			const dadosFormulario = converterConfiguracoesGeraisParaApi(values);
			await asyncUpdateConfiguracoesEmpresa(dadosFormulario, async () => {
				let erroUpload = false;
				if (values.logotipoUrl !== initialValues.logotipoUrl) {
					await asyncUploadLogotipoEmpresa(novoLogotipo, null, () => (erroUpload = true));
				}
				if (values.certificado !== initialValues.certificado) {
					await asyncUploadCertificado(values.certificado, values.certificadoSenha, null, () => (erroUpload = true));
				}
				if (!erroUpload) {
					notify('Configurações atualizadas', ToastTypes.SUCCESS);
					asyncSelectRegistro();
				}
				dispatchAction(actionTypes.MENU_LATERAL_ATUALIZAR_FILIAL, true);
			});
		}
	}

	async function asyncSelectRegistro(emiteNfe, emiteNfce, emiteNfse, emiteMdfe) {
		asyncGetConfiguracoesEmpresa(async ({ data: dadosEmpresa }) => {
			await asyncBuscarInformacoesCredencial(async (e) => {
				let dadosLogin = await buscarDadosLoginLocalStorage();
				dadosLogin = {
					...e.data,
					...dadosLogin,
					setores: e.data.setores,
					configuracoes: e.data.configuracoes,
					organizacao: e.data.organizacao,
					recursos: e.data.recursos,
				};
				dadosLogin.organizacao = {
					...dadosLogin.organizacao,
					nome: dadosEmpresa.nome,
					fiscalNfe: dadosEmpresa.parametrosFiscalNFe,
					fiscalNfce: dadosEmpresa.parametrosFiscalNFCe,
					documentos: dadosEmpresa.parametrosDocumentos,
					cadastros: dadosEmpresa.parametrosCadastros,
					vendas: dadosEmpresa.parametrosVendas,
				};
				dadosLogin.filialConectada = e.data.filialConectada;
				salvarDadosLoginLocalStorage(dadosLogin);
			});

			let logotipoUrl = semImagemImg;
			await asyncGetLogotipoEmpresa(({ data: logotipo }) => {
				logotipoUrl = URL.createObjectURL(logotipo);
			});

			setNovoLogotipo(null);
			setPodeVisualizarVendas(usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR));

			const converterParaFormulario = converterConfiguracoesGeraisParaFormulario(
				{ ...dadosEmpresa, logotipoUrl },
				emiteNfe ?? marcarCheckboxEmiteNfe,
				emiteNfce ?? marcarCheckboxEmiteNfce,
				emiteNfse ?? marcarCheckboxEmiteNfse,
				emiteMdfe ?? marcarCheckboxEmiteMdfe
			);

			setAlterarNumeroInicialESerieNfce(converterParaFormulario.alterarNumeroInicialESerieNfce);
			setAlterarNumeroInicialESerieNfe(converterParaFormulario.alterarNumeroInicialESerieNfe);
			setAlterarNumeroInicialESerieNfse(converterParaFormulario.alterarNumeroInicialESerieNfse);
			setAlterarNumeroInicialESerieMdfe(converterParaFormulario.alterarNumeroInicialESerieMdfe);
			resetForm({
				values: converterParaFormulario,
				errors: null,
			});
		});
	}

	return (
		<>
			<Prompt
				dirty={dirty}
				isModal={isModal}
				message={`Tem certeza que deseja sair? \nAs alterações serão perdidas.`}
			/>
			<Form className="card-default screen-max-width" header="Configurações gerais" isModal={isModal}>
				<FormActions className="screen-max-width">
					<ActionButtons informacoesPermissoes={informacoesPermissoes} cancelar={cancelar} salvar={salvar} />
				</FormActions>
				<FormContent>
					<Grid>
						<TabsConfiguracoesGerais />
					</Grid>
				</FormContent>
			</Form>
			<ModalExcluirEmpresa visible={modalExcluirEmpresaVisible} onHide={() => setModalExcluirEmpresaVisible(false)} />
			<If test={modalConfigurarAliquotaSimplesVisible}>
				<ModalAliquotaSimples
					visible={modalConfigurarAliquotaSimplesVisible}
					onHide={() => setModalConfigurarAliquotaSimplesVisible(false)}
				/>
			</If>
			<If test={visibleModalContador}>
				<ModalContador visible={visibleModalContador} onHide={() => setVisibleModalContador(false)} />
			</If>
		</>
	);
}

const ConfiguracoesGerais = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	async validate(values) {
		const errors = {};
		const pattern = /[^áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑa-zA-Z0-9-,.&\s]/;
		const camposParaValidar = ['logradouro', 'numero', 'bairro', 'complemento', 'cep'];

		Object.entries(values).forEach(([key, value]) => {
			if (
				typeof value !== 'object' &&
				typeof value !== 'boolean' &&
				camposParaValidar.some((item) => key.includes(item))
			) {
				if (pattern.test(value)) {
					errors[key] = mensagensDeValidacao.CARACTER_ESPECIAL;
				}
			}
		});
		const regexNumeros = /^\d{8,9}$/;

		if (values.suframa && !regexNumeros.test(values.suframa)) {
			errors.suframa = 'Mínimo de 8 e máximo de 9 dígitos';
		}

		if (!values.nome) {
			errors.nome = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}

		if (!values.nomeApresentacao) {
			errors.nomeApresentacao = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}

		if (!values.telefone) {
			errors.telefone = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}

		if (
			!new StringMask('(00) 00000-0000').validate(manterApenasNumeros(values.telefone)) &&
			!new StringMask('(00) 0000-0000').validate(manterApenasNumeros(values.telefone))
		) {
			errors.telefone = mensagensDeValidacao.TELEFONE_INVALIDO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}

		if (!values.email) {
			errors.email = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}

		if (values.tipo === 'FISICA') {
			if (!values.cpf) {
				errors.cpf = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.INFORMACOES_EMPRESA;
			}
			if (values.cpf && !isValidCPF(manterApenasNumeros(values.cpf))) {
				errors.cpf = 'Digite um CPF válido.';
				errors.tab = TABS.INFORMACOES_EMPRESA;
			}
		}

		if (values.tipo === 'JURIDICA') {
			if (!values.cnpj) {
				errors.cnpj = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.INFORMACOES_EMPRESA;
			}
			if (values.cnpj && !isValidCNPJ(manterApenasNumeros(values.cnpj))) {
				errors.cnpj = 'Digite um CNPJ válido.';
				errors.tab = TABS.INFORMACOES_EMPRESA;
			}
		}

		if (!values.modulos || values.modulos.length === 0) {
			errors.modulos = 'Selecione ao menos um módulo do sistema';
			errors.tab = TABS.MODULOS;
		}

		if (!values.cep) {
			errors.cep = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}
		if (!values.razaoSocial) {
			errors.razaoSocial = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}
		if (!values.numero) {
			errors.numero = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}
		if (!values.bairro) {
			errors.bairro = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}
		if (!values.logradouro) {
			errors.logradouro = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}
		if (!values.regimeTributario) {
			errors.regimeTributario = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}
		if (!values.inscricaoEstadual) {
			errors.inscricaoEstadual = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}
		if (!values.municipio) {
			errors.municipio = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}
		if (!values.pais) {
			errors.pais = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = TABS.INFORMACOES_EMPRESA;
		}

		if (values.certificadoAlterado) {
			if (!values.certificadoSenha) {
				errors.certificadoSenha = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.CONFIGURACOES_FISCAIS;
			}
			if (values.certificadoSenha !== values.certificadoConfirmacaoSenha) {
				errors.certificadoConfirmacaoSenha = 'A senha e a confirmação da senha não conferem';
				errors.tab = TABS.CONFIGURACOES_FISCAIS;
			}
		}

		if (values.emiteNfe) {
			if (!values.tipoImpressaoDanfeNfe) {
				errors.tipoImpressaoDanfeNfe = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.CONFIGURACOES_FISCAIS;
			}
			if (!values.serieNfe) {
				errors.serieNfe = 'Campo obrigatório';
				errors.tab = TABS.CONFIGURACOES_FISCAIS;
			}
		}

		if (values.emiteNfce) {
			if (!values.tipoImpressaoDanfeNfce) {
				errors.tipoImpressaoDanfeNfce = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.CONFIGURACOES_FISCAIS;
			}
			if (!values.serieNfce) {
				errors.serieNfce = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.CONFIGURACOES_FISCAIS;
			}
			if (!values.codigoSegurancaContribuinteNfce) {
				errors.codigoSegurancaContribuinteNfce = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.CONFIGURACOES_FISCAIS;
			}
			if (!values.tokenSegurancaContribuinteNfce) {
				errors.tokenSegurancaContribuinteNfce = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.CONFIGURACOES_FISCAIS;
			}
		}

		if (values.emiteNfse) {
			if (!values.integracaoNfse) {
				errors.integracaoNfse = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.CONFIGURACOES_FISCAIS;
			}
			if (!values.serieNfse) {
				errors.serieNfse = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.CONFIGURACOES_FISCAIS;
			}
			if (!values.numeroInicialRps) {
				errors.numeroInicialRps = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.CONFIGURACOES_FISCAIS;
			}
			if (!values.loteInicialRps) {
				errors.loteInicialRps = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.CONFIGURACOES_FISCAIS;
			}
		}

		if (values.parametrizacao.documentos?.emailAlertaVigencia) {
			if (
				!values.parametrizacao.documentos?.diasAlertaVigencia &&
				typeof values.parametrizacao.documentos?.diasAlertaVigencia !== 'number'
			) {
				errors.diasAlertaVigencia = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.PARAMETRIZACAO;
			}
			if (!values.parametrizacao.documentos?.tipoAlertaVigencia) {
				errors.vezesAlertaVigencia = mensagensDeValidacao.OBRIGATORIO;
				errors.tab = TABS.PARAMETRIZACAO;
			}
		}

		return errors;
	},

	handleSubmit: () => {},
})(ConfiguracoesGeraisViews);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default withRouter(connect(mapStateToProps)(ConfiguracoesGerais));
