export const tutorialSteps = [
	{
		target: 'body',
		content: `Esta é a tela para cadastro de gestão de documentos Nela você pode visualizar e editar o seu documento`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-identificador-documento',
		content:
			'Neste campo, você pode criar um identificador para o documento para auxiliar na organização e pesquisas futuras',
		placement: 'top',
	},
	{
		target: '.step-primeiras_informacoes-documento',
		content: 'Estes são os campos obrigatórios para criar um novo documento',
		placement: 'top',
	},
	{
		target: '.step-partes-documento',
		content: 'Neste campo, você pode incluir os participantes do documento',
		placement: 'top',
	},
	{
		target: '.step-produtos-documento',
		content:
			'Neste campo, você pode incluir os produtos do documento. Lembre-se, para um produto aparecer aqui, ele deve ter a opção "Produto utilizado no módulo de documentos" marcada, essa opção é encontrada dentro do cadastro de produtos.',
		placement: 'top',
	},
	{
		target: '.step-documento-arquivo',
		content: 'Neste campo, você pode incluir arquivos/assinaturas no documento',
		placement: 'top',
	},
	{
		target: '.step-aditivo-documento',
		content: 'Quando o status do documento for "Vigente", é possivel adicionar anexos ao documento.',
		placement: 'top',
	},
];
