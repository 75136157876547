const convenioFieldsPorBanco = {
	//BB
	'cce6d2ff-71ed-48a9-88ff-20932616acf9': [
		'cnab',
		'numero',
		'descricao',
		'situacao',
		'numeroCarteira',
		'numeroRemessaReiniciaDiariamente',
		'numeroRemessa',
		'tipoWebservice',
		'nossoNumero',
		'percentualDesconto',
		'percentualJuros',
		'percentualMulta',
		'mensagem01',
		'mensagem02',
		'apiId',
		'apiKey',
		'apiSecret',
	],
	//Santander
	'41b59254-ad4f-45b2-9172-39fd8cc827c3': [
		'cnab',
		'numero',
		'descricao',
		'situacao',
		'numeroCarteira',
		'numeroRemessaReiniciaDiariamente',
		'numeroRemessa',
		'nossoNumero',
		'percentualDesconto',
		'percentualJuros',
		'percentualMulta',
		'mensagem01',
		'mensagem02',
		'apiEstacao',
	],
	//Banrisul
	'0d771543-f868-440f-90bc-8919e9943e24': [
		'cnab',
		'numero',
		'descricao',
		'situacao',
		'numeroCarteira',
		'codigoCarteira',
		'numeroRemessaReiniciaDiariamente',
		'numeroRemessa',
		'numeroContrato',
		'versaoLayoutArquivo',
		'nossoNumero',
		'percentualDesconto',
		'percentualJuros',
		'percentualMulta',
		'mensagem01',
		'mensagem02',
	],
	//caixa
	'c9c29c8e-56b0-4e53-bf87-2ad12c0fa94e': [
		'cnab',
		'numero',
		'descricao',
		'situacao',
		'numeroCarteira',
		'numeroRemessaReiniciaDiariamente',
		'numeroRemessa',
		'versaoLayoutArquivo',
		'nossoNumero',
		'percentualDesconto',
		'percentualJuros',
		'percentualMulta',
		'mensagem01',
		'mensagem02',
	],
	//unicred
	'42bdc03d-06c1-4fcb-ac9e-6a9d99117099': [
		'cnab',
		'numero',
		'descricao',
		'situacao',
		'numeroCarteira',
		'numeroRemessa',
		'nossoNumero',
		'percentualDesconto',
		'percentualJuros',
		'percentualMulta',
		'mensagem01',
		'mensagem02',
		'apiId',
		'apiKey',
		'apiSecret',
		'apiEstacao',
	],
	//bradesco
	'd5dc03be-d0f4-4063-b992-60ea79df91de': [
		'cnab',
		'numero',
		'descricao',
		'situacao',
		'numeroCarteira',
		'numeroRemessaReiniciaDiariamente',
		'numeroRemessa',
		'densidadeRemessa',
		'tipoWebservice',
		'nossoNumero',
		'percentualDesconto',
		'percentualJuros',
		'percentualMulta',
		'mensagem01',
		'mensagem02',
		'apiId',
		'apiKey',
		'apiSecret',
	],
	//itau
	'ac9a6bb7-f836-45b0-bed2-88122067450f': [
		'cnab',
		'numero',
		'descricao',
		'situacao',
		'numeroCarteira',
		'numeroRemessa',
		'nossoNumero',
		'percentualDesconto',
		'percentualJuros',
		'percentualMulta',
		'mensagem01',
		'mensagem02',
		'apiId',
		'apiSecret',
	],
	//sicredi
	'fe79b0ad-602f-4fa9-a1c8-6a0e5a6447ad': [
		'cnab',
		'numero',
		'descricao',
		'situacao',
		'numeroCarteira',
		'numeroRemessaReiniciaDiariamente',
		'numeroRemessa',
		'nossoNumero',
		'percentualDesconto',
		'percentualJuros',
		'percentualMulta',
		'mensagem01',
		'mensagem02',
		'apiEstacao',
		'apiId',
		'apiKey',
		'apiSecret',
	],
	//sicoob
	'207cf63b-167a-42da-acb7-eb7be66fde67': [
		'cnab',
		'numero',
		'descricao',
		'situacao',
		'numeroCarteira',
		'codigoCarteira',
		'numeroRemessaReiniciaDiariamente',
		'numeroRemessa',
		'tipoWebservice',
		'numeroContrato',
		'nossoNumero',
		'percentualDesconto',
		'percentualJuros',
		'percentualMulta',
		'mensagem01',
		'mensagem02',
		'apiId',
	],
};

const fieldsComExcecoes = ['apiSecret'];

const optionsCnab = [
	{ label: '240', value: 'CNAB240' },
	{ label: '400', value: 'CNAB400' },
];

const optionsSituacao = [
	{ label: 'Ativa', value: 'ATIVA' },
	{ label: 'Inativa', value: 'INATIVA' },
];

const optionsRemessaReiniciaDiariamente = [
	{ label: 'Sim', value: true },
	{ label: 'Não', value: false },
];

const optionsDensidadeRemessa = [
	{ label: '1600', value: 1600 },
	{ label: '6250', value: 6250 },
];

const optionsTipoWebservice = [
	{ label: 'V2', value: 'V2', banco: 'cce6d2ff-71ed-48a9-88ff-20932616acf9' }, //BB
	{ label: 'Normal', value: 'NORMAL', banco: 'd5dc03be-d0f4-4063-b992-60ea79df91de' }, //Bradesco
	{ label: 'Shop Fácil', value: 'SHOPFACIL', banco: 'd5dc03be-d0f4-4063-b992-60ea79df91de' }, //Bradesco
	{ label: 'V1', value: 'V1', banco: '207cf63b-167a-42da-acb7-eb7be66fde67' }, //Sicoob
	{ label: 'V2', value: 'V2', banco: '207cf63b-167a-42da-acb7-eb7be66fde67' }, //Sicoob
	{ label: 'V2.2', value: 'V2_2', banco: '207cf63b-167a-42da-acb7-eb7be66fde67' }, //Sicoob
];

const optionsNossoNumeroPeloBanco = [
	{ label: 'Sim', value: true },
	{ label: 'Não', value: false },
];

const bancosHomologados = [
	'cce6d2ff-71ed-48a9-88ff-20932616acf9', //BB
	'41b59254-ad4f-45b2-9172-39fd8cc827c3', //Santander
	'0d771543-f868-440f-90bc-8919e9943e24', //Banrisul
	'c9c29c8e-56b0-4e53-bf87-2ad12c0fa94e', //Caixa
	'42bdc03d-06c1-4fcb-ac9e-6a9d99117099', //Unicred
	'd5dc03be-d0f4-4063-b992-60ea79df91de', //Bradesco
	'ac9a6bb7-f836-45b0-bed2-88122067450f', //Itau
	'fe79b0ad-602f-4fa9-a1c8-6a0e5a6447ad', //Sicredi
	'207cf63b-167a-42da-acb7-eb7be66fde67', //Sicoob
];

export {
	convenioFieldsPorBanco,
	fieldsComExcecoes,
	optionsCnab,
	optionsSituacao,
	optionsRemessaReiniciaDiariamente,
	optionsDensidadeRemessa,
	optionsTipoWebservice,
	optionsNossoNumeroPeloBanco,
	bancosHomologados,
};
