import { del, exibirToast, get, post, put, services } from 'Common';

export async function asyncGetEtiquetas(url, onSuccess, onError) {
  return await get(url, null, onSuccess, onError);
}

export async function asyncGetEtiqueta(id, onSuccess, onError) {
  return await get(`${services.GESTOR}/v1/etiquetas/${id}`, null, onSuccess, onError);
}

export async function asyncCreateEtiqueta(data, onSuccess, onError) {
  await post(
    `${services.GESTOR}/v1/etiquetas`,
    data,
    null,
    exibirToast(onSuccess, 'Etiqueta criada com sucesso'),
    onError
  );
}

export async function asyncUpdateEtiqueta(data, onSuccess, onError) {
  await put(
    `${services.GESTOR}/v1/etiquetas/${data.id}`,
    data,
    null,
    exibirToast(onSuccess, 'Etiqueta atualizada com sucesso'),
    onError
  );
}

export async function asyncDeleteEtiqueta(id, messageOnSuccess = 'Registro excluído com sucesso', onSuccess, onError) {
  await del(`${services.GESTOR}/v1/etiquetas/${id}`, null, exibirToast(onSuccess, messageOnSuccess), onError);
}

export async function asyncGetCamposDisponiveis(onSuccess, onError) {
  await get(`${services.GESTOR}/v1/etiquetas/campos_disponiveis?page=0&size=50`, null, onSuccess, onError);
}
