import { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import {
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Card,
	Dropdown,
	Grid,
	If,
	NenhumRegistroEncontrado,
} from 'components';
import { formatarMonetario, formatos, inserirMascara } from 'Common';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';
import {
	MDFE_CATEGORIA_COMBINACAO_VEICULAR_OPTIONS,
	MDFE_TIPO_VALE_PEDAGIO,
} from 'views/transporte/MDFe/Form/Util/constantes';

import { ModalValePedagio } from './components/ModalValePedagio';

function ValePedagio() {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, isSituacaoFinalMDFe, isMobile } = useContextMDFe();
	const [sortOrder, setSortOrder] = useState(1);
	const [sortField, setSortField] = useState('numeroComprovante');
	const [modalValePedagioVisible, setModalValePedagioVisible] = useState(false);
	const [indexRegistro, setIndexRegistro] = useState(null);
	const [isNovoRegistro, setIsNovoRegistro] = useState(false);

	function renderFieldnumeroComprovante(row) {
		return row?.numeroComprovante ?? null;
	}

	function renderFieldValor(row) {
		return formatarMonetario(row.valePedagioValor);
	}

	function renderCnpjFornecedor(row) {
		return inserirMascara(row?.fornecedoraCnpj, formatos.CNPJ);
	}

	function renderFieldTipoValePedagio(row) {
		switch (row.valePedagioTipo) {
			case MDFE_TIPO_VALE_PEDAGIO.CARTAO: {
				return 'Cartão';
			}
			case MDFE_TIPO_VALE_PEDAGIO.CUPOM: {
				return 'Cupom';
			}
			case MDFE_TIPO_VALE_PEDAGIO.TAG: {
				return 'Tag ';
			}
			default:
				return null;
		}
	}

	function renderCpfCnpjPagador(row) {
		if (row?.tipoPessoa === TIPO_PESSOA.JURIDICA) {
			return inserirMascara(row?.cnpj, formatos.CNPJ);
		} else if (row?.tipoPessoa === TIPO_PESSOA.FISICA) {
			return inserirMascara(row?.cpf, formatos.CPF);
		}

		return null;
	}

	function renderAcoes(row) {
		return (
			<div>
				<ButtonEditarTable title="Editar" onClick={() => onEdit(row)} />
				<ButtonExcluirTable onClick={() => onRemove(row)} disabled={isSituacaoFinalMDFe} {...informacoesPermissoes} />
			</div>
		);
	}

	function onEdit(row) {
		setModalValePedagioVisible(true);
		values?.valePedagio.forEach((vale, index) => {
			if (vale.id === row.id) {
				setIndexRegistro(index);
			}
		});
	}

	function onRemove(row) {
		const valePedagioList = values.valePedagio.filter((vale) => vale?.id !== row?.id);
		setFieldValue('valePedagio', valePedagioList);
	}

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	return (
		<>
			<Grid style={{ alignItems: 'flex-end' }}>
				<Field
					sm="12"
					md="10"
					lg="5"
					xl="5"
					component={Dropdown}
					label="Categoria de combinação veicular"
					name="categoriaCombinacaoVeicular"
					options={MDFE_CATEGORIA_COMBINACAO_VEICULAR_OPTIONS}
					onChange={(e) => setFieldValue('categoriaCombinacaoVeicular', e.value)}
					value={values?.categoriaCombinacaoVeicular}
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<ButtonNovo
					label="Novo vale"
					onClick={() => {
						setModalValePedagioVisible(true);
						setIsNovoRegistro(true);
						setIndexRegistro(values?.valePedagio?.length ?? 0);
					}}
					disabled={isSituacaoFinalMDFe}
					style={{ bottom: `${!isMobile ? '3px' : null}`, marginLeft: `${isMobile ? '8px' : null}` }}
				/>
			</Grid>
			<Card>
				<DataTable
					className="table"
					value={values?.valePedagio}
					sortField={sortField}
					sortOrder={sortOrder}
					onSort={atualizarOrdenacao}
					responsive
					emptyMessage={<NenhumRegistroEncontrado />}
					style={{
						paddingBottom: '16px',
						overflowY: 'auto',
						overflowX: 'hidden',
						maxHeight: isMobile ? '24.2rem' : '13.8rem',
					}}
				>
					<Column
						field="numeroComprovante"
						header="Comprovante"
						body={(row) => renderFieldnumeroComprovante(row)}
						sortable
						style={{ width: '8rem' }}
					/>
					<Column
						field="valePedagioValor"
						header="Valor"
						body={(row) => renderFieldValor(row)}
						sortable
						style={{ width: '8rem' }}
					/>
					<Column
						field="fornecedoraCnpj"
						header="CNPJ fornecedor"
						body={(row) => renderCnpjFornecedor(row)}
						sortable
						style={{ width: '12rem' }}
					/>
					<Column
						field="valePedagioTipo"
						header="Tipo do vale"
						body={(row) => renderFieldTipoValePedagio(row)}
						sortable
					/>
					<Column
						header="CPF/CNPJ pagador"
						body={(row) => renderCpfCnpjPagador(row)}
						sortable
						style={{ width: '12rem' }}
					/>
					<Column
						header="Ações"
						body={renderAcoes}
						style={{
							width: '6rem',
						}}
					/>
				</DataTable>
			</Card>
			<If test={modalValePedagioVisible}>
				<ModalValePedagio
					visible={modalValePedagioVisible}
					indexRegistro={indexRegistro}
					valePedagio={values.valePedagio[indexRegistro]}
					valePedagioList={values.valePedagio}
					isNovoRegistro={isNovoRegistro}
					setFieldValueMDFe={setFieldValue}
					onCloseModal={() => {
						setIndexRegistro(null);
						setModalValePedagioVisible(false);
						setIsNovoRegistro(false);
					}}
				/>
			</If>
		</>
	);
}

export { ValePedagio };
