import { useState } from 'react';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { parseISO } from 'date-fns';
import propTypes from 'prop-types';

import { ButtonCancelar, estadosBotaoCancelar, LayoutMenuSuperior, Modal, If, Grid, Col, Button } from 'components';

import FeedbackResumoAssinaturas from '../../components/FeedbackResumoAssinaturas';
import ampulheta from '../../../../Images/ampulheta.svg';
import assinaturaEfetuada from '../../../../Images/assinatura_efetuada.svg';
import assinaturaRejeitada from '../../../../Images/assinatura_rejeitada.svg';
import documentoExpirado from '../../../../Images/documento_expirado.svg';
import { statusDocumento } from '../../../Util/constantes';
import { asyncEnviarWhatsAppUnicoSignatario } from '../ModalEnviarParaAssinatura/Requests';
import { PAPEL_SIGNATARIOS } from 'Common';

const detalhesStyle = {
	margin: 0,
	color: '#7b7b7b',
	wordBreak: 'break-word',
};

const gridStyle = {
	marginTop: '40px',
	minHeight: '110px',
	borderBottom: '1px solid #e2e2e2',
	borderLeft: '3px solid #0288d1',
	paddingLeft: '10px',
};

const enviarEmailStyle = {
	cursor: 'pointer',
};

function ModalVerDetalhesDocumento(props) {
	const { documento } = props;

	const [signatariosWhatsapp, setSignatariosWhatsapp] = useState(props.documento.signatarios);

	const assinaturas = documento.signatarios.filter((signatario) => signatario.assinatura !== null);

	function cancelar() {
		props.onHide();
	}

	function buscarTextoAssinatura(assinatura) {
		if (!assinatura) {
			const documentoInutilizado = Boolean(
				props.documento.status === statusDocumento.REJEITADO || props.documento.status === statusDocumento.EXPIRADO
			);
			return (
				<p style={detalhesStyle}>
					<strong>{documentoInutilizado ? 'Documento não assinado pelo signatário' : 'Aguardando assinatura'}</strong>
				</p>
			);
		}
		return null;
	}

	function buscarBotaoEnviarWhatsapp(index, signatario) {
		const documentoInutilizado = Boolean(
			props.documento.status === statusDocumento.REJEITADO ||
				props.documento.status === statusDocumento.EXPIRADO ||
				props.documento.status === statusDocumento.ASSINADO
		);
		const assinaturaEfetuada = Boolean(
			(signatario.assinatura && signatario.assinatura.status === 'ASSINADO') ||
				(signatario.assinatura && signatario.assinatura.status === 'REJEITADO')
		);

		let enviadoWhatsApp =
			signatariosWhatsapp[index].enviadoWhatsApp && signatario.telefone === signatario.numeroTelefoneControle;

		if (!documentoInutilizado && !assinaturaEfetuada && signatario.telefone) {
			return (
				<div style={{ marginTop: '20px', marginRight: '20px' }}>
					{/* eslint-disable-next-line */}
					<Button
						className={enviadoWhatsApp ? 'p-button-secondary' : 'p-button-success'}
						style={enviarEmailStyle}
						type="button"
						label="Enviar Whatsapp"
						icon="fa fa-whatsapp"
						onClick={() => onClickEnviarMensagemWhatsapp(index, signatario)}
					/>
				</div>
			);
		}
	}

	async function onClickEnviarMensagemWhatsapp(index, signatario) {
		await asyncEnviarWhatsAppUnicoSignatario(props.documento.id, signatario.id, () => {});
		let newSignatariosWhatsapp = signatariosWhatsapp;
		newSignatariosWhatsapp[index].enviadoWhatsApp = true;
		newSignatariosWhatsapp[index].numeroTelefoneControle = signatario.telefone;

		setSignatariosWhatsapp(newSignatariosWhatsapp);
	}

	function buscarBotaoReenviarEmail(signatario) {
		const documentoInutilizado = Boolean(
			props.documento.status === statusDocumento.REJEITADO ||
				props.documento.status === statusDocumento.EXPIRADO ||
				props.documento.status === statusDocumento.ASSINADO
		);
		const assinaturaEfetuada = Boolean(
			(signatario.assinatura && signatario.assinatura.status === 'ASSINADO') ||
				(signatario.assinatura && signatario.assinatura.status === 'REJEITADO')
		);

		if (!documentoInutilizado && !assinaturaEfetuada) {
			return (
				<div style={{ marginTop: '20px', marginRight: '20px' }}>
					{/* eslint-disable-next-line */}
					<Button
						className="p-button-primary"
						style={enviarEmailStyle}
						type="button"
						label="Reenviar e-mail"
						icon="fa fa-envelope-o"
						onClick={() => props.onClickEnviarEmailSignatarioEspecifico(signatario)}
					/>
				</div>
			);
		}
	}

	function montarDetalhesAssinatura(assinatura) {
		if (assinatura) {
			const dataConvertida = parseISO(assinatura.dataHora);
			return (
				<>
					<p style={detalhesStyle}>
						<strong>
							Documento {assinatura.status === 'REJEITADO' ? 'rejeitado' : 'assinado'} em{' '}
							{format(dataConvertida, 'dd/MM/yyyy')}
						</strong>
					</p>
					<If test={assinatura.status === 'ASSINADO'}>
						<p style={detalhesStyle}>
							<strong>IP do dispositivo: </strong>
							{assinatura.ip}
						</p>
						<If test={assinatura?.latitude && assinatura?.longitude}>
							<p style={detalhesStyle}>
								<strong>Localização do signatário: </strong>
								Latitude: {assinatura.latitude}. Longitude: {assinatura.longitude}
							</p>
						</If>
					</If>
					<p style={detalhesStyle}>
						<strong>Observações do signatário: </strong>{' '}
						{assinatura.observacao || 'Nenhuma observação deixada pelo signatário.'}{' '}
					</p>
				</>
			);
		}
	}

	function montarHashDocumento(documento) {
		if (documento.hash256) {
			return (
				<p style={{ ...detalhesStyle }}>
					<strong>Chave do documento (Hash SHA256): </strong>
					{documento.hash256}
				</p>
			);
		}
	}

	function renderizarDetalhes() {
		const { documento, isMobile } = props;

		let signatarios = documento.signatarios;

		//ordena por nome
		signatarios =
			signatarios &&
			signatarios.sort((a, b) => {
				return String(a.nome).toLowerCase() > String(b.nome).toLowerCase()
					? 1
					: String(b.nome).toLowerCase() > String(a.nome).toLowerCase()
						? -1
						: 0;
			});

		return (
			signatarios &&
			signatarios.map((signatario, key) => {
				const assinatura = signatario.assinatura;
				let icon;
				let iconText;

				if (assinatura) {
					if (assinatura.status === 'ASSINADO') {
						icon = assinaturaEfetuada;
						iconText = 'Símbolo Assinatura Efetuada';
					}
					if (assinatura.status === 'REJEITADO') {
						icon = assinaturaRejeitada;
						iconText = 'Símbolo Assinatura Rejeitada';
					}
				} else {
					if (documento.status === statusDocumento.EXPIRADO) {
						icon = documentoExpirado;
						iconText = 'Símbolo Assinatura Expirada';
					} else {
						icon = ampulheta;
						iconText = 'Símbolo Aguardando Assinatura';
					}
				}

				return (
					<div key={key}>
						<Grid style={gridStyle}>
							<Col sm="12" md="6" lg="6" xl="7">
								<h2 style={{ fontSize: '16px' }}>
									{`${PAPEL_SIGNATARIOS.find((papel) => papel.value === signatario.papel)?.label}: ${
										signatario.nome
									} (${signatario.email})`}
								</h2>
								{montarDetalhesAssinatura(assinatura)}
								{buscarTextoAssinatura(assinatura)}
							</Col>
							<Col sm="12" md="6" lg="6" xl="5">
								<div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
									{buscarBotaoEnviarWhatsapp(key, signatario)}
									<div>
										<If test={!isMobile}>
											<img
												src={icon}
												style={{ width: '40px', marginLeft: '70px', marginRight: '10px' }}
												alt={iconText}
											/>
										</If>
										{buscarBotaoReenviarEmail(signatario)}
									</div>
								</div>
							</Col>
						</Grid>
					</div>
				);
			})
		);
	}

	return (
		<Modal visible={props.visible} header="Detalhes do documento" onHide={props.onHide}>
			<If test={props.visible}>
				<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={() => cancelar()} />
				<LayoutMenuSuperior isModal={true}>
					<center>
						<FeedbackResumoAssinaturas assinaturas={assinaturas} status={documento.status} />
					</center>
					<div style={{ marginTop: '20px' }}>
						<div
							style={{ ...detalhesStyle }}
							title="Identificador único do documento que pode ser utilizado para consulta após todas as assinaturas serem efetuadas"
						>
							<strong> Identificador do documento: </strong>
							{documento.id}
						</div>
						{montarHashDocumento(documento)}
					</div>
					<div
						style={{
							marginTop: '0px',
							fontSize: '14px',
							color: '#545454',
							maxHeight: '480px',
							overflowY: 'auto',
							overflowX: 'hidden',
						}}
					>
						{renderizarDetalhes()}
					</div>
				</LayoutMenuSuperior>
			</If>
		</Modal>
	);
}

ModalVerDetalhesDocumento.propTypes = {
	visible: propTypes.bool,
	onHide: propTypes.func,
	documento: propTypes.object,
};

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(ModalVerDetalhesDocumento);
