
export const tutorialSteps = [
    {
        target: 'body',
        content: `Os grupos de usuários são grupos dos usuários do sistema com permissões específicas`,
        disableBeacon: true,
        placement: 'center',
    },{
        target: '.step-papeis-nome',
        content: 'Aqui você informa a descrição da atribuição que o usuário terá. Por exemplo: "Secretária"',
    },{
        target: '.step-papeis-three',
        content: 'Cada item de permissão é relacionado a um recurso do sistema. Selecionando os itens você pode definir quais permissões os usuários com esse grupo terão no sistema',
    }
]