import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Button,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	PesquisaAvancada,
	ToastTypes,
	Tutorial,
	notify,
	tutorialStepsListagens,
} from 'components';

import { atualizarUrl } from 'views/Util';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';

import { TabelaRegiaoVenda } from './Components/TabelaRegiaoVenda';
import { deleteRegiaoVenda, getRegioesVendaResumo } from './Requests';
import { OPTIONS_FILTRO_AVANCADO_REGIAO_VENDA } from './Utils/constantes';

function RegiaoView({ history, isMobile }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		size,
		setSize,
		totalRecords,
		setTotalRecords,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
	} = useContextPesquisa();

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_REGIOES, permissoes.INSERIR));
	const [registros, setRegistros] = useState([]);
	const [registroSelecionado, setRegistroSelecionado] = useState({});
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS));
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);

	const fetchCallback = useCallback(() => {
		if (!firstRender) {
			fetchRegioes();
		}
	});

	useEffect(() => {
		fetchRegioes();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('RegiaoVendaInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		fetchCallback();
	}, [page, rows, sortOrder, sortField, filtroAvancado]);

	async function fetchRegioes() {
		const filter = getFilter();
		let campoOrdenacao = sortField;
		let sentidoOrdenacao = sortOrder;
		if (firstRender) {
			setSortField('nome');
			setSortOrder(1);
			campoOrdenacao = 'nome';
			sentidoOrdenacao = 1;
		}
		const url = construirUrl(
			`${services.GESTOR}/v1/vendas/regiao/resumo`,
			filter || '?',
			size,
			page,
			sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
		);

		const promises = [
			getRegioesVendaResumo(url, ({ data: response }) => {
				setRegistros(response.content);
				setTotalRecords(response.totalElements);
				setFirstRender(false);
			}),
		];

		await Promise.all(promises);
	}

	function getFilter() {
		let filter = String('?query=(').concat(`nome=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroAvancado) {
			filter = filter.concat(`;(${filtroAvancado})`);
		}

		return filter;
	}

	function handleChangeOrder(event) {
		setSortField(event.sortField);
		setSortOrder(event.sortOrder);
	}

	function handlePageChange(event) {
		setSize(event.rows);
		setRows(event.rows);
		setPage(event.page);
	}

	function handleClickNovo() {
		atualizarUrl(history, '/vendas/regioes/cadastro');
	}

	function handleClickInputSearch(value) {
		setValorPesquisa(value);
	}

	async function handleSearchFiltroAvancado(filtro) {
		setLimparFiltroPesquisaAvancada(false);
		setFiltroAvancado(filtro);
	}

	function handleChangeFiltroRsql(rsql) {
		setFiltroAvancado(rsql);
	}

	function handleChangeDescricaoFiltro(value) {
		setDescricaoFiltroAvancado(value);
	}

	function handleClickDeletar(row) {
		confirmarExclusao(() => excluirRegistro(row));
	}

	async function excluirRegistro(registro) {
		await deleteRegiaoVenda(
			registro.id,
			() => {
				fetchCallback();
				notify('Região removída com sucesso', ToastTypes.SUCCESS);
			},
			() => {
				notify('Falha ao remover o registro', ToastTypes.ERROR);
			}
		);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
				disableScrolling
			/>
			<Form header="Região de venda">
				<FormActions>
					<Button
						className="step-listagem-novo p-button-primary"
						label={isMobile ? 'Nova região' : 'Nova região de venda'}
						icon="fa fa-plus"
						title="Inserir uma nova região de venda"
						onClick={handleClickNovo}
						podeInserir={podeInserir}
						disabled={!podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<InputSearch
							id="RegiaoVendaInputSearch"
							className="step-listagem-input-search"
							value={valorPesquisa}
							onChange={handleClickInputSearch}
							onPesquisar={fetchCallback}
						/>
						<span className="step-listagem-filtro-avancado" style={{ padding: '12px' }}>
							<PesquisaAvancada
								optionsFiltros={OPTIONS_FILTRO_AVANCADO_REGIAO_VENDA}
								onPesquisarClick={handleSearchFiltroAvancado}
								onChangeFiltroRsql={handleChangeFiltroRsql}
								onChangeDescricaoFiltro={handleChangeDescricaoFiltro}
								limparFiltro={limparFiltroPesquisaAvancada}
							/>
						</span>
					</Grid>
					<Grid justifyBetween>
						<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
						<TabelaRegiaoVenda
							isMobile={isMobile}
							history={history}
							registros={registros}
							rows={size}
							page={page}
							sortField={sortField}
							sortOrder={sortOrder}
							totalRecords={totalRecords}
							handlePageChange={handlePageChange}
							handleChangeOrder={handleChangeOrder}
							handleClickDeletar={handleClickDeletar}
							registroSelecionado={registroSelecionado}
							setRegistroSelecionado={setRegistroSelecionado}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export const Regiao = connect(mapStateToProps)(RegiaoView);
