import { DATA_ACTION_TYPES } from "./actions";

export function dataReducer(state, action) {
  switch (action.type) {
    case DATA_ACTION_TYPES.SET_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case DATA_ACTION_TYPES.ADD_SERVICOS_PRESTADOS: {
      return {
        ...state,
        servicosPrestados: {
          ...action.payload,
          content: [
            ...state.servicosPrestados.content,
            ...action.payload.content,
          ],
        },
      };
    }
    case DATA_ACTION_TYPES.ADD_PRODUTOS_VENDIDOS: {
      return {
        ...state,
        produtosVendidos: {
          ...action.payload,
          content: [
            ...state.produtosVendidos.content,
            ...action.payload.content,
          ],
        },
      };
    }
    case DATA_ACTION_TYPES.ADD_RANKING_TOMADORES: {
      return {
        ...state,
        rankingTomadores: {
          ...action.payload,
          content: [
            ...state.rankingTomadores.content,
            ...action.payload.content,
          ],
        },
      };
    }
    case DATA_ACTION_TYPES.ADD_RANKING_VENDEDORES: {
      return {
        ...state,
        rankingVendedores: {
          ...action.payload,
          content: [
            ...state.rankingVendedores.content,
            ...action.payload.content,
          ],
        },
      };
    }
    default:
      return state;
  }
}
