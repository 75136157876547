import { Col, Grid, SelectableCard } from 'components';
import { useContextNFSe } from '../../Context';
import { Colors, SelectableCards } from '../../Util/constantes';

export function Cards(props) {
	const { cards } = props;

	const { cardTotalSelected, setCardTotalSelected } = useContextNFSe();

	function handleSelectCard(card) {
		setCardTotalSelected(value => (value !== card ? card : null));
	}

	return (
		<Grid style={{ display: 'flex', justifyContent: 'center' }}>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<SelectableCard
					title="Não enviadas"
					name="cardPendente"
					colors={Colors.PENDENTE}
					selected={cardTotalSelected === SelectableCards.PENDENTE}
					onSelect={() => handleSelectCard(SelectableCards.PENDENTE)}
					value={cards.notasPendentes.valor}
					numberOfElements={cards.notasPendentes.quantidade}
					titleFiltro="Clique para filtrar pelas notas fiscais de serviço que estão pendentes"
				/>
			</Col>			
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<SelectableCard
					title="Rejeitadas"
					name="cardRejeitado"
					colors={Colors.REJEITADO}
					onSelect={() => handleSelectCard(SelectableCards.REJEITADO)}
					selected={cardTotalSelected === SelectableCards.REJEITADO}
					value={cards.notasRejeitadas.valor}
					numberOfElements={cards.notasRejeitadas.quantidade}
					titleFiltro="Clique para filtrar pelas notas fiscais de serviço rejeitadas"
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<SelectableCard
					title="Canceladas"
					name="cardCancelado"
					colors={Colors.CANCELADO}
					onSelect={() => handleSelectCard(SelectableCards.CANCELADO)}
					selected={cardTotalSelected === SelectableCards.CANCELADO}
					value={cards.notasCanceladas.valor}
					numberOfElements={cards.notasCanceladas.quantidade}
					titleFiltro="Clique para filtrar pelas notas fiscais de serviço canceladas"
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<SelectableCard
					title="Transmitidas"
					name="cardTransmitido"
					colors={Colors.TRANSMITIDO}
					onSelect={() => handleSelectCard(SelectableCards.TRANSMITIDO)}
					selected={cardTotalSelected === SelectableCards.TRANSMITIDO}
					value={cards.notasTransmitidas.valor}
					numberOfElements={cards.notasTransmitidas.quantidade}
					titleFiltro="Clique para filtrar pelas notas fiscais de serviço transmitidas"
				/>
			</Col>
		</Grid>
	);
}
