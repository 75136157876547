const TIPO_ALINHAMENTO = {
  ESQUERDA: 'ESQUERDA',
  CENTRO: 'CENTRO',
  DIREITA: 'DIREITA',
};

const OPTIONS_TIPO_ALINHAMENTO = [
  { name: 'esquerda', value: TIPO_ALINHAMENTO.ESQUERDA },
  { name: 'centro', value: TIPO_ALINHAMENTO.CENTRO },
  { name: 'direita', value: TIPO_ALINHAMENTO.DIREITA },
];

export { TIPO_ALINHAMENTO, OPTIONS_TIPO_ALINHAMENTO };
