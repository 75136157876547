import propTypes from 'prop-types';
import {
	BsCheckCircle,
	BsFillExclamationCircleFill,
	BsFillExclamationDiamondFill,
	BsInfoCircleFill,
} from 'react-icons/bs';

export default function Message({ hidden, colStyle, severity, style, className, text }) {
	function getDivStyle() {
		if (hidden) {
			return {
				display: 'none',
			};
		} else {
			return {
				padding: '0.5em',
				width: '100%',
				...colStyle,
			};
		}
	}

	function getStyle() {
		const estiloPadrao = {
			width: '100%',
			height: 'auto',
			padding: '1rem 1.5rem',
			...style,
		};

		switch (severity) {
			case 'error':
				return {
					...estiloPadrao,
					color: '#73000c',
					background: '#ffcdd2',
					borderLeft: '6px solid #e60017',
				};
			case 'warn':
				return {
					...estiloPadrao,
					color: '#765100',
					background: '#ffecb3',
					borderLeft: '6px solid #d9a300',
				};
			case 'success':
				return {
					...estiloPadrao,
					color: '#274a23',
					background: '#c8e6c9',
					borderLeft: '6px solid #439446',
				};
			default:
				return {
					...estiloPadrao,
					color: '#044871',
					background: '#b3e5fc',
					borderLeft: '6px solid #0891cf',
				};
		}
	}

	function getIcon() {
		switch (severity) {
			case 'error':
				return <BsFillExclamationCircleFill size={20} color="#73000c" />; //'fa fa-exclamation-circle'
			case 'warn':
				return <BsFillExclamationDiamondFill size={20} color="#765100" />; //'fa fa-exclamation-triangle'
			case 'success':
				return <BsCheckCircle size={20} color="#274a23" />; //'fa fa-check-circle'
			default:
				return <BsInfoCircleFill size={20} color="#044871" />; //'fa fa-info-circle'
		}
	}

	return (
		<div className={className} style={getDivStyle()}>
			<div style={getStyle()}>
				<div style={{ display: 'flex' }}>
					<span style={{ marginRight: '10px', alignItems: 'center', display: 'flex' }}>{getIcon()}</span>
					<span style={{ flex: '1' }}>{text}</span>
				</div>
			</div>
		</div>
	);
}

Message.propTypes = {
	/** Identificador do componente */
	id: propTypes.string,
	/** Derfine o componente como invisível */
	hidden: propTypes.bool,
	/** Nome da classe do componente */
	className: propTypes.string,
	/** Estilo do tipo do componente */
	severity: propTypes.oneOf(['info', 'success', 'warn', 'error']),
	/** Texto do componente */
	text: propTypes.any,
	/** Usado para ignorar o padding externo do componente */
	ignorePadding: propTypes.bool,
};
