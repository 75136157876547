import InputSelectPercentualOrValor from 'components/input/InputSelectPercentualOrValor';
import { Field, useFormikContext } from 'formik';

function InputsTotalizadores({
	disabledFields,
	informacoesPermissoes,
	onBlurTotalFrete,
	onBlurTotalSeguro,
	onBlurTotalAcessorias,
	onBlurTotalDesconto,
}) {
	const { values } = useFormikContext();

	const existePeloMenosUmProduto = existeProduto();
	const desabilitarCampos = disabledFields || !existePeloMenosUmProduto || values.produtos.length < 1;
	function existeProduto() {
		if (values.produtos?.length > 0) {
			return values.produtos.some((produto) => produto.produto);
		}
		return false;
	}

	return (
		<>
			<Field
				sm="6"
				md="3"
				lg="3"
				xl="3"
				component={InputSelectPercentualOrValor}
				size={14}
				label="Valor do frete"
				name="totalFrete"
				value={values.totalizadores.totalFrete}
				onBlur={(tipo, value, percentual) => onBlurTotalFrete(tipo, value, percentual)}
				allowNegative={false}
				disabled={desabilitarCampos}
				informacoesPermissoes={informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="3"
				lg="3"
				xl="3"
				component={InputSelectPercentualOrValor}
				size={14}
				label="Valor do seguro"
				name="totalSeguro"
				value={values.totalizadores.totalSeguro}
				onBlur={(tipo, value, percentual) => onBlurTotalSeguro(tipo, value, percentual)}
				allowNegative={false}
				disabled={desabilitarCampos}
				informacoesPermissoes={informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="3"
				lg="3"
				xl="3"
				component={InputSelectPercentualOrValor}
				size={14}
				label="Acessórias"
				name="totalAcessorias"
				value={values.totalizadores.totalAcessorias}
				onBlur={(tipo, value, percentual) => onBlurTotalAcessorias(tipo, value, percentual)}
				placeholder="0,00"
				allowNegative={false}
				disabled={desabilitarCampos}
				informacoesPermissoes={informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="3"
				lg="3"
				xl="3"
				component={InputSelectPercentualOrValor}
				size={14}
				label="Desconto"
				name="totalDesconto"
				value={values.totalizadores.totalDesconto}
				onBlur={(tipo, value, percentual) => onBlurTotalDesconto(tipo, value, percentual)}
				placeholder="0,00"
				allowNegative={false}
				disabled={desabilitarCampos}
				informacoesPermissoes={informacoesPermissoes}
			/>
		</>
	);
}

export { InputsTotalizadores };
