import { Field, useFormikContext } from 'formik';
import { Card, Divider, Dropdown, Grid, InputDouble } from 'components';

import { OPTIONS_TIPO_PAPEL_ETIQUETA, TIPO_PAPEL_ETIQUETA } from '../../../../Util/constantes';

function CardMedidas({ informacoesPermissoes }) {
	const { values, setFieldValue } = useFormikContext();
	const isDisabledWithBobina = values.tipoPapel === TIPO_PAPEL_ETIQUETA.BOBINA;

	function onChangeTipoPapel(event) {
		setFieldValue('tipoPapel', event.value);

		if (event.value === TIPO_PAPEL_ETIQUETA.BOBINA) {
			setFieldValue('alturaPagina', 0);
			setFieldValue('linhasPorPagina', 1);
			setFieldValue('etiquetasPorLinha', 1);
			setFieldValue('margemInferior', 0);
			setFieldValue('espacamentoLinha', 0);
		}
	}

	return (
		<Card
			className="card-etiqueta step-etiqueta-configuracao-card-medidas"
			title="Medidas"
			style={{ height: '100%', paddingTop: '1rem' }}
		>
			<Divider
				label="Página"
				styleContainer={{ marginLeft: '0', marginTop: '0', paddingLeft: '0' }}
				styleLabel={{ fontSize: '1rem' }}
			/>
			<Grid>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="4"
					component={Dropdown}
					label="Tipo de papel"
					name="tipoPapel"
					value={values.tipoPapel}
					showClear={false}
					onChange={onChangeTipoPapel}
					options={OPTIONS_TIPO_PAPEL_ETIQUETA}
					{...informacoesPermissoes}
				/>

				<Field
					sm="12"
					md="6"
					lg="6"
					xl="4"
					component={InputDouble}
					decimalScale={0}
					thousandSeparator=""
					maxValue={1000}
					label="Altura (mm)"
					name="alturaPagina"
					value={values.alturaPagina}
					disabled={isDisabledWithBobina}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="4"
					component={InputDouble}
					decimalScale={0}
					thousandSeparator=""
					maxValue={1000}
					label="Largura (mm)"
					name="larguraPagina"
					value={values.larguraPagina}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="4"
					component={InputDouble}
					decimalScale={0}
					thousandSeparator=""
					maxValue={1000}
					label="Linhas por página"
					name="linhasPorPagina"
					value={values.linhasPorPagina}
					disabled={isDisabledWithBobina}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="4"
					component={InputDouble}
					decimalScale={0}
					thousandSeparator=""
					maxValue={1000}
					label="Etiqueta por linha"
					name="etiquetasPorLinha"
					value={values.etiquetasPorLinha}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="4"
					component={InputDouble}
					decimalScale={0}
					thousandSeparator=""
					maxValue={1000}
					label="Margem superior (mm)"
					name="margemSuperior"
					value={values.margemSuperior}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="4"
					component={InputDouble}
					decimalScale={0}
					thousandSeparator=""
					maxValue={1000}
					label="Margem inferior (mm)"
					name="margemInferior"
					value={values.margemInferior}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="4"
					component={InputDouble}
					decimalScale={0}
					thousandSeparator=""
					maxValue={1000}
					label="Margem direita (mm)"
					name="margemDireita"
					value={values.margemDireita}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="4"
					component={InputDouble}
					decimalScale={0}
					thousandSeparator=""
					maxValue={1000}
					label="Margem esquerda (mm)"
					name="margemEsquerda"
					value={values.margemEsquerda}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="4"
					component={InputDouble}
					decimalScale={2}
					maxValue={1000}
					label="Espaçamento coluna (mm)"
					name="espacamentoColuna"
					value={values.espacamentoColuna}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="4"
					component={InputDouble}
					decimalScale={2}
					maxValue={1000}
					label="Espaçamento linha (mm)"
					name="espacamentoLinha"
					value={values.espacamentoLinha}
					disabled={isDisabledWithBobina}
					{...informacoesPermissoes}
				/>
			</Grid>
			<Divider
				label="Etiquetas"
				styleContainer={{ marginLeft: '0', paddingLeft: '0' }}
				styleLabel={{ fontSize: '1rem' }}
			/>
			<Grid>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="3"
					component={InputDouble}
					decimalScale={2}
					maxValue={1000}
					label="Altura etiqueta (mm)"
					name="alturaEtiqueta"
					value={values.alturaEtiqueta}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="3"
					component={InputDouble}
					decimalScale={2}
					maxValue={1000}
					label="Largura etiqueta (mm)"
					name="larguraEtiqueta"
					value={values.larguraEtiqueta}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="3"
					component={Dropdown}
					label="Contorno etiqueta"
					name="exibirContornoEtiqueta"
					value={values.exibirContornoEtiqueta}
					options={[
						{ label: 'Sim', value: true },
						{ label: 'Não', value: false },
					]}
					showClear={false}
					onChange={(e) => setFieldValue('exibirContornoEtiqueta', e.value)}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="3"
					component={Dropdown}
					label="Contorno campos"
					name="exibirContornoCampos"
					value={values.exibirContornoCampos}
					options={[
						{ label: 'Sim', value: true },
						{ label: 'Não', value: false },
					]}
					showClear={false}
					onChange={(e) => setFieldValue('exibirContornoCampos', e.value)}
					{...informacoesPermissoes}
				/>
			</Grid>
		</Card>
	);
}

export { CardMedidas };
