import { manterApenasNumeros } from '../../../../../Common/Mascara';

export function converterParaFormulario(values) {
	return {
		id: values.id,
		nome: values.nome,
		tipo: values.tipo,
		cpf: values.cpf ? manterApenasNumeros(values.cpf) : null,
		bloqueada: values.bloqueada,
		cnpj: values.cnpj ? manterApenasNumeros(values.cnpj) : null,
		telefone: manterApenasNumeros(values.telefone),
		email: values.email,
		logradouro: values.endereco ? values.endereco.logradouro : null,
		numero: values.endereco ? values.endereco.numero : null,
		bairro: values.endereco ? values.endereco.bairro : null,
		complemento: values.endereco ? values.endereco.complemento : null,
		cep: values.endereco ? values.endereco.cep : null,
		municipio: converterMunicipioParaFormulario(values.endereco),
		municipioExterior: values.endereco ? values.endereco.municipioExterior : null,
		pais: converterPaisParaFormulario(values.endereco),
		modulos: values.modulos,
		plano: converterPlanoParaFormulario(values.plano),
		meioPagamento: values.meioPagamento,
		agenteAutorizado: converterAgenteAutorizadoParaFormulario(values.agenteAutorizado),
		aplicacao: values.aplicacao,
		autoridade: values.autoridade,
		filiais: values.filiais,
		criadoEm: values.criadoEm,
	};
}

function converterPlanoParaFormulario(plano) {
	if (plano) {
		return {
			label: plano.descricao,
			value: plano.id,
		};
	}
	return null;
}

function converterPaisParaFormulario(endereco) {
	if (endereco && endereco.pais) {
		return {
			label: endereco.pais.nome,
			value: endereco.pais.id,
		};
	}
	return null;
}

function converterAgenteAutorizadoParaFormulario(agente) {
	if (agente && agente.cpf) {
		return {
			label: agente.nome + ' - ' + agente.cpf,
			value: agente.id,
		};
	} else if (agente && agente.cnpj) {
		return {
			label: agente.nome + ' - ' + agente.cnpj,
			value: agente.id,
		};
	}
	return null;
}

function converterMunicipioParaFormulario(endereco) {
	if (endereco && endereco.municipio) {
		return {
			label: endereco.municipio.nome + ' - ' + endereco.municipio.estadoSigla,
			value: endereco.municipio.id,
		};
	}
	return null;
}

export function converterParaApi(values) {
	if (values.tipo === 'JURIDICA') {
		values.cpf = null;
	}

	if (values.tipo === 'FISICA') {
		values.cnpj = null;
	}

	return {
		id: values.id,
		nome: values.nome,
		tipo: values.tipo,
		email: values.email,
		bloqueada: values.bloqueada,
		meioPagamento: values.meioPagamento,
		modulos: values.modulos,
		aplicacao: values.aplicacao,
		autoridade: values.autoridade,
		cpf: manterApenasNumeros(values.cpf),
		cnpj: manterApenasNumeros(values.cnpj),
		telefone: manterApenasNumeros(values.telefone),
		criadoEm: values.criadoEm,
		endereco: {
			logradouro: values.logradouro,
			numero: values.numero,
			bairro: values.bairro,
			complemento: values.complemento,
			municipioExterior: values.municipioExterior,
			estadoExterior: values.estadoExterior,
			cep: manterApenasNumeros(values.cep),
			municipio: values.municipio
				? {
						id: values.municipio.value,
					}
				: null,
			pais: values.pais
				? {
						id: values.pais.value,
					}
				: null,
		},
		plano: values.plano ? { id: values.plano.value } : null,
		agenteAutorizado: values.agenteAutorizado
			? {
					id: values.agenteAutorizado.value,
				}
			: null,
		filiais: converterFiliaisParaApi(values.filiais ?? []),
	};
}

function converterFiliaisParaApi(filiais) {
	if (filiais.length > 0) {
		const filiaisConverted = filiais?.map((filial) => {
			return { ...filial, contabilidade: filial.contabilidade?.value ? { id: filial.contabilidade.value } : null };
		});

		return filiaisConverted;
	} else {
		return [];
	}
}
