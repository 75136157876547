import { Dropdown, Grid, InputDouble, InputMoney, SingleSelectCst, typesCst } from 'components';
import { Field } from 'formik';
import { origemProduto } from '../../../../../../../../../../../components/body/Util/constantes';

function Icms(props) {
	const { tributo, setFieldValue, disabled, informacoesPermissoes, isFullScreen } = props;

	return (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={SingleSelectCst}
				label="CST/CSOSN"
				name="cstCsosn"
				value={tributo?.cstCsosn}
				useFormErrors={false}
				useFormTouched={false}
				errors={props.errors?.cstCsosn ?? null}
				touched={props.errors?.cstCsosn}
				type={typesCst.CST_CSOSN}
				isClearable={false}
				onChange={(event) => setFieldValue(`produto.tributos.icms.cstCsosn`, event)}
				obrigatorio
				disabled={disabled}
				menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={Dropdown}
				label="Origem do produto"
				name="origemProduto"
				useFormErrors={false}
				useFormTouched={false}
				errors={props.errors?.origemProduto ?? null}
				touched={props.errors?.origemProduto}
				value={tributo?.origemProduto}
				showClear={false}
				onChange={(event) => setFieldValue(`produto.tributos.icms.origemProduto`, event.value)}
				options={origemProduto}
				disabled={disabled}
				obrigatorio
				menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputMoney}
				label="BC ICMS"
				name="baseIcms"
				touched
				allowNegative={false}
				value={tributo?.baseIcms}
				onChange={(event) => setFieldValue(`produto.tributos.icms.baseIcms`, event.target.value)}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputDouble}
				label="% ICMS"
				name="percentualIcms"
				value={tributo?.percentualIcms}
				onChange={(event) => setFieldValue(`produto.tributos.icms.percentualIcms`, event.target.value)}
				suffix="%"
				allowNegative={false}
				maxValue={100}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputMoney}
				label="Valor ICMS"
				name="valorIcms"
				touched
				allowNegative={false}
				value={tributo?.valorIcms}
				onChange={(event) => setFieldValue(`produto.tributos.icms.valorIcms`, event.target.value)}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export default Icms;
