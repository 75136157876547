import { useEffect, useState } from "react";
import { InternalInputDouble } from "./components/InternalInputDouble";
import { useFormikContext } from "formik";

// PAI DA CRIANÇA https://www.npmjs.com/package/react-number-format

export default function InputDouble(props) {
  const {
    field,
    form,
    useFormErrors = true,
    useFormTouched = true,
    ...rest
  } = props;
  const formikContext = useFormikContext();

  const useForm = useFormErrors && useFormTouched;
  const [errors, setErrors] = useState(null);
  const [touched, setTouched] = useState(null);

  const styleDefault = {
    textAlign: "end",
    ...props.style,
  };

  useEffect(() => {
    if (
      (form && field && errors !== form?.errors[field.name]) ||
      touched !== form?.touched[field.name]
    ) {
      setErrors(form?.errors[field.name]);
      setTouched(form?.touched[field.name]);
    }
  });

  function handleChange(event) {
    if (props.onChange !== undefined && typeof props.onChange === "function") {
      props.onChange(event);
    } else {
      formikContext?.setFieldValue(event.target.name, event.target.value);
    }
  }

  return (
    <>
      <InternalInputDouble
        {...field}
        {...rest}
        onChange={handleChange}
        errors={errors && useForm ? errors : props?.errors ?? errors}
        touched={touched && useForm ? touched : props?.touched ?? touched}
        style={styleDefault}
      />
    </>
  );
}
