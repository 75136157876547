import { useState } from 'react';
import { ListagemRelatorios, If } from 'components';
import { modulos } from 'Common';
import ModalRelatorioAssinatura from './components/ModalRelatorioAssinatura';
import ModalFimVigenciaPeriodo from './components/ModalFimVigenciaPeriodo';

function DocumentosRelatorios() {
  const [exibirModalAssinatura, setExibirModalAssinatura] = useState(false);
  const [exibirModalFimVigenciaPeriodo, setExibirModalFimVigenciaPeriodo] = useState(false);

  const metodosImprimir = [
    {
      nome: 'ASSINATURAS',
      onImprimir: function () {
        setExibirModalAssinatura(true);
      },
    },
    {
      nome: 'FIM_VIGENCIA_PERIODO',
      onImprimir: () => {
        setExibirModalFimVigenciaPeriodo(true);
      },
    },
  ];

  return (
    <>
      <ListagemRelatorios
        header="Relatórios de documentos"
        modulo={modulos.DOCUMENTOS}
        metodosImprimir={metodosImprimir}
      />
      <If test={exibirModalAssinatura}>
        <ModalRelatorioAssinatura visible={exibirModalAssinatura} onHide={() => setExibirModalAssinatura(false)} />
      </If>
      <If test={exibirModalFimVigenciaPeriodo}>
        <ModalFimVigenciaPeriodo
          visible={exibirModalFimVigenciaPeriodo}
          onHide={() => setExibirModalFimVigenciaPeriodo(false)}
        />
      </If>
    </>
  );
}

export default DocumentosRelatorios;
