export function formatarPlacaParaApi(placa) {
	if (!placa) {
		return null;
	}
	return placa.replace('-', '');
}

export function formatarPlaca(placa) {
	const cleanedPlate = placa?.replace(/\W/g, '');
	return cleanedPlate?.replace(/^(\w{3})(\w{4})$/, '$1-$2').toUpperCase();
}
