import { get, post, put, del, exibirToast } from '../../../../Common/Requisicoes';
import { services } from '../../../../Common/Constantes/api';

export async function asyncBuscarEstruturaPapeis(onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/papeis_permissoes/estrutura`, null, onSuccess, onError);
}

export async function asyncDeletePapel(idPapel, onSuccess, onError) {
	return await del(
		`${services.GESTOR}/v1/papeis_permissoes/${idPapel}`,
		null,
		exibirToast(onSuccess, 'Grupo de usuários excluído com sucesso'),
		onError
	);
}

export async function asyncCreatePapel(papel, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/papeis_permissoes`,
		papel,
		null,
		exibirToast(onSuccess, 'Grupo de usuários criado com sucesso'),
		onError
	);
}

export async function asyncGetPesquisaPapeis(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function asyncUpdatePapel(papel, onSuccess, onError) {
	return await put(
		`${services.GESTOR}/v1/papeis_permissoes/${papel.id}`,
		papel,
		null,
		exibirToast(onSuccess, 'Grupo de usuários atualizado com sucesso'),
		onError
	);
}

export async function asyncGetPapel(papelId, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/papeis_permissoes/${papelId}`, null, onSuccess, onError);
}

export async function asyncGetPapelComMesmoNome(nome, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/papeis_permissoes/resumo?query=nome=contains="${nome}"`, null, onSuccess, onError);
}
