import { useEffect, useState } from 'react';
import { asyncBuscarQRCode, asyncBuscarStatusPagamento } from 'views/configuracao/Planos/Requests';
import { Button, notify } from 'components';
import { buscarDadosLoginLocalStorage } from 'Common';
import { fazerLogin } from 'Common/Autenticacao';

function QRCodePix({ activeIndex, setActiveIndex, aderir, mensalidade }) {
	const [imageUrl, setImageUrl] = useState(null);
	const [dataQrCode, setDataQrCode] = useState(null);
	const [statusPix, setStatusPix] = useState(null);
	const [trigger, setTrigger] = useState(0);
	const [aderirAoPlano, setAderirAoPlano] = useState(true);

	useEffect(() => {
		if (!mensalidade) {
			aderirPlano();
		} else {
			setAderirAoPlano(false);
		}
	}, []);

	useEffect(() => {
		if (!aderirAoPlano) {
			buscarQRCode();
			setTrigger(trigger + 1);
		}
	}, [aderirAoPlano]);

	useEffect(() => {
		checkStatus();
	}, [trigger]);

	async function checkStatus() {
		if (statusPix?.status === 'ATIVA' || statusPix === null) {
			await verificarStatusPagamento();
			setTimeout(() => setTrigger(trigger + 1), 3000);
		} else if (statusPix?.status === 'CONCLUIDA') {
			setActiveIndex(mensalidade ? activeIndex + 1 : 2);
		}
	}

	async function aderirPlano() {
		if (aderirAoPlano) {
			await aderir();
			setAderirAoPlano(false);
		}
	}

	async function buscarQRCode() {
		await asyncBuscarQRCode(!!mensalidade, ({ data }) => {
			setDataQrCode(data);
			setImageUrl(data.qrCode);
		});
	}

	function copiarPix() {
		navigator.clipboard.writeText(dataQrCode.qrCodeUrl);
		notify('Pix copiado!');
	}

	async function verificarStatusPagamento() {
		if (dataQrCode) {
			await asyncBuscarStatusPagamento(
				dataQrCode?.id,
				({ data }) => {
					setStatusPix(data);
				},
				(e) => {
					if (e.response.status === 401) {
						const dadosLocalStorage = buscarDadosLoginLocalStorage();
						fazerLogin(dadosLocalStorage.email, dadosLocalStorage.senha, dadosLocalStorage.manterConectado, () => {});
					}
				}
			);
		}
	}

	return (
		dataQrCode && (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<img src={imageUrl} alt="QR Code PIX" style={{ height: '250px', width: '250px' }} />
				<p style={{ fontSize: '1.2rem', paddingBottom: '1rem' }}>Aguardando pagamento...</p>
				<Button
					label="Copiar código do QR Code"
					icon="pi pi-copy"
					title="Copiar o link do Pix para a área de transferência"
					onClick={copiarPix}
				/>
			</div>
		)
	);
}

export { QRCodePix };
