import React from 'react';

const styleErrors = {
	fontSize: '12px',
	color: 'red',
	height: '16px',
};

const styleWarnings = {
	fontSize: '12px',
	color: '#ff9800',
	height: '16px',
};

const styleHelpers = {
	fontSize: '12px',
	color: '#666666',
	height: '16px',
};

export function renderizarValidacao(error, touched = false, warning = '', helpText = '', disabled = false) {
	const renderizarErros = error && touched;
	const renderizarWarnings = warning && touched;
	const renderizarHelpText = helpText && touched;

	if (renderizarErros) {
		return <div style={disabled ? { ...styleErrors, opacity: '0.5' } : styleErrors}>{error}</div>;
	}

	if (renderizarWarnings) {
		return <div style={disabled ? { ...styleWarnings, opacity: '0.5' } : styleWarnings}>{warning}</div>;
	}

	if (renderizarHelpText) {
		return <div style={disabled ? { ...styleHelpers, opacity: '0.5' } : styleHelpers}>{helpText}</div>;
	}
}

export function buscarClassNamePelosErros(className, error, touched = false, warning) {
	if (error && touched) {
		return `${className} p-input-error`;
	} else if (warning && touched) {
		return `${className} p-input-warning`;
	}
	return className;
}
