function montarCorpoEmail(dadosPessoaParaEmail, boletos) {
	let textoBoletos = '';
	boletos?.forEach((boleto) => {
		if (boleto.temBoleto) {
			textoBoletos += ` ${boleto.financeiroIdentificador},`;
		}
	});
	textoBoletos = textoBoletos.replace(/,\s*$/, '.').replace(/,([^,]*)$/, ' e$1');

	const corpoEmail = `Olá ${dadosPessoaParaEmail?.pessoaNome}, ${
		boletos.length > 1
			? `segue boletos referentes aos títulos:${textoBoletos}`
			: `segue boleto referente ao título:${textoBoletos}`
	}`;

	return corpoEmail;
}

export const initialValue = (boletos, dadosPessoaParaEmail, dadosFilial) => ({
	assunto: `Boletos - ${dadosFilial?.nome}`,
	corpoEmail: montarCorpoEmail(dadosPessoaParaEmail, boletos),
	destinatarios: dadosPessoaParaEmail?.pessoaEmail,
});

function converterDestinatariosEmailParaApi(destinatarios) {
	return destinatarios
		.split(',')
		.map((item) => item.trim())
		.filter((item) => item.trim() !== '');
}

export function converterBoletosEmailParaApi(email) {
	return {
		assunto: email.assunto,
		corpoEmail: email.corpoEmail,
		destinatarios: converterDestinatariosEmailParaApi(email.destinatarios),
		contaReceberIds: email.contaReceberIds,
	};
}
