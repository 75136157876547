import { useFormikContext } from 'formik';

import { If, Message } from 'components';

import { Servicos } from 'components/Servicos';
import { useContextNFSe } from '../../../Context';
import { buscarPercentualAdicionalCondicaoPagamento, buscarPercentualAdicionalQuantidadeServico } from './Utils';

function Body({ informacoesPermissoes, buscarMunicipioIdPessoa }) {
	const { values, setFieldValue, errors, isFirstRender } = useFormikContext();
	const {
		setAtualizarTotal,
		color,
		disableFields,
		clienteUsaPrecoAtacado,
		recalcularTodosOsServicosComTabelaPreco,
		setRecalcularTodosOsServicosComTabelaPreco,
	} = useContextNFSe();

	const municipioId = buscarMunicipioIdPessoa(values.tomador);
	const showMessage = !values.tomador?.registro || !values.operacaoFiscal?.registro;
	let camposVaziosMessage = '';

	function createTextMessage() {
		if (!values.tomador) {
			camposVaziosMessage = 'cliente';
		}

		if (!values.operacaoFiscal) {
			camposVaziosMessage !== '' ? (camposVaziosMessage += ' e ') : null;
			camposVaziosMessage += 'operação fiscal';
		}

		if (camposVaziosMessage.split(' ').length <= 2) {
			return (
				<span>
					{`O campo `}
					<b>{`${camposVaziosMessage}`}</b>
					{` deve ser preenchido antes de adicionar um serviço.`}
				</span>
			);
		}

		return null;
	}

	function renderMessage() {
		return (
			<If test={!isFirstRender && showMessage}>
				<Message severity="warn" text={createTextMessage()} />
			</If>
		);
	}

	function onChangeServicos(servicos) {
		setFieldValue('servicos', servicos);
		setAtualizarTotal(true);
	}

	function onChangeServico(index, value) {
		setFieldValue(`servicos[${index}]`, value);
		setAtualizarTotal(true);
	}

	async function calcularPercentualAdicionalTabelaPreco(quantidade) {
		const Promises = [
			buscarPercentualAdicionalCondicaoPagamento(
				values.pagamentos,
				values.informacoesComplementares?.tabelaPreco?.registro?.alteracoesPorCondicao
			),
			buscarPercentualAdicionalQuantidadeServico(
				quantidade,
				values.informacoesComplementares?.tabelaPreco?.registro?.descontosPorQuantidade
			),
		];

		return Promise.all(Promises).then((dados) => {
			const percentualAplicado = dados.reduce((percentual, dado) => {
				if (dado?.tipo === 'ACRESCIMO') {
					return percentual + (dado.percentual || 0);
				} else if (dado?.tipo === 'DESCONTO') {
					return percentual - (dado.percentual || 0);
				}

				return percentual;
			}, 0);

			return { percentual: Math.abs(percentualAplicado), tipo: percentualAplicado > 0 ? 'ACRESCIMO' : 'DESCONTO' };
		});
	}

	return (
		<div
			className="step-nfse-servicos grid-Produtos"
			style={{
				overflowY: 'scroll',
				overflowX: 'hidden',
				maxHeight: '18.2rem',
			}}
		>
			{renderMessage()}
			<Servicos
				servicos={values.servicos}
				municipioId={municipioId}
				tabelaPreco={values.informacoesComplementares?.tabelaPreco}
				pagamentos={values.pagamentos}
				informacoesPermissoes={informacoesPermissoes}
				disabled={disableFields || showMessage}
				disabledButtonAdd={disableFields}
				color={color.strongColor}
				onChangeServicos={onChangeServicos}
				onChangeServico={onChangeServico}
				calcularPercentualAdicionalTabelaPreco={calcularPercentualAdicionalTabelaPreco}
				atualizarTotalizadores={setAtualizarTotal}
				operacaoFiscalHeader={values.operacaoFiscal}
				comissaoHeader={values.informacoesComplementares?.vendedor?.registro?.comissao}								
				descontoMaximoVendedor={values.informacoesComplementares?.vendedor?.registro?.descontoMaximo}
				descontoMaximoSuperior={values.informacoesComplementares?.vendedor?.registro?.descontoMaximoSuperior}																
				utilizaPrecoAtacado={clienteUsaPrecoAtacado}
				recalcularTodosOsServicosComTabelaPreco={recalcularTodosOsServicosComTabelaPreco}
				setRecalcularTodosOsServicosComTabelaPreco={setRecalcularTodosOsServicosComTabelaPreco}
				errors={errors.servicos}
			/>
		</div>
	);
}

export default Body;
