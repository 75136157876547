import { useState } from "react";
import ListagemRelatorios from "../../../components/ListagemRelatorios";
import { modulos } from "../../../Common/Constantes/autorizacao";
import { If } from "../../../components";
import ModalInventarioEstoque from "./components/ModalInventarioEstoque";
import ModalMovimentacaoEstoque from "./components/ModalMovimentacaoEstoque";
import { ModalProdutoEstoqueMinimo } from "./components/ModalProdutoEstoqueMinimo";

function EstoqueRelatorios() {
  const [exibirModalInventarioEstoque, setExibirModalInventarioEstoque] =
    useState(false);
  const [exibirModalMovimentacaoEstoque, setExibirModalMovimentacaoEstoque] =
    useState(false);
  const [exibirModalProdutoEstoqueMinimo, setExibirModalProdutoEstoqueMinimo] =
    useState(false);

  const metodosImprimir = [
    {
      nome: "INVENTARIO_ESTOQUE_PRODUTOS",
      onImprimir: () => {
        setExibirModalInventarioEstoque(true);
      },
    },
    {
      nome: "MOVIMENTACOES_ESTOQUE",
      onImprimir: () => {
        setExibirModalMovimentacaoEstoque(true);
      },
    },
    {
      nome: "PRODUTO_ESTOQUE_MINIMO",
      onImprimir: () => {
        setExibirModalProdutoEstoqueMinimo(true);
      },
    },
  ];

  return (
    <>
      <ListagemRelatorios
        header="Relatórios de estoque"
        modulo={modulos.ESTOQUE}
        metodosImprimir={metodosImprimir}
      />
      <If test={exibirModalInventarioEstoque}>
        <ModalInventarioEstoque
          visible={exibirModalInventarioEstoque}
          onHide={() => setExibirModalInventarioEstoque(false)}
        />
      </If>
      <If test={exibirModalMovimentacaoEstoque}>
        <ModalMovimentacaoEstoque
          visible={exibirModalMovimentacaoEstoque}
          onHide={() => setExibirModalMovimentacaoEstoque(false)}
        />
      </If>
      <If test={exibirModalProdutoEstoqueMinimo}>
        <ModalProdutoEstoqueMinimo
          visible={exibirModalProdutoEstoqueMinimo}
          onHide={() => setExibirModalProdutoEstoqueMinimo(false)}
        />
      </If>
    </>
  );
}

export default EstoqueRelatorios;
