import propTypes from 'prop-types';
import { Button } from 'primereact/button';
import Col from '../../../../../../../components/Col';
import { removerCaracteresInvalidosRsql } from '../../../../../../../Common/Rsql';

import InputSearchMask from '../../../../../../../components/input/InputSearchMask';

export default function InputNcmDestino(props) {
	const { placeholder, name, label, className, value, disabled, sm, md, lg, xl, id, size, errors, touched } = props;

	function onKeyDown(e) {
		if (props.onKeyDown) props.onKeyDown(e);
		else if (e.key === 'Enter') {
			props.onPesquisar();
		}
	}

	function onClickPesquisar() {
		props.onPesquisar();
	}

	function handleChangePesquisa(e) {
		if (e.target.value?.length > size) {
			const value = String(e.target.value).substr(0, size);
			props.onChange(value);
		} else {
			props.onChange(removerCaracteresInvalidosRsql(e.target.value));
		}
	}

	async function limparEPesquisar() {
		await props.onChange('');
		props.onPesquisar();
	}

	function montarLabel() {
		return <label title={props.helpMessage}> {label} </label>;
	}
	return (
		<>
			<Col sm={sm} md={md} lg={lg} xl={xl}>
				{montarLabel()}
				<InputSearchMask
					id={id}
					className={className}
					placeholder={placeholder}
					name={name}
					style={{
						width: '100%',
						marginRight: '-36px',
					}}
					colStyle={{ flex: 'auto', padding: '0' }}
					value={value}
					disabled={disabled}
					onChange={handleChangePesquisa}
					mask="0000.00.00"
					errors={errors}
					touched={touched}
					useFormErrors={props.useFormErrors}
					useFormTouched={props.useFormTouched}
					onClickPesquisar={onClickPesquisar}
					onKeyDown={onKeyDown}
				/>
			</Col>
		</>
	);
}
InputNcmDestino.propTypes = {
	/** Placeholder da pesquisa */
	placeholder: propTypes.string,
	/** Nome do campo de pesquisa */
	name: propTypes.string,
	/** Classificacao da pesquisa */
	className: propTypes.string,
	/** Evento disparado ao teclar */
	onKeyDown: propTypes.func,
	/** Valor do componente */
	value: propTypes.any,
	/** Evento onChange*/
	onChange: propTypes.func,
	/** Evento clicar no pesquisa */
	onPesquisar: propTypes.func,
	/** Componente desabilitado */
	disabled: propTypes.bool,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
	/**Define se o componente está carregando */
	loading: propTypes.bool,
};
InputNcmDestino.defaultProps = {
	placeholder: 'Digite para pesquisar...',
	sm: '12',
	md: '7',
	lg: '6',
	xl: '5',
};
