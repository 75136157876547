function converterPedidoApiParaLista(pedidosApi) {
  let pedidos = pedidosApi.map(pedido => {
    return {
      id: pedido.id,
      numero: pedido.numero,
      clienteNome: pedido.clienteNome,
      clienteCpfCnpj: pedido.clienteCpf ?? pedido.clienteCnpj,
      emissao: pedido.emissao,
      totalLiquido: pedido.totalLiquido,
      status: pedido.status,
    };
  });

  return pedidos;
}

export { converterPedidoApiParaLista };
