import { estadosCadastro, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { createContext, useContext, useState } from 'react';
import { connect } from 'react-redux';

const ContextRegiaoVenda = createContext();

function RegiaoVendaProvider({ children, history, match, isMobile, isTablet }) {
	const [estadosLocal, setEstadosLocal] = useState([]);
	const [municipio] = useState(null);
	const [sortField, setSortField] = useState('municipio.nome');
	const [sortOrder, setSortOrder] = useState(1);
	const [page, setPage] = useState(0);
	const [size, setSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [controleBuscarMunicipiosPaginados, setControleBuscarMunicipiosPaginados] = useState(false);
	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_REGIOES, permissoes.INSERIR)
	);
	const [podeEditar, setPodeEditar] = useState(usuarioPossuiPermissao(recursos.CADASTROS_REGIOES, permissoes.EDITAR));
	const [podeExcluir, setPodeExcluir] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_REGIOES, permissoes.EXCLUIR)
	);

	const informacoesPermissoes = {
		estadoCadastro: match?.params?.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir,
		podeEditar,
		podeExcluir,
	};

	const idRegiao = match?.params?.id;

	return (
		<ContextRegiaoVenda.Provider
			value={{
				idRegiao,
				podeInserir,
				setPodeInserir,
				podeEditar,
				setPodeEditar,
				podeExcluir,
				setPodeExcluir,
				informacoesPermissoes,
				history,
				isMobile,
				isTablet,
				estadosLocal,
				setEstadosLocal,
				municipio,
				sortField,
				setSortField,
				sortOrder,
				setSortOrder,
				page,
				setPage,
				size,
				setSize,
				totalRecords,
				setTotalRecords,
				controleBuscarMunicipiosPaginados,
				setControleBuscarMunicipiosPaginados,
			}}
		>
			{children || null}
		</ContextRegiaoVenda.Provider>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(RegiaoVendaProvider);
export const useContextRegiaoVenda = () => useContext(ContextRegiaoVenda);
