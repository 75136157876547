import Lottie from 'lottie-react';
import animation from '../../../../App/Animation/sucesso.json';

function AnimacaoCompletado({ onHide, titulo }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <label style={{ fontSize: '1.2rem', paddingTop: '0.5rem' }}>{titulo}</label>	
      <Lottie
        style={{
          width: '100%',
          height: '250px',
          width: '250px',
        }}
        animationData={animation}
        loop
        autoPlay
        onLoopComplete={onHide}
      />      
      <label style={{ fontSize: '1.2rem' }}>
        {'Pagamento recebido com sucesso!'}
      </label>
    </div>
  );
}

export { AnimacaoCompletado };
