import { useEffect, useState, useCallback } from 'react';
import { formatISO } from 'date-fns';
import { format } from 'date-fns';
import { asyncGetTotalizadores, asyncGetNotasEntrada, asyncGetDownloadXmls } from './Requests';
import { ColorsCard, operacaoStatus, optionsFiltroAvancado } from './Util/constantes';
import { atualizarUrl } from '../../Util';

import {
	removerCaracteres,
	configuracoesUsuario,
	buscarConfiguracaoUsuario,
	salvarConfiguracaoUsuario,
	construirUrl,
	permissoes,
	recursos,
	usuarioPossuiPermissao,
	services,
	baixarArquivo,
} from '../../../Common';

import {
	Button,
	DateInterval,
	DescricaoFiltroAvancado,
	Grid,
	Col,
	PesquisaAvancada,
	InputSearch,
	Form,
	FormActions,
	FormContent,
	ButtonNovo,
	Tutorial,
	tutorialStepsListagens,
	ModalLoadingTransmissao,
} from '../../../components';
import CardTotalizadorListagem from './components/CardTotalizadorListagem';
import TabelaNotas from './components/TabelaNotas';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { useEffectOnce, useUpdateEffect } from 'react-use';

function currentMonth() {
	let date = new Date();
	let firstDay = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
	let lastDay = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
	let formatedDate = `dataEntrada>=${firstDay};dataEntrada<=${lastDay}`;

	return formatedDate;
}

function NotasEntrada(props) {
	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		setSortField,
		sortField,
		setSortOrder,
		sortOrder,
		page,
		rows,
		filtroData,
		setFiltroData,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		selectedCard,
		setSelectedCard,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [cards, setCards] = useState([]);

	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.INSERIR)
	);
	const [podeEditar, setPodeEditar] = useState(
		usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.EDITAR)
	);
	const [podeExcluir, setPodeExcluir] = useState(
		usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.EXCLUIR)
	);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial, setDeveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
	);
	const [mostrarLoading, setMostrarLoading] = useState(false);
	const [firstRender, setFirstRender] = useState(true);

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffectOnce(() => {
		pesquisarCallback();
	});

	useEffect(() => {
		pesquisar();
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('NfeInputSearch')?.focus();
		}, 500);
	}, []);

	useUpdateEffect(() => {
		pesquisarCallback();
	}, [page, rows, sortOrder, sortField, filtroAvancado, interval, selectedCard]);

	function buscarFiltro() {
		const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.']);

		let filtroRSQL = String('?query=(')
			.concat(`serie=contains="*${pesquisaCodigo.replaceAll('&', '')}*",`)
			.concat(`numero=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`situacao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`fornecedor=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (selectedCard) {
			filtroRSQL = filtroRSQL.concat(`;(situacao=contains="*${selectedCard}*")`);
		}

		if (filtroData) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroData})`);
		} else {
			filtroRSQL = filtroRSQL.concat(`;(${currentMonth()})`);
		}

		if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}
		return filtroRSQL;
	}

	async function pesquisar() {
		if (interval?.dataInicial !== null && interval?.dataFinal !== null) {
			const filtro = buscarFiltro();
			let campoOrdenacao = sortField;
			let sentidoOrdenacao = sortOrder;
			if (firstRender) {
				setSortField('dataEntrada');
				setSortOrder(-1);
				campoOrdenacao = 'dataEntrada';
				sentidoOrdenacao = -1;
			}
			const url = construirUrl(
				`${services.GESTOR}/v1/compras/resumo`,
				filtro || '?',
				rows,
				page,
				sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
			);

			asyncGetTotalizadores(interval, ({ data: totais }) => {
				let localCards = {};

				totais &&
					totais.map((item) => {
						if (item.status === 'FINALIZADA') {
							localCards = {
								...localCards,
								finalizada: {
									...item,
								},
							};
						} else if (item.status === 'PENDENTE') {
							localCards = {
								...localCards,
								pendente: {
									...item,
								},
							};
						} else if (item.status === 'CANCELADA') {
							localCards = {
								...localCards,
								cancelada: {
									...item,
								},
							};
						}
					});
				setCards(localCards);
			});

			asyncGetNotasEntrada(url, ({ data: nota }) => {
				setRegistros(nota.content);
				setTotalElements(nota.totalElements);
				setFirstRender(false);
			});
		}
	}

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function handleChangeInterval(interval) {
		setInterval(interval);
		setFiltroData(
			`dataEntrada>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};dataEntrada<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	function onPesquisar() {
		pesquisar();
	}

	function baixarArquivosXml() {
		setMostrarLoading(true);
		asyncGetDownloadXmls(
			buscarFiltro().replace("fornecedor","emitente.nome"),
			({ data: data }) => {
				baixarArquivo(
					data,
					`ArquivosXmlImportados_${format(interval.dataInicial, 'dd-MM-yyyy')}_${format(
						interval.dataFinal,
						'dd-MM-yyyy'
					)}.zip`
				);
				setMostrarLoading(false);
			},
			() => {
				setMostrarLoading(false);
			}
		);
	}

	return (
		<>
			<ModalLoadingTransmissao visible={mostrarLoading} message="Gerando arquivo com os XMLs..." />
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Notas de entrada">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Nova nota"
						disabled={!podeInserir}
						onClick={() => {
							atualizarUrl(props.history, '/nota_entrada/cadastro');
						}}
					/>
					<Button
						className="step-listagem-novo"
						label="Importar XML"
						color="success"
						icon="pi pi-download"
						disabled={!podeInserir}
						onClick={() => {
							atualizarUrl(props.history, '/nota_entrada/importar_xml');
						}}
					/>
					<Button
						label="Download XMLs"
						className="step-listagem-novo p-button-primary"
						icon="fa fa-file-archive-o"
						title="Download dos arquivos XMLs importados das notas de entrada filtradas"
						style={{ margin: '5px' }}
						onClick={() => baixarArquivosXml()}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<DateInterval onChange={(e) => handleChangeInterval(e)} interval={interval} />
						<InputSearch
							value={valorPesquisa}
							onPesquisar={() => onPesquisar()}
							onChange={(value) => setValorPesquisa(value)}
							removerEComercial={false}
							id="NfeInputSearch"
						/>
						<Col
							sm="12"
							md="4"
							lg="3"
							xl="3"
							className="step-listagem-filtro-avancado"
							style={{ width: 'fit-content' }}
						>
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(descricao) => setDescricaoFiltroAvancado(descricao)}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<Grid justifyCenter>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								selectable
								name="cardNotasEmAberto"
								title="Notas finalizadas"
								value={cards.finalizada ? cards.finalizada.valor : 0}
								numberOfElements={cards.finalizada?.quantidade}
								colors={ColorsCard.FINALIZADA}
								selected={selectedCard === operacaoStatus.FINALIZADA}
								onSelect={() =>
									setSelectedCard(selectedCard === operacaoStatus.FINALIZADA ? '' : operacaoStatus.FINALIZADA)
								}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								selectable
								name="cardNotasFinalizadas"
								title="Notas pendentes"
								value={cards.pendente ? cards.pendente.valor : 0}
								numberOfElements={cards.pendente?.quantidade}
								colors={ColorsCard.PENDENTE}
								selected={selectedCard === operacaoStatus.PENDENTE}
								onSelect={() =>
									setSelectedCard(selectedCard === operacaoStatus.PENDENTE ? '' : operacaoStatus.PENDENTE)
								}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								selectable
								name="cardNotasCanceladas"
								title="Notas canceladas"
								value={cards.cancelada ? cards.cancelada.valor : 0}
								numberOfElements={cards.cancelada?.quantidade}
								colors={ColorsCard.CANCELADA}
								selected={selectedCard === operacaoStatus.CANCELADA}
								onSelect={() =>
									setSelectedCard(selectedCard === operacaoStatus.CANCELADA ? '' : operacaoStatus.CANCELADA)
								}
							/>
						</Col>
					</Grid>
					<TabelaNotas
						registros={registros}
						setRegistros={setRegistros}
						totalElements={totalElements}
						setTotalElements={setTotalElements}
						history={props.history}
						podeEditar={podeEditar}
						podeExcluir={podeExcluir}
					/>
				</FormContent>
			</Form>
		</>
	);
}

export default NotasEntrada;
