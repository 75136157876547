import { If, Modal } from 'components';
import AgentesAutorizadosForm from '../Form';

function ModalAgenteAutorizado(props) {
	const { visible, onHide } = props;

	return (
		<Modal
			className={visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de agente autorizado"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1200px' }}
		>
			<If test={visible}>
				<AgentesAutorizadosForm hideModal={onHide} isModal={true} />
			</If>
		</Modal>
	);
}

export default ModalAgenteAutorizado;
