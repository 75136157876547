import { Field, useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import * as Yup from 'yup';

import {
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	InputMask,
	Prompt,
	Tutorial,
	tutorialStepsSeguradora,
} from 'components';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	estadosCadastro,
	keyFilterConsultaRsql,
	mensagensDeValidacao,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';

import { isValidCNPJ } from '@brazilian-utils/brazilian-utils';
import { atualizarUrl, metodosAtualizarUrl } from '../../../../Util';
import { readSeguradora } from '../Requests';
import { CADASTRO_URL, INITIAL_VALUES, SITACAO_OPTIONS } from '../Util/constantes';
import { ActionButtons } from './components/ActionButtons';

function SeguradoraFormImpl({ history, isModal, match, hideModal }) {
	const { values, dirty, setFieldValue, resetForm } = useFormikContext();
	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EXCLUIR);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_SEGURADORA);

	const informacoesPermissoes = {
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		const { id } = match.params;

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_SEGURADORA, false, null, false);
		}

		if (validarUUID(id) && !isModal) {
			fetchRegistro(id);
		}

		setTimeout(() => {
			document.getElementById('input-field-nome')?.focus();
		}, 500);
	}, []);

	async function fetchRegistro(idSeguradora) {
		await readSeguradora(idSeguradora, ({ data: seguradora }) => {
			resetForm({ values: seguradora });

			if (!isModal) {
				atualizarUrl(history, CADASTRO_URL, seguradora.id, metodosAtualizarUrl.POP);
			}
		});
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsSeguradora}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form className="card-default screen-max-width" header="Cadastro de seguradora" isModal={isModal}>
				<FormActions className="screen-max-width">
					<ActionButtons informacoesPermissoes={informacoesPermissoes} isModal={isModal} hideModal={hideModal} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							id="input-field-nome"
							sm="12"
							md="5"
							lg="5"
							xl="5"
							component={InputField}
							label="Nome"
							obrigatorio
							keyfilter={keyFilterConsultaRsql}
							name="nome"
							size={30}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={InputMask}
							obrigatorio
							mask="00.000.000/0000-00"
							placeholder="  .   .   /    -  "
							label="CNPJ "
							name="cnpj"
							value={values.cnpj}
							onChange={(e) => setFieldValue('cnpj', e.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={InputField}
							label="Apólice padrão"
							obrigatorio
							keyfilter={keyFilterConsultaRsql}
							name="apolice"
							size={20}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={Dropdown}
							label="Situação"
							name="situacao"
							onChange={(event) => setFieldValue('situacao', event.value)}
							options={SITACAO_OPTIONS}
							obrigatorio
							showClear={false}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const SeguradoraFormFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};

		if (values.cnpj && !isValidCNPJ(values.cnpj)) {
			errors.cnpj = 'Digite um CNPJ válido.';
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.max(60, 'O campo não pode ter mais de 60 caracteres'),

		cnpj: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.max(14, 'O campo não pode ter mais de 14 caracteres'),

		apolice: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.min(1, 'Valor mínimo é 1')
			.max(60, 'O campo não pode ter mais de 60 caracteres'),
	}),

	handleSubmit: () => {},
})(SeguradoraFormImpl);

export const SeguradoraForm = withRouter(SeguradoraFormFormik);
