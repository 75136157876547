import { services } from '../../../../Common/Constantes/api';
import { post, exibirToast } from '../../../../Common/Requisicoes';

export async function asyncRecuperarSenha(email, onSuccess, onError) {
	const body = {
		email: email,
	};

	const message = 'Um e-mail de redefinição de senha foi enviado para sua caixa de entrada.';
	post(services.GESTOR + '/v1/credenciais/recuperar_senha', body, null, exibirToast(onSuccess, message), onError);
}
