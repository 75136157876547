export const helpOperacaoFiscalForm = {
	descricao: 'Nome da operação fiscal.',
	situacao: 'Situação em que se encontra a operação fiscal, pode ser ativa ou inativa.',
	indicacaoMovimento: 'Seleciona se a operação fiscal vai ser utilizada nas movimentações de entrada ou saída.',
	favoritoSaida: 'Destaca se a operação vai ser a padrão para a indicação de movimento Saída.',
	favoritoEntrada: 'Destaca se a operação vai ser a padrão para a indicação de movimento Entrada.',
	geraFinanceiro: 'Parâmetro para destacar se a movimentação vai ou não gerar financeiro.',
	geraEstoque: 'Parâmetro para destacar se a movimentação vai ou não gerar estoque.',
	atualizaCustoProduto: 'Parâmetro para destacar se a movimentação vai ou não atualizar o custo do produto.',
	consumidorFinal: 'Checkbox que destaca se a operação é para Consumidor Final..',
};
