import React, { useEffect, useRef, useState } from 'react';
import Grid from '../../../../../components/Grid';
import Col from '../../../../../components/Col';
import GraficoDespesasPorCategoria from './components/GraficoDespesasPorCategoria';
import PrevisoesDeReceitas from './components/PrevisoesDeReceitas';
import GraficoReceitasPorCategoria from './components/GraficoReceitasPorCategoria';
import PrevisoesDeDespesas from './components/PrevisoesDeDespesas';
import GraficosDosResultadosFinanceiros from './components/GraficosDosResultadosFinanceiros';
import GraficoBalancoDiario from './components/GraficoBalancoDiario';
import { connect } from 'react-redux';
import ListagemContas from './components/ListagemContas';
import BotoesNovosLancamentos from './components/BotoesNovosLancamentos';
import { usuarioPossuiPermissao } from '../../../../../Common/Autenticacao';
import { recursos, permissoes } from '../../../../../Common/Constantes/autorizacao';
import usePrevious from '../../../../../Common/Hooks/usePrevious';

function DashboardFinancas(props) {
	const { dataAtualizacaoMenuLateral, dataInicial, dataFinal, isMobile } = props;

	const [atualizarGraficoNovamente, setAtualizarGraficoNovamente] = useState(null);
	const [podeVisualizarContas, setPodeVisualizarContas] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.VISUALIZAR)
	);
	const [podeVisualizarContasReceber, setPodeVisualizarContasReceber] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR)
	);
	const [podeVisualizarContasPagar, setPodeVisualizarContasPagar] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR)
	);
	const [podeVisualizarCategoria, setPodeVisualizarCategoria] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.VISUALIZAR)
	);
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	const paddingCards = isMobile ? { padding: '0.5em 0em' } : { padding: '0.5em' };

	const getNewTimestamp = () => new Date().getTime();

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataAtualizacaoMenuLateral !== dataAtualizacaoMenuLateral) {
				setAtualizarGraficoNovamente(getNewTimestamp());
				setPodeVisualizarContas(
					usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.VISUALIZAR)
				);
				setPodeVisualizarContasReceber(
					usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR)
				);
				setPodeVisualizarContasPagar(
					usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR)
				);
				setPodeVisualizarCategoria(
					usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.VISUALIZAR)
				);
			}
		} else afterFirstRenderRef = true;
	});

	if (dataInicial && dataFinal) {
		return (
			<Grid style={{ margin: '0px', width: '100%' }}>
				<Col style={{ padding: '0 0 0 2px', width: '100%' }}>
					<BotoesNovosLancamentos
						isMobile={isMobile}
						atualizarGraficos={() => setAtualizarGraficoNovamente(getNewTimestamp())}
					/>
				</Col>
				<GraficosDosResultadosFinanceiros
					dataInicial={dataInicial}
					dataFinal={dataFinal}
					isMobile={isMobile}
					atualizarGraficoNovamente={atualizarGraficoNovamente}
				/>

				{podeVisualizarContasReceber && (
					<Col xs="12" sm="6" md="6" lg="6" xl="4" style={paddingCards}>
						<PrevisoesDeReceitas
							dataInicial={dataInicial}
							dataFinal={dataFinal}
							atualizarGraficoNovamente={atualizarGraficoNovamente}
							atualizarGraficos={() => setAtualizarGraficoNovamente(getNewTimestamp())}
							isMobile={isMobile}
						/>
					</Col>
				)}
				{podeVisualizarContasPagar && (
					<Col xs="12" sm="6" md="6" lg="6" xl="4" style={paddingCards}>
						<PrevisoesDeDespesas
							dataInicial={dataInicial}
							dataFinal={dataFinal}
							atualizarGraficoNovamente={atualizarGraficoNovamente}
							atualizarGraficos={() => setAtualizarGraficoNovamente(getNewTimestamp())}
							isMobile={isMobile}
						/>
					</Col>
				)}
				{podeVisualizarContas && (
					<Col xs="12" sm="12" md="12" lg="12" xl="4" style={paddingCards}>
						<ListagemContas
							dataInicial={dataInicial}
							dataFinal={dataFinal}
							atualizarGraficoNovamente={atualizarGraficoNovamente}
							isMobile={isMobile}
						/>
					</Col>
				)}
				{podeVisualizarCategoria && (
					<Col xs="12" sm="6" md="6" lg="6" xl="6" style={paddingCards}>
						<GraficoReceitasPorCategoria
							dataInicial={dataInicial}
							dataFinal={dataFinal}
							atualizarGraficoNovamente={atualizarGraficoNovamente}
							isMobile={isMobile}
						/>
					</Col>
				)}
				{podeVisualizarCategoria && (
					<Col xs="12" sm="6" md="6" lg="6" xl="6" style={paddingCards}>
						<GraficoDespesasPorCategoria
							dataInicial={dataInicial}
							dataFinal={dataFinal}
							atualizarGraficoNovamente={atualizarGraficoNovamente}
							isMobile={isMobile}
						/>
					</Col>
				)}
				<Col xs="12" sm="12" md="12" lg="12" xl="12" style={paddingCards}>
					<GraficoBalancoDiario
						dataInicial={dataInicial}
						dataFinal={dataFinal}
						atualizarGraficoNovamente={atualizarGraficoNovamente}
						isMobile={isMobile}
					/>
				</Col>
			</Grid>
		);
	} else {
		return <span>Carregando informações...</span>;
	}
}

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
	dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
});
export default connect(mapStateToProps)(DashboardFinancas);
