import { tipoCampos } from '../../../../components/PesquisaAvancada/Util/constantes';

export const statusGestaoDocumento = {
  PENDENTE: 'PENDENTE',
  VIGENTE: 'VIGENTE',
  VIGENCIA_EXPIRADA: 'VIGENCIA_EXPIRADA',
  RESCINDIDO: 'RESCINDIDO',
};

export const tipoGestaoDocumento = {
  CONTRATO: 'CONTRATO',
  DOCUMENTO: 'DOCUMENTO',
};

export const optionsFiltroAvancado = [
  { label: 'Identificação', name: 'identificacao', type: tipoCampos.STRING },
  { label: 'Nome da parte', name: 'partes.nome', type: tipoCampos.STRING },
  { label: 'CPF da parte', name: 'partes.pessoaFisica.cpf', type: tipoCampos.STRING },
  { label: 'CNPJ da parte', name: 'partes.pessoaJuridica.cnpj', type: tipoCampos.STRING },
  {
    label: 'Status',
    name: 'status',
    type: tipoCampos.SELECT,
    optionSelect: [
      { label: 'Pendente', value: statusGestaoDocumento.PENDENTE },
      { label: 'Vigente', value: statusGestaoDocumento.VIGENTE },
      { label: 'Rescindido', value: statusGestaoDocumento.RESCINDIDO },
      {
        label: 'Vigência expirada',
        value: statusGestaoDocumento.VIGENCIA_EXPIRADA,
      },
    ],
  },
  { label: 'Data de criação', name: 'criadoEm', type: tipoCampos.DATE },
  { label: 'Inicio da vigência', name: 'vigenciaInicio', type: tipoCampos.DATE },
  { label: 'Final da vigência', name: 'vigenciaFinal', type: tipoCampos.DATE },
];

export const ColorsCard = {
  PENDENTE: {
    lightColor: '#fff7cd',
    strongColor: '#c16b13',
  },
  VIGENTE: {
    lightColor: '#c8facd',
    strongColor: '#008013',
  },
  RESCINDIDO: {
    lightColor: '#ffcdd2',
    strongColor: '#da1f1f',
  },
  VIGENCIA_EXPIRADA: {
    lightColor: '#e6e6e6',
    strongColor: '#666666',
  },
};

export const CADASTROURL = '/documentos/gestao_documentos/cadastro';

export const PESQUISAURL = '/documentos/gestao_documentos';
