import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  ButtonNovo,
  Grid,
  ButtonEditarTable,
  ButtonExcluirTable,
  InputSearch,
  Tutorial,
  tutorialStepsListagens,
  Form,
  FormActions,
  FormContent,
  NenhumRegistroEncontrado,
  Paginacao,
  Badge,
  Col,
  PesquisaAvancada,
  Checkbox,
  DescricaoFiltroAvancado,
} from '../../../components';

import {
  services,
  recursos,
  permissoes,
  construirUrl,
  removerElemento,
  usuarioPossuiPermissao,
  salvarConfiguracaoUsuario,
  configuracoesUsuario,
  buscarConfiguracaoUsuario,
  useStateCallback,
} from '../../../Common';

import { asyncDeleteTecnico, asyncGetPesquisaTecnico } from './Requests';
import { atualizarUrl } from '../../Util';
import { confirmarExclusao } from '../../Util/ExclusaoDeRegistros';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Colors, optionsFiltroAvancado, styleBackground } from './Util/constantes';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';

function Tecnicos(props) {
  const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.TECNICOS, permissoes.INSERIR));
  const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.TECNICOS, permissoes.EXCLUIR));
  const [deveExibirTutorial, setDeveExibirTutorial] = useState(
    buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
  );

  const {
    valorPesquisa,
    setValorPesquisa,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    rows,
    setRows,
    filtroAvancado,
    setFiltroAvancado,
    descricaoFiltroAvancado,
    setDescricaoFiltroAvancado,
    exibirBloqueadas,
    setExibirBloqueadas,
  } = useContextPesquisa();

  const [registros, setRegistros] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [tutorialVisible, setTutorialVisible] = useState(false);

  const pesquisarCallback = useCallback(() => {
    pesquisar();
  });

  useEffect(() => {
    if (deveExibirTutorial !== false) {
      setTutorialVisible(true);
      salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
    }

    setTimeout(() => {
      document.getElementById('tecnicosInputSearch')?.focus();
    }, 500);
  }, []);

  async function onPesquisarFiltroAvancado(filtro) {
    setFiltroAvancado(filtro, pesquisarCallback());
  }

  useEffect(() => {
    pesquisarCallback();
  }, [exibirBloqueadas, page, rows, sortField, filtroAvancado]);

  function pesquisar() {
    const filtro = `?query=nome=contains="*${valorPesquisa}*"`;
    const url = construirUrl(
      `${services.GESTOR}/v1/tecnicos`,
      filtro,
      rows,
      page,
      sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
    );
    asyncGetPesquisaTecnico(url, e => {
      setRegistros(e.data.content);
      setTotalElements(e.data.totalElements);
    });
  }

  function buscarFiltro() {
    let result = `?query=(nome=contains="*${valorPesquisa}*")`;

    if (filtroAvancado) {
      result += `;${filtroAvancado}`;
    }

    return result;
  }

  async function pesquisar(firstPage = null) {
    if (firstPage !== null) {
      //setPage(firstPage);
    }

    let filtro = buscarFiltro();
    filtro += `${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`;
    const url = construirUrl(
      `${services.GESTOR}/v1/tecnicos`,
      filtro,
      rows,
      firstPage !== null ? firstPage : page,
      sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
    );

    await asyncGetPesquisaTecnico(url, ({ data: marcas }) => {
      setRegistros(marcas.content);
      setTotalElements(marcas.totalElements);

      if (!exibirBloqueadas && !!filtroAvancado && filtroAvancado.includes('situacao=="INATIVO"')) {
        setExibirBloqueadas(true);
      }
    });
  }

  function onPesquisar() {
    pesquisar();
  }

  function onPageChange(e) {
    setPage(e.page);
    setRows(e.rows);
    pesquisar();
  }

  function onSort(e) {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  }

  function onEditar(row) {
    atualizarUrl(props.history, '/tecnicos/cadastro', row.id);
  }

  function onExcluir(row) {
    confirmarExclusao(() => asyncExcluirRegistro(row));
  }

  async function asyncExcluirRegistro(registro) {
    await asyncDeleteTecnico(registro.id, () => {
      setRegistros(removerElemento(registros, registro));
      setTotalElements(totalElements - 1);
    });
  }

  function renderSituacao(row) {
    const styleDescription = {
      fontStyle: row.situacao === 'INATIVO' ? 'italic' : '',
      opacity: row.situacao === 'INATIVO' ? '0.7' : '',
      margin: row.situacao === 'ATIVO' ? '3px 16px' : '3px 10px',
    };

    switch (row.situacao) {
      case 'ATIVO': {
        return Badge(Colors.textAtivo, Colors.bgAtivo, 'Ativo', styleBackground, styleDescription);
      }
      case 'INATIVO': {
        return Badge(Colors.textInativo, Colors.bgInativo, 'Inativo', styleBackground, styleDescription);
      }
      default:
        return row.situacao;
    }
  }

  function renderOpcoes(row) {
    return (
      <div style={{ display: 'flex' }}>
        <ButtonEditarTable onClick={() => onEditar(row)} />
        <ButtonExcluirTable onClick={() => onExcluir(row)} podeExcluir={podeExcluir} />
      </div>
    );
  }

  function renderNome(row) {
    return (
      <span
        style={{
          wordBreak: 'break-word',
          maxWidth: props.isMobile ? '70%' : '100%',
          textAlign: props.isMobile ? 'end' : 'start',
        }}
      >
        {row.nome}
      </span>
    );
  }

  async function onPesquisarFiltroAvancado(filtro) {
    setFiltroAvancado(filtro, pesquisarCallback());
  }

  function onChangeCheckboxExibirInativos(element) {
    setExibirBloqueadas(element.checked);
  }

  return (
    <>
      <Tutorial
        steps={tutorialStepsListagens}
        showSkipButton
        continuous
        disableScrolling
        visible={tutorialVisible}
        onHide={() => setTutorialVisible(false)}
      />

      <Form header="Técnicos">
        <FormActions>
          <ButtonNovo
            label="Novo técnico"
            className="step-listagem-novo"
            onClick={() => {
              props.history.push('/tecnicos/cadastro');
            }}
            podeInserir={podeInserir}
          />
        </FormActions>
        <FormContent>
          <Grid justifyCenter>
            <InputSearch
              id="tecnicosInputSearch"
              className="step-listagem-input-search"
              onPesquisar={() => onPesquisar()}
              value={valorPesquisa}
              onChange={value => setValorPesquisa(value)}
            />
            <Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
              <PesquisaAvancada
                className="step-listagem-filtro-avancado"
                optionsFiltros={optionsFiltroAvancado}
                onPesquisarClick={onPesquisarFiltroAvancado}
                onChangeFiltroRsql={rsql => setFiltroAvancado(rsql)}
                onChangeDescricaoFiltro={descricao => setDescricaoFiltroAvancado(descricao)}
              />
            </Col>
          </Grid>
          <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
          <DataTable
            className="table"
            responsive
            value={registros}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            emptyMessage={<NenhumRegistroEncontrado />}
            header={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div>
                  <Checkbox
                    name="exibirBloqueadas"
                    inputId="exibirBloqueadas"
                    checked={exibirBloqueadas}
                    onChange={onChangeCheckboxExibirInativos}
                  />
                </div>
                <div>
                  <label htmlFor="exibirTributacoesInativas" className="p-checkbox-label">
                    Exibir técnicos inativos
                  </label>
                </div>
              </div>
            }
          >
            <Column className="step-listagem-order" field="nome" header="Nome" body={renderNome} sortable={true} />
            <Column field="situacao" header="Situação" body={renderSituacao} sortable style={{ width: '15rem' }} />
            <Column
              className="step-listagem-acoes"
              body={row => renderOpcoes(row)}
              header="Ações"
              style={{ width: '7em' }}
            />
          </DataTable>
          <Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={e => onPageChange(e)} />
        </FormContent>
      </Form>
    </>
  );
}

function mapStateToProps(state) {
  return {
    isMobile: state.dispositivo.isMobile,
  };
}

export default connect(mapStateToProps)(Tecnicos);
