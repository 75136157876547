export const tutorialStepsNotaFiscal = [
	{
		target: 'body',
		content: `Este é o cadastro de nota fiscal`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-nfe-cliente',
		content: 'Nesta seção é possível informar os campos do cabeçalho da nota',
		placement: 'top',
	},
	{
		target: '.step-nfe-produtos',
		content: 'E nesta área você pode gerenciar os produtos desta nota',
	},
	{
		target: '.step-nfe-totalizadores',
		content:
			'Por fim, nesta aba estão os totalizadores da nota, além disso, nas demais abas, podem ser alterados as informações de pagamentos, transporte e informações complementares.',
	},
];
