export const tutorialStepsNFCe = [
	{
		target: 'body',
		content: `Este é o frente de caixa do MsysGestor. Um PDV que funciona até no seu celular.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-nfce-produto',
		content: 'Aqui você pode incluir novos produtos na NFC-e.',
	},
	{
		target: '.step-nfce-action-buttons',
		content:
			'Estes são os botões de ação, com eles, é possível resetar o preenchimento, acessar uma NFC-e já transmitida, cancelar um documento, ou inutilizar uma numeração.',
	},
	{
		target: '.step-nfce-listagem-produto',
		content:
			'E aqui serão listados os produtos adicionados, sendo possível edita-los ou excluí-los antes da transmissão.',
	},
	{
		target: '.step-nfce-pessoa',
		content: 'Neste botão você pode adicionar as informações do consumidor, como por exemplo, nome e CPF/CNPJ.',
	},
	{
		target: '.step-nfce-finalizacao',
		content:
			'Por fim, ao clicar em "Finalizar", será aberto uma nova tela para que seja possível incluir as formas de pagamento e transmitir a NFC-e.',
	},
];
