import { services, del, get, post, put, exibirToast } from 'Common';
import { notify, ToastTypes } from 'components';

export async function asyncGetCodigosServico(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncCreateCodigoServico(codigoServico, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/tributacoes/codigo_servico`,
		codigoServico,
		null,
		exibirToast(onSuccess, 'Código serviço criado com sucesso!', ToastTypes.SUCCESS),
		onError
	);
}

export async function asyncDeleteCodigoServico(idCodigoServico, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/tributacoes/codigo_servico/${idCodigoServico}`,
		null,
		response => {
			if (response.status === 400) {
				notify(response.data.message, ToastTypes.ERROR, 5);
			} else {
				notify('Código de serviço excluído', ToastTypes.SUCCESS, 5, onSuccess());
			}
		},
		onError
	);
}

export async function asyncUpdateCodigoServico(codigoServico, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/tributacoes/codigo_servico/${codigoServico.id}`,
		codigoServico,
		null,
		exibirToast(onSuccess, 'Código serviço atualizado'),
		onError
	);
}

export async function asyncGetCodigoServico(idCodigoServico, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/tributacoes/codigo_servico/${idCodigoServico}`, null, onSuccess, onError);
}

export async function asyncGetCodigoServicoByCodigoExtensao(codigoCodigoServico, onSuccess, onError) {
	let query = `${services.GESTOR}/v1/tributacoes/codigo_servico?query=codigoServico.codigo==${codigoCodigoServico}`;

	await get(query, null, onSuccess, onError);
}
