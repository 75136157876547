import propTypes from 'prop-types';
import { Password } from 'primereact/password';
import Col from '../../../../Col';
import {
	buscarHiddenDeAcordoComAsPermissoes,
	buscarDisabledDeAcordoComAsPermissoes,
	buscarTitleCampoDeAcordoComAsPermissoes,
} from '../../../../../Common/Autorizacao/ManipulacaoDeComponentes';
import {
	renderizarValidacao,
	buscarClassNamePelosErros,
} from '../../../../../Common/TratamentoDeErro/validacoesDeCampos';

export function InternalInputPassword(props) {
	const {
		sm,
		md,
		lg,
		xl,
		label,
		feedback,
		id,
		name,
		title,
		placeholder,
		value,
		onChange,
		className,
		onBlur,
		onKeyDown,
		errors,
		touched,
		podeVisualizar,
		podeInserir,
		podeEditar,
		estadoCadastro,
		disabled,
		colId,
	} = props;

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden))
			return { display: 'none', ...props.colStyle };
		return props.colStyle;
	}

	return (
		<Col id={colId} sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			<label title={props.helpMessage}>{label}</label>
			<span
				title={buscarTitleCampoDeAcordoComAsPermissoes(
					podeVisualizar,
					podeInserir,
					podeEditar,
					estadoCadastro,
					title
				)}
				className="p-fluid"
			>
				<Password
					promptLabel="Informe pelo menos 8 dígitos"
					weakLabel="Senha fraca"
					mediumLabel="Senha mediana"
					strongLabel="Senha forte"
					feedback={feedback}
					id={id}
					name={name}
					title={title}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					className={buscarClassNamePelosErros(className, errors, touched)}
					disabled={buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled)}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					autoComplete="off"
				/>
			</span>
			{renderizarValidacao(errors, touched)}
		</Col>
	);
}

InternalInputPassword.propTypes = {
	/** Evento disparado ao modificar o componente do componente */
	onChange: propTypes.func,
	/** Label do componente */
	label: propTypes.string,
	/** Identificador do componente */
	id: propTypes.string,
	/** Identificador do componente */
	colId: propTypes.string,
	/** Placeholder do componente */
	placeholder: propTypes.string,
	/** Valor do componente do componente */
	value: propTypes.any,
	/** Nome da classe do componente */
	className: propTypes.string,
	/** Define se o componente está desabilitado*/
	disabled: propTypes.bool,
	/** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
	errors: propTypes.string,
	/** Especifica se o componente foi 'tocado'*/
	touched: propTypes.bool,
	/** Evento executado ao sair do campo*/
	onBlur: propTypes.any,
	/** Evento executado ao pressionar uma tecla do campo*/
	onKeyDown: propTypes.any,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
	/** Estilo da coluna*/
	colStyle: propTypes.object,
	/** Estado em que o cadastro se encontra*/
	estadoCadastro: propTypes.string,
	/** Diz se o usuário possui permissão de visualizar*/
	podeVisualizar: propTypes.bool,
	/** Diz se o usuário possui permissão de editar*/
	podeEditar: propTypes.bool,
	/** Diz se o usuário possui permissão de excluir*/
	podeInserir: propTypes.bool,
	/** Title do componente*/
	title: propTypes.string,
	/** Mostrar o indicador de senha fraca ou forte */
	feedback: propTypes.bool,
};
