import { Grid, InputDate, InputField, TextArea } from 'components';
import { Field, useFormikContext } from 'formik';
import { helpMessage } from '../../Util/constantes';

function FormInformacoesComplementares({ informacoesPermissoes, isReparcelada }) {
	const { values, setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputDate}
				label="Competência"
				name="competencia"
				helpMessage={helpMessage.competencia}
				onChange={(e) => setFieldValue('competencia', e.target.value)}
				value={values.competencia}
				disabled={
					(!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)) ||
					!!values.pix ||
					isReparcelada
				}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputField}
				label="Número do documento de origem"
				name="numeroDocumento"
				helpMessage={helpMessage.numeroDocumento}
				size={255}
				disabled={
					(!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)) ||
					!!values.pix ||
					isReparcelada
				}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputDate}
				label="Data do documento de origem"
				name="dataDocumento"
				helpMessage={helpMessage.dataDocumento}
				onChange={(e) => setFieldValue('dataDocumento', e.target.value)}
				value={values.dataDocumento}
				disabled={
					(!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)) ||
					!!values.pix ||
					isReparcelada
				}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				component={TextArea}
				placeholder="Escreva sua observação aqui"
				label="Observação"
				name="observacao"
				helpMessage={helpMessage.observacao}
				maxLength={4096}
				onChange={(e) => setFieldValue('observacao', e.target.value)}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { FormInformacoesComplementares };
