import { MdOutlineImageNotSupported } from 'react-icons/md';

export default function WebCamCapture(props) {
	const { id, hasPicture, imagemUrl, isMobile, isTablet } = props;

	const styleContainer = {
		display: hasPicture && (isTablet || isMobile) ? 'flex' : isTablet || isMobile ? 'none' : 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		marginLeft: isTablet || isMobile ? '0' : '10px',
	};

	const styleTitle = {
		display: 'flex',
		fontSize: '16px',
		width: '100%',
	};

	const styleCanvas = {
		display: 'none',
	};

	const styleImg = {
		display: hasPicture ? 'flex' : 'none',
		maxWidth: isMobile ? 'calc(100% - 5px)' : '450px',
		maxHeight: '600px',
		borderRadius: '3px',
		width: isMobile ? '100%' : '450px',
	};

	const styleContentIcon = {
		display: !hasPicture ? 'flex' : 'none',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: isMobile ? 'calc(100% - 5px)' : '450px',
		height: '337px',
		border: '1px solid #BBBBBB',
		borderRadius: '3px',
	};

	const styleIcon = {
		maxWidth: isMobile ? 'calc(100% - 5px)' : '450px',
		maxHeight: '600px',
		borderRadius: '3px',
	};

	return (
		<div style={styleContainer}>
			<h1 style={styleTitle}>Imagem</h1>
			<canvas id={id} style={styleCanvas}></canvas>
			<img id={id} src={imagemUrl} style={styleImg}></img>
			<div style={styleContentIcon}>
				<MdOutlineImageNotSupported size={60} style={styleIcon} />
				<p>Sem imagem</p>
			</div>
		</div>
	);
}
