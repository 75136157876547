import { tipoCampos } from 'components';

export const CADASTRO_URL = '/formas_pagamento/cadastro';
export const PESQUISA_URL = '/formas_pagamento';

export const FORMA_PAGAMENTO_FISCAL = {
	DINHEIRO: 'DINHEIRO',
	CHEQUE: 'CHEQUE',
	CARTAO_CREDITO: 'CARTAO_CREDITO',
	CARTAO_DEBITO: 'CARTAO_DEBITO',
	CREDITO_LOJA: 'CREDITO_LOJA',
	VALE_ALIMENTACAO: 'VALE_ALIMENTACAO',
	VALE_REFEICAO: 'VALE_REFEICAO',
	VALE_PRESENTE: 'VALE_PRESENTE',
	VALE_COMBUSTIVEL: 'VALE_COMBUSTIVEL',
	BOLETO_BANCARIO: 'BOLETO_BANCARIO',
	SEM_PAGAMENTO: 'SEM_PAGAMENTO',
	DEPOSITO_BANCARIO: 'DEPOSITO_BANCARIO',
	PAGAMENTO_INSTANTANEO_PIX: 'PAGAMENTO_INSTANTANEO_PIX',
	TRANSFERENCIA_BANCARIA: 'TRANSFERENCIA_BANCARIA',
	PROGRAMA_DE_FINALIDADE: 'PROGRAMA_DE_FINALIDADE',
	OUTROS: 'OUTROS',
};

export const FORMA_PAGAMENTO_FISCAL_OPTIONS = [
	{ label: 'Dinheiro', value: FORMA_PAGAMENTO_FISCAL.DINHEIRO },
	{ label: 'Cheque', value: FORMA_PAGAMENTO_FISCAL.CHEQUE },
	{ label: 'Cartão de crédito', value: FORMA_PAGAMENTO_FISCAL.CARTAO_CREDITO },
	{ label: 'Cartão de débito', value: FORMA_PAGAMENTO_FISCAL.CARTAO_DEBITO },
	{ label: 'Crédito na loja', value: FORMA_PAGAMENTO_FISCAL.CREDITO_LOJA },
	{ label: 'Vale alimentação', value: FORMA_PAGAMENTO_FISCAL.VALE_ALIMENTACAO },
	{ label: 'Vale refeição', value: FORMA_PAGAMENTO_FISCAL.VALE_REFEICAO },
	{ label: 'Vale presente', value: FORMA_PAGAMENTO_FISCAL.VALE_PRESENTE },
	{ label: 'Vale combustível', value: FORMA_PAGAMENTO_FISCAL.VALE_COMBUSTIVEL },
	{ label: 'Boleto bancário', value: FORMA_PAGAMENTO_FISCAL.BOLETO_BANCARIO },
	{ label: 'Sem Pagamento', value: FORMA_PAGAMENTO_FISCAL.SEM_PAGAMENTO },
	{ label: 'Depósito bancário', value: FORMA_PAGAMENTO_FISCAL.DEPOSITO_BANCARIO },
	{ label: 'Pix - Pagamento instantâneo', value: FORMA_PAGAMENTO_FISCAL.PAGAMENTO_INSTANTANEO_PIX },
	{ label: 'Transferência bancária, Carteira digital', value: FORMA_PAGAMENTO_FISCAL.TRANSFERENCIA_BANCARIA },
	{
		label: 'Programa de fidelidade, Cashback, Crédito virtual',
		value: FORMA_PAGAMENTO_FISCAL.PROGRAMA_DE_FINALIDADE,
	},
	{ label: 'Outros', value: FORMA_PAGAMENTO_FISCAL.OUTROS },
];

export const OPTIONS_FILTRO_AVANCADO = [
	{ label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
	{
		label: 'Padrão',
		name: 'podeExcluir',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Sim', value: 'false' },
			{ label: 'Não', value: 'true' },
		],
	},

	{
		label: 'Forma fiscal',
		name: 'formaPagamentoFiscal',
		type: tipoCampos.SELECT,
		optionSelect: FORMA_PAGAMENTO_FISCAL_OPTIONS,
	},
];

export const INITIAL_VALUES = {
	id: null,
	descricao: null,
	podeExcluir: true,
	conta: null,
	formaPagamentoFiscal: 'DINHEIRO',
	categoriaReceita: null,
	categoriaDespesa: null,
};
