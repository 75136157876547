import { calcularImpostosProduto, getSafeValue, montarCalcularImpostosProduto } from 'Common';

const styleColumn = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

function getUfDestinatario(pessoa) {
	if (pessoa) {
		if (pessoa?.registro?.enderecos) {
			const listaEnderecos = pessoa?.registro?.enderecos.filter((endereco) => endereco.favorito === true);
			if (listaEnderecos.length > 0) {
				return listaEnderecos[0]?.municipio?.ufId;
			}
			return pessoa?.registro?.enderecos[0]?.municipio?.ufId;
		}
	}
	return '';
}

async function montarECalcularTributacaoProduto(item, pessoa) {
	let produto = { ...item };

	if (item.produto && item.operacaoFiscal) {
		const ufDestinatario = getUfDestinatario(pessoa);
		if (ufDestinatario) {
			const quantidade = getSafeValue(item?.quantidade);
			const valor = getSafeValue(item?.valor);
			const seguro = getSafeValue(item?.seguro);
			const frete = getSafeValue(item?.frete);
			const acessorias = getSafeValue(item?.acessorias);
			const desconto = getSafeValue(item?.desconto);

			const baseCalculo = (quantidade * valor + seguro + frete + acessorias - desconto).toFixed(2);
			produto.baseCalculo = parseFloat(baseCalculo);

			produto = await montarCalcularImpostosProduto(ufDestinatario, produto);

			const valorIcmsSt = getSafeValue(produto?.tributos?.icms?.valorIcmsSt);
			const valorIpi = getSafeValue(produto?.tributos?.ipi?.valorIpi);

			const subtotal = (quantidade * valor + seguro + frete + acessorias + valorIcmsSt + valorIpi - desconto).toFixed(
				2
			);

			produto.subtotal = parseFloat(subtotal);
		}
	}

	return produto;
}

async function calcularTributacaoProduto(item) {
	const produto = { ...item };
	const quantidade = getSafeValue(item?.quantidade);
	const valor = getSafeValue(item?.valor);
	const seguro = getSafeValue(item?.seguro);
	const frete = getSafeValue(item?.frete);
	const acessorias = getSafeValue(item?.acessorias);
	const desconto = getSafeValue(item?.desconto);

	const baseCalculo = (quantidade * valor + seguro + frete + acessorias - desconto).toFixed(2);
	produto.baseCalculo = parseFloat(baseCalculo);

	produto.tributos = await calcularImpostosProduto(item);

	const valorIcmsSt = getSafeValue(produto?.tributos?.icms?.valorIcmsSt);
	const valorIpi = getSafeValue(produto?.tributos?.ipi?.valorIpi);

	const subtotal = (quantidade * valor + seguro + frete + acessorias + valorIcmsSt + valorIpi - desconto).toFixed(2);

	produto.subtotal = parseFloat(subtotal);

	return produto;
}

async function montarECalcularTributacaoTodosProdutos(itens, pessoa, onSuccess) {
	const promises = itens?.map((produto) => montarECalcularTributacaoProduto(produto, pessoa));
	const produtos = calcularComissaoValorProdutos(await Promise.all(promises));

	if (typeof onSuccess === 'function') {
		onSuccess(produtos);
	}
}

async function calcularTributacaoTodosProdutos(itens, onSuccess) {
	const promises = itens?.map((produto) => calcularTributacaoProduto(produto));
	const produtos = calcularComissaoValorProdutos(await Promise.all(promises));

	if (typeof onSuccess === 'function') {
		onSuccess(produtos);
	}
}
function calcularComissaoValorProdutos(produtos) {
	produtos?.forEach((produto) => {
		produto.comissaoValor = produto.comissaoPercentual
			? parseFloat((produto.subtotal * (produto.comissaoPercentual / 100)).toFixed(2))
			: 0;
	});

	return produtos;
}

export {
	styleColumn,
	styleButton,
	getUfDestinatario,
	montarECalcularTributacaoProduto,
	montarECalcularTributacaoTodosProdutos,
	calcularTributacaoProduto,
	calcularTributacaoTodosProdutos,
};
