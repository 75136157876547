export function jaExisteMesmoTelefone(listaTelefones, values) {
    let mesmoTelefone = false;
    if (listaTelefones.length > 0) {
        for (let i = 0; i < listaTelefones.length; i++) {
            const element = listaTelefones[i];
            
            if (element.numero === values.numero) {
                if(element.id !== values.id) {
                    mesmoTelefone = true;
                } else {
                    mesmoTelefone = false;
                }
            }
        }
    }
    return mesmoTelefone;
}
