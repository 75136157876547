import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';

import { Grid, TabView } from 'components';

import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';

import { Cofins } from './components/Cofins';
import { Descricao } from './components/Descricao';
import { Devolucao } from './components/Devolucao';
import { Icms } from './components/Icms';
import { Importacao } from './components/Importacao';
import { Ipi } from './components/Ipi';
import { Pis } from './components/Pis';

function Tabs({ disabled, finalidadeDocumento, informacoesPermissoes, pessoa, tipoOperacao, setAtualizarSubTotal }) {
	const { values } = useFormikContext();

	function onBlurValoresTributacao() {
		setAtualizarSubTotal(true);
	}

	return (
		<Grid>
			<TabView className="tab-view" {...informacoesPermissoes}>
				<TabPanel header="ICMS">
					<Icms
						tributo={values?.tributos?.icms}
						disabled={disabled}
						informacoesPermissoes={informacoesPermissoes}
						onBlurValoresTributacao={onBlurValoresTributacao}
					/>
				</TabPanel>
				<TabPanel header="IPI">
					<Ipi
						tributo={values?.tributos?.ipi}
						disabled={disabled}
						informacoesPermissoes={informacoesPermissoes}
						onBlurValoresTributacao={onBlurValoresTributacao}
					/>
				</TabPanel>
				<TabPanel header="PIS">
					<Pis tributo={values?.tributos?.pis} disabled={disabled} informacoesPermissoes={informacoesPermissoes} />
				</TabPanel>
				<TabPanel header="COFINS">
					<Cofins
						tributo={values?.tributos?.cofins}
						disabled={disabled}
						informacoesPermissoes={informacoesPermissoes}
					/>
				</TabPanel>
				<TabPanel
					header="Devolução"
					disabled={finalidadeDocumento !== 'DEVOLUCAO_MERCADORIA'}
					headerStyle={{
						display: finalidadeDocumento !== 'DEVOLUCAO_MERCADORIA' ? 'none' : 'unset',
					}}
				>
					<Devolucao
						tributo={values?.tributos?.devolvido}
						disabled={disabled}
						informacoesPermissoes={informacoesPermissoes}
					/>
				</TabPanel>
				<TabPanel
					header="Importação"
					disabled={!(pessoa?.registro?.tipo === TIPO_PESSOA.ESTRANGEIRO && tipoOperacao === 'ENTRADA')}
					headerStyle={{
						display: !(pessoa?.registro?.tipo === TIPO_PESSOA.ESTRANGEIRO && tipoOperacao === 'ENTRADA')
							? 'none'
							: 'unset',
					}}
				>
					<Importacao
						importacao={values?.importacao}
						disabled={disabled}
						informacoesPermissoes={informacoesPermissoes}
					/>
				</TabPanel>
				<TabPanel header="Observação">
					<Descricao disabled={disabled} informacoesPermissoes={informacoesPermissoes} />
				</TabPanel>
			</TabView>
		</Grid>
	);
}

export { Tabs };
