import { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Field, useFormikContext } from 'formik';

import { keyFilterConsultaRsql } from 'Common';
import {
	ButtonEditarTable,
	ButtonExcluirTable,
	Grid,
	If,
	NenhumRegistroEncontrado,
	SingleSelectReboque,
} from 'components';

import { formatarPlaca } from 'views/cadastros/transporte/Veiculos/Util/function';
import { useContextMDFe } from 'views/transporte/MDFe/Context';

import ModalReboques from './components/ModalReboques';
import { converterReboqueModalParaForm } from './components/ModalReboques/Util/converter';

import '../../../../Styles/index.css';

function Reboques({ disabled }) {
	const { values, setFieldValue } = useFormikContext();
	const { isMobile, informacoesPermissoes, visibleModalReboques, setVisibleModalReboques } = useContextMDFe();
	const [sortOrder, setSortOrder] = useState(1);
	const [sortField, setSortField] = useState('placa');
	const [reboque, setReboque] = useState(null);
	const [reboqueParaModal, setReboqueParaModal] = useState(null);

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function handleClickExcluir(row) {
		const newValues = values.reboques.filter((reboque) => reboque.idRegistro !== row.idRegistro);
		setFieldValue('reboques', newValues);
	}

	function handleAdicionarReboque(reboque) {
		setFieldValue('reboques', [
			...values.reboques,
			{
				id: null,
				idRegistro: reboque?.registro?.id,
				placa: formatarPlaca(reboque?.registro?.placa),
				renavam: reboque?.registro?.renavam,
				estado: reboque?.registro?.uf,
				tara: reboque?.registro?.tara,
				capacidade: reboque?.registro?.capacidade,
				carroceria: reboque?.registro?.carroceria,
				veiculo: reboque?.registro,
			},
		]);
		setReboque(null);
	}

	function handleEditarReboque(reboqueEditado) {
		const index = values.reboques.findIndex((reboque) => reboque.idRegistro === reboqueEditado.idRegistro);
		const newValues = [...values.reboques];
		newValues[index] = converterReboqueModalParaForm(reboqueEditado);
		setFieldValue('reboques', newValues);
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable
					disabled={disabled}
					onClick={() => {
						setVisibleModalReboques(true);
						setReboqueParaModal(row);
					}}
				/>
				<ButtonExcluirTable disabled={disabled} onClick={() => handleClickExcluir(row)} />
			</div>
		);
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return <span style={{ fontStyle: 'italic', opacity: '0.7' }}>{field}</span>;
		}
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-all',
					paddingLeft: isMobile ? '15%' : '0',
				}}
			>
				{field}
			</span>
		);
	}

	function aplicarEstiloInativaEstado(row, field) {
		const convertido = `${field?.nome} - ${field?.sigla}`;
		return aplicarEstiloInativa(row, convertido);
	}

	return (
		<>
			<Grid style={{ display: 'block', padding: '0px' }}>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={SingleSelectReboque}
					optionsRemover={values.reboques}
					label="Reboque"
					value={reboque}
					onChange={handleAdicionarReboque}
					keyfilter={keyFilterConsultaRsql}
					name="reboque"
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<div className="cards-tabs-mdfe">
					<DataTable
						className="table tables-tabs-mdfe"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={values.reboques}
						sortField={sortField}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column field="placa" header="Placa" body={(row) => aplicarEstiloInativa(row, row.placa)} sortable />
						<Column field="renavam" header="Renavam" body={(row) => aplicarEstiloInativa(row, row.renavam)} sortable />
						<Column
							field="estado"
							header="Estado"
							body={(row) => aplicarEstiloInativaEstado(row, row.estado)}
							sortable
						/>
						<Column className="step-listagem-acoes" header="Ações" style={{ width: '5%' }} body={renderOpcoes} />
					</DataTable>
				</div>
			</Grid>
			<If test={visibleModalReboques}>
				<ModalReboques
					visible={visibleModalReboques}
					onClose={() => setVisibleModalReboques(false)}
					onSave={(values) => handleEditarReboque(values)}
					initialValue={reboqueParaModal}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</If>
		</>
	);
}

export { Reboques };
