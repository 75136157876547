import { useState } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Grid, If } from 'components';
import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';

import { OPTIONS_SELECT, SERVICOS_MODULOS } from './Util/constantes';
import { DashboardOrdemServico } from './components/DashboardOrdemServico';
import { DashboardNFSe } from './components/DashboardNFSe';

const selectButtonStyle = {
	whiteSpace: 'nowrap',
	overflowY: 'hidden',
	width: '100%',
};

function DashboardServicosImpl({ dataInicial, dataFinal, history, isMobile }) {
	const [podeVisualizarOrdemServico] = useState(
		usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.VISUALIZAR)
	);
	const [podeVisualizarNfse] = useState(usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.VISUALIZAR));
	const optionsSelect = OPTIONS_SELECT.filter((e) => {
		if (e.value === SERVICOS_MODULOS.ORDEM_SERVICO && podeVisualizarOrdemServico) {
			return true;
		}
		if (e.value === SERVICOS_MODULOS.NFSE && podeVisualizarNfse) {
			return true;
		}
		return false;
	});

	const [currentOption, setCurrentOption] = useState(optionsSelect[0]?.value);

	function handleChangeOption(event) {
		if (event.value) {
			setCurrentOption(event.value);
		}
	}

	if (dataInicial && dataFinal) {
		return (
			<Grid style={{ margin: '0px', width: '99.8%' }}>
				<SelectButton
					style={selectButtonStyle}
					className="grid-select-filtro select-button"
					options={optionsSelect}
					value={currentOption}
					onChange={handleChangeOption}
				/>
				<If test={currentOption === SERVICOS_MODULOS.ORDEM_SERVICO}>
					<DashboardOrdemServico
						dataInicial={dataInicial}
						dataFinal={dataFinal}
						history={history}
						isMobile={isMobile}
					/>
				</If>
				<If test={currentOption === SERVICOS_MODULOS.NFSE}>
					<DashboardNFSe dataInicial={dataInicial} dataFinal={dataFinal} history={history} isMobile={isMobile} />
				</If>
			</Grid>
		);
	} else {
		return <span>Carregando informações...</span>;
	}
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export const DashboardServicos = connect(mapStateToProps)(withRouter(DashboardServicosImpl));
