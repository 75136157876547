import { useState } from 'react';

import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { permissoes, recursos } from '../../../Common/Constantes/autorizacao';
import MultipleSelect from '../MultipleSelect';

export default function MultiSelectVendedores(props) {
	const { url, noOptionsMessage, label = 'Tabelas de preço' } = props;

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.INSERIR));
	const [podeVisualizar] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.VISUALIZAR));
	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO"&page=${page}&size=50&sort=nome,asc`;
	}

	function montarLabel(row) {
		if (row.filialCodigo) {
			const labelFilial = montarLabelFilial(row);
			return `${labelFilial} - ${row.nome}`;
		}

		return row.nome;
	}

	function montarLabelFilial(row) {
		const filialCodigo = row.filialCodigo ? row.filialCodigo : '';
		const filialNome =
			row.filialNomeApresentacao || row.filialNome ? `${row.filialNomeApresentacao || row.filialNome}` : '';

		return `[${filialCodigo} - ${filialNome}]`;
	}

	return (
		<MultipleSelect
			label={label}
			buscarUrlPesquisa={buscarUrlPesquisa}
			montarLabel={montarLabel}
			noOptionsMessage={noOptionsMessage}
			disabledButton={!podeInserirEVisualizar}
			onChange={props.onChange}
			esconderBotao
			{...props}
		/>
	);
}
