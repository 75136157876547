import { Field } from "formik";
import {
  Grid,
  InputField,
  SingleSelectEstado,
  SingleSelectMunicipio,
} from "../../../..";
import { removerCaracteres } from "Common";

export default function InformacoesExportacao(props) {
  const { informacoesPermissoes, setFieldValue, exportacao } = props;

  // exportacao: {
  //   ufEmbarque: null,
  //   localEmbarque: "",
  //   localExportacao: "",
  // },

  return (
    <>
      <Grid>
        <Field
          sm="12"
          md="6"
          lg="6"
          xl="6"
          component={SingleSelectEstado}
          label="UF de embarque"
          name="ufEmbarque"
          value={exportacao?.ufEmbarque}
          onChange={(event) => setFieldValue("exportacao.ufEmbarque", event)}
          {...informacoesPermissoes}
        />
      </Grid>
      <Grid>
        <Field
          sm="12"
          md="12"
          lg="12"
          xl="6"
          component={InputField}
          label="Local de embarque"
          name="localEmbarque"
          onBlur={() => {
            if (exportacao.localEmbarque) {
              setFieldValue(
                "exportacao.localEmbarque",
                exportacao.localEmbarque.trim()
              );
            }
          }}
          onChange={(e) =>
            setFieldValue(
              "exportacao.localEmbarque",
              removerCaracteres(e.target?.value, ["─"])
            )
          }
          value={exportacao?.localEmbarque}
          size={60}
          {...informacoesPermissoes}
        />
        <Field
          sm="12"
          md="12"
          lg="12"
          xl="6"
          component={InputField}
          label="Local de exportação"
          name="localExportacao"
          onBlur={() => {
            if (exportacao.localExportacao) {
              setFieldValue(
                "exportacao.localExportacao",
                exportacao.localExportacao.trim()
              );
            }
          }}
          onChange={(e) =>
            setFieldValue(
              "exportacao.localExportacao",
              removerCaracteres(e.target?.value, ["─"])
            )
          }
          value={exportacao?.localExportacao}
          size={60}
          {...informacoesPermissoes}
        />
      </Grid>
    </>
  );
}
