import { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import DocumentoConfirmacaoForm from './Form';

import {
	Prompt,
	ButtonExcluirTable,
	ButtonNovo,
	Modal,
	If,
	ButtonEditarTable,
	NenhumRegistroEncontrado,
} from 'components';

import { removerElemento, estadosCadastro } from 'Common';

function DocumentoConfirmacao(props) {
	const { dirty, value, informacoesPermissoes, estadoCadastro, disabled } = props;

	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [modalFormVisible, setModalFormVisible] = useState(false);

	const podeInserir =
		estadoCadastro === estadosCadastro.INCLUSAO
			? informacoesPermissoes.podeInserir
			: informacoesPermissoes.podeEditar;

	function novo() {
		setModalFormVisible(true);
		setRegistroSelecionado(null);
	}

	function onEditar(row) {
		setModalFormVisible(true);
		setRegistroSelecionado(row);
	}

	function renderOpcoes(row) {
		const { informacoesPermissoes, disabled } = props;
		return (
			<div>
				<ButtonEditarTable onClick={() => onEditar(row)} {...informacoesPermissoes} />
				<ButtonExcluirTable
					onClick={() => excluirRegistro(row)}
					disabled={disabled}
					podeExcluir={informacoesPermissoes.podeEditar}
				/>
			</div>
		);
	}

	function hideModal() {
		setModalFormVisible(false);
		setRegistroSelecionado(null);
	}

	function atualizarRegistro(dadosFormulario) {
		const result =
			value &&
			value.map(item => {
				if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
					return dadosFormulario;
				} else if (item.id && item.id === dadosFormulario.id) {
					return dadosFormulario;
				}
				return item;
			});
		setRegistroSelecionado(dadosFormulario);
		props.onChange(result);
	}

	function inserirRegistro(dadosFormulario) {
		props.onChange([...value, dadosFormulario]);
		setRegistroSelecionado(dadosFormulario);
	}

	function excluirRegistro(dadosFormulario) {
		props.onChange(removerElemento(value, dadosFormulario));
		setRegistroSelecionado(null);
	}

	function renderRespostaEsperada(row) {
		if (!row.respostaEsperada) {
			return <p style={{ color: 'red' }}>Nenhum valor informado</p>;
		}

		if (row.respostaTipo === 'SIM_NAO') {
			if (row.respostaEsperada === 'true') {
				return 'Sim';
			} else if (row.respostaEsperada === 'false') {
				return 'Não';
			}
		}

		if (row.respostaTipo === 'DATA' && isValid(new Date(row.respostaEsperada))) {
			return format(new Date(row.respostaEsperada), 'dd/MM/yyyy');
		}

		return row.respostaEsperada;
	}

	return (
		<>
			<Modal
				visible={modalFormVisible}
				header="Cadastro de confirmação para assinatura"
				onHide={() => setModalFormVisible(false)}
			>
				<If test={modalFormVisible}>
					<DocumentoConfirmacaoForm
						registroSelecionado={registroSelecionado}
						onNovoClick={() => setRegistroSelecionado(null)}
						excluirRegistro={e => excluirRegistro(e)}
						inserirRegistro={e => inserirRegistro(e)}
						atualizarRegistro={e => atualizarRegistro(e)}
						onHide={() => hideModal()}
						disabled={disabled}
						informacoesPermissoes={informacoesPermissoes}
					/>
				</If>
			</Modal>

			<Prompt dirty={dirty} />
			<ButtonNovo
				label="Nova confirmação"
				enableShortCut={false}
				onClick={() => novo()}
				disabled={disabled}
				podeInserir={podeInserir}
			/>
			<DataTable
				className="table"
				responsive
				value={value}
				emptyMessage={<NenhumRegistroEncontrado message="Nenhuma confirmação encontrada" />}
			>
				<Column field="pergunta" header="Pergunta para confirmação" sortable={true} />
				<Column
					field="respostaEsperada"
					header="Resposta esperada"
					body={row => renderRespostaEsperada(row)}
					sortable={true}
				/>
				<Column body={row => renderOpcoes(row)} header="Ações" style={{ width: '7em' }} />
			</DataTable>
		</>
	);
}

export default DocumentoConfirmacao;
