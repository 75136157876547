import {
	estadosBotaoCancelar,
	ButtonCancelar,
	ButtonNovo,
	estadosBotaoNovo,
	ButtonExcluir,
	ButtonSalvar,
} from '../../../../../../components';

import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../../../Util';
import { confirmarExclusao } from '../../../../../Util/ExclusaoDeRegistros';
import { asyncCreateNcm, asyncDeleteNcm, asyncUpdateNcm } from '../../../Requests';
import { converterNcmParaApi } from '../../../Util/ncmConverter';
import { jaExisteMesmaNcm } from '../../../Util/funcoes';

export default function ActionButton(props) {
	const {
		values,
		dirty,
		initialValue,
		dadosBase,
		history,
		informacoesPermissoes,
		CADASTROURL,
		PESQUISAURL,
		resetForm,
		setDadosBase,
		handleSubmit,
		setIsNewNcm,
	} = props;

	const { id } = values;

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const onClickNovo = dirty ? (event) => salvar(event, novo) : novo;

	function cancelar() {
		if (dirty) {
			resetForm({
				values: dadosBase?.values,
			});
		} else if (props.isModal) {
			props.hideModal(values);
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
	}

	async function salvar(e, novoOnSuccess) {
		handleSubmit();
		if (await jaExisteMesmaNcm(values)) {
			props.setFieldError('codigoNcm', 'NCM já cadastrada');
		} else {
			if (await validarFormulario(props)) {
				let dadosFormulario = converterNcmParaApi(values);
				if (id) {
					asyncUpdateRegistro(dadosFormulario, novoOnSuccess);
					setDadosBase(values);
					resetForm({ values: values });
				} else {
					setDadosBase(values);
					await asyncCreateRegistro(dadosFormulario, novoOnSuccess);
					if (typeof novoOnSuccess === 'undefined' && !informacoesPermissoes.podeEditar) {
						history.goBack();
					}
				}
			}
		}
	}

	function novo() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: initialValue });
	}

	function excluir() {
		confirmarExclusao(asyncDeleteRegistro);
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteNcm(id, () => {
			resetForm();
			voltarParaAPesquisa(history, PESQUISAURL);
		});
	}

	async function asyncCreateRegistro(dadosFormulario, novoOnSuccess) {
		await asyncCreateNcm(dadosFormulario, async ({ data: ncm }) => {
			setIsNewNcm(true);
			let dados = { ...values, id: ncm.id };
			resetForm({ values: dados });

			if (novoOnSuccess) {
				novoOnSuccess();
			} else if (props.isModal) {
				props.hideModal(dados);
			} else {
				atualizarUrl(history, CADASTROURL, ncm.id, metodosAtualizarUrl.POP);
			}
		});
	}

	async function asyncUpdateRegistro(dadosFormulario, novoOnSuccess) {
		await asyncUpdateNcm(dadosFormulario, () => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				resetForm({ values: values });
			}
		});
	}

	return (
		<>
			<ButtonCancelar onClick={cancelar} estadoBotao={estadoBotaoCancelar} />
			<ButtonSalvar onClick={() => salvar()} disabled={!dirty} />
			<ButtonNovo
				onClick={onClickNovo}
				estadoBotao={estadoBotaoNovo}
				hidden={!id && !dirty}
				disabled={!informacoesPermissoes.podeInserir}
			/>
			<ButtonExcluir onClick={excluir} hidden={!id} disabled={!informacoesPermissoes.podeExcluir} />
		</>
	);
}
