import { isValidCPF, isValidCNPJ } from '@brazilian-utils/brazilian-utils';
import { isValid, parseISO } from 'date-fns';
import { mensagensDeValidacao } from '../../../../../Common/Constantes/mensagens';
import { manterApenasNumeros } from '../../../../../Common/Mascara';
import validaFieldsPorModulo from '../../../../../Common/ValidaFieldsPorModulo';
import { tabsCadastro } from '../../Util/constantes';

export function fieldsValidate(values) {
	let fieldsErrors = {
		DEFAULT: {},
		DOCUMENTOS: {},
		ESTOQUE: {},
		FINANCAS: {},
		VENDAS: {},
	};

	if (values.tipo === 'FISICA') {
		fieldsErrors = {
			DEFAULT: {
				cpf: values.cpf && !isValidCPF(manterApenasNumeros(values.cpf)) ? 'Digite um CPF válido.' : null,
				nascimento:
					values.nascimento && !isValid(parseISO(values.nascimento))
						? mensagensDeValidacao.DATA_INVALIDA
						: null,

				tab: tabsCadastro.PESSOA_FISICA,
			},
			DOCUMENTOS: {},
			ESTOQUE: {},
			FINANCAS: {},
			VENDAS: {
				cpf: !values.cpf ? mensagensDeValidacao.OBRIGATORIO : null,
			},
		};
	} else if (values.tipo === 'JURIDICA') {
		fieldsErrors = {
			DEFAULT: {
				cnpj: values.cnpj && !isValidCNPJ(manterApenasNumeros(values.cnpj)) ? 'Digite um CNPJ válido.' : null,
				razaoSocial: !values.razaoSocial ? mensagensDeValidacao.OBRIGATORIO : null,

				tab: tabsCadastro.PESSOA_JURIDICA,
			},
			DOCUMENTOS: {},
			ESTOQUE: {},
			FINANCAS: {},
			VENDAS: {
				cnpj: !values.cnpj ? mensagensDeValidacao.OBRIGATORIO : null,
			},
		};
	} else {
		fieldsErrors = {
			DEFAULT: {
				tab: tabsCadastro.ESTRANGEIRO,
			},
			DOCUMENTOS: {},
			ESTOQUE: {},
			FINANCAS: {},
			VENDAS: {},
		};
	}

	//Validações genéricas
	if (!values.nome) {
		fieldsErrors.DEFAULT.nome = mensagensDeValidacao.OBRIGATORIO;
	}
	if (!values.indicadorInscricaoEstadual) {
		fieldsErrors.VENDAS.indicadorInscricaoEstadual = mensagensDeValidacao.OBRIGATORIO;
	}
	if (values.indicadorInscricaoEstadual === 'CONTRIBUINTE') {
		fieldsErrors.VENDAS.inscricaoEstadual = !values.inscricaoEstadual ? mensagensDeValidacao.OBRIGATORIO : null;
	}

	let errors = [];
	if (!values.cliente && !values.fornecedor && !values.transportador) {
		errors.push('Selecione pelo menos um tipo de cadastro: Cliente, fornecedor, transportador.');
		fieldsErrors.DEFAULT.cliente = mensagensDeValidacao.OBRIGATORIO;
	}
	if (values.campoObrigatorioParaDocumentoFiscal) {
		if (!values.enderecos.length) {
			errors.push('Atenção: É necessário cadastrar pelo menos um endereço.');
			fieldsErrors.VENDAS.enderecos = mensagensDeValidacao.OBRIGATORIO;
		}
	}
	values.errors = errors;

	return validaFieldsPorModulo(fieldsErrors);
}
