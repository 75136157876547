import { tipoCampos } from 'components';

export const OPTIONS_FILTRO_AVANCADO = [
	{ label: 'Série', name: 'serie', type: tipoCampos.STRING },
	{ label: 'Número', name: 'numero', type: tipoCampos.STRING },
	{ label: 'Chave', name: 'chave', type: tipoCampos.STRING },
	{ label: 'Protocolo', name: 'protocolo', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Não enviado', value: 'NAO_ENVIADO' },
			{ label: 'Aguardando autorização', value: 'AGUARDANDO_AUTORIZACAO' },
			{ label: 'Transmitido', value: 'TRANSMITIDO' },
			{ label: 'Rejeitado', value: 'REJEITADO' },
			{ label: 'Cancelado', value: 'CANCELADO' },
			{ label: 'Encerrado', value: 'ENCERRADO' },
			{ label: 'Denegado', value: 'DENEGADO' },
		],
	},
	{
		label: 'Tipo emitente',
		name: 'tipoEmitente',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Prestador de serviço', value: 'PRESTADOR_SERVICO' },
			{ label: 'Carga própria', value: 'CARGA_PROPRIA' },
		],
	},
	{
		label: 'Tipo transportador',
		name: 'tipoTransportador',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'ETC', value: 'ETC' },
			{ label: 'TAC', value: 'TAC' },
			{ label: 'CTC', value: 'CTC' },
		],
	},
	{
		label: 'Categoria de combinação veicular',
		name: 'categoriaCombinacaoVeicular',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Veículo comercial 2 eixos', value: 'VEICULO_COMERCIAL_2_EIXOS' },
			{ label: 'Veículo comercial 3 eixos', value: 'VEICULO_COMERCIAL_3_EIXOS' },
			{ label: 'Veículo comercial 4 eixos', value: 'VEICULO_COMERCIAL_4_EIXOS' },
			{ label: 'Veículo comercial 5 eixos', value: 'VEICULO_COMERCIAL_5_EIXOS' },
			{ label: 'Veículo comercial 6 eixos', value: 'VEICULO_COMERCIAL_6_EIXOS' },
			{ label: 'Veículo comercial 7 eixos', value: 'VEICULO_COMERCIAL_7_EIXOS' },
			{ label: 'Veículo comercial 8 eixos', value: 'VEICULO_COMERCIAL_8_EIXOS' },
			{ label: 'Veículo comercial 9 eixos', value: 'VEICULO_COMERCIAL_9_EIXOS' },
			{ label: 'Veículo comercial 10 eixos', value: 'VEICULO_COMERCIAL_10_EIXOS' },
			{ label: 'Veículo comercial acima de 10 eixos', value: 'VEICULO_COMERCIAL_ACIMA_10_EIXOS' },
		],
	},
	{ label: 'Data emissão', name: 'emissao', type: tipoCampos.DATE },
	{ label: 'Data transmissão', name: 'dataTransmissao', type: tipoCampos.DATE },
	{ label: 'UF carregamento (sigla)', name: 'ufCarregamentoSigla', type: tipoCampos.STRING },
	{ label: 'UF descarregamento (sigla)', name: 'ufDescarregamentoSigla', type: tipoCampos.STRING },
];

export const Status = {
	REJEITADO: 'REJEITADO',
	TRANSMITIDO: 'TRANSMITIDO',	
	CANCELADO: 'CANCELADO',
	NAO_ENVIADO: 'NAO_ENVIADO',	
	AGUARDANDO_AUTORIZACAO: 'AGUARDANDO_AUTORIZACAO',	
	ENCERRADO: 'ENCERRADO',
};
