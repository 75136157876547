export function converterParaModal(values) {
	const dest = {
		nome: values?.nome,
		nomeFantasia: values?.nomeFantasia,
		cpf: values?.cpf,
		cnpj: values?.cnpj,
		inscricaoEstadual: values?.inscricaoEstadual,
		inscricaoEstadualSubstituto: values?.inscricaoEstadualSubstituto,
		inscricaoMunicipal: values?.inscricaoMunicipal,
		cnae: values?.cnae,
		regimeTributario: values?.regimeTributario,
		cep: values?.cep,
		logradouro: values?.logradouro,
		bairro: values?.bairro,
		numero: values?.numero,
		complemento: values?.complemento,
		telefone: values?.telefone,
		municipio: montarMunicipio(values?.municipio),
	};
	return dest;
}

function montarMunicipio(value) {
	if (value?.nome) {
		let labelMunicipio = value?.nome + ' - ' + (value.estadoSigla ? value.estadoSigla : value.estado?.sigla);
		return {
			...value,
			label: labelMunicipio,
		};
	} else return null;
}
