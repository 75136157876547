import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';

export const SITUACAO_OPTIONS = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];

export const TIPO_OPTIONS = [
	{ label: 'Tração', value: 'TRACAO' },
	{ label: 'Reboque', value: 'REBOQUE' },
];

export const RODADO_OPTIONS = [
	{ label: 'Truck', value: 'TRUCK' },
	{ label: 'Toco', value: 'TOCO' },
	{ label: 'Cavalo mecânico', value: 'CAVALO_MECANICO' },
	{ label: 'Van', value: 'VAN' },
	{ label: 'Utilitário', value: 'UTILITARIO' },
	{ label: 'Outros', value: 'OUTROS' },
];

export const CARROCERIA_OPTIONS = [
	{ label: 'Aberta', value: 'ABERTA' },
	{ label: 'Fechada baú', value: 'FECHADA_BAU' },
	{ label: 'Graneleira', value: 'GRANELEIRA' },
	{ label: 'Porta container', value: 'PORTA_CONTAINER' },
	{ label: 'Sider', value: 'SIDER' },
	{ label: 'Não aplícavel', value: 'NAO_APLICAVEL' },
];

export const OPTIONS_FILTRO_AVANCADO = [
	{ label: 'Placa', name: 'placa', type: tipoCampos.STRING },
	{ label: 'Renavam', name: 'renavam', type: tipoCampos.STRING },
	{ label: 'Tipo', name: 'tipo', type: tipoCampos.STRING },
	{ label: 'Estado', name: 'estado', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: SITUACAO_OPTIONS,
	},
];

export const COLORS = {
	bgAtivo: '#DCEDC8',
	textAtivo: '#1B5E20',
	bgInativo: '#FFCDD2',
	textInativo: '#B71C1C',
};

export const STYLE_BACKGROUND = {
	borderRadius: '20px',
	padding: '0.2rem 1.1rem',
};

export const STYLE_BUTTON_OPTIONS = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

export const INITIAL_VALUES = {
	id: null,
	placa: null,
	renavam: null,
	tipo: 'TRACAO',
	situacao: 'ATIVO',
	tara: 0,
	capacidade: 0,
	condutores: [],
	reboques: [],
	rodado: null,
	carroceria: null,
	proprietario: null,
	rntrc: null,
	uf: null,
};

export const CADASTRO_URL = '/transporte/veiculos/cadastro';

export const PESQUISA_URL = '/transporte/veiculos';
