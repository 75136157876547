import React, { useEffect, useState } from 'react';
import { asyncGetPesquisaOrganizacoes, asyncDeleteOrganizacao } from './Requests';
import { atualizarUrl } from '../../Util';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { connect } from 'react-redux';
import { useEffectOnce } from 'react-use';

import {
  recursos,
  permissoes,
  formatarParaPesquisarParaCamposBoolean,
  removerCaracteres,
  inserirMascara,
  usuarioPossuiPermissao,
  buscarDadosLoginLocalStorage,
  construirUrl,
  services,
  formatos,
} from 'Common';

import {
  ButtonNovo,
  ButtonEditarTable,
  ButtonExcluirTable,
  InputSearch,
  confirm,
  Form,
  FormActions,
  FormContent,
  NenhumRegistroEncontrado,
  Badge,
  Grid,
  Paginacao,
} from 'components';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';

function Organizacoes(props) {
  const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.INSERIR));
  const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.EXCLUIR));
  const {
    valorPesquisa,
    setValorPesquisa,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    rows,
    setRows,
    exibirBloqueadas,
    setExibirBloqueadas,
  } = useContextPesquisa();
  const [registros, setRegistros] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [idOrganizacaoLogada, setIdOrganizacaoLogada] = useState(null);

  useEffectOnce(() => {
    setIdOrganizacaoLogada(buscarDadosLoginLocalStorage().organizacao.id);

    setTimeout(() => {
      document.getElementById('OrganizacoesInputSearch')?.focus();
    }, 500);
  }, []);

  useEffect(() => {
    pesquisar();
  }, [sortField, sortOrder, page, rows]);

  function montarHeader() {
    return (
      <span
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>Organizações</span>
      </span>
    );
  }

  function buscarFiltro() {
    const pesquisaCodigo = removerCaracteres(valorPesquisa.replaceAll('&', ''), ['.', '/', '-']);
    const valorCredencialBloqueada = formatarParaPesquisarParaCamposBoolean(
      valorPesquisa.replaceAll('&', ''),
      'BLOQUEADA',
      'DESBLOQUEADA'
    );

    let filtroRSQL = String('?query=(')
      .concat(`nome=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
      .concat(`cpf=contains="*${pesquisaCodigo}*",`)
      .concat(`cnpj=contains="*${pesquisaCodigo}*",`)
      .concat(`email=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
      .concat(`bloqueada==${valorCredencialBloqueada})`);
    return filtroRSQL;
  }

  function pesquisar() {
    const filtro = buscarFiltro();
    const url = construirUrl(
      `${services.GESTOR}/v1/organizacoes/resumo`,
      filtro,
      rows,
      page,
      sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
    );

    asyncGetPesquisaOrganizacoes(url, ({ data: organizacao }) => {
      setRegistros(organizacao.content);
      setTotalElements(organizacao.totalElements);
    });
  }

  function onPesquisar() {
    pesquisar();
  }

  function onPageChange(e) {
    setPage(e?.page);
    setRows(e?.rows);
  }

  function onSort(e) {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  }

  function onEditar(row) {
    atualizarUrl(props.history, '/organizacoes/cadastro', row.id);
  }

  function onExcluir(row) {
    confirm(
      'Confirmação',
      'Tem certeza que deseja excluir a organização? Este processo não pode ser revertido.',
      () => {
        asyncExcluirRegistro(row);
      }
    );
  }

  function asyncExcluirRegistro(registro) {
    asyncDeleteOrganizacao(registro.id, () => {
      pesquisar();
    });
  }

  function renderOpcoes(row) {
    let mesmaOrganizacao = idOrganizacaoLogada === row.id;

    let msgTitleExcluir = null;

    if (mesmaOrganizacao) {
      msgTitleExcluir = 'Não é possível excluir a própria organização.';
    } else {
      msgTitleExcluir = 'Excluir a organização.';
    }

    return (
      <div>
        <ButtonEditarTable onClick={() => onEditar(row)} />
        <ButtonExcluirTable
          title={msgTitleExcluir}
          disabled={mesmaOrganizacao}
          podeExcluir={podeExcluir}
          onClick={() => onExcluir(row)}
        />
      </div>
    );
  }

  function renderCpfCnpj(row) {
    const cpfCnpj = row.cpf ? inserirMascara(row.cpf, formatos.CPF) : inserirMascara(row.cnpj, formatos.CNPJ);

    return (
      <span
        title={cpfCnpj}
        style={{
          wordBreak: 'break-word',
          maxWidth: props.isMobile ? '70%' : '100%',
          textAlign: props.isMobile ? 'end' : 'start',
        }}
      >
        {cpfCnpj}
      </span>
    );
  }

  function renderEmail(row) {
    return (
      <span
        title={row.email}
        style={{
          wordBreak: 'break-word',
          maxWidth: props.isMobile ? '70%' : '100%',
          textAlign: props.isMobile ? 'end' : 'start',
        }}
      >
        {row.email}
      </span>
    );
  }

  function renderStatus(row) {
    const colors = {
      txtBloqueado: '#da1f1f',
      bgBloqueado: '#ffcdd2',
      txtDesbloqueado: 'green',
      bgDesbloqueado: '#dcedc8',
    };
    const backgroundColor = {
      padding: row.bloqueada ? '0.2rem 1.5rem' : '0.2rem 0.8rem',
      borderRadius: '20px',
    };

    return row.bloqueada
      ? Badge(colors.txtBloqueado, colors.bgBloqueado, 'Bloqueado', backgroundColor)
      : Badge(colors.txtDesbloqueado, colors.bgDesbloqueado, 'Desbloqueado', backgroundColor);
  }

  function renderNome(row) {
    return (
      <span title={row.nome} style={{ wordBreak: 'break-all', maxWidth: props.isMobile ? '70%' : '100%' }}>
        {row.nome}
      </span>
    );
  }

  const registrosFiltrados = registros.filter(registro => (exibirBloqueadas ? true : !registro.bloqueada));

  return (
    <>
      <Form header={montarHeader()}>
        <FormActions>
          <ButtonNovo
            label="Nova organização"
            onClick={() => props.history.push('/organizacoes/cadastro')}
            podeInserir={podeInserir}
          />
        </FormActions>
        <FormContent>
          <Grid justifyCenter>
            <InputSearch
              onPesquisar={() => onPesquisar()}
              value={valorPesquisa}
              onChange={value => setValorPesquisa(value)}
              removerEComercial={false}
              id="OrganizacoesInputSearch"
            />
          </Grid>
          <DataTable
            className="table"
            responsive
            value={registrosFiltrados}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            emptyMessage={<NenhumRegistroEncontrado />}
            header={
              <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Checkbox
                  name="exibirBloqueadas"
                  inputId="exibirBloqueadas"
                  title="Marque esta opção caso queira ver as organizações bloqueadas"
                  onChange={() => setExibirBloqueadas(!exibirBloqueadas)}
                  checked={exibirBloqueadas}
                />
                <label htmlFor="exibirBloqueadas" className="p-checkbox-label">
                  Exibir bloqueadas
                </label>
              </span>
            }
          >
            <Column
              field="nome"
              header="Nome"
              sortable={true}
              body={row => renderNome(row)}
              style={{ overflow: 'hidden' }}
            />
            <Column field="cpf" header="CPF/CNPJ" body={row => renderCpfCnpj(row)} sortable={true} />
            <Column
              field="email"
              header="E-mail"
              sortable={true}
              body={row => renderEmail(row)}
              style={{ overflow: 'hidden' }}
            />
            <Column field="bloqueada" header="Status" body={row => renderStatus(row)} style={{ width: '11rem' }} />
            <Column body={row => renderOpcoes(row)} header="Ações" style={{ width: '7rem' }} />
          </DataTable>
        </FormContent>
        <Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={e => onPageChange(e)} />
      </Form>
    </>
  );
}

const mapStateToProps = state => ({
  isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Organizacoes);
