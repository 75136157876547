import {
	buscarDadosLoginLocalStorage,
	formatarTelefone,
	mensagensDeValidacao,
	replaceCaracteresEspeciais,
} from 'Common';
import { Button, DateInterval, Grid, Modal, NenhumRegistroEncontrado } from 'components';
import { format, parse } from 'date-fns';
import { useFormikContext, withFormik } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { validarFormulario } from 'views/Util';
import { getAniversariantes } from '../../Requests';

function ModalAniversariantesView({ visible, onHide, isMobile }) {
	const { values, validateForm, setFieldValue, handleSubmit } = useFormikContext();
	const [aniversariantes, setAniversariantes] = useState(null);

	const localStorage = buscarDadosLoginLocalStorage();

	useEffectOnce(() => {
		consultarAniversariantes();
	});

	useEffect(() => {
		consultarAniversariantes();
	}, [values.dataInicial, values.dataFinal]);

	async function consultarAniversariantes() {
		handleSubmit();

		if (await validarFormulario({ values: values, validateForm })) {
			try {
				getAniversariantes(values.dataInicial, values.dataFinal, (response) => {
					setAniversariantes(response.data);
				});
			} catch (error) {
				console.error(error);
			}
		}
	}

	function renderNome(nome) {
		return (
			<span
				title={nome}
				style={{
					overflow: 'hidden',
					display: 'inline-flex',
					wordBreak: 'break-word',
					maxWidth: isMobile ? '70%' : '100%',
					textAlign: isMobile ? 'end' : 'start',
				}}
			>
				{nome}
			</span>
		);
	}

	function renderEmail(email) {
		return (
			<span
				title={email}
				style={{
					overflow: 'hidden',
					wordBreak: 'break-word',
					maxWidth: isMobile ? '70%' : '100%',
					textAlign: isMobile ? 'end' : 'start',
				}}
			>
				{email || '-'}
			</span>
		);
	}

	function renderNascimento(nascimento) {
		return (
			<span
				title={nascimento}
				style={{
					overflow: 'hidden',
					wordBreak: 'break-word',
					maxWidth: isMobile ? '70%' : '100%',
					textAlign: isMobile ? 'end' : 'start',
				}}
			>
				{format(parse(nascimento, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') || '-'}
			</span>
		);
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex', gap: '0.5rem' }}>
				<Button
					icon="pi pi-whatsapp"
					color="success"
					rounded
					disabled={!row.telefone}
					onClick={() => enviarWhatsapp(row)}
				/>
				<Button icon="pi pi-envelope" color="info" rounded disabled={!row.email} onClick={() => enviarEmail(row)} />
			</div>
		);
	}

	function enviarWhatsapp(row) {
		const texto = `Feliz Aniversário ${row.nome}!
		%0D%0AEm nome da ${localStorage?.filialConectada?.razaoSocial}, desejamos a você um feliz aniversário, repleto de boas surpresas e muitas felicidades.`;

		window.open(`https://api.whatsapp.com/send?phone=${55 + row.telefone}&text=${replaceCaracteresEspeciais(texto)}`);
	}

	function enviarEmail(row) {
		const texto = `Feliz Aniversário ${row.nome}!
		%0D%0AEm nome da ${localStorage?.filialConectada?.razaoSocial}, desejamos a você um feliz aniversário, repleto de boas surpresas e muitas felicidades.`;

		window.location.href = `mailto:${row.email}?subject=Feliz Aniversário&body=${replaceCaracteresEspeciais(texto)}`;
	}

	function onChangePeriodo(dataInicial, dataFinal) {
		setFieldValue('dataInicial', format(dataInicial, 'yyyy-MM-dd'));
		setFieldValue('dataFinal', format(dataFinal, 'yyyy-MM-dd'));
	}

	return (
		<Modal
			visible={visible}
			onHide={onHide}
			header="Aniversariantes"
			styleModal={{ maxHeight: '700px' }}
			closeOnOverlayClick
		>
			<div style={{ display: 'flex', flexDirection: 'column', padding: '10px 0', margin: '0' }}>
				<Grid justifyCenter style={{ padding: '0px', margin: '0px' }}>
					<DateInterval onChange={(interval) => onChangePeriodo(interval.dataInicial, interval.dataFinal)} />
				</Grid>
				<DataTable
					className="table"
					responsive
					value={aniversariantes}
					emptyMessage={<NenhumRegistroEncontrado />}
					style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: '500px' }}
				>
					<Column
						field="nascimento"
						header="Data de nascimento"
						sortable
						body={(row) => renderNascimento(row.nascimento)}
						style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '8rem' }}
					/>
					<Column
						field="nome"
						header="Nome"
						sortable
						body={(row) => renderNome(row.nome)}
						style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '20rem' }}
					/>
					<Column
						field="telefone"
						header="Telefone"
						sortable
						body={(row) => (row.telefone ? formatarTelefone(row.telefone) : '-')}
						style={{ width: '10rem' }}
					/>
					<Column
						field="email"
						header="Email"
						sortable
						body={(row) => renderEmail(row.email)}
						style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '12rem' }}
					/>
					<Column
						className="step-listagem-acoes"
						body={(row) => renderOpcoes(row)}
						header="Ações"
						style={{ width: '7em' }}
					/>
				</DataTable>
			</div>
		</Modal>
	);
}

const ModalAniversariantes = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues() {
		return {
			dataInicial: format(new Date(), 'yyyy-MM-dd'),
			dataFinal: format(new Date(), 'yyyy-MM-dd'),
		};
	},

	validate(values) {
		const errors = {};

		if (!values.dataInicial) {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataFinal) {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.dataFinal && values.dataFinal < values.dataInicial) {
			errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalAniversariantesView);

export { ModalAniversariantes };
