import {
	buscarTitleCampoDeAcordoComAsPermissoes,
	buscarDisabledDeAcordoComAsPermissoes,
} from '../../../../Common/Autorizacao/ManipulacaoDeComponentes';
import { SEMPERMISSAO } from '../../../../Common/Constantes/autorizacao';

export function getColStyle({ colStyle, hidden }) {
	if (hidden) {
		return {
			display: 'none',
			...colStyle,
		};
	}
	return colStyle;
}

export function buscarTitle({ podeVisualizar, podeInserir, podeEditar, estadoCadastro, title }) {
	let titleDefault = '';

	if (buscarDisabled({ podeVisualizar, podeInserir, podeEditar, estadoCadastro, title })) titleDefault = '';

	return buscarTitleCampoDeAcordoComAsPermissoes(podeVisualizar, podeInserir, podeEditar, estadoCadastro, title || titleDefault);
}

export function buscarDisabled({ podeVisualizar, podeInserir, podeEditar, estadoCadastro, disabled }) {
	if (!podeVisualizar) return true;

	return buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled);
}

export function buscarPlaceholder(podeVisualizar, placeholder) {
	if (!podeVisualizar) return SEMPERMISSAO;

	return placeholder || 'Selecione';
}

export function buscarTitleBotao(numeroDeModaisAbertos, { disabledButton, titleBotaoNovo }) {
	if (!disabledButton) {
		return 'Você não possui permissão para Inserir';
	} else if (numeroDeModaisAbertos > 1) {
		return 'Não é possível abrir mais de duas janelas.';
	} else {
		return titleBotaoNovo;
	}
}

export function buscarDisableBotao(desabilitarSelect, numeroDeModaisAbertos, { disabledButton }) {
	if (!disabledButton || desabilitarSelect || numeroDeModaisAbertos > 1) return true;
}
