import { copiarObjeto } from 'Common';
import { FormContent, Grid, InputSelectPercentualOrValor } from 'components';
import { Field, useFormikContext } from 'formik';
import { useEffect } from 'react';

function FormInputs() {
	const { values, setFieldValue } = useFormikContext();

	useEffect(() => {
		calcularTotalRecebido(values.valorJuros, values.valorMulta, values.valorDesconto);
	}, [values.valorJuros, values.valorMulta, values.valorDesconto, values.valorTitulos]);

	function calcularTotalRecebido(juros, multa, desconto) {
		const titulos = copiarObjeto(values.valorTitulos);
		const total =
			parseFloat(titulos.toFixed(2)) +
			parseFloat(juros.toFixed(2)) +
			parseFloat(multa.toFixed(2)) -
			parseFloat(desconto.toFixed(2));
		setFieldValue('valorTitulosNovos', parseFloat(total.toFixed(2)));
		setFieldValue('valor', parseFloat(total.toFixed(2)));
	}

	function handleChangeJuros(tipo, value, percentual) {
		setFieldValue('valorJuros', tipo === 'VALOR' ? value : percentual);
	}

	function handleChangeMulta(tipo, value, percentual) {
		setFieldValue('valorMulta', tipo === 'VALOR' ? value : percentual);
	}

	function handleChangeDesconto(tipo, value, percentual) {
		setFieldValue('valorDesconto', tipo === 'VALOR' ? value : percentual);
	}

	return (
		<FormContent>
			<Grid>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputSelectPercentualOrValor}
					label="Juros"
					name="valorJuros"
					value={values.valorJuros}
					onChange={handleChangeJuros}
					// {...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputSelectPercentualOrValor}
					label="Multa"
					name="valorMulta"
					value={values.valorMulta}
					onChange={handleChangeMulta}
					// {...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputSelectPercentualOrValor}
					label="Desconto"
					name="valorDesconto"
					value={values.valorDesconto}
					onChange={handleChangeDesconto}
					// {...informacoesPermissoes}
				/>
			</Grid>
		</FormContent>
	);
}

export default FormInputs;
