const detalhesCardStyle = {
  color: '#7b7b7b',
  fontWeight: 'bold',
  padding: '16px 0',
};

function DadosConfirmacaoForm(props) {
  const { numero, expiracao, titular } = props.informacoesCartao;
  return (
    <>
      <p style={{ paddingTop: '8px', fontSize: '16px', color: '#6c757d' }}>Confirme seus dados</p>
      <div style={{ display: 'grid', gridTemplateColumns: '10.5rem 10.5rem' }}>
        <div style={{ ...detalhesCardStyle }}>
          <p>Número do cartão:</p>
          <p>Titular do cartão:</p>
          <p>Validade do cartão:</p>
        </div>
        <div style={{ ...detalhesCardStyle }}>
          <p>{numero}</p>
          <p>{titular}</p>
          <p>{expiracao}</p>
        </div>
      </div>
    </>
  );
}

export default DadosConfirmacaoForm;
