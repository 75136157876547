import { services } from '../../../../Common/Constantes/api';
import { del, get, post, put, exibirToast } from '../../../../Common/Requisicoes';

export async function asyncGetTributacoesFederais(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetTributacaoFederal(idTributacao, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/tributacoes/federal/${idTributacao}`, null, onSuccess, onError);
}

export async function asyncCreateTributacaoFederal(tributacao, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/tributacoes/federal`,
		tributacao,
		null,
		exibirToast(onSuccess, 'Tributação criada'),
		onError
	);
}

export async function asyncUpdateTributacaoFederal(tributacao, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/tributacoes/federal/${tributacao.id}`,
		tributacao,
		null,
		exibirToast(onSuccess, 'Tributação atualizada'),
		onError
	);
}

export async function asyncDeleteTributacaoFederal(idTributacao, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/tributacoes/federal/${idTributacao}`,
		null,
		exibirToast(onSuccess, 'Tributação excluída'),
		onError
	);
}
