import { Operacao } from './constantes';

export function montarMensagemToast(operacao) {
	if (operacao === Operacao.EDITAR) {
		return 'Nota fiscal alterada com sucesso';
	} else if (operacao === Operacao.EXCLUIR) {
		return 'Nota fiscal excluída com sucesso';
	} else if (operacao === Operacao.INSERIR) {
		return 'Nota fiscal incluída com sucesso';
	}
}
