export const INITIAL_VALUES = {
	codigo: 1,
	nome: null,
	nomeApresentacao: null,
	razaoSocial: null,
	situacao: { value: 'ATIVA', label: 'Ativa' },
	tipo: { value: 'JURIDICA', label: 'Juridica' },
	cpf: null,
	cnpj: null,
	email: null,
	telefone: null,
	inscricaoEstadual: null,
	ieSubstitutoTributario: null,
	inscricaoMunicipal: null,
	cnaeFiscal: null,
	regimeTributario: { label: 'Simples nacional', value: 'SIMPLES_NACIONAL' },
	suframa: null,
	contabilidade: null,
	cep: null,
	logradouro: null,
	numero: null,
	bairro: null,
	complemento: null,
	pais: null,
	municipio: null,
};
