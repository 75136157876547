import axios from 'axios';
import { store } from '../../index';
import { solicitarLoginDoUsuario } from '../Autenticacao';
import { tratarErroRequisicoes } from '../TratamentoDeErro/tratamentoDeMensagens';
import { notify, ToastTypes } from '../../components/Toast';
import { dispatchAction } from '../Redux';
import { actionTypes } from '../Constantes/reduxTypes';

export async function get(
  url,
  config,
  onSuccess,
  onError,
  exibirLoading = true,
  tratarErros = true,
  titleError = null
) {
  dispatchRequest(exibirLoading);
  return await axios
    .get(url, config)
    .then(e => resolveSuccess(e, onSuccess, exibirLoading))
    .catch(e => {
      if (tokenExpirou(e.response)) {
        solicitarLoginDoUsuario(() => {
          get(url, config, onSuccess, onError, exibirLoading);
        });
        store.dispatch(asyncDispatchError);
      } else {
        resolveError(e, onError, exibirLoading, tratarErros, config, titleError);
      }
    });
}

export async function post(
  url,
  payload,
  config,
  onSuccess,
  onError,
  exibirLoading = true,
  tratarErros = true,
  titleError = null
) {
  dispatchRequest(exibirLoading);
  return await axios
    .post(url, payload, config)
    .then(e => resolveSuccess(e, onSuccess, exibirLoading))
    .catch(e => resolveError(e, onError, exibirLoading, tratarErros, config, titleError));
}

export async function put(
  url,
  payload,
  config,
  onSuccess,
  onError,
  exibirLoading = true,
  tratarErros = true,
  titleError = null
) {
  dispatchRequest(exibirLoading);
  return await axios
    .put(url, payload, config)
    .then(e => resolveSuccess(e, onSuccess, exibirLoading))
    .catch(e => resolveError(e, onError, exibirLoading, tratarErros, config, titleError));
}

export async function patch(
  url,
  payload,
  config,
  onSuccess,
  onError,
  exibirLoading = true,
  tratarErros = true,
  titleError = null
) {
  dispatchRequest(exibirLoading);
  return await axios
    .patch(url, payload, config)
    .then(e => resolveSuccess(e, onSuccess, exibirLoading))
    .catch(e => resolveError(e, onError, exibirLoading, tratarErros, config, titleError));
}

export async function del(
  url,
  config,
  onSuccess,
  onError,
  exibirLoading = true,
  tratarErros = true,
  titleError = null
) {
  dispatchRequest(exibirLoading);
  return await axios
    .delete(url, config)
    .then(e => resolveSuccess(e, onSuccess, exibirLoading))
    .catch(e => resolveError(e, onError, exibirLoading, tratarErros, config, titleError));
}

export function exibirToast(callback, message, toastType = ToastTypes.SUCCESS, wait = 5) {
  return e => {
    notify(message, toastType, wait);
    callback && callback(e);
  };
}

export function exibirToastError(callback, message, toastType = ToastTypes.ERROR, wait = 5) {
  return e => {
    notify(message, toastType, wait);
    callback && callback(e);
  };
}

function tokenExpirou(response) {
  return response && response.status && response.status === 401;
}

const asyncDispatchRequest = async function (dispatch) {
  dispatch({ type: 'EXIBIR_LOADING' });
};

const asyncDispatchSuccess = async function (dispatch) {
  dispatch({ type: 'OCULTAR_LOADING' });
};

const asyncDispatchError = async function (dispatch) {
  dispatch({ type: 'OCULTAR_LOADING' });
};

function dispatchRequest(exibirLoading) {
  if (exibirLoading) store.dispatch(asyncDispatchRequest);
}

function resolveSuccess(e, onSuccess, exibirLoading) {
  dispatchAction(actionTypes.POSSUI_INTERNET, true);
  if (exibirLoading) store.dispatch(asyncDispatchSuccess);
  onSuccess && onSuccess(e);
}

function resolveError(e, onError, exibirLoading, tratarErros, config, titleError) {
  if (exibirLoading) {
    store.dispatch(asyncDispatchError);
  }
  if (tratarErros) {
    if (config && config.responseType && config.responseType === 'arraybuffer') {
      const response = JSON.parse(new TextDecoder('utf-8').decode(new Uint8Array(e.response.data)));
      tratarErroRequisicoes(
        {
          data: response,
          status: response.statusCode,
        },
        titleError
      );
    } else {
      tratarErroRequisicoes(e.response, titleError);
    }
  }
  onError && onError(e);
}
