import { isValid, parseISO } from 'date-fns';
import format from 'date-fns/format';
import { Field, withFormik } from 'formik';
import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
	Col,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputDate,
	InputField,
	Prompt,
	ReactSteps,
	SingleSelectPessoa,
	ToastTypes,
	Tutorial,
	alert,
	confirm,
	notify,
	tutorialStepsDocumentos,
} from 'components';

import {
	DADOSLOCALSTORAGE,
	baixarArquivo,
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	buscarItemLocalStorage,
	configuracoesUsuario,
	copiarObjeto,
	estadosCadastro,
	gerarUUID,
	mensagensDeValidacao,
	permissoes,
	recursos,
	removerCaracteres,
	removerCaracteresInvalidosRsql,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';

import {
	asyncArquivarDocumento,
	asyncBaixarDocumentoComAssinaturas,
	asyncBaixarDocumentoOriginal,
	asyncBuscarResponsavel,
	asyncDesarquivarDocumento,
	asyncGetDocumento,
	asyncRegerarBaixarDocumentoComAssinaturas,
	getImportarPedidoVenda,
	getImpressaoPedidoVenda,
} from '../Requests';

import { atualizarUrl, metodosAtualizarUrl } from '../../../Util';
import { styleArquivado } from '../../Util/constantes';
import { statusDocumento } from '../Util/constantes';
import { ASSINATURATIPO, CADASTROURL, buscarEtapas, helpMessage, initialValue } from './Util/constantes';
import {
	converterConfirmacoesPadraoParaFormulario,
	converterDocumentoParaFormulario,
	converterPedidoParaAssinatura,
	converterSetorParaFormulario,
	converterSignatariosPadraoParaFormulario,
	converterUsuarioParaFormulario,
} from './Util/documentoConverter';
import { buscarEmailFavorito, buscarTelefoneFavorito } from './Util/functions';

import { ActionButtons } from './components/ActionButtons';
import DocumentoHistorico from './components/DocumentoHistorico';
import ExibirFeedbackCliente from './components/ExibirFeedbackCliente';
import SecaoArquivoAdicionado from './components/SecaoArquivoAdicionado';
import SecaoUploadArquivo from './components/SecaoUploadArquivo';
import { TabsAssinaturaEletronica } from './components/Tabs';
import ModalEnviarParaAssinatura from './modal/ModalEnviarParaAssinatura';
import ModalVerDetalhesDocumento from './modal/ModalVerDetalhesDocumento';

function AssinaturaEletronicaFormView(props) {
	const {
		dirty,
		isModal,
		isMobile,
		isTablet,
		errors,
		idDocumento,
		match,
		values,
		history,
		resetForm,
		initialValues,
		hideModal,
		setFieldValue,
		setValues,
	} = props;

	const menuOpcoes = useRef(null);

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.DOCUMENTOS_ASSINATURA, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.DOCUMENTOS_ASSINATURA, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.DOCUMENTOS_ASSINATURA, permissoes.EXCLUIR));
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_DOCUMENTO));
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [tabSelecionada, setTabSelecionada] = useState(0);
	const [modalHistoricoVisible, setModalHistoricoVisible] = useState(false);
	const [modalEnviarDocumentoVisible, setModalEnviarDocumentoVisible] = useState(false);
	const [modalVerDetalhesDocumentoVisible, setModalVerDetalhesDocumentoVisible] = useState(false);
	const [signatarioEspecifico, setSignatarioEspecifico] = useState(null);
	const [modalReenviarEmail, setModalReenviarEmail] = useState(false);

	const estadoCadastro = buscarEstadoCadastro();

	const informacoesPermissoes = {
		estadoCadastro: estadoCadastro,
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	const itensOpcoes = buscaritensOpcoes();
	const statusFinaisDeDocumentos = [
		statusDocumento.AGUARDANDO_ASSINATURAS,
		statusDocumento.ASSINADO,
		statusDocumento.REJEITADO,
		statusDocumento.EXPIRADO,
	];
	const desabilitarCamposDocumentoEnviadoOuExpirado = statusFinaisDeDocumentos.includes(values.status);

	const statusFinaisDataExpiracao = [statusDocumento.ASSINADO, statusDocumento.REJEITADO];
	const desabilitarDataExpiracao = statusFinaisDataExpiracao.includes(values.status);

	useEffect(() => {
		const uuid = isModal ? idDocumento : match?.params.id;
		const isImportarPedido = match?.path === '/documentos/assinatura/importar_pedido/:id';

		if (!isImportarPedido) {
			if (validarUUID(uuid)) {
				asyncSelectRegistro(uuid);
			} else {
				novo();
			}
		} else if (isImportarPedido) {
			handleImportarPedido(uuid);
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_DOCUMENTO, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('AssinaturaInputFieldId')?.focus();
		}, 500);
	}, []);

	function buscarEstadoCadastro() {
		return values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	async function novo() {
		const { usuario, setor } = await asyncBuscarResponsavel();
		const { parametrosDocumentos } = buscarDadosLoginLocalStorage().filialConectada;

		if (!isModal) {
			atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		}

		resetForm({
			values: {
				...initialValue,
				usuario: converterUsuarioParaFormulario(usuario),
				setor: converterSetorParaFormulario(setor),
				signatarios: parametrosDocumentos?.signatarios
					? converterSignatariosPadraoParaFormulario(parametrosDocumentos?.signatarios)
					: [],
				confirmacoes: parametrosDocumentos?.confirmacoes
					? converterConfirmacoesPadraoParaFormulario(parametrosDocumentos?.confirmacoes)
					: [],
			},
		});
		setSignatarioEspecifico(null);
	}

	async function handleImportarPedido(idPedido) {
		const { usuario, setor } = await asyncBuscarResponsavel();
		const { parametrosDocumentos } = buscarDadosLoginLocalStorage().filialConectada;
		const idOrganizacao = buscarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin).organizacao.id;
		let valoresPedido = copiarObjeto(initialValue);

		await getImportarPedidoVenda(idPedido, (document) => {
			const pedidoConvertido = converterPedidoParaAssinatura(document.data);

			valoresPedido = {
				...initialValue,
				...pedidoConvertido,
				usuario: converterUsuarioParaFormulario(usuario),
				setor: converterSetorParaFormulario(setor),
				signatarios: pedidoConvertido?.signatarios
					? [
							...pedidoConvertido.signatarios,
							...converterSignatariosPadraoParaFormulario(parametrosDocumentos.signatarios),
						]
					: pedidoConvertido.signatarios,
				confirmacoes: parametrosDocumentos?.confirmacoes
					? converterConfirmacoesPadraoParaFormulario(parametrosDocumentos?.confirmacoes)
					: [],
				pedidoVendaId: idPedido,
			};
		});
		getImpressaoPedidoVenda(idPedido, idOrganizacao, ({ data: pdf }) => {
			const fileName = `pedido_venda.pdf`;
			const arquivo = new File([pdf], fileName, { type: 'application/pdf' });
			valoresPedido = {
				...valoresPedido,
				arquivo: arquivo,
			};
		}).then(() => {
			setValues({
				...valoresPedido,
			});
			atualizarUrl(history, '/documentos/assinatura/cadastro', null, metodosAtualizarUrl.POP);
		});
	}

	async function asyncSelectRegistro(idDocumento, onSuccess, manterDadosArquivo) {
		asyncGetDocumento(
			idDocumento,
			async (document) => {
				if (manterDadosArquivo) {
					resetForm({
						values: {
							...converterDocumentoParaFormulario(document.data),
							arquivo: values.arquivo,
						},
					});
				} else {
					resetForm({
						values: converterDocumentoParaFormulario(document.data),
					});
					if (isModal) {
						hideModal({ ...values });
					}
				}
				if (!isModal) {
					atualizarUrl(history, CADASTROURL, document.data.id, metodosAtualizarUrl.POP);
				}
				onSuccess && onSuccess(document);
			},
			() => {
				novo();
			}
		);
	}

	function arquivarDocumento() {
		if (dirty) {
			alert('Atenção', 'Salve o documento para arquivá-lo');
			return;
		}

		confirm(
			'Tem certeza que deseja arquivar este documento?',
			'Um documento arquivado não aparecerá mais na listagem, para visualizá-los marque o item "Exibir documentos arquivados" na listagem.',
			() => {
				asyncArquivarDocumento(values.id, () => {
					resetForm({
						values: { ...values, arquivado: true },
					});
				});
			}
		);
	}

	function desarquivarDocumento() {
		if (dirty) {
			alert('Atenção', 'Salve o documento para arquivá-lo');
			return;
		}

		confirm('Confirmação', 'Tem certeza que deseja desarquivar este documento?', () => {
			asyncDesarquivarDocumento(values.id, () => {
				resetForm({
					values: { ...values, arquivado: false },
				});
			});
		});
	}

	function baixarDocumento() {
		if (
			initialValues.arquivo &&
			initialValues.arquivo.name === values.arquivo.name &&
			initialValues.arquivo.size === values.arquivo.size
		) {
			asyncBaixarDocumentoOriginal(values.id, ({ data: file }) => {
				baixarArquivo(file, values.arquivo.name);
			});
		} else {
			baixarArquivo(values.arquivo, values.arquivo.name);
		}
	}

	function baixarDocumentoAssinado() {
		asyncBaixarDocumentoComAssinaturas(values.id, ({ data: file }) => {
			baixarArquivo(file, `${values.arquivo.name.split('.pdf')[0]} - Assinado.pdf`);
		}).catch(() => {
			regerarbaixarDocumentoAssinado();
		});
	}

	function regerarbaixarDocumentoAssinado() {
		asyncRegerarBaixarDocumentoComAssinaturas(values.id, ({ data: file }) => {
			baixarArquivo(file, `${values.arquivo.name.split('.pdf')[0]} - Assinado.pdf`);
		});
	}

	function buscaritensOpcoes() {
		const res = [];

		if (values.id) {
			const historico = {
				label: 'Histórico',
				icon: 'fa fa-history',
				command: () => setModalHistoricoVisible(true),
			};
			res.push(historico);

			if (values.arquivo) {
				const documentoOriginal = {
					label: 'Visualizar arquivo original',
					icon: 'fa fa-download',
					command: () => baixarDocumento(),
				};
				res.push(documentoOriginal);
			}

			if (values.status === statusDocumento.ASSINADO) {
				const documentoAssinado = {
					label: 'Visualizar arquivo assinado',
					icon: 'fa fa-download',
					command: () => baixarDocumentoAssinado(),
				};
				res.push(documentoAssinado);
			}

			if (values.arquivado === false && podeEditar) {
				const arquivar = {
					label: 'Arquivar',
					icon: 'fa fa-archive',
					command: () => arquivarDocumento(),
				};
				res.push(arquivar);
			}
			if (values.arquivado && podeEditar) {
				const arquivar = {
					label: 'Desarquivar',
					icon: 'fa fa-archive',
					command: () => desarquivarDocumento(),
				};
				res.push(arquivar);
			}
		}
		return res;
	}

	async function onChangePessoa(e) {
		const pessoaAntiga = values.pessoa;
		setFieldValue('pessoa', e);

		if (values.status === statusDocumento.PENDENTE || values.status === statusDocumento.ARQUIVO_ADICIONADO) {
			const novosSignatarios = values.signatarios.filter((signatario) => {
				if (!pessoaAntiga || !signatario.pessoa) {
					return true;
				}
				return signatario.pessoa.value !== (pessoaAntiga && pessoaAntiga.value);
			});
			if (e) {
				const novoSignatario = {
					idTemporario: gerarUUID(),
					pessoa: e,
					email: (await buscarEmailFavorito(e.registro?.emails)?.email) ?? null,
					telefone: (await buscarTelefoneFavorito(e.registro?.telefones)?.numero) ?? null,
					nome: e.registro?.nome ?? e.label,
					papel: 'PARTE',
				};

				if (novoSignatario.email && !hasEmailSignatarios(values.signatarios, novoSignatario.email)) {
					await novosSignatarios.push(novoSignatario);
				} else if (!novoSignatario.email) {
					notify('Pessoa sem e-mail cadastrado', ToastTypes.ERROR);
				} else if (hasEmailSignatarios(values.signatarios, novoSignatario.email)) {
					notify('Email da pessoa já consta nos signatários', ToastTypes.ERROR, 8);
				}
			}
			pessoaAntiga !== e ? setFieldValue('signatarios', novosSignatarios) : null;
		}
	}

	function hasEmailSignatarios(signatarios, newEmail) {
		let result = false;
		signatarios.forEach((signatario) => {
			if (signatario?.email === newEmail) {
				result = true;
			}
		});
		return result;
	}

	function onDocumentoEnviadoTodosSignatarios() {
		setSignatarioEspecifico(null);
		asyncSelectRegistro(values.id);
	}

	function buscarNumeroEtapa() {
		const { status } = values;

		switch (status) {
			case statusDocumento.PENDENTE:
				return 0;
			case statusDocumento.ARQUIVO_ADICIONADO:
				return 1;
			case statusDocumento.AGUARDANDO_ASSINATURAS:
				return 2;
			default:
				return 3;
		}
	}

	function buscarStatusEtapa() {
		const { status } = values;
		if (status === statusDocumento.REJEITADO || status === statusDocumento.EXPIRADO) {
			return 'error';
		}
		if (status === statusDocumento.ASSINADO) {
			return 'finish';
		}
		return 'process';
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsDocumentos}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Prompt dirty={dirty} isModal={isModal} />
			<Menu model={itensOpcoes} style={{ minWidth: '230px' }} popup ref={menuOpcoes} />
			<Form
				header={`Cadastro de documento${values.arquivado ? ' (Arquivado)' : ''}`}
				isModal={isModal}
				className="card-default screen-max-width"
				style={values.arquivado ? styleArquivado : null}
			>
				<FormActions className="screen-max-width">
					<ActionButtons
						informacoesPermissoes={informacoesPermissoes}
						initialValues={initialValues}
						isModal={isModal}
						hideModal={hideModal}
						history={history}
						novo={novo}
						asyncSelectRegistro={asyncSelectRegistro}
						itensOpcoes={itensOpcoes}
						menuOpcoes={menuOpcoes}
						setTabSelecionada={setTabSelecionada}
						setModalEnviarDocumentoVisible={setModalEnviarDocumentoVisible}
						setModalReenviarEmail={setModalReenviarEmail}
						setSignatarioEspecifico={setSignatarioEspecifico}
						{...props}
					/>
				</FormActions>
				<FormContent>
					<ReactSteps
						className="step-documento-status"
						etapas={buscarEtapas(values.status)}
						current={buscarNumeroEtapa()}
						status={buscarStatusEtapa()}
					/>
					<Grid nestedGrid>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputField}
							label="Identificação do documento"
							onBlur={() =>
								dirty && values.identificacao?.trim() && setFieldValue('identificacao', values.identificacao.trim())
							}
							onChange={(e) => {
								setFieldValue('identificacao', removerCaracteres(e.target.value, ['%']));
							}}
							name="identificacao"
							obrigatorio
							size={50}
							helpMessage={helpMessage.identificacao}
							disabled={desabilitarCamposDocumentoEnviadoOuExpirado}
							id="AssinaturaInputFieldId"
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							options={ASSINATURATIPO}
							label="Tipo de assinatura"
							value={values.assinaturaTipo}
							showClear={false}
							onChange={(e) => setFieldValue('assinaturaTipo', e.value)}
							name="assinaturaTipo"
							obrigatorio
							helpMessage={helpMessage.assinaturaTipo}
							disabled={desabilitarCamposDocumentoEnviadoOuExpirado}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputDate}
							name="expiracaoAssinaturas"
							label="Expiração das assinaturas"
							errors={errors.expiracaoAssinaturas}
							touched
							onChange={(e) => setFieldValue('expiracaoAssinaturas', e.target.value)}
							helpMessage={helpMessage.expiracaoAssinaturas}
							value={values.expiracaoAssinaturas}
							disabled={desabilitarDataExpiracao}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={SingleSelectPessoa}
							url={`${services.GESTOR}/v1/documentos/relacoes/pessoas`}
							name="pessoa"
							className="react-select-base step-documento-pessoa"
							label="Pessoa "
							value={values.pessoa}
							disabled={desabilitarCamposDocumentoEnviadoOuExpirado}
							helpMessage={helpMessage.pessoa}
							onChange={(e) => onChangePessoa(e)}
							{...informacoesPermissoes}
						/>
						<If test={values.status === statusDocumento.PENDENTE && !values.arquivo}>
							<Col sm="12" md="12" lg="12" xl="12" className="step-documento-botoes" style={{ minHeight: '150px' }}>
								<SecaoUploadArquivo
									onAdicionar={(e) => setFieldValue('arquivo', e)}
									disabled={values.id ? !informacoesPermissoes.podeEditar : !informacoesPermissoes.podeInserir}
								/>
							</Col>
						</If>
						<If
							test={
								values.arquivo &&
								(values.status === statusDocumento.PENDENTE || values.status === statusDocumento.ARQUIVO_ADICIONADO)
							}
						>
							<Col
								sm="12"
								md="12"
								lg="12"
								xl="12"
								style={{
									minHeight: '150px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<SecaoArquivoAdicionado
									arquivo={values.arquivo}
									onAdicionar={(e) => setFieldValue('arquivo', e)}
									onBaixar={() => baixarDocumento()}
									disabled={values.id ? !informacoesPermissoes.podeEditar : !informacoesPermissoes.podeInserir}
								/>
							</Col>
						</If>
						<If
							test={values.status !== statusDocumento.PENDENTE && values.status !== statusDocumento.ARQUIVO_ADICIONADO}
						>
							<Col
								sm="12"
								md="12"
								lg="12"
								xl="12"
								className="step-documento-botoes"
								style={{
									minHeight: '150px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<ExibirFeedbackCliente
									onVerDetalhesClick={() => setModalVerDetalhesDocumentoVisible(true)}
									signatarios={values.signatarios}
									status={values.status}
								/>
							</Col>
						</If>
					</Grid>
					<Grid>
						<TabsAssinaturaEletronica
							tabSelecionada={tabSelecionada}
							setTabSelecionada={setTabSelecionada}
							values={values}
							setFieldValue={setFieldValue}
							informacoesPermissoes={informacoesPermissoes}
							isMobile={isMobile}
							isTablet={isTablet}
							estadoCadastro={estadoCadastro}
							desabilitarCamposDocumentoEnviadoOuExpirado={desabilitarCamposDocumentoEnviadoOuExpirado}
						/>
					</Grid>
				</FormContent>
			</Form>
			<If test={modalEnviarDocumentoVisible}>
				<ModalEnviarParaAssinatura
					modalReenviarEmail={modalReenviarEmail}
					visible={modalEnviarDocumentoVisible}
					onHide={() => setModalEnviarDocumentoVisible(false)}
					idDocumento={values.id}
					identificacaoDocumento={values.identificacao}
					arquivo={values.arquivo}
					signatarioEspecifico={signatarioEspecifico}
					onDocumentoEnviado={() => onDocumentoEnviadoTodosSignatarios()}
					signatarios={values.signatarios}
					emailsSignatarios={values.signatarios.map((signatario) => signatario.email)}
					assinaturaTipo={values.assinaturaTipo}
					informacoesEmail={{
						corpoEmail: values.corpoEmail,
						tituloEmail: values.tituloEmail,
					}}
				/>
			</If>
			<If test={modalVerDetalhesDocumentoVisible}>
				<ModalVerDetalhesDocumento
					documento={values}
					visible={modalVerDetalhesDocumentoVisible}
					onClickEnviarEmailSignatarioEspecifico={(e) => {
						setModalEnviarDocumentoVisible(true);
						setModalReenviarEmail(true);
						setSignatarioEspecifico(e);
					}}
					onHide={() => setModalVerDetalhesDocumentoVisible(false)}
				/>
			</If>
			<If test={modalHistoricoVisible}>
				<DocumentoHistorico
					idDocumento={values.id}
					visible={modalHistoricoVisible}
					onHide={() => setModalHistoricoVisible(false)}
				/>
			</If>
		</>
	);
}

const AssinaturaEletronicaForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValue;
	},

	async validate(values) {
		const errors = {};

		if (values && !values.assinaturaTipo?.trim()) {
			errors.assinaturaTipo = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values && !values.identificacao?.trim()) {
			errors.identificacao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (removerCaracteresInvalidosRsql(values.identificacao, false) !== values.identificacao) {
			errors.identificacao = 'A identificação não pode conter caracteres especiais.';
		}

		if (values && !values.usuario) {
			errors.usuario = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = 2;
		}

		if (values && !values.setor) {
			errors.setor = mensagensDeValidacao.OBRIGATORIO;
			errors.tab = 2;
		}

		if (values.expiracaoAssinaturas && !isValid(parseISO(values.expiracaoAssinaturas))) {
			errors.expiracaoAssinaturas = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.expiracaoAssinaturas) {
			if (parseISO(values.expiracaoAssinaturas) < parseISO(format(new Date(), 'yyyy-MM-dd'))) {
				errors.expiracaoAssinaturas = 'A data de expiração não pode ser menor que hoje.';
			}
		}

		return errors;
	},

	handleSubmit: () => {},
})(AssinaturaEletronicaFormView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default withRouter(connect(mapStateToProps)(AssinaturaEletronicaForm));
