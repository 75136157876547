import { useState } from 'react';
import { useFormikContext } from 'formik';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { ButtonEditarTable, ButtonExcluirTable, ButtonNovo, Card, If, NenhumRegistroEncontrado } from 'components';
import { formatarMonetario } from 'Common';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { MDFE_TIPO_PAGAMENTO_COMPONENTE } from 'views/transporte/MDFe/Form/Util/constantes';

import { ModalComponentes } from './components/ModalComponentes';

function Componentes({ canTransmitir }) {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, isMobile } = useContextMDFe();

	const [sortOrder, setSortOrder] = useState(1);
	const [sortField, setSortField] = useState('descricao');
	const [modalComponentesVisible, setModalComponentesVisible] = useState(false);
	const [indexRegistro, setIndexRegistro] = useState(null);
	const [isNovoRegistro, setIsNovoRegistro] = useState(false);

	function renderFieldTipoPagamento(row) {
		switch (row.tipoPagamento) {
			case MDFE_TIPO_PAGAMENTO_COMPONENTE.DESPESAS: {
				return 'Despesas';
			}
			case MDFE_TIPO_PAGAMENTO_COMPONENTE.IMPOSTOS_TAXAS_CONTRIBUICOES: {
				return 'Impostos/Taxas';
			}
			case MDFE_TIPO_PAGAMENTO_COMPONENTE.OUTROS: {
				return 'Outros';
			}
			case MDFE_TIPO_PAGAMENTO_COMPONENTE.VALE_PEDAGIO: {
				return 'Vale pedágio';
			}
			default:
				return null;
		}
	}

	function renderFieldValor(row) {
		return formatarMonetario(row.valor);
	}

	function renderFieldDescricao(row) {
		return row?.descricao ?? null;
	}

	function renderAcoes(row) {
		return (
			<div>
				<ButtonEditarTable title="Editar" onClick={() => onEdit(row)} />
				<ButtonExcluirTable onClick={() => onRemove(row)} disabled={!canTransmitir} {...informacoesPermissoes} />
			</div>
		);
	}

	function onEdit(row) {
		values?.componentes?.forEach((componente, index) => {
			if (componente.id === row.id) {
				setIndexRegistro(index);
				setModalComponentesVisible(true);
			}
		});
	}

	function onRemove(row) {
		const componentesList = values.componentes?.filter((componente) => componente?.id !== row?.id);
		setFieldValue('componentes', componentesList);
	}

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	return (
		<>
			<ButtonNovo
				label="Novo componente"
				onClick={() => {
					setModalComponentesVisible(true);
					setIsNovoRegistro(true);
					setIndexRegistro(values?.componentes?.length ?? 0);
				}}
				disabled={!canTransmitir}
			/>
			<Card>
				<DataTable
					className="table"
					value={values?.componentes}
					sortField={sortField}
					sortOrder={sortOrder}
					onSort={atualizarOrdenacao}
					responsive
					emptyMessage={<NenhumRegistroEncontrado />}
					style={{
						paddingBottom: '16px',
						overflowY: 'auto',
						overflowX: 'hidden',
						maxHeight: isMobile ? '20.6rem' : '13.8rem',
					}}
				>
					<Column
						field="tipoPagamento"
						header="Tipo"
						body={(row) => renderFieldTipoPagamento(row)}
						sortable
						style={{ width: '10rem' }}
					/>
					<Column
						field="valor"
						header="Valor"
						body={(row) => renderFieldValor(row)}
						sortable
						style={{ width: '8rem' }}
					/>
					<Column field="descricao" header="Descrição" body={(row) => renderFieldDescricao(row)} sortable />
					<Column
						header="Ações"
						body={renderAcoes}
						style={{
							width: '6rem',
						}}
					/>
				</DataTable>
			</Card>
			<If test={modalComponentesVisible}>
				<ModalComponentes
					canTransmitir={canTransmitir}
					visible={modalComponentesVisible}
					indexRegistro={indexRegistro}
					componente={values?.componentes[indexRegistro]}
					componentesList={values?.componentes}
					isNovoRegistro={isNovoRegistro}
					setFieldValuePagamento={setFieldValue}
					onCloseModal={() => {
						setIndexRegistro(null);
						setModalComponentesVisible(false);
						setIsNovoRegistro(false);
					}}
				/>
			</If>
		</>
	);
}

export { Componentes };
