import { formatarMonetario } from 'Common';
import { Button, If } from 'components';
import { useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import {
	contentStyle,
	detalhesStyle,
	styleButtonAdquirir,
	styleCardAderido,
	styleCardNormal,
	styleNomePlanoAderido,
	styleNomePlanoNormal,
	styleValorAderido,
	styleValorNormal,
	valorLetrasStyle,
} from './Styles';

function CardPlano({ podeEditar, plano, onAderirClick }) {
	const { descricao, valor, aderido, labels, ilimitado, tipo } = plano;

	const [isHovered, setIsHovered] = useState(false);

	const valorNumeros = formatarMonetario(valor);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const styleCardNormalInternal = {
		...styleCardNormal,
		outline: isHovered ? '2px solid #2a7ecb' : 'none',
		boxShadow: isHovered ? '0px 0px 10px 4px rgba(42, 126, 203,0.5)' : null,
	};

	const styleButtonAdquirirInternal = {
		...styleButtonAdquirir,
		backgroundColor: isHovered ? '#0d3c61' : null,
		boxShadow: isHovered && !aderido ? '0px 0px 10px 4px rgba(42, 126, 203,0.5)' : null,
	};

	function handleClickTipoCard() {
		if (tipo === 'NORMAL' && !aderido) {
			return onAderirClick(plano);
		} else if (tipo === 'GRATUITO') {
			return window.open('https://msyssolutions.inf.br/contato', '_blank');
		} else {
			return null;
		}
	}

	return (
		<div
			className="card-default"
			style={aderido ? styleCardAderido : styleCardNormalInternal}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={() => handleClickTipoCard()}
		>
			<div style={contentStyle}>
				<div>
					<div style={aderido ? styleNomePlanoAderido : styleNomePlanoNormal}>{descricao.toUpperCase()}</div>
					<If test={!ilimitado}>
						<div style={aderido ? styleValorAderido : styleValorNormal}>
							<span>
								{valorNumeros}
								<span style={valorLetrasStyle}>/mês</span>
							</span>
						</div>
					</If>
					{labels.map((detalhe) => (
						<div key={detalhe} style={detalhesStyle}>
							<div style={{ marginRight: '10px' }}>
								<If test={detalhe[0] !== '0'}>
									<FaCheck color={aderido ? 'green' : '#006095'} />
								</If>

								<If test={detalhe[0] === '0'}>
									<FaTimes color="red" size={15} />
								</If>
							</div>
							<div>{detalhe}</div>
						</div>
					))}
				</div>
				<div>
					{tipo === 'NORMAL' ? (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								title={!podeEditar ? 'Você não possui permissão para contratar o plano' : null}
								label={aderido ? 'Plano atual' : 'Escolher plano'}
								disabled={aderido || !podeEditar}
								onClick={() => onAderirClick(plano)}
								style={styleButtonAdquirirInternal}
								color={aderido ? 'success' : 'primary'}
							/>
						</div>
					) : (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								title="Acesso o nosso site para ver todas as formas de entrar em contato conosco"
								label="Contate-nos"
								onClick={() => window.open('https://msyssolutions.inf.br/contato', '_blank')}
								style={styleButtonAdquirirInternal}
								color={aderido ? 'success' : 'primary'}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export { CardPlano };
