import { saveAs } from 'file-saver'

export function baixarArquivo(content, filename = "arquivo", contentType) {
    if (!contentType) {
        contentType = 'application/pdf;base64';
    }

    var blob = new Blob([content], { 'type': contentType });

    saveAs(blob, filename);
}