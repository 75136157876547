export const tutorialStepsEtiquetaProdutoConfiguracao = [
  {
    target: 'body',
    content: `Este é o módulo de configuração de etiquetas dos produtos`,
    disableBeacon: true,
    placement: 'center',
  },
  {
    target: '.step-etiqueta-configuracao-codigo',
    content: 'O campo "código" é controlado pelo sistema, visando auxiliá-lo na identificar a etiqueta de forma fácil',
    placement: 'top',
  },
  {
    target: '.step-etiqueta-configuracao-descricao',
    content: 'No campo "descrição", você pode informar um nome para auxiliar na organização e pesquisas futuras',
    placement: 'top',
  },
  {
    target: '.step-etiqueta-configuracao-card-medidas',
    content: 'Aqui estão as informações de tamanho e configurações, para o tamanho da página/etiqueta da impressão',
    placement: 'right',
  },
  {
    target: '.step-etiqueta-configuracao-card-preview-pagina',
    content: 'Nessa área está o preview da página, ela é alterada segundo o que for informado nas medidas ao lado',
    placement: 'left',
  },
  {
    target: '.step-etiqueta-configuracao-pagina-ou-campos',
    content:
      'Por fim, após informar as medidas de página necessárias, vai ser habilitado para informar os "campos", nessa tela é possível informar os campos da etiqueta, como, por exemplo: Código do produto, preço de venda, código de barras. Além disso, nessa etapa, exite um preview da etiqueta para você acompanhar em tempo real as alterações que você está fazendo',
    placement: 'top',
  },
];
