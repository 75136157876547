import { localServices } from "Common/Constantes/api";
import { get } from "Common/Requisicoes";

export async function asyncGetVersaoMsysGestorService(onSucess, onError) {
  await get(
    `${localServices.MSYS_GESTOR_SERVICE}/msysgestor/servico/versao`,
    null,
    onSucess,
    onError,
    true,
    false
  );
}

export async function asyncGetIsRunningMsysGestorService(onSuccess, onError) {
  await get(
    `${localServices.MSYS_GESTOR_SERVICE}/msysgestor/servico/verificar`,
    null,
    onSuccess,
    onError,
    true,
    false
  );
}
