export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é o cadastro de tributação Estadual, neste módulo serão vinculadas as tributações por estado e posteriormente vinculadas na NCM.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-tributacao-estadual-principais',
		content: 'Aqui você vai preencher a descrição e a situação da tributação estadual.',
	},
	{
		target: '.step-tributacao-estadual-estados-botao',
		content: 'Por esse botão pode ser incluídas novas tributações por estado.',
	},
];
