import { INITIAL_VALUES_VENDEDORES } from './constantes';

function converterVendedorApiParaForm(vendedor) {
	return {
		id: vendedor.id,
		codigo: vendedor.codigo,
		nome: vendedor.nome,
		tipo: vendedor.tipo,
		situacao: vendedor.situacao,
		origemCobranca: vendedor.origemCobranca,
		descontoMaximo: vendedor.descontoMaximo ?? 0,
		comissao: vendedor.comissao ?? 0,
		superior: vendedor.superior ? converterSuperiorApiParaForm(vendedor.superior) : null,
		descendenteFavorito: vendedor.descendenteFavorito
			? converterDescendenteFavoritoApiParaForm(vendedor.descendenteFavorito)
			: null,
		usuario: vendedor.usuario ? converterUsuarioApiParaForm(vendedor.usuario) : null,
		regioesTabelaPrecos: vendedor.regioes
			? converterRegioesTabelaPrecosApiParaForm(vendedor.regioes)
			: [INITIAL_VALUES_VENDEDORES],
		filiais: converterFiliaisParaFormulario(vendedor.filiais),
	};
}

function converterFiliaisParaFormulario(filiais) {
	if (filiais) {
		return filiais?.map((filial) => {
			return {
				id: filial.id,
				filial: {
					id: filial.filialId,
					codigo: filial.filialCodigo,
					nome: filial.filialNome,
					nomeApresentacao: filial.filialNomeApresentacao,
				},
				tabelaPreco: converterTabelaPrecoApiParaForm(filial.tabelaPreco),
			};
		});
	}

	return [];
}

function converterTabelaPrecoApiParaForm(tabelaPreco) {
	if (tabelaPreco) {
		return {
			label: tabelaPreco.nome,
			value: tabelaPreco.id,
			registro: tabelaPreco,
		};
	}

	return null;
}

function converterSuperiorApiParaForm(superior) {
	const convertido = {
		label: superior.nome,
		value: superior.id,
		registro: superior,
	};

	return convertido;
}

function converterDescendenteFavoritoApiParaForm(descendenteFavorito) {
	const convertido = {
		label: descendenteFavorito.nome,
		value: descendenteFavorito.id,
		registro: descendenteFavorito,
	};

	return convertido;
}

function converterUsuarioApiParaForm(usuario) {
	return {
		label: usuario.nome,
		value: usuario.id,
		registro: usuario,
	};
}

function converterRegioesTabelaPrecosApiParaForm(regioesTabelaPrecos) {
	const convertido = regioesTabelaPrecos.map((regiao) => {
		return {
			id: regiao.id,
			regiao: {
				label: regiao.regiao.nome,
				value: regiao.regiao.id,
				registro: regiao.regiao,
			},
			tabelaPreco: {
				label: regiao.tabelaPreco.nome,
				value: regiao.tabelaPreco.id,
				registro: regiao.tabelaPreco,
			},
		};
	});

	return convertido;
}

function converterVendedorFormParaApi(values) {
	const convertido = {
		id: values.id,
		codigo: values.codigo,
		nome: values.nome,
		tipo: values.tipo,
		situacao: values.situacao,
		origemCobranca: values.origemCobranca,
		descontoMaximo: values.descontoMaximo,
		comissao: values.comissao,
		superior: values.superior ? converterSuperiorFormParaApi(values.superior) : null,
		descendenteFavorito: values.descendenteFavorito
			? converterDescendenteFavoritoFormParaApi(values.descendenteFavorito)
			: null,
		regioes: values.regioesTabelaPrecos ? converterRegioesTabelaPrecosFormParaApi(values.regioesTabelaPrecos) : null,
		usuario: values.usuario ? { id: values.usuario?.value } : null,
		filiais: converterFiliaisFormParaApi(values.filiais),
	};

	return convertido;
}

function converterFiliaisFormParaApi(filiais) {
	if (filiais) {
		return filiais?.map((filial) => {
			return {
				id: filial.id,
				filialId: filial.filial?.id,
				filialCodigo: filial.filial?.codigo,
				filialNome: filial.filial?.nome,
				filialNomeApresentacao: filial.filial?.nomeApresentacao,
				tabelaPreco: filial.tabelaPreco ? { id: filial.tabelaPreco?.value } : null,
			};
		});
	}

	return null;
}

function converterSuperiorFormParaApi(superior) {
	return {
		...superior.registro,
	};
}

function converterDescendenteFavoritoFormParaApi(descendente) {
	return {
		...descendente.registro,
	};
}

function converterRegioesTabelaPrecosFormParaApi(regioesTabelaPrecos) {
	const convertido = regioesTabelaPrecos.map((regiao) => {
		if (regiao.regiao?.value) {
			return {
				id: regiao.id ?? null,
				regiao: { id: regiao.regiao?.registro?.id },
				tabelaPreco: { id: regiao.tabelaPreco?.registro?.id },
			};
		} else {
			return null;
		}
	});

	return convertido;
}

export { converterVendedorApiParaForm, converterVendedorFormParaApi };
