import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, withFormik } from 'formik';

import {
	usuarioPossuiPermissao,
	permissoes,
	recursos,
	removerCaracteres,
	validarUUID,
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	salvarConfiguracaoUsuario,
	mensagensDeValidacao,
} from '../../../../Common';

import {
	Form,
	FormContent,
	Grid,
	InputField,
	Prompt,
	Dropdown,
	Checkbox,
	Col,
	If,
	FormActions,
	Tutorial,
	tutorialStepsOperacaoFiscal,
} from '../../../../components';

import ActionButtons from './components/ActionsButtons';
import { helpOperacaoFiscalForm } from './components/Help';

import { asyncGetOperacaoFiscal } from '../Requests';
import {
	buscarEstadoCadastro,
	indicacaoMovimentoOptions,
	parametrosOptions,
	situacaoOptions,
} from '../Util/constantes';
import { converterOperacaoParaFormulario } from '../Util/operacoesFiscaisConverter';

const CADASTROURL = '/tributacoes/operacoes_fiscais/cadastro';
const PESQUISAURL = '/tributacoes/operacoes_fiscais';

const secaoStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '0px',
};

const codigoStyle = {
	fontSize: '24px',
	color: '#006095',
	fontWeight: 'bold',
};

const initialValue = {
	descricao: '',
	situacao: situacaoOptions[0].value,
	indicacaoMovimento: indicacaoMovimentoOptions[0].value,
	favoritoSaida: parametrosOptions[1].value,
	favoritoEntrada: parametrosOptions[1].value,
	geraFinanceiro: parametrosOptions[0].value,
	geraEstoque: parametrosOptions[0].value,
	atualizaCustoProduto: parametrosOptions[0].value,
	vendaConsumidor: false,
};

function OperacoesFiscaisFormView(props) {
	const { values, dirty, setFieldValue, resetForm, isMobile, isModal, hideModal } = props;
	const {
		codigo,
		descricao,
		situacao,
		indicacaoMovimento,
		favoritoSaida,
		favoritoEntrada,
		geraEstoque,
		geraFinanceiro,
		atualizaCustoProduto,
	} = values;

	const [dadosBase, setDadosBase] = useState(null);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [isNewOperacao, setIsNewOperacao] = useState(false);

	const podeInserir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EXCLUIR);
	const informacoesPermissoes = {
		estadoCadastro: buscarEstadoCadastro(props),
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	const idURL = props.match?.params.id;

	useEffect(() => {
		const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_OPERACOES_FISCAIS);

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_OPERACOES_FISCAIS, false, null, false);
		}
	}, []);

	useEffect(() => {
		if (validarUUID(idURL) && !isNewOperacao && !isModal) {
			asyncGetOperacaoFiscal(idURL, async ({ data: operacaoFiscal }) => {
				let OperacaoConvertida = converterOperacaoParaFormulario(operacaoFiscal);
				resetForm({ values: OperacaoConvertida });
				setDadosBase(OperacaoConvertida);
			});
		}
	}, [idURL, resetForm]);

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsOperacaoFiscal}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form
				header={isMobile ? 'Operação Fiscal' : 'Cadastro de operação fiscal'}
				isModal={isModal}
				className="card-default screen-max-width"
			>
				<FormActions className="screen-max-width">
					<ActionButtons
						informacoesPermissoes={informacoesPermissoes}
						CADASTROURL={CADASTROURL}
						PESQUISAURL={PESQUISAURL}
						dadosBase={dadosBase}
						setDadosBase={e => setDadosBase(e)}
						initialValue={initialValue}
						setIsNewOperacao={e => setIsNewOperacao(e)}
						{...props}
						hideModal={hideModal}
						isModal={isModal}
					/>
				</FormActions>
				<FormContent>
					<Grid style={{ display: 'flex', alignItems: 'center' }}>
						<Col sm="2" md="2" lg="2" xl="2" col="2">
							<Col sm="12" md="12" lg="12" xl="12" style={{ height: '42px', marginBottom: '1rem' }}>
								<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
									<div
										style={secaoStyle}
										title={!codigo ? 'O código é gerado automaticamente pelo sistema' : null}
									>
										<div>Código</div>
										<div style={codigoStyle}>{codigo || '-'}</div>
									</div>
								</div>
							</Col>
						</Col>
						<Col
							sm="10"
							md="10"
							lg="10"
							xl="10"
							col="10"
							style={{ display: 'flex', justifyContent: 'flex-end' }}
						>
							<If test={indicacaoMovimento === 'SAIDA'}>
								<Field
									className="step-favorito"
									sm="12"
									md="12"
									lg="12"
									xl="12"
									component={Checkbox}
									colStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '7px' }}
									label="Favorita para saída"
									name="favoritoSaida"
									disabled={situacao === 'INATIVO' ? true : false}
									helpMessage={helpOperacaoFiscalForm.favoritoSaida}
									onChange={e => setFieldValue('favoritoSaida', !favoritoSaida)}
									checked={favoritoSaida}
									{...informacoesPermissoes}
								/>
							</If>
							<If test={indicacaoMovimento === 'ENTRADA'}>
								<Field
									className="step-favorito"
									sm="12"
									md="12"
									lg="12"
									xl="12"
									component={Checkbox}
									colStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '7px' }}
									label="Favorita para entrada"
									name="favoritoEntrada"
									disabled={situacao === 'INATIVO' ? true : false}
									helpMessage={helpOperacaoFiscalForm.favoritoEntrada}
									onChange={e => setFieldValue('favoritoEntrada', !favoritoEntrada)}
									checked={favoritoEntrada}
									{...informacoesPermissoes}
								/>
							</If>
						</Col>
					</Grid>
					<Grid className="step-campos" style={{ display: isMobile ? 'block' : 'flex' }}>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							component={InputField}
							label="Descrição"
							name="descricao"
							onChange={e => setFieldValue('descricao', removerCaracteres(e.target?.value, ['─']))}
							value={descricao}
							helpMessage={helpOperacaoFiscalForm.descricao}
							obrigatorio
							size={120}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Situação"
							name="situacao"
							value={situacao}
							helpMessage={helpOperacaoFiscalForm.situacao}
							onChange={event => setFieldValue('situacao', event?.value)}
							options={situacaoOptions}
							showClear={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Indicação movimento"
							name="indicacaoMovimento"
							value={indicacaoMovimento}
							helpMessage={helpOperacaoFiscalForm.indicacaoMovimento}
							onChange={event => setFieldValue('indicacaoMovimento', event?.value)}
							disabled={situacao === 'INATIVO' ? true : false}
							options={indicacaoMovimentoOptions}
							showClear={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Gera financeiro"
							name="geraFinanceiro"
							value={geraFinanceiro}
							helpMessage={helpOperacaoFiscalForm.geraFinanceiro}
							onChange={event => setFieldValue('geraFinanceiro', event?.value)}
							options={parametrosOptions}
							showClear={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Gera estoque"
							name="geraEstoque"
							value={geraEstoque}
							helpMessage={helpOperacaoFiscalForm.geraEstoque}
							onChange={event => setFieldValue('geraEstoque', event?.value)}
							options={parametrosOptions}
							showClear={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Atualiza custo do produto"
							name="atualizaCustoProduto"
							value={atualizaCustoProduto}
							helpMessage={helpOperacaoFiscalForm.atualizaCustoProduto}
							onChange={event => setFieldValue('atualizaCustoProduto', event?.value)}
							options={parametrosOptions}
							showClear={false}
							{...informacoesPermissoes}
						/>
						<If test={indicacaoMovimento === 'SAIDA'}>
							<Field
								sm="12"
								md="4"
								lg="3"
								xl="3"
								component={Checkbox}
								colStyle={{
									display: 'flex',
									alignItems: 'flex-end',
									marginBottom: '7px',
									paddingBottom: '8px',
								}}
								label="Venda para consumidor final"
								obrigatorio
								name="vendaConsumidor"
								helpMessage={helpOperacaoFiscalForm.vendaConsumidor}
								checked={values.vendaConsumidor}
								onChange={event => setFieldValue('vendaConsumidor', !values.vendaConsumidor)}
								showClear={false}
								{...informacoesPermissoes}
							/>
						</If>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const OperacoesFiscaisForm = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		let errors = {};
		if (!values.descricao) {
			errors.descricao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.favoritoSaida && values.indicacaoMovimento === 'ENTRADA') {
			values.favoritoSaida = false;
		}

		if (values.favoritoEntrada && values.indicacaoMovimento === 'SAIDA') {
			values.favoritoEntrada = false;
		}

		if (values.situacao === 'INATIVO' && values.favoritoEntrada) {
			values.favoritoEntrada = false;
		}

		if (values.situacao === 'INATIVO' && values.favoritoSaida) {
			values.favoritoSaida = false;
		}
		return errors;
	},

	handleSubmit: () => {},
})(OperacoesFiscaisFormView);

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
});

export default withRouter(connect(mapStateToProps)(OperacoesFiscaisForm));
