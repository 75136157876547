import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { isValid, parseISO, format, formatISO, startOfMonth } from 'date-fns';
import { withFormik, Field } from 'formik';

import {
	Grid,
	InputDate,
	ModalRelatorio,
	MultiSelectClientes,
	DropdownMultiSelect,
	MultipleSelectSetores,
} from 'components';
import { mensagensDeValidacao, services } from 'Common';

import { validarFormulario } from 'views/Util';
import { helpMessage } from './util/constantes';
import { asyncBaixarRelatorioOrdemServicoPeriodo } from '../../Requests';

const initialValue = {
	dataInicial: formatISO(startOfMonth(new Date())),
	dataFinal: new Date().toISOString(),
	cliente: [],
	situacao: [],
	setor: [],
};

const optionsSituacao = [
	{ value: 'PENDENTE', label: 'Pendente' },
	{ value: 'FINALIZADO', label: 'Finalizado' },
	{ value: 'CANCELADO', label: 'Cancelado' },
];

function OrdemServicoPeriodo(props) {
	const { visible, onHide, values, handleSubmit, validateForm, setFieldValue } = props;

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('InputDataInicial')?.focus();
		}, 500);
	}, []);

	async function imprimir() {
		await handleSubmit();
		await validateForm();

		if (await validarFormulario(props)) {
			try {
				const dataInicialFormatada = format(parseISO(values.dataInicial), 'yyyy-MM-dd');
				const dataFinalFormatada = format(parseISO(values.dataFinal), 'yyyy-MM-dd');
				let clientesSelecionados = '';
				let situacao = '';
				let setor = '';

				if (values.cliente) {
					clientesSelecionados = values.cliente.map((item) => `'${item.value}'`).join(',');
				}

				if (values.situacao) {
					situacao = values.situacao.map((item) => `'${item.value}'`).join(',');
				}

				if (values.setor) {
					setor = values.setor.map((item) => `'${item.value}'`).join(',');
				}

				await asyncBaixarRelatorioOrdemServicoPeriodo(
					dataInicialFormatada,
					dataFinalFormatada,
					clientesSelecionados,
					situacao,
					setor,
					({ data: pdf }) => {
						const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						const arquivoURL = URL.createObjectURL(arquivoPDF);
						const relatorio = window.open(arquivoURL);
						if (relatorio) {
							relatorio.onload = () => {
								setTimeout(() => {
									relatorio.document.title = 'Relatório ordens de serviço por período';
								}, 250);
							};
						}
						onHide();
					}
				);
			} catch (e) {
				console.error(e);
			}
		}
	}

	const parametros = (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={InputDate}
				label="Data inicial"
				name="dataInicial"
				obrigatorio
				helpMessage={helpMessage.dataInicial}
				value={values.dataInicial}
				touched
				id="InputDataInicial"
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={InputDate}
				label="Data final"
				name="dataFinal"
				obrigatorio
				helpMessage={helpMessage.dataFinal}
				value={values.dataFinal}
				touched
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={MultiSelectClientes}
				label="Cliente"
				name="cliente"
				helpMessage={helpMessage.cliente}
				url={`${services.GESTOR}/v1/ordem_servico/relacoes/clientes`}
				isMulti
				closeMenuOnSelect={false}
				backspaceRemovesValue={false}
				esconderBotao
				showClear={false}
				onChange={(e) => setFieldValue('cliente', e)}
				value={values.cliente}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={DropdownMultiSelect}
				touched
				label="Situação"
				name="situacao"
				helpMessage={helpMessage.situacao}
				obrigatorio
				onChange={(e) => setFieldValue('situacao', e)}
				options={optionsSituacao}
				value={values.situacao}
				showClear={false}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={MultipleSelectSetores}
				name="setor"
				label="Setor(es)"
				touched
				obrigatorio
				helpMessage={helpMessage.setor}
				url={`${services.GESTOR}/v1/ordem_servico/relacoes/setores`}
				value={values.setor}
				onChange={(e) => setFieldValue('setor', e)}
				esconderBotao
				isClearable={false}
				utilizaFavorito
			/>
		</Grid>
	);
	return (
		<ModalRelatorio
			header="Relatório ordens de serviço por período"
			visible={visible}
			onHide={onHide}
			onImprimir={imprimir}
			parametros={parametros}
			isValid={props.errors.length === 0}
		/>
	);
}

const ModalOrdemServicoPeriodo = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		const errors = {};

		if (!values.dataInicial || values.dataInicial === '') {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataFinal || values.dataFinal === '') {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.dataInicial && !isValid(parseISO(values.dataInicial))) {
			errors.dataInicial = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.dataFinal && !isValid(parseISO(values.dataFinal))) {
			errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.situacao.length === 0) {
			errors.situacao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.setor.length === 0) {
			errors.setor = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		dataInicial: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		dataFinal: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		situacao: Yup.array()
			.min(1, mensagensDeValidacao.OBRIGATORIO)
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO),
		setor: Yup.array().min(1, mensagensDeValidacao.OBRIGATORIO).nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(OrdemServicoPeriodo);

export default ModalOrdemServicoPeriodo;
