import { Field, useFormikContext } from 'formik';

import { Dropdown, Grid, InputDouble, InputMoney, SingleSelectCst, typesCst } from 'components';
import { origemProduto } from '../../../../Utils/constantes';

function Icms({ tributo, disabled, informacoesPermissoes, onBlurValoresTributacao }) {
	const { errors, setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={SingleSelectCst}
				label="CST/CSOSN"
				name="tributos.icms.cstCsosn"
				value={tributo?.cstCsosn}
				useFormErrors={false}
				useFormTouched={false}
				errors={errors?.cstCsosn ?? null}
				touched={!!errors?.cstCsosn}
				type={typesCst.CST_CSOSN}
				isClearable={false}
				onChange={(event) => setFieldValue('tributos.icms.cstCsosn', event)}
				obrigatorio
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={Dropdown}
				label="Origem do produto"
				name="tributos.icms.origemProduto"
				useFormErrors={false}
				useFormTouched={false}
				errors={errors?.origemProduto ?? null}
				touched={!!errors?.origemProduto}
				value={tributo?.origemProduto}
				showClear={false}
				onChange={(event) => setFieldValue('tributos.icms.origemProduto', event.value)}
				options={origemProduto}
				disabled={disabled}
				obrigatorio
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputMoney}
				label="BC ICMS"
				name="tributos.icms.baseIcms"
				touched
				allowNegative={false}
				value={tributo?.baseIcms}
				onChange={(event) => setFieldValue('tributos.icms.baseIcms', event.target.value)}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				label="% ICMS"
				name="tributos.icms.percentualIcms"
				value={tributo?.percentualIcms}
				onChange={(event) => setFieldValue('tributos.icms.percentualIcms', event.target.value)}
				suffix="%"
				allowNegative={false}
				maxValue={100}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Valor ICMS"
				name="tributos.icms.valorIcms"
				touched
				allowNegative={false}
				value={tributo?.valorIcms}
				onChange={(event) => setFieldValue('tributos.icms.valorIcms', event.target.value)}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputMoney}
				label="BC ICMS ST"
				name="tributos.icms.baseIcmsSt"
				touched
				allowNegative={false}
				value={tributo?.baseIcmsSt}
				onChange={(event) => setFieldValue('tributos.icms.baseIcmsSt', event.target.value)}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				label="% ICMS ST"
				name="tributos.icms.percentualIcmsSt"
				value={tributo?.percentualIcmsSt}
				onChange={(event) => setFieldValue('tributos.icms.percentualIcmsSt', event.target.value)}
				suffix="%"
				allowNegative={false}
				maxValue={100}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Valor ICMS ST"
				name="tributos.icms.valorIcmsSt"
				touched
				allowNegative={false}
				value={tributo?.valorIcmsSt}
				onChange={(event) => setFieldValue('tributos.icms.valorIcmsSt', event.target.value)}
				onBlur={onBlurValoresTributacao}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				label="% MVA"
				name="tributos.icms.percentualMva"
				value={tributo?.percentualMva}
				onChange={(event) => setFieldValue('tributos.icms.percentualMva', event.target.value)}
				suffix="%"
				allowNegative={false}
				maxValue={100}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Valor pauta"
				name="tributos.icms.valorPauta"
				touched
				allowNegative={false}
				value={tributo?.valorPauta}
				onChange={(event) => setFieldValue('tributos.icms.valorPauta', event.target.value)}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Base ST retido"
				name="tributos.icms.baseRetidoSt"
				touched
				allowNegative={false}
				value={tributo?.baseRetidoSt}
				onChange={(event) => setFieldValue('tributos.icms.baseRetidoSt', event.target.value)}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Valor ST retido"
				name="tributos.icms.valorRetidoSt"
				touched
				allowNegative={false}
				value={tributo?.valorRetidoSt}
				onChange={(event) => setFieldValue('tributos.icms.valorRetidoSt', event.target.value)}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Valor diferido"
				name="tributos.icms.valorDiferimento"
				touched
				allowNegative={false}
				value={tributo?.valorDiferimento}
				onChange={(event) => setFieldValue('tributos.icms.valorDiferimento', event.target.value)}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				label="% FCP"
				name="tributos.icms.percentualFundoCombatePobreza"
				value={tributo?.percentualFundoCombatePobreza}
				onChange={(event) => setFieldValue('tributos.icms.percentualFundoCombatePobreza', event.target.value)}
				suffix="%"
				allowNegative={false}
				maxValue={100}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { Icms };
