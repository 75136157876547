import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { buscarContas } from './Requests';
import { ProgressBar } from 'primereact/progressbar';
import {
	formatarMonetario,
	usuarioPossuiPermissao,
	copiarObjeto,
	recursos,
	permissoes,
	usePrevious,
} from '../../../../../../../Common';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import { Link, withRouter } from 'react-router-dom';
import { Icon } from '@iconify/react';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { Financas } from '../../../../../../Util/constantes';
import './Styles/index.css';

const List = props => {
	return (
		<div className="listagem-contas-content">
			<ul style={{ listStyle: 'none', padding: '0px 3px', margin: '0px' }}>{props.children}</ul>
		</div>
	);
};

function ListagemContas(props) {
	const { dataInicial, dataFinal, atualizarGraficoNovamente, history, isMobile } = props;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [contas, setContas] = useState([]);
	const [podeVisualizarLancamentos, setPodeVisualizarLancamentos] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.VISUALIZAR)
	);
	const [podeVisualizarContas, setPodeVisualizarContas] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.VISUALIZAR)
	);
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	const [hiddenObjects, setHiddenObjects] = useState([]);

	useEffect(() => {
		carregarInformacoes();
	}, [atualizarGraficoNovamente]);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}

			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;
	}, []);

	useEffect(() => {
		buscarContas(
			({ data: contas }) => {
				setContas(contas);
				setHiddenObjects(contas);
				setLoading(false);
				setError(false);
			},
			() => {
				setError(true);
				setLoading(false);
			}
		);
	}, [loading, atualizarGraficoNovamente]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	function getHeaderCard() {
		return (
			<div id="card-header-listagem-contas">
				<label title="Lista as contas e seu saldo atual">Saldo atual das minhas contas</label>
			</div>
		);
	}

	function onVerDetalhesClick(contaId) {
		history.push({
			pathname: '/movimentacoes',
			state: { contaSelecionadaId: contaId },
		});
	}

	function handleToggle(item) {
		let filteredObjects = copiarObjeto(hiddenObjects);
		const existingItem = hiddenObjects.find(x => x.id === item.id);
		if (!existingItem) {
			filteredObjects.push(item);
		} else {
			filteredObjects.splice(
				filteredObjects.findIndex(x => x.id === item.id),
				1
			);
		}
		setHiddenObjects(filteredObjects);
	}

	function renderSaldoAtual(item) {
		if (hiddenObjects.some(e => e.id === item.id)) {
			return formatarMonetario(item.saldoAtual);
		} else {
			return '-';
		}
	}

	function renderBotao(item) {
		if (hiddenObjects.some(e => e.id === item.id)) {
			return <Icon icon="mdi:eye" />;
		} else {
			return <Icon icon="mdi:eye-off" />;
		}
	}

	function ListItem(item) {
		return (
			<li style={{ margin: '6px 0px' }}>
				<div
					className="p-grid"
					style={{
						margin: '0px 6px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<span
						className="p-col listagem-contas-title"
						title={item.nome}
						style={{ padding: '0px', display: 'flex', alignItems: 'center' }}
					>
						<p
							style={{ display: 'flex', padding: '4px 6px', cursor: 'pointer' }}
							onClick={() => handleToggle(item)}
						>
							{renderBotao(item)}
						</p>
						{item.nome}
					</span>
					<div
						className="p-col-fixed listagem-contas-coluna-valor"
						style={
							item.saldoAtual < 0 ? { color: Financas.cores.vermelho } : { color: Financas.cores.verde }
						}
						onClick={item.habilitarVerDetalhes ? () => item.verDetalhesClick(item.id) : null}
						title={item.habilitarVerDetalhes ? 'Ver movimentações' : null}
					>
						{renderSaldoAtual(item)}
					</div>
				</div>
			</li>
		);
	}

	if (loading) {
		return (
			<Card
				header={getHeaderCard()}
				style={{ margin: isMobile ? '8px' : '0px', borderRadius: '5px', height: '100%' }}
			>
				<ProgressBar mode="indeterminate" style={{ height: '3px' }} />
			</Card>
		);
	} else if (error) {
		return (
			<Card
				header={getHeaderCard()}
				style={{ margin: isMobile ? '8px' : '0px', borderRadius: '5px', height: '100%' }}
			>
				<FalhaAoCarregarAsInformacoes />
			</Card>
		);
	} else {
		const saldoTotal = hiddenObjects.reduce((acc, cur) => acc + cur.saldoAtual, 0);

		return (
			<Card
				header={getHeaderCard()}
				style={{ margin: isMobile ? '8px' : '0px', borderRadius: '5px', height: '100%' }}
			>
				{contas.length > 0 ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'space-between',
						}}
					>
						<List>
							{contas.map((conta, idx, array) => {
								return (
									<React.Fragment key={conta.id}>
										<ListItem
											id={conta.id}
											nome={conta.nome}
											saldoAtual={conta.saldoAtual}
											verDetalhesClick={onVerDetalhesClick}
											habilitarVerDetalhes={podeVisualizarLancamentos}
										/>
										{++idx !== array.length ? <hr className="divider-listagem-contas" /> : null}
									</React.Fragment>
								);
							})}
						</List>
						<div>
							<hr className="divider-listagem-contas" />
							<div
								className="p-grid"
								style={{
									margin: '0px 6px',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'flex-end',
								}}
							>
								<span
									title="Soma total de todas as contas"
									className="p-col"
									style={{ fontWeight: 'bold', padding: '0.6rem 0px 0px 0px', fontSize: '16px' }}
								>
									Saldo total
								</span>
								<div
									className="p-col-fixed listagem-contas-coluna-total"
									style={
										saldoTotal < 0
											? { color: Financas.cores.vermelho, fontWeight: 'bold' }
											: { color: Financas.cores.verde, fontWeight: 'bold' }
									}
								>
									{formatarMonetario(saldoTotal)}
								</div>
							</div>
						</div>
						{podeVisualizarContas && (
							<div style={{ textAlign: 'center', padding: '1rem 0' }}>
								<Link to="/contas">Visualizar contas</Link>
							</div>
						)}
					</div>
				) : (
					<NenhumaInformacaoEncontrada />
				)}
			</Card>
		);
	}
}

export default withRouter(ListagemContas);
