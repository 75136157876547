import { Modal } from "../../../../../../components";
import FormModalProdutos from "./FormModalProdutos";

function ModalProdutos(props) {
  const {
    onHide,
    visible,
    isMobile,
    valuesProduto,
    onSave,
    isFinalizado,
    modeloNota,
  } = props;
  const is_nfse = modeloNota.value === "8f07d8eb-0ca0-4830-8b12-729e6ae2019b";
  const product_or_service = is_nfse ? "serviço" : "produto";

  return (
    <>
      <Modal
        header={
          valuesProduto
            ? `Editar ${product_or_service}`
            : `Adicionar ${product_or_service}`
        }
        onHide={onHide}
        visible={visible}
        styleModal={{
          minWidth: "60%",
          height: isMobile ? "85%" : "auto",
        }}
        isMobile={isMobile}
      >
        <FormModalProdutos
          {...props}
          valuesProduto={valuesProduto}
          isFinalizado={isFinalizado}
          onSave={(e) => onSave(e)}
          onHide={onHide}
          isNfse={is_nfse}
        />
      </Modal>
    </>
  );
}

export default ModalProdutos;
