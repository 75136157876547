import { format, parse } from "date-fns";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { connect } from "react-redux";
import { formatarMonetario } from "../../../../../Common";
import {
  Badge,
  NenhumRegistroEncontrado,
  Paginacao,
} from "../../../../../components";
import { ColorsSituacao, Situacao } from "../../Util/constantes";

const styleColumn = {
  textOverflow: "ellipsis",
  overflow: "hidden",
};

function TabelaCobrancas(props) {
  const {
    sortField,
    sortOrder,
    registros,
    totalElements,
    rows,
    setRows,
    page,
    setPage,
    selectedCobrancas,
    setFieldValue,
  } = props;

  function renderIdentificadorField(row) {
    return (
      <span
        title={
          row.descricao ? `descrição: ${row.descricao}` : `${row.identificador}`
        }
        style={{
          wordBreak: "break-all",
          paddingLeft: props.isMobile ? "15%" : "0",
          fontWeight: "500",
        }}
      >
        {row.identificador}
      </span>
    );
  }

  function renderPessoa(row) {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          wordBreak: "keep-all",
          paddingLeft: props.isMobile ? "15%" : "0",
        }}
      >
        {row}
      </span>
    );
  }

  function renderEmissaoField(row) {
    return (
      <span>
        {row
          ? format(parse(row, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")
          : " - "}
      </span>
    );
  }

  function onPageChange(event) {
    setRows(event.rows);
    setPage(event.page);
  }

  function renderCategoriaField(element) {
    return (
      <span
        title={element?.nome}
        style={{ color: element?.aparencia, fontWeight: "bold" }}
      >
        {element?.nome}
      </span>
    );
  }

  function renderSituacaoField(row) {
    const styleBackground = {
      borderRadius: "20px",
      padding: getPaddingSituacao(row?.pix?.status),
    };
    if (row.pix && row.pix.status) {
            switch (row.pix.status) {
        case Situacao.RECEBIDO.value: {
          return Badge(
            ColorsSituacao.textRecebido,
            ColorsSituacao.bgRecebido,
            Situacao.RECEBIDO.label,
            styleBackground,
            null,
            null,
            null
          );
        }
        case Situacao.PENDENTE.value: {
          return Badge(
            ColorsSituacao.textGerado,
            ColorsSituacao.bgGerado,
            Situacao.GERADO.label,
            styleBackground,
            null,
            null,
            null
          );
        }       
        case Situacao.EXPIRADO.value: {
          return Badge(
            ColorsSituacao.textExpirado,
            ColorsSituacao.bgExpirado,
            Situacao.EXPIRADO.label,
            styleBackground,
            null,
            null,
            null
          );
        }       
        default:
          return Badge(
            ColorsSituacao.textPendente,
            ColorsSituacao.bgPendente,
            Situacao.PENDENTE.label,
            styleBackground,
            null,
            null,
            null
          );
      }
    } else {
      return Badge(
        ColorsSituacao.textPendente,
        ColorsSituacao.bgPendente,
        Situacao.PENDENTE.label,
        styleBackground,
        null,
        null,
        null
      );
    }
  }

  function getPaddingSituacao(situacao) {
    switch (situacao) {
      case Situacao.PENDENTE.value: {
        return "0.1rem 2.2rem";
      }
      /*case Situacao.FALHA.value: {
        return "0.1rem 2.6rem";
      }
      case Situacao.EMITIDO.value: {
        return "0.1rem 2.1rem";
      }
      case Situacao.REJEITADO.value: {
        return "0.1rem 1.8rem";
      }
      case Situacao.REGISTRADO.value: {
        return "0.1rem 1.5rem";
      }
      case Situacao.LIQUIDADO.value: {
        return "0.1rem 1.6rem";
      }
      case Situacao.BAIXADO.value: {
        return "0.1rem 2.1rem";
      }
      case Situacao.PENDENTE_RETENTATIVA.value: {
        return "0.1rem 1.2rem";
      }*/
      default: {
        return "0.1rem 1.8rem";
      }
    }
  }

  return (
    <>
      <DataTable
        className="table table-cobrancas"
        rowClassName="table-row"
        cellClassName="table-row-cell"
        responsive
        sortField={sortField}
        sortOrder={sortOrder}
        value={registros}
        style={{ width: "100%" }}
        emptyMessage={<NenhumRegistroEncontrado />}
        selection={selectedCobrancas}
        onSelectionChange={(e) => setFieldValue("selectedCobrancas", e.value)}
        dataKey="id"
      >
        <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
        <Column
          className="step-listagem-order"
          field="identificador"
          header="Identificador"
          body={(row) => renderIdentificadorField(row)}
          sortable
          style={{
            ...styleColumn,
            width: "20%",
          }}
        />
        <Column
          field="emissao"
          header="Emissão"
          body={(row) => renderEmissaoField(row.emissao)}
          sortable
          style={{
            ...styleColumn,
            width: "8%",
          }}
        />
        <Column
          field="vencimento"
          header="Vencimento"
          body={(row) => renderEmissaoField(row.vencimento)}
          sortable
          style={{
            ...styleColumn,
            width: "8%",
          }}
        />
        <Column
          field="pessoa"
          header="Pessoa"
          body={(row) => renderPessoa(row.pessoa)}
          sortable
          style={{
            ...styleColumn,
            width: "30%",
          }}
        />
        <Column
          field="valor"
          header="Valor"
          body={(row) => formatarMonetario(row.valor)}
          sortable
          style={{
            ...styleColumn,
            width: "8%",
          }}
        />
        <Column
          field="aReceber"
          header="A receber"
          body={(row) => formatarMonetario(row.aReceber)}
          sortable
          style={{
            ...styleColumn,
            width: "8%",
          }}
        />
        <Column
          field="categoria.nome"
          header="Categoria"
          body={(row) => renderCategoriaField(row.categoria)}
          sortable
          style={{
            ...styleColumn,
            width: "10%",
          }}
        />
        <Column
          field="pix.status"
          header="Situação"
          body={(row) => renderSituacaoField(row)}
          style={{
            ...styleColumn,
            width: "20%",
          }}
        />
      </DataTable>
      <Paginacao
        totalElements={totalElements}
        rows={rows}
        page={page}
        onPageChange={(e) => onPageChange(e)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(TabelaCobrancas);
