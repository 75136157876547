import { useState } from 'react';
import { If, Modal } from 'components';

import { SeguradoraForm } from '../Form';

function ModalSeguradora({ visible, onHide, container, styleModal }) {
	const [hideBackground, setHideBackground] = useState(false);

	return (
		<Modal
			className={visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de seguradora"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1200px', ...styleModal }}
			hideBackground={hideBackground}
			container={container}
		>
			<If test={visible}>
				<SeguradoraForm hideModal={onHide} isModal setHideBackground={setHideBackground} appendTo={container} />
			</If>
		</Modal>
	);
}

export { ModalSeguradora };
