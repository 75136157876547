import { Grid, InputMoney, SingleSelectCst, typesCst } from 'components';
import { Field, useFormikContext } from 'formik';

function Pis({ tributo, disabled, informacoesPermissoes }) {
	const { errors, setFieldValue } = useFormikContext();

	return (
		<>
			<Grid>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					label="CST PIS"
					name="tributos.pis.cstPis"
					component={SingleSelectCst}
					useFormErrors={false}
					useFormTouched={false}
					errors={errors?.cstPis ?? null}
					touched={!!errors?.cstPis}
					type={typesCst.CST_PIS_COFINS}
					value={tributo?.cst}
					isClearable={false}
					onChange={(event) => setFieldValue('tributos.pis.cst', event)}
					obrigatorio
					disabled={disabled}
					{...informacoesPermissoes}
				/>
			</Grid>
			<Grid>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="BC PIS"
					name="tributos.pis.basePis"
					component={InputMoney}
					value={tributo?.basePis}
					touched
					allowNegative={false}
					onChange={(event) => setFieldValue('tributos.pis.basePis', event.target?.value)}
					size={13}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="PIS %"
					name="tributos.pis.percentualPis"
					component={InputMoney}
					value={tributo?.percentualPis}
					onChange={(event) => setFieldValue('tributos.pis.percentualPis', event.target?.value)}
					size={8}
					decimalScale={2}
					prefix=""
					suffix="%"
					maxValue={100}
					placeholder="0,00 %"
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Valor PIS"
					name="tributos.pis.valorPis"
					component={InputMoney}
					value={tributo?.valorPis}
					touched
					allowNegative={false}
					onChange={(event) => setFieldValue('tributos.pis.valorPis', event.target?.value)}
					size={13}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="BC PIS ST"
					name="tributos.pis.basePisSt"
					component={InputMoney}
					value={tributo?.basePisSt}
					touched
					allowNegative={false}
					onChange={(event) => setFieldValue('tributos.pis.basePisSt', event.target?.value)}
					size={13}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="PIS ST %"
					name="tributos.pis.percentualPisSt"
					component={InputMoney}
					value={tributo?.percentualPisSt}
					onChange={(event) => setFieldValue('tributos.pis.percentualPisSt', event.target?.value)}
					size={8}
					decimalScale={2}
					prefix=""
					suffix="%"
					maxValue={100}
					placeholder="0,00 %"
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Valor PIS ST"
					name="tributos.pis.valorPisSt"
					component={InputMoney}
					value={tributo?.valorPisSt}
					touched
					allowNegative={false}
					onChange={(event) => setFieldValue('tributos.pis.valorPisSt', event.target?.value)}
					size={13}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
			</Grid>
		</>
	);
}

export { Pis };
