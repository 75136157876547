import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	estadosCadastro,
	keyFilterConsultaRsql,
	mensagensDeValidacao,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	Col,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	Tree,
	Tutorial,
	alert,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	tutorialStepsPapeis,
} from 'components';
import { Field, withFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../Util';
import { confirmarExclusao } from '../../../Util/ExclusaoDeRegistros';
import {
	asyncBuscarEstruturaPapeis,
	asyncCreatePapel,
	asyncDeletePapel,
	asyncGetPapel,
	asyncGetPapelComMesmoNome,
	asyncUpdatePapel,
} from '../Requests';
import { INITIAL_VALUES, PAPEL_TIPO } from '../Util/constantes';
import { permissoesForamMarcadas, possuiPermissoesDePapeis } from '../Util/functions';
import {
	converterEstruturaParaTree,
	converterPapelParaApi,
	converterPapelParaFormulario,
} from '../Util/papelConverter';
import { helpFieldText } from './Help';

const CADASTROURL = '/papeis/cadastro';
const PESQUISAURL = '/papeis';

function FieldsView({
	isModal,
	values,
	dirty,
	setFieldValue,
	handleSubmit,
	resetForm,
	setFieldError,
	initialValues,
	errors,
	history,
	...props
}) {
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.PAPEIS, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.PAPEIS, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.PAPEIS, permissoes.EXCLUIR));
	const [deveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PAPEIS_PERMISSOES)
	);
	const [estruturaTree, setEstruturaTree] = useState([]);
	const [tutorialVisible, setTutorialVisible] = useState(false);

	const { id } = props.match.params;
	const menuOpcoes = useRef(null);

	useEffect(() => {
		asyncBuscarEstruturaPapeis(({ data: papeisPermissoes }) => {
			const estrutura = converterEstruturaParaTree(papeisPermissoes.recursos);
			setEstruturaTree(estrutura);
		});

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PAPEIS_PERMISSOES, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('PapeisInputNome')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		if (validarUUID(id) && !isModal && estruturaTree.length > 0) {
			asyncSelectRegistro(id);
		}
	}, [estruturaTree]);

	function novo() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: INITIAL_VALUES });
	}

	function excluir() {
		confirmarExclusao(() => asyncDeleteRegistro());
	}

	function buscarEstadoCadastro() {
		return values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	async function salvar(e, novoOnSuccess) {
		await handleSubmit();
		if ((await validarFormulario(props)) && !(await existePapelComMesmoNome())) {
			if (values.id) {
				await asyncUpdateRegistro(novoOnSuccess);
			} else {
				await asyncCreateRegistro(novoOnSuccess);
			}
		}
	}

	async function existePapelComMesmoNome() {
		const { nome, id } = values;
		let existePapelComMesmoNome = false;
		await asyncGetPapelComMesmoNome(nome, ({ data: papeis }) => {
			papeis.content.forEach((papel) => {
				if (papel.id !== id) {
					existePapelComMesmoNome = true;
					setFieldError('nome', `Já existe um grupo com o nome ${nome}`);
				}
			});
		});
		return existePapelComMesmoNome;
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
	}

	async function asyncDeleteRegistro() {
		await asyncDeletePapel(values.id, () => {
			resetForm();
			voltarParaAPesquisa(history, PESQUISAURL);
		});
	}

	async function asyncUpdateRegistro(novoOnSuccess) {
		const isAdministrador = values.tipo === PAPEL_TIPO.ADMINISTRADOR;
		if (isAdministrador && !possuiPermissoesDePapeis(values.permissoes)) {
			alert('Atenção', 'Você não pode remover as permissões "Grupos de usuários" do usuário administrador.');
		} else {
			const valuesLocal = converterPapelParaApi(values);
			await asyncUpdatePapel(valuesLocal, () => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					resetForm({ values: valuesLocal });
					asyncSelectRegistro(valuesLocal.id);
				}
			});
		}
	}

	async function asyncCreateRegistro(novoOnSuccess) {
		const valuesLocal = converterPapelParaApi(values);
		await asyncCreatePapel(valuesLocal, ({ data: papel }) => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				asyncSelectRegistro(papel.id);
			}
		});
	}

	async function asyncSelectRegistro(papelId) {
		await asyncGetPapel(papelId, ({ data: papel }) => {
			resetForm({
				values: converterPapelParaFormulario(papel, estruturaTree),
			});
			atualizarUrl(history, CADASTROURL, papelId, metodosAtualizarUrl.POP);
		});
	}

	const isNormal = values.tipo === PAPEL_TIPO.NORMAL;

	const informacoesPermissoes = {
		estadoCadastro: buscarEstadoCadastro(),
		podeInserir,
		podeEditar,
		podeExcluir,
	};

	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	const onClickNovo = dirty ? (e) => salvar(e, novo) : novo;
	return (
		<>
			<Tutorial
				steps={tutorialStepsPapeis}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>

			<Form header="Cadastro de grupo de usuário" isModal={isModal} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ButtonCancelar
						onClick={cancelar}
						hidden={isModal && values.id && !dirty}
						estadoBotao={dirty && !isModal ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						{...informacoesPermissoes}
					/>
					<ButtonSalvar onClick={salvar} disabled={!dirty} {...informacoesPermissoes} />
					<ButtonNovo
						onClick={onClickNovo}
						hidden={(!dirty && !values.id) || isModal}
						estadoBotao={estadoBotaoNovo}
						{...informacoesPermissoes}
					/>
					<ButtonExcluir
						hidden={!values.id}
						onClick={excluir}
						disabled={!isNormal}
						titlePersonalizado={
							!isNormal
								? 'Não é possível excluir um papel do tipo administrador ou contabilidade'
								: 'Excluir o registro'
						}
						{...informacoesPermissoes}
					/>
					<Button
						className="p-button-secondary"
						type="button"
						label="Opções"
						icon="fa fa-list"
						hidden
						onClick={(event) => menuOpcoes.toggle(event)}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							className="step-papeis-nome"
							label="Nome "
							name="nome"
							obrigatorio
							keyfilter={keyFilterConsultaRsql}
							helpMessage={helpFieldText.nome}
							value={values.nome}
							component={InputField}
							id="PapeisInputNome"
							{...informacoesPermissoes}
						/>
						<Col>
							<p title={helpFieldText.permissoes}>
								Permissões <b style={{ fontSize: '18px', lineHeight: '5px' }}> *</b>
							</p>
							<Tree
								className="step-papeis-three"
								style={{ border: 0, padding: 0, width: 'auto' }}
								value={estruturaTree}
								selectionKeys={values.permissoes}
								onSelectionChange={async (e) => {
									await setFieldValue('permissoes', e.value);
									props.validateForm();
								}}
								selection={values.permissoes}
								sortMode="single"
								selectionMode="checkbox"
								errors={errors.permissoes}
								{...informacoesPermissoes}
							/>
						</Col>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const Fields = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		permissoes: Yup.object()
			.nullable()
			.test('Permissoes marcadas', 'Selecione ao menos uma permissão para este grupo', (val) =>
				permissoesForamMarcadas(val)
			),
	}),

	handleSubmit: () => {},
})(FieldsView);

export default Fields;
