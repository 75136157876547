import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { asyncGetComprasUltimosDozeMeses } from './Requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../Common/Mascara';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import TituloCard from '../../../../../../../components/TituloCard';
import { Compras } from '../../../../../../Util/constantes';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';

const dashboardContent = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
};

let graficoCompras;

export default function GraficoComprasUltimosDozeMeses(props) {
	const { atualizarGraficoNovamente, isMobile, dataAtualizacaoMenuLateral } = props;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [competencia, setCompetencia] = useState([]);
	const [valorGrafico, setValorGrafico] = useState([]);
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);
	const graficoComprasUltimosDozeMeses = useRef();

	const cardStyle = {
		borderRadius: '5px',
		backgroundColor: 'white',
		boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
		minHeight: '100%',
		padding: isMobile ? '7px 0 0 0' : '7px',
	};

	useEffect(() => {
		carregarInformacoes();

		if (afterFirstRenderRef) {
			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;

		return () => {
			if (typeof graficoCompras !== 'undefined') graficoCompras.destroy();
		};
	}, []);

	useEffect(() => {
		asyncGetComprasUltimosDozeMeses(
			({ data }) => {
				let competenciaResponse = [];
				let valorGraficoResponse = [];

				data.forEach(elemento => {
					competenciaResponse.push(elemento.competencia);
					valorGraficoResponse.push(
						elemento.valorCancelado + elemento.valorFinalizado + elemento.valorPendente
					);
				});

				setCompetencia(competenciaResponse);
				setValorGrafico(valorGraficoResponse);
				setLoading(false);
				setError(false);
			},
			() => {
				setError(true);
				setLoading(false);
			}
		);
	}, [atualizarGraficoNovamente, dataAtualizacaoMenuLateral]);

	useEffect(() => {
		if (typeof graficoCompras !== 'undefined') graficoCompras.destroy();
		buildChart();
	}, [valorGrafico]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	function buildChart() {
		if (!graficoComprasUltimosDozeMeses.current) {
			return;
		}
		const myChartRef = graficoComprasUltimosDozeMeses.current.getContext('2d');

		if (typeof graficoCompras !== 'undefined') graficoCompras.destroy();

		graficoCompras = new Chart(myChartRef, {
			type: 'bar',
			data: {
				labels: competencia,
				datasets: [
					{
						label: 'Total de compras no mês',
						data: valorGrafico,
						backgroundColor: Compras.cores.verde,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				aspectRatio: 1,
				animation: {
					animateScale: true,
					animateRotate: true,
				},
				plugins: {
					datalabels: {
						color: Compras.cores.preto,
					},
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							usePointStyle: true,
						},
						onHover: function (e) {
							e.native.target.style.cursor = 'pointer';
						},
						onLeave: event => {
							event.native.target.style.cursor = 'default';
						},
					},
					tooltip: {
						callbacks: {
							title: tooltipItem => {
								return `Competência: ${tooltipItem[0].label}`;
							},
							label: tooltipItem => {
								const label = tooltipItem.dataset.label;
								const valor = formatarMonetario(tooltipItem.raw);
								return `${label}: ${valor}`;
							},
						},
					},
				},
				scales: {
					y: {
						beginAtZero: true,
						ticks: {
							callback: value => formatarMonetario(value),
						},
					},
				},
			},
		});
	}

	if (loading || error || valorGrafico?.length === 0) {
		return (
			<div style={cardStyle}>
				<TituloCard
					title={'Compras nos últimos 12 meses'}
					helpMessage="Lista o valor total das compras agrupadas por mês nos últimos doze meses"
					style={{ padding: isMobile ? '7px 0 0 7px' : '7px' }}
				/>
				<div style={dashboardContent}>
					{loading && (
						<ProgressBar
							mode="indeterminate"
							style={{ height: '3px', width: '100%', margin: '10px 0px' }}
						/>
					)}
					{error && <FalhaAoCarregarAsInformacoes />}
					{valorGrafico?.length === 0 && <NenhumaInformacaoEncontrada />}
				</div>
			</div>
		);
	} else {
		return (
			<div style={cardStyle}>
				<TituloCard
					title={'Compras nos últimos 12 meses'}
					helpMessage="Lista o valor total das compras agrupadas por mês nos últimos doze meses"
					style={{ padding: isMobile ? '7px 0 0 7px' : '7px' }}
				/>
				<div style={{ marginTop: '20px' }}>
					<canvas
						id="graficoComprasUltimosDozeMeses"
						ref={graficoComprasUltimosDozeMeses}
						style={{ maxWidth: '100%' }}
					/>
				</div>
			</div>
		);
	}
}
