import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Button,
	ButtonCancelar,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	Tutorial,
	estadosBotaoCancelar,
	tutorialStepsListagens,
} from 'components';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { atualizarUrl } from 'views/Util';
import { TabelaEtiquetas } from './components/TabelaEtiquetas';
import { asyncDeleteEtiqueta, asyncGetEtiquetas } from './Request';
import {
	onChangeValorPesquisa,
	setPage,
	setRegistros,
	setRows,
	setSize,
	setSortField,
	setSortOrder,
	setTotalRecords,
} from './Reducer/actions';
import { dataReducer } from './Reducer';

function EtiquetasConfiguracoes({ history }) {
	const podeInserir = usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.INSERIR);

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);

	const [{ valorPesquisa, totalRecords, registros, size, page, rows, sortField, sortOrder }, dispatch] = useReducer(
		dataReducer,
		{
			valorPesquisa: '',
			totalRecords: 0,
			registro: '',
			size: 0,
			page: 0,
			rows: 10,
			sortField: 'codigo',
			sortOrder: 1,
		}
	);
	const isFirstRender = useRef(false);

	const pesquisarCallback = useCallback(() => {
		pesquisar();
	});

	useEffect(() => {
		pesquisar();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		isFirstRender.current = true;

		setTimeout(() => {
			document.getElementById('EtiquetaInputSearch')?.focus();
		}, 500);
	}, []);

	useUpdateEffect(() => {
		if (isFirstRender.current) {
			pesquisarCallback();
		}
	}, [page, rows, sortOrder, sortField]);

	async function pesquisar() {
		const filtro = buscarFiltro();

		const url = construirUrl(
			`${services.GESTOR}/v1/etiquetas/resumo`,
			filtro || '?',
			size,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);
		await asyncGetEtiquetas(url, ({ data: etiquetas }) => {
			dispatch(setRegistros(etiquetas.content));
			dispatch(setTotalRecords(etiquetas.totalElements));
		});
	}

	function buscarFiltro() {
		const filtroRSQL = String('?query=(')
			.concat(`codigo=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`descricao=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		return filtroRSQL;
	}

	function handleChangePesquisa(data) {
		dispatch(onChangeValorPesquisa(data));
	}

	function handleClickVoltar() {
		atualizarUrl(history, `/produtos/etiquetas`);
	}

	function handleClickNovo() {
		atualizarUrl(history, `/produtos/etiquetas/configuracao/cadastro`);
	}

	function onEditItem(item) {
		atualizarUrl(history, `/produtos/etiquetas/configuracao/cadastro`, item.id);
	}

	function onRemoveItem(item) {
		confirmarExclusao(async () => {
			await asyncDeleteEtiqueta(item.id, 'Registro excluído com sucesso', () => pesquisar());
		});
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>

			<Form header="Cadastro de etiquetas">
				<FormActions>
					<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={handleClickVoltar} />
					<Button
						className="step-listagem-novo p-button-primary"
						label="Nova etiqueta"
						icon="fa fa-plus"
						title="Inserir uma nova etiqueta"
						podeInserir={podeInserir}
						disabled={!podeInserir}
						onClick={handleClickNovo}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<InputSearch
							id="EtiquetaInputSearch"
							className="step-listagem-input-search"
							value={valorPesquisa}
							onPesquisar={pesquisar}
							onChange={handleChangePesquisa}
						/>
					</Grid>
					<TabelaEtiquetas
						registros={registros}
						totalRecords={totalRecords}
						onEdit={onEditItem}
						onRemove={onRemoveItem}
						onPageChange={(e) => {
							dispatch(setSize(e.rows));
							dispatch(setRows(e.rows));
							dispatch(setPage(e.page));
						}}
						size={size}
						page={page}
						rows={rows}
						setSortField={(sortField) => {
							dispatch(setSortField(sortField));
						}}
						setSortOrder={(sortOrder) => {
							dispatch(setSortOrder(sortOrder));
						}}
						sortField={sortField}
						sortOrder={sortOrder}
					/>
				</FormContent>
			</Form>
		</>
	);
}

export { EtiquetasConfiguracoes };
