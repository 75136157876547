import { AutoProgressBar, Col, Grid, Modal } from 'components';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { useState } from 'react';

const TIPOS_EXCLUSOES_CONTAS_PAGAR = {
	APENAS_SELECIONADA: 'APENAS_SELECIONADA',
	PROXIMAS_EM_ABERTO: 'PROXIMAS_EM_ABERTO',
	TODAS_EM_ABERTO: 'TODAS_EM_ABERTO',
};

function ModalExclusaoContasPagar({ visible, onHide }) {
	const [value, setValue] = useState(TIPOS_EXCLUSOES_CONTAS_PAGAR.APENAS_SELECIONADA);

	function buscarDescricaoExcluir(value) {
		if (value === TIPOS_EXCLUSOES_CONTAS_PAGAR.APENAS_SELECIONADA) {
			return 'Excluir apenas selecionada';
		} else if (value === TIPOS_EXCLUSOES_CONTAS_PAGAR.PROXIMAS_EM_ABERTO) {
			return 'Excluir próximas em aberto';
		} else {
			return 'Excluir todas em aberto';
		}
	}

	return (
		<Modal header="Excluir conta a pagar com repetições" visible={visible} onHide={onHide}>
			<AutoProgressBar />
			<p style={{ textAlign: 'justify', marginLeft: '5px' }}>
				Você está tentando excluir uma conta a pagar que faz parte de várias repetições. <br /> Quais dessas repetições
				você deseja excluir?
			</p>
			<Grid style={{ margin: '20px' }}>
				<Col>
					<RadioButton
						inputId="rbExcluir1"
						name="value"
						value={TIPOS_EXCLUSOES_CONTAS_PAGAR.APENAS_SELECIONADA}
						onChange={(e) => setValue(e.value)}
						checked={value === TIPOS_EXCLUSOES_CONTAS_PAGAR.APENAS_SELECIONADA}
					/>
					<label htmlFor="rbExcluir1" className="p-radiobutton-label">
						Excluir <b>apenas esta conta a pagar</b>
					</label>
				</Col>
				<Col>
					<RadioButton
						inputId="rbExcluir2"
						name="value"
						value={TIPOS_EXCLUSOES_CONTAS_PAGAR.PROXIMAS_EM_ABERTO}
						onChange={(e) => setValue(e.value)}
						checked={value === TIPOS_EXCLUSOES_CONTAS_PAGAR.PROXIMAS_EM_ABERTO}
					/>
					<label htmlFor="rbExcluir2" className="p-radiobutton-label">
						Excluir <b>esta conta a pagar e as próximas</b> repetições em aberto
					</label>
				</Col>
				<Col>
					<RadioButton
						inputId="rbExcluir3"
						name="value"
						value={TIPOS_EXCLUSOES_CONTAS_PAGAR.TODAS_EM_ABERTO}
						onChange={(e) => setValue(e.value)}
						checked={value === TIPOS_EXCLUSOES_CONTAS_PAGAR.TODAS_EM_ABERTO}
					/>
					<label htmlFor="rbExcluir3" className="p-radiobutton-label">
						Excluir <b>esta conta a pagar e todas</b> as repetições em aberto
					</label>
				</Col>
			</Grid>
			<Grid justifyEnd>
				<div>
					<Button className="p-button-secondary" style={{ margin: '5px' }} label="Voltar" onClick={() => onHide()} />
					<Button
						style={{ margin: '5px' }}
						className="p-button-danger"
						label={buscarDescricaoExcluir(value)}
						onClick={() => onHide(value)}
					/>
				</div>
			</Grid>
		</Modal>
	);
}

export { ModalExclusaoContasPagar, TIPOS_EXCLUSOES_CONTAS_PAGAR };
