import { format } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { construirUrl, services } from '../../../../../../../Common';
import {
	ButtonCancelar,
	estadosBotaoCancelar,
	Form,
	FormActions,
	FormContent,
	Modal,
	NenhumRegistroEncontrado,
	Paginacao,
} from '../../../../../../../components';
import { asyncGetHistoricoMde } from '../../../../Requests';

export default function ModalMdeHistorico(props) {
	const { visible, onHide, idMde } = props;

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [page, setPage] = useState(0);
	const [sortField, setSortField] = useState('dataHora');
	const [sortOrder, setSortOrder] = useState(0);
	const [rows, setRows] = useState(10);

	useEffect(() => {
		if (visible) {
			pesquisar();
		}
	}, [visible, sortField, sortOrder, page, rows]);

	function pesquisar() {
		const url = construirUrl(
			`${services.GESTOR}/v1/mde/${idMde}/historico`,
			'?query=()',
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		asyncGetHistoricoMde(url, ({ data: historico }) => {
			setRegistros(historico.content);
			setTotalElements(historico.totalElements);
		});
	}

	function onSort(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function onPageChange(e) {
		setPage(e.page);
		setRows(e.rows);
	}

	function renderData(row) {
		if (row.dataHora) {
			return <span>{format(new Date(row.dataHora), 'dd/MM/yyyy HH:mm:ss')}</span>;
		}
		return '';
	}

	function renderUsuario(row) {
		if (row.nome && row.nome) {
			if (row.nome) {
				return `${row.nome} (${row.email})`;
			} else {
				return `${row.email}`;
			}
		} else {
			return 'Usuário não cadastrado no sistema';
		}
	}

	function renderOperacao(row) {
		return row.acao;
	}

	return (
		<Modal header="Histórico da manifestação" visible={visible} onHide={onHide}>
			<Form>
				<FormActions>
					<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
				</FormActions>
				<FormContent>
					<DataTable
						className="table"
						responsive
						value={registros}
						sortField={sortField}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column field="dataHora" header="Data" body={row => renderData(row)} sortable />
						<Column field="nome" header="Usuário" body={renderUsuario} />
						<Column field="acao" header="Ação" body={renderOperacao} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
				</FormContent>
			</Form>
		</Modal>
	);
}
