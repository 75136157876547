import { Button } from 'primereact/button';

import {
	buscarDisabledDeAcordoComAsPermissoes,
	buscarTitleBotaoDeAcordoComAsPermissoes,
} from 'Common/Autorizacao/ManipulacaoDeComponentes';

function ButtonAdicionarItem({
	id,
	label = 'Adicionar item',
	title,
	titlePersonalizado,
	podeInserir,
	podeEditar,
	estadoCadastro,
	disabled,
	hidden,
	onClick,
	icon = 'fa fa-plus',
	style,
	raised,
	rounded,
}) {
	const disabledFormatado = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled);
	const titleFormatado =
		titlePersonalizado ?? buscarTitleBotaoDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, title);

	return (
		<Button
			label={label}
			id={id}
			type="button"
			color="success"
			icon={icon}
			disabled={disabledFormatado}
			title={titleFormatado}
			hidden={hidden}
			raised={raised}
			rounded={rounded}
			style={{
				background: 'none',
				border: 'none',
				fontWeight: 'bold',
				boxShadow: 'none',
				color: '#006095',
				padding: '8px 0px',
				margin: '5px',
				...style,
			}}
			onClick={onClick}
		/>
	);
}

export { ButtonAdicionarItem };
