import { Field, useFormikContext } from 'formik';

import { Card, Dropdown, Grid, If, InputField, InputMask } from 'components';

import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';
import { useContextMDFe } from 'views/transporte/MDFe/Context';

function Ciot() {
	const { errors, values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, isSituacaoFinalMDFe } = useContextMDFe();

	function onChangeTipoPessoa(e) {
		setFieldValue('ciot.tipoPessoa', e.value);

		if (e.value === TIPO_PESSOA.JURIDICA) {
			setFieldValue('ciot.cpf', null);
		} else if (e.value === TIPO_PESSOA.FISICA) {
			setFieldValue('ciot.cnpj', null);
		} else {
			setFieldValue('ciot.cpf', null);
			setFieldValue('ciot.cnpj', null);
		}
	}

	return (
		<Card style={{ marginBottom: '8px' }}>
			<Grid>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					label="Número CIOT"
					name="ciot.numeroCiot"
					size={12}
					errors={errors.ciot?.numeroCiot}
					touched
					useFormErrors={false}
					onChange={(e) => setFieldValue('ciot.numeroCiot', e.target.value)}
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={Dropdown}
					label="Tipo pessoa"
					name="ciot.tipoPessoa"
					showClear={false}
					onChange={(e) => onChangeTipoPessoa(e)}
					options={[
						{ label: 'Física', value: 'FISICA' },
						{ label: 'Jurídica', value: 'JURIDICA' },
					]}
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<If test={values.ciot?.tipoPessoa === TIPO_PESSOA.JURIDICA}>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputMask}
						mask="00.000.000/0000-00"
						placeholder="  .   .   /    -  "
						label="CNPJ"
						name="ciot.cnpj"
						errors={errors.ciot?.cnpj}
						onChange={(e) => setFieldValue('ciot.cnpj', e.target.value)}
						disabled={isSituacaoFinalMDFe}
						{...informacoesPermissoes}
					/>
				</If>
				<If test={values.ciot?.tipoPessoa === TIPO_PESSOA.FISICA}>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputMask}
						mask="000.000.000-00"
						placeholder="   .   .   -  "
						label="CPF "
						name="ciot.cpf"
						errors={errors.ciot?.cpf}
						onChange={(e) => setFieldValue('ciot.cpf', e.target.value)}
						disabled={isSituacaoFinalMDFe}
						{...informacoesPermissoes}
					/>
				</If>
			</Grid>
		</Card>
	);
}

export { Ciot };
