import { Col, Grid, InputMoney, InputSelectPercentualOrValor, tipos as TIPOS_DESCONTO } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextOrdemServico } from '../../../Context';
import {
	calcularAcessoriasProdutos,
	calcularDescontoProdutos,
	calcularDescontoServicos,
	calcularFreteProdutos,
	calcularSeguroProdutos,
	hasProduto,
	hasServico,
} from '../../../Util/funtions';

export function TotalizadoresAdicionais() {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, totais, setTotais, disabledFields, setAtualizarTotais } = useContextOrdemServico();

	const disabledFieldsProdutos = disabledFields || !hasProduto(values.produtos);
	const disabledFieldsServicos = disabledFields || !hasServico(values.servicos);

	function handleChangeDescontoServico(tipo, value, percentual) {
		if (value !== totais.totalServicos?.desconto) {
			const desconto = {
				tipo: tipo,
				total: tipo === TIPOS_DESCONTO.PERCENTUAL ? percentual : value,
			};
			const calculatedData = calcularDescontoServicos({ ...values, totais }, desconto);

			setFieldValue('servicos', calculatedData.servicos);
			setTotais((state) => ({
				...state,
				totalServicos: { ...state.totalServicos, desconto: calculatedData.totalDesconto },
			}));
			setAtualizarTotais(true);
		}
	}

	async function handleChangeFreteProdutos(event) {
		if (event.target.value !== totais.totalProdutos?.frete) {
			const calculatedData = await calcularFreteProdutos({ ...values, totais }, event.target.value);

			setFieldValue('produtos', calculatedData.produtos);

			setTotais((state) => ({ ...state, totalProdutos: { ...state.totalProdutos, frete: calculatedData.totalFrete } }));
			setAtualizarTotais(true);
		}
	}

	async function handleChangeSeguroProdutos(event) {
		if (event.target.value !== totais.totalProdutos?.seguro) {
			const calculatedData = await calcularSeguroProdutos({ ...values, totais }, event.target.value);

			setFieldValue('produtos', calculatedData.produtos);

			setTotais((state) => ({
				...state,
				totalProdutos: { ...state.totalProdutos, seguro: calculatedData.totalSeguro },
			}));
			setAtualizarTotais(true);
		}
	}

	async function handleChangeDescontoProdutos(tipo, value, percentual) {
		if (value !== totais.totalProdutos?.desconto) {
			const desconto = {
				tipo: tipo,
				total: tipo === TIPOS_DESCONTO.PERCENTUAL ? percentual : value,
			};
			const calculatedData = await calcularDescontoProdutos({ ...values, totais }, desconto);

			setFieldValue('produtos', calculatedData.produtos);

			setTotais((state) => ({
				...state,
				totalProdutos: { ...state.totalProdutos, desconto: calculatedData.totalDesconto },
			}));
			setAtualizarTotais(true);
		}
	}

	async function handleChangeAcessoriasProdutos(tipo, value, percentual) {
		if (value !== totais.totalProdutos?.acessorias) {
			const acessorias = {
				tipo: tipo,
				total: tipo === TIPOS_DESCONTO.PERCENTUAL ? percentual : value,
			};
			const calculatedData = await calcularAcessoriasProdutos({ ...values, totais }, acessorias);
			setFieldValue('produtos', calculatedData.produtos);

			setTotais((state) => ({
				...state,
				totalProdutos: { ...state.totalProdutos, acessorias: calculatedData.totalAcessorias },
			}));
			setAtualizarTotais(true);
		}
	}

	return (
		<Col style={{ paddingTop: '0px' }}>
			<Grid>
				<Field
					sm="6"
					md="3"
					lg="3"
					xl="3"
					component={InputSelectPercentualOrValor}
					size={14}
					label="Desconto serviço"
					name="desconto"
					value={totais.totalServicos?.desconto}
					onBlur={(tipo, value, percentual) => handleChangeDescontoServico(tipo, value, percentual)}
					placeholder="0,00"
					allowNegative={false}
					disabled={disabledFieldsServicos}
					informacoesPermissoes={informacoesPermissoes}
				/>
				<Field
					sm="6"
					md="3"
					lg="3"
					xl="3"
					component={InputMoney}
					size={14}
					label="Frete produto"
					name="frete"
					value={totais.totalProdutos?.frete}
					onChange={handleChangeFreteProdutos}
					allowNegative={false}
					disabled={disabledFieldsProdutos}
					{...informacoesPermissoes}
				/>
				<Field
					sm="6"
					md="3"
					lg="3"
					xl="3"
					component={InputMoney}
					size={14}
					label="Seguro produto"
					name="seguro"
					value={totais.totalProdutos?.seguro}
					onChange={handleChangeSeguroProdutos}
					allowNegative={false}
					disabled={disabledFieldsProdutos}
					{...informacoesPermissoes}
				/>
				<Field
					sm="6"
					md="3"
					lg="3"
					xl="3"
					component={InputSelectPercentualOrValor}
					label="Acessórias produto"
					name="acessorias"
					value={totais.totalProdutos?.acessorias}
					onBlur={(tipo, value, percentual) => handleChangeAcessoriasProdutos(tipo, value, percentual)}
					size={14}
					placeholder="0,00"
					allowNegative={false}
					disabled={disabledFieldsProdutos}
					informacoesPermissoes={informacoesPermissoes}
				/>
				<Field
					sm="6"
					md="3"
					lg="3"
					xl="3"
					component={InputSelectPercentualOrValor}
					size={14}
					label="Desconto produto"
					name="totalProdutos.desconto"
					value={totais.totalProdutos?.desconto}
					onBlur={(tipo, value, percentual) => handleChangeDescontoProdutos(tipo, value, percentual)}
					allowNegative={false}
					disabled={disabledFieldsProdutos}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</Grid>
		</Col>
	);
}
