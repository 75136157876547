import { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { formatCPF } from '@brazilian-utils/brazilian-utils';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { keyFilterConsultaRsql } from 'Common';
import {
	ButtonEditarTable,
	ButtonExcluirTable,
	Grid,
	If,
	NenhumRegistroEncontrado,
	SingleSelectCondutor,
} from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import ModalCondutores from './components/ModalCondutores';

import '../../../../Styles/index.css';

function Condutores({ disabled }) {
	const { values, setFieldValue } = useFormikContext();
	const { isMobile, informacoesPermissoes, visibleModalCondutores, setVisibleModalCondutores } = useContextMDFe();
	const [sortOrder, setSortOrder] = useState(1);
	const [sortField, setSortField] = useState('nome');
	const [condutor, setCondutor] = useState(null);
	const [condutorParaModal, setCondutorParaModal] = useState(null);

	function handleClickExcluir(row) {
		const newValues = values.condutores.filter((condutor) => condutor.condutor.id !== row.condutor.id);
		setFieldValue('condutores', newValues);
	}

	function handleAdicionarCondutor(condutor) {
		setFieldValue('condutores', [
			...values.condutores,
			{
				id: null,
				cpf: condutor?.registro?.cpf,
				idRegistro: condutor?.registro?.id,
				nome: condutor?.registro?.nome,
				condutor: condutor?.registro,
			},
		]);
		setCondutor(null);
	}

	function handleEditarCondutor(condutorEditado) {
		const index = values.condutores.findIndex((condutor) => condutor.idRegistro === condutorEditado.idRegistro);
		const newValues = [...values.condutores];
		newValues[index] = condutorEditado;
		setFieldValue('condutores', newValues);
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable
					disabled={disabled}
					onClick={() => {
						!disabled && setVisibleModalCondutores(true);
						setCondutorParaModal(row);
					}}
				/>
				<ButtonExcluirTable disabled={disabled} onClick={() => handleClickExcluir(row)} />
			</div>
		);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return <span style={{ fontStyle: 'italic', opacity: '0.7' }}>{field}</span>;
		}
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-all',
					paddingLeft: isMobile ? '15%' : '0',
				}}
			>
				{field}
			</span>
		);
	}
	return (
		<>
			<Grid style={{ display: 'block', padding: '0px' }}>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={SingleSelectCondutor}
					optionsRemover={values.condutores}
					onChange={handleAdicionarCondutor}
					name="condutor"
					value={condutor}
					keyfilter={keyFilterConsultaRsql}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<div className="cards-tabs-mdfe">
					<DataTable
						className="table tables-tabs-mdfe"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={values.condutores}
						sortField={sortField}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							field="nome"
							header="Nome"
							body={(row) => aplicarEstiloInativa(row, row.nome)}
							style={{ width: '60%' }}
							sortable
						/>
						<Column
							field="cpf"
							header="CPF"
							body={(row) => aplicarEstiloInativa(row, formatCPF(row.cpf))}
							sortable
							style={{ width: '40%' }}
						/>
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '5%' }} />
					</DataTable>
				</div>
			</Grid>
			<If test={visibleModalCondutores}>
				<ModalCondutores
					visible={visibleModalCondutores}
					onClose={() => setVisibleModalCondutores(false)}
					onSave={(values) => handleEditarCondutor(values)}
					initialValue={condutorParaModal}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</If>
		</>
	);
}

export { Condutores };
