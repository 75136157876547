import { useState } from 'react';
import ListagemRelatorios from '../../../components/ListagemRelatorios';
import { modulos } from '../../../Common/Constantes/autorizacao';
import { If } from '../../../components';
import { ModalMdfePeriodo } from './components/ModalMdfePeriodo';
import { ModalMdfeNaoEncerrada } from './components/ModalMdfeNaoEncerrada';

function RelatoriosTransporte() {
	const [exibirModalMDFEPorPeriodo, setExibirModalMDFEPorPeriodo] = useState(false);
	const [exibirModalMDFENaoEncerrada, setExibirModalMDFENaoEncerrada] = useState(false);

	const metodosImprimir = [
		{
			nome: 'MDFE_POR_PERIODO',
			onImprimir: () => {
				setExibirModalMDFEPorPeriodo(true);
			},
		},
		{
			nome: 'MDFE_NAO_ENCERRADA',
			onImprimir: () => {
				setExibirModalMDFENaoEncerrada(true);
			},
		},
	];

	return (
		<>
			<ListagemRelatorios
				header="Relatórios de transporte"
				modulo={modulos.TRANSPORTE}
				metodosImprimir={metodosImprimir}
			/>
			<If test={exibirModalMDFEPorPeriodo}>
				<ModalMdfePeriodo onHide={() => setExibirModalMDFEPorPeriodo(false)} visible={exibirModalMDFEPorPeriodo} />
			</If>
			<If test={exibirModalMDFENaoEncerrada}>
				<ModalMdfeNaoEncerrada
					onHide={() => setExibirModalMDFENaoEncerrada(false)}
					visible={exibirModalMDFENaoEncerrada}
				/>
			</If>
		</>
	);
}

export { RelatoriosTransporte };
