import { SingleSelectConta, SingleSelectFormaPagamento } from 'components';
import Col from 'components/Col';
import Grid from 'components/Grid';
import InputDate from 'components/input/InputDate';
import InputDouble from 'components/input/InputDouble';
import { Field, useFormikContext } from 'formik';
import { ActionsParcela } from './components/ActionsParcela';

function FieldsParcela({
	value,
	values,
	indexParcela,
	urls,
	disabledFields,
	isMobile,
	isTablet,
	isLessHd,
	informacoesPermissoes,
	onChangeVencimentoParcelas,
	onChangeValorParcelas,
	onBlurValorParcelas,
	onChangeFormaPagamento,
	onChangeConta,
	onChangeDescricao,
	onBlurDescricaoParcelas,
	onRemoveParcela,
	setWasEdited,
	isModal,
	indexPagamento,
}) {
	const { errors } = useFormikContext();

	const showLabels = indexParcela === 0 || isMobile || isTablet;

	function handleChangeVencimento(e) {
		onChangeVencimentoParcelas(e.target.value, indexParcela);
		if (isModal) {
			setWasEdited(true);
		}
	}

	function handleChangeValor(e) {
		onChangeValorParcelas(e.target.value, indexParcela);
		if (isModal) {
			setWasEdited(true);
		}
	}

	function handleBlurValor() {
		onBlurValorParcelas(value.valor, indexParcela);
	}

	function handleBlurDescricao() {
		onBlurDescricaoParcelas(value.valor);
	}

	function handleChangeFormaPagamento(e) {
		onChangeFormaPagamento(e, indexParcela);
		if (isModal) {
			setWasEdited(true);
		}
	}

	function handleChangeConta(e) {
		onChangeConta(e, indexParcela);
		if (isModal) {
			setWasEdited(true);
		}
	}

	function getErrors(field) {
		if (
			errors &&
			errors[indexPagamento] &&
			errors[indexPagamento]?.parcelas &&
			errors[indexPagamento]?.parcelas[indexParcela] &&
			errors[indexPagamento]?.parcelas[indexParcela][field]
		) {
			return errors[indexPagamento]?.parcelas[indexParcela][field];
		}
		return null;
	}

	return (
		<Grid
			style={{
				margin: '0px -15px',
				background: indexParcela % 2 === 0 ? null : '#f1f1f1',
				paddingTop: '7px',
				width: '100%',
			}}
			className="pagamento-list-hover"
		>
			<Col col="6" sm="6" md="6" lg={isLessHd ? '3' : '2'} xl="2" style={{ padding: '0px 5px' }}>
				<Grid>
					<Col
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							fontSize: '16px',
							fontWeight: 'bold',
							width: '30px',
							padding: '2px 0px 0px 10px',
							margin: showLabels ? '10px 0px 0px 0px' : '0px',
						}}
					>
						{`${value.sequencial}ª `}
					</Col>
					<InputDate
						colStyle={{
							width: 'calc(100% - 30px)',
							paddingLeft: isMobile ? '0px' : undefined,
							paddingRight: isMobile ? '0px' : undefined,
						}}
						label={showLabels ? 'Vencimento' : ''}
						helpMessage={showLabels ? 'Data do vencimento desta parcela' : ''}
						name="vencimento"
						obrigatorio={showLabels}
						onChange={handleChangeVencimento}
						value={value.vencimento}
						disabled={disabledFields}
						touched
						errors={getErrors('vencimento')}
						useFormErrors={false}
						{...informacoesPermissoes}
					/>
				</Grid>
			</Col>
			<InputDouble
				colStyle={{
					padding: '0px 5px',
				}}
				col="6"
				sm="6"
				md="6"
				lg="2"
				xl="2"
				label={showLabels ? 'Valor' : ''}
				helpMessage={showLabels && !isMobile ? 'Valor da parcela' : ''}
				name="valor"
				size={13}
				obrigatorio={showLabels}
				onChange={handleChangeValor}
				onBlur={handleBlurValor}
				value={value.valor}
				placeholder="R$ 0,00"
				prefix="R$ "
				allowNegative={false}
				disabled={disabledFields}
				touched
				errors={getErrors('valor')}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				colStyle={{ padding: '0px 5px' }}
				component={SingleSelectFormaPagamento}
				label={showLabels ? 'Forma de pagamento' : null}
				name="formaPagamento"
				value={value.formaPagamento}
				onChange={handleChangeFormaPagamento}
				url={urls.formaPagamento}
				isClearable={false}
				disabled={disabledFields}
				obrigatorio={showLabels}
				touched
				errors={getErrors('formaPagamento')}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg={isLessHd ? '3' : '4'}
				xl="4"
				colStyle={{ padding: '0px 5px' }}
				component={SingleSelectConta}
				label={showLabels ? 'Conta' : null}
				name="conta"
				value={value.conta}
				onChange={handleChangeConta}
				url={urls.conta}
				isClearable={false}
				disabled={disabledFields}
				obrigatorio={showLabels}
				touched
				errors={getErrors('conta')}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<ActionsParcela
				value={value}
				values={values}
				indexParcela={indexParcela}
				disabledFields={disabledFields}
				informacoesPermissoes={informacoesPermissoes}
				showLabels={showLabels}
				onChangeDescricao={onChangeDescricao}
				handleBlurDescricao={handleBlurDescricao}
				onRemoveParcela={onRemoveParcela}
				setWasEdited={setWasEdited}
				isModal={isModal}
				isMobile={isMobile}
				isTablet={isTablet}
			/>
		</Grid>
	);
}

export { FieldsParcela };
