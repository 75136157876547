const TIPO_APLICACAO = {
	DOCS_DIGITAIS: 'DOCS_DIGITAIS',
	MSYS_GESTOR: 'MSYS_GESTOR',
	GESTOR_MEI: 'GESTOR_MEI',
};

const optionsTipoAplicacao = [
	{ label: 'DOCs Digitais', value: TIPO_APLICACAO.DOCS_DIGITAIS },
	{ label: 'Msys Gestor', value: TIPO_APLICACAO.MSYS_GESTOR },	
	{ label: 'Gestor MEI', value: TIPO_APLICACAO.GESTOR_MEI },
];

const TIPO_NOTA_VERSAO = {
	NOVIDADE: 'NOVIDADE',
	ALTERACAO: 'ALTERACAO',
	CORRECAO: 'CORRECAO',
};

const optionsTipoNotaVersao = [
	{ label: 'Alteração', value: TIPO_NOTA_VERSAO.ALTERACAO },
	{ label: 'Correção', value: TIPO_NOTA_VERSAO.CORRECAO },
	{ label: 'Novidade', value: TIPO_NOTA_VERSAO.NOVIDADE },
];

export { TIPO_APLICACAO, optionsTipoAplicacao, TIPO_NOTA_VERSAO, optionsTipoNotaVersao };
