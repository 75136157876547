import { services } from '../../../../Common/Constantes/api';
import { post, exibirToast } from '../../../../Common/Requisicoes';
import { ToastTypes } from '../../../../components/Toast';

export async function asyncCriarConta(form, onSuccess, onError) {
	post(
		`${services.GESTOR}/v1/empresa/registrar`,
		form,
		null,
		exibirToast(onSuccess, 'Sua conta foi criada com sucesso! Verifique seu email para realizar a confirmação.', ToastTypes.SUCCESS, 20),
		onError
	);
}
