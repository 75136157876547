import { del, get, post } from '../../../../Common/Requisicoes';
import { services } from '../../../../Common/Constantes/api';

export async function asyncGetBoletos(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGerarBoletos(convenioId, contaReceberIds, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/boletos/convenios/${convenioId}/emitir`,
		contaReceberIds,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncPostImprimirBoletos(boletos, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/boletos/imprimir`,
		boletos,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncPostEnviarBoletosPorEmail(data, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/boletos/enviar_email`, data, null, onSuccess, onError);
}

export async function asyncPostConsultaBoletos(boletos, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/boletos/consultar`, boletos, null, onSuccess, onError, false);
}

export async function asyncGetContaFavoritaReceita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/boletos/relacoes/contas?query=favoritaReceita=="true";situacao=="ATIVA";emitirBoletos=="true"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncPostDescartarBoletos(boletos, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/boletos/descartar`, boletos, null, onSuccess, onError, false);
}
