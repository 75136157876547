import { Switch, withRouter } from 'react-router-dom';
import { permissoes, recursos } from 'Common';
import PrivateRoute from 'components/Route';

import { MDFe } from 'views/transporte/MDFe';
import { MDFeForm } from 'views/transporte/MDFe/Form';
import MDFeProvider from 'views/transporte/MDFe/Context';
import { RelatoriosTransporte } from 'views/transporte/Relatorios';
import PaginaEmConstrucao from 'views/Util/PaginaEmConstrucao';

export const pathTransporteRoutes = [
	'/ctes',
	'/ctes/cadastro',
	'/mdfes',
	'/mdfes/cadastro',
	'/mdfes/cadastro/:id',
	'/relatorios_transporte',
];

const textoCTeEmConstrucao = (
	<div>
		<div>
			Em breve, novo módulo de <b>Conhecimento de Transporte Eletrônico (CT-e)</b>. Você poderá emitir e controlar seus
			CT-es diretamente pelo sistema, garantindo mais praticidade e conformidade com as normas fiscais.
		</div>
	</div>
);

function TransporteRoutes() {
	return (
		<Switch>
			<PrivateRoute
				exact
				path="/ctes"
				render={() => <PaginaEmConstrucao text={textoCTeEmConstrucao} />}
				recurso={recursos.TRANSPORTE_MDFE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/ctes/cadastro', '/ctes/cadastro/:id']}
				render={() => <PaginaEmConstrucao text={textoCTeEmConstrucao} />}
				recurso={recursos.TRANSPORTE_MDFE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/mdfes"
				render={(props) => (
					<MDFeProvider>
						<MDFe {...props} />
					</MDFeProvider>
				)}
				recurso={recursos.TRANSPORTE_MDFE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/mdfes/cadastro', '/mdfes/cadastro/:id']}
				render={(props) => (
					<MDFeProvider {...props}>
						<MDFeForm {...props} />
					</MDFeProvider>
				)}
				recurso={recursos.TRANSPORTE_MDFE}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path={['/relatorios_transporte']}
				render={(props) => <RelatoriosTransporte {...props} />}
				recurso={recursos.TRANSPORTE_RELATORIOS}
				permissao={permissoes.VISUALIZAR}
			/>
		</Switch>
	);
}

export default withRouter(TransporteRoutes);
