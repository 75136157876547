import { useEffect, useState } from 'react';
import { withFormik } from 'formik';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../components/Button/ButtonCancelar';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../components/Button/ButtonSalvar';
import ButtonExcluir from '../../../../../../components/Button/ButtonExcluir';
import Form from '../../../../../../components/Form';
import FormContent from '../../../../../../components/FormContent';
import FormActions from '../../../../../../components/FormActions';
import Grid from '../../../../../../components/Grid';
import AutoProgressBar from '../../../../../../components/Loading/AutoProgressBar';
import Modal from '../../../../../../components/Modal';
import FieldsModalTributacaoEstadualUf from '../FieldsModalTributacaoEstadualUf';
import FieldsModalIcms from '../FieldsModalIcms';
import FieldsModalIcmsSt from '../FieldsModalIcmsSt';
import FieldsModalDiferimento from '../FieldsModalDiferimento';
import FieldsModalFCPobreza from '../FieldsModalFCPobreza';
import If from '../../../../../../components/If';
import FieldsModalIcmsStRetido from '../FieldsModalIcmsStRetido';
import { validarFormulario } from '../../../../../Util';
import { mensagensDeValidacao } from '../../../../../../Common/Constantes/mensagens';
import FieldsModalIcmsMonofasicoRetido from '../FieldsModalIcmsMonofasicoRetido';
import FieldsContribuicaoBeneficiario from '../FieldsContribuicaoBeneficiario';

const initialValue = {
  id: null,
  estado: null,
  cfop: null,
  cstCsosn: null,
  //ICMS
  icmsBase: 100,
  icmsPercentual: 0,
  //ST
  icmsStBase: 100,
  icmsStPercentual: 0,
  icmsStRetidoBase: 100,
  icmsStRetidoPercentual: 0,
  modalidadeCalculoSt: 'MARGEM_VALOR_AGREGADO',
  mvaPauta: 0,
  //monofasico
  icmsMonofasicoRetido: 0,
  //diferimento e FCP
  diferimento: 0,
  fundoCombatePobreza: 0,

  codigoBeneficiario: null,
};

function ModalTributacaoEstadualUfView(props) {
  const { visible, onHide, header, dirty, validacaoNovaTributacao, setFieldValue, values } = props;

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('modalTributacaoUf')?.getElementsByTagName('input')[0]?.focus();
    }, 500);
  }, []);

  function onChangeCstCsosn() {
    setFieldValue('imcsBase', 100);
    setFieldValue('icmsPercentual', 0);
    setFieldValue('icmsStBase', 100);
    setFieldValue('icmsStPercentual', 0);
    setFieldValue('icmsStRetidoBase', 100);
    setFieldValue('icmsStRetidoPercentual', 0);
    setFieldValue('icmsMonofasicoRetido', 0);
    setFieldValue('modalidadeCalculoSt', 'MARGEM_VALOR_AGREGADO');
    setFieldValue('mvaPauta', 0);
    setFieldValue('diferimento', 0);
    setFieldValue('fundoCombatePobreza', 0);
    setFieldValue('codigoBeneficiario', null);
  }

  function onCancelar() {
    if (dirty) {
      props.handleReset();
      setTimeout(() => {
        document.getElementById('modalTributacaoUf')?.getElementsByTagName('input')[0]?.focus();
      }, 500);
    } else {
      onHide();
    }
  }

  async function onSalvar() {
    props.handleSubmit();
    if (await validarFormulario(props)) {
      if (props.tributacaoUf) {
        props.atualizar(values);
      } else {
        props.salvar(values);
        props.handleReset();
      }
    }
  }

  function onExcluir() {
    props.excluirTributacao(values);
  }

  function validarCstCsosn(cstCsosn, arrayCodigos) {
    let existeValor = '';
    arrayCodigos.forEach(codigo => {
      codigo === cstCsosn ? (existeValor = cstCsosn) : '';
    });

    if (existeValor.length) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Modal header={header} visible={visible} onHide={onHide}>
      <AutoProgressBar />
      <Form>
        <FormActions>
          <ButtonCancelar
            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
            onClick={onCancelar}
          />
          <ButtonSalvar estadoBotao={estadosBotaoSalvar.SALVAR} disabled={!dirty} onClick={onSalvar} />

          <ButtonExcluir onClick={onExcluir} disabled={validacaoNovaTributacao || dirty} />
        </FormActions>
        <FormContent>
          <Grid>
            <FieldsModalTributacaoEstadualUf
              setFieldValue={setFieldValue}
              onChangeCstCsosn={onChangeCstCsosn}
              values={values}
              listaTributacoesUf={props.listaTributacoesUf}
              validacaoNovaTributacao={validacaoNovaTributacao}
            />

            <If test={validarCstCsosn(values.cstCsosn?.registro.codigo, ['00', '10', '20', '51', '70', '90', '900'])}>
              <FieldsModalIcms
                setFieldValue={setFieldValue}
                values={values}
                validacaoNovaTributacao={validacaoNovaTributacao}
              />
            </If>

            <If
              test={validarCstCsosn(values.cstCsosn?.registro.codigo, [
                '10',
                '30',
                '70',
                '90',
                '201',
                '202',
                '203',
                '900',
              ])}
            >
              <FieldsModalIcmsSt
                setFieldValue={setFieldValue}
                values={values}
                validacaoNovaTributacao={validacaoNovaTributacao}
              />
            </If>
            <If test={validarCstCsosn(values.cstCsosn?.registro.codigo, ['60', '500', '900'])}>
              <FieldsModalIcmsStRetido
                setFieldValue={setFieldValue}
                values={values}
                validacaoNovaTributacao={validacaoNovaTributacao}
              />
            </If>

            <If test={validarCstCsosn(values.cstCsosn?.registro.codigo, ['51', '90', '900'])}>
              <FieldsModalDiferimento
                setFieldValue={setFieldValue}
                values={values}
                validacaoNovaTributacao={validacaoNovaTributacao}
              />
            </If>

            <If test={validarCstCsosn(values.cstCsosn?.registro.codigo, ['61'])}>
              <FieldsModalIcmsMonofasicoRetido
                setFieldValue={setFieldValue}
                values={values}
                validacaoNovaTributacao={validacaoNovaTributacao}
              />
            </If>

            <If test={validarCstCsosn(values.cstCsosn?.registro.codigo, ['00', '10', '20', '51', '70', '90', '900'])}>
              <FieldsModalFCPobreza
                setFieldValue={setFieldValue}
                values={values}
                validacaoNovaTributacao={validacaoNovaTributacao}
              />
            </If>
            <If
              test={validarCstCsosn(values.cstCsosn?.registro.codigo, [             
                '20',
                '30',
                '40',
                '41',
                '50',
                '51',
                '70',
              ])}
            >
              <FieldsContribuicaoBeneficiario
                setFieldValue={setFieldValue}
                values={values}
                validacaoNovaTributacao={validacaoNovaTributacao}
              />
            </If>
          </Grid>
        </FormContent>
      </Form>
    </Modal>
  );
}

const ModalTributacaoEstadualUf = withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: false,

  mapPropsToValues(props) {
    if (props.tributacaoUf?.id || props.tributacaoUf?.idTemporario) {
      return props.tributacaoUf;
    } else {
      return initialValue;
    }
  },

  validate(values, props) {
    let errors = {};
    let estadoJaSelecionado = false;

    if (!values.estado) {
      errors.estado = mensagensDeValidacao.OBRIGATORIO;
    }

    if (!values.cfop) {
      errors.cfop = mensagensDeValidacao.OBRIGATORIO;
    }

    if (!values.cstCsosn) {
      errors.cstCsosn = mensagensDeValidacao.OBRIGATORIO;
    }

    props.listaTributacoesUf?.forEach(tributacao => {
      tributacao.estado?.value === values.estado?.value ? (estadoJaSelecionado = true) : null;
    });
    if (estadoJaSelecionado && values.estado?.value !== props.tributacaoUf?.estado?.value) {
      errors.estado = 'UF Destino já utilizada.';
    }

    return errors;
  },
  handleSubmit: () => {},
})(ModalTributacaoEstadualUfView);

export default ModalTributacaoEstadualUf;
