import { services } from '../../../../Common/Constantes/api';
import axios from 'axios';

export async function asyncConfirmEmail(token, onSuccess, onError) {
	const obj = {
		token: token,
	};
	return await axios
		.post(services.GESTOR + '/v1/credenciais/ativar_email', obj)
		.then(e => {
			onSuccess && onSuccess(e);
		})
		.catch(e => {
			onError && onError(e);
		});
}
