import { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { GoBookmark } from 'react-icons/go';

import { estadosCadastro, removerElemento, copiarObjeto } from '../../../../../../../Common';

import {
  ButtonExcluirTable,
  ButtonNovo,
  Modal,
  If,
  ButtonEditarTable,
  NenhumRegistroEncontrado,
  Grid,
} from '../../../../../../../components';

import PessoaEnderecoForm from './Form';

function PessoaEndereco(props) {
  const { estadoCadastro, appendTo } = props;

  const [registroSelecionado, setRegistroSelecionado] = useState(null);
  const [modalFormVisible, setModalFormVisible] = useState(false);

  const desabilitarCampos = estadoCadastro === estadosCadastro.EDICAO && !props.podeEditar;

  function novo() {
    if (props.setHideBackground) {
      props.setHideBackground(true);
    }

    setRegistroSelecionado(null);
    setModalFormVisible(true);
  }

  function editar(row) {
    if (props.setHideBackground) {
      props.setHideBackground(true);
    }
    setRegistroSelecionado(row);
    setModalFormVisible(true);
  }

  function renderOpcoes(row) {
    return (
      <div>
        <ButtonEditarTable onClick={() => editar(row)} />
        <ButtonExcluirTable onClick={() => excluirRegistro(row)} podeExcluir={!desabilitarCampos} />
      </div>
    );
  }

  function hideModal() {
    if (props.setHideBackground) {
      props.setHideBackground(false);
    }
    setRegistroSelecionado(null);
    setModalFormVisible(false);
  }

  function renderLogradouro(row) {
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {row.logradouro}
        {row.favorito ? (
          <span>
            <GoBookmark size={22} color="#fbc02d" title="Endereço favorito" />
          </span>
        ) : null}
      </span>
    );
  }

  function atualizarRegistro(dadosFormulario) {
    const endereco = copiarObjeto(props.value);
    const result =
      endereco &&
      endereco.map(item => {
        if (item.idTemporario || item.id) {
          if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
            return dadosFormulario;
          } else if (item.id && item.id === dadosFormulario.id) {
            return dadosFormulario;
          }
          if (dadosFormulario.favorito) {
            item.favorito = false;
          }
          return item;
        } else {
          return dadosFormulario;
        }
      });

    setRegistroSelecionado(dadosFormulario);
    props.onChange(result);
  }

  function inserirRegistro(dadosFormulario) {
    let enderecos = copiarObjeto(props.value);
    props.onChange(mergeEnderecoIntoEnderecos(dadosFormulario, enderecos));
    setRegistroSelecionado(dadosFormulario);
  }

  function mergeEnderecoIntoEnderecos(endereco, enderecos) {
    if (endereco.favorito) {
      enderecos.map(tempEndereco => {
        return (tempEndereco.favorito = false);
      });
    }
    return [...enderecos, endereco];
  }

  function excluirRegistro(dadosFormulario) {
    props.onChange(removerElemento(props.value, dadosFormulario));
    setRegistroSelecionado(null);
  }

  function marcarComoFavorito() {
    const enderecos = copiarObjeto(props.value);
    let existemFavoritos = false;

    enderecos?.forEach(endereco => {
      if (endereco.favorito) {
        existemFavoritos = true;
      }
    });

    return !existemFavoritos;
  }

  return (
    <>
      <Modal visible={modalFormVisible} header="Cadastro de endereço" onHide={hideModal} container={appendTo}>
        <If test={modalFormVisible}>
          <PessoaEnderecoForm
            registroSelecionado={registroSelecionado}
            onNovoClick={() => setRegistroSelecionado(null)}
            inserirRegistro={inserirRegistro}
            atualizarRegistro={atualizarRegistro}
            excluirRegistro={excluirRegistro}
            marcarComoFavorito={marcarComoFavorito}
            onHide={hideModal}
            podeEditar={!desabilitarCampos}
            podeExcluir={!desabilitarCampos}
            appendTo={appendTo}
          />
        </If>
      </Modal>
      <Grid style={{ padding: '0.5rem 0rem' }}>
        <ButtonNovo
          label="Novo endereço"
          value={props.values?.enderecos}
          enableShortCut={false}
          onClick={novo}
          podeInserir={!desabilitarCampos}
        />
      </Grid>
      <DataTable
        className="table"
        responsive
        value={props.value}
        emptyMessage={<NenhumRegistroEncontrado message="Nenhum endereço encontrado" />}
      >
        <Column field="logradouro" body={renderLogradouro} header="Logradouro" sortable={true} />
        <Column field="bairro" header="Bairro" sortable={true} />
        <Column field="numero" header="Número" sortable={true} />
        <Column
          field="municipio"
          header="Município"
          body={e => (e.municipio ? e.municipio.nome + ' - ' + e.municipio.estadoSigla : '')}
          sortable={true}
        />
        <Column body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
      </DataTable>
    </>
  );
}

export default PessoaEndereco;
