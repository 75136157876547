import { post, services } from 'Common';

export async function gerarQuitacaoEmLote(titulos, isRecebimento, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/${isRecebimento ? 'contas_receber/recebimento_em_lote' : 'contas_pagar/pagamento_em_lote'}`,
		titulos,
		null,
		onSuccess,
		onError,
		null,
		false
	);
}
