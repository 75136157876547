import { confirm } from 'components';

export async function confirmarExclusao(onOk) {
	confirm(
		'Confirmação',
		'Deseja excluir o registro?',
		async () => {
			await onOk();
		},
		() => {},
		'Sim',
		'Não'
	);
}
