import React from 'react'

export default function NoInternetBar() {
    return (
        <>
            <div style={{ width: '100%', height: '20px', background: '#f44336', position: 'fixed', zIndex: 1000 }}>
                <p style={{ margin: '0', color: '#fff', fontWeight: 'bold', fontSize: '13px', textAlign: 'center' }}>Parece que você está sem internet :(</p>
            </div>
            <div style={{ width: '100%', height: '20px' }}/>
        </>
    )
}