function converterNotaDeVersaoParaFormulario(notasDeVersaoApi) {
	return {
		id: notasDeVersaoApi.id,
		versao: notasDeVersaoApi.versao,
		aplicacao: notasDeVersaoApi.aplicacao,
		titulos: converterTituloNotaDeVersaoParaFormulario(notasDeVersaoApi.titulos),
	};
}

function converterTituloNotaDeVersaoParaFormulario(titulos) {
	const titulosFormulario = titulos?.map(titulo => {
		return {
			id: titulo.id,
			titulo: titulo.titulo,
			itens: converterItensNotaDeVersaoParaFormulario(titulo?.itens),
		};
	});

	return titulosFormulario;
}

function converterItensNotaDeVersaoParaFormulario(itens) {
	const itensFormulario = itens?.map(item => {
		return {
			id: item.id,
			texto: item.texto,
			tipo: item.tipo,
		};
	});

	return itensFormulario;
}

function converterNotaDeVersaoParaApi(notasDeVersao) {
	return {
		id: notasDeVersao.id,
		versao: notasDeVersao.versao,
		aplicacao: notasDeVersao.aplicacao,
		titulos: converterTituloNotaDeVersaoParaApi(notasDeVersao.titulos),
	};
}

function converterTituloNotaDeVersaoParaApi(titulos) {
	const titulosApi = titulos?.map(titulo => {
		return {
			id: titulo.id,
			titulo: titulo.titulo,
			itens: converterItensNotaDeVersaoParaApi(titulo?.itens),
		};
	});

	return titulosApi;
}

function converterItensNotaDeVersaoParaApi(itens) {
	const itensApi = itens?.map(item => {
		return {
			id: item.id,
			texto: item.texto,
			tipo: item.tipo,
		};
	});

	return itensApi;
}

export { converterNotaDeVersaoParaFormulario, converterNotaDeVersaoParaApi };
