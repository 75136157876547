export const tutorialStepsNotaFiscalServico = [
	{
		target: 'body',
		content: `Este é o cadastro de nota fiscal de serviço`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-nfse-cliente',
		content: 'Nesta seção é possível informar os campos do cabeçalho da NFS-e',
		placement: 'top',
	},
	{
		target: '.step-nfse-servicos',
		content: 'E nesta área você pode gerenciar os servicos desta NFS-e',
	},
	{
		target: '.step-nfse-totalizadores',
		content:
			'Por fim, nesta aba estão os totalizadores da NFS-e, além disso, nas demais abas, podem ser alterados as informações de pagamentos e informações complementares.',
	},
];
