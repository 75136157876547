import { tipoCampos } from '../../../../components/PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancado = [
	{ label: 'NCM (sem pontuação)', name: 'codigoNcm.codigo', type: tipoCampos.STRING },
	{ label: 'Extensão', name: 'extensao', type: tipoCampos.STRING },
	{ label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Ativo', value: 'ATIVO' },
			{ label: 'Inativo', value: 'INATIVO' },
		],
	},
];
