import { formatarMonetario } from 'Common';
import { If } from 'components';
import TituloCard from 'components/TituloCard';
import { ProgressBar } from 'primereact/progressbar';
import { Fragment } from 'react';
import NenhumaInformacaoEncontrada from 'views/PaginaInicial/Dashboard/components/NenhumaInformacaoEncontrada';
import { TRANSPORTES_CORES } from '../../../../Util/constantes';

const CARD_STYLE = {
	padding: '7px',
	borderRadius: '5px',
	backgroundColor: 'white',
	boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
	minHeight: '100%',
};

const CARD_CONTENT = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
};

const LABEL_STYLE = {
	fontSize: '15px',
	fontWeight: '400',
	lineHeight: '1.5',
	color: '#000000de',
};

const VALUE_STYLE = {
	display: 'flex',
	justifyContent: 'flex-end',
	fontSize: '15px',
	fontWeight: '600',
	color: TRANSPORTES_CORES.verde,
	padding: '0px',
	marginLeft: '5px',
};

const HR_STYLE = {
	width: '100%',
	border: 'none',
	height: '1px',
	margin: '0',
	flexShrink: '0',
	backgroundColor: '#0000001f',
	marginBottom: '7px',
};

const TYPE_VALUE = {
	PRICE: 'PRICE',
	INTEGER: 'INTEGER',
};

function CardList({
	title,
	helpMessage,
	data,
	fieldLabel,
	montarLabel,
	fieldValue,
	typeValue = TYPE_VALUE.PRICE,
	visibleButtonVerMais,
	onClickVerMais,
	page,
	loading,
}) {
	const emptyList = data?.length === 0;

	function handleClickVerMais() {
		onClickVerMais(page + 1);
	}

	function getLabel(item) {
		if (typeof montarLabel === 'function') {
			return montarLabel(item);
		} else {
			return item[fieldLabel];
		}
	}

	return (
		<div style={CARD_STYLE}>
			<TituloCard title={title} helpMessage={helpMessage} />
			<If test={loading}>
				<div style={CARD_CONTENT}>
					<ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />
				</div>
			</If>

			<If test={emptyList}>
				<div style={CARD_CONTENT}>
					<NenhumaInformacaoEncontrada />
				</div>
			</If>

			<If test={!loading && !emptyList}>
				<div
					className="dashboard-scroll"
					style={{
						padding: '15px 15px 0px 15px',
						maxHeight: '280px',
						overflow: 'auto',
						marginTop: '10px',
						maxWidth: '100%',
					}}
				>
					{data &&
						data.map((item, index) => (
							<Fragment key={item.id}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										paddingBottom: '7px',
									}}
								>
									<span style={LABEL_STYLE}>
										<b>{index + 1}º</b> {getLabel(item)}
									</span>
									<span style={VALUE_STYLE}>
										{typeValue === TYPE_VALUE.PRICE ? formatarMonetario(item[fieldValue]) : item[fieldValue]}
									</span>
								</div>
								<hr style={HR_STYLE} />
							</Fragment>
						))}
					<If test={visibleButtonVerMais}>
						<div style={{ textAlign: 'center', padding: '0.5rem 0px' }}>
							<span onClick={handleClickVerMais} style={{ color: `${TRANSPORTES_CORES.azul}`, cursor: 'pointer' }}>
								Ver mais
							</span>
						</div>
					</If>
				</div>
			</If>
		</div>
	);
}

export { CardList, TYPE_VALUE };
