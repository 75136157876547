import { get, services } from 'Common';

export async function buscarTributacaoServico(servicoId, municipioId, operacaoFiscalId, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/tributacoes/vendas/servicos?servicoId=${servicoId}&municipioId=${municipioId}&operacaoFiscalId=${operacaoFiscalId}`,
		null,
		onSuccess,
		onError
	);
}
