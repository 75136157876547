import { useState } from 'react';
import { connect } from 'react-redux';
import { format, isEqual, isPast, parseISO } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FaCheckCircle, FaExclamationCircle, FaMinusCircle } from 'react-icons/fa';

import { formatarMonetario, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { Button, NenhumRegistroEncontrado, Paginacao } from 'components';

import { CONTA_RECEBER_STATUS } from 'views/financas/components/ModalReparcelamentoTitulos/Utils/constantes';
import { Financas } from '../../../../Util/constantes';

import './Styles/index.css';

const styleColumnOverflowElipsis = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

const styleDestakFiend = {
	display: 'inline-flex',
	justifyContent: 'center',
};

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

const dataAtual = new Date().setHours(0, 0, 0, 0);

function TabelaContasReceber({
	registros,
	totalRecords,
	rows,
	page,
	sortField,
	sortOrder,
	setSortField,
	setSortOrder,
	onEditItem,
	onEfetuarRecebimentoItem,
	onRemoveItem,
	onPageChange,
	isMobile,
	documentosSelecionados,
	setDocumentosSelecionados,
}) {
	const [podeExcluirContasAReceber] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EXCLUIR)
	);
	const [podeEditarContasAReceber] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EDITAR)
	);
	const [podeVisualizarVenda] = useState(usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR));

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function renderStatus(el) {
		const dataVencimento = parseISO(el.vencimento);

		if (
			el.status !== CONTA_RECEBER_STATUS.RECEBIDA &&
			el.status !== CONTA_RECEBER_STATUS.REPARCELADA &&
			isPast(dataVencimento) &&
			!isEqual(dataVencimento, dataAtual)
		) {
			return <FaExclamationCircle title="Vencida" size={18} color={Financas.cores.vermelho} />;
		} else {
			if (el.status === CONTA_RECEBER_STATUS.NAO_RECEBIDA || el.status === 'PARCIALMENTE_RECEBIDA') {
				return <FaCheckCircle title="Não recebida" size={18} color={Financas.cores.cinza} />;
			} else if (el.status === CONTA_RECEBER_STATUS.RECEBIDA) {
				return <FaCheckCircle title="Recebida" size={18} color={Financas.cores.verde} />;
			} else if (el.status === CONTA_RECEBER_STATUS.REPARCELADA) {
				return <FaMinusCircle title="Reparcelada" size={18} color={Financas.cores.amarelo} />;
			}

			return null;
		}
	}

	function renderDataVencimento(el) {
		const dataVencimento = parseISO(el.vencimento);
		const dataVencimentoTexto = format(dataVencimento, 'dd/MM/yyyy');

		if (isPast(dataVencimento) && !isEqual(dataVencimento, dataAtual)) {
			return <span title={`Conta a receber vencida em: ${dataVencimentoTexto}`}>{dataVencimentoTexto}</span>;
		}

		return <span title={`Data de vencimento da conta a receber: ${dataVencimentoTexto}`}>{dataVencimentoTexto}</span>;
	}

	function renderIdentificadorField(el) {
		return (
			<span
				title={el.descricao}
				style={{
					wordBreak: 'break-word',
					display: 'flex',
					maxWidth: isMobile ? '70%' : '100%',
					textAlign: isMobile ? 'end' : 'start',
					fontWeight: '500',
					gap: 5,
				}}
			>
				{el.identificador}
				{renderStatus(el)}
			</span>
		);
	}

	function renderCategoriaField(el) {
		return (
			<span title={el.categoriaNome} style={{ color: el.categoriaAparencia, fontWeight: 'bold' }}>
				{el.categoriaNome}
			</span>
		);
	}

	function renderNomeField(el) {
		return (
			<span
				title={el.pessoaNome}
				style={{
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: isMobile ? '70%' : '100%',
					textAlign: isMobile ? 'end' : 'start',
				}}
			>
				{el.pessoaNome ?? '-'}
			</span>
		);
	}

	function renderValorField(el) {
		const valor = formatarMonetario(el.valor);

		return <span title={valor}>{valor}</span>;
	}

	function renderRecebidoAReceberField(el) {
		const valorAReceber = formatarMonetario(el.valorAReceber);

		if (el.valorAReceber > 0) {
			return (
				<span title={valorAReceber} style={{ ...styleDestakFiend, fontWeight: 'bold' }}>
					{valorAReceber}
				</span>
			);
		}
		return '-';
	}

	function renderAcoesField(el) {
		let titleEditar = 'Editar';
		let titleEfetuarRecebimento = 'Efetuar recebimento';
		let titleExcluir = 'Excluir';
		let disableBtnEditar = false;
		let disableBtnEfetuarRecebimento = false;
		let disableBtnExcluir = false;

		if (el.origem === 'VENDA' && !podeVisualizarVenda) {
			disableBtnEditar = true;
			titleEditar = 'Você não possui permissão de editar vendas';
		}

		if (!podeEditarContasAReceber) {
			titleEfetuarRecebimento = 'Você não possuir permissão para executar essa ação';
			disableBtnEfetuarRecebimento = true;
		}

		if (el.origem === 'VENDA') {
			titleExcluir = 'Não é possível excluir uma conta a receber de uma venda';
			disableBtnExcluir = true;
		} else if (!podeExcluirContasAReceber) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		} else if (el.temBoleto) {
			titleExcluir = 'Conta a receber com boleto bancário gerado';
			disableBtnExcluir = true;
		} else if (el.temPix) {
			titleExcluir = 'Conta a receber com pix cobrança gerado';
			disableBtnExcluir = true;
		} else if (el.status === CONTA_RECEBER_STATUS.REPARCELADA) {
			titleExcluir = 'Não é possível excluir um conta a receber reparcelado';
			disableBtnExcluir = true;
		}

		return (
			<div>
				<Button
					style={styleButton}
					className="p-button p-button-secondary"
					icon="fa fa-check"
					hidden={el.status === 'RECEBIDA' || el.status === 'REPARCELADA' || el.temBoleto || el.temPix}
					title={titleEfetuarRecebimento}
					disabled={disableBtnEfetuarRecebimento}
					onClick={() => onEfetuarRecebimentoItem(el)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-secondary"
					icon="pi pi-qrcode"
					hidden={!el.temPix || el.status === 'RECEBIDA'}
					title="Detalhes pix cobrança"
					disabled={disableBtnEfetuarRecebimento}
					onClick={() => onEfetuarRecebimentoItem(el)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={titleEditar}
					disabled={disableBtnEditar}
					onClick={() => onEditItem(el)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => onRemoveItem(el)}
				/>
			</div>
		);
	}

	return (
		<div style={{ width: !isMobile ? '100%' : '99%' }}>
			<DataTable
				className="table"
				value={registros}
				responsive
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={(e) => atualizarOrdenacao(e)}
				selectionMode="checkbox"
				selection={documentosSelecionados}
				onSelectionChange={(e) => setDocumentosSelecionados(e.value)}
				emptyMessage={<NenhumRegistroEncontrado message="Nenhuma conta a receber encontrada" />}
			>
				<Column header={isMobile ? 'Seleção' : ''} selectionMode="multiple" style={{ width: '45px' }} />
				<Column
					header="Identificador"
					field="identificador"
					body={renderIdentificadorField}
					sortable
					bodyStyle={{ ...styleColumnOverflowElipsis, width: '120px' }}
				/>
				<Column
					header="Vencimento"
					field="vencimento"
					body={renderDataVencimento}
					sortable
					bodyStyle={{ ...styleColumnOverflowElipsis, width: '120px' }}
				/>
				<Column
					header="Pessoa"
					field="pessoaNome"
					body={renderNomeField}
					sortable
					bodyStyle={{ ...styleColumnOverflowElipsis, minWidth: '170px' }}
				/>
				<Column
					header="Categoria"
					field="categoriaNome"
					body={renderCategoriaField}
					sortable
					bodyStyle={{ ...styleColumnOverflowElipsis, minWidth: '140px' }}
				/>
				<Column
					header="Valor"
					field="valor"
					sortable
					body={renderValorField}
					bodyStyle={{ ...styleColumnOverflowElipsis, width: '120px' }}
				/>
				<Column
					header="A receber"
					field="valorAReceber"
					body={renderRecebidoAReceberField}
					bodyStyle={{ ...styleColumnOverflowElipsis, width: '120px' }}
				/>
				<Column
					header="Ações"
					body={renderAcoesField}
					style={{ textAlign: !isMobile ? 'end' : null }}
					bodyStyle={{ ...styleColumnOverflowElipsis, width: '130px', textAlign: 'end' }}
				/>
			</DataTable>
			<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
		</div>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(TabelaContasReceber);
