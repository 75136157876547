import propTypes from 'prop-types';
import Col from '../Col';

export default function DescricaoFiltroAvancado(props) {
	const { texto } = props;

	const style = {
		padding: '0px 10px',
		fontSize: '11px',
		color: 'red',
		...props.style,
	};

	function renderTexto() {
		return (
			texto &&
			texto.split(`\\n`).map((item, i) => {
				if (item.trim().length > 0) {
					return <li key={i}>{item}</li>;
				}

				return null;
			})
		);
	}

	return (
		<Col style={style}>
			<ul style={{ margin: '0px', paddingLeft: '15px' }}>{renderTexto()}</ul>
		</Col>
	);
}

DescricaoFiltroAvancado.propTypes = {
	/** Texto do componente */
	texto: propTypes.string,
};
