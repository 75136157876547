import { post, exibirToast, get } from '../../../../../../../Common/Requisicoes/index';
import { services } from '../../../../../../../Common/Constantes/api';

export async function asyncCompartilharDocumento(idDocumento, values, onSuccess) {
	var dados = {
		...values,
		email: values.emailLabel,
		destinatariosCopia:
			values.enviarCopia && values.destinatariosCopia ? values.destinatariosCopia.split(',') : null,
	};

	await post(
		`${services.GESTOR}/v1/documentos/${idDocumento}/enviar_para_assinatura`,
		dados,
		null,
		exibirToast(onSuccess, 'Documento enviado para assinatura ')
	);
}

export async function asyncCompartilharDocumentoUnicoSignatario(idDocumento, idSignatario, values, onSuccess) {
	var dados = {
		...values,
		email: values.emailLabel,
		destinatariosCopia: values.enviarCopia ? values.destinatariosCopia.split(',') : null,
	};

	await post(
		`${services.GESTOR}/v1/documentos/${idDocumento}/signatarios/${idSignatario}/reenviar_email`,
		dados,
		null,
		exibirToast(onSuccess, 'E-mail enviado com sucesso ')
	);
}

export async function asyncGetCorpoEmailPadrao(url, onSuccess, onError) {
	await get(url, null, onSuccess, onError);
}

export async function asyncEnviarWhatsAppUnicoSignatario(idDocumento, idSignatario, onSuccess) {
	await post(
		`${services.GESTOR}/v1/documentos/${idDocumento}/signatarios/${idSignatario}/reenviar_whatsapp`,
		null,
		null,
		exibirToast(onSuccess, 'WhatsApp enviado com sucesso ')
	);
}
