import { copiarObjeto } from 'Common';
import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar } from 'components';
import { validarFormulario } from 'views/Util';
import { converterFilialToApi } from '../../../../Utils/filialConverter';

function ActionButtons(props) {
  const {
    values,
    setTouched,
    handleReset,
    handleSubmit,
    onHide,
    onSave,
    dirty,
    filialSelecionada,
    setFilialSelecionada,
    informacoesPermissoes,
  } = props;

  const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;

  async function salvar() {
    await handleSubmit();

    if (await validarFormulario(props)) {
      setTouched('email');
      if (filialSelecionada) {
        values.id = filialSelecionada.id;
        await onSave(converterFilialToApi(values));
      } else {
        values.id = null;
        await onSave(converterFilialToApi(values));
      }

      setFilialSelecionada(null);
    }
  }

  function cancelar() {
    if (dirty) {
      if (filialSelecionada) {
        handleReset({ values: copiarObjeto(filialSelecionada) })
      } else {
        handleReset()
        setFilialSelecionada(null);
      }
    } else {
      onHide();
      setFilialSelecionada(null);
    }

  }
  return (
    <>
      <ButtonCancelar onClick={() => cancelar()} estadoBotao={estadoBotaoCancelar} {...informacoesPermissoes} />
      <ButtonSalvar onClick={() => salvar(values.email)} disabled={!props.dirty} {...informacoesPermissoes} />
    </>
  );
}

export default ActionButtons;
