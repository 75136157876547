import { get, post, services } from "Common";

export async function asyncAlterarFilialConectada(
  idFilial,
  onSuccess,
  onError
) {
  return post(
    `${services.GESTOR}/v1/filiais/${idFilial}/conectar`,
    null,
    null,
    onSuccess,
    onError,
    false,
    false
  );
}

export async function asyncGetOrganizacaoFiliaisSetores(
  organizacaoId,
  onSuccess,
  onError
) {
  return get(
    `${services.GESTOR}/v1/credenciais/organizacao_filiais_setores?organizacaoId=${organizacaoId}`,
    null,
    onSuccess,
    onError
  );
}

export async function asyncAlterarOrganizacao(data, onSuccess, onError) {
  return post(
    `${services.GESTOR}/v1/credenciais/alterar_organizacao`,
    data,
    null,
    onSuccess,
    onError,
    false,
    false
  );
}
