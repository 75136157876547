import { formatarMonetario, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import Button from 'components/Button';
import Col from 'components/Col';
import Grid from 'components/Grid';
import If from 'components/If';
import NenhumRegistroEncontrado from 'components/NenhumRegistroEncontrado';
import { createEstornarPagamento } from 'components/Pagamentos/Requests';
import { FINANCEIRO_STATUS_CONTA_PAGAR } from 'components/Pagamentos/Util/constantes';
import { converterPagamentoToForm } from 'components/Pagamentos/Util/converter';
import { format, parseISO } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { ModalPagamento } from './components/ModalPagamento';

function TabelaContaPagar({ idOrigem, isMobile, parcelas, informacoesPermissoes, urls, color }) {
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [visibleModalPagamento, setVisibleModalPagamento] = useState(false);
	const [listParcelas, setListParcelas] = useState(parcelas);
	const [totalRestante, setTotalRestante] = useState(calcularTotalRestante());
	const [totalRecebido, setTotalRecebido] = useState(calcularTotalRecebido());

	const [podeEditarContaPagar] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EDITAR));

	useEffect(() => {
		setTotalRestante(calcularTotalRestante());
		setTotalRecebido(calcularTotalRecebido());
	}, [listParcelas]);

	function calcularTotalRestante() {
		return (
			listParcelas.reduce(
				(accumulator, currentValue) =>
					Number(parseFloat(accumulator) + parseFloat(currentValue.valorRestante)).toFixed(2),
				0
			) ?? 0
		);
	}

	function calcularTotalRecebido() {
		return (
			listParcelas.reduce(
				(accumulator, currentValue) =>
					Number(parseFloat(accumulator) + parseFloat(currentValue.valor - currentValue.valorRestante)).toFixed(2),
				0
			) ?? 0
		);
	}

	function renderIdentificadorField(element) {
		return (
			<span
				title={element.financeiroIdentificador}
				style={{ maxHeight: '60px', overflow: 'hidden', display: 'inline-flex', fontWeight: '500' }}
			>
				{element.financeiroIdentificador}
			</span>
		);
	}

	function renderVencimentoField(element) {
		return <span>{format(parseISO(element.vencimento), 'dd/MM/yyyy')}</span>;
	}

	function renderValorField(element) {
		const valorFormatado = formatarMonetario(element.valor);
		return <span title={valorFormatado}>{valorFormatado}</span>;
	}

	function renderValorAReceberField(element) {
		const valorFormatado = formatarMonetario(element.valorRestante);
		return <span title={valorFormatado}>{valorFormatado}</span>;
	}

	function renderFormaPagamentoField(element) {
		const label = element.formaPagamento?.label;
		return (
			<span title={label} style={{ maxHeight: '60px', overflow: 'hidden', display: 'inline-flex' }}>
				{label}
			</span>
		);
	}

	function renderAcoesField(element) {
		const paga = Boolean(element.financeiroStatus === FINANCEIRO_STATUS_CONTA_PAGAR.PAGA);
		const naoPaga = Boolean(element.financeiroStatus === FINANCEIRO_STATUS_CONTA_PAGAR.NAO_PAGA);
		const parcialmentePaga = Boolean(element.financeiroStatus === FINANCEIRO_STATUS_CONTA_PAGAR.PARCIALMENTE_PAGA);

		const disableBotoes = !informacoesPermissoes.podeEditar || !podeEditarContaPagar;

		return (
			<span style={!isMobile ? { display: 'flex', justifyContent: 'flex-end' } : null}>
				<If test={paga || parcialmentePaga}>
					<Button
						className="p-button p-button-secondary"
						style={{ borderRadius: '20px', marginRight: '5px', padding: '5px 0px' }}
						disabled={disableBotoes}
						title={
							disableBotoes
								? 'Você não possui permissão ou o módulo habilitado para executar essa ação'
								: 'Estornar pagamentos'
						}
						onClick={() => handleClickEstornar(element)}
						icon="pi pi-replay"
					/>
				</If>
				<If test={paga}>
					<Button
						className="p-button p-button-success"
						style={{ borderRadius: '20px', width: '105px', padding: '4px 7px' }}
						disabled
						label="Pago"
						icon="pi pi-check-circle"
					/>
				</If>
				<If test={naoPaga || parcialmentePaga}>
					<Button
						id="btn-contas-receber-receber"
						className="p-button p-button-secondary"
						style={{ borderRadius: '20px', width: '105px' }}
						disabled={disableBotoes}
						title={
							disableBotoes
								? 'Você não possui permissão ou o módulo habilitado para executar essa ação'
								: 'Efetuar o pagamento'
						}
						onClick={() => handleClickEfetuarBaixa(element)}
						icon="pi pi-check"
						label="Pagar"
					/>
				</If>
			</span>
		);
	}

	function handleClickEfetuarBaixa(e) {
		setRegistroSelecionado({ ...e });
		setVisibleModalPagamento(true);
	}

	async function handleClickEstornar(e) {
		await createEstornarPagamento(urls.urlBase, idOrigem, e.id, () => {
			setListParcelas((state) =>
				state.map((parcela) => {
					if (parcela.id === e.id) {
						return {
							...parcela,
							valor: e.valor,
							valorRestante: e.valor,
							financeiroStatus: FINANCEIRO_STATUS_CONTA_PAGAR.NAO_PAGA,
						};
					}
					return parcela;
				})
			);
			setRegistroSelecionado(null);
		});
	}

	function onHideModalPagamento(values) {
		if (values) {
			setListParcelas((state) =>
				state.map((parcela) => {
					if (parcela.id === registroSelecionado.id) {
						return converterPagamentoToForm(registroSelecionado, values);
					}
					return parcela;
				})
			);
			setVisibleModalPagamento(false);
			setRegistroSelecionado(null);
		} else {
			setVisibleModalPagamento(false);
			setRegistroSelecionado(null);
		}
	}

	return (
		<>
			<Grid style={{ marginTop: '8px' }}>
				<Col>
					<DataTable
						style={{ width: '100%' }}
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						value={listParcelas}
						responsive
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							header={isMobile ? 'Iden.' : 'Identificador'}
							field="financeiroIdentificador"
							sortable
							body={renderIdentificadorField}
							style={{ wordBreak: 'break-all', width: '12%' }}
						/>
						<Column header="Data" field="vencimento" sortable body={renderVencimentoField} style={{ width: '110px' }} />
						<Column
							header="Valor"
							field="valor"
							sortable
							body={renderValorField}
							style={{ width: '105px', textOverflow: 'ellipsis', overflow: 'hidden' }}
						/>
						<Column
							header={isMobile ? 'A pag.' : 'A pagar'}
							field="valorRestante"
							sortable
							body={renderValorAReceberField}
							style={{ width: '120px', textOverflow: 'ellipsis', overflow: 'hidden' }}
						/>
						<Column
							header={isMobile ? 'Forma pgto.' : 'Forma de pagamento'}
							field="forma"
							sortable
							body={renderFormaPagamentoField}
							style={{ wordBreak: 'break-all' }}
						/>
						<Column header={isMobile ? 'Ações' : ''} body={renderAcoesField} />
					</DataTable>
				</Col>
				<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Col col="3" style={{ textAlign: 'end' }}>
						<div>
							{'A pagar: '}
							<b style={{ color }}>{formatarMonetario(totalRestante)}</b>
						</div>
						<div>
							{'Recebido: '}
							<b style={{ color }}>{formatarMonetario(totalRecebido)}</b>
						</div>
					</Col>
				</Col>
			</Grid>
			<If test={visibleModalPagamento}>
				<ModalPagamento
					visible={visibleModalPagamento}
					onHide={onHideModalPagamento}
					parcela={registroSelecionado}
					informacoesPermissoes={informacoesPermissoes}
					urls={urls}
					idOrigem={idOrigem}
				/>
			</If>
		</>
	);
}

export { TabelaContaPagar };
