import { FINANCEIRO_STATUS_CONTA_PAGAR, FINANCEIRO_STATUS_CONTA_RECEBER } from './constantes';

function converterRecebimentoToApi(values) {
	return {
		id: values.id,
		data: values.data,
		valor: values.valor,
		multa: values.multa,
		juros: values.juros,
		desconto: values.desconto,
		formaPagamento: values.formaPagamento ? { id: values.formaPagamento.value } : null,
		conta: values.conta ? { id: values.conta.value } : null,
		observacao: values.observacao,
	};
}

function calcularValorRestante(oldParcela, newParcela) {
	const resultado = oldParcela.valorRestante - newParcela.valor;

	if (resultado <= 0) {
		return 0;
	}

	return parseFloat(resultado).toFixed(2);
}

function converterRecebimentoToForm(oldParcela, newParcela) {
	return {
		...oldParcela,
		financeiroStatus:
			calcularValorRestante(oldParcela, newParcela) === 0
				? FINANCEIRO_STATUS_CONTA_RECEBER.RECEBIDA
				: FINANCEIRO_STATUS_CONTA_RECEBER.PARCIALMENTE_RECEBIDA,
		valorRestante: calcularValorRestante(oldParcela, newParcela),
	};
}

function converterPagamentoToForm(oldParcela, newParcela) {
	return {
		...oldParcela,
		financeiroStatus:
			calcularValorRestante(oldParcela, newParcela) === 0
				? FINANCEIRO_STATUS_CONTA_PAGAR.PAGA
				: FINANCEIRO_STATUS_CONTA_PAGAR.PARCIALMENTE_PAGA,
		valorRestante: calcularValorRestante(oldParcela, newParcela),
	};
}

function converterPagamentoToApi(values) {
	return {
		id: values.id,
		data: values.data,
		valor: values.valor,
		multa: values.multa,
		juros: values.juros,
		desconto: values.desconto,
		formaPagamento: values.formaPagamento ? { id: values.formaPagamento.value } : null,
		conta: values.conta ? { id: values.conta.value } : null,
		observacao: values.observacao,
	};
}

export { converterRecebimentoToApi, converterRecebimentoToForm, converterPagamentoToForm, converterPagamentoToApi };
