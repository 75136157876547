import { services } from '../../../../Common/Constantes/api';
import { del, get, post, put, exibirToast } from '../../../../Common/Requisicoes';

export async function asyncDeletePessoa(idPessoa, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/pessoas/${idPessoa}`,
		null,
		exibirToast(onSuccess, 'Pessoa excluída com sucesso'),
		onError
	);
}

export async function asyncGetPesquisaPessoas(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncCreatePessoa(pessoa, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/pessoas`,
		pessoa,
		null,
		exibirToast(onSuccess, 'Pessoa criada com sucesso'),
		onError
	);
}

export async function asyncUpdatePessoa(pessoa, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/pessoas/${pessoa.id}`,
		pessoa,
		null,
		exibirToast(onSuccess, 'Pessoa atualizada com sucesso'),
		onError
	);
}

export async function asyncGetPessoa(idPessoa, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/pessoas/${idPessoa}`, null, onSuccess, onError);
}

export async function asyncGetPessoaFisica(pessoa, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/pessoas?query=pessoaFisica.cpf=contains="*${pessoa}*"`, null, onSuccess, onError);
}

export async function asyncGetPessoaJuridica(pessoa, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/pessoas?query=pessoaJuridica.cnpj=contains="*${pessoa}*"`, null, onSuccess, onError);
}

export async function asyncGetPessoaEstrangeira(identificacao, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/pessoas?query=pessoaEstrangeira.identificacao==${identificacao}`,
		null,
		onSuccess,
		onError
	);
}

export async function asyncUploadImagemPessoa(imagem, idPessoa, onSuccess, onError, exibirLoading, tratarErros) {
	const formData = new FormData();
	formData.append('imagem', imagem);
	await post(
		`${services.GESTOR}/v1/pessoas/${idPessoa}/imagem`,
		formData,
		{ headers: { 'Content-Type': 'multipart/form-data' } },
		onSuccess,
		onError,
		exibirLoading,
		tratarErros
	);
}

export async function asyncDownloadImagemPessoa(idPessoa, onSuccess, onError, exibirLoading, tratarErros) {
	await get(
		`${services.GESTOR}/v1/pessoas/${idPessoa}/imagem`,
		{ responseType: 'blob' },
		onSuccess,
		onError,
		exibirLoading,
		tratarErros
	);
}

export async function asyncDeleteImagemPessoa(idPessoa, onSuccess, onError, exibirLoading, tratarErros) {
	await del(`${services.GESTOR}/v1/pessoas/${idPessoa}/imagem`, null, onSuccess, onError, exibirLoading, tratarErros);
}

export async function asyncGetConsultaPorCnpj(cnpj, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/pessoas/consultar_cnpj/${cnpj}`, null, onSuccess, onError);
}

export async function getAniversariantes(dataInicial, dataFinal, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/pessoas/aniversariantes?dataInicial=${dataInicial}&dataFinal=${dataFinal}`,
		null,
		onSuccess,
		onError
	);
}

export async function getEnderecoFavoritoPessoa(idPessoa, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/pessoas/resumo/${idPessoa}/endereco_pessoa`, null, onSuccess, onError);
}

export async function asyncImprimirFichaCadastral(idPessoa, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/pessoas/${idPessoa}/ficha_cadastral`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}
