import { useEffect, useState } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';

import {
  FormGroup,
  estadosBotaoSalvar,
  estadosBotaoCancelar,
  Grid,
  LayoutMenuSuperior,
  MenuSuperior,
  ButtonNovo,
  ButtonSalvar,
  ButtonCancelar,
  InputField,
  ButtonExcluir,
  Paper,
  Checkbox,
  InputMask,
  FormActions,
} from 'components';

import {
  mensagensDeValidacao,
  estadosCadastro,
  gerarUUID,
  buscarMascaraTelefone,
  manterApenasNumeros,
  copiarObjeto,
} from 'Common';

import { validarFormulario } from '../../../../../../../Util';
import { converterPessoaContatoParaApi, converterPessoaContatoParaFormulario } from './Util/pessoaContatoConverter';
import { helpPessoaContatoForm } from './Help';
import stringMask from 'string-mask';

const initialValue = {
  id: null,
  idTemporario: null,
  email: '',
  nome: '',
  cargo: '',
  telefone: '',
  observacao: '',
  favorito: false,
};

function PessoaContatoForm(props) {
  const { values } = props;
  const [inserindoNovoRegistro, setInserindoNovoRegistro] = useState(false);

  const informacoesPermissoes = {
    estadoCadastro: inserindoNovoRegistro ? estadosCadastro.INCLUSAO : estadosCadastro.EDICAO,
    podeInserir: props.podeEditar,
    podeEditar: props.podeEditar,
    podeExcluir: props.podeEditar,
  };

  useEffect(() => {
    setInserindoNovoRegistro(!props.registroSelecionado);

    setTimeout(() => {
      if (document.getElementById('pessoaInputContato')) {
        document.getElementById('pessoaInputContato').focus();
      }
    }, 500);
  }, []);

  function novo() {
    props.onNovoClick();
    props.resetForm({ values: copiarObjeto(initialValue) });
    setInserindoNovoRegistro(true);
  }

  function excluir() {
    props.excluirRegistro(props.registroSelecionado);
    props.onHide();
  }

  async function salvar() {
    props.handleSubmit();
    if (await validarFormulario(props)) {
      if (inserindoNovoRegistro) {
        criarRegistro();
      } else {
        atualizarRegistro();
      }
    }
  }

  function criarRegistro() {
    const dadosFormulario = converterPessoaContatoParaApi(props.values);
    props.inserirRegistro({ ...dadosFormulario, idTemporario: gerarUUID() });
    props.onHide();
  }

  function atualizarRegistro() {
    const dadosFormulario = converterPessoaContatoParaApi(props.values);
    props.atualizarRegistro(dadosFormulario);
    props.onHide();
  }

  function cancelar() {
    if (props.dirty) {
      props.handleReset();
    } else {
      props.onHide();
    }
  }

  return (
    <>
      <MenuSuperior isModal={true}>
        <FormActions>
          <ButtonCancelar
            {...informacoesPermissoes}
            estadoBotao={props.dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
            onClick={cancelar}
          />
          <ButtonSalvar
            estadoBotao={estadosBotaoSalvar.CONFIRMAR}
            {...informacoesPermissoes}
            disabled={!props.dirty}
            onClick={salvar}
          />
          <ButtonNovo onClick={novo} hidden={inserindoNovoRegistro} {...informacoesPermissoes} />
          <ButtonExcluir
            hidden={inserindoNovoRegistro}
            {...informacoesPermissoes}
            onClick={excluir}
            podeExcluir={props.podeExcluir}
          />
        </FormActions>
      </MenuSuperior>
      <LayoutMenuSuperior isModal={true}>
        <Paper dirty={props.dirty} {...informacoesPermissoes} childsOnly={true}>
          <FormGroup>
            <Grid>
              <Field
                sm="12"
                md="12"
                lg="12"
                xl="12"
                component={Checkbox}
                label="Contato favorito"
                title="Marque esta opção caso o contato seja o favorito"
                name="favorito"
                helpMessage={helpPessoaContatoForm.favorito}
                onChange={e => props.setFieldValue('favorito', !values.favorito)}
                checked={values.favorito}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="6"
                lg="4"
                xl="4"
                id="pessoaInputContato"
                component={InputField}
                type="text"
                label="Nome "
                obrigatorio
                name="nome"
                value={values.nome}
                helpMessage={helpPessoaContatoForm.nome}
                size={255}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="6"
                lg="4"
                xl="4"
                component={InputField}
                type="text"
                label="E-mail"
                name="email"
                value={values.email}
                helpMessage={helpPessoaContatoForm.email}
                size={255}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="6"
                lg="4"
                xl="4"
                component={InputField}
                type="text"
                label="Cargo"
                name="cargo"
                value={values.cargo}
                helpMessage={helpPessoaContatoForm.cargo}
                size={128}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="6"
                lg="4"
                xl="4"
                component={InputMask}
                mask={buscarMascaraTelefone(values.telefone)}
                placeholder={'(  )      -    '}
                label="Telefone"
                obrigatorio
                value={values.telefone}
                name="telefone"
                onChange={e => props.setFieldValue('telefone', e.target.value)}
                helpMessage={helpPessoaContatoForm.telefone}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="12"
                lg="12"
                xl="12"
                component={InputField}
                type="text"
                label="Observação"
                name="observacao"
                helpMessage={helpPessoaContatoForm.observacao}
                {...informacoesPermissoes}
              />
            </Grid>
          </FormGroup>
        </Paper>
      </LayoutMenuSuperior>
    </>
  );
}

PessoaContatoForm = withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,

  mapPropsToValues(props) {
    if (props.registroSelecionado) {
      return converterPessoaContatoParaFormulario(props.registroSelecionado);
    }
    return { ...initialValue, favorito: props.marcarComoFavorito() };
  },

  validate(values) {
    let errors = {};

    if (
      !new stringMask('(00) 00000-0000').validate(manterApenasNumeros(values.telefone)) &&
      !new stringMask('(00) 0000-0000').validate(manterApenasNumeros(values.telefone))
    ) {
      errors.telefone = mensagensDeValidacao.TELEFONE_INVALIDO;
    }
    return errors;
  },

  validationSchema: Yup.object().shape({
    nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    email: Yup.string().nullable().email(mensagensDeValidacao.EMAIL_INVALIDO),
  }),

  handleSubmit: () => {},
})(PessoaContatoForm);

export default PessoaContatoForm;
