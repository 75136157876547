import { get, post, put, services } from 'Common';
import { notify } from 'components';

export async function asyncGetDadosAtualizarIbptax(onSuccess, onError) {
  return await get(`${services.GESTOR}/v1/painel_help_desk/buscar_ibptax`, null, onSuccess, onError);
}

export async function asyncUpdateDadosAtualizarIbptax(dadosAtualizarIbptax, onSuccess, onError, mostrarToast = true) {
  return await put(
    `${services.GESTOR}/v1/painel_help_desk/atualizar_ibptax`,
    dadosAtualizarIbptax,
    null,
    e => {
      if (mostrarToast) {
        notify('IBPTax atualizado com sucesso.');
      }
      onSuccess && onSuccess(e);
    },
    onError
  );
}

export async function asyncAdicionarArquivosIbptax(arquivo, onSuccess, onError) {
  const formData = new FormData();
  formData.append('arquivo', arquivo);

  await post(
    `${services.GESTOR}/v1/painel_help_desk/atualizar_ibptax/arquivo`,
    formData,
    { headers: { 'content-type': 'multipart/form-data' } },
    onSuccess,
    onError
  );
}

export async function asyncProcessarIbptaxEmVigor(onSuccess, onError) {
  await post(`${services.GESTOR}/v1/painel_help_desk/atualizar_ibptax/processar_dados`, null, null, onSuccess, onError);
}

export async function asyncAdicionarArquivosAtualizarNcm(arquivo, onSuccess, onError) {
  const formData = new FormData();
  formData.append('arquivo', arquivo);

  await post(
    `${services.GESTOR}/v1/painel_help_desk/atualizar_ncms/arquivo`,
    formData,
    { headers: { 'content-type': 'multipart/form-data' } },
    onSuccess,
    onError
  );
}
