import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';
import { Field, useFormikContext, withFormik } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';

import {
	buscarDadosLoginLocalStorage,
	copiarObjeto,
	gerarUUID,
	manterApenasNumeros,
	mensagensDeValidacao,
} from 'Common';
import {
	ButtonExcluirTable,
	Card,
	Divider,
	Dropdown,
	FormActions,
	FormContent,
	Grid,
	If,
	InputField,
	InputMask,
	Modal,
	NenhumRegistroEncontrado,
	SingleSelectSeguradora,
} from 'components';

import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';
import { useContextMDFe } from 'views/transporte/MDFe/Context';
import {
	INITIAL_VALUE_SEGURADORA,
	MDFE_SEGURADORA_RESPONSAVEL,
	MDFE_SEGURADORA_RESPONSAVEL_OPTIONS,
} from 'views/transporte/MDFe/Form/Util/constantes';

import { InputFieldWithButton } from 'components/input/InputFieldWithButton';
import { ActionButtons } from './components/ActionButtons';

function ModalSeguradoraImpl({ visible, indexRegistro, setFieldValueMDFe, onCloseModal, seguradoras, isNovoRegistro }) {
	const { errors, values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, isSituacaoFinalMDFe } = useContextMDFe();
	const [averbacao, setAverbacao] = useState(null);

	const { filialConectada } = buscarDadosLoginLocalStorage();

	const whenEmitente = values.responsavel === MDFE_SEGURADORA_RESPONSAVEL.EMITENTE;

	function onChangeResponsavel(e) {
		setFieldValue('responsavel', e.value);

		if (e.value === MDFE_SEGURADORA_RESPONSAVEL.EMITENTE) {
			if (filialConectada.tipo === TIPO_PESSOA.JURIDICA) {
				setFieldValue('tipoPessoa', TIPO_PESSOA.JURIDICA);
				setFieldValue('cnpj', filialConectada.cnpj);
				setFieldValue('cpf', null);
			} else {
				setFieldValue('tipoPessoa', TIPO_PESSOA.FISICA);
				setFieldValue('cpf', filialConectada.cpf);
				setFieldValue('cnpj', null);
			}
			setFieldValue('seguradora', null);
			setFieldValue('apolice', null);
		} else {
			setFieldValue('cpf', null);
			setFieldValue('cnpj', null);
		}
	}

	function onChangeTipoPessoa(e) {
		setFieldValue('tipoPessoa', e.value);

		if (e.value === TIPO_PESSOA.JURIDICA) {
			setFieldValue('cpf', null);
		} else {
			setFieldValue('cnpj', null);
		}
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonExcluirTable onClick={() => handleClickExcluir(row)} disabled={isSituacaoFinalMDFe} />
			</div>
		);
	}

	function handleClickExcluir(row) {
		const newValues = values.averbacoes?.filter((averbacao) => {
			if (row.idTemporario) {
				return averbacao.idTemporario !== row.idTemporario;
			} else {
				return averbacao.id !== row.id;
			}
		});
		setFieldValue('averbacoes', newValues);
	}

	function handleAdicionarAverbacao() {
		if (averbacao) {
			const newAverbacoes = [
				...(copiarObjeto(values.averbacoes) ?? []),
				{ id: null, idTemporario: gerarUUID(), averbacao: averbacao },
			];
			setFieldValue('averbacoes', newAverbacoes);
			setAverbacao(null);
		}
	}

	function handleChangeSeguradora(e) {
		setFieldValue('seguradora', e);
		setFieldValue('apolice', e.registro?.apolice);
	}

	return (
		<Modal
			header={indexRegistro ? 'Cadastro de seguradora' : 'Dados da seguradora'}
			visible={visible}
			onHide={onCloseModal}
		>
			<FormActions>
				<ActionButtons
					setFieldValueMDFe={setFieldValueMDFe}
					indexRegistro={indexRegistro}
					onCloseModal={onCloseModal}
					seguradoras={seguradoras}
					isNovoRegistro={isNovoRegistro}
				/>
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="5"
						lg="5"
						xl="5"
						component={Dropdown}
						label="Responsável"
						name="responsavel"
						showClear={false}
						value={values.responsavel}
						onChange={(e) => {
							onChangeResponsavel(e);
						}}
						options={MDFE_SEGURADORA_RESPONSAVEL_OPTIONS}
						disabled={isSituacaoFinalMDFe}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="7"
						lg="7"
						xl="7"
						label="Seguradora"
						name="seguradora"
						component={SingleSelectSeguradora}
						value={values.seguradora}
						onChange={handleChangeSeguradora}
						url="/v1/mdfes/relacoes/seguradoras"
						errors={errors.seguradora}
						isClearable={false}
						obrigatorio={values.responsavel === MDFE_SEGURADORA_RESPONSAVEL.CONTRATANTE}
						disabled={isSituacaoFinalMDFe || values.responsavel === MDFE_SEGURADORA_RESPONSAVEL.EMITENTE}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						label="Tipo pessoa"
						name="tipoPessoa"
						showClear={false}
						onChange={(e) => onChangeTipoPessoa(e)}
						options={[
							{ label: 'Física', value: 'FISICA' },
							{ label: 'Jurídica', value: 'JURIDICA' },
						]}
						disabled={whenEmitente || isSituacaoFinalMDFe}
						{...informacoesPermissoes}
					/>
					<If test={values.tipoPessoa === TIPO_PESSOA.JURIDICA}>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputMask}
							mask="00.000.000/0000-00"
							placeholder="  .   .   /    -  "
							label="CNPJ responsável"
							name="cnpj"
							errors={errors.cnpj}
							obrigatorio={!whenEmitente}
							onChange={(e) => setFieldValue('cnpj', e.target.value)}
							disabled={whenEmitente || isSituacaoFinalMDFe}
							{...informacoesPermissoes}
						/>
					</If>
					<If test={values.tipoPessoa === TIPO_PESSOA.FISICA}>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputMask}
							mask="000.000.000-00"
							placeholder="   .   .   -  "
							label="CPF responsável"
							name="cpf"
							errors={errors.cpf}
							obrigatorio={!whenEmitente}
							onChange={(e) => setFieldValue('cpf', e.target.value)}
							disabled={whenEmitente || isSituacaoFinalMDFe}
							{...informacoesPermissoes}
						/>
					</If>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputField}
						label="Apólice"
						name="apolice"
						obrigatorio
						size={20}
						errors={errors.apolice}
						onChange={(e) => setFieldValue('apolice', e.target.value)}
						disabled={isSituacaoFinalMDFe}
						{...informacoesPermissoes}
					/>
				</Grid>
				<Divider label="Averbações" />
				<Card>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						component={InputFieldWithButton}
						onChange={(e) => setAverbacao(e.target.value)}
						onSubmit={handleAdicionarAverbacao}
						name="averbacao"
						value={averbacao}
						disabled={isSituacaoFinalMDFe}
						{...informacoesPermissoes}
					/>

					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={values?.averbacoes}
						emptyMessage={<NenhumRegistroEncontrado />}
						style={{
							paddingBottom: '16px',
							overflowY: 'auto',
							overflowX: 'hidden',
							maxHeight: '13.8rem',
						}}
					>
						<Column field="averbacao" header="Averbação" body={(row) => row.averbacao} sortable />
						<Column body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
					</DataTable>
				</Card>
			</FormContent>
		</Modal>
	);
}

const ModalSeguradora = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.seguradora) {
			return copiarObjeto(props.seguradora);
		}

		return INITIAL_VALUE_SEGURADORA;
	},

	validate(values) {
		const errors = {};

		if (values.responsavel === MDFE_SEGURADORA_RESPONSAVEL.CONTRATANTE) {
			if (!values.seguradora) {
				errors.seguradora = mensagensDeValidacao.OBRIGATORIO;
			}

			if (values.tipoPessoa === TIPO_PESSOA.JURIDICA) {
				if (!values.cnpj) {
					errors.cnpj = mensagensDeValidacao.OBRIGATORIO;
				} else if (!isValidCNPJ(manterApenasNumeros(values.cnpj))) {
					errors.cnpj = mensagensDeValidacao.CNPJ_INVALIDO;
				}
			} else if (!values.cpf) {
				errors.cpf = mensagensDeValidacao.OBRIGATORIO;
			} else if (!isValidCPF(manterApenasNumeros(values.cpf))) {
				errors.cpf = mensagensDeValidacao.CPF_INVALIDO;
			}
		}

		if (!values.apolice) {
			errors.apolice = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalSeguradoraImpl);

export { ModalSeguradora };
