import './index.css';

function ColorPicker({ disabled, value, onChange, style, ...props }) {
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<span>Cor da categoria</span>
			<input
				id="inputColor"
				type="color"
				disabled={disabled}
				value={value}
				onChange={onChange}
				{...props}
				style={{
					...style,
				}}
			/>
		</div>
	);
}

export default ColorPicker;
