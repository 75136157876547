import { useState } from 'react';
import { If, Modal } from 'components';
import { CondutoresForm } from '../Form';

function ModalCondutoresImpl({ onHide, visible, container, styleModal }) {
	const [hideBackground, setHideBackground] = useState(false);

	return (
		<Modal
			className={visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de condutor"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1200px', ...styleModal }}
			hideBackground={hideBackground}
			container={container}
		>
			<If test={visible}>
				<CondutoresForm hideModal={onHide} isModal setHideBackground={setHideBackground} appendTo={container} />
			</If>
		</Modal>
	);
}

export const ModalCondutores = ModalCondutoresImpl;
