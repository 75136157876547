import { useCallback, useEffect, useState } from 'react';
import { construirUrl, services } from '../../Common';
import { asyncBuscarPedidosParaImportacao } from './Requests';
import { converterPedidoApiParaLista } from './Util/Converter';
import ListaPedidos from './components/ListaPedidos';
import Grid from '../Grid';
import InputSearch from '../input/InputSearch';
import Modal from '../Modal';
import Paginacao from '../Paginacao';

function ModalPedidoImportacao(props) {
	const { moduloImportacao, visible, onHide, isMobile, isTablet, container } = props;

	const [valorPesquisa, setValorPesquisa] = useState('');
	const [registros, setRegistros] = useState(null);
	const [sortField, setSortField] = useState('numero');
	const [sortOrder, setSortOrder] = useState(-1);
	const [totalRecords, setTotalRecords] = useState(0);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState(10);

	const pesquisarPedidosNaApi = useCallback(async () => {
		const filtro = buscarFiltro();
		const url = construirUrl(
			`${services.GESTOR}/v1/pedidos_venda/importacao_pedidos`,
			filtro || '?',
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		asyncBuscarPedidosParaImportacao(url, ({ data: pedidos }) => {
			setRegistros(converterPedidoApiParaLista(pedidos.content));
			setTotalRecords(pedidos.totalElements);
		});
	});

	const pesquisarCallback = useCallback(e => {
		pesquisarPedidosNaApi();
	});

	useEffect(() => {
		pesquisarPedidosNaApi();
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [page, rows, sortOrder, sortField]);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	function pesquisar() {
		pesquisarCallback();
	}

	function buscarFiltro() {
		return `?filtro=${valorPesquisa}`;
	}

	function pageChanged(e) {
		setRows(e.rows);
		setPage(e.page);
	}

	return (
		<Modal
			header={isMobile || isTablet ? 'Pedidos de venda' : 'Pedidos de venda disponíveis para importação'}
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1100px' }}
			closeOnEsc
			showCloseIcon
			container={container}
		>
			<Grid style={{ justifyContent: 'center', marginTop:'16px' }}>
				<InputSearch
					sm="12"
					md="12"
					lg="6"
					xl="6"
					id="InputSearchImportarPedidos"
					name="pesquisa"
					onPesquisar={() => pesquisar()}
					value={valorPesquisa}
					onChange={value => setValorPesquisa(value)}
				/>
			</Grid>
			<ListaPedidos
				setSortField={setSortField}
				setSortOrder={setSortOrder}
				sortField={sortField}
				sortOrder={sortOrder}
				registros={registros}
				isMobile={isMobile}
				onHide={onHide}
				history={props.history}
				moduloImportacao={moduloImportacao}
			/>
			<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={e => pageChanged(e)} />
		</Modal>
	);
}

export default ModalPedidoImportacao;
