import { get } from '../../../../../../../../../Common/Requisicoes';
import { services } from '../../../../../../../../../Common/Constantes/api';

export async function asyncGetPessoa(idPessoa, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/documentos/relacoes/pessoas?query=id==${idPessoa}`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetPessoaEmails(idPessoa, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/documentos/relacoes/pessoas/${idPessoa}/emails`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetPessoaTelefones(idPessoa, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/documentos/relacoes/pessoas/${idPessoa}/telefones`,
		null,
		onSuccess,
		onError,
		false
	);
}
