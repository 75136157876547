import { useState } from 'react';
import { If, Modal } from 'components';
import { VendedoresForm } from '../Form';
import { TIPO_VENDEDORES } from '../Form/Utils/constantes';

export function ModalVendedores({ onHide, visible, container, styleModal, tipoVendedor }) {
	const [hideBackground, setHideBackground] = useState(false);

	function getTipoVendedorModal() {
		switch (tipoVendedor) {
			case TIPO_VENDEDORES.VENDEDOR:
				return TIPO_VENDEDORES.SUPERVISOR;
			case TIPO_VENDEDORES.SUPERVISOR:
				return TIPO_VENDEDORES.GERENTE;
			default:
				return null;
		}
	}

	return (
		<Modal
			className={visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de vendedor"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1200px', ...styleModal }}
			hideBackground={hideBackground}
			container={container}
		>
			<If test={visible}>
				<VendedoresForm
					hideModal={onHide}
					isModal
					setHideBackground={setHideBackground}
					appendTo={container}
					tipoVendedor={getTipoVendedorModal()}
				/>
			</If>
		</Modal>
	);
}
