import { keyFilterConsultaRsql, parseFloatNumber } from 'Common';
import { Dropdown, Grid, If, InputDouble, InputField, InputMask, SingleSelectEstado } from 'components';
import { Field, useFormikContext } from 'formik';
import { CARROCERIA_OPTIONS, RODADO_OPTIONS, SITUACAO_OPTIONS, TIPO_OPTIONS } from '../../../Util/constantes';

function FormVeiculosImpl({ informacoesPermissoes, isModal }) {
	const { values, setFieldValue } = useFormikContext();

	function onChangePlaca(event) {
		let placa = event;

		if (placa) {
			placa = placa?.toUpperCase();
		}
		setFieldValue('placa', placa);
	}

	return (
		<Grid className="step-veiculo-campos-principais">
			<Field
				id="input-placa"
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={InputMask}
				mask="aaa-0*00"
				obrigatorio
				label="Placa"
				name="placa"
				value={values.placa}
				onChange={(e) => onChangePlaca(e.target.value)}
				size={8}
				removerMascara={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={InputField}
				label="Renavam"
				obrigatorio
				keyfilter={keyFilterConsultaRsql}
				name="renavam"
				size={11}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={SingleSelectEstado}
				label="Estado"
				name="uf"
				onChange={(event) => setFieldValue('uf', event)}
				value={values.uf}
				keyfilter={keyFilterConsultaRsql}
				isClearable={false}
				obrigatorio
				placeholder="Selecione"
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={Dropdown}
				label="Tipo"
				onChange={(event) => setFieldValue('tipo', event.value)}
				options={TIPO_OPTIONS}
				keyfilter={keyFilterConsultaRsql}
				name="tipo"
				showClear={false}
				disabled={isModal}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={Dropdown}
				label="Situação"
				name="situacao"
				onChange={(event) => setFieldValue('situacao', event.value)}
				options={SITUACAO_OPTIONS}
				showClear={false}
				disabled={isModal}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={InputDouble}
				label="Tara (KG)"
				name="tara"
				obrigatorio
				value={values.tara}
				onChange={(e) => setFieldValue('tara', parseFloatNumber(e.target.value))}
				placeholder="0"
				decimalScale={0}
				size={6}
				allowNegative={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={InputDouble}
				label="Capacidade (KG)"
				name="capacidade"
				obrigatorio
				value={values.capacidade}
				onChange={(e) => setFieldValue('capacidade', parseFloatNumber(e.target.value))}
				placeholder="0"
				decimalScale={0}
				size={6}
				allowNegative={false}
				{...informacoesPermissoes}
			/>
			<If test={values.tipo === 'TRACAO'}>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={Dropdown}
					label="Rodado"
					onChange={(event) => setFieldValue('rodado', event.value)}
					options={RODADO_OPTIONS}
					keyfilter={keyFilterConsultaRsql}
					name="rodado"
					showClear={false}
					obrigatorio
					{...informacoesPermissoes}
				/>
			</If>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={Dropdown}
				label="Carroceria"
				onChange={(event) => setFieldValue('carroceria', event.value)}
				options={CARROCERIA_OPTIONS}
				keyfilter={keyFilterConsultaRsql}
				name="carroceria"
				showClear={false}
				obrigatorio
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export const FormVeiculos = FormVeiculosImpl;
