import { tipoCampos } from '../../../../components/PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancado = [
	{ label: 'Código', name: 'codigo', type: tipoCampos.INTEGER },
	{ label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Ativo', value: 'ATIVO' },
			{ label: 'Inativo', value: 'INATIVO' },
		],
	},
];

export const optionsModalidadeDeterminacaoST = [
	{ label: 'Preço tabelado ou máximo sugerido', value: 'PRECO_TABELADO' },
	{ label: 'Lista Negativa (valor)', value: 'LISTA_NEGATIVA' },
	{ label: 'Lista Positiva (valor)', value: 'LISTA_POSITIVA' },
	{ label: 'Lista Neutra (valor)', value: 'LISTA_NEUTRA' },
	{ label: 'Margem Valor Agregado (%)', value: 'MARGEM_VALOR_AGREGADO' },
	{ label: 'Pauta (valor)', value: 'PAUTA' },
];
