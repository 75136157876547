import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { withFormik } from 'formik';
import { Modal, FormContent, FormActions, If } from '../../../../../../../../components';
import { copiarObjeto, mensagensDeValidacao } from '../../../../../../../../Common';
import { convenioFieldsPorBanco } from './Util';
import ModalConvenioCertificado from './components/ModalConvenioCertificado';
import ActionButtons from './components/ActionButtons';
import FieldsConvenio from './components/FieldsConvenio';
import FieldsConvenioApi from './components/FieldsConvenioApi';

const initialValue = {
	cnab: { label: '240', value: 'CNAB240' },
	numero: null,
	descricao: null,
	situacao: { label: 'Ativa', value: 'ATIVA' },
	numeroCarteira: null,
	codigoCarteira: null,
	numeroRemessaReiniciaDiariamente: { label: 'Não', value: false },
	numeroRemessa: null,
	densidadeRemessa: null,
	tipoWebservice: null,
	numeroContrato: null,
	versaoLayoutArquivo: null,
	carteiraEscritural: null,
	nossoNumeroPeloBanco: { label: 'Não', value: false },
	apiId: null,
	apiKey: null,
	apiSecret: null,
	apiEstacao: null,
	nomeCertificado: null,

	bancoSelecionado: {},
};

function ModalNovoConvenioForm(props) {
	const {
		visible,
		informacoesPermissoes,
		values,
		onHide,
		setFieldValue,
		setFieldValueForm,
		resetFormPrincipal,
		dadosFormPrincipal,
		indexConvenio,
		isModal,
		dirty,
		bancoSelecionado,
		contaId,
		onExcluirRegistro,
		convenios,
		isMobile,
		isTablet,
		isLessHd,
	} = props;

	const [fieldExcecao, setFieldExcecao] = useState(null);
	const [excecaoPorBanco, setExcecaoPorBanco] = useState(null);
	const [exibirModalConvenioCertificado, setExibirModalConvenioCertificado] = useState(false);
	useEffect(() => {
		return () => {
			if (fieldExcecao) {
				setExcecaoPorBanco(null);
			}
			setFieldExcecao(null);
		};
	}, []);

	useEffect(() => {
		let flagFieldExcecao = false;

		//sicoob
		if (bancoSelecionado?.value === '207cf63b-167a-42da-acb7-eb7be66fde67') {
			if (fieldExcecao === 'apiSecret' && ['V1', 'V2'].includes(values.tipoWebservice)) {
				flagFieldExcecao = true;
			} else {
				flagFieldExcecao = false;
			}
		}
		setExcecaoPorBanco(flagFieldExcecao);
	}, [fieldExcecao, values.tipoWebservice]);

	function validarConvenioFieldsPorBanco(campo) {
		return convenioFieldsPorBanco[`${bancoSelecionado?.value}`].includes(campo);
	}

	async function asyncUploadCertificado(nomeCertificado) {
		const formPrincipal = await {
			...copiarObjeto(dadosFormPrincipal),
			convenios: await montarConvenios(nomeCertificado),
		};
		resetFormPrincipal({
			values: formPrincipal,
		});
		setTimeout(() => {
			props.resetForm({ values: { ...values, nomeCertificado: nomeCertificado } });
		}, 50);
	}

	async function montarConvenios(nomeCertificado) {
		let conveniosArray = await copiarObjeto(convenios);
		conveniosArray[indexConvenio] = { ...conveniosArray[indexConvenio], nomeCertificado: nomeCertificado };

		return conveniosArray;
	}

	return (
		<>
			<Modal
				header={'Dados do convênio'}
				visible={visible}
				onHide={() => {
					onHide();
				}}
			>
				<FormActions>
					<ActionButtons
						values={values}
						convenios={convenios}
						initialValue={initialValue}
						isModal={isModal}
						dirty={dirty}
						informacoesPermissoes={informacoesPermissoes}
						indexConvenio={indexConvenio}
						onHide={onHide}
						setFieldValueForm={setFieldValueForm}
						onExcluirRegistro={onExcluirRegistro}
						setFieldError={props.setFieldError}
						resetForm={props.resetForm}
						handleSubmit={props.handleSubmit}
						registroSelecionado={props.registroSelecionado}
						setExibirModalConvenioCertificado={setExibirModalConvenioCertificado}
						propsForm={props}
					/>
				</FormActions>
				<FormContent>
					<div className={'card-default'} style={{ padding: '8px', margin: '8px 0 8px 0' }}>
						<FieldsConvenio
							validarConvenioFieldsPorBanco={validarConvenioFieldsPorBanco}
							bancoSelecionado={bancoSelecionado}
							setFieldValue={setFieldValue}
							informacoesPermissoes={informacoesPermissoes}
							isMobile={isMobile}
							isTablet={isTablet}
							isLessHd={isLessHd}
							setFieldExcecao={setFieldExcecao}
						/>
						<FieldsConvenioApi
							bancoSelecionado={bancoSelecionado}
							validarConvenioFieldsPorBanco={validarConvenioFieldsPorBanco}
							informacoesPermissoes={informacoesPermissoes}
							excecaoPorBanco={excecaoPorBanco}
						/>
					</div>
				</FormContent>
			</Modal>
			<If test={exibirModalConvenioCertificado}>
				<ModalConvenioCertificado
					visible={exibirModalConvenioCertificado}
					onHide={() => setExibirModalConvenioCertificado(false)}
					informacoesPermissoes={informacoesPermissoes}
					alterarNomeCertificado={(nomeCertificado) => asyncUploadCertificado(nomeCertificado)}
					nomeCertificado={values.nomeCertificado}
					convenioId={values.id}
					contaId={contaId}
				/>
			</If>
		</>
	);
}

const ModalNovoConvenio = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return { ...props.registroSelecionado, bancoSelecionado: props.bancoSelecionado };
		} else {
			return { ...initialValue, bancoSelecionado: props.bancoSelecionado };
		}
	},

	validate(values) {
		let errors = {};

		if (!values.numero) {
			errors = { ...errors, numero: mensagensDeValidacao.OBRIGATORIO };
		}

		if (!values.cnab) {
			errors = { ...errors, cnab: mensagensDeValidacao.OBRIGATORIO };
		}

		if (!values.descricao) {
			errors = { ...errors, descricao: mensagensDeValidacao.OBRIGATORIO };
		}

		if (!values.situacao) {
			errors = { ...errors, situacao: mensagensDeValidacao.OBRIGATORIO };
		}

		if (!values.numeroRemessaReiniciaDiariamente) {
			errors = { ...errors, numeroRemessaReiniciaDiariamente: mensagensDeValidacao.OBRIGATORIO };
		}

		if (!values.nossoNumeroPeloBanco) {
			errors = { ...errors, nossoNumeroPeloBanco: mensagensDeValidacao.OBRIGATORIO };
		}

		if (!values.numeroCarteira) {
			errors = { ...errors, numeroCarteira: mensagensDeValidacao.OBRIGATORIO };
		}

		if (values?.bancoSelecionado) {
			//BB
			if (values.bancoSelecionado?.value === 'cce6d2ff-71ed-48a9-88ff-20932616acf9') {
				if (!values.apiId) {
					errors = { ...errors, apiId: mensagensDeValidacao.OBRIGATORIO };
				}
				if (!values.apiKey) {
					errors = { ...errors, apiKey: mensagensDeValidacao.OBRIGATORIO };
				}
				if (!values.apiSecret) {
					errors = { ...errors, apiSecret: mensagensDeValidacao.OBRIGATORIO };
				}
			}
			//Santander
			if (values.bancoSelecionado?.value === '41b59254-ad4f-45b2-9172-39fd8cc827c3') {
				if (!values.apiEstacao) {
					errors = { ...errors, apiEstacao: mensagensDeValidacao.OBRIGATORIO };
				}
			}
			//Unicred
			if (values.bancoSelecionado?.value === '42bdc03d-06c1-4fcb-ac9e-6a9d99117099') {
				if (!values.apiId) {
					errors = { ...errors, apiId: mensagensDeValidacao.OBRIGATORIO };
				}
				if (!values.apiKey) {
					errors = { ...errors, apiKey: mensagensDeValidacao.OBRIGATORIO };
				}
				if (!values.apiSecret) {
					errors = { ...errors, apiSecret: mensagensDeValidacao.OBRIGATORIO };
				}
				if (!values.apiEstacao) {
					errors = { ...errors, apiEstacao: mensagensDeValidacao.OBRIGATORIO };
				}
			}
			//Bradesco
			if (values.bancoSelecionado?.value === 'd5dc03be-d0f4-4063-b992-60ea79df91de') {
				if (!values.apiId) {
					errors = { ...errors, apiId: mensagensDeValidacao.OBRIGATORIO };
				}
				if (!values.apiKey) {
					errors = { ...errors, apiKey: mensagensDeValidacao.OBRIGATORIO };
				}
				if (!values.apiSecret) {
					errors = { ...errors, apiSecret: mensagensDeValidacao.OBRIGATORIO };
				}
			}
			//Itau
			if (values.bancoSelecionado?.value === 'ac9a6bb7-f836-45b0-bed2-88122067450f') {
				if (!values.apiId) {
					errors = { ...errors, apiId: mensagensDeValidacao.OBRIGATORIO };
				}
				if (!values.apiSecret) {
					errors = { ...errors, apiSecret: mensagensDeValidacao.OBRIGATORIO };
				}
			}
			//Sicredi
			if (values.bancoSelecionado?.value === 'fe79b0ad-602f-4fa9-a1c8-6a0e5a6447ad') {
				if (!values.apiId) {
					errors = { ...errors, apiId: mensagensDeValidacao.OBRIGATORIO };
				}
				if (!values.apiKey) {
					errors = { ...errors, apiKey: mensagensDeValidacao.OBRIGATORIO };
				}
				if (!values.apiSecret) {
					errors = { ...errors, apiSecret: mensagensDeValidacao.OBRIGATORIO };
				}
				if (!values.apiEstacao) {
					errors = { ...errors, apiEstacao: mensagensDeValidacao.OBRIGATORIO };
				}
			}
			//Sicoob
			if (values.bancoSelecionado?.value === '207cf63b-167a-42da-acb7-eb7be66fde67') {
				if (!values.apiId) {
					errors = { ...errors, apiId: mensagensDeValidacao.OBRIGATORIO };
				}
				if (['V1', 'V2'].includes(values?.tipoWebservice)) {
					if (!values.apiSecret) {
						errors = { ...errors, apiSecret: mensagensDeValidacao.OBRIGATORIO };
					}
				}
			}
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalNovoConvenioForm);

export default withRouter(ModalNovoConvenio);
