import { useState } from 'react';
import { permissoes, recursos, removerCaracteres, services, usuarioPossuiPermissao } from 'Common';

import { ModalSeguradora } from 'views/cadastros/transporte/Seguradora/Modal';
import SingleSelect from '../SingleSelect';

function SingleSelectSeguradoraImpl({
	isMobile,
	label,
	setHideBackground = false,
	menuPortalTarget = document.body,
	onShowModal,
	onChange,
	errors,
	touched,
	url,
	...props
}) {
	const [visible, setVisible] = useState(false);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR));
	const [podeVisualizar] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR));
	const [resetPesquisa, setResetPesquisa] = useState(false);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		const urlCadastro = `${services.GESTOR}/v1/transporte/seguradora/resumo`;
		const pesquisaValor = removerCaracteres(pesquisa, ['.', '&', '-']);

		return `${
			url ? `${services.GESTOR}${url}` : urlCadastro
		}?query=(nome=contains="*${pesquisaValor}*");situacao=="ATIVO"&page=${page}&size=20&sort=nome`;
	}

	function mostrarModal() {
		if (setHideBackground) {
			setHideBackground(true);
		}
		if (onShowModal) {
			onShowModal();
		}
		setVisible(!visible);
	}

	function esconderModal() {
		if (setHideBackground) {
			setHideBackground(false);
		}
		setVisible(false);
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			onChange({
				label: `${novoRegistro.nome} - ${novoRegistro.cnpj}`,
				value: novoRegistro.id,
				registro: novoRegistro,
			});
			setResetPesquisa(true);
		}
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		return `${row.nome} - ${row.cnpj}`;
	}

	return (
		<>
			<ModalSeguradora visible={visible} onHide={onHide} container={menuPortalTarget} />
			<SingleSelect
				titleBotaoNovo="Adicionar nova seguradora"
				buscarUrlPesquisa={buscarUrlPesquisa}
				label={label}
				montarLabel={montarLabel}
				onChange={onChange}
				onClickModal={mostrarModal}
				errors={errors}
				useFormErrors={false}
				useFormTouched={false}
				touched={touched}
				disabledButton={!podeInserirEVisualizar}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
				styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
				menuPortalTarget={menuPortalTarget}
				placeholder={isMobile ? 'Selecione uma opção' : 'Digite para pesquisar'}
				{...props}
			/>
		</>
	);
}

SingleSelectSeguradoraImpl.defaultProps = {
	label: 'Seguradora',
};

export const SingleSelectSeguradora = SingleSelectSeguradoraImpl;
