import { SelectButton } from "primereact/selectbutton";

import "./styles/index.css";

function SelectToggle(props) {
  const { value, options, onChange, disabled, style, className } = props;

  return (
    <SelectButton
      className={`select-toggle ${className}`}
      style={style}
      value={value}
      options={options}
      onChange={onChange}
      disabled={disabled}
    />
  );
}
export default SelectToggle;
