import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	removerElemento,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Badge,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Col,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	Tutorial,
	tutorialStepsListagens,
} from 'components';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { atualizarUrl } from '../../Util';
import { confirmarExclusao } from '../../Util/ExclusaoDeRegistros';
import { asyncDeleteUsuario, asyncListarUsuarios } from './Requests';
import { OPTIONS_FILTRO_AVANCADO } from './Util/constantes';

function Usuarios({ ...props }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		totalRecords,
		setTotalRecords,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [registros, setRegistros] = useState([]);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.USUARIOS, permissoes.INSERIR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.USUARIOS, permissoes.EXCLUIR));
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS));

	const onPesquisar = useCallback(() => {
		pesquisar();
	});

	useEffect(() => {
		setRows(10);
		onPesquisar();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('UsuariosInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		onPesquisar();
	}, [rows, page, interval, sortField, sortOrder, filtroAvancado, exibirBloqueadas]);

	function buscarFiltro() {
		const filtroAvancadoLocal = filtroAvancado ?? '';

		let result = `?query=(nome=contains="*${valorPesquisa.replaceAll(
			'&',
			'%26'
		)}*",credencial.email=contains="*${valorPesquisa.replaceAll('&', '%26')}*")${!exibirBloqueadas ? ';credencial.bloqueada==false' : ''}`;
		if (filtroAvancadoLocal) {
			result += `;${filtroAvancadoLocal}`;
		}
		return result;
	}

	async function pesquisar() {
		const filtro = buscarFiltro();
		const url = construirUrl(
			`${services.GESTOR}/v1/usuarios/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		await asyncListarUsuarios(url, ({ data: usuarios }) => {
			setRegistros(usuarios.content);
			setTotalRecords(usuarios.totalElements);
		});
	}

	function onPageChange(e) {
		setPage(e.page);
		setRows(e.rows);
	}

	function onSort(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function onEditar(row) {
		atualizarUrl(props.history, '/usuarios/cadastro', row.id);
	}

	function onExcluir(row) {
		confirmarExclusao(() => asyncExcluirRegistro(row));
	}

	async function asyncExcluirRegistro(registro) {
		await asyncDeleteUsuario(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalRecords(totalRecords - 1);
		});
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'inline' }}>
				<ButtonEditarTable onClick={() => onEditar(row)} />
				<ButtonExcluirTable
					podeExcluir={podeExcluir}
					onClick={() => onExcluir(row)}
					disabled={row.contaConfirmada}
					title={row.contaConfirmada ? 'Não é possivel excluir um usuário com a conta confirmada' : 'Excluir registro'}
				/>
			</div>
		);
	}

	async function onPesquisarFiltroAvancado(e) {
		await setFiltroAvancado(e);
	}

	function onClickexibirBloqueadas() {
		setExibirBloqueadas(!exibirBloqueadas);
	}

	function renderContaConfirmada(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: !row.credencialAtiva ? '0.1rem 1.3rem' : '0.1rem 1.2rem',
		};

		switch (!row.credencialAtiva) {
			case true: {
				return Badge('#b71c1c', '#ffcdd2', 'Pendente', styleBackground);
			}
			case false: {
				return Badge('#1b5e20', '#dcedc8', 'Efetuada', styleBackground);
			}
			default:
				return row.situacao;
		}
	}

	function renderSituação(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: row.credencialBloqueada ? '0.1rem 1.3rem' : '0.1rem 1.2rem',
		};

		switch (row.credencialBloqueada) {
			case true: {
				return Badge('#b71c1c', '#ffcdd2', 'Bloqueada', styleBackground);
			}
			case false: {
				return Badge('#1b5e20', '#dcedc8', 'Desbloqueada', styleBackground);
			}
			default:
				return row.situacao;
		}
	}

	function renderEmail(row) {
		return <span title={row.credencialEmail}>{row.credencialEmail}</span>;
	}

	const registrosFiltrados = registros;

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Usuários">
				<FormActions>
					<ButtonNovo
						label="Novo usuário"
						className="step-listagem-novo"
						onClick={() => props.history.push('/usuarios/cadastro')}
						title={podeInserir ? 'Cadastrar um novo usuário' : 'Você não possui permissão para executar essa ação'}
						podeInserir={podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							className="step-listagem-input-search"
							onPesquisar={() => onPesquisar()}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
							id="UsuariosInputSearch"
						/>
						<Col sm="12" md="4" lg="4" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								optionsFiltros={OPTIONS_FILTRO_AVANCADO}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						className="table"
						responsive
						value={registrosFiltrados}
						sortField={sortField}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Checkbox
									name="exibirBloqueadas"
									inputId="exibirBloqueadas"
									checked={exibirBloqueadas}
									onChange={() => onClickexibirBloqueadas()}
								/>
								<label htmlFor="exibirBloqueadas" className="p-checkbox-label">
									Exibir usuários bloqueados
								</label>
							</span>
						}
					>
						<Column field="nome" header="Nome" sortable />
						<Column
							className="step-listagem-order"
							field="credencialEmail"
							header="E-mail"
							body={renderEmail}
							sortable
							style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
						/>
						<Column field="contaConfirmada" body={renderContaConfirmada} header="Confirmação" sortable={false} />
						<Column field="credencialBloqueada" body={renderSituação} header="Situação" sortable />
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
					</DataTable>
					<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
				</FormContent>
			</Form>
		</>
	);
}

export default withRouter(Usuarios);
