import { services } from '../../../../Common/Constantes/api';
import { del, get, exibirToast } from '../../../../Common/Requisicoes';

export async function asyncDeleteOrganizacao(organizacaoId, onSuccess, onError) {
	await del(`${services.GESTOR}/v1/agentes_autorizados/${organizacaoId}`, null, exibirToast(onSuccess, 'Operação realizada com sucesso'), onError);
}

export async function asyncGetPesquisaOrganizacoes(url, onSuccess, onError) {
	await get(url, null, exibirToast(onSuccess), onError);
}
