import { Field } from 'formik';
import InputDouble from '../../../../../../components/input/InputDouble';

function FieldsModalIcmsStRetido(props) {
	const { validacaoNovaTributacao, setFieldValue, values } = props;

	return (
		<>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				label="Base ST Retido"
				name="icmsStRetidoBase"
				value={values.icmsStRetidoBase}
				onChange={event => setFieldValue('icmsStRetidoBase', event.target.value)}
				suffix="%"
				allowNegative={false}
				MaxValue={100}
				disabled={validacaoNovaTributacao}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				label="% ST Retido"
				name="icmsStRetidoPercentual"
				value={values.icmsStRetidoPercentual}
				onChange={event => setFieldValue('icmsStRetidoPercentual', event.target.value)}
				suffix="%"
				allowNegative={false}
				MaxValue={100}
				disabled={validacaoNovaTributacao}
			/>
		</>
	);
}

export default FieldsModalIcmsStRetido;
