import { useEffect, useState } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { connect } from 'react-redux';
import usePrevious from '../../../Common/Hooks/usePrevious';

function AutoProgressBar(props) {
	const [exibirLoading, setExibirLoading] = useState(false);
	const prevProps = usePrevious(props);

	useEffect(() => {
		if (prevProps?.actionLoading !== props.actionLoading) {
			if (props.actionLoading && typeof props.actionLoading === 'string') {
				if (props.actionLoading.includes('OCULTAR_LOADING') && exibirLoading) {
					setExibirLoading(false);
				} else if (props.actionLoading.includes('EXIBIR_LOADING') && !exibirLoading) {
					setExibirLoading(true);
				}
			}
		}
	}, [props]);

	return (
		<ProgressBar
			mode="indeterminate"
			style={{
				height: '4px',
				visibility: exibirLoading ? 'initial' : 'hidden',
				...props.style,
			}}
		/>
	);
}

const mapStateToProps = state => ({
	actionLoading: state.loading.actionLoading,
});

export default connect(mapStateToProps)(AutoProgressBar);
