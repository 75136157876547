import { useState } from "react";
import { Card } from "primereact/card";
import Col from "../../../../../components/Col";
import Grid from "../../../../../components/Grid";
import { Button } from "primereact/button";
import { formatarMonetario } from "../../../../../Common/Mascara";
import { Menu } from "primereact/menu";
import { usuarioPossuiPermissao } from "../../../../../Common/Autenticacao";
import {
  recursos,
  permissoes,
} from "../../../../../Common/Constantes/autorizacao";
import { GoBookmark } from "react-icons/go";
import If from "../../../../../components/If";
import "./Styles/index.css";
import { Financas } from "../../../../Util/constantes";
import { Badge } from "components";
import { COLOR_CARD } from "./util/constantes";

const tipoConta = {
  DINHEIRO: "DINHEIRO",
  CORRENTE: "CORRENTE",
  POUPANCA: "POUPANCA",
  INVESTIMENTO: "INVESTIMENTO",
  OUTRO: "OUTRO",
};

const styleNomeConta = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  padding: "0px 0px 0px 6px",
  fontSize: "20px",
  color: "#333333",
  fontWeight: "bold",
};

const styleSubtitleConta = {
  fontSize: "13px",
  color: "#333333a6",
  fontWeight: "bold",
  padding: "3px 0px 0px 6px",
};

const styleSaldoAtualPositivo = {
  fontSize: "23px",
  color: Financas.cores.verde,
  fontWeight: "bold",
};

const styleSaldoAtualNegativo = {
  fontSize: "23px",
  color: Financas.cores.vermelho,
  fontWeight: "bold",
};

const styleContaFavorita = {
  marginLeft: "-5px",
};

const styleBotaoOpcoesCard = {
  padding: "0px",
  display: "flex",
  justifyContent: "flex-end",
};

const styleContaInativa = {
  backgroundImage:
    "repeating-linear-gradient(40deg, #0000001a, transparent 2px)",
};

const styleBadgeContaInativa = {
  borderRadius: "20px",
  padding: "0.1rem 1.3rem",
};

function CardConta(props) {
  const [podeExcluir, setPodeExcluir] = useState(
    usuarioPossuiPermissao(
      recursos.CADASTROS_CONTAS_FINANCEIRAS,
      permissoes.EXCLUIR
    )
  );
  const [podeEditar, setPodeEditar] = useState(
    usuarioPossuiPermissao(
      recursos.CADASTROS_CONTAS_FINANCEIRAS,
      permissoes.EDITAR
    )
  );
  const [menu, setMenu] = useState(null);

  function montarItensMenu() {
    const { conta } = props;
    const { habilitarVerDetalhes } = props;

    let itens = [];

    if (podeExcluir) {
      itens.push({
        label: "Excluir conta",
        icon: "fa fa-trash",
        command: () => props.excluirConta(conta),
      });
    }

    if (conta.situacao === "ATIVA") {
      if (podeEditar) {
        itens.push({
          label: "Inativar conta",
          icon: "fa fa-ban",
          command: () => props.desativarConta(conta),
        });
      }
    } else {
      if (podeEditar) {
        itens.push({
          label: "Ativar conta",
          icon: "fa fa-undo",
          command: () => props.ativarConta(conta),
        });
      }
    }
    if (habilitarVerDetalhes) {
      itens.push({
        label: "Ver movimentações",
        icon: "fa fa-usd",
        command: () => props.verDetalhesClick(conta),
      });
    }
    return itens;
  }

  function renderizarSaldoConta() {
    const { habilitarVerDetalhes, verDetalhesClick, conta } = props;
    const { situacao, saldoAtual } = conta;

    const saldoPositivo = Number(saldoAtual) >= 0;
    return (
      <Grid verticalAlignCenter>
        <Col
          col="9"
          style={situacao === "INATIVA" ? { opacity: "0.70" } : null}
        >
          <div>Saldo atual</div>
          <div
            className={habilitarVerDetalhes ? "card-conta-saldo-atual" : null}
            onClick={
              habilitarVerDetalhes ? () => verDetalhesClick(conta) : null
            }
            style={
              saldoPositivo ? styleSaldoAtualPositivo : styleSaldoAtualNegativo
            }
            title={habilitarVerDetalhes ? "Ver movimentações" : null}
          >
            {formatarMonetario(saldoAtual)}
          </div>
        </Col>
        {situacao === "INATIVA" ? (
          <Col
            col="3"
            style={{
              display: "flex",
              alignSelf: "center",
            }}
          >
            {Badge(
              COLOR_CARD.txtInativo,
              COLOR_CARD.bgInativo,
              "Conta inativa",
              styleBadgeContaInativa
            )}
          </Col>
        ) : null}
      </Grid>
    );
  }

  function renderizarTipoConta(tipo) {
    if (tipo === tipoConta.POUPANCA) {
      return "POUPANÇA";
    }
    return tipo;
  }

  const { favoritaReceita, favoritaDespesa, situacao, nome, tipo } =
    props.conta;
  const mensagemContaDespesa = "Conta favorita para despesas";
  const mensagemContaReceita = "Conta favorita para receitas";

  return (
    <Col col="12" sm="12" md="4" lg="4" xl="4">
      <Menu
        model={montarItensMenu()}
        popup={true}
        ref={(elemento) => setMenu(elemento)}
        id="popup_menu"
      />
      <Card
        style={
          situacao === "INATIVA" ? styleContaInativa : { borderRadius: "5px" }
        }
        footer={renderizarSaldoConta()}
      >
        <Grid style={situacao === "INATIVA" ? { opacity: "0.70" } : null}>
          <Col col="8" lg="9" style={styleNomeConta}>
            <If test={favoritaReceita}>
              <GoBookmark
                title={mensagemContaReceita}
                color={Financas.cores.verde}
                size={22}
                style={styleContaFavorita}
              />
            </If>
            <If test={favoritaDespesa}>
              <GoBookmark
                title={mensagemContaDespesa}
                color={Financas.cores.vermelho}
                size={22}
                style={styleContaFavorita}
              />
            </If>
            <span title={nome}>{nome}</span>
          </Col>
          <Col col="4" lg="3" style={styleBotaoOpcoesCard}>
            <If test={podeEditar}>
              <Button
                title="Editar conta"
                className="p-button-primary"
                style={{
                  marginRight: "5px",
                  borderRadius: "50%",
                  padding: "5px",
                  width: "30px",
                  height: "30px",
                }}
                icon="fa fa-pencil"
                onClick={() => props.editarConta(props.conta)}
              />
            </If>
            <If test={podeEditar || podeExcluir}>
              <Button
                title="Opções"
                className="p-button-secondary"
                icon="fa fa-ellipsis-v"
                style={{
                  borderRadius: "50%",
                  border: "none",
                  padding: "5px",
                  width: "30px",
                  height: "30px",
                }}
                aria-controls="popup_menu"
                aria-haspopup={true}
                onClick={(event) => menu.toggle(event)}
              />
            </If>
          </Col>
        </Grid>
        <Grid justifyEnd>
          <Col col="12" style={styleSubtitleConta}>
            {renderizarTipoConta(tipo)}
          </Col>
        </Grid>
      </Card>
    </Col>
  );
}

export default CardConta;
