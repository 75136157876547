import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { If, Message } from '../../../../../../components';

function ValidateMessages(props) {
	const { organizacaoPodeTransmitirNfe, credencial, history } = props;

	const { values, errors } = useFormikContext();

	function buscarMensagemOrganizacaoPodeTransmitirNfe() {
		return (
			<span>
				{getTitleButtonTransmitir()}
				<div>
					<b
						style={{ cursor: 'pointer' }}
						onClick={() =>
							history.push({
								pathname: '/configuracoes_gerais',
								state: { configurarSerieNfe: true },
							})
						}
					>
						Clique aqui para configurar
					</b>
				</div>
			</span>
		);
	}

	function getTitleButtonTransmitir() {
		if (credencial?.filialConectada?.parametrosFiscalNFe) {
			if (!credencial?.filialConectada?.parametrosFiscalCertificado) {
				return 'Filial sem certificado digital configurado para transmissão de NF-e';
			} else if (!credencial.filialConectada.parametrosFiscalNFe?.serieNfe) {
				return 'Série da nota fiscal não configurada';
			}
		}
		return '';
	}

	function montarValidacoesNotaRejeitada() {
		return (
			<>
				<div style={{ marginBottom: '7px' }}>
					<b>Nota fiscal Rejeitada pelo SEFAZ, as seguintes validações foram retornadas:</b>
				</div>
				{values?.erros?.map((erro, index) => {
					return <div key={index}>{erro.mensagem}</div>;
				})}
			</>
		);
	}

	const validateFields = useCallback(() => {
		if (errors.conta) {
			return true;
		}

		if (errors.pessoaEndereco) {
			return true;
		}

		return false;
	});

	function buscarMensagemValidateFields() {
		let text = '';

		if (errors.conta) {
			text = 'Campo Conta na aba Pagamentos não pode ser vazio';
		}

		if (errors.pessoaEndereco) {
			text = 'Endereço do destinatário incompleto ou não informado';
		}

		return <span>{text}</span>;
	}

	return (
		<>
			<If test={organizacaoPodeTransmitirNfe() === false}>
				<Message severity="error" text={buscarMensagemOrganizacaoPodeTransmitirNfe()} />
			</If>

			<If test={values?.erros?.length > 0}>
				<Message
					colStyle={{ padding: '0px', marginTop: '5px' }}
					severity="warn"
					text={montarValidacoesNotaRejeitada()}
					style={{ margin: '0px 0px 1rem 0px' }}
				/>
			</If>

			<If test={validateFields()}>
				<Message severity="warn" text={buscarMensagemValidateFields()} />
			</If>
		</>
	);
}

export default ValidateMessages;
