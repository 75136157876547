import { useEffect, useState } from "react";

import SingleSelect from "../SingleSelect";
import { services } from "../../../Common/Constantes/api";
import ModalTributacaoFederal from "../../../views/cadastros/TributacaoFederal/Modal";

export default function SingleSelectTributacaoFederal(props) {
  const { setVisibleModalFederal } = props;
  const [visible, setVisible] = useState(false);
  const [resetPesquisa, setResetPesquisa] = useState(false);

  useEffect(() => {
    if (setVisibleModalFederal) {
      setVisibleModalFederal(visible);
    }
  }, [visible]);

  function buscarUrlPesquisa(pesquisa, page) {
    return `${services.GESTOR}/v1/tributacoes/federal?query=(codigo=contains="${pesquisa}",descricao=contains="*${pesquisa}*");situacao=="ATIVO"&page=${page}&size=50&sort=codigo`;
  }

  function mostrarModal() {
    setVisible(!visible);
  }

  function esconderModal() {
    setVisible(false);
  }

  function onHide(novoRegistro) {
    setarNovoRegistroNoSelect(novoRegistro);
    esconderModal();
  }

  function montarLabel(row) {
    return row.codigo + " - " + row.descricao;
  }

  function setarNovoRegistroNoSelect(novoRegistro) {
    if (novoRegistro?.id) {
      props.onChange({
        label: montarLabel({
          codigo: novoRegistro.codigo,
          descricao: novoRegistro.descricao,
        }),
        value: novoRegistro.id,
      });
      setResetPesquisa(true);
    }
  }

  return (
    <>
      <ModalTributacaoFederal visible={visible} onHide={onHide} />
      <SingleSelect
        titleBotaoNovo={"Adicionar nova tributação federal"}
        buscarUrlPesquisa={buscarUrlPesquisa}
        montarLabel={montarLabel}
        onClickModal={mostrarModal}
        onChange={props.onChange}
        {...props}
        resetPesquisa={resetPesquisa}
        setResetPesquisa={setResetPesquisa}
      />
    </>
  );
}

SingleSelectTributacaoFederal.defaultProps = {
  label: "Tributação federal",
};
