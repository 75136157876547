export const HelpTributacaoMunicipalForm = {
  nome: "Nome/descrição da tributação municipal.",
  tributacaoFederal: "Selecionar a tributação federal.",
  situacao: "Define se o registro está ativo ou inativo.",
  favorito: "Checkbox que destaca se a tributação é a favorita para uso.",

  municipio: "Municipio da tributação.",
  servicoCst: "Situação tributária(CST) da tributação.",
  issBase: "Base de cálculo de ISS da tributação.",
  issPercentual: "Alíquota de ISS da tributação.",
  irBase: "Base de cálculo de IR da tributação.",
  irPercentual: "Alíquota de IR da tributação.",
  csllBase: "Base de cálculo de CSLL da tributação.",
  csllPercentual: "Alíquota de CSLL da tributação.",
  inssBase: "Base de cálculo de INSS da tributação.",
  inssPercentual: "Alíquota de INSS da tributação.",
};
