import { tipoCampos } from '../../../../components/PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancado = [
	{ label: 'Número', name: 'numero', type: tipoCampos.STRING },
	{ label: 'Série', name: 'serie', type: tipoCampos.STRING },
	{ label: 'Emissão', name: 'dataHoraEmissao', type: tipoCampos.DATE },
	{ label: 'Nome emitente', name: 'emitenteNome', type: tipoCampos.STRING },
	{ label: 'CPF/CNPJ', name: 'emitenteCpfCnpj', type: tipoCampos.STRING },
	{
		label: 'Status da NF-e',
		name: 'situacaoNfe',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Transmitida', value: 'TRANSMITIDA' },
			{ label: 'Cancelada', value: 'CANCELADA' },
			{ label: 'Denegada', value: 'DENEGADA' },
		],
	},
];

export const NotasEntrada = {
	cores: {
		verde: '#1b5e20',
		verdeClaro: '#dcedc8',
		azul: '#006095',
		preto: '#fff0',
		cinza: '#888888',
	},
};

export const ColorsCard = {
	SEM_MANIFESTACAO: {
		lightColor: '#F6FAB9',
		strongColor: '#996A13',
	},
	CIENCIA_OPERACAO: {
		lightColor: '#B3E5FC',
		strongColor: '#063970',
	},
	CONFIRMACAO_OPERACAO: {
		lightColor: '#dcedc8',
		strongColor: '#1b5e20',
	},
	DESCONHECIMENTO_OPERACAO: {
		lightColor: '#E0E0E0',
		strongColor: '#000000',
	},
	OPERACAO_NAO_REALIZADA: {
		lightColor: '#FFCDD2',
		strongColor: '#B71C1C',
	},
};

export const OperacaoStatus = {
	SEMMANIFESTACAO: 'SEM_MANIFESTACAO',
	CIENCIA: 'CIENCIA_OPERACAO',
	CONFIRMADA: 'CONFIRMACAO_OPERACAO',
	DESCONHECIMENTO: 'DESCONHECIMENTO_OPERACAO',
	NAOREALIZADA: 'OPERACAO_NAO_REALIZADA',
};

export const ColorsSituacao = {
	bgSemManifestacao: '#F6FAB9',
	textSemManifestacao: '#996A13',
	bgCiencia: '#B3E5FC',
	textCiencia: '#333333',
	bgConfirmada: '#DCEDC8 ',
	textConfirmada: '#1B5E20 ',
	bgDesconhecimento: '#E0E0E0',
	textDesconhecimento: '#000000',
	bgNaoRealizada: '#FFCDD2',
	textNaoRealizada: '#B71C1C',
};

export const OptionsManifestacao = [
	{ label: 'Ciência da operação', value: 'CIENCIA_DA_OPERACAO' },
	{ label: 'Confirmação da operação', value: 'CONFIRMACAO_DA_OPERACAO' },
	{ label: 'Desconhecimento da operação', value: 'DESCONHECIMENTO_DA_OPERACAO' },
	{ label: 'Operação não realizada', value: 'OPERACAO_NAO_REALIZADA' },
];

export const TipoManifestacao = {
	CIENCIA_DA_OPERACAO: 'CIENCIA_DA_OPERACAO',
	CONFIRMACAO_DA_OPERACAO: 'CONFIRMACAO_DA_OPERACAO',
	DESCONHECIMENTO_DA_OPERACAO: 'DESCONHECIMENTO_DA_OPERACAO',
	OPERACAO_NAO_REALIZADA: 'OPERACAO_NAO_REALIZADA',
	SEM_MANIFESTACAO: 'SEM_MANIFESTACAO',
};

export const SituacaoNfe = {
	TRANSMITIDA: 'TRANSMITIDA',
	CANCELADA: 'CANCELADA',
	DENEGADA: 'DENEGADA',
};
