import { useEffect } from 'react';
import * as Yup from 'yup';
import autoBind from 'react-autobind';
import propTypes from 'prop-types';
import Modal from '../../../../../components/Modal';
import { withRouter } from 'react-router';
import { withFormik, Field } from 'formik';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../../../../Common/Autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../Common/Constantes/autorizacao';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import ButtonSalvar from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import Grid from '../../../../../components/Grid';
import InputDate from '../../../../../components/input/InputDate';
import InputMoney from '../../../../../components/input/InputMoney';
import { services } from '../../../../../Common/Constantes/api';
import { helpMessage } from './Util/constantes';
import { confirm } from '../../../../../components/Toast';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import TextArea from '../../../../../components/TextArea/TextArea';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import { isValid, parseISO, formatISO, format } from 'date-fns';
import SingleSelectProduto from '../../../../../components/select/SingleSelectProduto';
import { converterSaidaEstoqueParaApi } from './Util/saidaEstoqueConverter';
import { asyncCreateSaidaEstoque, asyncDeleteSaidaEstoque, asyncUpdateSaidaEstoque } from './Request';
import { mensagensDeValidacao } from '../../../../../Common/Constantes/mensagens';
import Message from '../../../../../components/Message';
import { TipoMovimentacaoDocumento } from '../../Util/constantes';
import { InputField } from 'components';

const initialValue = {
	id: null,
	data: formatISO(new Date()),
	hora: format(new Date(), 'HH:mm'),
	tipo: 'SAIDA',
	produto: null,
	custo: null,
	quantidade: 1,
	observacao: '',
	saldoTipo: 'ESTOQUE',
	venda: {
		documentoTipo: TipoMovimentacaoDocumento.VENDA,
	},
};

function ModalSaidaEstoqueView(props) {
	const {
		visible,
		onHide,
		dirty,
		values,
		geradoAPartirDeUmaVenda,
		onNovoClick,
		resetForm,
		handleSubmit,
		validateForm,
		isValid,
		atualizarListagem,
		registroSelecionado,
		setFieldValue,
		initialValues,
	} = props;
	const informacoesPermissoes = {
		podeInserir: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.INSERIR),
		podeEditar: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.EDITAR),
		podeExcluir: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.EXCLUIR),
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};

	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const onClickNovo = dirty ? e => salvar(e, novo) : novo;
	const DocumentoTipo = validaDocumentoTipo();
	const decimaisQtd = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('EstoqueSaidaSingleSelectProduto')?.getElementsByTagName('input')[0]?.focus();
		}, 500);
	}, []);

	function novo() {
		onNovoClick && onNovoClick();
		resetForm({ values: initialValue });
	}

	async function salvar(e, novoOnSuccess) {
		await handleSubmit();
		await validateForm();

		if (isValid) {
			if (values.id) {
				asyncUpdateSaidaEstoqueModal(values, novoOnSuccess);
			} else {
				asyncCreateSaidaEstoqueModal(values, novoOnSuccess);
			}
		}
	}

	async function asyncCreateSaidaEstoqueModal(values, novoOnSuccess) {
		if (values) {
			await asyncCreateSaidaEstoque(converterSaidaEstoqueParaApi(values), () => {
				if (novoOnSuccess) {
					atualizarListagem();
					resetForm({ values: initialValue });
					novoOnSuccess();
				} else {
					onHide(values);
				}
			});
		}
	}

	async function asyncUpdateSaidaEstoqueModal(values, novoOnSuccess) {
		if (values) {
			await asyncUpdateSaidaEstoque(converterSaidaEstoqueParaApi(values), () => {
				if (novoOnSuccess) {
					atualizarListagem();
					resetForm({ values: initialValue });
					novoOnSuccess();
				} else {
					onHide(values);
				}
			});
		}
	}

	function excluir() {
		confirm('Confirmação', 'Deseja excluir o registro?', async () => {
			await asyncDeleteSaidaEstoque(values.id, () => {
				onHide(true);
			});
		});
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			onHide();
		}
	}

	function verificarEstoqueMinimo() {
		const { produto, quantidade } = values;
		if (produto) {
			const { estoqueMinimo, estoqueSaldo } = produto.registro;
			const novoSaldoEmEstoque = estoqueSaldo - quantidade;

			if (novoSaldoEmEstoque < estoqueMinimo) {
				return 'Quantidade excederá o estoque mínimo';
			}
		}
		return null;
	}

	function validaDocumentoTipo() {
		if (registroSelecionado?.venda && registroSelecionado.venda.documentoTipo === TipoMovimentacaoDocumento.VENDA) {
			return 'venda';
		} else if (
			registroSelecionado?.venda &&
			registroSelecionado.venda.documentoTipo === TipoMovimentacaoDocumento.NFCE
		) {
			return 'NFC-e';
		} else {
			return 'movimentação manual';
		}
	}

	return (
		<Modal
			header={values.id ? 'Editar saída de estoque' : 'Nova saída de estoque'}
			visible={visible}
			onHide={onHide}
		>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ButtonCancelar
						{...informacoesPermissoes}
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={cancelar}
					/>
					<ButtonSalvar {...informacoesPermissoes} disabled={geradoAPartirDeUmaVenda} onClick={salvar} />
					<ButtonNovo
						onClick={onClickNovo}
						hidden={!dirty && !values.id}
						estadoBotao={estadoBotaoNovo}
						{...informacoesPermissoes}
					/>
					<ButtonExcluir
						hidden={!values.id}
						{...informacoesPermissoes}
						disabled={geradoAPartirDeUmaVenda}
						title={
							geradoAPartirDeUmaVenda
								? `Esta movimentação foi gerada automaticamente a partir de uma ${DocumentoTipo} e não pode ser removida.`
								: null
						}
						onClick={excluir}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Message
							style={{ marginTop: '10px', marginBottom: '10px' }}
							hidden={!geradoAPartirDeUmaVenda}
							severity="info"
							text={`Esta movimentação foi gerada automaticamente a partir de uma ${DocumentoTipo} e não pode ser alterada.`}
						/>

						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							name="produto"
							label="Produto"
							autofocus
							component={SingleSelectProduto}
							value={values.produto}
							obrigatorio
							disabled={geradoAPartirDeUmaVenda}
							helpMessage={helpMessage.produto}
							onChange={e => setFieldValue('produto', e)}
							url={`${services.GESTOR}/v1/estoque/movimentacoes/relacoes/produtos`}
							tipoPredefinido="PRODUTO"
							filtroAdicionalUrl=";tipo!=SERVICO;controlarEstoque==true"
							id="EstoqueSaidaSingleSelectProduto"
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={InputMoney}
							label="Quantidade"
							name="quantidade"
							id="quantidade"
							obrigatorio
							size={11}
							disabled={geradoAPartirDeUmaVenda}
							prefix=""
							placeholder=""
							warnings={verificarEstoqueMinimo()}
							helpMessage={helpMessage.quantidade}
							onChange={e => setFieldValue('quantidade', e.target.value)}
							value={values.quantidade}
							allowNegative={false}
							decimalScale={decimaisQtd}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={InputDate}
							obrigatorio
							label="Data/hora da saída"
							name="data"
							disabled={geradoAPartirDeUmaVenda}
							helpMessage={helpMessage.data}
							onChange={e => setFieldValue('data', e.target.value)}
							value={values.data}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={InputField}
							style={{ marginTop: '17px' }}
							type="time"							
							name="hora"
							helpMessage={helpMessage.hora}
							onChange={e => setFieldValue('hora', e.target.value)}
							value={values.hora}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={TextArea}
							placeholder="Escreva sua observação aqui"
							label="Observação"
							name="observacao"
							disabled={geradoAPartirDeUmaVenda}
							helpMessage={helpMessage.observacao}
							onChange={e => setFieldValue('observacao', e.target.value)}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalSaidaEstoque = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: true,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return props.registroSelecionado;
		} else {
			return { ...initialValue, produto: props.produto };
		}
	},

	validate(values) {
		let errors = {};

		if (values.quantidade <= 0) {
			errors.quantidade = 'A quantidade deve ser maior que zero';
		}
		if (values.data && !isValid(parseISO(values.data))) {
			errors.data = mensagensDeValidacao.DATA_INVALIDA;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		produto: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		data: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		quantidade: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		observacao: Yup.string().max(255, 'O campo observação não pode ter mais que 255 caracteres.').nullable(true),
	}),
	handleSubmit: () => {},
})(ModalSaidaEstoqueView);

ModalSaidaEstoque.defaultProps = {
	geradoAPartirDeUmaVenda: false,
	produtoSelecionado: null,
};

ModalSaidaEstoque.propTypes = {
	/** Caso o registro foi gerado através de uma venda, não poderá ser alterada. */
	geradoAPartirDeUmaVenda: propTypes.bool,

	/**Produto selecionado que vem como sugestão */
	produto: propTypes.object,

	/**Registro recebido com os dados para serem carregados no formulário */
	registroSelecionado: propTypes.object,

	/**Função responsável por passar null para o registroSelecionado e para o produto */
	onNovoClick: propTypes.func,
};

export default withRouter(ModalSaidaEstoque);
