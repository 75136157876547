import {
	DADOSLOCALSTORAGE,
	buscarDadosLoginLocalStorage,
	buscarItemLocalStorage,
	copiarParaAreaTransferencia,
	services,
} from 'Common';
import { notify } from 'components';
import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { FaPrint, FaShareAlt } from 'react-icons/fa';
import { asyncImprimirVenda } from '../../../Requests';
import { Tipo } from '../../../Util/constantes';

const styleBotaoImprimir = {
	padding: '0px 10px',
	color: '#333333',
	fontWeight: 'bold',
	fontSize: '15px',
	display: 'flex',
	alignItems: 'center',
	border: 'none',
};

export default function HeaderForm({
	isVenda,
	isMobile,
	setModalEnviarPorEmailVisible,
	setModalEnviarPorWhatsappVisible,
	urlVendaOuOrcamento,
}) {
	const { values, dirty, initialValues } = useFormikContext();
	const [organizacaoId] = useState(buscarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin).organizacao.id);
	const menuCompartilhar = useRef(null);

	async function imprimirVenda() {
		await asyncImprimirVenda(urlVendaOuOrcamento, values.id, organizacaoId, ({ data: pdf }) => {
			const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const relatorio = window.open(arquivoURL);
			if (relatorio) {
				relatorio.onload = () => {
					setTimeout(() => {
						relatorio.document.title = urlVendaOuOrcamento === 'orcamentos' ? 'Orçamento' : 'Pedido de Venda';
					}, 250);
				};
			}
		});
	}

	function copiarLinkCompartilhavel() {
		const organizacaoId = buscarDadosLoginLocalStorage().organizacao.id;
		copiarParaAreaTransferencia(
			`${services.GESTOR}/v1/${urlVendaOuOrcamento}/${values.id}/organizacoes/${organizacaoId}/imprimir`,
			() => {
				notify('Link copiado para área de transferência');
			}
		);
	}

	if (values.id) {
		const title = <span>{initialValues.tipo === Tipo.PEDIDO_VENDA ? 'Pedido de venda ' : 'Orçamento de venda '}</span>;
		const botoesDisabled = dirty;

		const menuCompartilharModel = [
			{
				label: 'Enviar por e-mail',
				icon: 'fa fa-send',
				command: () => setModalEnviarPorEmailVisible(true),
			},
			{
				label: 'Enviar por Whatsapp',
				icon: 'fa fa-whatsapp',
				command: () => setModalEnviarPorWhatsappVisible(true),
			},
			{
				label: 'Copiar link compartilhável',
				icon: 'fa fa-link',
				command: copiarLinkCompartilhavel,
			},
		];

		if (botoesDisabled) {
			return (
				<span
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{title}
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							fontSize: '16px',
							cursor: 'default',
						}}
					>
						<span style={{ ...styleBotaoImprimir, opacity: '0.5' }}>
							<FaShareAlt size="15px" style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Compartilhar</span>}
						</span>
						<span style={{ ...styleBotaoImprimir, opacity: '0.5' }} title="Salve a venda ou orçamento para imprimir">
							<FaPrint size="15px" style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Imprimir</span>}
						</span>
					</span>
				</span>
			);
		} else {
			return (
				<span
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{title}
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							fontSize: '16px',
							cursor: 'pointer',
						}}
					>
						<span
							style={{ ...styleBotaoImprimir, cursor: 'pointer' }}
							onClick={(event) => menuCompartilhar.current.toggle(event)}
						>
							<Menu
								style={{ width: '220px' }}
								model={menuCompartilharModel}
								popup
								ref={menuCompartilhar}
								id="menu_compartilhar"
							/>
							<FaShareAlt size="15px" style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Compartilhar</span>}
						</span>
						<span style={{ ...styleBotaoImprimir }} onClick={imprimirVenda}>
							<FaPrint size="15px" style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Imprimir</span>}
						</span>
					</span>
				</span>
			);
		}
	} else {
		return <div>{isVenda() ? 'Novo pedido de venda ' : 'Novo orçamento de venda'}</div>;
	}
}
