import { permissoes, recursos } from 'Common';
import { Switch, withRouter } from 'react-router-dom';
import PrivateRoute from 'components/Route';

import SpedForm from '../../controladoria/sped/Form';

export const pathControladoriaRoutes = ['/sped'];

function ControladoriaRoutes() {
	return (
		<Switch>
			<PrivateRoute
				exact
				path={['/sped']}
				render={props => <SpedForm {...props} />}
				recurso={recursos.CONTROLADORIA_SPED}
				permissao={permissoes.VISUALIZAR}
			/>
		</Switch>
	);
}

export default withRouter(ControladoriaRoutes);
