import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Prompt as InternalPrompt } from 'react-router-dom';
import { alterouEstadoFormulario } from '../Action';
import usePrevious from '../../../Common/Hooks/usePrevious';

function Prompt(props) {
	const prevProps = usePrevious(props);

	useEffect(() => {
		if (prevProps?.dirty !== props.dirty) {
			alterouEstadoFormulario(props.dirty);
		}
	}, [prevProps]);

	if (props.isModal) {
		return null;
	}
	return (
		<InternalPrompt
			when={props.dirty}
			message={params => (!params.pathname.includes('/cadastro') ? props.message : true)}
		/>
	);
}

Prompt.propTypes = {
	/** Mensagem  */
	message: PropTypes.string,
	/** Se for modal, não renderiza o prompt  */
	isModal: PropTypes.bool,
	dirty: PropTypes.bool,
};

Prompt.defaultProps = {
	dirty: false,
	message: `Tem certeza que deseja sair? \nAs alterações serão perdidas.`,
};

export default Prompt;
