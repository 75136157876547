function converterFormaPagamentoParaFormulario(value) {
	return {
		id: value.id,
		descricao: value.descricao,
		podeExcluir: value.podeExcluir,
		conta: converterContaParaFormulario(value.conta),
		formaPagamentoFiscal: value.formaPagamentoFiscal,
		categoriaReceita: converterCategoriaParaFormulario(value.categoriaReceita),
		categoriaDespesa: converterCategoriaParaFormulario(value.categoriaDespesa),
	};
}

function converterContaParaFormulario(conta) {
	if (conta) {
		return {
			label: conta.nome,
			value: conta.id,
			registro: conta,
		};
	}
	return null;
}

function converterCategoriaParaFormulario(conta) {
	if (conta) {
		return {
			label: conta.nome,
			value: conta.id,
			registro: conta,
		};
	}
	return null;
}

function converterFormaPagamentoParaApi(value) {
	return {
		id: value.id ? value.id : null,
		descricao: value.descricao,
		conta: value.conta ? { id: value.conta.value } : null,
		formaPagamentoFiscal: value.formaPagamentoFiscal,
		categoriaReceita: value.categoriaReceita ? { id: value.categoriaReceita.value } : null,
		categoriaDespesa: value.categoriaDespesa ? { id: value.categoriaDespesa.value } : null,
	};
}

export { converterFormaPagamentoParaFormulario, converterFormaPagamentoParaApi };
