import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormikContext, withFormik } from 'formik';

import { buscarDadosLoginLocalStorage, getSafeValue } from 'Common';
import { AutoProgressBar, Form, FormActions, FormContent, If, Message, Modal } from 'components';

import { MESSAGES_ERRORS } from 'components/Servicos/Utils';
import { ActionButtons } from './components/ActionButtons';
import { CamposPrincipais } from './components/CamposPrincipais';
import { Tabs } from './components/Tabs';

function ModalServicoImpl({
	municipioId,
	visibleModalServico,
	onHide,
	servicos,
	onChangeServico,
	indexServico,
	disabled,
	informacoesPermissoes,
	montarServico,
	setFieldValueServico,
	utilizaTabelaPreco,
	calcularValorVenda,
	tabelaPreco,
	calcularPercentualAdicionalTabelaPreco,
}) {
	const { handleReset, dirty, errors, submitCount, setFieldValue, values } = useFormikContext();
	const [showMessage, setShowMessage] = useState(false);
	const [atualizarSubTotal, setAtualizarSubTotal] = useState(false);

	const filialConectada = buscarDadosLoginLocalStorage()?.filialConectada;
	const decimaisQtd = filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
	const decimaisPreco = filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;
	const utilizaTabelaPrecoDescontoQuantidade = filialConectada?.parametrosVendas?.utilizaTabelaPrecoDescontoQuantidade;

	useEffect(() => {
		if (atualizarSubTotal) {
			const subtotal = parseFloat(
				(getSafeValue(values?.quantidade) * getSafeValue(values?.valor) - getSafeValue(values?.desconto)).toFixed(2)
			);

			const comissaoValor = values.comissaoPercentual
				? parseFloat((values.subtotal * (values.comissaoPercentual / 100)).toFixed(2))
				: 0;

			setFieldValue('subtotal', subtotal);
			setFieldValue('comissaoValor', comissaoValor);

			setAtualizarSubTotal(false);
		}
	}, [atualizarSubTotal]);

	useEffect(() => {
		const fieldsToValidate = ['cstPis', 'cstCofins'];

		Object.keys(errors)?.some((key) => fieldsToValidate.indexOf(key) >= 0)
			? setShowMessage(true)
			: setShowMessage(false);
	}, [errors]);

	function montarTextoMessage() {
		return (
			<span>
				{Object.entries(errors).map(([field]) => {
					switch (field) {
						case 'cstPis':
							return formatField('CST PIS');
						case 'cstCofins':
							return formatField('CST COFINS');
						default:
							return null;
					}
				})}
			</span>
		);
	}

	function formatField(field) {
		return <div key={field}>{field} - Obrigatório</div>;
	}

	return (
		<Modal
			header="Dados do serviço"
			visible={visibleModalServico}
			onHide={() => {
				if (dirty) {
					handleReset();
				} else {
					onHide();
				}
			}}
			styleModal={{
				minWidth: '60%',
			}}
		>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ActionButtons
						indexServico={indexServico}
						setFieldValueServico={setFieldValueServico}
						onChangeServico={onChangeServico}
						onHide={onHide}
						informacoesPermissoes={informacoesPermissoes}
						disabled={disabled}
					/>
				</FormActions>
				<FormContent>
					<If test={showMessage && submitCount}>
						<Message severity="error" text={montarTextoMessage()} />
					</If>
					<CamposPrincipais
						municipioId={municipioId}
						montarServico={montarServico}
						decimaisQtd={decimaisQtd}
						decimaisPreco={decimaisPreco}
						disableFields={disabled}
						informacoesPermissoes={informacoesPermissoes}
						tabelaPreco={tabelaPreco}
						utilizaTabelaPreco={utilizaTabelaPreco}
						servicoForm={servicos[indexServico]}
						utilizaTabelaPrecoDescontoQuantidade={utilizaTabelaPrecoDescontoQuantidade}
						setAtualizarSubTotal={setAtualizarSubTotal}
						calcularValorVenda={calcularValorVenda}
						calcularPercentualAdicionalTabelaPreco={calcularPercentualAdicionalTabelaPreco}
					/>
					<Tabs informacoesPermissoes={informacoesPermissoes} disabled={disabled} />
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalServico = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: true,

	mapPropsToValues(props) {
		if (props.servicos[props.indexServico]) {
			return props.servicos[props.indexServico];
		}
		return null;
	},

	validate: (values) => {
		const errors = {};

		if (!values.tributos?.pis.cst) {
			errors.cstPis = MESSAGES_ERRORS.CAMPO_OBRIGATORIO;
		}
		if (!values.tributos?.cofins.cst) {
			errors.cstCofins = MESSAGES_ERRORS.CAMPO_OBRIGATORIO;
		}
		if (values.tributos?.quantidade <= 0) {
			errors.quantidade = 'Quantidade inválida';
		}
		if (values.desconto >= values.valor) {
			errors.desconto = 'Desconto inválido';
		}
		if (values.valor <= 0) {
			errors.valor = 'Valor inválido';
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		servico: Yup.object().nullable().required(MESSAGES_ERRORS.CAMPO_OBRIGATORIO),
		unidadeMedida: Yup.object().nullable().required(MESSAGES_ERRORS.CAMPO_OBRIGATORIO),
		quantidade: Yup.string().nullable().required(MESSAGES_ERRORS.CAMPO_OBRIGATORIO),
		valor: Yup.string().nullable().required(MESSAGES_ERRORS.CAMPO_OBRIGATORIO),
		codigoServico: Yup.object().nullable().required(MESSAGES_ERRORS.CAMPO_OBRIGATORIO),
		operacaoFiscal: Yup.object().nullable().required(MESSAGES_ERRORS.CAMPO_OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalServicoImpl);

export { ModalServico };
