import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useFormikContext, withFormik } from 'formik';

import { buscarDadosLoginLocalStorage, copiarObjeto, gerarUUID, montarCalcularImpostosServico } from 'Common';
import { If, Grid, Col, ButtonAdicionarItem } from 'components';

import { ListaServicos } from './components/ListaServicos';
import { NOVO_SERVICO } from './Utils';
import { ModalServico } from './components/ModalServico';

function ServicosImpl({
	municipioId,
	tabelaPreco,
	pagamentos,
	informacoesPermissoes,
	disabled,
	disabledButtonAdd,
	color,
	isMobile,
	isTablet,
	onChangeServicos,
	onChangeServico,
	calcularPercentualAdicionalTabelaPreco,
	atualizarTotalizadores,
	operacaoFiscalHeader,
	comissaoHeader,
	descontoMaximoVendedor,
	descontoMaximoSuperior,
	utilizaPrecoAtacado,
	recalcularTodosOsServicosComTabelaPreco,
	setRecalcularTodosOsServicosComTabelaPreco,
}) {
	const { values, setFieldValue } = useFormikContext();
	const [visibleModalServico, setVisibleModalServico] = useState(false);
	const [indexModalServico, setIndexModalServico] = useState(null);
	const [atualizarServicos, setAtualizarServicos] = useState(false);

	const filialConectada = buscarDadosLoginLocalStorage()?.filialConectada;

	const utilizaTabelaPreco = filialConectada?.parametrosVendas?.utilizaTabelaPrecoParaServico;
	const decimaisPreco = filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	useEffect(() => {
		if (atualizarServicos) {
			onChangeServicos(values.servicos);
			setAtualizarServicos(false);
		}
	}, [atualizarServicos]);

	function handleClickAdicionarServico() {
		setFieldValue('servicos', [
			...values.servicos,
			{
				...copiarObjeto(NOVO_SERVICO),
				id: gerarUUID(),
				item: values.servicos.length + 1,
				operacaoFiscal: values?.operacaoFiscal ?? null,
			},
		]);

		setTimeout(() => {
			const elementHtmlProduto = document
				.getElementById('id-servicos-listagem')
				?.getElementsByTagName('table')[0]
				?.getElementsByTagName('tr')
				[values.servicos.length + 1]?.getElementsByClassName('id-servicos-listagem-campo-servico')[0];
			elementHtmlProduto?.click();
		}, 200);

		setAtualizarServicos(true);
	}

	async function montarServico(serv, value) {
		let servico = serv;
		if (value) {
			if (utilizaTabelaPreco) {
				const adicionalTabelaPreco = await calcularPercentualAdicionalTabelaPreco(servico.quantidade);
				servico.adicionalTabelaPreco = { percentual: adicionalTabelaPreco.percentual, tipo: adicionalTabelaPreco.tipo };
			}

			let subtotal = value.registro.preco;
			if (utilizaPrecoAtacado && value.registro?.precoAtacado > 0) {
				const valor = utilizaTabelaPreco
					? calcularValorVenda(value.registro?.precoAtacado, servico.adicionalTabelaPreco)
					: value.registro?.precoAtacado;

				servico.valor = parseFloat(valor.toFixed(decimaisPreco));
				servico.valorOriginal = parseFloat(value.registro?.precoAtacado.toFixed(decimaisPreco));
				subtotal = servico.quantidade * valor;
			} else {
				const valor = utilizaTabelaPreco
					? calcularValorVenda(value.registro?.preco, servico.adicionalTabelaPreco)
					: value.registro?.preco;

				servico.valor = parseFloat(valor.toFixed(decimaisPreco));
				servico.valorOriginal = parseFloat(value.registro?.preco.toFixed(decimaisPreco));
				subtotal = servico.quantidade * valor;
			}

			servico.alterouValorManualmente = false;

			servico.subtotal = subtotal;

			if (servico?.desconto > 0) {
				servico.subtotal -= servico.desconto;
			}

			servico.subtotal = parseFloat(servico.subtotal.toFixed(2));

			if (value.registro?.comissao || comissaoHeader) {
				let comissaoPercentual = 0;
				value.registro?.comissao
					? (comissaoPercentual = value.registro?.comissao)
					: (comissaoPercentual = comissaoHeader ?? 0);

				servico.comissaoPercentual = comissaoPercentual;
				servico.comissaoValor =
					comissaoPercentual > 0 ? parseFloat(((comissaoPercentual / 100) * servico.subtotal).toFixed(2)) : 0;
			} else {
				servico.comissaoPercentual = 0;
				servico.comissaoValor = 0;
			}
			operacaoFiscalHeader?.value && !servico.operacaoFiscal ? (servico.operacaoFiscal = operacaoFiscalHeader) : null;

			if (value.registro?.descontoMaximo || descontoMaximoVendedor || descontoMaximoSuperior) {
				if (descontoMaximoSuperior) {
					servico.descontoMaximoPercentual = descontoMaximoSuperior;
				} else {
					const maxDesconto = value.registro?.descontoMaximo ?? descontoMaximoVendedor;
					servico.descontoMaximoPercentual = descontoMaximoVendedor
						? Math.min(maxDesconto, descontoMaximoVendedor)
						: maxDesconto;
				}
			} else {
				servico.descontoMaximoPercentual = 100;
			}

			servico.descontoMaximoVendedor = descontoMaximoSuperior || descontoMaximoVendedor;
			servico.descontoMaximoTabela = value.registro?.descontoMaximo || 100;

			value.registro.unidadeMedida
				? (servico.unidadeMedida = {
						label: `${value.registro.unidadeMedida?.unidade} - ${value.registro.unidadeMedida?.descricao}`,
						value: value.registro.unidadeMedida?.id,
						registro: value.registro.unidadeMedida,
					})
				: (servico.unidadeMedida = null);
			value.registro.codigoServico
				? (servico.codigoServico = {
						label: `${value.registro.codigoServico?.codigo} - ${value.registro.codigoServico?.descricao}`,
						value: value.registro.codigoServico?.id,
						registro: value.registro.codigoServico,
					})
				: (servico.codigoServico = null);

			if (utilizaTabelaPreco && value.registro?.tabelaPreco) {
				servico.utilizouTabelaPromocional = value.registro?.tabelaPreco?.tabelaPromocional;
				servico.tabelaPreco = {
					label: `${value.registro?.tabelaPreco.codigo} - ${value.registro?.tabelaPreco.nome}`,
					registro: value.registro?.tabelaPreco,
					value: value.registro?.tabelaPreco.id,
				};
			}

			if (servico && servico.operacaoFiscal && municipioId) {
				servico = await montarCalcularImpostosServico(municipioId, servico);
				atualizarTotalizadores(true);
			}
		}

		return servico;
	}

	function calcularValorVenda(valor, adicionalTabelaPreco) {
		let preco = valor;
		const percentual = adicionalTabelaPreco.percentual / 100;

		if (adicionalTabelaPreco.tipo === 'ACRESCIMO') {
			preco += valor * percentual;
		} else if (adicionalTabelaPreco.tipo === 'DESCONTO') {
			preco -= valor * percentual;
			preco = Math.max(preco, 0);
		}

		return preco;
	}

	return (
		<>
			<Grid>
				<ListaServicos
					isMobile={isMobile}
					isTablet={isTablet}
					disabled={disabled}
					descontoMaximoSuperior={descontoMaximoSuperior}
					montarServico={montarServico}
					informacoesPermissoes={informacoesPermissoes}
					setVisibleModalServico={setVisibleModalServico}
					setIndexModalServico={setIndexModalServico}
					setAtualizarServicos={setAtualizarServicos}
					adicionarNovoServico={handleClickAdicionarServico}
					onChangeServico={onChangeServico}
					onChangeServicos={onChangeServicos}
					utilizaTabelaPreco={utilizaTabelaPreco}
					operacaoFiscalHeader={operacaoFiscalHeader}
					tabelaPreco={tabelaPreco}
					pagamentos={pagamentos}
					calcularValorVenda={calcularValorVenda}
					calcularPercentualAdicionalTabelaPreco={calcularPercentualAdicionalTabelaPreco}
					recalcularTodosOsServicosComTabelaPreco={recalcularTodosOsServicosComTabelaPreco}
					setRecalcularTodosOsServicosComTabelaPreco={setRecalcularTodosOsServicosComTabelaPreco}
				/>
			</Grid>

			<Grid style={{ paddingTop: '10px' }}>
				<Col sm="8" md="8" lg="8" xl="8" style={{ padding: '8px 0px 0px 0px' }}>
					<ButtonAdicionarItem
						label="Adicionar serviço"
						style={{
							background: 'none',
							border: 'none',
							fontWeight: 'bold',
							boxShadow: 'none',
							padding: '0rem 1.2rem',
							color: color,
						}}
						onClick={handleClickAdicionarServico}
						disabled={!informacoesPermissoes?.podeInserir || disabledButtonAdd}
					/>
				</Col>
			</Grid>

			<If test={visibleModalServico}>
				<ModalServico
					municipioId={municipioId}
					visibleModalServico={visibleModalServico}
					servicos={values?.servicos}
					onHide={() => setVisibleModalServico(false)}
					onChangeServico={onChangeServico}
					indexServico={indexModalServico}
					disabled={disabled}
					informacoesPermissoes={informacoesPermissoes}
					montarServico={montarServico}
					setFieldValueServico={setFieldValue}
					utilizaTabelaPreco={utilizaTabelaPreco}
					calcularValorVenda={calcularValorVenda}
					tabelaPreco={tabelaPreco}
					calcularPercentualAdicionalTabelaPreco={calcularPercentualAdicionalTabelaPreco}
				/>
			</If>
		</>
	);
}

const ServicosFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues({ servicos }) {
		if (servicos?.length > 0) {
			return { servicos: [...servicos] };
		}
		return { servicos: [] };
	},

	mapPropsToErrors({ errors }) {
		return errors ?? [];
	},
	handleSubmit: () => {},
})(ServicosImpl);

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
		isTablet: state.dispositivo.isTablet,
		isLessHd: state.dispositivo.isLessHd,
	};
}

export const Servicos = connect(mapStateToProps)(ServicosFormik);
