import { get } from "../../../../../../../../Common/Requisicoes";
import { services } from "../../../../../../../../Common/Constantes/api";

export function buscarProdutos(page = 0, onSuccess, onError) {
  return get(
    `${services.GESTOR}/v1/dashboards/estoque/produtos_abaixo_do_estoque_minimo?page=${page}`,
    null,
    onSuccess,
    onError,
    false
  );
}
