import { actionTypes } from '../../Common/Constantes/reduxTypes';

export default (state = false, action) => {
	switch (action.type) {
		case actionTypes.MENU_LATERAL_ATUALIZAR_FILIAL: {
			return action.payload;
		}
		default: {
			return state;
		}
	}
};
