import { useState, useEffect, useCallback } from 'react';
import format from 'date-fns/format';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import {
  Modal,
  ButtonCancelar,
  estadosBotaoCancelar,
  Form,
  FormActions,
  FormContent,
  NenhumRegistroEncontrado,
  Paginacao,
} from 'components';
import { construirUrl, services } from 'Common';

import { asyncGetDocumentoHistorico } from './Requests';
import { buscarIconeHistorico } from './Util';

function DocumentoHistorico(props) {
  const { idDocumento, visible, onHide } = props;
  const [registros, setRegistros] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [sortField, setSortField] = useState('dataHora');
  const [sortOrder, setSortOrder] = useState(-1);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);

  useEffect(() => {
    if (idDocumento) {
      pesquisar();
    }
  }, []);

  const pesquisarCallback = useCallback(e => {
    pesquisar();
  });

  useEffect(() => {
    pesquisarCallback();
  }, [page, rows, sortOrder, sortField]);

  function pesquisar() {
    const url = construirUrl(
      `${services.GESTOR}/v1/documentos/${idDocumento}/historico`,
      '?query=()',
      rows,
      page,
      sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
    );

    asyncGetDocumentoHistorico(url, ({ data: documentos }) => {
      setRegistros(documentos.content);
      setTotalElements(documentos.totalElements);
    });
  }

  function onPageChange(e) {
    setRows(e.rows);
    setPage(e.page);
  }

  function onSort(e) {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  }

  function renderUsuario(row) {
    if (row.nome && row.nome) {
      if (row.nome) {
        return `${row.nome} (${row.email})`;
      } else {
        return `${row.email}`;
      }
    } else {
      return `Usuário não cadastrado no sistema`;
    }
  }

  function renderData(row) {
    if (row.dataHora) {
      return <span>{format(new Date(row.dataHora), 'dd/MM/yyyy HH:mm:ss')}</span>;
    }
    return '';
  }

  function renderOperacao(row) {
    let icon = buscarIconeHistorico(row.operacao);
    return (
      <>
        <i className={icon}></i>
        &nbsp;&nbsp;&nbsp;{row.operacao}
      </>
    );
  }

  return (
    <>
      <Modal header="Histórico do documento" visible={visible} onHide={onHide}>
        <Form>
          <FormActions>
            <ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
          </FormActions>
          <FormContent>
            <DataTable
              className="table"
              responsive
              value={registros}
              sortField={sortField}
              sortOrder={sortOrder}
              onSort={onSort}
              emptyMessage={<NenhumRegistroEncontrado />}
            >
              <Column field="dataHora" header="Data" body={row => renderData(row)} sortable />
              <Column field="nome" header="Usuário" body={row => renderUsuario(row)} />
              <Column field="operacao" header="Operação" body={row => renderOperacao(row)} />
            </DataTable>
            <Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
          </FormContent>
        </Form>
      </Modal>
    </>
  );
}

export default DocumentoHistorico;
