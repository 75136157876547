import { useRef, useEffect } from 'react';

const usePrevious = (value, initialValue) => {
	const ref = useRef(initialValue);
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

// retorna o antes e depois das dependencias de um useEffect. Util para debugar qual valor chamou o useEffect e comparar o antes e depois
const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
	const previousDeps = usePrevious(dependencies, []);

	const changedDeps = dependencies.reduce((accum, dependency, index) => {
		if (dependency !== previousDeps[index]) {
			const keyName = dependencyNames[index] || index;
			return {
				...accum,
				[keyName]: {
					after: dependency,
					before: previousDeps[index],
				},
			};
		}

		return accum;
	}, {});

	return changedDeps;
};

export default useEffectDebugger;
