import { TIPO_ALINHAMENTO } from "../components/EtiquetaCampos/components/ModalCampo/components/RichTextConfiguration/Util/constantes";

const TIPO_PAGINA_CAMPOS = {
  PAGINA: "PAGINA",
  CAMPOS: "CAMPOS",
};

const OPTIONS_PAGINA_CAMPOS = [
  { label: "Página", value: TIPO_PAGINA_CAMPOS.PAGINA },
  { label: "Campos", value: TIPO_PAGINA_CAMPOS.CAMPOS },
];

const TIPO_PAPEL_ETIQUETA = {
  FOLHA: "FOLHA",
  BOBINA: "BOBINA",
};

const OPTIONS_TIPO_PAPEL_ETIQUETA = [
  { label: "Folha", value: TIPO_PAPEL_ETIQUETA.FOLHA },
  { label: "Bobina", value: TIPO_PAPEL_ETIQUETA.BOBINA },
];

const TIPO_CODIGO_BARRA_CAMPO_ETIQUETA = {
  BCCODEEAN8: "BCCODEEAN8",
  BCCODEEAN13: "BCCODEEAN13",
  BCCODEEAN128: "BCCODEEAN128",
  BCCODE128: "BCCODE128",
  BCCODECODABAR: "BCCODECODABAR",
  BCCODE39: "BCCODE39",
};

const OPTIONS_TIPO_CODIGO_BARRA_CAMPO_ETIQUETA = [
  { label: "EAN 8", value: "BCCODEEAN8" },
  { label: "EAN 13", value: "BCCODEEAN13" },
  { label: "EAN 128", value: "BCCODEEAN128" },
  { label: "Code 128", value: "BCCODE128" },
  { label: "Code 39", value: "BCCODE39" },
  { label: "Codabar", value: "BCCODECODABAR" },
];

const COEFICIENTE_CM_PARA_PIXEL = 37.7952755905512;
const COEFICIENTE_FONT_SIZE_PARA_PIXEL = 1.333;

const INITIAL_VALUE = {
  id: null,
  codigo: null,
  descricao: null,
  tipo: TIPO_PAGINA_CAMPOS.PAGINA,
  tipoPapel: TIPO_PAPEL_ETIQUETA.FOLHA,
  //dados página
  alturaPagina: 0,
  larguraPagina: 0,
  linhasPorPagina: 0,
  etiquetasPorLinha: 0,
  espacamentoColuna: 0,
  espacamentoLinha: 0,
  margemSuperior: 0,
  margemInferior: 0,
  margemDireita: 0,
  margemEsquerda: 0,
  //dados Etiqueta
  alturaEtiqueta: 0,
  larguraEtiqueta: 0,
  exibirContornoCampos: false,
  exibirContornoEtiqueta: false,

  campos: [],
};
const INITIAL_VALUES_CAMPO = {
  id: null,
  campo: null,
  textoLivre: null,
  posicaoVertical: 0,
  posicaoHorizontal: 0,
  altura: 8,
  largura: 20,
  tamanhoFonte: 8,
  rotacao: 0,
  negrito: false,
  italico: false,
  sublinhado: false,
  quebraLinha: false,
  alinhamento: TIPO_ALINHAMENTO.ESQUERDA,
  tamanhoAutomatico: false,
  tipoCodigoDeBarra: TIPO_CODIGO_BARRA_CAMPO_ETIQUETA.BCCODEEAN13,
  zoom: 0,
  mostrarCodigoNaBarra: false,
};

const DRAG_AND_DROP_TYPES = {
  FIELDS_ETIQUETA: "FIELDS_ETIQUETA",
};

const OPTIONS_MOSTRAR_CODIGO_BARRAS = [
  { label: "Sim", value: true },
  { label: "Não", value: false },
];

export {
  TIPO_PAGINA_CAMPOS,
  OPTIONS_PAGINA_CAMPOS,
  TIPO_PAPEL_ETIQUETA,
  OPTIONS_TIPO_PAPEL_ETIQUETA,
  TIPO_CODIGO_BARRA_CAMPO_ETIQUETA,
  OPTIONS_TIPO_CODIGO_BARRA_CAMPO_ETIQUETA,
  COEFICIENTE_CM_PARA_PIXEL,
  COEFICIENTE_FONT_SIZE_PARA_PIXEL,
  INITIAL_VALUE,
  INITIAL_VALUES_CAMPO,
  DRAG_AND_DROP_TYPES,
  OPTIONS_MOSTRAR_CODIGO_BARRAS,
};
