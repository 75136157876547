import { Switch, withRouter } from 'react-router-dom';
import { permissoes, recursos } from 'Common';
import PrivateRoute from 'components/Route';

import { OrdemServico } from 'views/fiscal/servicos/OrdemServico';
import { OrdemServicoForm } from 'views/fiscal/servicos/OrdemServico/Form';
import OrdemServicoProvider from 'views/fiscal/servicos/OrdemServico/Context';

import NFSe from 'views/fiscal/servicos/NFSe';
import NFSeForm from 'views/fiscal/servicos/NFSe/Form';
import NFSeProvider from 'views/fiscal/servicos/NFSe/Context';
import RelatoriosServicos from 'views/fiscal/servicos/Relatorios';

export const pathServicosRoutes = [
  '/ordem_servico',
  '/ordem_servico/cadastro',
  '/ordem_servico/cadastro/:id',
  '/nfses',
  '/nfses/cadastro',
  '/nfses/cadastro/:id',
  '/relatorios_servicos',
];

function ServicosRoutes() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/ordem_servico"
        render={props => (
          <OrdemServicoProvider>
            <OrdemServico {...props} />
          </OrdemServicoProvider>
        )}
        recurso={recursos.SERVICOS_ORDEM_SERVICO}
        permissao={permissoes.VISUALIZAR}
      />
      <PrivateRoute
        exact
        path={['/ordem_servico/cadastro', '/ordem_servico/cadastro/:id']}
        render={props => (
          <OrdemServicoProvider>
            <OrdemServicoForm {...props} />
          </OrdemServicoProvider>
        )}
        recurso={recursos.SERVICOS_ORDEM_SERVICO}
        permissao={permissoes.VISUALIZAR}
      />

      <PrivateRoute
        exact
        path={['/nfses']}
        render={props => (
          <NFSeProvider>
            <NFSe {...props} />
          </NFSeProvider>
        )}
        recurso={recursos.SERVICOS_NFSE}
        permissao={permissoes.VISUALIZAR}
      />
      <PrivateRoute
        exact
        path={['/nfses/cadastro', '/nfses/cadastro/:id']}
        render={props => (
          <NFSeProvider>
            <NFSeForm {...props} />
          </NFSeProvider>
        )}
        recurso={recursos.SERVICOS_NFSE}
        permissao={permissoes.VISUALIZAR}
      />
      <PrivateRoute
        exact
        path={['/relatorios_servicos']}
        render={props => <RelatoriosServicos {...props} />}
        recurso={recursos.SERVICOS_RELATORIOS}
        permissao={permissoes.VISUALIZAR}
      />
    </Switch>
  );
}

export default withRouter(ServicosRoutes);
