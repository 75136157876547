import { Field, withFormik } from 'formik';
import { useEffect } from 'react';
import {
	AutoProgressBar,
	Modal,
	Form,
	FormActions,
	FormContent,
	ButtonCancelar,
	estadosBotaoCancelar,
	ButtonSalvar,
	InputField,
	InputMask,
	Grid,
	If,
	SingleSelectMunicipio,
} from '../../../..';

function ModalDadosTransportador(props) {
	const { visible, values, onHide, setFieldValues, informacoesPermissoes } = props;
	const estadoBotaoCancelar = props.dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;

	function cancelar() {
		props.dirty ? props.handleReset() : onHide();
	}

	function salvar() {
		setFieldValues('transporte', values);
		onHide();
	}

	function onChangeNome(event) {
		props.setFieldValue('transportador.registro.nome', event.target.value);
		props.setFieldValue('transportador.label', `${values.transportador.registro.codigo} - ${event.target.value}`);
	}

	return (
		<Modal header="Dados da transportadora" visible={visible} onHide={() => onHide()}>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ButtonCancelar onClick={cancelar} estadoBotao={estadoBotaoCancelar} {...informacoesPermissoes} />
					<ButtonSalvar onClick={salvar} disabled={!props.dirty} {...informacoesPermissoes} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="6"
							md="6"
							lg="6"
							xl="6"
							component={InputField}
							size={60}
							label="Nome"
							name="nome"
							value={values.transportador.registro?.nome}
							onChange={(e) => onChangeNome(e)}
							{...informacoesPermissoes}
						/>
						<If test={values.transportador.registro?.pessoaFisica?.cpf}>
							<Field
								sm="6"
								md="6"
								lg="3"
								xl="3"
								component={InputMask}
								mask="000.000.000-00"
								placeholder="   .   .   -  "
								size={11}
								label="CPF"
								name="cpf"
								value={values.transportador.registro?.pessoaFisica?.cpf}
								onChange={(e) => props.setFieldValue('transportador.registro.pessoaFisica.cpf', e.target.value)}
								{...informacoesPermissoes}
							/>
						</If>
						<If test={values.transportador.registro?.pessoaJuridica?.cnpj}>
							<Field
								sm="6"
								md="6"
								lg="3"
								xl="3"
								component={InputMask}
								size={14}
								mask="00.000.000/0000-00"
								placeholder="  .   .   /    -  "
								label="CNPJ"
								name="cnpj"
								value={values.transportador.registro?.pessoaJuridica?.cnpj}
								onChange={(e) => props.setFieldValue('transportador.registro.pessoaJuridica.cnpj', e.target.value)}
								{...informacoesPermissoes}
							/>
						</If>
						<Field
							sm="6"
							md="6"
							lg="3"
							xl="3"
							component={InputField}
							size={14}
							label="Inscricao Estadual"
							name="inscricaoEstadual"
							value={values.transportador.registro?.inscricaoEstadual}
							onChange={(e) => props.setFieldValue('transportador.registro.inscricaoEstadual', e.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="6"
							lg="3"
							xl="3"
							component={InputField}
							size={20}
							label="RNTC"
							name="RNTC"
							value={values.veiculo.RNTC}
							onChange={(e) => props.setFieldValue('veiculo.RNTC', e.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="6"
							lg="6"
							xl="6"
							component={InputField}
							size={60}
							label="Endereço"
							name="endereco"
							value={values.transportador.endereco?.enderecoString}
							onChange={(e) => props.setFieldValue('transportador.endereco.enderecoString', e.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="6"
							lg="3"
							xl="3"
							component={SingleSelectMunicipio}
							label="Município"
							name="municipio"
							value={values.transportador.endereco?.municipio}
							onChange={(e) => props.setFieldValue('transportador.endereco.municipio', e)}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

ModalDadosTransportador = withFormik({
	mapPropsToValues(props) {
		return props.transporte;
	},

	handleSubmit: () => {},
})(ModalDadosTransportador);

export default ModalDadosTransportador;
