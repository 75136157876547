import { TabPanel } from 'primereact/tabview';
import { useFormikContext } from 'formik';

import { Grid, TabView } from 'components';

import { useContextNFSe } from '../../../Context';
import { ClassNameTabViewColor } from '../../../Util/constantes';
import { InformacoesComplementares } from './components/InformacoesComplementares';
import { TabPagamentos } from './components/TabPagamentos';
import { Totalizadores } from './components/Totalizadores';

import '../../Styles/index.css';

function TabsServicos({
	informacoesPermissoes,
	isMobile,
	utilizaTabelaPreco,
	buscarTabelaPreco,
	buscarMunicipioIdPessoa,
}) {
	const { values } = useFormikContext();
	const { activeTab, setActiveTab } = useContextNFSe();

	return (
		<Grid style={{ width: '100%', margin: 0, padding: 0, display: 'block' }} className="step-nfse-totalizadores">
			<TabView
				activeIndex={activeTab}
				onTabChange={(event) => {
					setActiveTab(event.index);
				}}
				{...informacoesPermissoes}
				className={`tab-view ${ClassNameTabViewColor[values.situacao]}`}
			>
				<TabPanel header="Totalizadores">
					<Totalizadores isMobile={isMobile} />
				</TabPanel>
				<TabPanel header="Pagamentos">
					<TabPagamentos informacoesPermissoes={informacoesPermissoes} />
				</TabPanel>
				<TabPanel header="Informações complementares">
					<InformacoesComplementares
						informacoesPermissoes={informacoesPermissoes}
						utilizaTabelaPreco={utilizaTabelaPreco}
						buscarTabelaPreco={buscarTabelaPreco}
						buscarMunicipioIdPessoa={buscarMunicipioIdPessoa}
					/>
				</TabPanel>
			</TabView>
		</Grid>
	);
}

export default TabsServicos;
