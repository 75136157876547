import { useState } from 'react';
import { If, ListagemRelatorios } from 'components';
import { modulos } from 'Common';
import ModalImprimirPagamentosRecebidosPorPeriodo from './components/ModalImprimirPagamentosRecebidosPorPeriodo';
import ModalImprimirComissaoPorAgenteAutorizado from './components/ModalImprimirComissaoPorAgenteAutorizado';

function AdministracaoRelatorios() {
	const [exibirModalImprimirPagamentosRecebidosPorPeriodo, setExibirModalImprimirPagamentosRecebidosPorPeriodo] = useState(false);
	const [exibirModalImprimirComissaoPorAgenteAutorizado, setExibirModalImprimirComissaoPorAgenteAutorizado] =
		useState(false);

	const metodosImprimir = [
		{
			nome: 'PAGAMENTOS_RECEBIDOS_PERIODO',
			onImprimir: function () {
				setExibirModalImprimirPagamentosRecebidosPorPeriodo(true);
			},
		},
		{
			nome: 'COMISSAO_POR_AGENTE_AUTORIZADO',
			onImprimir: function () {
				setExibirModalImprimirComissaoPorAgenteAutorizado(true);
			},
		},
	];

	return (
		<>
			<ListagemRelatorios
				header="Relatórios administração"
				modulo={modulos.ADMINISTRACAO}
				metodosImprimir={metodosImprimir}
			/>
			<If test={exibirModalImprimirPagamentosRecebidosPorPeriodo}>
				<ModalImprimirPagamentosRecebidosPorPeriodo
					visible={exibirModalImprimirPagamentosRecebidosPorPeriodo}
					onHide={() => setExibirModalImprimirPagamentosRecebidosPorPeriodo(false)}
				/>
			</If>

			<If test={exibirModalImprimirComissaoPorAgenteAutorizado}>
				<ModalImprimirComissaoPorAgenteAutorizado
					visible={exibirModalImprimirComissaoPorAgenteAutorizado}
					onHide={() => setExibirModalImprimirComissaoPorAgenteAutorizado(false)}
				/>
			</If>
		</>
	);
}

export default AdministracaoRelatorios;
