import { manterApenasNumeros } from '../../../../Common/Mascara';
import { retornarAplicacao } from '../../../Util';

export function converterCadastroParaApi(values) {
	return {
		...values,
		cpf: values.tipo === 'FISICA' ? manterApenasNumeros(values.cpf) : null,
		cnpj: values.tipo === 'JURIDICA' ? manterApenasNumeros(values.cnpj) : null,
		telefone: manterApenasNumeros(values.telefone),
		aplicacao: retornarAplicacao(),
		agenteAutorizado: values.agenteAutorizado
			? {
					id: values.agenteAutorizado,
			  }
			: null,
	};
}
