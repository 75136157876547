import { Button } from 'primereact/button';
import Dropzone from 'react-dropzone';
import { alert } from '../../../../../components/Toast';
import certificadoAdicionadoImg from './Images/certificado.svg';

const selecionarCertificadoStyle = {
	background: 'none',
	border: 'none',
	fontWeight: 'bold',
	boxShadow: 'none',
	color: '#006095',
};

const divPrincipalStyle = {
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	marginTop: '15px',
};

const certificadoDivStyle = {
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
};

const nenhumCertificadoEncontradoStyle = {
	fontWeight: 'bold',
};

const certificadoNomeStyle = {
	fontWeight: 'bold',
	marginLeft: '10px',
	marginTop: '5px',
	marginBottom: '5px',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
};

function CertificadoUpload({ value, onChange, podeEditarEmpresa }) {
	function onUploadCertificado(files, error) {
		if (error) {
			alert('Problema ao adicionar o certificado', 'Só são permitidos arquivos com extensão .pfx');
		} else {
			onChange(files[0]);
		}
	}

	function renderDropzone(label) {
		return (
			<Dropzone
				multiple={false}
				accept=".pfx"
				onDropAccepted={(e) => onUploadCertificado(e, false)}
				onDropRejected={(e) => onUploadCertificado(e, true)}
				disabled={!podeEditarEmpresa}
			>
				{({ getRootProps, getInputProps }) => (
					<div {...getRootProps()}>
						<input {...getInputProps()} />
						<Button
							label={label}
							style={{
								...selecionarCertificadoStyle,
								opacity: !podeEditarEmpresa ? '0.5' : '1',
							}}
							title={!podeEditarEmpresa ? 'Você não possui permissão para editar' : null}
						/>
					</div>
				)}
			</Dropzone>
		);
	}

	if (value) {
		return (
			<div style={divPrincipalStyle}>
				<div style={certificadoDivStyle}>
					<img width="70px" src={certificadoAdicionadoImg} alt="Certificado" />
					<div style={{ maxWidth: 'calc(100% - 70px)' }}>
						<p style={certificadoNomeStyle}>{value && value.name}</p>
						{renderDropzone('ALTERAR CERTIFICADO')}
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div style={divPrincipalStyle}>
				<p style={nenhumCertificadoEncontradoStyle}> Nenhum certificado digital encontrado </p>
				{renderDropzone('SELECIONAR CERTIFICADO')}
			</div>
		);
	}
}

export default CertificadoUpload;
