export const tutorialStepsListagens = [
	{
		target: 'body',
		content: `Esta é uma das telas de pesquisa. Nela você pode pesquisar, adicionar, editar e remover registros`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-listagem-novo',
		content: 'No botão novo, você pode inserir novos registros',
		placement: 'top',
	},
	{
		target: '.step-listagem-input-search',
		content: 'Neste campo, você pode pesquisar o registro desejado',
		placement: 'top',
	},
	{
		target: '.step-listagem-filtro-avancado',
		content: 'Neste campo, você pode configurar filtros para otimizar a busca por registros específicos',
		placement: 'top',
	},
	{
		target: '.step-listagem-order',
		content: 'Você pode clicar no título da coluna para ordenar',
		placement: 'top',
	},
	{
		target: '.step-listagem-acoes',
		content: 'Na coluna "Ações", você pode executar ações para cada registro separadamente',
		placement: 'top',
	},
];
