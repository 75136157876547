import { Field, useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import * as Yup from 'yup';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	estadosCadastro,
	keyFilterConsultaRsql,
	manterApenasNumeros,
	mensagensDeValidacao,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	InputMask,
	Prompt,
	Tutorial,
	tutorialStepsCondutor,
} from 'components';

import { isValidCPF } from '@brazilian-utils/brazilian-utils';
import { atualizarUrl, metodosAtualizarUrl } from '../../../../Util';
import { readCondutor } from '../Requests';
import { converterCondutorParaFormulario } from '../Util/condutoresConverter';
import { CADASTRO_URL, INITIAL_VALUES, SITUACAO_OPTIONS } from '../Util/constantes';
import { ActionButtons } from './components/ActionButtons';

function CondutoresFormImpl({ history, isModal, match, hideModal }) {
	const { values, dirty, resetForm, setFieldValue } = useFormikContext();
	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EXCLUIR);

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_CONDUTOR);

	const informacoesPermissoes = {
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		const { id } = match.params;

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_CONDUTOR, false, null, false);
		}

		if (validarUUID(id) && !isModal) {
			fetchRegistro(id);
		}

		setTimeout(() => {
			document.getElementById('condutor-input-nome')?.focus();
		}, 500);
	}, []);

	async function fetchRegistro(idCondutor) {
		await readCondutor(idCondutor, ({ data: condutor }) => {
			resetForm({ values: converterCondutorParaFormulario(condutor) });

			if (!isModal) {
				atualizarUrl(history, CADASTRO_URL, condutor.id, metodosAtualizarUrl.POP);
			}
		});
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsCondutor}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form className="card-default screen-max-width" header="Cadastro de condutor" isModal={isModal}>
				<FormActions className="screen-max-width">
					<ActionButtons informacoesPermissoes={informacoesPermissoes} isModal={isModal} hideModal={hideModal} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="5"
							lg="5"
							xl="5"
							component={InputField}
							label="Nome"
							obrigatorio
							keyfilter={keyFilterConsultaRsql}
							name="nome"
							size={60}
							id="condutor-input-nome"
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={InputMask}
							obrigatorio
							mask="000.000.000-00"
							placeholder="   .   .   -  "
							label="CPF "
							name="cpf"
							value={values.cpf}
							onChange={(e) => setFieldValue('cpf', e.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={Dropdown}
							label="Situação"
							name="situacao"
							onChange={(event) => setFieldValue('situacao', event.value)}
							options={SITUACAO_OPTIONS}
							obrigatorio
							showClear={false}
							disabled={isModal}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const CondutoresFormFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};

		if (values.cpf && !isValidCPF(manterApenasNumeros(values.cpf))) {
			errors.cpf = 'Digite um CPF válido.';
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.max(60, 'O campo não pode ter mais de 60 caracteres.'),

		cpf: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.max(14, 'O campo não pode ter mais de 14 caracteres.'),
	}),

	handleSubmit: () => {},
})(CondutoresFormImpl);

export const CondutoresForm = withRouter(CondutoresFormFormik);
