export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é o cadastro de "Condição de pagamento". Estes registros são utilizados na parte de pagamentos do sistema. As condições "À vista", "A prazo" e "Sem pagamento" são as condições iniciais do sistema.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-condicao-pagamento-tipo',
		content:
			'Este campo tem como objetivo destacar para o sistema qual o tipo da condicão de pagamento. À vista, a prazo ou sem pagamento.',
		placement: 'top',
	},
	{
		target: '.step-condicao-pagamento-parcela-minima',
		content:
			'O campo "Min. Parcela" é configurável apenas quanto o tipo for  "A prazo". Visa limitar o número mínimo de parcelas que a condição aceita.',
		placement: 'top',
	},
	{
		target: '.step-condicao-pagamento-parcela-maxima',
		content:
			'O campo "Max. Parcela" é configurável apenas quanto o tipo for  "A prazo". Visa limitar o número máximo de parcelas que a condição aceita, até um limite de 48x.',
		placement: 'top',
	},
	{
		target: '.step-condicao-pagamento-parcela-padrao',
		content:
			'Por fim, o campo "Parcela padrão" é configurável apenas quanto o tipo for "A prazo". Diferente dos campos de parcelas anteriores, esse campo indica o número de parcelas que o sistema traz por padrão, limitado pelos campos anteriores.',
		placement: 'top',
	},
];
