import { del, get, post, put, services } from 'Common';

export async function getVendedoresResumo(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function getVendedor(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/vendas/vendedor/${id}`, null, onSuccess, onError);
}

export async function salvarVendedor(vendedor, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/vendas/vendedor`, vendedor, null, onSuccess, onError);
}

export async function atualizarVendedor(id, vendedor, onSuccess, onError) {
	return await put(`${services.GESTOR}/v1/vendas/vendedor/${id}`, vendedor, null, onSuccess, onError);
}

export async function deletarVendedor(id, onSuccess, onError) {
	return await del(`${services.GESTOR}/v1/vendas/vendedor/${id}`, null, onSuccess, onError);
}

export async function getUsuarioPadrao(onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/vendas/vendedor/relacoes/usuario`, null, onSuccess, onError);
}
