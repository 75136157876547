import React from 'react';
import { SITUACAO_NOME } from '../../Util/constantes';

const styleStatus = {
	height: '38px',
	borderRadius: '25px',
	fontWeight: 'bold',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '14px',
};

const styleName = {
	textAlign: 'center',
	fontWeight: 'bold',
	padding: '2px 0px',
};

export default function VendaStatusCard({ backgroundColor, color, status }) {
	return (
		<>
			<div
				style={{
					...styleStatus,
					backgroundColor,
					color,
				}}
			>
				<div style={styleName}>{SITUACAO_NOME[status]}</div>
			</div>
		</>
	);
}
