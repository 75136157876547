import { If, Message } from 'components';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

function MessagesValidacoes() {
	const { errors, submitCount } = useFormikContext();
	const [hasErrorsValidacoes, setHasErrorsValidacoes] = useState(false);
	const [mensagemValidacao, setMensagemValidacao] = useState('');

	useEffect(() => {
		if (Object.keys(errors)?.length > 0) {
			setHasErrorsValidacoes(true);

			if (errors.totais?.quitado) {
				setMensagemValidacao(errors.totais?.quitado);
			} else {
				setMensagemValidacao('Existem títulos com erros, verifique');
			}
		} else {
			setHasErrorsValidacoes(false);
			setMensagemValidacao('');
		}
	}, [errors, submitCount]);

	function createTextMessagesValidacoes() {
		return mensagemValidacao;
	}

	return (
		<If test={hasErrorsValidacoes}>
			<Message severity="error" text={createTextMessagesValidacoes()} />
		</If>
	);
}

export { MessagesValidacoes };
