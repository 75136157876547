import { services } from 'Common';
import { Pagamentos } from 'components';
import { colorsStatus } from 'components/body/Util/constantes';
import { moduloUtilizado as modulo, tipoOperacao } from 'components/header/Util/constantes';
import { useFormikContext } from 'formik';

function TabPagamentos({
	informacoesPermissoes,
	favoritos,
	urlModuloUtilizado,
	totalLiquido,
	classNameTabView,
	podeEditar,
	moduloUtilizado,
	setRecalcularTodosOsProdutosComTabelaPreco,
}) {
	const { values, setFieldValue, errors } = useFormikContext();

	const disabledFieldsPagamentos =
		!['PENDENTE', 'EXPIRADA', 'NAO_ENVIADA', 'REJEITADA'].includes(values.status) ||
		values.tipoOperacao === tipoOperacao.ENTRADA;

	function onChangePagamentos(pagamentos) {
		setFieldValue('pagamentos', pagamentos);
	}

	function onChangePagamento(index, value) {
		setFieldValue(`pagamentos[${index}]`, value);
	}

	function onChangePagamentoField(index, field, value) {
		setFieldValue(`pagamentos[${index}].[${field}]`, value);
	}

	return (
		<>
			<Pagamentos
				idOrigem={values.id}
				pagamentos={values.pagamentos}
				valorTotal={totalLiquido}
				dataBaseParcela={values.dataEmissao}
				urls={{
					urlBase: `${services.GESTOR}/v1/${urlModuloUtilizado}`,
					condicaoPagamento: `${services.GESTOR}/v1/${urlModuloUtilizado}/relacoes/condicoes_pagamento`,
					formaPagamento: `${services.GESTOR}/v1/${urlModuloUtilizado}/relacoes/formas_pagamento`,
					conta: `${services.GESTOR}/v1/${urlModuloUtilizado}/relacoes/contas`,
					categoria: `${services.GESTOR}/v1/${urlModuloUtilizado}/relacoes/categorias/receitas`,
				}}
				informacoesPermissoes={{ ...informacoesPermissoes, podeEditar }}
				disabledFields={disabledFieldsPagamentos}
				favoritos={favoritos}
				hideTabFinanceiro={
					(values.status !== 'FINALIZADO' && values.status !== 'TRANSMITIDA') || moduloUtilizado === modulo.ORCAMENTO
				}
				onChangePagamentos={onChangePagamentos}
				onChangePagamento={onChangePagamento}
				onChangePagamentoField={onChangePagamentoField}
				setRecalcularTodosOsProdutosComTabelaPreco={setRecalcularTodosOsProdutosComTabelaPreco}
				errors={errors?.pagamentos}
				classNameTabView={`tab-view-no-border ${classNameTabView(values.status)}`}
				color={colorsStatus[values.status].strongColor}
				hiddenButtonAdd={values.status !== 'PENDENTE' && values.status !== 'NAO_ENVIADA'}
			/>

			<br />
		</>
	);
}

export { TabPagamentos };
