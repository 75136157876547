import { useState, useEffect, useRef } from 'react';
import { itensBreadCrumb } from '../../../config/Rotas/index';
import { withRouter } from 'react-router';
import { AutoComplete } from 'primereact/autocomplete';
import { connect } from 'react-redux';
import usePrevious from '../../../Common/Hooks/usePrevious';

function SelectPesquisaTela(props) {
	const [descricaoSelecionada, setDescricaoSelecionada] = useState('');
	const [descricoes, setDescricoes] = useState([]);
	const [descricoesFiltradas, setDescricoesFiltradas] = useState([]);
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	const autoCompleteStyle = {
		border: 0,
		borderRadius: 0,
		height: '40px',
		width: '100%',
		padding: '10px',
		fontFamily: 'FontAwesome, sans-serif',
		...props.inputStyle,
	};

	useEffect(() => {
		atualizarDescricoes();
	}, []);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataAtualizacaoMenuLateral !== props.dataAtualizacaoMenuLateral) {
				atualizarDescricoes();
			}
		} else afterFirstRenderRef = true;
	}, []);

	function atualizarDescricoes() {
		let descricoes = [];

		for (const item in itensBreadCrumb) {
			if (itensBreadCrumb.hasOwnProperty(item)) {
				const element = itensBreadCrumb[item];
				if (element.podeAcessar && element.podeAcessar() !== false) {
					descricoes.push(element.descricao);
				}
			}
		}
		setDescricoes(descricoes);
		setDescricoesFiltradas(descricoes);

		return descricoes;
	}

	function onTextoChange(e) {
		if (descricoes.indexOf(e.value) > -1) {
			itensBreadCrumb.forEach(element => {
				if (
					e.value &&
					element.descricao &&
					e.value.includes(element.descricao) &&
					element.podeAcessar() !== false
				) {
					props.history.push('/' + element.valor);
				}
			});
		}

		setDescricaoSelecionada(e.value);
	}

	function filtrarRegistros(event) {
		let results = descricoes.filter(registro => {
			return registro.toLowerCase().includes(event.query.toLowerCase());
		});

		setDescricoesFiltradas(results);
	}

	return (
		<>
			<span>
				<AutoComplete
					inputClassName={`autocompletePesquisa  ${props.className}`}
					inputId="descricoes"
					value={descricaoSelecionada}
					onChange={onTextoChange}
					suggestions={descricoesFiltradas}
					size={30}
					inputStyle={autoCompleteStyle}
					style={props.style}
					scrollHeight="300px"
					delay={0}
					completeMethod={filtrarRegistros}
					placeholder=" &#xF002; Pesquisar"
				/>
			</span>
		</>
	);
}

const mapStateToProps = state => ({
	dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
});

export default withRouter(connect(mapStateToProps)(SelectPesquisaTela));
