import React, { Component } from 'react';
import propTypes from 'prop-types';
import SelectableCard from './components/SelectableCard';
import SimpleCard from './components/SimpleCard';

class CardTotalizadorListagem extends Component {

    render() {

        const { selectable } = this.props;

        if (selectable) {
            return <SelectableCard {...this.props} />
        } else {
            return <SimpleCard {...this.props} />
        }
    }
}

CardTotalizadorListagem.propTypes = {
    selectable: propTypes.bool,
    selected: propTypes.bool,
    onSelect: propTypes.func,
    value: propTypes.number.isRequired,
    primaryColor: propTypes.string.isRequired,
    icon: propTypes.any.isRequired,
    title: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
}

export default CardTotalizadorListagem;
