import { Grid, If } from 'components';
import { RadioButton } from 'primereact/radiobutton';
import { useContextPlanos } from 'views/configuracao/Planos/Context';
import DadosCartaoForm from '../DadosCartaoForm';
import DadosConfirmacaoForm from '../DadosConfirmacaoForm';
import { QRCodePix } from '../QRCodePix';

function PagamentoForm({
	metodoPagamento,
	setMetodoPagamento,
	activeIndex,
	setActiveIndex,
	onConfirmarAdesaoClick,
	informacoesCartao,
	triggerSalvarCartao,
}) {
	const disabledOption = Object.keys(informacoesCartao).length === 0;
	const { selecionarMetodo } = useContextPlanos();

	return (
		<Grid style={{ display: 'flex', flexDirection: 'column' }}>
			<If test={selecionarMetodo}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'start',
						gap: '4px',
						marginLeft: '10px',
						paddingTop: '10px',
					}}
				>
					<p style={{ color: '#6c757d' }}>Método de Pagamento</p>
					<div>
						<RadioButton
							inputId="optionCartaoModal"
							name="cartao"
							value={metodoPagamento}
							onChange={() => setMetodoPagamento('CARTAO')}
							checked={metodoPagamento === 'CARTAO'}
							disabled={disabledOption}
							style={{ cursor: disabledOption ? 'not-allowed' : 'pointer', color: disabledOption ? '#6c757d' : '' }}
						/>
						<label
							htmlFor="optionCartaoModal"
							className="p-radiobutton-label"
							style={{ cursor: disabledOption ? 'not-allowed' : 'pointer', color: disabledOption ? '#6c757d' : '' }}
						>
							Utilizar cartão crédito/débito cadastrado
						</label>
					</div>
					<div>
						<RadioButton
							inputId="optionNovoCartaoModal"
							name="novoCartao"
							value={metodoPagamento}
							onChange={() => setMetodoPagamento('NOVO_CARTAO')}
							checked={metodoPagamento === 'NOVO_CARTAO'}
						/>
						<label htmlFor="optionNovoCartaoModal" className="p-radiobutton-label">
							Cadastrar um novo crédito/débito cadastrado
						</label>
					</div>
					<div>
						<RadioButton
							inputId="optionPixModal"
							name="pix"
							value={metodoPagamento}
							onChange={() => setMetodoPagamento('PIX')}
							checked={metodoPagamento === 'PIX'}
						/>
						<label htmlFor="optionPixModal" className="p-radiobutton-label">
							Pagar com Pix
						</label>
					</div>
				</div>
			</If>
			<If test={!selecionarMetodo && metodoPagamento === 'NOVO_CARTAO'}>
				<DadosCartaoForm
					isModalConfirmacaoAdesao
					trigger={triggerSalvarCartao}
					setMetodoPagamento={setMetodoPagamento}
				/>
			</If>
			<If test={!selecionarMetodo && metodoPagamento === 'PIX'}>
				<QRCodePix
					activeIndex={activeIndex}
					setActiveIndex={setActiveIndex}
					aderir={() => onConfirmarAdesaoClick(metodoPagamento)}
				/>
			</If>
			<If test={!selecionarMetodo && metodoPagamento === 'CARTAO'}>
				<DadosConfirmacaoForm informacoesCartao={informacoesCartao} />
			</If>
		</Grid>
	);
}

export default PagamentoForm;
