import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	estadosBotaoCancelar,
	estadosBotaoNovo,
} from 'components';
import { asyncCreateMarca, asyncDeleteMarca, asyncUpdateMarca } from 'views/cadastros/Marca/Requests';
import { converterMarcaParaApi } from 'views/cadastros/Marca/Util/marcaConverter';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';

const PESQUISA_URL = '/marca';
const CADASTRO_URL = '/marca/cadastro';

function ActionButtons(props) {
	const { values, dirty, history, informacoesPermissoes, handleReset, resetForm, handleSubmit } = props;

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	const onClickNovo = dirty ? (event) => salvar(event, novo) : novo;

	function cancelar() {
		if (dirty) {
			handleReset();
		} else if (props.isModal) {
			props.hideModal(values);
		} else {
			voltarParaAPesquisa(history, PESQUISA_URL);
		}
	}

	async function salvar(e, novoOnSuccess) {
		handleSubmit();
		if (await validarFormulario(props)) {
			let dadosFormulario = converterMarcaParaApi(values);
			if (values.id) {
				await asyncUpdateRegistro(dadosFormulario, novoOnSuccess);
			} else {
				await asyncCreateRegistro(dadosFormulario, novoOnSuccess);
				if (typeof novoOnSuccess === 'undefined' && !informacoesPermissoes.podeEditar) {
					history.goBack();
				}
			}
		}
	}

	function novo() {
		atualizarUrl(history, CADASTRO_URL, null, metodosAtualizarUrl.POP);
		resetForm({
			values: {
				id: null,
				nome: '',
				situacao: 'ATIVO',
			},
		});

		setTimeout(() => {
			document.getElementById('InputNomeMarca').focus();
		}, 300);
	}

	function excluir() {
		confirmarExclusao(asyncDeleteRegistro);
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteMarca(values.id, () => {
			handleReset();
			voltarParaAPesquisa(history, PESQUISA_URL);
		});
	}

	async function asyncUpdateRegistro(dadosFormulario, novoOnSuccess) {
		await asyncUpdateMarca(dadosFormulario, () => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				resetForm({ values: values });
			}
		});
	}

	async function asyncCreateRegistro(dadosFormulario, novoOnSuccess) {
		await asyncCreateMarca(dadosFormulario, ({ data: marca }) => {
			let dados = { ...values, id: marca.id };
			resetForm({ values: dados });

			if (novoOnSuccess) {
				novoOnSuccess();
			} else if (props.isModal) {
				props.hideModal(dados);
			} else {
				atualizarUrl(history, CADASTRO_URL, marca.id, metodosAtualizarUrl.POP);
			}
		});
	}

	return (
		<>
			<ButtonCancelar onClick={cancelar} estadoBotao={estadoBotaoCancelar} />
			<ButtonSalvar onClick={() => salvar()} disabled={!dirty} />
			<ButtonNovo
				onClick={onClickNovo}
				estadoBotao={estadoBotaoNovo}
				hidden={!values.id && !dirty}
				disabled={!informacoesPermissoes.podeInserir}
			/>
			<ButtonExcluir onClick={excluir} hidden={!values.id} disabled={!informacoesPermissoes.podeExcluir} />
		</>
	);
}

export default ActionButtons;
