import { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Badge, Button, Grid, NenhumRegistroEncontrado, Paginacao } from 'components';
import { Menu } from 'primereact/menu';
import { format, parse } from 'date-fns';
import { formatarMonetario, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { atualizarUrl } from 'views/Util';
import { COLORS, ORDEM_SERVICO_ORCAMENTO_SITUACAO, SITUACAO_ORDEM_SERVICO } from '../../Util/constantes';
import { useContextOrdemServico } from '../../Context';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

export function TabelaOrdensServico(props) {
	const {
		history,
		registros,
		rows,
		page,
		sortField,
		sortOrder,
		totalRecords,
		handlePageChange,
		handleChangeOrder,
		handleClickDeletar,
		registroSelecionado,
		setRegistroSelecionado,
		setVisibleModalHistorico,
	} = props;
	const { podeExcluir } = useContextOrdemServico();

	const [menu, setMenu] = useState(null);
	const [permissaoNFSe] = useState(usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.VISUALIZAR));
	const [permissaoNFe] = useState(usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.VISUALIZAR));

	function montarItensMenu() {
		const itens = [];

		itens.push({
			label: 'Duplicar',
			icon: 'fa fa-copy',
			command: () => handleClickDuplicar(registroSelecionado.id),
		});
		itens.push({
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setVisibleModalHistorico(true),
		});

		return itens;
	}

	function handleClickDuplicar(id) {
		history.push({
			pathname: `/ordem_servico/cadastro/${id}`,
			state: { ordemServicoId: id },
		});
	}

	function renderAcoes(row) {
		const titleEditar = 'Editar';
		let titleExcluir = 'Excluir';
		const disableBtnEditar = false;
		let disableBtnExcluir = false;

		if (row?.situacao === 'CANCELADO' || row?.situacao === 'FINALIZADO') {
			titleExcluir = 'Você não pode excluir uma venda cancelado/finalizado';
			disableBtnExcluir = true;
		}

		if (!podeExcluir) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		}

		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={titleEditar}
					disabled={disableBtnEditar}
					onClick={() => handleClickEditar(row)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => handleClickDeletar(row)}
				/>
				<Button
					title="Opções"
					className="p-button-secondary step-listagem-acoes"
					icon="fa fa-ellipsis-v"
					style={styleButton}
					aria-controls="popup_menu"
					aria-haspopup
					onClick={(event) => {
						setRegistroSelecionado(row);
						menu.toggle(event);
					}}
				/>
			</div>
		);
	}

	function renderFieldNumero(row) {
		return <span title={row.numero}>{row.numero}</span>;
	}

	function renderFieldData(row) {
		const label = format(parse(row.dataAbertura, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
		return <span title={label}>{label}</span>;
	}

	function renderFieldCliente(row) {
		const label = row.cliente ? `${row.cliente.codigo} - ${row.cliente.nome}` : '—';
		return (
			<span
				title={label}
				style={{
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{label}
			</span>
		);
	}

	function renderFieldNfse(row) {
		if (row.nfse?.numero && row.nfse?.serie && permissaoNFSe) {
			return (
				<span
					onClick={() => atualizarUrl(history, `/nfses/cadastro/${row.nfse?.id}`)}
					style={{ fontSize: '12px', color: '#006095' }}
					className="link_to"
					title={
						!row.nfse?.numero || row.nfse?.numero === 0
							? `Acessar nota fiscal (ainda não transmitida)`
							: `Acessar nota fiscal número ${row.nfse?.numero}`
					}
				>
					{`NFS-e ${row.nfse?.numero}/${row.nfse?.serie}`}
				</span>
			);
		} else if (row.nfse?.numero && row.nfse?.serie && !permissaoNFSe) {
			return <span>{`NFS-e ${row.nfse?.numero}/${row.nfse?.serie}`}</span>;
		} else {
			return <span>—</span>;
		}
	}

	function renderFieldNfe(row) {
		if (row.nfe?.numero && row.nfe?.serie && permissaoNFe) {
			return (
				<span
					onClick={() => atualizarUrl(history, `/nfes/cadastro/${row.nfe?.id}`)}
					style={{ fontSize: '12px', color: '#006095' }}
					className="link_to"
					title={
						!row.nfe?.numero || row.nfe?.numero === 0
							? `Acessar nota fiscal (ainda não transmitida)`
							: `Acessar nota fiscal número ${row.nfe?.numero}`
					}
				>
					{`NF-e ${row.nfe?.numero}/${row.nfe?.serie}`}
				</span>
			);
		} else if (row.nfe?.numero && row.nfe?.serie && !permissaoNFe) {
			return <span>{`NF-e ${row.nfe?.numero}/${row.nfe?.serie}`}</span>;
		} else {
			return <span>—</span>;
		}
	}

	function renderFieldSituacao(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding:
				// eslint-disable-next-line no-nested-ternary
				row.situacao === SITUACAO_ORDEM_SERVICO.PENDENTE &&
				row.situacaoOrcamento === ORDEM_SERVICO_ORCAMENTO_SITUACAO.AGUARDANDO_APROVACAO
					? '0.1rem 0.7rem'
					: // eslint-disable-next-line no-nested-ternary
						row.situacao === SITUACAO_ORDEM_SERVICO.PENDENTE &&
						  row.situacaoOrcamento === ORDEM_SERVICO_ORCAMENTO_SITUACAO.APROVADO
						? '0.1rem 1.2rem'
						: row.situacao === SITUACAO_ORDEM_SERVICO.PENDENTE
							? '0.1rem 1.3rem'
							: '0.1rem 1.2rem',
		};

		switch (row.situacao) {
			case SITUACAO_ORDEM_SERVICO.PENDENTE: {
				switch (row.situacaoOrcamento) {
					case ORDEM_SERVICO_ORCAMENTO_SITUACAO.AGUARDANDO_APROVACAO: {
						return Badge(
							COLORS.PENDENTE.strongColor,
							COLORS.PENDENTE.lightColor,
							'Aguardando',
							styleBackground,
							null,
							null,
							'Aguardando aprovação do orçamento'
						);
					}
					case ORDEM_SERVICO_ORCAMENTO_SITUACAO.APROVADO: {
						return Badge(
							COLORS.PENDENTE.strongColor,
							COLORS.PENDENTE.lightColor,
							'Aprovado',
							styleBackground,
							null,
							null,
							'Orçamento aprovado'
						);
					}
					case ORDEM_SERVICO_ORCAMENTO_SITUACAO.REJEITADO: {
						return Badge(
							COLORS.EXPIRADO.strongColor,
							COLORS.EXPIRADO.lightColor,
							'Rejeitado',
							styleBackground,
							null,
							null,
							'Orçamento rejeitado'
						);
					}
					default:
						return Badge(COLORS.PENDENTE.strongColor, COLORS.PENDENTE.lightColor, 'Pendente', styleBackground);
				}
			}
			case SITUACAO_ORDEM_SERVICO.FINALIZADO: {
				return Badge(COLORS.FINALIZADO.strongColor, COLORS.FINALIZADO.lightColor, 'Finalizado', styleBackground);
			}
			case SITUACAO_ORDEM_SERVICO.CANCELADO: {
				return Badge(COLORS.CANCELADO.strongColor, COLORS.CANCELADO.lightColor, 'Cancelado', styleBackground);
			}
			default:
				return row.situacao;
		}
	}

	function renderFieldValor(row) {
		const label = formatarMonetario(row.valorTotal);
		return <span title={label}>{label}</span>;
	}

	function handleClickEditar(row) {
		atualizarUrl(history, '/ordem_servico/cadastro', row.id);
	}

	return (
		<Grid style={{ paddingTop: '10px', width: '100%' }} justifyCenter verticalAlignCenter>
			<Menu model={montarItensMenu()} popup ref={(elemento) => setMenu(elemento)} />
			<DataTable
				className="table"
				value={registros}
				responsive
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={handleChangeOrder}
				emptyMessage={<NenhumRegistroEncontrado />}
				style={{ flex: '1', marginLeft: '1rem' }}
			>
				<Column
					header="N°"
					className="step-listagem-order"
					field="numero"
					sortable
					body={renderFieldNumero}
					style={{ color: '#333333', width: '60px' }}
				/>
				<Column
					header="Data"
					field="data"
					sortable
					body={renderFieldData}
					style={{ color: '#333333', width: '100px' }}
				/>
				<Column
					header="Cliente"
					field="clienteCodigo"
					sortable
					body={renderFieldCliente}
					style={{ color: '#333333' }}
				/>
				<Column header="NFS-e" field="nfseNumero" body={renderFieldNfse} style={{ color: '#333333', width: '100px' }} />
				<Column header="NF-e" field="nfeNumero" body={renderFieldNfe} style={{ color: '#333333', width: '100px' }} />
				<Column
					header="Situação"
					field="situacao"
					sortable
					body={renderFieldSituacao}
					style={{ color: '#333333', width: '130px' }}
				/>
				<Column
					header="Valor"
					field="valorTotal"
					sortable
					body={renderFieldValor}
					style={{ color: '#333333', width: '150px', fontWeight: 'bold' }}
				/>
				<Column header="Ações" body={renderAcoes} style={{ color: '#333333', width: '130px' }} />
			</DataTable>
			<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={handlePageChange} />
		</Grid>
	);
}
