import { useFormikContext } from 'formik';
import { Slider } from 'primereact/slider';
import { useState } from 'react';

import { Button, Card, Divider, Grid, ToastTypes, notify } from 'components';

import { GESTOR_SERVICE } from 'Common';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { asyncPostPreviewEtiquetas } from 'views/cadastros/Etiquetas/Requests';
import { getExamplePreviewEtiquetas } from '../../../../Util/functions';

import { ContainerEtiqueta } from './components/ContainerEtiqueta';

function CardPreviewEtiqueta({ isMobile, isTablet }) {
	const { values } = useFormikContext();
	const [escalaVisualizacao, setEscalaVisualizacao] = useState(1);

	const campoPreviewImpressao = (
		<Grid justifyCenter>
			<Divider styleContainer={{ margin: '0px' }} styleLabel={{ margin: '0px' }} />
			<Button
				type="button"
				color="success"
				label={isMobile || isTablet ? `Preview` : 'Visualizar preview'}
				icon="fa fa-print"
				style={{ margin: '5px' }}
				onClick={handleClickGerarImpressao}
			/>
		</Grid>
	);

	function montarTitlePreview() {
		return (
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<span>
					<span style={{ marginRight: '8px' }}>Preview</span>
					<span style={{ color: '#848484', fontSize: '10px' }}>
						{`(Altura: ${values.alturaEtiqueta}mm Largura: ${values.larguraEtiqueta}mm)`}
					</span>
				</span>

				<span title={`25%-200% (atual:${escalaVisualizacao * 100}%)`}>
					<Slider
						min={0.25}
						max={2}
						step={0.25}
						value={escalaVisualizacao}
						onChange={(e) => setEscalaVisualizacao(e.value)}
						style={{ width: '120px' }}
					/>
				</span>
			</div>
		);
	}

	async function handleClickGerarImpressao() {
		if (await localServiceIsRunning(true)) {
			if (await GESTOR_SERVICE.validateVersion(true)) {
				await asyncPostPreviewEtiquetas(
					getExamplePreviewEtiquetas(values),
					async ({ data: pdf }) => {
						const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						const arquivoURL = URL.createObjectURL(arquivoPDF);
						const preview = window.open(arquivoURL);
						if (preview) {
							preview.onload = () => {
								setTimeout(() => {
									preview.document.title = 'Preview';
								}, 250);
							};
						}
					},
					(response) => {
						const titleError = `Erro ao gerar preview de etiquetas`;
						notify(titleError, ToastTypes.ERROR);
						console.error(`${titleError}:`, response.response.data?.erro);
					}
				);
			}
		}
	}

	async function localServiceIsRunning() {
		return GESTOR_SERVICE.isRunning();
	}

	return (
		<Card
			className="card-etiqueta card-preview"
			title={montarTitlePreview}
			style={{ height: '100%', width: '100%', position: 'relative' }}
			footer={campoPreviewImpressao}
		>
			<DndProvider backend={HTML5Backend}>
				<ContainerEtiqueta escalaVisualizacao={escalaVisualizacao} />
			</DndProvider>
		</Card>
	);
}

export { CardPreviewEtiqueta };
