function converterContaParaFormulario(contaApi) {
  let conta = {
    id: contaApi.id,
    nome: contaApi.nome,
    tipo: contaApi.tipo,
    saldoInicial: contaApi.saldoInicial,
    favoritaReceita: contaApi.favoritaReceita,
    favoritaDespesa: contaApi.favoritaDespesa,
    emitirBoletos: contaApi.emitirBoletos,
    recebePix: contaApi.recebePix,
    saldoAtual: contaApi.saldoAtual,
    situacao: contaApi.situacao,
    contaBancaria: {
      banco: contaApi.banco
        ? {
            label: `${contaApi.banco?.descricao} (${contaApi.banco?.codigo})`,
            registro: contaApi.banco,
            value: contaApi.banco?.id,
          }
        : null,
      numeroConta: contaApi.numeroConta ?? null,
      digitoConta: contaApi.verificadorConta ?? null,
      agencia: contaApi.numeroAgencia ?? null,
      digitoAgencia: contaApi.digitoVerificadorAgencia ?? null,
      codigoBeneficiario: contaApi.codigoBeneficiario ?? null,
      codigoBancoCorrespondente: contaApi.codigoBancoCorrespondente ?? null,
      codigoContaEmpresa: contaApi.codigoContaEmpresa ?? null,
      validacaoAPIAtiva: contaApi.verificarApiAtiva ?? null,
      clientIdPix: contaApi.clientIdPix ?? null,
      clientSecretPix: contaApi.clientSecretPix ?? null,
      chavePix: contaApi.chavePix ?? null,
      percentualMultaPix: contaApi.percentualMultaPix ?? null,
		  percentualJurosPix: contaApi.percentualJurosPix ?? null,
		  percentualDescontoPix: contaApi.percentualDescontoPix ?? null,
		  diasValidadePix: contaApi.diasValidadePix ?? null,
    },
    convenios: converterConveniosParaFormulario(contaApi.convenios),
  };

  return conta;
}

function lowerCaseAndCapitalizeFirstLetter(string) {
  let toLowerCase = string.toLowerCase();
  return toLowerCase.charAt(0).toUpperCase() + toLowerCase.slice(1);
}

function converterConveniosParaFormulario(conveniosApi) {
  let convenios = [];

  conveniosApi?.forEach((convenioApi) => {
    convenios.push({
      id: convenioApi.id,
      cnab: {
        label: String(convenioApi.codigoCnab)?.substring(4),
        value: convenioApi.codigoCnab,
      },
      numero: convenioApi.numero,
      descricao: convenioApi.descricao,
      situacao: {
        label: lowerCaseAndCapitalizeFirstLetter(convenioApi.situacao),
        value: convenioApi.situacao,
      },
      numeroCarteira: convenioApi.numeroCarteira,
      codigoCarteira: convenioApi.codigoCarteira,
      numeroRemessaReiniciaDiariamente: {
        label: convenioApi.remessaReiniciaDiariamente ? "Sim" : "Não",
        value: convenioApi.remessaReiniciaDiariamente,
      },
      numeroRemessa: convenioApi.numeroRemessa,
      densidadeRemessa: convenioApi.densidadeRemessa,
      tipoWebservice: convenioApi.tipoWebService,
      numeroContrato: convenioApi.numeroContrato,
      versaoLayoutArquivo: convenioApi.versaoLayoutArquivo,
      nossoNumeroPeloBanco: {
        label: convenioApi.nossoNumeroPeloBanco ? "Sim" : "Não",
        value: convenioApi.nossoNumeroPeloBanco,
      },
      nossoNumero: convenioApi.nossoNumero,
      percentualDesconto: convenioApi.percentualDesconto ?? 0,
      percentualJuros: convenioApi.percentualJuros ?? 0,
      percentualMulta: convenioApi.percentualMulta ?? 0,
      mensagem01: convenioApi.mensagem01 ?? "",
      mensagem02: convenioApi.mensagem02 ?? "",

      apiId: convenioApi.apiId,
      apiKey: convenioApi.apiKey,
      apiSecret: convenioApi.apiSecret,
      apiEstacao: convenioApi.apiEstacao,

      nomeCertificado: convenioApi.nomeCertificado,
    });
  });

  return convenios;
}

function converterContaParaApi(contaFormulario) {
  let conta = {
    id: contaFormulario.id ?? null,
    nome: contaFormulario.nome,
    tipo: contaFormulario.tipo,
    saldoInicial: contaFormulario.saldoInicial,
    favoritaReceita: contaFormulario.favoritaReceita,
    favoritaDespesa: contaFormulario.favoritaDespesa,
    saldoAtual: contaFormulario.saldoAtual,
    emitirBoletos: contaFormulario.emitirBoletos,
    recebePix: contaFormulario.recebePix,
    banco: contaFormulario.contaBancaria?.banco?.value
      ? { id: contaFormulario.contaBancaria?.banco?.value }
      : null,
    numeroConta: contaFormulario.contaBancaria?.numeroConta,
    verificadorConta: contaFormulario.contaBancaria?.digitoConta,
    numeroAgencia: contaFormulario.contaBancaria?.agencia,
    digitoVerificadorAgencia: contaFormulario.contaBancaria?.digitoAgencia,
    codigoBeneficiario: contaFormulario.contaBancaria?.codigoBeneficiario,
    codigoBancoCorrespondente:
      contaFormulario.contaBancaria?.codigoBancoCorrespondente,
    codigoContaEmpresa: contaFormulario.contaBancaria?.codigoContaEmpresa,
    verificarApiAtiva: contaFormulario.contaBancaria?.validacaoAPIAtiva,
    clientIdPix: contaFormulario.contaBancaria?.clientIdPix,
    clientSecretPix: contaFormulario.contaBancaria?.clientSecretPix,
    chavePix: contaFormulario.contaBancaria?.chavePix,
    percentualMultaPix: contaFormulario.contaBancaria?.percentualMultaPix,
		percentualJurosPix: contaFormulario.contaBancaria?.percentualJurosPix,
		percentualDescontoPix: contaFormulario.contaBancaria?.percentualDescontoPix,
		diasValidadePix: contaFormulario.contaBancaria?.diasValidadePix,
    situacao: contaFormulario.situacao,

    convenios: converterConveniosParaApi(contaFormulario.convenios),
  };

  return conta;
}

function converterConveniosParaApi(conveniosFormulario) {
  let convenios = [];

  conveniosFormulario?.forEach((convenioFormulario) => {
    convenios.push({
      id: convenioFormulario.id ?? null,
      codigoCnab: convenioFormulario.cnab?.value ?? null,
      numero: convenioFormulario.numero ?? null,
      descricao: convenioFormulario.descricao ?? null,
      situacao: convenioFormulario.situacao?.value ?? null,
      numeroCarteira: convenioFormulario.numeroCarteira ?? null,
      codigoCarteira: convenioFormulario.codigoCarteira ?? null,
      remessaReiniciaDiariamente:
        convenioFormulario.numeroRemessaReiniciaDiariamente
          ? convenioFormulario.numeroRemessaReiniciaDiariamente?.value ??
            convenioFormulario.numeroRemessaReiniciaDiariamente
          : false,
      numeroRemessa: convenioFormulario.numeroRemessa ?? null,
      densidadeRemessa: convenioFormulario.densidadeRemessa ?? null,
      tipoWebService: convenioFormulario.tipoWebservice ?? null,
      numeroContrato: convenioFormulario.numeroContrato ?? null,
      versaoLayoutArquivo: convenioFormulario.versaoLayoutArquivo ?? null,
      nossoNumeroPeloBanco:
        convenioFormulario.nossoNumeroPeloBanco?.value ?? null,
      nossoNumero: convenioFormulario.nossoNumero,
      percentualDesconto: convenioFormulario.percentualDesconto ?? 0,
      percentualJuros: convenioFormulario.percentualJuros ?? 0,
      percentualMulta: convenioFormulario.percentualMulta ?? 0,
      mensagem01: convenioFormulario.mensagem01 ?? "",
      mensagem02: convenioFormulario.mensagem02 ?? "",
      nomeCertificado: convenioFormulario.nomeCertificado,

      apiId: convenioFormulario.apiId ?? null,
      apiKey: convenioFormulario.apiKey ?? null,
      apiSecret: convenioFormulario.apiSecret ?? null,
      apiEstacao: convenioFormulario.apiEstacao ?? null,
    });
  });

  return convenios;
}

export {
  converterContaParaFormulario,
  converterConveniosParaFormulario,
  converterContaParaApi,
  converterConveniosParaApi,
};
