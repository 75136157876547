export function selecionarBancoCorrespondenteDados(selecionado, lista) {
	switch (selecionado.value) {
		case 'cce6d2ff-71ed-48a9-88ff-20932616acf9': //BB
			return lista.BancoDoBrasil;
		case '41b59254-ad4f-45b2-9172-39fd8cc827c3': //Santander
			return lista.Santander;
		case '0d771543-f868-440f-90bc-8919e9943e24': //Banrisul
			return lista.Banrisul;
		case 'c9c29c8e-56b0-4e53-bf87-2ad12c0fa94e': //Caixa
			return lista.Caixa;
		case '42bdc03d-06c1-4fcb-ac9e-6a9d99117099': //Unicred
			return lista.Unicred;
		case 'd5dc03be-d0f4-4063-b992-60ea79df91de': //Bradesco
			return lista.Bradesco;
		case 'ac9a6bb7-f836-45b0-bed2-88122067450f': //Itau
			return lista.Itau;
		case 'fe79b0ad-602f-4fa9-a1c8-6a0e5a6447ad': //Sicredi
			return lista.Sicredi;
		case '207cf63b-167a-42da-acb7-eb7be66fde67': //Sicoob
			return lista.Sicoob;
		default:
			return null;
	}
}

export function selecionarBancoCorrespondenteConvenios(selecionado, lista) {
	switch (selecionado.value) {
		case 'cce6d2ff-71ed-48a9-88ff-20932616acf9': //BB
			return lista.BancoDoBrasilConvenio;
		case '41b59254-ad4f-45b2-9172-39fd8cc827c3': //Santander
			return lista.SantanderConvenio;
		case '0d771543-f868-440f-90bc-8919e9943e24': //Banrisul
			return lista.BanrisulConvenio;
		case 'c9c29c8e-56b0-4e53-bf87-2ad12c0fa94e': //Caixa
			return lista.CaixaConvenio;
		case '42bdc03d-06c1-4fcb-ac9e-6a9d99117099': //Unicred
			return lista.UnicredConvenio;
		case 'd5dc03be-d0f4-4063-b992-60ea79df91de': //Bradesco
			return lista.BradescoConvenio;
		case 'ac9a6bb7-f836-45b0-bed2-88122067450f': //Itau
			return lista.ItauConvenio;
		case 'fe79b0ad-602f-4fa9-a1c8-6a0e5a6447ad': //Sicredi
			return lista.SicrediConvenio;
		case '207cf63b-167a-42da-acb7-eb7be66fde67': //Sicoob
			return lista.SicoobConvenio;
		default:
			return null;
	}
}
