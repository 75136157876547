import { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';

import { Grid, InputMoney } from 'components';

function ISS({ disableFields, informacoesPermissoes }) {
	const { values, setFieldValue } = useFormikContext();

	const [calcularImposto, setCalcularImposto] = useState({ iss: false, ir: false, csll: false, inss: false });

	useEffect(() => {
		if (calcularImposto.iss) {
			setFieldValue('tributos.iss.valor', (values.tributos.iss.valorBase * values.tributos.iss.percentual) / 100);
			setCalcularImposto((state) => ({ ...state, iss: false }));
		} else if (calcularImposto.ir) {
			setFieldValue('tributos.ir.valor', (values.tributos.ir.valorBase * values.tributos.ir.percentual) / 100);
			setCalcularImposto((state) => ({ ...state, ir: false }));
		} else if (calcularImposto.csll) {
			setFieldValue('tributos.csll.valor', (values.tributos.csll.valorBase * values.tributos.csll.percentual) / 100);
			setCalcularImposto((state) => ({ ...state, csll: false }));
		} else if (calcularImposto.inss) {
			setFieldValue('tributos.inss.valor', (values.tributos.inss.valorBase * values.tributos.inss.percentual) / 100);
			setCalcularImposto((state) => ({ ...state, inss: false }));
		}
	}, [calcularImposto.iss, calcularImposto.ir, calcularImposto.csll, calcularImposto.inss]);

	function onChangeValores(field, event) {
		if (field === 'tributos.iss.valorBase' || field === 'tributos.iss.percentual') {
			setCalcularImposto((state) => ({ ...state, iss: true }));
		} else if (field === 'tributos.ir.valorBase' || field === 'tributos.ir.percentual') {
			setCalcularImposto((state) => ({ ...state, ir: true }));
		} else if (field === 'tributos.csll.valorBase' || field === 'tributos.csll.percentual') {
			setCalcularImposto((state) => ({ ...state, csll: true }));
		} else if (field === 'tributos.inss.valorBase' || field === 'tributos.inss.percentual') {
			setCalcularImposto((state) => ({ ...state, inss: true }));
		}

		setFieldValue(field, event);
	}

	return (
		<Grid>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="BC ISS"
				name="BaseISS"
				value={values.tributos?.iss.valorBase}
				touched
				allowNegative={false}
				onChange={(event) => {
					onChangeValores('tributos.iss.valorBase', event.target.value);
				}}
				size={13}
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="ISS %"
				name="percentualISS"
				value={values.tributos?.iss.percentual}
				onChange={(event) => onChangeValores('tributos.iss.percentual', event.target.value)}
				size={8}
				decimalScale={2}
				prefix=""
				suffix="%"
				maxValue={100}
				placeholder="0,00 %"
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Valor ISS"
				name="valorISS"
				value={values.tributos?.iss.valor}
				touched
				allowNegative={false}
				onChange={(event) => onChangeValores('tributos.iss.valor', event.target.value)}
				size={13}
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="BC IR"
				name="baseIR"
				value={values.tributos?.ir.valorBase}
				touched
				allowNegative={false}
				onChange={(event) => onChangeValores('tributos.ir.valorBase', event.target.value)}
				size={13}
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="IR %"
				name="percentualIR"
				value={values.tributos?.ir.percentual}
				onChange={(event) => onChangeValores('tributos.ir.percentual', event.target.value)}
				size={8}
				decimalScale={2}
				prefix=""
				suffix="%"
				maxValue={100}
				placeholder="0,00 %"
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Valor IR"
				name="valorIR"
				value={values.tributos?.ir.valor}
				touched
				allowNegative={false}
				onChange={(event) => onChangeValores('tributos.ir.valor', event.target.value)}
				size={13}
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="BC CSLL"
				name="baseCSLL"
				value={values.tributos?.csll.valorBase}
				touched
				allowNegative={false}
				onChange={(event) => onChangeValores('tributos.csll.valorBase', event.target.value)}
				size={13}
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="CSLL %"
				name="percentualCSLL"
				value={values.tributos?.csll.percentual}
				onChange={(event) => onChangeValores('tributos.csll.percentual', event.target.value)}
				size={8}
				decimalScale={2}
				prefix=""
				suffix="%"
				maxValue={100}
				placeholder="0,00 %"
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Valor CSLL"
				name="valorCSLL"
				value={values.tributos?.csll.valor}
				touched
				allowNegative={false}
				onChange={(event) => onChangeValores('tributos.csll.valor', event.target.value)}
				size={13}
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="BC INSS"
				name="baseINSS"
				value={values.tributos?.inss.valorBase}
				touched
				allowNegative={false}
				onChange={(event) => onChangeValores('tributos.inss.valorBase', event.target.value)}
				size={13}
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="INSS %"
				name="percentualINSS"
				value={values.tributos?.inss.percentual}
				onChange={(event) => onChangeValores('tributos.inss.percentual', event.target.value)}
				size={8}
				decimalScale={2}
				prefix=""
				suffix="%"
				maxValue={100}
				placeholder="0,00 %"
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Valor INSS"
				name="valorINSS"
				value={values.tributos?.inss.valor}
				touched
				allowNegative={false}
				onChange={(event) => onChangeValores('tributos.inss.valor', event.target.value)}
				size={13}
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { ISS };
