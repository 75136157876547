import { Operacao, CAMPOS_TOTAIS } from './constantes';
import { Colors } from '../../Util/constantes';
import { calcularImpostosServico, copiarObjeto, montarCalcularImpostosServico } from 'Common';
import { tipos as TIPOS_PERCENTUAL_VALOR } from 'components';
import { aplicarPercentual } from 'views/Util';

export function validarValorNegativo(valor) {
	return valor < 0 ? 0.0 : valor;
}

export function getPrimaryColorOfSelectedItem(item) {
	if (item) {
		return Colors[item.status].strongColor;
	}
	return '#006095';
}

export function converterSetorParaFormulario(setor) {
	if (setor) {
		return {
			label: setor.nome,
			registro: setor,
			value: setor.id,
		};
	}
	return null;
}

export function CodigosServicoConverter(data) {
	return data.map((item) => {
		return {
			label: item.codigo + ' - ' + item.descricao,
			value: item.id,
			registro: item,
		};
	});
}

export function converterValueParaSelect(value, label) {
	return value
		? {
				value: value.id ?? null,
				label: label ?? value.nome ?? value.descricao,
				registro: value,
			}
		: null;
}

export function montarMensagemToast(operacao) {
	if (operacao === Operacao.EDITAR) {
		return 'Nota fiscal alterada com sucesso';
	} else if (operacao === Operacao.EXCLUIR) {
		return 'Nota fiscal excluída com sucesso';
	} else if (operacao === Operacao.INSERIR) {
		return 'Nota fiscal incluída com sucesso';
	}
}

export function rateioValorEntreServicos(produtos, totalProdutos, campo, valor) {
	let itens = produtos;
	let somaRateado = 0;

	itens?.map((item) => {
		item[campo] = parseFloat(((item?.quantidade * item?.valor * valor) / totalProdutos).toFixed(2));
		somaRateado += item[campo];
	});

	if (valor - somaRateado !== 0) {
		if (itens.length > 0) {
			itens[0][campo] = parseFloat((itens[0][campo] - parseFloat((somaRateado - valor).toFixed(2))).toFixed(2));
		}
	}

	return itens;
}

async function converterServicoImpostoParaCalculo(item) {
	return {
		servico: {
			id: item.servico.value,
		},
		operacaoFiscal: {
			id: item.operacaoFiscal.value,
		},
		quantidade: item.quantidade,
		valor: item.valor,
		desconto: item.desconto,
		baseCalculo: 0,
		subtotal: 0,
		tributos: {
			iss: {
				valorBase: item.tributos?.iss?.valorBase,
				percentualBase: item.tributos?.iss?.percentualBase,
				percentual: item.tributos?.iss?.percentual,
				valor: item.tributos?.iss?.valor,
			},
			ir: {
				valorBase: item.tributos?.ir?.valorBase,
				percentualBase: item.tributos?.ir?.percentualBase,
				percentual: item.tributos?.ir?.percentual,
				valor: item.tributos?.ir?.valor,
			},
			csll: {
				valorBase: item.tributos?.csll?.valorBase,
				percentualBase: item.tributos?.csll?.percentualBase,
				percentual: item.tributos?.csll?.percentual,
				valor: item.tributos?.csll?.valor,
			},
			inss: {
				valorBase: item.tributos?.inss?.valorBase,
				percentualBase: item.tributos?.inss?.percentualBase,
				percentual: item.tributos?.inss?.percentual,
				valor: item.tributos?.inss?.valor,
			},
			pis: {
				valorBase: item.tributos?.pis?.valorBase,
				percentualBase: item.tributos?.pis?.percentualBase,
				percentual: item.tributos?.pis?.percentual,
				valor: item.tributos?.pis?.valor,
			},
			cofins: {
				valorBase: item.tributos?.cofins?.valorBase,
				percentualBase: item.tributos?.cofins?.percentualBase,
				percentual: item.tributos?.cofins?.percentual,
				valor: item.tributos?.cofins?.valor,
			},
		},
	};
}

export async function montarCalcularImpostosServicoNFSe(municipioId, values) {
	const servicoCalculado = await montarCalcularImpostosServico(
		municipioId,
		await converterServicoImpostoParaCalculo(values)
	);
	return converterServicoImpostoParaFormulario(servicoCalculado, values);
}

function converterServicoImpostoParaFormulario(newValues, oldValues) {
	return {
		...oldValues,
		tributos: {
			iss: {
				percentual: newValues.tributos?.iss.percentual,
				percentualBase: newValues.tributos?.iss.percentualBase,
				valor: newValues.tributos?.iss.valor,
				valorBase: newValues.tributos?.iss.valorBase,
			},
			ir: {
				percentual: newValues.tributos?.ir.percentual,
				percentualBase: newValues.tributos?.ir.percentualBase,
				valor: newValues.tributos?.ir.valor,
				valorBase: newValues.tributos?.ir.valorBase,
			},
			csll: {
				percentual: newValues.tributos?.csll.percentual,
				percentualBase: newValues.tributos?.csll.percentualBase,
				valor: newValues.tributos?.csll.valor,
				valorBase: newValues.tributos?.csll.valorBase,
			},
			inss: {
				percentual: newValues.tributos?.inss.percentual,
				percentualBase: newValues.tributos?.inss.percentualBase,
				valor: newValues.tributos?.inss.valor,
				valorBase: newValues.tributos?.inss.valorBase,
			},
			pis: {
				cst: newValues.tributos?.pis?.cst ? newValues.tributos?.pis?.cst : oldValues.tributos?.pis?.cst,
				percentual: newValues.tributos?.pis.percentual,
				percentualBase: newValues.tributos?.pis.percentualBase,
				valor: newValues.tributos?.pis.valor,
				valorBase: newValues.tributos?.pis.valorBase,
			},
			cofins: {
				cst: newValues.tributos?.cofins?.cst ? newValues.tributos?.cofins?.cst : oldValues.tributos?.cofins?.cst,
				percentual: newValues.tributos?.cofins.percentual,
				percentualBase: newValues.tributos?.cofins.percentualBase,
				valor: newValues.tributos?.cofins.valor,
				valorBase: newValues.tributos?.cofins.valorBase,
			},
		},
	};
}

export async function calcularImpostosServicoNFSe(values) {
	const servicoCalculado = await calcularImpostosServico(await converterServicoImpostoParaCalculo(values));
	return converterServicoImpostoParaFormulario(servicoCalculado, values);
}

function calcularDescontoServicos(values, desconto) {
	let result = {
		servicos: [],
		totalDesconto: 0,
	};
	
	const servicosTemp = copiarObjeto(values.servicos);		
	const descontoMaximoSuperior = values.informacoesComplementares?.vendedor?.registro?.descontoMaximoSuperior;

	if (descontoMaximoSuperior){			
		servicosTemp.forEach((servico, index) => { 					
				if (descontoMaximoSuperior > servico.descontoMaximoPercentual){
					servicosTemp[index].descontoMaximoVendedor = descontoMaximoSuperior;
					servicosTemp[index].descontoMaximoPercentual = descontoMaximoSuperior;
				}					
			}	
		);
	}

	if (desconto.tipo === TIPOS_PERCENTUAL_VALOR.PERCENTUAL) {
		let servicos = calcularImpostosServicos(
			rateioValorEntreServicos(
				servicosTemp,
				values.totalizadores.valorLiquido,
				CAMPOS_TOTAIS.DESCONTO,
				aplicarPercentual(values.totalizadores.valorLiquido, desconto.total)
			)
		);

		result.servicos = servicos;
		result.totalDesconto = aplicarPercentual(values.totalizadores.valorLiquido, desconto.total);
	} else {
		let servicos = calcularImpostosServicos(
			rateioValorEntreServicos(
				servicosTemp,
				values.totalizadores.valorLiquido,
				CAMPOS_TOTAIS.DESCONTO,
				desconto.total
			)
		);

		result.servicos = servicos;
		result.totalDesconto = desconto.total;
	}
	return result;
}

function calcularImpostosServicos(itens) {
	let servicos = [];
	if (hasServico(itens)) {
		itens.forEach(async (item) => {
			if (Object.keys(Object(item.servico)).length) {
				let servico = calcularImpostosServico(await converterServicoImpostoParaCalculo(item));
				servico.subtotal = parseFloat(
					((servico?.quantidade ?? 0) * (servico?.valor ?? 0) - (servico?.desconto ?? 0)).toFixed(2)
				);
				servicos.push({ ...item, ...servico });
			}
		});
	}

	return servicos.length > 0 ? servicos : itens;
}

function hasServico(servicos) {
	return servicos.filter((item) => Object.keys(Object(item.servico)).length).length > 0;
}

function recalcularTotais(values) {
	let valoresTotais = values.servicos?.reduce(
		(acc, servico) => {
			if (servico.servico) {
				acc.valorIss += servico.tributos?.iss.valor || 0;
				acc.baseIss += servico.tributos?.iss.valorBase || 0;
				acc.valorIr += servico.tributos?.ir.valor || 0;
				acc.baseIr += servico.tributos?.ir.valorBase || 0;
				acc.valorCsll += servico.tributos?.csll.valor || 0;
				acc.baseCsll += servico.tributos?.csll.valorBase || 0;
				acc.valorInss += servico.tributos?.inss.valor || 0;
				acc.baseInss += servico.tributos?.inss.valorBase || 0;
				acc.valorPis += servico.tributos?.pis.valor || 0;
				acc.basePis += servico.tributos?.pis.valorBase || 0;
				acc.valorCofins += servico.tributos?.cofins.valor || 0;
				acc.baseCofins += servico.tributos?.cofins.valorBase || 0;
				acc.desconto += servico?.desconto || 0;
				acc.valorBruto += servico.quantidade * servico.valor;
			}
			return acc;
		},
		{
			valorIss: 0,
			baseIss: 0,
			valorIr: 0,
			baseIr: 0,
			valorCsll: 0,
			baseCsll: 0,
			valorInss: 0,
			baseInss: 0,
			valorPis: 0,
			basePis: 0,
			valorCofins: 0,
			baseCofins: 0,
			desconto: 0,
			valorBruto: 0,
		}
	);

	valoresTotais.valorLiquido = valoresTotais.valorBruto - valoresTotais.desconto;

	for (const val in valoresTotais) {
		valoresTotais[val] = truncateDecimals(valoresTotais[val]);
	}

	return {
		...valoresTotais,
		id: values.totalizadores.id,
		tipoDesconto: values.totalizadores.tipoDesconto,
	};
}

function truncateDecimals(number) {
	return Math.floor(number * 100) / 100;
}
export { calcularDescontoServicos, recalcularTotais };
