import { mensagensDeValidacao } from 'Common';
import { Dropdown, Grid, InputDate, ModalRelatorio } from 'components';
import { format, formatISO, isValid, parseISO, startOfMonth } from 'date-fns';
import { Field, useFormikContext, withFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { asyncBaixarRelatorioDespesasPorCategoria } from '../../Requests';
import { converterCategoriasUtilizadasParaAPI } from './converter';

const initialValue = {
	dataInicial: formatISO(startOfMonth(new Date())),
	dataFinal: new Date().toISOString(),
	categoriasUtilizadas: {
		value: 'todos',
		label: 'Todos',
	},
};

const optionsDropdownCategorias = [
	{
		value: 'receitas',
		label: 'Receitas',
	},
	{
		value: 'despesas',
		label: 'Despesas',
	},
	{
		value: 'todos',
		label: 'Todos',
	},
];

function ModalReceitasDespesasPorCategoriaImpl({ visible, onHide }) {
	const { values, isValid, handleSubmit, validateForm, setFieldValue } = useFormikContext();

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('input-data-inicial')?.focus();
		}, 500);
	}, []);

	async function print() {
		handleSubmit();
		await validateForm();
		if (isValid) {
			const initialDateFormated = format(parseISO(values.dataInicial), 'yyyy-MM-dd');
			const finalDateFormated = format(parseISO(values.dataFinal), 'yyyy-MM-dd');
			const categoriaFormatada = converterCategoriasUtilizadasParaAPI(values.categoriasUtilizadas);
			await asyncBaixarRelatorioDespesasPorCategoria(
				initialDateFormated,
				finalDateFormated,
				categoriaFormatada,
				({ data: pdf }) => {
					const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
					const arquivoURL = URL.createObjectURL(arquivoPDF);
					const report = window.open(arquivoURL);
					if (report) {
						report.onload = () => {
							setTimeout(() => {
								report.document.title = `Relatório receitas e despesas por categoria`;
							}, 250);
						};
					}
					onHide();
				}
			);
		}
	}

	const params = (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={InputDate}
				label="Data inicial"
				name="dataInicial"
				obrigatorio
				value={values.dataInicial}
				touched
				id="input-data-inicial"
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={InputDate}
				label="Data final"
				name="dataFinal"
				obrigatorio
				value={values.dataFinal}
				touched
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={Dropdown}
				label="Categorias utilizadas"
				name="categoriasUtilizadas"
				options={optionsDropdownCategorias}
				obrigatorio
				value={values.categoriasUtilizadas}
				onChange={(e) => setFieldValue('categoriasUtilizadas', e.value)}
				showClear={false}
				touched
			/>
		</Grid>
	);

	return (
		<ModalRelatorio
			header="Relatório receitas e despesas por categoria"
			visible={visible}
			onHide={onHide}
			onImprimir={print}
			parametros={params}
			isValid={isValid}
		/>
	);
}

const ModalReceitasDespesasPorCategoria = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		const errors = {};

		if (values.dataInicial && !isValid(parseISO(values.dataInicial))) {
			errors.dataInicial = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.dataFinal && !isValid(parseISO(values.dataFinal))) {
			errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (!values.categoriasUtilizadas) {
			errors.categoriasUtilizadas = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		dataInicial: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		dataFinal: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalReceitasDespesasPorCategoriaImpl);

export { ModalReceitasDespesasPorCategoria };
