import { useState } from 'react';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { Grid, NenhumRegistroEncontrado, SingleSelectTabelaPreco } from 'components';
import { services } from 'Common';

import { useContextVendedores } from 'views/cadastros/Vendedores/Context';

function TabTabelaPrecoFilial() {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, isMobile, isTablet } = useContextVendedores();

	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(1);

	function onSort(element) {
		setSortField(element.sortField);
		setSortOrder(element.sortOrder);
	}

	function renderNomeFilial(row) {
		return (
			<span style={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}>
				{`${row.filial?.codigo} - ${row.filial?.nomeApresentacao}`}
			</span>
		);
	}

	function renderTabelaPreco(row, options) {
		return (
			<SingleSelectTabelaPreco
				label=""
				name="filiais[0].tabelaPreco"
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				value={row?.tabelaPreco}
				onChange={(e) => setFieldValue(`filiais[${options.rowIndex}].tabelaPreco`, e)}
				url={`${services.GESTOR}/v1/vendas/vendedor/relacoes/tabela_preco`}
				idFilialParaPesquisa={row?.filial?.id}
				disabledButton
				esconderBotao
				{...informacoesPermissoes}
			/>
		);
	}

	return (
		<Grid>
			<DataTable
				className="table"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				responsive
				value={values.filiais}
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={onSort}
				emptyMessage={<NenhumRegistroEncontrado />}
				style={{ width: '100%' }}
			>
				<Column
					field="filial"
					header="Filial"
					body={renderNomeFilial}
					sortable
					style={{ width: '60%', textOverflow: 'ellipsis', overflow: 'hidden' }}
				/>
				<Column
					field="tabelaPreco"
					header="Tabela de preço"
					body={(row, options) => renderTabelaPreco(row, options)}
					sortable
					style={{ width: '40%' }}
				/>
			</DataTable>
		</Grid>
	);
}

export { TabTabelaPrecoFilial };
