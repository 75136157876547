import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import ModalNovaFilial from "./components/ModalNovaFilial";

import { buscarDadosLoginLocalStorage } from "Common";

import {
  Button,
  confirm,
  Message,
  FormGroup,
  NenhumRegistroEncontrado,
  ButtonNovo,
  Grid,
  If,
} from "components";
import { renderDocumento, renderFilialInativa } from "./Utils/function";

const styleButton = {
  borderRadius: "50%",
  padding: "5px",
  width: "30px",
  height: "30px",
  margin: "2px",
};

function Filiais(props) {
  const {
    filiais,
    values,
    idOrganizacao,
    autoridadeOrganizacao,
    onChange,
    podeEditar,
  } = props;

  const [registros, setRegistros] = useState([]);
  const [emailUsuarioLogado, setEmailUsuarioLogado] = useState(
    buscarDadosLoginLocalStorage().email
  );
  const [exibirUsuariosInativos, setExibirUsuariosInativos] = useState(false);
  const [exibirModalNovaFilial, setExibirModalNovaFilial] = useState(false);
  const [filialSelecionada, setFilialSelecionada] = useState(null);
  const [idOrganizacaoLocal, setIdOrganizacaoLocal] = useState();

  useEffect(() => {
    setIdOrganizacaoLocal(buscarDadosLoginLocalStorage().organizacao.id);
  }, []);

  useEffect(() => {
    if (idOrganizacao !== idOrganizacaoLocal) {
      setIdOrganizacaoLocal(idOrganizacao);
      setRegistros(filiais || null);
    }
  }, [values]);

  useEffect(() => {
    if (filiais.length > 0) {
      setRegistros(filiais);
    }
  }, [filiais]);

  function handleSalvarFilial(object) {
    const index = filiais.findIndex((item) => item.id === object.id);
    const filiaisEditadas = [...filiais];
    if (index === -1 || filiaisEditadas[index]?.id === null) {
      filiaisEditadas.push(object);
    } else {
      filiaisEditadas[index] = object;
    }
    onChange(filiaisEditadas);
    setExibirModalNovaFilial(false);
  }

  function handleModalFilial(row) {
    if (row) {
      setFilialSelecionada(row);
    }
    setExibirModalNovaFilial(true);
  }

  function handleDesativarFilial(row) {
    const index = filiais.findIndex((item) => item.id === row.id);
    let filiaisEditadas = [...filiais];
    confirm(
      "Confirmação",
      "Tem certeza que deseja desativar a filial " + row.nomeApresentacao + "?",
      async () => {
        filiaisEditadas[index].situacao = "INATIVA";
        onChange(filiaisEditadas);
      }
    );
  }

  function handleAtivarFilial(row) {
    const index = filiais.findIndex((item) => item.id === row.id);
    let filiaisEditadas = [...filiais];
    confirm(
      "Confirmação",
      "Tem certeza que deseja ativar a filial " + row.nomeApresentacao + "?",
      async () => {
        filiaisEditadas[index].situacao = "ATIVA";
        onChange(filiaisEditadas);
      }
    );
  }

  function renderizarOpcoes(row) {
    if (row) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            style={styleButton}
            color="primary"
            title="Editar filial"
            icon="fa fa-pencil"
            onClick={() => handleModalFilial(row)}
            disabled={(row && row.email === emailUsuarioLogado) || !podeEditar}
          />
          <Button
            style={styleButton}
            color="danger"
            title="Bloquear a filial"
            icon="fa fa-lock"
            onClick={() => handleDesativarFilial(row)}
            hidden={row.situacao == "INATIVA"}
            disabled={(row && row.email === emailUsuarioLogado) || !podeEditar}
          />
          <Button
            style={styleButton}
            color="success"
            title="Desbloquear a filial"
            icon="fa fa-unlock"
            disabled={!podeEditar}
            onClick={() => handleAtivarFilial(row)}
            hidden={row.situacao == "ATIVA"}
          />
        </div>
      );
    }
  }

  function handleFecharModalFilial() {
    setFilialSelecionada(null);
    setExibirModalNovaFilial(false);
  }

  function getNovoCodigo() {
    let novoCodigo = 1;
    if (registros.length > 0) {
      registros.forEach((filial) => {
        if (filial.codigo >= novoCodigo) {
          novoCodigo = Number(filial.codigo) + 1;
        }
      });
    }
    return novoCodigo;
  }

  if (!idOrganizacao) {
    return (
      <Message
        severity="info"
        colStyle={{ padding: "0px" }}
        text="Para cadastrar novas filiais é necessário salvar a organização"
      />
    );
  }

  const registrosFiltrados =
    registros && exibirUsuariosInativos
      ? registros
      : registros?.filter((registro) => registro.situacao == "ATIVA");

  return (
    <>
      <FormGroup>
        <Grid style={{ margin: "-4px" }}>
          <ButtonNovo
            label="Nova filial"
            podeInserir={podeEditar}
            disabled={!idOrganizacao || !podeEditar}
            onClick={() => {
              setExibirModalNovaFilial(true);
            }}
          />
        </Grid>

        <DataTable
          className="table"
          responsive
          value={registrosFiltrados}
          emptyMessage={<NenhumRegistroEncontrado />}
          header={
            <span style={{ display: "flex", justifyContent: "flex-end" }}>
              <Checkbox
                inputId="exibirUsuariosInativos"
                name="exibirUsuariosInativos"
                onChange={(e) =>
                  setExibirUsuariosInativos(!exibirUsuariosInativos)
                }
                checked={exibirUsuariosInativos}
              />
              <label
                htmlFor="exibirUsuariosInativos"
                className="p-checkbox-label"
              >
                Exibir inativos
              </label>
            </span>
          }
        >
          <Column
            field="codigo"
            body={(row) => row?.codigo}
            header="Código"
            sortable={true}
          />
          <Column
            field="nome"
            body={(row) => row?.nomeApresentacao}
            header="Nome da filial"
            sortable={true}
          />
          <Column
            field="cpfCnpj"
            body={(row) => renderDocumento(row)}
            header="CPF/CNPJ"
            sortable={true}
          />
          <Column
            field="situacao"
            body={(row) => renderFilialInativa(row)}
            header="Situação"
            sortable={true}
          />
          <Column
            header="Ações"
            body={(row) => renderizarOpcoes(row)}
            style={{ width: "100px" }}
          />
        </DataTable>
      </FormGroup>
      <If test={exibirModalNovaFilial}>
        <ModalNovaFilial
          visible={exibirModalNovaFilial}
          filialSelecionada={filialSelecionada}
          setFilialSelecionada={setFilialSelecionada}
          onHide={() => handleFecharModalFilial()}
          onSave={(e) => handleSalvarFilial(e)}
          getNovoCodigo={getNovoCodigo}
          idOrganizacao={idOrganizacao}
          autoridadeOrganizacao={autoridadeOrganizacao}
        />
      </If>
    </>
  );
}

export default Filiais;
