import { useEffect, useState } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';
import { TabPanel } from 'primereact/tabview';

import {
	AutoProgressBar,
	Checkbox,
	Divider,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputDouble,
	InputField,
	InputMask,
	Modal,
	SingleSelectBanco,
	TabView,
	validarValorNegativo,
} from 'components';
import { copiarObjeto, gerarUUID, keyFilterConsultaRsql, mensagensDeValidacao } from 'Common';

import { PESSOA_TIPO, TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';
import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { converterMDFePagamentoParaForm } from 'views/transporte/MDFe/Form/Util/MDFeConverter';
import { CONDICOES_PAGAMENTO } from 'views/fiscal/servicos/OrdemServico/Util/constantes';

import { addMonths, formatISO, isValid, parseISO } from 'date-fns';
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';
import { ActionButtons } from './components/ActionButtons';
import { Prazos } from './components/Prazos';
import { Componentes } from './components/Componentes';

function ModalPagamentoImpl({ mdfe, visible, canTransmitir, onHide, resetFormPrincipal }) {
	const { informacoesPermissoes, isTablet, isMobile } = useContextMDFe();
	const { values, setFieldValue, dirty } = useFormikContext();
	const [activeIndexTab, setActiveIndexTab] = useState(0);
	const [isGerarParcelas, setIsGerarParcelas] = useState(false);
	const [indexRecalcularRateioParcela, setIndexRecalcularRateioParcela] = useState(null);

	const isAPrazo = values.tipoPagamento === CONDICOES_PAGAMENTO.A_PRAZO;

	useEffect(() => {
		if (isGerarParcelas) {
			const parcelasTemp = [];

			const rateio = rateioParcelas(values.quantidadeParcelas, values.valorContrato);

			for (let i = 0; i < values.quantidadeParcelas; i++) {
				const oldParcela = values.prazos && values.prazos[i];
				let valorParcela = rateio.valorPorParcela;

				if (values.quantidadeParcelas - 1 === i) {
					valorParcela = rateio.valorUltimaParcela;
				}

				const idParcela = oldParcela ? oldParcela.id : gerarUUID();
				const vencimento = formatISO(
					isValid(new Date(oldParcela?.vencimento)) ? parseISO(oldParcela.vencimento) : addMonths(new Date(), i + 1)
				);
				parcelasTemp.push({
					id: idParcela,
					numero: i + 1,
					vencimento: vencimento,
					valor: valorParcela,
				});
			}

			setFieldValue('prazos', parcelasTemp);
		}
		setIsGerarParcelas(false);
	}, [isGerarParcelas]);

	useEffect(() => {
		if (dirty && values.valorContrato > 0) {
			if (isAPrazo) {
				let parcelasTemp = recalcularParcelas(values.quantidadeParcelas ?? 1, values.prazos, values.valorContrato);

				parcelasTemp = parcelasTemp.map((parcela, index) => {
					const vencimento = formatISO(
						isValid(new Date(parcela?.vencimento))
							? parseISO(parcela.vencimento)
							: addMonths(parseISO(new Date()), index + 1)
					);
					return {
						...parcela,
						id: parcela.id ? parcela.id : gerarUUID(),
						numero: index + 1,
						vencimento: vencimento,
					};
				});
				setFieldValue('prazos', parcelasTemp);
			} else {
				setFieldValue('prazos', []);
				setFieldValue('quantidadeParcelas', null);
			}
		}
	}, [values.tipoPagamento, values.valorContrato]);

	useEffect(() => {
		if (indexRecalcularRateioParcela !== null) {
			setFieldValue(
				'prazos',
				recalcularRateioParcela(indexRecalcularRateioParcela, values.prazos, values.valorContrato)
			);
		}
		setIndexRecalcularRateioParcela(null);
	}, [indexRecalcularRateioParcela]);

	function recalcularParcelas(quantidadeParcelas, parcelas, valorTotal) {
		let parcelasTemp = copiarObjeto(parcelas);
		const rateio = rateioParcelas(quantidadeParcelas, valorTotal);

		parcelasTemp = parcelasTemp.map((parcela, index) => {
			if (index === parcelasTemp.length - 1) {
				return { ...parcela, valor: rateio.valorUltimaParcela };
			} else {
				return { ...parcela, valor: rateio.valorPorParcela };
			}
		});
		return parcelasTemp;
	}

	function rateioParcelas(quantidadeParcelas, valorTotal) {
		const valorPorParcela = parseFloat((valorTotal / quantidadeParcelas).toFixed(2));
		const valorUltimaParcela = parseFloat((valorTotal - valorPorParcela * (quantidadeParcelas - 1)).toFixed(2));

		return {
			valorPorParcela: valorPorParcela,
			valorUltimaParcela: valorUltimaParcela,
		};
	}

	function recalcularRateioParcela(indexParcela, parcelas, valorTotal) {
		let parcelasTemp = copiarObjeto(parcelas);

		const valorInformado = parcelasTemp[indexParcela].valor;

		const valorParcelasAnteriores = valorSomadoDasParcelasAnteriores(parcelasTemp, indexParcela);
		const valorASerRatiado = valorTotal - (valorParcelasAnteriores + valorInformado);
		const quantidadeParcelasRestantes = parcelasTemp.length - 1 - indexParcela;
		const valorRatiadoParaCadaParcelaRestante = parseFloat((valorASerRatiado / quantidadeParcelasRestantes).toFixed(2));

		let somaDasParcelas = 0;

		parcelasTemp = parcelasTemp.map((parcela, index) => {
			let { valor } = parcela;

			if (index > indexParcela) {
				somaDasParcelas += valorRatiadoParaCadaParcelaRestante;
				if (index === parcelasTemp.length - 1) {
					valor = validarValorNegativo(
						parseFloat((valorRatiadoParaCadaParcelaRestante + (valorTotal - somaDasParcelas)).toFixed(2))
					);
				} else {
					valor = validarValorNegativo(valorRatiadoParaCadaParcelaRestante);
				}
			} else {
				somaDasParcelas += parcela.valor;
			}
			parcelasTemp[index].valor = valor;
			return parcela;
		});
		return parcelasTemp;
	}

	function valorSomadoDasParcelasAnteriores(parcelas, index) {
		let valorParcelasAnteriores = 0.0;
		for (let i = 0; i < parcelas.length; i++) {
			if (i < index) {
				valorParcelasAnteriores += parcelas[i].valor;
			}
		}
		return valorParcelasAnteriores;
	}

	function onChangeTipoPessoa(e) {
		setFieldValue('tipoPessoa', e.value);

		if (e.value === TIPO_PESSOA.JURIDICA) {
			setFieldValue('cpf', null);
			setFieldValue('idEstrangeiro', null);
		} else if (e.value === TIPO_PESSOA.FISICA) {
			setFieldValue('cnpj', null);
			setFieldValue('idEstrangeiro', null);
		} else {
			setFieldValue('cnpj', null);
			setFieldValue('cpf', null);
		}
	}

	function onChangeTipoPagamento(value) {
		if (value === CONDICOES_PAGAMENTO.A_VISTA) {
			setFieldValue('valorAdiantamento', 0);
			setFieldValue('tipoPagamento', value);
			setFieldValue('quantidadeParcelas', null);
			setFieldValue('prazos', null);
			setActiveIndexTab(0);
		} else {
			setFieldValue('tipoPagamento', value);
			setFieldValue('quantidadeParcelas', 1);
			setFieldValue('prazos', [
				{ id: gerarUUID(), numero: 1, valor: values.valorContrato, vencimento: formatISO(addMonths(new Date(), 1)) },
			]);
		}
	}

	function onChangeBanco(e) {
		setFieldValue('banco', e);
	}

	return (
		<Modal header="Dados pagamento" visible={visible} onHide={onHide} closeOnEsc={false}>
			<AutoProgressBar />
			<Form className="screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButtons
						onHide={onHide}
						canTransmitir={canTransmitir}
						id={mdfe.id}
						resetFormPrincipal={resetFormPrincipal}
						mdfe={mdfe}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							obrigatorio
							size={60}
							label="Nome"
							name="nome"
							onChange={(e) => setFieldValue('nome', e.target.value)}
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Tipo pessoa"
							name="tipoPessoa"
							showClear={false}
							onChange={(e) => onChangeTipoPessoa(e)}
							options={PESSOA_TIPO}
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<If test={values.tipoPessoa === TIPO_PESSOA.FISICA}>
							<Field
								sm="12"
								md="4"
								lg="5"
								xl="5"
								component={InputMask}
								label="CPF"
								name="cpf"
								obrigatorio
								mask="000.000.000-00"
								placeholder="   .   .   -  "
								size={11}
								onChange={(e) => setFieldValue('cpf', e.target.value)}
								disabled={!canTransmitir}
								{...informacoesPermissoes}
							/>
						</If>
						<If test={values.tipoPessoa === TIPO_PESSOA.JURIDICA}>
							<Field
								sm="12"
								md="4"
								lg="5"
								xl="5"
								component={InputMask}
								label="CNPJ"
								name="cnpj"
								obrigatorio
								mask="00.000.000/0000-00"
								placeholder="  .   .   /    -  "
								size={14}
								onChange={(e) => setFieldValue('cnpj', e.target.value)}
								disabled={!canTransmitir}
								{...informacoesPermissoes}
							/>
						</If>
						<If test={values.tipoPessoa === TIPO_PESSOA.ESTRANGEIRO}>
							<Field
								sm="12"
								md="4"
								lg="5"
								xl="5"
								component={InputField}
								label="Identificador"
								name="idEstrangeiro"
								obrigatorio
								size={14}
								onChange={(e) => setFieldValue('idEstrangeiro', e.target.value)}
								disabled={!canTransmitir}
								{...informacoesPermissoes}
							/>
						</If>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							label="Forma de pagamento"
							name="tipoPagamento"
							onChange={(e) => onChangeTipoPagamento(e.value)}
							options={[
								{ label: 'À vista', value: CONDICOES_PAGAMENTO.A_VISTA },
								{ label: 'A prazo', value: CONDICOES_PAGAMENTO.A_PRAZO },
							]}
							showClear={false}
							obrigatorio
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputDouble}
							label="Valor contrato"
							name="valorContrato"
							obrigatorio
							decimalScale={2}
							allowNegative={false}
							size={15}
							onChange={(e) => setFieldValue('valorContrato', e.target.value)}
							touched
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputDouble}
							label="Valor adiantamento"
							name="valorAdiantamento"
							decimalScale={2}
							allowNegative={false}
							size={15}
							onChange={(e) => setFieldValue('valorAdiantamento', e.target.value)}
							disabled={!canTransmitir || values.tipoPagamento === CONDICOES_PAGAMENTO.A_VISTA}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={Checkbox}
							label={isTablet ? 'Ind. de alto desempenho' : 'Indicador de alto desempenho'}
							name="indicadorAltoDesempenho"
							colStyle={{ paddingTop: isMobile ? '10px' : '32px' }}
							onChange={(e) => setFieldValue('indicadorAltoDesempenho', e.checked)}
							checked={values.indicadorAltoDesempenho}
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<Divider label="Dados bancários" />
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={SingleSelectBanco}
							label="Banco"
							name="banco"
							keyfilter={keyFilterConsultaRsql}
							useFormErrors={false}
							useFormTouched={false}
							obrigatorio
							onChange={(e) => onChangeBanco(e)}
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="2"
							xl="2"
							component={InputField}
							size={10}
							label="Agência"
							name="bancoAgencia"
							obrigatorio
							onChange={(e) => setFieldValue('bancoAgencia', e.target.value)}
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={InputMask}
							label="CNPJ instituição"
							name="cnpjBancoPagamentoEletronico"
							mask="00.000.000/0000-00"
							placeholder="  .   .   /    -  "
							size={14}
							onChange={(e) => setFieldValue('cnpjBancoPagamentoEletronico', e.target.value)}
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="5"
							lg="7"
							xl="7"
							component={InputField}
							size={60}
							label="Chave PIX"
							name="chavePix"
							onChange={(e) => setFieldValue('chavePix', e.target.value)}
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<TabView
							activeIndex={activeIndexTab}
							onTabChange={(event) => {
								setActiveIndexTab(event.index);
							}}
							{...informacoesPermissoes}
							className="tab-view-no-border"
						>
							<TabPanel header="Componentes">
								<Componentes canTransmitir={canTransmitir} />
							</TabPanel>
							<TabPanel
								header="Prazos"
								headerStyle={values.tipoPagamento === CONDICOES_PAGAMENTO.A_VISTA ? { display: 'none' } : null}
							>
								<Prazos
									canTransmitir={canTransmitir}
									isGerarParcelas={isGerarParcelas}
									setIsGerarParcelas={setIsGerarParcelas}
									indexRecalcularRateioParcela={indexRecalcularRateioParcela}
									setIndexRecalcularRateioParcela={setIndexRecalcularRateioParcela}
									isAPrazo={isAPrazo}
									rateioParcelas={rateioParcelas}
									recalcularParcelas={recalcularParcelas}
									recalcularRateioParcela={recalcularRateioParcela}
								/>
							</TabPanel>
						</TabView>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalPagamento = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,

	mapPropsToValues({ values }) {
		if (values) {
			return converterMDFePagamentoParaForm(values);
		} else {
			return {
				id: null,
				tipoPessoa: TIPO_PESSOA.JURIDICA,
				nome: null,
				cnpj: null,
				cpf: null,
				idEstrangeiro: null,
				valorContrato: 0,
				tipoPagamento: CONDICOES_PAGAMENTO.A_VISTA,
				quantidadeParcelas: null,
				valorAdiantamento: 0,
				indicadorAltoDesempenho: false,
				banco: null,
				bancoAgencia: null,
				cnpjBancoPagamentoEletronico: null,
				chavePix: null,
				componentes: [],
				prazos: [],
			};
		}
	},

	validate(values) {
		const errors = {};

		if (!values.nome) {
			errors.nome = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.tipoPessoa === TIPO_PESSOA.JURIDICA) {
			if (!values.cnpj) {
				errors.cnpj = mensagensDeValidacao.OBRIGATORIO;
			} else if (!isValidCNPJ(values.cnpj)) {
				errors.cnpj = mensagensDeValidacao.CNPJ_INVALIDO;
			}
		} else if (values.tipoPessoa === TIPO_PESSOA.FISICA) {
			if (!values.cpf) {
				errors.cpf = mensagensDeValidacao.OBRIGATORIO;
			} else if (!isValidCPF(values.cpf)) {
				errors.cpf = mensagensDeValidacao.CPF_INVALIDO;
			}
		} else if (values.tipoPessoa === TIPO_PESSOA.ESTRANGEIRO) {
			if (!values.idEstrangeiro) {
				errors.idEstrangeiro = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		if (values.valorContrato <= 0) {
			errors.valorContrato = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.banco) {
			errors.banco = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.bancoAgencia) {
			errors.bancoAgencia = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.cnpjBancoPagamentoEletronico && !isValidCNPJ(values.cnpjBancoPagamentoEletronico)) {
			errors.cnpjBancoPagamentoEletronico = mensagensDeValidacao.CNPJ_INVALIDO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalPagamentoImpl);

export { ModalPagamento };
