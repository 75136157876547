import { useEffect } from 'react';
import * as Yup from 'yup';
import { isValid, parseISO, format, formatISO, startOfMonth } from 'date-fns';
import { withFormik, Field } from 'formik';
import { Dropdown, Grid, InputDate, MultipleSelectSetores } from 'components';
import { mensagensDeValidacao, services } from 'Common';
import ModalRelatorio from '../../../../../../components/ModalRelatorio';
import { helpMessage } from './util/constantes';
import { asyncBaixarRelatorioFaturamentoPorPeriodo } from '../../../NFe/Requests';
import { validarFormulario } from 'views/Util';

const initialValue = {
  dataInicial: formatISO(startOfMonth(new Date())),
  dataFinal: new Date().toISOString(),
  documentos: 'Todos',
  operacoes: 'Todas',
  setores: null,
};

function FaturamentoPorPeriodo(props) {
  const { visible, onHide, values, handleSubmit, validateForm, isValid, setFieldValue } = props;

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('InputDataInicial')?.focus();
    }, 500);
  }, []);

  async function imprimir() {
    await handleSubmit();
    await validateForm();

    try {
      let dataInicialFormatada = format(parseISO(values.dataInicial), 'yyyy-MM-dd');
      let dataFinalFormatada = format(parseISO(values.dataFinal), 'yyyy-MM-dd');
      let setores = '';

      if (values.setores) {
        setores = values.setores.map(item => `'${item.value}'`).join(',');
      }

      if (await validarFormulario(props)) {
        await asyncBaixarRelatorioFaturamentoPorPeriodo(
          dataInicialFormatada,
          dataFinalFormatada,
          values.documentos,
          values.operacoes,
          setores,
          ({ data: pdf }) => {
            let arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
            let arquivoURL = URL.createObjectURL(arquivoPDF);
            let relatorio = window.open(arquivoURL);
            if (relatorio) {
              relatorio.onload = () => {
                setTimeout(() => {
                  relatorio.document.title = 'Relatório faturamento por período';
                }, 250);
              };
            }
            onHide();
          }
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  const parametros = (
    <Grid>
      <Field
        sm="12"
        md="6"
        lg="6"
        xl="6"
        component={InputDate}
        label="Data inicial"
        name="dataInicial"
        obrigatorio
        helpMessage={helpMessage.dataInicial}
        value={values.dataInicial}
        touched={true}
        id="InputDataInicial"
      />
      <Field
        sm="12"
        md="6"
        lg="6"
        xl="6"
        component={InputDate}
        label="Data final"
        name="dataFinal"
        obrigatorio
        helpMessage={helpMessage.dataFinal}
        value={values.dataFinal}
        touched={true}
      />
      <Field
        sm="12"
        md="3"
        lg="3"
        xl="3"
        component={Dropdown}
        label="Documento(s)"
        name="documentos"
        onChange={e => setFieldValue('documentos', e.value)}
        options={[
          { value: 'NF-e', label: 'NF-es' },
          { value: 'NFC-e', label: 'NFC-es' },
          { value: 'Todos', label: 'NF-es e NFC-es' },
        ]}
        value={values.documentos}
        showClear={false}
      />
      <Field
        sm="12"
        md="3"
        lg="3"
        xl="3"
        component={Dropdown}
        label="Operacão(es)"
        name="operacoes"
        onChange={e => setFieldValue('operacoes', e.value)}
        options={[
          { value: 'ENTRADA', label: 'Entrada' },
          { value: 'SAIDA', label: 'Saída' },
          { value: 'Todas', label: 'Entrada e saída' },
        ]}
        value={values.operacoes}
        showClear={false}
      />
      <Field
        sm="12"
        md="6"
        lg="6"
        xl="6"
        component={MultipleSelectSetores}
        label="Setor(es)"
        name="setores"
        obrigatorio
        url={`${services.GESTOR}/v1/pedidos_venda/relacoes/setores`}
        onChange={e => setFieldValue('setores', e)}
        value={values.setores}
        showClear={false}
        utilizaFavorito={true}
      />
    </Grid>
  );
  return (
    <ModalRelatorio
      header={'Relatório faturamento por período'}
      visible={visible}
      onHide={onHide}
      onImprimir={imprimir}
      parametros={parametros}
      isValid={props.errors.length === 0}
    />
  );
}

const ModalFaturamentoPorPeriodo = withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: false,

  mapPropsToValues() {
    return initialValue;
  },

  validate(values) {
    let errors = {};

    if (!values.dataInicial || values.dataInicial === '') {
      errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
    }

    if (!values.dataFinal || values.dataFinal === '') {
      errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
    }

    if (values.dataInicial && !isValid(parseISO(values.dataInicial))) {
      errors.dataInicial = mensagensDeValidacao.DATA_INVALIDA;
    }

    if (values.dataFinal && !isValid(parseISO(values.dataFinal))) {
      errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
    }

    return errors;
  },

  validationSchema: Yup.object().shape({
    dataInicial: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
    dataFinal: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
    setores: Yup.array().min(1, mensagensDeValidacao.OBRIGATORIO).nullable().required(mensagensDeValidacao.OBRIGATORIO),
  }),

  handleSubmit: () => {},
})(FaturamentoPorPeriodo);

export default ModalFaturamentoPorPeriodo;
