import { Field, useFormikContext } from 'formik';

import {
	Col,
	Grid,
	InputDate,
	InputField,
	SingleSelectObjetoServico,
	SingleSelectOperacaoFiscal,
	SingleSelectPessoa,
	TextArea,
} from 'components';
import { buscarDadosLoginLocalStorage, services } from 'Common';

import { MUNICIPIO_PRESTACAO } from 'views/configuracao/ConfiguracoesGerais/Util/constantes';
import { TipoPessoa } from 'views/cadastros/Pessoas/Util/constantes';
import { converterValueParaSelect } from '../../../Util/ordemServicoConverterFormulario';
import { useContextOrdemServico } from '../../../Context';
import { TAB_ITENS } from '../../../Util/constantes';

export function CabecalhoOrdemServico({ isMobile, buscarTabelaPreco, buscarMunicipioIdPessoa }) {
	const { values } = useFormikContext();
	const { setClienteUsaPrecoAtacado, functionsForm, disabledFields, tabItensIndex } = useContextOrdemServico();

	const { padraoMunicipioPrestacao: parametroMunicipioPrestacao } =
		buscarDadosLoginLocalStorage().filialConectada.parametrosServicos;
	const hiddenCurrentKm = values.objetoServico?.registro?.tipo !== 'VEICULO';

	function handleChangeCliente(value) {
		functionsForm.handleChangeSelect('cliente', value);
		setClienteUsaPrecoAtacado(value.registro?.configPrecoPraticado === 'PRECO_ATACADO');

		if (parametroMunicipioPrestacao === MUNICIPIO_PRESTACAO.TOMADOR && value.registro?.enderecos?.length > 0) {
			let endereco = value.registro?.enderecos.find((endereco) => endereco.favorito);

			if (typeof endereco === 'undefined') {
				endereco = value.registro?.enderecos[0];
			}

			functionsForm.handleChangeSelect(
				'informacoesComplementares.servico.municipio',
				converterValueParaSelect(endereco.municipio, `${endereco.municipio.nome} - ${endereco.municipio.estadoSigla}`)
			);
		}

		setTimeout(() => {
			let elementHtmlProduto = null;
			if (tabItensIndex === TAB_ITENS.SERVICOS) {
				elementHtmlProduto = document
					.getElementById('id-servicos-listagem')
					?.getElementsByTagName('table')[0]
					?.getElementsByTagName('tr')
					[values.servicos.length]?.getElementsByClassName('id-servicos-listagem-campo-servico')[0];
			} else if (tabItensIndex === TAB_ITENS.PRODUTOS) {
				elementHtmlProduto = document
					.getElementById('id-produtos-listagem')
					?.getElementsByTagName('table')[0]
					?.getElementsByTagName('tr')
					[values.produtos.length]?.getElementsByClassName('id-produtos-listagem-campo-produto')[0];
			}
			elementHtmlProduto?.click();
		}, 200);

		buscarTabelaPreco(
			{
				vendedorId: values.informacoesComplementares?.vendedor?.value,
				clienteId: value?.value,
				municipioId: buscarMunicipioIdPessoa(value),
			},
			({ data: tabelaPrecoApi }) => {
				const tabelaPreco = {
					label: `${tabelaPrecoApi.codigo} - ${tabelaPrecoApi.nome}`,
					registro: tabelaPrecoApi,
					value: tabelaPrecoApi.id,
				};

				functionsForm.handleChangeSelect('informacoesComplementares.tabelaPreco', tabelaPreco);
			}
		);
	}

	return (
		<Col style={{ paddingBottom: '0px' }}>
			<Grid className="step-ordem-servico-cliente">
				<Field
					id="OrdemServicoSingleSelectCliente"
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={SingleSelectPessoa}
					label="Cliente"
					name="cliente"
					value={values.cliente}
					onChange={handleChangeCliente}
					tipoPessoa={TipoPessoa.CLIENTE}
					obrigatorio
					isClearable={false}
					disabled={disabledFields}
				/>
				<Field
					sm="8"
					md="4"
					lg="2"
					xl="2"
					col={isMobile ? '8' : '12'}
					component={InputDate}
					label="Data abertura"
					name="dataAbertura"
					value={values.dataAbertura}
					onChange={functionsForm.handleChangeInput}
					obrigatorio
					disabled={disabledFields}
				/>
				<Field
					sm="4"
					md="2"
					lg="1"
					xl="1"
					col={isMobile ? '4' : '12'}
					component={InputField}
					type="time"
					name="horaAbertura"
					value={values.horaAbertura}
					onChange={functionsForm.handleChangeInput}
					style={{ marginTop: '17px' }}
					disabled={disabledFields}
				/>
				<Field
					sm="8"
					md="4"
					lg="2"
					xl="2"
					col={isMobile ? '8' : '12'}
					component={InputDate}
					label="Data fechamento"
					name="dataFechamento"
					value={values.dataFechamento}
					onChange={functionsForm.handleChangeInput}
					disabled={disabledFields}
				/>
				<Field
					sm="4"
					md="2"
					lg="1"
					xl="1"
					col={isMobile ? '4' : '12'}
					component={InputField}
					type="time"
					name="horaFechamento"
					value={values.horaFechamento}
					onChange={functionsForm.handleChangeInput}
					style={{ marginTop: '17px' }}
					disabled={disabledFields}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={SingleSelectObjetoServico}
					name="objetoServico"
					url={`${services.GESTOR}/v1/ordem_servico/relacoes/objetos_servicos`}
					onChange={(value) => functionsForm.handleChangeSelect('objetoServico', value)}
					value={values.objetoServico}
					cliente={values.cliente}
					disabled={disabledFields || !values.cliente}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={SingleSelectOperacaoFiscal}
					name="operacaoFiscal"
					value={values.operacaoFiscal}
					onChange={(value) => functionsForm.handleChangeSelect('operacaoFiscal', value)}
					isClearable={false}
					obrigatorio
					disabled={disabledFields}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputField}
					label="KM atual"
					type="number"
					name="quilometragemAtual"
					value={String(values.quilometragemAtual)}
					onChange={functionsForm.handleChangeInput}
					disabled={disabledFields}
					hidden={hiddenCurrentKm}
				/>

				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={TextArea}
					placeholder="Escreva o problema relatado pelo cliente aqui"
					label="Problema relatado"
					name="problemaRelatado"
					size={500}
					value={values.problemaRelatado}
					disabled={disabledFields}
					onChange={functionsForm.handleChangeInput}
				/>
			</Grid>
		</Col>
	);
}
