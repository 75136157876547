import { Col, Grid } from 'components';
import { CardMedidas } from './components/CardMedidas';
import { CardPreview } from './components/CardPreview';

import './Styles/index.css';

function EtiquetasPagina({ informacoesPermissoes }) {
  return (
    <Grid>
      <Col sm={12} md={12} lg={7} xl={8}>
        <CardMedidas informacoesPermissoes={informacoesPermissoes} />
      </Col>
      <Col sm={12} md={12} lg={5} xl={4}>
        <CardPreview />
      </Col>
    </Grid>
  );
}
export { EtiquetasPagina };
