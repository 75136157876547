export const INITIAL_VALUE = {
	id: null,
	nome: null,
	crc: null,
	cpf: null,
	cnpj: null,
	telefone: null,
	email: null,
	endereco: {
		cep: null,
		logradouro: null,
		numero: null,
		complemento: null,
		bairro: null,
		municipio: null,
	},
};
