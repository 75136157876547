import { useState } from 'react';
import { recursos, permissoes, usuarioPossuiPermissao, removerCaracteres, services } from 'Common';
import SingleSelect from '../SingleSelect';
import { ModalVeiculos } from 'views/cadastros/transporte/Veiculos/Modal';
import { formatarPlaca } from 'views/cadastros/transporte/Veiculos/Util/function';

export default function SingleSelectReboque({
	isMobile,
	label,
	setHideBackground = false,
	menuPortalTarget = document.body,
	onShowModal,
	onChange,
	errors,
	touched,
	url,
	...props
}) {
	const [visible, setVisible] = useState(false);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR));
	const [podeVisualizar] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR));
	const [resetPesquisa, setResetPesquisa] = useState(false);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		const urlCadastro = `${services.GESTOR}/v1/transporte/veiculos/resumo`;
		const pesquisaCodigo = removerCaracteres(pesquisa?.replaceAll('&', ''), ['.']);
		return `${url ?? urlCadastro}?query=(placa=contains="*${pesquisaCodigo}*",renavam=contains="*${pesquisa?.replaceAll(
			'&',
			'%26'
		)}*");situacao=="ATIVO";tipo=="REBOQUE"&page=${page}&size=20&sort=placa`;
	}

	function mostrarModal() {
		if (setHideBackground) {
			setHideBackground(true);
		}
		if (onShowModal) {
			onShowModal();
		}
		setVisible(!visible);
	}

	function esconderModal() {
		if (setHideBackground) {
			setHideBackground(false);
		}
		setVisible(false);
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			onChange({
				label: montarLabel(novoRegistro),
				value: novoRegistro.id,
				registro: novoRegistro,
			});
			setResetPesquisa(true);
		}
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		return `${formatarPlaca(row.placa)} - ${row.renavam}`;
	}

	return (
		<>
			<ModalVeiculos visible={visible} onHide={onHide} container={menuPortalTarget} tipo="REBOQUE" />
			<SingleSelect
				titleBotaoNovo="Adicionar novo reboque"
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				label={label}
				onChange={onChange}
				onClickModal={mostrarModal}
				errors={errors}
				useFormErrors={false}
				useFormTouched={false}
				touched={touched}
				disabledButton={!podeInserirEVisualizar}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
				styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
				menuPortalTarget={menuPortalTarget}
				placeholder={isMobile ? 'Selecione uma opção' : 'Digite para pesquisar'}
				{...props}
			/>
		</>
	);
}

SingleSelectReboque.defaultProps = {
	label: 'Reboque',
};
