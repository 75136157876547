import { Field, useFormikContext } from 'formik';

import { services } from 'Common';
import { Checkbox, Grid, SingleSelectSetor, SingleSelectUsuario, TextArea } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { MDFE_TIPO_EMITENTE } from 'views/transporte/MDFe/Form/Util/constantes';

function InformacoesComplementares() {
	const { values, errors, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, isSituacaoFinalMDFe } = useContextMDFe();

	const isCargaPropria = values.tipoEmitente === MDFE_TIPO_EMITENTE.CARGA_PROPRIA;

	function handleChangePermiteCarregamentoPosterior(e) {
		setFieldValue('informacaoComplementar.permiteCarregamentoPosterior', e.checked);

		if (e.checked) {
			if (values.ufCarregamento) {
				setFieldValue('ufDescarregamento', values.ufCarregamento);
			}
			if (values.municipios?.length > 0) {
				setFieldValue('municipios', []);
				setFieldValue('total.quantidadeDocumento', 0);
			}
		}
	}

	return (
		<Grid>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Permite carregamento posterior"
				name="informacaoComplementar.permiteCarregamentoPosterior"
				checked={values.informacaoComplementar?.permiteCarregamentoPosterior}
				onChange={handleChangePermiteCarregamentoPosterior}
				colStyle={{ marginTop: '8px' }}
				style={{ fontWeight: 'normal' }}
				disabled={isSituacaoFinalMDFe || !isCargaPropria}
				title={!isCargaPropria ? 'Carregamento posterior só é permitido para transporte de carga própria' : ''}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={SingleSelectUsuario}
				label="Usuário responsável"
				name="informacaoComplementar.usuario"
				obrigatorio
				disabled={isSituacaoFinalMDFe}
				isClearable={false}
				value={values?.informacaoComplementar?.usuario}
				url={`${services.GESTOR}/v1/mdfes/relacoes/usuarios`}
				onChange={(event) => setFieldValue('informacaoComplementar.usuario', event)}
				errors={errors.informacaoComplementar?.usuario}
				touched
				useFormErrors={false}
				esconderBotao
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={SingleSelectSetor}
				label="Setor"
				name="informacaoComplementar.setor"
				obrigatorio
				value={values?.informacaoComplementar?.setor}
				url={`${services.GESTOR}/v1/mdfes/relacoes/setores`}
				onChange={(event) => setFieldValue('informacaoComplementar.setor', event)}
				esconderBotao
				isClearable={false}
				disabled={isSituacaoFinalMDFe}
				id="selectSetor"
				errors={errors.informacaoComplementar?.setor}
				touched
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={TextArea}
				placeholder="Escreva informações que sejam importantes para você e seu cliente"
				label="Informações complementares"
				name="informacaoComplementar.informacaoComplementar"
				maxLength={2000}
				disabled={isSituacaoFinalMDFe}
				rows={4}
				onChange={(event) => setFieldValue('informacaoComplementar.informacaoComplementar', event.target.value)}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={TextArea}
				placeholder="Escreva observações para uso interno"
				label="Observação interna"
				name="informacaoComplementar.observacaoInterna"
				maxLength={5000}
				disabled={isSituacaoFinalMDFe}
				rows={4}
				onChange={(event) => setFieldValue('informacaoComplementar.observacaoInterna', event.target.value)}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { InformacoesComplementares };
