import { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { usuarioPossuiPermissao } from '../../../../../../../Common/Autenticacao';
import { recursos, permissoes } from '../../../../../../../Common/Constantes/autorizacao';
import If from '../../../../../../../components/If';
import ModalTransferencia from '../../../../../../financas/Lancamentos/components/ModalTransferencia';
import ModalLancamentoReceita from '../../../../../../financas/Lancamentos/components/ModalLancamentoReceita';
import ModalLancamentoDespesa from '../../../../../../financas/Lancamentos/components/ModalLancamentoDespesa';
import ModalContaReceber from '../../../../../../financas/ContasReceber/components/ModalContaReceber';
import ModalContaPagar from '../../../../../../financas/ContasPagar/components/ModalContaPagar';

export default function BotoesNovosLancamentos(props) {
	const { atualizarGraficos, isMobile } = props;

	const [exibirModalContaReceber, setExibirModalContaReceber] = useState(false);
	const [exibirModalContaPagar, setExibirModalContaPagar] = useState(false);
	const [exibirModalLancamentoDespesa, setExibirModalLancamentoDespesa] = useState(false);
	const [exibirModalLancamentoReceita, setExibirModalLancamentoReceita] = useState(false);
	const [exibirModalTransferencia, setExibirModalTransferencia] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [podeInserirContaAReceber, setPodeInserirContaAReceber] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.INSERIR)
	);
	const [podeInserirContaAPagar, setPodeInserirContaAPagar] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.INSERIR)
	);
	const [podeInserirNovaMovimentacao, setPodeInserirNovaMovimentacao] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.INSERIR)
	);
	const [novoLancamento, setNovoLancamento] = useState();

	const styleButtons = {
		margin: isMobile ? '3px 1%' : '3px 7px',
		width: isMobile ? '98%' : '185px',
	};
	const classNameButtons = isMobile ? 'p-grid p-justify-center' : 'p-grid p-justify-start';

	const opcoesNovoMobile = isMobile
		? [
				{
					label: 'Conta a pagar',
					icon: 'fa fa-plus',
					command: () => setExibirModalContaPagar(true),
					disabled: !podeInserirContaAPagar,
				},
				{
					label: 'Conta a receber',
					icon: 'fa fa-plus',
					command: () => setExibirModalContaReceber(true),
					disabled: !podeInserirContaAReceber,
				},
			]
		: [];

	function onHideModalContaReceber(novaConta) {
		setExibirModalContaReceber(false);
		novaConta && atualizarGraficos();
	}

	function onHideModalContaPagar(novaConta) {
		setExibirModalContaPagar(false);
		novaConta && atualizarGraficos();
	}

	function onHideModalTransferencia(novaTransferencia) {
		setExibirModalTransferencia(false);
		novaTransferencia && atualizarGraficos();
	}

	function onHideModalNovaReceita(novaReceita) {
		setExibirModalLancamentoReceita(false);
		novaReceita && atualizarGraficos();
	}

	function onHideModalNovaDespesa(novaDespesa) {
		setExibirModalLancamentoDespesa(false);
		novaDespesa && atualizarGraficos();
	}

	return (
		<>
			<Menu
				model={[
					...opcoesNovoMobile,
					{
						label: 'Receita',
						icon: 'fa fa-plus',
						command: () => setExibirModalLancamentoReceita(true),
						disabled: !podeInserirNovaMovimentacao,
					},
					{
						label: 'Despesa',
						icon: 'fa fa-plus',
						command: () => setExibirModalLancamentoDespesa(true),
						disabled: !podeInserirNovaMovimentacao,
					},
					{
						label: 'Transferência',
						icon: 'fa fa-plus',
						command: () => setExibirModalTransferencia(true),
						disabled: !podeInserirNovaMovimentacao,
					},
				]}
				popup={true}
				ref={(el) => setNovoLancamento(el)}
			/>
			<div className="p-col-12" style={{ padding: '0px' }}>
				<div className={classNameButtons} style={{ margin: '0px' }}>
					{!isMobile && (
						<Button
							label="Nova conta a receber"
							className="p-button-success"
							onClick={() => setExibirModalContaReceber(true)}
							style={styleButtons}
							disabled={!podeInserirContaAReceber}
						/>
					)}
					{!isMobile && (
						<Button
							label="Nova conta a pagar"
							className="p-button-danger"
							onClick={() => setExibirModalContaPagar(true)}
							style={styleButtons}
							disabled={!podeInserirContaAPagar}
						/>
					)}

					<Button
						className="p-button-primary"
						type="button"
						label={isMobile ? 'Novo registro' : 'Nova movimentação'}
						icon="fa fa-angle-down"
						iconPos="right"
						onClick={(event) => novoLancamento.toggle(event)}
						style={styleButtons}
						disabled={
							(!podeInserirNovaMovimentacao && !isMobile) ||
							(!podeInserirNovaMovimentacao && !podeInserirContaAPagar && !podeInserirContaAReceber)
						}
					/>
				</div>
			</div>
			<If test={exibirModalTransferencia}>
				<ModalTransferencia
					transferenciaSelecionada={null}
					visible={exibirModalTransferencia}
					valorPadraoData={new Date()}
					onHide={onHideModalTransferencia}
				/>
			</If>
			<If test={exibirModalLancamentoReceita}>
				<ModalLancamentoReceita
					registroSelecionado={null}
					visible={exibirModalLancamentoReceita}
					valorPadraoData={new Date()}
					onHide={onHideModalNovaReceita}
				/>
			</If>
			<If test={exibirModalLancamentoDespesa}>
				<ModalLancamentoDespesa
					registroSelecionado={null}
					visible={exibirModalLancamentoDespesa}
					valorPadraoData={new Date()}
					onHide={onHideModalNovaDespesa}
				/>
			</If>
			<If test={exibirModalContaReceber}>
				<ModalContaReceber
					visible={exibirModalContaReceber}
					onHide={onHideModalContaReceber}
					registroSelecionado={null}
				/>
			</If>
			<If test={exibirModalContaPagar}>
				<ModalContaPagar
					visible={exibirModalContaPagar}
					onHide={onHideModalContaPagar}
					registroSelecionado={registroSelecionado}
				/>
			</If>
		</>
	);
}
