import { Field, useFormikContext, withFormik } from 'formik';

import { FormActions, FormContent, InputField, Modal } from 'components';
import { copiarObjeto, mensagensDeValidacao } from 'Common';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { INITIAL_VALUE_LACRES } from 'views/transporte/MDFe/Form/Util/constantes';

import { ActionButtons } from './components/ActionButtons';

function ModalLacreImpl({ visible, indexRegistro, setFieldValueMDFe, onCloseModal, lacres, isNovoRegistro }) {
	const { errors, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, isSituacaoFinalMDFe } = useContextMDFe();
	return (
		<Modal header={indexRegistro ? 'Cadastro de lacre' : 'Dados do lacre'} visible={visible} onHide={onCloseModal}>
			<FormActions>
				<ActionButtons
					setFieldValueMDFe={setFieldValueMDFe}
					indexRegistro={indexRegistro}
					onCloseModal={onCloseModal}
					lacres={lacres}
					isNovoRegistro={isNovoRegistro}
				/>
			</FormActions>
			<FormContent>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					label="Número lacre"
					name="lacre"
					size={60}
					errors={errors.lacre}
					obrigatorio
					onChange={(e) => setFieldValue('lacre', e.target.value)}
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
			</FormContent>
		</Modal>
	);
}

const ModalLacre = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.lacre) {
			return copiarObjeto(props.lacre);
		}

		return INITIAL_VALUE_LACRES;
	},

	validate(values) {
		const errors = {};

		if (!values.lacre) {
			errors.lacre = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalLacreImpl);

export { ModalLacre };
