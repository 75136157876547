import { formatarMonetario } from 'Common';
import { Button, If, NenhumRegistroEncontrado } from 'components';
import { ModalDetalhesPix } from 'components/ModalDetalhesPix';
import { format, parseISO } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import './Styles/index.css';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

const styleValorField = {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

function TabelaRecebimentos({
	podeEditar,
	podeExcluir,
	registros,
	dadosPix,
	onEditar,
	onExcluir,
	onImprimirRecibo,
	hasBoleto,
	hasPix,
	dirty,
	isMobile,
	isReparcelada,
}) {
	const [modalDetalhesPixVisible, setModalDetalhesPixVisible] = useState(false);

	function renderData(el) {
		return <span>{format(parseISO(el.data), 'dd/MM/yyyy')}</span>;
	}

	function renderValor(el) {
		const valor = formatarMonetario(el.valor);
		return <span title={valor}>{valor}</span>;
	}

	function renderObservacao(el) {
		return <span>{el.observacao}</span>;
	}

	function renderConta(el) {
		return (
			<span
				name={el.nome}
				style={{
					wordBreak: 'break-all',
					paddingLeft: isMobile ? '15%' : '0',
				}}
			>
				{el.conta.label}
			</span>
		);
	}

	function renderJurosMulta(el) {
		const valor = formatarMonetario(el.juros + el.multa);
		return <span title={valor}>{valor}</span>;
	}

	function renderTotal(el) {
		const valor = formatarMonetario(el.valor + el.juros + el.multa - el.desconto);
		return (
			<span style={{ fontWeight: 'bold' }} title={valor}>
				{valor}
			</span>
		);
	}

	function renderDesconto(el) {
		const valor = formatarMonetario(el.desconto);
		return <span title={valor}>{valor}</span>;
	}

	function renderAcoes(el) {
		let titleEditar = 'Editar';
		let titleExcluir = 'Excluir';
		let titleImprimiRecibo = 'Imprimir recibo';
		let disableBtnEditar = false;
		let disableBtnExcluir = false;
		const disableBtnImprimir = dirty || el.id === null;

		if (!podeEditar) {
			titleEditar = 'Você não possuir permissão para executar essa ação';
			disableBtnEditar = true;
		}

		if (!podeExcluir) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		}

		if (disableBtnImprimir) {
			titleImprimiRecibo = 'Não é permitido imprimir o recibo com o registro em edição';
		}

		if (hasBoleto) {
			titleEditar = 'Pagamento realizado via boleto bancário';
			disableBtnEditar = true;

			titleExcluir = 'Pagamento realizado via boleto bancário';
			disableBtnExcluir = true;
		}

		if (hasPix) {
			titleEditar = 'Pagamento realizado via pix cobrança';
			disableBtnEditar = true;

			titleExcluir = 'Pagamento realizado via pix cobrança';
			disableBtnExcluir = true;
		}

		return (
			<span>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={titleEditar}
					disabled={disableBtnEditar || isReparcelada}
					onClick={() => onEditar(el)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir || isReparcelada}
					onClick={() => onExcluir(el)}
				/>

				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-print"
					title={titleImprimiRecibo}
					disabled={disableBtnImprimir}
					onClick={() => onImprimirRecibo(el.id)}
				/>
			</span>
		);
	}

	if (registros && registros.length > 0) {
		return (
			<>
				<DataTable
					className="table"
					responsive
					value={registros}
					emptyMessage={<NenhumRegistroEncontrado />}
					style={{ margin: '0px 5px 0px 5px' }}
				>
					<Column header="Data" field="data" body={renderData} />
					<Column header="Conta" field="conta" body={renderConta} />
					<Column
						header="Observação"
						field="observacao"
						body={renderObservacao}
						style={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							maxWidth: '200px',
						}}
					/>
					<Column style={styleValorField} header="Valor" field="valor" body={renderValor} />
					<Column style={styleValorField} header="Juros + Multa" field="juros" body={renderJurosMulta} />
					<Column style={styleValorField} header="Descontos" field="desconto" body={renderDesconto} />
					<Column style={styleValorField} header="Total" field="total" body={renderTotal} />
					<Column header="Ações" style={{ width: '130px' }} body={renderAcoes} />
				</DataTable>
				<If test={modalDetalhesPixVisible}>
					<ModalDetalhesPix
						visible={modalDetalhesPixVisible}
						pixId={dadosPix?.id}
						valor={dadosPix?.valor}
						onHide={() => {
							setModalDetalhesPixVisible(false);
						}}
					/>
				</If>
			</>
		);
	} else {
		return null;
	}
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(withRouter(TabelaRecebimentos));
