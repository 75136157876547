import { Grid, TextArea } from 'components';
import { Field, useFormikContext } from 'formik';

function FormObservacoes({ informacoesPermissoes }) {
	const { setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={TextArea}
				placeholder="Escreva sua observação aqui"
				name="observacao"
				onChange={(e) => setFieldValue('observacao', e.target.value)}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { FormObservacoes };
