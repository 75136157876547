import { useEffect, useState } from 'react';
import { useFormikContext, withFormik } from 'formik';
import { connect } from 'react-redux';
import {
	FormActions,
	FormContent,
	Form,
	If,
	tutorialStepsEtiquetaProdutoConfiguracao,
	Tutorial,
	Prompt,
} from 'components';
import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	estadosCadastro,
	mensagensDeValidacao,
	salvarConfiguracaoUsuario,
	validarUUID,
} from 'Common';
import { useContextEtiquetaConfiguracao } from './Context';

import { InformacoesPrincipais } from './components/InformacoesPrincipais';
import { EtiquetasPagina } from './components/EtiquetaPagina';
import { EtiquetasCampos } from './components/EtiquetaCampos';

import { INITIAL_VALUE } from './Util/constantes';
import { converterEtiquetaParaFormulario } from './Util/EtiquetaConverter';
import { isCampos, isPagina } from './Util/functions';
import { asyncGetEtiqueta } from '../Request';
import { ActionButtons } from './components/ActionButtons';

import './Styles/index.css';

function CadastroEtiquetaView({ history, match, isMobile, isTablet, isLessHd }) {
	const { values, dirty, resetForm } = useFormikContext();
	const { podeInserir, podeEditar, podeExcluir, paginaOuCampos } = useContextEtiquetaConfiguracao();

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_ETIQUETA_CADASTRO);

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};

	useEffect(() => {
		const idUrl = match.params.id;

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_ETIQUETA_CADASTRO, false, null, false);
		}

		if (validarUUID(idUrl)) {
			BuscarEtiqueta(idUrl);
		}

		setTimeout(() => {
			document.getElementById('etiquetaDescricaoInputField')?.focus();
		}, 500);
	}, []);

	function BuscarEtiqueta(id) {
		asyncGetEtiqueta(id, ({ data: etiqueta }) => {
			const convertedEtiqueta = converterEtiquetaParaFormulario(etiqueta);
			resetForm({ values: convertedEtiqueta });
		});
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Tutorial
				steps={tutorialStepsEtiquetaProdutoConfiguracao}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>

			<Form className="form-configuracao-etiqueta" header="Cadastro de etiquetas">
				<FormActions>
					<ActionButtons informacoesPermissoes={informacoesPermissoes} history={history} />
				</FormActions>
				<FormContent>
					<InformacoesPrincipais informacoesPermissoes={informacoesPermissoes} />

					<If test={isPagina(paginaOuCampos)}>
						<EtiquetasPagina informacoesPermissoes={informacoesPermissoes} />
					</If>
					<If test={isCampos(paginaOuCampos)}>
						<EtiquetasCampos
							informacoesPermissoes={informacoesPermissoes}
							isMobile={isMobile}
							isTablet={isTablet}
							isLessHd={isLessHd}
						/>
					</If>
				</FormContent>
			</Form>
		</>
	);
}

const CadastroEtiqueta = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUE;
	},

	validate(values) {
		const errors = {};

		if (values.descricao === null || values.descricao === undefined) {
			errors.descricao = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.tipoPapel === null || values.tipoPapel === undefined) {
			errors.tipoPapel = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.alturaPagina === null || values.alturaPagina === undefined) {
			errors.alturaPagina = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.larguraPagina === null || values.larguraPagina === undefined) {
			errors.larguraPagina = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.linhasPorPagina === null || values.linhasPorPagina === undefined) {
			errors.linhasPorPagina = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.etiquetasPorLinha === null || values.etiquetasPorLinha === undefined) {
			errors.etiquetasPorLinha = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.margemSuperior === null || values.margemSuperior === undefined) {
			errors.margemSuperior = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.margemInferior === null || values.margemInferior === undefined) {
			errors.margemInferior = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.margemDireita === null || values.margemDireita === undefined) {
			errors.margemDireita = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.margemEsquerda === null || values.margemEsquerda === undefined) {
			errors.margemEsquerda = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.espacamentoColuna === null || values.espacamentoColuna === undefined) {
			errors.espacamentoColuna = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.espacamentoLinha === null || values.espacamentoLinha === undefined) {
			errors.espacamentoLinha = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.alturaEtiqueta === null || values.alturaEtiqueta === undefined) {
			errors.alturaEtiqueta = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.larguraEtiqueta === null || values.larguraEtiqueta === undefined) {
			errors.larguraEtiqueta = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.exibirContornoEtiqueta === null || values.exibirContornoEtiqueta === undefined) {
			errors.exibirContornoEtiqueta = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.exibirContornoCampos === null || values.exibirContornoCampos === undefined) {
			errors.exibirContornoCampos = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},
	handleSubmit: () => {},
})(CadastroEtiquetaView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
	isLessHd: state.dispositivo.isLessHd,
});

export default connect(mapStateToProps)(CadastroEtiqueta);
