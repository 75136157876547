import { ButtonCancelar, ButtonNovo, ButtonSalvar, estadosBotaoCancelar, estadosBotaoSalvar } from 'components';
import { useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';

function ActionButtons({ novoSignatario, cancelar, salvar }) {
	const { dirty } = useFormikContext();
	const { informacoesPermissoes, podeEditarEmpresa } = useContextConfiguracoesGerais();

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={() => cancelar()}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar
				estadoBotao={estadosBotaoSalvar.SALVAR}
				disabled={!dirty}
				onClick={() => salvar()}
				{...informacoesPermissoes}
			/>
			<ButtonNovo
				label="Novo signatário"
				enableShortCut={false}
				onClick={() => novoSignatario()}
				disabled={!podeEditarEmpresa}
				podeInserir={podeEditarEmpresa}
			/>
		</>
	);
}

export { ActionButtons };
