import { Modal } from '../../../../../components';
import FieldsEnviarPorEmail from './components/FieldsEnviarPorEmail';

function ModalEnviarCobrancasPorEmail(props) {
	const { visible, onHide, cobrancas, cobrancasIds, dadosPessoaParaEmail } = props;

	return (
		<Modal header="Enviar cobranças por e-mail" visible={visible} onHide={onHide}>
			<FieldsEnviarPorEmail
				onHide={onHide}
				cobrancas={cobrancas}
				cobrancasIds={cobrancasIds}
				dadosPessoaParaEmail={dadosPessoaParaEmail}
			/>
		</Modal>
	);
}

export default ModalEnviarCobrancasPorEmail;
