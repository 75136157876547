import { Field, withFormik } from 'formik';
import { useCallback } from 'react';
import {
	ButtonCancelar,
	ButtonSalvar,
	Col,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
	FormActions,
	FormContent,
	Grid,
	If,
	InputField,
	Message,
	Modal,
} from '../../../../../../../../../../components';
import { copiarObjeto, mensagensDeValidacao } from '../../../../../../../../../../Common';
import CertificadoUpload from '../../../../../../../../../configuracao/ConfiguracoesGerais/components/CertificadoUpload';
import { asyncEnvioCertificadoConvenio } from '../../../../../Requests';
import { validarFormulario } from '../../../../../../../../../Util';

const initialValue = {
	nomeCertificado: '',
	certificado: null,
	certificadoAlterado: false,
	senha: '',
	confirmarSenha: '',
	convenioId: null,
	contaId: null,
};

const messageConvenioCertificado =
	'Lembre-se, o certificado é sincronizado com o convênio. Em caso de múltiplos convênios, o certificado deve ser sincronizado de forma individual';

function ModalConvenioCertificadoView(props) {
	const { visible, onHide, informacoesPermissoes, dirty, values, setFieldValue, alterarNomeCertificado } = props;

	const cancelar = useCallback(() => {
		if (dirty) {
			props.resetForm({ values: copiarObjeto(initialValue) });
		} else {
			onHide();
		}
	}, [dirty]);

	const salvar = useCallback(async () => {
		props.handleSubmit();

		if (await validarFormulario(props)) {
			asyncEnvioCertificadoConvenio(values.contaId, values.convenioId, values.certificado, values.senha, e => {
				alterarNomeCertificado(values.nomeCertificado);
			});
		}
	});

	function onChangeCertificado(novoCertificado) {
		setFieldValue('certificado', novoCertificado);
		setFieldValue('nomeCertificado', novoCertificado.name);
		setFieldValue('certificadoAlterado', true);
	}

	function buscarSenhaFake() {
		if (values.nomeCertificado && !values.certificadoAlterado) {
			return '••••••••••';
		}
		return '';
	}

	return (
		<>
			<Modal
				header={'Dados do certificado'}
				visible={visible}
				onHide={() => {
					onHide();
				}}
			>
				<FormActions>
					<ButtonCancelar
						{...informacoesPermissoes}
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
					/>
					<ButtonSalvar
						{...informacoesPermissoes}
						estadoBotao={estadosBotaoSalvar.SALVAR}
						disabled={!dirty}
						onClick={salvar}
					/>
				</FormActions>
				<If test={!values?.nomeCertificado}>
					<Message severity="info" text={messageConvenioCertificado} />
				</If>
				<FormContent>
					<Grid style={{ marginTop: '16px', alignItems: 'center' }}>
						<Col sm="12" md="6" lg="6" xl="6">
							<CertificadoUpload
								onChange={onChangeCertificado}
								value={{ name: values.nomeCertificado }}
								podeEditarEmpresa={informacoesPermissoes.podeEditar}
							/>
						</Col>
						<Col sm="12" md="6" lg="6" xl="6">
							<Field
								sm="12"
								label="Senha do certificado"
								name="senha"
								size={128}
								component={InputField}
								type="password"
								disabled={!values.certificadoAlterado}
								title={
									!values.certificadoAlterado
										? 'Para alterar a senha é necessário inserir um novo certificado'
										: ''
								}
								placeholder={buscarSenhaFake()}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								label="Confirme a senha do certificado"
								name="confirmarSenha"
								size={128}
								component={InputField}
								type="password"
								title={
									!values.certificadoAlterado
										? 'Para alterar a senha é necessário inserir um novo certificado'
										: ''
								}
								placeholder={buscarSenhaFake()}
								disabled={!values.certificadoAlterado}
								{...informacoesPermissoes}
							/>
						</Col>
					</Grid>
				</FormContent>
			</Modal>
		</>
	);
}

const ModalConvenioCertificado = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.nomeCertificado) {
			return {
				...initialValue,
				nomeCertificado: props.nomeCertificado,
				convenioId: props.convenioId,
				contaId: props.contaId,
			};
		} else {
			return { ...initialValue, convenioId: props.convenioId, contaId: props.contaId };
		}
	},

	validate(props) {
		let errors = {};
		if (props.certificadoAlterado) {
			if (props.senha !== props.confirmarSenha) {
				errors.confirmarSenha = 'A senha e a confirmação da senha não conferem';
			}
			if (!props.confirmarSenha) {
				errors.confirmarSenha = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalConvenioCertificadoView);

export default ModalConvenioCertificado;
