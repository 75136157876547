import { useEffect, useState } from 'react';
import { recursos, permissoes } from '../../../../../Common/Constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../../../Common/Autenticacao';
import { connect } from 'react-redux';
import Grid from '../../../../../components/Grid';
import { Button } from 'primereact/button';
import { withRouter } from 'react-router-dom';
import DocumentosExpiracaoPeriodo from './components/ListagemDocumentosExpiracaoPeriodo';
import Col from '../../../../../components/Col';
import DocumentosAssinadosPeriodo from './components/ListagemDocumentosAssinadosPeriodo';
import CardTotalDocumentosPorStatus from './components/CardTotalDocumentosPorStatus';
import GraficoDocumentosPorStatusPeriodo from './components/GraficoDocumentosPorStatusPeriodo';
import GraficoDocumentosArquivadosPeriodo from './components/GraficoDocumentosArquivadosPeriodo';

function DashboardDocumentosDigitais(props) {
	const { dataInicial, dataFinal, isMobile, dataAtualizacaoMenuLateral } = props;

	const [podeInserirDocumentos, setPodeInserirDocumentos] = useState(false);

	const styleButtons = {
		margin: isMobile ? '3px 1%' : '3px 7px',
		width: isMobile ? '98%' : '185px',
	};

	const classNameButtons = isMobile ? 'p-grid p-justify-center' : 'p-grid p-justify-start';
	const styleCards = isMobile ? { padding: '0.5em 0em', margin: '8px', flex: 'auto' } : { padding: '0.5em' };

	useEffect(() => {
		setPodeInserirDocumentos(
			usuarioPossuiPermissao(recursos.DOCUMENTOS_ASSINATURA, permissoes.INSERIR) &&
				usuarioPossuiPermissao(recursos.DOCUMENTOS_ASSINATURA, permissoes.VISUALIZAR)
		);
	});

	if (dataInicial && dataFinal) {
		return (
			<Grid style={{ margin: '0px' }}>
				<div className="col-12" style={{ padding: '0 0 0 2px' }}>
					<div className={classNameButtons} style={{ margin: '0px' }}>
						<Button
							color="primary"
							label="Novo documento"
							style={styleButtons}
							disabled={!podeInserirDocumentos}
							onClick={() => props.history.push('documentos/assinatura/cadastro')}
						/>
					</div>
				</div>
				<Col xs="12" sm="12" md="12" lg="12" xl="6" style={styleCards}>
					<CardTotalDocumentosPorStatus dataAtualizacaoMenuLateral={dataAtualizacaoMenuLateral} />
				</Col>

				<Col xs="12" sm="12" md="12" lg="12" xl="6" style={styleCards}>
					<DocumentosExpiracaoPeriodo
						dataInicial={dataInicial}
						dataFinal={dataFinal}
						isMobile={isMobile}
						dataAtualizacaoMenuLateral={dataAtualizacaoMenuLateral}
					/>
				</Col>
				<Col xs="12" sm="12" md="12" lg="12" xl="6" style={styleCards}>
					<DocumentosAssinadosPeriodo
						dataInicial={dataInicial}
						dataFinal={dataFinal}
						dataAtualizacaoMenuLateral={dataAtualizacaoMenuLateral}
					/>
				</Col>
				<Col xs="12" sm="12" md="12" lg="12" xl="6" style={styleCards}>
					<GraficoDocumentosPorStatusPeriodo
						dataInicial={dataInicial}
						dataFinal={dataFinal}
						dataAtualizacaoMenuLateral={dataAtualizacaoMenuLateral}
					/>
				</Col>
				<Col xs="12" sm="12" md="12" lg="12" xl="12" style={styleCards}>
					<GraficoDocumentosArquivadosPeriodo dataInicial={dataInicial} dataFinal={dataFinal} />
				</Col>
			</Grid>
		);
	} else {
		return <span>Carregando informações...</span>;
	}
}

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
	dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
});

export default connect(mapStateToProps)(withRouter(DashboardDocumentosDigitais));
