import { useEffect, useRef, useState } from 'react';
import { Field, Form, withFormik } from 'formik';
import { format, formatISO, parseISO } from 'date-fns';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';
import { helpDocsRef } from './Help';
import { CAMPO_OBRIGATORIO, CAMPO_OBRIGATORIO_RESUMO } from '../../../Util/constantes';
import { optionsDocumentosReferenciados } from '../../../../../views/fiscal/Util/constantes';
import {
	Modal,
	ButtonCancelar,
	estadosBotaoCancelar,
	ButtonSalvar,
	estadosBotaoSalvar,
	FormActions,
	FormContent,
	Fieldset,
	Grid,
	NenhumRegistroEncontrado,
	Dropdown,
	InputDate,
	Button,
	Col,
	InputMask,
	If,
	InputDouble,
	SingleSelectEstado,
	InputField,
	ButtonAdicionarItem,
} from '../../../../index';
import { inserirMascara } from '../../../../../Common';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

const initialValue = {
	modelo: 'DOCUMENTOS_ELETRONICOS',
	dataEmissao: formatISO(new Date()),
	chaveNFe: null,
	numeroCoo: null,
	caixa: null,
	numeroEcf: null,
	serie: null,
	numeroNF: null,
	cnpjCpf: null,
	uf: null,
	inscricaoEstadual: null,
};

function ModalDocumentosReferenciadosView(props) {
	const {
		onHide,
		visible,
		onChange,
		informacoesPermissoes,
		values,
		setFieldValue,
		resetForm,
		validateForm,
		errors,
		isValid,
		dirty,
		handleReset,
	} = props;
	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;
	const [isTouched, setIsTouched] = useState(false);
	const [disabledAdicionar, setDisabledAdicionar] = useState(false);
	const [documentos, setDocumentos] = useState([]);
	const [initialDocumentos, setInitialDocumentos] = useState([]);

	useEffect(() => {
		setDocumentos(values.listaDocumentos ?? []);
		setInitialDocumentos(values.listaDocumentos ?? []);

		setTimeout(() => {
			document.getElementById('inputChaveAcessoDocumentosReferenciados')?.focus();
		}, 25);
	}, []);

	function deletar(e) {
		setDocumentos(documentos.filter((r) => r !== e));
	}

	function confirmar() {
		onChange(documentos);
		onHide();
	}

	function cancelar() {
		dirty ? handleReset() : onHide();
	}

	async function adicionar() {
		let novoDocumento = [];

		await validateForm();
		if (isValid) {
			novoDocumento = {
				caixa: values.caixa ?? null,
				chaveNFe: values.chaveNFe ?? null,
				cnpjCpf: values.cnpjCpf ?? null,
				dataEmissao: values.dataEmissao ?? null,
				inscricaoEstadual: values.inscricaoEstadual ?? null,
				modelo: values.modelo ?? null,
				numeroCoo: values.numeroCoo ?? null,
				numeroEcf: values.numeroEcf ?? null,
				numeroNF: values.numeroNF ?? null,
				serie: values.serie ?? null,
				uf: values.uf ?? null,
			};
			setDocumentos([...documentos, novoDocumento]);
			resetForm({ initialValue });
			setDisabledAdicionar(true);
		}
	}

	function onChangeModelo(value) {
		if (value === 'DOCUMENTOS_ELETRONICOS') {
			setTimeout(() => {
				setDisabledAdicionar(false);
				document.getElementById('inputChaveAcessoDocumentosReferenciados')?.focus();
			}, 500);
		} else if (value === 'CUPOM_FISCAL') {
			setTimeout(() => {
				setDisabledAdicionar(false);
				document.getElementById('inputNSECFDocumentosReferenciados')?.focus();
			}, 500);
		} else {
			setTimeout(() => {
				setDisabledAdicionar(false);
				document.getElementById('inputUFDocumentosReferenciados')?.getElementsByTagName('input')[0]?.focus();
			}, 500);
		}
	}

	function onChangeField(field, value) {
		setFieldValue(field, value);
		setDisabledAdicionar(false);
	}

	function renderModelo(row) {
		if (row.modelo === 'DOCUMENTOS_ELETRONICOS') {
			return 'NF-e/NFC-e/SAT/CT-e';
		} else if (row.modelo === 'CUPOM_FISCAL') {
			return 'Cupom fiscal';
		} else if (row.modelo === 'NOTA_FISCAL') {
			return 'Nota fiscal';
		} else {
			return 'NF produtor rural';
		}
	}

	function renderEmissao(row) {
		let emissaoFormatada = '';

		if (row.dataEmissao) {
			emissaoFormatada = format(parseISO(row.dataEmissao, new Date()), 'dd/MM/yyyy');
		}

		return emissaoFormatada;
	}

	function renderInformacao(row) {
		if (row.modelo === 'DOCUMENTOS_ELETRONICOS') {
			return inserirMascara(row.chaveNFe, '0000-0000-0000-0000-0000-0000-0000-0000-0000-0000-0000');
		} else if (row.modelo === 'CUPOM_FISCAL') {
			return 'ECF: ' + row.numeroEcf + ' COO: ' + row.numeroCoo + ' Caixa: ' + row.caixa;
		} else if (row.modelo === 'NOTA_FISCAL') {
			return 'CNPJ/CPF: ' + row.cnpjCpf + ' Número: ' + row.numeroNF + ' Série: ' + row.serie;
		} else {
			return 'CNPJ/CPF: ' + row.cnpjCpf + ' Número: ' + row.numeroNF + ' Série: ' + row.serie;
		}
	}

	function renderAcoesField(row) {
		return (
			<div>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={'Excluir documento referenciado'}
					onClick={() => deletar(row)}
					disabled={!informacoesPermissoes?.podeEditar}
				/>
			</div>
		);
	}

	function checkDirty() {
		if (documentos === initialDocumentos) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<>
			<Modal header="Documentos referenciados" visible={visible} onHide={() => onHide()}>
				<Form>
					<FormActions>
						<ButtonCancelar estadoBotao={estadoBotaoCancelar} onClick={cancelar} />
						<ButtonSalvar
							onClick={confirmar}
							estadoBotao={estadosBotaoSalvar.CONFIRMAR}
							hidden={!informacoesPermissoes?.podeEditar}
							disabled={!!values.chaveNFe || checkDirty()}
						/>
					</FormActions>
					<br />
					<FormContent>
						<Grid>
							<Field
								sm="6"
								md="2"
								lg="2"
								xl="2"
								component={Dropdown}
								obrigatorio
								label="Modelo"
								name="modelo"
								showClear={false}
								options={optionsDocumentosReferenciados}
								onChange={(e) => {
									onChangeField('modelo', e.value);
									onChangeModelo(e.value);
								}}
								value={values.modelo}
								touched={isTouched}
								helpMessage={helpDocsRef.modelo}
								{...informacoesPermissoes}
							/>

							<Field
								sm="6"
								md="2"
								lg="2"
								xl="2"
								component={InputDate}
								label="Emissão"
								value={values.dataEmissao}
								onChange={(e) => onChangeField('dataEmissao', e.target.value)}
								name="dataEmissao"
								errors={errors.dataEmissao}
								touched={isTouched}
								obrigatorio
								helpMessage={helpDocsRef.dataEmissao}
								{...informacoesPermissoes}
							/>

							<If test={values.modelo === 'DOCUMENTOS_ELETRONICOS'}>
								<Field
									sm="12"
									md="6"
									lg="6"
									xl="6"
									component={InputMask}
									mask={'0000-0000-0000-0000-0000-0000-0000-0000-0000-0000-0000'}
									label="Chave de acesso"
									id={'inputChaveAcessoDocumentosReferenciados'}
									value={values.chaveNFe}
									name="chaveNFe"
									onChange={(e) => onChangeField('chaveNFe', e.target.value)}
									obrigatorio
									touched={isTouched}
									helpMessage={helpDocsRef.chaveNFe}
									{...informacoesPermissoes}
								/>
							</If>

							<If test={values.modelo === 'CUPOM_FISCAL'}>
								<Field
									sm="6"
									md="3"
									lg="3"
									xl="3"
									component={InputField}
									obrigatorio
									label="Nº/S ECF"
									name="numeroEcf"
									size={20}
									errors={errors.numeroEcf}
									value={values.numeroEcf}
									touched={isTouched}
									id="inputNSECFDocumentosReferenciados"
									onChange={(e) => onChangeField('numeroEcf', e.target.value?.toUpperCase())}
									helpMessage={helpDocsRef.numeroEcf}
									{...informacoesPermissoes}
								/>

								<Field
									sm="6"
									md="2"
									lg="2"
									xl="2"
									component={InputDouble}
									decimalScale={0}
									size={9}
									obrigatorio
									label="COO"
									name="numeroCoo"
									value={values.numeroCoo}
									touched={isTouched}
									onChange={(e) => onChangeField('numeroCoo', e.target.value)}
									helpMessage={helpDocsRef.numeroCoo}
									{...informacoesPermissoes}
								/>

								<Field
									sm="6"
									md="1"
									lg="1"
									xl="1"
									component={InputDouble}
									decimalScale={0}
									size={3}
									obrigatorio
									label="Caixa"
									name="caixa"
									value={values.caixa}
									touched={isTouched}
									onChange={(e) => onChangeField('caixa', e.target.value)}
									helpMessage={helpDocsRef.caixa}
									{...informacoesPermissoes}
								/>
							</If>

							<If test={values.modelo === 'NF_PRODUTOR_RURAL'}>
								<Field
									sm="6"
									md="4"
									lg="4"
									xl="4"
									component={SingleSelectEstado}
									label="UF"
									onChange={(e) => onChangeField('uf', e)}
									name="uf"
									value={values.uf}
									obrigatorio
									id="inputUFDocumentosReferenciados"
									helpMessage={helpDocsRef.uf}
									{...informacoesPermissoes}
								/>

								<Field
									sm="6"
									md="4"
									lg="4"
									xl="4"
									component={InputMask}
									mask={'00000000000000'}
									obrigatorio
									label="CNPJ/CPF"
									name="cnpjCpf"
									touched={isTouched}
									onChange={(e) => onChangeField('cnpjCpf', e.target.value)}
									value={values.cnpjCpf}
									errors={errors.cnpjCpf}
									helpMessage={helpDocsRef.cnpjCpf}
									{...informacoesPermissoes}
								/>

								<Field
									sm="6"
									md="4"
									lg="4"
									xl="4"
									component={InputMask}
									mask={'00000000000000'}
									label="Inscrição estadual"
									name="inscricaoEstadual"
									touched={isTouched}
									onChange={(e) => onChangeField('inscricaoEstadual', e.target.value)}
									value={values.inscricaoEstadual}
									helpMessage={helpDocsRef.inscricaoEstadual}
									{...informacoesPermissoes}
								/>

								<Field
									sm="6"
									md="2"
									lg="2"
									xl="2"
									component={InputDouble}
									decimalScale={0}
									size={9}
									label="Número"
									name="numeroNF"
									obrigatorio
									touched={isTouched}
									value={values.numeroNF}
									onChange={(e) => onChangeField('numeroNF', e.target.value)}
									helpMessage={helpDocsRef.numeroNF}
									{...informacoesPermissoes}
								/>

								<Field
									sm="6"
									md="1"
									lg="1"
									xl="1"
									component={InputDouble}
									decimalScale={0}
									size={3}
									label="Série"
									name="serie"
									touched={isTouched}
									obrigatorio
									value={values.serie}
									onChange={(e) => onChangeField('serie', e.target.value)}
									helpMessage={helpDocsRef.serie}
									{...informacoesPermissoes}
								/>
							</If>

							<If test={values.modelo === 'NOTA_FISCAL'}>
								<Field
									sm="6"
									md="4"
									lg="4"
									xl="4"
									component={SingleSelectEstado}
									label="UF"
									onChange={(e) => onChangeField('uf', e)}
									name="uf"
									value={values.uf}
									obrigatorio
									id="inputUFDocumentosReferenciados"
									helpMessage={helpDocsRef.uf}
									{...informacoesPermissoes}
								/>

								<Field
									sm="6"
									md="4"
									lg="4"
									xl="4"
									component={InputMask}
									mask={'00000000000000'}
									obrigatorio
									label="CNPJ"
									name="cnpjCpf"
									touched={isTouched}
									onChange={(e) => onChangeField('cnpjCpf', e.target.value)}
									value={values.cnpjCpf}
									errors={errors.cnpjCpf}
									helpMessage={helpDocsRef.cnpj}
									{...informacoesPermissoes}
								/>

								<Field
									sm="6"
									md="2"
									lg="2"
									xl="2"
									component={InputDouble}
									decimalScale={0}
									size={9}
									label="Número"
									name="numeroNF"
									obrigatorio
									touched={isTouched}
									value={values.numeroNF}
									onChange={(e) => onChangeField('numeroNF', e.target.value)}
									helpMessage={helpDocsRef.numeroNF}
									{...informacoesPermissoes}
								/>

								<Field
									sm="6"
									md="1"
									lg="1"
									xl="1"
									component={InputDouble}
									decimalScale={0}
									size={3}
									label="Série"
									name="serie"
									touched={isTouched}
									obrigatorio
									value={values.serie}
									onChange={(e) => onChangeField('serie', e.target.value)}
									helpMessage={helpDocsRef.serie}
									{...informacoesPermissoes}
								/>
							</If>

							<Col sm="2" md="2" lg="2" xl="2" style={{ paddingTop: '24px', textAlign: 'center' }}>
								<ButtonAdicionarItem
									value={''}
									label={'Adicionar'}
									title={'Adicionar documento referenciado'}
									onClick={adicionar}
									disabled={disabledAdicionar}
									hidden={!informacoesPermissoes?.podeEditar}
									{...informacoesPermissoes}
								/>
							</Col>
						</Grid>
						<Fieldset legend="Documentos adicionados" className="fieldset">
							<Grid>
								<DataTable
									className="table"
									value={documentos}
									responsive
									style={{ width: '100%' }}
									sortOrder={-1}
									emptyMessage={<NenhumRegistroEncontrado />}
								>
									<Column
										header="Modelo"
										field="modelo"
										body={(row) => renderModelo(row)}
										style={{ color: '#333333', width: '170px' }}
									/>
									<Column
										header="Emissão"
										field="dataEmissao"
										body={(row) => renderEmissao(row)}
										style={{ color: '#333333', width: '150px' }}
									/>
									<Column
										header="Chave acesso/Número NF/Cupom fiscal "
										field="refNFe"
										body={(row) => renderInformacao(row)}
										style={{
											color: '#333333',
											flex: '1 1 0%',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									/>
									<Column
										className="step-listagem-acoes"
										header="Ações"
										body={renderAcoesField}
										style={{ width: '35px' }}
									/>
								</DataTable>
							</Grid>
						</Fieldset>
					</FormContent>
				</Form>
			</Modal>
		</>
	);
}

const ModalDocumentosReferenciados = withFormik({
	enableReinitialize: true,
	validateOnChange: false,

	mapPropsToValues(props) {
		return { ...initialValue, modelo: 'DOCUMENTOS_ELETRONICOS', listaDocumentos: props.NFref };
	},

	validate(values) {
		let errors = {};

		if (!values.dataEmissao || values.dataEmissao.length === 0) {
			errors.dataEmissao = CAMPO_OBRIGATORIO;
		}

		if (values.modelo === 'DOCUMENTOS_ELETRONICOS') {
			if (!values.chaveNFe) {
				errors.chaveNFe = CAMPO_OBRIGATORIO;
			} else if (values.chaveNFe.length < 44) {
				errors.chaveNFe = 'Chave de acesso deve conter 44 dígitos';
			}
		} else if (values.modelo === 'CUPOM_FISCAL') {
			if (!values.numeroEcf) {
				errors.numeroEcf = CAMPO_OBRIGATORIO;
			} else if (values.numeroEcf.length < 20) {
				errors.numeroEcf = 'Nº/S ECF deve conter 20 dígitos';
			}
			if (!values.numeroCoo) {
				errors.numeroCoo = CAMPO_OBRIGATORIO;
			}
			if (!values.caixa) {
				errors.caixa = CAMPO_OBRIGATORIO_RESUMO;
			}
		} else if (values.modelo === 'NF_PRODUTOR_RURAL') {
			if (!values.uf) {
				errors.uf = CAMPO_OBRIGATORIO;
			}
			if (!values.cnpjCpf) {
				errors.cnpjCpf = CAMPO_OBRIGATORIO;
			} else {
				if (values.cnpjCpf.length > 11) {
					if (!isValidCNPJ(values.cnpjCpf)) {
						errors.cnpjCpf = 'Digite um CNPJ válido.';
					}
				} else {
					if (!isValidCPF(values.cnpjCpf)) {
						errors.cnpjCpf = 'Digite um CPF válido.';
					}
				}
			}
			if (!values.numeroNF) {
				errors.numeroNF = CAMPO_OBRIGATORIO;
			}
			if (!values.serie) {
				errors.serie = CAMPO_OBRIGATORIO_RESUMO;
			}
		} else {
			if (!values.uf) {
				errors.uf = CAMPO_OBRIGATORIO;
			}
			if (!values.cnpjCpf) {
				errors.cnpjCpf = CAMPO_OBRIGATORIO;
			} else {
				if (!isValidCNPJ(values.cnpjCpf)) {
					errors.cnpjCpf = 'Digite um CNPJ válido.';
				}
			}
			if (!values.numeroNF) {
				errors.numeroNF = CAMPO_OBRIGATORIO;
			}
			if (!values.serie) {
				errors.serie = CAMPO_OBRIGATORIO_RESUMO;
			}
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalDocumentosReferenciadosView);

export default ModalDocumentosReferenciados;
