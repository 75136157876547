import {
	estadosBotaoCancelar,
	ButtonCancelar,
	ButtonSalvar,
	estadosBotaoSalvar,
	confirm,
} from '../../../../../../../../components';

import { validarFormulario } from '../../../../../../../Util';
import { asyncPostModeloNcm } from '../../Requests';
import { converterModeloNcmParaApi } from '../../Util/converter';

export default function ActionsButtons(props) {
	const { dirty, handleReset, onHide, handleSubmit, tributacoes, values, modelos, setModelos, nome, setFieldValue } =
		props;

	async function cancelar() {
		if (dirty) {
			await handleReset();
		} else {
			onHide();
		}
	}

	async function validarESalvar() {
		handleSubmit();
		if (await validarFormulario(props)) {
			if (JaExisteModeloComMesmoNome(nome, modelos)) {
				confirm('Confirmação', `Já existe um modelo com este nome. Tem certeza que deseja salvar?`, () =>
					salvar()
				);
			} else {
				salvar();
			}
		}
	}

	function JaExisteModeloComMesmoNome(nome, modelos) {
		let existeModelo = false;
		modelos.forEach(modelo => {
			if (modelo.descricao === nome) {
				existeModelo = true;
			}
		});
		return existeModelo;
	}

	async function salvar() {
		let dadosModelo = converterModeloNcmParaApi(values, tributacoes);
		await asyncPostModeloNcm(dadosModelo, ({ data: modelo }) => {
			setModelos([...modelos, modelo]);
			setFieldValue('nome', '');

			onHide();
		});
	}

	return (
		<>
			<ButtonCancelar
				style={{ margin: '5px 5px 5px 0px' }}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
			/>
			<ButtonSalvar
				estadoBotao={estadosBotaoSalvar.SALVAR}
				label="Salvar"
				disabled={!dirty}
				onClick={validarESalvar}
			/>
		</>
	);
}
