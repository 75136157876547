import { Field, useFormikContext } from 'formik';

import { Grid, InputMoney } from 'components';

function Devolucao({ tributo, disabled, informacoesPermissoes }) {
	const { setFieldValue } = useFormikContext();
	return (
		<Grid>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				label="% Devolvido"
				name="tributos.devolvido.percentualIpi"
				component={InputMoney}
				value={tributo?.percentualDevolvido}
				onChange={(event) => setFieldValue('tributos.devolvido.percentualDevolvido', event.target?.value)}
				size={8}
				decimalScale={2}
				prefix=""
				suffix="%"
				maxValue={100}
				placeholder="0,00 %"
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputMoney}
				label="Valor IPI devolvido"
				name="tributos.devolvido.valorIpi"
				value={tributo?.valorIpiDevolvido}
				touched
				allowNegative={false}
				onChange={(event) => setFieldValue('tributos.devolvido.valorIpiDevolvido', event.target?.value)}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { Devolucao };
