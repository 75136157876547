import { Fragment, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { addMonths, formatISO, isValid, parseISO } from 'date-fns';

import { Card, Dropdown } from 'components';
import { gerarUUID } from 'Common';
import { useContextMDFe } from 'views/transporte/MDFe/Context';

import { Parcelas } from './components/Parcelas';

function Prazos({
	canTransmitir,
	isGerarParcelas,
	setIsGerarParcelas,
	indexRecalcularRateioParcela,
	setIndexRecalcularRateioParcela,
	isAPrazo,
	rateioParcelas,
	recalcularParcelas,
	recalcularRateioParcela,
}) {
	const { informacoesPermissoes, isMobile } = useContextMDFe();
	const { values, setFieldValue, dirty, errors } = useFormikContext();

	useEffect(() => {
		if (isGerarParcelas) {
			const parcelasTemp = [];

			const rateio = rateioParcelas(values.quantidadeParcelas, values.valorContrato);

			for (let i = 0; i < values.quantidadeParcelas; i++) {
				const oldParcela = values.prazos && values.prazos[i];
				let valorParcela = rateio.valorPorParcela;

				if (values.quantidadeParcelas - 1 === i) {
					valorParcela = rateio.valorUltimaParcela;
				}

				const idParcela = oldParcela ? oldParcela.id : gerarUUID();
				const vencimento = formatISO(
					isValid(new Date(oldParcela?.vencimento)) ? parseISO(oldParcela.vencimento) : addMonths(new Date(), i + 1)
				);
				parcelasTemp.push({
					id: idParcela,
					numero: i + 1,
					vencimento: vencimento,
					valor: valorParcela,
				});
			}

			setFieldValue('prazos', parcelasTemp);
		}
		setIsGerarParcelas(false);
	}, [isGerarParcelas]);

	useEffect(() => {
		if (dirty && values.valorContrato > 0) {
			if (isAPrazo) {
				let parcelasTemp = recalcularParcelas(values.quantidadeParcelas ?? 1, values.prazos, values.valorContrato);

				parcelasTemp = parcelasTemp.map((parcela, index) => {
					const vencimento = formatISO(
						isValid(new Date(parcela?.vencimento))
							? parseISO(parcela.vencimento)
							: addMonths(parseISO(new Date()), index + 1)
					);
					return {
						...parcela,
						id: parcela.id ? parcela.id : gerarUUID(),
						numero: index + 1,
						vencimento: vencimento,
					};
				});
				setFieldValue('prazos', parcelasTemp);
			} else {
				setFieldValue('prazos', []);
				setFieldValue('quantidadeParcelas', null);
			}
		}
	}, [values.tipoPagamento, values.valorContrato]);

	useEffect(() => {
		if (indexRecalcularRateioParcela !== null) {
			setFieldValue(
				'prazos',
				recalcularRateioParcela(indexRecalcularRateioParcela, values.prazos, values.valorContrato)
			);
		}
		setIndexRecalcularRateioParcela(null);
	}, [indexRecalcularRateioParcela]);

	function getOptionsParcelas(parcelas = 120) {
		const result = [];
		for (let i = 0; i < parcelas; i++) {
			result.push({ label: `${i + 1}x`, value: i + 1 });
		}
		return result;
	}

	function handleChangeQuantidadeParcelas(event) {
		setFieldValue('quantidadeParcelas', event.value);
		setIsGerarParcelas(true);
	}

	return (
		<>
			<Field
				sm="6"
				md="4"
				lg="4"
				xl="4"
				component={Dropdown}
				label="Qtd. parcelas"
				name="quantidadeParcelas"
				onChange={handleChangeQuantidadeParcelas}
				options={getOptionsParcelas()}
				showClear={false}
				obrigatorio
				disabled={!canTransmitir}
				{...informacoesPermissoes}
			/>
			<Card style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: isMobile ? '20.6rem' : '13.8rem' }}>
				{isAPrazo &&
					values.prazos?.map((parcela, index) => (
						<Fragment key={parcela.id}>
							<Parcelas
								index={index}
								parcela={parcela}
								excluirDisabled={values?.prazos.length === 1 || !informacoesPermissoes.podeEditar || !canTransmitir}
								errors={errors?.prazos?.length > 0 ? errors?.prazos[index] : null}
								canTransmitir={canTransmitir}
								setIndexRecalcularRateioParcela={setIndexRecalcularRateioParcela}
							/>
						</Fragment>
					))}
			</Card>
		</>
	);
}

export { Prazos };
