import { useState } from 'react';
import { Fieldset } from 'primereact/fieldset';

import { ButtonNovo, If } from 'components';

import ModalAnexo from '../ModalAnexo';
import ListagemAnexo from './components/ListagemAnexos';
import { statusGestaoDocumento, tipoGestaoDocumento } from '../../../Util/constantes';
import { helpMessage } from '../../Util/constantes';

export default function CardAnexo(props) {
	const {
		anexos,
		informacoesPermissoes,
		idDocumento,
		status,
		setModalAnexoVisible,
		atualizarPagina,
		values,
		setTipoGestaoDocumentoAnexo,
		setIsDocumentoRescindido,
		tipoGestaoDocumentoParaAnexos,
	} = props;

	const [isModalAnexo, setIsModalAnexo] = useState(false);
	const [isNewAnexo, setIsNewAnexo] = useState(false);
	const [idAnexo, setIdAnexo] = useState();
	const [documentoRescindido, setDocumentoRescindido] = useState(null);

	const statusFinalDocumento =
		status === statusGestaoDocumento.VIGENCIA_EXPIRADA || status === statusGestaoDocumento.RESCINDIDO;

	function handleNovoAnexo() {
		setIsNewAnexo(true);
		handleModalAnexoVisibleChange();
	}

	function handleModalAnexoVisibleChange() {
		setIsModalAnexo(true);
		setModalAnexoVisible(true);
		setTipoGestaoDocumentoAnexo(values.tipoGestaoDocumento);
		setIsDocumentoRescindido(values.status === statusGestaoDocumento.RESCINDIDO);
		setDocumentoRescindido(values.status === statusGestaoDocumento.RESCINDIDO);
	}

	function onHide() {
		setIsModalAnexo(false);
		setModalAnexoVisible(false);
		setIdAnexo(null);
		setIsNewAnexo(false);
		setDocumentoRescindido(null);
	}

	return (
		<>
			<Fieldset legend="Anexos do documento" className="fieldset" style={{ marginTop: '0.5rem' }}>
				<ButtonNovo
					label="Novo anexo"
					className="step-aditivo-documento"
					onClick={handleNovoAnexo}
					disabled={
						values.tipoGestaoDocumento === tipoGestaoDocumento.CONTRATO
							? status !== statusGestaoDocumento.VIGENTE
							: ![statusGestaoDocumento.VIGENTE, statusGestaoDocumento.VIGENCIA_EXPIRADA].includes(status)
					}
					titlePersonalizado={
						status === statusGestaoDocumento.VIGENTE ? null : helpMessage.novoAnexoDocumentoNaoVigente
					}
					{...informacoesPermissoes}
				/>
				<ListagemAnexo
					statusDoDocumento={status}
					anexos={anexos}
					informacoesPermissoes={informacoesPermissoes}
					setIdAnexo={setIdAnexo}
					handleModalAnexoVisibleChange={handleModalAnexoVisibleChange}
					idDocumento={idDocumento}
					atualizarPagina={() => atualizarPagina()}
					gestaoDocumentoTipo={values.tipoGestaoDocumento}
				></ListagemAnexo>
			</Fieldset>
			<If test={isModalAnexo}>
				<ModalAnexo
					isModalAnexo={isModalAnexo}
					documentoRescindido={documentoRescindido}
					idAnexo={idAnexo}
					isNewAnexo={isNewAnexo}
					tipoGestaoDocumentoParaAnexos={tipoGestaoDocumentoParaAnexos}
					onHide={onHide}
					idDocumento={idDocumento}
					informacoesPermissoes={informacoesPermissoes}
					statusFinalDocumento={statusFinalDocumento}
				/>
			</If>
		</>
	);
}
