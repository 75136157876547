import { gerarUUID } from 'Common';
import { concatenate, ordenarItens } from './functions';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/CondicaoPagamento/Util/constantes';

function converterNotaParaFormulario(nota) {
	const dadosNota = {
		id: nota.id,
		numero: nota.numero ? nota.numero : null,
		serie: nota.serie ? nota.serie : null,
		modelo: nota.modelo ? concatenate(nota.modelo) : null,
		emitente: {
			id: nota.emitente?.id ? nota.emitente?.id : null,
			codigo: nota.emitente?.pessoa?.codigo ? nota.emitente?.pessoa?.codigo : null,
			cnpj: nota.emitente?.pessoa?.tipo === 'JURIDICA' ? nota.emitente?.pessoa.pessoaJuridica?.cnpj : null,
			cpf: nota.emitente?.pessoa?.tipo === 'FISICA' ? nota.emitente?.pessoa.pessoaFisica?.cpf : null,
			telefone: nota.emitente?.telefone ? nota.emitente?.telefone : null,
			inscricaoEstadual: nota.emitente?.inscricaoEstadual ? nota.emitente?.inscricaoEstadual : null,
			inscricaoMunicipal: nota.emitente?.inscricaoMunicipal ? nota.emitente?.inscricaoMunicipal : null,
			label: nota.emitente?.label ? nota.emitente?.label : montarEmitente(nota.emitente.pessoa),
			complemento: nota.emitente?.complemento ? nota.emitente?.complemento : null,
			logradouro: nota.emitente?.logradouro ? nota.emitente?.logradouro : null,
			municipio: nota.emitente?.municipio ? nota.emitente?.municipio : null,
			bairro: nota.emitente?.bairro ? nota.emitente?.bairro : null,
			cep: nota.emitente?.cep ? nota.emitente?.cep : null,
			numero: nota.emitente?.numero ? nota.emitente?.numero : null,
			nome: nota.emitente?.nome ? nota.emitente?.nome : null,
			nomeFantasia: nota.emitente?.nomeFantasia ? nota.emitente?.nomeFantasia : null,
			pessoa: nota.emitente?.pessoa ? nota.emitente?.pessoa : null,
			value: nota.emitente?.pessoa?.id ?? null,
		},
		dataEmissao: nota.dataEmissao ? nota.dataEmissao : null,
		horaEmissao: nota.horaEmissao ? formatHoraParaFormulario(nota.horaEmissao) : null,
		dataEntrada: nota.dataEntrada ? nota.dataEntrada : null,
		horaEntrada: nota.horaEntrada ? formatHoraParaFormulario(nota.horaEntrada) : null,
		xmlImportado: nota.xmlImportado ? nota.xmlImportado : false,
		situacao: nota.situacao ?? null,
		chaveAcesso: nota.chaveAcesso ? nota.chaveAcesso : null,
		operacaoFiscal: nota.operacaoFiscal ? concatenate(nota.operacaoFiscal) : null,
		itens: ordenarItens(nota.itens),
		cobranca: {
			id: nota.cobranca?.id ?? null,
			numeroFatura: nota.cobranca?.numeroFatura ?? null,
			valorOrigem: nota.cobranca?.valorOrigem ?? null,
			valorDesconto: nota.cobranca?.valorDesconto ?? null,
			valorLiquido: nota.cobranca?.valorLiquido ?? null,
		},
		pagamentos: [
			{
				id: nota.cobranca?.id ?? null,
				tempKey: gerarUUID(),
				idOrigem: null,
				sequencial: nota.cobranca?.sequencial,
				condicaoPagamento: converterCondicaoPagamentoParaFormulario(nota.cobranca?.condicaoPagamento),
				quantidadeParcelas: nota.cobranca?.quantidadeParcelas
					? { value: nota.cobranca?.quantidadeParcelas, label: `${nota.cobranca?.quantidadeParcelas}x` }
					: null,
				categoria: nota.cobranca?.categoria
					? {
							label: nota.cobranca?.categoria.nome ?? null,
							value: nota.cobranca?.categoria.id ?? null,
							registro: { aparencia: nota.cobranca.categoria?.aparencia ?? null },
							...nota.cobranca.categoria,
						}
					: null,
				conta: nota.cobranca?.conta
					? { label: nota.cobranca?.conta.nome, value: nota.cobranca?.conta.id, registro: nota.cobranca.conta }
					: null,
				formaPagamento: nota.cobranca?.formaPagamento
					? {
							label: nota.cobranca?.formaPagamento.descricao,
							value: nota.cobranca?.formaPagamento.id,
							registro: nota.cobranca.formaPagamento,
						}
					: null,
				valor: nota.cobranca?.valor ?? null,
				parcelas:
					nota.cobranca?.parcelas?.length > 0
						? nota.cobranca?.parcelas?.map((parcela) => {
								return {
									tempKey: gerarUUID(),
									id: parcela.id ?? null,
									sequencial: parcela.sequencial,
									numero: parcela.numero ?? null,
									vencimento: parcela.vencimento ?? null,
									valor: parcela.valor ?? null,
									financeiroId: parcela.financeiroId ?? null,
									financeiroIdentificador: parcela.financeiroIdentificador ?? null,
									financeiroStatus: parcela.financeiroStatus ?? null,
									valorRestante: parcela.valorRestante ?? null,
									formaPagamento: parcela.formaPagamento
										? {
												label: parcela.formaPagamento.descricao,
												value: parcela.formaPagamento.id,
												registro: parcela.formaPagamento,
											}
										: null,
									conta: parcela.conta
										? { label: parcela.conta.nome, value: parcela.conta.id, registro: parcela.conta }
										: null,
								};
							})
						: [],
			},
		],
		totais: {
			...nota.totais,
			valorAcessorias: nota.totais.valorOutros ?? null,
		},
		transporte: montarTransporte(nota.transporte),
		xml: nota.xml ? nota.xml : null,
	};
	return dadosNota;
}

function montarTransporte(transporte) {
	let transporteNota = {
		id: transporte?.id ?? null,
		modalidadeFrete: transporte?.modalidadeFrete ?? null,
		transportadora: {
			label: transporte?.transportadora?.transportadora?.codigo
				? transporte?.transportadora?.transportadora?.codigo + ' - ' + transporte?.transportadora?.nome
				: transporte?.transportadora?.nome,
			id: transporte?.transportadora?.id ?? null,
			cnpj: transporte?.transportadora?.cnpj ?? null,
			cpf: transporte?.transportadora?.cpf ?? null,
			nome: transporte?.transportadora?.nome ?? null,
			inscricaoEstadual: transporte?.transportadora?.inscricaoEstadual ?? null,
			endereco: transporte?.transportadora?.endereco ?? null,
			municipio: {
				label: transporte?.transportadora?.municipio?.nome
					? transporte?.transportadora?.municipio?.nome + ' - ' + transporte?.transportadora?.municipio?.estado?.sigla
					: transporte?.transportadora?.municipio?.nome,
				value: transporte?.transportadora?.municipio?.id ?? null,
				id: transporte?.transportadora?.municipio?.id ?? null,
				nome: transporte?.transportadora?.municipio?.nome ?? null,
				codigo: transporte?.transportadora?.municipio?.codigo ?? null,
				estado: {
					label:
						transporte?.transportadora?.municipio?.estado?.nome +
							' - ' +
							transporte?.transportadora?.municipio?.estado?.sigla ?? null,
					value: transporte?.transportadora?.municipio?.estado?.id ?? null,
					id: transporte?.transportadora?.municipio?.estado?.id ?? null,
					nome: transporte?.transportadora?.municipio?.estado?.nome ?? null,
					sigla: transporte?.transportadora?.municipio?.estado?.sigla ?? null,
					codigo: transporte?.transportadora?.municipio?.estado?.codigo ?? null,
					aliquotaInterna: transporte?.transportadora?.municipio?.estado?.aliquotaInterna ?? null,
					pais: {
						label:
							transporte?.transportadora?.municipio?.estado?.pais?.nome +
								' - ' +
								transporte?.transportadora?.municipio?.estado?.pais?.sigla ?? null,
						value: transporte?.transportadora?.municipio?.estado?.pais?.id ?? null,
						id: transporte?.transportadora?.municipio?.estado?.pais?.id ?? null,
						nome: transporte?.transportadora?.municipio?.estado?.pais?.nome ?? null,
						sigla: transporte?.transportadora?.municipio?.estado?.pais?.sigla ?? null,
						codigo: transporte?.transportadora?.municipio?.estado?.pais?.codigo ?? null,
						ddi: transporte?.transportadora?.municipio?.estado?.pais?.ddi ?? null,
					},
				},
			},
		},
		veiculo: {
			id: transporte?.veiculo?.id ?? null,
			placa: transporte?.veiculo?.placa ?? null,
			rntc: transporte?.veiculo?.rntc ?? null,
			estado: transporte?.veiculo?.estado
				? {
						label: transporte?.veiculo?.estado?.nome + ' - ' + transporte?.veiculo?.estado?.sigla ?? null,
						value: transporte?.veiculo?.estado?.id ?? null,
						nome: transporte?.veiculo?.estado?.nome ?? null,
						sigla: transporte?.veiculo?.estado?.sigla ?? null,
						pais: {
							id: transporte?.veiculo?.estado?.pais?.id ?? null,
							nome: transporte?.veiculo?.estado?.pais?.nome ?? null,
							sigla: transporte?.veiculo?.estado?.pais?.sigla ?? null,
						},
					}
				: null,
		},
		volume: {
			id: transporte?.volume?.id ?? null,
			quantidade: transporte?.volume?.quantidade ?? null,
			especie: transporte?.volume?.especie ?? null,
			marca: transporte?.volume?.marca ?? null,
			numeracao: transporte?.volume?.numeracao ?? null,
			pesoLiquido: transporte?.volume?.pesoLiquido ?? null,
			pesoBruto: transporte?.volume?.pesoBruto ?? null,
		},
	};
	return transporteNota;
}

function converterNotaParaApi(nota) {
	const valorNota = {
		id: nota.id,
		numero: nota.numero,
		serie: nota.serie,
		modelo: {
			id: nota.modelo?.id ? nota.modelo?.id : nota.modelo?.value ?? null,
		},
		xmlImportado: nota.xmlImportado ? nota.xmlImportado : false,
		situacao: nota.situacao,
		indicacaoPresenca: nota.indicacaoPresenca ?? null,
		chaveAcesso: nota.chaveAcesso ?? null,
		naturezaOperacao: nota.naturezaOperacao ?? null,
		finalidadeNfe: nota.finalidadeNfe ?? null,
		indicadorConsumidor: nota.indicadorConsumidor ?? null,
		operacaoFiscal: {
			id: nota.operacaoFiscal?.value,
		},
		filial: nota.filial ?? null,
		atualizacaoPrecoAtacado: nota.atualizacaoPrecoAtacado ?? null,
		emitente: {
			id: nota.emitente?.id ?? null,
			cnpj: nota.emitente?.cnpj ?? null,
			cpf: nota.emitente?.cpf ?? null,
			nome: nota.emitente?.nome ?? null,
			nomeFantasia: nota.emitente?.nomeFantasia ?? null,
			inscricaoEstadual: nota.emitente?.inscricaoEstadual ?? null,
			inscricaoMunicipal: nota.emitente?.inscricaoMunicipal ?? null,
			cnae: nota.emitente?.cnae ?? null,
			regimeTributario: nota.emitente?.regimeTributario ?? null,
			telefone: nota.emitente?.telefone ?? null,
			logradouro: nota.emitente?.logradouro ?? null,
			numero: nota.emitente?.numero ?? null,
			complemento: nota.emitente?.complemento ?? null,
			bairro: nota.emitente?.bairro ?? null,
			cep: nota.emitente?.cep ?? null,
			municipio: nota.emitente?.municipio?.id
				? {
						id: nota.emitente?.municipio?.id,
					}
				: nota.emitente?.municipio?.value
					? {
							id: nota.emitente?.municipio?.value,
						}
					: null,
			pessoa: nota.emitente?.pessoa?.id
				? {
						id: nota.emitente?.pessoa?.id,
					}
				: null,
			value: nota.emitente?.pessoa?.id,
		},
		transporte: {
			id: nota.transporte?.id,
			modalidadeFrete: nota.transporte.modalidadeFrete.value
				? nota.transporte.modalidadeFrete.value
				: typeof nota.transporte.modalidadeFrete === 'string'
					? nota.transporte.modalidadeFrete
					: 'SEM_FRETE',
			transportadora: {
				id: nota.transporte.transportadora?.id ?? null,
				cnpj: nota.transporte.transportadora?.cnpj ?? null,
				cpf: nota.transporte.transportadora?.cpf ?? null,
				nome: nota.transporte.transportadora?.nome ?? null,
				inscricaoEstadual: nota.transporte.transportadora?.inscricaoEstadual ?? null,
				endereco: nota.transporte.transportadora?.endereco ?? null,
				municipio: nota.transporte?.transportadora?.municipio?.value
					? {
							id: nota.transporte?.transportadora?.municipio?.value,
						}
					: null,
				transportadora: nota.transporte.transportadora?.transportadora
					? {
							id: nota.transporte.transportadora?.transportadora.id,
						}
					: null,
			},
			veiculo: {
				id: nota.transporte.veiculo?.id ?? null,
				placa: nota.transporte.veiculo?.placa ?? null,
				rntc: nota.transporte.veiculo?.rntc ?? null,
				estado: nota.transporte?.veiculo?.estado?.value
					? {
							id: nota.transporte?.veiculo?.estado?.value,
						}
					: null,
			},
			volume: {
				id: nota.transporte.volume?.id ?? null,
				quantidade: nota.transporte.volume?.quantidade ?? null,
				especie: nota.transporte.volume?.especie ?? null,
				marca: nota.transporte.volume?.marca ?? null,
				numeracao: nota.transporte.volume?.numeracao ?? null,
				pesoBruto: nota.transporte.volume?.pesoBruto ?? null,
				pesoLiquido: nota.transporte.volume?.pesoLiquido ?? null,
			},
		},
		itens: converterModalProdutoParaApi(nota.itens),
		totais: {
			id: nota.totais.id,
			valorBaseCalculo: nota.totais.valorBaseCalculo ?? 0,
			valorIcms: nota.totais.valorIcms ?? 0,
			valorBaseCalculoSt: nota.totais.valorBaseCalculoSt ?? 0,
			valorSt: nota.totais.valorSt ?? 0,
			valorProdutos: nota.totais.valorProdutos ?? 0,
			valorFrete: nota.totais.valorFrete ?? 0,
			valorSeguro: nota.totais.valorSeguro ?? 0,
			valorDesconto: nota.totais.valorDesconto ?? 0,
			valorOutros: nota.totais.valorAcessorias ?? 0,
			valorNfe: nota.totais.valorNfe ?? 0,
			valorTributos: nota.totais.valorTributos ?? 0,
			valorFcpUfDestino: nota.totais.valorFcpUfDestino ?? 0,
			valorIcmsUfDestino: nota.totais.valorIcmsUfDestino ?? 0,
			valorIcmsUfRemetente: nota.totais.valorIcmsUfRemetente ?? 0,
			valorFcp: nota.totais.valorFcp ?? 0,
			valorFcpSt: nota.totais.valorFcpSt ?? 0,
			valorFcpStRetido: nota.totais.valorFcpStRetido ?? 0,
			valorIpiDevolucao: nota.totais.valorIpiDevolucao ?? 0,
			icmsDesonerado: nota.totais.icmsDesonerado ?? 0,
			valorIi: nota.totais.valorIi ?? 0,
			valorIpi: nota.totais.valorIpi ?? 0,
			valorBaseCalculoIpi: nota.totais.valorBaseCalculoIpi ?? 0,
			valorPis: nota.totais.valorPis ?? 0,
			valorBaseCalculoPis: nota.totais.valorBaseCalculoPis ?? 0,
			valorCofins: nota.totais.valorCofins ?? 0,
			valorBaseCalculoCofins: nota.totais.valorBaseCalculoCofins ?? 0,
		},
		cobranca: {
			id: nota.cobranca.id,
			sequencial: 1,
			numeroFatura: nota.numero ?? null,
			valorOrigem: nota.cobranca.valorOrigem ?? 0,
			valorDesconto: nota.cobranca.valorDesconto ?? 0,
			valorLiquido: nota.totais.valorNfe ?? 0,
			condicaoPagamento: nota.pagamentos[0].condicaoPagamento
				? {
						id: nota.pagamentos[0].condicaoPagamento?.value,
					}
				: null,
			quantidadeParcelas: nota.pagamentos[0].quantidadeParcelas ? nota.pagamentos[0].quantidadeParcelas.value : null,
			categoria: nota.pagamentos[0].categoria?.value
				? {
						id: nota.pagamentos[0].categoria?.value,
					}
				: null,
			conta: nota.pagamentos[0].conta?.value
				? {
						id: nota.pagamentos[0].conta?.value,
					}
				: null,
			formaPagamento: nota.pagamentos[0].formaPagamento?.value
				? {
						id: nota.pagamentos[0].formaPagamento?.value,
					}
				: null,
			valor: nota.pagamentos[0].valor,
			parcelas:
				nota.pagamentos[0].parcelas.length > 0 &&
				nota.pagamentos[0].condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO
					? nota.pagamentos[0].parcelas?.map((item) => {
							return {
								id: item.id,
								sequencial: item.sequencial,
								numero: `${nota.numero}/${item.sequencial}`,
								vencimento: item.vencimento ?? null,
								valor: item.valor ?? null,
								status: item.status?.value ?? null,
								valorRestante: item.valorRestante ?? null,
								formaPagamento: item.formaPagamento ? { id: item.formaPagamento?.value } : null,
								conta: item.conta ? { id: item.conta?.value } : null,
							};
						})
					: [],
		},
		dataEmissao: nota.dataEmissao ?? new Date(),
		dataEntrada: nota.dataEntrada,
		horaEmissao: nota.horaEmissao
			? montarHorario(nota.horaEmissao)
			: montarHorario(new Date().toTimeString().split(' ')[0]),
		horaEntrada: montarHorario(nota.horaEntrada),
		xml: nota.xml ? nota.xml : null,
	};

	if (nota.cobranca.condicao === 'SEM_PAGAMENTO') {
		valorNota.cobranca = {
			condicao: 'SEM_PAGAMENTO',
		};
	}

	if (nota.transporte.modalidadeFrete.value === 'SEM_FRETE') {
		valorNota.transporte = {
			modalidadeFrete: 'SEM_FRETE',
		};
	}

	return valorNota;
}

function converterEmitente(nota) {
	let enderecoPessoa = {};
	let numeroTelefone = null;

	nota.pessoa?.enderecos?.map((endereco) => {
		if (endereco.favorito) {
			enderecoPessoa = endereco;
			let concatValue = endereco.municipio.nome + ' - ' + endereco.municipio.estadoSigla;
			enderecoPessoa.municipio = { ...enderecoPessoa.municipio, label: concatValue };
		}
	});

	nota.pessoa?.telefones?.map((telefone) => {
		if (telefone.favorito) {
			numeroTelefone = telefone.numero;
		}
	});

	let emitente = {
		codigo: nota.pessoa?.codigo ? nota.pessoa?.codigo : null,
		id: nota.pessoa?.id ? nota.pessoa?.id : null,
		pessoa: nota.pessoa ? nota.pessoa : null,
		cnpj: nota.pessoa?.tipo === 'JURIDICA' ? nota.pessoa.pessoaJuridica?.cnpj : null,
		cpf: nota.pessoa?.tipo === 'FISICA' ? nota.pessoa.pessoaFisica?.cpf : null,
		telefone: numeroTelefone ? numeroTelefone : null,
		inscricaoEstadual: nota.pessoa?.inscricaoEstadual ? nota.pessoa?.inscricaoEstadual : null,
		inscricaoMunicipal: nota.pessoa?.pessoaJuridica ? nota.pessoa?.pessoaJuridica.inscricaoMunicipal : null,
		label: nota.label ? nota.label : null,
		complemento: enderecoPessoa ? enderecoPessoa.complemento : null,
		logradouro: enderecoPessoa ? enderecoPessoa.logradouro : null,
		municipio: enderecoPessoa ? enderecoPessoa.municipio : null,
		bairro: enderecoPessoa ? enderecoPessoa.bairro : null,
		cep: enderecoPessoa ? enderecoPessoa.cep : null,
		numero: enderecoPessoa ? enderecoPessoa.numero : null,
		nome: nota.pessoa?.nome ? nota.pessoa?.nome : null,
		nomeFantasia: nota.nomeFantasia ? nota.nomeFantasia : nota.pessoa.pessoaJuridica?.razaoSocial ?? null,
		value: nota.pessoa?.id,
	};

	return emitente;
}

function converterModalProdutoParaApi(itens) {
	let itensConvertidos = [];
	itens.map((item, index) => {
		let itemConvertido = {
			id: item.id ? item.id : null,
			item: item.item ? item.item : index + 1,
			produto: item.produto
				? {
						id: item.produto.id,
					}
				: null,
			codigoProduto: item.codigoProduto ? item.codigoProduto : item.produto.codigo,
			descricaoProduto: item.descricaoProduto ? item.descricaoProduto : item.produto.nome,
			ncm: null,
			cfop: item.cfop?.id ? { id: item.cfop?.id } : item.cfop?.value ? { id: item.cfop.value } : null,
			unidadeCompra: item.unidadeCompra?.value
				? {
						id: item.unidadeCompra?.value,
					}
				: item.unidadeCompra?.id
					? {
							id: item.unidadeCompra?.id,
						}
					: null,
			unidadeEstoque: item.unidadeEstoque?.value
				? {
						id: item.unidadeEstoque?.value,
					}
				: item.unidadeEstoque?.id
					? {
							id: item.unidadeEstoque?.id,
						}
					: null,
			fatorConversao: item.fatorConversao ?? 0,
			operacaoFatorConversao: item.operacaoFatorConversao ?? null,
			cest: null,
			quantidade: item.quantidade ?? 0,
			valorUnitario: item.valorUnitario ?? 0,
			valorFrete: item.valorFrete ?? 0,
			valorSeguro: item.valorSeguro ?? 0,
			valorDesconto: item.valorDesconto ?? 0,
			valorAcessorias: item.valorAcessorias ?? 0,
			subTotal: item.subTotal ?? item.valorUnitario * item.quantidade,
			quantidadeConvertida: item.quantidadeConvertida ?? 0,
			indicadorTotal: 'NAO_COMPOEM_TOTAL_NFE',
			pedidoCompra: item.pedidoCompra ?? null,
			itemPedidoCompra: item.itemPedidoCompra ?? null,
			margemLucro: item.margemLucro ?? 0,
			margemLucroAtacado: item.margemLucroAtacado ?? 0,
			codigoEan: item.codigoEan ?? null,
			origem: item.origem ?? null,
			icms: {
				cst: {
					id: item.cst?.id ?? item.icms?.cst?.id,
				},
				baseCalculo: item.baseCalculoIcms ? item.baseCalculoIcms : item.icms?.baseCalculo ?? 0,
				aliquota: item.icmsPorcentagem ? item.icmsPorcentagem : item.icms?.aliquota ?? 0,
				valor: item.valorIcms ? item.valorIcms : item.icms?.valor ?? 0,
				modalidadeBcIcms: item.icms?.modalidadeBcIcms ?? null,
				valorBaseCalculo: item.icms?.valorBaseCalculo ?? 0,
				valorFcp: item.icms?.valorFcp ?? 0,
				aliquotaFcp: item.icms?.aliquotaFcp ?? 0,
				valorBcFcp: item.icms?.valorBcFcp ?? 0,
				modalidadeBcIcmsSt: item.icms?.modalidadeBcIcmsSt ?? null,
				percentualReducaoBcSt: item.icms?.percentualReducaoBcSt ?? 0,
				valorBcSt: item.baseCalculoIcmsSt ? item.baseCalculoIcmsSt : item.icms?.valorBcSt ?? 0,
				aliquotaSt: item.icmsStPorcentagem ? item.icmsStPorcentagem : item.icms?.aliquotaSt ?? 0,
				valorSt: item.valorIcmsSt ? item.valorIcmsSt : item.icms?.valorSt ?? 0,
				ValorBcFcpSt: item.icms?.ValorBcFcpSt ?? 0,
				aliquotaFcpSt: item.icms?.aliquotaFcpSt ?? 0,
				valorFcpSt: item.icms?.valorFcpSt ?? 0,
			},
			ipi: {
				cnpjProdutor: item.ipi?.cnpjProdutor ?? null,
				codigoSeloControle: item.ipi?.codigoSeloControle ?? null,
				quantidadeSeloControle: item.ipi?.quantidadeSeloControle ?? null,
				enquadramento: item.enquadramentoIpi?.id
					? {
							id: item.enquadramentoIpi?.id,
						}
					: {
							id: item.ipi?.enquadramento?.id ?? null,
						},
				cst: item.cstIpi?.id
					? {
							id: item.cstIpi?.id,
						}
					: {
							id: item.ipi?.cst?.id ?? null,
						},
				baseCalculo: item.baseCalculoIpi ? item.baseCalculoIpi : item.ipi?.baseCalculo ?? 0,
				aliquota: item.ipiPorcentagem ? item.ipiPorcentagem : item.ipi?.aliquota ?? 0,
				quantidadeUnidadeMedida: item.ipi?.quantidadeUnidadeMedida ?? 0,
				valorUnidadeMedida: item.ipi?.valorUnidadeMedida ?? 0,
				valor: item.valorIpi ? item.valorIpi : item.ipi?.valor ?? 0,
			},
			pis: {
				cst: item.cstPis?.id
					? {
							id: item.cstPis?.id,
						}
					: {
							id: item.pis?.cst?.id ?? null,
						},
				baseCalculoValor: item.baseCalculoPis ? item.baseCalculoPis : item.pis?.baseCalculoValor ?? 0,
				baseCalculoQuantidade: item.pis?.baseCalculoQuantidade ? item.pis?.baseCalculoQuantidade : 0,
				aliquota: item.pisPorcentagem ? item.pisPorcentagem : item.pis?.aliquota ?? 0,
				valor: item.valorPis ? item.valorPis : item.pis?.valor ?? 0,
				quantidadeVendida: item.pis?.quantidadeVendida ?? 0,
				aliquotaReais: item.pis?.aliquotaReais ?? 0,
				baseCalculoSt: item.baseCalculoPisSt ? item.baseCalculoPisSt : item.pis?.baseCalculoSt ?? 0,
				aliquotaSt: item.pisStPorcentagem ? item.pisStPorcentagem : item.pisStPorcentagem ?? 0,
				quantidadeVendidaSt: item.pis?.quantidadeVendidaSt ?? 0,
				aliquotaReaisSt: item.pis?.aliquotaReaisSt ?? 0,
				valorSt: item.valorPisSt ? item.valorPisSt : item.pis?.valorSt ?? 0,
			},
			cofins: {
				cst: item.cstCofins?.id
					? {
							id: item.cstCofins?.id,
						}
					: {
							id: item.cofins?.cst?.id ?? null,
						},
				aliquota: item.cofinsPorcentagem ? item.cofinsPorcentagem : item.cofins?.aliquota ?? 0,
				valor: item.valorCofins ? item.valorCofins : item.cofins?.valor ?? 0,
				baseCalculoValor: item.baseCalculoCofins ? item.baseCalculoCofins : item.cofins?.baseCalculoValor ?? 0,
				quantidadeVendida: item.cofins?.quantidadeVendida ?? 0,
				aliquotaReais: item.cofins?.aliquotaReais ?? 0,
				baseCalculoSt: item.baseCalculoCofinsSt ? item.baseCalculoCofinsSt : item.cofins?.baseCalculoSt ?? 0,
				aliquotaSt: item.cofinsStPorcentagem ? item.cofinsStPorcentagem : item.cofinsStPorcentagem ?? 0,
				quantidadeSt: item.cofins?.quantidadeSt ?? 0,
				valorSt: item.valorCofinsSt ? item.valorCofinsSt : item.cofins?.valorSt ?? 0,
			},
		};
		itensConvertidos.push(itemConvertido);
	});
	return itensConvertidos;
}

function converterModalProdutoParaForm(nota, modeloNota) {
	const notaForm = {
		id: nota.id ? nota.id : nota.idTemporario ? nota.idTemporario : null,
		item: nota.item ? nota.item : null,
		produto: nota.produto ? concatenate(nota.produto) : null,
		codigoProduto: nota.codigoProduto,
		cfop: nota.cfop ? concatenate(nota.cfop) : null,
		cst: nota.icms?.cst ? concatenate(nota.icms?.cst) : nota.cst ?? null,
		quantidade: nota.quantidade ? nota.quantidade : 0,
		quantidadeConvertida: nota.quantidadeConvertida ? nota.quantidadeConvertida : 0,
		unidadeCompra: nota.unidadeCompra?.registro
			? nota.unidadeCompra
			: nota.unidadeCompra
				? concatenate(nota.unidadeCompra)
				: null,
		unidadeEstoque: nota.unidadeEstoque?.registro
			? nota.unidadeEstoque
			: nota.unidadeEstoque
				? concatenate(nota.unidadeEstoque)
				: null,
		fatorConversao: nota.fatorConversao ? nota.fatorConversao : 0,
		operacaoFatorConversao: nota.operacaoFatorConversao ? nota.operacaoFatorConversao : null,
		valorUnitario: nota.valorUnitario ? nota.valorUnitario : 0,
		valorFrete: nota.valorFrete ? nota.valorFrete : 0,
		valorSeguro: nota.valorSeguro ? nota.valorSeguro : 0,
		valorDesconto: nota.valorDesconto ? nota.valorDesconto : 0,
		valorAcessorias: nota.valorAcessorias ? nota.valorAcessorias : 0,
		subTotal: nota.subTotal ? nota.subTotal : 0,
		codigoEan: nota.codigoEan ? nota.codigoEan : null,
		origem: nota.origem ? nota.origem : null,
		baseCalculoIcms: isBiggerThanZero(nota.icms?.baseCalculo)
			? nota.icms.baseCalculo
			: isBiggerThanZero(nota.baseCalculoIcms)
				? nota.baseCalculoIcms
				: 0,
		icmsPorcentagem: isBiggerThanZero(nota.icms?.aliquota)
			? nota.icms.aliquota
			: isBiggerThanZero(nota.icmsPorcentagem)
				? nota.icmsPorcentagem
				: 0,
		valorIcms: isBiggerThanZero(nota.icms?.valor)
			? nota.icms.valor
			: isBiggerThanZero(nota.valorIcms)
				? nota.valorIcms
				: 0,
		baseCalculoIcmsSt: isBiggerThanZero(nota.icms?.valorBcSt)
			? nota.icms.valorBcSt
			: isBiggerThanZero(nota.baseCalculoIcmsSt)
				? nota.baseCalculoIcmsSt
				: 0,
		icmsStPorcentagem: isBiggerThanZero(nota.icms?.aliquotaSt)
			? nota.icms.aliquotaSt
			: isBiggerThanZero(nota.icmsStPorcentagem)
				? nota.icmsStPorcentagem
				: 0,
		valorIcmsSt: isBiggerThanZero(nota.icms?.valorSt)
			? nota.icms.valorSt
			: isBiggerThanZero(nota.valorIcmsSt)
				? nota.valorIcmsSt
				: 0,
		enquadramentoIpi: nota.ipi?.enquadramento
			? concatenate(nota.ipi.enquadramento)
			: nota.enquadramentoIpi
				? concatenate(nota.enquadramentoIpi)
				: null,
		cstIpi: nota.ipi?.cst ? concatenate(nota.ipi.cst) : nota.cstIpi ? concatenate(nota.cstIpi) : null,
		baseCalculoIpi: isBiggerThanZero(nota.ipi?.baseCalculo)
			? nota.ipi.baseCalculo
			: isBiggerThanZero(nota.baseCalculoIpi)
				? nota.baseCalculoIpi
				: 0,
		ipiPorcentagem: isBiggerThanZero(nota.ipi?.aliquota)
			? nota.ipi.aliquota
			: isBiggerThanZero(nota.ipiPorcentagem)
				? nota.ipiPorcentagem
				: 0,
		valorIpi: isBiggerThanZero(nota.ipi?.valor) ? nota.ipi.valor : isBiggerThanZero(nota.valorIpi) ? nota.valorIpi : 0,
		cstPis: nota.pis?.cst ? concatenate(nota.pis.cst) : nota.cstPis ? concatenate(nota.cstPis) : null,
		pisPorcentagem: isBiggerThanZero(nota.pis?.aliquota)
			? nota.pis.aliquota
			: isBiggerThanZero(nota.pisPorcentagem)
				? nota.pisPorcentagem
				: 0,
		baseCalculoPis: isBiggerThanZero(nota.pis?.baseCalculoValor)
			? nota.pis.baseCalculoValor
			: isBiggerThanZero(nota.baseCalculoPis)
				? nota.baseCalculoPis
				: 0,
		valorPis: isBiggerThanZero(nota.pis?.valor) ? nota.pis.valor : isBiggerThanZero(nota.valorPis) ? nota.valorPis : 0,
		baseCalculoPisSt: isBiggerThanZero(nota.pis?.baseCalculoSt)
			? nota.pis.baseCalculoSt
			: isBiggerThanZero(nota.baseCalculoPisSt)
				? nota.baseCalculoPisSt
				: 0,
		pisStPorcentagem: isBiggerThanZero(nota.pis?.aliquotaSt)
			? nota.pis.aliquotaSt
			: isBiggerThanZero(nota.pisStPorcentagem)
				? nota.pisStPorcentagem
				: 0,
		valorPisSt: isBiggerThanZero(nota.pis?.valorSt)
			? nota.pis.valorSt
			: isBiggerThanZero(nota.valorPisSt)
				? nota.valorPisSt
				: 0,
		cstCofins: nota.cofins?.cst ? concatenate(nota.cofins.cst) : nota.cstCofins ? concatenate(nota.cstCofins) : null,
		baseCalculoCofins: isBiggerThanZero(nota.cofins?.baseCalculoValor)
			? nota.cofins.baseCalculoValor
			: isBiggerThanZero(nota.baseCalculoCofins)
				? nota.baseCalculoCofins
				: 0,
		cofinsPorcentagem: isBiggerThanZero(nota.cofins?.aliquota)
			? nota.cofins.aliquota
			: isBiggerThanZero(nota.cofinsPorcentagem)
				? nota.cofinsPorcentagem
				: 0,
		valorCofins: isBiggerThanZero(nota.cofins?.valor)
			? nota.cofins.valor
			: isBiggerThanZero(nota.valorCofins)
				? nota.valorCofins
				: 0,
		baseCalculoCofinsSt: isBiggerThanZero(nota.cofins?.baseCalculoSt)
			? nota.cofins.baseCalculoSt
			: isBiggerThanZero(nota.baseCalculoCofinsSt)
				? nota.baseCalculoCofinsSt
				: 0,
		cofinsStPorcentagem: isBiggerThanZero(nota.cofins?.aliquotaSt)
			? nota.cofins.aliquotaSt
			: isBiggerThanZero(nota.cofinsStPorcentagem)
				? nota.cofinsStPorcentagem
				: 0,
		valorCofinsSt: isBiggerThanZero(nota.cofins?.valorSt)
			? nota.cofins.valorSt
			: isBiggerThanZero(nota.valorCofinsSt)
				? nota.valorCofinsSt
				: 0,

		modeloNota: modeloNota,
	};
	return notaForm;
}

function isBiggerThanZero(value) {
	return value > 0;
}

function montarEmitente(value) {
	let labelEmitente = value.codigo + ' - ' + value.nome;
	return labelEmitente;
}

function montarHorario(value) {
	if (value?.length <= 5) {
		return value.concat(':00');
	}
	return value;
}

function formatHoraParaFormulario(hora) {
	return hora.replace(/^(\d{2}:\d{2}).*/, '$1');
}

function converterCondicaoPagamentoParaFormulario(condicaoPagamento) {
	if (condicaoPagamento) {
		return {
			label: condicaoPagamento.descricao,
			value: condicaoPagamento.id,
			registro: condicaoPagamento,
		};
	}
	return null;
}

function converterValueParaSelect(value, label) {
	return value
		? {
				value: value.id ?? null,
				label: label ?? value.nome ?? value.descricao,
				registro: value,
			}
		: null;
}

export {
	converterNotaParaFormulario,
	converterNotaParaApi,
	converterModalProdutoParaApi,
	converterModalProdutoParaForm,
	converterEmitente,
	formatHoraParaFormulario,
	converterValueParaSelect,
};
