import { get, services } from 'Common';
import { formatISO, isDate, isValid } from 'date-fns';

export function readResultadoPeriodo(dataInicial, dataFinal, onSuccess, onError) {
	let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
		representation: 'date',
	});
	let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
		representation: 'date',
	});

	if (isDate(dataInicial) && isValid(dataInicial)) {
		formatedInitialDate = formatISO(dataInicial, { representation: 'date' });
	}
	if (isDate(dataFinal) && isValid(dataFinal)) {
		formatedFinalDate = formatISO(dataFinal, { representation: 'date' });
	}
	return get(
		`${services.GESTOR}/v1/dashboards/transporte/mdfe/resultado_periodo_vs_periodo_anterior?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}`,
		null,
		onSuccess,
		onError,
		false
	);
}

export function readRankingMotoristas(dataInicial, dataFinal, page, onSuccess, onError) {
	let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
		representation: 'date',
	});
	let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
		representation: 'date',
	});

	if (isDate(dataInicial) && isValid(dataInicial)) {
		formatedInitialDate = formatISO(dataInicial, { representation: 'date' });
	}
	if (isDate(dataFinal) && isValid(dataFinal)) {
		formatedFinalDate = formatISO(dataFinal, { representation: 'date' });
	}
	return get(
		`${services.GESTOR}/v1/dashboards/transporte/mdfe/ranking_motorista?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}&page=${page}&sort=transportesRealizados,desc&size=10`,
		null,
		onSuccess,
		onError,
		false
	);
}

export function readRankingVeiculos(dataInicial, dataFinal, page, onSuccess, onError) {
	let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
		representation: 'date',
	});
	let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
		representation: 'date',
	});

	if (isDate(dataInicial) && isValid(dataInicial)) {
		formatedInitialDate = formatISO(dataInicial, { representation: 'date' });
	}
	if (isDate(dataFinal) && isValid(dataFinal)) {
		formatedFinalDate = formatISO(dataFinal, { representation: 'date' });
	}
	return get(
		`${services.GESTOR}/v1/dashboards/transporte/mdfe/ranking_veiculo?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}&page=${page}&sort=transportesRealizados,desc&size=10`,
		null,
		onSuccess,
		onError,
		false
	);
}

export function readRankingEstados(dataInicial, dataFinal, page, onSuccess, onError) {
	let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
		representation: 'date',
	});
	let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
		representation: 'date',
	});

	if (isDate(dataInicial) && isValid(dataInicial)) {
		formatedInitialDate = formatISO(dataInicial, { representation: 'date' });
	}
	if (isDate(dataFinal) && isValid(dataFinal)) {
		formatedFinalDate = formatISO(dataFinal, { representation: 'date' });
	}
	return get(
		`${services.GESTOR}/v1/dashboards/transporte/mdfe/ranking_estado?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}&page=${page}&sort=transportesRealizados,desc&size=10`,
		null,
		onSuccess,
		onError,
		false
	);
}

export function readDocumentosPorDia(dataInicial, dataFinal, onSuccess, onError) {
	let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
		representation: 'date',
	});
	let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
		representation: 'date',
	});

	if (isDate(dataInicial) && isValid(dataInicial)) {
		formatedInitialDate = formatISO(dataInicial, { representation: 'date' });
	}
	if (isDate(dataFinal) && isValid(dataFinal)) {
		formatedFinalDate = formatISO(dataFinal, { representation: 'date' });
	}
	return get(
		`${services.GESTOR}/v1/dashboards/transporte/mdfe/documentos_por_dia?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}`,
		null,
		onSuccess,
		onError,
		false
	);
}

export function readDocumentosUltimosDozeMeses(onSuccess, onError) {
	return get(`${services.GESTOR}/v1/dashboards/transporte/mdfe/ultimos_doze_meses`, null, onSuccess, onError, false);
}
