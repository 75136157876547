import { useFormikContext } from 'formik';

import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar, notify, ToastTypes } from 'components';

import { devolverProdutosDoPedidoDeVenda } from 'views/fiscal/vendas/PedidoVendasOrcamentos/Requests';
import { validarFormulario } from 'views/Util';
import { converterProdutosDevolucaoParaApi } from 'views/fiscal/vendas/PedidoVendasOrcamentos/Form/Util/PedidovendasOrcamentosConverter';

function ActionButtons({ vendaId, informacoesPermissoes, onHide }) {
	const { dirty, handleReset, validateForm, values } = useFormikContext();

	function cancelar() {
		if (dirty) {
			handleReset();
		} else {
			onHide();
		}
	}

	async function devolverProdutos() {
		const produtosDevolvidos = values?.produtos?.filter((produto) => produto?.quantidade > 0);

		if (
			await validarFormulario({
				validateForm,
				values: { ...values, produtos: produtosDevolvidos },
			})
		) {
			devolverProdutosDoPedidoDeVenda(
				vendaId,
				converterProdutosDevolucaoParaApi(produtosDevolvidos),
				() => {
					notify('Devolução do pedido de venda realizada com sucesso', ToastTypes.SUCCESS);
					onHide();
				},
				({ response }) => {
					const erro = response && response?.data?.details?.length > 0 ? response?.data?.details[0] : null;

					notify(erro || `Ocorreu um erro na devolução do pedido de venda`, ToastTypes.ERROR);
				}
			);
		}
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar onClick={devolverProdutos} {...informacoesPermissoes} />
		</>
	);
}

export { ActionButtons };
