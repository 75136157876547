import { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { GoBookmark } from 'react-icons/go';

import { estadosCadastro, removerElemento, copiarObjeto, formatarTelefone } from '../../../../../../../Common';

import {
  ButtonNovo,
  Modal,
  ButtonExcluirTable,
  If,
  ButtonEditarTable,
  NenhumRegistroEncontrado,
  Grid,
} from '../../../../../../../components';

import PessoaTelefoneForm from './Form';
import { jaExisteMesmoTelefone } from './Util/validacoes';

function PessoaTelefone(props) {
  const { estadoCadastro, appendTo } = props;

  const [registroSelecionado, setRegistroSelecionado] = useState(null);
  const [modalFormVisible, setModalFormVisible] = useState(false);

  const desabilitarCampos = estadoCadastro === estadosCadastro.EDICAO && !props.podeEditar;

  function novo() {
    if (props.setHideBackground) {
      props.setHideBackground(true);
    }
    setRegistroSelecionado(null);
    setModalFormVisible(true);
  }

  function onEditar(row) {
    if (props.setHideBackground) {
      props.setHideBackground(true);
    }
    setRegistroSelecionado(row);
    setModalFormVisible(true);
  }

  function renderOpcoes(row) {
    return (
      <div>
        <ButtonEditarTable onClick={() => onEditar(row)} />
        <ButtonExcluirTable onClick={() => excluirRegistro(row)} podeExcluir={!desabilitarCampos} />
      </div>
    );
  }

  function hideModal() {
    if (props.setHideBackground) {
      props.setHideBackground(false);
    }
    setRegistroSelecionado(null);
    setModalFormVisible(false);
  }

  function atualizarRegistro(dadosFormulario) {
    const telefones = copiarObjeto(props.value);

    const result =
      telefones &&
      telefones.map(item => {
        if (item.id || item.idTemporario) {
          if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
            return dadosFormulario;
          } else if (item.id && item.id === dadosFormulario.id) {
            return dadosFormulario;
          }

          if (dadosFormulario.favorito) {
            item.favorito = false;
          }

          return item;
        } else {
          return dadosFormulario;
        }
      });

    setRegistroSelecionado(dadosFormulario);
    props.onChange(result);
  }

  function inserirRegistro(dadosFormulario) {
    let telefones = copiarObjeto(props.value);
    props.onChange(mergeTelefoneIntoTelefones(dadosFormulario, telefones));
    setRegistroSelecionado(dadosFormulario);
  }

  function mergeTelefoneIntoTelefones(telefone, telefones) {
    if (telefone.favorito) {
      telefones.map(tempTelefone => {
        return (tempTelefone.favorito = false);
      });
    }
    return [...telefones, telefone];
  }

  function excluirRegistro(dadosFormulario) {
    props.onChange(removerElemento(props.value, dadosFormulario));
    setRegistroSelecionado(null);
  }

  function validarMesmoTelefone(telefone) {
    return jaExisteMesmoTelefone(props.value, telefone);
  }

  function marcarComoFavorito() {
    const telefones = copiarObjeto(props.value);
    let existemFavoritos = false;

    telefones?.forEach(telefone => {
      if (telefone.favorito) {
        existemFavoritos = true;
      }
    });

    return !existemFavoritos;
  }

  function renderNumero(row) {
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {formatarTelefone(row.numero)}
        {row.favorito ? <GoBookmark size={22} color="#fbc02d" title="Número favorito" /> : null}
      </span>
    );
  }

  return (
    <>
      <Modal visible={modalFormVisible} header="Cadastro de telefone" onHide={hideModal} container={appendTo}>
        <If test={modalFormVisible}>
          <PessoaTelefoneForm
            registroSelecionado={registroSelecionado}
            onNovoClick={() => setRegistroSelecionado(null)}
            excluirRegistro={excluirRegistro}
            inserirRegistro={inserirRegistro}
            atualizarRegistro={atualizarRegistro}
            marcarComoFavorito={marcarComoFavorito}
            podeEditar={!desabilitarCampos}
            podeExcluir={!desabilitarCampos}
            onHide={hideModal}
            validarMesmoTelefone={validarMesmoTelefone}
            appendTo={appendTo}
          />
        </If>
      </Modal>
      <Grid style={{ padding: '0.5rem 0rem' }}>
        <ButtonNovo label="Novo telefone" enableShortCut={false} onClick={novo} podeInserir={!desabilitarCampos} />
      </Grid>
      <DataTable
        className="table"
        responsive
        value={props.value}
        emptyMessage={<NenhumRegistroEncontrado message="Nenhum telefone encontrado" />}
      >
        <Column field="numero" body={renderNumero} header="Número" sortable={true} />
        <Column field="operadora" header="Operadora" sortable={true} />
        <Column body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
      </DataTable>
    </>
  );
}

export default PessoaTelefone;
