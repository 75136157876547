import { get, del, put, post, exibirToast } from '../../../../../../Common/Requisicoes';
import { services } from '../../../../../../Common/Constantes/api';

export async function asyncGetRegistro(idCategoria, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/categorias/${idCategoria}`, null, onSuccess, onError);
}

export async function asyncDeleteRegistro(idCategoria, onSuccess, onError) {
	await del(`${services.GESTOR}/v1/categorias/${idCategoria}`, null, exibirToast(onSuccess, 'Categoria removida com sucesso'), onError);
}

export async function asyncUpdateRegistro(data, onSuccess, onError) {
	await put(`${services.GESTOR}/v1/categorias/${data.id}`, data, null, exibirToast(onSuccess, 'Categoria atualizada com sucesso'), onError);
}

export async function asyncCreateRegistro(data, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/categorias`, data, null, exibirToast(onSuccess, 'Categoria criada com sucesso'), onError);
}

export async function asyncGetCategoriaMesmoNome(nome, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/categorias/relacoes/categorias_superiores?query=nome=="${nome}"`, null, onSuccess, onError);
}
