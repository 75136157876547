import Grid from '../../../../../../components/Grid';
import propTypes from 'prop-types';
import FeedbackResumoAssinaturas from '../FeedbackResumoAssinaturas';
import Box from '../../../../../../components/Box';

const styleGrid = {
	borderRadius: '10px',
	padding: '15px',
	margin: '5px 15px 5px 15px',
	textAlign: 'center',
	flexDirection: 'column',
};

const verDetalhesStyle = {
	marginTop: '10px',
	fontWeight: 'bold',
	cursor: 'pointer',
};

function ExibirFeedbackCliente(props) {
	const { status, signatarios, onVerDetalhesClick } = props;

	return (
		<Grid style={{ height: '100%' }} verticalAlignCenter justifyCenter>
			<Box stretched style={{ width: '100%' }}>
				<Grid style={styleGrid}>
					<FeedbackResumoAssinaturas signatarios={signatarios} status={status} />
					{/* eslint-disable-next-line */}
					<a style={verDetalhesStyle} onClick={onVerDetalhesClick}>
						Ver detalhes
					</a>
				</Grid>
			</Box>
		</Grid>
	);
}

ExibirFeedbackCliente.propTypes = {
	onVerDetalhesClick: propTypes.func,
	status: propTypes.string,
	assinaturas: propTypes.array,
};

export default ExibirFeedbackCliente;
