import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';

export const SITACAO_OPTIONS = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];

export const OPTIONS_FILTRO_AVANCADO = [
	{ label: 'Nome', name: 'nome', type: tipoCampos.STRING },
	{ label: 'CNPJ', name: 'cnpj', type: tipoCampos.STRING },
	{ label: 'Apólice', name: 'apolice', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: SITACAO_OPTIONS,
	},
];

export const COLORS = {
	bgAtivo: '#DCEDC8',
	textAtivo: '#1B5E20',
	bgInativo: '#FFCDD2',
	textInativo: '#B71C1C',
};

export const STYLE_BACKGROUND = {
	borderRadius: '20px',
	padding: '0.2rem 1.1rem',
};

export const INITIAL_VALUES = {
	id: '',
	cnpj: '',
	nome: '',
	apolice: '',
	situacao: 'ATIVO',
};

export const CADASTRO_URL = '/transporte/seguradora/cadastro';
export const PESQUISA_URL = '/transporte/seguradora';
