import { keyFilterConsultaRsql } from 'Common';
import { Col, Divider, Grid, If, InputField, SingleSelectPessoa } from 'components';
import { Field, useFormikContext } from 'formik';
import { TabsVeiculos } from '../TabsVeiculos';

function FormProprietario({ informacoesPermissoes }) {
	const { values, setFieldValue } = useFormikContext();

	const notHaveProprietario = !values.proprietario;

	return (
		<Grid>
			<Divider
				label={
					<span>
						Proprietário{' '}
						<span style={{ fontSize: '12px', fontWeight: 'normal' }}>(Apenas ao transporte de carga de terceiro)</span>
					</span>
				}
				styleContainer={{ margin: '8px' }}
				styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
			/>
			<Col style={{ display: 'flex', margin: 0, padding: 0 }} className="step-veiculo-proprietario">
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={SingleSelectPessoa}
					label="Proprietário"
					name="proprietario"
					value={values.proprietario}
					onChange={(event) => setFieldValue('proprietario', event)}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputField}
					label="RNTRC"
					keyfilter={keyFilterConsultaRsql}
					name="rntrc"
					size={8}
					disabled={notHaveProprietario}
					title={notHaveProprietario ? 'Informe um proprietário para habilitar o campo' : ''}
					{...informacoesPermissoes}
				/>
			</Col>
			<If test={values.tipo !== 'REBOQUE'}>
				<TabsVeiculos informacoesPermissoes={informacoesPermissoes} />
			</If>
		</Grid>
	);
}

export { FormProprietario };
