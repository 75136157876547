import { useEffect } from 'react';
import { useFormikContext, withFormik } from 'formik';
import { TabPanel } from 'primereact/tabview';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useLastLocation } from 'react-router-last-location';
import * as Yup from 'yup';

import { converterNotaParaFormulario, converterValueParaSelect } from '../Util/notaConverter';

import {
	usuarioPossuiPermissao,
	permissoes,
	recursos,
	validarUUID,
	gerarUUID,
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	salvarConfiguracaoUsuario,
	copiarObjeto,
	parseFloatNumber,
} from '../../../../Common';
import useEffectDebugger from '../../../../Common/Hooks/useEffectDebugger';

import {
	Badge,
	Col,
	Form,
	FormActions,
	FormContent,
	Grid,
	Prompt,
	TabView,
	If,
	Message,
	notify,
	alert,
	Tutorial,
	tutorialStepsNotaEntrada,
	confirm,
	ToastTypes,
	useGenerateParcelas,
	useRecalculatePagamentos,
	validatePagamentos,
	ButtonAdicionarItem,
} from '../../../../components';

import {
	asyncGetNotaEntrada,
	asyncCadastrarPessoa,
	readOperacaoFiscalFavorita,
	asyncGetXmlNfe,
	readCondicaoPagamentoSemPagamento,
	asyncGetFormaPagamentoDinheiro,
	buscarContaFavoritaDespesa,
	buscarCategoriaFavoritaDespesa,
} from '../Requests/index';

import { CLASSNAME_TABVIEW_COLOR, ColorsSituacao, MessagesErrors } from '../Util/constantes';
import { atualizarUrl, metodosAtualizarUrl, voltarParaAPesquisa } from '../../../Util';
import converteXmlParaFormulario from '../Util/xmlConverter';
import { Messages } from '../Util/messages';
import { FieldsPagamentosForm } from './components/FieldsPagamentos';
import FieldsTotalizadores from './components/FieldsTotalizadores';
import ModalAtualizacaoPrecos from './components/ModalAtualizacaoPrecos';
import ModalVinculoFornecedor from './components/ModalVinculoFornecedor';
import ModalDadosFornecedor from './components/ModalDadosFornecedor';
import ModalProdutos from './components/ModalProdutos';
import FieldsTransporte from './components/FieldsTransporte';
import ModalNotaEntradaHistorico from './components/ModalNotaEntradaHistorico';
import ActionButtons from './components/ActionsButtons';
import TabelaItens from './components/TabelaItens';
import CabecalhoNotaEntrada from './components/CabecalhoNotaEntrada';

import './Styles/index.css';
import { format } from 'date-fns';
import { ReplaceAt } from '../../../../Common/Funcoes';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/CondicaoPagamento/Util/constantes';

const initialValues = {
	id: null,
	numero: null,
	serie: null,
	modelo: {
		label: '55 - Nota Fiscal Eletrônica (NF-e)',
		value: 'a4067392-505f-47e4-81c0-2f89c55484c9',
	},
	situacao: 'PENDENTE',
	chaveAcesso: null,
	operacaoFiscal: null,
	emitente: null,
	transporte: {
		modalidadeFrete: {
			label: 'Sem frete',
			value: 'SEM_FRETE',
		},
		transportadora: {
			cnpj: null,
			cpf: null,
			nome: null,
			inscricaoEstadual: null,
			endereco: null,
			municipio: null,
			transportadora: null,
		},
		veiculo: {
			placa: null,
			rntc: null,
			estado: null,
		},
		volume: {
			quantidade: null,
			especie: null,
			marca: null,
			numeracao: null,
			pesoLiquido: null,
			pesoBruto: null,
		},
	},
	itens: [],
	totais: {
		valorBaseCalculo: 0,
		valorIcms: 0,
		valorBaseCalculoSt: 0,
		valorSt: 0,
		valorBaseCalculoIpi: 0,
		valorIpi: 0,
		valorBaseCalculoPis: 0,
		valorPis: 0,
		valorBaseCalculoCofins: 0,
		valorCofins: 0,
		valorProdutos: 0,
		valorFrete: 0,
		valorSeguro: 0,
		valorDesconto: 0,
		valorAcessorias: 0,
		valorNfe: 0,
		valorTributos: 0,
		valorFcpUfDestino: 0,
		valorIcmsUfDestino: 0,
		valorIcmsUfRemetente: 0,
		valorFcp: 0,
		valorFcpSt: 0,
		valorFcpStRetido: 0,
		valorIpiDevolucao: 0,
		icmsDesonerado: 0,
		valorIi: 0,
		valorIpi: 0,
		valorPis: 0,
		valorCofins: 0,
	},
	cobranca: {
		numeroFatura: null,
		valorOrigem: 0,
		valorDesconto: 0,
		valorLiquido: 0,
	},
	pagamentos: [
		{
			id: null,
			idOrigem: null,
			tempKey: gerarUUID(),
			sequencial: 1,
			condicaoPagamento: null,
			conta: null,
			formaPagamento: null,
			categoria: null,
			quantidadeParcelas: 0,
			valor: 0,
			valorRecebido: 0,
			parcelas: [],
		},
	],
	dataEmissao: null,
	horaEmissao: null,
	dataEntrada: format(new Date(), 'yyyy-MM-dd'),
	horaEntrada: format(new Date(), 'HH:mm'),
};

const buttonAdicionarStyle = {
	background: 'none',
	border: 'none',
	fontWeight: 'bold',
	boxShadow: 'none',
	marginTop: '0.7rem',
	paddingLeft: '0.7rem',
};

function NotasEntradaFormView(props) {
	const { history, isMobile } = props;
	const { values, dirty, setFieldValue, resetForm, submitCount, errors, setValues, handleReset } = useFormikContext();

	const lastLocation = useLastLocation();

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.EXCLUIR));
	const [sortOrder] = useState(1);
	const [sortField] = useState('item');
	const [isFinalizado, setIsFinalizado] = useState(false);
	const [modalProdutos, setModalProdutos] = useState(false);
	const [modalVinculoFornecedor, setModalVinculoFornecedor] = useState(false);
	const [modalAtualizacaoPrecos, setModalAtualizacaoPrecos] = useState(false);
	const [modalDadosFornecedor, setModalDadosFornecedor] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState('');
	const [isUserInput, setIsUserInput] = useState(false);
	const [modalHistorico, setModalHistorico] = useState(false);
	const [recarregarParcelas, setRecarregarParcelas] = useState(false);
	const [tabActivePagamentos, setTabActivePagamentos] = useState(0);
	const [errosPagamento, setErrosPagamento] = useState(false);
	const [errosItens, setErrosItens] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);
	const [xml, setXml] = useState(null);
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_NOTA_ENTRADA));
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [validaTransportador, setValidaTransportador] = useState(false);
	const [favoritosPagamento, setFavoritosPagamento] = useState({
		conta: null,
		formaPagamento: null,
		condicaoPagamento: null,
		categoria: null,
	});
	const [desabilitarRecalcularPagamento, setDesabilitarRecalcularPagamento] = useState(false);

	const [totalizadores, setTotalizadores] = useState({
		baseCalculoIcms: 0,
		valorIcms: 0,
		baseCalculoIcmsSt: 0,
		valorIcmsSt: 0,
		baseCalculoIpi: 0,
		valorIpi: 0,
		baseCalculoPis: 0,
		valorPis: 0,
		baseCalculoCofins: 0,
		valorCofins: 0,
		totalProdutos: 0,
		totalImpostos: 0,
		totalNota: 0,
	});

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	const [generateParcelas] = useGenerateParcelas({
		valorPagamento: totalizadores.totalNota,
		dataBaseParcela: values.dataEntrada,
		favoritos: favoritosPagamento,
	});

	useRecalculatePagamentos(
		{
			valorTotal: totalizadores.totalNota,
			dataBaseParcela: values.dataEntrada,
			favoritos: favoritosPagamento,
			desabilitarRecalculoParcelas: desabilitarRecalcularPagamento,
		},
		[totalizadores.totalNota]
	);

	const comparissonEffect = useEffectDebugger(() => {}, [
		values.itens,
		values.totais.valorAcessorias,
		values.totais.valorDesconto,
		values.totais.valorFrete,
		values.totais.valorSeguro,
	]);

	useEffectOnce(() => {
		if (props.match.path === '/nota_entrada/importar_xml' && deveExibirTutorial === false) {
			onImportarXml();
		}
		if (props.match.path === '/nota_entrada/importar_xml_mde/:id' && deveExibirTutorial === false) {
			onImportarXmlMde(props.match.params.id);
		}
	});

	useEffectOnce(async () => {
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_NOTA_ENTRADA, false, null, false);
		}

		const id = !props.match.path.includes('/nota_entrada/importar_xml_mde/') ? props.match.params.id : null;

		if (validarUUID(id)) {
			asyncSelectNotaEntrada(id);
		} else {
			await novo();
		}

		setTimeout(() => {
			document.getElementById('Numero')?.focus();
		}, 500);
	});

	useUpdateEffect(() => {
		if (isUserInput) {
			setIsUserInput(false);
		}
	}, [values.totais.acessorias, values.totais.desconto, values.totais.frete, values.totais.seguro]);

	useUpdateEffect(() => {
		ajustarItens();
		if (isUserInput) {
			calcularCustos();
			setIsUserInput(false);
		}
		if (values.itens?.length > 0) {
			setErrosItens(false);
		}
	}, [values.itens]);

	useEffect(() => {
		if (validaTransportador) {
			if (values.transporte?.modalidadeFrete !== 'SEM_FRETE') {
				if (!values.transporte?.transportadora?.transportadora) {
					alert('Aviso', Messages.TransportadorNaoCadastrado);
				}
			}
			setValidaTransportador(false);
		}
	}, [validaTransportador]);

	useEffectOnce(() => {
		calcularValor();
	});

	useUpdateEffect(() => {
		calcularValor();
	}, [values.itens, values.totais]);

	function calcularValor() {
		let valores = {
			baseCalculoIcms: 0,
			valorIcms: 0,
			baseCalculoIcmsSt: 0,
			valorIcmsSt: 0,
			baseCalculoIpi: 0,
			valorIpi: 0,
			baseCalculoPis: 0,
			valorPis: 0,
			baseCalculoCofins: 0,
			valorCofins: 0,
			unitario: 0,
			quantidade: 0,
			quantidadeConvertida: 0,
			frete: 0,
			seguro: 0,
			acessorias: 0,
			desconto: 0,
			totalProdutos: 0,
			totalImpostos: 0,
			total: 0,
		};

		values.itens?.forEach((produto) => {
			valores.baseCalculoIcms += produto.baseCalculoIcms ? produto.baseCalculoIcms : produto.icms?.baseCalculo ?? 0;
			valores.valorIcms += produto.valorIcms ? produto.valorIcms : produto.icms?.valor ?? 0;
			valores.baseCalculoIcmsSt += produto.baseCalculoIcmsSt ? produto.baseCalculoIcmsSt : produto.icms?.valorBcSt ?? 0;
			valores.valorIcmsSt += produto.valorIcmsSt ? produto.valorIcmsSt : produto.icms?.valorSt ?? 0;
			valores.baseCalculoIpi += produto.baseCalculoIpi ? produto.baseCalculoIpi : produto.ipi?.baseCalculo ?? 0;
			valores.valorIpi += produto.valorIpi ? produto.valorIpi : produto.ipi?.valor ?? 0;
			valores.baseCalculoPis += produto.baseCalculoPis ? produto.baseCalculoPis : produto.pis?.baseCalculoValor ?? 0;
			valores.valorPis += produto.valorPis ? produto.valorPis : produto.pis?.valor ?? 0;
			valores.baseCalculoCofins += produto.baseCalculoCofins
				? produto.baseCalculoCofins
				: produto.cofins?.baseCalculoValor ?? 0;
			valores.valorCofins += produto.valorCofins ? produto.valorCofins : produto.cofins?.valor ?? 0;
			valores.unitario = produto.valorUnitario ?? 0;
			valores.quantidade = produto.quantidade ?? 0;
			valores.quantidadeConvertida = produto.quantidadeConvertida ?? 0;
			valores.frete += produto.valorFrete ?? 0;
			valores.seguro += produto.valorSeguro ?? 0;
			valores.acessorias += produto.valorAcessorias ?? 0;
			valores.desconto += produto.valorDesconto ?? 0;

			valores.totalProdutos += parseFloatNumber(valores.unitario * valores.quantidade);
		});

		valores.totalImpostos = valores.valorIcmsSt + valores.valorIpi;

		valores.total =
			valores.totalProdutos +
			valores.totalImpostos +
			valores.frete +
			valores.seguro +
			valores.acessorias -
			valores.desconto;

		setTotalizadores({
			baseCalculoIcms: valores.baseCalculoIcms,
			valorIcms: valores.valorIcms,
			baseCalculoIcmsSt: valores.baseCalculoIcmsSt,
			valorIcmsSt: valores.valorIcmsSt,
			baseCalculoIpi: valores.baseCalculoIpi,
			valorIpi: valores.valorIpi,
			baseCalculoPis: valores.baseCalculoPis,
			valorPis: valores.valorPis,
			baseCalculoCofins: valores.baseCalculoCofins,
			valorCofins: valores.valorCofins,
			totalProdutos: valores.totalProdutos,
			totalImpostos: valores.totalImpostos,
			totalNota: valores.total,
		});

		if (Object.keys(comparissonEffect ?? {}).length > 0) {
			setFieldValue('totais', {
				...values.totais,
				valorBaseCalculo: parseFloatNumber(valores.baseCalculoIcms),
				valorIcms: parseFloatNumber(valores.valorIcms),
				valorBaseCalculoSt: parseFloatNumber(valores.baseCalculoIcmsSt),
				valorSt: parseFloatNumber(valores.valorIcmsSt),
				valorBaseCalculoIpi: parseFloatNumber(valores.baseCalculoIpi),
				valorIpi: parseFloatNumber(valores.valorIpi),
				valorBaseCalculoPis: parseFloatNumber(valores.baseCalculoPis),
				valorPis: parseFloatNumber(valores.valorPis),
				valorBaseCalculoCofins: parseFloatNumber(valores.baseCalculoCofins),
				valorCofins: parseFloatNumber(valores.valorCofins),
				valorProdutos: parseFloatNumber(valores.totalProdutos),
				valorTributos: parseFloatNumber(valores.totalImpostos),
				valorNfe: parseFloatNumber(valores.total),
			});
		}
	}

	function isSorted() {
		let currentSort = [];
		let correctSort = [];

		values.itens?.map((item) => {
			currentSort.push(item.item);
		});

		for (let i = 1; i < values.itens?.length + 1; i++) {
			correctSort.push(i);
		}

		if (JSON.stringify(currentSort) === JSON.stringify(correctSort)) {
			return true;
		} else {
			return false;
		}
	}

	function ajustarItens() {
		let check = isSorted();

		let i = 1;
		if (!check) {
			let itensAjustados = values.itens?.map((item) => {
				item.item = i;
				i++;
				return item;
			});
			setFieldValue('itens', itensAjustados);
		}
	}

	function calcularCustos() {
		let acessorias = 0;
		let descontos = 0;
		let frete = 0;
		let seguro = 0;

		values.itens?.forEach((item) => {
			acessorias += item.valorAcessorias;
			descontos += item.valorDesconto;
			frete += item.valorFrete;
			seguro += item.valorSeguro;
		});

		setFieldValue('totais.valorAcessorias', parseFloatNumber(acessorias));
		setFieldValue('totais.valorDesconto', parseFloatNumber(descontos));
		setFieldValue('totais.valorFrete', parseFloatNumber(frete));
		setFieldValue('totais.valorSeguro', parseFloatNumber(seguro));
	}

	async function asyncSelectNotaEntrada(idNotaEntrada) {
		await asyncGetNotaEntrada(idNotaEntrada, ({ data: notaEntrada }) => {
			let notaConvertida = converterNotaParaFormulario(notaEntrada);
			resetForm({ values: notaConvertida });

			notaConvertida.situacao === 'FINALIZADA' || notaConvertida.situacao === 'CANCELADA'
				? setIsFinalizado(true)
				: null;

			fetchFavoritosPagamentos();
		});
	}

	function onSavePagamentos(values) {
		setFieldValue('cobranca', values);
	}

	function onSaveTransportes(values) {
		setFieldValue('transporte', values);
	}

	function onSaveDetalhesFornecedor(values) {
		setFieldValue('emitente', values);
	}

	function onSaveProduto(produto) {
		if (produto.id) {
			let produtosAtualizados = values.itens?.map((item) => {
				if (item.id && produto.id === item.id) {
					return produto;
				} else if (item.idTemporario ?? false) {
					return produto;
				} else {
					return item;
				}
			});

			setIsUserInput(true);
			setFieldValue('itens', produtosAtualizados);
		} else {
			let presentItens = [];

			values.itens?.forEach((item) => {
				presentItens?.push(item.item);
			});

			produto.idTemporario = gerarUUID();
			produto.item = values.itens?.length + 1;

			setIsUserInput(true);
			setFieldValue('itens', [...values.itens, produto]);
		}
		setModalProdutos(false);
	}

	function onCancelar() {
		if (dirty) {
			handleReset();
		} else {
			voltarParaAPesquisa(history, '/nota_entrada');
		}
	}

	async function novo() {
		atualizarUrl(history, '/nota_entrada/cadastro', null, metodosAtualizarUrl.POP);
		setIsFinalizado(false);
		await resetFormWithFavoritos(copiarObjeto(initialValues));
	}

	async function resetFormWithFavoritos(data = values) {
		const favoritos = {
			operacaoFiscal: null,
			pagamentos: [],
		};

		const promises = [
			readOperacaoFiscalFavorita(({ data: response }) => {
				const operacao = response.content[0];
				favoritos.operacaoFiscal = converterValueParaSelect(operacao, `${operacao.codigo} - ${operacao.descricao}`);
			}),
			fetchFavoritosPagamentos((favoritosPagamentos) => {
				favoritos.pagamentos = favoritosPagamentos;
			}),
		];

		await Promise.all(promises).then(() => {
			resetForm({ values: { ...data, ...favoritos } });
		});
	}

	function renderSituacao(nota) {
		const styleBackground = {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: isMobile ? '100%' : '32%',
			padding: '0.7rem',
			marginBottom: '1rem',
			borderRadius: '20px',
		};

		switch (nota.situacao) {
			case 'PENDENTE': {
				return Badge(ColorsSituacao.textPendente, ColorsSituacao.bgPendente, 'Pendente', styleBackground);
			}
			case 'FINALIZADA': {
				return Badge(ColorsSituacao.textFinalizada, ColorsSituacao.bgFinalizada, 'Finalizada', styleBackground);
			}
			case 'CANCELADA': {
				return Badge(ColorsSituacao.textCancelada, ColorsSituacao.bgCancelada, 'Cancelada', styleBackground);
			}
			default: {
				return nota.situacao;
			}
		}
	}

	function defineColorStatus(situacao) {
		switch (situacao) {
			case 'PENDENTE': {
				return ColorsSituacao.textPendente;
			}
			case 'FINALIZADA': {
				return ColorsSituacao.textFinalizada;
			}
			case 'CANCELADA': {
				return ColorsSituacao.textCancelada;
			}
			default: {
				return ColorsSituacao.textPendente;
			}
		}
	}

	function onImportarXml() {
		let input = document.createElement('input');
		input.type = 'file';
		input.accept = 'text/xml';
		input.onchange = (e) => {
			let file = e.target.files[0];

			if (file.type === 'text/xml') {
				setXml(file);
				let reader = new FileReader();
				reader.readAsText(file, 'UTF-8');

				reader.onload = async (readerEvent) => {
					setDesabilitarRecalcularPagamento(true);
					let dadosConvertidos = await converteXmlParaFormulario(readerEvent.target.result);
					if (dadosConvertidos) {
						setValues(dadosConvertidos);
						await afterImportarXml(dadosConvertidos);
					} else {
						notify('O modelo do XML importado é diferente de 55 (NF-e).', ToastTypes.ERROR);
					}
					setDesabilitarRecalcularPagamento(false);
				};
			} else {
				notify('O arquivo selecionado não é um arquivo XML válido.', ToastTypes.ERROR);
			}
		};
		input.click();
	}

	async function onImportarXmlMde(idMde) {
		await asyncGetXmlNfe(idMde, async ({ data: xml }) => {
			setDesabilitarRecalcularPagamento(true);
			setXml(new File([xml], 'nfe.xml', { type: 'text/xml' }));
			let dadosConvertidos = await converteXmlParaFormulario(xml);
			if (dadosConvertidos) {
				setValues(dadosConvertidos);
				await afterImportarXml(dadosConvertidos);
			} else {
				notify('O modelo do XML importado é diferente de 55 (NF-e).', ToastTypes.ERROR);
			}
			setDesabilitarRecalcularPagamento(false);
		});
	}

	function mensagensDeErro() {
		let mensagens = {};
		if (values.cobranca.condicao === 'A_VISTA' && values.cobranca.conta === null) {
			mensagens = formatField('Conta');
		} else if (errosPagamento) {
			setErrosPagamento(false);
		}

		if (values.cobranca.condicao === 'A_PRAZO' && values.cobranca.categoria === null) {
			mensagens = formatField('Categoria');
		} else if (errosPagamento) {
			setErrosPagamento(false);
		}

		if (values.itens?.length === 0) {
			mensagens = formatField('Item');
		} else if (errosItens) {
			setErrosItens(false);
		}

		return mensagens;
	}

	function formatField(field) {
		return (
			<span key={field}>
				{field} - Obrigatório
				<br />
			</span>
		);
	}

	function mensagemErroModeloNFe() {
		let mensagem = null;
		if (errors?.itens) {
			if (errors?.itens.numeroItens === 1) {
				mensagem = <span>{`O item ${errors?.itens.produtosComErro} não contém o campo quantidade informada.`}</span>;
			} else {
				mensagem = <span>{`Os itens ${errors?.itens.produtosComErro} não contêm o campo quantidade informada.`}</span>;
			}
		}
		return mensagem;
	}

	async function afterImportarXml(dados) {
		const validaFornecedorCadastrado = () => {
			if (!dados.emitente?.pessoa?.id) {
				setTimeout(() => {
					confirm(
						'Confirmação',
						Messages.FornecedorNaoCadastrado(dados.emitente.nome),
						() => cadastrarFornecedor(dados.emitente, () => setVisibleModalVinculo(dados.itens)),
						() => {
							resetForm({ values: { ...copiarObjeto(initialValues) } });
						},
						'Sim',
						'Não'
					);
				}, 500);
			} else {
				setVisibleModalVinculo(dados.itens);
			}
		};

		const validaCpfCnpjOrganizacao = () => {
			if (!dados.destinatarioOrganizacao) {
				setTimeout(() => {
					confirm(
						'Confirmação',
						Messages.DestinatarioDiferenteOrganizacao,
						() => validaFornecedorCadastrado(),
						() => {
							resetForm({ values: { ...copiarObjeto(initialValues) } });
						},
						'Sim',
						'Não'
					);
				}, 500);
			} else {
				validaFornecedorCadastrado();
			}
		};

		if (dados.chaveAcessoCadastrada) {
			confirm(
				'Aviso',
				Messages.ChaveAcessoCadastrada,
				() => carregarNotaEntrada(dados.idNotaCadastrada),
				() => {
					resetForm({ values: { ...copiarObjeto(initialValues) } });
				},
				'Acessar nota cadastrada'
			);
		} else {
			validaCpfCnpjOrganizacao();
		}
	}

	async function carregarNotaEntrada(id) {
		atualizarUrl(history, '/nota_entrada/cadastro', id);
		await asyncGetNotaEntrada(id, async ({ data: nota }) => {
			resetForm({ values: converterNotaParaFormulario(nota) });
		});
	}

	async function cadastrarFornecedor(emitente, callback) {
		let fornecedor = {
			id: '',
			cliente: false,
			fornecedor: true,
			transportador: false,
			nome: emitente.nome,
			situacao: 'ATIVA',
			tipo: emitente.cnpj !== null && emitente.cpf === null ? 'JURIDICA' : 'FISICA',
			observacao: null,
			enderecos: [
				{
					logradouro: emitente.logradouro,
					bairro: emitente.bairro,
					numero: emitente.numero,
					cep: emitente.cep,
					complemento: emitente.complemento,
					municipio: emitente.municipio,
					pais: emitente.municipio.estado.pais,
					favorito: true,
					municipioExterior: null,
					estadoExterior: null,
				},
			],
			emails: [],
			limiteCredito: 0,
			consumidorFinal: false,
			inscricaoEstadual: '',
			indicadorInscricaoEstadual: 'NAO_CONTRIBUINTE',
			imagem: null,
			telefones: [],
			contatos: [],
			pessoaFisica:
				emitente.cpf !== null && emitente.cnpj === null
					? {
							cpf: emitente.cpf,
							rg: null,
							genero: null,
							nascimento: null,
							pessoaPai: null,
							pessoaMae: null,
							pessoaConjuge: null,
							localTrabalho: null,
						}
					: null,
			pessoaJuridica:
				emitente.cnpj !== null && emitente.cpf === null
					? {
							cnpj: emitente.cnpj,
							razaoSocial: emitente.nomeFantasia,
							simplesNacional: emitente.regimeTributario === '1' ? true : false,
							inscricaoSuframa: '',
							inscricaoMunicipal: emitente.inscricaoMunicipal ? emitente.inscricaoMunicipal : '',
						}
					: null,
			pessoaEstrangeira: null,
			configPrecoPraticado: 'PRECO_VENDA',
		};
		await asyncCadastrarPessoa(fornecedor, ({ data: pessoa }) => {
			setFieldValue('emitente', {
				...emitente,
				pessoa: pessoa,
				label: `${pessoa.codigo} - ${pessoa.nome}`,
			});
		});
		callback();
	}

	async function onConfirmarModalVinculo(value) {
		await setFieldValue('itens', value);
		setValidaTransportador(true);
	}

	function onHideModalVinculoFornecedor() {
		setModalVinculoFornecedor(false);
	}

	function setVisibleModalVinculo(itens) {
		let noBond = false;
		itens?.forEach((item) => {
			if (!item.vinculo || !item.cfop) {
				noBond = true;
			}
		});

		if (noBond) {
			setModalVinculoFornecedor(true);
		} else {
			setValidaTransportador(true);
		}
	}

	async function fetchFavoritosPagamentos(callback) {
		const pagamentos = initialValues.pagamentos;
		const favoritosToContextState = {};

		const promises = [
			buscarContaFavoritaDespesa(({ data: response }) => {
				const contaFavorita = converterValueParaSelect(response.content[0]);
				pagamentos[0].conta = contaFavorita;
				favoritosToContextState.conta = contaFavorita;
			}),
			buscarCategoriaFavoritaDespesa(({ data: response }) => {
				const categoria = converterValueParaSelect(response.content[0]);
				pagamentos[0].categoria = categoria;
				favoritosToContextState.categoria = categoria;
			}),
			readCondicaoPagamentoSemPagamento(async ({ data: response }) => {
				const condicaoPagamentoFavorita = converterValueParaSelect(response.content[0]);
				const isAPrazo = condicaoPagamentoFavorita?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
				const parcelaPadraoCondicaoPagamento =
					condicaoPagamentoFavorita?.registro?.parcelaPadrao ?? condicaoPagamentoFavorita?.registro?.parcelaMinima ?? 1;

				pagamentos[0].condicaoPagamento = condicaoPagamentoFavorita;
				favoritosToContextState.condicaoPagamento = condicaoPagamentoFavorita;

				function setFormaPagamento(formaPagamento) {
					const formaPagamentoConvertida = converterValueParaSelect(formaPagamento);
					pagamentos[0].formaPagamento = formaPagamentoConvertida;
					favoritosToContextState.formaPagamento = formaPagamentoConvertida;

					if (formaPagamento.conta) {
						const contaConvertida = converterValueParaSelect(formaPagamento.conta);
						pagamentos[0].conta = contaConvertida;
						favoritosToContextState.conta = contaConvertida;
					}

					if (formaPagamento.categoria) {
						const categoriaConvertida = converterValueParaSelect(formaPagamento.categoria);
						pagamentos[0].categoria = categoriaConvertida;
						favoritosToContextState.categoria = categoriaConvertida;
					}
				}

				if (condicaoPagamentoFavorita.registro.formaPagamento) {
					setFormaPagamento(condicaoPagamentoFavorita.registro.formaPagamento);
				} else {
					await asyncGetFormaPagamentoDinheiro(({ data: response }) => {
						setFormaPagamento(response.content[0]);
					});
				}

				if (isAPrazo) {
					pagamentos[0].quantidadeParcelas = {
						value: parcelaPadraoCondicaoPagamento,
						label: `${parcelaPadraoCondicaoPagamento}x`,
					};
					pagamentos[0].parcelas = generateParcelas({
						qtdParcelas: parcelaPadraoCondicaoPagamento,
						favoritos: favoritosToContextState,
					});
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFavoritosPagamento(favoritosToContextState);

			if (typeof callback === 'function') {
				callback(pagamentos);
			}
		});
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Tutorial
				steps={tutorialStepsNotaEntrada}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => {
					setTutorialVisible(false);
					if (props.match.path === '/nota_entrada/importar_xml') {
						onImportarXml();
					}
				}}
			/>
			<Form header="Cadastro de notas de entrada" className="card-default screen-max-width">
				<FormActions className="screen-max-width step-nota-entrada-actions">
					<ActionButtons
						podeEditar={podeEditar}
						podeInserir={podeInserir}
						podeExcluir={podeExcluir}
						setModalHistorico={setModalHistorico}
						setIsFinalizado={setIsFinalizado}
						setTabActivePagamentos={setTabActivePagamentos}
						onCancelar={onCancelar}
						carregarNotaEntrada={carregarNotaEntrada}
						setErrosPagamento={setErrosPagamento}
						setTabIndex={setTabIndex}
						setModalDadosFornecedor={setModalDadosFornecedor}
						setErrosItens={setErrosItens}
						xml={xml}
						onImportarXml={onImportarXml}
						setModalAtualizacaoPrecos={setModalAtualizacaoPrecos}
						setRecarregarParcelas={setRecarregarParcelas}
						novo={novo}
						history={history}
					/>
				</FormActions>
				<FormContent>
					<If test={errosPagamento}>
						<Message
							severity="error"
							text={errosPagamento ? mensagensDeErro() : null}
							colStyle={{ padding: '5px 0px', marginBottom: '8px' }}
							className="message-nota-compra-effect"
						/>
					</If>
					<If test={errosItens}>
						<Message
							severity="error"
							text={'A nota deve conter ao menos um item'}
							colStyle={{ padding: '5px 0px', marginBottom: '8px' }}
							className="message-nota-compra-effect"
						/>
					</If>
					<If test={errors?.itens}>
						<Message
							severity="error"
							text={errors?.itens ? mensagemErroModeloNFe() : null}
							colStyle={{ padding: '5px 0px', marginBottom: '8px' }}
							className="message-nota-compra-effect"
						/>
					</If>
					<div>{renderSituacao(values)}</div>
					<Grid style={{ margin: '0px' }}>
						<Grid className="step-nota-entrada-inputs">
							<CabecalhoNotaEntrada
								isFinalizado={isFinalizado}
								podeEditar={podeEditar}
								setModalDadosFornecedor={setModalDadosFornecedor}
								isUserInput={isUserInput}
								setIsUserInput={setIsUserInput}
								isMobile={isMobile}
							/>
						</Grid>
						<div style={{ width: '100%', backgroundColor: 'transparent' }} className="step-nota-entrada-itens">
							<TabelaItens
								sortField={sortField}
								sortOrder={sortOrder}
								podeExcluir={podeExcluir}
								podeEditar={podeEditar}
								setRegistroSelecionado={setRegistroSelecionado}
								setModalProdutos={setModalProdutos}
								isFinalizado={isFinalizado}
								isMobile={isMobile}
							/>
							<ButtonAdicionarItem
								label={
									values.modelo?.value === '8f07d8eb-0ca0-4830-8b12-729e6ae2019b'
										? 'Adicionar serviço'
										: 'Adicionar produto'
								}
								style={{
									...buttonAdicionarStyle,
									color: defineColorStatus(values.situacao),
								}}
								onClick={() => {
									setRegistroSelecionado(null);
									setModalProdutos(true);
								}}
								tabIndex={-1}
								disabled={values.situacao !== 'PENDENTE' || values.xmlImportado || (values.id && !podeEditar)}
							/>
						</div>
						<Col sm="12" md="12" lg="12" xl="12">
							<TabView
								renderActiveOnly
								activeIndex={tabIndex}
								onTabChange={(e) => setTabIndex(e.index)}
								className={`step-nota-entrada-tabs tab-view ${CLASSNAME_TABVIEW_COLOR[values.situacao].class}`}
							>
								<TabPanel header="Totalizadores">
									<FieldsTotalizadores
										{...props}
										totalizadores={totalizadores}
										colorStatus={defineColorStatus(values.situacao)}
										isMobile={isMobile}
									/>
								</TabPanel>
								<TabPanel header="Pagamentos">
									<FieldsPagamentosForm
										{...props}
										values={values}
										parentValues={values}
										tabPagamentoDisabled={!values.id || values.situacao !== 'FINALIZADA'}
										idCompra={values?.id}
										disabled={isFinalizado || (values.id && !podeEditar)}
										recarregarParcelas={recarregarParcelas}
										setRecarregarParcelas={setRecarregarParcelas}
										tabActivePagamentos={tabActivePagamentos}
										setTabActivePagamentos={setTabActivePagamentos}
										isFinalizado={isFinalizado}
										onSave={onSavePagamentos}
										validationErrors={errors}
										favoritos={favoritosPagamento}
										informacoesPermissoes={informacoesPermissoes}
									/>
								</TabPanel>
								<TabPanel header="Transporte">
									<FieldsTransporte
										values={values.transporte}
										onSave={onSaveTransportes}
										isFinalizado={isFinalizado || (values.id && !podeEditar)}
									/>
								</TabPanel>
							</TabView>
						</Col>
					</Grid>
				</FormContent>
			</Form>
			<If test={modalVinculoFornecedor}>
				<ModalVinculoFornecedor
					onHide={onHideModalVinculoFornecedor}
					visible={modalVinculoFornecedor}
					itens={values.itens}
					cancelaImportacaoXml={() => onCancelar()}
					onConfirmarModalVinculo={onConfirmarModalVinculo}
					isFinalizado={isFinalizado}
					isMobile={isMobile}
				/>
			</If>
			<If test={modalAtualizacaoPrecos}>
				<ModalAtualizacaoPrecos
					onHide={() => setModalAtualizacaoPrecos(false)}
					visible={modalAtualizacaoPrecos}
					itens={values.itens}
					idNotaEntrada={values.id}
					isMobile={isMobile}
				/>
			</If>
			<If test={modalProdutos}>
				<ModalProdutos
					onHide={() => {
						setModalProdutos(false);
					}}
					visible={modalProdutos}
					modeloNota={values.modelo}
					isMobile={isMobile}
					valuesProduto={registroSelecionado}
					isFinalizado={isFinalizado || (values.id && !podeEditar)}
					xmlImportado={values.xmlImportado}
					onSave={(e) => onSaveProduto(e)}
				/>
			</If>
			<If test={modalDadosFornecedor}>
				<ModalDadosFornecedor
					onHide={() => setModalDadosFornecedor(false)}
					visible={modalDadosFornecedor}
					isFinalizado={isFinalizado || values.xmlImportado || (values.id && !podeEditar)}
					isMobile={isMobile}
					valuesDadosFornecedor={values?.emitente}
					mainFormSubmitCount={submitCount}
					onSave={(e) => onSaveDetalhesFornecedor(e)}
				/>
			</If>
			<If test={modalHistorico}>
				<ModalNotaEntradaHistorico
					visible={modalHistorico}
					idNotaEntrada={values.id}
					onHide={() => setModalHistorico(false)}
				/>
			</If>
		</>
	);
}

const NotasEntradaForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		let modifiedinitialValues = { ...copiarObjeto(initialValues) };
		if (props.match.path === '/nota_entrada/cadastro') {
			modifiedinitialValues = {
				...copiarObjeto(initialValues),
				emitente: null,
			};
			return modifiedinitialValues;
		} else {
			return initialValues;
		}
	},

	validationSchema: Yup.object().shape({
		numero: Yup.string().nullable().required(MessagesErrors.OBRIGATORIO),
		serie: Yup.string().nullable().required(MessagesErrors.OBRIGATORIO),
		modelo: Yup.object().nullable().required(MessagesErrors.OBRIGATORIO),
		emitente: Yup.object().nullable().required(MessagesErrors.CAMPO_OBRIGATORIO),
		operacaoFiscal: Yup.object().nullable().required(MessagesErrors.CAMPO_OBRIGATORIO),
		cobranca: Yup.object({
			conta: Yup.object()
				.nullable()
				.when('condicao', (condicao, schema) =>
					condicao === 'A_VISTA' ? schema.required(MessagesErrors.OBRIGATORIO) : schema
				),
			categoria: Yup.object()
				.nullable()
				.when('condicao', (condicao, schema) =>
					condicao === 'A_PRAZO' ? schema.required(MessagesErrors.CAMPO_OBRIGATORIO) : schema
				),
		}),
		dataEntrada: Yup.string().nullable().required(MessagesErrors.CAMPO_OBRIGATORIO),
	}),

	validate: (values) => {
		let errors = {};
		let errorsItens = { produtosComErro: '', numeroItens: 0 };
		const errorsPagamentos = validatePagamentos(values.pagamentos, values.totais?.valorNfe);

		if (values.chaveAcesso && values.chaveAcesso.length !== 44) {
			errors.chaveAcesso = MessagesErrors.CHAVE_ACESSO_INVALIDA;
		}

		//validação quando for modelo 55
		if (values.modelo.value === 'a4067392-505f-47e4-81c0-2f89c55484c9') {
			if (!values.chaveAcesso) {
				errors.chaveAcesso = MessagesErrors.CAMPO_OBRIGATORIO;
			}

			let ultimoItem = 0;
			values.itens?.forEach((item, index) => {
				if (item.quantidade === 0 || !item.quantidade) {
					ultimoItem = index + 1;

					errorsItens.produtosComErro =
						errorsItens.numeroItens === 0 ? `${index + 1}` : `${errorsItens.produtosComErro}, ${index + 1}`;
					errorsItens.numeroItens++;
				}
				if (values.itens?.length - 1 === index) {
					if (errorsItens.numeroItens > 1) {
						errorsItens.produtosComErro = ReplaceAt(
							errorsItens.produtosComErro,
							' e ',
							errorsItens.produtosComErro?.length - (String(ultimoItem).length + 2),
							errorsItens.produtosComErro?.length - String(ultimoItem).length
						);
					}
				}
			});

			if (errorsItens.numeroItens > 0) {
				errors.itens = errorsItens;
			}
		}

		if (errorsPagamentos.length > 0) {
			errors.pagamentos = errorsPagamentos;
		}

		return errors;
	},

	handleSubmit: () => {},
})(NotasEntradaFormView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default withRouter(connect(mapStateToProps)(NotasEntradaForm));
