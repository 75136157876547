export const DADOSLOCALSTORAGE = {
	ultimoEmailCompartilharDocumento: '698377a0886e596d5634aad59c63fff2f',
	dadosLogin: '3985b0320064333d1662e644a1a1047eec1b7',
	dataUltimoAcesso: 'c2ec6a184c68423d1662e86e596d5634aa5',
	instalacaoAplicativoJaSolicitada: '3df2a7c0bd6550dc8abe16963a070fe7',
	tabviewDashboard: 'fc535d1754dd54f8681b128fa74ba9a7',
	ultimaRotaNaoAutenticada: 'e48f21a4-40d5-41a9-bc98-d0a1a0e47e63',
	notificarPorEmailDocumentoAssinadoRejeitado: 'cd96a972-8a1c-42bc-98a5-2c286e0344d0',
	ultimaEtiquetaImpressa: 'ea0cyoqhpesqgwwihomrdrrrquisrslwao8i86i3jii',
	ultimaNotificacaoAniversario: 'ece3ce18699548f0bf21930b0fc4bae7',
};
