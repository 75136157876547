import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { If, Message } from 'components';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';

const textStyle = {
	margin: '5px 0px',
};

function MessageDiasRestantesImpl({ history }) {
	const [podeVisualizarPlanos] = useState(usuarioPossuiPermissao(recursos.PLANOS, permissoes.VISUALIZAR));

	return (
		<Message
			severity="warn"
			colStyle={{ margin: '1em 0em' }}
			text={
				<div>
					<h2>Não foi possível confirmar seu pagamento</h2>
					<p style={textStyle}>Regularize sua mensalidade para evitar o bloqueio do sistema</p>
					<If test={podeVisualizarPlanos}>
						<span onClick={() => history.push('/planos')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
							Ir para plano e preços
						</span>
					</If>
				</div>
			}
		/>
	);
}

export const MessageDiasRestantes = withRouter(MessageDiasRestantesImpl);
