import { useState } from "react";
import { Column } from "primereact/column";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import { DataTable } from "primereact/datatable";

import {
  ButtonEditarTable,
  ButtonExcluirTable,
  Modal,
  If,
  ButtonNovo,
  NenhumRegistroEncontrado,
  Prompt,
  Fieldset,
} from "components";

import { removerElemento, estadosCadastro } from "Common";

import DocumentoConfirmacaoSignatarioForm from "./Form";

function DocumentoConfirmacaoSignatario(props) {
  const { dirty, value, informacoesPermissoes, estadoCadastro, disabled } =
    props;

  const [registroSelecionado, setRegistroSelecionado] = useState(null);
  const [modalFormVisible, setModalFormVisible] = useState(false);

  const podeInserir =
    estadoCadastro === estadosCadastro.INCLUSAO
      ? informacoesPermissoes.podeInserir
      : informacoesPermissoes.podeEditar;

  function novo() {
    setModalFormVisible(true);
    setRegistroSelecionado(null);
  }

  function onEditar(row) {
    setModalFormVisible(true);
    setRegistroSelecionado(row);
  }

  function renderOpcoes(row) {
    const { informacoesPermissoes, disabled } = props;
    return (
      <div>
        <ButtonEditarTable
          onClick={() => onEditar(row)}
          {...informacoesPermissoes}
        />
        <ButtonExcluirTable
          onClick={() => excluirRegistro(row)}
          disabled={disabled}
          podeExcluir={informacoesPermissoes.podeEditar}
        />
      </div>
    );
  }

  function hideModal() {
    setModalFormVisible(false);
    setRegistroSelecionado(null);
  }

  function atualizarRegistro(dadosFormulario) {
    const { value } = props;

    const result =
      value &&
      value.map((item) => {
        if (
          item.idTemporario &&
          item.idTemporario === dadosFormulario.idTemporario
        ) {
          return dadosFormulario;
        } else if (item.id && item.id === dadosFormulario.id) {
          return dadosFormulario;
        }
        return item;
      });
    setRegistroSelecionado(dadosFormulario);
    props.onChange(result);
  }

  function inserirRegistro(dadosFormulario) {
    props.onChange([...(props.value ? props.value : []), dadosFormulario]);
    setRegistroSelecionado(dadosFormulario);
  }

  function excluirRegistro(dadosFormulario) {
    props.onChange(removerElemento(props.value, dadosFormulario));
    setRegistroSelecionado(null);
  }

  function renderRespostaEsperada(row) {
    if (!row?.respostaEsperada) {
      return <p style={{ color: "red" }}>Nenhum valor informado</p>;
    }

    if (row?.respostaTipo === "SIM_NAO") {
      if (row?.respostaEsperada === "true") {
        return "Sim";
      } else if (row?.respostaEsperada === "false") {
        return "Não";
      }
    }

    if (
      row?.respostaTipo === "DATA" &&
      isValid(new Date(row?.respostaEsperada))
    ) {
      return format(new Date(row?.respostaEsperada), "dd/MM/yyyy");
    }

    return row?.respostaEsperada;
  }

  return (
    <>
      <Prompt dirty={dirty} />
      <Modal
        visible={modalFormVisible}
        header="Cadastro de confirmação para assinatura"
        onHide={() => setModalFormVisible(false)}
      >
        <If test={modalFormVisible}>
          <DocumentoConfirmacaoSignatarioForm
            disabled={disabled}
            onNovoClick={() => setRegistroSelecionado(null)}
            registroSelecionado={registroSelecionado}
            excluirRegistro={(e) => excluirRegistro(e)}
            inserirRegistro={(e) => inserirRegistro(e)}
            atualizarRegistro={(e) => atualizarRegistro(e)}
            onHide={() => hideModal()}
            estadoCadastro={estadoCadastro}
            informacoesPermissoes={informacoesPermissoes}
          />
        </If>
      </Modal>

      <Fieldset
        legend="Confirmações específicas do signatário"
        className="fieldset"
        style={{ padding: 0 }}
      />
      <ButtonNovo
        label="Nova confirmação"
        enableShortCut={false}
        onClick={() => novo()}
        disabled={disabled}
        podeInserir={podeInserir}
      />
      <DataTable
        className="table"
        responsive
        value={value}
        emptyMessage={
          <NenhumRegistroEncontrado message="Nenhuma confirmação encontrada" />
        }
      >
        <Column
          field="pergunta"
          header="Pergunta para confirmação"
          sortable={true}
        />
        <Column
          field="respostaEsperada"
          header="Resposta esperada"
          body={(row) => renderRespostaEsperada(row)}
          sortable={true}
        />
        <Column body={renderOpcoes} header="Ações" style={{ width: "7em" }} />
      </DataTable>
    </>
  );
}

export default DocumentoConfirmacaoSignatario;
