import React, { useEffect, useState } from 'react';
import { withFormik, Field } from 'formik';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';

import {
	Grid,
	Form,
	FormActions,
	FormContent,
	InputField,
	ButtonCancelar,
	estadosBotaoCancelar,
	Button,
	TextArea,
	If,
} from '../../../../index';

import { asyncInutilizar, asyncGetInutilizacao } from '../../../Requests';
import { inutilizacaoFormParaApi, inutilizacaoApiParaForm } from '../../../Util/inutilizacaoConverter';
import { MessagesErrors, TipoInutilizacao } from '../../../Util/constantes';
import { buscarDadosLoginLocalStorage } from '../../../../../Common';
import { format } from 'date-fns';

const initialvalues = {
	dataInutilizacao: null,
	id: null,
	justificativa: null,
	modelo: null,
	numeroFinal: null,
	numeroInicial: null,
	serie: null,
	situacao: null,
	usuario: null,
	ano: format(new Date(), 'yyyy'),
};

function FormModalNovaInutilizacaoView(props) {
	const {
		setFieldValue,
		onHide,
		informacoesPermissoes,
		values,
		dirty,
		resetForm,
		isMobile,
		isLessHd,
		validateForm,
		isValid,
		setTouched,
		selectedElement,
		tipoInutilizacao,
		pathName,
	} = props;

	const [finalizado, setFinalizado] = useState(false);

	useEffect(() => {
		if (selectedElement) {
			setFinalizado(true);
			pesquisarInutilizacao();
		}
		if (tipoInutilizacao === TipoInutilizacao.NFE) {
			setFieldValue('serie', buscarDadosLoginLocalStorage().filialConectada.parametrosFiscalNFe?.serieNfe);
		} else {
			setFieldValue('serie', buscarDadosLoginLocalStorage().filialConectada.parametrosFiscalNFCe?.serieNfce);
		}

		setTimeout(() => {
			document.getElementById('inputSerie')?.focus();
		}, 500);
	}, []);

	function cancelar() {
		if (dirty) {
			resetForm('');
		} else {
			onHide();
		}
	}

	async function pesquisarInutilizacao() {
		asyncGetInutilizacao(pathName, selectedElement.id, ({ data: inutilizacao }) => {
			resetForm({ values: inutilizacaoApiParaForm(inutilizacao) });
		});
	}

	async function transmitir() {
		let valoresConvertidos = inutilizacaoFormParaApi(values);

		let dadosUsuario = buscarDadosLoginLocalStorage();
		let nome = dadosUsuario.nome;
		let email = dadosUsuario.email;

		valoresConvertidos = { ...valoresConvertidos, usuarioNome: nome, usuarioEmail: email };

		setTouched({ ano: true, serie: true, numeroInicial: true, numeroFinal: true, justificativa: true });

		await validateForm();
		if (isValid) {
			asyncInutilizar(pathName, valoresConvertidos, response => {
				if (response.status === 200) {
					setFinalizado(true);
					resetForm({ values: inutilizacaoApiParaForm(response.data) });
				}
			});
		}
	}

	return (
		<div
			style={{
				width: '100%',
				overflowY: isMobile ? 'scroll' : 'hidden',
				overflowX: 'hidden',
				maxHeight: '40rem',
			}}
		>
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
					/>
					<Button
						label={isMobile ? 'Inutilizar' : 'Transmitir inutilização'}
						className="p-button-success"
						icon={<Icon icon="fa:paper-plane" style={{ marginRight: '0.5rem' }} />}
						onClick={() => transmitir()}
						disabled={!dirty}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="6"
							md="6"
							lg="3"
							xl="3"
							obrigatorio
							component={InputField}
							label="Ano"
							name="ano"
							value={values.ano}
							disabled={true}
							onChange={elemento => setFieldValue('ano', elemento.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="6"
							lg="3"
							xl="3"
							id="inputSerie"
							obrigatorio
							component={InputField}
							label="Série"
							name="serie"
							value={values.serie}
							type={'number'}
							onChange={elemento => setFieldValue('serie', elemento.target.value)}
							disabled={finalizado}
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="6"
							lg="3"
							xl="3"
							obrigatorio
							component={InputField}
							label="Nº inicial"
							name="numeroInicial"
							value={values.numeroInicial}
							type={'number'}
							onChange={elemento => setFieldValue('numeroInicial', elemento.target.value)}
							disabled={finalizado}
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="6"
							lg="3"
							xl="3"
							obrigatorio
							component={InputField}
							label="Nº final"
							name="numeroFinal"
							value={values.numeroFinal}
							type={'number'}
							onChange={elemento => setFieldValue('numeroFinal', elemento.target.value)}
							disabled={finalizado}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							obrigatorio
							component={TextArea}
							label="Justificativa"
							name="justificativa"
							value={values.justificativa}
							onChange={elemento => setFieldValue('justificativa', elemento.target.value)}
							disabled={finalizado}
							{...informacoesPermissoes}
						/>
						<If test={finalizado}>
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={InputField}
								label={isLessHd ? 'Data/Hora' : 'Data/Hora da inutilização'}
								name="dataInutilizacao"
								value={values.dataInutilizacao}
								onChange={elemento => setFieldValue('dataInutilizacao', elemento.target.value)}
								disabled={true}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="8"
								xl="8"
								component={InputField}
								label="Usuário"
								name="usuario"
								value={values.usuario}
								disabled={true}
								{...informacoesPermissoes}
							/>
						</If>
					</Grid>
				</FormContent>
			</Form>
		</div>
	);
}

const FormModalNovaInutilizacao = withFormik({
	enableReinitialize: true,
	validateOnMount: true,
	validateOnChange: true,

	mapPropsToValues() {
		return initialvalues;
	},

	validationSchema: Yup.object().shape({
		ano: Yup.number()
			.nullable()
			.required(MessagesErrors.CAMPO_OBRIGATORIO)
			.min(1900, 'Deve ser maior do que 1900')
			.max(3000, 'Deve ser menor do que 3000'),
		serie: Yup.number().nullable().required(MessagesErrors.CAMPO_OBRIGATORIO),
		numeroInicial: Yup.number().nullable().required(MessagesErrors.CAMPO_OBRIGATORIO),
		numeroFinal: Yup.number()
			.nullable()
			.required(MessagesErrors.CAMPO_OBRIGATORIO)
			.min(Yup.ref('numeroInicial'), 'Número final deve ser maior ou igual ao número inicial')
			.max(999999999, 'Deve ser menor do que 999999999'),
		justificativa: Yup.string()
			.nullable()
			.required(MessagesErrors.CAMPO_OBRIGATORIO)
			.test('len', 'A justificativa deve ter pelo menos 15 caracteres', val => val && val.length >= 15),
	}),

	handleSubmit: () => {},
})(FormModalNovaInutilizacaoView);

export default FormModalNovaInutilizacao;
