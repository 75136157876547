import { InternalInputPassword } from './components/InternalInputPassword';

export default function InputPassword(props) {
	const { field, form, ...rest } = props;
	return (
		<>
			<InternalInputPassword {...field} {...rest} errors={form.errors[field.name]} touched={form.touched[field.name]} />
		</>
	);
}
