import { Col, SelectableCard } from 'components';
import { COLORS, SELECTABLE_CARDS } from '../../Util/constantes';

export function Cards({ cards, selectedCard, setSelectedCard }) {
  function handleSelectCard(card) {
    setSelectedCard(value => (value !== card ? card : null));
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        width: '100%',
      }}
    >
      <Col sm="6" md="6" lg="3" xl="3">
        <SelectableCard
          title="Ordens pendentes"
          name="cardPendentes"
          colors={COLORS.PENDENTE}
          selected={selectedCard === SELECTABLE_CARDS.PENDENTE}
          onSelect={() => handleSelectCard(SELECTABLE_CARDS.PENDENTE)}
          value={cards.pendente?.valor ?? 0}
          numberOfElements={cards.pendente?.quantidade ?? 0}
          titleFiltro="Clique para filtrar pelas ordens de serviço pendentes"
        />
      </Col>
      <Col sm="6" md="6" lg="3" xl="3">
        <SelectableCard
          title="Ordens finalizados"
          name="cardFinalizados"
          colors={COLORS.FINALIZADO}
          onSelect={() => handleSelectCard(SELECTABLE_CARDS.FINALIZADO)}
          selected={selectedCard === SELECTABLE_CARDS.FINALIZADO}
          value={cards.finalizado?.valor ?? 0}
          numberOfElements={cards.finalizado?.quantidade ?? 0}
          titleFiltro="Clique para filtrar pelas ordens de serviço finalizados"
        />
      </Col>
      <Col sm="6" md="6" lg="3" xl="3">
        <SelectableCard
          title="Ordens cancelados"
          name="cardCancelados"
          colors={COLORS.CANCELADO}
          onSelect={() => handleSelectCard(SELECTABLE_CARDS.CANCELADO)}
          selected={selectedCard === SELECTABLE_CARDS.CANCELADO}
          value={cards.cancelado?.valor ?? 0}
          numberOfElements={cards.cancelado?.quantidade ?? 0}
          titleFiltro="Clique para filtrar pelas ordens de serviço cancelados"
          colNumberOfElements="5"
          colTitle="7"
        />
      </Col>
    </div>
  );
}
