import { alert, confirm } from '../../../../../components/Toast';
import {
	asyncArquivarGestaoDocumento,
	asyncCancelarRescisaoDocumento,
	asyncDesarquivarGestaoDocumento,
	asyncGetPesquisaDocumentos,
	asyncRescisaoDocumento,
} from '../../Requests';
import { estadosCadastro } from '../../../../../Common/Constantes/autorizacao';
import { statusGestaoDocumento } from '../../Util/constantes';
import { services } from '../../../../../Common/Constantes/api';

export function buscarEstadoCadastro(props) {
	return props.values?.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
}

export function buscaritensOpcoes(props, setModalHistoricoVisible) {
	let res = [];

	if (props.values.id) {
		const historico = {
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setModalHistoricoVisible(true),
		};
		res.push(historico);
		if (props.values.arquivado === false && props.informacoesPermissoes?.podeEditar) {
			const arquivar = {
				label: 'Arquivar',
				icon: 'fa fa-archive',
				command: () => ArquivarDocumento(props),
			};
			res.push(arquivar);
		}
		if (props.values.arquivado) {
			const arquivar = {
				label: 'Desarquivar',
				icon: 'fa fa-eye',
				command: () => DesarquivarDocumento(props),
			};
			res.push(arquivar);
		}
		if (
			props.values?.tipoGestaoDocumento === 'CONTRATO'
				? props.values.status === statusGestaoDocumento.VIGENTE
				: [statusGestaoDocumento.VIGENTE, statusGestaoDocumento.VIGENCIA_EXPIRADA].includes(props.values.status)
		) {
			const rescindido = {
				label: 'Rescindir documento',
				icon: 'fa fa-file-excel-o',
				command: () => rescisaoDocumento(props),
			};
			res.push(rescindido);
		}
		if (props.values.status === statusGestaoDocumento.RESCINDIDO) {
			const cancelarRescisao = {
				label: 'Cancelar rescisão',
				icon: 'fa fa-undo',
				command: () => cancelarRescisaoDocumento(props),
			};
			res.push(cancelarRescisao);
		}
	}

	return res;
}

function ArquivarDocumento(props) {
	const { values, resetForm } = props;
	const message = `Tem certeza que deseja arquivar este documento? Um documento arquivado não aparecerá mais na listagem, para visualizá-los marque o item "Exibir documentos arquivados" na listagem.`;
	confirm('Atenção', message, () => {
		asyncArquivarGestaoDocumento(
			props.values.id,
			() => {
				resetForm({
					values: { ...values, arquivado: true },
				});
			},
			null
		);
	});
}

function DesarquivarDocumento(props) {
	const { values, resetForm } = props;
	const message = 'Tem certeza que deseja desarquivar este documento?';
	confirm('Atenção', message, () => {
		asyncDesarquivarGestaoDocumento(
			props.values.id,
			() => {
				resetForm({
					values: { ...values, arquivado: false },
				});
			},
			null
		);
	});
}

function rescisaoDocumento(props) {
	const { values, resetForm, dirty } = props;
	if (dirty) {
		alert('Atenção', 'Salve o documento para rescindi-lo');
		return;
	}

	confirm('Confirmação', 'Tem certeza que deseja rescindir este documento?', () => {
		asyncRescisaoDocumento(props.values.id, () => {
			resetForm({
				values: { ...values, status: 'RESCINDIDO' },
			});
		});
	});
}

function cancelarRescisaoDocumento(props) {
	const { values, resetForm } = props;
	confirm('Confirmação', 'Tem certeza que deseja cancelar a rescisão deste documento?', () => {
		asyncCancelarRescisaoDocumento(props.values.id, ({ data: novoStatus }) => {
			resetForm({
				values: { ...values, status: novoStatus },
			});
		});
	});
}

export async function jaExisteDocumentoMesmaIdentificacao(documentoId, identificacao) {
	let existeMesmaIdentificacao = false;
	let url = `${services.GESTOR}/v1/documentos/gestao_documentos/resumo?query=identificacao=='${identificacao.replaceAll(
		'&',
		'%26'
	)}'`;
	await asyncGetPesquisaDocumentos(url, (e) => {
		if (e.data.content.length > 0 && e.data.content[0].id !== documentoId) {
			existeMesmaIdentificacao = true;
		}
	});
	return existeMesmaIdentificacao;
}

export function exibirConfirmacaoMesmaIdentificacao(onOk) {
	confirm('Confirmação', `Já existe um documento com esta identificação. Tem certeza que deseja salvar?`, onOk);
}

export function exibirConfirmacaoSalvarDocumento(onOk) {
	confirm(
		'Cuidado!',
		`O documento vinculado ao documento está assinado. Ao salvar o documento seu status vai ser alterado para vigente, bloqueando qualquer alteração nas informações.`,
		onOk
	);
}

export function gerarOptionsAlertaVigencia() {
	const options = [];
	for (let i = 1; i <= 90; i++) {
		options.push({ value: i, label: `${i} ${i > 1 ? 'dias' : 'dia'}` });
	}
	return options;
}
