import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { Badge, Button, Grid, NenhumRegistroEncontrado, Paginacao } from 'components';

import { atualizarUrl } from 'views/Util';
import { TABELA_VENDEDORES_VIEW_SITUACAO, TABELA_VENDENDORES_TIPO } from '../../Utils/constantes';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

export function TabelaVendedores({
	handleChangeOrder,
	handleClickDeletar,
	handlePageChange,
	history,
	page,
	registros,
	rows,
	sortField,
	sortOrder,
	totalRecords,
}) {
	const podeExcluir = true;

	function handleClickEditar(row) {
		atualizarUrl(history, '/vendas/vendedores/cadastro', row.id);
	}

	function renderAcoes(row) {
		const titleEditar = 'Editar';
		let titleExcluir = 'Excluir';
		const disableBtnEditar = false;
		let disableBtnExcluir = false;

		if (row?.situacao === 'CANCELADO' || row?.situacao === 'FINALIZADO') {
			titleExcluir = 'Você não pode excluir uma venda cancelado/finalizado';
			disableBtnExcluir = true;
		}

		if (!podeExcluir) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		}

		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={titleEditar}
					disabled={disableBtnEditar}
					onClick={() => handleClickEditar(row)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => handleClickDeletar(row)}
				/>
			</div>
		);
	}

	function renderFieldCodigo(row) {
		return <span title={row.codigo}>{row.codigo}</span>;
	}
	function renderFieldNome(row) {
		return <span title={row.nome}>{row.nome}</span>;
	}

	function renderFieldTipo(row) {
		let label = '-';
		for (const key in TABELA_VENDENDORES_TIPO) {
			if (Object.prototype.hasOwnProperty.call(TABELA_VENDENDORES_TIPO, key)) {
				if (row.tipo === TABELA_VENDENDORES_TIPO[key].value) {
					label = TABELA_VENDENDORES_TIPO[key].label;
					break;
				}
			}
		}
		return <span title={row.tipo}>{label}</span>;
	}

	function renderFieldComissao(row) {
		let comissao = '-';

		if (row.comissao !== null && row.comissao !== undefined && row.comissao > 0) {
			comissao = `${row.comissao} %`;
		}

		return <span title={row.comissao}>{comissao}</span>;
	}

	function renderFieldSituacao(row) {
		const styleBadge = {
			borderRadius: '20px',
			fontWeight: 'bold',
			fontSize: '13px',
			display: 'flex',
			height: '1.5rem',
			width: '7rem',
			alignItems: 'center',
			justifyContent: 'center',
		};
		const situacaoVendedores = TABELA_VENDEDORES_VIEW_SITUACAO[row.situacao];

		return Badge(situacaoVendedores?.strongColor, situacaoVendedores?.lightColor, situacaoVendedores?.name, styleBadge);
	}

	return (
		<Grid style={{ paddingTop: '10px', width: '100%' }} justifyCenter verticalAlignCenter>
			<DataTable
				className="table"
				value={registros}
				responsive
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={handleChangeOrder}
				emptyMessage={<NenhumRegistroEncontrado />}
				style={{ flex: '1', marginLeft: '1rem' }}
			>
				<Column
					header="Código"
					className="step-listagem-order"
					field="codigo"
					sortable
					body={renderFieldCodigo}
					style={{ width: '130px' }}
				/>
				<Column header="Nome" className="step-listagem-order" field="nome" sortable body={renderFieldNome} />
				<Column
					header="Tipo"
					className="step-listagem-order"
					field="tipo"
					sortable
					body={renderFieldTipo}
					style={{ width: '180px' }}
				/>
				<Column
					header="Comissão"
					className="step-listagem-order"
					field="comissao"
					sortable
					body={renderFieldComissao}
					style={{ width: '180px' }}
				/>
				<Column
					header="Situação"
					className="step-listagem-order"
					field="situacao"
					sortable
					body={renderFieldSituacao}
					style={{ width: '200px' }}
				/>
				<Column header="Ações" body={renderAcoes} style={{ width: '100px' }} />
			</DataTable>
			<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={handlePageChange} />
		</Grid>
	);
}
