import { useState, useEffect } from "react";
import { withFormik, Field } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import isValid from "date-fns/isValid";
import isDate from "date-fns/isDate";
import parse from "date-fns/parse";
import isWithinInterval from "date-fns/isWithinInterval";
import { isValidCNPJ, isValidCPF } from "@brazilian-utils/brazilian-utils";
import { validarFormulario } from "../../../../../../../../Util";
import { helpDocumentoConfirmacaoForm } from "./Help";

import {
  mensagensDeValidacao,
  manterApenasNumeros,
  gerarUUID,
  formatarDecimais,
} from "Common";
import {
  FormGroup,
  Grid,
  LayoutMenuSuperior,
  MenuSuperior,
  ButtonNovo,
  estadosBotaoNovo,
  ButtonSalvar,
  ButtonCancelar,
  estadosBotaoCancelar,
  InputField,
  InputMask,
  InputDouble,
  ButtonExcluir,
  Dropdown,
  Paper,
} from "components";

const initialValue = {
  id: "",
  documento: "",
  pergunta: "",
  respostaTipo: "TEXTO",
  respostaEsperada: "",
  idTemporario: "",
};

function DocumentoConfirmacaoFormView(props) {
  const {
    dirty,
    informacoesPermissoes,
    resetForm,
    values,
    registroSelecionado,
    onNovoClick,
    excluirRegistro,
    onHide,
    handleSubmit,
    setFieldValue,
    inserirRegistro,
    disabled,
    handleReset,
  } = props;
  const [inserindoNovoRegistro, setInserindoNovoRegistro] = useState(false);

  const estadoBotaoNovo = dirty
    ? estadosBotaoNovo.SALVAR_E_NOVO
    : estadosBotaoNovo.NOVO;

  function onClickNovo(e) {
    if (dirty) {
      salvar(e, novo());
    } else {
      novo();
    }
  }

  useEffect(() => {
    setInserindoNovoRegistro(!registroSelecionado);

    setTimeout(() => {
      document.getElementById("AssinaturaConfirmacaoInputPergunta")?.focus();
    }, 500);
  }, []);

  function novo() {
    onNovoClick();
    resetForm({ values: initialValue });
    setInserindoNovoRegistro(true);
  }

  function excluir() {
    excluirRegistro(registroSelecionado);
    onHide();
  }

  async function salvar(e, novoOnSuccess) {
    handleSubmit();

    if (await validarFormulario(props)) {
      if (inserindoNovoRegistro) {
        criarRegistro(novoOnSuccess);
      } else {
        atualizarRegistro(novoOnSuccess);
      }
    }
  }

  function criarRegistro(novoOnSuccess) {
    inserirRegistro({
      ...values,
      idTemporario: gerarUUID(),
    });
    if (novoOnSuccess) {
      novoOnSuccess();
    } else {
      onHide();
    }
  }

  function atualizarRegistro(novoOnSuccess) {
    props.atualizarRegistro(values);
    if (novoOnSuccess) {
      novoOnSuccess();
    } else {
      onHide();
    }
  }

  function cancelar() {
    if (dirty) {
      handleReset();
    } else {
      onHide();
    }
  }

  function renderCampoResposta(informacoesPermissoes) {
    const propriedadesPadrao = {
      label: "Resposta esperada ",
      name: "respostaEsperada",
      obrigatorio: true,
      value: values.respostaEsperada,
      helpMessage: helpDocumentoConfirmacaoForm.respostaEsperada,
      disabled: disabled,
      ...informacoesPermissoes,
    };

    switch (values.respostaTipo) {
      case "CNPJ":
        return (
          <Field
            sm="12"
            md="12"
            lg="6"
            xl="6"
            component={InputMask}
            mask="00.000.000/0000-00"
            placeholder="  .   .   /    -  "
            removerMascara={false}
            onChange={(e) => setFieldValue("respostaEsperada", e.target.value)}
            {...propriedadesPadrao}
          />
        );
      case "CPF":
        return (
          <Field
            sm="12"
            md="12"
            lg="6"
            xl="6"
            component={InputMask}
            mask="000.000.000-00"
            placeholder="   .   .   -  "
            removerMascara={false}
            onChange={(e) => setFieldValue("respostaEsperada", e.target.value)}
            {...propriedadesPadrao}
          />
        );
      case "DATA":
        return (
          <Field
            sm="12"
            md="12"
            lg="6"
            xl="6"
            component={InputMask}
            mask="00/00/0000"
            removerMascara={false}
            placeholder="  /  /    "
            onChange={(e) => setFieldValue("respostaEsperada", e.target.value)}
            {...propriedadesPadrao}
          />
        );
      case "NUMERO":
        return (
          <Field
            sm="12"
            md="12"
            lg="6"
            xl="6"
            component={InputDouble}
            size={15}
            onChange={(e) =>
              setFieldValue(
                "respostaEsperada",
                formatarDecimais(e.target.value)
              )
            }
            decimalScale={2}
            {...propriedadesPadrao}
          />
        );
      case "SIM_NAO":
        return (
          <Field
            sm="12"
            md="12"
            lg="6"
            xl="6"
            component={Dropdown}
            options={[
              { label: "Sim", value: "true" },
              { label: "Não", value: "false" },
            ]}
            showClear={false}
            onChange={(e) => setFieldValue("respostaEsperada", e.value)}
            {...propriedadesPadrao}
          />
        );
      case "TEXTO":
        return (
          <Field
            sm="12"
            md="12"
            lg="6"
            xl="6"
            component={InputField}
            size={255}
            {...propriedadesPadrao}
          />
        );
      default:
        return null;
    }
  }

  function onChangeTipoResposta(respostaTipo) {
    if (respostaTipo?.value !== props.values.respostaTipo) {
      setFieldValue("respostaEsperada", null);
    }
    setFieldValue("respostaTipo", respostaTipo?.value);
  }

  return (
    <>
      <MenuSuperior isModal={true}>
        <ButtonCancelar
          {...informacoesPermissoes}
          estadoBotao={
            dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR
          }
          onClick={() => cancelar()}
        />
        <ButtonSalvar
          {...informacoesPermissoes}
          disabled={!dirty}
          onClick={() => salvar()}
        />
        <ButtonNovo
          onClick={(e) => onClickNovo(e)}
          hidden={!dirty && !values.id}
          disabled={disabled}
          estadoBotao={estadoBotaoNovo}
          {...informacoesPermissoes}
        />
        <ButtonExcluir
          hidden={inserindoNovoRegistro}
          podeExcluir={informacoesPermissoes.podeEditar}
          disabled={disabled}
          onClick={(e) => excluir(e)}
        />
      </MenuSuperior>
      <LayoutMenuSuperior isModal={true}>
        <Paper dirty={dirty} {...informacoesPermissoes} childsOnly={true}>
          <FormGroup>
            <Grid>
              <Field
                sm="12"
                md="12"
                lg="12"
                xl="12"
                component={InputField}
                label="Pergunta para confirmação "
                obrigatorio
                name="pergunta"
                size={255}
                disabled={disabled}
                helpMessage={helpDocumentoConfirmacaoForm.pergunta}
                id="AssinaturaConfirmacaoInputPergunta"
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="12"
                lg="6"
                xl="6"
                component={Dropdown}
                label="Tipo de resposta "
                obrigatorio
                options={[
                  { label: "CNPJ", value: "CNPJ" },
                  { label: "CPF", value: "CPF" },
                  { label: "Data", value: "DATA" },
                  { label: "Número", value: "NUMERO" },
                  { label: "Sim/Não", value: "SIM_NAO" },
                  { label: "Texto", value: "TEXTO" },
                ]}
                showClear={false}
                name="respostaTipo"
                value={values.respostaTipo}
                onChange={(e) => onChangeTipoResposta(e)}
                helpMessage={helpDocumentoConfirmacaoForm.respostaTipo}
                disabled={disabled}
                {...informacoesPermissoes}
              />
              {renderCampoResposta(informacoesPermissoes)}
            </Grid>
          </FormGroup>
        </Paper>
      </LayoutMenuSuperior>
    </>
  );
}

const DocumentoConfirmacaoForm = withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,

  mapPropsToValues(props) {
    if (props.registroSelecionado) {
      return { ...props.registroSelecionado };
    }
    return { ...initialValue };
  },

  async validate(values) {
    let errors = {};

    switch (values.respostaTipo) {
      case "CNPJ":
        if (
          values.respostaEsperada &&
          !isValidCNPJ(manterApenasNumeros(values.respostaEsperada))
        )
          errors.respostaEsperada = "Digite um CNPJ válido.";
        break;
      case "CPF":
        if (
          values.respostaEsperada &&
          !isValidCPF(manterApenasNumeros(values.respostaEsperada))
        )
          errors.respostaEsperada = "Digite um CPF válido.";
        break;
      case "DATA": {
        if (values.respostaEsperada) {
          const dataParaConfirmacao = parse(
            values.respostaEsperada,
            "dd/MM/yyyy",
            new Date()
          );
          if (isDate(dataParaConfirmacao) && isValid(dataParaConfirmacao)) {
            if (
              !isWithinInterval(dataParaConfirmacao, {
                start: new Date(1900, 1, 1),
                end: new Date(2100, 1, 1),
              })
            ) {
              errors.respostaEsperada = "Digite uma data válida.";
            }
          } else {
            errors.respostaEsperada = "Digite uma data válida.";
          }
        }
        break;
      }
      default:
        break;
    }

    return errors;
  },

  validationSchema: Yup.object().shape({
    pergunta: Yup.string()
      .nullable()
      .required(mensagensDeValidacao.OBRIGATORIO),
    respostaTipo: Yup.string()
      .nullable()
      .required(mensagensDeValidacao.OBRIGATORIO),
    respostaEsperada: Yup.string()
      .nullable()
      .required(mensagensDeValidacao.OBRIGATORIO),
  }),
  handleSubmit: () => {},
})(DocumentoConfirmacaoFormView);

export default withRouter(DocumentoConfirmacaoForm);
