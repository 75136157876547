const styleLocal = {
	margin: 0,
	padding: 0,
	color: '#006095',
	fontSize: '12px',
	display: 'flex',
	position: 'absolute',
};

function LinkVerDados(props) {
	const { label, onClick, style } = props;
	return (
		<p style={{ ...styleLocal, ...style }} onClick={onClick} className="link_to">
			{label}
		</p>
	);
}

export default LinkVerDados;
