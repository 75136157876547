import { Field } from 'formik';
import { InputField } from '../../../../../../components';
import { helpFieldText } from './Help';

export function renderizarFieldsEstrangeiro(values, informacoesPermissoes) {
	return (
		<>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputField}
				type="text"
				label="Identificação"
				name="identificacao"
				helpMessage={helpFieldText.identificacao}
				size={255}
				value={values.identificacao}
				{...informacoesPermissoes}
			/>
		</>
	);
}
