import { copiarObjeto, formatarTelefone, PAPEL_SIGNATARIOS, removerElementoPorId } from 'Common';
import { ButtonEditarTable, ButtonExcluirTable, If, Message, Modal, NenhumRegistroEncontrado } from 'components';
import { useFormikContext, withFormik } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { Prompt } from 'react-router-dom/cjs/react-router-dom.min';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { ActionButtons } from './ActionButtons';
import { FormModalSignatarioPadrao } from './ModalFormSignatariosPadrao';
import { INITIAL_VALUES } from './Util/constantes';

function ModalSignatarioPadraoView({ visible, onHide, handleSalvarSignatarioPadrao }) {
	const { values, dirty, initialValues, setFieldValue, resetForm } = useFormikContext();
	const { isMobile, informacoesPermissoes } = useContextConfiguracoesGerais();

	const [modalFormSignatarioPadraoVisible, setModalFormSignatarioPadraoVisible] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);

	const acoesStyle = !isMobile && { textAlign: 'end' };

	function novoSignatario() {
		setModalFormSignatarioPadraoVisible(true);
		setRegistroSelecionado(null);
	}

	function onEditar(row) {
		setModalFormSignatarioPadraoVisible(true);
		setRegistroSelecionado(row);
	}

	function renderPapel(row) {
		return PAPEL_SIGNATARIOS?.find((papel) => papel?.value === row?.papel)?.label;
	}

	function renderNome(row) {
		if (row.nome) {
			return row.nome;
		} else {
			return row.pessoa.registro.nome;
		}
	}

	function renderEmail(row) {
		if (row.email) {
			return row.email;
		} else {
			return row.pessoaEmail.registro.email;
		}
	}

	function renderTelefone(row) {
		if (!row.telefone) {
			return ' - ';
		}

		if (row.telefone) {
			return formatarTelefone(row.telefone);
		} else {
			return formatarTelefone(row.pessoaTelefone.registro.telefone);
		}
	}

	function renderOpcoes(row) {
		return (
			<div>
				<ButtonEditarTable onClick={() => onEditar(row)} {...informacoesPermissoes} />
				<ButtonExcluirTable onClick={() => excluirRegistro(row)} podeExcluir={informacoesPermissoes.podeEditar} />
			</div>
		);
	}

	function excluirRegistro(dadosFormulario) {
		const dadosAtualizados = removerElementoPorId(values.signatariosPadrao, dadosFormulario, 'id');
		setRegistroSelecionado(null);
		setFieldValue('signatariosPadrao', dadosAtualizados);
	}

	function inserirRegistro(dadosFormulario) {
		const registrosAtualizados = [...(values.signatariosPadrao ?? []), dadosFormulario];
		setFieldValue('signatariosPadrao', registrosAtualizados);
		setRegistroSelecionado(null);
		setModalFormSignatarioPadraoVisible(false);
	}

	function atualizarRegistro(dadosFormulario) {
		const dadosAtualizados = copiarObjeto(values.signatariosPadrao);

		if (dadosFormulario.id !== null) {
			const index = dadosAtualizados.findIndex((obj) => obj.id === dadosFormulario.id);

			if (index !== -1) {
				dadosAtualizados[index] = dadosFormulario;
			}
		} else if (dadosFormulario.tempId !== null) {
			const index = dadosAtualizados.findIndex((obj) => obj.tempId === dadosFormulario.tempId);

			if (index !== -1) {
				dadosAtualizados[index] = dadosFormulario;
			}
		}

		setFieldValue('signatariosPadrao', dadosAtualizados);
		setRegistroSelecionado(null);
		setModalFormSignatarioPadraoVisible(false);
	}

	function esconderMessageEmail() {
		if (!values) {
			return true;
		}

		for (let i = 0; i < values.length; i++) {
			if (!values[i].email) {
				return false;
			}
		}
		return true;
	}

	function handleHideFormSignatarioPadrao() {
		setModalFormSignatarioPadraoVisible(false);
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			onHide();
		}
	}

	function salvar() {
		handleSalvarSignatarioPadrao(values.signatariosPadrao);
	}

	return (
		<>
			<Modal visible={visible} header="Signatários padrão" onHide={onHide}>
				<Prompt dirty={dirty} />
				<ActionButtons novoSignatario={novoSignatario} cancelar={cancelar} salvar={salvar} />
				<Message
					hidden={esconderMessageEmail()}
					severity="error"
					text="Informe um e-mail válido para todos os signatários"
				/>
				<DataTable
					className="table"
					responsive
					value={values.signatariosPadrao}
					emptyMessage={<NenhumRegistroEncontrado message="Nenhum signatário encontrado" />}
				>
					<Column field="nome" header="Nome" body={(row) => renderNome(row)} sortable style={{ width: '25rem' }} />
					<Column field="email" header="E-mail" body={(row) => renderEmail(row)} sortable style={{ width: '20rem' }} />
					<Column
						field="telefone"
						header="Telefone"
						body={(row) => renderTelefone(row)}
						sortable
						style={{ width: '15rem' }}
					/>
					<Column field="papel" body={(row) => renderPapel(row)} header="Assinar como" sortable />
					<Column header="Ações" body={(row) => renderOpcoes(row)} style={{ width: '8rem', ...acoesStyle }} />
				</DataTable>
			</Modal>
			<If test={modalFormSignatarioPadraoVisible}>
				<FormModalSignatarioPadrao
					visible={modalFormSignatarioPadraoVisible}
					registroSelecionado={registroSelecionado}
					onNovoClick={() => setRegistroSelecionado(null)}
					excluirRegistro={(e) => excluirRegistro(e)}
					inserirRegistro={(e) => inserirRegistro(e)}
					atualizarRegistro={(e) => atualizarRegistro(e)}
					onHide={handleHideFormSignatarioPadrao}
				/>
			</If>
		</>
	);
}

const ModalSignatarioPadrao = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.values) {
			return { signatariosPadrao: props.values };
		}
		return INITIAL_VALUES;
	},

	handleSubmit: () => {},
})(ModalSignatarioPadraoView);

export { ModalSignatarioPadrao };
