export const ContaPagarStatus = {
    NAO_PAGA: "NAO_PAGA",
    PARCIALMENTE_PAGA: "PARCIALMENTE_PAGA",
    PAGA: "PAGA",

}

export const ContaReceberStatus = {
    PARCIALMENTE_RECEBIDA: "PARCIALMENTE_RECEBIDA",
    NAO_RECEBIDA: "NAO_RECEBIDA",
    RECEBIDA: "RECEBIDA",
}

export const ContaOrigem = {
    CONTA_RECEBER: "CONTA_RECEBER",
    CONTA_PAGAR: "CONTA_PAGAR",
}

