import { NenhumRegistroEncontrado, TabView } from 'components';
import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';
import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { useContextConfiguracoesGerais } from '../../Context';
import { TabConfiguracoesMdfe } from './components/TabConfiguracoesMdfe';
import { TabConfiguracoesNfce } from './components/TabConfiguracoesNfce';
import { TabConfiguracoesNfe } from './components/TabConfiguracoesNfe';
import { TabConfiguracoesNfse } from './components/TabConfiguracoesNfse';

function TabsConfiguracoesFiscais() {
	const { values } = useFormikContext();
	const { tabConfiguracoesFiscais, setTabConfiguracoesFiscais, location } = useContextConfiguracoesGerais();

	useEffectOnce(() => {
		handleSelectTabInicial();
	});

	const findNextAvailableTab = () => {
		for (let i = 0; i < 4; i++) {
			if (values[`emite${['Nfe', 'Nfce', 'Nfse', 'Mdfe'][i]}`]) {
				return i;
			}
		}
		return 4;
	};

	const handleSelectTabInicial = (index = 0, manual = false) => {
		if (!location.state) {
			const nextTab = findNextAvailableTab();
			let tabIndex;
			if (manual) {
				tabIndex = index;
			} else if (nextTab !== 4) {
				tabIndex = nextTab;
			} else {
				tabIndex = 4;
			}
			setTabConfiguracoesFiscais(tabIndex);
		}
	};

	useEffect(() => {
		const currentTabEnabled = values[`emite${['Nfe', 'Nfce', 'Nfse', 'Mdfe'][tabConfiguracoesFiscais]}`];
		if (!currentTabEnabled) {
			handleSelectTabInicial();
		}
	}, [values, tabConfiguracoesFiscais]);

	return (
		<TabView
			className="tab-view-last-hidden"
			activeIndex={tabConfiguracoesFiscais}
			onTabChange={({ index }) => {
				handleSelectTabInicial(index, true);
			}}
			renderActiveOnly={false}
		>
			<TabPanel header="Configurações NF-e" disabled={!values.emiteNfe} title="Configurações NF-e">
				<TabConfiguracoesNfe />
			</TabPanel>
			<TabPanel header="Configurações NFC-e" disabled={!values.emiteNfce}>
				<TabConfiguracoesNfce />
			</TabPanel>
			<TabPanel header="Configurações NFS-e" disabled={!values.emiteNfse}>
				<TabConfiguracoesNfse />
			</TabPanel>
			<TabPanel header="Configurações MDF-e" disabled={!values.emiteMdfe}>
				<TabConfiguracoesMdfe />
			</TabPanel>
			<TabPanel header="" disabled contentStyle={{ paddingBottom: '8px' }}>
				<NenhumRegistroEncontrado message="Para ativar as configurações fiscais, você precisa selecionar pelo menos uma opção. Marque o checkbox correspondente acima." />
			</TabPanel>
		</TabView>
	);
}

export { TabsConfiguracoesFiscais };
