import { get, post } from '../../../../Common/Requisicoes';
import { formatISO } from 'date-fns';
import { services } from '../../../../Common/Constantes/api';

export async function asyncGetTotalizadores(interval, onSuccess, onError) {
	if (interval.dataInicial && interval.dataFinal) {
		await get(
			`${services.GESTOR}/v1/mde/totais?dataInicial=${formatISO(interval.dataInicial, {
				representation: 'date',
			})}&dataFinal=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`,
			null,
			onSuccess,
			onError
		);
	}
}

export async function asyncGetRegistros(url, onSuccess, onError) {
	await get(url, null, onSuccess, onError, true);
}

export async function asyncGetConsultaSefaz(onSuccess, onError) {
	await get(`${services.GESTOR}/v1/mde/consulta_sefaz`, null, onSuccess, onError, true, false);
}

export async function asyncGetConsultaSefazChaveAcesso(chaveAcesso, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/mde/consulta_sefaz?chave_acesso=${chaveAcesso}`,
		null,
		onSuccess,
		onError,
		true,
		false
	);
}

export async function asyncPostManifestar(id, dados, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/mde/${id}/manifestar`, dados, null, onSuccess, onError);
}

export async function asyncDownloadXmlNfe(id, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/mde/${id}/consulta_xml_nfe`, null, onSuccess, onError, true, false);
}

export async function asyncDownloadXmlManifestacao(id, idManifestacao, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/mde/${id}/manifestacao/${idManifestacao}/xml`, null, onSuccess, onError);
}

export async function asyncGetHistoricoMde(url, onSuccess, onError) {
	await get(url, null, onSuccess, onError);
}

export async function asyncGetValidateConsulta(onSuccess, onError) {
	await get(`${services.GESTOR}/v1/mde/validate_consulta`, null, onSuccess, onError, true, false);
}
