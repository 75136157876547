import { FormikProvider, useFormik } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { ButtonExcluirTable, InputField, NenhumRegistroEncontrado } from 'components';
import { buscarDadosLoginLocalStorage, formatarMonetarioDecimais } from 'Common';

import { useContextEtiqueta } from '../../Context';

function DataTableProdutos({ isMobile, isTablet }) {
	const { registros, setRegistros, sortField, setSortField, sortOrder, setSortOrder, setNotasImportadas, podeGerar } =
		useContextEtiqueta();

	const { decimaisPreco = 2 } = buscarDadosLoginLocalStorage().filialConectada.parametrosCadastros;

	const formik = useFormik({
		initialValues: {
			quantidade: 1,
		},
		onSubmit: () => {},
	});

	function renderQuantidade(row) {
		return (
			<FormikProvider value={formik}>
				<InputField
					value={row.quantidade}
					onBlur={() => {
						row.quantidade == null ? handleChangeQuandidade({ target: { value: 0 } }, row) : null;
					}}
					type="number"
					colStyle={isMobile || isTablet ? { width: '8rem', padding: '0' } : { padding: '0' }}
					onChange={(event) => handleChangeQuandidade(event, row)}
				/>
			</FormikProvider>
		);
	}

	function renderAcao(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonExcluirTable onClick={() => handleClickExcluir(row)} />
			</div>
		);
	}

	function handleChangeSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function handleChangeQuandidade(event, row) {
		setRegistros((state) =>
			state.map((e) => {
				if (e.id === row.id) {
					return { ...e, quantidade: event.target.value };
				}
				return e;
			})
		);
	}

	function handleClickExcluir(row) {
		setRegistros((state) => {
			setNotasImportadas((prevNotas) =>
				prevNotas.filter((nota) => state.some((item) => item.id !== row.id && item.notas.includes(nota.id)))
			);
			return state.filter((item) => item.id !== row.id);
		});
	}

	return (
		<DataTable
			className="table"
			rowClassName="table-row"
			cellClassName="table-row-cell"
			responsive
			value={registros}
			sortField={sortField}
			sortOrder={sortOrder}
			onSort={handleChangeSort}
			emptyMessage={
				<NenhumRegistroEncontrado
					message={!podeGerar ? 'Usuário sem permissão para gerar etiquetas' : 'Nenhum produto selecionado'}
				/>
			}
		>
			<Column field="codigo" header="Código" body={(row) => row.codigo} sortable style={{ width: '8rem' }} />
			<Column field="nome" header="Nome" body={(row) => row.nome} sortable style={{ width: '100%' }} />
			<Column
				field="precoVenda"
				header="Preço de venda"
				body={(row) => formatarMonetarioDecimais(row.precoVenda, decimaisPreco)}
				sortable
				style={{ width: '10rem' }}
			/>
			<Column
				field="precoAtacado"
				header="Preço atacado"
				body={(row) => formatarMonetarioDecimais(row.precoAtacado, decimaisPreco)}
				sortable
				style={{ width: '10rem' }}
			/>
			<Column
				className="step-etiqueta-impressao-quantidade"
				field="quantidade"
				header="Quantidade"
				body={renderQuantidade}
				style={{ width: '12rem' }}
			/>
			<Column header="Ação" body={renderAcao} style={{ width: '4rem' }} />
		</DataTable>
	);
}

export { DataTableProdutos };
