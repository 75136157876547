export const OPCOES_CODIGO = [
  {
    label: "Código",
    value: "CODIGO",
  },
  {
    label: "Referência",
    value: "REFERENCIA",
  },
];

export const OPCOES_ORDENACAO = [
  {
    label: "Código",
    value: "CODIGO",
  },
  {
    label: "Referência",
    value: "REFERENCIA",
  },
  {
    label: "Nome",
    value: "NOME",
  },
];
