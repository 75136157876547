export const CORES_CARDS = {
	SUCCESS: {
		light: '#DCEDC8',
		strongColor: '#1B5E20',
	},
	DANGER: {
		light: '#FFCDD2',
		strongColor: '#B71C1C',
	},
	WARNING: {
		light: '#FFECB3',
		strongColor: '#765100',
	},
	INFO: {
		light: '#B3E5FC',
		strongColor: '#006095',
	},
	NEUTRAL: {
		light: '#E7ECF0',
		strongColor: '#333333',
	},
};

export const STYLE_BUTTONS = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

export const INITIAL_VALUES = {
	titulos: [],
	totais: { titulos: 0, juros: 0, multa: 0, desconto: 0, quitado: 0 },
	juros: 0,
	multa: 0,
	desconto: 0,
	conta: null,
	formaPagamento: null,
};
