import Button from '..';
import propTypes from 'prop-types';

export const estadosBotaoCancelar = {
	CANCELAR: 'CANCELAR',
	VOLTAR: 'VOLTAR',
};

function ButtonCancelar(props) {
	const { estadoBotao, disabled, title, label, id, onClick, hidden, enableShortCut, shortCut, raised, rounded, style } =
		props;

	const styleButton = {
		border: '1px solid #0000001a',
		margin: '5px 5px 5px 0',
		color: '#333',
		boxShadow: 'none',
		...style,
	};

	let defaultLabel = estadoBotao === estadosBotaoCancelar.VOLTAR ? 'Voltar' : 'Cancelar';

	let icon = estadoBotao === estadosBotaoCancelar.VOLTAR ? 'fa fa-arrow-left' : 'fa fa-ban';

	let labelFormatado = formatarLabel();

	function formatarLabel() {
		let labelTemp = '';

		label ? (labelTemp = label) : (labelTemp = defaultLabel);

		enableShortCut && shortCut ? (labelTemp += ` [${shortCut}]`) : null;

		return labelTemp;
	}

	return (
		<Button
			label={labelFormatado}
			title={title}
			id={id || 'idButtonCancelar'}
			color="secondary"
			type="button"
			style={styleButton}
			onClick={onClick}
			icon={icon}
			disabled={disabled}
			raised={raised}
			rounded={rounded}
			hidden={hidden}
			hotKey={['alt', 'c']}
			enableShortCut={enableShortCut}
		/>
	);
}

ButtonCancelar.defaultProps = {
	hidden: false,
	disabled: false,
};

ButtonCancelar.propTypes = {
	/** Id do componente */
	id: propTypes.string,
	/** Derfine o botão como invisível */
	hidden: propTypes.bool,
	/** Ação executada ao clicar no botão */
	onClick: propTypes.func,
	/** Define o botão como desabilitado */
	disabled: propTypes.bool,
	/** Habilitar atalho */
	enableShortCut: propTypes.bool,
	/** Especifica o label do botão*/
	estadoBotao: propTypes.oneOf([estadosBotaoCancelar.VOLTAR, estadosBotaoCancelar.CANCELAR]),
};

export default ButtonCancelar;
