function converterRelacaoParaApi(data) {
	return {
		id: data.id,
		produto: { id: data.produto.value },
		pessoa: { id: data.fornecedor.id },
		referencia: data.referencia,
		cfop: { id: data.cfop.registro.id },
		fatorConversao: data.fatorConversao,
		operacaoFatorConversao: data.operacaoFatorConversao,
	};
}

export default converterRelacaoParaApi;
