export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é o cadastro de pessoas, nele você pode adicionar os clientes e/ou fornecedores que vão ser usados nos demais módulos do sistema`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-pessoa-tipo',
		content:
			'O primeiro passo é selecionar qual vai ser o tipo do cadastro, se é um cliente, fornecedor ou transportador. É possível selecionar mais de uma opção.',
	},
	{
		target: '.step-pessoa-informacoes',
		content:
			'Aqui serão incluídas as informações de nome, situação do cadastro e se é  uma pessoa física ou jurídica',
	},
	{
		target: '.step-pessoa-tabs',
		content:
			'Além do cadastro do nome, situação e tipo, os demais dados estão separadas em abas, para uma melhor organização das informações.',
	},
];
