/* eslint-disable no-unused-vars */
import { CardTotalizadorListagem } from 'components';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { CORES_CARDS } from 'views/financas/components/ModalReparcelamentoTitulos/Utils/constantes';

const styleCard = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-evenly',
};

function CardsTotalizadores({ isMobile }) {
	const { values } = useFormikContext();

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr 1fr',
				margin: '10px 0 0 0',
				padding: 0,
				gap: '10px',
			}}
		>
			<CardTotalizadorListagem
				styleCard={styleCard}
				name="cardValorTitulos"
				title="Valor títulos"
				helpMessage="Soma dos valores de todos os títulos selecionados"
				icon={<FaCheckCircle size={22} color={CORES_CARDS.NEUTRAL} />}
				primaryColor={CORES_CARDS.NEUTRAL}
				colors={CORES_CARDS.NEUTRAL}
				value={values.valorTitulos}
			/>

			<CardTotalizadorListagem
				styleCard={styleCard}
				name="cardValorJuros"
				title="Juros"
				helpMessage="Soma dos juros dos títulos selecionados"
				icon={<FaCheckCircle size={22} color={CORES_CARDS.DANGER} />}
				primaryColor={CORES_CARDS.DANGER}
				colors={CORES_CARDS.DANGER}
				value={values.valorJuros}
			/>

			<CardTotalizadorListagem
				styleCard={styleCard}
				name="cardValorMulta"
				title="Multa"
				helpMessage="Soma das multas dos títulos selecionados"
				icon={<FaCheckCircle size={22} color={CORES_CARDS.DANGER} />}
				primaryColor={CORES_CARDS.DANGER}
				colors={CORES_CARDS.DANGER}
				value={values.valorMulta}
			/>

			<CardTotalizadorListagem
				styleCard={styleCard}
				name="cardValorDesconto"
				title="Desconto"
				helpMessage="Soma dos descontos dos títulos selecionados"
				icon={<FaCheckCircle size={22} color={CORES_CARDS.INFO} />}
				primaryColor={CORES_CARDS.INFO}
				colors={CORES_CARDS.INFO}
				value={values.valorDesconto}
			/>

			<CardTotalizadorListagem
				styleCard={styleCard}
				name="cardValorRecebido"
				title="Valor título novo"
				helpMessage="Soma dos valores para o novo título"
				icon={<FaCheckCircle size={22} color={CORES_CARDS.SUCCESS} />}
				primaryColor={CORES_CARDS.SUCCESS}
				colors={CORES_CARDS.SUCCESS}
				value={values.valorTitulosNovos}
			/>
		</div>
	);
}

export default CardsTotalizadores;
