import { Field } from 'formik';
import { Dropdown, InputDate } from '../../../../../../components';
import {
	OptionsClassificacaoContribuinteIpi,
	OptionsIndicacaoAtividade,
	OptionsPerfil,
} from '../../../Util/constantes';

function InformacoesIniciais(props) {
	const { values, podeGerar, setFieldValue, isTablet } = props;
	return (
		<>
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				component={InputDate}
				label="Data inicial"
				name="dataInicial"
				value={values.dataInicial}
				obrigatorio
				disabled={!podeGerar}
			/>
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				component={InputDate}
				label="Data final"
				name="dataFinal"
				value={values.dataFinal}
				obrigatorio
				disabled={!podeGerar}
			/>
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				component={Dropdown}
				label="Perfil"
				name="perfil"
				options={OptionsPerfil}
				onChange={event => setFieldValue('perfil', event)}
				value={values.perfil}
				obrigatorio
				showClear={false}
				disabled={!podeGerar}
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={Dropdown}
				label="Atividade"
				name="indicacaoAtividade"
				options={OptionsIndicacaoAtividade}
				onChange={event => setFieldValue('indicacaoAtividade', event)}
				value={values.indicacaoAtividade}
				obrigatorio
				showClear={false}
				disabled={!podeGerar}
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={Dropdown}
				label={isTablet ? 'Classificação do IPI' : 'Classificação de contribuinte do IPI'}
				name="classificacaoContribuinteIpi"
				options={OptionsClassificacaoContribuinteIpi}
				onChange={event => setFieldValue('classificacaoContribuinteIpi', event)}
				value={values.classificacaoContribuinteIpi}
				obrigatorio={values.indicacaoAtividade?.value === 'IND_OU_EQUIPARADO_A_IND'}
				disabled={values.indicacaoAtividade?.value !== 'IND_OU_EQUIPARADO_A_IND' || !podeGerar}
				showClear={false}
			/>
		</>
	);
}

export default InformacoesIniciais;
