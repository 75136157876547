export function converterParaFormulario(values) {
	return {
		modeloBalanca:values.balanca.modelo,
        portaBalanca:values.balanca.porta,
        velocidadeBalanca:values.balanca.velocidade,
        tamanhoBalanca:values.balanca.tamanho,
        stopBitsBalanca:values.balanca.stopbits,
        paridadeBalanca:values.balanca.paridade ? values.balanca.paridade : 'None',
        mostrarPreviewImpressao:values.impressao.preview ? values.impressao.preview : false,
        impressoraImpressao:values.impressao.impressora,
        impressoraEtiqueta:values.etiqueta.impressora
	};
}

export function converterParaApi(values) {
	return {
        balanca:{
            modelo:values.modeloBalanca,
            porta:values.portaBalanca,
            velocidade:values.velocidadeBalanca,
            tamanho:values.tamanhoBalanca,         
            stopbits:values.stopBitsBalanca,
            paridade:values.paridadeBalanca,
        },
        impressao:{
            preview: values.mostrarPreviewImpressao,
            impressora: values.impressoraImpressao,
        },
        etiqueta:{          
            impressora: values.impressoraEtiqueta,
        }        
	};
}