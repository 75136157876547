function converteCobrancasParaFormulario(cobrancas) {
  const cobrancasConvertidas = [];

  if (cobrancas?.length > 0) {
    cobrancas.forEach(cobranca => {
      cobrancasConvertidas.push({
        id: cobranca.id,
        identificador: cobranca.identificador,
        descricao: cobranca.descricao,
        emissao: cobranca.emissao,
        vencimento: cobranca.vencimento,
        pessoa: cobranca.pessoa ? cobranca.pessoa.nome : null,
        pessoaCodigo: cobranca.pessoa ? cobranca.pessoa.codigo : null,
        pessoaEmail: converterPessoaEmailParaFormulario(cobranca.pessoa?.emails),
        valor: cobranca.valor,
        aReceber: cobranca.valorAReceber,
        categoria: cobranca.categoria
          ? {
              nome: cobranca.categoria.nome,
              aparencia: cobranca.categoria.aparencia,
            }
          : null,
        pix: cobranca.pix ? cobranca.pix : null,
      });
    });
  }
  return cobrancasConvertidas;
}

function converterPessoaEmailParaFormulario(emails) {
  let emailFavorito = '';
  if (emails?.length > 0) {
    emails?.forEach(email => {
      emailFavorito = email.favorito === true ? `${email.email}` : null;
    });
  }

  if (emailFavorito) {
    return emailFavorito;
  } else if (emails?.length > 0) {
    return emails[0]?.email;
  }
  return null;
}

function converterCobrancasEmailParaApi(email) {
  const convertedEmail = {
    assunto: email.assunto,
    corpoEmail: email.corpoEmail,
    destinatarios: converterDestinatariosEmailParaApi(email.destinatarios),
    contaReceberIds: email.contaReceberIds,
  };

  return convertedEmail;
}

function converterDestinatariosEmailParaApi(destinatarios) {
  return destinatarios
    .split(',')
    .map(item => item.trim())
    .filter(item => item.trim() !== '');
}

export { converteCobrancasParaFormulario, converterCobrancasEmailParaApi };
