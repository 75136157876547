import { Campo, infoStatusNfce } from './constantes';

export function defineColorStatus(situacao) {
	return infoStatusNfce[situacao] ? infoStatusNfce[situacao]?.strongColor : infoStatusNfce['NAO_ENVIADA']?.strongColor;
}

export function rateioValorEntreProdutosNFCe(produtos, totalProdutos, campo, valor) {
	let itens = produtos;
	let somaRateado = 0;

	itens.map(item => {
		if (campo === Campo.DESCONTO) {
			item[campo] = parseFloat(
				((item?.quantidade * (item?.valor - item?.descontoItem) * valor) / totalProdutos).toFixed(2)
			);
			item['desconto'] = parseFloat((item[campo] + item?.descontoItem).toFixed(2));
		} else if (campo === Campo.ACESSORIAS) {
			item[campo] = parseFloat(((item?.quantidade * item?.valor * valor) / totalProdutos).toFixed(2));
			item['acessorias'] = parseFloat((item[campo] + item?.acessoriasItem).toFixed(2));
		}

		somaRateado += item[campo];
	});

	if (valor - somaRateado !== 0) {
		if (itens.length > 0) {
			if (campo === Campo.DESCONTO) {
				itens[0][campo] = parseFloat((itens[0][campo] - parseFloat((somaRateado - valor).toFixed(2))).toFixed(2));
				itens[0]['desconto'] = parseFloat((itens[0][campo] + itens[0]['descontoItem']).toFixed(2));
			} else if (campo === Campo.ACESSORIAS) {
				itens[0][campo] = parseFloat((itens[0][campo] - parseFloat((somaRateado - valor).toFixed(2))).toFixed(2));
				itens[0]['acessorias'] = parseFloat((itens[0][campo] + itens[0]['acessoriasItem']).toFixed(2));
			}
		}
	}

	return itens;
}
