import { Field, useFormikContext, withFormik } from 'formik';

import { keyFilterConsultaRsql, mensagensDeValidacao } from 'Common';
import {
	ButtonCancelar,
	ButtonSalvar,
	Form,
	FormActions,
	FormContent,
	Grid,
	Modal,
	ToastTypes,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
	notify,
} from 'components';

import MultipleSelectProdutosGrupo from 'components/select/MultipleSelectProdutosGrupo';
import { validarFormulario } from 'views/Util';
import { useContextTabelaPreco } from 'views/cadastros/TabelaPreco/Context';
import { importarProdutosPorGrupoTabelaPreco } from 'views/cadastros/TabelaPreco/Requests';
import { converterGrupoProdutosParaApi } from 'views/cadastros/TabelaPreco/Util/TabelaPrecoProdutoConverter';

function ModalImportarProdutosPorGrupoImpl({ visible, onCloseModal, fetch, idTabelaPreco }) {
	const { values, dirty, handleReset, handleSubmit, validateForm, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, desabilitarCampos } = useContextTabelaPreco();

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			onCloseModal();
		}
	}

	async function handleClickSalvar() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			await importarProdutosPorGrupo();

			onCloseModal();
		}
	}

	async function importarProdutosPorGrupo() {
		importarProdutosPorGrupoTabelaPreco(
			idTabelaPreco,
			converterGrupoProdutosParaApi(values.grupos),
			() => {
				fetch();
			},
			() => {
				notify('Erro desconhecido ao importar produtos por grupo', ToastTypes.ERROR);
			}
		);
	}

	function onChangeProdutosGrupo(e) {
		setFieldValue('grupos', e);
	}

	return (
		<Modal header="Importar produtos por grupo" visible={visible} onHide={onCloseModal}>
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={handleClickCancelar}
						{...informacoesPermissoes}
					/>
					<ButtonSalvar
						estadoBotao={estadosBotaoSalvar.SALVAR}
						disabled={!dirty || desabilitarCampos}
						onClick={handleClickSalvar}
						{...informacoesPermissoes}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={MultipleSelectProdutosGrupo}
							label="Grupos"
							name="grupos"
							obrigatorio
							value={values.grupos}
							onChange={onChangeProdutosGrupo}
							keyfilter={keyFilterConsultaRsql}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalImportarProdutosPorGrupo = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return { grupos: [] };
	},

	validate(values) {
		const errors = {};

		if (values.grupos?.length <= 0) {
			errors.grupos = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},
	handleSubmit: () => {},
})(ModalImportarProdutosPorGrupoImpl);
export { ModalImportarProdutosPorGrupo };
