import { useFormikContext } from 'formik';
import { useContextOrdemServico } from 'views/fiscal/servicos/OrdemServico/Context';
import {
  ORDEM_SERVICO_ORCAMENTO_SITUACAO,
  SITUACAO_ORDEM_SERVICO,
} from 'views/fiscal/servicos/OrdemServico/Util/constantes';

const styleStatus = {
  height: '38px',
  borderRadius: '25px',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  padding: '0 7rem',
};

const styleName = {
  textAlign: 'center',
  fontWeight: 'bold',
  padding: '2px 0px',
};

export function SituacaoCard({ isMobile }) {
  const { color } = useContextOrdemServico();
  const { values } = useFormikContext();

  let label = '';
  let title = '';

  switch (values.situacao) {
    case SITUACAO_ORDEM_SERVICO.FINALIZADO: {
      label = 'Finalizado';
      break;
    }
    case SITUACAO_ORDEM_SERVICO.CANCELADO: {
      label = 'Cancelado';
      break;
    }
    default: {
      switch (values.situacaoOrcamento) {
        case ORDEM_SERVICO_ORCAMENTO_SITUACAO.AGUARDANDO_APROVACAO: {
          label = 'Aguardando';
          title = 'Aguardando aprovação do orçamento';
          break;
        }
        case ORDEM_SERVICO_ORCAMENTO_SITUACAO.APROVADO: {
          label = 'Aprovado';
          title = 'Orçamento aprovado';
          break;
        }
        case ORDEM_SERVICO_ORCAMENTO_SITUACAO.REJEITADO: {
          label = 'Rejeitado';
          title = 'Orçamento rejeitado';
          break;
        }
        default: {
          label = 'Pendente';
          break;
        }
      }
    }
  }

  return (
    <div
      title={title}
      style={{
        ...styleStatus,
        backgroundColor: color.lightColor,
        color: color.strongColor,
        width: isMobile ? '100%' : '32%',
      }}
    >
      <div style={styleName}>{label}</div>
    </div>
  );
}
