import { Icon } from '@iconify/react';
import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';

import { modulos, usuarioPossuiModulo } from 'Common';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	If,
	ModalHistorico,
	NormalButton,
	ToastTypes,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	notify,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import {
	asyncCreatePessoa,
	asyncDeleteImagemPessoa,
	asyncDeletePessoa,
	asyncGetPessoa,
	asyncImprimirFichaCadastral,
	asyncUpdatePessoa,
	asyncUploadImagemPessoa,
} from 'views/cadastros/Pessoas/Requests';
import { converterPessoaParaApi, converterPessoaParaDuplicar } from 'views/cadastros/Pessoas/Util/pessoaConverter';
import { jaExisteMesmoCnpj, jaExisteMesmoCpf } from '../../Util/functions';

function ActionButtons({
	initialValues,
	initialValue,
	hideModal,
	selecionarTab,
	idPessoa,
	isModal,
	referenciaEstoqueValido,
	informacoesPermissoes,
	setTabSelecionadaInterno,
	imagemFileBase,
	setImagemFileBase,
	PESQUISAURL,
	CADASTROURL,
	setProdutosIsVisible,
	history,
}) {
	const { dirty, resetForm, values, handleSubmit, validateForm, errors, setValues } = useFormikContext();
	const [visibleModalHistorico, setVisibleModalHistorico] = useState(false);

	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	const menuOpcoes = useRef(null);
	const itensOpcoes = getItensOpcoes();

	function getItensOpcoes() {
		const itens = [
			{
				label: 'Duplicar',
				icon: 'fa fa-copy',
				command: () => duplicarPessoa(values.id),
				visible: !dirty && values.id,
			},
			{
				label: 'Histórico',
				icon: 'fa fa-history',
				command: () => setVisibleModalHistorico(true),
				visible: true,
			},
			{ separator: true },
			{
				label: 'Ficha cadastral',
				icon: 'fa fa-print',
				command: () => imprimirRelatorioFichaCadastral(values.id),
				visible: true,
			},
		];

		return itens.filter((element) => element.visible || element.separator);
	}

	async function imprimirRelatorioFichaCadastral(id) {
		await asyncImprimirFichaCadastral(id, ({ data: pdf }) => {
			const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const relatorio = window.open(arquivoURL);
			if (relatorio) {
				relatorio.onload = () => {
					setTimeout(() => {
						relatorio.document.title = 'Ficha Cadastral';
					}, 250);
				};
			}
		});
	}

	async function duplicarPessoa(id) {
		await asyncGetPessoa(id, async ({ data: pessoa }) => {
			atualizarUrl(history, '/pessoas/cadastro', null, metodosAtualizarUrl.POP);
			const pessoaConverter = converterPessoaParaDuplicar(pessoa);

			selecionarTab({ index: 0 });

			setValues(pessoaConverter);
		});
	}

	function cancelar() {
		if (dirty) {
			resetForm({
				values: initialValues,
				campoObrigatorioParaDocumentoFiscal: usuarioPossuiModulo(modulos.VENDAS),
			});
		} else if (isModal) {
			hideModal();
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
		selecionarTab({ index: 0 });
	}

	async function salvar(novoOnSuccess) {
		handleSubmit();
		if (await validarFormulario({ values: values, validateForm })) {
			if (values.tipo === 'FISICA' && (await jaExisteMesmoCpf(values))) {
				confirm('Confirmação', montarMensagemConfirm(), () => {
					salvarRegistro(novoOnSuccess);
				});
			} else if (values.tipo === 'JURIDICA' && (await jaExisteMesmoCnpj(values))) {
				confirm('Confirmação', montarMensagemConfirm(), () => {
					salvarRegistro(novoOnSuccess);
				});
			} else {
				salvarRegistro(novoOnSuccess);
			}
		} else {
			setTabSelecionadaInterno(errors.tab);
		}
	}

	async function salvarRegistro(novoOnSuccess) {
		const dadosFormulario = converterPessoaParaApi(values);
		if (values.id) {
			asyncUpdateRegistro(dadosFormulario, novoOnSuccess);
			uploadImagem(values.id);
			if (values.imagemFile === null && imagemFileBase !== values.imagemFile) {
				await asyncDeleteImagemPessoa(
					values.id,
					() => {
						setImagemFileBase(null);
						setTimeout(() => {
							values.imagem = null;
							resetForm({
								...values,
								imagem: null,
								imagemUrl: null,
							});
						}, 600);
					},
					() => {
						notify('Não foi possivel excluir a imagem', ToastTypes.ERROR);
					},
					true,
					false
				);
			}
		} else {
			asyncCreateRegistro(dadosFormulario, novoOnSuccess);
		}
	}

	async function asyncUpdateRegistro(dadosFormulario, novoOnSuccess) {
		await asyncUpdatePessoa(dadosFormulario, () => {
			if (novoOnSuccess !== undefined && typeof novoOnSuccess === 'function') {
				novoOnSuccess();
			} else if (hideModal) {
				hideModal(values);
			} else {
				resetForm({ values: values });
			}
		});
	}

	async function asyncCreateRegistro(dadosFormulario, novoOnSuccess) {
		return asyncCreatePessoa(dadosFormulario, ({ data: pessoa }) => {
			uploadImagem(pessoa.id);
			if (novoOnSuccess) {
				novoOnSuccess();
			} else if (hideModal) {
				hideModal({
					...values,
					id: pessoa.id,
					codigo: pessoa.codigo,
				});
			} else {
				resetForm({
					values: {
						...values,
						id: pessoa.id,
						codigo: pessoa.codigo,
					},
				});
				if (!isModal) {
					atualizarUrl(history, CADASTROURL, pessoa.id, metodosAtualizarUrl.POP);
				}
			}
		});
	}

	async function uploadImagem(idPessoa) {
		if (
			values.imagemFile !== initialValue.imagemFile &&
			values.imagemFile !== undefined &&
			imagemFileBase !== values.imagemFile
		) {
			await asyncUploadImagemPessoa(
				values.imagemFile,
				idPessoa,
				({ data }) => {
					setImagemFileBase(values.imagemFile);
					values.imagem = data.id;
					resetForm({
						...values,
						imagem: data.id,
						imagemUrl: URL.createObjectURL(values.imagemFile),
					});
				},
				() => {
					notify('Não foi possivel fazer upload da imagem', ToastTypes.ERROR);
				},
				true,
				false
			);
		}
	}

	function montarMensagemConfirm() {
		if (values.tipo === 'ESTRANGEIRO') {
			return `Já existe uma pessoa com esta identificação. Tem certeza que deseja salvar?`;
		} else if (values.tipo === 'FISICA') {
			return `Já existe uma pessoa com este CPF. Tem certeza que deseja salvar?`;
		} else if (values.tipo === 'JURIDICA') {
			return `Já existe uma pessoa com este CNPJ. Tem certeza que deseja salvar?`;
		}
		return null;
	}

	function excluir() {
		confirmarExclusao(asyncDeleteRegistro);
	}

	async function asyncDeleteRegistro() {
		await asyncDeletePessoa(values.id, () => {
			resetForm();
			voltarParaAPesquisa(history, PESQUISAURL);
		});
	}

	function onClickNovo() {
		if (dirty) {
			salvar(novo);
		} else {
			novo();
		}
	}

	function novo() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: initialValue });
		selecionarTab({ index: 0 });
	}

	return (
		<>
			<Menu model={itensOpcoes} popup ref={menuOpcoes} />
			<ButtonCancelar
				{...informacoesPermissoes}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={() => cancelar()}
			/>
			<ButtonSalvar {...informacoesPermissoes} disabled={!dirty} onClick={() => salvar()} />
			<ButtonNovo
				onClick={() => onClickNovo()}
				hidden={(!dirty && !values.id) || isModal}
				estadoBotao={estadoBotaoNovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir hidden={!idPessoa || isModal} {...informacoesPermissoes} onClick={excluir} />
			<NormalButton
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={dirty || !values.id}
				onClick={(event) => menuOpcoes.current.toggle(event)}
				{...informacoesPermissoes}
			/>
			<Button
				style={{ margin: '0px 0px 0px 5px' }}
				type="button"
				label="Referência estoque"
				icon={<Icon icon="carbon:chart-relationship" width="20" style={{ marginRight: '5px' }} />}
				hidden={!values.fornecedor || isModal}
				onClick={() => setProdutosIsVisible(true)}
				disabled={!referenciaEstoqueValido}
			/>

			<If test={visibleModalHistorico}>
				<ModalHistorico
					header="Histórico do cadastro de pessoa"
					visible={visibleModalHistorico}
					onHide={() => setVisibleModalHistorico(false)}
					mapping="pessoas"
					id={values.id}
				/>
			</If>
		</>
	);
}

export { ActionButtons };
