import { withFormik, Field } from 'formik';

import {
	Grid,
	Form,
	FormActions,
	FormContent,
	InputField,
	ButtonSalvar,
	ButtonCancelar,
	estadosBotaoCancelar,
	Dropdown,
	TextArea,
	If,
	notify,
	ToastTypes,
} from '../../../../../../components';

import { OptionsManifestacao, TipoManifestacao } from '../../../Util/constantes';
import { asyncPostManifestar } from '../../../Requests';

const initialValues = {
	manifestacaoAtual: null,
	novaManifestacao: null,
	justificativa: null,
};

function FormManifestarView(props) {
	const { setFieldValue, onHide, informacoesPermissoes, values, dirty, resetForm, onSave, registro, getSituacaoMde } =
		props;

	const isOperacaoNaoRealizada = getSituacaoMde(registro.manifestacoes) === TipoManifestacao.OPERACAO_NAO_REALIZADA;

	function handleChangeStatusDocumento(e) {
		setFieldValue('novaManifestacao', e);
		if (values?.novaManifestacao?.value !== TipoManifestacao.OPERACAO_NAO_REALIZADA) {
			setFieldValue('justificativa', null);
		}
	}

	function cancelar() {
		if (dirty) {
			resetForm('');
		} else {
			onHide();
		}
	}

	async function salvar() {
		let dados = {
			tipo: values?.novaManifestacao?.value,
			justificativa: values.justificativa ?? null,
		};
		await asyncPostManifestar(registro.id, dados, () => {
			onSave();
		});
	}

	function getManifestacaoAtual(manifestacoes) {
		return (
			OptionsManifestacao.filter(item => item.value === getSituacaoMde(manifestacoes))[0]?.label ??
			'Sem manifestação'
		);
	}

	function getOptionsManifestacao(manifestacoes) {
		let options = OptionsManifestacao;
		manifestacoes.forEach(item => {
			options = options.filter(option => option.value !== item.tipo);
		});
		return options;
	}

	return (
		<div
			style={{
				width: '100%',
				overflowX: 'hidden',
				maxHeight: '40rem',
			}}
		>
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
					/>
					<ButtonSalvar onClick={() => salvar()} label="Manifestar" disabled={!dirty} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Manifestação atual"
							name="manifestacaoAtual"
							value={getManifestacaoAtual(registro.manifestacoes)}
							disabled={true}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Dropdown}
							label="Nova manifestação"
							name="novaManifestacao"
							onChange={e => handleChangeStatusDocumento(e)}
							options={getOptionsManifestacao(registro.manifestacoes)}
							showClear={false}
						/>
						<If
							test={
								values?.novaManifestacao?.value === TipoManifestacao.OPERACAO_NAO_REALIZADA ||
								isOperacaoNaoRealizada
							}
						>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								obrigatorio
								component={TextArea}
								touched={true}
								label="Justificativa"
								name="justificativa"
								onChange={e => setFieldValue('justificativa', e.target.value)}
								value={values.justificativa}
								disabled={isOperacaoNaoRealizada}
							/>
						</If>
					</Grid>
				</FormContent>
			</Form>
		</div>
	);
}
const FormManifestar = withFormik({
	validateOnMount: true,
	validateOnChange: true,

	mapPropsToValues(props) {
		let justificativa =
			props.registro?.manifestacoes[props.registro?.manifestacoes.length - 1]?.justificativa ?? null;
		return { ...initialValues, justificativa: justificativa };
	},

	handleSubmit: () => {},
})(FormManifestarView);

export default FormManifestar;
