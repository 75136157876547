import * as Yup from 'yup';
import { Field, useFormikContext, withFormik } from 'formik';

import {
	Button,
	ButtonCancelar,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputDate,
	Modal,
	SingleSelectMunicipio,
	estadosBotaoCancelar,
} from 'components';
import { mensagensDeValidacao } from 'Common';

import { MDFE_SITUACAO } from 'views/transporte/MDFe/Form/Util/constantes';
import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { validarFormulario } from 'views/Util';
import { encerrarMDFe } from 'views/transporte/MDFe/Requests';

function ModalEncerramentoImpl({ mdfe, visible, onHide, callback }) {
	const { informacoesPermissoes, setMessageLoading, setExibirLoadingTransmissao } = useContextMDFe();
	const { dirty, values, setFieldValue, handleReset, handleSubmit, validateForm } = useFormikContext();
	const isEncerrado = mdfe.situacao === MDFE_SITUACAO.ENCERRADO;

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			onHide();
		}
	}

	async function handleClickEncerrar() {
		handleSubmit();

		if (await validarFormulario({ values, validateForm })) {
			setExibirLoadingTransmissao(true);
			setMessageLoading('Encerrando MDF-e...');

			await encerrarMDFe(
				mdfe.id,
				{ id: values.municipio?.value },
				() => {
					if (typeof callback === 'function') {
						callback(mdfe.id);
					}

					setExibirLoadingTransmissao(false);
					setMessageLoading('');
					onHide();
				},
				() => {
					setExibirLoadingTransmissao(false);
					setMessageLoading('');
				}
			);
		}
	}

	return (
		<Modal
			header={isEncerrado ? 'Dados encerramento ' : 'Encerrar MDF-e'}
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '720px' }}
		>
			<Form>
				<FormActions>
					<ButtonCancelar
						onClick={handleClickCancelar}
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						{...informacoesPermissoes}
					/>
					<Button
						className="p-button-success"
						label="Encerrar"
						icon="fa fa-send"
						onClick={handleClickEncerrar}
						style={{ margin: '5px' }}
						disabled={!dirty || isEncerrado}
						{...informacoesPermissoes}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<If test={values.data}>
							<Field
								sm="12"
								md="3"
								lg="3"
								xl="3"
								component={InputDate}
								label="Data encerramento"
								name="data"
								value={values.data}
								onChange={(e) => setFieldValue('data', e.target?.value)}
								disabled
								{...informacoesPermissoes}
							/>
						</If>
						<Field
							sm="12"
							md={values.data ? '9' : '12'}
							lg={values.data ? '9' : '12'}
							xl={values.data ? '9' : '12'}
							component={SingleSelectMunicipio}
							label="Município encerramento"
							name="municipio"
							obrigatorio
							value={values.municipio}
							onChange={(e) => setFieldValue('municipio', e !== null ? e : null)}
							disabled={isEncerrado}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalEncerramento = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,

	mapPropsToValues(props) {
		if (props.mdfe?.encerramento) {
			return { data: props.mdfe?.encerramento?.data, municipio: props.mdfe?.encerramento?.municipio };
		} else {
			return { data: null, municipio: null };
		}
	},

	validationSchema: Yup.object().shape({
		municipio: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalEncerramentoImpl);

export { ModalEncerramento };
