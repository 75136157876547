import { Col, TabView } from 'components';
import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';
import { CLASSNAME_TABVIEW_COLOR } from '../../../Util/constantes';
import { TabInformacoesComplementares } from './components/TabInformacoesComplementares';
import { TabPagamentos } from './components/TabPagamentos';
import { TabTotalizadores } from './components/TabTotalizadores';
import { useContextOrdemServico } from '../../../Context';

export function TabViewAdicionais({
	isMobile,
	isTablet,
	utilizaTabelaPreco,
	utilizaTabelaPrecoServico,
	buscarTabelaPreco,
	buscarMunicipioIdPessoa,
}) {
	const { values } = useFormikContext();
	const { tabAdicionaisIndex, setTabAdicionaisIndex } = useContextOrdemServico();

	return (
		<Col style={{ paddingTop: '0px' }}>
			<TabView
				activeIndex={tabAdicionaisIndex}
				onTabChange={(event) => {
					setTabAdicionaisIndex(event.index);
				}}
				className={`step-ordem-servico-totalizadores tab-view ${CLASSNAME_TABVIEW_COLOR[values.situacao].class}`}
			>
				<TabPanel header="Totalizadores">
					<TabTotalizadores isTablet={isTablet} isMobile={isMobile} />
				</TabPanel>
				<TabPanel header="Pagamentos">
					<TabPagamentos isMobile={isMobile} />
				</TabPanel>
				<TabPanel header="Informações complementares">
					<TabInformacoesComplementares
						utilizaTabelaPreco={utilizaTabelaPreco}
						utilizaTabelaPrecoServico={utilizaTabelaPrecoServico}
						buscarTabelaPreco={buscarTabelaPreco}
						buscarMunicipioIdPessoa={buscarMunicipioIdPessoa}
					/>
				</TabPanel>
			</TabView>
		</Col>
	);
}
