import { exibirToast, get, post, services } from 'Common';

/* Contas receber resquests */
export async function createEfetuarRecebimento(urlBase, idOrigem, recebimento, onSuccess, onError) {
	await post(
		`${urlBase}/${idOrigem}/parcelas/${recebimento.id}/receber`,
		recebimento,
		null,
		exibirToast(onSuccess, 'Recebimento efetuado com sucesso'),
		onError
	);
}

export async function createEstornarRecebimento(urlBase, id, recebimentoId, onSuccess, onError) {
	await post(
		`${urlBase}/${id}/parcelas/${recebimentoId}/estornar`,
		null,
		null,
		exibirToast(onSuccess, 'Recebimento estornado com sucesso'),
		onError
	);
}

export async function readImprimirRecibo(id, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/contas_receber/recibos/${id}/pdf`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function readContasReceberPorId(contaReceberId, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/contas_receber/${contaReceberId}`, null, onSuccess, onError);
}

/* Boletos resquests */
export async function readContaComConvenio(url, emiteBoletos, onSuccess, onError) {
	await get(
		`${url}?query=situacao=="ATIVA"${emiteBoletos === true ? ';emitirBoletos=="true"' : ';recebePix=="true"'}`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function createEmitirBoletos(convenioId, contaReceberIds, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/boletos/convenios/${convenioId}/emitir`,
		contaReceberIds,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function createImprimirBoletos(boletos, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/boletos/imprimir`,
		boletos,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function createEnviarBoletosPorEmail(data, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/boletos/enviar_email`, data, null, onSuccess, onError);
}

/* Pix resquests */
export async function createGerarPixCobrancas(contaId, contaReceberIds, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/pix/cobranca/contas/${contaId}/gerar`,
		contaReceberIds,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function createImprimirPixCobrancas(cobrancas, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/pix/cobranca/imprimir`,
		cobrancas,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

/* Contas receber resquests */
export async function createEfetuarPagamento(urlBase, idOrigem, pagamento, onSuccess, onError) {
	await post(
		`${urlBase}/${idOrigem}/duplicatas/${pagamento.id}/pagar`,
		pagamento,
		null,
		exibirToast(onSuccess, 'Pagamento efetuado com sucesso'),
		onError
	);
}

export async function createEstornarPagamento(urlBase, id, pagamentoId, onSuccess, onError) {
	await post(
		`${urlBase}/${id}/duplicatas/${pagamentoId}/estornar`,
		null,
		null,
		exibirToast(onSuccess, 'Pagamento estornado com sucesso'),
		onError
	);
}
