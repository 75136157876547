
import { services } from "Common/Constantes/api";
import { post } from "Common/Requisicoes";
import axios from 'axios';

export async function asyncBuscarQRCodePixImediato(lancamentoId, onSuccess, onError) {
  return await post(`${services.GESTOR}/v1/pix/imediato/lancamentos/${lancamentoId}/gerar`, null, null, onSuccess, onError);
}

export async function asyncBuscarStatusPix(pixId, onSuccess, onError) {
  return await axios
    .get(`${services.GESTOR}/v1/pix/${pixId}/consultar`, null)
    .then(e => {
      onSuccess(e);
    })
    .catch(e => {
      onError(e);
    });
}