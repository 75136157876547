/* eslint-disable no-unused-vars */
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';
import { formatarDecimais, gerarUUID, manterApenasNumeros, mensagensDeValidacao } from 'Common';
import {
	Dropdown,
	FormActions,
	FormContent,
	FormGroup,
	Grid,
	InputDouble,
	InputField,
	InputMask,
	Modal,
	Paper,
	confirm,
} from 'components';
import isDate from 'date-fns/isDate';
import isValid from 'date-fns/isValid';
import isWithinInterval from 'date-fns/isWithinInterval';
import parse from 'date-fns/parse';
import { Field, useFormikContext, withFormik } from 'formik';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { validarFormulario } from 'views/Util';
import * as Yup from 'yup';
import { INITIAL_VALUES, INITIAL_VALUES_EDIT, helpDocumentoConfirmacaoForm } from '../Util/constantes';
import { ActionButtons } from './ActionButtons';

function ModalFormConfirmacaoPadraoView({
	visible,
	onHide,
	inserirRegistro,
	excluirRegistro,
	atualizarRegistro,
	registroSelecionado,
	onNovoClick,
	disabled,
	informacoesPermissoes,
	...props
}) {
	const { values, dirty, initialValues, resetForm, setFieldValue, handleSubmit } = useFormikContext();
	const [inserindoNovoRegistro, setInserindoNovoRegistro] = useState(!registroSelecionado);

	useEffectOnce(() => {
		setInserindoNovoRegistro(!registroSelecionado);

		setTimeout(() => {
			document.getElementById('InputFieldPergunta')?.focus();
		}, 500);
	});

	function onClickNovo() {
		if (dirty) {
			salvar(novo());
		} else {
			novo();
		}
	}

	function novo() {
		onNovoClick();
		resetForm({ values: INITIAL_VALUES });
		setInserindoNovoRegistro(true);
	}

	function excluir() {
		confirm(
			'Confirmação',
			'Deseja excluir o signatário?',
			async () => {
				excluirRegistro(registroSelecionado);
				onHide();
			},
			() => {
				null;
			},
			'Sim',
			'Não'
		);
	}

	async function salvar(novoOnSuccess = null) {
		handleSubmit();

		if (await validarFormulario(props)) {
			if (inserindoNovoRegistro) {
				inserirRegistro(values, novoOnSuccess);
			} else {
				atualizarRegistro(values, novoOnSuccess);
			}
		}
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			onHide();
		}
	}

	function renderCampoResposta(informacoesPermissoes) {
		const propriedadesPadrao = {
			label: 'Resposta esperada ',
			name: 'respostaEsperada',
			obrigatorio: true,
			value: values.respostaEsperada,
			helpMessage: helpDocumentoConfirmacaoForm.respostaEsperada,
			disabled: disabled,
			...informacoesPermissoes,
		};

		switch (values.respostaTipo) {
			case 'CNPJ':
				return (
					<Field
						sm="12"
						md="12"
						lg="6"
						xl="6"
						component={InputMask}
						mask="00.000.000/0000-00"
						placeholder="  .   .   /    -  "
						removerMascara={false}
						onChange={(e) => setFieldValue('respostaEsperada', e.target.value)}
						{...propriedadesPadrao}
					/>
				);
			case 'CPF':
				return (
					<Field
						sm="12"
						md="12"
						lg="6"
						xl="6"
						component={InputMask}
						mask="000.000.000-00"
						placeholder="   .   .   -  "
						removerMascara={false}
						onChange={(e) => setFieldValue('respostaEsperada', e.target.value)}
						{...propriedadesPadrao}
					/>
				);
			case 'DATA':
				return (
					<Field
						sm="12"
						md="12"
						lg="6"
						xl="6"
						component={InputMask}
						mask="00/00/0000"
						removerMascara={false}
						placeholder="  /  /    "
						onChange={(e) => setFieldValue('respostaEsperada', e.target.value)}
						{...propriedadesPadrao}
					/>
				);
			case 'NUMERO':
				return (
					<Field
						sm="12"
						md="12"
						lg="6"
						xl="6"
						component={InputDouble}
						size={15}
						onChange={(e) => setFieldValue('respostaEsperada', formatarDecimais(e.target.value))}
						decimalScale={2}
						{...propriedadesPadrao}
					/>
				);
			case 'SIM_NAO':
				return (
					<Field
						sm="12"
						md="12"
						lg="6"
						xl="6"
						component={Dropdown}
						options={[
							{ label: 'Sim', value: 'true' },
							{ label: 'Não', value: 'false' },
						]}
						showClear={false}
						onChange={(e) => setFieldValue('respostaEsperada', e.value)}
						{...propriedadesPadrao}
					/>
				);
			case 'TEXTO':
				return <Field sm="12" md="12" lg="6" xl="6" component={InputField} size={255} {...propriedadesPadrao} />;
			default:
				return null;
		}
	}

	function onChangeTipoResposta(respostaTipo) {
		if (respostaTipo?.value !== props.values.respostaTipo) {
			setFieldValue('respostaEsperada', null);
		}
		setFieldValue('respostaTipo', respostaTipo?.value);
	}

	return (
		<Modal header="Cadastro de confirmação para assinatura" visible={visible} onHide={onHide}>
			<FormActions>
				<ActionButtons
					onClickNovo={onClickNovo}
					salvar={salvar}
					cancelar={cancelar}
					excluir={excluir}
					inserindoNovoRegistro={inserindoNovoRegistro}
					disabled={disabled}
				/>
			</FormActions>
			<FormContent>
				<Paper dirty={dirty} {...informacoesPermissoes} childsOnly>
					<FormGroup>
						<Grid>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={InputField}
								label="Pergunta para confirmação "
								obrigatorio
								name="pergunta"
								size={255}
								disabled={disabled}
								helpMessage={helpDocumentoConfirmacaoForm.pergunta}
								id="InputFieldPergunta"
								// {...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="6"
								xl="6"
								component={Dropdown}
								label="Tipo de resposta "
								obrigatorio
								options={[
									{ label: 'CNPJ', value: 'CNPJ' },
									{ label: 'CPF', value: 'CPF' },
									{ label: 'Data', value: 'DATA' },
									{ label: 'Número', value: 'NUMERO' },
									{ label: 'Sim/Não', value: 'SIM_NAO' },
									{ label: 'Texto', value: 'TEXTO' },
								]}
								showClear={false}
								name="respostaTipo"
								value={values.respostaTipo}
								onChange={(e) => onChangeTipoResposta(e)}
								helpMessage={helpDocumentoConfirmacaoForm.respostaTipo}
								disabled={disabled}
								// {...informacoesPermissoes}
							/>
							{renderCampoResposta(informacoesPermissoes)}
						</Grid>
					</FormGroup>
				</Paper>
			</FormContent>
		</Modal>
	);
}

const ModalFormConfirmacaoPadrao = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return { ...props.registroSelecionado };
		}
		return { ...INITIAL_VALUES_EDIT, tempId: gerarUUID() };
	},

	async validate(values) {
		const errors = {};

		switch (values.respostaTipo) {
			case 'CNPJ':
				if (values.respostaEsperada && !isValidCNPJ(manterApenasNumeros(values.respostaEsperada)))
					errors.respostaEsperada = 'Digite um CNPJ válido.';
				break;
			case 'CPF':
				if (values.respostaEsperada && !isValidCPF(manterApenasNumeros(values.respostaEsperada)))
					errors.respostaEsperada = 'Digite um CPF válido.';
				break;
			case 'DATA': {
				if (values.respostaEsperada) {
					const dataParaConfirmacao = parse(values.respostaEsperada, 'dd/MM/yyyy', new Date());
					if (isDate(dataParaConfirmacao) && isValid(dataParaConfirmacao)) {
						if (
							!isWithinInterval(dataParaConfirmacao, {
								start: new Date(1900, 1, 1),
								end: new Date(2100, 1, 1),
							})
						) {
							errors.respostaEsperada = 'Digite uma data válida.';
						}
					} else {
						errors.respostaEsperada = 'Digite uma data válida.';
					}
				}
				break;
			}
			default:
				break;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		pergunta: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		respostaTipo: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		respostaEsperada: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),
	handleSubmit: () => {},
})(ModalFormConfirmacaoPadraoView);

export default withRouter(ModalFormConfirmacaoPadrao);
