import { Field } from 'formik';
import { InputField } from 'components';

function FieldsContribuicaoBeneficiario(props) {
  const { validacaoNovaTributacao, setFieldValue, values } = props;

  return (
    <>
      <Field
        sm="6"
        md="4"
        lg="2"
        xl="2"
        component={InputField}
        label="Código beneficiário"
        name="codigoBeneficiario"
        value={values.codigoBeneficiario}
        onChange={event => setFieldValue('codigoBeneficiario', event.target.value)}
        allowNegative={false}
        size={8}
        disabled={validacaoNovaTributacao}
      />
    </>
  );
}

export default FieldsContribuicaoBeneficiario;
