import { useEffect, useState } from 'react';
import { buscarDocumentosPorStatus } from './Requests';
import { Card } from 'primereact/card';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { ProgressBar } from 'primereact/progressbar';
import { ColorsCard } from '../../../../../../documentos/AssinaturaEletronica/Util/constantes';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import If from '../../../../../../../components/If';

const StatusDocumento = {
	ASSINADO: 'Assinados',
	REJEITADO: 'Rejeitados',
	ARQUIVO_ADICIONADO: 'Arquivo adicionado',
	AGUARDANDO_ASSINATURA: 'Aguardando assinaturas',
	EXPIRADO: 'Expirados',
	PENDENTE: 'Pendente',
};

const CardItem = ({
	status = 'Status do documento',
	background = '#dee2e6',
	value = 50,
	valueTotal = 100,
	color = '00ab55',
	title = '',
}) => {
	const percentualSobTotal = valueTotal > 0 ? Number((value / valueTotal) * 100).toFixed(2) : 0;
	return (
		<div style={{ cursor: 'default', padding: '5px' }} title={title}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<span style={{ fontSize: '14px', fontWeight: 'bolder', color: '#424242', overflow: 'hidden' }}>
					{status}
				</span>
				<span
					style={{ fontSize: '16px', fontWeight: 'bolder', color: '#424242' }}
				>{`${value} (${percentualSobTotal}%)`}</span>
			</div>
			<LineIndicator value={percentualSobTotal} background={background} color={color} />
		</div>
	);
};

const LineIndicator = ({ color, background, value }) => {
	return (
		<div style={{ background, height: '5px', borderRadius: '15px', margin: '10px 0px' }}>
			<span
				style={{
					width: `${value}%`,
					background: color,
					display: 'block',
					height: '100%',
					borderRadius: '15px',
				}}
			/>
		</div>
	);
};

export default function CardTotalDocumentosPorStatus(props) {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [assinados, setAssinados] = useState(0);
	const [rejeitados, setRejeitados] = useState(0);
	const [pendente, setPendente] = useState(0);
	const [aguardandoAssinaturas, setAguardandoAssinaturas] = useState(0);
	const [arquivoAdicionado, setArquivoAdicionado] = useState(0);
	const [expirados, setExpirados] = useState(0);
	const [totalDocumentos, setTotalDocumentos] = useState(0);

	useEffect(() => {
		carregarInformacoes();
	}, []);

	useEffect(() => {
		carregarInformacoes();
	}, [props.dataAtualizacaoMenuLateral]);

	useEffect(() => {
		if (loading) {
			buscarDocumentosPorStatus(
				({ data }) => {
					const totalDocumentos =
						data.assinados +
						data.rejeitados +
						data.aguardandoAssinaturas +
						data.expirados +
						data.pendente +
						data.arquivoAdicionado;

					setAssinados(data.assinados);
					setRejeitados(data.rejeitados);
					setAguardandoAssinaturas(data.aguardandoAssinaturas);
					setArquivoAdicionado(data.arquivoAdicionado);
					setPendente(data.pendente);
					setExpirados(data.expirados);
					setTotalDocumentos(totalDocumentos);
					setLoading(false);
					setError(false);
				},
				() => {
					setLoading(false);
					setError(true);
				}
			);
		}
	}, [loading, props.dataAtualizacaoMenuLateral]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	function getHeaderCard() {
		return (
			<div style={{ padding: '14px', color: '#333333', display: 'flex', justifyContent: 'space-between' }}>
				<label
					style={{ fontSize: '17px' }}
					title="Lista o somatório dos documentos por status sem levar em consideração o período informado"
				>
					Totalidade de documentos (inclusive arquivados)
				</label>
			</div>
		);
	}

	function titleCardPendente(pendente) {
		if (pendente === 1) {
			return `Um documento está pendente até o momento`;
		}
		return `Um total de ${pendente} documentos estão pendentes até o momento`;
	}

	function titleCardArquivoAdicionado(arquivoAdicionado) {
		if (arquivoAdicionado === 1) {
			return `Um documento está com o status Arquivo Adicionado até o momento`;
		}
		return `Um total de ${arquivoAdicionado} documentos foram adicionados até o momento`;
	}

	function titleCardExpirados(expirados) {
		if (expirados === 1) {
			return `Um documento está com o status Expirado até o momento`;
		}
		return `Um total de ${expirados} documentos foram expirados até o momento`;
	}

	function titleCardAguardandoAssinatura(aguardandoAssinaturas) {
		if (aguardandoAssinaturas === 1) {
			return `Um documento está com o status Aguardando Assinaturas até o momento`;
		}
		return `Um total de ${aguardandoAssinaturas} documentos estão aguardando assinatura até o momento`;
	}

	function titleCardRejeitados(rejeitados) {
		if (rejeitados === 1) {
			return `Um documento está com o status Rejeitado até o momento`;
		}
		return `Um total de ${rejeitados} documentos foram rejeitados até o momento`;
	}

	function titleCardAssinados(assinados) {
		if (assinados === 1) {
			return `Um documento está com o status Assinado até o momento`;
		}
		return `Um total de ${assinados} documentos foram assinados até o momento`;
	}

	if (loading) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
				<ProgressBar mode="indeterminate" style={{ height: '3px' }} />
			</Card>
		);
	} else if (error) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
				<FalhaAoCarregarAsInformacoes />
			</Card>
		);
	} else if (totalDocumentos === 0) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
				<NenhumaInformacaoEncontrada />
			</Card>
		);
	} else {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
				<If test={assinados > 0}>
					<CardItem
						value={assinados}
						valueTotal={totalDocumentos}
						background={ColorsCard.ASSINADO.lightColor}
						color={ColorsCard.ASSINADO.strongColor}
						status={StatusDocumento.ASSINADO}
						title={titleCardAssinados(assinados)}
					/>
				</If>
				<If test={rejeitados > 0}>
					<CardItem
						value={rejeitados}
						valueTotal={totalDocumentos}
						background={ColorsCard.REJEITADO.lightColor}
						color={ColorsCard.REJEITADO.strongColor}
						status={StatusDocumento.REJEITADO}
						title={titleCardRejeitados(rejeitados)}
					/>
				</If>
				<If test={aguardandoAssinaturas > 0}>
					<CardItem
						value={aguardandoAssinaturas}
						valueTotal={totalDocumentos}
						background={ColorsCard.AGUARDANDO_ASSINATURAS.lightColor}
						color={ColorsCard.AGUARDANDO_ASSINATURAS.strongColor}
						status={StatusDocumento.AGUARDANDO_ASSINATURA}
						title={titleCardAguardandoAssinatura(aguardandoAssinaturas)}
					/>
				</If>
				<If test={expirados > 0}>
					<CardItem
						value={expirados}
						valueTotal={totalDocumentos}
						background={ColorsCard.EXPIRADO.lightColor}
						color={ColorsCard.EXPIRADO.strongColor}
						status={StatusDocumento.EXPIRADO}
						title={titleCardExpirados(expirados)}
					/>
				</If>
				<If test={arquivoAdicionado > 0}>
					<CardItem
						value={arquivoAdicionado}
						valueTotal={totalDocumentos}
						background={ColorsCard.ARQUIVO_ADICIONADO.lightColor}
						color={ColorsCard.ARQUIVO_ADICIONADO.strongColor}
						status={StatusDocumento.ARQUIVO_ADICIONADO}
						title={titleCardArquivoAdicionado(arquivoAdicionado)}
					/>
				</If>
				<If test={pendente > 0}>
					<CardItem
						value={pendente}
						valueTotal={totalDocumentos}
						background={ColorsCard.PENDENTE.lightColor}
						color={ColorsCard.PENDENTE.strongColor}
						status={StatusDocumento.PENDENTE}
						title={titleCardPendente(pendente)}
					/>
				</If>
			</Card>
		);
	}
}
