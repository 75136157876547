import { useEffect, useRef, useState } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';

import { keyFilterConsultaRsql, mensagensDeValidacao, renderizarValidacao } from 'Common';
import {
	AutoProgressBar,
	Col,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	Modal,
	SingleSelectMunicipio,
} from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { converterMDFeDFeParaForm } from 'views/transporte/MDFe/Form/Util/MDFeConverter';
import InputChaveDeAcesso from 'views/transporte/MDFe/Form/components/TabsPrincipais/components/Documentos/components/InputChaveDeAcesso';
import CardMunicipioDescarregamento from 'views/transporte/MDFe/Form/components/TabsPrincipais/components/Documentos/components/CardMunicipioDescarregamento';

import { ActionButtons } from './components/ActionButtons';

function ModalDadosDFeImpl({ visible, onHide, canTransmitir, id, siglaUfCarregamento, isCargaPropria }) {
	const { informacoesPermissoes, isFirstRender, setIsFirstRender } = useContextMDFe();
	const { values, errors, setFieldValue } = useFormikContext();

	const [municipiosLocal, setMunicipiosLocal] = useState([]);
	const [municipioSelecionado, setMunicipioSelecionado] = useState({});
	const [indexMunicipioSelecionado, setIndexMunicipioSelecionado] = useState(null);
	const [limparSelecionado, setLimparSelecionado] = useState(false);
	const [chaveDeAcesso, setChaveDeAcesso] = useState(null);
	const [municipio] = useState(null);
	const [errorsChaveAcesso, setErrorsChaveAcesso] = useState(null);

	const isEmpty = Object.keys(municipioSelecionado)?.length === 0;
	const canAddDocumento = municipioSelecionado.documentos?.length === 0;

	const childRef = useRef(null);

	useEffect(() => {
		if (values.municipios?.length > 0) {
			setMunicipiosLocal(values.municipios);
		}
	}, [values.municipios]);

	useEffect(() => {
		if (isFirstRender) {
			setMunicipiosLocal(values.municipios);
			setIsFirstRender(false);
		}
	}, [isFirstRender, setIsFirstRender, values.municipios]);

	useEffect(() => {
		setFieldValue('municipios', municipiosLocal);
	}, [municipiosLocal]);

	useEffect(() => {
		if (limparSelecionado) {
			setMunicipioSelecionado({});
			setIndexMunicipioSelecionado(null);
			setLimparSelecionado(false);
		}
	}, [limparSelecionado]);

	function handleChangeMunicipioCarregamento(e) {
		setFieldValue('carregamentoMunicipio', e);
	}

	return (
		<Modal header="Dados DF-e" visible={visible} onHide={onHide} styleModal={{ maxWidth: '1000px' }}>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ActionButtons onHide={onHide} canTransmitir={canTransmitir} id={id} />
				</FormActions>
				<FormContent>
					<Grid style={{ padding: '0px' }}>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={SingleSelectMunicipio}
							label="Município de carregamento"
							name="carregamentoMunicipio"
							value={values.carregamentoMunicipio}
							onChange={handleChangeMunicipioCarregamento}
							keyfilter={keyFilterConsultaRsql}
							isClearable={false}
							uf={siglaUfCarregamento}
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={SingleSelectMunicipio}
							label="Município de descarregamento"
							name="municipio"
							value={municipio}
							optionsRemover={values.municipios}
							onChange={(e) => childRef.current.handleAdicionarMunicipio(e)}
							keyfilter={keyFilterConsultaRsql}
							uf={siglaUfCarregamento}
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<Col
							sm="12"
							md="6"
							lg="6"
							xl="6"
							style={{ display: 'flex', padding: '0', alignItems: 'baseline', position: 'relative' }}
						>
							<InputChaveDeAcesso
								chaveDeAcesso={chaveDeAcesso}
								setChaveDeAcesso={setChaveDeAcesso}
								handleAdicionarChaveAcesso={(e) => childRef.current.handleAdicionarChaveAcesso(e)}
								isEmpty={isEmpty || !canTransmitir || !canAddDocumento}
								errors={errorsChaveAcesso}
							/>
						</Col>
						<If test={errors.municipios}>
							<div style={{ marginLeft: '8px' }}>{renderizarValidacao(errors.municipios, true)}</div>
						</If>
						<Col sm="12" md="12" lg="12" xl="12" style={{ padding: '0', display: 'flex' }}>
							<CardMunicipioDescarregamento
								municipio={municipio}
								values={values}
								setFieldValue={setFieldValue}
								municipiosLocal={municipiosLocal}
								setMunicipiosLocal={setMunicipiosLocal}
								limparSelecionado={limparSelecionado}
								setLimparSelecionado={setLimparSelecionado}
								municipioSelecionado={municipioSelecionado}
								setMunicipioSelecionado={setMunicipioSelecionado}
								indexMunicipioSelecionado={indexMunicipioSelecionado}
								setIndexMunicipioSelecionado={setIndexMunicipioSelecionado}
								chaveDeAcesso={chaveDeAcesso}
								setChaveDeAcesso={setChaveDeAcesso}
								isEmpty={isEmpty}
								setErrors={setErrorsChaveAcesso}
								disabled={!canTransmitir}
								isCargaPropria={isCargaPropria}
								ref={childRef}
							/>
						</Col>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalDadosDFe = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,

	mapPropsToValues({ values, proximoSequencial, municipioCarregamento }) {
		if (Object.keys(values)?.length > 0) {
			return converterMDFeDFeParaForm(values);
		} else {
			return {
				id: null,
				sequenciaEvento: proximoSequencial,
				carregamentoMunicipio: municipioCarregamento,
				municipios: [],
			};
		}
	},

	validate(values) {
		const errors = {};

		if (!values.municipios?.length > 0) {
			errors.municipios = 'Informe pelo menos um município de descarregamento';
		} else {
			values.municipios?.forEach((e) => {
				if (!e.documentos[0]?.chave && !errors.municipios) {
					errors.municipios = `O município ${e.municipio?.label} não possui chave de acesso informada`;
				}
			});
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		carregamentoMunicipio: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalDadosDFeImpl);

export { ModalDadosDFe };
