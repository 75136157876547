import { connect } from 'react-redux';
import { InternalDropdown } from './components/InternalDropdown';

function Dropdown(props) {
  const { field, form, useFormErrors = true, useFormTouched = true, ...rest } = props;
  const useForm = useFormErrors && useFormTouched;
  return (
    <InternalDropdown
      {...field}
      {...rest}
      dirty={form ? form.dirty : props.dirty}
      errors={form && useForm ? form?.errors[field.name] : props.errors}
      touched={form && useForm ? Boolean(form?.touched[field.name]) : Boolean(props.touched)}
      {...props}
    />
  );
}

const mapStateToProps = state => ({
  isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Dropdown);
