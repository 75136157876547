import {
	buscarDadosLoginLocalStorage,
	copiarObjeto,
	estadosCadastro,
	formatarMonetario,
	mensagensDeValidacao,
	permissoes,
	recursos,
	usuarioPossuiPermissao,
} from 'Common';
import { confirm, Modal } from 'components';
import { addMonths, format, formatISO, isBefore, isValid, parseISO, startOfDay } from 'date-fns';
import { useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useEffectOnce } from 'react-use';
import FormReceber from 'views/financas/components/FormContasPagarReceber/FormReceber';
import {
	CONTA_RECEBER_STATUS,
	INITIAL_VALUES_RECEBER,
} from 'views/financas/components/ModalReparcelamentoTitulos/Utils/constantes';
import * as Yup from 'yup';
import {
	asyncDeleteContaReceber,
	asyncDeleteContaReceberProximasEmAberto,
	asyncDeleteContaReceberTodasEmAberto,
	asyncEditarContaReceberProximasEmAberto,
	asyncEditarContaReceberTodasEmAberto,
	asyncGetContaReceber,
	asyncGetProximoIdentificador,
	asyncUpdateContaReceber,
	buscarCategoriaFavoritaReceita,
	buscarContaFavoritaReceita,
	buscarFormaPagamentoDinheiro,
} from '../../Requests';
import { tiposEdicoesContasReceber } from '../ModalEdicaoContasReceber';
import { tiposExclusoesContasReceber } from '../ModalExclusaoContasReceber';
import { TIPO_CONTA_RECEBER } from './Util/constantes';
import { converterContaReceberParaApi, converterContaReceberParaFormulario } from './Util/contaReceberConverter';

function ModalContaReceberView({
	valorPadraoDataVencimento,
	isMobile,
	isModal,
	onHide,
	visible,
	history,
	onNovoClick,
	...props
}) {
	const { values, dirty, resetForm } = useFormikContext();

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EXCLUIR));

	const [registroSelecionado, setRegistroSelecionado] = useState(props.registroSelecionado ?? null);
	const [exibirModalExclusao, setExibirModalExclusao] = useState(false);
	const [exibirModalConfirmacaoEdicao, setExibirModalConfirmacaoEdicao] = useState(false);
	const [modalDetalhesPixVisible, setModalDetalhesPixVisible] = useState(false);
	const [activeIndex, setActiveIndex] = useState([]);

	const { filialConectada } = buscarDadosLoginLocalStorage();

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};

	const isEdicao = values.id !== null;
	const isReparcelada = values.status === CONTA_RECEBER_STATUS.REPARCELADA;

	useEffectOnce(() => {
		if (!values.id) {
			atribuirValoresIniciais();
		}

		setTimeout(() => {
			if (!document.getElementById('ContasReceberInputMoneyValor')?.disabled) {
				document.getElementById('ContasReceberInputMoneyValor')?.focus();
			} else {
				document.getElementById('ContasReceberInputEmissao')?.focus();
			}
		}, 500);
	});

	useEffect(() => {
		if (registroSelecionado) {
			resetForm({
				values: converterContaReceberParaFormulario({
					...registroSelecionado,
					vendaId: values.vendaId,
					nfeId: values.nfeId,
					nfceId: values.nfceId,
					ordemId: values.ordemId,
					nfseId: values.nfseId,
				}),
			});
		}
	}, [registroSelecionado]);

	async function atribuirValoresIniciais() {
		let identificador = null;
		let vencimento = null;
		let categoria = null;
		let formaPagamento = null;
		let conta = null;
		let contaRecebido = null;
		let taxaCategoria = null;

		if (valorPadraoDataVencimento) {
			vencimento = formatISO(valorPadraoDataVencimento);
		} else {
			vencimento = formatISO(addMonths(new Date(), 1));
		}

		try {
			const [identificadorData, categoriaData, formaPagamentoData, contaData] = await Promise.all([
				new Promise((resolve) => {
					asyncGetProximoIdentificador(resolve);
				}),
				new Promise((resolve) => {
					buscarCategoriaFavoritaReceita(resolve);
				}),
				new Promise((resolve) => {
					buscarFormaPagamentoDinheiro(resolve);
				}),
				new Promise((resolve) => {
					buscarContaFavoritaReceita(resolve);
				}),
			]);

			identificador = identificadorData.data.identificador;

			if (categoriaData.data.totalElements > 0) {
				categoria = {
					label: categoriaData.data.content[0].nome,
					value: categoriaData.data.content[0].id,
					registro: categoriaData.data.content[0],
				};
			}

			if (formaPagamentoData.data.totalElements > 0) {
				formaPagamento = {
					label: formaPagamentoData.data.content[0].descricao,
					value: formaPagamentoData.data.content[0].id,
					registro: formaPagamentoData.data.content[0],
				};
			}

			if (contaData.data.totalElements > 0) {
				contaRecebido = {
					label: contaData.data.content[0].nome,
					value: contaData.data.content[0].id,
					registro: contaData.data.content[0],
				};
				conta = copiarObjeto(contaRecebido);
			}

			if (filialConectada?.parametrosFinancas?.taxaCategoria) {
				const categoriaTaxa = filialConectada?.parametrosFinancas?.taxaCategoria;
				taxaCategoria = {
					label: categoriaTaxa.nome,
					value: categoriaTaxa.id,
					registro: categoriaTaxa,
				};
			}

			resetForm({
				values: {
					...values,
					identificador,
					vencimento,
					categoria,
					formaPagamento,
					conta,
					contaRecebido,
					taxaCategoria,
				},
			});
		} catch (error) {
			console.error(error);
		}
	}

	function onHideModalExclusao(tipoExclusao) {
		if (tipoExclusao) {
			if (tipoExclusao === tiposExclusoesContasReceber.APENAS_SELECIONADA) {
				asyncDeleteContaReceber(values.id, () => {
					onHide();
				});
			} else if (tipoExclusao === tiposExclusoesContasReceber.PROXIMAS_EM_ABERTO) {
				asyncDeleteContaReceberProximasEmAberto(values.id, () => {
					onHide();
				});
			} else {
				asyncDeleteContaReceberTodasEmAberto(values.id, () => {
					onHide();
				});
			}
		} else {
			setExibirModalExclusao(false);
		}
	}

	function onHideModalEdicao(tipoEdicao) {
		if (tipoEdicao) {
			if (tipoEdicao === tiposEdicoesContasReceber.APENAS_SELECIONADA) {
				asyncUpdateContaReceber(converterContaReceberParaApi(values), () => {
					onHide();
				});
			} else if (tipoEdicao === tiposEdicoesContasReceber.PROXIMAS_EM_ABERTO) {
				asyncEditarContaReceberProximasEmAberto(converterContaReceberParaApi(values), () => {
					onHide();
				});
			} else {
				asyncEditarContaReceberTodasEmAberto(converterContaReceberParaApi(values), () => {
					onHide();
				});
			}
		} else {
			setExibirModalConfirmacaoEdicao(false);
		}
	}

	function renderModalHeader(values) {
		if (values.itemRepeticao && values.quantidadeRepeticoes) {
			const tituloRepeticao = `${values.itemRepeticao}/${values.quantidadeRepeticoes}`;
			return values.id ? `Editar conta a receber ${tituloRepeticao}` : `Nova conta a receber ${tituloRepeticao}`;
		}

		return values.id ? `Editar conta a receber` : `Nova conta a receber`;
	}

	function onHideModal(solicitarConfirmacao = true) {
		if (dirty && solicitarConfirmacao) {
			confirm(
				'Confirmação',
				'A conta a receber possui informações não salvas, tem certeza que deseja sair sem salvar?',
				() => {
					onHide(true);
				}
			);
		} else {
			onHide();
		}
	}

	function buscarValor() {
		const { tipo, valor, quantidadeRepeticoes } = values;
		if (tipo === TIPO_CONTA_RECEBER.VALOR_PARCELADO) {
			return Math.trunc((valor / quantidadeRepeticoes) * 100) / 100;
		} else {
			return valor;
		}
	}

	async function atualizarReceber() {
		await asyncGetContaReceber(values.id, ({ data: contaReceber }) => {
			resetForm({
				values: converterContaReceberParaFormulario({
					...contaReceber,
					vendaId: values.vendaId,
					nfeId: values.nfeId,
					nfceId: values.nfceId,
					ordemId: values.ordemId,
					nfseId: values.nfseId,
				}),
			});
		});
	}

	return (
		<Modal
			header={renderModalHeader(values)}
			visible={visible}
			onHide={onHideModal}
			styleModal={{ padding: isMobile ? '5px' : '20px' }}
			styleCloseButton={{
				marginTop: isMobile ? '-0.5rem' : '0',
			}}
		>
			<FormReceber
				{...{
					isMobile,
					isModal,
					onHideModal,
					history,
					onNovoClick,
					onHideModalExclusao,
					onHideModalEdicao,
					atualizarReceber,
					atribuirValoresIniciais,
					buscarValor,
					podeEditar,
					registroSelecionado,
					setRegistroSelecionado,
					exibirModalExclusao,
					setExibirModalExclusao,
					exibirModalConfirmacaoEdicao,
					setExibirModalConfirmacaoEdicao,
					modalDetalhesPixVisible,
					setModalDetalhesPixVisible,
					activeIndex,
					setActiveIndex,
					filialConectada,
					informacoesPermissoes,
					isEdicao,
					isReparcelada,
				}}
			/>
		</Modal>
	);
}

const ModalContaReceber = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return converterContaReceberParaFormulario(props.registroSelecionado);
		} else {
			return INITIAL_VALUES_RECEBER;
		}
	},

	validate(values) {
		const errors = {};

		function buscarValor() {
			const { tipo, valor, quantidadeRepeticoes } = values;
			if (tipo === TIPO_CONTA_RECEBER.VALOR_PARCELADO) {
				return Math.trunc((valor / quantidadeRepeticoes) * 100) / 100;
			} else {
				return valor;
			}
		}

		if (values.recebimentos.length > 0) {
			let valorTotalRecebimentos = 0;

			values.recebimentos.forEach((recebimento) => {
				valorTotalRecebimentos += recebimento.valor;
			});

			if (valorTotalRecebimentos > values.valor) {
				errors.recebimentos = 'O valor a receber não pode ser maior que o valor total da conta';
			}
		}

		if (values.tipo === TIPO_CONTA_RECEBER.VALOR_PARCELADO) {
			if (values.recebido) {
				if (values.valorRecebido > values.valor / values.quantidadeRepeticoes) {
					errors.valorRecebido = `O valor recebido não pode ser maior que ${formatarMonetario(buscarValor())}`;
				}
				if (format(parseISO(values.dataRecebido), 'yyyy-MM-dd') > format(parseISO(values.emissao), 'yyyy-MM-dd')) {
					errors.dataRecebido = 'Recebimento da primeira parcela deve ser menor ou igual a emissão';
				}
			}
		}

		if (values.valor <= 0) {
			errors.valor = 'O valor deve ser maior que zero';
		}

		if (isBefore(startOfDay(parseISO(values.vencimento)), startOfDay(parseISO(values.emissao)))) {
			errors.vencimento = 'Venc. menor que emissão';
		}

		if (values.emissao && !isValid(parseISO(values.emissao))) {
			errors.emissao = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.vencimento && !isValid(parseISO(values.vencimento))) {
			errors.vencimento = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.competencia && !isValid(parseISO(values.competencia))) {
			errors.competencia = mensagensDeValidacao.DATA_INVALIDA;
			errors.activeIndex = [0];
		}

		if (values.dataDocumento && !isValid(parseISO(values.dataDocumento))) {
			errors.dataDocumento = mensagensDeValidacao.DATA_INVALIDA;
			errors.activeIndex = [0];
		}
		if (values.tipo !== TIPO_CONTA_RECEBER.UNICA) {
			if (values.quantidadeRepeticoes < 2) {
				errors.quantidadeRepeticoes = 'Não pode ser inferior a 2';
			}
		}

		if (values.observacao && values.observacao.length > 4096) {
			errors.activeIndex = [0];
		}

		if (values.recebido) {
			if (values.valorRecebido <= 0) {
				errors.valorRecebido = 'O valor deve ser maior que zero';
			}
			if (!isValid(parseISO(values.dataRecebido))) {
				errors.dataRecebido = mensagensDeValidacao.DATA_INVALIDA;
			}

			if (!values.contaRecebido) {
				errors.contaRecebido = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		if (!values.conta) {
			errors.conta = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.formaPagamento) {
			errors.formaPagamento = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.compensacaoAutomatica) {
			if (!values.dataCompensacao) {
				errors.dataCompensacao = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values.tipoCompensacao) {
				errors.tipoCompensacao = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		if (values.taxaValor > 0 || values.taxaAliquota > 0) {
			if (!values.taxaCategoria) {
				errors.taxaCategoria = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		vencimento: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		emissao: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		competencia: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		dataDocumento: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		valor: Yup.string().required(mensagensDeValidacao.OBRIGATORIO),
		numeroDocumento: Yup.string().max(50, 'O campo observação não pode ter mais que 50 caracteres.').nullable(),
		observacao: Yup.string().max(4096, 'O campo observação não pode ter mais que 4096 caracteres.').nullable(),
	}),

	handleSubmit: () => {},
})(ModalContaReceberView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default withRouter(connect(mapStateToProps)(ModalContaReceber));
