import { Field } from 'formik';
import { Dropdown, InputField, Checkbox } from '../../../../../../components';
import { helpFieldText, helpTitleText } from './Help';
import { INDICADOR_INSCRICAO_ESTADUAL } from '../../../Util/constantes';

export function renderizarFieldsInformacoesFiscais(values, setFieldValue, informacoesPermissoes, props, appendTo) {
	const isContribuinteIsento = values.indicadorInscricaoEstadual === 'CONTRIBUINTE_ISENTO';

	const colStyleConsumidorFinal = {
		display: 'flex',
		alignItems: 'flex-end',
		marginBottom: '7px',
		paddingBottom:
			values.indicadorInscricaoEstadual === 'CONTRIBUINTE' &&
			props.errors?.inscricaoEstadual &&
			props.submitCount > 0
				? '23px'
				: '8px',
	};

	return (
		<>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={Dropdown}
				label="Indicador de inscrição estadual"
				name="indicadorInscricaoEstadual"
				helpMessage={helpFieldText.indicadorInscricaoEstadual}
				onChange={e => {
					setFieldValue('indicadorInscricaoEstadual', e.value);
					if (e.value === 'CONTRIBUINTE_ISENTO') {
						setFieldValue('inscricaoEstadual', null);
					}
				}}
				options={INDICADOR_INSCRICAO_ESTADUAL}
				value={values.indicadorInscricaoEstadual}
				showClear={false}
				obrigatorio={values.campoObrigatorioParaDocumentoFiscal}
				menuPortalTarget={appendTo}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputField}
				size={14}
				title={isContribuinteIsento ? helpTitleText.inscricaoEstadual : ''}
				type={values && values.indicadorInscricaoEstadual === 'CONTRIBUINTE' ? 'number' : 'text'}
				label="Inscrição estadual"
				helpMessage={helpFieldText.inscricaoEstadual}
				name="inscricaoEstadual"
				onBlur={e => {
					if (e.target.value && e.target.value.toString().trim() != '') {
						if (values.indicadorInscricaoEstadual === 'NAO_CONTRIBUINTE' && !isNaN(e.target.value)) {
							setFieldValue('indicadorInscricaoEstadual', 'CONTRIBUINTE');
						}
					}
				}}
				value={
					values && values.inscricaoEstadual && values.indicadorInscricaoEstadual === 'NAO_CONTRIBUINTE'
						? values.inscricaoEstadual.toString().toUpperCase()
						: values.inscricaoEstadual
				}
				disabled={values.indicadorInscricaoEstadual === 'CONTRIBUINTE_ISENTO'}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={Checkbox}
				colStyle={colStyleConsumidorFinal}
				label="A pessoa é consumidor final"
				name="consumidorFinal"
				helpMessage={helpFieldText.consumidorFinal}
				onChange={e => setFieldValue('consumidorFinal', e.checked)}
				checked={values.consumidorFinal}
				{...informacoesPermissoes}
			/>
		</>
	);
}
