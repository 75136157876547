import { useEffect, useState } from 'react';

import { Form, FormActions, FormContent, Modal } from 'components';

import { ActionButtons } from './components/ActionButtons';
import { converterProdutosParaFormItensDevolvidos } from './Util/converter';
import { getVendaItensDevolvidos } from '../../Requests';
import { ListaProdutosDevolvidos } from './components/ListaProdutosDevolvidos';

function ModalItensDevolvidos({ vendaId, produtosPedido, visible, onHide, isMobile }) {
	const [produtos, setProdutos] = useState(converterProdutosParaFormItensDevolvidos(produtosPedido) || []);

	useEffect(() => {
		getVendaItensDevolvidos(vendaId, ({ data }) => {
			if (data?.length > 0) {
				atualizarProdutosDevolvidos(data);
			}
		});
	}, []);

	function atualizarProdutosDevolvidos(data) {
		const produtosDoPedido = produtos?.map((produto) => {
			const item = data?.find((item) => item?.vendaItemId === produto?.id) || null;
			const { quantidadeOriginal } = produto;

			if (item?.quantidade) {
				const { quantidade } = item;

				return {
					...produto,
					quantidadeDevolvida: quantidade,
					quantidadePendenteDevolucao: quantidadeOriginal - quantidade,
				};
			}

			return { ...produto };
		});

		setProdutos(produtosDoPedido);
	}

	return (
		<Modal header="Itens devolvidos" visible={visible} onHide={onHide}>
			<Form>
				<FormActions>
					<ActionButtons onHide={onHide} />
				</FormActions>
				<FormContent>
					<ListaProdutosDevolvidos produtos={produtos} isMobile={isMobile} />
				</FormContent>
			</Form>
		</Modal>
	);
}

export { ModalItensDevolvidos };
