import { get, del, exibirToast, put, post } from '../../../../Common/Requisicoes';
import { services } from '../../../../Common/Constantes/api';
import { formatISO, format } from 'date-fns';

export async function asyncGetContasPagar(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetContaPagar(idContaPagar, onSuccess, onError, exibirLoading = true) {
	return await get(`${services.GESTOR}/v1/contas_pagar/${idContaPagar}`, null, onSuccess, onError, exibirLoading);
}

export async function asyncCreateContaPagar(data, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/contas_pagar`,
		data,
		null,
		exibirToast(onSuccess, 'Conta a pagar criada com sucesso'),
		onError
	);
}

export async function asyncUpdateContaPagar(contaPagar, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/contas_pagar/${contaPagar.id}`,
		contaPagar,
		null,
		exibirToast(onSuccess, 'Conta a pagar atualizada com sucesso'),
		onError
	);
}

export async function asyncDeleteContaPagar(idContaPagar, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/contas_pagar/${idContaPagar}`,
		null,
		exibirToast(onSuccess, 'Conta a pagar removida com sucesso'),
		onError
	);
}

export async function asyncEditarContaPagarProximasEmAberto(contaPagar, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/contas_pagar/${contaPagar.id}/proximas_repeticoes_em_aberto`,
		contaPagar,
		null,
		exibirToast(onSuccess, 'Contas a pagar alteradas com sucesso'),
		onError
	);
}

export async function asyncEditarContaPagarTodasEmAberto(contaPagar, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/contas_pagar/${contaPagar.id}/todas_repeticoes_em_aberto`,
		contaPagar,
		null,
		exibirToast(onSuccess, 'Contas a pagar alteradas com sucesso'),
		onError
	);
}

export async function asyncDeleteContaPagarProximasEmAberto(idContaPagar, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/contas_pagar/${idContaPagar}/proximas_repeticoes_em_aberto`,
		null,
		exibirToast(onSuccess, 'Contas a pagar removidas com sucesso'),
		onError
	);
}

export async function asyncDeleteContaPagarTodasEmAberto(idContaPagar, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/contas_pagar/${idContaPagar}/todas_repeticoes_em_aberto`,
		null,
		exibirToast(onSuccess, 'Contas a pagar removidas com sucesso'),
		onError
	);
}

export async function buscarCategoriaFavoritaDespesa(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_pagar/relacoes/categorias/despesas?query=favoritaDespesa=="true";ativa=="true"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarFormaPagamentoDinheiro(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_pagar/relacoes/formas_pagamento?query=formaPagamentoFiscal=="DINHEIRO"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetCategoriasDespesa(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/contas_pagar/relacoes/categorias/despesas?size=500`,
		null,
		onSuccess,
		onError,
		true
	);
}

export async function asyncGetContas(onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/contas_pagar/relacoes/contas?size=500`, null, onSuccess, onError, true);
}

export async function asyncGetTotalizadoresCards(filtro, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/contas_pagar/totais?dataInicial=${formatISO(filtro.dataInicial, {
			representation: 'date',
		})}&dataFinal=${formatISO(filtro.dataFinal, { representation: 'date' })}`,
		null,
		onSuccess,
		onError,
		true
	);
}

export async function asyncEfetuarPagamento(id, onSuccess, onError) {
	return await put(
		`${services.GESTOR}/v1/contas_pagar/${id}/pagar`,
		null,
		onSuccess,
		exibirToast(onSuccess, 'Pagamento efetuado com sucesso'),
		onError
	);
}

export async function buscarContaFavoritaDespesa(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/lancamentos/relacoes/contas?query=favoritaDespesa=="true";situacao=="ATIVA"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncBaixarRelatorioContasAPagarPorPeriodo(
	dataInicial,
	dataFinal,
	contaSituacao,
	onSuccess,
	onError
) {
	const dataInicialFormatada = format(dataInicial, 'yyyy-MM-dd');
	const dataFinalFormatada = format(dataFinal, 'yyyy-MM-dd');
	return await get(
		`${services.GESTOR}/v1/contas_pagar/imprimir/contas_a_pagar_por_periodo?dataInicial=${dataInicialFormatada}&dataFinal=${dataFinalFormatada}&contaSituacao=${contaSituacao}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function buscarContaPagarPeloNumeroDocumento(numeroDocumento, onSuccess) {
	await get(`${services.GESTOR}/v1/contas_pagar?query=numeroDocumento=='${numeroDocumento}'`, null, (data) =>
		onSuccess(data)
	);
}

export async function asyncGerarReparcelamentos(titulosAntigos, novosTitulos, valores, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/contas_pagar/reparcelar_titulos`,
		{ titulosAntigos, novosTitulos, ...valores },
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function buscarTitulosNovosOuAntigosApi(idReparcelamento, tipo, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/contas_pagar/titulos_novos_antigos?idReparcelamento=${idReparcelamento}&tipo=${tipo}`,
		null,
		onSuccess,
		onError
	);
}
