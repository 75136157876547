export const ESTADOS_ADJACENTES = [
	{ estado: 'AC', estadosAdjacentes: ['AM', 'RO'] },
	{ estado: 'AL', estadosAdjacentes: ['BA', 'PE', 'SE'] },
	{ estado: 'AP', estadosAdjacentes: ['PA'] },
	{ estado: 'AM', estadosAdjacentes: ['AC', 'MT', 'PA', 'RO', 'RR'] },
	{ estado: 'BA', estadosAdjacentes: ['AL', 'ES', 'GO', 'MG', 'PE', 'PI', 'SE', 'TO'] },
	{ estado: 'CE', estadosAdjacentes: ['PB', 'PE', 'PI', 'RN'] },
	{ estado: 'DF', estadosAdjacentes: ['GO', 'MG'] },
	{ estado: 'ES', estadosAdjacentes: ['BA', 'MG', 'RJ'] },
	{ estado: 'GO', estadosAdjacentes: ['BA', 'DF', 'MG', 'MS', 'MT', 'TO'] },
	{ estado: 'MA', estadosAdjacentes: ['PA', 'PI', 'TO'] },
	{ estado: 'MT', estadosAdjacentes: ['AM', 'GO', 'MS', 'PA', 'RO', 'TO'] },
	{ estado: 'MS', estadosAdjacentes: ['GO', 'MG', 'MT', 'PR', 'SP'] },
	{ estado: 'MG', estadosAdjacentes: ['BA', 'ES', 'DF', 'GO', 'MS', 'RJ', 'SP'] },
	{ estado: 'PA', estadosAdjacentes: ['AM', 'AP', 'MA', 'MG', 'RR', 'TO'] },
	{ estado: 'PB', estadosAdjacentes: ['CE', 'PE', 'RN'] },
	{ estado: 'PR', estadosAdjacentes: ['MS', 'SC', 'SP'] },
	{ estado: 'PE', estadosAdjacentes: ['AL', 'BA', 'CE', 'PB', 'PI'] },
	{ estado: 'PI', estadosAdjacentes: ['BA', 'CE', 'MA', 'PE', 'TO'] },
	{ estado: 'RJ', estadosAdjacentes: ['ES', 'MG', 'SP'] },
	{ estado: 'RN', estadosAdjacentes: ['CE', 'PB'] },
	{ estado: 'RS', estadosAdjacentes: ['SC'] },
	{ estado: 'RO', estadosAdjacentes: ['AM', 'AC', 'MG'] },
	{ estado: 'RR', estadosAdjacentes: ['AM', 'PA'] },
	{ estado: 'SC', estadosAdjacentes: ['PR', 'RS'] },
	{ estado: 'SP', estadosAdjacentes: ['MG', 'MS', 'PR', 'RJ'] },
	{ estado: 'SE', estadosAdjacentes: ['AL', 'BA'] },
	{ estado: 'TO', estadosAdjacentes: ['BA', 'GO', 'MA', 'MG', 'PA', 'PI'] },
];

export default ESTADOS_ADJACENTES;

export function isEstadoAdjacente(ufOrigem, ufTest) {
	if (ufOrigem === ufTest) {
		return true;
	}

	const uf = ESTADOS_ADJACENTES.find((e) => e.estado === ufOrigem);

	if (uf && ufTest) {
		const result = uf.estadosAdjacentes.find((e) => e === ufTest);
		if (result) {
			return true;
		}
	}

	return false;
}
