function converterCondutorParaFormulario(value) {
	return {
		id: value.id,
		nome: value.nome,
		cpf: value.cpf,
		situacao: value.situacao,
	};
}

function converterCondutorParaApi(value) {
	return {
		id: value.id ? value.id : null,
		nome: value.nome,
		cpf: value.cpf,
		situacao: value.situacao,
	};
}

export { converterCondutorParaFormulario, converterCondutorParaApi };
