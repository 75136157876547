import { Modal } from 'components';
import { FormDadosCartao } from './FormDadosCartao';

function ModalDadosCartao({ onHide, visible, isMobile, isTablet, isLessHd }) {
	const styleModalWidth = {
		maxWidth: '825px',
		height: 'auto',
	};

	if (isMobile) {
		styleModalWidth.width = '100%';
	} else if (isTablet) {
		styleModalWidth.width = '80%';
	} else {
		styleModalWidth.width = '60%';
	}

	return (
		<Modal
			header="Dados do cartão de crédito/débito"
			onHide={onHide}
			visible={visible}
			styleModal={{
				width: styleModalWidth,
				maxWidth: '825px',
				height: 'auto',
			}}
		>
			<FormDadosCartao onHide={onHide} isMobile={isMobile} isTablet={isTablet} isLessHd={isLessHd} />
		</Modal>
	);
}

export { ModalDadosCartao };
