import {
	Button,
	Col,
	Form,
	FormActions,
	FormContent,
	Grid,
	Modal,
	ModalLoadingTransmissao,
	ToastTypes,
	notify,
} from 'components';
import { useState } from 'react';
import { adicionarNovosProdutosHelpDesk, adicionarNovosServicosHelpDesk } from './Requests';

function ModalPopularTabelas({ visible, setMostrarModalPopularTabelas }) {
	const [exibirLoading, setExibirLoading] = useState(false);
	const [mensagemLoading, setMensagemLoading] = useState('');

	function onHide() {
		setMostrarModalPopularTabelas(false);
	}

	function adicionarProdutos() {
		setExibirLoading(true);
		setMensagemLoading('Adicionando 10.000 novos produtos');

		adicionarNovosProdutosHelpDesk(
			() => {
				notify('Produtos adicionados com sucesso', ToastTypes.SUCCESS);
				setExibirLoading(false);
				setMensagemLoading('');
			},
			() => {
				notify('Ocorreu um erro ao adicionar produtos', ToastTypes.ERROR);
				setExibirLoading(false);
				setMensagemLoading('');
			}
		);
	}

	function adicionarServicos() {
		setExibirLoading(true);
		setMensagemLoading('Adicionando 10.000 novos serviços');

		adicionarNovosServicosHelpDesk(
			() => {
				notify('Serviços adicionados com sucesso', ToastTypes.SUCCESS);
				setExibirLoading(false);
				setMensagemLoading('');
			},
			() => {
				notify('Ocorreu um erro ao adicionar serviços', ToastTypes.ERROR);
				setExibirLoading(false);
				setMensagemLoading('');
			}
		);
	}

	return (
		<Modal header="Popular tabelas" visible={visible} onHide={onHide} styleModal={{ maxWidth: '480px' }}>
			<ModalLoadingTransmissao visible={exibirLoading} message={mensagemLoading} />
			<Form>
				<FormActions />
				<FormContent>
					<Grid>
						<Col sm={12} md={12} lg={12} xl={12}>
							<Button label="Adicionar produtos" onClick={adicionarProdutos} style={{ width: '100%' }} />
						</Col>
						<Col sm={12} md={12} lg={12} xl={12}>
							<Button label="Adicionar serviços" onClick={adicionarServicos} style={{ width: '100%' }} />
						</Col>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

export { ModalPopularTabelas };
