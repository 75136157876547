import { get, put, exibirToast } from '../../../../../../../../Common/Requisicoes';
import { services } from '../../../../../../../../Common/Constantes/api';
import { formatISO, isValid, isDate } from 'date-fns';

export function buscarDespesasPrevistas(dataInicial, dataFinal, onSuccess, onError) {
	let dataInicialFormatada = formatISO(new Date(1900, 1, 1), { representation: 'date' });
	let dataFinalFormatada = formatISO(new Date(2100, 1, 1), { representation: 'date' });

	if (isDate(dataInicial) && isValid(dataInicial)) {
		dataInicialFormatada = formatISO(dataInicial, { representation: 'date' });
	}
	if (isDate(dataFinal) && isValid(dataFinal)) {
		dataFinalFormatada = formatISO(dataFinal, { representation: 'date' });
	}
	return get(
		`${services.GESTOR}/v1/dashboards/financas/despesas_previstas?dataInicial=${dataInicialFormatada}&dataFinal=${dataFinalFormatada}`,
		null,
		onSuccess,
		onError,
		false
	);
}

export function efetuarPagamentoDespesa(idDespesa, onSuccess, onError) {
	return put(
		`${services.GESTOR}/v1/contas_pagar/${idDespesa}/pagar`,
		null,
		null,
		exibirToast(onSuccess, 'Pagamento efetuado com sucesso'),
		onError,
		false
	);
}
