import { del, exibirToast, get, post, put, services } from 'Common';

export async function asyncGetNotasDeVersao(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function asyncGetNotasDeVersaoResumo(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function asyncGetNotaDeVersao(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/notas_de_versao/${id}`, null, onSuccess, onError);
}

export async function asyncCreateNotaDeVersao(
	notaDeVersao,
	messageOnSuccess = 'Registro criado com sucesso',
	onSuccess,
	onError
) {
	await post(
		`${services.GESTOR}/v1/notas_de_versao`,
		notaDeVersao,
		null,
		exibirToast(onSuccess, messageOnSuccess),
		onError
	);
}

export async function asyncUpdateNotaDeVersao(
	notaDeVersao,
	messageOnSuccess = 'Registro atualizado com sucesso',
	onSuccess,
	onError
) {
	await put(
		`${services.GESTOR}/v1/notas_de_versao/${notaDeVersao.id}`,
		notaDeVersao,
		null,
		exibirToast(onSuccess, messageOnSuccess),
		onError
	);
}

export async function asyncDeleteNotaDeVersao(
	id,
	messageOnSuccess = 'Registro excluído com sucesso',
	onSuccess,
	onError
) {
	await del(`${services.GESTOR}/v1/notas_de_versao/${id}`, null, exibirToast(onSuccess, messageOnSuccess), onError);
}
