import { useEffect, useState } from 'react';
import { Field } from 'formik';
import {
	Grid,
	InputField,
	Checkbox,
	Divider,
	Button,
	If,
	SingleSelectBanco,
	Message,
	InputDouble,
} from '../../../../../../../components';
import { keyFilterConsultaRsql } from '../../../../../../../Common';
import { helpMessage } from '../../Help';
import ModalAjudaContaBancaria from './ModalAjudaContaBancaria';
import { listaBancosComDocumentacao } from '../../Util/ajudasContaBancaria';

function FormDadosBancarios(props) {
	const {
		values,
		setFieldValue,
		informacoesPermissoes,
		camposObrigatorios,
		bancoEmissorBoletos,
		bancoRecebedorPix,
		emitirBoletos,
		isTablet,
		isLessHd,
		errors,
	} = props;

	const [exibirAjudaDadosBancarios, setExibirAjudaDadosBancarios] = useState(false);
	const [disableCodigoBancoCorrespondente, setDisableCodigoBancoCorrespondente] = useState(false);
	const [possuiDocumentacao, setPossuiDocumentacao] = useState(false);
	const [messageBancoSemRetorno, setMessageBancoSemRetorno] = useState('');
	const [bancoSemRetorno, setBancoSemRetorno] = useState(false);

	useEffect(() => {
		validateBancoSemRetorno();
		validateBancoSemDocumentacao();
	}, [values?.banco?.value, camposObrigatorios]);

	function validateBancoSemDocumentacao() {
		if (listaBancosComDocumentacao.includes(values?.banco?.value)) {
			setPossuiDocumentacao(true);
		} else {
			setPossuiDocumentacao(false);
		}
	}

	function validateBancoSemRetorno() {
		if (values?.banco?.value) {
			//Santander & Banrisul
			if (
				['41b59254-ad4f-45b2-9172-39fd8cc827c3', '0d771543-f868-440f-90bc-8919e9943e24'].includes(
					values?.banco?.value
				) &&
				camposObrigatorios
			) {
				let banco = values?.banco.value === '41b59254-ad4f-45b2-9172-39fd8cc827c3' ? 'Santander' : 'Banrisul';

				setBancoSemRetorno(true);
				setMessageBancoSemRetorno(
					`O banco ${banco} não contém retorno automático, entre em contato com o suporte Microsys para verificar outras opções.`
				);

				//Bradesco
			} else if (['d5dc03be-d0f4-4063-b992-60ea79df91de'].includes(values?.banco?.value) && camposObrigatorios) {
				setBancoSemRetorno(true);
				setMessageBancoSemRetorno(
					`Para o Bradesco, o convênio com tipo WebService "NORMAL" não contém retorno automático, entre em contato com o suporte Microsys para verificar outras opções.`
				);
			} else {
				setBancoSemRetorno(false);
			}
		}

		return bancoSemRetorno;
	}

	return (
		<>
			<div className={'card-default'} style={{ padding: '8px', margin: '8px 0 8px 0' }}>
				<div style={{ position: 'relative', width: '100%' }}>
					<Divider
						label="Dados da conta bancária"
						style={{
							padding: '0 0 8px 0',
						}}
						styleLabel={{ display: 'flex', alignItems: 'center', height: '32px' }}
						styleLine={{ margin: '0 0 8px 0' }}
					/>
					<Button
						type="button"
						color="info"
						icon="fa fa-question"
						title={
							!values?.banco?.name
								? 'Selecione um banco'
									? !possuiDocumentacao
										? 'Banco sem documentação'
										: 'Ajuda preenchimento da conta bancária'
									: 'Ajuda preenchimento da conta bancária'
								: 'Ajuda preenchimento da conta bancária'
						}
						hidden={false}
						raised={true}
						rounded={true}
						style={{
							position: 'absolute',
							zIndex: '9999',
							width: '32px',
							height: '32px',
							right: '0rem',
							top: '-0.2rem',
						}}
						onClick={() => setExibirAjudaDadosBancarios(true)}
						enableShortCut={false}
						disabled={!values?.banco || !emitirBoletos || !possuiDocumentacao}
					/>
				</div>
				<If test={bancoSemRetorno}>
					<Message severity="warn" text={messageBancoSemRetorno} />
				</If>
				<Grid>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						component={SingleSelectBanco}
						label="Banco"
						obrigatorio
						keyfilter={keyFilterConsultaRsql}
						name="contaBancaria.banco"
						helpMessage={helpMessage.banco}
						value={values?.banco}
						errors={errors?.banco}
						touched={!!errors?.banco}
						useFormErrors={false}
						useFormTouched={false}
						bancoEmissorBoletos={bancoEmissorBoletos}
						onChange={(e) => setFieldValue('contaBancaria.banco', e)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="10"
						md="2"
						lg="2"
						xl="2"
						component={InputField}
						label="Número"
						keyfilter={keyFilterConsultaRsql}
						name="contaBancaria.numeroConta"
						obrigatorio={camposObrigatorios}
						size={255}
						helpMessage={helpMessage.numeroConta}
						value={values?.numeroConta}
						errors={errors?.numeroConta}
						touched={!!errors?.numeroConta}
						useFormErrors={false}
						useFormTouched={false}
						onChange={(e) => setFieldValue('contaBancaria.numeroConta', e.target.value)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="2"
						md="1"
						lg="1"
						xl="1"
						component={InputField}
						keyfilter={keyFilterConsultaRsql}
						label="Dígito"
						name="contaBancaria.digitoConta"
						size={1}
						helpMessage={helpMessage.digitoConta}
						value={values?.digitoConta}
						errors={errors?.digitoConta}
						touched={!!errors?.digitoConta}
						useFormErrors={false}
						useFormTouched={false}
						onChange={(e) => setFieldValue('contaBancaria.digitoConta', e.target.value)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="10"
						md="2"
						lg="2"
						xl="2"
						component={InputField}
						label="Agência"
						keyfilter={keyFilterConsultaRsql}
						name="contaBancaria.agencia"
						obrigatorio={camposObrigatorios}
						size={255}
						helpMessage={helpMessage.agencia}
						value={values?.agencia}
						errors={errors?.agencia}
						touched={!!errors?.agencia}
						useFormErrors={false}
						useFormTouched={false}
						onChange={(e) => setFieldValue('contaBancaria.agencia', e.target.value)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="2"
						md="1"
						lg="1"
						xl="1"
						component={InputField}
						keyfilter={keyFilterConsultaRsql}
						label="Dígito"
						name="contaBancaria.digitoAgencia"
						size={1}
						helpMessage={helpMessage.digitoAgencia}
						value={values?.digitoAgencia}
						errors={errors?.digitoAgencia}
						touched={!!errors?.digitoAgencia}
						useFormErrors={false}
						useFormTouched={false}
						onChange={(e) => setFieldValue('contaBancaria.digitoAgencia', e.target.value)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						component={InputField}
						label={isTablet || isLessHd ? 'Cód. beneficiário' : 'Código beneficiário'}
						keyfilter={keyFilterConsultaRsql}
						name="contaBancaria.codigoBeneficiario"
						obrigatorio={camposObrigatorios}
						size={255}
						helpMessage={helpMessage.codigoBeneficiario}
						value={values?.codigoBeneficiario}
						errors={errors?.codigoBeneficiario}
						touched={!!errors?.codigoBeneficiario}
						useFormErrors={false}
						useFormTouched={false}
						onChange={(e) => setFieldValue('contaBancaria.codigoBeneficiario', e.target.value)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						component={InputField}
						label={isTablet || isLessHd ? 'Cód. banco corresp.' : 'Código banco correspondente'}
						keyfilter={keyFilterConsultaRsql}
						name="contaBancaria.codigoBancoCorrespondente"
						size={255}
						helpMessage={helpMessage.codigoBancoCorrespondente}
						value={values?.codigoBancoCorrespondente}
						errors={errors?.codigoBancoCorrespondente}
						touched={!!errors?.codigoBancoCorrespondente}
						useFormErrors={false}
						useFormTouched={false}
						onChange={(e) => setFieldValue('contaBancaria.codigoBancoCorrespondente', e.target.value)}
						disabled={disableCodigoBancoCorrespondente}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						component={InputField}
						label={isTablet || isLessHd ? 'Cód. conta empresa' : 'Código conta empresa'}
						keyfilter={keyFilterConsultaRsql}
						name="contaBancaria.codigoContaEmpresa"
						size={255}
						helpMessage={helpMessage.codigoContaEmpresa}
						value={values?.codigoContaEmpresa}
						errors={errors?.codigoContaEmpresa}
						touched={!!errors?.codigoContaEmpresa}
						useFormErrors={false}
						useFormTouched={false}
						onChange={(e) => setFieldValue('contaBancaria.codigoContaEmpresa', e.target.value)}
						{...informacoesPermissoes}
					/>
					<Grid style={{ paddingTop: '2rem', paddingLeft: '8px' }}>
						<Field
							component={Checkbox}
							label="Validação da API ativa"
							name="contaBancaria.validacaoAPIAtiva"
							checked={values?.validacaoAPIAtiva}
							helpMessage={helpMessage.validacaoAPIAtiva}
							value={values?.validacaoAPIAtiva}
							errors={errors?.validacaoAPIAtiva}
							touched={!!errors?.validacaoAPIAtiva}
							useFormErrors={false}
							useFormTouched={false}
							onChange={(e) => setFieldValue('contaBancaria.validacaoAPIAtiva', !values?.validacaoAPIAtiva)}
							{...informacoesPermissoes}
						/>
					</Grid>
					<If test={bancoRecebedorPix}>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputField}
							label={'Client id pix'}
							keyfilter={keyFilterConsultaRsql}
							name="contaBancaria.clientIdPix"
							size={50}
							obrigatorio
							helpMessage={helpMessage.clientIdPix}
							value={values?.clientIdPix}
							errors={errors?.clientIdPix}
							touched={!!errors?.clientIdPix}
							useFormErrors={false}
							useFormTouched={false}
							onChange={(e) => setFieldValue('contaBancaria.clientIdPix', e.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputField}
							label={'Client secret pix'}
							keyfilter={keyFilterConsultaRsql}
							name="contaBancaria.clientSecretPix"
							size={50}
							obrigatorio
							helpMessage={helpMessage.clientSecretPix}
							value={values?.clientSecretPix}
							errors={errors?.clientSecretPix}
							touched={!!errors?.clientSecretPix}
							useFormErrors={false}
							useFormTouched={false}
							onChange={(e) => setFieldValue('contaBancaria.clientSecretPix', e.target.value)}
							{...informacoesPermissoes}
						/>

						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputField}
							label={'Chave pix'}
							keyfilter={keyFilterConsultaRsql}
							name="contaBancaria.chavePix"
							size={50}
							obrigatorio
							helpMessage={helpMessage.chavePix}
							value={values?.chavePix}
							errors={errors?.chavePix}
							touched={!!errors?.chavePix}
							useFormErrors={false}
							useFormTouched={false}
							onChange={(e) => setFieldValue('contaBancaria.chavePix', e.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="3"
							lg="3"
							xl="3"
							component={InputDouble}
							label="% Multa pix"
							name="contaBancaria.percentualMultaPix"
							onChange={(e) => setFieldValue('contaBancaria.percentualMultaPix', e.target.value)}
							decimalScale={2}
							allowNegative={false}
							errors={errors?.percentualMultaPix}
							touched={!!errors?.percentualMultaPix}
							useFormErrors={false}
							useFormTouched={false}
							prefix=""
							suffix="%"
							maxValue={100}
							placeholder="0,00 %"
							obrigatorio
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="3"
							lg="3"
							xl="3"
							component={InputDouble}
							label="% Juros pix"
							name="contaBancaria.percentualJurosPix"
							onChange={(e) => setFieldValue('contaBancaria.percentualJurosPix', e.target.value)}
							decimalScale={2}
							allowNegative={false}
							errors={errors?.percentualJurosPix}
							touched={!!errors?.percentualJurosPix}
							useFormErrors={false}
							useFormTouched={false}
							prefix=""
							suffix="%"
							maxValue={100}
							placeholder="0,00 %"
							obrigatorio
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="3"
							lg="3"
							xl="3"
							component={InputDouble}
							label="% Desconto pix"
							name="contaBancaria.percentualDescontoPix"
							onChange={(e) => setFieldValue('contaBancaria.percentualDescontoPix', e.target.value)}
							decimalScale={2}
							allowNegative={false}
							errors={errors?.percentualDescontoPix}
							touched={!!errors?.percentualDescontoPix}
							useFormErrors={false}
							useFormTouched={false}
							prefix=""
							suffix="%"
							maxValue={100}
							placeholder="0,00 %"
							obrigatorio
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="3"
							lg="3"
							xl="3"
							component={InputDouble}
							label="Dias validade pix"
							name="contaBancaria.diasValidadePix"
							onChange={(e) => setFieldValue('contaBancaria.diasValidadePix', e.target.value)}
							decimalScale={0}
							allowNegative={false}
							errors={errors?.diasValidadePix}
							touched={!!errors?.diasValidadePix}
							useFormErrors={false}
							useFormTouched={false}
							prefix=""
							suffix=""
							placeholder="0"
							obrigatorio
							{...informacoesPermissoes}
						/>
					</If>
				</Grid>
			</div>
			<If test={exibirAjudaDadosBancarios}>
				<ModalAjudaContaBancaria
					visible={exibirAjudaDadosBancarios}
					onHide={() => setExibirAjudaDadosBancarios(false)}
					bancoSelecionado={values?.banco}
				/>
			</If>
		</>
	);
}

export default FormDadosBancarios;
