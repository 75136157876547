let keys = {}

export function converterParaTreeTable(arrayEntrada) {
    let childrens = []

    arrayEntrada.forEach((categoria, key) => {
        childrens.push(recursiveFunction( key, categoria))
    });
    return {
        root: childrens,
        chaves: keys,
    }
}

function recursiveFunction( key, categoria) {
    let childrens = [];
    if (categoria.subCategorias.length > 0) {
        
        keys = {...keys, [key]: true}
        categoria.subCategorias.forEach((categoria, key2) => {
            let chave = `${key}-${key2}`;
            keys = {...keys, [chave]: true}
            childrens.push(recursiveFunction( chave, categoria))
        });
    }
    return {
        key: `${key}`,
        data: categoria,
        children: childrens
    }
}