import { Field, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';

import {
	AutoProgressBar,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	InputMask,
	Modal,
	SingleSelectCondutor,
} from 'components';
import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { converterMDFeCondutorIndicadoParaForm } from 'views/transporte/MDFe/Form/Util/MDFeConverter';
import { mensagensDeValidacao } from 'Common';
import { isValidCPF } from '@brazilian-utils/brazilian-utils';
import { ActionButtons } from './components/ActionButtons';

function ModalDadosCondutorIndicadoImpl({ visible, onHide, canTransmitir, id }) {
	const { informacoesPermissoes } = useContextMDFe();
	const { values, setValues, setFieldValue } = useFormikContext();

	function handleChangeCondutor(e) {
		const newCondutor = {
			id: null,
			sequenciaEvento: values.sequenciaEvento,
			condutor: e,
			nome: e.registro?.nome,
			cpf: e.registro?.cpf,
		};
		setValues(newCondutor);
	}

	return (
		<Modal header="Dados condutor indicado" visible={visible} onHide={onHide} styleModal={{ maxWidth: '650px' }}>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ActionButtons onHide={onHide} canTransmitir={canTransmitir} id={id} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={InputField}
							label="Sequencial"
							value={values.sequenciaEvento}
							name="sequenciaEvento"
							disabled
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="9"
							lg="9"
							xl="9"
							name="condutor"
							component={SingleSelectCondutor}
							obrigatorio
							value={values.condutor}
							onChange={handleChangeCondutor}
							url="/v1/mdfes/relacoes/condutores"
							isClearable={false}
							placeholder="Selecione"
							touched
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<Field
							sm="9"
							md="9"
							lg="9"
							xl="9"
							component={InputField}
							obrigatorio
							size={60}
							label="Nome"
							name="nome"
							value={values.nome}
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							label="CPF"
							component={InputMask}
							obrigatorio
							name="cpf"
							mask="000.000.000-00"
							placeholder="   .   .   -  "
							value={values.cpf}
							onChange={(e) => setFieldValue('cpf', e.target.value)}
							disabled={!canTransmitir}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalDadosCondutorIndicado = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,

	mapPropsToValues({ values, proximoSequencial }) {
		if (Object.keys(values)?.length > 0) {
			return converterMDFeCondutorIndicadoParaForm(values);
		} else {
			return {
				id: null,
				sequenciaEvento: proximoSequencial,
				condutor: null,
				nome: null,
				cpf: null,
			};
		}
	},

	validate(values) {
		const errors = {};

		if (values.cpf && !isValidCPF(values.cpf)) {
			errors.cpf = 'CPF inválido';
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		condutor: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		cpf: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalDadosCondutorIndicadoImpl);

export { ModalDadosCondutorIndicado };
