import { TabView } from 'components';
import { TabPanel } from 'primereact/tabview';
import { TabCondutores } from '../TabCondutores';
import { TabReboques } from '../TabReboques';

function TabsVeiculosImpl({ informacoesPermissoes }) {
	return (
		<div style={{ margin: '8px', width: '100%' }}>
			<TabView className="tab-view step-veiculo-tabs" {...informacoesPermissoes}>
				<TabPanel header="Condutores">
					<TabCondutores informacoesPermissoes={informacoesPermissoes} />
				</TabPanel>
				<TabPanel header="Reboques">
					<TabReboques informacoesPermissoes={informacoesPermissoes} />
				</TabPanel>
			</TabView>
		</div>
	);
}

export const TabsVeiculos = TabsVeiculosImpl;
