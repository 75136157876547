import { useState } from 'react';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { Field, withFormik } from 'formik';
import {
	Modal,
	Message,
	ModalLoadingTransmissao,
	Grid,
	If,
	InputField,
	TextArea,
	Button,
	ToastTypes,
	notify,
} from '../../../../../../../../../components';
import { mensagensDeValidacao } from '../../../../../../../../../Common';
import { asyncCancelarNfce } from '../../../../../Requests';
import { Status } from '../../../../../Util/constantes';
import { validarFormulario } from '../../../../../../../../Util';
import { converterCancelamentoParaApi } from '../../../../../Util/NFCeConverter';
import { useContextNFCe } from '../../../../../Context';

const initialValue = {
	justificativa: null,
	dataCancelamento: format(new Date(), 'dd/MM/yyyy HH:mm'),
};

function ModalCancelamento(props) {
	const {
		values,
		valuesFormPrincipal,
		idNFCe,
		setFieldValue,
		onHide,
		isMobile,
		visible,
		resetFormPrincipal,
		isFullScreen,
	} = props;

	const { podeEditar } = useContextNFCe();

	const NfceCancelada = valuesFormPrincipal.status === Status.CANCELADA;

	const [mostrarLoadingTransmissao, setMostrarLoadingTransmissao] = useState(false);

	function montarInformacaoDeCancelamento() {
		return (
			<span>
				<div>
					O prazo para cancelamento da NFC-e é de no máximo
					<b> 30 minutos</b>, sendo que esse prazo pode ser reduzido a critério de cada estado.
				</div>
				<b
					style={{ cursor: 'pointer', textDecoration: 'underline' }}
					onClick={() => window.open('https://www.confaz.fazenda.gov.br/legislacao/ajustes/2018/AJ0007_18')}
				>
					Clique aqui para mais informações
				</b>
			</span>
		);
	}

	function cancelar() {
		onHide();
	}

	async function confirmar() {
		props.handleSubmit();

		if (await validarFormulario(props)) {
			setMostrarLoadingTransmissao(true);
			asyncCancelarNfce(
				idNFCe,
				converterCancelamentoParaApi(values.justificativa),
				() => {
					setMostrarLoadingTransmissao(false);
					resetFormPrincipal({
						values: {
							...valuesFormPrincipal,
							status: Status.CANCELADA,
							dataCancelamento: new Date().toISOString(),
							justificativaCancelamento: values.justificativa,
						},
					});
					onHide();

					notify('NFC-e cancelada com sucesso', ToastTypes.SUCCESS);

					let layoutFullscreen = document.getElementsByClassName('layout-fullscreen');
					let alertifyNotifier = document.getElementsByClassName('alertify-notifier');

					if (isFullScreen) {
						layoutFullscreen[0].append(alertifyNotifier[0]);
					}
				},
				() => {
					setMostrarLoadingTransmissao(false);

					notify('Erro ao cancelar NFC-e', ToastTypes.ERROR);

					let layoutFullscreen = document.getElementsByClassName('layout-fullscreen');
					let alertifyNotifier = document.getElementsByClassName('alertify-notifier');

					if (isFullScreen) {
						layoutFullscreen[0].append(alertifyNotifier[0]);
					}
				}
			);
		}
	}

	return (
		<Modal
			header="Cancelamento NFC-e"
			onHide={onHide}
			visible={visible}
			styleModal={{
				minWidth: '60%',
				height: isMobile ? '85%' : 'auto',
			}}
			isMobile={isMobile}
			container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
		>
			<ModalLoadingTransmissao
				visible={mostrarLoadingTransmissao}
				message="Cancelando NFC-e..."
				container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
			/>
			<Message
				severity="info"
				style={{ marginBottom: '12px' }}
				colStyle={{ padding: '5px 0px' }}
				text={montarInformacaoDeCancelamento()}
			/>
			<Grid>
				<If test={NfceCancelada}>
					<Field
						sm="12"
						component={InputField}
						label="Data e hora do evento"
						value={values.dataCancelamento}
						name="dataCancelamento"
						disabled
					/>
				</If>
				<Field
					sm="12"
					component={TextArea}
					label="Justificativa do cancelamento"
					name="justificativa"
					obrigatorio
					maxLength={255}
					disabled={NfceCancelada || !podeEditar}
					value={values.justificativa}
					errors={props.errors.justificativa}
					touched={!!props.errors.justificativa}
					onChange={e => setFieldValue('justificativa', e.target.value)}
				/>
			</Grid>
			<Grid justifyEnd>
				<span style={{ padding: '0.5em' }}>
					<Button color="secondary" icon="fa fa-arrow-left" label="Voltar" onClick={cancelar} />
					<Button
						color="danger"
						icon={NfceCancelada ? 'fa fa-check' : 'fa fa-send'}
						label={NfceCancelada ? 'NFC-e cancelada' : 'Cancelar NFC-e'}
						severity="info"
						disabled={NfceCancelada || !podeEditar}
						onClick={confirmar}
						style={{ marginLeft: '10px' }}
					/>
				</span>
			</Grid>
		</Modal>
	);
}

ModalCancelamento = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.values.justificativa) {
			return props.values;
		}

		return initialValue;
	},

	validationSchema: Yup.object().shape({
		justificativa: Yup.string()
			.nullable()
			.trim()
			.min(15, 'A descrição deve ter mais de 15 caracteres')
			.required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalCancelamento);

export default ModalCancelamento;
