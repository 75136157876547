import { InputDate } from 'components';

function VencimentoMemorizado(props) {
	const { exibirLabels, isMobile, onChangeVencimento, valueVencimento, errors, ...rest } = props;

	return (
		<InputDate
			label={exibirLabels ? 'Vencimento' : ''}
			helpMessage={exibirLabels ? 'Data do vencimento desta parcela' : ''}
			name="vencimento"
			obrigatorio={exibirLabels}
			onChange={onChangeVencimento}
			value={valueVencimento}
			errors={errors && errors.vencimento}
			touched
			{...rest}
		/>
	);
}

export default VencimentoMemorizado;
