import crypto from 'crypto-js'

const CHAVEPADRAO = '992d614b594911e516d2ffeb0ded2500'

export function criptografarLocalStorage(texto, chave = CHAVEPADRAO) {

    if(!texto) return '';

    const criptografado = crypto.AES.encrypt(String(texto), chave)
    
    return criptografado.toString();
}

export function descriptografarLocalStorage(texto, chave = CHAVEPADRAO) {

    if (!texto) return '';

    const descriptografado = crypto.AES.decrypt(String(texto), chave);

    return descriptografado.toString(crypto.enc.Utf8);
}


export function converterArquivoParaBase64(arquivo, onSuccess, onError) {
    let reader = new FileReader();
    reader.readAsDataURL(arquivo);
  
    reader.onload = () => onSuccess(reader.result)
    reader.onerror = error => onError(error)
}