import { formatISO } from 'date-fns';
import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';

export const CADASTRO_URL = '/mdfes/cadastro';
export const PESQUISA_URL = '/mdfes';

export const MDFE_SITUACAO = {
	NAO_ENVIADO: 'NAO_ENVIADO',
	AGUARDANDO_AUTORIZACAO: 'AGUARDANDO_AUTORIZACAO',
	TRANSMITIDO: 'TRANSMITIDO',
	REJEITADO: 'REJEITADO',
	CANCELADO: 'CANCELADO',
	ENCERRADO: 'ENCERRADO',
	DENEGADO: 'DENEGADO',
};

export const MDFE_SITUACAO_OPTIONS = [
	{
		value: MDFE_SITUACAO.NAO_ENVIADO,
		label: 'Não enviado',
	},
	{
		value: MDFE_SITUACAO.AGUARDANDO_AUTORIZACAO,
		label: 'Aguardando autorização',
	},
	{
		value: MDFE_SITUACAO.TRANSMITIDO,
		label: 'Transmitido',
	},
	{
		value: MDFE_SITUACAO.REJEITADO,
		label: 'Rejeitado',
	},
	{
		value: MDFE_SITUACAO.CANCELADO,
		label: 'Cancelado',
	},
	{
		value: MDFE_SITUACAO.ENCERRADO,
		label: 'Encerrado',
	},
	{
		value: MDFE_SITUACAO.DENEGADO,
		label: 'Denegado',
	},
];

export const MDFE_TIPO_EMITENTE = {
	PRESTADOR_SERVICO: 'PRESTADOR_SERVICO',
	CARGA_PROPRIA: 'CARGA_PROPRIA',
};

export const MDFE_TIPO_TRANSPORTADOR = {
	ETC: 'ETC',
	TAC: 'TAC',
	CTC: 'CTC',
};

export const MDFE_CATEGORIA_COMBINACAO_VEICULAR = {
	VEICULO_COMERCIAL_2_EIXOS: 'VEICULO_COMERCIAL_2_EIXOS',
	VEICULO_COMERCIAL_3_EIXOS: 'VEICULO_COMERCIAL_3_EIXOS',
	VEICULO_COMERCIAL_4_EIXOS: 'VEICULO_COMERCIAL_4_EIXOS',
	VEICULO_COMERCIAL_5_EIXOS: 'VEICULO_COMERCIAL_5_EIXOS',
	VEICULO_COMERCIAL_6_EIXOS: 'VEICULO_COMERCIAL_6_EIXOS',
	VEICULO_COMERCIAL_7_EIXOS: 'VEICULO_COMERCIAL_7_EIXOS',
	VEICULO_COMERCIAL_8_EIXOS: 'VEICULO_COMERCIAL_8_EIXOS',
	VEICULO_COMERCIAL_9_EIXOS: 'VEICULO_COMERCIAL_9_EIXOS',
	VEICULO_COMERCIAL_10_EIXOS: 'VEICULO_COMERCIAL_10_EIXOS',
	VEICULO_COMERCIAL_ACIMA_10_EIXOS: 'VEICULO_COMERCIAL_ACIMA_10_EIXOS',
};

export const MDFE_VEICULO_RODADO = {
	TRUCK: 'TRUCK',
	TOCO: 'TOCO',
	CAVALO_MECANICO: 'CAVALO_MECANICO',
	VAN: 'VAN',
	UTILITARIO: 'UTILITARIO',
	OUTROS: 'OUTROS',
};

export const MDFE_VEICULO_CARROCERIA = {
	NAO_APLICAVEL: 'NAO_APLICAVEL',
	ABERTA: 'ABERTA',
	FECHADA_BAU: 'FECHADA_BAU',
	GRANELEIRA: 'GRANELEIRA',
	PORTA_CONTAINER: 'PORTA_CONTAINER',
	SIDER: 'SIDER',
};

export const MDFE_TIPO_CARGA_PREDOMINANTE = {
	GRANEL_SOLIDO: 'GRANEL_SOLIDO',
	GRANEL_LIQUIDO: 'GRANEL_LIQUIDO',
	FRIGORIFICADA: 'FRIGORIFICADA',
	CONTEINERIZADA: 'CONTEINERIZADA',
	CARGA_GERAL: 'CARGA_GERAL',
	NEOGRANEL: 'NEOGRANEL',
	PERIGOSA_GRANEL_SOLIDO: 'PERIGOSA_GRANEL_SOLIDO',
	PERIGOSA_GRANEL_LIQUIDO: 'PERIGOSA_GRANEL_LIQUIDO',
	PERIGOSA_FRIGORIFICADA: 'PERIGOSA_FRIGORIFICADA',
	PERIGOSA_CONTEINERIZADA: 'PERIGOSA_CONTEINERIZADA',
	PERIGOSA_CARGA_GERAL: 'PERIGOSA_CARGA_GERAL',
};

export const MDFE_TIPO_PEDIDA_PESO = {
	KG: 'KG',
	TON: 'TON',
};

export const MDFE_SEGURADORA_RESPONSAVEL = {
	EMITENTE: 'EMITENTE',
	CONTRATANTE: 'CONTRATANTE',
};

export const MDFE_TIPO_VALE_PEDAGIO = {
	CARTAO: 'CARTAO',
	CUPOM: 'CUPOM',
	TAG: 'TAG',
};

export const MDFE_TIPO_PAGAMENTO_COMPONENTE = {
	VALE_PEDAGIO: 'VALE_PEDAGIO',
	IMPOSTOS_TAXAS_CONTRIBUICOES: 'IMPOSTOS_TAXAS_CONTRIBUICOES',
	DESPESAS: 'DESPESAS',
	OUTROS: 'OUTROS',
};

export const INITIAL_VALUES = {
	id: null,
	numero: null,
	serie: null,
	chave: null,
	protocolo: null,
	situacao: MDFE_SITUACAO.NAO_ENVIADO,
	tipoEmitente: MDFE_TIPO_EMITENTE.CARGA_PROPRIA,
	tipoTransportador: MDFE_TIPO_TRANSPORTADOR.ETC,
	categoriaCombinacaoVeicular: null,
	emissao: formatISO(new Date()),
	dataTransmissao: null,
	inicioViagem: null,
	ufCarregamento: null,
	ufDescarregamento: null,
	municipioCarregamento: null,
	veiculoTracao: {
		id: null,
		veiculo: null,
		placa: null,
		renavam: null,
		estado: null,
		tara: null,
		capacidade: null,
		rodado: MDFE_VEICULO_RODADO.TRUCK,
		carroceria: MDFE_VEICULO_CARROCERIA.ABERTA,
	},
	rntrc: null,
	xml: null,
	produtoPredominante: null,
	total: {
		id: null,
		quantidadeDocumento: 0,
		medidaPeso: MDFE_TIPO_PEDIDA_PESO.KG,
		pesoBruto: 0,
		valorTotal: 0,
	},
	informacaoComplementar: {
		id: null,
		usuario: null,
		setor: null,
		informacaoComplementar: null,
		observacaoInterna: null,
		permiteCarregamentoPosterior: false,
	},
	ciot: null,
	encerramento: null,
	dataCancelamento: null,
	motivoCancelamento: null,
	xmlCancelamento: null,
	ufsPercurso: [],
	municipios: [],
	condutores: [],
	reboques: [],
	seguradoras: [],
	valePedagio: [],
	lacres: [],
	erros: [],
};

export const INITIAL_VALUE_VALE_PEDAGIO = {
	id: null,
	fornecedoraCnpj: null,
	numeroComprovante: null,
	valePedagioTipo: MDFE_TIPO_VALE_PEDAGIO.CARTAO,
	valePedagioValor: 0,
	tipoPessoa: TIPO_PESSOA.JURIDICA,
	cnpj: null,
	cpf: null,
};

export const INITIAL_VALUE_LACRES = {
	id: null,
	lacre: null,
};

export const INITIAL_VALUES_DFE = {
	id: null,
	sequenciaEvento: null,
	carregamentoMunicipio: null,
	descarregamentoMunicipio: [],
};

export const INITIAL_VALUES_PAGAMENTO = {
	id: null,
	sequenciaEvento: null,
	tipoPessoa: TIPO_PESSOA.JURIDICA,
	nome: null,
	cnpj: null,
	cpf: null,
	idEstrangeiro: null,
	valorContrato: null,
	tipoPagamento: null,
	quantidadeParcelas: 0,
	valorAdiantamento: null,
	indicadorAltoDesempenho: false,
	banco: null,
	bancoAgencia: null,
	cnpjBancoPagamentoEletronico: null,
	chavePix: null,
	componentes: [],
	prazos: [],
};

export const INITIAL_VALUE_SEGURADORA = {
	id: null,
	responsavel: MDFE_SEGURADORA_RESPONSAVEL.CONTRATANTE,
	seguradora: null,
	tipoPessoa: TIPO_PESSOA.JURIDICA,
	cnpj: null,
	cpf: null,
	apolice: null,
	averbacoes: [],
};

export const INITIAL_VALUES_CONDUTOR_INDICADO = {
	id: null,
	sequenciaEvento: null,
	condutor: null,
	cpf: null,
	nome: null,
};

export const MDFE_INFORMACOES_SITUACAO = {
	[MDFE_SITUACAO.NAO_ENVIADO]: {
		name: 'Não enviado',
		description: 'MDF-e não enviado para o sefaz',
		lightColor: '#B3E5FC',
		strongColor: '#006095',
	},
	[MDFE_SITUACAO.AGUARDANDO_AUTORIZACAO]: {
		name: 'Aguardando',
		description: 'O MDF-e está sendo processado pelo sefaz',
		lightColor: '#E7ECF0',
		strongColor: '#333333',
	},
	[MDFE_SITUACAO.TRANSMITIDO]: {
		name: 'Transmitido',
		description: 'MDF-e transmitido para o sefaz com sucesso',
		lightColor: '#DCEDC8',
		strongColor: '#1B5E20',
	},
	[MDFE_SITUACAO.REJEITADO]: {
		name: 'Rejeitado',
		description: 'MDF-e rejeitado pelo sefaz',
		lightColor: '#FFECB3',
		strongColor: '#765100',
	},
	[MDFE_SITUACAO.CANCELADO]: {
		name: 'Cancelado',
		description: 'MDF-e cancelado',
		lightColor: '#FFCDD2',
		strongColor: '#B71C1C',
	},
	[MDFE_SITUACAO.ENCERRADO]: {
		name: 'Encerrado',
		description: 'MDF-e encerrado',
		lightColor: '#E7ECF0',
		strongColor: '#333333',
	},
	[MDFE_SITUACAO.DENEGADO]: {
		name: 'Denegado',
		description: 'MDF-e denegado pelo sefaz',
		lightColor: '#FFCDD2',
		strongColor: '#B71C1C',
	},
};

export const ClassNameTabViewColor = {
	NAO_ENVIADO: 'tab-view-mdfe-pendente',
	AGUARDANDO: 'tab-view-mdfe-aguardando',
	TRANSMITIDO: 'tab-view-mdfe-transmitido',
	ENCERRADO: 'tab-view-mdfe-encerrado',
	REJEITADO: 'tab-view-mdfe-rejeitado',
	CANCELADO: 'tab-view-mdfe-cancelado',
	DENEGADO: 'tab-view-mdfe-denegado',
};

export const ClassNameInputValorTotalColor = {
	NAO_ENVIADO: 'input-double-valor-total-pendente',
	AGUARDANDO: 'input-double-valor-total-aguardando',
	TRANSMITIDO: 'input-double-valor-total-transmitido',
	ENCERRADO: 'input-double-valor-total-encerrado',
	REJEITADO: 'input-double-valor-total-rejeitado',
	CANCELADO: 'input-double-valor-total-cancelado',
	DENEGADO: 'input-double-valor-total-denegado',
};

export const MDFE_TIPO_EMITENTE_OPTIONS = [
	{
		label: 'Transportador de carga própria',
		value: MDFE_TIPO_EMITENTE.CARGA_PROPRIA,
	},
	{
		label: 'Prestador de serviço de transporte',
		value: MDFE_TIPO_EMITENTE.PRESTADOR_SERVICO,
	},
];

export const MDFE_TIPO_TRANSPORTADOR_OPTIONS = [
	{
		label: 'ETC',
		value: MDFE_TIPO_TRANSPORTADOR.ETC,
	},
	{
		label: 'TAC',
		value: MDFE_TIPO_TRANSPORTADOR.TAC,
	},
	{
		label: 'CTC',
		value: MDFE_TIPO_TRANSPORTADOR.CTC,
	},
];

export const MDFE_SEGURADORA_RESPONSAVEL_OPTIONS = [
	{ label: 'Emitente', value: MDFE_SEGURADORA_RESPONSAVEL.EMITENTE },
	{ label: 'Contratante do serviço de transporte', value: MDFE_SEGURADORA_RESPONSAVEL.CONTRATANTE },
];

export const MDFE_TIPO_VALE_PEDAGIO_OPTIONS = [
	{ label: 'Cartão', value: MDFE_TIPO_VALE_PEDAGIO.CARTAO },
	{ label: 'Cupom', value: MDFE_TIPO_VALE_PEDAGIO.CUPOM },
	{ label: 'Tag', value: MDFE_TIPO_VALE_PEDAGIO.TAG },
];

export const MDFE_CATEGORIA_COMBINACAO_VEICULAR_OPTIONS = [
	{ label: 'Veículo comercial 2 eixos', value: MDFE_CATEGORIA_COMBINACAO_VEICULAR.VEICULO_COMERCIAL_2_EIXOS },
	{ label: 'Veículo comercial 3 eixos', value: MDFE_CATEGORIA_COMBINACAO_VEICULAR.VEICULO_COMERCIAL_3_EIXOS },
	{ label: 'Veículo comercial 4 eixos', value: MDFE_CATEGORIA_COMBINACAO_VEICULAR.VEICULO_COMERCIAL_4_EIXOS },
	{ label: 'Veículo comercial 5 eixos', value: MDFE_CATEGORIA_COMBINACAO_VEICULAR.VEICULO_COMERCIAL_5_EIXOS },
	{ label: 'Veículo comercial 6 eixos', value: MDFE_CATEGORIA_COMBINACAO_VEICULAR.VEICULO_COMERCIAL_6_EIXOS },
	{ label: 'Veículo comercial 7 eixos', value: MDFE_CATEGORIA_COMBINACAO_VEICULAR.VEICULO_COMERCIAL_7_EIXOS },
	{ label: 'Veículo comercial 8 eixos', value: MDFE_CATEGORIA_COMBINACAO_VEICULAR.VEICULO_COMERCIAL_8_EIXOS },
	{ label: 'Veículo comercial 9 eixos', value: MDFE_CATEGORIA_COMBINACAO_VEICULAR.VEICULO_COMERCIAL_9_EIXOS },
	{ label: 'Veículo comercial 10 eixos', value: MDFE_CATEGORIA_COMBINACAO_VEICULAR.VEICULO_COMERCIAL_10_EIXOS },
	{
		label: 'Veículo comercial acima de 10 eixos',
		value: MDFE_CATEGORIA_COMBINACAO_VEICULAR.VEICULO_COMERCIAL_ACIMA_10_EIXOS,
	},
];

export const MDFE_TIPO_CARGA_PREDOMINANTE_OPTIONS = [
	{ label: 'Carga geral', value: MDFE_TIPO_CARGA_PREDOMINANTE.CARGA_GERAL },
	{ label: 'Carga geral (perigosa)', value: MDFE_TIPO_CARGA_PREDOMINANTE.PERIGOSA_CARGA_GERAL },
	{ label: 'Conteinerizada', value: MDFE_TIPO_CARGA_PREDOMINANTE.CONTEINERIZADA },
	{ label: 'Conteinerizada (perigosa)', value: MDFE_TIPO_CARGA_PREDOMINANTE.PERIGOSA_CONTEINERIZADA },
	{ label: 'Frigorificada', value: MDFE_TIPO_CARGA_PREDOMINANTE.FRIGORIFICADA },
	{ label: 'Frigorificada (perigosa)', value: MDFE_TIPO_CARGA_PREDOMINANTE.PERIGOSA_FRIGORIFICADA },
	{ label: 'Granel líquido', value: MDFE_TIPO_CARGA_PREDOMINANTE.GRANEL_LIQUIDO },
	{ label: 'Granel líquido (perigosa)', value: MDFE_TIPO_CARGA_PREDOMINANTE.PERIGOSA_GRANEL_LIQUIDO },
	{ label: 'Granel sólido', value: MDFE_TIPO_CARGA_PREDOMINANTE.GRANEL_SOLIDO },
	{ label: 'Granel sólido (perigosa)', value: MDFE_TIPO_CARGA_PREDOMINANTE.PERIGOSA_GRANEL_SOLIDO },
	{ label: 'Neogranel', value: MDFE_TIPO_CARGA_PREDOMINANTE.NEOGRANEL },
];

export const MDFE_TIPO_PAGAMENTO_COMPONENTE_OPTIONS = [
	{ label: 'Despesas', value: MDFE_TIPO_PAGAMENTO_COMPONENTE.DESPESAS },
	{ label: 'Impostos/taxas de contribuições', value: MDFE_TIPO_PAGAMENTO_COMPONENTE.IMPOSTOS_TAXAS_CONTRIBUICOES },
	{ label: 'Outros', value: MDFE_TIPO_PAGAMENTO_COMPONENTE.OUTROS },
	{ label: 'Vale pedágio', value: MDFE_TIPO_PAGAMENTO_COMPONENTE.VALE_PEDAGIO },
];

export const ERROR_MESSAGES = {
	CHAVE_ACESSO_INVALIDA: 'Chave de acesso inválida',
};

export const INITIAL_VALUE_ENVIAR_POR_EMAIL = {
	assunto: null,
	conteudo: null,
	destinatarios: null,
	enviarCopia: false,
};
