import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import HotKey from 'react-shortcut';
import { withRouter } from 'react-router';
import * as Yup from 'yup';
import { Field, useFormikContext, withFormik } from 'formik';
import { Button } from 'primereact/button';

import {
	If,
	InputPassword,
	ModalLoadingTransmissao,
	FormGroup,
	Grid,
	AutoProgressBar,
	ToastTypes,
	notify,
	Card,
} from 'components';
import { fazerLogoutLocalStorange, mensagensDeValidacao } from '../../../Common';

import logo from '../../../layout/Images/logo_microsys.svg';
import { cardStyle, contentStyle, errorStyle, imgStyle, subTitleStyle } from '../Styles';
import { asyncConfirmarSenha } from './Requests';

function ConfirmarSenhaImpl({ urlConfirmacao }) {
	const { handleSubmit, isValid, values, validateForm, errors } = useFormikContext();
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const messageLoading = 'Estamos preparando tudo para você...';

	useEffectOnce(() => {
		const location = window.location.toString();
		if (location.indexOf('token=') === -1) {
			setErrorMessage('Link de autenticação expirado!');
		}

		const token = location.substring(location.indexOf('token=') + 6);
		values.token = token;
	});

	async function criarSenha() {
		await validateForm(values);
		handleSubmit();

		if (isValid && values.senha && values.senha.length > 0) {
			setLoading(true);
			asyncConfirmarSenha(
				urlConfirmacao,
				values,
				() => {
					fazerLogoutLocalStorange();
					window.location = '#/login';
					notify('Senha cadastada com sucesso!', ToastTypes.SUCCESS);
				},
				(e) => {
					if (!e.response || !e.response.data) {
						setLoading(false);
						setErrorMessage('Erro de conexão com a internet!');
					} else {
						setLoading(false);
						setErrorMessage(e.response.data.message);
					}
				}
			);
		}
	}

	function onKeyDownSenha(e) {
		if (e.key === 'Enter') {
			document.getElementById('input_confirmarSenha').getElementsByTagName('input')[0]?.focus();
		}
	}

	function onKeyDownConfirmarSenha(e) {
		if (e.key === 'Enter') {
			criarSenha();
		}
	}

	function renderErrorMessage() {
		return (
			<div style={errorStyle}>
				<center>
					<h4 style={{ margin: 0, color: '#751111' }}>{errorMessage}</h4>
				</center>
			</div>
		);
	}

	return (
		<>
			<AutoProgressBar />
			<div style={contentStyle}>
				<Card style={cardStyle}>
					{errorMessage ? renderErrorMessage : undefined}
					<div className="right-side p-g-12 p-lg-12" style={{ display: 'flex', justifyContent: 'center' }}>
						<img alt="Logo Microsys" style={imgStyle} src={logo} />
					</div>
					<div className="left-side p-g-12 p-lg-12">
						<h3 style={subTitleStyle}>Bem-vindo, por favor crie uma senha de acesso.</h3>
						<div className="content-section implementation">
							<FormGroup>
								<Grid>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputPassword}
										label="Senha"
										feedback={false}
										name="senha"
										onKeyDown={onKeyDownSenha}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										id="input_confirmarSenha"
										component={InputPassword}
										label="Confirmação da senha"
										name="confirmarSenha"
										errors={errors.confirmarSenha}
										feedback={false}
										onKeyDown={onKeyDownConfirmarSenha}
									/>
									<Button
										type="button"
										label="Criar senha"
										disabled={loading}
										style={{
											margin: '7px 0 18px 0',
											height: '40px',
											width: '100%',
										}}
										onClick={criarSenha}
									/>
								</Grid>
							</FormGroup>
							<HotKey
								keys={['enter']}
								onKeysPressed={() => {
									criarSenha();
								}}
							/>
						</div>
					</div>
				</Card>
			</div>
			<If test={loading}>
				<ModalLoadingTransmissao visible={loading} message={messageLoading} />
			</If>
		</>
	);
}

const ConfirmarSenha = withFormik({
	validateOnBlur: false,
	mapPropsToValues() {
		return {
			senha: '',
			confirmarSenha: '',
			token: '',
		};
	},

	validate: (e) => {
		const errors = {};
		if (e.senha !== e.confirmarSenha) {
			errors.confirmarSenha = 'As senhas não coincidem!';
		}
		return errors;
	},

	validationSchema: Yup.object().shape({
		senha: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.test('len', 'A senha deve ter no mínimo 8 caracteres', (val) => val && val.length >= 8)
			.test('mustHaveNumber', 'A senha deve ter pelo menos um caracter numérico', (val) => val && val.match(/\d+/g)),
		confirmarSenha: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.test('len', 'A senha deve ter no mínimo 8 caracteres', (val) => val && val.length >= 8)
			.test('mustHaveNumber', 'A senha deve ter pelo menos um caracter numérico', (val) => val && val.match(/\d+/g)),
	}),
	handleSubmit: () => {},
})(ConfirmarSenhaImpl);

export default withRouter(ConfirmarSenha);
