import { useState } from 'react';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';

import { asyncCorrigirNFe } from '../../../Requests';
import { validarFormulario } from '../../../../../../Util';

import { Modal, Button, Grid, TextArea, Message, ModalLoadingTransmissao } from '../../../../../../../components';
import { mensagensDeValidacao } from '../../../../../../../Common';

function ModalCartaCorrecao(props) {
	const { onHide, handleSubmit, visible, values, setFieldValue, idNfe } = props;
	const [mostrarLoadingTransmissao, setMostrarLoadingTransmissao] = useState(false);

	function cancelar() {
		onHide();
	}

	async function confirmar() {
		handleSubmit();

		if (await validarFormulario(props)) {
			setMostrarLoadingTransmissao(true);
			asyncCorrigirNFe(
				idNfe,
				converterParaApi(),
				() => {
					setMostrarLoadingTransmissao(false);
					onHide(values);
				},
				() => {
					setMostrarLoadingTransmissao(false);
					onHide(values);
				}
			);
		}
	}

	function converterParaApi() {
		if (values.descricaoCorrecao) {
			return {
				xCorrecao: String(values.descricaoCorrecao).trim(),
			};
		}
	}

	function montarInformacaoMessage() {
		return (
			<>
				<div style={{ marginBottom: '7px' }}>
					A Carta de Correção é disciplinada pelo parágrafo 1º-A do art. 7º do Convenio S/N, de 15 de dezembro de 1970 e
					pode ser utilizada para regularização de erro ocorrido na emissão de documento fiscal, desde que o erro não
					esteja relacionado com:
				</div>
				<div>
					I - as variáveis que determinam o valor do imposto tais como: base de cálculo, alíquota, diferença de preço,
					quantidade, valor da operação ou da prestação;
				</div>
				<div>II - a correção de dados cadastrais que implique mudança do remetente ou do destinatário;</div>
				<div>III - a data de emissão ou de saída.</div>
			</>
		);
	}

	return (
		<>
			<ModalLoadingTransmissao visible={mostrarLoadingTransmissao} message="Transmitindo carta de correção..." />
			<Modal header="Corrigir nota fiscal" visible={visible} onHide={() => onHide()}>
				<Message style={{ marginBottom: '7px' }} severity="info" text={montarInformacaoMessage()} />
				<Grid>
					<Field
						sm="12"
						component={TextArea}
						label="Descrição do que está sendo corrigido"
						name="descricaoCorrecao"
						maxLength={255}
						value={values.descricaoCorrecao}
						errors={props.errors?.descricaoCorrecao}
						touched={!!props.errors?.descricaoCorrecao}
						onChange={e => setFieldValue('descricaoCorrecao', e.target.value)}
					/>
				</Grid>
				<Grid justifyEnd style={{ marginTop: '10px', marginRight: '0px' }}>
					<Button color="secondary" icon="fa fa-arrow-left" label="Voltar" onClick={cancelar} />
					<Button
						color="primary"
						icon="fa fa-send"
						label="Corrigir nota fiscal"
						onClick={confirmar}
						style={{ marginLeft: '10px' }}
					/>
				</Grid>
			</Modal>
		</>
	);
}

ModalCartaCorrecao = withFormik({
	enableReinitialize: true,
	validateOnChange: false,

	mapPropsToValues(props) {
		return {
			descricaoCorrecao: '',
		};
	},

	validationSchema: Yup.object().shape({
		descricaoCorrecao: Yup.string()
			.nullable()
			.trim()
			.min(15, 'A descrição deve ter mais de 15 caracteres')
			.required(mensagensDeValidacao.OBRIGATORIO),
	}),
	handleSubmit: () => {},
})(ModalCartaCorrecao);

export default ModalCartaCorrecao;
