import Col from '../../../../../../../components/Col';
import Grid from '../../../../../../../components/Grid';
import { formatarDecimais } from '../../../../../../../Common/Mascara';

const styleCardContent = {
	borderRadius: '5px',
};

const styleTitle = {
	fontSize: '14px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'end',
	padding: '0px',
};

const styleValue = {
	fontSize: '18px',
	marginTop: '5px',
	fontWeight: 'bold',
	overflow: 'hidden',
};

const SimpleCard = ({ primaryColor, title, value, helpMessage, formatarValueDecimais = true, disabled, decimais }) => {	
	return (
		<div className="p-card" style={styleCardContent}>
			<Grid verticalAlignCenter justifyBetween style={{ margin: '0px', opacity: disabled ? '0.7' : '1' }}>
				<Col col="12">
					<Grid justifyEnd verticalAlignCenter style={{ margin: '0px' }}>
						<div className="p-col" style={styleTitle} title={helpMessage}>
							{title}
						</div>
					</Grid>
					<Grid justifyEnd style={{ margin: '0px' }}>
						<div
							style={{
								...styleValue,
								color: primaryColor,
								marginRight: formatarValueDecimais ? '0' : '0.5rem',
							}}
						>
							{formatarValueDecimais ? formatarDecimais(value, decimais) : value}
						</div>
					</Grid>
				</Col>
			</Grid>
		</div>
	);
};

export default SimpleCard;
