import { InternalInputDate } from './components/InternalInputDate';

export default function InputDate(props) {
	const { field, form, useFormErrors = true, useFormTouched = true, ...rest } = props;
	const useForm = useFormErrors && useFormTouched;

	return (
		<InternalInputDate
			{...field}
			{...rest}
			errors={form && useForm ? form?.errors[field.name] : props.errors}
			touched={form && useForm ? Boolean(form?.touched[field.name]) : Boolean(props.touched)}
			onBlur={props.onBlur}
		/>
	);
}
