import propTypes from 'prop-types';
import MultipleSelect from '../MultipleSelect';

export default function MultipleSelectCategorias({ url, obrigatorio, label, onChange, ...props }) {
	function buscarUrlPesquisa(pesquisa, page) {
		return `${url}?query=nome=contains="*${pesquisa}*"&page=${page}&size=50&sort=nome`;
	}

	function montarLabel(row) {
		if (row) {
			return row.nome;
		}

		return '';
	}

	return (
		<MultipleSelect montarLabel={montarLabel} buscarUrlPesquisa={buscarUrlPesquisa} onChange={onChange} {...props} />
	);
}

MultipleSelectCategorias.propTypes = {
	url: propTypes.string.isRequired,
	obrigatorio: propTypes.bool,
	onChange: propTypes.func.isRequired,
	label: propTypes.string,
};

MultipleSelectCategorias.defaultProps = {
	label: 'Categorias',
	obrigatorio: false,
};
