import { get, del, exibirToast, put, post } from '../../../../../Common/Requisicoes';
import { services } from '../../../../../Common/Constantes/api';
import formatISO from 'date-fns/formatISO';
import { buscarDadosLoginLocalStorage } from '../../../../../Common/Autenticacao';

export async function asyncGetResumoNotas(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function asyncGetVenda(urlModulo, idVenda, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/${urlModulo}/${idVenda}`, null, onSuccess, onError);
}

export async function asyncDeleteNfe(idNfe, messageOnSuccess = 'Registro excluído com sucesso', onSuccess, onError) {
	await del(`${services.GESTOR}/v1/nfes/${idNfe}`, null, exibirToast(onSuccess, messageOnSuccess), onError);
}

export async function asyncCreateNfe(data, messageOnSuccess = 'Registro criado com sucesso', onSuccess, onError) {
	await post(`${services.GESTOR}/v1/nfes`, data, null, exibirToast(onSuccess, messageOnSuccess), onError);
}

export async function asyncUpdateNfe(nfe, messageOnSuccess = 'Registro atualizado com sucesso', onSuccess, onError) {
	await put(`${services.GESTOR}/v1/nfes/${nfe.id}`, nfe, null, exibirToast(onSuccess, messageOnSuccess), onError);
}

export async function asyncTransmitirNFe(id, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/nfes/${id}/transmitir`, null, null, onSuccess, onError);
}

export async function asyncCorrigirNFe(id, payload, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/nfes/${id}/correcao`,
		payload,
		null,
		exibirToast(onSuccess, 'Nota fiscal corrigida'),
		onError
	);
}

export async function asyncCancelarNfe(id, payload, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/nfes/${id}/cancelar`,
		payload,
		null,
		exibirToast(onSuccess, 'Nota fiscal cancelada'),
		onError
	);
}

export async function asyncGetTotalizadoresCard(interval, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/nfes/totais?dataInicial=${formatISO(interval.dataInicial, {
			representation: 'date',
		})}&dataFinal=${formatISO(interval.dataFinal, { representation: 'date' })}`,
		null,
		onSuccess,
		onError
	);
}

export async function asyncRecalcularNfe(payload, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/nfes/calcular`, payload, null, onSuccess, onError, false);
}

export async function imprimirDANFE(idNfe, onSuccess, onError) {
	return get(`${services.GESTOR}/v1/nfes/${idNfe}/pdf`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function imprimirEspelhoDANFE(idNfe, onSuccess, onError) {
	return get(`${services.GESTOR}/v1/nfes/${idNfe}/espelho`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function imprimirCorrecao(idNfe, onSuccess, onError) {
	return get(`${services.GESTOR}/v1/nfes/${idNfe}/correcao/pdf`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function imprimirXMLNfe(idNfe, onSuccess, onError) {
	return get(`${services.GESTOR}/v1/nfes/${idNfe}/xml`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function imprimirXMLRejeicaoNfe(idNfe, onSuccess, onError) {
	return get(`${services.GESTOR}/v1/nfes/${idNfe}/xml_rejeicao`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function consultarNfe(idNfe, onSuccess, onError) {
	return get(`${services.GESTOR}/v1/nfes/${idNfe}/consultar`, null, onSuccess, onError);
}

export async function imprimirXMLCorrecao(idNfe, onSuccess, onError) {
	return get(`${services.GESTOR}/v1/nfes/${idNfe}/correcao/xml`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function asyncEnviarNfePorEmail(nfeId, body, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/nfes/${nfeId}/enviar_por_email`,
		body,
		null,
		exibirToast(onSuccess, 'Email(s) enviado(s) com sucesso'),
		onError
	);
}

export async function asyncBuscarEmailsPessoa(pessoaId, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/nfes/relacoes/clientes/${pessoaId}/emails`, null, onSuccess, onError);
}

export async function asyncGetTotalizadores(interval, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/nfes/totais?dataInicial=${formatISO(interval.dataInicial, {
			representation: 'date',
		})}&dataFinal=${formatISO(interval.dataFinal, { representation: 'date' })}`,
		null,
		onSuccess,
		onError
	);
}

export async function asyncGetContasReceber(pessoaId, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/nfes/financeiro_por_pessoa/${pessoaId}`, null, onSuccess, onError);
}

export async function asyncGetContaFavorita(onSucess, onError) {
	await get(`${services.GESTOR}/v1/nfes/relacoes/contas?query=favoritaReceita==true`, null, onSucess, onError);
}

export async function asyncGetCondicaoPagamentoFavorita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfes/relacoes/condicoes_pagamento?query=(favorita==true);(movimentacao==SAIDA)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetCondicaoPagamentoSemPagamento(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfes/relacoes/condicoes_pagamento?query=(tipo==SEM_PAGAMENTO);(movimentacao==SAIDA)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetFormaPagamentoDinheiro(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfes/relacoes/formas_pagamento?query=(formaPagamentoFiscal==DINHEIRO)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarVendedorInicial(onSuccess) {
	await get(
		`${services.GESTOR}/v1/nfes/relacoes/vendedores?filtro=&page=0&size=500&sort=codigo,asc`,
		null,
		({ data }) => {
			if (data && data.content && data.content.length > 0) {
				let indexFavorito =
					Math.max(
						data.content?.findIndex((vendedor) => vendedor.favorito === true),
						0
					) ?? 0;
				onSuccess({
					label: `${data.content[indexFavorito]?.codigo} - ${data.content[indexFavorito]?.nome}`,
					value: data.content[indexFavorito].id,
					registro: data.content[indexFavorito],
				});
			} else {
				onSuccess(null);
			}
		}
	);
}

export async function asyncGetDownloadXmls(filtro, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/nfes/xmls/download${filtro}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncBuscarSetorResponsavel() {
	let dados = {};
	const { setores, setorFavorito } = await asyncBuscarSetoresUsuario();

	dados.setores = setores;
	dados.setor = setorFavorito;

	return dados;
}

async function asyncBuscarSetoresUsuario() {
	let result = {};

	await get(
		`${services.GESTOR}/v1/nfes/relacoes/setores`,
		null,
		({ data }) => {
			result.setores = data.content;
			result.setorFavorito = data.content.find((setor) => setor.favorito);
		},
		null,
		false
	);

	return result;
}

export async function asyncBuscarTabelaPreco(dadosRequest, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfes/relacoes/buscar_tabela_a_utilizar?municipioId=${
			dadosRequest?.municipioId ?? ''
		}&clienteId=${dadosRequest?.clienteId ?? ''}&vendedorId=${dadosRequest?.vendedorId ?? ''}`,
		null,
		onSuccess,
		onError
	);
}

export async function asyncBaixarRelatorioFaturamentoPorPeriodo(
	dataInicial,
	dataFinal,
	documentos,
	operacoes,
	setores,
	onSuccess,
	onError
) {
	return await get(
		`${services.GESTOR}/v1/nfes/imprimir/faturamento_por_periodo?dataInicial=${dataInicial}&dataFinal=${dataFinal}&documentos=${documentos}&operacoes=${operacoes}&setores=${setores}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}
