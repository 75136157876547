import { If, Modal } from 'components';
import MarcaForm from '../Form';

export default function ModalMarca(props) {
	return (
		<Modal
			className={props.visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de marca"
			visible={props.visible}
			onHide={props.onHide}
			styleModal={{ maxWidth: '1200px' }}
		>
			<If test={props.visible}>
				<MarcaForm hideModal={props.onHide} isModal={true} history={props.history} />
			</If>
		</Modal>
	);
}
