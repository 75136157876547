const DATA_ACTION_TYPES = {
  ON_CHANGE_VALOR_PESQUISA: 'ON_CHANGE_VALOR_PESQUISA',
  SET_TOTAL_RECORDS: 'SET_TOTAL_RECORDS',
  SET_REGISTROS: 'SET_REGISTROS',
  SET_SIZE: 'SET_SIZE',
  SET_ROWS: 'SET_ROWS',
  SET_PAGE: 'SET_PAGE',
  SET_SORT_FIELD: 'SET_SORT_FIELD',
  SET_SORT_ORDER: 'SET_SORT_ORDER',
};

function onChangeValorPesquisa(data) {
  return {
    type: DATA_ACTION_TYPES.ON_CHANGE_VALOR_PESQUISA,
    payload: {
      content: data,
    },
  };
}

function setTotalRecords(data) {
  return {
    type: DATA_ACTION_TYPES.SET_TOTAL_RECORDS,
    payload: {
      content: data,
    },
  };
}

function setRegistros(data) {
  return {
    type: DATA_ACTION_TYPES.SET_REGISTROS,
    payload: {
      content: data,
    },
  };
}

function setSize(data) {
  return {
    type: DATA_ACTION_TYPES.SET_SIZE,
    payload: {
      content: data,
    },
  };
}

function setRows(data) {
  return {
    type: DATA_ACTION_TYPES.SET_ROWS,
    payload: {
      content: data,
    },
  };
}

function setPage(data) {
  return {
    type: DATA_ACTION_TYPES.SET_PAGE,
    payload: {
      content: data,
    },
  };
}
function setSortField(data) {
  return {
    type: DATA_ACTION_TYPES.SET_SORT_FIELD,
    payload: {
      content: data,
    },
  };
}
function setSortOrder(data) {
  return {
    type: DATA_ACTION_TYPES.SET_SORT_ORDER,
    payload: {
      content: data,
    },
  };
}

export {
  DATA_ACTION_TYPES,
  onChangeValorPesquisa,
  setTotalRecords,
  setRegistros,
  setSize,
  setRows,
  setPage,
  setSortField,
  setSortOrder,
};
