import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { buscarReceitasPrevistas } from './Requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../Common/Mascara';
import { format, parse } from 'date-fns';
import { Button } from 'primereact/button';
import { usuarioPossuiPermissao } from '../../../../../../../Common/Autenticacao';
import { recursos, permissoes } from '../../../../../../../Common/Constantes/autorizacao';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import { Link } from 'react-router-dom';
import If from '../../../../../../../components/If';
import ModalRecebimentoTable from '../../../../../../financas/ContasReceber/components/ModalRecebimentoTable';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { Financas } from '../../../../../../Util/constantes';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';

import './Styles/index.css';

const Divider = () => {
	return <hr id="divider-previsoes-receitas" />;
};

const List = props => {
	return (
		<div id="previsoes-receitas-content" className="dashboard-scroll">
			<ul style={{ listStyle: 'none', padding: '0px 3px', margin: '0px' }}>{props.children}</ul>
		</div>
	);
};

const ListItem = props => {
	return (
		<li style={{ margin: '6px 0px' }}>
			<div
				className="p-grid"
				style={{
					margin: '0px 6px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					paddingBottom: '0.5rem',
				}}
			>
				<span id="previsoes-receitas-title" title={props.descricao} style={{ whiteSpace: 'normal' }}>
					{props.itemRepeticao && props.quantidadeRepeticoes
						? `${props.descricao || 'Nenhuma descrição informada'} (${props.itemRepeticao}/${
								props.quantidadeRepeticoes
						  })`
						: `${props.descricao || 'Nenhuma descrição informada'}`}
				</span>
				{props.podeEfetuarRecebimento ? (
					<div className="p-col-fixed" style={{ paddingLeft: '0.5rem', color: Financas.cores.vermelho }}>
						<div id="previsoes-receitas-button-content">
							<Button
								className="p-button p-button-secondary"
								icon="fa fa-check"
								title="Efetuar recebimento da receita"
								onClick={props.efetuarPagamento}
							/>
						</div>
					</div>
				) : null}
			</div>
			<div
				className="p-grid p-align-center"
				style={{ display: 'flex', margin: '0px', justifyContent: 'space-between' }}
			>
				<div className="p-col" style={{ padding: '0px 6px' }}>
					<p id="previsoes-receitas-coluna-data">
						{format(parse(props.vencimento, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}
					</p>
				</div>
				<div
					className="p-col"
					id="previsoes-receitas-coluna-valor"
					style={{ color: `${Financas.cores.verde}` }}
				>
					{formatarMonetario(props.valor)}
				</div>
			</div>
		</li>
	);
};

export default function PrevisoesDeReceitas(props) {
	const { dataInicial, dataFinal, atualizarGraficoNovamente, atualizarGraficos, isMobile } = props;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [receitasPrevistas, setReceitasPrevistas] = useState([]);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [exibirModalConfirmarRecebimento, setExibirModalConfirmarRecebimento] = useState(false);
	const [podeVisualizarContasReceber, setPodeVisualizarContasReceber] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR)
	);
	const [podeEditarContasReceber, setPodeEditarContasReceber] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EDITAR)
	);
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	useEffect(() => {
		carregarInformacoes();
	}, [atualizarGraficoNovamente]);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}

			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;
	}, [dataInicial, dataFinal]);

	useEffect(() => {
		buscarReceitasPrevistas(
			dataInicial,
			dataFinal,
			({ data: receitasPrevistas }) => {
				setReceitasPrevistas(receitasPrevistas);
				setLoading(false);
				setError(false);
			},
			() => {
				setLoading(false);
				setError(true);
			}
		);
	}, [loading, atualizarGraficoNovamente]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	function getHeaderCard() {
		return (
			<div id="card-header-previsoes-receitas">
				<label title="Lista as receitas pendentes para o período informado">
					Receitas pendentes do período
				</label>
			</div>
		);
	}

	function onHideModalRecebimentoTable(atualizou) {
		if (atualizou) {
			setExibirModalConfirmarRecebimento(false);
			atualizarGraficos();
		} else {
			setExibirModalConfirmarRecebimento(false);
		}
	}

	function onEfetuarPagamento(receitaPrevista) {
		setExibirModalConfirmarRecebimento(true);
		setRegistroSelecionado({ ...receitaPrevista, valorAReceber: receitaPrevista.valor });
	}

	if (loading) {
		return (
			<Card
				header={getHeaderCard()}
				style={{ margin: isMobile ? '8px' : '0px', borderRadius: '5px', height: '100%' }}
			>
				<ProgressBar mode="indeterminate" style={{ height: '3px' }} />
			</Card>
		);
	} else if (error) {
		return (
			<Card
				header={getHeaderCard()}
				style={{ margin: isMobile ? '8px' : '0px', borderRadius: '5px', height: '100%' }}
			>
				<FalhaAoCarregarAsInformacoes />
			</Card>
		);
	} else {
		return (
			<>
				<Card
					header={getHeaderCard()}
					style={{ margin: isMobile ? '8px' : '0px', borderRadius: '5px', height: '100%' }}
				>
					{receitasPrevistas.length > 0 ? (
						<>
							<List>
								{receitasPrevistas.map((receitaPrevista, idx, array) => {
									return (
										<React.Fragment key={receitaPrevista.id}>
											<ListItem
												id={receitaPrevista.id}
												descricao={receitaPrevista.descricao}
												vencimento={receitaPrevista.vencimento}
												valor={receitaPrevista.valor}
												quantidadeRepeticoes={receitaPrevista.quantidadeRepeticoes}
												itemRepeticao={receitaPrevista.itemRepeticao}
												efetuarPagamento={() => onEfetuarPagamento(receitaPrevista)}
												podeEfetuarRecebimento={
													podeEditarContasReceber && podeVisualizarContasReceber
												}
											/>
											{++idx !== array.length ? <Divider /> : null}
										</React.Fragment>
									);
								})}
							</List>
							<Divider />
							{podeVisualizarContasReceber && (
								<div style={{ textAlign: 'center', padding: '1rem 0' }}>
									<Link
										to={{
											pathname: '/contas_receber',
											state: {
												dataInicial: dataInicial,
												dataFinal: dataFinal,
											},
										}}
									>
										Listar receitas
									</Link>
								</div>
							)}
						</>
					) : (
						<NenhumaInformacaoEncontrada />
					)}
				</Card>
				<If test={exibirModalConfirmarRecebimento}>
					<ModalRecebimentoTable
						registroSelecionado={registroSelecionado}
						visible={exibirModalConfirmarRecebimento}
						onHide={onHideModalRecebimentoTable}
					/>
				</If>
			</>
		);
	}
}
