import { format, parseISO } from 'date-fns';
import { Field, useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';

import { buscarDadosLoginLocalStorage, construirUrl, formatarMonetario, services } from 'Common';
import {
	Col,
	Form,
	FormActions,
	FormContent,
	Modal,
	NenhumRegistroEncontrado,
	Paginacao,
	SingleSelectEstado,
} from 'components';

import { readMDFeImportarNFes } from 'views/transporte/MDFe/Requests';
import { ActionButtons } from './components/ActionButtons';

const styleColumn = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

function ModalImportacaoNFe({ visible, onHide }) {
	const { values, setFieldValue } = useFormikContext();

	const [isFirstRender, setIsFirstRender] = useState(true);
	const [totalElements, setTotalElements] = useState(0);
	const [rows, setRows] = useState(10);
	const [page, setPage] = useState(0);
	const [sortOrder] = useState(-1);
	const [sortField] = useState('numero');
	const [registros, setRegistros] = useState(null);
	const [registrosSelecionados, setRegistrosSelecionados] = useState(null);

	const url = construirUrl(
		`${services.GESTOR}/v1/mdfes/listar_nfes_importacao`,
		`?ufDestinatario=${values?.ufDescarregamento?.registro?.sigla}`,
		rows,
		page,
		sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
	);

	useEffect(() => {
		if (values.ufDescarregamento) {
			pesquisar();
		}
		setIsFirstRender(false);
	}, []);

	useEffect(() => {
		if (!isFirstRender) {
			pesquisar();
		}
	}, [rows, page, values.ufDescarregamento]);

	function pesquisar() {
		readMDFeImportarNFes(url, ({ data: notas }) => {
			setRegistros(notas?.content ?? []);
			setTotalElements(notas.totalElements);
		});
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function buscarSerieMDFe(serie, serieConfiguracoes) {
		if (serie) {
			return serie;
		}

		if (serieConfiguracoes.serie) {
			return serieConfiguracoes.serie;
		}

		return '?';
	}

	function renderNumeroField(row) {
		const { filialConectada } = buscarDadosLoginLocalStorage();
		const serieConfiguracoes = filialConectada.parametrosFiscalMDFe;

		return (
			<div>
				<span>{buscarSerieMDFe(row.serie, serieConfiguracoes)}</span>
				<span>{' / '}</span>
				<span style={{ fontWeight: 'bolder' }}>{row.numero || '-'}</span>
			</div>
		);
	}

	function renderEmissaoField(row) {
		return <span>{row ? format(parseISO(row.emissao), 'dd/MM/yyyy') : ' - '}</span>;
	}

	function handleChangeUfDescarregamento(e) {
		setFieldValue('ufDescarregamento', e ?? null);
		setFieldValue('ufsPercurso', []);
	}

	return (
		<Modal
			header="Importar notas para o MDF-e"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '900px' }}
			closeOnEsc={false}
		>
			<Form>
				<FormActions>
					<Col style={{ paddingLeft: '0px' }}>
						<ActionButtons onHide={onHide} registrosSelecionados={registrosSelecionados} />
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							name="ufDescarregamento"
							label="UF descarregamento"
							obrigatorio
							component={SingleSelectEstado}
							value={values.ufDescarregamento}
							onChange={handleChangeUfDescarregamento}
							colStyle={{ paddingLeft: '0px' }}
							isClearable={false}
						/>
					</Col>
				</FormActions>
				<FormContent>
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						sortField={sortField}
						sortOrder={sortOrder}
						value={registros}
						style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden', maxHeight: '382px' }}
						emptyMessage={<NenhumRegistroEncontrado message="Informe um estado para buscar as notas" />}
						selection={registrosSelecionados}
						onSelectionChange={(e) => setRegistrosSelecionados(e.value)}
						dataKey="id"
					>
						<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
						<Column
							header="Série/Número"
							field="numero"
							sortable
							body={renderNumeroField}
							style={{ color: '#333333', width: '130px' }}
						/>
						<Column
							field="nomeDest"
							header="Cliente"
							body={(row) => row.nomeDest}
							sortable
							style={{
								...styleColumn,
								width: '30%',
							}}
						/>
						<Column
							field="emissao"
							header="Emissão"
							body={(row) => renderEmissaoField(row)}
							sortable
							style={{
								...styleColumn,
								width: '8%',
							}}
						/>
						<Column
							field="ufDest"
							header="UF destinatário"
							body={(row) => row.ufDest}
							sortable
							style={{
								...styleColumn,
								width: '8%',
							}}
						/>
						<Column
							field="valorTotal"
							header="Valor"
							body={(row) => formatarMonetario(row.valorTotal)}
							sortable
							style={{
								...styleColumn,
								width: '8%',
							}}
						/>
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={(e) => onPageChange(e)} />
				</FormContent>
			</Form>
		</Modal>
	);
}

export { ModalImportacaoNFe };
