import { copiarObjeto, mensagensDeValidacao } from 'Common';
import {
	AutoProgressBar,
	ButtonAdicionarItem,
	ButtonCancelar,
	ButtonExcluirTable,
	ButtonNovo,
	ButtonNovoTable,
	ButtonSalvar,
	Divider,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	Modal,
	TextArea,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	estadosBotaoSalvar,
} from 'components';
import { Field, withFormik } from 'formik';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { validarFormulario } from 'views/Util';
import { asyncCreateNotaDeVersao, asyncGetNotaDeVersao, asyncUpdateNotaDeVersao } from '../../Request';
import { converterNotaDeVersaoParaApi, converterNotaDeVersaoParaFormulario } from '../../Utils/NotasDeVersaoConverter';
import { TIPO_APLICACAO, TIPO_NOTA_VERSAO, optionsTipoAplicacao, optionsTipoNotaVersao } from '../../Utils/constantes';

import './Styles/index.css';

const initialValues = {
	id: '',
	versao: '',
	aplicacao: TIPO_APLICACAO.MSYS_GESTOR,
	titulos: [{ titulo: '', itens: [] }],
};

const buttonAdicionarStyle = {
	background: 'none',
	border: 'none',
	fontWeight: 'bold',
	boxShadow: 'none',
	color: '#1B5E20',
	marginTop: '0.7rem',
	paddingLeft: '0',
};

function ModalNotaDeVersaoView(props) {
	const {
		visible,
		onHide,
		isAdmin,
		idRegistroSelecionado,
		isMobile,
		dirty,
		values,
		setFieldValue,
		resetForm,
		handleSubmit,
	} = props;

	useEffect(() => {
		if (idRegistroSelecionado) {
			asyncGetNotaDeVersao(idRegistroSelecionado, ({ data: notaDeVersaoApi }) => {
				const notaDeVersao = converterNotaDeVersaoParaFormulario(notaDeVersaoApi);

				resetForm({ values: notaDeVersao });
			});
		}
	}, []);

	function cancelar() {
		dirty ? resetForm() : onHide();
	}

	function salvar() {
		handleSubmit();
		if (validarFormulario(props)) {
			const notaDeVersao = converterNotaDeVersaoParaApi(values);
			if (idRegistroSelecionado || values.id) {
				asyncUpdateNotaDeVersao(notaDeVersao, 'Registro atualizado com sucesso', () => {
					resetForm({ values: values });
				});
			} else {
				asyncCreateNotaDeVersao(notaDeVersao, 'Registro criado com sucesso', ({ data }) => {
					resetForm({ values: { ...values, id: data.id } });
				});
			}
		}
	}

	function onClickNovo() {
		resetForm({ values: copiarObjeto(initialValues) });
	}

	function getScrollTituloNotaVersao() {
		const height = document.getElementsByClassName('grid-titulo-nota-versao')[0]?.clientHeight;
		if (height >= 280 || isMobile) {
			return 'scroll';
		} else {
			return 'hidden';
		}
	}

	function addTitulo() {
		setFieldValue('titulos', [...values.titulos, { id: null, titulo: '', itens: [] }]);
	}

	async function removeTitulo(index) {
		const titulos = await copiarObjeto(values.titulos);

		titulos.splice(index, 1);
		setFieldValue('titulos', titulos);
	}

	function addNewItem(index) {
		setFieldValue(`titulos[${index}].itens`, [
			...values.titulos[index].itens,
			{ id: null, texto: '', tipo: TIPO_NOTA_VERSAO.NOVIDADE },
		]);
	}

	async function removerItem(index, itemIndex) {
		const itens = await copiarObjeto(values.titulos[index].itens);

		itens.splice(itemIndex, 1);
		setFieldValue(`titulos[${index}].itens`, itens);
	}

	return (
		<Modal header="Nota de versão" visible={visible} onHide={onHide}>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
					/>
					<ButtonSalvar
						estadoBotao={estadosBotaoSalvar.SALVAR}
						disabled={!dirty || !isAdmin}
						onClick={() => salvar()}
					/>
					<ButtonNovo
						onClick={() => onClickNovo()}
						hidden={!dirty && !values.id}
						estadoBotao={estadosBotaoNovo}
						disabled={!isAdmin}
					/>
				</FormActions>
				<FormContent>
					<Grid style={{ maxHeight: '620px' }}>
						<Field
							xs="6"
							sm="6"
							md="6"
							lg="6"
							xl="6"
							component={InputField}
							label="Versão"
							autoFocus
							obrigatorio
							disabled={!isAdmin}
							name="versao"
							value={values.versao}
							size={30}
						/>
						<Field
							xs="6"
							sm="6"
							md="6"
							lg="6"
							xl="6"
							component={Dropdown}
							label="Aplicação"
							name="aplicacao"
							obrigatorio
							value={values.aplicacao}
							disabled={!isAdmin}
							showClear={false}
							options={optionsTipoAplicacao}
							onChange={(e) => setFieldValue('aplicacao', e.value)}
						/>
					</Grid>
					<h3>Títulos da nota de versão</h3>
					<Grid
						className="grid-titulo-nota-versao"
						style={{ overflowY: getScrollTituloNotaVersao(), overflowX: 'hidden', maxHeight: '320px' }}
					>
						{!!values.titulos?.length &&
							values.titulos?.map((principal, index) => {
								const { titulo, itens } = principal;
								return (
									<div
										key={principal.id}
										style={{
											display: 'flex',
											flexDirection: 'column',
											width: '100%',
											paddingLeft: '1.5rem',
											justifyContent: 'center',
										}}
									>
										<Grid style={{ width: '100%' }}>
											<Field
												xs="9"
												sm="9"
												md="10"
												lg="10"
												xl="11"
												component={InputField}
												label="Título"
												autoFocus
												obrigatorio
												disabled={!isAdmin}
												name="titulo"
												value={titulo}
												size={255}
												onChange={(e) => setFieldValue(`titulos[${index}].titulo`, e.target.value)}
											/>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													paddingTop: '0.5rem',
													paddingLeft: '0.5rem',
													paddingRight: values.titulos?.length > 5 ? '0.5rem' : '0',
													paddingBottom: '0',
													marginBottom: '-0.8rem',
												}}
											>
												<div style={{ display: 'flex' }}>
													<ButtonNovoTable onClick={() => addNewItem(index)} disabled={!isAdmin} />
													<ButtonExcluirTable
														component={ButtonExcluirTable}
														onClick={() => removeTitulo(index)}
														disabled={!isAdmin || values.titulos?.length <= 1}
													/>
												</div>
											</div>
										</Grid>
										{values.titulos[index]?.itens?.length > 0 ? (
											<Divider
												styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
												styleContainer={{
													marginBottom: '1.2rem',
												}}
											/>
										) : null}
										{!!itens?.length &&
											itens?.map((item, indexItem) => {
												const { texto, tipo } = item;
												return (
													<div
														key={item.id}
														style={{
															display: 'flex',
															marginTop: isMobile ? '1rem' : '0rem',
															marginLeft: '1rem',
														}}
													>
														<Grid style={{ width: '100%' }}>
															<Field
																sm="12"
																md="8"
																lg="9"
																xl="9"
																component={TextArea}
																placeholder="Descrição do item da nota de versão"
																label="Item"
																name="texto"
																maxLength={4096}
																disabled={!isAdmin}
																rows={1}
																value={texto}
																onChange={(event) =>
																	setFieldValue(`titulos[${index}].itens[${indexItem}].texto`, event.target.value)
																}
															/>
															<Field
																sm="10"
																md="3"
																lg="2"
																xl="2"
																component={Dropdown}
																label="Tipo Item"
																name="tipo"
																obrigatorio
																value={tipo}
																disabled={!isAdmin}
																showClear={false}
																options={optionsTipoNotaVersao}
																onChange={(e) => setFieldValue(`titulos[${index}].itens[${indexItem}].tipo`, e.value)}
															/>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	paddingTop: '0.5rem',
																	paddingLeft: '0.5rem',
																	paddingRight: values.titulos?.length > 5 ? '0.5rem' : '0',
																	paddingBottom: '0',
																	marginBottom: '-0.6rem',
																}}
															>
																<Field
																	sm="12"
																	md="1"
																	lg="1"
																	xl="1"
																	component={ButtonExcluirTable}
																	onClick={() => removerItem(index, indexItem)}
																	disabled={!isAdmin}
																/>
															</div>
														</Grid>
													</div>
												);
											})}
										{index !== values.titulos.length - 1 ? (
											<Divider
												styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
												styleContainer={{
													margin: '1rem 0.5rem 1.6rem 0rem',
												}}
											/>
										) : null}
									</div>
								);
							})}
					</Grid>
					<ButtonAdicionarItem
						label="Adicionar título"
						style={{ ...buttonAdicionarStyle, marginTop: '16px' }}
						onClick={addTitulo}
						disabled={!isAdmin}
					/>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalNotaDeVersao = withFormik({
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValues;
	},

	validate(values) {
		const errors = {};

		if (!values.versao) {
			errors.versao = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.aplicacao) {
			errors.aplicacao = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},
	handleSubmit: () => {},
})(ModalNotaDeVersaoView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(ModalNotaDeVersao);
