import { useFormikContext } from 'formik';

import { copiarObjeto } from 'Common';
import { ButtonCancelar, ButtonExcluir, ButtonSalvar, estadosBotaoCancelar, estadosBotaoSalvar } from 'components';

import { validarFormulario } from 'views/Util';
import { useContextMDFe } from 'views/transporte/MDFe/Context';

function ActionButtons({
	canTransmitir,
	setFieldValuePagamento,
	indexRegistro,
	onCloseModal,
	componentesList,
	isNovoRegistro,
}) {
	const { values, validateForm, dirty, handleReset, handleSubmit } = useFormikContext();
	const { informacoesPermissoes } = useContextMDFe();

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			onCloseModal();
		}
	}

	async function handleClickSalvar() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			setFieldValuePagamento(`componentes.[${indexRegistro}]`, values);

			onCloseModal();
		}
	}

	function handleClickExcluir() {
		const componentesArray = copiarObjeto(componentesList);

		componentesArray.splice(indexRegistro, 1);

		setFieldValuePagamento('componentes', componentesArray);
		onCloseModal();
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={handleClickCancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar
				estadoBotao={estadosBotaoSalvar.SALVAR}
				disabled={!dirty || !canTransmitir}
				onClick={handleClickSalvar}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir
				disabled={isNovoRegistro || dirty || !canTransmitir}
				onClick={handleClickExcluir}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export { ActionButtons };
