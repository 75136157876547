import Button from "..";
import propTypes from "prop-types";
import { buscarHiddenDeAcordoComAsPermissoes } from "../../../Common/Autorizacao/ManipulacaoDeComponentes";

export const estadosBotaoSalvar = {
  IMPRIMIR: "IMPRIMIR",
};

function ButtonImprimir(props) {
  const {
    podeVisualizar,
    onClick,
    hidden,
    enableShortCut,
    style,
    id,
    raised,
    rounded,
  } = props;

  const labelFormatado = "Imprimir";
  const disabledFormatado = buscarHiddenDeAcordoComAsPermissoes(
    podeVisualizar,
    hidden
  );
  const titleFormatado = "Imprimir";

  return (
    <Button
      label={labelFormatado}
      id={id}
      type="button"
      icon="fa fa-print"
      disabled={disabledFormatado}
      title={titleFormatado}
      hidden={hidden}
      raised={raised}
      rounded={rounded}
      style={{ ...style, margin: "5px 5px 15px 0px" }}
      onClick={onClick}
      hotKey={["alt", "p"]}
      enableShortCut={enableShortCut}
    />
  );
}

ButtonImprimir.defaultProps = {
  hidden: false,
  disabled: false,
  label: "Imprimir",
  title: "Imprimir",
};

ButtonImprimir.propTypes = {
  /** Identificador do componente */
  id: propTypes.string,
  /** Derfine o botão como invisível */
  hidden: propTypes.bool,
  /** Ação executada ao clicar no botão */
  onClick: propTypes.func,
  /** Define o botão como desabilitaedo */
  disabled: propTypes.bool,
  /** Habilitar atalho */
  enableShortCut: propTypes.bool,
  /** Estado em que o cadastro se encontra*/
  estadoCadastro: propTypes.string,
  /** Diz se o usuário possui permissão de editar*/
  podeEditar: propTypes.bool,
  /** Diz se o usuário possui permissão de excluir*/
  podeInserir: propTypes.bool,
  /** Estilo do componente */
  style: propTypes.object,
  /** Title do componente*/
  title: propTypes.string,
  /** Especifica o label do botão*/
  estadoBotao: propTypes.oneOf([
    estadosBotaoSalvar.SALVAR,
    estadosBotaoSalvar.CONFIRMAR,
  ]),
};

export default ButtonImprimir;
