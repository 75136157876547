export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é o cadastro de NCM, a qual posteriormente será vinculado ao produto.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-ncm-principais',
		content: 'Aqui você vai preencher o código, descrição e a situação da NCM.',
	},
	{
		target: '.step-listagem-tributacoes',
		content: 'Aqui serão listados todos os vínculos na NCM com a operação fiscal e a tributação estadual.',
	},
	{
		target: '.step-editar-tributacoes',
		content:
			'Aqui é possível incluir, editar ou deletar os vínculos na NCM com a operação fiscal e a tributação estadual',
	},
];
