import { mensagensDeValidacao } from 'Common';
import {
	Button,
	ButtonCancelar,
	Col,
	FormActions,
	FormContent,
	Grid,
	Modal,
	TextArea,
	estadosBotaoCancelar,
} from 'components';
import { Field, useFormikContext, withFormik } from 'formik';
import { validarFormulario } from 'views/Util';
import * as Yup from 'yup';

export function ModalCancelamentoImpl(props) {
	const { visible, isCanceled, onHide, onCancelar, onEstornar, isMobile, isTablet, isLessHd } = props;
	const { values, handleSubmit, validateForm, setFieldValue } = useFormikContext();

	function handleChangeMotivoCancelamento(event) {
		setFieldValue(event.target.name, event.target.value);
	}

	async function handleClickCancelar() {
		handleSubmit();

		if (await validarFormulario({ values, validateForm })) {
			await onCancelar(values.motivoCancelamento);
			onHide();
		}
	}

	async function handleClickEstornar() {
		await onEstornar();
		onHide();
	}

	return (
		<Modal
			header={isCanceled ? 'Motivo do cancelamento' : 'Cancelar ordem de serviço'}
			visible={visible}
			onHide={onHide}
		>
			<FormContent>
				<Grid style={{ marginTop: '5px' }}>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={TextArea}
						name="motivoCancelamento"
						label={isCanceled ? null : 'Justificativa do cancelamento'}
						value={values.motivoCancelamento}
						onChange={handleChangeMotivoCancelamento}
						obrigatorio={!isCanceled}
						touched
						disabled={isCanceled}
					/>
				</Grid>
			</FormContent>
			<FormActions>
				<Grid
					style={{
						display: 'flex',
						justifyContent: 'end',
						margin: 0,
					}}
				>
					<Col
						sm="12"
						md="6"
						lg="4"
						xl="4"
						style={{
							padding: 0,
							margin: 0,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}
					>
						<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
						<Button
							icon={isCanceled ? 'fa fa-reply' : 'fa fa-paper-plane-o'}
							type="submit"
							style={{ marginRight: '0px !important' }}
							label={
								isCanceled && (isMobile || isTablet || isLessHd)
									? 'Estornar'
									: isCanceled
										? 'Estornar cancelamento'
										: isMobile || isTablet || isLessHd
											? 'Cancelar'
											: 'Cancelar ordem de servico'
							}
							color="danger"
							onClick={isCanceled ? handleClickEstornar : handleClickCancelar}
						/>
					</Col>
				</Grid>
			</FormActions>
		</Modal>
	);
}

const ModalCancelamento = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: true,

	mapPropsToValues(props) {
		return {
			motivoCancelamento: props.motivoCancelamento ?? null,
		};
	},

	validationSchema: Yup.object().shape({
		motivoCancelamento: Yup.string()
			.trim()
			.min(15, 'A justificativa deve ter mais de 15 caracteres')
			.required(mensagensDeValidacao.OBRIGATORIO)
			.nullable(),
	}),
	handleSubmit: () => {},
})(ModalCancelamentoImpl);

export { ModalCancelamento };
