import { useEffect } from 'react';
import * as Yup from 'yup';
import { withFormik, Field } from 'formik';
import { isValid, parseISO, formatISO, startOfMonth, format } from 'date-fns';
import { asyncImprimirRelatorioAssinaturas } from '../../Requests';

import { Grid, InputDate, ModalRelatorio, DropdownMultiSelect, MultipleSelectSetores } from 'components';
import { mensagensDeValidacao, baixarArquivo, services } from 'Common';

const helpMessage = {
  DataInicial: 'Data do início do período',
  DataFinal: 'Data do final do período',
  situacoes: 'Status do documento',
};

const optionsSituacoes = [
  { value: 'ARQUIVO_ADICIONADO', label: 'Arquivo adicionado' },
  { value: 'AGUARDANDO_ASSINATURAS', label: 'Aguardando assinaturas' },
  { value: 'ASSINADO', label: 'Assinado' },
  { value: 'REJEITADO', label: 'Rejeitado' },
  { value: 'EXPIRADO', label: 'Expirado' },
  { value: 'SEM_DOCUMENTO', label: 'Sem documento' },
];

const initialValue = {
  situacoes: [],
  DataInicial: formatISO(startOfMonth(new Date())),
  DataFinal: formatISO(new Date()),
  setores: null,
};

function ModalRelatorioAssinaturaForm(props) {
  const { visible, onHide, values, handleSubmit, validateForm, isValid, setFieldValue } = props;

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('InputDataInicial')?.focus();
    }, 500);
  },[]);

  async function imprimir() {
    await handleSubmit();
    await validateForm();
    if (isValid) {
      let situacoesFormatadas = [];
      if (values.situacoes.length > 0) {
        values.situacoes?.map(situacao => {
          situacoesFormatadas.push("'" + situacao.value + "'");
        });
      } else {
        optionsSituacoes.map(situacao => {
          situacoesFormatadas.push("'" + situacao.value + "'");
        });
      }
      let setores = '';

      if (values.setores) {
        setores = values.setores.map(item => `'${item.value}'`).join(',');
      }

      await asyncImprimirRelatorioAssinaturas(
        situacoesFormatadas,
        setores,
        format(parseISO(values.DataInicial), 'yyyy-MM-dd'),
        format(parseISO(values.DataFinal), 'yyyy-MM-dd'),
        ({ data: pdf }) => {
          let arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
          let arquivoURL = URL.createObjectURL(arquivoPDF);
          let relatorio = window.open(arquivoURL);
          if (relatorio) {
            relatorio.onload = () => {
              setTimeout(() => {
                relatorio.document.title = `Assinaturas no período - ${format(
                  parseISO(values.DataInicial),
                  'dd/MM/yyyy'
                )} - ${format(parseISO(values.DataFinal), 'dd/MM/yyyy')}`;
              }, 250);
              onHide();
            };
          }
        }
      );
    }
  }

  const parametros = (
    <Grid>
      <Field
        sm="12"
        md="6"
        lg="6"
        xl="6"
        component={InputDate}
        label="Data inicial"
        name="DataInicial"
        obrigatorio
        helpMessage={helpMessage.DataInicial}
        value={values.DataInicial}
        touched={true}
        id={'InputDataInicial'}
      />

      <Field
        sm="12"
        md="6"
        lg="6"
        xl="6"
        component={InputDate}
        label="Data final"
        name="DataFinal"
        obrigatorio
        helpMessage={helpMessage.DataFinal}
        value={values.DataFinal}
        touched={true}
      />

      <Field
        sm="12"
        md="6"
        lg="6"
        xl="6"
        component={DropdownMultiSelect}
        label="Situações do documento"
        name="situacoes"
        onChange={e => setFieldValue('situacoes', e)}
        options={optionsSituacoes}
        value={values.situacoes}
        showClear={false}
      />

      <Field
        sm="12"
        md="6"
        lg="6"
        xl="6"
        component={MultipleSelectSetores}
        label="Setor(es)"
        name="setores"
        obrigatorio
        url={`${services.GESTOR}/v1/documentos/relacoes/setores`}
        onChange={e => setFieldValue('setores', e)}
        value={values.setores}
        showClear={false}
        utilizaFavorito={true}
      />
    </Grid>
  );

  return (
    <ModalRelatorio
      header={'Assinaturas no período'}
      visible={visible}
      onHide={onHide}
      onImprimir={imprimir}
      parametros={parametros}
      isValid={isValid}
    />
  );
}

const ModalRelatorioAssinaturaView = withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: false,

  mapPropsToValues() {
    return initialValue;
  },

  validate(values) {
    let errors = {};

    if (!values.DataInicial || values.DataInicial === '') {
      errors.DataInicial = mensagensDeValidacao.OBRIGATORIO;
    }

    if (values.DataInicial && !isValid(parseISO(values.DataInicial))) {
      errors.DataInicial = mensagensDeValidacao.DATA_INVALIDA;
    }

    if (!values.DataFinal || values.DataFinal === '') {
      errors.DataFinal = mensagensDeValidacao.OBRIGATORIO;
    }

    if (values.DataFinal && !isValid(parseISO(values.DataFinal))) {
      errors.DataFinal = mensagensDeValidacao.DATA_INVALIDA;
    }
    if (values.DataInicial > values.DataFinal) {
      errors.DataInicial = 'Data inicial não pode ser maior que a data final';
    }

    return errors;
  },

  validationSchema: Yup.object().shape({
    DataInicial: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
    DataFinal: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
    setores: Yup.array().min(1, mensagensDeValidacao.OBRIGATORIO).nullable().required(mensagensDeValidacao.OBRIGATORIO),
  }),

  handleSubmit: () => {},
})(ModalRelatorioAssinaturaForm);

export default ModalRelatorioAssinaturaView;
