import { Card, Col, If } from 'components';
import { format, parseISO } from 'date-fns';
import { useContextNFCe } from '../../../Context';

import ActionButtons from './components/ActionButtons';
import { Logo } from './components/Logo';
import ModalCancelamento from './components/ModalCancelamento';
import ModalListagem from './components/ModalListagem';
import NFCeHistorico from './components/NFCeHistorico';
import NFCeNumeroStatus from './components/NFCeNumeroStatus';
import ProdutoForm from './components/ProdutoForm';

import './Styles/index.css';
import { ModalMenuFiscal } from './components/ModalMenuFiscal';

function CabecalhoCard(props) {
	const {
		sm,
		md,
		lg,
		xl,
		values,
		setFieldValue,
		dirty,
		isMobile,
		isTablet,
		isLessHd,
		isDesktop,
		isFullScreen,
		history,
		resetForm,
		credencial,
		setAtualizarTotal,
		decimaisQtd,
		decimaisPreco,
		novaNFCe,
		pathname,
		mostrarMenuFiscal,
	} = props;

	const {
		modalHistoricoVisible,
		setModalHistoricoVisible,
		modalListagemVisible,
		setModalListagemVisible,
		modalCancelamentoVisible,
		setModalCancelamentoVisible,
		modalMenuFiscalVisible,
		setModalMenuFiscalVisible,
	} = useContextNFCe();

	return (
		<>
			<Col sm={sm} md={md} lg={lg} xl={xl}>
				<Card
					className="card-cabecalho"
					footer={
						<ActionButtons
							dirty={dirty}
							values={values}
							setModalHistoricoVisible={setModalHistoricoVisible}
							setModalListagemVisible={setModalListagemVisible}
							setModalCancelamentoVisible={setModalCancelamentoVisible}
							setModalMenuFiscalVisible={setModalMenuFiscalVisible}
							mostrarMenuFiscal={mostrarMenuFiscal}
							history={history}
							novaNFCe={novaNFCe}
							isMobile={isMobile}
							isTablet={isTablet}
							isLessHd={isLessHd}
							isFullScreen={isFullScreen}
							pathname={pathname}
						/>
					}
				>
					<NFCeNumeroStatus numero={values.numero} serie={values.serie} status={values.status} />

					<ProdutoForm
						setFieldValue={setFieldValue}
						produtoSelecionado={values.produtoSelecionado}
						quantidadeSelecionada={values.quantidadeSelecionada}
						operacaoFiscalConfigurado={values.operacaoFiscal}
						produtos={values.produtos}
						cliente={values.cliente}
						credencial={credencial}
						setAtualizarTotal={setAtualizarTotal}
						decimaisQtd={decimaisQtd}
						decimaisPreco={decimaisPreco}
						isFullScreen={isFullScreen}
						isMobile={isMobile}
					/>
					<If test={isDesktop || isTablet}>
						<Logo
							key={document.getElementById('id-nfce-produto')?.offsetWidth}
							isFullScreen={isFullScreen}
							isLessHd={isLessHd}
							isTablet={isTablet}
							idNFCe={values?.id}
							credencial={credencial}
							history={history}
						/>
					</If>
				</Card>
			</Col>
			<If test={modalListagemVisible}>
				<ModalListagem
					visible={modalListagemVisible}
					onHide={() => setModalListagemVisible(false)}
					isMobile={isMobile}
					isTablet={isTablet}
					isLessHd={isLessHd}
					history={history}
					resetForm={resetForm}
					isFullScreen={isFullScreen}
					dirty={dirty}
				/>
			</If>
			<If test={modalHistoricoVisible}>
				<NFCeHistorico
					visible={modalHistoricoVisible}
					onHide={() => setModalHistoricoVisible(false)}
					idNFCe={values.id}
					isFullScreen={isFullScreen}
				/>
			</If>
			<If test={modalCancelamentoVisible}>
				<ModalCancelamento
					visible={modalCancelamentoVisible}
					isMobile={isMobile}
					onHide={() => setModalCancelamentoVisible(false)}
					idNFCe={values.id}
					valuesFormPrincipal={values}
					values={{
						justificativa: values.justificativaCancelamento,
						dataCancelamento: values?.dataCancelamento
							? format(parseISO(values?.dataCancelamento), 'dd/MM/yyyy HH:mm')
							: values?.dataCancelamento,
					}}
					resetFormPrincipal={resetForm}
					isFullScreen={isFullScreen}
				/>
			</If>
			<If test={modalMenuFiscalVisible}>
				<ModalMenuFiscal
					visible={modalMenuFiscalVisible}
					isMobile={isMobile}
					onHide={() => setModalMenuFiscalVisible(false)}
					isFullScreen={isFullScreen}
				/>
			</If>
		</>
	);
}

export default CabecalhoCard;
