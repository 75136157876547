import { useState } from 'react';
import { ListagemRelatorios, If } from 'components';
import { modulos } from 'Common';
import ModalOrdemServicoPeriodo from './components/ModalOrdemServicoPeriodo';
import ModalNotaServicoPeriodo from './components/ModalNotaServicoPeriodo';

function RelatoriosServicos() {
	const [exibirModalOrdemServicoPeriodo, setExibirModalOrdemServicoPeriodo] = useState(false);
	const [exibirModalNotaServicoPeriodo, setExibirModalNotaServicoPeriodo] = useState(false);

	const metodosImprimir = [
		{
			nome: 'ORDEM_SERVICO_PERIODO',
			onImprimir: () => {
				setExibirModalOrdemServicoPeriodo(true);
			},
		},
		{
			nome: 'NOTA_SERVICO_PERIODO',
			onImprimir: () => {
				setExibirModalNotaServicoPeriodo(true);
			},
		},
	];

	return (
		<>
			<ListagemRelatorios header="Relatórios de serviços" modulo={modulos.SERVICOS} metodosImprimir={metodosImprimir} />
			<If test={exibirModalOrdemServicoPeriodo}>
				<ModalOrdemServicoPeriodo
					visible={exibirModalOrdemServicoPeriodo}
					onHide={() => setExibirModalOrdemServicoPeriodo(false)}
				/>
			</If>
			<If test={exibirModalNotaServicoPeriodo}>
				<ModalNotaServicoPeriodo
					visible={exibirModalNotaServicoPeriodo}
					onHide={() => setExibirModalNotaServicoPeriodo(false)}
				/>
			</If>
		</>
	);
}

export default RelatoriosServicos;
