import React from 'react';
import Col from '../../../../../../components/Col';
import Grid from '../../../../../../components/Grid';
import { formatarMonetario } from '../../../../../../Common/Mascara';

const styleCardContent = {
	borderRadius: '5px',
};

const styleCircleIcon = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '35px',
	height: '35px',
	borderRadius: '50%',
};

const styleTitle = {
	fontSize: '14px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'end',
	padding: '0px',
};

const styleValue = {
	fontSize: '18px',
	marginTop: '5px',
	fontWeight: 'bold',
	overflow: 'hidden',
};

function SimpleCard({ primaryColor, title, icon, value, helpMessage }) {
	return (
		<div className="p-card" style={styleCardContent}>
			<Grid verticalAlignCenter justifyBetween style={{ margin: '0px' }}>
				<Col col="auto">
					<div style={styleCircleIcon}>{icon}</div>
				</Col>
				<Col col="10">
					<Grid justifyEnd verticalAlignCenter style={{ margin: '0px' }}>
						<div className="p-col" style={styleTitle} title={helpMessage}>
							{title}
						</div>
					</Grid>
					<Grid justifyEnd style={{ margin: '0px' }}>
						<div style={{ ...styleValue, color: primaryColor }}>{formatarMonetario(value)}</div>
					</Grid>
				</Col>
			</Grid>
		</div>
	);
}

export default SimpleCard;
