import { Modal } from '../../../../../components';
import FieldsEnviarPorEmail from './components/FieldsEnviarPorEmail';

function ModalEnviarBoletosPorEmail(props) {
	const { visible, onHide, boletos, boletosIds, dadosPessoaParaEmail } = props;

	return (
		<Modal header="Enviar boletos por e-mail" visible={visible} onHide={onHide}>
			<FieldsEnviarPorEmail
				onHide={onHide}
				boletos={boletos}
				boletosIds={boletosIds}
				dadosPessoaParaEmail={dadosPessoaParaEmail}
			/>
		</Modal>
	);
}

export default ModalEnviarBoletosPorEmail;
