import { useState } from 'react';
import If from '../../../../components/If';
import Modal from '../../../../components/Modal';
import TributacaoEstadualForm from '../Form';

export default function ModalTributacaoEstadual(props) {
	const [hideBackgroundTributacaoEstadual, setHideBackgroundTributacaoEstadual] = useState(false);

	return (
		<Modal
			className={props.visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de tributação estadual"
			visible={props.visible}
			onHide={props.onHide}
			styleModal={{ maxWidth: '1200px' }}
			hideBackground={hideBackgroundTributacaoEstadual}
		>
			<If test={props.visible}>
				<TributacaoEstadualForm hideModal={props.onHide} isModal={true} history={props.history} setHideBackground={setHideBackgroundTributacaoEstadual} />
			</If>
		</Modal>
	);
}
