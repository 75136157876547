import { mensagensDeValidacao } from 'Common';
import {
	ButtonCancelar,
	ButtonSalvar,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	Modal,
	ToastTypes,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
	notify,
} from 'components';
import { Field, useFormikContext, withFormik } from 'formik';
import { validarFormulario } from 'views/Util';
import { useContextTabelaPreco } from 'views/cadastros/TabelaPreco/Context';
import { atualizarCustoTodosOsProdutosTabelaPreco } from 'views/cadastros/TabelaPreco/Requests';
import { OPTIONS_TIPO_CUSTO, TIPO_CUSTO } from 'views/cadastros/TabelaPreco/Util/constantes';

function ModalAlteracaoCustoImpl({ visible, onCloseModal, fetch, idTabelaPreco }) {
	const { values, dirty, resetForm, handleSubmit, validateForm, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, desabilitarCampos } = useContextTabelaPreco();

	function handleClickCancelar() {
		if (dirty) {
			resetForm({
				values: {
					tipoCusto: TIPO_CUSTO.ULTIMO_CUSTO,
				},
			});
		} else {
			onCloseModal();
		}
	}

	async function handleClickSalvar() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			await atualizarCustoTodosOsProdutosTabelaPreco(
				{ idTabelaPreco: idTabelaPreco, tipoCusto: values.tipoCusto },
				() => {
					fetch();
				},
				() => {
					notify('Erro desconhecido ao atualizar todos os produtos', ToastTypes.ERROR);
				}
			);
		}

		onCloseModal();
	}

	return (
		<Modal
			header="Atualizar custo dos produtos"
			visible={visible}
			onHide={onCloseModal}
			styleModal={{ maxWidth: '420px' }}
		>
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={handleClickCancelar}
						{...informacoesPermissoes}
					/>
					<ButtonSalvar
						estadoBotao={estadosBotaoSalvar.SALVAR}
						disabled={desabilitarCampos}
						onClick={handleClickSalvar}
						{...informacoesPermissoes}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Dropdown}
							label="Tipo alteração"
							name="tipoCusto"
							options={OPTIONS_TIPO_CUSTO}
							onChange={(e) => setFieldValue('tipoCusto', e.value)}
							value={values?.tipoCusto}
							showClear={false}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalAlteracaoCusto = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return { tipoCusto: TIPO_CUSTO.ULTIMO_CUSTO };
	},

	validate(values) {
		const errors = {};

		if (!values.tipoCusto) {
			errors.tipoCusto = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},
	handleSubmit: () => {},
})(ModalAlteracaoCustoImpl);
export { ModalAlteracaoCusto };
