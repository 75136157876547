export function converterFiliaisSetoresToForm(filiaisSetores) {
  return {
    filiais: filiaisSetores.filiais.map((filial) => ({
      value: filial.id,
      label: filial.nomeApresentacao,
      registro: filial,
    })),
    setores: filiaisSetores.setores.map((setor) => ({
      value: setor.id,
      label: setor.nome,
      registro: setor,
    })),
  };
}

export function converterOrganizacaoToSelect(organizacao) {
  return {
    label: `${organizacao?.nome} ${organizacao.bloqueada ? "(Bloqueada)" : ""}`,
    registro: {
      id: organizacao?.id,
      nome: organizacao?.nome,
      bloqueada: organizacao?.bloqueada,
    },
    value: organizacao.id,
    isDisabled: organizacao.bloqueada,
  };
}

export function converterFilialToSelect(filial) {
  return {
    label: `${filial?.codigo} - ${filial?.nomeApresentacao}`,
    registro: {
      codigo: filial?.codigo,
      nome: filial?.nomeApresentacao,
      id: filial?.id,
    },
    value: filial.id,
  };
}
