export const CAMPO_OBRIGATORIO = 'Campo obrigatório';
export const CAMPO_OBRIGATORIO_RESUMO = 'Obrigatório';

export const moduloUtilizado = {
	NOTA_FISCAL: 'NOTA_FISCAL',
	PEDIDO_VENDA: 'PEDIDO_VENDA',
	ORCAMENTO: 'ORCAMENTO',
};

export const idDest = {
	OPERACAO_INTERNA: 'OPERACAO_INTERNA',
	OPERACAO_INTERESTADUAL: 'OPERACAO_INTERESTADUAL',
	OPERACAO_COM_EXTERIOR: 'OPERACAO_COM_EXTERIOR',
};

export const tipoOperacao = {
	ENTRADA: 'ENTRADA',
	SAIDA: 'SAIDA',
};

export const TipoDestinatario = {
	FISICA: 'FISICA',
	JURIDICA: 'JURIDICA',
	ESTRANGEIRO: 'ESTRANGEIRO',
};
