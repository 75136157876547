import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';
import {
	buscarDadosLoginLocalStorage,
	buscarMascaraTelefone,
	estadosCadastro,
	manterApenasNumeros,
	mensagensDeValidacao,
	permissoes,
	recursos,
	services,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	Col,
	Dropdown,
	Fieldset,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputCep,
	InputDate,
	InputField,
	InputMask,
	Message,
	ModalLoadingTransmissao,
	Prompt,
	SingleSelectAgenteAutorizado,
	SingleSelectMunicipio,
	SingleSelectPais,
	SingleSelectPlano,
	ToastTypes,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	estadosBotaoSalvar,
	notify,
} from 'components';
import InputCnpjWithButton from 'components/input/InputCnpjWithButton';
import { Field, useFormikContext, withFormik } from 'formik';
import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import StringMask from 'string-mask';
import * as Yup from 'yup';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../Util';
import { asyncGetConsultaPorCnpj } from '../Requests';
import {
	INITIAL_VALUES,
	autoridadeDropdown,
	meioPagamento,
	modulosDropdown,
	tipoPessoaDropdown,
} from '../Util/constantes';
import { helpOrganizacoesForm } from './Help';
import {
	asyncCreateOrganizacao,
	asyncDeleteOrganizacao,
	asyncGetOrganizacao,
	asyncUpdateOrganizacao,
} from './Requests';
import { converterParaApi, converterParaFormulario } from './Util/organizacaoConverter';
import Filiais from './components/tabs/Filiais';
import Usuarios from './components/tabs/Usuarios';

const idOrganizacaoMicrosys = '4171272a-c8d0-11e8-a8d5-f2801f1b9fd1';
const idPaisBrasil = '34e63b2e-c596-f34f-824d-bfd27eb62fa8';
const CADASTROURL = '/organizacoes/cadastro';
const PESQUISAURL = '/organizacoes';

function OrganizacoesForm(props) {
	const { isModal } = props;
	const { values, errors, dirty, setFieldValue, setFieldError, setValues } = useFormikContext();

	const [idOrganizacaoLogada, setIdOrganizacaoLogada] = useState(null);
	const [podeInserirOrganizacao] = useState(usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.INSERIR));
	const [podeEditarOrganizacao] = useState(usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.EDITAR));
	const [podeExcluirOrganizacao] = useState(usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.EXCLUIR));
	const [credenciais, setCredenciais] = useState([]);
	const [filiais, setFiliais] = useState([]);
	const [filiaisAtualizadas, setFiliaisAtualizadas] = useState([]);
	const [tabSelecionadaInterno, setTabSelecionadaInterno] = useState(0);
	const [exibirLoading, setExibirLoading] = useState(false);

	const messageLoading = 'Criando base de dados...';

	useEffect(() => {
		const idOrganizacao = props.match.params.id;

		if (validarUUID(idOrganizacao)) {
			asyncSelectRegistro(idOrganizacao);
		}

		setIdOrganizacaoLogada(buscarDadosLoginLocalStorage().organizacao.id);

		setTimeout(() => {
			document.getElementById('OrganizacoesInputFieldNome')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		if (filiaisAtualizadas.length > 0) {
			salvar();
		}
	}, [filiaisAtualizadas]);

	function buscarEstadoCadastro() {
		return props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	function novo() {
		atualizarUrl(props.history, CADASTROURL, null, metodosAtualizarUrl.POP);
		props.resetForm({ values: INITIAL_VALUES });
		setCredenciais([]);
		setFiliais([]);
	}

	function excluir() {
		confirm(
			'Confirmação',
			'Tem certeza que deseja excluir a organização? Este processo não pode ser revertido.',
			async () => {
				await asyncDeleteRegistro();
			}
		);
	}

	function handleChangeFiliais(filiaisAtualizadas) {
		setFiliais(filiaisAtualizadas);
		setFieldValue('filiais', filiaisAtualizadas);
		setFiliaisAtualizadas(filiaisAtualizadas);
	}

	function salvar(e, novoOnSuccess) {
		props.handleSubmit();

		if (props.isValid) {
			if (props.values.id) {
				asyncUpdateRegistro({ ...props.values }, novoOnSuccess);
			} else {
				asyncCreateRegistro({ ...props.values }, novoOnSuccess);
			}
		}
	}

	function cancelar() {
		if (props.dirty) {
			props.resetForm({ values: props.initialValues });
		} else {
			voltarParaAPesquisa(props.history, PESQUISAURL);
		}
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteOrganizacao(props.values.id, () => {
			props.resetForm();
			voltarParaAPesquisa(props.history, PESQUISAURL);
		});
	}

	async function asyncUpdateRegistro(values, novoOnSuccess) {
		await asyncUpdateOrganizacao(converterParaApi(values), ({ data: organizacao }) => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				const convertedOrganization = converterParaFormulario(organizacao);
				props.resetForm({ values: convertedOrganization });
				setFiliais(convertedOrganization.filiais);
			}
		});
	}

	async function asyncCreateRegistro(values, novoOnSuccess) {
		if (values) {
			setExibirLoading(true);
			await asyncCreateOrganizacao(converterParaApi(values), ({ data: organizacao }) => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					props.resetForm({ values: { ...values, id: organizacao.id } });
					asyncSelectRegistro(organizacao.id);
				}
				setExibirLoading(false);
			});
		}
	}

	async function asyncSelectRegistro(idOrganizacao) {
		await asyncGetOrganizacao(idOrganizacao, ({ data: organizacao }) => {
			props.resetForm({ values: converterParaFormulario(organizacao) });
			setCredenciais(organizacao.credenciais);
			setFiliais(organizacao.filiais);
			atualizarUrl(props.history, CADASTROURL, organizacao.id, metodosAtualizarUrl.POP);
		});
	}

	function habilitarTipoOrganizacao(e) {
		props.setFieldValue('tipo', e);

		switch (e) {
			case 'FISICO':
				break;
			case 'JURIDICO':
				break;
			default:
		}
	}

	function getMessageOrganizacao() {
		return (
			<span>
				Organização <b>bloqueada</b>. Nenhum usuário desta organização poderá inserir dados no sistema.
			</span>
		);
	}

	function bloquearOrganizacao() {
		confirm(
			'Confirmação',
			'Tem certeza que deseja bloquear a organização? Os usuários desta organização não poderão inserir dados no sistema.',
			async () => {
				props.handleSubmit();

				if ((await validarFormulario(props)) && props.values.id) {
					asyncUpdateRegistro({ ...props.values, bloqueada: true });
				}
			}
		);
	}

	function desbloquearOrganizacao() {
		confirm('Confirmação', 'Tem certeza que deseja desbloquear a organização?', async () => {
			props.handleSubmit();

			if ((await validarFormulario(props)) && props.values.id) {
				asyncUpdateRegistro({ ...props.values, bloqueada: false });
			}
		});
	}

	function getTitleBtnExcluir() {
		if (idOrganizacaoLogada === props.values.id) {
			return 'Não é possível excluir a própria organização.';
		} else {
			return 'Excluir a organização.';
		}
	}

	function onChangeCep(e) {
		if (e.pesquisou) {
			const { setFieldValue } = props;
			e.bairro && setFieldValue('bairro', e.bairro);
			e.complemento && setFieldValue('complemento', e.complemento);
			e.logradouro && setFieldValue('logradouro', e.logradouro);
			if (e.logradouro && e.logradouro !== '') {
				document.getElementById('inputNumeroEndereco').focus();
			} else {
				document.getElementById('inputLogradouroEndereco').focus();
			}
			e.municipio &&
				setFieldValue('municipio', {
					label: `${e.municipio.nome} - ${e.municipio.estado.sigla}`,
					value: e.municipio.id,
				});
			e.pais && setFieldValue('pais', { label: e.pais.nome, value: e.pais.id });
		} else {
			props.setFieldValue('cep', e.value);
		}
	}

	function getModulos() {
		if (props.values.id === idOrganizacaoMicrosys) {
			return modulosDropdown;
		}
		return modulosDropdown.filter((modulo) => modulo.value !== 'ADMINISTRACAO');
	}

	function onClickNovo() {
		if (dirty) {
			(e) => salvar(e, novo());
		} else {
			novo();
		}
	}

	function onKeyUp(e) {
		if (e.keyCode === 13) {
			consultarCnpj();
		}
	}

	function onClickPesquisarCnpj() {
		consultarCnpj();
	}

	function consultarCnpj() {
		if (isValidCNPJ(manterApenasNumeros(values.cnpj))) {
			asyncGetConsultaPorCnpj(
				values.cnpj,
				({ data: empresa }) => {
					const empresaConvertida = converterParaFormulario({
						...values,
						...empresa,
					});

					setValues(empresaConvertida);
					setFieldError('cnpj', null);
				},
				() => {
					notify('CNPJ não encontrado, verifique', ToastTypes.ERROR);
				}
			);
		} else {
			setFieldError('cnpj', 'Digite um CNPJ válido.');
		}
	}

	const mesmaOrganizacao = idOrganizacaoLogada === props.values.id;

	const informacoesPermissoes = {
		podeInserir: podeInserirOrganizacao,
		podeEditar: podeEditarOrganizacao,
		podeExcluir: podeExcluirOrganizacao,
	};

	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	return (
		<>
			<Prompt dirty={dirty} />
			<Form header="Cadastro de organização" isModal={isModal} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
						{...informacoesPermissoes}
					/>
					<ButtonSalvar
						estadoBotao={estadosBotaoSalvar.SALVAR}
						disabled={!dirty}
						onClick={() => salvar()}
						{...informacoesPermissoes}
					/>
					<ButtonNovo
						onClick={onClickNovo}
						hidden={(!dirty && !values.id) || isModal}
						estadoBotao={estadoBotaoNovo}
						{...informacoesPermissoes}
					/>
					<ButtonExcluir
						disabled={mesmaOrganizacao}
						hidden={!values.id}
						title={getTitleBtnExcluir()}
						onClick={() => excluir()}
						{...informacoesPermissoes}
					/>
					<Button
						color="danger"
						label="Bloquear"
						title="Bloquear o usuário selecionado"
						icon="fa fa-lock"
						onClick={() => bloquearOrganizacao()}
						hidden={values.bloqueada || mesmaOrganizacao || !values.id}
						disabled={!informacoesPermissoes.podeEditar}
						style={{ margin: '5px' }}
					/>
					<Button
						color="success"
						label="Desbloquear"
						title="Desbloquear o usuário selecionado"
						icon="fa fa-unlock"
						disabled={!informacoesPermissoes.podeEditar}
						onClick={() => desbloquearOrganizacao()}
						hidden={!values.bloqueada || mesmaOrganizacao || !values.id}
						style={{ margin: '5px' }}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Message severity="warn" hidden={values.bloqueada === false} text={getMessageOrganizacao()} />
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							label="Nome da organização *"
							name="nome"
							size={60}
							helpMessage={helpOrganizacoesForm.nome}
							component={InputField}
							id="OrganizacoesInputFieldNome"
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							label="Tipo *"
							name="tipo"
							showClear={false}
							onChange={(e) => habilitarTipoOrganizacao(e.value)}
							options={tipoPessoaDropdown}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							hidden={values.tipo !== 'FISICA'}
							component={InputMask}
							mask="000.000.000-00"
							placeholder="   .   .   -  "
							label="CPF *"
							name="cpf"
							onChange={(e) => props.setFieldValue('cpf', e.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputCnpjWithButton}
							mask="00.000.000/0000-00"
							placeholder="  .   .   /    -  "
							label="CNPJ"
							name="cnpj"
							hidden={values.tipo !== 'JURIDICA'}
							onChange={(e) => setFieldValue('cnpj', e.value)}
							value={values.cnpj}
							onKeyUp={onKeyUp}
							onClick={onClickPesquisarCnpj}
							errors={errors.cnpj ? errors.cnpj : null}
							touched={errors.cnpj ? errors.cnpj : null}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							label="E-mail"
							obrigatorio
							estadoCadastro={buscarEstadoCadastro()}
							name="email"
							size={255}
							component={InputField}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputMask}
							mask={buscarMascaraTelefone(values.telefone)}
							placeholder="(  )      -    "
							label="Telefone"
							obrigatorio
							name="telefone"
							size={16}
							onChange={(e) => props.setFieldValue('telefone', e.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputDate}
							label="Data de criação"
							name="criadoEm"
							value={values.criadoEm}
							disabled
						/>
						<Field
							sm="12"
							md="8"
							lg="8"
							xl="8"
							component={SingleSelectAgenteAutorizado}
							url={`${services.GESTOR}/v1/organizacoes/relacoes/agentes_autorizados`}
							name="agenteAutorizado"
							label="Agente autorizado"
							value={values.agenteAutorizado}
							helpMessage={helpOrganizacoesForm.agenteAutorizado}
							onChange={(e) => props.setFieldValue('agenteAutorizado', e)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							label="Autoridade"
							name="autoridade"
							obrigatorio
							showClear={false}
							onChange={(e) => {
								props.setFieldValue('autoridade', e.value);
							}}
							options={autoridadeDropdown}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Dropdown}
							showClear={false}
							isMulti
							closeMenuOnSelect={false}
							backspaceRemovesValue={false}
							label="Módulos que a organização possui acesso"
							options={getModulos()}
							name="modulos"
							obrigatorio
							helpMessage={helpOrganizacoesForm.modulos}
							value={values.modulos}
							onChange={(e) => props.setFieldValue('modulos', e)}
							{...informacoesPermissoes}
						/>
						<Col sm="12" md="12" lg="12" xl="12">
							<Fieldset legend="Plano e pagamento" className="fieldset">
								<Grid>
									<Field
										sm="12"
										md="4"
										lg="4"
										xl="4"
										component={Dropdown}
										label="Aplicação"
										name="aplicacao"
										obrigatorio
										showClear={false}
										onChange={(e) => {
											props.setFieldValue('aplicacao', e.value);
											props.setFieldValue('plano', null);
										}}
										options={[
											{ label: 'DOCs Digitais', value: 'DOCS_DIGITAIS' },
											{ label: 'Msys Gestor', value: 'MSYS_GESTOR' },
											{ label: 'Gestor MEI', value: 'GESTOR_MEI' },
										]}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="4"
										lg="4"
										xl="4"
										component={SingleSelectPlano}
										label="Plano"
										name="plano"
										obrigatorio
										aplicacao={values.aplicacao}
										value={values.plano}
										url={`${services.GESTOR}/v1/organizacoes/relacoes/planos`}
										onChange={(e) => props.setFieldValue('plano', e)}
										isClearable={false}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="4"
										lg="4"
										xl="4"
										component={Dropdown}
										label="Meio de pagamento"
										name="meioPagamento"
										obrigatorio
										value={values.meioPagamento}
										showClear={false}
										onChange={(e) => props.setFieldValue('meioPagamento', e.value)}
										options={meioPagamento}
										{...informacoesPermissoes}
									/>
								</Grid>
							</Fieldset>
						</Col>

						<Col sm="12" md="12" lg="12" xl="12">
							<Fieldset legend="Endereço" className="fieldset">
								<Grid>
									<Field
										sm="12"
										md="4"
										lg="4"
										xl="4"
										obrigatorio
										component={InputCep}
										label="CEP"
										name="cep"
										type="tel"
										value={values.cep}
										onChange={(e) => onChangeCep(e)}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="4"
										lg="4"
										xl="4"
										obrigatorio
										label="Logradouro"
										name="logradouro"
										id="inputLogradouroEndereco"
										size={255}
										component={InputField}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="4"
										lg="4"
										xl="4"
										obrigatorio
										label="Número"
										id="inputNumeroEndereco"
										size={10}
										name="numero"
										component={InputField}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="4"
										lg="4"
										xl="4"
										obrigatorio
										label="Bairro"
										name="bairro"
										size={60}
										component={InputField}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="4"
										lg="4"
										xl="4"
										label="Complemento"
										name="complemento"
										size={255}
										component={InputField}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="4"
										lg="4"
										xl="4"
										obrigatorio
										label="País"
										name="pais"
										component={SingleSelectPais}
										value={values.pais}
										onChange={(e) => props.setFieldValue('pais', e)}
										isClearable={false}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="4"
										lg="4"
										xl="4"
										obrigatorio
										label="Município"
										name="municipio"
										component={SingleSelectMunicipio}
										value={values.municipio}
										onChange={(e) => props.setFieldValue('municipio', e)}
										hidden={(values.pais ? values.pais.value : null) !== idPaisBrasil}
										isClearable={false}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="4"
										lg="4"
										xl="4"
										component={InputField}
										type="text"
										label="Estado exterior"
										helpMessage={helpOrganizacoesForm.estadoExterior}
										name="estadoExterior"
										value={values.estadoExterior}
										size={128}
										hidden={(values.pais ? values.pais.value : null) === idPaisBrasil}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="4"
										lg="4"
										xl="4"
										component={InputField}
										type="text"
										label="Município exterior"
										helpMessage={helpOrganizacoesForm.municipioExterior}
										name="municipioExterior"
										value={values.municipioExterior}
										size={128}
										hidden={(values.pais ? values.pais.value : null) === idPaisBrasil}
										{...informacoesPermissoes}
									/>
								</Grid>
							</Fieldset>
						</Col>
						<Col sm="12" md="12" lg="12" xl="12">
							<TabView
								className="tab-view step-pessoa-tabs"
								onTabChange={(event) => setTabSelecionadaInterno(event.index)}
								activeIndex={tabSelecionadaInterno}
								{...informacoesPermissoes}
							>
								<TabPanel header="Filial">
									<Filiais
										idOrganizacao={values.id}
										filiais={filiais}
										onChange={(filiaisAtualizadas) => handleChangeFiliais(filiaisAtualizadas)}
										autoridadeOrganizacao={values.autoridade}
										{...informacoesPermissoes}
									/>
								</TabPanel>
								<TabPanel header="Usuários">
									<Usuarios
										idOrganizacao={values.id}
										credenciais={credenciais}
										onChange={(credenciaisAtualizadas) => setCredenciais(credenciaisAtualizadas)}
										{...informacoesPermissoes}
									/>
								</TabPanel>
							</TabView>
						</Col>
					</Grid>
				</FormContent>
			</Form>
			<If test={exibirLoading}>
				<ModalLoadingTransmissao visible={exibirLoading} message={messageLoading} />
			</If>
		</>
	);
}

const OrganizacoesView = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		email: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).email('E-mail inválido'),
		plano: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		meioPagamento: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		telefone: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		cep: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		logradouro: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		numero: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		bairro: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		pais: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		municipio: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	validate(values) {
		const errors = {};
		const isMicrosys = values?.id === '4171272a-c8d0-11e8-a8d5-f2801f1b9fd1';

		if (
			!new StringMask('(00) 00000-0000').validate(manterApenasNumeros(values.telefone)) &&
			!new StringMask('(00) 0000-0000').validate(manterApenasNumeros(values.telefone))
		) {
			errors.telefone = mensagensDeValidacao.TELEFONE_INVALIDO;
		}

		if (values.tipo === 'FISICA') {
			if (!values.cpf) errors.cpf = mensagensDeValidacao.OBRIGATORIO;

			if (values.cpf && !isValidCPF(manterApenasNumeros(values.cpf))) errors.cpf = 'Digite um CPF válido.';
		}

		if (values.tipo === 'JURIDICA') {
			if (!values.cnpj) errors.cnpj = mensagensDeValidacao.OBRIGATORIO;

			if (values.cnpj && !isValidCNPJ(manterApenasNumeros(values.cnpj))) errors.cnpj = 'Digite um CNPJ válido.';
		}

		if (!values.modulos.length) {
			errors.modulos = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.autoridade === 'MICROSYS') {
			if (!isMicrosys) {
				errors.autoridade = 'Apenas o schema administrador pode ter a autoridade Microsys';
			}
		}

		return errors;
	},

	handleSubmit: () => {},
})(OrganizacoesForm);

export default OrganizacoesView;
