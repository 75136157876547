import {
	buscarDadosLoginLocalStorage,
	buscarMascaraTelefone,
	estadosCadastro,
	mensagensDeValidacao,
	modulos,
	permissoes,
	recursos,
	services,
	usuarioPossuiModulo,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputField,
	InputMask,
	MultiSelectFiliais,
	MultipleSelectPapeis,
	MultipleSelectSetores,
} from 'components';
import { Field, withFormik } from 'formik';
import { useState } from 'react';
import { withRouter } from 'react-router';
import { useEffectOnce } from 'react-use';
import * as Yup from 'yup';
import { atualizarUrl, metodosAtualizarUrl } from '../../../Util';
import { INITIAL_VALUES } from '../Util/constantes';
import { ActionButtons } from './ActionButtons';
import { converterUsuarioParaFormulario } from './Functions';
import { helpFieldText } from './Help';
import { asyncGetUsuario } from './Requests';

const CADASTROURL = '/usuarios/cadastro';

function UsuariosFormView({
	values,
	dirty,
	isModal,
	initialValues,
	handleSubmit,
	resetForm,
	setFieldValue,
	hideModal,
	...props
}) {
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.USUARIOS, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.USUARIOS, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.USUARIOS, permissoes.EXCLUIR));
	const [possuiSetores] = useState(usuarioPossuiModulo(modulos.DOCUMENTOS) || usuarioPossuiModulo(modulos.VENDAS));
	const [optionsSetorFavorito, setOptionsSetorFavorito] = useState([{ label: '', value: '' }]);

	useEffectOnce(() => {
		const { id } = props.match.params;

		if (validarUUID(id) && !isModal) {
			asyncSelectRegistro(id);
		} else {
			resetForm({ values: { ...initialValues, setores: [] } });
		}

		setTimeout(() => {
			document.getElementById('UsuariosInputNome')?.focus();
		}, 500);
	});

	async function asyncSelectRegistro(idUsuario) {
		await asyncGetUsuario(idUsuario, async ({ data: usuario }) => {
			const valoresFormulario = converterUsuarioParaFormulario({ ...usuario });

			setOptionsSetorFavorito(valoresFormulario.setores);

			resetForm({ values: valoresFormulario });

			if (!isModal) {
				atualizarUrl(props.history, CADASTROURL, usuario.id, metodosAtualizarUrl.POP);
			}
		});
	}

	function handleChangeSetores(event) {
		setFieldValue('setores', event);
		atualizarOptionsSetorFavorito(event);

		if (event.length > 0 && values.setorFavorito) {
			if (!event.find((setor) => setor.value === values.setorFavorito.value)) {
				setFieldValue('setorFavorito', null);
			}
		} else {
			setFieldValue('setorFavorito', null);
		}
	}

	function atualizarOptionsSetorFavorito(setores) {
		setOptionsSetorFavorito(setores);
	}

	function handleChangeSetorFavorito(event) {
		setFieldValue('setorFavorito', event);
	}

	function buscarOptionsAutoridade(organizacaoAutoridade) {
		switch (organizacaoAutoridade) {
			case 'MICROSYS':
				return [
					{ label: 'Usuário', value: 'USER' },
					{ label: 'Suporte', value: 'HELPDESK' },
					{ label: 'Agenciador', value: 'ACCOUNTANT' },
				];
			case 'CONTABILIDADE':
				return [
					{ label: 'Usuário', value: 'USER' },
					{ label: 'Contabilidade', value: 'ACCOUNTANT' },
				];
			case 'CLIENTE':
				return [];
			default:
				return [];
		}
	}

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: estadosCadastro.EDICAO,
	};

	const dadosLocalStorage = buscarDadosLoginLocalStorage();

	const optionsAutoridade = buscarOptionsAutoridade(dadosLocalStorage?.organizacao?.autoridade);

	return (
		<Form header="Cadastro de usuário" className="card-default screen-max-width">
			<FormActions className="screen-max-width">
				<ActionButtons
					history={props.history}
					isModal={isModal}
					hideModal={hideModal}
					informacoesPermissoes={informacoesPermissoes}
					props={props}
				/>
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="5"
						lg="6"
						xl="6"
						component={InputField}
						label="Nome "
						obrigatorio
						name="nome"
						helpMessage={helpFieldText.nome}
						size={60}
						id="UsuariosInputNome"
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputField}
						label="E-mail "
						obrigatorio
						name="email"
						helpMessage={helpFieldText.email}
						disabled={Boolean(values.id)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="3"
						lg="2"
						xl="2"
						component={InputMask}
						mask={buscarMascaraTelefone(values.celular)}
						label="Celular "
						name="celular"
						placeholder="(  )      -    "
						value={values.celular}
						onChange={(e) => setFieldValue('celular', e.target.value)}
						helpMessage={helpFieldText.celular}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={MultiSelectFiliais}
						name="filiaisAcesso"
						obrigatorio
						onChange={(e) => setFieldValue('filiaisAcesso', e)}
						value={values.filiaisAcesso}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={MultipleSelectPapeis}
						url={`${services.GESTOR}/v1/usuarios/relacoes/papeis`}
						name="papeis"
						obrigatorio
						helpMessage={helpFieldText.papeis}
						onChange={(e) => setFieldValue('papeis', e)}
						value={values.papeis}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="9"
						lg="9"
						xl="9"
						url={`${services.GESTOR}/v1/usuarios/relacoes/setores`}
						component={MultipleSelectSetores}
						label="Setores"
						obrigatorio
						helpMessage={helpFieldText.setores}
						name="setores"
						onChange={(event) => handleChangeSetores(event)}
						value={values.setores}
						hidden={!possuiSetores}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						component={Dropdown}
						label="Setor favorito"
						name="setorFavorito"
						value={values.setorFavorito}
						obrigatorio
						showClear={false}
						options={optionsSetorFavorito}
						onChange={(event) => handleChangeSetorFavorito(event)}
						{...informacoesPermissoes}
					/>
					<If test={['MICROSYS', 'CONTABILIDADE'].includes(dadosLocalStorage?.organizacao?.autoridade)}>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							label="Autoridade"
							name="autoridade"
							value={values.autoridade}
							obrigatorio
							showClear={false}
							options={optionsAutoridade}
							onChange={(event) => setFieldValue('autoridade', event.value)}
							{...informacoesPermissoes}
						/>
					</If>
				</Grid>
			</FormContent>
		</Form>
	);
}

const UsuariosForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string().required(mensagensDeValidacao.OBRIGATORIO).nullable(),
		email: Yup.string()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.nullable()
			.email('Por favor, informe um e-mail válido.'),
		filiaisAcesso: Yup.array().min(1, mensagensDeValidacao.OBRIGATORIO),
		papeis: Yup.array().min(1, mensagensDeValidacao.OBRIGATORIO),
		setores: Yup.array().min(1, mensagensDeValidacao.OBRIGATORIO),
		setorFavorito: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(UsuariosFormView);

export default withRouter(UsuariosForm);
