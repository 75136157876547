import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import {
	Button,
	DateInterval,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputSearch,
	Modal,
	PesquisaAvancada,
} from '../index';

import { construirUrl, formatarParaPesquisarTiposEnumerados, services } from '../../Common';

import { useEffectOnce } from 'react-use';
import ModalNovaInutilizacao from './Components/ModalNovaInutilizacao';
import TabelaInutilizacao from './Components/TabelaInutilizacao';
import { asyncGetInutilizacoes } from './Requests';
import { optionsFiltroAvancado, TipoInutilizacao } from './Util/constantes';

function ModalInutilizacao(props) {
	const { header, visible, onHide, podeInserir, tipoInutilizacao, container, isMobile } = props;

	const [registros, setRegistros] = useState([]);
	const [selectedElement, setSelectedElement] = useState();
	const [totalRecords, setTotalRecords] = useState(0);
	const [size, setSize] = useState(10);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState(0);
	const [sortField, setSortField] = useState('serie');
	const [sortOrder, setSortOrder] = useState(-1);
	const [valorPesquisa, setValorPesquisa] = useState('');
	const [optsFiltroAvancado] = useState(optionsFiltroAvancado);
	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
	const [descricaoFiltroAvancado, setDescricaoFiltroAvancado] = useState('');
	const [interval, setInterval] = useState({
		dataInicial: startOfMonth(new Date()),
		dataFinal: endOfMonth(new Date()),
	});
	const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa);
	const [filtroData, setFiltroData] = useState(
		`criadoEm>=${formatISO(startOfMonth(new Date()), {
			representation: 'date',
		})};criadoEm<=${formatISO(endOfMonth(new Date()), {
			representation: 'date',
		})}`
	);
	const [filtroAvancado, setFiltroAvancado] = useState('');
	const [modalNovaInutilizacao, setModalNovaInutilizacao] = useState(false);

	const pathName = tipoInutilizacao === TipoInutilizacao.NFE ? 'nfes' : 'nfce';

	useEffect(() => {
		pesquisar();
	}, [page, rows, sortOrder, filtroAvancado, interval]);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	function buscarFiltro() {
		let filtroRSQL = String('?query=(')
			.concat(`serie=contains="*${valorPesquisa}*",`)
			.concat(`usuarioNome=contains="*${valorPesquisa}*",`)
			.concat(`numeroInicial=contains="*${valorPesquisa}*",`)
			.concat(`numeroFinal=contains="*${valorTiposEnumerados}*",`)
			.concat(`dataInutilizacao=contains="*${valorTiposEnumerados}*",`)
			.concat(`justificativa=contains="*${valorTiposEnumerados}*",`)
			.concat(`situacao=contains="*${valorTiposEnumerados}*")`)
			.concat(`;(${filtroData})`);

		if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}

		return filtroRSQL;
	}

	async function pesquisar() {
		if (interval.dataInicial != null && interval.dataFinal != null) {
			const filtro = buscarFiltro();

			const url = construirUrl(
				`${services.GESTOR}/v1/${pathName}/inutilizacoes`,
				filtro || '?',
				size,
				page,
				sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
			);

			await asyncGetInutilizacoes(url, ({ data: inutilizacoes }) => {
				setRegistros(inutilizacoes.content);
				setTotalRecords(inutilizacoes.totalElements);
			});
		}
	}

	function handleChangeInterval(interval) {
		setPage(0);
		setInterval(interval);
		setFiltroData(
			`criadoEm>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};criadoEm<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	function onPesquisar() {
		setPage(0);
		pesquisar();
	}

	function onPesquisarFiltroAvancado(e) {
		setFiltroAvancado(e);
		setLimparFiltroPesquisaAvancada(false);
	}

	function onCloseModalNovaInutilizacao() {
		setModalNovaInutilizacao(false);
		setSelectedElement(null);
		pesquisar();
	}

	return (
		<>
			<Modal header={header} visible={visible} onHide={onHide} container={container}>
				<Form>
					<FormActions
						style={{
							display: 'block',
							width: '100%',
							margin: isMobile ? '5px 0px 5px -5px' : '5px 0px 5px 0px',
						}}
					>
						<Button
							label="Nova inutilização"
							className="step-listagem-novo p-button-primary"
							icon="fa fa-plus"
							title="Inserir uma nova NF-e"
							podeInserir={podeInserir}
							disabled={!podeInserir}
							onClick={() => setModalNovaInutilizacao(true)}
						/>
					</FormActions>
					<FormContent>
						<Grid justifyCenter verticalAlignCenter>
							<span style={{ padding: '12px' }}>
								<DateInterval onChange={handleChangeInterval} appendTo={container} />
							</span>
							<InputSearch
								onPesquisar={onPesquisar}
								className="step-listagem-input-search"
								value={valorPesquisa}
								onChange={(value) => setValorPesquisa(value)}
								id="NfeInputSearch"
							/>
							<span style={{ padding: '12px' }} className="step-listagem-filtro-avancado">
								<PesquisaAvancada
									optionsFiltros={optsFiltroAvancado}
									onPesquisarClick={onPesquisarFiltroAvancado}
									onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
									onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
									limparFiltro={limparFiltroPesquisaAvancada}
									menuPortalTarget={container}
								/>
							</span>
						</Grid>
						<Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
							<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
							<TabelaInutilizacao
								registros={registros}
								totalRecords={totalRecords}
								rows={size}
								page={page}
								sortField={sortField}
								sortOrder={sortOrder}
								setSortField={(sortField) => setSortField(sortField)}
								setSortOrder={(sortOrder) => {
									setSortOrder(sortOrder);
								}}
								onPageChange={(e) => {
									setSize(e.rows);
									setRows(e.rows);
									setPage(e.page);
								}}
								setModalNovaInutilizacao={setModalNovaInutilizacao}
								setSelectedElement={setSelectedElement}
							/>
						</Grid>
					</FormContent>
				</Form>
			</Modal>
			<If test={modalNovaInutilizacao}>
				<ModalNovaInutilizacao
					{...props}
					selectedElement={selectedElement}
					visible={modalNovaInutilizacao}
					onHide={() => onCloseModalNovaInutilizacao()}
					tipoInutilizacao={tipoInutilizacao}
					pathName={pathName}
					container={container}
				/>
			</If>
		</>
	);
}

export default ModalInutilizacao;
