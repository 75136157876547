export const tabsAgentesAutorizados = {
	ENDERECO: 0,
	GERACAO_LINK_PERSONALIZADO: 1,
};

export const tipoAgenteAutorizado = [
	{ label: 'Física', value: 'FISICA' },
	{ label: 'Jurídica', value: 'JURIDICA' },
];

export const situacaoAgenteAutorizado = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];
