import { Dropdown, Grid, Subtitle } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { OPTIONS_MUNICIPIO_PRESTACAO } from 'views/configuracao/ConfiguracoesGerais/Util/constantes';

function TabServicos() {
	const { podeEditarEmpresa } = useContextConfiguracoesGerais();
	const { values, setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Subtitle subtitle="Serviços" />
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={Dropdown}
				label="Padrão município prestação de serviço"
				name="padraoMunicipioPrestacao"
				value={values.parametrizacao.servicos?.padraoMunicipioPrestacao}
				onChange={(e) => setFieldValue('parametrizacao.servicos.padraoMunicipioPrestacao', e.value)}
				options={OPTIONS_MUNICIPIO_PRESTACAO}
				disabled={!podeEditarEmpresa}
				showClear={false}
			/>
		</Grid>
	);
}

export { TabServicos };
