import { Field, useFormikContext } from 'formik';
import { Divider, Grid, InputField } from 'components';
import { useContextEtiquetaConfiguracao } from '../../../../Context';

function ModalMovimentarCampo({ informacoesPermissoes, indexCampo }) {
	const { values, setFieldValue } = useFormikContext();
	const { setDesselecionarCampoPreview } = useContextEtiquetaConfiguracao();

	function onChangeValoresPosicao(campo, valor, eixo) {
		const valorMaximo = eixo === 'posicaoVertical' ? values.alturaEtiqueta : values.larguraEtiqueta;
		const medidasCampo = {
			altura: values.campos[indexCampo].altura,
			largura: values.campos[indexCampo].largura,
		};
		const valorMaisMedidaCampo =
			Number(valor) + Number(eixo === 'posicaoVertical' ? medidasCampo.altura : medidasCampo.largura);

		if (valor < 0) {
			setFieldValue(campo, 0);
		} else if (valorMaisMedidaCampo <= valorMaximo) {
			setFieldValue(campo, Number(valor));
		}
	}

	function handleChangeTamanho(field, value) {
		if (value > 0) {
			setFieldValue(field, value);
		}
	}

	function onClickInputs() {
		setDesselecionarCampoPreview(true);
	}

	return (
		<Grid>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				label="Posição vertical"
				name="posicaoVertical"
				obrigatorio
				component={InputField}
				value={values?.campos[indexCampo]?.posicaoVertical}
				type="number"
				onClick={onClickInputs}
				onChange={(event) =>
					onChangeValoresPosicao(`campos[${indexCampo}].posicaoVertical`, event.target.value, 'posicaoVertical')
				}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				label="Posição horizontal"
				name="posicaoHorizontal"
				obrigatorio
				value={values?.campos[indexCampo]?.posicaoHorizontal}
				type="number"
				component={InputField}
				onClick={onClickInputs}
				onChange={(event) =>
					onChangeValoresPosicao(`campos[${indexCampo}].posicaoHorizontal`, event.target.value, 'posicaoHorizontal')
				}
				{...informacoesPermissoes}
			/>
			<Divider style={{ margin: '0.2rem 0.5rem' }} />
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				label="Altura"
				name="altura"
				obrigatorio
				component={InputField}
				value={values?.campos[indexCampo]?.altura}
				type="number"
				onClick={onClickInputs}
				onChange={(event) => handleChangeTamanho(`campos[${indexCampo}].altura`, event.target.value)}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				label="Largura"
				name="largura"
				obrigatorio
				component={InputField}
				value={values?.campos[indexCampo]?.largura}
				type="number"
				onClick={onClickInputs}
				onChange={(event) => handleChangeTamanho(`campos[${indexCampo}].largura`, event.target.value)}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { ModalMovimentarCampo };
