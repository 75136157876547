import { services } from '../../../../Common/Constantes/api';
import { del, get, put, post, exibirToast } from '../../../../Common/Requisicoes';

export async function asyncGetOperacoesFiscais(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetOperacaoFiscal(IdOperacao, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/tributacoes/operacoes_fiscais/${IdOperacao}`, null, onSuccess, onError);
}

export async function asyncDeleteOperacaoFiscal(idOperacao, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/tributacoes/operacoes_fiscais/${idOperacao}`,
		null,
		exibirToast(onSuccess, 'Tributação excluída'),
		onError
	);
}

export async function asyncUpdateOperacaoFiscal(operacaoFiscal, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/tributacoes/operacoes_fiscais/${operacaoFiscal.id}`,
		operacaoFiscal,
		null,
		exibirToast(onSuccess, 'Operação fiscal atualizada'),
		onError
	);
}

export async function asyncCreateOperacaoFiscal(operacaoFiscal, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/tributacoes/operacoes_fiscais`,
		operacaoFiscal,
		null,
		exibirToast(onSuccess, 'Operação fiscal criada'),
		onError
	);
}
