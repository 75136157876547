import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	mensagensDeValidacao,
	permissoes,
	recursos,
	removerCaracteres,
	salvarConfiguracaoUsuario,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	Prompt,
	ToastTypes,
	Tutorial,
	notify,
	tutorialStepsMarca,
} from 'components';
import { Field, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { buscarEstadoCadastro } from '../Util/function';
import { situacaoOptions } from '../Util/constantes';
import { converterMarcaParaFormulario, converterParaDuplicar } from '../Util/marcaConverter';
import ActionButtons from './components/ActionButtons';
import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { asyncGetMarca } from '../Requests';

const initialValue = {
	id: null,
	nome: '',
	situacao: 'ATIVO',
};

function MarcaFormView(props) {
	const { values, dirty, setFieldValue, resetForm, isModal, hideModal } = props;

	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_MARCAS, permissoes.INSERIR));
	const [podeEditar, setPodeEditar] = useState(usuarioPossuiPermissao(recursos.CADASTROS_MARCAS, permissoes.EDITAR));
	const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_MARCAS, permissoes.EXCLUIR));
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const informacoesPermissoes = {
		estadoCadastro: buscarEstadoCadastro(),
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_MARCA);
		const idURL = props.match?.params.id;
		const marcaIdParaDuplicar = props.location?.state?.marcaId;

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_MARCA, false, null, false);
		}

		if (marcaIdParaDuplicar) {
			duplicarMarca(marcaIdParaDuplicar);
		} else if (validarUUID(idURL)) {
			getMarca(idURL);
		}

		setTimeout(() => {
			document.getElementById('InputNomeMarca').focus();
		}, 300);
	}, []);

	function duplicarMarca(marcaId) {
		asyncGetMarca(marcaId, ({ data: marca }) => {
			atualizarUrl(props.history, '/marca/cadastro', null, metodosAtualizarUrl.POP);
			const marcaFormulario = converterMarcaParaFormulario(marca);

			props.setValues(converterParaDuplicar(marcaFormulario));
		});
	}

	function getMarca(idURL) {
		asyncGetMarca(
			idURL,
			({ data: marca }) => {
				let marcaConvertida = converterMarcaParaFormulario(marca);
				resetForm({ values: marcaConvertida });
			},
			(error) => {
				notify('Erro ao buscar registro', ToastTypes.ERROR);
				console.error(error);
			}
		);
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsMarca}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Cadastro de marca" isModal={isModal} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButtons
						isModal={isModal}
						hideModal={hideModal}
						informacoesPermissoes={informacoesPermissoes}
						{...props}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="9"
							lg="10"
							xl="10"
							id="InputNomeMarca"
							component={InputField}
							label="Nome"
							obrigatorio
							name="nome"
							onBlur={() => {
								if (values.nome) {
									setFieldValue('nome', values.nome.trim());
								}
							}}
							onChange={(e) => setFieldValue('nome', removerCaracteres(e.target?.value, ['─']))}
							value={values.nome}
							size={120}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="2"
							xl="2"
							component={Dropdown}
							label="Situação"
							obrigatorio
							name="situacao"
							value={values.situacao}
							onChange={(event) => setFieldValue('situacao', event?.value)}
							options={situacaoOptions}
							showClear={false}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const MarcaForm = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (!values.nome) {
			errors.nome = mensagensDeValidacao.OBRIGATORIO;
		}
		return errors;
	},

	handleSubmit: () => {},
})(MarcaFormView);

export default MarcaForm;
