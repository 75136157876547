import { useState } from 'react';
import { copiarObjeto, keyFilterConsultaRsql } from 'Common';
import {
	ButtonCancelar,
	ButtonSalvar,
	FormActions,
	Modal,
	SingleSelectCondutor,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
} from 'components';
import { Field, useFormikContext } from 'formik';

function ModalCondutorImpl({ informacoesPermissoes, visible, onHide }) {
	const { values, setFieldValue } = useFormikContext();
	const [valuesModalCondutor, setValuesModalCondutor] = useState(null);

	function handleClickSalvar() {
		if (valuesModalCondutor) {
			const newValuesCondutores = copiarObjeto(values.condutores);
			newValuesCondutores.push(valuesModalCondutor.registro);
			setFieldValue('condutores', newValuesCondutores);
		}
		onHide();
	}

	function handleClickCancelar() {
		if (valuesModalCondutor) {
			setValuesModalCondutor(null);
		} else {
			onHide();
		}
	}

	return (
		<Modal header="Cadastro de condutor" visible={visible} onHide={onHide}>
			<FormActions>
				<ButtonCancelar
					estadoBotao={!valuesModalCondutor ? estadosBotaoCancelar.VOLTAR : estadosBotaoCancelar.CANCELAR}
					onClick={handleClickCancelar}
					{...informacoesPermissoes}
				/>
				<ButtonSalvar
					estadoBotao={estadosBotaoSalvar.SALVAR}
					disabled={!valuesModalCondutor}
					onClick={handleClickSalvar}
					{...informacoesPermissoes}
				/>
			</FormActions>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={SingleSelectCondutor}
				onChange={(event) => setValuesModalCondutor(event)}
				value={valuesModalCondutor}
				keyfilter={keyFilterConsultaRsql}
				name="condutor"
				size={60}
				styles={{ padding: '8px 0px' }}
				{...informacoesPermissoes}
			/>
		</Modal>
	);
}

export const ModalCondutor = ModalCondutorImpl;
