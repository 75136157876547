import { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Card } from 'primereact/card';
import { buscarDocumentosPorStatusPeriodo } from './Requests';
import { ProgressBar } from 'primereact/progressbar';
import 'chartjs-plugin-datalabels';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { ColorsCard } from '../../../../../../documentos/AssinaturaEletronica/Util/constantes';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';

const cardHeader = {
	padding: '14px',
	display: 'flex',
	justifyContent: 'space-between',
};

const NomeECorDocumento = {
	PENDENTE: {
		nome: 'Pendente',
		cor: ColorsCard['PENDENTE'].strongColor,
	},
	ARQUIVO_ADICIONADO: {
		nome: 'Arquivo adicionado',
		cor: ColorsCard['ARQUIVO_ADICIONADO'].strongColor,
	},
	AGUARDANDO_ASSINATURAS: {
		nome: 'Aguardando assinaturas',
		cor: ColorsCard['AGUARDANDO_ASSINATURAS'].strongColor,
	},
	ASSINADO: {
		nome: 'Assinado',
		cor: ColorsCard['ASSINADO'].strongColor,
	},
	REJEITADO: {
		nome: 'Rejeitado',
		cor: ColorsCard['REJEITADO'].strongColor,
	},
	EXPIRADO: {
		nome: 'Expirado',
		cor: ColorsCard['EXPIRADO'].strongColor,
	},
};

let documentosPorStatus;

export default function GraficoDocumentosPorStatusPeriodo(props) {
	const { dataInicial, dataFinal, atualizarGraficoNovamente, dataAtualizacaoMenuLateral } = props;

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [status, setStatus] = useState([]);
	const [corDocumentos, setCorDocumentos] = useState([]);
	const [quantidade, setQuantidade] = useState([]);
	const [documentos, setDocumentos] = useState([]);
	const graficoDeDocumentosAgrupadoPorStatus = useRef();
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	useEffect(() => {
		carregarInformacoes();
		return () => {
			if (typeof documentosPorStatus !== 'undefined') documentosPorStatus.destroy();
		};
	}, []);

	useEffect(() => {
		carregarInformacoes();
	}, [dataAtualizacaoMenuLateral]);

	useEffect(() => {
		if (loading) {
			buscarDocumentosPorStatusPeriodo(
				dataInicial,
				dataFinal,
				({ data: documentos }) => {
					let status = [];
					let quantidade = [];
					let corDocumentos = [];

					documentos.forEach(documento => {
						status.push(NomeECorDocumento[documento.status].nome);
						corDocumentos.push(NomeECorDocumento[documento.status].cor);
						quantidade.push(documento.quantidade);
					});

					setStatus(status);
					setQuantidade(quantidade);
					setLoading(false);
					setDocumentos(documentos);
					setCorDocumentos(corDocumentos);
				},
				() => {
					setLoading(false);
					setError(true);
				}
			);
		}
	}, [loading, dataAtualizacaoMenuLateral]);

	useEffect(() => {
		buildChart();
	}, [corDocumentos]);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}

			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;
	}, [dataInicial, dataFinal]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	function buildChart() {
		if (!graficoDeDocumentosAgrupadoPorStatus.current) {
			return;
		}

		const myChartRef = graficoDeDocumentosAgrupadoPorStatus.current.getContext('2d');

		if (typeof documentosPorStatus !== 'undefined') documentosPorStatus.destroy();
		documentosPorStatus = new Chart(myChartRef, {
			type: 'doughnut',
			data: {
				datasets: [
					{
						data: quantidade,
						backgroundColor: corDocumentos,
					},
				],
				labels: status,
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				aspectRatio: 1.1,
				onHover: (event, chartElement) => {
					chartElement.length === 1
						? (event.native.target.style.cursor = 'pointer')
						: (event.native.target.style.cursor = 'default');
				},
				plugins: {
					datalabels: {
						color: '#fff',
						font: {
							size: 11,
						},
						formatter: (value, ctx) => {
							let total = 0;
							let dataArr = ctx.chart.data.datasets[0].data;
							dataArr.forEach(data => {
								total += data;
							});
							return ((value * 100) / total).toFixed(1) + '%';
						},
					},
					legend: {
						position: 'bottom',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
						},
						onHover: event => {
							event.native.target.style.cursor = 'pointer';
						},
						onLeave: event => {
							event.native.target.style.cursor = 'default';
						},
					},
				},
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							const quantidade = data.datasets[0].data[tooltipItem.index];
							if (quantidade === 1) {
								return ` ${quantidade} Documento`;
							}
							return ` ${quantidade} Documentos`;
						},
						title: (tooltipItem, data) => {
							const label = data.labels[tooltipItem[0].index];
							let total = 0;
							let dataArr = data.datasets[0].data;
							dataArr.forEach(data => (total += data));
							const value = dataArr[tooltipItem[0].index];
							return label + ' (' + ((value * 100) / total).toFixed(1) + '%)';
						},
					},
				},
			},
		});
	}

	function getHeaderCard() {
		return (
			<div style={cardHeader}>
				<label
					title="Mostra os documentos adicionados no período e agrupa por status"
					style={{ fontSize: '17px', color: '#333333' }}
				>
					Documentos por status no período
				</label>
			</div>
		);
	}

	if (loading) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
				<ProgressBar mode="indeterminate" style={{ height: '3px' }} />
			</Card>
		);
	} else if (error) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
				<FalhaAoCarregarAsInformacoes />
			</Card>
		);
	} else if (documentos.length === 0) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
				<NenhumaInformacaoEncontrada />
			</Card>
		);
	} else {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%', margin: '0px' }}>
				<canvas
					id="graficoDeDocumentosAgrupadoPorStatus"
					ref={graficoDeDocumentosAgrupadoPorStatus}
					style={{ maxWidth: '100%' }}
				/>
			</Card>
		);
	}
}
