import { useState } from 'react';
import { format } from 'date-fns';
import { Field, withFormik } from 'formik';
import { formatos, inserirMascara, mensagensDeValidacao } from '../../../../../../../../Common';
import {
	ButtonCancelar,
	estadosBotaoCancelar,
	Form,
	Grid,
	InputField,
	TextArea,
	FormActions,
	FormContent,
	Button,
	If,
	ModalLoadingTransmissao,
} from '../../../../../../../../components';
import { asyncPostGerarSpedEEnviarPorEmail } from '../../../../../Requests';
import { converterSpedEmailParaApi } from '../../../../../Util/spedConverter';
import * as Yup from 'yup';
import { isValidEmail } from '@brazilian-utils/brazilian-utils';
import { validarFormulario } from '../../../../../../../Util';

const initialValue = sped => ({
	assunto: `SPED - ${format(new Date(sped.dataInicial), 'dd/MM/yyyy')} - ${format(
		new Date(sped.dataFinal),
		'dd/MM/yyyy'
	)}`,
	corpoEmail: sped.filial?.razaoSocial
		? `Olá, segue SPED fiscal da empresa ${sped.filial?.razaoSocial}, ${
				sped.filial?.cpf
					? `CPF ${inserirMascara(sped.filial?.cpf, formatos.CPF)}`
					: `CNPJ ${inserirMascara(sped.filial?.cnpj, formatos.CNPJ)}`
		  }.`
		: `Olá, segue SPED fiscal requisitado.`,
	destinatarios: sped.filial?.emailContador
		? sped.filial?.emailContador
		: sped.filial.contador
		? sped.filial.contador.email
		: null,
});

function FieldsEnviarPorEmailView(props) {
	const { values, onHide, setFieldValue } = props;

	const [exibirLoadingGerarSpedEnviarEmail, setExibirLoadingGerarSpedEnviarEmail] = useState(false);

	async function onClickEnviarEmail() {
		props.handleSubmit();

		if (await validarFormulario(props)) {
			setExibirLoadingGerarSpedEnviarEmail(true);
			await asyncPostGerarSpedEEnviarPorEmail(
				converterSpedEmailParaApi(values),
				() => {
					setExibirLoadingGerarSpedEnviarEmail(false);
					onHide();
				},
				() => {
					setExibirLoadingGerarSpedEnviarEmail(false);
				}
			);
		}
	}

	return (
		<Form>
			<FormActions style={{ marginBottom: '10px' }}>
				<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
				<Button
					label="Enviar por e-mail"
					icon="fa fa-send"
					onClick={onClickEnviarEmail}
					style={{ margin: '5px' }}
				/>
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={InputField}
						label="Destinatário(s)"
						name="destinatarios"
						helpMessage="Destinatários que irão receber o e-mail, podem ser informados vários e-mails separados por vírgula"
						obrigatorio
						values={values.destinatarios}
						size={255}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={InputField}
						label="Assunto"
						obrigatorio
						name="assunto"
						values={values.assunto}
						helpMessage="Assunto (título) do e-mail que será encaminhado para o(s) destinatário(s)"
						size={255}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={TextArea}
						label="Texto do e-mail"
						obrigatorio
						placeholder="Escreva o texto do e-mail aqui..."
						name="corpoEmail"
						values={values.corpoEmail}
						helpMessage="Texto do e-mail que será encaminhado para o(s) destinatário(s)"
						rows={5}
						onChange={e => setFieldValue('corpoEmail', e.target.value)}
					/>
				</Grid>
			</FormContent>
			<If test={exibirLoadingGerarSpedEnviarEmail}>
				<ModalLoadingTransmissao
					visible={exibirLoadingGerarSpedEnviarEmail}
					message="Gerando SPED e enviando por e-mail..."
					onHide={() => {}}
				/>
			</If>
		</Form>
	);
}

const FieldsEnviarPorEmail = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return { ...initialValue(props.sped), sped: props.sped };
	},

	validate(values, props) {
		let errors = {};

		if (values.destinatarios) {
			const arrayDestinatarios = values.destinatarios
				.split(',')
				.map(item => item.trim())
				.filter(item => item.trim() !== '');

			arrayDestinatarios.forEach(destinatario => {
				if (!isValidEmail(destinatario)) {
					errors.destinatarios = `O e-mail ${destinatario} não é válido`;
				}
			});
		} else {
			errors.destinatarios = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		assunto: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		corpoEmail: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(FieldsEnviarPorEmailView);

export default FieldsEnviarPorEmail;
