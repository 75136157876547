export const METODO_PAGAMENTO = {
  CARTAO: 'CARTAO',
  NOVO_CARTAO: 'NOVO_CARTAO',
  PIX: 'PIX',
};

export const Etapas = {
  DETALHES: 'DETALHES',
  PAGAMENTO: 'PAGAMENTO',
  CONFIRMACAO: 'CONFIRMACAO',
};

export const GetSteps = [
  {
    name: 'Detalhes',
    value: Etapas.DETALHES,
    index: 0,
  },
  {
    name: 'Pagamento',
    value: Etapas.PAGAMENTO,
    index: 1,
  },
  {
    name: 'Confirmação',
    value: Etapas.CONFIRMACAO,
    index: 2,
  },
];
