/** https://github.com/the-darc/string-mask
 * var tokens = {
        0	Any numbers
        9	Any numbers (Optional)
        #	Any numbers (recursive)
        A	Any alphanumeric character
        a	Any alphanumeric character (Optional) Not implemented yet
        S	Any letter
        U	Any letter (All lower case character will be mapped to uppercase)
        L	Any letter (All upper case character will be mapped to lowercase)
        $	Escape character, used to escape any of the special formatting characters.
 * };
 */

export const formatos = {
	TELEFONE: '(00) 0000-0000',
	CELULAR: '(00) 00000-0000',
	CEP: '00000-000',
	CPF: '000.000.000-00',
	CNPJ: '00.000.000/0000-00',
	NCM: '0000.00.00',
};
