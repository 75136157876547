import { Field } from 'formik';
import { Fieldset } from 'primereact/fieldset';

import { Col, Grid, SingleSelectCodigoServico } from 'components';
import { helpProdutosForm } from '../../../Help';
import { useEffect } from 'react';

export default function FieldsServicos(props) {
	const { setFieldValue, values, informacoesPermissoes, errors, setFieldTouched } = props;

	useEffect(() => {
		if (values.codigoServico) {
			setFieldTouched('codigoServico');
		}
	}, [values.codigoServico]);

	return (
		<Grid>
			<Col>
				<Fieldset className="fieldset">
					<Grid>
						<Field
							sm="12"
							md="5"
							lg="5"
							xl="5"
							obrigatorio
							component={SingleSelectCodigoServico}
							mostrarSimbolo
							label="Código serviço"
							name="codigoServico"
							value={values.codigoServico}
							helpMessage={helpProdutosForm.codigoServico}
							onChange={value => setFieldValue('codigoServico', value)}
							errors={errors.codigoServico}
							{...informacoesPermissoes}
						/>
					</Grid>
				</Fieldset>
			</Col>
		</Grid>
	);
}
