import { format, isBefore, parseISO, startOfDay } from 'date-fns';
import { withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	mensagensDeValidacao,
	permissoes,
	recursos,
	removerCaracteres,
	salvarConfiguracaoUsuario,
	services,
	useStateCallback,
	usuarioPossuiPermissao,
} from 'Common';

import {
	Form,
	FormActions,
	FormContent,
	If,
	ModalLoadingTransmissao,
	Tutorial,
	tutorialStepsListagens,
} from 'components';

import { asyncGetCobrancas, asyncGetContaFavoritaReceita } from './Requests';
import ModalContaReceber from '../ContasReceber/components/ModalContaReceber';
import { converteCobrancasParaFormulario } from './Util/cobrancaConverter';
import { getIdCobrancasSelected } from './Util/functions';
import ActionButtons from './components/ActionButtons';
import ModalEnviarCobrancasPorEmail from './components/ModalEnviarCobrancasPorEmail';
import PesquisaFields from './components/PesquisaFields';
import TabelaCobrancas from './components/TabelaCobrancas';

function currentMonth() {
	const date = new Date();
	const firstDay = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
	const lastDay = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
	const formatedDate = `vencimento>=${firstDay};vencimento<=${lastDay}`;

	return formatedDate;
}

const initialValues = {
	conta: null,
	convenio: null,
	registros: [],
	selectedCobrancas: [],
};

function PixCobrancaView(props) {
	const { isMobile, setFieldValue, values } = props;

	const [totalElements, setTotalElements] = useState(0);
	const [rows, setRows] = useState(10);
	const [page, setPage] = useState(0);
	const [sortOrder] = useState(-1);
	const [sortField] = useState('vencimento');
	const [interval, setInterval] = useState({
		dataInicial: null,
		dataFinal: null,
	});
	const [filtroData, setFiltroData] = useState('');
	const [valorPesquisa, setValorPesquisa] = useStateCallback('');
	const [filtroAvancado, setFiltroAvancado] = useState('');
	const [exibirLoading, setExibirLoading] = useState(false);
	const [messageLoading, setMessageLoading] = useState('Loading...');
	const [exibirModalContaReceber, setExibirModalContaReceber] = useState(false);
	const [exibirModalEnviarPorEmail, setExibirModalEnviarPorEmail] = useState(false);
	const [dadosPessoaParaEmail, setDadosPessoaParaEmail] = useState(null);

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EDITAR));
	const [podeVisualizar] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR));
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial, setDeveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
	);

	useEffect(() => {
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			setDeveExibirTutorial(false);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		buscarContaFavorita();

		setTimeout(() => {
			document.getElementById('CobrancaInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		pesquisar();
	}, [interval, filtroAvancado, rows, page]);

	function buscarContaFavorita() {
		asyncGetContaFavoritaReceita(async ({ data }) => {
			if (data.totalElements > 0) {
				const contaFavorita = {
					label: await montarLabel(data.content[0]),
					registro: data.content[0],
					value: data.content[0].id,
				};
				await props.resetForm({
					values: { ...values, conta: contaFavorita },
				});
			}
		});
	}

	function montarLabel(conta) {
		return conta.nome;
	}

	function validateDate(dateInput) {
		const dateFormats = [
			/(\d{2})\/(\d{2})\/(\d{4})/,
			/(\d{2}).(\d{2}).(\d{4})/,
			/(\d{2})-(\d{2})-(\d{4})/,

			/(\d{4})\/(\d{2})\/(\d{2})/,
			/(\d{4}).(\d{2}).(\d{2})/,
		];
		let dataFormatada = null;
		dateFormats.forEach((dateFormat) => {
			const dataCorrespondente = dateInput.match(dateFormat);
			let day = null;
			let month = null;
			let year = null;

			if (dataCorrespondente) {
				if (dataCorrespondente[1].length <= 2) {
					[, day, month, year] = dataCorrespondente;
				} else {
					[, year, month, day] = dataCorrespondente;
				}

				try {
					dataFormatada = format(new Date(year, month - 1, day), 'yyyy-MM-dd');
				} catch (error) {
					return null;
				}
			}
			return dataFormatada;
		});
		return dataFormatada;
	}

	function buscarFiltro() {
		const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.']);
		let pesquisaData = valorPesquisa;

		if (valorPesquisa.length === 10) {
			pesquisaData = validateDate(valorPesquisa);
		}

		let filtroRSQL = String('?query=(')
			.concat(`identificador=contains="*${pesquisaCodigo.replaceAll('&', '')}*",`)
			.concat(`descricao=contains="*${pesquisaCodigo.replaceAll('&', '')}*",`)
			.concat(`emissao=contains="*${pesquisaData?.replaceAll('&', '%26')}*",`)
			.concat(`vencimento=contains="*${pesquisaData?.replaceAll('&', '%26')}*",`)
			.concat(`valor=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(
				`pessoa.pessoaFisica.cpf=contains="*${removerCaracteres(valorPesquisa, ['.', '-']).replaceAll('&', '%26')}*",`
			)
			.concat(
				`pessoa.pessoaJuridica.cnpj=contains="*${removerCaracteres(valorPesquisa, ['.', '/', '-']).replaceAll(
					'&',
					'%26'
				)}*",`
			)
			.concat(`pessoa.nome=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroData) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroData})`);
		} else {
			filtroRSQL = filtroRSQL.concat(`;(${currentMonth()})`);
		}

		if (filtroAvancado && filtroAvancado?.includes('pix.status=="GERADO"')) {
			const hasMoreFilters = filtroAvancado.replace(
				'pix.status=="GERADO"',
				`pix.status=="PENDENTE";pix.validade>=${format(new Date(), 'yyyy-MM-dd')}`
			);
			if (hasMoreFilters.length > 0) {
				filtroRSQL = filtroRSQL.concat(`;(${hasMoreFilters})`);
			}
		} else if (filtroAvancado && filtroAvancado?.includes('pix.status!="GERADO"')) {
			const hasMoreFilters = filtroAvancado.replace(
				'pix.status!="GERADO"',
				`pix==null,pix.status!="PENDENTE",pix.validade<${format(new Date(), 'yyyy-MM-dd')}`
			);
			if (hasMoreFilters.length > 0) {
				filtroRSQL = filtroRSQL.concat(`;(${hasMoreFilters})`);
			}
		} else if (filtroAvancado && filtroAvancado?.includes('pix.status=="PENDENTE"')) {
			const hasMoreFilters = filtroAvancado.replace('pix.status=="PENDENTE"', 'pix=="null"');
			if (hasMoreFilters.length > 0) {
				filtroRSQL = filtroRSQL.concat(`;(${hasMoreFilters})`);
			}
		} else if (filtroAvancado && filtroAvancado?.includes('pix.status!="PENDENTE"')) {
			const hasMoreFilters = filtroAvancado.replace('pix.status!="PENDENTE"', 'pix!="null"');
			if (hasMoreFilters.length > 0) {
				filtroRSQL = filtroRSQL.concat(`;(${hasMoreFilters})`);
			}
		} else if (filtroAvancado && filtroAvancado?.includes('pix.status=="EXPIRADO"')) {
			const hasMoreFilters = filtroAvancado.replace(
				'pix.status=="EXPIRADO"',
				`pix.status=="PENDENTE";pix.validade<${format(new Date(), 'yyyy-MM-dd')}`
			);
			if (hasMoreFilters.length > 0) {
				filtroRSQL = filtroRSQL.concat(`;(${hasMoreFilters})`);
			}
		} else if (filtroAvancado && filtroAvancado?.includes('pix.status!="EXPIRADO"')) {
			const hasMoreFilters = filtroAvancado.replace(
				'pix.status!="EXPIRADO"',
				`pix==null,pix.validade>=${format(new Date(), 'yyyy-MM-dd')}`
			);
			if (hasMoreFilters.length > 0) {
				filtroRSQL = filtroRSQL.concat(`;(${hasMoreFilters})`);
			}
		} else if (filtroAvancado && filtroAvancado?.includes('pix.status!="RECEBIDO"')) {
			const hasMoreFilters = filtroAvancado.replace('pix.status!="RECEBIDO"', 'pix.status!="RECEBIDO",pix=="null"');
			if (hasMoreFilters.length > 0) {
				filtroRSQL = filtroRSQL.concat(`;(${hasMoreFilters})`);
			}
		} else if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}
		return filtroRSQL;
	}

	async function pesquisar() {
		const filtro = buscarFiltro();

		const url = construirUrl(
			`${services.GESTOR}/v1/pix/resumo`,
			filtro || '?',
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		asyncGetCobrancas(url, ({ data: cobrancas }) => {
			setFieldValue('registros', converteCobrancasParaFormulario(cobrancas.content));
			setTotalElements(cobrancas.totalElements);
		});
	}

	function onHideModalContaReceber() {
		pesquisar();
		setExibirModalContaReceber(false);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Pix cobrança">
				<FormActions>
					<ActionButtons
						podeInserir={podeInserir}
						podeEditar={podeEditar}
						podeVisualizar={podeVisualizar}
						isMobile={isMobile}
						pesquisar={pesquisar}
						values={values}
						setFieldValue={setFieldValue}
						handleSubmit={props.handleSubmit}
						setExibirModalContaReceber={setExibirModalContaReceber}
						setExibirModalEnviarPorEmail={setExibirModalEnviarPorEmail}
						setExibirLoading={setExibirLoading}
						setMessageLoading={setMessageLoading}
						setDadosPessoaParaEmail={setDadosPessoaParaEmail}
						buscarContaFavorita={buscarContaFavorita}
						propsForm={props}
					/>
				</FormActions>
				<FormContent>
					<PesquisaFields
						setFieldValue={setFieldValue}
						pesquisar={pesquisar}
						valorPesquisa={valorPesquisa}
						setFiltroData={setFiltroData}
						setPage={setPage}
						setInterval={setInterval}
						setValorPesquisa={setValorPesquisa}
						setFiltroAvancado={setFiltroAvancado}
					/>
					<TabelaCobrancas
						sortField={sortField}
						sortOrder={sortOrder}
						registros={values.registros}
						selectedCobrancas={values.selectedCobrancas}
						setFieldValue={setFieldValue}
						totalElements={totalElements}
						rows={rows}
						setRows={setRows}
						page={page}
						setPage={setPage}
						history={props.history}
						podeEditar={podeEditar}
					/>
				</FormContent>
			</Form>
			<If test={exibirLoading}>
				<ModalLoadingTransmissao visible={exibirLoading} message={messageLoading} onHide={() => {}} />
			</If>
			<If test={exibirModalContaReceber}>
				<ModalContaReceber
					visible={exibirModalContaReceber}
					onHide={onHideModalContaReceber}
					registroSelecionado={null}
					valorPadraoDataVencimento={new Date()}
				/>
			</If>
			<If test={exibirModalEnviarPorEmail}>
				<ModalEnviarCobrancasPorEmail
					visible={exibirModalEnviarPorEmail}
					onHide={() => setExibirModalEnviarPorEmail(false)}
					cobrancas={values.selectedCobrancas}
					cobrancasIds={getIdCobrancasSelected(values.selectedCobrancas)}
					dadosPessoaParaEmail={dadosPessoaParaEmail}
				/>
			</If>
		</>
	);
}

const PixCobranca = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValues;
	},

	validate(values) {
		let errors = {};
		let errorsCobrancas = {};

		if (!values.conta) {
			errors.conta = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.selectedCobrancas) {
			let errorPessoa = 0;
			let errorVencimento = 0;
			let errorVencimentoMenorQueEmissao = 0;
			let erroVencimentoMenorQueHoje = 0;

			if (values.selectedCobrancas.length > 0) {
				values.selectedCobrancas?.forEach((cobranca) => {
					if (!cobranca.pessoa) {
						errorPessoa++;
					}
					if (!cobranca.vencimento) {
						errorVencimento++;
					}
					if (isBefore(parseISO(cobranca.vencimento), parseISO(cobranca.emissao))) {
						errorVencimentoMenorQueEmissao++;
					}
					if (isBefore(parseISO(cobranca.vencimento), startOfDay(new Date()))) {
						erroVencimentoMenorQueHoje++;
					}
				});

				if (errorPessoa > 0) {
					errorsCobrancas =
						errorPessoa === 1
							? 'Registro selecionado sem pessoa vinculada'
							: 'Existem registros selecionados sem pessoa vinculada';
				}
				if (errorVencimento > 0) {
					errorsCobrancas =
						errorPessoa === 1
							? 'Registro selecionado sem data de vencimento'
							: 'Existem registros selecionados sem data de vencimento';
				}
				if (errorVencimentoMenorQueEmissao > 0) {
					errorsCobrancas =
						errorVencimentoMenorQueEmissao === 1
							? 'Registro selecionado com data de vencimento menor que a emissão'
							: 'Existem registros selecionados com data de vencimento menor que a emissão';
				}
				if (erroVencimentoMenorQueHoje > 0) {
					errorsCobrancas =
						erroVencimentoMenorQueHoje === 1
							? 'Registro selecionado com data de vencimento menor que hoje'
							: 'Existem registros selecionados com data de vencimento menor que hoje';
				}
			} else {
				errorsCobrancas = 'Não existem cobranças selecionadas para emissão';
			}
		}
		if (Object.keys(errorsCobrancas)?.length > 0) {
			errors = { ...errors, cobrancas: errorsCobrancas };
		}
		return errors;
	},

	handleSubmit: () => {},
})(PixCobrancaView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(PixCobranca);
