export const tutorialStepsMde = [
	{
		target: 'body',
		content: `Esta é a tela de manifestação do destinatário(MD-e). Nela você pode visualizar as notas fiscais emitidas para o seu CPF/CNPJ`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-busca-sefaz',
		content: `No botão "Buscar documentos da SEFAZ", você pode consultar as últimas notas fiscais emitidas`,
		placement: 'top',
	},
	{
		target: '.step-consulta-chave-acesso',
		content: `No botão "Consulta pela chave de acesso", é possível consultar uma NF-e específica através da chave de acesso`,
		placement: 'top',
	},
	{
		target: '.step-listagem-input-search',
		content: 'Neste campo, você pode pesquisar o registro desejado',
		placement: 'top',
	},
	{
		target: '.step-listagem-filtro-avancado',
		content: 'Neste campo, você pode configurar filtros para otimizar a busca por registros específicos',
		placement: 'top',
	},
	{
		target: '.step-listagem-order',
		content: 'Aqui você pode clicar no título da coluna para ordenar os registros',
		placement: 'top',
	},
	{
		target: '.step-listagem-manifestar',
		content: 'Neste botão, é possível fazer a manifestação de uma NF-e',
		placement: 'top',
	},
	{
		target: '.step-listagem-importar',
		content: 'Por último, você pode importar uma NF-e manifestada para o módulo de notas de entrada',
		placement: 'top',
	},
	{
		target: '.step-listagem-opcoes',
		content:
			'Por último, no botão de opções, é possível fazer a manifestação de uma NF-e ou importar uma NF-e manifestada para o módulo de notas de entrada',
		placement: 'top',
	},
];
