import { GESTOR_SERVICE } from 'Common';
import { LINKS } from 'Common/Constantes/links';
import {
	AutoProgressBar,
	ButtonCancelar,
	ButtonSalvar,
	Checkbox,
	Divider,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputDouble,
	Message,
	Modal,
	ToastTypes,
	estadosBotaoCancelar,
	notify,
} from 'components';
import { Field, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { salvarItemLocalStorage } from '../../../../../../../Common/LocalStorage';
import { helpConfiguracoesForm } from './Help';
import {
	asyncGetConfiguracoesServicoLocal,
	asyncGetImpressorasServicoLocal,
	asyncGetPortasCOMServicoLocal,
	asyncPostConfiguracoesServicoLocal,
} from './Requests';
import { converterParaApi, converterParaFormulario } from './Util/configuracoesConverter';
import { optionsModeloBalanca, optionsParidadeBalanca, optionsStopBitsBalanca } from './Util/constantes';

const initialvalues = {
	modeloBalanca: 'Nenhum',
	portaBalanca: 'Nenhuma',
	velocidadeBalanca: 2400,
	tamanhoBalanca: 8,
	stopBitsBalanca: 1,
	paridadeBalanca: 'Nenhum(None)',
	mostrarPreviewImpressao: false,
	impressoraImpressao: 'Padrão do Windows',
};

function ModalConfiguracoesView(props) {
	const {
		values,
		isModal,
		setFieldValue,
		onHide,
		visible,
		isMobile,
		dirty,
		resetForm,
		informacoesPermissoes,
		isFullScreen,
	} = props;

	const [optionsImpresorasImpressao, setOptionsImpresorasImpressao] = useState([]);
	const [optionsPortasBalanca, setOptionsPortasBalanca] = useState([]);
	const [servicoLocalRodando, setServicoLocalRodando] = useState(false);

	useEffect(() => {
		validarServicoLocal();

		asyncGetConfiguracoesServicoLocal(({ data: configuracoes }) => {
			resetForm({ values: converterParaFormulario(configuracoes) });
		});

		asyncGetImpressorasServicoLocal(({ data: impressoras }) => {
			const options = impressoras.map((registro) => ({ label: registro.impressora, value: registro.impressora }));
			setOptionsImpresorasImpressao(options);
		});

		asyncGetPortasCOMServicoLocal(({ data: portas }) => {
			const options = portas.map((registro) => ({ label: registro.porta, value: registro.porta }));
			setOptionsPortasBalanca(options);
		});
	}, []);

	function cancelar() {
		if (dirty) {
			resetForm();
		} else {
			onHide();
		}
	}

	async function validarServicoLocal() {
		if ((await GESTOR_SERVICE.isRunning()) && (await GESTOR_SERVICE.validateVersion())) {
			setServicoLocalRodando(true);
		}
	}

	async function downloadInstalador() {
		window.open(LINKS.DOWNLOAD_MSYSGESTOR_SERVICO, '_self');
		onHide();
	}

	function montarInformacaoMessage() {
		return (
			<>
				<div style={{ marginBottom: '7px' }}>
					Este painel de configuração destina-se ao Msys Gestor Service que não está em execução na sua máquina ou está
					desatualizado. Para instalá-lo ou atualizá-lo faça o download no link abaixo e efetue a instalação.
				</div>
				<span style={{ cursor: 'pointer' }}>
					<FaDownload size="15px" style={{ marginRight: '5px' }} />
					<span onClick={() => downloadInstalador()} style={{ marginBottom: '5px' }}>
						<b>Baixar Msys Gestor Service</b>
					</span>
				</span>
			</>
		);
	}

	async function salvar() {
		await props.validateForm();

		if (props.isValid) {
			const dadosFormulario = converterParaApi(values);
			asyncPostConfiguracoesServicoLocal(dadosFormulario, ({ data: configuracoes }) => {
				salvarItemLocalStorage('mostrarPreviewNFCe', values.mostrarPreviewImpressao, true);
				if (values.modeloBalanca !== 'Nenhum') salvarItemLocalStorage('balancaConfigurada', true, true);
				else {
					salvarItemLocalStorage('balancaConfigurada', false, true);
				}
				notify('Configurações salvas com sucesso', ToastTypes.SUCCESS);

				const layoutFullscreen = document.getElementsByClassName('layout-fullscreen');
				const alertifyNotifier = document.getElementsByClassName('alertify-notifier');

				if (isFullScreen) {
					layoutFullscreen[0].append(alertifyNotifier[0]);
				}

				resetForm({ values: converterParaFormulario(configuracoes) });
				onHide();
			});
		}
	}

	return (
		<Modal
			header="Configurações locais"
			onHide={onHide}
			visible={visible}
			styleModal={{
				maxWidth: '580px',
			}}
			isMobile={isMobile}
			container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
		>
			<AutoProgressBar />

			<Form>
				<FormActions>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							paddingBottom: '10px',
							flexWrap: 'wrap',
						}}
					>
						<ButtonCancelar
							hidden={isModal && values.id && !dirty}
							estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={() => cancelar()}
						/>
						<ButtonSalvar disabled={!dirty} onClick={() => salvar()} />
					</div>
					<Message
						style={{ marginBottom: '7px' }}
						severity="info"
						text={montarInformacaoMessage()}
						hidden={servicoLocalRodando}
					/>
				</FormActions>

				<FormContent>
					<Divider
						label="Balança"
						styleLabel={{ fontSize: '14px' }}
						styleContainer={{ margin: '0rem 0.5rem 0.5rem 0.5rem' }}
					/>
					<div style={{ margin: '1rem 0.5rem 0.5rem 0.5rem' }}>
						<Grid>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Dropdown}
								obrigatorio
								label="Modelo"
								name="modeloBalanca"
								options={optionsModeloBalanca}
								onChange={(e) => setFieldValue('modeloBalanca', e.value)}
								value={values?.modeloBalanca}
								disabled={!servicoLocalRodando}
								showClear={false}
								helpMessage={helpConfiguracoesForm.modeloBalanca}
								menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Dropdown}
								obrigatorio
								label="Porta"
								name="portaBalanca"
								options={optionsPortasBalanca}
								disabled={!servicoLocalRodando}
								onChange={(e) => setFieldValue('portaBalanca', e.value)}
								value={values?.portaBalanca}
								showClear={false}
								helpMessage={helpConfiguracoesForm.portaBalanca}
								menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={InputDouble}
								decimalScale={0}
								obrigatorio
								label="Velocidade"
								name="velocidadeBalanca"
								onChange={(e) => setFieldValue('velocidadeBalanca', e.target.value)}
								value={values?.velocidadeBalanca}
								showClear={false}
								disabled={!servicoLocalRodando}
								helpMessage={helpConfiguracoesForm.velocidadeBalanca}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={InputDouble}
								decimalScale={0}
								obrigatorio
								label="Tamanho"
								name="tamanhoBalanca"
								onChange={(e) => setFieldValue('tamanhoBalanca', e.target.value)}
								value={values?.tamanhoBalanca}
								showClear={false}
								disabled={!servicoLocalRodando}
								helpMessage={helpConfiguracoesForm.tamanhoBalanca}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={Dropdown}
								obrigatorio
								label="Stop bits"
								name="stopBitsBalanca"
								options={optionsStopBitsBalanca}
								onChange={(e) => setFieldValue('stopBitsBalanca', e.value)}
								value={values?.stopBitsBalanca}
								showClear={false}
								disabled={!servicoLocalRodando}
								helpMessage={helpConfiguracoesForm.stopBitsBalanca}
								menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Dropdown}
								obrigatorio
								label="Paridade"
								name="paridadeBalanca"
								options={optionsParidadeBalanca}
								onChange={(e) => setFieldValue('paridadeBalanca', e.value)}
								value={values?.paridadeBalanca}
								showClear={false}
								disabled={!servicoLocalRodando}
								helpMessage={helpConfiguracoesForm.paridadeBalanca}
								menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
								{...informacoesPermissoes}
							/>
						</Grid>
					</div>

					<Divider label="Impressão" styleLabel={{ fontSize: '14px' }} />
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Checkbox}
						obrigatorio
						label="Mostrar preview"
						name="mostrarPreviewImpressao"
						onChange={() => {
							setFieldValue('mostrarPreviewImpressao', !values.mostrarPreviewImpressao);
							if (!values.mostrarPreviewImpressao === true) {
								setFieldValue('impressoraImpressao', initialvalues.impressoraImpressao);
							}
						}}
						checked={values?.mostrarPreviewImpressao}
						disabled={!servicoLocalRodando}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Dropdown}
						obrigatorio
						label="Impressora"
						name="impressoraImpressao"
						options={optionsImpresorasImpressao}
						onChange={(e) => setFieldValue('impressoraImpressao', e.value)}
						value={values?.impressoraImpressao}
						showClear={false}
						disabled={!servicoLocalRodando || values?.mostrarPreviewImpressao}
						helpMessage={helpConfiguracoesForm.impressoraImpressao}
						menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
						{...informacoesPermissoes}
					/>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalConfiguracoes = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (!props.values) {
			return initialvalues;
		}
		return null;
	},

	validate(values) {
		const errors = {};
		if (!values.velocidadeBalanca) {
			errors.velocidadeBalanca = 'Campo obrigatório';
		}
		if (!values.tamanhoBalanca) {
			errors.tamanhoBalanca = 'Campo obrigatório';
		}
		return errors;
	},

	handleSubmit: () => {},
})(ModalConfiguracoesView);

export default ModalConfiguracoes;
