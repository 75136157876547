import { Button, Grid } from 'components';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';

function TabSeguranca() {
	const { setModalExcluirEmpresaVisible, podeExcluirEmpresa, isHelpDesk } = useContextConfiguracoesGerais();

	return (
		<>
			<p style={{ fontSize: '16px', fontWeight: 'bold', padding: '0.5rem 0' }}>Segurança</p>
			Nesta seção você pode apagar completamente a sua conta, isso fará com que{' '}
			<b>todos os dados contidos no sistema sejam perdidos e este processo não pode ser revertido</b>. Portanto tenha
			absoluta certeza que deseja fazer isso antes de prosseguir.
			<Grid>
				<Button
					className="p-button-danger"
					type="button"
					label="Apagar todos os dados"
					icon="fa fa-trash"
					onClick={() => setModalExcluirEmpresaVisible(true)}
					disabled={!podeExcluirEmpresa || isHelpDesk}
					style={{ margin: '26px 26px 20px 7px' }}
					title={!podeExcluirEmpresa ? 'Você não possui permissão para apagar os dados' : null}
				/>
			</Grid>
		</>
	);
}

export { TabSeguranca };
