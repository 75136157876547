function converterGrupoProdutosParaApi(data) {
	if (data?.length > 0) {
		const grupo = data.map((item) => {
			return {
				id: item.value,
				descricao: item.registro?.descricao,
				nivel: item.registro?.nivel,
				codigoNivel: item.registro?.codigoNivel,
				grupoPai: item.registro?.grupoPai,
			};
		});

		return grupo;
	}

	return null;
}

export { converterGrupoProdutosParaApi };
