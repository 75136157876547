import { Field, useFormikContext } from 'formik';

import { Card, Grid, InputDouble } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { ClassNameInputValorTotalColor, MDFE_SITUACAO } from 'views/transporte/MDFe/Form/Util/constantes';

import './Styles/index.css';

function Totalizadores() {
	const { values, errors, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, isSituacaoFinalMDFe, isTablet } = useContextMDFe();

	function getLabelColorValorTotal() {
		switch (values.situacao) {
			case MDFE_SITUACAO.NAO_ENVIADO: {
				return '#01579b';
			}
			case MDFE_SITUACAO.AGUARDANDO_AUTORIZACAO: {
				return '#333333';
			}
			case MDFE_SITUACAO.TRANSMITIDO: {
				return '#1b5e20';
			}
			case MDFE_SITUACAO.REJEITADO: {
				return '#765100';
			}
			case MDFE_SITUACAO.ENCERRADO: {
				return '#333333';
			}
			case MDFE_SITUACAO.CANCELADO: {
				return '#b71c1c';
			}
			case MDFE_SITUACAO.DENEGADO: {
				return '#b71c1c';
			}
			default: {
				return '#01579b';
			}
		}
	}

	return (
		<Card>
			<Grid style={{ justifyContent: 'flex-end' }}>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputDouble}
					label={isTablet ? 'Qtde. documentos' : 'Quantidade documentos'}
					name="total.quantidadeDocumento"
					obrigatorio={!values.informacaoComplementar?.permiteCarregamentoPosterior}
					decimalScale={0}
					allowNegative={false}
					size={11}
					disabled
					errors={errors.total?.quantidadeDocumento}
					touched
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputDouble}
					label="Peso total"
					name="total.pesoBruto"
					obrigatorio
					decimalScale={2}
					allowNegative={false}
					size={155}
					errors={errors.total?.pesoBruto}
					touched
					onChange={(e) => setFieldValue('total.pesoBruto', e.target.value)}
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					className={`${ClassNameInputValorTotalColor[values.situacao]}`}
					styleLabel={{ fontWeight: 'bold', color: getLabelColorValorTotal() }}
					component={InputDouble}
					label="Valor total"
					name="total.valorTotal"
					obrigatorio
					decimalScale={2}
					allowNegative={false}
					size={15}
					errors={errors.total?.valorTotal}
					onChange={(e) => setFieldValue('total.valorTotal', e.target.value)}
					touched
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
			</Grid>
		</Card>
	);
}

export { Totalizadores };
