import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { baixarArquivo, mensagensDeValidacao } from '../../../../../../../../../../../Common';
import {
	Button,
	Col,
	DateInterval,
	Dropdown,
	Form,
	Grid,
	Modal,
	ModalLoadingTransmissao,
} from '../../../../../../../../../../../components';
import FormActions from '../../../../../../../../../../../components/FormActions';
import FormContent from '../../../../../../../../../../../components/FormContent';
import { asyncGetSaidasIdentificadas } from '../../../../../../../Requests';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import InputSelectCpfOrCnpj from '../../../../../../../../../../../components/input/InputSelectCpfOrCnpj';

const initialValue = {
	dataInicial: new Date().toISOString(),
	dataFinal: new Date().toISOString(),
	adquerinte: 'TODOS',
	tipo: 'CPF',
	documento: null,
};

function ModalSaidasIdentificadasView(props) {
	const { onHide, isMobile, visible, isFullScreen, values, setFieldValue, isValid, handleSubmit, validateForm } =
		props;

	const [exibirLoadingGeracao, setExibirLoadingGeracao] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('SaidasIdentificadasInputDateDataInicial')?.focus();
		}, 500);
	}, []);

	async function saidasIdentificadas() {
		await handleSubmit();
		await validateForm();
		if (isValid) {
			setExibirLoadingGeracao(true);
			asyncGetSaidasIdentificadas(
				format(parseISO(values.dataInicial), 'yyyy-MM-dd'),
				format(parseISO(values.dataFinal), 'yyyy-MM-dd'),
				values.adquerinte,
				values.documento,
				({ data: xml }) => {
					baixarArquivo(
						xml,
						`SaidasIdenficadas${format(parseISO(values.dataInicial), 'ddMMyyyy')}a${format(
							parseISO(values.dataFinal),
							'ddMMyyyy'
						)}.xml`
					);
					setExibirLoadingGeracao(false);
					onHide();
				},
				() => {
					setExibirLoadingGeracao(false);
				}
			);
		}
	}

	function trocarTipoDocumento() {
		if (values.tipo === 'CPF') {
			setFieldValue('tipo', 'CNPJ');
			setFieldValue('documento', null);
		} else {
			setFieldValue('tipo', 'CPF');
			setFieldValue('documento', null);
		}
	}

	return (
		<>
			<Modal
				header="Saídas identificadas CPF/CNPJ"
				onHide={onHide}
				visible={visible}
				styleModal={{
					maxWidth: '600px',
				}}
				isMobile={isMobile}
				container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
			>
				<Form>
					<FormActions>
						<Button
							className="p-button p-button-primary"
							icon="fa fa-download"
							title="Gerar e baixar o arquivo XML"
							label="Gerar arquivo"
							onClick={saidasIdentificadas}
						/>
					</FormActions>
					<FormContent>
						<Grid>
							<Col sm="1" md="1" lg="3" xl="3" />
							<Col sm="10" md="10" lg="6" xl="6">
								<div style={{ justifyContent: 'center' }}>
									<DateInterval
										onChange={e => {
											setFieldValue('dataInicial', e.dataInicial.toISOString());
											setFieldValue('dataFinal', e.dataFinal.toISOString());
										}}
										appendTo={
											isFullScreen
												? document.getElementsByClassName('layout-fullscreen')[0]
												: null
										}
									/>
								</div>
							</Col>
							<Col sm="1" md="1" lg="3" xl="3" />

							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Dropdown}
								label="Adquerinte"
								name="adquerinte"
								value={values.adquerinte}
								onChange={e => {
									if (e.value === 'TODOS') {
										setFieldValue('adquerinte', e.value);
										setFieldValue('documento', null);
									} else {
										setFieldValue('adquerinte', e.value);
									}
								}}
								options={[
									{ value: 'TODOS', label: 'Todos' },
									{ value: 'ESPECIFICO', label: 'Específico' },
								]}
								obrigatorio
								helpMessage="Filtrar por Adquerinte da NFC-e"
								showClear={false}
								menuPortalTarget={
									isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null
								}
							/>

							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								name="documento"
								label="CPF/CNPJ"
								autofocus
								isMulti
								component={InputSelectCpfOrCnpj}
								value={values.documento}
								valueDocumentoTipo={values.tipo}
								onChange={e => {
									setFieldValue('documento', e);
								}}
								disabled={values.adquerinte === 'TODOS'}
								onChangeDocumentoTipo={e => trocarTipoDocumento(e)}
							/>
						</Grid>
					</FormContent>
				</Form>
			</Modal>
			<ModalLoadingTransmissao
				visible={exibirLoadingGeracao}
				message="Gerando saídas identificadas CPF/CNPJ..."
				container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
			/>
		</>
	);
}

const ModalSaidasIdentificadas = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (!values.dataInicial || values.dataInicial === '') {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataFinal || values.dataFinal === '') {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		dataInicial: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		dataFinal: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
	}),

	handleSubmit: () => {},
})(ModalSaidasIdentificadasView);

export default ModalSaidasIdentificadas;
