import { tipoCampos } from '../../../../components/PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancadoPix = [
	{ label: 'Identificador', name: 'identificador', type: tipoCampos.STRING },
	{ label: 'Emissão', name: 'emissao', type: tipoCampos.DATE },
	{ label: 'Vencimento', name: 'vencimento', type: tipoCampos.DATE },
	{ label: 'Nome pessoa', name: 'pessoa.nome', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'pix.status',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Pendente', value: 'PENDENTE' },
			{ label: 'Expirado', value: 'EXPIRADO' },
			{ label: 'Gerado', value: 'GERADO' },
			{ label: 'Recebido', value: 'RECEBIDO' },
		],
	},
];

export const Situacao = {
	PENDENTE: {
		value: 'PENDENTE',
		label: 'Pendente',
	},
	GERADO: {
		value: 'GERADO',
		label: 'Gerado',
	},
	RECEBIDO: {
		value: 'RECEBIDO',
		label: 'Recebido',
	},
	EXPIRADO: {
		value: 'EXPIRADO',
		label: 'Expirado',
	},
};

export const ColorsSituacao = {
	bgPendente: '#B3E5FC',
	textPendente: '#006095',
	bgExpirado: '#FFCDD2 ',
	textExpirado: '#B71C1C ',
	bgGerado: '#FBD6BC',
	textGerado: '#D35D08',
	bgRecebido: '#DCEDC8',
	textRecebido: '#24812A',
};
