import { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import Button from '../Button';
import Modal from '../Modal';
import { alert, notify, ToastTypes } from '../Toast';
import WebCamCapture from './components/WebCamCapture';
import WebCamVideo from './components/WebCamVideo';
import { connect } from 'react-redux';

function WebCam(props) {
	const { visible, header, onHide, isMobile, isTablet, imagemUrl, setFieldValue, appendTo = document.body } = props;

	const [streamWebCam, setStreamWebCam] = useState(null);
	const [hasPicture, setHasPicture] = useState(false);
	const [imagemUrlState, setImagemUrlState] = useState(null);
	const [possuiPermissoesWebCam, setPossuiPermissoesWebCam] = useState(false);

	const styleModal = {
		maxWidth: isTablet || isMobile ? '488px' : '950px',
		width: isTablet || isMobile ? '100%' : 'auto',
	};

	const styleContent = {
		display: 'flex',
		flexDirection: 'column',
	};

	const styleContainerButtons = {
		display: 'flex',
		flexDirection: isMobile ? 'column' : 'row',
		justifyContent: 'space-between',
	};

	const styleButtonsNew = {
		display: 'flex',
	};

	const styleButtonsConfirm = {
		display: hasPicture ? 'inline' : 'none',
	};

	const styleButton = {
		margin: '5px 5px 10px 0',
	};

	const styleButtonCapturar = {
		background: '#006095',
		border: '1px solid #006095',
	};

	const styleButtonExcluir = {
		color: '#ffffff',
		background: '#D32F2F',
		border: '1px solid #D32F2F',
	};

	const styleButtonUpload = {
		color: '#ffffff',
		background: '#006095 ',
		border: '1px solid #006095 ',
	};

	const styleButtonConfirmar = {
		color: '#ffffff',
		background: '#34a835 ',
		border: '1px solid #34a835 ',
	};

	const styleContentImage = {
		display: 'flex',
		flexDirection: isTablet || isMobile ? 'column' : 'row',
		justifyContent: 'space-between',
	};

	useEffect(() => {
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then(mediaStream => {
					let video = document.querySelector('#video');

					video.srcObject = mediaStream;
					video.play();
					setPossuiPermissoesWebCam(true);
					setStreamWebCam(mediaStream);
				})
				.catch(err => {
					setPossuiPermissoesWebCam(false);
				});
		}
		if (imagemUrl) {
			setHasPicture(true);
			setImagemUrlState(imagemUrl);
		}
	}, []);

	useEffect(() => {
		if (imagemUrl) {
			setHasPicture(true);
		} else {
			setHasPicture(false);
		}
		setImagemUrlState(imagemUrl);
	}, [imagemUrl]);

	function stopVideo() {
		onHide();
		streamWebCam?.getVideoTracks()[0].stop();
	}

	function capturarImagem() {
		setHasPicture(true);
		let canvas = document.querySelector('#capture');
		canvas.height = video.videoHeight;
		canvas.width = video.videoWidth;

		let context = canvas.getContext('2d');
		context.drawImage(video, 0, 0);
		convertCanvasToImage();
	}

	function excluirImagem() {
		setFieldValue('imagemFile', null);
		setFieldValue('imagemUrl', null);
		setHasPicture(false);
		document.querySelector('#capture').src = '';
	}

	function validaTamanhoArquivo() {
		let file = document.querySelector('#input').files[0];

		if (file.size > 5000000) {
			alert('Problema ao adicionar a imagem', 'São permitidas imagens com tamanho máximo de 5MB e do tipo PNG');
			setFieldValue('imagemFile', null);
			setFieldValue('imagemUrl', null);
			setHasPicture(false);
			return;
		}
	}

	function uploadImagem(event) {
		if (validaExtensao(event)) {
			setHasPicture(true);
			setFieldValue('imagemFile', event.target.files[0]);
			setFieldValue('imagemUrl', URL.createObjectURL(event.target.files[0]));
			setImagemUrlState(URL.createObjectURL(event.target.files[0]));
			validaTamanhoArquivo();
		}
	}

	function convertCanvasToImage() {
		const canvas = document.querySelector('#capture');
		const image = canvas.toDataURL('image/png');
		setFieldValue('imagemFile', dataURLtoFile(image, 'imagem.png'));
		setFieldValue('imagemUrl', image);
		setImagemUrlState(image);
	}

	function dataURLtoFile(dataUrl, fileName) {
		let arr = dataUrl?.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], fileName, { type: mime });
	}

	function validaExtensao(input) {
		let extPermitidas = ['jpg', 'png'];
		let extArquivo = input.target.value.split('.').pop();

		if (
			typeof extPermitidas.find(function (ext) {
				return extArquivo == ext;
			}) == 'undefined'
		) {
			alert('Atenção!', 'São permitidos somente arquivos do tipo imagem!');
			return false;
		} else {
			return true;
		}
	}

	return (
		<>
			<Modal
				header={header}
				visible={visible}
				onHide={() => {
					stopVideo();
				}}
				styleModal={styleModal}
				closeOnEsc={false}
				container={appendTo}
			>
				<div style={styleContent}>
					<div style={styleContainerButtons}>
						<div style={styleButtonsNew}>
							<Button
								style={{ ...styleButton, ...styleButtonCapturar }}
								label={'Capturar'}
								onClick={capturarImagem}
								disabled={!possuiPermissoesWebCam}
								icon="fa fa-camera"
							/>
							<Dropzone multiple={false} accept="image/*" maxSize={5000000}>
								{({ getRootProps, getInputProps }) => (
									<div {...getRootProps()}>
										<input
											id="input"
											{...getInputProps()}
											onChange={uploadImagem}
											type="file"
											accept="image/*"
										/>
										<Button
											label="Selecionar"
											style={{ ...styleButton, ...styleButtonUpload }}
											icon="fa fa-upload"
										/>
									</div>
								)}
							</Dropzone>
						</div>
						<div style={styleButtonsConfirm}>
							<Button
								style={{ ...styleButton, ...styleButtonExcluir }}
								label={'Excluir'}
								onClick={excluirImagem}
								disabled={!hasPicture}
								icon="fa fa-trash-o"
							/>
							<Button
								style={{ ...styleButton, ...styleButtonConfirmar }}
								label={'Confirmar'}
								onClick={stopVideo}
								disabled={!hasPicture}
								icon="fa fa-check"
							/>
						</div>
					</div>
					<div style={styleContentImage}>
						<WebCamVideo
							id="video"
							hasPicture={hasPicture}
							isTablet={isTablet}
							isMobile={isMobile}
							possuiPermissoesWebCam={possuiPermissoesWebCam}
						/>
						<WebCamCapture
							{...props}
							id="capture"
							hasPicture={hasPicture}
							imagemUrl={imagemUrlState}
							isTablet={isTablet}
							isMobile={isMobile}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
}

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(WebCam);
