import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar } from 'components';
import { useFormikContext } from 'formik';
import {
	exibirConfirmacaoMesmoNumeroDocumento,
	jaExisteContaPagarComMesmoNumeroDocumentoOrigem,
} from 'views/financas/ContasPagar/components/ModalContaPagar/Util/util';
import { asyncCreateReparcelamentoContasPagar, asyncCreateReparcelamentoContasReceber } from '../../Requests';
import { converterNovoTituloParaApi } from '../../Utils/converter';

function ActionButtons({ onHide, documentosSelecionados, origem }) {
	const { values, dirty, resetForm, initialValues, validateForm, handleSubmit, isValid } = useFormikContext();

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			onHide(true);
		}
	}

	async function salvar(novoOnSuccess) {
		const currentErrors = await validateForm();

		if (Object.keys(currentErrors).length === 0) {
			handleSubmit();
			if (isValid) {
				if (values) {
					if (
						values.numeroDocumento &&
						values.numeroDocumento !== initialValues.numeroDocumento &&
						(await jaExisteContaPagarComMesmoNumeroDocumentoOrigem(values.id, values.numeroDocumento))
					) {
						exibirConfirmacaoMesmoNumeroDocumento(() => {
							salvarOuAtualizar(values, novoOnSuccess);
						});
					} else {
						salvarOuAtualizar(values, novoOnSuccess);
					}
				}
			}
		}
	}

	async function salvarOuAtualizar() {
		const { valorJuros, valorMulta, valorDesconto } = values;
		const idsTitulosAntigos = documentosSelecionados.map((doc) => doc.id);

		if (origem === 'CONTAS_RECEBER') {
			await asyncCreateReparcelamentoContasReceber(
				converterNovoTituloParaApi(values),
				idsTitulosAntigos,
				valorJuros,
				valorMulta,
				valorDesconto,
				() => {
					onHide(true);
				}
			);
		} else if (origem === 'CONTAS_PAGAR') {
			await asyncCreateReparcelamentoContasPagar(
				converterNovoTituloParaApi(values),
				idsTitulosAntigos,
				valorJuros,
				valorMulta,
				valorDesconto,
				() => {
					onHide(true);
				}
			);
		}
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
			/>
			<ButtonSalvar onClick={() => salvar()} />
		</>
	);
}

export default ActionButtons;
