import { Modal } from '../../../../../components';
import FieldsContador from './components/FieldsContador';

export function ModalContador(props) {
	const { visible, onHide } = props;

	return (
		<Modal header="Contador" visible={visible} onHide={onHide}>
			<FieldsContador onHide={onHide} />
		</Modal>
	);
}
