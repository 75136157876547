import { manterApenasNumeros } from '../../../../../../../../../Common';
import { idPaisBrasil } from '../';

export function converterPessoaEnderecoParaApi(values) {
  const enderecoBrasileiro = values.pais === idPaisBrasil;

  return {
    id: values.id || '',
    logradouro: values.logradouro,
    bairro: values.bairro,
    numero: values.numero,
    cep: manterApenasNumeros(values.cep),
    complemento: values.complemento,
    municipio: converterMunicipioParaApi(values.municipio),
    pais: converterPaisParaApi(values.pais),
    favorito: values.favorito,
    idTemporario: values.idTemporario,
    municipioExterior: enderecoBrasileiro ? null : values.municipioExterior,
    estadoExterior: enderecoBrasileiro ? null : values.estadoExterior,
  };
}

export function converterPessoaEnderecoParaFormulario(values) {
  return {
    id: values.id,
    logradouro: values.logradouro,
    bairro: values.bairro,
    numero: values.numero,
    cep: values.cep,
    complemento: values.complemento,
    municipio: converterMunicipioParaFormulario(values.municipio),
    pais: converterPaisParaFormulario(values.pais),
    favorito: values.favorito,
    idTemporario: values.idTemporario,
    municipioExterior: values.municipioExterior,
    estadoExterior: values.estadoExterior,
  };
}

function converterMunicipioParaApi(municipio) {
  if (municipio) {
    return {
      id: municipio.value,
      nome: municipio.label.split(' - ')[0],
      estadoSigla: municipio.label.split(' - ')[1],
      ufId: municipio?.registro?.estado?.id ?? null
    };
  }
  return null;
}

function converterPaisParaApi(pais) {
  if (pais) {
    return {
      id: pais.value,
      nome: pais.label,
    };
  }
  return null;
}

function converterMunicipioParaFormulario(municipio) {
  if (municipio) {
    return {
      label: municipio.nome + ' - ' + municipio.estadoSigla,
      value: municipio.id,
    };
  }
  return null;
}

function converterPaisParaFormulario(pais) {
  if (pais) {
    return {
      label: pais.nome,
      value: pais.id,
    };
  }
  return null;
}
