import { useState } from 'react';
import { Tooltip } from 'primereact/tooltip';
import {
	buscarPercentualAdicionalCondicaoPagamento,
	buscarPercentualAdicionalQuantidadeProduto,
} from 'components/body/Vendas/Util/functions';
import { formatarMonetarioDecimais } from 'Common';

const styleDiv = { display: 'flex', justifyContent: 'space-between' };
const stylePrimeiraSpan = { marginRight: '16px' };

function TooltipValorUnitario({
	indexProduto,
	produto,
	disabled,
	tabelaPreco,
	pagamentos,
	utilizaTabelaPreco,
	utilizaTabelaPrecoDescontoQuantidade,
	utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao,
}) {
	const [percentualPagamento, setPercentualPagamento] = useState({ percentual: 0, tipo: 'DESCONTO' });
	const [percentualDescontoQuantidade, setPercentualDescontoQuantidade] = useState({ percentual: 0, tipo: 'DESCONTO' });

	const { valorOriginal } = produto;

	const valorPagamento = valorOriginal * (percentualPagamento.percentual / 100);
	const valorQuantidade = valorOriginal * (percentualDescontoQuantidade.percentual / 100);
	const valorModificado = getValorModificado();

	function getValorModificado() {
		let valor = valorOriginal;

		valor -= valorQuantidade;

		if (percentualPagamento.tipo === 'DESCONTO') {
			valor -= valorPagamento;
		} else {
			valor += valorPagamento;
		}

		return valor;
	}

	function getFormacaoDePreco() {
		if (utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao) {
			setPercentualPagamento(
				buscarPercentualAdicionalCondicaoPagamento(pagamentos, tabelaPreco?.registro?.alteracoesPorCondicao)
			);
		}

		if (utilizaTabelaPrecoDescontoQuantidade) {
			setPercentualDescontoQuantidade(
				buscarPercentualAdicionalQuantidadeProduto(produto.quantidade, tabelaPreco?.registro?.descontosPorQuantidade)
			);
		}
	}

	return (
		<Tooltip
			target={`.id-valor-unitario-produto-listagem-${indexProduto}`}
			position="bottom"
			showDelay={500}
			onShow={() => getFormacaoDePreco()}
			disabled={disabled || !produto.produto || !utilizaTabelaPreco}
		>
			<div>
				<div style={styleDiv}>
					<span style={stylePrimeiraSpan}>{`Valor original: `}</span>
					<span>{formatarMonetarioDecimais(valorOriginal, 2)}</span>
				</div>

				{utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao ? (
					<div style={styleDiv}>
						<span style={stylePrimeiraSpan}>{`${
							percentualPagamento.tipo === 'DESCONTO' ? 'Desconto' : 'Acréscimo'
						} por pagamento: `}</span>
						<span>{formatarMonetarioDecimais(valorPagamento, 2)}</span>
					</div>
				) : null}

				{utilizaTabelaPrecoDescontoQuantidade ? (
					<div style={styleDiv}>
						<span style={stylePrimeiraSpan}>{`Desconto por quantidade: `}</span>
						<span>{formatarMonetarioDecimais(valorQuantidade, 2)}</span>
					</div>
				) : null}

				<div style={{ ...styleDiv, fontWeight: 'bold' }}>
					<span>{`Valor Final: `}</span>
					<span>{formatarMonetarioDecimais(produto.valor, 2)}</span>
				</div>

				{utilizaTabelaPreco && produto.valor !== valorModificado ? (
					<div style={{ ...styleDiv, fontSize: '12px' }}>
						<span>* Valor Final alterado manualmente</span>
					</div>
				) : null}
			</div>
		</Tooltip>
	);
}

export { TooltipValorUnitario };
