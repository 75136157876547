import { services, del, exibirToast, get, post } from 'Common';

export async function asyncGetmodelosCodigoServico(urlParametros, onSuccess, onError) {
  await get(
    `${services.GESTOR}/v1/tributacoes/codigo_servico/modelo_tributacao${urlParametros}`,
    null,
    onSuccess,
    onError
  );
}

export async function asyncPostModeloCodigoServico(values, onSuccess, onError) {
  await post(
    `${services.GESTOR}/v1/tributacoes/codigo_servico/modelo_tributacao`,
    values,
    null,
    exibirToast(onSuccess, 'Modelo salvo com sucesso!'),
    onError
  );
}

export async function asyncDeleteModeloCodigoServico(idModelo, onSuccess, onError) {
  await del(
    `${services.GESTOR}/v1/tributacoes/codigo_servico/modelo_tributacao${idModelo}`,
    null,
    exibirToast(onSuccess, 'Modelo excluído com sucesso!'),
    onError
  );
}
