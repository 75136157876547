import { Field, useFormikContext, withFormik } from 'formik';

import { keyFilterConsultaRsql, mensagensDeValidacao, parseFloatNumber } from 'Common';
import {
	ButtonCancelar,
	ButtonSalvar,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputDouble,
	InputField,
	InputMask,
	Modal,
	SingleSelectEstado,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
} from 'components';

import { CARROCERIA_OPTIONS } from 'views/cadastros/transporte/Veiculos/Util/constantes';

import { converterReboqueParaModal } from './Util/converter';

function ModalReboquesImpl({ visible, onClose, informacoesPermissoes, onSave }) {
	const { values, dirty, handleReset, setFieldValue, isValid } = useFormikContext();
	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;

	function cancelar() {
		dirty ? handleReset() : onClose();
	}

	function confirmar() {
		if (isValid) {
			onSave(values);
			onClose();
		}
	}

	return (
		<Modal visible={visible} onHide={onClose} header="Reboques">
			<Form>
				<FormActions>
					<ButtonCancelar estadoBotao={estadoBotaoCancelar} onClick={() => cancelar()} />
					<ButtonSalvar
						onClick={confirmar}
						estadoBotao={estadosBotaoSalvar.CONFIRMAR}
						hidden={!informacoesPermissoes?.podeEditar}
						disabled={!dirty}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							id="input-placa"
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputMask}
							mask="aaa-0*00"
							obrigatorio
							label="Placa"
							name="placa"
							value={values.placa?.toUpperCase()}
							onChange={(e) => setFieldValue('placa', e.target.value?.toUpperCase())}
							size={8}
							removerMascara={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputField}
							label="Renavam"
							obrigatorio
							keyfilter={keyFilterConsultaRsql}
							name="renavam"
							size={11}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={SingleSelectEstado}
							label="Estado"
							name="estado"
							onChange={(event) => setFieldValue('estado', event)}
							value={values.estado}
							keyfilter={keyFilterConsultaRsql}
							isClearable={false}
							obrigatorio
							placeholder="Selecione"
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputDouble}
							label="Tara (KG)"
							name="tara"
							obrigatorio
							value={values.tara}
							onChange={(e) => setFieldValue('tara', parseFloatNumber(e.target.value))}
							placeholder="0"
							decimalScale={0}
							size={6}
							allowNegative={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputDouble}
							label="Capacidade (KG)"
							name="capacidade"
							obrigatorio
							value={values.capacidade}
							onChange={(e) => setFieldValue('capacidade', parseFloatNumber(e.target.value))}
							placeholder="0"
							decimalScale={0}
							size={6}
							allowNegative={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							label="Carroceria"
							onChange={(event) => setFieldValue('carroceria', event.value)}
							options={CARROCERIA_OPTIONS}
							keyfilter={keyFilterConsultaRsql}
							name="carroceria"
							showClear={false}
							obrigatorio
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalReboques = withFormik({
	enableReinitialize: true,
	validateOnChange: false,

	mapPropsToValues({ initialValue }) {
		return converterReboqueParaModal(initialValue);
	},

	validate: (values) => {
		const errors = {};

		if (!values.placa) {
			errors.placa = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.renavam) {
			errors.renavam = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.estado) {
			errors.estado = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.tara) {
			errors.tara = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.capacidade) {
			errors.capacidade = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.carroceria) {
			errors.carroceria = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalReboquesImpl);

export default ModalReboques;
