import { formatISO } from 'date-fns';

export const modulosDropdown = [
	{ label: 'Administração', value: 'ADMINISTRACAO', isFixed: true },
	{ label: 'Documentos', value: 'DOCUMENTOS' },
	{ label: 'Estoque', value: 'ESTOQUE' },
	{ label: 'Finanças', value: 'FINANCAS' },
	{ label: 'Vendas', value: 'VENDAS' },
	{ label: 'Serviços', value: 'SERVICOS' },
	{ label: 'Compras', value: 'COMPRAS' },
	{ label: 'Controladoria', value: 'CONTROLADORIA' },
	{ label: 'Transportes', value: 'TRANSPORTE' },
];

export const tipoPessoaDropdown = [
	{ label: 'Física', value: 'FISICA' },
	{ label: 'Jurídica', value: 'JURIDICA' },
];

export const meioPagamento = [
	{ label: 'Sem pagamento', value: 'SEM_PAGAMENTO' },
	{ label: 'Sistema de cobrança', value: 'SISTEMA_COBRANCA' },
	{ label: 'Boleto', value: 'BOLETO_BANCARIO', isDisabled: false },
];

export const autoridadeDropdown = [
	{ label: 'Microsys', value: 'MICROSYS' },
	{ label: 'Contabilidade', value: 'CONTABILIDADE' },
	{ label: 'Cliente', value: 'CLIENTE' },
];

export const INITIAL_VALUES = {
	id: null,
	nome: null,
	tipo: 'JURIDICA',
	cpf: null,
	bloqueada: false,
	cnpj: null,
	telefone: null,
	email: null,
	logradouro: null,
	numero: null,
	bairro: null,
	complemento: null,
	cep: null,
	municipio: null,
	municipioExterior: null,
	pais: null,
	modulos: [],
	plano: null,
	meioPagamento: null,
	agenteAutorizado: null,
	aplicacao: 'MSYS_GESTOR',
	autoridade: 'CLIENTE',
	criadoEm: formatISO(new Date()),
};
