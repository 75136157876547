import { BsCameraVideoOff } from 'react-icons/bs';

export default function WebCamVideo(props) {
	const { id, hasPicture, isTablet, isMobile, possuiPermissoesWebCam } = props;

	const styleContainer = {
		display: hasPicture && (isTablet || isMobile) ? 'none' : 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	};

	const styleTitle = {
		fontSize: '16px',
		width: '100%',
	};

	const styleContent = {
		display: possuiPermissoesWebCam ? 'flex' : 'none',
		maxWidth: isMobile ? 'calc(100% - 5px)' : '450px',
		maxHeight: '600px',
		borderRadius: '3px',
	};

	const styleContentIcon = {
		display: !possuiPermissoesWebCam ? 'flex' : 'none',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: isMobile ? 'calc(100% - 5px)' : '450px',
		height: '337px',
		border: '1px solid #BBBBBB',
		borderRadius: '3px',
	};

	const styleIcon = {
		maxWidth: isMobile ? 'calc(100% - 5px)' : '450px',
		maxHeight: '600px',
		borderRadius: '3px',
	};

	return (
		<div style={styleContainer}>
			<h1 style={styleTitle}>WebCam</h1>
			<video id={id} style={styleContent}></video>
			<div style={styleContentIcon}>
				<BsCameraVideoOff size={60} style={styleIcon} />
				<p style={{ textAlign: 'center' }}>
					Sem permissão para acessar a WebCam
					<br />
					Verifique as configurações do seu navegador
				</p>
			</div>
		</div>
	);
}
