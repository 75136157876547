import { modulos, usuarioPossuiModulo } from 'Common';
import { TabView } from 'components';
import { TabPanel } from 'primereact/tabview';
import { TabCadastros } from './components/TabCadastros';
import { TabDocumentos } from './components/TabDocumentos';
import { TabEstoque } from './components/TabEstoque';
import { TabFinanceiro } from './components/TabFinanceiro';
import { TabGeral } from './components/TabGeral';
import { TabServicos } from './components/TabServicos';
import { TabVendas } from './components/TabVendas';
import { useContextConfiguracoesGerais } from '../../Context';

function TabsParametrizacao() {
	const { tabsParametrizacao, setTabsParametrizacao } = useContextConfiguracoesGerais();

	return (
		<TabView
			className="tab-view"
			activeIndex={tabsParametrizacao}
			onTabChange={({ index }) => setTabsParametrizacao(index)}
			renderActiveOnly={false}
		>
			<TabPanel header="Geral">
				<TabGeral />
			</TabPanel>
			<TabPanel header="Cadastros">
				<TabCadastros />
			</TabPanel>
			<TabPanel header="Estoque" disabled={!usuarioPossuiModulo(modulos.ESTOQUE)}>
				<TabEstoque />
			</TabPanel>
			<TabPanel header="Financeiro" disabled={!usuarioPossuiModulo(modulos.FINANCAS)}>
				<TabFinanceiro />
			</TabPanel>
			<TabPanel header="Vendas" disabled={!usuarioPossuiModulo(modulos.VENDAS)}>
				<TabVendas />
			</TabPanel>
			<TabPanel header="Serviços" disabled={!usuarioPossuiModulo(modulos.SERVICOS)}>
				<TabServicos />
			</TabPanel>
			<TabPanel header="Documentos" disabled={!usuarioPossuiModulo(modulos.DOCUMENTOS)}>
				<TabDocumentos />
			</TabPanel>
		</TabView>
	);
}

export { TabsParametrizacao };
