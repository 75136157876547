import { useFormikContext } from 'formik';

import { services } from 'Common';
import { Pagamentos } from 'components';

import { FINANCEIRO_TIPO } from 'components/Pagamentos/Util/constantes';
import { useContextNFSe } from 'views/fiscal/servicos/NFSe/Context';
import { ClassNameTabViewColor, Colors, Situacao } from 'views/fiscal/servicos/NFSe/Util/constantes';

function TabPagamentos({ informacoesPermissoes }) {
	const { values, errors, setFieldValue } = useFormikContext();
	const { favoritos, disableFields, podeEditar, setRecalcularTodosOsServicosComTabelaPreco } = useContextNFSe();

	function onChangePagamentos(pagamentos) {
		setFieldValue('pagamentos', pagamentos);
	}

	function onChangePagamento(index, value) {
		setFieldValue(`pagamentos[${index}]`, value);
	}

	function onChangePagamentoField(index, field, value) {
		setFieldValue(`pagamentos[${index}].[${field}]`, value);
	}

	return (
		<Pagamentos
			idOrigem={values.id}
			pagamentos={values.pagamentos}
			valorTotal={values.totalizadores?.valorLiquido}
			dataBaseParcela={values.dataEmissao}
			urls={{
				urlBase: `${services.GESTOR}/v1/nfses`,
				condicaoPagamento: `${services.GESTOR}/v1/nfses/relacoes/condicoes_pagamento`,
				formaPagamento: `${services.GESTOR}/v1/nfses/relacoes/formas_pagamento`,
				conta: `${services.GESTOR}/v1/nfses/relacoes/contas`,
				categoria: `${services.GESTOR}/v1/nfses/relacoes/categorias/receitas`,
			}}
			informacoesPermissoes={{ ...informacoesPermissoes, podeEditar }}
			disabledFields={disableFields}
			favoritos={favoritos}
			hideTabFinanceiro={values.situacao !== Situacao.TRANSMITIDO}
			onChangePagamentos={onChangePagamentos}
			onChangePagamento={onChangePagamento}
			onChangePagamentoField={onChangePagamentoField}
			setRecalcularTodosOsServicosComTabelaPreco={setRecalcularTodosOsServicosComTabelaPreco}
			errors={errors?.pagamentos}
			classNameTabView={`tab-view-no-border ${ClassNameTabViewColor[values.situacao]}`}
			color={Colors[values.situacao].strongColor}
			hiddenButtonAdd={values.situacao !== Situacao.PENDENTE}
			financeiroTipo={FINANCEIRO_TIPO.CONTA_RECEBER}
		/>
	);
}

export { TabPagamentos };
