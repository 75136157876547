import { useFormikContext } from 'formik';

import { If, ModalHistorico } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';

import { ModalCancelamento } from './components/ModalCancelamento';
import { ModalCondutorIndicado } from './components/ModalCondutorIndicado';
import { ModalDFe } from './components/ModalDFe';
import { ModalPagamento } from './components/ModalPagamento';
import { ModalEncerramento } from './components/ModalEncerramento';

import { MDFE_SITUACAO } from '../../Util/constantes';
import { ModalImportacaoNFe } from './components/ModalImportacaoNFe';

function EventosPosteriores({ fetchRegistro }) {
	const { values, resetForm } = useFormikContext();
	const {
		visibleModalHistorico,
		setVisibleModalHistorico,
		visibleModalCancelamento,
		setVisibleModalCancelamento,
		visibleModalCondutorIndicado,
		setVisibleModalCondutorIndicado,
		visibleModalIncluirDFe,
		setVisibleModalIncluirDFe,
		visibleModalIncluirPagamento,
		setVisibleModalIncluirPagamento,
		visibleModalEncerramento,
		setVisibleModalEncerramento,
		visibleModalImportacaoNFe,
		setVisibleModalImportacaoNFe,
	} = useContextMDFe();

	return (
		<>
			<If test={visibleModalHistorico}>
				<ModalHistorico
					header="Histórico do manifesto de documentos fiscal eletrônico"
					visible={visibleModalHistorico}
					onHide={() => setVisibleModalHistorico(false)}
					mapping="mdfes"
					id={values.id}
				/>
			</If>
			<If test={visibleModalCancelamento}>
				<ModalCancelamento
					visible={visibleModalCancelamento}
					onHide={() => setVisibleModalCancelamento(false)}
					resetFormPrincipal={resetForm}
					mdfe={values}
				/>
			</If>
			<If test={visibleModalCondutorIndicado}>
				<ModalCondutorIndicado
					visible={visibleModalCondutorIndicado}
					onHide={() => setVisibleModalCondutorIndicado(false)}
				/>
			</If>
			<If test={visibleModalIncluirDFe}>
				<ModalDFe visible={visibleModalIncluirDFe} onHide={() => setVisibleModalIncluirDFe(false)} />
			</If>
			<If test={visibleModalIncluirPagamento}>
				<ModalPagamento
					mdfe={values}
					values={values.pagamento}
					canTransmitir={values.situacao === MDFE_SITUACAO.TRANSMITIDO && !values.pagamento?.id}
					visible={visibleModalIncluirPagamento}
					onHide={() => setVisibleModalIncluirPagamento(false)}
					resetFormPrincipal={resetForm}
				/>
			</If>
			<If test={visibleModalEncerramento}>
				<ModalEncerramento
					mdfe={values}
					visible={visibleModalEncerramento}
					callback={fetchRegistro}
					onHide={() => {
						setVisibleModalEncerramento(false);
					}}
				/>
			</If>
			<If test={visibleModalImportacaoNFe}>
				<ModalImportacaoNFe
					mdfe={values}
					visible={visibleModalImportacaoNFe}
					onHide={() => {
						setVisibleModalImportacaoNFe(false);
					}}
				/>
			</If>
		</>
	);
}

export { EventosPosteriores };
