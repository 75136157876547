import { useState } from 'react';
import { Grid, If } from 'components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SelectButton } from 'primereact/selectbutton';
import { OPTIONS_SELECT, VENDAS_MODULOS } from './Util/constantes';
import { DashboardOrcamento } from './components/DashboardOrcamento';
import { DashboardPedidoVenda } from './components/DashboardPedidoVenda';
import { DashboardNFe } from './components/DashboardNFe';
import { DashboardNFCe } from './components/DashboardNFCe';
import { buscarDadosLoginLocalStorage, permissoes, recursos, usuarioPossuiPermissao } from 'Common';

const selectButtonStyle = {
	whiteSpace: 'nowrap',
	overflowY: 'auto',
	overflowX: 'auto',
	width: '100%',
};

function DashboardVendasImpl({ dataInicial, dataFinal, history, isMobile, isTablet }) {
	const [podeVisualizarOrcamentoPedido] = useState(
		usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR)
	);
	const [podeVisualizarNfe] = useState(usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.VISUALIZAR));
	const [podeVisualizarNfce] = useState(
		usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.VISUALIZAR)
	);
	const [optionsSelect] = useState(
		OPTIONS_SELECT.filter((e) => {
			switch (e.value) {
				case VENDAS_MODULOS.ORCAMENTO: {
					if (podeVisualizarOrcamentoPedido && buscarDadosLoginLocalStorage().organizacao?.aplicacao !== 'GESTOR_MEI') {
						return e;
					} else break;
				}
				case VENDAS_MODULOS.PEDIDO_VENDA: {
					if (podeVisualizarOrcamentoPedido) {
						return e;
					} else break;
				}
				case VENDAS_MODULOS.NFE: {
					if (podeVisualizarNfe) {
						return e;
					} else break;
				}
				case VENDAS_MODULOS.NFCE: {
					if (podeVisualizarNfce) {
						return e;
					} else break;
				}
			}
		})
	);
	const [currentOption, setCurrentOption] = useState(optionsSelect[0]?.value);

	function handleChangeOption(event) {
		if (event.value) {
			setCurrentOption(event.value);
		}
	}

	if (dataInicial && dataFinal) {
		return (
			<Grid style={{ margin: '0px', width: '99.8%' }}>
				<SelectButton
					style={selectButtonStyle}
					className="grid-select-filtro select-button"
					options={optionsSelect}
					value={currentOption}
					onChange={handleChangeOption}
				/>
				<If test={currentOption === VENDAS_MODULOS.ORCAMENTO}>
					<DashboardOrcamento dataInicial={dataInicial} dataFinal={dataFinal} history={history} isMobile={isMobile} />
				</If>
				<If test={currentOption === VENDAS_MODULOS.PEDIDO_VENDA}>
					<DashboardPedidoVenda
						dataInicial={dataInicial}
						dataFinal={dataFinal}
						history={history}
						isMobile={isMobile}
						isTablet={isTablet}
					/>
				</If>
				<If test={currentOption === VENDAS_MODULOS.NFE}>
					<DashboardNFe dataInicial={dataInicial} dataFinal={dataFinal} history={history} isMobile={isMobile} />
				</If>
				<If test={currentOption === VENDAS_MODULOS.NFCE}>
					<DashboardNFCe dataInicial={dataInicial} dataFinal={dataFinal} history={history} isMobile={isMobile} />
				</If>
			</Grid>
		);
	} else {
		return <span>Carregando informações...</span>;
	}
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export const DashboardVendas = connect(mapStateToProps)(withRouter(DashboardVendasImpl));
