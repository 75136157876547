import { createContext, useContext, useState } from 'react';
import { connect } from 'react-redux';

import { buscarDadosLoginLocalStorage, estadosCadastro, permissoes, recursos, usuarioPossuiPermissao } from 'Common';

const ContextVendedores = createContext();

function VendedoresProvider({ children, history, match, isMobile, isTablet }) {
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_VENDEDORES, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.CADASTROS_VENDEDORES, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_VENDEDORES, permissoes.EXCLUIR));
	const [usuarioPadrao, setUsuarioPadrao] = useState(null);

	const { filialConectada } = buscarDadosLoginLocalStorage();
	const utilizaTabelaPrecoPorRegiao = filialConectada?.parametrosVendas?.utilizaTabelaPrecoPorRegiao;

	const idVendedor = match?.params?.id;

	const informacoesPermissoes = {
		estadoCadastro: match?.params?.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir,
		podeEditar,
		podeExcluir,
	};
	const desabilitarCampos =
		(informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO && !informacoesPermissoes.podeEditar) ||
		(informacoesPermissoes.estadoCadastro === estadosCadastro.INCLUSAO &&
			(!informacoesPermissoes.podeInserir || !informacoesPermissoes.podeEditar));

	return (
		<ContextVendedores.Provider
			value={{
				idVendedor,
				history,
				match,
				isMobile,
				isTablet,
				podeInserir,
				podeEditar,
				podeExcluir,
				activeTabIndex,
				setActiveTabIndex,
				informacoesPermissoes,
				utilizaTabelaPrecoPorRegiao,
				desabilitarCampos,
				usuarioPadrao,
				setUsuarioPadrao,
			}}
		>
			{children || null}
		</ContextVendedores.Provider>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(VendedoresProvider);
export const useContextVendedores = () => useContext(ContextVendedores);
