import { useEffect, useRef, useState } from 'react';
import Grid from '../../../../../components/Grid';
import Col from '../../../../../components/Col';
import { connect } from 'react-redux';
import { usuarioPossuiPermissao } from '../../../../../Common/Autenticacao';
import { recursos, permissoes } from '../../../../../Common/Constantes/autorizacao';
import { Button } from 'primereact/button';
import { withRouter } from 'react-router-dom';
import CardsResultadoNoPeriodo from './components/CardsResultadoNoPeriodo';
import ListaProdutosMaisComprados from './components/ListaProdutosMaisComprados';
import ComprasPorSituacao from './components/ComprasPorSituacao';
import GraficoComprasUltimosDozeMeses from './components/GraficoComprasUltimosDozeMeses';
import GraficoComprasPorDia from './components/GraficoComprasPorDia';
import usePrevious from '../../../../../Common/Hooks/usePrevious';
import ListaFornecedoresMaisComprados from './components/ListaFornecedoresMaisComprados';

function DashboardCompras(props) {
	const { dataInicial, dataFinal, dataAtualizacaoMenuLateral, isMobile, history } = props;

	const [atualizarGraficoNovamente, setAtualizarGraficoNovamente] = useState(null);
	const [podeInserirCompras, setPodeInserirCompras] = useState(
		usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.INSERIR)
	);

	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	const styleCards = isMobile ? { padding: '0.5em 0em', margin: '8px', flex: 'auto' } : { padding: '0.5em' };

	const styleButtons = {
		margin: isMobile ? '3px 5px' : '3px 7px',
		width: isMobile ? 'calc(50% - 10px)' : '185px',
	};

	const getNewTimestamp = () => new Date().getTime();

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataAtualizacaoMenuLateral !== dataAtualizacaoMenuLateral) {
				setAtualizarGraficoNovamente(getNewTimestamp());
				setPodeInserirCompras(usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.INSERIR));
			}
		} else afterFirstRenderRef = true;
	}, []);

	if (dataInicial && dataFinal) {
		return (
			<Grid style={{ margin: '0px', width: '99.8%' }}>
				<Col style={{ padding: '0 0 0 2px' }}>
					<Button
						label="Nova compra"
						className="p-button-primary"
						onClick={() =>
							history.push({
								pathname: '/nota_entrada/cadastro',
							})
						}
						style={styleButtons}
						disabled={!podeInserirCompras}
						title={
							!podeInserirCompras ? 'Você não possui permissão para inserir uma nota de entrada' : null
						}
					/>
				</Col>
				<CardsResultadoNoPeriodo
					dataInicial={dataInicial}
					dataFinal={dataFinal}
					isMobile={isMobile}
					atualizarGraficoNovamente={atualizarGraficoNovamente}
					dataAtualizacaoMenuLateral={dataAtualizacaoMenuLateral}
				/>
				<Col xs="12" sm="6" md="4" lg="4" xl="4" style={styleCards}>
					<ListaProdutosMaisComprados
						dataInicial={dataInicial}
						dataFinal={dataFinal}
						atualizarGraficoNovamente={atualizarGraficoNovamente}
						dataAtualizacaoMenuLateral={dataAtualizacaoMenuLateral}
					/>
				</Col>
				<Col xs="12" sm="6" md="4" lg="4" xl="4" style={styleCards}>
					<ListaFornecedoresMaisComprados
						dataInicial={dataInicial}
						dataFinal={dataFinal}
						atualizarGraficoNovamente={atualizarGraficoNovamente}
						dataAtualizacaoMenuLateral={dataAtualizacaoMenuLateral}
					/>
				</Col>
				<Col xs="12" sm="6" md="4" lg="4" xl="4" style={styleCards}>
					<ComprasPorSituacao
						dataInicial={dataInicial}
						dataFinal={dataFinal}
						atualizarGraficoNovamente={atualizarGraficoNovamente}
						dataAtualizacaoMenuLateral={dataAtualizacaoMenuLateral}
					/>
				</Col>
				<Col style={styleCards}>
					<GraficoComprasPorDia
						dataInicial={dataInicial}
						dataFinal={dataFinal}
						atualizarGraficoNovamente={atualizarGraficoNovamente}
						dataAtualizacaoMenuLateral={dataAtualizacaoMenuLateral}
						isMobile={isMobile}
					/>
				</Col>
				<Col style={styleCards}>
					<GraficoComprasUltimosDozeMeses
						dataInicial={dataInicial}
						dataFinal={dataFinal}
						atualizarGraficoNovamente={atualizarGraficoNovamente}
						dataAtualizacaoMenuLateral={dataAtualizacaoMenuLateral}
						isMobile={isMobile}
					/>
				</Col>
			</Grid>
		);
	} else {
		return <span>Carregando informações...</span>;
	}
}

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
	dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
});
export default connect(mapStateToProps)(withRouter(DashboardCompras));
