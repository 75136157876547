import { formatISO } from 'date-fns';

import { get, del, put, exibirToast, post } from '../../../../../Common/Requisicoes';
import { services } from '../../../../../Common/Constantes/api';

export async function buscarProximoNumero(vendaOuOrcamento, onSuccess, tipo) {
	return await get(`${services.GESTOR}/v1/${vendaOuOrcamento}/proximo_numero?tipo=${tipo}`, null, ({ data }) => {
		if (data) {
			onSuccess(data.numero);
		} else {
			onSuccess(null);
		}
	});
}

export async function asyncBuscarResponsavel(vendaOuOrcamento, onSuccess) {
	let dados = {};

	const promises = [
		asyncBuscarSetoresUsuario(vendaOuOrcamento, ({ data }) => {
			dados.setores = data.content;
			dados.setorFavorito = data.content.find((setor) => setor.favorito);
		}),
		asyncBuscarVendedores(vendaOuOrcamento, ({ data }) => {
			if (data?.content?.length > 0) {
				let indexFavorito =
					Math.max(
						data.content?.findIndex((vendedor) => vendedor.favorito === true),
						0
					) ?? 0;
				dados.vendedor = {
					label: `${data.content[indexFavorito]?.codigo} - ${data.content[indexFavorito]?.nome}`,
					value: data.content[indexFavorito]?.id,
					registro: data.content[indexFavorito],
				};
			}
		}),
	];

	await Promise.all(promises).then(() => {});
	if (typeof onSuccess === 'function') {
		onSuccess(dados);
	} else {
		return dados;
	}
}

export async function asyncBuscarVendedores(vendaOuOrcamento, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/${vendaOuOrcamento}/relacoes/vendedores?filtro=&page=0&size=500&sort=codigo,asc`,
		null,
		onSuccess,
		onError
	);
}

async function asyncBuscarSetoresUsuario(vendaOuOrcamento, onSuccess) {
	let result = {};

	await get(`${services.GESTOR}/v1/${vendaOuOrcamento}/relacoes/setores`, null, onSuccess, null, false);

	return result;
}

export async function asyncDeleteVenda(
	vendaOuOrcamento,
	idVenda,
	messageOnSuccess = 'Registro excluído com sucesso',
	onSuccess,
	onError
) {
	await del(
		`${services.GESTOR}/v1/${vendaOuOrcamento}/${idVenda}`,
		null,
		exibirToast(onSuccess, messageOnSuccess),
		onError
	);
}

export async function asyncGetVenda(vendaOuOrcamento, idVenda, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/${vendaOuOrcamento}/${idVenda}`, null, onSuccess, onError);
}

export async function asyncCreateVenda(
	vendaOuOrcamento,
	data,
	messageOnSuccess = 'Registro criado com sucesso',
	onSuccess,
	onError
) {
	await post(
		`${services.GESTOR}/v1/${vendaOuOrcamento}`,
		data,
		null,
		exibirToast(onSuccess, messageOnSuccess),
		onError
	);
}

export async function asyncUpdateVenda(
	vendaOuOrcamento,
	venda,
	messageOnSuccess = 'Registro atualizado com sucesso',
	onSuccess,
	onError,
	validarOrcamentoOuVenda = true
) {
	await put(
		`${services.GESTOR}/v1/${vendaOuOrcamento}/${venda.id}`,
		venda,
		null,
		validarOrcamentoOuVenda ? exibirToast(onSuccess, messageOnSuccess) : null,
		onError
	);
}

export async function asyncEnviarVendaPorEmail(vendaOuOrcamento, vendaId, body, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/${vendaOuOrcamento}/${vendaId}/enviar_por_email`,
		body,
		null,
		exibirToast(onSuccess, 'Email(s) enviado(s)'),
		onError
	);
}

export async function asyncBuscarEmailsPessoa(vendaOuOrcamento, pessoaId, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/${vendaOuOrcamento}/relacoes/clientes/${pessoaId}/emails`, null, onSuccess, onError);
}

export async function asyncGetVendasOrcamentos(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function asyncGetTotalizadores(vendaOuOrcamento, interval, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/${vendaOuOrcamento}/totais?dataInicial=${formatISO(interval.dataInicial, {
			representation: 'date',
		})}&dataFinal=${formatISO(interval.dataFinal, { representation: 'date' })}`,
		null,
		onSuccess,
		onError
	);
}

export async function asyncGerarNfe(vendaId, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/pedidos_venda/${vendaId}/gerar_nfe`,
		null,
		null,
		exibirToast(onSuccess, 'Nota fiscal gerada com sucesso'),
		onError
	);
}

export async function asyncFinalizarPedido(vendaId, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/pedidos_venda/${vendaId}/finalizar`,
		null,
		null,
		exibirToast(onSuccess, 'Pedido de venda finalizado com sucesso'),
		onError
	);
}

export async function asyncEstornarPedido(vendaId, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/pedidos_venda/${vendaId}/estornar`,
		null,
		null,
		exibirToast(onSuccess, 'Pedido de venda atualizado com sucesso'),
		onError,
		true,
		true,
		'Operação não permitida'
	);
}

export async function asyncCancelarPedidoOrcamento(vendaOuOrcamento, vendaId, motivo, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/${vendaOuOrcamento}/${vendaId}/cancelar?motivo=${motivo}`,
		null,
		null,
		exibirToast(onSuccess, 'Registro cancelado com sucesso'),
		onError,
		true,
		true,
		'Operação não permitida'
	);
}

export async function asyncGerarVendaDeOrcamento(vendaOuOrcamento, vendaId, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/${vendaOuOrcamento}/${vendaId}/gerar_pedido`,
		null,
		null,
		exibirToast(onSuccess, 'Venda gerada com sucesso'),
		onError
	);
}

export async function asyncGetContasReceber(pessoaId, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/contas_receber/financeiro_por_pessoa/${pessoaId}`, null, onSuccess, onError);
}

export async function asyncGetContasReceberPorId(contaReceberId, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/contas_receber/${contaReceberId}`, null, onSuccess, onError);
}

export async function asyncGetContaFavorita(vendaOuOrcamento, onSucess, onError) {
	await get(
		`${services.GESTOR}/v1/${vendaOuOrcamento}/relacoes/contas?query=favoritaReceita==true`,
		null,
		onSucess,
		onError
	);
}

export async function asyncGetCategoriaFavorita(vendaOuOrcamento, onSucess, onError) {
	await get(
		`${services.GESTOR}/v1/${vendaOuOrcamento}/relacoes/categorias/receitas?query=favoritaReceita==true`,
		null,
		onSucess,
		onError
	);
}

export async function asyncGetCondicaoPagamentoFavorita(vendaOuOrcamento, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/${vendaOuOrcamento}/relacoes/condicoes_pagamento?query=(favorita==true);(movimentacao==SAIDA)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetFormaPagamentoDinheiro(vendaOuOrcamento, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/${vendaOuOrcamento}/relacoes/formas_pagamento?query=(formaPagamentoFiscal==DINHEIRO)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncBuscarTabelaPreco(vendaOuOrcamento, dadosRequest, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/${vendaOuOrcamento}/relacoes/buscar_tabela_a_utilizar?municipioId=${
			dadosRequest?.municipioId ?? ''
		}&clienteId=${dadosRequest?.clienteId ?? ''}&vendedorId=${dadosRequest?.vendedorId ?? ''}`,
		null,
		onSuccess,
		onError
	);
}

export async function asyncGetOperacoesFiscaisParaVenda({ tipoOperacao, tipoCliente }, onSuccess, onError) {
	const query = `?query=(descricao=contains="**",codigo=contains="**");situacao=="ATIVO";indicacaoMovimento==${tipoOperacao};vendaConsumidor==${tipoCliente}&page=0&size=50`;
	await get(`${services.GESTOR}/v1/tributacoes/operacoes_fiscais/resumo${query}`, null, onSuccess, onError);

	return;
}

export async function asyncBaixarRelatorioPedidosPorPeriodo(
	dataInicial,
	dataFinal,
	status,
	clienteId,
	setores,
	custo_utilizado,
	onSuccess,
	onError
) {
	return await get(
		`${
			services.GESTOR
		}/v1/pedidos_venda/imprimir/pedidos_por_periodo?dataInicial=${dataInicial}&dataFinal=${dataFinal}&status=${status}${
			clienteId ? '&clienteId=' + clienteId : ''
		}&setores=${setores}&custo_utilizado=${custo_utilizado}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function asyncBaixarRelatorioProdutosPorPeriodo(
	dataInicial,
	dataFinal,
	filiais,
	marcaId,
	status,
	clienteId,
	setores,
	custo_utilizado,
	adicionar_observacao,
	ocultar_valor_venda,
	considerar_devolucoes,
	onSuccess,
	onError
) {
	return await get(
		`${
			services.GESTOR
		}/v1/pedidos_venda/imprimir/produtos_pedidos_por_periodo?dataInicial=${dataInicial}&dataFinal=${dataFinal}&filiais=${filiais}${
			marcaId ? '&marcaId=' + marcaId : ''
		}&status=${status}${
			clienteId ? '&clienteId=' + clienteId : ''
		}&setores=${setores}&custo_utilizado=${custo_utilizado}&adicionar_observacao=${adicionar_observacao}&ocultar_valor_venda=${ocultar_valor_venda}&considerar_devolucoes=${considerar_devolucoes}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function asyncImprimirVenda(urlVendaOuOrcamento, idOrcamento, idOrganizacao, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/${urlVendaOuOrcamento}/${idOrcamento}/organizacoes/${idOrganizacao}/imprimir`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function getVendaItensDevolvidos(vendaId, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/pedidos_venda/${vendaId}/itens_devolvidos`, null, onSuccess, onError);
}

export async function devolverProdutosDoPedidoDeVenda(vendaId, produtosDevolvidos, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/pedidos_venda/${vendaId}/devolver_produtos`,
		produtosDevolvidos,
		null,
		onSuccess,
		onError,
		null,
		false
	);
}

export async function estornarDevolucaoDoPedidoDeVenda(vendaId, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/pedidos_venda/${vendaId}/estornar_devolucao`, null, null, onSuccess, onError);
}
