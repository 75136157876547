import { modulos, usuarioPossuiModulo, usuarioPossuiModulos } from 'Common';

export const CADASTRO_URL = '/produtos/cadastro';
export const PESQUISA_URL = '/produtos';

export const INITIAL_VALUES = {
	//CABEÇALHO
	id: '',
	codigo: '-',
	nome: '',
	sku: '',
	situacao: 'ATIVO',
	tipo: 'PRODUTO',
	preco: 0,
	precoAtacado: 0,
	codigoBarras: '',
	unidadeMedida: {
		label: 'UN - Unidade',
		value: '7415ab61-b29c-45c6-9631-3892095eb037',
	},
	unidadeMedidaEntrada: {
		label: 'UN - Unidade',
		value: '7415ab61-b29c-45c6-9631-3892095eb037',
	},
	grupo: 0,

	//ESTOQUE
	controlarEstoque: true,
	estoqueMinimo: 0,
	estoqueSaldo: 0,
	ultimoCusto: 0,

	//NOTA FISCAL
	origem: 'NACIONAL',
	ncm: 0,
	cest: 0,
	pesoBruto: 0,
	pesoLiquido: 0,

	//CONFIGURACOES
	utilizarDocumentosDigitais: usuarioPossuiModulos([modulos.DOCUMENTOS]),
	utilizaBalanca: false,

	//INFORMACÕES COMPLEMENTARES
	observacao: '',

	custo: 0,

	imagem: null,
	imagemFile: null,
	imagemUrl: null,

	possuiModulos: {
		VENDAS: usuarioPossuiModulo(modulos.VENDAS),
	},

	fatorConversao: 0,
	operacaoFatorConversao: 'MULTIPLICACAO',

	marca: null,

	//SERVICOS
	codigoServico: null,

	//CUSTOS
	filiais: [],
};

export const styleInativado = {
	fontStyle: 'italic',
	opacity: '0.8',
};
