import { useEffect, useState } from 'react';
import { TabPanel } from 'primereact/tabview';
import ModalCategoria from './components/ModalCategoria';
import TreeTabelaCategorias from './components/TreeTabelaCategorias';
import {
	asyncBuscarCategorias,
	asyncDeleteCategoria,
	asyncInativarCategoria,
	asyncAtivarCategoria,
	buscarCategoria,
} from './Requests';
import './Styles/tab-view.css';
import { connect } from 'react-redux';
import { usuarioPossuiPermissao, recursos, permissoes, services } from '../../../Common';
import { TabView, confirm, If, FormActions, Form, FormContent, ButtonNovo } from '../../../components';

function Categorias(props) {
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.VISUALIZAR)
	);
	const [podeEditar, setPodeEditar] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.EDITAR)
	);
	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.INSERIR)
	);
	const [podeExcluir, setPodeExcluir] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.EXCLUIR)
	);
	const [tabSelecionada, setTabSelecionada] = useState(0);
	const [categorias, setCategorias] = useState([]);
	const [exibirModalCategoria, setExibirModalCategoria] = useState(false);
	const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
	const [tituloTabReceita, setTituloTabReceita] = useState('Categorias de receitas');
	const [tituloTabDespesas, setTituloTabDespesas] = useState('Categorias de despesas');

	useEffect(() => {
		buscarCategorias();
		renderizarTituloTabView();
	}, []);

	function renderizarTituloTabView() {
		const larguraInicial = window.innerWidth;

		if (larguraInicial < 500) {
			setTituloTabDespesas('Despesas');
			setTituloTabReceita('Receitas');
		}

		window.addEventListener('resize', atualizarTituloTabView());
	}

	function atualizarTituloTabView() {
		if (window.innerWidth < 500 && tituloTabReceita.length > 20) {
			setTituloTabReceita('Receitas');
			setTituloTabDespesas('Despesas');
		} else if (window.innerWidth > 500 && tituloTabDespesas.length < 20) {
			setTituloTabReceita('Categorias de receitas');
			setTituloTabDespesas('Categorias de despesas');
		}
	}

	function pesquisar() {
		buscarCategorias();
	}

	function buscarCategorias() {
		asyncBuscarCategorias(`${services.GESTOR}/v1/categorias`, ({ data: categorias }) => {
			setCategorias(categorias);
		});
	}

	function excluirCategoria(categoria) {
		confirm('Confirmação', `Tem certeza que deseja excluir a categoria?`, () => {
			asyncDeleteCategoria(categoria.id, () => {
				setCategoriaSelecionada(null);
				pesquisar();
			});
		});
	}

	function inativarCategoria(categoria) {
		buscarCategoria(categoria.id, ({ data: categoria }) => {
			confirm('Confirmação', `Tem certeza que deseja inativar a categoria?`, () => {
				asyncInativarCategoria(categoria, () => {
					setCategoriaSelecionada(null);
					pesquisar();
				});
			});
		});
	}

	function ativarCategoria(categoria) {
		buscarCategoria(categoria.id, ({ data: categoria }) => {
			confirm('Confirmação', `Tem certeza que deseja ativar a categoria?`, () => {
				asyncAtivarCategoria(categoria, () => {
					setCategoriaSelecionada(null);
					pesquisar();
				});
			});
		});
	}

	function selecionarCategoria(categoria) {
		setCategoriaSelecionada(categoria);
	}

	function onHideModalCategoria() {
		setExibirModalCategoria(false);
		setCategoriaSelecionada(null);
		pesquisar();
	}

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeVisualizar: podeVisualizar,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	const tipoCategoria = tabSelecionada === 0 ? 'RECEITA' : 'DESPESA';

	const { isMobile } = props;

	return (
		<>
			<Form header="Categorias">
				<FormActions>
					<ButtonNovo
						label={`Nova categoria de ${tabSelecionada === 0 ? 'receita' : 'despesa'}`}
						title={`Inserir uma nova categoria de ${tabSelecionada === 0 ? 'receita' : 'despesa'}`}
						onClick={() => setExibirModalCategoria(true)}
						disabled={!podeInserir}
					/>
				</FormActions>
				<FormContent>
					<TabView
						className="tab-view-categorias"
						activeIndex={tabSelecionada}
						onTabChange={({ index }) => setTabSelecionada(index)}
						renderActiveOnly={false}
					>
						<TabPanel header={tituloTabReceita}>
							<TreeTabelaCategorias
								className=".p-tabview-title-categorias-financeiro"
								isMobile={isMobile}
								pesquisar={() => pesquisar()}
								categoriaSelecionada={categoriaSelecionada}
								excluirCategoria={e => excluirCategoria(e)}
								inativarCategoria={e => inativarCategoria(e)}
								ativarCategoria={e => ativarCategoria(e)}
								selecionarCategoria={e => selecionarCategoria(e)}
								informacoesPermissoes={informacoesPermissoes}
								exibirModalCategoria={() => setExibirModalCategoria(true)}
								categorias={categorias.filter(categoria => categoria.receita)}
								tipoCategoria={tipoCategoria}
								tabName="categoriaReceita"
							/>
						</TabPanel>
						<TabPanel header={tituloTabDespesas}>
							<TreeTabelaCategorias
								className=".p-tabview-title-categorias-financeiro"
								isMobile={isMobile}
								pesquisar={() => pesquisar()}
								categoriaSelecionada={categoriaSelecionada}
								excluirCategoria={e => excluirCategoria(e)}
								inativarCategoria={e => inativarCategoria(e)}
								ativarCategoria={e => ativarCategoria(e)}
								selecionarCategoria={e => selecionarCategoria(e)}
								informacoesPermissoes={informacoesPermissoes}
								exibirModalCategoria={() => setExibirModalCategoria(true)}
								categorias={categorias.filter(categoria => categoria.despesa)}
								tipoCategoria={tipoCategoria}
								tabName="categoriaDespesa"
							/>
						</TabPanel>
					</TabView>
				</FormContent>
			</Form>
			<If test={exibirModalCategoria}>
				<ModalCategoria
					visible={exibirModalCategoria}
					onHide={() => onHideModalCategoria()}
					tipoCategoria={tipoCategoria}
					categoriaSelecionada={categoriaSelecionada}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</If>
		</>
	);
}

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Categorias);
