import {
	DOCUMENTOFISCAL,
	calcularImpostosProduto,
	copiarObjeto,
	formatarDecimais,
	formatarMonetarioDecimais,
	mensagensDeValidacao,
	services,
} from 'Common';
import {
	AutoProgressBar,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputDouble,
	InputField,
	InputMoney,
	Message,
	Modal,
	SingleSelectCest,
	SingleSelectCfop,
	SingleSelectNcm,
	SingleSelectUnidadeMedida,
	TabView,
} from 'components';
import { Field, withFormik } from 'formik';
import { TabPanel } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { useContextNFCe } from '../../../../../Context';

import { defineColorStatus } from '../../../../../Util/function';
import ActionButtons from './components/ActionButtons';
import Cofins from './components/Cofins';
import Icms from './components/Icms';
import Pis from './components/Pis';

function ModalProdutoView(props) {
	const {
		visible,
		onHide,
		setFieldValueForm,
		setAtualizarTotal,
		values,
		tipoOperacao,
		decimaisQtd,
		decimaisPreco,
		indexProdutoLista,
		disabled,
		isFullScreen,
		statusNfce,
	} = props;

	const { informacoesPermissoes } = useContextNFCe();

	const { produto } = values;

	const [submitCount, setSubmitCount] = useState(0);
	const [showMessage, setShowMessage] = useState(false);
	const [atualizarTotalModal, setAtualizarTotalModal] = useState(false);

	useEffect(() => {
		const fieldsToValidate = ['cstCsosn', 'origemProduto', 'cstIpi', 'enquadramentoIpi', 'cstPis', 'cstCofins'];

		Object.keys(props.errors).some((key) => fieldsToValidate.indexOf(key) >= 0)
			? setShowMessage(true)
			: setShowMessage(false);
	}, [props.errors]);

	useEffect(() => {
		if (atualizarTotalModal) {
			const subTotal = produto.quantidade * produto.valor + produto.acessorias - produto.desconto;
			const tributos = calcularImpostosProduto({ ...copiarObjeto(produto), subTotal: subTotal }, DOCUMENTOFISCAL.NFCE);

			props.setFieldValue('produto.tributos', tributos);
			props.setFieldValue('produto.subTotal', subTotal);

			setAtualizarTotalModal(false);
		}
	}, [atualizarTotalModal]);

	function montarTextoMessage() {
		return (
			<span>
				{Object.entries(props.errors).map(([field]) => {
					switch (field) {
						case 'cstCsosn':
							return formatField('CST ICMS');
						case 'origemProduto':
							return formatField('Origem do Produto');
						case 'enquadramentoIpi':
							return formatField('Enquadramento IPI');
						case 'cstIpi':
							return formatField('CST IPI');
						case 'cstPis':
							return formatField('CST PIS');
						case 'cstCofins':
							return formatField('CST COFINS');
						default:
							return null;
					}
				})}
			</span>
		);
	}

	function formatField(field) {
		return <div key={field}>{field} - Obrigatório</div>;
	}

	function onChangeQuantidade(quantidade) {
		props.setFieldValue('produto.quantidade', quantidade);
		setAtualizarTotal(true);
		setAtualizarTotalModal(true);
	}

	function onChangeValores(field, value) {
		props.setFieldValue(`produto.${field}`, value);
		setAtualizarTotal(true);
		setAtualizarTotalModal(true);
	}

	function onChangeDesconto(value) {
		props.setFieldValue(`produto.descontoItem`, value);
		props.setFieldValue('produto.desconto', value + produto.descontoRateio);

		setAtualizarTotal(true);
		setAtualizarTotalModal(true);
	}

	return (
		<Modal
			header="Dados do produto"
			visible={visible}
			onHide={() => {
				if (props.dirty) {
					props.handleReset();
				}
				onHide(values);
			}}
			styleModal={{
				minWidth: '60%',
			}}
			container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
		>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ActionButtons
						onHide={onHide}
						indexProdutoLista={indexProdutoLista}
						setFieldValueForm={setFieldValueForm}
						disabled={disabled}
						setSubmitCount={setSubmitCount}
						submitCount={submitCount}
						setAtualizarTotal={setAtualizarTotal}
						{...props}
					/>
				</FormActions>
				<FormContent>
					<If test={showMessage && submitCount}>
						<Message severity="error" text={montarTextoMessage()} />
					</If>
					<Grid>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							component={InputField}
							label="Produto"
							size={20}
							value={produto?.produto?.label ?? produto?.produto?.registro?.nome}
							name="produto"
							disabled
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={InputDouble}
							name="quantidade"
							obrigatorio
							label="Quantidade"
							placeholder={formatarDecimais(0, decimaisQtd)}
							onChange={(event) => onChangeQuantidade(event.target.value)}
							value={produto?.quantidade}
							decimalScale={decimaisQtd}
							size={10}
							allowNegative={false}
							disabled={disabled}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={InputMoney}
							label="Valor unitário"
							decimalScale={decimaisPreco}
							name="valor"
							obrigatorio
							touched
							placeholder={formatarMonetarioDecimais(0, decimaisPreco)}
							allowNegative={false}
							onChange={(event) => onChangeValores('valor', event.target.value)}
							value={produto?.valor}
							size={13}
							disabled={disabled}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={InputMoney}
							label="Desconto"
							name="descontoItem"
							touched
							allowNegative={false}
							onChange={(event) => onChangeDesconto(event.target.value)}
							value={produto?.descontoItem}
							size={13}
							disabled={disabled}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={InputMoney}
							label="Desconto subtotal"
							name="descontoRateio"
							value={produto?.descontoRateio}
							size={13}
							disabled
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={InputMoney}
							label="Acessorias subtotal"
							name="acessorias"
							value={produto?.acessorias}
							size={13}
							disabled
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							style={{ fontWeight: 'bold' }}
							component={InputMoney}
							label="Valor total"
							name="subTotal"
							allowNegative={false}
							value={formatarMonetarioDecimais(produto?.subTotal, decimaisPreco)}
							disabled
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputField}
							label="Código de barras"
							type="number"
							size={13}
							onChange={(event) => {
								props.setFieldValue('produto.produto.registro.codigoBarras', event.target.value);
							}}
							value={produto?.produto.registro.codigoBarras}
							name="codigoBarras"
							disabled={disabled}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							label="Unidade de medida"
							name="unidadeMedida"
							obrigatorio
							component={SingleSelectUnidadeMedida}
							isClearable={false}
							value={produto?.unidadeMedida}
							onChange={(event) => props.setFieldValue('produto.unidadeMedida', event)}
							disabled={disabled}
							menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="5"
							xl="5"
							label="CFOP"
							name="cfop"
							obrigatorio
							isClearable={false}
							component={SingleSelectCfop}
							operacao={tipoOperacao}
							value={produto?.cfop}
							onChange={(event) => props.setFieldValue('produto.cfop', event)}
							onlyCodigo={false}
							placeholder="Pesquisar"
							disabled={disabled}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="5"
							lg="6"
							xl="6"
							label="NCM"
							name="ncm"
							component={SingleSelectNcm}
							mostrarSimbolo
							url={`${services.GESTOR}/v1/produtos/relacoes/ncms`}
							isClearable={false}
							value={produto?.ncm}
							onChange={(e) => {
								props.setFieldValue('produto.ncm', e);
							}}
							obrigatorio
							disabled={disabled}
							esconderBotao
							menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="5"
							lg="6"
							xl="6"
							label="CEST"
							name="cest"
							component={SingleSelectCest}
							value={produto?.cest}
							onChange={(e) => props.setFieldValue('produto.cest', e)}
							menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
							disabled={disabled}
						/>
					</Grid>
					<Grid>
						<TabView className="tab-view" {...informacoesPermissoes}>
							<TabPanel header="ICMS">
								<Icms
									tributo={produto?.tributos?.icms}
									setFieldValue={props.setFieldValue}
									disabled={disabled}
									errors={props.errors}
									informacoesPermissoes={informacoesPermissoes}
									isFullScreen={isFullScreen}
								/>
							</TabPanel>
							<TabPanel header="PIS">
								<Pis
									tributo={produto?.tributos?.pis}
									setFieldValue={props.setFieldValue}
									disabled={disabled}
									errors={props.errors}
									informacoesPermissoes={informacoesPermissoes}
									isFullScreen={isFullScreen}
								/>
							</TabPanel>
							<TabPanel header="COFINS">
								<Cofins
									tributo={produto?.tributos?.cofins}
									setFieldValue={props.setFieldValue}
									disabled={disabled}
									errors={props.errors}
									informacoesPermissoes={informacoesPermissoes}
									isFullScreen={isFullScreen}
								/>
							</TabPanel>
						</TabView>
					</Grid>
				</FormContent>
			</Form>
			<Grid style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
				<span
					style={{
						fontSize: '32px',
						fontWeight: 'bold',
						color: defineColorStatus(statusNfce),
						marginRight: '5px',
					}}
				>
					Total: {formatarMonetarioDecimais(produto?.subTotal, decimaisPreco)}
				</span>
			</Grid>
		</Modal>
	);
}
const ModalProduto = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,

	validate({ produto }) {
		const errors = {};

		if (!produto.quantidade) {
			errors.quantidade = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!produto.valor) {
			errors.valor = mensagensDeValidacao.OBRIGATORIO;
		}
		if (produto.descontoItem > produto.valor * produto.quantidade) {
			errors.descontoItem = 'Desc. maior que valor total.';
		}
		if (!produto.unidadeMedida) {
			errors.unidadeMedida = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!produto.cfop) {
			errors.cfop = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!produto.operacaoFiscal) {
			errors.operacaoFiscal = mensagensDeValidacao.OBRIGATORIO;
		}

		// Aba ICMS
		if (!produto.tributos?.icms?.cstCsosn) {
			errors.cstCsosn = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!produto.tributos?.icms?.origemProduto) {
			errors.origemProduto = mensagensDeValidacao.OBRIGATORIO;
		}

		// Aba PIS
		if (!produto.tributos?.pis?.cst) {
			errors.cstPis = mensagensDeValidacao.OBRIGATORIO;
		}

		// Aba COFINS
		if (!produto.tributos?.cofins?.cst) {
			errors.cstCofins = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalProdutoView);

export default ModalProduto;
