import { asyncGetNcmByCodigoExtensao } from '../Requests';
import { manterApenasNumeros } from '../../../../Common/Mascara';

export async function jaExisteMesmaNcm(values) {
	let existeMesmaNcm = false;

	await asyncGetNcmByCodigoExtensao(
		manterApenasNumeros(values.codigoNcm?.label),
		values.extensao,
		({ data: ncm }) => {
			if (ncm.content.length > 0 && ncm.content[0].id !== values.id) {
				existeMesmaNcm = true;
			}
		}
	);

	return existeMesmaNcm;
}
