import { del, exibirToast, get, post, put, services } from 'Common';

export async function readFormasPagamento(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function readFormaPagamento(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/formas_pagamento/${id}`, null, onSuccess, onError);
}

export async function createFormaPagamento(data, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/formas_pagamento`,
		data,
		null,
		exibirToast(onSuccess, 'Forma de pagamento criada com sucesso'),
		onError
	);
}

export async function updateFormaPagamento(data, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/formas_pagamento/${data.id}`,
		data,
		null,
		exibirToast(onSuccess, 'Forma de pagamento atualizada com sucesso'),
		onError
	);
}

export async function deleteFormaPagamento(id, onSuccess, onError) {
	return await del(
		`${services.GESTOR}/v1/formas_pagamento/${id}`,
		null,
		exibirToast(onSuccess, 'Registro excluído'),
		onError
	);
}

export async function readContas(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/formas_pagamento/relacoes/contas?size=500&sort=nome,asc`,
		null,
		onSuccess,
		onError,
		false
	);
}
