import { get, del, exibirToast, put, post } from '../../../Common/Requisicoes';
import { services } from '../../../Common/Constantes/api';

export async function asyncGetInutilizacoes(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function asyncGetInutilizacao(urlModulo, id, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/${urlModulo}/inutilizacoes/${id}`, null, onSuccess, onError);
}

export async function asyncInutilizar(urlModulo, payload, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/${urlModulo}/inutilizar`,
		payload,
		null,
		exibirToast(onSuccess, 'Inutilização salva'),
		onError
	);
}
