import { actionTypes } from '../../../../../Common/Constantes/reduxTypes';

export default (state = {}, action) => {
	switch (action.type) {
		case actionTypes.ATUALIZAR_PLANO: {
			return {
				...state,
				dataAtualizacaoPlano: new Date(),
			};
		}
		default: {
			return state;
		}
	}
};
