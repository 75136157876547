import { keyFilterConsultaRsql, mensagensDeValidacao, parseFloatNumber } from 'Common';
import { Field, useFormikContext, withFormik } from 'formik';
import { CARROCERIA_OPTIONS, RODADO_OPTIONS } from 'views/cadastros/transporte/Veiculos/Util/constantes';
import * as Yup from 'yup';

import {
	AutoProgressBar,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputDouble,
	InputField,
	InputMask,
	Modal,
	SingleSelectEstado,
	SingleSelectVeiculo,
} from 'components';
import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { ActionButtons } from './components/ActionButtons';

function ModalDadosVeiculoImpl({ visible, onHide, onSave, onChangeVeiculo }) {
	const { isSituacaoFinalMDFe, informacoesPermissoes } = useContextMDFe();
	const { values, errors, setFieldValue, setValues } = useFormikContext();

	function handleChangeVeiculo(e) {
		onChangeVeiculo(e, setValues);
	}

	return (
		<Modal visible={visible} onHide={onHide} header="Dados do veículo" focusTrapped={false}>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ActionButtons onHide={onHide} onSave={onSave} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							name="veiculo"
							component={SingleSelectVeiculo}
							obrigatorio
							value={values.veiculo}
							onChange={handleChangeVeiculo}
							url="/v1/mdfes/relacoes/veiculos"
							isClearable={false}
							placeholder="Selecione"
							touched
							disabled={isSituacaoFinalMDFe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="2"
							xl="2"
							component={InputMask}
							mask="aaa-0*00"
							obrigatorio
							label="Placa"
							name="placa"
							value={values.placa}
							onChange={(e) => setFieldValue('placa', e.target.value?.toUpperCase())}
							size={8}
							removerMascara={false}
							touched
							useFormTouched={false}
							errors={errors?.placa}
							disabled={isSituacaoFinalMDFe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="2"
							xl="2"
							component={InputField}
							label="Renavam"
							size={11}
							value={values.renavam}
							name="renavam"
							touched
							useFormTouched={false}
							errors={errors?.renavam}
							disabled={isSituacaoFinalMDFe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="8"
							lg="5"
							xl="5"
							component={SingleSelectEstado}
							label="Estado"
							name="estado"
							onChange={(event) => setFieldValue('estado', event)}
							value={values.estado}
							keyfilter={keyFilterConsultaRsql}
							isClearable={false}
							obrigatorio
							placeholder="Selecione"
							touched
							useFormTouched={false}
							disabled={isSituacaoFinalMDFe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={InputDouble}
							label="Tara (KG)"
							name="tara"
							obrigatorio
							value={values.tara}
							onChange={(e) => setFieldValue('tara', parseFloatNumber(e.target.value))}
							placeholder="0"
							decimalScale={0}
							size={6}
							allowNegative={false}
							touched
							disabled={isSituacaoFinalMDFe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={InputDouble}
							label="Capacidade (KG)"
							name="capacidade"
							obrigatorio
							value={values.capacidade}
							onChange={(e) => setFieldValue('capacidade', parseFloatNumber(e.target.value))}
							placeholder="0"
							decimalScale={0}
							size={6}
							allowNegative={false}
							touched
							disabled={isSituacaoFinalMDFe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Rodado"
							onChange={(event) => setFieldValue('rodado', event.value)}
							options={RODADO_OPTIONS}
							keyfilter={keyFilterConsultaRsql}
							name="rodado"
							showClear={false}
							obrigatorio
							touched
							disabled={isSituacaoFinalMDFe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Carroceria"
							onChange={(event) => setFieldValue('carroceria', event.value)}
							options={CARROCERIA_OPTIONS}
							keyfilter={keyFilterConsultaRsql}
							name="carroceria"
							showClear={false}
							obrigatorio
							touched
							disabled={isSituacaoFinalMDFe}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalDadosVeiculo = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,

	mapPropsToValues({ values }) {
		return values;
	},

	validationSchema: Yup.object().shape({
		veiculo: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		placa: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		renavam: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.min(9, 'Tamanho mínimo de 9 dígitos')
			.max(11, 'Tamanho máximo de 11 dígitos'),
		estado: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		tara: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).min(1, 'Valor mínimo é 1'),
		capacidade: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		rodado: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		carroceria: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalDadosVeiculoImpl);

export { ModalDadosVeiculo };
