import { Field, useFormikContext } from 'formik';

import { Grid, InputMoney, SingleSelectCst, typesCst } from 'components';

function Cofins({ tributo, disabled, informacoesPermissoes }) {
	const { errors, setFieldValue } = useFormikContext();
	return (
		<>
			<Grid>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					label="CST COFINS"
					name="tributos.cofins.cstCofins"
					component={SingleSelectCst}
					useFormErrors={false}
					useFormTouched={false}
					errors={errors?.cstCofins ?? null}
					touched={!!errors?.cstCofins}
					type={typesCst.CST_PIS_COFINS}
					value={tributo?.cst}
					isClearable={false}
					onChange={(event) => setFieldValue('tributos.cofins.cst', event)}
					obrigatorio
					disabled={disabled}
					{...informacoesPermissoes}
				/>
			</Grid>
			<Grid>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="BC COFINS"
					name="tributos.cofins.baseCofins"
					component={InputMoney}
					value={tributo?.baseCofins}
					touched
					allowNegative={false}
					onChange={(event) => setFieldValue('tributos.cofins.baseCofins', event.target?.value)}
					size={13}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="COFINS %"
					name="tributos.cofins.percentualCofins"
					component={InputMoney}
					value={tributo?.percentualCofins}
					onChange={(event) => setFieldValue('tributos.cofins.percentualCofins', event.target?.value)}
					size={8}
					decimalScale={2}
					prefix=""
					suffix="%"
					maxValue={100}
					placeholder="0,00 %"
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Valor COFINS"
					name="tributos.cofins.valorCofins"
					component={InputMoney}
					value={tributo?.valorCofins}
					touched
					allowNegative={false}
					onChange={(event) => setFieldValue('tributos.cofins.valorCofins', event.target?.value)}
					size={13}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="BC COFINS ST"
					name="tributos.cofins.baseCofinsSt"
					component={InputMoney}
					value={tributo?.baseCofinsSt}
					touched
					allowNegative={false}
					onChange={(event) => setFieldValue('tributos.cofins.baseCofinsSt', event.target?.value)}
					size={13}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="COFINS ST %"
					name="tributos.cofins.percentualCofinsSt"
					component={InputMoney}
					value={tributo?.percentualCofinsSt}
					onChange={(event) => setFieldValue('tributos.cofins.percentualCofinsSt', event.target?.value)}
					size={8}
					decimalScale={2}
					prefix=""
					suffix="%"
					maxValue={100}
					placeholder="0,00 %"
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Valor COFINS ST"
					name="tributos.cofins.valorCofinsSt"
					component={InputMoney}
					value={tributo?.valorCofinsSt}
					touched
					allowNegative={false}
					onChange={(event) => setFieldValue('tributos.cofins.valorCofinsSt', event.target?.value)}
					size={13}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
			</Grid>
		</>
	);
}

export { Cofins };
