import {
	Checkbox,
	Dropdown,
	Fieldset,
	Grid,
	If,
	InputDouble,
	InputField,
	SingleSelectOperacaoFiscal,
} from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { TIPO_OPERACAO } from 'views/configuracao/ConfiguracoesGerais/Util/constantes';
import { helpConfiguracoesForm } from '../../../TabsConfiguracoesGerais/Help';

function TabConfiguracoesNfce() {
	const { informacoesPermissoes, alterarNumeroInicialESerieNfce, setAlterarNumeroInicialESerieNfce } =
		useContextConfiguracoesGerais();
	const { values, setFieldValue, errors } = useFormikContext();

	return (
		<If test={values.emiteNfce}>
			<Fieldset legend="Configurações NFC-e" className="fieldset">
				<Grid>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						label="Tipo de impressão da DANFC-e"
						name="tipoImpressaoDanfeNfce"
						value={values.tipoImpressaoDanfeNfce}
						showClear={false}
						onChange={(e) => setFieldValue('tipoImpressaoDanfeNfce', e.value)}
						options={[
							{
								label: 'Sem geração de DANF-e',
								value: 'SEM_GERACAO',
							},
							{
								label: 'Normal / Modo retrato',
								value: 'NORMAL_RETRATO',
							},
							{
								label: 'Normal / Modo paisagem',
								value: 'NORMAL_PAISAGEM',
							},
							{
								label: 'Simplificado',
								value: 'SIMPLIFICADO',
							},
							{
								label: 'Normal / NFC-e',
								value: 'NFCE',
							},
						]}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Token de seguranca do contribuinte"
						obrigatorio
						name="tokenSegurancaContribuinteNfce"
						size={10}
						value={values.tokenSegurancaContribuinteNfce}
						onChange={(e) => setFieldValue('tokenSegurancaContribuinteNfce', e.target.value?.replace(/\D/g, ''))}
						component={InputField}
						useFormErrors={false}
						useFormTouched={false}
						errors={errors.tokenSegurancaContribuinteNfce}
						touched={!!errors.tokenSegurancaContribuinteNfce}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Código de segurança do contribuinte"
						name="codigoSegurancaContribuinteNfce"
						obrigatorio
						value={values.codigoSegurancaContribuinteNfce}
						onChange={(e) => setFieldValue('codigoSegurancaContribuinteNfce', e.target.value)}
						size={255}
						component={InputField}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Checkbox}
						label="Alterar número e série da NFC-e"
						name="alterarNumeroInicialESerieNfce"
						onChange={() => {
							setAlterarNumeroInicialESerieNfce(!alterarNumeroInicialESerieNfce);
							setFieldValue('alterarNumeroInicialESerieNfce', alterarNumeroInicialESerieNfce);
						}}
						checked={alterarNumeroInicialESerieNfce}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						label="Série da NFC-e"
						name="serieNfce"
						obrigatorio
						disabled={!alterarNumeroInicialESerieNfce}
						size={3}
						value={values.serieNfce}
						onChange={(e) => setFieldValue('serieNfce', e.target.value)}
						component={InputDouble}
						decimalScale={0}
						helpMessage={helpConfiguracoesForm.serieNfce}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						label="Número inicial da NFC-e"
						name="numeroInicialNfce"
						value={values.numeroInicialNfce}
						onChange={(e) => setFieldValue('numeroInicialNfce', e.target.value)}
						size={9}
						thousandSeparator=""
						component={InputDouble}
						disabled={!alterarNumeroInicialESerieNfce}
						decimalScale={0}
						helpMessage={helpConfiguracoesForm.numeroInicialNfce}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Checkbox}
						label="Enviar e-mails automaticamente para destinatário ao emitir uma NFC-e"
						name="envioAutomaticoEmailNfce"
						onChange={(e) => setFieldValue('envioAutomaticoEmailNfce', e.checked)}
						checked={values.envioAutomaticoEmailNfce}
						{...informacoesPermissoes}
					/>

					<div
						style={{
							margin: '10px 0px 0px 7px',
							width: '100%',
						}}
						title="Ao definir as preferências elas serão utilizadas como informações padrões na NFC-e"
					>
						<b>Preferências NFC-e</b>
					</div>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						name="operacaoFiscalNfce"
						label="Operação fiscal"
						component={SingleSelectOperacaoFiscal}
						onChange={(event) => setFieldValue('operacaoFiscalNfce', event)}
						tipoOperacao={TIPO_OPERACAO.SAIDA}
						isClearable={false}
						value={values.operacaoFiscalNfce}
						{...informacoesPermissoes}
					/>
				</Grid>
			</Fieldset>
		</If>
	);
}

export { TabConfiguracoesNfce };
