import { services } from "../../../../Common/Constantes/api";
import {
  del,
  get,
  post,
  put,
  exibirToast,
} from "../../../../Common/Requisicoes";

export async function asyncGetProdutos(url, onSuccess, onError) {
  return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetProduto(idProduto, onSuccess, onError) {
  await get(
    `${services.GESTOR}/v1/produtos/${idProduto}`,
    null,
    onSuccess,
    onError
  );
}

export async function asyncDeleteProduto(idProduto, onSuccess, onError) {
  await del(
    `${services.GESTOR}/v1/produtos/${idProduto}`,
    null,
    exibirToast(onSuccess, "Produto excluído"),
    onError
  );
}

export async function asyncCreateProduto(produto, onSuccess, onError) {
  const isServico = produto?.tipo === "SERVICO";
  await post(
    `${services.GESTOR}/v1/produtos`,
    produto,
    null,
    exibirToast(onSuccess, `${isServico ? "Serviço" : "Produto"} criado`),
    onError
  );
}

export async function asyncUpdateProduto(produto, onSuccess, onError) {
  const isServico = produto?.tipo === "SERVICO";
  await put(
    `${services.GESTOR}/v1/produtos/${produto.id}`,
    produto,
    null,
    exibirToast(onSuccess, `${isServico ? "Serviço" : "Produto"} atualizado`),
    onError
  );
}

export async function asyncGetProdutoMesmoSku(produto, onSuccess, onError) {
  await get(
    `${services.GESTOR}/v1/produtos?query=sku=="${produto}"`,
    null,
    onSuccess,
    onError
  );
}

export async function asyncGetProdutoMesmoCodigoBarras(
  codigoBarras,
  onSuccess,
  onError
) {
  await get(
    `${services.GESTOR}/v1/produtos?query=codigoBarras=="${codigoBarras}"`,
    null,
    onSuccess,
    onError
  );
}

export async function asyncAjustarSaldo(idProduto, data, onSuccess, onError) {
  await post(
    `${services.GESTOR}/v1/produtos/estoque/${idProduto}/ajustar_saldo`,
    data,
    null,
    exibirToast(onSuccess, "Saldo em estoque ajustado"),
    onError
  );
}

export async function asyncImprimirInventario(
  dataReferencia,
  campoCodigo,
  valorInventario,
  campoOrdenacao,
  condicaoSaldo,
  listaProdutos,
  onSuccess,
  onError
) {
  await get(
    `${services.GESTOR}/v1/produtos/estoque/imprimir?datareferencia=` +
      dataReferencia +
      `&campocodigo=` +
      campoCodigo +
      `&valorinventario=` +
      valorInventario +
      `&campoordenacao=` +
      campoOrdenacao +
      `&condicaosaldo=` +
      condicaoSaldo +
      `&listaprodutos=` +
      listaProdutos,
    { responseType: "arraybuffer" },
    onSuccess,
    onError,
    false
  );
}

export async function asyncBaixarRelatorioMovimentacoesPorPeriodo(
  produtos,
  dataInicial,
  dataFinal,
  onSuccess,
  onError
) {
  let produtosIds = "";

  if (produtos && produtos.length > 0) {
    produtosIds = montarProdutosSelecionados(produtos);
  }
  return await get(
    `${services.GESTOR}/v1/estoque/movimentacoes/produtos/imprimir?dataInicial=${dataInicial}&dataFinal=${dataFinal}&produtoIds=${produtosIds}`,
    { responseType: "arraybuffer" },
    onSuccess,
    onError,
    false
  );
}

export async function asyncUploadImagemProduto(
  imagem,
  idProduto,
  onSuccess,
  onError,
  exibirLoading,
  tratarErros
) {
  const formData = new FormData();
  formData.append("imagem", imagem);
  await post(
    `${services.GESTOR}/v1/produtos/${idProduto}/imagem`,
    formData,
    { headers: { "content-type": "multipart/form-data" } },
    onSuccess,
    onError,
    exibirLoading,
    tratarErros
  );
}

export async function asyncDownloadImagemProduto(
  idProduto,
  onSuccess,
  onError,
  exibirLoading,
  tratarErros
) {
  await get(
    `${services.GESTOR}/v1/produtos/${idProduto}/imagem`,
    { responseType: "blob" },
    onSuccess,
    onError,
    exibirLoading,
    tratarErros
  );
}

export async function asyncDeleteImagemProduto(
  idProduto,
  onSuccess,
  onError,
  exibirLoading,
  tratarErros
) {
  await del(
    `${services.GESTOR}/v1/produtos/${idProduto}/imagem`,
    null,
    onSuccess,
    onError,
    exibirLoading,
    tratarErros
  );
}

function montarProdutosSelecionados(produtos) {
  return produtos.map((produto) => produto.value);
}

export async function asyncImprimirProdutosEstoqueMinimo(
  campoCodigo,
  campoOrdenacao,
  listaProdutos,
  onSuccess,
  onError
) {
  await get(
    `${services.GESTOR}/v1/produtos/produtos_abaixo_do_estoque_minimo/imprimir?campoCodigo=${campoCodigo}&campoOrdenacao=${campoOrdenacao}&listaprodutos=${listaProdutos}`,
    { responseType: "arraybuffer" },
    onSuccess,
    onError,
    false
  );
}
