import { fazerLogoutLocalStorange, mensagensDeValidacao } from 'Common';
import { AutoProgressBar, Button, Col, FormGroup, Grid, InputField, InputPassword, Modal } from 'components';
import { Field, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { INITIAL_VALUES } from './Util/constantes';
import { asyncExcluirEmpresa } from '../../Requests';

function ModalExcluirEmpresaView({ onHide, visible, isValid }) {
	const { values, setFieldValue, handleSubmit } = useFormikContext();

	function excluir() {
		handleSubmit();

		if (isValid && values.fraseConfirmacao && values.senha) {
			asyncExcluirEmpresa(values, () => {
				onHide();
				fazerLogoutLocalStorange();
				window.location = '#/login';
			});
		}
	}

	return (
		<Modal header="Confirmar exclusão da empresa?" visible={visible} onHide={() => onHide()}>
			<FormGroup>
				<AutoProgressBar />
				<Grid style={{ marginBottom: '10px' }}>
					<Col>
						Você tem certeza que deseja excluir sua empresa? Todos os{' '}
						<b>seus dados serão destruídos e esta ação é irreversível.</b>
						<br />
						Caso queira continuar, informe a frase <b>apagar meus dados</b> e sua senha nos respectivos campos para
						prosseguir
					</Col>
					<Col>
						<Field
							colStyle={{ padding: 0 }}
							component={InputField}
							label="Frase de confirmação"
							feedback={false}
							name="fraseConfirmacao"
							onChange={(e) => setFieldValue('fraseConfirmacao', e.target.value)}
							value={values.fraseConfirmacao}
						/>
					</Col>
					<Col>
						<Field
							colStyle={{ padding: 0 }}
							component={InputPassword}
							label="Sua senha"
							feedback={false}
							name="senha"
							onChange={(e) => setFieldValue('senha', e.target.value)}
							value={values.senha}
						/>
					</Col>
				</Grid>
				<Col>
					<Grid justifyStart>
						<Button
							color="danger"
							disabled={!values.senha || !values.fraseConfirmacao}
							label="Apagar todos os dados e remover conta"
							icon="fa fa-trash"
							onClick={excluir}
						/>
					</Grid>
				</Col>
			</FormGroup>
		</Modal>
	);
}

const ModalExcluirEmpresa = withFormik({
	validateOnChange: false,
	validateOnBlur: true,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validationSchema: Yup.object().shape({
		senha: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.test('len', 'A senha deve ter no mínimo 8 caracteres', (val) => val && val.length >= 8),
		fraseConfirmacao: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.test('len', 'Frase de confirmação inválida', (val) => val && val === 'apagar meus dados'),
	}),

	handleSubmit: () => {},
})(ModalExcluirEmpresaView);

export default ModalExcluirEmpresa;
