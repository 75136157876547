import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format } from 'date-fns';
import { NenhumRegistroEncontrado, Paginacao, Button, Badge } from '../../../index';
import { status, statusColor } from '../../Util/constantes';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

const styleJustificativa = {
	color: '#333333',
	width: '30%',
	maxWidth: '10rem',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

function TabelaInutilizacao(props) {
	const {
		registros,
		sortField,
		sortOrder,
		totalRecords,
		rows,
		page,
		onPageChange,
		isMobile,
		setSortField,
		setSortOrder,
		setModalNovaInutilizacao,
		setSelectedElement,
	} = props;

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function renderAno(row) {
		let ano = format(new Date(row.dataInutilizacao), 'yyyy');

		return ano;
	}
	function renderData(row) {
		let data = format(new Date(row.dataInutilizacao), 'dd/MM/yyyy');

		return data;
	}
	function renderSituacao(row) {
		let situacao = row.situacao;

		const styleBackground = {
			borderRadius: '20px',
			padding: '0.2rem 1.1rem',
		};

		const styleDescription = {
			fontStyle: situacao === 'INUTILIZADA' ? 'italic' : '',
			opacity: situacao === 'REJEITADA' ? '0.7' : '',
			margin: situacao === 'PENDENTE' ? '3px 16px' : '3px 10px',
		};

		switch (situacao) {
			case status.PENDENTE: {
				return Badge(
					statusColor.PENDENTE.darkText,
					statusColor.PENDENTE.lightBackground,
					'Pendente',
					styleBackground,
					styleDescription
				);
			}
			case status.REJEITADA: {
				return Badge(
					statusColor.REJEITADA.darkText,
					statusColor.REJEITADA.lightBackground,
					'Rejeitada',
					styleBackground,
					styleDescription
				);
			}
			case status.INUTILIZADA: {
				return Badge(
					statusColor.INUTILIZADA.darkText,
					statusColor.INUTILIZADA.lightBackground,
					'Inutilizada',
					styleBackground,
					styleDescription
				);
			}
		}
	}
	function renderAcoes(row) {
		return (
			<Button
				style={styleButton}
				className="p-button p-button-primary"
				icon="fa fa-pencil"
				title={'Editar'}
				disabled={false}
				onClick={() => {
					setSelectedElement(row), setModalNovaInutilizacao(true);
				}}
			/>
		);
	}

	return (
		<div style={{ width: !isMobile ? '100%' : '99%' }}>
			<DataTable
				className="table"
				value={registros}
				responsive
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={atualizarOrdenacao}
				emptyMessage={<NenhumRegistroEncontrado message="Nenhuma nota fiscal encontrada" />}
				style={{ overflow: 'hidden' }}
			>
				<Column
					className="step-listagem-order"
					header="Ano"
					field="ano"
					sortable={true}
					body={row => renderAno(row)}
					style={{ color: '#333333', width: '5%' }}
				/>
				<Column
					header="Série"
					field="serie"
					sortable={true}
					body={row => row.serie}
					style={{ color: '#333333', width: '5%' }}
				/>
				<Column
					header="Nº inicial"
					field="numeroInicial"
					sortable={true}
					body={row => row.numeroInicial}
					style={{ color: '#333333', width: '5%' }}
				/>
				<Column
					header="Nº final"
					field="numeroFinal"
					sortable={true}
					body={row => row.numeroFinal}
					style={{ color: '#333333', width: '5%' }}
				/>
				<Column
					header="Data"
					field="data"
					sortable={true}
					body={row => renderData(row)}
					style={{ color: '#333333', width: '5%' }}
				/>
				<Column
					header="Justificativa"
					field="justificativa"
					sortable={true}
					body={row => row.justificativa}
					style={styleJustificativa}
				/>
				<Column
					header="Situação"
					field="situacao"
					sortable={true}
					body={row => renderSituacao(row)}
					style={{ color: '#333333', width: '15%' }}
				/>
				<Column
					header="Ações"
					sortable={true}
					body={row => renderAcoes(row)}
					style={{ color: '#333333', width: '5%' }}
				/>
			</DataTable>
			<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
		</div>
	);
}

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(TabelaInutilizacao);
