import { Modal } from '../../../../../../components';
import FormAtualizacaoPrecosView from './FormAtualizacaoPrecos';

function ModalAtualizacaoPrecos(props) {
	const { onHide, visible, isMobile } = props;

	return (
		<>
			<Modal
				header={isMobile ? 'Atualização de preços' : 'Atualização de preços em lote'}
				onHide={onHide}
				visible={visible}
				styleModal={{
					minWidth: '60%',
					height: isMobile ? '85%' : 'auto',
				}}
				isMobile={isMobile}
			>
				<FormAtualizacaoPrecosView {...props} />
			</Modal>
		</>
	);
}

export default ModalAtualizacaoPrecos;
