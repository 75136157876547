import { tipoCampos } from '../../PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancado = [
	{ label: 'Série', name: 'serie', type: tipoCampos.INTEGER },
	{ label: 'N. Inicial', name: 'numeroInicial', type: tipoCampos.INTEGER },
	{ label: 'N. Final', name: 'numeroFinal', type: tipoCampos.INTEGER },
	{ label: 'Data', name: 'dataInutilizacao', type: tipoCampos.DATE },
	{ label: 'Justificativa', name: 'justificativa', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Inutilizada', value: 'INUTILIZADA' },
			{ label: 'Rejeitada', value: 'REJEITADA' },
		],
	},
];

export const MessagesErrors = {
	OBRIGATORIO: 'Obrigatório',
	CAMPO_OBRIGATORIO: 'Campo obrigatório',
	CHAVE_ACESSO_INVALIDA: 'Chave de acesso inválida',
};

export const status = {
	PENDENTE: 'PENDENTE',
	REJEITADA: 'REJEITADA',
	INUTILIZADA: 'INUTILIZADA',
};

export const statusColor = {
	PENDENTE: {
		lightText: '#B3E5FC',
		darkText: '#006095',
		lightBackground: '#B3E5FC',
		darkBackground: '#006095',
	},
	REJEITADA: {
		lightText: '#FFCDD2',
		darkText: '#B71C1C',
		lightBackground: '#FFCDD2',
		darkBackground: '#B71C1C',
	},
	INUTILIZADA: {
		lightText: '#DCEDC8',
		darkText: '#1B5E20',
		lightBackground: '#DCEDC8',
		darkBackground: '#1B5E20',
	},
};

export const TipoInutilizacao = {
	NFE: 'NFE',
	NFCE: 'NFCE',
};
