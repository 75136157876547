import React, { Component } from 'react';
import * as Yup from 'yup';
import autoBind from 'react-autobind';
import Modal from '../../../../../components/Modal';
import { withRouter } from 'react-router';
import { withFormik, Field } from 'formik';
import { mensagensDeValidacao } from '../../../../../Common/Constantes/mensagens';
import { usuarioPossuiPermissao } from '../../../../../Common/Autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../Common/Constantes/autorizacao';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import ButtonSalvar from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import Grid from '../../../../../components/Grid';
import InputDate from '../../../../../components/input/InputDate';
import InputMoney from '../../../../../components/input/InputMoney';
import { services } from '../../../../../Common/Constantes/api';
import SingleSelectContas from '../../../../../components/select/SingleSelectConta';
import SingleSelectCategoria, { tiposCategoria } from '../../../../../components/select/SingleSelectCategoria';
import {
	asyncCreateLancamentoReceita,
	asyncDeleteLancamentoReceita,
	buscarCategoriaFavoritaReceita,
	buscarContaFavoritaReceita,
	asyncUpdateLancamentoReceita,
	buscarFormaPagamentoDinheiro,
} from './Requests';
import {
	converterLancamentoReceitaParaApi,
	converterLancamentoReceitaParaFormulario,
} from './Util/lancamentoReceitaConverter';
import { helpMessage } from './Util/constantes';
import { confirm } from '../../../../../components/Toast';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import InputField from '../../../../../components/input/InputField';
import SingleSelectPessoa from '../../../../../components/select/SingleSelectPessoa';
import TextArea from '../../../../../components/TextArea/TextArea';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import { formatISO, isValid, parseISO } from 'date-fns';
import { SingleSelectFormaPagamento } from 'components';
import { formaPagamento } from 'views/compras/NotasEntrada/Form/components/TabelaPagamentos/Util/constantes';

const initialValue = {
	id: null,
	tipo: 'RECEITA',
	valor: 0,
	data: null,
	descricao: '',
	formaPagamento: null,
	conta: null,
	categoria: null,
	pessoa: null,
	observacao: null,
};

class ModalLancamentoReceita extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			podeInserir: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.INSERIR),
			podeEditar: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.EDITAR),
			podeExcluir: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.EXCLUIR),
			optionsSelectFormasPagamento: [],
			optionsSelectContas: [],
		};
	}

	componentDidMount() {
		if (!this.props.values.id) {
			this.atribuirValoresPadrao();
		}

		setTimeout(() => {
			document.getElementById('LancamentoReceitaInputMoneyValor')?.focus();
		}, 500);
	}

	atribuirValoresPadrao() {
		const { values, valorPadraoData } = this.props;
		let categoria = values.categoria;
		let formaPagamento = values.formaPagamento;
		let conta = values.conta;
		let data = values.data;

		if (valorPadraoData) {
			data = formatISO(valorPadraoData);
		}

		buscarCategoriaFavoritaReceita(({ data: categoriaReceita }) => {
			if (categoriaReceita.totalElements > 0) {
				categoria = {
					label: categoriaReceita.content[0].nome,
					value: categoriaReceita.content[0].id,
					registro: categoriaReceita.content[0],
				};
			}
			if (this.props.dirty) {
				this.props.setFieldValue('categoria', categoria);
				this.props.setFieldValue('data', data);
			} else {
				this.props.resetForm({ values: { ...this.props.values, categoria, data } });
			}
		});

		buscarFormaPagamentoDinheiro(({ data }) => {
			if (data.totalElements > 0) {
			  formaPagamento = {
				label: data.content[0].descricao,
				value: data.content[0].id,
				registro: data.content[0],
			  };
			}
			if (this.props.dirty) {
			  this.props.setFieldValue('formaPagamento', formaPagamento);
			} else {
			  this.props.resetForm({
				values: { ...this.props.values, formaPagamento },
			  });
			}
		});

		buscarContaFavoritaReceita(({ data: contaFavorita }) => {
			if (contaFavorita.totalElements > 0) {
				conta = {
					label: contaFavorita.content[0].nome,
					value: contaFavorita.content[0].id,
					registro: contaFavorita.content[0],
				};
			}
			if (this.props.dirty) {
				this.props.setFieldValue('conta', conta);
			} else {
				this.props.resetForm({ values: { ...this.props.values, conta } });
			}
		});
	}

	novo() {
		this.props.onNovoClick && this.props.onNovoClick();
		this.props.resetForm({ values: initialValue });
		this.atribuirValoresPadrao();
	}

	async salvar(e, novoOnSuccess) {
		await this.props.handleSubmit();
		await this.props.validateForm();
		if (this.props.isValid) {
			if (this.props.values.id) {
				this.asyncUpdateLancamentoReceita(this.props.values, novoOnSuccess);
			} else {
				this.asyncCreateLancamentoReceita(this.props.values, novoOnSuccess);
			}
		}
	}

	async asyncCreateLancamentoReceita(values, novoOnSuccess) {
		if (values) {
			await asyncCreateLancamentoReceita(converterLancamentoReceitaParaApi(this.props.values), () => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					this.props.onHide(values);
				}
			});
		}
	}

	async asyncUpdateLancamentoReceita(values, novoOnSuccess) {
		if (values) {
			await asyncUpdateLancamentoReceita(converterLancamentoReceitaParaApi(this.props.values), () => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					this.props.onHide(values);
				}
			});
		}
	}

	excluir() {
		confirm('Confirmação', 'Deseja excluir o registro?', async () => {
			await asyncDeleteLancamentoReceita(this.props.values.id, () => {
				this.props.onHide(true);
			});
		});
	}

	cancelar() {
		if (this.props.dirty) {
			this.props.resetForm({ values: this.props.initialValues });
		} else {
			this.props.onHide(true);
		}
	}

	render() {
		const { visible, onHide, dirty, values } = this.props;

		const informacoesPermissoes = {
			podeInserir: this.state.podeInserir,
			podeEditar: this.state.podeEditar,
			podeExcluir: this.state.podeExcluir,
			estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		};

		const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
		const onClickNovo = dirty ? e => this.salvar(e, this.novo) : this.novo;

		return (
			<Modal header={values.id ? 'Editar receita' : 'Nova receita'} visible={visible} onHide={() => onHide(true)}>
				<AutoProgressBar />
				<Form>
					<FormActions>
						<ButtonCancelar
							{...informacoesPermissoes}
							hidden={values.id && !dirty}
							estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={this.cancelar}
						/>
						<ButtonSalvar {...informacoesPermissoes} disabled={!dirty} onClick={this.salvar} />
						<ButtonNovo
							onClick={onClickNovo}
							hidden={!dirty && !values.id}
							estadoBotao={estadoBotaoNovo}
							{...informacoesPermissoes}
						/>
						<ButtonExcluir hidden={!values.id} {...informacoesPermissoes} onClick={this.excluir} />
					</FormActions>
					<FormContent>
						<Grid>
							<Field
								sm="12"
								md="3"
								lg="3"
								xl="3"
								component={InputMoney}
								label="Valor"
								name="valor"
								autofocus
								obrigatorio
								size={12}
								helpMessage={helpMessage.valor}
								onChange={e => this.props.setFieldValue('valor', e.target.value)}
								value={values.valor}
								id="LancamentoReceitaInputMoneyValor"
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="6"
								lg="6"
								xl="6"
								component={InputField}
								label="Descrição"
								name="descricao"
								helpMessage={helpMessage.descricao}
								size={255}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="3"
								lg="3"
								xl="3"
								component={InputDate}
								obrigatorio
								label="Data"
								name="data"
								helpMessage={helpMessage.data}
								onChange={e => this.props.setFieldValue('data', e.target.value)}
								value={values.data}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="6"
								lg="4"
								xl="4"
								name="formaPagamento"
								label="Forma de Pagamento"
								component={SingleSelectFormaPagamento}
								value={values.formaPagamento}
								obrigatorio
								options={this.state.optionsSelectFormasPagamento}
								onChangeOptions={options => this.setState({ optionsSelectFormasPagamento: options })}
								helpMessage={helpMessage.formaPagamento}
								onChange={e => this.props.setFieldValue('formaPagamento', e)}
								url={`${services.GESTOR}/v1/lancamentos/relacoes/formas_pagamento`}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="6"
								lg="4"
								xl="4"
								name="conta"
								label="Conta"
								component={SingleSelectContas}
								value={values.conta}
								obrigatorio
								options={this.state.optionsSelectContas}
								onChangeOptions={options => this.setState({ optionsSelectContas: options })}
								helpMessage={helpMessage.conta}
								onChange={e => this.props.setFieldValue('conta', e)}
								url={`${services.GESTOR}/v1/lancamentos/relacoes/contas`}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="6"
								lg="4"
								xl="4"
								name="categoria"
								label="Categoria"
								component={SingleSelectCategoria}
								tipoCategoria={tiposCategoria.RECEITA}
								value={values.categoria}
								helpMessage={helpMessage.categoria}
								onChange={e => this.props.setFieldValue('categoria', e)}
								url={`${services.GESTOR}/v1/lancamentos/relacoes/categorias/receitas`}
								{...informacoesPermissoes}
							/>
							<Accordion className="tab-accordion-lite" style={{ width: '100%' }}>
								<AccordionTab header="Informações adicionais">
									<Field
										sm="8"
										md="8"
										lg="8"
										xl="8"
										component={SingleSelectPessoa}
										url={`${services.GESTOR}/v1/lancamentos/relacoes/pessoas`}
										name="pessoa"
										label="Pessoa "
										value={values.pessoa}
										helpMessage={helpMessage.pessoa}
										onChange={e => this.props.setFieldValue('pessoa', e)}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={TextArea}
										placeholder="Escreva sua observação aqui"
										label="Observação"
										name="observacao"
										helpMessage={helpMessage.observacao}
										onChange={e => this.props.setFieldValue('observacao', e.target.value)}
										{...informacoesPermissoes}
									/>
								</AccordionTab>
							</Accordion>
						</Grid>
					</FormContent>
				</Form>
			</Modal>
		);
	}
}

ModalLancamentoReceita = withFormik({
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return converterLancamentoReceitaParaFormulario(props.registroSelecionado);
		} else {
			return initialValue;
		}
	},

	validate(values) {
		let errors = {};
		if (!values.valor || values.valor <= 0) {
			errors.valor = 'O valor deve ser maior que zero';
		}

		if (values.data && !isValid(parseISO(values.data))) {
			errors.data = mensagensDeValidacao.DATA_INVALIDA;
		}
		return errors;
	},

	validationSchema: Yup.object().shape({
		formaPagamento: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		conta: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),		
		data: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		observacao: Yup.string().max(4096, 'O campo observação não pode ter mais que 4096 caracteres.').nullable(true),
	}),
	handleSubmit: () => {},
})(ModalLancamentoReceita);

export default withRouter(ModalLancamentoReceita);
