import { get } from "../../../../../../../../Common/Requisicoes";
import { services } from "../../../../../../../../Common/Constantes/api";
import { formatISO, isValid, isDate, format } from "date-fns";

export function buscarDespesasPorCategoria(
  dataInicial,
  dataFinal,
  onSuccess,
  onError
) {
  let dataInicialFormatada = formatISO(new Date(1900, 1, 1), {
    representation: "date",
  });
  let dataFinalFormatada = formatISO(new Date(2100, 1, 1), {
    representation: "date",
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    dataInicialFormatada = formatISO(dataInicial, { representation: "date" });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    dataFinalFormatada = formatISO(dataFinal, { representation: "date" });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/financas/despesas_por_categoria?dataInicial=${dataInicialFormatada}&dataFinal=${dataFinalFormatada}`,
    null,
    onSuccess,
    onError,
    false
  );
}
