import { del, exibirToast, get, post, put, services } from 'Common';
import { formatISO } from 'date-fns';

export async function asyncGetOrdensServico(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function asyncGetOrdemServicoById(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/ordem_servico/${id}`, null, onSuccess, onError);
}

export async function asyncCreateOrdemServico(data, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/ordem_servico`,
		data,
		null,
		exibirToast(onSuccess, 'Ordem de serviço criada com sucesso'),
		onError
	);
}

export async function asyncUpdateOrdemServico(data, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/ordem_servico/${data.id}`,
		data,
		null,
		exibirToast(onSuccess, 'Ordem de serviço atualizada com sucesso'),
		onError
	);
}

export async function asyncDeleteOrdemServico(
	id,
	messageOnSuccess = 'Registro excluído com sucesso',
	onSuccess,
	onError
) {
	await del(`${services.GESTOR}/v1/ordem_servico/${id}`, null, exibirToast(onSuccess, messageOnSuccess), onError);
}

export async function asyncGetTotalizadores(interval, onSuccess, onError) {
	if (interval.dataInicial && interval.dataFinal) {
		return await get(
			`${services.GESTOR}/v1/ordem_servico/totais?dataInicial=${formatISO(interval.dataInicial, {
				representation: 'date',
			})}&dataFinal=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`,
			null,
			onSuccess,
			onError
		);
	}
}

export async function asyncEmitirBoletosOS(convenioId, contaReceberIds, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/boletos/convenios/${convenioId}/emitir`,
		contaReceberIds,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncGerarCobrancas(contaId, contaReceberIds, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/pix/cobranca/contas/${contaId}/gerar`,
		contaReceberIds,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncPostImprimirBoletosOS(boletos, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/boletos/imprimir`,
		boletos,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncPostImprimirCobrancas(cobrancas, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/pix/cobranca/imprimir`,
		cobrancas,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetContasReceberPorId(contaReceberId, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/contas_receber/${contaReceberId}`, null, onSuccess, onError);
}

export async function asyncGetOperacoesFiscais(
	{ tipoOperacao = 'SAIDA', tipoCliente = false, favoritaSaida = true, favoritaEntrada = false },
	onSuccess,
	onError
) {
	const query = `?query=situacao=="ATIVO";indicacaoMovimento==${tipoOperacao};vendaConsumidor==${tipoCliente};favoritoSaida==${favoritaSaida};favoritoEntrada==${favoritaEntrada}`;
	await get(`${services.GESTOR}/v1/ordem_servico/relacoes/operacoes_fiscais${query}`, null, onSuccess, onError);
}

export async function asyncGetVendedor(onSuccess) {
	await get(
		`${services.GESTOR}/v1/ordem_servico/relacoes/vendedores?filtro=&page=0&size=500&sort=codigo,asc`,
		null,
		onSuccess
	);
}

export async function asyncGetConta(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/ordem_servico/relacoes/contas?query=situacao=="ATIVA";favoritaReceita==true`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetContaComConvenio(emiteBoletos, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_receber/relacoes/contas?query=situacao=="ATIVA"${
			emiteBoletos === true ? ';emitirBoletos=="true"' : ';recebePix=="true"'
		}`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetCategoria(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/ordem_servico/relacoes/categorias/receitas?query=ativa==true;favoritaReceita==true`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetCondicaoPagamentoFavorita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/ordem_servico/relacoes/condicoes_pagamento?query=(favorita==true);(movimentacao==SAIDA)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetFormaPagamentoDinheiro(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/ordem_servico/relacoes/formas_pagamento?query=(formaPagamentoFiscal==DINHEIRO)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncBuscarTabelaPreco(dadosRequest, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/ordem_servico/relacoes/buscar_tabela_a_utilizar?municipioId=${
			dadosRequest?.municipioId ?? ''
		}&clienteId=${dadosRequest?.clienteId ?? ''}&vendedorId=${dadosRequest?.vendedorId ?? ''}`,
		null,
		onSuccess,
		onError
	);
}

export async function asyncPostGerarDocumentosFiscais(id, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/ordem_servico/${id}/criar_documentos_fiscais`, null, null, onSuccess, onError);
}

export async function asyncPostTransmitirDocumentosFiscais(id, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/ordem_servico/${id}/transmitir_documentos_fiscais`, null, null, onSuccess, onError);
}

export async function asyncPostEstornarOrdemServico(id, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/ordem_servico/${id}/estornar`, null, null, onSuccess, onError);
}

export async function asyncPostCancelarOrdemServico(id, motivoCancelamento, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/ordem_servico/${id}/cancelar`, { motivoCancelamento }, null, onSuccess, onError);
}

export async function asyncPostEstornarCancelamentoOrdemServico(id, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/ordem_servico/${id}/estornar_cancelamento`, null, null, onSuccess, onError);
}

export async function asyncPostFinalizarOrdemServico(id, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/ordem_servico/${id}/finalizar`, null, null, onSuccess, onError);
}

export async function asyncImprimirOrdemServicoExecucao(id, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/ordem_servico/${id}/imprimir_execucao`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function asyncImprimirOrdemServicoComprovante(id, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/ordem_servico/${id}/imprimir_comprovante`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function asyncImprimirOrdemServico(id, idOrganizacao, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/ordem_servico/${id}/organizacoes/${idOrganizacao}/imprimir`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function asyncImprimirOrcamentoOrdemServico(id, idOrganizacao, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/ordem_servico/${id}/organizacoes/${idOrganizacao}/imprimir_orcamento`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function asyncEnviarOrdemServicoPorEmail(id, body, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/ordem_servico/${id}/enviar_por_email`,
		body,
		null,
		exibirToast(onSuccess, 'E-mail(s) enviado(s)'),
		onError
	);
}

export async function asyncEnviarOrcamentoOrdemServicoPorEmail(id, body, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/ordem_servico/${id}/enviar_orcamento_por_email`,
		body,
		null,
		exibirToast(onSuccess, 'E-mail(s) enviado(s)'),
		onError
	);
}

export async function asyncEfetuarRecebimento(id, recebimento, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/ordem_servico/${id}/parcelas/${recebimento.id}/receber`,
		recebimento,
		null,
		exibirToast(onSuccess, 'Recebimento efetuado com sucesso'),
		onError
	);
}

export async function asyncEstornarRecebimento(id, parcelaId, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/ordem_servico/${id}/parcelas/${parcelaId}/estornar`,
		null,
		null,
		exibirToast(onSuccess, 'Recebimento estornado com sucesso'),
		onError
	);
}

export async function asyncPostAtualizarSituacaoOrcamentoOrdemServico(id, data, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/ordem_servico/${id}/atualizar_situacao_orcamento`, data, null, onSuccess, onError);
}
