import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { buscarDocumentosAssinados } from './Requests';
import { ProgressBar } from 'primereact/progressbar';
import { format, parseISO } from 'date-fns';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import { Link } from 'react-router-dom';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { Documentos } from '../../../../../../Util/constantes';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';

import './Styles/index.css';

const Divider = () => {
  return <hr id="divider-documentos-assinatura-periodo" />;
};

const List = props => {
  return (
    <div id="documentos-periodo" className="dashboard-scroll">
      <ul style={{ listStyle: 'none', padding: '0px 3px', margin: '0px' }}>{props.children}</ul>
    </div>
  );
};

const ListItem = props => {
  let titleDocumento = 'Ir para o documento ' + (props.identificacao ? props.identificacao : '');

  return (
    <li style={{ margin: '6px 0px' }}>
      <div className="p-grid p-align-center" style={{ margin: '0px' }}>
        <Link className="p-col-4" to={{ pathname: '/documentos/assinatura/cadastro/' + props.docId }}>
          <div className="link_to" style={{ display: 'flex', alignItems: 'center' }}>
            <span
              id="documento-identificacao-title"
              title={titleDocumento}
              style={{ color: `${Documentos.cores.azul}` }}
            >
              {props && props.identificacao ? props.identificacao : 'Nenhuma descrição informada'}
            </span>
          </div>
        </Link>
        <div className="documento-pessoa-assinado-periodo">
          <span id="documento-pessoa">
            {props && props.nomePessoa ? props.nomePessoa : 'Nenhuma pessoa informada '}
          </span>
          <p className="p-col-4" style={{ margin: '6px 0px', textAlign: 'end' }}>
            {`Assinado em ${format(parseISO(props.dataAssinatura), 'dd/MM/yyyy')}`}
          </p>
        </div>
      </div>
    </li>
  );
};

export default function DocumentosAssinadosPeriodo(props) {
  const { dataInicial, dataFinal, dataAtualizacaoMenuLateral } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [documentosAssinados, setDocumentosAssinados] = useState([]);
  const afterFirstRenderRef = useRef(false);
  const prevProps = usePrevious(props);

  useEffect(() => {
    carregarInformacoes();
  }, []);

  useEffect(() => {
    buscarDocumentosAssinados(
      dataInicial,
      dataFinal,
      ({ data: documentosAssinados }) => {
        setDocumentosAssinados(documentosAssinados);
        setLoading(false);
        setError(false);
      },
      () => {
        setLoading(false);
        setError(true);
      }
    );
  }, [loading, dataAtualizacaoMenuLateral]);

  useEffect(() => {
    if (afterFirstRenderRef) {
      if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
        carregarInformacoes();
      }
    } else afterFirstRenderRef = true;
  }, [dataInicial, dataFinal]);

  function carregarInformacoes(exibirLoading = true) {
    setLoading(exibirLoading);
    setError(false);
  }

  function getHeaderCard() {
    return (
      <div id="card-header-documentos-assinados-periodo">
        <label title="Lista os documentos assinados no período">Documentos assinados no período</label>
      </div>
    );
  }

  if (loading) {
    return (
      <Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
        <ProgressBar mode="indeterminate" style={{ height: '3px' }} />
      </Card>
    );
  } else if (error) {
    return (
      <Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
        <FalhaAoCarregarAsInformacoes />
      </Card>
    );
  } else {
    return (
      <>
        <Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
          {documentosAssinados.length > 0 ? (
            <List>
              {documentosAssinados.map((documento, idx, array) => {
                return (
                  <React.Fragment key={idx}>
                    <ListItem
                      docId={documento.docId}
                      dataAssinatura={documento.dataAssinatura}
                      identificacao={documento.identificacao}
                      nomePessoa={documento.nomePessoa}
                    />
                    {++idx !== array.length ? <Divider /> : null}
                  </React.Fragment>
                );
              })}
            </List>
          ) : (
            <NenhumaInformacaoEncontrada />
          )}
        </Card>
      </>
    );
  }
}
