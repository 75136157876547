import { DATA_ACTION_TYPES } from './actions';

export function dataReducer(state, action) {
  switch (action.type) {
    case DATA_ACTION_TYPES.ON_CHANGE_VALOR_PESQUISA: {
      return {
        ...state,
        valorPesquisa: action.payload.content,
      };
    }
    case DATA_ACTION_TYPES.SET_TOTAL_RECORDS: {
      return {
        ...state,
        totalRecords: action.payload.content,
      };
    }
    case DATA_ACTION_TYPES.SET_REGISTROS: {
      return {
        ...state,
        registros: action.payload.content,
      };
    }
    case DATA_ACTION_TYPES.SET_SIZE: {
      return {
        ...state,
        size: action.payload.content,
      };
    }
    case DATA_ACTION_TYPES.SET_ROWS: {
      return {
        ...state,
        rows: action.payload.content,
      };
    }
    case DATA_ACTION_TYPES.SET_PAGE: {
      return {
        ...state,
        page: action.payload.content,
      };
    }
    case DATA_ACTION_TYPES.SET_SORT_FIELD: {
      return {
        ...state,
        sortField: action.payload.content,
      };
    }
    case DATA_ACTION_TYPES.SET_SORT_ORDER: {
      return {
        ...state,
        sortOrder: action.payload.content,
      };
    }
    default:
      return state;
  }
}
