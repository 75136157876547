import { useState, useEffect, useRef } from 'react';
import { buscarResultadoFinanceiro } from './Requests';
import Col from '../../../../../../../components/Col';
import CardReceitas from './components/CardReceitas';
import CardDespesas from './components/CardDespesas';
import CardBalanco from './components/CardBalanco';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';

export default function GraficosDosResultadosFinanceiros(props) {
	const { dataInicial, dataFinal, isMobile, atualizarGraficoNovamente } = props;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [receitasRecebidas, setReceitasRecebidas] = useState(0);
	const [receitasAReceber, setReceitasAReceber] = useState(0);
	const [receitasTotal, setReceitasTotal] = useState(0);
	const [despesasPagas, setDespesasPagas] = useState(0);
	const [despesasAPagar, setDespesasAPagar] = useState(0);
	const [despesasTotal, setDespesasTotal] = useState(0);
	const [saldoTotal, setSaldoTotal] = useState(0);
	const [saldoRealizado, setSaldoRealizado] = useState(0);
	const [saldoPendente, setSaldoPendente] = useState(0);
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	const styleCards = isMobile ? { margin: '8px', padding: '0.5rem 0em', flex: 'auto' } : { padding: '0.5rem' };

	useEffect(() => {
		carregarInformacoes();
	}, [atualizarGraficoNovamente]);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}

			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;
	}, [dataInicial, dataFinal]);

	useEffect(() => {
		buscarResultadoFinanceiro(
			dataInicial,
			dataFinal,
			({ data: resultadoFinanceiro }) => {
				setReceitasRecebidas(resultadoFinanceiro.receitasRecebidas);
				setReceitasAReceber(resultadoFinanceiro.receitasAReceber);
				setReceitasTotal(resultadoFinanceiro.receitasTotal);
				setDespesasPagas(resultadoFinanceiro.despesasPagas);
				setDespesasAPagar(resultadoFinanceiro.despesasAPagar);
				setDespesasTotal(resultadoFinanceiro.despesasTotal);
				setSaldoTotal(resultadoFinanceiro.saldoTotal);
				setSaldoRealizado(resultadoFinanceiro.saldoRealizado);
				setSaldoPendente(resultadoFinanceiro.saldoPendente);
				setLoading(false);
				setError(false);
			},
			() => {
				setError(true);
				setLoading(false);
			}
		);
	}, [loading, atualizarGraficoNovamente]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	return (
		<>
			<Col xs="12" sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardReceitas
					receitasRecebidas={receitasRecebidas}
					receitasAReceber={receitasAReceber}
					receitasTotal={receitasTotal}
					loading={loading}
					error={error}
				/>
			</Col>
			<Col xs="12" sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardDespesas
					despesasPagas={despesasPagas}
					despesasAPagar={despesasAPagar}
					despesasTotal={despesasTotal}
					loading={loading}
					error={error}
				/>
			</Col>
			<Col xs="12" sm="12" md="4" lg="4" xl="4" style={styleCards}>
				<CardBalanco
					saldoTotal={saldoTotal}
					saldoRealizado={saldoRealizado}
					saldoPendente={saldoPendente}
					loading={loading}
					error={error}
				/>
			</Col>
		</>
	);
}
