import { estadosCadastro, formatarMonetario } from 'Common';
import { Dropdown, InputDouble } from 'components';
import { Field, useFormikContext } from 'formik';
import {
	PERIODICIDADE_DROPDOWN,
	STYLE_DESCRICAO_PAGAMENTO,
	TIPO_CONTA_PAGAR,
} from 'views/financas/ContasPagar/Util/constantes';
import { CONDICAO_PAGAMENTO_TAXA_TIPO, helpMessage } from '../../Util/constantes';

function FormMultiplasParcelas({ informacoesPermissoes }) {
	const { values, setFieldValue } = useFormikContext();

	function buscarValor() {
		if (values.tipo === TIPO_CONTA_PAGAR.VALOR_PARCELADO) {
			return Math.trunc((values.valor / values.quantidadeRepeticoes) * 100) / 100;
		} else {
			return values.valor;
		}
	}

	function onBlurQuantidadeRepeticoes() {
		if (
			values.tipo === TIPO_CONTA_PAGAR.VALOR_PARCELADO &&
			values.tipoTaxa === CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL &&
			values.taxaAliquota
		) {
			const valor = values.valor / values.quantidadeRepeticoes;
			setFieldValue('taxaValor', (valor * values.taxaAliquota) / 100);
		}
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
			<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'end' }}>
				<Field
					col="6"
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={InputDouble}
					decimalScale={0}
					disabled={informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO}
					label={values.tipo === TIPO_CONTA_PAGAR.VALOR_FIXO ? 'Repetir por' : 'Parcelado em'}
					name="quantidadeRepeticoes"
					value={values.quantidadeRepeticoes}
					helpMessage={
						values.tipo === TIPO_CONTA_PAGAR.VALOR_FIXO
							? helpMessage.quantidadeRepeticoesValorFixo
							: helpMessage.quantidadeRepeticoesParcelas
					}
					size={2}
					onChange={(e) => {
						setFieldValue('quantidadeRepeticoes', e.target.value);
						setFieldValue('valorPago', buscarValor());
					}}
					onBlur={onBlurQuantidadeRepeticoes}
					allowNegative={false}
					{...informacoesPermissoes}
				/>

				<Field
					col="6"
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={Dropdown}
					colStyle={{ paddingTop: '24px' }}
					autoFocus
					disabled={informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO}
					value={values.ocorrencia}
					showClear={false}
					options={PERIODICIDADE_DROPDOWN}
					isSearchableOnMobile={false}
					onChange={(e) => setFieldValue('ocorrencia', e.value || null)}
					{...informacoesPermissoes}
				/>
			</div>
			<div>
				<p style={STYLE_DESCRICAO_PAGAMENTO}>
					Serão geradas {values.quantidadeRepeticoes} contas a pagar de <b>{formatarMonetario(buscarValor())}</b>
				</p>
				<p style={{ ...STYLE_DESCRICAO_PAGAMENTO, fontSize: '12px' }}>
					Em caso de divisão não exata, a sobra será somada à última parcela.
				</p>
			</div>
		</div>
	);
}

export { FormMultiplasParcelas };
