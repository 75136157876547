import { format } from 'date-fns';

function inutilizacaoFormParaApi(values) {
	let inutilizacao = {
		serie: values.serie ?? null,
		numeroInicial: values.numeroInicial ?? null,
		numeroFinal: values.numeroFinal ?? null,
		justificativa: values.justificativa ?? null,
		dataInutilizacao: new Date(),
		usuarioEmail: values.email ?? null,
		usuarioNome: values.nome ?? null,
		situacao: 'PENDENTE',
	};

	return inutilizacao;
}

function inutilizacaoApiParaForm(values) {
	let inutilizacao = {
		ano: values.ano ?? null,
		serie: values.serie ?? null,
		numeroInicial: values.numeroInicial ?? null,
		numeroFinal: values.numeroFinal ?? null,
		justificativa: values.justificativa ?? null,
		dataInutilizacao: format(new Date(values.dataInutilizacao), 'dd/MM/yyyy hh:mm:ss') ?? null,
		usuario: values.usuarioNome + ` (${values.usuarioEmail})`,
		situacao: values.situacao ?? null,
	};

	return inutilizacao;
}

export { inutilizacaoFormParaApi, inutilizacaoApiParaForm };
