import propTypes from "prop-types";
import Col from "../../../../Col";
import {
  buscarDisabledDeAcordoComAsPermissoes,
  buscarHiddenDeAcordoComAsPermissoes,
  buscarTitleCampoDeAcordoComAsPermissoes,
} from "../../../../../Common/Autorizacao/ManipulacaoDeComponentes";
import {
  renderizarValidacao,
  buscarClassNamePelosErros,
} from "../../../../../Common/TratamentoDeErro/validacoesDeCampos";
import { InputText } from "primereact/inputtext";

export function InternalInputDate(props) {
  const {
    sm,
    md,
    lg,
    xl,
    podeVisualizar,
    podeInserir,
    podeEditar,
    estadoCadastro,
    disabled,
    title,
    id,
    name,
    errors,
    touched,
    colId,
    className,
    col,
    style,
    obrigatorio,
    label,
    onBlur,
  } = props;

  function onChange(e) {
    if (e.target.value && e.target.value.length > 10) {
      e.preventDefault();
    } else {
      props.onChange(e);
    }
  }

  function getColStyle() {
    if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden))
      return { display: "none", ...props.colStyle };
    return props.colStyle;
  }

  function montarLabel() {
    if (obrigatorio) {
      return (
        <label title={props.helpMessage}>
          {" "}
          {label} <b style={{ fontSize: "18px", lineHeight: "5px" }}> *</b>{" "}
        </label>
      );
    }
    return <label title={props.helpMessage}> {label} </label>;
  }

  function getValue() {
    if (!props.value) {
      return "";
    }

    if (props.value.length > 10) {
      return props.value.substring(0, 10);
    }
    return props.value;
  }

  const value = getValue();

  return (
    <Col
      className="p-fluid"
      id={colId}
      col={col}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      style={getColStyle()}
    >
      {montarLabel()}

      <InputText
        title={buscarTitleCampoDeAcordoComAsPermissoes(
          podeVisualizar,
          podeInserir,
          podeEditar,
          estadoCadastro,
          title
        )}
        id={id}
        type="date"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={buscarClassNamePelosErros(className, errors, touched)}
        disabled={buscarDisabledDeAcordoComAsPermissoes(
          podeInserir,
          podeEditar,
          estadoCadastro,
          disabled
        )}
        style={{ borderRadius: "3px", height: "32px", ...style }}
        placeholder="aaaa-mm-dd"
        autoComplete="off"
      />
      {renderizarValidacao(errors, touched)}
    </Col>
  );
}

InternalInputDate.propTypes = {
  /** Evento disparado ao modificar o componente do componente */
  onChange: propTypes.func,
  /** Label do componente */
  label: propTypes.string,
  /** Identificador do componente */
  id: propTypes.string,
  /** Identificador da coluna do */
  colId: propTypes.string,
  /** Valor do componente do componente */
  value: propTypes.any,
  /** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
  errors: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  /** Especifica se o componente foi 'tocado'*/
  touched: propTypes.any,
  /** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
  col: propTypes.string,
  /** Tamanho do campo em small devices*/
  sm: propTypes.string,
  /** Tamanho do campo em medium devices*/
  md: propTypes.string,
  /** Tamanho do campo em large devices*/
  lg: propTypes.string,
  /** Tamanho do campo em extra large devices*/
  xl: propTypes.string,
  /** Estilo da coluna*/
  colStyle: propTypes.object,
  /** Estado em que o cadastro se encontra*/
  estadoCadastro: propTypes.string,
  /** Diz se o usuário possui permissão de visualizar*/
  podeVisualizar: propTypes.bool,
  /** Diz se o usuário possui permissão de editar*/
  podeEditar: propTypes.bool,
  /** Diz se o usuário possui permissão de excluir*/
  podeInserir: propTypes.bool,
  /** Title do componente*/
  title: propTypes.string,
  /** Esconde o componente*/
  hidden: propTypes.bool,
  /** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
  helpMessage: propTypes.string,
};
