import { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { useFormikContext } from 'formik';

import { baixarArquivo } from 'Common';
import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	NormalButton,
	ToastTypes,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	notify,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { INITIAL_VALUE } from '../../Util/constantes';
import { converterEtiquetaParaApi, converterEtiquetaParaNovoRegistro } from '../../Util/EtiquetaConverter';

import { asyncCreateEtiqueta, asyncDeleteEtiqueta, asyncGetEtiqueta, asyncUpdateEtiqueta } from '../../../Request';
import { useContextEtiquetaConfiguracao } from '../../Context';

const PESQUISA_URL = '/produtos/etiquetas/configuracao';

function ActionButtons({ informacoesPermissoes, history }) {
	const { podeInserir } = useContextEtiquetaConfiguracao();
	const { values, dirty, handleReset, resetForm, handleSubmit, validateForm, setValues } = useFormikContext();
	const menuOptions = useRef(null);

	const itemsOptions = getItemsOptions();

	function novaEtiqueta() {
		atualizarUrl(history, '/produtos/etiquetas/configuracao/cadastro');
		resetForm({ values: INITIAL_VALUE });
	}

	async function salvarEtiqueta(callback) {
		handleSubmit();

		if (await validarFormulario({ values: values, validateForm })) {
			const convertedData = converterEtiquetaParaApi(values);
			if (values.id) {
				await updateEtiqueta(convertedData, callback);
			} else {
				await createEtiqueta(convertedData, callback);
			}
		}
	}

	async function updateEtiqueta(convertedValues, callback) {
		await asyncUpdateEtiqueta(convertedValues, ({ data: response }) => {
			if (callback !== undefined && typeof callback === 'function') {
				callback();
			} else {
				resetForm({ values: { ...values, ...response } });
			}
		});
	}

	async function createEtiqueta(convertedValues, callback) {
		await asyncCreateEtiqueta(convertedValues, ({ data: response }) => {
			atualizarUrl(history, '/produtos/etiquetas/configuracao/cadastro', response.id);
			if (callback !== undefined && typeof callback === 'function') {
				callback();
			} else {
				resetForm({ values: { ...values, ...response } });
			}
		});
	}

	function handleClickVoltarCancelar() {
		dirty ? handleReset() : voltarParaAPesquisa(history, PESQUISA_URL);
	}

	function handleClickSalvar() {
		salvarEtiqueta();
	}

	function handleClickNovo() {
		if (dirty) {
			salvarEtiqueta(novaEtiqueta);
		} else {
			novaEtiqueta();
		}
	}

	function handleClickExcluir() {
		confirm('Atenção', 'Tem certeza que deseja excluir?', async () => {
			await asyncDeleteEtiqueta(values.id, 'Etiqueta excluída com sucesso', () => {
				voltarParaAPesquisa(history, '/produtos/etiquetas/configuracao');
			});
		});
	}

	async function handleClickDuplicar() {
		await asyncGetEtiqueta(values.id, ({ data: etiqueta }) => {
			atualizarUrl(history, '/produtos/etiquetas/configuracao/cadastro', null, metodosAtualizarUrl.POP);

			setValues(converterEtiquetaParaNovoRegistro(etiqueta));
		});
	}

	async function handleClickExportar() {
		await asyncGetEtiqueta(values.id, ({ data: etiqueta }) => {
			baixarArquivo(
				new Blob([JSON.stringify(converterEtiquetaParaNovoRegistro(etiqueta))]),
				`${etiqueta.descricao.replaceAll(' ', '-')}.msys_gestor_etiqueta.json`,
				'application/json'
			);
		});
	}

	function handleClickImportar() {
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = 'application/json';
		input.onchange = (e) => {
			const file = e.target.files[0];

			if (file.type === 'application/json') {
				const reader = new FileReader();
				reader.readAsText(file, 'UTF-8');

				reader.onload = async (readerEvent) => {
					setValues(JSON.parse(readerEvent.target.result));
				};
			} else {
				notify('O arquivo selecionado não é um arquivo JSON válido.', ToastTypes.ERROR);
			}
		};
		input.click();
	}

	function getItemsOptions() {
		const items = [];

		items.push({
			label: 'Duplicar',
			icon: 'pi pi-copy',
			visible: !!values.id,
			disabled: !podeInserir || dirty,
			command: handleClickDuplicar,
		});

		items.push({
			label: 'Exportar',
			icon: 'pi pi-upload',
			visible: !!values.id,
			disabled: dirty,
			command: handleClickExportar,
		});

		items.push({
			label: 'Importar',
			icon: 'pi pi-download',
			visible: !values.id,
			disabled: !podeInserir || dirty,
			command: handleClickImportar,
		});

		return items.filter((e) => !!e.visible);
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={handleClickVoltarCancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar disabled={!dirty} onClick={handleClickSalvar} {...informacoesPermissoes} />
			<ButtonNovo
				onClick={() => handleClickNovo()}
				hidden={!dirty && !values.id}
				estadoBotao={dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir hidden={!values.id} onClick={handleClickExcluir} {...informacoesPermissoes} />
			<NormalButton
				type="button"
				label="Opções"
				icon="fa fa-list"
				onClick={(event) => menuOptions.current.toggle(event)}
			/>
			<Menu model={itemsOptions} style={{ minWidth: '180px' }} popup ref={menuOptions} />
		</>
	);
}

export { ActionButtons };
