import { formatarMonetario } from 'Common';
import { If } from 'components';
import TituloCard from 'components/TituloCard';
import { ProgressBar } from 'primereact/progressbar';
import { Fragment } from 'react';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import { SERVICOS_CORES } from '../../Util/constantes';

const cardStyle = {
	padding: '7px',
	borderRadius: '5px',
	backgroundColor: 'white',
	boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
	minHeight: '100%',
};

const cardContent = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
};

const servicoStyle = {
	fontSize: '15px',
	fontWeight: '400',
	lineHeight: '1.5',
	color: '#000000de',
};

const valorStyle = {
	display: 'flex',
	justifyContent: 'flex-end',
	fontSize: '15px',
	fontWeight: '600',
	color: SERVICOS_CORES.verde,
	padding: '0px',
	marginLeft: '5px',
};

const hrStyle = {
	width: '100%',
	border: 'none',
	height: '1px',
	margin: '0',
	flexShrink: '0',
	backgroundColor: '#0000001f',
	marginBottom: '7px',
};

function CardList({ title, helpMessage, data, visibleButtonVerMais, onClickVerMais, page, loading }) {
	const emptyList = data?.length === 0;

	function handleClickVerMais() {
		onClickVerMais(page + 1);
	}

	return (
		<div style={cardStyle}>
			<TituloCard title={title} helpMessage={helpMessage} />
			<If test={loading}>
				<div style={cardContent}>
					<ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />
				</div>
			</If>

			<If test={emptyList}>
				<div style={cardContent}>
					<NenhumaInformacaoEncontrada />
				</div>
			</If>

			<If test={!loading && !emptyList}>
				<div
					className="dashboard-scroll"
					style={{
						padding: '15px 15px 0px 15px',
						maxHeight: '280px',
						overflow: 'auto',
						marginTop: '10px',
						maxWidth: '100%',
					}}
				>
					{data &&
						data.map((item, index) => (
							<Fragment key={item.id}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										paddingBottom: '7px',
									}}
								>
									<span style={servicoStyle}>
										<b>{index + 1}º</b> {item.nome}
									</span>
									<span style={valorStyle}>{formatarMonetario(item.valorFinalizados)}</span>
								</div>
								<hr style={hrStyle} />
							</Fragment>
						))}
					<If test={visibleButtonVerMais}>
						<div style={{ textAlign: 'center', padding: '0.5rem 0px' }}>
							<span onClick={handleClickVerMais} style={{ color: `${SERVICOS_CORES.azul}`, cursor: 'pointer' }}>
								Ver mais
							</span>
						</div>
					</If>
				</div>
			</If>
		</div>
	);
}

export { CardList };
