/* eslint-disable no-unused-vars */
import { copiarObjeto, parseFloatNumber, services } from 'Common';
import {
	Col,
	Grid,
	InputDouble,
	InternalTextArea,
	SingleSelectConta,
	SingleSelectFormaPagamento,
	validarValorNegativo,
} from 'components';
import { addMonths, formatISO, isValid, parseISO } from 'date-fns';
import { Field, useFormikContext } from 'formik';
import { OverlayPanel } from 'primereact/overlaypanel';
import { FaTimes } from 'react-icons/fa';
import { MdDescription } from 'react-icons/md';
import { useRef } from 'react';
import { useContextNFCe } from '../../../../../../../Context';

import VencimentoMemorizado from './components/VencimentoMemorizado';

const styleNumeracaoDefault = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '16px',
	width: '30px',
};

function Parcelas(props) {
	const { isMobile, index, exibirLabels, labelParcela, parcela, disabled, isFullScreen } = props;
	const { informacoesPermissoes, podeInserir } = useContextNFCe();
	const { values, errors, setFieldValue } = useFormikContext();
	const panelDetalhes = useRef(null);

	const excluirDisabled = values.parcelas?.length === 1 || !informacoesPermissoes.podeEditar || !podeInserir;

	function onChangeVencimento(value) {
		let parcelasTemp = copiarObjeto(values.parcelas);
		if (index === 0 && isValid(parseISO(value))) {
			parcelasTemp = parcelasTemp.map((parcela, index) => ({
				...parcela,
				vencimento: formatISO(addMonths(parseISO(value), index)),
			}));
			setFieldValue('parcelas', parcelasTemp);
		} else {
			parcelasTemp[index] = { ...parcelasTemp[index], vencimento: value };
			setFieldValue('parcelas', parcelasTemp);
		}
	}

	async function onChangeValor(value) {
		recalcularRateioParcela(value, index);
	}

	function handleChangeFormaPagamento(value) {
		setFieldValue(`parcelas[${index}].formaPagamento`, value);
	}

	function handleChangeConta(value) {
		setFieldValue(`parcelas[${index}].conta`, value);
	}

	function onChangeDescricao(event) {
		setFieldValue(`parcelas[${index}].descricao`, event.target.value);
	}

	function onClickExcluir() {
		if (!excluirDisabled) {
			let parcelasTemp = copiarObjeto(values.parcelas);

			parcelasTemp = values.parcelas.filter((p, i) => i !== index);

			setFieldValue('parcelas', parcelasTemp);
			setFieldValue('quantidadeParcelas', values.quantidadeParcelas - 1);
		}
	}

	function recalcularRateioParcela(value, index) {
		let parcelasTemp = copiarObjeto(values.parcelas);
		parcelasTemp[index].valor = value;

		const valorParcelasAnteriores = valorSomadoDasParcelasAnteriores(parcelasTemp, index);
		const valorASerRatiado = parseFloatNumber(values.valor - (valorParcelasAnteriores + value));
		const quantidadeParcelasRestantes = parcelasTemp.length - 1 - index;
		const valorRatiadoParaCadaParcelaRestante = parseFloatNumber(valorASerRatiado / quantidadeParcelasRestantes);

		let somaDasParcelas = 0;

		parcelasTemp = parcelasTemp.map((parcela, indexParcela) => {
			let { valor } = parcela;

			if (indexParcela > index) {
				somaDasParcelas += valorRatiadoParaCadaParcelaRestante;
				if (indexParcela === values.parcelas.length - 1) {
					valor = validarValorNegativo(
						parseFloatNumber(valorRatiadoParaCadaParcelaRestante + (values.valor - somaDasParcelas))
					);
				} else {
					valor = validarValorNegativo(valorRatiadoParaCadaParcelaRestante);
				}
			} else {
				somaDasParcelas += parcela.valor;
			}

			return { ...parcela, valor };
		});

		setFieldValue('parcelas', parcelasTemp);
	}

	function valorSomadoDasParcelasAnteriores(parcelas, index) {
		let valorParcelasAnteriores = 0.0;
		for (let i = 0; i < parcelas.length; i++) {
			if (i < index) {
				valorParcelasAnteriores += parcelas[i].valor;
			}
		}
		return valorParcelasAnteriores;
	}

	return (
		<Grid
			style={{
				margin: '0px -15px',
				background: isMobile && index % 2 === 0 ? '#f1f1f1' : null,
				paddingTop: '7px',
				paddingBottom: errors ? '10px' : undefined,
			}}
		>
			<Col sm="12" md="3" lg="3" xl="3">
				<Grid>
					<Col
						style={{
							...styleNumeracaoDefault,
							padding: '2px 0px 0px 10px',
							margin: exibirLabels ? '10px 0px 0px 0px' : '0px 0px 10px 0px',
						}}
					>
						{labelParcela}
					</Col>
					<VencimentoMemorizado
						colStyle={{
							width: 'calc(100% - 30px)',
							paddingLeft: isMobile ? '0px' : undefined,
							paddingRight: isMobile ? '0px' : undefined,
						}}
						exibirLabels={exibirLabels}
						onChangeVencimento={(event) => onChangeVencimento(event.target.value)}
						valueVencimento={parcela.vencimento}
						errors={errors}
						isMobile={isMobile}
						disabled={disabled}
						{...informacoesPermissoes}
					/>
				</Grid>
			</Col>
			<InputDouble
				sm="12"
				md="2"
				lg="2"
				xl="2"
				label={exibirLabels ? 'Valor' : ''}
				helpMessage={exibirLabels && !isMobile ? 'Valor da parcela' : ''}
				name="valor"
				size={13}
				obrigatorio={exibirLabels}
				onChange={(event) => onChangeValor(event.target.value)}
				value={parcela.valor}
				placeholder="R$ 0,00"
				prefix="R$ "
				errors={errors && errors.valor}
				touched
				allowNegative={false}
				disabled={disabled}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				name="formaPagamento"
				helpMessage="Forma de pagamento"
				label={exibirLabels && !isMobile ? 'Forma de pagamento' : null}
				component={SingleSelectFormaPagamento}
				value={parcela.formaPagamento}
				onChange={(event) => handleChangeFormaPagamento(event)}
				url={`${services.GESTOR}/v1/nfce/relacoes/formas_pagamento`}
				disabled={!podeInserir}
				obrigatorio={exibirLabels}
				esconderBotao
				isClearable={false}
				touched
				errors={errors && errors.formaPagamento}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				name="conta"
				label={exibirLabels && !isMobile ? 'Conta' : null}
				helpMessage="Conta"
				placeholder="Pesquisar"
				component={SingleSelectConta}
				value={parcela.conta}
				onChange={(event) => handleChangeConta(event)}
				url={`${services.GESTOR}/v1/nfce/relacoes/contas`}
				disabled={!podeInserir}
				obrigatorio={exibirLabels}
				esconderBotao
				isClearable={false}
				touched
				errors={errors && errors.conta}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<Col
				sm="12"
				md="1"
				lg="1"
				xl="1"
				style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '0px' }}
			>
				<div>
					<OverlayPanel
						style={isMobile ? { right: '12px' } : null}
						ref={panelDetalhes}
						showCloseIcon
						appendTo={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
					>
						<InternalTextArea
							helpMessage="Detalhes gerais da parcela"
							type="text"
							label="Detalhes da parcela"
							name="descricao"
							value={parcela.descricao}
							size={200}
							onChange={onChangeDescricao}
							disabled={disabled}
							{...informacoesPermissoes}
						/>
					</OverlayPanel>
					<button
						onClick={(e) => panelDetalhes.current.toggle(e)}
						style={{
							marginTop: exibirLabels && !isMobile ? '20px' : '0px',
							padding: '0px',
							width: '30px',
							height: '30px',
							background: 'none',
							border: 'none',
							cursor: 'pointer',
						}}
						type="button"
					>
						<MdDescription title="Detalhes da parcela" size={30} style={{ color: '#316095', padding: '5px' }} />
					</button>
				</div>
				<div>
					<button
						onClick={(e) => (excluirDisabled ? null : onClickExcluir(e))}
						style={{
							color: '#e91224',
							width: '30px',
							height: '30px',
							marginTop: exibirLabels && !isMobile ? '22px' : '5px',
							background: 'none',
							border: 'none',
							padding: '0px',
						}}
						disabled={excluirDisabled}
						type="button"
					>
						<FaTimes
							size={20}
							title={excluirDisabled ? 'Você não pode executar esta ação' : 'Excluir item'}
							style={{
								color: '#e91224',
								width: '20px',
								height: '20px',
								opacity: excluirDisabled ? '0.5' : '1',
								cursor: excluirDisabled ? 'default' : 'pointer',
							}}
						/>
					</button>
				</div>
			</Col>
		</Grid>
	);
}

export default Parcelas;
