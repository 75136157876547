import { DATA_ACTION_TYPES } from './actions';

export function dataReducer(state, action) {
	switch (action.type) {
		case DATA_ACTION_TYPES.SET_DATA: {
			return {
				...state,
				...action.payload,
			};
		}
		case DATA_ACTION_TYPES.ADD_RANKING_MOTORISTAS: {
			return {
				...state,
				rankingMotoristas: {
					...action.payload,
					content: [...state.rankingMotoristas.content, ...action.payload.content],
				},
			};
		}
		case DATA_ACTION_TYPES.ADD_RANKING_VEICULOS: {
			return {
				...state,
				rankingVeiculos: {
					...action.payload,
					content: [...state.rankingVeiculos.content, ...action.payload.content],
				},
			};
		}
		case DATA_ACTION_TYPES.ADD_RANKING_ESTADOS: {
			return {
				...state,
				rankingEstados: {
					...action.payload,
					content: [...state.rankingEstados.content, ...action.payload.content],
				},
			};
		}
		default:
			return state;
	}
}
