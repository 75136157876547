import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { formatCPF } from '@brazilian-utils/brazilian-utils';

import { construirUrl } from 'Common';
import { ButtonEditarTable, ButtonNovo, Modal, NenhumRegistroEncontrado, Paginacao } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { readCondutoresIndicadosMDFe } from 'views/transporte/MDFe/Requests';
import { MDFE_SITUACAO } from 'views/transporte/MDFe/Form/Util/constantes';

import { ModalDadosCondutorIndicado } from './components/ModalDadosCondutorIndicado';

function ModalCondutorIndicado({ visible, onHide }) {
	const { values } = useFormikContext();
	const { informacoesPermissoes } = useContextMDFe();

	const [registros, setRegistros] = useState([]);
	const [registroSelecionado, setRegistroSelecionado] = useState({});
	const [sortOrder, setSortOrder] = useState(-1);
	const [sortField, setSortField] = useState('sequenciaEvento');
	const [totalElements, setTotalElements] = useState(-1);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState(10);
	const [visibleModalDados, setVisibleModalDados] = useState(false);

	const isTransmitido = Boolean(values.situacao === MDFE_SITUACAO.TRANSMITIDO);

	useEffect(() => {
		fetchCondutoresIndicados();
	}, [rows, page]);

	async function fetchCondutoresIndicados() {
		await readCondutoresIndicadosMDFe(
			values.id,
			construirUrl('', '?', rows, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`),
			({ data: condutores }) => {
				setRegistros(condutores.content);
				setTotalElements(condutores.totalElements);
			}
		);
	}

	function handleClickSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function handleChangePage(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function handleClickVerDados(row) {
		setRegistroSelecionado(row);
		setVisibleModalDados(true);
	}

	function renderOptions(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable
					className="p-button p-button-primary"
					icon="fa fa-eye"
					title="Visualizar dados"
					onClick={() => handleClickVerDados(row)}
				/>
			</div>
		);
	}

	function handleClickNovo() {
		setVisibleModalDados(true);
	}

	function onHideModalDados(refresh = false) {
		setVisibleModalDados(false);
		setRegistroSelecionado({});
		if (refresh) {
			setPage(0);
			fetchCondutoresIndicados();
		}
	}

	return (
		<Modal header="Condutores indicados" visible={visible} onHide={onHide} styleModal={{ maxWidth: '750px' }}>
			<div>
				<ButtonNovo
					label="Indicar condutor"
					onClick={handleClickNovo}
					disabled={!isTransmitido}
					{...informacoesPermissoes}
				/>
			</div>
			<DataTable
				className="table"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				responsive
				value={registros}
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={handleClickSort}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					field="sequenciaEvento"
					header="Sequencial"
					body={(row) => row.sequenciaEvento}
					style={{ width: '15%' }}
					sortable
				/>
				<Column field="nome" header="Nome" body={(row) => row.nome} style={{ width: '34%' }} sortable />
				<Column field="cpf" header="CPF" body={(row) => formatCPF(row.cpf)} style={{ width: '19%' }} sortable />
				<Column className="step-listagem-acoes" body={renderOptions} header="Ações" style={{ width: '7%' }} />
			</DataTable>
			<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={handleChangePage} />
			<ModalDadosCondutorIndicado
				visible={visibleModalDados}
				onHide={onHideModalDados}
				values={registroSelecionado}
				canTransmitir={Object.keys(registroSelecionado)?.length === 0}
				proximoSequencial={registros.length + 1}
				id={values.id}
			/>
		</Modal>
	);
}

export { ModalCondutorIndicado };
