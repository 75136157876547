import { manterApenasNumeros } from '../../../../../../../../../Common';

export function converterPessoaContatoParaApi(values) {
	return {
		...values,
		telefone: manterApenasNumeros(values.telefone),
	};
}

export function converterPessoaContatoParaFormulario(values) {
	return {
		...values,
		telefone: manterApenasNumeros(values.telefone),
	};
}
