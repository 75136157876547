import { get, services } from 'Common';
import { put } from '../../../../../../Common/Requisicoes';

export async function asyncGetFilialConectada(onSuccess) {
	await get(`${services.GESTOR}/v1/filiais/conectada`, null, onSuccess);
}

export async function asyncPutFilial(id, filial, onSuccess) {
	await put(`${services.GESTOR}/v1/filiais/${id}`, filial, null, onSuccess);
}
