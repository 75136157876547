export function converterParaFormulario(values) {
	return {
		email: values.email,
		tipo: values.tipo,
		pessoaFisica: {
			cpf: values.cpf,
		},
		pessoaJuridica: {
			cnpj: values.cnpj,
		},
		idEstrangeiro: values.idEstrangeiro,
		indicadorInscricaoEstadual: values.indicadorInscricaoEstadual,
		inscricaoEstadual: values.inscricaoEstadual,
		destinoOperacao: values.destinoOperacao,
		consumidorFinal: values.consumidorFinal ?? null,
		enderecos: converterEnderecosParaFormulario(values),
	};
}

function converterEnderecosParaFormulario(values) {
	let enderecos = values.registro?.enderecos ?? values.enderecos ?? [];

	if (Array.isArray(enderecos)) {
		enderecos = enderecos.map((endereco) => {
			if (endereco.id === values.endereco?.id) {
				return {
					...values.endereco,
					municipio: values.endereco?.municipio?.registro,
					pais: values.endereco?.pais?.registro,
				};
			}
			return endereco;
		});
	}

	return enderecos;
}
