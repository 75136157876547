import TextArea from 'components/TextArea/TextArea';
import { useFormikContext } from 'formik';

function Descricao({ disabled, informacoesPermissoes }) {
	const { values, setFieldValue } = useFormikContext();

	return (
		<TextArea
			label="Observações do item"
			name="descricao"
			value={values.descricao}
			disabled={disabled}
			onChange={(e) => setFieldValue('descricao', e.target.value)}
			{...informacoesPermissoes}
		/>
	);
}

export { Descricao };
