import { Field } from 'formik';
import { Grid, TextArea } from '../../../../../../../components';

export default function PessoaObservacoes(props) {
	const { setFieldValue, informacoesPermissoes } = props;
	return (
		<Grid>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={TextArea}
				placeholder="Escreva sua observação aqui"
				name="observacao"
				onChange={e => setFieldValue('observacao', e.target.value)}
				id="PessoaObservacoesTextAreaObservacoes"
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}
