export const Messages = {
	FornecedorNaoCadastrado: nomeFornecedor => {
		return `O fornecedor ${nomeFornecedor} não está cadastrado no sistema. Deseja cadastrar?`;
	},
	DestinatarioDiferenteOrganizacao:
		'CPF/CNPJ do destinatário diferente do cadastrado na filial. Deseja continuar?',
	TransportadorNaoCadastrado: 'Transportador não cadastrado, verifique!',
	ChaveAcessoCadastrada: 'O XML importado já possui uma nota de compra vinculada.',
	NotaCadastrada:
		'Já existe uma nota cadastrada com o mesmo número, série, modelo e fornecedor ou com a mesma chave de acesso.',
};
