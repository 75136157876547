import { Field } from 'formik';

import {
	InputField,
	InputDate,
	InputMoney,
	Grid,
	MultiSelectProdutos,
	MultiSelectClientes,
	Dropdown,
} from 'components';

import { services, removerCaracteresInvalidosRsql } from 'Common';

export default function FieldsInformacoesGestaoDocumentos(props) {
	const {
		informacoesPermissoes,
		values,
		setFieldValue,
		helpMessage,
		errors,
		isModalAnexo,
		documentoAnexoAssinado,
		anexoDocumentoDisabled,
		statusFinalDocumento,
		idFocusInitial,
	} = props;

	const { podeEditarDocumento, estadoCadastro } = informacoesPermissoes;

	const tamanhoCampoMenorQuandoModal = '3';
	const tamanhoCampoMaiorQuandoModal = '6';
	const tamanhoQuandoNaoModal = '2';

	const nameValor = !isModalAnexo ? 'documentoValor' : 'anexoValor';

	const nameValorMensal = !isModalAnexo ? 'documentoValorMensal' : 'anexoValorMensal';

	const valor = !isModalAnexo ? values.documentoValor : values.anexoValor;

	const valorMensal = !isModalAnexo ? values.documentoValorMensal : values.anexoValorMensal;

	const labelValor = !isModalAnexo ? 'Valor do documento' : 'Valor do anexo';

	const fieldDisabled =
		((!isModalAnexo && !podeEditarDocumento) ||
			statusFinalDocumento ||
			documentoAnexoAssinado ||
			anexoDocumentoDisabled) &&
		estadoCadastro !== 'INCLUSAO';

	const helpMessageFieldDisabled = isModalAnexo
		? helpMessage.documentoAssinadoEditar
		: helpMessage.documentoVigenteEditar;

	const mensagemIdentificacao = !isModalAnexo ? helpMessage.identificacao : helpMessage.identificacaoAnexo;
	const mensagemValor = !isModalAnexo ? helpMessage.documentoValor : helpMessage.anexoValor;
	const mensagemValorMensal = !isModalAnexo ? helpMessage.documentoValorMensal : helpMessage.anexoValorMensal;
	const mensagemParte = !isModalAnexo ? helpMessage.parte : helpMessage.parteAnexo;
	const mensagemProdutos = !isModalAnexo ? helpMessage.produto : helpMessage.produtoAnexo;
	const mensagemVigenciaInicio = !isModalAnexo ? helpMessage.vigenciaInicio : helpMessage.vigenciaInicioAnexo;
	const mensagemVigenciaFinal = !isModalAnexo ? helpMessage.vigenciaFinal : helpMessage.vigenciaFinalAnexo;

	function alterarTipo(event) {
		setFieldValue('tipoGestaoDocumento', event.value);
		if (values.tipoGestaoDocumento !== event.value) {
			setFieldValue('arquivos', []);
		}
	}

	return (
		<Grid>
			<div
				style={{ display: 'flex', width: '100%', flexWrap: 'inherit' }}
				className="step-primeiras_informacoes-documento"
			>
				<Field
					className="step-identificador-documento"
					sm="12"
					md="6"
					lg={isModalAnexo ? tamanhoCampoMaiorQuandoModal : '2'}
					xl={isModalAnexo ? '3' : '2'}
					component={InputField}
					label="Identificador"
					name="identificacao"
					obrigatorio
					disabled={fieldDisabled}
					onChange={element => {
						setFieldValue('identificacao', removerCaracteresInvalidosRsql(element.target.value, false));
					}}
					size={50}
					helpMessage={fieldDisabled ? helpMessageFieldDisabled : mensagemIdentificacao}
					id={idFocusInitial}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg={isModalAnexo ? tamanhoCampoMenorQuandoModal : tamanhoQuandoNaoModal}
					xl={isModalAnexo ? tamanhoCampoMenorQuandoModal : tamanhoQuandoNaoModal}
					component={Dropdown}
					label={'Tipo'}
					name="tipoGestaoDocumento"
					value={values.tipoGestaoDocumento}
					onChange={e => alterarTipo(e)}
					options={[
						{ label: 'Contrato', value: 'CONTRATO' },
						{ label: 'Documento', value: 'DOCUMENTO' },
					]}
					obrigatorio
					errors={errors.tipoGestaoDocumento}
					touched={true}
					showClear={false}
					disabled={fieldDisabled || isModalAnexo}
					{...informacoesPermissoes}
				/>
				<Field
					className="step-primeiras_informacoes-documento"
					sm="12"
					md="3"
					lg={isModalAnexo ? tamanhoCampoMenorQuandoModal : tamanhoQuandoNaoModal}
					xl={isModalAnexo ? tamanhoCampoMenorQuandoModal : tamanhoQuandoNaoModal}
					component={InputDate}
					label="Vigência início"
					name="vigenciaInicio"
					obrigatorio
					errors={errors.vigenciaInicio}
					touched={true}
					disabled={fieldDisabled}
					onChange={element => {
						setFieldValue('vigenciaInicio', element.target.value);
					}}
					value={values.vigenciaInicio}
					helpMessage={fieldDisabled ? helpMessageFieldDisabled : mensagemVigenciaInicio}
					{...informacoesPermissoes}
				/>
				<Field
					className="step-primeiras_informacoes-documento"
					sm="12"
					md="3"
					lg={isModalAnexo ? tamanhoCampoMenorQuandoModal : tamanhoQuandoNaoModal}
					xl={isModalAnexo ? tamanhoCampoMenorQuandoModal : tamanhoQuandoNaoModal}
					component={InputDate}
					label="Vigência final"
					name="vigenciaFinal"
					obrigatorio={values.tipoGestaoDocumento === 'CONTRATO'}
					errors={errors.vigenciaFinal}
					touched={true}
					disabled={fieldDisabled}
					onChange={element => {
						setFieldValue('vigenciaFinal', element.target.value);
					}}
					value={values.vigenciaFinal}
					helpMessage={fieldDisabled ? helpMessageFieldDisabled : mensagemVigenciaFinal}
					{...informacoesPermissoes}
				/>
				<Field
					className="step-primeiras_informacoes-documento"
					sm="12"
					md="3"
					lg={isModalAnexo ? tamanhoCampoMaiorQuandoModal : tamanhoQuandoNaoModal}
					xl={isModalAnexo ? tamanhoCampoMaiorQuandoModal : tamanhoQuandoNaoModal}
					component={InputMoney}
					label={labelValor}
					name={nameValor}
					obrigatorio
					disabled={fieldDisabled}
					errors={errors.documentoValor}
					touched={true}
					size={15}
					allowNegative={false}
					onChange={element => {
						setFieldValue(nameValor, element.target.value ?? 0);
					}}
					value={valor}
					helpMessage={fieldDisabled ? helpMessageFieldDisabled : mensagemValor}
					{...informacoesPermissoes}
				/>
				<Field
					className="step-primeiras_informacoes-documento"
					sm="12"
					md="3"
					lg={isModalAnexo ? tamanhoCampoMaiorQuandoModal : tamanhoQuandoNaoModal}
					xl={isModalAnexo ? tamanhoCampoMaiorQuandoModal : tamanhoQuandoNaoModal}
					component={InputMoney}
					label="Valor mensal"
					name={nameValorMensal}
					size={15}
					allowNegative={false}
					disabled={fieldDisabled}
					onChange={element => {
						setFieldValue(nameValorMensal, element.target.value ?? 0);
					}}
					value={valorMensal}
					helpMessage={fieldDisabled ? helpMessageFieldDisabled : mensagemValorMensal}
					{...informacoesPermissoes}
				/>
			</div>
			<div style={{ display: 'flex', width: '100%', flexWrap: 'inherit' }}>
				<Field
					className="step-partes-documento"
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={MultiSelectClientes}
					showClear={false}
					url={`${services.GESTOR}/v1/documentos/gestao_documentos/relacoes/partes`}
					isMulti
					closeMenuOnSelect={false}
					backspaceRemovesValue={false}
					label="Partes"
					name="parte"
					helpMessage={fieldDisabled ? helpMessageFieldDisabled : mensagemParte}
					value={values.partes}
					disabled={fieldDisabled}
					onChange={element => setFieldValue('partes', element)}
					{...informacoesPermissoes}
				/>

				<Field
					className="step-produtos-documento"
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={MultiSelectProdutos}
					showClear={false}
					url={`${services.GESTOR}/v1/documentos/gestao_documentos/relacoes/produtos`}
					parametros={';utilizarDocumentosDigitais=="true"'}
					noOptionsMessage={() =>
						'Os produtos devem ter a opção "Produto utilizado no módulo de documentos" marcada em seu cadastro para que sejam filtrados no Documento.'
					}
					isMulti
					closeMenuOnSelect={false}
					backspaceRemovesValue={false}
					label={`Produtos do ${isModalAnexo ? 'anexo' : 'documento'}`}
					name="produto"
					helpMessage={fieldDisabled ? helpMessageFieldDisabled : mensagemProdutos}
					value={values.produtos}
					disabled={fieldDisabled}
					onChange={element => setFieldValue('produtos', element)}
					{...informacoesPermissoes}
				/>
			</div>
		</Grid>
	);
}
