import { useState } from 'react';
import If from '../../If';
import Checkbox from '../../input/Checkbox';

function CardModuloDetalhado({ title, subtitle, selected, onSelect, value, disabled, content }) {
	const [clicked, setClicked] = useState(false);

	const divStyle = {
		borderRadius: '5px',
		border: '4px solid #c0c0c0',
		height: '100%',
		width: '100%',
		cursor: 'pointer',
	};

	const divStyleDisabled = {
		opacity: '0.5',
		cursor: 'default',
	};

	const titleStyle = {
		color: 'rgb(49 49 49)',
		fontSize: '20px',
		display: 'flex',
		justifyContent: 'center',
		margin: '5px',
		textAlign: 'center',
	};

	const subtitleStyle = {
		color: 'rgb(123, 123, 123)',
		fontWeight: '600',
		margin: '5px',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
	};

	const contentStyle = {
		transition: 'max-height 0.5s ease',
		justifyContent: 'left',
		overflow: 'hidden',
		marginLeft: '15px',
		marginRight: '15px',
		marginTop: '5px',
		marginBottom: '5px',
		textAlign: 'justify',
	};

	let estilo = divStyle;
	estilo = disabled ? { ...estilo, ...divStyleDisabled } : estilo;

	let estiloConteudoCard = clicked ? { maxHeight: '150px' } : { maxHeight: '0px' };

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div style={{ marginRight: '5px' }}>
				<Checkbox name="" onChange={() => !disabled && onSelect && onSelect(value)} checked={selected} />
			</div>

			<div style={estilo} onClick={() => setClicked(!clicked)}>
				<p style={titleStyle}>{title}</p>

				<p style={subtitleStyle}>{subtitle}</p>

				<div style={{ ...estiloConteudoCard, ...contentStyle }}>{content}</div>
				<div style={{ margin: '0px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
					<If test={!clicked}>
						<i
							className="fa fa-chevron-down"
							style={{ alignSelf: 'flex-end', with: '100%', marginRight: '10px', marginBottom: '10px' }}
							title="Recolher conteúdo"
						/>
					</If>
					<If test={clicked}>
						<i
							className="fa fa-chevron-up"
							style={{ alignSelf: 'flex-end', with: '100%', marginRight: '10px', marginBottom: '10px' }}
							title="Expandir conteúdo"
						/>
					</If>
				</div>
			</div>
		</div>
	);
}

export default CardModuloDetalhado;
