import { ToastTypes, notify } from "components";
import {
  asyncGetIsRunningMsysGestorService,
  asyncGetVersaoMsysGestorService,
} from "./Requests";
import { VERSOES } from "Common/Constantes/versoes";

async function validateVersion(showNotify = false) {
  let versionIsValid = false;
  await asyncGetVersaoMsysGestorService(({ data }) => {
    if (data.versao !== VERSOES.GESTOR_SERVICE) {
      if (showNotify) {
        notify(
          "Msys Gestor Service desatualizado, verifique!",
          ToastTypes.ERROR
        );
      }
      versionIsValid = false;
    } else {
      versionIsValid = true;
    }
  });
  return versionIsValid;
}

async function isRunning(showNotify = false) {
  let serviceIsRunning = false;

  await asyncGetIsRunningMsysGestorService(({ data: data }) => {
    if (!!data.executando) {
      serviceIsRunning = true;
    }
  });

  if (!serviceIsRunning && showNotify) {
    notify(
      "Msys Gestor Service não está sendo executado. Verifique!",
      ToastTypes.ERROR
    );
  }

  return serviceIsRunning;
}

export const GESTOR_SERVICE = { validateVersion, isRunning };
