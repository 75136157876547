export function jaExisteMesmoCnae(listaCnaes, values) {
	let mesmoCnae = false;
	if (listaCnaes.length > 0) {
		for (let i = 0; i < listaCnaes.length; i++) {
			const element = listaCnaes[i];

			if (element.codigo === values.codigo) {
				if (element.id !== values.id) {
					mesmoCnae = true;
				} else {
					mesmoCnae = false;
				}
			}
		}
	}
	return mesmoCnae;
}
