import { Button } from 'primereact/button';

function ButtonSumSubtract(props) {
	const {
		id,
		disabled,
		hidden,
		onClickSum,
		onClickSubtract,
		titleSum = 'Clique aqui para adicionar 1 quantidade desse item.',
		titleSubtract = 'Clique aqui para remover 1 quantidade desse item.',
		styleContainer,
		styleSum,
		styleSubtract,
	} = props;

	const style = {
		borderRadius: '50%',
		width: '20px',
		height: '20px',
		marginLeft: '2.5px',
		marginRight: '2.5px',
		backgroundColor: 'transparent',
		boxShadow: 'none',
	};

	return (
		<span id={id} style={{ ...styleContainer, display: 'flex' }}>
			<Button
				type="button"
				disabled={disabled}
				onClick={onClickSubtract}
				hidden={hidden}
				icon={
					<i
						className="pi pi-minus"
						style={{
							color: 'red',
							fontSize: '12px',
							fontWeight: 'bold',
							...styleSubtract,
						}}
					/>
				}
				title={titleSubtract}
				style={{
					...style,
					borderColor: 'red',
				}}
			/>
			<Button
				type="button"
				disabled={disabled}
				onClick={onClickSum}
				hidden={hidden}
				icon={
					<i
						className="pi pi-plus"
						style={{
							color: 'green',
							fontSize: '12px',
							fontWeight: 'bold',
							...styleSum,
						}}
					/>
				}
				title={titleSum}
				style={{
					...style,
					borderColor: 'green',
				}}
			/>
		</span>
	);
}

export default ButtonSumSubtract;
