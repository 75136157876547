function converterProdutosParaFormItensDevolvidos(produtos) {
	if (produtos?.length > 0) {
		return produtos.map((produto) => ({
			id: produto.id,
			item: produto.item,
			produto: produto.produto?.label,
			quantidadeOriginal: produto.quantidade,
			quantidadeDevolvida: 0,
			quantidadePendenteDevolucao: produto.quantidade,
		}));
	}

	return [];
}

export { converterProdutosParaFormItensDevolvidos };
