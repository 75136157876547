import { formatCPF } from '@brazilian-utils/brazilian-utils';
import { permissoes, recursos, removerCaracteres, services, usuarioPossuiPermissao } from 'Common';
import { useState } from 'react';
import { ModalCondutores } from 'views/cadastros/transporte/Condutores/Modal';
import SingleSelect from '../SingleSelect';

function SingleSelectCondutorImpl({
	isMobile,
	label,
	setHideBackground = false,
	menuPortalTarget = document.body,
	onShowModal,
	onChange,
	errors,
	touched,
	...props
}) {
	const [visible, setVisible] = useState(false);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR));
	const [podeVisualizar] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR));
	const [resetPesquisa, setResetPesquisa] = useState(false);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		const url = `${services.GESTOR}/v1/transporte/condutores/resumo`;
		const pesquisaValor = removerCaracteres(pesquisa?.replaceAll('&', ''), ['.']);

		return `${url}?query=(cpf=contains="*${pesquisaValor}*",nome=contains="*${pesquisa?.replaceAll(
			'&',
			'%26'
		)}*");situacao=="ATIVO"&page=${page}&size=20&sort=nome`;
	}

	function mostrarModal() {
		if (setHideBackground) {
			setHideBackground(true);
		}
		if (onShowModal) {
			onShowModal();
		}
		setVisible(!visible);
	}

	function esconderModal() {
		if (setHideBackground) {
			setHideBackground(false);
		}
		setVisible(false);
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			onChange({
				label: montarLabel(novoRegistro),
				value: novoRegistro.id,
				registro: novoRegistro,
			});
			setResetPesquisa(true);
		}
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		return `${row.nome} - ${formatCPF(row.cpf)}`;
	}

	return (
		<>
			<ModalCondutores visible={visible} onHide={onHide} container={menuPortalTarget} />
			<SingleSelect
				titleBotaoNovo="Adicionar novo condutor"
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				label={label}
				onChange={onChange}
				onClickModal={mostrarModal}
				errors={errors}
				useFormErrors={false}
				useFormTouched={false}
				touched={touched}
				disabledButton={!podeInserirEVisualizar}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
				menuPortalTarget={menuPortalTarget}
				placeholder={isMobile ? 'Selecione uma opção' : 'Digite para pesquisar'}
				{...props}
				styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
			/>
		</>
	);
}

SingleSelectCondutorImpl.defaultProps = {
	label: 'Condutor',
};

export const SingleSelectCondutor = SingleSelectCondutorImpl;
