import { services } from '../../../Common/Constantes/api';
import SingleSelect from '../SingleSelect';

export default function SingleSelectModeloFiscal(props) {
	const { label, isClearable, touched, isMobile } = props;

	function buscarUrlPesquisa(pesquisa, page) {
		let query = `?query=(descricao=contains="*${pesquisa}*",codigo=contains="${pesquisa}*");sigla=contains="*${pesquisa}*"&page=${page}&size=50&sortBy="codigo"`;

		return `${services.GESTOR}/v1/modelos_fiscais${query}`;
	}

	function montarLabel(row) {
		return `${row.codigo} - ${row.descricao} (${row.sigla})`;
	}

	return (
		<>
			<SingleSelect
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				label={label}
				onChange={props.onChange}
				isClearable={isClearable}
				esconderBotao
				errors={props.errors}
				useFormErrors={false}
				useFormTouched={false}
				touched={touched}
				styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
				menuPortalTarget={document.body}
				placeholder={isMobile ? 'Selecione uma opção' : 'Digite para pesquisar'}
				{...props}
			/>
		</>
	);
}

SingleSelectModeloFiscal.defaultProps = {
	label: 'Modelo fiscal',
};
