import { Field } from 'formik';
import { InputField, Dropdown, InputMask, InputDate } from '../../../../../../components';
import { GENERO } from '../../../Util/constantes';
import { helpFieldText } from './Help';

export function renderizarFieldsPessoaFisica(
	values,
	setFieldValue,
	informacoesPermissoes,
	campoObrigatorioParaDocumentoFiscal,
	appendTo
) {
	return (
		<>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={InputMask}
				mask="000.000.000-00"
				placeholder="   .   .   -  "
				label="CPF "
				name="cpf"
				helpMessage={helpFieldText.cpf}
				value={values.cpf}
				onChange={e => setFieldValue('cpf', e.target.value)}
				obrigatorio={campoObrigatorioParaDocumentoFiscal}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={InputField}
				type="text"
				label="RG"
				name="rg"
				value={values.rg}
				helpMessage={helpFieldText.rg}
				size={20}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={Dropdown}
				label="Gênero"
				name="genero"
				helpMessage={helpFieldText.genero}
				onChange={e => setFieldValue('genero', e.value)}
				options={GENERO}
				value={values.genero}
				menuPortalTarget={appendTo}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={InputDate}
				name="nascimento"
				label="Data de nascimento"
				helpMessage={helpFieldText.dataNascimento}
				onChange={e => setFieldValue('nascimento', e.target.value)}
				value={values.nascimento}
				{...informacoesPermissoes}
			/>
		</>
	);
}
