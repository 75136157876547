import { createContext, useContext, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { buscarDadosLoginLocalStorage, estadosCadastro, permissoes, recursos, usuarioPossuiPermissao } from 'Common';

const ContextTabelaPreco = createContext();

function TabelaPrecoProvider({ children, history, match, isMobile, isTablet }) {
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_TABELAS_PRECO, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.CADASTROS_TABELAS_PRECO, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_TABELAS_PRECO, permissoes.EXCLUIR));
	const [disableFields, setDisableFields] = useState(false);
	const [forceClearFilter, setForceClearFilter] = useState(false);

	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const isCanceled = useRef(false);
	const isHeaderEditing = useRef(false);
	const isHeaderSave = useRef(false);

	const informacoesPermissoes = {
		estadoCadastro: match?.params?.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir,
		podeEditar,
		podeExcluir,
	};
	const { filialConectada } = buscarDadosLoginLocalStorage();
	const utilizaTabelaPrecoPromocional = filialConectada?.parametrosVendas?.utilizaTabelaPrecoPromocional;
	const utilizaTabelaPrecoParaServico = filialConectada?.parametrosVendas?.utilizaTabelaPrecoParaServico;
	const utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao =
		filialConectada?.parametrosVendas?.utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao;
	const utilizaTabelaPrecoDescontoQuantidade = filialConectada?.parametrosVendas?.utilizaTabelaPrecoDescontoQuantidade;
	const desabilitarCampos =
		(informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO && !informacoesPermissoes.podeEditar) ||
		(informacoesPermissoes.estadoCadastro === estadosCadastro.INCLUSAO &&
			(!informacoesPermissoes.podeInserir || !informacoesPermissoes.podeEditar));

	return (
		<ContextTabelaPreco.Provider
			value={{
				history,
				match,
				isMobile,
				isTablet,
				podeInserir,
				podeEditar,
				podeExcluir,
				informacoesPermissoes,
				disableFields,
				setDisableFields,
				activeTabIndex,
				setActiveTabIndex,
				forceClearFilter,
				setForceClearFilter,
				utilizaTabelaPrecoPromocional,
				utilizaTabelaPrecoParaServico,
				utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao,
				utilizaTabelaPrecoDescontoQuantidade,
				desabilitarCampos,
				isCanceled,
				isHeaderEditing,
				isHeaderSave,
			}}
		>
			{children}
		</ContextTabelaPreco.Provider>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(TabelaPrecoProvider);
export const useContextTabelaPreco = () => useContext(ContextTabelaPreco);
