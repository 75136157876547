import { useEffect } from 'react';

import { If, Modal } from 'components';

import GestaoDocumentos from '../..';

export default function ModalAnexo(props) {
	const {
		isModalAnexo,
		documentoRescindido,
		idAnexo,
		isNewAnexo,
		idDocumento,
		onHide,
		informacoesPermissoes,
		statusFinalDocumento,
		tipoGestaoDocumentoParaAnexos,
	} = props;

	const idFocus = 'DocumentosAnexoInputIdentificador';

	useEffect(() => {
		setTimeout(() => {
			document.getElementById(idFocus)?.focus();
		}, 500);
	});

	return (
		<Modal header="Cadastro de anexos" visible={isModalAnexo} onHide={() => onHide()}>
			<If test={isModalAnexo}>
				<GestaoDocumentos
					tipoGestaoDocumentoParaAnexos={tipoGestaoDocumentoParaAnexos}
					isModalAnexo={isModalAnexo}
					documentoRescindido={documentoRescindido}
					idAnexo={idAnexo}
					isNewAnexo={isNewAnexo}
					idDocumento={idDocumento}
					hideModalAnexo={onHide}
					informacoesPermissoes={informacoesPermissoes}
					statusFinalDocumento={statusFinalDocumento}
					idFocus={idFocus}
				/>
			</If>
		</Modal>
	);
}
