import { tipoCampos } from '../../../../../components/PesquisaAvancada/Util/constantes';

export const OptionsFiltroAvancadoOrcamento = [
	{ label: 'Número', name: 'numero', type: tipoCampos.INTEGER },
	{
		label: 'Status',
		name: 'status',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Pendente', value: 'PENDENTE' },
			{ label: 'Finalizada', value: 'FINALIZADO' },
			{ label: 'Cancelada', value: 'CANCELADA' },
			{ label: 'Expirada', value: 'EXPIRADA' },
		],
	},
	{ label: 'Valor', name: 'totalLiquido', type: tipoCampos.DECIMAL },
];

export const OptionsFiltroAvancadoPedido = [
	{ label: 'Número', name: 'numero', type: tipoCampos.INTEGER },
	{
		label: 'Status',
		name: 'status',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Pendente', value: 'PENDENTE' },
			{ label: 'Finalizada', value: 'FINALIZADO' },
			{ label: 'Cancelada', value: 'CANCELADA' },
		],
	},
	{ label: 'Valor', name: 'totalLiquido', type: tipoCampos.DECIMAL },
];

export const ColorsCard = {
	ORCAMENTO: {
		lightColor: '#b3e5fc',
		strongColor: '#01579B',
	},
	PEDIDO_VENDA: {
		lightColor: '#dcedc8',
		strongColor: '#1b5e20',
	},
	CANCELADA: {
		lightColor: '#ffcdd2',
		strongColor: '#b71c1c',
	},
	TOTAL: {
		lightColor: '#e0e0e0',
		strongColor: '#000000de',
	},
};

export const colorsStatus = {
	PENDENTE: {
		lightColor: '#b3e5fc',
		strongColor: '#01579B',
	},
	FINALIZADO: {
		lightColor: '#dcedc8',
		strongColor: '#1b5e20',
	},
	PARCIALMENTE_DEVOLVIDO: {
		lightColor: '#dcedc8',
		strongColor: '#1b5e20',
	},
	DEVOLVIDO: {
		lightColor: '#dcedc8',
		strongColor: '#1b5e20',
	},
	CANCELADA: {
		lightColor: '#ffcdd2',
		strongColor: '#b71c1c',
	},
	EXPIRADA: {
		lightColor: '#e0e0e0',
		strongColor: '#000000de',
	},
};

export const Status = {
	PENDENTE: 'PENDENTE',
	FINALIZADO: 'FINALIZADO',
	CANCELADA: 'CANCELADA',
	EXPIRADA: 'EXPIRADA',
};

export const SITUACAO_NOME = {
	PENDENTE: 'Pendente',
	FINALIZADO: 'Finalizado',
	PARCIALMENTE_DEVOLVIDO: 'Parcialmente Devolvido',
	DEVOLVIDO: 'Devolvido',
	CANCELADA: 'Cancelado',
	EXPIRADA: 'Expirado',
};

export const SITUACAO_DEVOLUCAO = {
	NAO_DEVOLVIDO: 'NAO_DEVOLVIDO',
	PARCIALMENTE_DEVOLVIDO: 'PARCIALMENTE_DEVOLVIDO',
	DEVOLVIDO: 'DEVOLVIDO',
};

export const Tipo = {
	PEDIDO_VENDA: 'PEDIDO_VENDA',
	ORCAMENTO: 'ORCAMENTO',
	NOTA_FISCAL: 'NOTA_FISCAL',
};

export const Operacao = {
	INSERIR: 'INSERIR',
	EDITAR: 'EDITAR',
	EXCLUIR: 'EXCLUIR',
};

export const PESQUISAURL = '/orcamento';
