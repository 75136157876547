import { useState, useEffect, useRef, useCallback } from 'react';

export default function useStateCallback(initialState) {
	const [state, setState] = useState(initialState);
	const callBackRef = useRef(null);

	const setStateCallback = useCallback((state, callback) => {
		callBackRef.current = callback;
		setState(state);
	}, []);

	useEffect(() => {
		if (callBackRef.current) {
			callBackRef.current(state);
			callBackRef.current = null;
		}
	}, [state]);

	return [state, setStateCallback];
}
