import { keyFilterConsultaRsql, removerCaracteresInvalidosRsql } from 'Common';
import { Grid, InputDate, InputField, TextArea } from 'components';
import { Field, useFormikContext } from 'formik';
import { helpMessage } from '../../Util/constantes';

function FormInformacoesComplementares({ informacoesPermissoes }) {
	const { values, setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputDate}
				label="Competência"
				name="competencia"
				helpMessage={helpMessage.competencia}
				onChange={(event) => setFieldValue('competencia', event.target.value)}
				value={values.competencia}
				validateOnly={false}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputField}
				label="Número do documento de origem"
				name="numeroDocumento"
				onChange={(event) => setFieldValue('numeroDocumento', removerCaracteresInvalidosRsql(event.target.value))}
				keyfilter={keyFilterConsultaRsql}
				helpMessage={helpMessage.numeroDocumento}
				size={255}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputDate}
				label="Data do documento de origem"
				name="dataDocumento"
				helpMessage={helpMessage.dataDocumento}
				onChange={(event) => setFieldValue('dataDocumento', event.target.value)}
				value={values.dataDocumento}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				component={TextArea}
				placeholder="Escreva sua observação aqui"
				label="Observação"
				name="observacao"
				helpMessage={helpMessage.observacao}
				maxLength={4096}
				onChange={(event) => setFieldValue('observacao', event.target.value)}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { FormInformacoesComplementares };
