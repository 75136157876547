import { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button, Col } from '../../index';

import './Styles/index.css';

export default function InputFieldCopyButton(props) {
	const { sm, md, lg, xl, id, className, placeholder, name, title, value, readOnly = true } = props;
	const [copyButtonClicked, setCopyButtonClicked] = useState(false);
	const [idButtonCopy, setIdButtonCopy] = useState('');

	const inputRef = useRef(null);

	useEffect(() => {
		if (copyButtonClicked) {
			setTimeout(() => {
				setCopyButtonClicked(false);
				setIdButtonCopy('');
			}, 3000);
		}
	}, [copyButtonClicked]);

	function montarLabel() {
		const { obrigatorio } = props;

		const label = props.label;
		if (obrigatorio) {
			return (
				<label title={props.helpMessage}>
					{label} <b style={{ fontSize: '18px', lineHeight: '5px' }}> *</b>
				</label>
			);
		}
		return <label title={props.helpMessage}>{label}</label>;
	}

	function copiarConteudoInput() {
		navigator.clipboard.writeText(inputRef.current.value);
		setCopyButtonClicked(true);
		setIdButtonCopy('button-input-copy-clicked');
	}

	return (
		<>
			<Col sm={sm} md={md} lg={lg} xl={xl}>
				{montarLabel()}
				<span className="p-fluid" title={title}>
					<div className="p-inputgroup">
						<InputText
							id={id}
							ref={inputRef}
							type="text"
							className={className}
							placeholder={placeholder}
							name={name}
							value={value}
							readOnly={readOnly}
							style={{
								width: '100%',
								marginRight: '-36px',
								color: '#9e9e9e',
								borderRadius: '3px 0px 0px 3px',
							}}
						/>
						<Button
							id={idButtonCopy}
							type="button"
							icon={copyButtonClicked ? 'fa fa-check' : 'fa fa-clone'}
							color={copyButtonClicked ? 'success' : 'primary'}
							onClick={() => copiarConteudoInput()}
							title="Clique aqui para copiar o texto"
							style={{
								boxShadow: 'none',
								height: '100%',
								borderTopLeftRadius: '0px',
								borderBottomLeftRadius: '0px',
								zIndex: 1,
							}}
						/>
					</div>
				</span>
			</Col>
		</>
	);
}
