import { formatCNPJ, formatCPF } from '@brazilian-utils/brazilian-utils';

export function montarLabelPessoa(pessoa) {
	let complemento = '';
	if (pessoa.tipo === 'JURIDICA') {
		complemento = ` - ${formatCNPJ(pessoa?.cnpj ? pessoa?.cnpj : pessoa.pessoaJuridica?.cnpj)}`;
	} else if (pessoa.tipo === 'FISICA') {
		complemento = ` - ${formatCPF(pessoa?.cpf ? pessoa?.cpf : pessoa.pessoaFisica?.cpf)}`;
	} else if (pessoa.tipo === 'ESTRANGEIRO' && (pessoa?.identificacao || pessoa.pessoaEstrangeira?.identificacao)) {
		complemento = ` - ${pessoa?.identificacao ? pessoa?.identificacao : pessoa.pessoaEstrangeira?.identificacao}`;
	}

	if (pessoa.codigo) {
		return `${pessoa.codigo} - ${pessoa.nome}${complemento}`;
	}

	return `${pessoa.nome}${complemento}`;
}
