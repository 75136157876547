import { removerCaracteresInvalidosRsql } from 'Common';
import { InputField } from 'components';
import { Field } from 'formik';

function VeiculoFields(props) {
	const { setFieldValue, values, informacoesPermissoes } = props;
	return (
		<>
			<Field
				sm="3"
				md="2"
				lg="2"
				xl="2"
				component={InputField}
				label="Placa"
				name="veiculo.placa"
				size={7}
				onChange={e => setFieldValue('veiculo.placa', removerCaracteresInvalidosRsql(e.target?.value))}
				value={values.veiculo?.placa}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="3"
				lg="3"
				xl="3"
				component={InputField}
				label="Chassi"
				name="veiculo.chassi"
				size={17}
				onChange={e => setFieldValue('veiculo.chassi', removerCaracteresInvalidosRsql(e.target?.value))}
				value={values.veiculo?.chassi}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="3"
				lg="3"
				xl="3"
				component={InputField}
				label="Renavam"
				name="veiculo.renavam"
				size={11}
				onChange={e => setFieldValue('veiculo.renavam', removerCaracteresInvalidosRsql(e.target?.value))}
				value={values.veiculo?.renavam}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="2"
				lg="2"
				xl="2"
				component={InputField}
				type="number"
				label="Quilometragem"
				name="veiculo.quilometragem"
				onChange={e => setFieldValue('veiculo.quilometragem', removerCaracteresInvalidosRsql(e.target?.value))}
				value={values.veiculo?.quilometragem}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export default VeiculoFields;
