import { useState } from 'react';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { services } from '../../../Common/Constantes/api';

export default function SingleSelectProdutoGrupo(props) {
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR)
	);

	function buscarUrlPesquisa(pesquisa, page) {
		return `${services.GESTOR}/v1/produtos/grupos?query=nivel=contains="${pesquisa}",descricao=contains="*${pesquisa}*"&page=${page}&size=50&sort=nivel`;
	}

	function montarLabel(row) {
		return row.nivel + ' - ' + row.descricao;
	}

	return (
		<>
			<SingleSelect
				esconderBotao
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				{...props}
				disabledButton={!podeVisualizar}
				onChange={props.onChange}
			/>
		</>
	);
}

SingleSelectProdutoGrupo.defaultProps = {
	label: 'Grupo',
};
