/* eslint-disable no-sequences */
import {
	DOCUMENTOFISCAL,
	buscarItemLocalStorage,
	gerarUUID,
	montarCalcularImpostosProduto as montarCalcularImpostos,
} from 'Common';
import { Field } from 'formik';
import { formatarDecimais } from '../../../../../../../../../Common/Mascara';
import { Grid, InputDouble, ToastTypes, notify } from '../../../../../../../../../components';
import { useContextNFCe } from '../../../../../Context';
import { asyncGetPesoBalancaServicoLocal } from '../../../../../Requests';
import InputProduto from './components/InputProduto';

const initialValue = {
	produtoSelecionado: null,
	quantidadeSelecionada: 1,
};

function ProdutoForm(props) {
	const {
		produtoSelecionado,
		quantidadeSelecionada,
		produtos,
		cliente,
		setFieldValue,
		credencial,
		operacaoFiscalConfigurado,
		setAtualizarTotal,
		decimaisQtd,
		decimaisPreco,
		isFullScreen,
		isMobile,
	} = props;

	const { informacoesPermissoes, podeInserir } = useContextNFCe();

	async function adicionarProdutoNfce(produto, quantidadeBarras = 0, precoBarras = 0) {
		if (produto?.registro) {
			const produtosAtualizados = produtos?.map((prod, index) => {
				if (prod.id) {
					return { ...prod, item: index + 1 };
				}
				return null;
			});

			if (!produto.registro.origem) {
				notify('O produto não possui Origem informada!', ToastTypes.ERROR, 8);
				return;
			}

			if (!produto.registro.ncm) {
				notify('O produto não possui NCM informado!', ToastTypes.ERROR, 8);
				return;
			}

			let quantidadeVendida = quantidadeSelecionada;
			if (quantidadeBarras > 0) {
				quantidadeVendida = quantidadeBarras;
			}
			const balancaConfigurada = buscarItemLocalStorage('balancaConfigurada', true);
			if (produto.registro?.utilizaBalanca && balancaConfigurada && quantidadeBarras === 0 && precoBarras === 0) {
				await asyncGetPesoBalancaServicoLocal(
					({ data: balanca }) => {
						quantidadeVendida = balanca.peso;
					},
					({ response }) => {
						if (response?.data) {
							notify(`MsysGestorService - Balança: &ensp;&ensp;&ensp;${response.data.erro}`, ToastTypes.ERROR, 8);
						} else {
							notify(
								`MsysGestorService - Balança: &ensp;&ensp;&ensp;&ensp;O aplicativo não está respondendo!`,
								ToastTypes.ERROR,
								8
							);
						}
						const layoutFullscreen = document.getElementsByClassName('layout-fullscreen');
						const alertifyNotifier = document.getElementsByClassName('alertify-notifier');

						if (isFullScreen) {
							layoutFullscreen[0].append(alertifyNotifier[0]);
						}
						quantidadeVendida = 0;
					}
				);
			}

			if (quantidadeVendida === 0) {
				return;
			}

			let produtoNovo = {
				id: gerarUUID(),
				item: produtosAtualizados.length + 1,
				produto: produto,
				codigo: produto.registro?.codigo,
				descricao: produto.registro?.nome,

				ncm: produto.registro?.ncm
					? {
							label: `${produto.registro?.ncm.codigo} - ${produto.registro?.ncm.descricao}`,
							registro: produto.registro?.ncm,
							value: produto.registro?.ncm.id,
						}
					: null,
				cest: produto.registro?.cest
					? {
							label: `${produto.registro?.cest.codigo} - ${produto.registro?.cest.descricao}`,
							registro: produto.registro?.cest,
							value: produto.registro?.cest.id,
						}
					: null,
				cfop: null,
				operacaoFiscal: operacaoFiscalConfigurado ?? null,
				unidadeMedida: produto.registro?.unidadeMedida
					? {
							label: produto.registro?.unidadeMedida.label
								? produto.registro?.unidadeMedida.label
								: `${produto.registro?.unidadeMedida.unidade} - ${produto.registro?.unidadeMedida.descricao}`,
							registro: produto.registro?.unidadeMedida,
							value: produto.registro?.unidadeMedida.value
								? produto.registro?.unidadeMedida.value
								: produto.registro?.unidadeMedida.id,
						}
					: null,

				quantidade: quantidadeVendida,
				desconto: 0,
				descontoItem: 0,
				descontoRateio: 0,
				acessorias: 0,
				acessoriasItem: 0,
				acessoriasRateio: 0,
				valor:
					// eslint-disable-next-line no-nested-ternary
					cliente?.configPrecoPraticado === 'PRECO_ATACADO' && produto.registro.precoAtacado > 0
						? produto.registro.precoAtacado
						: produto.registro.preco > 0
							? produto.registro.preco
							: 0,

				tributos: {
					icms: {
						cstCsosn: {
							label:
								credencial.filialConectada?.regimeTributario === ' SIMPLES_NACIONAL'
									? '102 - Tributada pelo Simples Nacional sem permissão de crédito'
									: '00 - Tributada integralmente',
							value:
								credencial.filialConectada?.regimeTributario === ' SIMPLES_NACIONAL'
									? '3fffc6e3-866d-4a8b-8285-fa1fc406abfb'
									: 'e2a76bea-82cc-4ca8-9f56-848d9e6d2206',
						},
						origemProduto: produto.registro?.origem ? produto.registro?.origem : null,
						percentualCreditoSimplesNacional: 0,
						valorCreditoSimplesNacional: 0,
						percentualBaseIcms: 0,
						percentualReducaoBaseIcms: 0,
						baseIcms: 0,
						percentualIcms: 0,
						valorIcms: 0,
					},
					pis: {
						cst: {
							label: '07 - Operação Isenta da Contribuição',
							registro: {
								codigo: '07',
								descricao: 'Operação Isenta da Contribuição',
								id: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
							},
							value: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
						},
						percentualBasePis: 0,
						basePis: 0,
						percentualPis: 0,
						valorPis: 0,
					},
					cofins: {
						cst: {
							label: '07 - Operação Isenta da Contribuição',
							value: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
						},
						percentualBaseCofins: 0,
						baseCofins: 0,
						percentualCofins: 0,
						valorCofins: 0,
					},
				},

				subTotal: parseFloat((quantidadeVendida * produto.registro.preco).toFixed(2)),
				baseCalculo: parseFloat((quantidadeVendida * produto.registro.preco).toFixed(2)),
				utilizouTabelaPromocional: produto.registro?.tabelaPreco?.tabelaPromocional || false,
				tabelaPreco: produto.registro?.tabelaPreco
					? {
							label: `${produto.registro?.tabelaPreco?.codigo} - ${produto.registro?.tabelaPreco?.nome}`,
							registro: produto.registro?.tabelaPreco,
							value: produto.registro?.tabelaPreco?.id,
						}
					: null,
			};

			if (precoBarras > 0) {
				produtoNovo.quantidade = parseFloat((precoBarras / produtoNovo.valor).toFixed(decimaisQtd));
			}
			(produtoNovo.subTotal = parseFloat((produtoNovo.quantidade * produtoNovo.valor).toFixed(2))),
				(produtoNovo.baseCalculo = parseFloat((produtoNovo.quantidade * produtoNovo.valor).toFixed(2))),
				(produtoNovo = await montarCalcularImpostos(
					credencial.filialConectada?.endereco?.municipio.estado.id,
					{
						...produtoNovo,
					},
					DOCUMENTOFISCAL.NFCE
				));
			produtosAtualizados.push(produtoNovo);

			setFieldValue('produtos', produtosAtualizados);

			setFieldValue('produtoSelecionado', initialValue.produtoSelecionado);
			setFieldValue('quantidadeSelecionada', initialValue.quantidadeSelecionada);
			setAtualizarTotal(true);
		}
	}

	return (
		<Grid id="id-nfce-produto" className="step-nfce-produto">
			<InputProduto
				id="id-produto-nfce"
				sm="12"
				md="12"
				lg="8"
				xl="9"
				name="produto"
				label="Produto"
				shortcut="[Ctrl+P]"
				produtoSelecionado={produtoSelecionado}
				decimaisPreco={decimaisPreco}
				decimaisQtd={decimaisQtd}
				setFieldValue={setFieldValue}
				adicionarProduto={adicionarProdutoNfce}
				initialValue={initialValue}
				podeInserir={podeInserir}
				isFullScreen={isFullScreen}
				isMobile={isMobile}
				refComponenteVinculado={document.getElementById('id-produto-nfce')}
				informacoesPermissoes={informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="4"
				xl="3"
				component={InputDouble}
				id="id-quantidade-nfce"
				name="quantidade"
				label="Qtde."
				shortcut="[Ctrl+Q]"
				value={quantidadeSelecionada}
				onChange={(event) => setFieldValue('quantidadeSelecionada', event.target.value, decimaisQtd)}
				placeholder={formatarDecimais(0, decimaisQtd)}
				decimalScale={decimaisQtd}
				size={17}
				allowNegative={false}
				disabled={!podeInserir}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export default ProdutoForm;
