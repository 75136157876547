import { useFormikContext } from 'formik';

import { Col, If } from 'components';
import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';

import { useContextNFSe } from '../../../Context';

const secaoStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
};

const numeroStyle = {
	fontSize: '24px',
	color: '#006095',
	fontWeight: 'bold',
};

const visualizarVendaStyle = {
	color: '#006095',
	fontWeight: 'bold',
	display: 'flex',
	margin: '0.2rem 0 0.5rem 0',
	fontSize: '12px',
};

const styleStatus = {
	height: '38px',
	borderRadius: '25px',
	fontWeight: 'bold',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '14px',
};

const styleName = {
	textAlign: 'center',
	fontWeight: 'bold',
	padding: '2px 0px',
};

export default function Numero(props) {
	const { isMobile, history } = props;

	const { values } = useFormikContext();
	const { color } = useContextNFSe();

	const podeVisualizarOrdem = usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.VISUALIZAR);

	return (
		<>
			<Col sm="5" lg="6" xl="6">
				<div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
					<div
						style={secaoStyle}
						title={!values.numero ? 'O número da nota é gerados automaticamente pelo sistema' : null}
					>
						<div>Número</div>
						<div style={{ ...numeroStyle, color: color.strongColor }}>{values.numero || '-'}</div>
					</div>
				</div>
				<If test={values.ordemServico}>
					<div style={{ ...visualizarVendaStyle, justifyContent: isMobile ? 'center' : 'flex-start' }}>
						{podeVisualizarOrdem ? (
							<span
								onClick={values ? () => history.push(`/ordem_servico/cadastro/${values.ordemServico.id}`) : null}
								className="link_to"
							>
								Visualizar ordem (N° {values.ordemServico?.numero})
							</span>
						) : (
							<span>Ordem N° {values.ordemServico?.numero}</span>
						)}
					</div>
				</If>
			</Col>
			<Col sm="7" lg="4" xl="4">
				<div
					title={color.description}
					style={{ ...styleStatus, backgroundColor: color.lightColor, color: color.strongColor }}
				>
					<div style={styleName}>{color.name}</div>
				</div>
			</Col>
		</>
	);
}
