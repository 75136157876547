import { buscarMascaraTelefone, gerarUUID, mensagensDeValidacao, PAPEL_SIGNATARIOS, services } from 'Common';
import {
	Checkbox,
	Dropdown,
	FormGroup,
	Grid,
	If,
	InputField,
	InputMask,
	LayoutMenuSuperior,
	MenuSuperior,
	Modal,
	Paper,
	SingleSelectPessoa,
	SingleSelectPessoaEmail,
	SingleSelectPessoaTelefone,
	confirm,
} from 'components';
import { Field, useFormikContext, withFormik } from 'formik';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { validarFormulario } from 'views/Util';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { INITIAL_VALUES_EDIT, helpDocumentoSignatarioForm } from '../Util/constantes';
import { ActionButtons } from './ActionButtons';

function FormModalSignatarioPadraoView({
	visible,
	onHide,
	onNovoClick,
	registroSelecionado,
	inserirRegistro,
	excluirRegistro,
	atualizarRegistro,
	...props
}) {
	const { informacoesPermissoes, podeEditarEmpresa } = useContextConfiguracoesGerais();
	const { values, dirty, resetForm, initialValues, setFieldValue, handleSubmit } = useFormikContext();

	const [inserindoNovoRegistro, setInserindoNovoRegistro] = useState(!registroSelecionado);

	const disabled = !podeEditarEmpresa;
	const pessoaId = values.pessoa ? values.pessoa.value : null;

	useEffectOnce(() => {
		setInserindoNovoRegistro(!registroSelecionado);

		setTimeout(() => {
			document.getElementById('InputFieldPessoa')?.focus();
		}, 500);
	});

	function onClickNovo() {
		if (dirty) {
			salvar(novo);
		} else {
			novo;
		}
	}

	function novo() {
		onNovoClick();
		resetForm({ values: INITIAL_VALUES_EDIT });
		setInserindoNovoRegistro(true);
	}

	function excluir() {
		confirm(
			'Confirmação',
			'Deseja excluir o signatário?',
			async () => {
				excluirRegistro(registroSelecionado);
				onHide();
			},
			() => {
				null;
			},
			'Sim',
			'Não'
		);
	}

	async function salvar(novoOnSuccess = null) {
		handleSubmit();

		if (await validarFormulario(props)) {
			if (inserindoNovoRegistro) {
				inserirRegistro(values, novoOnSuccess);
			} else {
				atualizarRegistro(values, novoOnSuccess);
			}
		}
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			onHide();
		}
	}

	function afterNewEmail(e) {
		if (e && e.email) {
			setFieldValue('pessoaEmail', e.email);
		}
	}

	function afterNewTelefone(e) {
		if (e && e.telefone) {
			setFieldValue('pessoaTelefone', e.telefone);
		}
	}

	return (
		<Modal header="Cadastro de signatário padrão" visible={visible} onHide={onHide}>
			<MenuSuperior isModal>
				<ActionButtons
					onClickNovo={onClickNovo}
					salvar={salvar}
					cancelar={cancelar}
					excluir={excluir}
					inserindoNovoRegistro={inserindoNovoRegistro}
					disabled={disabled}
				/>
			</MenuSuperior>
			<LayoutMenuSuperior isModal>
				<Paper dirty={dirty} {...informacoesPermissoes} childsOnly>
					<FormGroup>
						<Grid>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Checkbox}
								label="Signatário já cadastrado no sistema"
								name="signatarioCadastrado"
								helpMessage={helpDocumentoSignatarioForm.signatarioCadastrado}
								checked={values.signatarioCadastrado}
								disabled={disabled}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={SingleSelectPessoa}
								label="Pessoa "
								obrigatorio
								name="pessoa"
								url={`${services.GESTOR}/v1/documentos/relacoes/pessoas`}
								value={values.pessoa}
								onChange={(e) => setFieldValue('pessoa', e)}
								helpMessage={helpDocumentoSignatarioForm.pessoa}
								hidden={!values.signatarioCadastrado}
								disabled={disabled}
								id="InputFieldPessoa"
								{...informacoesPermissoes}
							/>
							<If test={values.pessoa}>
								<Field
									sm="12"
									md="12"
									lg="4"
									xl="4"
									component={SingleSelectPessoaEmail}
									label="E-mail "
									obrigatorio
									name="pessoaEmail"
									url={`${services.GESTOR}/v1/documentos/relacoes/pessoas/${pessoaId}/emails`}
									value={values.pessoaEmail}
									idPessoa={pessoaId}
									onChange={(e) => setFieldValue('pessoaEmail', e)}
									afterNewEmail={(e) => afterNewEmail(e)}
									helpMessage={helpDocumentoSignatarioForm.email}
									disabled={disabled && values.assinatura !== undefined}
									{...informacoesPermissoes}
								/>
							</If>
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={InputField}
								label="Nome "
								obrigatorio
								name="nome"
								size={255}
								helpMessage={helpDocumentoSignatarioForm.nome}
								{...informacoesPermissoes}
								disabled={disabled}
								hidden={values.signatarioCadastrado}
								id="AssinaturaInputNome"
							/>
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={InputField}
								label="E-mail "
								obrigatorio
								name="email"
								type="email"
								size={255}
								helpMessage={helpDocumentoSignatarioForm.email}
								hidden={values.signatarioCadastrado}
								disabled={disabled && values.assinatura !== undefined}
								{...informacoesPermissoes}
							/>

							<If test={values.pessoa}>
								<Field
									sm="12"
									md="12"
									lg="4"
									xl="4"
									component={SingleSelectPessoaTelefone}
									label="Telefone"
									name="pessoaTelefone"
									url={`${services.GESTOR}/v1/documentos/relacoes/pessoas/${pessoaId}/telefones`}
									value={values.pessoaTelefone}
									idPessoa={pessoaId}
									mask={buscarMascaraTelefone(values.telefone)}
									placeholder="(  )      -    "
									onChange={(e) => setFieldValue('pessoaTelefone', e)}
									afterNewTelefone={(e) => afterNewTelefone(e)}
									helpMessage={helpDocumentoSignatarioForm.telefone}
									disabled={disabled && values.assinatura !== undefined}
									{...informacoesPermissoes}
								/>
							</If>

							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={InputMask}
								mask={buscarMascaraTelefone(values.telefone)}
								placeholder="(  )      -    "
								label="Telefone "
								name="telefone"
								value={values.telefone}
								onChange={(e) => setFieldValue('telefone', e.target.value)}
								helpMessage={helpDocumentoSignatarioForm.telefone}
								disabled={disabled && values.assinatura !== undefined}
								hidden={values.signatarioCadastrado}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={Dropdown}
								label="Assinar como "
								obrigatorio
								options={PAPEL_SIGNATARIOS}
								name="papel"
								value={values.papel}
								onChange={(e) => setFieldValue('papel', e.value)}
								helpMessage={helpDocumentoSignatarioForm.papel}
								disabled={disabled}
								{...informacoesPermissoes}
							/>
						</Grid>
					</FormGroup>
				</Paper>
			</LayoutMenuSuperior>
		</Modal>
	);
}

const FormModalSignatarioPadrao = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return {
				...props.registroSelecionado,
				signatarioCadastrado: Boolean(props.registroSelecionado.pessoa),
			};
		}

		return { ...INITIAL_VALUES_EDIT, tempId: gerarUUID() };
	},

	validate(values) {
		const errors = {};

		if (!values.signatarioCadastrado) {
			if (!values.nome) {
				errors.nome = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values.email) {
				errors.email = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values.papel) {
				errors.papel = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		if (values.signatarioCadastrado) {
			if (!values.pessoa) {
				errors.pessoa = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values.pessoaEmail) {
				errors.pessoaEmail = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values.papel) {
				errors.papel = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		return errors;
	},

	handleSubmit: () => {},
})(FormModalSignatarioPadraoView);

export { FormModalSignatarioPadrao };
