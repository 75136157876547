import { get, services } from "../../..";

export async function buscarTributacaoProduto(
  produtoId,
  ufId,
  operacaoFiscalId,
  onSuccess,
  onError
) {
  return await get(
    `${services.GESTOR}/v1/tributacoes/vendas/produtos?produtoId=${produtoId}&ufId=${ufId}&operacaoFiscalId=${operacaoFiscalId}`,
    null,
    onSuccess,
    onError
  );
}
