import { Grid, InputMoney, SingleSelectCst, typesCst } from 'components';
import { Field } from 'formik';

function Pis(props) {
	const { tributo, setFieldValue, disabled, informacoesPermissoes, isFullScreen } = props;
	return (
		<>
			<Grid>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={SingleSelectCst}
					useFormErrors={false}
					useFormTouched={false}
					errors={props.errors?.cstPis ?? null}
					touched={props.errors?.cstPis}
					type={typesCst.CST_PIS_COFINS}
					label="CST PIS"
					name="cstPis"
					value={tributo?.cst}
					isClearable={false}
					onChange={(event) => setFieldValue(`produto.tributos.pis.cst`, event)}
					obrigatorio
					disabled={disabled}
					menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
					{...informacoesPermissoes}
				/>
			</Grid>
			<Grid>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMoney}
					label="BC PIS"
					name="basePis"
					value={tributo?.basePis}
					touched
					allowNegative={false}
					onChange={(event) => setFieldValue(`produto.tributos.pis.basePis`, event.target?.value)}
					size={13}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMoney}
					label="PIS %"
					name="percentualPis"
					value={tributo?.percentualPis}
					onChange={(event) => setFieldValue(`produto.tributos.pis.percentualPis`, event.target?.value)}
					size={8}
					decimalScale={2}
					prefix=""
					suffix="%"
					maxValue={100}
					placeholder="0,00 %"
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMoney}
					label="Valor PIS"
					name="valorPis"
					value={tributo?.valorPis}
					touched
					allowNegative={false}
					onChange={(event) => setFieldValue(`produto.tributos.pis.valorPis`, event.target?.value)}
					size={13}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
			</Grid>
		</>
	);
}

export default Pis;
