export function formatarConfiguracaoUsuarioParaApi(valor) {
    if (valor === true) {
        return 'TRUE'
    } else if (valor === false) {
        return 'FALSE'
    } else {
        return valor
    }
}

export function formatarConfiguracaoUsuarioParaAplicacao(valor) {
   
    if (valor === 'TRUE') {
        return true
    } else if (valor === 'FALSE') {
        return false
    } else {
        return valor
    }
}

export function adicionarConfiguracaoNosDadosLogin(configuracao, dadosLogin) {
    let encontrouConfiguracao = false
    dadosLogin.configuracoes = dadosLogin.configuracoes.map((item) => {
        if (item.chave === configuracao.chave) {
            item.valor = configuracao.valor
            encontrouConfiguracao = true
        }
        return item
    })
    if (!encontrouConfiguracao) {
        dadosLogin.configuracoes.push({ chave: configuracao.chave, valor: configuracao.valor })
    }
    return dadosLogin
}