import { actionTypes } from '../../../Common/Constantes/reduxTypes';

export default (state = {}, action) => {
	switch (action.type) {
		case actionTypes.MENU_LATERAL_ATUALIZAR:
			return { ...state, dataAtualizacaoMenuLateral: new Date() };
		default:
			return state;
	}
};
