import SingleSelect from '../SingleSelect';
import { services } from '../../../Common/Constantes/api';

export default function SingleSelectPais(props) {
	function buscarUrlPesquisa(pesquisa, page) {
		return `${services.GESTOR}/v1/paises?query=nome=contains="*${pesquisa}*"&page=${page}&size=50&sort=nome`;
	}

	function montarLabel(row) {
		if (row) {
			return row.nome;
		}
	}

	return (
		<>
			<SingleSelect
				titleBotaoNovo="Adicionar novo país"
				esconderBotao
				montarLabel={montarLabel}
				buscarUrlPesquisa={buscarUrlPesquisa}
				onChange={props.onChange}
				{...props}
			/>
		</>
	);
}

SingleSelectPais.defaultProps = {
	label: 'País *',
};
