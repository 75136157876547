import { Field } from 'formik';

import {
	removerCaracteres,
	keyFilterConsultaRsql,
	services,
	buscarDadosLoginLocalStorage,
} from '../../../../../../../Common';

import {
	Grid,
	InputField,
	InputMoney,
	Dropdown,
	SingleSelectUnidadeMedida,
	SingleSelectProdutoGrupo,
	Col,
	If,
} from '../../../../../../../components';

import { helpProdutosForm } from '../../../Help';

const tipoOptions = [
	{ label: '00 – Mercadoria para revenda', value: 'PRODUTO' },
	{ label: '01 – Matéria-prima', value: 'MATERIAPRIMA' },
	{ label: '02 – Embalagem', value: 'EMBALAGEM' },
	{ label: '03 – Produto em processo', value: 'PROCESSO' },
	{ label: '04 – Produto acabado', value: 'ACABADO' },
	{ label: '05 – Subproduto', value: 'SUBPRODUTO' },
	{ label: '06 – Produto intermediário', value: 'INTERMEDIARIO' },
	{ label: '07 – Material de uso e consumo', value: 'CONSUMO' },
	{ label: '08 – Ativo imobilizado', value: 'ATIVO' },
	{ label: '09 – Serviços', value: 'SERVICO' },
	{ label: '10 – Outros insumos', value: 'INSUMO' },
	{ label: '99 – Outras', value: 'OUTRA' },
];

const sitacaoOptions = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];

const secaoStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '0px',
};

const codigoStyle = {
	fontSize: '24px',
	color: '#006095',
	fontWeight: 'bold',
};

export default function FieldsPrincipais(props) {
	const {
		informacoesPermissoes,
		values,
		setFieldValue,
		tipoPredefinido,
		setAjustarSaldoEmEstoque,
		possuiEstoqueEVendas,
		isGestor,
	} = props;

	const { codigo, nome, codigoBarras, unidadeMedida, tipo, unidadeMedidaEntrada, grupo, filiais } = values;
	const { filialConectada } = buscarDadosLoginLocalStorage();
	const decimaisPreco = filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	const indexFilialConectada =
		values.filiais.map((e) => e.filialId).indexOf(filialConectada.id) > -1
			? values.filiais.map((e) => e.filialId).indexOf(filialConectada.id)
			: 0;

	return (
		<Grid className="step-produto-principais">
			<Col sm="12" md="12" lg="12" xl="12" style={{ height: '42px', marginBottom: '1rem' }}>
				<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
					<div style={secaoStyle} title={!codigo ? 'O código é gerado automaticamente pelo sistema' : null}>
						<div>Código</div>
						<div style={codigoStyle}>{codigo || '-'}</div>
					</div>
				</div>
			</Col>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={InputField}
				label="Referência"
				name="sku"
				id="sku"
				keyfilter={keyFilterConsultaRsql}
				onChange={(e) => setFieldValue('sku', e.target.value?.trim())}
				size={60}
				helpMessage={helpProdutosForm.sku}
				{...informacoesPermissoes}
			/>
			<If test={possuiEstoqueEVendas}>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputField}
					label="Código de barras"
					type="number"
					name="codigoBarras"
					disabled={tipo === 'SERVICO'}
					onChange={(e) => setFieldValue('codigoBarras', e.target.value)}
					value={codigoBarras}
					size={13}
					helpMessage={helpProdutosForm.codigoDeBarras}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={Dropdown}
					label="Tipo"
					obrigatorio
					name="tipo"
					showClear={false}
					helpMessage={helpProdutosForm.tipo}
					onChange={(event) => {
						handleTipoProduto(event);
						if (event.value === 'SERVICO') {
							setFieldValue('codigoBarras', '');
							setFieldValue('unidadeMedidaEntrada', null);
						}
					}}
					options={tipoOptions}
					disabled={tipoPredefinido}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={possuiEstoqueEVendas}>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={Dropdown}
					label="Situação"
					name="situacao"
					helpMessage={helpProdutosForm.situacao}
					onChange={(event) => setFieldValue('situacao', event.value)}
					options={sitacaoOptions}
					obrigatorio
					showClear={false}
					{...informacoesPermissoes}
				/>
				<Field
					id="id-pessoa-nome"
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={InputField}
					label="Nome"
					obrigatorio
					name="nome"
					onBlur={() => {
						if (values.nome) {
							setFieldValue('nome', values.nome.trim());
						}
					}}
					onChange={(e) => setFieldValue('nome', removerCaracteres(e.target.value, ['─']))}
					value={nome}
					helpMessage={helpProdutosForm.nome}
					size={120}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={!possuiEstoqueEVendas}>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={InputField}
					label="Nome"
					obrigatorio
					name="nome"
					onBlur={() => {
						if (values.nome) {
							setFieldValue('nome', values.nome.trim());
						}
					}}
					onChange={(e) => setFieldValue('nome', removerCaracteres(e.target.value, ['─']))}
					value={nome}
					helpMessage={helpProdutosForm.nome}
					size={120}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={Dropdown}
					label="Situação"
					name="situacao"
					helpMessage={helpProdutosForm.situacao}
					onChange={(event) => setFieldValue('situacao', event.value)}
					options={sitacaoOptions}
					obrigatorio
					showClear={false}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={possuiEstoqueEVendas}>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					label="Unidade de saída"
					name="unidadeMedida"
					component={SingleSelectUnidadeMedida}
					value={unidadeMedida}
					onChange={(e) => setFieldValue('unidadeMedida', e)}
					helpMessage={helpProdutosForm.unidadeMedida}
					obrigatorio={values.possuiModulos.VENDAS}
					isClearable={false}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					label="Unidade de entrada"
					name="unidadeMedidaEntrada"
					disabled={tipo === 'SERVICO'}
					component={SingleSelectUnidadeMedida}
					value={unidadeMedidaEntrada}
					onChange={(e) => setFieldValue('unidadeMedidaEntrada', e)}
					helpMessage={helpProdutosForm.unidadeMedidaEntrada}
					{...informacoesPermissoes}
				/>
			</If>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={InputMoney}
				label={filiais.length > 1 && isGestor ? 'Preço de venda (filial conectada)' : 'Preço de venda'}
				name="preco"
				size={12}
				decimalScale={decimaisPreco}
				helpMessage={helpProdutosForm.preco}
				onChange={(e) => setFieldValue(`filiais.${indexFilialConectada}.precoVenda`, e.target.value ?? 0)}
				value={filiais[indexFilialConectada]?.precoVenda}
				allowNegative={false}
				obrigatorio={values.possuiModulos.VENDAS}
				{...informacoesPermissoes}
			/>
			<If test={possuiEstoqueEVendas}>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputMoney}
					label={filiais.length > 1 ? 'Preço de atacado (filial conectada)' : 'Preço de atacado'}
					name="precoAtacado"
					size={12}
					helpMessage={helpProdutosForm.precoAtacado}
					onChange={(e) => setFieldValue(`filiais.${indexFilialConectada}.precoAtacado`, e.target.value ?? 0)}
					value={filiais[indexFilialConectada]?.precoAtacado}
					decimalScale={decimaisPreco}
					allowNegative={false}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					label="Grupo"
					name="grupo"
					component={SingleSelectProdutoGrupo}
					url={`${services.GESTOR}/v1/produtos/grupos`}
					value={grupo}
					onChange={(e) => setFieldValue('grupo', e)}
					{...informacoesPermissoes}
				/>
			</If>
		</Grid>
	);

	function handleTipoProduto(e) {
		setFieldValue('tipo', e.value);
		setAjustarSaldoEmEstoque(false);

		if (e.value != 'SERVICO' && values.origem === null) setFieldValue('origem', 'NACIONAL');
	}
}
