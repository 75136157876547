import { services, get } from 'Common';

export async function asyncImprimirRelatorioAssinaturas(
  situacoes,
  setores,
  dataInicial,
  dataFinal,
  onSuccess,
  onError
) {
  await get(
    `${services.GESTOR}/v1/documentos/assinaturas/imprimir?DataInicial=${dataInicial}&DataFinal=${dataFinal}&situacoes=${situacoes}&setores=${setores}`,
    { responseType: 'arraybuffer' },
    onSuccess,
    onError
  );
}

export async function asyncImprimirRelatorioFimVigenciaPeriodo(dataInicial, dataFinal, onSuccess, onError) {
  await get(
    `${services.GESTOR}/v1/documentos/gestao_documentos/fim_vigencia/imprimir?data_inicial=` +
      dataInicial +
      `&data_final=` +
      dataFinal,
    { responseType: 'arraybuffer' },
    onSuccess,
    onError
  );
}
