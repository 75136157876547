import { Field, useFormikContext, withFormik } from 'formik';

import { keyFilterConsultaRsql, mensagensDeValidacao, services } from 'Common';
import {
	ButtonCancelar,
	ButtonSalvar,
	Checkbox,
	Form,
	FormActions,
	FormContent,
	Grid,
	Modal,
	MultipleSelectTabelasPreco,
	ToastTypes,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
	notify,
} from 'components';

import { validarFormulario } from 'views/Util';
import { useContextTabelaPreco } from 'views/cadastros/TabelaPreco/Context';
import { replicarProdutosParaOutrasTabelasPreco } from 'views/cadastros/TabelaPreco/Requests';

function ModalReplicarProdutosParaOutrasTabelasImpl({ visible, onCloseModal, fetch, idTabelaPreco }) {
	const { values, dirty, handleReset, handleSubmit, validateForm, setFieldValue, setValues } = useFormikContext();
	const { informacoesPermissoes, desabilitarCampos } = useContextTabelaPreco();

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			onCloseModal();
		}
	}

	async function handleClickSalvar() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			confirm(
				'Atenção',
				'A replicação de produtos para outras tabelas vai substituir todos os produtos das tabelas de destino, deseja continuar?',
				async () => {
					await replicarProdutosParaOutrasTabelas();

					onCloseModal();
				},
				() => {},
				'Sim',
				'Não'
			);
		}
	}

	async function replicarProdutosParaOutrasTabelas() {
		replicarProdutosParaOutrasTabelasPreco(
			idTabelaPreco,
			await converterTabelasDestinoParaReplicarApi(),
			() => {
				fetch();
				notify('Replicação concluída com sucesso!', ToastTypes.SUCCESS);
			},
			() => {
				notify('Erro desconhecido ao replicar produtos para outras tabelas', ToastTypes.ERROR);
			}
		);
	}

	async function converterTabelasDestinoParaReplicarApi() {
		if (values.tabelasDestino?.length > 0) {
			const tabelasDestino = values.tabelasDestino?.map((tabela) => tabela.value);

			return { tabelasDestino: tabelasDestino, buscarTodasTabelasDestino: values.buscarTodasTabelasDestino };
		}

		return { tabelasDestino: [], buscarTodasTabelasDestino: values.buscarTodasTabelasDestino };
	}

	function onChangeTabelasPreco(e) {
		setFieldValue('tabelasDestino', e);
	}

	function onChangeBuscarTodasTabelasDestino() {
		setValues({ tabelasDestino: [], buscarTodasTabelasDestino: !values.buscarTodasTabelasDestino });
		// setFieldValue('buscarTodasTabelasDestino', !values.buscarTodasTabelasDestino);
	}

	return (
		<Modal header="Replicar produtos para outras tabelas" visible={visible} onHide={onCloseModal}>
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={handleClickCancelar}
						{...informacoesPermissoes}
					/>
					<ButtonSalvar
						label="Replicar"
						estadoBotao={estadosBotaoSalvar.SALVAR}
						disabled={!dirty || desabilitarCampos}
						onClick={handleClickSalvar}
						{...informacoesPermissoes}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Checkbox}
							colStyle={{ paddingTop: '10px' }}
							label="Replicar para todas as tabelas acessíveis"
							name="buscarTodasTabelasDestino"
							onChange={() => onChangeBuscarTodasTabelasDestino()}
							checked={values.buscarTodasTabelasDestino}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={MultipleSelectTabelasPreco}
							url={`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabelaPreco}/buscar_tabelas_importar_replicar_outra_tabela`}
							label="Tabelas para replicar produtos"
							name="tabelasDestino"
							obrigatorio
							value={values.tabelasDestino}
							onChange={onChangeTabelasPreco}
							keyfilter={keyFilterConsultaRsql}
							disabled={values.buscarTodasTabelasDestino}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalReplicarProdutosParaOutrasTabelas = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return { tabelasDestino: [], buscarTodasTabelasDestino: false };
	},

	validate(values) {
		const errors = {};

		if (!values.buscarTodasTabelasDestino && values.tabelasDestino?.length <= 0) {
			errors.tabelasDestino = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalReplicarProdutosParaOutrasTabelasImpl);

export { ModalReplicarProdutosParaOutrasTabelas };
