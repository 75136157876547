import { Button, Checkbox, Col, Divider, Dropdown, Fieldset, Grid, If, InputDouble } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { helpConfiguracoesForm } from '../../../TabsConfiguracoesGerais/Help';

function TabConfiguracoesNfe() {
	const {
		isMobile,
		informacoesPermissoes,
		alterarNumeroInicialESerieNfe,
		setAlterarNumeroInicialESerieNfe,
		alterarUltimoNsu,
		setAlterarUltimoNsu,
		setModalConfigurarAliquotaSimplesVisible,
	} = useContextConfiguracoesGerais();
	const { values, setFieldValue } = useFormikContext();

	return (
		<If test={values.emiteNfe}>
			<Fieldset legend="Configurações NF-e" className="fieldset">
				<Grid>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						label="Tipo de emissão NF-e"
						name="tipoEmissaoNfe"
						value={values.tipoEmissaoNfe}
						showClear={false}
						onChange={(e) => setFieldValue('tipoEmissaoNfe', e.value)}
						options={[
							{
								label: 'Normal',
								value: 'NORMAL',
							},
							{
								label: 'Contingência SVR-AN',
								value: 'CONTINGENCIA_SVC_AN',
							},
							{
								label: 'Contingência SVR-RS',
								value: 'CONTINGENCIA_SVC_RS',
							},
						]}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						label="Tipo de impressão da DANF-e"
						name="tipoImpressaoDanfeNfe"
						value={values.tipoImpressaoDanfeNfe}
						showClear={false}
						onChange={(e) => setFieldValue('tipoImpressaoDanfeNfe', e.value)}
						options={[
							{
								label: 'Sem geração de DANF-e',
								value: 'SEM_GERACAO',
							},
							{
								label: 'Normal / Modo retrato',
								value: 'NORMAL_RETRATO',
							},
							{
								label: 'Normal / Modo paisagem',
								value: 'NORMAL_PAISAGEM',
							},
							{
								label: 'Simplificado',
								value: 'SIMPLIFICADO',
							},
						]}
						{...informacoesPermissoes}
					/>

					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Checkbox}
						label="Alterar número e série da NF-e"
						name="alterarNumeroInicialESerieNfe"
						onChange={() => {
							setAlterarNumeroInicialESerieNfe(!alterarNumeroInicialESerieNfe);
							setFieldValue('alterarNumeroInicialESerieNfe', alterarNumeroInicialESerieNfe);
						}}
						checked={alterarNumeroInicialESerieNfe}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						label="Série da NF-e"
						name="serieNfe"
						obrigatorio
						size={3}
						value={values.serieNfe}
						disabled={!alterarNumeroInicialESerieNfe}
						onChange={(e) => setFieldValue('serieNfe', e.target.value)}
						component={InputDouble}
						decimalScale={0}
						helpMessage={helpConfiguracoesForm.serieNfe}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						label="Número inicial da NF-e"
						name="numeroInicialNfe"
						size={9}
						disabled={!alterarNumeroInicialESerieNfe}
						value={values.numeroInicialNfe}
						onChange={(e) => setFieldValue('numeroInicialNfe', e.target.value)}
						thousandSeparator=""
						component={InputDouble}
						decimalScale={0}
						helpMessage={helpConfiguracoesForm.numeroInicialNfe}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Checkbox}
						label="Imprimir as informações do fundo de combate a pobreza (FCP) na DANF-e"
						name="impressaoFcpDanfeNfe"
						onChange={(e) => setFieldValue('impressaoFcpDanfeNfe', e.checked)}
						checked={values.impressaoFcpDanfeNfe}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Checkbox}
						label="Imprimir as informações da partilha de ICMS na DANF-e"
						name="impressaoPartilhaDanfeNfe"
						onChange={(e) => setFieldValue('impressaoPartilhaDanfeNfe', e.checked)}
						checked={values.impressaoPartilhaDanfeNfe}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Checkbox}
						label="Enviar e-mails automaticamente para destinatário ao emitir uma NF-e"
						name="envioAutomaticoEmailNfe"
						onChange={(e) => setFieldValue('envioAutomaticoEmailNfe', e.checked)}
						checked={values.envioAutomaticoEmailNfe}
						{...informacoesPermissoes}
					/>
					<Divider styleLine={{ opacity: '0.2' }} />
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Checkbox}
						label="Alterar último NSU da MD-e"
						name="alterarUltimoNsuMde"
						onChange={() => setAlterarUltimoNsu(!alterarUltimoNsu)}
						checked={alterarUltimoNsu}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Último NSU"
						name="ultimoNsu"
						size={9}
						disabled={!alterarUltimoNsu}
						value={values.ultimoNsu}
						onChange={(e) => setFieldValue('ultimoNsu', e.target.value)}
						thousandSeparator=""
						component={InputDouble}
						decimalScale={0}
						helpMessage={helpConfiguracoesForm.ultimoNsu}
						{...informacoesPermissoes}
					/>
					<If test={values.regimeTributario === 'SIMPLES_NACIONAL'}>
						<Col sm="12" md="8" lg="8" xl="8">
							<div
								style={{
									display: 'flex',
									justifyContent: 'end',
								}}
							>
								<Button
									className="p-button-warning"
									type="button"
									label={isMobile ? 'Alíquota do simples' : 'Configurar alíquota do simples'}
									icon="fa fa-pencil"
									onClick={() => setModalConfigurarAliquotaSimplesVisible(true)}
									style={{
										height: '32px',
										margin: '21px 0px 0px 7px',
									}}
									title={helpConfiguracoesForm.percentualAliquotaSimples}
								/>
							</div>
						</Col>
					</If>
				</Grid>
			</Fieldset>
		</If>
	);
}

export { TabConfiguracoesNfe };
