import { Field } from 'formik';
import { Fieldset } from 'primereact/fieldset';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';

import { Card, SingleSelectAssinatura, If, Grid, Col } from 'components';

import { services } from 'Common';

import ArquivoDocumentoStatus from './components/StatusArquivoDocumento';
import { InfoStatusArquivoDocumento } from '../../Util/constantes';

export default function FieldsArquivoDocumento(props) {
	const {
		informacoesPermissoes,
		values,
		setFieldValue,
		helpMessage,
		isModalAnexo,
		documentoAnexoAssinado,
		anexoDocumentoDisabled,
		statusFinalDocumento,
		isMobile,
	} = props;

	const { podeEditarDocumento, estadoCadastro } = informacoesPermissoes;

	const documentoInformado = values.arquivoDocumento ? true : false;
	const documento = values.arquivoDocumento?.registro;
	const valorResponsivo = documentoInformado ? '6' : '12';
	const dataDocumento = documento?.dataCriacao
		? format(parseISO(documento?.dataCriacao), 'dd/MM/yyyy')
		: format(new Date(), 'dd/MM/yyyy');

	const fieldDisabled =
		((!isModalAnexo && !podeEditarDocumento) ||
			statusFinalDocumento ||
			documentoAnexoAssinado ||
			anexoDocumentoDisabled) &&
		estadoCadastro !== 'INCLUSAO';

	const helpMessageFieldDisabled = isModalAnexo
		? helpMessage.documentoAssinadoEditar
		: helpMessage.documentoVigenteEditar;
	const mensagemDocumento = !isModalAnexo ? helpMessage.arquivoDocumento : helpMessage.documentoAnexo;

	return (
		<Grid>
			<Col sm="12" md="12" lg={valorResponsivo} xl={valorResponsivo}>
				<Card>
					<Fieldset legend="Assinatura do documento" className="fieldset">
						<Grid className="step-documento-arquivo" style={{ marginLeft: '0px' }}>
							<Field
								{...informacoesPermissoes}
								label="Documento "
								name="arquivoDocumento"
								component={SingleSelectAssinatura}
								disabled={fieldDisabled}
								url={`${services.GESTOR}/v1/documentos/gestao_documentos/relacoes/documentos`}
								onChange={element => setFieldValue('arquivoDocumento', element)}
								value={values.arquivoDocumento}
								helpMessage={fieldDisabled ? helpMessageFieldDisabled : mensagemDocumento}
							/>
						</Grid>
					</Fieldset>
				</Card>
			</Col>
			<If test={documentoInformado}>
				<Col sm="12" md="12" lg="6" xl="6" style={{ height: 'initial' }}>
					<Card style={{ padding: '0rem', height: '100%' }}>
						<Fieldset
							legend="Detalhes do arquivo da gestão de documento"
							className="fieldset"
							style={{ paddingBottom: '3px' }}
						>
							<Grid
								style={{
									marginTop: '0px',
									display: 'flex',
									alignItems: 'center',
									padding: 0,
								}}
							>
								<Col
									sm="7"
									md="7"
									lg="7"
									xl="7"
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<p style={{ margin: ' 0px 16px 0px 10px' }}>Status:</p>
									<ArquivoDocumentoStatus
										backgroundColor={
											documento?.status
												? InfoStatusArquivoDocumento[documento?.status].lightColor
												: null
										}
										color={
											documento?.status
												? InfoStatusArquivoDocumento[documento?.status].strongColor
												: null
										}
										name={
											documento?.status
												? InfoStatusArquivoDocumento[documento?.status].name
												: null
										}
										Description={
											documento?.status
												? InfoStatusArquivoDocumento[documento?.status].Description
												: null
										}
									/>
								</Col>
								<Col
									sm="5"
									md="5"
									lg="5"
									xl="5"
									style={{
										display: 'flex',
										alignItems: 'center',
										marginTop: '6px',
									}}
								>
									<p style={{ margin: isMobile ? '0px 16px 0 10px' : '0px 16px' }}>Criação:</p>
									<p style={{ fontWeight: 'bold', fontSize: '14px' }}>{dataDocumento}</p>
								</Col>
							</Grid>
						</Fieldset>
					</Card>
				</Col>
			</If>
		</Grid>
	);
}
