import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { baixarArquivo, mensagensDeValidacao } from '../../../../../../../../../../../Common';
import {
	Button,
	Form,
	Grid,
	InputDate,
	Modal,
	ModalLoadingTransmissao,
} from '../../../../../../../../../../../components';
import FormActions from '../../../../../../../../../../../components/FormActions';
import FormContent from '../../../../../../../../../../../components/FormContent';
import { asyncGetControleDavs } from '../../../../../../../Requests';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';

const initialValue = {
	dataInicial: new Date().toISOString(),
	dataFinal: new Date().toISOString(),
};

function ModalControleDavsView(props) {
	const { onHide, isMobile, visible, isFullScreen, values, setFieldValue, isValid, handleSubmit, validateForm } =
		props;

	const [exibirLoadingGeracao, setExibirLoadingGeracao] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('ControleDavsInputDateDataInicial')?.focus();
		}, 500);
	}, []);

	async function requisicoesExternas() {
		await handleSubmit();
		await validateForm();
		if (isValid) {
			setExibirLoadingGeracao(true);
			asyncGetControleDavs(
				format(parseISO(values.dataInicial), 'yyyy-MM-dd'),
				format(parseISO(values.dataFinal), 'yyyy-MM-dd'),
				({ data: xml }) => {
					baixarArquivo(
						xml,
						`ControleDAVs${format(parseISO(values.dataInicial), 'ddMMyyyy')}a${format(
							parseISO(values.dataFinal),
							'ddMMyyyy'
						)}.xml`
					);
					setExibirLoadingGeracao(false);
					onHide();
				},
				() => {
					setExibirLoadingGeracao(false);
				}
			);
		}
	}

	return (
		<>
			<Modal
				header="Controle dos DAVs"
				onHide={onHide}
				visible={visible}
				styleModal={{
					maxWidth: '600px',
				}}
				isMobile={isMobile}
				container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
			>
				<Form>
					<FormActions>
						<Button
							className="p-button p-button-primary"
							icon="fa fa-download"
							title="Gerar e baixar o arquivo XML"
							label="Gerar arquivo"
							onClick={requisicoesExternas}
						/>
					</FormActions>
					<FormContent>
						<Grid>
							<Field
								sm="12"
								md="6"
								lg="6"
								xl="6"
								component={InputDate}
								label="Data inicial"
								name="dataInicial"
								obrigatorio
								value={values.dataInicial}
								touched={true}
								helpMessage="Data inicial do período da geração do arquivo"
								onChange={e => {
									setFieldValue('dataInicial', e.target.value);
								}}
								id="ControleDavsInputDateDataInicial"
							/>

							<Field
								sm="12"
								md="6"
								lg="6"
								xl="6"
								component={InputDate}
								label="Data final"
								name="dataFinal"
								obrigatorio
								value={values.dataFinal}
								helpMessage="Data final do período da geração do arquivo"
								touched={true}
								onChange={e => {
									setFieldValue('dataFinal', e.target.value);
								}}
							/>
						</Grid>
					</FormContent>
				</Form>
			</Modal>
			<ModalLoadingTransmissao
				visible={exibirLoadingGeracao}
				message="Gerando controle dos DAVs..."
				container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
			/>
		</>
	);
}

const ModalControleDavs = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (!values.dataInicial || values.dataInicial === '') {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataFinal || values.dataFinal === '') {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		dataInicial: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		dataFinal: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
	}),

	handleSubmit: () => {},
})(ModalControleDavsView);

export default ModalControleDavs;
