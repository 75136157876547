export const detalhesStyle = {
  color: '#7b7b7b',
  margin: '8px 5px',
  display: 'flex',
  alignItems: 'center',
};

export const styleCardNormal = {
  margin: '8px',
  padding: '15px 5px',
  borderRadius: '10px',
  height: '100%',
  maxWidth: '320px',
  cursor: 'pointer',
};

export const styleCardAderido = {
  ...styleCardNormal,
  outline: '2px solid #36a536',
  WebkitBoxShadow: '0px 0px 10px 4px rgba(54, 165, 54,0.5)',
  MozBoxShadow: '0px 0px 10px 4px rgba(54, 165, 54,0.5)',
  boxShadow: '0px 0px 10px 4px rgba(54, 165, 54,0.5)',
};

export const styleNomePlanoNormal = {
  color: '#2a7ecb',
  display: 'flex',
  fontSize: '20px',
  textAlign: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
};

export const styleNomePlanoAderido = {
  ...styleNomePlanoNormal,
  color: '#36a536',
};

export const styleValorNormal = {
  margin: '5px',
  fontSize: '26px',
  fontWeight: 'bold',
  color: '#2a7ecb',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
};

export const styleValorAderido = {
  ...styleValorNormal,
  color: '#36a536',
};

export const styleButtonAdquirir = {
  marginTop: '20px',
  borderRadius: '25px',
  padding: '5px',
  minWidth: '180px',
};

export const valorLetrasStyle = {
  fontSize: '14px',
  fontWeight: 'normal',
};

export const contentStyle = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};
