import { useState } from 'react';
import { saveAs } from 'file-saver';
import { Menu } from 'primereact/menu';
import {
	asyncCancelarNota,
	asyncEstornarCancelamentoNota,
	asyncDownloadXml,
	asyncUpdateRegistro,
	asyncCreateRegistro,
	asyncUploadXml,
	asyncValidaNotaUnica,
	asyncFinalizarNota,
	asyncEstornarNota,
	asyncDeleteNotaEntrada,
} from '../../../Requests';
import {
	converterNotaParaApi,
	converterNotaParaFormulario,
	formatHoraParaFormulario,
} from '../../../Util/notaConverter';

import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	notify,
} from '../../../../../../components';
import { Messages } from '../../../Util/messages';
import { confirmarExclusao } from '../../../../../Util/ExclusaoDeRegistros';
import { atualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../../../Util';
import { useFormikContext } from 'formik';

function ActionButtons({
	podeEditar,
	podeInserir,
	podeExcluir,
	setModalHistorico,
	setIsFinalizado,
	setTabActivePagamentos,
	onCancelar,
	carregarNotaEntrada,
	setErrosPagamento,
	setTabIndex,
	setModalDadosFornecedor,
	setErrosItens,
	xml,
	onImportarXml,
	setModalAtualizacaoPrecos,
	setRecarregarParcelas,
	history,
	novo,
}) {
	const { dirty, values, handleSubmit, resetForm, validateForm, errors } = useFormikContext();

	const [menu, setMenu] = useState(null);

	const itensMenu = montarItensMenu();

	function montarItensMenu() {
		let itens = [];

		itens.push({
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setModalHistorico(true),
		});

		itens.push({
			separator: true,
			visible: true,
		});

		if ((values.situacao === 'PENDENTE' && !dirty) || values.situacao === 'FINALIZADA') {
			itens.push({
				label: 'Cancelar',
				icon: 'fa fa-times',
				command: () => onCancelarNota(values),
				disabled: !podeEditar,
			});
		} else if (values.situacao === 'CANCELADA') {
			itens.push({
				label: 'Estornar cancelamento',
				icon: 'pi pi-file-excel',
				command: () => onEstornarCancelamentoNota(values),
				disabled: !podeEditar,
			});
		}

		if (values.xml) {
			itens.push({
				label: 'Download XML',
				icon: 'pi pi-download',
				command: () => onDownloadXml(values),
			});
		}

		return itens;
	}

	function onCancelarNota(nota) {
		asyncCancelarNota(nota.id, ({ data: notaAtualizada }) => {
			setIsFinalizado(true);
			let notaConvertida = converterNotaParaFormulario(notaAtualizada);
			resetForm({ values: notaConvertida });
			setTabActivePagamentos(0);
		});
	}

	function onEstornarCancelamentoNota(nota) {
		asyncEstornarCancelamentoNota(nota.id, ({ data: notaAtualizada }) => {
			setIsFinalizado(false);
			let notaConvertida = converterNotaParaFormulario(notaAtualizada);
			resetForm({ values: notaConvertida });
			setTabActivePagamentos(0);
		});
	}

	async function onDownloadXml(nota) {
		if (nota.xml) {
			await asyncDownloadXml(nota.id, ({ data: xml }) => {
				let blob = new Blob([xml], { type: 'text/plain;charset=utf-8' });
				saveAs(blob, `${nota.chaveAcesso ? nota.chaveAcesso : nota.id}.xml`);
			});
		} else {
			notify('Nota de entrada sem XML vínculado', ToastTypes.ERROR);
		}
	}

	function salvar(novoOnSuccess) {
		salvarRegistro(novoOnSuccess);
	}

	async function salvarRegistro(novoOnSuccess) {
		let dadosFormulario = converterNotaParaApi(values);
		let errosFornecedor = validarFornecedor(dadosFormulario);
		let errosPagamento = validarPagamentos(dadosFormulario);
		let errosItens = validarItens(dadosFormulario);

		if (
			dirty &&
			(await validarFormulario({ values, validateForm })) &&
			errosFornecedor === false &&
			errosPagamento === false &&
			errosItens === false
		) {
			if (await validarNotaUnica(dadosFormulario)) {
				if (values.id) {
					await asyncUpdateRegistro(dadosFormulario, novoOnSuccess);
					if (!novoOnSuccess) {
						resetForm({ values: values });
					}
				} else {
					asyncCreateRegistro(dadosFormulario, async ({ data: registro }) => {
						let idXml = null;
						if (xml !== null && !registro.xml) {
							await asyncUploadXml(
								registro.id,
								xml,
								({ data: result }) => {
									idXml = result.id;
								},
								() => {}
							);
						}
						atualizarUrl(history, '/nota_entrada/cadastro', registro.id);
						resetForm({
							values: {
								...values,
								id: registro.id,
								xml: idXml,
								dataEmissao: registro.dataEmissao,
								horaEmissao: formatHoraParaFormulario(registro.horaEmissao),
							},
						});
						if (novoOnSuccess) {
							novoOnSuccess();
						}
					});
				}
			}
		} else {
			handleSubmit();
		}
	}

	async function validarNotaUnica(nota) {
		let result = true;
		let data = {
			numero: nota.numero,
			serie: nota.serie,
			modelo: nota.modelo.id,
			fornecedor: nota.emitente.pessoa?.id ? nota.emitente.pessoa.id : null,
			chaveAcesso: nota.chaveAcesso ? nota.chaveAcesso : null,
		};

		await asyncValidaNotaUnica(data, ({ data: dados }) => {
			if (dados.cadastrada && dados.idNotaEntrada !== nota.id) {
				confirm(
					'Aviso',
					Messages.NotaCadastrada,
					() => carregarNotaEntrada(dados.idNotaEntrada),
					() => {},
					'Acessar nota cadastrada'
				);
				result = false;
			}
		});
		return result;
	}

	function validarPagamentos() {
		if (errors.pagamentos) {
			setErrosPagamento(true);
			setTabIndex(1);
			return true;
		} else {
			setErrosPagamento(false);
			return false;
		}
	}

	function validarFornecedor(dadosFormulario) {
		if (
			!dadosFormulario.emitente?.cep &&
			!dadosFormulario.emitente?.municipio?.id &&
			!dadosFormulario.emitente?.logradouro &&
			!dadosFormulario.emitente?.numero &&
			dadosFormulario.emitente?.nome
		) {
			setModalDadosFornecedor(true);
			return true;
		} else {
			return false;
		}
	}

	function validarItens(dadosFormulario) {
		if (dadosFormulario.itens.length < 1) {
			setErrosItens(true);
			return true;
		} else {
			setErrosItens(false);
			return false;
		}
	}

	function onClickNovo() {
		dirty ? salvar(novo) : novo();
	}

	async function onFinalizarEstornarNota(nota) {
		if (nota.situacao === 'PENDENTE') {
			asyncFinalizarNota(nota.id, async ({ data: notaAtualizada }) => {
				setIsFinalizado(true);
				let notaConvertida = converterNotaParaFormulario(notaAtualizada);
				resetForm({ values: notaConvertida });
				setModalAtualizacaoPrecos(true);
			});
		} else if (nota.situacao === 'FINALIZADA') {
			asyncEstornarNota(nota.id, ({ data: notaAtualizada }) => {
				setIsFinalizado(false);
				let notaConvertida = converterNotaParaFormulario(notaAtualizada);
				setRecarregarParcelas(true);
				setTabActivePagamentos(0);
				resetForm({ values: notaConvertida });
			});
		}
	}

	async function onExcluirNota() {
		await confirmarExclusao(async () => {
			await asyncDeleteNotaEntrada(values.id, () => {
				voltarParaAPesquisa(history, '/nota_entrada');
			});
		});
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={
					dirty
						? values.situacao === 'FINALIZADA'
							? estadosBotaoCancelar.VOLTAR
							: estadosBotaoCancelar.CANCELAR
						: estadosBotaoCancelar.VOLTAR
				}
				onClick={() => onCancelar()}
			/>
			<ButtonSalvar
				disabled={!dirty || (values.id && !podeEditar) || values.situacao === 'FINALIZADA' ? true : false}
				onClick={() => salvar()}
			/>
			<ButtonNovo
				estadoBotao={
					dirty
						? values.situacao === 'FINALIZADA'
							? estadosBotaoNovo.NOVO
							: estadosBotaoNovo.SALVAR_E_NOVO
						: estadosBotaoNovo.NOVO
				}
				hidden={!values.id && !dirty}
				disabled={!podeInserir}
				onClick={() => onClickNovo()}
			/>
			<ButtonExcluir
				onClick={() => onExcluirNota()}
				hidden={!values.id}
				disabled={values.situacao !== 'PENDENTE' || !podeExcluir}
			/>
			<Button
				className="p-button-primary"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={!values.id}
				aria-controls="popup_menu"
				aria-haspopup={true}
				onClick={(event) => {
					menu.toggle(event);
				}}
			/>
			<Button
				className="p-button-primary"
				label="Importar XML"
				icon="pi pi-download"
				style={{ margin: '5px' }}
				hidden={!!values.id || dirty}
				disabled={!podeInserir}
				onClick={() => {
					onImportarXml();
				}}
			/>
			<Button
				label={values.situacao === 'PENDENTE' ? 'Finalizar nota' : 'Estornar nota'}
				icon="fa fa-file-text-o"
				color={values.situacao === 'PENDENTE' ? 'success' : 'primary'}
				style={{ margin: '5px' }}
				onClick={() => onFinalizarEstornarNota(values)}
				hidden={values.situacao === 'CANCELADA' || !values.id || dirty}
				disabled={!podeEditar || values.itens?.length === 0}
			/>
			<Menu model={itensMenu} popup={true} style={{ width: '200px' }} ref={(elemento) => setMenu(elemento)} />
		</>
	);
}

export default ActionButtons;
