import { Chart } from 'chart.js';
import { useEffect, useRef } from 'react';

import { If } from 'components';
import { formatarMonetario } from 'Common';

import TituloCard from 'components/TituloCard';
import { ProgressBar } from 'primereact/progressbar';
import { VENDAS_CORES } from '../../Util/constantes';

const cardContent = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
};

let chartServicos;

function ChartUltimosDozeMeses({ data, title, label, helpMessage, isMobile, loading }) {
	const graficoServicosUltimosDozeMeses = useRef();

	const emptyData = !data.meses?.length;

	const cardStyle = {
		borderRadius: '5px',
		backgroundColor: 'white',
		boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
		minHeight: '100%',
		padding: isMobile ? '7px 0 0 0' : '7px',
	};

	useEffect(() => {
		return () => {
			if (typeof chartServicos !== 'undefined') {
				chartServicos.destroy();
			}
		};
	}, []);

	setTimeout(() => {
		buildChart();
	}, 300);

	function buildChart() {
		if (!graficoServicosUltimosDozeMeses.current || emptyData) {
			return;
		}

		const myChartRef = graficoServicosUltimosDozeMeses.current?.getContext('2d');

		if (typeof chartServicos !== 'undefined') {
			chartServicos.destroy();
		}

		chartServicos = new Chart(myChartRef, {
			type: 'bar',
			data: {
				labels: data.meses,
				datasets: [
					{
						label: label,
						data: data.finalizados,
						backgroundColor: VENDAS_CORES.verde,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				aspectRatio: 1,
				animation: {
					animateScale: true,
					animateRotate: true,
				},
				plugins: {
					datalabels: {
						color: VENDAS_CORES.preto,
					},
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							usePointStyle: true,
						},
						onHover: function (e) {
							e.native.target.style.cursor = 'pointer';
						},
						onLeave: (event) => {
							event.native.target.style.cursor = 'default';
						},
					},
					tooltip: {
						callbacks: {
							title: (tooltipItem) => {
								return `Competência: ${tooltipItem[0].label}`;
							},
							label: (tooltipItem) => {
								const label = tooltipItem.dataset.label;
								const valor = formatarMonetario(tooltipItem.raw);
								return `${label}: ${valor}`;
							},
						},
					},
				},
				scales: {
					y: {
						beginAtZero: true,
						ticks: {
							callback: (value) => formatarMonetario(value),
						},
					},
				},
			},
		});
	}

	return (
		<div style={cardStyle}>
			<TituloCard title={title} helpMessage={helpMessage} style={{ padding: isMobile ? '7px 0 0 7px' : '7px' }} />
			<If test={loading}>
				<div style={cardContent}>
					<ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />
				</div>
			</If>

			<If test={!loading && !emptyData}>
				<div
					style={{
						marginTop: '20px',
						padding: isMobile ? '0px' : '7px 0 0 7px',
					}}
				>
					<canvas
						id="graficoServicosUltimosDozeMeses"
						ref={graficoServicosUltimosDozeMeses}
						style={{ maxWidth: '100%' }}
					/>
				</div>
			</If>
		</div>
	);
}

export { ChartUltimosDozeMeses };
