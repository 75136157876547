import { useState, useEffect } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../Util';
import { helpOrganizacoesForm } from './Help';
import { converterParaFormulario, converterParaApi } from './Util/agenteAutorizadoConverter';
import {
	asyncCreateAgenteAutorizado,
	asyncDeleteAgenteAutorizado,
	asyncGetAgenteAutorizado,
	asyncUpdateAgenteAutorizado,
} from './Requests';
import { isValidCPF, isValidCNPJ } from '@brazilian-utils/brazilian-utils';
import { connect } from 'react-redux';
import stringMask from 'string-mask';
import { TabPanel } from 'primereact/tabview';
import { renderizarFieldsEndereco } from './components/FieldsEndereco';
import { situacaoAgenteAutorizado, tabsAgentesAutorizados, tipoAgenteAutorizado } from './Util/constantes';
import FieldsLinkModulosPersonalizados from './components/FieldsLinkModulosPersonalizados';

import {
	mensagensDeValidacao,
	buscarMascaraTelefone,
	manterApenasNumeros,
	recursos,
	permissoes,
	estadosCadastro,
	validarUUID,
	usuarioPossuiPermissao,
} from 'Common';

import {
	ButtonNovo,
	estadosBotaoNovo,
	ButtonCancelar,
	estadosBotaoCancelar,
	ButtonSalvar,
	estadosBotaoSalvar,
	ButtonExcluir,
	InputField,
	Grid,
	InputMask,
	Dropdown,
	Prompt,
	confirm,
	FormActions,
	FormContent,
	Form,
	InputDouble,
	TabView,
} from 'components';

const initialValues = {
	id: null,
	nome: null,
	situacao: 'ATIVO',
	tipo: 'JURIDICA',
	percentualComissao: 0,
	cpf: null,
	cnpj: null,
	email: null,
	telefone: null,
	logradouro: null,
	numero: null,
	bairro: null,
	complemento: null,
	cep: null,
	municipio: null,
	municipioExterior: null,
	observacao: '',
	pais: {
		label: 'Brasil',
		registro: {
			codigo: 1058,
			ddi: 55,
			id: '34e63b2e-c596-f34f-824d-bfd27eb62fa8',
			nome: 'Brasil',
			sigla: 'BRA',
		},
		value: '34e63b2e-c596-f34f-824d-bfd27eb62fa8',
	},
};

const CADASTROURL = '/agentes_autorizados/cadastro';
const PESQUISAURL = '/agentes_autorizados';

const styleInativado = {
	fontStyle: 'italic',
	opacity: '0.8',
};

function AgentesAutorizadosForm(props) {
	const {
		hideModal,
		handleSubmit,
		handleReset,
		setFieldValue,
		values,
		dirty,
		isModal,
		isMobile,
		match,
		history,
		resetForm,
	} = props;

	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.INSERIR)
	);
	const [podeEditar, setPodeEditar] = useState(
		usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.EDITAR)
	);
	const [podeExcluir, setPodeExcluir] = useState(
		usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.EXCLUIR)
	);
	const [tabSelecionada, setTabSelecionada] = useState(tabsAgentesAutorizados.ENDERECO);

	useEffect(() => {
		const id = match && match.params.id;
		if (validarUUID(id)) {
			asyncSelectRegistro(id);
		}

		setTimeout(() => {
			document.getElementById('AgentesAutorizadosInputFieldNome')?.focus();
		}, 500);
	}, []);

	function buscarEstadoCadastro() {
		return values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	function novo() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: initialValues });
	}

	function excluir() {
		confirm('Confirmação', 'Tem certeza que deseja excluir o registro?', async () => {
			await asyncDeleteRegistro();
		});
	}

	function fecharModal() {
		if (values.id) {
			hideModal(values);
		} else {
			hideModal();
		}
	}

	async function salvar(e, novoOnSuccess) {
		handleSubmit();

		if (await validarFormulario(props)) {
			if (values.id) {
				await asyncUpdateRegistro(values, novoOnSuccess);
			} else {
				await asyncCreateRegistro(values, novoOnSuccess);
			}
			if (hideModal) {
				fecharModal();
			}
		}
	}

	function cancelar() {
		if (dirty) {
			handleReset();
		} else if (isModal) {
			fecharModal();
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteAgenteAutorizado(values.id, () => {
			resetForm();
			voltarParaAPesquisa(history, PESQUISAURL);
		});
	}

	async function asyncUpdateRegistro(values, novoOnSuccess) {
		await asyncUpdateAgenteAutorizado(converterParaApi(values), () => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				resetForm({ values: values });
				novoOnSuccess && novoOnSuccess();
			}
		});
	}

	async function asyncCreateRegistro(values, novoOnSuccess) {
		if (values) {
			await asyncCreateAgenteAutorizado(converterParaApi(values), async ({ data: agenteAutorizado }) => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					resetForm({ values: { ...values, id: agenteAutorizado.id } });
					if (!isModal) {
						atualizarUrl(history, CADASTROURL, agenteAutorizado.id, metodosAtualizarUrl.POP);
					}
				}
			});
		}
	}

	async function asyncSelectRegistro(idAgenteAutorizado) {
		await asyncGetAgenteAutorizado(idAgenteAutorizado, ({ data: agenteAutorizado }) => {
			resetForm({ values: converterParaFormulario(agenteAutorizado) });
			if (!isModal) {
				atualizarUrl(history, CADASTROURL, agenteAutorizado.id, metodosAtualizarUrl.POP);
			}
		});
	}

	function habilitarTipoOrganizacao(e) {
		setFieldValue('tipo', e);
	}

	function onChangeComissao(e) {
		if (e.target.value <= 100) {
			setFieldValue('percentualComissao', e.target.value);
		} else {
			setFieldValue('percentualComissao', values.percentualComissao);
		}
	}

	function montarCabecalho() {
		return (
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<span>Cadastro de agente autorizado</span>
				<span></span>
			</div>
		);
	}

	function selecionarTab(event) {
		setTabSelecionada(event.index);

		setTimeout(() => {
			switch (event.index) {
				case 0:
					document.getElementById('AgentesAutorizadosInputCep')?.focus();
					break;
				default:
					break;
			}
		}, 500);
	}

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};

	function estadoBotaoNovo() {
		if (dirty) {
			return estadosBotaoNovo.SALVAR_E_NOVO;
		} else {
			return estadosBotaoNovo.NOVO;
		}
	}

	function onClickNovo(e) {
		if (dirty) {
			salvar(e, novo());
		} else {
			novo();
		}
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Form
				header={montarCabecalho()}
				isModal={isModal}
				className="card-default screen-max-width"
				style={values.situacao == 'INATIVO' ? styleInativado : null}
			>
				<FormActions className="screen-max-width">
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
						{...informacoesPermissoes}
					/>
					<ButtonSalvar
						estadoBotao={estadosBotaoSalvar.SALVAR}
						disabled={!dirty}
						onClick={() => salvar()}
						{...informacoesPermissoes}
					/>
					<ButtonNovo
						onClick={() => onClickNovo()}
						hidden={(!dirty && !values.id) || isModal}
						estadoBotao={estadoBotaoNovo}
						{...informacoesPermissoes}
					/>
					<ButtonExcluir hidden={!values.id} onClick={() => excluir()} {...informacoesPermissoes} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							label="Nome"
							name="nome"
							size={60}
							obrigatorio
							helpMessage={helpOrganizacoesForm.nome}
							component={InputField}
							id="AgentesAutorizadosInputFieldNome"
							style={values.situacao == 'INATIVO' ? styleInativado : null}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							label="Situação"
							name="situacao"
							obrigatorio
							showClear={false}
							onChange={e => setFieldValue('situacao', e.value)}
							options={situacaoAgenteAutorizado}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={Dropdown}
							label="Tipo"
							name="tipo"
							obrigatorio
							showClear={false}
							onChange={e => habilitarTipoOrganizacao(e.value)}
							options={tipoAgenteAutorizado}
							{...informacoesPermissoes}
						/>
						<InputDouble
							sm="12"
							md="2"
							lg="2"
							xl="2"
							label="Comissão"
							name="percentualComissao"
							onChange={e => onChangeComissao(e)}
							size={15}
							helpMessage={helpOrganizacoesForm.percentualComissao}
							prefix=""
							suffix=" %"
							value={values.percentualComissao}
							placeholder="0,00 %"
							allowNegative={false}
							style={values.situacao == 'INATIVO' ? styleInativado : null}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							hidden={values.tipo !== 'FISICA'}
							component={InputMask}
							mask="000.000.000-00"
							placeholder="   .   .   -  "
							label="CPF"
							name="cpf"
							onChange={e => setFieldValue('cpf', e.target.value)}
							style={values.situacao == 'INATIVO' ? styleInativado : null}
							obrigatorio
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							hidden={values.tipo !== 'JURIDICA'}
							component={InputMask}
							mask="00.000.000/0000-00"
							placeholder="  .   .   /    -  "
							label="CNPJ"
							name="cnpj"
							obrigatorio
							onChange={e => setFieldValue('cnpj', e.target.value)}
							style={values.situacao == 'INATIVO' ? styleInativado : null}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							label="E-mail"
							estadoCadastro={() => buscarEstadoCadastro()}
							name="email"
							obrigatorio
							size={255}
							component={InputField}
							style={values.situacao == 'INATIVO' ? styleInativado : null}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputMask}
							mask={buscarMascaraTelefone(values.telefone)}
							placeholder={'(  )      -    '}
							label="Telefone"
							obrigatorio
							name="telefone"
							size={16}
							onChange={e => setFieldValue('telefone', e.target.value)}
							style={values.situacao == 'INATIVO' ? styleInativado : null}
							{...informacoesPermissoes}
						/>
					</Grid>
					<Grid>
						<TabView
							className="tab-view"
							onTabChange={e => selecionarTab(e)}
							activeIndex={tabSelecionada}
							{...informacoesPermissoes}
						>
							<TabPanel header="Endereço">
								{renderizarFieldsEndereco(values, setFieldValue, informacoesPermissoes, styleInativado)}
							</TabPanel>
							<TabPanel
								disabled={values.id === null}
								header={isMobile ? 'Link criação de conta' : 'Gerar link para criação de conta'}
							>
								<FieldsLinkModulosPersonalizados values={values} {...informacoesPermissoes} />
							</TabPanel>
						</TabView>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const AgentesAutorizadosView = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValues;
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		email: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).email('E-mail inválido'),
		telefone: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	validate(values) {
		let errors = {};

		if (values.tipo === 'FISICA') {
			if (!values.cpf) errors.cpf = mensagensDeValidacao.OBRIGATORIO;

			if (values.cpf && !isValidCPF(manterApenasNumeros(values.cpf))) errors.cpf = 'Digite um CPF válido.';
		}

		if (
			!new stringMask('(00) 00000-0000').validate(manterApenasNumeros(values.telefone)) &&
			!new stringMask('(00) 0000-0000').validate(manterApenasNumeros(values.telefone))
		) {
			errors.telefone = mensagensDeValidacao.TELEFONE_INVALIDO;
		}

		if (values.tipo === 'JURIDICA') {
			if (!values.cnpj) errors.cnpj = mensagensDeValidacao.OBRIGATORIO;

			if (values.cnpj && !isValidCNPJ(manterApenasNumeros(values.cnpj))) errors.cnpj = 'Digite um CNPJ válido.';
		}

		if (values.percentualComissao > 100) {
			errors.percentualComissao = 'O percentual de comissão não pode ser maior que 100%';
		}
		return errors;
	},

	handleSubmit: () => {},
})(AgentesAutorizadosForm);

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(AgentesAutorizadosView);
