import { services } from 'Common';
import { Pagamentos } from 'components';
import { FINANCEIRO_TIPO } from 'components/Pagamentos/Util/constantes';
import { useFormikContext } from 'formik';
import { useContextOrdemServico } from 'views/fiscal/servicos/OrdemServico/Context';
import { CLASSNAME_TABVIEW_COLOR, SITUACAO_ORDEM_SERVICO } from 'views/fiscal/servicos/OrdemServico/Util/constantes';

function TabPagamentos() {
	const { values, errors, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, disabledFields, favoritos, totais } = useContextOrdemServico();

	function onChangePagamentos(pagamentos) {
		setFieldValue('pagamentos', pagamentos);
	}

	function onChangePagamento(index, value) {
		setFieldValue(`pagamentos[${index}]`, value);
	}

	function onChangePagamentoField(index, field, value) {
		setFieldValue(`pagamentos[${index}].[${field}]`, value);
	}

	return (
		<Pagamentos
			idOrigem={values.id}
			pagamentos={values.pagamentos}
			valorTotal={totais.valorTotal}
			dataBaseParcela={values.dataAbertura}
			urls={{
				urlBase: `${services.GESTOR}/v1/ordem_servico`,
				condicaoPagamento: `${services.GESTOR}/v1/ordem_servico/relacoes/condicoes_pagamento`,
				formaPagamento: `${services.GESTOR}/v1/ordem_servico/relacoes/formas_pagamento`,
				conta: `${services.GESTOR}/v1/ordem_servico/relacoes/contas`,
				categoria: `${services.GESTOR}/v1/ordem_servico/relacoes/categorias/receitas`,
			}}
			informacoesPermissoes={informacoesPermissoes}
			disabledFields={disabledFields}
			favoritos={favoritos}
			hideTabFinanceiro={values.situacao !== SITUACAO_ORDEM_SERVICO.FINALIZADO}
			onChangePagamentos={onChangePagamentos}
			onChangePagamento={onChangePagamento}
			onChangePagamentoField={onChangePagamentoField}
			errors={errors?.pagamentos}
			classNameTabView={CLASSNAME_TABVIEW_COLOR[values.situacao].class}
			color={CLASSNAME_TABVIEW_COLOR[values.situacao].color}
			hiddenButtonAdd={values.situacao !== SITUACAO_ORDEM_SERVICO.PENDENTE}
			financeiroTipo={FINANCEIRO_TIPO.CONTA_RECEBER}
		/>
	);
}

export { TabPagamentos };
