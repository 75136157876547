import { Field, useFormikContext } from 'formik';

import { Card, Dropdown, Grid, InputField, InputMask, SingleSelectNcm } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { MDFE_TIPO_CARGA_PREDOMINANTE_OPTIONS } from 'views/transporte/MDFe/Form/Util/constantes';

function ProdutoPredominante() {
	const { values, setFieldValue, errors } = useFormikContext();
	const { informacoesPermissoes, isSituacaoFinalMDFe } = useContextMDFe();

	function onChangeNcm(event) {
		if (event !== null) {
			setFieldValue('produtoPredominante.ncm', event);
		} else {
			setFieldValue('produtoPredominante.ncm', null);
		}
	}

	return (
		<Card>
			<Grid>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={Dropdown}
					obrigatorio
					label="Tipo de carga"
					name="produtoPredominante.tipoCarga"
					showClear={false}
					options={MDFE_TIPO_CARGA_PREDOMINANTE_OPTIONS}
					onChange={(e) => setFieldValue('produtoPredominante.tipoCarga', e.value)}
					errors={errors.produtoPredominante?.tipoCarga}
					useFormErrors={false}
					touched
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="8"
					lg="8"
					xl="8"
					component={InputField}
					label="Descrição"
					name="produtoPredominante.descricao"
					size={120}
					obrigatorio
					onChange={(e) => setFieldValue('produtoPredominante.descricao', e.target.value)}
					errors={errors.produtoPredominante?.descricao}
					useFormErrors={false}
					touched
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					label="NCM"
					name="produtoPredominante.ncm"
					component={SingleSelectNcm}
					value={values?.produtoPredominante?.ncm}
					onChange={(event) => onChangeNcm(event)}
					useFormErrors={false}
					useFormTouched={false}
					listarTributacoesCadastradas={false}
					listarTributacaoNcm={false}
					disabled={isSituacaoFinalMDFe}
					esconderBotao
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					label="Código EAN"
					name="produtoPredominante.codigoEan"
					size={14}
					onChange={(e) => setFieldValue('produtoPredominante.codigoEan', e.target.value)}
					errors={errors.produtoPredominante?.codigoEan}
					useFormErrors={false}
					touched
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMask}
					mask="00000-000"
					placeholder=""
					obrigatorio
					label="CEP carregamento"
					name="produtoPredominante.carregamentoCep"
					value={values.produtoPredominante?.carregamentoCep}
					onChange={(e) => setFieldValue('produtoPredominante.carregamentoCep', e.target.value)}
					errors={errors.produtoPredominante?.carregamentoCep}
					useFormErrors={false}
					touched
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMask}
					mask="00000-000"
					placeholder=""
					obrigatorio
					label="CEP descarregamento"
					name="produtoPredominante.descarregamentoCep"
					value={values.produtoPredominante?.descarregamentoCep}
					onChange={(e) => setFieldValue('produtoPredominante.descarregamentoCep', e.target.value)}
					errors={errors.produtoPredominante?.descarregamentoCep}
					useFormErrors={false}
					touched
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
			</Grid>
		</Card>
	);
}

export { ProdutoPredominante };
