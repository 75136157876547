export function Badge(
	fontColor,
	backgroundColor,
	description = '',
	styleBackground = {},
	styleDescription = {},
	useEllipsis = false,
	title = null
) {
	const styleBackgroundEllipsis = () => {
		if (useEllipsis) {
			return {
				maxWidth: '100px',
				display: 'block',
			};
		}
		return {};
	};

	const styleDescriptionEllipsis = () => {
		if (useEllipsis) {
			return {
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				display: 'block',
			};
		}
		return {};
	};

	return (
		<span
			style={{
				backgroundColor: backgroundColor,
				color: fontColor,
				fontWeight: 'bold',
				fontSize: '13px',
				...styleBackground,
				...styleBackgroundEllipsis(),
			}}
			title={title ?? description}
		>
			<span
				style={{
					width: '100%',
					textAlign: 'center',
					whiteSpace: 'pre',
					...styleDescription,
					...styleDescriptionEllipsis(),
				}}
			>
				{description}
			</span>
		</span>
	);
}
