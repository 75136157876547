export const contentStyle = {
  height: "98%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export const cardStyle = {
  width: "300px",
  borderRadius: "5px",
};

export const subTitleStyle = {
  fontWeight: "normal",
  fontSize: "14px",
  color: "#666666",
};

export const titleStyle = {
  fontWeight: "normal",
  fontSize: "20px",
  color: "#666666",
};

export const imgStyle = {
  width: "200px",
};

export const successStyle = {
  backgroundColor: "rgba(0, 0, 0, 0.06)",
  borderRadius: "2px",
  padding: "1rem",
  marginTop: "10px",
  marginBottom: "20px",
};

export const errorStyle = {
  backgroundColor: "rgba(0, 0, 0, 0.06)",
  borderRadius: "2px",
  padding: "1rem",
  marginTop: "10px",
  marginBottom: "20px",
};
