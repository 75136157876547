export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é o cadastro de manifesto de documento fiscal eletrônico.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-mdfe-campos-principais',
		content:
			'Nesta área são preenchidas as informações básicas do MDF-e, falaremos sobre as mais importantes em sequência.',
	},
	{
		target: '.step-mdfe-tipo-emitente',
		content:
			'O campo "Tipo de emitente" permite escolher o tipo de MDF-e, entre transporte próprio ou serviço de transporte.',
	},
	{
		target: '.step-mdfe-tipo-uf-carregamento',
		content: 'O campo "UF carregamento" deve ser preenchido com a uf de início do transporte.',
	},
	{
		target: '.step-mdfe-tipo-uf-descarregamento',
		content: 'O campo "UF descarregamento" deve ser preenchido com a uf final do transporte.',
	},
	{
		target: '.step-mdfe-tipo-veiculo-tracao',
		content: 'O campo "Veículo" deve ser preenchido com o veículo de tração que realizará o transporte.',
	},
	{
		target: '.step-mdfe-tipo-uf-percurso',
		content:
			'O campo "UF percurso" deve ser preenchido os campos "UF carregamento" e "UF descarregamento" forem diferentes.',
	},
	{
		target: '.step-mdfe-tabs-principais',
		content:
			'Aqui são preenchidos as informações principais do MDF-e, como os documentos a serem transportados, condutores e reboque caso necessário.',
	},
	{
		target: '.step-mdfe-tabs-totalizadores',
		content: 'Por fim, nessa área são preenchidos os totalizadores do MDF-e, além das informações complementares.',
	},
];
