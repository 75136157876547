import {
	Button,
	ButtonCancelar,
	estadosBotaoCancelar,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	Modal,
} from 'components';
import { useState } from 'react';
import { connect } from 'react-redux';
import AlterarEmail from './AlterarEmail';
import AlterarSenha from './AlterarSenha';

const buttonsStyle = {
	width: '170px',
	height: '35px',
	margin: '10px 10px 0 0',
};

function MinhaConta({ isMobile, history }) {
	const [visibleModalEmail, setVisibleModalEmail] = useState(false);
	const [visibleModalSenha, setVisibleModalSenha] = useState(false);

	function cancelar() {
		history.goBack();
	}

	function esconderModalSenha() {
		setVisibleModalSenha(!visibleModalSenha);
	}

	function esconderModalEmail() {
		setVisibleModalEmail(!visibleModalEmail);
	}

	return (
		<>
			<Form header="Minha conta">
				<FormActions>
					<ButtonCancelar onClick={cancelar} estadoBotao={estadosBotaoCancelar.VOLTAR} />
				</FormActions>
				<FormContent>
					<Grid style={{ padding: '0px 10px', display: 'flex', flexDirection: 'column' }}>
						<div style={{ fontSize: '16px', margin: '6px 0px', fontWeight: 'bold' }}>Segurança</div>
						<p style={{ padding: '0 0 10px 0' }}>
							Clicando abaixo você poderá alterar o seu e-mail ou sua senha de acesso ao sistema.
						</p>
						<div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
							<Button
								type="button"
								label="Alterar e-mail"
								icon="fa fa-envelope"
								onClick={esconderModalEmail}
								style={buttonsStyle}
							/>
							<Button
								type="button"
								label="Alterar senha"
								icon="fa fa-key"
								onClick={esconderModalSenha}
								style={buttonsStyle}
							/>
						</div>
					</Grid>
				</FormContent>
			</Form>
			<If test={visibleModalEmail}>
				<Modal header="Alterar e-mail" visible={visibleModalEmail} onHide={esconderModalEmail}>
					<AlterarEmail onHide={esconderModalEmail} />
				</Modal>
			</If>

			<If test={visibleModalSenha}>
				<Modal header="Alterar senha" visible={visibleModalSenha} onHide={esconderModalSenha}>
					<AlterarSenha onHide={esconderModalSenha} />
				</Modal>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(MinhaConta);
