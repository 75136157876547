import { useState } from 'react';
import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { ModalCondicaoPagamento } from 'views/cadastros/CondicaoPagamento/Modal';
// eslint-disable-next-line import/no-cycle
import SingleSelect from '../SingleSelect';

function SingleSelectCondicaoPagamento({ url, hideAPrazo = false, hideSemPagamento = false, indicacaoMovimento = 'SAIDA',...props }) {
	const [visible, setVisible] = useState(false);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.CONDICOES_PAGAMENTO, permissoes.INSERIR));
	const [podeVisualizar] = useState(usuarioPossuiPermissao(recursos.CONDICOES_PAGAMENTO, permissoes.VISUALIZAR));
	const [resetPesquisa, setResetPesquisa] = useState(false);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		let urlWithRsql = `${url}?query=descricao=contains="*${pesquisa}*";situacao=="ATIVO"`;

		if (hideAPrazo) {
			urlWithRsql = urlWithRsql.concat(`;tipo!=A_PRAZO`);
		}

		if (hideSemPagamento) {
			urlWithRsql = urlWithRsql.concat(`;tipo!=SEM_PAGAMENTO`);
		}

		if (indicacaoMovimento){
			urlWithRsql = urlWithRsql.concat(`;movimentacao==${indicacaoMovimento}`);
		}

		urlWithRsql = urlWithRsql.concat(`&page=${page}&size=50&sort=descricao`);

		return urlWithRsql;
	}

	function buscarUrlRegistroUnico(id) {
		return `${url}?query=id==${id}`;
	}

	function montarLabel(row) {
		return row.descricao;
	}

	function mostrarModal() {
		setVisible((state) => !state);
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro?.id) {
			props.onChange({
				label: novoRegistro.descricao,
				registro: novoRegistro,
				value: novoRegistro.id,
			});
			setResetPesquisa(true);
		}
	}

	function esconderModal() {
		setVisible(false);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	return (
		<>
			<ModalCondicaoPagamento visible={visible} onHide={onHide} />
			<SingleSelect
				buscarUrlPesquisa={buscarUrlPesquisa}
				buscarUrlRegistroUnico={buscarUrlRegistroUnico}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				disabledButton={!podeInserirEVisualizar}
				titleBotaoNovo={
					podeInserirEVisualizar
						? 'Adicionar nova condição pagamento'
						: 'Você não possui permissão para executar esta ação'
				}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
				{...props}
			/>
		</>
	);
}

export { SingleSelectCondicaoPagamento };
