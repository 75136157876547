import { Field, useFormikContext } from 'formik';

import { services } from 'Common';
import { ButtonExcluirTable, Col, Divider, Grid } from 'components';

import { useContextVendedores } from 'views/cadastros/Vendedores/Context';

import SingleSelectRegiao from 'components/select/SingleSelectRegiao';
import SingleSelectTabelaPreco from 'components/select/SingleSelectTabelaPreco';

function RegioesTabelaPrecos({ index, excluirItem, optionsRemoveRegiao }) {
	const { values, setFieldValue, errors } = useFormikContext();

	const { informacoesPermissoes, isMobile, isTablet, desabilitarCampos } = useContextVendedores();

	function onClickExcluir() {
		if (typeof excluirItem === 'function') {
			excluirItem();
		}
	}

	function onChangeRegiao(event) {
		setFieldValue(`regioesTabelaPrecos[${index}].regiao`, event);

		optionsRemoveRegiao.current?.push({ ...event, idRegistro: event.value });
	}

	return (
		<Grid style={{ width: '100%' }}>
			<Field
				sm="12"
				md="5"
				lg="5"
				xl="5"
				component={SingleSelectRegiao}
				label="Região"
				name="regioesTabelaPrecos[index].regiao"
				value={values.regioesTabelaPrecos[index].regiao}
				errors={errors?.regioesTabelaPrecos?.length > 0 ? errors?.regioesTabelaPrecos[index]?.regiao : null}
				touched={errors?.regioesTabelaPrecos?.length > 0}
				useFormErrors={false}
				useFormTouched={false}
				onChange={(event) => onChangeRegiao(event)}
				url={`${services.GESTOR}/v1/vendas/vendedor/relacoes/regiao`}
				isClearable={false}
				disabledButton
				esconderBotao
				optionsRemover={optionsRemoveRegiao.current}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={SingleSelectTabelaPreco}
				label="Tabela de preço"
				name="regioesTabelaPrecos[index].tabelaPreco"
				value={values.regioesTabelaPrecos[index].tabelaPreco}
				errors={errors?.regioesTabelaPrecos?.length > 0 ? errors?.regioesTabelaPrecos[index]?.tabelaPreco : null}
				touched={errors?.regioesTabelaPrecos?.length > 0}
				useFormErrors={false}
				useFormTouched={false}
				onChange={(event) => setFieldValue(`regioesTabelaPrecos[${index}].tabelaPreco`, event)}
				url={`${services.GESTOR}/v1/vendas/vendedor/relacoes/tabela_preco`}
				showClear={false}
				disabledButton
				esconderBotao
				{...informacoesPermissoes}
			/>
			<Col
				sm="12"
				md="1"
				lg="1"
				xl="1"
				style={
					isMobile || isTablet
						? { display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }
						: { height: '70px', display: 'flex' }
				}
			>
				<ButtonExcluirTable
					onClick={() => (informacoesPermissoes.podeEditar ? onClickExcluir() : null)}
					title="Excluir item"
					disabled={desabilitarCampos}
					styleContentButton={{ marginTop: '24px' }}
				/>
			</Col>
			{isMobile || isTablet ? (
				<Divider styleContainer={{ marginBottom: '16px' }} styleLine={{ borderBottom: '0.5px solid' }} />
			) : null}
		</Grid>
	);
}
export { RegioesTabelaPrecos };
