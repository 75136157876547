import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Checkbox as InternalCheckbox } from 'primereact/checkbox';
import Col from '../../Col';
import { renderizarValidacao } from '../../../Common/TratamentoDeErro/validacoesDeCampos';
import { buscarDisabledDeAcordoComAsPermissoes } from '../../../Common/Autorizacao/ManipulacaoDeComponentes';
import { usuarioPossuiModulos } from '../../../Common/Autenticacao';

function Checkbox(props) {
	const { field, form, className, ...rest } = props;

	const [validouModulo, setValidouModulo] = useState(true);

	useEffect(() => {
		if (props.modulosEspecificos) {
			setValidouModulo(usuarioPossuiModulos(props.modulosEspecificos));
		}
	}, []);

	function getColStyle() {
		if (props.hidden) {
			return { display: 'none', ...props.colStyle };
		}
		return props.colStyle;
	}

	if (!validouModulo || !props.podeVisualizar) {
		return null;
	}

	const disabled = buscarDisabledDeAcordoComAsPermissoes(
		rest.podeInserir,
		rest.podeEditar,
		rest.estadoCadastro,
		rest.disabled
	);

	return (
		<Col id={rest.colId} col={rest.col} sm={rest.sm} md={rest.md} lg={rest.lg} xl={rest.xl} style={getColStyle()}>
			<span title={rest.title} className={`p-fluid ${className}`}>
				<InternalCheckbox
					{...field}
					{...rest}
					disabled={disabled}
					inputId={`id-checkbox-${field && field.name ? field.name : rest.name}`}
				/>
				{rest.label ? (
					<label
						title={props.helpMessage}
						style={rest && rest.style ? rest.style : null}
						htmlFor={`id-checkbox-${field && field.name ? field.name : rest.name}`}
						className={disabled ? 'p-checkbox-label p-disabled' : 'p-checkbox-label'}
					>
						{rest.label}
					</label>
				) : null}
			</span>
			{renderizarValidacao(rest.errors, rest.touched, rest.warning, rest.helpText, disabled)}
		</Col>
	);
}

Checkbox.defaultProps = {
	podeVisualizar: true,
};

Checkbox.propTypes = {
	/** Define o id do componente */
	id: propTypes.any,
	/** Define o nome do componente */
	name: propTypes.string,
	/** Define o label do componente */
	label: propTypes.oneOfType([propTypes.string, propTypes.object]),
	/** Guarda o valor do componente */
	value: propTypes.any,
	/** Caso queira usar o title do próprio componente. Esta propriedade é usada para passar a frase do title */
	title: propTypes.string,
	/** Guarda o estilo do componente */
	style: propTypes.object,
	/** Usado para desabilitar o componente */
	disabled: propTypes.bool,
	/** Marca ou desmarca o componente(altera seu valor) */
	checked: propTypes.bool,
	/** Identificador da coluna do componente */
	colId: propTypes.string,
	/** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
	col: propTypes.string,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
	/** Estilo da coluna*/
	colStyle: propTypes.object,
	/** Nome da classe do componente */
	className: propTypes.string,
	/** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
	errors: propTypes.oneOfType([propTypes.string, propTypes.bool]),
	/** Especifica se o componente foi 'tocado'*/
	touched: propTypes.bool,
	/** Diz se o usuário possui permissão de editar*/
	podeEditar: propTypes.bool,
	/** Diz se o usuário possui permissão de excluir*/
	podeInserir: propTypes.bool,
	/** Estado em que o cadastro se encontra*/
	estadoCadastro: propTypes.string,
	/** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
	helpMessage: propTypes.string,
};

export default Checkbox;
