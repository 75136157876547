import { buscarItemLocalStorage, DADOSLOCALSTORAGE, salvarItemLocalStorage } from 'Common';
import { Button, If } from 'components';
import { format, isSameDay } from 'date-fns';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { ModalAniversariantes } from 'views/cadastros/Pessoas/components/ModalAniversariantes';
import { getAniversariantes } from 'views/cadastros/Pessoas/Requests';

function ToastNotificacoes({ isMobile }) {
	const [modalAniversariantesVisible, setModalAniversariantesVisible] = useState(false);

	const toastNotificacoes = useRef(null);

	useEffect(() => {
		verificarNotificacoes();
	}, []);

	async function verificarNotificacoes() {
		const promises = [verificarNotificacaoAniversario()];

		await Promise.all(promises).then((data) => {
			const notificacoes = data.filter((notificacao) => notificacao);

			if (notificacoes?.length > 0) {
				toastNotificacoes.current?.show(notificacoes);
			}
		});
	}

	function verificarNotificacaoAniversario() {
		const ultimaNotificacaoAniversario = buscarItemLocalStorage(DADOSLOCALSTORAGE.ultimaNotificacaoAniversario);
		const hoje = new Date();

		if (!ultimaNotificacaoAniversario) {
			return montarNotificacaoAniversario(hoje);
		} else {
			const dataUltimaNotificacao = new Date(ultimaNotificacaoAniversario);

			if (!isSameDay(hoje, dataUltimaNotificacao)) {
				return montarNotificacaoAniversario(hoje);
			}
		}

		return null;
	}

	async function montarNotificacaoAniversario(data) {
		let notificacaoAniversario;
		await getAniversariantes(format(data, 'yyyy-MM-dd'), format(data, 'yyyy-MM-dd'), (response) => {
			if (response.status === 200 && response.data.length > 0) {
				notificacaoAniversario = {
					severity: 'info',
					summary: <span>&#x1F382;{` Você possui clientes de aniversário hoje! `}&#x1F389;</span>,
					sticky: true,
					acao: () => {
						salvarItemLocalStorage(DADOSLOCALSTORAGE.ultimaNotificacaoAniversario, data);
						setModalAniversariantesVisible(true);
					},
					onClose: () => {
						salvarItemLocalStorage(DADOSLOCALSTORAGE.ultimaNotificacaoAniversario, data);
					},
					content: (props) => (
						<div className="flex flex-column align-items-left" style={{ flex: '1' }}>
							<div className="font-medium text-lg my-2 text-900">{props.message.summary}</div>
							<Button
								style={{ minWidth: '100px', width: 'fit-content' }}
								label="Visualizar"
								severity="success"
								onClick={() => {
									props.message.acao(props.onClose());
								}}
							/>
						</div>
					),
				};
			}
		});
		return notificacaoAniversario;
	}

	return (
		<>
			<Toast
				ref={toastNotificacoes}
				position="top-right"
				onRemove={(props) => props.onClose()}
				style={{ minWidth: 'fit-content' }}
			/>

			<If test={modalAniversariantesVisible}>
				<ModalAniversariantes
					visible={modalAniversariantesVisible}
					onHide={() => setModalAniversariantesVisible(false)}
					isMobile={isMobile}
				/>
			</If>
		</>
	);
}

export { ToastNotificacoes };
