import { combineReducers } from 'redux';

import loadingReducer from '../../components/Loading/Reducer';
import menuLateralreducers from '../../views/PaginaInicial/Reducers';
import reducerEstadoFormulario from '../../components/Route/Reducer';
import reducerPlano from '../../views/configuracao/Planos/Util/reducer/atualizarPlano';
import suporteViaChat from '../../views/configuracao/Planos/Util/reducer/suporteViaChat';
import reducerPlanoViolado from '../../Common/TratamentoDeErro/Reducers/plano_violado_reducer';
import reducerTratamentoErro from '../../Common/TratamentoDeErro/Reducers/tratamento_erro_reducer';
import reducerDispositivo from '../../App/Reducer/dispositivoReducer';
import reducerPossuiInternet from '../../App/Reducer/possuiInternetReducer';
import reducerMenuLateralFilial from '../../App/Reducer/menuLateralFiliaisReducer';

const rootReducer = combineReducers({
	loading: loadingReducer,
	menuLateral: menuLateralreducers,
	estadoFormulario: reducerEstadoFormulario,
	plano: reducerPlano,
	planoViolado: reducerPlanoViolado,
	dispositivo: reducerDispositivo,
	possuiInternet: reducerPossuiInternet,
	possuiSuporteViaChat: suporteViaChat,
	mostrarErroTratado: reducerTratamentoErro,
	atualizacaoFilialMenuLateral: reducerMenuLateralFilial,
});

export default rootReducer;
