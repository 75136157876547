export const tutorialSteps = [
  {
    target: "body",
    content: `Este é o cadastro de tributação municipal, neste módulo serão vinculadas as tributações por município e posteriormente vinculadas ao código de serviço.`,
    disableBeacon: true,
    placement: "center",
  },
  {
    target: ".step-tributacao-municipal-principais",
    content:
      "Aqui você vai preencher o nome, tributação federal e a situação da tributação municipal.",
  },
  {
    target: ".step-tributacao-municipal-tributacoes-button",
    content:
      "Por esse botão pode ser incluídas novas tributações por município.",
  },
];
