import { montarLabelPessoa } from '../../../../../components/select/SingleSelectPessoa/Util';

export function converterDocumentoParaFormulario(values) {
	return {
		id: values.id,
		identificacao: values.identificacao,
		assinaturaTipo: values.assinaturaTipo,
		status: values.status,
		pessoa: converterPessoaParaFormulario(values.pessoa),
		hash256: values.hash256,
		arquivado: values.arquivado,
		expiracaoAssinaturas: values.expiracaoAssinaturas,
		usuario: converterUsuarioParaFormulario(values.responsavel ? values.responsavel.usuario : null),
		setor: converterSetorParaFormulario(values.responsavel ? values.responsavel.setor : null),
		confirmacoes: converterConfirmacoesParaFormulario(values.confirmacoes),
		signatarios: converterSignatariosParaFormulario(values.signatarios),
		arquivo: converterArquivoParaFormulario(values.arquivo),
		observacao: values.observacao,
		tituloEmail: values.tituloEmail ?? '',
		corpoEmail: values.corpoEmail ?? '',
	};
}

export function converterDocumentoParaApi(values) {
	return {
		id: values.id,
		identificacao: values.identificacao,
		pessoa: values.pessoa ? { id: values.pessoa.value } : null,
		arquivado: values.arquivado,
		status: values.status,
		assinaturaTipo: values.assinaturaTipo,
		signatarios: converterSignatariosParaAPI(values.signatarios),
		confirmacoes: converterConfirmacoesParaAPI(values.confirmacoes),
		expiracaoAssinaturas: values.expiracaoAssinaturas,
		responsavel: {
			usuario: { id: values.usuario ? values.usuario.value : null },
			setor: { id: values.setor ? values.setor.value : null },
		},
		observacao: values.observacao,
		notificarAssinatura: false,
		pedidoVendaId: values.pedidoVendaId ?? null,
	};
}

export function converterPedidoParaAssinatura(values) {
	return {
		id: null,
		identificacao: 'Pedido de venda ' + values?.numero,
		pessoa: converterPessoaParaFormulario(values.cliente),
		signatarios: converterClientePedidoParaSignatario(values.cliente),
	};
}

function converterClientePedidoParaSignatario(cliente) {
	return [
		{
			id: null,
			pessoa: converterPessoaParaFormulario(cliente),
			email: converterEmailsClientePedidoParaSignatario(cliente.emails),
			nome: cliente.nome,
			papel: 'PARTE',
			assinatura: null,
			confirmacoes: null,
			telefone: converterTelefonesClientePedidoParaSignatario(cliente.telefones),
		},
	];
}

function converterTelefonesClientePedidoParaSignatario(telefones) {
	if (!!telefones && telefones.length > 0) {
		const telefoneFavorito = telefones.find((telefone) => telefone.favorito);

		return telefoneFavorito ? telefoneFavorito.numero : telefones[0].numero;
	}

	return null;
}

function converterEmailsClientePedidoParaSignatario(emails) {
	if (!!emails && emails.length > 0) {
		const emailFavorito = emails.find((email) => email.favorito);

		return emailFavorito ? emailFavorito.email : emails[0].email;
	}

	return null;
}

export function converterUsuarioParaFormulario(usuario) {
	if (usuario) {
		return {
			label: usuario.nome,
			value: usuario.id,
		};
	}
	return null;
}

export function converterSetorParaFormulario(setor) {
	if (setor) {
		return {
			label: setor.nome,
			value: setor.id,
		};
	}
	return null;
}

function converterPessoaParaFormulario(pessoaApi) {
	if (pessoaApi) {
		return {
			label: montarLabelPessoa(pessoaApi),
			value: pessoaApi.id,
		};
	}
	return null;
}

function converterSignatariosParaFormulario(signatarios) {
	return signatarios.map((signatario) => {
		return {
			id: signatario.id,
			pessoa: signatario.pessoa ? { label: montarLabelPessoa(signatario.pessoa), value: signatario.pessoa.id } : null,
			email: signatario.email,
			nome: signatario.nome,
			papel: signatario.papel,
			assinatura: signatario.assinatura,
			confirmacoes: signatario.confirmacoes,
			telefone: signatario.telefone,
			linkAssinatura: signatario.linkAssinatura,
		};
	});
}

export function converterSignatariosPadraoParaFormulario(signatarios) {
	return signatarios.map((signatario) => {
		return {
			id: null,
			pessoa: signatario.pessoa ? { label: montarLabelPessoa(signatario.pessoa), value: signatario.pessoa.id } : null,
			email: signatario.email,
			nome: signatario.nome,
			papel: signatario.papel,
			assinatura: null,
			confirmacoes: signatario.confirmacoes,
			telefone: signatario.telefone,
			linkAssinatura: null,
		};
	});
}

function converterConfirmacoesParaFormulario(confirmacoes) {
	return confirmacoes.map((confirmacao) => {
		return {
			id: confirmacao.id,
			pergunta: confirmacao.pergunta,
			respostaTipo: confirmacao.respostaTipo,
			respostaEsperada: confirmacao.respostaEsperada,
		};
	});
}

export function converterConfirmacoesPadraoParaFormulario(confirmacoes) {
	return confirmacoes.map((confirmacao) => {
		return {
			id: null,
			pergunta: confirmacao.pergunta,
			respostaTipo: confirmacao.respostaTipo,
			respostaEsperada: confirmacao.respostaEsperada,
		};
	});
}

function converterArquivoParaFormulario(arquivo) {
	if (arquivo) {
		return {
			name: arquivo.nome,
			size: arquivo.tamanho,
			type: arquivo.tipo,
		};
	}
	return null;
}

function converterSignatariosParaAPI(signatarios) {
	return signatarios.map((signatario) => {
		return {
			id: signatario.id,
			pessoa: signatario.pessoa
				? {
						id: signatario.pessoa.value ?? signatario.pessoa.id,
					}
				: null,
			email: signatario.email,
			nome: signatario.pessoa && signatario.pessoa.registro ? signatario.pessoa.registro.nome : signatario.nome,
			papel: signatario.papel,
			confirmacoes: converterConfirmacoesParaAPI(signatario.confirmacoes),
			assinatura: signatario.assinatura,
			telefone: signatario.telefone,
		};
	});
}

function converterConfirmacoesParaAPI(confirmacoes) {
	if (!confirmacoes) {
		return [];
	}
	return confirmacoes.map((confirmacao) => {
		return {
			id: confirmacao.id,
			pergunta: confirmacao.pergunta,
			respostaTipo: confirmacao.respostaTipo,
			respostaEsperada: confirmacao.respostaEsperada,
		};
	});
}
