import { Switch, withRouter } from 'react-router-dom';
import PrivateRoute from 'components/Route';
import Dashboard from '../Dashboard';
import NoMatch from 'views/Util/NoMatch';

export const pathDefaultRoutes = ['/', '*'];

function DefaultRoutes() {
  return (
    <Switch>
      <PrivateRoute exact path="/" render={props => <Dashboard {...props} />} validarPermissoes={false} />
      <PrivateRoute path="*" component={NoMatch} />
    </Switch>
  );
}

export default withRouter(DefaultRoutes);
