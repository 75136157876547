export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é o cadastro de tributação federal, que posteriormente será vinculado à tributação estadual.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-tributacao-federal-principais',
		content: 'Aqui você vai preencher a descrição e a situação da tributação federal.',
	},
	{
		target: '.step-tributacao-federal-ipi',
		content: 'Aqui serão incluídas as informações referente a tributação de IPI.',
	},
	{
		target: '.step-tributacao-federal-pis',
		content: 'Aqui serão incluídas as informações referente a tributação de PIS.',
	},
	{
		target: '.step-tributacao-federal-cofins',
		content: 'Aqui serão incluídas as informações referente a tributação de COFINS.',
	},
];
