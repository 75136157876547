import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	removerElemento,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Badge,
	Button,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Col,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	Tutorial,
	tutorialStepsListagens,
} from 'components';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { atualizarUrl } from 'views/Util';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { asyncDeleteMarca, asyncGetMarcas } from './Requests';
import { Colors, optionsFiltroAvancado, styleBackground, styleButtonOptions } from './Util/constantes';

function Marca(props) {
	const {
		valorPesquisa,
		setValorPesquisa,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();

	const [menu, setMenu] = useState(null);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS));

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_MARCAS, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.CADASTROS_MARCAS, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_MARCAS, permissoes.EXCLUIR));

	const itensMenu = montarItensMenu();

	const pesquisarCallback = useCallback(() => {
		pesquisar();
	});

	useEffect(() => {
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			if (document.getElementById('InputSearchMarca')) {
				document.getElementById('InputSearchMarca').focus();
			}
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [exibirBloqueadas, page, rows, sortField, filtroAvancado]);

	async function pesquisar() {
		let filtro = buscarFiltro();
		filtro += `${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`;
		const url = construirUrl(
			`${services.GESTOR}/v1/marca/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		await asyncGetMarcas(url, ({ data: marcas }) => {
			setRegistros(marcas.content);
			setTotalElements(marcas.totalElements);

			if (!exibirBloqueadas && !!filtroAvancado && filtroAvancado.includes('situacao=="INATIVO"')) {
				setExibirBloqueadas(true);
			}
		});
	}

	function buscarFiltro() {
		let result = `?query=(nome=contains="*${valorPesquisa}*")`;

		if (filtroAvancado) {
			result += `;${filtroAvancado}`;
		}

		return result;
	}

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro, pesquisarCallback());
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function onChangeCheckboxExibirInativas(element) {
		setExibirBloqueadas(element.checked);
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return (
				<span
					style={{
						fontStyle: 'italic',
						opacity: '0.7',
						wordBreak: 'break-word',
						maxWidth: props.isMobile ? '70%' : '100%',
						textAlign: props.isMobile ? 'end' : 'start',
					}}
				>
					{field}
				</span>
			);
		}
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{field}
			</span>
		);
	}

	function renderSituacao(row) {
		const styleDescription = {
			fontStyle: row.situacao === 'INATIVO' ? 'italic' : '',
			opacity: row.situacao === 'INATIVO' ? '0.7' : '',
			margin: row.situacao === 'ATIVO' ? '3px 16px' : '3px 10px',
		};

		switch (row.situacao) {
			case 'ATIVO': {
				return Badge(Colors.textAtivo, Colors.bgAtivo, 'Ativo', styleBackground, styleDescription);
			}
			case 'INATIVO': {
				return Badge(Colors.textInativo, Colors.bgInativo, 'Inativo', styleBackground, styleDescription);
			}
			default:
				return row.situacao;
		}
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEditar(row)} disabled={!podeEditar} />
				<ButtonExcluirTable onClick={() => onExcluir(row)} podeExcluir={podeExcluir} />
				<Button
					className="p-button-secondary"
					icon="fa fa-ellipsis-v"
					style={styleButtonOptions}
					title={'Opções'}
					disabled={row.situacao === 'INATIVO' || !podeInserir}
					aria-controls="popup_menu"
					aria-haspopup={true}
					onClick={(event) => {
						setRegistroSelecionado(row);
						menu.toggle(event);
					}}
				/>
			</div>
		);
	}

	function montarItensMenu() {
		let itens = [];
		itens.push({
			label: 'Duplicar',
			icon: 'fa fa-copy',
			command: () => duplicar(registroSelecionado?.id),
		});

		return itens;
	}

	function duplicar(marcaId) {
		props.history.push({
			pathname: `/marca/cadastro/${marcaId}`,
			state: { marcaId },
		});
	}

	function onEditar(row) {
		atualizarUrl(props.history, '/marca/cadastro', row.id);
	}

	function onExcluir(row) {
		confirmarExclusao(() => asyncExcluirRegistro(row));
	}

	async function asyncExcluirRegistro(registro) {
		await asyncDeleteMarca(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Marca">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Nova marca"
						onClick={() => {
							props.history.push('/marca/cadastro');
						}}
						podeInserir={podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="InputSearchMarca"
							className="step-listagem-input-search"
							onPesquisar={() => pesquisar(0)}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql, () => {})}
								onChangeDescricaoFiltro={(descricao) => setDescricaoFiltroAvancado(descricao)}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<Menu model={itensMenu} popup={true} style={{ width: '200px' }} ref={(elemento) => setMenu(elemento)} />
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortField}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Checkbox
									name="exibirBloqueadas"
									inputId="exibirBloqueadas"
									checked={exibirBloqueadas}
									onChange={onChangeCheckboxExibirInativas}
								/>
								<label htmlFor="exibirBloqueadas" className="p-checkbox-label">
									Exibir marcas inativas
								</label>
							</span>
						}
					>
						<Column field="nome" header="Nome" body={(row) => aplicarEstiloInativa(row, row.nome)} sortable />
						<Column field="situacao" header="Situação" body={renderSituacao} sortable style={{ width: '15rem' }} />
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
				</FormContent>
			</Form>
		</>
	);
}

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export default connect(mapStateToProps)(Marca);
