import { buscarDadosLoginLocalStorage } from '../Autenticacao';
import { removeNullObjeto } from '../Array/';

export default function validaFieldsPorModulo(fields) {
	let fieldsValidar = {};
	const dadosLogin = buscarDadosLoginLocalStorage();

	fieldsValidar = fields.DEFAULT;
	dadosLogin.organizacao?.modulos.forEach(modulo => {
		if (fields.hasOwnProperty(modulo)) {
			fieldsValidar = Object.assign({ ...fieldsValidar }, removeNullObjeto(fields[modulo]));
		}
	});
	return removeNullObjeto(fieldsValidar);
}
