import { FiDownload, FiSend } from 'react-icons/fi';
import { Button, ButtonCancelar, estadosBotaoCancelar } from '../../../../../../components';
import { converterSpedParaApi } from '../../../Util/spedConverter';
import { validarFormulario } from '../../../../../Util';
import { asyncPostGerarSped } from '../../../Requests';
import { baixarArquivo } from '../../../../../../Common';
import { format } from 'date-fns';

function ActionButtons(props) {
	const {
		values,
		podeGerar,
		setExibirLoadingGerarSped,
		setExibirModalEnviarPorEmail,
		dirty,
		isMobile,
		handleReset,
		history,
		consultaUltimoSped,
	} = props;

	function onClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			history.goBack();
		}
	}

	async function onClickGerar() {
		props.handleSubmit();

		if (await validarFormulario(props)) {
			setExibirLoadingGerarSped(true);

			await asyncPostGerarSped(
				converterSpedParaApi(values),
				({ data: arquivo }) => {
					baixarArquivo(
						new Blob([arquivo]),
						`SPED_${format(new Date(values.dataInicial), 'dd-MM-yyyy')}_${format(
							new Date(values.dataFinal),
							'dd-MM-yyyy'
						)}`,
						'text/plain;charset=ISO-8859-1'
					);

					setExibirLoadingGerarSped(false);
					consultaUltimoSped();
				},
				() => setExibirLoadingGerarSped(false)
			);
		}
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={() => onClickCancelar()}
			/>
			<Button
				label={isMobile ? 'Gerar' : 'Gerar arquivo'}
				icon={<FiDownload size={20} style={{ marginRight: '6px' }} />}
				onClick={() => onClickGerar()}
				disabled={!podeGerar}
			/>
			<Button
				label={isMobile ? 'Enviar' : 'Enviar por e-mail'}
				icon={<FiSend size={18} style={{ marginRight: '7px' }} />}
				onClick={() => setExibirModalEnviarPorEmail(true)}
				disabled={!podeGerar}
			/>
		</>
	);
}
export default ActionButtons;
