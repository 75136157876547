import { Switch, withRouter } from 'react-router-dom';
import { permissoes, recursos } from 'Common';
import PrivateRoute from 'components/Route';

import MovimentacoesEstoque from 'views/estoque/Movimentacoes';
import EstoqueRelatorios from 'views/estoque/Relatorios';

export const pathEstoqueRoutes = ['/movimentacoes_estoque', '/relatorios_estoque'];

function EstoqueRoutes() {
	return (
		<Switch>
			<PrivateRoute
				exact
				path={['/movimentacoes_estoque']}
				render={props => <MovimentacoesEstoque {...props} />}
				recurso={recursos.ESTOQUE_MOVIMENTACOES}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/relatorios_estoque']}
				render={props => <EstoqueRelatorios {...props} />}
				recurso={recursos.ESTOQUE_RELATORIOS}
				permissao={permissoes.VISUALIZAR}
			/>
		</Switch>
	);
}

export default withRouter(EstoqueRoutes);
