import { Checkbox, SingleSelectConta, SingleSelectFormaPagamento } from 'components';
import { Field, useFormikContext } from 'formik';
import { OverlayPanel } from 'primereact/overlaypanel';

function InformacoesExtrasPanel({
	panelDetalhesTitulo,
	currentTituloIndex,
	setCurrentTituloIndex,
	isMobile,
	url,
	informacoesPermissoes,
}) {
	const { values, setFieldValue } = useFormikContext();

	return (
		<OverlayPanel
			ref={panelDetalhesTitulo}
			id="overlayPanel-detalhes"
			style={{
				width: isMobile ? '90%' : '100%',
				maxWidth: '300px',
				marginLeft: isMobile ? '1rem' : null,
				marginTop: '0px',
			}}
			onHide={() => setCurrentTituloIndex(null)}
			showCloseIcon
		>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={SingleSelectConta}
				url={`${url}/relacoes/contas`}
				label="Conta"
				name="quitacao.conta"
				onChange={(e) => setFieldValue(`titulos[${currentTituloIndex}].quitacao.conta`, e)}
				value={values.titulos[currentTituloIndex]?.quitacao?.conta}
				esconderBotao
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={SingleSelectFormaPagamento}
				url={`${url}/relacoes/formas_pagamento`}
				label="Forma de pagamento"
				name="quitacao.formaPagamento"
				onChange={(e) => setFieldValue(`titulos[${currentTituloIndex}].quitacao.formaPagamento`, e)}
				value={values.titulos[currentTituloIndex]?.quitacao?.formaPagamento}
				esconderBotao
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				obrigatorio
				label="Calcular taxa aplicada ao título"
				name="quitacao.aplicarTaxa"
				component={Checkbox}
				value={values.titulos[currentTituloIndex]?.quitacao?.aplicarTaxa}
				onChange={() =>
					setFieldValue(
						`titulos[${currentTituloIndex}].quitacao.aplicarTaxa`,
						!values.titulos[currentTituloIndex]?.quitacao?.aplicarTaxa
					)
				}
				checked={values.titulos[currentTituloIndex]?.quitacao?.aplicarTaxa}
				{...informacoesPermissoes}
			/>
		</OverlayPanel>
	);
}

export { InformacoesExtrasPanel };
