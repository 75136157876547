import { If } from 'components';
import { ModalRecebimentoPix } from 'components/ModalRecebimentoPix';
import { useEffect, useState } from 'react';
import { useContextNFCe } from '../../../Context';

function ModalRecebimentoPorPix(props) {
	const { onHide, isFullScreen, imprimirNFCe } = props;

	const [listaRecebimentos, setListaRecebimentos] = useState([]);
	const [modalPixVisible, setModalPixVisible] = useState(false);
	const [indexRecebimento, setIndexRecebimento] = useState(0);

	const { dadosNFCe } = useContextNFCe();

	useEffect(() => {
		const recebimentos = [];
		dadosNFCe.pagamentos.forEach((pagamento) => {
			if (
				pagamento.condicaoPagamento.tipo === 'A_VISTA' &&
				pagamento.conta.recebePix &&
				pagamento.formaPagamento.descricao.includes('Pix')
			) {
				recebimentos.push(pagamento);
			}
		});
		setListaRecebimentos(recebimentos);
		onShowModalPix();
	}, []);

	function onShowModalPix() {
		setTimeout(() => {
			setModalPixVisible(true);
		}, 500);
	}

	function onHideModalPix() {
		setModalPixVisible(false);
		if (indexRecebimento + 1 < listaRecebimentos.length) {
			setIndexRecebimento(indexRecebimento + 1);
			onShowModalPix();
		} else {
			onHide();
			imprimirNFCe(dadosNFCe.id);
		}
	}

	return (
		<If test={modalPixVisible}>
			<ModalRecebimentoPix
				titulo={listaRecebimentos.length > 1 ? `Cobrança ${indexRecebimento + 1} de ${listaRecebimentos.length}.` : ``}
				valor={listaRecebimentos[indexRecebimento]?.lancamento.valor}
				visible={modalPixVisible}
				lancamentoId={listaRecebimentos[indexRecebimento]?.lancamento.id}
				onHide={onHideModalPix}
				container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
			/>
		</If>
	);
}

export default ModalRecebimentoPorPix;
