function converterOperacaoParaFormulario(operacaoFiscais) {
	return {
		id: operacaoFiscais.id,
		codigo: operacaoFiscais.codigo,
		descricao: operacaoFiscais.descricao,
		situacao: operacaoFiscais.situacao,
		indicacaoMovimento: operacaoFiscais.indicacaoMovimento,
		favoritoSaida: operacaoFiscais.favoritoSaida,
		favoritoEntrada: operacaoFiscais.favoritoEntrada,
		geraFinanceiro: operacaoFiscais.geraFinanceiro,
		geraEstoque: operacaoFiscais.geraEstoque,
		atualizaCustoProduto: operacaoFiscais.atualizaCustoProduto,
		vendaConsumidor: operacaoFiscais.vendaConsumidor,
	};
}

function converterOperacaoParaApi(operacaoFiscais) {
	return {
		id: operacaoFiscais.id,
		codigo: operacaoFiscais.codigo,
		descricao: operacaoFiscais.descricao,
		situacao: operacaoFiscais.situacao,
		indicacaoMovimento: operacaoFiscais.indicacaoMovimento,
		favoritoSaida: operacaoFiscais.favoritoSaida,
		favoritoEntrada: operacaoFiscais.favoritoEntrada,
		geraFinanceiro: operacaoFiscais.geraFinanceiro,
		geraEstoque: operacaoFiscais.geraEstoque,
		atualizaCustoProduto: operacaoFiscais.atualizaCustoProduto,
		vendaConsumidor: operacaoFiscais.vendaConsumidor ?? false,
	};
}

export { converterOperacaoParaFormulario, converterOperacaoParaApi };
