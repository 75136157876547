import { Icon } from '@iconify/react';
import { keyFilterConsultaRsql } from 'Common';
import { Button, InputMask } from 'components';
import { Field } from 'formik';
import { useContextMDFe } from 'views/transporte/MDFe/Context';

const styleButtonAdicionarChaveAcesso = {
	position: 'absolute',
	right: '8px',
	margin: '4px 0 0 0',
	padding: '0px 4px',
	borderRadius: '0 3px 3px 0',
	height: '32px',
};

function InputChaveDeAcesso({
	chaveDeAcesso,
	setChaveDeAcesso,
	handleAdicionarChaveAcesso,
	isEmpty,
	errors,
	disabled,
}) {
	const { informacoesPermissoes } = useContextMDFe();

	function onPressKeyDown(e) {
		if (e.key === 'Enter') {
			handleAdicionarChaveAcesso();
		}
	}

	return (
		<>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={InputMask}
				mask="0000 0000 0000 0000 0000 0000 0000 0000 0000 0000 0000"
				label="Chave de acesso"
				name="chaveDeAcesso"
				id="chaveDeAcesso"
				size={44}
				value={chaveDeAcesso}
				onChange={(event) => setChaveDeAcesso(event.target.value)}
				keyfilter={keyFilterConsultaRsql}
				onKeyUp={onPressKeyDown}
				disabled={isEmpty || disabled}
				errors={errors}
				touched
				useFormTouched={false}
				{...informacoesPermissoes}
			/>
			<Button style={styleButtonAdicionarChaveAcesso} onClick={handleAdicionarChaveAcesso} disabled={isEmpty}>
				<Icon icon="ic:baseline-plus" fontSize={25} />
			</Button>
		</>
	);
}

export default InputChaveDeAcesso;
