import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../../../Common/Mascara';
import { FaBalanceScale } from 'react-icons/fa';
import FalhaAoCarregarAsInformacoes from '../../../../../FalhaAoCarregarAsInformacoes';
import '../Styles/index.css';
import { Financas } from '../../../../../../../../Util/constantes';

const messages = {
	helpMessage: 'Receitas no período - Despesas no período',
};

export default function CardBalanco(props) {
	const { loading, error, saldoTotal } = props;

	function montarTituloCard() {
		return (
			<div className="card-resultado-financeiro-dashboard-titulo">
				<div className="card-resultado-financeiro-dashboard-titulo-descricao" title={messages.helpMessage}>
					Balanço (Receitas - Despesas)
				</div>
			</div>
		);
	}

	if (loading) {
		return (
			<div className="card-resultado-financeiro-dashboard">
				{montarTituloCard()}
				<div className="card-resultado-financeiro-dashboard-content">
					<ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />
				</div>
			</div>
		);
	} else if (error) {
		return (
			<div className="card-resultado-financeiro-dashboard">
				{montarTituloCard()}
				<div className="card-resultado-financeiro-dashboard-content">
					<FalhaAoCarregarAsInformacoes />
				</div>
			</div>
		);
	} else {
		const colorMoney = saldoTotal < 0 ? Financas.cores.vermelho : Financas.cores.verde;

		return (
			<div className="card-resultado-financeiro-dashboard" style={{ height: '100%' }}>
				{montarTituloCard()}
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						height: '100%',
						paddingBottom: '1rem',
					}}
				>
					<div style={{ padding: '5px' }}>
						<FaBalanceScale size={40} color={colorMoney} />
					</div>
					<div
						title="Valor total a receber - Valor total a pagar"
						style={{ color: colorMoney, padding: '5px', fontSize: '25px', fontWeight: 500 }}
					>
						{formatarMonetario(saldoTotal)}
					</div>
				</div>
			</div>
		);
	}
}
