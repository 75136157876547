export function converterRegistrosParaOptions(registros, montarLabel) {
	return registros.map(registro => converterRegistroParaOption(registro, montarLabel));
}

export function converterRegistroParaOption(registro, montarLabel) {
	return {
		value: registro.id,
		label: montarLabel(registro),
		codigoBarras: registro.codigoBarras,
		registro: registro,
	};
}

export function buscarOption(options, option) {
	for (const opcaoExistente of options) {
		if (opcaoExistente.value === option.value) {
			return opcaoExistente;
		}
	}
	return null;
}

export function filtrarOpcoesRepetidas(opcoes, novasOpcoes) {
	let result = [...opcoes];
	novasOpcoes.forEach(novaOpcao => {
		if (!result.some(item => item.value === novaOpcao.value)) {
			result.push(novaOpcao);
		}
	});
	return result;
}
