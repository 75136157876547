import InternalDropdownMultiSelect from './components/InternalDropdownMultiSelect';

export default function DropdownMultiSelect({
	field,
	form,
	value,
	useFormErrors = true,
	useFormTouched = true,
	...props
}) {
	const useForm = useFormErrors && useFormTouched;

	return (
		<>
			<InternalDropdownMultiSelect
				{...field}
				{...props}
				dirty={form && form.dirty}
				errors={form && useForm ? form?.errors[field.name] : props.errors ?? form?.errors[field.name]}
				touched={
					form && useForm ? Boolean(form?.touched[field.name]) : props.touched ?? Boolean(form?.touched[field.name])
				}
				value={value}
			/>
		</>
	);
}
