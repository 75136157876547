import { exibirToast, get, post, del, put, services } from 'Common';

export async function asyncGetMarcas(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetMarca(idMarca, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/marca/${idMarca}`, null, onSuccess, onError);
}

export async function asyncCreateMarca(marca, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/marca`, marca, null, exibirToast(onSuccess, 'Marca criada com sucesso'), onError);
}

export async function asyncUpdateMarca(marca, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/marca/${marca.id}`,
		marca,
		null,
		exibirToast(onSuccess, 'Marca atualizada com sucesso'),
		onError
	);
}

export async function asyncDeleteMarca(idMarca, onSuccess, onError) {
	return await del(`${services.GESTOR}/v1/marca/${idMarca}`, null, exibirToast(onSuccess, 'Marca excluída'), onError);
}
