import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { ButtonEditarTable, ButtonExcluirTable, NenhumRegistroEncontrado } from '../../../../../../../../../components';
import { confirmarExclusao } from '../../../../../../../../Util/ExclusaoDeRegistros';

const styleColumn = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

function TableConvenios(props) {
	const {
		values,
		setRegistroSelecionado,
		setExibirModalNovoConvenio,
		setIndexConvenio,
		excluirConvenio,
		informacoesPermissoes,
	} = props;
	const [sortField, setSortField] = useState('numero');
	const [sortOrder, setSortOrder] = useState(1);

	function renderOpcoes(row, index) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable
					onClick={() => {
						setRegistroSelecionado(row);
						setIndexConvenio(index);
						setExibirModalNovoConvenio(true);
					}}
					disabled={!informacoesPermissoes.podeEditar}
				/>
				<ButtonExcluirTable
					onClick={() => {
						onDelete(row);
					}}
					podeExcluir={informacoesPermissoes.podeExcluir}
				/>
			</div>
		);
	}

	function onDelete(row) {
		confirmarExclusao(() => excluirConvenio(row));
	}

	function onSort(element) {
		setSortField(element.sortField);
		setSortOrder(element.sortOrder);
	}
	return (
		<DataTable
			className="table"
			rowClassName="table-row"
			cellClassName="table-row-cell"
			responsive
			sortField={sortField}
			sortOrder={sortOrder}
			onSort={onSort}
			value={values?.convenios}
			style={{ width: '100%' }}
			emptyMessage={<NenhumRegistroEncontrado />}
		>
			<Column
				header="Número"
				field="numero"
				body={row => row.numero}
				sortable
				style={{
					...styleColumn,
					width: '15%',
				}}
			/>
			<Column
				header="Descrição"
				field="descricao"
				body={row => row.descricao}
				sortable
				style={{
					...styleColumn,
					width: '60%',
				}}
			/>
			<Column
				header="CNAB"
				field="cnab"
				body={row => row.cnab?.label}
				sortable
				style={{
					...styleColumn,
					width: '15%',
				}}
			/>
			<Column
				header="Ações"
				body={(rowData, index) => renderOpcoes(rowData, index?.rowIndex)}
				style={{
					...styleColumn,
					width: 'auto',
				}}
			/>
		</DataTable>
	);
}

export default TableConvenios;
