import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';

export const situacaoOptions = [
  { label: 'Ativo', value: 'ATIVO' },
  { label: 'Inativo', value: 'INATIVO' },
];

export const tipoOptions = [
  { label: 'Veículo', value: 'VEICULO' },
  { label: 'Eletrônico', value: 'ELETRONICO' },
  { label: 'Outros', value: 'OUTRO' },
];

export const optionsFiltroAvancadoObjetoDeServico = [
  { label: 'Nome', name: 'nome', type: tipoCampos.STRING },
  {
    label: 'Situação',
    name: 'situacao',
    type: tipoCampos.SELECT,
    optionSelect: situacaoOptions,
  },
  {
    label: 'Tipo',
    name: 'tipo',
    type: tipoCampos.SELECT,
    optionSelect: tipoOptions,
  },
  { label: 'Placa', name: 'veiculo.veiculoPlaca', type: tipoCampos.STRING },
  { label: 'Serie', name: 'eletronico.eletronicoSerie', type: tipoCampos.STRING },
];

export const Colors = {
  bgAtivo: '#DCEDC8',
  textAtivo: '#1B5E20',
  bgInativo: '#FFCDD2',
  textInativo: '#B71C1C',
};

export const styleBackground = {
  borderRadius: '20px',
  padding: '0.2rem 1.1rem',
};

export const styleButtonOptions = {
  borderRadius: '50%',
  padding: '5px',
  width: '30px',
  height: '30px',
  margin: '2px',
};
