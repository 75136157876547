import propTypes from 'prop-types';
import MultipleSelectProdutos from '../../../../../components/select/MultipleSelectProdutos';
import { services } from '../../../../../Common/Constantes/api';
import Button from '../../../../../components/Button';
import Col from '../../../../../components/Col';

export default function InputSearchProdutos(props) {
	function onKeyDown(e) {
		if (props.onKeyDown) {
			props.onKeyDown(e);
		} else if (e.key === 'Enter') {
			props.onPesquisar();
		}
	}

	function handleChangePesquisa(e) {
		props.onChange(e);
	}

	const { placeholder, name, className, value, disabled, sm, md, lg, xl, onPesquisar } = props;
	return (
		<Col style={{ display: 'flex', alignItens: 'center' }} sm={sm} md={md} lg={lg} xl={xl}>
			<MultipleSelectProdutos
				url={`${services.GESTOR}/v1/estoque/movimentacoes/relacoes/produtos`}
				filtroAdicionalUrl=';tipo!="SERVICO";controlarEstoque==true'
				className={className}
				placeholder={placeholder}
				name={name}
				disabled={disabled}
				onKeyDown={onKeyDown}
				onChange={handleChangePesquisa}
				value={value}
				colStyle={{ width: 'calc(100% - 33px)', padding: '0px' }}
				id={props.id}
			/>
			<Button
				type="button"
				icon="fa fa-search"
				style={{
					height: '100%',
					borderBottomLeftRadius: '0px',
					borderTopLeftRadius: '0px',
				}}
				onClick={() => onPesquisar()}
			/>
		</Col>
	);
}

InputSearchProdutos.defaultProps = {
	placeholder: 'Filtre por produto...',
	sm: '12',
	md: '7',
	lg: '6',
	xl: '5',
};

InputSearchProdutos.propTypes = {
	/** Placeholder da pesquisa */
	placeholder: propTypes.string,
	/** Nome do campo de pesquisa */
	name: propTypes.string,
	/** Classificacao da pesquisa */
	className: propTypes.string,
	/** Evento disparado ao teclar */
	onKeyDown: propTypes.func,
	/** Valor do componente */
	value: propTypes.any,
	/** Evento onChange*/
	onChange: propTypes.func,
	/** Evento clicar no pesquisa */
	onPesquisar: propTypes.func,
	/** Componente desabilitado */
	disabled: propTypes.bool,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
	/**Define se o componente está carregando */
	loading: propTypes.bool,
};
