import { useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	mensagensDeValidacao,
	salvarConfiguracaoUsuario,
	validarUUID,
} from 'Common';
import { Form, FormActions, FormContent, Prompt, Tutorial, tutorialStepsTabelaPreco } from 'components';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';

import { useContextTabelaPreco } from '../Context';
import { readTabelaPreco } from '../Requests';
import { converterTabelaPrecoParaForm } from '../Util/TabelaPrecoConverter';
import { CADASTRO_URL, INITIAL_VALUES } from '../Util/constantes';

import { ActionButtons } from './components/ActionButtons';
import { HeaderForm } from './components/HeaderForm';
import { HeaderTabelaPreco } from './components/HeaderTabelaPreco';
import { TabsTabelaPreco } from './components/TabsTabelaPreco';

function TabelaPrecoFormView() {
	const { dirty, resetForm, submitCount, errors } = useFormikContext();
	const { match, history, isMobile, setActiveTabIndex, setForceClearFilter } = useContextTabelaPreco();

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_TABELA_PRECO));

	const [visibleModalReplicarProdutosOutrasTabelas, setVisibleModalReplicarProdutosOutrasTabelas] = useState(false);

	useEffect(async () => {
		const { id } = match.params;

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_TABELA_PRECO, false, null, false);
		}

		setForceClearFilter(true);

		if (validarUUID(id)) {
			fetchRegistro(id);
		} else {
			await newRegistro();
		}

		setTimeout(() => {
			document.getElementById('input-tabela_preco-nome')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		if (errors && Object.keys(errors)?.length > 0) {
			const hasErrorInTabDescontoPorQuantidade = errors.descontosPorQuantidade?.length > 0;

			if (hasErrorInTabDescontoPorQuantidade) {
				setActiveTabIndex(2);
			}
		}
	}, [errors, submitCount]);

	async function fetchRegistro(id) {
		await readTabelaPreco(id, ({ data: tabelaprecoApi }) => {
			resetForm({ values: converterTabelaPrecoParaForm(tabelaprecoApi) });

			atualizarUrl(history, CADASTRO_URL, tabelaprecoApi.id, metodosAtualizarUrl.POP);
		});
	}

	async function newRegistro() {
		atualizarUrl(history, CADASTRO_URL, null, metodosAtualizarUrl.POP);
		setActiveTabIndex(0);

		resetForm();
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Tutorial
				steps={tutorialStepsTabelaPreco}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form
				header={<HeaderForm title={isMobile ? 'Cadastro tabela preço' : 'Cadastro de tabela de preço'} />}
				className="card-default screen-max-width"
			>
				<FormActions className="screen-max-width">
					<ActionButtons
						fetchRegistro={fetchRegistro}
						setVisibleModalReplicarProdutosOutrasTabelas={setVisibleModalReplicarProdutosOutrasTabelas}
					/>
				</FormActions>
				<FormContent>
					<HeaderTabelaPreco />
					<TabsTabelaPreco
						visibleModalReplicarProdutosOutrasTabelas={visibleModalReplicarProdutosOutrasTabelas}
						setVisibleModalReplicarProdutosOutrasTabelas={setVisibleModalReplicarProdutosOutrasTabelas}
					/>
				</FormContent>
			</Form>
		</>
	);
}

const TabelaPrecoForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};
		const errorsDescontosPorQuantidade = [];

		values.descontosPorQuantidade?.length > 0 &&
			values.descontosPorQuantidade?.forEach((item, index) => {
				if (item.valorInicial === null || item.valorInicial === undefined) {
					errorsDescontosPorQuantidade[index] = {
						...errorsDescontosPorQuantidade[index],
						valorInicial: mensagensDeValidacao.OBRIGATORIO,
					};
				}

				if (item.valorFinal === null || item.valorFinal === undefined) {
					errorsDescontosPorQuantidade[index] = {
						...errorsDescontosPorQuantidade[index],
						valorFinal: mensagensDeValidacao.OBRIGATORIO,
					};
				}

				if (item.percentualAplicado === null || item.percentualAplicado === undefined) {
					errorsDescontosPorQuantidade[index] = {
						...errorsDescontosPorQuantidade[index],
						percentualAplicado: mensagensDeValidacao.OBRIGATORIO,
					};
				}

				if (item.valorFinal < item.valorInicial) {
					errorsDescontosPorQuantidade[index] = {
						...errorsDescontosPorQuantidade[index],
						valorFinal: 'Valor Final menor que inicial',
					};
				}

				if (index > 0) {
					const valorAnterior = values.descontosPorQuantidade[index - 1];

					if (item.valorInicial <= valorAnterior.valorFinal) {
						errorsDescontosPorQuantidade[index] = {
							...errorsDescontosPorQuantidade[index],
							valorInicial: 'Valor menor que valor final anterior',
						};
					}
				}
			});

		if (errorsDescontosPorQuantidade?.length > 0) {
			errors.descontosPorQuantidade = errorsDescontosPorQuantidade;
		}

		return errors;
	},

	handleSubmit: () => {},
})(TabelaPrecoFormView);

export { TabelaPrecoForm };
