import { actionTypes } from '../../../../../Common/Constantes/reduxTypes';

export default (state = {}, action) => {
	switch (action.type) {
		case actionTypes.POSSUI_SUPORTE_VIA_CHAT: {
			return action.payload;
		}
		default: {
			return state;
		}
	}
};
