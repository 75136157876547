export function converterReboqueParaModal(values) {
	return {
		...values,
		estado: values.estado
			? {
					label: values.estado?.nome + ' - ' + values.estado?.sigla,
					value: values.estado?.id,
					registro: values.estado,
				}
			: null,
	};
}

export function converterReboqueModalParaForm(values) {
	return {
		...values,
		estado: values.estado?.registro,
	};
}
