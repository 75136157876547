import { Tree as InternalTree } from 'primereact/tree';
import propTypes from 'prop-types';
import { buscarDisabledDeAcordoComAsPermissoes } from '../../Common/Autorizacao/ManipulacaoDeComponentes';
import { renderizarValidacao } from '../../Common/TratamentoDeErro/validacoesDeCampos';

export default function Tree(props) {
	const { podeInserir, podeEditar, estadoCadastro, errors, className } = props;
	const disabled = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, props.disabled);

	function getOnSelectionChange(disabled) {
		if (disabled) return (event) => {};
		return props.onSelectionChange;
	}

	return (
		<>
			<InternalTree
				className={`${disabled ? 'p-disabled' : undefined} ${className}`}
				{...props}
				onSelectionChange={getOnSelectionChange(disabled)}
			/>
			{renderizarValidacao(errors, true)}
		</>
	);
}

Tree.propTypes = {
	/** Identificador do componente */
	id: propTypes.string,
	/** Estado em que o cadastro se encontra*/
	estadoCadastro: propTypes.string,
	/** Diz se o usuário possui permissão de inserir */
	podeVisualizar: propTypes.bool,
	/** Diz se o usuário possui permissão de editar */
	podeEditar: propTypes.bool,
	/** Diz se o usuário possui permissão de excluir */
	podeInserir: propTypes.bool,
	/** Esconde o componente */
	hidden: propTypes.bool,
	/** Estilo do componente */
	style: propTypes.object,
};
