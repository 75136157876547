import { localServices } from '../../../../../../../../Common/Constantes/api';
import { get, post } from '../../../../../../../../Common/Requisicoes';

export async function asyncGetImpressorasServicoLocal(onSuccess, onError) {
	await get(
		`${localServices.MSYS_GESTOR_SERVICE}/msysgestor/servico/impressoras`,
		null,
		onSuccess,
		onError,
		true,
		false
	);
}

export async function asyncGetPortasCOMServicoLocal(onSuccess, onError) {
	await get(
		`${localServices.MSYS_GESTOR_SERVICE}/msysgestor/servico/portas`,
		null,
		onSuccess,
		onError,
		true,
		false
	);
}

export async function asyncPostConfiguracoesServicoLocal(configuracoes, onSuccess, onError) {
	await post(
		`${localServices.MSYS_GESTOR_SERVICE}/msysgestor/servico/configuracoes`,
		JSON.stringify(configuracoes),
		null,
		onSuccess,
		onError,
		true,
		false
	);
}

export async function asyncGetConfiguracoesServicoLocal(onSuccess, onError) {
	await get(
		`${localServices.MSYS_GESTOR_SERVICE}/msysgestor/servico/configuracoes`,
		null,
		onSuccess,
		onError,
		true,
		false
	);
}
