import { buscarDadosLoginLocalStorage, fazerLogin, fazerLogoutLocalStorange, mensagensDeValidacao } from 'Common';
import { AutoProgressBar, Button, Col, FormGroup, Grid, InputPassword, notify } from 'components';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { asyncAtualizarSenha } from './Requests';
import { INITIAL_VALUES } from './Util/constantes';

function AlterarSenhaView({ handleSubmit, validateForm, values, handleReset, onHide, isValid }) {
	async function salvar() {
		await validateForm();
		await handleSubmit();
		if (isValid) {
			await asyncAtualizarSenha(values, () => {
				const dadosLogin = buscarDadosLoginLocalStorage();
				fazerLogin(
					dadosLogin.email,
					values.senhaNova,
					dadosLogin.manterConectado,
					() => {
						onHide();
					},
					() => {
						notify('Não foi possível efetuar login com a nova senha!');
						fazerLogoutLocalStorange();
					}
				);
				handleReset();
			});
		}
	}

	return (
		<FormGroup>
			<AutoProgressBar />
			<Grid>
				<Col>
					<Field
						lg="12"
						xl="12"
						colStyle={{ padding: 0 }}
						component={InputPassword}
						label="Senha atual"
						feedback={false}
						name="senhaAtual"
					/>
				</Col>
				<Col>
					<Field
						lg="12"
						xl="12"
						colStyle={{ padding: 0 }}
						component={InputPassword}
						label="Nova senha"
						feedback={false}
						name="senhaNova"
					/>
				</Col>
				<Col>
					<Field
						lg="12"
						xl="12"
						colStyle={{ padding: 0 }}
						component={InputPassword}
						label="Confirmação da nova senha"
						name="confirmacaoSenha"
						feedback={false}
					/>
				</Col>
				<Col>
					<Button type="button" label="Confirmar" icon="fa fa-key" width="140px" onClick={salvar} />
				</Col>
			</Grid>
		</FormGroup>
	);
}

const AlterarSenha = withFormik({
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate: (e) => {
		const errors = {};
		if (e.senhaNova !== e.confirmacaoSenha) {
			errors.confirmacaoSenha = 'As senhas não coincidem!';
		}
		return errors;
	},

	validationSchema: Yup.object().shape({
		senhaAtual: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.test('len', 'A senha deve ter no mínimo 8 caracteres', (val) => val && val.length >= 8),
		senhaNova: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.test('len', 'A senha deve ter no mínimo 8 caracteres', (val) => val && val.length >= 8)
			.test('mustHaveNumber', 'A senha deve ter pelo menos um caracter numérico', (val) => val && val.match(/\d+/g)),
		confirmacaoSenha: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.test('len', 'A senha deve ter no mínimo 8 caracteres', (val) => val && val.length >= 8)
			.test('mustHaveNumber', 'A senha deve ter pelo menos um caracter numérico', (val) => val && val.match(/\d+/g)),
	}),

	handleSubmit: () => {},
})(AlterarSenhaView);

export default AlterarSenha;
