import { del, get, post, put, exibirToast } from '../../../../Common/Requisicoes';
import { formatISO } from 'date-fns';
import { services } from '../../../../Common/Constantes/api';

export const TipoPessoa = {
	FISICA: 'FISICA',
	JURIDICA: 'JURIDICA',
};

export async function asyncGetPessoa(idPessoa, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/pessoas/${idPessoa}`, null, onSuccess, onError);
}

export async function asyncGetTotalizadores(interval, onSuccess, onError) {
	if (interval.dataInicial && interval.dataFinal) {
		return await get(
			`${services.GESTOR}/v1/compras/totais?dataInicial=${formatISO(interval.dataInicial, {
				representation: 'date',
			})}&dataFinal=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`,
			null,
			onSuccess,
			onError
		);
	}
}

export async function asyncGetNotasEntrada(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetNotaEntrada(idNotaEntrada, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/compras/${idNotaEntrada}`, null, onSuccess, onError);
}

export async function asyncCreateRegistro(data, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/compras`,
		data,
		null,
		exibirToast(onSuccess, 'Nota de entrada criada com sucesso'),
		onError
	);
}

export async function asyncUpdateRegistro(data, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/compras/${data.id}`,
		data,
		null,
		exibirToast(onSuccess, 'Nota de entrada atualizada com sucesso'),
		onError
	);
}

export async function asyncDeleteNotaEntrada(idNotaEntrada, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/compras/${idNotaEntrada}`,
		null,
		exibirToast(onSuccess, 'Nota de entrada excluída com sucesso'),
		onError
	);
}

export async function buscarContaFavoritaDespesa(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/compras/relacoes/contas?query=favoritaDespesa=="true";situacao=="ATIVA"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarCategoriaFavoritaDespesa(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/compras/relacoes/categorias/receitas?query=favoritaDespesa=="true";ativa=="true"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function readOperacaoFiscalFavorita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/compras/relacoes/operacoes_fiscais?query=favoritoEntrada=="true";situacao=="ATIVO"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function readCondicaoPagamentoSemPagamento(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/compras/relacoes/condicoes_pagamento?query=(tipo==SEM_PAGAMENTO;movimentacao==ENTRADA)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetFormaPagamentoDinheiro(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/compras/relacoes/formas_pagamento?query=(formaPagamentoFiscal==DINHEIRO)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncEfetuarPagamento(idCompra, parcela, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/compras/${idCompra}/duplicatas/${parcela.id}/pagar`,
		parcela,
		null,
		exibirToast(onSuccess, 'Pagamento efetuado com sucesso'),
		onError
	);
}

export async function asyncEstornarParcelaPaga(idCompra, parcelaId, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/compras/${idCompra}/duplicatas/${parcelaId}/estornar`,
		null,
		null,
		exibirToast(onSuccess, 'Pagamento estornado com sucesso'),
		onError
	);
}

export async function asyncFinalizarNota(idCompra, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/compras/${idCompra}/finalizar`,
		null,
		null,
		exibirToast(onSuccess, 'Nota de entrada finalizada com sucesso'),
		onError
	);
}

export async function asyncEstornarNota(idCompra, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/compras/${idCompra}/estornar`,
		null,
		null,
		exibirToast(onSuccess, 'Nota de entrada estornada com sucesso'),
		onError
	);
}

export async function asyncCancelarNota(idCompra, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/compras/${idCompra}/cancelar`,
		null,
		null,
		exibirToast(onSuccess, 'Nota de entrada cancelada com sucesso'),
		onError
	);
}

export async function asyncEstornarCancelamentoNota(idCompra, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/compras/${idCompra}/estornar_cancelamento`,
		null,
		null,
		exibirToast(onSuccess, 'Nota de entrada cancelamento estornado com sucesso'),
		onError
	);
}

export async function asyncConsultaDadosXml(dadosXml, onSuccess) {
	await post(`${services.GESTOR}/v1/compras/dados_xml`, dadosXml, null, onSuccess, null);
}

export async function asyncCadastrarPessoa(data, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/pessoas`,
		data,
		null,
		exibirToast(onSuccess, 'Fornecedor cadastrado com sucesso.'),
		onError
	);
}

export async function asyncCadastrarProdutos(produtos, onSuccess) {
	await post(
		`${services.GESTOR}/v1/produtos/cadastrar_lote`,
		produtos,
		null,
		exibirToast(onSuccess, 'Produtos cadastrados com sucesso.'),
		null
	);
}

export async function asyncAtualizarPrecos(idNotaEntrada, itens, onSuccess) {
	await post(
		`${services.GESTOR}/v1/compras/${idNotaEntrada}/atualizar_preco`,
		itens,
		null,
		exibirToast(onSuccess, itens?.length > 1 ? 'Preços atualizados com sucesso.' : 'Preço atualizado com sucesso.'),
		null
	);
}

export async function asyncValidaNotaUnica(dados, onSuccess) {
	await post(`${services.GESTOR}/v1/compras/nota_unica`, dados, null, onSuccess, null);
}

export async function asyncUploadXml(id, xml, onSuccess, onError) {
	const formData = new FormData();
	formData.append('xml', xml);
	await post(
		`${services.GESTOR}/v1/compras/${id}/xml`,
		formData,
		{ headers: { 'content-type': 'multipart/form-data' } },
		exibirToast(onSuccess, 'XML salvo com sucesso'),
		exibirToast(onError, 'Erro ao salvar XML')
	);
}

export async function asyncDownloadXml(id, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/compras/${id}/xml`, null, onSuccess, onError, false);
}

export async function asyncGetXmlNfe(idMde, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/mde/${idMde}/consulta_xml_nfe`, null, onSuccess, onError, false);
}

export async function asyncGetDownloadXmls(filtro, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/compras/xmls/download${filtro}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}
