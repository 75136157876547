export const helpParametrizacao = {
  enviarEmailAutomatico:
    'Se essa opção estiver marcada e o usuário ter permissão no módulo financeiro ele receberá e-mail de alerta de títulos do contas a pagar e receber',
  mensagemEmailContasPagar:
    'A mensagem deste campo será enviada no corpo do email de alerta do contas a pagar, junto com as informações dos títulos',
  mensagemEmailContasReceber:
    'A mensagem deste campo será enviada no corpo do email de alerta do contas a receber, junto com as informações dos títulos',
  emailAlertaVigencia:
    'Se essa opção estiver marcada, quando a gestão de documento estiver vencendo, o usuário vai receber um e-mail lembrando-o',
  corpoEmailDocumentos: 'Mensagem padrão do corpo do email, exibida no momento de enviar documento para assinatura',
  pesquisaInteracao:
    'Se essa opção estiver marcada, os campos de pesquisa funcionarão apenas com a interação do usuário',
  visualizarReceberTodasFiliais: 'Se essa opção estiver marcada, a filial conectada visualizará o contas a receber de todas as demais filiais',
  visualizarPagarTodasFiliais: 'Se essa opção estiver marcada, a filial conectada visualizará o contas a pagar de todas as demais filiais',
  permitirTransferenciaEntreFiliais: 'Se essa opção estiver marcada, permitirá transferir valores entre filiais',

};
