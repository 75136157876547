import { buscarDadosLoginLocalStorage, buscarMascaraTelefone, services } from 'Common';
import { Dropdown, If, InputField, InputMask, SingleSelectContabilidade } from 'components';
import { Field } from 'formik';

const OptionsRegimeTributario = [
  { label: 'Microempreendedor individual(MEI)', value: 'MEI' },
  { label: 'Simples nacional', value: 'SIMPLES_NACIONAL' },
  {
    label: 'Simples Nacional, excesso sublimite de receita bruta',
    value: 'SIMPLES_NACIONAL_EXCESSO',
  },
  { label: 'Regime normal', value: 'NORMAL' },
];

function InformacoesAdicionais(props) {
  const { values, setFieldValue, informacoesPermissoes, autoridadeOrganizacao } = props;
  return (
    <>
      <Field
        sm="12"
        md="4"
        lg="4"
        xl="4"
        obrigatorio
        component={InputField}
        value={values.email}
        label="E-mail"
        name="email"
        onChange={e => setFieldValue('email', e.target.value)}
        colStyle={{ padding: '4px 8px' }}
        {...informacoesPermissoes}
      />

      <Field
        sm="12"
        md="4"
        lg="4"
        xl="4"
        obrigatorio
        component={InputMask}
        value={values.telefone}
        mask={buscarMascaraTelefone(values.telefone)}
        placeholder={'(  )      -    '}
        label="Telefone"
        name="telefone"
        onChange={e => setFieldValue('telefone', e.target.value)}
        colStyle={{ padding: '4px 8px' }}
        {...informacoesPermissoes}
      />

      <Field
        sm="12"
        md="4"
        lg="4"
        xl="4"
        component={InputField}
        value={values.inscricaoEstadual}
        label="Inscrição estadual"
        name="inscricaoEstadual"
        size={14}
        onChange={e => setFieldValue('inscricaoEstadual', e.target.value)}
        colStyle={{ padding: '4px 8px' }}
        {...informacoesPermissoes}
      />

      <Field
        sm="12"
        md="4"
        lg="4"
        xl="4"
        component={InputField}
        value={values.ieSubstitutoTributario}
        label="I.E. do substituto tributário"
        name="ieSubstitutoTributario"
        size={14}
        onChange={e => setFieldValue('ieSubstitutoTributario', e.target.value)}
        colStyle={{ padding: '4px 8px' }}
        {...informacoesPermissoes}
      />

      <Field
        sm="12"
        md="4"
        lg="4"
        xl="4"
        component={InputField}
        value={values.inscricaoMunicipal}
        label="Inscrição municipal"
        name="inscricaoMunicipal"
        size={15}
        onChange={e => setFieldValue('inscricaoMunicipal', e.target.value)}
        colStyle={{ padding: '4px 8px' }}
        {...informacoesPermissoes}
      />

      <Field
        sm="12"
        md="4"
        lg="4"
        xl="4"
        component={InputField}
        value={values.cnaeFiscal}
        label="CNAE fiscal"
        name="cnaeFiscal"
        size={7}
        onChange={e => setFieldValue('cnaeFiscal', e.target.value)}
        colStyle={{ padding: '4px 8px' }}
        {...informacoesPermissoes}
      />

      <Field
        sm="12"
        md="4"
        lg="4"
        xl="4"
        obrigatorio
        showClear={false}
        component={Dropdown}
        value={values.regimeTributario}
        label="Regime tributário"
        name="regimeTributario"
        onChange={e => setFieldValue('regimeTributario', e)}
        colStyle={{ padding: '4px 8px' }}
        options={OptionsRegimeTributario}
        {...informacoesPermissoes}
      />
      <Field
        sm="12"
        md="4"
        lg={autoridadeOrganizacao === 'CLIENTE' ? '2' : '4'}
        xl={autoridadeOrganizacao === 'CLIENTE' ? '2' : '4'}
        component={InputField}
        value={values.suframa}
        label="Suframa"
        name="suframa"
        size={9}
        onChange={e => setFieldValue('suframa', e.target.value)}
        colStyle={{ padding: '4px 8px' }}
        {...informacoesPermissoes}
      />
      <If test={autoridadeOrganizacao === 'CLIENTE'}>
        <Field
          sm="12"
          md="8"
          lg="6"
          xl="6"
          component={SingleSelectContabilidade}
          url={`${services.GESTOR}/v1/filiais/relacoes/contabilidades`}
          name="contabilidade"
          label="Contabilidade/Agenciador"
          value={values.contabilidade}
          onChange={e => props.setFieldValue('contabilidade', e)}
          colStyle={{ padding: '4px 8px' }}
          {...informacoesPermissoes}
        />
      </If>
    </>
  );
}
export default InformacoesAdicionais;
