import { copiarObjeto } from 'Common';
import { useFormikContext } from 'formik';

import { Button, ButtonCancelar, Grid, estadosBotaoCancelar } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';

function ActionButtons({ onHide, registrosSelecionados }) {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, setImportouNFe } = useContextMDFe();

	function handleClickImportarNotas() {
		const municipios = copiarObjeto(values?.municipios ?? []);

		if (registrosSelecionados?.length > 0) {
			registrosSelecionados?.forEach((registro) => {
				let municipioJaAdicionado = false;

				if (municipios?.length > 0) {
					municipios?.forEach((municipio, index) => {
						if (municipio.idRegistro === registro?.municipio?.id) {
							municipioJaAdicionado = true;

							validarDocumentosExistenteOuAddNovo(municipio, index, registro, municipios);
						}
					});
				}

				if (!municipioJaAdicionado) {
					municipios?.push(addNovoMunicipio(registro));
				}
			});
		}

		setFieldValue('municipios', municipios);
		setImportouNFe(true);
		onHide();
	}

	function addNovoMunicipio(registro) {
		return {
			id: null,
			idRegistro: registro?.municipio?.id,
			municipio: {
				label: `${registro?.municipio?.nome} - ${registro?.municipio?.estado?.sigla}`,
				value: registro?.municipio?.id,
				registro: registro?.municipio,
			},
			documentos: [{ id: null, chave: registro?.chave }],
		};
	}

	function validarDocumentosExistenteOuAddNovo(municipio, index, registro, municipios) {
		if (municipio?.documentos?.length > 0) {
			let existeDocumentoNoMunicipio = false;

			municipio?.documentos?.forEach((documento) => {
				if (registro?.chave === documento.chave) {
					existeDocumentoNoMunicipio = true;
				}
			});

			if (!existeDocumentoNoMunicipio) {
				municipios[index]?.documentos.push({ id: null, chave: registro.chave });
			}
		}
	}

	return (
		<Grid style={{ paddingLeft: '8px' }}>
			<ButtonCancelar onClick={onHide} estadoBotao={estadosBotaoCancelar.VOLTAR} {...informacoesPermissoes} />
			<Button
				className="p-button-success"
				label="Importar notas"
				icon="fa fa-send"
				onClick={handleClickImportarNotas}
				style={{ margin: '5px', marginLeft: '0px' }}
				disabled={!registrosSelecionados || registrosSelecionados?.length === 0}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { ActionButtons };
