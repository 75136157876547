export const styleStatus = {
    height: '38px',
    borderRadius: '25px',
    fontWeight: "bold",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px'
}

export const styleName = {
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '2px 0px'
}