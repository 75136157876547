import { mensagensDeValidacao, services } from 'Common';
import {	
	Checkbox,
	Dropdown,
	Grid,
	InputDate,
	ModalRelatorio,
	MultiSelectFiliais,
	MultipleSelectSetores,
	SingleSelectMarca,
	SingleSelectPessoa,
} from 'components';
import { format, formatISO, isValid, parseISO, startOfMonth } from 'date-fns';
import { Field, withFormik } from 'formik';
import { useEffect } from 'react';
import { validarFormulario } from 'views/Util';
import { asyncBaixarRelatorioProdutosPorPeriodo } from 'views/fiscal/vendas/PedidoVendasOrcamentos/Requests';
import * as Yup from 'yup';
import { helpMessage, opcoesCusto } from './util/constantes';

const initialValue = {
	dataInicial: formatISO(startOfMonth(new Date())),
	dataFinal: new Date().toISOString(),
	status: 'FINALIZADO',
	cliente: null,
	setores: null,
	custo: 'NAO_EXIBIR_CUSTO',
	marca: null,
	filiais: null,
	adicionarObservacao : false,
	ocultarValorVenda : false,
	considerarDevolucoes : true,
};

function ProdutosPorPedido(props) {
	const { visible, onHide, values, handleSubmit, validateForm, setFieldValue } = props;

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('InputDataInicial')?.focus();
		}, 500);
	}, []);

	async function imprimir() {
		await handleSubmit();
		await validateForm();

		try {
			const dataInicialFormatada = format(parseISO(values.dataInicial), 'yyyy-MM-dd');
			const dataFinalFormatada = format(parseISO(values.dataFinal), 'yyyy-MM-dd');
			let setoresFormatados = '';
			let filiaisFormatadas = '';

			if (values.setores) {
				setoresFormatados = values.setores.map((item) => `'${item.value}'`).join(',');
			}

			if (values.filiais) {
				filiaisFormatadas = values.filiais.map((item) => `'${item.value}'`).join(',');
			}

			if (await validarFormulario(props)) {
				await asyncBaixarRelatorioProdutosPorPeriodo(
					dataInicialFormatada,
					dataFinalFormatada,
					filiaisFormatadas,
					values.marca?.value,
					values.status,
					values.cliente?.value,
					setoresFormatados,
					values.custo,
					values.adicionarObservacao,
					values.ocultarValorVenda,
					values.considerarDevolucoes,
					({ data: pdf }) => {
						const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						const arquivoURL = URL.createObjectURL(arquivoPDF);
						const relatorio = window.open(arquivoURL);
						if (relatorio) {
							relatorio.onload = () => {
								setTimeout(() => {
									relatorio.document.title = 'Relatório de produtos dos pedidos de venda por período';
								}, 250);
							};
						}
						onHide();
					}
				);
			}
		} catch (e) {
			console.error(e);
		}
	}

	const parametros = (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={InputDate}
				label="Data inicial"
				name="dataInicial"
				obrigatorio
				helpMessage={helpMessage.dataInicial}
				value={values.dataInicial}
				touched
				id="InputDataInicial"
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={InputDate}
				label="Data final"
				name="dataFinal"
				obrigatorio
				helpMessage={helpMessage.dataFinal}
				value={values.dataFinal}
				touched
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={Dropdown}
				label="Status"
				name="status"
				helpMessage={helpMessage.status}
				onChange={(e) => setFieldValue('status', e.value)}
				options={[
					{ value: 'FINALIZADO', label: 'Finalizados' },
					{ value: 'PENDENTE', label: 'Pendentes' },
					{ value: 'CANCELADA', label: 'Cancelados' },
					{ value: 'Todos', label: 'Todos' },
				]}
				value={values.status}
				showClear={false}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={Dropdown}
				label="Custo a utilizar"
				name="custo"
				onChange={(e) => setFieldValue('custo', e.value)}
				options={opcoesCusto}
				value={values.custo}
				showClear={false}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={MultipleSelectSetores}
				label="Setor(es)"
				name="setores"
				obrigatorio
				url={`${services.GESTOR}/v1/pedidos_venda/relacoes/setores`}
				onChange={(e) => setFieldValue('setores', e)}
				value={values.setores}
				showClear={false}
				utilizaFavorito
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={MultiSelectFiliais}
				label="Filiais"
				name="filiais"
				onChange={(e) => setFieldValue('filiais', e)}
				value={values.filiais}
				showClear={false}
				startConnectedFilial
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				label="Cliente"
				name="cliente"
				component={SingleSelectPessoa}
				esconderBotao
				value={values?.cliente}
				helpMessage={helpMessage.cliente}
				onChange={(e) => setFieldValue('cliente', e)}
				showClear
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={SingleSelectMarca}
				label="Marca"
				name="marca"
				tipoPessoa="marca"
				onChange={(e) => setFieldValue('marca', e)}
				value={values.marca}
				showClear={false}
			/>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={Checkbox}
				label="Adicionar observações do produto"
				name="adicionarObservacao"
				onChange={() => {
					setFieldValue('adicionarObservacao', !values.adicionarObservacao);
				}}
				checked={values.adicionarObservacao}
			/>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={Checkbox}
				label="Ocultar o valor de venda"
				name="ocultarValorVenda"
				onChange={() => {
					setFieldValue('ocultarValorVenda', !values.ocultarValorVenda);
				}}
				checked={values.ocultarValorVenda}
			/>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={Checkbox}
				label="Considerar devoluções"
				name="considerarDevolucoes"
				onChange={() => {
					setFieldValue('considerarDevolucoes', !values.considerarDevolucoes);
				}}
				checked={values.considerarDevolucoes}
			/>
		</Grid>
	);
	return (
		<ModalRelatorio
			header="Relatório de produtos dos pedidos de venda por período"
			visible={visible}
			onHide={onHide}
			onImprimir={imprimir}
			parametros={parametros}
			isValid={props.errors.length === 0}
		/>
	);
}

const ModalProdutosPorPedido = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		const errors = {};

		if (!values.dataInicial || values.dataInicial === '') {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataFinal || values.dataFinal === '') {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.dataInicial && !isValid(parseISO(values.dataInicial))) {
			errors.dataInicial = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.dataFinal && !isValid(parseISO(values.dataFinal))) {
			errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (
			!values.filiais ||
			values.filiais.length === 0 ||
			values.filiais === null ||
			values.filiais === '' ||
			values.filiais === undefined
		) {
			errors.filiais = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		dataInicial: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		dataFinal: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		setores: Yup.array().min(1, mensagensDeValidacao.OBRIGATORIO).nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ProdutosPorPedido);

export default ModalProdutosPorPedido;
