import { connect } from 'react-redux';
import './Styles/index.css';
import FormActions from '../FormActions';
import FormMobileActions from '../FormMobileActions';

function Form(props) {
	const { children, isModal, header, isMobile, className, style } = props;

	const formAction = children && children.filter(child => child.type === FormActions);
	const formMobileAction = children && children.filter(child => child.type === FormMobileActions);
	const otherChildren =
		children && children.filter(child => child.type !== FormActions && children.type !== FormMobileActions);

	const renderActionButtons = () => {
		if (isMobile && formMobileAction.length > 0) {
			return formMobileAction;
		} else {
			return formAction;
		}
	};

	if (header && !isModal) {
		let classNameCard = className ? `card card-w-title ${className}` : `card card-w-title`;
		return (
			<>
				{renderActionButtons()}
				<div className={classNameCard} style={style}>
					<h1>{header}</h1>
					{otherChildren}
				</div>
			</>
		);
	} else {
		return (
			<>
				{renderActionButtons()}
				<div className={`card ${className}`} style={{ width: '100%', ...style }}>
					{otherChildren}
				</div>
			</>
		);
	}
}

Form.defaultProps = {
	className: 'card-default',
};

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Form);
