import { mensagensDeValidacao } from 'Common';
import { Grid, InputField, ModalRelatorio, SingleSelectPessoa, TextArea } from 'components';
import { Field, withFormik } from 'formik';
import { useEffect } from 'react';
import { Dropdown } from '../../../../../components';
import { asyncBaixarRelatorioContasAReceberInformeRendimento } from '../../Requests';

const initialValue = {
	anoRelatorio: null,
	pessoaPagador: null,
	pessoaBeneficiario: null,
	aliquota: 0,
	informacoesComplementares: null,
};

function ContasReceberInformeRendimentos(props) {
	const { visible, onHide, values, handleSubmit, isValid, setFieldValue } = props;

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('inputAnoRelatorio')?.focus();
		}, 500);
	}, []);

	function gerarAnosParaSelect() {
		const years = [];
		const currentYear = new Date().getFullYear();

		for (let year = currentYear; year >= 2010; year--) {
			years.push({ label: year.toString(), value: year });
		}

		return years;
	}

	async function imprimir() {
		await handleSubmit();

		try {
			const anoRelatorio = values?.anoRelatorio;
			const pagadorId = values?.pessoaPagador?.value;
			const beneficiarioId = values?.pessoaBeneficiario?.value;
			const aliquota = parseFloat(values?.aliquota);
			const informacoesComplementares = values?.informacoesComplementares;

			if (isValid) {
				await asyncBaixarRelatorioContasAReceberInformeRendimento(
					anoRelatorio,
					pagadorId,
					beneficiarioId,
					aliquota,
					informacoesComplementares,
					({ data: pdf }) => {
						const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						const arquivoURL = URL.createObjectURL(arquivoPDF);
						const relatorio = window.open(arquivoURL);
						if (relatorio) {
							relatorio.onload = () => {
								setTimeout(() => {
									relatorio.document.title = 'Relatório contas a receber - informe rendimentos';
								}, 250);
							};
						}
						onHide();
					}
				);
			}
		} catch (e) {
			console.error(e);
		}
	}

	const parametros = (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={Dropdown}
				options={gerarAnosParaSelect()}
				onChange={(e) => setFieldValue('anoRelatorio', e.value)}
				label="Ano vigente"
				name="anoRelatorio"
				showClear={false}
				obrigatorio
				value={values.anoRelatorio}
				touched
				id="inputAnoRelatorio"
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				label="Pessoa (beneficiário)"
				component={SingleSelectPessoa}
				name="pessoaBeneficiario"
				isClearable={false}
				esconderBotao
				obrigatorio
				onChange={(e) => setFieldValue('pessoaBeneficiario', e)}
				value={values.pessoaBeneficiario}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				label="Pessoa (pagador)"
				component={SingleSelectPessoa}
				name="pessoaPagador"
				isClearable={false}
				esconderBotao
				obrigatorio
				onChange={(e) => setFieldValue('pessoaPagador', e)}
				value={values.pessoaPagador}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				label="Comissão (%)"
				component={InputField}
				name="aliquota"
				type="number"
				esconderBotao
				obrigatorio
				onChange={(e) => setFieldValue('aliquota', e.target.value)}
				value={values.aliquota}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={TextArea}
				label="Informações complementares"
				name="informacoesComplementares"
				value={values?.informacoesComplementares}
				onChange={(e) => setFieldValue('informacoesComplementares', e.target.value)}
			/>
		</Grid>
	);
	return (
		<ModalRelatorio
			header="Relatório contas a receber - informe de rendimentos"
			visible={visible}
			onHide={onHide}
			onImprimir={imprimir}
			parametros={parametros}
			isValid={isValid}
		/>
	);
}

const ModalContasReceberInformeRendimentos = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		const errors = {};

		if (!values.anoRelatorio) {
			errors.anoRelatorio = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.pessoaBeneficiario) {
			errors.pessoaBeneficiario = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.pessoaPagador) {
			errors.pessoaPagador = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.aliquota < 0 || !values.aliquota) {
			errors.aliquota = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ContasReceberInformeRendimentos);

export default ModalContasReceberInformeRendimentos;
