import { useState, useEffect, useRef } from 'react';
import Grid from '../../../../../components/Grid';
import Col from '../../../../../components/Col';
import ProdutosAbaixoEstoqueMinimo from './components/ProdutosAbaixoEstoqueMinimo';
import GraficoValorEstoquePorPeriodo from './components/GraficoValorEstoquePorPeriodo';
import { connect } from 'react-redux';
import { usuarioPossuiPermissao } from '../../../../../Common/Autenticacao';
import { recursos, permissoes } from '../../../../../Common/Constantes/autorizacao';
import If from '../../../../../components/If';
import ModalSaidaEstoque from '../../../../estoque/Movimentacoes/components/ModalSaidaEstoque';
import ModalEntradaEstoque from '../../../../estoque/Movimentacoes/components/ModalEntradaEstoque';
import { Button } from 'primereact/button';
import usePrevious from '../../../../../Common/Hooks/usePrevious';

function DashboardEstoque(props) {
	const { dataAtualizacaoMenuLateral, dataInicial, dataFinal, isMobile } = props;

	const [atualizarGraficoNovamente, setAtualizarGraficoNovamente] = useState(null);
	const [podeVisualizarEstoque, setPodeVisualizarEstoque] = useState(
		usuarioPossuiPermissao(recursos.ESTOQUE_DASHBOARD, permissoes.VISUALIZAR)
	);
	const [podeInserirMovimentacaoEstoque, setPodeInserirMovimentacaoEstoque] = useState(
		usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.INSERIR)
	);
	const [exibirModalLancamentoEntrada, setExibirModalLancamentoEntrada] = useState(false);
	const [exibirModalLancamentoSaida, setExibirModalLancamentoSaida] = useState(false);
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	const styleCards = isMobile ? { margin: '8px', padding: '0.5em 0em', flex: 'auto' } : { padding: '0.5em' };

	const styleButtons = {
		margin: isMobile ? '3px 5px' : '3px 7px',
		width: isMobile ? 'calc(50% - 10px)' : '185px',
	};

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataAtualizacaoMenuLateral !== dataAtualizacaoMenuLateral) {
				setAtualizarGraficoNovamente(getNewTimestamp());
				setPodeVisualizarEstoque(usuarioPossuiPermissao(recursos.ESTOQUE_DASHBOARD, permissoes.VISUALIZAR));
			}
		} else afterFirstRenderRef = true;
	}, []);

	function getNewTimestamp() {
		return new Date().getTime();
	}

	function onHideModal() {
		setExibirModalLancamentoEntrada(false);
		setExibirModalLancamentoSaida(false);
		atualizarGraficos();
	}

	function atualizarGraficos() {
		setAtualizarGraficoNovamente(getNewTimestamp());
	}

	if (dataInicial && dataFinal) {
		return (
			<Grid style={{ margin: '0px' }}>
				{podeVisualizarEstoque && (
					<>
						<Col style={{ padding: '0 0 0 2px' }}>
							<Button
								label="Nova entrada"
								className="p-button-success"
								onClick={() => setExibirModalLancamentoEntrada(true)}
								style={styleButtons}
								disabled={!podeInserirMovimentacaoEstoque}
								title={
									!podeInserirMovimentacaoEstoque
										? 'Você não possui permissão para movimentar estoque'
										: null
								}
							/>
							<Button
								label="Nova saída"
								className="p-button-danger"
								onClick={() => setExibirModalLancamentoSaida(true)}
								style={styleButtons}
								disabled={!podeInserirMovimentacaoEstoque}
								title={
									!podeInserirMovimentacaoEstoque
										? 'Você não possui permissão para movimentar estoque'
										: null
								}
							/>
						</Col>
						<Col xs="12" sm="12" md="12" lg="12" xl="12" style={styleCards}>
							<GraficoValorEstoquePorPeriodo
								dataInicial={dataInicial}
								dataFinal={dataFinal}
								atualizarGraficoNovamente={atualizarGraficoNovamente}
								isMobile={isMobile}
								dataAtualizacaoMenuLateral={dataAtualizacaoMenuLateral}
							/>
						</Col>
						<Col xs="12" sm="12" md="12" lg="12" xl="12" style={styleCards}>
							<ProdutosAbaixoEstoqueMinimo
								atualizarGraficoNovamente={atualizarGraficoNovamente}
								atualizarGraficos={() => setAtualizarGraficoNovamente(getNewTimestamp())}
								dataAtualizacaoMenuLateral={dataAtualizacaoMenuLateral}
							/>
						</Col>
					</>
				)}
				<If test={exibirModalLancamentoSaida}>
					<ModalSaidaEstoque
						atualizarListagem={atualizarGraficos}
						onNovoClick={() => setProdutoSelecionado(null)}
						visible={exibirModalLancamentoSaida}
						onHide={onHideModal}
					/>
				</If>
				<If test={exibirModalLancamentoEntrada}>
					<ModalEntradaEstoque
						atualizarListagem={atualizarGraficos}
						onNovoClick={() => setProdutoSelecionado(null)}
						visible={exibirModalLancamentoEntrada}
						onHide={onHideModal}
					/>
				</If>
			</Grid>
		);
	} else {
		return <span>Carregando informações...</span>;
	}
}

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
	dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
});
export default connect(mapStateToProps)(DashboardEstoque);
