import { useEffect } from 'react';
import { Field } from 'formik';
import { Fieldset } from 'primereact/fieldset';

import { services } from '../../../../../../../Common/Constantes/api';

import { Col, Grid, InputMoney, Dropdown, SingleSelectCest, SingleSelectNcm, InputField } from '../../../../../../../components';

import { helpProdutosForm } from '../../../Help';

export default function FieldsNotaFiscal(props) {
	const { setFieldValue, values, informacoesPermissoes, setFieldTouched } = props;
	const { pesoLiquido, pesoBruto, cest, ncm, codigoAnp } = values;

	useEffect(() => {
		if (values.ncm) {
			setFieldTouched('ncm');
		}
	}, [values.ncm]);

	const origem = [
		{
			label: '0 - Nacional, exceto as indicadas nos código 3, 4, 5 e 8',
			value: 'NACIONAL',
		},
		{
			label: '1 - Estrangeira - Importação direta, exceto a indicada no código 6',
			value: 'ESTRANGEIRA',
		},
		{
			label: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7',
			value: 'ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO',
		},
		{
			label: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%',
			value: 'NACIONAL_IMPORTACAO_ENTRE_40_E_70_PORCENTO',
		},
		{
			label: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes',
			value: 'NACIONAL_COM_PROCESSO_PRODUTIVO',
		},
		{
			label: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%',
			value: 'NACIONAL_IMPORTACAO_INFERIOR_40_PORCENTO',
		},
		{
			label: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural',
			value: 'ESTRANGEIRA_IMPORTACAO_DIRETA',
		},
		{
			label: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX e gás natural',
			value: 'ESTRANGEIRA_ADQUIR_MERCADO_INTERNO_SEM_SIMILAR_NAC',
		},
		{
			label: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%',
			value: 'NACIONAL_IMPORTACAO_SUPERIOR_70_PORCENTO',
		},
	];

	return (
		<Grid>
			<Col>
				<Fieldset className="fieldset">
					<Grid>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							component={Dropdown}
							label="Origem do produto"
							name="origem"
							showClear={false}
							helpMessage={helpProdutosForm.origem}
							onChange={e => setFieldValue('origem', e.value)}
							options={origem}
							id="ProdutoDropdownOrigemProduto"
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							label="NCM"
							name="ncm"
							component={SingleSelectNcm}
							mostrarSimbolo={true}
							url={`${services.GESTOR}/v1/produtos/relacoes/ncms`}
							value={ncm}
							onChange={e => {
								setFieldValue('ncm', e);
							}}
							helpMessage={helpProdutosForm.ncm}
							obrigatorio={values.possuiModulos.VENDAS}
							isClearable={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							label="CEST"
							name="cest"
							component={SingleSelectCest}
							value={cest}
							onChange={e => setFieldValue('cest', e)}
							helpMessage={helpProdutosForm.cest}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="2"
							xl="2"
							component={InputMoney}
							label="Peso bruto"
							name="pesoBruto"
							size={11}
							decimalScale={3}
							prefix=""
							placeholder=""
							helpMessage={helpProdutosForm.pesoBruto}
							onChange={e => setFieldValue('pesoBruto', e.target.value)}
							value={pesoBruto}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="2"
							xl="2"
							component={InputMoney}
							label="Peso líquido"
							name={'pesoLiquido'}
							size={11}
							decimalScale={3}
							prefix=""
							placeholder=""
							helpMessage={helpProdutosForm.pesoLiquido}
							onChange={e => setFieldValue('pesoLiquido', e.target.value)}
							value={pesoLiquido}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="2"
							xl="2"
							component={InputField}
							label="Código ANP"
							name={'codigoAnp'}
							size={9}
							decimalScale={0}
							prefix=""
							placeholder=""
							helpMessage={helpProdutosForm.codigoAnp}
							onChange={e => setFieldValue('codigoAnp', e.target.value)}
							value={codigoAnp}
							{...informacoesPermissoes}
						/>
					</Grid>
				</Fieldset>
			</Col>
		</Grid>
	);
}
