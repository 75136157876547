import { Field } from 'formik';
import { Card, Col, Dropdown, Grid, InputDate } from '../../../../../../components';
import { OptionsMotivoInventario, OptionsTipoCusto } from '../../../Util/constantes';

function BlocoH(props) {
	const { values, geraBlocoH, podeGerar, setFieldValue, errors } = props;
	return (
		<Col sm="12" md="12" lg="12" xl="12">
			<Card header={<h3 style={{ margin: '0px 0px 0px 15px', paddingTop: '7px' }}>Bloco H - Inventário</h3>}>
				<Grid style={{ paddingBottom: '8px' }}>
					<Field
						sm="12"
						md="2"
						lg="2"
						xl="2"
						component={InputDate}
						label="Data base"
						name="dataBase"
						value={values.blocoH.data}
						obrigatorio={geraBlocoH}
						disabled={!geraBlocoH || !podeGerar}
						onChange={event => setFieldValue('blocoH.data', event.target.value)}
						useFormTouched={false}
						useFormErrors={false}
						touched
						errors={errors?.dataBase ? errors.dataBase : undefined}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						label="Tipo de custo"
						name="tipoCusto"
						options={OptionsTipoCusto}
						onChange={event => setFieldValue('blocoH.tipoCusto', event)}
						value={values.blocoH.tipoCusto}
						obrigatorio={geraBlocoH}
						disabled={!geraBlocoH || !podeGerar}
						showClear={false}
						useFormTouched={false}
						useFormErrors={false}
						touched
						errors={errors?.tipoCusto ? errors.tipoCusto : undefined}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						component={Dropdown}
						label="Motivo"
						name="motivo"
						options={OptionsMotivoInventario}
						onChange={event => setFieldValue('blocoH.motivo', event)}
						value={values.blocoH.motivo}
						obrigatorio={geraBlocoH}
						disabled={!geraBlocoH || !podeGerar}
						showClear={false}
						useFormTouched={false}
						useFormErrors={false}
						touched
						errors={errors?.motivo ? errors.motivo : undefined}
					/>
				</Grid>
			</Card>
		</Col>
	);
}

export default BlocoH;
