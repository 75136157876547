import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar } from 'components';
import { useFormikContext } from 'formik';
import { validarFormulario } from 'views/Util';
import { useContextMDFe } from 'views/transporte/MDFe/Context';

function ActionButtons({ onHide, onSave }) {
	const { values, dirty, handleReset, handleSubmit, validateForm } = useFormikContext();
	const { informacoesPermissoes } = useContextMDFe();

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			onHide();
		}
	}

	async function handleClickSalvar() {
		handleSubmit();

		if (await validarFormulario({ values, validateForm })) {
			onSave(values);
			onHide();
		}
	}

	return (
		<>
			<ButtonCancelar onClick={handleClickCancelar} estadoBotao={estadoBotaoCancelar} {...informacoesPermissoes} />
			<ButtonSalvar onClick={handleClickSalvar} disabled={!dirty} {...informacoesPermissoes} />
		</>
	);
}

export { ActionButtons };
