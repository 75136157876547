import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	ToastTypes,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	notify,
} from 'components';
import { useFormikContext } from 'formik';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { atualizarRegiao, deleteRegiaoVenda, salvarRegiao } from 'views/cadastros/Regiao/Requests';
import { useContextRegiaoVenda } from 'views/cadastros/Regiao/Context';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../../../Util';
import { CADASTROURL, INITIAL_VALUES_REGIAO, PESQUISAURL } from '../../Utils/constantes';
import { converterFormParaApi } from '../../Utils/regiaoConverter';

export default function ActionButtons({ asyncSelectRegistro }) {
	const { values, validateForm, dirty, resetForm, handleReset, handleSubmit } = useFormikContext();
	const { idRegiao, history, informacoesPermissoes } = useContextRegiaoVenda();

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const onClickNovo = dirty ? () => salvar(novo) : novo;

	function cancelar() {
		if (dirty) {
			handleReset();
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
	}

	async function novo() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: INITIAL_VALUES_REGIAO });
	}

	async function salvar(novoOnSuccess) {
		handleSubmit();

		if (await validarFormulario({ validateForm, values })) {
			if (idRegiao) {
				asyncUpdateRegistro(values, novoOnSuccess);
			} else {
				asyncCreateRegistro(values, novoOnSuccess);
			}
		}
	}

	async function asyncCreateRegistro(dadosFormulario, novoOnSuccess) {
		await salvarRegiao(
			{ ...dadosFormulario, situacao: 'ATIVO' },
			({ data: response }) => {
				if (typeof novoOnSuccess === 'function') {
					novoOnSuccess();
				} else {
					asyncSelectRegistro(response.id);
				}
			},
			notify('Região salva com sucesso', ToastTypes.SUCCESS)
		);
	}

	async function asyncUpdateRegistro(dadosFormulario, novoOnSuccess) {
		handleSubmit();
		converterFormParaApi(dadosFormulario);
		await atualizarRegiao(
			idRegiao,
			converterFormParaApi(dadosFormulario),
			({ data: response }) => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					asyncSelectRegistro(response.id);
				}
			},
			notify('Região atualizada com sucesso', ToastTypes.SUCCESS)
		);
	}

	function handleClickDeletar() {
		confirmarExclusao(() => excluirRegistro());
	}

	async function excluirRegistro() {
		if (!values.id) return;
		await deleteRegiaoVenda(values.id, () => {
			voltarParaAPesquisa(history, PESQUISAURL);
			notify('Região removída com sucesso', ToastTypes.SUCCESS);
		});
	}

	return (
		<>
			<ButtonCancelar onClick={cancelar} estadoBotao={estadoBotaoCancelar} />
			<ButtonSalvar onClick={() => salvar()} disabled={!dirty} />
			<ButtonNovo
				onClick={onClickNovo}
				estadoBotao={estadoBotaoNovo}
				hidden={!values.id && !dirty}
				disabled={!informacoesPermissoes.podeInserir}
			/>
			<ButtonExcluir
				onClick={handleClickDeletar}
				hidden={!values.id}
				disabled={!informacoesPermissoes.podeExcluir || dirty}
			/>
		</>
	);
}
