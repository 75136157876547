import { useEffect, useState } from 'react';
import MaskInput from 'react-maskinput';
import { manterApenasNumeros, renderizarValidacao, usuarioPossuiModulos } from 'Common';
import {
	buscarDisabledDeAcordoComAsPermissoes,
	buscarHiddenDeAcordoComAsPermissoes,
	buscarTitleCampoDeAcordoComAsPermissoes,
} from 'Common/Autorizacao/ManipulacaoDeComponentes';
import { buscarClassNamePelosErros } from 'Common/TratamentoDeErro/validacoesDeCampos';
import { Col, Button } from 'components';

function InputCnpjWithButton(props) {
	const {
		sm,
		md,
		lg,
		xl,
		id,
		colId,
		name,
		value,
		placeholder,
		onKeyUp,
		mask,
		onClick,
		podeInserir,
		podeEditar,
		podeVisualizar,
		estadoCadastro,
		errors,
		touched,
		style,
		onBlur,
	} = props;

	const [validouModulo, setValidouModulo] = useState(true);

	useEffect(() => {
		if (props.modulosEspecificos) {
			setValidouModulo(usuarioPossuiModulos(props.modulosEspecificos));
		}
	}, []);

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden))
			return { display: 'none', ...props.colStyle };
		return props.colStyle;
	}

	function onChange(e) {
		if (e.target.value === '') {
			props.onChange({ value: null });
		} else {
			props.onChange({ value: manterApenasNumeros(e.target.value) });
		}
	}

	function montarLabel() {
		const { obrigatorio } = props;

		const { label } = props;
		if (obrigatorio) {
			return (
				<label title={props.helpMessage}>
					{label} <b style={{ fontSize: '18px', lineHeight: '5px' }}> *</b>{' '}
				</label>
			);
		}
		return <label title={props.helpMessage}>{label}</label>;
	}

	if (!validouModulo) {
		return null;
	}

	const disabled = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro);
	const colStyle = getColStyle();
	const title = buscarTitleCampoDeAcordoComAsPermissoes(
		podeVisualizar,
		podeInserir,
		podeEditar,
		estadoCadastro,
		props.title
	);
	const classNameInput = buscarClassNamePelosErros(
		`p-inputtext p-state-default p-corner-all p-widget ${disabled && 'p-disabled'}`,
		errors,
		touched
	);
	const disabledButton = String(manterApenasNumeros(value)).length < 14 || disabled;

	return (
		<Col id={colId} sm={sm} md={md} lg={lg} xl={xl} style={colStyle}>
			{montarLabel()}
			<span className="p-fluid" title={title}>
				<div className="p-inputgroup">
					<MaskInput
						id={id}
						className={classNameInput}
						style={{ height: '32px', borderRadius: '3px 0px 0px 3px', ...style }}
						name={name}
						placeholder={placeholder}
						mask={mask}
						value={value ?? ''}
						onChange={onChange}
						disabled={disabled}
						onBlur={onBlur}
						onKeyUp={onKeyUp}
						autoComplete="off"
					/>
					<Button
						style={{
							margin: '0px',
							borderTopLeftRadius: '0px',
							borderBottomLeftRadius: '0px',
							height: '32px',
						}}
						icon="fa fa-search"
						title="Preencher as informações automaticamente"
						disabled={disabledButton}
						onClick={onClick}
					/>
				</div>
			</span>
			{renderizarValidacao(errors, touched)}
		</Col>
	);
}

export default InputCnpjWithButton;
