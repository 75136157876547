import React, { Component } from 'react';
import Grid from '../../../../../../../../components/Grid';
import Col from '../../../../../../../../components/Col';
import { formatarMonetario } from '../../../../../../../../Common/Mascara';
import If from '../../../../../../../../components/If';
import { Vendas } from '../../../../../../../Util/constantes';

const produtoStyle = {
	fontSize: '15px',
	fontWeight: '400',
	lineHeight: '1.5',
	color: '#000000de',
};

const hrStyle = {
	width: '100%',
	border: 'none',
	height: '1px',
	margin: '0',
	flexShrink: '0',
	backgroundColor: '#0000001f',
	marginBottom: '7px',
};

const valorStyle = {
	display: 'flex',
	justifyContent: 'flex-end',
	fontSize: '15px',
	fontWeight: '600',
	color: Vendas.cores.verde,
	padding: '0px',
	marginLeft: '5px',
};

export default function RankingMaisComprados(props) {
	const { itens, exibirButtonVerMais, onVerMaisClick } = props;

	return (
		<div
			className="dashboard-scroll"
			style={{
				padding: '15px 15px 0px 15px',
				maxHeight: '280px',
				overflow: 'auto',
				marginTop: '10px',
				maxWidth: '100%',
			}}
		>
			{itens.map((item, index) => {
				return (
					<React.Fragment key={index}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								paddingBottom: '7px',
							}}
						>
							<span style={produtoStyle}>
								{' '}
								<b>{index + 1}º</b> {item.descricao}
							</span>
							<span style={valorStyle}>{formatarMonetario(item.valor)}</span>
						</div>
						<hr style={hrStyle} />
					</React.Fragment>
				);
			})}
			<If test={exibirButtonVerMais}>
				<div style={{ textAlign: 'center', padding: '0.5rem 0px' }}>
					<span onClick={onVerMaisClick} style={{ color: `${Vendas.cores.azul}`, cursor: 'pointer' }}>
						Ver mais
					</span>
				</div>
			</If>
		</div>
	);
}
