import React from 'react';
import { statusDocumento } from './constantes';
import { formatarParaPesquisarTiposEnumerados } from '../../../../Common/ManipulacaoDeString';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';

export function renderStatus(row) {
  let strongColor = '#721358';
  let lightColor = '#BFA8C5';
  let texto;
  let paddingSize = '';

  switch (row.status) {
    case statusDocumento.PENDENTE:
      texto = aplicarEstiloArquivado(row.arquivado, 'Pendente');
      paddingSize = '0.2rem 0.9rem';
      break;
    case statusDocumento.ARQUIVO_ADICIONADO:
      texto = aplicarEstiloArquivado(row.arquivado, 'Arquivo adicionado');
      strongColor = '#01579b';
      lightColor = '#b3e5fc';
      paddingSize = '0.2rem 0.9rem';
      break;
    case statusDocumento.AGUARDANDO_ASSINATURAS:
      texto = aplicarEstiloArquivado(row.arquivado, 'Aguardando assinaturas');
      strongColor = '#c16b13';
      lightColor = '#fff7cd';
      paddingSize = '0.2rem 0.9rem';
      break;
    case statusDocumento.ASSINADO:
      texto = aplicarEstiloArquivado(row.arquivado, 'Assinado');
      strongColor = 'green';
      lightColor = '#dcedc8';
      paddingSize = '0.2rem 1.5rem';
      break;
    case statusDocumento.REJEITADO:
      texto = aplicarEstiloArquivado(row.arquivado, 'Rejeitado');
      strongColor = '#da1f1f';
      lightColor = '#ffcdd2';
      paddingSize = '0.2rem 1.5rem';
      break;
    case statusDocumento.EXPIRADO:
      texto = aplicarEstiloArquivado(row.arquivado, 'Expirado');
      strongColor = '#313131';
      lightColor = '#e6e6e6';
      paddingSize = '0.2rem 1.5rem';
      break;
    case statusDocumento.SEM_DOCUMENTO:
      texto = aplicarEstiloArquivado(row.arquivado, 'Sem documento');
      paddingSize = '0.2rem 0.9rem';
      break;
    default:
      texto = row.status ?? '';
  }
  return (
    <span
      style={{
        backgroundColor: lightColor,
        color: strongColor,
        fontWeight: 'bold',
        fontSize: '13px',
        borderRadius: '20px',
        display: 'flex',
        height: '1.5rem',
        width: '11rem',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: paddingSize,
      }}
    >
      <span style={{ width: '100%', textAlign: 'center' }}>{texto}</span>
    </span>
  );
}

export function renderDataCriacao(row) {
  return row.criadoEm
    ? aplicarEstiloArquivado(row.arquivado, format(parseISO(row.criadoEm, new Date()), 'dd/MM/yyyy'))
    : '';
}

export function aplicarEstiloArquivado(arquivado, field, isMobile) {
  if (arquivado) {
    return (
      <span
        style={{
          fontStyle: 'italic',
          opacity: '0.8',
          wordBreak: 'break-word',
          maxWidth: isMobile ? '70%' : '100%',
          textAlign: isMobile ? 'end' : 'start',
        }}
      >
        {field}
      </span>
    );
  }
  return (
    <span
      style={{
        wordBreak: 'break-word',
        maxWidth: isMobile ? '70%' : '100%',
        textAlign: isMobile ? 'end' : 'start',
      }}
    >
      {field}
    </span>
  );
}

export function montarRsqlPesquisaDocumentos(valorPesquisa, filtroAvancado, exibirArquivados) {
  filtroAvancado = filtroAvancado || '';
  const enumStatus = formatarParaPesquisarTiposEnumerados(valorPesquisa);

  let urlArquivados = ``;
  if (!exibirArquivados) {
    urlArquivados = `;arquivado==${false}`;
  }

  let urlFiltroAvancado = '';
  if (filtroAvancado) {
    urlFiltroAvancado += `;${filtroAvancado}`;
  }
  let result = `?query=(identificacao=contains="*${valorPesquisa}*",pessoaNome=contains="*${valorPesquisa}*",status=contains="*${enumStatus}*")${urlArquivados}${urlFiltroAvancado}`;

  return result;
}

export function buscarMensagemExcluirDocumento(documento) {
  let mensagem = 'Tem certeza que deseja excluir?';

  if (documento.status === statusDocumento.ASSINADO) {
    mensagem =
      `Após excluir o documento, ele só poderá ser consultado em <a href="https://documentos.docsdigitais.com/" target="_blank">documentos.docsdigitais.com</a> através do identificador <b>${documento.id}</b> <br/><br/>` +
      'Armazene este identificador em local seguro pois o processo de exclusão não poderá ser revertido. <br/><br/>' +
      'Deseja mesmo excluir?';
  }
  return mensagem;
}
