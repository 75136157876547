import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';

import { Modal, FormActions, FormContent, Grid, Fieldset } from 'components';
import { mensagensDeValidacao, copiarObjeto } from 'Common';

import { converterFilialToForm } from '../../Utils/filialConverter';

import ActionButtons from './components/ActionButtons';
import InformacoesPrincipais from './components/InformacoesPrincipais';
import InformacoesAdicionais from './components/InformacoesAdicionais';
import Endereco from './components/Endereco';
import { INITIAL_VALUES } from '../../Utils/constantes';

function ModalNovaFilialForm(props) {
	const {
		visible,
		onHide,
		setFieldValue,
		values,
		informacoesPermissoes,
		onSave,
		handleReset,
		dirty,
		filialSelecionada,
		setFilialSelecionada,
		handleSubmit,
		autoridadeOrganizacao,
	} = props;

	const headerModal = filialSelecionada ? 'Editar filial' : 'Cadastrar filial';

	return (
		<Modal visible={visible} onHide={() => onHide()} header={headerModal}>
			<Form>
				<FormActions>
					<ActionButtons
						setTouched={props.setTouched}
						handleSubmit={handleSubmit}
						handleReset={handleReset}
						onHide={onHide}
						onSave={onSave}
						dirty={dirty}
						filialSelecionada={filialSelecionada}
						setFilialSelecionada={setFilialSelecionada}
						informacoesPermissoes={informacoesPermissoes}
						{...props}
					/>
				</FormActions>
				<FormContent>
					<Fieldset className="fieldset">
						<Grid>
							<InformacoesPrincipais
								values={values}
								setFieldValue={setFieldValue}
								informacoesPermissoes={informacoesPermissoes}
							/>

							<InformacoesAdicionais
								values={values}
								setFieldValue={setFieldValue}
								informacoesPermissoes={informacoesPermissoes}
								autoridadeOrganizacao={autoridadeOrganizacao}
							/>
						</Grid>
					</Fieldset>
					<Fieldset legend="Endereço" className="fieldset">
						<Endereco values={values} setFieldValue={setFieldValue} informacoesPermissoes={informacoesPermissoes} />
					</Fieldset>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalNovaFilial = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.filialSelecionada) {
			return { ...converterFilialToForm(copiarObjeto(props.filialSelecionada)), idOrganizacao: props.idOrganizacao };
		} else {
			return { ...copiarObjeto(INITIAL_VALUES), codigo: props.getNovoCodigo(), idOrganizacao: props.idOrganizacao };
		}
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		razaoSocial: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		nomeApresentacao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		tipo: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		email: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		telefone: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		cep: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		logradouro: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		numero: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		bairro: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		pais: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		municipio: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	validate(values) {
		const errors = {};
		const regexNumeros = /^\d{8,9}$/;

		if (values.situacao === null) {
			errors.situacao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.regimeTributario === null) {
			errors.regimeTributario = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.suframa && !regexNumeros.test(values.suframa)) {
			errors.suframa = 'Mínimo de 8 e máximo de 9 dígitos';
		}

		if (values.tipo?.value === 'FISICA') {
			if (!values.cpf) {
				errors.cpf = mensagensDeValidacao.OBRIGATORIO;
			} else if (!isValidCPF(values.cpf)) {
				errors.cpf = 'CPF inválido';
			}
		}

		if (values.tipo?.value === 'JURIDICA') {
			if (!values.cnpj) {
				errors.cnpj = mensagensDeValidacao.OBRIGATORIO;
			} else if (!isValidCNPJ(values.cnpj)) {
				errors.cnpj = 'CNPJ inválido';
			}
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalNovaFilialForm);

export default ModalNovaFilial;
