import { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import propTypes from 'prop-types';
import Col from '../../../../Col';
import { renderizarValidacao } from '../../../../../Common/TratamentoDeErro/validacoesDeCampos';
import {
  buscarDisabledDeAcordoComAsPermissoes,
  buscarHiddenDeAcordoComAsPermissoes,
} from '../../../../../Common/Autorizacao/ManipulacaoDeComponentes';
import MenuList from '../../../components/FastList';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '32px !important',
    border: state.selectProps.errors && state.selectProps.touched ? '1px solid #ff0000' : '1px solid #ced4da',
    backgroundColor: '#fff',
    opacity: state.isDisabled ? '0.5' : '1',
    ...dot(state.selectProps.errors, state.selectProps.touched),
    ...state.selectProps.customStyles?.control,
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: 6,
  }),
  menu: style => ({
    ...style,
    marginTop: 0,
    marginBottom: 0,
  }),
  menuList: styles => ({
    ...styles,
    maxHeight: 200,
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    padding: '0px 8px',
    ...state.selectProps.customStyles?.valueContainer,
  }),
  multiValueLabel: (styles, state) => ({
    ...styles,
    wordWrap: 'break-word',
    textOverflow: 'ellipsis',
    margin: '0px 2px',
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    marginTop: '-3px',
  }),
};
const dot = (errors, touched) => ({
  ':hover': {
    border: errors && touched ? '1px solid #ff0000 !important' : '1px solid #2196f3 !important',
    cursor: 'pointer',
  },
});

export default function InternalDropdownMultiSelect(props) {
  const {
    sm,
    md,
    lg,
    xl,
    isClearable,
    errors,
    touched,
    podeInserir,
    podeEditar,
    estadoCadastro,
    disabled,
    placeholder,
    onChange,
    value,
    noOptionsMessage,
    podeVisualizar,
    hidden,
    colStyle,
    obrigatorio,
    label,
    options,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  function onInputChange(inputValue) {
    setInputValue(inputValue);
  }

  function filterOption(element) {
    return (element.label || '').toLowerCase().includes(inputValue.toLowerCase());
  }

  function getColStyle() {
    if (buscarHiddenDeAcordoComAsPermissoes(podeVisualizar, hidden)) return { display: 'none', ...colStyle };
    return colStyle;
  }

  function montarTitulo() {
    if (obrigatorio) {
      return (
        <label title={props.helpMessage}>
          {label} <b style={{ fontSize: '18px', lineHeight: '5px' }}> *</b>{' '}
        </label>
      );
    }
    return <label title={props.helpMessage}> {label} </label>;
  }

  const desabilitarSelect = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled);

  return (
    <Col sm={sm} md={md} lg={lg} xl={xl} style={getColStyle(props)}>
      {label && montarTitulo()}
      <Select
        id="select"
        isMulti
        isLoading={loading}
        styles={customStyles}
        inputValue={inputValue}
        isClearable={isClearable}
        options={options}
        closeMenuOnSelect={true}
        onInputChange={onInputChange}
        isDisabled={desabilitarSelect}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
        {...props}
        value={value}
        onChange={onChange}
        filterOption={filterOption}
        components={options.length > 0 ? { MenuList } : undefined}
      />
      {errors && renderizarValidacao(errors, touched)}
    </Col>
  );
}

InternalDropdownMultiSelect.defaultProps = {
  noOptionsMessage: () => 'Nenhum elemento encontrado',
  loadingMessage: () => 'Por favor, aguarde...',
  placeholder: 'Clique para selecionar',
  defaultOptions: false,
  cacheOptions: true,
  menuPlacement: 'auto',
  className: 'react-select-base',
  classNamePrefix: 'reactSelect',
  closeMenuOnSelect: false,
  disabled: false,
  podeVisualizar: true,
  isClearable: false,
};

InternalDropdownMultiSelect.propTypes = {
  /** Quando definido como true, os resultados de loadOptions() serão carregados automaticamente antes do usuário clicar para pesquisar.  */
  defaultOptions: propTypes.bool,

  /** Quando definido como true, os dados carregados serão armazenados em cache. O cache permanecerá até o valor cacheOptions sofrer alterações. */
  cacheOptions: propTypes.bool,

  /** Função executada quando o valor do input altera de estado. */
  onInputChange: propTypes.func,

  /** Função que retorna uma Promisse, que é o conjunto de opções a ser usado quando esta é resolvida. É executada assim que o componente é montado e a cada vez que o usuário filtrar a pesqusia. */
  loadOptions: propTypes.func,

  /** texto a ser exibido quando as opções estão sendo carregadas. */
  loadingMessage: propTypes.func,
  /** texto a ser exibido quando não tem nenhum item na lista */
  noOptionsMessage: propTypes.func,
};
