function converterModeloServicoParaFormulario(modeloApi) {
	return {
		id: modeloApi.id,
		nome: modeloApi.nome,
		marca: converterSelectParaFormulario(modeloApi.marca),
		situacao: modeloApi.situacao,
	};
}

function converterModeloServicoParaApi(modeloForm) {
	return {
		id: modeloForm.id,
		nome: modeloForm.nome,
		marca: { id: modeloForm.marca?.value },
		situacao: modeloForm.situacao,
	};
}

function converterParaDuplicar(modelo) {
	return {
		id: null,
		nome: modelo.nome,
		marca: modelo.marca,
		situacao: 'ATIVO',
	};
}

function converterSelectParaFormulario(selectApi) {
	if (selectApi) {
		return {
			label: selectApi.nome,
			value: selectApi.id,
			registro: selectApi,
		};
	}
	return null;
}

export { converterModeloServicoParaFormulario, converterModeloServicoParaApi, converterParaDuplicar };
