import { format } from 'date-fns';

export function currentMonth() {
	let date = new Date();
	let firstDay = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
	let lastDay = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
	let formatedDate = `criadoEm>=${firstDay};criadoEm<=${lastDay}`;

	return formatedDate;
}
