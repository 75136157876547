import { useRef } from 'react';
import propTypes from 'prop-types';
import Col from '../../../../Col';
import {
	buscarDisabledDeAcordoComAsPermissoes,
	buscarHiddenDeAcordoComAsPermissoes,
	buscarTitleCampoDeAcordoComAsPermissoes,
} from '../../../../../Common/Autorizacao/ManipulacaoDeComponentes';
import {
	renderizarValidacao,
	buscarClassNamePelosErros,
} from '../../../../../Common/TratamentoDeErro/validacoesDeCampos';
import NumberFormat from 'react-number-format';

export function InternalInputDouble(props) {
	const {
		id,
		name,
		title,
		placeholder,
		className,
		onBlur,
		onKeyDown,
		errors,
		touched,
		sm,
		md,
		lg,
		xl,
		podeVisualizar,
		podeInserir,
		podeEditar,
		estadoCadastro,
		colId,
		thousandSeparator,
		decimalScale,
		fixedDecimalScale,
		decimalSeparator,
		prefix,
		suffix,
		onMouseUp,
		col,
		autofocus,
		colClassName,
		allowNegative,
		children,
		label,
		shortcut,
		warnings,
		obrigatorio,
		labelSize,
		style,
		styleLabel,
	} = props;

	const shortcutStyle = {
		marginLeft: '4px',
		fontSize: 10,
	};

	let inputElem = useRef();

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden))
			return { display: 'none', ...props.colStyle };
		return props.colStyle;
	}

	function onChange(e) {
		let valor = e.floatValue;

		if (valor !== undefined && valor > props.maxValue) {
			valor = props.maxValue;
		} else if (valor === undefined) {
			valor = null;
		}

		props.onChange({
			target: {
				value: valor,
				name: props.name,
			},
		});
	}

	function montarLabel() {
		if (obrigatorio) {
			return (
				<span style={{ display: 'flex', alignItems: 'center' }}>
					<label style={{ fontSize: labelSize, ...styleLabel }} title={props.helpMessage}>
						{label}
						<b
							style={{
								fontSize: labelSize ? labelSize : '18px',
								lineHeight: '5px',
							}}
						>
							*
						</b>
					</label>
					{shortcut && <span style={{ ...shortcutStyle }}>{shortcut}</span>}
				</span>
			);
		}

		return (
			<label
				style={{ display: 'flex', alignItems: 'center', fontSize: labelSize, ...styleLabel }}
				title={props.helpMessage}
			>
				{label}
				{shortcut && <span style={{ ...shortcutStyle }}>{shortcut}</span>}
			</label>
		);
	}

	function onFocusable() {
		if (props.onFocus) {
			props.onFocus();
		} else {
			if (inputElem) {
				inputElem.current.select();
			}
		}
	}

	function getStyle() {
		return {
			borderRadius: '3px',
			height: style?.height ? style.height : '32px',
			...style,
		};
	}

	const disabled = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, props.disabled);
	const classNameDisabled = disabled ? 'p-disabled' : '';

	return (
		<Col id={colId} className={colClassName} col={col} sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			{label && montarLabel()}

			<div
				className="p-inputgroup p-fluid"
				title={buscarTitleCampoDeAcordoComAsPermissoes(podeVisualizar, podeInserir, podeEditar, estadoCadastro, title)}
			>
				<NumberFormat
					id={id}
					getInputRef={inputElem}
					name={name}
					displayType="input"
					value={props.value}
					className={buscarClassNamePelosErros(
						`p-inputtext ${classNameDisabled} ${className}`,
						errors,
						touched,
						warnings
					)}
					disabled={disabled}
					style={getStyle()}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					placeholder={placeholder}
					thousandSeparator={thousandSeparator}
					prefix={prefix}
					onMouseUp={onMouseUp}
					decimalScale={decimalScale}
					fixedDecimalScale={fixedDecimalScale}
					onValueChange={onChange}
					onFocus={onFocusable}
					decimalSeparator={decimalSeparator}
					suffix={suffix}
					autoFocus={autofocus}
					inputMode="decimal"
					allowNegative={allowNegative}
					isAllowed={(e) => (e.value ? e.value.length <= props.size : true)}
					autoComplete="off"
				/>
				{children}
			</div>
			{(errors || warnings) && renderizarValidacao(errors, touched, warnings)}
		</Col>
	);
}

InternalInputDouble.defaultProps = {
	value: '',
	onChange: () => {},
	size: 99999,
	className: '',
	decimalScale: 2,
	fixedDecimalScale: true,
	thousandSeparator: '.',
	decimalSeparator: ',',
};

InternalInputDouble.propTypes = {
	/** Evento disparado ao modificar o componente do componente */
	onChange: propTypes.func,
	/** Label do componente */
	label: propTypes.string,
	/** Identificador do componente */
	id: propTypes.string,
	/** Placeholder do componente */
	placeholder: propTypes.string,
	/** Valor do componente do componente */
	value: propTypes.any,
	/** Nome da classe do componente */
	className: propTypes.string,
	/** Define se o componente está desabilitado*/
	disabled: propTypes.bool,
	/** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
	errors: propTypes.oneOfType([propTypes.string, propTypes.bool]),
	warnings: propTypes.any,
	/** Especifica se o componente foi 'tocado'*/
	touched: propTypes.bool,
	/** Evento executado ao sair do campo*/
	onBlur: propTypes.any,
	/** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
	col: propTypes.string,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
	/** Estilo da coluna*/
	colStyle: propTypes.object,
	/** Estado em que o cadastro se encontra*/
	estadoCadastro: propTypes.string,
	/** Diz se o usuário possui permissão de visualizar*/
	podeVisualizar: propTypes.bool,
	/** Diz se o usuário possui permissão de editar*/
	podeEditar: propTypes.bool,
	/** Diz se o usuário possui permissão de excluir*/
	podeInserir: propTypes.bool,
	/** Title do componente*/
	title: propTypes.string,
	/** Id da coluna*/
	colId: propTypes.string,
	/** Esconde o componente*/
	hidden: propTypes.bool,
	/** Tamanho máximo do texto do componente*/
	size: propTypes.number,
	/** Separador de milhares */
	thousandSeparator: propTypes.string,
	/** Prefixo: Ex: R$ */
	prefix: propTypes.string,
	/** Sufixo: Ex: % */
	suffix: propTypes.string,
	/** quantos dígitos decimais deverão ser digitados */
	decimalScale: propTypes.number,
	/** Sempre mostrar os decimais */
	fixedDecimalScale: propTypes.bool,
	/** Separador de dígitos decimais */
	decimalSeparator: propTypes.string,
	/** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
	helpMessage: propTypes.string,
	/** Define os filhos do componente */
	children: propTypes.any,
	allowNegative: propTypes.bool,
	/**Valor máximo para o input */
	maxValue: propTypes.number,
};
