import { useEffect, useState } from 'react';
import { TabPanel } from 'primereact/tabview';

import { usuarioPossuiModulo, modulos } from 'Common';

import { If, TabView } from 'components';

import FieldsDocumentos from './FieldsDocumentos';
import FieldsEstoque from './FieldsEstoque';
import FieldsInformacoesComplementares from './FieldsInformacoesComplementares';
import FieldsNotaFiscal from './FieldsNotaFiscal';
import FieldsAdicionais from './FieldsAdicionais';
import FieldsServicos from './FieldsServicos';
import FieldsCustos from './FieldsCustos';
import FieldsPrecos from './FieldsPrecos';
import FieldsEstoqueFiliais from './FieldsEstoqueFiliais';

export default function TabsOptions(props) {
	const { values, style, activeIndex, setActiveIndex, isGestor } = props;

	const { tipo, filiais, controlarEstoque } = values;

	const tipoForServico = tipo === 'SERVICO';

	const possuiModuloEstoque = usuarioPossuiModulo(modulos.ESTOQUE);
	const possuiModuloVendas = usuarioPossuiModulo(modulos.VENDAS);
	const possuiModuloDocumentosDigitais = usuarioPossuiModulo(modulos.DOCUMENTOS);

	useEffect(() => {
		const tabs = {
			NOTA_FISCAL: 0,
			ESTOQUE: 2,
			FISCAL: 1,
			DOCUMENTOS: 5,
			INFORMACOES: 6,
			ADICIONAIS: 7,
			CUSTOS: 3,
			PRECOS: 4,
		};

		function getIndexTable() {
			let tabelaInicial;
			if (tipoForServico) {
				tabelaInicial = tabs.FISCAL;
			} else if (possuiModuloVendas) {
				tabelaInicial = tabs.NOTA_FISCAL;
			} else if (possuiModuloEstoque) {
				tabelaInicial = tabs.ESTOQUE;
			} else {
				tabelaInicial = tabs.DOCUMENTOS;
			}

			return tabelaInicial;
		}

		setActiveIndex(getIndexTable());
	}, [tipoForServico, possuiModuloEstoque, possuiModuloVendas, possuiModuloDocumentosDigitais]);

	function tabChange(event) {
		setActiveIndex(event.index);
		setTimeout(() => {
			switch (event.index) {
				case 0:
					document.getElementById('ProdutoDropdownOrigemProduto')?.getElementsByTagName('input')[0]?.focus();
					break;
				case 1:
					document.getElementById('ProdutoInputFatorConversao')?.focus();
					break;
				case 3:
					document.getElementById('ProdutoTextAreaObservacao')?.focus();
					break;
				default:
					break;
			}
		}, 500);
	}

	return (
		<div style={{ marginLeft: '-0.5rem', marginRight: '-0.5rem' }}>
			<TabView
				className="tab-view step-produto-tabs"
				activeIndex={activeIndex}
				onTabChange={(event) => tabChange(event)}
				style={style}
			>
				<TabPanel
					header="Fiscal"
					headerStyle={!possuiModuloVendas || tipoForServico ? { display: 'none' } : null}
					style={style}
				>
					<FieldsNotaFiscal {...props} style={style} />
				</TabPanel>
				<TabPanel header="Fiscal" headerStyle={tipoForServico ? null : { display: 'none' }} style={style}>
					<FieldsServicos {...props} style={style} />
				</TabPanel>
				<TabPanel header="Estoque" headerStyle={!possuiModuloEstoque || tipoForServico ? { display: 'none' } : null}>
					<If test={filiais.length === 1}>
						<FieldsEstoque {...props} />
					</If>
					<If test={filiais.length > 1}>
						<FieldsEstoqueFiliais {...props} />
					</If>
				</TabPanel>
				<TabPanel
					header="Custos"
					headerStyle={!possuiModuloEstoque || tipoForServico || !controlarEstoque ? { display: 'none' } : null}
				>
					<FieldsCustos {...props} />
				</TabPanel>
				<TabPanel
					header="Preços"
					headerStyle={!possuiModuloEstoque || filiais.length === 1 ? { display: 'none' } : null}
				>
					<FieldsPrecos {...props} />
				</TabPanel>
				<TabPanel header="Adicionais" headerStyle={tipoForServico ? { display: 'none' } : null}>
					<FieldsAdicionais {...props} isGestor={isGestor} />
				</TabPanel>
				<TabPanel header="Observações">
					<FieldsInformacoesComplementares {...props} />
				</TabPanel>
				<TabPanel
					header="Configurações"
					headerStyle={!possuiModuloDocumentosDigitais && !possuiModuloVendas ? { display: 'none' } : null}
				>
					<FieldsDocumentos
						possuiModuloVendas={possuiModuloVendas}
						possuiModuloDocumentosDigitais={possuiModuloDocumentosDigitais}
						{...props}
					/>
				</TabPanel>
			</TabView>
		</div>
	);
}
