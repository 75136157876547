import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';

import { Col, Divider, If, Message, TabView } from 'components';
import { useContextOrdemServico } from '../../../Context';

import { CLASSNAME_TABVIEW_COLOR, TAB_ITENS } from '../../../Util/constantes';
import { TabProdutos } from './components/TabProdutos';
import { TabServicos } from './components/TabServicos';
import {
	buscarPercentualAdicionalCondicaoPagamento,
	buscarPercentualAdicionalQuantidadeServico,
} from '../../../Util/funtions';

export function TabItens({ isFirstRender, tutorialVisible, buscarMunicipioIdPessoa }) {
	const { tabItensIndex, setTabItensIndex, setAtualizarTotais } = useContextOrdemServico();
	const { values, setFieldValue, errors } = useFormikContext();
	const [showMessageError, setShowMessageError] = useState(false);

	const showMessage = !values.cliente?.registro || !values.operacaoFiscal?.registro;
	const municipioId = buscarMunicipioIdPessoa(values.cliente);

	useEffect(() => {
		if (errors.servicos?.length > 0 || errors.produtos?.length > 0) {
			setShowMessageError(true);
		} else {
			setShowMessageError(false);
		}
	}, [errors.servicos, errors.produtos]);

	function createTextMessage() {
		let camposVaziosMessage = '';
		if (!values.cliente) {
			camposVaziosMessage = 'cliente';
		}

		if (!values.operacaoFiscal) {
			camposVaziosMessage !== '' ? (camposVaziosMessage += ' e ') : null;
			camposVaziosMessage += 'operação fiscal';
		}

		if (camposVaziosMessage.split(' ').length <= 2) {
			return (
				<span>
					{`O campo `}
					<b>{`${camposVaziosMessage}`}</b>
					{` deve ser preenchido antes de adicionar um ${
						tabItensIndex === TAB_ITENS.SERVICOS ? 'serviço' : 'produto'
					}.`}
				</span>
			);
		} else {
			return (
				<span>
					{`Os campos `}
					<b>{`${camposVaziosMessage}`}</b>
					{` devem ser preenchidos antes de adicionar um ${
						tabItensIndex === TAB_ITENS.SERVICOS ? 'serviço' : 'produto'
					}.`}
				</span>
			);
		}
	}

	function createTextMessageError() {
		let message = 'Existem erros ou validações a serem corrigidas';

		if (errors.servicos?.length > 0 && errors.produtos?.length > 0) {
			message += ' nos serviços e nos produtos';
		} else if (errors.servicos?.length > 0) {
			message += ' nos serviços';
		} else if (errors.produtos?.length > 0) {
			message += ' nos produtos';
		}

		return <span>{message}</span>;
	}

	function renderMessage() {
		return (
			<>
				<If test={!isFirstRender && showMessage && !tutorialVisible}>
					<Message severity="warn" text={createTextMessage()} />
				</If>
				<If test={!isFirstRender && showMessageError && !tutorialVisible}>
					<Message severity="error" text={createTextMessageError()} />
				</If>
			</>
		);
	}

	function onChangeServicos(servicos) {
		setFieldValue('servicos', servicos);
		setAtualizarTotais(true);
	}

	function onChangeServico(index, value) {
		setFieldValue(`servicos[${index}]`, value);
		setAtualizarTotais(true);
	}

	function onChangeProdutos(produtos) {
		setFieldValue('produtos', produtos);
		setAtualizarTotais(true);
	}

	function onChangeProduto(index, value) {
		setFieldValue(`produtos[${index}]`, value);
		setAtualizarTotais(true);
	}

	async function calcularPercentualAdicionalTabelaPreco(quantidade) {
		const Promises = [
			buscarPercentualAdicionalCondicaoPagamento(
				values.pagamentos,
				values.informacoesComplementares?.tabelaPreco?.registro?.alteracoesPorCondicao
			),
			buscarPercentualAdicionalQuantidadeServico(
				quantidade,
				values.informacoesComplementares?.tabelaPreco?.registro?.descontosPorQuantidade
			),
		];

		return Promise.all(Promises).then((dados) => {
			const percentualAplicado = dados.reduce((percentual, dado) => {
				if (dado?.tipo === 'ACRESCIMO') {
					return percentual + (dado.percentual || 0);
				} else if (dado?.tipo === 'DESCONTO') {
					return percentual - (dado.percentual || 0);
				}

				return percentual;
			}, 0);

			return { percentual: Math.abs(percentualAplicado), tipo: percentualAplicado > 0 ? 'ACRESCIMO' : 'DESCONTO' };
		});
	}

	return (
		<Col style={{ paddingTop: '0px', paddingBottom: '0px' }}>
			<TabView
				className={`step-ordem-servico-produtos-servicos tab-view-no-border ${
					CLASSNAME_TABVIEW_COLOR[values.situacao].class
				}`}
				activeIndex={tabItensIndex}
				onTabChange={(event) => setTabItensIndex(event.index)}
			>
				<TabPanel header="Serviços">
					{renderMessage()}
					<TabServicos
						municipioId={municipioId}
						onChangeServico={onChangeServico}
						onChangeServicos={onChangeServicos}
						calcularPercentualAdicionalTabelaPreco={calcularPercentualAdicionalTabelaPreco}
					/>
				</TabPanel>
				<TabPanel header="Produtos">
					{renderMessage()}
					<TabProdutos
						onChangeProduto={onChangeProduto}
						onChangeProdutos={onChangeProdutos}
						calcularPercentualAdicionalTabelaPreco={calcularPercentualAdicionalTabelaPreco}
					/>
				</TabPanel>
			</TabView>
			<Divider styleContainer={{ margin: '8px 0px' }} />
		</Col>
	);
}
