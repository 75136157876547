import { Field, useFormikContext, withFormik } from 'formik';

import { keyFilterConsultaRsql, mensagensDeValidacao } from 'Common';
import {
	ButtonCancelar,
	ButtonSalvar,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	InputMask,
	Modal,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
} from 'components';

function ModalCondutoresImpl({ visible, onClose, informacoesPermissoes, onSave }) {
	const { values, dirty, handleReset, setFieldValue, isValid } = useFormikContext();
	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;

	function cancelar() {
		dirty ? handleReset() : onClose();
	}

	function confirmar() {
		if (isValid) {
			onSave(values);
			onClose();
		}
	}

	return (
		<Modal visible={visible} onHide={onClose} header="Condutores">
			<Form>
				<FormActions>
					<ButtonCancelar estadoBotao={estadoBotaoCancelar} onClick={() => cancelar()} />
					<ButtonSalvar
						onClick={confirmar}
						estadoBotao={estadosBotaoSalvar.CONFIRMAR}
						hidden={!informacoesPermissoes?.podeEditar}
						disabled={!dirty}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							component={InputField}
							label="Nome"
							obrigatorio
							keyfilter={keyFilterConsultaRsql}
							name="nome"
							size={60}
							id="condutor-input-nome"
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							component={InputMask}
							obrigatorio
							mask="000.000.000-00"
							placeholder="   .   .   -  "
							label="CPF "
							name="cpf"
							value={values.cpf}
							onChange={(e) => setFieldValue('cpf', e.target.value)}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalCondutores = withFormik({
	enableReinitialize: true,
	validateOnChange: false,

	mapPropsToValues({ initialValue }) {
		return initialValue;
	},

	validate: (values) => {
		const errors = {};

		if (!values.cpf) {
			errors.cpf = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.nome) {
			errors.nome = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalCondutoresImpl);

export default ModalCondutores;
