import { useEffect, useRef } from 'react';
import { useDebounce } from 'react-use';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { removerCaracteresInvalidosRsql } from 'Common';
import { Grid, NenhumRegistroEncontrado, confirm } from 'components';
import { InputFieldButton } from './components/InputFieldButton';
import { TIPO_CONSULTA } from '../../Util/constantes';
import { converterProdutosToList } from '../../Util/converterProdutos';
import { useContextEtiqueta } from '../../Context';
import { asyncGetNotasEntrada, asyncGetProdutos, asyncGetProdutosByNotasEntrada } from '../../Requests';

function InputProdutoNotaEntradaImpl({ isMobile }) {
	const {
		tipoConsultaSelected,
		optionsInput,
		setOptionsInput,
		valueInput,
		setValueInput,
		setRegistros,
		notasImportadas,
		setNotasImportadas,
		podeGerar,
	} = useContextEtiqueta();

	const panelList = useRef(null);

	const isNotaEntrada = tipoConsultaSelected === TIPO_CONSULTA.NOTA_ENTRADA;

	const isFirstRender = useRef(false);

	useDebounce(fetchOptionsInput, 500, [valueInput]);

	useEffect(() => {
		fetchOptionsInput();
		setFocusInput();
	}, [tipoConsultaSelected]);

	async function fetchOptionsInput() {
		if (podeGerar) {
			if (isNotaEntrada) {
				await asyncGetNotasEntrada(removerCaracteresInvalidosRsql(valueInput), ({ data: notas }) => {
					if (notas.content.length === 1 && !!valueInput) {
						addOrUpdateProdutosByNotaEntrada(notas.content[0]);
					} else if (notas.content.length > 0 && !!valueInput) {
						setOptionsInput(notas.content);
						openPanelList();
					} else {
						setOptionsInput(notas.content);
					}
				});
			} else {
				if (isFirstRender.current) {
					await asyncGetProdutos(removerCaracteresInvalidosRsql(valueInput), ({ data: produtos }) => {
						setOptionsInput(converterProdutosToList(produtos.content));
						if (produtos.content.length === 1 && !!valueInput) {
							addOrUpdateProduto(converterProdutosToList(produtos.content)[0]);
						} else if (produtos.content.length > 0 && !!valueInput) {
							openPanelList();
						}
					});
				}
				isFirstRender.current = true;
			}
		}
	}

	function renderProdutoField(row) {
		return (
			<span style={{ paddingLeft: isMobile ? '20%' : '0' }}>
				{`${row.codigo} - ${row.nome}${row.sku ? ` [Ref: ${row.sku}]` : ''} `}
			</span>
		);
	}

	function renderNotaEntradaField(row) {
		return <span style={{ paddingLeft: isMobile ? '20%' : '0' }}>{`${row.numero}/${row.serie} - ${row.nome} `}</span>;
	}

	function openPanelList() {
		panelList.current.show({
			target: document.getElementById('input-produto-nota-entrada'),
		});
		setTimeout(() => {
			document
				.getElementById('id-overlay-panel-produto')
				?.getElementsByTagName('tbody')[0]
				?.getElementsByTagName('tr')[0]
				?.focus();
		}, 200);
	}

	function closePanelList() {
		panelList.current.hide();
		setFocusInput();
	}

	function handleChangeInput(event) {
		setValueInput(event.target.value);
	}

	function handleClickClearInput() {
		setValueInput('');
		closePanelList();
	}

	function onClickSearch() {
		fetchOptionsInput();
		openPanelList();
	}

	function onKeyDownInput(event) {
		if (event.key === 'Enter') {
			fetchOptionsInput();
			if ((!!valueInput && optionsInput.length === 0) || !valueInput) {
				openPanelList();
			}
		}
		if (event.key === 'Escape') {
			closePanelList();
		}
	}

	function setFocusInput() {
		setTimeout(() => {
			document.getElementById('input-produto-nota-entrada')?.focus();
		}, 200);
	}

	function addOrUpdateProduto(produto) {
		setRegistros((state) => addOrUpdateProdutoDaLista(state, produto));
		setValueInput('');
		closePanelList();
	}

	async function addOrUpdateProdutosByNotaEntrada(nota) {
		const notaJaImportada = !!notasImportadas.filter((e) => e.id === nota.id).length;

		async function getProdutos() {
			await asyncGetProdutosByNotasEntrada(nota.id, ({ data: produtos }) => {
				setRegistros((state) => {
					const newState = addOrUpdateProdutosDaLista(
						state,
						linkNotaComProduto(converterProdutosToList(produtos), state, nota.id)
					);
					return newState;
				});
				setValueInput('');
				closePanelList();
			});
		}

		if (notaJaImportada) {
			confirm('Confirmação', 'Nota de entrada já importada. Deseja continuar?', getProdutos, () => {}, 'Sim', 'Não');
		} else {
			getProdutos();
			setNotasImportadas((state) => [...state, nota]);
		}
	}

	function addOrUpdateProdutoDaLista(list, novoProduto) {
		const newList = list;
		const produtoExistenteIndex = list.findIndex((produto) => produto.id === novoProduto.id);

		if (produtoExistenteIndex !== -1) {
			newList[produtoExistenteIndex] = {
				...list[produtoExistenteIndex],
				quantidade: list[produtoExistenteIndex].quantidade + novoProduto.quantidade,
			};
			return [...newList];
		} else {
			return [...newList, novoProduto];
		}
	}

	function addOrUpdateProdutosDaLista(list, novosProdutos) {
		let updatedList = [...list];

		for (const novoProduto of novosProdutos) {
			updatedList = addOrUpdateProdutoDaLista(updatedList, novoProduto);
		}

		return updatedList;
	}

	function linkNotaComProduto(produtosNota, produtosList, notaId) {
		return produtosNota.map((produto) => {
			const oldProduto = produtosList.find((e) => e.id === produto.id);
			if (!!oldProduto && !!oldProduto.notas.length) {
				const existsNota = !!oldProduto.notas.find((e) => e === notaId);
				if (existsNota) {
					return produto;
				} else {
					return { ...produto, notas: oldProduto.notas.push(notaId) };
				}
			}
			return { ...produto, notas: [notaId] };
		});
	}

	function handleSelectRow(event) {
		if (isNotaEntrada) {
			addOrUpdateProdutosByNotaEntrada(event.data);
		} else {
			addOrUpdateProduto(event.data);
		}
	}

	return (
		<>
			<OverlayPanel
				style={{
					right: `${isMobile ? '12px' : null}`,
					width: document.getElementById('input-produto-nota-entrada')?.offsetWidth,
					margin: '0px 8px',
					marginTop: '0px',
				}}
				ref={panelList}
			>
				<Grid>
					<DataTable
						id="id-overlay-panel-produto"
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						selectionMode="single"
						responsive
						value={optionsInput}
						onRowSelect={handleSelectRow}
						style={{
							maxWidth: '100%',
							width: '100%',
							maxHeight: '12rem',
							overflowY: optionsInput?.length > 3 ? 'scroll' : 'hidden',
							overflowX: 'hidden',
						}}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						{isNotaEntrada ? (
							<Column
								field="notaEntrada"
								header="Nota de entrada"
								name="notaEntrada"
								body={renderNotaEntradaField}
								style={{
									textOverflow: 'ellipsis',
									overflow: 'hidden',
								}}
							/>
						) : (
							<Column
								field="produto"
								header="Produto"
								name="produto"
								body={renderProdutoField}
								style={{
									textOverflow: 'ellipsis',
									overflow: 'hidden',
								}}
							/>
						)}
					</DataTable>
				</Grid>
			</OverlayPanel>
			<Grid style={{ width: isMobile ? '100%' : '35rem', margin: '0 8px' }}>
				<InputFieldButton
					id="input-produto-nota-entrada"
					value={valueInput}
					placeholder="Digite para pesquisar"
					onChange={handleChangeInput}
					onClickSearch={onClickSearch}
					onKeyDown={onKeyDownInput}
					onClickClearInput={handleClickClearInput}
					disabled={!podeGerar}
					title={!podeGerar ? 'Usuário sem permissão para gerar etiquetas' : 'Consulta de produtos ou notas de entrada'}
				/>
			</Grid>
		</>
	);
}

export const InputProdutoNotaEntrada = InputProdutoNotaEntradaImpl;
