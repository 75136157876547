import { Button, If } from 'components';
import { useState } from 'react';
import { ModalAjudaTabelaPreco } from './components/ModalAjudaTabelaPreco';

function HeaderForm({ title }) {
	const [exibirModalAjudaTabelaPreco, setExibirModalAjudaTabelaPreco] = useState(false);

	return (
		<>
			<span
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				{title}
				<div style={{ position: 'relative', width: '28px' }}>
					<Button
						type="button"
						color="info"
						icon="fa fa-question"
						title="Ajuda"
						raised
						rounded
						style={{
							position: 'absolute',
							zIndex: '1',
							width: '28px',
							height: '28px',
							right: '0rem',
							top: '-0.88rem',
						}}
						onClick={() => setExibirModalAjudaTabelaPreco(true)}
					/>
				</div>
			</span>
			<If test={exibirModalAjudaTabelaPreco}>
				<ModalAjudaTabelaPreco
					visible={exibirModalAjudaTabelaPreco}
					onHide={() => setExibirModalAjudaTabelaPreco(false)}
				/>
			</If>
		</>
	);
}

export { HeaderForm };
