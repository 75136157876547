import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';
import { Grid, TabView } from 'components';
import { useContextMDFe } from 'views/transporte/MDFe/Context';

import { InformacoesComplementares } from './components/InformacoesComplementares';
import { Totalizadores } from './components/Totalizadores';
import { ClassNameTabViewColor } from '../../Util/constantes';

function TabsComplementares() {
	const { values } = useFormikContext();
	const { activeTabComplementares, setActiveTabComplementares, informacoesPermissoes } = useContextMDFe();

	return (
		<Grid style={{ margin: 0, padding: 0 }}>
			<TabView
				activeIndex={activeTabComplementares}
				onTabChange={(event) => {
					setActiveTabComplementares(event.index);
				}}
				{...informacoesPermissoes}
				className={`tab-view-no-border ${ClassNameTabViewColor[values.situacao]}`}
			>
				<TabPanel header="Totalizadores">
					<Totalizadores />
				</TabPanel>
				<TabPanel header="Informações complementares">
					<InformacoesComplementares />
				</TabPanel>
			</TabView>
		</Grid>
	);
}

export { TabsComplementares };
