import PropTypes from 'prop-types';

function FormActions(props) {
	return (
		<div {...props} className={`${props.className} form-actions`}>
			{props?.children}
		</div>
	);
}

export default FormActions;
