import { get, services } from 'Common';

async function getEstoqueAtualProduto(produtoId, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/produtos/${produtoId}/buscar_estoque_atual`,
		null,
		onSuccess,
		onError,
		false,
		false
	);
}

export { getEstoqueAtualProduto };
