import { tipoCampos } from "components/PesquisaAvancada/Util/constantes";

export const ColorsTributacaoMunicipal = {
  bgAtivo: "#DCEDC8",
  textAtivo: "#1B5E20",
  bgInativo: "#FFCDD2",
  textInativo: "#B71C1C",
};

export const OptionsFiltroAvancado = [
  { label: "Código", name: "codigo", type: tipoCampos.INTEGER },
  { label: "Nome", name: "nome", type: tipoCampos.STRING },
  {
    label: "Situação",
    name: "situacao",
    type: tipoCampos.SELECT,
    optionSelect: [
      { label: "Ativo", value: "ATIVO" },
      { label: "Inativo", value: "INATIVO" },
    ],
  },
];

export const SituacaoOptions = [
  { label: "Ativo", value: "ATIVO" },
  { label: "Inativo", value: "INATIVO" },
];

export const TypesHeaderModalTributacaoMunicipal = {
  INCLUIR: "Incluir tributação",
  EDITAR: "Editar tributação",
};
