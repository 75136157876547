export function possuiPermissoesDePapeis(permissoes) {
    const propNames = Object.getOwnPropertyNames(permissoes);
    const papeis = propNames.filter(el => {
        return el.split('|').includes('PAPEIS');
    })
    return papeis.length === 5;
}

export function permissoesForamMarcadas(permissoes){
    let validouPermissoes = false
    if (permissoes) {
        for (const prop in permissoes) {
            if (permissoes.hasOwnProperty(prop)) {
                const permissao = permissoes[prop];
                if (permissao.checked) {
                    validouPermissoes = true
                    break
                }
            }
        }
    }
    return validouPermissoes
}