import { useState } from 'react';
import { Field } from 'formik';
import {
	Grid,
	If,
	Col,
	Divider,
	Dropdown,
	InputField,
	InputDouble,
	SingleSelectPessoa,
	SingleSelectEstado,
	LinkVerDados,
} from '../../../..';
import { TipoPessoa } from '../../../../../views/cadastros/Pessoas/Util/constantes';
import ModalDadosTransportador from '../ModalDadosTransportador';
import { optionsModalidadeFrete } from '../../../../../views/fiscal/Util/constantes';

function Transporte(props) {
	const { setFieldValue, transporte, informacoesPermissoes } = props;
	const [modalTransportadorVisible, setModalTransportadorVisible] = useState(false);

	function onChangeModalidadeFrete(value) {
		setFieldValue('transporte.modalidadeFrete', value);

		if (value === 'SEM_FRETE') {
			setFieldValue('transporte.transportador', null);
			setFieldValue('transporte.veiculo.placa', null);
			setFieldValue('transporte.veiculo.uf', null);
			setFieldValue('transporte.veiculo.RNTC', null);
		}
	}

	async function onChangeTransportador(value) {
		setFieldValue('transporte.transportador', value);
		setFieldValue('transporte.transportador.endereco', await montarEndereco(value?.registro));
	}

	async function montarEndereco(values) {
		let enderecoTransporte = {};
		let enderecoConcatenado = '';
		await values.enderecos?.forEach(async endereco => {
			if (endereco.favorito === true) {
				enderecoTransporte = endereco;
			}
		});
		if (!enderecoTransporte) {
			enderecoTransporte = values.enderecos[0];
		}

		if (enderecoTransporte.logradouro) {
			enderecoConcatenado = enderecoTransporte.logradouro;
		}
		if (enderecoTransporte.numero) {
			enderecoConcatenado = `${enderecoConcatenado}, ${enderecoTransporte.numero}`;
		}
		if (enderecoTransporte.bairro) {
			enderecoConcatenado = `${enderecoConcatenado}. ${enderecoTransporte.bairro}`;
		}

		return {
			...enderecoTransporte,
			enderecoString: enderecoTransporte.enderecoString ?? enderecoConcatenado.substring(0, 60),
			municipio: {
				value: enderecoTransporte.municipio.id,
				registro: enderecoTransporte.municipio,
				label: `${enderecoTransporte.municipio.nome} - ${enderecoTransporte.municipio.estadoSigla}`,
			},
		};
	}

	return (
		<>
			<Grid>
				<Field
					sm="12"
					md="6"
					lg="3"
					xl="3"
					component={Dropdown}
					obrigatorio
					label="Responsável pelo frete"
					name="modalidadeFrete"
					onChange={e => onChangeModalidadeFrete(e.value)}
					options={optionsModalidadeFrete}
					value={transporte?.modalidadeFrete}
					showClear={false}
					{...informacoesPermissoes}
				/>
				<If test={transporte?.modalidadeFrete && transporte.modalidadeFrete !== 'SEM_FRETE'}>
					<Col sm="12" md="6" lg="4" xl="4">
						<Field
							component={SingleSelectPessoa}
							colStyle={{ padding: '0px' }}
							label="Transportador"
							name="transportador"
							tipoPessoa={TipoPessoa.TRANSPORTADOR}
							value={transporte?.transportador}
							onChange={value => onChangeTransportador(value)}
							{...informacoesPermissoes}
						/>
						<If test={transporte?.transportador}>
							<LinkVerDados
								label="Ver dados do transportador"
								onClick={() => setModalTransportadorVisible(true)}
							/>
						</If>
					</Col>
					<Field
						sm="6"
						md="6"
						lg="2"
						xl="2"
						component={InputField}
						size={7}
						label="Placa do veículo"
						name="placa"
						value={transporte?.veiculo.placa}
						onChange={e => setFieldValue('transporte.veiculo.placa', e.target.value)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="6"
						lg="3"
						xl="3"
						component={SingleSelectEstado}
						label="UF do veículo"
						onChange={value => setFieldValue('transporte.veiculo.uf', value)}
						name="UF"
						value={transporte?.veiculo.uf}
						{...informacoesPermissoes}
					/>
				</If>
			</Grid>
			<Divider
				label="Volumes"
				styleContainer={{ margin: '1rem 0rem 1rem 0rem' }}
				styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
			/>
			<Grid>
				<Field
					sm="6"
					md="4"
					lg="4"
					xl="4"
					component={InputDouble}
					decimalScale={0}
					size={15}
					label="Quantidade"
					name="quantidade"
					value={transporte?.volumes?.quantidade}
					onChange={e => setFieldValue('transporte.volumes.quantidade', e.target.value)}
					{...informacoesPermissoes}
				/>
				<Field
					sm="6"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					size={60}
					label="Tipo"
					name="tipo"
					value={transporte?.volumes?.tipo}
					onChange={e => setFieldValue('transporte.volumes.tipo', e.target.value)}
					{...informacoesPermissoes}
				/>
				<Field
					sm="6"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					size={60}
					label="Marca"
					name="marca"
					value={transporte?.volumes?.marca}
					onChange={e => setFieldValue('transporte.volumes.marca', e.target.value)}
					{...informacoesPermissoes}
				/>
				<Field
					sm="6"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					size={60}
					label="Numeração"
					name="numeracao"
					value={transporte?.volumes?.numeracao}
					onChange={e => setFieldValue('transporte.volumes.numeracao', e.target.value)}
					{...informacoesPermissoes}
				/>
				<Field
					sm="6"
					md="4"
					lg="4"
					xl="4"
					component={InputDouble}
					decimalScale={3}
					size={15}
					label="Peso bruto total"
					name="pesoBruto"
					value={transporte?.volumes?.pesoBruto}
					onChange={e => setFieldValue('transporte.volumes.pesoBruto', e.target.value)}
					{...informacoesPermissoes}
				/>
				<Field
					sm="6"
					md="4"
					lg="4"
					xl="4"
					component={InputDouble}
					decimalScale={3}
					size={15}
					label="Peso líquido total"
					name="pesoLiquido"
					value={transporte?.volumes?.pesoLiquido}
					onChange={e => setFieldValue('transporte.volumes.pesoLiquido', e.target.value)}
					{...informacoesPermissoes}
				/>
			</Grid>
			<If test={modalTransportadorVisible}>
				<ModalDadosTransportador
					visible={modalTransportadorVisible}
					transporte={transporte}
					onHide={() => setModalTransportadorVisible(false)}
					setFieldValues={setFieldValue}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</If>
		</>
	);
}

export default Transporte;
