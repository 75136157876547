import React from 'react';

import { Step } from "rc-steps";

export const renderizarEtapas = (etapas) => {
    return etapas.map((el, idx) => {
        return (
            <Step
                key={idx}
                title={getTitle(el)}
                description={el.description || ""}
                icon={el.icon || ""}
                status={el.status}
                tailContent={el.tailContent || ""}
            />
        )
    })
};

export const Icon = ({ name }) => {
    return <i className={`fa fa-${name}`} />;
}

function getTitle(etapa) {
    if (etapa.status === "error") {
        return etapa.errorTitle;
    }
    return etapa.name || "";

}