import { copiarObjeto, formatarMonetario, parseFloatNumber, services } from 'Common';
import {
	ButtonAdicionarItem,
	ButtonExcluirTable,
	Col,
	Grid,
	If,
	InputMoney,
	NormalButton,
	SingleSelectCategoria,
	SingleSelectCondicaoPagamento,
	SingleSelectConta,
	SingleSelectFormaPagamento,
	useGenerateParcelas,
} from 'components';
import ButtonDetalhesTable from 'components/Button/ButtonDetalhesTable';
import { ModalDetalhesPix } from 'components/ModalDetalhesPix';
import { Field, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useContextNFCe } from '../../../../../Context';
import {
	buscarCategoriaFavoritaReceita,
	buscarCondicaoPagamentoFavorita,
	buscarContaFavorita,
	buscarFormaPagamentoFavorita,
} from '../../../../../Requests';
import { converterValueParaSelect } from '../../../../../Util/NFCeConverter';
import { CONDICAO_PAGAMENTO_TIPO, Condicoes, INITIAL_VALUES_PAGAMENTOS_NFCE } from '../../../../../Util/constantes';
import { defineColorStatus } from '../../../../../Util/function';
import ModalParcelas from '../ModalParcelas';

function FormaPagamento(props) {
	const {
		isMobile,
		isTablet,
		atualizarPagamentos,
		setAtualizarPagamentos,
		resetFormPrincipal,
		isFullScreen,
		davImportado,
	} = props;

	const { values, errors, setFieldValue } = useFormikContext();

	const {
		modalParcelasPrazo,
		setModalParcelasPrazo,
		informacoesPermissoes,
		podeInserir,
		modalDetalhesPixVisible,
		setModalDetalhesPixVisible,
	} = useContextNFCe();

	const [indexPagamento, setIndexPagamento] = useState(null);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [isAVista, setIsAVista] = useState([]);
	const [favoritosLocal, setFavoritosLocal] = useState({});

	const urls = {
		condicaoPagamento: `${services.GESTOR}/v1/nfce/relacoes/condicoes_pagamento`,
		formaPagamento: `${services.GESTOR}/v1/nfce/relacoes/formas_pagamento`,
		conta: `${services.GESTOR}/v1/nfce/relacoes/contas`,
		categoria: `${services.GESTOR}/v1/nfce/relacoes/categorias/receitas`,
	};

	useEffect(() => {
		setIsAVista([]);
		values.pagamentos?.forEach((pagamento) => {
			setIsAVista((isAVista) => [...isAVista, pagamento.condicaoPagamento?.registro?.tipo === Condicoes.A_VISTA]);
		});
	}, [values.pagamentos]);

	useEffectOnce(() => {
		if (!davImportado) {
			getFavoritosPagamento();
		}
	});

	const [generateParcelas] = useGenerateParcelas({
		valorPagamento: values.totalizadores?.totalLiquido,
		dataBaseParcela: values.emissao,
	});

	useUpdateEffect(() => {
		if (atualizarPagamentos) {
			const pagamentosTemp = copiarObjeto(values.pagamentos);
			let valorParaRatear = 0;
			let existeParcelaAVista = false;

			valorParaRatear = values.totalizadores.totalLiquido;

			if (valorParaRatear > 0) {
				let valorSemRateio = valorParaRatear;
				values.pagamentos?.forEach((pagamento, index) => {
					pagamentosTemp[index].valor = parseFloatNumber(valorSemRateio / values.pagamentos.length);
					valorSemRateio -= parseFloatNumber(valorSemRateio / values.pagamentos.length);

					if (pagamento.condicaoPagamento?.registro?.tipo === Condicoes.A_VISTA) {
						existeParcelaAVista = true;
					}
				});
				valorSemRateio > 0
					? (pagamentosTemp[values.pagamentos.length - 1].valor += parseFloatNumber(valorSemRateio))
					: null;
			} else {
				values.pagamentos?.forEach((pagamento, index) => {
					pagamentosTemp[index].valor = 0;

					if (pagamento.condicaoPagamento?.registro?.tipo === Condicoes.A_VISTA) {
						existeParcelaAVista = true;
					}
				});
			}

			if (existeParcelaAVista) {
				pagamentosTemp.forEach((pagamento, index) => {
					if (pagamento.condicaoPagamento?.registro?.tipo === Condicoes.A_VISTA) {
						if (pagamentosTemp[index].valorRecebido > 0) {
							pagamentosTemp[index].valorTroco = pagamentosTemp[index].valorRecebido - pagamentosTemp[index].valor;
						} else {
							pagamentosTemp[index].valorTroco = 0;
						}
					}
				});
			}

			setFieldValue('pagamentos', pagamentosTemp);
			setAtualizarPagamentos(false);
		}
	}, [atualizarPagamentos]);

	async function buscarCategoria() {
		let categoriaFavorita = null;
		await buscarCategoriaFavoritaReceita(({ data: response }) => {
			categoriaFavorita = converterValueParaSelect(response.content[0]);
		});
		return categoriaFavorita ?? null;
	}

	async function buscarConta() {
		let contaFavorita = null;
		await buscarContaFavorita(({ data: response }) => {
			contaFavorita = converterValueParaSelect(response.content[0]);
		});
		return contaFavorita ?? null;
	}

	async function buscarForma() {
		let formaPagamentoFavorita = null;
		await buscarFormaPagamentoFavorita(({ data: response }) => {
			formaPagamentoFavorita = converterValueParaSelect(response.content[0]);
		});
		return formaPagamentoFavorita ?? null;
	}

	async function buscarCondicao() {
		let condicaoPagamentoFavorita = null;
		await buscarCondicaoPagamentoFavorita(({ data: response }) => {
			condicaoPagamentoFavorita = converterValueParaSelect(response.content[0]);
		});
		return condicaoPagamentoFavorita ?? null;
	}

	async function getFavoritosPagamento(condicaoPagamento = null, pagamento = null) {
		const pagamentoLocal = {
			...INITIAL_VALUES_PAGAMENTOS_NFCE,
			...pagamento,
		};

		const favoritos = {};
		let isAPrazo = false;

		if (pagamento) {
			Object.assign(pagamentoLocal, pagamento);
		}

		if (condicaoPagamento) {
			Object.assign(pagamentoLocal, {
				condicaoPagamento,
				minParcelas: condicaoPagamento?.registro?.parcelaMinima,
				maxParcelas: condicaoPagamento?.registro?.parcelaMaxima,
				parcelaPadrao: condicaoPagamento?.registro?.parcelaPadrao,
				conta: condicaoPagamento.registro?.formaPagamento?.conta
					? converterValueParaSelect(condicaoPagamento.registro.formaPagamento.conta)
					: pagamentoLocal.conta,
				categoria: condicaoPagamento.registro?.formaPagamento?.categoriaReceita
					? converterValueParaSelect(condicaoPagamento.registro.formaPagamento.categoriaReceita)
					: pagamentoLocal.categoria,
				formaPagamento: condicaoPagamento.registro?.formaPagamento
					? converterValueParaSelect(condicaoPagamento.registro.formaPagamento)
					: pagamentoLocal.formaPagamento,
			});
		}

		const [contaFavorita, categoriaFavorita, formaFavorita, condicaoPagamentoFavorita] = await Promise.all([
			!pagamentoLocal.conta ? buscarConta() : Promise.resolve(pagamentoLocal.conta),
			!pagamentoLocal.categoria ? buscarCategoria() : Promise.resolve(pagamentoLocal.categoria),
			!pagamentoLocal.formaPagamento ? buscarForma() : Promise.resolve(pagamentoLocal.formaPagamento),
			!pagamentoLocal.condicaoPagamento ? buscarCondicao() : Promise.resolve(pagamentoLocal.condicaoPagamento),
		]);

		if (contaFavorita) {
			pagamentoLocal.conta = contaFavorita;
			favoritos.conta = contaFavorita;
		}
		if (categoriaFavorita) {
			pagamentoLocal.categoria = categoriaFavorita;
			favoritos.categoria = categoriaFavorita;
		}
		if (formaFavorita) {
			pagamentoLocal.formaPagamento = formaFavorita;
			favoritos.formaPagamento = formaFavorita;
		}
		if (condicaoPagamentoFavorita) {
			pagamentoLocal.condicaoPagamento = condicaoPagamentoFavorita;
			favoritos.condicaoPagamento = condicaoPagamentoFavorita;

			if (condicaoPagamentoFavorita.registro?.formaPagamento) {
				pagamentoLocal.formaPagamento = converterValueParaSelect(condicaoPagamentoFavorita.registro.formaPagamento);
				favoritos.formaPagamento = converterValueParaSelect(condicaoPagamentoFavorita.registro.formaPagamento);

				if (condicaoPagamentoFavorita.registro?.formaPagamento?.conta) {
					pagamentoLocal.conta = converterValueParaSelect(condicaoPagamentoFavorita.registro.formaPagamento.conta);
					favoritos.conta = converterValueParaSelect(condicaoPagamentoFavorita.registro.formaPagamento.conta);
				}

				if (condicaoPagamentoFavorita.registro?.formaPagamento?.categoriaReceita) {
					pagamentoLocal.categoria = converterValueParaSelect(
						condicaoPagamentoFavorita.registro.formaPagamento.categoriaReceita
					);
					favoritos.categoria = converterValueParaSelect(
						condicaoPagamentoFavorita.registro.formaPagamento.categoriaReceita
					);
				}
			}

			isAPrazo = condicaoPagamentoFavorita?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
			pagamentoLocal.parcelaPadrao =
				condicaoPagamentoFavorita?.registro?.parcelaPadrao ?? condicaoPagamentoFavorita?.registro?.parcelaMinima ?? 1;
			pagamentoLocal.parcelaMinima = condicaoPagamentoFavorita?.registro?.parcelaMinima ?? 1;
			pagamentoLocal.parcelaMaxima = condicaoPagamentoFavorita?.registro?.parcelaMaxima ?? 48;
		}

		if (isAPrazo) {
			pagamentoLocal.quantidadeParcelas = pagamentoLocal.parcelaPadrao;
			pagamentoLocal.parcelas = generateParcelas({
				qtdParcelas: pagamentoLocal.parcelaPadrao,
				favoritos: {
					conta: pagamentoLocal.conta,
					categoria: pagamentoLocal.categoria,
					condicaoPagamento: pagamentoLocal.condicaoPagamento,
					formaPagamento: pagamentoLocal.formaPagamento,
				},
			});
		}

		if (isFirstRender && !davImportado && podeInserir) {
			resetFormPrincipal({ values: { ...values, pagamentos: [pagamentoLocal] } });
			setFavoritosLocal(favoritos);
			setIsFirstRender(false);
		}

		return pagamentoLocal;
	}

	async function onChangeCondicao(event, pagamento, index) {
		const pagamentoNovo = await getFavoritosPagamento(event, pagamento);
		setFieldValue(`pagamentos[${index}]`, { ...pagamento, ...pagamentoNovo });
		setAtualizarPagamentos(true);
	}

	function onChangeValorRecebido(value, index) {
		setFieldValue(`pagamentos[${index}].valorRecebido`, value);
		if (value > 0) {
			setFieldValue(`pagamentos[${index}].valorTroco`, value - values.pagamentos[index].valor);
		} else if (!value || value === 0) {
			setFieldValue(`pagamentos[${index}].valorTroco`, 0);
		}
	}

	function handleChangeValor(event, index) {
		const pagamento = {
			...values.pagamentos[index],
			valor: event,
		};
		setFieldValue(`pagamentos[${index}]`, pagamento);
	}

	function onDeletePagamento(index) {
		if (!(index === 0 && values.pagamentos?.length === 1)) {
			const { pagamentos } = values;
			pagamentos.splice(index, 1);
			setFieldValue('pagamentos', pagamentos);
			setAtualizarPagamentos(true);
		}
	}

	async function adicionarNovaFormaPagamento() {
		const pagamentoNovo = await getFavoritosPagamento();
		setFieldValue('pagamentos', [...values.pagamentos, pagamentoNovo]);
		setAtualizarPagamentos(true);
	}

	function handleChangeFormaPagamento(event, index) {
		const pagamento = {
			...values.pagamentos[index],
			formaPagamento: event,
			categoria: event.registro?.categoriaReceita
				? converterValueParaSelect(event.registro?.categoriaReceita)
				: values.pagamentos[index].categoria,
			conta: event.registro?.conta ? converterValueParaSelect(event.registro?.conta) : values.pagamentos[index].conta,
		};
		setFieldValue(`pagamentos[${index}]`, pagamento);
	}

	function handleChangeCategoria(event, index) {
		const pagamento = {
			...values.pagamentos[index],
			categoria: event,
		};
		setFieldValue(`pagamentos[${index}]`, pagamento);
	}

	function handleChangeConta(event, index) {
		const pagamento = {
			...values.pagamentos[index],
			conta: event,
		};
		setFieldValue(`pagamentos[${index}]`, pagamento);
	}

	return (
		<>
			<div
				style={{
					overflowY: values.pagamentos.length > 1 ? 'scroll' : 'hidden',
					overflowX: 'hidden',
					maxHeight: isMobile ? '36.4rem' : '16rem',
				}}
			>
				{values.pagamentos?.map((pagamento, index) => (
					<div key={`pagamentoNFCe_${pagamento.id}`}>
						<Grid
							style={{
								backgroundColor: index % 2 === 0 ? null : '#f1f1f1',
							}}
						>
							<Col
								sm="10"
								md="11"
								lg="11"
								xl="11"
								style={{
									display: 'flex',
									flexDirection: 'row',
									flexWrap: 'wrap',
								}}
							>
								<div style={{ width: '100%', display: 'flex' }}>
									<If test={!isMobile}>
										<div
											style={{
												padding: '0px 4px',
												paddingTop: isAVista[index] ? '0px' : '16px',
												display: 'flex',
												alignItems: 'center',
											}}
										>{`${index + 1}ª `}</div>
									</If>
									<div
										style={{
											width: '100%',
											display: 'flex',
											flexDirection: 'row',
											flexWrap: 'wrap',
										}}
									>
										<Field
											sm="12"
											md="3"
											lg="3"
											xl="3"
											name="condicaoPagamento"
											label="Condição de pagamento"
											helpMessage="Condição de pagamento"
											component={SingleSelectCondicaoPagamento}
											value={pagamento.condicaoPagamento}
											onChange={(event) => onChangeCondicao(event, pagamento, index)}
											url={urls.condicaoPagamento}
											disabled={!podeInserir}
											esconderBotao
											obrigatorio
											isClearable={false}
											hideSemPagamento
											touched
											errors={errors?.pagamentos?.[index]?.condicaoPagamento}
											useFormErrors={false}
											{...informacoesPermissoes}
										/>
										<If test={pagamento.condicaoPagamento?.registro?.tipo === Condicoes.A_VISTA}>
											<Field
												sm="12"
												md="3"
												lg="3"
												xl="3"
												name="formaPagamento"
												label="Forma de pagamento"
												helpMessage="Forma de pagamento"
												component={SingleSelectFormaPagamento}
												value={pagamento.formaPagamento}
												onChange={(event) => handleChangeFormaPagamento(event, index)}
												url={urls.formaPagamento}
												disabled={!podeInserir}
												esconderBotao
												obrigatorio
												isClearable={false}
												touched
												errors={errors?.pagamentos?.[index]?.formaPagamento}
												useFormErrors={false}
												{...informacoesPermissoes}
											/>
											<Field
												sm="12"
												md="3"
												lg="3"
												xl="3"
												name="conta"
												label="Conta"
												helpMessage="Conta"
												placeholder="Pesquisar"
												component={SingleSelectConta}
												value={pagamento.conta}
												onChange={(event) => handleChangeConta(event, index)}
												url={urls.conta}
												disabled={!podeInserir}
												esconderBotao
												obrigatorio
												isClearable={false}
												touched
												errors={errors?.pagamentos?.[index]?.conta}
												useFormErrors={false}
												{...informacoesPermissoes}
											/>
										</If>
										<Field
											sm="12"
											md="3"
											lg={isAVista[index] ? '3' : '4'}
											xl={isAVista[index] ? '3' : '4'}
											label="Categoria"
											name="categoria"
											helpMessage="Categoria"
											placeholder="Pesquisar"
											component={SingleSelectCategoria}
											value={pagamento.categoria}
											onChange={(event) => handleChangeCategoria(event, index)}
											url={urls.categoria}
											disabled={!podeInserir}
											esconderBotao
											obrigatorio
											isClearable={false}
											touched
											errors={errors?.pagamentos?.[index]?.categoria}
											useFormErrors={false}
											{...informacoesPermissoes}
										/>
										<Field
											sm="12"
											md="3"
											lg="3"
											xl="3"
											label="Valor"
											name="valor"
											helpMessage="Valor"
											component={InputMoney}
											value={pagamento.valor}
											onChange={(event) => handleChangeValor(event.target.value, index)}
											allowNegative={false}
											size={13}
											prefix="R$ "
											placeholder="R$ 0,00"
											obrigatorio
											disabled={!podeInserir && !davImportado}
											touched
											errors={errors?.pagamentos?.[index]?.valor}
											useFormErrors={false}
											{...informacoesPermissoes}
										/>
										<If test={pagamento.condicaoPagamento?.registro?.tipo === Condicoes.A_VISTA}>
											<Field
												sm="12"
												md="3"
												lg="3"
												xl="3"
												component={InputMoney}
												id="input-valor-recebido-nfce"
												label="Valor recebido"
												name="valorRecebido"
												allowNegative={false}
												onChange={(event) => onChangeValorRecebido(event.target.value, index)}
												value={pagamento?.valorRecebido}
												size={13}
												prefix="R$ "
												placeholder="R$ 0,00"
												touched
												useFormErrors={false}
												errors={errors?.pagamentos?.[index]?.valorRecebido}
												disabled={!podeInserir && !davImportado}
												{...informacoesPermissoes}
											/>
											<div
												style={{
													fontSize: '24px',
													fontWeight: 'bold',
													color: pagamento?.valorTroco < 0 ? '#B71C1C' : '#1B5E20',
													display: 'flex',
													alignItems: 'center',
													marginTop:
														isMobile ||
														isTablet ||
														(errors?.pagamentos?.length > 0 &&
															(errors?.pagamentos[index]?.valorRecebido || errors?.pagamentos[index]?.valor))
															? '0px'
															: '18px',
													marginLeft: isMobile || isTablet ? '0px' : '8px',
												}}
												title={
													pagamento?.valorTroco < 0
														? 'Valor que o Cliente deve para fechar o pagamento'
														: 'Valor a ser devolvido para o cliente'
												}
											>
												Troco: {formatarMonetario(pagamento?.valorTroco)}
											</div>
										</If>
										<If test={pagamento.condicaoPagamento?.registro?.tipo === Condicoes.A_PRAZO}>
											<Col
												sm="12"
												md="2"
												lg="2"
												xl="2"
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													paddingTop: '23px',
												}}
											>
												<Field
													style={{ width: isMobile ? '100%' : '146px' }}
													className="p-button-primary"
													type="button"
													component={NormalButton}
													label="Parcelas"
													icon="fa fa-credit-card"
													name="parcelas"
													obrigatorio
													touched
													allowNegative={false}
													onClick={() => {
														setIndexPagamento(index);
														setModalParcelasPrazo(true);
													}}
													{...informacoesPermissoes}
												/>
											</Col>
										</If>
									</div>
								</div>
							</Col>

							<Col
								sm="2"
								md="1"
								lg="1"
								xl="1"
								style={{
									padding: '0px 4px',
									paddingTop: isAVista[index] ? '0px' : '12px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<ButtonDetalhesTable
									icon="pi pi-qrcode"
									color="success"
									onClick={() => {
										setModalDetalhesPixVisible(true);
										setIndexPagamento(index);
									}}
									title={
										values.pagamentos[index].lancamento && values.pagamentos[index].lancamento.pixId
											? 'Visualizar detalhes recebimento pix'
											: 'Sem detalhes para exibir'
									}
									disabled={!(values.pagamentos[index].lancamento && values.pagamentos[index].lancamento.pixId)}
									{...informacoesPermissoes}
								/>
								<ButtonExcluirTable
									onClick={() => onDeletePagamento(index)}
									title="Excluir item"
									disabled={(index === 0 && values.pagamentos.length === 1) || !podeInserir}
									{...informacoesPermissoes}
								/>
							</Col>
						</Grid>
					</div>
				))}
			</div>
			<Grid style={{ paddingTop: '4px' }}>
				<Col sm="8" md="8" lg="8" xl="8" style={{ padding: '8px 0px 0px 0px' }}>
					<ButtonAdicionarItem
						label="Adicionar forma de pagamento"
						style={{
							background: 'none',
							border: 'none',
							fontWeight: 'bold',
							boxShadow: 'none',
							padding: '0rem 1.2rem',
							color: defineColorStatus(values.status),
						}}
						onClick={adicionarNovaFormaPagamento}
						disabled={!podeInserir}
					/>
				</Col>
			</Grid>
			<If test={modalParcelasPrazo}>
				<ModalParcelas
					visible={modalParcelasPrazo}
					onHide={() => {
						setModalParcelasPrazo(false);
						setIndexPagamento(null);
					}}
					setFieldValueModalFinalizacao={setFieldValue}
					indexPagamento={indexPagamento}
					pagamento={values.pagamentos[indexPagamento]}
					isMobile={isMobile}
					isTablet={isTablet}
					disabled={!podeInserir}
					emissao={values.emissao}
					isFullScreen={isFullScreen}
					generateParcelas={generateParcelas}
					favoritosLocal={favoritosLocal}
				/>
			</If>
			<If test={modalDetalhesPixVisible}>
				<ModalDetalhesPix
					visible={modalDetalhesPixVisible}
					lancamentoId={values.pagamentos[indexPagamento]?.lancamento?.id}
					pixId={values.pagamentos[indexPagamento]?.lancamento?.pixId}
					valor={values.pagamentos[indexPagamento]?.lancamento?.valor}
					container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
					onHide={() => {
						setModalDetalhesPixVisible(false);
					}}
				/>
			</If>
		</>
	);
}

export default FormaPagamento;
