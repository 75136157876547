import { InternalInputSearchMask } from './components/InternalInputSearchMask';

export default function InputSearchMask(props) {
	const { field, form, useFormErrors = true, useFormTouched = true, ...rest } = props;
	const useForm = useFormErrors && useFormTouched;

	return (
		<>
			<InternalInputSearchMask
				{...field}
				{...rest}
				errors={form && useForm ? form.errors[field.name] : props.errors}
				touched={form && useForm ? Boolean(form.touched[field.name]) : props.touched}
			/>
		</>
	);
}
