import { gerarUUID } from 'Common';

const styleColumn = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

const TIPOS_PERCENTUAL_VALOR = {
	PERCENTUAL: 'PERCENTUAL',
	VALOR: 'VALOR',
};

const MESSAGES_ERRORS = {
	OBRIGATORIO: 'Obrigatório',
	CAMPO_OBRIGATORIO: 'Campo obrigatório',
};

const NOVO_SERVICO = {
	id: gerarUUID(),
	item: 1,
	servico: null,
	quantidade: 1,
	valor: 0,
	valorOriginal: 0,

	valorAntesAlteracao: null,
	alterouValorManualmente: false,

	subtotal: 0,
	operacaoFiscal: null,
	desconto: 0,
	unidadeMedida: null,
	codigoServico: null,
	descricao: null,

	tributos: {
		iss: {
			percentualBase: 0,
			valorBase: 0,
			percentual: 0,
			valor: 0,
		},
		ir: {
			percentualBase: 0,
			valorBase: 0,
			percentual: 0,
			valor: 0,
		},
		csll: {
			percentualBase: 0,
			valorBase: 0,
			percentual: 0,
			valor: 0,
		},
		inss: {
			percentualBase: 0,
			valorBase: 0,
			percentual: 0,
			valor: 0,
		},
		pis: {
			id: null,
			cst: {
				label: '07 - Operação Isenta da Contribuição',
				value: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
				registro: {
					id: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
					codigo: '07',
					descricao: 'Operação Isenta da Contribuição',
				},
			},
			percentualBase: 0,
			valorBase: 0,
			percentual: 0,
			valor: 0,
		},
		cofins: {
			id: null,
			cst: {
				label: '07 - Operação Isenta da Contribuição',
				value: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
				registro: {
					id: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
					codigo: '07',
					descricao: 'Operação Isenta da Contribuição',
				},
			},
			percentualBase: 0,
			valorBase: 0,
			percentual: 0,
			valor: 0,
		},
	},
};

export { styleColumn, styleButton, TIPOS_PERCENTUAL_VALOR, MESSAGES_ERRORS, NOVO_SERVICO };
