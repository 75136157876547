import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Menu } from 'primereact/menu';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import Grid from '../Grid';

function MenuSuperior(props) {
	const { hidden, isModal, children, id, items } = props;
	const [displayWidth, setDisplayWidth] = useState(window.innerWidth);
	const divStyle = {
		zIndex: '1',
		display: 'block',
		paddingTop: '0px',
		position: 'fixed',
		top: '70',
		width: '100%',
	};

	const modalDivStyle = {
		paddingBottom: '5px',
	};

	useEffect(() => {
		if (props.items) {
			window.addEventListener('resize', updateWindowDimensions);
		}

		return () => {
			if (props.items) {
			}
			window.removeEventListener('resize', updateWindowDimensions);
		};
	}, []);

	function updateWindowDimensions() {
		if (props.items) {
			if ((displayWidth < 750 && window.innerWidth > 750) || (displayWidth > 750 && window.innerWidth < 750)) {
				setDisplayWidth(window.innerWidth);
			}
		}
	}

	function renderButtons() {
		if (displayWidth < 750 && props.items) {
			return (
				<>
					<Button type="button" label="Opções" icon="fa fa-list" onClick={event => menu.toggle(event)} />
				</>
			);
		} else {
			return props.children;
		}
	}

	if (hidden) {
		return null;
	}

	if (isModal) {
		return <div style={modalDivStyle}>{children}</div>;
	}

	return (
		<>
			<Menu id={id} model={items} popup={true} ref={el => (menu = el)} />
			<Grid style={divStyle}>
				<Toolbar
					className="p-toolbar color-template"
					style={{ border: '0px', paddingTop: '5px', paddingBottom: '0px' }}
				>
					{renderButtons()}
				</Toolbar>
			</Grid>
		</>
	);
}

MenuSuperior.defaultProps = {
	hidden: false,
};

MenuSuperior.propTypes = {
	/** Identificador do componente */
	id: propTypes.string,
	/** Itens do menu quando o mesmo estiver no tamanho pequeno, seguindo a documentação do componente Menu do PrimeReact */
	items: propTypes.array,
	/** Define se o botão está escondido */
	hidden: propTypes.bool,
	/** Define se o componente é modal, aí o estilo muda */
	isModal: propTypes.bool,
};

export default MenuSuperior;
