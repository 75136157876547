import { Field } from 'formik';
import { Grid, InputCep, InputField, SingleSelectMunicipio, SingleSelectPais } from 'components';
import { useRef } from 'react';

const idPaisBrasil = '34e63b2e-c596-f34f-824d-bfd27eb62fa8';

function Endereco(props) {
	const { values, setFieldValue, informacoesPermissoes } = props;

	function onChangeCep(e) {
		if (e.pesquisou) {
			e.bairro && setFieldValue('bairro', e.bairro);
			e.complemento && setFieldValue('complemento', e.complemento);
			e.logradouro && setFieldValue('logradouro', e.logradouro);
			if (e.logradouro && e.logradouro != '') {
				document.getElementById('inputNumeroEnderecoModal').focus();
			} else {
				document.getElementById('inputLogradouroEnderecoModal').focus();
			}
			e.municipio &&
				setFieldValue('municipio', {
					label: e.municipio.nome + ' - ' + e.municipio.estado.sigla,
					value: e.municipio.id,
					...e.municipio,
				});
			e.pais && setFieldValue('pais', { label: e.pais.nome, value: e.pais.id, ...e.pais });
		} else {
			setFieldValue('cep', e.value);
		}
	}

	return (
		<Grid>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				obrigatorio
				component={InputCep}
				label="CEP"
				name="cep"
				type="tel"
				onChange={(e) => onChangeCep(e)}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				obrigatorio
				id="inputLogradouroEnderecoModal"
				label="Logradouro"
				name="logradouro"
				size={255}
				component={InputField}
				onChange={(e) => setFieldValue('logradouro', e.target.value)}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				obrigatorio
				id="inputNumeroEnderecoModal"
				label="Número"
				size={10}
				name="numero"
				component={InputField}
				onChange={(e) => setFieldValue('numero', e.target.value)}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				obrigatorio
				label="Bairro"
				name="bairro"
				size={60}
				component={InputField}
				onChange={(e) => setFieldValue('bairro', e.target.value)}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				label="Complemento"
				name="complemento"
				size={255}
				component={InputField}
				onChange={(e) => setFieldValue('complemento', e.target.value)}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				obrigatorio
				label="País"
				name="pais"
				isClearable={false}
				component={SingleSelectPais}
				value={values.pais}
				onChange={(e) => setFieldValue('pais', e)}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				obrigatorio
				label="Município"
				name="municipio"
				component={SingleSelectMunicipio}
				value={values.municipio}
				onChange={(e) => setFieldValue('municipio', e)}
				hidden={(values.pais ? values.pais.value : null) !== idPaisBrasil}
				colStyle={{ padding: '4px 8px' }}
				isClearable={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputField}
				type="text"
				label="Estado exterior"
				name="estadoExterior"
				value={values.estadoExterior}
				onChange={(e) => setFieldValue('estadoExterior', e.target.value)}
				size={128}
				hidden={(values.pais ? values.pais.value : null) === idPaisBrasil}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputField}
				type="text"
				label="Município exterior"
				name="municipioExterior"
				value={values.municipioExterior}
				onChange={(e) => setFieldValue('municipioExterior', e.target.value)}
				size={128}
				hidden={(values.pais ? values.pais.value : null) === idPaisBrasil}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export default Endereco;
