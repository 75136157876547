import { Field, useFormikContext } from 'formik';

import { services } from 'Common';
import { Checkbox, Col, Grid, If, SingleSelectCategoria, Subtitle, TextArea, tiposCategoria } from 'components';

import InputFieldCopyButton from 'components/input/InputFieldCopyButton';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { helpParametrizacao } from '../../Help';

function TabFinanceiro() {
	const { podeEditarEmpresa } = useContextConfiguracoesGerais();
	const { values, setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Subtitle subtitle="Financeiro" />
			<Field
				helpMessage={helpParametrizacao.gerarFinanceiroNaNfe}
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Gerar financeiro na NF-e"
				name="gerarFinanceiroNaNfe"
				value={values.parametrizacao.financas?.gerarFinanceiroNaNfe}
				onChange={(e) => setFieldValue('parametrizacao.financas.gerarFinanceiroNaNfe', e.checked)}
				checked={values.parametrizacao.financas?.gerarFinanceiroNaNfe}
				disabled={!podeEditarEmpresa}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Gerar financeiro na NFS-e"
				name="gerarFinanceiroNaNfse"
				value={values.parametrizacao.financas?.gerarFinanceiroNaNfse}
				onChange={(e) => setFieldValue('parametrizacao.financas.gerarFinanceiroNaNfse', e.checked)}
				checked={values.parametrizacao.financas?.gerarFinanceiroNaNfse}
				disabled={!podeEditarEmpresa}
			/>
			<Field
				helpMessage={helpParametrizacao.enviarEmailAutomatico}
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Enviar e-mail de alerta do contas a pagar e receber"
				name="enviarEmailAutomatico"
				value={values.parametrizacao.financas?.enviarEmailAutomatico}
				onChange={(e) => setFieldValue('parametrizacao.financas.enviarEmailAutomatico', e.checked)}
				checked={values.parametrizacao.financas?.enviarEmailAutomatico}
				disabled={!podeEditarEmpresa}
			/>
			<If test={values.parametrizacao.financas?.enviarEmailAutomatico}>
				<Field
					helpMessage={helpParametrizacao.mensagemEmailContasReceber}
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={TextArea}
					label="Mensagem do corpo do e-mail contas a receber"
					name="mensagemEmailContasReceber"
					maxLength={255}
					value={values.parametrizacao.financas?.mensagemEmailContasReceber}
					onChange={(e) => setFieldValue('parametrizacao.financas.mensagemEmailContasReceber', e.target.value)}
					disabled={!podeEditarEmpresa}
				/>
				<Field
					helpMessage={helpParametrizacao.mensagemEmailContasPagar}
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={TextArea}
					label="Mensagem do corpo do e-mail contas a pagar"
					name="mensagemEmailContasPagar"
					maxLength={255}
					value={values.parametrizacao.financas?.mensagemEmailContasPagar}
					onChange={(e) => setFieldValue('parametrizacao.financas.mensagemEmailContasPagar', e.target.value)}
					disabled={!podeEditarEmpresa}
				/>
			</If>
			<Field
				helpMessage={helpParametrizacao.visualizarReceberTodasFiliais}
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Visualizar contas a receber de todas as filiais"
				name="visualizarReceberTodasFiliais"
				value={values.parametrizacao.financas?.visualizarReceberTodasFiliais}
				onChange={(e) => setFieldValue('parametrizacao.financas.visualizarReceberTodasFiliais', e.checked)}
				checked={values.parametrizacao.financas?.visualizarReceberTodasFiliais}
				disabled={!podeEditarEmpresa}
			/>
			<Field
				helpMessage={helpParametrizacao.visualizarPagarTodasFiliais}
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Visualizar contas a pagar de todas as filiais"
				name="visualizarPagarTodasFiliais"
				value={values.parametrizacao.financas?.visualizarPagarTodasFiliais}
				onChange={(e) => setFieldValue('parametrizacao.financas.visualizarPagarTodasFiliais', e.checked)}
				checked={values.parametrizacao.financas?.visualizarPagarTodasFiliais}
				disabled={!podeEditarEmpresa}
			/>
			<Field
				helpMessage={helpParametrizacao.permitirTransferenciaEntreFiliais}
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Permitir transferências entre filiais"
				name="PermitirTransferenciaEntreFiliais"
				value={values.parametrizacao.financas?.permitirTransferenciaEntreFiliais}
				onChange={(e) => setFieldValue('parametrizacao.financas.permitirTransferenciaEntreFiliais', e.checked)}
				checked={values.parametrizacao.financas?.permitirTransferenciaEntreFiliais}
				disabled={!podeEditarEmpresa}
			/>
			<Col sm="12" md="12" lg="12" xl="12">
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					name="taxaCategoria"
					label="Categoria padrão para taxas"
					tipoCategoria={tiposCategoria.DESPESA}
					component={SingleSelectCategoria}
					value={values.parametrizacao.financas?.taxaCategoria}
					onChange={(e) => setFieldValue('parametrizacao.financas.taxaCategoria', e)}
					url={`${services.GESTOR}/v1/empresa/relacoes/categorias/despesas`}
					disabled={!podeEditarEmpresa}
					disabledButton
				/>
			</Col>

			<If test={values.parametrizacao.financas?.plugBoletosToken}>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={InputFieldCopyButton}
					label="Token integração boletos"
					name="plugBoletosToken"
					value={values.parametrizacao.financas?.plugBoletosToken}
					readOnly
				/>
			</If>
		</Grid>
	);
}

export { TabFinanceiro };
