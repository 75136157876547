export const VENDAS_MODULOS = {
  ORCAMENTO: 'ORCAMENTO',
  PEDIDO_VENDA: 'PEDIDO_VENDA',
  NFE: 'NFE',
  NFCE: 'NFCE',
};

export const OPTIONS_SELECT = [
  {
    label: 'Orçamento',
    value: VENDAS_MODULOS.ORCAMENTO,
  },
  {
    label: 'Pedido de venda',
    value: VENDAS_MODULOS.PEDIDO_VENDA,
  },
  {
    label: 'NF-e',
    value: VENDAS_MODULOS.NFE,
  },
  {
    label: 'NFC-e',
    value: VENDAS_MODULOS.NFCE,
  },
];

export const VENDAS_CORES = {
  vermelho: '#b71c1c',
  vermelhoClaro: '#ffcdd2',
  verde: '#1b5e20',
  verdeClaro: '#dcedc8',
  azul: '#006095',
  preto: '#fff0',
  cinza: '#888888',
  branco: '#F2F2F2',
};
