import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { connect } from 'react-redux';
import { copiarParaAreaTransferencia, formatarMonetario, inserirMascara } from '../../../../../Common';
import { Badge, Button, If, NenhumRegistroEncontrado, notify, Paginacao, ToastTypes } from '../../../../../components';
import { atualizarUrl } from '../../../../Util';
import { ColorsSituacao, TipoManifestacao, SituacaoNfe } from '../../Util/constantes';
import { Icon } from '@iconify/react';
import { formatos } from '../../../../../Common/Constantes/mascaras';
import { useWindowSize } from 'react-use';
import { asyncDownloadXmlManifestacao, asyncDownloadXmlNfe, asyncGetValidateConsulta } from '../../Requests';
import ModalManifestar from '../ModalManifestar';
import ModalMdeHistorico from './components/ModalMdeHistorico';

const styleColumn = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

function TabelasNotas(props) {
	const {
		sortField,
		sortOrder,
		registros,
		pesquisar,
		totalElements,
		rows,
		setRows,
		page,
		setPage,
		podeEditar,
		podeInserirNotaEntrada,
		isMobile,
		isTablet,
	} = props;

	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [menu, setMenu] = useState(null);
	const [modalManifestar, setModalManifestar] = useState(false);
	const [modalHistorico, setModalHistorico] = useState(false);

	const isLessHd = useWindowSize()?.width < 1280 && !isMobile && !isTablet;

	function renderFornecedor(row) {
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{row.emitenteNome}
			</span>
		);
	}

	function renderEmissaoField(row) {
		return <span>{row.dataEmissao ? format(new Date(row.dataEmissao), 'dd/MM/yyyy') : ' - '}</span>;
	}

	function renderSituacao(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: isLessHd ? '0.1rem 0.5rem' : getPaddingBackground(),
		};

		function getPaddingBackground() {
			switch (row.manifestacoes[row.manifestacoes.length - 1]?.tipo) {
				case TipoManifestacao.CIENCIA_DA_OPERACAO:
					return '0.1rem 1.7rem';
				case TipoManifestacao.CONFIRMACAO_DA_OPERACAO:
					return '0.1rem 1.1rem';
				case TipoManifestacao.DESCONHECIMENTO_DA_OPERACAO:
					return '0.1rem 2rem';
				case TipoManifestacao.OPERACAO_NAO_REALIZADA:
					return '0.1rem 1rem';
				default:
					return '0.1rem 2rem';
			}
		}

		function getJustificativa() {
			return 'Justificativa: ' + row.manifestacoes[row.manifestacoes.length - 1]?.justificativa;
		}

		switch (row.manifestacoes[row.manifestacoes.length - 1]?.tipo) {
			case TipoManifestacao.CIENCIA_DA_OPERACAO: {
				return Badge(
					ColorsSituacao.textCiencia,
					ColorsSituacao.bgCiencia,
					'Ciência da operação',
					styleBackground,
					{},
					isLessHd
				);
			}
			case TipoManifestacao.CONFIRMACAO_DA_OPERACAO: {
				return Badge(
					ColorsSituacao.textConfirmada,
					ColorsSituacao.bgConfirmada,
					'Confirmada a operação',
					styleBackground,
					{},
					isLessHd
				);
			}
			case TipoManifestacao.DESCONHECIMENTO_DA_OPERACAO: {
				return Badge(
					ColorsSituacao.textDesconhecimento,
					ColorsSituacao.bgDesconhecimento,
					'Desc. da operação',
					styleBackground,
					{},
					isLessHd,
					'Desconhecimento da operação'
				);
			}
			case TipoManifestacao.OPERACAO_NAO_REALIZADA: {
				return Badge(
					ColorsSituacao.textNaoRealizada,
					ColorsSituacao.bgNaoRealizada,
					'Operação não realizada',
					styleBackground,
					{},
					isLessHd,
					getJustificativa()
				);
			}
			default:
				return Badge(
					ColorsSituacao.textSemManifestacao,
					ColorsSituacao.bgSemManifestacao,
					'Sem manifestação',
					styleBackground,
					{},
					isLessHd
				);
		}
	}

	function getSituacaoMde(manifestacoes) {
		switch (manifestacoes[manifestacoes.length - 1]?.tipo) {
			case TipoManifestacao.CIENCIA_DA_OPERACAO: {
				return TipoManifestacao.CIENCIA_DA_OPERACAO;
			}
			case TipoManifestacao.CONFIRMACAO_DA_OPERACAO: {
				return TipoManifestacao.CONFIRMACAO_DA_OPERACAO;
			}
			case TipoManifestacao.DESCONHECIMENTO_DA_OPERACAO: {
				return TipoManifestacao.DESCONHECIMENTO_DA_OPERACAO;
			}
			case TipoManifestacao.OPERACAO_NAO_REALIZADA: {
				return TipoManifestacao.OPERACAO_NAO_REALIZADA;
			}
			default:
				return TipoManifestacao.SEM_MANIFESTACAO;
		}
	}

	function renderStatusNfe(row) {
		const styleContainer = {
			display: 'flex',
			justifyContent: 'center',
		};

		switch (row.situacaoNfe) {
			case SituacaoNfe.TRANSMITIDA: {
				return (
					<div style={styleContainer}>
						<i
							className="pi pi-check-circle"
							aria-hidden="true"
							style={{ fontSize: '20px', color: 'green', fontWeight: 'bold', height: '100%' }}
							title="NF-e transmitida"
						/>
					</div>
				);
			}
			case SituacaoNfe.CANCELADA: {
				return (
					<div style={styleContainer}>
						<i
							className="pi pi-times-circle"
							aria-hidden="true"
							style={{ fontSize: '20px', color: 'red', fontWeight: 'bold' }}
							title="NF-e cancelada"
						/>
					</div>
				);
			}
			case SituacaoNfe.DENEGADA: {
				return (
					<div style={styleContainer}>
						<i
							className="pi pi-minus-circle"
							aria-hidden="true"
							style={{ fontSize: '20px', color: 'gray', fontWeight: 'bold' }}
							title="NF-e denegada"
						/>
					</div>
				);
			}
		}
	}

	function renderAcoesField(element) {
		let titleManifestar = 'Manifestar';
		let titleImportar = 'Importar nota de entrada';
		let disableBtnManifestar = false;
		let disableBtnImportar = false;
		const statusMde = getSituacaoMde(element?.manifestacoes);

		if (element?.situacaoNfe === SituacaoNfe.CANCELADA || element?.situacaoNfe === SituacaoNfe.DENEGADA) {
			titleImportar = 'Você não pode inserir uma nova nota de entrada';
			disableBtnImportar = true;
			titleManifestar = 'Você não pode manifestar esta NF-e';
			disableBtnManifestar = true;
		}

		if (statusMde === TipoManifestacao.SEM_MANIFESTACAO || statusMde === TipoManifestacao.DESCONHECIMENTO_DA_OPERACAO) {
			titleImportar = 'Você não pode importar esta NF-e';
			disableBtnImportar = true;
		}

		if (element?.manifestacoes?.length >= 4) {
			titleManifestar = 'Você não pode manifestar esta NF-e';
			disableBtnManifestar = true;
		}

		if (!podeEditar) {
			titleManifestar = 'Você não tem autorização para manifestar';
			disableBtnManifestar = true;
		}

		if (!podeInserirNotaEntrada) {
			titleImportar = 'Você não tem autorização para inserir uma nota de entrada';
			disableBtnImportar = true;
		}

		return (
			<div style={{ display: 'flex' }}>
				<If test={!isLessHd}>
					<Button
						style={styleButton}
						className="p-button p-button-primary step-listagem-manifestar"
						icon={<Icon icon="fa-solid:paper-plane" style={{ marginRight: '0.2rem' }} />}
						title={titleManifestar}
						disabled={disableBtnManifestar}
						onClick={() => {
							setRegistroSelecionado(element);
							setModalManifestar(true);
						}}
					/>
					<Button
						style={styleButton}
						className="p-button p-button-success step-listagem-importar"
						icon="fa fa-upload"
						title={titleImportar}
						disabled={disableBtnImportar}
						onClick={() => importarNfe(element)}
					/>
				</If>
				<Button
					title="Opções"
					className={`p-button-secondary ${isLessHd ? 'step-listagem-opcoes' : ''}`}
					icon={<Icon icon="clarity:ellipsis-vertical-line" style={{ marginTop: '0.08rem' }} />}
					style={styleButton}
					aria-controls="popup_menu"
					aria-haspopup={true}
					onClick={(event) => {
						setRegistroSelecionado(element);
						menu.toggle(event);
					}}
				/>
			</div>
		);
	}

	function montarItensMenu() {
		let itens = [];

		if (registroSelecionado) {
			if (isLessHd) {
				itens.push({
					label: 'Manifestar',
					icon: 'fa fa-paper-plane',
					command: () => setModalManifestar(true),
				});
				itens.push({
					label: 'Importar NF-e',
					icon: 'fa fa-upload',
					command: () => importarNfe(registroSelecionado),
				});
			}
			itens.push({
				label: 'Download XML NF-e',
				icon: 'fa fa-download',
				command: () => downloadXmlNfe(registroSelecionado),
				disabled: getSituacaoMde(registroSelecionado.manifestacoes) === TipoManifestacao.SEM_MANIFESTACAO,
			});
			itens.push({
				label: 'Download XML MD-e',
				icon: 'fa fa-download',
				command: () => downloadXmlManifestacao(registroSelecionado),
				disabled: getSituacaoMde(registroSelecionado.manifestacoes) === TipoManifestacao.SEM_MANIFESTACAO,
			});
			itens.push({
				label: 'Copiar chave de acesso',
				icon: 'pi pi-copy',
				command: () => copiarChaveAcesso(registroSelecionado),
			});
			itens.push({
				label: 'Histórico',
				icon: 'fa fa-history',
				command: () => setModalHistorico(true),
			});
		}
		return itens;
	}

	function onSaveManifestacao() {
		setModalManifestar(false);
		pesquisar();
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function formatarCpfCnpj(cpfCnpj) {
		if (cpfCnpj.length === 11) {
			return inserirMascara(cpfCnpj, formatos.CPF);
		} else {
			return inserirMascara(cpfCnpj, formatos.CNPJ);
		}
	}

	async function downloadXmlNfe(value) {
		await asyncDownloadXmlNfe(
			value.id,
			({ data: xml }) => {
				let blob = new Blob([xml], { type: 'text/plain;charset=utf-8' });
				saveAs(blob, `${value.chaveAcesso ?? value.id}.xml`);
			},
			({ response }) => {
				notify(response.data.details[0], ToastTypes.ERROR, 8);
			}
		);
	}

	async function downloadXmlManifestacao(value) {
		const lastManifestacao = value.manifestacoes[value.manifestacoes.length - 1];

		await asyncDownloadXmlManifestacao(value.id, lastManifestacao.id, ({ data: xml }) => {
			let blob = new Blob([xml], { type: 'text/plain;charset=utf-8' });
			saveAs(blob, `${lastManifestacao.protocolo ?? lastManifestacao.id}.xml`);
		});
	}

	function copiarChaveAcesso(value) {
		copiarParaAreaTransferencia(value.chaveAcesso, () =>
			notify('Chave de acesso copiada para área de transferência', ToastTypes.SUCCESS)
		);
	}

	function importarNfe(value) {
		asyncGetValidateConsulta(
			({ data: resultIsTrue }) => {
				if (resultIsTrue) {
					atualizarUrl(props.history, `/nota_entrada/importar_xml_mde/${value.id}`);
				}
			},
			({ response }) => {
				notify(response.data.details[0], ToastTypes.ERROR, 8);
			}
		);
	}

	return (
		<>
			<Menu
				model={montarItensMenu()}
				popup={true}
				ref={(elemento) => setMenu(elemento)}
				style={{ width: 'max-content' }}
			/>
			<DataTable
				className="table"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				responsive
				sortField={sortField}
				sortOrder={sortOrder}
				value={registros}
				style={{ width: '100%' }}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					className="step-listagem-order"
					field="numero"
					header="Número"
					body={(row) => row.numero}
					sortable
					style={{
						...styleColumn,
						width: '5%',
					}}
				/>
				<Column
					field="serie"
					header="Série"
					body={(row) => row.serie}
					sortable
					style={{
						...styleColumn,
						width: '5%',
					}}
				/>
				<Column
					field="dataEmissao"
					header="Emissão"
					body={(row) => renderEmissaoField(row)}
					sortable
					style={{
						...styleColumn,
						width: '10%',
					}}
				/>
				<Column
					field="emitenteNome"
					header="Emitente"
					body={(row) => renderFornecedor(row)}
					sortable
					style={{
						...styleColumn,
						width: '40%',
					}}
				/>
				<Column
					field="emitenteCpfCnpj"
					header="CPF/CNPJ"
					body={(row) => formatarCpfCnpj(row.emitenteCpfCnpj)}
					sortable
					style={{
						...styleColumn,
						whiteSpace: 'nowrap',
					}}
				/>
				<Column
					field="situacaoNfe"
					header="Status"
					body={(row) => renderStatusNfe(row)}
					style={{
						...styleColumn,
					}}
				/>
				<Column
					field="total"
					header="Total"
					body={(row) => formatarMonetario(row.total)}
					style={{
						...styleColumn,
						width: 'auto',
						fontWeight: 'bold',
					}}
				/>
				<Column
					field="manifestacao"
					header="Manifestação"
					body={(row) => renderSituacao(row)}
					style={{
						...styleColumn,
					}}
				/>
				<Column
					header="Ações"
					body={renderAcoesField}
					style={{
						width: 'auto',
					}}
				/>
			</DataTable>
			<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={(e) => onPageChange(e)} />
			<If test={modalManifestar}>
				<ModalManifestar
					visible={modalManifestar}
					onHide={() => setModalManifestar(false)}
					onSave={(e) => onSaveManifestacao(e)}
					registro={registroSelecionado}
					getSituacaoMde={getSituacaoMde}
					isMobile={isMobile}
					isTablet={isTablet}
				/>
			</If>
			<If test={modalHistorico}>
				<ModalMdeHistorico
					visible={modalHistorico}
					onHide={() => setModalHistorico(false)}
					idMde={registroSelecionado?.id}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(TabelasNotas);
