export const optionsModeloBalanca = [
  { label: "Nenhum", value: "Nenhum" },
  { label: "Filizola", value: "Filizola" },
  { label: "Toledo", value: "Toledo" },
  { label: "Urano", value: "Urano" },
  { label: "LucasTec", value: "LucasTec" },
  { label: "Magna", value: "Magna" },
  { label: "Digitron", value: "Digitron" },
  { label: "Magellan", value: "Magellan" },
  { label: "Lider", value: "Lider" },
  { label: "Rinnert", value: "Rinnert" },
  { label: "Muller", value: "Muller" },
  { label: "Saturno", value: "Saturno" },
  { label: "AFTS", value: "AFTS" },
  { label: "Libratek", value: "Libratek" },
  { label: "Genérica", value: "Generica" },
];

export const optionsStopBitsBalanca = [
  { label: "1", value: 1 },
  { label: "1,5", value: 1.5 },
  { label: "2", value: 2 },
];

export const optionsParidadeBalanca = [
  { label: "Nenhum(None)", value: "Nenhum(None)" },
  { label: "Par(Even)", value: "Par(Even)" },
  { label: "Ímpar(Odd)", value: "Ímpar(Odd)" },
  { label: "Marca(Mark)", value: "Marca(Mark)" },
  { label: "Espaço(Spac)", value: "Espaço(Spac)" },
];
