export default function Subtitle(props) {
	return (
		<span
			style={{
				width: '100%',
				margin: '1em 0 0 0.5em',
				color: '#9e9e9e',
				borderBottom: '1px solid #e2e2e2',
				...props.style
			}}
		>
			{props.subtitle}
		</span>
	);
}
