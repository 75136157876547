import { useFormikContext } from 'formik';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef } from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { Divider, If } from '../../../..';
import { formatarMonetario } from '../../../../../Common';
import { moduloUtilizado as modulo } from '../../../../header/Util/constantes';
import './Styles/index.css';

export default function Totalizadores({
	moduloUtilizado,
	isMobile,
	colorStatus,
	totalLiquido,
	totalProdutos,
	totalFrete,
	totalSeguro,
	totalAcessorias,
	totalDesconto,
	totalIcmsSt,
	totalIpi,
	totalIpiDevolvido,
	totalPis,
	totalCofins,
	totalIcms,
	totalBaseCalculoIcmsSt,
	totalBaseCalculoIcms,
	totalBaseCalculoIpi,
	totalBaseCalculoPis,
	totalBaseCalculoCofins,
}) {
	const { values } = useFormikContext();
	const menuImpostos = useRef(null);

	const styleTotal = {
		fontSize: 20,
		fontWeight: 'bold',
		color: colorStatus,
	};

	const styleLineTotais = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0.2rem 0px',
	};

	function renderImpostos() {
		return (
			<If test={moduloUtilizado !== modulo.ORCAMENTO}>
				<div className="table-totais" style={{ width: '100%', height: '100%' }}>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Base de cálculo de ICMS</span>
						<span>{formatarMonetario(totalBaseCalculoIcms)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Valor de ICMS</span>
						<span>{formatarMonetario(totalIcms)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Base de cálculo de ICMS ST</span>
						<span>{formatarMonetario(totalBaseCalculoIcmsSt)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Valor de ICMS ST</span>
						<span>{formatarMonetario(totalIcmsSt)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Base de cálculo do IPI</span>
						<span>{formatarMonetario(totalBaseCalculoIpi)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>
							Valor do IPI {values.finalidadeDocumento?.value === 'DEVOLUCAO_MERCADORIA' ? ' + IPI devolvido' : ''}
						</span>
						<span>
							{formatarMonetario(totalIpi) +
								(values.finalidadeDocumento?.value === 'DEVOLUCAO_MERCADORIA'
									? ` + ${formatarMonetario(totalIpiDevolvido)}`
									: '')}
						</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Base de cálculo do PIS</span>
						<span>{formatarMonetario(totalBaseCalculoPis)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Valor do PIS</span>
						<span>{formatarMonetario(totalPis)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Base de cálculo do COFINS</span>
						<span>{formatarMonetario(totalBaseCalculoCofins)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Valor do COFINS</span>
						<span>{formatarMonetario(totalCofins)}</span>
					</div>
				</div>
			</If>
		);
	}

	function renderTotais() {
		return (
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: isMobile ? '1fr' : '1fr min-content 1fr',
					paddingBottom: '1rem',
				}}
			>
				<div>
					<div style={styleLineTotais}>
						<span>Valor do frete</span>
						<span>{formatarMonetario(totalFrete)}</span>
					</div>
					<div style={styleLineTotais}>
						<span>Valor do seguro</span>
						<span>{formatarMonetario(totalSeguro)}</span>
					</div>
					<div style={styleLineTotais}>
						<span>Valor acessórias</span>
						<span>{formatarMonetario(totalAcessorias)}</span>
					</div>
					<div style={styleLineTotais}>
						<span>Valor de desconto</span>
						<span>{formatarMonetario(totalDesconto * -1)}</span>
					</div>
				</div>
				<If test={!isMobile}>
					<div>
						<Divider orientation="vertical" />
					</div>
				</If>
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
					<div>
						<div style={styleLineTotais}>
							<span>Valor dos produtos</span>
							<span>{formatarMonetario(totalProdutos)}</span>
						</div>
						<If test={moduloUtilizado !== modulo.ORCAMENTO}>
							<div style={styleLineTotais}>
								<span
									style={{ display: 'flex', alignItems: 'self-end', cursor: 'pointer' }}
									onClick={(e) => menuImpostos.current?.toggle(e)}
									className="label-totais"
								>
									Valor dos tributos <IoMdInformationCircleOutline style={{ margin: '0 0 1px 5px' }} />
								</span>
								<span>{formatarMonetario(totalIpi + totalIcmsSt + totalIpiDevolvido)}</span>
							</div>
						</If>
					</div>
					<Divider styleContainer={{ position: 'relative', paddingTop: '0.5rem' }} />
					<div
						style={{
							...styleLineTotais,
							...styleTotal,
						}}
					>
						<span>Valor total</span>
						<span>{formatarMonetario(totalLiquido)}</span>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<OverlayPanel
				style={{ marginLeft: isMobile ? '5%' : null, width: isMobile ? '90%' : '400px' }}
				ref={menuImpostos}
				showCloseIcon
			>
				{renderImpostos()}
			</OverlayPanel>
			{renderTotais()}
		</>
	);
}
