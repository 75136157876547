export const helpPessoaForm = {
	cliente: 'Selecione se o tipo do cadastro é cliente, fornecedor ou transportador.',
	fornecedor: 'Selecione se o tipo do cadastro é cliente, fornecedor ou transportador.',
	transportador: 'Selecione se o tipo do cadastro é cliente, fornecedor ou transportador.',
	nome: 'Nome ou razão social da pessoa a ser cadastrada, dependendo do tipo de pessoa (Física, Jurídica ou Estrangeiro).',
	situacao:
		'Situação em que a pessoa se encontra. Caso a pessoa estiver inativa, a mesma não poderá ser mais utilizada no sistema.',
	tipo: 'Tipo de pessoa que pode ser: Física, Jurídica ou Estrangeiro. Alguns campos e abas são exibidos conforme este tipo.',
	//PESSOA FISICA
	cpf: 'Número do Cadastro de Pessoa física.',
	rg: 'Número da carteira de identidade.',
	genero: 'Gênero da pessoa.',
	dataNascimento: 'Data de nascimento da pessoa física.',
	pessoaPai: 'Nome do Pai da pessoa física.',
	pessoaMae: 'Nome da Mãe da pessoa física.',
	pessoaConjuge: 'Nome do Cônjuge da pessoa física.',
	localTrabalho: 'Local onde a pessoa física trabalha.',

	configPrecoPraticado: 'Parâmetro que definir qual o preço praticado na venda para este cliente',
	tabelaPreco:
		'Parâmetro para vincular uma tabela de preço para o cliente. Quando este campo estiver preenchido, a tabela de preço vinculada vai ter a maior prioridade de uso',
};
