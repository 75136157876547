import { formatarMonetario } from 'Common';

function validateServicos(servicos, tabelaPreco) {
	const errorsServicos = [];

	if (servicos.length > 0) {
		servicos.forEach((item, index) => {
			if (item.servico) {
				if (!item.quantidade || item.quantidade <= 0) {
					errorsServicos[index] = {
						...errorsServicos[index],
						quantidade: 'Quantidade inválida',
					};
				}
				if (!item.valor) {
					errorsServicos[index] = {
						...errorsServicos[index],
						valor: 'Valor inválido',
					};
				}
				if (item.desconto === null || item.desconto === undefined) {
					errorsServicos[index] = {
						...errorsServicos[index],
						desconto: 'Desc. inválido',
					};
				}
				if (item.desconto > item.subtotal) {
					errorsServicos[index] = {
						...errorsServicos[index],
						desconto: 'Desc. inválido',
					};
				}
				if (!item.operacaoFiscal?.value) {
					errorsServicos[index] = {
						...errorsServicos[index],
						servico: 'Operação Fiscal é obrigatória.',
					};
				}
				if (!item.unidadeMedida?.value) {
					errorsServicos[index] = {
						...errorsServicos[index],
						unidadeMedida: 'Unidade de medida é obrigatória.',
					};
				}

				if (tabelaPreco) {
					const valorAntesAlteracao = getValorAntesAlteracao(item);
					if (!tabelaPreco?.registro?.editarPrecoAbaixoValorTabela && item.alterouValorManualmente) {
						if (item.valor < valorAntesAlteracao) {
							errorsServicos[index] = {
								...errorsServicos[index],
								valor: `Não é permitido alterar o valor abaixo de R$ ${formatarMonetario(valorAntesAlteracao)}`,
							};
						}
					}

					if (!tabelaPreco?.registro?.editarPrecoAcimaValorTabela && item.alterouValorManualmente) {
						if (item.valor > valorAntesAlteracao) {
							errorsServicos[index] = {
								...errorsServicos[index],
								valor: `Não é permitido alterar o valor acima de R$ ${formatarMonetario(valorAntesAlteracao)}`,
							};
						}
					}
				}
			} else {
				errorsServicos[index] = {
					...errorsServicos[index],
					servico: 'Serviço é obrigatório',
				};
			}
		});
	} else {
		errorsServicos[0] = {
			...errorsServicos[0],
			servico: 'Informa pelo menos um serviço',
		};
	}

	return errorsServicos.length > 0 ? errorsServicos : [];
}

function getValorAntesAlteracao(servico) {
	let valor = servico.valorOriginal;

	if (servico.adicionalTabelaPreco && servico.adicionalTabelaPreco.percentual > 0) {
		if (servico.adicionalTabelaPreco.tipo === 'DESCONTO') {
			valor -= valor * (servico.adicionalTabelaPreco.percentual / 100);
		} else {
			valor += valor * (servico.adicionalTabelaPreco.percentual / 100);
		}
	}

	return parseFloat(valor.toFixed(2));
}

export { validateServicos };
