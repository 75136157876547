import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	If,
} from 'components';
import { tipoGestaoDocumento } from 'views/documentos/GestaoDocumento/Util/constantes';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../../../Util';
import { confirmarExclusao } from '../../../../../Util/ExclusaoDeRegistros';
import { statusDocumento } from '../../../../AssinaturaEletronica/Util/constantes';
import {
	asyncAdicionarArquivosAnexo,
	asyncAdicionarArquivosGestaoDocumento,
	asyncCreateAnexo,
	asyncCreateDocumento,
	asyncDeleteAnexo,
	asyncDeleteDocumento,
	asyncUpdateAnexo,
	asyncUpdateDocumento,
} from '../../../Requests';
import { helpMessage, INITIAL_VALUE } from '../../Util/constantes';
import {
	exibirConfirmacaoMesmaIdentificacao,
	exibirConfirmacaoSalvarDocumento,
	jaExisteDocumentoMesmaIdentificacao,
} from '../../Util/funcoes';
import {
	converterAnexosParaApi,
	converterGestaoDocumentoParaApi,
	converterGestaoDocumentoParaFormulario,
} from '../../Util/gestaoDocumentosConverter';

export default function ActionButtons(props) {
	const {
		isModal,
		values,
		dirty,
		resetForm,
		history,
		handleSubmit,
		CADASTROURL,
		PESQUISAURL,
		informacoesPermissoes,
		hideModalAnexo,
		idDocumento,
		menu,
		itensOpcoes,
		dadosBase,
		setPodeEditarDocumento,
		isModalAnexo,
		atualizarPagina,
		setDadosBase,
		statusFinalDocumento,
		permiteExcluirAnexo,
		setAnexos,
		adicionouArquivo,
		setAdicionouArquivo,
		anexoDocumentoDisabled,
		novo,
	} = props;

	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	const onClickBotaoNovo = dirty ? (element) => salvar(element, novoRegistro) : novoRegistro;

	function novoRegistro() {		
		setPodeEditarDocumento(true);
		novo();		
		setAnexos([]);
		setDadosBase(INITIAL_VALUE);
		!isModalAnexo && atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		setTimeout(() => {
            document.getElementById("DocumentosInputIdentificador")?.focus();
        }, 500);
	}

	function cancelar() {
		return dirty
			? resetForm({ values: dadosBase })
			: isModalAnexo
				? hideModalAnexo()
				: voltarParaAPesquisa(history, PESQUISAURL);
	}

	async function salvar(element, novoOnSuccess) {
		handleSubmit();
		if (values.tipoGestaoDocumento === tipoGestaoDocumento.CONTRATO) {
			let statusDocumentoDoDocumento = values.arquivoDocumento?.registro?.status;

			if (!isModalAnexo && statusDocumentoDoDocumento === statusDocumento.ASSINADO) {
				exibirConfirmacaoSalvarDocumento(async () => {
					validacoesESalvar(props, novoOnSuccess);
				});
			} else {
				validacoesESalvar(props, novoOnSuccess);
			}
		} else {
			validacoesESalvar(props, novoOnSuccess);
		}
	}

	async function validacoesESalvar(props, novoOnSuccess) {
		if (await validarFormulario(props)) {
			let dadosFormulario = validarDocumentoOuAnexo(values);
			if (await jaExisteDocumentoMesmaIdentificacao(values.id, values.identificacao)) {
				exibirConfirmacaoMesmaIdentificacao(() => {
					salvarOuAtualizar(dadosFormulario, novoOnSuccess);
				});
			} else {
				salvarOuAtualizar(dadosFormulario, novoOnSuccess);
			}
		}
	}

	function validarDocumentoOuAnexo(values) {
		return !isModalAnexo ? converterGestaoDocumentoParaApi(values) : converterAnexosParaApi(values, idDocumento);
	}

	function salvarOuAtualizar(dadosFormulario, novoOnSuccess) {
		values.id ? updateRegistro(dadosFormulario, novoOnSuccess) : createRegistro(dadosFormulario, novoOnSuccess);
	}

	function updateRegistro(dadosFormulario, novoOnSuccess) {
		!isModalAnexo
			? asyncUpdateDocumento(dadosFormulario, async () => {
					if (dadosFormulario.tipoGestaoDocumento === tipoGestaoDocumento.DOCUMENTO) {
						if (values.arquivos?.length > 0 && adicionouArquivo) {
							await asyncAdicionarArquivosGestaoDocumento(values.id, values.arquivos, () => {
								setAdicionouArquivo(false);
							});
						}
					}

					return novoOnSuccess
						? novoOnSuccess()
						: dadosFormulario.arquivoDocumento
							? atualizarPagina()
							: resetForm({ values: values });
				})
			: asyncUpdateAnexo(dadosFormulario, async ({ data: anexo }) => {
					if (dadosFormulario.tipoGestaoDocumento === tipoGestaoDocumento.DOCUMENTO) {
						if (values.arquivos?.length > 0 && adicionouArquivo) {
							await asyncAdicionarArquivosAnexo(anexo.id, values.arquivos, () => {
								setAdicionouArquivo(false);
							});
						}
					}

					return novoOnSuccess ? novoOnSuccess() : hideModalAnexo();
				});
	}

	function createRegistro(dadosFormulario, novoOnSuccess) {
		!isModalAnexo
			? asyncCreateDocumento(dadosFormulario, async ({ data: documento }) => {
					if (dadosFormulario.tipoGestaoDocumento === tipoGestaoDocumento.DOCUMENTO) {
						if (values.arquivos?.length > 0 && adicionouArquivo) {
							await asyncAdicionarArquivosGestaoDocumento(documento.id, values.arquivos, () => {
								setAdicionouArquivo(false);
							});
						}
					}

					if (novoOnSuccess) {
						novoOnSuccess();
					} else {
						atualizarUrl(history, CADASTROURL, documento.id, metodosAtualizarUrl.POP);
					}
				})
			: asyncCreateAnexo(dadosFormulario, async ({ data: anexo }) => {
					if (dadosFormulario.tipoGestaoDocumento === tipoGestaoDocumento.DOCUMENTO) {
						if (values.arquivos?.length > 0 && adicionouArquivo) {
							await asyncAdicionarArquivosAnexo(anexo.id, values.arquivos, () => {
								setAdicionouArquivo(false);
							});
						}
					}

					return novoOnSuccess ? novoOnSuccess() : hideModalAnexo();
				});
	}

	function excluir() {
		confirmarExclusao(deleteRegistro);
	}

	function deleteRegistro() {
		!isModalAnexo
			? asyncDeleteDocumento(values.id, () => {
					resetForm();
					voltarParaAPesquisa(history, PESQUISAURL);
				})
			: asyncDeleteAnexo(values.id, () => {
					return hideModalAnexo();
				});
	}

	return (
		<>
			<ButtonCancelar
				{...informacoesPermissoes}
				hidden={isModal && values.id && !dirty}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
			/>
			<ButtonSalvar {...informacoesPermissoes} disabled={!dirty || statusFinalDocumento} onClick={salvar} />
			<ButtonNovo
				{...informacoesPermissoes}
				hidden={isModal || (!values.id && !dirty)}
				estadoBotao={estadoBotaoNovo}
				disabled={statusFinalDocumento || (isModalAnexo && anexoDocumentoDisabled)}
				onClick={onClickBotaoNovo}
			/>
			<ButtonExcluir
				{...informacoesPermissoes}
				disabled={statusFinalDocumento || (isModalAnexo && (!permiteExcluirAnexo || anexoDocumentoDisabled))}
				hidden={isModal || !values.id}
				onClick={excluir}
				titlePersonalizado={isModalAnexo && !permiteExcluirAnexo ? helpMessage.anexoNaoPodeExcluir : null}
			/>
			<If test={!isModalAnexo}>
				<Button
					className="p-button-primary"
					type="button"
					label="Opções"
					icon="fa fa-list"
					style={{ margin: '5px' }}
					disabled={statusFinalDocumento}
					hidden={itensOpcoes.length === 0}
					onClick={(event) => menu.current.toggle(event)}
					aria-controls="popup_menu"
					aria-haspopup={true}
				/>
			</If>
		</>
	);
}
