/* eslint-disable import/no-cycle */ // Isso é bucha de ajustar, vale a pena cuidar para não ocorrer no v2.0, até pq a equipe herdou isso da equipe anterior :)
import { Icon } from '@iconify/react';
import { VscGraphLine, VscSettings, VscSettingsGear } from 'react-icons/vsc';
import { GrVmMaintenance, GrDocumentText, GrHostMaintenance, GrDocumentDownload } from 'react-icons/gr';
import { GiReceiveMoney, GiPayMoney } from 'react-icons/gi';
import {
	FaBoxes,
	FaCubes,
	FaUsersCog,
	FaPrint,
	FaDolly,
	FaRegBookmark,
	FaWpforms,
	FaRegAddressCard,
	FaRegMoneyBillAlt,
	FaUniversity,
	FaRegHandshake,
	FaQrcode,
} from 'react-icons/fa';
import { HiOutlineReceiptTax, HiOutlineTemplate } from 'react-icons/hi';
import {
	AiOutlineFileText,
	AiOutlineFileDone,
	AiOutlineFileSearch,
	AiOutlineShoppingCart,
	AiOutlineFileAdd,
	AiOutlineTags,
} from 'react-icons/ai';
import { TiCalculator, TiPrinter } from 'react-icons/ti';
import { FiArchive } from 'react-icons/fi';
import { ImFileText2 } from 'react-icons/im';
import { BsBox2, BsBuilding, BsKey, BsPerson, BsPersonLock } from 'react-icons/bs';
import { BiBarcode, BiBriefcase, BiDotsHorizontalRounded, BiHome } from 'react-icons/bi';
import { TbFileInvoice, TbSitemap } from 'react-icons/tb';
import { IoHammerOutline, IoLogoUsd } from 'react-icons/io5';
import { MdOutlineBusinessCenter, MdOutlineEngineering, MdOutlineRealEstateAgent } from 'react-icons/md';
import { LiaMapMarkedAltSolid } from 'react-icons/lia';
import { recursos, permissoes, modulos } from '../../../Common/Constantes/autorizacao';
import {
	buscarDadosLoginLocalStorage,
	usuarioPossuiModulo,
	usuarioPossuiPermissao,
} from '../../../Common/Autenticacao';
import IconSPED from '../../../Common/CustomIcons/SPED.svg';

export function buscarMenu(alterarRota) {
	const menu = [
		{
			label: 'Início',
			icon: <BiHome size={22} style={{ marginRight: '3px' }} />,
			command: () => {
				alterarRota('/');
			},
			isValid: true,
		},
		{
			label: 'Administração',
			icon: <BsKey size={20} style={{ marginRight: '5px' }} />,
			isValid: true,
			items: [
				{
					label: 'Agentes autorizados',
					icon: <FaUsersCog size={20} />,
					command: () => alterarRota('/agentes_autorizados'),
					isValid: usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.VISUALIZAR),
				},
				{
					label: 'Organizações',
					icon: <BsBuilding size={20} />,
					command: () => alterarRota('/organizacoes'),
					isValid: usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.VISUALIZAR),
				},
				{
					label: 'Planos',
					icon: <FaCubes size={20} />,
					command: () => alterarRota('/administracao_planos'),
					isValid: usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.VISUALIZAR),
				},
				{
					label: 'Relatórios',
					icon: <FaPrint size={20} />,
					command: () => alterarRota('/relatorios_administracao'),
					isValid: usuarioPossuiPermissao(recursos.RELATORIOS_ADMINISTRACAO, permissoes.VISUALIZAR),
				},
			],
		},
		{
			label: 'Cadastros',
			icon: <FaWpforms size={20} style={{ marginRight: '5px' }} />,
			validate: false,
			items: [
				{
					label: 'Pessoas',
					icon: <FaRegAddressCard size={20} />,
					command: () => alterarRota('/pessoas'),
					isValid: usuarioPossuiPermissao(recursos.PESSOAS, permissoes.VISUALIZAR),
				},
				buscarDadosLoginLocalStorage().organizacao.aplicacao !== 'DOCS_DIGITAIS'
					? {
							label: 'Produtos / serviços',
							icon: <FiArchive size={20} />,
							validate: false,
							items: [
								{
									label: 'Manutenção',
									icon: <GrVmMaintenance size={20} />,
									command: () => alterarRota('/produtos'),
									isValid: usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR),
								},
								{
									label: 'Grupos',
									icon: <FaBoxes size={20} />,
									command: () => alterarRota('/produtos/grupos'),
									isValid: usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR),
								},
								{
									label: 'Etiquetas',
									icon: <BiBarcode size={20} />,
									command: () => alterarRota('/produtos/etiquetas'),
									isValid: usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.VISUALIZAR),
								},
							],
						}
					: {
							label: 'Produtos',
							icon: <FiArchive size={20} />,
							command: () => alterarRota('/produtos'),
							isValid: usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR),
						},
				buscarDadosLoginLocalStorage().organizacao.aplicacao !== 'DOCS_DIGITAIS'
					? {
							label: 'Fiscal',
							icon: <HiOutlineReceiptTax size={20} style={{ marginRight: '2px' }} />,
							validate: false,
							items: [
								{
									label: 'NCM',
									icon: <Icon icon="simple-icons:buffer" width={20} />,
									command: () => alterarRota('/tributacoes/ncm'),
									isValid: usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.VISUALIZAR),
								},
								{
									label: 'Código serviço',
									icon: <Icon icon="simple-icons:buffer" width={20} />,
									command: () => alterarRota('/tributacoes/codigo_servico'),
									isValid: usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.VISUALIZAR),
								},
								{
									label: 'Operações fiscais',
									icon: <Icon icon="ep:operation" width={21} />,
									command: () => alterarRota('/tributacoes/operacoes_fiscais'),
									isValid: usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.VISUALIZAR),
								},
								{
									label: 'Tributação municipal',
									icon: <VscGraphLine size={20} />,
									command: () => alterarRota('/tributacoes/municipal'),
									isValid: usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.VISUALIZAR),
								},
								{
									label: 'Tributação estadual',
									icon: <VscGraphLine size={20} />,
									command: () => alterarRota('/tributacoes/estadual'),
									isValid: usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.VISUALIZAR),
								},
								{
									label: 'Tributação federal',
									icon: <VscGraphLine size={20} />,
									command: () => alterarRota('/tributacoes/federal'),
									isValid: usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.VISUALIZAR),
								},
							],
						}
					: {},
				usuarioPossuiModulo(modulos.FINANCAS)
					? {
							label: 'Finanças',
							icon: <FaRegMoneyBillAlt size={20} />,
							validate: false,
							items: [
								{
									label: 'Contas',
									icon: <FaUniversity size={20} />,
									command: () => alterarRota('/contas'),
									isValid: usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.VISUALIZAR),
								},
								{
									label: 'Categorias',
									icon: <FaRegBookmark size={20} />,
									command: () => alterarRota('/categorias'),
									isValid: usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.VISUALIZAR),
								},
								{
									label: 'Formas de pagamento',
									icon: (
										<Icon
											icon="fluent:wallet-credit-card-16-regular"
											width="28"
											height="28"
											style={{ marginLeft: '-7px' }}
										/>
									),
									command: () => alterarRota('/formas_pagamento'),
									isValid: usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.VISUALIZAR),
								},
								{
									label: 'Condições de pagamento',
									icon: <Icon icon="mdi:account-tag-outline" width="30" height="30" style={{ marginLeft: '-7px' }} />,
									command: () => alterarRota('/condicoes_pagamento'),
									isValid: usuarioPossuiPermissao(recursos.CONDICOES_PAGAMENTO, permissoes.VISUALIZAR),
								},
							],
						}
					: {},
				usuarioPossuiModulo(modulos.VENDAS)
					? {
							label: 'Vendas',
							icon: <FaRegHandshake size={20} />,
							validate: false,
							items: [
								{
									label: 'Região',
									icon: <LiaMapMarkedAltSolid size={20} />,
									command: () => alterarRota('/vendas/regioes'),
									isValid: usuarioPossuiPermissao(recursos.CADASTROS_REGIOES, permissoes.VISUALIZAR),
								},
								{
									label: 'Tabela de preço',
									icon: <TiCalculator size={20} />,
									command: () => alterarRota('/vendas/tabelas_preco'),
									isValid: usuarioPossuiPermissao(recursos.CADASTROS_TABELAS_PRECO, permissoes.VISUALIZAR),
								},
								{
									label: 'Vendedores',
									icon: <MdOutlineBusinessCenter size={20} />,
									command: () => alterarRota('/vendas/vendedores'),
									isValid: usuarioPossuiPermissao(recursos.CADASTROS_VENDEDORES, permissoes.VISUALIZAR),
								},
							],
						}
					: {},
				usuarioPossuiModulo(modulos.SERVICOS)
					? {
							label: 'Serviços',
							icon: <IoHammerOutline size={20} />,
							validate: false,
							items: [
								{
									label: 'Objeto de serviço',
									icon: <MdOutlineRealEstateAgent size={20} />,
									command: () => alterarRota('/servicos/objeto'),
									isValid: usuarioPossuiPermissao(recursos.SERVICOS, permissoes.VISUALIZAR),
								},
								{
									label: 'Modelo',
									icon: <HiOutlineTemplate size={20} />,
									command: () => alterarRota('/servicos/modelo'),
									isValid: usuarioPossuiPermissao(recursos.SERVICOS, permissoes.VISUALIZAR),
								},
							],
						}
					: {},
				usuarioPossuiModulo(modulos.TRANSPORTE)
					? {
							label: 'Transporte',
							icon: <Icon icon="mdi:truck-fast-outline" width={20} />,
							validate: false,
							items: [
								{
									label: 'Condutores',
									icon: <Icon icon="mdi:badge-account-horizontal-outline" width={20} />,
									command: () => alterarRota('/transporte/condutores'),
									isValid: usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
								},
								{
									label: 'Veículos',
									icon: <Icon icon="mdi:truck-outline" width={20} />,
									command: () => alterarRota('/transporte/veiculos'),
									isValid: usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
								},
								{
									label: 'Seguradora',
									icon: <Icon icon="mdi:shield-check-outline" width={20} />,
									command: () => alterarRota('/transporte/seguradora'),
									isValid: usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
								},
							],
						}
					: {},
				buscarDadosLoginLocalStorage().organizacao.aplicacao !== 'DOCS_DIGITAIS'
					? {
							label: 'Outros',
							icon: <BiDotsHorizontalRounded size={20} />,
							validate: false,
							items: [
								{
									label: 'Setores',
									icon: <TbSitemap size={20} />,
									command: () => alterarRota('/setores'),
									isValid: usuarioPossuiPermissao(recursos.SETORES, permissoes.VISUALIZAR),
								},
								{
									label: 'Técnicos',
									icon: <MdOutlineEngineering size={20} />,
									command: () => alterarRota('/tecnicos'),
									isValid: usuarioPossuiPermissao(recursos.TECNICOS, permissoes.VISUALIZAR),
								},
								{
									label: 'Marca',
									icon: <AiOutlineTags size={20} />,
									command: () => alterarRota('/marca'),
									isValid: usuarioPossuiPermissao(recursos.CADASTROS_MARCAS, permissoes.VISUALIZAR),
								},
							],
						}
					: {
							label: 'Setores',
							icon: <TbSitemap size={20} />,
							command: () => alterarRota('/setores'),
							isValid: usuarioPossuiPermissao(recursos.SETORES, permissoes.VISUALIZAR),
						},
			],
		},
		{
			label: 'Compras',
			icon: <Icon icon="icon-park-outline:buy" width={21} style={{ marginRight: '4px' }} />,
			isValid:
				usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.VISUALIZAR) ||
				usuarioPossuiPermissao(recursos.COMPRAS_MANIFESTACAO, permissoes.VISUALIZAR),
			items: [
				{
					label: 'MD-e',
					icon: <GrDocumentDownload size={20} />,
					command: () => alterarRota('/manifestacao_destinatario'),
					isValid: usuarioPossuiPermissao(recursos.COMPRAS_MANIFESTACAO, permissoes.VISUALIZAR),
				},
				{
					label: 'Nota de entrada',
					icon: <GrDocumentText size={20} />,
					command: () => alterarRota('/nota_entrada'),
					isValid: usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.VISUALIZAR),
				},
			],
		},
		{
			label: 'Estoque',
			icon: <BsBox2 size={20} style={{ marginRight: '5px' }} />,
			isValid: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.VISUALIZAR),
			items: [
				{
					label: 'Movimentações',
					icon: <FaDolly size={20} />,
					command: () => alterarRota('/movimentacoes_estoque'),
					isValid: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.VISUALIZAR),
				},
				{
					label: 'Relatórios',
					icon: <FaPrint size={20} />,
					command: () => alterarRota('/relatorios_estoque'),
					isValid: usuarioPossuiPermissao(recursos.ESTOQUE_RELATORIOS, permissoes.VISUALIZAR),
				},
			],
		},
		{
			label: 'Vendas',
			icon: <FaRegHandshake size={20} style={{ marginRight: '5px' }} />,
			isValid:
				usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR) ||
				usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.VISUALIZAR),
			items: [
				{
					label: 'Orçamentos',
					icon: <AiOutlineFileAdd size={22} style={{ marginRight: '4px' }} />,
					command: () => alterarRota('/orcamento'),
					isValid:
						usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR) &&
						buscarDadosLoginLocalStorage().organizacao?.aplicacao !== 'GESTOR_MEI',
				},
				{
					label: 'Pedidos de venda',
					icon: <AiOutlineShoppingCart size={22} style={{ marginRight: '3px' }} />,
					command: () => alterarRota('/pedido_venda'),
					isValid: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR),
				},
				{
					label: 'NF-e',
					title: 'Nota fiscal eletrônica',
					icon: <GrDocumentText size={20} style={{ marginLeft: '2', marginRight: '3px' }} />,
					command: () => alterarRota('/nfes'),
					isValid: usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.VISUALIZAR),
				},
				{
					label: 'NFC-e',
					title: 'Nota fiscal de consumidor',
					icon: <TiPrinter size={25} />,
					command: () => alterarRota('/nfces/cadastro'),
					isValid: usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.VISUALIZAR),
				},
				{
					label: 'Relatórios',
					icon: <FaPrint size={20} />,
					command: () => alterarRota('/relatorios_vendas'),
					isValid: usuarioPossuiPermissao(recursos.VENDAS_RELATORIOS, permissoes.VISUALIZAR),
				},
			],
		},
		usuarioPossuiModulo(modulos.SERVICOS)
			? {
					label: 'Serviços',
					icon: <IoHammerOutline size={22} style={{ marginRight: '3px' }} />,
					isValid:
						usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.VISUALIZAR) ||
						usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.VISUALIZAR),
					items: [
						{
							label: 'Ordem de serviço',
							icon: <GrHostMaintenance size={20} style={{ marginLeft: '2px', marginRight: '5px' }} />,
							command: () => alterarRota('/ordem_servico'),
							isValid: usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.VISUALIZAR),
						},
						{
							label: 'NFS-e',
							title: 'Nota fiscal de serviço eletrônica',
							icon: <TbFileInvoice size={24} style={{ marginRight: '3px' }} />,
							command: () => alterarRota('/nfses'),
							isValid: usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.VISUALIZAR),
						},
						{
							label: 'Relatórios',
							icon: <FaPrint size={20} />,
							command: () => alterarRota('/relatorios_servicos'),
							isValid: usuarioPossuiPermissao(recursos.SERVICOS_RELATORIOS, permissoes.VISUALIZAR),
						},
					],
				}
			: {},

		usuarioPossuiModulo(modulos.TRANSPORTE)
			? {
					label: 'Transporte',
					icon: <Icon icon="mdi:truck-fast-outline" width={20} />,
					isValid: usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.VISUALIZAR),
					items: [
						{
							label: 'CT-e',
							icon: <GrDocumentText size={20} style={{ marginLeft: '2', marginRight: '3px' }} />,
							command: () => alterarRota('/ctes'),
							isValid: usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.VISUALIZAR),
						},
						{
							label: 'MDF-e',
							icon: <GrDocumentText size={20} style={{ marginLeft: '2', marginRight: '3px' }} />,
							command: () => alterarRota('/mdfes'),
							isValid: usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.VISUALIZAR),
						},
						{
							label: 'Relatórios',
							icon: <FaPrint size={20} />,
							command: () => alterarRota('/relatorios_transporte'),
							isValid: usuarioPossuiPermissao(recursos.TRANSPORTE_RELATORIOS, permissoes.VISUALIZAR),
						},
					],
				}
			: {},

		{
			label: 'Finanças',
			icon: <i className="fa fa-money" style={{ marginRight: '5px' }} />,
			isValid: true,
			items: [
				{
					label: 'Contas a pagar',
					icon: <GiPayMoney size={20} />,
					command: () => alterarRota('/contas_pagar'),
					isValid: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR),
				},
				{
					label: 'Contas a receber',
					icon: <GiReceiveMoney size={20} />,
					command: () => alterarRota('/contas_receber'),
					isValid: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR),
				},
				{
					label: 'Movimentações',
					icon: <IoLogoUsd size={20} />,
					command: () => alterarRota('/movimentacoes'),
					isValid: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.VISUALIZAR),
				},
				{
					label: 'Boletos',
					icon: <ImFileText2 size={20} />,
					command: () => alterarRota('/boletos'),
					isValid: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR),
				},
				{
					label: 'Pix cobrança',
					icon: <FaQrcode size={20} />,
					command: () => alterarRota('/pix_cobranca'),
					isValid: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR),
				},
				{
					label: 'Relatórios',
					icon: <FaPrint size={20} />,
					command: () => alterarRota('/relatorios_financas'),
					isValid: usuarioPossuiPermissao(recursos.FINANCAS_RELATORIOS, permissoes.VISUALIZAR),
				},
			],
		},
		{
			label: 'Controladoria',
			icon: <BiBriefcase size={20} style={{ marginRight: '4px' }} />,
			validate: false,
			items: [
				{
					label: 'SPED',
					icon: <img alt="Icon SPED" style={{ width: '20px' }} src={IconSPED} />,
					command: () => alterarRota('/sped'),
					isValid: usuarioPossuiPermissao(recursos.CONTROLADORIA_SPED, permissoes.VISUALIZAR),
				},
			],
		},
		{
			label: 'Documentos',
			icon: <AiOutlineFileText size={20} style={{ marginRight: '3px' }} />,
			validate: false,
			items: [
				{
					label: 'Assinatura',
					icon: <AiOutlineFileDone size={20} />,
					command: () => alterarRota('/documentos/assinatura'),
					isValid: usuarioPossuiPermissao(recursos.DOCUMENTOS_ASSINATURA, permissoes.VISUALIZAR),
				},
				{
					label: 'Gestão de documentos',
					icon: <AiOutlineFileSearch size={20} />,
					command: () => alterarRota('/documentos/gestao_documentos'),
					isValid: usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.VISUALIZAR),
				},
				{
					label: 'Relatórios',
					icon: <FaPrint size={20} />,
					command: () => alterarRota('/relatorios_documentos'),
					isValid: usuarioPossuiPermissao(recursos.DOCUMENTOS_RELATORIOS, permissoes.VISUALIZAR),
				},
			],
		},
		{
			label: 'Configurações',
			icon: <VscSettingsGear size={20} style={{ marginRight: '3px' }} />,
			validate: false,
			items: [
				{
					label: 'Configurações gerais',
					icon: <VscSettings size={20} style={{ marginRight: '1px' }} />,
					command: () => alterarRota('/configuracoes_gerais'),
					validate: false,
				},
				{
					label: 'Usuários',
					icon: <BsPerson size={22} />,
					command: () => alterarRota('/usuarios'),
					isValid: usuarioPossuiPermissao(recursos.USUARIOS, permissoes.VISUALIZAR),
				},
				{
					label: 'Permissões do usuário',
					icon: <BsPersonLock size={22} />,
					command: () => alterarRota('/papeis'),
					isValid: usuarioPossuiPermissao(recursos.PAPEIS, permissoes.VISUALIZAR),
				},
			],
		},
	];
	return menu;
}
