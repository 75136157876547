export const helpMessage = {
    data: 'Data de referência do inventário',    
}

export const opcoesCodigo = [
    { label: 'Código NCM', value: 'NCM'},
    { label: 'Código produto', value: 'CODIGO'},
    { label: 'Código referência', value: 'REFERENCIA' },
];

export const opcoesPreco = [
    { label: 'Último custo', value: 'ULTIMO_CUSTO'},
    { label: 'Custo médio', value: 'CUSTO_MEDIO'},
    { label: 'Preço custo', value: 'PRECO_CUSTO'},  
    { label: 'Preço venda', value: 'PRECO_VENDA'},
];

export const opcoesSaldo = [
    { label: 'Todos', value: 'TODOS'},
    { label: 'Positivos', value: 'POSITIVOS'},
    { label: 'Negativos', value: 'NEGATIVOS'},
    { label: 'Zerados', value: 'ZERADOS'},
    { label: 'Negativos/zerados', value: 'NEGATIVOS_E_ZERADOS'},
    { label: 'Positivos/zerados', value: 'POSITIVOS_E_ZERADOS'},
];

export const opcoesOrdenacao = [
    { label: 'Código produto', value: 'CODIGO'},
    { label: 'Nome produto', value: 'NOME'}
];