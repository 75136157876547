import { Fieldset as InternalFieldSet } from 'primereact/fieldset';
import propTypes from 'prop-types';

export default function Fieldset(props) {
	const { hidden, podeVisualizar, style, legend } = props;
	function getStyle() {
		if (hidden || podeVisualizar === false) {
			return { display: 'none', ...style };
		} else return style;
	}

	return (
		<div style={getStyle()}>
			<InternalFieldSet {...props} legend={legend} />
		</div>
	);
}

Fieldset.propTypes = {
	/** Identificador do componente */
	id: propTypes.string,
	/** Diz se o usuário possui permissão de inserir */
	podeVisualizar: propTypes.bool,
	/** Esconde o componente */
	hidden: propTypes.bool,
	/** Estilo do componente */
	style: propTypes.any,
};
