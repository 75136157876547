import { Paginator } from 'primereact/paginator';
import { connect } from 'react-redux';
import Col from '../Col';

function Paginacao(props) {
  const { totalElements, rows, page, onPageChange, isMobile } = props;

  return (
    <Col
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: isMobile ? 'column' : 'row',
      }}
    >
      <Paginator
        className="paginator"
        rowsPerPageOptions={[10, 25, 50]}
        totalRecords={totalElements}
        rows={rows}
        first={rows * page}
        onPageChange={onPageChange}
        dropdownAppendTo="self"
      />
      {totalElements > 0 ? (
        <span style={{ whiteSpace: 'nowrap' }}>{`${totalElements} registro(s) encontrado(s)`}</span>
      ) : null}
    </Col>
  );
}

const mapStateToProps = state => ({
  isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Paginacao);
