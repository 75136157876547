import { useEffect, useState } from 'react';
import { useFormikContext, withFormik } from 'formik';

import { buscarDadosLoginLocalStorage, getSafeValue } from 'Common';
import { Form, FormActions, AutoProgressBar, Modal, FormContent, If, Message } from 'components';

import { ActionButtons } from './components/ActionButtons';
import { CamposPrincipais } from './components/CamposPrincipais';
import { Tabs } from './components/Tabs';

function ModalProdutoImpl({
	visibleModalProduto,
	onHide,
	onChangeProduto,
	produtos,
	indexProduto,
	disabled,
	isMobile,
	isTablet,
	informacoesPermissoes,
	consumidorFinal,
	tipoOperacao,
	finalidadeDocumento,
	pessoa,
	montarProduto,
	setFieldValueProduto,
	modulo,
	setAtualizarTotal,
	utilizaTabelaPreco,
	calcularValorVenda,
	tabelaPreco,
	calcularPercentualAdicionalTabelaPreco,
}) {
	const { handleReset, dirty, values, submitCount, errors, setFieldValue } = useFormikContext();
	const [showMessage, setShowMessage] = useState(false);
	const [atualizarSubTotal, setAtualizarSubTotal] = useState(false);

	const filialConectada = buscarDadosLoginLocalStorage()?.filialConectada;
	const decimaisQtd = filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
	const decimaisPreco = filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;
	const utilizaTabelaPrecoDescontoQuantidade = filialConectada?.parametrosVendas?.utilizaTabelaPrecoDescontoQuantidade;

	useEffect(() => {
		if (atualizarSubTotal) {
			setFieldValue(
				'baseCalculo',
				parseFloat(
					(
						getSafeValue(values.quantidade) * getSafeValue(values.valor) +
						getSafeValue(values.seguro) +
						getSafeValue(values.frete) +
						getSafeValue(values.acessorias) -
						getSafeValue(values.desconto)
					).toFixed(2)
				)
			);
			atualizaSubTotal();
			setAtualizarSubTotal(false);
			setAtualizarTotal(true);
		}
	}, [atualizarSubTotal]);

	useEffect(() => {
		const fieldsToValidate = ['cstCsosn', 'origemProduto', 'cstIpi', 'enquadramentoIpi', 'cstPis', 'cstCofins'];

		Object.keys(errors)?.some((key) => fieldsToValidate.indexOf(key) >= 0)
			? setShowMessage(true)
			: setShowMessage(false);
	}, [errors]);

	function atualizaSubTotal() {
		const subtotal = parseFloat(
			getSafeValue(values?.quantidade) * getSafeValue(values?.valor) +
				getSafeValue(values?.seguro) +
				getSafeValue(values?.frete) +
				getSafeValue(values?.acessorias) +
				getSafeValue(values?.tributos?.icms?.valorIcmsSt) +
				getSafeValue(values?.tributos?.ipi?.valorIpi) -
				getSafeValue(values?.desconto).toFixed(2)
		);

		const comissaoValor = values.comissaoPercentual
			? parseFloat((values.subtotal * (values.comissaoPercentual / 100)).toFixed(2))
			: 0;

		setFieldValue('subtotal', subtotal);
		setFieldValue('comissaoValor', comissaoValor);
	}

	function montarTextoMessage() {
		return (
			<span>
				{Object.entries(errors).map(([field]) => {
					switch (field) {
						case 'cstCsosn':
							return formatField('CST ICMS');
						case 'origemProduto':
							return formatField('Origem do Produto');
						case 'enquadramentoIpi':
							return formatField('Enquadramento IPI');
						case 'cstIpi':
							return formatField('CST IPI');
						case 'cstPis':
							return formatField('CST PIS');
						case 'cstCofins':
							return formatField('CST COFINS');
						default:
							return null;
					}
				})}
			</span>
		);
	}

	function formatField(field) {
		return <div key={field}>{field} - Obrigatório</div>;
	}

	return (
		<Modal
			header="Dados do produto"
			visible={visibleModalProduto}
			onHide={() => {
				if (dirty) {
					handleReset();
				} else {
					onHide();
				}
			}}
			styleModal={{
				minWidth: '60%',
			}}
		>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ActionButtons
						onChangeProduto={onChangeProduto}
						indexProduto={indexProduto}
						disabled={disabled}
						informacoesPermissoes={informacoesPermissoes}
						pessoa={pessoa}
						onHide={onHide}
						modulo={modulo}
						setFieldValueProduto={setFieldValueProduto}
					/>
				</FormActions>
				<FormContent>
					<If test={showMessage && submitCount}>
						<Message severity="error" text={montarTextoMessage()} />
					</If>
					<CamposPrincipais
						decimaisQtd={decimaisQtd}
						decimaisPreco={decimaisPreco}
						disabled={disabled}
						isMobile={isMobile}
						isTablet={isTablet}
						consumidorFinal={consumidorFinal}
						tipoOperacao={tipoOperacao}
						informacoesPermissoes={informacoesPermissoes}
						montarProduto={montarProduto}
						pessoa={pessoa}
						setIsAtualizarTotal={setAtualizarSubTotal}
						utilizaTabelaPreco={utilizaTabelaPreco}
						tabelaPreco={tabelaPreco}
						produtoForm={produtos[indexProduto]}
						calcularValorVenda={calcularValorVenda}
						calcularPercentualAdicionalTabelaPreco={calcularPercentualAdicionalTabelaPreco}
						utilizaTabelaPrecoDescontoQuantidade={utilizaTabelaPrecoDescontoQuantidade}
					/>
					<Tabs
						informacoesPermissoes={informacoesPermissoes}
						disabled={disabled}
						finalidadeDocumento={finalidadeDocumento}
						tipoOperacao={tipoOperacao}
						pessoa={pessoa}
						setAtualizarSubTotal={setAtualizarSubTotal}
					/>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalProduto = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,

	mapPropsToValues(props) {
		if (props.produtos[props.indexProduto]) {
			return props.produtos[props.indexProduto];
		}
		return null;
	},

	handleSubmit: () => {},
})(ModalProdutoImpl);

export { ModalProduto };
