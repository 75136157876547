import { Field } from 'formik';
import { helpOrganizacoesForm } from '../../Help';

import { FormGroup, InputField, SingleSelectMunicipio, SingleSelectPais, TextArea, InputCep, Grid } from 'components';

const idPaisBrasil = '34e63b2e-c596-f34f-824d-bfd27eb62fa8';

export function renderizarFieldsEndereco(values, setFieldValue, informacoesPermissoes, styleInativado) {
	return (
		<FormGroup>
			<Grid>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputCep}
					label="CEP"
					name="cep"
					type="tel"
					value={values.cep}
					onChange={e => {
						if (e.pesquisou) {
							e.bairro && setFieldValue('bairro', e.bairro);
							e.complemento && setFieldValue('complemento', e.complemento);
							e.logradouro && setFieldValue('logradouro', e.logradouro);
							if (e.logradouro && e.logradouro != '') {
								document.getElementById('inputNumeroEndereco').focus();
							} else {
								document.getElementById('inputLogradouroEndereco').focus();
							}
							e.municipio &&
								setFieldValue('municipio', {
									label: e.municipio.nome + ' - ' + e.municipio.estado.sigla,
									value: e.municipio.id,
								});
							e.pais && setFieldValue('pais', { label: e.pais.nome, value: e.pais.id });
						} else {
							setFieldValue('cep', e.value);
						}
					}}
					id="AgentesAutorizadosInputCep"
					style={values.situacao == 'INATIVO' ? styleInativado : null}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Logradouro"
					name="logradouro"
					id="inputLogradouroEndereco"
					size={255}
					component={InputField}
					style={values.situacao == 'INATIVO' ? styleInativado : null}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Número"
					id="inputNumeroEndereco"
					size={10}
					name="numero"
					component={InputField}
					style={values.situacao == 'INATIVO' ? styleInativado : null}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Bairro"
					name="bairro"
					size={60}
					component={InputField}
					style={values.situacao == 'INATIVO' ? styleInativado : null}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Complemento"
					name="complemento"
					size={255}
					component={InputField}
					style={values.situacao == 'INATIVO' ? styleInativado : null}
					{...informacoesPermissoes}
				/>

				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="País"
					name="pais"
					component={SingleSelectPais}
					value={values.pais}
					onChange={e => setFieldValue('pais', e)}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Município"
					name="municipio"
					component={SingleSelectMunicipio}
					value={values.municipio}
					onChange={e => setFieldValue('municipio', e)}
					hidden={(values.pais ? values.pais.value : null) !== idPaisBrasil}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					type="text"
					label="Estado exterior"
					helpMessage={helpOrganizacoesForm.estadoExterior}
					name="estadoExterior"
					value={values.estadoExterior}
					size={128}
					hidden={(values.pais ? values.pais.value : null) === idPaisBrasil}
					style={values.situacao == 'INATIVO' ? styleInativado : null}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					type="text"
					label="Município exterior"
					helpMessage={helpOrganizacoesForm.municipioExterior}
					name="municipioExterior"
					value={values.municipioExterior}
					size={128}
					hidden={(values.pais ? values.pais.value : null) === idPaisBrasil}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={TextArea}
					placeholder="Escreva sua observação aqui"
					label="Observação"
					name="observacao"
					maxLength={512}
					onChange={e => setFieldValue('observacao', e.target.value)}
					style={values.situacao == 'INATIVO' ? styleInativado : null}
					{...informacoesPermissoes}
				/>
			</Grid>
		</FormGroup>
	);
}
