export function converterTributacaoEstadualParaFormulario(tributacao) {
  return {
    id: tributacao.id,
    codigo: tributacao.codigo,
    descricao: tributacao.descricao,
    tributacaoFederal: converterTributacaoFederalParaFormulario(tributacao.tributacaoFederal),
    situacao: tributacao.situacao,
    favorito: tributacao.favorito,
    tributacoesUf: converterTributacoesUfParaFormulario(tributacao.tributacoesUf),
  };
}

function converterTributacaoFederalParaFormulario(tributacaofederal) {
  if (tributacaofederal && tributacaofederal.id) {
    return {
      label: `${tributacaofederal.codigo} - ${tributacaofederal.descricao}`,
      value: tributacaofederal.id,
    };
  }
  return tributacaofederal?.value ? { id: tributacaofederal.value } : null;
}
function converterTributacoesUfParaFormulario(tributacoesUf) {
  return tributacoesUf.map(item => {
    return {
      id: item.id,
      diferimento: item.diferimento ?? 0,
      fundoCombatePobreza: item.fundoCombatePobreza ?? 0,
      icmsBase: item.icmsBase ?? 100,
      icmsPercentual: item.icmsPercentual ?? 0,
      icmsStBase: item.icmsStBase ?? 100,
      icmsStPercentual: item.icmsStPercentual ?? 0,
      icmsStRetidoBase: item.icmsStRetidoBase ?? 100,
      icmsStRetidoPercentual: item.icmsStRetidoPercentual ?? 0,
      icmsMonofasicoRetido: item.icmsMonofasicoRetido ?? 0,
      modalidadeCalculoSt: item.modalidadeCalculoSt ?? 'MARGEM_VALOR_AGREGADO',
      mvaPauta: item.mvaPauta ?? 0,
      estado: converterEstadoParaFormulario(item.estado),
      cfop: converterCfopParaFormulario(item.cfop),
      cstCsosn: converterCstCsosnParaFormulario(item.cstCsosn),
      codigoBeneficiario: item.codigoBeneficiario ?? null,
    };
  });
}

function converterEstadoParaFormulario(estado) {
  if (estado && estado?.id) {
    return {
      label: `${estado.nome} - ${estado.sigla}`,
      value: estado.id,
      registro: estado,
    };
  }
}

function converterCfopParaFormulario(cfop) {
  if (cfop && cfop?.id) {
    return {
      label: cfop.codigo + ' - ' + cfop.descricao,
      value: cfop.id,
      registro: cfop,
    };
  }
}

function converterCstCsosnParaFormulario(cstCsosn) {
  if (cstCsosn && cstCsosn?.id) {
    return {
      label: cstCsosn.codigo + ' - ' + cstCsosn.descricao,
      value: cstCsosn.id,
      registro: cstCsosn,
    };
  }
}

export function converterTributacaoEstadualParaApi(tributacao) {
  return {
    id: tributacao.id,
    codigo: tributacao.codigo,
    descricao: tributacao.descricao,
    tributacaoFederal: converterTributacaoFederalParaApi(tributacao.tributacaoFederal),
    situacao: tributacao.situacao,
    favorito: tributacao.favorito,
    tributacoesUf: converterTributacoesUfParaApi(tributacao.tributacoesUf),
  };
}

function converterTributacaoFederalParaApi(tributacaofederal) {
  return tributacaofederal?.value ? { id: tributacaofederal.value } : null;
}

function converterTributacoesUfParaApi(tributacoesUf) {
  return tributacoesUf.map(item => {
    return {
      id: item.id ?? null,
      diferimento: item.diferimento ?? 0,
      fundoCombatePobreza: item.fundoCombatePobreza ?? 0,
      icmsBase: item.icmsBase ?? 0,
      icmsPercentual: item.icmsPercentual ?? 0,
      icmsStBase: item.icmsStBase ?? 0,
      icmsStPercentual: item.icmsStPercentual ?? 0,
      icmsStRetidoBase: item.icmsStRetidoBase ?? 0,
      icmsStRetidoPercentual: item.icmsStRetidoPercentual ?? 0,
      icmsMonofasicoRetido: item.icmsMonofasicoRetido ?? 0,
      modalidadeCalculoSt: item.modalidadeCalculoSt ?? 0,
      mvaPauta: item.mvaPauta ?? 0,
      estado: converterEstadoParaApi(item.estado),
      cfop: converterCfopParaApi(item.cfop),
      cstCsosn: converterCstCsosnParaApi(item.cstCsosn),
      codigoBeneficiario: item.codigoBeneficiario ?? null,
    };
  });
}

function converterCfopParaApi(cfop) {
  return cfop?.value ? { id: cfop.value } : null;
}

function converterCstCsosnParaApi(cstCsosn) {
  return cstCsosn?.value ? { id: cstCsosn.value } : null;
}

function converterEstadoParaApi(estado) {
  return estado?.value ? { id: estado.value } : null;
}
