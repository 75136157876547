import { useEffect, useState } from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useFormikContext, withFormik } from 'formik';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	mensagensDeValidacao,
	salvarConfiguracaoUsuario,
	validarUUID,
} from 'Common';
import { Form, FormActions, FormContent, Tutorial, tutorialStepsVendedor } from 'components';

import { asyncGetFiliaisDoUsuario } from 'views/configuracao/Usuarios/Form/Requests';

import { useContextVendedores } from '../Context';
import { getUsuarioPadrao, getVendedor } from '../Requests';
import ActionButtons from './Components/ActionButtons';
import { HeaderVendedores } from './Components/HeaderVendedores';
import { TabsVendedores } from './Components/TabsVendedores';
import { INITIAL_VALUES_VENDEDORES } from './Utils/constantes';
import { converterVendedorApiParaForm } from './Utils/vendedorConverter';

function VendedoresFormView({ hideModal, isModal, tipoVendedor }) {
	const { values, resetForm, errors, submitCount } = useFormikContext();
	const { idVendedor, setActiveTabIndex, usuarioPadrao, setUsuarioPadrao } = useContextVendedores();

	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_VENDEDOR));
	const [tutorialVisible, setTutorialVisible] = useState(false);

	useEffectOnce(() => {
		if (validarUUID(idVendedor) && !isModal) {
			buscarVendedor(idVendedor);
		} else {
			buscarFiliaisUsuario();
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_VENDEDOR, false, null, false);
		}

		if (!usuarioPadrao) {
			getUsuarioPadrao(({ data: response }) => {
				setUsuarioPadrao(response?.content[0]);
			});
		}

		setTimeout(() => {
			document.getElementById('InputNomeVendedor')?.focus();
		}, 500);
	});

	useUpdateEffect(() => {
		buscarVendedor(idVendedor);
	}, [idVendedor]);

	useEffect(() => {
		if (errors && Object.keys(errors)?.length > 0) {
			const hasErrorInTabRegioes = errors.regioesTabelaPrecos?.length > 0;

			if (hasErrorInTabRegioes) {
				setActiveTabIndex(1);
			}
		}
	}, [errors, submitCount]);

	function handleCallbackTutorial(e) {
		if (e.step.target === '.step-vendedor-regioes') {
			setActiveTabIndex(1);
		}

		if (e.status === 'finished') {
			setActiveTabIndex(0);
		}
	}

	function buscarVendedor(id = null) {
		if (!id) return;
		try {
			getVendedor(id, ({ data: response }) => {
				resetForm({
					values: converterVendedorApiParaForm(response),
				});
			});
		} catch (error) {
			console.error(error);
		}
	}

	function buscarFiliaisUsuario() {
		asyncGetFiliaisDoUsuario(({ data: body }) => {
			const filiaisUsuario = body.content;
			const filiaisConvertidas = [];

			filiaisUsuario?.forEach((filial) => {
				filiaisConvertidas.push({
					id: null,
					filial: {
						id: filial.id,
						codigo: filial.codigo,
						nome: filial.nome,
						nomeApresentacao: filial.nomeApresentacao,
					},
					tabelaPreco: null,
				});
			});
			resetForm({ values: { ...values, filiais: filiaisConvertidas } });
		});
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsVendedor}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
				disableScrolling
				callback={(e) => {
					handleCallbackTutorial(e);
				}}
			/>
			<Form header="Cadastro de vendedor" className="card-default screen-max-width" isModal={isModal}>
				<FormActions className="screen-max-width">
					<ActionButtons hideModal={hideModal} isModal={isModal} />
				</FormActions>
				<FormContent>
					<HeaderVendedores tipoVendedor={tipoVendedor} />
					<TabsVendedores isModal={isModal} />
				</FormContent>
			</Form>
		</>
	);
}

const VendedoresForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (!props.isModal) {
			return INITIAL_VALUES_VENDEDORES;
		} else {
			return { ...INITIAL_VALUES_VENDEDORES, tipo: props.tipoVendedor };
		}
	},

	validate: (values) => {
		const errors = {};
		const errorsRegioesTabelaPrecos = [];

		if (!values.nome) {
			errors.nome = 'Obrigatório';
		}

		if (!values.usuario) {
			errors.usuario = mensagensDeValidacao.OBRIGATORIO;
		}

		values.regioesTabelaPrecos?.length > 0 &&
			values.regioesTabelaPrecos?.forEach((item, index) => {
				if (item.regiao === null) {
					errorsRegioesTabelaPrecos[index] = {
						...errorsRegioesTabelaPrecos[index],
						regiao: mensagensDeValidacao.OBRIGATORIO,
					};
				}

				if (item.tabelaPreco === null) {
					errorsRegioesTabelaPrecos[index] = {
						...errorsRegioesTabelaPrecos[index],
						tabelaPreco: mensagensDeValidacao.OBRIGATORIO,
					};
				}
			});

		if (errorsRegioesTabelaPrecos?.length > 0) {
			errors.regioesTabelaPrecos = errorsRegioesTabelaPrecos;
		}

		return errors;
	},

	handleSubmit: () => {},
})(VendedoresFormView);

export { VendedoresForm };
