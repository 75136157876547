import { buttonSendStyle } from '../../../Styles';
import { copiarParaAreaTransferencia } from 'Common';
import { Col, FormGroup, Grid, Button, notify } from 'components';

export default function FieldsLinkModulosPersonalizados(props) {
	const { values } = props;

	function criarLinkParaCriacaoDeContaDocs() {
		const isDev = Boolean(window.location.href.split('host')[0] === 'http://local');
		let link = '';
		if (!isDev) {
			link = `https://docsdigitais.com/#/registro?agenteId=${values.id}`;
		} else {
			link = `${window.location.href.split('/#/')[0]}/#/registro?agenteId=${values.id}`;
		}

		copiarParaAreaTransferencia(link, () => {
			notify('Link copiado para área de transferência');
		});
	}

	function criarLinkParaCriacaoDeContaGestor() {
		const isDev = Boolean(window.location.href.split('host')[0] === 'http://local');
		let link = '';
		if (!isDev) {
			link = `https://msysgestor.com/#/registro?agenteId=${values.id}`;
		} else {
			link = `${window.location.href.split('/#/')[0]}/#/registro?agenteId=${values.id}`;
		}

		copiarParaAreaTransferencia(link, () => {
			notify('Link copiado para área de transferência');
		});
	}

	function criarLinkParaCriacaoDeContaGestorMei() {
		const isDev = Boolean(window.location.href.split('host')[0] === 'http://local');
		let link = '';
		if (!isDev) {
			link = `https://gestormei.com/#/registro?agenteId=${values.id}`;
		} else {
			link = `${window.location.href.split('/#/')[0]}/#/registro?agenteId=${values.id}`;
		}

		copiarParaAreaTransferencia(link, () => {
			notify('Link copiado para área de transferência');
		});
	}

	return (
		<FormGroup>
			<Grid style={{ marginTop: '10px' }}>
				<Col style={{ display: 'flex', justifyContent: 'center' }}>
					<Col sm="12" md="12" lg="12" xl="12">
						<Button
							color="success"
							label={'Copiar o link de cadastro personalizado para DOCs Digitais'}
							title={'Clique nesse botão para gerar um link personalizado'}
							style={buttonSendStyle}
							onClick={() => criarLinkParaCriacaoDeContaDocs()}
						/>
					</Col>
				</Col>

				<Col style={{ display: 'flex', justifyContent: 'center' }}>
					<Col sm="12" md="12" lg="12" xl="12">
						<Button
							color="success"
							label={'Copiar o link de cadastro personalizado para o Msys Gestor'}
							title={'Clique nesse botão para gerar um link personalizado'}
							style={buttonSendStyle}
							onClick={() => criarLinkParaCriacaoDeContaGestor()}
						/>
					</Col>
				</Col>

				<Col style={{ display: 'flex', justifyContent: 'center' }}>
					<Col sm="12" md="12" lg="12" xl="12">
						<Button
							color="success"
							label={'Copiar o link de cadastro personalizado para o Gestor MEI'}
							title={'Clique nesse botão para gerar um link personalizado'}
							style={buttonSendStyle}
							onClick={() => criarLinkParaCriacaoDeContaGestorMei()}
						/>
					</Col>
				</Col>
			</Grid>
		</FormGroup>
	);
}
