import { isValidEmail } from '@brazilian-utils/brazilian-utils';
import { Field, withFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { buscarDadosLoginLocalStorage, mensagensDeValidacao } from '../../../../../../../Common';
import {
	Button,
	ButtonCancelar,
	estadosBotaoCancelar,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputField,
	ModalLoadingTransmissao,
	TextArea,
} from '../../../../../../../components';
import { validarFormulario } from '../../../../../../Util';
import { asyncPostEnviarCobrancasPorEmail } from '../../../../Requests';
import { converterCobrancasEmailParaApi } from '../../../../Util/cobrancaConverter';

const initialValue = (cobrancas, dadosPessoaParaEmail, dadosFilial) => ({
	assunto: `Cobranças - ${dadosFilial?.nome}`,
	corpoEmail: montarCorpoEmail(dadosPessoaParaEmail, cobrancas),
	destinatarios: dadosPessoaParaEmail?.pessoaEmail,
});

function montarCorpoEmail(dadosPessoaParaEmail, cobrancas) {
	let textoCobrancas = '';
	cobrancas?.forEach(cobranca => (textoCobrancas = textoCobrancas + ` ${cobranca.identificador},`));
	textoCobrancas = textoCobrancas.replace(/,\s*$/, '.').replace(/,([^,]*)$/, ' e$1');

	let corpoEmail = `Olá ${dadosPessoaParaEmail?.pessoaNome}, ${
		cobrancas.length > 1
			? ` segue cobranças referentes aos títulos:${textoCobrancas}`
			: ` segue cobrança referente ao título:${textoCobrancas}`
	}`;

	return corpoEmail;
}

function FieldsEnviarPorEmail(props) {
	const { onHide, setFieldValue, cobrancasIds, values } = props;

	const [exibirLoadingEnvioEmail, setExibirLoadingEnvioEmail] = useState(false);

	async function onClickEnviarEmail() {
		props.handleSubmit();

		if (await validarFormulario(props)) {
			setExibirLoadingEnvioEmail(true);
			asyncPostEnviarCobrancasPorEmail(
				converterCobrancasEmailParaApi({ ...values, contaReceberIds: cobrancasIds }),
				() => {
					setExibirLoadingEnvioEmail(false);
					onHide();
				},
				() => {
					setExibirLoadingEnvioEmail(false);
				}
			);
		}
	}

	return (
		<Form>
			<FormActions style={{ marginBottom: '10px' }}>
				<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
				<Button
					label="Enviar por e-mail"
					icon="fa fa-send"
					onClick={onClickEnviarEmail}
					style={{ margin: '5px' }}
				/>
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={InputField}
						label="Destinatário(s)"
						name="destinatarios"
						helpMessage="Destinatários que irão receber o e-mail, podem ser informados vários e-mails separados por vírgula"
						obrigatorio
						values={values.destinatarios}
						size={255}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={InputField}
						label="Assunto"
						obrigatorio
						name="assunto"
						values={values.assunto}
						helpMessage="Assunto (título) do e-mail que será encaminhado para o(s) destinatário(s)"
						size={255}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={TextArea}
						label="Texto do e-mail"
						obrigatorio
						placeholder="Escreva o texto do e-mail aqui..."
						name="corpoEmail"
						values={values.corpoEmail}
						helpMessage="Texto do e-mail que será encaminhado para o(s) destinatário(s)"
						rows={5}
						onChange={e => setFieldValue('corpoEmail', e.target.value)}
					/>
				</Grid>
			</FormContent>
			<If test={exibirLoadingEnvioEmail}>
				<ModalLoadingTransmissao
					visible={exibirLoadingEnvioEmail}
					message="Gerando impressão e enviando por e-mail..."
				/>
			</If>
		</Form>
	);
}

FieldsEnviarPorEmail = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return {
			...initialValue(props.cobrancas, props.dadosPessoaParaEmail, buscarDadosLoginLocalStorage()?.filialConectada),
		};
	},

	validate(values) {
		let errors = {};

		if (values.destinatarios) {
			const arrayDestinatarios = values.destinatarios
				.split(',')
				.map(item => item.trim())
				.filter(item => item.trim() !== '');

			arrayDestinatarios.forEach(destinatario => {
				if (!isValidEmail(destinatario)) {
					errors.destinatarios = `O e-mail ${destinatario} não é válido`;
				}
			});
		} else {
			errors.destinatarios = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		assunto: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		corpoEmail: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(FieldsEnviarPorEmail);

export default FieldsEnviarPorEmail;
