import { If, Modal } from 'components';
import { connect } from 'react-redux';
import { Planos } from '../..';
import PlanosProvider from '../../Context';

function ModalPlanosImpl({ visible, onHide, mensagemErroPlanoViolado }) {
	return (
		<Modal header="Seu plano chegou ao limite!" visible={visible} onHide={onHide} styleModal={{ maxWidth: '1200px' }}>
			<If test={visible}>
				<PlanosProvider>
					<Planos onHide={onHide} isModal mensagemAviso={mensagemErroPlanoViolado} />
				</PlanosProvider>
			</If>
		</Modal>
	);
}

const mapStateToProps = (state) => ({
	mensagemErroPlanoViolado: state.planoViolado.mensagemErroPlanoViolado,
});

export const ModalPlanos = connect(mapStateToProps)(ModalPlanosImpl);
