import { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { NenhumRegistroEncontrado } from '../../../../../../../../components';
import { converterNovaTributacaoParaFormulario } from '../../../../../Util/ncmConverter';

import './Styles/index.css';

export default function ListaModelo(props) {
	const { listaModelos, setFieldValue, onHide } = props;
	const [expandedRows, setExpandedRows] = useState(null);

	function modeloSelecionado(event) {
		let listaAtualizada = converterNovaTributacaoParaFormulario(event.data?.tributacoes);
		setFieldValue('tributacoes', listaAtualizada);
		onHide();
	}

	const rowExpansionTemplated = data => {
		return (
			<DataTable
				className="table modal-importar-modelo-ncm"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				responsive
				value={data.tributacoes}
				emptyMessage={<NenhumRegistroEncontrado />}
				style={{
					overflowY: 'scroll',
					maxHeight: '20rem',
					width: '100%',
				}}
			>
				<Column
					field="operacao"
					header="Operação Fiscal"
					name="operacao"
					body={row => `${row.codigoOperacao} - ${row.descricaoOperacao}`}
					style={{
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						wordBreak: 'break-all',
					}}
				/>
				<Column
					field="tributacao"
					header="Tributação Estadual"
					name="tributacao"
					body={row => `${row.codigoTributacao} - ${row.descricaoTributacao}`}
					style={{
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						wordBreak: 'break-all',
					}}
				/>
			</DataTable>
		);
	};

	return (
		<DataTable
			className="table modal-importar-modelo-ncm"
			rowClassName="table-row"
			cellClassName="table-row-cell"
			responsive
			value={listaModelos}
			emptyMessage={<NenhumRegistroEncontrado />}
			style={{
				overflowY: 'scroll',
				maxHeight: '20rem',
				width: '100%',
				cursor: 'pointer',
			}}
			onRowClick={event => modeloSelecionado(event)}
			onRowToggle={e => setExpandedRows(e.data)}
			rowExpansionTemplate={rowExpansionTemplated}
			expandedRows={expandedRows}
			dataKey="codigo"
		>
			<Column expander style={{ width: '3em' }} />
			<Column
				field="codigo"
				header="Código"
				name="codigo"
				body={row => row.codigo}
				sortable
				style={{
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					wordBreak: 'break-all',
					width: '8rem',
				}}
			/>
			<Column
				field="descricao"
				header="Descrição"
				name="descricao"
				body={row => row.descricao}
				sortable
				style={{
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					wordBreak: 'break-all',
				}}
			/>
		</DataTable>
	);
}
