import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar } from 'components';
import { useFormikContext } from 'formik';

function ActionButtons({ informacoesPermissoes, cancelar, salvar }) {
	const { dirty, values, isModal } = useFormikContext();

	return (
		<>
			<ButtonCancelar
				{...informacoesPermissoes}
				hidden={isModal && values.id && !dirty}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
			/>
			<ButtonSalvar {...informacoesPermissoes} disabled={!dirty} onClick={salvar} />
		</>
	);
}

export { ActionButtons };
