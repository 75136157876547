import { del, get, exibirToast } from '../../../../Common/Requisicoes';
import { services } from '../../../../Common/Constantes/api';

export async function asyncListarUsuarios(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function asyncDeleteUsuario(idCredencial, onSuccess, onError) {
	return await del(`${services.GESTOR}/v1/usuarios/${idCredencial}`, null, exibirToast(onSuccess, 'Usuário excluído com sucesso'), onError);
}
