import { useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { FaPrint, FaShareAlt } from 'react-icons/fa';
import { Menu } from 'primereact/menu';

import { DADOSLOCALSTORAGE, buscarItemLocalStorage, copiarParaAreaTransferencia, services } from 'Common';
import { If, confirm, notify } from 'components';

import {
	asyncImprimirOrcamentoOrdemServico,
	asyncImprimirOrdemServico,
	asyncImprimirOrdemServicoComprovante,
	asyncImprimirOrdemServicoExecucao,
	asyncPostAtualizarSituacaoOrcamentoOrdemServico,
} from '../../../Requests';

import { hasServico } from '../../../Util/funtions';
import { ORDEM_SERVICO_ORCAMENTO_SITUACAO, SITUACAO_ORDEM_SERVICO } from '../../../Util/constantes';

import { ModalCompartilharPorEmail } from './componentes/ModalCompartilharPorEmail';
import { ModalCompartilharPorWhatsapp } from './componentes/ModalCompartilharPorWhatsapp';

export function HeaderForm({ isMobile }) {
	const { values, dirty, resetForm } = useFormikContext();

	const [enviarPorEmailTipo, setEnviarPorEmailTipo] = useState('ORDEM');
	const [enviarPorWhatsappTipo, setEnviarPorWhatsappTipo] = useState('ORDEM');
	const [modalEnviarPorEmailVisible, setModalEnviarPorEmailVisible] = useState(false);
	const [modalEnviarPorWhatsappVisible, setModalEnviarPorWhatsappVisible] = useState(false);
	const [organizacaoId] = useState(buscarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin).organizacao.id);
	const menuCompartilhar = useRef(null);

	const menuImprimir = useRef(null);

	const disabled = dirty || !values.id;

	const styleButton = {
		padding: '0px 10px',
		color: '#333333',
		fontWeight: 'bold',
		fontSize: '15px',
		display: 'flex',
		alignItems: 'center',
		border: 'none',
		marginTop: isMobile ? '8px' : '0',
	};

	const menuCompartilharModel = [
		{
			label: 'Enviar orçamento por e-mail',
			icon: 'fa fa-send',
			command: () => {
				if (values.situacaoOrcamento === ORDEM_SERVICO_ORCAMENTO_SITUACAO.NAO_ENVIADO) {
					confirm(
						'Confirmação',
						'Deseja mudar a situação da ordem de serviço para aguardando aprovação?',
						async () => {
							const situacaoOrcamento = { situacaoOrcamento: ORDEM_SERVICO_ORCAMENTO_SITUACAO.AGUARDANDO_APROVACAO };
							await asyncPostAtualizarSituacaoOrcamentoOrdemServico(values.id, situacaoOrcamento, () => {
								notify('Ordem de serviço atualizada com sucesso');
								resetForm({
									values: { ...values, situacaoOrcamento: ORDEM_SERVICO_ORCAMENTO_SITUACAO.AGUARDANDO_APROVACAO },
								});
							});
							setEnviarPorEmailTipo('ORCAMENTO');
							setModalEnviarPorEmailVisible(true);
						},
						() => {
							setEnviarPorEmailTipo('ORCAMENTO');
							setModalEnviarPorEmailVisible(true);
						},
						'Sim',
						'Não'
					);
				} else {
					setEnviarPorEmailTipo('ORCAMENTO');
					setModalEnviarPorEmailVisible(true);
				}
			},
			disabled:
				values.situacao !== SITUACAO_ORDEM_SERVICO.PENDENTE ||
				values.situacaoOrcamento === ORDEM_SERVICO_ORCAMENTO_SITUACAO.REJEITADO,
		},
		{
			label: 'Enviar orçamento por Whatsapp',
			icon: 'fa fa-whatsapp',
			command: () => {
				if (values.situacaoOrcamento === ORDEM_SERVICO_ORCAMENTO_SITUACAO.NAO_ENVIADO) {
					confirm(
						'Confirmação',
						'Deseja mudar a situação da ordem de serviço para aguardando aprovação?',
						async () => {
							const situacaoOrcamento = { situacaoOrcamento: ORDEM_SERVICO_ORCAMENTO_SITUACAO.AGUARDANDO_APROVACAO };
							await asyncPostAtualizarSituacaoOrcamentoOrdemServico(values.id, situacaoOrcamento, () => {
								notify('Ordem de serviço atualizada com sucesso');
								resetForm({
									values: { ...values, situacaoOrcamento: ORDEM_SERVICO_ORCAMENTO_SITUACAO.AGUARDANDO_APROVACAO },
								});
							});
							setEnviarPorWhatsappTipo('ORCAMENTO');
							setModalEnviarPorWhatsappVisible(true);
						},
						() => {
							setEnviarPorWhatsappTipo('ORCAMENTO');
							setModalEnviarPorWhatsappVisible(true);
						},
						'Sim',
						'Não'
					);
				} else {
					setEnviarPorWhatsappTipo('ORCAMENTO');
					setModalEnviarPorWhatsappVisible(true);
				}
			},
			disabled:
				values.situacao !== SITUACAO_ORDEM_SERVICO.PENDENTE ||
				values.situacaoOrcamento === ORDEM_SERVICO_ORCAMENTO_SITUACAO.REJEITADO,
		},
		{
			separator: true,
		},
		{
			label: 'Enviar por e-mail',
			icon: 'fa fa-send',
			command: () => {
				setEnviarPorEmailTipo('ORDEM');
				setModalEnviarPorEmailVisible(true);
			},
		},
		{
			label: 'Enviar por Whatsapp',
			icon: 'fa fa-whatsapp',
			command: () => {
				setEnviarPorWhatsappTipo('ORDEM');
				setModalEnviarPorWhatsappVisible(true);
			},
		},
		{
			label: 'Copiar link compartilhável',
			icon: 'fa fa-link',
			command: copyLinkShareable,
		},
	];

	const menuImprimirModel = [
		{
			label: 'Imprimir comprovante',
			icon: 'fa fa-print',
			disabled: values.situacao !== SITUACAO_ORDEM_SERVICO.PENDENTE,
			command: () => handleClickImprimirComprovante(),
		},
		{
			label: 'Imprimir execução',
			icon: 'fa fa-print',
			disabled: values.situacao !== SITUACAO_ORDEM_SERVICO.PENDENTE,
			command: () => handleClickImprimirExecucao(),
		},
		{
			label: 'Imprimir orçamento',
			icon: 'fa fa-print',
			disabled:
				!hasServico(values.servicos) ||
				values.situacao !== SITUACAO_ORDEM_SERVICO.PENDENTE ||
				values.situacaoOrcamento === ORDEM_SERVICO_ORCAMENTO_SITUACAO.REJEITADO,
			command: () => handleClickImprimirOrcamentoOrdem(),
		},
		{
			label: 'Imprimir ordem',
			icon: 'fa fa-print',
			disabled: !hasServico(values.servicos),
			command: () => handleClickImprimirOrdem(),
		},
	];

	async function handleClickImprimirExecucao() {
		await asyncImprimirOrdemServicoExecucao(values.id, (document) => {
			const arquivoPDF = new Blob([document.data], {
				type: 'application/pdf',
			});
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const relatorio = window.open(arquivoURL);
			if (relatorio) {
				relatorio.onload = () => {
					setTimeout(() => {
						relatorio.document.title = `Execução - ordem de serviço Nº ${values.numero}`;
					}, 250);
				};
			}
		});
	}

	async function handleClickImprimirComprovante() {
		await asyncImprimirOrdemServicoComprovante(values.id, (document) => {
			const arquivoPDF = new Blob([document.data], {
				type: 'application/pdf',
			});
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const relatorio = window.open(arquivoURL);
			if (relatorio) {
				relatorio.onload = () => {
					setTimeout(() => {
						relatorio.document.title = `Comprovante - ordem de serviço Nº ${values.numero}`;
					}, 250);
				};
			}
		});
	}

	async function handleClickImprimirOrdem() {
		await asyncImprimirOrdemServico(values.id, organizacaoId, (document) => {
			const arquivoPDF = new Blob([document.data], {
				type: 'application/pdf',
			});
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const relatorio = window.open(arquivoURL);
			if (relatorio) {
				relatorio.onload = () => {
					setTimeout(() => {
						relatorio.document.title = `Ordem de serviço Nº ${values.numero}`;
					}, 250);
				};
			}
		});
	}

	async function handleClickImprimirOrcamentoOrdem() {
		await asyncImprimirOrcamentoOrdemServico(values.id, organizacaoId, (document) => {
			const arquivoPDF = new Blob([document.data], {
				type: 'application/pdf',
			});
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const relatorio = window.open(arquivoURL);
			if (relatorio) {
				relatorio.onload = () => {
					setTimeout(() => {
						relatorio.document.title = `Orçamento - Ordem de serviço Nº ${values.numero}`;
					}, 250);
				};
			}
		});
	}

	function copyLinkShareable() {
		copiarParaAreaTransferencia(
			`${services.GESTOR}/v1/ordem_servico/${values.id}/organizacoes/${organizacaoId}/imprimir`,
			() => {
				notify('Link copiado para área de transferência');
			}
		);
	}

	let titulo = 'Nova ordem de serviço';
	if (values.id) {
		titulo = 'Ordem de serviço';
	}

	if (disabled) {
		return (
			<span
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexDirection: isMobile ? 'column' : 'row',
				}}
			>
				{titulo}
				<span
					style={{
						display: 'flex',
						alignItems: 'center',
						fontSize: '16px',
						cursor: 'default',
					}}
				>
					<span style={{ ...styleButton, opacity: '0.5' }}>
						<FaShareAlt size="15px" style={{ marginRight: '5px' }} />
						<span>Compartilhar</span>
					</span>
					<span style={{ ...styleButton, opacity: '0.5' }}>
						<FaPrint size="15px" style={{ marginRight: '5px' }} />
						<span>Imprimir</span>
					</span>
				</span>
			</span>
		);
	} else {
		return (
			<>
				<span
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						flexDirection: isMobile ? 'column' : 'row',
					}}
				>
					{titulo}
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							fontSize: '16px',
							cursor: 'pointer',
						}}
					>
						<span
							style={
								hasServico(values.servicos) ? { ...styleButton } : { ...styleButton, opacity: '0.5', cursor: 'default' }
							}
							onClick={(event) => {
								if (hasServico(values.servicos)) {
									menuCompartilhar.current.toggle(event);
								}
							}}
						>
							<Menu
								style={{ minWidth: '290px' }}
								model={menuCompartilharModel}
								popup
								ref={menuCompartilhar}
								id="menu_compartilhar"
							/>
							<FaShareAlt size="15px" style={{ marginRight: '5px' }} />
							<span>Compartilhar</span>
						</span>
						<span style={styleButton} onClick={(event) => menuImprimir.current.toggle(event)}>
							<Menu style={{ width: '220px' }} model={menuImprimirModel} popup ref={menuImprimir} id="menu_imprimir" />
							<FaPrint size="15px" style={{ marginRight: '5px' }} />
							<span>Imprimir</span>
						</span>
					</span>
				</span>
				<If test={modalEnviarPorEmailVisible}>
					<ModalCompartilharPorEmail
						visible={modalEnviarPorEmailVisible}
						onHide={() => setModalEnviarPorEmailVisible(false)}
						valuesForm={values}
						isMobile={isMobile}
						enviarPorEmailTipo={enviarPorEmailTipo}
					/>
				</If>
				<If test={modalEnviarPorWhatsappVisible}>
					<ModalCompartilharPorWhatsapp
						idVenda={values.id}
						idPessoa={values.cliente && values.cliente.value}
						cliente={values.cliente}
						numero={values.numero}
						statusVenda={values.status}
						visible={modalEnviarPorWhatsappVisible}
						tipoOrdem={enviarPorWhatsappTipo}
						onHide={() => setModalEnviarPorWhatsappVisible(false)}
					/>
				</If>
			</>
		);
	}
}
