import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { ButtonExcluirTable, ButtonNovo, If, NenhumRegistroEncontrado } from 'components';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { connect } from 'react-redux';
import { formatCPF } from '@brazilian-utils/brazilian-utils';
import { ModalCondutor } from './components/ModalCondutor';

function TabCondutoresImpl({ informacoesPermissoes, isMobile }) {
	const { values, setFieldValue } = useFormikContext();
	const [sortOrder, setSortOrder] = useState(1);
	const [sortField, setSortField] = useState('nome');
	const [modalCondutorVisible, setModalCondutorVisible] = useState(false);

	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EXCLUIR);

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return <span style={{ fontStyle: 'italic', opacity: '0.7' }}>{field}</span>;
		}
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-all',
					paddingLeft: isMobile ? '15%' : '0',
				}}
			>
				{field}
			</span>
		);
	}

	function handleClickExcluir(row) {
		const newValues = values.condutores.filter((condutor) => condutor.id !== row.id);
		setFieldValue('condutores', newValues);
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonExcluirTable onClick={() => handleClickExcluir(row)} podeExcluir={podeExcluir} />
			</div>
		);
	}

	return (
		<>
			<ButtonNovo
				onClick={() => setModalCondutorVisible(true)}
				label="Novo condutor"
				disabled={!podeInserir}
				style={{ marginBottom: '10px' }}
			/>
			<DataTable
				className="table"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				responsive
				value={values.condutores}
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={onSort}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					field="nome"
					header="Nome"
					body={(row) => aplicarEstiloInativa(row, row.nome)}
					style={{ width: '80%' }}
					sortable
				/>
				<Column
					field="cpf"
					header="CPF"
					body={(row) => aplicarEstiloInativa(row, formatCPF(row.cpf))}
					sortable
					style={{ width: '20%' }}
				/>
				<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
			</DataTable>
			<If test={modalCondutorVisible}>
				<ModalCondutor
					informacoesPermissoes={informacoesPermissoes}
					visible={modalCondutorVisible}
					onHide={() => setModalCondutorVisible(false)}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export const TabCondutores = connect(mapStateToProps)(TabCondutoresImpl);
