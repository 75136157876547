import Button from '..';

function ButtonDownloadTable(props) {
	const {
		id,
		disabled = false,
		onClick,
		hidden = false,
		title = 'Download',
		icon = 'fa fa-download',
		raised,
		rounded,
	} = props;

	const style = {
		borderRadius: '50%',
		padding: '5px',
		width: '30px',
		height: '30px',
		marginLeft: '2.5px',
		marginRight: '2.5px',
	};

	return (
		<Button
			id={id}
			type="button"
			color="primary"
			disabled={disabled}
			onClick={onClick}
			hidden={hidden}
			raised={raised}
			rounded={rounded}
			icon={icon}
			enableShortCut={false}
			title={title}
			style={style}
		/>
	);
}

export default ButtonDownloadTable;
