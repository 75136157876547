import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { buscarDocumentosComExpiracao } from './Requests';
import { ProgressBar } from 'primereact/progressbar';
import { format, parse } from 'date-fns';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { Link } from 'react-router-dom';
import { Documentos } from '../../../../../../Util/constantes';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';

import './Styles/index.css';

const Divider = () => {
	return <hr id="divider-documentos-expiracao-periodo" />;
};

const List = props => {
	return (
		<div id="documentos-periodo" className="dashboard-scroll">
			<ul style={{ listStyle: 'none', padding: '0px 3px', margin: '0px' }}>{props.children}</ul>
		</div>
	);
};

const ListItem = props => {
	let titleDocumento = 'Ir para o documento ' + (props.identificacao ? props.identificacao : '');

	return (
		<li style={{ margin: '6px 0px' }}>
			<div className="p-grid" style={{ margin: '0px 6px' }}>
				<Link to={{ pathname: '/documentos/assinatura/cadastro/' + props.docId }}>
					<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} className="link_to">
						<span
							id="documento-identificacao-title"
							title={titleDocumento}
							style={{ color: `${Documentos.cores.azul}` }}
						>
							{props && props.identificacao ? props.identificacao : 'Nenhuma descrição informada'}
						</span>
					</div>
				</Link>
			</div>
			<div className="p-grid p-align-center" style={{ margin: '0px', marginTop: '0.5rem' }}>
				<div className="p-col-4" style={{ padding: '0px 6px' }}>
					<span id="documento-pessoa">
						{props && props.nomePessoa ? props.nomePessoa : 'Nenhuma pessoa informada'}
					</span>
				</div>
				<div className="container-expiracao-assinaturas">
					<div className="p-col-4" style={{ padding: '0 6px' }}>
						<p style={{ margin: '0.5rem 0px' }}>{`${
							props.isMobile ? 'Expiração:' : 'Data expiração:'
						} ${format(parse(props.expiracao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}`}</p>
					</div>
					<div className="p-col-4" id="documento-quantidade-assinaturas">
						{`Assinaturas: ${props.assinaturasEfetuadas} de ${props.quantidadeSignatarios}`}
					</div>
				</div>
			</div>
		</li>
	);
};

export default function DocumentosExpiracaoPeriodo(props) {
	const { dataInicial, dataFinal, isMobile, dataAtualizacaoMenuLateral, atualizarGraficoNovamente } = props;

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [documentosComExpiracao, setDocumentosComExpiracao] = useState([]);
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	useEffect(() => {
		carregarInformacoes();
	}, []);

	useEffect(() => {
		carregarInformacoes();
	}, [dataAtualizacaoMenuLateral]);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}
		} else afterFirstRenderRef = true;
	}, [dataInicial, dataFinal]);

	useEffect(() => {
		if (loading) {
			buscarDocumentosComExpiracao(
				dataInicial,
				dataFinal,
				({ data: documentosComExpiracao }) => {
					setDocumentosComExpiracao(documentosComExpiracao);
					setLoading(false);
					setError(false);
				},
				() => {
					setLoading(false);
					setError(true);
				}
			);
		}
	}, [loading, dataAtualizacaoMenuLateral]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	function getHeaderCard() {
		return (
			<div id="card-header-documentos-expiracao-periodo">
				<label title="Lista os documentos que estão aguardando assinatura e que possuem expiração no período">
					Documentos com expiração no período
				</label>
			</div>
		);
	}

	if (loading) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
				<ProgressBar mode="indeterminate" style={{ height: '3px' }} />
			</Card>
		);
	} else if (error) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
				<FalhaAoCarregarAsInformacoes style={{ marginTop: '32px' }} />
			</Card>
		);
	} else {
		return (
			<>
				<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
					{documentosComExpiracao.length > 0 ? (
						<div style={{ paddingTop: '0px' }}>
							<List>
								{documentosComExpiracao.map((documento, idx, array) => {
									return (
										<React.Fragment key={idx}>
											<ListItem
												docId={documento.docId}
												expiracao={documento.dataExpiracao}
												identificacao={documento.identificacao}
												nomePessoa={documento.nomePessoa}
												assinaturasEfetuadas={documento.assinaturasEfetuadas}
												quantidadeSignatarios={documento.quantidadeSignatarios}
												isMobile={isMobile}
											/>
											{++idx !== array.length ? <Divider /> : null}
										</React.Fragment>
									);
								})}
							</List>
						</div>
					) : (
						<NenhumaInformacaoEncontrada />
					)}
				</Card>
			</>
		);
	}
}
