import { useEffect } from 'react';
import * as Yup from 'yup';
import Modal from '../../../../../components/Modal';
import { withRouter } from 'react-router';
import { withFormik, Field } from 'formik';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../../../../Common/Autenticacao';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import ButtonSalvar from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import Grid from '../../../../../components/Grid';
import InputDate from '../../../../../components/input/InputDate';
import InputMoney from '../../../../../components/input/InputMoney';
import { services } from '../../../../../Common/Constantes/api';
import { helpMessage } from './Util/constantes';
import { confirm } from '../../../../../components/Toast';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import TextArea from '../../../../../components/TextArea/TextArea';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import { isValid, parseISO, formatISO, format } from 'date-fns';
import SingleSelectProduto from '../../../../../components/select/SingleSelectProduto';
import { converterEntradaEstoqueParaApi } from './Util/entradaEstoqueConverter';
import { asyncCreateEntradaEstoque, asyncUpdateEntradaEstoque, asyncDeleteEntradaEstoque } from './Request';
import { mensagensDeValidacao } from '../../../../../Common/Constantes/mensagens';
import propTypes from 'prop-types';
import { estadosCadastro, permissoes, recursos } from '../../../../../Common';
import { InputField } from 'components';
import { tipoSaldo } from '../../Util/constantes';

const initialValue = {
	id: null,
	data: formatISO(new Date()),
	hora: format(new Date(), 'HH:mm'),
	tipo: 'ENTRADA',
	produto: null,
	custo: 0,
	quantidade: 1,
	observacao: '',
	saldoTipo: 'ESTOQUE',
};

function ModalEntradaEstoqueView(props) {
	const {
		onNovoClick,
		resetForm,
		setFieldValue,
		handleSubmit,
		validateForm,
		isValid,
		values,
		atualizarListagem,
		onHide,
		dirty,
		visible,
		initialValues,
		saldoTipo = tipoSaldo.ESTOQUE,
	} = props;
	const decimaisQtd = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
	const decimaisPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;
	const informacoesPermissoes = {
		podeInserir: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.INSERIR),
		podeEditar: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.EDITAR),
		podeExcluir: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.EXCLUIR),
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const onClickNovo = dirty ? e => salvar(e, novo) : novo;

	useEffect(() => {
		setFieldValue('saldoTipo', saldoTipo);	
		setTimeout(() => {
			document.getElementById('EstoqueEntradaSingleSelectProduto')?.getElementsByTagName('input')[0]?.focus();
		}, 500);
	}, []);

	async function novo() {
		onNovoClick && onNovoClick();
		resetForm({ values: initialValue });

		await setFieldValue('quantidade', 1);
		await setFieldValue('saldoTipo', saldoTipo);		
		document.getElementById('quantidade').focus();
	}

	async function salvar(e, novoOnSuccess) {
		await handleSubmit();
		await validateForm();

		if (isValid) {
			if (values.id) {
				asyncUpdateEntradaEstoqueModal(values, novoOnSuccess);
			} else {
				asyncCreateEntradaEstoqueModal(values, novoOnSuccess);
			}
		}
	}

	async function asyncCreateEntradaEstoqueModal(values, novoOnSuccess) {
		if (values) {
			await asyncCreateEntradaEstoque(converterEntradaEstoqueParaApi(values), () => {
				if (novoOnSuccess) {
					if (atualizarListagem) {
						atualizarListagem();
					}
					resetForm({ values: initialValue });
					novoOnSuccess();
				} else {
					onHide(values);
				}
			});
		}
	}

	async function asyncUpdateEntradaEstoqueModal(values, novoOnSuccess) {
		if (values) {
			await asyncUpdateEntradaEstoque(converterEntradaEstoqueParaApi(values), () => {
				if (novoOnSuccess) {
					if (atualizarListagem) {
						atualizarListagem();
					}
					resetForm({ values: initialValue });
					novoOnSuccess();
				} else {
					onHide(values);
				}
			});
		}
	}

	function excluir() {
		confirm('Confirmação', 'Deseja excluir o registro?', async () => {
			await asyncDeleteEntradaEstoque(values.id, () => {
				onHide(true);
			});
		});
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			onHide();
		}
	}

	return (
		<Modal
			header={saldoTipo === tipoSaldo.ESTOQUE ?  (values.id ? 'Editar entrada de estoque' : 'Nova entrada de estoque') : (values.id ? 'Editar reserva de estoque' : 'Nova reserva de estoque')}
			visible={visible}
			onHide={onHide}
		>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ButtonCancelar
						{...informacoesPermissoes}
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={cancelar}
					/>
					<ButtonSalvar {...informacoesPermissoes} onClick={salvar} />
					<ButtonNovo
						onClick={onClickNovo}
						hidden={!dirty && !values.id}
						estadoBotao={estadoBotaoNovo}
						{...informacoesPermissoes}
					/>
					<ButtonExcluir hidden={!values.id} {...informacoesPermissoes} onClick={excluir} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							name="produto"
							label="Produto"
							autofocus
							component={SingleSelectProduto}
							value={values.produto}
							obrigatorio
							helpMessage={helpMessage.produto}
							onChange={e => {
								setFieldValue('produto', e);
								if (e && e.registro && e.registro.ultimoCusto) {
									setFieldValue('custo', e.registro.ultimoCusto);
								}
							}}
							url={`${services.GESTOR}/v1/estoque/movimentacoes/relacoes/produtos`}
							tipoPredefinido="PRODUTO"
							filtroAdicionalUrl=";tipo!=SERVICO;controlarEstoque==true"
							id="EstoqueEntradaSingleSelectProduto"							
							{...informacoesPermissoes}
						/>						
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={InputMoney}
							label="Quantidade"
							name="quantidade"
							id="quantidade"
							obrigatorio
							size={11}
							prefix=""
							placeholder=""
							decimalScale={decimaisQtd}
							helpMessage={helpMessage.quantidade}
							onChange={e => setFieldValue('quantidade', e.target.value)}
							value={values.quantidade}
							allowNegative={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputMoney}
							label="Preço de custo"
							name="custo"
							size={11}
							helpMessage={helpMessage.custo}
							onChange={e => setFieldValue('custo', e.target.value)}
							value={values.custo}
							allowNegative={false}
							decimalScale={decimaisPreco}
							disabled={values.saldoTipo === tipoSaldo.RESERVADO}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={InputDate}
							obrigatorio
							label="Data/hora da entrada"
							name="data"
							helpMessage={helpMessage.data}
							onChange={e => setFieldValue('data', e.target.value)}
							value={values.data}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={InputField}
							style={{ marginTop: '17px' }}
							type="time"							
							name="hora"
							helpMessage={helpMessage.hora}
							onChange={e => setFieldValue('hora', e.target.value)}
							value={values.hora}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={TextArea}
							placeholder="Escreva sua observação aqui"
							label="Observação"
							name="observacao"
							helpMessage={helpMessage.observacao}
							onChange={e => setFieldValue('observacao', e.target.value)}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalEntradaEstoque = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: true,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return props.registroSelecionado;
		} else {
			return { ...initialValue, produto: props.produto };
		}
	},

	validate(values) {
		let errors = {};

		if (values.data && !isValid(parseISO(values.data))) {
			errors.data = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.custo < 0) {
			errors.custo = 'O custo deve ser maior ou igual a zero';
		}

		if (values.quantidade <= 0) {
			errors.quantidade = 'A quantidade deve ser maior que zero';
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		produto: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		data: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		quantidade: Yup.string().nullable(true).required(mensagensDeValidacao.OBRIGATORIO),
		observacao: Yup.string().max(255, 'O campo observação não pode ter mais que 255 caracteres.').nullable(true),
	}),
	handleSubmit: () => {},
})(ModalEntradaEstoqueView);

ModalEntradaEstoque.propTypes = {
	/**Produto selecionado que vem como sugestão */
	produto: propTypes.object,

	/**Registro recebido com os dados para serem carregados no formulário */
	registroSelecionado: propTypes.object,

	/**Função responsável por passar null para o registroSelecionado e para o produto */
	onNovoClick: propTypes.func,
};

export default withRouter(ModalEntradaEstoque);
