export const TiposPercentualValor = {
	PERCENTUAL: 'PERCENTUAL',
	VALOR: 'VALOR',
};

export const PAPEL_SIGNATARIOS = [
	{ label: 'Acusar recebimento', value: 'ACUSAR_RECEBIMENTO' },
	{ label: 'Agenciadora', value: 'AGENCIADORA' },
	{ label: 'Agente autorizado', value: 'AGENTE' },
	{ label: 'Anuente', value: 'ANUENTE' },
	{ label: 'Aprovador(a)', value: 'APROVADOR' },
	{ label: 'Associação', value: 'ASSOCIACAO' },
	{ label: 'Associado(a)', value: 'ASSOCIADO' },
	{ label: 'Avalista', value: 'AVALISTA' },
	{ label: 'Cedente', value: 'CEDENTE' },
	{ label: 'Credor(a)', value: 'CREDOR' },
	{ label: 'Colaborador(a)', value: 'COLABORADOR' },
	{ label: 'Comodante', value: 'COMODANTE' },
	{ label: 'Comodatário(a)', value: 'COMODATARIO' },
	{ label: 'Condômino', value: 'CONDOMINO' },
	{ label: 'Contratada', value: 'CONTRATADA' },
	{ label: 'Contratada p.p.', value: 'CONTRATADA_PP' },
	{ label: 'Contratante', value: 'CONTRATANTE' },
	{ label: 'Contratante p.p.', value: 'CONTRATANTE_PP' },
	{ label: 'Cessionário', value: 'CESSIONARIO' },
	{ label: 'Declarante', value: 'DECLARANTE' },
	{ label: 'Devedor(a)', value: 'DEVEDOR' },
	{ label: 'Devedor solidário', value: 'DEVEDOR_SOLIDARIO' },
	{ label: 'Emitente', value: 'EMITENTE' },
	{ label: 'Empregador(a)', value: 'EMPREGADOR' },
	{ label: 'Endossante', value: 'ENDOSSANTE' },
	{ label: 'Endossatário', value: 'ENDOSSATARIO' },
	{ label: 'Fiador(a)', value: 'FIADOR' },
	{ label: 'Funcionário(a)', value: 'FUNCIONARIO' },
	{ label: 'Gestor(a)', value: 'GESTOR' },
	{ label: 'Interveniente', value: 'INTERVENIENTE' },
	{ label: 'Locador(a)', value: 'LOCADOR' },
	{ label: 'Locatário(a)', value: 'LOCATARIO' },
	{ label: 'Membro do conselho', value: 'MEMBRO_CONSELHO' },
	{ label: 'Outorgante', value: 'OUTORGANTE' },
	{ label: 'Parceiro(a) indicador(a)', value: 'PARCEIRO_INDICADOR' },
	{ label: 'Parte', value: 'PARTE' },
	{ label: 'Parte compradora', value: 'PARTE_COMPRADORA' },
	{ label: 'Parte representante legal', value: 'PARTE_REPRESENTANTE_LEGAL' },
	{ label: 'Parte vendedora', value: 'PARTE_VENDEDORA' },
	{ label: 'Presidente', value: 'PRESIDENTE' },
	{ label: 'Procurador(a)', value: 'PROCURADOR' },
	{ label: 'Produtor(a)', value: 'PRODUTOR' },
	{ label: 'Representante', value: 'REPRESENTANTE' },
	{ label: 'Representante legal', value: 'REPRESENTANTE_LEGAL' },
	{ label: 'Representado', value: 'REPRESENTADO' },
	{ label: 'Responsável solidário', value: 'RESPONSAVEL_SOLIDARIO' },
	{ label: 'Secretário(a)', value: 'SECRETARIO' },
	{ label: 'Sublocador(a)', value: 'SUBLOCADOR' },
	{ label: 'Sublocatário(a)', value: 'SUBLOCATARIO' },
	{ label: 'Sócio(a) administrador(a)', value: 'SOCIO_ADMINISTRADOR' },
	{ label: 'Solicitante', value: 'SOLICITANTE' },
	{ label: 'Testemunha', value: 'TESTEMUNHA' },
	{ label: 'Validador', value: 'VALIDADOR' },
];
