import { buscarDadosLoginLocalStorage, buscarMascaraTelefone } from 'Common';
import {
	Button,
	Col,
	Divider,
	Dropdown,
	Grid,
	If,
	InputCep,
	InputField,
	InputMask,
	SingleSelectMunicipio,
	SingleSelectPais,
} from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { OPTIONS_REGIME_TRIBUTARIO } from 'views/configuracao/ConfiguracoesGerais/Util/constantes';
import LogotipoUpload from '../../../LogotipoUpload';
import { helpConfiguracoesForm } from '../../Help';

function TabInformacoesEmpresa() {
	const { setNovoLogotipo, podeEditarEmpresa, informacoesPermissoes, setVisibleModalContador } =
		useContextConfiguracoesGerais();
	const { values, setFieldValue, setTouched } = useFormikContext();

	function onUploadLogotipo(e) {
		setNovoLogotipo(e);
		setFieldValue('logotipoUrl', URL.createObjectURL(e));
	}

	function onChangeTipo(e) {
		setFieldValue('tipo', e.value);
		setFieldValue('cpf', null);
		setFieldValue('cnpj', null);
	}

	function onChangeCep(e) {
		if (e.pesquisou) {
			e.bairro && setFieldValue('bairro', e.bairro);
			e.complemento && setFieldValue('complemento', e.complemento);
			e.logradouro && setFieldValue('logradouro', e.logradouro);
			e.municipio &&
				setFieldValue('municipio', {
					label: `${e.municipio.nome} - ${e.municipio.estado.sigla}`,
					value: e.municipio.id,
				});
			e.pais && setFieldValue('pais', { label: e.pais.nome, value: e.pais.id });

			setTouched({
				field: {
					bairro: true,
					complemento: true,
					logradouro: true,
					municipio: true,
					pais: true,
					numero: true,
				},
			});
		} else {
			setFieldValue('cep', e.value);
		}
	}

	return (
		<Grid>
			<Col
				sm="4"
				md="4"
				lg="3"
				style={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<LogotipoUpload
					loading={!values.logotipoUrl}
					logotipo={values.logotipoUrl}
					onChange={(e) => onUploadLogotipo(e)}
					podeEditarEmpresa={podeEditarEmpresa}
				/>
			</Col>
			<Col sm="8" md="8" lg="9">
				<Grid>
					<Field
						sm="12"
						md="6"
						lg="5"
						xl="5"
						label="Nome"
						obrigatorio
						name="nome"
						size={60}
						component={InputField}
						helpMessage={helpConfiguracoesForm.nome}
						id="ConfiguracoesInputNome"
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="7"
						xl="7"
						label="Razão social"
						name="razaoSocial"
						size={60}
						obrigatorio={Boolean(values.certificado)}
						component={InputField}
						{...informacoesPermissoes}
					/>

					<Field
						sm="12"
						md="6"
						lg="4"
						xl="4"
						label="Nome apresentação"
						name="nomeApresentacao"
						size={60}
						obrigatorio
						component={InputField}
						{...informacoesPermissoes}
					/>

					<Field
						sm="12"
						md="6"
						lg="2"
						xl="2"
						component={Dropdown}
						label="Tipo"
						obrigatorio
						name="tipo"
						showClear={false}
						onChange={onChangeTipo}
						options={[
							{ label: 'Física', value: 'FISICA' },
							{ label: 'Jurídica', value: 'JURIDICA' },
						]}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="3"
						xl="3"
						hidden={values.tipo !== 'FISICA'}
						component={InputMask}
						mask="000.000.000-00"
						placeholder="   .   .   -  "
						label="CPF"
						obrigatorio
						name="cpf"
						onChange={(e) => setFieldValue('cpf', e.target.value)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="3"
						xl="3"
						hidden={values.tipo !== 'JURIDICA'}
						component={InputMask}
						mask="00.000.000/0000-00"
						placeholder="  .   .   /    -  "
						label="CNPJ"
						obrigatorio
						name="cnpj"
						onChange={(e) => setFieldValue('cnpj', e.target.value)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="3"
						xl="3"
						label="Inscrição estadual"
						name="inscricaoEstadual"
						size={14}
						obrigatorio={Boolean(values.certificado)}
						component={InputField}
						value={values.inscricaoEstadual}
						onChange={(e) => setFieldValue('inscricaoEstadual', e.target.value)}
						helpMessage={helpConfiguracoesForm.inscricaoEstadual}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="5"
						xl="5"
						label="E-mail"
						obrigatorio
						name="email"
						size={255}
						component={InputField}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="3"
						xl="3"
						component={InputMask}
						mask={buscarMascaraTelefone(values.telefone)}
						placeholder="(  )      -    "
						label="Telefone"
						obrigatorio
						name="telefone"
						size={16}
						onChange={(e) => setFieldValue('telefone', e.target.value)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="4"
						xl="4"
						label="I.E. do substituto tributário"
						name="inscricaoEstadualSubstitutoTributario"
						helpMessage={helpConfiguracoesForm.inscricaoEstadualSubstitutoTributario}
						size={14}
						component={InputField}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="3"
						xl="3"
						label="Inscrição municipal"
						name="inscricaoMunicipal"
						helpMessage={helpConfiguracoesForm.inscricaoMunicipal}
						size={15}
						component={InputField}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="3"
						xl="3"
						label="CNAE fiscal"
						helpMessage={helpConfiguracoesForm.cnae}
						name="cnae"
						size={7}
						component={InputField}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="3"
						xl="3"
						component={Dropdown}
						helpMessage={helpConfiguracoesForm.regimeTributario}
						label="Regime tributário"
						name="regimeTributario"
						obrigatorio
						onChange={(e) => setFieldValue('regimeTributario', e.value)}
						showClear={false}
						options={OPTIONS_REGIME_TRIBUTARIO}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="3"
						xl="3"
						label="Suframa"
						name="suframa"
						helpMessage={helpConfiguracoesForm.suframa}
						size={9}
						type="number"
						component={InputField}
						{...informacoesPermissoes}
					/>
				</Grid>
			</Col>
			<Col sm="12" md="12" lg="12" xl="12">
				<Divider label="Endereço" styleContainer={{ margin: '0.5rem 0' }} />
				<Grid>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputCep}
						label="CEP"
						name="cep"
						type="tel"
						obrigatorio
						value={values.cep}
						onChange={(e) => onChangeCep(e)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Logradouro"
						name="logradouro"
						size={255}
						obrigatorio
						component={InputField}
						pattern="[a-zA-Z0-9]"
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Número"
						size={10}
						obrigatorio
						name="numero"
						component={InputField}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Bairro"
						name="bairro"
						obrigatorio
						size={60}
						component={InputField}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Complemento"
						name="complemento"
						size={255}
						component={InputField}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="País"
						name="pais"
						obrigatorio
						component={SingleSelectPais}
						value={values.pais}
						onChange={(e) => setFieldValue('pais', e)}
						isClearable={false}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Município"
						name="municipio"
						obrigatorio
						component={SingleSelectMunicipio}
						value={values.municipio}
						onChange={(e) => setFieldValue('municipio', e)}
						isClearable={false}
						{...informacoesPermissoes}
					/>
				</Grid>
			</Col>
			<If test={buscarDadosLoginLocalStorage()?.organizacao.aplicacao === 'MSYS_GESTOR'}>
				<Col sm="12" md="12" lg="12" xl="12">
					<Grid>
						<Button
							className="p-button"
							type="button"
							label="Contador"
							icon="fa fa-pencil"
							onClick={() => {
								setVisibleModalContador(true);
							}}
							style={{ margin: '0px 26px 20px 10px' }}
						/>
					</Grid>
				</Col>
			</If>
		</Grid>
	);
}

export { TabInformacoesEmpresa };
