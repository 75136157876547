import { infoStatusNfce } from '../../../../../Util/constantes';
import { Col, Grid } from '../../../../../../../../../components';

const secaoStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '0 1.5rem 0 0',
};

const numeroStyle = {
	fontSize: '24px',
	color: '#006095',
	fontWeight: 'bold',
};

const styleStatus = {
	height: '38px',
	borderRadius: '25px',
	fontWeight: 'bold',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '14px',
};

const styleName = {
	textAlign: 'center',
	fontWeight: 'bold',
	padding: '2px 0px',
};

export default function NFCeNumeroStatus(props) {
	const { isMobile, numero, serie, status } = props;

	const backgroundColor = status ? infoStatusNfce[status].lightColor : null;
	const color = status ? infoStatusNfce[status].strongColor : null;
	const name = status ? infoStatusNfce[status].name : null;
	const description = status ? infoStatusNfce[status].description : null;

	return (
		<Grid style={{ alignItems: 'center' }}>
			<Col sm="12" md="12" lg="4" xl="6">
				<div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
					<div style={secaoStyle}>
						<div>Série</div>
						<div
							style={numeroStyle}
							title={serie ? 'Série definida nas configurações do sistema' : 'Série não configurada'}
						>
							<span>{serie || '?'}</span>
						</div>
					</div>
					<div
						style={secaoStyle}
						title={!numero ? 'O número da nota é gerados automaticamente pelo sistema' : null}
					>
						<div>Número</div>
						<div style={numeroStyle}>{numero || '-'}</div>
					</div>
				</div>
			</Col>
			<Col sm="12" md="12" lg="8" xl="6">
				<div title={description} style={{ ...styleStatus, backgroundColor, color }}>
					<div style={styleName}>{name}</div>
				</div>
			</Col>
		</Grid>
	);
}
