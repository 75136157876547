import { services } from '../../../../Common/Constantes/api';
import { get } from '../../../../Common/Requisicoes';

export async function buscarContas(onSuccess, onError) {
	await get(`${services.GESTOR}/v1/contas?size=500&sort=nome`, null, onSuccess, onError);
}

export async function buscarConta(idConta, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/contas/${idConta}`, null, onSuccess, onError);
}

export async function buscarBancos(onSuccess, onError) {
	await get(`${services.GESTOR}/v1/bancos`, null, onSuccess, onError);
}
