export function copiarParaAreaTransferencia(texto, onSuccess) {

    const el = document.createElement("textarea");
    el.value = texto;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    onSuccess && onSuccess()
}