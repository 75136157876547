import { createContext, useContext, useState } from 'react';
import { estadosCadastro, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { connect } from 'react-redux';

const ContextMDFe = createContext();

function MDFeProvider({ children, history, match, isMobile, isTablet }) {
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.EXCLUIR));
	const [disableFields, setDisableFields] = useState(false);

	const [activeTabPrincipais, setActiveTabPrincipais] = useState(0);
	const [activeTabServicos, setActiveTabServicos] = useState(0);
	const [activeTabComplementares, setActiveTabComplementares] = useState(0);
	const [isFirstRender, setIsFirstRender] = useState(true);

	const [exibirLoadingTransmissao, setExibirLoadingTransmissao] = useState(false);
	const [messageLoading, setMessageLoading] = useState('');
	const [showMessageTransmissao, setShowMessageTransmissao] = useState(false);
	const [showMessageValidacoes, setShowMessageValidacoes] = useState(false);
	const [showMessageParametrosFiscais, setShowMessageParametrosFiscais] = useState(false);
	const [errosTransmissao, setErrosTransmissao] = useState({});
	const [isSituacaoFinalMDFe, setIsSituacaoFinalMDFe] = useState(false);

	const [visibleModalHistorico, setVisibleModalHistorico] = useState(false);
	const [visibleModalCancelamento, setVisibleModalCancelamento] = useState(false);
	const [visibleModalCondutorIndicado, setVisibleModalCondutorIndicado] = useState(false);
	const [visibleModalIncluirDFe, setVisibleModalIncluirDFe] = useState(false);
	const [visibleModalIncluirPagamento, setVisibleModalIncluirPagamento] = useState(false);
	const [visibleModalEncerramento, setVisibleModalEncerramento] = useState(false);
	const [visibleModalCondutores, setVisibleModalCondutores] = useState(false);
	const [visibleModalReboques, setVisibleModalReboques] = useState(false);
	const [visibleModalImportacaoNFe, setVisibleModalImportacaoNFe] = useState(false);

	const [resetCampos, setResetCampos] = useState(false);
	const [resetCamposCancelamento, setResetCamposCancelamento] = useState(false);
	const [importouNFe, setImportouNFe] = useState(false);

	const informacoesPermissoes = {
		estadoCadastro: match?.params?.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir,
		podeEditar,
		podeExcluir,
	};

	return (
		<ContextMDFe.Provider
			value={{
				history,
				match,
				isMobile,
				isTablet,
				podeInserir,
				podeEditar,
				podeExcluir,
				informacoesPermissoes,
				disableFields,
				setDisableFields,
				activeTabPrincipais,
				setActiveTabPrincipais,
				activeTabServicos,
				setActiveTabServicos,
				activeTabComplementares,
				setActiveTabComplementares,
				isFirstRender,
				setIsFirstRender,
				exibirLoadingTransmissao,
				setExibirLoadingTransmissao,
				showMessageTransmissao,
				setShowMessageTransmissao,
				showMessageParametrosFiscais,
				setShowMessageParametrosFiscais,
				showMessageValidacoes,
				setShowMessageValidacoes,
				errosTransmissao,
				setErrosTransmissao,
				isSituacaoFinalMDFe,
				setIsSituacaoFinalMDFe,
				visibleModalHistorico,
				setVisibleModalHistorico,
				visibleModalCancelamento,
				setVisibleModalCancelamento,
				visibleModalCondutorIndicado,
				setVisibleModalCondutorIndicado,
				visibleModalIncluirDFe,
				setVisibleModalIncluirDFe,
				visibleModalIncluirPagamento,
				setVisibleModalIncluirPagamento,
				visibleModalEncerramento,
				setVisibleModalEncerramento,
				messageLoading,
				setMessageLoading,
				visibleModalReboques,
				setVisibleModalReboques,
				visibleModalCondutores,
				setVisibleModalCondutores,
				resetCampos,
				setResetCampos,
				resetCamposCancelamento,
				setResetCamposCancelamento,
				visibleModalImportacaoNFe,
				setVisibleModalImportacaoNFe,
				importouNFe,
				setImportouNFe,
			}}
		>
			{children}
		</ContextMDFe.Provider>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(MDFeProvider);
export const useContextMDFe = () => useContext(ContextMDFe);
