import { useFormikContext } from 'formik';

import { copiarObjeto } from 'Common';
import { ButtonCancelar, ButtonExcluir, ButtonSalvar, estadosBotaoCancelar, estadosBotaoSalvar } from 'components';

import { validarFormulario } from 'views/Util';
import { useContextTabelaPreco } from 'views/cadastros/TabelaPreco/Context';

function ActionButtons({
	setFieldValueTabelaPreco,
	indexRegistro,
	onCloseModal,
	alteracoesPorCondicao,
	isNovoRegistro,
}) {
	const { values, validateForm, dirty, handleReset, handleSubmit } = useFormikContext();
	const { informacoesPermissoes, desabilitarCampos, isHeaderEditing } = useContextTabelaPreco();

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			onCloseModal();
		}
	}

	async function handleClickSalvar() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			setFieldValueTabelaPreco(`alteracoesPorCondicao.[${indexRegistro}]`, {
				...values,
				idRegistro: values.condicao?.value,
			});

			isHeaderEditing.current = true;
			onCloseModal();
		}
	}

	function handleClickExcluir() {
		const alteracoesPorCondicaoArray = copiarObjeto(alteracoesPorCondicao);

		alteracoesPorCondicaoArray.splice(indexRegistro, 1);

		setFieldValueTabelaPreco('alteracoesPorCondicao', alteracoesPorCondicaoArray);
		isHeaderEditing.current = true;
		onCloseModal();
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={handleClickCancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar
				estadoBotao={estadosBotaoSalvar.SALVAR}
				disabled={!dirty || desabilitarCampos}
				onClick={handleClickSalvar}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir
				disabled={isNovoRegistro || dirty || desabilitarCampos}
				onClick={handleClickExcluir}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export { ActionButtons };
