import { Field, useFormikContext, withFormik } from 'formik';

import { copiarObjeto, gerarUUID, mensagensDeValidacao, services } from 'Common';
import { Dropdown, Form, FormActions, FormContent, Grid, Modal, SingleSelectCondicaoPagamento } from 'components';

import { useContextTabelaPreco } from 'views/cadastros/TabelaPreco/Context';
import {
	TABELA_PRECO_ALTERACAO_CONDICAO,
	TABELA_PRECO_ALTERACAO_CONDICAO_OPTIONS,
} from 'views/cadastros/TabelaPreco/Util/constantes';

import { ActionButtons } from './components/ActionButtons';
import { AlteracaoPercentualCondicaoAPrazo } from './components/AlteracaoPercentualCondicaoAPrazo';
import { AlteracaoPercentualCondicaoAVista } from './components/AlteracaoPercentualCondicaoAVista';

function ModalAlteracaoPorCondicaoPagamentoImpl({
	visible,
	indexRegistro,
	setFieldValueTabelaPreco,
	onCloseModal,
	alteracoesPorCondicao,
	isNovoRegistro,
}) {
	const { values, setFieldValue } = useFormikContext();
	const { desabilitarCampos, informacoesPermissoes } = useContextTabelaPreco();

	function onChangeCondicao(e) {
		setFieldValue('condicao', e);
		if (e?.registro?.tipo === 'A_VISTA') {
			setFieldValue('condicaoItens', [
				{
					id: null,
					item: values.condicaoItens.length + 1,
					idTemporario: gerarUUID(),
					valorInicial: 0,
					valorFinal: 0,
					percentualAplicado: 0,
				},
			]);
		} else if (e?.registro?.tipo === 'A_PRAZO') {
			setFieldValue('condicaoItens', [
				{
					id: null,
					item: values.condicaoItens.length + 1,
					idTemporario: gerarUUID(),
					valorInicial: null,
					valorFinal: null,
					percentualAplicado: 0,
				},
			]);
		}
	}

	return (
		<Modal header="Acréscimo/Desconto por condição" visible={visible} onHide={onCloseModal}>
			<Form>
				<FormActions>
					<ActionButtons
						alteracoesPorCondicao={alteracoesPorCondicao}
						setFieldValueTabelaPreco={setFieldValueTabelaPreco}
						indexRegistro={indexRegistro}
						isNovoRegistro={isNovoRegistro}
						onCloseModal={onCloseModal}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="8"
							lg="8"
							xl="8"
							component={SingleSelectCondicaoPagamento}
							label="Condição de pagamento"
							name="condicao"
							obrigatorio
							showClear={false}
							optionsRemover={alteracoesPorCondicao}
							value={values.condicao}
							onChange={(e) => onChangeCondicao(e)}
							url={`${services.GESTOR}/v1/vendas/tabelas_preco/relacoes/condicoes_pagamento`}
							disabled={desabilitarCampos}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							label="Acréscimo/desconto por condição"
							name="tipoAlteracaoCondicao"
							options={TABELA_PRECO_ALTERACAO_CONDICAO_OPTIONS}
							onChange={(e) => setFieldValue('tipoAlteracaoCondicao', e.value)}
							value={values?.tipoAlteracaoCondicao}
							showClear={false}
							{...informacoesPermissoes}
						/>
					</Grid>
					{values.condicao?.registro?.maximoParcelas > 0 ? (
						<Grid style={{ padding: '12px' }}>
							<div>
								A condição contém <b>{values.condicao?.registro?.maximoParcelas}</b> parcelas.
							</div>
						</Grid>
					) : null}
					<Grid>
						{values.condicao?.registro?.tipo === 'A_VISTA' ? (
							<AlteracaoPercentualCondicaoAVista tipoAlteracaoPorCondicao={values.tipoAlteracaoCondicao} />
						) : null}
						{values.condicao?.registro?.tipo === 'A_PRAZO' ? (
							<AlteracaoPercentualCondicaoAPrazo tipoAlteracaoPorCondicao={values.tipoAlteracaoCondicao} />
						) : null}
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}
const ModalAlteracaoPorCondicaoPagamento = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.alteracaoCondicaoPagamento) {
			return copiarObjeto(props.alteracaoCondicaoPagamento);
		}

		return {
			id: null,
			idTemporario: gerarUUID(),
			idRegistro: null,
			condicao: null,
			tipoAlteracaoCondicao: TABELA_PRECO_ALTERACAO_CONDICAO.ACRESCIMO,
			condicaoItens: [],
		};
	},

	validate(values) {
		const errors = {};
		const errorsCondicaoItens = [];

		if (!values.tipoAlteracaoCondicao) {
			errors.tipoAlteracaoCondicao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.condicaoItens?.length > 0) {
			values.condicaoItens?.forEach((item, index) => {
				if (item.percentualAplicado === null || item.percentualAplicado === undefined) {
					errorsCondicaoItens[index] = {
						...errorsCondicaoItens[index],
						percentualAplicado: mensagensDeValidacao.OBRIGATORIO,
					};
				}

				if (values.condicao?.registro?.tipo === 'A_PRAZO') {
					if (item.valorInicial === null || item.valorInicial === undefined) {
						errorsCondicaoItens[index] = {
							...errorsCondicaoItens[index],
							valorInicial: mensagensDeValidacao.OBRIGATORIO,
						};
					}

					if (item.valorFinal === null || item.valorFinal === undefined) {
						errorsCondicaoItens[index] = {
							...errorsCondicaoItens[index],
							valorFinal: mensagensDeValidacao.OBRIGATORIO,
						};
					}

					if (item.valorFinal < item.valorInicial) {
						errorsCondicaoItens[index] = {
							...errorsCondicaoItens[index],
							valorFinal: 'Valor Final menor que inicial',
						};
					}

					if (index > 0) {
						const valorAnterior = values.condicaoItens[index - 1];

						if (item.valorInicial <= valorAnterior.valorFinal) {
							errorsCondicaoItens[index] = {
								...errorsCondicaoItens[index],
								valorInicial: 'Valor final anterior igual ou maior',
							};
						}
					}
				}
			});
		}

		if (errorsCondicaoItens.length > 0) {
			errors.condicaoItens = errorsCondicaoItens;
		}

		return errors;
	},
	handleSubmit: () => {},
})(ModalAlteracaoPorCondicaoPagamentoImpl);
export { ModalAlteracaoPorCondicaoPagamento };
