import { get, services } from "Common";
import { formatISO, isDate, isValid } from "date-fns";

export function asyncGetResultadoPeriodo(
  dataInicial,
  dataFinal,
  onSuccess,
  onError
) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: "date",
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: "date",
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: "date" });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: "date" });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/servicos/ordem_servico/resultado_periodo_vs_periodo_anterior?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}`,
    null,
    onSuccess,
    onError,
    false
  );
}

export function asyncGetServicosMaisPrestados(
  dataInicial,
  dataFinal,
  page,
  onSuccess,
  onError
) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: "date",
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: "date",
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: "date" });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: "date" });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/servicos/ordem_servico/servicos_prestados?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}&page=${page}&sort=valorFinalizados,desc&size=10`,
    null,
    onSuccess,
    onError,
    false
  );
}

export function asyncGetProdutosMaisVendidos(
  dataInicial,
  dataFinal,
  page,
  onSuccess,
  onError
) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: "date",
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: "date",
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: "date" });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: "date" });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/servicos/ordem_servico/produtos_vendidos?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}&page=${page}&sort=valorFinalizados,desc&size=10`,
    null,
    onSuccess,
    onError,
    false
  );
}

export function asyncGetRankingTomadores(
  dataInicial,
  dataFinal,
  page,
  onSuccess,
  onError
) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: "date",
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: "date",
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: "date" });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: "date" });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/servicos/ordem_servico/ranking_clientes?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}&page=${page}&sort=valorFinalizados,desc&size=10`,
    null,
    onSuccess,
    onError,
    false
  );
}

export function asyncGetRankingVendedores(
  dataInicial,
  dataFinal,
  page,
  onSuccess,
  onError
) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: "date",
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: "date",
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: "date" });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: "date" });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/servicos/ordem_servico/ranking_vendedores?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}&page=${page}&sort=valorFinalizados,desc&size=10`,
    null,
    onSuccess,
    onError,
    false
  );
}

export function asyncGetOrdensPorDia(
  dataInicial,
  dataFinal,
  onSuccess,
  onError
) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: "date",
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: "date",
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: "date" });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: "date" });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/servicos/ordem_servico/ordens_por_dia?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}`,
    null,
    onSuccess,
    onError,
    false
  );
}

export function asyncGetOrdensUltimosDozeMeses(
  dataInicial,
  dataFinal,
  onSuccess,
  onError
) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: "date",
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: "date",
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: "date" });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: "date" });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/servicos/ordem_servico/ultimos_doze_meses?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}`,
    null,
    onSuccess,
    onError,
    false
  );
}
