import { formatarNcm, removerCaracteres } from 'Common';
import { setNullForIdProperties } from 'Common/Array';
import { formatISO } from 'date-fns';
import { formatarPlaca } from 'views/cadastros/transporte/Veiculos/Util/function';
import { MDFE_SITUACAO, MDFE_TIPO_CARGA_PREDOMINANTE } from './constantes';

function converterMDFeParaForm(data) {
	return {
		id: data.id,
		numero: data.numero,
		serie: data.serie,
		chave: data.chave,
		protocolo: data.protocolo,
		situacao: data.situacao,
		tipoEmitente: data.tipoEmitente,
		tipoTransportador: data.tipoTransportador,
		categoriaCombinacaoVeicular: data.categoriaCombinacaoVeicular,
		emissao: data.emissao,
		dataTransmissao: data.dataTransmissao,
		inicioViagem: data.inicioViagem,
		ufCarregamento: converterMDFeEstadoParaForm(data.ufCarregamento),
		ufDescarregamento: converterMDFeEstadoParaForm(data.ufDescarregamento),
		municipioCarregamento: converterMDFeMunicipioParaForm(data.municipioCarregamento),
		veiculoTracao: converterMDFeVeiculoTracaoParaForm(data.veiculoTracao),
		rntrc: data.rntrc ?? null,
		produtoPredominante: converterMDFeProdutoPredominanteParaForm(data.produtoPredominante),
		total: converterMDFeTotalParaForm(data.total),
		informacaoComplementar: converterMDFeInformacaoComplementarParaForm(data.informacaoComplementar),
		ciot: converterMDFeCiotParaForm(data.ciot),
		ufsPercurso: converterMDFeUFsPercursoParaForm(data.ufsPercurso),
		municipios: converterMDFeMunicipiosParaForm(data.municipios),
		condutores: converterMDFeCondutoresParaForm(data.condutores),
		reboques: converterMDFeReboquesParaForm(data.reboques),
		seguradoras: converterMDFeSeguradorasParaForm(data.seguradoras),
		valePedagio: converterMDFeValePedagioParaForm(data.valePedagio),
		lacres: converterMDFeLacresParaForm(data.lacres),
		erros: data.erros?.length > 0 ? data.erros[0] : [],
		encerramento: converterMDFeEncerramentoParaForm(data),
		pagamento: converterMDFePagamentoParaForm(data.pagamento),

		dataCancelamento: data.dataCancelamento,
		motivoCancelamento: data.motivoCancelamento,
	};
}

function converterMDFeVeiculoTracaoParaForm(veiculoTracao) {
	if (veiculoTracao) {
		return {
			id: veiculoTracao.id,
			veiculo: converterMDFeVeiculoParaForm(veiculoTracao.veiculo),
			placa: formatarPlaca(veiculoTracao.placa),
			renavam: veiculoTracao.renavam,
			estado: converterMDFeEstadoParaForm(veiculoTracao.estado),
			tara: veiculoTracao.tara,
			capacidade: veiculoTracao.capacidade,
			rodado: veiculoTracao.rodado,
			carroceria: veiculoTracao.carroceria,
		};
	}

	return null;
}

function converterMDFeProdutoPredominanteParaForm(produtoPredominante) {
	if (produtoPredominante) {
		return {
			id: produtoPredominante.id,
			tipoCarga: produtoPredominante.tipoCarga,
			descricao: produtoPredominante.descricao,
			ncm: converterMDFeNcmParaForm(produtoPredominante.ncm),
			codigoEan: produtoPredominante.codigoEan,
			carregamentoCep: produtoPredominante.carregamentoCep,
			carregamentoLatitude: produtoPredominante.carregamentoLatitude,
			carregamentoLongitude: produtoPredominante.carregamentoLongitude,
			descarregamentoCep: produtoPredominante.descarregamentoCep,
			descarregamentoLatitude: produtoPredominante.descarregamentoLatitude,
			descarregamentoLongitude: produtoPredominante.descarregamentoLongitude,
		};
	}

	return null;
}

function converterMDFeTotalParaForm(total) {
	if (total) {
		return {
			id: total.id,
			quantidadeDocumento: total.quantidadeDocumento,
			medidaPeso: total.medidaPeso,
			pesoBruto: total.pesoBruto,
			valorTotal: total.valorTotal,
		};
	}

	return null;
}

function converterMDFeInformacaoComplementarParaForm(informacaoComplementar) {
	if (informacaoComplementar) {
		return {
			id: informacaoComplementar.id,
			usuario: converterMDFeUsuarioParaForm(informacaoComplementar.usuario),
			setor: converterMDFeSetorParaForm(informacaoComplementar.setor),
			informacaoComplementar: informacaoComplementar.informacaoComplementar,
			observacaoInterna: informacaoComplementar.observacaoInterna,
			permiteCarregamentoPosterior: informacaoComplementar.permiteCarregamentoPosterior,
		};
	}

	return null;
}

function converterMDFeCiotParaForm(ciot) {
	if (ciot) {
		return {
			id: ciot.id,
			numeroCiot: ciot.numeroCiot,
			tipoPessoa: ciot.tipoPessoa,
			cnpj: ciot.cnpj ?? null,
			cpf: ciot.cpf ?? null,
		};
	}

	return null;
}

function converterMDFeUFsPercursoParaForm(ufsPercurso) {
	if (ufsPercurso) {
		return ufsPercurso?.map((ufPercurso) => ({
			id: ufPercurso.id,
			value: ufPercurso.estado.id,
			label: `${ufPercurso.item} - ${ufPercurso.estado.sigla}`,
			sigla: ufPercurso.estado.sigla,
			item: ufPercurso.item,
		}));
	}

	return null;
}

function converterMDFeMunicipiosParaForm(municipios) {
	if (municipios) {
		return municipios?.map((municipio) => ({
			id: municipio.id,
			idRegistro: municipio?.municipio?.id,
			municipio: converterMDFeMunicipioParaForm(municipio.municipio),
			documentos: converterMDFeDocumentosParaForm(municipio.documentos),
		}));
	}

	return null;
}

function converterMDFeCondutoresParaForm(condutores) {
	if (condutores) {
		return condutores?.map((condutor) => ({
			id: condutor.id,
			idRegistro: condutor?.condutor?.id,
			condutor: condutor.condutor,
			cpf: condutor.cpf,
			nome: condutor.nome,
		}));
	}

	return null;
}

function converterMDFeReboquesParaForm(reboques) {
	if (reboques) {
		return reboques?.map((reboque) => ({
			id: reboque.id,
			idRegistro: reboque.veiculo?.id,
			veiculo: reboque.veiculo,
			placa: formatarPlaca(reboque.placa),
			renavam: reboque.renavam,
			estado: reboque.estado,
			tara: reboque.tara,
			capacidade: reboque.capacidade,
			carroceria: reboque.carroceria,
		}));
	}

	return null;
}

function converterMDFeSeguradorasParaForm(seguradoras) {
	if (seguradoras) {
		return seguradoras?.map((seguradora) => ({
			id: seguradora.id,
			responsavel: seguradora.responsavel,
			seguradora: converterMDFeSeguradoraParaForm(seguradora.seguradora),
			tipoPessoa: seguradora.tipoPessoa,
			cnpj: seguradora.cnpj ?? null,
			cpf: seguradora.cpf ?? null,
			apolice: seguradora.apolice,
			averbacoes: converterMDFeSeguradoraAverbacoesParaForm(seguradora.averbacoes),
		}));
	}

	return null;
}

function converterMDFeValePedagioParaForm(valePedagio) {
	if (valePedagio) {
		return valePedagio?.map((vale) => ({
			id: vale.id,
			fornecedoraCnpj: vale.fornecedoraCnpj,
			numeroComprovante: vale.numeroComprovante,
			valePedagioTipo: vale.valePedagioTipo,
			valePedagioValor: vale.valePedagioValor,
			tipoPessoa: vale.tipoPessoa,
			cnpj: vale.cnpj ?? null,
			cpf: vale.cpf ?? null,
		}));
	}

	return null;
}

function converterMDFeSeguradoraAverbacoesParaForm(averbacoes) {
	if (averbacoes) {
		return averbacoes?.map((averbacao) => ({
			id: averbacao.id,
			averbacao: averbacao.averbacao,
		}));
	}
	return [];
}

function converterMDFeLacresParaForm(lacres) {
	if (lacres) {
		return lacres?.map((lacre) => ({
			id: lacre.id,
			lacre: lacre.lacre,
		}));
	}

	return null;
}

function converterMDFeEncerramentoParaForm(mdfe) {
	if (mdfe.encerramento) {
		return {
			id: mdfe.encerramento?.id,
			data: mdfe.encerramento?.data,
			municipio: converterMDFeMunicipioParaForm(mdfe.encerramento?.municipio),
		};
	}

	return null;
}

function converterMDFeDFeParaForm(dfe) {
	if (dfe) {
		return {
			id: dfe.id,
			sequenciaEvento: dfe.sequenciaEvento,
			carregamentoMunicipio: converterMDFeMunicipioParaForm(dfe.carregamentoMunicipio),
			municipios: converterMDFeDFesDescarregamentoMunicipiosParaForm(dfe.descarregamentoMunicipios),
		};
	}

	return null;
}

function converterMDFeDFesDescarregamentoMunicipiosParaForm(municipios) {
	if (municipios) {
		return municipios?.map((municipio) => ({
			id: municipio.id,
			idRegistro: municipio.descarregamentoMunicipio?.id,
			municipio: converterMDFeMunicipioParaForm(municipio.descarregamentoMunicipio),
			documentos: [{ chave: municipio.chave }],
		}));
	}

	return null;
}

function converterMDFePagamentoParaForm(pagamento) {
	if (pagamento) {
		return {
			id: pagamento.id,
			tipoPessoa: pagamento.tipoPessoa,
			nome: pagamento.nome,
			cnpj: pagamento.cnpj ?? null,
			cpf: pagamento.cpf ?? null,
			idEstrangeiro: pagamento.idEstrangeiro ?? null,
			valorContrato: pagamento.valorContrato,
			tipoPagamento: pagamento.tipoPagamento,
			quantidadeParcelas: pagamento.quantidadeParcelas,
			valorAdiantamento: pagamento.valorAdiantamento,
			indicadorAltoDesempenho: pagamento.indicadorAltoDesempenho,
			banco: converterMDFePagamentoBancoParaForm(pagamento.banco),
			bancoAgencia: pagamento.bancoAgencia,
			cnpjBancoPagamentoEletronico: pagamento.cnpjBancoPagamentoEletronico,
			chavePix: pagamento.chavePix,
			componentes: converterMDFePagamentoComponentesParaForm(pagamento.componentes),
			prazos: converterMDFePagamentoPrazosParaForm(pagamento.prazos),
		};
	}

	return null;
}

function converterMDFePagamentoBancoParaForm(banco) {
	if (banco) {
		return { label: `${banco.codigo} - ${banco.descricao}`, value: banco.id, registro: banco };
	}
	return null;
}

function converterMDFePagamentoComponentesParaForm(componentes) {
	if (componentes) {
		return componentes?.map((componente) => ({
			id: componente.id,
			tipoPagamento: componente.tipoPagamento,
			valor: componente.valor,
			descricao: componente.descricao,
		}));
	}

	return null;
}

function converterMDFePagamentoPrazosParaForm(prazos) {
	if (prazos) {
		return prazos?.map((prazo) => ({
			id: prazo.id,
			numero: prazo.numero,
			valor: prazo.valor,
			vencimento: prazo.vencimento,
		}));
	}

	return null;
}

function converterMDFeCondutorIndicadoParaForm(condutorIndicado) {
	if (condutorIndicado) {
		return {
			id: condutorIndicado.id,
			sequenciaEvento: condutorIndicado.sequenciaEvento,
			condutor: converterMDFeCondutorParaForm(condutorIndicado.condutor),
			cpf: condutorIndicado.cpf,
			nome: condutorIndicado.nome,
		};
	}
	return null;
}

function converterMDFeEstadoParaForm(estado) {
	if (estado) {
		return {
			label: `${estado.nome} - ${estado.sigla}`,
			value: estado.id,
			registro: estado,
		};
	}

	return null;
}

function converterMDFeMunicipioParaForm(municipio) {
	if (municipio) {
		if (municipio.value) {
			return municipio;
		} else {
			return {
				label: `${municipio?.nome} - ${municipio.estadoSigla ?? municipio.estado?.sigla}`,
				value: municipio.id,
				registro: municipio,
			};
		}
	}
	return null;
}

function converterMDFeVeiculoParaForm(veiculo) {
	if (veiculo) {
		if (veiculo.value) {
			return veiculo;
		} else {
			return {
				label: formatarPlaca(veiculo.placa),
				value: veiculo.id,
				registro: veiculo,
			};
		}
	}

	return null;
}

function converterMDFeSetorParaForm(setor) {
	if (setor) {
		return { label: setor.nome, value: setor.id, registro: setor };
	}
	return null;
}

function converterMDFeUsuarioParaForm(usuario) {
	if (usuario) {
		return { label: usuario.nome, value: usuario.id, registro: usuario };
	}
	return null;
}

function converterMDFeNcmParaForm(ncm) {
	if (ncm) {
		return {
			label: `${formatarNcm(ncm.codigo)} - ${ncm.descricao}`,
			value: ncm.id,
			registro: ncm,
		};
	}

	return null;
}

function converterMDFeCondutorParaForm(condutor) {
	if (condutor) {
		return { label: `${condutor.nome} - ${condutor.cpf}`, value: condutor.id, registro: condutor };
	}

	return null;
}

function converterMDFeSeguradoraParaForm(seguradora) {
	if (seguradora) {
		return {
			label: `${seguradora.nome} - ${seguradora.cnpj}`,
			value: seguradora.id,
			registro: seguradora,
		};
	}

	return null;
}

function converterMDFeDocumentosParaForm(documentos) {
	if (documentos) {
		return documentos?.map((documento) => ({ id: documento.id, chave: documento.chave }));
	}

	return null;
}

function converterMDFeParaApi(data) {
	return {
		id: data.id ?? null,
		numero: data.numero,
		serie: data.serie,
		chave: data.chave,
		protocolo: data.protocolo,
		situacao: data.situacao,
		tipoEmitente: data.tipoEmitente,
		tipoTransportador: data.tipoTransportador,
		categoriaCombinacaoVeicular: data.categoriaCombinacaoVeicular,
		emissao: data.emissao,
		dataTransmissao: data.dataTransmissao,
		inicioViagem: data.inicioViagem,
		ufCarregamento: { id: data.ufCarregamento?.value },
		ufDescarregamento: { id: data.ufDescarregamento?.value },
		municipioCarregamento: { id: data.municipioCarregamento?.value },
		veiculoTracao: converterMDFeVeiculoTracaoParaApi(data.veiculoTracao),
		rntrc: data.rntrc,
		produtoPredominante: converterMDFeProdutoPredominanteParaApi(data.produtoPredominante),
		total: converterMDFeTotalParaApi(data.total),
		informacaoComplementar: converterMDFeInformacaoComplementarParaApi(data.informacaoComplementar),
		ciot: converterMDFeCiotParaApi(data.ciot),
		ufsPercurso: converterMDFeUFsPercursoParaApi(data.ufsPercurso),
		municipios: converterMDFeMunicipiosParaApi(data.municipios),
		condutores: converterMDFeCondutoresParaApi(data.condutores),
		reboques: converterMDFeReboquesParaApi(data.reboques),
		seguradoras: converterMDFeSeguradorasParaApi(data.seguradoras),
		valePedagio: converterMDFeValePedagioParaApi(data.valePedagio),
		lacres: converterMDFeLacresParaApi(data.lacres),
	};
}

function converterMDFeVeiculoTracaoParaApi(veiculoTracao) {
	return {
		id: veiculoTracao.id,
		veiculo: { id: veiculoTracao.veiculo?.value },
		placa: removerCaracteres(veiculoTracao.placa, ['-']),
		renavam: veiculoTracao.renavam,
		estado: { id: veiculoTracao.estado.value },
		tara: veiculoTracao.tara,
		capacidade: veiculoTracao.capacidade,
		rodado: veiculoTracao.rodado,
		carroceria: veiculoTracao.carroceria,
	};
}

function converterMDFeProdutoPredominanteParaApi(produtoPredominante) {
	if (produtoPredominante) {
		return {
			id: produtoPredominante.id,
			tipoCarga: produtoPredominante.tipoCarga,
			descricao: produtoPredominante.descricao,
			ncm: produtoPredominante.ncm ? { id: produtoPredominante.ncm?.value } : null,
			codigoEan: produtoPredominante.codigoEan,
			carregamentoCep: produtoPredominante.carregamentoCep,
			carregamentoLatitude: produtoPredominante.carregamentoLatitude,
			carregamentoLongitude: produtoPredominante.carregamentoLongitude,
			descarregamentoCep: produtoPredominante.descarregamentoCep,
			descarregamentoLatitude: produtoPredominante.descarregamentoLatitude,
			descarregamentoLongitude: produtoPredominante.descarregamentoLongitude,
		};
	}

	return null;
}

function converterMDFeTotalParaApi(total) {
	if (total) {
		return {
			id: total.id,
			quantidadeDocumento: total.quantidadeDocumento,
			medidaPeso: total.medidaPeso,
			pesoBruto: total.pesoBruto,
			valorTotal: total.valorTotal,
		};
	}

	return null;
}

function converterMDFeInformacaoComplementarParaApi(informacaoComplementar) {
	if (informacaoComplementar) {
		return {
			id: informacaoComplementar.id,
			usuario: { id: informacaoComplementar.usuario?.value },
			setor: { id: informacaoComplementar.setor?.value },
			informacaoComplementar: informacaoComplementar.informacaoComplementar,
			observacaoInterna: informacaoComplementar.observacaoInterna,
			permiteCarregamentoPosterior: informacaoComplementar.permiteCarregamentoPosterior,
		};
	}

	return null;
}

function converterMDFeCiotParaApi(ciot) {
	if (ciot) {
		return {
			id: ciot.id,
			numeroCiot: ciot.numeroCiot,
			tipoPessoa: ciot.tipoPessoa,
			cnpj: ciot.cnpj ?? null,
			cpf: ciot.cpf ?? null,
		};
	}

	return null;
}

function converterMDFeUFsPercursoParaApi(ufsPercurso) {
	if (ufsPercurso) {
		return ufsPercurso?.map((ufPercurso) => ({
			id: ufPercurso.id,
			item: ufPercurso.item,
			estado: { id: ufPercurso.value },
		}));
	}

	return null;
}

function converterMDFeMunicipiosParaApi(municipios) {
	if (municipios) {
		return municipios?.map((municipio) => ({
			id: municipio.id,
			municipio: { id: municipio.municipio?.value },
			documentos: converterMDFeDocumentosParaApi(municipio.documentos),
		}));
	}

	return null;
}

function converterMDFeCondutoresParaApi(condutores) {
	if (condutores) {
		return condutores?.map((condutor) => ({
			id: condutor.id,
			condutor: { id: condutor.condutor?.id },
			cpf: condutor.cpf,
			nome: condutor.nome,
		}));
	}

	return null;
}

function converterMDFeReboquesParaApi(reboques) {
	if (reboques) {
		return reboques?.map((reboque) => ({
			id: reboque.id,
			veiculo: { id: reboque.idRegistro },
			placa: removerCaracteres(reboque.placa, ['-']),
			renavam: reboque.renavam,
			estado: reboque.estado,
			tara: reboque.tara,
			capacidade: reboque.capacidade,
			carroceria: reboque.carroceria,
		}));
	}

	return null;
}

function converterMDFeSeguradorasParaApi(seguradoras) {
	if (seguradoras) {
		return seguradoras?.map((seguradora) => ({
			id: seguradora.id,
			responsavel: seguradora.responsavel,
			seguradora: seguradora.seguradora ? { id: seguradora.seguradora?.value } : null,
			tipoPessoa: seguradora.tipoPessoa,
			cnpj: seguradora.cnpj,
			cpf: seguradora.cpf,
			apolice: seguradora.apolice,
			averbacoes: converterMDFeSeguradoraAverbacoesParaApi(seguradora.averbacoes),
		}));
	}

	return null;
}

function converterMDFeSeguradoraAverbacoesParaApi(averbacoes) {
	if (averbacoes) {
		return averbacoes?.map((averbacao) => ({
			id: averbacao.id,
			averbacao: averbacao.averbacao,
		}));
	}
	return null;
}

function converterMDFeValePedagioParaApi(valePedagio) {
	if (valePedagio) {
		return valePedagio?.map((vale) => ({
			id: vale.id,
			fornecedoraCnpj: vale.fornecedoraCnpj,
			numeroComprovante: vale.numeroComprovante,
			valePedagioTipo: vale.valePedagioTipo,
			valePedagioValor: vale.valePedagioValor,
			tipoPessoa: vale.tipoPessoa,
			cnpj: vale.cnpj ?? null,
			cpf: vale.cpf ?? null,
		}));
	}

	return null;
}

function converterMDFeLacresParaApi(lacres) {
	if (lacres) {
		return lacres?.map((lacre) => ({
			id: lacre.id,
			lacre: lacre.lacre,
		}));
	}

	return null;
}

function converterMDFeEncerramentoParaApi(encerramento) {
	if (encerramento) {
		return {
			municipio: { id: encerramento?.municipio?.value },
		};
	}

	return null;
}

function converterMDFeDFeParaApi(values) {
	if (values) {
		return {
			id: values.id,
			sequenciaEvento: values.sequenciaEvento,
			carregamentoMunicipio: { id: values.carregamentoMunicipio?.value },
			descarregamentoMunicipios: converterMDFeDFesDescarregamentoMunicipiosParaApi(values.municipios),
		};
	}

	return null;
}

function converterMDFeDFesDescarregamentoMunicipiosParaApi(municipios) {
	if (municipios) {
		return municipios?.map((municipio) => ({
			id: municipio.id,
			descarregamentoMunicipio: { id: municipio.idRegistro },
			chave: municipio.documentos[0]?.chave,
		}));
	}

	return [];
}

function converterMDFePagamentoParaApi(pagamento) {
	if (pagamento) {
		return {
			id: pagamento.id,
			sequenciaEvento: pagamento.sequenciaEvento,
			tipoPessoa: pagamento.tipoPessoa,
			nome: pagamento.nome,
			cnpj: pagamento.cnpj,
			cpf: pagamento.cpf,
			idEstrangeiro: pagamento.idEstrangeiro,
			valorContrato: pagamento.valorContrato,
			tipoPagamento: pagamento.tipoPagamento,
			quantidadeParcelas: pagamento.quantidadeParcelas,
			valorAdiantamento: pagamento.valorAdiantamento > 0 ? pagamento.valorAdiantamento : null,
			indicadorAltoDesempenho: pagamento.indicadorAltoDesempenho,
			banco: { id: pagamento.banco?.value },
			bancoAgencia: pagamento.bancoAgencia,
			cnpjBancoPagamentoEletronico: pagamento.cnpjBancoPagamentoEletronico,
			chavePix: pagamento.chavePix,
			componentes: converterMDFePagamentoComponentesParaApi(pagamento.componentes),
			prazos: converterMDFePagamentoPrazosParaApi(pagamento.prazos),
		};
	}

	return null;
}

function converterMDFePagamentoComponentesParaApi(componentes) {
	if (componentes) {
		return componentes?.map((componente) => ({
			id: componente.id,
			tipoPagamento: componente.tipoPagamento,
			valor: componente.valor,
			descricao: componente.descricao,
		}));
	}

	return null;
}

function converterMDFePagamentoPrazosParaApi(prazos) {
	if (prazos) {
		return prazos?.map((prazo) => ({
			id: prazo.id,
			numero: prazo.numero,
			valor: prazo.valor,
			vencimento: prazo.vencimento,
		}));
	}

	return null;
}

function converterMDFeCondutorIndicadoParaApi(condutorIndicado) {
	if (condutorIndicado) {
		return {
			id: condutorIndicado.id,
			sequenciaEvento: condutorIndicado.sequenciaEvento,
			condutor: { id: condutorIndicado.condutor?.value },
			cpf: condutorIndicado.cpf,
			nome: condutorIndicado.nome,
		};
	}

	return null;
}

function converterMDFeDocumentosParaApi(documentos) {
	if (documentos) {
		return documentos?.map((documento) => ({ id: documento.id, chave: documento.chave }));
	}

	return null;
}

function converterMDFeParaNovoRegistro(mdfe) {
	let convertedData = converterMDFeParaForm(mdfe);

	setNullForIdProperties(convertedData, [
		'ufCarregamento',
		'ufDescarregamento',
		'municipioCarregamento',
		'veiculo',
		'estado',
		'condutor',
	]);

	convertedData.situacao = MDFE_SITUACAO.NAO_ENVIADO;
	convertedData.numero = null;
	convertedData.emissao = formatISO(new Date());
	convertedData.inicioViagem = null;
	convertedData.dataTransmissao = null;
	convertedData.valePedagio = [];
	convertedData.lacres = [];
	convertedData.seguradoras = [];
	convertedData.errors = [];
	convertedData.total = {
		...convertedData.total,
		quantidadeDocumento: 0,
		pesoBruto: 0,
		valorTotal: 0,
	};
	convertedData.ciot = null;
	convertedData.produtoPredominante = null;
	convertedData.encerramento = {
		...convertedData.encerramento,
		data: null,
		municipio: null,
	};
	convertedData.municipios = [];
	convertedData.dataCancelamento = null;
	convertedData.motivoCancelamento = null;
	convertedData.xmlCancelamento = null;
	convertedData.xml = null;

	return convertedData;
}

export {
	converterMDFeCondutorIndicadoParaApi,
	converterMDFeCondutorIndicadoParaForm,
	converterMDFeDFeParaApi,
	converterMDFeDFeParaForm,
	converterMDFeEncerramentoParaApi,
	converterMDFeEncerramentoParaForm,
	converterMDFePagamentoParaApi,
	converterMDFePagamentoParaForm,
	converterMDFeParaApi,
	converterMDFeParaForm,
	converterMDFeParaNovoRegistro,
};
