import { Icon } from '@iconify/react';
import { Button, If, Modal, NenhumRegistroEncontrado } from 'components';
import { format, parse } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { formatarPlaca } from 'views/cadastros/transporte/Veiculos/Util/function';
import { useContextMDFe } from '../../Context';
import { CADASTRO_URL } from '../../Form/Util/constantes';
import { readMDFeNaoEncerrados } from '../../Requests';
import { ModalEncerramento } from '../../Form/components/EventosPosteriores/components/ModalEncerramento';

function ModalConsultaNaoEncerrados({ visible, onHide, history, callback }) {
	const { isMobile } = useContextMDFe();
	const [registros, setRegistros] = useState([]);
	const [registroSelecionado, setRegistroSelecionado] = useState({});
	const [visibleModalEncerramento, setVisibleModalEncerramento] = useState(false);

	useEffect(() => {
		fetchNaoEncerrados();
	}, []);

	async function fetchNaoEncerrados() {
		await readMDFeNaoEncerrados(({ data }) => {
			setRegistros(data);
		});
	}

	function renderFieldData(row) {
		const label = format(parse(row.emissao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
		return <span title={label}>{label}</span>;
	}

	function renderFieldChave(row) {
		return (
			<span
				title={row.chave}
				style={{
					maxHeight: '60px',
					overflow: 'hidden',
					display: 'inline-flex',
					wordBreak: 'break-all',
					paddingLeft: isMobile ? '20%' : '0',
				}}
			>
				{row.chave ? row.chave : ''}
			</span>
		);
	}

	function renderOptions(row) {
		return (
			<div style={{ display: 'flex' }}>
				<Button
					style={{
						borderRadius: '50%',
						padding: '5px',
						width: '30px',
						height: '30px',
						margin: '2px',
					}}
					className="p-button p-button-success"
					icon={<Icon icon="fa-solid:paper-plane" style={{ marginRight: '0.2rem' }} />}
					title="Encerrar MDF-e"
					onClick={() => {
						setRegistroSelecionado(row);
						setVisibleModalEncerramento(true);
					}}
				/>
				<Button
					style={{
						borderRadius: '50%',
						padding: '5px',
						width: '30px',
						height: '30px',
						margin: '2px',
					}}
					className="p-button p-button-primary "
					icon="fa fa-eye"
					title="Visualizar MDF-e"
					onClick={() => history.push(`${CADASTRO_URL}/${row.id}`)}
				/>
			</div>
		);
	}

	function onHideModalEncerramento() {
		setVisibleModalEncerramento(false);
		fetchNaoEncerrados();
		if (typeof callback === 'function') {
			callback();
		}
	}

	return (
		<>
			<Modal header="MDF-es não encerrados" visible={visible} onHide={onHide} styleModal={{ maxWidth: '900px' }}>
				<div style={{ maxHeight: window.innerHeight - 250, overflowY: 'auto' }}>
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField="numero"
						sortOrder={-1}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							field="numero"
							header="Série/N°"
							body={(row) => `${row.serie ?? '-'} / ${row.numero ?? '-'}`}
							style={{ width: '110px' }}
							sortable
						/>
						<Column
							field="placa"
							header="Placa"
							body={(row) => formatarPlaca(row.placa)}
							style={{ width: '13%' }}
							sortable
						/>
						<Column field="emissao" header="Emissão" body={renderFieldData} style={{ width: '110px' }} sortable />
						<Column field="chave" header="Chave" body={renderFieldChave} sortable />
						<Column className="step-listagem-acoes" body={renderOptions} header="Ações" style={{ width: '7em' }} />
					</DataTable>
				</div>
			</Modal>
			<If test={visibleModalEncerramento}>
				<ModalEncerramento
					visible={visibleModalEncerramento}
					onHide={onHideModalEncerramento}
					mdfe={registroSelecionado}
				/>
			</If>
		</>
	);
}

export { ModalConsultaNaoEncerrados };
