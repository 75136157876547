import { Field, withFormik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	ButtonCancelar,
	ButtonSalvar,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	InputMask,
	SingleSelectMunicipio,
	estadosBotaoCancelar,
	If,
} from '../../../../../../../../../../components';

function FormModalTransportadoraView(props) {
	const { values, setFieldValue, onSave, dirty, onHide, resetForm, isFinalizado } = props;

	function cancelar() {
		if (dirty) {
			resetForm('');
		} else {
			onHide();
		}
	}

	return (
		<>
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
					/>
					<ButtonSalvar onClick={() => onSave(values)} disabled={!dirty} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							label="Razão social"
							component={InputField}
							name="nome"
							value={values.nome}
							onChange={event => setFieldValue('nome', event.target?.value)}
							disabled={isFinalizado}
						/>
						<If test={values.transportadora?.tipo === 'JURIDICA'}>
							<Field
								sm="12"
								md="3"
								lg="3"
								xl="3"
								label="CNPJ"
								component={InputMask}
								name="cnpj"
								mask="00.000.000/0000-00"
								placeholder="  .   .   /    -  "
								value={values.cnpj}
								onChange={event => setFieldValue('cnpj', event.target?.value)}
								disabled={isFinalizado}
							/>
						</If>
						<If test={values.transportadora?.tipo === 'FISICA'}>
							<Field
								sm="12"
								md="3"
								lg="3"
								xl="3"
								label="CPF"
								component={InputMask}
								name="cpf"
								mask="000.000.000-00"
								placeholder="   .   .   -  "
								value={values.cpf}
								onChange={event => setFieldValue('cpf', event.target?.value)}
								disabled={isFinalizado}
							/>
						</If>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							label="Inscrição estadual"
							component={InputMask}
							mask="000.000.000"
							name="inscricaoEstadual"
							value={values.inscricaoEstadual}
							onChange={event => setFieldValue('inscricaoEstadual', event.target?.value)}
							disabled={isFinalizado}
						/>
						<Field
							sm="12"
							md="8"
							lg="8"
							xl="8"
							label="Endereço *"
							component={InputField}
							name="endereco"
							value={values.endereco}
							onChange={event => setFieldValue('endereco', event.target?.value)}
							disabled
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							label="Município"
							component={SingleSelectMunicipio}
							name="municipio"
							value={values.municipio}
							onChange={event => setFieldValue('municipio', event)}
							disabled={isFinalizado}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const FormModalTransportadoraForm = withFormik({
	validateOnChange: false,

	mapPropsToValues(props) {
		return props.values;
	},

	handleSubmit: () => {},
})(FormModalTransportadoraView);

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default withRouter(connect(mapStateToProps)(FormModalTransportadoraForm));
