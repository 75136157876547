function converterMarcaParaFormulario(marcaApi) {
	return {
		id: marcaApi.id,
		nome: marcaApi.nome,
		situacao: marcaApi.situacao,
	};
}

function converterMarcaParaApi(marcaForm) {
	return {
		id: marcaForm.id,
		nome: marcaForm.nome,
		situacao: marcaForm.situacao,
	};
}

function converterParaDuplicar(marca) {
	return {
		id: null,
		nome: marca.nome,
		situacao: 'ATIVO',
	};
}

export { converterMarcaParaFormulario, converterMarcaParaApi, converterParaDuplicar };
