import { copiarParaAreaTransferencia } from 'Common';
import { Col, Grid, If, ToastTypes, notify } from 'components';
import { useFormikContext } from 'formik';
import { FaKey } from 'react-icons/fa';
import { MDFE_INFORMACOES_SITUACAO } from '../../Util/constantes';

const SECAO_STYLE = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '0 1.5rem 0 0',
};

const NUMERO_STYLE = {
	fontSize: '24px',
	color: '#006095',
	fontWeight: 'bold',
};

const STYLE_SITUACAO = {
	height: '38px',
	borderRadius: '25px',
	fontWeight: 'bold',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '14px',
};

const STYLE_CHAVE = {
	textAlign: 'center',
	cursor: 'pointer',
	flex: '1 1',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	padding: '2px 0px',
	fontSize: '12px',
};

const STYLE_NAME = {
	textAlign: 'center',
	fontWeight: 'bold',
	padding: '2px 0px',
};

function Header({ isMobile }) {
	const { values } = useFormikContext();

	const backgroundColor = values.situacao ? MDFE_INFORMACOES_SITUACAO[values.situacao].lightColor : null;
	const color = values.situacao ? MDFE_INFORMACOES_SITUACAO[values.situacao].strongColor : null;
	const name = values.situacao ? MDFE_INFORMACOES_SITUACAO[values.situacao].name : null;
	const description = values.situacao ? MDFE_INFORMACOES_SITUACAO[values.situacao].description : null;

	return (
		<Grid justifyBetween verticalAlignCenter style={{ margin: '1rem 0px 0px 0px' }}>
			<Col sm="5" lg="6" xl="6">
				<div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
					<div style={SECAO_STYLE}>
						<div>Série</div>
						<div
							style={{ ...NUMERO_STYLE, color: color }}
							title={values.serie ? 'Série definida nas configurações do sistema' : 'Série não configurada'}
						>
							<span>{values.serie || '-'}</span>
						</div>
					</div>
					<div
						style={SECAO_STYLE}
						title={!values.numero ? 'O número da nota é gerados automaticamente pelo sistema' : null}
					>
						<div>Número</div>
						<div style={{ ...NUMERO_STYLE, color: color }}>{values.numero || '-'}</div>
					</div>
				</div>
			</Col>
			<Col sm="7" lg="4" xl="4">
				<If test={values.chave}>
					<>
						<div title={description} style={{ ...STYLE_SITUACAO, backgroundColor, color }}>
							<div style={STYLE_NAME}>{name}</div>
						</div>
						<div
							title="Clique para copiar"
							style={{ ...STYLE_CHAVE, color }}
							onClick={() => {
								copiarParaAreaTransferencia(values.chave);
								notify('Chave de acesso copiada para área de transferência', ToastTypes.SUCCESS);
							}}
						>
							<FaKey /> {values.chave}
						</div>
					</>
				</If>
				<If test={!values.chave}>
					<div title={description} style={{ ...STYLE_SITUACAO, backgroundColor, color }}>
						<div style={STYLE_NAME}>{name}</div>
					</div>
				</If>
			</Col>
		</Grid>
	);
}

export { Header };
