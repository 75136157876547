
export const tutorialSteps = [
    {
        target: 'body',
        content: `Este é o cadastro de documentos`,
        disableBeacon: true,
        placement: 'center',
    }, {
        target: '.step-documento-status',
        content: 'Aqui você pode acompanhar o status do documento',
    }, {
        target: '.step-documento-botoes',
        content: 'Aqui serão mostrados os botões para executar as ações referentes ao status atual do documento',
    },
    {
        target: '.step-documento-pessoa',
        content: 'Aqui você pode selecionar a pessoa do documento.',
    }, {
        target: '.step-documento-signatarios',
        content: 'Aqui você pode gerenciar os signatários deste documento',
    }, {
        target: '.step-documento-confirmacoes',
        content: 'Aqui você pode gerenciar as perguntas que os signatários terão que responder para assinar o documento',
    }
]