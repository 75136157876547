import { gerarUUID } from 'Common';
import { NOVO_SERVICO } from 'components/Servicos/Utils';
import { formatISO } from 'date-fns';

export const CAMPOS_TOTAIS = {
	DESCONTO: 'desconto',
};

export const helpMessage = {
	codigo: 'Código de identificação do documento',
	pessoa: 'Cliente da venda ou orçamento',
	emissao: 'Data de emissão da venda ou orçamento',
	validade: 'Data de vencimento do orçamento',
	status: '',
	condicao: '',
	quantidade: '',
	informacaoComplementar: 'Estas informações serão incluídas na impressão do pedido',
	observacaoInterna: 'Estas informações NÃO serão incluídas na impressão do pedido',
	vendedor: 'Usuário do sistema responsável pelo orçamento ou venda',
	previsaoEntrega: 'Data prevista para entrega da venda ou orçamento',
};

export const condicaoDropdown = [
	{ label: 'À vista', value: 'A_VISTA' },
	{ label: 'A prazo', value: 'A_PRAZO' },
	{ label: 'Sem pagamento', value: 'SEM_PAGAMENTO' },
];

function sequenciaParcelas() {
	const arrayParcelas = [];

	for (let i = 1; i <= 48; i++) {
		const parcela = {
			label: `${i}x`,
			value: i,
		};

		arrayParcelas.push(parcela);
	}

	return arrayParcelas;
}

export const quantidadeParcelasDropdown = sequenciaParcelas();

export const optionsTipoInscricao = [
	{ label: 'Pessoa física', value: 'FISICA' },
	{ label: 'Pessoa jurídica', value: 'JURIDICA' },
	{ label: 'Pessoa estrangeira', value: 'ESTRANGEIRO' },
];

export const optionsIndicadorInscricaoEstadual = [
	{ label: '9 - Não contribuinte', value: 'NAO_CONTRIBUINTE' },
	{ label: '1 - Contribuinte ICMS', value: 'CONTRIBUINTE' },
	{ label: '2 - Contribuinte isento', value: 'CONTRIBUINTE_ISENTO' },
];

export const municipioParaModal = {
	EXTERIOR: '8d1f5be6-89b3-479b-9f84-8d9b9a1f0ced',
};

export const contaReceberStatus = {
	NAO_RECEBIDA: 'NAO_RECEBIDA',
	PARCIALMENTE_RECEBIDA: 'PARCIALMENTE_RECEBIDA',
	RECEBIDA: 'RECEBIDA',
};

export const Condicoes = {
	SEM_PAGAMENTO: 'SEM_PAGAMENTO',
	A_VISTA: 'A_VISTA',
	A_PRAZO: 'A_PRAZO',
};

export const TypeErrors = {
	PRODUTO_INATIVO: 'PRODUTO_INATIVO',
};

export const Operacao = {
	INSERIR: 'INSERIR',
	EDITAR: 'EDITAR',
	EXCLUIR: 'EXCLUIR',
};

export const OPTIONS_FORMA_PAGAMENTO = [
	{ label: 'Dinheiro', value: 'DINHEIRO' },
	{ label: 'Cheque', value: 'CHEQUE' },
	{ label: 'Cartão de crédito', value: 'CARTAO_CREDITO' },
	{ label: 'Cartão de débito', value: 'CARTAO_DEBITO' },
	{ label: 'Crédito na loja', value: 'CREDITO_LOJA' },
	{ label: 'Vale alimentação', value: 'VALE_ALIMENTACAO' },
	{ label: 'Vale refeição', value: 'VALE_REFEICAO' },
	{ label: 'Vale presente', value: 'VALE_PRESENTE' },
	{ label: 'Vale combustível', value: 'VALE_COMBUSTIVEL' },
	{ label: 'Boleto bancário', value: 'BOLETO_BANCARIO' },
	{ label: 'Sem Pagamento', value: 'SEM_PAGAMENTO' },
	{ label: 'Depósito bancário', value: 'DEPOSITO_BANCARIO' },
	{ label: 'Pix - Pagamento instantâneo', value: 'PAGAMENTO_INSTANTANEO_PIX' },
	{
		label: 'Transferência bancária, Carteira digital',
		value: 'TRANFERENCIA_BANCARIA_CARTEIRA_DIGITAL',
	},
	{
		label: 'Programa de fidelidade, Cashback, Crédito virtual',
		value: 'PROGRAMA_FIDELIDADE_CASHBACK_CREDITO_VIRTUAL',
	},
];

export const initialValuesTomador = {
	id: null,
	idTomador: null,
	tipo: null,
	cpf: null,
	cnpj: null,
	idEstrangeiro: null,
	nome: null,
	nomeFantasia: null,
	endereco: null,
	inscricaoEstadual: null,
	inscricaoMunicipal: null,
	email: null,
	cnae: null,
	telefone: null,
	indicadorInscricaoEstadual: null,
};

export const novaNotaServico = {
	//header
	id: null,
	numero: null,
	situacao: 'PENDENTE',
	dataEmissao: formatISO(new Date()),
	prestador: null,
	operacaoFiscal: null,
	erros: [],
	tomador: null,

	//body
	servicos: [NOVO_SERVICO],

	//totalizadores
	totalizadores: {
		id: null,
		valorBruto: 0,
		tipoDesconto: 'VALOR',
		desconto: 0,
		valorLiquido: 0,
		baseIss: 0,
		valorIss: 0,
		baseIr: 0,
		valorIr: 0,
		baseCsll: 0,
		valorCsll: 0,
		baseInss: 0,
		valorInss: 0,
		basePis: 0,
		valorPis: 0,
		baseCofins: 0,
		valorCofins: 0,
	},

	//pagamentos
	pagamentos: [
		{
			id: null,
			tempKey: gerarUUID(),
			sequencial: 1,
			condicaoPagamento: null,
			conta: null,
			formaPagamento: null,
			categoria: null,
			quantidadeParcelas: 0,
			valor: 0,
			valorRecebido: 0,
			parcelas: [],
		},
	],

	//complementares
	informacoesComplementares: {
		setor: null,
		vendedor: null,
		informacoesComplementares: null,
		observacaoInterna: null,
		municipio: null,
		numeroRPS: null,
		loteRPS: null,
		protocolo: null,
	},
};
