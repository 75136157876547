import { formatarMonetario } from 'Common';
import { Col, Grid, If } from 'components';
import TituloCard from 'components/TituloCard';

const cardStyle = {
	padding: '7px',
	borderRadius: '5px',
	backgroundColor: 'white',
	boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
	minHeight: '100%',
};

const cardContent = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
};

const colValorStyle = {
	fontSize: '21px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	paddingBottom: '5px',
	marginTop: '1rem',
};

const iconStyle = {
	maxWidth: '50px',
	display: 'flex',
	alignItems: 'center',
	padding: '0px',
	marginTop: '1rem',
};

const evolucaoStyle = {
	fontWeight: 'bold',
	fontSize: '12px',
	borderRadius: '8px',
	padding: '1px 6px',
	marginRight: '5px',
	marginTop: '7px',
};

function CardResultado({
	title,
	helpMessage,
	icon,
	valor,
	percentualEvolucao,
	loading,
	error,
	colorValor,
	colorEvolucao,
	backgroundEvolucao,
}) {
	const valorStyle = {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		alignSelf: 'flex-end',
		paddingTop: percentualEvolucao ? '0px' : '10px',
	};

	if (loading || error) {
		return (
			<div style={cardStyle}>
				<TituloCard title={title} helpMessage={helpMessage} />
				<div style={cardContent} />
			</div>
		);
	} else {
		const valorFormatado = formatarMonetario(valor || 0);
		return (
			<div style={cardStyle}>
				<TituloCard title={title} helpMessage={helpMessage} />
				<Grid>
					<Col style={iconStyle}>{icon}</Col>
					<Col
						style={{
							maxWidth: 'calc(100% - 50px)',
							paddingLeft: '0px',
						}}
					>
						<Grid>
							<Col
								style={{
									...colValorStyle,
									color: colorValor,
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-end',
								}}
							>
								<If test={percentualEvolucao}>
									<div
										title="Percentual de evolução em relação ao período anterior"
										style={{
											...evolucaoStyle,
											color: colorEvolucao,
											background: backgroundEvolucao,
											alignSelf: 'flex-end',
										}}
									>
										{`${percentualEvolucao}%`}
									</div>
								</If>
								<span style={valorStyle} title={valorFormatado}>
									{valorFormatado}
								</span>
							</Col>
						</Grid>
					</Col>
				</Grid>
			</div>
		);
	}
}

export { CardResultado };
