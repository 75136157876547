import { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { isAfter, isBefore, parseISO } from 'date-fns';

import { estadosCadastro, permissoes, recursos, services, usuarioPossuiPermissao } from 'Common';
import {
	Badge,
	Col,
	InputDate,
	InputField,
	InputMoney,
	SingleSelectCategoria,
	SingleSelectConta,
	SingleSelectFormaPagamento,
	SingleSelectPessoa,
	tiposCategoria,
} from 'components';

import { CONTA_RECEBER_STATUS } from 'views/financas/components/ModalReparcelamentoTitulos/Utils/constantes';
import { COLORS_SITUACAO, CONDICAO_PAGAMENTO_TAXA_TIPO, helpMessage, TIPO_CONTA_RECEBER } from '../../Util/constantes';

function FormBodyContaReceber({ informacoesPermissoes, isReparcelamento, disabledFieldReparcelamento }) {
	const { values, setFieldValue, errors } = useFormikContext();

	const [podeVisualizarFinancasCategorias] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.VISUALIZAR)
	);
	const [podeVisualizarFinancasContas] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.VISUALIZAR)
	);
	const [podeVisualizarFinancasFormasPagamento] = useState(
		usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.VISUALIZAR)
	);
	const isContaParcelada = values.tipo === TIPO_CONTA_RECEBER.VALOR_PARCELADO;

	function handleChangeValor(valor) {
		setFieldValue('valor', valor);
	}

	function onBlurValor() {
		if (values.tipoTaxa === CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL && values.taxaAliquota) {
			let { valor } = values;

			if (isContaParcelada && informacoesPermissoes.estadoCadastro === estadosCadastro.INCLUSAO) {
				valor /= values.quantidadeRepeticoes;
			}

			setFieldValue('taxaValor', (valor * values.taxaAliquota) / 100);
		} else if (values.tipoTaxa === CONDICAO_PAGAMENTO_TAXA_TIPO.VALOR && values.taxaValor) {
			setFieldValue('taxaAliquota', (values.taxaValor * 100) / values.valor);
		}
	}

	function renderSituacao(contaReceber) {
		const styleBackground = {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '32px',
			borderRadius: '20px',
			alignSelf: 'center',
			marginTop: '15px',
		};

		const vencimentoDate = parseISO(contaReceber.vencimento);
		const currentDate = new Date();
		const isToday = currentDate.toDateString() === vencimentoDate.toDateString();

		if (
			contaReceber.status === CONTA_RECEBER_STATUS.NAO_RECEBIDA &&
			(isAfter(vencimentoDate, currentDate) || isToday)
		) {
			return Badge(COLORS_SITUACAO.INFO.text, COLORS_SITUACAO.INFO.bg, 'Pendente', styleBackground);
		}
		if (contaReceber.status === CONTA_RECEBER_STATUS.NAO_RECEBIDA && isBefore(vencimentoDate, currentDate)) {
			return Badge(COLORS_SITUACAO.DANGER.text, COLORS_SITUACAO.DANGER.bg, 'Pendente / Vencida', styleBackground);
		}
		if (
			contaReceber.status === CONTA_RECEBER_STATUS.PARCIALMENTE_RECEBIDA &&
			(isAfter(vencimentoDate, currentDate) || isToday)
		) {
			return Badge(COLORS_SITUACAO.WARNING.text, COLORS_SITUACAO.WARNING.bg, 'Parcialmente recebida', styleBackground);
		}
		if (contaReceber.status === CONTA_RECEBER_STATUS.PARCIALMENTE_RECEBIDA && isBefore(vencimentoDate, currentDate)) {
			return Badge(
				COLORS_SITUACAO.DANGER.text,
				COLORS_SITUACAO.DANGER.bg,
				'Parcialmente recebida / Vencida',
				styleBackground
			);
		}
		if (contaReceber.status === CONTA_RECEBER_STATUS.RECEBIDA) {
			return Badge(COLORS_SITUACAO.SUCCESS.text, COLORS_SITUACAO.SUCCESS.bg, 'Recebida', styleBackground);
		}
		if (contaReceber.status === CONTA_RECEBER_STATUS.REPARCELADA) {
			return Badge(COLORS_SITUACAO.NEUTRAL.text, COLORS_SITUACAO.NEUTRAL.bg, 'Reparcelada', styleBackground);
		}

		return Badge(COLORS_SITUACAO.INFO.text, COLORS_SITUACAO.INFO.bg, 'Pendente', styleBackground);
	}

	return (
		<>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputField}
				label="Identificador"
				name="identificador"
				value={values.identificador}
				helpMessage={helpMessage.identificador}
				disabled
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputMoney}
				autofocus
				label="Valor"
				name="valor"
				obrigatorio
				size={15}
				helpMessage={helpMessage.valor}
				onChange={(e) => handleChangeValor(e.target.value)}
				onBlur={onBlurValor}
				value={values.valor}
				errors={errors?.valor}
				touched={!!errors?.valor}
				useFormErrors={false}
				useFormTouched={false}
				disabled={
					values.origem === 'VENDA' ||
					values.recebimentos.length > 0 ||
					(!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)) ||
					!!values.pix ||
					isReparcelamento
				}
				title={
					values.origem === 'VENDA'
						? 'Não é possível editar o valor de uma conta a receber derivada de uma venda'
						: null
				}
				allowNegative={false}
				id="ContasReceberInputMoneyValor"
				{...informacoesPermissoes}
			/>

			<Col sm="12" md="4" lg="4" xl="4">
				{renderSituacao(values)}
			</Col>

			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={InputDate}
				label="Emissão"
				name="emissao"
				obrigatorio
				helpMessage={helpMessage.emissao}
				onChange={(e) => setFieldValue('emissao', e.target.value)}
				onBlur={(e) => setFieldValue('dataDocumento', e.target.value)}
				value={values.emissao}
				errors={errors?.emissao}
				touched={!!errors?.emissao}
				useFormErrors={false}
				useFormTouched={false}
				id="ContasReceberInputEmissao"
				disabled={
					values.recebimentos.length > 0 ||
					(!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)) ||
					!!values.pix
				}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={InputDate}
				label="Vencimento"
				name="vencimento"
				obrigatorio
				helpMessage={helpMessage.vencimento}
				onChange={(e) => setFieldValue('vencimento', e.target.value)}
				onBlur={(e) => setFieldValue('competencia', e.target.value)}
				value={values.vencimento}
				errors={errors?.vencimento}
				touched={!!errors?.vencimento}
				useFormErrors={false}
				useFormTouched={false}
				disabled={
					values.recebimentos.length > 0 ||
					(!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)) ||
					!!values.pix
				}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="6"
				xl="6"
				name="pessoa"
				label="Pessoa (devedor)"
				component={SingleSelectPessoa}
				value={values.pessoa}
				helpMessage={helpMessage.pessoa}
				onChange={(e) => setFieldValue('pessoa', e)}
				url={`${services.GESTOR}/v1/contas_receber/relacoes/pessoas`}
				disabled={
					values.recebimentos.length > 0 ||
					(!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)) ||
					!!values.pix ||
					disabledFieldReparcelamento
				}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={InputField}
				label="Descrição"
				name="descricao"
				helpMessage={helpMessage.descricao}
				size={255}
				disabled={
					values.recebimentos.length > 0 ||
					(!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)) ||
					!!values.pix
				}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				name="formaPagamento"
				label="Forma de pagamento"
				helpMessage={helpMessage.formaPagamento}
				tipoCategoria={tiposCategoria.RECEITA}
				component={SingleSelectFormaPagamento}
				value={values.formaPagamento}
				obrigatorio
				onChange={(e) => setFieldValue('formaPagamento', e)}
				url={`${services.GESTOR}/v1/contas_receber/relacoes/formas_pagamento`}
				disabledButton={!podeVisualizarFinancasFormasPagamento || values.recebimentos.length > 0}
				disabled={
					(!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)) ||
					!!values.pix ||
					values.recebimentos.length > 0
				}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				name="conta"
				label="Conta"
				obrigatorio
				helpMessage={helpMessage.conta}
				tipoCategoria={tiposCategoria.RECEITA}
				component={SingleSelectConta}
				value={values.conta}
				onChange={(e) => setFieldValue('conta', e)}
				url={`${services.GESTOR}/v1/contas_receber/relacoes/contas`}
				disabledButton={!podeVisualizarFinancasContas || values.recebimentos.length > 0}
				disabled={
					(!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)) ||
					!!values.pix ||
					values.recebimentos.length > 0
				}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				name="categoria"
				label="Categoria"
				helpMessage={helpMessage.categoria}
				tipoCategoria={tiposCategoria.RECEITA}
				component={SingleSelectCategoria}
				value={values.categoria}
				onChange={(e) => setFieldValue('categoria', e)}
				url={`${services.GESTOR}/v1/contas_receber/relacoes/categorias/receitas`}
				disabledButton={!podeVisualizarFinancasCategorias || values.recebimentos.length > 0}
				disabled={
					(!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)) ||
					!!values.pix ||
					values.recebimentos.length > 0
				}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export { FormBodyContaReceber };
