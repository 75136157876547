import Lottie from 'lottie-react';
import animation from '../../../../../../../App/Animation/sucesso.json';

function AnimacaoCompletado({ onHide, isAdesaoInstantanea }) {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
			<Lottie
				style={{
					width: '100%',
					height: '200px',
				}}
				animationData={animation}
				loop
				autoPlay
				onLoopComplete={onHide}
			/>
			<label style={{ fontSize: '1.2rem' }}>
				{isAdesaoInstantanea ? 'Plano contratado com sucesso!' : 'Pagamento realizado com sucesso!'}
			</label>
		</div>
	);
}

export { AnimacaoCompletado };
