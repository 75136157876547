export const DATA_ACTION_TYPES = {
  SET_DATA: 'SET_DATA',
  ADD_ORCAMENTOS: 'ADD_ORCAMENTOS',
  ADD_PRODUTOS_VENDIDOS: 'ADD_PRODUTOS_VENDIDOS',
  ADD_RANKING_CLIENTES: 'ADD_RANKING_CLIENTES',
  ADD_RANKING_VENDEDORES: 'ADD_RANKING_VENDEDORES',
};

export function setData(data) {
  return {
    type: DATA_ACTION_TYPES.SET_DATA,
    payload: {
      ...data,
    },
  };
}

export function addDataOrcamentos(data) {
  return {
    type: DATA_ACTION_TYPES.ADD_ORCAMENTOS,
    payload: {
      ...data,
    },
  };
}

export function addDataProdutosVendidos(data) {
  return {
    type: DATA_ACTION_TYPES.ADD_PRODUTOS_VENDIDOS,
    payload: {
      ...data,
    },
  };
}

export function addDataRankingClientes(data) {
  return {
    type: DATA_ACTION_TYPES.ADD_RANKING_CLIENTES,
    payload: {
      ...data,
    },
  };
}

export function addDataRankingVendedores(data) {
  return {
    type: DATA_ACTION_TYPES.ADD_RANKING_VENDEDORES,
    payload: {
      ...data,
    },
  };
}
