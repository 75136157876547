export const tipoCampos = {
	DATE: 'date',
	INTEGER: 'integer',
	DECIMAL: 'decimal',
	SELECT: 'select',
	MULTI_SELECT: 'multiSelect',
	STRING: 'string',
	CPFCNPJ: 'cpfCnpj',
};

export const operacoesString = [
	{ label: 'Igual a', value: '==' },
	{ label: 'Contém', value: '=contains=' },
	{ label: 'Diferente de', value: '!=' },
];

export const operacoesNumericos = [
	{ label: 'Igual a', value: '==' },
	{ label: 'Diferente de', value: '!=' },
	{ label: 'Maior que', value: '>' },
	{ label: 'Menor que', value: '<' },
	{ label: 'Entre', value: 'entre' },
];

export const operacoesDatas = [
	{ label: 'Igual a', value: '==' },
	{ label: 'Diferente de', value: '!=' },
	{ label: 'Maior que', value: '>' },
	{ label: 'Menor que', value: '<' },
	{ label: 'Entre', value: 'entre' },
];

export const operacoesSelectOptions = [
	{ label: 'Igual a', value: '==' },
	{ label: 'Diferente de', value: '!=' },
];

export const operacoesMultiSelectOptions = [{ label: 'Contém', value: '=contains=' }];
