import { Field, useFormikContext } from 'formik';

import { removerCaracteres, services } from 'Common';
import { Col, Grid, InputField, SingleSelectUsuario } from 'components';

import { useContextVendedores } from 'views/cadastros/Vendedores/Context';

import './index.css';

const secaoStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '0 0 1rem 0',
};

const codigoStyle = {
	fontSize: '24px',
	color: '#006095',
	fontWeight: 'bold',
};

function HeaderVendedores() {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes } = useContextVendedores();

	return (
		<Grid>
			<Col sm="12" md="12" lg="12" xl="12" style={{ height: '4rem' }}>
				<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
					<div style={secaoStyle} title={!values.codigo ? 'O código é gerado automaticamente pelo sistema' : null}>
						<div>Código</div>
						<div style={codigoStyle}>{values.codigo || '-'}</div>
					</div>
				</div>
			</Col>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				id="InputNomeVendedor"
				className="step-vendedor-nome"
				component={InputField}
				label="Nome"
				obrigatorio
				name="nome"
				onBlur={() => {
					if (values.nome) {
						setFieldValue('nome', values.nome.trim());
					}
				}}
				onChange={(e) => setFieldValue('nome', removerCaracteres(e.target?.value, ['─']))}
				value={values.nome}
				size={120}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={SingleSelectUsuario}
				obrigatorio
				isClearable={false}
				label="Usuário"
				name="usuario"
				url={`${services.GESTOR}/v1/vendas/vendedor/relacoes/usuario`}
				rsql={`credencial.autoridade=="USER"`}
				onChange={(e) => setFieldValue('usuario', e)}
				value={values.usuario}
				utilizaFavorito={false}
			/>
		</Grid>
	);
}

export { HeaderVendedores };
