import { buscarDadosLoginLocalStorage } from 'Common';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/CondicaoPagamento/Util/constantes';

export function buscarPercentualAdicionalCondicaoPagamento(pagamentos, alteracoesPorCondicao = []) {
	const utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao =
		buscarDadosLoginLocalStorage()?.filialConectada?.parametrosVendas?.utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao;

	let adicionalAplicado = { percentual: 0, tipo: 'DESCONTO' };
	let pagamentoAPrazo = null;
	let pagamentoAVista = null;

	pagamentos?.forEach((pagamento) => {
		const tipoPagamento = pagamento?.condicaoPagamento?.registro?.tipo;
		if (tipoPagamento === CONDICAO_PAGAMENTO_TIPO.A_PRAZO) {
			pagamentoAPrazo = pagamento;
		} else if (tipoPagamento === CONDICAO_PAGAMENTO_TIPO.A_VISTA) {
			pagamentoAVista = pagamento;
		}
	});

	function calcularAdicional(pagamento, condicao) {
		const quantidadeParcelas = pagamento?.quantidadeParcelas?.value || 0;
		const percentualAplicado = condicao?.condicaoItens?.reduce((percentual, item) => {
			if (quantidadeParcelas >= item?.valorInicial && quantidadeParcelas <= item?.valorFinal) {
				return item.percentualAplicado;
			}

			return percentual;
		}, 0);

		return percentualAplicado;
	}

	function encontrarAlteracaoPorCondicao(pagamento, tipoPagamento) {
		alteracoesPorCondicao?.forEach((alteracao) => {
			if (pagamento?.condicaoPagamento?.value === alteracao.condicao.id) {
				const percentual =
					tipoPagamento === CONDICAO_PAGAMENTO_TIPO.A_PRAZO
						? calcularAdicional(pagamento, alteracao)
						: alteracao.condicaoItens[0]?.percentualAplicado || 0;

				adicionalAplicado = { percentual: percentual, tipo: alteracao.tipoAlteracaoCondicao };
			}
		});
	}

	if (utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao) {
		if (pagamentoAPrazo) {
			encontrarAlteracaoPorCondicao(pagamentoAPrazo, CONDICAO_PAGAMENTO_TIPO.A_PRAZO);
		} else if (pagamentoAVista) {
			encontrarAlteracaoPorCondicao(pagamentoAVista, CONDICAO_PAGAMENTO_TIPO.A_VISTA);
		}
	}

	return adicionalAplicado;
}

export function buscarPercentualAdicionalQuantidadeServico(quantidade, descontosPorQuantidade = []) {
	const utilizaTabelaPrecoDescontoQuantidade =
		buscarDadosLoginLocalStorage()?.filialConectada?.parametrosVendas?.utilizaTabelaPrecoDescontoQuantidade;

	let percentualAplicado = 0;

	if (utilizaTabelaPrecoDescontoQuantidade) {
		percentualAplicado = descontosPorQuantidade?.reduce((percentual, desconto) => {
			if (quantidade >= desconto.valorInicial && quantidade <= desconto.valorFinal) {
				return desconto.percentualAplicado;
			}

			return percentual;
		}, 0);
	}

	return { percentual: percentualAplicado, tipo: 'DESCONTO' };
}
