import { useEffect, useRef, useState } from 'react';
import { Field } from 'formik';
import { format, isBefore, parseISO, startOfDay } from 'date-fns';
import { Menu } from 'primereact/menu';
import { OverlayPanel } from 'primereact/overlaypanel';

import { services } from '../../../../../Common';
import { Button, Col, Dropdown, notify, SingleSelectContaComConvenio, ToastTypes } from '../../../../../components';

import {
	asyncGerarBoletos,
	asyncPostConsultaBoletos,
	asyncPostDescartarBoletos,
	asyncPostImprimirBoletos,
} from '../../Requests';
import { validarFormulario } from '../../../../Util';
import { getIdBoletosSelected } from '../../Util/functions';

function ActionButtons(props) {
	const {
		podeInserir,
		podeEditar,
		podeVisualizar,
		values,
		isMobile,
		setExibirModalContaReceber,
		setExibirModalEnviarPorEmail,
		setFieldValue,
		setExibirLoading,
		setMessageLoading,
		setDadosPessoaParaEmail,
		pesquisar,
		propsForm,
		buscarContaFavorita,
	} = props;

	const [menu, setMenu] = useState(null);
	const [optionContaConvenios, setOptionContaConvenios] = useState([]);
	const panelEmissaoBoletos = useRef(null);
	const itensMenu = montarItensMenu();
	const [boletosSelecionados, setBoletosSelecionados] = useState([]);

	useEffect(() => {
		if (values.selectedBoletos?.length > 0) {
			const matchedRegistros = values.registros?.filter(registro => {
				return boletosSelecionados.some(boleto => boleto.id === registro.id);
			});
			matchedRegistros
				? setFieldValue('selectedBoletos', matchedRegistros)
				: setFieldValue('selectedBoletos', []);
		}
	}, [values.registros]);

	function montarItensMenu() {
		let itens = [];

		itens.push({
			label: 'Sincronizar',
			icon: 'pi pi-sync',
			command: () => onClickSincronizar(),
			disabled: !podeVisualizar,
		});

		itens.push({
			label: 'Gerar conta a receber',
			icon: 'pi pi-plus-circle',
			command: () => setExibirModalContaReceber(true),
			disabled: !podeInserir,
		});

		itens.push({
			label: 'Descartar boleto',
			icon: 'pi pi-minus-circle',
			command: () => onClickDescartarBoleto(true),
			disabled: !podeEditar,
		});

		return itens;
	}
	useEffect(() => {
		mountConveniosOptions(values.conta?.registro?.convenios ?? []);
	}, [values.conta]);

	function openEmissaoBoletos(e) {
		panelEmissaoBoletos.current?.toggle(e);

		setTimeout(() => {
			document.getElementById('select-conta-emissao-boletos')?.getElementsByTagName('input')[0]?.focus();
		}, 250);
	}

	async function onClickSincronizar() {
		const boletos = getIdBoletosSelected(values.selectedBoletos);

		if (boletos.length > 0) {
			await asyncPostConsultaBoletos(boletos, () => {
				pesquisar();
			});
		} else {
			notify('Selecione ao menos um registro', ToastTypes.ERROR);
		}
	}

	function handleChangeConta(e) {
		setFieldValue('conta', e);

		mountConveniosOptions(e?.registro?.convenios ?? []);
	}

	async function mountConveniosOptions(convenios) {
		let convenioOptions = [];
		let primeiroConvenio = {};

		if (convenios?.length > 0) {
			convenioOptions = await convenios
				?.filter(convenio => convenio.situacao === 'ATIVA')
				?.map(convenio => {
					return {
						label: `${convenio.descricao} - ${convenio.numero}`,
						registro: convenio,
						value: convenio.id,
					};
				})
				?.sort((a, b) => a.label.localeCompare(b.label));

			if (convenioOptions.length > 0) {
				primeiroConvenio = {
					label: `${convenioOptions[0]?.registro?.descricao} - ${convenioOptions[0]?.registro?.numero}`,
					registro: convenioOptions[0]?.registro,
					value: convenioOptions[0]?.registro?.id,
				};
			}
		}

		setOptionContaConvenios(convenioOptions);
		setFieldValue('convenio', Object.keys(primeiroConvenio).length > 0 ? primeiroConvenio : null);
	}

	function handleChangeConvenio(e) {
		Object.keys(e)?.length > 0 ? setFieldValue('convenio', e) : setFieldValue('convenio', {});
	}

	function allHasBoletoRegistrado() {
		let result = {
			value: true,
			countNotHas: 0,
		};

		if (values.selectedBoletos.length > 0) {
			values.selectedBoletos.forEach(boleto => {
				if (!boleto?.boleto) {
					result.value = false;
					result.countNotHas++;
				}
			});
		}

		return result;
	}

	function allNotHasBoletoRegistrado() {
		let result = {
			value: true,
			countNotHas: 0,
		};

		if (values.selectedBoletos.length > 0) {
			values.selectedBoletos.forEach(boleto => {
				if (boleto?.boleto && !['FALHA', 'REJEITADO'].includes(boleto.boleto.situacao)) {
					result.value = false;
					result.countNotHas++;
				}
			});
		}

		return result;
	}

	function allNotHasBoletoRegistradoWithFalha() {
		let result = {
			value: true,
			countNotHas: 0,
		};

		if (values.selectedBoletos.length > 0) {
			values.selectedBoletos.forEach(boleto => {
				if (!['FALHA', 'REJEITADO'].includes(boleto?.boleto?.situacao)) {
					result.value = false;
					result.countNotHas++;
				}
			});
		}

		return result;
	}

	async function emitirBoletos() {
		await props.handleSubmit();

		if (await validarFormulario(propsForm)) {
			const existeBoletosRegistrados = allNotHasBoletoRegistrado();

			if (existeBoletosRegistrados.countNotHas > 0) {
				notify('Existem registros selecionados com boletos já emitidos', ToastTypes.ERROR);
				return;
			}

			const boletos = getIdBoletosSelected(values.selectedBoletos);

			if (boletos.length > 0) {
				setExibirLoading(true);
				setMessageLoading(`Gerando ${boletos.length === 1 ? 'boleto' : 'boletos'}...`);

				asyncGerarBoletos(
					values.convenio.value,
					boletos,
					async ({ data: pdf }) => {
						let arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						let arquivoURL = URL.createObjectURL(arquivoPDF);
						let boleto = window.open(arquivoURL);
						if (boleto) {
							boleto.onload = () => {
								setTimeout(() => {
									boleto.document.title = boletos.length === 1 ? 'Boleto' : 'Boletos';
								}, 250);
							};
						}
						setExibirLoading(false);

						await pesquisar();
						setBoletosSelecionados(values.selectedBoletos);
						document
							.getElementById('overlayPanel-emitir-boletos')
							?.getElementsByClassName('p-overlaypanel-close p-link')[0]
							?.click();
					},
					() => {
						setBoletosSelecionados(values.selectedBoletos);
						onClickSincronizar();
						document
							.getElementById('overlayPanel-emitir-boletos')
							?.getElementsByClassName('p-overlaypanel-close p-link')[0]
							?.click();
						setExibirLoading(false);
					}
				);
			}
		} else {
			validarEmissaoBoletos();
		}
	}

	function validarEmissaoBoletos() {
		if (values.selectedBoletos) {
			let errorPessoa = 0;
			let errorVencimento = 0;
			let errorVencimentoMenorQueEmissao = 0;
			let erroVencimentoMenorQueHoje = 0;

			if (values.selectedBoletos.length > 0) {
				values.selectedBoletos?.forEach(boleto => {
					if (!boleto.pessoa) {
						errorPessoa++;
					}
					if (!boleto.vencimento) {
						errorVencimento++;
					}
					if (isBefore(parseISO(boleto.vencimento), parseISO(boleto.emissao))) {
						errorVencimentoMenorQueEmissao++;
					}
					if (isBefore(parseISO(boleto.vencimento), startOfDay(new Date()))) {
						erroVencimentoMenorQueHoje++;
					}
				});

				if (errorPessoa > 0) {
					notify(
						errorPessoa === 1
							? 'Registro selecionado sem pessoa vinculada'
							: 'Existem registros selecionados sem pessoa vinculada',
						ToastTypes.ERROR
					);
				} else if (errorVencimento > 0) {
					notify(
						errorVencimento === 1
							? 'Registro selecionado sem data de vencimento'
							: 'Existem registros selecionados sem data de vencimento',
						ToastTypes.ERROR
					);
				} else if (errorVencimentoMenorQueEmissao > 0) {
					notify(
						errorVencimentoMenorQueEmissao === 1
							? 'Registro selecionado com data de vencimento menor que a emissão'
							: 'Existem registros selecionados com data de vencimento menor que a emissão',
						ToastTypes.ERROR
					);
				} else if (erroVencimentoMenorQueHoje > 0) {
					notify(
						erroVencimentoMenorQueHoje === 1
							? 'Registro selecionado com data de vencimento menor que hoje'
							: 'Existem registros selecionados com data de vencimento menor que hoje',
						ToastTypes.ERROR
					);
				}
			} else {
				notify('Nenhum boleto selecionado para emissão', ToastTypes.ERROR);
			}
		}
	}

	function existeClientesDiferentes() {
		let clientesPresentes = [];

		values.selectedBoletos?.forEach(boleto => {
			if (!clientesPresentes?.includes(boleto.pessoaCodigo)) {
				clientesPresentes.push(boleto.pessoaCodigo);
			}
		});

		return clientesPresentes.length > 1;
	}

	function onClickEnviarEmail() {
		const boletos = getIdBoletosSelected(values.selectedBoletos);

		if (boletos.length > 0) {
			const boletoRegistrado = allHasBoletoRegistrado();

			if (!existeClientesDiferentes()) {
				if (boletoRegistrado.value) {
					setDadosPessoaParaEmail({
						pessoaNome: values.selectedBoletos[0]?.pessoa,
						pessoaCodigo: values.selectedBoletos[0]?.pessoaCodigo,
						pessoaEmail: values.selectedBoletos[0]?.pessoaEmail,
					});
					setExibirModalEnviarPorEmail(true);
				} else {
					notify(
						boletoRegistrado.countNotHas === 1
							? 'Registro selecionado sem boleto gerado'
							: 'Existem registros selecionados sem boleto gerado',
						ToastTypes.ERROR
					);
				}
			} else {
				notify('Registros selecionados com clientes diferentes', ToastTypes.ERROR);
			}
		} else {
			notify('Selecione ao menos um registro', ToastTypes.ERROR);
		}
	}

	async function onClickImprimir() {
		const boletos = getIdBoletosSelected(values.selectedBoletos);

		if (boletos.length > 0) {
			const boletoRegistrado = allHasBoletoRegistrado();

			if (boletoRegistrado.value) {
				setExibirLoading(true);
				setMessageLoading(`Gerando ${boletos.length === 1 ? 'impressão' : 'impressões'}...`);

				await asyncPostImprimirBoletos(
					boletos,
					({ data: pdf }) => {
						let arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						let arquivoURL = URL.createObjectURL(arquivoPDF);
						let boleto = window.open(arquivoURL);
						if (boleto) {
							boleto.onload = () => {
								setTimeout(() => {
									boleto.document.title = boletos.length === 1 ? 'Boleto' : 'Boletos';
								}, 250);
							};
						}
						setExibirLoading(false);
					},
					() => setExibirLoading(false)
				);
			} else {
				notify(
					boletoRegistrado.countNotHas === 1
						? 'Registro selecionado sem boleto gerado'
						: 'Existem registros selecionados sem boleto gerado',
					ToastTypes.ERROR
				);
			}
		} else {
			notify('Selecione ao menos um registro', ToastTypes.ERROR);
		}
	}

	async function onClickDescartarBoleto() {
		const boletos = getIdBoletosSelected(values.selectedBoletos);

		if (boletos.length > 0) {
			const boletosRegistrado = allHasBoletoRegistrado();
			const boletosRegistradoSemFalha = allNotHasBoletoRegistradoWithFalha();

			if (boletosRegistrado.value) {
				if (boletosRegistradoSemFalha.value) {
					setExibirLoading(true);
					setMessageLoading(`Descartando ${boletos.length === 1 ? 'boleto' : 'boletos'}...`);

					await asyncPostDescartarBoletos(
						boletos,
						async () => {
							await pesquisar();
							setExibirLoading(false);
						},
						() => setExibirLoading(false)
					);
				} else {
					notify(
						boletosRegistradoSemFalha.countNotHas === 1
							? 'Boleto selecionado com situação diferente de falha ou rejeitado'
							: 'Existem boletos selecionados com situação diferente de falha ou rejeitado',
						ToastTypes.ERROR
					);
				}
			} else {
				notify(
					boletosRegistrado.countNotHas === 1
						? 'Registro selecionado sem boleto gerado'
						: 'Existem registros selecionados sem boleto gerado',
					ToastTypes.ERROR
				);
			}
		} else {
			notify('Selecione ao menos um registro', ToastTypes.ERROR);
		}
	}

	return (
		<>
			<>
				<Button
					className="step-listagem-novo"
					label="Emitir boletos"
					color="success"
					icon="fa fa-plus"
					disabled={!podeEditar}
					onClick={e => openEmissaoBoletos(e)}
				/>
				<Button
					className="step-listagem-novo"
					label="Imprimir boletos"
					color="info"
					icon="fa fa-print"
					disabled={!podeVisualizar}
					onClick={onClickImprimir}
				/>
				<Button
					className="step-listagem-novo"
					label={isMobile ? 'Enviar e-mail' : 'Enviar por e-mail'}
					color="info"
					icon="fa fa-paper-plane"
					disabled={!podeVisualizar}
					onClick={onClickEnviarEmail}
				/>
				<Button
					className="p-button-primary"
					label="Opções"
					icon="fa fa-list"
					style={{ margin: '5px' }}
					aria-controls="popup_menu"
					aria-haspopup={true}
					onClick={event => {
						menu.toggle(event);
					}}
				/>
			</>
			<Menu model={itensMenu} popup={true} style={{ width: '220px' }} ref={elemento => setMenu(elemento)} />
			<OverlayPanel
				dismissable={false}
				ref={panelEmissaoBoletos}
				id="overlayPanel-emitir-boletos"
				style={{
					width: isMobile ? '90%' : '100%',
					maxWidth: '30.5rem',
					marginLeft: isMobile ? '1rem' : null,
					marginTop: '0px',
				}}
				onHide={() => {
					buscarContaFavorita();
				}}
				showCloseIcon
			>
				<div className="p-inputgroup" style={{ flexDirection: 'column' }}>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						name="conta"
						label="Conta"
						component={SingleSelectContaComConvenio}
						style={{ flexDirection: 'row', width: '100%' }}
						isClearable={false}
						obrigatorio
						value={values.conta}
						onChange={e => handleChangeConta(e)}
						url={`${services.GESTOR}/v1/contas_receber/relacoes/contas`}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						name="convenio"
						label="Convênio"
						component={Dropdown}
						showClear={false}
						obrigatorio
						options={optionContaConvenios}
						value={values.convenio}
						onChange={e => handleChangeConvenio(e)}
					/>
					<Col sm="12" md="12" lg="12" xl="12">
						<Field
							style={{ width: '100%' }}
							label="Emitir boletos"
							color="success"
							component={Button}
							disabled={!podeInserir}
							onClick={() => emitirBoletos()}
						/>
					</Col>
				</div>
			</OverlayPanel>
		</>
	);
}

export default ActionButtons;
