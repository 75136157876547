export function gerarOptionsDecimais(inicial, final) {
	const options = [];
	for (let i = inicial; i <= final; i++) {
		options.push({ value: i, label: `${i}` });
	}
	return options;
}

export function gerarOptionsAlertaVigencia() {
	const options = [];
	for (let i = 1; i <= 90; i++) {
		options.push({ value: i, label: `${i} ${i > 1 ? 'dias' : 'dia'}` });
	}
	return options;
}
