import { isValidCEP } from '@brazilian-utils/brazilian-utils';
import { buscarMascaraTelefone, mensagensDeValidacao } from 'Common';
import {
	ButtonCancelar,
	ButtonSalvar,
	Checkbox,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputCep,
	InputField,
	InputMask,
	Modal,
	SingleSelectMunicipio,
	SingleSelectPais,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
} from 'components';
import { Field, useFormikContext, withFormik } from 'formik';
import { Fieldset } from 'primereact/fieldset';
import { useEffect } from 'react';
import { validarFormulario } from '../../../../../views/Util';
import { helpNFeDest } from './Help';
import { municipioParaModal } from './Util/constantes';
import { converterParaFormulario } from './Util/converterParaFormulario';
import { converterParaModal } from './Util/converterParaModal';

const initialvalues = {
	nome: null,
	email: null,
	tipo: null,
	cpf: null,
	cnpj: null,
	idEstrangeiro: null,
	indicadorInscricaoEstadual: null,
	inscricaoEstadual: null,
	destinoOperacao: null,
	consumidorFinal: null,

	endereco: {
		id: null,
		cep: null,
		logradouro: null,
		bairro: null,
		numero: null,
		complemento: null,
		municipio: null,
		pais: null,
		telefone: null,
	},
};

export const optionsIndicadorInscricaoEstadual = [
	{ label: '9 - Não contribuinte', value: 'NAO_CONTRIBUINTE' },
	{ label: '1 - Contribuinte ICMS', value: 'CONTRIBUINTE' },
	{ label: '2 - Contribuinte isento', value: 'CONTRIBUINTE_ISENTO' },
];

export const optionsTipoInscricao = [
	{ label: 'Pessoa física', value: 'FISICA' },
	{ label: 'Pessoa jurídica', value: 'JURIDICA' },
	{ label: 'Pessoa estrangeira', value: 'ESTRANGEIRO' },
];

export const optionsDestinoOperacao = [
	{ label: '1 - Operação interna', value: 'OPERACAO_INTERNA' },
	{ label: '2 - Operação interestadual', value: 'OPERACAO_INTERESTADUAL' },
	{ label: '3 - Operação com exterior', value: 'OPERACAO_COM_EXTERIOR' },
];

function ModalDadosDestinatarioView({ onChange, visible, onHide, informacoesPermissoes, ...props }) {
	const { values, setFieldValue, errors, handleSubmit } = useFormikContext();

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('NfeDadosDestInputFieldEmail')?.focus();
		}, 500);
	}, []);

	function cancelar() {
		onHide();
	}

	async function onChangeCep(elemento) {
		if (elemento.pesquisou) {
			elemento.bairro && (await setFieldValue('endereco.bairro', elemento.bairro));
			elemento.complemento && (await setFieldValue('endereco.complemento', elemento.complemento));
			elemento.logradouro && (await setFieldValue('endereco.logradouro', elemento.logradouro));
			elemento.municipio &&
				(await setFieldValue('endereco.municipio', {
					label: `${elemento.municipio.nome} - ${elemento.municipio.estado.sigla}`,
					registro: elemento.municipio,
					value: elemento.municipio.id,
				}));
			elemento.pais && (await setFieldValue('endereco.pais', { label: elemento.pais.nome, value: elemento.pais.id }));
			setFieldValue('endereco.cep', elemento.value);
		} else {
			setFieldValue('endereco.cep', elemento.value);
		}
	}

	async function confirmar() {
		handleSubmit();

		if (await validarFormulario(props)) {
			onChange(converterParaFormulario(values));
			onHide();
		}
	}

	return (
		<Modal header="Dados do destinatário" visible={visible} onHide={onHide}>
			<Form>
				<FormActions>
					<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={cancelar} />
					<ButtonSalvar onClick={confirmar} estadoBotao={estadosBotaoSalvar.CONFIRMAR} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="6"
							md="6"
							lg="6"
							xl="6"
							component={InputField}
							label="Nome"
							value={values.nome}
							onChange={(elemento) => setFieldValue('nome', elemento.target.value)}
							helpMessage={helpNFeDest.nome}
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="6"
							lg="6"
							xl="6"
							component={InputField}
							size={60}
							label="E-mail"
							value={values.email}
							onChange={(elemento) => setFieldValue('email', elemento.target.value)}
							helpMessage={helpNFeDest.email}
							id="NfeDadosDestInputFieldEmail"
							name="email"
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="6"
							lg="6"
							xl="6"
							component={Dropdown}
							obrigatorio
							label="Tipo de inscrição"
							name="tipo"
							onChange={(elemento) => setFieldValue('tipo', elemento.value)}
							options={optionsTipoInscricao}
							value={values.tipo}
							showClear={false}
							helpMessage={helpNFeDest.tipo}
							{...informacoesPermissoes}
						/>
						<If test={values.tipo === 'FISICA'}>
							<Field
								sm="6"
								md="6"
								lg="6"
								xl="6"
								component={InputMask}
								mask="000.000.000-00"
								placeholder="   .   .   -  "
								label="CPF "
								name="cpf"
								value={values.cpf}
								onChange={(elemento) => setFieldValue('cpf', elemento.target.value)}
								helpMessage={helpNFeDest.CPF}
								{...informacoesPermissoes}
							/>
						</If>
						<If test={values.tipo === 'JURIDICA'}>
							<Field
								sm="6"
								md="6"
								lg="6"
								xl="6"
								component={InputMask}
								mask="00.000.000/0000-00"
								placeholder="  .   .   /    -  "
								label="CNPJ"
								name="cnpj"
								onChange={(elemento) => setFieldValue('cnpj', elemento.target.value)}
								value={values.cnpj}
								helpMessage={helpNFeDest.CNPJ}
								{...informacoesPermissoes}
							/>
						</If>
						<If test={values.tipo === 'ESTRANGEIRO'}>
							<Field
								sm="6"
								md="6"
								lg="6"
								xl="6"
								component={InputField}
								size={20}
								name="idEstrangeiro"
								label="Identificação do estrangeiro"
								value={values.idEstrangeiro}
								onChange={(elemento) => setFieldValue('idEstrangeiro', elemento.target.value)}
								{...informacoesPermissoes}
							/>
						</If>
					</Grid>
					<Fieldset legend="Fiscal" className="fieldset">
						<Grid>
							<Field
								sm="6"
								md="4"
								lg="4"
								xl="4"
								component={Dropdown}
								obrigatorio
								label="Indicador de IE"
								onChange={async (elemento) => {
									await setFieldValue('indicadorInscricaoEstadual', elemento.value);
									if (elemento.value === 'CONTRIBUINTE_ISENTO') {
										setFieldValue('IE', null);
									}
								}}
								name="indicadorInscricaoEstadual',"
								options={optionsIndicadorInscricaoEstadual}
								value={values.indicadorInscricaoEstadual}
								showClear={false}
								helpMessage={helpNFeDest.indicadorInscricaoEstadual}
								{...informacoesPermissoes}
							/>
							<If test={values.indicadorInscricaoEstadual !== 'CONTRIBUINTE_ISENTO'}>
								<Field
									sm="6"
									md="4"
									lg="4"
									xl="4"
									component={InputField}
									size={14}
									type={values && values.indicadorInscricaoEstadual === 'CONTRIBUINTE' ? 'number' : 'text'}
									label="Inscrição estadual"
									value={values.inscricaoEstadual}
									name="inscricaoEstadual"
									onChange={(elemento) =>
										setFieldValue('inscricaoEstadual', elemento.target.value ? elemento.target.value.toUpperCase() : '')
									}
									helpMessage={helpNFeDest.inscricaoEstadual}
									{...informacoesPermissoes}
								/>
							</If>
							<Field
								sm="6"
								md="4"
								lg="4"
								xl="4"
								component={Dropdown}
								obrigatorio
								label="Destino operação"
								name="destinoOperacao"
								onChange={(elemento) => {
									setFieldValue('destinoOperacao', elemento.value);
								}}
								touched={!!props.errors.destinoOperacao}
								options={optionsDestinoOperacao}
								value={values.destinoOperacao}
								showClear={false}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Checkbox}
								colStyle={{ paddingTop: '10px' }}
								label="O destinatário é consumidor final"
								name="consumidorFinal"
								onChange={() => setFieldValue('consumidorFinal', !values.consumidorFinal)}
								checked={values.consumidorFinal}
								{...informacoesPermissoes}
							/>
						</Grid>
					</Fieldset>
					<Fieldset legend="Endereço" className="fieldset">
						<Grid>
							<Field
								sm="6"
								md="4"
								lg="4"
								xl="4"
								component={InputCep}
								label="CEP"
								name="cep"
								type="tel"
								obrigatorio
								value={values.endereco?.cep}
								errors={errors.cep}
								useFormTouched={false}
								touched={!!errors.cep}
								disabled={values.municipio?.value === municipioParaModal.EXTERIOR}
								onChange={(elemento) => onChangeCep(elemento)}
								{...informacoesPermissoes}
							/>
							<Field
								sm="6"
								md="4"
								lg="4"
								xl="4"
								component={InputField}
								size={60}
								label="Logradouro"
								name="endereco.logradouro"
								obrigatorio
								value={values.endereco.logradouro}
								errors={errors.logradouro}
								useFormTouched={false}
								touched={!!errors.logradouro}
								onChange={(elemento) => setFieldValue('endereco.logradouro', elemento.target.value)}
								{...informacoesPermissoes}
							/>
							<Field
								sm="6"
								md="4"
								lg="4"
								xl="4"
								component={InputField}
								size={60}
								label="Bairro"
								name="bairro"
								obrigatorio
								value={values.endereco.bairro}
								errors={errors.bairro}
								useFormTouched={false}
								touched={!!errors.bairro}
								onChange={(elemento) => setFieldValue('endereco.bairro', elemento.target.value)}
								{...informacoesPermissoes}
							/>
							<Field
								sm="6"
								md="4"
								lg="4"
								xl="4"
								component={InputField}
								size={60}
								label="Número"
								name="numero"
								obrigatorio
								value={values.endereco.numero}
								errors={errors.numero}
								useFormTouched={false}
								touched={!!errors.numero}
								onChange={(elemento) => setFieldValue('endereco.numero', elemento.target.value)}
								{...informacoesPermissoes}
							/>
							<Field
								sm="6"
								md="4"
								lg="4"
								xl="4"
								component={InputField}
								size={60}
								label="Complemento"
								name="complemento"
								value={values.endereco.complemento}
								onChange={(elemento) => setFieldValue('endereco.complemento', elemento.target.value)}
								{...informacoesPermissoes}
							/>
							<Field
								sm="6"
								md="4"
								lg="4"
								xl="4"
								component={InputMask}
								mask={buscarMascaraTelefone(values?.telefone)}
								label="Telefone"
								name="telefone"
								placeholder="(  )      -    "
								value={values.telefone}
								onChange={(elemento) => setFieldValue('telefone', elemento.target.value)}
								{...informacoesPermissoes}
							/>
							<Field
								sm="6"
								md="4"
								lg="4"
								xl="4"
								component={SingleSelectPais}
								label="País "
								name="pais"
								obrigatorio
								errors={errors.pais}
								useFormTouched={false}
								touched={!!errors.pais}
								onChange={(elemento) => setFieldValue('endereco.pais', elemento)}
								value={values.endereco.pais}
								{...informacoesPermissoes}
							/>
							<Field
								sm="6"
								md="4"
								lg="4"
								xl="4"
								component={SingleSelectMunicipio}
								label="Município "
								name="municipio"
								obrigatorio
								errors={errors.municipio}
								useFormTouched={false}
								touched={!!errors.municipio}
								onChange={(elemento) => setFieldValue('endereco.municipio', elemento)}
								value={values.endereco.municipio}
								{...informacoesPermissoes}
							/>
						</Grid>
					</Fieldset>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalDadosDestinatario = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.valuesDestinatario) {
			return converterParaModal(props.valuesDestinatario?.registro);
		} else {
			return initialvalues;
		}
	},

	validate(value) {
		const errors = {};

		if (!value.destinoOperacao) {
			errors.destinoOperacao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!value.endereco.logradouro) {
			errors.logradouro = mensagensDeValidacao.OBRIGATORIO;
		}

		if (value.endereco.logradouro && value.endereco.logradouro.length < 2) {
			errors.logradouro = 'O logradouro deve conter no minimo 2 caracteres';
		}

		if (!value.endereco.bairro) {
			errors.bairro = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!value.endereco.numero) {
			errors.numero = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!value.endereco.pais) {
			errors.pais = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!value.endereco.municipio) {
			errors.municipio = mensagensDeValidacao.OBRIGATORIO;
		}

		if (value.endereco.municipio?.value !== municipioParaModal.EXTERIOR) {
			if (!value.endereco.cep) {
				errors.cep = mensagensDeValidacao.OBRIGATORIO;
			} else if (!isValidCEP(value.endereco.cep)) {
				errors.cep = mensagensDeValidacao.CEP_INVALIDO;
			}
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalDadosDestinatarioView);

export default ModalDadosDestinatario;
