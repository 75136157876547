import { Button, ButtonCancelar, estadosBotaoCancelar } from 'components';
import { useFormikContext } from 'formik';
import { validarFormulario } from 'views/Util';
import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { converterMDFeDFeParaApi } from 'views/transporte/MDFe/Form/Util/MDFeConverter';
import { createDFeMDFe } from 'views/transporte/MDFe/Requests';

function ActionButtons({ onHide, canTransmitir, id }) {
	const { informacoesPermissoes, setMessageLoading, setExibirLoadingTransmissao } = useContextMDFe();
	const { values, dirty, handleReset, handleSubmit, validateForm } = useFormikContext();

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			onHide();
		}
	}

	async function handleClickTransmitir() {
		handleSubmit();

		if (await validarFormulario({ values, validateForm })) {
			setExibirLoadingTransmissao(true);
			setMessageLoading('Transmitindo DF-e...');
			await createDFeMDFe(
				id,
				converterMDFeDFeParaApi(values),
				() => {
					setExibirLoadingTransmissao(false);
					onHide(true);
				},
				() => setExibirLoadingTransmissao(false)
			);
		}
	}

	return (
		<>
			<ButtonCancelar onClick={handleClickCancelar} estadoBotao={estadoBotaoCancelar} {...informacoesPermissoes} />
			<Button
				className="p-button-success"
				label="Transmitir"
				icon="fa fa-send"
				onClick={handleClickTransmitir}
				style={{ margin: '5px' }}
				disabled={!dirty}
				hidden={!canTransmitir}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export { ActionButtons };
