export const DATA_ACTION_TYPES = {
	SET_DATA: 'SET_DATA',
	ADD_RANKING_MOTORISTAS: 'ADD_RANKING_MOTORISTAS',
	ADD_RANKING_VEICULOS: 'ADD_RANKING_VEICULOS',
	ADD_RANKING_ESTADOS: 'ADD_RANKING_ESTADOS',
};

export function setData(data) {
	return {
		type: DATA_ACTION_TYPES.SET_DATA,
		payload: {
			...data,
		},
	};
}

export function addDataRankingMotoristas(data) {
	return {
		type: DATA_ACTION_TYPES.ADD_RANKING_MOTORISTAS,
		payload: {
			...data,
		},
	};
}

export function addDataRankingVeiculos(data) {
	return {
		type: DATA_ACTION_TYPES.ADD_RANKING_VEICULOS,
		payload: {
			...data,
		},
	};
}

export function addDataRankingEstados(data) {
	return {
		type: DATA_ACTION_TYPES.ADD_RANKING_ESTADOS,
		payload: {
			...data,
		},
	};
}
