import { tipoCampos } from '../../../../components/PesquisaAvancada/Util/constantes';

export const OPTIONS_FILTRO_AVANCADO = [
	{ label: 'Nome', name: 'nome', type: tipoCampos.STRING },
	{ label: 'E-mail', name: 'credencial.email', type: tipoCampos.STRING },
	{
		label: 'Ativo',
		name: 'credencial.ativa',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Sim', value: 'true' },
			{ label: 'Não', value: 'false' },
		],
	},
	{
		label: 'Situação',
		name: 'credencial.bloqueada',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Bloqueada', value: 'true' },
			{ label: 'Desbloqueada', value: 'false' },
		],
	},
];

export const INITIAL_VALUES = {
	id: '',
	nome: '',
	email: '',
	celular: null,
	ativa: '',
	bloqueada: '',
	papeis: [],
	setores: [],
	filiaisAcesso: [],
	autoridade: 'USER',
};
