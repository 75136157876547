import { montarLabelPessoa } from 'components/select/SingleSelectPessoa/Util';

function converterObjetoDeServicoParaFormulario(objetoDeServicoApi) {
	return {
		id: objetoDeServicoApi.id,
		codigo: objetoDeServicoApi.codigo,
		nome: objetoDeServicoApi.nome,
		cliente: converterClienteParaFormulario(objetoDeServicoApi.cliente),
		situacao: objetoDeServicoApi.situacao,
		tipo: objetoDeServicoApi.tipo,
		marca: converterSelectParaFormulario(objetoDeServicoApi.marca),
		modelo: converterSelectParaFormulario(objetoDeServicoApi.modelo),
		anoFabricacao: objetoDeServicoApi.anoFabricacao,
		observacao: objetoDeServicoApi.observacao,
		veiculo: {
			placa: objetoDeServicoApi.veiculo?.veiculoPlaca,
			chassi: objetoDeServicoApi.veiculo?.veiculoChassi,
			renavam: objetoDeServicoApi.veiculo?.veiculoRenavam,
			quilometragem: objetoDeServicoApi.veiculo?.veiculoQuilometragem,
		},
		eletronico: {
			serie: objetoDeServicoApi.eletronico?.eletronicoSerie,
		},
	};
}

function converterClienteParaFormulario(clienteApi) {
	if (clienteApi) {
		return {
			label: montarLabelPessoa(clienteApi),
			value: clienteApi.id,
			registro: clienteApi,
		};
	}
	return null;
}

function converterSelectParaFormulario(selectApi) {
	if (selectApi) {
		return {
			label: selectApi.nome,
			value: selectApi.id,
			registro: selectApi,
		};
	}
	return null;
}

function converterObjetoDeServicoParaApi(objetoDeServicoForm) {
	return {
		id: objetoDeServicoForm.id,
		codigo: objetoDeServicoForm.codigo,
		nome: objetoDeServicoForm.nome,
		cliente: objetoDeServicoForm?.cliente?.value ? { id: objetoDeServicoForm?.cliente?.value } : null,
		situacao: objetoDeServicoForm.situacao,
		tipo: objetoDeServicoForm.tipo,
		marca: objetoDeServicoForm?.marca?.value ? { id: objetoDeServicoForm?.marca?.value } : null,
		modelo: objetoDeServicoForm?.modelo?.value ? { id: objetoDeServicoForm?.modelo?.value } : null,
		anoFabricacao: objetoDeServicoForm?.anoFabricacao,
		observacao: objetoDeServicoForm?.observacao,
		veiculo: {
			veiculoPlaca: objetoDeServicoForm?.veiculo?.placa,
			veiculoChassi: objetoDeServicoForm?.veiculo?.chassi,
			veiculoRenavam: objetoDeServicoForm?.veiculo?.renavam,
			veiculoQuilometragem: objetoDeServicoForm?.veiculo?.quilometragem,
		},
		eletronico: {
			eletronicoSerie: objetoDeServicoForm?.eletronico?.serie,
		},
	};
}

function converterParaDuplicar(objetoDeServico) {
	return {
		id: null,
		codigo: objetoDeServico.codigo,
		nome: objetoDeServico.nome,
		cliente: objetoDeServico.cliente,
		situacao: objetoDeServico.situacao,
		tipo: objetoDeServico.tipo,
		marca: objetoDeServico.marca,
		modelo: objetoDeServico.modelo,
		anoFabricacao: objetoDeServico.anoFabricacao,
		observacao: objetoDeServico.observacao,
		veiculo: {
			placa: objetoDeServico.veiculo?.placa,
			chassi: objetoDeServico.veiculo?.chassi,
			renavam: objetoDeServico.veiculo?.renavam,
			quilometragem: objetoDeServico.veiculo?.quilometragem,
		},
		eletronico: {
			serie: objetoDeServico.eletronico?.serie,
		},
	};
}

export { converterObjetoDeServicoParaFormulario, converterObjetoDeServicoParaApi, converterParaDuplicar };
