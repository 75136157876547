import { get, services } from 'Common';

export async function asyncBaixarRelatorioOrdemServicoPeriodo(
  dataInicial,
  dataFinal,
  cliente,
  situacao,
  setor,
  onSuccess,
  onError
) {
  return await get(
    `${
      services.GESTOR
    }/v1/ordem_servico/imprimir/ordem_servico_periodo?dataInicial=${dataInicial}&dataFinal=${dataFinal}${
      cliente ? '&clientes=' + cliente : '&clientes='
    }&situacao=${situacao}&setor=${setor}`,
    { responseType: 'arraybuffer' },
    onSuccess,
    onError
  );
}

export async function asyncBaixarRelatorioNotaServicoPeriodo(
  dataInicial,
  dataFinal,
  situacao,
  cliente,
  setor,
  onSuccess,
  onError
) {
  return await get(
    `${services.GESTOR}/v1/nfses/imprimir/nota_servico_periodo?dataInicial=${dataInicial}&dataFinal=${dataFinal}${
      cliente ? '&clientes=' + cliente : '&clientes='
    }&situacao=${situacao}&setor=${setor}`,
    { responseType: 'arraybuffer' },
    onSuccess,
    onError
  );
}
