import { TabView } from 'components';
import { TabPanel } from 'primereact/tabview';
import { FormObservacoes } from './FormObservacoes';
import { FormResponsavel } from './FormResponsavel';

function Tabs({ informacoesPermissoes, disabledAlertaVigencia }) {
	return (
		<TabView className="tab-view" {...informacoesPermissoes}>
			<TabPanel header="Responsável">
				<FormResponsavel
					informacoesPermissoes={informacoesPermissoes}
					disabledAlertaVigencia={disabledAlertaVigencia}
				/>
			</TabPanel>
			<TabPanel header="Observações">
				<FormObservacoes informacoesPermissoes={informacoesPermissoes} />
			</TabPanel>
		</TabView>
	);
}

export { Tabs };
