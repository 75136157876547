import { useEffect } from 'react';
import * as Yup from 'yup';
import { withFormik, Field } from 'formik';
import { isValid, parseISO, formatISO, startOfMonth, format } from 'date-fns';
import { helpMessage } from './util/constantes';
import { asyncImprimirPagamentosRecebidosPorPeriodo } from '../../Requests';

import { mensagensDeValidacao } from 'Common';
import { Dropdown, Grid, InputDate, ModalRelatorio } from 'components';

const initialValue = {
	dataInicial: formatISO(startOfMonth(new Date())),
	dataFinal: formatISO(new Date()),
	formasPagamento: 'Todas',
};

function ModalImprimirPagamentosRecebidosPorPeriodoView(props) {
	const { visible, onHide, values, handleSubmit, validateForm, isValid, setFieldValue } = props;

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('RelAdminstracaoPagamentosInputDateDataInicial')?.focus();
		}, 500);
	});

	async function imprimir() {
		await handleSubmit();
		await validateForm();
		if (isValid) {
			await asyncImprimirPagamentosRecebidosPorPeriodo(
				format(parseISO(values.dataInicial), 'yyyy-MM-dd'),
				format(parseISO(values.dataFinal), 'yyyy-MM-dd'),
				values.formasPagamento,
				({ data: pdf }) => {		
					let arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
					let arquivoURL = URL.createObjectURL(arquivoPDF);
					let relatorio = window.open(arquivoURL);
					if (relatorio) {
						relatorio.onload = () => {
							setTimeout(() => {
								relatorio.document.title = `Relatório pagamentos recebidos - ${format(parseISO(values.dataInicial), 'dd-MM-yyyy')} - ${format(
									parseISO(values.dataFinal),
									'dd-MM-yyyy'
								)}`;
							}, 250);
						};
					}
					onHide();
				}
			);
		}
	}

	const parametros = (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputDate}
				id="RelAdminstracaoPagamentosInputDateDataInicial"
				label="Data inicial"
				name="dataInicial"
				obrigatorio
				helpMessage={helpMessage.dataInicial}
				value={values.dataInicial}
				touched={true}
			/>

			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputDate}
				label="Data final"
				name="dataFinal"
				obrigatorio
				helpMessage={helpMessage.dataFinal}
				value={values.dataFinal}
				touched={true}
			/>
			 <Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={Dropdown}
				label="Forma(s) pagamento"
				name="formasPagamento"
				obrigatorio
				onChange={e => setFieldValue('formasPagamento', e.value)}
				options={[
				{ value: 'PIX', label: 'Pix' },
				{ value: 'CARTAO', label: 'Cartão' },
				{ value: 'Todas', label: 'Todas'},
				]}
				value={values.formasPagamento}
				showClear={false}
			/>
		</Grid>
	);

	return (
		<ModalRelatorio
			header={'Relatório pagamentos recebidos por período'}
			visible={visible}
			onHide={onHide}
			onImprimir={imprimir}
			parametros={parametros}
		/>
	);
}

const ModalImprimirPagamentosRecebidosPorPeriodo = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValue;
	},

	validate(values) {
		let errors = {};
		let dataInicial = format(parseISO(values.dataInicial), 'dd-MM-yyyy');
		let dataFinal = format(parseISO(values.dataFinal), 'dd-MM-yyyy');

		if (!dataInicial || dataInicial === '') {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (dataInicial && !isValid(parseISO(values.dataInicial))) {
			errors.dataInicial = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (!dataFinal || dataFinal === '') {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		if (dataFinal && !isValid(parseISO(values.dataFinal))) {
			errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (dataInicial > dataFinal) {
			errors.dataInicial = 'Data inicial não pode ser maior que a data final';
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		dataInicial: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		dataFinal: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
	}),

	handleSubmit: () => {},
})(ModalImprimirPagamentosRecebidosPorPeriodoView);

export default ModalImprimirPagamentosRecebidosPorPeriodo;
