import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { services } from '../../../../../../Common';
import {
	Col,
	Dropdown,
	Grid,
	If,
	InputDouble,
	InputField,
	LinkVerDados,
	SingleSelectPessoa,
	Divider,
	SingleSelectEstado,
} from '../../../../../../components';
import { optionsModalidadeFrete } from '../../../Util/constantes';
import ModalDadoTransportadora from './components/ModalDadoTransportadora';
import { TipoPessoa } from '../../../../../cadastros/Pessoas/Util/constantes';

function FieldsTransporteView({ isFinalizado, onSave }) {
	const { values, setFieldValue } = useFormikContext();

	const [modalDadosTransportadora, setModalDadosTransportadora] = useState(false);

	function onChangeTransportadora(value) {
		if (value !== null) {
			setFieldValue('transportadora', {
				id: values.transportadora?.id,
				label: value.label,
				value: value.value,
				nome: value.registro.nome,
				cpf: value.registro.pessoaFisica?.cpf
					? value.registro.pessoaFisica.cpf
					: value.registro?.cpf
						? value.registro?.cpf
						: null,
				cnpj: value.registro.pessoaJuridica?.cnpj
					? value.registro.pessoaJuridica.cnpj
					: value.registro?.cnpj
						? value.registro?.cnpj
						: null,
				inscricaoEstadual: value.registro?.inscricaoEstadual,
				endereco: setEndereco(value.registro),
				municipio: setMunicipio(value.registro),
				transportadora: value.registro,
			});
		} else {
			setFieldValue('transportadora', {
				id: values.transportadora?.id,
				label: null,
				value: null,
				nome: null,
				cpf: null,
				cnpj: null,
				inscricaoEstadual: null,
				endereco: null,
				municipio: null,
				transportadora: null,
			});
		}
	}

	function onChangeUfVeiculo(value) {
		if (value !== null) {
			setFieldValue('veiculo.estado', {
				label: value.label,
				value: value.value,
			});
		} else {
			setFieldValue('veiculo.estado', null);
		}
	}

	function setEndereco(registro) {
		let result = null;
		if (registro.enderecos?.length > 0) {
			registro.enderecos.forEach((endereco) => {
				if (endereco.favorito) {
					result = `${endereco?.logradouro !== null ? endereco.logradouro : ''}${
						endereco?.numero !== null ? ' - ' + endereco.numero : ''
					}${endereco?.bairro !== null ? ', ' + endereco.bairro : ''}`;
				}
			});
		} else result = null;
		return result;
	}

	function setMunicipio(registro) {
		let result = null;
		if (registro.enderecos?.length > 0) {
			registro.enderecos.forEach((endereco) => {
				if (endereco.favorito) {
					result = {
						label: `${endereco.municipio.nome} - ${endereco.municipio.estadoSigla}`,
						value: endereco.municipio.id,
					};
				}
			});
		} else result = null;
		return result;
	}

	useUpdateEffect(() => {
		onSave(values);
	}, [values]);

	function handleSaveDadosTransportadora(registro) {
		setFieldValue('transportadora', registro);
		setModalDadosTransportadora(false);
	}

	return (
		<>
			<Grid>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					label="Responsável pelo frete"
					component={Dropdown}
					value={values.modalidadeFrete}
					name="modalidadeFrete"
					options={optionsModalidadeFrete}
					onChange={(value) => setFieldValue('modalidadeFrete', value)}
					showClear={false}
					disabled={isFinalizado}
				/>
				<If test={values.modalidadeFrete !== 'SEM_FRETE' && values.modalidadeFrete?.value !== 'SEM_FRETE'}>
					<Col sm="12" md="4" lg="4" xl="4">
						<Field
							colStyle={{ padding: '0px' }}
							label="Transportadora"
							name="transportadora"
							component={SingleSelectPessoa}
							value={values.transportadora?.transportadora !== null ? values.transportadora : null}
							url={`${services.GESTOR}/v1/pessoas/transportadores`}
							onChange={(value) => onChangeTransportadora(value)}
							tipoPessoa={TipoPessoa.TRANSPORTADOR}
							labelConcatCpfCnpj={false}
							disabled={isFinalizado}
						/>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
							}}
						>
							<If test={values.transportadora !== null && values.transportadora?.value !== null}>
								<LinkVerDados
									label="Ver dados da transportadora"
									onClick={() => setModalDadosTransportadora(true)}
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										position: 'relative',
										margin: '2px 25px -20px 0',
									}}
								/>
							</If>
						</div>
					</Col>
					<Field
						sm="12"
						md="2"
						lg="2"
						xl="2"
						label="Placa do veículo"
						name="placa"
						component={InputField}
						value={values.veiculo?.placa}
						onChange={(value) => setFieldValue('veiculo.placa', value.target?.value)}
						size={7}
						disabled={isFinalizado}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="UF do veículo"
						name="estado"
						component={SingleSelectEstado}
						value={values.veiculo?.estado}
						onChange={(value) => onChangeUfVeiculo(value)}
						disabled={isFinalizado}
					/>
				</If>
				<Divider label="Volumes" styleLabel={{ fontSize: '14px' }} />
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Quantidade"
					name="quantidade"
					component={InputDouble}
					value={values.volume?.quantidade}
					onChange={(value) => setFieldValue('volume.quantidade', value.target?.value)}
					disabled={isFinalizado}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Espécie"
					name="especie"
					component={InputField}
					value={values.volume?.especie}
					onChange={(value) => setFieldValue('volume.especie', value.target?.value)}
					size={60}
					disabled={isFinalizado}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Marca"
					name="marca"
					component={InputField}
					value={values.volume?.marca}
					onChange={(value) => setFieldValue('volume.marca', value.target?.value)}
					size={60}
					disabled={isFinalizado}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Numeração"
					name="numeracao"
					component={InputField}
					value={values.volume?.numeracao}
					onChange={(value) => setFieldValue('volume.numeracao', value.target?.value)}
					size={60}
					disabled={isFinalizado}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Peso bruto total"
					name="pesoBruto"
					component={InputDouble}
					value={values.volume?.pesoBruto}
					onChange={(value) => setFieldValue('volume.pesoBruto', value.target?.value)}
					disabled={isFinalizado}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Peso líquido total"
					name="pesoLiquido"
					component={InputDouble}
					value={values.volume?.pesoLiquido}
					onChange={(value) => setFieldValue('volume.pesoLiquido', value.target?.value)}
					disabled={isFinalizado}
				/>
			</Grid>
			<ModalDadoTransportadora
				onHide={() => setModalDadosTransportadora(false)}
				visible={modalDadosTransportadora}
				isFinalizado={isFinalizado}
				valuesDadosTransportadora={values?.transportadora}
				onSave={(e) => handleSaveDadosTransportadora(e)}
			/>
		</>
	);
}

const FieldsTransporteForm = withFormik({
	enableReinitialize: false,

	mapPropsToValues(props) {
		if (props.values.transportadora?.label === undefined) {
			return { ...props.values, transportadora: null };
		} else {
			return props.values;
		}
	},

	handleSubmit: () => {},
})(FieldsTransporteView);

const mapStateToProps = (state) => ({
	isMobile: state.isMobile,
});

export default withRouter(connect(mapStateToProps)(FieldsTransporteForm));
