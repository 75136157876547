import { Icon } from '@iconify/react';
import './Styles/index.css';
import { buscarDadosLoginLocalStorage, replaceCaracteresEspeciais } from 'Common';
import { TIPO_APLICACAO } from 'views/configuracao/NotasDeVersao/Utils/constantes';

function carregarOpaSuite(carregarOpaSuite = true) {
	if (!carregarOpaSuite) {
		return null;
	}

	const localStorage = buscarDadosLoginLocalStorage();
	const texto = `Olá, sou ${localStorage?.nome} e falo em nome da ${
		localStorage?.organizacao?.nome
	}. Preciso de suporte para a solução ${
		localStorage?.aplicacao === TIPO_APLICACAO.DOCS_DIGITAIS ? 'DOCs DIGITAIS' : 'MSYS GESTOR'
	}.`;

	function handleClickWhatsapp() {
		window.open(`https://api.whatsapp.com/send?phone=554936645000&text=${replaceCaracteresEspeciais(texto)}`);
	}

	return (
		<button type="button" className="opa-suite" onClick={handleClickWhatsapp}>
			<Icon className="opa-suite-icon" icon="ic:baseline-whatsapp" />
			<span className="opa-suite-text">Atendimento</span>
		</button>
	);
}

export { carregarOpaSuite };
