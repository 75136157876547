import { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { format, formatISO, parse, startOfMonth, endOfMonth } from 'date-fns';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { useUpdateEffect } from 'react-use';

import {
  Col,
  Grid,
  PesquisaAvancada,
  DescricaoFiltroAvancado,
  ButtonEditarTable,
  confirm,
  notify,
  ToastTypes,
  Button,
  InputSearch,
  If,
  ButtonExcluir,
  tutorialStepsListagens,
  Tutorial,
  FormActions,
  Form,
  FormContent,
  NenhumRegistroEncontrado,
  DateInterval,
  ModalHistorico,
  Paginacao,
} from 'components';

import {
  services,
  recursos,
  permissoes,
  construirUrl,
  usuarioPossuiPermissao,
  executarComLoading,
  buscarConfiguracaoUsuario,
  configuracoesUsuario,
  salvarConfiguracaoUsuario,
  formatarParaPesquisarTiposEnumerados,
  formatarMonetario,
} from 'Common';

import {
  asyncExcluirDocumento,
  asyncGetPesquisaDocumentos,
  asyncGetTotalizadores,
  asyncArquivarGestaoDocumento,
  asyncDesarquivarGestaoDocumento,
} from './Requests';

import { optionsFiltroAvancado, statusGestaoDocumento, ColorsCard } from './Util/constantes';
import { renderStatus, aplicarEstiloArquivado, buscarMensagemExcluirDocumento } from './Util/funcoesList';
import { atualizarUrl } from '../../Util';
import { CardsStatusGestaoDocumento } from './components/CardsStatus';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { OverlayPanelInformacoesComplementares } from './components/OverlayPanelInformacoesComplementares';

const buttonTableStyle = {
  borderRadius: '50%',
  padding: '5px',
  width: '30px',
  height: '30px',
  marginLeft: '2.5px',
  marginRight: '2.5px',
  border: 'none',
  color: '#333',
};

function GestaoDocumentos(props) {
  const {
    valorPesquisa,
    setValorPesquisa,
    interval,
    setInterval,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    rows,
    setRows,
    filtroAvancado,
    setFiltroAvancado,
    descricaoFiltroAvancado,
    setDescricaoFiltroAvancado,
    filtroData,
    setFiltroData,
    exibirBloqueadas,
    setExibirBloqueadas,
    selectedCard,
    setSelectedCard,
    valorCard,
    setValorCard,
  } = useContextPesquisa();

  const [podeInserir, setPodeInserir] = useState(
    usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.INSERIR)
  );
  const [podeExcluir, setPodeExcluir] = useState(
    usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.EXCLUIR)
  );
  const [podeEditar, setPodeEditar] = useState(usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.EDITAR));
  const [deveExibirTutorial, setDeveExibirTutorial] = useState(
    buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
  );
  const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
  const [registroSelecionado, setRegistroSelecionado] = useState(null);
  const [modalDocumentoHistoricoVisible, setModalDocumentoHistoricoVisible] = useState(false);
  const [documentosSelecionados, setDocumentosSelecionados] = useState([]);
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const [cards, setCards] = useState({
    pendente: 0,
    rescindido: 0,
    vigenciaExpirada: 0,
    vigente: 0,
  });
  const [registros, setRegistros] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  const [currentDocumentoIndex, setCurrentDocumentoIndex] = useState(null);

  const menuOpcoes = useRef(null);

  const panelInformacoesComplementares = useRef(null);

  const pesquisarCallback = useCallback(() => {
    if (!firstRender){
      pesquisar();
    }  
  });

  useEffect(() => {
    pesquisar();
    const { state } = props.location;

    if (state && state.pesquisaPadrao) {
      setValorPesquisa(state.pesquisaPadrao);
    }

    if (deveExibirTutorial !== false) {
      setTutorialVisible(true);
      salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
    }

    setTimeout(() => {
      document.getElementById('DocumentosInputSearch')?.focus();
    }, 500);
  }, []);

  useUpdateEffect(() => {
    pesquisarCallback();
  }, [exibirBloqueadas, page, rows, sortOrder, sortField, filtroAvancado, interval, selectedCard]);

  function asyncExcluirRegistro() {
    confirm('Atenção', buscarMensagemExcluirDocumento(registroSelecionado), () => {
      asyncExcluirDocumento(registroSelecionado.id, () => {
        notify('Documento excluído', ToastTypes.SUCCESS);
        pesquisar(false);
      });
    });
  }

  function buscarFiltro() {
    const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa.replaceAll('&', ''));
    const currentDate = new Date();
    const startOfTheMonth = startOfMonth(currentDate);
    const endOfTheMonth = endOfMonth(currentDate);

    let filtroRSQL = String('?query=(')
      .concat(`identificacao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)            
      .concat(`status=contains="*${valorTiposEnumerados}*")`);

    if (filtroData) {
      filtroRSQL = filtroRSQL.concat(`;(${filtroData})`);
    } else {
      filtroRSQL = filtroRSQL.concat(
        `;(${`criadoEm>=${formatISO(startOfTheMonth, {
          representation: 'date',
        })};criadoEm<=${formatISO(endOfTheMonth, {
          representation: 'date',
        })}`})`
      );
    }
    if (filtroAvancado) {
      filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
    }
    if (!exibirBloqueadas) {
      filtroRSQL = filtroRSQL.concat(`;arquivado==${false}`);
    }
    if (valorCard) {
      filtroRSQL = filtroRSQL.concat(`;(${valorCard})`);
    }

    return filtroRSQL;
  }

  function pesquisar(exibirLoading) {
    const filtro = buscarFiltro();
    let campoOrdenacao = sortField;
    let sentidoOrdenacao = sortOrder;
    if (firstRender){
      setSortField("vigenciaInicio");
      setSortOrder(-1);
      campoOrdenacao = "vigenciaInicio";
      sentidoOrdenacao = -1;
    }
    const url = construirUrl(
      `${services.GESTOR}/v1/documentos/gestao_documentos/resumo`,
      filtro,
      rows,
      page,
      sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
    );

    asyncGetTotalizadores(interval, exibirBloqueadas, ({ data: totais }) => {
      setCards(totais);
    });

    asyncGetPesquisaDocumentos(
      url,
      ({ data: gestaoDocumento }) => {
        setRegistros(gestaoDocumento.content);
        setTotalElements(gestaoDocumento.totalElements);
        setFirstRender(false);
      },
      null,
      exibirLoading
    );
  }

  function handleShowOverlay(e, rowIndex) {
		document
			.getElementById('overlayPanel-informacoes-complementares')
			?.getElementsByClassName('p-overlaypanel-close p-link')[0]
			?.click();

		setCurrentDocumentoIndex(rowIndex);
		panelInformacoesComplementares.current?.toggle(e);
	}

  function onPesquisar() {
    pesquisar();
  }

  function onPageChange(e) {
    setPage(e.page);
    setRows(e.rows);
  }

  function onSort(e) {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  }

  function onEditar(row) {
    atualizarUrl(props.history, `/documentos/gestao_documentos/cadastro/${row.id}`);
  }

  function onExcluir() {
    asyncExcluirRegistro();
  }

  function renderOpcoes(registroSelecionadoOpcoes, options) {
    const acoesDoBotao = buscarAcoes(registroSelecionadoOpcoes);
    return (
      <div style={{ display: 'flex', padding: 0 }}>
        <Button
					style={{
						borderRadius: '50%',
						padding: '5px',
						width: '30px',
						height: '30px',
					}}
					className="p-button p-button-primary"
					title="Informações complementares"
					icon="fa fa-info"
					onClick={(e) => handleShowOverlay(e, options.rowIndex)}					
				/>


        <ButtonEditarTable onClick={() => onEditar(registroSelecionadoOpcoes)} />
        <Button
          color="secondary"
          style={buttonTableStyle}
          icon="fa fa-ellipsis-v"
          size="icon"
          disabled={Boolean(acoesDoBotao.length === 0)}
          title={Boolean(acoesDoBotao.length === 0) ? 'Nenhuma operação possível para este documento' : null}
          onClick={event => {
            menuOpcoes.current.toggle(event);
            setRegistroSelecionado(registroSelecionadoOpcoes);
          }}
        />
      </div>
    );
  }

  function buscarAcoes(registroSelecionado) {
    let itens = [];

    itens.push({
      label: 'Histórico',
      icon: 'fa fa-history',
      command: () => setModalDocumentoHistoricoVisible(true),
    });

    if (registroSelecionado && registroSelecionado.arquivado && podeEditar) {
      itens.push({
        label: 'Desarquivar',
        icon: 'fa fa-archive',
        disabled: !podeEditar,
        command: () => asyncDesarquivarDocumento(),
      });
    }

    if (registroSelecionado && !registroSelecionado.arquivado && podeEditar) {
      itens.push({
        label: 'Arquivar',
        icon: 'fa fa-archive',
        disabled: !podeEditar,
        command: () => asyncArquivarDocumento(),
      });
    }

    if (podeExcluir) {
      itens.push({
        label: 'Excluir',
        icon: 'fa fa-trash',
        command: () => onExcluir(),
      });
    }

    return itens;
  }

  function asyncArquivarDocumento() {
    const message = `Tem certeza que deseja arquivar este documento? Um documento arquivado não aparecerá mais na listagem, para visualizá-los marque o item "Exibir documentos arquivados" na listagem.`;
    confirm('Atenção', message, () => {
      asyncArquivarGestaoDocumento(
        registroSelecionado.id,
        () => {
          pesquisar(false);
        },
        null
      );
    });
  }

  function asyncDesarquivarDocumento() {
    const message = 'Tem certeza que deseja desarquivar este documento?';
    confirm('Atenção', message, () => {
      asyncDesarquivarGestaoDocumento(
        registroSelecionado.id,
        () => {
          pesquisar(false);
        },
        null
      );
    });
  }

  function arquivarSelecionados(documentos) {
    confirm(
      'Tem certeza que deseja arquivar estes documentos?',
      'Um documento arquivado não aparecerá mais na listagem, para visualizá-los marque o item "Exibir documentos arquivados" na listagem.',
      async () => {
        const promises = documentos.map(
          documento =>
            new Promise((resolve, reject) => {
              asyncArquivarGestaoDocumento(
                documento.id,
                () => {
                  resolve();
                },
                () => {
                  reject(error);
                },
                null,
                false
              );
            })
        );

        await Promise.all(promises);
        pesquisar(false);
        limparRegistrosSelecionados();
        notify('Documentos arquivados com sucesso');
      }
    );
  }

  function desarquivarSelecionados(documentos) {
    confirm('Confirmação', 'Tem certeza que deseja desarquivar este(s) documento(s)?', async () => {
      const promises = documentos.map(
        documento =>
          new Promise((resolve, reject) => {
            asyncDesarquivarGestaoDocumento(
              documento.id,
              () => {
                resolve();
              },
              () => {
                reject(error);
              },
              null,
              false
            );
          })
      );

      await Promise.all(promises);
      pesquisar(false);
      limparRegistrosSelecionados();
      notify('Documentos desarquivados com sucesso');
    });
  }

  function limparRegistrosSelecionados() {
    setDocumentosSelecionados([]);
  }

  function onPesquisarFiltroAvancado(e) {
    setFiltroAvancado(e);
    setLimparFiltroPesquisaAvancada(false);
  }

  function onChangeCheckboxexibirBloqueadas(e) {
    limparRegistrosSelecionados();
    setExibirBloqueadas(e.checked);
  }

  function getHeader() {
    return (
      <Grid justifyBetween>
         <Col col="5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', minHeight: '50px' }}>
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Checkbox
              inputId="exibirBloqueadas"
              checked={exibirBloqueadas}
              name="exibirBloqueadas"
              onChange={e => onChangeCheckboxexibirBloqueadas(e)}
            />
            <label htmlFor="exibirBloqueadas" className="p-checkbox-label">
              Exibir documentos arquivados
            </label>
          </span>
        </Col>
        <Col col="7" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', minHeight: '50px' }}>
          <If test={documentosSelecionados.length > 0}>
            <Button
              className="p-button-primary"
              type="button"
              label="Arquivar"
              icon="fa fa-archive"
              disabled={!podeEditar}
              title={!podeEditar ? 'Você não possui permissão para executar essa ação' : ''}
              style={{ marginRight: '6px' }}
              onClick={() => arquivarSelecionados(documentosSelecionados)}
            />
            <Button
              className="p-button-primary"
              type="button"
              hidden={exibirBloqueadas === false}
              disabled={!podeEditar}
              title={!podeEditar ? 'Você não possui permissão para executar essa ação' : ''}
              label="Desarquivar"
              icon="fa fa-archive"
              onClick={() => desarquivarSelecionados(documentosSelecionados)}
            />
          </If>
        </Col>       
      </Grid>
    );
  }

  function handleChangeInterval(interval) {
    setInterval(interval);
    setFiltroData(`criadoEm>=${formatISO(interval.dataInicial)};criadoEm<=${formatISO(interval.dataFinal)}`);
  }

  function renderIdentificacao(el) {
    const identificacao = el.identificacao;
    return aplicarEstiloArquivado(el.arquivado, identificacao, isMobile);
  }

  function renderDataVigenciaInicio(el) {
    const vigenciaInicio = format(parse(el.vigenciaInicio, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
    return aplicarEstiloArquivado(el.arquivado, vigenciaInicio, isMobile);
  }

  function renderDataVigenciaFim(el) {
    if (el.vigenciaFinal) {
      const vigenciaFinal = format(parse(el.vigenciaFinal, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
      return aplicarEstiloArquivado(el.arquivado, vigenciaFinal, isMobile);
    }
  }

  function renderValorField(el) {
    const valor = formatarMonetario(el.documentoValor);
    return aplicarEstiloArquivado(el.arquivado, valor, isMobile);
  }

  function renderValorMensalField(el) {
    const valor = formatarMonetario(el.documentoValorMensal);
    return aplicarEstiloArquivado(el.arquivado, valor, isMobile);
  }

  const { isMobile, isLessHd } = props;
  const acoesStyle = !isMobile && { textAlign: 'end' };

  return (
    <>
      <Menu model={buscarAcoes(registroSelecionado)} popup={true} style={{ minWidth: '230px' }} ref={menuOpcoes} />
      <Tutorial
        steps={tutorialStepsListagens}
        showSkipButton
        continuous
        visible={tutorialVisible}
        onHide={() => setTutorialVisible(false)}
        disableScrolling
      />
      <Form header="Gestão de documentos">
        <FormActions>
          <Button
            label="Novo documento"
            className="p-button-primary"
            icon="fa fa-plus"
            title="Inserir um novo documento"
            onClick={() => {
              props.history.push('/documentos/gestao_documentos/cadastro');
            }}
            disabled={!podeInserir}
            style={{ margin: '5px' }}
          />
        </FormActions>

        <FormContent>
          <Grid justifyCenter verticalAlignCenter>
            <span style={{ padding: '12px' }}>
              <DateInterval onChange={e => handleChangeInterval(e)} interval={interval} />
            </span>
            <InputSearch
              className="step-listagem-input-search"
              onPesquisar={() => onPesquisar()}
              value={valorPesquisa}
              onChange={value => setValorPesquisa(value)}
              removerEComercial={false}
              id="DocumentosInputSearch"
            />
            <span style={{ padding: '12px' }}>
              <PesquisaAvancada
                optionsFiltros={optionsFiltroAvancado}
                onPesquisarClick={e => onPesquisarFiltroAvancado(e)}
                onChangeFiltroRsql={rsql => setFiltroAvancado(rsql)}
                onChangeDescricaoFiltro={e => setDescricaoFiltroAvancado(e)}
                limparFiltro={limparFiltroPesquisaAvancada}
              />
            </span>
          </Grid>
          <CardsStatusGestaoDocumento
            cards={cards}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            setValorCard={setValorCard}
            setPage={setPage}
            isMobile={isMobile}
          />
          <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
          <DataTable
            className="table table-assinaturas"
            responsive
            selectionMode="checkbox"
            value={registros}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={e => onSort(e)}
            selection={documentosSelecionados}
            header={getHeader()}
            onSelectionChange={e => setDocumentosSelecionados(e.value)}
            emptyMessage={<NenhumRegistroEncontrado />}
          >
            <Column header={isMobile ? 'Seleção' : ''} selectionMode="multiple" style={{ width: '45px' }} />
            <Column
              field="identificacao"
              header={isMobile || isLessHd ? 'Identific.' : 'Identificação'}
              sortable={true}
              body={e => renderIdentificacao(e)}
              style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
            />
            <Column
              field="vigenciaInicio"
              header={isLessHd ? 'Inicio vigência' : 'Inicio da vigência'}
              sortable={true}
              body={e => renderDataVigenciaInicio(e)}
            />
            <Column
              field="vigenciaFinal"
              header={isLessHd ? 'Fim vigência' : 'Fim da vigência'}
              sortable={true}
              body={e => renderDataVigenciaFim(e)}
            />
            <Column
              field="documentoValor"
              header={isLessHd ? 'Valor doc.' : 'Valor do documento'}
              style={{ width: '200px' }}
              body={e => renderValorField(e)}
              sortable={true}
            />
            <Column
              field="documentoValorMensal"
              header="Valor mensal"
              style={{ width: '200px' }}
              body={e => renderValorMensalField(e)}
              sortable={true}
            />
            <Column
              field="status"
              header="Status"
              style={{ width: '220px' }}
              body={row => renderStatus(row)}
              sortable={true}
            />
            <Column
              className="step-listagem-acoes"
              body={(e, options) => renderOpcoes(e, options)}
              header="Ações"
              style={{ width: '100px', ...acoesStyle }}
            />
          </DataTable>
          <Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={e => onPageChange(e)} />
        </FormContent>
      </Form>
      <If test={modalDocumentoHistoricoVisible}>
        <ModalHistorico
          header="Histórico do documento"
          mapping="documentos/gestao_documentos"
          id={registroSelecionado?.id}
          visible={modalDocumentoHistoricoVisible}
          onHide={() => setModalDocumentoHistoricoVisible(false)}
        />
      </If>
      <OverlayPanelInformacoesComplementares
				documento={registros[currentDocumentoIndex]}
				panelInformacoesComplementares={panelInformacoesComplementares}
				isMobile={props.isMobile}
				setCurrentDocumentoIndex={setCurrentDocumentoIndex}
			/>
    </>
  );
}

const mapStateToProps = state => ({
  isMobile: state.dispositivo.isMobile,
  isLessHd: state.dispositivo.isLessHd,
});

export default connect(mapStateToProps)(GestaoDocumentos);
