import { ContaReceberStatus, ContaPagarStatus, ContaOrigem } from "./contantes";

export default function montarMensagemExclusaoTitulos(statusTitulo, origem) {
    if (origem === ContaOrigem.CONTA_RECEBER) {
        if (statusTitulo === ContaReceberStatus.PARCIALMENTE_RECEBIDA || statusTitulo === ContaReceberStatus.RECEBIDA) {
            return 'Tem certeza que deseja excluir esta conta a receber? Todas as baixas serão excluidas com o título.'
        } else {
            return 'Tem certeza que deseja excluir esta conta a receber?'
        }
    } else {
        if (statusTitulo === ContaPagarStatus.PARCIALMENTE_PAGA || statusTitulo === ContaPagarStatus.PAGA) {
            return 'Tem certeza que deseja excluir esta conta a pagar? Todos os pagamentos parciais serão excluidos com o título'
        } else {
            return 'Tem certeza que deseja excluir esta conta a pagar?'
        }
    }
};
