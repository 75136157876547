import Button from 'components/Button';
import ButtonCancelar, { estadosBotaoCancelar } from 'components/Button/ButtonCancelar';
import ButtonSalvar, { estadosBotaoSalvar } from 'components/Button/ButtonSalvar';
import { createEfetuarRecebimento, createGerarPixCobrancas, readImprimirRecibo } from 'components/Pagamentos/Requests';
import { converterRecebimentoToApi } from 'components/Pagamentos/Util/converter';
import { confirm } from 'components/Toast';
import { useFormikContext } from 'formik';
import { validarFormulario } from 'views/Util';

function ActionButtons({ informacoesPermissoes, onHide, urls, onHideRecebimentoPix }) {
	const { values, dirty, isValid, handleSubmit, handleReset, validateForm } = useFormikContext();

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			onHide();
		}
	}

	async function handleClickSalvar() {
		handleSubmit();

		if (await validarFormulario({ validateForm, values })) {
			if (values && isValid) {
				await createEfetuarRecebimento(
					urls.urlBase,
					values.idOrigem,
					converterRecebimentoToApi(values),
					({ data: response }) => {
						confirm(
							'Confirmação',
							'Deseja imprimir o recibo de pagamento?',
							async () => {
								await printRecibo(response?.id);
								onHide(values);
							},
							() => {
								onHide(values);
							},
							'Sim',
							'Não'
						);
					}
				);
			}
		}
	}

	async function printRecibo(id) {
		await readImprimirRecibo(id, async ({ data: arquivo }) => {
			const arquivoPDF = new Blob([arquivo], { type: 'application/pdf' });
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const file = window.open(arquivoURL);

			if (file) {
				file.onload = () => {
					setTimeout(() => {
						file.document.title = 'Recibo de pagamento';
					}, 250);
				};
			}
		});
	}

	async function handleClickRecebimentoPorPix() {
		await createGerarPixCobrancas(values.conta.value, [{ id: values.financeiroId }], () => {
			onHideRecebimentoPix(values);
		});
	}

	return (
		<div
			style={{
				display: 'flex',
				justifyItems: 'flex-start',
				alignItems: 'flex-start',
				flexWrap: 'wrap',
			}}
		>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={handleClickCancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar estadoBotao={estadosBotaoSalvar.SALVAR} onClick={handleClickSalvar} {...informacoesPermissoes} />
			<Button
				icon="pi pi-qrcode"
				label="Receber com pix"
				title="Gerar um pix para efetuar o recebimento da conta"
				color="success"
				hidden={!values.conta || !values.conta?.registro?.recebePix}
				onClick={handleClickRecebimentoPorPix}
			/>
		</div>
	);
}

export { ActionButtons };
