/* eslint-disable class-methods-use-this */
import { parse } from 'date-fns';
import format from 'date-fns/format';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { usuarioPossuiPermissao } from '../../../../../Common/Autenticacao';
import { permissoes, recursos } from '../../../../../Common/Constantes/autorizacao';
import { formatarMonetario } from '../../../../../Common/Mascara';
import { Paginacao } from '../../../../../components';
import Button from '../../../../../components/Button';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';
import { Financas } from '../../../../Util/constantes';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

class TabelaLancamentos extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			podeExcluirLancamento: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.EXCLUIR),
			podeEditarContas:
				usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.VISUALIZAR) &&
				usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.EDITAR),
			podeEditarContaReceber:
				usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR) &&
				usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EDITAR),
			podeEditarContaPagar:
				usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR) &&
				usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EDITAR),
			podeEditarNotaEntrada:
				usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.VISUALIZAR) &&
				usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.EDITAR),
		};
	}

	atualizarOrdenacao(e) {
		this.props.setSortField(e.sortField);
		this.props.setSortOrder(e.sortOrder);
	}

	renderDataField(el) {
		return <span>{format(parse(el.data, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}</span>;
	}

	renderDescricaoField(el) {
		return (
			<span
				title={el.descricao}
				style={{
					overflow: 'hidden',
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: this.props.isMobile ? '70%' : '100%',
					textAlign: this.props.isMobile ? 'end' : 'start',
				}}
			>
				{el.descricao}
			</span>
		);
	}

	renderPessoaNomeField(el) {
		return (
			<span
				title={el.pessoaNome}
				style={{
					overflow: 'hidden',
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: this.props.isMobile ? '70%' : '100%',
					textAlign: this.props.isMobile ? 'end' : 'start',
				}}
			>
				{el.pessoaNome}
			</span>
		);
	}

	renderCategoriaField(el) {
		if (el.origem === 'TRANSFERENCIA') {
			return <span>Transferência</span>;
		} else if (el.origem === 'SALDO_INICIAL') {
			return <span>Saldo inicial</span>;
		}
		return (
			<span title={el.categoriaNome} style={{ color: el.categoriaAparencia, fontWeight: 'bold' }}>
				{String(el.categoriaNome).length > 50
					? String(el.categoriaNome).slice(0, 50).concat('...')
					: el.categoriaNome || ''}
			</span>
		);
	}

	renderContaNomeField(el) {
		return (
			<span
				title={el.contaNome}
				style={{
					wordBreak: 'break-word',
					maxWidth: this.props.isMobile ? '70%' : '100%',
					textAlign: this.props.isMobile ? 'end' : 'start',
				}}
			>
				{el.contaNome}
			</span>
		);
	}

	renderValorField(el) {
		const valor = formatarMonetario(el.valor);

		if (el.tipo === 'DESPESA') {
			return (
				<span
					style={{
						backgroundColor: Financas.cores.vermelhoClaro,
						color: Financas.cores.vermelho,
						fontWeight: 'bold',
						fontSize: '13px',
						borderRadius: '20px',
						padding: '3px 10px',
					}}
				>
					<span style={{ width: '100%', textAlign: 'center' }}>{valor}</span>
				</span>
			);
		} else {
			return (
				<span
					style={{
						backgroundColor: Financas.cores.verdeClaro,
						color: Financas.cores.verde,
						fontWeight: 'bold',
						fontSize: '13px',
						borderRadius: '20px',
						padding: '3px 10px',
					}}
				>
					<span style={{ width: '100%', textAlign: 'center' }}>{valor}</span>
				</span>
			);
		}
	}

	renderAcoesField(el) {
		const {
			podeExcluirLancamento,
			podeEditarContas,
			podeEditarContaPagar,
			podeEditarContaReceber,
			podeEditarNotaEntrada,
		} = this.state;
		let titleEditar = 'Editar';
		let titleExcluir = 'Excluir';
		let disableBtnEditar = false;
		let disableBtnExcluir = false;

		if (el.origem === 'RECEBIMENTO' || el.origem === 'PAGAMENTO') {
			if (
				(el.origem === 'RECEBIMENTO' && !podeEditarContaReceber) ||
				(el.origem === 'PAGAMENTO' && !podeEditarContaPagar)
			) {
				titleEditar = 'Você não possui permissão para executar essa ação';
				disableBtnEditar = true;
			}
			titleExcluir =
				"Para excluir uma movimentação vindo da contas a receber ou a pagar, clique no botão de editar e em seguinda em 'Excluir'";
			disableBtnExcluir = true;
		}

		if (el.origem === 'SALDO_INICIAL') {
			if (!podeEditarContas) {
				titleEditar = 'Você não possui permissão para executar essa ação';
				disableBtnEditar = true;
			}
			titleExcluir =
				'Não é possível excluir uma movimentação de saldo inicial. Caso a movimentação estiver incorreta, edite-a.';
			disableBtnExcluir = true;
		}
		if (!podeExcluirLancamento) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		}

		if (el.origem === 'COMPRA_A_VISTA') {
			if (!podeEditarNotaEntrada) {
				titleEditar = 'Você não possui permissão para executar essa ação.';
				disableBtnEditar = true;
			}
			titleExcluir = 'Para excluir uma movimentação vindo de um nota de entrada, deve ser estornada a nota de origem.';
			disableBtnExcluir = true;
		}

		if (el.origem === 'VENDA_A_VISTA') {
			if (!podeEditarNotaEntrada) {
				titleEditar = 'Você não possui permissão para executar essa ação.';
				disableBtnEditar = true;
			}
			titleExcluir = 'Para excluir uma movimentação vindo de uma venda, deve ser estornado o documento de origem.';
			disableBtnExcluir = true;
		}

		return (
			<div>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={titleEditar}
					disabled={disableBtnEditar}
					onClick={() => this.props.onEditItem(el)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => this.props.onRemoveItem(el)}
				/>
			</div>
		);
	}

	render() {
		const { registros, sortField, sortOrder, onPageChange, page, rows, totalRecords, isMobile } = this.props;

		return (
			<div style={{ width: !isMobile ? '100%' : '99%' }}>
				<DataTable
					className="table"
					value={registros}
					responsive
					sortField={sortField}
					sortOrder={sortOrder}
					onSort={this.atualizarOrdenacao}
					emptyMessage={<NenhumRegistroEncontrado message="Nenhuma movimentação encontrada" />}
				>
					<Column
						header="Data"
						field="data"
						sortable
						body={this.renderDataField}
						style={{ color: '#333333', width: '90px' }}
					/>
					<Column
						header="Descrição"
						field="descricao"
						body={this.renderDescricaoField}
						style={{ color: '#333333', minWidth: '15em', maxWidth: '40em' }}
					/>
					<Column header="Pessoa" field="pessoaNome" body={this.renderPessoaNomeField} />
					<Column
						header="Categoria"
						field="categoriaNome"
						sortable
						body={this.renderCategoriaField}
						style={{ color: '#333333' }}
					/>
					<Column
						header="Conta"
						field="contaNome"
						sortable
						body={this.renderContaNomeField}
						style={{ color: '#333333' }}
					/>
					<Column header="Valor" field="valor" sortable body={this.renderValorField} style={{ color: '#333333' }} />
					<Column header="Ações" body={this.renderAcoesField} style={{ width: '100px' }} />
				</DataTable>
				<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(TabelaLancamentos);
