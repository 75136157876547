import { useFormikContext } from 'formik';

import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar } from 'components';

import { validarFormulario } from 'views/Util';

function ActionButtons({
	indexServico,
	setFieldValueServico,
	onChangeServico,
	onHide,
	informacoesPermissoes,
	disabled,
}) {
	const { dirty, values, handleReset, handleSubmit, validateForm } = useFormikContext();

	function cancelar() {
		dirty ? handleReset() : onHide();
	}

	async function salvar() {
		handleSubmit();

		if (await validarFormulario({ values, validateForm })) {
			setFieldValueServico(`servicos[ ${indexServico}]`, values);
			onChangeServico(indexServico, values);
			onHide();
		}
	}

	return (
		<>
			<ButtonCancelar
				onClick={cancelar}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar onClick={salvar} disabled={!dirty || disabled} {...informacoesPermissoes} />
		</>
	);
}

export { ActionButtons };
