import { mensagensDeValidacao, services } from 'Common';
import { Dropdown, Grid, InputDate, MultiSelectVendedores } from 'components';
import { format, isValid, parseISO } from 'date-fns';
import { Field, useFormikContext, withFormik } from 'formik';
import { useEffect } from 'react';
import { validarFormulario } from 'views/Util';
import ModalRelatorio from '../../../../../../components/ModalRelatorio';
import { HELP_MESSAGE, INITIAL_VALUES } from './util/constantes';
import { asyncBaixarRelatorioComissaoAnalitico } from '../Requests';

function ModalComissaoAnaliticoView({ visible, onHide, ...props }) {
	const { values, errors, handleSubmit, validateForm, setFieldValue } = useFormikContext();

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('InputDataInicial')?.focus();
		}, 500);
	}, []);

	async function imprimir() {
		handleSubmit();
		await validateForm();

		try {
			const dataInicialFormatada = format(parseISO(values.dataInicial), 'yyyy-MM-dd');
			const dataFinalFormatada = format(parseISO(values.dataFinal), 'yyyy-MM-dd');
			let vendedoresFormatados = '';

			if (values.vendedores) {
				vendedoresFormatados = values.vendedores.map((item) => item.value).join(',');
			}

			if (await validarFormulario(props)) {
				await asyncBaixarRelatorioComissaoAnalitico(
					dataInicialFormatada,
					dataFinalFormatada,
					vendedoresFormatados,
					values.origemCobranca,
					values.documentoOrigem,
					({ data: pdf }) => {
						const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						const arquivoURL = URL.createObjectURL(arquivoPDF);
						const relatorio = window.open(arquivoURL);
						if (relatorio) {
							relatorio.onload = () => {
								setTimeout(() => {
									relatorio.document.title = 'Relatório comissões analítico';
								}, 250);
							};
						}
						onHide();
					}
				);
			}
		} catch (e) {
			console.error(e);
		}
	}

	const parametros = (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={InputDate}
				label="Data inicial"
				name="dataInicial"
				obrigatorio
				helpMessage={HELP_MESSAGE.dataInicial}
				value={values.dataInicial}
				touched
				id="InputDataInicial"
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={InputDate}
				label="Data final"
				name="dataFinal"
				obrigatorio
				helpMessage={HELP_MESSAGE.dataFinal}
				value={values.dataFinal}
				touched
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={MultiSelectVendedores}
				label="Vendedor(es)"
				name="vendedores"
				onChange={(e) => setFieldValue('vendedores', e)}
				value={values.vendedores}
				showClear={false}
				esconderBotao
				url={`${services.GESTOR}/v1/pedidos_venda/relacoes/vendedor/relatorios`}
				placeholder="Todos os vendedores"
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={Dropdown}
				label="Origem de cobrança"
				name="origemCobranca"
				onChange={(e) => setFieldValue('origemCobranca', e.value)}
				options={[
					{ value: 'LIQUIDACAO', label: 'Liquidação' },
					{ value: 'FATURAMENTO', label: 'Faturamento' },
				]}
				value={values.origemCobranca}
				showClear={false}
				obrigatorio
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={Dropdown}
				label="Documento de origem"
				name="documentoOrigem"
				onChange={(e) => setFieldValue('documentoOrigem', e.value)}
				options={[
					{ value: 'PEDIDO_VENDA', label: 'Pedido de venda' },
					{ value: 'NFE', label: 'NF-e' },
				]}
				value={values.documentoOrigem}
				disabled={values.origemCobranca === 'LIQUIDACAO'}
				showClear={false}
			/>
		</Grid>
	);
	return (
		<ModalRelatorio
			header="Relatório de comissão por vendedor - Analítico"
			visible={visible}
			onHide={onHide}
			onImprimir={imprimir}
			parametros={parametros}
			isValid={errors.length === 0}
		/>
	);
}

const ModalComissaoAnalitico = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};

		if (!values.dataInicial || values.dataInicial === '') {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataFinal || values.dataFinal === '') {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.dataInicial && !isValid(parseISO(values.dataInicial))) {
			errors.dataInicial = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.dataFinal && !isValid(parseISO(values.dataFinal))) {
			errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (!values.origemCobranca) {
			errors.origemCobranca = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalComissaoAnaliticoView);

export default ModalComissaoAnalitico;
