import propTypes from 'prop-types';
import InputDouble from '../InputDouble';

function InputPercentage(props) {
	return <InputDouble {...props} />;
}

InputPercentage.defaultProps = {
	value: '',
	onChange: () => {},
	size: 99999,
	placeholder: '0 %',
	sufix: ' %',
	className: '',
};

InputPercentage.propTypes = {
	/** Evento disparado ao modificar o componente do componente */
	onChange: propTypes.func,
	/** Label do componente */
	label: propTypes.string,
	/** Identificador do componente */
	id: propTypes.string,
	/** Placeholder do componente */
	placeholder: propTypes.string,
	/** Valor do componente do componente */
	value: propTypes.any,
	/** Nome da classe do componente */
	className: propTypes.string,
	/** Define se o componente está desabilitado*/
	disabled: propTypes.bool,
	/** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
	errors: propTypes.string,
	/** Especifica se o componente foi 'tocado'*/
	touched: propTypes.bool,
	/** Evento executado ao sair do campo*/
	onBlur: propTypes.any,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
	/** Estilo da coluna*/
	colStyle: propTypes.object,
	/** Estado em que o cadastro se encontra*/
	estadoCadastro: propTypes.string,
	/** Diz se o usuário possui permissão de visualizar*/
	podeVisualizar: propTypes.bool,
	/** Diz se o usuário possui permissão de editar*/
	podeEditar: propTypes.bool,
	/** Diz se o usuário possui permissão de excluir*/
	podeInserir: propTypes.bool,
	/** Title do componente*/
	title: propTypes.string,
	/** Id da coluna*/
	colId: propTypes.string,
	/** Esconde o componente*/
	hidden: propTypes.bool,
	/** Tamanho máximo do texto do componente*/
	size: propTypes.number,
	/** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
	helpMessage: propTypes.string,
	/**Define se componente aceita valor negativo ou não. */
	allowNegative: propTypes.bool,
};
export default InputPercentage;
