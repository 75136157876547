import { Modal } from '../../../../../../components';
import FieldsEnviarPorEmail from './components/FieldsEnviarPorEmail';

export function ModalEnviarPorEmail(props) {
	const { visible, onHide, sped } = props;

	return (
		<Modal header="Enviar SPED por e-mail" visible={visible} onHide={onHide}>
			<FieldsEnviarPorEmail onHide={onHide} sped={sped} />
		</Modal>
	);
}
