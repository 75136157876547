/* eslint-disable no-lonely-if */
import { endOfMonth, formatISO, isWithinInterval, startOfMonth } from 'date-fns';
import { Menu } from 'primereact/menu';
import { SelectButton } from 'primereact/selectbutton';
import { useCallback, useEffect, useState } from 'react';
import { AiFillBank } from 'react-icons/ai';
import { BsGraphDown, BsGraphUp } from 'react-icons/bs';
import { FaBalanceScale } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { services } from '../../../Common/Constantes/api';
import { permissoes, recursos } from '../../../Common/Constantes/autorizacao';
import { formatarParaPesquisarTiposEnumerados } from '../../../Common/ManipulacaoDeString';
import { construirUrl } from '../../../Common/Rsql';
import Button from '../../../components/Button';
import Col from '../../../components/Col';
import DateInterval from '../../../components/DateInterval';
import DescricaoFiltroAvancado from '../../../components/DescricaoFiltroAvancado';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import Grid from '../../../components/Grid';
import If from '../../../components/If';
import PesquisaAvancada from '../../../components/PesquisaAvancada';
import { tipoCampos } from '../../../components/PesquisaAvancada/Util/constantes';
import { confirm } from '../../../components/Toast';
import InputSearch from '../../../components/input/InputSearch';
import { Financas } from '../../Util/constantes';
import ModalSaldoInicial from '../../cadastros/Contas/components/ModalConta';
import ModalContaPagar from '../ContasPagar/components/ModalContaPagar';
import ModalContaReceber from '../ContasReceber/components/ModalContaReceber';
import CardTotalizadorListagem from '../components/CardTotalizadorListagem';
import {
	asyncGetCategoriasDespesas,
	asyncGetCategoriasReceita,
	asyncGetConta,
	asyncGetContaPagar,
	asyncGetContaReceber,
	asyncGetContas,
	asyncGetLancamento,
	asyncGetLancamentos,
	asyncGetTotalizadores,
	readFormasPagamento,
} from './Requests';
import { optionsFiltroAvancadoLancamentos } from './Util/constantes';
import ModalLancamentoDespesa from './components/ModalLancamentoDespesa';
import { asyncDeleteLancamentoDespesa } from './components/ModalLancamentoDespesa/Requests';
import ModalLancamentoReceita from './components/ModalLancamentoReceita';
import { asyncDeleteLancamentoReceita } from './components/ModalLancamentoReceita/Requests';
import ModalTransferencia from './components/ModalTransferencia';
import { asyncDeleteTransferencia, asyncGetTransferencia } from './components/ModalTransferencia/Requests';
import TabelaLancamentos from './components/TabelaLancamentos';

function Lancamentos(props) {
	const { isMobile, location } = props;

	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		size,
		setSize,
		totalRecords,
		setTotalRecords,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		selectedCard,
		setSelectedCard,
		filtroData,
		setFiltroData,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.INSERIR));
	const [exibirModalTransferencia, setExibirModalTransferencia] = useState(false);
	const [exibirModalLancamentoReceita, setExibirModalLancamentoReceita] = useState(false);
	const [exibirModalLancamentoDespesa, setExibirModalLancamentoDespesa] = useState(false);
	const [exibirModalSaldoInicial, setExibirModalSaldoInicial] = useState(false);
	const [exibirModalContaPagar, setExibirModalContaPagar] = useState(false);
	const [exibirModalContaReceber, setExibirModalContaReceber] = useState(false);
	const [valorCard, setValorCard] = useState('');
	const [cards, setCards] = useState({
		despesas: 0,
		receitas: 0,
		balanco: 0,
		saldo: 0,
	});
	const [filtroConta, setFiltroConta] = useState(null);
	const [optionsContas, setOptionsContas] = useState([]);
	const [optionsFiltroAvancado, setOptionsFiltroAvancado] = useState(optionsFiltroAvancadoLancamentos);
	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
	const [firstRender, setFirstRender] = useState(true);

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();

		if (location.state && location.state.contaSelecionadaId) {
			setFiltroConta(location.state.contaSelecionadaId);
		}
		buscarInformacoesComplementaresFiltro();

		setTimeout(() => {
			document.getElementById('LancamentosInputSearch')?.focus();
		}, 500);
	}, []);

	useUpdateEffect(() => {
		pesquisarCallback();
	}, [page, rows, sortOrder, sortField, filtroAvancado, interval, selectedCard, filtroConta]);

	function onPesquisar() {
		pesquisar();
	}

	function onPesquisarFiltroAvancado(e) {
		setFiltroAvancado(e);
		setLimparFiltroPesquisaAvancada(false);
	}

	async function buscarInformacoesComplementaresFiltro() {
		await asyncGetContas(({ data: contas }) => {
			const optionsContas = contas && contas.content.map((conta) => ({ label: conta.nome, value: conta.id }));
			setOptionsContas(optionsContas);
		});

		await asyncGetCategoriasDespesas(({ data: catDespesas }) => {
			const optionSelectCategorias = [];

			catDespesas.content.forEach((categoria) => {
				optionSelectCategorias.push({
					label: categoria.nome,
					value: categoria.nome,
				});
			});

			asyncGetCategoriasReceita(({ data: catReceita }) => {
				catReceita.content.forEach((categoria) => {
					optionSelectCategorias.push({
						label: categoria.nome,
						value: categoria.nome,
					});
				});

				const categorias = {
					label: 'Categoria',
					name: 'categoriaNome',
					type: tipoCampos.SELECT,
					optionSelect: optionSelectCategorias,
				};
				setOptionsFiltroAvancado([...optionsFiltroAvancado, { ...categorias }]);
			});
		});

		await readFormasPagamento(({ data: response }) => {
			if (response.content) {
				const newOption = {
					label: 'Forma pagamento',
					name: 'formaPagamentoId',
					type: tipoCampos.SELECT,
					optionSelect: [],
				};
				response.content.forEach((option) => {
					newOption.optionSelect.push({ label: option.descricao, value: option.id });
				});
				setOptionsFiltroAvancado([...optionsFiltroAvancado, { ...newOption }]);
			}
		});
	}

	function buscarFiltro() {
		const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa);

		let filtroRSQL = String('?query=(')
			.concat(`descricao=contains="*${valorPesquisa}*",`)
			.concat(`data=contains="*${valorPesquisa}*",`)
			.concat(`categoriaNome=contains="*${valorPesquisa}*",`)
			.concat(`origem=contains="*${valorTiposEnumerados}*",`)
			.concat(`contaNome=contains="*${valorPesquisa}*",`)
			.concat(`observacao=contains="*${valorPesquisa}*",`)
			.concat(`valor=contains="*${valorPesquisa}*")`);
		const currentDate = new Date();
		const startOfTheMonth = startOfMonth(currentDate);
		const endOfTheMonth = endOfMonth(currentDate);

		if (filtroConta) {
			filtroRSQL = filtroRSQL.concat(`;(contaId==${filtroConta})`);
		}
		if (filtroData) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroData})`);
		} else {
			filtroRSQL = filtroRSQL.concat(
				`;(${`data>=${formatISO(startOfTheMonth, {
					representation: 'date',
				})};data<=${formatISO(endOfTheMonth, {
					representation: 'date',
				})}`})`
			);
		}

		if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}
		if (valorCard) {
			filtroRSQL = filtroRSQL.concat(`;(${valorCard})`);
		}

		return filtroRSQL;
	}

	async function pesquisar() {
		const filtro = buscarFiltro();

		let campoOrdenacao = sortField;
		let sentidoOrdenacao = sortOrder;
		if (firstRender) {
			setSortField('data');
			setSortOrder(0);
			campoOrdenacao = 'data';
			sentidoOrdenacao = 0;
		}

		const url = construirUrl(
			`${services.GESTOR}/v1/lancamentos/resumo`,
			filtro || '?',
			size,
			page,
			sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
		);

		asyncGetTotalizadores(interval, filtroConta, ({ data: totais }) => {
			setCards({
				despesas: totais.despesasPagas,
				receitas: totais.receitasRecebidas,
				balanco: totais.balancoNoPeriodo,
				saldo: totais.saldoAtual,
			});
		});

		asyncGetLancamentos(url, ({ data: lancamentos }) => {
			setRegistros(lancamentos.content);
			setTotalRecords(lancamentos.totalElements);
			setFirstRender(false);
		});
	}

	function handleChangeInterval(interval) {
		setInterval(interval);
		setFiltroData(
			`data>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};data<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	async function handleEditItem(item) {
		if (item.origem === 'PAGAMENTO') {
			asyncGetContaPagar(item.contaPagarId, ({ data }) => {
				setRegistroSelecionado(data);
				setExibirModalContaPagar(true);
			});
		} else if (item.origem === 'RECEBIMENTO') {
			asyncGetContaReceber(item.contaReceberId, ({ data }) => {
				setRegistroSelecionado(data);
				setExibirModalContaReceber(true);
			});
		} else {
			await asyncGetLancamento(item.id, ({ data: lancamento }) => {
				if (item.origem === 'TRANSFERENCIA') {
					asyncGetTransferencia(lancamento.transferencia.id, ({ data: dados }) => {
						setRegistroSelecionado(dados);
						setExibirModalTransferencia(true);
					});
				} else if (item.origem === 'SALDO_INICIAL') {
					asyncGetConta(item.contaId, ({ data: dados }) => {
						setRegistroSelecionado(dados);
						setExibirModalSaldoInicial(true);
					});
				} else {
					if (item.tipo === 'RECEITA') {
						setRegistroSelecionado(lancamento);
						setExibirModalLancamentoReceita(true);
					} else {
						setRegistroSelecionado(lancamento);
						setExibirModalLancamentoDespesa(true);
					}
				}
			});
		}
	}

	async function handleRemoveItem(item) {
		if (item.origem === 'TRANSFERENCIA') {
			await asyncGetLancamento(item.id, ({ data: lancamentos }) => {
				confirm('Confirmação', 'Tem certeza que deseja excluir esta transferência?', async () => {
					await asyncDeleteTransferencia(lancamentos.transferencia.id);
					pesquisar();
				});
			});
		} else {
			confirm('Confirmação', 'Tem certeza que deseja excluir esta movimentação?', async () => {
				if (item.tipo === 'RECEITA') {
					await asyncDeleteLancamentoReceita(item.id);
				} else {
					await asyncDeleteLancamentoDespesa(item.id);
				}
				pesquisar();
			});
		}
	}

	function onHideModal(values) {
		if (values) {
			if (values.conta && values.conta.value) {
				const encontrouNovaConta = optionsContas.some((item) => item.value === values.conta.value);
				if (!encontrouNovaConta) {
					const novasOptionsContas = [...optionsContas, values.conta];
					setOptionsContas(novasOptionsContas);
				}
			} else if (values.length) {
				setOptionsContas(values);
			}
		}

		setExibirModalLancamentoReceita(false);
		setExibirModalLancamentoDespesa(false);
		setExibirModalTransferencia(false);
		setExibirModalSaldoInicial(false);
		setExibirModalContaPagar(false);
		setExibirModalContaReceber(false);
		setRegistroSelecionado(null);
		onPesquisar();
	}

	function handleSelectCardDespesa(cardName) {
		const filtro = 'tipo==DESPESA';
		pesquisarRSQLCardTotais(cardName, filtro);
	}

	function handleSelectCardReceita(cardName) {
		const filtro = 'tipo==RECEITA;origem!=TRANSFERENCIA';
		pesquisarRSQLCardTotais(cardName, filtro);
	}

	function handleSelectConta(e) {
		setFiltroConta(e.value);
	}

	function pesquisarRSQLCardTotais(cardName, valorAplicadoNoFiltro) {
		if (selectedCard === cardName) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(cardName);
			setValorCard(valorAplicadoNoFiltro);
		}
	}

	function montarHeader() {
		return (
			<span
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<span>Movimentações financeiras</span>
			</span>
		);
	}

	const estaNoPeriodoAtual =
		interval.dataInicial && interval.dataFinal
			? isWithinInterval(new Date(), {
					start: interval.dataInicial,
					end: interval.dataFinal,
				})
			: false;

	return (
		<>
			<Menu
				model={[
					{
						label: 'Nova receita',
						icon: 'fa fa-plus',
						command: () => setExibirModalLancamentoReceita(true),
					},
					{
						label: 'Nova despesa',
						icon: 'fa fa-plus',
						command: () => setExibirModalLancamentoDespesa(true),
					},
					{
						label: 'Nova transferência',
						icon: 'fa fa-plus',
						command: () => setExibirModalTransferencia(true),
					},
				]}
				popup
			/>
			<Form header={montarHeader()}>
				<FormActions>
					<Button
						label="Nova receita"
						className="p-button-success"
						icon="fa fa-plus"
						title="Inserir uma nova movimentação de receita"
						onClick={() => setExibirModalLancamentoReceita(true)}
						disabled={!podeInserir}
						style={{ margin: '5px' }}
					/>
					<Button
						label="Nova despesa"
						className="p-button-danger"
						icon="fa fa-plus"
						title="Inserir uma nova movimentação de despesa"
						onClick={() => setExibirModalLancamentoDespesa(true)}
						disabled={!podeInserir}
						style={{ margin: '5px' }}
					/>
					<Button
						label="Nova transferência"
						icon="fa fa-plus"
						title="Inserir uma nova transferência entre contas"
						onClick={() => setExibirModalTransferencia(true)}
						disabled={!podeInserir}
						style={{ margin: '5px' }}
					/>
				</FormActions>

				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<span style={{ padding: '12px' }}>
							<DateInterval onChange={handleChangeInterval} />
						</span>
						<InputSearch
							onPesquisar={onPesquisar}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
							id="LancamentosInputSearch"
						/>
						<span style={{ padding: '12px' }}>
							<PesquisaAvancada
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
								limparFiltro={limparFiltroPesquisaAvancada}
							/>
						</span>
					</Grid>
					<If test={optionsContas.length > 0}>
						<SelectButton
							style={{
								whiteSpace: 'nowrap',
								overflowY: 'hidden',
								width: '100%',
								margin: '0px',
								paddingBottom: '8px',
							}}
							className={`grid-select-filtro select-button ${isMobile ? 'grid-select-filtro-mobile' : null}`}
							value={filtroConta}
							options={optionsContas}
							onChange={handleSelectConta}
						/>
					</If>
					<Grid justifyBetween>
						<Col xs="12" sm="12" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardReceita"
								title="Receitas recebidas"
								helpMessage="Soma de todas as movimentações de receitas realizadas no período selecionado"
								titleFiltro="Clique para filtrar por receitas realizadas"
								icon={<BsGraphUp size={25} color={Financas.cores.verde} />}
								primaryColor={cards.receitas < 0 ? Financas.cores.vermelho : Financas.cores.verde}
								value={cards.receitas}
								selectable
								selected={selectedCard === 'cardReceita'}
								onSelect={handleSelectCardReceita}
							/>
						</Col>
						<Col xs="12" sm="12" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardDespesa"
								title="Despesas pagas"
								helpMessage="Soma de todas as movimentações de despesas realizadas no período selecionado"
								titleFiltro="Clique para filtrar por despesas realizadas"
								icon={<BsGraphDown size={25} color={Financas.cores.vermelho} />}
								primaryColor={Financas.cores.vermelho}
								value={cards.despesas}
								selectable
								selected={selectedCard === 'cardDespesa'}
								onSelect={handleSelectCardDespesa}
							/>
						</Col>
						<Col xs="12" sm="12" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardBalancoPeriodo"
								title="Balanço do período"
								helpMessage="Balanço financeiro das movimentações realizadas no período selecionado (receitas - despesas)"
								icon={
									<FaBalanceScale
										size={25}
										color={cards.balanco < 0 ? Financas.cores.vermelho : Financas.cores.verde}
									/>
								}
								primaryColor={cards.balanco < 0 ? Financas.cores.vermelho : Financas.cores.verde}
								value={cards.balanco}
							/>
						</Col>
						<Col xs="12" sm="12" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardSaldoAtual"
								title="Saldo atual"
								helpMessage="Soma dos saldos de todas as contas até a data atual. Esse valor é calculado somando todas as movimentações até a data de hoje"
								icon={<AiFillBank size={25} color={Financas.cores.azul} />}
								primaryColor={Financas.cores.azul}
								value={cards.saldo}
							/>
						</Col>
					</Grid>
					<Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
						<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
						<TabelaLancamentos
							registros={registros}
							totalRecords={totalRecords}
							rows={size}
							page={page}
							sortField={sortField}
							sortOrder={sortOrder}
							setSortField={(sortField) => setSortField(sortField)}
							setSortOrder={(sortOrder) => setSortOrder(sortOrder)}
							onEditItem={handleEditItem}
							onRemoveItem={handleRemoveItem}
							onPageChange={(e) => {
								setSize(e.rows);
								setRows(e.rows);
								setPage(e.page);
							}}
						/>
					</Grid>
				</FormContent>
			</Form>
			<If test={exibirModalTransferencia}>
				<ModalTransferencia
					transferenciaSelecionada={registroSelecionado}
					onNovoClick={() => setRegistroSelecionado(null)}
					visible={exibirModalTransferencia}
					valorPadraoData={estaNoPeriodoAtual ? new Date() : null}
					onHide={onHideModal}
				/>
			</If>
			<If test={exibirModalLancamentoReceita}>
				<ModalLancamentoReceita
					registroSelecionado={registroSelecionado}
					onNovoClick={() => setRegistroSelecionado(null)}
					visible={exibirModalLancamentoReceita}
					valorPadraoData={estaNoPeriodoAtual ? new Date() : null}
					onHide={onHideModal}
				/>
			</If>
			<If test={exibirModalLancamentoDespesa}>
				<ModalLancamentoDespesa
					registroSelecionado={registroSelecionado}
					onNovoClick={() => setRegistroSelecionado(null)}
					visible={exibirModalLancamentoDespesa}
					valorPadraoData={estaNoPeriodoAtual ? new Date() : null}
					onHide={onHideModal}
				/>
			</If>
			<If test={exibirModalSaldoInicial}>
				<ModalSaldoInicial
					visible={exibirModalSaldoInicial}
					onHide={onHideModal}
					registroSelecionado={registroSelecionado}
					esconderBotaoNovo
					esconderBotaoExcluir
				/>
			</If>
			<If test={exibirModalContaPagar}>
				<ModalContaPagar
					onNovoClick={() => setRegistroSelecionado(null)}
					visible={exibirModalContaPagar}
					onHide={onHideModal}
					registroSelecionado={registroSelecionado}
					valorPadraoDataVencimento={estaNoPeriodoAtual ? new Date() : null}
				/>
			</If>
			<If test={exibirModalContaReceber}>
				<ModalContaReceber
					onNovoClick={() => setRegistroSelecionado(null)}
					visible={exibirModalContaReceber}
					onHide={onHideModal}
					registroSelecionado={registroSelecionado}
					valorPadraoDataVencimento={estaNoPeriodoAtual ? new Date() : null}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Lancamentos);
