import { formatarMonetario } from 'Common';
import { Grid, If, Modal, ReactSteps } from 'components';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useContextPlanos } from '../../Context';
import { GetSteps, METODO_PAGAMENTO } from '../../Util/constantes';
import './Styles/index.css';
import { AnimacaoCompletado } from './components/AnimacaoCompletado';
import DetalhesForm from './components/DetalhesForm';
import PagamentoForm from './components/PagamentoForm';

export default function ModalConfirmarAdesao({
	visible,
	onHide,
	valorPlanoAtual,
	novoPlano,
	onConfirmarAdesaoClick,
	efetuarPagamentoAoAderir,
	dataProximoPagamento,
	valorPagoNoUltimoMes,
	informacoesCartao,
	isAdesaoInstantanea,
}) {
	const { selecionarMetodo, setSelecionarMetodo, activeIndex, setActiveIndex } = useContextPlanos();

	const valorNovoPlanoFormatado = formatarMonetario(novoPlano.valor);
	const valorPlanoAtualFormatado = formatarMonetario(valorPlanoAtual);

	const [triggerSalvarCartao, setTriggerSalvarCartao] = useState(0);
	const [metodoPagamento, setMetodoPagamento] = useState(METODO_PAGAMENTO.PIX);

	function salvarNovoCartao() {
		setTriggerSalvarCartao(triggerSalvarCartao + 1);
	}

	function getIsUltimoPasso() {
		if (activeIndex === GetSteps.length - 1) {
			return true;
		}

		return false;
	}

	function handleBotaoAdesaoInstantanea() {
		onConfirmarAdesaoClick(metodoPagamento);
	}

	function handleAcaoBotaoModal() {
		if (isAdesaoInstantanea) {
			handleBotaoAdesaoInstantanea();
			return;
		}
		if (activeIndex === 0) {
			setSelecionarMetodo(true);
		} else if (selecionarMetodo && activeIndex === 1) {
			setSelecionarMetodo(false);
			return;
		}
		if (metodoPagamento === METODO_PAGAMENTO.CARTAO && !selecionarMetodo) {
			onConfirmarAdesaoClick(metodoPagamento);
			return;
		}
		setActiveIndex(activeIndex + 1);
	}

	function handleAcaoBotaoCartao() {
		salvarNovoCartao();
	}

	function handleLabelBotaoModal() {
		if (selecionarMetodo && activeIndex === 1) {
			return 'Selecionar';
		} else if (metodoPagamento === METODO_PAGAMENTO.CARTAO && !selecionarMetodo) {
			return 'Efetuar pagamento';
		}
		if (novoPlano.valor - valorPagoNoUltimoMes > 0) {
			return 'Próximo';
		} else {
			return 'Efetuar adesão';
		}
	}

	function buscarStatusEtapa() {
		return 'process';
	}

	return (
		<Modal visible={visible} onHide={onHide} styleModal={{ minWidth: '200px', maxWidth: '900px' }}>
			<ReactSteps etapas={GetSteps} current={activeIndex} status={buscarStatusEtapa()} />
			<If test={activeIndex === 0}>
				<DetalhesForm
					valorPlanoAtual={valorPlanoAtual}
					novoPlano={novoPlano}
					efetuarPagamentoAoAderir={efetuarPagamentoAoAderir}
					dataProximoPagamento={dataProximoPagamento}
					valorPagoNoUltimoMes={valorPagoNoUltimoMes}
					valorNovoPlanoFormatado={valorNovoPlanoFormatado}
					valorPlanoAtualFormatado={valorPlanoAtualFormatado}
					metodoPagamento={metodoPagamento}
					setMetodoPagamento={setMetodoPagamento}
				/>
			</If>
			<If test={activeIndex === 1}>
				<PagamentoForm
					valorPlanoAtual={valorPlanoAtual}
					novoPlano={novoPlano}
					efetuarPagamentoAoAderir={efetuarPagamentoAoAderir}
					dataProximoPagamento={dataProximoPagamento}
					valorPagoNoUltimoMes={valorPagoNoUltimoMes}
					valorNovoPlanoFormatado={valorNovoPlanoFormatado}
					valorPlanoAtualFormatado={valorPlanoAtualFormatado}
					metodoPagamento={metodoPagamento}
					setMetodoPagamento={setMetodoPagamento}
					onConfirmarAdesaoClick={onConfirmarAdesaoClick}
					informacoesCartao={informacoesCartao}
					setActiveIndex={setActiveIndex}
					triggerSalvarCartao={triggerSalvarCartao}
				/>
			</If>
			<If test={activeIndex === 2}>
				<AnimacaoCompletado isAdesaoInstantanea={isAdesaoInstantanea} onHide={onHide} />
			</If>
			<If
				test={
					(metodoPagamento === METODO_PAGAMENTO.PIX && activeIndex !== 1) ||
					(selecionarMetodo && activeIndex === 1) ||
					(!selecionarMetodo && activeIndex === 1 && metodoPagamento !== METODO_PAGAMENTO.PIX)
				}
			>
				<If test={(selecionarMetodo || metodoPagamento !== 'NOVO_CARTAO') && !getIsUltimoPasso()}>
					<Grid justifyEnd>
						<Button
							className="p-button-pimary"
							label={handleLabelBotaoModal()}
							onClick={() => handleAcaoBotaoModal()}
						/>
					</Grid>
				</If>
				<If test={!selecionarMetodo && metodoPagamento === 'NOVO_CARTAO' && !getIsUltimoPasso()}>
					<Grid justifyEnd>
						<Button className="p-button-pimary" label="Salvar" onClick={() => handleAcaoBotaoCartao()} />
					</Grid>
				</If>
			</If>
		</Modal>
	);
}
