import { baixarArquivo, buscarItemLocalStorage } from 'Common';
import {
	ButtonCancelar,
	ButtonNovo,
	If,
	ModalInutilizacao,
	NormalButton,
	TipoInutilizacao,
	ToastTypes,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	notify,
} from 'components';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import ModalPedidoImportacao from '../../../../../../../../../components/ModalPedidoImportacao';
import { ModuloImportacao } from '../../../../../../../../../components/ModalPedidoImportacao/Util/Constantes';
import { atualizarUrl } from '../../../../../../../../Util';
import { useContextNFCe } from '../../../../../Context';
import { asyncImprimirServicoLocal, imprimirDANFE, imprimirXMLNfce } from '../../../../../Requests';
import ModalCompartilharPorEmail from '../../../ModalCompartilharPorEmail';
import ModalCompartilharPorWhatsapp from '../../../ModalCompartilharPorWhatsapp';

function ActionButtons(props) {
	const {
		dirty,
		values,
		setModalHistoricoVisible,
		setModalListagemVisible,
		setModalMenuFiscalVisible,
		setModalCancelamentoVisible,
		mostrarMenuFiscal,
		novaNFCe,
		isMobile,
		isTablet,
		isLessHd,
		isFullScreen,
		pathname,
	} = props;

	const {
		modalInutilizacao,
		setModalInutilizacao,
		setExibirLoadingImprimir,
		setMessageLoadingTransmissao,
		informacoesPermissoes,
		modalPedidoImportacao,
		setModalPedidoImportacao,
		setModalCompartilharPorEmailVisible,
		modalCompartilharPorEmailVisible,
		setModalCompartilharPorWhatsappVisible,
		modalCompartilharPorWhatsappVisible,
	} = useContextNFCe();

	const menuOpcoes = useRef(null);
	const itensOpcoes = buscaritensOpcoes();

	function buscaritensOpcoes() {
		const res = [];

		if (!values.id) {
			const importarPedido = {
				label: 'Importar pedido [F8]',
				icon: 'pi pi-download',
				command: () => setModalPedidoImportacao(true),
				disabled: dirty,
			};
			res.push(importarPedido);
		}

		if (values.id) {
			const cancelarNFCe = {
				label: 'Cancelar NFC-e [F6]',
				icon: 'fa fa-times-circle-o',
				command: () => {
					setModalCancelamentoVisible(true);
				},
			};
			res.push(cancelarNFCe);

			const historico = {
				label: 'Histórico',
				icon: 'fa fa-history',
				command: () => setModalHistoricoVisible(true),
			};
			res.push(historico);

			const imprimirDocumento = {
				label: 'Imprimir NFC-e',
				icon: 'fa fa-print',
				command: () => imprimirDanfce(),
			};
			res.push(imprimirDocumento);

			const xmlDocumento = {
				label: 'Baixar XML da NFC-e',
				icon: 'fa fa-download',
				command: () => downloadXML(),
			};
			res.push(xmlDocumento);

			const compartilharEmail = {
				label: 'Enviar por e-mail',
				icon: 'fa fa-envelope',
				command: () => setModalCompartilharPorEmailVisible(true),
			};
			res.push(compartilharEmail);

			const compartilharWhatsapp = {
				label: 'Enviar por WhatsApp',
				icon: 'fa fa-whatsapp',
				command: () => setModalCompartilharPorWhatsappVisible(true),
			};
			res.push(compartilharWhatsapp);
		}

		const inutilizacao = {
			label: 'Inutilizar numeração',
			icon: 'fa fa-minus-circle',
			command: () => setModalInutilizacao(true),
			disabled: !informacoesPermissoes.podeVisualizar,
		};
		res.push(inutilizacao);

		if (mostrarMenuFiscal && (isTablet || isMobile || isLessHd)) {
			const menuFiscal = {
				label: 'Menu fiscal [F9]',
				icon: 'fa fa-bars',
				command: () => setModalMenuFiscalVisible(true),
			};
			res.push(menuFiscal);
		}

		return res;
	}

	async function imprimirDanfce() {
		setExibirLoadingImprimir(true);
		setMessageLoadingTransmissao('Imprimindo NFC-e...');
		const mostrarPreview = buscarItemLocalStorage('mostrarPreviewNFCe', true);
		await imprimirDANFE(values.id, !mostrarPreview, async ({ data: arquivo }) => {
			if (mostrarPreview) {
				const arquivoPDF = new Blob([arquivo], { type: 'application/pdf' });
				const arquivoURL = URL.createObjectURL(arquivoPDF);
				const danfce = window.open(arquivoURL);
				if (danfce) {
					danfce.onload = () => {
						setTimeout(() => {
							danfce.document.title = 'Preview NFC-e';
						}, 250);
					};
				}
			} else {
				const formData = new FormData();
				const blob = new File([arquivo], { type: 'application/pdf' });
				formData.append('arquivo', blob, `NFCe ${values.numero} (${values.id}).pdf`);

				await asyncImprimirServicoLocal(formData, isFullScreen, null, () => {
					notify('Erro ao imprimir a NFC-e', ToastTypes.ERROR);
					const layoutFullscreen = document.getElementsByClassName('layout-fullscreen');
					const alertifyNotifier = document.getElementsByClassName('alertify-notifier');

					if (isFullScreen) {
						layoutFullscreen[0].append(alertifyNotifier[0]);
					}
				});
			}
			setExibirLoadingImprimir(false);
		});
	}

	async function downloadXML() {
		imprimirXMLNfce(values.id, ({ data: file }) => {
			baixarArquivo(file, values.chaveAcesso, 'application/xml');
		});
	}

	function cancelar() {
		if (dirty) {
			novaNFCe();
		} else {
			if (pathname === '/nfces/cadastro/:id') {
				novaNFCe();
			}
			props.history.goBack();
		}
	}

	function onClickNovo() {
		atualizarUrl(props.history, `/nfces/cadastro`);
		novaNFCe();
	}

	return (
		<div
			className="step-nfce-action-buttons"
			style={{
				width: '100%',
				paddingBottom: '0.5rem',
				display: isMobile ? 'grid' : 'flex',
				gap: isTablet || isMobile ? '0.5rem' : null,
			}}
		>
			<Menu
				model={itensOpcoes}
				style={{ minWidth: '230px' }}
				popup
				ref={menuOpcoes}
				appendTo={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
			/>
			<ButtonCancelar
				id="id-button-cancelar-nfce"
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={() => cancelar()}
				style={{
					margin: '3.5px',
					height: '100%',
					width: isTablet || isMobile ? '100%' : 'auto',
				}}
				enableShortCut
				shortCut="F2"
			/>
			<ButtonNovo
				id="id-button-novo-nfce"
				hidden={!values.id}
				estadoBotao={estadosBotaoNovo.NOVO}
				onClick={() => onClickNovo()}
				style={{
					margin: '3.5px',
					height: '100%',
					width: isTablet || isMobile ? '100%' : 'auto',
				}}
				label="Novo [F7]"
				enableShortcut
			/>
			<NormalButton
				className="p-button-primary"
				type="button"
				label={isTablet || isMobile ? 'Listagem [F5]' : 'Listagem NFC-e [F5]'}
				icon="fa fa-bars"
				style={{
					margin: '3.5px',
					height: '100%',
					width: isTablet || isMobile ? '100%' : 'auto',
				}}
				onClick={() => setModalListagemVisible(true)}
				{...informacoesPermissoes}
			/>
			<NormalButton
				className="p-button-primary"
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{
					margin: '3.5px',
					height: '100%',
					width: isTablet || isMobile ? '100%' : 'auto',
				}}
				onClick={(event) => menuOpcoes.current.toggle(event)}
			/>

			<If test={mostrarMenuFiscal && !isTablet && !isMobile && !isLessHd}>
				<NormalButton
					className="p-button-success"
					type="button"
					label="Menu fiscal [F9]"
					icon="fa fa-bars"
					style={{
						margin: '3.5px',
						height: '100%',
						width: isTablet || isMobile ? '100%' : 'auto',
					}}
					onClick={() => setModalMenuFiscalVisible(true)}
					{...informacoesPermissoes}
				/>
			</If>

			<If test={modalInutilizacao}>
				<ModalInutilizacao
					{...props}
					header="Inutilização de NFC-e"
					visible={modalInutilizacao}
					onHide={() => setModalInutilizacao(false)}
					tipoInutilizacao={TipoInutilizacao.NFCE}
					podeInserir={informacoesPermissoes.podeInserirInutilizacao}
					container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
					isLessHd={isLessHd}
					isMobile={isMobile}
				/>
			</If>
			<If test={modalPedidoImportacao}>
				<ModalPedidoImportacao
					moduloImportacao={ModuloImportacao.NFCE}
					visible={modalPedidoImportacao}
					onHide={() => setModalPedidoImportacao(false)}
					isMobile={isMobile}
					isTablet={isTablet}
					history={props.history}
					container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
				/>
			</If>
			<If test={modalCompartilharPorEmailVisible}>
				<ModalCompartilharPorEmail
					idNFCe={values.id}
					idPessoa={values.pessoa?.value}
					numero={values.numero}
					visible={modalCompartilharPorEmailVisible}
					onHide={() => setModalCompartilharPorEmailVisible(false)}
					container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
				/>
			</If>
			<If test={modalCompartilharPorWhatsappVisible}>
				<ModalCompartilharPorWhatsapp
					idNFCe={values.id}
					idPessoa={values.cliente?.clienteCadastrado?.registro?.id}
					nomeCliente={
						values.cliente?.clienteCadastrado
							? values.cliente?.clienteCadastrado?.registro?.xNome
							: values.cliente?.nome
					}
					numero={values.numero}
					telefone={values.cliente?.clienteCadastrado?.registro?.telefone}
					visible={modalCompartilharPorWhatsappVisible}
					onHide={() => setModalCompartilharPorWhatsappVisible(false)}
					container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
				/>
			</If>
		</div>
	);
}

export default ActionButtons;
