import { exibirToast, get, post, del, put, services } from 'Common';

export async function readSeguradoras(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function readSeguradora(idSeguradora, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/transporte/seguradoras/${idSeguradora}`, null, onSuccess, onError);
}

export async function createSeguradora(seguradora, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/transporte/seguradoras`,
		seguradora,
		null,
		exibirToast(onSuccess, 'Seguradora criada com sucesso'),
		onError
	);
}

export async function updateSeguradora(seguradora, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/transporte/seguradoras/${seguradora.id}`,
		seguradora,
		null,
		exibirToast(onSuccess, 'Seguradora atualizada com sucesso'),
		onError
	);
}

export async function deleteSeguradora(idSeguradora, onSuccess, onError) {
	return await del(
		`${services.GESTOR}/v1/transporte/seguradoras/${idSeguradora}`,
		null,
		exibirToast(onSuccess, 'Seguradora excluído'),
		onError
	);
}
