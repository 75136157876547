import { Field, useFormikContext } from 'formik';

import { Grid, InputMoney, SingleSelectCst, typesCst } from 'components';

function Ipi({ tributo, disabled, informacoesPermissoes, onBlurValoresTributacao }) {
	const { errors, setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Field
				sm="12"
				md="12"
				lg="6"
				xl="6"
				component={SingleSelectCst}
				useFormErrors={false}
				useFormTouched={false}
				errors={errors?.enquadramentoIpi ?? null}
				touched={!!errors?.enquadramentoIpi}
				type={typesCst.ENQUADRAMENTO_IPI}
				label="Código enquadramento IPI"
				name="tributos.ipi.enquadramentoIpi"
				value={tributo?.enquadramentoIpi}
				isClearable={false}
				onChange={(event) => setFieldValue('tributos.ipi.enquadramentoIpi', event)}
				obrigatorio
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="6"
				xl="6"
				component={SingleSelectCst}
				useFormErrors={false}
				useFormTouched={false}
				errors={errors?.cstIpi ?? null}
				touched={!!errors?.cstIpi}
				type={typesCst.CST_IPI}
				label="CST IPI"
				name="tributos.ipi.cstIpi"
				value={tributo?.cst}
				isClearable={false}
				onChange={(event) => setFieldValue('tributos.ipi.cst', event)}
				obrigatorio
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputMoney}
				label="BC IPI"
				name="tributos.ipi.baseIpi"
				value={tributo?.baseIpi}
				touched
				allowNegative={false}
				onChange={(event) => setFieldValue('tributos.ipi.baseIpi', event.target?.value)}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputMoney}
				label="IPI %"
				name="tributos.ipi.percentualIpi"
				value={tributo?.percentualIpi}
				onChange={(event) => setFieldValue('tributos.ipi.percentualIpi', event.target?.value)}
				size={8}
				decimalScale={2}
				prefix=""
				suffix="%"
				maxValue={100}
				placeholder="0,00 %"
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={InputMoney}
				label="Valor IPI"
				name="tributos.ipi.valorIpi"
				value={tributo?.valorIpi}
				touched
				allowNegative={false}
				onChange={(event) => setFieldValue('tributos.ipi.valorIpi', event.target?.value)}
				onBlur={onBlurValoresTributacao}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { Ipi };
