const BancoDoBrasil = {
	NOME: 'Banco do Brasil',
	AGENCIA: {
		descricao:
			'No campo agência pode ser informada o número da agência da conta. Este campo é composto por 4 dígitos + DV.',
		exemplo: 'Exemplo: 1234-5.',
		titulo: 'Agência',
	},
	NUMERO: {
		descricao: 'Campo referente ao número da conta. Campo de até 8 de dígitos da conta corrente, mais um DV.',
		exemplo: 'Exemplo: 123456-7.',
		titulo: 'Número',
	},
	CODIGO_BENEFICIARIO: {
		descricao: 'Neste campo pode ser informado a conta corrente sem o DV.',
		exemplo: 'Exemplo: 123456.',
		titulo: 'Cód. beneficiário',
	},
};

const BancoDoBrasilConvenio = {
	NOME: 'Banco do Brasil',
	PADRAO_CNAB: {
		descricao: 'Layout adotado pelo Banco.',
		exemplo: '400 ou 240',
		titulo: 'CNAB',
	},
	NUMERO_CONVENIO: {
		descricao: 'Neste campo pode ser informado o número do convênio vinculado à conta.',
		exemplo: 'Exemplo: 1234567.',
		titulo: 'Número convênio',
	},
	DESCRICAO_CONVENIO: {
		descricao: 'Campo de texto para controle interno e identificação do convênio.',
		exemplo: 'Convênio Banco do Brasil',
		titulo: 'Descrição convênio',
	},
	CARTEIRA: {
		descricao: 'Informar o Número da carteira de acordo com o repassado pelo banco (Informar apenas o código).',
		exemplo:
			'17 - Cobrança com registro (Convênio de até 7 Dígitos), 18 - Cobrança sem registro (Convênio de até 7 Dígitos)',
		titulo: 'Número da carteira',
	},
	NUMERO_REMESSA_REINICIA: {
		descricao: 'Para Banco do Brasil pode ser marcado como "Sim"',
		titulo: 'Nº remessa reinicia diariamente',
	},
	NUMERO_REMESSA: {
		descricao:
			'Caso a opção "Nº remessa reinicia diariamente" for "Não", deve ser informado o número da remessa atual.',
		titulo: 'Número da remessa',
	},
	TIPO_WEBSERVICE: {
		descricao: 'para Banco do Brasil, está opção deve ser "V2"',
		titulo: 'Tipo webservice',
	},
};

const Santander = {
	NOME: 'Santander',
	AGENCIA: {
		descricao: 'Informar a agência mantenedora da conta. Este campo é composto por até 4 dígitos + DV.',
		exemplo: 'Exemplo: 1234-5.',
		titulo: 'Agência',
	},
	NUMERO: {
		descricao: 'Campo referente ao número da conta. Campo de até 8 de dígitos da conta corrente + DV.',
		exemplo: 'Exemplo: 123456-7.',
		titulo: 'Número',
	},
	CODIGO_BENEFICIARIO: {
		descricao: 'Neste campo pode ser informado o código do beneficiário.',
		exemplo: 'Exemplo: 123456.',
		titulo: 'Cód. beneficiário',
	},
};

const SantanderConvenio = {
	NOME: 'Santander',
	PADRAO_CNAB: {
		descricao: 'Layout adotado pelo Banco.',
		exemplo: '400 ou 240',
		titulo: 'CNAB',
	},
	NUMERO_CONVENIO: {
		descricao: 'Neste campo pode ser informado o mesmo código preenchido no campo código do beneficiário.',
		exemplo: 'Exemplo: 123456.',
		titulo: 'Número convênio',
	},
	DESCRICAO_CONVENIO: {
		descricao: 'Campo de texto para controle interno e identificação do convênio.',
		exemplo: 'Convênio Santander',
		titulo: 'Descrição convênio',
	},
	CARTEIRA: {
		descricao: 'Informar o Número da carteira de acordo com o repassado pelo banco (Informar apenas o código).',
		exemplo: 'RCR - Cobrança Simples Rápida com Registro, CSR - Cobrança Simples sem Registro',
		titulo: 'Número Carteira',
	},
	NUMERO_REMESSA_REINICIA: {
		descricao: 'Para Santander pode ser marcado como "Sim"',
		titulo: 'Nº remessa reinicia diariamente',
	},
	NUMERO_REMESSA: {
		descricao:
			'Caso a opção "N° remessa reinicia diariamente" for "Não", deve ser informado o número da remessa atual.',
		titulo: 'Número da remessa',
	},
};

const Banrisul = {
	NOME: 'Banrisul',
	AGENCIA: {
		descricao:
			'Agência Mantenedora da Conta. Código adotado pelo Banco responsável pela conta, para identificar a qual unidade está vinculada a conta corrente Este campo é composto por até 4 dígitos',
		exemplo: 'Exemplo: 1234.',
		titulo: 'Agência',
	},
	NUMERO: {
		descricao: 'Campo referente ao número da conta. Campo composto por até 9 dígitos + DV.',
		exemplo: 'Exemplo: 123456789-0.',
		titulo: 'Número',
	},
	CODIGO_BENEFICIARIO: {
		descricao:
			'Código do Convênio no Banco (número fornecido pelo Banco).  Trata-se do código adotado pelo Banco para identificar o contrato. É composto por: Número da agência (4 dígitos), código do beneficiário (7 dígitos) e número de controle (2 dígitos).  Neste campo, deve-se informar apenas o código do beneficiário com os 7 dígitos juntamente com o número de controle (2 dígitos).',
		exemplo: 'Exemplo: 111111122',
		titulo: 'Cód. beneficiário',
	},
};

const BanrisulConvenio = {
	NOME: 'Banrisul',
	PADRAO_CNAB: {
		descricao: 'Layout adotado pelo Banco',
		exemplo: '"400" ou "240"',
		titulo: 'CNAB',
	},
	NUMERO_CONVENIO: {
		descricao: 'Neste campo pode ser informado o código beneficiário (número fornecido pelo Banco).',
		exemplo: 'Exemplo: 111111122.',
		titulo: 'Número convênio',
	},
	DESCRICAO_CONVENIO: {
		descricao: 'Campo de texto para controle interno e identificação do convênio.',
		exemplo: 'Convênio Banrisul',
		titulo: 'Descrição convênio',
	},
	CARTEIRA: {
		descricao: 'Informar o Número da carteira de acordo com o repassado pelo banco (Informar apenas o código).',
		exemplo: '1 - COBRANÇA SIMPLES 2 - COBRANÇA VINCULADA',
		titulo: 'Número Carteira',
	},
	CODIGO_CARTEIRA: {
		descricao: 'Informar o Código da carteira de acordo com o repassado pelo banco (Informar apenas o código).',
		exemplo:
			'Valores possíveis: Cobrança Simples; Cobrança Vinculada; Cobrança Caucionada; Cobrança Descontada; Cobrança Vendor.',
		titulo: 'Código carteira',
	},
	NUMERO_REMESSA_REINICIA: {
		descricao: 'Para Banrisul pode ser marcado como "Sim"',
		titulo: 'Nº remessa reinicia diariamente',
	},
	NUMERO_REMESSA: {
		descricao:
			'Caso a opção "N° remessa reinicia diariamente" for "Não", deve ser informado o número da remessa atual.',
		titulo: 'Número da remessa',
	},
	NUMERO_CONTRATO: {
		descricao:
			'Campo opcional, utilizado para informar o número de contrato da conta caso o banco exija este campo presente na remessa. Disponível apenas no CNAB 240.',
		titulo: 'Número do contrato',
	},
	VERSAO_LAYOUT: {
		descricao:
			'Campo opcional, onde pode ser informada a versão do layout que será utilizado, caso ele não seja a versão mais recente. Caso não seja informado, a remessa será gerada na versão 103. (Informar apenas o código).',
		Exemplo: 'Valor possível:  001: Gera a remessa na versão 040 do manual. Disponível apenas no CNAB 240.',
		titulo: 'Versão layout',
	},
};

const Caixa = {
	NOME: 'Caixa',
	AGENCIA: {
		descricao:
			'Informar a agência mantenedora da conta. Este campo é composto por 4 dígitos, o campo referente ao DV da agência pode ficar vazio.',
		exemplo: 'Dica de preechimento, 1234.',
		titulo: 'Agência',
	},
	NUMERO: {
		descricao: 'Campo referente ao número da conta. Campo de até 6 de dígitos da conta corrente + DV.',
		exemplo: 'Exemplo: 123456-7.',
		titulo: 'Número',
	},
	CODIGO_BENEFICIARIO: {
		descricao: 'Neste campo pode ser informado o código do beneficiário sem o DV.',
		exemplo: 'Exemplo: 654321.',
		titulo: 'Cód. beneficiário',
	},
};

const CaixaConvenio = {
	NOME: 'Caixa',
	PADRAO_CNAB: {
		descricao: 'Layout adotado pelo Banco',
		exemplo: '"400" ou "240"',
		titulo: 'CNAB',
	},
	NUMERO_CONVENIO: {
		descricao: 'Neste campo pode ser informado o código do beneficiário com o DV.',
		exemplo: 'Exemplo: 7654321.',
		titulo: 'Número convênio',
	},
	DESCRICAO_CONVENIO: {
		descricao: 'Campo de texto para controle interno e identificação do convênio.',
		exemplo: '"Convênio Caixa".',
		titulo: 'Descrição convênio',
	},
	CARTEIRA: {
		exemplo: '14 - Cobrança com registro (SIGCB) 24 - Cobrança sem registro (SIGCB)',
		titulo: 'Número Carteira',
	},
	NUMERO_REMESSA_REINICIA: {
		descricao: 'Para Caixa, está opção pode ser marcada como "Sim".',
		titulo: 'Nº remessa reinicia diariamente',
	},
	NUMERO_REMESSA: {
		descricao:
			'Caso a opção "N° remessa reinicia diariamente" for "Não", deve ser informado o número da remessa atual.',
		titulo: 'Número da remessa',
	},
	VERSAO_LAYOUT: {
		descricao: 'Para as versões de layout no CNAB 240, estão disponíveis os códigos (Informar apenas o código).',
		exemplo: '107 - Layout Novo, 101 - Layout antigo',
		titulo: 'Versão layout',
	},
};

const Unicred = {
	NOME: 'Unicred',
	NUMERO: {
		descricao: 'Informar o número da conta.  campo composto por até 12 dígitos + DV.',
		exemplo: '12345-6 ou 123456789123-1',
		titulo: 'Número',
	},
	AGENCIA: {
		descricao: 'Informar a agência mantenedora da conta.  Este campo é composto por até 5 dígitos + DV.',
		exemplo: '12345-6',
		titulo: 'Agência',
	},
	CODIGO_BENEFICIARIO: {
		descricao: 'Neste campo pode ser informado o código do beneficiário.',
		exemplo: '1234567',
		titulo: 'Cód. beneficiário',
	},
	CODIGO_EMPRESA: {
		descricao: 'Informar o código da empresa caso o banco disponibilize.',
		titulo: 'Cód. empresa',
	},
};

const UnicredConvenio = {
	NOME: 'Unicred',
	PADRAO_CNAB: {
		descricao: 'Layout adotado pelo Banco',
		exemplo: '400 ou 240',
		titulo: 'CNAB',
	},
	NUMERO_CONVENIO: {
		descricao: 'Neste campo pode ser informado o código beneficiário',
		exemplo: '1234567',
		titulo: 'Número convênio',
	},
	DESCRICAO_CONVENIO: {
		descricao: 'Campo de texto para controle interno e identificação do convênio.',
		exemplo: 'Convênio Unicred',
		titulo: 'Descrição convênio',
	},
	CARTEIRA: {
		descricao: 'Informar o Número da carteira de acordo com o repassado pelo banco (Informar apenas o código).',
		titulo: 'Número Carteira',
	},
	NUMERO_REMESSA: {
		descricao: 'Deve ser informado o número da remessa atual.',
		titulo: 'Número da remessa',
	},
};

const Bradesco = {
	NOME: 'Bradesco',
	NUMERO: {
		descricao: 'Campo referente ao número da conta. Campo composto por até 7 dígitos + DV.',
		exemplo: 'Exemplo: 1234567-8.',
		titulo: 'Número',
	},
	AGENCIA: {
		descricao: 'Informar a agência mantenedora da conta. Este campo é composto por 4 dígitos + DV',
		exemplo: 'Exemplo: 1234-5.',
		titulo: 'Agência',
	},
	CODIGO_BENEFICIARIO: {
		descricao: 'Neste campo pode ser informado o número da conta sem o DV.',
		exemplo: 'Exemplo: 1234567.',
		titulo: 'Cód. beneficiário',
	},
	CODIGO_EMPRESA: {
		descricao: 'Neste campo pode ser informado o Acessório Escritural (Verificar informação com o Banco).',
		exemplo: 'Exemplo: 1234567.',
		titulo: 'Cód. empresa',
	},
};

const BradescoConvenio = {
	NOME: 'Bradesco',
	PADRAO_CNAB: {
		descricao: 'Layout adotado pelo Banco',
		exemplo: '400 ou 240',
		titulo: 'CNAB',
	},
	NUMERO_CONVENIO: {
		descricao: 'Neste campo pode ser informado o número da conta sem o DV.',
		exemplo: '1234567',
		titulo: 'Número convênio',
	},
	DESCRICAO_CONVENIO: {
		descricao: 'Campo de texto para controle interno e identificação do convênio.',
		exemplo: 'Convênio Bradesco',
		titulo: 'Descrição convênio',
	},
	CARTEIRA: {
		descricao: 'Informar o Número da carteira de acordo com o repassado pelo banco (Informar apenas o código).',
		exemplo:
			'02 - Cobrança com registro, 04 - Cobrança com registro, 06 - Cobrança sem registr,o 09 - Cobrança com registro (Modalidade escritural, Nosso número Livre, Nosso Número livre - Emissão pelo banco)',
		titulo: 'Número Carteira',
	},
	NUMERO_REMESSA_REINICIA: {
		descricao: 'Para Bradesco, está opção pode ser marcada como "Não" quando o CNAB for 400.',
		titulo: 'Nº remessa reinicia diariamente',
	},
	NUMERO_REMESSA: {
		descricao:
			'Caso a opção "N° remessa reinicia diariamente" for "Não", deve ser informado o número da remessa atual.',
		titulo: 'Número da remessa',
	},
	CODIGO_DENSIDADE_REMESSA: {
		descricao:
			'Apenas para o CNAB 240. Campo recebe o código da densidade do arquivo. Preencher com 1600 ou 6250, conforme orientação do banco.',
		titulo: 'Código densidade da remessa',
	},
	TIPO_WEBSERVICE: {
		descricao:
			'Informar o tipo de transmissão informado pelo banco, caso o banco não especifique, normalmente é o tipo "Normal".',
		exemplo: 'Normal, Shop Fácil',
		titulo: 'Tipo webservice',
	},
};

const Itau = {
	NOME: 'Itau',
	NUMERO: {
		descricao: 'Campo referente ao número da conta. Campo composto por 5 dígitos + DV.',
		exemplo: 'Exemplo: 12345-6.',
		titulo: 'Número',
	},
	AGENCIA: {
		descricao:
			'Informar a agência mantenedora da conta. Este campo é composto por 4 dígitos, o campo referente ao DV da agência pode ficar vazio.',
		exemplo: 'Exemplo: 9999.',
		titulo: 'Agência',
	},
	CODIGO_BENEFICIARIO: {
		descricao: 'Neste campo pode ser informado o número da conta sem o DV.',
		exemplo: '12345.',
		titulo: 'Cód. beneficiário',
	},
};

const ItauConvenio = {
	NOME: 'Itau',
	PADRAO_CNAB: {
		descricao: 'Layout adotado pelo Banco',
		exemplo: '400 ou 240',
		titulo: 'CNAB',
	},
	NUMERO_CONVENIO: {
		descricao: 'Neste campo pode ser informado a agência mais o número da conta + DV.',
		exemplo: '9999123456.',
		titulo: 'Número convênio',
	},
	DESCRICAO_CONVENIO: {
		descricao: 'Campo de texto para controle interno e identificação do convênio.',
		exemplo: 'Convênio Itaú.',
		titulo: 'Descrição convênio',
	},
	CARTEIRA: {
		descricao: 'Informar o Número da carteira de acordo com o repassado pelo banco (Informar apenas o código).',
		exemplo:
			'109 - Cobrança com registrom, 104 - Cobrança com registro (Escritural Eletrônica), 175 - Cobrança sem registro',
		titulo: 'Número Carteira',
	},
	NUMERO_REMESSA: {
		descricao: '	Informado o número da remessa atual.',
		titulo: 'Número da remessa',
	},
};

const Sicredi = {
	NOME: 'Sicredi',
	NUMERO: {
		descricao: 'Campo referente ao número da conta. Campo composto por 5 dígitos + DV.',
		exemplo: 'Exemplo: 12345-6.',
		titulo: 'Número',
	},
	AGENCIA: {
		descricao:
			'Informar a agência mantenedora da conta. Este campo é composto por até 6 dígitos (Agência + Posto), o campo referente ao DV da agência deverá ficar vazio.',
		exemplo: '123459',
		titulo: 'Agência',
	},
	CODIGO_BENEFICIARIO: {
		descricao:
			'Código do Beneficiário (ou código do cliente) informado pelo banco. Obs.: Caso o Sicredi não tenha especificado um valor, é comum que o mesmo seja o número da conta sem o DV. Contudo, recentemente identificamos que esse valor pode ser diferente do número de conta, isso ocorre para casos onde é solicitado um novo beneficiário para a conta e para esses casos recomendamos que o valor seja confirmado junto ao banco.',
		exemplo: '12345',
		titulo: 'Cód. beneficiário',
	},
};

const SicrediConvenio = {
	NOME: 'Sicredi',
	PADRAO_CNAB: {
		descricao: 'Layout adotado pelo Banco',
		exemplo: '400 ou 240',
		titulo: 'CNAB',
	},
	NUMERO_CONVENIO: {
		descricao: 'Neste campo pode ser informado o número da conta sem o DV.',
		exemplo: '12345.',
		titulo: 'Número convênio',
	},
	DESCRICAO_CONVENIO: {
		descricao: 'Campo de texto para controle interno e identificação do convênio.',
		exemplo: 'Convênio Sicredi',
		titulo: 'Descrição convênio',
	},
	CARTEIRA: {
		descricao: 'Informar o Número da carteira de acordo com o repassado pelo banco (Informar apenas o código).',
		exemplo: '1 - Carteira registrada',
		titulo: 'Número Carteira',
	},
	NUMERO_REMESSA_REINICIA: {
		descricao: 'Para Sicredi, está opção pode ser marcada como "Não", quando o CNAB for 400.',
		titulo: 'Nº remessa reinicia diariamente',
	},
	NUMERO_REMESSA: {
		descricao:
			'Caso a opção "N° remessa reinicia diariamente" for "Não", deve ser informado o número da remessa atual.',
		titulo: 'Número da remessa',
	},
};

const Sicoob = {
	NOME: 'Sicoob',
	NUMERO: {
		descricao: 'Campo referente ao número da conta. Campo de até 7 de dígitos da conta corrente, mais o DV da Conta.',
		exemplo: '1234567-8',
		titulo: 'Número',
	},
	AGENCIA: {
		descricao: 'Informar a agência mantenedora da conta. Este campo é composto por 4 dígitos + DV da Agência.',
		exemplo: '1234-5.',
		titulo: 'Agência',
	},
	CODIGO_BENEFICIARIO: {
		descricao: 'Neste campo pode ser informado o código do beneficiário com o seu DV.',
		exemplo: '123456',
		titulo: 'Cód. beneficiário',
	},
};

const SicoobConvenio = {
	NOME: 'Sicoob',
	PADRAO_CNAB: {
		descricao: 'Layout adotado pelo Banco',
		exemplo: '400 ou 240',
		titulo: 'CNAB',
	},
	NUMERO_CONVENIO: {
		descricao: 'Neste campo pode ser informado o código do beneficiário com seu o DV.',
		exemplo: '123456',
		titulo: 'Número convênio',
	},
	DESCRICAO_CONVENIO: {
		descricao: 'Campo de texto para controle interno e identificação do convênio.',
		exemplo: 'Convênio Sicoob',
		titulo: 'Descrição convênio',
	},
	CARTEIRA: {
		descricao: 'Informar o Número da carteira de acordo com o repassado pelo banco (Informar apenas o código).',
		exemplo: '1 - Simples Com Registro, 3 - Garantida Caucionada',
		titulo: 'Número Carteira',
	},
	CODIGO_CARTEIRA: {
		descricao: 'Informar o Código da carteira de acordo com o repassado pelo banco (Informar apenas o código).',
		titulo: 'Código carteira',
	},
	NUMERO_CONTRATO: {
		descricao:
			'Número do contrato da carteira de cobrança. Preencher apenas em caso de exigência bancária. Obs.: Geralmente o valor a ser preenchido neste campo é o mesmo que o informado no Código do beneficiário. Porém, recomendamos a confirmação do valor junto à instituição bancária.',
		titulo: 'Número do contrato',
	},
	NUMERO_REMESSA_REINICIA: {
		descricao: 'Para Sicoob, está opção pode ser marcada como "Sim"',
		titulo: 'Nº remessa reinicia diariamente',
	},
	NUMERO_REMESSA: {
		descricao:
			'Caso a opção "N° remessa reinicia diariamente" for "Não", deve ser informado o número da remessa atual.',
		titulo: 'Número da remessa',
	},
};

const linkDocumentacao = {
	//BB
	'cce6d2ff-71ed-48a9-88ff-20932616acf9':
		'http://documentacao.microsys.inf.br/books/gestor-%7C-boletos-via-web-service-banc%C3%A1rio/page/dicas-preenchimento-conv%C3%AAnio---banco-do-brasil',
	//Santander
	'41b59254-ad4f-45b2-9172-39fd8cc827c3':
		'http://documentacao.microsys.inf.br/books/gestor-%7C-boletos-via-web-service-banc%C3%A1rio/page/dicas-preenchimento-conv%C3%AAnio---santander',
	//Banrisul
	'0d771543-f868-440f-90bc-8919e9943e24':
		'http://documentacao.microsys.inf.br/books/gestor-%7C-boletos-via-web-service-banc%C3%A1rio/page/dicas-preenchimento-conv%C3%AAnio---banrisul',
	//Caixa
	'c9c29c8e-56b0-4e53-bf87-2ad12c0fa94e':
		'http://documentacao.microsys.inf.br/books/gestor-%7C-boletos-via-web-service-banc%C3%A1rio/page/dicas-preenchimento-conv%C3%AAnio---caixa',
	//Unicred
	'42bdc03d-06c1-4fcb-ac9e-6a9d99117099':
		'http://documentacao.microsys.inf.br/books/gestor-%7C-boletos-via-web-service-banc%C3%A1rio/page/dicas-preenchimento-conv%C3%AAnio---unicred',
	//Bradesco
	'd5dc03be-d0f4-4063-b992-60ea79df91de':
		'http://documentacao.microsys.inf.br/books/gestor-%7C-boletos-via-web-service-banc%C3%A1rio/page/dicas-preenchimento-conv%C3%AAnio---bradesco',
	//Itau
	'ac9a6bb7-f836-45b0-bed2-88122067450f':
		'http://documentacao.microsys.inf.br/books/gestor-%7C-boletos-via-web-service-banc%C3%A1rio/page/dicas-preenchimento-conv%C3%AAnio---ita%C3%BA',
	//Sicredi
	'fe79b0ad-602f-4fa9-a1c8-6a0e5a6447ad':
		'http://documentacao.microsys.inf.br/books/gestor-%7C-boletos-via-web-service-banc%C3%A1rio/page/dicas-preenchimento-conv%C3%AAnio---sicredi',
	//Sicoob
	'207cf63b-167a-42da-acb7-eb7be66fde67':
		'http://documentacao.microsys.inf.br/books/gestor-%7C-boletos-via-web-service-banc%C3%A1rio/page/dicas-preenchimento-conv%C3%AAnio---sicoob',
};

const listaBancosComDocumentacao = [
	'cce6d2ff-71ed-48a9-88ff-20932616acf9',
	'41b59254-ad4f-45b2-9172-39fd8cc827c3',
	'0d771543-f868-440f-90bc-8919e9943e24',
	'c9c29c8e-56b0-4e53-bf87-2ad12c0fa94e',
	'42bdc03d-06c1-4fcb-ac9e-6a9d99117099',
	'd5dc03be-d0f4-4063-b992-60ea79df91de',
	'ac9a6bb7-f836-45b0-bed2-88122067450f',
	'fe79b0ad-602f-4fa9-a1c8-6a0e5a6447ad',
	'207cf63b-167a-42da-acb7-eb7be66fde67',
];

export {
	BancoDoBrasil,
	BancoDoBrasilConvenio,
	Santander,
	SantanderConvenio,
	Banrisul,
	BanrisulConvenio,
	Caixa,
	CaixaConvenio,
	Unicred,
	UnicredConvenio,
	Bradesco,
	BradescoConvenio,
	Itau,
	ItauConvenio,
	Sicredi,
	SicrediConvenio,
	Sicoob,
	SicoobConvenio,
	linkDocumentacao,
	listaBancosComDocumentacao,
};
