import { useState, useEffect } from 'react';
import MultipleSelect from '../MultipleSelect';
import propTypes from 'prop-types';
import { removerCaracteres } from '../../../Common/ManipulacaoDeString';
import { copiarObjeto } from '../../../Common/Array';

export default function MultipleSelectProdutos(props) {
	const { value } = props;
	const [valueMulti, setValueMulti] = useState(null);	

	useEffect(
		()=>{	
			let produtosSelecionados = value;													
			if (produtosSelecionados.length){				
				produtosSelecionados = copiarObjeto(value);
				for (let i in produtosSelecionados) {
					produtosSelecionados[i].label=produtosSelecionados[i].registro.codigo + ' - ' + produtosSelecionados[i].registro.nome				
				}
			}			
			setValueMulti(produtosSelecionados);
		},[value]
	)

	function buscarUrlPesquisa(pesquisa, page) {		
		const pesquisaCodigo = removerCaracteres(pesquisa, ['.']);
        return `${props.url}?query=(codigo=contains="*${pesquisaCodigo}*",nome=contains="*${pesquisa}*",codigo=contains="*${pesquisa}*",sku=contains="*${pesquisa}*");situacao=="ATIVO"${props.filtroAdicionalUrl}&page=${page}&size=50&sort=nome`;		
	}

	function montarLabel(row) {
		if (row) {
			return row.codigo + " - " + row.nome+((row.sku && row.sku.trim() != '') ? ' - [Ref: '+row.sku+']' :'');
		}
	}

	return (
		<MultipleSelect
			montarLabel={montarLabel}
			buscarUrlPesquisa={buscarUrlPesquisa}									
			onChange={props.onChange}			
			{...props}
			value={valueMulti}
		/>
	);
}

MultipleSelectProdutos.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/setores`*/
	url: propTypes.string.isRequired,
	/** Filtor adicional a ser passado para o RSQL ex: ";tipo=="PRODUTO";controlarEstoque==true"*/
	filtroAdicionalUrl: propTypes.string,
};

MultipleSelectProdutos.defaultProps = {
	label: '',
	filtroAdicionalUrl: '',
};
