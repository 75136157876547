import { Modal } from '../../../../../components';
import FormManifestar from './FormModalManifestar';

function ModalManifestar(props) {
	const { onHide, visible, isMobile, isTablet, onSave } = props;

	return (
		<>
			<Modal
				header={'Manifestar'}
				onHide={onHide}
				visible={visible}
				styleModal={{
					maxWidth: isMobile ? '80%' : isTablet ? '50%' : '30%',
					height: 'auto',
				}}
				isMobile={isMobile}
			>
				<FormManifestar {...props} onSave={e => onSave(e)} onHide={onHide} />
			</Modal>
		</>
	);
}

export default ModalManifestar;
