import { useEffect } from 'react';
import { format, isValid, parseISO } from 'date-fns';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { mensagensDeValidacao } from '../../../../../Common';
import { Grid, InputDate, ModalRelatorio } from '../../../../../components';
import { asyncImprimirRelatorioFimVigenciaPeriodo } from '../../Requests';

const initialValue = {
  dataInicial: format(new Date(), 'yyyy-MM-01'),
  dataFinal: new Date().toISOString(),
};

function FimVigenciaPeriodo(props) {
  const { visible, onHide, values, handleSubmit, validateForm, isValid } = props;

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('InputDataInicial')?.focus();
    }, 500);
  },[]);

  async function imprimir() {
    await handleSubmit();
    await validateForm();

    if (isValid) {
      await asyncImprimirRelatorioFimVigenciaPeriodo(
        format(parseISO(values.dataInicial), 'yyyy-MM-dd'),
        format(parseISO(values.dataFinal), 'yyyy-MM-dd'),
        ({ data: pdf }) => {
          let arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
          let arquivoURL = URL.createObjectURL(arquivoPDF);
          let relatorio = window.open(arquivoURL);
          if (relatorio) {
            relatorio.onload = () => {
              setTimeout(() => {
                relatorio.document.title = `Documentos com fim da vigência no período - ${format(
                  parseISO(values.dataInicial),
                  'dd/MM/yyyy'
                )} - ${format(parseISO(values.dataFinal), 'dd/MM/yyyy')}`;
              }, 250);
              onHide();
            };
          }
        }
      );
    }
  }

  const parametros = (
    <>
      <Grid>
        <Field
          sm="12"
          md="6"
          lg="6"
          xl="6"
          component={InputDate}
          label="Data inicial"
          name="dataInicial"
          obrigatorio
          value={values.dataInicial}
          touched={true}
          id="InputDataInicial"
        />
        <Field
          sm="12"
          md="6"
          lg="6"
          xl="6"
          component={InputDate}
          label="Data final"
          name="dataFinal"
          obrigatorio
          value={values.dataFinal}
          touched={true}
        />
      </Grid>
    </>
  );

  return (
    <ModalRelatorio
      header="Documentos que expiram no período"
      visible={visible}
      onHide={onHide}
      onImprimir={imprimir}
      parametros={parametros}
      isValid={isValid}
    />
  );
}

const ModalFimVigenciaPeriodo = withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: false,

  mapPropsToValues(props) {
    return initialValue;
  },

  validate(values) {
    let errors = {};

    if (!values.dataInicial || values.dataInicial === '') {
      errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
    }

    if (values.dataFinal && !isValid(parseISO(values.dataFinal))) {
      errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
    }

    return errors;
  },

  validationSchema: Yup.object().shape({
    dataInicial: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
    dataFinal: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
  }),

  handleSubmit: () => {},
})(FimVigenciaPeriodo);

export default ModalFimVigenciaPeriodo;
