import { montarLabelPessoa } from '../../../../../../components/select/SingleSelectPessoa/Util';

export function converterLancamentoReceitaParaFormulario(values) {
	return {
		...values,
		categoria: converterCategoriaParaFormulario(values.categoria),
		formaPagamento: converterFormaParaFormulario(values.formaPagamento),
		conta: converterContaParaFormulario(values.conta),
		pessoa: converterPessoaParaFormulario(values.pessoa),
	};
}

function converterCategoriaParaFormulario(categoriaApi) {
	if (categoriaApi) {
		return {
			label: categoriaApi.nome,
			value: categoriaApi.id,
			registro: categoriaApi,
		};
	}
	return null;
}

function converterFormaParaFormulario(formaApi) {
	if (formaApi) {
		return {
			label: formaApi.descricao,
			value: formaApi.id,
			registro: formaApi,
		};
	}
	return null;
}

function converterContaParaFormulario(contaApi) {
	if (contaApi) {
		return {
			label: contaApi.nome,
			value: contaApi.id,
			registro: contaApi,
		};
	}
	return null;
}

function converterPessoaParaFormulario(pessoaApi) {
	if (pessoaApi) {
		return {
			label: montarLabelPessoa(pessoaApi),
			value: pessoaApi.id,
			registro: pessoaApi,
		};
	}
	return null;
}

export function converterLancamentoReceitaParaApi(values) {
	return {
		...values,
		categoria: values.categoria ? { id: values.categoria.value } : null,
		formaPagamento: values.formaPagamento ? { id: values.formaPagamento.value } : null,
		conta: values.conta ? { id: values.conta.value } : null,
		pessoa: values.pessoa ? { id: values.pessoa.value } : null,
	};
}
