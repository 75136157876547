import { Field, useFormikContext } from 'formik';

import {
	calcularImpostosProduto,
	copiarObjeto,
	formatarDecimais,
	formatarMonetarioDecimais,
	services,
	TiposPercentualValor,
} from 'Common';
import {
	Grid,
	InputDouble,
	InputField,
	InputMoney,
	InputSelectPercentualOrValor,
	SingleSelectCfop,
	SingleSelectOperacaoFiscal,
	SingleSelectProduto,
	SingleSelectUnidadeMedida,
} from 'components';
import { montarECalcularTributacaoProduto } from 'components/Produtos/Util/util';
import { aplicarPercentual } from 'views/Util';

function CamposPrincipais({
	decimaisQtd,
	decimaisPreco,
	disabled,
	informacoesPermissoes,
	isMobile,
	isTablet,
	consumidorFinal,
	tipoOperacao,
	montarProduto,
	setIsAtualizarTotal,
	pessoa,
	utilizaTabelaPreco,
	tabelaPreco,
	produtoForm,
	calcularValorVenda,
	calcularPercentualAdicionalTabelaPreco,
	utilizaTabelaPrecoDescontoQuantidade,
}) {
	const { values, setValues, setFieldValue } = useFormikContext();

	async function onChangeProduto(value) {
		if (value) {
			let produto = { ...values, produto: value };

			produto = await montarProduto(produto, value);

			setValues(produto);
		}
	}
	async function onChangeOperacaoFiscal(value) {
		const produto = await montarECalcularTributacaoProduto({ ...values, operacaoFiscal: value }, pessoa);

		setValues(produto);
	}

	function onChangeValores(field, value) {
		setFieldValue(field, value);
	}

	function onBlurDescontoAcrescimo(field, value, percentual, tipo = TiposPercentualValor.VALOR) {
		if (tipo === TiposPercentualValor.PERCENTUAL) {
			setFieldValue(field, aplicarPercentual(values.subtotal, percentual));
		} else {
			setFieldValue(field, value);
		}
		onBlurValores('descontoOuAcrescimo');
	}

	async function onBlurValores(field) {
		if (values[field] !== produtoForm[field]) {
			const produto = copiarObjeto(values);

			if (utilizaTabelaPreco) {
				if (field === 'quantidade' && utilizaTabelaPrecoDescontoQuantidade) {
					const adicionalTabelaPreco = await calcularPercentualAdicionalTabelaPreco(produto.quantidade);
					produto.adicionalTabelaPreco = {
						percentual: adicionalTabelaPreco.percentual,
						tipo: adicionalTabelaPreco.tipo,
					};

					produto.valor = parseFloat(
						calcularValorVenda(produto.valorOriginal, produto.adicionalTabelaPreco).toFixed(decimaisPreco)
					);

					produto.alterouValorManualmente = false;
					produto.alterouQuantidade = false;
					produto.valorAntesAlteracao = null;
				}
				if (
					field === 'valor' &&
					(tabelaPreco?.registro?.editarPrecoAbaixoValorTabela || tabelaPreco?.registro?.editarPrecoAcimaValorTabela)
				) {
					produto.alterouValorManualmente = true;
					produto.valorAntesAlteracao = produto.valor;
				}
			}

			produto.tributos = await calcularImpostosProduto(produto);

			setValues(produto);
			setIsAtualizarTotal(true);
		}
	}

	return (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="5"
				xl="5"
				name="produto"
				label="Produto"
				component={SingleSelectProduto}
				url={`${services.GESTOR}/v1/produtos`}
				onChange={(event) => onChangeProduto(event)}
				value={values?.produto}
				placeholder="Selecione o produto"
				disabled={disabled}
				filtroAdicionalUrl={`;tipo!="SERVICO"`}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				component={InputDouble}
				name="quantidade"
				obrigatorio
				label="Quantidade"
				placeholder={formatarDecimais(0, decimaisQtd)}
				onChange={(event) => onChangeValores('quantidade', event.target.value)}
				onBlur={() => onBlurValores('quantidade')}
				value={values?.quantidade}
				decimalScale={decimaisQtd}
				size={10}
				allowNegative={false}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Valor unitário"
				decimalScale={decimaisPreco}
				name="valor"
				obrigatorio
				touched
				placeholder={formatarMonetarioDecimais(0, decimaisPreco)}
				allowNegative={false}
				onChange={(event) => onChangeValores('valor', event.target.value)}
				onBlur={() => onBlurValores('valor')}
				value={values?.valor}
				size={13}
				disabled={
					disabled ||
					(utilizaTabelaPreco &&
						!tabelaPreco?.registro?.editarPrecoAbaixoValorTabela &&
						!tabelaPreco?.registro?.editarPrecoAcimaValorTabela)
				}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="3"
				xl="3"
				label="Unidade de medida"
				name="unidadeMedida"
				obrigatorio
				component={SingleSelectUnidadeMedida}
				isClearable={false}
				value={values?.unidadeMedida}
				onChange={(event) => setFieldValue('unidadeMedida', event)}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				label="CFOP"
				name="cfop"
				obrigatorio
				isClearable={false}
				component={SingleSelectCfop}
				operacao={values?.tipoOperacao}
				value={values?.cfop}
				onChange={(event) => setFieldValue('cfop', event)}
				onlyCodigo
				placeholder="Pesquisar"
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="2"
				md="2"
				lg="2"
				xl="2"
				component={InputSelectPercentualOrValor}
				label="Desconto"
				name="desconto"
				touched
				allowNegative={false}
				onBlur={(tipo, value, percentual) => onBlurDescontoAcrescimo('desconto', value, percentual, tipo)}
				value={values?.desconto}
				size={13}
				disabled={disabled}
				informacoesPermissoes={informacoesPermissoes}
			/>
			<Field
				sm="2"
				md="2"
				lg="2"
				xl="2"
				component={InputSelectPercentualOrValor}
				label="Acessórias"
				name="acessorias"
				touched
				allowNegative={false}
				onBlur={(tipo, value, percentual) => onBlurDescontoAcrescimo('acessorias', value, percentual, tipo)}
				value={values?.acessorias}
				size={13}
				disabled={disabled}
				informacoesPermissoes={informacoesPermissoes}
			/>
			<Field
				sm="2"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Frete"
				name="frete"
				touched
				allowNegative={false}
				onChange={(event) => onChangeValores('frete', event.target.value)}
				onBlur={() => onBlurValores('frete')}
				value={values?.frete}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="2"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Seguro"
				name="seguro"
				touched
				allowNegative={false}
				onChange={(event) => onChangeValores('seguro', event.target.value)}
				onBlur={() => onBlurValores('seguro')}
				value={values?.seguro}
				size={13}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="2"
				md="3"
				lg="2"
				xl="2"
				style={{ fontWeight: 'bold' }}
				component={InputMoney}
				label="Total do produto"
				name="subtotal"
				touched
				allowNegative={false}
				disabled
				value={values?.subtotal}
				size={13}
			/>
			<Field
				sm="12"
				md="5"
				lg="3"
				xl="3"
				name="operacaoFiscal"
				label="Operação fiscal"
				component={SingleSelectOperacaoFiscal}
				onChange={(event) => onChangeOperacaoFiscal(event)}
				obrigatorio
				tipoOperacao={tipoOperacao}
				tipoCliente={tipoOperacao === 'SAIDA' ? consumidorFinal : false}
				isClearable={false}
				isMobile={isMobile}
				isTablet={isTablet}
				value={values?.operacaoFiscal}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				component={InputField}
				label="Código de barras"
				type="number"
				size={13}
				onChange={(event) => {
					setFieldValue('codigoBarras', event.target.value);
				}}
				value={values?.codigoBarras}
				name="codigoBarras"
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				component={InputField}
				label="Cód. beneficiário"
				size={8}
				value={values?.codigoBeneficiario}
				onChange={(event) => setFieldValue('codigoBeneficiario', event.target.value)}
				name="codigoBeneficiario"
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				component={InputField}
				label="N° ped. de compra"
				size={15}
				value={values?.numeroPedidoCompra}
				onChange={(event) => setFieldValue('numeroPedidoCompra', event.target.value)}
				name="numeroPedidoCompra"
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={InputField}
				label="Item ped. de compra"
				size={6}
				value={values?.itemPedidoCompra}
				onChange={(event) => setFieldValue('itemPedidoCompra', event.target.value)}
				name="itemPedidoCompra"
				disabled={disabled}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { CamposPrincipais };
