import { useState, useEffect } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';

import {
	usuarioPossuiPermissao,
	recursos,
	permissoes,
	mensagensDeValidacao,
	services,
} from '../../../../../../../Common';
import {
	InputSelectMultiplicacaoOrDivisao,
	SingleSelectCfop,
	FormActions,
	FormContent,
	estadosBotaoCancelar,
	ButtonCancelar,
	ButtonSalvar,
	InputField,
	Grid,
	SingleSelectProduto,
} from '../../../../../../../components';

import { asyncGetProdutos, asyncCreateRelacao, asyncUpdateRelacao, asyncGetRelacao } from '../Requests';
import converterRelacaoParaApi from '../Util/relacaoConverter';
import { validarFormulario } from '../../../../../../Util';

const tipos = {
	MULTIPLICACAO: 'MULTIPLICACAO',
	DIVISAO: 'DIVISAO',
};

const initialValues = {
	id: '',
	fornecedor: '',
	produto: null,
	referencia: '',
	cfop: '',
	fatorConversao: '',
	operacaoFatorConversao: '',
};

function FormRelacao(props) {
	const [options, setOptions] = useState([]);

	const { values, resetForm, setFieldValue, handleSubmit, dirty, isEdit } = props;
	const { produto, cfop, fatorConversao, operacaoFatorConversao } = values;

	const informacoesPermissoes = {
		podeInserir: usuarioPossuiPermissao(recursos.PESSOAS, permissoes.INSERIR),
		podeEditar: usuarioPossuiPermissao(recursos.PESSOAS, permissoes.EDITAR),
		podeExcluir: usuarioPossuiPermissao(recursos.PESSOAS, permissoes.EXCLUIR),
	};

	useEffect(() => {
		pesquisarRelacao();
		pesquisarPedidos();
	}, []);

	async function pesquisarPedidos() {
		await asyncGetProdutos(({ data: produtos }) => {
			produtos.content?.map(produto => {
				setOptions(options => [
					...options,
					{ label: produto.codigo + ' - ' + produto.nome, value: produto.id },
				]);
			});
		});
	}

	async function pesquisarRelacao() {
		let newValues = initialValues;
		if (isEdit) {
			await asyncGetRelacao(isEdit, ({ data: relacao }) => {
				newValues.id = relacao.id;
				newValues.operacaoFatorConversao = relacao.operacaoFatorConversao;
				newValues.fatorConversao = relacao.fatorConversao;
				newValues.fornecedor = { id: props.valuesFornecedor.id };
				newValues.cfop = converterCfopParaFormulario(relacao.cfop);
				newValues.produto = converterProdutoParaFormulario(relacao.produto);
				newValues.referencia = relacao.referencia;
				setFieldValue({ ...values, newValues });
			});
		} else {
			newValues.id = '';
			newValues.fornecedor = { id: props.valuesFornecedor.id };
			newValues.operacaoFatorConversao = 'MULTIPLICACAO';
			newValues.fatorConversao = '';
			newValues.cfop = '';
			newValues.produto = '';
			newValues.referencia = '';
			setFieldValue({ ...values, newValues });
		}
	}

	function converterCfopParaFormulario(cfop) {
		if (cfop && cfop?.id) {
			return {
				label: cfop.codigo + ' - ' + cfop.descricao,
				value: cfop.id,
				registro: cfop,
			};
		}
	}

	function converterProdutoParaFormulario(produto) {
		if (produto && produto?.id) {
			return {
				label: produto.codigo + ' - ' + produto.nome,
				value: produto.id,
				registro: produto,
			};
		}
	}

	function alterarFatorOperacao(operacao) {
		if (operacao === tipos.MULTIPLICACAO) {
			setFieldValue('operacaoFatorConversao', tipos.DIVISAO);
		} else {
			setFieldValue('operacaoFatorConversao', tipos.MULTIPLICACAO);
		}
	}

	function cancelar() {
		if (dirty) {
			resetForm(values);
		} else {
			resetForm(values);
			props.onHide();
		}
	}

	async function salvar() {
		handleSubmit();
		if (!isEdit) {
			if (await validarFormulario(props)) {
				await asyncCreateRelacao(converterRelacaoParaApi(values), () => {
					resetForm({ values: values });
					props.onHide();
				});
			}
		} else {
			if (await validarFormulario(props)) {
				await asyncUpdateRelacao(values.id, converterRelacaoParaApi(values), () => {
					resetForm({ values: values });
					props.onHide();
				});
			}
		}
	}
	return (
		<>
			<FormActions>
				<ButtonCancelar
					{...informacoesPermissoes}
					estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
					onClick={() => cancelar()}
				/>
				<ButtonSalvar {...informacoesPermissoes} disabled={!dirty} onClick={() => salvar()} />
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={SingleSelectProduto}
						label="Produto"
						obrigatorio
						name="produto"
						value={produto}
						url={`${services.GESTOR}/v1/produtos`}
						placeholder={'Selecione o produto'}
						onChange={event => setFieldValue('produto', event)}
						showClear={false}
						menuPortalTarget={document.body}
						{...informacoesPermissoes}
					/>
				</Grid>
				<Grid>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputField}
						label="Cód. produto fornecedor"
						name="referencia"
						obrigatorio
						size={60}
						{...informacoesPermissoes}
					/>

					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={SingleSelectCfop}
						label="CFOP"
						name="cfop"
						obrigatorio
						value={cfop}
						onChange={event => setFieldValue('cfop', event)}
						{...informacoesPermissoes}
					/>

					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Fator de conversão"
						name="fatorConversao"
						component={InputSelectMultiplicacaoOrDivisao}
						valueOperacao={operacaoFatorConversao}
						onChangeOperacao={() => alterarFatorOperacao(operacaoFatorConversao)}
						value={fatorConversao}
						onChange={event => setFieldValue('fatorConversao', event)}
						{...informacoesPermissoes}
					/>
				</Grid>
			</FormContent>
		</>
	);
}

FormRelacao = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValues;
	},

	validate(values) {
		let errors = {};

		if (values.cfop === '' || !values.cfop) {
			errors.cfop = 'Campo obrigatório';
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		produto: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		referencia: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(FormRelacao);

export default FormRelacao;
