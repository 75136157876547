import { Card as InternalCard } from "primereact/card";
import propTypes from "prop-types";

function Card(props) {
  const {
    id,
    hidden,
    style,
    className,
    title,
    subTitle,
    children,
    header,
    footer,
    onClick,
  } = props;

  if (!hidden) {
    return (
      <div id={id} onClick={onClick} style={style} className={className}>
        <InternalCard
          header={header}
          footer={footer}
          title={title}
          subTitle={subTitle}
          style={style}
          {...props}
        >
          {children}
        </InternalCard>
      </div>
    );
  }
  return null;
}

Card.defaultProps = {
  title: "",
  subTitle: "",
};

Card.propTypes = {
  /** Identificador do componente */
  id: propTypes.any,
  /** Título do componente */
  title: propTypes.any,
  /** Subtítulo do componente */
  subTitle: propTypes.string,
  /** estilo personalizado, se tiver */
  style: propTypes.object,
  /** Esconde o componente */
  hidden: propTypes.bool,
  /** Nome da classe do componente */
  className: propTypes.string,
  /** Componentes filhos */
  children: propTypes.any,
  /** Conteúdo do cabeçalho do card */
  header: propTypes.any,
  /** Conteúdo do rodapé do card */
  footer: propTypes.any,
  /** Ação do clique em um card */
  onClick: propTypes.func,
};

export default Card;
