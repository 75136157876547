import { buscarDadosLoginLocalStorage } from '../../Autenticacao';
import axios from 'axios';
import { services } from '../../Constantes/api';

//Interceptor que pega o token para usar nas requisições
axios.interceptors.request.use(
	config => {
		if (config) {
			if (config.url.includes(services.GESTOR)) {
				config.headers.Authorization = 'Bearer ' + buscarDadosLoginLocalStorage().access_token;
			}
		}
		return config;
	},
	error => Promise.reject(error)
);
