import { Field } from 'formik';
import { formatarDecimais } from '../../../../../../Common/Mascara';
import { InputMoney } from '../../../../../../components';
import InputDouble from '../../../../../../components/input/InputDouble';
import Dropdown from '../../../../../../components/select/Dropdown';
import { optionsModalidadeDeterminacaoST } from '../../../Util/constantes';

function FieldsModalIcmsSt(props) {
	const { validacaoNovaTributacao, setFieldValue, values } = props;
		
	return (
		<>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={Dropdown}
				label="Modalidade Cálculo ST"
				name="modalidadeCalculoSt"
				values={values.modalidadeCalculoSt}
				options={optionsModalidadeDeterminacaoST}
				onChange={event => setFieldValue('modalidadeCalculoSt', event.value)}
				disabled={validacaoNovaTributacao}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				label="Base ICMS ST"
				name="icmsStBase"
				value={values.icmsStBase}
				onChange={event => setFieldValue('icmsStBase', event.target.value)}
				suffix="%"
				allowNegative={false}
				MaxValue={100}
				disabled={validacaoNovaTributacao}
			/>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				label="% ICMS ST"
				name="icmsStPercentual"
				value={values.icmsStPercentual}
				onChange={event => setFieldValue('icmsStPercentual', event.target.value)}
				suffix="%"
				allowNegative={false}
				MaxValue={100}
				disabled={validacaoNovaTributacao}
			/>
			
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={(values.modalidadeCalculoSt === 'MARGEM_VALOR_AGREGADO') ? InputDouble: InputMoney}
				label="% MVA/Pauta"
				name="mvaPauta"
				value={values.mvaPauta}
				onChange={event => setFieldValue('mvaPauta', event.target.value)}
				suffix={(values.modalidadeCalculoSt === 'MARGEM_VALOR_AGREGADO') ? '%' : ''}
				allowNegative={false}
				disabled={validacaoNovaTributacao}
			/>
		</>
	);
}

export default FieldsModalIcmsSt;
