import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	If,
	NormalButton,
	ToastTypes,
	alert,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	estadosBotaoSalvar,
	notify,
} from 'components';
import { useFormikContext } from 'formik';
import { validarFormulario, voltarParaAPesquisa } from 'views/Util';
import {
	asyncAdicionarArquivoDocumento,
	asyncAtualizarDocumento,
	asyncCriarDocumento,
	asyncExcluirDocumento,
} from 'views/documentos/AssinaturaEletronica/Requests';
import { statusDocumento } from 'views/documentos/AssinaturaEletronica/Util/constantes';
import { buscarMensagemExcluirDocumento } from 'views/documentos/AssinaturaEletronica/Util/funcoesList';
import { converterDocumentoParaApi } from '../../Util/documentoConverter';
import { exibirConfirmacaoMesmaIdentificacao, jaExisteDocumentoMesmaIdentificacao } from '../../Util/functions';

const PESQUISA_URL = '/documentos/assinatura';

function ActionButtons(props) {
	const {
		informacoesPermissoes,
		isModal,
		hideModal,
		history,
		handleSubmit,
		novo,
		asyncSelectRegistro,
		itensOpcoes,
		menuOpcoes,
		setTabSelecionada,
		setModalEnviarDocumentoVisible,
		setModalReenviarEmail,
		setSignatarioEspecifico,
	} = props;
	const { values, dirty, initialValues, resetForm, setFieldValue, errors } = useFormikContext();

	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	const onClickNovo = dirty ? (element) => validareSalvar(element, novo) : novo;

	async function validareSalvar(e, novoOnSuccess) {
		await handleSubmit();

		if (await validarFormulario(props)) {
			if (
				values.identificacao &&
				values.identificacao !== initialValues.identificacao &&
				(await jaExisteDocumentoMesmaIdentificacao(values.id, values.identificacao))
			) {
				exibirConfirmacaoMesmaIdentificacao(() => {
					salvarOuAtualizar(values, novoOnSuccess);
				});
			} else {
				salvarOuAtualizar(values, novoOnSuccess);
			}
		} else {
			setTabSelecionada(errors.tab);
		}
	}

	function salvarOuAtualizar(values, novoOnSuccess) {
		let dadosNovoArquivo;
		if (
			values.arquivo &&
			(values.status === statusDocumento.PENDENTE || values.status === statusDocumento.ARQUIVO_ADICIONADO)
		) {
			if (!initialValues.arquivo || values.arquivoInseridoComFalha || initialValues.arquivo !== values.arquivo) {
				dadosNovoArquivo = values.arquivo;
			}
		}

		if (values.id) {
			if (dadosNovoArquivo) {
				asyncAdicionarArquivoDocumento(values.id, dadosNovoArquivo, () => {
					atualizarRegistro(() => {
						resetForm({
							values: {
								...values,
								status: statusDocumento.ARQUIVO_ADICIONADO,
								arquivo: dadosNovoArquivo,
							},
						});
					}, novoOnSuccess);
				});
			} else {
				atualizarRegistro(undefined, novoOnSuccess);
			}
		} else {
			criarRegistro(dadosNovoArquivo, novoOnSuccess);
		}
		setSignatarioEspecifico(null);
	}

	function atualizarRegistro(onSuccess, novoOnSuccess) {
		const dadosForm = values;
		const data = converterDocumentoParaApi(values);
		asyncAtualizarDocumento(data, () => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else if (data.status === statusDocumento.EXPIRADO) {
				asyncSelectRegistro(dadosForm.id, onSuccess);
			} else {
				resetForm({ values: dadosForm });
				onSuccess && onSuccess(dadosForm);
			}
		});
	}

	function criarRegistro(dadosNovoArquivo, novoOnSuccess) {
		const data = converterDocumentoParaApi(values);

		asyncCriarDocumento(data, async (document) => {
			if (dadosNovoArquivo) {
				adicionarArquivoAoDocumentoCriado(document.data.id, dadosNovoArquivo, novoOnSuccess);
			} else if (novoOnSuccess) {
				novoOnSuccess();
			} else if (hideModal) {
				hideModal({ ...values, id: document.data.id });
			} else {
				resetForm({
					values: {
						...values,
						status: statusDocumento.PENDENTE,
						arquivo: null,
					},
				});
				await asyncSelectRegistro(document.data.id, null, true);
			}
		});
	}

	function cancelar() {
		if (dirty) {
			if (values.arquivoInseridoComFalha) {
				resetForm({
					values: { ...initialValues, arquivo: null },
				});
			} else {
				resetForm({ values: initialValues });
			}
			setSignatarioEspecifico(null);
		} else if (isModal) {
			hideModal({ ...values });
		} else {
			voltarParaAPesquisa(history, PESQUISA_URL);
		}
	}

	function adicionarArquivoAoDocumentoCriado(idDocumento, dadosNovoArquivo, novoOnSuccess) {
		asyncAdicionarArquivoDocumento(
			idDocumento,
			dadosNovoArquivo,
			async () => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					resetForm({
						values: {
							...values,
							status: statusDocumento.ARQUIVO_ADICIONADO,
							arquivo: dadosNovoArquivo,
						},
					});
					await asyncSelectRegistro(idDocumento, null, true);
				}
			},
			() => {
				setFieldValue('arquivoInseridoComFalha', true);
			}
		);
	}

	function excluir() {
		confirm('Atenção', buscarMensagemExcluirDocumento(values), () => {
			asyncExcluirDocumento(values.id, () => {
				notify('Documento excluído com sucesso', ToastTypes.SUCCESS);
				resetForm();
				setSignatarioEspecifico(null);
				history.goBack();
			});
		});
	}

	function enviarParaAssinatura() {
		if (!values.signatarios || values.signatarios.length === 0) {
			alert('Atenção', 'Para enviar o documento para assinatura é necessário informar ao menos um signatário');
		} else {
			setModalEnviarDocumentoVisible(true);
			setModalReenviarEmail(false);
			setSignatarioEspecifico(null);
		}
	}

	return (
		<>
			<ButtonCancelar
				{...informacoesPermissoes}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
			/>
			<ButtonSalvar
				{...informacoesPermissoes}
				estadoBotao={estadosBotaoSalvar.SALVAR}
				disabled={!dirty}
				onClick={(e) => validareSalvar(e)}
			/>
			<ButtonNovo
				onClick={onClickNovo}
				hidden={(!dirty && !values.id) || isModal}
				estadoBotao={estadoBotaoNovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir
				hidden={!values.id || isModal}
				disabled={values.status === statusDocumento.ASSINADO}
				{...informacoesPermissoes}
				onClick={excluir}
				title={
					values.status === statusDocumento.ASSINADO
						? 'Um documento assinado, não pode ser excluido'
						: 'Excluir documento'
				}
			/>
			<If test={!isModal}>
				<NormalButton
					className="p-button-primary"
					type="button"
					label="Opções"
					icon="fa fa-list"
					style={{ margin: '5px' }}
					hidden={itensOpcoes.length === 0}
					onClick={(event) => menuOpcoes.current.toggle(event)}
				/>
			</If>
			<NormalButton
				className="p-button-success"
				disabled={dirty}
				type="button"
				label="Enviar para assinatura"
				icon="fa fa-send"
				style={{ margin: '5px' }}
				hidden={values.status !== statusDocumento.ARQUIVO_ADICIONADO}
				onClick={enviarParaAssinatura}
				title={
					dirty
						? 'Salve os dados do documento para enviá-lo para assinatura'
						: 'Enviar o documento para assinatura digital'
				}
			/>
		</>
	);
}

export { ActionButtons };
