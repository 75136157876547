import { Checkbox, Grid, Subtitle } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { helpParametrizacao } from '../../Help';

function TabEstoque() {
	const { podeEditarEmpresa } = useContextConfiguracoesGerais();
	const { values, setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Subtitle subtitle="Estoque" />
			<Field
				helpMessage={helpParametrizacao.gerarEstoqueNaNfe}
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Gerar estoque na NF-e"
				name="gerarEstoqueNaNfe"
				value={values.parametrizacao.estoque?.gerarEstoqueNaNfe}
				onChange={(e) => setFieldValue('parametrizacao.estoque.gerarEstoqueNaNfe', e.checked)}
				checked={values.parametrizacao.estoque?.gerarEstoqueNaNfe}
				disabled={!podeEditarEmpresa}
			/>
			<Field
				helpMessage={helpParametrizacao.permitirEstoqueNegativo}
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Permitir estoque negativo"
				name="permitirEstoqueNegativo"
				value={values.parametrizacao.estoque?.gerarEstoqueNaNfe}
				onChange={(e) => setFieldValue('parametrizacao.estoque.permitirEstoqueNegativo', e.checked)}
				checked={values.parametrizacao.estoque?.permitirEstoqueNegativo}
				disabled={!podeEditarEmpresa}
			/>
		</Grid>
	);
}

export { TabEstoque };
