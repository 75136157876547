import {
	buscarDadosLoginLocalStorage,
	calcularImpostosProduto,
	calcularImpostosServico,
	copiarObjeto,
	gerarUUID,
	montarCalcularImpostosProduto,
	montarCalcularImpostosServico,
} from 'Common';
import { tipos as TIPOS_PERCENTUAL_VALOR } from 'components';
import { aplicarPercentual } from 'views/Util';
import { CAMPOS_TOTAIS, CONDICOES_PAGAMENTO, DEFAULT_TOTAIS, OPTIONS_FORMA_PAGAMENTO } from './constantes';
import { converterValueParaSelect } from './ordemServicoConverterFormulario';
import { addMonths, formatISO, isValid, parseISO } from 'date-fns';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/CondicaoPagamento/Util/constantes';

function calcularDescontoServicos(values, desconto) {
	let result = {
		servicos: [],
		totalDesconto: 0,
	};

	const servicosTemp = copiarObjeto(values.servicos);
	const descontoMaximoSuperior = values.informacoesComplementares?.vendedor?.registro?.descontoMaximoSuperior;

	if (descontoMaximoSuperior) {
		servicosTemp.forEach((servico, index) => {
			if (descontoMaximoSuperior > servico.descontoMaximoPercentual) {
				servicosTemp[index].descontoMaximoVendedor = descontoMaximoSuperior;
				servicosTemp[index].descontoMaximoPercentual = descontoMaximoSuperior;
			}
		});
	}

	if (desconto.tipo === TIPOS_PERCENTUAL_VALOR.PERCENTUAL) {
		let servicos = calcularImpostosServicos(
			rateioValorEntreServicos(
				servicosTemp,
				values.totais.totalServicos?.valorLiquido,
				CAMPOS_TOTAIS.DESCONTO,
				aplicarPercentual(values.totais.totalServicos?.valorLiquido, desconto.total)
			)
		);

		result.servicos = servicos;
		result.totalDesconto = aplicarPercentual(values.totais.totalServicos?.valorLiquido, desconto.total);
	} else {
		let servicos = calcularImpostosServicos(
			rateioValorEntreServicos(
				servicosTemp,
				values.totais.totalServicos?.valorLiquido,
				CAMPOS_TOTAIS.DESCONTO,
				desconto.total
			)
		);

		result.servicos = servicos;
		result.totalDesconto = desconto.total;
	}

	return result;
}

function calcularImpostosServicos(itens) {
	let servicos = [];
	if (hasServico(itens)) {
		itens.forEach((item) => {
			if (Object.keys(Object(item.servico)).length) {
				let servico = calcularImpostosServico(converterServicoImpostoParaCalculo(item));
				servico.subtotal = parseFloat(
					((servico?.quantidade ?? 0) * (servico?.valor ?? 0) - (servico?.desconto ?? 0)).toFixed(2)
				);
				servicos.push({ ...item, ...servico });
			}
		});
	}

	return servicos.length > 0 ? servicos : itens;
}

function rateioValorEntreServicos(itens, totalServicos, campo, valor) {
	let somaRateado = 0;

	itens?.map((item) => {
		item[campo] = parseFloat(((item?.quantidade * item?.valor * valor) / totalServicos).toFixed(2));
		somaRateado += item[campo];
	});

	if (valor - somaRateado !== 0) {
		if (itens.length > 0) {
			itens[0][campo] = parseFloat((itens[0][campo] - parseFloat((somaRateado - valor).toFixed(2))).toFixed(2));
		}
	}

	return itens;
}

function converterServicoImpostoParaCalculo(item) {
	return {
		servico: item.servico,
		operacaoFiscal: item.operacaoFiscal,
		quantidade: item.quantidade,
		valor: item.valor,
		desconto: item.desconto,
		baseCalculo: 0,
		subtotal: 0,
		tributos: {
			iss: {
				valorBase: item.tributos?.iss?.valorBase,
				percentualBase: item.tributos?.iss?.percentualBase,
				percentual: item.tributos?.iss?.percentual,
				valor: item.tributos?.iss?.valor,
			},
			ir: {
				valorBase: item.tributos?.ir?.valorBase,
				percentualBase: item.tributos?.ir?.percentualBase,
				percentual: item.tributos?.ir?.percentual,
				valor: item.tributos?.ir?.valor,
			},
			csll: {
				valorBase: item.tributos?.csll?.valorBase,
				percentualBase: item.tributos?.csll?.percentualBase,
				percentual: item.tributos?.csll?.percentual,
				valor: item.tributos?.csll?.valor,
			},
			inss: {
				valorBase: item.tributos?.inss?.valorBase,
				percentualBase: item.tributos?.inss?.percentualBase,
				percentual: item.tributos?.inss?.percentual,
				valor: item.tributos?.inss?.valor,
			},
			pis: {
				valorBase: item.tributos?.pis?.valorBase,
				percentualBase: item.tributos?.pis?.percentualBase,
				percentual: item.tributos?.pis?.percentual,
				valor: item.tributos?.pis?.valor,
			},
			cofins: {
				valorBase: item.tributos?.cofins?.valorBase,
				percentualBase: item.tributos?.cofins?.percentualBase,
				percentual: item.tributos?.cofins?.percentual,
				valor: item.tributos?.cofins?.valor,
			},
		},
	};
}

async function calcularFreteProdutos(values, frete) {
	let result = {
		produtos: [],
		totalFrete: 0,
	};

	let produtosCalculados = await calcularImpostosProdutos(
		await rateioValorEntreProdutos(
			values.produtos,
			values.totais.totalProdutos?.valorLiquido,
			CAMPOS_TOTAIS.FRETE,
			frete
		)
	);

	result.produtos = produtosCalculados;
	result.totalFrete = frete;
	return result;
}

async function calcularSeguroProdutos(values, seguro) {
	let result = {
		produtos: [],
		totalSeguro: 0,
	};

	let produtosCalculados = await calcularImpostosProdutos(
		await rateioValorEntreProdutos(
			values.produtos,
			values.totais.totalProdutos?.valorLiquido,
			CAMPOS_TOTAIS.SEGURO,
			seguro
		)
	);

	result.produtos = produtosCalculados;
	result.totalSeguro = seguro;
	return result;
}

async function calcularDescontoProdutos(values, desconto) {
	let result = {
		produtos: [],
		totalDesconto: 0,
	};

	const produtosTemp = copiarObjeto(values.produtos);
	const descontoMaximoSuperior = values.informacoesComplementares?.vendedor?.registro?.descontoMaximoSuperior;

	if (descontoMaximoSuperior) {
		produtosTemp.forEach((produto, index) => {
			if (descontoMaximoSuperior > produto.descontoMaximoPercentual) {
				produtosTemp[index].descontoMaximoVendedor = descontoMaximoSuperior;
				produtosTemp[index].descontoMaximoPercentual = descontoMaximoSuperior;
			}
		});
	}

	if (desconto.tipo === TIPOS_PERCENTUAL_VALOR.PERCENTUAL) {
		let produtos = await calcularImpostosProdutos(
			await rateioValorEntreProdutos(
				produtosTemp,
				values.totais.totalProdutos?.valorLiquido,
				CAMPOS_TOTAIS.DESCONTO,
				aplicarPercentual(values.totais.totalProdutos?.valorBruto, desconto.total)
			)
		);

		result.produtos = produtos;
		result.totalDesconto = aplicarPercentual(values.totais.totalProdutos?.valorBruto, desconto.total);
	} else {
		let produtos = await calcularImpostosProdutos(
			await rateioValorEntreProdutos(
				produtosTemp,
				values.totais.totalProdutos?.valorLiquido,
				CAMPOS_TOTAIS.DESCONTO,
				desconto.total
			)
		);

		result.produtos = produtos;
		result.totalDesconto = desconto.total;
	}
	return result;
}

async function calcularAcessoriasProdutos(values, acessorias) {
	let result = {
		produtos: [],
		totalAcessorias: 0,
	};

	if (acessorias.tipo === TIPOS_PERCENTUAL_VALOR.PERCENTUAL) {
		let produtos = await calcularImpostosProdutos(
			await rateioValorEntreProdutos(
				values.produtos,
				values.totais.totalProdutos?.valorLiquido,
				CAMPOS_TOTAIS.ACESSORIAS,
				aplicarPercentual(values.totais.totalProdutos?.valorLiquido, acessorias.total)
			)
		);

		result.produtos = produtos;
		result.totalAcessorias = aplicarPercentual(values.totais.totalProdutos?.valorLiquido, acessorias.total);
	} else {
		let produtos = await calcularImpostosProdutos(
			await rateioValorEntreProdutos(
				values.produtos,
				values.totais.totalProdutos?.valorLiquido,
				CAMPOS_TOTAIS.ACESSORIAS,
				acessorias.total
			)
		);

		result.produtos = produtos;
		result.totalAcessorias = acessorias.total;
	}

	return result;
}

async function calcularImpostosProdutos(itens) {
	let produtos = [];
	if (hasProduto(itens)) {
		await itens.forEach(async (item) => {
			if (Object.keys(Object(item.produto)).length) {
				let produtoTributos = calcularImpostosProduto(await converterProdutoImpostoParaCalculo(item));
				item.subtotal = parseFloat(
					(
						(item?.quantidade ?? 0) * (item?.valor ?? 0) +
						(item?.seguro ?? 0) +
						(item?.frete ?? 0) +
						(item?.acessorias ?? 0) +
						(item?.tributos?.icms.valorSt ? item?.tributos?.icms.valorSt : 0) +
						(item?.tributos?.ipi?.valor ? item?.tributos?.ipi?.valor : 0) -
						(item?.desconto ?? 0)
					).toFixed(2)
				);

				produtos.push({
					...item,
					tributos: {
						...converterTributosParaFormulario(produtoTributos, item),
					},
				});
			}
		});
	}

	return produtos.length > 0 ? produtos : itens;
}

async function rateioValorEntreProdutos(itens, totalProdutos, campo, valor) {
	let somaRateado = 0;

	await itens?.map((item) => {
		item[campo] = parseFloat(((item?.quantidade * item?.valor * valor) / totalProdutos).toFixed(2));
		somaRateado += item[campo];
	});

	if (valor - somaRateado !== 0) {
		if (itens.length > 0) {
			itens[0][campo] = parseFloat((itens[0][campo] - parseFloat((somaRateado - valor).toFixed(2))).toFixed(2));
		}
	}

	return itens;
}

function hasProduto(produtos) {
	if (produtos?.length > 0) {
		return produtos?.filter((item) => Object.keys(Object(item.produto)).length).length > 0;
	}
	return false;
}

function hasServico(servicos) {
	if (servicos?.length > 0) {
		return servicos?.filter((item) => Object.keys(Object(item.servico)).length).length > 0;
	}
	return false;
}

function validarValorNegativo(valor) {
	return valor < 0 ? 0.0 : valor;
}

function recalcularParcelas(quantidadeParcelas, parcelas, valorTotal) {
	let parcelasTemp = copiarObjeto(parcelas);
	const rateio = rateioParcelas(quantidadeParcelas, valorTotal);

	parcelasTemp = parcelasTemp.map((parcela, index) => {
		if (index === parcelasTemp.length - 1) {
			return { ...parcela, valor: rateio.valorUltimaParcela };
		} else {
			return { ...parcela, valor: rateio.valorPorParcela };
		}
	});
	return parcelasTemp;
}

function rateioParcelas(quantidadeParcelas, valorTotal) {
	const valorPorParcela = parseFloat((valorTotal / quantidadeParcelas).toFixed(2));
	const valorUltimaParcela = parseFloat((valorTotal - valorPorParcela * (quantidadeParcelas - 1)).toFixed(2));

	return {
		valorPorParcela: valorPorParcela,
		valorUltimaParcela: valorUltimaParcela,
	};
}

function recalcularRateioParcela(indexParcela, parcelas, valorTotal) {
	let parcelasTemp = copiarObjeto(parcelas);

	const valorInformado = parcelasTemp[indexParcela].valor;

	const valorParcelasAnteriores = valorSomadoDasParcelasAnteriores(parcelasTemp, indexParcela);
	const valorASerRatiado = valorTotal - (valorParcelasAnteriores + valorInformado);
	const quantidadeParcelasRestantes = parcelasTemp.length - 1 - indexParcela;
	const valorRatiadoParaCadaParcelaRestante = parseFloat((valorASerRatiado / quantidadeParcelasRestantes).toFixed(2));

	let somaDasParcelas = 0;

	parcelasTemp = parcelasTemp.map((parcela, index) => {
		let valor = parcela.valor;

		if (index > indexParcela) {
			somaDasParcelas += valorRatiadoParaCadaParcelaRestante;
			if (index === parcelasTemp.length - 1) {
				valor = validarValorNegativo(
					parseFloat((valorRatiadoParaCadaParcelaRestante + (valorTotal - somaDasParcelas)).toFixed(2))
				);
			} else {
				valor = validarValorNegativo(valorRatiadoParaCadaParcelaRestante);
			}
		} else {
			somaDasParcelas += parcela.valor;
		}
		parcela.valorAReceber = valor;
		parcela.valor = valor;
		return parcela;
	});
	return parcelasTemp;
}

function valorSomadoDasParcelasAnteriores(parcelas, index) {
	let valorParcelasAnteriores = 0.0;
	for (let i = 0; i < parcelas.length; i++) {
		if (i < index) {
			valorParcelasAnteriores += parcelas[i].valor;
		}
	}
	return valorParcelasAnteriores;
}

async function montarCalcularImpostosServicoOS(municipioId, values) {
	const servicoCalculado = await montarCalcularImpostosServico(municipioId, converterServicoImpostoParaCalculo(values));
	return converterServicoImpostoParaFormulario(servicoCalculado, values);
}

function converterServicoImpostoParaFormulario(newValues, oldValues) {
	return {
		...oldValues,
		tributos: {
			iss: { ...oldValues?.iss, ...newValues.iss },
			ir: { ...oldValues?.ir, ...newValues.ir },
			csll: { ...oldValues?.csll, ...newValues.csll },
			inss: { ...oldValues?.inss, ...newValues.inss },
			pis: { ...oldValues?.pis, ...newValues.pis },
			cofins: { ...oldValues?.cofins, ...newValues.cofins },
		},
	};
}

async function calcularImpostosServicoOS(values) {
	const servicoCalculado = await calcularImpostosServico(converterServicoImpostoParaCalculo(values));

	return converterServicoImpostoParaFormulario(servicoCalculado, values);
}

async function montarCalcularImpostosProdutoOS(ufId, values) {
	const produtoCalculado = await montarCalcularImpostosProduto(ufId, await converterProdutoImpostoParaCalculo(values));

	let produtoConvertido = await converterProdutoImpostoParaFormulario(produtoCalculado, values);

	produtoConvertido.subtotal = parseFloat(
		(
			(produtoConvertido?.valor ?? 0) * (produtoConvertido?.quantidade ?? 0) +
			(produtoConvertido?.seguro ?? 0) +
			(produtoConvertido?.frete ?? 0) +
			(produtoConvertido?.acessorias ?? 0) +
			(produtoConvertido?.tributos?.icms?.valorSt ? produtoConvertido?.tributos?.icms?.valorSt : 0) +
			(produtoConvertido?.tributos?.ipi?.valor ? produtoConvertido?.tributos?.ipi?.valor : 0) -
			(produtoConvertido?.desconto ?? 0)
		).toFixed(2)
	);

	return produtoConvertido;
}

async function converterProdutoImpostoParaCalculo(item) {
	return {
		produto: item.produto,
		cfop: item.cfop,
		operacaoFiscal: item.operacaoFiscal,
		baseCalculo: item.baseCalculo,
		quantidade: item.quantidade,
		valor: item.valor,
		seguro: item.seguro,
		frete: item.frete,
		acessorias: item.acessorias,
		desconto: item.desconto,
		tributos: {
			icms: {
				origemProduto: item.tributos.icms.origemProduto,
				cstCsosn: item.tributos.icms.cstCsosn,
				modalidadeCalculo: item.tributos.icms.modalidadeCalculo,
				percentualBaseIcms: item.tributos.icms.percentualBaseIcms,
				percentualIcms: item.tributos.icms.percentualIcms,
				percentualCreditoSimplesNacional: item.tributos.icms.percentualCreditoSimplesNacional,
				percentualReducaoBaseIcms: item.tributos.icms.percentualReducaoBaseIcms,
				percentualDiferimento: item.tributos.icms.percentualDiferimento,
				percentualFundoCombatePobreza: item.tributos.icms.percentualFundoCombatePobreza,
				modalidadeCalculoSt: item.tributos.icms.modalidadeCalculoSt,
				percentualReducaoBaseCalculoSt: item.tributos.icms.percentualReducaoBaseCalculoSt,
				percentualBaseIcmsSt: item.tributos.icms.percentualBaseIcmsSt,
				percentualIcmsSt: item.tributos.icms.percentualIcmsSt,
				percentualMva: item.tributos.icms.percentualMva,
				valorPauta: item.tributos.icms.valorPauta,
				percentualBaseIcmsStRetido: item.tributos.icms.percentualBaseIcmsStRetido,
				percentualIcmsStRetido: item.tributos.icms.percentualIcmsStRetido,
				quantidadeBaseIcmsMonofasicoRetido: item.tributos.icms.quantidadeBaseIcmsMonofasicoRetido,
				percentualIcmsMonofasicoRetido: item.tributos.icms.percentualIcmsMonofasicoRetido,
				valorIcmsMonofasicoRetido: item.tributos.icms.valorIcmsMonofasicoRetido,
			},
			ipi: {
				cst: item.tributos.ipi.cst,
				enquadramentoIpi: item.tributos.ipi.enquadramentoIpi,
				percentualBaseIpi: item.tributos.ipi.percentualBaseIpi,
				percentualIpi: item.tributos.ipi.percentualIpi,
			},
			pis: {
				cst: item.tributos.pis.cst,
				percentualBasePis: item.tributos.pis.percentualBasePis,
				percentualPis: item.tributos.pis.percentualPis,
				percentualBasePisSt: item.tributos.pis.percentualBasePisSt,
				percentualPisSt: item.tributos.pis.percentualPisSt,
			},
			cofins: {
				cst: item.tributos.cofins.cst,
				percentualBaseCofins: item.tributos.cofins.percentualBase,
				percentualCofins: item.tributos.cofins.percentual,
				percentualBaseCofinsSt: item.tributos.cofins.percentualBaseSt,
				percentualCofinsSt: item.tributos.cofins.percentualSt,
			},
		},
	};
}

async function converterProdutoImpostoParaFormulario(newValues, oldValues) {
	return {
		...oldValues,
		cfop: newValues.cfop ?? oldValues.cfop,
		codigoBeneficiario: newValues.codigoBeneficiario ?? oldValues.codigoBeneficiario,
		baseCalculo: newValues.baseCalculo,
		tributos: {
			...converterTributosParaFormulario(newValues.tributos ?? newValues, oldValues),
		},
	};
}

function converterTributosParaFormulario(tributos, oldValues) {
	function converterIcms(newValue) {
		return {
			...oldValues.icms,
			origemProduto: newValue.origemProduto,
			cstCsosn: newValue.cstCsosn,
			modalidadeCalculo: newValue.modalidadeCalculo,
			percentualBaseIcms: newValue.percentualBaseIcms,
			baseIcms: newValue.baseIcms,
			percentualIcms: newValue.percentualIcms,
			valorIcms: newValue.valorIcms,
			percentualCreditoSimplesNacional: newValue.percentualCreditoSimplesNacional,
			percentualReducaoBaseIcms: newValue.percentualReducaoBaseIcms,
			percentualDiferimento: newValue.percentualDiferimento,
			percentualFundoCombatePobreza: newValue.percentualFundoCombatePobreza,
			modalidadeCalculoSt: newValue.modalidadeCalculoSt,
			percentualReducaoBaseCalculoSt: newValue.percentualReducaoBaseCalculoSt,
			percentualBaseIcmsSt: newValue.percentualBaseIcmsSt,
			baseIcmsSt: newValue.baseIcmsSt,
			percentualIcmsSt: newValue.percentualIcmsSt,
			valorIcmsSt: newValue.valorIcmsSt,
			percentualMva: newValue.percentualMva,
			valorPauta: newValue.valorPauta,
			percentualBaseIcmsStRetido: newValue.percentualBaseIcmsStRetido,
			baseRetidoSt: newValue.baseRetidoSt,
			percentualIcmsStRetido: newValue.percentualIcmsStRetido,
			valorRetidoSt: newValue.valorRetidoSt,
			quantidadeBaseIcmsMonofasicoRetido: newValue.quantidadeBaseIcmsMonofasicoRetido,
			percentualIcmsMonofasicoRetido: newValue.percentualIcmsMonofasicoRetido,
			valorIcmsMonofasicoRetido: newValue.valorIcmsMonofasicoRetido,
		};
	}

	function converterIpi(newValue) {
		return {
			...oldValues.ipi,
			cst: newValue.cst,
			enquadramentoIpi: newValue.enquadramentoIpi,
			percentualBaseIpi: newValue.percentualBaseIpi,
			baseIpi: newValue.baseIpi,
			percentualIpi: newValue.percentualIpi,
			valorIpi: newValue.valorIpi,
		};
	}

	function converterPis(newValue) {
		return {
			...oldValues.pis,
			cst: newValue.cst,
			percentualBasePis: newValue.percentualBasePis,
			basePis: newValue.basePis,
			percentualPis: newValue.percentualPis,
			valorPis: newValue.valorPis,
			percentualBasePisSt: newValue.percentualBasePisSt,
			basePisSt: newValue.basePisSt,
			percentualPisSt: newValue.percentualPisSt,
			valorPisSt: newValue.valorPisSt,
		};
	}

	function converterCofins(newValue) {
		return {
			...oldValues.cofins,
			cst: newValue.cst,
			percentualBaseCofins: newValue.percentualBaseCofins,
			baseCofins: newValue.baseCofins,
			percentualCofins: newValue.percentualCofins,
			valorCofins: newValue.valorCofins,
			percentualBaseCofinsSt: newValue.percentualBaseCofinsSt,
			baseCofinsSt: newValue.baseCofinsSt,
			percentualCofinsSt: newValue.percentualCofinsSt,
			valorCofinsSt: newValue.valorCofinsSt,
		};
	}

	return {
		icms: converterIcms(tributos.icms),
		ipi: converterIpi(tributos.ipi),
		pis: converterPis(tributos.pis),
		cofins: converterCofins(tributos.cofins),
	};
}

async function calcularImpostosProdutoOS(values) {
	const produtoCalculado = await calcularImpostosProduto(await converterProdutoImpostoParaCalculo(values));

	const produtoConvertido = await converterProdutoImpostoParaFormulario(produtoCalculado, values);

	produtoConvertido.subtotal = parseFloat(
		(
			(produtoConvertido?.quantidade ?? 0) * (produtoConvertido?.valor ?? 0) +
			(produtoConvertido?.seguro ?? 0) +
			(produtoConvertido?.frete ?? 0) +
			(produtoConvertido?.acessorias ?? 0) +
			(produtoConvertido?.tributos?.icms?.valorSt ? produtoConvertido?.tributos?.icms?.valorSt : 0) +
			(produtoConvertido?.tributos?.ipi?.valor ? produtoConvertido?.tributos?.ipi?.valor : 0) -
			(produtoConvertido?.desconto ?? 0)
		).toFixed(2)
	);

	return produtoConvertido;
}

function getMunicipioTomador() {
	const municipio = buscarDadosLoginLocalStorage()?.filialConectada?.endereco?.municipio;
	return converterValueParaSelect(municipio, `${municipio?.nome} - ${municipio?.estado?.sigla}`);
}

function recalcularTotais(values, totais) {
	const { totalServicos, totalProdutos } = totais;
	let totaisAtualizado = copiarObjeto(DEFAULT_TOTAIS);

	totaisAtualizado.totalServicos.id = totalServicos.id;
	totaisAtualizado.totalServicos.tipoDesconto = totalServicos.tipoDesconto;
	totaisAtualizado.totalProdutos.id = totalProdutos.id;
	totaisAtualizado.totalProdutos.tipoDesconto = totalProdutos.tipoDesconto;

	// Serviços
	values.servicos.forEach((servico) => {
		totaisAtualizado.totalServicos.valorBruto += parseFloat((servico.quantidade * servico.valor)?.toFixed(2) ?? 0);
		totaisAtualizado.totalServicos.desconto += parseFloat(servico.desconto?.toFixed(2) ?? 0);

		// ISS
		totaisAtualizado.totalServicos.baseIss += parseFloat(servico.tributos.iss.valorBase?.toFixed(2) ?? 0);
		totaisAtualizado.totalServicos.valorIss += parseFloat(servico.tributos.iss.valor?.toFixed(2) ?? 0);

		// IR
		totaisAtualizado.totalServicos.baseIr += parseFloat(servico.tributos.ir.valorBase?.toFixed(2) ?? 0);
		totaisAtualizado.totalServicos.valorIr += parseFloat(servico.tributos.ir.valor?.toFixed(2) ?? 0);

		// CSLL
		totaisAtualizado.totalServicos.baseCsll += parseFloat(servico.tributos.csll.valorBase?.toFixed(2) ?? 0);
		totaisAtualizado.totalServicos.valorCsll += parseFloat(servico.tributos.csll.valor?.toFixed(2) ?? 0);

		// INSS
		totaisAtualizado.totalServicos.baseInss += parseFloat(servico.tributos.inss.valorBase?.toFixed(2) ?? 0);
		totaisAtualizado.totalServicos.valorInss += parseFloat(servico.tributos.inss.valor?.toFixed(2) ?? 0);

		// PIS
		totaisAtualizado.totalServicos.basePis += parseFloat(servico.tributos.pis.valorBase?.toFixed(2) ?? 0);
		totaisAtualizado.totalServicos.valorPis += parseFloat(servico.tributos.pis.valor?.toFixed(2) ?? 0);

		// COFINS
		totaisAtualizado.totalServicos.baseCofins += parseFloat(servico.tributos.cofins.valorBase?.toFixed(2) ?? 0);
		totaisAtualizado.totalServicos.valorCofins += parseFloat(servico.tributos.cofins.valor?.toFixed(2) ?? 0);
	});

	totaisAtualizado.totalServicos.valorLiquido = parseFloat(
		(totaisAtualizado.totalServicos.valorBruto - totaisAtualizado.totalServicos.desconto)?.toFixed(2) ?? 0
	);

	// Produtos
	values.produtos.forEach((produto) => {
		totaisAtualizado.totalProdutos.valorBruto += parseFloat((produto.quantidade * produto.valor)?.toFixed(2) ?? 0);
		totaisAtualizado.totalProdutos.frete += parseFloat(produto.frete?.toFixed(2) ?? 0);
		totaisAtualizado.totalProdutos.seguro += parseFloat(produto.seguro?.toFixed(2) ?? 0);
		totaisAtualizado.totalProdutos.acessorias += parseFloat(produto.acessorias?.toFixed(2) ?? 0);
		totaisAtualizado.totalProdutos.desconto += parseFloat(produto.desconto?.toFixed(2) ?? 0);

		// ICMS
		totaisAtualizado.totalProdutos.baseIcms += parseFloat(produto.tributos.icms?.baseIcms?.toFixed(2) ?? 0);
		totaisAtualizado.totalProdutos.valorIcms += parseFloat(produto.tributos.icms?.valorIcms?.toFixed(2) ?? 0);

		// ST
		totaisAtualizado.totalProdutos.baseSt += parseFloat(produto.tributos.icms?.baseIcmsSt?.toFixed(2) ?? 0);
		totaisAtualizado.totalProdutos.valorSt += parseFloat(produto.tributos.icms?.valorSt?.toFixed(2) ?? 0);

		// IPI
		totaisAtualizado.totalProdutos.baseIpi += parseFloat(produto.tributos.ipi?.baseIpi?.toFixed(2) ?? 0);
		totaisAtualizado.totalProdutos.valorIpi += parseFloat(produto.tributos.ipi?.valorIpi?.toFixed(2) ?? 0);

		// PIS
		totaisAtualizado.totalProdutos.basePis += parseFloat(produto.tributos.pis?.basePis?.toFixed(2) ?? 0);
		totaisAtualizado.totalProdutos.valorPis += parseFloat(produto.tributos.pis?.valorPis?.toFixed(2) ?? 0);

		// COFINS
		totaisAtualizado.totalProdutos.baseCofins += parseFloat(produto.tributos.cofins?.baseCofins?.toFixed(2) ?? 0);
		totaisAtualizado.totalProdutos.valorCofins += parseFloat(produto.tributos.cofins?.valorCofins?.toFixed(2) ?? 0);
	});

	totaisAtualizado.totalProdutos.valorLiquido = parseFloat(
		(
			totaisAtualizado.totalProdutos.valorBruto +
			totaisAtualizado.totalProdutos.seguro +
			totaisAtualizado.totalProdutos.frete +
			totaisAtualizado.totalProdutos.acessorias +
			(totaisAtualizado.totalProdutos?.icms?.valorSt ?? 0) +
			(totaisAtualizado.totalProdutos?.ipi?.valor ?? 0) -
			totaisAtualizado.totalProdutos.desconto
		)?.toFixed(2) ?? 0
	);

	totaisAtualizado.valorTotal =
		totaisAtualizado.totalServicos.valorLiquido + totaisAtualizado.totalProdutos.valorLiquido;

	return totaisAtualizado;
}

function checkHasBoletoGerado(pagamentos = []) {
	// return (
	// 	pagamentos.filter((pagamento) => {
	// 		if (pagamento.parcelas.length > 0) {
	// 			return parcelas.filter((parcela) => !!parcela.temBoleto);
	// 		}
	// 	}).length > 0
	// );
}

function gerarParcela(parcela, data, condicaoPagamentoTipo) {
	let apenasPrimeiraParcela = null;
	if (isValid(parseISO(data))) {
		const vencimentoPrimeiraParcela =
			condicaoPagamentoTipo === CONDICOES_PAGAMENTO.A_VISTA ? parseISO(data) : addMonths(parseISO(data), 1);
		apenasPrimeiraParcela = [
			{
				...parcela,
				id: parcela ? parcela.id : gerarUUID(),
				vencimento: formatISO(vencimentoPrimeiraParcela),
				formaPagamento: OPTIONS_FORMA_PAGAMENTO.find((e) => e.value === 'DINHEIRO'),
			},
		];
	} else {
		apenasPrimeiraParcela = [
			{
				...parcela,
				id: parcela ? parcela.id : gerarUUID(),
				vencimento: null,
				formaPagamento: OPTIONS_FORMA_PAGAMENTO.find((e) => e.value === 'DINHEIRO'),
			},
		];
	}
	return apenasPrimeiraParcela;
}

function buscarPercentualAdicionalCondicaoPagamento(pagamentos, alteracoesPorCondicao = []) {
	const utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao =
		buscarDadosLoginLocalStorage()?.filialConectada?.parametrosVendas?.utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao;

	let adicionalAplicado = { percentual: 0, tipo: 'DESCONTO' };
	let pagamentoAPrazo = null;
	let pagamentoAVista = null;

	pagamentos?.forEach((pagamento) => {
		const tipoPagamento = pagamento?.condicaoPagamento?.registro?.tipo;
		if (tipoPagamento === CONDICAO_PAGAMENTO_TIPO.A_PRAZO) {
			pagamentoAPrazo = pagamento;
		} else if (tipoPagamento === CONDICAO_PAGAMENTO_TIPO.A_VISTA) {
			pagamentoAVista = pagamento;
		}
	});

	function calcularAdicional(pagamento, condicao) {
		const quantidadeParcelas = pagamento?.quantidadeParcelas?.value || 0;
		const percentualAplicado = condicao?.condicaoItens?.reduce((percentual, item) => {
			if (quantidadeParcelas >= item?.valorInicial && quantidadeParcelas <= item?.valorFinal) {
				return item.percentualAplicado;
			}

			return percentual;
		}, 0);

		return percentualAplicado;
	}

	function encontrarAlteracaoPorCondicao(pagamento, tipoPagamento) {
		alteracoesPorCondicao?.forEach((alteracao) => {
			if (pagamento?.condicaoPagamento?.value === alteracao.condicao.id) {
				const percentual =
					tipoPagamento === CONDICAO_PAGAMENTO_TIPO.A_PRAZO
						? calcularAdicional(pagamento, alteracao)
						: alteracao.condicaoItens[0]?.percentualAplicado || 0;

				adicionalAplicado = { percentual: percentual, tipo: alteracao.tipoAlteracaoCondicao };
			}
		});
	}

	if (utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao) {
		if (pagamentoAPrazo) {
			encontrarAlteracaoPorCondicao(pagamentoAPrazo, CONDICAO_PAGAMENTO_TIPO.A_PRAZO);
		} else if (pagamentoAVista) {
			encontrarAlteracaoPorCondicao(pagamentoAVista, CONDICAO_PAGAMENTO_TIPO.A_VISTA);
		}
	}

	return adicionalAplicado;
}

function buscarPercentualAdicionalQuantidadeServico(quantidade, descontosPorQuantidade = []) {
	const utilizaTabelaPrecoDescontoQuantidade =
		buscarDadosLoginLocalStorage()?.filialConectada?.parametrosVendas?.utilizaTabelaPrecoDescontoQuantidade;

	let percentualAplicado = 0;

	if (utilizaTabelaPrecoDescontoQuantidade) {
		percentualAplicado = descontosPorQuantidade?.reduce((percentual, desconto) => {
			if (quantidade >= desconto.valorInicial && quantidade <= desconto.valorFinal) {
				return desconto.percentualAplicado;
			}

			return percentual;
		}, 0);
	}

	return { percentual: percentualAplicado, tipo: 'DESCONTO' };
}

export {
	hasProduto,
	hasServico,
	calcularDescontoServicos,
	calcularFreteProdutos,
	calcularSeguroProdutos,
	calcularDescontoProdutos,
	calcularAcessoriasProdutos,
	recalcularParcelas,
	rateioParcelas,
	recalcularRateioParcela,
	validarValorNegativo,
	montarCalcularImpostosServicoOS,
	calcularImpostosServicoOS,
	montarCalcularImpostosProdutoOS,
	calcularImpostosProdutoOS,
	getMunicipioTomador,
	recalcularTotais,
	checkHasBoletoGerado,
	gerarParcela,
	buscarPercentualAdicionalCondicaoPagamento,
	buscarPercentualAdicionalQuantidadeServico,
};
