import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { ButtonExcluirTable, ButtonNovo, If, NenhumRegistroEncontrado } from 'components';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { connect } from 'react-redux';
import { ModalReboques } from './components/ModalReboques';

function TabReboquesImpl({ informacoesPermissoes, isMobile }) {
	const { values, setFieldValue } = useFormikContext();
	const [sortOrder, setSortOrder] = useState(1);
	const [sortField, setSortField] = useState('nome');
	const [modalReboqueVisible, setModalReboqueVisible] = useState(false);

	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EXCLUIR);

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return <span style={{ fontStyle: 'italic', opacity: '0.7' }}>{field.sigla ? field.sigla : field}</span>;
		}
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-all',
					paddingLeft: isMobile ? '15%' : '0',
				}}
			>
				{field.sigla ? field.sigla : field}
			</span>
		);
	}

	function handleClickExcluir(row) {
		const newValues = values.reboques.filter((reboque) => reboque.id !== row.id);
		setFieldValue('reboques', newValues);
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonExcluirTable onClick={() => handleClickExcluir(row)} podeExcluir={podeExcluir} />
			</div>
		);
	}

	return (
		<>
			<ButtonNovo
				onClick={() => setModalReboqueVisible(true)}
				label="Novo reboque"
				disabled={!podeInserir}
				style={{ marginBottom: '10px' }}
			/>
			<DataTable
				className="table"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				responsive
				value={values.reboques}
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={onSort}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column field="placa" header="Placa" body={(row) => aplicarEstiloInativa(row, row.placa)} sortable />
				<Column field="renavam" header="Renavam" body={(row) => aplicarEstiloInativa(row, row.renavam)} sortable />
				<Column field="uf" header="Estado" body={(row) => aplicarEstiloInativa(row, row.uf)} sortable />
				<Column className="step-listagem-acoes" header="Ações" body={renderOpcoes} />
			</DataTable>
			<If test={modalReboqueVisible}>
				<ModalReboques
					informacoesPermissoes={informacoesPermissoes}
					visible={modalReboqueVisible}
					onHide={() => setModalReboqueVisible(false)}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export const TabReboques = connect(mapStateToProps)(TabReboquesImpl);
