import {
	Modal,
	InputField,
	FormActions,
	FormContent,
	ButtonCancelar,
	ButtonSalvar,
	estadosBotaoCancelar,
} from 'components';
import { mensagensDeValidacao } from 'Common';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { validarFormulario } from '../../../../../../../../Util';

const initialValue = {
	email: null,
};

function ModalNovoUsuario(props) {
	const { visible, onHide, setFieldValue, values, informacoesPermissoes, onSave, handleReset, dirty } = props;
	const estadoBotaoCancelar = props.dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;

	async function salvar() {
		if (await validarFormulario(props)) {
			onSave(values.email);
		}
	}

	function cancelar() {
		if (dirty) {
			handleReset();
		} else {
			onHide();
		}
	}

	return (
		<Modal visible={visible} onHide={() => onHide()} header={'Novo usuário'} width={'30%'}>
			<FormActions>
				<ButtonCancelar
					onClick={() => cancelar()}
					estadoBotao={estadoBotaoCancelar}
					{...informacoesPermissoes}
				/>
				<ButtonSalvar onClick={() => salvar(values.email)} disabled={!props.dirty} {...informacoesPermissoes} />
			</FormActions>
			<FormContent>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					obrigatorio
					component={InputField}
					value={values.email}
					label="E-mail"
					name="email"
					onChange={e => setFieldValue('email', e.target.value)}
					colStyle={{ padding: '8px 0px' }}
					{...informacoesPermissoes}
				/>
			</FormContent>
		</Modal>
	);
}

ModalNovoUsuario = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (!values.email) {
			errors.email = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		email: Yup.string().email('Informe um e-mail válido').nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalNovoUsuario);

export default ModalNovoUsuario;
