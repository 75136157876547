import { Col, Grid } from 'components';
import { ListaCampos } from './components/ListaCampos';
import { CardPreviewEtiqueta } from './components/CardPreviewEtiqueta';

function EtiquetasCampos({ informacoesPermissoes, isMobile, isTablet, isLessHd }) {
  return (
    <Grid>
      <Col sm={12} md={12} lg={7} xl={7}>
        <ListaCampos
          informacoesPermissoes={informacoesPermissoes}
          isLessHd={isLessHd}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      </Col>
      <Col sm={12} md={12} lg={5} xl={5}>
        <CardPreviewEtiqueta isMobile={isMobile} isTablet={isTablet} />
      </Col>
    </Grid>
  );
}
export { EtiquetasCampos };
