import { Field } from 'formik';
import { useState } from 'react';
import { Button, Grid, If, InputField, WebCam } from '../../../../../../../components';

import { helpPessoaForm } from '../../../Help';

export default function PessoaAdicionais(props) {
	const { values, informacoesPermissoes, appendTo } = props;
	const { pessoaPai, pessoaMae, pessoaConjuge, localTrabalho } = values;

	const [visible, setVisible] = useState(false);

	const styleContainer = {
		display: 'flex',
		flexDirection: 'column',
		fontSize: '14px',
	};

	const styleImagem = {
		maxWidth: '200px',
		margin: '5px 5px 5px 0',
		borderRadius: '3px',
	};

	return (
		<div style={styleContainer}>
			<Grid>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={InputField}
					label="Nome do pai"
					name="pessoaPai"
					value={pessoaPai}
					helpMessage={helpPessoaForm.pessoaPai}
					size={255}
					id="PessoasAdicionaisInputNomePai"
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={InputField}
					label="Nome da mãe"
					name="pessoaMae"
					value={pessoaMae}
					helpMessage={helpPessoaForm.pessoaMae}
					size={255}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={InputField}
					label="Nome do cônjuge"
					name="pessoaConjuge"
					value={pessoaConjuge}
					helpMessage={helpPessoaForm.pessoaConjuge}
					size={255}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={InputField}
					label="Local de Trabalho"
					name="localTrabalho"
					value={localTrabalho}
					helpMessage={helpPessoaForm.localTrabalho}
					size={255}
					{...informacoesPermissoes}
				/>
			</Grid>
			<Button
				label={values.imagemUrl ? 'Alterar imagem' : 'Adicionar imagem'}
				onClick={() => {
					setVisible(true);
				}}
				style={{ marginBottom: '0.5rem' }}
			></Button>
			<If test={values.imagemUrl}>
				<img src={values.imagemUrl} style={styleImagem}></img>
			</If>
			<If test={visible}>
				<WebCam
					{...props}
					header="Imagem da pessoa"
					imagemFile={values.imagemFile}
					imagemUrl={values.imagemUrl}
					visible={visible}
					onHide={() => {
						setVisible(false);
					}}
					appendTo={appendTo}
				/>
			</If>
		</div>
	);
}
