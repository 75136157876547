import { format, parse } from "date-fns";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { connect } from "react-redux";
import { formatarMonetario } from "../../../../../Common";
import {
  Badge,
  NenhumRegistroEncontrado,
  Paginacao,
} from "../../../../../components";
import { ColorsSituacao, Situacao } from "../../Util/constantes";

const styleColumn = {
  textOverflow: "ellipsis",
  overflow: "hidden",
};

function TabelaBoletos(props) {
  const {
    sortField,
    sortOrder,
    registros,
    totalElements,
    rows,
    setRows,
    page,
    setPage,
    selectedBoletos,
    setFieldValue,
  } = props;

  function renderIdentificadorField(row) {
    return (
      <span
        title={
          row.descricao ? `descrição: ${row.descricao}` : `${row.identificador}`
        }
        style={{
          wordBreak: "break-all",
          paddingLeft: props.isMobile ? "15%" : "0",
          fontWeight: "500",
        }}
      >
        {row.identificador}
      </span>
    );
  }

  function renderPessoa(row) {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          wordBreak: "keep-all",
          paddingLeft: props.isMobile ? "15%" : "0",
        }}
      >
        {row}
      </span>
    );
  }

  function renderEmissaoField(row) {
    return (
      <span>
        {row
          ? format(parse(row, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")
          : " - "}
      </span>
    );
  }

  function renderSituacao(row) {
    const styleBackground = {
      borderRadius: "20px",
      padding: row.situacao === "PENDENTE" ? "0.1rem 1.3rem" : "0.1rem 1.2rem",
    };

    switch (row.situacao) {
      case "PENDENTE": {
        return Badge(
          ColorsSituacao.textPendente,
          ColorsSituacao.bgPendente,
          "Pendente",
          styleBackground
        );
      }
      case "FINALIZADA": {
        return Badge(
          ColorsSituacao.textFinalizada,
          ColorsSituacao.bgFinalizada,
          "Finalizada",
          styleBackground
        );
      }
      case "CANCELADA": {
        return Badge(
          ColorsSituacao.textCancelada,
          ColorsSituacao.bgCancelada,
          "Cancelada",
          styleBackground
        );
      }
      default:
        return row.situacao;
    }
  }

  function onPageChange(event) {
    setRows(event.rows);
    setPage(event.page);
  }

  function renderCategoriaField(element) {
    return (
      <span
        title={element?.nome}
        style={{ color: element?.aparencia, fontWeight: "bold" }}
      >
        {element?.nome}
      </span>
    );
  }

  function renderSituacaoField(row) {
    const styleBackground = {
      borderRadius: "20px",
      padding: getPaddingSituacao(row?.boleto?.situacao),
    };

    if (row.boleto && row.boleto.situacao) {
      switch (row.boleto.situacao) {
        case Situacao.SALVO.value: {
          return Badge(
            ColorsSituacao.textSalvo,
            ColorsSituacao.bgSalvo,
            Situacao.SALVO.label,
            styleBackground,
            null,
            null,
            row.boleto?.motivo ?? null
          );
        }
        case Situacao.FALHA.value: {
          return Badge(
            ColorsSituacao.textFalha,
            ColorsSituacao.bgFalha,
            Situacao.FALHA.label,
            styleBackground,
            null,
            null,
            row.boleto?.motivo ?? null
          );
        }
        case Situacao.EMITIDO.value: {
          return Badge(
            ColorsSituacao.textEmitido,
            ColorsSituacao.bgEmitido,
            Situacao.EMITIDO.label,
            styleBackground,
            null,
            null,
            row.boleto?.motivo ?? null
          );
        }
        case Situacao.REJEITADO.value: {
          return Badge(
            ColorsSituacao.textRejeitado,
            ColorsSituacao.bgRejeitado,
            Situacao.REJEITADO.label,
            styleBackground,
            null,
            null,
            row.boleto?.motivo ?? null
          );
        }
        case Situacao.REGISTRADO.value: {
          return Badge(
            ColorsSituacao.textRegistrado,
            ColorsSituacao.bgRegistrado,
            Situacao.REGISTRADO.label,
            styleBackground,
            null,
            null,
            row.boleto?.motivo ?? null
          );
        }
        case Situacao.LIQUIDADO.value: {
          return Badge(
            ColorsSituacao.textLiquidado,
            ColorsSituacao.bgLiquidado,
            Situacao.LIQUIDADO.label,
            styleBackground,
            null,
            null,
            row.boleto?.motivo ?? null
          );
        }
        case Situacao.BAIXADO.value: {
          return Badge(
            ColorsSituacao.textBaixado,
            ColorsSituacao.bgBaixado,
            Situacao.BAIXADO.label,
            styleBackground,
            null,
            null,
            row.boleto?.motivo ?? null
          );
        }
        case Situacao.PENDENTE_RETENTATIVA.value: {
          return Badge(
            ColorsSituacao.textRetentativa,
            ColorsSituacao.bgRetentativa,
            Situacao.PENDENTE_RETENTATIVA.label,
            styleBackground,
            null,
            null,
            row.boleto?.motivo ?? null
          );
        }
        default:
          return Badge(
            ColorsSituacao.textPendente,
            ColorsSituacao.bgPendente,
            Situacao.PENDENTE.label,
            styleBackground,
            null,
            null,
            row.boleto?.motivo ?? null
          );
      }
    } else {
      return Badge(
        ColorsSituacao.textPendente,
        ColorsSituacao.bgPendente,
        Situacao.PENDENTE.label,
        styleBackground,
        null,
        null,
        row.boleto?.motivo ?? null
      );
    }
  }

  function getPaddingSituacao(situacao) {
    switch (situacao) {
      case Situacao.SALVO.value: {
        return "0.1rem 1.2rem";
      }
      case Situacao.FALHA.value: {
        return "0.1rem 2.6rem";
      }
      case Situacao.EMITIDO.value: {
        return "0.1rem 2.1rem";
      }
      case Situacao.REJEITADO.value: {
        return "0.1rem 1.8rem";
      }
      case Situacao.REGISTRADO.value: {
        return "0.1rem 1.5rem";
      }
      case Situacao.LIQUIDADO.value: {
        return "0.1rem 1.6rem";
      }
      case Situacao.BAIXADO.value: {
        return "0.1rem 2.1rem";
      }
      case Situacao.PENDENTE_RETENTATIVA.value: {
        return "0.1rem 1.2rem";
      }
      default: {
        return "0.1rem 1.8rem";
      }
    }
  }

  return (
    <>
      <DataTable
        className="table table-boletos"
        rowClassName="table-row"
        cellClassName="table-row-cell"
        responsive
        sortField={sortField}
        sortOrder={sortOrder}
        value={registros}
        style={{ width: "100%" }}
        emptyMessage={<NenhumRegistroEncontrado />}
        selection={selectedBoletos}
        onSelectionChange={(e) => setFieldValue("selectedBoletos", e.value)}
        dataKey="id"
      >
        <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
        <Column
          className="step-listagem-order"
          field="identificador"
          header="Identificador"
          body={(row) => renderIdentificadorField(row)}
          sortable
          style={{
            ...styleColumn,
            width: "20%",
          }}
        />
        <Column
          field="emissao"
          header="Emissão"
          body={(row) => renderEmissaoField(row.emissao)}
          sortable
          style={{
            ...styleColumn,
            width: "8%",
          }}
        />
        <Column
          field="vencimento"
          header="Vencimento"
          body={(row) => renderEmissaoField(row.vencimento)}
          sortable
          style={{
            ...styleColumn,
            width: "8%",
          }}
        />
        <Column
          field="pessoa"
          header="Pessoa"
          body={(row) => renderPessoa(row.pessoa)}
          sortable
          style={{
            ...styleColumn,
            width: "30%",
          }}
        />
        <Column
          field="valor"
          header="Valor"
          body={(row) => formatarMonetario(row.valor)}
          sortable
          style={{
            ...styleColumn,
            width: "8%",
          }}
        />
        <Column
          field="aReceber"
          header="A receber"
          body={(row) => formatarMonetario(row.aReceber)}
          sortable
          style={{
            ...styleColumn,
            width: "8%",
          }}
        />
        <Column
          field="categoria.nome"
          header="Categoria"
          body={(row) => renderCategoriaField(row.categoria)}
          sortable
          style={{
            ...styleColumn,
            width: "10%",
          }}
        />
        <Column
          field="boleto.situacao"
          header="Situação"
          body={(row) => renderSituacaoField(row)}
          style={{
            ...styleColumn,
            width: "20%",
          }}
        />
      </DataTable>
      <Paginacao
        totalElements={totalElements}
        rows={rows}
        page={page}
        onPageChange={(e) => onPageChange(e)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(TabelaBoletos);
