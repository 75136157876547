function converteFilialContadorParaFormulario(filial) {
	const { contador } = filial;
	return {
		id: contador.id,
		nome: contador.nome,
		crc: contador.crc,
		cpf: contador.cpf,
		cnpj: contador.cnpj,
		telefone: contador.telefone,
		email: contador.email,
		endereco: contador.endereco
			? {
					cep: contador.endereco.cep,
					logradouro: contador.endereco.logradouro,
					numero: contador.endereco.numero,
					complemento: contador.endereco.complemento,
					bairro: contador.endereco.bairro,
					municipio: contador.endereco.municipio
						? {
								value: contador.endereco.municipio.id,
								label:
									contador.endereco.municipio.nome + ' - ' + contador.endereco.municipio.estado.sigla,
								registro: contador.endereco.municipio,
						  }
						: null,
			  }
			: null,
	};
}

function converteFilialContadorParaApi(filial, contador) {
	return {
		...filial,
		contador: {
			id: contador.id,
			nome: contador.nome,
			crc: contador.crc,
			cpf: contador.cpf,
			cnpj: contador.cnpj,
			telefone: contador.telefone,
			email: contador.email,
			endereco: contador.endereco
				? {
						cep: contador.endereco.cep,
						logradouro: contador.endereco.logradouro,
						numero: contador.endereco.numero,
						complemento: contador.endereco.complemento,
						bairro: contador.endereco.bairro,
						municipio: contador.endereco.municipio
							? {
									id: contador.endereco.municipio.value,
							  }
							: null,
				  }
				: null,
		},
	};
}

export { converteFilialContadorParaFormulario, converteFilialContadorParaApi };
