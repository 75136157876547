export const helpNFeForm = {
	indPres: 'Indicador de presença do comprador no estabelecimento comercial no momento da  operação',
	dhSaiEnt: 'Data e hora de saída da mercadoria/produto, campo preenchido automaticamente',
	vFrete: 'Valor Total do Frete',
	vSeg: 'Valor Total do Seguro',
	vOutro: 'Outras despesas acessórias',
	vDesc: 'Valor do desconto',
	ufSaidaPais: 'UF de Embarque ou de transposição de fronteira ',
	xLocExporta: 'Descrição do Local de Embarque ou de transposição de fronteira',
	xLocDespacho: 'Descrição do local de despacho',
	tpNF: 'Operação da NF-e (entrada/saída)',
	finNfe: 'Finalidade da NF-e (normal/complementar/ajuste/devolução)',
	natOp: 'Natureza da operação',
};

export const helpMessageOrcamentoVenda = {
	codigo: 'Código de identificação do documento',
	cliente: 'Cliente da venda ou orçamento',
	emissao: 'Data de emissão da venda ou orçamento',
	validade: 'Data de vencimento do orçamento',
	status: '',
	condicao: '',
	quantidade: '',
	informacaoComplementar: 'Estas informações serão incluídas na impressão do pedido',
	observacaoInterna: 'Estas informações NÃO serão incluídas na impressão do pedido',
	vendedor: 'Usuário do sistema responsável pelo orçamento ou venda',
	previsaoEntrega: 'Data prevista para entrega da venda ou orçamento',
};
