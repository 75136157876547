import { format, parseISO } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import {
  buscarDadosLoginLocalStorage,
  formatarMonetario,
  formatos,
  inserirMascara,
} from '../../../../../../../../../../Common';
import { Button, NenhumRegistroEncontrado, Paginacao } from '../../../../../../../../../../components';
import { infoStatusNfce } from '../../../../../../Util/constantes';
import '../../../Styles/index.css';

const styleButton = {
  borderRadius: '50%',
  padding: '5px',
  width: '30px',
  height: '30px',
  margin: '2px',
};

function TabelaNFCe(props) {
  const {
    registros,
    sortField,
    sortOrder,
    totalRecords,
    rows,
    page,
    onPageChange,
    setSortField,
    setSortOrder,
    isMobile,
    isTablet,
    isLessHd,
    acessarNFCe,
  } = props;

  function atualizarOrdenacao(e) {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  }

  function renderNumeroField(nfce) {
    const { filialConectada } = buscarDadosLoginLocalStorage();
    const serieConfiguracoes = filialConectada.parametrosFiscalNFCe?.serieNfce;

    return (
      <div title={buscarTitleSerie(nfce.serie, nfce.nro, serieConfiguracoes)}>
        <span>{buscarSerieNfce(nfce.serie, serieConfiguracoes)}</span>
        <span>{' / '}</span>
        <span
          title={!nfce.nro ? 'NFC-e não transmitida' : 'Número da nota fiscal do Consumidor'}
          style={{ fontWeight: 'bolder' }}
        >
          {nfce.nro || '-'}
        </span>
      </div>
    );
  }

  function buscarTitleSerie(serie, nro, serieConfiguracoes) {
    if (serie && nro) {
      return 'Série utilizada na transmissão da NFC-e';
    }

    if (serieConfiguracoes && !nro) {
      return 'Série definida nas configurações da NFC-e';
    }

    return 'Série não configurada';
  }

  function buscarSerieNfce(serie, serieConfiguracoes) {
    if (serie) {
      return serie;
    }

    if (serieConfiguracoes) {
      return serieConfiguracoes;
    }

    return '?';
  }

  function renderEmissaoField(nfce) {
    if (nfce.criadoEm) return <span>{format(parseISO(nfce.criadoEm, new Date()), 'dd/MM/yyyy')}</span>;
    return '';
  }

  function renderClienteField(nfce) {
    return (
      <span
        title={nfce.destNome}
        style={{
          maxHeight: '60px',
          overflow: 'hidden',
          display: 'flex',
          wordBreak: 'break-word',
          maxWidth: props.isMobile ? '70%' : '100%',
          textAlign: props.isMobile ? 'end' : 'start',
        }}
      >
        {nfce.destNome}
      </span>
    );
  }

  function renderCpfCnpjField(nfce) {
    const cpfCnpjComMascara = aplicaMascaraCpfCnpj(nfce);

    return (
      <span
        title={nfce.destCpf ?? nfce.destCnpj}
        style={{
          maxHeight: '60px',
          overflow: 'hidden',
          display: 'inline-flex',
        }}
      >
        {cpfCnpjComMascara}
      </span>
    );
  }

  function aplicaMascaraCpfCnpj(nfce) {
    if (nfce.destCpf) {
      return inserirMascara(nfce.destCpf, formatos.CPF);
    } else if (nfce.destCnpj) {
      return inserirMascara(nfce.destCnpj, formatos.CNPJ);
    }
  }

  function renderStatusField(nfce) {
    if (!nfce.status) {
      return null;
    }

    return (
      <span
        title={infoStatusNfce[nfce.status].description}
        style={{
          backgroundColor: infoStatusNfce[nfce.status].lightColor,
          color: infoStatusNfce[nfce.status].strongColor,
          fontWeight: 'bold',
          fontSize: '13px',
          borderRadius: '20px',
          display: 'flex',
          height: '1.5rem',
          width: '7rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p>{infoStatusNfce[nfce.status].name}</p>
      </span>
    );
  }

  function renderValorField(nfce) {
    const valor = formatarMonetario(nfce.valor);
    return <span title={valor}>{valor}</span>;
  }

  function renderAcoesField(row) {
    return (
      <Button
        style={styleButton}
        className="p-button p-button-primary"
        icon="fa fa-pencil"
        title={'Editar'}
        disabled={false}
        onClick={() => {
          acessarNFCe(row);
        }}
      />
    );
  }

  return (
    <>
      <DataTable
        className={`table step-nfce-listagem-produto ${
          registros?.length <= 0 ? 'table-itens-nfce-empty' : 'table-itens-nfce'
        }`}
        value={registros}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={atualizarOrdenacao}
        emptyMessage={<NenhumRegistroEncontrado message="Nenhuma nota fiscal encontrada" />}
        style={{
          height: '48vh',
          width: '100%',
        }}
      >
        <Column
          header="Série/NFC-e"
          field="nro"
          sortable={true}
          body={renderNumeroField}
          style={{ color: '#333333', width: '130px' }}
        />
        <Column
          header="Data"
          field="criadoEm"
          sortable={true}
          body={renderEmissaoField}
          style={{ color: '#333333', maxWidth: isLessHd ? '95px' : isMobile || isTablet ? '100%' : '100px' }}
        />
        <Column
          header="Cliente"
          field="destNome"
          sortable={true}
          body={renderClienteField}
          style={{
            color: '#333333',
            overflow: 'hidden',
          }}
        />
        <Column
          header="CPF/CNPJ"
          field="destCpfCnpj"
          sortable={false}
          body={renderCpfCnpjField}
          style={{ color: '#333333', maxWidth: isLessHd ? '155px' : isMobile || isTablet ? '100%' : '170px' }}
        />
        <Column
          header="Situação"
          field="status"
          sortable={true}
          body={renderStatusField}
          style={{ color: '#333333', width: isLessHd ? '130px' : '150px' }}
        />
        <Column
          header="Valor"
          field="valor"
          sortable={true}
          body={renderValorField}
          style={{
            color: '#333333',
            maxWidth: isLessHd ? '90px' : isMobile || isTablet ? '100%' : '110px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontWeight: 'bold',
          }}
        />
        <Column
          className="step-listagem-acoes"
          header="Ações"
          body={renderAcoesField}
          style={{ width: isMobile ? '100%' : '60px' }}
        />
      </DataTable>
      <Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
    </>
  );
}
export default TabelaNFCe;
