import { useState, useEffect } from 'react';
import {
	asyncDeletarUsuario,
	asyncDesbloquearCredencial,
	asyncBloquearCredencial,
	asyncRegistrarUsuario,
} from './Requests';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import ModalNovoUsuario from './components/ModalNovoUsuario';

import { buscarDadosLoginLocalStorage, removerElemento } from 'Common';

import {
	Button,
	confirm,
	alert,
	ButtonExcluirTable,
	Message,
	FormGroup,
	NenhumRegistroEncontrado,
	Badge,
	ButtonNovo,
	Grid,
	If,
} from 'components';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

const Colors = {
	kBgLightGreen: '#dcedc8',
	kTextGreen: '#1b5e20',
	kBgLightRed: '#ffcdd2',
	kTextRed: '#b71c1c',
};

function Usuarios(props) {
	const { values, idOrganizacao, credenciais, onChange, podeEditar } = props;

	const [registros, setRegistros] = useState(null);
	const [emailUsuarioLogado, setEmailUsuarioLogado] = useState(buscarDadosLoginLocalStorage().email);
	const [exibirUsuariosBloqueados, setExibirUsuariosBloqueados] = useState(false);
	const [exibirModalNovoUsuario, setExibirModalNovoUsuario] = useState(false);
	const [idOrganizacaoLocal, setIdOrganizacaoLocal] = useState();

	useEffect(() => {
		setIdOrganizacaoLocal(buscarDadosLoginLocalStorage().organizacao.id);
	}, []);

	useEffect(() => {
		if (idOrganizacao !== idOrganizacaoLocal) {
			setIdOrganizacaoLocal(idOrganizacao);
			setRegistros(credenciais || null);
		}
	}, [values]);

	function excluirUsuario(registro) {
		if (registro) {
			confirm('Confirmação', 'Tem certeza que deseja excluir o usuário ' + registro.email + '?', async () => {
				await asyncDeletarUsuario(idOrganizacao, registro.id, () => {
					onChange(removerElemento(credenciais, registro));
				});
			});
		}
	}

	function renderContaConfirmada(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: row.ativa ? '0.2rem 1.5rem' : '0.2rem 1.4rem',
		};

		if (row.ativa) {
			return Badge(Colors.kTextGreen, Colors.kBgLightGreen, 'Efetuada', styleBackground);
		} else {
			return Badge(Colors.kTextRed, Colors.kBgLightRed, 'Pendente', styleBackground);
		}
	}

	function renderContaBloqueada(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: row.bloqueada ? '0.2rem 2.1rem' : '0.2rem 1.4rem',
		};

		if (row.bloqueada) {
			return Badge(Colors.kTextRed, Colors.kBgLightRed, 'Bloqueada', styleBackground);
		} else {
			return Badge(Colors.kTextGreen, Colors.kBgLightGreen, 'Desbloqueada', styleBackground);
		}
	}

	function renderizarOpcoes(row) {
		if (row) {
			return (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<ButtonExcluirTable
						onClick={() => excluirUsuario(row)}
						podeExcluir={podeEditar}
						disabled={row.ativa}
						title={row.ativa ? 'Não é possível excluir usuários já confirmados.' : undefined}
					/>
					<Button
						style={styleButton}
						color="danger"
						title="Bloquear o usuário"
						icon="fa fa-lock"
						onClick={() => bloquearUsuarioSelecionado(row)}
						hidden={row.bloqueada}
						disabled={(row && row.email === emailUsuarioLogado) || !podeEditar}
					/>
					<Button
						style={styleButton}
						color="success"
						title="Desbloquear o usuário"
						icon="fa fa-unlock"
						disabled={!podeEditar}
						onClick={() => desbloquearUsuarioSelecionado(row)}
						hidden={!row.bloqueada}
					/>
				</div>
			);
		}
	}

	function registrarNovoUsuario(email) {
		asyncRegistrarUsuario(idOrganizacao, { email: email }, ({ data: credencial }) => {
			let credenciaisFiltrado = credenciais ?? [];
			onChange([...credenciaisFiltrado, { id: credencial.id, email: email, ativa: false, bloqueada: false }]);
			setExibirModalNovoUsuario(false);
		});
	}

	function bloquearUsuarioSelecionado(row) {
		if (emailUsuarioLogado !== row.email) {
			confirmarBloqueioUsuario(row);
		} else {
			alert('Confirmação', 'Não é possível bloquear-se. Entre com outro usuário para isto.');
		}
	}

	function confirmarBloqueioUsuario(row) {
		confirm('Confirmação', 'Tem certeza que deseja bloquear o usuário ' + row.email + '?', async () => {
			await asyncBloquearCredencial(idOrganizacao, row.id, () => {
				const values = credenciais.map(credencial => {
					if (row.id === credencial.id) {
						credencial.bloqueada = true;
					}
					return credencial;
				});
				onChange(values);
			});
		});
	}

	function desbloquearUsuarioSelecionado(row) {
		confirm('Confirmação', 'Tem certeza que deseja desbloquear o usuário ' + row.email + '?', async () => {
			await asyncDesbloquearCredencial(idOrganizacao, row.id, () => {
				const values = credenciais.map(credencial => {
					if (row.id === credencial.id) {
						credencial.bloqueada = false;
					}
					return credencial;
				});
				onChange(values);
			});
		});
	}

	if (!idOrganizacao) {
		return (
			<Message
				severity="info"
				colStyle={{ padding: '0px' }}
				text="Para cadastrar novos usuários é necessário salvar a organização"
			/>
		);
	}
	const credenciaisFiltradas =
		credenciais && exibirUsuariosBloqueados
			? credenciais
			: credenciais?.filter(credencial => !credencial.bloqueada);

	return (
		<>
			<FormGroup>
				<Grid style={{ margin: '-4px' }}>
					<ButtonNovo
						label="Novo usuário"
						podeInserir={podeEditar}
						disabled={!idOrganizacao || !podeEditar}
						onClick={() => {
							setEmailUsuarioLogado(true);
							setExibirModalNovoUsuario(true);
						}}
					/>
				</Grid>

				<DataTable
					className="table"
					responsive
					value={credenciaisFiltradas}
					emptyMessage={<NenhumRegistroEncontrado />}
					header={
						<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Checkbox
								inputId="exibirUsuariosBloqueados"
								name="exibirUsuariosBloqueados"
								onChange={e => setExibirUsuariosBloqueados(!exibirUsuariosBloqueados)}
								checked={exibirUsuariosBloqueados}
							/>
							<label htmlFor="exibirUsuariosBloqueados" className="p-checkbox-label">
								Exibir bloqueados
							</label>
						</span>
					}
				>
					<Column field="email" header="E-mail" sortable={true} />
					<Column field="ativa" body={renderContaConfirmada} header="Confirmação" sortable={true} />
					<Column field="bloqueada" body={renderContaBloqueada} header="Situação" sortable={true} />
					<Column header="Ações" body={renderizarOpcoes} style={{ width: '100px' }} />
				</DataTable>
			</FormGroup>
			<If test={exibirModalNovoUsuario}>
				<ModalNovoUsuario
					visible={exibirModalNovoUsuario}
					onHide={() => setExibirModalNovoUsuario(false)}
					onSave={email => registrarNovoUsuario(email)}
				/>
			</If>
		</>
	);
}

export default Usuarios;
