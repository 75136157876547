import Message from '../../../../../components/Message';

const textStyle = {
	margin: '5px 0px',
};

function MessagePagamentoNaoEfetuado() {
	return (
		<Message
			severity="warn"
			colStyle={{ margin: '1em 0em' }}
			text={
				<div>
					<h2
						style={{ fontWeight: 'bold' }}
						title="Você não pode mais incluir novas informações, porém ainda poderá visualizar, editar e excluir as informações existentes"
					>
						Sua organização foi bloqueada
					</h2>
					<p style={textStyle}>
						Não recebemos o pagamento da mensalidade. Caso já tenha efetuado o pagamento, tente novamente mais tarde.
						Caso tiver alguma dúvida, favor entrar em contato.
					</p>
					<span
						onClick={() => window.open('https://msyssolutions.inf.br/contato/', '_blank')}
						style={{ cursor: 'pointer', fontWeight: 'bold' }}
					>
						Entrar em contato
					</span>
				</div>
			}
		/>
	);
}

export { MessagePagamentoNaoEfetuado };
