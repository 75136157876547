import { TabPanel } from 'primereact/tabview';

import { Grid, TabView } from 'components';
import { ISS } from './components/ISS';
import { PIS } from './components/PIS';
import { COFINS } from './components/COFINS';
import { Descricao } from './components/Descricao';

function Tabs({ disabled, informacoesPermissoes }) {
	return (
		<Grid>
			<TabView className="tab-view" {...informacoesPermissoes}>
				<TabPanel header="ISS">
					<ISS disabled={disabled} informacoesPermissoes={informacoesPermissoes} />
				</TabPanel>

				<TabPanel header="PIS">
					<PIS disabled={disabled} informacoesPermissoes={informacoesPermissoes} />
				</TabPanel>
				<TabPanel header="COFINS">
					<COFINS disabled={disabled} informacoesPermissoes={informacoesPermissoes} />
				</TabPanel>
				<TabPanel header="Observação">
					<Descricao disabled={disabled} informacoesPermissoes={informacoesPermissoes} />
				</TabPanel>
			</TabView>
		</Grid>
	);
}

export { Tabs };
