import { useEffect } from 'react';
import { useFormikContext, withFormik } from 'formik';

import { Form, FormActions, FormContent, Modal } from 'components';
import { estadosCadastro, permissoes, recursos, usuarioPossuiPermissao } from 'Common';

import { converterProdutosDevolucaoParaForm } from '../../Form/Util/PedidovendasOrcamentosConverter';
import { ActionButtons } from './components/ActionButtons';
import { ListaProdutosDevolucao } from './components/ListaProdutosDevolucao';
import { getVendaItensDevolvidos } from '../../Requests';

function ModalDevolverItensImpl({ vendaId, visible, onHide, isMobile, isTablet }) {
	const { values, resetForm } = useFormikContext();

	const podeInserir = usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EXCLUIR);

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};

	useEffect(() => {
		getVendaItensDevolvidos(vendaId, ({ data }) => {
			if (data?.length > 0) {
				atualizarProdutos(data);
			}
		});
	}, []);

	function atualizarProdutos(data) {
		const produtos = values?.produtos
			?.map((produto) => {
				const item = data?.find((item) => item?.vendaItemId === produto?.id) || null;

				if (item?.quantidade) {
					const quantidade = produto.quantidade - item.quantidade;
					const unitario = produto.subtotal / produto.quantidade;
					const subtotal = quantidade * unitario;

					return {
						...produto,
						quantidade: quantidade,
						quantidadeInicial: quantidade,
						valor: unitario,
						subtotal: subtotal,
					};
				}

				return { ...produto, quantidadeInicial: produto.quantidade };
			})
			?.filter((produto) => produto.quantidade > 0);

		resetForm({ values: { ...values, produtos: produtos }, initialValues: { ...values, produtos: produtos } });
	}

	return (
		<Modal header="Devolução de pedido" visible={visible} onHide={onHide}>
			<Form>
				<FormActions>
					<ActionButtons vendaId={vendaId} informacoesPermissoes={informacoesPermissoes} onHide={onHide} />
				</FormActions>
				<FormContent>
					<ListaProdutosDevolucao
						isMobile={isMobile}
						isTablet={isTablet}
						informacoesPermissoes={informacoesPermissoes}
					/>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalDevolverItens = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return { produtos: converterProdutosDevolucaoParaForm(props.produtos) };
	},

	validate(props) {
		const errors = {};
		const errorsProdutos = [];

		if (props.produtos?.length > 0) {
			props.produtos?.forEach((produto, index) => {
				if (produto.quantidade > produto.quantidadeInicial) {
					errorsProdutos[index] = {
						...errorsProdutos[index],
						quantidade: 'Quantidade não pode ser maior que a quantidade disponível',
					};
				}

				if (produto.quantidade < 0) {
					errorsProdutos[index] = {
						...errorsProdutos[index],
						quantidade: 'Quantidade não pode ser menor que 0',
					};
				}
			});
		}

		if (errorsProdutos.length > 0) {
			errors.produtos = errorsProdutos;
		}

		return errors;
	},
})(ModalDevolverItensImpl);

export { ModalDevolverItens };
