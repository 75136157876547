import {
  ButtonNovo,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Form,
  FormActions,
  FormContent,
  Grid,
  If,
  InputField,
  Prompt,
  Tutorial,
} from 'components';
import { Field, withFormik } from 'formik';
import { SituacaoOptions, TypesHeaderModalTributacaoMunicipal } from '../Util/constantes';
import SingleSelectTributacaoFederal from 'components/select/SingleSelectTributacaoFederal';
import { useEffect, useState } from 'react';
import { ListagemTributacaoMunicipio } from './components/ListagemTributacaoMunicipio';
import {
  buscarConfiguracaoUsuario,
  buscarDadosLoginLocalStorage,
  configuracoesUsuario,
  estadosCadastro,
  gerarUUID,
  mensagensDeValidacao,
  permissoes,
  recursos,
  removerCaracteres,
  salvarConfiguracaoUsuario,
  usuarioPossuiPermissao,
  validarUUID,
} from 'Common';
import { asyncGetTributacaoMunicipal } from '../Requests';
import {
  converterTributacaoMunicipalParaDuplicar,
  converterTributacaoMunicipalParaFormulario,
} from '../Util/tributacaoMunicipalConverter';
import { ActionButton } from './components/ActionButton';
import { ModalTributacaoMunicipio } from './components/ModalTributacaoMunicipio';
import { HelpTributacaoMunicipalForm } from './Help';
import { useEffectOnce } from 'react-use';
import { tutorialSteps } from 'components/Tutorial/steps/TributacaoMunicipal';

const CADASTROURL = '/tributacoes/municipal/cadastro';
const PESQUISAURL = '/tributacoes/municipal';

const secaoStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0px',
};

const codigoStyle = {
  fontSize: '24px',
  color: '#006095',
  fontWeight: 'bold',
};

const initialValue = {
  id: null,
  codigo: null,
  nome: '',
  tributacaoFederal: null,
  situacao: SituacaoOptions.find(e => e.value === 'ATIVO'),
  municipios: [],
  favorito: false,
};

function TributacaoMunicipalFormView(props) {
  const { values, setFieldValue, dirty, resetForm, setValues, isModal, hideModal } = props;

  const [visibleModal, setVisibleModal] = useState(false);
  const [isNewTributacao, setIsNewTributacao] = useState(false);
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const [headerModal, setHeaderModal] = useState(TypesHeaderModalTributacaoMunicipal.INCLUIR);
  const [selectTributacaoMunicipio, setSelectTributacaoMunicipio] = useState(null);

  const idURL = props.match?.params.id;

  const tipoIntegracao = buscarDadosLoginLocalStorage().filialConectada.parametrosFiscalNFSe?.integracao;

  const podeInserir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.INSERIR);
  const podeEditar = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EDITAR);
  const podeExcluir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EXCLUIR);

  const informacoesPermissoes = {
    estadoCadastro: buscarEstadoCadastro(),
    podeInserir: podeInserir,
    podeEditar: podeEditar,
    podeExcluir: podeExcluir,
  };

  const validacaoNovaTributacao = idURL && !podeEditar;

  useEffect(() => {
    const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_TRIBUTACAO_MUNICIPAL);

    if (validarUUID(idURL) && !isNewTributacao && !isModal) {
      getTributacaoSelected();
    }

    if (deveExibirTutorial !== false) {
      setTutorialVisible(true);
      salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_TRIBUTACAO_MUNICIPAL, false, null, false);
    }
  }, []);

  useEffectOnce(() => {
    if (props.match?.path === '/tributacoes/municipal/duplicar/:id') {
      onDuplicarTributacaoMunicipal(props.match.params.id);
    }
  });

  async function getTributacaoSelected() {
    await asyncGetTributacaoMunicipal(idURL, async ({ data: tributacao }) => {
      let tributacaoConvertida = converterTributacaoMunicipalParaFormulario(tributacao);
      resetForm({ values: tributacaoConvertida });
    });
  }

  async function onDuplicarTributacaoMunicipal(id) {
    await asyncGetTributacaoMunicipal(id, async ({ data: tributacao }) => {
      let tributacaoConvertida = converterTributacaoMunicipalParaDuplicar(tributacao);
      setValues(tributacaoConvertida);
    });
  }

  function buscarEstadoCadastro() {
    return values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
  }

  function onChangeNome(event) {
    setFieldValue('nome', removerCaracteres(event.target?.value, ['─']));
  }

  function onBlurNome() {
    if (values.nome) {
      setFieldValue('nome', values.nome.trim());
    }
  }

  function onChangeTributacaoFederal(event) {
    setFieldValue(
      'tributacaoFederal',
      event
        ? {
            label: event.label,
            value: event.value,
          }
        : null
    );
  }

  function onChangeSituacao(event) {
    setFieldValue('situacao', {
      label: event.label,
      value: event.value,
    });
  }

  function onChangeFavorita() {
    setFieldValue('favorita', !values.favorita);
  }

  function onVisibleModal() {
    setHeaderModal(TypesHeaderModalTributacaoMunicipal.INCLUIR);
    setVisibleModal(true);
  }

  function onHideModal() {
    setSelectTributacaoMunicipio(null);
    setVisibleModal(false);
  }

  function salvarNovaTributacao(tributacao) {
    tributacao.idTemporario = gerarUUID();
    setFieldValue('municipios', [...values.municipios, tributacao]);
    onHideModal();
  }

  function atualizarTributacaoMunicipios(tributacaoModal) {
    let tributacoesAtualizadas = values.municipios.map(tributacao => {
      if (tributacao.id && tributacaoModal.id === tributacao.id) {
        return tributacaoModal;
      } else if (tributacao.idTemporario && tributacaoModal.idTemporario === tributacao.idTemporario) {
        return tributacaoModal;
      } else {
        return tributacao;
      }
    });

    setFieldValue('municipios', tributacoesAtualizadas);
    onHideModal();
  }

  function excluirTributacaoMunicipio(tributacaoModal) {
    let tributacoesAtualizadas = values.municipios.filter(tributacao => {
      if (
        (tributacao.id && tributacaoModal.id !== tributacao.id) ||
        (tributacao.idTemporario && tributacaoModal.idTemporario !== tributacao.idTemporario)
      ) {
        return tributacao;
      }
    });
    setFieldValue('municipios', tributacoesAtualizadas);

    if (visibleModal) {
      onHideModal();
    }
  }

  function setVisibleModalByListagem(value) {
    setVisibleModal(value);
    if (value) {
      setHeaderModal(TypesHeaderModalTributacaoMunicipal.EDITAR);
    }
  }

  return (
    <>
      <Prompt dirty={dirty} isModal={isModal} />
      <Tutorial
        steps={tutorialSteps}
        showSkipButton
        continuous
        visible={tutorialVisible}
        onHide={() => setTutorialVisible(false)}
      />
      <Form header="Cadastro de tributação municipal" isModal={isModal} className="card-default screen-max-width">
        <FormActions className="screen-max-width">
          <ActionButton
            informacoesPermissoes={informacoesPermissoes}
            initialValue={initialValue}
            CADASTROURL={CADASTROURL}
            PESQUISAURL={PESQUISAURL}
            setIsNewTributacao={setIsNewTributacao}
            isModal={isModal}
            hideModal={hideModal}
            {...props}
          />
        </FormActions>
        <FormContent>
          <Grid className="step-tributacao-municipal-principais">
            <Col sm="12" md="12" lg="12" xl="12" style={{ height: '42px', marginBottom: '1rem' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div
                  style={secaoStyle}
                  title={!values.codigo ? 'O código é gerado automaticamente pelo sistema' : null}
                >
                  <div>Código</div>
                  <div style={codigoStyle}>{values.codigo || '-'}</div>
                </div>
              </div>
            </Col>
            <Field
              sm="12"
              md="6"
              lg="6"
              xl="6"
              component={InputField}
              obrigatorio
              label="Nome"
              name="nome"
              value={values.nome}
              onChange={onChangeNome}
              onBlur={onBlurNome}
              size={120}
              helpMessage={HelpTributacaoMunicipalForm.nome}
              {...informacoesPermissoes}
            />
            <Field
              sm="12"
              md="4"
              lg="4"
              xl="4"
              component={SingleSelectTributacaoFederal}
              obrigatorio
              label="Tributação federal"
              name="tributacaoFederal"
              value={values.tributacaoFederal}
              onChange={onChangeTributacaoFederal}
              isClearable={false}
              helpMessage={HelpTributacaoMunicipalForm.tributacaoFederal}
              {...informacoesPermissoes}
            />
            <Field
              sm="12"
              md="2"
              lg="2"
              xl="2"
              component={Dropdown}
              obrigatorio
              label="Situação"
              name="situacao"
              value={values.situacao}
              onChange={onChangeSituacao}
              options={SituacaoOptions}
              showClear={false}
              helpMessage={HelpTributacaoMunicipalForm.situacao}
              {...informacoesPermissoes}
            />
            <Field
              sm="12"
              md="4"
              lg="3"
              xl="3"
              component={Checkbox}
              obrigatorio
              label="Tributação municipal favorita"
              name="favorita"
              checked={values.favorita}
              onChange={onChangeFavorita}
              showClear={false}
              helpMessage={HelpTributacaoMunicipalForm.favorito}
              {...informacoesPermissoes}
            />
          </Grid>
          <Divider label="Tributações por município" styleContainer={{ marginLeft: '0' }} />
          <ButtonNovo
            className="step-tributacao-municipal-tributacoes-button"
            label="Nova tributação"
            onClick={onVisibleModal}
            disabled={idURL && !informacoesPermissoes.podeEditar}
          />
          <ListagemTributacaoMunicipio
            values={values.municipios}
            setSelectTributacaoMunicipio={setSelectTributacaoMunicipio}
            setVisibleModal={setVisibleModalByListagem}
            excluirTributacaoMunicipio={excluirTributacaoMunicipio}
          />
        </FormContent>
      </Form>
      <If test={visibleModal}>
        <ModalTributacaoMunicipio
          visible={visibleModal}
          onHide={onHideModal}
          header={headerModal}
          listaTributacaoMunicipios={values.municipios}
          selectTributacaoMunicipio={selectTributacaoMunicipio}
          salvarNovaTributacao={salvarNovaTributacao}
          atualizarTributacaoMunicipios={atualizarTributacaoMunicipios}
          excluirTributacaoMunicipio={excluirTributacaoMunicipio}
          validacaoNovaTributacao={validacaoNovaTributacao}
          tipoIntegracao={tipoIntegracao}
        />
      </If>
    </>
  );
}

export const TributacaoMunicipalForm = withFormik({
  mapPropsToValues(props) {
    return initialValue;
  },

  validate(values) {
    let errors = {};

    if (!values.nome || values.nome?.trim() === '') {
      errors.nome = mensagensDeValidacao.OBRIGATORIO;
    }

    if (!values.tributacaoFederal) {
      errors.tributacaoFederal = mensagensDeValidacao.OBRIGATORIO;
    }

    if (!values.situacao) {
      errors.situacao = mensagensDeValidacao.OBRIGATORIO;
    }
    return errors;
  },

  handleSubmit: () => {},
})(TributacaoMunicipalFormView);
