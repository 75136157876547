import { get, del, post, put, exibirToast } from '../../../../../../Common/Requisicoes';
import { services } from '../../../../../../Common/Constantes/api';

export async function asyncCreateLancamentoDespesa(data, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/lancamentos`,
		data,
		null,
		exibirToast(onSuccess, 'Despesa criada com sucesso'),
		onError
	);
}

export async function asyncGetLancamentoDespesa(lancamento, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/lancamentos/${lancamento}`, null, onSuccess, onError);
}

export async function asyncUpdateLancamentoDespesa(lancamento, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/lancamentos/${lancamento.id}`,
		lancamento,
		null,
		exibirToast(onSuccess, 'Despesa atualizada com sucesso'),
		onError
	);
}

export async function asyncDeleteLancamentoDespesa(lancamento, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/lancamentos/${lancamento}`,
		null,
		exibirToast(onSuccess, 'Despesa removida com sucesso'),
		onError
	);
}

export async function buscarCategoriaFavoritaDespesa(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/lancamentos/relacoes/categorias/despesas?query=favoritaDespesa=="true";ativa=="true"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarFormaPagamentoDinheiro(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/lancamentos/relacoes/formas_pagamento?query=formaPagamentoFiscal=="DINHEIRO"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarContaFavoritaDespesa(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/lancamentos/relacoes/contas?query=favoritaDespesa=="true";situacao=="ATIVA"`,
		null,
		onSuccess,
		onError,
		false
	);
}
