import { removerCaracteres } from '../ManipulacaoDeString';

export function construirUrl(url, filtro, size, page, sort) {
	var retorno =
		url +
		(filtro ? formatarValor(filtro) : '') +
		(filtro ? '&size=' : '?size=') +
		size +
		'&page=' +
		page +
		(sort ? '&sort=' + sort : '');
	return retorno;
}

function formatarValor(parametro) {
	let retorno = parametro;
	retorno = retorno?.split('/').join('%2F');
	// retorno = retorno?.split("=").join("%3D");
	retorno = retorno?.split('#').join('');
	retorno = retorno?.split("'").join("'");
	retorno = retorno?.split('"').join('"');
	retorno = retorno?.split('	').join('');

	return retorno;
}

export function removerCaracteresInvalidosRsql(valor, removerEComercial = true) {
	if (removerEComercial) {
		return removerCaracteres(valor, ['#', '%', '"', '[', ']', '`', '|', '\\', '^', '&', '{', '}']);
	} else {
		return removerCaracteres(valor, ['#', '%', '"', '[', ']', '`', '|', '\\', '^', '{', '}']);
	}
}

export const keyFilterConsultaRsql = /^[^%"[\]`|\\^&{}#']+$/;
