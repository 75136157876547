import { Card, Col, Divider, Grid, If } from 'components';
import { Field } from 'formik';
import { useState } from 'react';
import { useContextNFCe } from '../../../Context';

import Cliente from './components/Cliente';
import ListaProdutos from './components/ListaProdutos';
import ModalProduto from './components/ModalProduto';
import TotalFinalizacao from './components/TotalFinalizacao';

import './Styles/index.css';

function ItensCard(props) {
	const {
		values,
		sm,
		md,
		lg,
		xl,
		setFieldValue,
		isMobile,
		isTablet,
		isLessHd,
		isDesktop,
		isFullScreen,
		dirty,
		novaNFCe,
		setAtualizarTotal,
		decimaisQtd,
		decimaisPreco,
		resetFormPrincipal,
		imprimirNFCe,
	} = props;

	const { modalProdutoVisible, setModalProdutoVisible, podeInserir } = useContextNFCe();
	const [indexProdutoLista, setIndexProdutoLista] = useState();

	function onHideModalProduto() {
		setModalProdutoVisible(false);
		setAtualizarTotal(true);
	}

	const totalizadores = (
		<Grid>
			<Divider styleContainer={{ marginTop: '0px' }} />
			<Field
				sm={12}
				md={12}
				lg={12}
				xl={12}
				component={Cliente}
				isMobile={isMobile}
				isTablet={isTablet}
				isLessHd={isLessHd}
				cliente={values.cliente}
				setFieldValue={setFieldValue}
				isFullScreen={isFullScreen}
			/>
			<Field
				sm={12}
				md={12}
				lg={12}
				xl={12}
				component={TotalFinalizacao}
				isMobile={isMobile}
				isTablet={isTablet}
				isLessHd={isLessHd}
				values={values}
				setFieldValue={setFieldValue}
				novaNFCe={novaNFCe}
				dirty={dirty}
				decimaisQtd={decimaisQtd}
				decimaisPreco={decimaisPreco}
				isFullScreen={isFullScreen}
				resetFormPrincipal={resetFormPrincipal}
				setAtualizarTotal={setAtualizarTotal}
				disabled={!podeInserir}
				imprimirNFCe={imprimirNFCe}
			/>
		</Grid>
	);

	return (
		<Col sm={sm} md={md} lg={lg} xl={xl}>
			<Card className="card-itens-nfce" style={{ height: '100%' }} footer={totalizadores}>
				<ListaProdutos
					values={values}
					decimaisQtd={decimaisQtd}
					decimaisPreco={decimaisPreco}
					setFieldValue={setFieldValue}
					setAtualizarTotal={setAtualizarTotal}
					setIndexProdutoLista={setIndexProdutoLista}
					setModalProdutoVisible={setModalProdutoVisible}
					disabled={!podeInserir}
					isMobile={isMobile}
					isTablet={isTablet}
					isLessHd={isLessHd}
					isDesktop={isDesktop}
					isFullScreen={isFullScreen}
				/>
			</Card>
			<If test={modalProdutoVisible}>
				<ModalProduto
					visible={modalProdutoVisible}
					onHide={() => onHideModalProduto()}
					produto={values.produtos[indexProdutoLista]}
					statusNfce={values.status}
					indexProdutoLista={indexProdutoLista}
					setFieldValueForm={props.setFieldValue}
					tipoOperacao={values.tipoOperacao}
					setAtualizarTotal={setAtualizarTotal}
					disabled={!podeInserir}
					isFullScreen={isFullScreen}
					decimaisQtd={decimaisQtd}
					decimaisPreco={decimaisPreco}
				/>
			</If>
		</Col>
	);
}

export default ItensCard;
