import { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';

import { Grid, InputMoney, SingleSelectCst, typesCst } from 'components';

function COFINS({ disableFields, informacoesPermissoes }) {
	const { values, errors, setFieldValue } = useFormikContext();

	const [calcularImposto, setCalcularImposto] = useState(false);

	useEffect(() => {
		if (calcularImposto) {
			setFieldValue(
				'tributos.cofins.valor',
				(values.tributos.cofins.valorBase * values.tributos.cofins.percentual) / 100
			);
			setCalcularImposto(false);
		}
	}, [calcularImposto]);

	function onChangeValores(field, event) {
		if (field === 'tributos.cofins.valorBase' || field === 'tributos.cofins.percentual') {
			setCalcularImposto(true);
		}

		setFieldValue(`${field}`, event);
	}

	return (
		<>
			<Grid>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={SingleSelectCst}
					useFormErrors={false}
					useFormTouched={false}
					label="CST COFINS"
					name="cstCofins"
					errors={errors?.cstCofins}
					type={typesCst.CST_PIS_COFINS}
					value={values.tributos?.cofins.cst}
					onChange={(event) => onChangeValores('tributos.cofins.cst', event)}
					isClearable={false}
					obrigatorio
					disabled={disableFields}
					{...informacoesPermissoes}
				/>
			</Grid>
			<Grid>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMoney}
					label="BC COFINS"
					name="baseCofins"
					value={values.tributos?.cofins.valorBase}
					onChange={(event) => onChangeValores('tributos.cofins.valorBase', event.target.value)}
					touched
					allowNegative={false}
					size={13}
					disabled={disableFields}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMoney}
					label="COFINS %"
					name="percentualCofins"
					value={values.tributos?.cofins.percentual}
					onChange={(event) => onChangeValores('tributos.cofins.percentual', event.target.value)}
					size={8}
					decimalScale={2}
					prefix=""
					suffix="%"
					maxValue={100}
					placeholder="0,00 %"
					disabled={disableFields}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMoney}
					label="Valor COFINS"
					name="valorCofins"
					value={values.tributos?.cofins.valor}
					onChange={(event) => onChangeValores('tributos.cofins.valor', event.target.value)}
					touched
					allowNegative={false}
					size={13}
					disabled={disableFields}
					{...informacoesPermissoes}
				/>
			</Grid>
		</>
	);
}

export { COFINS };
