import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { Field, useFormikContext } from 'formik';

import { Col, Grid, If, InputDate, LinkVerDados, SingleSelectOperacaoFiscal, SingleSelectPessoa } from 'components';

import { TipoPessoa } from 'views/cadastros/Pessoas/Util/constantes';
import { useContextNFSe } from '../../../Context';
import { ModalTomadorView } from '../ModalTomador';
import { converterTomadorToModalForm } from '../../Util/NFSeConverter';

function Header({ informacoesPermissoes, buscarTabelaPreco, operacaoFiscal, buscarMunicipioIdPessoa }) {
	const { values, errors, setFieldValue } = useFormikContext();
	const { disableFields, setClienteUsaPrecoAtacado } = useContextNFSe();

	const [optionsOperacaoFiscal, setOptionsOperacaoFiscal] = useState(null);
	const [modalTomadorVisible, setModalTomadorVisible] = useState(false);

	useUpdateEffect(() => {
		if (!optionsOperacaoFiscal.some((e) => e.value === operacaoFiscal?.value)) {
			onChangeOperacaoFiscal(optionsOperacaoFiscal[0]);
		}
	}, [optionsOperacaoFiscal]);

	function onChangeOperacaoFiscal(value) {
		setFieldValue('operacaoFiscal', value);
	}

	function getOptions(values) {
		if (values !== undefined) {
			setOptionsOperacaoFiscal(values);
		}
	}

	function handleChangeTomador(data) {
		let tomadorConvertido = null;
		if (data) {
			data.registro.configPrecoPraticado === 'PRECO_ATACADO' ? setClienteUsaPrecoAtacado(true) : null;
			tomadorConvertido = {
				value: data.value,
				label: `${data.registro.nome} - ${
					data.registro.pessoaFisica
						? data.registro.pessoaFisica?.cpf
						: data.registro.pessoaJuridica
							? data.registro.pessoaJuridica?.cnpj
							: null
				}`,
				registro: {
					...data.registro,
					enderecos: values.tomador?.registro?.endereco
						? { ...values.tomador?.registro?.endereco }
						: data.registro?.enderecos,
					id: values.tomador?.registro?.id ?? values.tomador?.value ?? null,
					idTomador: data.value,
				},
			};

			setTimeout(() => {
				const elementHtmlProduto = document
					.getElementById('id-servicos-listagem')
					?.getElementsByTagName('table')[0]
					?.getElementsByTagName('tr')
					[values.servicos.length]?.getElementsByClassName('id-servicos-listagem-campo-servico')[0];

				elementHtmlProduto?.click();
			}, 200);
		} else {
			tomadorConvertido = null;
		}

		tomadorConvertido = {
			...tomadorConvertido,
			registro: converterTomadorToModalForm(tomadorConvertido.registro),
		};

		setFieldValue('tomador', tomadorConvertido);

		buscarTabelaPreco(
			{
				vendedorId: values.informacoesComplementares?.vendedor?.value,
				clienteId: tomadorConvertido?.value,
				municipioId: buscarMunicipioIdPessoa(tomadorConvertido),
			},
			({ data: tabelaPrecoApi }) => {
				const tabelaPreco = {
					label: `${tabelaPrecoApi.codigo} - ${tabelaPrecoApi.nome}`,
					registro: tabelaPrecoApi,
					value: tabelaPrecoApi.id,
				};

				setFieldValue('informacoesComplementares.tabelaPreco', tabelaPreco);
			}
		);
	}

	function handleSaveTomador(data) {
		setModalTomadorVisible(false);
		setFieldValue('tomador', data);
	}

	return (
		<>
			<Grid>
				<Col sm="12" md="12" lg="6" xl="6" style={{ padding: '0px' }}>
					<Field
						label="Cliente"
						name="tomador"
						component={SingleSelectPessoa}
						value={values?.tomador}
						onChange={(value) => handleChangeTomador(value)}
						tipoPessoa={TipoPessoa.CLIENTE}
						errors={errors.tomador}
						isClearable={false}
						disabled={disableFields}
						touched
						{...informacoesPermissoes}
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: `${errors?.tomador ? 'space-between' : 'flex-end'}`,
						}}
					>
						<If test={values.tomador}>
							<LinkVerDados
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									position: 'relative',
									margin: '-8px 30px -15px 0px',
								}}
								onClick={() => setModalTomadorVisible(true)}
								label="Ver dados do cliente"
							/>
						</If>
					</div>
				</Col>
				<Col sm="12" md="12" lg="3" xl="3" style={{ padding: '0px' }}>
					<Field
						label="Operação fiscal"
						name="operacaoFiscal"
						getOptions={getOptions}
						component={SingleSelectOperacaoFiscal}
						value={values?.operacaoFiscal}
						onChange={(value) => onChangeOperacaoFiscal(value)}
						obrigatorio
						isClearable={false}
						errors={errors.operacaoFiscal}
						disabled={disableFields}
						touched
						{...informacoesPermissoes}
					/>
				</Col>
				<Col sm="12" md="12" lg="3" xl="3" style={{ padding: '0px' }}>
					<Field
						component={InputDate}
						name="dataEmissao"
						label="Data de emissão"
						value={values?.dataEmissao}
						onChange={(e) => setFieldValue('dataEmissao', e.target.value)}
						errors={errors.dataEmissao}
						disabled={disableFields}
						{...informacoesPermissoes}
					/>
				</Col>
			</Grid>
			<If test={modalTomadorVisible}>
				<ModalTomadorView
					visible={modalTomadorVisible}
					onClose={() => setModalTomadorVisible(false)}
					onSaveModalTomador={(data) => handleSaveTomador(data)}
					tomador={values?.tomador}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</If>
		</>
	);
}

export default Header;
