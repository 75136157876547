import { createContext, useContext, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';

import { TIPO_PAGINA_CAMPOS } from '../Util/constantes';

const ContextEtiquetaConfiguracao = createContext();

export default function EtiquetaConfiguracaoProvider(props) {
  const { children } = props;

  const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.INSERIR));
  const [podeEditar, setPodeEditar] = useState(usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.EDITAR));
  const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.EXCLUIR));
  const [paginaOuCampos, setPaginaOuCampos] = useState(TIPO_PAGINA_CAMPOS.PAGINA);
  const [desselecionarCampoPreview, setDesselecionarCampoPreview] = useState(false);

  const alturaMaximaCardsContent = useWindowSize()?.height <= 720 ? 308 : 524;

  const camposEtiquetasUtilizados = useRef(null);

  return (
    <ContextEtiquetaConfiguracao.Provider
      value={{
        podeInserir,
        podeEditar,
        podeExcluir,
        paginaOuCampos,
        setPaginaOuCampos,
        camposEtiquetasUtilizados,
        alturaMaximaCardsContent,
        desselecionarCampoPreview,
        setDesselecionarCampoPreview,
      }}
    >
      {children ? children : null}
    </ContextEtiquetaConfiguracao.Provider>
  );
}

export const useContextEtiquetaConfiguracao = () => useContext(ContextEtiquetaConfiguracao);
