import {
	asyncGetOperacaoFiscalFavorita,
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	configuracoesUsuario,
	copiarObjeto,
	estadosCadastro,
	gerarUUID,
	mensagensDeValidacao,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usePrevious,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Col,
	Divider,
	Fieldset,
	Form,
	FormActions,
	FormContent,
	Grid,
	HeaderVendas,
	INITIAL_VALUE_PAGAMENTO,
	If,
	Message,
	Prompt,
	ToastTypes,
	Tutorial,
	confirm,
	notify,
	tutorialStepsOrcamento,
	tutorialStepsPedidoVenda,
	useGenerateParcelas,
	useRecalculatePagamentos,
	validatePagamentos,
} from 'components';
import { NOVO_PRODUTO_VENDA } from 'components/Produtos/Util/constantes';
import { montarECalcularTributacaoTodosProdutos } from 'components/Produtos/Util/util';
import { validateProdutos } from 'components/Produtos/Util/validations';
import { converterValueParaSelect } from 'components/body/Vendas/Util/functions';
import { format, isValid, parseISO } from 'date-fns';
import { useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/CondicaoPagamento/Util/constantes';
import BodyVendas from '../../../../../components/body/Vendas';
import { moduloUtilizado } from '../../../../../components/header/Util/constantes';
import { atualizarUrl, metodosAtualizarUrl } from '../../../../Util';
import { TipoPessoa } from '../../../../cadastros/Pessoas/Util/constantes';
import {
	asyncBuscarResponsavel,
	asyncBuscarTabelaPreco,
	asyncGetCategoriaFavorita,
	asyncGetCondicaoPagamentoFavorita,
	asyncGetContaFavorita,
	asyncGetFormaPagamentoDinheiro,
	asyncGetOperacoesFiscaisParaVenda,
	asyncGetVenda,
	buscarProximoNumero,
} from '../Requests';
import { SITUACAO_DEVOLUCAO, Status, colorsStatus } from '../Util/constantes';
import ModalCancelarVenda from '../components/ModalCancelarVenda';
import ModalCompartilharPorEmail from '../components/ModalCompartilharPorEmail';
import ModalCompartilharPorWhatsapp from '../components/ModalCompartilharPorWhatsapp';
import VendaNumeroCard from '../components/VendaNumeroCard';
import VendaStatusCard from '../components/VendaStatusCard';
import VendasHistorico from '../components/VendasHistorico';
import {
	converterParaDuplicar,
	converterParaGerarVenda,
	converterVendaParaFormulario,
} from './Util/PedidovendasOrcamentosConverter';
import { Condicoes, INITIAL_VALUES, TypeErrors } from './Util/constantes';
import { converterSetorParaFormulario } from './Util/functions';
import ActionButtons from './components/ActionButtons';
import HeaderForm from './components/HeaderForm';
import ValidateMessages from './components/ValidateMessages';
import { ModalDevolverItens } from '../components/ModalDevolverItens';
import { ModalItensDevolvidos } from '../components/ModalItensDevolvidos';

function PedidoVendasOrcamentosView({ isMobile, isTablet, history, ...props }) {
	const { values, dirty, setFieldValue, setValues, resetForm, isValid, validateForm, handleSubmit } =
		useFormikContext();

	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR));
	const [podeEditar, setPodeEditar] = useState(usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EDITAR));
	const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EXCLUIR));
	const [credencial] = useState(buscarDadosLoginLocalStorage());
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(
			isVenda() ? configuracoesUsuario.EXIBIR_TUTORIAL_PEDIDO_VENDA : configuracoesUsuario.EXIBIR_TUTORIAL_ORCAMENTO
		)
	);
	const [existeParcelaRecebida, setExisteParcelaRecebida] = useState(false);
	const [tabPagamentoSelecionada, setTabPagamentoSelecionada] = useState(0);
	const [modalMotivoCancelamentoVisible, setModalMotivoCancelamentoVisible] = useState(false);
	const [modalHistoricoVisible, setModalHistoricoVisible] = useState(false);
	const [modalEnviarPorEmailVisible, setModalEnviarPorEmailVisible] = useState(false);
	const [modalEnviarPorWhatsappVisible, setModalEnviarPorWhatsappVisible] = useState(false);
	const [modalDevolverItens, setModalDevolverItens] = useState(false);
	const [modalItensDevolvidos, setModalItensDevolvidos] = useState(false);
	const [atualizarTotal, setAtualizarTotal] = useState(false);
	const [atualizarOperacaoFiscalProdutos, setAtualizarOperacaoFiscalProdutos] = useState(false);
	const [atualizarImpostosProdutos, setAtualizarImpostosProdutos] = useState(false);
	const [totalizadores, setTotalizadores] = useState({
		totalProdutos: values.totalizadores?.totalProdutos ?? 0,
		totalFrete: values.totalizadores?.totalFrete ?? 0,
		totalSeguro: values.totalizadores?.totalSeguro ?? 0,
		totalAcessorias: values.totalizadores?.totalAcessorias ?? 0,
		totalDesconto: values.totalizadores?.totalDesconto ?? 0,
		totalIcmsSt: values.totalizadores?.totalIcmsSt ?? 0,
		totalIpi: values.totalizadores?.totalIpi ?? 0,
		totalIcms: values.totalizadores?.totalIcms ?? 0,
		totalBaseCalculoIcmsSt: values.totalizadores?.totalBaseCalculoIcmsSt ?? 0,
		totalBaseCalculoIcms: values.totalizadores?.totalBaseCalculoIcms ?? 0,
		totalBaseCalculoIpi: values.totalizadores?.totalBaseCalculoIpi ?? 0,
		totalLiquido: values.totalizadores?.totalLiquido ?? 0,
	});

	const [isFirstRender, setIsFirstRender] = useState(true);
	const [controlMessageProdutos, setControlMessageProdutos] = useState(null);
	const [isGerarVenda, setIsGerarVenda] = useState(false);
	const [itemInativo, setItemInativo] = useState(null);
	const [favoritosPagamento, setFavoritosPagamento] = useState(null);

	const prevProps = usePrevious(props);

	const podeEditarParaPagamentos = usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EDITAR);

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar && !(values.status === 'CANCELADA' || values.status === 'FINALIZADO'),
		podeExcluir: podeExcluir && !(values.status === 'CANCELADA' || values.status === 'FINALIZADO'),
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};

	const precoVendaOuAtacado = values.pessoa?.registro.configPrecoPraticado ?? null;
	const urlVendaOuOrcamento = isVenda() ? 'pedidos_venda' : 'orcamentos';
	const vendaOuOrcamento = isVenda() ? moduloUtilizado.PEDIDO_VENDA : moduloUtilizado.ORCAMENTO;
	const utilizaTabelaPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosVendas?.utilizaTabelaPreco;

	const [generateParcelas] = useGenerateParcelas({
		valorPagamento: totalizadores?.totalLiquido,
		dataBaseParcela: values.dataEmissao,
		favoritos: favoritosPagamento,
	});

	useRecalculatePagamentos(
		{
			valorTotal: totalizadores?.totalLiquido,
			dataBaseParcela: values.dataEmissao,
			favoritos: favoritosPagamento,
		},
		[totalizadores?.totalLiquido]
	);

	useEffect(async () => {
		const vendaId = props.location.state?.vendaId;

		if (vendaId) {
			duplicarVenda(vendaId);
		} else {
			const uuid = props.match.params.id;
			validarUUID(uuid) ? await asyncSelectRegistro(uuid) : novo();
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(
				isVenda() ? configuracoesUsuario.EXIBIR_TUTORIAL_PEDIDO_VENDA : configuracoesUsuario.EXIBIR_TUTORIAL_ORCAMENTO,
				false,
				null,
				false
			);
		}

		await getFavoritosPagamento();

		setTimeout(() => {
			if (props.match.path === '/pedido_venda/importar_orcamento/:id') {
				asyncGetVenda(urlVendaOuOrcamento, props.match.params.id, async ({ data: venda }) => {
					let vendaComSetor = copiarObjeto(venda);

					const { setores } = await asyncBuscarResponsavel(urlVendaOuOrcamento);
					setores?.forEach((setor) => {
						if (venda.setorId === setor.id) {
							vendaComSetor = { ...vendaComSetor, setor: setor };
						}
					});

					await asyncGetOperacoesFiscaisParaVenda(
						{
							tipoOperacao: 'SAIDA',
							tipoCliente: vendaComSetor?.cliente?.consumidorFinal,
						},
						({ data }) => {
							let operacaoFiscalData = {};

							if (data.content.length > 1) {
								data.content.forEach((operacaoFiscal) => {
									if (operacaoFiscal.favoritoSaida) {
										operacaoFiscalData = operacaoFiscal;
									}
								});
							} else {
								[operacaoFiscalData] = data.content;
							}

							const operacaoFiscalFavorita = {
								label: `${operacaoFiscalData.codigo} - ${operacaoFiscalData.descricao}`,
								registro: operacaoFiscalData,
								value: operacaoFiscalData.id,
							};

							if (operacaoFiscalFavorita.registro) {
								vendaComSetor.operacaoFiscal = operacaoFiscalFavorita;
							}
						}
					);

					generateVenda(converterVendaParaFormulario(vendaComSetor));
				});
			}
			document.getElementById('VendasSingleSelectCliente')?.getElementsByTagName('input')[0]?.focus();
		}, 250);
	}, []);

	useUpdateEffect(() => {
		if (buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PEDIDO_VENDA) !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PEDIDO_VENDA, false, null, false);
		}
	}, [isGerarVenda]);

	useUpdateEffect(() => {
		if (values.condicao === 'A_VISTA') {
			asyncGetContaFavorita(urlVendaOuOrcamento, ({ data }) => {
				let conta = data.content[0];
				conta = {
					label: conta.nome,
					registro: conta,
					value: conta.id,
				};

				setFieldValue('conta', conta);
			});
		}
	}, [values.condicao]);

	useEffect(() => {
		const isDiffAndDirty = prevProps?.dirty !== dirty && dirty;

		if (isDiffAndDirty && tabPagamentoSelecionada === 1) {
			if (values.condicao !== Condicoes.A_VISTA) {
				setTabPagamentoSelecionada(0);
			}
		}

		if (prevProps?.values.validacaoRecarregaProdutoPeloCliente !== values.validacaoRecarregaProdutoPeloCliente) {
			if (values.validacaoRecarregaProdutoPeloCliente) {
				prevProps?.values.produtos.map((item, index) => {
					ajustarValorVenda(item, index);
					return null;
				});
				setAtualizarTotal(true);
				setFieldValue('validacaoRecarregaProdutoPeloCliente', false);
			}
		}
	}, [prevProps]);

	useEffect(() => {
		if (atualizarImpostosProdutos && isVenda()) {
			if (values.pessoa) {
				montarECalcularTributacaoTodosProdutos(values.produtos, values.pessoa, (produtos) => {
					setFieldValue('produtos', produtos);
				});
			}
		}
		setAtualizarImpostosProdutos(false);
	}, [atualizarImpostosProdutos]);

	useEffect(() => {
		if (!isFirstRender && atualizarOperacaoFiscalProdutos && isVenda()) {
			const { produtos } = values;
			values.produtos.forEach((produto, index) => {
				if (prevProps.values?.operacaoFiscal?.value === produto.operacaoFiscal?.value) {
					produtos[index].operacaoFiscal = values.operacaoFiscal;
				}
			});
			montarECalcularTributacaoTodosProdutos(values.produtos, values.pessoa, (produtos) => {
				setFieldValue('produtos', produtos);
			});
		}
		setAtualizarOperacaoFiscalProdutos(false);
	}, [atualizarOperacaoFiscalProdutos]);

	async function asyncSelectRegistro(idVenda, deveAtualizarUrl = true) {
		await asyncGetVenda(
			urlVendaOuOrcamento,
			idVenda,
			async (venda) => {
				const { setores } = await asyncBuscarResponsavel(urlVendaOuOrcamento);
				let vendaComSetor;
				setores?.map((setor) => {
					if (venda.data.setorId === setor.id) {
						vendaComSetor = { ...venda.data, setor: setor };
					}
					return null;
				});

				const vendaConverted = converterVendaParaFormulario(vendaComSetor ?? venda.data);

				if (vendaConverted.status === 'FINALIZADO') {
					setPodeInserir(true);
					setPodeEditar(false);
					setPodeExcluir(false);
				}

				if (deveAtualizarUrl) {
					atualizarUrl(
						history,
						`/${isVenda() ? 'pedido_venda' : 'orcamento'}/cadastro/${idVenda}`,
						null,
						metodosAtualizarUrl.POP
					);
				}

				if (!vendaConverted.operacaoFiscal?.value) {
					await asyncGetOperacaoFiscalFavorita('SAIDA', ({ data }) => {
						const operacaoFiscal = data;
						const operacaoFiscalFavorita = {
							label: `${operacaoFiscal.codigo} - ${operacaoFiscal.descricao}`,
							registro: operacaoFiscal,
							value: operacaoFiscal.id,
						};

						if (operacaoFiscalFavorita.registro) {
							vendaConverted.operacaoFiscal = operacaoFiscalFavorita;
						}
					});
				}
				resetForm({ values: vendaConverted });
				setTotalizadores(vendaConverted.totalizadores);
				setAtualizarTotal(true);
				setIsFirstRender(false);
			},
			() => {
				novo();
			}
		);
	}

	async function getFavoritosPagamento(callback) {
		const pagamentos = [
			{
				...INITIAL_VALUE_PAGAMENTO,
				tempKey: gerarUUID(),
				sequencial: 1,
				conta: null,
				categoria: null,
				condicaoPagamento: null,
				formaPagamento: null,
			},
		];

		const favoritos = {};

		const promises = [
			asyncGetContaFavorita(urlVendaOuOrcamento, ({ data: response }) => {
				const contaFavorita = converterValueParaSelect(response.content[0]);
				pagamentos[0].conta = contaFavorita;
				favoritos.conta = contaFavorita;
			}),
			asyncGetCategoriaFavorita(urlVendaOuOrcamento, ({ data: response }) => {
				const categoria = converterValueParaSelect(response.content[0]);
				pagamentos[0].categoria = categoria;
				favoritos.categoria = categoria;
			}),
			asyncGetCondicaoPagamentoFavorita(urlVendaOuOrcamento, async ({ data: response }) => {
				const condicaoPagamentoFavorita = converterValueParaSelect(response.content[0]);
				const isAPrazo = condicaoPagamentoFavorita?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
				const parcelaPadraoCondicaoPagamento =
					condicaoPagamentoFavorita?.registro?.parcelaPadrao ?? condicaoPagamentoFavorita?.registro?.parcelaMinima ?? 1;

				pagamentos[0].condicaoPagamento = condicaoPagamentoFavorita;
				favoritos.condicaoPagamento = condicaoPagamentoFavorita;

				function setFormaPagamento(formaPagamento) {
					const formaPagamentoConvertida = converterValueParaSelect(formaPagamento);
					pagamentos[0].formaPagamento = formaPagamentoConvertida;
					favoritos.formaPagamento = formaPagamentoConvertida;

					if (formaPagamento.conta) {
						const contaConvertida = converterValueParaSelect(formaPagamento.conta);
						pagamentos[0].conta = contaConvertida;
						favoritos.conta = contaConvertida;
					}

					if (formaPagamento.categoria) {
						const categoriaConvertida = converterValueParaSelect(formaPagamento.categoria);
						pagamentos[0].categoria = categoriaConvertida;
						favoritos.categoria = categoriaConvertida;
					}
				}

				if (condicaoPagamentoFavorita.registro.formaPagamento) {
					setFormaPagamento(condicaoPagamentoFavorita.registro.formaPagamento);
				} else {
					await asyncGetFormaPagamentoDinheiro(urlVendaOuOrcamento, ({ data: response }) => {
						setFormaPagamento(response.content[0]);
					});
				}

				if (isAPrazo) {
					pagamentos[0].quantidadeParcelas = {
						value: parcelaPadraoCondicaoPagamento,
						label: `${parcelaPadraoCondicaoPagamento}x`,
					};
					pagamentos[0].parcelas = generateParcelas({
						qtdParcelas: parcelaPadraoCondicaoPagamento,
						favoritos: favoritos,
					});
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFavoritosPagamento(favoritos);
		});

		if (typeof callback === 'function') {
			callback(pagamentos);
		}
	}

	async function novo({ isCancelarVenda = false } = {}) {
		const tipoRegistro = isVenda() ? 'PEDIDO_VENDA' : 'ORCAMENTO';
		const valoresGet = {
			...copiarObjeto({ ...INITIAL_VALUES, produtos: [NOVO_PRODUTO_VENDA] }),
			status: Status.PENDENTE,
			tipo: tipoRegistro,
		};

		atualizarUrl(history, isVenda() ? '/pedido_venda/cadastro' : '/orcamento/cadastro', null, metodosAtualizarUrl.POP);

		await asyncBuscarResponsavel(urlVendaOuOrcamento, (dados) => {
			const informacoesComplementares = {
				...valoresGet.informacoesComplementares,
				setor: converterSetorParaFormulario(dados?.setorFavorito),
				vendedor: dados?.vendedor,
			};

			setExisteParcelaRecebida(false);
			setTabPagamentoSelecionada(0);

			valoresGet.informacoesComplementares = informacoesComplementares;
		});

		const promises = [
			asyncGetOperacaoFiscalFavorita('SAIDA', ({ data }) => {
				const operacaoFiscal = data;
				valoresGet.operacaoFiscal = operacaoFiscal
					? {
							label: `${operacaoFiscal.codigo} - ${operacaoFiscal.descricao}`,
							registro: operacaoFiscal,
							value: operacaoFiscal.id,
						}
					: null;
			}),
			buscarTabelaPreco(
				{ vendedorId: valoresGet.informacoesComplementares?.vendedor?.value, isCancelarVenda: isCancelarVenda },
				({ data: tabelaPrecoApi }) => {
					const tabelaPrecoGet = {
						label: `${tabelaPrecoApi.codigo} - ${tabelaPrecoApi.nome}`,
						registro: tabelaPrecoApi,
						value: tabelaPrecoApi.id,
					};
					valoresGet.informacoesComplementares = {
						...valoresGet.informacoesComplementares,
						tabelaPreco: tabelaPrecoGet,
					};
				}
			),
			buscarProximoNumero(
				urlVendaOuOrcamento,
				(numero) => {
					valoresGet.numero = numero;
				},
				tipoRegistro
			),
			getFavoritosPagamento((favoritosPagamentos) => {
				valoresGet.pagamentos = favoritosPagamentos;
			}),
		];

		await Promise.all(promises).then(() => {
			setPodeInserir(usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR));
			setPodeEditar(usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EDITAR));
			setPodeExcluir(usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EXCLUIR));

			resetForm({
				values: valoresGet,
			});

			setAtualizarTotal(true);
			setIsFirstRender(false);
		});
	}

	function ajustarValorVenda(item, index) {
		precoVendaOuAtacado === 'PRECO_ATACADO' && item.produto?.registro?.precoAtacado > 0
			? (values.produtos[index].valor = item.produto?.registro.precoAtacado)
			: (values.produtos[index].valor = item.produto?.registro.preco ?? 0);
		values.produtos[index].subtotal = values.produtos[index].valor * values.produtos[index].quantidade;
	}

	function isVenda() {
		if (props.match.path === '/pedido_venda') {
			return true;
		} else if (props.match.path === '/pedido_venda/cadastro') {
			return true;
		} else if (props.match.path === '/pedido_venda/cadastro/:id') {
			return true;
		} else if (props.match.path === '/pedido_venda/importar_orcamento/:id') {
			return true;
		} else {
			return false;
		}
	}

	function duplicarVenda(idVenda) {
		asyncGetVenda(urlVendaOuOrcamento, idVenda, async ({ data }) => {
			atualizarUrl(
				history,
				isVenda() ? '/pedido_venda/cadastro' : '/orcamento/cadastro',
				null,
				metodosAtualizarUrl.POP
			);
			const informacoesVenda = converterVendaParaFormulario(data);
			setExisteParcelaRecebida(false);
			setTabPagamentoSelecionada(0);
			setPodeEditar(true);
			setPodeExcluir(true);
			setPodeInserir(true);

			await buscarProximoNumero(urlVendaOuOrcamento, (numeroVenda) => {
				informacoesVenda.numero = numeroVenda ?? null;
			});
			setValues(converterParaDuplicar(informacoesVenda));
		});
	}

	function acessarNfe() {
		atualizarUrl(history, `/nfes/cadastro/${values?.nfe?.id}`);
	}

	function acessarNfce() {
		atualizarUrl(history, `/nfces/cadastro/${values?.nfce?.id}`);
	}

	async function generateVenda(orcamento) {
		const pedidoVenda = orcamento;
		let operacaoFiscalFavorita = {};
		try {
			const produtosInativos = [];
			values.produtos?.map((produto) => {
				if (
					produto.produto?.registro?.situacao === 'INATIVO' &&
					!produtosInativos.some((e) => e.id === produto.produto.registro.id)
				) {
					produtosInativos.push(produto.produto.registro);
				}
				return null;
			});
			if (produtosInativos?.length > 0) {
				setItemInativo(produtosInativos);
				throw new Error(TypeErrors.PRODUTO_INATIVO);
			}

			if (!values?.tipoOperacao) {
				pedidoVenda.tipoOperacao = 'SAIDA';
			}
			const isConsumidorFinal = !!(pedidoVenda.pessoa?.registro && pedidoVenda.pessoa?.registro?.consumidorFinal);

			if (pedidoVenda.tipo === 'ORCAMENTO' && pedidoVenda.tipoOperacao === 'SAIDA' && isConsumidorFinal) {
				await asyncGetOperacoesFiscaisParaVenda(
					{ tipoOperacao: pedidoVenda.tipoOperacao, tipoCliente: isConsumidorFinal },
					({ data }) => {
						const operacaoFiscal = data?.content[0];
						operacaoFiscalFavorita = {
							label: `${operacaoFiscal?.codigo} - ${operacaoFiscal?.descricao}`,
							registro: operacaoFiscal,
							value: operacaoFiscal?.id,
						};
					}
				);
				if (operacaoFiscalFavorita.registro) {
					pedidoVenda.operacaoFiscal = operacaoFiscalFavorita;
				}
			} else if (!pedidoVenda.operacaoFiscal) {
				await asyncGetOperacaoFiscalFavorita('SAIDA', ({ data }) => {
					const operacaoFiscal = data;
					operacaoFiscalFavorita = {
						label: `${operacaoFiscal.codigo} - ${operacaoFiscal.descricao}`,
						registro: operacaoFiscal,
						value: operacaoFiscal.id,
					};
				});

				if (operacaoFiscalFavorita.registro) {
					pedidoVenda.operacaoFiscal = operacaoFiscalFavorita;
				}
			}

			if (pedidoVenda.produtos) {
				pedidoVenda.produtos = await pedidoVenda.produtos?.map((produto) => {
					const produtoLocal = { ...produto };
					produtoLocal.operacaoFiscal = pedidoVenda.operacaoFiscal;
					produtoLocal.cfop = {};
					return produtoLocal;
				});
			}

			if (pedidoVenda.pessoa) {
				await montarECalcularTributacaoTodosProdutos(pedidoVenda.produtos, pedidoVenda.pessoa, (produtos) => {
					pedidoVenda.produtos = [...produtos];
				});
			}

			handleSubmit();
			await validateForm();

			if (isValid) {
				if (values) {
					atualizarUrl(history, `/pedido_venda/cadastro`, null, metodosAtualizarUrl.PUSH);
					const pedidoVendaDeOrcamento = converterParaGerarVenda(pedidoVenda);
					const promise = buscarProximoNumero('pedidos_venda', (numero) => {
						pedidoVendaDeOrcamento.numero = numero;
					});
					promise.then(() => {
						setValues(pedidoVendaDeOrcamento);
						setAtualizarTotal(true);
					});
				}
			}
			await validateForm();
			setIsGerarVenda(true);
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, orçamento contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	function montarNomeItem() {
		function possuiVirgula(index) {
			if (itemInativo?.length > 1) {
				return index < itemInativo.length - 1 ? ', ' : '';
			} else {
				return '';
			}
		}
		if (itemInativo) {
			return (
				<span>
					{itemInativo?.length > 1 ? 'Itens ' : 'Item '}
					<span style={{ fontWeight: 'bold' }}>
						{itemInativo?.map((item, index) => {
							`${item.codigo} - ${item.nome}${possuiVirgula(index)}`;
							return null;
						})}
					</span>
					{itemInativo?.length > 1
						? ' estão inativos no cadastro de produtos'
						: ' está inativo no cadastro de produtos'}
				</span>
			);
		}
		return null;
	}

	async function buscarTabelaPreco(dados, onSuccess) {
		function onSuccessWrapper(request) {
			const arrayProdutos = values.produtos.filter((item) => item.produto);
			if (
				!isFirstRender &&
				!dados.isCancelarVenda &&
				values.informacoesComplementares?.tabelaPreco &&
				arrayProdutos?.length > 0 &&
				values.informacoesComplementares?.tabelaPreco?.value !== request?.data?.id
			) {
				confirm(
					'Confirmação',
					'Nova tabela de preço reconhecida. Ao atualizá-la os produtos serão removidos. Deseja atualizar a tabela de preço?',
					() => {
						setFieldValue('produtos', [{ ...copiarObjeto(NOVO_PRODUTO_VENDA), id: gerarUUID() }]);
						onSuccess(request);
					}
				);
			} else if (typeof onSuccess === 'function') {
				onSuccess(request);
			}
		}

		if (utilizaTabelaPreco) {
			await asyncBuscarTabelaPreco(urlVendaOuOrcamento, dados, onSuccessWrapper);
		}
	}

	function buscarMunicipioIdPessoa(pessoa) {
		let municipioId = null;
		if (pessoa) {
			const indexEnderecoFavorito = pessoa.registro?.enderecos?.findIndex((endereco) => endereco.favorito);
			if (indexEnderecoFavorito >= 0) {
				municipioId = pessoa.registro?.enderecos[indexEnderecoFavorito]?.municipio?.id;
			} else if (indexEnderecoFavorito === -1 && pessoa.registro?.enderecos?.length > 0) {
				municipioId = pessoa.registro?.enderecos[0]?.municipio?.id;
			}
		}

		return municipioId;
	}

	function buscarVendaStatusCard() {
		if (
			values.status === Status.FINALIZADO &&
			(values.situacaoDevolucao === SITUACAO_DEVOLUCAO.PARCIALMENTE_DEVOLVIDO ||
				values.situacaoDevolucao === SITUACAO_DEVOLUCAO.DEVOLVIDO)
		) {
			return values.situacaoDevolucao;
		}

		return values.status;
	}

	function buscarColorsPeloStatus() {
		const colors =
			values.status === Status.FINALIZADO && colorsStatus[values.situacaoDevolucao]
				? colorsStatus[values.situacaoDevolucao]
				: colorsStatus[values.status];

		return {
			lightColor: colors.lightColor,
			strongColor: colors.strongColor,
		};
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Tutorial
				steps={isVenda() ? tutorialStepsPedidoVenda : tutorialStepsOrcamento}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form
				header={
					<HeaderForm
						isVenda={isVenda}
						isMobile={isMobile}
						setModalEnviarPorEmailVisible={setModalEnviarPorEmailVisible}
						setModalEnviarPorWhatsappVisible={setModalEnviarPorWhatsappVisible}
						urlVendaOuOrcamento={urlVendaOuOrcamento}
						indetificacaoOrcamento={values.numero}
					/>
				}
				className="card-default screen-max-width"
			>
				<FormActions className="screen-max-width">
					<ActionButtons
						match={props.match}
						history={history}
						duplicarVenda={duplicarVenda}
						asyncSelectRegistro={asyncSelectRegistro}
						novo={novo}
						acessarNfe={acessarNfe}
						acessarNfce={acessarNfce}
						podeInserir={podeInserir}
						setTabPagamentoSelecionada={setTabPagamentoSelecionada}
						setModalHistoricoVisible={setModalHistoricoVisible}
						setModalMotivoCancelamentoVisible={setModalMotivoCancelamentoVisible}
						setModalDevolverItens={setModalDevolverItens}
						setModalItensDevolvidos={setModalItensDevolvidos}
						informacoesPermissoes={informacoesPermissoes}
						existeParcelaRecebida={existeParcelaRecebida}
						urlVendaOuOrcamento={urlVendaOuOrcamento}
						moduloUtilizado={vendaOuOrcamento}
						setAtualizarImpostosProdutos={setAtualizarImpostosProdutos}
						isVenda={isVenda}
						totalizadores={totalizadores}
						setPodeEditar={setPodeEditar}
						setPodeInserir={setPodeInserir}
						setPodeExcluir={setPodeExcluir}
						generateVenda={generateVenda}
						credencial={credencial}
						erroProdutoInativo={(item) => setItemInativo(item)}
						atualizarParcelas={(parcelas) => {
							setFieldValue('parcelas', parcelas);
						}}
						setAtualizarTotal={setAtualizarTotal}
						isMobile={isMobile}
						setTotalizadores={setTotalizadores}
					/>
				</FormActions>
				<FormContent>
					<If test={itemInativo}>
						<Message colStyle={{ padding: '0px' }} severity="error" text={montarNomeItem()} />
					</If>
					<ValidateMessages
						existeParcelaRecebida={existeParcelaRecebida}
						credencial={credencial}
						history={history}
						acessarNfe={acessarNfe}
					/>
					<Grid
						className="step-venda-e-orcamento-status"
						justifyBetween
						verticalAlignCenter
						style={{ margin: '1rem 0px 0px 0px', display: isMobile ? 'block' : 'flex' }}
					>
						<VendaNumeroCard isMobile={isMobile} numero={values.numero} status={values.status} />
						<Col sm="12" md="6" lg="4" xl="4" style={{ padding: '0px' }}>
							<VendaStatusCard
								status={buscarVendaStatusCard()}
								backgroundColor={values.status ? buscarColorsPeloStatus().lightColor : null}
								color={values.status ? buscarColorsPeloStatus().strongColor : null}
							/>
						</Col>
					</Grid>
					<Grid>
						<Col>
							<Fieldset className="fieldset" style={{ padding: '0rem' }}>
								<HeaderVendas
									moduloUtilizado={vendaOuOrcamento}
									tipoPessoa={TipoPessoa.CLIENTE}
									informacoesPermissoes={informacoesPermissoes}
									setAtualizarOperacaoFiscalProdutos={setAtualizarOperacaoFiscalProdutos}
									setControlMessageProdutos={setControlMessageProdutos}
									setAtualizarImpostosProdutos={setAtualizarImpostosProdutos}
									buscarTabelaPreco={buscarTabelaPreco}
									buscarMunicipioIdPessoa={buscarMunicipioIdPessoa}
								/>
							</Fieldset>
						</Col>
						<Divider
							label="Produtos"
							styleContainer={{ margin: '0rem 0.5rem' }}
							styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
						/>
						<Col>
							<BodyVendas
								moduloUtilizado={vendaOuOrcamento}
								informacoesPermissoes={informacoesPermissoes}
								precoVendaOuAtacado={precoVendaOuAtacado}
								setAtualizarTotal={setAtualizarTotal}
								atualizarTotal={atualizarTotal}
								existeParcelaRecebida={existeParcelaRecebida}
								setExisteParcelaRecebida={setExisteParcelaRecebida}
								tabPagamentoSelecionada={tabPagamentoSelecionada}
								setTabPagamentoSelecionada={setTabPagamentoSelecionada}
								controlMessageProdutos={controlMessageProdutos}
								setControlMessageProdutos={setControlMessageProdutos}
								urlModuloUtilizado={urlVendaOuOrcamento}
								operacaoFiscalHeader={values.operacaoFiscal}
								comissaoHeader={values.informacoesComplementares?.vendedor?.registro?.comissao}
								descontoMaximoVendedor={values.informacoesComplementares?.vendedor?.registro?.descontoMaximo}
								descontoMaximoSuperior={values.informacoesComplementares?.vendedor?.registro?.descontoMaximoSuperior}
								totalizadores={totalizadores}
								setTotalizadores={setTotalizadores}
								isFirstRender={isFirstRender}
								isMobile={isMobile}
								isTablet={isTablet}
								favoritosPagamento={favoritosPagamento}
								podeEditar={podeEditarParaPagamentos}
								buscarTabelaPreco={buscarTabelaPreco}
								buscarMunicipioIdPessoa={buscarMunicipioIdPessoa}
							/>
						</Col>
					</Grid>
				</FormContent>
			</Form>
			<If test={modalMotivoCancelamentoVisible}>
				<ModalCancelarVenda
					valuesVenda={values}
					visible={modalMotivoCancelamentoVisible}
					onHide={() => setModalMotivoCancelamentoVisible(false)}
					urlVendaOuOrcamento={urlVendaOuOrcamento}
					resetFormForm={resetForm}
				/>
			</If>
			<If test={modalHistoricoVisible}>
				<VendasHistorico
					tipo={values.tipo}
					idVenda={values.id}
					visible={modalHistoricoVisible}
					urlVendaOuOrcamento={urlVendaOuOrcamento}
					onHide={() => setModalHistoricoVisible(false)}
					vendaOuOrcamento={vendaOuOrcamento}
				/>
			</If>
			<If test={modalEnviarPorEmailVisible}>
				<ModalCompartilharPorEmail
					idVenda={values.id}
					idPessoa={values.pessoa && values.pessoa.value}
					numero={values.numero}
					statusVenda={values.status}
					urlVendaOuOrcamento={urlVendaOuOrcamento}
					visible={modalEnviarPorEmailVisible}
					onHide={() => setModalEnviarPorEmailVisible(false)}
				/>
			</If>
			<If test={modalEnviarPorWhatsappVisible}>
				<ModalCompartilharPorWhatsapp
					idVenda={values.id}
					idPessoa={values.pessoa && values.pessoa.value}
					cliente={values.pessoa}
					numero={values.numero}
					statusVenda={values.status}
					urlVendaOuOrcamento={urlVendaOuOrcamento}
					visible={modalEnviarPorWhatsappVisible}
					isVenda={isVenda()}
					onHide={() => setModalEnviarPorWhatsappVisible(false)}
				/>
			</If>
			<If test={modalDevolverItens}>
				<ModalDevolverItens
					produtos={values.produtos}
					vendaId={values.id}
					visible={modalDevolverItens}
					onHide={() => {
						setModalDevolverItens(false);
						asyncSelectRegistro(values.id);
					}}
					isMobile={isMobile}
					isTablet={isTablet}
				/>
			</If>
			<If test={modalItensDevolvidos}>
				<ModalItensDevolvidos
					produtosPedido={values.produtos}
					vendaId={values.id}
					visible={modalItensDevolvidos}
					onHide={() => {
						setModalItensDevolvidos(false);
					}}
					isMobile={isMobile}
				/>
			</If>
		</>
	);
}

const PedidoVendasOrcamentosForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};
		const errorsProdutos = validateProdutos(
			values.produtos,
			values.tipo,
			values.informacoesComplementares?.tabelaPreco
		);
		const errorsPagamentos = validatePagamentos(values.pagamentos, values.totalizadores?.totalLiquido);

		if (values.tipo !== 'ORCAMENTO') {
			if (!values.pessoa) {
				errors.pessoa = mensagensDeValidacao.OBRIGATORIO;
			}

			if (!values.operacaoFiscal) {
				errors.operacaoFiscal = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		if (!values.dataEmissao || (values.dataEmissao && !isValid(parseISO(values.dataEmissao)))) {
			errors.dataEmissao = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (!isValid(parseISO(values.validade))) {
			errors.validade = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (
			values.validade &&
			format(parseISO(values.validade), 'yyyy-MM-dd') < format(parseISO(values.dataEmissao), 'yyyy-MM-dd')
		) {
			errors.validade = 'Validade deve ser maior que a data de emissão';
		}

		if (values.tipo === 'PEDIDO_VENDA') {
			if (!isValid(parseISO(values.previsaoEntrega))) {
				errors.previsaoEntrega = mensagensDeValidacao.DATA_INVALIDA;
			}

			if (
				values.previsaoEntrega &&
				format(parseISO(values.previsaoEntrega), 'yyyy-MM-dd') < format(parseISO(values.dataEmissao), 'yyyy-MM-dd')
			) {
				errors.previsaoEntrega = 'Previsão deve ser maior que a data de emissão';
			}
		}
		if (errorsProdutos.length > 0) {
			errors.produtos = errorsProdutos;
		}

		if (!values.informacoesComplementares.vendedor) {
			errors.informacoesComplementares = {
				...errors.informacoesComplementares,
				vendedor: mensagensDeValidacao.OBRIGATORIO,
			};
		}

		if (errorsPagamentos.length > 0) {
			errors.pagamentos = errorsPagamentos;
		}

		return errors;
	},
	handleSubmit: () => {},
})(PedidoVendasOrcamentosView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(PedidoVendasOrcamentosForm);
