import propTypes from "prop-types";
import { AiFillCloseCircle } from "react-icons/ai";
import { NotificacaoTotalRegistros, Grid, Col, If } from "../../index";
import { formatarMonetario } from "../../../Common";

const styleCardContent = {
  borderRadius: "5px",
  cursor: "pointer",
  padding: "7px",
  height: "100%",
};

const styleCloseIcon = {
  float: "right",
  margin: "-15px -15px 0px -15px",
  fontSize: "20px",
  backgroundColor: "white",
  borderRadius: "50%",
  color: "#006095",
};

const styleValue = {
  fontSize: "18px",
  fontWeight: "bold",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "end",
  paddingBottom: "0px",
};

function SelectableCard({
  colors,
  title,
  value = 0,
  numberOfElements = 0,
  onSelect,
  name,
  selected,
  helpMessage,
  titleFiltro,
  colNumberOfElements = "6",
  colTitle = "6",
  showValue,
}) {
  const getContentStyle = () => {
    if (selected) {
      return {
        ...styleCardContent,
        border: `2px solid #006095`,
        boxShadow: "2px 4px 10px 0px #999999",
        margin: "-2px",
      };
    }
    return styleCardContent;
  };

  const titleFiltrar = !selected ? titleFiltro : `Clique para limpar o filtro`;
  const valorFormatado = formatarMonetario(value);

  return (
    <div
      className="p-card p-total-list-cards"
      onClick={() => onSelect(name)}
      style={getContentStyle()}
    >
      {selected && <AiFillCloseCircle style={styleCloseIcon} />}
      <If test={showValue}>
        <Grid justifyBetween verticalAlignCenter title={titleFiltrar}>
          <Col col={colNumberOfElements} title={showValue ? "Total de registros" : ""} >              
            <NotificacaoTotalRegistros
              colors={colors}
              style={{ cursor: "default" }}
              numberOfElements={numberOfElements}
            />              
          </Col>
          <Col col={colTitle} style={{ textAlign: "end" }}>
            <span
              title={helpMessage}
              style={{ color: "#424242", fontSize: "14px" }}
            >
              {title}
            </span>
          </Col>
        </Grid>
      </If>
      <If test={!showValue}>
        <Grid justifyBetween verticalAlignCenter title={titleFiltrar}>
          <Col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            col={colTitle} style={{ textAlign: "end" }}
          >
            <span
              title={helpMessage}
              style={{ color: "#424242", fontSize: "14px" }}
            >
              {title}
            </span>
          </Col>
        </Grid>
      </If>
      <Grid>
        <Col
          title={showValue ? valorFormatado : "Total de registros"}
          style={{ ...styleValue, color: colors.strongColor, paddingBottom: !showValue ? "7px" : "0px" }}          
        >
          <If test={showValue}>
            {valorFormatado}
          </If>
          <If test={!showValue}>
            <NotificacaoTotalRegistros
                colors={colors}
                style={{ cursor: "default" }}
                numberOfElements={numberOfElements}
            />
          </If>
        </Col>
      </Grid>
    </div>
  );
}

SelectableCard.defaultProps = {
	showValue: true,
};

SelectableCard.propTypes = {
  selectable: propTypes.bool,
  selected: propTypes.bool,
  showValue: propTypes.bool,
  numberOfElements: propTypes.number.isRequired,
  onSelect: propTypes.func,
  colors: propTypes.object.isRequired,
  title: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
};

export default SelectableCard;
