import { Button, Grid, NenhumRegistroEncontrado, Paginacao } from 'components';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useContextRegiaoVenda } from 'views/cadastros/Regiao/Context';
import { excluirMunicipio } from 'views/cadastros/Regiao/Requests';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

function TabelaMunicipios() {
	const {
		idRegiao,
		podeEditar,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		size,
		setSize,
		totalRecords,
		setControleBuscarMunicipiosPaginados,
	} = useContextRegiaoVenda();
	const { values } = useFormikContext();

	async function removerMunicipio(idMunicipioRegiao) {
		await excluirMunicipio(idRegiao, idMunicipioRegiao, null);
		setControleBuscarMunicipiosPaginados(true);
	}

	function handleChangeOrder(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
		setPage(0);
		setSize(10);
	}

	function handlePageChange(e) {
		setPage(e.page);
		setSize(e.rows);
	}

	function handleClickDeletar(row) {
		removerMunicipio(row.id);
	}

	function renderFieldNome(row) {
		return <span title={row.municipio.nome}>{row.municipio.nome}</span>;
	}

	function renderFieldEstado(row) {
		return (
			<span
				title={row?.municipio?.estado?.nome}
			>{`${row?.municipio?.estado?.nome} - ${row?.municipio?.estado?.sigla}`}</span>
		);
	}

	function renderAcoes(row) {
		let titleExcluir = 'Excluir';
		let disableBtnExcluir = false;

		if (!podeEditar) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		}

		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => handleClickDeletar(row)}
				/>
			</div>
		);
	}

	return (
		<Grid
			className="step-regiao-listagem-municipios"
			style={{ paddingTop: '10px', width: '100%' }}
			justifyCenter
			verticalAlignCenter
		>
			<DataTable
				className="table"
				value={values.municipios}
				responsive
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={handleChangeOrder}
				emptyMessage={<NenhumRegistroEncontrado />}
				style={{ flex: '1', marginLeft: '1rem' }}
			>
				<Column
					header="Nome"
					className="step-listagem-order"
					field="municipio.nome"
					sortable
					body={renderFieldNome}
					style={{ width: '700px' }}
				/>
				<Column
					header="Estado"
					className="step-listagem-order"
					field="municipio.estado.nome"
					sortable
					body={renderFieldEstado}
				/>
				<Column header="Ações" body={renderAcoes} style={{ width: '60px' }} />
			</DataTable>
			<Paginacao totalElements={totalRecords} rows={size} page={page} onPageChange={handlePageChange} />
		</Grid>
	);
}

export default TabelaMunicipios;
