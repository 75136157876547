import { useState } from 'react';
import { If, Modal } from '../../../../components';
import NcmForm from '../Form';

export default function ModalNcmTributacao(props) {
	const [hideBackground, setHideBackground] = useState(false);

	return (
		<Modal
			className={props.visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de NCM"
			visible={props.visible}
			onHide={props.onHide}
			styleModal={{ maxWidth: '1200px' }}
			hideBackground={hideBackground}
		>
			<If test={props.visible}>
				<NcmForm
					hideModal={props.onHide}
					isModal={true}
					history={props.history}
					setHideBackground={setHideBackground}
					ncm={props.ncm}
				/>
			</If>
		</Modal>
	);
}
