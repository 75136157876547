import Card from '../../../components/cards/Card';
import { Button } from 'primereact/button';
import Col from '../../../components/Col';

import ilustracao from './Util/Animation/ilustracao.gif';

const customStyleTitle = {
	fontSize: 48,
	fontWeight: 'bold',
	color: '#006095',
	margin: 0,
};

const customStyleSubTitle = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	fontSize: 24,
	color: '#006095',
	marginBottom: 16,
};

const customStyleText = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	fontSize: 18,
	color: '#006095',
	marginBottom: 40,
};

function PaginaEmConstrucao(props) {
	const { text } = props;

	return (
		<div className="p-panel p-component">
			<Button label="Voltar" onClick={e => window.history.back()} icon="fa fa-arrow-left" />
			<Card style={{ marginTop: '5px' }}>
				<Col
					sm="12"
					md="12"
					lg="12"
					xl="12"
					style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
				>
					<Col sm="12" md="12" lg="12" xl="12" style={{ display: 'flex', justifyContent: 'flex-start' }}>
						<p style={customStyleTitle}>Página em construção!</p>
					</Col>
					<Col sm="12" md="12" lg="12" xl="12" style={{ display: 'flex', justifyContent: 'flex-start' }}>
						<div style={customStyleSubTitle}>
							<p style={{ margin: 0 }}>
								Estamos nos esforçando para trazer muitas novidades para vocês :)
							</p>
						</div>
					</Col>
					<Col sm="12" md="12" lg="12" xl="12" style={{ display: 'flex', justifyContent: 'flex-start' }}>
						<div style={{ ...customStyleText }}>
							<p style={{ margin: 0 }}>{text}</p>
						</div>
					</Col>
				</Col>
				<Col sm="" md="12" lg="12" xl="12" style={{ display: 'flex', justifyContent: 'center' }}>
					<img
						src={ilustracao}
						alt="Ilustração Página em Construção"
						width="320"
						height="300"
						style={{ marginBottom: '24px' }}
					/>
				</Col>
			</Card>
		</div>
	);
}
export default PaginaEmConstrucao;
