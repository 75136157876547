import { del, post, get, put, exibirToast } from '../../../../../Common/Requisicoes';
import { services } from '../../../../../Common/Constantes/api';

export async function asyncDeleteOrganizacao(organizacaoId, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/organizacoes/${organizacaoId}`,
		null,
		exibirToast(onSuccess, 'Organização excluída com sucesso'),
		onError
	);
}

export async function asyncCreateOrganizacao(organizacao, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/organizacoes`,
		organizacao,
		null,
		exibirToast(onSuccess, 'Organização criada com sucesso'),
		onError,
		false
	);
}

export async function asyncUpdateOrganizacao(organizacao, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/organizacoes/${organizacao.id}`,
		organizacao,
		null,
		exibirToast(onSuccess, 'Organização atualizada com sucesso'),
		onError
	);
}

export async function asyncGetOrganizacao(organizacaoId, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/organizacoes/${organizacaoId}`, null, exibirToast(onSuccess), onError);
}
