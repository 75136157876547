import { isValidEmail } from '@brazilian-utils/brazilian-utils';
import { buscarDadosLoginLocalStorage, mensagensDeValidacao } from 'Common';
import Button from 'components/Button';
import ButtonCancelar, { estadosBotaoCancelar } from 'components/Button/ButtonCancelar';
import Form from 'components/Form';
import FormActions from 'components/FormActions';
import FormContent from 'components/FormContent';
import Grid from 'components/Grid';
import If from 'components/If';
import Modal from 'components/Modal';
import { ModalLoadingTransmissao } from 'components/ModalLoadingTransmissao';
import { createEnviarBoletosPorEmail } from 'components/Pagamentos/Requests';
import TextArea from 'components/TextArea/TextArea';
import { notify } from 'components/Toast';
import InputField from 'components/input/InputField';
import { Field, useFormikContext, withFormik } from 'formik';
import { useState } from 'react';
import { validarFormulario } from 'views/Util';
import * as Yup from 'yup';
import { converterBoletosEmailParaApi, initialValue } from './functions';

function ModalEnviarBoletosPorEmailImpl({ visible, onHide, boletosIds }) {
	const { values, setFieldValue, handleSubmit, validateForm } = useFormikContext();

	const [exibirLoadingEnvioEmail, setExibirLoadingEnvioEmail] = useState(false);

	async function onClickEnviarEmail() {
		handleSubmit();

		if (await validarFormulario({ validateForm, values })) {
			setExibirLoadingEnvioEmail(true);
			createEnviarBoletosPorEmail(
				converterBoletosEmailParaApi({ ...values, contaReceberIds: boletosIds }),
				() => {
					notify('E-mail enviado com sucesso');
					setExibirLoadingEnvioEmail(false);
					onHide();
				},
				() => setExibirLoadingEnvioEmail(false)
			);
		}
	}

	function handleChangeTextoEmail(e) {
		setFieldValue('corpoEmail', e.target.value);
	}

	return (
		<Modal header="Enviar boletos por e-mail" visible={visible} onHide={onHide}>
			<Form>
				<FormActions style={{ marginBottom: '10px' }}>
					<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
					<Button label="Enviar por e-mail" icon="fa fa-send" onClick={onClickEnviarEmail} style={{ margin: '5px' }} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Destinatário(s)"
							name="destinatarios"
							helpMessage="Destinatários que irão receber o e-mail, podem ser informados vários e-mails separados por vírgula"
							obrigatorio
							values={values.destinatarios}
							size={255}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Assunto"
							obrigatorio
							name="assunto"
							values={values.assunto}
							helpMessage="Assunto (título) do e-mail que será encaminhado para o(s) destinatário(s)"
							size={255}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={TextArea}
							label="Texto do e-mail"
							obrigatorio
							placeholder="Escreva o texto do e-mail aqui..."
							name="corpoEmail"
							values={values.corpoEmail}
							helpMessage="Texto do e-mail que será encaminhado para o(s) destinatário(s)"
							rows={5}
							onChange={handleChangeTextoEmail}
						/>
					</Grid>
				</FormContent>
				<If test={exibirLoadingEnvioEmail}>
					<ModalLoadingTransmissao
						visible={exibirLoadingEnvioEmail}
						message="Gerando impressão e enviando por e-mail..."
					/>
				</If>
			</Form>
		</Modal>
	);
}

const ModalEnviarBoletosPorEmail = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues({ boletos, dadosPessoaParaEmail }) {
		return {
			...initialValue(boletos, dadosPessoaParaEmail, buscarDadosLoginLocalStorage()?.filialConectada),
		};
	},

	validate(values) {
		const errors = {};

		if (values.destinatarios) {
			const arrayDestinatarios = values.destinatarios
				.split(',')
				.map((item) => item.trim())
				.filter((item) => item.trim() !== '');

			arrayDestinatarios.forEach((destinatario) => {
				if (!isValidEmail(destinatario)) {
					errors.destinatarios = `O e-mail ${destinatario} não é válido`;
				}
			});
		} else {
			errors.destinatarios = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		assunto: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		corpoEmail: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalEnviarBoletosPorEmailImpl);

export { ModalEnviarBoletosPorEmail };
