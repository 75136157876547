import { DATA_ACTION_TYPES } from './actions';

export function dataReducer(state, action) {
  switch (action.type) {
    case DATA_ACTION_TYPES.SET_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case DATA_ACTION_TYPES.ADD_NFE: {
      return {
        ...state,
        nfe: {
          ...action.payload,
          content: [...state.nfe.content, ...action.payload.content],
        },
      };
    }
    case DATA_ACTION_TYPES.ADD_PRODUTOS_VENDIDOS: {
      return {
        ...state,
        produtosVendidos: {
          ...action.payload,
          content: [...state.produtosVendidos.content, ...action.payload.content],
        },
      };
    }
    case DATA_ACTION_TYPES.ADD_RANKING_CLIENTES: {
      return {
        ...state,
        rankingClientes: {
          ...action.payload,
          content: [...state.rankingClientes.content, ...action.payload.content],
        },
      };
    }
    case DATA_ACTION_TYPES.ADD_RANKING_VENDEDORES: {
      return {
        ...state,
        rankingVendedores: {
          ...action.payload,
          content: [...state.rankingVendedores.content, ...action.payload.content],
        },
      };
    }
    default:
      return state;
  }
}
