import { ProgressBar } from 'primereact/progressbar';
import { BsGraphUp } from 'react-icons/bs';
import { formatarMonetario } from '../../../../../../../../../Common/Mascara';
import FalhaAoCarregarAsInformacoes from '../../../../../FalhaAoCarregarAsInformacoes';
import { Financas } from '../../../../../../../../Util/constantes';

import '../Styles/index.css';

const messages = {
	helpMessage: 'Valor total de suas receitas cadastradas no período',
};

export default function CardReceitas(props) {
	const { loading, error, receitasTotal, receitasRecebidas, receitasAReceber } = props;

	function montarTituloCard() {
		return (
			<div className="card-resultado-financeiro-dashboard-titulo">
				<div className="card-resultado-financeiro-dashboard-titulo-descricao" title={messages.helpMessage}>
					Receitas no período
				</div>
			</div>
		);
	}

	if (loading) {
		return (
			<div className="card-resultado-financeiro-dashboard">
				{montarTituloCard()}
				<div className="card-resultado-financeiro-dashboard-content">
					<ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />
				</div>
			</div>
		);
	} else if (error) {
		return (
			<div className="card-resultado-financeiro-dashboard">
				{montarTituloCard()}
				<div className="card-resultado-financeiro-dashboard-content">
					<FalhaAoCarregarAsInformacoes />
				</div>
			</div>
		);
	} else {
		return (
			<div className="card-resultado-financeiro-dashboard">
				{montarTituloCard()}
				<div className="card-resultado-financeiro-dashboard-content">
					<div className="card-resultado-financeiro-dashboard-icone">
						<BsGraphUp size={40} color={Financas.cores.verde} />
					</div>
					<div className="card-resultado-financeiro-dashboard-valor">
						<div
							className="card-resultado-financeiro-dashboard-valor-principal"
							title="Valor total a receber (Recebidos + A receber)"
							style={{ color: Financas.cores.verde }}
						>
							{formatarMonetario(receitasTotal)}
						</div>
						<div className="card-resultado-financeiro-dashboard-valor-secundario">
							<span
								className="card-resultado-financeiro-dashboard-valor-secundario-efetuado"
								title="Valor já recebido do montante total"
							>
								<label>Recebidas</label>
								<div>{formatarMonetario(receitasRecebidas)}</div>
							</span>
							<span
								className="card-resultado-financeiro-dashboard-valor-secundario-pendente"
								title="Valor que falta receber do montante total"
							>
								<label>A receber</label>
								<div>{formatarMonetario(receitasAReceber)}</div>
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
