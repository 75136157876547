import { useState } from "react";
import {
  ButtonCancelar,
  ButtonSalvar,
  Dropdown,
  Form,
  FormActions,
  FormContent,
  Modal,
} from "components";

function ModalFilialSetor({ visible, onHide, onConfirm, onCancel, options }) {
  const [values, setValues] = useState({
    filial: options.filiais[0],
    setor: options.setores[0],
  });

  function handleClickConectar() {
    onConfirm(values.filial.value, values.setor.value);
    onHide(values.filial);
  }

  function handleChangeFilial(value) {
    setValues((state) => ({
      ...state,
      filial: value,
    }));
  }

  function handleChangeSetor(value) {
    setValues((state) => ({
      ...state,
      setor: value,
    }));
  }

  return (
    <Modal
      header="Preferências para conectar"
      visible={visible}
      onHide={onHide}
      showCloseIcon={false}
      closeOnEsc={false}
      styleModal={{
        maxWidth: "512px",
      }}
    >
      <Form>
        <FormActions>
          <ButtonCancelar onClick={onCancel} />
          <ButtonSalvar 
            label="Conectar" 
            onClick={handleClickConectar} 
            title="Conectar na organização"
          />
        </FormActions>
        <FormContent>
          <Dropdown
            label="Filial"
            value={values.filial}
            options={options.filiais}
            onChange={handleChangeFilial}
            showClear={false}
          />
          <Dropdown
            label="Setor"
            value={values.setor}
            options={options.setores}
            onChange={handleChangeSetor}
            showClear={false}
          />
        </FormContent>
      </Form>
    </Modal>
  );
}

export { ModalFilialSetor };
