import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { withRouter } from 'react-router';
import NoMatch from '../../views/Util/NoMatch';
import {
	buscarDadosLoginLocalStorage,
	usuarioPossuiPermissao,
	salvarDataUltimoAcessoLocalStorage,
	buscarDataUltimoAcessoLocalStorage,
	fazerLogout,
} from '../../Common/Autenticacao';
import { alert } from '../Toast';
import { salvarItemLocalStorage } from '../../Common/LocalStorage';
import { DADOSLOCALSTORAGE } from '../../Common/Constantes/localStorage';

function PrivateRoute(props) {
	const { recurso, permissao, component } = props;
	const [hash, useHash] = useState(window.location.hash);

	const NotAuthorized = () => {
		return (
			<NoMatch
				errorCode="400"
				title="Não autorizado!"
				message="Você não está autorizado a acessar essa rota, contate o administrador do sistema para mais detalhes."
			/>
		);
	};

	useEffect(() => {
		validarRota();
		validarManterConectado();
	}, []);

	useEffect(() => {
		if (hash !== window.location.hash) {
			validarRota();
			validarManterConectado();
			useHash(window.location.hash);
		}
	});

	function validarRota() {
		const dadosLogin = buscarDadosLoginLocalStorage();

		if (!dadosLogin.access_token) {
			salvarItemLocalStorage(DADOSLOCALSTORAGE.ultimaRotaNaoAutenticada, props.location.pathname);
			window.location = '#/login';
		}
		return dadosLogin.access_token;
	}

	function validarManterConectado() {
		const manterConectado = buscarDadosLoginLocalStorage().manterConectado;
		if (!manterConectado) {
			let ultimoAcesso = buscarDataUltimoAcessoLocalStorage();
			if (ultimoAcesso) {
				let dataUltimoAcesso = Date.parse(ultimoAcesso);
				let dozeHorasAtras = new Date().setHours(new Date().getHours() - 12);
				if (dataUltimoAcesso < dozeHorasAtras) {
					fazerLogout();
					alert('Atenção', 'Você foi desconectado por inatividade, faça login novamente para continuar');
				}
			}
			salvarDataUltimoAcessoLocalStorage(new Date());
		}
	}

	function getComponent() {
		if (!validarRota() || (props.validarPermissoes && !usuarioPossuiPermissao(recurso, permissao))) {
			return NotAuthorized;
		} else {
			return component;
		}
	}

	return (
		<>
			<LastLocationProvider>
				<Route {...props} component={getComponent()} />
			</LastLocationProvider>
		</>
	);
}

PrivateRoute.propTypes = {
	/** Informar se o caminho da url precisa ser exato */
	exact: PropTypes.bool,
	/** Componente que deve ser carregado na url */
	component: PropTypes.any,
	/** Se deve validar as permissões */
	validarPermissoes: PropTypes.bool,
	/** Recurso que o usuário deve possuir */
	recurso: PropTypes.string,
	/** Permissão que o usuário deve possuir */
	permissao: PropTypes.string,
};

PrivateRoute.defaultProps = {
	validarPermissoes: true,
	exact: true,
};

export default withRouter(PrivateRoute);
