import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { useState } from 'react';
import Col from '../../../../../components/Col';
import Grid from '../../../../../components/Grid';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import Modal from '../../../../../components/Modal';

export const tiposEdicoesContasPagar = {
	APENAS_SELECIONADA: 'APENAS_SELECIONADA',
	PROXIMAS_EM_ABERTO: 'PROXIMAS_EM_ABERTO',
	TODAS_EM_ABERTO: 'TODAS_EM_ABERTO',
};

function ModalEdicaoContasPagar({ visible, onHide }) {
	const [value, setValue] = useState(tiposEdicoesContasPagar.APENAS_SELECIONADA);

	function buscarDescricaoEditar(value) {
		if (value === tiposEdicoesContasPagar.APENAS_SELECIONADA) {
			return 'Editar apenas selecionada';
		} else if (value === tiposEdicoesContasPagar.PROXIMAS_EM_ABERTO) {
			return 'Editar próximas em aberto';
		} else {
			return 'Editar todas em aberto';
		}
	}

	return (
		<Modal header="Editar conta a pagar com repetições" visible={visible} onHide={onHide}>
			<AutoProgressBar />
			<p style={{ textAlign: 'justify', marginLeft: '5px' }}>
				Você está tentando editar uma conta a pagar que faz parte de várias repetições. <br /> Quais dessas repetições
				você deseja editar?
			</p>
			<Grid style={{ margin: '20px' }}>
				<Col>
					<RadioButton
						inputId="rbEditar1"
						name="value"
						value={tiposEdicoesContasPagar.APENAS_SELECIONADA}
						onChange={(e) => setValue(e.value)}
						checked={value === tiposEdicoesContasPagar.APENAS_SELECIONADA}
					/>
					<label htmlFor="rbEditar1" className="p-radiobutton-label">
						Editar <b>apenas esta conta a pagar</b>
					</label>
				</Col>
				<Col>
					<RadioButton
						inputId="rbEditar2"
						name="value"
						value={tiposEdicoesContasPagar.PROXIMAS_EM_ABERTO}
						onChange={(e) => setValue(e.value)}
						checked={value === tiposEdicoesContasPagar.PROXIMAS_EM_ABERTO}
					/>
					<label htmlFor="rbEditar2" className="p-radiobutton-label">
						Editar <b>esta conta a pagar e as próximas</b> repetições em aberto
					</label>
				</Col>
				<Col>
					<RadioButton
						inputId="rbEditar3"
						name="value"
						value={tiposEdicoesContasPagar.TODAS_EM_ABERTO}
						onChange={(e) => setValue(e.value)}
						checked={value === tiposEdicoesContasPagar.TODAS_EM_ABERTO}
					/>
					<label htmlFor="rbEditar3" className="p-radiobutton-label">
						Editar <b>esta conta a pagar e todas</b> as repetições em aberto
					</label>
				</Col>
			</Grid>
			<Grid justifyEnd>
				<div>
					<Button className="p-button-secondary" style={{ margin: '5px' }} label="Voltar" onClick={() => onHide()} />
					<Button
						style={{ margin: '5px' }}
						className="p-button-success"
						label={buscarDescricaoEditar(value)}
						onClick={() => onHide(value)}
					/>
				</div>
			</Grid>
		</Modal>
	);
}

export { ModalEdicaoContasPagar };
