import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';

import { Grid, TabView } from 'components';
import { useContextMDFe } from 'views/transporte/MDFe/Context';

import { Ciot } from './components/Ciot';
import { Lacres } from './components/Lacres';
import { ProdutoPredominante } from './components/ProdutoPredominante';
import { Seguradora } from './components/Seguradora';
import { ValePedagio } from './components/ValePedagio';
import { ClassNameTabViewColor } from '../../Util/constantes';

function TabsPrestadorServico() {
	const { values } = useFormikContext();
	const { activeTabServicos, setActiveTabServicos, informacoesPermissoes } = useContextMDFe();

	return (
		<Grid style={{ width: '100%', margin: 0, padding: 0 }}>
			<TabView
				activeIndex={activeTabServicos}
				onTabChange={(event) => {
					setActiveTabServicos(event.index);
				}}
				{...informacoesPermissoes}
				className={`tab-view-no-border ${ClassNameTabViewColor[values.situacao]}`}
			>
				<TabPanel header="Seguradora">
					<Seguradora />
				</TabPanel>
				<TabPanel header="Vale pedágio">
					<ValePedagio />
				</TabPanel>
				<TabPanel header="Lacres">
					<Lacres />
				</TabPanel>
				<TabPanel header="CIOT">
					<Ciot />
				</TabPanel>
				<TabPanel header="Produto predominante">
					<ProdutoPredominante />
				</TabPanel>
			</TabView>
		</Grid>
	);
}

export { TabsPrestadorServico };
