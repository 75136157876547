import { useState } from 'react';
import { useFormikContext, withFormik } from 'formik';
import { connect } from 'react-redux';

import { Modal } from 'components';
import { buscarDadosLoginLocalStorage, copiarObjeto, services } from 'Common';

import ActionButtons from './components/ActionButtons';
import CardsTotalizadores from './components/CardsTotalizadores';
import AlteracaoEmLote from './components/AlteracaoEmLote';
import TabelaTitulos from './components/TabelaTitulos';
import { INITIAL_VALUES } from './Utils/constantes';
import { MessagesValidacoes } from './components/MessagesValidacoes';

function ModalQuitacaoTitulosView({
	isRecebimento,
	visible,
	onHide,
	isMobile,
	isTablet,
	pathUrl,
	informacoesPermissoes,
}) {
	const { values } = useFormikContext();

	const [atualizarTotalizadorTitulos, setAtualizarTotalizadorTitulos] = useState(false);
	const [atualizarTotalizadorAlteracaoEmLote, setAtualizarTotalizadorAlteracaoEmLote] = useState(false);

	const { filialConectada } = buscarDadosLoginLocalStorage();
	const decimaisPreco = filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	const url = `${services.GESTOR}/v1/${pathUrl}`;

	return (
		<Modal
			visible={visible}
			onHide={onHide}
			header={`${isRecebimento ? 'Recebimento' : 'Pagamento'} de títulos`}
			closeOnEsc={false}
		>
			<ActionButtons onHide={onHide} isRecebimento={isRecebimento} informacoesPermissoes={informacoesPermissoes} />
			<MessagesValidacoes />
			<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
				<CardsTotalizadores
					isMobile={isMobile}
					isRecebimento={isRecebimento}
					atualizarTotalizadorTitulos={atualizarTotalizadorTitulos}
					setAtualizarTotalizadorTitulos={setAtualizarTotalizadorTitulos}
				/>
				<AlteracaoEmLote
					url={url}
					setAtualizarTotalizadorTitulos={setAtualizarTotalizadorTitulos}
					informacoesPermissoes={informacoesPermissoes}
					atualizarTotalizadorAlteracaoEmLote={atualizarTotalizadorAlteracaoEmLote}
					setAtualizarTotalizadorAlteracaoEmLote={setAtualizarTotalizadorAlteracaoEmLote}
				/>
				<TabelaTitulos
					registros={values?.titulos}
					isMobile={isMobile}
					isTablet={isTablet}
					isRecebimento={isRecebimento}
					decimaisPreco={decimaisPreco}
					url={url}
					setAtualizarTotalizadorTitulos={setAtualizarTotalizadorTitulos}
					setAtualizarTotalizadorAlteracaoEmLote={setAtualizarTotalizadorAlteracaoEmLote}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</div>
		</Modal>
	);
}

const ModalQuitacaoTitulos = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.titulos) {
			const titulos = props.titulos?.map((titulo) => {
				const valorPendente = getValor();
				function getValor() {
					if (['CONTA_RECEBER', 'VENDA'].includes(titulo.origem)) {
						return titulo.valorAReceber;
					} else {
						return titulo.valorAPagar;
					}
				}

				function montarIdentificadorContasPagar(titulo) {
					let identificador = titulo.descricao || '';

					if (titulo.quantidadeRepeticoes) {
						identificador += identificador
							? ` ${titulo.itemRepeticao}/${titulo.quantidadeRepeticoes}`
							: `${titulo.itemRepeticao}/${titulo.quantidadeRepeticoes}`;
					}

					return identificador;
				}

				return {
					...titulo,
					identificador: props.isRecebimento ? titulo.identificador : montarIdentificadorContasPagar(titulo),
					quitacao: {
						valor: valorPendente,
						juros: 0,
						multa: 0,
						desconto: 0,
						quitado: valorPendente,
						formaPagamento: titulo?.formaPagamento
							? {
									label: titulo?.formaPagamento?.descricao,
									value: titulo?.formaPagamento?.id,
									registro: titulo?.formaPagamento,
								}
							: null,
						conta: titulo?.conta
							? { label: titulo?.conta?.nome, value: titulo?.conta?.id, registro: titulo?.conta }
							: null,
						aplicarTaxa: !!titulo?.taxaValor,
					},
				};
			});
			return { ...copiarObjeto(INITIAL_VALUES), titulos: titulos };
		} else {
			return copiarObjeto(INITIAL_VALUES);
		}
	},

	validate(values) {
		const errors = {};
		const errorsTitulos = [];
		if (values.totais?.quitado < 0) {
			errors.totais = { ...errors.totais, quitado: 'O valor quitado deve ser maior ou igual a zero' };
		}

		if (values.titulos?.length > 0) {
			values.titulos.forEach((titulo, index) => {
				if (!titulo.quitacao?.conta) {
					errorsTitulos[index] = {
						...errorsTitulos[index],
						conta: 'Conta obrigatória',
					};
				}

				if (!titulo.quitacao?.formaPagamento) {
					errorsTitulos[index] = {
						...errorsTitulos[index],
						formaPagamento: 'Forma de pagamento obrigatória',
					};
				}

				if (titulo.quitacao?.quitado < 0) {
					errorsTitulos[index] = {
						...errorsTitulos[index],
						quitado: 'Valor baixado do título deve ser maior ou igual a zero',
					};
				}
			});
		}

		if (errorsTitulos?.length > 0) {
			errors.titulos = errorsTitulos;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalQuitacaoTitulosView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(ModalQuitacaoTitulos);
