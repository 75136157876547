export const helpMessage = {
	dataInicial: 'Data inicial da impressão',
	dataFinal: 'Data final da impressão',
	cliente: 'Pedidos de um cliente específico',
	status: 'Pedidos com um status específico',
};

export const opcoesCusto = [
	{ label: 'Não exibir custo', value: 'NAO_EXIBIR_CUSTO'},
	{ label: 'Último custo', value: 'ULTIMO_CUSTO'},
    { label: 'Custo médio', value: 'CUSTO_MEDIO'},
    { label: 'Preço custo', value: 'PRECO_CUSTO'},       
];