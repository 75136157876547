import { useReducer, useEffect } from 'react';
import { format, parseISO } from 'date-fns';

import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { Button, Col } from 'components';

import { atualizarUrl } from 'views/Util';
import { dataReducer } from './reducer';
import { addDataProdutosVendidos, addDataRankingClientes, addDataRankingVendedores, setData } from './reducer/actions';
import {
	asyncGetNFePorDia,
	asyncGetNFeUltimosDozeMeses,
	asyncGetProdutosMaisVendidos,
	asyncGetRankingClientes,
	asyncGetRankingVendedores,
	asyncGetResultadoPeriodo,
} from './requests';
import { CardsResultado } from '../CardsResultado';
import { CardList } from '../CardList';
import { ChartPorDia } from '../ChartPorDia';
import { ChartUltimosDozeMeses } from '../ChartUltimosDozeMeses';

function DashboardNFCe({ dataInicial, dataFinal, isMobile, history }) {
	const [
		{
			cardsResultado,
			produtosVendidos,
			rankingClientes,
			rankingVendedores,
			NFCePorDia,
			NFCeUltimosDozeMeses,
			loading,
			podeInserirNFCe,
		},
		dispatch,
	] = useReducer(
		dataReducer,
		{
			cardsResultado: {},
			produtosVendidos: {},
			rankingClientes: {},
			rankingVendedores: {},
			NFCePorDia: {},
			NFCeUltimosDozeMeses: {},
			loading: true,
			podeInserirNFCe: false,
		},
		(initialState) => ({
			...initialState,
			podeInserirNFCe: usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.INSERIR),
		})
	);
	const styleCards = isMobile ? { padding: '0.5em 0em', margin: '8px', flex: 'auto' } : { padding: '0.5em' };

	const styleContainerButton = {
		display: 'flex',
		justifyContent: isMobile ? 'center' : 'flex-start',
		padding: isMobile ? '15px 0 0 2px' : '0 0 0 2px',
	};

	const styleButton = {
		margin: isMobile ? '3px 5px' : '3px 7px',
		width: '185px',
	};

	useEffect(() => {
		fetchData();
	}, [dataInicial, dataFinal]);

	async function fetchData() {
		let newData = {
			loading: false,
		};
		const promises = [
			asyncGetResultadoPeriodo(dataInicial, dataFinal, ({ data }) => {
				const { periodoAtual, periodoAnterior } = data;
				const dataCards = {
					pendentes: {
						valor: periodoAtual.valorNfcePendente,
						evolucao: calculateEvolution(periodoAtual.valorNfcePendente, periodoAnterior.valorNfcePendente),
					},
					finalizados: {
						valor: periodoAtual.valorNfceFinalizado,
						evolucao: calculateEvolution(periodoAtual.valorNfceFinalizado, periodoAnterior.valorNfceFinalizado),
					},
					cancelados: {
						valor: periodoAtual.valorNfceCancelada,
						evolucao: calculateEvolution(periodoAtual.valorNfceCancelada, periodoAnterior.valorNfceCancelada),
					},
				};

				newData = {
					...newData,
					cardsResultado: dataCards,
				};
			}),
			asyncGetProdutosMaisVendidos(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					produtosVendidos: data,
				};
			}),
			asyncGetRankingClientes(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingClientes: data,
				};
			}),
			asyncGetRankingVendedores(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingVendedores: data,
				};
			}),
			asyncGetNFePorDia(dataInicial, dataFinal, ({ data }) => {
				const dias = [];
				const pendentes = [];
				const finalizados = [];
				const cancelados = [];

				data.forEach((day) => {
					dias.push(format(parseISO(day.data), 'dd/MM/yyyy'));
					pendentes.push(day.valorNfcePendente);
					finalizados.push(day.valorNfceFinalizado);
					cancelados.push(day.valorNfceCancelada);
				});

				newData = {
					...newData,
					NFCePorDia: { dias, pendentes, finalizados, cancelados },
				};
			}),
			asyncGetNFeUltimosDozeMeses(dataInicial, dataFinal, ({ data }) => {
				const meses = [];
				const pendentes = [];
				const finalizados = [];
				const cancelados = [];

				data.forEach((month) => {
					meses.push(month.competencia);
					pendentes.push(month.valorNfcePendente);
					finalizados.push(month.valorNfceFinalizado);
					cancelados.push(month.valorNfceCancelada);
				});

				newData = {
					...newData,
					NFCeUltimosDozeMeses: {
						meses,
						pendentes,
						finalizados,
						cancelados,
					},
				};
			}),
		];

		await Promise.all(promises).then(() => dispatch(setData(newData)));
	}

	async function handleClickVerMaisProdutosMaisVendidos(page) {
		await asyncGetProdutosMaisVendidos(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataProdutosVendidos(data));
		});
	}

	async function handleClickVerMaisRankingClientes(page) {
		await asyncGetRankingClientes(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingClientes(data));
		});
	}

	async function handleClickVerMaisRankingVendedores(page) {
		await asyncGetRankingVendedores(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingVendedores(data));
		});
	}

	function calculateEvolution(value, oldValue) {
		if (oldValue === 0) {
			return 0;
		}

		return ((value * 100) / oldValue - 100).toFixed(0);
	}

	function handleClickNovaNFCe() {
		atualizarUrl(history, '/nfces/cadastro');
	}

	return (
		<>
			<Col style={styleContainerButton}>
				<Button
					label="Nova NFC-e"
					onClick={handleClickNovaNFCe}
					style={styleButton}
					disabled={!podeInserirNFCe}
					title={!podeInserirNFCe ? 'Você não possui permissão para criar novas notas fiscais de consumidor' : null}
				/>
			</Col>
			<CardsResultado data={cardsResultado} isMobile={isMobile} />
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Produtos mais vendidos"
					helpMessage="Lista dos produtos mais vendidos no período selecionado"
					data={produtosVendidos.content}
					loading={loading}
					visibleButtonVerMais={(produtosVendidos.number + 1) * 10 < produtosVendidos.totalElements}
					onClickVerMais={handleClickVerMaisProdutosMaisVendidos}
					page={produtosVendidos.number}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de clientes"
					helpMessage="Lista de clientes que mais utilizados no período"
					data={rankingClientes.content}
					loading={loading}
					visibleButtonVerMais={(rankingClientes.number + 1) * 10 < rankingClientes.totalElements}
					onClickVerMais={handleClickVerMaisRankingClientes}
					page={rankingClientes.number}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de vendedores"
					helpMessage="Lista de vendedores que mais venderam no período"
					data={rankingVendedores.content}
					loading={loading}
					visibleButtonVerMais={(rankingVendedores.number + 1) * 10 < rankingVendedores.totalElements}
					onClickVerMais={handleClickVerMaisRankingVendedores}
					page={rankingVendedores.number}
				/>
			</Col>
			<Col style={styleCards}>
				<ChartPorDia
					title="Nota fiscal eletrônica de consumidor por dia no período selecionado"
					helpMessage="Lista o valor total de notas fisicais de consumidor por situação no período selecionado"
					data={NFCePorDia}
					loading={loading}
					isMobile={isMobile}
				/>
			</Col>
			<Col style={styleCards}>
				<ChartUltimosDozeMeses
					title="Nota fiscal eletrônica de consumidor nos últimos 12 meses"
					label="Total de notas fisicais de consumidor no mês"
					helpMessage="Lista o valor total de notas fisicais de consumidor agrupadas por mês nos últimos doze meses"
					data={NFCeUltimosDozeMeses}
					loading={loading}
					isMobile={isMobile}
				/>
			</Col>
		</>
	);
}
export { DashboardNFCe };
