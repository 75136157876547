import { Field, useFormikContext, withFormik } from 'formik';

import { copiarObjeto, gerarUUID, mensagensDeValidacao } from 'Common';
import { Dropdown, FormActions, FormContent, Grid, InputDouble, InputField, Modal } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import {
	MDFE_TIPO_PAGAMENTO_COMPONENTE,
	MDFE_TIPO_PAGAMENTO_COMPONENTE_OPTIONS,
} from 'views/transporte/MDFe/Form/Util/constantes';

import { ActionButtons } from './components/ActionButtons';

function ModalComponentesImpl({
	canTransmitir,
	visible,
	indexRegistro,
	componentesList,
	isNovoRegistro,
	setFieldValuePagamento,
	onCloseModal,
}) {
	const { setFieldValue, errors, values } = useFormikContext();
	const { informacoesPermissoes } = useContextMDFe();

	return (
		<Modal
			header={indexRegistro ? 'Cadastro de pagamento' : 'Dados do pagamento'}
			visible={visible}
			onHide={onCloseModal}
			closeOnEsc={false}
		>
			<FormActions>
				<ActionButtons
					setFieldValuePagamento={setFieldValuePagamento}
					indexRegistro={indexRegistro}
					onCloseModal={onCloseModal}
					componentesList={componentesList}
					isNovoRegistro={isNovoRegistro}
					canTransmitir={canTransmitir}
				/>
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="6"
						lg="3"
						xl="3"
						component={Dropdown}
						label="Tipo pagamento"
						name="tipoPagamento"
						showClear={false}
						obrigatorio
						onChange={(e) => setFieldValue('tipoPagamento', e.value)}
						options={MDFE_TIPO_PAGAMENTO_COMPONENTE_OPTIONS}
						errors={errors.tipoPagamento}
						disabled={!canTransmitir}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="3"
						xl="3"
						component={InputDouble}
						label="Valor"
						name="valor"
						prefix="R$ "
						decimalScale={2}
						allowNegative={false}
						size={15}
						errors={errors.valor}
						obrigatorio
						onChange={(e) => setFieldValue('valor', e.target.value)}
						disabled={!canTransmitir}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="12"
						lg="6"
						xl="6"
						component={InputField}
						label="Descrição (Outros)"
						name="descricao"
						size={60}
						errors={errors.descricao}
						onChange={(e) => setFieldValue('descricao', e.target.value)}
						disabled={!canTransmitir || values.tipoPagamento !== MDFE_TIPO_PAGAMENTO_COMPONENTE.OUTROS}
						{...informacoesPermissoes}
					/>
				</Grid>
			</FormContent>
		</Modal>
	);
}

const ModalComponentes = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.componente) {
			return copiarObjeto(props.componente);
		}

		return {
			id: gerarUUID(),
			tipoPagamento: null,
			valor: 0,
			descricao: null,
		};
	},

	validate(values) {
		const errors = {};

		if (!values.tipoPagamento) {
			errors.tipoPagamento = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.valor) {
			errors.valor = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalComponentesImpl);

export { ModalComponentes };
