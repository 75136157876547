import { del, exibirToast, get, post, put, services } from "Common";

export async function asyncGetTributacoesMunicipal(url, onSuccess, onError) {
  return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetTributacaoMunicipal(id, onSuccess, onError) {
  await get(
    `${services.GESTOR}/v1/tributacoes/municipal/${id}`,
    null,
    onSuccess,
    onError
  );
}

export async function asyncDeleteTributacaoMunicipal(id, onSuccess, onError) {
  await del(
    `${services.GESTOR}/v1/tributacoes/municipal/${id}`,
    null,
    exibirToast(onSuccess, "Tributação excluída"),
    onError
  );
}

export async function asyncCreateTributacaoMunicipal(
  tributacao,
  onSuccess,
  onError
) {
  await post(
    `${services.GESTOR}/v1/tributacoes/municipal`,
    tributacao,
    null,
    exibirToast(onSuccess, "Tributação criada"),
    onError
  );
}

export async function asyncUpdateTributacaoMunicipal(
  tributacao,
  onSuccess,
  onError
) {
  await put(
    `${services.GESTOR}/v1/tributacoes/municipal/${tributacao.id}`,
    tributacao,
    null,
    exibirToast(onSuccess, "Tributação atualizada"),
    onError
  );
}
