import {
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	configuracoesUsuario,
	estadosCadastro,
	modulos,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usuarioPossuiModulo,
	usuarioPossuiModulos,
	usuarioPossuiPermissao,
	validaFieldsPorModulo,
	validarUUID,
} from 'Common';
import {
	AutoProgressBar,
	Form,
	FormActions,
	FormContent,
	If,
	Message,
	notify,
	Prompt,
	ToastTypes,
	Tutorial,
	tutorialStepsProduto,
} from 'components';
import { withFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { asyncGetFiliaisDoUsuario } from 'views/configuracao/Usuarios/Form/Requests';
import * as Yup from 'yup';
import { asyncDownloadImagemProduto, asyncGetProduto } from '../Requests';
import { converterProdutoParaFormulario } from '../Util/produtoConverter';
import './Styles/index.css';
import { CADASTRO_URL, INITIAL_VALUES, PESQUISA_URL, styleInativado } from './Util/constantes';
import { fieldsValidate, fieldsValidationSchema } from './Util/validacoes';
import ActionButtons from './components/ActionButtons';
import ModalMovimentacaoEstoque from './components/ModalMovimentacaoEstoque';
import TabsOptions from './components/Tabs';
import FieldsPrincipais from './components/Tabs/FieldsPrincipais';

function ProdutosFormView(props) {
	const { values, isModal, dirty, resetForm, setFieldValue, setValues, tipoPredefinido, history } = props;

	const { id } = values;

	const [ajustarSaldoEmEstoque, setAjustarSaldoEmEstoque] = useState(false);
	const [estoqueSaldoAnterior, setEstoqueSaldoAnterior] = useState(null);
	const [exibirModalEntradaEstoque, setExibirModalEntradaEstoque] = useState(false);
	const [exibirModalSaidaEstoque, setExibirModalSaidaEstoque] = useState(false);
	const [exibirModalReservaEstoque, setExibirModalReservaEstoque] = useState(false);
	const [dadosBase, setDadosBase] = useState(null);
	const [estoqueSaldo, setEstoqueSaldo] = useState(0);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [isNewProduto, setIsNewProduto] = useState(false);
	const [possuiEstoqueEVendas] = useState(
		usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS, modulos.SERVICOS, modulos.COMPRAS])
	);
	const [activeIndex, setActiveIndex] = useState(null);

	const myRef = useRef(null);

	const idURL = props.match?.params.id;
	const isGestor = buscarDadosLoginLocalStorage().organizacao.aplicacao !== 'DOCS_DIGITAIS';

	const podeInserir = usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.EXCLUIR);

	const informacoesPermissoes = {
		estadoCadastro: buscarEstadoCadastro(),
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PRODUTO);

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PRODUTO, false, null, false);
		}

		asyncGetFiliaisDoUsuario(({ data: body }) => {
			const filiaisAcesso = body.content;
			const filiais = [];
			const filialId = buscarDadosLoginLocalStorage().filialConectada.id;

			filiaisAcesso?.forEach((filial) => {
				filiais.push({
					filialId: filial.id,
					filialCodigo: filial.codigo,
					filialNomeApresentacao: filial.nomeApresentacao ?? filial.nome,
					precoCusto: 0,
					custoMedio: 0,
					ultimoCusto: 0,
					fatorConversao: 0,
					operacaoFatorConversao: 'MULTIPLICACAO',
					estoqueMinimo: 0,
					estoqueReservado: 0,
					estoqueSaldo: 0,
					precoVenda: 0,
					precoAtacado: 0,
					filialConectada: filial.id === filialId,
				});
			});
			INITIAL_VALUES.filiais = filiais;

			if (tipoPredefinido) {
				INITIAL_VALUES.tipo = tipoPredefinido;
			} else {
				INITIAL_VALUES.tipo = 'PRODUTO';
			}

			resetForm({ values: INITIAL_VALUES });
		});
	}, []);

	useEffect(() => {
		setFieldValue('possuiModulos', {
			VENDAS: usuarioPossuiModulo(modulos.VENDAS),
		});
		function getSelectedProduto() {
			asyncGetProduto(idURL, async ({ data: produto }) => {
				const produtoConvertido = converterProdutoParaFormulario(produto);
				resetForm({ values: produtoConvertido });
				setDadosBase(produtoConvertido);
				setEstoqueSaldoAnterior(produtoConvertido.estoqueSaldo);
				setEstoqueSaldo(produtoConvertido.estoqueSaldo);

				if (produtoConvertido.imagem) {
					await asyncDownloadImagemProduto(
						produtoConvertido.id,
						({ data: imagem }) => {
							resetForm({
								values: {
									...produtoConvertido,
									imagemFile: imagem,
									imagemUrl: URL.createObjectURL(imagem),
								},
							});
							setDadosBase({
								...produtoConvertido,
								imagemFile: imagem,
								imagemUrl: URL.createObjectURL(imagem),
							});
						},
						() => {
							notify('Não foi possivel fazer download da imagem', ToastTypes.ERROR);
						},
						true,
						false
					);
				}
			});
		}

		if (
			!isModal &&
			validarUUID(idURL) &&
			!exibirModalEntradaEstoque &&
			!exibirModalSaidaEstoque &&
			!exibirModalReservaEstoque
		) {
			if (!isNewProduto) {
				getSelectedProduto();
			}
		}
	}, [isModal, idURL, exibirModalEntradaEstoque, exibirModalSaidaEstoque, exibirModalReservaEstoque, resetForm]);

	useEffect(() => {
		const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PRODUTO);

		if (deveExibirTutorial === false) {
			ajustarSaldoEmEstoque
				? document.getElementById('estoqueSaldo')?.focus()
				: document.getElementById('id-pessoa-nome')?.focus();
		}
	}, [ajustarSaldoEmEstoque]);

	function buscarEstadoCadastro() {
		return id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	function handleTutorialVisible() {
		setTutorialVisible(false);
		document.getElementById('sku').focus();
	}

	function FormatarTextoWarning(errors) {
		return (
			<span>
				{errors.map((erro) => (
					<span key={erro}>
						{erro}
						<br />
					</span>
				))}
			</span>
		);
	}

	return (
		<>
			<Prompt dirty={dirty || ajustarSaldoEmEstoque} isModal={isModal} />

			<Tutorial
				steps={tutorialStepsProduto}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => handleTutorialVisible()}
			/>

			<ModalMovimentacaoEstoque
				values={values}
				myRef={myRef}
				history={history}
				setAjustarSaldoEmEstoque={setAjustarSaldoEmEstoque}
				setExibirModalEntradaEstoque={setExibirModalEntradaEstoque}
				setExibirModalSaidaEstoque={setExibirModalSaidaEstoque}
				setExibirModalReservaEstoque={setExibirModalReservaEstoque}
				exibirModalEntradaEstoque={exibirModalEntradaEstoque}
				exibirModalSaidaEstoque={exibirModalSaidaEstoque}
				exibirModalReservaEstoque={exibirModalReservaEstoque}
			/>

			<Form
				header={isGestor ? 'Cadastro de produto / serviço' : 'Cadastro de produto'}
				isModal={isModal}
				className="card-default screen-max-width"
				style={values.situacao === 'INATIVO' ? styleInativado : null}
			>
				<If test={isModal}>
					<AutoProgressBar />
				</If>
				<If test={dirty && values.errors?.length && props.submitCount > 0}>
					<Message
						severity="error"
						text={values.errors ? FormatarTextoWarning(values.errors) : null}
						colStyle={{ padding: '5px 0px' }}
					/>
				</If>
				<FormActions className="screen-max-width">
					<ActionButtons
						{...props}
						isGestor={isGestor}
						informacoesPermissoes={informacoesPermissoes}
						ajustarSaldoEmEstoque={ajustarSaldoEmEstoque}
						CADASTROURL={CADASTRO_URL}
						initialValue={INITIAL_VALUES}
						setEstoqueSaldoAnterior={setEstoqueSaldoAnterior}
						setAjustarSaldoEmEstoque={setAjustarSaldoEmEstoque}
						setDadosBase={setDadosBase}
						setEstoqueSaldo={setEstoqueSaldo}
						PESQUISAURL={PESQUISA_URL}
						dadosBase={dadosBase}
						setIsNewProduto={setIsNewProduto}
						setActiveIndex={setActiveIndex}
						setValues={setValues}
					/>
				</FormActions>
				<FormContent>
					<FieldsPrincipais
						{...props}
						isGestor={isGestor}
						informacoesPermissoes={informacoesPermissoes}
						setAjustarSaldoEmEstoque={setAjustarSaldoEmEstoque}
						possuiEstoqueEVendas={possuiEstoqueEVendas}
					/>
					<TabsOptions
						{...props}
						isGestor={isGestor}
						estoqueSaldo={estoqueSaldo}
						setEstoqueSaldo={setEstoqueSaldo}
						informacoesPermissoes={informacoesPermissoes}
						myRef={myRef}
						estoqueSaldoAnterior={estoqueSaldoAnterior}
						ajustarSaldoEmEstoque={ajustarSaldoEmEstoque}
						setAjustarSaldoEmEstoque={setAjustarSaldoEmEstoque}
						setEstoqueSaldoAnterior={setEstoqueSaldoAnterior}
						activeIndex={activeIndex}
						setActiveIndex={setActiveIndex}
					/>
				</FormContent>
			</Form>
		</>
	);
}

const ProdutosForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.tipoPredefinido) {
			return {
				...INITIAL_VALUES,
				tipo: props.tipoPredefinido,
			};
		}
		return INITIAL_VALUES;
	},

	validate(values) {
		return fieldsValidate(values);
	},

	validationSchema: Yup.object().shape(validaFieldsPorModulo(fieldsValidationSchema)),
	handleSubmit: () => {},
})(ProdutosFormView);

export default ProdutosForm;
