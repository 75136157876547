export function converterNcmsParaModal(ncm) {
	return {
		id: ncm.id ?? ncm.codigoNcm.id,
		codigoNcm: ncm.codigoNcm,
		descricao: ncm.descricao,
		extensao: ncm.extensao ?? null,
		idNcmCadastrada: ncm.id ?? null,
		situacao: ncm.situacao,
		filial: ncm.filial ?? null,
		tributacoes: ncm.tributacoes ?? null,
	};
}

export function converterNcmParaApi(values) {
	return {
		ncmOrigem: values.ncmOrigem.value,
		replicarTodasNcms: values.replicarTodasNcms,
		listaNcms: converterListaNcmsParaApi(values.listaNcms),
	};
}

function converterListaNcmsParaApi(listaNcms) {
	let listaDeUUIDs = listaNcms.map(ncm => {
		return ncm?.idNcmCadastrada ?? ncm?.id;
	});
	return listaDeUUIDs;
}
