import { mensagensDeValidacao, services } from 'Common';
import {
	AutoProgressBar,
	DropdownMultiSelect,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputDate,
	Modal,
	ModalLoadingTransmissao,
	MultipleSelectSetores,
} from 'components';
import ButtonImprimir from 'components/Button/ButtonImprimir';
import { format, formatISO, isValid as isValidDate, parseISO, startOfMonth } from 'date-fns';
import { Field, useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { MDFE_SITUACAO_OPTIONS } from 'views/transporte/MDFe/Form/Util/constantes';
import { asyncBaixarRelatorioMdfePorPeriodo } from 'views/transporte/MDFe/Requests';

const initialValue = {
	dataInicial: formatISO(startOfMonth(new Date())),
	dataFinal: new Date().toISOString(),
	situacao: [],
	filiaisSelecionadas: [],
	setores: [],
};

function ModalMdfePeriodoView({ visible, onHide }) {
	const { values, setFieldValue, validateForm, handleSubmit, isValid } = useFormikContext();

	const [visibleLoading, setVisibleLoading] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('InputDataInicial')?.focus();
		}, 500);
	}, []);

	async function imprimir() {
		await handleSubmit();
		await validateForm();

		if (isValid) {
			try {
				const dataInicialFormatada = format(parseISO(values.dataInicial), 'yyyy-MM-dd');
				const dataFinalFormatada = format(parseISO(values.dataFinal), 'yyyy-MM-dd');
				let setores = '';
				let filiais = '';
				let situacao = '';
				setVisibleLoading(true);

				if (values.setores) {
					setores = values.setores.map((item) => `'${item.value}'`).join(',');
				}

				if (values.filiaisSelecionadas) {
					filiais = values.filiaisSelecionadas.map((item) => `'${item.value}'`).join(',');
				}

				if (values.situacao) {
					situacao = values.situacao.map((item) => `'${item.value}'`).join(',');
				}

				await asyncBaixarRelatorioMdfePorPeriodo(
					dataInicialFormatada,
					dataFinalFormatada,
					situacao,
					filiais,
					setores,
					({ data: pdf }) => {
						const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						const arquivoURL = URL.createObjectURL(arquivoPDF);
						const relatorio = window.open(arquivoURL);
						if (relatorio) {
							relatorio.onload = () => {
								setTimeout(() => {
									relatorio.document.title = 'Relatório faturamento por período';
								}, 250);
							};
						}
						onHide();
					}
				);
				setVisibleLoading(false);
			} catch (e) {
				console.error(e);
				setVisibleLoading(false);
			}
		}
	}

	return (
		<>
			<Modal header="Relatório de MDF-e por período" onHide={onHide} visible={visible}>
				<AutoProgressBar />
				<Form>
					<FormActions>
						<ButtonImprimir onClick={imprimir} />
					</FormActions>
					<FormContent>
						<Grid>
							<Field
								sm="12"
								md="6"
								lg="3"
								xl="3"
								component={InputDate}
								label="Data inicial"
								name="dataInicial"
								obrigatorio
								value={values.dataInicial}
								touched
								id="InputDataInicial"
							/>
							<Field
								sm="12"
								md="6"
								lg="3"
								xl="3"
								component={InputDate}
								label="Data final"
								name="dataFinal"
								obrigatorio
								value={values.dataFinal}
								touched
							/>
							<Field
								sm="12"
								md="12"
								lg="6"
								xl="6"
								component={DropdownMultiSelect}
								label="Situação"
								name="situacao"
								obrigatorio
								onChange={(e) => setFieldValue('situacao', e)}
								options={MDFE_SITUACAO_OPTIONS}
								value={values.situacao}
								showClear={false}
							/>
							<Field
								sm="12"
								md="12"
								lg="6"
								xl="6"
								component={MultipleSelectSetores}
								label="Setor(es)"
								name="setores"
								obrigatorio
								url={`${services.GESTOR}/v1/mdfes/relacoes/setores`}
								onChange={(e) => setFieldValue('setores', e)}
								value={values.setores}
								showClear={false}
								utilizaFavorito
							/>
						</Grid>
					</FormContent>
				</Form>
			</Modal>
			<ModalLoadingTransmissao visible={visibleLoading} message="Gerando relatório..." />
		</>
	);
}

const ModalMdfePeriodo = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: true,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		const errors = {};

		if (!values.dataInicial || values.dataInicial === '') {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataFinal || values.dataFinal === '') {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.dataInicial && !isValidDate(parseISO(values.dataInicial))) {
			errors.dataInicial = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.dataFinal && !isValidDate(parseISO(values.dataFinal))) {
			errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.situacao.length === 0) {
			errors.situacao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.setores.length === 0) {
			errors.setores = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalMdfePeriodoView);

export { ModalMdfePeriodo };
