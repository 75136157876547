import { useEffect, useState } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { isValidCEP } from '@brazilian-utils/brazilian-utils';

import {
  FormGroup,
  estadosBotaoSalvar,
  estadosBotaoCancelar,
  Grid,
  LayoutMenuSuperior,
  MenuSuperior,
  ButtonNovo,
  ButtonSalvar,
  ButtonCancelar,
  InputField,
  ButtonExcluir,
  Paper,
  Checkbox,
  SingleSelectPais,
  SingleSelectMunicipio,
  InputCep,
  FormActions,
} from 'components';

import { mensagensDeValidacao, estadosCadastro, manterApenasNumeros, gerarUUID } from 'Common';

import { validarFormulario } from '../../../../../../../Util';
import { converterPessoaEnderecoParaApi, converterPessoaEnderecoParaFormulario } from './Util/pessoaEnderecoConverter';
import { helpPessoaEnderecoForm } from './Help';

export const idPaisBrasil = '34e63b2e-c596-f34f-824d-bfd27eb62fa8';

const initialValue = {
  id: '',
  cep: '',
  logradouro: '',
  bairro: '',
  numero: '',
  complemento: '',
  pais: { label: 'Brasil', value: idPaisBrasil },
  municipio: null,
  municipioExterior: null,
  estadoExterior: null,
  favorito: false,
  idTemporario: null,
};

function PessoaEnderecoForm(props) {
  const { values, appendTo } = props;

  const [inserindoNovoRegistro, setInserindoNovoRegistro] = useState(false);

  const informacoesPermissoes = {
    estadoCadastro: inserindoNovoRegistro ? estadosCadastro.INCLUSAO : estadosCadastro.EDICAO,
    podeInserir: props.podeEditar,
    podeEditar: props.podeEditar,
    podeExcluir: props.podeEditar,
  };

  useEffect(() => {
    setInserindoNovoRegistro(!props.registroSelecionado);

    setTimeout(() => {
      if (document.getElementById('pessoaInputCep')) {
        document.getElementById('pessoaInputCep').focus();
      }
    }, 500);
  }, []);

  function novo() {
    props.onNovoClick();
    props.resetForm({ values: initialValue });
    setInserindoNovoRegistro(true);
  }

  function excluir() {
    props.excluirRegistro(props.registroSelecionado);
    props.onHide();
  }

  async function salvar() {
    props.handleSubmit();
    if (await validarFormulario(props)) {
      if (inserindoNovoRegistro) {
        criarRegistro();
      } else {
        atualizarRegistro();
      }
    }
  }

  function criarRegistro() {
    const dadosFormulario = converterPessoaEnderecoParaApi(props.values);
    props.inserirRegistro({
      ...dadosFormulario,
      idTemporario: gerarUUID(),
    });
    props.onHide();
  }

  function atualizarRegistro() {
    const dadosFormulario = converterPessoaEnderecoParaApi(props.values);
    props.atualizarRegistro(dadosFormulario);
    props.onHide();
  }

  function cancelar() {
    if (props.dirty) {
      props.handleReset();
    } else {
      props.onHide();
    }
  }

  function onChangeCep(e) {
    if (e.pesquisou) {
      const { setFieldValue } = props;
      e.bairro && setFieldValue('bairro', e.bairro);
      e.complemento && setFieldValue('complemento', e.complemento);
      e.logradouro && setFieldValue('logradouro', e.logradouro);
      if (e.logradouro && e.logradouro != '') {
        document.getElementById('inputNumeroEndereco').focus();
      } else {
        document.getElementById('inputLogradouroEndereco').focus();
      }
      e.municipio &&
        setFieldValue('municipio', {
          label: e.municipio.nome + ' - ' + e.municipio.estado.sigla,
          registro: { ...e.municipio },
          value: e.municipio.id,
        });
      e.pais && setFieldValue('pais', { label: e.pais.nome, value: e.pais.id });
    } else {
      props.setFieldValue('cep', e.value);
    }
  }

  function onChangePais(e){
    if (idPaisBrasil !== e.value){
      props.setFieldValue('municipio', {value: '8d1f5be6-89b3-479b-9f84-8d9b9a1f0ced',label: 'EXTERIOR - EX'});
      props.setFieldValue('municipioExterior', 'EXTERIOR');
      props.setFieldValue('estadoExterior', 'EX');
    }else{
      props.setFieldValue('municipio', null);
      props.setFieldValue('municipioExterior', null);
      props.setFieldValue('estadoExterior', null);
    }
    props.setFieldValue('pais', e);
  }

  return (
    <>
      <MenuSuperior isModal={true}>
        <FormActions>
          <ButtonCancelar
            {...informacoesPermissoes}
            estadoBotao={props.dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
            onClick={cancelar}
          />
          <ButtonSalvar
            estadoBotao={estadosBotaoSalvar.CONFIRMAR}
            {...informacoesPermissoes}
            disabled={!props.dirty}
            onClick={salvar}
          />
          <ButtonNovo onClick={novo} hidden={inserindoNovoRegistro} {...informacoesPermissoes} />
          <ButtonExcluir
            hidden={inserindoNovoRegistro}
            {...informacoesPermissoes}
            onClick={excluir}
            podeExcluir={props.podeExcluir}
          />
        </FormActions>
      </MenuSuperior>
      <LayoutMenuSuperior isModal={true}>
        <Paper dirty={props.dirty} {...informacoesPermissoes} childsOnly={true}>
          <FormGroup>
            <Grid>
              <Field
                sm="12"
                md="12"
                lg="12"
                xl="12"
                component={Checkbox}
                label="Endereço favorito"
                title="Marque esta opção caso o endereço seja o favorito"
                name="favorito"
                helpMessage={helpPessoaEnderecoForm.favorito}
                onChange={() => props.setFieldValue('favorito', !values.favorito)}
                checked={values.favorito}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="4"
                lg="4"
                xl="4"
                id="pessoaInputCep"
                component={InputCep}
                label="CEP"
                name="cep"
                type="tel"
                helpMessage={helpPessoaEnderecoForm.cep}
                value={values.cep}
                onChange={e => onChangeCep(e)}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="4"
                lg="4"
                xl="4"
                component={InputField}
                type="text"
                label="Logradouro "
                obrigatorio
                helpMessage={helpPessoaEnderecoForm.logradouro}
                name="logradouro"
                id="inputLogradouroEndereco"
                value={values.logradouro}
                size={255}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="4"
                lg="4"
                xl="4"
                component={InputField}
                type="text"
                label="Número"
                size={60}
                helpMessage={helpPessoaEnderecoForm.numero}
                name="numero"
                id="inputNumeroEndereco"
                value={values.numero}
                obrigatorio
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="4"
                lg="4"
                xl="4"
                component={InputField}
                type="text"
                label="Bairro"
                helpMessage={helpPessoaEnderecoForm.bairro}
                size={60}
                name="bairro"
                value={values.bairro}
                obrigatorio
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="4"
                lg="4"
                xl="4"
                component={InputField}
                type="text"
                label="Complemento"
                name="complemento"
                value={values.complemento}
                helpMessage={helpPessoaEnderecoForm.complemento}
                size={255}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="4"
                lg="4"
                xl="4"
                component={SingleSelectPais}
                label="País "
                obrigatorio
                helpMessage={helpPessoaEnderecoForm.pais}
                name="pais"
                onChange={onChangePais}
                value={values.pais}
                menuPortalTarget={appendTo}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="4"
                lg="4"
                xl="4"
                component={SingleSelectMunicipio}
                label="Município "
                name="municipio"
                obrigatorio
                helpMessage={helpPessoaEnderecoForm.municipio}
                onChange={e => props.setFieldValue('municipio', e)}
                value={values.municipio}
                disabled={(values.pais ? values.pais.value : null) !== idPaisBrasil}
                menuPortalTarget={appendTo}
                {...informacoesPermissoes}
              />            
            </Grid>
          </FormGroup>
        </Paper>
      </LayoutMenuSuperior>
    </>
  );
}

PessoaEnderecoForm = withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,

  mapPropsToValues(props) {
    if (props.registroSelecionado) {
      return converterPessoaEnderecoParaFormulario(props.registroSelecionado);
    }
    return { ...initialValue, favorito: props.marcarComoFavorito() };
  },

  validationSchema: Yup.object().shape({
    logradouro: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    bairro: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    numero: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
  }),

  validate(values) {
    let errors = {};
    let pattern = /[^áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑa-zA-Z0-9-,.&\s]/;
    let camposParaValidar = ['logradouro', 'numero', 'bairro', 'complemento', 'cep'];

    Object.entries(values).map(([key, value]) => {
      if (
        typeof value !== 'object' &&
        typeof value !== 'boolean' &&
        camposParaValidar.some(item => key.includes(item))
      ) {
        if (pattern.test(value)) {
          errors[key] = mensagensDeValidacao.CARACTER_ESPECIAL;
        }
      }
    });

    if (values.cep && !isValidCEP(manterApenasNumeros(values.cep))) {
      errors.cep = 'Digite um CEP válido.';
    }

    if (values.pais && values.pais.value === idPaisBrasil) {
      if (!values.municipio) {
        errors.municipio = mensagensDeValidacao.OBRIGATORIO;
      }
    } else {
      if (!values.municipioExterior) {
        errors.municipioExterior = mensagensDeValidacao.OBRIGATORIO;
      }
    }
    return errors;
  },
  handleSubmit: () => {},
})(PessoaEnderecoForm);

export default PessoaEnderecoForm;
