import { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';

import { Grid, InputMoney, SingleSelectCst, typesCst } from 'components';

function PIS({ disableFields, informacoesPermissoes }) {
	const { values, errors, setFieldValue } = useFormikContext();

	const [calcularImposto, setCalcularImposto] = useState(false);

	useEffect(() => {
		if (calcularImposto) {
			setFieldValue('tributos.pis.valor', (values.tributos.pis.valorBase * values.tributos.pis.percentual) / 100);
			setCalcularImposto(false);
		}
	}, [calcularImposto]);

	function onChangeValores(field, event) {
		if (field === 'tributos.pis.valorBase' || field === 'tributos.pis.percentual') {
			setCalcularImposto(true);
		}

		setFieldValue(`${field}`, event);
	}

	return (
		<>
			<Grid>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={SingleSelectCst}
					useFormErrors={false}
					useFormTouched={false}
					errors={errors?.cstPis}
					type={typesCst.CST_PIS_COFINS}
					label="CST PIS"
					name="cstPis"
					value={values.tributos?.pis.cst}
					isClearable={false}
					onChange={(event) => onChangeValores('tributos.pis.cst', event)}
					obrigatorio
					disabled={disableFields}
					{...informacoesPermissoes}
				/>
			</Grid>
			<Grid>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMoney}
					label="BC PIS"
					name="basePis"
					value={values.tributos?.pis.valorBase}
					touched
					allowNegative={false}
					onChange={(event) => onChangeValores('tributos.pis.valorBase', event.target.value)}
					size={13}
					disabled={disableFields}
					{...informacoesPermissoes}
				/>

				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMoney}
					label="PIS %"
					name="percentualPis"
					value={values.tributos?.pis.percentual}
					onChange={(event) => onChangeValores('tributos.pis.percentual', event.target.value)}
					size={8}
					decimalScale={2}
					prefix=""
					suffix="%"
					maxValue={100}
					placeholder="0,00 %"
					disabled={disableFields}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMoney}
					label="Valor PIS"
					name="valorPis"
					value={values.tributos?.pis.valor}
					onChange={(event) => onChangeValores('tributos.pis.valor', event.target.value)}
					touched
					allowNegative={false}
					size={13}
					disabled={disableFields}
					{...informacoesPermissoes}
				/>
			</Grid>
		</>
	);
}

export { PIS };
