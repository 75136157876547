import { useFormikContext } from 'formik';

import { Message } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';

function ErrosTransmissao() {
	const { values } = useFormikContext();
	const { errosTransmissao } = useContextMDFe();

	function montarMensagemTransmissao() {
		let mensagem;

		if (Object.keys(values.erros)?.length > 0) {
			mensagem = <p key={values.erros?.id}>{values.erros?.mensagem}</p>;
		}

		if (Object.keys(errosTransmissao)?.length > 0) {
			mensagem = <p key={errosTransmissao.id}>{errosTransmissao.mensagem}</p>;
		}

		return mensagem;
	}

	return <Message severity="warn" text={montarMensagemTransmissao()} />;
}

export { ErrosTransmissao };
