import CardsTotalizadores from './components/CardsTotalizadores';
import FormInputs from './components/FormInputs';
import TableTitulos from './components/TableTitulos';

function FormTitulosAntigos({
	documentosSelecionados,
	setDocumentosSelecionados,
	calcularTotalReparcelamento,
	origem,
}) {
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<CardsTotalizadores />
			<FormInputs />
			<TableTitulos {...{ documentosSelecionados, setDocumentosSelecionados, calcularTotalReparcelamento, origem }} />
		</div>
	);
}

export default FormTitulosAntigos;
