import { useEffect, useState } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';

import {
  FormGroup,
  estadosBotaoSalvar,
  estadosBotaoCancelar,
  Grid,
  LayoutMenuSuperior,
  MenuSuperior,
  ButtonNovo,
  ButtonSalvar,
  ButtonCancelar,
  InputField,
  ButtonExcluir,
  Checkbox,
  Paper,
  FormActions,
} from 'components';

import { mensagensDeValidacao, estadosCadastro, gerarUUID } from 'Common';

import { converterPessoaCnaeParaApi } from './Util/pessoaEmailConverter';
import { validarFormulario } from '../../../../../../../Util';
import { helpPessoaCnaeForm } from './Help';

const initialValue = {
  id: '',
  codigo: '',
  descricao: '',
  favorito: false,
  idTemporario: null,
};

function PessoaCnaeForm(props) {
  const { values, dirty } = props;

  const [inserindoNovoRegistro, setInserindoNovoRegistro] = useState(false);
  const informacoesPermissoes = {
    estadoCadastro: inserindoNovoRegistro ? estadosCadastro.INCLUSAO : estadosCadastro.EDICAO,
    podeInserir: props.podeEditar,
    podeEditar: props.podeEditar,
    podeExcluir: props.podeEditar,
  };

  useEffect(() => {
    setInserindoNovoRegistro(!props.registroSelecionado);

    setTimeout(() => {
      if (document.getElementById('pessoaInputCnae')) {
        document.getElementById('pessoaInputCnae').focus();
      }
    }, 500);
  }, []);

  function novo() {
    props.onNovoClick();
    props.resetForm({ values: initialValue });
    setInserindoNovoRegistro(true);
  }

  function excluir() {
    props.excluirRegistro(props.registroSelecionado);
    props.onHide();
  }

  async function salvar() {
    props.handleSubmit();
    if (await validarFormulario(props)) {
      if (!props.validarMesmoCnae(props.values)) {
        if (inserindoNovoRegistro) {
          criarRegistro();
        } else {
          atualizarRegistro();
        }
      } else {
        props.setFieldError('codigo', 'Este CNAE já existe');
      }
    }
  }

  function atualizarRegistro() {
    const dadosFormulario = converterPessoaCnaeParaApi(props.values);
    props.atualizarRegistro(dadosFormulario);
    props.onHide();
  }

  function criarRegistro() {
    const dadosFormulario = converterPessoaCnaeParaApi(props.values);
    props.inserirRegistro({ ...dadosFormulario, idTemporario: gerarUUID() });
    props.onHide();
  }

  function cancelar() {
    if (props.dirty) {
      props.handleReset();
    } else {
      props.onHide();
    }
  }

  return (
    <>
      <MenuSuperior isModal={true}>
        <FormActions>
          <ButtonCancelar
            {...informacoesPermissoes}
            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
            onClick={cancelar}
          />
          <ButtonSalvar
            estadoBotao={estadosBotaoSalvar.CONFIRMAR}
            {...informacoesPermissoes}
            disabled={!dirty}
            onClick={salvar}
          />
          <ButtonNovo onClick={novo} hidden={inserindoNovoRegistro} {...informacoesPermissoes} />
          <ButtonExcluir
            hidden={inserindoNovoRegistro}
            {...informacoesPermissoes}
            onClick={excluir}
            podeExcluir={props.podeExcluir}
          />
        </FormActions>
      </MenuSuperior>
      <LayoutMenuSuperior isModal={true}>
        <Paper dirty={dirty} {...informacoesPermissoes} childsOnly={true}>
          <FormGroup>
            <Grid>
              <Field
                sm="12"
                md="12"
                lg="12"
                xl="12"
                component={Checkbox}
                label="CNAE favorito"
                title="Marque esta opção caso o CNAE seja o favorito"
                name="favorito"
                helpMessage={helpPessoaCnaeForm.favorito}
                onChange={() => props.setFieldValue('favorito', !props.values.favorito)}
                checked={values.favorito}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="2"
                lg="2"
                xl="2"
                id="pessoaInputCnae"
                component={InputField}
                type="text"
                label="CNAE"
                obrigatorio
                helpMessage={helpPessoaCnaeForm.cnae}
                name="codigo"
                size={7}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="10"
                lg="10"
                xl="10"
                component={InputField}
                type="text"
                label="Descrição"
                helpMessage={helpPessoaCnaeForm.descricao}
                name="descricao"
                {...informacoesPermissoes}
              />
            </Grid>
          </FormGroup>
        </Paper>
      </LayoutMenuSuperior>
    </>
  );
}

PessoaCnaeForm = withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,

  mapPropsToValues(props) {
    if (props.registroSelecionado) {
      return { ...props.registroSelecionado };
    }
    return { ...initialValue, favorito: props.marcarComoFavorito() };
  },

  validationSchema: Yup.object().shape({
    codigo: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
  }),

  handleSubmit: () => {},
})(PessoaCnaeForm);

export default PessoaCnaeForm;
