import { InternalInputMoneyGroup } from './components/InternalInputMoneyGroup';

export default function InputMoneyGroup(props) {
	const { field, form, ...rest } = props;
	return (
		<>
			<InternalInputMoneyGroup
				{...field}
				{...rest}
				errors={form?.errors[field.name]}
				touched={form?.touched[field.name]}
			/>
		</>
	);
}
