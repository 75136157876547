import { Field } from 'formik';
import { Grid, InputMoney, SingleSelectCst, typesCst } from 'components';

function Cofins(props) {
	const { tributo, setFieldValue, disabled, informacoesPermissoes, isFullScreen } = props;
	return (
		<>
			<Grid>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={SingleSelectCst}
					useFormErrors={false}
					useFormTouched={false}
					errors={props.errors?.cstCofins ?? null}
					touched={props.errors?.cstCofins}
					type={typesCst.CST_PIS_COFINS}
					label="CST COFINS"
					name="cstCofins"
					value={tributo?.cst}
					isClearable={false}
					onChange={(event) => setFieldValue(`produto.tributos.cofins.cst`, event)}
					obrigatorio
					disabled={disabled}
					menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
					{...informacoesPermissoes}
				/>
			</Grid>
			<Grid>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMoney}
					label="BC COFINS"
					name="baseCofins"
					value={tributo?.baseCofins}
					touched
					allowNegative={false}
					onChange={(event) => setFieldValue(`produto.tributos.cofins.baseCofins`, event.target?.value)}
					size={13}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMoney}
					label="COFINS %"
					name="percentualCofins"
					value={tributo?.percentualCofins}
					onChange={(event) => setFieldValue(`produto.tributos.cofins.percentualCofins`, event.target?.value)}
					size={8}
					decimalScale={2}
					prefix=""
					suffix="%"
					maxValue={100}
					placeholder="0,00 %"
					disabled={disabled}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputMoney}
					label="Valor COFINS"
					name="valorCofins"
					value={tributo?.valorCofins}
					touched
					allowNegative={false}
					onChange={(event) => setFieldValue(`produto.tributos.cofins.valorCofins`, event.target?.value)}
					size={13}
					disabled={disabled}
					{...informacoesPermissoes}
				/>
			</Grid>
		</>
	);
}

export default Cofins;
