import { Card, Checkbox, Grid } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextTabelaPreco } from 'views/cadastros/TabelaPreco/Context';

function Configuracoes() {
	const { values, setFieldValue } = useFormikContext();
	const { desabilitarCampos, informacoesPermissoes } = useContextTabelaPreco();

	return (
		<Card>
			<Grid style={{ padding: '8px 0px' }}>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={Checkbox}
					label="Permite editar o preço abaixo do valor da tabela"
					name="editarPrecoAbaixoValorTabela"
					value={values.configuracoes?.editarPrecoAbaixoValorTabela}
					onChange={(e) => setFieldValue('configuracoes.editarPrecoAbaixoValorTabela', e.checked)}
					checked={values.configuracoes?.editarPrecoAbaixoValorTabela}
					disabled={desabilitarCampos}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={Checkbox}
					label="Permite editar o preço acima do valor da tabela"
					name="editarPrecoAcimaValorTabela"
					value={values.configuracoes?.editarPrecoAcimaValorTabela}
					onChange={(e) => setFieldValue('configuracoes.editarPrecoAcimaValorTabela', e.checked)}
					checked={values.configuracoes?.editarPrecoAcimaValorTabela}
					disabled={desabilitarCampos}
					{...informacoesPermissoes}
				/>
			</Grid>
		</Card>
	);
}

export { Configuracoes };
