import { useState } from 'react';
import { useFormikContext } from 'formik';

import { If, Message } from 'components';
import { buscarDadosLoginLocalStorage } from 'Common';

import { atualizarUrl } from 'views/Util';

import { useContextOrdemServico } from '../../../Context';
import { hasProduto, hasServico } from '../../../Util/funtions';

export function OrdemServicoMessages({ history, isFirstRender }) {
	const { values } = useFormikContext();
	const { hasRejeicaoNFSe, hasRejeicaoNFe, hasNFSeGerada, hasNFeGerada, hasNFSeConfigurada, hasNFeConfigurada } =
		useContextOrdemServico();

	const [credencial] = useState(buscarDadosLoginLocalStorage());

	function acessarNFSe() {
		atualizarUrl(history, '/nfses/cadastro', values.nfse?.id);
	}

	function acessarNFe() {
		atualizarUrl(history, '/nfes/cadastro', values.nfe?.id);
	}

	function handleClickAcessarConfiguracoes() {
		history.push({
			pathname: '/configuracoes_gerais',
			state: { configurarSerieNfe: true },
		});
	}

	function createTextMessage() {
		const styleLink = {
			cursor: 'pointer',
			fontStyle: 'italic',
		};

		function formatRejection(string) {
			const lastChar = string?.trim()?.slice(-1);
			return lastChar === '.' ? `${string} ` : `${string}. `;
		}

		const message = hasRejeicaoNFSe ? (
			<>
				<b>{'NFS-e: '}</b>
				{formatRejection(values.nfse?.rejeicao)}
				<b style={styleLink} onClick={acessarNFSe}>
					Clique aqui para acessar
				</b>
				{hasRejeicaoNFe ? (
					<>
						<br />
						<br />
						<b>{'NF-e: '}</b>
						{formatRejection(values.nfe?.rejeicao)}
						<b style={styleLink} onClick={acessarNFe}>
							Clique aqui para acessar
						</b>
					</>
				) : (
					''
				)}
			</>
		) : hasRejeicaoNFe ? (
			<>
				<b>{'NF-e: '}</b>
				{formatRejection(values.nfe?.rejeicao)}
				<b style={styleLink} onClick={acessarNFe}>
					Clique aqui para acessar
				</b>
			</>
		) : (
			''
		);
		return <span>{message}</span>;
	}

	function createTextMessageConfiguracao() {
		return (
			<span>
				{getTextMessageConfiguracao()}
				<div>
					<b style={{ cursor: 'pointer' }} onClick={handleClickAcessarConfiguracoes}>
						Clique aqui para configurar
					</b>
				</div>
			</span>
		);
	}

	function getTextMessageConfiguracao() {
		if (hasServico(values.servicos) && hasNFSeGerada && credencial?.filialConectada?.parametrosFiscalNFSe) {
			if (!credencial?.filialConectada?.parametrosFiscalCertificado) {
				return 'Filial sem certificado digital configurado para transmissão de NFS-e';
			} else if (!credencial.filialConectada.parametrosFiscalNFSe?.serie) {
				return 'Série da NFS-e não configurada';
			}
		}

		if (hasProduto(values.produtos) && hasNFeGerada && credencial?.filialConectada?.parametrosFiscalNFe) {
			if (!credencial?.filialConectada?.parametrosFiscalCertificado) {
				return 'Filial sem certificado digital configurado para transmissão de NF-e';
			} else if (!credencial.filialConectada.parametrosFiscalNFe?.serieNfe) {
				return 'Série da NF-e não configurada';
			}
		}
		return '';
	}

	return (
		<>
			<If test={!isFirstRender && (hasRejeicaoNFSe || hasRejeicaoNFe)}>
				<Message severity="warn" text={createTextMessage()} />
			</If>
			<If test={(hasNFSeGerada && !hasNFSeConfigurada) || (hasNFeGerada && !hasNFeConfigurada)}>
				<Message severity="error" text={createTextMessageConfiguracao()} />
			</If>
		</>
	);
}
