import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	estadosBotaoCancelar,
	estadosBotaoNovo,
} from 'components';
import { useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';

function ActionButtons({ onClickNovo, salvar, cancelar, excluir, inserindoNovoRegistro, disabled }) {
	const { values, dirty } = useFormikContext();
	const { informacoesPermissoes, podeEditarEmpresa } = useContextConfiguracoesGerais();
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	return (
		<>
			<ButtonCancelar
				{...informacoesPermissoes}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={() => cancelar()}
			/>
			<ButtonSalvar {...informacoesPermissoes} disabled={!dirty} onClick={() => salvar()} />

			<ButtonNovo
				onClick={(e) => onClickNovo(e)}
				hidden={!dirty && !values.id}
				disabled={disabled}
				estadoBotao={estadoBotaoNovo}
				podeInserir={podeEditarEmpresa}
			/>
			<ButtonExcluir
				hidden={inserindoNovoRegistro}
				podeExcluir={informacoesPermissoes.podeEditar}
				disabled={disabled}
				onClick={(e) => excluir(e)}
			/>
		</>
	);
}

export { ActionButtons };
