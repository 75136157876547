import { useState } from 'react';
import { useFormikContext } from 'formik';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { ButtonEditarTable, ButtonExcluirTable, ButtonNovo, Card, If, NenhumRegistroEncontrado } from 'components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ModalLacre } from './components/ModalLacre';

function Lacres() {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, isSituacaoFinalMDFe, isMobile } = useContextMDFe();
	const [sortOrder, setSortOrder] = useState(1);
	const [sortField, setSortField] = useState('lacre');
	const [modalLacresVisible, setModalLacresVisible] = useState(false);
	const [indexRegistro, setIndexRegistro] = useState(null);
	const [isNovoRegistro, setIsNovoRegistro] = useState(false);

	function renderFieldNumeroLacre(row) {
		return row?.lacre ?? null;
	}

	function renderAcoes(row) {
		return (
			<div>
				<ButtonEditarTable title="Editar" onClick={() => onEdit(row)} />
				<ButtonExcluirTable onClick={() => onRemove(row)} disabled={isSituacaoFinalMDFe} {...informacoesPermissoes} />
			</div>
		);
	}

	function onEdit(row) {
		setModalLacresVisible(true);
		values?.lacres.forEach((Lacre, index) => {
			if (Lacre.id === row.id) {
				setIndexRegistro(index);
			}
		});
	}

	function onRemove(row) {
		const lacres = values.lacres.filter((Lacre) => Lacre?.id !== row?.id);
		setFieldValue('lacres', lacres);
	}

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	return (
		<>
			<ButtonNovo
				label="Novo Lacre"
				onClick={() => {
					setModalLacresVisible(true);
					setIsNovoRegistro(true);
					setIndexRegistro(values?.lacres?.length ?? 0);
				}}
				disabled={isSituacaoFinalMDFe}
			/>
			<Card>
				<DataTable
					className="table"
					value={values?.lacres}
					sortField={sortField}
					sortOrder={sortOrder}
					onSort={atualizarOrdenacao}
					responsive
					emptyMessage={<NenhumRegistroEncontrado />}
					style={{
						paddingBottom: '16px',
						overflowY: 'auto',
						overflowX: 'hidden',
						maxHeight: isMobile ? '20.6rem' : '13.8rem',
					}}
				>
					<Column field="lacre" header="Número lacre" body={(row) => renderFieldNumeroLacre(row)} sortable />
					<Column
						header="Ações"
						body={renderAcoes}
						style={{
							width: '6rem',
						}}
					/>
				</DataTable>
			</Card>
			<If test={modalLacresVisible}>
				<ModalLacre
					visible={modalLacresVisible}
					indexRegistro={indexRegistro}
					lacre={values.lacres[indexRegistro]}
					lacres={values.lacres}
					isNovoRegistro={isNovoRegistro}
					setFieldValueMDFe={setFieldValue}
					onCloseModal={() => {
						setIndexRegistro(null);
						setModalLacresVisible(false);
						setIsNovoRegistro(false);
					}}
				/>
			</If>
		</>
	);
}

export { Lacres };
