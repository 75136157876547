export const helpOrganizacoesForm = {
    nome: 'Campo destinado à identificação da organização. Representa o nome ou assinatura pelo qual ela é conhecida.',
    modulos: 'Define quais módulos a organização contratou. Por exemplo: caso marcado "Assinatura", ela poderá usar apenas os recursos do módulo de Documento Digitais, ocultando as funcionalidades do restante do sistema.',
    ddi: 'Discagem direta internacional',
    ddd: 'Discagem direta à distância',
    municipioExterior: "Município ou cidade do endereço localizado no exterior",
    estadoExterior: "Estado ou província do endereço localizado no exterior",
    planoIlimitado: "Caso esta opção estiver marcada, a organização terá acesso ao sistema de forma gratuita e vitalícia. Isso é útil para os clientes de teste que não deverão ser cobrados.",
    agenteAutorizado: "O agente que indicou o sistema para a organização. Este agente receberá comissão (percentual está definido em seu cadastro)"
}
