export function converterSaidaEstoqueParaApi(values) {
	return {
		...values,
		dataHora: `${values.data.substring(0,10)}T${values.hora}:00`,
		produto: values.produto
			? {
					id: values.produto?.value ?? null,
					tipo: values.produto?.registro?.tipo ?? null,
					controlarEstoque: values.produto?.registro?.controlarEstoque ?? null,
			  }
			: null,
	};
}
