import { useEffect, useRef, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { TreeTable } from 'primereact/treetable';
import { GiPlainCircle } from 'react-icons/gi';
import { GoBookmark } from 'react-icons/go';
import { Button, Grid, If, NenhumRegistroEncontrado } from '../../../../../components';
import { converterParaTreeTable } from './Util/converter';

const styleButton = {
  borderRadius: '50%',
  padding: '5px',
  width: '30px',
  height: '30px',
  margin: '3px',
};

function TreeTabelaCategorias(props) {
  const { categorias } = props;
  const [exibirCategoriasInativas, setExibirCategoriasInativas] = useState(false);
  const [categoriasTree, setCategoriasTree] = useState([]);
  const [registroSelecionado, setRegistroSelecionado] = useState(null);

  const prevPropsRef = useRef({ props });
  const prevStateRef = useRef(categoriasTree);
  const menuOpcoes = useRef(null);

  useEffect(() => {
    const prevProps = prevPropsRef.current;
    const prevState = prevStateRef.current;
    if (prevProps.categorias !== props.categorias || prevState.exibirCategoriasInativas !== exibirCategoriasInativas) {
      let categoriasFiltradas = [];
      if (exibirCategoriasInativas) {
        categoriasFiltradas = categorias;
      } else {
        categoriasFiltradas = filtrarCategoriasAtivas(categorias);
      }
      setCategoriasTree(converterParaTreeTable(categoriasFiltradas));
    }
  }, [props, exibirCategoriasInativas]);

  function filtrarCategoriasAtivas(categorias) {
    let result = [];

    for (const categoria of categorias) {
      if (categoria.ativa) {
        result.push({
          ...categoria,
          subCategorias: filtrarCategoriasAtivas(categoria.subCategorias),
        });
      }
    }

    return result;
  }

  function renderAcoesField(row) {
    if (isMobile) {
      return acoesMobile(row);
    } else {
      return acoesTelaNormal(row);
    }
  }

  function acoesMobile(row) {
    let data = row.data;

    const acoesDoBotaoMobile = buscarAcoesMobile(data);
    return (
      <span>
        <Button
          color="secondary"
          style={styleButton}
          icon="fa fa-ellipsis-v"
          size="icon"
          disabled={Boolean(acoesDoBotaoMobile.length === 0)}
          title={Boolean(acoesDoBotaoMobile.length === 0) ? 'Nenhuma operação possível para esta categoria' : null}
          onClick={event => {
            menuOpcoes.current.toggle(event);
            setRegistroSelecionado(data);
          }}
        />
      </span>
    );
  }

  function buscarAcoesMobile(registroSelecionado) {
    const { informacoesPermissoes } = props;

    let itens = [];

    if (registroSelecionado) {
      if (registroSelecionado.ativa) {
        itens.push({
          label: 'Inativar',
          icon: 'fa fa-ban',
          command: () => props.inativarCategoria(registroSelecionado),
        });
      } else {
        itens.push({
          label: 'Ativar',
          icon: 'fa fa-check',
          command: () => props.ativarCategoria(registroSelecionado),
        });
      }

      if (informacoesPermissoes.podeVisualizar) {
        itens.push({
          label: 'Editar',
          icon: 'fa fa-pencil',
          command: () => {
            props.selecionarCategoria(registroSelecionado);
            props.exibirModalCategoria();
          },
        });
      }

      if (informacoesPermissoes.podeExcluir) {
        itens.push({
          label: 'Excluir',
          icon: 'fa fa-trash',
          command: () => props.excluirCategoria(registroSelecionado),
        });
      }
    }

    return itens;
  }

  function acoesTelaNormal(row) {
    const { informacoesPermissoes } = props;

    let data = row.data;

    return (
      <div style={{ display: 'flex' }}>
        {data.ativa ? (
          <Button
            style={styleButton}
            className="p-button p-button-secondary"
            icon="fa fa-ban"
            title={
              !informacoesPermissoes.podeEditar
                ? 'Inativar: Você não possui permissão para executar essa ação'
                : 'Inativar'
            }
            disabled={!informacoesPermissoes.podeEditar}
            onClick={() => props.inativarCategoria(data)}
          />
        ) : (
          <Button
            style={styleButton}
            className="p-button p-button-secondary"
            icon="fa fa-check"
            title={
              !informacoesPermissoes.podeEditar ? 'Ativar: Você não possui permissão para executar essa ação' : 'Ativar'
            }
            disabled={!informacoesPermissoes.podeEditar}
            onClick={() => props.ativarCategoria(data)}
          />
        )}
        <Button
          style={styleButton}
          className="p-button p-button-primary"
          icon="fa fa-pencil"
          title={
            !informacoesPermissoes.podeVisualizar
              ? 'Editar: Você não possui permissão para executar essa ação'
              : 'Editar'
          }
          disabled={!informacoesPermissoes.podeVisualizar}
          onClick={() => {
            props.selecionarCategoria(data);
            props.exibirModalCategoria();
          }}
        />
        <Button
          style={styleButton}
          className="p-button p-button-danger"
          icon="fa fa-trash"
          title={
            !informacoesPermissoes.podeExcluir
              ? 'Excluir: Você não possui permissão para executar essa ação'
              : 'Excluir'
          }
          disabled={!informacoesPermissoes.podeExcluir}
          onClick={() => props.excluirCategoria(data)}
        />
      </div>
    );
  }

  function renderNomeField(row) {
    let data = row.data;
    let marginLeftStyle = null;

    const keyLength = row.key.split('-').length;

    if (keyLength > 1) {
      marginLeftStyle = { marginLeft: `${keyLength * 16}px` };
    }

    const stylePrimeiroNivel = keyLength === 1 ? { fontWeight: 'bold', fontSize: '16px' } : null;
    const styleSegundoNivel = keyLength === 2 ? { fontWeight: 'bold' } : null;
    const style = data.ativa ? null : { opacity: '0.50' };
    const texto = data.ativa ? data.nome : data.nome + ' (Inativa)';

    return (
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <span
          style={{
            ...marginLeftStyle,
            ...styleSegundoNivel,
            ...style,
            ...stylePrimeiroNivel,
            marginRight: '5px',
          }}
          title={texto}
        >
          {texto}
        </span>
        {renderFavorita(row)}
      </div>
    );
  }

  function renderFavorita(row) {
    const { tipoCategoria } = props;

    if (tipoCategoria === 'RECEITA') {
      return (
        <>
          {row.data.favoritaReceita ? (
            <GoBookmark size={22} color="#fbc02d" title="Categoria favorita receita" />
          ) : null}
        </>
      );
    } else {
      return (
        <>
          {row.data.favoritaDespesa ? (
            <GoBookmark size={22} color="#fbc02d" title="Categoria favorita despesa" />
          ) : null}
        </>
      );
    }
  }

  function renderCorField(row) {
    let data = row.data;
    const temPai = row.key.split('-').length > 1;
    return (
      <>
        {!temPai ? (
          <GiPlainCircle style={!data.ativa ? { opacity: '0.50' } : null} color={data.aparencia} size={24} />
        ) : null}
      </>
    );
  }

  function onClickExibirContasInativas() {
    setExibirCategoriasInativas(!exibirCategoriasInativas);
  }

  const { isMobile, tabName } = props;

  const exibirTabela = Boolean(categoriasTree.root && categoriasTree.root.length > 0);

  return (
    <>
      <Menu
        model={buscarAcoesMobile(registroSelecionado)}
        popup={true}
        style={{ minWidth: '230px' }}
        ref={menuOpcoes}
      />
      <If test={exibirTabela}>
        <TreeTable
          tableClassName="tree-table-categorias"
          value={categoriasTree.root}
          expandedKeys={categoriasTree.chaves}
          showGridlines={true}
          onToggle={e => null} // precisa passar algo para onToggle senão o expandedKeys não funciona...
          responsive
          header={
            <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '15px 0px' }}>
              <Checkbox
                inputId={`exibitCategoriasCheck-${tabName}`}
                checked={exibirCategoriasInativas}
                name={`exibitCategoriasCheck-${tabName}`}
                onChange={() => onClickExibirContasInativas()}
              />
              <label
                htmlFor={`exibitCategoriasCheck-${tabName}`}
                className="p-checkbox-label p-checkbox-label-categorias"
              >
                Exibir categorias inativas
              </label>
            </span>
          }
        >
          <Column
            header="Cor"
            field="aparencia"
            body={e => renderCorField(e)}
            style={{ width: '40px', textAlign: 'start' }}
          />
          <Column
            header="Nome"
            field="nome"
            body={e => renderNomeField(e)}
            style={{ textAlign: 'justify', wordBreak: 'break-all' }}
            expander
          />
          <Column header="Ações" body={e => renderAcoesField(e)} style={{ width: `${isMobile ? '60' : '135'}px` }} />
        </TreeTable>
      </If>
      <If test={!exibirTabela}>
        <NenhumRegistroEncontrado message="Nenhuma categoria encontrada" />
        <div style={{ color: '#999999', width: '100%' }}>
          <Grid justifyCenter style={{ padding: '15px' }}>
            Clique no botão "Exibir categorias inativas" para visualizar todas as categorias
          </Grid>
        </div>
      </If>
    </>
  );
}

export default TreeTabelaCategorias;
