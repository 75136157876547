import { useEffect, useState } from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { asyncBuscarEmailsPessoa, asyncEnviarVendaPorEmail } from '../../Requests';
import { buscarDadosLoginLocalStorage, mensagensDeValidacao } from '../../../../../../Common';
import {
  ButtonCancelar,
  estadosBotaoCancelar,
  FormActions,
  FormContent,
  Grid,
  InputField,
  AutoProgressBar,
  Modal,
  TextArea,
  Button,
  Checkbox,
  If,
} from '../../../../../../components';

const initialValue = {
  assunto: null,
  conteudo: null,
  destinatarios: null,
  enviarCopia: false,
};

function ModalCompartilharPorEmail(props) {
  const {
    idVenda,
    numero,
    statusVenda,
    idPessoa,
    urlVendaOuOrcamento,
    values,
    setFieldValue,
    validateForm,
    handleSubmit,
    visible,
    onHide,
  } = props;

  const [emailUsuario, setEmailUsuario] = useState(null);

  useEffect(() => {
    const dadosLogin = buscarDadosLoginLocalStorage();

    let assunto = `Pedido de venda Nº ${numero} - ${dadosLogin.filialConectada.nome}`;
    let conteudo = `Olá, segue em anexo as informações do pedido de venda Nº ${numero}`;

    if (urlVendaOuOrcamento === 'orcamentos') {
      assunto = `Orçamento Nº ${numero} - ${dadosLogin.filialConectada.nome}`;
      conteudo = `Olá, segue em anexo as informações do orçamento Nº ${numero}`;
    } else if (statusVenda === 'CANCELADA') {
      assunto = `Negociação cancelada Nº ${numero} - ${dadosLogin.filialConectada.nome}`;
      conteudo = `Olá, segue em anexo as informações do orçamento cancelado Nº ${numero}`;
    }

    setFieldValue('assunto', assunto);
    setFieldValue('conteudo', conteudo);

    if (idPessoa) {
      asyncBuscarEmailsPessoa(urlVendaOuOrcamento, idPessoa, ({ data }) => {
        data.map(e => {
          e.favorito ? setFieldValue('destinatarios', e.email) : null;
        });
      });
    }
    setEmailUsuario(dadosLogin.email);
  }, []);

  async function enviarPorEmail() {
    await handleSubmit();
    await validateForm();

    if (props.isValid && values.destinatarios) {
      let destinatarios = values.destinatarios
        .split(',')
        .map(item => item.trim())
        .filter(item => item.trim() !== '');
      if (values.enviarCopia) {
        destinatarios.push(emailUsuario);
      }

      asyncEnviarVendaPorEmail(urlVendaOuOrcamento, idVenda, { ...values, destinatarios }, () => props.onHide());
    }
  }

  return (
    <Modal header="Enviar por e-mail" visible={visible} onHide={onHide}>
      <AutoProgressBar />
      <Form>
        <FormActions style={{ marginBottom: '10px' }}>
          <ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
          <Button label="Enviar por e-mail" icon="fa fa-send" onClick={enviarPorEmail} style={{ margin: '5px' }} />
        </FormActions>
        <FormContent>
          <Grid>
            <Field
              sm="12"
              md="12"
              lg="12"
              xl="12"
              component={InputField}
              label="Destinatários"
              name="destinatarios"
              helpMessage="Pessoas que irão receber o e-mail, podem ser informados vários e-mails separados por vírgula"
              obrigatorio
              size={255}
            />
            <Field
              sm="12"
              md="12"
              lg="12"
              xl="12"
              component={InputField}
              label="Assunto"
              obrigatorio
              name="assunto"
              helpMessage="Assunto (título) do e-mail que será encaminhado para os destinatários"
              size={255}
            />
            <Field
              sm="12"
              md="12"
              lg="12"
              xl="12"
              component={TextArea}
              label="Texto do e-mail"
              obrigatorio
              placeholder="Escreva o texto do e-mail aqui..."
              name="conteudo"
              helpMessage="Texto do e-mail que será encaminhado para os destinatários"
              rows={5}
              onChange={e => setFieldValue('conteudo', e.target.value)}
            />
            <If test={emailUsuario}>
              <Field
                sm="12"
                md="12"
                lg="12"
                xl="12"
                component={Checkbox}
                label={`Enviar cópia do e-mail para mim (${emailUsuario})`}
                name="enviarCopia"
                helpMessage="Inclui o usuário do sistema na lista de destinatários ao enviar o e-mail"
                onChange={e => setFieldValue('enviarCopia', e.checked)}
                checked={values.enviarCopia}
              />
            </If>
          </Grid>
        </FormContent>
      </Form>
    </Modal>
  );
}

ModalCompartilharPorEmail = withFormik({
  enableReinitialize: true,
  validateOnChange: false,

  mapPropsToValues() {
    return initialValue;
  },

  async validate(values) {
    const errors = {};

    if (values.destinatarios) {
      let destinatarios = values.destinatarios.split(',').map(item => item.trim());
      let schemaEmail = Yup.string().email();

      for (const item of destinatarios) {
        if (item === '') {
          errors.destinatarios = `O e-mail "${item}" não é um e-mail válido`;
          break;
        } else if (!(await schemaEmail.isValid(item))) {
          errors.destinatarios = `O e-mail "${item}" não é um e-mail válido`;
          break;
        }
      }
    }

    return errors;
  },

  validationSchema: Yup.object().shape({
    assunto: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    destinatarios: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    conteudo: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
  }),
  handleSubmit: () => {},
})(ModalCompartilharPorEmail);

export default ModalCompartilharPorEmail;
