import { useCallback, useEffect, useState } from 'react';
import { Field, withFormik } from 'formik';
import Modal from '../../../../../components/Modal';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import Grid from '../../../../../components/Grid';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../components/Button/ButtonSalvar';
import SingleSelectNcm from '../../../../../components/select/SingleSelectNcm';
import { formatarNcm } from '../../../../../Common/Mascara';
import Dropdown from '../../../../../components/select/Dropdown';
import InputNcmDestino from './components/InputNcmDestino';
import Checkbox from '../../../../../components/input/Checkbox';
import Divider from '../../../../../components/Divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';
import ButtonExcluirTable from '../../../../../components/Button/ButtonExcluirTable';
import { confirm } from '../../../../../components/Toast';
import { filtroSelectOptions, mensagemConfirmacao } from './Util/constantes';
import { asyncGetNcmDestino, asyncPostReplicarNcms } from './Requests';
import { converterNcmsParaModal } from './Util/converter';
import './Styles/index.css';
import { mensagensDeValidacao } from '../../../../../Common/Constantes/mensagens';
import { validarFormulario } from '../../../../Util';
import { converterNcmParaApi } from './Util/converter';

const initialValue = {
	ncmOrigem: null,
	filtro: { label: 'Iniciado em', value: 'INICIADO' },
	ncmDestino: '',
	replicarTodasNcms: false,
	listaNcms: [],
	dirty: false,
	error: '',
};

function ModalReplicarTributacaoView(props) {
	const { dirty, visible, onHide, values, setFieldValue, handleSubmit, resetForm } = props;
	const { ncmOrigem, filtro, ncmDestino, replicarTodasNcms, listaNcms } = values;

	const [isTouched, setIsTouched] = useState(true);
	const [urlParametros, setUrlParametros] = useState('');
	const [bloquearCamposQuandoCheckboxMarcado, setBloquearCamposQuandoCheckboxMarcado] = useState(false);

	useEffect(() => {
		if (replicarTodasNcms) {
			setUrlParametros(`?query=situacao==ATIVO;cadastrado==false`);
		} else {
			if (filtro.value === 'IGUAL') {
				setUrlParametros(`?query=situacao==ATIVO;cadastrado==false;(codigo==${ncmDestino})`);
			} else if (filtro.value === 'INICIADO') {
				setUrlParametros(`?query=situacao==ATIVO;cadastrado==false;(codigo=contains=${ncmDestino}*)`);
			}
		}
	}, [replicarTodasNcms, filtro, ncmDestino]);

	useEffect(() => {
		if (props.error !== undefined) {
			setFieldValue('error', props.error);
		}
	}, [props.error]);

	useEffect(() => {
		setFieldValue('dirty', props.dirty);
	}, [props.dirty]);

	async function cancelar() {
		if (dirty) {
			await setFieldValue('listaNcms', []);
			await setFieldValue('error', '');
			await setFieldValue('dirty', false);
			await props.handleReset();
		} else {
			props.onHide();
		}
	}

	async function pesquisar() {
		let existeNaLista = false;
		let listaNcmsTemporaria = [];

		if (ncmDestino) {
			await asyncGetNcmDestino(urlParametros, ({ data: ncms }) => {
				ncms.forEach(ncm => {
					if (!ncmOrigem || ncmOrigem?.value !== ncm.id) {
						listaNcms?.forEach(ncmDaLista => {
							ncmDaLista.codigoNcm?.id === ncm.codigoNcm?.id ? (existeNaLista = true) : null;
						});
						if (!existeNaLista) {
							listaNcmsTemporaria?.push(converterNcmsParaModal(ncm));
						}
					}
					existeNaLista = false;
				});
			});
			if (listaNcmsTemporaria.length > 0) {
				setFieldValue('listaNcms', listaNcmsTemporaria);
				setFieldValue('error', '');
			}
		}
		if (!props.errors) {
			setFieldValue('ncmDestino', '');
		}		
	}

	async function replicar() {
		handleSubmit();
		if ((!values.listaNcms || values.listaNcms?.length === 0) && !values.replicarTodasNcms) {
			setFieldValue('error', 'Lista de NCMs vazia, insira pelo menos uma NCM.');
		} else {
			if (await validarFormulario(props)) {
				let dadosFormulario = converterNcmParaApi(values);
				asyncPostReplicarNcms(dadosFormulario, () => {
					setFieldValue('listaNcms', []),
						setFieldValue('error', ''),
						setBloquearCamposQuandoCheckboxMarcado(false),
						resetForm({ values: initialValue });
				});
			}
		}
	}

	function setNcmOrigem(event) {
		if (event !== null) {
			setFieldValue('ncmOrigem', {
				label: formatarNcm(event.registro.codigo),
				value: event.registro.id,
			});
			setIsTouched(false);
		} else {
			setFieldValue('ncmOrigem', null);
			setIsTouched(true);
		}
	}

	function setReplicarTodasNcms(event) {
		confirm('Confirmação', mensagemConfirmacao, () => {
			setFieldValue('replicarTodasNcms', !replicarTodasNcms);
			setBloquearCamposQuandoCheckboxMarcado(!replicarTodasNcms);
			setFieldValue('ncmDestino', '');
			setFieldValue('listaNcms', []);
		});
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<ButtonExcluirTable onClick={() => onExcluirNcmLista(row)} />
			</div>
		);
	}

	function onExcluirNcmLista(row) {
		let listaAtualizada = listaNcms.filter(ncm => {
			if (ncm.id !== row.id) {
				return ncm;
			}
		});
		setFieldValue('listaNcms', listaAtualizada);
	}

	function renderDescricao(row) {
		if (row.idNcmCadastrada) {
			return '✓ - ' + row.descricao;
		} else {
			return '✕ - ' + row.descricao;
		}
	}

	return (
		<Modal
			header="Replicar tributação em lote"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1200px' }}
			showCloseIcon={true}
			closeOnEsc={false}
		>
			<Form className="card-default screen-max-width">
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={cancelar}
					/>
					<ButtonSalvar
						estadoBotao={estadosBotaoSalvar.SALVAR}
						label="Replicar"
						disabled={!dirty}
						onClick={replicar}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="3"
							label="NCM de origem"
							name="ncmOrigem"
							obrigatorio
							key={ncmOrigem?.value}
							component={SingleSelectNcm}
							value={ncmOrigem}
							onChange={event => setNcmOrigem(event)}
							placeholder={'Selecione a NCM de origem'}
							touched={isTouched}
							useFormErrors={false}
							useFormTouched={false}
							errors={props.errors && props.errors.ncmOrigem ? props.errors.ncmOrigem : null}
							listarTributacoesCadastradas={true}
							listarTributacaoNcm={false}
							disabled={props.ncmOrigemParaModal ? true : false}
						/>

						<Field
							sm="12"
							md="4"
							lg="4"
							xl="2"
							component={Dropdown}
							label="Condição"
							name="filtro"
							value={filtro}
							showClear={false}
							options={filtroSelectOptions}
							onChange={operacaoSelecionada => setFieldValue('filtro', operacaoSelecionada)}
							disabled={bloquearCamposQuandoCheckboxMarcado}
						/>
						<InputNcmDestino
							sm="12"
							md="4"
							lg="4"
							xl="4"
							label="NCM de destino"
							name="ncmDestino"
							onPesquisar={() => pesquisar()}
							value={ncmDestino}
							size={10}
							onChange={value => setFieldValue('ncmDestino', value)}
							disabled={bloquearCamposQuandoCheckboxMarcado}
							errors={props.errors && props.errors.ncmDestino ? props.errors.ncmDestino : null}
							useFormErrors={false}
							useFormTouched={false}
							touched={true}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="3"
							component={Checkbox}
							label="Replicar para todas as NCMs"
							obrigatorio
							colStyle={{ alignItems: 'center', display: 'flex', paddingTop: '1.5rem' }}
							name="replicarTodasNcms"
							checked={replicarTodasNcms}
							onChange={event => setReplicarTodasNcms(event)}
							showClear={false}
						/>
					</Grid>
					<Divider label="NCMs que serão alteradas:" styleLabel={{ fontSize: '20px', fontWeight: '500' }} />
					<DataTable
						className="table modal-replicacao-lote"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={listaNcms}
						emptyMessage={<NenhumRegistroEncontrado />}
						style={{ overflowY: 'scroll', maxHeight: '25rem' }}
					>
						<Column
							field="codigoNcm.codigo"
							header="Código NCM"
							name="codigoNcm"
							body={row => formatarNcm(row.codigoNcm?.codigo)}
							sortable
							style={{
								width: '10rem',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								wordBreak: 'break-all',
							}}
						/>
						<Column
							field="descricao"
							header="Descrição NCM"
							body={row => renderDescricao(row)}
							sortable
							style={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								wordBreak: 'break-all',
							}}
						/>
						<Column body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
					</DataTable>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalReplicarTributacaoNcm = withFormik({
	enableReinitialize: false,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.ncmOrigemParaModal) {
			return { ...initialValue, ncmOrigem: props.ncmOrigemParaModal };
		}
		return initialValue;
	},

	validate(values) {
		let errors = {};
		if (values.dirty) {
			if (!values.ncmOrigem) {
				errors.ncmOrigem = mensagensDeValidacao.OBRIGATORIO;
			}

			if (!values.replicarTodasNcms) {
				if (values.error) {
					errors.ncmDestino = 'Lista de NCMs vazia, insira pelo menos uma NCM.';
				}

				if (values.filtro.value === 'IGUAL' && values.ncmDestino?.length > 0 && values.ncmDestino?.length < 8) {
					errors.ncmDestino = 'Condição "igual a" necessita ter 8 digitos.';
				}
			}
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalReplicarTributacaoView);

export default ModalReplicarTributacaoNcm;
