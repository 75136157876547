import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { buscarDadosLoginLocalStorage } from 'Common';
import { If } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';

import { ErrosParametrosFiscais } from './components/ErrosParametrosFiscais';
import { ErrosTransmissao } from './components/ErrosTransmissao';
import { ErrosValidacoes } from './components/ErrosValidacoes';

function ErrosMessage() {
	const { values, errors } = useFormikContext();
	const {
		showMessageParametrosFiscais,
		setShowMessageParametrosFiscais,
		showMessageTransmissao,
		setShowMessageTransmissao,
		errosTransmissao,
		showMessageValidacoes,
		setShowMessageValidacoes,
	} = useContextMDFe();
	const [mensagemParametroFiscal, setMensagemParametroFiscal] = useState(null);
	const dadosLocais = buscarDadosLoginLocalStorage();

	useEffect(() => {
		handleParametrosFiscais();
		handleErrosTransmissao();
		handleErrosValidacoes();
	}, []);

	useEffect(() => {
		handleParametrosFiscais();
		handleErrosTransmissao();
	}, [errosTransmissao, values.erros]);

	useEffect(() => {
		handleErrosValidacoes();
	}, [errors]);

	function handleParametrosFiscais() {
		if (dadosLocais?.filialConectada?.parametrosFiscalMDFe) {
			if (!dadosLocais?.filialConectada?.parametrosFiscalCertificado) {
				setMensagemParametroFiscal('Filial sem certificado digital configurado para transmissão de MDF-e.');
				setShowMessageParametrosFiscais(true);
			} else if (
				!dadosLocais.filialConectada.parametrosFiscalMDFe?.serie ||
				!dadosLocais.filialConectada.parametrosFiscalMDFe?.numeroInicial
			) {
				setMensagemParametroFiscal('Número inicial ou série inválidos para transmissão de MDF-e.');
				setShowMessageParametrosFiscais(true);
			}
		}
	}

	function handleErrosTransmissao() {
		if (Object.keys(errosTransmissao)?.length > 0) {
			setShowMessageTransmissao(true);
		}

		if (values?.erros && Object.keys(values?.erros)?.length > 0) {
			setShowMessageTransmissao(true);
		}

		if (!Object.keys(errosTransmissao)?.length > 0 && !Object.keys(values?.erros)?.length > 0) {
			setShowMessageTransmissao(false);
		}
	}

	function handleErrosValidacoes() {
		if (
			(errors?.total && Object.keys(errors.total)?.length > 0) ||
			(errors?.ciot && Object.keys(errors.ciot)?.length > 0) ||
			(errors?.produtoPredominante && Object.keys(errors.produtoPredominante)?.length > 0) ||
			errors?.condutores ||
			errors?.seguradoras ||
			errors?.ufsPercurso
		) {
			setShowMessageValidacoes(true);
		} else {
			setShowMessageValidacoes(false);
		}
	}

	return (
		<>
			<If test={showMessageTransmissao}>
				<ErrosTransmissao />
			</If>
			<If test={showMessageParametrosFiscais}>
				<ErrosParametrosFiscais mensagemParametroFiscal={mensagemParametroFiscal} />
			</If>
			<If test={showMessageValidacoes}>
				<ErrosValidacoes />
			</If>
		</>
	);
}

export { ErrosMessage };
