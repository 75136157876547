import {
	DADOSLOCALSTORAGE,
	GESTOR_SERVICE,
	buscarItemLocalStorage,
	mensagensDeValidacao,
	salvarItemLocalStorage,
} from 'Common';
import {
	Button,
	Col,
	Dropdown,
	Grid,
	If,
	InputDouble,
	SingleSelectEtiqueta,
	ToastTypes,
	confirm,
	notify,
} from 'components';
import { FormikProvider, useFormik } from 'formik';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useEffect, useRef } from 'react';
import { AiOutlineSetting } from 'react-icons/ai';
import { FaCogs } from 'react-icons/fa';
import { GoTag } from 'react-icons/go';
import { atualizarUrl, validarFormulario } from 'views/Util';
import * as yup from 'yup';
import { useContextEtiqueta } from '../../Context';
import { asyncGetEtiqueta, asyncPostImprimirEtiquetas, asyncPostPreviewEtiquetas } from '../../Requests';
import { OPTIONS_CONTORNO } from '../../Util/constantes';
import { converterListProdutosToPrint } from '../../Util/converterProdutos';
import { ModalConfiguracoesLocais } from './components/ModalConfiguracoesLocais';

function ActionsButtons({ isMobile, isTablet, history, limparLista }) {
	const { registros, visibleModalConfiguracoesLocais, setVisibleModalConfiguracoesLocais, podeGerar } =
		useContextEtiqueta();
	const panelImprimir = useRef(null);

	const formik = useFormik({
		initialValues: {
			etiqueta: null,
			etiquetaInicial: 1,
			exibirContorno: OPTIONS_CONTORNO.filter((e) => !e.value),
		},
		onSubmit: () => {},
		validateOnChange: false,
		validationSchema: yup.object().shape({
			etiqueta: yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
			etiquetaInicial: yup
				.number()
				.nullable()
				.min(1, 'Valor precisa ser maior que zero')
				.required(mensagensDeValidacao.OBRIGATORIO),
		}),
	});

	useEffect(() => {
		if (podeGerar) {
			getUltimaEtiquetaImpressa();
		}
	}, []);

	function handleClickImprimir(event) {
		panelImprimir.current.show(event);
	}

	function handleClickConfigurar() {
		atualizarUrl(history, '/produtos/etiquetas/configuracao');
	}

	function handleChangeEtiqueta(value) {
		formik.setFieldValue('etiqueta', {
			label: value.label,
			value: value.value,
			registro: value,
		});
	}

	function handleChangeEtiquetaInicial(value) {
		formik.setFieldValue('etiquetaInicial', value.target.value);
	}

	function handleChangeExibirContorno(value) {
		formik.setFieldValue('exibirContorno', value);
	}

	async function handleClickImprimirEtiquetas() {
		if (await GESTOR_SERVICE.isRunning(true)) {
			if ((await GESTOR_SERVICE.validateVersion(true)) && (await validarFormulario(formik))) {
				const etiquetaId = formik.values.etiqueta.value;
				salvarItemLocalStorage(DADOSLOCALSTORAGE.ultimaEtiquetaImpressa, etiquetaId, true);
				await asyncGetEtiqueta(etiquetaId, async ({ data: etiqueta }) => {
					await asyncPostImprimirEtiquetas(
						converterListProdutosToPrint(registros, etiqueta, formik.values),
						() => {
							confirm('Confirmação', 'Deseja limpar a lista de produtos?', limparLista, () => {}, 'Sim', 'Não');
						},
						(response) => {
							const titleError = `Erro ao imprimir ${registros.length > 1 ? 'etiquetas' : 'etiqueta'}`;
							notify(titleError, ToastTypes.ERROR);
							console.error(`${titleError}:`, response.response.data?.erro);
						}
					);
				});
				panelImprimir.current.hide();
			}
		}
	}

	async function handleClickVisualizarEtiquetas() {
		if (await GESTOR_SERVICE.isRunning(true)) {
			if ((await GESTOR_SERVICE.validateVersion(true)) && (await validarFormulario(formik))) {
				const etiquetaId = formik.values.etiqueta.value;
				salvarItemLocalStorage(DADOSLOCALSTORAGE.ultimaEtiquetaImpressa, etiquetaId, true);
				await asyncGetEtiqueta(etiquetaId, async ({ data: etiqueta }) => {
					await asyncPostPreviewEtiquetas(
						converterListProdutosToPrint(registros, etiqueta, formik.values),
						async ({ data: pdf }) => {
							const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
							const arquivoURL = URL.createObjectURL(arquivoPDF);
							const preview = window.open(arquivoURL);
							if (preview) {
								preview.onload = () => {
									setTimeout(() => {
										preview.document.title = 'Preview';
									}, 250);
								};
							}
						},
						(response) => {
							const titleError = `Erro ao gerar ${registros.length > 1 ? 'etiquetas' : 'etiqueta'}`;
							notify(titleError, ToastTypes.ERROR);
							console.error(`${titleError}:`, response.response.data?.erro);
						}
					);
				});
			}
		}
	}

	async function getUltimaEtiquetaImpressa() {
		const ultimaEtiquetaImpressaId = buscarItemLocalStorage(DADOSLOCALSTORAGE.ultimaEtiquetaImpressa, true);

		if (ultimaEtiquetaImpressaId.length) {
			await asyncGetEtiqueta(
				ultimaEtiquetaImpressaId,
				({ data: etiqueta }) => {
					formik.resetForm({
						values: {
							...formik.values,
							etiqueta: {
								label: `${etiqueta.codigo} - ${etiqueta.descricao}`,
								value: etiqueta.id,
								registro: etiqueta,
							},
						},
					});
				},
				() => {},
				false
			);
		}
	}

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div>
					<Button
						className="step-etiqueta-impressao-imprimir"
						label="Imprimir"
						color="success"
						icon={<GoTag size={20} />}
						onClick={handleClickImprimir}
						disabled={!registros.length || !podeGerar}
						style={{
							gap: '10px',
						}}
						title={!podeGerar ? 'Usuário sem permissão para gerar etiquetas' : 'Imprimir ou visualizar etiquetas'}
					/>
					<Button
						className="step-etiqueta-impressao-configuracao"
						label={isMobile ? 'Configurar' : 'Configurar etiquetas'}
						icon={<AiOutlineSetting size={20} />}
						onClick={handleClickConfigurar}
						style={{
							gap: '5px',
						}}
					/>
				</div>
				<span
					className="step-etiqueta-impressao-configuracao-impressora"
					style={{
						margin: '0px 3px 0px 0px',
						cursor: 'pointer',
						alignSelf: 'center',
						display: isMobile || isTablet ? 'none' : 'flex',
					}}
					title="Configurações locais"
					onClick={() => setVisibleModalConfiguracoesLocais(true)}
				>
					<FaCogs size="20px" style={!isMobile && { marginTop: '3px', marginRight: '3px' }} />
				</span>
			</div>
			<OverlayPanel
				ref={panelImprimir}
				style={{
					margin: '0px 8px',
					width: isMobile ? '19rem' : '30rem',
				}}
			>
				<FormikProvider value={formik}>
					<h2 style={{ fontWeight: 'bold', paddingLeft: '8px' }}>Etiqueta</h2>
					<Grid>
						<Col>
							<SingleSelectEtiqueta
								label=""
								value={formik.values.etiqueta}
								name="etiqueta"
								onChange={handleChangeEtiqueta}
								errors={formik.errors?.etiqueta}
								useFormTouched={false}
								touched
								isClearable={false}
							/>
						</Col>
						<Col sm="12" md="6" lg="6" xl="6">
							<InputDouble
								label="Iniciar na etiqueta"
								name="etiquetaInicial"
								value={formik.values.etiquetaInicial}
								onChange={handleChangeEtiquetaInicial}
								errors={formik.errors?.etiquetaInicial}
								useFormTouched={false}
								touched
								allowNegative={false}
								decimalScale={0}
								fixedDecimalScale
							/>
						</Col>
						<Col sm="12" md="6" lg="6" xl="6">
							<Dropdown
								label="Exibir contorno"
								name="exibirContorno"
								value={formik.values.exibirContorno}
								onChange={handleChangeExibirContorno}
								options={OPTIONS_CONTORNO}
								showClear={false}
							/>
						</Col>
						<Col style={{ paddingBottom: '0px' }}>
							<Button
								label={`Visualizar ${registros.length > 1 ? 'etiquetas' : 'etiqueta'}`}
								color="secondary"
								onClick={handleClickVisualizarEtiquetas}
								style={{ width: '100%' }}
							/>
						</Col>
						<Col style={{ paddingBottom: '0px' }}>
							<Button
								label={`Imprimir ${registros.length > 1 ? 'etiquetas' : 'etiqueta'}`}
								color="success"
								onClick={handleClickImprimirEtiquetas}
								style={{ width: '100%' }}
							/>
						</Col>
					</Grid>
				</FormikProvider>
			</OverlayPanel>
			<If test={visibleModalConfiguracoesLocais}>
				<ModalConfiguracoesLocais
					visible={visibleModalConfiguracoesLocais}
					onHide={() => setVisibleModalConfiguracoesLocais(false)}
					isMobile={isMobile}
				/>
			</If>
		</>
	);
}

export { ActionsButtons };
