import { connect } from 'react-redux';
import { InternalSingleSelect } from './components/InternalSingleSelect';

function SingleSelect(props) {
	const { field, form, style, useFormErrors = true, useFormTouched = true, ...rest } = props;
	const useForm = useFormErrors && useFormTouched;
	return (
		<InternalSingleSelect
			{...field}
			{...rest}
			dirty={form ? form?.dirty : props.dirty}
			errors={form && useForm ? form?.errors[field.name] : props.errors ?? form?.errors[field.name]}
			touched={
				form && useForm ? Boolean(form?.touched[field.name]) : props.touched ?? Boolean(form?.touched[field.name])
			}
			onBlur={() => {
				form?.setFieldTouched && form?.setFieldTouched(field.name);
				rest?.onBlur && rest.onBlur();
			}}
			value={props.value}
			placeholder={rest.isTablet ? 'Pesquisar' : props.placeholder ? props.placeholder : 'Digite para pesquisar'}
			style={{
				...style,
				menuPortal: (base) => ({ ...base, zIndex: 99999 }),
			}}
		/>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(SingleSelect);
