import { useState, useEffect } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';

import {
	FormGroup,
	Grid,
	LayoutMenuSuperior,
	MenuSuperior,
	ButtonNovo,
	estadosBotaoNovo,
	ButtonSalvar,
	ButtonCancelar,
	estadosBotaoCancelar,
	InputField,
	ButtonExcluir,
	Paper,
	Checkbox,
	SingleSelectPessoa,
	SingleSelectPessoaEmail,
	Dropdown,
	If,
	InputMask,
} from 'components';

import {
	services,
	mensagensDeValidacao,
	gerarUUID,
	estadosCadastro,
	buscarMascaraTelefone,
	formatarTelefone,
	PAPEL_SIGNATARIOS,
} from 'Common';

import { asyncGetPessoaEmails, asyncGetPessoaTelefones } from '../Requests';
import DocumentoConfirmacaoSignatario from './components/DocumentoConfirmacaoSignatario';
import { asyncBuscaRelacaoPessoaEmails } from '../../../../../../Requests';
import { helpDocumentoSignatarioForm } from './Help';
import { validarFormulario } from '../../../../../../../../Util';
import SingleSelectPessoaTelefone from 'components/select/SingleSelectPessoaTelefone';

const initialValue = {
	id: '',
	pessoa: null,
	email: '',
	nome: '',
	papel: 'PARTE',
	telefone: null,
	pessoaEmail: null,
	pessoaTelefone: null,
	signatarioCadastrado: false,
	confirmacoes: [],
};

function DocumentoSignatariosFormView(props) {
	const {
		dirty,
		informacoesPermissoes,
		resetForm,
		values,
		registroSelecionado,
		atualizarRegistroSelecionado,
		onNovoClick,
		excluirRegistro,
		onHide,
		handleSubmit,
		setFieldValue,
		inserirRegistro,
		handleReset,
		disabled,
		estadoCadastro,
	} = props;

	const pessoaId = values.pessoa ? values.pessoa.value : null;

	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	const podeInserir =
		estadoCadastro === estadosCadastro.INCLUSAO ? informacoesPermissoes.podeInserir : informacoesPermissoes.podeEditar;

	const [inserindoNovoRegistro, setInserindoNovoRegistro] = useState(false);
	const [resetarPesquisaEmails, setResetarPesquisaEmails] = useState(false);
	const [resetarPesquisaTelefone, setResetarPesquisaTelefone] = useState(false);

	function onClickNovo(e) {
		if (dirty) {
			salvar(e, novo);
		} else {
			novo;
		}
	}

	useEffect(() => {
		setInserindoNovoRegistro(!registroSelecionado);
		let registroSelecionadoAtualizado = registroSelecionado;

		if (
			registroSelecionadoAtualizado?.email &&
			registroSelecionadoAtualizado?.pessoa &&
			!registroSelecionadoAtualizado?.pessoaEmail
		) {
			asyncBuscaRelacaoPessoaEmails(registroSelecionadoAtualizado.pessoa.value, ({ data }) => {
				for (const item of data.content) {
					if (item.email === registroSelecionadoAtualizado.email) {
						registroSelecionadoAtualizado = {
							...registroSelecionadoAtualizado,
							pessoaEmail: {
								label: item.email,
								value: item.id,
							},
						};
						atualizarRegistroSelecionado(registroSelecionadoAtualizado);
					}
				}
			});
		}

		if (
			registroSelecionadoAtualizado?.telefone &&
			registroSelecionadoAtualizado?.pessoa &&
			!registroSelecionadoAtualizado?.pessoaTelefone
		) {
			asyncGetPessoaTelefones(registroSelecionadoAtualizado.pessoa.value, ({ data }) => {
				for (const item of data.content) {
					if (item.telefone === registroSelecionadoAtualizado.telefone) {
						registroSelecionadoAtualizado = {
							...registroSelecionadoAtualizado,
							pessoaTelefone: {
								label: formatarTelefone(item.telefone),
								value: item.id,
							},
						};
						atualizarRegistroSelecionado(registroSelecionadoAtualizado);
					}
				}
			});
		}

		setTimeout(() => {
			if (values.pessoa) {
				document.getElementById('AssinaturaInputPessoa')?.focus();
			} else {
				document.getElementById('AssinaturaInputNome')?.focus();
			}
		}, 500);
	}, []);

	function novo() {
		onNovoClick();
		resetForm({ values: initialValue });
		setInserindoNovoRegistro(true);
	}

	function excluir() {
		excluirRegistro(props.registroSelecionado);
		onHide();
	}

	async function salvar(e, novoOnSuccess) {
		handleSubmit();

		if (await validarFormulario(props)) {
			if (inserindoNovoRegistro) {
				criarRegistro(novoOnSuccess);
			} else {
				atualizarRegistro(novoOnSuccess);
			}
		}
	}

	async function onChangePessoa(e) {
		values.pessoa = null;
		if (e) {
			setFieldValue('nome', e.registro?.nome ?? e.label);
			setFieldValue('pessoaEmail', null);
			setFieldValue('pessoaTelefone', null);
			setFieldValue('email', null);
			setFieldValue('telefone', null);
			setResetarPesquisaEmails(!resetarPesquisaEmails);
			setResetarPesquisaTelefone(!resetarPesquisaTelefone);

			await asyncGetPessoaEmails(e.value, ({ data }) => {
				setFieldValue('pessoa', e);
				if (data.content.length > 0) {
					setFieldValue('emailsPessoa', data.content);
					let encontrou = false;
					data.content.forEach((e) => {
						if (e.favorito) {
							setFieldValue('pessoaEmail', {
								label: e.email,
								value: e.id,
							});
							setFieldValue('email', e.email);
							encontrou = true;
						}
					});
					if (!encontrou) {
						setFieldValue('pessoaEmail', {
							label: data.content[0].email,
							value: data.content[0].id,
						});
						setFieldValue('email', data.content[0].email);
					}
				}
			});

			await asyncGetPessoaTelefones(e.value, ({ data }) => {
				if (data.content.length > 0) {
					setFieldValue('telefonesPessoa', data.content);
					let encontrou = false;
					data.content.forEach((e) => {
						if (e.favorito) {
							setFieldValue('pessoaTelefone', {
								label: formatarTelefone(e.telefone),
								value: e.id,
							});
							setFieldValue('telefone', e.telefone);
							encontrou = true;
						}
					});
					if (!encontrou) {
						setFieldValue('pessoaTelefone', {
							label: formatarTelefone(data.content[0].telefone),
							value: data.content[0].id,
						});
						setFieldValue('telefone', data.content[0].telefone);
					}
				}
			});
		} else {
			setFieldValue('pessoa', e);
		}
	}

	async function afterNewEmail() {
		let emailsPessoa = values.emailsPessoa;
		await onChangePessoa(values.pessoa);
		let novoEmail = null;
		values.emailsPessoa?.forEach(function (element) {
			if (emailsPessoa?.indexOf(element) == -1) novoEmail = element;
		});

		if (novoEmail) {
			setFieldValue('pessoaEmail', { label: novoEmail.email, value: novoEmail.id });
			setFieldValue('email', novoEmail.email);
		}
	}

	async function afterNewTelefone() {
		let telefonesPessoa = values.telefonesPessoa;
		await onChangePessoa(values.pessoa);
		let novoTelefone = null;
		values.telefonesPessoa?.forEach(function (element) {
			if (telefonesPessoa?.indexOf(element) == -1) novoTelefone = element;
		});

		if (novoTelefone) {
			setFieldValue('pessoaTelefone', { label: novoTelefone.telefone, value: novoTelefone.id });
			setFieldValue('telefone', novoTelefone.telefone);
		}
	}

	function onChangePessoaEmail(e) {
		setFieldValue('pessoaEmail', e);
		if (e) {
			setFieldValue('email', e.label);
		} else {
			setFieldValue('email', null);
		}
	}

	function onChangePessoaTelefone(e) {
		setFieldValue('pessoaTelefone', e);
		if (e) {
			setFieldValue('telefone', e.registro?.telefone);
		} else {
			setFieldValue('telefone', null);
		}
	}

	function criarRegistro(novoOnSuccess) {
		inserirRegistro({
			...values,
			idTemporario: gerarUUID(),
		});
		if (novoOnSuccess) {
			novoOnSuccess();
		} else {
			onHide();
		}
	}

	function atualizarRegistro(novoOnSuccess) {
		props.atualizarRegistro(values);
		if (novoOnSuccess) {
			novoOnSuccess();
		} else {
			onHide();
		}
	}

	function cancelar() {
		if (dirty) {
			handleReset();
		} else {
			onHide();
		}
	}

	function onChangeSignatarioCadastrado(e) {
		if (!e.checked) {
			setFieldValue('pessoa', null);
		}
		setFieldValue('signatarioCadastrado', e.checked);
	}

	return (
		<>
			<MenuSuperior isModal={true}>
				<ButtonCancelar
					{...informacoesPermissoes}
					estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
					onClick={() => cancelar()}
				/>
				<ButtonSalvar {...informacoesPermissoes} disabled={!dirty} onClick={() => salvar()} />

				<ButtonNovo
					onClick={(e) => onClickNovo(e)}
					hidden={!dirty && !values.id}
					disabled={disabled}
					estadoBotao={estadoBotaoNovo}
					podeInserir={podeInserir}
				/>
				<ButtonExcluir
					hidden={inserindoNovoRegistro}
					podeExcluir={informacoesPermissoes.podeEditar}
					disabled={disabled}
					onClick={(e) => excluir(e)}
				/>
			</MenuSuperior>
			<LayoutMenuSuperior isModal={true}>
				<Paper dirty={dirty} {...informacoesPermissoes} childsOnly={true}>
					<FormGroup>
						<Grid>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Checkbox}
								label="Signatário já cadastrado no sistema"
								name="signatarioCadastrado"
								helpMessage={helpDocumentoSignatarioForm.signatarioCadastrado}
								onChange={(e) => onChangeSignatarioCadastrado(e)}
								checked={values.signatarioCadastrado}
								disabled={disabled}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={SingleSelectPessoa}
								label="Pessoa "
								obrigatorio
								name="pessoa"
								url={`${services.GESTOR}/v1/documentos/relacoes/pessoas`}
								value={values.pessoa}
								onChange={(e) => onChangePessoa(e)}
								helpMessage={helpDocumentoSignatarioForm.pessoa}
								hidden={!values.signatarioCadastrado}
								disabled={disabled}
								id="AssinaturaInputPessoa"
								{...informacoesPermissoes}
							/>
							<If test={values.pessoa}>
								<Field
									sm="12"
									md="12"
									lg="4"
									xl="4"
									component={SingleSelectPessoaEmail}
									label="E-mail "
									obrigatorio
									name="pessoaEmail"
									url={`${services.GESTOR}/v1/documentos/relacoes/pessoas/${pessoaId}/emails`}
									value={values.pessoaEmail}
									idPessoa={pessoaId}
									onChange={(e) => onChangePessoaEmail(e)}
									afterNewEmail={() => afterNewEmail()}
									helpMessage={helpDocumentoSignatarioForm.email}
									resetarPesquisa={resetarPesquisaEmails}
									disabled={disabled && values.assinatura !== undefined}
									{...informacoesPermissoes}
								/>
							</If>
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={InputField}
								label="Nome "
								obrigatorio
								name="nome"
								size={255}
								helpMessage={helpDocumentoSignatarioForm.nome}
								{...informacoesPermissoes}
								disabled={disabled}
								hidden={values.signatarioCadastrado}
								id="AssinaturaInputNome"
							/>
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={InputField}
								label="E-mail "
								obrigatorio
								name="email"
								type="email"
								size={255}
								helpMessage={helpDocumentoSignatarioForm.email}
								hidden={values.signatarioCadastrado}
								disabled={disabled && values.assinatura !== undefined}
								{...informacoesPermissoes}
							/>

							<If test={values.pessoa}>
								<Field
									sm="12"
									md="12"
									lg="4"
									xl="4"
									component={SingleSelectPessoaTelefone}
									label="Telefone"
									name="pessoaTelefone"
									url={`${services.GESTOR}/v1/documentos/relacoes/pessoas/${pessoaId}/telefones`}
									value={values.pessoaTelefone}
									idPessoa={pessoaId}
									mask={buscarMascaraTelefone(values.telefone)}
									placeholder={'(  )      -    '}
									onChange={(e) => onChangePessoaTelefone(e)}
									afterNewTelefone={() => afterNewTelefone()}
									helpMessage={helpDocumentoSignatarioForm.telefone}
									resetarPesquisa={resetarPesquisaTelefone}
									disabled={disabled && values.assinatura !== undefined}
									{...informacoesPermissoes}
								/>
							</If>

							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={InputMask}
								mask={buscarMascaraTelefone(values.telefone)}
								placeholder={'(  )      -    '}
								label="Telefone "
								name="telefone"
								value={values.telefone}
								onChange={(e) => setFieldValue('telefone', e.target.value)}
								helpMessage={helpDocumentoSignatarioForm.telefone}
								disabled={disabled && values.assinatura !== undefined}
								hidden={values.signatarioCadastrado}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={Dropdown}
								label="Assinar como "
								obrigatorio
								options={PAPEL_SIGNATARIOS}
								name="papel"
								value={values.papel}
								onChange={(e) => setFieldValue('papel', e.value)}
								helpMessage={helpDocumentoSignatarioForm.papel}
								disabled={disabled}
								{...informacoesPermissoes}
							/>
						</Grid>
						<DocumentoConfirmacaoSignatario
							value={values.confirmacoes}
							onChange={(confirmacoes) => setFieldValue('confirmacoes', confirmacoes)}
							estadoCadastro={estadoCadastro}
							disabled={disabled}
							informacoesPermissoes={informacoesPermissoes}
						/>
					</FormGroup>
				</Paper>
			</LayoutMenuSuperior>
		</>
	);
}

const DocumentoSignatariosForm = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		const { registroSelecionado } = props;
		if (registroSelecionado) {
			return {
				...registroSelecionado,
				signatarioCadastrado: Boolean(registroSelecionado.pessoa),
			};
		}

		return { ...initialValue };
	},

	validate(values, props) {
		let errors = {};
		if (values.signatarioCadastrado) {
			if (!values.pessoa) {
				errors.pessoa = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values.pessoaEmail) {
				errors.pessoaEmail = mensagensDeValidacao.OBRIGATORIO;
			}
		} else {
			if (!values.nome) {
				errors.nome = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values.email) {
				errors.email = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		props.registros.forEach((registro) => {
			const idRegistro = registro.id || registro.idTemporario;
			const idValues = values.id || values.idTemporario;

			if (idRegistro !== idValues && registro.email === values.email) {
				errors.pessoaEmail = 'E-mail já utilizado para outro signatário.';
				errors.email = 'E-mail já utilizado para outro signatário.';
			}
		});

		return errors;
	},

	validationSchema: Yup.object().shape({
		papel: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		email: Yup.string().email('Informe um e-mail válido').nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),
	handleSubmit: () => {},
})(DocumentoSignatariosFormView);

export default withRouter(DocumentoSignatariosForm);
