import { estadosCadastro, formatarMonetario } from 'Common';
import { Dropdown, InputDouble } from 'components';
import { Field, useFormikContext } from 'formik';
import {
	CONDICAO_PAGAMENTO_TAXA_TIPO,
	helpMessage,
	OPTIONS_PERIODICIDADE,
	STYLE_DESCRICAO_RECEBIMENTO,
	TIPO_CONTA_RECEBER,
} from '../../Util/constantes';

function FormMultiplasParcelas({ informacoesPermissoes, buscarValor }) {
	const { values, setFieldValue } = useFormikContext();

	function onBlurQuantidadeRepeticoes() {
		if (
			values.tipo === TIPO_CONTA_RECEBER.VALOR_PARCELADO &&
			values.tipoTaxa === CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL &&
			values.taxaAliquota
		) {
			const valor = values.valor / values.quantidadeRepeticoes;
			setFieldValue('taxaValor', (valor * values.taxaAliquota) / 100);
		}
	}

	return (
		<>
			<Field
				col="6"
				sm="6"
				md="6"
				lg="6"
				xl="6"
				component={InputDouble}
				decimalScale={0}
				disabled={informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO}
				type="number"
				keyfilter="pint"
				label={values.tipo === TIPO_CONTA_RECEBER.VALOR_FIXO ? 'Repetir por' : 'Parcelado em'}
				name="quantidadeRepeticoes"
				value={values.quantidadeRepeticoes}
				helpMessage={
					values.tipo === TIPO_CONTA_RECEBER.VALOR_FIXO
						? helpMessage.quantidadeRepeticoesValorFixo
						: helpMessage.quantidadeRepeticoesParcelas
				}
				size={2}
				onChange={(e) => {
					setFieldValue('quantidadeRepeticoes', e.target.value);
					setFieldValue('valorRecebido', buscarValor());
				}}
				onBlur={onBlurQuantidadeRepeticoes}
				allowNegative={false}
				{...informacoesPermissoes}
			/>
			<Field
				col="6"
				sm="6"
				md="6"
				lg="6"
				xl="6"
				component={Dropdown}
				colStyle={{ paddingTop: '24px' }}
				name="ocorrencia"
				autoFocus
				disabled={informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO}
				value={values.ocorrencia}
				showClear={false}
				isSearchableOnMobile={false}
				options={OPTIONS_PERIODICIDADE}
				onChange={(e) => setFieldValue('ocorrencia', e.value || null)}
				{...informacoesPermissoes}
			/>
			<p style={STYLE_DESCRICAO_RECEBIMENTO}>
				Serão geradas {values.quantidadeRepeticoes} contas a receber de <b>{formatarMonetario(buscarValor())}</b>
			</p>
			<p style={{ ...STYLE_DESCRICAO_RECEBIMENTO, fontSize: '12px' }}>
				Em caso de divisão não exata, a sobra será somada à última parcela.
			</p>
		</>
	);
}

export { FormMultiplasParcelas };
