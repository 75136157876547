import { buscarDadosLoginLocalStorage, mensagensDeValidacao } from 'Common';
import {
	AutoProgressBar,
	Button,
	ButtonCancelar,
	Checkbox,
	FormActions,
	FormContent,
	Grid,
	If,
	InputField,
	Modal,
	TextArea,
	estadosBotaoCancelar,
} from 'components';
import { Field, Form, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { asyncEnviarNfcePorEmail } from '../../../Requests';

const initialValue = {
	assunto: null,
	conteudo: null,
	destinatarios: null,
	enviarCopia: false,
};

function ModalCompartilharPorEmailView(props) {
	const { idNFCe, numero, statusNota, values, setFieldValue, validateForm, handleSubmit, visible, onHide, container } =
		props;

	const [emailUsuario, setEmailUsuario] = useState(null);

	useEffect(() => {
		const dadosLogin = buscarDadosLoginLocalStorage();

		let assunto = `NFC-e Nº ${numero} - ${dadosLogin.filialConectada.nome}`;
		let conteudo = `Olá, segue em anexo as informações da NFC-e Nº ${numero}`;

		if (statusNota === 'CANCELADA') {
			assunto = `NFC-e cancelada Nº ${numero} - ${dadosLogin.filialConectada.nome}`;
			conteudo = `Olá, segue em anexo as informações do NFC-e cancelado Nº ${numero}`;
		}

		setFieldValue('assunto', assunto);
		setFieldValue('conteudo', conteudo);

		setEmailUsuario(dadosLogin.email);
	}, []);

	async function enviarPorEmail() {
		await handleSubmit();
		await validateForm();

		if (props.isValid && values.destinatarios) {
			const destinatarios = values.destinatarios
				.split(',')
				.map((item) => item.trim())
				.filter((item) => item.trim() !== '');
			if (values.enviarCopia) {
				destinatarios.push(emailUsuario);
			}

			asyncEnviarNfcePorEmail(idNFCe, { ...values, destinatarios }, () => props.onHide());
		}
	}

	return (
		<Modal header="Enviar por e-mail" visible={visible} onHide={onHide} container={container}>
			<AutoProgressBar />
			<Form>
				<FormActions style={{ marginBottom: '10px' }}>
					<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
					<Button label="Enviar por e-mail" icon="fa fa-send" onClick={enviarPorEmail} style={{ margin: '5px' }} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Destinatários"
							name="destinatarios"
							helpMessage="Pessoas que irão receber o e-mail, podem ser informados vários e-mails separados por vírgula"
							obrigatorio
							size={255}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Assunto"
							obrigatorio
							name="assunto"
							helpMessage="Assunto (título) do e-mail que será encaminhado para os destinatários"
							size={255}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={TextArea}
							label="Texto do e-mail"
							obrigatorio
							placeholder="Escreva o texto do e-mail aqui..."
							name="conteudo"
							helpMessage="Texto do e-mail que será encaminhado para os destinatários"
							rows={5}
							onChange={(e) => setFieldValue('conteudo', e.target.value)}
						/>
						<If test={emailUsuario}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Checkbox}
								label={`Enviar cópia do e-mail para mim (${emailUsuario})`}
								name="enviarCopia"
								helpMessage="Inclui o usuário do sistema na lista de destinatários ao enviar o e-mail"
								onChange={(e) => setFieldValue('enviarCopia', e.checked)}
								checked={values.enviarCopia}
							/>
						</If>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalCompartilharPorEmail = withFormik({
	enableReinitialize: true,
	validateOnChange: false,

	mapPropsToValues() {
		return initialValue;
	},

	async validate(values) {
		const errors = {};

		if (values.destinatarios) {
			const destinatarios = values.destinatarios.split(',').map((item) => item.trim());
			const schemaEmail = Yup.string().email();

			for (const item of destinatarios) {
				if (item === '') {
					errors.destinatarios = `O e-mail "${item}" não é um e-mail válido`;
					break;
				} else if (!schemaEmail.isValid(item)) {
					errors.destinatarios = `O e-mail "${item}" não é um e-mail válido`;
					break;
				}
			}
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		assunto: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		destinatarios: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		conteudo: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),
	handleSubmit: () => {},
})(ModalCompartilharPorEmailView);

export default ModalCompartilharPorEmail;
