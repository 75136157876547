/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import { formatarMonetario } from 'Common';
import { Col, Divider, Grid, If } from 'components';
import { useContextOrdemServico } from 'views/fiscal/servicos/OrdemServico/Context';

import './Styles/index.css';
import { useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { IoMdInformationCircleOutline } from 'react-icons/io';

export function TabTotalizadores({ isTablet, isMobile }) {
	const { totais, color } = useContextOrdemServico();
	const { totalServicos, totalProdutos, valorTotal } = totais;

	const menuImpostosProdutos = useRef(null);
	const menuImpostosServicos = useRef(null);

	const styleTotal = {
		fontSize: 20,
		fontWeight: 'bold',
		color: color.strongColor,
	};

	const styleSubTotal = {
		fontSize: 16,
		fontWeight: 'bold',
		color: '#3c3c3c',
	};

	const styleLineTotais = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0.2rem 0px',
	};

	function renderImpostosServicos() {
		return (
			<table className="os-table-totais-impostos">
				<tbody>
					<tr>
						<td className="os-table-line">
							<span>{isTablet ? 'BC do ISS' : 'Base de cálculo do ISS'}</span>
							<span>{formatarMonetario(totalServicos?.baseIss)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>Valor do ISS</span>
							<span>{formatarMonetario(totalServicos?.valorIss)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>{isTablet ? 'BC do IR' : 'Base de cálculo do IR'}</span>
							<span>{formatarMonetario(totalServicos?.baseIr)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>Valor do IR</span>
							<span>{formatarMonetario(totalServicos?.valorIr)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>{isTablet ? 'BC do CSLL' : 'Base de cálculo do CSLL'}</span>
							<span>{formatarMonetario(totalServicos?.baseCsll)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>Valor do CSLL</span>
							<span>{formatarMonetario(totalServicos?.valorCsll)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>{isTablet ? 'BC do INSS' : 'Base de cálculo do INSS'}</span>
							<span>{formatarMonetario(totalServicos?.baseInss)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>Valor do INSS</span>
							<span>{formatarMonetario(totalServicos?.valorInss)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>{isTablet ? 'BC do PIS' : 'Base de cálculo do PIS'}</span>
							<span>{formatarMonetario(totalServicos?.basePis)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>Valor do PIS</span>
							<span>{formatarMonetario(totalServicos?.valorPis)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>{isTablet ? 'BC do COFINS' : 'Base de cálculo do COFINS'}</span>
							<span>{formatarMonetario(totalServicos?.baseCofins)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>Valor do COFINS</span>
							<span>{formatarMonetario(totalServicos?.valorCofins)}</span>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	function renderImpostosProdutos() {
		return (
			<table className="os-table-totais-impostos">
				<tbody>
					<tr>
						<td className="os-table-line">
							<span>{isTablet ? 'BC do ICMS' : 'Base de cálculo do ICMS'}</span>
							<span>{formatarMonetario(totalProdutos?.baseIcms)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>Valor do ICMS</span>
							<span>{formatarMonetario(totalProdutos?.valorIcms)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>{isTablet ? 'BC do ICMS ST' : 'Base de cálculo do ICMS ST'}</span>
							<span>{formatarMonetario(totalProdutos?.baseSt)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>Valor do ICMS ST</span>
							<span>{formatarMonetario(totalProdutos?.valorSt)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>{isTablet ? 'BC do IPI' : 'Base de cálculo do IPI'}</span>
							<span>{formatarMonetario(totalProdutos?.baseIpi)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>Valor do IPI</span>
							<span>{formatarMonetario(totalProdutos?.valorIpi)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>{isTablet ? 'BC do PIS' : 'Base de cálculo do PIS'}</span>
							<span>{formatarMonetario(totalProdutos?.basePis)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>Valor do PIS</span>
							<span>{formatarMonetario(totalProdutos?.valorPis)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>{isTablet ? 'BC do COFINS' : 'Base de cálculo do COFINS'}</span>
							<span>{formatarMonetario(totalProdutos?.baseCofins)}</span>
						</td>
					</tr>
					<tr>
						<td className="os-table-line">
							<span>Valor do COFINS</span>
							<span>{formatarMonetario(totalProdutos?.valorCofins)}</span>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	return (
		<>
			<div
				style={{
					display: isMobile ? 'block' : 'grid',
					gridTemplateColumns: '1fr min-content 1fr min-content 1fr',
					position: 'relative',
				}}
			>
				<div>
					<div style={styleLineTotais}>
						<span>Valor do frete</span>
						<span>{formatarMonetario(totalProdutos?.frete)}</span>
					</div>
					<div style={styleLineTotais}>
						<span>Valor do seguro</span>
						<span>{formatarMonetario(totalProdutos?.seguro)}</span>
					</div>
					<div style={styleLineTotais}>
						<span>Valor do acessórias</span>
						<span>{formatarMonetario(totalProdutos?.acessorias)}</span>
					</div>
					<div style={styleLineTotais}>
						<span>Valor de desconto produto</span>
						<span>{formatarMonetario(totalProdutos?.desconto * -1)}</span>
					</div>
					<div style={styleLineTotais}>
						<span>Valor de desconto serviço</span>
						<span>{formatarMonetario(totalServicos?.desconto * -1)}</span>
					</div>
				</div>
				<If test={!isMobile}>
					<div>
						<Divider orientation="vertical" />
					</div>
				</If>
				<div>
					<div>
						<div style={styleLineTotais}>
							<span>Valor dos produtos</span>
							<span>{formatarMonetario(totalProdutos?.valorBruto)}</span>
						</div>
						<div style={styleLineTotais}>
							<span
								style={{ display: 'flex', alignItems: 'self-end', cursor: 'pointer' }}
								onClick={(e) => menuImpostosProdutos.current?.toggle(e)}
								className="label-totais"
							>
								Tributos dos produtos <IoMdInformationCircleOutline style={{ margin: '0 0 1px 5px' }} />
							</span>
							<span>{formatarMonetario(totalProdutos?.valorIpi + totalProdutos?.valorSt)}</span>
						</div>
					</div>
					<div style={{ ...styleLineTotais, ...styleSubTotal }}>
						<span>Total produtos</span>
						<span>{formatarMonetario(totalProdutos?.valorLiquido)}</span>
					</div>
				</div>
				<If test={!isMobile}>
					<div>
						<Divider orientation="vertical" />
					</div>
				</If>
				<div>
					<div>
						<div style={styleLineTotais}>
							<span>Valor dos serviços</span>
							<span>{formatarMonetario(totalServicos?.valorBruto)}</span>
						</div>
						<div style={styleLineTotais}>
							<span
								style={{ display: 'flex', alignItems: 'self-end', cursor: 'pointer' }}
								onClick={(e) => menuImpostosServicos.current?.toggle(e)}
								className="label-totais"
							>
								Tributos dos Serviços <IoMdInformationCircleOutline style={{ margin: '0 0 1px 5px' }} />
							</span>
							<span>{formatarMonetario(totalServicos?.valorIss ?? 0)}</span>
						</div>
					</div>
					<div style={{ ...styleLineTotais, ...styleSubTotal }}>
						<span>Total serviços</span>
						<span>{formatarMonetario(totalServicos?.valorLiquido)}</span>
					</div>
				</div>
				<div
					style={{
						gridColumn: '3',
						position: isMobile ? 'relative' : 'absolute',
						top: isMobile ? '0' : '64%',
						width: '100%',
						backgroundColor: 'white',
					}}
				>
					<div>
						<Divider styleContainer={{ padding: '0.5rem 0 0.6rem 0' }} />
					</div>
					<div style={{ ...styleLineTotais, ...styleTotal, padding: 0 }}>
						<span>{isTablet ? 'Total' : 'Valor total'}</span>
						<span>{formatarMonetario(valorTotal)}</span>
					</div>
				</div>
			</div>
			<OverlayPanel
				style={{ marginLeft: isMobile ? '5%' : null, width: isMobile ? '90%' : '400px' }}
				ref={menuImpostosProdutos}
				showCloseIcon
			>
				{renderImpostosProdutos()}
			</OverlayPanel>
			<OverlayPanel
				style={{ marginLeft: isMobile ? '5%' : null, width: isMobile ? '90%' : '400px' }}
				ref={menuImpostosServicos}
				showCloseIcon
			>
				{renderImpostosServicos()}
			</OverlayPanel>
		</>
	);
}
