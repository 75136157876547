
export const tutorialSteps = [
    {
        target: 'body',
        content: `Nesta tela você pode gerenciar seu plano`,
        disableBeacon: true,
        placement: 'center',
    },
    {
        target: '.step-planos-cartao',
        content: 'Aqui você pode visualizar e alterar seu cartão de crédito',
    },
    {
        target: '.step-planos-utilizacao',
        content: 'Aqui você pode verificar a quantidade utilizada de cada recurso do seu plano',
    },
    {
        target: '.step-planos-contratacao',
        content: 'Os nossos planos são separados por recursos, e aqui você pode escolher qual plano atende melhor a sua necessidade',
    },
    {
        target: '.step-planos-historico',
        content: 'Por fim, você pode conferir o histórico de pagamentos',
    }
]