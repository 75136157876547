import { Suspense } from 'react';

import './layout/Theme/layout.scss';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import './App/microsys.css';
import './App/App.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'font-awesome/css/font-awesome.min.css';

import 'react-credit-cards-2/lib/styles.scss';
import 'rc-steps/assets/index.css';

import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

// Redux import
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

// reducers import

// registra globalmente os charts utilizados no sistema
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { LastLocationProvider } from 'react-router-last-location';
import { ProgressBar } from 'primereact/progressbar';
import App from './App/App';
import rootReducers from './config/Reducers';
import ErrorBoundary from './Common/TratamentoDeErro/ErrorBoundary';
import * as serviceWorker from './App/serviceWorker';

//provider de contexto para pesquisa
import PesquisaProvider from 'views/Util/Context/ContextPesquisa';

//configura extensão do redux pro navegador
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// aplica os middlewars na aplicação e cria a store do redux
export const store = createStore(rootReducers, composeEnhancer(applyMiddleware(thunk)));

Chart.register(ChartDataLabels);
Chart.register(...registerables);

ReactDOM.render(
	<Provider store={store}>
		<HashRouter hashType="slash">
			<LastLocationProvider>
				<ErrorBoundary>
					<Suspense fallback={<ProgressBar mode="indeterminate" style={{ height: '4px' }} />}>
						<PesquisaProvider>
							<App />
						</PesquisaProvider>
					</Suspense>
				</ErrorBoundary>
			</LastLocationProvider>
		</HashRouter>
	</Provider>,
	document.getElementById('root')
);

serviceWorker.register({
	onUpdate: (registration) => {
		const waitingServiceWorker = registration.waiting;
		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener('statechange', (event) => {
				if (event.target.state === 'activated') {
					window.location.reload();
				}
			});
			waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
		}
	},
});
