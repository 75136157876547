import { useState } from 'react';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { formatos, inserirMascara } from 'Common';
import { ButtonEditarTable, ButtonExcluirTable, ButtonNovo, Card, If, NenhumRegistroEncontrado } from 'components';

import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';
import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { MDFE_SEGURADORA_RESPONSAVEL } from 'views/transporte/MDFe/Form/Util/constantes';

import { ModalSeguradora } from './components/ModalSeguradora';

function Seguradora() {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, isSituacaoFinalMDFe, isMobile } = useContextMDFe();
	const [sortOrder, setSortOrder] = useState(-1);
	const [sortField, setSortField] = useState('seguradora');
	const [modalSeguradoraVisible, setModalSeguradoraVisible] = useState(false);
	const [indexRegistro, setIndexRegistro] = useState(null);
	const [isNovoRegistro, setIsNovoRegistro] = useState(false);

	function renderFieldResponsavel(row) {
		if (row?.responsavel) {
			return row?.responsavel === MDFE_SEGURADORA_RESPONSAVEL.EMITENTE ? 'Emitente' : 'Contratante';
		}

		return null;
	}

	function renderCpfCnpj(row) {
		if (row?.tipoPessoa === TIPO_PESSOA.JURIDICA) {
			return inserirMascara(row?.cnpj, formatos.CNPJ);
		} else if (row?.tipoPessoa === TIPO_PESSOA.FISICA) {
			return inserirMascara(row?.cpf, formatos.CPF);
		} else if (row?.tipoPessoa === TIPO_PESSOA.ESTRANGEIRO) {
			return row?.identificacao;
		}

		return null;
	}

	function renderFieldSeguradora(row) {
		return row?.seguradora?.registro?.nome ?? null;
	}

	function renderFieldApolice(row) {
		return row?.apolice ?? null;
	}

	function renderAcoes(row) {
		return (
			<div>
				<ButtonEditarTable title="Editar" onClick={() => onEdit(row)} />
				<ButtonExcluirTable onClick={() => onRemove(row)} disabled={isSituacaoFinalMDFe} {...informacoesPermissoes} />
			</div>
		);
	}

	function onEdit(row) {
		setModalSeguradoraVisible(true);
		values?.seguradoras.forEach((seguradora, index) => {
			if (seguradora.id === row.id) {
				setIndexRegistro(index);
			}
		});
	}

	function onRemove(row) {
		const seguradoras = values.seguradoras?.filter((seguradora) => seguradora?.id !== row?.id);
		setFieldValue('seguradoras', seguradoras);
	}

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	return (
		<>
			<ButtonNovo
				label="Nova seguradora"
				onClick={() => {
					setModalSeguradoraVisible(true);
					setIsNovoRegistro(true);
					setIndexRegistro(values?.seguradoras?.length ?? 0);
				}}
				disabled={isSituacaoFinalMDFe}
			/>
			<Card>
				<DataTable
					className="table"
					value={values?.seguradoras}
					sortField={sortField}
					sortOrder={sortOrder}
					onSort={atualizarOrdenacao}
					responsive
					emptyMessage={<NenhumRegistroEncontrado />}
					style={{
						paddingBottom: '16px',
						overflowY: 'auto',
						overflowX: 'hidden',
						maxHeight: isMobile ? '20.6rem' : '13.8rem',
					}}
				>
					<Column
						field="responsavel"
						header="Responsável"
						body={(row) => renderFieldResponsavel(row)}
						sortable
						style={{ width: '8rem' }}
					/>
					<Column
						header="CPF/CNPJ responsável"
						body={(row) => renderCpfCnpj(row)}
						sortable
						style={{ width: '12rem' }}
					/>
					<Column field="seguradora" header="Seguradora" body={(row) => renderFieldSeguradora(row)} sortable />
					<Column
						field="apolice"
						header="Apólice"
						body={(row) => renderFieldApolice(row)}
						sortable
						style={{ width: '12rem' }}
					/>
					<Column
						header="Ações"
						body={renderAcoes}
						style={{
							width: '6rem',
						}}
					/>
				</DataTable>
			</Card>
			<If test={modalSeguradoraVisible}>
				<ModalSeguradora
					visible={modalSeguradoraVisible}
					indexRegistro={indexRegistro}
					seguradora={values.seguradoras[indexRegistro]}
					seguradoras={values.seguradoras}
					isNovoRegistro={isNovoRegistro}
					setFieldValueMDFe={setFieldValue}
					onCloseModal={() => {
						setIndexRegistro(null);
						setModalSeguradoraVisible(false);
						setIsNovoRegistro(false);
					}}
				/>
			</If>
		</>
	);
}

export { Seguradora };
