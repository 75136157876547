import { SituacaoOptions } from "./constantes";

function converterTributacaoMunicipalParaFormulario(tributacao) {
  return {
    id: tributacao.id,
    codigo: tributacao.codigo,
    nome: tributacao.nome,
    tributacaoFederal: converterTributacaoFederalParaFormulario(
      tributacao.tributacaoFederal
    ),
    situacao: SituacaoOptions.find((e) => e.value === tributacao.situacao),
    favorita: tributacao.favorita,
    municipios: converterMunicipiosParaFormulario(tributacao.municipios),
  };
}

function converterTributacaoFederalParaFormulario(tributacaoFederal) {
  if (tributacaoFederal && tributacaoFederal.id) {
    return {
      label: `${tributacaoFederal.codigo} - ${tributacaoFederal.descricao}`,
      value: tributacaoFederal.id,
    };
  }
  return tributacaoFederal?.value ? { id: tributacaoFederal.value } : null;
}

function converterMunicipiosParaFormulario(municipios) {
  return municipios.map((item) => {
    return {
      id: item.id,
      municipio: converterMunicipioParaFormulario(item.municipio),
      servicoCst: converterServicoCstParaFormulario(item.servicoCst),
      issBase: item.issBase,
      issPercentual: item.issPercentual,
      irBase: item.irBase,
      irPercentual: item.irPercentual,
      csllBase: item.csllBase,
      csllPercentual: item.csllPercentual,
      inssBase: item.inssBase,
      inssPercentual: item.inssPercentual,
    };
  });
}

function converterMunicipioParaFormulario(municipio) {
  if (municipio && municipio?.id) {
    return {
      label: `${municipio.nome} - ${municipio.estado.sigla}`,
      value: municipio.id,
      registro: municipio,
    };
  }
}

function converterServicoCstParaFormulario(servicoCst) {
  if (servicoCst && servicoCst?.id) {
    return {
      label: `${servicoCst.codigo} - ${servicoCst.descricao}`,
      value: servicoCst.id,
      registro: servicoCst,
    };
  }
}

function converterTributacaoMunicipalParaDuplicar(tributacao) {
  return {
    id: null,
    codigo: null,
    nome: tributacao.nome,
    tributacaoFederal: converterTributacaoFederalParaFormulario(
      tributacao.tributacaoFederal
    ),
    situacao: SituacaoOptions.find((e) => e.value === tributacao.situacao),
    favorita: tributacao.favorita,
    municipios: converterMunicipiosParaDuplicar(tributacao.municipios),
  };
}

function converterMunicipiosParaDuplicar(municipios) {
  return municipios.map((item) => {
    return {
      id: null,
      municipio: converterMunicipioParaFormulario(item.municipio),
      servicoCst: converterServicoCstParaFormulario(item.servicoCst),
      issBase: item.issBase,
      issPercentual: item.issPercentual,
      irBase: item.irBase,
      irPercentual: item.irPercentual,
      csllBase: item.csllBase,
      csllPercentual: item.csllPercentual,
      inssBase: item.inssBase,
      inssPercentual: item.inssPercentual,
    };
  });
}

function converterTributacaoMunicipalParaApi(tributacao) {
  return {
    id: tributacao.id,
    codigo: tributacao.codigo,
    nome: tributacao.nome,
    tributacaoFederal: converterTributacaoFederalParaApi(
      tributacao.tributacaoFederal
    ),
    situacao: converterSituacaoParaApi(tributacao.situacao),
    favorita: tributacao.favorita,
    municipios: converterMunicipiosParaApi(tributacao.municipios),
  };
}

function converterTributacaoFederalParaApi(tributacaofederal) {
  return tributacaofederal?.value ? { id: tributacaofederal.value } : null;
}

function converterSituacaoParaApi(situacao) {
  return situacao?.value ? situacao.value : null;
}

function converterMunicipiosParaApi(municipios) {
  return municipios.map((item) => {
    return {
      id: item.id,
      municipio: converterMunicipioParaApi(item.municipio),
      servicoCst: converterServicoCstParaApi(item.servicoCst),
      issBase: item.issBase,
      issPercentual: item.issPercentual,
      irBase: item.irBase,
      irPercentual: item.irPercentual,
      csllBase: item.csllBase,
      csllPercentual: item.csllPercentual,
      inssBase: item.inssBase,
      inssPercentual: item.inssPercentual,
    };
  });
}

function converterMunicipioParaApi(municipio) {
  return municipio?.value ? { id: municipio.value } : null;
}

function converterServicoCstParaApi(servicoCst) {
  return servicoCst?.value ? { id: servicoCst.value } : null;
}

export {
  converterTributacaoMunicipalParaFormulario,
  converterTributacaoMunicipalParaDuplicar,
  converterTributacaoMunicipalParaApi,
};
