import { services } from 'Common';
import { ButtonCancelar, Checkbox, Fieldset, Grid, If, InputField, SingleSelectPessoa } from 'components';
import { Field } from 'formik';
import { useEffect } from 'react';
import Card from '../../../../../../../../../../components/cards/Card';
import InputSelectCpfOrCnpj from '../../../../../../../../../../components/input/InputSelectCpfOrCnpj';
import { TIPO_PESSOA, TipoPessoa } from '../../../../../../../../../cadastros/Pessoas/Util/constantes';
import { useContextNFCe } from '../../../../../../Context';
import { DocumentosTipos } from '../../../../../../Util/constantes';

function ModalCliente(props) {
	const { cliente, setFieldValue, setFocusCliente, isFullScreen } = props;
	const { informacoesPermissoes, podeInserir, davImportado } = useContextNFCe();

	useEffect(() => {
		setFocusCliente(true);
	}, []);

	function buscarLabelEPlaceholderDestinatarioNfce() {
		return cliente.documentoTipo === DocumentosTipos.CPF ? 'Nome' : 'Razão Social';
	}

	function removerConsumidor() {
		if (davImportado) {
			setFieldValue('cliente.consumidorCadastrado', false);
			setFieldValue('cliente.nome', null);
			setFieldValue('cliente.cpf', null);
			setFieldValue('cliente.cnpj', null);
		}
	}

	function changeConsumidorCadastrado(e) {
		setFieldValue('cliente.consumidorCadastrado', e.checked);
		setTimeout(() => {
			setFocusCliente(true);
		}, 200);
	}

	function onChangeNome(nome) {
		if (nome) {
			setFieldValue('cliente.nome', nome);
		} else {
			setFieldValue('cliente.nome', null);
			setFieldValue('cliente.clienteCadastrado', null);
		}
	}

	function montarInformacoesDestinatarioCadastrado(informacoesDestinatario) {
		if (informacoesDestinatario) {
			const { registro } = informacoesDestinatario;

			if (registro.tipo === 'JURIDICA') {
				setFieldValue('cliente.documentoTipo', DocumentosTipos.CNPJ);
			} else {
				setFieldValue('cliente.documentoTipo', DocumentosTipos.CPF);
			}

			if (cliente.consumidorCadastrado) {
				const clienteAtualizado = {
					...cliente,
					documentoTipo: registro.tipo === 'JURIDICA' ? DocumentosTipos.CNPJ : DocumentosTipos.CPF,
					tipoCliente: registro.tipo,
					cnpj: registro.cnpj ? registro.cnpj : registro.pessoaJuridica?.cnpj,
					cpf: registro.cpf ? registro.cpf : registro.pessoaFisica?.cpf,
					nome: registro.nome,
					clienteCadastrado: informacoesDestinatario,
					configPrecoPraticado: registro.configPrecoPraticado,
				};
				setFieldValue('validacaoRecarregaProdutoPeloCliente', true);
				setFieldValue('cliente', clienteAtualizado);
			}
		} else {
			setFieldValue('cliente.clienteCadastrado', null);
			setFieldValue('cliente.documentoTipo', DocumentosTipos.CPF);
			setFieldValue('cliente.tipoCliente', TIPO_PESSOA.FISICA);
			setFieldValue('cliente.cnpj', null);
			setFieldValue('cliente.cpf', null);
			setFieldValue('cliente.nome', null);
			setFieldValue('validacaoRecarregaProdutoPeloCliente', true);
		}

		document
			.getElementById('id-cliente-overlay-panel-nfce')
			?.getElementsByClassName('p-overlaypanel-close p-link')[0]
			?.click();
	}

	function changeCpfOuCnpj(CpfOuCnpj) {
		if (cliente.documentoTipo === DocumentosTipos.CNPJ) {
			setFieldValue('cliente.cnpj', CpfOuCnpj);
			setFieldValue('cliente.tipoCliente', TIPO_PESSOA.JURIDICA);
		} else {
			setFieldValue('cliente.cpf', CpfOuCnpj);
			setFieldValue('cliente.tipoCliente', TIPO_PESSOA.FISICA);
		}
	}

	function trocarTipoDocumento() {
		if (cliente.documentoTipo === DocumentosTipos.CPF) {
			setFieldValue('cliente.documentoTipo', DocumentosTipos.CNPJ);
			setFieldValue('cliente.tipoCliente', TIPO_PESSOA.JURIDICA);
			setFieldValue('cliente.cpf', null);
		} else {
			setFieldValue('cliente.documentoTipo', DocumentosTipos.CPF);
			setFieldValue('cliente.tipoCliente', TIPO_PESSOA.FISICA);
			setFieldValue('cliente.cnpj', null);
		}
	}

	function pegarValuesDocumento() {
		if (cliente.documentoTipo === DocumentosTipos.CPF) {
			return cliente.cpf;
		} else {
			return cliente.cnpj;
		}
	}

	function onShowModalNewPessoa() {
		document.getElementById('id-cliente-overlay-panel-nfce').style.zIndex = 1000;
	}

	return (
		<Fieldset legend="Identificação do consumidor" className="fieldset" style={{ paddingTop: '0' }}>
			<Card>
				<Grid style={{ border: '1px' }}>
					<If test={!davImportado}>
						<Field
							component={Checkbox}
							label={davImportado ? 'Remover consumidor' : 'Consumidor já cadastrado'}
							name="consumidorCadastrado"
							onChange={(e) => changeConsumidorCadastrado(e)}
							checked={cliente.consumidorCadastrado}
							disabled={!podeInserir && !davImportado}
							{...informacoesPermissoes}
						/>
					</If>
					<If test={davImportado && cliente.consumidorCadastrado}>
						<ButtonCancelar
							label="Remover consumidor"
							title="Remover consumidor da NFC-e"
							onClick={removerConsumidor}
						/>
					</If>
					<If test={!cliente.consumidorCadastrado}>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							id="id-nome-cliente-nfce"
							component={InputField}
							name="nome"
							type="text"
							label={buscarLabelEPlaceholderDestinatarioNfce()}
							placeholder={buscarLabelEPlaceholderDestinatarioNfce()}
							value={cliente.nome}
							onChange={(e) => onChangeNome(e.target.value)}
							size={60}
							disabled={!podeInserir}
							{...informacoesPermissoes}
						/>
					</If>
					<If test={cliente.consumidorCadastrado}>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							id="id-cliente-cadastrado-nfce"
							name="clienteCadastrado"
							label="Cliente"
							component={SingleSelectPessoa}
							onChange={(e) => montarInformacoesDestinatarioCadastrado(e)}
							url={`${services.GESTOR}/v1/nfce/relacoes/pessoas`}
							value={cliente.clienteCadastrado}
							disabled={!podeInserir}
							onShowModal={onShowModalNewPessoa}
							menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
							tipoPessoa={TipoPessoa.CLIENTE}
							{...informacoesPermissoes}
						/>
					</If>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						name="documento"
						component={InputSelectCpfOrCnpj}
						value={pegarValuesDocumento()}
						valueDocumentoTipo={cliente.documentoTipo}
						onChange={(e) => changeCpfOuCnpj(e)}
						onChangeDocumentoTipo={(e) => trocarTipoDocumento(e)}
						disabled={!podeInserir}
						{...informacoesPermissoes}
					/>
				</Grid>
			</Card>
		</Fieldset>
	);
}

export default ModalCliente;
