import { formatarMonetario } from 'Common';
import { Grid, If } from 'components';
import { format } from 'date-fns';

function DetalhesForm({
	valorPlanoAtual,
	novoPlano,
	efetuarPagamentoAoAderir,
	dataProximoPagamento,
	valorPagoNoUltimoMes,
	valorPlanoAtualFormatado,
	valorNovoPlanoFormatado,
}) {
	return (
		<Grid style={{ display: 'flex', flexDirection: 'column' }}>
			<p
				style={{
					fontSize: '14px',
					textAlign: 'justify',
					marginLeft: '10px',
					marginRight: '10px',
					marginTop: '16px',
				}}
			>
				- O plano contratado será alterado para o plano <b>{novoPlano.descricao.toUpperCase()}</b>.
				<br />
				<br />
				<If test={novoPlano.valor - valorPagoNoUltimoMes > 0}>
					- Será efetuada uma cobraça hoje no valor de{' '}
					<b>{formatarMonetario(novoPlano.valor - valorPagoNoUltimoMes)}</b>.
					<br />
					<br />
				</If>
				- O valor da mensalidade será modificado {valorPlanoAtual > 0 ? 'de ' : ''}
				<b>{valorPlanoAtual > 0 ? valorPlanoAtualFormatado : ''}</b> para{' '}
				<b style={{ color: '#006095' }}>{valorNovoPlanoFormatado}</b>.
				<br />
				<br />
				<If
					test={
						novoPlano.valor - valorPagoNoUltimoMes > 0 &&
						format(new Date().setMonth(new Date().getMonth() + 1), 'dd/MM/yyyy') !==
							format(dataProximoPagamento, 'dd/MM/yyyy')
					}
				>
					- A data da próxima cobrança será alterada para{' '}
					<b>{format(new Date().setMonth(new Date().getMonth() + 1), 'dd/MM/yyyy')}</b>.
					<br />
					<br />
				</If>
				<If
					test={
						format(new Date().setMonth(new Date().getMonth() + 1), 'dd/MM/yyyy') ===
						format(dataProximoPagamento, 'dd/MM/yyyy')
					}
				>
					- Este novo valor será cobrado na próxima mensalidade em <b>{format(dataProximoPagamento, 'dd/MM/yyyy')}</b>
					.
					<br />
				</If>
				<If test={novoPlano.valor - valorPagoNoUltimoMes <= 0}>
					<If test={efetuarPagamentoAoAderir}>
						<br /> Para prosseguir, efetue o pagamento da mensalidade.
					</If>
				</If>
			</p>
		</Grid>
	);
}

export default DetalhesForm;
