import { buscarDadosLoginLocalStorage, gerarUUID } from 'Common';
import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import {
	optionsIndicadorPresenca,
	optionsNaturezaOperacao,
	optionsOperacao,
	optionsTipoEmissao,
} from '../../../views/fiscal/Util/constantes';
import LinkVerDados from '../../LinkVerDados';
import { Col, Dropdown, Grid, If, SingleSelectOperacaoFiscal, SingleSelectPessoa, confirm } from '../../index';
import { idDest, moduloUtilizado as modulos } from '../Util/constantes';
import { helpMessageOrcamentoVenda, helpNFeForm } from './Help';
import ModalDadosDestinatario from './components/ModalDadosDestinatario';
import ModalDocumentosReferenciados from './components/ModalDocumentosReferenciados';
import DataPorModulo from './components/dataPorModulo';

function HeaderVendas(props) {
	const {
		moduloUtilizado,
		tipoPessoa,
		informacoesPermissoes,
		setAtualizarOperacaoFiscalProdutos,
		setControlMessageProdutos,
		setAtualizarImpostosProdutos,
		getCondicaoPagamentoSemPagamento,
		buscarTabelaPreco,
		buscarMunicipioIdPessoa,
	} = props;

	const { values, errors, dirty, setFieldValue } = useFormikContext();

	const [modalDestinatarioVisible, setModalDestinatarioVisible] = useState(false);
	const [modalDocumentosReferenciadosVisible, setModalDocumentosReferenciadosVisible] = useState(false);
	const [optionsOperacaoFiscal, setOptionsOperacaoFiscal] = useState(null);

	const { filialConectada } = buscarDadosLoginLocalStorage();
	const helpForm = moduloUtilizado === modulos.NOTA_FISCAL ? helpNFeForm : helpMessageOrcamentoVenda;

	useUpdateEffect(() => {
		getOptions();
	}, [values.tipoOperacao]);

	useUpdateEffect(() => {
		if (!optionsOperacaoFiscal.some((e) => e.value === values?.operacaoFiscal?.value) && dirty) {
			onChangeOperacaoFiscal(optionsOperacaoFiscal[0]);
		}
	}, [optionsOperacaoFiscal]);

	function onChangeindicadorPresenca(e) {
		const { value } = e;

		setFieldValue('indicadorPresenca', value);
	}

	function onChangeOperacaoFiscal(value) {
		setControlMessageProdutos(true);
		setFieldValue('operacaoFiscal', value);
		setAtualizarOperacaoFiscalProdutos(true);
	}

	function onChangePessoa(pessoa) {
		if (pessoa) {
			const destinoOperacao = buscarDestinoOperacao(pessoa?.registro);
			setFieldValue('pessoa', {
				...pessoa,
				registro: {
					...pessoa?.registro,
					destinoOperacao: destinoOperacao,
					idEstrangeiro: pessoa?.registro?.pessoaEstrangeira?.identificacao,
				},
			});
			setControlMessageProdutos(true);

			if (values.pessoa || pessoa?.registro?.configPrecoPraticado === 'PRECO_ATACADO') {
				if (values.pessoa?.registro?.configPrecoPraticado !== pessoa?.registro?.configPrecoPraticado) {
					if (values.produtos.length > 0) {
						const arrayProdutos = values.produtos.filter((item) => item.produto);

						if (arrayProdutos.length > 0) {
							confirm(
								'Confirmação',
								'O cliente selecionado apresenta outra configuração de preço, deseja atualizar o valor dos produtos já inclusos?',
								() => {
									setFieldValue('validacaoRecarregaProdutoPeloCliente', true);
								}
							);
						}
					}
				}
			}

			setAtualizarImpostosProdutos(true);
			setTimeout(() => {
				const elementHtmlProduto = document
					.getElementById('id-produtos-listagem')
					?.getElementsByTagName('table')[0]
					?.getElementsByTagName('tr')
					[values.produtos.length]?.getElementsByClassName('id-produtos-listagem-campo-produto')[0];

				elementHtmlProduto?.click();
			}, 200);
		} else {
			setFieldValue('pessoa', null);
		}

		buscarTabelaPreco(
			{
				vendedorId: values.informacoesComplementares?.vendedor?.value,
				clienteId: pessoa?.value,
				municipioId: buscarMunicipioIdPessoa(pessoa),
			},
			({ data: tabelaPrecoApi }) => {
				const tabelaPreco = {
					label: `${tabelaPrecoApi.codigo} - ${tabelaPrecoApi.nome}`,
					registro: tabelaPrecoApi,
					value: tabelaPrecoApi.id,
				};

				setFieldValue('informacoesComplementares.tabelaPreco', tabelaPreco);
			}
		);
	}

	function buscarDestinoOperacao(pessoa) {
		let enderecoFavorito;
		if (pessoa?.endereco) {
			enderecoFavorito = pessoa.endereco;
		} else {
			pessoa?.enderecos?.forEach((endereco) => {
				enderecoFavorito =
					endereco.favorito === true
						? {
								...endereco,
								municipio: {
									label: `${endereco?.municipio?.nome} - ${endereco?.municipio?.estadoSigla}`,
									registro: endereco?.municipio,
									value: endereco?.municipio?.id,
								},
								pais: {
									label: endereco.pais.nome,
									registro: endereco.pais,
									value: endereco.pais.id,
								},
							}
						: null;
			});

			if (!enderecoFavorito) {
				if (pessoa?.enderecos?.length > 0) {
					enderecoFavorito = {
						...pessoa.enderecos[0],
						municipio: {
							label: `${pessoa.enderecos[0].municipio.nome} - ${pessoa.enderecos[0].municipio.estadoSigla}`,
							registro: pessoa.enderecos[0].municipio,
							value: pessoa.enderecos[0].municipio.id,
						},
						pais: {
							label: pessoa.enderecos[0].pais.nome,
							registro: pessoa.enderecos[0].pais,
							value: pessoa.enderecos[0].pais.id,
						},
					};
				}
			}
		}

		if (enderecoFavorito) {
			if (enderecoFavorito.municipio?.registro?.ufId === filialConectada?.endereco?.municipio?.estado.id) {
				return idDest.OPERACAO_INTERNA;
			} else if (pessoa.registro?.tipo === 'ESTRANGEIRO') {
				return idDest.OPERACAO_COM_EXTERIOR;
			} else if (enderecoFavorito.municipio?.registro?.ufId) {
				return idDest.OPERACAO_INTERESTADUAL;
			} else {
				return null;
			}
		}

		return null;
	}

	function onChangeNaturezaOperacao(naturezaOperacao) {
		setFieldValue('naturezaOperacao', naturezaOperacao);

		if (naturezaOperacao.value === 'DEVOLUCAO') {
			setFieldValue('idCobranca', null);
			setFieldValue('quantidadeParcelas', 1);
			setFieldValue('condicao', 'SEM_PAGAMENTO');
			setFieldValue('conta', null);
			setFieldValue('categoria', null);
			setFieldValue('forma', 'DINHEIRO');
		}
	}

	function onChangeReferencia(event) {
		const newValues = { ...values };
		newValues.NFref = event;
		setFieldValue('NFref', newValues.NFref);
	}

	function getOptions(values) {
		if (values !== undefined) {
			setOptionsOperacaoFiscal(values);
		}
	}

	function onChangeTipoOperacao(e) {
		setFieldValue('tipoOperacao', e.value);

		if (e.value === 'ENTRADA') {
			getCondicaoPagamentoSemPagamento(({ data }) => {
				const condicaoSemPagamento = data.content[0];

				const pagamento = {
					id: null,
					tempKey: gerarUUID(),
					sequencial: 1,
					condicaoPagamento: {
						label: `${condicaoSemPagamento.descricao}`,
						registro: condicaoSemPagamento,
						value: condicaoSemPagamento?.id,
					},
					conta: null,
					formaPagamento: null,
					categoria: null,
					quantidadeParcelas: 0,
					valor: 0,
					valorRecebido: 0,
					parcelas: [],
				};

				setFieldValue('pagamentos', [pagamento]);
			});
		}
	}

	return (
		<>
			<Grid
				style={{ width: '100%' }}
				className={moduloUtilizado === modulos.NOTA_FISCAL ? 'step-nfe-cliente' : 'step-venda-e-orcamento-cliente'}
			>
				<If test={moduloUtilizado === modulos.NOTA_FISCAL}>
					<Field
						sm="6"
						md="2"
						lg="2"
						xl="2"
						component={Dropdown}
						obrigatorio
						label="Operação"
						name="tipoOperacao"
						options={optionsOperacao}
						onChange={(e) => onChangeTipoOperacao(e)}
						value={values?.tipoOperacao}
						showClear={false}
						helpMessage={helpForm.tipoOperacao}
						id="dropdownOperacao"
						errors={errors.tipoOperacao}
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						obrigatorio
						label="Natureza da operação"
						name="naturezaOperacao"
						showClear={false}
						options={optionsNaturezaOperacao}
						onChange={(value) => onChangeNaturezaOperacao(value)}
						value={values?.naturezaOperacao}
						helpMessage={helpForm.naturezaOperacao}
						errors={errors.naturezaOperacao}
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="3"
						lg="3"
						xl="3"
						component={Dropdown}
						obrigatorio
						label="Finalidade"
						name="finalidadeDocumento"
						options={optionsTipoEmissao}
						onChange={(value) => setFieldValue('finalidadeDocumento', value)}
						value={values?.finalidadeDocumento}
						showClear={false}
						helpMessage={helpForm.finalidadeDocumento}
						titleBotao="Referenciar documentos CF/NF-e/NFC-e/NF Produtor"
						botaoIcon="fa fa-copy"
						styleButton={{ width: '32px', height: '32px', margin: '0px 0px 0px 5px' }}
						esconderBotao={false}
						onClickModal={() => setModalDocumentosReferenciadosVisible(true)}
						errors={errors.finalidadeDocumento}
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="3"
						lg="3"
						xl="3"
						component={Dropdown}
						obrigatorio
						label="Indicador presença"
						name="indicadorPresenca"
						onChange={(event) => onChangeindicadorPresenca(event)}
						options={optionsIndicadorPresenca}
						value={values?.indicadorPresenca}
						showClear={false}
						helpMessage={helpForm.indicadorPresenca}
						errors={errors.indicadorPresenca}
						{...informacoesPermissoes}
					/>
				</If>
				<Col
					sm="12"
					md="12"
					lg={[modulos.NOTA_FISCAL, modulos.PEDIDO_VENDA].includes(moduloUtilizado) ? '5' : '6'}
					xl={[modulos.NOTA_FISCAL, modulos.PEDIDO_VENDA].includes(moduloUtilizado) ? '5' : '6'}
				>
					<Field
						colStyle={{ padding: '0px' }}
						id="VendasSingleSelectCliente"
						label={moduloUtilizado === modulos.NOTA_FISCAL ? 'Destinatário' : 'Cliente'}
						name="pessoa"
						component={SingleSelectPessoa}
						value={values?.pessoa}
						helpMessage={helpForm.pessoa}
						tipoPessoa={tipoPessoa}
						obrigatorio={[modulos.NOTA_FISCAL, modulos.PEDIDO_VENDA].includes(moduloUtilizado)}
						onChange={(value) => onChangePessoa(value)}
						errors={errors.pessoa}
						touched
						{...informacoesPermissoes}
					/>
					<If test={moduloUtilizado === modulos.NOTA_FISCAL && values.pessoa}>
						<LinkVerDados label="Ver dados do destinatário" onClick={() => setModalDestinatarioVisible(true)} />
					</If>
				</Col>
				<If test={[modulos.NOTA_FISCAL, modulos.PEDIDO_VENDA].includes(moduloUtilizado)}>
					<Field
						sm="12"
						md="6"
						lg="3"
						xl="3"
						name="operacaoFiscal"
						label="Operação fiscal"
						id="operacaoFiscal"
						getOptions={getOptions}
						component={SingleSelectOperacaoFiscal}
						value={values?.operacaoFiscal}
						tipoOperacao={values.tipoOperacao}
						tipoCliente={values.tipoOperacao === 'SAIDA' ? values.pessoa?.registro?.consumidorFinal : false}
						onChange={(value) => onChangeOperacaoFiscal(value)}
						obrigatorio
						isClearable={false}
						errors={errors.operacaoFiscal}
						touched
						{...informacoesPermissoes}
					/>
				</If>
				<DataPorModulo
					moduloUtilizado={moduloUtilizado}
					values={values}
					helpForm={helpForm}
					setFieldValue={setFieldValue}
					errors={errors}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</Grid>
			<If test={modalDestinatarioVisible}>
				<ModalDadosDestinatario
					valuesDestinatario={values?.pessoa}
					onChange={(value) => setFieldValue('pessoa.registro', { ...values.pessoa.registro, ...value })}
					visible={modalDestinatarioVisible}
					onHide={() => setModalDestinatarioVisible(false)}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</If>
			<If test={modalDocumentosReferenciadosVisible}>
				<ModalDocumentosReferenciados
					visible={modalDocumentosReferenciadosVisible}
					onHide={() => setModalDocumentosReferenciadosVisible(false)}
					onChange={(event) => onChangeReferencia(event)}
					NFref={values?.NFref}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</If>
		</>
	);
}

export default HeaderVendas;
