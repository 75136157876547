export const SERVICOS_MODULOS = {
  ORDEM_SERVICO: "ORDEM_SERVICO",
  NFSE: "NFSE",
};

export const OPTIONS_SELECT = [
  {
    label: "Ordem de serviço",
    value: SERVICOS_MODULOS.ORDEM_SERVICO,
  },
  {
    label: "NFS-e",
    value: SERVICOS_MODULOS.NFSE,
  },
];

export const SERVICOS_CORES = {
  vermelho: "#b71c1c",
  vermelhoClaro: "#ffcdd2",
  verde: "#1b5e20",
  verdeClaro: "#dcedc8",
  azul: "#006095",
  preto: "#fff0",
  cinza: "#888888",
  branco: "#F2F2F2",
};
