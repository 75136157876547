import { get, post, services } from "Common";
import { localServices } from "Common/Constantes/api";

export async function asyncGetProdutos(filter, onSucess, onError) {
  await get(
    `${services.GESTOR}/v1/etiquetas/produtos?query=(codigo=contains="*${filter}*",nome=contains="*${filter}*",sku=contains="*${filter}*",codigoBarras=contains="${filter}");(tipo!="SERVICO";situacao=="ATIVO")`,
    null,
    onSucess,
    onError
  );
}

export async function asyncGetNotasEntrada(filter, onSucess, onError) {
  await get(
    `${services.GESTOR}/v1/etiquetas/notas_entrada?query=(numero=contains="*${filter}*",serie=contains="*${filter}*",emitente.nome=contains="*${filter}*",chaveAcesso=contains="*${filter}*");(situacao=="FINALIZADA")`,
    null,
    onSucess,
    onError
  );
}

export async function asyncGetProdutosByNotasEntrada(id, onSucess, onError) {
  await get(
    `${services.GESTOR}/v1/etiquetas/notas_entrada/${id}/produtos`,
    null,
    onSucess,
    onError
  );
}

export async function asyncGetEtiqueta(
  id,
  onSucess,
  onError,
  tratarErrors = true
) {
  await get(
    `${services.GESTOR}/v1/etiquetas/${id}`,
    null,
    onSucess,
    onError,
    false,
    tratarErrors
  );
}

export async function asyncPostImprimirEtiquetas(data, onSucess, onError) {
  await post(
    `${localServices.MSYS_GESTOR_SERVICE}/msysgestor/servico/etiqueta/imprimir`,
    JSON.stringify(data),
    null,
    onSucess,
    onError,
    true,
    false
  );
}

export async function asyncPostPreviewEtiquetas(data, onSucess, onError) {
  await post(
    `${localServices.MSYS_GESTOR_SERVICE}/msysgestor/servico/etiqueta/preview`,
    JSON.stringify(data),
    { responseType: "arraybuffer" },
    onSucess,
    onError,
    true,
    false
  );
}
