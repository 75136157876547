import { services } from 'Common';
import { Pagamentos } from 'components';
import { FINANCEIRO_TIPO } from 'components/Pagamentos/Util/constantes';
import { useFormikContext } from 'formik';
import { CLASSNAME_TABVIEW_COLOR, operacaoStatus } from 'views/compras/NotasEntrada/Util/constantes';

function FieldsPagamentosForm({ informacoesPermissoes, favoritos }) {
	const { values, setFieldValue, errors } = useFormikContext();

	function onChangePagamentos(pagamentos) {
		setFieldValue('pagamentos', pagamentos);
	}

	function onChangePagamento(index, value) {
		setFieldValue(`pagamentos[${index}]`, value);
	}

	function onChangePagamentoField(index, field, value) {
		setFieldValue(`pagamentos[${index}].[${field}]`, value);
	}

	return (
		<>
			<Pagamentos
				idOrigem={values.id}
				pagamentos={values.pagamentos}
				valorTotal={values.totais?.valorNfe}
				dataBaseParcela={values.dataEntrada}
				urls={{
					urlBase: `${services.GESTOR}/v1/compras`,
					condicaoPagamento: `${services.GESTOR}/v1/compras/relacoes/condicoes_pagamento`,
					formaPagamento: `${services.GESTOR}/v1/compras/relacoes/formas_pagamento`,
					conta: `${services.GESTOR}/v1/compras/relacoes/contas`,
					categoria: `${services.GESTOR}/v1/compras/relacoes/categorias/receitas`,
				}}
				informacoesPermissoes={informacoesPermissoes}
				disabledFields={values.situacao !== operacaoStatus.PENDENTE}
				favoritos={favoritos}
				hideTabFinanceiro={values.situacao !== operacaoStatus.FINALIZADA}
				onChangePagamentos={onChangePagamentos}
				onChangePagamento={onChangePagamento}
				onChangePagamentoField={onChangePagamentoField}
				errors={errors?.pagamentos}
				financeiroTipo={FINANCEIRO_TIPO.CONTA_PAGAR}
				classNameTabView={CLASSNAME_TABVIEW_COLOR[values.situacao].class}
				color={CLASSNAME_TABVIEW_COLOR[values.situacao].color}
				hiddenButtonAdd
			/>
			<br />
		</>
	);
}

export { FieldsPagamentosForm };
