import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { Grid, If } from 'components';
import { SelectButton } from 'primereact/selectbutton';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { OPTIONS_SELECT, TRANSPORTES_MODULOS } from './Util/constantes';
import { DashboardMDFe } from './components/DashboardMDFe';

const selectButtonStyle = {
	whiteSpace: 'nowrap',
	overflowY: 'hidden',
	width: '100%',
};

function DashboardTransportesImpl({ dataInicial, dataFinal, isMobile, history }) {
	const [podeVisualizarMDFe] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.VISUALIZAR));
	const [optionsSelect] = useState(
		OPTIONS_SELECT.filter((e) => {
			if (e.value === TRANSPORTES_MODULOS.MDFE && podeVisualizarMDFe) {
				return e;
			} else {
				return null;
			}
		})
	);

	const [currentOption, setCurrentOption] = useState(optionsSelect[0]?.value);

	function handleChangeOption(event) {
		if (event.value) {
			setCurrentOption(event.value);
		}
	}

	if (dataInicial && dataFinal) {
		return (
			<Grid style={{ margin: '0px', width: '99.8%' }}>
				<SelectButton
					style={selectButtonStyle}
					className="grid-select-filtro select-button"
					options={optionsSelect}
					value={currentOption}
					onChange={handleChangeOption}
				/>
				<If test={currentOption === TRANSPORTES_MODULOS.MDFE}>
					<DashboardMDFe dataInicial={dataInicial} dataFinal={dataFinal} history={history} isMobile={isMobile} />
				</If>
			</Grid>
		);
	} else {
		return <span>Carregando informações...</span>;
	}
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export const DashboardTransportes = connect(mapStateToProps)(withRouter(DashboardTransportesImpl));
