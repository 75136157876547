import { get, services } from 'Common';
import { formatISO, isDate, isValid } from 'date-fns';

export function asyncGetResultadoPeriodo(dataInicial, dataFinal, onSuccess, onError) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: 'date',
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: 'date',
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: 'date' });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: 'date' });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/vendas/resultado_periodo_nfe?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}`,
    null,
    onSuccess,
    onError,
    false
  );
}

export function asyncGetProdutosMaisVendidos(dataInicial, dataFinal, page, onSuccess, onError) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: 'date',
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: 'date',
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: 'date' });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: 'date' });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/vendas/vendas_por_produtos_nfe?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}&page=${page}&sort=valorFinalizados,desc&size=10`,
    null,
    onSuccess,
    onError,
    false
  );
}

export function asyncGetRankingClientes(dataInicial, dataFinal, page, onSuccess, onError) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: 'date',
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: 'date',
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: 'date' });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: 'date' });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/vendas/vendas_por_cliente_nfe?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}&page=${page}&sort=valorFinalizados,desc&size=10`,
    null,
    onSuccess,
    onError,
    false
  );
}

export function asyncGetRankingVendedores(dataInicial, dataFinal, page, onSuccess, onError) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: 'date',
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: 'date',
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: 'date' });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: 'date' });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/vendas/vendas_por_vendedor_nfe?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}&page=${page}&sort=valorFinalizados,desc&size=10`,
    null,
    onSuccess,
    onError,
    false
  );
}

export function asyncGetNFePorDia(dataInicial, dataFinal, onSuccess, onError) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: 'date',
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: 'date',
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: 'date' });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: 'date' });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/vendas/vendas_por_dia_nfe?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}`,
    null,
    onSuccess,
    onError,
    false
  );
}

export function asyncGetNFeUltimosDozeMeses(dataInicial, dataFinal, onSuccess, onError) {
  let formatedInitialDate = formatISO(new Date(1900, 1, 1), {
    representation: 'date',
  });
  let formatedFinalDate = formatISO(new Date(2100, 1, 1), {
    representation: 'date',
  });

  if (isDate(dataInicial) && isValid(dataInicial)) {
    formatedInitialDate = formatISO(dataInicial, { representation: 'date' });
  }
  if (isDate(dataFinal) && isValid(dataFinal)) {
    formatedFinalDate = formatISO(dataFinal, { representation: 'date' });
  }
  return get(
    `${services.GESTOR}/v1/dashboards/vendas/ultimos_doze_meses_nfe?dataInicial=${formatedInitialDate}&dataFinal=${formatedFinalDate}`,
    null,
    onSuccess,
    onError,
    false
  );
}
