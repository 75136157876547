import { useState } from 'react';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import ModalCategoria from '../../../views/cadastros/Categorias/components/ModalCategoria';
import If from '../../If';

const colourStyles = {
	option: (styles, { data }) => {
		return {
			...styles,
			color: data.registro.aparencia,
			fontWeight: 'bold',
			backgroundColor: 'transparent',
			whiteSpace: 'nowrap',
		};
	},
	singleValue: (styles, { data }) => ({
		...styles,
		color: data.registro?.aparencia,
		fontWeight: 'bold',
	}),
};

export const tiposCategoria = {
	DESPESA: 'DESPESA',
	RECEITA: 'RECEITA',
};

export default function SingleSelectCategoria(props) {
	const { url, tipoCategoria, disabledButton, esconderBotao } = props;

	const [visible, setVisible] = useState(false);
	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.INSERIR)
	);
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.VISUALIZAR)
	);
	const [resetPesquisa, setResetPesquisa] = useState(false);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		switch (tipoCategoria) {
			case tiposCategoria.DESPESA: {
				return `${url}?query=nome=contains="*${pesquisa}*";ativa=="true";despesa=="true"&page=${page}&size=50&sort=nome,asc`;
			}
			case tiposCategoria.RECEITA: {
				return `${url}?query=nome=contains="*${pesquisa}*";ativa=="true";receita=="true"&page=${page}&size=50&sort=nome,asc`;
			}
			default: {
				return `${url}?query=nome=contains="*${pesquisa}*";ativa=="true"&page=${page}&size=50&sort=nome,asc`;
			}
		}
	}

	function buscarUrlRegistroUnico(id) {
		return `${url}?query=id==${id}`;
	}

	function montarLabel(row) {
		return row.nome;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			props.onChange({ label: novoRegistro.nome, value: novoRegistro.id, registro: novoRegistro });
			setResetPesquisa(true);
		}
	}

	function esconderModal() {
		setVisible(false);
	}

	return (
		<>
			<If test={visible}>
				<ModalCategoria tipoCategoria={tipoCategoria} visible={visible} insertJustOne={true} onHide={onHide} />
			</If>
			<SingleSelect
				style={colourStyles}
				buscarUrlPesquisa={buscarUrlPesquisa}
				buscarUrlRegistroUnico={buscarUrlRegistroUnico}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				esconderBotao={esconderBotao}
				disabledButton={!podeInserirEVisualizar || disabledButton}
				titleBotaoNovo={
					podeInserirEVisualizar && !disabledButton
						? 'Adicionar nova categoria'
						: 'Você não possui permissão para executar esta ação'
				}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
				{...props}
			/>
		</>
	);
}

SingleSelectCategoria.defaultProps = {
	label: 'Categoria',
	disabledButton: false,
};

SingleSelectCategoria.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
};
