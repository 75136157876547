import { connect } from 'react-redux';
import propTypes from 'prop-types';
import ResponsiveModal from 'react-responsive-modal';
import { buscarDadosLoginLocalStorage } from '../../Common/Autenticacao';
import 'react-responsive-modal/styles.css';

import './Styles/index.css';

function Modal(props) {
	const {
		showCloseIcon,
		visible,
		closeOnOverlayClick,
		onHide,
		focusTrapped,
		className,
		children,
		header,
		styleOverlay,
		styleModal,
		styleCloseButton,
		styleCloseIcon,
		isMobile,
		width,
		styleHeader,
		hideBackground,
		container = document.body,
	} = props;

	const styles = {
		modal: {
			maxWidth: '1024px',
			minWidth: '0px',
			overflowY: 'visible',
			width: isMobile ? '100%' : width,
			margin: 'auto 0px',
			borderRadius: '5px',
			...styleModal,
		},
		overlay: {
			transform: 'translateZ(0)',
			paddingTop: isMobile ? '0px' : '20px',
			paddingRight: isMobile ? '0px' : '20px',
			paddingBottom: possuiSuporteViaChat() ? '55px' : '0px',
			paddingLeft: isMobile ? '0px' : '20px',
			background: hideBackground ? 'rgb(0 0 0 / 0%)' : 'rgba(0, 0, 0, 0.5)',
			...styleOverlay,
		},
		closeButton: {
			cursor: 'pointer',
			...styleCloseButton,
		},
		closeIcon: {
			...styleCloseIcon,
		},
		header: {
			paddingRight: showCloseIcon ? '20px' : '0px',
			...styleHeader,
		},
	};

	function getCloseOnEsc() {
		if (document.getElementsByClassName('ajs-no-overflow').length > 0) {
			return false;
		}
		return props.closeOnEsc;
	}

	function possuiSuporteViaChat() {
		let organizacao = buscarDadosLoginLocalStorage().organizacao;
		return organizacao?.plano?.suporteChat;
	}

	return (
		<ResponsiveModal
			center
			open={visible}
			closeOnOverlayClick={closeOnOverlayClick}
			onClose={() => onHide()}
			focusTrapped={showCloseIcon ? focusTrapped : false}
			styles={styles}
			showCloseIcon={showCloseIcon}
			closeOnEsc={getCloseOnEsc()}
			className={className}
			container={container}
		>
			<div className="modal">
				{header ? <h1 style={styles.header}>{header}</h1> : null}
				{children}
			</div>
		</ResponsiveModal>
	);
}

Modal.defaultProps = {
	closeOnOverlayClick: false,
	focusTrapped: true,
	width: '85%',
	showCloseIcon: true,
};

Modal.propTypes = {
	/** Define se o componente está visível, propriedade de controle */
	visible: propTypes.bool,
	/** Se deve ser fechado ao clicar fora */
	closeOnOverlayClick: propTypes.bool,
	/** Evento disparado ao fechar */
	onHide: propTypes.func,
	/** Se o foco deve ficar sempre só dentro do modal */
	focusTrapped: propTypes.bool,
	/** Texto do cabeçalho */
	header: propTypes.any,
	/** Tamanho do modal */
	width: propTypes.any,

	styleOverlay: propTypes.object,
	/** Define o estilo do modal */
	styleModal: propTypes.object,
	/** Define o estilo do botão close */
	styleCloseButton: propTypes.object,
	/** Define o estilo do icone */
	styleCloseIcon: propTypes.object,
	/** Define o que acontece quando der esc no modal*/
	closeOnEsc: propTypes.bool,
	/** Nome da classe do componente*/
	className: propTypes.string,
	/** Define o estilo do header */
	styleHeader: propTypes.object,
};

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Modal);
