import propTypes from 'prop-types';
import Button from '..';

function ButtonExcluir(props) {
	const { onClick, hidden, enableShortCut, id, style, label, raised, rounded, titlePersonalizado } = props;

	function getDisabled() {
		if (props.podeExcluir === false) {
			return true;
		}
		return props.disabled;
	}

	function getTitle() {
		if (props.podeExcluir === false) {
			return 'Você não possui permissão para executar essa ação';
		}
		return props.title;
	}

	const titleFormatado = titlePersonalizado ?? getTitle();

	return (
		<Button
			id={id}
			style={{ ...style, margin: '5px' }}
			label={label}
			type="button"
			color="danger"
			disabled={getDisabled()}
			onClick={onClick}
			hidden={hidden}
			title={titleFormatado}
			icon="fa fa-trash-o"
			raised={raised}
			rounded={rounded}
			hotKey={['alt', 'delete']}
			enableShortCut={enableShortCut}
		/>
	);
}

ButtonExcluir.defaultProps = {
	hidden: false,
	disabled: false,
	enableShortCut: true,
	label: 'Excluir',
	title: 'Excluir o registro',
};

ButtonExcluir.propTypes = {
	/** Identificador do componente */
	id: propTypes.string,
	/** Derfine o botão como invisível */
	hidden: propTypes.bool,
	/** Ação executada ao clicar no botão */
	onClick: propTypes.func,
	/** Define o botão como desabilitado */
	disabled: propTypes.bool,
	/** Habilitar atalho */
	enableShortCut: propTypes.bool,
	/** Diz se o usuário possui permissão de excluir*/
	podeExcluir: propTypes.bool,
	/** Title do componente*/
	title: propTypes.string,
	/** Estilo do componente */
	style: propTypes.object,
};

export default ButtonExcluir;
