import { services } from '../../../../Common/Constantes/api';
import { del, get, post, put, exibirToast } from '../../../../Common/Requisicoes';

export async function asyncGetTributacoesEstaduais(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetTributacaoEstadual(idTributacao, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/tributacoes/estadual/${idTributacao}`, null, onSuccess, onError);
}

export async function asyncCreateTributacaoEstadual(tributacao, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/tributacoes/estadual`,
		tributacao,
		null,
		exibirToast(onSuccess, 'Tributação criada'),
		onError
	);
}

export async function asyncUpdateTributacaoEstadual(tributacao, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/tributacoes/estadual/${tributacao.id}`,
		tributacao,
		null,
		exibirToast(onSuccess, 'Tributação atualizada'),
		onError
	);
}

export async function asyncDeleteTributacaoEstadual(idTributacao, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/tributacoes/estadual/${idTributacao}`,
		null,
		exibirToast(onSuccess, 'Tributação excluída'),
		onError
	);
}
