import { useState } from 'react';
import { copiarObjeto, keyFilterConsultaRsql } from 'Common';
import {
	ButtonCancelar,
	ButtonSalvar,
	FormActions,
	Modal,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
	SingleSelectReboque,
} from 'components';
import { Field, useFormikContext } from 'formik';

function ModalReboquesImpl({ informacoesPermissoes, visible, onHide }) {
	const { values, setFieldValue } = useFormikContext();
	const [valuesModalReboques, setValuesModalReboques] = useState(null);

	function handleClickSalvar() {
		if (valuesModalReboques) {
			const newValuesReboques = copiarObjeto(values.reboques);
			newValuesReboques.push(valuesModalReboques.registro);
			setFieldValue('reboques', newValuesReboques);
		}
		onHide();
	}

	function handleClickCancelar() {
		if (valuesModalReboques) {
			setValuesModalReboques(null);
		} else {
			onHide();
		}
	}

	return (
		<Modal header="Cadastro de reboque" visible={visible} onHide={onHide}>
			<FormActions>
				<ButtonCancelar
					estadoBotao={valuesModalReboques ? estadosBotaoCancelar.VOLTAR : estadosBotaoCancelar.CANCELAR}
					onClick={handleClickCancelar}
					{...informacoesPermissoes}
				/>
				<ButtonSalvar
					estadoBotao={estadosBotaoSalvar.SALVAR}
					disabled={!valuesModalReboques}
					onClick={handleClickSalvar}
					{...informacoesPermissoes}
				/>
			</FormActions>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={SingleSelectReboque}
				label="Reboque"
				onChange={(event) => setValuesModalReboques(event)}
				keyfilter={keyFilterConsultaRsql}
				name="reboque"
				size={60}
				styles={{ padding: '8px 0px' }}
				{...informacoesPermissoes}
			/>
		</Modal>
	);
}

export const ModalReboques = ModalReboquesImpl;
