import { Modal } from '../../../index';
import FormModalNovaInutilizacao from './FormModalNovaInutilizacao';

function ModalNovaInutilizacao(props) {
	const { onHide, visible, isMobile, valuesDadosFornecedor, onSave, isFinalizado, mainFormSubmitCount, container } =
		props;

	return (
		<>
			<Modal
				header={'Nova inutilização'}
				onHide={onHide}
				visible={visible}
				styleModal={{
					width: isMobile ? '100%' : '60%',
					height: 'auto',
					overflow: 'auto',
				}}
				container={container}
			>
				<FormModalNovaInutilizacao
					{...props}
					isFinalizado={isFinalizado}
					valuesDadosFornecedor={valuesDadosFornecedor}
					onSave={e => onSave(e)}
					onHide={onHide}
					mainFormSubmitCount={mainFormSubmitCount}
					isMobile={isMobile}
				/>
			</Modal>
		</>
	);
}

export default ModalNovaInutilizacao;
