import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { If, Message } from 'components';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';

const textStyle = {
	margin: '5px 0px',
};

function MessagePlanoExcedidoImpl({ history }) {
	const [podeVisualizarPlanos] = useState(usuarioPossuiPermissao(recursos.PLANOS, permissoes.VISUALIZAR));

	return (
		<Message
			severity="warn"
			colStyle={{ margin: '1em 0em' }}
			text={
				<div>
					<h2
						style={{ fontWeight: 'bold' }}
						title="Você não pode mais incluir novas informações, porém ainda poderá visualizar, editar e excluir as informações existentes"
					>
						Seu plano expirou
					</h2>
					<p style={textStyle}>
						Não foi possível efetuar a cobrança da mensalidade do seu plano. Atualize-o para o plano gratuito ou
						regularize seu pagamento para continuar usando o sistema.
					</p>
					<If test={podeVisualizarPlanos}>
						<span onClick={() => history.push('/planos')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
							Ir para plano e preços
						</span>
					</If>
				</div>
			}
		/>
	);
}

export const MessagePlanoExcedido = withRouter(MessagePlanoExcedidoImpl);
