import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import {
	usuarioPossuiModulos,
	renderizarValidacao,
	buscarDisabledDeAcordoComAsPermissoes,
	buscarHiddenDeAcordoComAsPermissoes,
	buscarTitleCampoDeAcordoComAsPermissoes,
	buscarClassNamePelosErros,
} from '../../../../../Common';

import { Button, Col, If } from '../../../..';

const shortcutStyle = {
	marginLeft: '4px',
	fontSize: 10,
};

function InternalInputFieldWithButton(props) {
	const {
		id,
		type,
		name,
		title,
		placeholder,
		className,
		onBlur,
		onKeyDown,
		errors,
		touched,
		sm,
		md,
		lg,
		xl,
		keyfilter,
		podeVisualizar,
		podeInserir,
		podeEditar,
		estadoCadastro,
		disabled,
		colId,
		col,
		onFocus,
		step,
		min,
		autoFocus,
		floatLabel,
		colClassName,
		warning,
		size,
		obrigatorio,
		labelSize,
		style,
		pattern,
		shortcut,
		onClick,
		onSubmit,
	} = props;

	const [validouModulo, setValidouModulo] = useState(true);

	useEffect(() => {
		if (props.modulosEspecificos) {
			setValidouModulo(usuarioPossuiModulos(props.modulosEspecificos));
		}
	});

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden)) {
			return { display: 'none', ...props.colStyle };
		}
		return { ...props.colStyle, textOverflow: 'ellipsis', overflow: 'hidden' };
	}

	function modificarOnChangeParaNull(e) {
		return {
			target: {
				value: null,
				name: e.target.name,
			},
		};
	}

	function onChange(e) {
		if (e.target.value === '') {
			props.onChange(modificarOnChangeParaNull(e));
		} else if (e.target.value.length > size) {
			const value = String(e.target.value).substr(0, size);
			props.onChange({
				target: {
					value: value,
					name: e.target.name,
				},
			});
		} else {
			props.onChange(e);
		}
	}

	function getValue() {
		return props.value || props.value === 0 ? props.value : '';
	}

	function montarLabel() {
		const label = props.floatLabel || props.label;
		if (obrigatorio) {
			return (
				<span style={{ display: 'flex', alignItems: 'center' }}>
					<label style={{ fontSize: labelSize }} title={props.helpMessage}>
						{label}
						<b
							style={{
								fontSize: labelSize || '18px',
								lineHeight: '5px',
							}}
						>
							*
						</b>
					</label>
					{shortcut && <span style={{ ...shortcutStyle }}>{shortcut}</span>}
				</span>
			);
		}
		return (
			<label style={{ display: 'flex', alignItems: 'center', fontSize: labelSize }} title={props.helpMessage}>
				{label}
				{shortcut && <span style={{ ...shortcutStyle }}>{shortcut}</span>}
			</label>
		);
	}

	function getStyle() {
		return {
			borderRadius: '3px 0px 0px 3px',
			height: style?.height ? style.height : '32px',
			...style,
		};
	}

	if (!validouModulo) {
		return null;
	}

	function onInternalKeyDown(e) {
		if (onKeyDown) onKeyDown(e);
		else if (e.key === 'Enter') {
			onSubmit();
		}
	}

	return (
		<Col className={colClassName} id={colId} col={col} sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			<If test={!floatLabel}>{montarLabel()}</If>

			<span
				title={buscarTitleCampoDeAcordoComAsPermissoes(podeVisualizar, podeInserir, podeEditar, estadoCadastro, title)}
				className="p-fluid"
			>
				<div className="p-inputgroup">
					<InputText
						id={id}
						keyfilter={keyfilter}
						type={type}
						name={name}
						step={step}
						min={min}
						placeholder={placeholder}
						value={getValue()}
						onClick={onClick}
						onChange={onChange}
						onFocus={onFocus}
						className={buscarClassNamePelosErros(className, errors, touched)}
						disabled={buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled)}
						style={getStyle()}
						onBlur={onBlur}
						onKeyDown={onInternalKeyDown}
						autoFocus={autoFocus}
						autoComplete="off"
						pattern={pattern}
					/>
					<If test={floatLabel}>
						<label htmlFor={id}>{montarLabel()}</label>
					</If>
					<Button
						type="button"
						icon="fa fa-plus"
						disabled={disabled}
						onClick={onSubmit}
						style={{
							boxShadow: 'none',
							height: '32px',
							zIndex: 1,
							borderRadius: '0px 3px 3px 0px',
						}}
					/>
				</div>
			</span>
			{renderizarValidacao(errors, touched, warning)}
		</Col>
	);
}

InternalInputFieldWithButton.defaultProps = {
	value: '',
	onChange: () => {},
	onClick: () => {},
	size: 99999,
	obrigatorio: false,
};

export { InternalInputFieldWithButton };
