import { useState } from 'react';
import { modulos } from '../../../../Common';
import { If } from '../../../../components';
import ListagemRelatorios from '../../../../components/ListagemRelatorios';
import ModalComissaoAnalitico from './components/ModalComissaoAnalitico';
import ModalComissaoSintetico from './components/ModalComissaoSintetico';
import ModalFaturamentoPorPeriodo from './components/ModalFaturamentoPorPeriodo';
import ModalPedidosPorPeriodo from './components/ModalPedidosPorPeriodo';
import ModalProdutosPorPedido from './components/ModalProdutosPorPedido';

function VendasRelatorios() {
	const [exibirModalFaturamentoPorPeriodo, setExibirModalFaturamentoPorPeriodo] = useState(false);
	const [exibirModalPedidosPorPeriodo, setExibirModalPedidosPorPeriodo] = useState(false);
	const [exibirModalProdutosPorPedido, setExibirModalProdutosPorPedido] = useState(false);
	const [exibirModalComissaoAnalitico, setExibirModalComissaoAnalitico] = useState(false);
	const [exibirModalComissaoSintetico, setExibirModalComissaoSintetico] = useState(false);

	const metodosImprimir = [
		{
			nome: 'FATURAMENTO_PERIODO',
			onImprimir: function () {
				setExibirModalFaturamentoPorPeriodo(true);
			},
		},
		{
			nome: 'PEDIDOS_PERIODO',
			onImprimir: function () {
				setExibirModalPedidosPorPeriodo(true);
			},
		},
		{
			nome: 'PRODUTOS_PEDIDOS_PERIODO',
			onImprimir: function () {
				setExibirModalProdutosPorPedido(true);
			},
		},
		{
			nome: 'COMISSAO_ANALITICO',
			onImprimir: function () {
				setExibirModalComissaoAnalitico(true);
			},
		},
		{
			nome: 'COMISSAO_SINTETICO',
			onImprimir: function () {
				setExibirModalComissaoSintetico(true);
			},
		},
	];

	return (
		<>
			<ListagemRelatorios header="Relatórios de vendas" modulo={modulos.VENDAS} metodosImprimir={metodosImprimir} />
			<If test={exibirModalFaturamentoPorPeriodo}>
				<ModalFaturamentoPorPeriodo
					visible={exibirModalFaturamentoPorPeriodo}
					onHide={() => setExibirModalFaturamentoPorPeriodo(false)}
				/>
			</If>
			<If test={exibirModalPedidosPorPeriodo}>
				<ModalPedidosPorPeriodo
					visible={exibirModalPedidosPorPeriodo}
					onHide={() => setExibirModalPedidosPorPeriodo(false)}
				/>
			</If>
			<If test={exibirModalProdutosPorPedido}>
				<ModalProdutosPorPedido
					visible={exibirModalProdutosPorPedido}
					onHide={() => setExibirModalProdutosPorPedido(false)}
				/>
			</If>
			<If test={exibirModalComissaoAnalitico}>
				<ModalComissaoAnalitico
					visible={exibirModalComissaoAnalitico}
					onHide={() => setExibirModalComissaoAnalitico(false)}
				/>
			</If>
			<If test={exibirModalComissaoSintetico}>
				<ModalComissaoSintetico
					visible={exibirModalComissaoSintetico}
					onHide={() => setExibirModalComissaoSintetico(false)}
				/>
			</If>
		</>
	);
}

export default VendasRelatorios;
