import { Modal } from '../../../../../../components';
import FormDadosFornecedor from './FormDadosFornecedor';

function ModalDadosFornecedor(props) {
	const { onHide, visible, isMobile, valuesDadosFornecedor, onSave, isFinalizado, mainFormSubmitCount } = props;

	return (
		<>
			<Modal
				header={'Dados do fornecedor'}
				onHide={onHide}
				visible={visible}
				styleModal={{
					minWidth: '60%',
					height: isMobile ? '85%' : 'auto',
				}}
			>
				<FormDadosFornecedor
					isFinalizado={isFinalizado}
					valuesDadosFornecedor={valuesDadosFornecedor}
					onSave={e => onSave(e)}
					onHide={onHide}
					mainFormSubmitCount={mainFormSubmitCount}
					isMobile={isMobile}
				/>
			</Modal>
		</>
	);
}

export default ModalDadosFornecedor;
