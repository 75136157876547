import { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { GoBookmark } from 'react-icons/go';

import { removerElemento, copiarObjeto, estadosCadastro, formatarTelefone } from '../../../../../../../Common';

import {
  ButtonExcluirTable,
  ButtonNovo,
  Modal,
  If,
  ButtonEditarTable,
  NenhumRegistroEncontrado,
  Grid,
} from '../../../../../../../components';

import PessoaContatoForm from './Form';

function PessoaContato(props) {
  const { estadoCadastro, appendTo } = props;

  const desabilitarCampos = estadoCadastro === estadosCadastro.EDICAO && !props.podeEditar;

  const [registroSelecionado, setregistroSelecionado] = useState(null);
  const [modalFormVisible, setModalFormVisible] = useState(false);

  function novo() {
    if (props.setHideBackground) {
      props.setHideBackground(true);
    }
    setregistroSelecionado(null);
    setModalFormVisible(true);
  }

  function editar(row) {
    if (props.setHideBackground) {
      props.setHideBackground(true);
    }
    setregistroSelecionado(row);
    setModalFormVisible(true);
  }

  function renderOpcoes(row) {
    return (
      <div>
        <ButtonEditarTable onClick={() => editar(row)} />
        <ButtonExcluirTable onClick={() => excluirRegistro(row)} podeExcluir={!desabilitarCampos} />
      </div>
    );
  }

  function hideModal() {
    if (props.setHideBackground) {
      props.setHideBackground(false);
    }
    setregistroSelecionado(null);
    setModalFormVisible(false);
  }

  function atualizarRegistro(dadosFormulario) {
    const contatos = copiarObjeto(props.value);
    const result =
      contatos &&
      contatos.map(item => {
        if (item.idTemporario || item.id) {
          if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
            return dadosFormulario;
          } else if (item.id && item.id === dadosFormulario.id) {
            return dadosFormulario;
          }

          if (dadosFormulario.favorito) {
            item.favorito = false;
          }

          return item;
        } else {
          return dadosFormulario;
        }
      });
    setregistroSelecionado(dadosFormulario);
    props.onChange(result);
  }

  function inserirRegistro(dadosFormulario) {
    let contatos = copiarObjeto(props.value);
    props.onChange(mergeContatoIntoContatos(dadosFormulario, contatos));
    setregistroSelecionado(dadosFormulario);
  }

  function mergeContatoIntoContatos(contato, contatos) {
    if (contato.favorito) {
      contatos.map(tempContato => {
        return (tempContato.favorito = false);
      });
    }
    return [...contatos, contato];
  }

  function excluirRegistro(dadosFormulario) {
    props.onChange(removerElemento(props.value, dadosFormulario));
    setregistroSelecionado(null);
  }

  function marcarComoFavorito() {
    const contatos = copiarObjeto(props.value);
    let existemFavoritos = false;

    contatos?.forEach(contato => {
      if (contato.favorito) {
        existemFavoritos = true;
      }
    });

    return !existemFavoritos;
  }

  function renderNome(row) {
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {row.nome}
        {row.favorito ? (
          <span>
            <GoBookmark size={22} color="#fbc02d" title="Contato favorito" />
          </span>
        ) : null}
      </span>
    );
  }

  return (
    <>
      <Modal visible={modalFormVisible} header="Cadastro de contato" onHide={hideModal} container={appendTo}>
        <If test={modalFormVisible}>
          <PessoaContatoForm
            registroSelecionado={registroSelecionado}
            onNovoClick={() => setregistroSelecionado(null)}
            inserirRegistro={inserirRegistro}
            atualizarRegistro={atualizarRegistro}
            excluirRegistro={excluirRegistro}
            marcarComoFavorito={marcarComoFavorito}
            onHide={hideModal}
            podeEditar={!desabilitarCampos}
            podeExcluir={!desabilitarCampos}
          />
        </If>
      </Modal>

      <Grid style={{ padding: '0.5rem 0rem' }}>
        <ButtonNovo label="Novo contato" enableShortCut={false} onClick={novo} podeInserir={!desabilitarCampos} />
      </Grid>

      <DataTable
        className="table"
        responsive
        value={props.value}
        emptyMessage={<NenhumRegistroEncontrado message="Nenhum contato encontrado" />}
      >
        <Column field="nome" body={renderNome} header="Nome" sortable={true} />
        <Column field="email" header="E-mail" sortable={true} />
        <Column field="telefone" header="Telefone" body={row => formatarTelefone(row.telefone)} sortable={true} />
        <Column body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
      </DataTable>
    </>
  );
}

export default PessoaContato;
