import { Field, withFormik } from 'formik';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import * as Yup from 'yup';
import { mensagensDeValidacao } from '../../../../../Common/Constantes/mensagens';
import Button from '../../../../../components/Button';
import Col from '../../../../../components/Col';
import FormGroup from '../../../../../components/FormGroup/index';
import Grid from '../../../../../components/Grid/index';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import Modal from '../../../../../components/Modal';
import { ToastTypes, notify } from '../../../../../components/Toast';
import InputDouble from '../../../../../components/input/InputDouble';
import Dropdown from '../../../../../components/select/Dropdown';
import { validarFormulario } from '../../../../Util';
import { helpConfiguracoesForm } from '../../Help';
import { asyncBuscarAliquotaSimples, asyncSalvarAliquotaSimples } from '../../Requests';
import { converterAliquotaSimplesParaApi } from '../../Util/configuracoesGeraisConverter';
import { INITIAL_VALUES } from './Util';

function ModalAliquotaSimplesView({ values, dirty, resetForm, onHide, visible, setFieldValue, ...props }) {
	const [loading, setLoading] = useState(true);

	useEffectOnce(() => {
		buscarAliquota();
	});

	function buscarAliquota() {
		asyncBuscarAliquotaSimples(
			({ data }) => {
				setLoading(false);
				resetForm({
					values: {
						...data,
						aliquotaSimples: data.percentualAliquota,
						mesVigencia: data.mesVigencia,
					},
				});
			},
			() => {
				setLoading(false);
			}
		);
	}

	async function salvarAliquota() {
		if (await validarFormulario(props)) {
			asyncSalvarAliquotaSimples(converterAliquotaSimplesParaApi(values), () => {
				onHide();
				notify('Alíquota atualizada', ToastTypes.SUCCESS);
			});
		}
	}

	return (
		<Modal header="Alíquota do simples nacional" visible={visible} onHide={() => onHide()}>
			{loading ? (
				<ProgressSpinner style={{ display: 'flex' }} />
			) : (
				<FormGroup>
					<Col>
						<AutoProgressBar />
					</Col>
					<Col sm="12" md="12" lg="12" xl="12">
						A lei complementar 155/2016 determina que a alíquota do simples nacional pode sofrer alteração mensal de
						acordo com o faturamento da empresa.
						<br /> Caso tenha dúvidas em relação ao percentual da sua alíquota entre em contato com sua contabilidade!
					</Col>
					<Col sm="12" md="12" lg="12" xl="12">
						<Grid>
							<Field
								sm="12"
								md="4"
								lg="4"
								xl="4"
								label="Alíquota do simples nacional"
								name="aliquotaSimples"
								value={values.aliquotaSimples}
								onChange={(e) => setFieldValue('aliquotaSimples', e.target.value)}
								size={6}
								component={InputDouble}
								decimalScale={2}
								allowNegative={false}
								helpMessage={helpConfiguracoesForm.aliquotaSimples}
								suffix=" %"
								maxValue={100}
							/>
							<Field
								sm="12"
								md="6"
								lg="6"
								xl="6"
								component={Dropdown}
								label="Mês de vigência"
								obrigatorio
								value={values.mesVigencia}
								showClear={false}
								name="mesVigencia"
								onChange={(e) => setFieldValue('mesVigencia', e.value)}
								helpMessage={helpConfiguracoesForm.mesVigencia}
								options={[
									{ label: 'Janeiro', value: 1 },
									{ label: 'Fevereiro', value: 2 },
									{ label: 'Março', value: 3 },
									{ label: 'Abril', value: 4 },
									{ label: 'Maio', value: 5 },
									{ label: 'Junho', value: 6 },
									{ label: 'Julho', value: 7 },
									{ label: 'Agosto', value: 8 },
									{ label: 'Setembro', value: 9 },
									{ label: 'Outubro', value: 10 },
									{ label: 'Novembro', value: 11 },
									{ label: 'Dezembro', value: 12 },
								]}
							/>
						</Grid>
					</Col>
					<Col sm="12" md="12" lg="12" xl="12">
						<Button
							color="success"
							label="Salvar nova alíquota"
							icon="fa fa-check"
							disabled={!dirty}
							onClick={salvarAliquota}
						/>
					</Col>
				</FormGroup>
			)}
		</Modal>
	);
}

const ModalAliquotaSimples = withFormik({
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validationSchema: Yup.object().shape({
		aliquotaSimples: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		mesVigencia: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalAliquotaSimplesView);

export default ModalAliquotaSimples;
