import { infoStatusNfe } from '../../../Util/constantes';
import { FaKey } from 'react-icons/fa';
import { Col, If, notify, ToastTypes } from '../../../../../../../components';
import { copiarParaAreaTransferencia } from '../../../../../../../Common';

const secaoStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '0 1.5rem 0 0',
};

const numeroStyle = {
	fontSize: '24px',
	color: '#006095',
	fontWeight: 'bold',
};

const visualizarVendaStyle = {
	color: '#006095',
	fontWeight: 'bold',
	display: 'flex',
	margin: '0.2rem 0 0.5rem 0',
	fontSize: '12px',
};

const styleStatus = {
	height: '38px',
	borderRadius: '25px',
	fontWeight: 'bold',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '14px',
};

const styleName = {
	textAlign: 'center',
	fontWeight: 'bold',
	padding: '2px 0px',
};

const styleChave = {
	textAlign: 'center',
	cursor: 'pointer',
	flex: '1 1',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	padding: '2px 0px',
	fontSize: '12px',
};

export default function NfeNumeroCard(props) {
	const { isMobile, history, values, podeVisualizarPedido } = props;

	const backgroundColor = values.status ? infoStatusNfe[values.status].lightColor : null;
	const color = values.status ? infoStatusNfe[values.status].strongColor : null;
	const name = values.status ? infoStatusNfe[values.status].name : null;
	const description = values.status ? infoStatusNfe[values.status].description : null;

	return (
		<>
			<Col sm="5" lg="6" xl="6">
				<div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
					<div style={secaoStyle}>
						<div>Série</div>
						<div
							style={{...numeroStyle, color : color}}
							title={values.serie ? 'Série definida nas configurações do sistema' : 'Série não configurada'}
						>
							<span>{values.serie || '?'}</span>
						</div>
					</div>
					<div
						style={secaoStyle}
						title={!values.numero ? 'O número da nota é gerados automaticamente pelo sistema' : null}
					>
						<div>Número</div>
						<div style={{...numeroStyle, color : color}}>{values.numero || '-'}</div>
					</div>
				</div>
				<If test={values.pedidoId}>
					<div style={{ ...visualizarVendaStyle, justifyContent: isMobile ? 'center' : 'flex-start' }}>
						{podeVisualizarPedido ? (
							<span
								onClick={values ? () => history.push('/pedido_venda/cadastro/' + values.pedidoId) : null}
								className="link_to"
							>
								Visualizar pedido (N° {values.pedidoNumero})
							</span>
						) : (
							<span>Pedido N° {values.pedidoNumero}</span>
						)}
					</div>
				</If>
			</Col>
			<Col sm="7" lg="4" xl="4">
				<If test={values.chave}>
					<>
						<div title={description} style={{ ...styleStatus, backgroundColor, color }}>
							<div style={styleName}>{name}</div>
						</div>
						<div
							title="Clique para copiar"
							style={{ ...styleChave, color }}
							onClick={() => {
								copiarParaAreaTransferencia(values.chave);
								notify('Chave de acesso copiada para área de transferência', ToastTypes.SUCCESS);
							}}
						>
							<FaKey /> {values.chave}
						</div>
					</>
				</If>
				<If test={!values.chave}>
					<div title={description} style={{ ...styleStatus, backgroundColor, color }}>
						<div style={styleName}>{name}</div>
					</div>
				</If>
			</Col>
		</>
	);
}
