export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é o cadastro de Operações Fiscais, neste cadastro são criadas e configuradas as operações que serão utilizadas nas movimentações de saída e entrada.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-favorito',
		content:
			'Aqui você pode destacar uma operação como favorita para que leve automaticamente para as movimentações.',
		placement: 'top',
	},
	{
		target: '.step-campos',
		content:
			'E nesta área serão realizadas todas as configurações da operação fiscal, como por exemplo: Se a movimentação vai gerar financeiro, estoque ou se é uma movimentação de saída/entrada.',
	},
];
