import { createContext, useContext } from 'react';
import { estadosCadastro, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { connect } from 'react-redux';

const ContextCondicaoPagamento = createContext();

function CondicaoPagamentoProvider({ children, history, match, isMobile, isTablet }) {
	const podeInserir = usuarioPossuiPermissao(recursos.CONDICOES_PAGAMENTO, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.CONDICOES_PAGAMENTO, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.CONDICOES_PAGAMENTO, permissoes.EXCLUIR);

	const idCondicaoPagamento = match?.params?.id;

	const informacoesPermissoes = {
		estadoCadastro: match?.params?.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir,
		podeEditar,
		podeExcluir,
	};

	return (
		<ContextCondicaoPagamento.Provider
			value={{
				podeInserir,
				podeEditar,
				podeExcluir,
				informacoesPermissoes,
				idCondicaoPagamento,
				history,
				isMobile,
				isTablet,
			}}
		>
			{children || null}
		</ContextCondicaoPagamento.Provider>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(CondicaoPagamentoProvider);
export const useContextCondicaoPagamento = () => useContext(ContextCondicaoPagamento);
