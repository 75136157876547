import {
	buscarDadosLoginLocalStorage,
	copiarObjeto,
	estadosCadastro,
	permissoes,
	recursos,
	usuarioPossuiPermissao,
} from 'Common';
import { FormActions, FormContent, If, Modal, TabView } from 'components';
import { addMonths, formatISO, parseISO } from 'date-fns';
import { useFormikContext, withFormik } from 'formik';
import { TabPanel } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TIPO_CONTA_PAGAR } from 'views/financas/ContasPagar/Util/constantes';
import FormPagar from '../FormContasPagarReceber/FormPagar';
import FormReceber from '../FormContasPagarReceber/FormReceber';
import ActionButtons from './components/ActionButtons';
import FormTitulosAntigos from './components/FormTitulosAntigos';
import {
	asyncGetProximoIdentificador,
	buscarCategoriaFavoritaDespesasContaPagar,
	buscarCategoriaFavoritaReceitasContaReceber,
	buscarContaFavoritaDespesasContaPagar,
	buscarContaFavoritaReceitasContaReceber,
	buscarFormaPagamentoDinheiroContaPagar,
	buscarFormaPagamentoDinheiroContaReceber,
} from './Requests';
import { INITIAL_VALUES_PAGAR, INITIAL_VALUES_RECEBER } from './Utils/constantes';

function ModalReparcelamentoTitulosView({
	visible,
	onHide,
	isMobile,
	documentosSelecionados,
	valorPadraoDataVencimento = null,
	setDocumentosSelecionados,
	origem,
}) {
	const { values, resetForm } = useFormikContext();

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EXCLUIR));

	const [exibirModalPagamento, setExibirModalPagamento] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [exibirModalExclusao, setExibirModalExclusao] = useState(false);
	const [exibirModalConfirmacaoEdicao, setExibirModalConfirmacaoEdicao] = useState(false);
	const [activeIndex, setActiveIndex] = useState([]);
	const [disabledFieldReparcelamento, setDisabledFieldReparcelamento] = useState(true);

	const { filialConectada } = buscarDadosLoginLocalStorage();

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};

	useEffect(() => {
		atribuirValoresIniciais();
	}, []);

	function calcularTotalReparcelamento(newValues) {
		const total = documentosSelecionados.reduce((accumulator, item) => accumulator + item.valor, 0).toFixed(2);
		const totalNumerico = parseFloat(total);

		if (!newValues) {
			resetForm({ values: { ...values, valor: totalNumerico, valorTitulos: totalNumerico } });
		} else {
			resetForm({ values: { ...newValues, valor: totalNumerico, valorTitulos: totalNumerico } });
		}
	}

	function buscarValor() {
		if (values.tipo === TIPO_CONTA_PAGAR.VALOR_PARCELADO) {
			return Math.trunc((values.valor / values.quantidadeRepeticoes) * 100) / 100;
		} else {
			return values.valor;
		}
	}

	async function atribuirValoresIniciais() {
		let identificador = null;
		let vencimento = null;
		let categoria = null;
		let formaPagamento = null;
		let conta = null;
		let contaPago = null;
		let taxaCategoria = null;
		let pessoa = null;

		vencimento = valorPadraoDataVencimento
			? formatISO(valorPadraoDataVencimento)
			: formatISO(addMonths(parseISO(values.emissao) || new Date(), 1));

		try {
			const [identificadorData, categoriaData, formaPagamentoData, contaData, pessoaData] = await Promise.all([
				origem === 'CONTAS_RECEBER'
					? new Promise((resolve) => {
							asyncGetProximoIdentificador(resolve);
						})
					: null,

				origem === 'CONTAS_RECEBER'
					? new Promise((resolve) => {
							buscarCategoriaFavoritaReceitasContaReceber(resolve);
						})
					: new Promise((resolve) => {
							buscarCategoriaFavoritaDespesasContaPagar(resolve);
						}),

				origem === 'CONTAS_RECEBER'
					? new Promise((resolve) => {
							buscarFormaPagamentoDinheiroContaReceber(resolve);
						})
					: new Promise((resolve) => {
							buscarFormaPagamentoDinheiroContaPagar(resolve);
						}),

				origem === 'CONTAS_RECEBER'
					? new Promise((resolve) => {
							buscarContaFavoritaReceitasContaReceber(resolve);
						})
					: new Promise((resolve) => {
							buscarContaFavoritaDespesasContaPagar(resolve);
						}),

				new Promise((resolve) => {
					buscarPessoaDosTitulos(resolve);
				}),
			]);

			if (identificadorData) {
				identificador = identificadorData.data.identificador;
			}

			if (categoriaData?.data?.totalElements > 0) {
				const categoriaItem = categoriaData.data.content[0];
				categoria = {
					label: categoriaItem.nome,
					value: categoriaItem.id,
					registro: categoriaItem,
				};
			}

			if (formaPagamentoData?.data?.totalElements > 0) {
				const formaPagamentoItem = formaPagamentoData.data.content[0];
				formaPagamento = {
					label: formaPagamentoItem.descricao,
					value: formaPagamentoItem.id,
					registro: formaPagamentoItem,
				};
			}

			if (contaData?.data?.totalElements > 0) {
				const contaItem = contaData.data.content[0];
				contaPago = {
					label: contaItem.nome,
					value: contaItem.id,
					registro: contaItem,
				};
				conta = copiarObjeto(contaPago);
			}

			if (filialConectada?.parametrosFinancas?.taxaCategoria) {
				const categoriaTaxa = filialConectada?.parametrosFinancas?.taxaCategoria;
				taxaCategoria = {
					label: categoriaTaxa.nome,
					value: categoriaTaxa.id,
					registro: categoriaTaxa,
				};
			}

			if (pessoaData) {
				pessoa = pessoaData;
				setDisabledFieldReparcelamento(true);
			} else {
				setDisabledFieldReparcelamento(false);
			}

			const newValues = {
				...values,
				identificador,
				vencimento,
				categoria,
				formaPagamento,
				conta,
				taxaCategoria,
				pessoa,
			};

			calcularTotalReparcelamento(newValues);
		} catch (error) {
			console.error(error);
		}
	}

	async function buscarPessoaDosTitulos(resolve) {
		const pessoa =
			documentosSelecionados
				.filter((documento) => documento.pessoaId)
				.map((documento) => ({
					label: `${documento.pessoaCodigo} - ${documento.pessoaNome}`,
					value: documento.pessoaId,
					registro: {
						id: documento.pessoaId,
						nome: documento.pessoaNome,
						codigo: documento.pessoaCodigo,
					},
				}))[0] || null;

		resolve(pessoa);
	}

	return (
		<Modal header="Reparcelamento de títulos" visible={visible} onHide={onHide}>
			<FormActions>
				<ActionButtons {...{ onHide, documentosSelecionados, origem }} />
			</FormActions>
			<FormContent>
				<TabView className="tab-view-no-border">
					<TabPanel header="Novo título">
						<If test={origem === 'CONTAS_RECEBER'}>
							<FormReceber
								isModal
								isReparcelamento
								onHideModal={onHide}
								onNovoClick={null}
								isEdicao={false}
								onHideModalExclusao={null}
								onHideModalEdicao={null}
								{...{
									isMobile,
									history,
									atribuirValoresIniciais,
									buscarValor,
									podeEditar,
									registroSelecionado,
									setRegistroSelecionado,
									exibirModalExclusao,
									setExibirModalExclusao,
									exibirModalConfirmacaoEdicao,
									setExibirModalConfirmacaoEdicao,
									exibirModalPagamento,
									setExibirModalPagamento,
									activeIndex,
									setActiveIndex,
									filialConectada,
									informacoesPermissoes,
									origem,
									disabledFieldReparcelamento,
								}}
							/>
						</If>
						<If test={origem === 'CONTAS_PAGAR'}>
							<FormPagar
								isModal
								isReparcelamento
								onHideModal={onHide}
								onNovoClick={null}
								isEdicao={false}
								onHideModalExclusao={null}
								onHideModalEdicao={null}
								{...{
									isMobile,
									history,
									atribuirValoresIniciais,
									buscarValor,
									podeEditar,
									registroSelecionado,
									setRegistroSelecionado,
									exibirModalExclusao,
									setExibirModalExclusao,
									exibirModalConfirmacaoEdicao,
									setExibirModalConfirmacaoEdicao,
									exibirModalPagamento,
									setExibirModalPagamento,
									activeIndex,
									setActiveIndex,
									filialConectada,
									informacoesPermissoes,
									origem,
								}}
							/>
						</If>
					</TabPanel>
					<TabPanel header="Títulos antigos">
						<FormTitulosAntigos
							{...{ documentosSelecionados, setDocumentosSelecionados, calcularTotalReparcelamento, origem }}
						/>
					</TabPanel>
				</TabView>
			</FormContent>
		</Modal>
	);
}

const ModalReparcelamentoTitulos = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.origem === 'CONTAS_RECEBER') {
			return INITIAL_VALUES_RECEBER;
		} else {
			return INITIAL_VALUES_PAGAR;
		}
	},

	handleSubmit: () => {},
})(ModalReparcelamentoTitulosView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default withRouter(connect(mapStateToProps)(ModalReparcelamentoTitulos));
