import { useEffect, useState } from 'react';
import { withFormik, Field } from 'formik';
import stringMask from 'string-mask';
import * as Yup from 'yup';

import {
	FormGroup,
	estadosBotaoSalvar,
	estadosBotaoCancelar,
	Grid,
	Paper,
	LayoutMenuSuperior,
	MenuSuperior,
	ButtonNovo,
	ButtonSalvar,
	ButtonCancelar,
	InputField,
	ButtonExcluir,
	Checkbox,
	Dropdown,
	InputMask,
	FormActions,
} from 'components';

import { mensagensDeValidacao, estadosCadastro, gerarUUID, buscarMascaraTelefone, manterApenasNumeros } from 'Common';

import { helpPessoaTelefoneForm } from './Help';
import { validarFormulario } from '../../../../../../../Util';
import { OPERADORAS } from '../../../../../Util/constantes';

const initialValue = {
	id: '',
	numero: '',
	operadora: null,
	observacao: '',
	favorito: false,
	idTemporario: null,
};

function PessoaTelefoneForm(props) {
	const { values, appendTo } = props;
	const [inserindoNovoRegistro, setInserindoNovoRegistro] = useState(false);

	const informacoesPermissoes = {
		estadoCadastro: inserindoNovoRegistro ? estadosCadastro.INCLUSAO : estadosCadastro.EDICAO,
		podeInserir: props.podeEditar,
		podeEditar: props.podeEditar,
		podeExcluir: props.podeEditar,
	};

	useEffect(() => {
		setInserindoNovoRegistro(!props.registroSelecionado);

		setTimeout(() => {
			if (document.getElementById('pessoaInputTelefone')) {
				document.getElementById('pessoaInputTelefone').focus();
			}
		}, 500);
	}, []);

	function novo() {
		props.onNovoClick();
		props.resetForm({ values: initialValue });
		setInserindoNovoRegistro(true);
	}

	function excluir() {
		props.excluirRegistro(props.registroSelecionado);
		props.onHide();
	}

	async function salvar() {
		props.handleSubmit();
		if (await validarFormulario(props)) {
			if (!props.validarMesmoTelefone(props.values)) {
				if (inserindoNovoRegistro) {
					criarRegistro();
				} else {
					atualizarRegistro();
				}
			} else {
				props.setFieldError('numero', 'Este número já existe');
			}
		}
	}

	function criarRegistro() {
		props.inserirRegistro({ ...props.values, idTemporario: gerarUUID() });
		props.onHide();
	}

	function atualizarRegistro() {
		props.atualizarRegistro(props.values);
		props.onHide();
	}

	function cancelar() {
		if (props.dirty) {
			props.handleReset();
		} else {
			props.onHide();
		}
	}

	return (
		<>
			<MenuSuperior isModal={true}>
				<FormActions>
					<ButtonCancelar
						{...informacoesPermissoes}
						estadoBotao={props.dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={cancelar}
					/>
					<ButtonSalvar
						estadoBotao={estadosBotaoSalvar.CONFIRMAR}
						{...informacoesPermissoes}
						disabled={!props.dirty}
						onClick={salvar}
					/>
					<ButtonNovo onClick={novo} hidden={inserindoNovoRegistro} {...informacoesPermissoes} />
					<ButtonExcluir
						hidden={inserindoNovoRegistro}
						{...informacoesPermissoes}
						podeExcluir={props.podeExcluir}
						onClick={excluir}
					/>
				</FormActions>
			</MenuSuperior>
			<LayoutMenuSuperior isModal={true}>
				<Paper dirty={props.dirty} {...informacoesPermissoes} childsOnly={true}>
					<FormGroup>
						<Grid style={{ height: 'auto' }}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Checkbox}
								label="Telefone favorito"
								title="Marque esta opção caso o telefone seja o favorito"
								name="favorito"
								helpMessage={helpPessoaTelefoneForm.favorito}
								onChange={(e) => props.setFieldValue('favorito', !props.values.favorito)}
								checked={values.favorito}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="6"
								lg="4"
								xl="4"
								id="pessoaInputTelefone"
								component={InputMask}
								mask={buscarMascaraTelefone(values.numero)}
								placeholder={'(  )      -    '}
								label="Número"
								obrigatorio
								value={values.numero}
								name="numero"
								onChange={(e) => props.setFieldValue('numero', e.target.value)}
								helpMessage={helpPessoaTelefoneForm.numero}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="4"
								lg="4"
								xl="4"
								component={Dropdown}
								label="Operadora"
								name="operadora"
								helpMessage={helpPessoaTelefoneForm.operadora}
								showClear={false}
								onChange={(e) => props.setFieldValue('operadora', e.value)}
								options={OPERADORAS}
								menuPortalTarget={appendTo}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={InputField}
								type="text"
								label="Observação"
								name="observacao"
								{...informacoesPermissoes}
							/>
						</Grid>
					</FormGroup>
				</Paper>
			</LayoutMenuSuperior>
		</>
	);
}

PessoaTelefoneForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return { ...props.registroSelecionado };
		}
		return { ...initialValue, favorito: props.marcarComoFavorito() };
	},

	validationSchema: Yup.object().shape({
		numero: Yup.string().required(mensagensDeValidacao.OBRIGATORIO).nullable(),
	}),

	validate(values) {
		let errors = {};

		if (
			!new stringMask('(00) 00000-0000').validate(manterApenasNumeros(values.numero)) &&
			!new stringMask('(00) 0000-0000').validate(manterApenasNumeros(values.numero))
		) {
			errors.numero = 'Digite um numero válido.';
		}
		return errors;
	},

	handleSubmit: () => {},
})(PessoaTelefoneForm);

export default PessoaTelefoneForm;
