import { buscarDadosLoginLocalStorage, usuarioPossuiModulo } from '../../../../Common/Autenticacao';
import { modulos } from '../../../../Common/Constantes/autorizacao';
import { manterApenasNumeros } from '../../../../Common/Mascara';

function converterPessoaParaFormulario(pessoa) {
	return {
		id: pessoa.id,

		cliente: pessoa.cliente,
		fornecedor: pessoa.fornecedor,
		transportador: pessoa.transportador,

		codigo: pessoa.codigo,

		nome: pessoa.nome,
		situacao: pessoa.situacao,
		tipo: pessoa.tipo,
		observacao: pessoa.observacao,
		enderecos: pessoa.enderecos,
		emails: pessoa.emails,
		telefones: pessoa.telefones,
		contatos: pessoa.contatos,
		cnaes: pessoa.cnaes,
		inscricaoEstadual: pessoa.inscricaoEstadual,
		indicadorInscricaoEstadual: pessoa.indicadorInscricaoEstadual ? pessoa.indicadorInscricaoEstadual : null,
		consumidorFinal: pessoa.consumidorFinal,
		limiteCredito: pessoa.limiteCredito,
		//fisica
		cpf: pessoa.pessoaFisica ? pessoa.pessoaFisica.cpf : null,
		rg: pessoa.pessoaFisica ? pessoa.pessoaFisica.rg : null,
		genero: pessoa.pessoaFisica ? pessoa.pessoaFisica.genero : null,
		nascimento: pessoa.pessoaFisica ? pessoa.pessoaFisica.nascimento : null,
		pessoaPai: pessoa.pessoaFisica ? pessoa.pessoaFisica.pessoaPai : null,
		pessoaMae: pessoa.pessoaFisica ? pessoa.pessoaFisica.pessoaMae : null,
		pessoaConjuge: pessoa.pessoaFisica ? pessoa.pessoaFisica.pessoaConjuge : null,
		localTrabalho: pessoa.pessoaFisica ? pessoa.pessoaFisica.localTrabalho : null,
		//juridica
		cnpj: pessoa.pessoaJuridica ? pessoa.pessoaJuridica.cnpj : null,
		razaoSocial: pessoa.pessoaJuridica ? pessoa.pessoaJuridica.razaoSocial : null,
		simplesNacional: pessoa.pessoaJuridica ? pessoa.pessoaJuridica.simplesNacional : null,
		inscricaoSuframa: pessoa.pessoaJuridica ? pessoa.pessoaJuridica.inscricaoSuframa : null,
		inscricaoMunicipal: pessoa.pessoaJuridica ? pessoa.pessoaJuridica.inscricaoMunicipal : null,
		//estrangeira
		identificacao: pessoa.pessoaEstrangeira ? pessoa.pessoaEstrangeira.identificacao : null,

		campoObrigatorioParaDocumentoFiscal: usuarioPossuiModulo(modulos.VENDAS),

		imagem: pessoa.imagem ? pessoa.imagem : null,
		imagemUrl: pessoa.imagemUrl ? pessoa.imagemUrl : null,

		//configuracoes
		configPrecoPraticado: pessoa.configPrecoPraticado ? pessoa.configPrecoPraticado : 'PRECO_VENDA',

		filiais: converterFiliaisParaFormulario(pessoa.filiais),
		pessoaFilialConectada: converterFilialConectadaParaFormulario(pessoa.pessoaFilialConectada),
	};
}

function converterFiliaisParaFormulario(filiais) {
	const filialId = buscarDadosLoginLocalStorage().filialConectada.id;
	const filiaisFormulario = filiais?.map((filial) => {
		return {
			id: filial.id,
			filial: {
				id: filial.filialId,
				filialCodigo: filial.filialCodigo,
				filialNome: filial.filialNome,
				filialNomeApresentacao: filial.filialNomeApresentacao,
			},
			tabelaPreco: converterTabelaPrecoParaSelect(filial.tabelaPreco),
			filialConectada: filialId === filial.filialId ? true : false,
		};
	});
	return filiaisFormulario;
}

function converterFilialConectadaParaFormulario(filial) {
	if (filial) {
		return {
			id: filial.id,
			filial: {
				id: filial.filialId,
				filialCodigo: filial.filialCodigo,
				filialNome: filial.filialNome,
				filialNomeApresentacao: filial.filialNomeApresentacao,
			},
			tabelaPreco: converterTabelaPrecoParaSelect(filial.tabelaPreco),
		};
	}

	return null;
}

function converterPessoaFisicaParaApi(pessoa) {
	if (pessoa.tipo === 'FISICA') {
		return {
			cpf: manterApenasNumeros(pessoa.cpf),
			rg: pessoa.rg,
			genero: pessoa.genero,
			nascimento: pessoa.nascimento,
			pessoaPai: pessoa.pessoaPai,
			pessoaMae: pessoa.pessoaMae,
			pessoaConjuge: pessoa.pessoaConjuge,
			localTrabalho: pessoa.localTrabalho,
		};
	} else {
		return null;
	}
}

function converterPessoaEstrangeiraParaApi(pessoa) {
	if (pessoa.tipo === 'ESTRANGEIRO') {
		return {
			identificacao: pessoa.identificacao,
		};
	} else {
		return null;
	}
}

function converterPessoaJuridicaParaApi(pessoa) {
	if (pessoa.tipo === 'JURIDICA') {
		return {
			cnpj: manterApenasNumeros(pessoa.cnpj),
			razaoSocial: pessoa.razaoSocial,
			simplesNacional: pessoa.simplesNacional,
			inscricaoSuframa: pessoa.inscricaoSuframa,
			inscricaoMunicipal: pessoa.inscricaoMunicipal,
		};
	} else {
		return null;
	}
}

function converterPessoaParaApi(pessoa) {
	return {
		id: pessoa.id,
		cliente: pessoa.cliente,
		fornecedor: pessoa.fornecedor,
		transportador: pessoa.transportador,
		nome: pessoa.nome,
		situacao: pessoa.situacao,
		tipo: pessoa.tipo,
		observacao: pessoa.observacao,
		enderecos: pessoa.enderecos,
		emails: pessoa.emails,
		cnaes: pessoa.cnaes?.length > 0 ? pessoa.cnaes : [],
		limiteCredito: pessoa.limiteCredito ?? 0,
		consumidorFinal: pessoa.consumidorFinal ?? false,
		inscricaoEstadual: pessoa.inscricaoEstadual,
		indicadorInscricaoEstadual: pessoa.indicadorInscricaoEstadual,
		imagem: pessoa.imagem,
		telefones: removerMascaraTelefones(pessoa.telefones),
		contatos: removerMascaraContatos(pessoa.contatos),
		pessoaFisica: converterPessoaFisicaParaApi(pessoa),
		pessoaJuridica: converterPessoaJuridicaParaApi(pessoa),
		pessoaEstrangeira: converterPessoaEstrangeiraParaApi(pessoa),
		configPrecoPraticado: pessoa.configPrecoPraticado,
		pessoaFilialConectada: converterFilialConectadaParaApi(pessoa.pessoaFilialConectada),
	};
}

function converterFilialConectadaParaApi(pessoaFilialConectada) {
	if (pessoaFilialConectada) {
		return {
			id: pessoaFilialConectada.id,
			filialId: pessoaFilialConectada?.filial ? pessoaFilialConectada.filial.id : null,
			tabelaPreco: pessoaFilialConectada?.tabelaPreco ? { id: pessoaFilialConectada.tabelaPreco.value } : null,
		};
	}
	return null;
}

function removerMascaraTelefones(telefones) {
	if (telefones) {
		return telefones.map((telefone) => {
			return {
				...telefone,
				numero: manterApenasNumeros(telefone.numero),
			};
		});
	}
	return null;
}

function removerMascaraContatos(contatos) {
	if (contatos) {
		return contatos.map((contato) => {
			return {
				...contato,
				telefone: manterApenasNumeros(contato.telefone),
			};
		});
	}
	return null;
}

function converterPessoaParaDuplicar(pessoa) {
	return {
		id: null,
		cliente: pessoa.cliente,
		fornecedor: pessoa.fornecedor,
		transportador: pessoa.transportador,

		codigo: pessoa.codigo,

		nome: pessoa.nome,
		situacao: pessoa.situacao,
		tipo: pessoa.tipo,
		observacao: pessoa.observacao,

		enderecos: converterPessoaEnderecosDuplicada(pessoa.enderecos),
		emails: converterPessoaEmailsDuplicada(pessoa.emails),
		telefones: converterPessoaTelefonesDuplicada(pessoa.telefones),
		contatos: converterPessoaContatosDuplicada(pessoa.contatos),
		cnaes: converterPessoaCnaesDuplicada(pessoa.cnaes),

		inscricaoEstadual: pessoa.inscricaoEstadual,
		indicadorInscricaoEstadual: pessoa.indicadorInscricaoEstadual ?? null,
		consumidorFinal: pessoa.consumidorFinal,
		limiteCredito: pessoa.limiteCredito,
		//fisica
		cpf: pessoa.pessoaFisica?.cpf ?? null,
		rg: pessoa.pessoaFisica?.rg ?? null,
		genero: pessoa.pessoaFisica?.genero ?? null,
		nascimento: pessoa.pessoaFisica?.nascimento ?? null,
		pessoaPai: pessoa.pessoaFisica?.pessoaPai ?? null,
		pessoaMae: pessoa.pessoaFisica?.pessoaMae ?? null,
		pessoaConjuge: pessoa.pessoaFisica?.pessoaConjuge ?? null,
		localTrabalho: pessoa.pessoaFisica?.localTrabalho ?? null,
		//juridica
		cnpj: pessoa.pessoaJuridica?.cnpj ?? null,
		razaoSocial: pessoa.pessoaJuridica?.razaoSocial ?? null,
		simplesNacional: pessoa.pessoaJuridica?.simplesNacional ?? null,
		inscricaoSuframa: pessoa.pessoaJuridica?.inscricaoSuframa ?? null,
		inscricaoMunicipal: pessoa.pessoaJuridica?.inscricaoMunicipal ?? null,
		//estrangeira
		identificacao: pessoa.pessoaEstrangeira?.identificacao ?? null,

		campoObrigatorioParaDocumentoFiscal: usuarioPossuiModulo(modulos.VENDAS),

		imagem: null,
		imagemUrl: null,

		//configuracoes
		configPrecoPraticado: pessoa.configPrecoPraticado ?? 'PRECO_VENDA',
	};
}

function converterPessoaEnderecosDuplicada(enderecos) {
	if (enderecos.length > 0) {
		return enderecos?.map((endereco) => {
			return { ...endereco, id: null };
		});
	}

	return [];
}
function converterPessoaEmailsDuplicada(emails) {
	if (emails.length > 0) {
		return emails?.map((email) => {
			return { ...email, id: null };
		});
	}

	return [];
}
function converterPessoaTelefonesDuplicada(telefones) {
	if (telefones.length > 0) {
		return telefones?.map((telefone) => {
			return { ...telefone, id: null };
		});
	}

	return [];
}
function converterPessoaContatosDuplicada(contatos) {
	if (contatos.length > 0) {
		return contatos?.map((contato) => {
			return { ...contato, id: null };
		});
	}

	return [];
}
function converterPessoaCnaesDuplicada(cnaes) {
	if (cnaes.length > 0) {
		return cnaes?.map((cnae) => {
			return { ...cnae, id: null };
		});
	}

	return [];
}

function converterTabelaPrecoParaSelect(tabelaPreco) {
	if (tabelaPreco) {
		return {
			label: `${tabelaPreco.codigo} - ${tabelaPreco.nome}`,
			value: tabelaPreco.id,
			registro: tabelaPreco,
		};
	}
	return null;
}

export { converterPessoaParaApi, converterPessoaParaFormulario, converterPessoaParaDuplicar };
