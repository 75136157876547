function converteBoletosParaFormulario(boletos) {
  const boletosConvertidos = [];

  if (boletos?.length > 0) {
    boletos.forEach(boleto => {
      boletosConvertidos.push({
        id: boleto.id,
        identificador: boleto.identificador,
        descricao: boleto.descricao,
        emissao: boleto.emissao,
        vencimento: boleto.vencimento,
        pessoa: boleto.pessoa ? boleto.pessoa.nome : null,
        pessoaCodigo: boleto.pessoa ? boleto.pessoa.codigo : null,
        pessoaEmail: converterPessoaEmailParaFormulario(boleto.pessoa?.emails),
        valor: boleto.valor,
        aReceber: boleto.valorAReceber,
        categoria: boleto.categoria
          ? {
              nome: boleto.categoria.nome,
              aparencia: boleto.categoria.aparencia,
            }
          : null,
        boleto: boleto.boleto ? boleto.boleto : null,
      });
    });
  }
  return boletosConvertidos;
}

function converterPessoaEmailParaFormulario(emails) {
  let emailFavorito = '';
  if (emails?.length > 0) {
    emails?.forEach(email => {
      emailFavorito = email.favorito === true ? `${email.email}` : null;
    });
  }

  if (emailFavorito) {
    return emailFavorito;
  } else if (emails?.length > 0) {
    return emails[0]?.email;
  }
  return null;
}

function converterBoletosEmailParaApi(email) {
  const convertedEmail = {
    assunto: email.assunto,
    corpoEmail: email.corpoEmail,
    destinatarios: converterDestinatariosEmailParaApi(email.destinatarios),
    contaReceberIds: email.contaReceberIds,
  };

  return convertedEmail;
}

function converterDestinatariosEmailParaApi(destinatarios) {
  return destinatarios
    .split(',')
    .map(item => item.trim())
    .filter(item => item.trim() !== '');
}

export { converteBoletosParaFormulario, converterBoletosEmailParaApi };
