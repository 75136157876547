import { post, get } from "../../Requisicoes";
import { services } from "../../Constantes/api";

const credenciaisServico = {
  auth: {
    username: "gestor-web-client",
    password: "nVCqNKauJKvRNUU8",
  },
};

export async function asyncfazerLogin(
  email,
  password,
  onSuccess,
  onError,
  tratarErros
) {
  let body = new FormData();

  body.set("grant_type", "password");
  body.set("username", email);
  body.set("password", password);
  body.set("client_id", "gestor-web-client");

  return await post(
    services.GESTOR + "/v1/login",
    body,
    credenciaisServico,
    onSuccess,
    onError,
    true,
    tratarErros
  );
}

export async function asyncFazerLogout(onSuccess, onError) {
  return await post(
    services.GESTOR + "/v1/logout",
    null,
    null,
    onSuccess,
    onError,
    true,
    false
  );
}

export async function asyncBuscarInformacoesCredencial(onSuccess, onError) {
  return await get(
    services.GESTOR + "/v1/credenciais/minha_credencial",
    null,
    onSuccess,
    onError,
    false,
    false
  );
}

export async function asyncResetarOrganizacaoConectada(onSuccess, onError) {
  return await post(
    `${services.GESTOR}/v1/credenciais/reset_organizacao_conectada`,
    null,
    null,
    onSuccess,
    onError,
    true,
    false
  );
}
