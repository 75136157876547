import propTypes from 'prop-types';
import If from '../If';
import { BsInfoCircle } from 'react-icons/bs';

export default function HelpTooltip(props) {
	const { message } = props;

	return (
		<If test={message}>
			<span style={{ display: 'inline-flex', margin: '0px 5px' }}>
				<BsInfoCircle title={message} style={{ color: '#333333' }} />
			</span>
		</If>
	);
}

HelpTooltip.propTypes = {
	/** Recebe a mensagem que será renderizada no ícone de pergunta. */
	message: propTypes.string,
};
