import { services } from 'Common';
import { Grid, SingleSelectVendedorListagem, TextArea } from 'components';
import { Field } from 'formik';
import { useContextNFCe } from 'views/fiscal/vendas/NFCe/Context';

function InformacoesComplementares({ disabled, setFieldValue, errors, values }) {
	const { informacoesPermissoes } = useContextNFCe();

	return (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={SingleSelectVendedorListagem}
				name="vendedor"
				label="Vendedor responsável"
				obrigatorio
				disabled={disabled}
				isClearable={false}
				url={`${services.GESTOR}/v1/nfce/relacoes/vendedores`}
				value={values.vendedor}
				errors={errors?.vendedor}
				useFormErrors={false}
				useFormTouched={false}
				touched
				onChange={(event) => setFieldValue('vendedor', event)}
				esconderBotao
				{...informacoesPermissoes}
			/>
			<Field
				component={TextArea}
				name="observacao"
				maxLength={255}
				placeholder="Escreva aqui a sua observação"
				disabled={disabled}
				onChange={(event) => setFieldValue('observacao', event.target.value)}
			/>
		</Grid>
	);
}

export { InformacoesComplementares };
