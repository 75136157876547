import { get } from '../../../../Common/Requisicoes';
import { services } from '../../../../Common/Constantes/api';

export async function asyncBaixarRelatorioContasAPagarPorPeriodo(
	dataInicial,
	dataFinal,
	contaSituacao,
	credorId,
	filiaisSelecionadas,
	formaPagamento,
	onSuccess,
	onError
) {
	return await get(
		`${
			services.GESTOR
		}/v1/contas_pagar/imprimir/contas_a_pagar_por_periodo?dataInicial=${dataInicial}&dataFinal=${dataFinal}&contaSituacao=${contaSituacao}${
			credorId ? '&credorId=' + credorId : ''
		}&filiaisSelecionadas=${filiaisSelecionadas}&formaPagamento=${formaPagamento}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncBaixarRelatorioContasAReceberPorPeriodo(
	dataInicial,
	dataFinal,
	contaSituacao,
	devedorId,
	filiaisSelecionadas,
	formaPagamento,
	onSuccess,
	onError
) {
	return await get(
		`${
			services.GESTOR
		}/v1/contas_receber/imprimir/contas_a_receber_por_periodo?dataInicial=${dataInicial}&dataFinal=${dataFinal}&contaSituacao=${contaSituacao}${
			devedorId ? '&devedorId=' + devedorId : ''
		}&filiaisSelecionadas=${filiaisSelecionadas}&formaPagamento=${formaPagamento}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncBaixarRelatorioMovimentacoesPorPeriodo(
	dataInicial,
	dataFinal,
	contaId,
	formaPagamento,
	onSuccess,
	onError
) {
	return await get(
		`${
			services.GESTOR
		}/v1/lancamentos/imprimir/movimentacoes_por_periodo?dataInicial=${dataInicial}&dataFinal=${dataFinal}&contaId=${
			contaId || ''
		}&formaPagamento=${formaPagamento || ''}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncBaixarRelatorioReceitasPorCategoria(dataInicial, dataFinal, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/contas_receber/receitas_por_categorias/imprimir?dataInicial=${dataInicial}&dataFinal=${dataFinal}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncBaixarRelatorioDespesasPorCategoria(
	dataInicial,
	dataFinal,
	categoriaFormatada,
	onSuccess,
	onError
) {
	return await get(
		`${services.GESTOR}/v1/contas_pagar/despesas_por_categorias/imprimir?dataInicial=${dataInicial}&dataFinal=${dataFinal}&categoriaFormatada=${categoriaFormatada}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncBaixarRelatorioContasAReceberInformeRendimento(
	anoRelatorio,
	pagadorId,
	beneficiarioId,
	aliquota,
	informacoesComplementares,
	onSuccess,
	onError
) {
	return await get(
		`${services.GESTOR}/v1/contas_receber/imprimir/contas_a_receber_informe_rendimento?anoRelatorio=${anoRelatorio}&pagadorId=${pagadorId}&beneficiarioId=${beneficiarioId}&aliquota=${aliquota}&informacoesComplementares=${informacoesComplementares}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}
