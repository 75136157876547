import { services } from '../../../../Common/Constantes/api';
import { get } from '../../../../Common/Requisicoes';

export async function asyncImprimirPagamentosRecebidosPorPeriodo(dataInicial, dataFinal, formasPagamento, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/organizacoes/pagamentos_recebidos_por_periodo/imprimir?datainicial=${dataInicial}&datafinal=${dataFinal}&formaspagamento=${formasPagamento}`, { responseType: 'arraybuffer' }, onSuccess, onError);
}


export async function asyncImprimirComissaoPorAgenteAutorizado(agenteautorizado, dataInicial, dataFinal, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/agentes_autorizados/comissao_por_agente_autorizado/imprimir?agenteautorizado=${agenteautorizado}&datainicial=${dataInicial}&datafinal=${dataFinal}`, { responseType: 'arraybuffer' }, onSuccess, onError);
}