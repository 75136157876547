import { get } from '../Requisicoes';
import { services } from '../Constantes/api';
import { tipoOperacao } from '../../components/header/Util/constantes';

export async function asyncGetOperacaoFiscalFavorita(tipoOp, onSuccess, onError) {
	const url =
		tipoOp === tipoOperacao.SAIDA
			? `${services.GESTOR}/v1/tributacoes/operacoes_fiscais/favorito/saida`
			: `${services.GESTOR}/v1/tributacoes/operacoes_fiscais/favorito/entrada`;

	return await get(url, null, onSuccess, onError);
}
