import { useEffect, useState } from 'react';
import { services } from '../../../Common/Constantes/api';
import ModalTributacaoEstadual from '../../../views/cadastros/TributacaoEstadual/Modal';
import SingleSelect from '../SingleSelect';

export default function SingleSelectTributacaoEstadual(props) {
  const { label, isClearable, errors, touched, isMobile, service, setHideBackground } = props;
  const [visible, setVisible] = useState(false);
  const [resetPesquisa, setResetPesquisa] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (setHideBackground) {
      setHideBackground(visible);
    }
  }, [visible]);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  function buscarUrlPesquisa(pesquisa, page) {
    let query = `?query=(descricao=contains="*${pesquisa}*",codigo=contains="${pesquisa}*");situacao=="ATIVO"&page=${page}&size=50`;

    return `${services.GESTOR}/v1/tributacoes/estadual/resumo${query}`;
  }

  function mostrarModal() {
    setVisible(!visible);
  }

  function esconderModal() {
    setVisible(false);
  }

  function onHide(novoRegistro) {
    setarNovoRegistroNoSelect(novoRegistro);
    esconderModal();
  }

  function montarLabel(row) {
    return row.codigo + ' - ' + row.descricao;
  }

  function setarNovoRegistroNoSelect(novoRegistro) {
    if (novoRegistro?.id && isMounted) {
      props.onChange(
        {
          label: montarLabel({
            codigo: novoRegistro.codigo,
            descricao: novoRegistro.descricao,
          }),
          registro: {
            id: novoRegistro.id,
            codigo: novoRegistro.codigo,
            descricao: novoRegistro.descricao,
            situacao: novoRegistro.situacao,
          },
          value: novoRegistro.id,
        },
        props.index
      );
      setResetPesquisa(true);
    }
  }

  return (
    <>
      <ModalTributacaoEstadual
        service={service}
        visible={visible}
        onHide={onHide}
        setHideBackground={setHideBackground}
      />
      <SingleSelect
        titleBotaoNovo={'Adicionar nova operação fiscal'}
        buscarUrlPesquisa={buscarUrlPesquisa}
        montarLabel={montarLabel}
        onClickModal={mostrarModal}
        label={label}
        onChange={props.onChange}
        isClearable={isClearable}
        useFormErrors={false}
        useFormTouched={false}
        errors={errors}
        touched={touched}
        styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
        menuPortalTarget={document.body}
        placeholder={isMobile ? 'Selecione uma opção' : 'Digite para pesquisar'}
        {...props}
        resetPesquisa={resetPesquisa}
        setResetPesquisa={setResetPesquisa}
      />
    </>
  );
}

SingleSelectTributacaoEstadual.defaultProps = {
  label: 'Tributação estadual',
};
