import { isValidCNPJ } from '@brazilian-utils/brazilian-utils';
import { manterApenasNumeros } from 'Common';
import { Dropdown, InputField, InputMask, ToastTypes, notify } from 'components';
import InputCnpjWithButton from 'components/input/InputCnpjWithButton';
import { Field, useFormikContext } from 'formik';
import { asyncGetConsultaPorCnpj } from 'views/administracao/Organizacoes/Requests';
import { converterFilialToForm } from '../../../../Utils/filialConverter';

function InformacoesPrincipais(props) {
	const { informacoesPermissoes } = props;
	const { values, errors, setFieldError, setValues, setFieldValue } = useFormikContext();

	function onChangeTipo(e) {
		setFieldValue('tipo', e);
		setFieldValue('cpf', null);
		setFieldValue('cnpj', null);
	}

	function onKeyUp(e) {
		if (e.keyCode === 13) {
			consultarCnpj();
		}
	}

	function onClickPesquisarCnpj() {
		consultarCnpj();
	}

	function consultarCnpj() {
		if (isValidCNPJ(manterApenasNumeros(values.cnpj))) {
			asyncGetConsultaPorCnpj(
				values.cnpj,
				({ data: empresa }) => {
					const empresaConvertida = converterFilialToForm({
						...values,
						...empresa,
					});

					setValues(empresaConvertida);
					setFieldError('cnpj', null);
				},
				() => {
					notify('CNPJ não encontrado, verifique', ToastTypes.ERROR);
				}
			);
		} else {
			setFieldError('cnpj', 'Digite um CNPJ válido.');
		}
	}

	return (
		<>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				obrigatorio
				component={InputField}
				value={values.codigo}
				label="Código"
				name="codigo"
				onChange={(e) => setFieldValue('codigo', e.target.value)}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="9"
				lg="5"
				xl="5"
				obrigatorio
				component={InputField}
				value={values.nome}
				label="Nome"
				name="nome"
				onChange={(e) => setFieldValue('nome', e.target.value)}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="9"
				lg="5"
				xl="5"
				obrigatorio
				component={InputField}
				value={values.nomeApresentacao}
				label="Nome de apresentação"
				name="nomeApresentacao"
				onChange={(e) => setFieldValue('nomeApresentacao', e.target.value)}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="9"
				lg="5"
				xl="5"
				obrigatorio
				component={InputField}
				value={values.razaoSocial}
				label="Razão social"
				name="razaoSocial"
				onChange={(e) => setFieldValue('razaoSocial', e.target.value)}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				obrigatorio
				component={Dropdown}
				value={values.situacao}
				label="Situação"
				name="situacao"
				onChange={(e) => setFieldValue('situacao', e)}
				colStyle={{ padding: '4px 8px' }}
				options={[
					{ label: 'Ativa', value: 'ATIVA' },
					{ label: 'Inativa', value: 'INATIVA' },
				]}
				showClear={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				obrigatorio
				component={Dropdown}
				value={values.tipo}
				label="Tipo"
				name="tipo"
				onChange={(e) => onChangeTipo(e)}
				colStyle={{ padding: '4px 8px' }}
				options={[
					{ label: 'Física', value: 'FISICA' },
					{ label: 'Jurídica', value: 'JURIDICA' },
				]}
				showClear={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="3"
				xl="3"
				hidden={values?.tipo?.value !== 'FISICA'}
				component={InputMask}
				mask="000.000.000-00"
				placeholder="   .   .   -  "
				label="CPF"
				obrigatorio
				name="cpf"
				onChange={(e) => setFieldValue('cpf', e.target.value)}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="3"
				xl="3"
				component={InputCnpjWithButton}
				mask="00.000.000/0000-00"
				placeholder="  .   .   /    -  "
				label="CNPJ"
				name="cnpj"
				hidden={values.tipo.value !== 'JURIDICA'}
				onChange={(e) => setFieldValue('cnpj', e.value)}
				value={values.cnpj}
				onKeyUp={onKeyUp}
				onClick={onClickPesquisarCnpj}
				errors={errors.cnpj ? errors.cnpj : null}
				touched={errors.cnpj ? errors.cnpj : null}
				colStyle={{ padding: '4px 8px' }}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export default InformacoesPrincipais;
