import { useEffect } from 'react';
import { buscarHiddenDeAcordoComAsPermissoes } from '../../../Common/Autorizacao/ManipulacaoDeComponentes';
import useStateCallback from '../../../Common/Hooks/useStateCallback';
import { renderizarValidacao } from '../../../Common/TratamentoDeErro/validacoesDeCampos';
import Col from '../../Col';
import If from '../../If';
import InputMask from '../InputMask';
import './Styles/index.css';

export const tipos = {
	CPF: 'CPF',
	CNPJ: 'CNPJ',
};

const inputStyle = {
	width: 'calc(100% - 54px)',
	padding: '0px',
};

const alteraCpfOuCnpj = {
	fontSize: '18px',
	height: '32px',
	width: '55px',
};

const styleDiv = {
	alignItems: 'flex-end',
};

function InputSelectCpfOrCnpj(props) {
	const {
		name,
		value,
		colId,
		colClassName,
		col,
		sm,
		md,
		lg,
		xl,
		style,
		labelSize,
		valueDocumentoTipo,
		errors,
		disabled,
		obrigatorio,
		helpMessage,
		restart,
		onRestart,
	} = props;

	const [cnpj, setCnpj] = useStateCallback('');
	const [cpf, setCpf] = useStateCallback('');

	useEffect(() => {
		if (restart && onRestart) {
			setCnpj('');
			onRestart();
		}
	});

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden))
			return { display: 'none', ...props.colStyle };
		return props.colStyle;
	}

	function onChangeTipoDocumento(e) {
		if (valueDocumentoTipo === tipos.CPF) {
			setCnpj('', props.onChangeDocumentoTipo(tipos.CNPJ));
		} else {
			setCpf('', props.onChangeDocumentoTipo(tipos.CPF));
		}
	}

	function changeValor(e) {
		const documento = e.target.value;
		props.onChange(documento);
	}

	return (
		<Col id={colId} className={colClassName} col={col} sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			<div className="p-inputgroup" style={styleDiv}>
				<If test={valueDocumentoTipo === tipos.CPF}>
					<InputMask
						style={{ ...style, width: '100%' }}
						colStyle={inputStyle}
						label="CPF"
						labelSize={labelSize}
						obrigatorio={obrigatorio}
						name={name}
						placeholder="   .   .   -  "
						value={value}
						helpMessage={helpMessage}
						onChange={changeValor}
						mask="000.000.000-00"
						disabled={disabled}
						autoComplete="off"
					/>
				</If>
				<If test={valueDocumentoTipo === tipos.CNPJ}>
					<InputMask
						style={{ ...style, width: '100%' }}
						colStyle={inputStyle}
						label="CNPJ"
						obrigatorio={obrigatorio}
						labelSize={labelSize}
						helpMessage={helpMessage}
						name={name}
						mask="00.000.000/0000-00"
						placeholder="  .   .   /    -  "
						value={value}
						onChange={changeValor}
						disabled={disabled}
						autoComplete="off"
					/>
				</If>
				<span
					color="secondary"
					onClick={disabled ? null : onChangeTipoDocumento}
					style={style ? style : alteraCpfOuCnpj}
					className="altera-cnpj-ou-cpf"
					title="Clique aqui para alternar modo de entrada"
				>
					<>
						{valueDocumentoTipo === tipos.CPF ? (
							<span className="altera-cnpj-ou-cpf-text">CPF</span>
						) : (
							<span className="altera-cnpj-ou-cpf-text">CNPJ</span>
						)}
						<i className="fa fa-refresh" aria-hidden="true" />
					</>
				</span>
			</div>
			{renderizarValidacao(errors, errors ? true : false)}
		</Col>
	);
}

export default InputSelectCpfOrCnpj;
