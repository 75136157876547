import { useEffect, useRef, useState } from 'react';
import { ButtonExcluirTable, Divider, If, SingleSelectCondicaoPagamento } from 'components';
import Grid from 'components/Grid';
import { useGenerateParcelas } from 'components/Pagamentos/Hooks/useGenerateParcelas';
import { FINANCEIRO_TIPO } from 'components/Pagamentos/Util/constantes';
import { convertObjectToSelectValue, divideValorPorQuantidade } from 'components/Pagamentos/Util/functions';
import { Field, useFormikContext } from 'formik';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/CondicaoPagamento/Util/constantes';
import './Styles/index.css';
import { copiarObjeto } from 'Common';
import { FieldsAPrazo } from './components/FieldsAPrazo';
import { FieldsAVista } from './components/FieldsAVista';

function Pagamento({
	idOrigem,
	valorTotalPagamentos,
	value,
	indexPagamento,
	length,
	urls,
	favoritos,
	dataBaseParcela,
	disabledFields,
	informacoesPermissoes,
	classNameTabView,
	onExcluirPagamento,
	onChangePagamento,
	onChangePagamentoField,
	handleClickEstornar,
	hideTabFinanceiro,
	financeiroTipo,
	indicacaoMovimento,
	isMobile,
	isTablet,
	isLessHd,
	color,
	setRecalcularValoresPagamentos,
	setRecalcularTodosOsProdutosComTabelaPreco,
	setRecalcularTodosOsServicosComTabelaPreco,
}) {
	const { values, errors, setFieldValue } = useFormikContext();

	const [internalFavoritos, setInternalFavoritos] = useState(favoritos);
	const prevValor = useRef(null);

	const isAVista = value.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_VISTA;
	const isAPrazo = value.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
	const hasMoreOnePagamento = length > 1;
	const isFirstAndUniqueParcelaAPrazo = length === 1 && indexPagamento === 0 && isAPrazo;

	const [generateParcelas] = useGenerateParcelas({
		qtdParcelas: value.quantidadeParcelas?.value,
		parcelas: value.parcelas,
		valorPagamento: value.valor,
		dataBaseParcela,
		favoritos: internalFavoritos,
	});

	useEffect(() => {
		if (value.condicaoPagamento?.registro?.formaPagamento) {
			const newFavoritos = copiarObjeto(internalFavoritos);
			const formaPagamento = value.condicaoPagamento?.registro?.formaPagamento;

			newFavoritos.formaPagamento = convertObjectToSelectValue(formaPagamento);

			if (formaPagamento.conta) {
				newFavoritos.conta = convertObjectToSelectValue(formaPagamento.conta);
			}

			setInternalFavoritos(newFavoritos);
		}
	}, []);

	function handleChangeCondicaoPagamento(e) {
		const pagamento = {
			...value,
			condicaoPagamento: e,
		};
		const { tipo, formaPagamento } = e.registro;
		const isPrevSemPagamento = value.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO;
		const categoriaConvertida =
			(formaPagamento?.categoriaReceita || formaPagamento?.categoriaDespesa) &&
			financeiroTipo === FINANCEIRO_TIPO.CONTA_RECEBER
				? convertObjectToSelectValue(formaPagamento?.categoriaReceita)
				: convertObjectToSelectValue(formaPagamento?.categoriaDespesa);

		if (e && tipo === CONDICAO_PAGAMENTO_TIPO.A_VISTA) {
			pagamento.quantidadeParcelas = 0;
			pagamento.formaPagamento = formaPagamento
				? convertObjectToSelectValue(formaPagamento)
				: value.formaPagamento ?? internalFavoritos.formaPagamento;
			pagamento.categoria = categoriaConvertida || (value.categoria ?? internalFavoritos.categoria);
			pagamento.parcelas = [];
			pagamento.conta = formaPagamento?.conta
				? convertObjectToSelectValue(formaPagamento.conta)
				: value.conta ?? internalFavoritos.conta;
		}

		if (e && tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO) {
			const qtdParcelas = e.registro.parcelaPadrao ?? e.registro.parcelaMinima ?? 1;

			pagamento.formaPagamento = null;
			pagamento.quantidadeParcelas = {
				label: `${qtdParcelas}x`,
				value: qtdParcelas,
			};
			pagamento.categoria = categoriaConvertida || (value.categoria ?? internalFavoritos.categoria);
			pagamento.parcelas = generateParcelas({ qtdParcelas }).map((parcela) => ({
				...parcela,
				formaPagamento: formaPagamento
					? convertObjectToSelectValue(formaPagamento)
					: parcela.formaPagamento ?? internalFavoritos.categoria,
				conta: formaPagamento?.conta ? convertObjectToSelectValue(formaPagamento.conta) : parcela.conta,
			}));
		}

		if (e && tipo === CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO) {
			pagamento.formaPagamento = null;
			pagamento.conta = null;
			pagamento.categoria = null;
			pagamento.quantidadeParcelas = null;
			pagamento.valor = 0;
			pagamento.parcelas = [];
		}

		if (e && isPrevSemPagamento && tipo !== CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO) {
			if (tipo === CONDICAO_PAGAMENTO_TIPO.A_VISTA) {
				pagamento.valor = valorTotalPagamentos;
				pagamento.categoria = internalFavoritos.categoria;
				pagamento.conta = internalFavoritos.conta;
			}
			if (tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO) {
				const qtdParcelas = e.registro.parcelaPadrao ?? e.registro.parcelaMinima ?? 1;
				const { valorPorQuantidade, valorUltimaQuantidade } = divideValorPorQuantidade(
					valorTotalPagamentos,
					qtdParcelas
				);

				pagamento.categoria = internalFavoritos.categoria;
				pagamento.valor = valorTotalPagamentos;
				pagamento.parcelas?.forEach((_parcela, index) => {
					if (index === pagamento.parcelas.length - 1) {
						pagamento.parcelas[index].valor = valorUltimaQuantidade;
					} else {
						pagamento.parcelas[index].valor = valorPorQuantidade;
					}
				});
			}
		}

		if (formaPagamento) {
			const newFavoritos = copiarObjeto(internalFavoritos);

			newFavoritos.formaPagamento = convertObjectToSelectValue(formaPagamento);

			if (formaPagamento.conta) {
				newFavoritos.conta = convertObjectToSelectValue(formaPagamento.conta);
			}

			setInternalFavoritos(newFavoritos);
		}

		setFieldValue(`[${indexPagamento}]`, pagamento);
		onChangePagamento(indexPagamento, pagamento);
		if (typeof setRecalcularTodosOsProdutosComTabelaPreco === 'function') {
			setRecalcularTodosOsProdutosComTabelaPreco(true);
		}
		if (typeof setRecalcularTodosOsServicosComTabelaPreco === 'function') {
			setRecalcularTodosOsServicosComTabelaPreco(true);
		}
	}

	function handleChangeFormaPagamento(e) {
		const pagamento = {
			...value,
			formaPagamento: e,
			parcelas: [],
		};

		if (e && e.registro?.conta) {
			pagamento.conta = {
				label: e.registro.conta.nome,
				value: e.registro.conta.id,
				registro: e.registro.conta,
			};

			if (value.parcelas.length > 0) {
				value.parcelas.forEach((e) => {
					pagamento.parcelas.push({
						...e,
						conta: pagamento.conta ? pagamento.conta : e.conta,
					});
				});
			}
		}
		if (e && e.registro?.categoriaReceita && financeiroTipo === FINANCEIRO_TIPO.CONTA_RECEBER) {
			pagamento.categoria = {
				label: e.registro.categoriaReceita.nome,
				value: e.registro.categoriaReceita.id,
				registro: e.registro.categoriaReceita,
			};
		}
		if (e && e.registro?.categoriaDespesa && financeiroTipo === FINANCEIRO_TIPO.CONTA_PAGAR) {
			pagamento.categoria = {
				label: e.registro.categoriaDespesa.nome,
				value: e.registro.categoriaDespesa.id,
				registro: e.registro.categoriaDespesa,
			};
		}

		setFieldValue(`[${indexPagamento}]`, pagamento);
		onChangePagamento(indexPagamento, pagamento);
	}

	function handleChangeConta(e) {
		setFieldValue(`[${indexPagamento}].conta`, e);
		onChangePagamentoField(indexPagamento, 'conta', e);
	}

	function handleChangeCategoria(e) {
		setFieldValue(`[${indexPagamento}].categoria`, e);
		onChangePagamentoField(indexPagamento, 'categoria', e);
	}

	function handleChangeValor(e) {
		setFieldValue(`[${indexPagamento}].valor`, e.target.value);
		onChangePagamentoField(indexPagamento, 'valor', e.target.value);

		prevValor.current = value.valor;
	}

	function onBlurValor() {
		if (prevValor.current !== value.valor) {
			setFieldValue(`[${indexPagamento}].alterouValor`, true);
			onChangePagamentoField(indexPagamento, 'alterouValor', true);
			setRecalcularValoresPagamentos(true);
		}
	}

	function handleClickExcluir() {
		onExcluirPagamento(indexPagamento);
	}

	function hasCondicaoAPrazo() {
		let result = false;

		if (values.length > 0) {
			values.forEach((e, i) => {
				if (e.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO && i !== indexPagamento) {
					result = true;
				}
			});
		}

		return result;
	}

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				borderBottom: length > 1 ? '1px solid #ddd' : 'none',
				backgroundColor: indexPagamento % 2 === 0 ? null : '#f1f1f1',
			}}
			className={hasMoreOnePagamento ? 'pagamento-list-hover' : ''}
		>
			<div
				style={{
					padding: hasMoreOnePagamento ? '0px 6px 0px 4px' : '0px 8px',
					display: 'flex',
					alignItems: 'center',
					fontSize: '16px',
					fontWeight: 'bold',
				}}
			>
				{hasMoreOnePagamento ? `${value.sequencial}ª ` : ''}
			</div>
			<Grid style={{ display: 'flex', width: '100%' }}>
				<Field
					sm="12"
					md={isAPrazo ? '6' : '6'}
					lg={isAPrazo ? '3' : '3'}
					xl={isAPrazo ? '3' : '3'}
					name="condicaoPagamento"
					label="Condição de pagamento"
					helpMessage="Condição de pagamento"
					component={SingleSelectCondicaoPagamento}
					value={value.condicaoPagamento}
					onChange={handleChangeCondicaoPagamento}
					url={urls.condicaoPagamento}
					disabled={disabledFields}
					obrigatorio
					isClearable={false}
					indicacaoMovimento={indicacaoMovimento}
					hideSemPagamento={hasMoreOnePagamento}
					hideAPrazo={hasCondicaoAPrazo()}
					touched
					errors={errors[indexPagamento]?.condicaoPagamento}
					useFormErrors={false}
					{...informacoesPermissoes}
				/>
				<If test={isAVista}>
					<FieldsAVista
						value={value}
						handleChangeFormaPagamento={handleChangeFormaPagamento}
						handleChangeConta={handleChangeConta}
						handleChangeCategoria={handleChangeCategoria}
						handleChangeValor={handleChangeValor}
						length={length}
						urls={urls}
						disabledFields={disabledFields}
						informacoesPermissoes={informacoesPermissoes}
						indexPagamento={indexPagamento}
						onBlurValor={onBlurValor}
					/>
				</If>
				<If test={isAPrazo}>
					<FieldsAPrazo
						idOrigem={idOrigem}
						value={value}
						handleChangeFormaPagamento={handleChangeFormaPagamento}
						handleChangeConta={handleChangeConta}
						handleChangeCategoria={handleChangeCategoria}
						handleChangeValor={handleChangeValor}
						length={length}
						indexPagamento={indexPagamento}
						isFirstAndUniqueParcelaAPrazo={isFirstAndUniqueParcelaAPrazo}
						urls={urls}
						disabledFields={disabledFields}
						informacoesPermissoes={informacoesPermissoes}
						hideTabFinanceiro={hideTabFinanceiro}
						financeiroTipo={financeiroTipo}
						onChangePagamentoField={onChangePagamentoField}
						dataBaseParcela={dataBaseParcela}
						internalFavoritos={internalFavoritos}
						handleClickEstornar={handleClickEstornar}
						isMobile={isMobile}
						isTablet={isTablet}
						isLessHd={isLessHd}
						classNameTabView={classNameTabView}
						color={color}
						favoritos={favoritos}
						onBlurValor={onBlurValor}
						setRecalcularTodosOsProdutosComTabelaPreco={setRecalcularTodosOsProdutosComTabelaPreco}
						setRecalcularTodosOsServicosComTabelaPreco={setRecalcularTodosOsServicosComTabelaPreco}
					/>
				</If>
				{isMobile && <Divider styleContainer={{ marginTop: '0px' }} />}
			</Grid>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: hasMoreOnePagamento ? '0px' : '0px 8px',
				}}
			>
				<If test={hasMoreOnePagamento}>
					<ButtonExcluirTable
						onClick={handleClickExcluir}
						title="Excluir item"
						disabled={(indexPagamento === 0 && length === 1) || disabledFields}
						{...informacoesPermissoes}
					/>
				</If>
			</div>
		</div>
	);
}

export { Pagamento };
