import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types';
import { services } from '../../../Common/Constantes/api';

export default function SingleSelectEstado(props) {
	function buscarUrlPesquisa(pesquisa, page) {
		return `${services.GESTOR}/v1/estados/resumo?query=nome=contains="*${pesquisa}*",sigla=contains="*${pesquisa}*"&page=${page}&size=50&sort=nome`;
	}

	function montarLabel(row) {
		return row.nome + ' - ' + row.sigla;
	}

	return (
		<>
			<SingleSelect
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				label={props.label}
				{...props}
				onChange={props.onChange}
				esconderBotao
			/>
		</>
	);
}

SingleSelectEstado.defaultProps = {
	label: 'Estado',
};

SingleSelectEstado.propTypes = {
	service: propTypes.string,
};
