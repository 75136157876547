function converterAtualizarIbptaxParaFormluario(props) {
  return {
    id: props.id,
    dataVigencia: props.vigenciaIbptax,
  };
}

function converterAtualizarIbptaxParaApi(props) {
  return {
    id: props.id,
    vigenciaIbptax: props.dataVigencia,
  };
}

export { converterAtualizarIbptaxParaFormluario, converterAtualizarIbptaxParaApi };
