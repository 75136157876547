import { useState, useEffect } from 'react';
import Col from '../../../../Col';
import { buscarDisabledDeAcordoComAsPermissoes } from '../../../../../Common/Autorizacao/ManipulacaoDeComponentes';
import { getColStyle, buscarTitle, buscarPlaceholder } from '../../Util/validacoes';
import Select from 'react-select';
import If from '../../../../If';
import NormalButton from '../../../../Button';
import { renderizarValidacao } from '../../../../../Common/TratamentoDeErro/validacoesDeCampos';
import propTypes from 'prop-types';

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      height: !state.hasValue || !state.isMulti ? 32 : undefined,
      border: state.selectProps.errors && state.selectProps.touched ? '1px solid #ff0000' : '1px solid #ced4da',
      backgroundColor: '#fff',
      opacity: state.isDisabled ? '0.5' : '1',
      ...dot(state.selectProps.errors && state.selectProps.touched),
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0,
    marginBottom: 0,
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 9999999,
  }),

  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },

  multiValueLabel: (styles, state) => {
    return state.data.isFixed
      ? { ...styles, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : {
          ...styles,
          width: state.data.label && state.data.label.length >= 20 ? '225px' : '100%',
          wordWrap: 'break-word',
          textOverflow: 'ellipsis',
        };
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
  indicatorsContainer: (styles, state) => ({
    ...styles,
    marginTop: '-3px',
  }),
};

const buttonStyle = {
  margin: '5px',
  width: '23px',
  height: '23px',
  borderRadius: '3px',
};

const dot = errors => ({
  ':hover': {
    border: errors ? '1px solid #ff0000 !important' : '1px solid #2196f3 !important',
    cursor: 'pointer',
  },
});

export function InternalDropdown(props) {
  const {
    sm,
    md,
    lg,
    xl,
    showClear,
    errors,
    touched,
    podeInserir,
    podeEditar,
    estadoCadastro,
    disabled,
    podeVisualizar,
    placeholder,
    name,
    options,
    isMulti,
    noOptionsMessage,
    menuPlacement,
    className,
    classNamePrefix,
    col,
    esconderBotao,
    botaoIcon,
    titleBotao,
    desabilitarBotao,
    botaoColor,
    onClickModal,
    filterOption,
    autoFocus,
    colClassName,
    isSearchable,
    isSearchableOnMobile,
    isMobile,
    warning,
    backspaceRemovesValue,
    closeMenuOnSelect,
    id,
    idInput,
    styleButton,
    menuPortalTarget = document.body,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [validouModulo, setValidouModulo] = useState(true);

  useEffect(() => {
    if (props.modulosEspecificos) setValidouModulo(usuarioPossuiModulos(props.modulosEspecificos));
  }, []);

  function handleInputChange(inputValue) {
    setInputValue(inputValue);
  }

  function onBlur(event) {
    props.onBlur && props.onBlur({ target: props });
  }

  async function onChange(value) {
    const { isMulti } = props;

    if (isMulti) {
      await onChangeMulti(value);
    } else {
      return onChangeSingle(value);
    }
  }

  async function onChangeMulti(value) {
    if (value) {
      const valorExportar = value.map(valor => valor.value);

      await props.onChange(valorExportar);
    } else {
      await props.onChange([]);
    }
    onBlur();
  }

  async function onChangeSingle(value) {
    await props.onChange(value || { value: null });
    onBlur();
  }

  function getValue() {
    const { isMulti, value, options } = props;

    if (isMulti) return getValueMulti(value, options);
    else return getValueSingle(value, options);
  }

  function getValueMulti(value, options) {
    if (value && options) {
      let novoArrayOpcoes = options.filter(element => {
        return value.includes(element.value);
      });
      return novoArrayOpcoes;
    }
  }

  function getValueSingle(value, options) {
    for (let i = 0; i < options.length; i++) {
      const opcao = options[i];
      if (opcao.value === value) {
        return opcao;
      }
    }
    return value;
  }

  function montarLabel() {
    const { obrigatorio, label } = props;

    if (obrigatorio) {
      return (
        <label title={props.helpMessage}>
          {label} <b style={{ fontSize: '18px', lineHeight: '5px' }}> *</b>
        </label>
      );
    }
    return <label title={props.helpMessage}> {label} </label>;
  }

  if (!validouModulo) return null;

  const desabilitarSelect = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled);
  const inputPlaceholder = buscarPlaceholder(podeVisualizar, placeholder);
  const value = getValue();

  return (
    <Col col={col} sm={sm} md={md} lg={lg} xl={xl} className={colClassName} style={getColStyle(props)}>
      {montarLabel()}

      <span title={buscarTitle(props)}>
        <div className="p-inputgroup" id={id}>
          <Select
            menuPosition="fixed"
            menuPortalTarget={menuPortalTarget}
            styles={{ ...customStyles, ...props.styles }}
            inputValue={inputValue}
            isClearable={showClear}
            backspaceRemovesValue={backspaceRemovesValue}
            options={options}
            closeMenuOnSelect={closeMenuOnSelect}
            onInputChange={handleInputChange}
            isDisabled={desabilitarSelect}
            placeholder={inputPlaceholder}
            isMulti={isMulti}
            value={value}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            noOptionsMessage={noOptionsMessage}
            menuPlacement={menuPlacement}
            className={className}
            classNamePrefix={classNamePrefix}
            errors={errors}
            touched={touched}
            filterOption={filterOption}
            autoFocus={autoFocus}
            isSearchable={!isSearchableOnMobile && isMobile ? false : isSearchable}
            id={idInput}
            closeMenuOnScroll={e => {
              if (e.target.className?.includes('reactSelect__menu-list')) {
                return false;
              } else {
                return true;
              }
            }}
          />
          <If test={!esconderBotao}>
            <span style={{ alignItems: 'center', display: 'flex' }}>
              <NormalButton
                icon={botaoIcon}
                title={titleBotao}
                style={{ ...buttonStyle, ...styleButton }}
                disabled={desabilitarBotao}
                color={botaoColor}
                onClick={onClickModal}
              />
            </span>
          </If>
        </div>
        {renderizarValidacao(errors, touched, warning)}
      </span>
    </Col>
  );
}

InternalDropdown.defaultProps = {
  noOptionsMessage: () => 'Nenhum elemento encontrado',
  menuPlacement: 'auto',
  className: 'react-select-base',
  classNamePrefix: 'reactSelect',
  podeVisualizar: true,
  showClear: true,
  isMulti: false,
  esconderBotao: true,
  touched: true,
  backspaceRemovesValue: true,
  closeMenuOnSelect: true,
  styleButton: {},
};

InternalDropdown.propTypes = {
  /** Quando definido como true, os resultados de loadOptions() serão carregados automaticamente antes do usuário clicar para pesquisar.  */
  defaultOptions: propTypes.bool,

  /** Quando definido como true, os dados carregados serão armazenados em cache. O cache permanecerá até o valor cacheOptions sofrer alterações. */
  cacheOptions: propTypes.bool,

  /** Função executada quando o valor do input altera de estado. */
  onInputChange: propTypes.func,

  /** Função que retorna uma Promisse, que é o conjunto de opções a ser usado quando esta é resolvida. É executada assim que o componente é montado e a cada vez que o usuário filtrar a pesqusia. */
  loadOptions: propTypes.func,

  /** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
  col: propTypes.string,

  /** Desabilita a pesquisa no mobile */
  isSearchableOnMobile: propTypes.bool,
};
