import { ButtonExcluirTable, Card } from 'components';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { FileUpload } from 'primereact/fileupload';

import './styles.css';
import ButtonDownloadTable from 'components/Button/ButtonDownloadTable';
import { asyncBaixarArquivo } from 'views/documentos/GestaoDocumento/Requests';
import { baixarArquivo } from 'Common';

function FieldsArquivoDocumentoDoCliente(props) {
	const {
		values,
		statusFinalDocumento,
		anexoDocumentoDisabled,
		setFieldValue,
		informacoesPermissoes,
		isModalAnexo,
		adicionouArquivo,
		setAdicionouArquivo,
	} = props;

	async function onUpload(upload) {
		await setFieldValue('arquivos', upload?.files);
		setAdicionouArquivo(true);
	}

	async function onRemove(fileRemoved) {
		const arquivos = values?.arquivos.filter(
			(file) => file?.name !== fileRemoved?.name && file?.size !== fileRemoved?.size
		);
		await setFieldValue('arquivos', arquivos);
	}

	function formatarTamanhoArquivo(size) {
		return `${(size / 1024).toFixed(1)} KB`;
	}

	async function downloadFile(arquivo) {
		asyncBaixarArquivo(arquivo.id, isModalAnexo, ({ data: file }) => {
			baixarArquivo(file, `${arquivo.name ?? 'documento'}.pdf`);
		});
	}

	const fieldDisabled =
		((!isModalAnexo && !informacoesPermissoes.podeEditarDocumento) || statusFinalDocumento || anexoDocumentoDisabled) &&
		informacoesPermissoes.estadoCadastro !== 'INCLUSAO';

	return (
		<Card>
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
				<section
					className="dropzone"
					style={{
						margin: '10px',
						border: '2px dashed #A4A4A4',
						borderRadius: '10px',
					}}
				>
					<FileUpload
						name="arquivos"
						multiple
						maxFileSize={25000000}
						auto
						customUpload
						disabled={fieldDisabled}
						uploadHandler={onUpload}
						invalidFileSizeMessageSummary=" "
						invalidFileSizeMessageDetail=" São permitidos arquivos com tamanho máximo de 25 MB"
						chooseOptions={{
							label: 'Adicionar arquivos',
							icon: false,
							className: 'custom-choose-options-file-upload',
						}}
						onRemove={({ file }) => onRemove(file)}
						itemTemplate={(file, props) => (
							<div style={{ display: 'flex', width: '100%' }}>
								<div style={{ flex: '1', display: 'flex' }}>{`${file.name}  (${formatarTamanhoArquivo(
									file.size
								)})`}</div>
								{adicionouArquivo ? (
									<ButtonExcluirTable
										title={null}
										disabled={statusFinalDocumento || !informacoesPermissoes.podeEditarDocumento}
										onClick={props.onRemove}
									/>
								) : (
									<ButtonDownloadTable
										title={null}
										disabled={statusFinalDocumento}
										onClick={() => {
											downloadFile(
												values.arquivos?.find((arquivo) => arquivo.name === file.name && arquivo.size === file.size)
											);
										}}
									/>
								)}
							</div>
						)}
						emptyTemplate={
							values.arquivos?.length > 0 ? (
								values.arquivos?.map((arquivo) => (
									<div
										key={`${arquivo.name}_${arquivo.size}`}
										style={{ display: 'flex', width: '100%', padding: '0.5rem 1rem' }}
									>
										<div style={{ flex: '1', display: 'flex' }}>
											{`${arquivo.name} (${formatarTamanhoArquivo(arquivo.size)})`}
										</div>
										<ButtonDownloadTable
											title={null}
											disabled={statusFinalDocumento}
											onClick={() => downloadFile(arquivo)}
										/>
									</div>
								))
							) : (
								<div
									style={{
										height: '100%',
										width: '100%',
										padding: '10px',
										display: 'flex',
										justifyContent: 'center',
										flexDirection: 'column',
										alignItems: 'center',
										opacity: statusFinalDocumento || !informacoesPermissoes.podeEditarDocumento ? '0.7' : '1',
									}}
								>
									<AiOutlineCloudUpload size={50} color="#006095" />
									<div
										style={{
											color: '#006095',
											fontSize: '16px',
											fontWeight: 'bold',
										}}
									>
										Ou arraste e solte o documento aqui
									</div>
									<div style={{ fontSize: '13px', color: '#565656' }}>Serão aceitos arquivos com no máximo 25 MB</div>
								</div>
							)
						}
					/>
				</section>
			</div>
		</Card>
	);
}

export { FieldsArquivoDocumentoDoCliente };
