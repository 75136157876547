import { estadosCadastro, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { createContext, useContext, useState } from 'react';

const ContextCodigoServico = createContext();

export default function CodigoServicoProvider(props) {
	const { children } = props;

	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.INSERIR));
	const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EXCLUIR));
	const [podeEditar, setPodeEditar] = useState(usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EDITAR));

	return (
		<ContextCodigoServico.Provider value={{ podeInserir, podeExcluir, podeEditar }}>
			{children ? children : null}
		</ContextCodigoServico.Provider>
	);
}

export const useContextCodigoServico = () => useContext(ContextCodigoServico);
