import SingleSelect from '../SingleSelect';
import { services } from '../../../Common/Constantes/api';

function SingleSelectUnidadeMedida(props) {
	function buscarUrlPesquisa(pesquisa, page) {
		return `${services.GESTOR}/v1/unidades_medida?query=descricao=contains="*${pesquisa}*",unidade=contains="*${pesquisa}*"&page=${page}&size=50`;
	}

	function montarLabel(row) {
		return row.unidade + ' - ' + row.descricao;
	}

	return (
		<>
			<SingleSelect
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				label={props.label}
				onChange={props.onChange}
				esconderBotao
				{...props}
			/>
		</>
	);
}

SingleSelectUnidadeMedida.defaultProps = {
	label: 'Unidade de medida',
};

export default SingleSelectUnidadeMedida;
