import propTypes from 'prop-types';

function LayoutMenuSuperior(props) {
	return (
		<div id={props.id} style={{ paddingTop: props.isModal ? '0px' : '45px' }} className={props.className}>
			{props.children}
		</div>
	);
}

LayoutMenuSuperior.propTypes = {
	/** Identificador do componente */
	id: propTypes.string,
	/** Define se o componente é modal */
	isModal: propTypes.bool,
	/** Nome da classe do componente */
	className: propTypes.any,
	/** Filhos do componente */
	children: propTypes.any,
};

export default LayoutMenuSuperior;
