import { Icon } from '@iconify/react';
import { copiarObjeto } from 'Common';
import { Col, If, NenhumRegistroEncontrado, Paper } from 'components';
import { useFormikContext } from 'formik';
import { Fragment, forwardRef, useImperativeHandle, useState } from 'react';

const stylePaper = {
	borderRadius: '3px',
	boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.23)',
	minHeight: '200px',
	maxHeight: '200px',
	overflowY: 'auto',
};

const CardMunicipioDescarregamento = forwardRef(
	(
		{
			values,
			setErrors,
			municipiosLocal,
			setMunicipiosLocal,
			setLimparSelecionado,
			municipioSelecionado,
			setMunicipioSelecionado,
			indexMunicipioSelecionado,
			setIndexMunicipioSelecionado,
			chaveDeAcesso,
			setChaveDeAcesso,
			disabled,
			isCargaPropria,
		},
		ref
	) => {
		const [isHovered, setIsHovered] = useState({});
		const { setFieldValue } = useFormikContext();

		const valuesIsEmpty = !values.municipios?.length > 0;
		const municipioSelecionadoIsEmpty = !municipioSelecionado?.documentos > 0;

		useImperativeHandle(ref, () => ({
			handleAdicionarMunicipio: handleAdicionarMunicipio,
			handleAdicionarChaveAcesso: handleAdicionarChaveAcesso,
		}));

		function handleSelectMunicipio(index) {
			setMunicipioSelecionado(municipiosLocal[index] ?? values.municipios[index]);
			setIndexMunicipioSelecionado(index);
			setTimeout(() => {
				document.getElementById('chaveDeAcesso')?.focus();
			}, 250);
		}

		function handleAdicionarMunicipio(municipio) {
			if (municipio) {
				const newMunicipios = [...municipiosLocal];
				newMunicipios.push({
					idRegistro: municipio?.registro?.id,
					municipio: {
						value: municipio.value,
						label: municipio.label,
						registro: municipio.registro,
					},
					documentos: [],
				});
				setMunicipiosLocal(newMunicipios);

				setMunicipioSelecionado({
					idRegistro: municipio?.registro?.id,
					municipio: {
						value: municipio.value,
						label: municipio.label,
						registro: municipio.registro,
					},
					documentos: [],
				});
				setIndexMunicipioSelecionado(newMunicipios.length - 1);

				setTimeout(() => {
					document.getElementById('chaveDeAcesso')?.focus();
				}, 250);
			}
		}

		function handleRemoverMunicipio(e, index) {
			e.preventDefault();

			const newMunicipios = [...municipiosLocal];

			newMunicipios.splice(index, 1);
			setMunicipiosLocal(newMunicipios);
			setFieldValue('municipios', newMunicipios);
			setLimparSelecionado(true);
		}

		function handleAdicionarChaveAcesso() {
			let errorOccurred = false;
			if (chaveDeAcesso?.length < 44) {
				setErrors('O campo deve ter pelo menos 44 caracteres');
				errorOccurred = true;
			}
			if (values.municipios?.length > 0) {
				values.municipios?.forEach((municipio) => {
					municipio?.documentos?.forEach((documento) => {
						if (chaveDeAcesso === documento.chave) {
							setErrors('Chave de acesso já existente');
							errorOccurred = true;
						}
					});
				});
			}

			if (errorOccurred) {
				return;
			}

			if (municipioSelecionado && chaveDeAcesso) {
				setErrors(null);
				if (municipiosLocal.length > 0) {
					const newData = copiarObjeto(municipiosLocal);
					const municipioAtual = municipiosLocal[indexMunicipioSelecionado];

					if (!municipioAtual.documentos) {
						municipioAtual.documentos = [];
					}

					municipioAtual.documentos.push({ chave: chaveDeAcesso });
					newData[indexMunicipioSelecionado] = municipioAtual;

					setMunicipioSelecionado(municipioAtual);
					setMunicipiosLocal(newData);
					setChaveDeAcesso(null);

					setTimeout(() => {
						document.getElementById('chaveDeAcesso')?.focus();
					}, 250);
				} else {
					values.municipios ?? setMunicipiosLocal(values.municipios);
				}
			}
		}

		function handleRemoverChaveAcesso(e, index) {
			e.preventDefault();

			const newData = copiarObjeto(municipiosLocal);
			const municipioAtual = copiarObjeto(municipiosLocal[indexMunicipioSelecionado]);

			municipioAtual?.documentos?.splice(index, 1);
			newData[indexMunicipioSelecionado] = municipioAtual;

			setMunicipioSelecionado(municipioAtual);
			setMunicipiosLocal(newData);

			setTimeout(() => {
				document.getElementById('chaveDeAcesso')?.focus();
			}, 250);
		}

		function SelectTags(label, quantidade, tipo, index) {
			const isMunicipio = tipo === 'municipio';
			const isSelecionado = indexMunicipioSelecionado === index;
			const quantidadeDocumentos = isMunicipio
				? `Qtde. ${isCargaPropria ? 'NF-e' : 'CT-e:'} ${quantidade ?? '0'}`
				: null;

			const formatLabel = (label) => {
				if (!isMunicipio) {
					return label.match(/.{1,4}/g).join(' ');
				}
				return label;
			};

			return (
				<div style={{ width: '100%' }} onClick={() => isMunicipio && handleSelectMunicipio(index)}>
					<div
						style={{
							display: 'flex',
							position: 'relative',
							padding: '2px 2px 2px 5px',
							margin: '5px',
							backgroundColor: isSelecionado && isMunicipio ? '#B3E5FC' : '#D9D9D9',
							borderRadius: '3px',
							cursor: 'pointer',
							transition: 'background-color 0.3s ease',
							...(isHovered.label === label && { backgroundColor: '#88d1f2' }),
						}}
						onMouseEnter={() => setIsHovered({ label: label })}
						onMouseLeave={() => setIsHovered({})}
					>
						<p>{formatLabel(label)}</p>
						<If test={isMunicipio}>
							<p style={{ position: 'absolute', right: disabled ? '10px' : '35px' }}>{quantidadeDocumentos}</p>
						</If>
						<If test={!disabled}>
							<p
								onClick={(e) =>
									(!disabled && isMunicipio && handleRemoverMunicipio(e, index)) ||
									(!disabled && !isMunicipio && handleRemoverChaveAcesso(e, index))
								}
								style={{ position: 'absolute', right: '5px', top: '4px' }}
							>
								<Icon
									icon="material-symbols:close"
									style={{ transition: 'color 0.3s ease' }}
									color={isHovered.label === label ? 'red' : 'grey'}
								/>
							</p>
						</If>
					</div>
				</div>
			);
		}

		return (
			<>
				<Col sm="12" md="6" lg="6" xl="6">
					<Paper
						style={{
							...stylePaper,
							display: valuesIsEmpty ? 'flex' : 'block',
							justifyContent: valuesIsEmpty ? 'center' : null,
							alignItems: valuesIsEmpty ? 'center' : null,
						}}
					>
						{values.municipios?.length > 0 ? (
							values.municipios.map((municipio, index) => (
								<Fragment key={municipio.municipio.label}>
									{SelectTags(municipio.municipio?.label, municipio.documentos?.length, 'municipio', index)}
								</Fragment>
							))
						) : (
							<div>
								<NenhumRegistroEncontrado />
							</div>
						)}
					</Paper>
				</Col>
				<Col sm="12" md="6" lg="6" xl="6">
					<Paper
						style={{
							...stylePaper,
							display: municipioSelecionadoIsEmpty ? 'flex' : 'block',
							justifyContent: municipioSelecionadoIsEmpty ? 'center' : null,
							alignItems: municipioSelecionadoIsEmpty ? 'center' : null,
						}}
					>
						{municipioSelecionado?.municipio?.label ? (
							<p style={{ color: municipioSelecionado?.municipio?.label ? '#006095' : null }}>
								Município selecionado: <b>{municipioSelecionado?.municipio?.label}</b>
							</p>
						) : (
							<div>
								<NenhumRegistroEncontrado />
							</div>
						)}
						{municipioSelecionado?.documentos?.map((documento, index) => (
							<Fragment key={documento.chave}>{SelectTags(documento.chave, null, 'documentos', index)}</Fragment>
						))}
					</Paper>
				</Col>
			</>
		);
	}
);

export default CardMunicipioDescarregamento;
