import { useState, createContext, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { permissoes, recursos, usuarioPossuiPermissao } from '../../../../../Common';
import grafismo from '../../../../../layout/Images/grafismo_microsys.svg';

const ContextNFCe = createContext();

export default function NFCeProvider(props) {
  const { children } = props;

  const [closeAllModal, setCloseAllModal] = useState(false);
  const [jogarFocoParaProduto, setJogarFocoParaProduto] = useState(false);

  const [modalHistoricoVisible, setModalHistoricoVisible] = useState(false);
  const [modalListagemVisible, setModalListagemVisible] = useState(false);
  const [modalMenuFiscalVisible, setModalMenuFiscalVisible] = useState(false);
  const [modalCancelamentoVisible, setModalCancelamentoVisible] = useState(false);
  const [modalProdutoVisible, setModalProdutoVisible] = useState(false);
  const [modalFinalizacaoVisible, setModalFinalizacaoVisible] = useState(false);
  const [modalParcelasPrazo, setModalParcelasPrazo] = useState(false);
  const [modalCliente, setModalCliente] = useState(false);
  const [modalInutilizacao, setModalInutilizacao] = useState(false);
  const [modalPedidoImportacao, setModalPedidoImportacao] = useState(false);
  const [modalRecebimentoPixVisible, setModalRecebimentoPixVisible] = useState(false);
  const [modalDetalhesPixVisible, setModalDetalhesPixVisible] = useState(false);

  const [exibirLoadingTransmissao, setExibirLoadingTransmissao] = useState(false);
  const [exibirLoadingImprimir, setExibirLoadingImprimir] = useState(false);
  const [messageLoadingTransmissao, setMessageLoadingTransmissao] = useState('Transmitindo NFC-e...');

  const [contaFavorita, setContaFavorita] = useState(null);

  const [widthMessage, setWidthMessage] = useState(document.getElementById('id-logo-nfce')?.offsetWidth);

  const [logoUrl, setLogoUrl] = useState(grafismo);

  const [davImportado, setDavImportado] = useState(false);

  const [podeInserir, setPodeInserir] = useState(
    usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.INSERIR)
  );
  const [podeEditar, setPodeEditar] = useState(
    usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.EDITAR)
  );

  const [podeVisualizar, setPodeVisualizar] = useState(
    usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.VISUALIZAR)
  );

  const [modalCompartilharPorEmailVisible, setModalCompartilharPorEmailVisible] = useState(false);
  const [modalCompartilharPorWhatsappVisible, setModalCompartilharPorWhatsappVisible] = useState(false);

  const [dadosNFCe, setDadosNFCe] = useState(null);

  const informacoesPermissoes = {
    podeInserir: podeInserir,
    podeEditar: podeEditar,
    podeVisualizar: podeVisualizar,
    podeInserirInutilizacao: usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.INSERIR),
  };

  const existeModalAberto = useRef(false);
  const transmissaoFlag = useRef(false);
  const didRequestLogo = useRef(false);

  useLayoutEffect(() => {
    return () => {
      existeModalAberto.current;
      transmissaoFlag.current;
      didRequestLogo.current;
    };
  }, []);

  useEffect(() => {
    if (jogarFocoParaProduto) {
      document.getElementById('id-produto-nfce').focus();
      setJogarFocoParaProduto(false);
    }
  }, [jogarFocoParaProduto]);

  useEffect(() => {
    if (
      modalHistoricoVisible ||
      modalListagemVisible ||
      modalMenuFiscalVisible ||
      modalCancelamentoVisible ||
      modalProdutoVisible ||
      modalFinalizacaoVisible ||
      modalParcelasPrazo ||
      exibirLoadingTransmissao ||
      modalCliente ||
      modalInutilizacao ||
      modalPedidoImportacao ||
      exibirLoadingImprimir ||
      modalRecebimentoPixVisible ||
      modalDetalhesPixVisible
    ) {
      existeModalAberto.current = true;
    } else if (
      !modalHistoricoVisible &&
      !modalListagemVisible &&
      !modalMenuFiscalVisible &&
      !modalCancelamentoVisible &&
      !modalProdutoVisible &&
      !modalFinalizacaoVisible &&
      !modalParcelasPrazo &&
      !exibirLoadingTransmissao &&
      !modalCliente &&
      !modalInutilizacao &&
      !modalPedidoImportacao &&
      !exibirLoadingImprimir &&
      !modalRecebimentoPixVisible &&
      !modalDetalhesPixVisible
    ) {
      existeModalAberto.current = false;
      document.getElementById('id-produto-nfce').focus();
    }
  }, [
    modalHistoricoVisible,
    modalListagemVisible,
    modalMenuFiscalVisible,
    modalCancelamentoVisible,
    modalProdutoVisible,
    modalFinalizacaoVisible,
    modalParcelasPrazo,
    exibirLoadingTransmissao,
    exibirLoadingImprimir,
    modalCliente,
    modalInutilizacao,
    modalPedidoImportacao,
    modalRecebimentoPixVisible,
    modalDetalhesPixVisible,
  ]);

  useEffect(() => {
    if (closeAllModal) {
      setModalHistoricoVisible(false);
      setModalListagemVisible(false);
      setModalMenuFiscalVisible(false);
      setModalCancelamentoVisible(false);
      setModalProdutoVisible(false);
      setModalFinalizacaoVisible(false);
      setModalParcelasPrazo(false);
      setModalCliente(false);
      setModalInutilizacao(false);
      setModalPedidoImportacao(false);
      setModalRecebimentoPixVisible(false);
      setModalDetalhesPixVisible(false);
      setCloseAllModal(false);
    }
  }, [closeAllModal]);

  return (
    <ContextNFCe.Provider
      value={{
        modalHistoricoVisible,
        setModalHistoricoVisible,
        modalListagemVisible,
        setModalListagemVisible,
        modalMenuFiscalVisible,
        setModalMenuFiscalVisible,
        modalCancelamentoVisible,
        setModalCancelamentoVisible,
        modalProdutoVisible,
        setModalProdutoVisible,
        modalFinalizacaoVisible,
        setModalFinalizacaoVisible,
        modalParcelasPrazo,
        setModalParcelasPrazo,
        setModalCliente,
        setCloseAllModal,
        contaFavorita,
        setContaFavorita,
        exibirLoadingTransmissao,
        setExibirLoadingTransmissao,
        exibirLoadingImprimir,
        setExibirLoadingImprimir,
        messageLoadingTransmissao,
        setMessageLoadingTransmissao,
        existeModalAberto,
        modalInutilizacao,
        setModalInutilizacao,
        setJogarFocoParaProduto,
        transmissaoFlag,
        podeInserir,
        setPodeInserir,
        podeEditar,
        setPodeEditar,
        podeVisualizar,
        setPodeVisualizar,
        informacoesPermissoes,
        widthMessage,
        setWidthMessage,
        didRequestLogo,
        setLogoUrl,
        logoUrl,
        modalPedidoImportacao,
        setModalPedidoImportacao,
        davImportado,
        setDavImportado,
        modalCompartilharPorWhatsappVisible,
        setModalCompartilharPorWhatsappVisible,
        modalCompartilharPorEmailVisible,
        setModalCompartilharPorEmailVisible,
        modalRecebimentoPixVisible,
        setModalRecebimentoPixVisible,
        modalDetalhesPixVisible,
        setModalDetalhesPixVisible,
        dadosNFCe, 
        setDadosNFCe,
      }}
    >
      {children ? children : null}
    </ContextNFCe.Provider>
  );
}

export const useContextNFCe = () => useContext(ContextNFCe);
