import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';
import { Field, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { buscarMascaraTelefone, mensagensDeValidacao } from 'Common';
import {
	ButtonCancelar,
	ButtonSalvar,
	Divider,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputCep,
	InputField,
	InputMask,
	SingleSelectMunicipio,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
	notify,
} from 'components';
import { validarFormulario } from '../../../../../../Util';
import { asyncGetFilialConectada, asyncPutFilial } from '../../Request';
import { INITIAL_VALUE } from '../../Util/constantes';
import {
	converteFilialContadorParaApi,
	converteFilialContadorParaFormulario,
} from '../../Util/converterFilialContador';

function FieldsContadorView(props) {
	const { values, resetForm, setFieldValue, setTouched, dirty, onHide, handleReset } = props;

	const [filial, setFilial] = useState(null);

	useEffect(() => {
		getFilialConectada();
	}, []);

	async function getFilialConectada() {
		await asyncGetFilialConectada(({ data: filial }) => {
			setFilial(filial);
			if (filial.contador) {
				resetForm({ values: converteFilialContadorParaFormulario(filial) });
			}
		});
	}

	function onClickCancelar() {
		dirty ? handleReset() : onHide();
	}

	async function onClickSalvar() {
		props.handleSubmit();

		if (await validarFormulario(props)) {
			await asyncPutFilial(filial.id, converteFilialContadorParaApi(filial, values), () => {
				notify(filial.contador ? 'Contador atualizado com sucesso' : 'Contador criado com sucesso');
				onHide();
			});
		}
	}

	function onChangeCep(event) {
		if (event.pesquisou) {
			event.logradouro && setFieldValue('endereco.logradouro', event.logradouro);
			event.bairro && setFieldValue('endereco.bairro', event.bairro);
			event.complemento && setFieldValue('endereco.complemento', event.complemento);
			event.municipio &&
				setFieldValue('endereco.municipio', {
					label: `${event.municipio.nome} - ${event.municipio.estado.sigla}`,
					value: event.municipio.id,
				});

			setTouched({
				field: { bairro: true, complemento: true, logradouro: true, municipio: true, pais: true, numero: true },
			});
		} else {
			setFieldValue('endereco.cep', event.value);
		}
	}

	return (
		<Form>
			<FormActions>
				<ButtonCancelar
					onClick={onClickCancelar}
					estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				/>
				<ButtonSalvar onClick={onClickSalvar} estadoBotao={estadosBotaoSalvar.CONFIRMAR} disabled={!dirty} />
			</FormActions>
			<FormContent>
				<Grid>
					<Field sm="12" md="8" lg="8" xl="8" component={InputField} obrigatorio label="Nome" name="nome" size={60} />
					<Field sm="12" md="4" lg="4" xl="4" component={InputField} obrigatorio label="CRC" name="crc" size={15} />
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputMask}
						mask="000.000.000-00"
						placeholder="   .   .   -  "
						label="CPF"
						obrigatorio
						name="cpf"
						onChange={(e) => setFieldValue('cpf', e.target.value)}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputMask}
						mask="00.000.000/0000-00"
						placeholder="  .   .   /    -  "
						label="CNPJ da contabilidade"
						name="cnpj"
						onChange={(e) => setFieldValue('cnpj', e.target.value)}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputMask}
						mask={buscarMascaraTelefone(values.telefone)}
						placeholder="(  )      -    "
						label="Telefone"
						name="telefone"
						size={16}
						onChange={(e) => setFieldValue('telefone', e.target.value)}
					/>
					<Field
						sm="12"
						md="8"
						lg="8"
						xl="8"
						component={InputField}
						label="E-mail"
						obrigatorio
						name="email"
						size={255}
					/>
					<Divider label="Endereço" styleLabel={{ fontSize: '18px', fontWeight: '500' }} />
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputCep}
						label="CEP"
						name="cep"
						type="tel"
						value={values.endereco.cep}
						onChange={(e) => onChangeCep(e)}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputField}
						label="Logradouro"
						name="endereco.logradouro"
						size={255}
					/>
					<Field sm="12" md="4" lg="4" xl="4" component={InputField} label="Número" size={60} name="endereco.numero" />
					<Field sm="12" md="4" lg="4" xl="4" component={InputField} label="Bairro" name="endereco.bairro" size={60} />
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputField}
						label="Complemento"
						name="endereco.complemento"
						size={255}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={SingleSelectMunicipio}
						obrigatorio
						touched
						useFormTouched={false}
						isClearable={false}
						label="Município"
						name="municipio"
						value={values.endereco.municipio}
						onChange={(e) => setFieldValue('endereco.municipio', e)}
					/>
				</Grid>
			</FormContent>
		</Form>
	);
}

const FieldsContador = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUE;
	},

	validate(values) {
		const errors = {};

		if (!isValidCPF(values.cpf)) {
			errors.cpf = mensagensDeValidacao.CPF_INVALIDO;
		}

		if (values.cnpj && !isValidCNPJ(values.cnpj)) {
			errors.cnpj = mensagensDeValidacao.CNPJ_INVALIDO;
		}

		if (!values.endereco.municipio?.value) {
			errors.municipio = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		crc: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		cpf: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		email: Yup.string()
			.nullable()
			.email(mensagensDeValidacao.EMAIL_INVALIDO)
			.required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(FieldsContadorView);

export default FieldsContador;
