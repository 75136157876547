import { InternalTextArea } from '../InternalTextArea';

export default function TextArea(props) {
	const { field, form, useFormErrors = true, useFormTouched = true, ...rest } = props;
	const useForm = useFormErrors && useFormTouched;

	return (
		<>
			<InternalTextArea
				{...field}
				{...rest}
				errors={form && useForm ? form?.errors[field.name] : props.errors}
				touched={form && useForm ? Boolean(form?.touched[field.name]) : Boolean(props.touched)}
			/>
		</>
	);
}
