export const INITIAL_VALUES_REGIAO = {
	id: null,
	nome: '',
	situacao: 'ATIVO',
	municipios: [],
	estados: [],
};

export const SITUACAO_REGIAO = [
	{ value: 'ATIVO', label: 'Ativo' },
	{ value: 'INATIVO', label: 'Inativo' },
];

export const CADASTROURL = '/vendas/regioes/cadastro';
export const PESQUISAURL = '/vendas/regioes';
