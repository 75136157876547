export default function Divider({ label, orientation = 'horizontal', styleContainer, styleLabel, styleLine }) {
	const verticalStyles = {
		width: '1px',
		height: '100%',
		margin: '0 0.5rem',
		display: 'inline-block',
	};

	return (
		<div
			style={{
				width: orientation === 'horizontal' ? '100%' : 'auto',
				...styleContainer,
				...(orientation === 'vertical' ? verticalStyles : {}),
			}}
		>
			{label && (
				<h3
					style={{
						margin: orientation === 'horizontal' ? '0 0 0.2rem 0.1rem' : '0.5rem 0',
						fontWeight: 'bold',
						...styleLabel,
					}}
				>
					{label}
				</h3>
			)}
			<div
				style={{
					width: orientation === 'horizontal' ? '100%' : '1px',
					height: orientation === 'horizontal' ? '1px' : '100%',
					borderBottom: orientation === 'horizontal' ? '1px solid' : 'none',
					borderLeft: orientation === 'vertical' ? '1px solid' : 'none',
					opacity: '0.5',
					...styleLine,
				}}
			/>
		</div>
	);
}
