import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../../../Common/Mascara';
import { BsGraphDown } from 'react-icons/bs';
import FalhaAoCarregarAsInformacoes from '../../../../../FalhaAoCarregarAsInformacoes';
import { Financas } from '../../../../../../../../Util/constantes';
import '../Styles/index.css';

const messages = {
	helpMessage: 'Valor total de suas despesas cadastradas no período',
};

export default function CardDespesas(props) {
	const { loading, error, despesasTotal, despesasPagas, despesasAPagar } = props;

	function montarTituloCard() {
		return (
			<div className="card-resultado-financeiro-dashboard-titulo">
				<div className="card-resultado-financeiro-dashboard-titulo-descricao" title={messages.helpMessage}>
					Despesas no período
				</div>
			</div>
		);
	}

	if (loading) {
		return (
			<div className="card-resultado-financeiro-dashboard">
				{montarTituloCard()}
				<div className="card-resultado-financeiro-dashboard-content">
					<ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />
				</div>
			</div>
		);
	} else if (error) {
		return (
			<div className="card-resultado-financeiro-dashboard">
				{montarTituloCard()}
				<div className="card-resultado-financeiro-dashboard-content">
					<FalhaAoCarregarAsInformacoes />
				</div>
			</div>
		);
	} else {
		return (
			<div className="card-resultado-financeiro-dashboard">
				{montarTituloCard()}
				<div className="card-resultado-financeiro-dashboard-content">
					<div className="card-resultado-financeiro-dashboard-icone">
						<BsGraphDown size={40} color={Financas.cores.vermelho} />
					</div>
					<div className="card-resultado-financeiro-dashboard-valor">
						<div
							className="card-resultado-financeiro-dashboard-valor-principal"
							title="Valor total a pagar (Pagas + A pagar)"
							style={{ color: Financas.cores.vermelho }}
						>
							{formatarMonetario(despesasTotal)}
						</div>
						<div className="card-resultado-financeiro-dashboard-valor-secundario">
							<span
								className="card-resultado-financeiro-dashboard-valor-secundario-efetuado"
								title="Valor já pago do montante total"
							>
								<label>Pagas</label>
								<div>{formatarMonetario(despesasPagas)}</div>
							</span>
							<span
								className="card-resultado-financeiro-dashboard-valor-secundario-pendente"
								title="Valor que falta pagar do montante total"
							>
								<label>A pagar</label>
								<div>{formatarMonetario(despesasAPagar)}</div>
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
