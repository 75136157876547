import { useFormikContext } from 'formik';
import { useContextOrdemServico } from 'views/fiscal/servicos/OrdemServico/Context';
import { Servicos } from 'components/Servicos';

export function TabServicos({
	municipioId,
	onChangeServico,
	onChangeServicos,
	calcularPercentualAdicionalTabelaPreco,
}) {
	const { values, errors } = useFormikContext();
	const {
		color,
		disabledFields,
		informacoesPermissoes,
		setAtualizarTotais,
		clienteUsaPrecoAtacado,
		recalcularTodosOsServicosComTabelaPreco,
		setRecalcularTodosOsServicosComTabelaPreco,
	} = useContextOrdemServico();

	const hasClienteSelecionado = Boolean(values?.cliente?.value);

	return (
		<div
			className="container-servicos-produtos"
			style={{
				paddingLeft: '0px',
				paddingTop: '0.3rem',
				overflowY: 'scroll',
				overflowX: 'hidden',
				maxHeight: '18.2rem',
			}}
		>
			<Servicos
				servicos={values.servicos}
				municipioId={municipioId}
				tabelaPreco={values.informacoesComplementares?.tabelaPreco}
				pagamentos={values.pagamentos}
				informacoesPermissoes={informacoesPermissoes}
				disabled={disabledFields || !hasClienteSelecionado}
				disabledButtonAdd={disabledFields}
				color={color.strongColor}
				onChangeServicos={onChangeServicos}
				onChangeServico={onChangeServico}
				calcularPercentualAdicionalTabelaPreco={calcularPercentualAdicionalTabelaPreco}
				atualizarTotalizadores={setAtualizarTotais}
				operacaoFiscalHeader={values.operacaoFiscal}
				comissaoHeader={values.informacoesComplementares?.vendedor?.registro?.comissao}				
				descontoMaximoVendedor={values.informacoesComplementares?.vendedor?.registro?.descontoMaximo}
				descontoMaximoSuperior={values.informacoesComplementares?.vendedor?.registro?.descontoMaximoSuperior}
				utilizaPrecoAtacado={clienteUsaPrecoAtacado}
				recalcularTodosOsServicosComTabelaPreco={recalcularTodosOsServicosComTabelaPreco}
				setRecalcularTodosOsServicosComTabelaPreco={setRecalcularTodosOsServicosComTabelaPreco}
				errors={errors.servicos}
			/>
		</div>
	);
}
