import { post, exibirToast } from '../../../../../Common/Requisicoes';
import { services } from '../../../../../Common/Constantes/api';
import { buscarDadosLoginLocalStorage } from '../../../../../Common/Autenticacao';

export async function asyncAtualizarEmail(dados, onSuccess, onError) {
	const body = {
		emailAtual: buscarDadosLoginLocalStorage().email,
		emailNovo: dados.email,
		senha: dados.senha,
	};
	return await post(
		services.GESTOR + '/v1/credenciais/alterar_email',
		body,
		null,
		exibirToast(onSuccess, 'Um e-mail de confirmação foi enviado para seu novo endereço de e-mail.'),
		onError
	);
}
