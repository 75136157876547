import {
	buscarClassNamePelosErros,
	buscarDisabledDeAcordoComAsPermissoes,
	buscarHiddenDeAcordoComAsPermissoes,
	buscarTitleCampoDeAcordoComAsPermissoes,
	renderizarValidacao,
} from 'Common';
import Button from 'components/Button';
import Col from 'components/Col';
import { addDays, formatISO } from 'date-fns';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef, useState } from 'react';

export const buttonStyle = {
	width: '23px',
	height: '23px',
	borderRadius: '3px',
	color: '#006095',
	backgroundColor: 'transparent',
	borderColor: 'transparent',
	fontSize: '15px',
	boxShadow: 'none',
	margin: 'unset',
	border: 'unset',
};

function InputDateWithAddingDays({
	sm,
	md,
	lg,
	xl,
	podeVisualizar,
	podeInserir,
	podeEditar,
	estadoCadastro,
	disabled,
	title,
	id,
	name,
	errors,
	touched,
	colId,
	className,
	col,
	style,
	obrigatorio,
	label,
	onBlur,
	dataBase,
	...props
}) {
	const [diasCompensacao, setDiasCompensacao] = useState(0);
	const overlayDias = useRef(null);

	const value = getValue();

	function handleClickDias(e) {
		overlayDias.current.toggle(e);
	}

	function onCloseOverlayDias() {
		overlayDias.current.hide();
	}

	function handleAdicionarDiasCompensacao(dias) {
		const diasCorrigido = parseInt(dias, 10) + 1;
		if (dias >= 0 && dataBase) {
			onChange({
				target: {
					value: formatISO(addDays(new Date(dataBase).setHours(0, 0, 0, 0), diasCorrigido)),
				},
			});
		}
		setDiasCompensacao(0);
		onCloseOverlayDias();
	}

	function onChange(e) {
		props.onChange(e);
	}

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden))
			return { display: 'none', ...props.colStyle };
		return props.colStyle;
	}

	function montarLabel() {
		if (obrigatorio) {
			return (
				<label title={props.helpMessage}>
					{' '}
					{label} <b style={{ fontSize: '18px', lineHeight: '5px' }}> *</b>{' '}
				</label>
			);
		}
		return <label title={props.helpMessage}> {label} </label>;
	}

	function getValue() {
		if (!props.value) {
			return '';
		}

		if (props.value.length > 10) {
			return props.value.substring(0, 10);
		}
		return props.value;
	}

	return (
		<Col col={col} sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			{montarLabel()}
			<div className="p-inputgroup" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
				<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
					<InputText
						title={buscarTitleCampoDeAcordoComAsPermissoes(
							podeVisualizar,
							podeInserir,
							podeEditar,
							estadoCadastro,
							title
						)}
						id={id}
						type="date"
						name={name}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						className={buscarClassNamePelosErros(className, errors, touched)}
						disabled={buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled)}
						style={{ borderRadius: '3px', height: '32px', ...style }}
						placeholder="dd-mm-aaaa"
						autoComplete="off"
					/>
					<Button
						icon="fa fa-plus"
						title="Número de dias para compensação"
						style={{ ...buttonStyle }}
						styleContentButton={{
							display: 'flex',
							alignItems: 'center',
						}}
						disabled={buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled)}
						onClick={handleClickDias}
						tabIndex={-1}
					/>
				</div>
				{renderizarValidacao(errors, touched)}
			</div>
			<OverlayPanel ref={overlayDias}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<label htmlFor="diasCompensacao">Quantidade de dias para compensação</label>
					<InputText
						type="number"
						title="Adicionar dias"
						id="diasCompensacao"
						name="diasCompensacao"
						value={diasCompensacao}
						onChange={(e) => setDiasCompensacao(e.target.value)}
						onBlur={onBlur}
						className={buscarClassNamePelosErros(className, errors, touched)}
						disabled={disabled}
						style={{ borderRadius: '3px', height: '32px', ...style }}
						placeholder="0"
						autoComplete="off"
					/>
					<Button
						label="Aplicar"
						title="Aplicar número de dias para compensação"
						styleContentButton={{
							display: 'flex',
							alignItems: 'center',
						}}
						style={{ width: '100%', marginTop: '8px' }}
						disabled={disabled}
						allowNegative={false}
						onClick={() => handleAdicionarDiasCompensacao(diasCompensacao)}
						tabIndex={-1}
					/>
				</div>
			</OverlayPanel>
		</Col>
	);
}

export { InputDateWithAddingDays };
