import { del, get, post } from '../../../../Common/Requisicoes';
import { services } from '../../../../Common/Constantes/api';

export async function asyncGetCobrancas(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncPostGerarCobrancas(contaId, contaReceberIds, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/pix/cobranca/contas/${contaId}/gerar`,
		contaReceberIds,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncPostDescartarCobrancas(contaReceberIds, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/pix/cobranca/descartar`,
		contaReceberIds,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncPostImprimirCobrancas(contaReceberIds, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/pix/cobranca/imprimir`,
		contaReceberIds,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetContaFavoritaReceita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/pix/relacoes/contas?query=situacao=="ATIVA";recebePix=="true"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncPostEnviarCobrancasPorEmail(data, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/pix/cobranca/enviar_email`, data, null, onSuccess, onError);
}
