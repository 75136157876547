import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, useFormikContext, withFormik } from 'formik';

import { buscarDadosLoginLocalStorage, mensagensDeValidacao } from 'Common';
import {
	AutoProgressBar,
	Button,
	ButtonCancelar,
	Checkbox,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputField,
	Modal,
	TextArea,
	estadosBotaoCancelar,
} from 'components';

import {
	asyncEnviarOrcamentoOrdemServicoPorEmail,
	asyncEnviarOrdemServicoPorEmail,
} from 'views/fiscal/servicos/OrdemServico/Requests';
import { INITIAL_COMPARTILHAR_POR_EMAIL, SITUACAO_ORDEM_SERVICO } from '../../../../../Util/constantes';

function ModalCompartilharPorEmailImpl(props) {
	const { visible, onHide, isMobile, enviarPorEmailTipo } = props;
	const { values, isValid, setFieldValue, handleSubmit, validateForm } = useFormikContext();
	const { numero, cliente, situacao, id } = values.valuesForm;

	const [emailUsuario, setEmailUsuario] = useState(null);

	useEffect(() => {
		const dadosLogin = buscarDadosLoginLocalStorage();

		let assunto = `Ordem de serviço Nº ${numero} - ${dadosLogin.filialConectada.nome}`;
		let conteudo = `Olá, segue em anexo as informações da ordem de serviço Nº ${numero}`;

		if (situacao === SITUACAO_ORDEM_SERVICO.CANCELADO) {
			assunto = `Ordem de serviço cancelado Nº ${numero} - ${dadosLogin.filialConectada.nome}`;
			conteudo = `Olá, segue em anexo as informações da ordem de serviço cancelado Nº ${numero}`;
		}

		if (enviarPorEmailTipo === 'ORCAMENTO') {
			assunto = `Orçamento - ordem de serviço Nº ${numero} - ${dadosLogin.filialConectada.nome}`;
			conteudo = `Olá, segue em anexo o orçamento da ordem de serviço Nº ${numero}`;
		}

		setFieldValue('assunto', assunto);
		setFieldValue('conteudo', conteudo);

		if (cliente?.registro && cliente.registro.emails.length > 0) {
			let emailDestinatario = null;

			cliente.registro.emails.map((e) => (e.favorito ? (emailDestinatario = e.email) : null));

			if (!emailDestinatario) {
				emailDestinatario = cliente.registro.emails[0].email;
			}

			setFieldValue('destinatarios', emailDestinatario);
		}
		setEmailUsuario(dadosLogin.email);
	}, []);

	async function handleClickEnviarPorEmail() {
		handleSubmit();
		await validateForm();

		if (isValid && values.destinatarios) {
			const destinatarios = values.destinatarios
				.split(',')
				.map((item) => item.trim())
				.filter((item) => item.trim() !== '');
			if (values.enviarCopia) {
				destinatarios.push(emailUsuario);
			}

			if (enviarPorEmailTipo === 'ORCAMENTO') {
				await asyncEnviarOrcamentoOrdemServicoPorEmail(id, { ...values, destinatarios }, () => props.onHide());
			} else {
				await asyncEnviarOrdemServicoPorEmail(id, { ...values, destinatarios }, () => props.onHide());
			}
		}
	}

	return (
		<Modal
			header={enviarPorEmailTipo === 'ORCAMENTO' ? 'Enviar orçamento por e-mail' : 'Enviar por e-mail'}
			visible={visible}
			onHide={onHide}
		>
			<AutoProgressBar />
			<Form>
				<FormActions style={{ marginBottom: '10px' }}>
					<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
					<Button
						label={isMobile ? 'Enviar' : 'Enviar por e-mail'}
						icon="fa fa-send"
						onClick={handleClickEnviarPorEmail}
						style={{ margin: '5px' }}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Destinatários"
							name="destinatarios"
							helpMessage="Pessoas que irão receber o e-mail, podem ser informados vários e-mails separados por vírgula"
							obrigatorio
							size={255}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Assunto"
							obrigatorio
							name="assunto"
							helpMessage="Assunto (título) do e-mail que será encaminhado para os destinatários"
							size={255}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={TextArea}
							label="Texto do e-mail"
							obrigatorio
							placeholder="Escreva o texto do e-mail aqui..."
							name="conteudo"
							helpMessage="Texto do e-mail que será encaminhado para os destinatários"
							rows={5}
							onChange={(e) => setFieldValue('conteudo', e.target.value)}
						/>
						<If test={emailUsuario}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Checkbox}
								label={`Enviar cópia do e-mail para mim (${emailUsuario})`}
								name="enviarCopia"
								helpMessage="Inclui o usuário do sistema na lista de destinatários ao enviar o e-mail"
								onChange={(e) => setFieldValue('enviarCopia', e.checked)}
								checked={values.enviarCopia}
							/>
						</If>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalCompartilharPorEmail = withFormik({
	enableReinitialize: true,
	validateOnChange: false,

	mapPropsToValues(props) {
		return { ...INITIAL_COMPARTILHAR_POR_EMAIL, valuesForm: props.valuesForm };
	},

	validate(values) {
		const errors = {};

		if (values.destinatarios) {
			const destinatarios = values.destinatarios.split(',').map((item) => item.trim());
			const schemaEmail = Yup.string().email();

			for (const item of destinatarios) {
				if (item === '') {
					errors.destinatarios = `O e-mail "${item}" não é um e-mail válido`;
					break;
				} else if (!schemaEmail.isValid(item)) {
					errors.destinatarios = `O e-mail "${item}" não é um e-mail válido`;
					break;
				}
			}
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		assunto: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		destinatarios: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		conteudo: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),
	handleSubmit: () => {},
})(ModalCompartilharPorEmailImpl);

export { ModalCompartilharPorEmail };
