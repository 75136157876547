import { useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';

import { services } from 'Common';
import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	If,
	ModalHistorico,
	NormalButton,
	ToastTypes,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	notify,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { useContextTabelaPreco } from 'views/cadastros/TabelaPreco/Context';
import {
	createTabelaPreco,
	deleteTabelaPreco,
	deleteTabelaPrecoProdutos,
	duplicarTabelaPreco,
	updateTabelaPreco,
	updateTabelaPrecoProdutos,
} from 'views/cadastros/TabelaPreco/Requests';
import { converterTabelaPrecoParaApi } from 'views/cadastros/TabelaPreco/Util/TabelaPrecoConverter';
import { CADASTRO_URL, INITIAL_VALUES, PESQUISA_URL } from 'views/cadastros/TabelaPreco/Util/constantes';

function ActionButtons({ fetchRegistro, setVisibleModalReplicarProdutosOutrasTabelas }) {
	const { dirty, values, handleReset, resetForm, handleSubmit, validateForm } = useFormikContext();
	const {
		informacoesPermissoes,
		history,
		setActiveTabIndex,
		isCanceled,
		isHeaderEditing,
		isHeaderSave,
		setForceClearFilter,
	} = useContextTabelaPreco();

	const [visibleModalHistorico, setVisibleModalHistorico] = useState(false);

	const menuOpcoes = useRef(null);
	const itensOpcoes = getItensOpcoes();

	function getItensOpcoes() {
		const itens = [];

		itens.push({
			label: 'Duplicar',
			icon: 'fa fa-copy',
			command: () => duplicar(values.id),
			visible: !dirty && values.id,
		});

		itens.push({
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setVisibleModalHistorico(true),
			visible: true,
		});

		itens.push({
			label: 'Replicar produtos para outras tabelas',
			icon: 'fa fa-angle-double-right',
			command: () => setVisibleModalReplicarProdutosOutrasTabelas(true),
			visible: true,
		});

		return itens.filter((element) => element.visible);
	}

	async function duplicar(id) {
		await duplicarTabelaPreco(
			id,
			async ({ data }) => {
				atualizarUrl(history, '/vendas/tabelas_preco/cadastro', data.id, metodosAtualizarUrl.POP);
				fetchRegistro(data.id);
				notify('Tabela de preço duplicada com sucesso', ToastTypes.SUCCESS);
			},
			() => {
				notify('Erro desconhecido ao duplicar a tabela de preço', ToastTypes.ERROR);
			}
		);

		setActiveTabIndex(0);
	}

	async function onClickCancelar() {
		if (dirty) {
			isCanceled.current = true;
			isHeaderEditing.current = false;
			handleReset();
			resetForm({ values: { ...values, produtosEdicao: [], produtosExclusao: [] } });
		} else {
			setForceClearFilter(true);
			voltarParaAPesquisa(history, PESQUISA_URL);
		}
	}

	async function onClickSalvar(novoOnSuccess) {
		handleSubmit();

		if (await validarFormulario({ validateForm, values })) {
			if (values.id) {
				updateRegistro(values, novoOnSuccess);
			} else {
				createRegistro(values, novoOnSuccess);
			}

			isHeaderEditing.current = false;
			isHeaderSave.current = true;
		}
	}

	function onClickNovo() {
		if (dirty) {
			onClickSalvar(novoRegistro);
		} else {
			novoRegistro();
		}
	}

	function onClickExcluir() {
		confirmarExclusao(deleteRegistro);
	}

	async function updateRegistro(formulario, novoOnSuccess) {
		await updateTabelaPreco(converterTabelaPrecoParaApi(formulario), async ({ data: tabelaPreco }) => {
			if (values.produtosEdicao?.length > 0 || values.produtosExclusao?.length > 0) {
				const url = `${services.GESTOR}/v1/vendas/tabelas_preco/${tabelaPreco?.id}/produtos`;
				if (values.produtosEdicao) {
					await updateTabelaPrecoProdutos(`${url}/alterar`, values.produtosEdicao);
				}
				if (values.produtosExclusao) {
					await deleteTabelaPrecoProdutos(`${url}/excluir`, values.produtosExclusao);
				}
			}

			resetForm({ values: { ...formulario, produtosEdicao: [], produtosExclusao: [], id: tabelaPreco.id } });

			if (typeof novoOnSuccess === 'function') {
				novoOnSuccess();
			}
		});
	}

	async function createRegistro(formulario, novoOnSuccess) {
		await createTabelaPreco(converterTabelaPrecoParaApi(formulario), ({ data: tabelaPreco }) => {
			atualizarUrl(history, CADASTRO_URL, tabelaPreco.id, metodosAtualizarUrl.POP);
			resetForm({ values: { ...formulario, id: tabelaPreco.id, codigo: tabelaPreco.codigo } });

			if (typeof novoOnSuccess === 'function') {
				novoOnSuccess();
			}
		});
	}

	async function deleteRegistro() {
		await deleteTabelaPreco(values.id, () => {
			voltarParaAPesquisa(history, PESQUISA_URL);
		});
	}

	async function novoRegistro() {
		atualizarUrl(history, CADASTRO_URL, null, metodosAtualizarUrl.POP);

		setActiveTabIndex(0);
		resetForm({ values: INITIAL_VALUES });
	}

	return (
		<>
			<Menu model={itensOpcoes} popup ref={menuOpcoes} />
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={() => onClickCancelar()}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar disabled={!dirty} onClick={() => onClickSalvar()} {...informacoesPermissoes} />
			<ButtonNovo
				onClick={(e) => onClickNovo(e)}
				hidden={!dirty && !values.id}
				estadoBotao={dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir hidden={!values.id} onClick={() => onClickExcluir()} {...informacoesPermissoes} />
			<NormalButton
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={dirty || !values.id}
				onClick={(event) => menuOpcoes.current.toggle(event)}
				{...informacoesPermissoes}
			/>
			<If test={visibleModalHistorico}>
				<ModalHistorico
					header="Histórico da tabela de preço"
					visible={visibleModalHistorico}
					onHide={() => setVisibleModalHistorico(false)}
					mapping="vendas/tabelas_preco"
					id={values.id}
				/>
			</If>
		</>
	);
}

export { ActionButtons };
