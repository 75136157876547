import propTypes from 'prop-types';
import Button from '..';

function ButtonExcluirTable(props) {
	const { id, onClick, hidden, styleContentButton } = props;

	const style = {
		borderRadius: '50%',
		padding: '5px',
		width: '30px',
		height: '30px',
		marginLeft: '2.5px',
		marginRight: '2.5px',
	};

	function getDisabled() {
		if (props.podeExcluir === false) return true;
		return props.disabled;
	}

	function getTitle() {
		if (props.podeExcluir === false) return 'Você não possui permissão para executar essa ação';
		return props.title;
	}

	return (
		<Button
			id={id}
			type="button"
			color="danger"
			size="icon"
			disabled={getDisabled()}
			onClick={onClick}
			hidden={hidden}
			icon="fa fa-trash-o"
			enableShortCut={false}
			style={style}
			styleContentButton={styleContentButton}
			title={getTitle()}
		/>
	);
}

ButtonExcluirTable.defaultProps = {
	hidden: false,
	disabled: false,
	title: 'Excluir o registro',
};

ButtonExcluirTable.propTypes = {
	/** Identificador do componente */
	id: propTypes.string,
	/** Derfine o botão como invisível */
	hidden: propTypes.bool,
	/** Ação executada ao clicar no botão */
	onClick: propTypes.func,
	/** Define o botão como desabilitado */
	disabled: propTypes.bool,
	/** Diz se o usuário possui permissão de excluir*/
	podeExcluir: propTypes.bool,
	/** Title do componente */
	title: propTypes.string,
};

export default ButtonExcluirTable;
