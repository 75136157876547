import React, { useEffect } from 'react';
import { withFormik, Field } from 'formik';
import { isValidCEP } from '@brazilian-utils/brazilian-utils';
import { useEffectOnce } from 'react-use';
import * as Yup from 'yup';

import { buscarMascaraTelefone, mensagensDeValidacao } from '../../../../../../../Common';

import {
	Grid,
	Form,
	FormActions,
	FormContent,
	InputField,
	InputMask,
	InputCep,
	SingleSelectMunicipio,
	ButtonSalvar,
	estadosBotaoSalvar,
	ButtonCancelar,
	estadosBotaoCancelar,
} from '../../../../../../../components';

import { validarFormulario } from '../../../../../../Util';
import { converterParaModal } from '../Util/converterParaModal';
import { municipioParaModal } from '../Util/constantes';

const initialvalues = {
	nome: null,
	nomeFantasia: null,
	cpf: null,
	cnpj: null,
	inscricaoEstadual: null,
	inscricaoEstadualSubstituto: null,
	InscricaoMunicipal: null,
	cnae: null,
	regimeTributario: null,
	cep: null,
	logradouro: null,
	bairro: null,
	numero: null,
	complemento: null,
	telefone: null,
	municipio: null,
};

function FormDadosFornecedorView(props) {
	const {
		handleSubmit,
		setFieldValue,
		onHide,
		informacoesPermissoes,
		values,
		valuesDadosFornecedor,
		dirty,
		resetForm,
		onSave,
		isFinalizado,
		mainFormSubmitCount,
		setTouched,
		isMobile,
	} = props;

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('inputNome')?.focus();
		}, 250);
	}, []);

	useEffectOnce(() => {
		if (mainFormSubmitCount > 0) {
			setTouched({ nome: true, cep: true, logradouro: true, numero: true });
		}
	});

	function cancelar() {
		if (dirty) {
			resetForm('');
		} else {
			onHide();
		}
	}

	async function onChangeEnderDest(field, value) {
		let values = { ...props.values };
		values.field = value;

		if (value && value.registro && (field === 'municipio' || field === 'cep')) {
			props.setFieldValue('idEstrangeiro', value.registro.estado.id);
			props.setFieldValue('estAliqInt', value.registro?.estado.aliquotaInterna);
		}
		if (field === 'municipio' && value !== values.municipio?.value) {
			props.setFieldValue('cep', null);
		}

		await props.setFieldValue(field, value);
	}

	async function onChangeCep(elemento) {
		if (elemento.pesquisou) {
			elemento.bairro && (await onChangeEnderDest('bairro', elemento.bairro));
			elemento.complemento && (await onChangeEnderDest('complemento', elemento.complemento));
			elemento.logradouro && (await onChangeEnderDest('logradouro', elemento.logradouro));
			elemento.municipio &&
				(await onChangeEnderDest('municipio', {
					label: elemento.municipio.nome + ' - ' + elemento.municipio.estado.sigla,
					registro: elemento.municipio,
					value: elemento.municipio.id,
				}));
			elemento.pais &&
				(await onChangeEnderDest('pais', {
					label: elemento.pais.nome,
					value: elemento.pais.id,
				}));
			onChangeEnderDest('cep', elemento.value);
		} else {
			onChangeEnderDest('cep', elemento.value);
		}
	}

	async function confirmar() {
		handleSubmit();
		let valoresForm = { ...valuesDadosFornecedor, ...values };
		if (dirty && (await validarFormulario(props))) {
			onSave(valoresForm);
			onHide();
		}
	}

	return (
		<div
			style={{
				width: '100%',
				overflowY: isMobile ? 'scroll' : 'hidden',
				overflowX: 'hidden',
				maxHeight: '40rem',
			}}
		>
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
					/>
					<ButtonSalvar estadoBotao={estadosBotaoSalvar.CONFIRMAR} onClick={() => confirmar()} disabled={!dirty} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="6"
							xl="6"
							id="inputNome"
							obrigatorio
							component={InputField}
							label="Razão social"
							name="nome"
							value={values.nome}
							onChange={elemento => setFieldValue('nome', elemento.target.value)}
							disabled={isFinalizado}
							{...informacoesPermissoes}
						/>

						<Field
							sm="12"
							md="12"
							lg="6"
							xl="6"
							component={InputField}
							label="Nome fantasia"
							name="nomeFantasia"
							value={values.nomeFantasia}
							onChange={elemento => setFieldValue('nomeFantasia', elemento.target.value)}
							disabled={isFinalizado}
							{...informacoesPermissoes}
						/>

						{values.cnpj !== null && (
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={InputMask}
								mask="00.000.000/0000-00"
								placeholder="  .   .   /    -  "
								label="CNPJ"
								onChange={elemento => setFieldValue('cnpj', elemento.target.value)}
								value={values.cnpj}
								disabled={isFinalizado}
								{...informacoesPermissoes}
							/>
						)}
						{values.cpf !== null && (
							<Field
								sm="12"
								md="12"
								lg="4"
								xl="4"
								component={InputMask}
								mask="000.000.000-00"
								placeholder="   .   .   -  "
								label="CPF "
								value={values.cpf}
								onChange={elemento => setFieldValue('cpf', elemento.target.value)}
								disabled={isFinalizado}
								{...informacoesPermissoes}
							/>
						)}

						<Field
							sm="12"
							md="12"
							lg="3"
							xl="3"
							component={InputField}
							size={14}
							label="Inscrição estadual"
							value={values.inscricaoEstadual}
							name="inscricaoEstadual"
							onChange={elemento =>
								setFieldValue('inscricaoEstadual', elemento.target.value ? elemento.target.value.toUpperCase() : '')
							}
							disabled={isFinalizado}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="3"
							xl="3"
							component={InputField}
							size={9}
							label="Inscrição municipal"
							name="inscricaoMunicipal"
							value={values.inscricaoMunicipal}
							onChange={elemento => setFieldValue('inscricaoMunicipal', elemento.target.value)}
							disabled={isFinalizado}
							{...informacoesPermissoes}
						/>

						<Field
							sm="12"
							md="12"
							lg="2"
							xl="2"
							component={InputMask}
							mask={buscarMascaraTelefone(values.telefone)}
							label="Telefone"
							name="telefone"
							placeholder={'(  )      -    '}
							value={values.telefone}
							onChange={elemento => onChangeEnderDest('telefone', elemento.target.value)}
							disabled={isFinalizado}
							{...informacoesPermissoes}
						/>
					</Grid>
					<h1 style={{ paddingTop: '16px' }}>Endereço</h1>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="4"
							xl="4"
							obrigatorio
							component={InputCep}
							label="CEP"
							name="cep"
							type="tel"
							onChange={elemento => onChangeCep(elemento)}
							value={values.cep}
							disabled={isFinalizado}
							{...informacoesPermissoes}
						/>

						<Field
							sm="12"
							md="12"
							lg="4"
							xl="4"
							obrigatorio
							component={SingleSelectMunicipio}
							label="Município "
							name="municipio"
							onChange={elemento => onChangeEnderDest('municipio', elemento)}
							value={values.municipio}
							disabled={isFinalizado}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="4"
							xl="4"
							component={InputField}
							size={60}
							label="Logradouro"
							name="logradouro"
							obrigatorio
							value={values.logradouro}
							onChange={elemento => onChangeEnderDest('logradouro', elemento.target.value)}
							disabled={isFinalizado}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="4"
							xl="4"
							component={InputField}
							size={60}
							label="Bairro"
							name="bairro"
							value={values.bairro}
							onChange={elemento => onChangeEnderDest('bairro', elemento.target.value)}
							disabled={isFinalizado}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="2"
							xl="2"
							component={InputField}
							size={60}
							label="Número"
							name="numero"
							obrigatorio
							value={values.numero}
							onChange={elemento => onChangeEnderDest('numero', elemento.target.value)}
							disabled={isFinalizado}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="6"
							xl="6"
							component={InputField}
							size={60}
							label="Complemento"
							name="complemento"
							value={values.complemento}
							onChange={elemento => onChangeEnderDest('complemento', elemento.target.value)}
							disabled={isFinalizado}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</div>
	);
}
const FormDadosFornecedor = withFormik({
	validateOnMount: true,
	validateOnChange: true,

	mapPropsToValues(props) {
		if (props.valuesDadosFornecedor) {
			return converterParaModal(props.valuesDadosFornecedor);
		} else {
			return initialvalues;
		}
	},

	validate(value) {
		let errors = {};

		if (!value.logradouro) {
			errors.logradouro = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!value.numero) {
			errors.numero = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!value.nome) {
			errors.nome = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!value.municipio) {
			errors.municipio = mensagensDeValidacao.OBRIGATORIO;
		}

		if (value.municipio?.value !== municipioParaModal.EXTERIOR) {
			if (!value.cep) {
				errors.cep = mensagensDeValidacao.OBRIGATORIO;
			} else {
				if (!isValidCEP(value.cep)) {
					errors.cep = mensagensDeValidacao.CEP_INVALIDO;
				}
			}
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		logradouro: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		numero: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(FormDadosFornecedorView);

export default FormDadosFornecedor;
