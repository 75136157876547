import { useEffect, useState } from 'react';
import { Field, withFormik, Form } from 'formik';
import { connect } from 'react-redux';

import {
  InputField,
  Checkbox,
  Col,
  InputDouble,
  InputSelectPercentualOrValor,
  FormActions,
  FormContent,
  ButtonCancelar,
  ButtonSalvar,
  Button,
  If,
  Divider,
  estadosBotaoCancelar,
} from '../../../../../../../components';

import { asyncAtualizarPrecos } from '../../../../Requests';
import { validarFormulario } from '../../../../../../Util';

import './Styles/index.css';
import { calcularCusto } from 'views/compras/NotasEntrada/Util/functions';

const tipos = {
  VALOR: 'VALOR',
  PERCENTUAL: 'PERCENTUAL',
};

const initialValues = {
  atualizarPrecoAtacado: true,
  itens: [],
};

function FormAtualizacaoPrecosView(props) {
  const { values, setFieldValue, isMobile, isTablet, idNotaEntrada, onHide, errors } = props;

  const [valor, setValor] = useState(0);
  const [percentual, setPercentual] = useState(0);
  const [valorAtacado, setValorAtacado] = useState(0);
  const [percentualAtacado, setPercentualAtacado] = useState(0);
  const [tipoOperacaoVenda, setTipoOperacaoVenda] = useState(tipos.VALOR);
  const [tipoOperacaoAtacado, setTipoOperacaoAtacado] = useState(tipos.VALOR);
  const [isUserInput, setIsUserInput] = useState(false);

  useEffect(() => {
    for (let i = 0; i < values.itens.length; i++) {
      setFieldValue(`itens.${i}.precoNovo`, values.itens[i].produto?.preco ?? 0);
      setFieldValue(`itens.${i}.precoNovoAtacado`, values.itens[i].produto?.precoAtacado ?? 0);
    }
  }, []);

  function calcularPrecoComMargem(atual, porcentagem) {
    let porcentagemFinal;
    let precoFinal;

    if (typeof porcentagem === 'string') {
      porcentagemFinal = parseFloat(porcentagem.slice(0, -1).replace(/,/, '.'));
    } else {
      porcentagemFinal = parseFloat(porcentagem);
    }

    precoFinal = parseFloat(atual) + (porcentagemFinal / 100) * parseFloat(atual);

    return precoFinal;
  }

  function calcularMargemDeLucro(atual, final) {
    let finalCorrigido;
    let margemFinal;

    if (typeof final === 'string') {
      finalCorrigido = parseFloat(final.substring(3).replace(/,/, '.'));
    } else {
      finalCorrigido = parseFloat(final);
    }

    margemFinal = ((finalCorrigido - parseFloat(atual)) / parseFloat(atual)) * 100;

    return margemFinal;
  }

  function calcularAtualizacaoLucroVenda() {
    //venda
    if (tipoOperacaoVenda === tipos.VALOR) {
      if (valor > 0) {
        values.itens.map((item, index) => {
          const unitarioConvertido = calcularCusto(item.subTotal, item.quantidadeConvertida);
          setFieldValue(`itens.${index}.precoNovo`, unitarioConvertido + valor);
          setFieldValue(
            `itens.${index}.produto.margemLucro`,
            calcularMargemDeLucro(unitarioConvertido, unitarioConvertido + valor)
          );
        });
      }
    }
    if (tipoOperacaoVenda === tipos.PERCENTUAL) {
      if (percentual > 0) {
        values.itens.map((item, index) => {
          const unitarioConvertido = calcularCusto(item.subTotal, item.quantidadeConvertida);
          setFieldValue(`itens.${index}.produto.margemLucro`, percentual);
          setFieldValue(`itens.${index}.precoNovo`, calcularPrecoComMargem(unitarioConvertido, percentual));
        });
      }
    }

    //atacado
    if (tipoOperacaoAtacado === tipos.VALOR) {
      if (valorAtacado > 0) {
        values.itens.map((item, index) => {
          const unitarioConvertido = calcularCusto(item.subTotal, item.quantidadeConvertida);
          setFieldValue(`itens.${index}.precoNovoAtacado`, unitarioConvertido + valorAtacado);
          setFieldValue(
            `itens.${index}.produto.margemLucroAtacado`,
            calcularMargemDeLucro(unitarioConvertido, unitarioConvertido + valorAtacado)
          );
        });
      }
    }
    if (tipoOperacaoAtacado === tipos.PERCENTUAL) {
      if (percentualAtacado > 0) {
        values.itens.map((item, index) => {
          const unitarioConvertido = calcularCusto(item.subTotal, item.quantidadeConvertida);
          setFieldValue(`itens.${index}.produto.margemLucroAtacado`, percentualAtacado);
          setFieldValue(
            `itens.${index}.precoNovoAtacado`,
            calcularPrecoComMargem(unitarioConvertido, percentualAtacado)
          );
        });
      }
    }
  }

  function isOdd(num) {
    return num % 2;
  }

  function handleKeyPress(e) {
    setIsUserInput(true);
  }

  async function onSaveModal() {
    if (await validarFormulario(props)) {
      let itens = [];

      values.itens.forEach(item => {
        itens.push({
          idProduto: item.produto.id,
          margemLucro: item.produto.margemLucro ?? 0,
          preco: item.precoNovo ?? 0,
          margemLucroAtacado: item.produto.margemLucroAtacado ?? 0,
          precoAtacado: item.precoNovoAtacado ?? 0,
          atualizarPrecoAtacado: values.atualizarPrecoAtacado,
        });
      });

      await asyncAtualizarPrecos(idNotaEntrada, itens, () => onHide());
    }
  }

  function RenderCabecalhoForm() {
    return (
      <div
        style={{
          display: isMobile || isTablet ? 'none' : 'inline-flex',
          width: '100%',
          padding: '0',
          marginBottom: '-1rem',
        }}
      >
        <Col sm="6" md="12" lg="1" xl="1" className="customCol">
          <p>Código</p>
        </Col>
        <Col sm="6" md="12" lg="4" xl="4" className="customCol">
          <p>Produto</p>
        </Col>
        <Col sm="6" md="12" lg="2" xl="2" className="customCol" title="Valor unitário com fator de conversão aplicado">
          <p>Custo</p>
        </Col>
        <Col sm="6" md="12" lg="1" xl="1" className="customCol">
          <p>Margem</p>
        </Col>
        <Col sm="6" md="12" lg="2" xl="2" className="customCol">
          <p>Preço atual</p>
        </Col>
        <Col sm="6" md="12" lg="2" xl="2" className="customCol">
          <p>Preço novo</p>
        </Col>
      </div>
    );
  }

  return (
    <>
      <Form>
        <FormActions style={{ margin: '0px' }}>
          <div style={{ margin: '0 0 0 -4px' }}>
            <ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
            <ButtonSalvar onClick={onSaveModal} />
          </div>
          <div
            style={{
              display: isMobile || isTablet ? 'block' : 'inline-flex',
              width: '100%',
              alignItems: 'center',
              marginLeft: '-7px',
            }}
          >
            <Field
              sm="4"
              md="12"
              lg={'3'}
              xl={'3'}
              label={tipoOperacaoVenda === tipos.VALOR ? 'Valor de lucro' : 'Porcentagem de lucro'}
              name={'operacao'}
              component={InputSelectPercentualOrValor}
              value={valor}
              valueBase={percentual}
              onChange={(tipo, value, percentual) => {
                if (tipo === 'VALOR') {
                  setTipoOperacaoVenda(tipos.VALOR);
                  setValor(value);
                } else {
                  setTipoOperacaoVenda(tipos.PERCENTUAL);
                  setPercentual(percentual);
                }
              }}
              size={15}
              className="customInput"
              maxValuePercental={999}
            />
            <If test={values.atualizarPrecoAtacado}>
              <Field
                sm="4"
                md="12"
                lg={'3'}
                xl={'3'}
                label={tipoOperacaoAtacado === tipos.VALOR ? 'Valor de lucro' : 'Porcentagem de lucro'}
                name={'operacaoAtacado'}
                component={InputSelectPercentualOrValor}
                value={valorAtacado}
                valueBase={percentualAtacado}
                onChange={(tipo, value, percentual) => {
                  if (tipo === 'VALOR' && values.atualizarPrecoAtacado) {
                    setTipoOperacaoAtacado(tipos.VALOR);
                    setValorAtacado(value);
                  } else {
                    setTipoOperacaoAtacado(tipos.PERCENTUAL);
                    setPercentualAtacado(percentual);
                  }
                }}
                size={15}
                className="customInput"
                maxValuePercental={999}
              />
            </If>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: isMobile ? '8px' : '16px',
                marginBottom: isMobile ? '8px' : '0px',
              }}
            >
              <Button
                type="button"
                label={isMobile ? 'Aplicar' : 'Aplicar lucro'}
                icon="fa fa-plus"
                onClick={() => {
                  calcularAtualizacaoLucroVenda();
                }}
                style={{
                  marginLeft: isMobile ? '8px' : '0px',
                  width: 'max-content',
                }}
              />
              <Checkbox
                label={'Atualizar preço atacado'}
                name={'atualizarPrecoAtacado'}
                checked={values.atualizarPrecoAtacado}
                onChange={() => setFieldValue('atualizarPrecoAtacado', !values.atualizarPrecoAtacado)}
              />
            </div>
          </div>
        </FormActions>
        <FormContent>
          <div
            style={{
              maxHeight:
                isMobile && !values.atualizarPrecoAtacado
                  ? '18rem'
                  : isMobile && values.atualizarPrecoAtacado
                  ? '14rem'
                  : '20rem',
              overflowY: values.itens?.length > 6 ? 'scroll' : 'visible',
              overflowX: 'hidden',
            }}
          >
            <RenderCabecalhoForm />
            {values.itens?.length > 0
              ? values.itens.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'block',
                        width: '100%',
                        backgroundColor: isMobile ? (isOdd(index) === 0 ? 'rgba(196, 196, 196, 0.3)' : null) : null,
                      }}
                    >
                      <If test={isMobile || isTablet}>
                        <Divider label={`Item ${item.item}`} />
                      </If>
                      <div
                        style={{
                          display: isMobile || isTablet ? 'block' : 'inline-flex',
                        }}
                        className="customDivItems"
                      >
                        <Field
                          sm="12"
                          md="12"
                          lg="1"
                          xl="1"
                          label={isMobile || isTablet ? 'Código' : null}
                          component={InputField}
                          disabled={true}
                          value={item.produto.codigo}
                          className="customInput"
                        />
                        <Field
                          sm="12"
                          md="12"
                          lg="4"
                          xl="4"
                          label={isMobile || isTablet ? 'Produto' : null}
                          component={InputField}
                          disabled={true}
                          value={item.produto.nome}
                          className="customInput"
                          useFormTouched={false}
                          touched
                          warning={
                            item.produto.margemLucro < 0
                              ? 'Margem de lucro menor que zero'
                              : item.produto.margemLucroAtacado < 0 && values.atualizarPrecoAtacado
                              ? 'Margem de lucro atacado menor que zero'
                              : null
                          }
                        />
                        <InputDouble
                          sm="6"
                          md="12"
                          lg="2"
                          xl="2"
                          disabled={true}
                          label={isMobile || isTablet ? 'Custo' : null}
                          value={calcularCusto(item.subTotal, item.quantidadeConvertida)}
                          prefix="R$ "
                          placeholder="R$ 0,00"
                          allowNegative={false}
                          className="customInput"
                        />
                        <InputDouble
                          sm="6"
                          md="12"
                          lg="1"
                          xl="1"
                          label={isMobile || isTablet ? 'Margem de lucro' : null}
                          name={`margemLucro`}
                          value={item.produto.margemLucro}
                          onChange={event => {
                            setFieldValue(`itens.${index}.produto.margemLucro`, event?.target.value);
                          }}
                          onKeyDown={e => handleKeyPress(e)}
                          onBlur={event => {
                            if (isUserInput) {
                              setIsUserInput(false);
                              setFieldValue(
                                `itens.${index}.precoNovo`,
                                calcularPrecoComMargem(
                                  calcularCusto(item.subTotal, item.quantidadeConvertida),
                                  event?.target.value
                                )
                              );
                            }
                          }}
                          suffix=" %"
                          placeholder="0,00 %"
                          className="customInput"
                        />
                        <InputDouble
                          sm="6"
                          md="12"
                          lg="2"
                          xl="2"
                          disabled={true}
                          label={isMobile || isTablet ? 'Preço atual' : null}
                          value={item.produto.preco}
                          prefix="R$ "
                          placeholder="R$ 0,00"
                          allowNegative={false}
                          className="customInput"
                        />
                        <InputDouble
                          sm="6"
                          md="12"
                          lg="2"
                          xl="2"
                          label={isMobile || isTablet ? 'Preço novo' : null}
                          name={`precoNovo`}
                          value={item.precoNovo}
                          onKeyDown={e => handleKeyPress(e)}
                          onChange={event => {
                            setFieldValue(`itens.${index}.precoNovo`, event?.target.value);
                          }}
                          onBlur={event => {
                            if (isUserInput) {
                              setIsUserInput(false);
                              setFieldValue(
                                `itens.${index}.produto.margemLucro`,
                                calcularMargemDeLucro(
                                  calcularCusto(item.subTotal, item.quantidadeConvertida),
                                  event?.target.value
                                )
                              );
                            }
                          }}
                          prefix="R$ "
                          placeholder="R$ 0,00"
                          allowNegative={false}
                          className="customInput"
                          touched
                          errors={errors && errors.itens?.length > 0 ? errors.itens[index]?.precoNovo : null}
                          useFormTouched={false}
                        />
                      </div>
                      <If test={values.atualizarPrecoAtacado}>
                        <div
                          style={{
                            display: isMobile ? 'block' : 'inline-flex',
                            width: '100%',
                            justifyContent: 'end',
                            alignItems: 'center',
                            marginTop: '-8px',
                          }}
                        >
                          <If test={!isMobile && !isTablet}>
                            <Col sm="6" md="12" lg="2" xl="2" className="customCol">
                              <p
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  justifyContent: 'flex-end',
                                  margin: '0 -8px',
                                  marginBottom:
                                    errors &&
                                    errors.itens?.length > 0 &&
                                    values.atualizarPrecoAtacado &&
                                    errors.itens[index]?.precoNovoAtacado
                                      ? '16px'
                                      : '0px',
                                }}
                              >
                                Preço atacado
                              </p>
                            </Col>
                          </If>
                          <InputDouble
                            sm="6"
                            md="12"
                            lg="1"
                            xl="1"
                            label={isMobile || isTablet ? 'Margem de lucro atacado' : null}
                            name={`margemLucroAtacado`}
                            value={item.produto.margemLucroAtacado}
                            onKeyDown={e => handleKeyPress(e)}
                            onChange={event =>
                              setFieldValue(`itens.${index}.produto.margemLucroAtacado`, event?.target.value)
                            }
                            onBlur={event => {
                              if (isUserInput) {
                                setIsUserInput(false);
                                setFieldValue(
                                  `itens.${index}.precoNovoAtacado`,
                                  calcularPrecoComMargem(
                                    calcularCusto(item.subTotal, item.quantidadeConvertida),
                                    event?.target.value
                                  )
                                );
                              }
                            }}
                            suffix=" %"
                            placeholder="0,00 %"
                            className="customInput"
                            style={{
                              marginBottom:
                                errors &&
                                errors.itens?.length > 0 &&
                                values.atualizarPrecoAtacado &&
                                errors.itens[index]?.precoNovoAtacado
                                  ? '16px'
                                  : '0px',
                            }}
                          />
                          <InputDouble
                            sm="6"
                            md="12"
                            lg="2"
                            xl="2"
                            disabled={true}
                            label={isMobile || isTablet ? 'Preço atacado atual' : null}
                            value={item.produto.precoAtacado}
                            onChange={event => setFieldValue(`itens.${index}.precoAtacado`, event?.target.value)}
                            prefix="R$ "
                            placeholder="R$ 0,00"
                            allowNegative={false}
                            className="customInput"
                            style={{
                              marginBottom:
                                errors &&
                                errors.itens?.length > 0 &&
                                values.atualizarPrecoAtacado &&
                                errors.itens[index]?.precoNovoAtacado
                                  ? '16px'
                                  : '0px',
                            }}
                          />
                          <InputDouble
                            sm="6"
                            md="12"
                            lg="2"
                            xl="2"
                            label={isMobile || isTablet ? 'Preço atacado novo' : null}
                            name={`precoNovoAtacado`}
                            value={item.precoNovoAtacado}
                            onKeyDown={e => handleKeyPress(e)}
                            onChange={event => setFieldValue(`itens.${index}.precoNovoAtacado`, event?.target.value)}
                            onBlur={event => {
                              if (isUserInput) {
                                setIsUserInput(false);
                                setFieldValue(
                                  `itens.${index}.produto.margemLucroAtacado`,
                                  calcularMargemDeLucro(
                                    calcularCusto(item.subTotal, item.quantidadeConvertida),
                                    event?.target.value
                                  )
                                );
                              }
                            }}
                            prefix="R$ "
                            placeholder="R$ 0,00"
                            allowNegative={false}
                            className="customInput"
                            touched
                            errors={
                              errors && errors.itens?.length > 0 && values.atualizarPrecoAtacado
                                ? errors.itens[index]?.precoNovoAtacado
                                : null
                            }
                            useFormTouched={false}
                          />
                        </div>
                      </If>
                    </div>
                  );
                })
              : null}
          </div>
        </FormContent>
      </Form>
    </>
  );
}

const mapStateToProps = state => ({
  isMobile: state.dispositivo.isMobile,
  isTablet: state.dispositivo.isTablet,
});

const FormAtualizacaoPrecos = withFormik({
  enableReinitialize: true,
  validateOnChange: false,

  mapPropsToValues(props) {
    if (props.itens) {
      return {
        itens: Array.from(props.itens).sort((a, b) => {
          if (a.item > b.item) {
            return 1;
          }
          if (a.item < b.item) {
            return -1;
          }
          return 0;
        }),
        atualizarPrecoAtacado: false,
      };
    }
    return initialValues;
  },
  validate: values => {
    let errors = { itens: [] };

    values.itens.forEach((item, index) => {
      if (!item?.precoNovo || item?.precoNovo === 0) {
        errors.itens[index] = {
          ...errors.itens[index],
          precoNovo: 'Campo obrigatório',
        };
      }
      if (values.atualizarPrecoAtacado && (!item.precoNovoAtacado || item.precoNovoAtacado === 0)) {
        errors.itens[index] = {
          ...errors.itens[index],
          precoNovoAtacado: 'Campo obrigatório',
        };
      }
    });

    return errors.itens.length > 0 ? errors : null;
  },
  handleSubmit: () => {},
})(FormAtualizacaoPrecosView);

export default connect(mapStateToProps)(FormAtualizacaoPrecos);
