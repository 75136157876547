import { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { construirUrl } from '../../Common/Rsql';
import { services } from '../../Common/Constantes/api';
import Modal from '../Modal';
import ButtonCancelar, { estadosBotaoCancelar } from '../Button/ButtonCancelar';
import NenhumRegistroEncontrado from '../NenhumRegistroEncontrado';
import { get } from '../../Common/Requisicoes';
import { validarUUID } from '../../Common/ManipulacaoDeString';
import { buscarIconeHistorico } from '../../views/documentos/AssinaturaEletronica/Form/components/DocumentoHistorico/Util';
import Paginacao from '../Paginacao';

export default function ModalHistorico(props) {
	const { visible, onHide, id, mapping, header, showIcon = true } = props;

	const [sortField, setSortField] = useState('dataHora');
	const [totalElements, setTotalElements] = useState(0);
	const [registros, setRegistros] = useState([]);
	const [sortOrder, setSortOrder] = useState(-1);
	const [page, setPage] = useState(0);
	const [rows, setRow] = useState(10);

	useEffect(() => {
		const getUrl = () => {
			return construirUrl(
				`${services.GESTOR}/v1/${mapping}/${id}/historico`,
				'?query=()',
				rows,
				page,
				sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
			);
		};

		const pesquisar = () => {
			asyncGetHistorico(getUrl(), ({ data: historico }) => {
				setRegistros(historico.content);
				setTotalElements(historico.totalElements);
			});
		};
		validarUUID(id) && pesquisar();
	}, [rows, page, sortField, sortOrder, id, mapping]);

	async function asyncGetHistorico(url, onSuccess, onError) {
		return await get(url, null, onSuccess, onError);
	}

	function handleSortChange(element) {
		setSortField(element.sortField);
		setSortOrder(element.sortOrder);
	}

	function onPageChange(element) {
		setPage(element.page);
		setRow(element.rows);
	}

	function renderData(row) {
		return row.dataHora ? <span>{format(new Date(row.dataHora), 'dd/MM/yyyy HH:mm:ss')}</span> : '';
	}

	function getOperacao(row) {
		let icon = showIcon && buscarIconeHistorico(row.acao ?? row.operacao);
		return (
			<>
				{showIcon && <i className={icon} style={{ marginRight: '0.6rem' }}></i>}
				{row.acao ?? row.operacao}
			</>
		);
	}

	function getUsuario(row) {
		const email = row.nome && row.email ? `(${row.email})` : row.email;
		return `${row.nome ?? ''} ${email ?? ''}`.trim();
	}

	return (
		<>
			<Modal header={header} visible={visible} onHide={onHide}>
				<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
				<DataTable
					className="table"
					responsive
					value={registros}
					sortField={sortField}
					sortOrder={sortOrder}
					onSort={handleSortChange}
					emptyMessage={<NenhumRegistroEncontrado />}
				>
					<Column field="dataHora" header="Data" body={(row) => renderData(row)} sortable />
					<Column field="nome" header="Usuário" body={getUsuario} />
					<Column field="acao" header="Ação" body={getOperacao} />
				</DataTable>
				<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
			</Modal>
		</>
	);
}
