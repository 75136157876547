import { Field, useFormikContext } from 'formik';

import { Grid, InputDate, InputField, SingleSelectEstado, SingleSelectPessoa } from 'components';
import { removerCaracteres } from 'Common';

function Importacao({ importacao, disabled, informacoesPermissoes }) {
	const { setFieldValue } = useFormikContext();
	return (
		<Grid>
			<Field
				sm="12"
				md="12"
				lg="6"
				xl="6"
				label="Número Documento Importação"
				name="importacao.numeroDocumentoImportacao"
				component={InputField}
				size={10}
				value={importacao?.numeroDocumentoImportacao}
				disabled={disabled}
				onChange={(e) => setFieldValue('importacao.numeroDocumentoImportacao', e.target.value)}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="6"
				lg="3"
				xl="3"
				name="importacao.dataRegistro"
				label="Data de registro"
				component={InputDate}
				value={importacao?.dataRegistro}
				disabled={disabled}
				onChange={(e) => setFieldValue('importacao.dataRegistro', e.target.value)}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="6"
				lg="3"
				xl="3"
				name="importacao.dataDesembaraco"
				label="Data de desembaraço"
				component={InputDate}
				value={importacao?.dataDesembaraco}
				disabled={disabled}
				onChange={(e) => setFieldValue('importacao.dataDesembaraco', e.target.value)}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="8"
				lg="8"
				xl="8"
				label="Local de desembaraço"
				name="importacao.localDesembaraco"
				component={InputField}
				onBlur={() => {
					if (importacao.localDesembaraco) {
						setFieldValue('importacao.localDesembaraco', importacao.localDesembaraco.trim());
					}
				}}
				onChange={(e) => setFieldValue('importacao.localDesembaraco', removerCaracteres(e.target?.value, ['─']))}
				value={importacao?.localDesembaraco}
				size={60}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				label="UF de desembaraço"
				name="importacao.estadoDesembaraco"
				component={SingleSelectEstado}
				value={importacao?.estadoDesembaraco}
				onChange={(event) => setFieldValue('importacao.estadoDesembaraco', event)}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				label="Exportador"
				name="importacao.exportador"
				component={SingleSelectPessoa}
				value={importacao?.exportador}
				onChange={(event) => setFieldValue('importacao.exportador', event)}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				label="Fabricante"
				name="importacao.fabricante"
				component={SingleSelectPessoa}
				value={importacao?.fabricante}
				onChange={(event) => setFieldValue('importacao.fabricante', event)}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { Importacao };
