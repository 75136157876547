import { copiarObjeto } from '../../../../../../../Common';
import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	estadosBotaoSalvar,
} from '../../../../../../../components';
import { validarFormulario } from '../../../../../../Util';
import {
	asyncAtualizarSaldoInicial,
	asyncCreateRegistro,
	asyncDeleteRegistro,
	asyncGetContaJaExistente,
	asyncGetContaMesmoNome,
	asyncUpdateRegistro,
} from '../../Requests';
import { converterContaParaApi } from '../../Util/contaConverter';

function ActionButtons(props) {
	const {
		isModal,
		values,
		dirty,
		informacoesPermissoes,
		insertJustOne,
		esconderBotaoExcluir,
		novo,
		initialValues,
		propsForm,
	} = props;

	const onClickNovo = dirty ? e => salvar(e, novo) : novo;

	async function salvar(e, novoOnSuccess) {
		props.handleSubmit();

		if (await validarFormulario(propsForm)) {
			if (await validarNomeDaConta()) {
				if (['CORRENTE', 'POUPANCA'].includes(values.tipo)) {
					if (await validarContaJaExiste()) {
						if (values.id) {
							UpdateRegistro(converterContaParaApi(values), novoOnSuccess);
						} else {
							CreateRegistro(converterContaParaApi(values), novoOnSuccess);
						}
					} else {
						props.setFieldError(
							'contaBancaria.banco',
							'Número, agência e cod. beneficiário utilizados em outra conta'
						);
					}
				} else {
					if (values.id) {
						UpdateRegistro(converterContaParaApi(values), novoOnSuccess);
					} else {
						CreateRegistro(converterContaParaApi(values), novoOnSuccess);
					}
				}
			} else {
				props.setFieldError('nome', 'O nome informado já existe');
			}
		}
	}

	async function UpdateRegistro(values, novoOnSuccess) {
		if (values.id && values.saldoInicial !== props.initialValues.saldoInicial) {
			await asyncAtualizarSaldoInicial(values.id, { saldoInicial: values.saldoInicial }, () => {
				asyncUpdateRegistro(values, () => {
					if (novoOnSuccess) {
						novoOnSuccess();
					} else {
						props.resetForm({ values: props.initialValues });
						props.onHide(values);
					}
				});
			});
		} else {
			await asyncUpdateRegistro(values, () => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					props.onHide(values);
				}
			});
		}
	}

	async function CreateRegistro(values, novoOnSuccess) {
		if (values) {
			await asyncCreateRegistro(values, ({ data: conta }) => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					props.onHide({
						...values,
						id: conta.id,
						convenios: conta.convenios ? conta.convenios : values.convenios ? values.convenios : [],
					});
					props.resetForm({ values: copiarObjeto(initialValues) });
				}
			});
		}
	}

	function cancelar() {
		if (dirty) {
			props.resetForm({ values: copiarObjeto(initialValues) });
		} else {
			props.onHide();
		}
	}

	function excluir() {
		confirm('Confirmação', 'Deseja excluir o registro?', async () => {
			await asyncDeleteRegistro(props.values.id, () => {
				props.onHide(props.values);
			});
		});
	}

	async function validarNomeDaConta() {
		let nomeJaExiste = false;
		const { values } = props;
		await asyncGetContaMesmoNome(values.nome, e => {
			if (e.data.content.length > 0 && e.data.content[0].id !== values.id) {
				nomeJaExiste = true;
			}
		});
		return !nomeJaExiste;
	}
	async function validarContaJaExiste() {
		let contaJaExiste = false;
		const { values } = props;
		await asyncGetContaJaExistente(
			values.contaBancaria?.numeroConta,
			values.contaBancaria?.agencia,
			values.contaBancaria?.codigoBeneficiario,
			e => {
				if (e.data.content.length > 0 && e.data.content[0].id !== values.id) {
					contaJaExiste = true;
				}
			}
		);
		return !contaJaExiste;
	}

	return (
		<>
			<ButtonCancelar
				{...informacoesPermissoes}
				hidden={isModal && values.id && !dirty}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={() => cancelar()}
			/>
			<ButtonSalvar
				{...informacoesPermissoes}
				estadoBotao={estadosBotaoSalvar.SALVAR}
				disabled={!dirty}
				onClick={() => salvar()}
			/>
			<ButtonNovo
				onClick={onClickNovo}
				hidden={(!dirty && !values.id) || insertJustOne}
				estadoBotao={dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir
				hidden={!values.id || isModal || esconderBotaoExcluir}
				{...informacoesPermissoes}
				onClick={() => excluir()}
			/>
		</>
	);
}

export default ActionButtons;
