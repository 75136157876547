import { isEstadoAdjacente } from 'Common';

export function isPercursoCompleta(ufsPercurso, ufOrigem, ufDestino) {
	const lastEstado = ufsPercurso?.length > 0 ? ufsPercurso.slice(-1)[0] : ufOrigem;
	return ufsPercurso?.length > 0
		? isEstadoAdjacente(ufsPercurso[0]?.sigla, ufOrigem) && isEstadoAdjacente(lastEstado.sigla, ufDestino)
		: false;
}

export function converterValueParaSelect(value, label) {
	return value
		? {
				value: value.id ?? null,
				label: label ?? value.nome ?? value.descricao,
				registro: value,
			}
		: null;
}
