import { gerarUUID } from 'Common';

function converterTabelaPrecoParaForm(data) {
	return {
		id: data.id,
		codigo: data.codigo,
		nome: data.nome,
		situacao: data.situacao,
		tabelaPromocional: data.tabelaPromocional,
		dataVencimento: data.dataVencimento,
		alteracoesPorCondicao: converterAlteracoesPorCondicaoForm(data.alteracoesPorCondicao),
		descontosPorQuantidade: converterDescontosPorQuantidadeForm(data.descontosPorQuantidade),
		configuracoes: converterConfiguracoesParaForm(data),
	};
}

function converterAlteracoesPorCondicaoForm(alteracoesPorCondicao) {
	if (alteracoesPorCondicao) {
		return alteracoesPorCondicao?.map((alteracao) => ({
			id: alteracao.id,
			idRegistro: alteracao.condicao?.id,
			condicao: converterAlteracoesPorCondicaoSelectCondicaoForm(alteracao.condicao),
			tipoAlteracaoCondicao: alteracao.tipoAlteracaoCondicao,
			condicaoItens: converterAlteracoesPorCondicaoCondicaoItensForm(alteracao.condicaoItens),
		}));
	}

	return null;
}

function converterAlteracoesPorCondicaoSelectCondicaoForm(condicao) {
	if (condicao) {
		if (condicao.value) {
			return condicao;
		} else {
			return {
				label: `${condicao.descricao}`,
				value: condicao.id,
				registro: condicao,
			};
		}
	}
	return [];
}

function converterAlteracoesPorCondicaoCondicaoItensForm(condicaoItens) {
	if (condicaoItens) {
		return condicaoItens?.map((item) => ({
			id: item.id,
			item: item.item,
			valorInicial: item.valorInicial ?? 0,
			valorFinal: item.valorFinal ?? 0,
			percentualAplicado: item.percentualAplicado ?? 0,
		}));
	}

	return [];
}

function converterConfiguracoesParaForm(data) {
	return {
		editarPrecoAbaixoValorTabela: data.editarPrecoAbaixoValorTabela,
		editarPrecoAcimaValorTabela: data.editarPrecoAcimaValorTabela,
	};
}

function converterDescontosPorQuantidadeForm(descontosPorQuantidade) {
	if (descontosPorQuantidade) {
		return descontosPorQuantidade?.map((item) => ({
			id: item.id,
			item: item.item,
			valorInicial: item.valorInicial ?? 0,
			valorFinal: item.valorFinal ?? 0,
			percentualAplicado: item.percentualAplicado ?? 0,
		}));
	}
	return [];
}

function converterTabelaPrecoParaApi(data) {
	return {
		id: data.id ?? null,
		nome: data.nome,
		situacao: data.situacao,
		tabelaPromocional: data.tabelaPromocional,
		dataVencimento: data.dataVencimento,
		alteracoesPorCondicao: converterAlteracoesPorCondicaoApi(data.alteracoesPorCondicao),
		descontosPorQuantidade: converterDescontosPorQuantidadeApi(data.descontosPorQuantidade),
		editarPrecoAbaixoValorTabela: data.configuracoes?.editarPrecoAbaixoValorTabela ?? false,
		editarPrecoAcimaValorTabela: data.configuracoes?.editarPrecoAcimaValorTabela ?? false,
	};
}

function converterAlteracoesPorCondicaoApi(alteracoesPorCondicao) {
	if (alteracoesPorCondicao) {
		return alteracoesPorCondicao?.map((alteracao) => ({
			id: alteracao.id ?? null,
			condicao: alteracao.condicao ? { id: alteracao.condicao?.value } : null,
			tipoAlteracaoCondicao: alteracao.tipoAlteracaoCondicao,
			condicaoItens: converterAlteracoesPorCondicaoCondicaoItensApi(alteracao.condicaoItens),
		}));
	}

	return null;
}

function converterAlteracoesPorCondicaoCondicaoItensApi(condicaoItens) {
	if (condicaoItens) {
		return condicaoItens?.map((item) => ({
			id: item.id ?? null,
			item: item.item,
			valorInicial: item.valorInicial ?? 0,
			valorFinal: item.valorFinal ?? 0,
			percentualAplicado: item.percentualAplicado ?? 0,
		}));
	}

	return null;
}

function converterDescontosPorQuantidadeApi(descontosPorQuantidade) {
	if (descontosPorQuantidade) {
		return descontosPorQuantidade?.map((item) => ({
			id: item.id ?? null,
			item: item.item,
			valorInicial: item.valorInicial ?? 0,
			valorFinal: item.valorFinal ?? 0,
			percentualAplicado: item.percentualAplicado ?? 0,
		}));
	}
	return null;
}

function converterTabelaPrecoParaDuplicar(data) {
	return {
		id: null,
		codigo: null,
		nome: data.nome,
		situacao: data.situacao,
		tabelaPromocional: data.tabelaPromocional,
		dataVencimento: data.dataVencimento,
		alteracoesPorCondicao: converterAlteracoesPorCondicaoParaDuplicar(data.alteracoesPorCondicao),
		descontosPorQuantidade: converterDescontosPorQuantidadeParaDuplicar(data.descontosPorQuantidade),
		editarPrecoAbaixoValorTabela: data.editarPrecoAbaixoValorTabela,
		editarPrecoAcimaValorTabela: data.editarPrecoAcimaValorTabela,
	};
}

function converterAlteracoesPorCondicaoParaDuplicar(alteracoesPorCondicao) {
	if (alteracoesPorCondicao) {
		return alteracoesPorCondicao?.map((alteracao) => ({
			id: null,
			idTemporario: gerarUUID(),
			idRegistro: alteracao.idRegistro,
			condicao: alteracao.condicao,
			tipoAlteracaoCondicao: alteracao.tipoAlteracaoCondicao,
			condicaoItens: converterAlteracoesPorCondicaoCondicaoItensParaDuplicar(alteracao.condicaoItens),
		}));
	}

	return [];
}

function converterAlteracoesPorCondicaoCondicaoItensParaDuplicar(condicaoItens) {
	if (condicaoItens) {
		return condicaoItens?.map((item) => ({
			id: null,
			idTemporario: gerarUUID(),
			valorInicial: item.valorInicial ?? 0,
			valorFinal: item.valorFinal ?? 0,
			percentualAplicado: item.percentualAplicado ?? 0,
		}));
	}

	return [];
}

function converterDescontosPorQuantidadeParaDuplicar(descontosPorQuantidade) {
	if (descontosPorQuantidade) {
		return descontosPorQuantidade?.map((item) => ({
			id: null,
			idTemporario: gerarUUID(),
			item: item.item,
			valorInicial: item.valorInicial ?? 0,
			valorFinal: item.valorFinal ?? 0,
			percentualAplicado: item.percentualAplicado ?? 0,
		}));
	}
	return [];
}

export { converterTabelaPrecoParaForm, converterTabelaPrecoParaApi, converterTabelaPrecoParaDuplicar };
