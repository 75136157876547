import { format } from 'date-fns';
import { parse } from 'date-fns/esm';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { Button, ButtonEditarTable, If, NenhumRegistroEncontrado } from 'components';

import { formatarMonetario } from 'Common';

import { confirmarExclusao } from '../../../../../../../Util/ExclusaoDeRegistros';
import { statusDocumentoPermiteExcluir } from '../../../../../../AssinaturaEletronica/Util/constantes';
import { renderStatus } from '../../../../../../AssinaturaEletronica/Util/funcoesList';
import { asyncDeleteAnexo } from '../../../../../Requests';
import { statusGestaoDocumento, tipoGestaoDocumento } from '../../../../../Util/constantes';
import { InfoStatusDocumento, helpMessage } from '../../../../Util/constantes';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '3px',
};

export default function ListagemAnexo(props) {
	const {
		anexos,
		informacoesPermissoes,
		setIdAnexo,
		handleModalAnexoVisibleChange,
		atualizarPagina,
		statusDoDocumento,
		gestaoDocumentoTipo,
	} = props;
	const possuiPermissaoParaExcluirAnexo = statusDoDocumento === statusGestaoDocumento.VIGENTE;

	function renderOpcoes(anexoSelecionado) {
		const permiteExcluir = statusDocumentoPermiteExcluir.includes(anexoSelecionado.status);

		const mensagemBotaoPodeExluir = permiteExcluir ? 'Excluir' : helpMessage.anexoNaoPodeExcluir;

		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<ButtonEditarTable onClick={() => onEditar(anexoSelecionado)} />
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={
						!informacoesPermissoes.podeExcluir
							? 'Excluir: Você não possui permissão para executar essa ação'
							: mensagemBotaoPodeExluir
					}
					disabled={!informacoesPermissoes.podeExcluir || !possuiPermissaoParaExcluirAnexo || !permiteExcluir}
					onClick={() =>
						confirmarExclusao(() =>
							asyncDeleteAnexo(anexoSelecionado.id, () => {
								atualizarPagina();
							})
						)
					}
				/>
			</div>
		);
	}
	function onEditar(anexoSelecionado) {
		handleModalAnexoVisibleChange();
		setIdAnexo(anexoSelecionado.id);
	}

	function formatarData(data) {
		if (data) {
			return format(parse(data, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
		}
		return null;
	}

	function renderStatusAnexo(row) {
		if (gestaoDocumentoTipo === tipoGestaoDocumento.CONTRATO) {
			return renderStatus(row);
		} else {
			return renderStatusDocumento(row);
		}
	}

	function renderStatusDocumento(row) {
		return (
			<span
				style={{
					backgroundColor: InfoStatusDocumento[row?.status]?.lightColor,
					color: InfoStatusDocumento[row?.status]?.strongColor,
					fontWeight: 'bold',
					fontSize: '13px',
					borderRadius: '20px',
					display: 'flex',
					height: '1.5rem',
					width: '11rem',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<span style={{ width: '100%', textAlign: 'center' }}>{InfoStatusDocumento[row?.status]?.name}</span>
			</span>
		);
	}

	return (
		<DataTable className="table" responsive value={anexos} emptyMessage={<NenhumRegistroEncontrado />}>
			<Column field="identificacao" header="Identificação" sortable={true} />
			<Column
				field="vigenciaInicio"
				header="Vigência início"
				sortable={true}
				body={row => formatarData(row?.vigenciaInicio)}
			/>
			<Column
				field="vigenciaFinal"
				header="Vigência final"
				sortable={true}
				body={row => formatarData(row?.vigenciaFinal)}
			/>
			<Column
				field="anexoValor"
				header="Valor anexo"
				sortable={true}
				body={row => formatarMonetario(row?.anexoValor)}
			/>
			<Column field="status" header="Situação" body={row => renderStatusAnexo(row)} sortable={true} />

			<Column field="acoes" header="Opções" body={e => renderOpcoes(e)} />
		</DataTable>
	);
}
