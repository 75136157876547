import { useEffect, useState } from 'react';
import { Button, notify } from 'components';
import { asyncBuscarQRCodePixImediato, asyncBuscarStatusPix } from 'components/ModalRecebimentoPix/Requests';

function QRCodePix({ lancamentoId, id, activeIndex, setActiveIndex, titulo, valor, setPixId }) {
  const [imageUrl, setImageUrl] = useState(null);
  const [dataQrCode, setDataQrCode] = useState(null);
  const [statusPix, setStatusPix] = useState(null);
  const [trigger, setTrigger] = useState(0);
  
  useEffect(() => {  
    buscarQRCode();
    setTrigger(trigger + 1);    
  }, []);

  useEffect(() => {
    checkStatus();
  }, [trigger]);

  async function checkStatus() {
    if (statusPix?.status === 'PENDENTE' || statusPix === null) {
      await verificarStatusPagamento();
      setTimeout(() => setTrigger(trigger + 1), 3000);
    } else if (statusPix?.status === 'RECEBIDO') {
      setActiveIndex(activeIndex + 1);
    }
  }

  async function buscarQRCode() {
    if (!id){
      await asyncBuscarQRCodePixImediato(lancamentoId, ({ data }) => {
        setDataQrCode(data);
        setImageUrl(data.qrCode);
        if (setPixId){
          setPixId(data.id)
        }
        
      });
    }else{
      await asyncBuscarStatusPix(
        id,
        ({ data }) => {
          setDataQrCode(data);
          setImageUrl(data.qrCode);
          if (setPixId){
            setPixId(data.id)
          }
        },        
      );
    }  
  }

  function copiarPix() {
    navigator.clipboard.writeText(dataQrCode.qrCodeUrl);
    notify('Pix copiado!');
  }

  async function verificarStatusPagamento() {
    if (dataQrCode) {
      await asyncBuscarStatusPix(
        dataQrCode?.id,
        ({ data }) => {
          setStatusPix(data);
        },        
      );
    }
  }

  return (
    dataQrCode && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <label style={{ fontSize: '1.2rem', paddingTop: '0.5rem' }}>{titulo}</label>	
        <img src={imageUrl} alt="QR Code PIX" style={{ height: '250px', width: '250px' }} />
        <label style={{ fontSize: '1.5rem', paddingBottom: '0.5rem' }}><b>{`Valor R$ ${valor?.toFixed(2).split(".").join(",")}`}</b></label>	
        <label style={{ fontSize: '1.2rem', paddingBottom: '1rem' }}>Aguardando pagamento...</label>
        <Button
          label="Copiar código do QR Code"
          icon="pi pi-copy"
          title="Copiar o link do Pix para a área de transferência"
          onClick={copiarPix}
        />
      </div>
    )
  );
}

export { QRCodePix };
