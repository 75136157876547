import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';

export const situacaoOptions = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];

export const optionsFiltroAvancadoModelo = [
	{ label: 'Nome', name: 'nome', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: situacaoOptions,
	},
];

export const Colors = {
	bgAtivo: '#DCEDC8',
	textAtivo: '#1B5E20',
	bgInativo: '#FFCDD2',
	textInativo: '#B71C1C',
};

export const styleBackground = {
	borderRadius: '20px',
	padding: '0.2rem 1.1rem',
};

export const styleButtonOptions = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};
