import { Tooltip } from 'primereact/tooltip';

import { formatarMonetarioDecimais, getSafeValue } from 'Common';
import Divider from 'components/Divider';

const styleDiv = { display: 'flex', justifyContent: 'space-between' };
const stylePrimeiraSpan = { marginLeft: '4px', marginRight: '16px' };
const styleSpanSomaSubtracao = {
	display: 'flex',
	justifyContent: 'space-between',
	marginLeft: '8px',
	maxWidth: '25px',
	minWidth: '25px',
};

function TooltipSubtotal({ indexProduto, produto, disabled }) {
	const subtotalProduto = produto.valor * produto.quantidade;
	const baseCalculo =
		subtotalProduto +
		getSafeValue(produto.frete) +
		getSafeValue(produto.seguro) +
		getSafeValue(produto.acessorias) -
		getSafeValue(produto.desconto);
	const tributosAplicados =
		getSafeValue(produto.tributos?.icms?.valorIcmsSt) + getSafeValue(produto.tributos?.ipi?.valorIpi);

	return (
		<Tooltip
			target={`.id-subtotal-produto-listagem-${indexProduto}`}
			position="bottom"
			showDelay={500}
			disabled={disabled || !produto.produto}
		>
			<div>
				<div style={styleDiv}>
					<span style={stylePrimeiraSpan}>{`Subtotal do produto: `}</span>
					<span>{formatarMonetarioDecimais(subtotalProduto, 2)}</span>
				</div>

				<div style={styleDiv}>
					<span style={{ display: 'flex' }}>
						<span style={styleSpanSomaSubtracao}>
							<span>(</span>
							<span>+</span>
							<span>)</span>
						</span>
						<span style={stylePrimeiraSpan}>{`Frete: `}</span>
					</span>
					<span>{formatarMonetarioDecimais(produto.frete, 2)}</span>
				</div>
				<div style={styleDiv}>
					<span style={{ display: 'flex' }}>
						<span style={styleSpanSomaSubtracao}>
							<span>(</span>
							<span>+</span>
							<span>)</span>
						</span>
						<span style={stylePrimeiraSpan}>{`Seguro: `}</span>
					</span>
					<span>{formatarMonetarioDecimais(produto.seguro, 2)}</span>
				</div>
				<div style={styleDiv}>
					<span style={{ display: 'flex' }}>
						<span style={styleSpanSomaSubtracao}>
							<span>(</span>
							<span>+</span>
							<span>)</span>
						</span>
						<span style={stylePrimeiraSpan}>{`Acessórias: `}</span>
					</span>
					<span>{formatarMonetarioDecimais(produto.acessorias, 2)}</span>
				</div>
				<div style={styleDiv}>
					<span style={{ display: 'flex' }}>
						<span style={styleSpanSomaSubtracao}>
							<span>(</span>
							<span>-</span>
							<span>)</span>
						</span>
						<span style={stylePrimeiraSpan}>{`Desconto: `}</span>
					</span>
					<span>{formatarMonetarioDecimais(produto.desconto, 2)}</span>
				</div>

				<div style={{ ...styleDiv, fontWeight: 'bold' }}>
					<span>{`Base de cálculo: `}</span>
					<span>{formatarMonetarioDecimais(baseCalculo, 2)}</span>
				</div>
				<Divider styleContainer={{ margin: '2px 0px' }} />

				<div style={{ ...styleDiv }}>
					<span>{`Tributos aplicados: `}</span>
					<span>{formatarMonetarioDecimais(tributosAplicados, 2)}</span>
				</div>
				<Divider styleContainer={{ margin: '2px 0px' }} />

				<div style={{ ...styleDiv, fontSize: '18px', fontWeight: 'bold' }}>
					<span>{`Valor Final: `}</span>
					<span>{formatarMonetarioDecimais(produto.subtotal, 2)}</span>
				</div>
			</div>
		</Tooltip>
	);
}

export { TooltipSubtotal };
