import { useEffect, useState } from 'react';
import { Col } from '../../../../../../components';
import { ColorsCard, Status } from '../../Util/constantes';
import CardTotalizadorListagem from './components/CardTotalizadorListagem';

export default function CardsOrcamentoVendas(props) {
	const { isMobile, setValorCard, pesquisarCallback, cards, selectedCard, setSelectedCard } = props;

	useEffect(() => {
		pesquisarCallback();
	}, [selectedCard]);

	function handleSelectCardPendente() {
		const card = Status.PENDENTE;
		const filtroRSQL = 'status==PENDENTE';
		pesquisarRSQLCardTotais(card, filtroRSQL);
	}

	function handleSelectCardFinalizado() {
		const card = Status.FINALIZADO;
		const filtroRSQL = 'status==FINALIZADO';
		pesquisarRSQLCardTotais(card, filtroRSQL);
	}

	function handleSelectCardCancelada() {
		const card = Status.CANCELADA;
		const filtroRSQL = 'status==CANCELADA';
		pesquisarRSQLCardTotais(card, filtroRSQL);
	}

	function pesquisarRSQLCardTotais(cardSelected, valueCard) {
		if (selectedCard === cardSelected) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(cardSelected);
			setValorCard(valueCard);
		}
	}

	return (
		<>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardTotalizadorListagem
					name="cardEmOrcamento"
					title="Pendentes"
					helpMessage={isMobile ? '' : 'Exibe o valor total e o número de orçamentos em aberto no período selecionado'}
					titleFiltro="Clique para filtrar pelos orçamentos abertos"
					colors={ColorsCard.ORCAMENTO}
					value={cards.pendente.valor ?? 0}
					selectable
					numberOfElements={cards.pendente.quantidade ?? 0}
					selected={selectedCard === Status.PENDENTE}
					onSelect={handleSelectCardPendente}
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardTotalizadorListagem
					name="cardVendas"
					title={
						<span>
							Finalizados <span style={{ fontSize: '10px' }}>(considera devoluções)</span>
						</span>
					}
					helpMessage={isMobile ? '' : 'Exibe o valor total e o número de vendas no período informado'}
					titleFiltro="Clique para filtrar pelas vendas"
					colors={ColorsCard.PEDIDO_VENDA}
					value={cards.finalizado.valor ?? 0}
					selectable
					numberOfElements={cards.finalizado.quantidade ?? 0}
					selected={selectedCard === Status.FINALIZADO}
					onSelect={handleSelectCardFinalizado}
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardTotalizadorListagem
					name="cardCanceladas"
					title="Cancelados"
					helpMessage={isMobile ? '' : 'Exibe o valor total e o número de orçamentos e vendas canceladas no período'}
					titleFiltro="Clique para filtrar pelos orçamentos e vendas canceladas"
					colors={ColorsCard.CANCELADA}
					value={cards.cancelada.valor ?? 0}
					selectable
					numberOfElements={cards.cancelada.quantidade ?? 0}
					selected={selectedCard === Status.CANCELADA}
					onSelect={handleSelectCardCancelada}
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardTotalizadorListagem
					name="cardTotalizador"
					title="Previsão"
					helpMessage={isMobile ? '' : 'Exibe o valor resultante da soma dos orçamentos e vendas do período'}
					colors={ColorsCard.TOTAL}
					value={cards.pendente.valor + cards.finalizado.valor ?? 0}
					numberOfElements={cards.pendente.quantidade + cards.finalizado.quantidade ?? 0}
					selectable
					onSelect={() => {}}
				/>
			</Col>
		</>
	);
}
