import { mensagensDeValidacao, services } from 'Common';
import {
	AutoProgressBar,
	Form,
	FormActions,
	FormContent,
	Grid,
	Modal,
	ModalLoadingTransmissao,
	MultipleSelectSetores,
} from 'components';
import ButtonImprimir from 'components/Button/ButtonImprimir';
import { Field, useFormikContext, withFormik } from 'formik';
import { useState } from 'react';
import { asyncBaixarRelatorioMdfeNaoEncerradas } from 'views/transporte/MDFe/Requests';

const initialValue = {
	setores: [],
};

function ModalMdfeNaoEncerradaView({ visible, onHide }) {
	const { values, setFieldValue, handleSubmit, validateForm, isValid } = useFormikContext();

	const [visibleLoading, setVisibleLoading] = useState(false);

	async function imprimir() {
		await handleSubmit();
		await validateForm();

		if (isValid) {
			try {
				let setores = '';
				setVisibleLoading(true);

				if (values.setores) {
					setores = values.setores.map((item) => `'${item.value}'`).join(',');
				}

				await asyncBaixarRelatorioMdfeNaoEncerradas(setores, ({ data: pdf }) => {
					const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
					const arquivoURL = URL.createObjectURL(arquivoPDF);
					const relatorio = window.open(arquivoURL);
					if (relatorio) {
						relatorio.onload = () => {
							setTimeout(() => {
								relatorio.document.title = 'Relatório MDF-es não encerradas';
							}, 250);
						};
					}
					setVisibleLoading(false);
					onHide();
				});
			} catch (e) {
				console.error(e);
				setVisibleLoading(false);
			}
		}
	}

	return (
		<>
			<Modal header="Relatório de MDF-e não encerrada em todo o período" onHide={onHide} visible={visible}>
				<AutoProgressBar />
				<Form>
					<FormActions>
						<ButtonImprimir onClick={imprimir} />
					</FormActions>
					<FormContent>
						<Grid>
							<Field
								sm="12"
								md="6"
								lg="6"
								xl="6"
								component={MultipleSelectSetores}
								label="Setor(es)"
								name="setores"
								obrigatorio
								url={`${services.GESTOR}/v1/mdfes/relacoes/setores`}
								onChange={(e) => setFieldValue('setores', e)}
								value={values.setores}
								showClear={false}
								utilizaFavorito
							/>
						</Grid>
					</FormContent>
				</Form>
			</Modal>
			<ModalLoadingTransmissao visible={visibleLoading} message="Gerando relatório..." />
		</>
	);
}

const ModalMdfeNaoEncerrada = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: true,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		const errors = {};

		if (values.setores.length === 0) {
			errors.setores = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalMdfeNaoEncerradaView);

export { ModalMdfeNaoEncerrada };
