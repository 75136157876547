import { formatarNcm } from '../../../../Common/Mascara';

export function converterNcmParaFormulario(ncm) {
	return {
		id: ncm.id,
		codigoNcm: converteCodigoNcmParaFormulario(ncm),
		extensao: ncm.extensao,
		descricao: ncm.descricao,
		situacao: ncm.situacao,
		tributacoes: converteTributacoesParaFormulario(ncm.tributacoes),
	};
}

function converteTributacoesParaFormulario(tributacoes) {
	let tributacoesConvetidas = [];
	tributacoes.forEach(tributacao => {
		tributacoesConvetidas.push({
			operacaoFiscal: converteOperacaoFiscalParaFormulario(tributacao),
			situacaoOperacao: tributacao.situacaoOperacao,
			tributacaoEstadual: converteTributacaoEstadualParaFormulario(tributacao),
			situacaoTributacao: tributacao.situacaoTributacao,
		});
	});
	return tributacoesConvetidas;
}

function converteOperacaoFiscalParaFormulario(tributacao) {
	return {
		label: tributacao.codigoOperacao + ' - ' + tributacao.descricaoOperacao,
		value: tributacao.idOperacao,
	};
}

function converteTributacaoEstadualParaFormulario(tributacao) {
	return {
		label: tributacao.codigoTributacao + ' - ' + tributacao.descricaoTributacao,
		value: tributacao.idTributacao,
	};
}

function converteCodigoNcmParaFormulario(codigoNcm) {
	if (codigoNcm) {
		return {
			label: codigoNcm.extensao
				? formatarNcm(codigoNcm.codigoNcm.codigo) + '-' + codigoNcm.extensao
				: formatarNcm(codigoNcm.codigoNcm.codigo),
			value: codigoNcm.codigoNcm.id,
		};
	} else {
		return null;
	}
}

export function converterNcmParaApi(ncm) {
	return {
		id: ncm.id,
		codigoNcm: {
			id: ncm.codigoNcm ? ncm.codigoNcm.value : null,
		},
		extensao: ncm.extensao ? ncm.extensao : null,
		descricao: ncm.descricao,
		situacao: ncm.situacao,
		tributacoes: !!ncm.tributacoes?.length ? converteTributacoesParaApi(ncm.tributacoes) : [],
	};
}

function converteTributacoesParaApi(tributacoes) {
	let tributacoesConvetidas = [];
	tributacoes.forEach(tributacao => {
		tributacoesConvetidas.push({
			idOperacao: tributacao.operacaoFiscal.value,
			idTributacao: tributacao.tributacaoEstadual.value,
		});
	});
	return tributacoesConvetidas;
}

export function converterNcmApiParaApi(ncm) {
	return {
		codigoNcm: {
			id: ncm.codigoNcm.id,
		},
		extensao: ncm.extensao ? ncm.extensao : null,
		descricao: ncm.descricao,
		situacao: ncm.situacao,
		tributacoes: !!ncm.tributacoes?.length ? converteTributacoesApiParaApi(ncm.tributacoes) : [],
	};
}

function converteTributacoesApiParaApi(tributacoes) {
	let tributacoesConvetidas = [];
	tributacoes.forEach(tributacao => {
		tributacoesConvetidas.push({
			idOperacao: tributacao.idOperacao,
			idTributacao: tributacao.idTributacao,
		});
	});
	return tributacoesConvetidas;
}

export function converterNovaTributacaoParaFormulario(tributacoes) {
	let tributacoesConvetidas = [];
	tributacoes.forEach(tributacao => {
		tributacoesConvetidas.push({
			situacaoOperacao: tributacao.situacaoOperacao,
			situacaoTributacao: tributacao.situacaoTributacao,
			operacaoFiscal: {
				label: `${tributacao.codigoOperacao} - ${tributacao.descricaoOperacao}`,
				value: tributacao.idOperacao,
			},
			tributacaoEstadual: {
				label: `${tributacao.codigoTributacao} - ${tributacao.descricaoTributacao}`,
				value: tributacao.idTributacao,
			},
		});
	});
	return tributacoesConvetidas;
}
