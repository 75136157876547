function converterParaApi(data, isRecebimento) {
	if (data?.titulos?.length > 0) {
		const titulos = data.titulos.map((titulo) => {
			return {
				id: titulo?.id,
				juros: titulo?.quitacao?.juros,
				multa: titulo?.quitacao?.multa,
				desconto: titulo?.quitacao?.desconto,
				aplicarTaxa: titulo?.quitacao?.aplicarTaxa,
				conta: { id: titulo?.quitacao?.conta?.value },
				formaPagamento: { id: titulo?.quitacao?.formaPagamento?.value },
			};
		});

		return isRecebimento ? { recebimentos: titulos } : { pagamentos: titulos };
	} else {
		return null;
	}
}

export { converterParaApi };
