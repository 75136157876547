import { useState, useEffect } from 'react';
import Steps from 'rc-steps';
import propTypes from 'prop-types';

import { renderizarEtapas, Icon } from './Util';

export default function ReactSteps(props) {
	const { etapas, current, style, status, className } = props;
	const [larguraTela, setLarguraTela] = useState(null);

	function alterarLarguraTela() {
		setLarguraTela(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener('resize', alterarLarguraTela);
		alterarLarguraTela();
		return () => {
			window.removeEventListener('resize', alterarLarguraTela);
		};
	}, []);

	return (
		<Steps
			className={className}
			direction={larguraTela < 650 ? 'vertical' : 'horizontal'}
			status={status}
			style={style}
			current={Number(current)}
			labelPlacement="vertical"
			icons={{ finish: <Icon name="check" />, error: <Icon name="close" /> }}
		>
			{renderizarEtapas(etapas)}
		</Steps>
	);
}

ReactSteps.defaultProps = {
	status: 'process',
	direction: 'horizontal',
};

ReactSteps.propTypes = {
	status: propTypes.oneOf(['error', 'process', 'finish', 'wait']),
};
