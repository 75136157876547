import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  ButtonNovo,
  Grid,
  ButtonEditarTable,
  ButtonExcluirTable,
  InputSearch,
  Tutorial,
  tutorialStepsListagens,
  Form,
  FormActions,
  FormContent,
  NenhumRegistroEncontrado,
  Paginacao,
} from '../../../components';

import {
  services,
  recursos,
  permissoes,
  construirUrl,
  removerElemento,
  usuarioPossuiPermissao,
  salvarConfiguracaoUsuario,
  configuracoesUsuario,
  buscarConfiguracaoUsuario,
} from '../../../Common';

import { asyncDeleteSetor, asyncGetPesquisaSetor } from './Requests';
import { atualizarUrl } from '../../Util';
import { confirmarExclusao } from '../../Util/ExclusaoDeRegistros';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';

function Setores(props) {
  const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.SETORES, permissoes.INSERIR));
  const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.SETORES, permissoes.EXCLUIR));
  const [deveExibirTutorial, setDeveExibirTutorial] = useState(
    buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
  );

  const {
    valorPesquisa,
    setValorPesquisa,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    rows,
    setRows,
  } = useContextPesquisa();

  const [registros, setRegistros] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [tutorialVisible, setTutorialVisible] = useState(false);

  const pesquisarCallback = useCallback(() => {
    pesquisar();
  });

  useEffect(() => {
    if (deveExibirTutorial !== false) {
      setTutorialVisible(true);
      salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
    }

    setTimeout(() => {
      document.getElementById('setoresInputSearch')?.focus();
    }, 500);
  }, []);

  useEffect(() => {
    pesquisarCallback();
  }, [page, rows, sortField]);

  function pesquisar() {
    const filtro = `?query=nome=contains="*${valorPesquisa}*"`;
    const url = construirUrl(
      `${services.GESTOR}/v1/setores`,
      filtro,
      rows,
      page,
      sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
    );
    asyncGetPesquisaSetor(url, e => {
      setRegistros(e.data.content);
      setTotalElements(e.data.totalElements);
    });
  }

  function onPesquisar() {
    pesquisar();
  }

  function onPageChange(e) {
    setPage(e.page);
    setRows(e.rows);
  }

  function onSort(e) {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  }

  function onEditar(row) {
    atualizarUrl(props.history, '/setores/cadastro', row.id);
  }

  function onExcluir(row) {
    confirmarExclusao(() => asyncExcluirRegistro(row));
  }

  async function asyncExcluirRegistro(registro) {
    await asyncDeleteSetor(registro.id, () => {
      setRegistros(removerElemento(registros, registro));
      setTotalElements(totalElements - 1);
    });
  }

  function renderOpcoes(row) {
    return (
      <div style={{ display: 'flex' }}>
        <ButtonEditarTable onClick={() => onEditar(row)} />
        <ButtonExcluirTable onClick={() => onExcluir(row)} podeExcluir={podeExcluir} />
      </div>
    );
  }

  function renderNome(row) {
    return (
      <span
        style={{
          display: 'flex',
          wordBreak: 'break-word',
          maxWidth: props.isMobile ? '70%' : '100%',
          textAlign: props.isMobile ? 'end' : 'start',
        }}
      >
        {row.nome}
      </span>
    );
  }

  return (
    <>
      <Tutorial
        steps={tutorialStepsListagens}
        showSkipButton
        continuous
        disableScrolling
        visible={tutorialVisible}
        onHide={() => setTutorialVisible(false)}
      />

      <Form header="Setores">
        <FormActions>
          <ButtonNovo
            label="Novo setor"
            className="step-listagem-novo"
            onClick={() => {
              props.history.push('/setores/cadastro');
            }}
            podeInserir={podeInserir}
          />
        </FormActions>
        <FormContent>
          <Grid justifyCenter>
            <InputSearch
              id="setoresInputSearch"
              className="step-listagem-input-search"
              onPesquisar={() => onPesquisar()}
              value={valorPesquisa}
              onChange={value => setValorPesquisa(value)}
            />
          </Grid>
          <DataTable
            className="table"
            responsive
            value={registros}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            emptyMessage={<NenhumRegistroEncontrado />}
          >
            <Column className="step-listagem-order" body={row => renderNome(row)} header="Nome" sortable={true} />
            <Column
              className="step-listagem-acoes"
              body={row => renderOpcoes(row)}
              header="Ações"
              style={{ width: '7em' }}
            />
          </DataTable>
          <Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={e => onPageChange(e)} />
        </FormContent>
      </Form>
    </>
  );
}

function mapStateToProps(state) {
  return {
    isMobile: state.dispositivo.isMobile,
  };
}

export default connect(mapStateToProps)(Setores);
