export function converterFilialToForm(values) {
	const municipioNome = values?.endereco?.municipio?.nome;
	const estadoSigla = values?.endereco?.municipio?.estadoSigla ?? values?.endereco?.municipio?.estado?.sigla;

	return {
		codigo: values.codigo,
		nome: values.nome,
		nomeApresentacao: values.nomeApresentacao ?? values.nome,
		razaoSocial: values.razaoSocial ?? values.nome.toUpperCase(),
		situacao: converterSituacao(values.situacao),
		tipo: converterTipo(values.tipo),
		cpf: values?.cpf,
		cnpj: values?.cnpj,
		email: values?.email,
		telefone: values?.telefone,
		inscricaoEstadual: values?.inscricaoEstadual,
		ieSubstitutoTributario: values?.inscricaoEstadualSubstitutoTributario,
		inscricaoMunicipal: values?.inscricaoMunicipal,
		cnaeFiscal: values?.cnae,
		regimeTributario: converterRegimeTributario(values.regimeTributario),
		suframa: values?.suframa,
		contabilidade: values.contabilidade?.value
			? values.contabilidade
			: converterSelectContabilidadeParaFormulario(values.contabilidade),
		cep: values?.endereco?.cep,
		logradouro: values?.endereco?.logradouro,
		numero: values?.endereco?.numero,
		bairro: values?.endereco?.bairro,
		complemento: values?.endereco?.complemento,
		pais: {
			value: values?.endereco?.pais?.id,
			label: values?.endereco?.pais?.nome,
			registro: values?.endereco?.pais,
		},
		municipio: {
			value: values?.endereco?.municipio?.id,
			label: municipioNome ? `${municipioNome} - ${estadoSigla}` : values?.endereco?.municipio?.nome,
			registro: values?.endereco?.municipio,
		},
	};
}

export function converterFilialToApi(values) {
	return {
		id: values.id ?? null,
		codigo: values.codigo,
		nome: values.nome,
		nomeApresentacao: values.nomeApresentacao,
		razaoSocial: values.razaoSocial,
		situacao: values.situacao?.value,
		tipo: values.tipo?.value,
		cpf: values.cpf ?? null,
		cnpj: values.cnpj ?? null,
		email: values.email,
		telefone: values.telefone,
		inscricaoEstadual: values.inscricaoEstadual,
		inscricaoEstadualSubstitutoTributario: values.ieSubstitutoTributario,
		inscricaoMunicipal: values.inscricaoMunicipal,
		cnae: values.cnaeFiscal,
		regimeTributario: values.regimeTributario.value,
		suframa: values.suframa,
		contabilidade: values.contabilidade ?? null,
		endereco: {
			cep: values.cep,
			logradouro: values.logradouro,
			numero: values.numero,
			bairro: values.bairro,
			complemento: values.complemento,
			pais: {
				codigo: values.pais.codigo ?? values.pais.registro.codigo,
				ddi: values.pais.ddi ?? values.pais.registro.ddi,
				id: values.pais.id ?? values.pais.registro.id,
				nome: values.pais.nome ?? values.pais.registro.nome,
				sigla: values.pais.sigla ?? values.pais.registro.sigla,
			},
			municipio: {
				codigo: values.municipio.codigo ?? values.municipio.registro.codigo,
				estado: values.municipio.estado ?? values.municipio.registro.estado,
				id: values.municipio.id ?? values.municipio.registro.id,
				nome: values.municipio.nome ?? values.municipio.registro.nome,
			},
		},
	};
}

function converterTipo(tipo) {
	let valorFormatado;

	switch (tipo) {
		case 'JURIDICA':
			valorFormatado = { value: 'JURIDICA', label: 'Jurídica' };
			break;
		case 'FISICA':
			valorFormatado = { value: 'FISICA', label: 'Física' };
			break;
		default:
			valorFormatado = { value: 'JURIDICA', label: 'Jurídica' };
			break;
	}
	return valorFormatado;
}

function converterSituacao(situacao) {
	let valorFormatado;

	switch (situacao) {
		case 'ATIVA':
			valorFormatado = { value: 'ATIVA', label: 'Ativa' };
			break;
		case 'INATIVA':
			valorFormatado = { value: 'INATIVA', label: 'Inativa' };
			break;
		default:
			valorFormatado = { value: 'ATIVA', label: 'Ativa' };
			break;
	}

	return valorFormatado;
}

function converterRegimeTributario(regime) {
	let valorFormatado;

	switch (regime) {
		case 'SIMPLES_NACIONAL':
			valorFormatado = { value: 'SIMPLES_NACIONAL', label: 'Simples nacional' };
			break;
		case 'SIMPLES_NACIONAL_EXCESSO':
			valorFormatado = {
				value: 'SIMPLES_NACIONAL_EXCESSO',
				label: 'Simples nacional, excesso sublimite de receita bruta',
			};
			break;
		case 'NORMAL':
			valorFormatado = { value: 'NORMAL', label: 'Regime normal' };
			break;
		case 'MEI':
			valorFormatado = { value: 'MEI', label: 'Microempreendedor individual (MEI)' };
			break;
		default:
			valorFormatado = { value: 'SIMPLES_NACIONAL', label: 'Simples nacional' };
			break;
	}

	return valorFormatado;
}

function converterSelectContabilidadeParaFormulario(selectApi) {
	if (selectApi) {
		return {
			label: selectApi.nome,
			value: selectApi.id,
			registro: selectApi,
		};
	}
	return null;
}
