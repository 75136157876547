import { Message } from 'components';
import { withRouter } from 'react-router-dom';

const textStyle = {
	margin: '5px 0px',
};

function MessageOrganizacaoBloqueadaImpl() {
	return (
		<Message
			severity="info"
			colStyle={{ margin: '1em 0em' }}
			text={
				<div>
					<h2>Organização bloqueada</h2>
					<p style={textStyle}>
						Sua organização foi bloqueada. Você não pode mais incluir novas informações, porém ainda poderá visualizar,
						editar e excluir as informações existentes
					</p>
					<p style={textStyle}>Para obter mais informações entre em contato conosco</p>
					<span
						onClick={() => window.open('https://msyssolutions.inf.br/contato/', '_blank')}
						style={{ cursor: 'pointer', fontWeight: 'bold' }}
					>
						Entrar em contato
					</span>
				</div>
			}
		/>
	);
}

export const MessageOrganizacaoBloqueada = withRouter(MessageOrganizacaoBloqueadaImpl);
