export const DATA_ACTION_TYPES = {
  SET_DATA: "SET_DATA",
  ADD_SERVICOS_PRESTADOS: "ADD_SERVICOS_PRESTADOS",
  ADD_PRODUTOS_VENDIDOS: "ADD_PRODUTOS_VENDIDOS",
  ADD_RANKING_TOMADORES: "ADD_RANKING_TOMADORES",
  ADD_RANKING_VENDEDORES: "ADD_RANKING_VENDEDORES",
};

export function setData(data) {
  return {
    type: DATA_ACTION_TYPES.SET_DATA,
    payload: {
      ...data,
    },
  };
}

export function addDataServicosPrestados(data) {
  return {
    type: DATA_ACTION_TYPES.ADD_SERVICOS_PRESTADOS,
    payload: {
      ...data,
    },
  };
}

export function addDataProdutosVendidos(data) {
  return {
    type: DATA_ACTION_TYPES.ADD_PRODUTOS_VENDIDOS,
    payload: {
      ...data,
    },
  };
}

export function addDataRankingTomadores(data) {
  return {
    type: DATA_ACTION_TYPES.ADD_RANKING_TOMADORES,
    payload: {
      ...data,
    },
  };
}

export function addDataRankingVendedores(data) {
  return {
    type: DATA_ACTION_TYPES.ADD_RANKING_VENDEDORES,
    payload: {
      ...data,
    },
  };
}
