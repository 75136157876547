import * as Yup from 'yup';
import { estadosCadastro, mensagensDeValidacao, validaFieldsPorModulo } from '../../../../../Common';

export const fieldsValidationSchema = {
	DEFAULT: {
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	},
	DOCUMENTOS: {},
	ESTOQUE: {},
	FINANCAS: {},
	ADMINISTRACAO: {},
	VENDAS: {
		unidadeMedida: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	},
};

export function fieldsValidate(values) {
	const indexFilialConectada = (values.filiais.map(e => e.filialConectada).indexOf(true) > -1) ? values.filiais.map(e => e.filialConectada).indexOf(true) : 0;
	let fieldsErrors = {
		DEFAULT: {
			ultimoCusto:
				values.controlarEstoque && !values.id && values.estoqueSaldo > 0 && values.ultimoCusto <= 0
					? 'Informe o custo válido'
					: null,
			estoqueMinimo: values.estoqueMinimo && values.estoqueMinimo < 0 ? 'Informe um valor válido' : null,
			codigoServico: !values.codigoServico && values.tipo === 'SERVICO' ? mensagensDeValidacao.OBRIGATORIO : null,
		},
		DOCUMENTOS: {},
		ESTOQUE: {},
		FINANCAS: {},
		ADMINISTRACAO: {},
		VENDAS: {
			preco: values.filiais[indexFilialConectada].precoVenda <= 0 ? mensagensDeValidacao.OBRIGATORIO : null,
			ncm: !values.ncm && values.tipo != 'SERVICO' ? mensagensDeValidacao.OBRIGATORIO : null,
			unidadeMedida: !values.unidadeMedida ? mensagensDeValidacao.OBRIGATORIO : null,
		},
	};

	let errors = [];

	if (estadosCadastro.INCLUSAO && !values.estoqueSaldo > 0) values.ultimoCusto = 0;

	if (values.possuiModulos.VENDAS) {
		if (!values.ncm && values.tipo !== 'SERVICO') errors.push('O campo NCM da aba fiscal é obrigatório.');
	}

	if (!values.codigoServico && values.tipo === 'SERVICO') {
		errors.push('O campo Código Serviço da aba fiscal é obrigatório.');
	}

	values.errors = errors;

	return validaFieldsPorModulo(fieldsErrors);
}
