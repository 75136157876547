export const tutorialStepsOrdemDeServico = [
	{
		target: 'body',
		content: `Este é o cadastro de ordem de serviço`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-ordem-servico-cliente',
		content:
			'Nesta seção, é possível informar os campos do cabeçalho da ordem de serviço, tais como cliente, data de abertura e fechamento, objeto do serviço e problema relatado pelo cliente',
		placement: 'top',
	},
	{
		target: '.step-ordem-servico-produtos-servicos',
		content: 'E nesta área você pode gerenciar os serviços e produtos desta ordem de serviço',
	},
	{
		target: '.step-ordem-servico-totalizadores',
		content:
			'Por fim, nesta aba estão os totalizadores, além disso, nas demais abas, podem ser alterados as informações de pagamentos e informações complementares.',
	},
];
