export const INITIAL_VALUES = {
	id: '',
	nomeCompleto: '',
	num: '',
	nomeImpresso: '',
	expiracao: '',
	bandeira: 'unknown',
	codigoSeguranca: '',
	cartaoValido: false,
};
