import { TIPO_PAPEL } from "./constantes";

function converterProdutosToList(produtos) {
  if (produtos?.length > 0) {
    return produtos.map((produto) => converterProdutoToList(produto));
  } else {
    return [];
  }
}

function converterProdutoToList(produto) {
  return {
    id: produto.id,
    codigo: produto.codigo,
    codigoBarras: produto.codigoBarras,
    referencia: produto.referencia,
    nome: produto.nome,
    unidadeMedida: produto.unidadeMedida,
    unidadeMedidaEntrada: produto.unidadeMedidaEntrada,
    observacao: produto.observacao,
    precoVenda: produto.preco,
    precoAtacado: produto.precoAtacado,
    pesoLiquido: produto.pesoLiquido,
    pesoBruto: produto.pesoBruto,
    saldo: produto.saldo,
    quantidade:
      Number(produto.quantidade) > 0 ? Math.ceil(produto.quantidade) : 1,
    notas: [],
  };
}

function converterListProdutosToPrint(produtos, etiqueta, values) {
  return {
    etiquetas: converterProdutosToPrint(produtos, etiqueta),
    configuracaoEtiqueta: {
      pagina: converterPaginaToPrint(etiqueta, values),
      camposEtiqueta: conveterCamposToPrint(etiqueta.campos),
    },
  };
}

function converterProdutosToPrint(produtos, etiqueta) {
  const fieldsTextoLivre = converterFieldsTextoLivre(etiqueta);

  const convertedProdutos = [];
  if (!!produtos.length) {
    produtos.forEach((produto) => {
      convertedProdutos.push({
        qtdDeEtiquetas: Number(produto.quantidade) ?? 1,
        produtoCodigo: produto.codigo,
        produtoCodigoBarras: produto.codigoBarras ?? "",
        produtoReferencia: produto.referencia ?? "",
        produtoNome: produto.nome ?? "",
        produtoUnidade: produto.unidadeMedida
          ? produto.unidadeMedida.unidade
          : null,
        produtoUnidadeEntrada: produto.unidadeMedidaEntrada
          ? produto.unidadeMedidaEntrada.unidade
          : null,
        produtoObservacao: produto.observacao ?? "",
        produtoPrecoVenda: produto.precoVenda ?? 0,
        produtoPrecoAtacado: produto.precoAtacado ?? 0,
        produtoPesoLiquido: produto.pesoLiquido ?? 0,
        produtoPesoBruto: produto.pesoBruto ?? 0,
        produtoQuantidade: produto.saldo ?? 0,
        textoLivre01: fieldsTextoLivre?.textoLivre01 ?? "",
        textoLivre02: fieldsTextoLivre?.textoLivre02 ?? "",
        textoLivre03: fieldsTextoLivre?.textoLivre03 ?? "",
        textoLivre04: fieldsTextoLivre?.textoLivre04 ?? "",
        textoLivre05: fieldsTextoLivre?.textoLivre05 ?? "",
        textoLivre06: fieldsTextoLivre?.textoLivre06 ?? "",
        textoLivre07: fieldsTextoLivre?.textoLivre07 ?? "",
        textoLivre08: fieldsTextoLivre?.textoLivre08 ?? "",
        textoLivre09: fieldsTextoLivre?.textoLivre09 ?? "",
        textoLivre10: fieldsTextoLivre?.textoLivre10 ?? "",
        textoLivre11: fieldsTextoLivre?.textoLivre11 ?? "",
        textoLivre12: fieldsTextoLivre?.textoLivre12 ?? "",
        textoLivre13: fieldsTextoLivre?.textoLivre13 ?? "",
        textoLivre14: fieldsTextoLivre?.textoLivre14 ?? "",
        textoLivre15: fieldsTextoLivre?.textoLivre15 ?? "",
      });
    });
  }
  return convertedProdutos;
}

function converterFieldsTextoLivre(etiqueta) {
  const fieldsTextoLivre = {};
  const hasFieldTextoLivre = etiqueta.campos.some((e) =>
    e.campo.nome.includes("textoLivre")
  );

  if (hasFieldTextoLivre) {
    etiqueta.campos.forEach((e) => {
      if (e.campo.nome.includes("textoLivre")) {
        fieldsTextoLivre[e.campo.nome] = e.textoLivre;
      }
    });
  }

  return fieldsTextoLivre;
}

function converterPaginaToPrint(etiqueta, values) {
  return {
    alturaPagina:
      etiqueta.tipoPapel === TIPO_PAPEL.BOBINA
        ? etiqueta.alturaEtiqueta +
          (etiqueta.margemSuperior + etiqueta.margemInferior)
        : etiqueta.alturaPagina,
    larguraPagina: etiqueta.larguraPagina,
    etiquetasPorLinha: etiqueta.etiquetasPorLinha,
    linhasPorPagina: etiqueta.linhasPorPagina,
    margemSuperior: etiqueta.margemSuperior,
    margemEsquerda: etiqueta.margemEsquerda,
    margemDireita: etiqueta.margemDireita,
    margemInferior: etiqueta.margemInferior,
    alturaEtiqueta: etiqueta.alturaEtiqueta,
    larguraEtiqueta: etiqueta.larguraEtiqueta,
    espacamentoLinha: etiqueta.espacamentoLinha,
    espacamentoColuna: etiqueta.espacamentoColuna,
    exibirContornoEtiqueta: values.exibirContorno.value ?? false,
    exibirContornoCampos: etiqueta.exibirContornoCampos,
    iniciarApartirDaEtiqueta: values.etiquetaInicial,
  };
}

function conveterCamposToPrint(campos) {
  const convertedFields = [];

  if (!!campos.length) {
    campos.forEach((field) => {
      convertedFields.push({
        nomeCampo: field.campo?.nome,
        posicaoTopo: field.posicaoTopo,
        posicaoEsquerda: field.posicaoEsquerda,
        altura: field.altura,
        largura: field.largura,
        fonte: field.fonte,
        tamanhoFonte: field.tamanhoFonte,
        corFonte: field.corFonte,
        rotacao: field.rotacao,
        negrito: field.negrito,
        italico: field.italico,
        sublinhado: field.sublinhado,
        quebraLinha: field.quebraLinha,
        alinhamento: field.alinhamento,
        tamanhoAutomatico: field.tamanhoAutomatico,
        zoom: field.zoom,
        mostrarCodigoNaBarra: field.mostrarCodigoNaBarra,
        tipoBarras: field.tipoBarras,
        corFundo: field.corFundo ?? null,
      });
    });
  }

  return convertedFields;
}

export { converterProdutosToList, converterListProdutosToPrint };
