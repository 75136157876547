import { get } from '../../../../../../../Common/Requisicoes';
import { services } from '../../../../../../../Common/Constantes/api';

export async function asyncGetProdutos(onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/produtos/resumo`, null, onSuccess, onError, true);
}

export async function asyncGetProduto(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/produtos/${id}`, null, onSuccess, onError, true);
}
