import { permissoes, recursos, usuarioPossuiPermissao } from "Common";
import { createContext, useContext, useState } from "react";
import { TIPO_CONSULTA } from "../Util/constantes";

const ContextEtiqueta = createContext();

export default function EtiquetaProvider(props) {
  const { children } = props;

  const [podeInserir] = useState(
    usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.INSERIR)
  );
  const [podeEditar] = useState(
    usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.EDITAR)
  );
  const [podeExcluir] = useState(
    usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.EXCLUIR)
  );
  const [podeGerar] = useState(
    usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.GERAR)
  );
  const [optionsInput, setOptionsInput] = useState([]);
  const [tipoConsultaSelected, setTipoConsultaSelected] = useState(
    TIPO_CONSULTA.PRODUTO
  );
  const [valueInput, setValueInput] = useState("");
  const [registros, setRegistros] = useState([]);
  const [sortField, setSortField] = useState("codigo");
  const [sortOrder, setSortOrder] = useState(1);
  const [notasImportadas, setNotasImportadas] = useState([]);
  const [visibleModalConfiguracoesLocais, setVisibleModalConfiguracoesLocais] =
    useState(false);

  return (
    <ContextEtiqueta.Provider
      value={{
        podeInserir,
        podeEditar,
        podeExcluir,
        podeGerar,
        optionsInput,
        setOptionsInput,
        tipoConsultaSelected,
        setTipoConsultaSelected,
        valueInput,
        setValueInput,
        registros,
        setRegistros,
        notasImportadas,
        setNotasImportadas,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        visibleModalConfiguracoesLocais,
        setVisibleModalConfiguracoesLocais,
      }}
    >
      {children ? children : null}
    </ContextEtiqueta.Provider>
  );
}

export const useContextEtiqueta = () => useContext(ContextEtiqueta);
