import React, { Component } from 'react';
import * as Yup from 'yup';
import autoBind from 'react-autobind';
import Modal from '../../../../../components/Modal';
import { withRouter } from 'react-router';
import { withFormik, Field } from 'formik';
import { mensagensDeValidacao } from '../../../../../Common/Constantes/mensagens';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../../../../Common/Autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../Common/Constantes/autorizacao';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import Grid from '../../../../../components/Grid';
import InputDate from '../../../../../components/input/InputDate';
import InputMoney from '../../../../../components/input/InputMoney';
import { services } from '../../../../../Common/Constantes/api';
import SingleSelectConta from '../../../../../components/select/SingleSelectConta';
import { asyncCreateTransferencia, asyncUpdateTransferencia, asyncDeleteTransferencia, buscarFormaPagamentoDinheiro } from './Requests';
import { converterTransferenciaParaApi, converterTransferenciaParaFormulario } from './Util/transferenciaConverter';
import { helpMessage } from './Util/constantes';
import { confirm } from '../../../../../components/Toast';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import TextArea from '../../../../../components/TextArea/TextArea';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import { formatISO, isValid, parseISO } from 'date-fns';
import { validarFormulario } from '../../../../Util';
import { SingleSelectFormaPagamento } from 'components';
import { formaPagamento } from 'components/body/Vendas/components/Parcelas/Util/constantes';

const initialValue = {
	id: '',
	data: null,
	formaPagamento: null,
	contaOrigem: null,
	contaDestino: null,
	valor: 0,
	observacao: '',
};

class ModalTransferencia extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			podeInserir: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.INSERIR),
			podeEditar: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.EDITAR),
			podeExcluir: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.EXCLUIR),
			optionsSelectFormasPagamento: [],
			optionsSelectContas: [],
		};
	}

	componentDidMount() {
		if (!this.props.values.id) {
			setTimeout(() => {
				this.atribuirValoresPadrao();
			}, 1);
		}

		setTimeout(() => {
			document.getElementById('LancamentoTransferenciaInputMoneyValor')?.focus();
		}, 500);
	}

	atribuirValoresPadrao() {
		const { values, valorPadraoData } = this.props;
		let data = values.data;
		let formaPagamento = values.formaPagamento;
		if (valorPadraoData) {
			data = formatISO(valorPadraoData);
		}
		this.props.resetForm({
			values: {
				...this.props.initialValues,
				data,
			},
		});

		buscarFormaPagamentoDinheiro(({ data }) => {
			if (data.totalElements > 0) {
			  formaPagamento = {
				label: data.content[0].descricao,
				value: data.content[0].id,
				registro: data.content[0],
			  };
			}
			if (this.props.dirty) {
			  this.props.setFieldValue('formaPagamento', formaPagamento);
			} else {
			  this.props.resetForm({
				values: { ...this.props.values, formaPagamento },
			  });
			}
		});
	}

	async novo() {
		await this.props.resetForm({ values: initialValue });
		this.atribuirValoresPadrao();
		this.props.onNovoClick && this.props.onNovoClick();
	}

	async salvar(e, novoOnSuccess) {
		await this.props.handleSubmit();

		if (await validarFormulario(this.props)) {
			if (this.props.values.id) {
				this.asyncUpdateRegistro({ ...this.props.values }, novoOnSuccess);
			} else {
				this.asyncCreateRegistro({ ...this.props.values }, novoOnSuccess);
			}
		}
	}

	async asyncCreateRegistro(values, novoOnSuccess) {
		if (values) {
			const idFilialConectada = buscarDadosLoginLocalStorage()?.filialConectada?.id;

			await asyncCreateTransferencia(converterTransferenciaParaApi(this.props.values), () => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					let optionsSelectContasFilteredByFilial = [];

					this.state.optionsSelectContas?.forEach(conta => {
						if (conta.registro?.filial?.id === idFilialConectada) {
							optionsSelectContasFilteredByFilial.push(conta);
						}
					});

					this.props.onHide(optionsSelectContasFilteredByFilial);
				}
			});
		}
	}

	async asyncUpdateRegistro(values, novoOnSuccess) {
		if (values) {
			await asyncUpdateTransferencia(converterTransferenciaParaApi(this.props.values), () => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					this.props.onHide(this.state.optionsSelectContas);
				}
			});
		}
	}

	excluir() {
		confirm('Confirmação', 'Deseja excluir o registro?', async () => {
			await asyncDeleteTransferencia(this.props.values.id, () => {
				this.props.onHide(true);
			});
		});
	}

	cancelar() {
		if (this.props.dirty) {
			this.props.resetForm({ values: this.props.initialValues });
		} else {
			this.props.onHide(this.state.optionsSelectContas);
		}
	}

	render() {
		const { visible, onHide, dirty, values, setFieldValue } = this.props;

		const informacoesPermissoes = {
			podeInserir: this.state.podeInserir,
			podeEditar: this.state.podeEditar,
			podeExcluir: this.state.podeExcluir,
			estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		};

		const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
		const onClickNovo = dirty ? e => this.salvar(e, this.novo) : this.novo;

		return (
			<Modal
				header={values.id ? 'Editar transferência' : 'Nova transferência'}
				visible={visible}
				onHide={() => onHide(true)}
			>
				<AutoProgressBar />
				<Form>
					<FormActions>
						<ButtonCancelar
							{...informacoesPermissoes}
							estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={this.cancelar}
						/>
						<ButtonSalvar
							{...informacoesPermissoes}
							estadoBotao={estadosBotaoSalvar.SALVAR}
							disabled={!dirty}
							onClick={this.salvar}
						/>
						<ButtonNovo
							onClick={onClickNovo}
							hidden={!dirty && !values.id}
							estadoBotao={estadoBotaoNovo}
							{...informacoesPermissoes}
						/>
						<ButtonExcluir hidden={!values.id} {...informacoesPermissoes} onClick={this.excluir} />
					</FormActions>
					<FormContent>
						<Grid>
							<Field
								sm="12"
								md="4"
								lg="4"
								xl="4"
								component={InputMoney}
								label="Valor"
								autofocus
								name="valor"
								obrigatorio
								size={12}
								helpMessage={helpMessage.valor}
								onChange={e => setFieldValue('valor', e.target.value)}
								value={values.valor}
								id="LancamentoTransferenciaInputMoneyValor"
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="4"
								lg="4"
								xl="4"
								component={InputDate}
								obrigatorio
								label="Data"
								name="data"
								helpMessage={helpMessage.data}
								onChange={e => setFieldValue('data', e.target.value)}
								value={values.data}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="4"
								lg="4"
								xl="4"
								name="formaPagamento"
								label="Forma de pagamento"
								component={SingleSelectFormaPagamento}
								value={values.formaPagamento}
								obrigatorio
								options={this.state.optionsSelectFormasPagamento}
								onChangeOptions={options => this.setState({ optionsSelectFormasPagamento: options })}
								helpMessage={helpMessage.formaPagamento}
								onChange={e => setFieldValue('formaPagamento', e)}
								url={`${services.GESTOR}/v1/transferencias/relacoes/formas_pagamento`}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="6"
								lg="6"
								xl="6"
								name="contaOrigem"
								label="Conta de origem"
								component={SingleSelectConta}
								value={values.contaOrigem}
								obrigatorio
								options={this.state.optionsSelectContas}
								onChangeOptions={options => this.setState({ optionsSelectContas: options })}
								helpMessage={helpMessage.contaOrigem}
								onChange={e => setFieldValue('contaOrigem', e)}
								url={`${services.GESTOR}/v1/transferencias/relacoes/contas`}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="6"
								lg="6"
								xl="6"
								name="contaDestino"
								label="Conta de destino"
								component={SingleSelectConta}
								obrigatorio
								options={this.state.optionsSelectContas}
								loadInitialValues={false}
								onChangeOptions={options => this.setState({ optionsSelectContas: options })}
								helpMessage={helpMessage.contaDestino}
								value={values.contaDestino}
								onChange={e => setFieldValue('contaDestino', e)}
								url={`${services.GESTOR}/v1/transferencias/relacoes/contas`}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={TextArea}
								placeholder="Escreva sua observação aqui"
								label="Observação"
								name="observacao"
								onChange={e => setFieldValue('observacao', e.target.value)}
								{...informacoesPermissoes}
							/>
						</Grid>
					</FormContent>
				</Form>
			</Modal>
		);
	}
}

ModalTransferencia = withFormik({
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.transferenciaSelecionada) {
			return converterTransferenciaParaFormulario(props.transferenciaSelecionada);
		}
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (values.valor <= 0) {
			errors.valor = "O valor deve ser maior que zero";
		  }

		if (values.contaOrigem && values.contaDestino) {
			if (values.contaOrigem.value === values.contaDestino.value) {
				errors.contaOrigem = 'As contas não podem ser iguais';
			}
		}

		if (values.data && !isValid(parseISO(values.data))) {
			errors.data = mensagensDeValidacao.DATA_INVALIDA;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		contaOrigem: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		contaDestino: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		valor: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		data: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		observacao: Yup.string().max(4096, 'O campo observação não pode ter mais que 4096 caracteres.').nullable(true),
	}),
	handleSubmit: () => {},
})(ModalTransferencia);

export default withRouter(ModalTransferencia);
