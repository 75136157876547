import { del, exibirToast, get, post, put, services } from 'Common';

export async function asyncGetObjetosDeServico(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetObjetoDeServico(idObjetoDeServico, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/servicos/objeto/${idObjetoDeServico}`, null, onSuccess, onError);
}

export async function asyncCreateObjetoDeServico(objetoDeServico, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/servicos/objeto`,
		objetoDeServico,
		null,
		exibirToast(onSuccess, 'Objeto de serviço criado com sucesso'),
		onError
	);
}

export async function asyncUpdateObjetoDeServico(objetoDeServico, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/servicos/objeto/${objetoDeServico.id}`,
		objetoDeServico,
		null,
		exibirToast(onSuccess, 'Objeto de serviço atualizado com sucesso'),
		onError
	);
}

export async function asyncDeleteObjetoDeServico(idObjetoDeServico, onSuccess, onError) {
	return await del(
		`${services.GESTOR}/v1/servicos/objeto/${idObjetoDeServico}`,
		null,
		exibirToast(onSuccess, 'Objeto de servico excluída'),
		onError
	);
}
