import { mensagensDeValidacao } from 'Common';
import { AutoProgressBar, Button, Col, FormGroup, Grid, InputField, confirm } from 'components';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { asyncAtualizarEmail } from './Requests';
import { INITIAL_VALUES } from './Util/constantes';

function AlterarEmailView({ handleSubmit, validateForm, values, handleReset, onHide, isValid }) {
	async function salvar() {
		await validateForm();
		await handleSubmit();
		if (isValid) {
			confirm(
				'Confirmação',
				'Será enviado um e-mail de confirmação para seu novo endereço de e-mail. Deseja prosseguir?',
				async () => {
					await asyncAtualizarEmail(values, () => {
						handleReset();
						onHide();
					});
				}
			);
		}
	}

	return (
		<FormGroup>
			<AutoProgressBar />
			<Grid>
				<Col>
					<Field lg="12" xl="12" colStyle={{ padding: 0 }} component={InputField} label="Novo e-mail" name="email" />
				</Col>
				<Col>
					<Field
						lg="12"
						xl="12"
						colStyle={{ padding: 0 }}
						component={InputField}
						type="password"
						label="Senha atual"
						name="senha"
					/>
				</Col>
				<Col lg="12" xl="12">
					<Button
						type="button"
						label="Confirmar"
						icon="fa fa-envelope"
						width="135px"
						onClick={salvar}
						style={{ margin: 0 }}
					/>
				</Col>
			</Grid>
		</FormGroup>
	);
}

const AlterarEmail = withFormik({
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validationSchema: Yup.object().shape({
		email: Yup.string().email('E-mail inválido').nullable().required(mensagensDeValidacao.OBRIGATORIO),
		senha: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.test('len', 'A senha deve ter no mínimo 8 caracteres', (val) => val && val.length >= 8),
	}),

	handleSubmit: () => {},
})(AlterarEmailView);

export default AlterarEmail;
