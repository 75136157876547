import { useState } from 'react';
import propTypes from 'prop-types';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import MultipleSelect from '../MultipleSelect';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { removerCaracteres } from '../../../Common/ManipulacaoDeString';
import { montarLabelPessoa } from '../SingleSelectPessoa/Util';
import ModalPessoas from '../../../views/cadastros/Pessoas/Modal';
import { copiarObjeto } from '../../../Common';

export default function MultiSelectClientes(props) {
	const { url, noOptionsMessage, service } = props;

	const [visible, setVisible] = useState(false);
	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.INSERIR));
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.PESSOAS, permissoes.VISUALIZAR)
	);
	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		const pesquisaCodigo = removerCaracteres(pesquisa, ['.']);
		return `${url}?query=(codigo=contains="*${pesquisaCodigo}*",nome=contains="*${pesquisa}*");situacao=="ATIVA"&page=${page}&size=50&sort=nome`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	async function setarNovoRegistroNoSelect(novoRegistro) {
		const partes = await copiarObjeto([...props.value]);
		if (novoRegistro) {
			partes.push({
				label: montarLabel(novoRegistro),
				value: novoRegistro.id,
				registro: {
					codigo: novoRegistro.codigo,
					cpf: novoRegistro.cpf,
					cnpj: novoRegistro.cnpj,
					id: novoRegistro.id,
					nome: novoRegistro.nome,
				},
			});

			await props.onChange(partes);
		}
	}

	function montarLabel(row) {
		return montarLabelPessoa(row);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	return (
		<>
			<ModalPessoas service={service} visible={visible} onHide={onHide} />
			<MultipleSelect
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				noOptionsMessage={noOptionsMessage}
				disabledButton={!podeInserirEVisualizar}
				onChange={props.onChange}
				esconderBotao={false}
				{...props}
			/>
		</>
	);
}

MultiSelectClientes.defaultProps = {
	label: 'Parte',
};

MultiSelectClientes.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
	/** texto a ser exibido quando não tem nenhum item na lista */
	noOptionsMessage: propTypes.func,
};
