import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { buscarDadosLoginLocalStorage, formatarDecimais } from 'Common';
import { NenhumRegistroEncontrado } from 'components';

const styleColumn = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

function ListaProdutosDevolvidos({ produtos, isMobile }) {
	const { filialConectada } = buscarDadosLoginLocalStorage();
	const decimaisQtd = filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;

	function renderLabelFieldQuantidade(field, row) {
		return formatarDecimais(row[field], decimaisQtd) ?? '-';
	}

	return (
		<DataTable
			className="table"
			rowClassName="table-row"
			cellClassName="table-row-cell"
			responsive
			value={produtos}
			style={{
				width: '100%',
				overflowY: length > 6 ? 'scroll' : 'hidden',
				overflowX: 'hidden',
				maxHeight: isMobile ? '36.4rem' : '20.2rem',
			}}
			emptyMessage={<NenhumRegistroEncontrado />}
		>
			<Column
				header="Item"
				field="item"
				body={(row) => row?.item}
				style={{
					...styleColumn,
					width: '40px',
				}}
			/>
			<Column
				header="Produto"
				field="produto"
				body={(row) => row.produto || '-'}
				style={{
					...styleColumn,
				}}
			/>
			<Column
				header="Qtde. original"
				field="quantidadeOriginal"
				body={(row) => renderLabelFieldQuantidade('quantidadeOriginal', row)}
				style={{
					...styleColumn,
					width: '120px',
				}}
			/>
			<Column
				header="Qtde. devolvida"
				field="quantidadeDevolvida"
				body={(row) => renderLabelFieldQuantidade('quantidadeDevolvida', row)}
				style={{
					...styleColumn,
					fontWeight: 'bold',
					width: '120px',
				}}
			/>
			<Column
				header="Qtde. restante"
				field="quantidadePendenteDevolucao"
				body={(row) => renderLabelFieldQuantidade('quantidadePendenteDevolucao', row)}
				style={{
					...styleColumn,
					width: '120px',
				}}
			/>
		</DataTable>
	);
}

export { ListaProdutosDevolvidos };
