import { put, exibirToast } from '../../../../../../Common/Requisicoes';
import { services } from '../../../../../../Common/Constantes/api';

export async function asyncUpdateCartao(values, efetuarCobranca, onSuccess, onError) {
  return await put(
    `${services.GESTOR}/v1/planos/cartao?efetuarCobranca=${efetuarCobranca}`,
    values,
    null,
    exibirToast(onSuccess, 'Cartão atualizado com sucesso!'),
    onError
  );
}
