import Button from '..';

function ButtonFinalizarENovo(props) {
	const {
		id,
		className,
		style,
		hidden,
		onClick,
		enableShortCut,
		rounded,
		raised,
		icon,
		size,
		podeInserir,
		disabled,
		title,
	} = props;

	function getDisabled() {
		if (podeInserir === false) return true;
		return disabled;
	}

	function getTitle() {
		if (podeInserir === false) {
			return 'Você não possui permissão para executar essa ação';
		} else if (podeInserir === true) {
			return 'Salva, finaliza e redireciona para um novo registro em uma única ação';
		}
		return title;
	}

	return (
		<Button
			id={id}
			className={className}
			type="button"
			style={{ ...style, margin: '5px' }}
			hidden={hidden}
			onClick={onClick}
			disabled={getDisabled()}
			title={getTitle()}
			color="success"
			iconPos="left"
			icon={icon || 'fa fa-check'}
			raised={raised}
			rounded={rounded}
			label="Finalizar e novo"
			size={size}
			hotKey={['alt', 'n']}
			enableShortCut={enableShortCut}
		/>
	);
}

export default ButtonFinalizarENovo;
