import { Field } from 'formik';
import { isValidCNPJ } from '@brazilian-utils/brazilian-utils';
import { InputField, Checkbox, notify, ToastTypes } from '../../../../../../components';
import { manterApenasNumeros } from 'Common';
import { helpFieldText } from './Help';
import { asyncGetConsultaPorCnpj } from 'views/cadastros/Pessoas/Requests';
import { converterPessoaParaFormulario } from 'views/cadastros/Pessoas/Util/pessoaConverter';
import InputCnpjWithButton from 'components/input/InputCnpjWithButton';

export function renderizarFieldsPessoaJuridica(
  values,
  setFieldValue,
  informacoesPermissoes,
  setValues,
  setFieldError,
  errors,
  campoObrigatorioParaDocumentoFiscal
) {
  function onClickPesquisarCnpj() {
    consultarCnpj();
  }

  function onKeyUp(e) {
    if (e.keyCode === 13) {
      consultarCnpj();
    }
  }

  function consultarCnpj() {
    if (isValidCNPJ(manterApenasNumeros(values.cnpj))) {
      asyncGetConsultaPorCnpj(
        values.cnpj,
        ({ data: pessoa }) => {
          let pessoaConverted = converterPessoaParaFormulario({
            ...values,
            ...pessoa,
            cliente: values.cliente ?? pessoa.cliente,
            fornecedor: values.fornecedor ?? pessoa.fornecedor,
            transportador: values.transportador ?? pessoa.transportador,
          });

          setValues(pessoaConverted);
          setFieldError('cnpj', null);
        },
        () => {
          notify('CNPJ não encontrado, verifique', ToastTypes.ERROR);
        }
      );
    } else {
      setFieldError('cnpj', 'Digite um CNPJ válido.');
    }
  }

  return (
    <>
      <Field
        sm="12"
        md="8"
        lg="8"
        xl="8"
        component={InputField}
        type="text"
        helpMessage={helpFieldText.razaoSocial}
        label="Razão social"
        size={255}
        name="razaoSocial"
        value={values.razaoSocial}
        obrigatorio={campoObrigatorioParaDocumentoFiscal}
        {...informacoesPermissoes}
      />
      <Field
        sm="12"
        md="4"
        lg="4"
        xl="4"
        component={InputCnpjWithButton}
        mask="00.000.000/0000-00"
        placeholder="  .   .   /    -  "
        label="CNPJ"
        name="cnpj"
        onChange={e => setFieldValue('cnpj', e.value)}
        value={values.cnpj}
        helpMessage={helpFieldText.cnpj}
        onKeyUp={onKeyUp}
        onClick={onClickPesquisarCnpj}
        errors={errors.cnpj ? errors.cnpj : null}
        touched={errors.cnpj ? errors.cnpj : null}
        obrigatorio={campoObrigatorioParaDocumentoFiscal}
        {...informacoesPermissoes}
      />
      <Field
        sm="12"
        md="4"
        lg="4"
        xl="4"
        component={InputField}
        size={9}
        type="text"
        label="Inscrição municipal"
        helpMessage={helpFieldText.inscricaoMunicipal}
        name="inscricaoMunicipal"
        value={values.inscricaoMunicipal}
        {...informacoesPermissoes}
      />
      <Field
        sm="12"
        md="4"
        lg="4"
        xl="4"
        component={InputField}
        size={9}
        type="number"
        label="Inscrição suframa"
        helpMessage={helpFieldText.inscricaoSuframa}
        name="inscricaoSuframa"
        value={values.inscricaoSuframa}
        {...informacoesPermissoes}
      />
      <Field
        sm="12"
        md="4"
        lg="4"
        xl="4"
        component={Checkbox}
        label={'Optante do simples nacional'}
        colStyle={{ display: 'flex', alignItems: 'flex-end', marginBottom: '7px' }}
        name="simplesNacional"
        helpMessage={helpFieldText.simplesNacional}
        onChange={e => setFieldValue('simplesNacional', e.checked)}
        checked={values.simplesNacional}
        {...informacoesPermissoes}
      />
    </>
  );
}
