import { If, Modal } from 'components';
import { useState } from 'react';
import { AnimacaoCompletado } from '../../ModalConfirmarAdesao/components/AnimacaoCompletado';
import { QRCodePix } from '../../ModalConfirmarAdesao/components/QRCodePix';

function ModalPagarComPix({ visible, onHide }) {
	const [activeIndex, setActiveIndex] = useState(0);
	return (
		<Modal header="Pagar mensalidade com Pix" styleModal={{ maxWidth: '400px' }} visible={visible} onHide={onHide}>
			<If test={activeIndex === 0}>
				<QRCodePix mensalidade activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
			</If>
			<If test={activeIndex === 1}>
				<AnimacaoCompletado onHide={onHide} />
			</If>
		</Modal>
	);
}

export { ModalPagarComPix };
