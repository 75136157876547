import { useEffect } from 'react';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';

import {
  ButtonCancelar,
  estadosBotaoCancelar,
  MenuSuperior,
  LayoutMenuSuperior,
  FormGroup,
  Paper,
  NormalButton,
  InputField,
  Grid,
  Modal,
  Dropdown,
  TextArea,
  Checkbox,
  If,
} from 'components';

import { mensagensDeValidacao, buscarDadosLoginLocalStorage } from 'Common';

import { asyncCompartilharDocumento, asyncCompartilharDocumentoUnicoSignatario } from './Requests';
import { salvarUltimoEmail, buscarUltimoEmail, salvarNotificacaoPorEmail, buscarNotificacaoPorEmail } from './Util';
import { validarFormulario } from '../../../../../Util';
import { initialValue } from '../../Util/constantes';

const initialValues = {
  email: [],
  titulo: '',
  corpoEmail: '',
  enviarCopia: false,
  destinatariosCopia: null,
  notificarAssinatura: false,
};

function ModalEnviarParaAssinaturaView(props) {
  const {
    visible,
    arquivo,
    handleSubmit,
    resetForm,
    identificacaoDocumento,
    signatarios,
    informacoesEmail,
    signatarioEspecifico,
    documentoSelecionado,
    setFieldValue,
    onDocumentoEnviado,
    idDocumento,
    onHide,
    dirty,
    handleReset,
    values,
    modalReenviarEmail,
    errors,
  } = props;

  useEffect(() => {
    documentoSelecionado ? null : pesquisarCorpoEmailPadrao();

    asyncSelectRegistro();

    setTimeout(() => {
      document.getElementById('AssinaturaInputFieldEmail')?.focus();
    }, 500);
  }, []);

  async function compartilharDocumento() {
    handleSubmit();

    if (await validarFormulario(props)) {
      if (signatarioEspecifico && modalReenviarEmail) {
        compartilharComSignatarioEspecifico();
      } else {
        compartilharComTodosOsSignatarios();
      }
    }
  }

  async function compartilharComSignatarioEspecifico() {
    await asyncCompartilharDocumentoUnicoSignatario(idDocumento, signatarioEspecifico.id, values, () => {
      onDocumentoEnviado && onDocumentoEnviado();
      salvarUltimoEmail(values);
      salvarNotificacaoPorEmail(values.notificarAssinatura);
      onHide();
    });
  }

  async function compartilharComTodosOsSignatarios() {
    await asyncCompartilharDocumento(idDocumento, values, () => {
      onDocumentoEnviado && onDocumentoEnviado();
      salvarUltimoEmail(values);
      salvarNotificacaoPorEmail(values.notificarAssinatura);
      onHide();
    });
  }

  function cancelar() {
    if (dirty) {
      handleReset();
    } else {
      onHide();
    }
  }

  async function pesquisarCorpoEmailPadrao() {
    const organizacaoDocumentos = await buscarDadosLoginLocalStorage();
    let documentos = organizacaoDocumentos?.organizacao?.documentos;

    setFieldValue('corpoEmail', documentos?.corpoEmail);
  }

  function formatarCorpoEmail(dadosLocalStorage) {
    let corpoEmailFormatado = dadosLocalStorage.filialConectada.parametrosDocumentos.corpoEmail;

    if (corpoEmailFormatado.includes('{identificacaoDocumento}')) {
      corpoEmailFormatado = corpoEmailFormatado.replaceAll('{identificacaoDocumento}', identificacaoDocumento);
    }
    if (corpoEmailFormatado.includes('{nomeOrganizacao}')) {
      corpoEmailFormatado = corpoEmailFormatado.replaceAll('{nomeOrganizacao}', dadosLocalStorage.filialConectada.nome);
    }

    return corpoEmailFormatado;
  }

  function formatarEmail() {
    let emailsFormatados = [];
    if (!!signatarioEspecifico?.email) {
      emailsFormatados.push(signatarioEspecifico?.email);
    } else {
      signatarios?.map(signatario => {
        if (signatario.telefone && !modalReenviarEmail) {
          emailsFormatados.push(signatario.email + ' - ' + formatarTelefone(signatario.telefone));
        } else {
          emailsFormatados.push(signatario.email);
        }
      });
    }
    return emailsFormatados;
  }

  function formatarTelefone(numero) {
    numero = numero.replace(/\D/g, '');
    numero = numero.replace(/^(\d{2})(\d)/g, '($1) $2');
    numero = numero.replace(/(\d)(\d{4})$/, '$1-$2');
    return numero;
  }

  async function asyncSelectRegistro() {
    let ultimoEmail = await buscarUltimoEmail();
    let notificarAssinatura = (await buscarNotificacaoPorEmail()) || false;
    const dadosLocalStorage = buscarDadosLoginLocalStorage();

    if (!ultimoEmail) {
      ultimoEmail = {
        titulo: 'Novo documento',
        corpoEmail: formatarCorpoEmail(dadosLocalStorage),
      };
    }
    
    resetForm({
      values: {
        ...initialValues,
        ...ultimoEmail,
        notificarAssinatura,
        titulo: documentoSelecionado?.tituloEmail ? documentoSelecionado.tituloEmail
          : informacoesEmail?.tituloEmail
          ? `${informacoesEmail?.tituloEmail}`
          : identificacaoDocumento?.length <= 10
          ? `Documento ${identificacaoDocumento}`
          : `${identificacaoDocumento}`,
        corpoEmail: documentoSelecionado?.corpoEmail ? documentoSelecionado.corpoEmail 
          :informacoesEmail?.corpoEmail
          ? `${informacoesEmail?.corpoEmail}`
          : formatarCorpoEmail(dadosLocalStorage),
        destinatariosCopia: dadosLocalStorage.email,
        email: formatarEmail(),
      },
    });
  }

  function getHeaderTitle() {
    return modalReenviarEmail ? 'Reenviar email' : 'Enviar para assinatura';
  }

  return (
    <>
      <Modal visible={visible} header={getHeaderTitle()} onHide={onHide}>
        <MenuSuperior isModal={true}>
          <Grid style={{ alignItems: 'center', padding: '8px' }}>
            <ButtonCancelar
              estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
              onClick={() => cancelar()}
            />
            <NormalButton
              label={modalReenviarEmail ? 'Enviar e-mail' : 'Enviar e-mail e whatsApp'}
              icon="fa fa-paper-plane"
              disabled={!errors}
              onClick={() => compartilharDocumento()}
            />
          </Grid>
        </MenuSuperior>
        <LayoutMenuSuperior isModal={true}>
          <Paper dirty={dirty} childsOnly={true}>
            <FormGroup>
              <Grid>
                <Field
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  component={Dropdown}
                  isMulti
                  options={
                    values.email &&
                    values.email.map(email => {
                      return { label: email, value: email };
                    })
                  }
                  label={modalReenviarEmail ? 'Destinatário(s)' : 'Signatário(s)'}
                  helpMessage={
                    modalReenviarEmail
                      ? 'Signatário(s) que receberão o e-mail'
                      : 'Signatário(s) que receberão o e-mail e a mensagem no WhatsApp'
                  }
                  obrigatorio
                  name="email"
                  value={values.email}
                  disabled
                />
                <Field
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  component={InputField}
                  name="titulo"
                  helpMessage={
                    modalReenviarEmail
                      ? 'Título do e-mail enviado ao destinatário'
                      : 'Título da mensagem a ser enviada ao destinatário'
                  }
                  label={modalReenviarEmail ? 'Título do e-mail' : 'Título da mensagem'}
                  obrigatorio
                  height={255}
                  onChange={e => setFieldValue('titulo', e.target.value)}
                  value={values.titulo}
                  id="AssinaturaInputFieldEmail"
                />
                <Field
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  label={modalReenviarEmail ? 'Texto do e-mail' : 'Texto da mensagem'}
                  component={TextArea}
                  obrigatorio
                  helpMessage={
                    modalReenviarEmail
                      ? 'Texto presente no e-mail enviado ao destinatário'
                      : 'Texto presente na mensagem enviada ao destinatário'
                  }
                  name="corpoEmail"
                  value={values.corpoEmail}
                  onChange={e => setFieldValue('corpoEmail', e.target.value)}
                />
                <If test={modalReenviarEmail === false}>
                  <Field
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    component={Checkbox}
                    label="Desejo ser notificado por e-mail assim que o documento for assinado ou rejeitado"
                    helpMessage="Você pode ser notificado por e-mail quando o documento for assinado ou rejeitado."
                    name="notificarAssinatura"
                    onChange={() => setFieldValue('notificarAssinatura', !values.notificarAssinatura)}
                    checked={values.notificarAssinatura}
                  />
                  <Field
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    component={Checkbox}
                    label="Desejo enviar uma cópia deste documento para outros destinatários"
                    helpMessage="Você pode enviar uma cópia deste documento para você e outros destinatários."
                    name="enviarCopia"
                    onChange={() => setFieldValue('enviarCopia', !values.enviarCopia)}
                    checked={values.enviarCopia}
                    //não permite enviar copia de arquivos de mais de 8 MB
                    disabled={arquivo && arquivo.size > 8388608}
                    title={
                      arquivo && arquivo.size > 8388608
                        ? 'Não é possível enviar uma cópia com um arquivo com tamanho superior a 8 MB no e-mail.' +
                          " Para obter o arquivo, você pode clicar em 'Opções' e 'Visualizar arquivo original'"
                        : ''
                    }
                  />
                  <Field
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    component={InputField}
                    label="Enviar cópia (separar destinatários com virgula)"
                    hidden={!values.enviarCopia}
                    name="destinatariosCopia"
                    helpMessage="E-mails que irão receber a cópia. Podem ser informados vários e-mails separados por vírgula"
                    obrigatorio
                    size={255}
                  />
                </If>
              </Grid>
            </FormGroup>
          </Paper>
        </LayoutMenuSuperior>
      </Modal>
    </>
  );
}

const ModalEnviarParaAssinatura = withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,

  mapPropsToValues() {
    return initialValue;
  },

  async validate(values) {
    const errors = {};

    if (values.destinatariosCopia) {
      let destinatariosCopia = values.destinatariosCopia.split(',').map(item => item.trim());
      let schemaEmail = Yup.string().email();

      for (const item of destinatariosCopia) {
        if (item === '') {
          errors.destinatariosCopia = `O e-mail "${item}" não é um e-mail válido`;
          break;
        } else if (!(await schemaEmail.isValid(item))) {
          errors.destinatariosCopia = `O e-mail "${item}" não é um e-mail válido`;
          break;
        }
      }
    }

    return errors;
  },

  validationSchema: Yup.object().shape({
    titulo: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    corpoEmail: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
  }),

  handleSubmit: () => {},
})(ModalEnviarParaAssinaturaView);

export default ModalEnviarParaAssinatura;
