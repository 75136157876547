import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar, estadosBotaoSalvar } from 'components';
import { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from '../../../../../components/Modal';

function ModalCropImage(props) {
	const [crop, setCrop] = useState({ unit: 'px', width: 200, height: 127, aspect: 1.57 / 1 });
	const [imageRef, setImageRef] = useState(null);

	function getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve) => {
			canvas.toBlob(
				(blob) => {
					const renamedBlob = new Blob([blob], { type: 'image/png' });
					renamedBlob.name = fileName;
					resolve(renamedBlob);
				},
				'image/png',
				1
			);
		});
	}

	async function confirmar() {
		props.onHide(await getCroppedImg(imageRef, crop, 'arquivo.png'));
	}

	return (
		<Modal header="Ajustar imagem" visible={props.visible} onHide={props.onHide} styleModal={{ maxWidth: '800px' }}>
			<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={() => props.onHide()} />
			<ButtonSalvar estadoBotao={estadosBotaoSalvar.CONFIRMAR} onClick={confirmar} />
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<ReactCrop
					style={{ borderRadius: '5px' }}
					src={props.imagemUrl}
					crop={crop}
					ruleOfThirds
					onChange={(e) => setCrop(e)}
					onImageLoaded={(image) => setImageRef(image)}
					keepSelection
				/>
			</div>
		</Modal>
	);
}

export default ModalCropImage;
