import { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BiMove } from 'react-icons/bi';

import { Button, ButtonNovo, Card, Divider, Grid, If, NenhumRegistroEncontrado, estadosBotaoNovo } from 'components';
import { copiarObjeto, removerElemento } from 'Common';

import { ModalCampo } from '../ModalCampo';
import { ModalMovimentarCampo } from '../ModalMovimentarCampo';
import { useContextEtiquetaConfiguracao } from '../../../../Context';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

function ListaCampos({ informacoesPermissoes, isLessHd }) {
	const { values, setFieldValue } = useFormikContext();
	const { camposEtiquetasUtilizados, podeEditar, alturaMaximaCardsContent } = useContextEtiquetaConfiguracao();

	const [visibleModalCampo, setVisibleModalCampo] = useState(false);
	const [registro, setRegistro] = useState(null);
	const [indexCampo, setindexCampo] = useState(null);

	const panelPosicaoCampos = useRef(null);

	const novoCampoButton = (
		<Grid justifyCenter>
			<Divider styleContainer={{ margin: '0px' }} styleLabel={{ margin: '0px' }} />
			<ButtonNovo
				label="Novo campo"
				onClick={handleClickNovo}
				estadoBotao={estadosBotaoNovo.NOVO}
				disabled={!podeEditar}
			/>
		</Grid>
	);

	useEffect(async () => {
		if (visibleModalCampo) {
			const camposTipo = await values.campos?.map((campos) => campos?.campo?.registro?.nome);
			camposEtiquetasUtilizados.current = camposTipo;
		}
	}, [visibleModalCampo]);

	function handleClickNovo() {
		setVisibleModalCampo(true);
	}

	function renderAcoes(row, index) {
		let titleExcluir = 'Excluir';
		let disableBtnExcluir = false;
		let disableBtnMoveField = false;

		if (!informacoesPermissoes.podeEditar) {
			disableBtnMoveField = true;
		}
		if (!informacoesPermissoes.podeExcluir) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		}

		return (
			<div>
				<Button
					style={styleButton}
					className="p-button p-button-success"
					icon={<BiMove />}
					title="Movimentar posição do campo"
					disabled={disableBtnMoveField}
					onClick={(event) => {
						setindexCampo(index);
						return panelPosicaoCampos.current.toggle(event);
					}}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title="Editar"
					disabled={false}
					onClick={() => onEdit(index)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => onRemove(row)}
				/>
			</div>
		);
	}

	function onEdit(index) {
		setVisibleModalCampo(true);
		setRegistro(values?.campos[index]);
		setindexCampo(index);
	}

	function onRemove(row) {
		setFieldValue('campos', removerElemento(values.campos, row));
	}

	function addNewCampoModal(newCampo) {
		const camposArray = copiarObjeto(values.campos);
		camposArray.push(newCampo);
		setFieldValue('campos', camposArray);
	}

	function editCampoModal(index, campo) {
		setFieldValue(`campos[${index}]`, campo);
	}

	function deleteCampoModal(index) {
		const camposArray = copiarObjeto(values.campos);
		camposArray.splice(index, 1);
		setFieldValue('campos', camposArray);
	}

	return (
		<>
			<Card className="card-etiqueta" footer={novoCampoButton} style={{ height: '100%' }}>
				<DataTable
					className="table"
					responsive
					value={values?.campos}
					emptyMessage={<NenhumRegistroEncontrado />}
					style={{
						height: `${alturaMaximaCardsContent}px`,
						overflowY: 'auto',
					}}
				>
					<Column
						field="tipoCampo"
						header="Campo"
						body={(rowData) => rowData.campo?.label}
						style={{ width: isLessHd ? '16rem' : '24rem' }}
						sortable
						sortField="campo.label"
					/>
					<Column field="altura" header="Altura" body={(rowData) => rowData.altura} />
					<Column field="largura" header="Largura" body={(rowData) => rowData.largura} />
					<Column
						body={(rowData, index) => renderAcoes(rowData, index?.rowIndex)}
						header="Ações"
						style={{ width: '9rem' }}
					/>
				</DataTable>
			</Card>
			<If test={visibleModalCampo}>
				<ModalCampo
					registro={registro}
					visible={visibleModalCampo}
					indexCampo={indexCampo}
					onHide={() => {
						setVisibleModalCampo(false);
						setRegistro(null);
						setindexCampo(null);
					}}
					informacoesPermissoes={informacoesPermissoes}
					addNewCampoModal={addNewCampoModal}
					editCampoModal={editCampoModal}
					deleteCampoModal={deleteCampoModal}
				/>
			</If>

			<OverlayPanel style={{ maxWidth: '240px' }} ref={panelPosicaoCampos} showCloseIcon dismissable>
				<ModalMovimentarCampo informacoesPermissoes={informacoesPermissoes} indexCampo={indexCampo} />
			</OverlayPanel>
		</>
	);
}

export { ListaCampos };
