export const Tipo = {
	PEDIDO_VENDA: 'PEDIDO_VENDA',
	ORCAMENTO: 'ORCAMENTO',
};

export const origemProduto = [
	{
		label: '0 - Nacional, exceto as indicadas nos código 3, 4, 5 e 8',
		value: 'NACIONAL',
	},
	{
		label: '1 - Estrangeira - Importação direta, exceto a indicada no código 6',
		value: 'ESTRANGEIRA',
	},
	{
		label: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7',
		value: 'ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO',
	},
	{
		label: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%',
		value: 'NACIONAL_IMPORTACAO_ENTRE_40_E_70_PORCENTO',
	},
	{
		label:
			'4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes',
		value: 'NACIONAL_COM_PROCESSO_PRODUTIVO',
	},
	{
		label: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%',
		value: 'NACIONAL_IMPORTACAO_INFERIOR_40_PORCENTO',
	},
	{
		label: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural',
		value: 'ESTRANGEIRA_IMPORTACAO_DIRETA',
	},
	{
		label:
			'7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX e gás natural',
		value: 'ESTRANGEIRA_ADQUIR_MERCADO_INTERNO_SEM_SIMILAR_NAC',
	},
	{
		label: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%',
		value: 'NACIONAL_IMPORTACAO_SUPERIOR_70_PORCENTO',
	},
];

export const colorsStatus = {
	PENDENTE: {
		lightColor: '#b3e5fc',
		strongColor: '#01579B',
	},
	NAO_ENVIADA: {
		lightColor: '#b3e5fc',
		strongColor: '#01579B',
	},
	FINALIZADO: {
		lightColor: '#dcedc8',
		strongColor: '#1b5e20',
	},
	TRANSMITIDA: {
		lightColor: '#dcedc8',
		strongColor: '#1b5e20',
	},
	CORRIGIDA: {
		lightColor: '#dcedc8',
		strongColor: '#1b5e20',
	},
	CANCELADA: {
		lightColor: '#ffcdd2',
		strongColor: '#b71c1c',
	},
	PARCIALMENTE_DEVOLVIDO: {
		lightColor: '#dcedc8',
		strongColor: '#1b5e20',
	},
	DEVOLVIDO: {
		lightColor: '#dcedc8',
		strongColor: '#1b5e20',
	},
	REJEITADA: {
		lightColor: '#FFECB3',
		strongColor: '#765100',
	},
	DENEGADA: {
		lightColor: '#ffcdd2',
		strongColor: '#b71c1c',
	},
	AGUARDANDO_AUTORIZACAO: {
		lightColor: '#E7ECF0',
		strongColor: '#333333',
	},
	EXPIRADA: {
		lightColor: '#E7ECF0',
		strongColor: '#333333',
	},
};
