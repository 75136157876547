import { services } from 'Common';
import { InputDate, InputMoney, SingleSelectConta } from 'components';
import { Field, useFormikContext } from 'formik';
import { helpMessage } from '../../Util/constantes';

function FormValorRecebido({ informacoesPermissoes }) {
	const { values, setFieldValue, errors } = useFormikContext();

	return (
		<>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputMoney}
				label="Valor recebido"
				name="valorRecebido"
				obrigatorio
				value={values.valorRecebido}
				onChange={(e) => setFieldValue('valorRecebido', e.target.value)}
				size={12}
				allowNegative={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputDate}
				label="Data do recebimento"
				name="dataRecebido"
				obrigatorio
				onChange={(e) => setFieldValue('dataRecebido', e.target.value)}
				value={values.dataRecebido}
				errors={errors?.dataRecebido}
				touched={!!errors?.dataRecebido}
				useFormErrors={false}
				useFormTouched={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				name="contaRecebido"
				label="Conta"
				obrigatorio
				component={SingleSelectConta}
				value={values.contaRecebido}
				helpMessage={helpMessage.conta}
				onChange={(e) => setFieldValue('contaRecebido', e)}
				url={`${services.GESTOR}/v1/contas_receber/relacoes/contas`}
			/>
		</>
	);
}

export { FormValorRecebido };
