import { actionTypes } from '../../Common/Constantes/reduxTypes';

export default (state = false, action) => {
	switch (action.type) {
		case actionTypes.POSSUI_INTERNET: {
			return action.payload;
		}
		default: {
			return state;
		}
	}
};
