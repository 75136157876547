import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { format, isBefore, parseISO, startOfDay } from 'date-fns';
import { asyncGetBoletos, asyncGetContaFavoritaReceita, asyncGetFilialConectada } from './Requests';

import {
	removerCaracteres,
	configuracoesUsuario,
	buscarConfiguracaoUsuario,
	salvarConfiguracaoUsuario,
	construirUrl,
	permissoes,
	recursos,
	usuarioPossuiPermissao,
	services,
	useStateCallback,
	mensagensDeValidacao,
} from '../../../Common';

import {
	Form,
	FormActions,
	FormContent,
	Tutorial,
	tutorialStepsListagens,
	If,
	ModalLoadingTransmissao,
} from '../../../components';
import ModalContaReceber from '../ContasReceber/components/ModalContaReceber';
import { converteBoletosParaFormulario } from './Util/boletosConverter';
import TabelaBoletos from './components/TabelaBoletos';
import ActionButtons from './components/ActionButtons';
import PesquisaFields from './components/PesquisaFields';
import ModalEnviarBoletosPorEmail from './components/ModalEnviarBoletosPorEmail';
import { getIdBoletosSelected } from './Util/functions';

function currentMonth() {
	let date = new Date();
	let firstDay = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
	let lastDay = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
	let formatedDate = `vencimento>=${firstDay};vencimento<=${lastDay}`;

	return formatedDate;
}

const initialValues = {
	conta: null,
	convenio: null,
	registros: [],
	selectedBoletos: [],
};

function Boletos(props) {
	const { isMobile, setFieldValue, values } = props;

	const [totalElements, setTotalElements] = useState(0);
	const [rows, setRows] = useState(10);
	const [page, setPage] = useState(0);
	const [sortOrder, setSortOrder] = useState(-1);
	const [sortField, setSortField] = useState('vencimento');
	const [interval, setInterval] = useState({
		dataInicial: null,
		dataFinal: null,
	});
	const [filtroData, setFiltroData] = useState('');
	const [valorPesquisa, setValorPesquisa] = useStateCallback('');
	const [filtroAvancado, setFiltroAvancado] = useState('');
	const [exibirLoading, setExibirLoading] = useState(false);
	const [messageLoading, setMessageLoading] = useState('Loading...');
	const [exibirModalContaReceber, setExibirModalContaReceber] = useState(false);
	const [exibirModalEnviarPorEmail, setExibirModalEnviarPorEmail] = useState(false);
	const [dadosPessoaParaEmail, setDadosPessoaParaEmail] = useState(null);

	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.INSERIR)
	);
	const [podeEditar, setPodeEditar] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EDITAR)
	);
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR)
	);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial, setDeveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
	);

	useEffect(() => {
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		buscarContaFavorita();

		setTimeout(() => {
			document.getElementById('BoletoInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		pesquisar();
	}, [interval, filtroAvancado, rows, page]);

	function buscarContaFavorita() {
		asyncGetContaFavoritaReceita(async ({ data }) => {
			if (data.totalElements > 0) {
				let contaFavorita = {
					label: await montarLabel(data.content[0]),
					registro: data.content[0],
					value: data.content[0].id,
				};
				let primeiroConvenio = null;

				if (data.content[0].convenios.length > 0) {
					let convenios = await data.content[0].convenios
						?.filter(convenio => convenio.situacao === 'ATIVA')
						?.sort((a, b) => a.numero.localeCompare(b.numero));

					if (convenios.length > 0) {
						primeiroConvenio = {
							label: `${convenios[0].descricao} - ${convenios[0].numero}`,
							registro: convenios[0],
							value: convenios[0].id,
						};
					}
				}
				await props.resetForm({
					values: { ...values, conta: contaFavorita, convenio: primeiroConvenio },
				});
			}
		});
	}

	function montarLabel(conta) {
		let label = conta.nome;

		label = `${label} Conta ${conta.numeroConta}`;
		if (conta.verificadorConta) {
			label = `${label}-${conta.verificadorConta}`;
		}

		label = `${label} AG ${conta.numeroAgencia}`;
		if (conta.digitoVerificadorAgencia) {
			label = `${label}-${conta.digitoVerificadorAgencia}`;
		}

		return label;
	}

	function validateDate(dateInput) {
		const dateFormats = [
			/(\d{2})\/(\d{2})\/(\d{4})/,
			/(\d{2}).(\d{2}).(\d{4})/,
			/(\d{2})-(\d{2})-(\d{4})/,

			/(\d{4})\/(\d{2})\/(\d{2})/,
			/(\d{4}).(\d{2}).(\d{2})/,
		];
		let dataFormatada = null;
		dateFormats.forEach(dateFormat => {
			const dataCorrespondente = dateInput.match(dateFormat);
			let day = null;
			let month = null;
			let year = null;

			if (dataCorrespondente) {
				if (dataCorrespondente[1].length <= 2) {
					[, day, month, year] = dataCorrespondente;
				} else {
					[, year, month, day] = dataCorrespondente;
				}

				try {
					dataFormatada = format(new Date(year, month - 1, day), 'yyyy-MM-dd');
				} catch (error) {
					return null;
				}
			}
		});
		return dataFormatada;
	}

	function buscarFiltro() {
		const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.']);
		let pesquisaData = valorPesquisa;

		if (valorPesquisa.length === 10) {
			pesquisaData = validateDate(valorPesquisa);
		}

		let filtroRSQL = String('?query=(')
			.concat(`identificador=contains="*${pesquisaCodigo.replaceAll('&', '')}*",`)
			.concat(`descricao=contains="*${pesquisaCodigo.replaceAll('&', '')}*",`)
			.concat(`emissao=contains="*${pesquisaData?.replaceAll('&', '%26')}*",`)
			.concat(`vencimento=contains="*${pesquisaData?.replaceAll('&', '%26')}*",`)
			.concat(`valor=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(
				`pessoa.pessoaFisica.cpf=contains="*${removerCaracteres(valorPesquisa, ['.', '-']).replaceAll(
					'&',
					'%26'
				)}*",`
			)
			.concat(
				`pessoa.pessoaJuridica.cnpj=contains="*${removerCaracteres(valorPesquisa, ['.', '/', '-']).replaceAll(
					'&',
					'%26'
				)}*",`
			)
			.concat(`pessoa.nome=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroData) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroData})`);
		} else {
			filtroRSQL = filtroRSQL.concat(`;(${currentMonth()})`);
		}

		filtroRSQL = filtroRSQL.concat(
			';((boleto.situacao!="LIQUIDADO";status=="NAO_RECEBIDA"),(boleto.situacao=="LIQUIDADO";status=="RECEBIDA"),(status=="NAO_RECEBIDA"))'
		);

		if (filtroAvancado && filtroAvancado?.includes('boleto.situacao=="PENDENTE"')) {
			let hasMoreFilters = filtroAvancado
				.replace(/^(.*)boleto\.situacao=="PENDENTE"(.*)$/, '$1$2')
				.replace(/^;|;$/, '')
				.replace(/;;/g, ';');

			filtroRSQL = filtroRSQL.concat(`;(boleto=="null")`);

			if (hasMoreFilters.length > 0) {
				filtroRSQL = filtroRSQL.concat(`;(${hasMoreFilters})`);
			}
		} else if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}
		return filtroRSQL;
	}

	async function pesquisar() {
		const filtro = buscarFiltro();

		const url = construirUrl(
			`${services.GESTOR}/v1/boletos/resumo`,
			filtro || '?',
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		asyncGetBoletos(url, ({ data: boletos }) => {
			setFieldValue('registros', converteBoletosParaFormulario(boletos.content));
			setTotalElements(boletos.totalElements);
		});
	}

	function onHideModalContaReceber() {
		pesquisar();
		setExibirModalContaReceber(false);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Boletos">
				<FormActions>
					<ActionButtons
						podeInserir={podeInserir}
						podeEditar={podeEditar}
						podeVisualizar={podeVisualizar}
						isMobile={isMobile}
						pesquisar={pesquisar}
						values={values}
						setFieldValue={setFieldValue}
						handleSubmit={props.handleSubmit}
						setExibirModalContaReceber={setExibirModalContaReceber}
						setExibirModalEnviarPorEmail={setExibirModalEnviarPorEmail}
						setExibirLoading={setExibirLoading}
						setMessageLoading={setMessageLoading}
						setDadosPessoaParaEmail={setDadosPessoaParaEmail}
						buscarContaFavorita={buscarContaFavorita}
						propsForm={props}
					/>
				</FormActions>
				<FormContent>
					<PesquisaFields
						setFieldValue={setFieldValue}
						pesquisar={pesquisar}
						valorPesquisa={valorPesquisa}
						setFiltroData={setFiltroData}
						setPage={setPage}
						setInterval={setInterval}
						setValorPesquisa={setValorPesquisa}
						setFiltroAvancado={setFiltroAvancado}
					/>
					<TabelaBoletos
						sortField={sortField}
						sortOrder={sortOrder}
						registros={values.registros}
						selectedBoletos={values.selectedBoletos}
						setFieldValue={setFieldValue}
						totalElements={totalElements}
						rows={rows}
						setRows={setRows}
						page={page}
						setPage={setPage}
						history={props.history}
						podeEditar={podeEditar}
					/>
				</FormContent>
			</Form>
			<If test={exibirLoading}>
				<ModalLoadingTransmissao visible={exibirLoading} message={messageLoading} onHide={() => {}} />
			</If>
			<If test={exibirModalContaReceber}>
				<ModalContaReceber
					visible={exibirModalContaReceber}
					onHide={onHideModalContaReceber}
					registroSelecionado={null}
					valorPadraoDataVencimento={new Date()}
				/>
			</If>
			<If test={exibirModalEnviarPorEmail}>
				<ModalEnviarBoletosPorEmail
					visible={exibirModalEnviarPorEmail}
					onHide={() => setExibirModalEnviarPorEmail(false)}
					boletos={values.selectedBoletos}
					boletosIds={getIdBoletosSelected(values.selectedBoletos)}
					dadosPessoaParaEmail={dadosPessoaParaEmail}
				/>
			</If>
		</>
	);
}

Boletos = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValues;
	},

	validate(values) {
		let errors = {};
		let errorsBoletos = {};

		if (!values.conta) {
			errors.conta = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.convenio) {
			errors.convenio = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.selectedBoletos) {
			let errorPessoa = 0;
			let errorVencimento = 0;
			let errorVencimentoMenorQueEmissao = 0;
			let erroVencimentoMenorQueHoje = 0;

			if (values.selectedBoletos.length > 0) {
				values.selectedBoletos?.forEach(boleto => {
					if (!boleto.pessoa) {
						errorPessoa++;
					}
					if (!boleto.vencimento) {
						errorVencimento++;
					}
					if (isBefore(parseISO(boleto.vencimento), parseISO(boleto.emissao))) {
						errorVencimentoMenorQueEmissao++;
					}
					if (isBefore(parseISO(boleto.vencimento), startOfDay(new Date()))) {
						erroVencimentoMenorQueHoje++;
					}
				});

				if (errorPessoa > 0) {
					errorsBoletos =
						errorPessoa === 1
							? 'Registro selecionado sem pessoa vinculada'
							: 'Existem registros selecionados sem pessoa vinculada';
				}
				if (errorVencimento > 0) {
					errorsBoletos =
						errorPessoa === 1
							? 'Registro selecionado sem data de vencimento'
							: 'Existem registros selecionados sem data de vencimento';
				}
				if (errorVencimentoMenorQueEmissao > 0) {
					errorsBoletos =
						errorVencimentoMenorQueEmissao === 1
							? 'Registro selecionado com data de vencimento menor que a emissão'
							: 'Existem registros selecionados com data de vencimento menor que a emissão';
				}
				if (erroVencimentoMenorQueHoje > 0) {
					errorsBoletos =
						erroVencimentoMenorQueHoje === 1
							? 'Registro selecionado com data de vencimento menor que hoje'
							: 'Existem registros selecionados com data de vencimento menor que hoje';
				}
			} else {
				errorsBoletos = 'Não existem boletos selecionados para emissão';
			}
		}
		if (Object.keys(errorsBoletos)?.length > 0) {
			errors = { ...errors, boletos: errorsBoletos };
		}
		return errors;
	},

	handleSubmit: () => {},
})(Boletos);

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Boletos);
