import { Button, ButtonExcluir, Grid, If } from 'components';
import { format, isAfter, isWithinInterval, subDays } from 'date-fns';
import { RadioButton } from 'primereact/radiobutton';
import { useEffect } from 'react';
import { MdPix } from 'react-icons/md';
import { useContextPlanos } from '../../Context';
import { METODO_PAGAMENTO } from '../../Util/constantes';
import { ModalPagarComPix } from './ModalPagarComPix';

function CardMeuCartao({
	planoAtual,
	podeEditar,
	informacoesCartao,
	onAlterarClick,
	onRemoverCartaoClick,
	dataProximoPagamento = new Date(),
	isTablet,
	isLessHd,
	isDesktop,
	atualizarDadosLocais,
}) {
	const { numero, titular, expiracao } = informacoesCartao;
	const { meioPagamento, setMeioPagamento, visibleModalPagarComPix, setVisibleModalPagarComPix } = useContextPlanos();

	const podeRemoverCartao = podeEditar && informacoesCartao.numero;
	const isWeekBeforeNextPayment = isWithinInterval(new Date(), {
		start: subDays(dataProximoPagamento, 7),
		end: dataProximoPagamento,
	});
	const todayIsAfterDueDate = isAfter(new Date(), dataProximoPagamento);

	useEffect(() => {
		if (informacoesCartao?.numero) {
			setMeioPagamento(METODO_PAGAMENTO.CARTAO);
		}
	}, []);

	function renderDetalhesCard() {
		if (meioPagamento === METODO_PAGAMENTO.CARTAO) {
			if (!informacoesCartao?.numero) {
				return (
					<p
						className="detalhesCardStyle"
						style={
							planoAtual && planoAtual?.tipo === 'EXCLUSIVO' && planoAtual?.meioPagamento !== METODO_PAGAMENTO.CARTAO
								? { opacity: 0.5 }
								: { margin: '8px 0 0 0' }
						}
					>
						Não há cartão de crédito configurado. <br />
					</p>
				);
			}

			return (
				<div style={{ paddingTop: !isTablet && '8px', width: 'fit-content' }}>
					<p className="detalhesCardStyle">
						{`Número do cartão: ${numero}`} <br />
						{`Titular: ${titular}`} <br />
						{`Validade: ${expiracao}`} <br />
					</p>
				</div>
			);
		} else if (meioPagamento === METODO_PAGAMENTO.PIX) {
			if (
				(!isWeekBeforeNextPayment && !todayIsAfterDueDate) ||
				planoAtual?.tipo === 'GRATUITO' ||
				planoAtual?.valor === 0
			) {
				return (
					<p
						className="detalhesCardStyle"
						style={{ margin: '8px 0 0 0' }}
						title="A cobrança estará disponível uma semana antes do próximo pagamento"
					>
						Não há nenhuma cobrança pendente. <br />
					</p>
				);
			} else {
				return (
					<p className="detalhesCardStyle" style={{ paddingTop: !isTablet && '8px', width: 'fit-content' }}>
						{`A sua mensalidade com vencimento em ${format(dataProximoPagamento, 'dd/MM/yyyy')}, no valor de`}
						<br />
						{`R$ ${planoAtual?.valor.toFixed(2).split('.').join(',')}. já está disponível!`}
					</p>
				);
			}
		}

		return null;
	}

	function onHideModalPagarComPix() {
		setVisibleModalPagarComPix(false);
		atualizarDadosLocais();
	}

	return (
		<div
			className="cardStyle"
			style={{
				justifyContent: isTablet ? 'flex-start' : 'space-between',
				paddingBottom: '8px',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'start',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: isTablet ? 'row' : 'column',
						justifyContent: isTablet ? 'space-between' : 'flex-start',
					}}
				>
					<div>
						<div
							style={{
								marginBottom: '4px',
							}}
						>
							<RadioButton
								inputId="optionCartao"
								name="value"
								value={meioPagamento}
								onChange={() => setMeioPagamento(METODO_PAGAMENTO.CARTAO)}
								checked={meioPagamento === METODO_PAGAMENTO.CARTAO}
							/>
							<label htmlFor="optionCartao" className="p-radiobutton-label">
								Cartão crédito/débito
							</label>
						</div>
						<div>
							<RadioButton
								inputId="optionPix"
								name="value"
								value={meioPagamento}
								onChange={() => setMeioPagamento(METODO_PAGAMENTO.PIX)}
								checked={meioPagamento === METODO_PAGAMENTO.PIX}
							/>
							<label htmlFor="optionPix" className="p-radiobutton-label">
								Pix
							</label>
						</div>
					</div>
					<div
						style={{
							width: '100%',
							borderBottom: '1px solid #d0d0d0',
							marginLeft: '-4px',
							marginRight: '-4px',
							marginTop: '8px',
							display: isTablet && 'none',
						}}
					/>
					{renderDetalhesCard()}
					<br />
				</div>
			</div>
			<div
				style={{
					width: '100%',
					borderBottom: '1px solid #d0d0d0',
					margin: '8px -4px',
					display: (meioPagamento === METODO_PAGAMENTO.PIX || !isTablet) && 'none',
				}}
			/>
			<If test={meioPagamento === METODO_PAGAMENTO.CARTAO}>
				<Grid justifyAround style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
					<Button
						label={
							podeRemoverCartao
								? `Alterar${isDesktop && !isLessHd ? ' cartão' : ''}`
								: `Adicionar${isDesktop && !isLessHd ? ' cartão' : ''}`
						}
						onClick={onAlterarClick}
						icon={podeRemoverCartao ? 'fa fa-pencil' : 'pi pi-plus'}
						style={{ margin: '5px' }}
					/>
					<ButtonExcluir
						label={`Excluir${isDesktop && !isLessHd ? ' cartão' : ''}`}
						onClick={onRemoverCartaoClick}
						style={{ margin: '0' }}
						hidden={!podeRemoverCartao}
					/>
				</Grid>
			</If>
			<If
				test={
					meioPagamento === METODO_PAGAMENTO.PIX &&
					(isWeekBeforeNextPayment || todayIsAfterDueDate) &&
					planoAtual?.tipo !== 'GRATUITO' &&
					planoAtual?.valor !== 0
				}
			>
				<Grid
					justifyCenter
					verticalAlignEnd
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						marginTop: isTablet && '1.3rem',
					}}
				>
					<Button
						label="Pagar com Pix"
						onClick={() => setVisibleModalPagarComPix(true)}
						icon={<MdPix size={23} style={{ marginRight: '6px' }} />}
						style={{ margin: '5px' }}
					/>
				</Grid>
			</If>
			<ModalPagarComPix visible={visibleModalPagarComPix} onHide={onHideModalPagarComPix} />
		</div>
	);
}

export { CardMeuCartao };
