import { useRef, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import {
	buscarDadosLoginLocalStorage,	
	copiarObjeto,	
} from '../../../../../../../Common';
import {
	Button,
	Col,
	Grid,
	InputMoney,
	Modal,
	MultiSelectFiliais,
	NenhumRegistroEncontrado,
	Form,
	FormActions,
	FormContent,
	ButtonCancelar,
	ButtonSalvar,
	estadosBotaoSalvar,
	estadosBotaoCancelar,
	SingleSelectFilial,
} from '../../../../../../../components';

export default function FieldsPrecos({ values, setFieldValue, informacoesPermissoes }) {
	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(1);
	const [visibleModalReplicacao, setVisibleModalReplicacao] = useState(false);
	const [filialOrigem, setFilialOrigem] = useState(null);
	const [filiaisDestino, setFiliaisDestino] = useState([]);
	const [resetPesquisaFiliaisDestino, setResetPesquisaFiliaisDestino] = useState(false);

	const menuAlterarEmLote = useRef(null);
	const itensMenu = buscaritensMenu();

	const decimaisPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	function buscaritensMenu() {
		const res = [];
		const replicar = {
			label: 'Replicar preços para filiais',
			command: handleExibirModalReplicacao,
		};
		res.push(replicar);	
		return res;
	}	

	function onSort(element) {
		setSortField(element.sortField);
		setSortOrder(element.sortOrder);
	}

	function renderPrecoVenda(row, index){
		function changePrecoVenda(event){			
			const filial = copiarObjeto(row);
			filial.precoVenda = event.target.value ?? 0;
			setFieldValue(`filiais[${index}]`, filial);
		}

		return (			
			<span>
				<InputMoney
					name="precoVenda"
					value={row.precoVenda}
					onChange={changePrecoVenda}					
					allowNegative={false}
					decimalScale={decimaisPreco}
					{...informacoesPermissoes}
				/>
			</span>
		)		
	}


	function renderPrecoAtacado(row, index){
		function changeAtacado(event){			
			const filial = copiarObjeto(row);
			filial.precoAtacado = event.target.value ?? 0;
			setFieldValue(`filiais[${index}]`, filial);
		}

		return (			
			<span>
				<InputMoney
					name="precoAtacado"
					value={row.precoAtacado}
					onChange={changeAtacado}					
					allowNegative={false}
					decimalScale={decimaisPreco}
					{...informacoesPermissoes}
				/>
			</span>
		)		
	}

	function renderNomeFilial(row){
		return (`${row.filialCodigo} - ${row.filialNomeApresentacao}`);
	}

	function handleChangeFilialOrigem(event){
		setFilialOrigem(event);
		setFiliaisDestino(filiaisDestino.filter(item => item.value !== event?.value));
		setResetPesquisaFiliaisDestino(true);
	}

	function handleConfirmar(){
		 const valorPrecoVenda = values.filiais.filter(item => item.filialId === filialOrigem.value)[0].precoVenda ?? 0;
		 const valorPrecoAtacado = values.filiais.filter(item => item.filialId === filialOrigem.value)[0].precoAtacado ?? 0;
		const filiais = copiarObjeto(values.filiais);
		filiais.forEach(item => {		
			const filial = item;	
			if (filiaisDestino.filter(item => item.value === filial.filialId).length > 0){
				filial.precoVenda = valorPrecoVenda;
				filial.precoAtacado =valorPrecoAtacado;
			}				
		});			
		setFieldValue(`filiais`, filiais);
		setVisibleModalReplicacao(false);
	}

	function handleExibirModalReplicacao(){
		setFilialOrigem(null);
		setFiliaisDestino([]);
		setVisibleModalReplicacao(true);
	}

	function filtrarOptionsFilialDestino(options){		
		return options.filter(item => item.value !== filialOrigem?.value);
	}

	return (
		<>			
			<Grid>
				<Col>
					<Fieldset className="fieldset">
						<Grid>
							<Col sm="8" md="9" lg="10" xl="10"/>	
							<Col sm="4" md="3" lg="2" xl="2">	
								<Button
									className="p-button-primary step-tabela-preco-alterar-lote"
									label="Alterar em lote"
									icon="fa fa-wrench"
									style={{ width: '100%' }}
									aria-controls="popup_menu"
									aria-haspopup
									disabled={values.filiais.length === 1}
									onClick={(event) => {										
										menuAlterarEmLote.current.toggle(event)
									}}			
								/>
							</Col>
							<DataTable
								className="table"
								rowClassName="table-row"
								cellClassName="table-row-cell"
								responsive
								value={values.filiais}
								sortField={sortField}
								sortOrder={sortOrder}
								onSort={onSort}
								emptyMessage={<NenhumRegistroEncontrado />}
								style={{ width: '100%' }}
							>
								<Column
									field="filialCodigo"
									header="Filial"
									body={renderNomeFilial}
									style={{ width: '70%' }}
									sortable
								/>
								<Column
									field="precoVenda"
									header="Preço venda"
									body={(row, index) => renderPrecoVenda(row, index.rowIndex)}
									style={{ width: '15%' }}
									sortable
								/>
								<Column
									field="precoAtacado"
									header="Preço atacado"
									body={(row, index) => renderPrecoAtacado(row, index.rowIndex)}
									style={{ width: '15%' }}
									sortable
								/>
							</DataTable>
						</Grid>
					</Fieldset>
				</Col>
			</Grid>
			<Menu model={itensMenu} style={{ minWidth: '255px' }} popup ref={menuAlterarEmLote} />		
			<Modal
				closeOnEsc
				showCloseIcon
				visible={visibleModalReplicacao}
				onHide={() => setVisibleModalReplicacao(false)}
				header="Replicar preços para filiais"				
			>		
				<Form>
					<FormActions>
						<ButtonCancelar
							estadoBotao={estadosBotaoCancelar.VOLTAR}
							onClick={() => setVisibleModalReplicacao(false)}
							{...informacoesPermissoes}
						/>
						<ButtonSalvar 
							estadoBotao={estadosBotaoSalvar.CONFIRMAR} 
							onClick={handleConfirmar} 
							disabled={filiaisDestino.length === 0}
							{...informacoesPermissoes} 
						/>
					</FormActions>
					<FormContent>	
						<Grid>
							<Col>
								<SingleSelectFilial
									label="Filial de origem"
									value={filialOrigem}
									onChange={handleChangeFilialOrigem}
									style={{ width: '100%' , backgroundColor: 'fff' }}
								/>
							</Col>
							<Col>	
								<MultiSelectFiliais
									label="Filial(is) de destino"
									style={{ width: '100%' }}
									value={filiaisDestino}
									disabled={!filialOrigem}
									onChange={setFiliaisDestino}
									onlyUserFiliais
									resetPesquisa={resetPesquisaFiliaisDestino}
									setResetPesquisa={setResetPesquisaFiliaisDestino}		
									converterOptions={filtrarOptionsFilialDestino}							
								/>
							</Col>		
						</Grid>
					</FormContent>		
				</Form>	
			</Modal>
		</>	
	);
}
