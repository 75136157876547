import { montarLabelPessoa } from 'components/select/SingleSelectPessoa/Util';
import { formatarPlacaParaApi } from './function';

function converterVeiculoParaFormulario(veiculo) {
	let veiculoConvertido = {
		id: veiculo.id ?? null,
		renavam: veiculo.renavam,
		tipo: veiculo.tipo,
		situacao: veiculo.situacao,
		tara: veiculo.tara,
		capacidade: veiculo.capacidade,
		condutores: veiculo.condutores,
		reboques: veiculo.reboques,
		carroceria: veiculo.carroceria,
		rntrc: veiculo.rntrc,
		rodado: veiculo.rodado,
		placa: veiculo.placa,
		proprietario: veiculo.proprietario
			? {
					label: montarLabelPessoa(veiculo.proprietario),
					value: veiculo.proprietario.id,
					registro: veiculo.proprietario,
				}
			: null,
		uf: veiculo.uf
			? {
					label: veiculo.uf.nome + ' - ' + veiculo.uf.sigla,
					value: veiculo.uf.id,
					registro: veiculo.uf,
				}
			: null,
	};

	return veiculoConvertido;
}

function converterVeiculoParaApi(veiculo) {
	let veiculoConvertido = {
		id: veiculo.id ?? null,
		renavam: veiculo.renavam,
		tipo: veiculo.tipo,
		situacao: veiculo.situacao,
		tara: veiculo.tara,
		capacidade: veiculo.capacidade,
		condutores: veiculo.condutores,
		reboques: veiculo.reboques,
		carroceria: veiculo.carroceria,
		rntrc: veiculo.rntrc,
		rodado: veiculo.tipo === 'TRACAO' ? veiculo.rodado : null,
		placa: veiculo.placa ? formatarPlacaParaApi(veiculo.placa) : null,
		proprietario: veiculo.proprietario?.registro ?? null,
		uf: veiculo.uf?.registro,
	};

	return veiculoConvertido;
}

export { converterVeiculoParaFormulario, converterVeiculoParaApi };
