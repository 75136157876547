import {
	ButtonCancelar,
	LayoutMenuSuperior,
	MenuSuperior,
	Modal,
	NenhumRegistroEncontrado,
	Paginacao,
	estadosBotaoCancelar,
} from 'components';
import { format, parseJSON } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';

function ModalVisualizarHistorico({ visible, onHide, totalElements, registros, atualizarInformacoesHistorico }) {
	const [sortField, setSortField] = useState('receivedDate');
	const [sortOrder, setSortOrder] = useState(-1);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState(10);

	useEffect(() => {
		atualizarInformacoesHistorico(page, sortField, sortOrder, rows);
	}, [page, rows, sortField, sortOrder]);

	function onPageChange(e) {
		setPage(e.page);
		setRows(e.rows);
	}

	function renderData(row) {
		return <strong>{format(parseJSON(row.data), 'dd/MM/yyyy')}</strong>;
	}

	function renderVencimento(row) {
		return format(parseJSON(row.vencimento), 'dd/MM/yyyy');
	}

	function renderValor(row) {
		if (row.valor) {
			return `R$ ${(row.valor / 100).toFixed(2).split('.').join(',')}`;
		} else {
			return '';
		}
	}

	function renderStatus(row) {
		if (row.status === 'Pagamento confirmado e finalizado') {
			return (
				<>
					<i className="fa fa-check" style={{ color: 'green' }} />
					&nbsp;&nbsp;&nbsp;{row.status}
				</>
			);
		} else {
			return (
				<>
					<i className="fa fa-close" style={{ color: 'red' }} />
					&nbsp;&nbsp;&nbsp;{row.status}
				</>
			);
		}
	}

	function renderFormaPagamento(row) {
		if (row.formaPagamento === 'PIX') {
			return 'Pix';
		} else if (row.formaPagamento === 'BOLETO') {
			return 'Boleto';
		}
		return 'Cartão';
	}

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	return (
		<Modal header="Histórico de pagamentos" visible={visible} onHide={onHide}>
			<MenuSuperior isModal>
				<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
			</MenuSuperior>
			<LayoutMenuSuperior isModal>
				<DataTable
					className="table"
					responsive
					value={registros}
					sortField={sortField}
					sortOrder={sortOrder}
					onSort={atualizarOrdenacao}
					emptyMessage={<NenhumRegistroEncontrado />}
				>
					<Column field="receivedDate" header="Data" body={renderData} sortable />
					<Column field="capturedDate" header="Vencimento" body={renderVencimento} sortable />
					<Column field="amount" header="Valor" body={renderValor} sortable />
					<Column field="formaPagamento" header="Método" body={renderFormaPagamento} />
					<Column field="status" header="Descrição" body={renderStatus} />
				</DataTable>
				<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
			</LayoutMenuSuperior>
		</Modal>
	);
}

export { ModalVisualizarHistorico };
