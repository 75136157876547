import { del, get, post, put, services } from 'Common';

export async function getRegioesVendaResumo(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function getRegiaoVenda(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/vendas/regiao/${id}`, null, onSuccess, onError);
}

export async function salvarRegiao(regiao, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/vendas/regiao`, regiao, null, onSuccess, onError);
}

export async function atualizarRegiao(id, regiao, onSuccess, onError) {
	return await put(`${services.GESTOR}/v1/vendas/regiao/${id}`, regiao, null, onSuccess, onError);
}

export async function deleteRegiaoVenda(id, onSuccess, onError) {
	return await del(`${services.GESTOR}/v1/vendas/regiao/${id}`, null, onSuccess, onError);
}

export async function getMunicipiosRegiaoVenda(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function inserirMunicipiosDoEstado(idRegiao, idEstado, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/regiao/${idRegiao}/estado/${idEstado}/inserir`,
		null,
		onSuccess,
		onError
	);
}

export async function excluirMunicipiosDoEstado(idRegiao, idEstado, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/regiao/${idRegiao}/estado/${idEstado}/excluir`,
		null,
		onSuccess,
		onError
	);
}

export async function inserirMunicipio(idRegiao, idMunicipio, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/regiao/${idRegiao}/municipio/${idMunicipio}/inserir`,
		null,
		onSuccess,
		onError
	);
}

export async function excluirMunicipio(idRegiao, idMunicipioRegiao, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/vendas/regiao/${idRegiao}/municipio/${idMunicipioRegiao}/excluir`,
		null,
		onSuccess,
		onError
	);
}
