import { actionTypes } from '../../../Common/Constantes/reduxTypes';
import { estadosCadastro } from '../../../Common/Constantes/autorizacao';

export default (state = {}, action) => {
	switch (action.type) {
		case actionTypes.ESTADO_FORMULARIO: {
			return {
				...state,
				estado: action.value ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
			};
		}
		default: {
			return state;
		}
	}
};
