import { Col, If } from 'components';
import { useFormikContext } from 'formik';
import { RadioButton } from 'primereact/radiobutton';
import { TIPO_CONTA_PAGAR } from 'views/financas/ContasPagar/Util/constantes';
import { CONDICAO_PAGAMENTO_TAXA_TIPO } from '../../Util/constantes';

function FormTipoContaPagar({ isReparcelamento }) {
	const { values, setFieldValue } = useFormikContext();

	function handleChangeTipo(value) {
		setFieldValue('tipo', value);

		if (values.tipoTaxa === CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL && values.taxaAliquota) {
			if (value === TIPO_CONTA_PAGAR.UNICA || value === TIPO_CONTA_PAGAR.VALOR_FIXO) {
				setFieldValue('taxaValor', (values.valor * values.taxaAliquota) / 100);
			} else if (value === TIPO_CONTA_PAGAR.VALOR_PARCELADO) {
				const valor = values.valor / values.quantidadeRepeticoes;
				setFieldValue('taxaValor', (valor * values.taxaAliquota) / 100);
			}
		}
	}

	return (
		<Col style={{ width: '100%', display: 'contents' }}>
			<Col sm="12" md="4" lg="4" xl="4">
				<RadioButton
					inputId="rb-tipo-valor-unico"
					name="tipo"
					value={TIPO_CONTA_PAGAR.UNICA}
					onChange={(e) => handleChangeTipo(e.value)}
					checked={values.tipo === TIPO_CONTA_PAGAR.UNICA}
				/>
				<label htmlFor="rb-tipo-valor-unico" className="p-radiobutton-label">
					É uma conta única
				</label>
			</Col>
			<If test={!isReparcelamento}>
				<Col sm="12" md="4" lg="4" xl="4">
					<RadioButton
						inputId="rb-tipo-valor-fixo"
						name="tipo"
						value={TIPO_CONTA_PAGAR.VALOR_FIXO}
						onChange={(e) => handleChangeTipo(e.value)}
						checked={values.tipo === TIPO_CONTA_PAGAR.VALOR_FIXO}
					/>
					<label htmlFor="rb-tipo-valor-fixo" className="p-radiobutton-label">
						É uma conta recorrente
					</label>
				</Col>
			</If>
			<Col sm="12" md="4" lg="4" xl="4">
				<RadioButton
					inputId="rb-tipo-valor-parcelado"
					name="tipo"
					value={TIPO_CONTA_PAGAR.VALOR_PARCELADO}
					onChange={(e) => handleChangeTipo(e.value)}
					checked={values.tipo === TIPO_CONTA_PAGAR.VALOR_PARCELADO}
				/>
				<label htmlFor="rb-tipo-valor-parcelado" className="p-radiobutton-label">
					É uma conta parcelada
				</label>
			</Col>
			<If test={isReparcelamento}>
				<Col sm="12" md="4" lg="4" xl="4">
					<div>{undefined}</div>
				</Col>
			</If>
		</Col>
	);
}

export { FormTipoContaPagar };
