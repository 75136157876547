import { get, post, services } from 'Common';

export async function asyncGetProximoIdentificador(onSuccess, onError) {
	return get(`${services.GESTOR}/v1/contas_receber/proximo_identificador`, null, onSuccess, onError);
}

export async function buscarFormaPagamentoDinheiroContaReceber(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_receber/relacoes/formas_pagamento?query=formaPagamentoFiscal=="DINHEIRO"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarFormaPagamentoDinheiroContaPagar(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_pagar/relacoes/formas_pagamento?query=formaPagamentoFiscal=="DINHEIRO"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarCategoriaFavoritaReceitasContaReceber(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_receber/relacoes/categorias/receitas?query=favoritaReceita=="true";ativa=="true"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarCategoriaFavoritaDespesasContaPagar(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_pagar/relacoes/categorias/despesas?query=favoritaDespesa=="true";ativa=="true"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarContaFavoritaReceitasContaReceber(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_receber/relacoes/contas?query=favoritaReceita=="true";situacao=="ATIVA"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarContaFavoritaDespesasContaPagar(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_pagar/relacoes/contas?query=favoritaDespesa=="true";situacao=="ATIVA"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncCreateReparcelamentoContasReceber(
	novoTitulo,
	idsTitulosAntigos,
	valorJuros,
	valorMulta,
	valorDesconto,
	onSuccess,
	onError
) {
	await post(
		`${services.GESTOR}/v1/contas_receber/reparcelar_titulos?valorJuros=${valorJuros}&valorMulta=${valorMulta}&valorDesconto=${valorDesconto}&valoresTitulos=${idsTitulosAntigos}`,
		novoTitulo,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncCreateReparcelamentoContasPagar(
	novoTitulo,
	idsTitulosAntigos,
	valorJuros,
	valorMulta,
	valorDesconto,
	onSuccess,
	onError
) {
	await post(
		`${services.GESTOR}/v1/contas_pagar/reparcelar_titulos?valorJuros=${valorJuros}&valorMulta=${valorMulta}&valorDesconto=${valorDesconto}&valoresTitulos=${idsTitulosAntigos}`,
		novoTitulo,
		null,
		onSuccess,
		onError,
		false
	);
}
