import { useFormikContext } from 'formik';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef } from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { formatarMonetario } from '../../../../../../Common';
import { Divider } from '../../../../../../components';
import './Styles/index.css';

function FormFieldsTotalizadores({ totalizadores, colorStatus, isMobile }) {
	const { values } = useFormikContext();
	const menuImpostos = useRef();

	const styleTotal = {
		fontSize: 20,
		fontWeight: 'bold',
		color: colorStatus,
	};

	const styleLineTotais = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0.2rem',
	};

	function renderImpostos() {
		return (
			<table className="table-totais" style={{ width: '100%' }}>
				<tbody>
					<tr>
						<td style={{ ...styleLineTotais, fontSize: '14px' }}>
							<span>Base de cálculo de ICMS</span>
							<span>{formatarMonetario(totalizadores.baseCalculoIcms)}</span>
						</td>
					</tr>
					<tr>
						<td style={{ ...styleLineTotais, fontSize: '14px' }}>
							<span>Valor de ICMS</span>
							<span>{formatarMonetario(totalizadores.valorIcms)}</span>
						</td>
					</tr>
					<tr>
						<td style={{ ...styleLineTotais, fontSize: '14px' }}>
							<span>Base de cálculo de ICMS ST</span>
							<span>{formatarMonetario(totalizadores.baseCalculoIcmsSt)}</span>
						</td>
					</tr>
					<tr>
						<td style={{ ...styleLineTotais, fontSize: '14px' }}>
							<span>Valor de ICMS ST</span>
							<span>{formatarMonetario(totalizadores.valorIcmsSt)}</span>
						</td>
					</tr>
					<tr>
						<td style={{ ...styleLineTotais, fontSize: '14px' }}>
							<span>Base de cálculo do IPI</span>
							<span>{formatarMonetario(totalizadores.baseCalculoIpi)}</span>
						</td>
					</tr>
					<tr>
						<td style={{ ...styleLineTotais, fontSize: '14px' }}>
							<span>Valor do IPI</span>
							<span>{formatarMonetario(totalizadores.valorIpi)}</span>
						</td>
					</tr>
					<tr>
						<td style={{ ...styleLineTotais, fontSize: '14px' }}>
							<span>Base de cáculo PIS</span>
							<span>{formatarMonetario(totalizadores.baseCalculoPis)}</span>
						</td>
					</tr>
					<tr>
						<td style={{ ...styleLineTotais, fontSize: '14px' }}>
							<span>Valor do PIS</span>
							<span>{formatarMonetario(totalizadores.valorPis)}</span>
						</td>
					</tr>
					<tr>
						<td style={{ ...styleLineTotais, fontSize: '14px' }}>
							<span>Base de cáculo COFINS</span>
							<span>{formatarMonetario(totalizadores.baseCalculoCofins)}</span>
						</td>
					</tr>
					<tr>
						<td style={{ ...styleLineTotais, fontSize: '14px' }}>
							<span>Valor do COFINS</span>
							<span>{formatarMonetario(totalizadores.valorCofins)}</span>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	return (
		<>
			<div
				style={{
					display: isMobile ? 'block' : 'grid',
					gridTemplateColumns: '1fr min-content 1fr',
					paddingBottom: '0.5rem',
				}}
			>
				<div>
					<div style={{ ...styleLineTotais }}>
						<span>Valor do frete</span>
						<span>{formatarMonetario(values.totais.valorFrete)}</span>
					</div>

					<div style={{ ...styleLineTotais }}>
						<span>Valor do seguro</span>
						<span>{formatarMonetario(values.totais.valorSeguro)}</span>
					</div>

					<div style={{ ...styleLineTotais }}>
						<span>Valor acessórias</span>
						<span>{formatarMonetario(values.totais.valorAcessorias)}</span>
					</div>

					<div style={{ ...styleLineTotais }}>
						<span>Valor de desconto</span>
						<span>-{formatarMonetario(values.totais.valorDesconto)}</span>
					</div>
				</div>
				<div>
					<Divider orientation={isMobile ? 'horizontal' : 'vertical'} />
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
					<div>
						<div style={{ ...styleLineTotais }}>
							<span>Valor dos produtos</span>
							<span>{formatarMonetario(totalizadores.totalProdutos)}</span>
						</div>

						<div style={styleLineTotais}>
							<span
								style={{ display: 'flex', alignItems: 'self-end', cursor: 'pointer' }}
								onClick={(e) => menuImpostos.current?.toggle(e)}
								className="label-totais"
							>
								Valor dos tributos <IoMdInformationCircleOutline style={{ margin: '0 0 1px 5px' }} />
							</span>
							<span>{formatarMonetario(totalizadores.totalImpostos)}</span>
						</div>
					</div>
					<Divider styleContainer={{ position: 'relative', paddingTop: '0.5rem' }} />
					<div
						style={{
							...styleLineTotais,
							...styleTotal,
						}}
					>
						<span>Valor total</span>
						<span>{formatarMonetario(totalizadores.totalNota)}</span>
					</div>
				</div>
			</div>
			<OverlayPanel
				style={{ marginLeft: isMobile ? '5%' : null, width: isMobile ? '90%' : '400px' }}
				ref={menuImpostos}
				showCloseIcon
			>
				{renderImpostos()}
			</OverlayPanel>
		</>
	);
}

export default FormFieldsTotalizadores;
