import { post, exibirToast } from "../../../../../Common/Requisicoes";
import { services } from "../../../../../Common/Constantes/api";

export async function asyncAtualizarSenha(data, onSuccess, onError) {
  return await post(
    services.GESTOR + "/v1/credenciais/alterar_senha",
    data,
    null,
    exibirToast(onSuccess, "Senha atualizada com sucesso!"),
    onError
  );
}
