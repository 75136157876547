import { get, del, post, put, exibirToast } from '../../../../../../Common/Requisicoes';
import { services } from '../../../../../../Common/Constantes/api';

export async function asyncGetRegistro(idConta, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/contas/${idConta}`, null, onSuccess, onError);
}

export async function asyncDeleteRegistro(idConta, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/contas/${idConta}`,
		null,
		exibirToast(onSuccess, 'Conta removida com sucesso'),
		onError
	);
}

export async function asyncUpdateRegistro(data, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/contas/${data.id}`,
		data,
		null,
		exibirToast(onSuccess, 'Conta atualizada com sucesso'),
		onError
	);
}

export async function asyncCreateRegistro(data, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/contas`, data, null, exibirToast(onSuccess, 'Conta criada com sucesso'), onError);
}

export async function asyncAtualizarSaldoInicial(idConta, data, onSuccess, onError) {
	await put(`${services.GESTOR}/v1/contas/${idConta}/atualizar_saldo_inicial`, data, null, onSuccess, onError);
}

export async function asyncGetContaMesmoNome(nome, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/contas?query=nome=="${nome}"`, null, onSuccess, onError);
}

export async function asyncGetContaJaExistente(numero, agencia, codigoBeneficiario, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas?query=(numeroConta=="${numero}";numeroAgencia=="${agencia}";codigoBeneficiario=="${codigoBeneficiario}";situacao=="ATIVA";(tipo=="CORRENTE",tipo=="POUPANCA"))`,
		null,
		onSuccess,
		onError
	);
}

export async function asyncEnvioCertificadoConvenio(idConta, idConvenio, arquivo, senha, onSuccess, onError) {
	const formData = new FormData();
	formData.append('arquivo', arquivo);
	formData.append('senha', senha);

	await post(
		`${services.GESTOR}/v1/contas/${idConta}/convenios/${idConvenio}/certificado`,
		formData,
		{ headers: { 'content-type': 'multipart/form-data' } },
		onSuccess,
		onError
	);
}
