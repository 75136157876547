export const tutorialStepsPedidoVenda = [
	{
		target: 'body',
		content: `Este é o cadastro de pedidos de venda`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-venda-e-orcamento-cliente',
		content: 'Nesta seção é possível informar os campos do cabeçalho do pedido de venda',
		placement: 'top',
	},
	{
		target: '.step-venda-e-orcamento-produtos',
		content: 'E nesta área você pode gerenciar os produtos deste pedido de venda',
	},
	{
		target: '.step-venda-e-orcamento-totalizadores',
		content:
			'Por fim, nesta aba estão os totalizadores do pedido, além disso, nas demais abas, podem ser alterados as informações de pagamentos, vendedor e complementares.',
	},
];

export const tutorialStepsOrcamento = [
	{
		target: 'body',
		content: `Este é o cadastro de orçamentos`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-venda-e-orcamento-cliente',
		content: 'Nesta seção é possível informar os campos do cabeçalho do orçamento',
		placement: 'top',
	},
	{
		target: '.step-venda-e-orcamento-produtos',
		content: 'E nesta área você pode gerenciar os produtos deste orçamento',
	},
	{
		target: '.step-venda-e-orcamento-totalizadores',
		content:
			'Por fim, nesta aba estão os totalizadores do orçamento, além disso, nas demais abas, podem ser alterados as informações de pagamentos, vendedor e complementares.',
	},
];
