import { permissoes, recursos } from 'Common';
import { Switch, withRouter } from 'react-router-dom';
import PrivateRoute from 'components/Route';

import AssinaturaEletronica from 'views/documentos/AssinaturaEletronica';
import AssinaturaEletronicaForm from 'views/documentos/AssinaturaEletronica/Form';

import GestaoDocumentosForm from 'views/documentos/GestaoDocumento/Form';
import GestaoDocumentos from 'views/documentos/GestaoDocumento';

import DocumentosRelatorios from 'views/documentos/Relatorios';

export const pathDocumentosRoutes = [
	'/documentos/assinatura',
	'/documentos/assinatura/cadastro',
	'/documentos/assinatura/cadastro/:id',
	'/documentos/assinatura/importar_pedido/:id',
	'/documentos/gestao_documentos',
	'/documentos/gestao_documentos/cadastro',
	'/documentos/gestao_documentos/cadastro/:id',
	'/documentos/relatorios',
	'/relatorios_documentos',
];

function DocumentosRoutes() {
	return (
		<Switch>
			<PrivateRoute
				exact
				path="/documentos/assinatura"
				render={(props) => <AssinaturaEletronica {...props} />}
				recurso={recursos.DOCUMENTOS_ASSINATURA}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/documentos/assinatura/cadastro', '/documentos/assinatura/cadastro/:id']}
				render={(props) => <AssinaturaEletronicaForm {...props} />}
				recurso={recursos.DOCUMENTOS_ASSINATURA}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/documentos/assinatura/importar_pedido/:id']}
				render={(props) => <AssinaturaEletronicaForm {...props} />}
				recurso={recursos.DOCUMENTOS_ASSINATURA}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/documentos/gestao_documentos"
				render={(props) => <GestaoDocumentos {...props} />}
				recurso={recursos.GESTAO_DOCUMENTOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/documentos/gestao_documentos/cadastro', '/documentos/gestao_documentos/cadastro/:id']}
				component={GestaoDocumentosForm}
				recurso={recursos.GESTAO_DOCUMENTOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/documentos/relatorios"
				render={(props) => <DocumentosRelatorios {...props} />}
				recurso={recursos.DOCUMENTOS_RELATORIOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/relatorios_documentos']}
				render={(props) => <DocumentosRelatorios {...props} />}
				recurso={recursos.DOCUMENTOS_RELATORIOS}
				permissao={permissoes.VISUALIZAR}
			/>
		</Switch>
	);
}

export default withRouter(DocumentosRoutes);
