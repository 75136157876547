import { useFormikContext } from 'formik';

import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	ToastTypes,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	notify,
} from 'components';

import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { useContextVendedores } from 'views/cadastros/Vendedores/Context';
import {
	atualizarVendedor,
	deletarVendedor,
	getUsuarioPadrao,
	salvarVendedor,
} from 'views/cadastros/Vendedores/Requests';

import { asyncGetFiliaisDoUsuario } from 'views/configuracao/Usuarios/Form/Requests';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../../../Util';
import { INITIAL_VALUES_VENDEDORES } from '../../Utils/constantes';
import { converterVendedorFormParaApi } from '../../Utils/vendedorConverter';

const CADASTROURL = '/vendas/vendedores/cadastro';
const PESQUISAURL = '/vendas/vendedores';

export default function ActionButtons({ isModal, hideModal }) {
	const { values, dirty, resetForm, handleReset, validateForm, handleSubmit } = useFormikContext();
	const { history, informacoesPermissoes, usuarioPadrao, setUsuarioPadrao } = useContextVendedores();

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const onClickNovo = dirty ? () => salvar(novo) : novo;

	async function salvar(novoOnSuccess) {
		handleSubmit();

		if (await validarFormulario({ validateForm, values })) {
			try {
				if (!values.id) {
					salvarVendedor(converterVendedorFormParaApi(values), ({ data: vendedor }) => {
						if (typeof novoOnSuccess === 'function') {
							novoOnSuccess();
						}
						if (!isModal) {
							atualizarUrl(history, CADASTROURL, vendedor.id, metodosAtualizarUrl.PUSH);
						} else {
							closeModal({ ...values, id: vendedor.id });
						}
					});
				} else {
					atualizarVendedor(values.id, converterVendedorFormParaApi(values), () => {
						resetForm({ values: values });

						if (typeof novoOnSuccess === 'function') {
							novoOnSuccess();
						}
					});
				}
			} catch (error) {
				console.error(error);
			}
		}
	}

	async function novo() {
		const filiais = await buscarFiliaisUsuario();
		resetForm({ values: { ...INITIAL_VALUES_VENDEDORES, filiais: filiais } });
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.PUSH);

		if (!usuarioPadrao) {
			getUsuarioPadrao(({ data: response }) => {
				setUsuarioPadrao(response?.content[0]);
			});
		}
	}

	function cancelar() {
		if (dirty) {
			handleReset();
		} else if (isModal) {
			closeModal();
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
	}

	function closeModal(dados = null) {
		if (dados) {
			hideModal(dados);
		} else {
			hideModal();
		}
	}

	function handleClickDeletar() {
		confirmarExclusao(() => excluirRegistro());
	}

	async function excluirRegistro() {
		if (!values.id) return;
		await deletarVendedor(values.id, () => {
			voltarParaAPesquisa(history, PESQUISAURL);
			notify('Vendedor removido com sucesso', ToastTypes.SUCCESS);
		});
	}

	async function buscarFiliaisUsuario() {
		const filiaisConvertidas = [];
		await asyncGetFiliaisDoUsuario(({ data: body }) => {
			const filiaisUsuario = body.content;

			filiaisUsuario?.forEach((filial) => {
				filiaisConvertidas.push({
					id: null,
					filial: {
						id: filial.id,
						codigo: filial.codigo,
						nome: filial.nome,
						nomeApresentacao: filial.nomeApresentacao,
					},
					tabelaPreco: null,
				});
			});
		});

		return filiaisConvertidas;
	}

	return (
		<>
			<ButtonCancelar onClick={cancelar} estadoBotao={estadoBotaoCancelar} />
			<ButtonSalvar onClick={() => salvar(null)} disabled={!dirty} />
			<ButtonNovo
				onClick={onClickNovo}
				estadoBotao={estadoBotaoNovo}
				hidden={(!values.id && !dirty) || isModal}
				disabled={!informacoesPermissoes.podeInserir}
			/>
			<ButtonExcluir
				onClick={handleClickDeletar}
				hidden={!values.id || isModal}
				disabled={!informacoesPermissoes.podeExcluir || dirty}
			/>
		</>
	);
}
