import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';

import { TabView } from 'components';

import { useContextTabelaPreco } from 'views/cadastros/TabelaPreco/Context';
import { ClassNameTabViewColor } from 'views/cadastros/TabelaPreco/Util/constantes';

import { AlteracaoPorCondicaoPagamento } from './components/AlteracaoPorCondicaoPagamento';
import { DescontoPorQuantidade } from './components/DescontoPorQuantidade';
import { TabelaPrecoProdutos } from './components/TabelaPrecoProdutos';
import { Configuracoes } from './components/Configuracoes';

function TabsTabelaPreco({ visibleModalReplicarProdutosOutrasTabelas, setVisibleModalReplicarProdutosOutrasTabelas }) {
	const { values, setFieldValue, resetForm, setValues } = useFormikContext();
	const {
		activeTabIndex,
		setActiveTabIndex,
		utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao,
		utilizaTabelaPrecoDescontoQuantidade,
	} = useContextTabelaPreco();

	return (
		<TabView
			className={`tab-view-no-border ${ClassNameTabViewColor[values.situacao]}`}
			activeIndex={activeTabIndex}
			onTabChange={({ index }) => setActiveTabIndex(index)}
			renderActiveOnly={false}
		>
			<TabPanel header="Produtos">
				<TabelaPrecoProdutos
					cabecalhoTabelaPreco={values}
					setFieldValueForm={setFieldValue}
					valuesForm={values}
					resetFormPrincipal={resetForm}
					setValuesForm={setValues}
					visibleModalReplicarProdutosOutrasTabelas={visibleModalReplicarProdutosOutrasTabelas}
					setVisibleModalReplicarProdutosOutrasTabelas={setVisibleModalReplicarProdutosOutrasTabelas}
				/>
			</TabPanel>
			<TabPanel
				header="Percentual por condição de pagamento"
				disabled={!utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao}
			>
				<AlteracaoPorCondicaoPagamento />
			</TabPanel>
			<TabPanel header="Desconto por quantidade" disabled={!utilizaTabelaPrecoDescontoQuantidade}>
				<DescontoPorQuantidade />
			</TabPanel>
			<TabPanel header="Configurações">
				<Configuracoes />
			</TabPanel>
		</TabView>
	);
}

export { TabsTabelaPreco };
