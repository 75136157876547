import { InputDouble } from "components";
import { Field } from "formik";
import { HelpTributacaoMunicipalForm } from "../../../Help";

export function FieldsModalImpostosTributacaoMunicipal({
  values,
  setFieldValue,
  validacaoNovaTributacao,
}) {
  return (
    <>
      <Field
        sm="12"
        md="3"
        lg="3"
        xl="3"
        component={InputDouble}
        label="Base ISS"
        name="issBase"
        value={values.issBase}
        onChange={(event) => setFieldValue("issBase", event.target.value)}
        suffix="%"
        allowNegative={false}
        maxValue={100}
        disabled={validacaoNovaTributacao}
        helpMessage={HelpTributacaoMunicipalForm.issBase}
      />
      <Field
        sm="12"
        md="3"
        lg="3"
        xl="3"
        component={InputDouble}
        label="% ISS"
        name="issPercentual"
        value={values.issPercentual}
        onChange={(event) => setFieldValue("issPercentual", event.target.value)}
        suffix=" %"
        allowNegative={false}
        maxValue={100}
        disabled={validacaoNovaTributacao}
        helpMessage={HelpTributacaoMunicipalForm.issPercentual}
      />
      <Field
        sm="12"
        md="3"
        lg="3"
        xl="3"
        component={InputDouble}
        label="Base IR"
        name="irBase"
        value={values.irBase}
        onChange={(event) => setFieldValue("irBase", event.target.value)}
        suffix=" %"
        allowNegative={false}
        maxValue={100}
        disabled={validacaoNovaTributacao}
        helpMessage={HelpTributacaoMunicipalForm.irBase}
      />
      <Field
        sm="12"
        md="3"
        lg="3"
        xl="3"
        component={InputDouble}
        label="% IR"
        name="irPercentual"
        value={values.irPercentual}
        onChange={(event) => setFieldValue("irPercentual", event.target.value)}
        suffix=" %"
        allowNegative={false}
        maxValue={100}
        disabled={validacaoNovaTributacao}
        helpMessage={HelpTributacaoMunicipalForm.irPercentual}
      />
      <Field
        sm="12"
        md="3"
        lg="3"
        xl="3"
        component={InputDouble}
        label="Base CSLL"
        name="csllBase"
        value={values.csllBase}
        onChange={(event) => setFieldValue("csllBase", event.target.value)}
        suffix=" %"
        allowNegative={false}
        maxValue={100}
        disabled={validacaoNovaTributacao}
        helpMessage={HelpTributacaoMunicipalForm.csllBase}
      />
      <Field
        sm="12"
        md="3"
        lg="3"
        xl="3"
        component={InputDouble}
        label="% CSLL"
        name="csllPercentual"
        value={values.csllPercentual}
        onChange={(event) =>
          setFieldValue("csllPercentual", event.target.value)
        }
        suffix=" %"
        allowNegative={false}
        maxValue={100}
        disabled={validacaoNovaTributacao}
        helpMessage={HelpTributacaoMunicipalForm.csllPercentual}
      />
      <Field
        sm="12"
        md="3"
        lg="3"
        xl="3"
        component={InputDouble}
        label="Base INSS"
        name="inssBase"
        value={values.inssBase}
        onChange={(event) => setFieldValue("inssBase", event.target.value)}
        suffix=" %"
        allowNegative={false}
        maxValue={100}
        disabled={validacaoNovaTributacao}
        helpMessage={HelpTributacaoMunicipalForm.inssBase}
      />
      <Field
        sm="12"
        md="3"
        lg="3"
        xl="3"
        component={InputDouble}
        label="% INSS"
        name="inssPercentual"
        value={values.inssPercentual}
        onChange={(event) =>
          setFieldValue("inssPercentual", event.target.value)
        }
        suffix=" %"
        allowNegative={false}
        maxValue={100}
        disabled={validacaoNovaTributacao}
        helpMessage={HelpTributacaoMunicipalForm.inssPercentual}
      />
    </>
  );
}
