import { isEstadoAdjacente, keyFilterConsultaRsql } from 'Common';
import {
	Col,
	Dropdown,
	DropdownMultiSelect,
	Grid,
	If,
	InputDate,
	InputField,
	LinkVerDados,
	SingleSelectEstado,
	SingleSelectMunicipio,
	SingleSelectVeiculo,
} from 'components';
import { Field, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { readEstados } from 'views/transporte/MDFe/Requests';
import { formatarPlaca } from 'views/cadastros/transporte/Veiculos/Util/function';
import { MDFE_TIPO_EMITENTE, MDFE_TIPO_EMITENTE_OPTIONS, MDFE_TIPO_TRANSPORTADOR_OPTIONS } from '../../Util/constantes';
import { isPercursoCompleta } from '../../Util/functions';
import { ModalDadosVeiculo } from './components/ModalDadosVeiculo';

function InformacoesPrincipais() {
	const { isSituacaoFinalMDFe, informacoesPermissoes } = useContextMDFe();
	const { values, errors, setFieldValue } = useFormikContext();

	const [optionsUfPercurso, setOptionsUfPercurso] = useState([]);
	const [estados, setEstados] = useState([]);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [visibleModalDadosVeiculo, setVisibleModalDadosVeiculo] = useState(false);

	const isCarregamentoDescarregamentoAdjacente =
		isEstadoAdjacente(values.ufCarregamento?.registro?.sigla, values.ufDescarregamento?.registro?.sigla) ||
		!values.ufCarregamento ||
		!values.ufDescarregamento;
	const hasPercursoCompleto = isPercursoCompleta(
		values.ufsPercurso,
		values.ufCarregamento?.registro?.sigla,
		values.ufDescarregamento?.registro?.sigla
	);
	const veiculoTracaoError =
		errors?.veiculoTracao?.veiculo ??
		errors?.veiculoTracao?.placa ??
		errors?.veiculoTracao?.renavam ??
		errors?.veiculoTracao?.uf ??
		errors?.veiculoTracao?.tara ??
		errors?.veiculoTracao?.capacidade ??
		errors?.veiculoTracao?.tracao ??
		errors?.veiculoTracao?.carroceria;

	useEffect(() => {
		fetchEstados();
	}, []);

	useEffect(() => {
		filterOptionsUfPercurso();
	}, [isMenuOpen]);

	function getUltimoEstadoPercurso() {
		return values.ufsPercurso?.length > 0 ? values.ufsPercurso.slice(-1)[0] : values.ufCarregamento?.registro;
	}

	async function fetchEstados() {
		await readEstados(({ data: states }) => {
			if (states.content) {
				const newStates = [];
				states.content.forEach(({ id, nome, sigla }) => {
					newStates.push({
						id,
						nome,
						sigla,
					});
				});
				setEstados(newStates);
			}
		});
	}

	function filterOptionsUfPercurso() {
		function convertToSelect(e) {
			return {
				label: `${e.nome} - ${e.sigla}`,
				value: e.id,
				sigla: e.sigla,
				item: null,
				id: null,
			};
		}

		if (estados) {
			const newOptions = [];
			const lastEstado = getUltimoEstadoPercurso();

			estados.forEach((state) => {
				const isUfCarregamentoOuDescarregamento =
					state.sigla === values.ufCarregamento?.registro?.sigla ||
					state.sigla === values.ufDescarregamento?.registro?.sigla;

				if (isEstadoAdjacente(lastEstado.sigla, state.sigla) && !isUfCarregamentoOuDescarregamento) {
					newOptions.push(convertToSelect(state));
				}
			});

			setOptionsUfPercurso(newOptions);
		}

		setIsMenuOpen(false);
	}

	function handleChangeUfPercurso(value, { action, removedValue }) {
		let newValue = value;

		function updateItem(value) {
			return value.map((estado, index) => {
				const newEstado = estado;
				if (!newEstado.item) {
					newEstado.item = index + 1;
				}
				newEstado.label = `${newEstado.item} - ${estado.sigla}`;
				return newEstado;
			});
		}

		function sortEstados(value) {
			return value.sort((a, b) => {
				if (a.item < b.item) {
					return -1;
				}
				if (a.item > b.item) {
					return 1;
				}
				return 0;
			});
		}

		if (action === 'select-option') {
			newValue = sortEstados(updateItem(value));
		} else if (action === 'remove-value') {
			newValue = value.filter((e) => e.item < removedValue.item);
		}

		setFieldValue('ufsPercurso', newValue);
	}

	function handleOpenMenuUfPercurso() {
		setIsMenuOpen(true);
	}

	function handleChangeVeiculo(e, setValues) {
		const veiculoTracao = {
			id: values.veiculoTracao ? values.veiculoTracao.id : null,
			veiculo: e,
			placa: formatarPlaca(e.registro.placa),
			renavam: e.registro.renavam,
			estado: {
				value: e.registro.uf?.value ? e.registro.uf?.value : e.registro.uf.id,
				label: e.registro.uf?.label ? e.registro.uf?.label : `${e.registro.uf.nome} - ${e.registro.uf.sigla}`,
				registro: e.registro.uf,
			},
			tara: e.registro.tara,
			capacidade: e.registro.capacidade,
			rodado: e.registro.rodado,
			carroceria: e.registro.carroceria,
			rntrc: e.registro.rntrc,
		};

		if (typeof setValues === 'function') {
			setValues(veiculoTracao);
		} else {
			setFieldValue('veiculoTracao', veiculoTracao);
		}

		if (!values.rntrc && veiculoTracao.rntrc?.length > 0) {
			setFieldValue('rntrc', veiculoTracao.rntrc);
		}

		setCondutores(e.registro?.condutores);
		setReboques(e.registro?.reboques);
	}

	function setCondutores(condutores) {
		if (condutores?.length > 0) {
			const newCondutores = [...values.condutores];
			condutores.forEach((condutor) => {
				const hasCondutorInValues = values.condutores?.filter((e) => e.idRegistro === condutor.id)?.length > 0;

				if (!hasCondutorInValues) {
					newCondutores.push({
						id: null,
						condutor,
						idRegistro: condutor.id,
						nome: condutor.nome,
						cpf: condutor.cpf,
					});
				}
			});
			setFieldValue('condutores', newCondutores);
		}
	}

	function setReboques(reboques) {
		if (reboques?.length > 0) {
			const newReboques = [...values.reboques];
			reboques.forEach((reboque) => {
				const hasReboqueInValues = values.reboques?.filter((e) => e.idRegistro === reboque.id)?.length > 0;

				if (!hasReboqueInValues) {
					newReboques.push({
						id: null,
						reboque,
						idRegistro: reboque.id,
						placa: formatarPlaca(reboque.placa),
						renavam: reboque.renavam,
						estado: reboque.uf,
						tara: reboque.tara,
						capacidade: reboque.capacidade,
						carroceria: reboque.carroceria,
					});
				}
			});
			setFieldValue('reboques', newReboques);
		}
	}

	function onSaveModalDadosVeiculo(values) {
		setFieldValue('veiculoTracao', values);
	}

	function handleChangeUfCarregamento(e) {
		setFieldValue('ufCarregamento', e !== null ? e : null);
		setFieldValue('ufsPercurso', []);
		setFieldValue('municipioCarregamento', null);
		if (values.informacaoComplementar.permiteCarregamentoPosterior) {
			setFieldValue('ufDescarregamento', e !== null ? e : null);
		}
	}

	function handleChangeUfDescarregamento(e) {
		setFieldValue('ufDescarregamento', e !== null ? e : null);
		setFieldValue('ufsPercurso', []);
	}

	function handleChangeTipoEmitente(e) {
		setFieldValue('tipoEmitente', e.value);

		if (e.value === MDFE_TIPO_EMITENTE.PRESTADOR_SERVICO) {
			setFieldValue('informacaoComplementar.permiteCarregamentoPosterior', false);
		}
	}

	return (
		<>
			<Grid className="">
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={Dropdown}
					label="Tipo emitente"
					name="tipoEmitente"
					options={MDFE_TIPO_EMITENTE_OPTIONS}
					onChange={handleChangeTipoEmitente}
					value={values?.tipoEmitente}
					showClear={false}
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="2"
					lg="2"
					xl="2"
					component={Dropdown}
					label="Tipo transportador"
					name="tipoTransportador"
					options={MDFE_TIPO_TRANSPORTADOR_OPTIONS}
					onChange={(e) => setFieldValue('tipoTransportador', e.value)}
					value={values?.tipoTransportador}
					showClear={false}
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="2"
					lg="2"
					xl="2"
					component={InputDate}
					label="Emissão"
					name="emissao"
					value={values.emissao}
					onChange={(e) => setFieldValue('emissao', e.target?.value)}
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="2"
					lg="2"
					xl="2"
					component={InputDate}
					label="Início da viagem"
					name="inicioViagem"
					value={values.inicioViagem}
					onChange={(e) => setFieldValue('inicioViagem', e.target?.value)}
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Col sm="12" md="3" lg="3" xl="3" style={{ padding: '0px' }}>
					<SingleSelectVeiculo
						id="veiculo-tracao"
						name="veiculoTracao.veiculo"
						obrigatorio
						value={values.veiculoTracao?.veiculo}
						onChange={handleChangeVeiculo}
						url="/v1/mdfes/relacoes/veiculos"
						isClearable={false}
						placeholder="Selecione"
						colStyle={{ padding: '8px 8px 0px' }}
						errors={veiculoTracaoError}
						touched
						disabled={isSituacaoFinalMDFe}
						{...informacoesPermissoes}
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-end',
							marginTop: veiculoTracaoError && '-15px',
							marginRight:
								veiculoTracaoError &&
								(errors?.veiculoTracao?.capacidade || errors?.veiculoTracao?.carroceria) &&
								'-12px',
						}}
					>
						<If test={values.veiculoTracao?.veiculo}>
							<LinkVerDados
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									position: 'relative',
									margin: '0px 33px -20px 0',
								}}
								onClick={() => setVisibleModalDadosVeiculo(true)}
								label="Ver dados do veículo"
							/>
						</If>
					</div>
				</Col>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					name="ufCarregamento"
					label="UF carregamento"
					obrigatorio
					component={SingleSelectEstado}
					value={values.ufCarregamento}
					onChange={handleChangeUfCarregamento}
					isClearable={false}
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					name="ufDescarregamento"
					label="UF descarregamento"
					obrigatorio
					component={SingleSelectEstado}
					value={values.ufDescarregamento}
					onChange={handleChangeUfDescarregamento}
					isClearable={false}
					disabled={isSituacaoFinalMDFe || values.informacaoComplementar.permiteCarregamentoPosterior}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					name="municipioCarregamento"
					label="Município carregamento"
					obrigatorio
					component={SingleSelectMunicipio}
					value={values.municipioCarregamento}
					onChange={(e) => setFieldValue('municipioCarregamento', e !== null ? e : null)}
					isClearable={false}
					uf={values.ufCarregamento?.registro?.sigla}
					disabled={!values.ufCarregamento || isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					name="rntrc"
					label="RNTRC"
					component={InputField}
					keyfilter={keyFilterConsultaRsql}
					value={values.rntrc}
					size={8}
					disabled={isSituacaoFinalMDFe}
					{...informacoesPermissoes}
				/>
				<Col sm="12" md="12" lg="12" xl="12" style={{ padding: '0px' }}>
					<DropdownMultiSelect
						name="ufsPercurso"
						label="UF percurso"
						value={values.ufsPercurso}
						options={optionsUfPercurso}
						onChange={handleChangeUfPercurso}
						closeMenuOnSelect
						backspaceRemovesValue={false}
						disabled={isCarregamentoDescarregamentoAdjacente || isSituacaoFinalMDFe}
						isMulti
						showClear={false}
						onMenuOpen={handleOpenMenuUfPercurso}
						noOptionsMessage={() => 'Nenhum estado encontrado'}
						colStyle={{ padding: '8px 8px 0px' }}
						errors={!hasPercursoCompleto && errors?.ufsPercurso ? errors?.ufsPercurso : null}
						touched
						{...informacoesPermissoes}
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							paddingLeft: '10px',
						}}
					>
						{hasPercursoCompleto && <span style={{ color: '#4aa84d', fontWeight: 'bold' }}>Percurso concluído</span>}
					</div>
				</Col>
			</Grid>
			{visibleModalDadosVeiculo && (
				<ModalDadosVeiculo
					visible={visibleModalDadosVeiculo}
					onHide={() => setVisibleModalDadosVeiculo(false)}
					onSave={onSaveModalDadosVeiculo}
					values={values.veiculoTracao}
					onChangeVeiculo={handleChangeVeiculo}
				/>
			)}
		</>
	);
}

export { InformacoesPrincipais };
