import { useState } from 'react';
import { modulos } from '../../../Common/Constantes/autorizacao';
import { If } from '../../../components';
import ListagemRelatorios from '../../../components/ListagemRelatorios';
import ModalContasPagar from './components/ModalContasPagar';
import ModalContasReceber from './components/ModalContasReceber';
import ModalContasReceberInformeRendimentos from './components/ModalContasReceberInformeRendimentos';
import ModalMovimentacao from './components/ModalMovimentacao';
import { ModalReceitasDespesasPorCategoria } from './components/ModalReceitasDespesasPorCategoria';

function RelatoriosFinancas() {
	const [exibirModalRelatorioContasPagar, setExibirModalRelatorioContasPagar] = useState(false);
	const [exibirModalRelatorioContasReceber, setExibirModalRelatorioContasReceber] = useState(false);
	const [exibirModalRelatorioMovimentacao, setExibirModalRelatorioMovimentacao] = useState(false);
	const [exibirModalReceitasDespesasPorCategoria, setExibirModalReceitasDespesasPorCategoria] = useState(false);
	const [exibirModalContasReceberInformeRendimentos, setExibirModalContasReceberInformeRendimentos] = useState(false);

	const metodosImprimir = [
		{
			nome: 'CONTAS_PAGAR',
			onImprimir: () => {
				setExibirModalRelatorioContasPagar(true);
			},
		},
		{
			nome: 'CONTAS_RECEBER',
			onImprimir: () => {
				setExibirModalRelatorioContasReceber(true);
			},
		},
		{
			nome: 'CONTAS_RECEBER_INFORME_RENDIMENTO',
			onImprimir: () => {
				setExibirModalContasReceberInformeRendimentos(true);
			},
		},
		{
			nome: 'MOVIMENTACOES_FINANCEIRAS',
			onImprimir: () => {
				setExibirModalRelatorioMovimentacao(true);
			},
		},
		{
			nome: 'RECEITAS_DESPESAS_CATEGORIA',
			onImprimir: () => {
				setExibirModalReceitasDespesasPorCategoria(true);
			},
		},
	];

	return (
		<>
			<ListagemRelatorios header="Relatórios de finanças" modulo={modulos.FINANCAS} metodosImprimir={metodosImprimir} />
			<If test={exibirModalRelatorioContasPagar}>
				<ModalContasPagar
					visible={exibirModalRelatorioContasPagar}
					onHide={() => setExibirModalRelatorioContasPagar(false)}
				/>
			</If>
			<If test={exibirModalRelatorioContasReceber}>
				<ModalContasReceber
					visible={exibirModalRelatorioContasReceber}
					onHide={() => setExibirModalRelatorioContasReceber(false)}
				/>
			</If>
			<If test={exibirModalContasReceberInformeRendimentos}>
				<ModalContasReceberInformeRendimentos
					visible={exibirModalContasReceberInformeRendimentos}
					onHide={() => setExibirModalContasReceberInformeRendimentos(false)}
				/>
			</If>
			<If test={exibirModalRelatorioMovimentacao}>
				<ModalMovimentacao
					visible={exibirModalRelatorioMovimentacao}
					onHide={() => setExibirModalRelatorioMovimentacao(false)}
				/>
			</If>
			<If test={exibirModalReceitasDespesasPorCategoria}>
				<ModalReceitasDespesasPorCategoria
					visible={exibirModalReceitasDespesasPorCategoria}
					onHide={() => setExibirModalReceitasDespesasPorCategoria(false)}
				/>
			</If>
		</>
	);
}

export default RelatoriosFinancas;
