import PropTypes from 'prop-types';
import { AppSubmenu } from './components/AppSubmenu';

export function AppMenu(props) {
	const { model, onMenuItemClick } = props;

	return (
		<div className="menu">
			<AppSubmenu items={model} className="layout-main-menu" onMenuItemClick={onMenuItemClick} root={true} />
		</div>
	);
}

AppMenu.defaultProps = {
	model: null,
	onMenuItemClick: null,
};

AppMenu.propTypes = {
	model: PropTypes.array,
	onMenuItemClick: PropTypes.func,
};
