export function converterParaModal(values) {
	const pessoa = {
		nome: values.nome,
		email: values.email ? values.email : converterEmailFavorito(values?.emails) ?? null,
		telefone: values.telefone ? values.telefone : converterTelefoneFavorito(values.telefones) ?? null,
		tipo: values.tipo,
		cpf: values.pessoaFisica?.cpf ?? values.cpf ?? null,
		cnpj: values.pessoaJuridica?.cnpj ?? values.cnpj ?? null,
		idEstrangeiro: values.idEstrangeiro ?? null,
		indicadorInscricaoEstadual: values.indicadorInscricaoEstadual,
		inscricaoEstadual: values.inscricaoEstadual,
		destinoOperacao: values.destinoOperacao,
		consumidorFinal: values.consumidorFinal,

		endereco: values.endereco ? values.endereco : converterEnderecoFavorito(values.enderecos) ?? null,
		registro: values,
	};
	return pessoa;
}

function converterEmailFavorito(emails) {
	let emailFavorito = '';
	emails?.forEach((email) => {
		emailFavorito = email.favorito === true ? `${email.email}` : null;
	});

	if (emailFavorito) {
		return emailFavorito;
	} else if (emails?.length > 0) {
		return emails[0]?.email;
	}
	return null;
}

function converterTelefoneFavorito(telefones) {
	let telefoneFavorito = '';
	telefones?.forEach((telefone) => {
		telefoneFavorito = telefone.favorito === true ? `${telefone.numero}` : null;
	});

	if (telefoneFavorito) {
		return telefoneFavorito;
	} else if (telefones?.length > 0) {
		return telefones[0]?.numero;
	}
	return null;
}

function converterEnderecoFavorito(enderecos) {
	let enderecoFavorito = {};
	enderecos?.forEach((endereco) => {
		enderecoFavorito =
			endereco.favorito === true
				? {
						...endereco,
						municipio: {
							label: `${endereco.municipio.nome} - ${endereco.municipio.estadoSigla}`,
							registro: endereco.municipio,
							value: endereco.municipio.id,
						},
						pais: {
							label: endereco.pais.nome,
							registro: endereco.pais,
							value: endereco.pais.id,
						},
					}
				: null;
	});

	if (enderecoFavorito) {
		return enderecoFavorito;
	} else if (enderecos?.length > 0) {
		return {
			...enderecos[0],
			municipio: {
				label: `${enderecos[0].municipio.nome} - ${enderecos[0].municipio.estadoSigla}`,
				registro: enderecos[0].municipio,
				value: enderecos[0].municipio.id,
			},
			pais: {
				label: enderecos[0].pais.nome,
				registro: enderecos[0].pais,
				value: enderecos[0].pais.id,
			},
		};
	}
	return null;
}
