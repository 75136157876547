import Grid from 'components/Grid';
import {
	AiOutlineAlignCenter,
	AiOutlineAlignLeft,
	AiOutlineAlignRight,
	AiOutlineBold,
	AiOutlineItalic,
	AiOutlineUnderline,
} from 'react-icons/ai';
import { useFormikContext } from 'formik';
import { SelectButton } from 'primereact/selectbutton';
import { BsTextWrap } from 'react-icons/bs';

import { renderizarValidacao } from 'Common';

import { useContextEtiquetaConfiguracao } from 'views/cadastros/Etiquetas/components/configuracao/Form/Context';
import { OPTIONS_TIPO_ALINHAMENTO, TIPO_ALINHAMENTO } from './Util/constantes';

import './Styles/index.css';

function RichTextConfiguration({ isMobile }) {
	const { podeEditar } = useContextEtiquetaConfiguracao();
	const { values, setFieldValue, errors } = useFormikContext();

	const alinhamentoTemplate = (option) => {
		switch (option.value) {
			case TIPO_ALINHAMENTO.ESQUERDA:
				return <AiOutlineAlignLeft />;
			case TIPO_ALINHAMENTO.CENTRO:
				return <AiOutlineAlignCenter />;
			case TIPO_ALINHAMENTO.DIREITA:
				return <AiOutlineAlignRight />;
			default:
				return <AiOutlineAlignLeft />;
		}
	};

	function handleChangeAlinhamento(event) {
		if (event.value) {
			setFieldValue('alinhamento', event.value);
		}
	}

	return (
		<>
			<Grid verticalAlignEnd style={{ padding: '8px 8px 0px 8px' }}>
				<div className="divider-group">
					<div>
						<label
							htmlFor="id-tamanho-fonto-etiqueta"
							style={{
								display: 'flex',
								alignItems: 'center',
								fontSize: '14px',
							}}
						>
							Fonte
						</label>
						<input
							id="id-tamanho-fonto-etiqueta"
							title="Tamanho da fonte"
							className={`toggle-button  ${!podeEditar && 'p-disabled'} ${errors.richText ? 'p-input-error' : ''}`}
							style={{
								width: '64px',
								height: '32px',
								padding: '0px !important',
							}}
							name="tamanhoFonte"
							type="number"
							value={values.tamanhoFonte}
							onChange={(event) => setFieldValue('tamanhoFonte', event.target.value ? Number(event.target.value) : '')}
						/>
					</div>

					<button
						type="button"
						title="Texto em negrito"
						className={`toggle-button ${values.negrito ? 'p-highlight' : null} ${!podeEditar && 'p-disabled'}`}
						onClick={() => setFieldValue('negrito', !values.negrito)}
					>
						<AiOutlineBold size={16} />
					</button>
					<button
						type="button"
						title="Texto em itálico"
						className={`toggle-button ${values.italico ? 'p-highlight' : null}  ${!podeEditar && 'p-disabled'}`}
						onClick={() => setFieldValue('italico', !values.italico)}
					>
						<AiOutlineItalic size={16} />
					</button>
					<button
						type="button"
						title="texto sublinhado"
						className={`toggle-button ${values.sublinhado ? 'p-highlight' : null} ${!podeEditar && 'p-disabled'}`}
						onClick={() => setFieldValue('sublinhado', !values.sublinhado)}
					>
						<AiOutlineUnderline size={16} />
					</button>
					<button
						type="button"
						title="Quebra de linha automática"
						className={`toggle-button ${values.quebraLinha ? 'p-highlight' : null} ${!podeEditar && 'p-disabled'}`}
						onClick={() => setFieldValue('quebraLinha', !values.quebraLinha)}
					>
						<BsTextWrap size={16} />
					</button>
				</div>

				<SelectButton
					className="select-toggle"
					style={{
						display: 'flex',
						height: '32px',
						marginTop: isMobile ? '8px' : '0px',
					}}
					value={values.alinhamento}
					options={OPTIONS_TIPO_ALINHAMENTO}
					itemTemplate={alinhamentoTemplate}
					onChange={handleChangeAlinhamento}
					disabled={!podeEditar}
				/>
			</Grid>
			{renderizarValidacao(errors.richText, !!errors.richText)}
		</>
	);
}

export { RichTextConfiguration };
