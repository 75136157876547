import { useFormikContext } from 'formik';

import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar, notify, ToastTypes } from 'components';

import { validarFormulario } from 'views/Util';
import { converterParaApi } from '../../Utils/quitacaoTitulosConverter';
import { gerarQuitacaoEmLote } from '../../Request';

function ActionButtons({ onHide, isRecebimento, informacoesPermissoes }) {
	const { dirty, handleReset, values, validateForm } = useFormikContext();

	function cancelar() {
		if (dirty) {
			handleReset();
		} else {
			onHide(false);
		}
	}

	async function salvar() {
		if (await validarFormulario({ validateForm, values: values })) {
			const titulos = converterParaApi(values, isRecebimento);
			gerarQuitacaoEmLote(
				titulos,
				isRecebimento,
				() => {
					onHide(true);
				},
				({ response }) => {
					const erro = response && response?.data?.details?.length > 0 ? response?.data?.details[0] : null;

					notify(
						erro || `Ocorreu um erro no ${isRecebimento ? 'Recebimento' : 'Pagamento'} de títulos`,
						ToastTypes.ERROR
					);
					console.warn(`erro na requisição de ${isRecebimento ? 'Recebimento' : 'Pagamento'} de títulos: `, response);
				}
			);
		}
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
			/>
			<ButtonSalvar {...informacoesPermissoes} onClick={salvar} />
		</>
	);
}

export default ActionButtons;
