import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.css';

export function AppSubmenu(props) {
  const { root } = props;

  const [activeIndex, setActiveIndex] = useState(null);

  function onMenuItemClickInternal(event, item, index) {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    //prevent hash change
    if (item.items || !item.url) {
      event.preventDefault();
    }

    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }

    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item: item,
      });
    }
  }

  function validarItem(item) {
    if (!item) return true;

    if (item.isValid || item.validate === false) {
      if (item.items) {
        for (let i in item.items) {
          if (item.items[i].isValid || (item.items[i].validate === false && item.items[i].command)) {
            return true;
          }
        }

        for (let i in item.items) {
          if (item.items[i].items) {
            for (let j in item.items[i].items) {
              if (
                item.items[i].items[j].isValid ||
                (item.items[i].items[j].validate === false && item.items[i].items[j].command)
              ) {
                return true;
              }
            }
          }
        }
      } else return true;
    }
  }

  let items =
    props.items &&
    props.items
      .filter(item => validarItem(item))
      .map((item, i) => {
        let active = activeIndex === i;
        let styleClass = classNames(item.badgeStyleClass, {
          'active-menuitem': active,
        });
        let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;
        let submenuIcon = item.items && (
          <i style={{ marginLeft: '5px' }} className={active ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
        );

        return (
          <li className={styleClass} key={i}>
            {item.items && root === true && <div className="arrow"></div>}
            <a
              href={item.url}
              onClick={e => onMenuItemClickInternal(e, item, i)}
              target={item.target}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px 33px 7px 7px',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {item.icon}
                <span title={item.title}>{item.label}</span>
              </span>
              {submenuIcon}
              {badge}
            </a>
            <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
          </li>
        );
      });

  return items ? (
    <ul style={props.className !== 'layout-main-menu' ? { paddingLeft: '2em' } : null} className={props.className}>
      {items}
    </ul>
  ) : null;
}

AppSubmenu.defaultProps = {
  className: null,
  items: null,
  onMenuItemClick: null,
  root: false,
};

AppSubmenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  onMenuItemClick: PropTypes.func,
  root: PropTypes.bool,
};
