import { dispatchAction } from '../../../../Common/Redux';
import { actionTypes } from '../../../../Common/Constantes/reduxTypes';

export function dispararAcoesReduxAtualizacaoPlano() {
	dispatchAction(actionTypes.ATUALIZAR_PLANO);
}

export function dispararAcoesReduxPossuiSuporteViaChat(possuiSuporteViaChat) {
	dispatchAction(actionTypes.POSSUI_SUPORTE_VIA_CHAT, possuiSuporteViaChat);
}
