import { buscarDadosLoginLocalStorage } from 'Common';
import { Col, TabView } from 'components';
import { TabPanel } from 'primereact/tabview';
import { TabInformacoesEmpresa } from './components/TabInformacoesEmpresa';
import { TabModulosSistema } from './components/TabModulosSistema';
import { TabsParametrizacao } from '../TabsParametrizacao';
import { TabSeguranca } from './components/TabSeguranca';
import { useContextConfiguracoesGerais } from '../../Context';
import { TabConfiguracoesFiscais } from './components/TabConfiguracoesFiscais';

function TabsConfiguracoesGerais() {
	const { podeVisualizarVendas, tabConfiguracoesGerais, setTabConfiguracoesGerais } = useContextConfiguracoesGerais();

	return (
		<TabView
			className="tab-view"
			activeIndex={tabConfiguracoesGerais}
			onTabChange={(e) => setTabConfiguracoesGerais(e.index)}
		>
			<TabPanel header="Informações da empresa">
				<TabInformacoesEmpresa />
			</TabPanel>

			<TabPanel
				header="Módulos do sistema"
				headerStyle={
					buscarDadosLoginLocalStorage()?.organizacao.aplicacao === 'MSYS_GESTOR' &&
					!window.location.href.includes('docsdigitais') &&
					!window.location.href.includes('msysgestor')
						? null
						: { display: 'none' }
				}
			>
				<TabModulosSistema />
			</TabPanel>

			<TabPanel header="Parametrização">
				<Col>
					<TabsParametrizacao />
				</Col>
			</TabPanel>

			<TabPanel header="Configurações fiscais" headerStyle={podeVisualizarVendas ? null : { display: 'none' }}>
				<TabConfiguracoesFiscais />
			</TabPanel>

			<TabPanel header="Segurança">
				<TabSeguranca />
			</TabPanel>
		</TabView>
	);
}

export { TabsConfiguracoesGerais };
