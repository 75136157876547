import Button from '..';
import propTypes from 'prop-types';

export const estadosBotaoNovo = {
	NOVO: 'NOVO',
	SALVAR_E_NOVO: 'SALVAR_E_NOVO',
};

function ButtonNovo(props) {
	const {
		id,
		className,
		style,
		hidden,
		onClick,
		enableShortCut,
		label,
		rounded,
		raised,
		icon,
		estadoBotao,
		size,
		titlePersonalizado,
	} = props;

	function getDisabled() {
		if (props.podeInserir === false) return true;
		return props.disabled;
	}

	function getTitle() {
		if (props.podeInserir === false) {
			return 'Você não possui permissão para executar essa ação';
		} else if (props.estadoBotao === estadosBotaoNovo.SALVAR_E_NOVO) {
			return 'Salvar e inserir novo registro';
		}
		return props.title;
	}

	const labelFormatado = estadoBotao === estadosBotaoNovo.SALVAR_E_NOVO ? 'Salvar e novo' : label;

	const titleFormatado = titlePersonalizado ?? getTitle();

	return (
		<Button
			id={id}
			className={className}
			type="button"
			style={{ margin: '5px', ...style }}
			hidden={hidden}
			onClick={onClick}
			disabled={getDisabled()}
			title={titleFormatado}
			color="primary"
			iconPos="left"
			icon={icon ? icon : 'fa fa-plus'}
			raised={raised}
			rounded={rounded}
			label={labelFormatado}
			size={size}
			hotKey={['alt', 'n']}
			enableShortCut={enableShortCut}
		/>
	);
}

ButtonNovo.defaultProps = {
	hidden: false,
	disabled: false,
	label: 'Novo',
	title: 'Inserir um novo registro',
};

ButtonNovo.propTypes = {
	/** Identificador do registro */
	id: propTypes.string,
	/** classificações do documento */
	className: propTypes.string,
	/** Define o botão como invisível */
	hidden: propTypes.bool,
	/** Ação executada ao clicar no botão */
	onClick: propTypes.func,
	/** Define o botão como desabilitaedo */
	disabled: propTypes.bool,
	/** Habilitar atalho */
	enableShortCut: propTypes.bool,
	/** Diz se o usuário possui permissão de inserir*/
	podeInserir: propTypes.bool,
	/** Title do componente*/
	title: propTypes.string,
	/** Estilo do componente */
	style: propTypes.object,
	/** Label do componente */
	label: propTypes.string,
};

export default ButtonNovo;
