import { parseFloatNumber } from '../../../../Common';

export function validarValorNegativo(valor) {
	return valor < 0 ? 0.0 : valor;
}

export function concatenate(value) {
	let str1 = value?.unidade ? value.unidade : value?.codigo ? value.codigo : '';
	let str2 = value?.descricao ? value.descricao : value?.nome ? value.nome : '';
	let str3 = `${str1} - ${str2}${value?.sigla ? ' (' + value.sigla + ')' : ''}`;
	return { ...value, label: str3, value: value?.id };
}

export function calcularConversao(value, fator, operacao) {
	let total = 0;
	if (operacao === 'MULTIPLICACAO') {
		total = value * fator;
	} else if (operacao === 'DIVISAO') {
		total = value / fator;
	}
	return parseFloatNumber(total);
}

export function calcularTotal(operacao, unitario, quantidade, icmsSt, ipi, frete, seguro, acessorias, desconto) {
	let total =
		(operacao === 'MULTIPLICACAO' ? quantidade * unitario : quantidade / unitario) -
		desconto +
		frete +
		acessorias +
		seguro +
		icmsSt +
		ipi;

	return parseFloatNumber(total);
}

export function ordenarItens(itens) {
	return Array.from(itens).sort((a, b) => {
		if (a.item > b.item) {
			return 1;
		}
		if (a.item < b.item) {
			return -1;
		}
		return 0;
	});
}

export function addZero(item) {
	let convertido = '';

	if (item.toString().length === 1) {
		return convertido.concat('0', item);
	} else {
		return item;
	}
}

export function getIdBoletosSelected(boletos) {
	const idBoletos = [];
	if (boletos.length > 0) {
		boletos.forEach(boleto => {
			idBoletos.push({ id: boleto.id });
		});
	}
	return idBoletos;
}
