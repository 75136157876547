import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar } from 'components';
import { useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';

function ActionButtons({ salvar, cancelar }) {
	const { dirty } = useFormikContext();
	const { informacoesPermissoes } = useContextConfiguracoesGerais();

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={() => cancelar()}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar label="Salvar" onClick={() => salvar()} disabled={!dirty} {...informacoesPermissoes} />
		</>
	);
}

export { ActionButtons };
