import { useFormikContext } from 'formik';

import { Button, ButtonCancelar, estadosBotaoCancelar } from 'components';

import { validarFormulario } from 'views/Util';
import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { createPagamentoMDFe } from 'views/transporte/MDFe/Requests';
import { converterMDFePagamentoParaApi } from 'views/transporte/MDFe/Form/Util/MDFeConverter';

function ActionButtons({ onHide, mdfe, canTransmitir, id, resetFormPrincipal }) {
	const { informacoesPermissoes } = useContextMDFe();
	const { values, dirty, handleReset, handleSubmit, validateForm } = useFormikContext();

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			onHide();
		}
	}

	async function handleClickTransmitir() {
		handleSubmit();

		if (await validarFormulario({ values, validateForm })) {
			await createPagamentoMDFe(id, converterMDFePagamentoParaApi(values), ({ data: response }) => {
				resetFormPrincipal({ values: { ...mdfe, pagamento: { ...values, id: response } } });
				onHide();
			});
		}
	}

	return (
		<>
			<ButtonCancelar onClick={handleClickCancelar} estadoBotao={estadoBotaoCancelar} {...informacoesPermissoes} />
			<Button
				className="p-button-success"
				label="Transmitir"
				icon="fa fa-send"
				onClick={handleClickTransmitir}
				style={{ margin: '5px' }}
				disabled={!dirty}
				hidden={!canTransmitir}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export { ActionButtons };
