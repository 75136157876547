import { useState, useEffect } from "react";
import Form from "../../../../components/Form";
import FormActions from "../../../../components/FormActions";
import FormContent from "../../../../components/FormContent";
import Prompt from "../../../../components/Route/Prompt";
import { Field, withFormik } from "formik";
import * as Yup from "yup";
import Tutorial from "../../../../components/Tutorial";
import Grid from "../../../../components/Grid";
import Col from "../../../../components/Col";
import InputField from "../../../../components/input/InputField";
import Dropdown from "../../../../components/select/Dropdown";
import {
  removerCaracteres,
  validarUUID,
} from "../../../../Common/ManipulacaoDeString";
import SingleSelectCst, {
  typesCst,
} from "../../../../components/select/SingleSelectCst";
import InputMoney from "../../../../components/input/InputMoney";
import { converterTributacaoFederalParaFormulario } from "../Util/tributacaoFederalConverter";
import { asyncGetTributacaoFederal } from "../Requests";
import {
  estadosCadastro,
  permissoes,
  recursos,
} from "../../../../Common/Constantes/autorizacao";
import { usuarioPossuiPermissao } from "../../../../Common/Autenticacao";
import { helpTributacaoFederalForm } from "./Help";
import {
  buscarConfiguracaoUsuario,
  configuracoesUsuario,
  salvarConfiguracaoUsuario,
} from "../../../../Common/ConfiguracaoUsuario";
import { tutorialSteps } from "../../../../components/Tutorial/steps/TributacaoFederal";
import Divider from "../../../../components/Divider";
import ActionButtons from "./components/ActionButtons";
import { mensagensDeValidacao } from "Common";

const situacaoOptions = [
  { label: "Ativo", value: "ATIVO" },
  { label: "Inativo", value: "INATIVO" },
];

const secaoStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0px",
};

const codigoStyle = {
  fontSize: "24px",
  color: "#006095",
  fontWeight: "bold",
};

const PESQUISAURL = "/tributacoes/federal";
const CADASTROURL = "/tributacoes/federal/cadastro";

const initialValue = {
  id: null,
  codigo: null,
  descricao: null,
  situacao: situacaoOptions[0].value,
  ipiEnquadramento: {
    label: "999 - Tributação normal IPI; Outros;",
    value: "15c1cd1b-29ba-4769-83bf-e399e9e3cb18",
  },
  ipiCst: {
    label: "53 - Saída Não-Tributada",
    value: "63ca257c-fb24-4bcc-80b8-2b6f45ee0d2e",
  },
  ipiBase: 100,
  ipiPercentual: 0,
  pisCst: {
    label: "07 - Operação Isenta da Contribuição",
    value: "314c4cd9-a8d9-4edc-80f9-2bac14234d2b",
  },
  pisBase: 100,
  pisPercentual: 0,
  pisBaseSt: 100,
  pisStPercentual: 0,
  cofinsCst: {
    label: "07 - Operação Isenta da Contribuição",
    value: "314c4cd9-a8d9-4edc-80f9-2bac14234d2b",
  },
  cofinsBase: 100,
  cofinsPercentual: 0,
  cofinsBaseSt: 100,
  cofinsStPercentual: 0,
};

function TributacaoFederalFormView(props) {
  const { values, dirty, setFieldValue, isModal, resetForm, hideModal } = props;

  const {
    id,
    codigo,
    descricao,
    situacao,
    ipiEnquadramento,
    ipiCst,
    ipiBase,
    ipiPercentual,
    pisCst,
    pisBase,
    pisPercentual,
    pisBaseSt,
    pisStPercentual,
    cofinsCst,
    cofinsBase,
    cofinsPercentual,
    cofinsBaseSt,
    cofinsStPercentual,
  } = values;

  const [dadosBase, setDadosBase] = useState(null);
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const [isNewTributacao, setIsNewTributacao] = useState(false);

  const idURL = props.match?.params.id;
  const podeInserir = usuarioPossuiPermissao(
    recursos.TRIBUTACAO,
    permissoes.INSERIR
  );
  const podeEditar = usuarioPossuiPermissao(
    recursos.TRIBUTACAO,
    permissoes.EDITAR
  );
  const podeExcluir = usuarioPossuiPermissao(
    recursos.TRIBUTACAO,
    permissoes.EXCLUIR
  );

  const informacoesPermissoes = {
    estadoCadastro: buscarEstadoCadastro(),
    podeInserir: podeInserir,
    podeEditar: podeEditar,
    podeExcluir: podeExcluir,
  };

  useEffect(() => {
    const deveExibirTutorial = buscarConfiguracaoUsuario(
      configuracoesUsuario.EXIBIR_TUTORIAL_TRIBUTACAO_FEDERAL
    );
    setTimeout(() => {
      if (document.getElementById("InputDescricao")) {
        document.getElementById("InputDescricao").focus();
      }
    }, 500);

    if (validarUUID(idURL) && !isNewTributacao) {
      getTributacaoSelected();
    }

    if (deveExibirTutorial !== false) {
      setTutorialVisible(true);
      salvarConfiguracaoUsuario(
        configuracoesUsuario.EXIBIR_TUTORIAL_TRIBUTACAO_FEDERAL,
        false,
        null,
        false
      );
    }
  }, [idURL]);

  function getTributacaoSelected() {
    asyncGetTributacaoFederal(idURL, async ({ data: tributacao }) => {
      let tributacaoConvertida =
        converterTributacaoFederalParaFormulario(tributacao);
      resetForm({ values: tributacaoConvertida });
      setDadosBase(tributacaoConvertida);
    });
  }

  function buscarEstadoCadastro() {
    return id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
  }

  function setValueCst(field, event) {
    if (event?.label && event?.value) {
      setFieldValue(field, { label: event.label, value: event.value });
    } else {
      setFieldValue(field, null);
    }
  }

  return (
    <>
      <Prompt dirty={dirty} isModal={isModal} />
      <Tutorial
        steps={tutorialSteps}
        showSkipButton
        continuous
        visible={tutorialVisible}
        onHide={() => setTutorialVisible(false)}
      />
      <Form
        header="Cadastro de tributação federal"
        isModal={isModal}
        className="card-default screen-max-width"
      >
        <FormActions className="screen-max-width">
          <ActionButtons
            {...props}
            informacoesPermissoes={informacoesPermissoes}
            initialValue={initialValue}
            dadosBase={dadosBase}
            setDadosBase={setDadosBase}
            CADASTROURL={CADASTROURL}
            PESQUISAURL={PESQUISAURL}
            setIsNewTributacao={setIsNewTributacao}
            hideModal={hideModal}
          />
        </FormActions>
        <FormContent>
          <Grid>
            <Col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style={{ height: "42px", marginBottom: "1rem" }}
            >
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div
                  style={secaoStyle}
                  title={
                    !codigo
                      ? "O código é gerado automaticamente pelo sistema"
                      : null
                  }
                >
                  <div>Código</div>
                  <div style={codigoStyle}>{codigo || "-"}</div>
                </div>
              </div>
            </Col>
            <Col sm="12" md="12" lg="12" xl="12">
              <Grid className="step-tributacao-federal-principais">
                <Field
                  sm="12"
                  md="9"
                  lg="9"
                  xl="10"
                  id="InputDescricao"
                  component={InputField}
                  label="Descrição"
                  obrigatorio
                  name="descricao"
                  value={descricao}
                  onChange={(e) =>
                    setFieldValue(
                      "descricao",
                      removerCaracteres(e.target?.value, ["─"])
                    )
                  }
                  helpMessage={helpTributacaoFederalForm.descricao}
                  size={120}
                  {...informacoesPermissoes}
                />
                <Field
                  sm="12"
                  md="3"
                  lg="3"
                  xl="2"
                  component={Dropdown}
                  label="Situação"
                  name="situacao"
                  value={situacao}
                  onChange={(event) => setFieldValue("situacao", event?.value)}
                  helpMessage={helpTributacaoFederalForm.situacao}
                  options={situacaoOptions}
                  obrigatorio
                  showClear={false}
                  {...informacoesPermissoes}
                />
              </Grid>
            </Col>

            <Col sm="12" md="12" lg="12" xl="12">
              <Grid className="step-tributacao-federal-ipi">
                <Divider label="IPI" />
                <Field
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  component={SingleSelectCst}
                  type={typesCst.ENQUADRAMENTO_IPI}
                  label="Código enquadramento IPI"
                  name="ipiEnquadramento"
                  value={ipiEnquadramento}
                  onChange={(event) => setValueCst("ipiEnquadramento", event)}
                  helpMessage={helpTributacaoFederalForm.enquadramentoIpi}
                  obrigatorio
                  isClearable={false}
                  {...informacoesPermissoes}
                />
                <Field
                  sm="12"
                  md="6"
                  lg="6"
                  xl="8"
                  component={SingleSelectCst}
                  type={typesCst.CST_IPI}
                  label="CST IPI"
                  name="ipiCst"
                  value={ipiCst}
                  onChange={(event) => setValueCst("ipiCst", event)}
                  helpMessage={helpTributacaoFederalForm.cstIpi}
                  obrigatorio
                  isClearable={false}
                  {...informacoesPermissoes}
                />
                <Field
                  sm="12"
                  md="3"
                  lg="3"
                  xl="2"
                  component={InputMoney}
                  label="Base IPI %"
                  name="ipiBase"
                  value={ipiBase}
                  onChange={(event) =>
                    setFieldValue("ipiBase", event.target?.value)
                  }
                  helpMessage={helpTributacaoFederalForm.baseIpi}
                  size={8}
                  decimalScale={2}
                  prefix=""
                  suffix="%"
                  maxValue={100}
                  placeholder="0,00 %"
                  {...informacoesPermissoes}
                />
                <Field
                  sm="12"
                  md="3"
                  lg="3"
                  xl="2"
                  component={InputMoney}
                  label="IPI %"
                  name="ipiPercentual"
                  value={ipiPercentual}
                  onChange={(event) =>
                    setFieldValue("ipiPercentual", event.target?.value)
                  }
                  helpMessage={helpTributacaoFederalForm.percentualIpi}
                  size={8}
                  decimalScale={2}
                  prefix=""
                  suffix="%"
                  maxValue={100}
                  placeholder="0,00 %"
                  {...informacoesPermissoes}
                />
              </Grid>
            </Col>

            <Col sm="12" md="12" lg="12" xl="12">
              <Grid className="step-tributacao-federal-pis">
                <Divider label="PIS" />
                <Field
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  component={SingleSelectCst}
                  type={typesCst.CST_PIS_COFINS}
                  label="CST PIS"
                  name="pisCst"
                  value={pisCst}
                  onChange={(event) => setValueCst("pisCst", event)}
                  helpMessage={helpTributacaoFederalForm.cstPis}
                  obrigatorio
                  isClearable={false}
                  {...informacoesPermissoes}
                />
                <Field
                  sm="12"
                  md="3"
                  lg="3"
                  xl="2"
                  component={InputMoney}
                  label="Base PIS %"
                  name="pisBase"
                  value={pisBase}
                  onChange={(event) =>
                    setFieldValue("pisBase", event.target?.value)
                  }
                  helpMessage={helpTributacaoFederalForm.basePis}
                  size={8}
                  decimalScale={2}
                  prefix=""
                  suffix="%"
                  maxValue={100}
                  placeholder="0,00 %"
                  {...informacoesPermissoes}
                />
                <Field
                  sm="12"
                  md="3"
                  lg="3"
                  xl="2"
                  component={InputMoney}
                  label="PIS %"
                  name="pisPercentual"
                  value={pisPercentual}
                  onChange={(event) =>
                    setFieldValue("pisPercentual", event.target?.value)
                  }
                  helpMessage={helpTributacaoFederalForm.percentualPis}
                  size={8}
                  decimalScale={2}
                  prefix=""
                  suffix="%"
                  maxValue={100}
                  placeholder="0,00 %"
                  {...informacoesPermissoes}
                />
                <Field
                  sm="12"
                  md="3"
                  lg="3"
                  xl="2"
                  label="Base PIS ST %"
                  component={InputMoney}
                  name="pisBaseSt"
                  value={pisBaseSt}
                  onChange={(event) =>
                    setFieldValue("pisBaseSt", event.target?.value)
                  }
                  helpMessage={helpTributacaoFederalForm.basePisSt}
                  size={8}
                  decimalScale={2}
                  prefix=""
                  suffix="%"
                  maxValue={100}
                  placeholder="0,00 %"
                  {...informacoesPermissoes}
                />
                <Field
                  sm="12"
                  md="3"
                  lg="3"
                  xl="2"
                  component={InputMoney}
                  label="PIS ST %"
                  name="pisStPercentual"
                  value={pisStPercentual}
                  onChange={(event) =>
                    setFieldValue("pisStPercentual", event.target?.value)
                  }
                  helpMessage={helpTributacaoFederalForm.percentualPisSt}
                  size={8}
                  decimalScale={2}
                  prefix=""
                  suffix="%"
                  maxValue={100}
                  placeholder="0,00 %"
                  {...informacoesPermissoes}
                />
              </Grid>
            </Col>

            <Col sm="12" md="12" lg="12" xl="12">
              <Grid className="step-tributacao-federal-cofins">
                <Divider label="COFINS" />
                <Field
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  component={SingleSelectCst}
                  type={typesCst.CST_PIS_COFINS}
                  label="CST COFINS"
                  name="cofinsCst"
                  value={cofinsCst}
                  onChange={(event) => setValueCst("cofinsCst", event)}
                  helpMessage={helpTributacaoFederalForm.cstCofins}
                  obrigatorio
                  isClearable={false}
                  {...informacoesPermissoes}
                />
                <Field
                  sm="12"
                  md="3"
                  lg="3"
                  xl="2"
                  component={InputMoney}
                  label="Base COFINS %"
                  name="cofinsBase"
                  value={cofinsBase}
                  onChange={(event) =>
                    setFieldValue("cofinsBase", event.target?.value)
                  }
                  helpMessage={helpTributacaoFederalForm.baseCofins}
                  size={8}
                  decimalScale={2}
                  prefix=""
                  suffix="%"
                  maxValue={100}
                  placeholder="0,00 %"
                  {...informacoesPermissoes}
                />
                <Field
                  sm="12"
                  md="3"
                  lg="3"
                  xl="2"
                  component={InputMoney}
                  label="COFINS %"
                  name="cofinsPercentual"
                  value={cofinsPercentual}
                  onChange={(event) =>
                    setFieldValue("cofinsPercentual", event.target?.value)
                  }
                  helpMessage={helpTributacaoFederalForm.percentualCofins}
                  size={8}
                  decimalScale={2}
                  prefix=""
                  suffix="%"
                  maxValue={100}
                  placeholder="0,00 %"
                  {...informacoesPermissoes}
                />
                <Field
                  sm="12"
                  md="3"
                  lg="3"
                  xl="2"
                  component={InputMoney}
                  label="Base COFINS ST %"
                  name="cofinsBaseSt"
                  value={cofinsBaseSt}
                  onChange={(event) =>
                    setFieldValue("cofinsBaseSt", event.target?.value)
                  }
                  helpMessage={helpTributacaoFederalForm.baseCofinsSt}
                  size={8}
                  decimalScale={2}
                  prefix=""
                  suffix="%"
                  maxValue={100}
                  placeholder="0,00 %"
                  {...informacoesPermissoes}
                />
                <Field
                  sm="12"
                  md="3"
                  lg="3"
                  xl="2"
                  component={InputMoney}
                  label="COFINS ST %"
                  name="cofinsStPercentual"
                  value={cofinsStPercentual}
                  onChange={(event) =>
                    setFieldValue("cofinsStPercentual", event.target?.value)
                  }
                  helpMessage={helpTributacaoFederalForm.percentualCofinsSt}
                  size={8}
                  decimalScale={2}
                  prefix=""
                  suffix="%"
                  maxValue={100}
                  placeholder="0,00 %"
                  {...informacoesPermissoes}
                />
              </Grid>
            </Col>
          </Grid>
        </FormContent>
      </Form>
    </>
  );
}

const TributacaoFederalForm = withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,

  mapPropsToValues() {
    return initialValue;
  },
  validationSchema: Yup.object().shape({
    descricao: Yup.string()
      .nullable()
      .required(mensagensDeValidacao.OBRIGATORIO),
    // ipiEnquadramento: Yup.object()
    //   .nullable()
    //   .required(mensagensDeValidacao.OBRIGATORIO),
    ipiCst: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    pisCst: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    cofinsCst: Yup.object()
      .nullable()
      .required(mensagensDeValidacao.OBRIGATORIO),
  }),
  handleSubmit: () => {},
})(TributacaoFederalFormView);

export default TributacaoFederalForm;
