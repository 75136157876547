import {
	OptionsBlocoCMovimentacoes,
	OptionsBlocos,
	OptionsClassificacaoContribuinteIpi,
	OptionsIndicacaoAtividade,
	OptionsMotivoInventario,
	OptionsMovimentos,
	OptionsPerfil,
	OptionsTipoCusto,
} from './constantes';

function converterSpedParaFormulario(sped) {
	return {
		id: sped.id,
		filial: sped.filial,
		dataInicial: sped.dataInicial,
		dataFinal: sped.dataFinal,
		perfil: OptionsPerfil.find(e => e.value === sped.perfil),
		indicacaoAtividade: OptionsIndicacaoAtividade.find(e => e.value === sped.indicacaoAtividade),
		classificacaoContribuinteIpi: OptionsClassificacaoContribuinteIpi.find(
			e => e.value === sped.classificacaoContribuinteIpi
		),
		blocoC: {
			id: sped.blocoC.id,
			movimentacoes: OptionsBlocoCMovimentacoes.find(e => e.value === sped.blocoC.movimentacoes),
		},
		blocoH: {
			id: sped.blocoH.id,
			data: sped.blocoH.data,
			tipoCusto: OptionsTipoCusto.find(e => e.value === sped.blocoH.tipoCusto),
			motivo: OptionsMotivoInventario.find(e => e.value === sped.blocoH.motivo),
		},
		blocos: converterBlocosParaFormulario(sped.blocos),
		movimentos: converterMovimentosParaFormulario(sped.movimentos),
	};
}

function converterBlocosParaFormulario(blocos) {
	const blocosConvertidos = [];

	if (blocos?.length > 0) {
		blocos.forEach(bloco => {
			blocosConvertidos.push({ ...OptionsBlocos.find(e => e.value === bloco.descricao), registro: bloco });
		});
	}

	return blocosConvertidos;
}

function converterMovimentosParaFormulario(movimentos) {
	const movimentosConvertidos = [];

	if (movimentos?.length > 0) {
		movimentos.forEach(movimento => {
			movimentosConvertidos.push({
				...OptionsMovimentos.find(e => e.value === movimento.indicacao),
				registro: movimento,
			});
		});
	}

	return movimentosConvertidos;
}

function converterSpedParaApi(sped) {
	return {
		id: sped.id,
		dataInicial: sped.dataInicial,
		dataFinal: sped.dataFinal,
		perfil: sped.perfil?.value ? sped.perfil?.value : null,
		indicacaoAtividade: sped.indicacaoAtividade?.value ? sped.indicacaoAtividade?.value : null,
		classificacaoContribuinteIpi: sped.classificacaoContribuinteIpi?.value
			? sped.classificacaoContribuinteIpi?.value
			: null,
		blocoC: {
			id: sped.blocoC.id,
			movimentacoes: sped.blocoC.movimentacoes?.value ? sped.blocoC.movimentacoes?.value : null,
		},
		blocoH: {
			id: sped.blocoH.id,
			data: sped.blocoH.data,
			tipoCusto: sped.blocoH.tipoCusto?.value ? sped.blocoH.tipoCusto?.value : null,
			motivo: sped.blocoH.motivo?.value ? sped.blocoH.motivo?.value : null,
		},
		blocos: converterBlocosParaApi(sped.blocos),
		movimentos: converterMovimentosParaApi(sped.movimentos),
	};
}

function converterBlocosParaApi(blocos) {
	const blocosConvertidos = [];

	if (blocos.length > 0) {
		blocos.forEach(bloco => {
			blocosConvertidos.push({
				id: bloco.registro?.id ? bloco.registro?.id : null,
				descricao: bloco.value,
			});
		});
	}

	return blocosConvertidos;
}

function converterMovimentosParaApi(movimentos) {
	const movimentosConvertidos = [];

	if (movimentos.length > 0) {
		movimentos.forEach(movimento => {
			movimentosConvertidos.push({
				id: movimento.registro?.id ? movimento.registro?.id : null,
				indicacao: movimento.value,
			});
		});
	}

	return movimentosConvertidos;
}

function converterSpedEmailParaApi(email) {
	return {
		assunto: email.assunto,
		corpoEmail: email.corpoEmail,
		destinatarios: converterDestinatariosEmailParaApi(email.destinatarios),
		sped: converterSpedParaApi(email.sped),
	};
}

function converterDestinatariosEmailParaApi(destinatarios) {
	return destinatarios
		.split(',')
		.map(item => item.trim())
		.filter(item => item.trim() !== '');
}

export { converterSpedParaFormulario, converterSpedParaApi, converterSpedEmailParaApi };
