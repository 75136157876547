import { del, exibirToast, get, post, put, services } from 'Common';

export async function asyncGetModelosDeServico(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetModeloDeServico(idModelo, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/servicos/modelo/${idModelo}`, null, onSuccess, onError);
}

export async function asyncCreateModeloDeServico(modelo, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/servicos/modelo`,
		modelo,
		null,
		exibirToast(onSuccess, 'Modelo criado com sucesso'),
		onError
	);
}

export async function asyncUpdateModeloDeServico(modelo, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/servicos/modelo/${modelo.id}`,
		modelo,
		null,
		exibirToast(onSuccess, 'Modelo atualizado com sucesso'),
		onError
	);
}

export async function asyncDeleteModeloDeServico(idModelo, onSuccess, onError) {
	return await del(
		`${services.GESTOR}/v1/servicos/modelo/${idModelo}`,
		null,
		exibirToast(onSuccess, 'Modelo excluído'),
		onError
	);
}
