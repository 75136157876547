import { Produtos } from 'components/Produtos';
import { moduloUtilizado } from 'components/Produtos/Util/constantes';
import { useFormikContext } from 'formik';

import { useContextOrdemServico } from 'views/fiscal/servicos/OrdemServico/Context';

export function TabProdutos({ onChangeProduto, onChangeProdutos, calcularPercentualAdicionalTabelaPreco }) {
	const { values, errors } = useFormikContext();
	const {
		color,
		disabledFields,
		informacoesPermissoes,
		setAtualizarTotais,
		clienteUsaPrecoAtacado,
		recalcularTodosOsProdutosComTabelaPreco,
		setRecalcularTodosOsProdutosComTabelaPreco,
	} = useContextOrdemServico();

	return (
		<Produtos
			produtos={values.produtos}
			pessoa={values.cliente}
			pagamentos={values.pagamentos}
			tabelaPreco={values.informacoesComplementares?.tabelaPreco}
			operacaoFiscalHeader={values.operacaoFiscal}
			comissaoHeader={values.informacoesComplementares?.vendedor?.registro?.comissao}			
			descontoMaximoVendedor={values.informacoesComplementares?.vendedor?.registro?.descontoMaximo}
			descontoMaximoSuperior={values.informacoesComplementares?.vendedor?.registro?.descontoMaximoSuperior}
			utilizaPrecoAtacado={clienteUsaPrecoAtacado}
			onChangeProdutos={onChangeProdutos}
			onChangeProduto={onChangeProduto}
			calcularPercentualAdicionalTabelaPreco={calcularPercentualAdicionalTabelaPreco}
			recalcularTodosOsProdutosComTabelaPreco={recalcularTodosOsProdutosComTabelaPreco}
			setRecalcularTodosOsProdutosComTabelaPreco={setRecalcularTodosOsProdutosComTabelaPreco}
			atualizarTotalizadores={() => setAtualizarTotais(true)}
			color={color.strongColor}
			disabledButtonAdd={!informacoesPermissoes.podeEditar || disabledFields}
			informacoesPermissoes={informacoesPermissoes}
			modulo={moduloUtilizado.ORDEM_SERVICO}
			disabled={disabledFields}
			tipoOperacao="SAIDA"
			consumidorFinal={values.cliente?.registro?.consumidorFinal}
			finalidadeDocumento="NORMAL"
			errors={errors.produtos}
		/>
	);
}
