import { useState } from 'react';
import { Button, If, Subtitle, WebCam, SingleSelectMarca } from 'components';
import { useFormikContext, Field } from 'formik';
import { CiImageOff } from 'react-icons/ci';
import { services } from 'Common';

function FieldsAdicionais({ informacoesPermissoes, isGestor, ...props }) {
	const { values, setFieldValue } = useFormikContext();

	const [visible, setVisible] = useState(false);

	const styleContainer = {
		display: 'flex',
		flexDirection: 'column',
	};

	const styleImagem = {
		width: '200px',
		margin: '10px 0',
		borderRadius: '3px',
	};

	return (
		<div style={styleContainer}>
			<Subtitle subtitle="Imagem do produto" />
			<div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px', paddingTop: '8px' }}>
				<Button
					label={values.imagemUrl ? 'Alterar imagem' : 'Adicionar imagem'}
					onClick={() => {
						setVisible(true);
					}}
					disabled={!informacoesPermissoes.podeEditar}
					style={{ width: '200px' }}
				/>
				<If test={!values.imagemUrl}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							width: '200px',
							height: '200px',
							border: '1px solid rgba(128,128,128, 0.5)',
							borderRadius: '3px',
							margin: '10px 0',
						}}
					>
						<CiImageOff size={60} />
						<p>Sem imagem</p>
					</div>
				</If>
				<If test={values.imagemUrl}>
					<img src={values.imagemUrl} style={styleImagem} alt="Imagem do produto" />
				</If>
				<If test={visible}>
					<WebCam
						{...props}
						header="Imagem do produto"
						imagemFile={values.imagemFile}
						imagemUrl={values.imagemUrl}
						visible={visible}
						onHide={() => {
							setVisible(false);
						}}
					/>
				</If>
			</div>
			<If test={isGestor}>
				<Subtitle subtitle="Outros" />
				<div>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						component={SingleSelectMarca}
						label="Marca"
						name="marca"
						value={values.marca}
						url={`${services.GESTOR}/v1/produtos/relacoes/marcas`}
						onChange={(event) => setFieldValue('marca', event)}
						{...informacoesPermissoes}
					/>
				</div>
			</If>
		</div>
	);
}

export default FieldsAdicionais;
