import { services } from '../../../../../../../Common/Constantes/api';
import { del, get, exibirToast } from '../../../../../../../Common/Requisicoes';

export async function asyncGetProdutos(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetProduto(idProduto, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/produtos/${idProduto}`, null, onSuccess, onError);
}

export async function asyncDeleteProduto(idProduto, onSuccess, onError) {
	await del(`${services.GESTOR}/v1/produtos/${idProduto}`, null, exibirToast(onSuccess, 'Produto excluído'), onError);
}
