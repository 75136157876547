import { del, exibirToast, get, post, put, services } from 'Common';

export async function readCondicoesPagamento(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function readCondicaoPagamento(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/condicoes_pagamento/${id}`, null, onSuccess, onError);
}

export async function createCondicaoPagamento(data, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/condicoes_pagamento`,
		data,
		null,
		exibirToast(onSuccess, 'Condição de pagamento criada com sucesso'),
		onError
	);
}

export async function updateCondicaoPagamento(data, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/condicoes_pagamento/${data.id}`,
		data,
		null,
		exibirToast(onSuccess, 'Condição de pagamento atualizada com sucesso'),
		onError
	);
}

export async function deleteCondicaoPagamento(id, onSuccess, onError) {
	return await del(
		`${services.GESTOR}/v1/condicoes_pagamento/${id}`,
		null,
		exibirToast(onSuccess, 'Registro excluído'),
		onError
	);
}

export async function readFormasPagamento(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/condicoes_pagamento/relacoes/formas_pagamento?size=500&sort=descricao,asc`,
		null,
		onSuccess,
		onError,
		false
	);
}