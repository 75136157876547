import { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';

import { Checkbox, Dropdown, Grid, InputField, SingleSelectFormaPagamento } from 'components';
import { generateOptionsQtdParcelas, keyFilterConsultaRsql, services } from 'Common';

import {
	CONDICAO_PAGAMENTO_MOVIMENTACAO,
	CONDICAO_PAGAMENTO_MOVIMENTACAO_OPTIONS,
	CONDICAO_PAGAMENTO_SITUACAO_OPTIONS,
	CONDICAO_PAGAMENTO_TIPO,
	CONDICAO_PAGAMENTO_TIPO_OPTIONS,
	INITIAL_VALUES,
	QTD_PARCELAS_OPTIONS,
} from 'views/cadastros/CondicaoPagamento/Util/constantes';
import { FORMA_PAGAMENTO_FISCAL } from 'views/cadastros/FormaPagamento/Util/constantes';
import { useContextCondicaoPagamento } from 'views/cadastros/CondicaoPagamento/Context';

function CamposPrincipais({ isEntrada }) {
	const { values, setFieldValue, errors } = useFormikContext();
	const { informacoesPermissoes } = useContextCondicaoPagamento();

	const [editarParcelaPadrao, setEditarParcelaPadrao] = useState(false);
	const [quantidadeParcelasPadrao, setQuantidadeParcelasPadrao] = useState(QTD_PARCELAS_OPTIONS);

	useEffect(() => {
		if (editarParcelaPadrao) {
			const novasParcelas = generateOptionsQtdParcelas(values.parcelaMinima, values.parcelaMaxima);
			recalcularParcelasPadrao(novasParcelas);
			recalcularParcelasTaxas(novasParcelas);

			setEditarParcelaPadrao(false);
		}
	}, [editarParcelaPadrao]);

	function handleChangeTipo(e) {
		setFieldValue('tipo', e.value);
		if (e.value === CONDICAO_PAGAMENTO_TIPO.A_VISTA) {
			setFieldValue('parcelaMinima', null);
			setFieldValue('parcelaMaxima', null);
			setFieldValue('parcelaPadrao', null);

			setFieldValue('compensacaoAutomatica', false);
			setFieldValue('compensacaoNumeroDias', 0);

			setFieldValue('taxas', []);
		} else if (e.value === CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO) {
			setFieldValue('parcelaMinima', null);
			setFieldValue('parcelaMaxima', null);
			setFieldValue('parcelaPadrao', null);
			setFieldValue('formaPagamento', null);

			setFieldValue('compensacaoAutomatica', false);
			setFieldValue('compensacaoNumeroDias', 0);

			setFieldValue('taxas', []);
		} else {
			setFieldValue('parcelaMinima', INITIAL_VALUES.parcelaMinima);
			setFieldValue('parcelaMaxima', INITIAL_VALUES.parcelaMaxima);
			setFieldValue('parcelaPadrao', INITIAL_VALUES.parcelaPadrao);

			setFieldValue('taxas', []);
		}
	}

	function handleChangeFormaPagamento(e) {
		setFieldValue('formaPagamento', e);

		if (e.registro?.formaPagamentoFiscal === FORMA_PAGAMENTO_FISCAL.BOLETO_BANCARIO) {
			setFieldValue('compensacaoAutomatica', false);
			setFieldValue('compensacaoNumeroDias', 0);
		}
	}

	function handleChangeParcelas(field, value) {
		setFieldValue(field, value);
		setEditarParcelaPadrao(true);
	}

	function recalcularParcelasPadrao(novasParcelas) {
		setQuantidadeParcelasPadrao(novasParcelas);

		setFieldValue('parcelaPadrao', novasParcelas?.length ? novasParcelas[0]?.value : null);
	}

	function recalcularParcelasTaxas(novasParcelas) {
		if (values.taxas.length > 0) {
			const novasTaxas = values.taxas;

			novasTaxas?.forEach((taxa, index) => {
				if (taxa.valorInicial < novasParcelas[0].value) {
					novasTaxas[index].valorInicial = null;
				}
				if (taxa.valorFinal > novasParcelas[novasTaxas.length - 1].value) {
					novasTaxas[index].valorFinal = null;
				}
			});

			setFieldValue('taxas', novasTaxas);
		}
	}

	return (
		<Grid>
			<Field
				sm="12"
				md="5"
				lg="6"
				xl="6"
				component={InputField}
				label="Descrição"
				obrigatorio
				keyfilter={keyFilterConsultaRsql}
				name="descricao"
				size={60}
				id="condicao-pagamento-descricao"
				disabled={isEntrada}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				colClassName="step-condicao-pagamento-tipo"
				component={Dropdown}
				label="Tipo"
				obrigatorio
				name="tipo"
				value={values.tipo}
				onChange={handleChangeTipo}
				options={CONDICAO_PAGAMENTO_TIPO_OPTIONS}
				showClear={false}
				disabled={isEntrada}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				component={Dropdown}
				label="Movimentação"
				obrigatorio
				name="movimentacao"
				value={values.movimentacao}
				onChange={(event) => setFieldValue('movimentacao', event?.value)}
				options={CONDICAO_PAGAMENTO_MOVIMENTACAO_OPTIONS}
				showClear={false}
				disabled
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				component={Dropdown}
				label="Situação"
				obrigatorio
				name="situacao"
				value={values.situacao}
				onChange={(event) => setFieldValue('situacao', event?.value)}
				options={CONDICAO_PAGAMENTO_SITUACAO_OPTIONS}
				showClear={false}
				disabled={isEntrada}
				{...informacoesPermissoes}
			/>
			<Field
				sm="4"
				md="4"
				lg="2"
				xl="2"
				colClassName="step-condicao-pagamento-parcela-minima"
				component={Dropdown}
				label="Min. parcelas"
				name="parcelaMinima"
				obrigatorio
				value={values.parcelaMinima}
				onChange={(event) => handleChangeParcelas('parcelaMinima', event?.value)}
				showClear={false}
				options={QTD_PARCELAS_OPTIONS}
				errors={errors.parcelaMinima}
				hidden={values.tipo !== CONDICAO_PAGAMENTO_TIPO.A_PRAZO}
				disabled={isEntrada}
				{...informacoesPermissoes}
			/>
			<Field
				sm="4"
				md="4"
				lg="2"
				xl="2"
				colClassName="step-condicao-pagamento-parcela-maxima"
				component={Dropdown}
				label="Max. parcelas"
				name="parcelaMaxima"
				obrigatorio
				value={values.parcelaMaxima}
				onChange={(event) => handleChangeParcelas('parcelaMaxima', event?.value)}
				showClear={false}
				options={QTD_PARCELAS_OPTIONS}
				errors={errors.parcelaMaxima}
				hidden={values.tipo !== CONDICAO_PAGAMENTO_TIPO.A_PRAZO}
				disabled={isEntrada}
				{...informacoesPermissoes}
			/>
			<Field
				sm="4"
				md="4"
				lg="2"
				xl="2"
				colClassName="step-condicao-pagamento-parcela-padrao"
				component={Dropdown}
				label="Parcela padrão"
				name="parcelaPadrao"
				obrigatorio
				value={values.parcelaPadrao}
				onChange={(event) => setFieldValue('parcelaPadrao', event?.value)}
				showClear={false}
				options={quantidadeParcelasPadrao}
				errors={errors.parcelaPadrao}
				hidden={values.tipo !== CONDICAO_PAGAMENTO_TIPO.A_PRAZO}
				disabled={isEntrada}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="7"
				lg="3"
				xl="3"
				name="formaPagamento"
				label="Forma pagamento"
				component={SingleSelectFormaPagamento}
				style={{ flexDirection: 'row', width: '100%' }}
				value={values.formaPagamento}
				onChange={handleChangeFormaPagamento}
				url={`${services.GESTOR}/v1/condicoes_pagamento/relacoes/formas_pagamento`}
				hidden={values.tipo === CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO}
				disabled={isEntrada}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={Checkbox}
				colStyle={{
					display: 'flex',
					alignItems: 'flex-end',
					marginBottom: '7px',
					paddingBottom: '8px',
				}}
				label={`Condição favorita para ${
					values.movimentacao === CONDICAO_PAGAMENTO_MOVIMENTACAO.SAIDA ? 'saída' : 'entrada'
				}`}
				obrigatorio
				name="favorita"
				checked={values.favorita}
				onChange={() => setFieldValue('favorita', !values.favorita)}
				showClear={false}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { CamposPrincipais };
