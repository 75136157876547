export function converterUsuarioParaFormulario(usuarioServer) {
	return {
		id: usuarioServer.id,
		idCredencial: usuarioServer.credencial.id,
		nome: usuarioServer.nome,
		celular: usuarioServer.celular,
		ativa: usuarioServer.credencial.ativa,
		bloqueada: usuarioServer.credencial.bloqueada,
		email: usuarioServer.credencial.email,
		autoridade: usuarioServer.credencial?.autoridade,
		papeis: converterPapeisParaFormulario(usuarioServer.papeis),
		setores: converterSetoresParaFormulario(usuarioServer.setores),
		setorFavorito: converterSetorFavoritoParaFormulario(usuarioServer.setorFavorito),
		filiaisAcesso: converterFiliaisAcessoParaFormulario(usuarioServer.filiaisAcesso),
	};
}

function converterPapeisParaFormulario(papeis) {
	return papeis.map((papel) => {
		return {
			label: papel.nome,
			value: papel.id,
		};
	});
}

function converterSetoresParaFormulario(setores) {
	return setores.map((setor) => {
		return {
			label: setor.nome,
			value: setor.id,
		};
	});
}

function converterFiliaisAcessoParaFormulario(filiais) {
	return filiais.map((filial) => {
		return {
			label: `${filial.codigo} - ${filial.nome}`,
			value: filial.id,
		};
	});
}

function converterSetorFavoritoParaFormulario(setorFavorito) {
	return setorFavorito
		? {
				label: setorFavorito.nome,
				value: setorFavorito.id,
			}
		: null;
}

export function converterUsuarioParaApi(values) {
	return {
		id: values.id,
		nome: values.nome,
		celular: values.celular,
		papeis: values.papeis.map((papel) => {
			return { id: papel.value };
		}),
		setores: values.setores.map((setor) => {
			return { id: setor.value };
		}),
		setorFavorito: {
			id: values.setorFavorito.value,
		},
		credencial: {
			email: values.email,
			autoridade: values.autoridade,
		},
		filiaisAcesso: values.filiaisAcesso.map((filial) => {
			return { id: filial.value };
		}),
	};
}
