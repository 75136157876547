function rateioParcelas(totalLiquido, quantidadeParcelas) {
  const valorPorParcela = parseFloat(
    (totalLiquido / quantidadeParcelas).toFixed(2)
  );
  const valorUltimaParcela = parseFloat(
    (totalLiquido - valorPorParcela * (quantidadeParcelas - 1)).toFixed(2)
  );

  return {
    valorPorParcela: valorPorParcela,
    valorUltimaParcela: valorUltimaParcela,
  };
}

export { rateioParcelas };
