import { Switch, withRouter } from 'react-router-dom';
import { buscarDadosLoginLocalStorage, permissoes, recursos } from 'Common';
import PrivateRoute from 'components/Route';

import PedidoVendasOrcamentos from 'views/fiscal/vendas/PedidoVendasOrcamentos';
import PedidoVendasOrcamentosForm from 'views/fiscal/vendas/PedidoVendasOrcamentos/Form';

import NFe from 'views/fiscal/vendas/NFe';
import NFeForm from 'views/fiscal/vendas/NFe/Form';

import NFCeForm from 'views/fiscal/vendas/NFCe/Form';
import NFCeProvider from 'views/fiscal/vendas/NFCe/Context';

import VendasRelatorios from 'views/fiscal/vendas/Relatorios';

export const pathVendasRoutes = [
	'/orcamento',
	'/orcamento/cadastro',
	'/orcamento/cadastro/:id',
	'/pedido_venda',
	'/pedido_venda/cadastro',
	'/pedido_venda/cadastro/:id',
	'/pedido_venda/importar_orcamento/:id',
	'/nfes',
	'/nfes/cadastro',
	'/nfes/cadastro/:id',
	'/nfes/importar_pedido/:id',
	'/nfces',
	'/nfces/cadastro',
	'/nfces/cadastro/:id',
	'/nfces/importar_pedido/:id',
	'/relatorios_vendas',
];

function VendasRoutes() {
	return (
		<Switch>
			<PrivateRoute
				exact
				path={['/orcamento']}
				render={(props) => <PedidoVendasOrcamentos {...props} />}
				recurso={recursos.VENDAS_VENDAS}
				permissao={buscarDadosLoginLocalStorage().organizacao?.aplicacao !== 'GESTOR_MEI' ? permissoes.VISUALIZAR : ''}
			/>
			<PrivateRoute
				exact
				path={['/orcamento/cadastro', '/orcamento/cadastro/:id']}
				render={(props) => <PedidoVendasOrcamentosForm {...props} />}
				recurso={recursos.VENDAS_VENDAS}
				permissao={buscarDadosLoginLocalStorage().organizacao?.aplicacao !== 'GESTOR_MEI' ? permissoes.VISUALIZAR : ''}
			/>
			<PrivateRoute
				exact
				path={['/pedido_venda']}
				render={(props) => <PedidoVendasOrcamentos {...props} />}
				recurso={recursos.VENDAS_VENDAS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/pedido_venda/cadastro', '/pedido_venda/cadastro/:id']}
				render={(props) => <PedidoVendasOrcamentosForm {...props} />}
				recurso={recursos.VENDAS_VENDAS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/pedido_venda/importar_orcamento/:id']}
				render={(props) => <PedidoVendasOrcamentosForm {...props} />}
				recurso={recursos.VENDAS_VENDAS}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path={['/nfes']}
				render={(props) => <NFe {...props} />}
				recurso={recursos.VENDAS_NOTAS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/nfes/cadastro', '/nfes/cadastro/:id']}
				render={(props) => <NFeForm {...props} />}
				recurso={recursos.VENDAS_NOTAS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/nfes/importar_pedido/:id']}
				render={(props) => <NFeForm {...props} />}
				recurso={recursos.VENDAS_NOTAS}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path={['/nfces', '/nfces/cadastro', '/nfces/cadastro/:id', '/nfces/importar_pedido/:id']}
				render={(props) => (
					<NFCeProvider>
						<NFCeForm {...props} />
					</NFCeProvider>
				)}
				recurso={recursos.VENDAS_NOTAS_CONSUMIDOR}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path={['/relatorios_vendas']}
				render={(props) => <VendasRelatorios {...props} />}
				recurso={recursos.VENDAS_RELATORIOS}
				permissao={permissoes.VISUALIZAR}
			/>
		</Switch>
	);
}

export default withRouter(VendasRoutes);
