import { tratarErroRequisicoes } from '../../../../Common/TratamentoDeErro/tratamentoDeMensagens';

export function tratarMensagemErroLogin(response) {
  if (!response) return 'Erro de conexão com a internet';

  if (response.data && response.data.details) {
    return response.data.details[0];
  } else {
    tratarErroRequisicoes(response);
  }
}
