import { addMonths, formatISO } from 'date-fns';

export const CORES_CARDS = {
	SUCCESS: {
		light: '#DCEDC8',
		strongColor: '#1B5E20',
	},
	DANGER: {
		light: '#FFCDD2',
		strongColor: '#B71C1C',
	},
	WARNING: {
		light: '#FFECB3',
		strongColor: '#765100',
	},
	INFO: {
		light: '#B3E5FC',
		strongColor: '#006095',
	},
	NEUTRAL: {
		light: '#E7ECF0',
		strongColor: '#333333',
	},
};

export const CONTA_RECEBER_STATUS = {
	NAO_RECEBIDA: 'NAO_RECEBIDA',
	PARCIALMENTE_RECEBIDA: 'PARCIALMENTE_RECEBIDA',
	RECEBIDA: 'RECEBIDA',
	REPARCELADA: 'REPARCELADA',
};

export const CONTA_PAGAR_STATUS = {
	NAO_PAGA: 'NAO_PAGA',
	PARCIALMENTE_PAGA: 'PARCIALMENTE_PAGA',
	PAGA: 'PAGA',
	REPARCELADA: 'REPARCELADA',
};

export const CONDICAO_PAGAMENTO_COMPENSACAO_TIPO = {
	EMISSAO: 'EMISSAO',
	VENCIMENTO: 'VENCIMENTO',
};

export const CONDICAO_PAGAMENTO_COMPENSACAO_TIPO_OPTIONS = [
	{ label: 'Emissão', value: CONDICAO_PAGAMENTO_COMPENSACAO_TIPO.EMISSAO },
	{ label: 'Vencimento', value: CONDICAO_PAGAMENTO_COMPENSACAO_TIPO.VENCIMENTO },
];

export const CONDICAO_PAGAMENTO_TAXA_TIPO = {
	PERCENTUAL: 'PERCENTUAL',
	VALOR: 'VALOR',
};

export const CONDICAO_PAGAMENTO_TAXA_TIPO_OPTIONS = [
	{ label: 'Percentual', value: CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL },
	{ label: 'Valor', value: CONDICAO_PAGAMENTO_TAXA_TIPO.VALOR },
];

export const INITIAL_VALUES_RECEBER = {
	id: null,
	identificador: null,
	valor: 0,
	emissao: formatISO(new Date()),
	vencimento: formatISO(addMonths(new Date(), 1)),
	status: CONTA_RECEBER_STATUS.NAO_RECEBIDA,
	descricao: null,
	pessoa: null,
	formaPagamento: null,
	conta: null,
	categoria: null,
	tipo: 'UNICA',
	quantidadeRepeticoes: 2,
	ocorrencia: 'MENSAL',
	recebido: false,
	valorRecebido: null,
	dataRecebido: formatISO(new Date()),
	contaRecebido: null,
	pix: null,
	boleto: null,

	competencia: formatISO(new Date()),
	numeroDocumento: null,
	dataDocumento: formatISO(new Date()),
	observacao: null,

	compensacaoAutomatica: false,
	tipoCompensacao: CONDICAO_PAGAMENTO_COMPENSACAO_TIPO.VENCIMENTO,
	dataCompensacao: formatISO(addMonths(new Date(), 1)),
	tipoTaxa: CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL,
	taxaCategoria: null,
	taxaAliquota: 0,
	taxaValor: 0,

	origem: 'CONTA_RECEBER',
	recebimentos: [],
	activeIndex: null,

	valorJuros: 0,
	valorMulta: 0,
	valorDesconto: 0,
	valorTitulos: 0,
	valorTitulosNovos: 0,
};

export const INITIAL_VALUES_PAGAR = {
	id: null,
	descricao: null,
	categoria: null,
	tipo: 'UNICA',
	emissao: formatISO(new Date()),
	formaPagamento: null,
	conta: null,
	vencimento: formatISO(addMonths(new Date(), 1)),
	status: CONTA_PAGAR_STATUS.NAO_PAGA,
	valor: 0,
	ocorrencia: 'MENSAL',
	quantidadeRepeticoes: 2,
	origem: 'CONTA_PAGAR',

	pago: false,
	valorPago: null,
	dataPago: formatISO(new Date()),
	contaPago: null,

	pagamentos: [],

	pessoa: null,
	competencia: formatISO(new Date()),
	dataDocumento: formatISO(new Date()),
	numeroDocumento: null,
	observacao: null,

	compensacaoAutomatica: false,
	tipoCompensacao: CONDICAO_PAGAMENTO_COMPENSACAO_TIPO.VENCIMENTO,
	dataCompensacao: formatISO(addMonths(new Date(), 1)),
	tipoTaxa: CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL,
	taxaCategoria: null,
	taxaAliquota: 0,
	taxaValor: 0,

	activeIndex: null,

	valorJuros: 0,
	valorMulta: 0,
	valorDesconto: 0,
	valorTitulos: 0,
	valorTitulosNovos: 0,
};
