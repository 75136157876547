import { If, Modal } from 'components';
import ObjetoDeServicoForm from '../Form';

export default function ModalObjetoDeServico(props) {
  return (
    <Modal
      className={props.visible ? 'modalDeUmAsyncSelect' : undefined}
      header="Cadastro de objeto de serviço"
      visible={props.visible}
      onHide={props.onHide}
      styleModal={{ maxWidth: '1200px' }}
    >
      <If test={props.visible}>
        <ObjetoDeServicoForm hideModal={props.onHide} isModal={true} history={props.history} cliente={props.cliente} />
      </If>
    </Modal>
  );
}
