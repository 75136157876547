import { useState, useEffect } from 'react';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types';
import ModalConta from '../../../views/cadastros/Contas/components/ModalConta';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';

export default function SingleSelectConta(props) {
	const { url, service, onlyRecebePix } = props;

	const [visible, setVisible] = useState(false);
	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.INSERIR)
	);
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.VISUALIZAR)
	);
	const [resetPesquisa, setResetPesquisa] = useState(false);

	useEffect(() => {
		if (visible) {
			setTimeout(() => {
				document.getElementById('ContasInputFieldNomeConta')?.focus();
			}, 500);
		}
	}, [visible]);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {		
		return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVA"${onlyRecebePix ? ';recebePix=="true"' : ''}&page=${page}&size=50`;
	}

	function buscarUrlRegistroUnico(id) {
		return `${url}?query=id==${id}`;
	}

	function montarLabel(row) {
		return row.nome;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			props.onChange({ label: novoRegistro.nome, value: novoRegistro.id });
			setResetPesquisa(true);
		}
	}

	function esconderModal() {
		setVisible(false);
	}

	return (
		<>
			<ModalConta service={service} visible={visible} insertJustOne={true} onHide={onHide} />
			<SingleSelect
				buscarUrlPesquisa={buscarUrlPesquisa}
				buscarUrlRegistroUnico={buscarUrlRegistroUnico}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				onChange={props.onChange}
				disabledButton={!podeInserirEVisualizar}
				titleBotaoNovo={
					podeInserirEVisualizar
						? 'Adicionar nova conta'
						: 'Você não possui permissão para executar esta ação'
				}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
				{...props}
			/>
		</>
	);
}

SingleSelectConta.defaultProps = {
	label: 'Conta',
};

SingleSelectConta.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
};
