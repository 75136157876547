export function converterModeloCodigoServicoParaApi(cabecalho, tributacoes) {
	return {
		descricao: cabecalho.nome,
		tributacoes: converterTributacoesParaApi(tributacoes),
	};
}

function converterTributacoesParaApi(tributacoes) {
	let listaTributacoes = tributacoes.map(tributacao => {
		return {
			operacaoFiscal: { id: tributacao.operacaoFiscal.value },
			tributacaoMunicipal: { id: tributacao.tributacaoMunicipal.value },
		};
	});
	return listaTributacoes;
}

export function converterNovaTributacaoParaFormulario(tributacoes) {
	let tributacoesConvetidas = [];
	tributacoes.forEach(tributacao => {
		tributacoesConvetidas.push({
			situacaoOperacao: tributacao.situacaoOperacao,
			situacaoTributacao: tributacao.situacaoTributacao,
			operacaoFiscal: {
				label: `${tributacao.codigoOperacao} - ${tributacao.descricaoOperacao}`,
				value: tributacao.idOperacao,
			},
			tributacaoMunicipal: {
				label: `${tributacao.codigoTributacao} - ${tributacao.descricaoTributacao}`,
				value: tributacao.idTributacao,
			},
		});
	});
	return tributacoesConvetidas;
}
