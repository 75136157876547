import { Field, useFormikContext } from 'formik';
import { useUpdateEffect } from 'react-use';

import { keyFilterConsultaRsql, removerCaracteres } from 'Common';
import { Col, Divider, Dropdown, If, InputField, Message, SingleSelectMunicipio, ToastTypes, notify } from 'components';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { useContextRegiaoVenda } from 'views/cadastros/Regiao/Context';
import {
	excluirMunicipiosDoEstado,
	inserirMunicipio,
	inserirMunicipiosDoEstado,
	salvarRegiao,
} from 'views/cadastros/Regiao/Requests';
import MultipleSelectEstados from 'components/select/MultipleSelectEstados';
import { CADASTROURL, SITUACAO_REGIAO } from '../../Utils/constantes';
import { converterFormParaApi } from '../../Utils/regiaoConverter';

import './index.css';

function HeaderRegiao({ fetchCallback }) {
	const { values, setFieldValue, errors } = useFormikContext();
	const {
		idRegiao,
		informacoesPermissoes,
		estadosLocal,
		setEstadosLocal,
		municipio,
		sortField,
		sortOrder,
		page,
		size,
		history,
		setControleBuscarMunicipiosPaginados,
	} = useContextRegiaoVenda();

	useUpdateEffect(() => {
		fetchCallback();
	}, [page, size, sortOrder, sortField]);

	function validacaoMunicipios() {
		if (values.municipios?.length === 0) {
			return 'Selecione ao menos um município';
		} else {
			return null;
		}
	}

	function handleMunicipio(municipio) {
		salvarMunicipio(municipio.value ?? municipio.registro.id);
	}

	async function salvarMunicipio(idMunicipio) {
		if (idRegiao) {
			inserirMunicipio(idRegiao ?? null, idMunicipio, notify('Município salvo com sucesso', ToastTypes.SUCCESS));
			setControleBuscarMunicipiosPaginados(true);
		} else {
			salvarRegiao(converterFormParaApi(values), async ({ data: response }) => {
				await inserirMunicipio(response.id, idMunicipio, notify('Município salvo com sucesso', ToastTypes.SUCCESS));
				atualizarUrl(history, CADASTROURL, response.id, metodosAtualizarUrl.PUSH);
				setControleBuscarMunicipiosPaginados(true);
			});
		}
	}

	function handleEstado(estado) {
		const addedItem = estado.find((newItem) => !estadosLocal.some((oldItem) => oldItem.value === newItem.value));
		const removedItem = estadosLocal.find((oldItem) => !estado.some((newItem) => newItem.value === oldItem.value));

		setEstadosLocal(estado);
		setFieldValue('estados', estado);

		if (addedItem) {
			salvarMunicipiosDoEstado(addedItem);
		} else if (removedItem) {
			removerMunicipiosDoEstado(removedItem);
		}
	}

	async function salvarMunicipiosDoEstado(estado) {
		if (idRegiao) {
			await inserirMunicipiosDoEstado(
				idRegiao ?? null,
				estado.value,
				notify('Municípios do estado salvos com sucesso', ToastTypes.SUCCESS)
			);
			setControleBuscarMunicipiosPaginados(true);
		} else {
			salvarRegiao(converterFormParaApi(values), async ({ data: response }) => {
				await inserirMunicipiosDoEstado(
					response.id,
					estado.value,
					notify('Municípios do estado salvos com sucesso', ToastTypes.SUCCESS)
				);
				atualizarUrl(history, CADASTROURL, response.id, metodosAtualizarUrl.POP);
				setControleBuscarMunicipiosPaginados(true);
			});
		}
	}

	async function removerMunicipiosDoEstado(estado) {
		await excluirMunicipiosDoEstado(
			idRegiao,
			estado.value,
			notify('Municípios do estado removidos com sucesso', ToastTypes.SUCCESS)
		);
		setControleBuscarMunicipiosPaginados(true);
	}

	return (
		<>
			<Field
				sm="12"
				md="8"
				lg="8"
				xl="8"
				id="InputNomeRegiao"
				className="step-regiao-nome"
				component={InputField}
				label="Nome"
				obrigatorio
				name="nome"
				onBlur={() => {
					if (values.nome) {
						setFieldValue('nome', values.nome.trim());
					}
				}}
				onChange={(e) => setFieldValue('nome', removerCaracteres(e.target?.value, ['─']))}
				value={values.nome}
				size={120}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				className="step-regiao-situacao input-correction"
				component={Dropdown}
				label="Situação"
				name="situacao"
				value={values.situacao}
				onChange={(event) => setFieldValue('situacao', event.value)}
				options={SITUACAO_REGIAO}
				showClear={false}
				{...informacoesPermissoes}
			/>
			<Divider />
			<If test={errors.municipios}>
				<Col sm="12" md="12" lg="12" xl="12">
					<Message severity="warn" text={validacaoMunicipios()} />
				</Col>
			</If>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				className="step-regiao-select-municipio input-correction"
				component={SingleSelectMunicipio}
				label="Adicionar município a região"
				name="municipio"
				value={municipio}
				optionsRemover={values.municipios}
				onChange={(e) => handleMunicipio(e)}
				keyfilter={keyFilterConsultaRsql}
				disabled={!values.nome}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="8"
				lg="8"
				xl="8"
				className="step-regiao-select-estado input-correction"
				component={MultipleSelectEstados}
				label="Adicionar todos os municípios do estado a região"
				name="estados"
				onChange={(e) => handleEstado(e)}
				showClear={false}
				value={values.estados}
				keyfilter={keyFilterConsultaRsql}
				disabled={!values.nome}
				isClearable={false}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export { HeaderRegiao };
