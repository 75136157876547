import { manterApenasNumeros } from '../../../../../../Common/Mascara';

export function converterCartaoParaApi(cartaoReact) {
	let brand;
	switch (cartaoReact.bandeira) {
		case 'mastercard':
			brand = 'Master';
			break;
		case 'visa':
			brand = 'Visa';
			break;
		case 'amex':
			brand = 'Amex';
			break;
		case 'elo':
			brand = 'Elo';
			break;
		case 'jcb':
			brand = 'JCB';
			break;
		case 'dinersclub':
			brand = 'Diners';
			break;
		case 'discover':
			brand = 'Discover';
			break;
		case 'hipercard':
			brand = 'Hipercard';
			break;
		default:
			brand = 'unknown';
			break;
	}
	return {
		nomeCompleto: cartaoReact.nomeImpresso,
		numero: manterApenasNumeros(cartaoReact.num),
		titular: cartaoReact.nomeImpresso,
		expiracao: cartaoReact.expiracao,
		bandeira: brand,
		codigoSeguranca: cartaoReact.codigoSeguranca.trim(),
	};
}
