export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é o cadastro de "Veículo". Os veículos são utilizados no módulo de transporte.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-veiculo-campos-principais',
		content:
			'Esses são os campos principais para preenchimento, sendo a maioria deles obrigatórios, por serem requeridos pelo fisco.',
		placement: 'top',
	},
	{
		target: '.step-veiculo-proprietario',
		content:
			'No campo "Proprietário" pode ser vinculado uma pessoa, essa informação é utilizada quando o MDF-e for um transporte de carga de terceiros.',
		placement: 'top',
	},
	{
		target: '.step-veiculo-tabs',
		content:
			'Nessa área podem ser vinculados os condutores e os reboques (Cadastro de veículo do tipo "Reboque"). Quando esses campos são preenchidos, são automaticamente utilizados no MDF-e ao preencher o veículo de transporte.',
		placement: 'top',
	},
];
