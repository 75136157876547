import { useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { buscarDadosLoginLocalStorage, copiarObjeto, gerarUUID } from 'Common';
import { ButtonAdicionarItem, Col, Grid, If } from 'components';

import { ListaProdutos } from './components/ListaProdutos';
import { ModalProduto } from './components/ModalProduto';
import { moduloUtilizado, NOVO_PRODUTO_VENDA } from './Util/constantes';
import { montarECalcularTributacaoProduto } from './Util/util';

function ProdutosImpl({
	informacoesPermissoes,
	disabled,
	disabledButtonAdd,
	color,
	isMobile,
	isTablet,
	onChangeProdutos,
	onChangeProduto,
	calcularPercentualAdicionalTabelaPreco,
	atualizarTotalizadores,
	utilizaPrecoAtacado,
	operacaoFiscalHeader,
	comissaoHeader,
	descontoMaximoVendedor,
	descontoMaximoSuperior,
	modulo,
	existeParcelaRecebida,
	consumidorFinal,
	tipoOperacao,
	finalidadeDocumento,
	pessoa,
	tabelaPreco,
	pagamentos,
	recalcularTodosOsProdutosComTabelaPreco,
	setRecalcularTodosOsProdutosComTabelaPreco,
}) {
	const { values, setFieldValue } = useFormikContext();
	const [visibleModalProduto, setVisibleModalProduto] = useState(false);
	const [indexModalProduto, setIndexModalProduto] = useState(null);
	const [atualizarProdutos, setAtualizarProdutos] = useState(false);
	const [atualizarTotal, setAtualizarTotal] = useState(false);

	const filialConectada = buscarDadosLoginLocalStorage()?.filialConectada;

	const utilizaTabelaPreco = filialConectada?.parametrosVendas?.utilizaTabelaPreco;
	const decimaisPreco = filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	useEffect(() => {
		if (atualizarTotal) {
			atualizarTotalizadores();

			setAtualizarTotal(false);
		}
	}, [atualizarTotal]);

	useEffect(() => {
		if (atualizarProdutos) {
			onChangeProdutos(values.produtos);
			setAtualizarProdutos(false);
		}
	}, [atualizarProdutos]);

	function handleClickAdicionarProduto() {
		setFieldValue('produtos', [
			...values.produtos,
			{
				...copiarObjeto(NOVO_PRODUTO_VENDA),
				id: gerarUUID(),
				item: values.produtos.length + 1,
				operacaoFiscal: values?.operacaoFiscal ?? null,
			},
		]);

		setTimeout(() => {
			const elementHtmlProduto = document
				.getElementById('id-produtos-listagem')
				?.getElementsByTagName('table')[0]
				?.getElementsByTagName('tr')
				[values.produtos.length + 1]?.getElementsByClassName('id-produtos-listagem-campo-produto')[0];
			elementHtmlProduto?.click();
		}, 200);

		setAtualizarProdutos(true);
	}

	async function montarProduto(prod, value) {
		let produto = prod;
		if (value) {
			if (utilizaTabelaPreco) {
				const adicionalTabelaPreco = await calcularPercentualAdicionalTabelaPreco(produto.quantidade);
				produto.adicionalTabelaPreco = { percentual: adicionalTabelaPreco.percentual, tipo: adicionalTabelaPreco.tipo };
			}

			let subtotal = value.registro.preco;
			if (utilizaPrecoAtacado && value.registro?.precoAtacado > 0) {
				const valor = utilizaTabelaPreco
					? calcularValorVenda(value.registro?.precoAtacado, produto.adicionalTabelaPreco)
					: value.registro?.precoAtacado;

				produto.valor = parseFloat(valor.toFixed(decimaisPreco));
				produto.valorOriginal = parseFloat(value.registro?.precoAtacado.toFixed(decimaisPreco));
				produto.subtotal = produto.quantidade * valor;
			} else {
				const valor = utilizaTabelaPreco
					? calcularValorVenda(value.registro?.preco, produto.adicionalTabelaPreco)
					: value.registro?.preco;

				produto.valor = parseFloat(valor.toFixed(decimaisPreco));
				produto.valorOriginal = parseFloat(value.registro?.preco.toFixed(decimaisPreco));
				subtotal = produto.quantidade * valor;
			}
			produto.alterouValorManualmente = false;

			produto.subtotal = subtotal;

			if (produto?.desconto > 0) {
				produto.subtotal -= produto.desconto;
			}

			produto.subtotal = parseFloat(produto.subtotal.toFixed(2));

			value.registro?.ncm
				? (produto.ncm = {
						label: `${value.registro?.ncm.codigo} - ${value.registro?.ncm.descricao}`,
						registro: value.registro?.ncm,
						value: value.registro?.ncm.id,
					})
				: null;
			value.registro?.cest
				? (produto.cest = {
						label: `${value.registro?.cest.codigo} - ${value.registro?.cest.descricao}`,
						registro: value.registro?.cest,
						value: value.registro?.cest.id,
					})
				: null;
			value.registro?.origem ? (produto.tributos.icms.origemProduto = value.registro?.origem) : null;
			value.registro?.unidadeMedida
				? (produto.unidadeMedida = {
						label: value.registro?.unidadeMedida.label
							? value.registro?.unidadeMedida.label
							: `${value.registro?.unidadeMedida.unidade} - ${value.registro?.unidadeMedida.descricao}`,
						registro: value.registro?.unidadeMedida,
						value: value.registro?.unidadeMedida.value
							? value.registro?.unidadeMedida.value
							: value.registro?.unidadeMedida.id,
					})
				: null;
			value.registro?.codigoBarras ? (produto.codigoBarras = value.registro?.codigoBarras) : null;
			operacaoFiscalHeader?.value && !produto.operacaoFiscal ? (produto.operacaoFiscal = operacaoFiscalHeader) : null;

			if (value.registro?.descontoMaximo || descontoMaximoVendedor || descontoMaximoSuperior) {
				if (descontoMaximoSuperior) {
					produto.descontoMaximoPercentual = descontoMaximoSuperior;
				} else {
					const maxDesconto = value.registro?.descontoMaximo ?? descontoMaximoVendedor;
					produto.descontoMaximoPercentual = descontoMaximoVendedor
						? Math.min(maxDesconto, descontoMaximoVendedor)
						: maxDesconto;
				}
			} else {
				produto.descontoMaximoPercentual = 100;
			}

			produto.descontoMaximoVendedor = descontoMaximoSuperior || descontoMaximoVendedor;
			produto.descontoMaximoTabela = value.registro?.descontoMaximo || 100;

			if (value.registro?.comissao || comissaoHeader) {
				let comissaoPercentual = 0;
				value.registro?.comissao
					? (comissaoPercentual = value.registro?.comissao)
					: (comissaoPercentual = comissaoHeader ?? null);

				produto.comissaoPercentual = comissaoPercentual;
				produto.comissaoValor =
					comissaoPercentual > 0 ? parseFloat(((comissaoPercentual / 100) * produto.subtotal).toFixed(2)) : 0;
			} else {
				produto.comissaoPercentual = 0;
				produto.comissaoValor = 0;
			}

			if (utilizaTabelaPreco && value.registro?.tabelaPreco) {
				produto.utilizouTabelaPromocional = value.registro?.tabelaPreco?.tabelaPromocional;
				produto.tabelaPreco = {
					label: `${value.registro?.tabelaPreco.codigo} - ${value.registro?.tabelaPreco.nome}`,
					registro: value.registro?.tabelaPreco,
					value: value.registro?.tabelaPreco.id,
				};
			}

			if (modulo !== moduloUtilizado.ORCAMENTO && produto && produto.operacaoFiscal && pessoa) {
				produto = await montarECalcularTributacaoProduto(produto, pessoa);
			}
		}

		return produto;
	}

	function calcularValorVenda(valor, adicionalTabelaPreco) {
		let preco = valor;
		const percentual = adicionalTabelaPreco.percentual / 100;

		if (adicionalTabelaPreco.tipo === 'ACRESCIMO') {
			preco += valor * percentual;
		} else if (adicionalTabelaPreco.tipo === 'DESCONTO') {
			preco -= valor * percentual;
			preco = Math.max(preco, 0);
		}

		return preco;
	}

	return (
		<>
			<Grid>
				<ListaProdutos
					isMobile={isMobile}
					isTablet={isTablet}
					disabled={disabled}
					informacoesPermissoes={informacoesPermissoes}
					setVisibleModalProduto={setVisibleModalProduto}
					setIndexModalProduto={setIndexModalProduto}
					setAtualizarProdutos={setAtualizarProdutos}
					adicionarNovoProduto={handleClickAdicionarProduto}
					onChangeProduto={onChangeProduto}
					onChangeProdutos={onChangeProdutos}
					utilizaPrecoAtacado={utilizaPrecoAtacado}
					utilizaTabelaPreco={utilizaTabelaPreco}
					operacaoFiscalHeader={operacaoFiscalHeader}
					modulo={modulo}
					existeParcelaRecebida={existeParcelaRecebida}
					descontoMaximoSuperior={descontoMaximoSuperior}
					montarProduto={montarProduto}
					tabelaPreco={tabelaPreco}
					pagamentos={pagamentos}
					calcularValorVenda={calcularValorVenda}
					calcularPercentualAdicionalTabelaPreco={calcularPercentualAdicionalTabelaPreco}
					recalcularTodosOsProdutosComTabelaPreco={recalcularTodosOsProdutosComTabelaPreco}
					setRecalcularTodosOsProdutosComTabelaPreco={setRecalcularTodosOsProdutosComTabelaPreco}
				/>
			</Grid>

			<Grid style={{ paddingTop: '10px' }}>
				<Col sm="8" md="8" lg="8" xl="8" style={{ padding: '8px 0px 0px 0px' }}>
					<ButtonAdicionarItem
						label="Adicionar produto"
						style={{
							background: 'none',
							border: 'none',
							fontWeight: 'bold',
							boxShadow: 'none',
							padding: '0rem 1.2rem',
							color: color,
						}}
						onClick={handleClickAdicionarProduto}
						tabIndex={-1}
						disabled={!informacoesPermissoes?.podeInserir || disabledButtonAdd}
					/>
				</Col>
			</Grid>

			<If test={visibleModalProduto}>
				<ModalProduto
					visibleModalProduto={visibleModalProduto}
					produtos={values?.produtos}
					onHide={() => setVisibleModalProduto(false)}
					onChangeProduto={onChangeProduto}
					indexProduto={indexModalProduto}
					disabled={disabled}
					isMobile={isMobile}
					isTablet={isTablet}
					consumidorFinal={consumidorFinal}
					tipoOperacao={tipoOperacao}
					finalidadeDocumento={finalidadeDocumento}
					informacoesPermissoes={informacoesPermissoes}
					pessoa={pessoa}
					montarProduto={montarProduto}
					setFieldValueProduto={setFieldValue}
					modulo={modulo}
					setAtualizarTotal={setAtualizarTotal}
					utilizaTabelaPreco={utilizaTabelaPreco}
					calcularValorVenda={calcularValorVenda}
					tabelaPreco={tabelaPreco}
					calcularPercentualAdicionalTabelaPreco={calcularPercentualAdicionalTabelaPreco}
				/>
			</If>
		</>
	);
}

const ProdutosFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues({ produtos }) {
		if (produtos?.length > 0) {
			return { produtos: [...produtos] };
		}
		return { produtos: [] };
	},

	mapPropsToErrors({ errors }) {
		return errors ?? [];
	},

	handleSubmit: () => {},
})(ProdutosImpl);

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
		isTablet: state.dispositivo.isTablet,
		isLessHd: state.dispositivo.isLessHd,
	};
}

export const Produtos = connect(mapStateToProps)(ProdutosFormik);
