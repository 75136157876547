import { Field, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { buscarDadosLoginLocalStorage, services } from 'Common';
import {
	Dropdown,
	Grid,
	If,
	InputPercentage,
	SingleSelectTabelaPreco,
	SingleSelectVendedor,
	tipoBuscaVendedorFiltro,
} from 'components';

import { useContextVendedores } from 'views/cadastros/Vendedores/Context';

import {
	ORIGEM_COBRANCA_VENDEDORES,
	SITUACAO_VENDEDORES,
	TIPO_VENDEDORES,
	TIPO_VENDEDORES_OPTIONS,
} from '../../../../Utils/constantes';

function TabConfiguracoes({ isModal, hasMultipleFiliais }) {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, usuarioPadrao } = useContextVendedores();
	const [disableSuperior, setDisableSuperior] = useState(true);
	const [disableDescendenteFavorito, setDisableDescendenteFavorito] = useState(true);

	const { filialConectada } = buscarDadosLoginLocalStorage();

	const hasUsuario = !!values.usuario?.value;

	useEffect(() => {
		if (values.tipo === 'GERENTE') {
			setFieldValue('superior', null);
			setFieldValue('descendenteFavorito', null);
			setDisableSuperior(true);
			setDisableDescendenteFavorito(true);
		} else if (values.tipo === 'SUPERVISOR') {
			setDisableSuperior(false);
			setDisableDescendenteFavorito(false);
		} else if (values.tipo === 'VENDEDOR') {
			setFieldValue('descendenteFavorito', null);
			setDisableSuperior(false);
			setDisableDescendenteFavorito(true);
		}
	}, [values.tipo]);

	function onChangeTipo(event) {
		setFieldValue('tipo', event.value);
		setFieldValue('superior', null);

		if (event.value !== TIPO_VENDEDORES.VENDEDOR && values.regioesTabelaPrecos?.length > 0) {
			setFieldValue('regioesTabelaPrecos', []);
		}
		if (event.value === TIPO_VENDEDORES.VENDEDOR && values.descendenteFavorito) {
			setFieldValue('descendenteFavorito', null);
		}
	}

	return (
		<Grid style={{ padding: '8px' }} className="step-vendedor-configuracoes">
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				className="step-vendedor-tipo input-correction"
				component={Dropdown}
				label="Tipo"
				name="tipo"
				value={values.tipo}
				onChange={(event) => onChangeTipo(event)}
				options={TIPO_VENDEDORES_OPTIONS}
				disabled={isModal?.isModal || !hasUsuario}
				showClear={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				className="step-vendedor-situacao input-correction"
				component={Dropdown}
				label="Situação"
				name="situacao"
				value={values.situacao}
				onChange={(event) => setFieldValue('situacao', event.value)}
				disabled={isModal?.isModal || !hasUsuario}
				options={SITUACAO_VENDEDORES}
				showClear={false}
				{...informacoesPermissoes}
			/>
			<If test={!hasMultipleFiliais}>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={SingleSelectTabelaPreco}
					label="Tabela de preço"
					name="filiais[0].tabelaPreco"
					value={values.filiais[0]?.tabelaPreco}
					onChange={(e) => setFieldValue(`filiais[0].tabelaPreco`, e)}
					url={`${services.GESTOR}/v1/vendas/vendedor/relacoes/tabela_preco`}
					idFilialParaPesquisa={values.filiais?.length > 0 ? values.filiais[0]?.filial?.id : filialConectada?.id}
					disabledButton
					esconderBotao
					disabled={!hasUsuario || values.tipo !== 'VENDEDOR'}
					{...informacoesPermissoes}
				/>
			</If>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={SingleSelectVendedor}
				label="Vendedor favorito"
				name="descendenteFavorito"
				tipoVendedor={values.tipo}
				tipoBusca={tipoBuscaVendedorFiltro.DESCENDENTE}
				value={values.descendenteFavorito}
				onChange={(e) => setFieldValue('descendenteFavorito', e)}
				url={`${services.GESTOR}/v1/vendas/vendedor/${
					values.usuario?.value ?? usuarioPadrao?.id
				}/relacoes/descendentes`}
				disabled={disableDescendenteFavorito || !hasUsuario}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg={hasMultipleFiliais ? '5' : '6'}
				xl={hasMultipleFiliais ? '5' : '6'}
				component={SingleSelectVendedor}
				label={values.tipo === TIPO_VENDEDORES.VENDEDOR ? 'Supervisor' : 'Gerente'}
				name="superior"
				tipoVendedor={values.tipo}
				tipoBusca={tipoBuscaVendedorFiltro.SUPERIOR}
				value={values.superior}
				onChange={(e) => setFieldValue('superior', e)}
				url={`${services.GESTOR}/v1/vendas/vendedor/${values.usuario?.value ?? usuarioPadrao?.id}/relacoes/superiores`}
				disabled={disableSuperior || !hasUsuario}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg={hasMultipleFiliais ? '2' : '4'}
				xl={hasMultipleFiliais ? '2' : '4'}
				component={InputPercentage}
				label="Desconto máx. %"
				name="descontoMaximo"
				onChange={(e) => setFieldValue('descontoMaximo', e.target?.value)}
				value={values.descontoMaximo}
				suffix=" %"
				placeholder="0 %"
				size={13}
				allowNegative={false}
				disabled={!hasUsuario}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg={hasMultipleFiliais ? '2' : '4'}
				xl={hasMultipleFiliais ? '2' : '4'}
				component={InputPercentage}
				label="Comissão %"
				name="comissao"
				onChange={(e) => setFieldValue('comissao', e.target?.value)}
				value={values.comissao}
				suffix=" %"
				placeholder="0 %"
				size={13}
				allowNegative={false}
				disabled={!hasUsuario}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg={hasMultipleFiliais ? '3' : '4'}
				xl={hasMultipleFiliais ? '3' : '4'}
				component={Dropdown}
				label="Origem cobrança comissão"
				name="origemCobranca"
				value={values.origemCobranca}
				onChange={(event) => setFieldValue('origemCobranca', event.value)}
				options={ORIGEM_COBRANCA_VENDEDORES}
				showClear={false}
				disabled={!hasUsuario}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}
export { TabConfiguracoes };
