import { services } from 'Common';
import { InputDate, InputMoney, SingleSelectConta } from 'components';
import { Field, useFormikContext } from 'formik';
import { helpMessage } from '../../Util/constantes';

function FormValorPago({ informacoesPermissoes }) {
	const { values, setFieldValue, errors } = useFormikContext();

	return (
		<>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputMoney}
				label="Valor pago"
				name="valorPago"
				obrigatorio
				value={values.valorPago}
				onChange={(event) => setFieldValue('valorPago', event.target.value)}
				size={12}
				allowNegative={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={InputDate}
				label="Data do pagamento"
				name="dataPago"
				obrigatorio
				onChange={(event) => setFieldValue('dataPago', event.target.value)}
				value={values.dataPago}
				errors={errors?.dataPago}
				touched={!!errors.dataPago}
				useFormErrors={false}
				useFormTouched={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				name="contaPago"
				label="Conta"
				obrigatorio
				component={SingleSelectConta}
				value={values.contaPago}
				helpMessage={helpMessage.conta}
				onChange={(event) => setFieldValue('contaPago', event)}
				url={`${services.GESTOR}/v1/contas_pagar/relacoes/contas`}
			/>
		</>
	);
}

export { FormValorPago };
