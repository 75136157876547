export function converterTributacaoFederalParaFormulario(tributacao) {
  return {
    id: tributacao.id ?? null,
    codigo: tributacao.codigo ?? null,
    descricao: tributacao.descricao,
    situacao: tributacao.situacao,
    ipiEnquadramento: converterCstParaFormulario(tributacao.ipiEnquadramento),
    ipiCst: converterCstParaFormulario(tributacao.ipiCst),
    ipiBase: tributacao.ipiBase,
    ipiPercentual: tributacao.ipiPercentual,
    pisCst: converterCstParaFormulario(tributacao.pisCst),
    pisBase: tributacao.pisBase,
    pisPercentual: tributacao.pisPercentual,
    pisBaseSt: tributacao.pisBaseSt,
    pisStPercentual: tributacao.pisStPercentual,
    cofinsCst: converterCstParaFormulario(tributacao.cofinsCst),
    cofinsBase: tributacao.cofinsBase,
    cofinsPercentual: tributacao.cofinsPercentual,
    cofinsBaseSt: tributacao.cofinsBaseSt,
    cofinsStPercentual: tributacao.cofinsStPercentual,
  };
}

export function converterTributacaoFederalParaApi(tributacao) {
  return {
    id: tributacao.id,
    codigo: tributacao.codigo,
    descricao: tributacao.descricao,
    situacao: tributacao.situacao,
    ipiEnquadramento: converterCstParaApi(tributacao.ipiEnquadramento),
    ipiCst: converterCstParaApi(tributacao.ipiCst),
    ipiBase: tributacao.ipiBase > 0 ? tributacao.ipiBase : 0,
    ipiPercentual: tributacao.ipiPercentual > 0 ? tributacao.ipiPercentual : 0,
    pisCst: converterCstParaApi(tributacao.pisCst),
    pisBase: tributacao.pisBase > 0 ? tributacao.pisBase : 0,
    pisPercentual: tributacao.pisPercentual > 0 ? tributacao.pisPercentual : 0,
    pisBaseSt: tributacao.pisBaseSt > 0 ? tributacao.pisBaseSt : 0,
    pisStPercentual:
      tributacao.pisStPercentual > 0 ? tributacao.pisStPercentual : 0,
    cofinsCst: converterCstParaApi(tributacao.cofinsCst),
    cofinsBase: tributacao.cofinsBase > 0 ? tributacao.cofinsBase : 0,
    cofinsPercentual:
      tributacao.cofinsPercentual > 0 ? tributacao.cofinsPercentual : 0,
    cofinsBaseSt: tributacao.cofinsBaseSt > 0 ? tributacao.cofinsBaseSt : 0,
    cofinsStPercentual:
      tributacao.cofinsStPercentual > 0 ? tributacao.cofinsStPercentual : 0,
  };
}

function converterCstParaFormulario(cst) {
  if (cst && cst?.id) {
    return {
      label: cst.codigo + " - " + cst.descricao,
      value: cst.id,
    };
  } else {
    return null;
  }
}

function converterCstParaApi(cst) {
  return cst?.value ? { id: cst.value } : null;
}
