/* eslint-disable no-unused-vars */
import { formatarMonetario } from 'Common';
import { Divider, If } from 'components';
import { useFormikContext } from 'formik';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef } from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useContextNFSe } from 'views/fiscal/servicos/NFSe/Context';
import '../../../../Styles/index.css';
import './Styles/index.css';

function Totalizadores(props) {
	const { color } = useContextNFSe();
	const { values } = useFormikContext();
	const { isMobile } = props;
	const menuImpostos = useRef();

	const styleTotal = {
		fontSize: 20,
		fontWeight: 'bold',
		color: color.strongColor,
	};

	const styleLineTotais = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0.2rem',
	};

	function renderImpostos() {
		return (
			<div>
				<div className="table-totais-nfse">
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Base de cálculo do ISS</span>
						<span>{formatarMonetario(values.totalizadores.baseIss)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Valor do ISS</span>
						<span>{formatarMonetario(values.totalizadores.valorIss)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Base de cálculo do IR</span>
						<span>{formatarMonetario(values.totalizadores.baseIr)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Valor do IR</span>
						<span>{formatarMonetario(values.totalizadores.valorIr)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Base de cálculo do CSLL</span>
						<span>{formatarMonetario(values.totalizadores.baseCsll)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Valor do CSLL</span>
						<span>{formatarMonetario(values.totalizadores.valorCsll)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Base de cálculo do INSS</span>
						<span>{formatarMonetario(values.totalizadores.baseInss)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Valor do INSS</span>
						<span>{formatarMonetario(values.totalizadores.valorInss)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Base de cálculo do PIS</span>
						<span>{formatarMonetario(values.totalizadores.basePis)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Valor do PIS</span>
						<span>{formatarMonetario(values.totalizadores.valorPis)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Base de cálculo do COFINS</span>
						<span>{formatarMonetario(values.totalizadores.baseCofins)}</span>
					</div>
					<div style={{ ...styleLineTotais, fontSize: '14px' }}>
						<span>Valor do COFINS</span>
						<span>{formatarMonetario(values.totalizadores.valorCofins)}</span>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr min-content 1fr',
					padding: '0.5rem 0',
				}}
			>
				<div>
					<div style={styleLineTotais}>
						<span>Valor dos serviços</span>
						<span>{formatarMonetario(values.totalizadores.valorBruto)}</span>
					</div>
					<div style={styleLineTotais}>
						<span>Valor de desconto</span>
						<span>{formatarMonetario(values.totalizadores.desconto * -1)}</span>
					</div>
				</div>
				<If test={!isMobile}>
					<div>
						<Divider orientation="vertical" />
					</div>
				</If>
				<div>
					<div style={styleLineTotais}>
						<span
							style={{ display: 'flex', alignItems: 'self-end', cursor: 'pointer' }}
							onClick={(e) => menuImpostos.current?.toggle(e)}
							className="label-totais"
						>
							Valor dos tributos <IoMdInformationCircleOutline style={{ margin: '0 0 1px 5px' }} />
						</span>
						<span>{formatarMonetario(values.totalizadores.valorIss ?? 0)}</span>
					</div>
					<div>
						<Divider styleContainer={{ padding: '1rem 0 0.5rem 0' }} />
						<div
							style={{
								...styleLineTotais,
								...styleTotal,
							}}
						>
							<p>Valor total</p>
							<p>{formatarMonetario(values.totalizadores.valorLiquido)}</p>
						</div>
					</div>
				</div>
			</div>
			<OverlayPanel
				style={{ marginLeft: isMobile ? '5%' : null, width: isMobile ? '90%' : '400px' }}
				ref={menuImpostos}
				showCloseIcon
			>
				{renderImpostos()}
			</OverlayPanel>
		</>
	);
}

export { Totalizadores };
