import { Checkbox, Fieldset, Grid, If, InputField } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';

function TabConfiguracoesMdfe() {
	const { informacoesPermissoes, alterarNumeroInicialESerieMdfe, setAlterarNumeroInicialESerieMdfe } =
		useContextConfiguracoesGerais();

	const { values, setFieldValue } = useFormikContext();

	return (
		<If test={values.emiteMdfe}>
			<Fieldset legend="Configurações MDF-e" className="fieldset">
				<Grid>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Checkbox}
						label="Alterar número e série da MDF-e"
						name="alterarNumeroInicialESerieMdfe"
						onChange={() => {
							setAlterarNumeroInicialESerieMdfe(!alterarNumeroInicialESerieMdfe);
							setFieldValue('alterarNumeroInicialESerieMdfe', alterarNumeroInicialESerieMdfe);
						}}
						checked={alterarNumeroInicialESerieMdfe}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						label="Serie"
						name="serieMdfe"
						obrigatorio
						value={values.serieMdfe}
						disabled={!alterarNumeroInicialESerieMdfe}
						onChange={(e) => setFieldValue('serieMdfe', e.target.value)}
						size={30}
						component={InputField}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						label="Número inicial da MDF-e"
						name="numeroInicialMdfe"
						obrigatorio
						value={values.numeroInicialMdfe}
						disabled={!alterarNumeroInicialESerieMdfe}
						onChange={(e) => setFieldValue('numeroInicialMdfe', e.target.value)}
						size={30}
						component={InputField}
						{...informacoesPermissoes}
					/>
				</Grid>
			</Fieldset>
		</If>
	);
}

export { TabConfiguracoesMdfe };
