import { useCallback, useEffect, useRef, useState } from 'react';

import {
  ButtonNovo,
  Grid,
  PesquisaAvancada,
  Col,
  DescricaoFiltroAvancado,
  ButtonEditarTable,
  ButtonExcluirTable,
  InputSearch,
  tutorialStepsListagens,
  Tutorial,
  Form,
  FormActions,
  FormContent,
  InputMoney,
  If,
  confirm,
  NenhumRegistroEncontrado,
  Paginacao,
} from '../../../components';

import {
  services,
  recursos,
  permissoes,
  modulos,
  removerCaracteres,
  removerElemento,
  construirUrl,
  buscarDadosLoginLocalStorage,
  usuarioPossuiModulo,
  usuarioPossuiPermissao,
  salvarConfiguracaoUsuario,
  configuracoesUsuario,
  buscarConfiguracaoUsuario,
  formatarDecimais,
  formatarMonetarioDecimais,
} from '../../../Common';

import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { connect } from 'react-redux';
import BotaoTabelaEstoque from './components/BotaoTabelaEstoque';
import { asyncAjustarSaldo, asyncDeleteProduto, asyncGetProdutos } from './Requests';
import { optionsFiltroAvancado } from './Util/constantes';
import { atualizarUrl } from '../../Util';
import { confirmarExclusao } from '../../Util/ExclusaoDeRegistros';
import ModalEntradaEstoque from '../../estoque/Movimentacoes/components/ModalEntradaEstoque';
import ModalSaidaEstoque from '../../estoque/Movimentacoes/components/ModalSaidaEstoque';
import { FormikProvider, useFormik } from 'formik';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { tipoSaldo } from 'views/estoque/Movimentacoes/Util/constantes';

const ColorIcons = {
  SUCCESS: '#34a835',
  PRIMARY: '#006095',
  DANGER: '#e91224',
};

function Produtos(props) {
  let decimaisQtd = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
  let decimaisPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

  const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.INSERIR));
  const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.EXCLUIR));
  const [podeVisualizarMovimentacaoEstoque, setPodeVisualizarMovimentacaoEstoque] = useState(
    usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.VISUALIZAR)
  );
  const [podeInserirMovimentacaoEstoque, setPodeInserirMovimentacaoEstoque] = useState(
    usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.INSERIR)
  );
  const [deveExibirTutorial, setDeveExibirTutorial] = useState(
    buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
  );
  const [possuiModuloEstoque, setPossuiModuloEstoque] = useState(usuarioPossuiModulo(modulos.ESTOQUE));
  const [exibirModalEntradaEstoque, setExibirModalEntradaEstoque] = useState(false);
  const [exibirModalReservaEstoque, setExibirModalReservaEstoque] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const {
    valorPesquisa,
    setValorPesquisa,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    rows,
    setRows,
    filtroAvancado,
    setFiltroAvancado,
    descricaoFiltroAvancado,
    setDescricaoFiltroAvancado,
    exibirBloqueadas,
    setExibirBloqueadas,
  } = useContextPesquisa();

  const [exibirModalSaidaEstoque, setExibirModalSaidaEstoque] = useState(false);
  const [registros, setRegistros] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [tutorialVisible, setTutorialVisible] = useState(false);  
  const [registroSelecionado, setRegistroSelecionado] = useState(null);
  const [campoParaEditar, setCampoParaEditar] = useState(null);
  const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);

  const optionsEstoqueMenu = useRef(null);

  const pesquisarCallback = useCallback(e => {
    if (!firstRender){      
      pesquisar(); 
    }
  });

  useEffect(() => {
    pesquisar();
    if (deveExibirTutorial !== false) {
      setTutorialVisible(true);
      salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
    }

    setTimeout(() => {
      if (document.getElementById('InputSearchProduto')) {
        document.getElementById('InputSearchProduto').focus();
      }
    }, 500);

    return () => {
      optionsEstoqueMenu.current = null;
    };
  }, []);

  useEffect(() => {
    pesquisarCallback();
  }, [exibirBloqueadas, page, rows, sortOrder, sortField, filtroAvancado]);

  function buscarFiltro() {
    const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.']);

    let result = `?query=(codigo=contains="*${pesquisaCodigo}*",codigoBarras=contains="*${pesquisaCodigo}*",sku=contains="*${pesquisaCodigo}*",nome=contains="*${valorPesquisa}*",situacao=contains="*${valorPesquisa}*",preco=contains="*${valorPesquisa}*")`;
    if (filtroAvancado) {
      result += `;${filtroAvancado}`;
    }

    return result;
  }

  async function pesquisar() {
    let filtro = buscarFiltro();
    filtro += String(`${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`);
    let campoOrdenacao = sortField;
    let sentidoOrdenacao = sortOrder;
    if (firstRender){
      setSortField("nome");
      setSortOrder(1);
      campoOrdenacao = "nome";
      sentidoOrdenacao = 1;
    }
    const url = construirUrl(
      `${services.GESTOR}/v1/produtos/resumo`,
      filtro,
      rows,
      page,
      sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
    );

    await asyncGetProdutos(url, ({ data: produto }) => {
      setRegistros(produto.content);
      setTotalElements(produto.totalElements);
      setFirstRender(false);
    });
    
  }

  function onPesquisar() {
    pesquisar();
  }

  function onPageChange(e) {
    setPage(e.page);
    setRows(e.rows);
  }

  function onSort(e) {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  }

  function onEditar(row) {
    atualizarUrl(props.history, '/produtos/cadastro', row.id);
  }

  function onExcluir(row) {
    confirmarExclusao(() => asyncExcluirRegistro(row));
  }

  async function asyncExcluirRegistro(registro) {
    await asyncDeleteProduto(registro.id, () => {
      setRegistros(removerElemento(registros, registro));
      setTotalElements(totalElements - 1);
    });
  }

  function renderOpcoes(row) {
    return (
      <div style={{ display: 'flex' }}>
        <ButtonEditarTable onClick={() => onEditar(row)} />
        <ButtonExcluirTable onClick={() => onExcluir(row)} podeExcluir={podeExcluir} />
      </div>
    );
  }

  function aplicarEstiloInativo(row, field) {
    const styleInactive = {
      fontStyle: 'italic',
      opacity: '0.8',
      wordBreak: 'break-word',
      maxWidth: props.isMobile ? '70%' : '100%',
      textAlign: props.isMobile ? 'end' : 'start',
    };

    const styleActive = {
      wordBreak: 'break-word',
      maxWidth: props.isMobile ? '70%' : '100%',
      textAlign: props.isMobile ? 'end' : 'start',
    };

    if (row.situacao === 'INATIVO') {
      return <span style={styleInactive}>{field}</span>;
    }
    return <span style={styleActive}>{field}</span>;
  }

  function onPesquisarFiltroAvancado(e) {
    setFiltroAvancado(e);
    setLimparFiltroPesquisaAvancada(false);
  }

  const formik = useFormik({
    initialValues: {
      saldo: 0,
    },
    onSubmit: values => {
      handleSalvarNovoSaldo(values.saldo, null);
    },
  });

  function renderEstoqueDisponivelField(el) {
    if (!el.controlarEstoque || el.tipo === 'SERVICO') {
      return '';
    }

    if (campoParaEditar && campoParaEditar.id === el.id) {
      const newSaldo = formik.values.saldo !== 0 ? formik.values.saldo : el.estoqueSaldo;
      return (
        <form onSubmit={formik.handleSubmit}>
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span style={{ width: 'calc(100% - 60px)' }}>
              <FormikProvider value={formik}>
                <InputMoney
                  colStyle={{ padding: '0px' }}
                  size={11}
                  prefix=""
                  placeholder=""
                  autofocus
                  touched={true}
                  decimalScale={decimaisQtd}
                  onChange={formik.handleChange}
                  value={formik.values.saldo}
                  name="saldo"
                />
              </FormikProvider>
            </span>
            <span style={{ width: '60px' }}>
              <BotaoTabelaEstoque
                title="Salvar novo saldo"
                icon="fa fa-check"
                style={{ fontSize: '22px' }}
                color={ColorIcons.SUCCESS}
                onClick={() => handleSalvarNovoSaldo(formik.values.saldo, null)}
                type="submit"
              />
              <BotaoTabelaEstoque
                title="Cancelar"
                icon="fa fa-times"
                style={{ fontSize: '22px' }}
                color={ColorIcons.DANGER}
                onClick={() => setCampoParaEditar(null)}
              />
            </span>
          </span>
        </form>
      );
    } else {
      const estoqueSaldoFormatado =
        formatarDecimais(el.estoqueSaldo, decimaisQtd) +
        (el.unidadeMedida?.unidade ? ' ' + el.unidadeMedida?.unidade : '');
      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span>
            <div title={estoqueSaldoFormatado}>{aplicarEstiloInativo(el, estoqueSaldoFormatado)}</div>
            <Link
              hidden={!podeVisualizarMovimentacaoEstoque}
              className="link_to"
              style={{ fontSize: '11px', color: '#006095', cursor: 'pointer' }}
              to={{ pathname: `movimentacoes_estoque`, state: el }}
              title="Visualizar movimentações de estoque deste produto"
            >
              Ver movimentações
            </Link>
          </span>
          <BotaoTabelaEstoque
            title={
              !podeInserirMovimentacaoEstoque ? 'Você não possui permissão para efetuar esta ação' : 'Ajustar saldo'
            }
            icon="fa fa-wrench"
            color={ColorIcons.PRIMARY}
            style={{ fontSize: '20px', padding: '0px 5px' }}
            disabled={!podeInserirMovimentacaoEstoque}
            onClick={event => {
              setRegistroSelecionado(el);
              optionsEstoqueMenu.current.toggle(event);
            }}
          />
        </span>
      );
    }
  }

  function handleSalvarNovoSaldo(novoSaldo, e) {
    e && e.preventDefault();

    if (campoParaEditar.controlarEstoque && novoSaldo < campoParaEditar.estoqueMinimo) {
      confirm(
        'Confirmação',
        'Novo saldo será menor que estoque mínimo, deseja continuar?',
        () => {
          movimentarNovoSaldoEstoque(novoSaldo);
        },
        () => {
          setCampoParaEditar(null);
        },
        'Sim',
        'Não'
      );
    } else {
      if (!novoSaldo) {
        movimentarNovoSaldoEstoque(0);
      } else {
        movimentarNovoSaldoEstoque(novoSaldo);
      }
    }
  }

  function movimentarNovoSaldoEstoque(novoSaldo) {
    if (novoSaldo >= 0 && campoParaEditar.estoqueSaldo !== novoSaldo) {
      asyncAjustarSaldo(
        campoParaEditar.id,
        { custo: campoParaEditar.ultimoCusto, novoSaldo: novoSaldo },
        () => {
          setCampoParaEditar(null);
          pesquisar();
        },
        () => {
          setRegistros(null);
          setCampoParaEditar(null);
        }
      );
    } else {
      setCampoParaEditar(null);
    }
  }

  function onChangeCheckboxExibirInativos(e) {
    setExibirBloqueadas(e.checked);
  }

  function montarHeader() {
    let titulo = 'Produtos / serviços';
    if (buscarDadosLoginLocalStorage().organizacao.aplicacao === 'DOCS_DIGITAIS') {
      titulo = 'Produtos';
    }
    return (
      <span
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>{titulo}</span>
      </span>
    );
  }

  function onHideModaisEstoque(novoRegistro) {
    setExibirModalSaidaEstoque(false);
    setExibirModalEntradaEstoque(false);
    setExibirModalReservaEstoque(false);
    if (novoRegistro) {
      pesquisarCallback();
    }
  }

  function buscarProdutoSelecionado() {    
    if (registroSelecionado) {
      return {
        label: `${registroSelecionado.codigo + ' - ' + registroSelecionado.nome}${
          registroSelecionado.sku ? ' - [Ref: ' + registroSelecionado.sku + ']' : ''
        }`,
        value: registroSelecionado.id,
        registro: {
          ...registroSelecionado,
          custo: registroSelecionado.ultimoCusto,
        },
      };
    }
    return null;
  }

  return (
    <>
      <Menu
        style={{ width: '212px' }}
        model={[
          {
            label: 'Nova entrada de estoque',
            icon: 'fa fa-plus',
            command: () => setExibirModalEntradaEstoque(true),
            disabled: false,
          },
          {
            label: 'Nova saída de estoque',
            icon: 'fa fa-minus',
            command: () => setExibirModalSaidaEstoque(true),
            disabled: false,
          },
          {
            label: 'Nova reserva de estoque',
            icon: 'fa fa-plus',
            command: () => setExibirModalReservaEstoque(true),
            disabled: false,
          },
          {
            label: 'Ajustar saldo em estoque',
            icon: 'fa fa-wrench',
            command: () => {
              formik.setFieldValue('saldo', registroSelecionado.estoqueSaldo);
              setCampoParaEditar(registroSelecionado);
            },
            disabled: false,
          },
        ]}
        popup={true}
        ref={optionsEstoqueMenu}
      />
      <Tutorial
        steps={tutorialStepsListagens}
        showSkipButton
        continuous
        visible={tutorialVisible}
        onHide={() => setTutorialVisible(false)}
      />

      <Form header={montarHeader()}>
        <FormActions>
          <ButtonNovo
            className="step-listagem-novo"
            label="Novo produto"
            onClick={() => props.history.push('/produtos/cadastro')}
            podeInserir={podeInserir}
          />
        </FormActions>
        <FormContent>
          <Grid justifyCenter>
            <InputSearch
              id="InputSearchProduto"
              className="step-listagem-input-search"
              onPesquisar={() => onPesquisar()}
              value={valorPesquisa}
              onChange={value => setValorPesquisa(value)}
            />
            <Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
              <PesquisaAvancada
                optionsFiltros={optionsFiltroAvancado}
                onPesquisarClick={onPesquisarFiltroAvancado}
                onChangeFiltroRsql={rsql => setFiltroAvancado(rsql)}
                onChangeDescricaoFiltro={e => setDescricaoFiltroAvancado(e)}
                limparFiltro={limparFiltroPesquisaAvancada}
              />
            </Col>
          </Grid>
          <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
          <DataTable
            className="table"
            responsive
            value={registros}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            emptyMessage={<NenhumRegistroEncontrado />}
            header={
              <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Checkbox
                  name="exibirBloqueadas"
                  inputId="exibirBloqueadas"
                  checked={exibirBloqueadas}
                  onChange={onChangeCheckboxExibirInativos}
                />
                <label htmlFor="exibirBloqueadas" className="p-checkbox-label">
                  Exibir produtos inativos
                </label>
              </span>
            }
          >
            <Column
              className="step-listagem-order"
              field="codigo"
              header="Código"
              body={row => aplicarEstiloInativo(row, row.codigo)}
              sortable={true}
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '130px',
              }}
            />
            <Column
              field="sku"
              header="Referência"
              body={row => aplicarEstiloInativo(row, row.sku)}
              sortable={true}
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '130px',
              }}
            />
            <Column field="nome" header="Nome" body={row => aplicarEstiloInativo(row, row.nome)} sortable={true} />
            <Column
              field="preco"
              body={row => aplicarEstiloInativo(row, formatarMonetarioDecimais(row.preco, decimaisPreco))}
              header="Preço"
              style={{ width: '150px' }}
              sortable={true}
            />
            {possuiModuloEstoque && (
              <Column
                header="Estoque disponível"
                field="estoqueSaldo"
                body={row => renderEstoqueDisponivelField(row)}
                style={{
                  width: '170px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  paddingBottom: '0px',
                  paddingTop: '0px',
                }}
              />
            )}
            <Column
              className="step-listagem-acoes"
              body={row => renderOpcoes(row)}
              header="Ações"
              style={{ width: '7em' }}
            />
          </DataTable>
          <Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
        </FormContent>
      </Form>
      <If test={exibirModalEntradaEstoque && registroSelecionado}>
        <ModalEntradaEstoque
          atualizarListagem={() => pesquisar()}
          visible={exibirModalEntradaEstoque}
          produto={buscarProdutoSelecionado()}
          onHide={onHideModaisEstoque}
        />
      </If>
      <If test={exibirModalSaidaEstoque}>
        <ModalSaidaEstoque
          atualizarListagem={() => pesquisar()}
          visible={exibirModalSaidaEstoque}
          produto={buscarProdutoSelecionado()}
          onHide={onHideModaisEstoque}
        />
      </If>
      <If test={exibirModalReservaEstoque && registroSelecionado}>
        <ModalEntradaEstoque
          atualizarListagem={() => pesquisar()}
          saldoTipo = {tipoSaldo.RESERVADO}
          visible={exibirModalReservaEstoque}
          produto={buscarProdutoSelecionado()}
          onHide={onHideModaisEstoque}
        />
      </If>
    </>
  );
}

const mapStateToProps = state => ({
  isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Produtos);
