function converterCondicaoPagamentoParaFormulario(value) {
	return {
		id: value.id ? value.id : null,
		descricao: value.descricao,
		tipo: value.tipo,
		movimentacao: value.movimentacao,
		parcelaMinima: value.parcelaMinima,
		parcelaPadrao: value.parcelaPadrao,
		parcelaMaxima: value.parcelaMaxima,
		situacao: value.situacao,
		favorita: value.favorita,
		formaPagamento: value.formaPagamento
			? { value: value.formaPagamento.id, label: value.formaPagamento.descricao, registro: value.formaPagamento }
			: null,
		compensacaoAutomatica: value.compensacaoAutomatica,
		compensacaoTipo: value.compensacaoTipo,
		compensacaoNumeroDias: value.compensacaoNumeroDias,
		taxaTipoAplicado: value.taxaTipoAplicado,
		taxaCategoria: value.taxaCategoria
			? { value: value.taxaCategoria.id, label: value.taxaCategoria.nome, registro: value.taxaCategoria }
			: null,
		taxas: converterTaxasParaFormulario(value.taxas),
	};
}

function converterTaxasParaFormulario(taxas) {
	if (taxas) {
		return taxas?.map((taxa) => ({
			id: taxa.id,
			item: taxa.item,
			valorInicial: taxa.valorInicial ?? 0,
			valorFinal: taxa.valorFinal ?? 0,
			percentualAplicado: taxa.percentualAplicado ?? 0,
			valorAplicado: taxa.valorAplicado ?? 0,
		}));
	}

	return [];
}

function converterCondicaoPagamentoParaApi(value) {
	return {
		id: value.id ? value.id : null,
		descricao: value.descricao,
		tipo: value.tipo,
		movimentacao: value.movimentacao,
		parcelaMinima: value.parcelaMinima,
		parcelaPadrao: value.parcelaPadrao,
		parcelaMaxima: value.parcelaMaxima,
		situacao: value.situacao,
		favorita: value.favorita,
		formaPagamento: value.formaPagamento ? { id: value.formaPagamento.value } : null,
		compensacaoAutomatica: value.compensacaoAutomatica,
		compensacaoTipo: value.compensacaoTipo,
		compensacaoNumeroDias: value.compensacaoNumeroDias,
		taxaTipoAplicado: value.taxaTipoAplicado,
		taxaCategoria: value.taxaCategoria ? { id: value.taxaCategoria.value } : null,
		taxas: converterTaxasParaApi(value.taxas),
	};
}

function converterTaxasParaApi(taxas) {
	if (taxas) {
		return taxas?.map((taxa) => ({
			id: taxa.id ?? null,
			item: taxa.item,
			valorInicial: taxa.valorInicial ?? 0,
			valorFinal: taxa.valorFinal ?? 0,
			percentualAplicado: taxa.percentualAplicado ?? 0,
			valorAplicado: taxa.valorAplicado ?? 0,
		}));
	}

	return null;
}
export { converterCondicaoPagamentoParaFormulario, converterCondicaoPagamentoParaApi };
