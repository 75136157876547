import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { baixarArquivo, mensagensDeValidacao, services } from '../../../../../../../../../../../Common';
import {
	Button,
	Dropdown,
	Form,
	Grid,
	InputDate,
	Modal,
	ModalLoadingTransmissao,
} from '../../../../../../../../../../../components';
import MultiSelectProdutos from '../../../../../../../../../../../components/select/MultiSelectProdutos';
import FormActions from '../../../../../../../../../../../components/FormActions';
import FormContent from '../../../../../../../../../../../components/FormContent';
import { asyncGetRegistrosPAFNFCe } from '../../../../../../../Requests';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';

const initialValue = {
	dataInicial: new Date().toISOString(),
	dataFinal: new Date().toISOString(),
	estoque: 'TOTAL',
	produtos: [],
};

function ModalRegistrosPafNfceView(props) {
	const { onHide, isMobile, visible, isFullScreen, values, setFieldValue, isValid, handleSubmit, validateForm } =
		props;

	const [exibirLoadingGeracao, setExibirLoadingGeracao] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('RegistrosPafNfceInputDateDataInicial')?.focus();
		}, 500);
	}, []);

	async function registrosPAFNFCe() {
		await handleSubmit();
		await validateForm();
		if (isValid) {
			setExibirLoadingGeracao(true);
			asyncGetRegistrosPAFNFCe(
				format(parseISO(values.dataInicial), 'yyyy-MM-dd'),
				format(parseISO(values.dataFinal), 'yyyy-MM-dd'),
				values.estoque,
				retornaListaProdutos(),
				({ data: xml }) => {
					baixarArquivo(
						xml,
						`RegistrosPAFNFCe${format(parseISO(values.dataInicial), 'ddMMyyyy')}a${format(
							parseISO(values.dataFinal),
							'ddMMyyyy'
						)}.xml`
					);
					setExibirLoadingGeracao(false);
					onHide();
				},
				() => {
					setExibirLoadingGeracao(false);
				}
			);
		}
	}

	function retornaListaProdutos() {
		let retorno = '';
		if (values.produtos) {
			for (let i = 0; i < values.produtos.length; i++) {
				if (retorno !== '') {
					retorno = retorno + ',';
				}
				retorno = retorno + values.produtos[i].value;
			}
		}
		return retorno;
	}

	return (
		<>
			<Modal
				header="Registros PAF-NFC-e"
				onHide={onHide}
				visible={visible}
				styleModal={{
					maxWidth: '600px',
				}}
				isMobile={isMobile}
				container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
			>
				<Form>
					<FormActions>
						<Button
							className="p-button p-button-primary"
							icon="fa fa-download"
							title="Gerar e baixar o arquivo XML"
							label="Gerar arquivo"
							onClick={registrosPAFNFCe}
						/>
					</FormActions>
					<FormContent>
						<Grid>
							<Field
								sm="12"
								md="6"
								lg="6"
								xl="6"
								component={InputDate}
								label="Data inicial"
								name="dataInicial"
								obrigatorio
								value={values.dataInicial}
								touched={true}
								helpMessage="Data inicial do período da geração do arquivo"
								onChange={e => {
									setFieldValue('dataInicial', e.target.value);
								}}
								id="RegistrosPafNfceInputDateDataInicial"
							/>

							<Field
								sm="12"
								md="6"
								lg="6"
								xl="6"
								component={InputDate}
								label="Data final"
								name="dataFinal"
								obrigatorio
								value={values.dataFinal}
								helpMessage="Data final do período da geração do arquivo"
								touched={true}
								onChange={e => {
									setFieldValue('dataFinal', e.target.value);
								}}
							/>

							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Dropdown}
								label="Estoque"
								name="estoque"
								value={values.estoque}
								onChange={e => {
									if (e.value === 'TOTAL') {
										setFieldValue('estoque', e.value);
										setFieldValue('produtos', []);
									} else {
										setFieldValue('estoque', e.value);
									}
								}}
								options={[
									{ value: 'TOTAL', label: 'Total' },
									{ value: 'PARCIAL', label: 'Parcial' },
								]}
								obrigatorio
								helpMessage="Estoque a ser gerado no arquivo"
								showClear={false}
								menuPortalTarget={
									isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null
								}
							/>

							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								name="produtos"
								label="Produtos"
								autofocus
								isMulti
								component={MultiSelectProdutos}
								value={values.produtos}
								disabled={values.estoque === 'TOTAL'}
								onChange={e => {
									setFieldValue('produtos', e);
								}}
								url={`${services.GESTOR}/v1/produtos`}
								parametros={';tipo!="SERVICO";controlarEstoque==true'}
							/>
						</Grid>
					</FormContent>
				</Form>
			</Modal>
			<ModalLoadingTransmissao
				visible={exibirLoadingGeracao}
				message="Gerando registros do PAF-NFC-e..."
				container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
			/>
		</>
	);
}

const ModalRegistrosPafNfce = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (!values.dataInicial || values.dataInicial === '') {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataFinal || values.dataFinal === '') {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		dataInicial: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		dataFinal: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
	}),

	handleSubmit: () => {},
})(ModalRegistrosPafNfceView);

export default ModalRegistrosPafNfce;
