import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../../../components/Button/ButtonExcluir';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../../components/Button/ButtonNovo';
import ButtonSalvar from '../../../../../../components/Button/ButtonSalvar';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../../../Util';
import { confirmarExclusao } from '../../../../../Util/ExclusaoDeRegistros';
import {
	asyncCreateTributacaoFederal,
	asyncDeleteTributacaoFederal,
	asyncUpdateTributacaoFederal,
} from '../../../Requests';
import { converterTributacaoFederalParaApi } from '../../../Util/tributacaoFederalConverter';

export default function ActionButtons(props) {
	const {
		values,
		dirty,
		initialValue,
		dadosBase,
		history,
		informacoesPermissoes,
		CADASTROURL,
		PESQUISAURL,
		resetForm,
		setDadosBase,
		handleSubmit,
		setIsNewTributacao,
	} = props;

	const { id } = values;

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const onClickNovo = dirty ? (event) => salvar(event, novo) : novo;

	function cancelar() {
		if (dirty) {
			resetForm({
				values: dadosBase?.values,
			});
		} else if (props.isModal) {
			props.hideModal(values);
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
	}

	async function salvar(e, novoOnSuccess) {
		handleSubmit();
		if (await validarFormulario(props)) {
			let dadosFormulario = converterTributacaoFederalParaApi(values);
			if (id) {
				asyncUpdateRegistro(dadosFormulario, novoOnSuccess);
				setDadosBase(values);
				resetForm({ values: values });
			} else {
				setDadosBase(values);
				await asyncCreateRegistro(dadosFormulario, novoOnSuccess);
				if (typeof novoOnSuccess === 'undefined' && !informacoesPermissoes.podeEditar) {
					history.goBack();
				}
			}
		}
	}

	function novo() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: initialValue });
	}

	function excluir() {
		confirmarExclusao(asyncDeleteRegistro);
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteTributacaoFederal(id, () => {
			resetForm();
			voltarParaAPesquisa(history, PESQUISAURL);
		});
	}

	async function asyncCreateRegistro(dadosFormulario, novoOnSuccess) {
		await asyncCreateTributacaoFederal(dadosFormulario, async ({ data: tributacao }) => {
			setIsNewTributacao(true);
			let dados = {
				...values,
				id: tributacao?.id,
				codigo: tributacao?.codigo,
			};
			resetForm({ values: dados });
			setDadosBase(dados);

			if (novoOnSuccess) {
				novoOnSuccess();
			} else if (props.isModal) {
				props.hideModal(dados);
			} else {
				atualizarUrl(history, CADASTROURL, tributacao.id, metodosAtualizarUrl.POP);
			}
		});
	}

	async function asyncUpdateRegistro(dadosFormulario, novoOnSuccess) {
		await asyncUpdateTributacaoFederal(dadosFormulario, () => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				resetForm({ values: values });
			}
		});
	}

	return (
		<>
			<ButtonCancelar onClick={cancelar} estadoBotao={estadoBotaoCancelar} />
			<ButtonSalvar onClick={() => salvar()} disabled={!dirty} />
			<ButtonNovo
				onClick={onClickNovo}
				estadoBotao={estadoBotaoNovo}
				hidden={(!values.id && !dirty) || props.isModal}
				disabled={!informacoesPermissoes.podeInserir}
			/>
			<ButtonExcluir
				onClick={excluir}
				hidden={!values.id || props.isModal}
				disabled={!informacoesPermissoes.podeExcluir}
			/>
		</>
	);
}
