import { useReducer, useEffect } from 'react';
import { format, parseISO } from 'date-fns';

import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { Button, Col } from 'components';

import { atualizarUrl } from 'views/Util';
import {
	asyncGetPedidoVendaPorDia,
	asyncGetPedidoVendaUltimosDozeMeses,
	asyncGetProdutosMaisVendidos,
	asyncGetRankingClientes,
	asyncGetRankingVendedores,
	asyncGetResultadoPeriodo,
} from './requests';
import { dataReducer } from './reducer';
import { addDataProdutosVendidos, addDataRankingClientes, addDataRankingVendedores, setData } from './reducer/actions';
import { CardsResultado } from '../CardsResultado';
import { CardList } from '../CardList';
import { ChartPorDia } from '../ChartPorDia';
import { ChartUltimosDozeMeses } from '../ChartUltimosDozeMeses';

function DashboardPedidoVenda({ dataInicial, dataFinal, isMobile, isTablet, history }) {
	const [
		{
			cardsResultado,
			produtosVendidos,
			rankingClientes,
			rankingVendedores,
			pedidoVendaPorDia,
			PedidoVendaUltimosDozeMeses,
			loading,
			podeInserirPedidoVenda,
		},
		dispatch,
	] = useReducer(
		dataReducer,
		{
			cardsResultado: {},
			produtosVendidos: {},
			rankingClientes: {},
			rankingVendedores: {},
			pedidoVendaPorDia: {},
			PedidoVendaUltimosDozeMeses: {},
			loading: true,
			podeInserirPedidoVenda: false,
		},
		(initialState) => ({
			...initialState,
			podeInserirPedidoVenda: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR),
		})
	);
	const styleCards = isMobile ? { padding: '0.5em 0em', margin: '8px', flex: 'auto' } : { padding: '0.5em' };

	const styleContainerButton = {
		display: 'flex',
		justifyContent: isMobile ? 'center' : 'flex-start',
		padding: isMobile ? '15px 0 0 2px' : '0 0 0 2px',
	};

	const styleButton = {
		margin: isMobile ? '3px 5px' : '3px 7px',
		width: '185px',
	};

	useEffect(() => {
		fetchData();
	}, [dataInicial, dataFinal]);

	async function fetchData() {
		let newData = {
			loading: false,
		};
		const promises = [
			asyncGetResultadoPeriodo(dataInicial, dataFinal, ({ data }) => {
				const { periodoAtual, periodoAnterior } = data;
				const dataCards = {
					pendentes: {
						valor: periodoAtual.valorPedidoVendaPendente,
						evolucao: calculateEvolution(
							periodoAtual.valorPedidoVendaPendente,
							periodoAnterior.valorPedidoVendaPendente
						),
					},
					finalizados: {
						valor: periodoAtual.valorPedidoVendaFinalizado,
						evolucao: calculateEvolution(
							periodoAtual.valorPedidoVendaFinalizado,
							periodoAnterior.valorPedidoVendaFinalizado
						),
					},
					cancelados: {
						valor: periodoAtual.valorPedidoVendaCancelada,
						evolucao: calculateEvolution(
							periodoAtual.valorPedidoVendaCancelada,
							periodoAnterior.valorPedidoVendaCancelada
						),
					},
				};

				newData = {
					...newData,
					cardsResultado: dataCards,
				};
			}),
			asyncGetProdutosMaisVendidos(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					produtosVendidos: data,
				};
			}),
			asyncGetRankingClientes(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingClientes: data,
				};
			}),
			asyncGetRankingVendedores(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingVendedores: data,
				};
			}),
			asyncGetPedidoVendaPorDia(dataInicial, dataFinal, ({ data }) => {
				const dias = [];
				const pendentes = [];
				const finalizados = [];
				const cancelados = [];

				data.forEach((day) => {
					dias.push(format(parseISO(day.data), 'dd/MM/yyyy'));
					pendentes.push(day.valorPedidoVendaPendente);
					finalizados.push(day.valorPedidoVendaFinalizado);
					cancelados.push(day.valorPedidoVendaCancelada);
				});

				newData = {
					...newData,
					pedidoVendaPorDia: { dias, pendentes, finalizados, cancelados },
				};
			}),
			asyncGetPedidoVendaUltimosDozeMeses(dataInicial, dataFinal, ({ data }) => {
				const meses = [];
				const pendentes = [];
				const finalizados = [];
				const cancelados = [];

				data.forEach((month) => {
					meses.push(month.competencia);
					pendentes.push(month.valorPedidoVendaPendente);
					finalizados.push(month.valorPedidoVendaFinalizado);
					cancelados.push(month.valorPedidoVendaCancelada);
				});

				newData = {
					...newData,
					PedidoVendaUltimosDozeMeses: {
						meses,
						pendentes,
						finalizados,
						cancelados,
					},
				};
			}),
		];

		await Promise.all(promises).then(() => dispatch(setData(newData)));
	}

	async function handleClickVerMaisProdutosMaisVendidos(page) {
		await asyncGetProdutosMaisVendidos(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataProdutosVendidos(data));
		});
	}

	async function handleClickVerMaisRankingClientes(page) {
		await asyncGetRankingClientes(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingClientes(data));
		});
	}

	async function handleClickVerMaisRankingVendedores(page) {
		await asyncGetRankingVendedores(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingVendedores(data));
		});
	}

	function calculateEvolution(value, oldValue) {
		if (oldValue === 0) {
			return 0;
		}

		return ((value * 100) / oldValue - 100).toFixed(0);
	}

	function handleClickNovoPedidoVenda() {
		atualizarUrl(history, '/pedido_venda/cadastro');
	}

	return (
		<>
			<Col style={styleContainerButton}>
				<Button
					label="Novo pedido de venda"
					onClick={handleClickNovoPedidoVenda}
					style={styleButton}
					disabled={!podeInserirPedidoVenda}
					title={!podeInserirPedidoVenda ? 'Você não possui permissão para criar novos pedidos de venda' : null}
				/>
			</Col>
			<CardsResultado data={cardsResultado} isMobile={isMobile} isTablet={isTablet} consideraDevolucao />
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Produtos mais vendidos"
					helpMessage="Lista dos produtos mais vendidos no período selecionado"
					data={produtosVendidos.content}
					loading={loading}
					visibleButtonVerMais={(produtosVendidos.number + 1) * 10 < produtosVendidos.totalElements}
					onClickVerMais={handleClickVerMaisProdutosMaisVendidos}
					page={produtosVendidos.number}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de clientes"
					helpMessage="Lista de clientes que mais utilizados no período"
					data={rankingClientes.content}
					loading={loading}
					visibleButtonVerMais={(rankingClientes.number + 1) * 10 < rankingClientes.totalElements}
					onClickVerMais={handleClickVerMaisRankingClientes}
					page={rankingClientes.number}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de vendedores"
					helpMessage="Lista de vendedores que mais venderam no período"
					data={rankingVendedores.content}
					loading={loading}
					visibleButtonVerMais={(rankingVendedores.number + 1) * 10 < rankingVendedores.totalElements}
					onClickVerMais={handleClickVerMaisRankingVendedores}
					page={rankingVendedores.number}
				/>
			</Col>
			<Col style={styleCards}>
				<ChartPorDia
					title="Pedidos de venda por dia no período selecionado"
					helpMessage="Lista o valor total dos pedidos de venda por situação no período selecionado"
					data={pedidoVendaPorDia}
					loading={loading}
					isMobile={isMobile}
				/>
			</Col>
			<Col style={styleCards}>
				<ChartUltimosDozeMeses
					title="Pedidos de venda nos últimos 12 meses"
					label="Total de orçamentos no mês"
					helpMessage="Lista o valor total dos pedidos de venda agrupadas por mês nos últimos doze meses"
					data={PedidoVendaUltimosDozeMeses}
					loading={loading}
					isMobile={isMobile}
				/>
			</Col>
		</>
	);
}
export { DashboardPedidoVenda };
