import { useEffect, useRef, useState } from 'react';
import { Field, useFormikContext } from 'formik';

import { copiarObjeto, keyFilterConsultaRsql } from 'Common';
import { Col, Grid, SingleSelectMunicipio } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { MDFE_TIPO_EMITENTE } from 'views/transporte/MDFe/Form/Util/constantes';
import InputChaveDeAcesso from './components/InputChaveDeAcesso';
import CardMunicipioDescarregamento from './components/CardMunicipioDescarregamento';

function Documentos({ disabled }) {
	const { values, setFieldValue, initialValues, handleReset } = useFormikContext();
	const {
		resetCampos,
		setResetCampos,
		resetCamposCancelamento,
		setResetCamposCancelamento,
		informacoesPermissoes,
		importouNFe,
		setImportouNFe,
	} = useContextMDFe();

	const [municipiosLocal, setMunicipiosLocal] = useState([]);
	const [municipioSelecionado, setMunicipioSelecionado] = useState({});
	const [indexMunicipioSelecionado, setIndexMunicipioSelecionado] = useState(null);
	const [limparSelecionado, setLimparSelecionado] = useState(false);
	const [chaveDeAcesso, setChaveDeAcesso] = useState(null);
	const [municipio] = useState(null);
	const [errors, setErrors] = useState(null);
	const [isFirstRender, setIsFirstRender] = useState(true);

	const isEmpty = municipioSelecionado ? Object.keys(municipioSelecionado)?.length === 0 : true;

	const childRef = useRef(null);

	useEffect(() => {
		function isDifferent(prevArray, newArray) {
			if (prevArray.length !== newArray.length) return true;

			for (let i = 0; i < prevArray.length; i++) {
				if (prevArray[i].municipio?.label !== newArray[i].municipio?.label) {
					return true;
				}

				if (prevArray[i].documentos?.length !== newArray[i].documentos?.length) {
					return true;
				}

				for (let j = 0; j < prevArray[i].documentos.length; j++) {
					if (prevArray[i].documentos[j]?.chave !== newArray[i].documentos[j]?.chave) {
						return true;
					}
				}
			}

			return false;
		}

		let prevMunicipiosLocal = copiarObjeto(values.municipios);

		if (values.municipios?.length > 0 && isDifferent(prevMunicipiosLocal, values.municipios)) {
			setMunicipiosLocal(values.municipios);
			prevMunicipiosLocal = copiarObjeto(values.municipios);
		}
	}, [values.municipios, municipiosLocal]);

	useEffect(() => {
		if (importouNFe) {
			setMunicipiosLocal(values.municipios);
			setMunicipioSelecionado(values.municipios[0]);
		}
		setImportouNFe(false);
	}, [importouNFe]);

	useEffect(() => {
		if (isFirstRender && values.municipios.length > 0) {
			setMunicipiosLocal(values.municipios);
			setIsFirstRender(false);

			setMunicipioSelecionado(values.municipios[0]);
			setIndexMunicipioSelecionado(0);
		}
	}, [isFirstRender, setIsFirstRender, values.municipios]);

	useEffect(() => {
		let quantidadeDeDocumentos = 0;
		if (municipiosLocal?.length > 0) {
			municipiosLocal.forEach((municipio) => {
				if (municipio) {
					quantidadeDeDocumentos += municipio.documentos?.length ?? 0;
				}
			});
		}
		setFieldValue('municipios', municipiosLocal);
		setFieldValue('total.quantidadeDocumento', quantidadeDeDocumentos);
	}, [municipiosLocal]);

	useEffect(() => {
		if (limparSelecionado) {
			setMunicipioSelecionado({});
			setIndexMunicipioSelecionado(null);
			setLimparSelecionado(false);
			setChaveDeAcesso(null);
		}
	}, [limparSelecionado]);

	useEffect(() => {
		if (resetCampos) {
			setChaveDeAcesso(null);
			setMunicipioSelecionado({});
			setIndexMunicipioSelecionado(null);
			setMunicipiosLocal([]);
			setLimparSelecionado(false);
			setResetCampos(false);
		}
	}, [resetCampos]);

	useEffect(() => {
		if (resetCamposCancelamento) {
			setChaveDeAcesso(null);
			setMunicipioSelecionado({});
			setIndexMunicipioSelecionado(null);
			setMunicipiosLocal(initialValues.municipios);
			setLimparSelecionado(false);
			setResetCamposCancelamento(false);
			handleReset();
		}
	}, [resetCamposCancelamento]);

	return (
		<Grid style={{ padding: '0px' }}>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={SingleSelectMunicipio}
				label="Município de descarregamento"
				name="municipio"
				value={municipio}
				optionsRemover={values.municipios}
				onChange={(e) => childRef.current.handleAdicionarMunicipio(e)}
				keyfilter={keyFilterConsultaRsql}
				uf={values.ufDescarregamento?.registro?.sigla}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
			<Col
				sm="12"
				md="6"
				lg="6"
				xl="6"
				style={{ display: 'flex', padding: '0', alignItems: 'baseline', position: 'relative' }}
			>
				<InputChaveDeAcesso
					chaveDeAcesso={chaveDeAcesso}
					setChaveDeAcesso={setChaveDeAcesso}
					handleAdicionarChaveAcesso={(e) => childRef.current.handleAdicionarChaveAcesso(e)}
					isEmpty={isEmpty}
					errors={errors}
					disabled={disabled}
				/>
			</Col>
			<CardMunicipioDescarregamento
				municipio={municipio}
				values={values}
				setFieldValue={setFieldValue}
				municipiosLocal={municipiosLocal}
				setMunicipiosLocal={setMunicipiosLocal}
				limparSelecionado={limparSelecionado}
				setLimparSelecionado={setLimparSelecionado}
				municipioSelecionado={municipioSelecionado}
				setMunicipioSelecionado={setMunicipioSelecionado}
				indexMunicipioSelecionado={indexMunicipioSelecionado}
				setIndexMunicipioSelecionado={setIndexMunicipioSelecionado}
				chaveDeAcesso={chaveDeAcesso}
				setChaveDeAcesso={setChaveDeAcesso}
				isEmpty={isEmpty}
				setErrors={setErrors}
				ref={childRef}
				isCargaPropria={values.tipoEmitente === MDFE_TIPO_EMITENTE.CARGA_PROPRIA}
				disabled={disabled}
			/>
		</Grid>
	);
}

export { Documentos };
