export function validateValorNegativo(valor) {
	return valor < 0 ? 0.0 : valor;
}

export function sumRestoParcelas(parcelas, index) {
	let sum = 0.0;
	for (let i = 0; i < parcelas.length; i++) {
		if (i < index) {
			sum += parcelas[i].valor;
		}
	}
	return sum;
}

export function updateSequencial(parcelas) {
	return parcelas.map((e, i) => ({ ...e, sequencial: i + 1 }));
}

export function divideValorPorQuantidade(valor, quantidade, precision = 2) {
	const valorPorQuantidade = parseFloat((valor / quantidade).toFixed(precision));
	const valorUltimaQuantidade = parseFloat((valor - valorPorQuantidade * (quantidade - 1)).toFixed(precision));

	return {
		valorPorQuantidade,
		valorUltimaQuantidade,
	};
}

export function convertObjectToSelectValue(object) {
	return object
		? {
				value: object.id,
				label: object.nome ?? object.descricao,
				registro: object,
			}
		: null;
}
