import { formatarMonetario } from 'Common';
import { Button, If, NenhumRegistroEncontrado } from 'components';
import { format, parseISO } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const styleColumnOverflowElipsis = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

function TableTitulos({ documentosSelecionados, setDocumentosSelecionados, calcularTotalReparcelamento, origem }) {
	function renderIdentificadorField({ identificador }) {
		return <div style={styleColumnOverflowElipsis}>{identificador}</div>;
	}

	function renderVencimentoField({ vencimento }) {
		const dataVencimento = parseISO(vencimento);
		const dataVencimentoTexto = format(dataVencimento, 'dd/MM/yyyy');

		return <div style={styleColumnOverflowElipsis}>{dataVencimentoTexto}</div>;
	}

	function renderCategoriaField({ categoriaNome, categoriaAparencia }) {
		return <div style={{ ...styleColumnOverflowElipsis, color: categoriaAparencia }}>{categoriaNome}</div>;
	}

	function renderValorField({ valor }) {
		return <div style={styleColumnOverflowElipsis}>{formatarMonetario(valor)}</div>;
	}

	function onRemoveItem(el) {
		const index = documentosSelecionados.indexOf(el);
		const documentosAtualizados = documentosSelecionados.splice(index, 1);
		setDocumentosSelecionados(documentosAtualizados);

		calcularTotalReparcelamento();
	}

	function renderAcoesField(e) {
		return (
			<div style={styleColumnOverflowElipsis}>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title="Remover título"
					disabled={documentosSelecionados.length === 1}
					onClick={() => onRemoveItem(e)}
				/>
			</div>
		);
	}
	return (
		<DataTable
			className="table"
			value={documentosSelecionados}
			responsive
			sortable={false}
			emptyMessage={<NenhumRegistroEncontrado message="Nenhuma conta a receber encontrada" />}
		>
			<If test={origem === 'CONTAS_RECEBER'}>
				<Column header="Identificador" field="identificador" body={renderIdentificadorField} sortable />
			</If>
			<Column header="Vencimento" field="vencimento" body={renderVencimentoField} sortable />
			<Column header="Categoria" field="categoria" body={renderCategoriaField} sortable />
			<Column header="Valor" field="valor" body={renderValorField} sortable />
			<Column
				header="Ações"
				headerClassName="coluna-acoes-end"
				body={(e) => renderAcoesField(e)}
				style={{ width: '130px' }}
			/>
		</DataTable>
	);
}

export default TableTitulos;
