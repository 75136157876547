import { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { TabView as InternalTabView } from 'primereact/tabview';
import { usuarioPossuiModulos } from '../../Common/Autenticacao';

export default function TabView(props) {
	const { modulosEspecificos, children } = props;
	const [validouModulo, setValidouModulo] = useState(true);

	useEffect(() => {
		setValidouModulo(usuarioPossuiModulos(modulosEspecificos));
	}, []);

	if (!validouModulo) return null;

	return <InternalTabView {...props}>{children}</InternalTabView>;
}

TabView.defaultProps = {};

TabView.propTypes = {
	/** Identificador do componente */
	id: propTypes.string,
	/** Módulos que o usuario precisa ter acesso para renderizar o componente */
	modulosEspecificos: propTypes.any,
};
