export function converterCategoriasUtilizadasParaAPI(value) {
	switch (value) {
		case 'receitas':
			return 'RECEITAS';
		case 'despesas':
			return 'DESPESAS';
		case 'todos':
			return 'TODOS';
		default:
			return 'TODOS';
	}
}
