import { useEffect, useState } from 'react';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { services } from '../../../Common/Constantes/api';

export default function SingleSelectBanco(props) {
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.VISUALIZAR)
	);
	const [resetPesquisa, setResetPesquisa] = useState(false);

	useEffect(() => {
		setResetPesquisa(true);
	}, [props.bancoEmissorBoletos]);

	function buscarUrlPesquisa(pesquisa, page) {
		if (props.bancoEmissorBoletos) {
			return `${services.GESTOR}/v1/bancos?query=(codigo=contains="*${pesquisa}*",descricao=contains="*${pesquisa}*");emiteBoleto=="true"&page=${page}&size=50&sort=descricao`;
		} else {
			return `${services.GESTOR}/v1/bancos?query=codigo=contains="*${pesquisa}*",descricao=contains="*${pesquisa}*"&page=${page}&size=50&sort=descricao`;
		}
	}

	function montarLabel(row) {
		return `${row.descricao} (${row.codigo})`;
	}

	return (
		<>
			<SingleSelect
				esconderBotao
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				{...props}
				disabledButton={!podeVisualizar}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
				onChange={props.onChange}
			/>
		</>
	);
}

SingleSelectBanco.defaultProps = {
	label: 'Banco',
};
