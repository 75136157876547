export const helpTributacaoEstadualForm = {
	descricao: 'Descrição da tributação estadual.',
	tributacaoFederal: 'Selecionar a tributação federal.',
	situacao: 'Define se a tributação está ativa ou inativa.',
	favorito: 'Checkbox que destaca se a tributação é a favorita para uso.',

	baseIpi: 'Base de cálculo de IPI da tributação.',
	percentualIpi: 'Alíquota de IPI da tributação.',
	cstPis: 'CST de PIS da tributação.',
	basePis: 'Base de cálculo de PIS da tributação.',
	percentualPis: 'Alíquota de PIS da tributação.',
	basePisSt: 'Base de cálculo de PIS-ST da tributação.',
	percentualPisSt: 'Alíquota de PIS-ST da tributação',
	cstCofins: 'CST de COFINS da tributação.',
	baseCofins: 'Base de cálculo de COFINS da tributação.',
	percentualCofins: 'Alíquota de COFINS da tributação.',
	baseCofinsSt: 'Base de cálculo de COFINS-ST da tributação.',
	percentualCofinsSt: 'Alíquota de COFINS-ST da tributação.',
};
