import { useFormikContext } from 'formik';
import { useContextOrdemServico } from 'views/fiscal/servicos/OrdemServico/Context';

const secaoStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '0',
};

const numeroStyle = {
	fontSize: '24px',
	fontWeight: 'bold',
};

export default function NumeroCard({ isMobile }) {
	const { color } = useContextOrdemServico();

	const { values } = useFormikContext();

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: isMobile ? 'center' : 'flex-start',
			}}
		>
			<div style={secaoStyle} title={!values.numero ? 'O número é gerado automaticamente pelo sistema' : null}>
				<div>Número</div>
				<div style={{ ...numeroStyle, color: color.strongColor }}>{values.numero || '-'}</div>
			</div>
		</div>
	);
}
