import { ButtonCancelar, ButtonNovo, ButtonSalvar, estadosBotaoCancelar, estadosBotaoSalvar } from 'components';
import { useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';

function ActionButtons({ novaConfirmacao, cancelar, salvar }) {
	const { dirty } = useFormikContext();
	const { informacoesPermissoes, podeEditarEmpresa } = useContextConfiguracoesGerais();

	return (
		<>
			<ButtonCancelar
				{...informacoesPermissoes}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={() => cancelar()}
			/>
			<ButtonSalvar
				estadoBotao={estadosBotaoSalvar.SALVAR}
				disabled={!dirty}
				onClick={() => salvar()}
				{...informacoesPermissoes}
			/>
			<ButtonNovo
				label="Nova confirmação"
				onClick={() => novaConfirmacao()}
				disabled={!podeEditarEmpresa}
				podeInserir={podeEditarEmpresa}
			/>
		</>
	);
}

export { ActionButtons };
