import { Field } from 'formik';
import { Dropdown, DropdownMultiSelect } from '../../../../../../components';
import { OptionsBlocoCMovimentacoes, OptionsBlocos, OptionsMovimentos } from '../../../Util/constantes';

function BlocosEMovimentos(props) {
	const { values, geraBlocoC, podeGerar, setFieldValue, isMobile, isTablet, errors } = props;
	return (
		<>
			<Field
				sm="12"
				md="12"
				lg="5"
				xl="5"
				component={DropdownMultiSelect}
				label="Blocos"
				name="blocos"
				options={OptionsBlocos}
				onChange={event => setFieldValue('blocos', event)}
				value={values.blocos}
				obrigatorio
				disabled={!podeGerar}
				showClear={false}
				customStyles={{
					control: {
						height: (isMobile || isTablet) && values.blocos.length > 2 ? undefined : 32,
					},
					valueContainer: {
						marginTop: (isMobile || isTablet) && values.blocos.length > 2 ? '0' : '-5px',
					},
				}}
			/>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				component={Dropdown}
				label="Bloco C - Movimentações"
				name="movimentacoes"
				options={OptionsBlocoCMovimentacoes}
				onChange={event => setFieldValue('blocoC.movimentacoes', event)}
				value={values.blocoC.movimentacoes}
				obrigatorio={geraBlocoC}
				disabled={!geraBlocoC || !podeGerar}
				showClear={false}
				useFormTouched={false}
				useFormErrors={false}
				touched
				errors={errors?.movimentacoes ? errors.movimentacoes : undefined}
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={DropdownMultiSelect}
				label="Movimentos"
				name="movimentos"
				placeholder={isTablet ? 'Selecione' : 'Clique para selecionar'}
				options={OptionsMovimentos}
				onChange={event => setFieldValue('movimentos', event)}
				value={values.movimentos}
				obrigatorio
				disabled={!podeGerar}
				showClear={false}
				customStyles={{
					control: {
						height: (isMobile || isTablet) && values.movimentos.length > 1 ? undefined : 32,
					},
					valueContainer: {
						marginTop: (isMobile || isTablet) && values.movimentos.length > 1 ? '0' : '-5px',
					},
				}}
			/>
		</>
	);
}

export default BlocosEMovimentos;
