export const mensagensDeValidacao = {
	OBRIGATORIO: 'Campo obrigatório',
	EMAIL_INVALIDO: 'Informe um e-mail válido.',
	DATA_INVALIDA: 'Data inválida',
	VALOR_INVALIDO: 'Valor inválido',
	TELEFONE_INVALIDO: 'Digite um numero válido.',
	VALOR_MAIOR_QUE_ZERO: 'Valor não pode ser negativo',
	CEP_INVALIDO: 'Informe um CEP válido.',
	CPF_INVALIDO: 'Digite um CPF válido',
	CNPJ_INVALIDO: 'Digite um CNPJ válido',
	CARACTER_ESPECIAL: 'Valor não pode conter caracteres especiais',
};
