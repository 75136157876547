import { Field, useFormikContext } from 'formik';

import {
	Grid,
	InputDouble,
	InputMoney,
	InputSelectPercentualOrValor,
	SingleSelectCodigoServico,
	SingleSelectOperacaoFiscal,
	SingleSelectProduto,
	SingleSelectUnidadeMedida,
} from 'components';
import {
	calcularImpostosServico,
	copiarObjeto,
	formatarDecimais,
	formatarMonetarioDecimais,
	montarCalcularImpostosServico,
	services,
} from 'Common';
import { TIPOS_PERCENTUAL_VALOR } from 'components/Servicos/Utils';
import { aplicarPercentual } from 'views/Util';

function CamposPrincipais({
	municipioId,
	montarServico,
	decimaisQtd,
	decimaisPreco,
	disableFields,
	informacoesPermissoes,
	tabelaPreco,
	utilizaTabelaPreco,
	servicoForm,
	utilizaTabelaPrecoDescontoQuantidade,
	setAtualizarSubTotal,
	calcularValorVenda,
	calcularPercentualAdicionalTabelaPreco,
}) {
	const { values, errors, setFieldValue, setValues } = useFormikContext();

	async function onChangeServico(value) {
		if (value) {
			let servico = { ...values, servico: value };

			servico = await montarServico(servico, value);

			setValues(servico);
		}
	}

	async function onChangeOperacaoFiscal(value) {
		const servico = await montarCalcularImpostosServico(municipioId, { ...values, operacaoFiscal: value });

		setValues(servico);
	}

	function onChangeValores(field, event) {
		setFieldValue(`${field}`, event?.target?.value ?? 0);
	}

	function onChangeSelect(field, event) {
		setFieldValue(`${field}`, event ?? null);
	}

	function onBlurDescontoAcrescimo(field, value, percentual, tipo = TIPOS_PERCENTUAL_VALOR.VALOR) {
		if (tipo === TIPOS_PERCENTUAL_VALOR.PERCENTUAL) {
			setFieldValue(field, aplicarPercentual(values.subtotal, percentual));
		} else {
			setFieldValue(field, value);
		}

		onBlurValores('desconto');
	}

	async function onBlurValores(field) {
		if (values[field] !== servicoForm[field]) {
			const servico = copiarObjeto(values);

			if (utilizaTabelaPreco) {
				if (field === 'quantidade' && utilizaTabelaPrecoDescontoQuantidade) {
					const adicionalTabelaPreco = await calcularPercentualAdicionalTabelaPreco(servico.quantidade);
					servico.adicionalTabelaPreco = {
						percentual: adicionalTabelaPreco.percentual,
						tipo: adicionalTabelaPreco.tipo,
					};

					servico.valor = parseFloat(
						calcularValorVenda(servico.valorOriginal, servico.adicionalTabelaPreco).toFixed(decimaisPreco)
					);

					servico.alterouValorManualmente = false;
					servico.alterouQuantidade = false;
					servico.valorAntesAlteracao = null;
				}
				if (
					field === 'valor' &&
					(tabelaPreco?.registro?.editarPrecoAbaixoValorTabela || tabelaPreco?.registro?.editarPrecoAcimaValorTabela)
				) {
					servico.alterouValorManualmente = true;
					servico.valorAntesAlteracao = servico.valor;
				}
			}

			await calcularImpostosServico(servico);

			setValues(servico);
			setAtualizarSubTotal(true);
		}
	}

	return (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="5"
				xl="5"
				name="servico"
				label="Servico"
				component={SingleSelectProduto}
				url={`${services.GESTOR}/v1/produtos`}
				onChange={(event) => onChangeServico(event)}
				value={values?.servico}
				placeholder="Selecione o servico"
				disabled={disableFields}
				filtroAdicionalUrl={`;tipo=="SERVICO"`}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				label="Unidade de medida"
				name="unidadeMedida"
				obrigatorio
				component={SingleSelectUnidadeMedida}
				value={values.unidadeMedida}
				onChange={(event) => onChangeSelect('unidadeMedida', event)}
				isClearable={false}
				errors={errors.unidadeMedida}
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputDouble}
				label="Quantidade"
				name="quantidade"
				obrigatorio
				value={values.quantidade}
				onChange={(event) => onChangeValores('quantidade', event)}
				onBlur={() => onBlurValores('quantidade')}
				placeholder={formatarDecimais(0, decimaisQtd)}
				decimalScale={decimaisQtd}
				size={10}
				allowNegative={false}
				errors={errors.codigoServico}
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Valor unitário"
				name="valor"
				obrigatorio
				value={values.valor}
				onChange={(event) => onChangeValores('valor', event)}
				onBlur={() => onBlurValores('valor')}
				touched
				placeholder={formatarMonetarioDecimais(0, decimaisPreco)}
				allowNegative={false}
				decimalScale={decimaisPreco}
				size={13}
				errors={errors.valor}
				disabled={
					disableFields ||
					(utilizaTabelaPreco &&
						!tabelaPreco?.registro?.editarPrecoAbaixoValorTabela &&
						!tabelaPreco?.registro?.editarPrecoAcimaValorTabela)
				}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="5"
				lg="5"
				xl="5"
				component={SingleSelectCodigoServico}
				label="Código serviço"
				name="codigoServico"
				obrigatorio
				value={values.codigoServico}
				onChange={(event) => onChangeSelect('codigoServico', event)}
				mostrarSimbolo
				disabled={disableFields}
				isClearable={false}
				errors={errors.codigoServico}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				label="Operação fiscal"
				name="operacaoFiscal"
				obrigatorio
				component={SingleSelectOperacaoFiscal}
				value={values.operacaoFiscal}
				onChange={(event) => onChangeOperacaoFiscal(event)}
				isClearable={false}
				esconderBotao
				errors={errors.operacaoFiscal}
				disabled={disableFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="2"
				md="2"
				lg="2"
				xl="2"
				component={InputSelectPercentualOrValor}
				label="Desconto"
				name="desconto"
				onBlur={(tipo, value, percentual) => onBlurDescontoAcrescimo('desconto', value, percentual, tipo)}
				touched
				allowNegative={false}
				value={values?.desconto}
				size={13}
				disabled={disableFields}
				informacoesPermissoes={informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Total"
				name="subtotal"
				value={values.subtotal}
				touched
				allowNegative={false}
				disabled
				size={13}
				style={{ fontWeight: 'bold' }}
			/>
		</Grid>
	);
}

export { CamposPrincipais };
