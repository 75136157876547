import { Checkbox, Grid, Subtitle } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { helpParametrizacao } from '../../Help';

function TabGeral() {
	const { podeEditarEmpresa } = useContextConfiguracoesGerais();
	const { values, setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Subtitle subtitle="Geral" />
			<Field
				helpMessage={helpParametrizacao.pesquisaInteracao}
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Pesquisar apenas com interação"
				name="pesquisaInteracao"
				value={values.parametrizacao.geral?.pesquisaInteracao}
				onChange={(e) => setFieldValue('parametrizacao.geral.pesquisaInteracao', e.checked)}
				checked={values.parametrizacao.geral?.pesquisaInteracao}
				disabled={!podeEditarEmpresa}
			/>
		</Grid>
	);
}

export { TabGeral };
