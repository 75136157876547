const OptionsPerfil = [
	{ label: 'Perfil A', value: 'PERFIL_A' },
	{ label: 'Perfil B', value: 'PERFIL_B' },
	{ label: 'Perfil C', value: 'PERFIL_C' },
];

const OptionsIndicacaoAtividade = [
	{ label: '0 - Industrial ou equiparado a industrial', value: 'IND_OU_EQUIPARADO_A_IND' },
	{ label: '1 - Outros', value: 'OUTROS' },
];

const OptionsClassificacaoContribuinteIpi = [
	{ label: '00 - Industrial - Transformação', value: 'INDUSTRIAL_TRANSFORMACAO' },
	{ label: '01 - Industrial - Beneficiamento', value: 'INDUSTRIAL_BENEFICIAMENTO' },
	{ label: '02 - Industrial - Montagem', value: 'INDUSTRIAL_MONTAGEM' },
	{
		label: '03 - Industrial - Acondicionamento ou reacondicionamento',
		value: 'INDUSTRIAL_ACONDICIONAMENTO_OU_REACONDICIONAMENTO',
	},
	{ label: '04 - Industrial - Renovação ou recondicionamento', value: 'INDUSTRIAL_RENOVACAO_OU_RECONDICIONAMENTO' },
	{ label: '05 - Equiparado a industrial - Por opção', value: 'EQUIP_INDUSTRIAL_POR_OPCAO' },
	{ label: '06 - Equiparado a industrial - Importação direta', value: 'EQUIP_INDUSTRIAL_IMPORTACAO_DIRETA' },
	{ label: '07 - Equiparado a industrial - Por lei específica', value: 'EQUIP_INDUSTRIAL_POR_LEI_ESPECIFICA' },
	{
		label: '08 - Equiparado a industrial - Não enquadrado nos códigos 05, 06 ou 07',
		value: 'EQUIP_INDUSTRIAL_NAO_ENQUADRADO_NOS_CODIGOS_05_06_OU_07',
	},
	{ label: '09 - Outros', value: 'OUTROS' },
];

const OptionsBlocos = [
	{ label: 'Bloco C', value: 'BLOCO_C' },
	{ label: 'Bloco E', value: 'BLOCO_E' },
	{ label: 'Bloco H', value: 'BLOCO_H' },
	{ label: 'Bloco 1', value: 'BLOCO_1' },
];

const OptionsBlocoCMovimentacoes = [
	{ label: 'C100 e C190 para notas de emissão própria', value: 'C100_C190' },
	{ label: 'C170 para todas as notas', value: 'C170' },
];

const OptionsMovimentos = [
	{ label: 'Compras', value: 'COMPRAS' },
	{ label: 'Vendas', value: 'VENDAS' },
];

const OptionsTipoCusto = [
	{ label: 'Último custo', value: 'ULTIMO_CUSTO' },
	{ label: 'Custo médio', value: 'CUSTO_MEDIO' },
	{ label: 'Preço custo', value: 'PRECO_CUSTO' },	
	{ label: 'Preço venda', value: 'PRECO_VENDA' },
];

const OptionsMotivoInventario = [
	{ label: '01 - No final no período', value: 'FINAL_PERIODO' },
	{ label: '02 - Na mudança de forma de tributação da mercadoria (ICMS)', value: 'MUDANCA_TRIBUTACAO_MERCADORIA' },
	{
		label: '03 - Na solicitação da baixa cadastral, paralisação temporária e outras situações',
		value: 'SOLICITACAO_BAIXA_CADASTRAL',
	},
	{ label: '04 - Na alteração de regime de pagamento - condição do contribuinte', value: 'ALTERACAO_REGIME' },
	{ label: '05 - Por determinação dos fiscos', value: 'DETERMINACAO_FISICO' },
	{
		label: '06 - Para controle das mercadorias sujeitas ao regime de substituição tributária - restituição/ressarcimento/complementação',
		value: 'MERCADORIAS_SUJEITAS_ST',
	},
];

const MessageObrigatorio = 'Obrigatório';

export {
	OptionsPerfil,
	OptionsIndicacaoAtividade,
	OptionsClassificacaoContribuinteIpi,
	OptionsBlocos,
	OptionsBlocoCMovimentacoes,
	OptionsMovimentos,
	OptionsTipoCusto,
	OptionsMotivoInventario,
	MessageObrigatorio,
};
