import { useState } from "react";
import { AnimacaoCompletado } from "./components/AnimacaoCompletado";
import { QRCodePix } from "./components/QRCodePix";
import {
    If,
    Modal,    
} from "components";

function ModalRecebimentoPix({ visible, onHide, container, lancamentoId, id, titulo, valor, hideBackground }){        
    const [activeIndex, setActiveIndex] = useState(0);
    const [pixId, setPixId] = useState(null);

    return (
        <>
          <Modal header="Recebimento - Pix" 
            styleModal={{maxWidth : '500px', minHeight : '430px'}} 
            visible={visible} 
            onHide={() => onHide(pixId)}
            container={container}
            hideBackground={hideBackground}
          >            
            <If test={activeIndex===0}>
                <QRCodePix
                    titulo={titulo}
                    valor={valor}
                    lancamentoId={lancamentoId}      
                    id={id}
                    activeIndex={activeIndex}          
                    setActiveIndex={setActiveIndex}
                    setPixId={setPixId}
            />      
            </If>
            <If test={activeIndex==1}>                
                <AnimacaoCompletado
                    titulo={titulo}
                    onHide={() => onHide(pixId)}
                />
            </If>            
          </Modal>  
        </>
    );
}

export { ModalRecebimentoPix };