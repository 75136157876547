import { mensagensDeValidacao } from 'Common';
import {
	ButtonCancelar,
	ButtonSalvar,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputMoney,
	InputPercentage,
	Modal,
	ToastTypes,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
	notify,
} from 'components';
import { Field, useFormikContext, withFormik } from 'formik';
import { validarFormulario } from 'views/Util';
import { useContextTabelaPreco } from 'views/cadastros/TabelaPreco/Context';
import {
	alterarComissaoPercentualEmLote,
	alterarDescontoMaximoPercentualEmLote,
	alterarLucroPercentualEmLote,
	alterarPrecoVendaEmLote,
} from 'views/cadastros/TabelaPreco/Requests';

function ModalAlteracaoEmLoteImpl({ visible, onCloseModal, fetch, idTabelaPreco, dadosModalAlteracaoEmLote }) {
	const { values, dirty, resetForm, handleSubmit, validateForm, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, desabilitarCampos } = useContextTabelaPreco();

	function handleClickCancelar() {
		if (dirty) {
			resetForm({
				values: {
					tipoAlteracao: 'ACRESCENTAR',
					valorOuPercentual: 'PERCENTUAL',
					percentualAlteracao: 0,
					valorAlteracao: 0,
				},
			});
		} else {
			onCloseModal();
		}
	}

	async function handleClickSalvar() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			switch (dadosModalAlteracaoEmLote.field) {
				case 'LUCRO_PERCENTUAL': {
					await alterarLucroPercentualEmLote(
						idTabelaPreco,
						values,
						() => {
							fetch();
						},
						() => {
							notify('Erro desconhecido ao atualizar o lucro % dos produtos', ToastTypes.ERROR);
						}
					);

					break;
				}
				case 'PRECO_VENDA': {
					await alterarPrecoVendaEmLote(
						idTabelaPreco,
						values,
						() => {
							fetch();
						},
						() => {
							notify('Erro desconhecido ao atualizar o preço de venda dos produtos', ToastTypes.ERROR);
						}
					);

					break;
				}
				case 'COMISSAO_PERCENTUAL': {
					await alterarComissaoPercentualEmLote(
						idTabelaPreco,
						values,
						() => {
							fetch();
						},
						() => {
							notify('Erro desconhecido ao atualizar a comissão % dos produtos', ToastTypes.ERROR);
						}
					);

					break;
				}
				case 'DESCONTO_MAXIMO_PERCENTUAL': {
					await alterarDescontoMaximoPercentualEmLote(
						idTabelaPreco,
						values,
						() => {
							fetch();
						},
						() => {
							notify('Erro desconhecido ao atualizar o desconto máximo % dos produtos', ToastTypes.ERROR);
						}
					);

					break;
				}
				default: {
					break;
				}
			}

			onCloseModal();
		}
	}

	return (
		<Modal
			header={`Atualizar ${dadosModalAlteracaoEmLote.label}`}
			visible={visible}
			onHide={onCloseModal}
			styleModal={{ maxWidth: '420px' }}
		>
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={handleClickCancelar}
						{...informacoesPermissoes}
					/>
					<ButtonSalvar
						estadoBotao={estadosBotaoSalvar.SALVAR}
						disabled={!dirty || desabilitarCampos}
						onClick={handleClickSalvar}
						{...informacoesPermissoes}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Dropdown}
							label="Tipo alteração"
							name="tipoAlteracao"
							options={[
								{ label: 'Acrescentar', value: 'ACRESCENTAR' },
								{ label: 'Descontar', value: 'DESCONTAR' },
							]}
							onChange={(e) => setFieldValue('tipoAlteracao', e.value)}
							value={values?.tipoAlteracao}
							showClear={false}
							{...informacoesPermissoes}
						/>
						<If test={dadosModalAlteracaoEmLote.field === 'PRECO_VENDA'}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Dropdown}
								label="Valor ou percentual"
								name="valorOuPercentual"
								options={[
									{ label: 'Valor', value: 'VALOR' },
									{ label: 'Percentual', value: 'PERCENTUAL' },
								]}
								onChange={(e) => setFieldValue('valorOuPercentual', e.value)}
								value={values?.valorOuPercentual}
								showClear={false}
								{...informacoesPermissoes}
							/>
						</If>

						<If test={values.valorOuPercentual === 'VALOR'}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={InputMoney}
								label="Informe o valor que deseja atualizar"
								name="valorAlteracao"
								allowNegative={false}
								value={values?.valorAlteracao}
								onChange={(e) => setFieldValue('valorAlteracao', e.target.value)}
								{...informacoesPermissoes}
							/>
						</If>

						<If test={values.valorOuPercentual === 'PERCENTUAL'}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={InputPercentage}
								label="Informe o percentual que deseja atualizar"
								name="percentualAlteracao"
								suffix=" %"
								placeholder="0 %"
								allowNegative={false}
								value={values?.percentualAlteracao}
								onChange={(e) => setFieldValue('percentualAlteracao', e.target.value)}
								{...informacoesPermissoes}
							/>
						</If>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalAlteracaoEmLote = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return { tipoAlteracao: 'ACRESCENTAR', valorOuPercentual: 'PERCENTUAL', percentualAlteracao: 0, valorAlteracao: 0 };
	},

	validate(values) {
		const errors = {};

		if (!values.tipoAlteracao) {
			errors.tipoAlteracao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.valorOuPercentual === 'PERCENTUAL' && !values.percentualAlteracao) {
			errors.percentualAlteracao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.valorOuPercentual === 'VALOR' && !values.valorAlteracao) {
			errors.valorAlteracao = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},
	handleSubmit: () => {},
})(ModalAlteracaoEmLoteImpl);
export { ModalAlteracaoEmLote };
