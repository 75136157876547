import { useState } from 'react';

import SingleSelect from '../SingleSelect';

export default function SingleSelectContabilidade(props) {
  const [resetPesquisa, setResetPesquisa] = useState(false);

  function buscarUrlPesquisa(pesquisa, page) {
    return `${props.url}?query=(nome=contains="*${pesquisa}*");bloqueada==false;autoridade!="CLIENTE"&page=${page}&size=50&sort=nome`;
  }

  function montarLabel(row) {
    let identificacao = '';
    if (row.cpf || row.cnpj) {
      identificacao = ` (${row.cnpj ?? row.cpf})`;
    }

    return `${row.nome}${identificacao}`;
  }

  return (
    <>
      <SingleSelect
        buscarUrlPesquisa={buscarUrlPesquisa}
        montarLabel={montarLabel}
        onChange={props.onChange}
        resetPesquisa={resetPesquisa}
        setResetPesquisa={setResetPesquisa}
        esconderBotao={true}
        {...props}
      />
    </>
  );
}

SingleSelectContabilidade.defaultProps = {
  label: 'Contabilidade',
};
