export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é seu cadastro de vendedores, nele é  gerenciado as associações com regiões e tabelas de preços dos vendedores.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-vendedor-nome',
		content: 'No campo "nome", é informado o nome do vendedor',
		placement: 'top',
	},
	{
		target: '.step-vendedor-tipo',
		content: 'No campo "tipo", informa-se o cargo do vendedor, sendo eles: Vendedor, supervisor ou gerente.',
		placement: 'top',
	},
	{
		target: '.step-vendedor-situacao',
		content: 'No campo "situação", é informado a situação do vendedor.',
		placement: 'top',
	},
	{
		target: '.step-vendedor-configuracoes',
		content:
			'Nesta seção, estão presentes informações operacionais do vendedor como tabela de preço padrão, desconto máximo e comissão, origem de cobrança, superior associado e os usuários do sistema que possam ter acesso ao vendedor.',
		placement: 'top',
	},
	{
		target: '.step-vendedor-regioes',
		content:
			'Por fim, é possível associar as regiões que o vendedor atende e associar a tabela de preço que esta região utilizará. Para usufruir desta opção, é necessário habilitar o parâmetro "Utiliza tabela de preço por região".',
		placement: 'top',
	},
];
