import PropType from 'prop-types';

function Col(props) {
	const { hidden, id, style, children, col, sm, md, lg, xl, offset, title } = props;

	function montarClassName() {
		const { className = '' } = props;

		let classe = `${className} col`;

		if (col) {
			classe = `${className} col-${col}`;
		}
		if (offset) {
			classe += ` offset-${offset}`;
		}
		if (sm) {
			classe += ` sm:col-${sm}`;
		}
		if (md) {
			classe += ` md:col-${md}`;
		}
		if (lg) {
			classe += ` lg:col-${lg}`;
		}
		if (xl) {
			classe += ` xl:col-${xl}`;
		}

		return classe;
	}

	const classe = montarClassName();

	return (
		<div
			id={id}
			title={title}
			style={style}
			hidden={hidden}
			col={col}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
			offset={offset}
			className={classe}
		>
			{children}
		</div>
	);
}

Col.defaultProps = {
	col: 12,
	hidden: false,
};

Col.PropType = {
	/** Identificador do componente */
	id: PropType.oneOfType([Number, String]),
	/** Classe do componente */
	className: PropType.oneOfType([Number, String]),
	/** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
	col: PropType.oneOfType([Number, String]),
	/** Tamanho em small devices (0 a 12) */
	sm: PropType.oneOfType([Number, String]),
	/** Tamanho em medium devices (0 a 12) */
	md: PropType.oneOfType([Number, String]),
	/** Tamanho em large devices (0 a 12) */
	lg: PropType.oneOfType([Number, String]),
	/**Tamanho em extra large devices (0 a 12) */
	xl: PropType.oneOfType([Number, String]),
	/** Esconde o componente*/
	hidden: PropType.oneOfType([Number, String]),
	/** Estilo do componente*/
	style: PropType.oneOfType([Number, String]),
	/** Filhos do componente*/
	children: PropType.oneOfType([Number, String]),
};

export default Col;
