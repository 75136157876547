import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import MaskInput from 'react-maskinput';
import Col from '../../../../Col';
import {
	buscarDisabledDeAcordoComAsPermissoes,
	buscarHiddenDeAcordoComAsPermissoes,
	buscarTitleCampoDeAcordoComAsPermissoes,
} from '../../../../../Common/Autorizacao/ManipulacaoDeComponentes';
import {
	renderizarValidacao,
	buscarClassNamePelosErros,
} from '../../../../../Common/TratamentoDeErro/validacoesDeCampos';
import { manterApenasNumeros } from '../../../../../Common/Mascara';
import { buscarDadosCEP, montarObjetoMunicipio } from '../../Functions';
import Button from '../../../../Button';
import { alert } from '../../../../Toast';
import { usuarioPossuiModulos } from '../../../../../Common/Autenticacao';
import { asyncBuscarMunicipiosPeloCodigo } from '../../Requests';
import { isValidCEP } from '@brazilian-utils/brazilian-utils';

export function InternalInputCep(props) {
	const {
		sm,
		md,
		lg,
		xl,
		id,
		name,
		placeholder,
		onBlur,
		errors,
		touched,
		podeVisualizar,
		podeInserir,
		podeEditar,
		estadoCadastro,
		colId,
		type,
		style,
	} = props;

	const [validouModulo, setValidouModulo] = useState(true);

	useEffect(() => {
		if (props.modulosEspecificos) {
			setValidouModulo(usuarioPossuiModulos(this.props.modulosEspecificos));
		}
	}, []);

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden))
			return { display: 'none', ...props.colStyle };
		return props.colStyle;
	}

	function onChange(e) {
		if (e.target.value === '') {
			props.onChange({ value: null });
		} else {
			props.onChange({ value: manterApenasNumeros(e.target.value) });
		}
	}

	function getValue() {
		return props.value ? props.value : '';
	}

	function onClickCep() {
		const cep = manterApenasNumeros(props.value);

		buscarDadosCEP(
			cep,
			async data => {
				asyncBuscarMunicipiosPeloCodigo(data, ({ data: municipios }) => {
					const dadosForm = montarObjetoMunicipio(municipios, data);
					props.onChange({
						...dadosForm,
						value: props.value,
						pesquisou: true,
					});
				});
			},
			err => {
				alert(
					'Atenção',
					'Não foi possível estabelecer uma conexão com o servidor. Por favor, preencha manualmente os valores ou tente novamente mais tarde.'
				);
			},
			message => {
				props.onInvalidCep && props.onInvalidCep(message);
			}
		);
	}

	function onKeyUp(e) {
		if (e.keyCode === 13) {
			onClickCep();
		}
	}

	function montarLabel() {
		const { obrigatorio } = props;

		const label = props.label;
		if (obrigatorio) {
			return (
				<label title={props.helpMessage}>
					{label} <b style={{ fontSize: '18px', lineHeight: '5px' }}> *</b>{' '}
				</label>
			);
		}
		return <label title={props.helpMessage}>{label}</label>;
	}

	if (!validouModulo) {
		return null;
	}

	const disabled = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, props.disabled);
	const colStyle = getColStyle();
	const title = buscarTitleCampoDeAcordoComAsPermissoes(
		podeVisualizar,
		podeInserir,
		podeEditar,
		estadoCadastro,
		props.title
	);
	const classNameInput = buscarClassNamePelosErros(
		'p-inputtext p-state-default p-corner-all p-widget ' + (disabled && 'p-disabled'),
		errors,
		touched
	);
	const disabledButton = !isValidCEP(manterApenasNumeros(props.value)) || disabled;

	return (
		<Col id={colId} sm={sm} md={md} lg={lg} xl={xl} style={colStyle}>
			{montarLabel()}

			<span className="p-fluid" title={title}>
				<div className="p-inputgroup">
					<MaskInput
						id={id}
						className={classNameInput}
						style={{ height: '32px', borderRadius: '3px 0px 0px 3px', ...style }}
						name={name}
						placeholder={placeholder}
						value={getValue()}
						onChange={onChange}
						disabled={disabled}
						mask="00000-000"
						onBlur={onBlur}
						onKeyUp={onKeyUp}
						type={type}
						autoComplete="off"
					/>
					<Button
						style={{
							margin: '0px',
							borderTopLeftRadius: '0px',
							borderBottomLeftRadius: '0px',
							height: '32px',
						}}
						icon="fa fa-search"
						title="Preencher as informações automaticamente"
						disabled={disabledButton}
						onClick={onClickCep}
					/>
				</div>
			</span>
			{renderizarValidacao(errors, touched)}
		</Col>
	);
}

InternalInputCep.propTypes = {
	/** Evento disparado ao modificar o componente do componente */
	onChange: propTypes.func,
	/** Label do componente */
	label: propTypes.string,
	/** Identificador do componente */
	id: propTypes.string,
	/** Identificador do id da coluna componente */
	colId: propTypes.string,
	/** Placeholder do componente */
	placeholder: propTypes.string,
	/** Valor do componente do componente */
	value: propTypes.string,
	/** Define se o componente está desabilitado*/
	disabled: propTypes.bool,
	/** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
	errors: propTypes.string,
	/** Especifica se o componente foi 'tocado'*/
	touched: propTypes.bool,
	/** Especifica a máscara do componente. Ex: '111.111.111-11'*/
	mask: propTypes.string,
	/** Evento executado ao sair do campo*/
	onBlur: propTypes.any,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
	/** Estilo da coluna*/
	colStyle: propTypes.object,
	/** Estado em que o cadastro se encontra*/
	estadoCadastro: propTypes.string,
	/** Diz se o usuário possui permissão de visualizar*/
	podeVisualizar: propTypes.bool,
	/** Diz se o usuário possui permissão de editar*/
	podeEditar: propTypes.bool,
	/** Diz se o usuário possui permissão de excluir*/
	podeInserir: propTypes.bool,
	/** Title do componente*/
	title: propTypes.string,
	/** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
	helpMessage: propTypes.string,
};
