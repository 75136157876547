import { Dropdown, Grid, Subtitle } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { gerarOptionsDecimais } from '../../Utils/functions';

function TabCadastros() {
	const { isMobile, isTablet, podeEditarEmpresa } = useContextConfiguracoesGerais();
	const { values, setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Subtitle subtitle="Cadastros" />
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={Dropdown}
				label={isMobile || isTablet ? 'Decimais qtde.' : 'Decimais quantidade'}
				name="decimaisQtd"
				value={values.parametrizacao.cadastros?.decimaisQtd}
				onChange={(e) => setFieldValue('parametrizacao.cadastros.decimaisQtd', e.value)}
				options={gerarOptionsDecimais(2, 4)}
				obrigatorio
				showClear={false}
				disabled={!podeEditarEmpresa}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={Dropdown}
				label="Decimais preço"
				name="decimaisPreco"
				value={values.parametrizacao.cadastros?.decimaisPreco}
				onChange={(e) => setFieldValue('parametrizacao.cadastros.decimaisPreco', e.value)}
				options={gerarOptionsDecimais(2, 6)}
				obrigatorio
				showClear={false}
				disabled={!podeEditarEmpresa}
			/>
		</Grid>
	);
}

export { TabCadastros };
