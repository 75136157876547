import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar } from 'components';
import { useContextNFCe } from '../../../../../../../Context';
import { validarFormulario } from '../../../../../../../../../../Util';

function ActionButtons(props) {
	const {
		dirty,
		disabled,
		onHide,
		handleReset,
		setFieldValueForm,
		submitCount,
		setSubmitCount,
		indexProdutoLista,
		setAtualizarTotal,
		values,
	} = props;

	const { informacoesPermissoes } = useContextNFCe();

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;

	function cancelar() {
		dirty ? handleReset() : onHide();
	}

	async function salvar() {
		setSubmitCount(submitCount + 1);

		if (await validarFormulario(props)) {
			setFieldValueForm(`produtos[${indexProdutoLista}]`, values.produto);
			setAtualizarTotal(true);
			onHide();
		}
	}

	return (
		<>
			<ButtonCancelar onClick={cancelar} estadoBotao={estadoBotaoCancelar} {...informacoesPermissoes} />
			<ButtonSalvar onClick={salvar} disabled={!dirty || disabled} {...informacoesPermissoes} />
		</>
	);
}
export default ActionButtons;
