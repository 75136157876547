import { formatISO, startOfMonth } from 'date-fns';

export const INITIAL_VALUES = {
	dataInicial: formatISO(startOfMonth(new Date())),
	dataFinal: new Date().toISOString(),
	vendedores: null,
	origemCobranca: 'FATURAMENTO',
	documentoOrigem: 'PEDIDO_VENDA',
};

export const HELP_MESSAGE = {
	dataInicial: 'Data inicial da impressão',
	dataFinal: 'Data final da impressão',
};
