import { gerarUUID } from 'Common';
import { addMonths, formatISO, parseISO } from 'date-fns';
import { useCallback } from 'react';
import { INITIAL_VALUE_PARCELA } from '../Util/constantes';
import { divideValorPorQuantidade } from '../Util/functions';

/**
 * @description Hook para gerar as parcelas de uma forma de pagamento a prazo
 *
 * @param {Object} defaultParams - Parametros default para os a função generateParcelas
 *
 * @exports generateParcelas
 */
function useGenerateParcelas(defaultParams) {
	/**
	 * @description Função que gera as parcelas
	 *
	 * @param {paramsType} params - Parametros necessários para gerar as parcelas
	 *
	 * @typedef {Object} paramsType
	 * @property {Number} qtdParcelas - Número de parcelas
	 * @property {Array} parcelas - Array de parcelas atualizado antes de gerar as parcelas
	 * @property {Float} valorPagamento - Valor total do pagamento
	 * @property {Date} dataBaseParcela - Data base para gerar as parcelas
	 * @property {favoritosType} favoritos - Forma de pagamento e conta favoritas
	 *
	 * @typedef {Object} favoritosType
	 * @property {Object} formaPagamento - Forma de pagamento favorita
	 * @property {Object} conta - Conta favorita
	 *
	 * @returns {Array} Parcelas
	 */
	const generateParcelas = useCallback(
		({
			qtdParcelas = defaultParams.qtdParcelas,
			parcelas = defaultParams.parcelas,
			valorPagamento = defaultParams.valorPagamento,
			dataBaseParcela = defaultParams.dataBaseParcela,
			favoritos: { formaPagamento, conta } = defaultParams.favoritos,
		}) => {
			const newParcelas = [];
			const { valorPorQuantidade, valorUltimaQuantidade } = divideValorPorQuantidade(valorPagamento, qtdParcelas);

			for (let i = 0; i < qtdParcelas; i++) {
				const oldParcela = parcelas && parcelas[i];

				newParcelas.push({
					...INITIAL_VALUE_PARCELA,
					id: oldParcela?.id ? oldParcela.id : null,
					tempKey: oldParcela ? oldParcela.tempKey : gerarUUID(),
					sequencial: oldParcela?.sequencial ? oldParcela.sequencial : i + 1,
					vencimento: formatISO(addMonths(parseISO(dataBaseParcela), i + 1)),
					formaPagamento: oldParcela?.formaPagamento ? oldParcela.formaPagamento : formaPagamento,
					conta: oldParcela?.conta ? oldParcela.conta : conta,
					descricao: oldParcela?.descricao ? oldParcela.descricao : null,
					valor: i === qtdParcelas - 1 ? valorUltimaQuantidade : valorPorQuantidade,
				});
			}

			return newParcelas;
		}
	);

	return [generateParcelas];
}

export { useGenerateParcelas };
