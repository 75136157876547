import { useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import Modulos from '../../../Modulos';

function TabModulosSistema() {
	const { podeEditarEmpresa } = useContextConfiguracoesGerais();
	const { values, errors, setFieldValue } = useFormikContext();

	return (
		<>
			<p style={{ fontSize: '16px', fontWeight: 'bold', padding: '0.5rem 0' }}>Módulos habilitados</p>
			<p style={{ paddingBottom: '0.5rem' }}>
				Clicando nos itens abaixo você pode escolher quais módulos do sistema você irá utilizar. Todos os módulos
				possuem um plano gratuito, se você sentir necessidade de mais, pode aderir a um novo plano.
			</p>
			<Modulos
				podeEditarEmpresa={podeEditarEmpresa}
				value={values.modulos}
				onChange={(value) => setFieldValue('modulos', value)}
				errors={errors.modulos}
			/>
		</>
	);
}

export { TabModulosSistema };
