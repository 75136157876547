import { copiarObjeto } from 'Common';
import { useFormikContext } from 'formik';
import { FaTimes } from 'react-icons/fa';
import { useContextMDFe } from 'views/transporte/MDFe/Context';

export function ExcluirParcela({ exibirLabels, excluirDisabled, index }) {
	const { informacoesPermissoes, isMobile } = useContextMDFe();
	const { values, setFieldValue } = useFormikContext();

	function handleClickExcluir() {
		let prazosTemp = copiarObjeto(values.prazos);

		prazosTemp.splice(index, 1);
		const valorPorParcela = parseFloat((values.valorContrato / prazosTemp.length).toFixed(2));
		const valorUltimaParcela = parseFloat(
			(values.valorContrato - valorPorParcela * (prazosTemp.length - 1)).toFixed(2)
		);
		const rateio = {
			valorPorParcela: valorPorParcela,
			valorUltimaParcela: valorUltimaParcela,
		};

		prazosTemp = prazosTemp.map((parcela, index) => {
			if (index === prazosTemp.length - 1) {
				return { ...parcela, valor: rateio.valorUltimaParcela };
			} else {
				return { ...parcela, valor: rateio.valorPorParcela };
			}
		});

		setFieldValue('quantidadeParcelas', prazosTemp.length);
		setFieldValue('prazos', prazosTemp);
	}

	return (
		<button
			type="button"
			onClick={() => (excluirDisabled || !informacoesPermissoes.podeEditar ? null : handleClickExcluir())}
			style={{
				color: '#e91224',
				width: '30px',
				height: '30px',
				marginTop: exibirLabels && !isMobile ? '22px' : '5px',
				background: 'none',
				border: 'none',
				padding: '0px',
			}}
			disabled={excluirDisabled}
		>
			<FaTimes
				size={20}
				title={excluirDisabled ? 'Você não pode executar esta ação' : 'Excluir item'}
				style={{
					color: '#e91224',
					width: '20px',
					height: '20px',
					opacity: excluirDisabled ? '0.5' : '1',
					cursor: excluirDisabled ? 'default' : 'pointer',
				}}
			/>
			{` `}
		</button>
	);
}
