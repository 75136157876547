export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é o cadastro de código de serviço, a qual posteriormente será vinculado ao produto/serviço.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-codigo-servico-principais',
		content: 'Aqui você vai preencher o código, descrição e a situação do código de serviço.',
	},
	{
		target: '.step-listagem-tributacoes',
		content:
			'Aqui serão listados todos os vínculos do código de serviço com a operação fiscal e a tributação municipal.',
	},
	{
		target: '.step-editar-tributacoes',
		content:
			'Aqui é possível incluir, editar ou deletar os vínculos do código de serviço com a operação fiscal e a tributação municipal',
	},
];
