import { buscarDadosLoginLocalStorage, permissoes, recursos } from 'Common';
import { Switch, withRouter } from 'react-router-dom';
import PrivateRoute from 'components/Route';

import ConfiguracoesGerais from 'views/configuracao/ConfiguracoesGerais';
import ConfiguracoesGeraisProvider from 'views/configuracao/ConfiguracoesGerais/Context';

import MinhaConta from 'views/configuracao/MinhaConta';

import Papeis from 'views/configuracao/Papeis';
import PapeisForm from 'views/configuracao/Papeis/Form';

import { Planos } from 'views/configuracao/Planos';
import PlanosProvider from 'views/configuracao/Planos/Context';

import Usuarios from 'views/configuracao/Usuarios';
import UsuariosForm from 'views/configuracao/Usuarios/Form';
import NotasDeVersao from 'views/configuracao/NotasDeVersao';
import { If } from 'components';
import { PainelHelpDesk } from 'views/configuracao/PainelHelpDesk';

export const pathConfiguracoesRoutes = [
	'/papeis',
	'/papeis/cadastro',
	'/papeis/cadastro/:id',
	'/usuarios',
	'/usuarios/cadastro',
	'/usuarios/cadastro/:id',
	'/configuracoes_gerais',
	'/minhaconta',
	'/notas_de_versao',
	'/planos',
	'/painel_helpdesk',
];

function ConfiguracoesRoutes() {
	const localStorage = buscarDadosLoginLocalStorage();

	return (
		<Switch>
			<PrivateRoute
				exact
				path="/papeis"
				render={(props) => <Papeis {...props} />}
				recurso={recursos.PAPEIS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/papeis/cadastro', '/papeis/cadastro/:id']}
				render={(props) => <PapeisForm {...props} />}
				recurso={recursos.PAPEIS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/usuarios"
				render={(props) => <Usuarios {...props} />}
				recurso={recursos.USUARIOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/usuarios/cadastro', '/usuarios/cadastro/:id']}
				render={(props) => <UsuariosForm {...props} />}
				recurso={recursos.USUARIOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/configuracoes_gerais"
				render={(props) => (
					<ConfiguracoesGeraisProvider {...props}>
						<ConfiguracoesGerais {...props} />
					</ConfiguracoesGeraisProvider>
				)}
				validarPermissoes={false}
			/>
			<PrivateRoute
				exact
				path="/notas_de_versao"
				validarPermissoes={false}
				render={(props) => <NotasDeVersao {...props} />}
			/>
			<PrivateRoute exact path="/minhaconta" validarPermissoes={false} render={(props) => <MinhaConta {...props} />} />
			<PrivateRoute
				exact
				path="/planos"
				render={(props) => (
					<PlanosProvider>
						<Planos {...props} />
					</PlanosProvider>
				)}
				recurso={recursos.PLANOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/painel_helpdesk"
				validarPermissoes={false}
				render={(props) => (
					<If test={['ADMIN', 'HELPDESK'].includes(localStorage.autoridade)}>
						<PainelHelpDesk {...props} />
					</If>
				)}
			/>
		</Switch>
	);
}

export default withRouter(ConfiguracoesRoutes);
