import { get, services } from 'Common';

export async function asyncBaixarRelatorioComissaoSintetico(
	dataInicial,
	dataFinal,
	vendedores,
	origemCobranca,
	documentoOrigem,
	onSuccess,
	onError
) {
	return await get(
		`${services.GESTOR}/v1/pedidos_venda/imprimir/comissao_sintetico?dataInicial=${dataInicial}&dataFinal=${dataFinal}&vendedores=${vendedores}&origem=${origemCobranca}&documento=${documentoOrigem}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function asyncBaixarRelatorioComissaoAnalitico(
	dataInicial,
	dataFinal,
	vendedores,
	origemCobranca,
	documentoOrigem,
	onSuccess,
	onError
) {
	return await get(
		`${services.GESTOR}/v1/pedidos_venda/imprimir/comissao_analitico?dataInicial=${dataInicial}&dataFinal=${dataFinal}&vendedores=${vendedores}&origem=${origemCobranca}&documento=${documentoOrigem}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}
