import { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { asyncGetComprasPorDia } from './Requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../Common/Mascara';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { parseISO, format } from 'date-fns';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import TituloCard from '../../../../../../../components/TituloCard';
import { Vendas } from '../../../../../../Util/constantes';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';
import { useEffectOnce, useUpdateEffect } from 'react-use';

const dashboardContent = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
};

let graficoVendas;

export default function GraficoComprasPorDia(props) {
	const { dataInicial, dataFinal, atualizarGraficoNovamente, isMobile, dataAtualizacaoMenuLateral } = props;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [dia, setDia] = useState([]);

	const [pendente, setPendente] = useState([]);
	const [finalizado, setFinalizado] = useState([]);
	const [cancelada, setCancelada] = useState([]);

	const graficoVendasPorDia = useRef();
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	const totaisPendentes = pendente.reduce((a, b) => a + b, 0);
	const totaisFinalizadas = finalizado.reduce((a, b) => a + b, 0);
	const totaisCanceladas = cancelada.reduce((a, b) => a + b, 0);

	buildChart();

	const cardStyle = {
		borderRadius: '5px',
		backgroundColor: 'white',
		boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
		minHeight: '100%',
		padding: isMobile ? '7px 0 0 0' : '7px',
	};

	useEffectOnce(() => {
		carregarInformacoes();

		return () => {
			if (typeof graficoVendas !== 'undefined') graficoVendas.destroy();
		};
	});

	useEffect(() => {
		if (typeof graficoVendas !== 'undefined') graficoVendas.destroy();
		carregarInformacoes();
	}, [atualizarGraficoNovamente]);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}

			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;
	}, [dataInicial, dataFinal]);

	useEffect(() => {
		asyncGetComprasPorDia(
			dataInicial,
			dataFinal,
			({ data }) => {
				let diaResponse = [];
				let pendenteResponse = [];
				let finalizadoResponse = [];
				let canceladaResponse = [];

				data.forEach(elemento => {
					diaResponse.push(format(parseISO(elemento.data), 'dd/MM/yyyy'));

					pendenteResponse.push(elemento.valorPendente);
					finalizadoResponse.push(elemento.valorFinalizado);
					canceladaResponse.push(elemento.valorCancelado);
				});

				setDia(diaResponse);
				setPendente(pendenteResponse);
				setFinalizado(finalizadoResponse);
				setCancelada(canceladaResponse);
				setLoading(false);
				setError(false);
			},
			() => {
				setLoading(false);
				setError(true);
			}
		);
	}, [loading, dataAtualizacaoMenuLateral]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}
	function buildChart() {
		if (!graficoVendasPorDia.current) {
			return;
		}
		const myChartRef = graficoVendasPorDia.current.getContext('2d');

		if (typeof graficoVendas !== 'undefined') graficoVendas.destroy();

		graficoVendas = new Chart(myChartRef, {
			type: 'line',
			data: {
				labels: dia,
				datasets: [
					{
						label: 'Pendentes',
						data: pendente,
						borderColor: Vendas.cores.azul,
						backgroundColor: Vendas.cores.azul,
					},
					{
						label: 'Finalizados',
						data: finalizado,
						borderColor: Vendas.cores.verde,
						backgroundColor: Vendas.cores.verde,
					},
					{
						label: 'Cancelados',
						data: cancelada,
						borderColor: Vendas.cores.vermelho,
						backgroundColor: Vendas.cores.vermelho,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				aspectRatio: 1,
				animation: {
					animateScale: true,
					animateRotate: true,
				},
				elements: {
					line: {
						tension: 0.5,
					},
				},
				plugins: {
					datalabels: {
						color: Vendas.cores.preto,
					},
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							usePointStyle: true,
						},
						onHover: function (e) {
							e.native.target.style.cursor = 'pointer';
						},
						onLeave: event => {
							event.native.target.style.cursor = 'default';
						},
					},
					tooltip: {
						callbacks: {
							title: tooltipItem => {
								return `Competência: ${tooltipItem[0].label}`;
							},
							label: tooltipItem => {
								const label = tooltipItem.dataset.label;
								const valor = formatarMonetario(tooltipItem.raw);
								return `${label}: ${valor}`;
							},
						},
					},
				},
				scales: {
					y: {
						beginAtZero: true,
						ticks: {
							callback: value => formatarMonetario(value),
						},
					},
				},
			},
		});
	}

	if (loading || error || (totaisPendentes === 0 && totaisFinalizadas === 0 && totaisCanceladas === 0)) {
		return (
			<div style={cardStyle}>
				<TituloCard
					title={'Compras no período selecionado'}
					helpMessage="Apresenta o valor total das compras agrupadas por dia no período selecionado"
					style={{ padding: isMobile ? '7px 0 0 7px' : '7px' }}
				/>
				<div style={dashboardContent}>
					{loading && (
						<ProgressBar
							mode="indeterminate"
							style={{ height: '3px', width: '100%', margin: '10px 0px' }}
						/>
					)}
					{error && <FalhaAoCarregarAsInformacoes />}
					{totaisPendentes === 0 && totaisFinalizadas === 0 && totaisCanceladas === 0 && (
						<NenhumaInformacaoEncontrada />
					)}
				</div>
			</div>
		);
	} else {
		return (
			<div style={cardStyle}>
				<TituloCard
					title={'Compras no período selecionado'}
					helpMessage="Lista o valor total das compras agrupadas por mês nos últimos doze meses"
					style={{ padding: isMobile ? '7px 0 0 7px' : '7px' }}
				/>
				<div style={{ marginTop: '20px', padding: isMobile ? '0px' : '7px 0 0 7px' }}>
					<canvas id="graficoVendasPorDia" ref={graficoVendasPorDia} style={{ maxWidth: '100%' }} />
				</div>
			</div>
		);
	}
}
