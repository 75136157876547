export const helpDocsRef = {
	modelo: 'Modelo do documento referenciado',
	dataEmissao: 'Data de emissão do documento referenciado',
	chaveNFe: 'Chave de acesso do documento referenciado',
	numeroCoo: 'COO do cupom fiscal',
	caixa: 'Caixa em que o cupom fiscal foi emitido',
	numeroEcf: 'Nº de série do ECF em que o cupom fiscal foi emitido',
	serie: 'Série da nota fiscal',
	numeroNF: 'Número da nota fiscal',
	cnpjCpf: 'CNPJ/CPF do emitente da nota fiscal',
	cnpj: 'CNPJ do emitente da nota fiscal',
	uf: 'UF do emitente da nota fiscal',
	inscricaoEstadual: 'IE do emitente da nota fiscal',
};
