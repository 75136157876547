import { permissoes, recursos } from 'Common';
import PrivateRoute from 'components/Route';
import { Switch, withRouter } from 'react-router-dom';

import ManifestacaoDestinatario from 'views/compras/MDe';
import NotasEntrada from 'views/compras/NotasEntrada';
import NotasEntradaForm from 'views/compras/NotasEntrada/Form';

export const pathComprasRoutes = [
	'/manifestacao_destinatario',
	'/nota_entrada',
	'/nota_entrada/cadastro',
	'/nota_entrada/cadastro/:id',
	'/nota_entrada/importar_xml',
	'/nota_entrada/importar_xml_mde/:id',
];

function ComprasRoutes() {
	return (
		<Switch>
			<PrivateRoute
				exact
				path="/manifestacao_destinatario"
				render={props => <ManifestacaoDestinatario {...props} />}
				recurso={recursos.COMPRAS_MANIFESTACAO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/nota_entrada"
				render={props => <NotasEntrada {...props} />}
				recurso={recursos.COMPRAS_NOTA_ENTRADA}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={[
					'/nota_entrada/cadastro',
					'/nota_entrada/cadastro/:id',
					'/nota_entrada/importar_xml',
					'/nota_entrada/importar_xml_mde/:id',
				]}
				render={props => <NotasEntradaForm {...props} />}
				recurso={recursos.COMPRAS_NOTA_ENTRADA}
				permissao={permissoes.VISUALIZAR}
			/>
		</Switch>
	);
}

export default withRouter(ComprasRoutes);
