import React, { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { buscarProdutos } from "./Requests";
import { ProgressBar } from "primereact/progressbar";
import NenhumaInformacaoEncontrada from "../../../NenhumaInformacaoEncontrada";
import FalhaAoCarregarAsInformacoes from "../../../FalhaAoCarregarAsInformacoes";

import If from "../../../../../../../components/If";
import { Link } from "react-router-dom";
import { formatarDecimais } from "../../../../../../../Common/Mascara";
import { usuarioPossuiPermissao } from "../../../../../../../Common/Autenticacao";
import {
  permissoes,
  recursos,
} from "../../../../../../../Common/Constantes/autorizacao";
import { Estoque } from "../../../../../../Util/constantes";
import usePrevious from "../../../../../../../Common/Hooks/usePrevious";
import "./Styles/index.css";

const Divider = () => {
  return <hr id="divider-estoque-minimo" />;
};

const List = (props) => {
  return (
    <div id="estoque-minimo-content" className="dashboard-scroll">
      <ul
        style={{
          listStyle: "none",
          padding: "0px 3px",
          margin: "0px 0px 1rem 0px",
        }}
      >
        {props.children}
      </ul>
    </div>
  );
};

const ListItem = (props) => {
  return (
    <li style={{ margin: "6px 0px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div id="estoque-minimo-coluna-produto">
          <div id="produto-nome">
            <If test={props.podeVisualizarProdutos}>
              <span className="link_to" title="Ir para cadastro do produto">
                <Link to={{ pathname: `/produtos/cadastro/${props.id}` }}>
                  {props.codigo} - {props.nome}{" "}
                  {props.unidadeMedida && `${props.unidadeMedidaUnidade}`}
                </Link>
              </span>
            </If>
            <If test={!props.podeVisualizarProdutos}>
              <span>
                {props.codigo} - {props.nome} (
                {props.unidadeMedida && props.unidadeMedidaUnidade})
              </span>
            </If>
          </div>
          <div id="produto-estoque">
            Estoque mínimo: {formatarDecimais(props.estoqueMinimo)}
          </div>
        </div>

        <div id="estoque-minimo-coluna-estoque-atual">
          <div
            title={`${formatarDecimais(props.estoqueAtual)} ${
              props.unidadeMedida
                ? props.unidadeMedidaDescricao
                : "(Unidade não informada)"
            }`}
          >
            <span>{formatarDecimais(props.estoqueAtual)} </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default function ProdutosAbaixoEstoqueMinimo(props) {
  const { atualizarGraficoNovamente, dataAtualizacaoMenuLateral } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [podeVisualizarProdutos, setPodeVisualizarProdutos] = useState(
    usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR)
  );
  const afterFirstRenderRef = useRef(false);
  const prevProps = usePrevious(props);

  useEffect(() => {
    carregarInformacoes();

    if (afterFirstRenderRef) {
      if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
        carregarInformacoes(false);
      }
    } else afterFirstRenderRef = true;
  }, []);

  useEffect(() => {
    carregarInformacoes();
  }, [atualizarGraficoNovamente]);

  useEffect(() => {
    buscarProdutos(
      page,
      ({ data }) => {
        setTotalPages(data.totalPages);
        setProdutos(data.content);
        setLoading(false);
        setError(false);
      },
      () => {
        setLoading(false);
        setError(true);
      }
    );
  }, [loading, dataAtualizacaoMenuLateral]);

  function carregarInformacoes(exibirLoading = true) {
    setLoading(exibirLoading);
    setError(false);
  }

  function getHeaderCard() {
    return (
      <div id="card-header-estoque-minimo">
        <span>
          <label title="Lista os produtos com estoque abaixo do mínimo">
            Produtos abaixo do estoque mínimo
          </label>
        </span>
      </div>
    );
  }

  function onVerMaisClick() {
    const nextPage = page + 1;

    buscarProdutos(
      nextPage,
      ({ data }) => {
        setTotalPages(data.totalPages);
        setProdutos([...produtos, ...data.content]);
        setPage(data.number);
        setLoading(false);
        setError(false);
      },
      () => {
        setLoading(false);
        setError(true);
      }
    );
  }

  if (loading) {
    return (
      <Card header={getHeaderCard()} style={{ borderRadius: "5px" }}>
        <ProgressBar mode="indeterminate" style={{ height: "3px" }} />
      </Card>
    );
  } else if (error) {
    return (
      <Card
        header={getHeaderCard()}
        style={{ borderRadius: "5px", height: "100%" }}
      >
        <FalhaAoCarregarAsInformacoes />
      </Card>
    );
  } else {
    return (
      <>
        <Card
          header={getHeaderCard()}
          style={{ borderRadius: "5px", height: "100%" }}
        >
          {produtos.length > 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                }}
              >
                <span>Produto</span>
                <span>Estoque atual</span>
              </div>
              <List>
                {produtos.map((produto, idx, array) => {
                  return (
                    <React.Fragment key={produto.id}>
                      <ListItem
                        id={produto.id}
                        nome={produto.nome}
                        codigo={produto.codigo}
                        podeVisualizarProdutos={podeVisualizarProdutos}
                        estoqueMinimo={produto.estoqueMinimo}
                        estoqueAtual={produto.estoqueSaldo}
                        unidadeMedida={produto.unidadeMedida}
                        unidadeMedidaUnidade={
                          produto.unidadeMedida && produto.unidadeMedida.unidade
                        }
                        unidadeMedidaDescricao={
                          produto.unidadeMedida &&
                          produto.unidadeMedida.descricao
                        }
                        carregarInformacoes={carregarInformacoes}
                      />
                      {++idx !== array.length ? <Divider /> : null}
                    </React.Fragment>
                  );
                })}
                <If test={page !== totalPages - 1}>
                  <div style={{ textAlign: "center" }}>
                    <span
                      onClick={onVerMaisClick}
                      style={{
                        color: `${Estoque.cores.azul}`,
                        cursor: "pointer",
                      }}
                    >
                      Ver mais
                    </span>
                  </div>
                </If>
              </List>
            </>
          ) : (
            <NenhumaInformacaoEncontrada />
          )}
        </Card>
      </>
    );
  }
}
