import { getSafeValue } from 'Common/Funcoes';
import { modalidadeICMSST } from 'views/fiscal/Util/constantes';
import { buscarTributacaoProduto } from './Requests';
import { DOCUMENTOFISCAL } from './Util/constantes';

export function calcularIcms(item, documentoFiscal = DOCUMENTOFISCAL.NFE) {
	item.tributos.icms.baseIcms = parseFloat(
		((getSafeValue(item.baseCalculo) * getSafeValue(item.tributos.icms.percentualBaseIcms)) / 100).toFixed(2)
	);
	item.tributos.icms.valorIcms = parseFloat(
		((getSafeValue(item.tributos.icms.baseIcms) * getSafeValue(item.tributos.icms.percentualIcms)) / 100).toFixed(2)
	);

	if (getSafeValue(item.tributos.icms.percentualCreditoSimplesNacional) > 0) {
		item.tributos.icms.valorCreditoSimplesNacional = parseFloat(
			(
				(getSafeValue(item.baseCalculo) * getSafeValue(item.tributos.icms.percentualCreditoSimplesNacional)) /
				100
			).toFixed(2)
		);
	}
	if (
		getSafeValue(item.tributos.icms.percentualIcms) === 0 &&
		getSafeValue(item.tributos.icms.percentualDiferimento) === 0
	) {
		item.tributos.icms.baseIcms = 0;
	}

	if (documentoFiscal === DOCUMENTOFISCAL.NFE) {
		if (getSafeValue(item.tributos.icms.percentualDiferimento) > 0) {
			item.tributos.icms.valorDiferimento = parseFloat(
				(
					(getSafeValue(item.tributos.icms.valorIcms) * getSafeValue(item.tributos.icms.percentualDiferimento)) /
					100
				).toFixed(2)
			);
			item.tributos.icms.valorIcms =
				getSafeValue(item.tributos.icms.valorIcms) >= getSafeValue(item.tributos.icms.valorDiferimento)
					? parseFloat(
							(getSafeValue(item.tributos.icms.valorIcms) - getSafeValue(item.tributos.icms.valorDiferimento)).toFixed(
								2
							)
						)
					: 0;
		}

		if (getSafeValue(item.tributos.icms.percentualFundoCombatePobreza) > 0) {
			item.tributos.icms.baseFundoCombatePobreza = parseFloat(getSafeValue(item.baseCalculo).toFixed(2));
			item.tributos.icms.valorFundoCombatePobreza = parseFloat(
				(
					(getSafeValue(item.tributos.icms.baseFundoCombatePobreza) *
						getSafeValue(item.tributos.icms.percentualFundoCombatePobreza)) /
					100
				).toFixed(2)
			);
		} else {
			item.tributos.icms.valorFundoCombatePobreza = 0;
		}

		if (item.tributos.icms.modalidadeCalculoSt === modalidadeICMSST.MARGEM_VALOR_AGREGADO) {
			item.tributos.icms.baseIcmsSt = parseFloat(
				(
					(getSafeValue(item.baseCalculo) * getSafeValue(item.tributos.icms.percentualBaseIcmsSt)) / 100 +
					(getSafeValue(item.baseCalculo) * getSafeValue(item.tributos.icms.percentualMva)) / 100
				).toFixed(2)
			);
		} else {
			item.tributos.icms.baseIcmsSt = parseFloat(
				(getSafeValue(item.quantidade) * getSafeValue(item.tributos.icms.valorPauta)).toFixed(2)
			);
		}
		item.tributos.icms.valorIcmsSt = parseFloat(
			(
				(getSafeValue(item.tributos.icms.baseIcmsSt) * getSafeValue(item.tributos.icms.percentualIcmsSt)) / 100 -
				getSafeValue(item.tributos.icms.valorIcms)
			).toFixed(2)
		);
		if (getSafeValue(item.tributos.icms.percentualIcmsSt) === 0) {
			item.tributos.icms.baseIcmsSt = 0;
			item.tributos.icms.valorIcmsSt = 0;
		}
		item.tributos.icms.baseRetidoSt = parseFloat(
			((getSafeValue(item.baseCalculo) * getSafeValue(item.tributos.icms.percentualBaseIcmsStRetido)) / 100).toFixed(2)
		);
		item.tributos.icms.valorRetidoSt = parseFloat(
			(
				(getSafeValue(item.tributos.icms.baseRetidoSt) * getSafeValue(item.tributos.icms.percentualIcmsStRetido)) /
				100
			).toFixed(2)
		);
		if (getSafeValue(item.tributos.icms.percentualIcmsStRetido) === 0) {
			item.tributos.icms.baseRetidoSt = 0;
		}
	}
	if (getSafeValue(item.tributos.icms.percentualIcmsMonofasicoRetido) > 0) {
		item.tributos.icms.quantidadeBaseIcmsMonofasicoRetido = getSafeValue(item.quantidade);
		item.tributos.icms.valorIcmsMonofasicoRetido = parseFloat(
			(getSafeValue(item.quantidade) * getSafeValue(item.tributos.icms.percentualIcmsMonofasicoRetido)).toFixed(2)
		);
	}
}

export function calcularIpi(item, documentoFiscal = DOCUMENTOFISCAL.NFE) {
	if (documentoFiscal === DOCUMENTOFISCAL.NFE) {
		if (item.tributos?.ipi) {
			item.tributos.ipi.baseIpi = parseFloat(
				((getSafeValue(item.baseCalculo) * getSafeValue(item.tributos.ipi.percentualBaseIpi)) / 100).toFixed(2)
			);

			item.tributos.ipi.valorIpi = parseFloat(
				((getSafeValue(item.tributos.ipi.baseIpi) * getSafeValue(item.tributos.ipi.percentualIpi)) / 100).toFixed(2)
			);

			if (getSafeValue(item.tributos.ipi.valorIpi) === 0) {
				item.tributos.ipi.baseIpi = 0;
			}
		}
	}
}

export function calcularPis(item, documentoFiscal = DOCUMENTOFISCAL.NFE) {
	item.tributos.pis.basePis = parseFloat(
		((getSafeValue(item.baseCalculo) * getSafeValue(item.tributos.pis.percentualBasePis)) / 100).toFixed(2)
	);
	item.tributos.pis.valorPis = parseFloat(
		((getSafeValue(item.tributos.pis.basePis) * getSafeValue(item.tributos.pis.percentualPis)) / 100).toFixed(2)
	);

	if (getSafeValue(item.tributos.pis.valorPis) === 0) {
		item.tributos.pis.basePis = 0;
	}

	if (documentoFiscal === DOCUMENTOFISCAL.NFE) {
		item.tributos.pis.basePisSt = parseFloat(
			((getSafeValue(item.baseCalculo) * getSafeValue(item.tributos.pis.percentualBasePisSt)) / 100).toFixed(2)
		);

		item.tributos.pis.valorPisSt = parseFloat(
			((getSafeValue(item.tributos.pis.basePisSt) * getSafeValue(item.tributos.pis.percentualPisSt)) / 100).toFixed(2)
		);

		if (getSafeValue(item.tributos.pis.valorPisSt) === 0) {
			item.tributos.pis.basePisSt = 0;
		}
	}
}

export function calcularCofins(item, documentoFiscal = DOCUMENTOFISCAL.NFE) {
	item.tributos.cofins.baseCofins = parseFloat(
		((getSafeValue(item.baseCalculo) * getSafeValue(item.tributos.cofins.percentualBaseCofins)) / 100).toFixed(2)
	);
	item.tributos.cofins.valorCofins = parseFloat(
		(
			(getSafeValue(item.tributos.cofins.baseCofins) * getSafeValue(item.tributos.cofins.percentualCofins)) /
			100
		).toFixed(2)
	);

	if (getSafeValue(item.tributos.cofins.valorCofins) === 0) {
		item.tributos.cofins.baseCofins = 0;
	}

	if (documentoFiscal === DOCUMENTOFISCAL.NFE) {
		item.tributos.cofins.baseCofinsSt = parseFloat(
			((getSafeValue(item.baseCalculo) * getSafeValue(item.tributos.cofins.percentualBaseCofinsSt)) / 100).toFixed(2)
		);

		item.tributos.cofins.valorCofinsSt = parseFloat(
			(
				(getSafeValue(item.tributos.cofins.baseCofinsSt) * getSafeValue(item.tributos.cofins.percentualCofinsSt)) /
				100
			).toFixed(2)
		);

		if (getSafeValue(item.tributos.cofins.valorCofinsSt) === 0) {
			item.tributos.cofins.baseCofinsSt = 0;
		}
	}
}

export function calcularBaseCalculo(item) {
	item.baseCalculo = parseFloat(
		(
			item?.quantidade * item?.valor +
			(item?.seguro ? item?.seguro : 0) +
			(item?.frete ? item?.frete : 0) +
			item?.acessorias -
			item?.desconto
		).toFixed(2)
	);
}

export function calcularImpostosProduto(produto, documentoFiscal = DOCUMENTOFISCAL.NFE) {
	calcularBaseCalculo(produto);
	calcularIcms(produto, documentoFiscal);
	calcularIpi(produto, documentoFiscal);
	calcularPis(produto, documentoFiscal);
	calcularCofins(produto, documentoFiscal);
	return produto.tributos;
}

export async function montarCalcularImpostosProduto(ufDestinatario, produto, documentoFiscal = DOCUMENTOFISCAL.NFE) {
	let item = produto;

	function montarProduto(tributacoes) {
		item.cfop = {
			value: tributacoes.produto.cfop.id,
			label: tributacoes.produto.cfop.codigo,
			registro: tributacoes.produto.cfop,
		};
		item.codigoBeneficiario = tributacoes.produto.codigoBeneficiario;
	}

	function montarIcms(tributacoes) {
		item.tributos.icms.origemProduto = tributacoes.icms.origem;
		item.tributos.icms.cstCsosn = {
			value: tributacoes.icms.cstCsosn.id,
			label: tributacoes.icms.cstCsosn.codigo + ' - ' + tributacoes.icms.cstCsosn.descricao,
			registro: tributacoes.icms.cstCsosn,
		};

		item.tributos.icms.modalidadeCalculo = tributacoes.icms.modalidadeCalculo;
		item.tributos.icms.percentualBaseIcms = tributacoes.icms.percentualBaseCalculo;
		item.tributos.icms.percentualIcms = tributacoes.icms.aliquota;
		item.tributos.icms.percentualCreditoSimplesNacional = tributacoes.icms.aliquotaCreditoSimplesNacional;

		if (documentoFiscal === DOCUMENTOFISCAL.NFE) {
			item.tributos.icms.percentualReducaoBaseIcms = tributacoes.icms.percentualReducaoBaseCalculo;
			item.tributos.icms.percentualDiferimento = tributacoes.icms.percentualDiferimento;
			item.tributos.icms.percentualFundoCombatePobreza = tributacoes.icms.percentualFcPobreza;
			item.tributos.icms.modalidadeCalculoSt = tributacoes.icms.modalidadeCalculoSt;
			item.tributos.icms.percentualReducaoBaseCalculoSt = tributacoes.icms.percentualReducaoBaseCalculoSt;
			item.tributos.icms.percentualBaseIcmsSt = tributacoes.icms.percentualBaseCalculoSt;
			item.tributos.icms.percentualIcmsSt = tributacoes.icms.aliquotaIcmsSt;
			item.tributos.icms.percentualMva = tributacoes.icms.percentualMva;
			item.tributos.icms.valorPauta = tributacoes.icms.valorPauta;
			item.tributos.icms.percentualBaseIcmsStRetido = tributacoes.icms.percentualBaseCalculoIcmsStRetido;
			item.tributos.icms.percentualIcmsStRetido = tributacoes.icms.aliquotaIcmsStRetido;
		}
		item.tributos.icms.percentualIcmsMonofasicoRetido = tributacoes.icms.aliquotaIcmsMonofasicoRetido;
	}

	function montarIpi(tributacoes) {
		if (documentoFiscal === DOCUMENTOFISCAL.NFE) {
			item.tributos.ipi.enquadramentoIpi = {
				value: tributacoes.ipi.enquadramento.id,
				label: tributacoes.ipi.enquadramento.codigo + ' - ' + tributacoes.ipi.enquadramento.descricao,
				registro: tributacoes.ipi.enquadramento,
			};
			item.tributos.ipi.cst = {
				value: tributacoes.ipi.cst.id,
				label: tributacoes.ipi.cst.codigo + ' - ' + tributacoes.ipi.cst.descricao,
				registro: tributacoes.ipi.cst,
			};
			item.tributos.ipi.percentualBaseIpi = tributacoes.ipi.percentualBaseCalculo;
			item.tributos.ipi.percentualIpi = tributacoes.ipi.aliquota;
		}
	}

	function montarPis(tributacoes) {
		item.tributos.pis.cst = {
			value: tributacoes.pis.cst.id,
			label: tributacoes.pis.cst.codigo + ' - ' + tributacoes.pis.cst.descricao,
			registro: tributacoes.pis.cst,
		};
		item.tributos.pis.percentualBasePis = tributacoes.pis.percentualBaseCalculo;
		item.tributos.pis.percentualPis = tributacoes.pis.aliquota;
		if (documentoFiscal === DOCUMENTOFISCAL.NFE) {
			item.tributos.pis.percentualBasePisSt = tributacoes.pis.percentualBaseCalculoSt;
			item.tributos.pis.percentualPisSt = tributacoes.pis.aliquotaSt;
		}
	}

	function montarCofins(tributacoes) {
		item.tributos.cofins.cst = {
			value: tributacoes.cofins.cst.id,
			label: tributacoes.cofins.cst.codigo + ' - ' + tributacoes.cofins.cst.descricao,
			registro: tributacoes.cofins.cst,
		};
		item.tributos.cofins.percentualBaseCofins = tributacoes.cofins.percentualBaseCalculo;
		item.tributos.cofins.percentualCofins = tributacoes.cofins.aliquota;
		if (documentoFiscal === DOCUMENTOFISCAL.NFE) {
			item.tributos.cofins.percentualBaseCofinsSt = tributacoes.cofins.percentualBaseCalculoSt;
			item.tributos.cofins.percentualCofinsSt = tributacoes.cofins.aliquotaSt;
		}
	}

	function montarImpostos(tributacoes) {
		montarProduto(tributacoes);
		montarIcms(tributacoes);
		montarIpi(tributacoes);
		montarPis(tributacoes);
		montarCofins(tributacoes);
	}

	async function processarProduto() {
		await buscarTributacaoProduto(
			item.produto?.registro.id,
			ufDestinatario,
			item.operacaoFiscal?.registro.id,
			({ data: tributacoes }) => {
				montarImpostos(tributacoes);
			}
		);
		item.tributos = calcularImpostosProduto(item, documentoFiscal);
		return item;
	}

	return await processarProduto();
}
