import { formatarTelefone } from 'Common';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useEffect, useState } from 'react';
import { getEnderecoFavoritoPessoa } from '../../Requests';

function OverlayPanelInformacoesComplementares({
	pessoa,
	panelInformacoesComplementares,
	isMobile,
	setCurrentPessoaIndex,
}) {
	const [endereco, setEndereço] = useState(null);

	useEffect(() => {
		if (pessoa && pessoa.id) {
			getEnderecoFavoritoPessoa(pessoa.id, ({ data }) => {
				if (data) {
					setEndereço(montarEndereco(data));
				} else {
					setEndereço(null);
				}
			});
		}
	}, [pessoa]);

	function montarEndereco(data) {
		let endereco = '';

		if (data) {
			if (data.logradouro) {
				endereco += `${data.logradouro}`;
			}
			if (data.numero) {
				endereco += `, ${data.numero}`;
			}
			if (data.bairro) {
				endereco += `, ${data.bairro}`;
			}
			if (data.complemento) {
				endereco += `. ${data.complemento}`;
			}
			if (data.municipio) {
				endereco += ` - ${data.municipio}`;
			}
			if (data.cep) {
				endereco += `, ${data.cep}`;
			}
		}

		return endereco;
	}

	return (
		<OverlayPanel
			ref={panelInformacoesComplementares}
			id="overlayPanel-informacoes-complementares"
			style={{
				width: isMobile ? '90%' : '100%',
				maxWidth: '320px',
				marginLeft: isMobile ? '1rem' : null,
				marginTop: '0px',
			}}
			onHide={() => {
				setCurrentPessoaIndex(null);
				setEndereço(null);
			}}
			showCloseIcon
		>
			<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<div style={{ fontWeight: 'bold' }}>Telefone:</div>
				<div style={{ marginBottom: '12px' }}>
					{pessoa && pessoa?.telefone ? formatarTelefone(pessoa?.telefone) : '-'}
				</div>

				<div style={{ fontWeight: 'bold' }}>E-mail:</div>
				<div style={{ marginBottom: '12px' }}>{pessoa && pessoa?.email ? pessoa?.email : '-'}</div>

				<div style={{ fontWeight: 'bold' }}>Endereço:</div>
				<div style={{ textAlign: 'center' }}>{endereco || '-'}</div>
			</div>
		</OverlayPanel>
	);
}

export { OverlayPanelInformacoesComplementares };
