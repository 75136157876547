export const helpDocumentoConfirmacaoForm = {
	pergunta: 'Informe uma pergunta que será solicitada ao signatário para confirmação da assinatura do documento.',
	respostaTipo: 'Tipo de resposta para a pergunta informada.',
	respostaEsperada:
		'Valor esperado da resposta por parte do signatário. Caso o signatário não informe a resposta corretamente ele não poderá proceder com a assinatura do documento.',
};

export const INITIAL_VALUES = {
	confirmacaoPadrao: [],
};

export const INITIAL_VALUES_EDIT = {
	id: null,
	tempId: null,
	pergunta: '',
	respostaTipo: 'TEXTO',
	respostaEsperada: '',
};
