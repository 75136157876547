import { useState } from 'react';
import { If, Modal } from 'components';
import { TributacaoMunicipalForm } from '../Form';

export default function ModalTributacaoMunicipal({ visible, onHide, history }) {
	const [hideBackgroundTributacaoMunicipal, setHideBackgroundTributacaoMunicipal] = useState(false);

	return (
		<Modal
			className={visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de tributação municipal"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1200px' }}
			hideBackground={hideBackgroundTributacaoMunicipal}
		>
			<If test={visible}>
				<TributacaoMunicipalForm
					hideModal={onHide}
					isModal={true}
					history={history}
					setHideBackground={setHideBackgroundTributacaoMunicipal}
				/>
			</If>
		</Modal>
	);
}
