import propTypes from 'prop-types';
import Button from '..';

function ButtonEditarTable(props) {
	const { id, disabled, onClick, hidden, title, icon, raised, rounded } = props;

	const style = {
		borderRadius: '50%',
		padding: '5px',
		width: '30px',
		height: '30px',
		marginLeft: '2.5px',
		marginRight: '2.5px',
	};

	return (
		<Button
			id={id}
			type="button"
			color="primary"
			disabled={disabled}
			onClick={onClick}
			hidden={hidden}
			raised={raised}
			rounded={rounded}
			icon={icon}
			enableShortCut={false}
			title={title}
			style={style}
		/>
	);
}

ButtonEditarTable.defaultProps = {
	hidden: false,
	disabled: false,
	title: 'Editar o registro',
	icon: 'fa fa-pencil',
};

ButtonEditarTable.propTypes = {
	/** Identificador do registro */
	id: propTypes.string,
	/** Derfine o botão como invisível */
	hidden: propTypes.bool,
	/** Ação executada ao clicar no botão */
	onClick: propTypes.func,
	/** Define o botão como desabilitado */
	disabled: propTypes.bool,
	/** Icone do componente */
	icon: propTypes.string,
	/** Title do componente */
	title: propTypes.string,
};

export default ButtonEditarTable;
