import { services } from '../../../../../../../Common/Constantes/api';
import { del, exibirToast, get, post } from '../../../../../../../Common/Requisicoes';

export async function asyncGetmodelosNcm(urlParametros, onSuccess, onError) {
  await get(`${services.GESTOR}/v1/tributacoes/ncm/modelo_tributacao${urlParametros}`, null, onSuccess, onError);
}

export async function asyncPostModeloNcm(values, onSuccess, onError) {
  await post(
    `${services.GESTOR}/v1/tributacoes/ncm/modelo_tributacao`,
    values,
    null,
    exibirToast(onSuccess, 'Modelo salvo com sucesso!'),
    onError
  );
}

export async function asyncDeleteModeloNcm(idModelo, onSuccess, onError) {
  await del(
    `${services.GESTOR}/v1/tributacoes/ncm/modelo_tributacao/${idModelo}`,
    null,
    exibirToast(onSuccess, 'Modelo excluído com sucesso!'),
    onError
  );
}
