import { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { useDrop } from 'react-dnd';

import { If } from 'components';

import { DRAG_AND_DROP_TYPES } from 'views/cadastros/Etiquetas/components/configuracao/Form/Util/constantes';
import {
	converterMilimetrosEmPixel,
	converterPixelEmMilimetros,
} from 'views/cadastros/Etiquetas/components/configuracao/Form/Util/functions';
import { useContextEtiquetaConfiguracao } from 'views/cadastros/Etiquetas/components/configuracao/Form/Context';
import { CampoEtiqueta } from '../CampoEtiqueta';

function ContainerEtiqueta({ escalaVisualizacao }) {
	const { values, setFieldValue } = useFormikContext();
	const { alturaMaximaCardsContent, desselecionarCampoPreview, setDesselecionarCampoPreview } =
		useContextEtiquetaConfiguracao();

	const [indexCampoSelecionado, setIndexCampoSelecionado] = useState(null);

	const valuesRef = useRef(null);
	const indexCampoSelecionadoRef = useRef(null);

	const medidasEtiqueta = {
		altura: converterMilimetrosEmPixel(values.alturaEtiqueta, escalaVisualizacao),
		largura: converterMilimetrosEmPixel(values.larguraEtiqueta, escalaVisualizacao),
	};

	const [, dropRef] = useDrop(
		() => ({
			accept: DRAG_AND_DROP_TYPES.FIELDS_ETIQUETA,
			drop(item, monitor) {
				const delta = monitor.getDifferenceFromInitialOffset();
				const deltaX = Math.round(converterPixelEmMilimetros(delta.x, escalaVisualizacao));
				const deltaY = Math.round(converterPixelEmMilimetros(delta.y, escalaVisualizacao));

				if (deltaX !== 0) {
					const posicaoHorizontal = item.posicaoHorizontal + deltaX;
					alterarPosicaoHorizontalCampoSelecionado(posicaoHorizontal, item.indexCampo);
				}

				if (deltaY !== 0) {
					const posicaoVertical = item.posicaoVertical + deltaY;
					alterarPosicaoVerticalCampoSelecionado(posicaoVertical, item.indexCampo);
				}
			},
		}),
		[values]
	);

	useEffect(() => {
		document.addEventListener('keydown', keyDownWatched);
	}, []);

	useEffect(() => {
		if (desselecionarCampoPreview) {
			setIndexCampoSelecionado(null);
			indexCampoSelecionadoRef.current = null;
			valuesRef.current = null;
			setDesselecionarCampoPreview(false);
		} else if (indexCampoSelecionado !== null && indexCampoSelecionado !== undefined) {
			indexCampoSelecionadoRef.current = indexCampoSelecionado;
			valuesRef.current = values;
		}
	}, [indexCampoSelecionado, desselecionarCampoPreview, values.campos[indexCampoSelecionado]]);

	function keyDownWatched(event) {
		const acceptedKeys = ['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft'];

		if (
			acceptedKeys.includes(event.code) &&
			indexCampoSelecionadoRef.current !== null &&
			indexCampoSelecionadoRef.current !== undefined
		) {
			switch (event.code) {
				case 'ArrowUp': {
					onChangeValoresPosicao(
						`campos[${indexCampoSelecionadoRef.current}].posicaoVertical`,
						valuesRef.current.campos[indexCampoSelecionadoRef.current].posicaoVertical - 1,
						'posicaoVertical'
					);
					break;
				}
				case 'ArrowDown': {
					onChangeValoresPosicao(
						`campos[${indexCampoSelecionadoRef.current}].posicaoVertical`,
						valuesRef.current.campos[indexCampoSelecionadoRef.current].posicaoVertical + 1,
						'posicaoVertical'
					);
					break;
				}
				case 'ArrowLeft': {
					onChangeValoresPosicao(
						`campos[${indexCampoSelecionadoRef.current}].posicaoHorizontal`,
						valuesRef.current.campos[indexCampoSelecionadoRef.current].posicaoHorizontal - 1,
						'posicaoHorizontal'
					);
					break;
				}

				case 'ArrowRight': {
					onChangeValoresPosicao(
						`campos[${indexCampoSelecionadoRef.current}].posicaoHorizontal`,
						valuesRef.current.campos[indexCampoSelecionadoRef.current].posicaoHorizontal + 1,
						'posicaoHorizontal'
					);
					break;
				}
				default:
					null;
			}
		}
	}

	function onChangeValoresPosicao(campo, valor, eixo) {
		const valorMaximo =
			eixo === 'posicaoVertical' ? valuesRef.current.alturaEtiqueta : valuesRef.current.larguraEtiqueta;
		const medidasCampo = {
			altura: valuesRef.current.campos[indexCampoSelecionadoRef.current].altura,
			largura: valuesRef.current.campos[indexCampoSelecionadoRef.current].largura,
		};
		const valorMaisMedidaCampo =
			Number(valor) + Number(eixo === 'posicaoVertical' ? medidasCampo.altura : medidasCampo.largura);

		if (valor < 0) {
			setFieldValue(campo, 0);
		} else if (valorMaisMedidaCampo <= valorMaximo) {
			setFieldValue(campo, Number(valor));
		}
	}

	function alterarPosicaoHorizontalCampoSelecionado(posicao, index) {
		const larguraEtiquetaEmMilimetros = values.larguraEtiqueta * escalaVisualizacao;
		const posicaoMaisMedidaCampo = posicao + values.campos[index].largura;

		if (posicao < 0) {
			setFieldValue(`campos[${index}].posicaoHorizontal`, 0);
		} else if (posicaoMaisMedidaCampo <= larguraEtiquetaEmMilimetros) {
			setFieldValue(`campos[${index}].posicaoHorizontal`, posicao);
		}
	}

	function alterarPosicaoVerticalCampoSelecionado(posicao, index) {
		const alturaEtiquetaEmMilimetros = values.alturaEtiqueta * escalaVisualizacao;
		const posicaoMaisMedidaCampo = posicao + values.campos[index].altura;

		if (posicao < 0) {
			setFieldValue(`campos[${index}].posicaoVertical`, 0);
		} else if (posicaoMaisMedidaCampo <= alturaEtiquetaEmMilimetros) {
			setFieldValue(`campos[${index}].posicaoVertical`, posicao);
		}
	}

	return (
		<div style={{ placeSelf: 'center', margin: '2rem 0rem', height: `${alturaMaximaCardsContent - 94}px` }}>
			<If test={medidasEtiqueta.altura && medidasEtiqueta.largura}>
				<div
					ref={dropRef}
					style={{
						background: '#ffffff',
						height: `${medidasEtiqueta.altura}px`,
						width: `${medidasEtiqueta.largura}px`,
						border: '1px solid #b3b3b3',
						position: 'relative',
					}}
				>
					{values.campos?.map((campos, index) => (
						<CampoEtiqueta
							key={`${campos.campo.value}`}
							campos={campos}
							indexCampo={index}
							escalaVisualizacao={escalaVisualizacao}
							indexCampoSelecionado={indexCampoSelecionado}
							setIndexCampoSelecionado={setIndexCampoSelecionado}
						/>
					))}
				</div>
			</If>
		</div>
	);
}

export { ContainerEtiqueta };
