import { useState } from 'react';
import PessoasForm from '../Form';
import { Modal, If } from '../../../../components';

export function ModalPessoas(props) {
	const {
		onHide,
		visible,
		validarIntermediador,
		idPessoa,
		tabSelecionada,
		tipo,
		tipoPessoaNovoCadastro,
		container,
		styleModal,
	} = props;
	const [hideBackground, setHideBackground] = useState(false);

	return (
		<Modal
			className={visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de pessoa"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1200px', ...styleModal }}
			hideBackground={hideBackground}
			container={container}
		>
			<If test={visible}>
				<PessoasForm
					validarIntermediador={validarIntermediador}
					hideModal={onHide}
					isModal={true}
					idPessoa={idPessoa}
					tabSelecionada={tabSelecionada}
					tipo={tipo}
					setHideBackground={setHideBackground}
					tipoPessoaNovoCadastro={tipoPessoaNovoCadastro}
					appendTo={container}
				/>
			</If>
		</Modal>
	);
}

export default ModalPessoas;
