import { useEffect, useState } from 'react';
import { Field, withFormik, Form } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  InputField,
  Dropdown,
  Checkbox,
  Grid,
  Col,
  SingleSelectCfop,
  InputSelectMultiplicacaoOrDivisao,
  FormActions,
  FormContent,
  ButtonCancelar,
  ButtonSalvar,
  If,
  SingleSelectProduto,
} from '../../../../../../../components';

import { gerarUUID, mensagensDeValidacao, services } from '../../../../../../../Common';

import { asyncCadastrarProdutos } from '../../../../Requests';
import { validarFormulario } from '../../../../../../Util';
import { calcularConversao, calcularCusto, calcularTotal, ordenarItens } from '../../../../Util/functions';

import '../../../Styles/index.css';

const tipos = {
  MULTIPLICACAO: 'MULTIPLICACAO',
  DIVISAO: 'DIVISAO',
};

const initialValues = {
  itens: [],
};

function FormVinculoView(props) {
  const { values, setFieldValue, isMobile, isTablet, onHide, cancelaImportacaoXml, onConfirmarModalVinculo } = props;

  const [isTouched, setIsTouched] = useState(false);

  function alterarFatorOperacao(operacao, value) {
    if (value === tipos.MULTIPLICACAO) {
      setFieldValue(operacao, tipos.DIVISAO);
    } else {
      setFieldValue(operacao, tipos.MULTIPLICACAO);
    }
  }

  function onHideModal() {
    cancelaImportacaoXml();
    onHide();
  }

  async function onSaveModal() {
    setIsTouched(true);

    if (await validarFormulario(props)) {
      setIsTouched(false);
      await cadastrarProdutos(values.itens);
      await onConfirmarModalVinculo(recalculaItens(values.itens));
      onHide();
    }
  }

  function recalculaItens(itens) {
    let newItens = [];
    itens.forEach(item => {
      const quantidadeConvertida = calcularConversao(item.quantidade, item.fatorConversao, item.operacaoFatorConversao);

      const subTotal = calcularTotal(
        item.operacaoFatorConversao,
        item.valorUnitario,
        item.quantidade,
        item.valorIcmsSt,
        item.valorIpi,
        item.valorFrete,
        item.valorSeguro,
        item.valorAcessorias,
        item.valorDesconto
      );

      newItens.push({
        ...item,
        quantidadeConvertida: quantidadeConvertida,
        subTotal: subTotal,
      });
    });
    return newItens;
  }

  function onChangeProduto(event, index) {
    if (event !== null) {
      setFieldValue(`itens.${index}.produto`, {...event?.registro, value: event.value, label: event.label});      
      setFieldValue(`itens.${index}.cadastrar`, false);
      if (event?.registro?.fatorConversao > 1) {
        setFieldValue(`itens.${index}.fatorConversao`, event?.registro?.fatorConversao);
      }
    } else {
      setFieldValue(`itens.${index}.produto`, null);
      setFieldValue(`itens.${index}.cadastrar`, true);
    }
  }

  async function cadastrarProdutos(itens) {
    let produtos = [];
    let itensLocal = itens;

    itens.forEach((item, index) => {
      if (item.cadastrar) {
        const idTemp = gerarUUID();
        itensLocal[index].produto = { id: idTemp };

        const codigoBarras = item.codigoBarras.replace(/[^0-9]/g, '');
        produtos.push({
          id: idTemp,
          situacao: 'ATIVO',
          sku: null,
          nome: item.descricaoFornecedor,
          tipo: 'PRODUTO',
          preco: calcularCusto(
            item.subTotal,
            calcularConversao(item.quantidade, item.fatorConversao, item.operacaoFatorConversao)
          ),
          precoAtacado: 0,
          codigoBarras: codigoBarras.trim() !== '' ? codigoBarras : null,
          unidadeMedida: item.unidadeEstoque,
          unidadeMedidaEntrada: item.unidadeCompra,
          grupo: null,
          controlarEstoque: true,
          estoqueMinimo: 0,
          estoqueSaldo: 0,
          ultimoCusto: 0,
          origem: 'NACIONAL',
          ncm: item.ncm,
          cest: item.cest,
          pesoBruto: 0,
          pesoLiquido: 0,
          utilizarDocumentosDigitais: false,
          observacao: '',
          imagem: null,
          fatorConversao: 0,
          operacaoFatorConversao: 'MULTIPLICACAO',
          utilizaBalanca: false,
          filiais:[],
        });
      }
    });

    await asyncCadastrarProdutos(produtos, ({ data: produtos }) => {
      produtos.forEach(produto => {
        itensLocal.filter((value, index) => {
          if (produto.id === value.produto.id) {
            itensLocal[index].produto = produto;
          }
        });
      });
    });

    await setFieldValue('itens', itensLocal);
  }

  function RenderCabecalhoLista() {
    return (
      <Grid
        style={{
          display: isMobile || isTablet ? 'none' : 'inline-flex',
          width: '100%',
          padding: '0',
          margin: '0 0 -1.5rem 0',
        }}
      >
        <Col sm="6" md="6" lg="1" xl="1">
          <p>Código</p>
        </Col>
        <Col sm="6" md="6" lg="3" xl="3">
          <p>Descrição fornecedor</p>
        </Col>
        <Col sm="12" md="12" lg="3" xl="3">
          <p>Produto</p>
        </Col>
        <Col sm="4" md="4" lg="2" xl="2">
          <p>CFOP</p>
        </Col>
        <Col sm="4" md="4" lg="2" xl="2">
          <p>Conversão</p>
        </Col>
        <Col sm="4" md="4" lg="1" xl="1">
          <p>Cadastrar</p>
        </Col>
      </Grid>
    );
  }

  return (
    <>
      <Form>
        <FormActions style={{ margin: '0 0 0 -5px' }}>
          <ButtonCancelar onClick={onHideModal} />
          <ButtonSalvar onClick={onSaveModal} />
        </FormActions>
        <FormContent>
          <div
            style={{
              width: '100%',
              overflowY: values.itens?.length > 7 || props.errors?.itens?.length > 5 ? 'scroll' : 'visible',
              maxHeight: '25.2rem',
            }}
          >
            <RenderCabecalhoLista />
            {values.itens?.length > 0
              ? values.itens.map((item, index) => {
                  return (
                    <If key={index} test={!item.vinculo}>
                      <div
                        style={{
                          display: isMobile || isTablet ? 'block' : 'inline-flex',
                          width: '100%',
                        }}
                      >
                        <Field
                          sm="6"
                          md="12"
                          lg="1"
                          xl="1"
                          label={isMobile || isTablet ? 'Cód. fornecedor' : null}
                          disabled={true}
                          component={InputField}
                          value={item.codigoProduto}
                          title={item.codigoProduto}
                          name={'codigoProduto'}
                          size={255}
                        />

                        <Field
                          sm="6"
                          md="12"
                          lg="3"
                          xl="3"
                          label={isMobile || isTablet ? 'Descrição fornecedor' : null}
                          disabled={true}
                          component={InputField}
                          value={item.descricaoFornecedor}
                          title={item.descricaoFornecedor}
                          name={'descricaoFornecedor'}
                          size={255}
                        />

                        <Field
                          sm="12"
                          md="12"
                          lg="3"
                          xl="3"
                          label={isMobile || isTablet ? 'Produto' : null}
                          component={SingleSelectProduto}
                          name={'produto'}
                          onChange={event => onChangeProduto(event, index)}
                          url={`${services.GESTOR}/v1/produtos/resumo`}
                          errors={
                            props.errors && props.errors.itens?.length > 0 ? props.errors.itens[index]?.produto : null
                          }
                          touched={isTouched}
                          useFormErrors={false}
                          useFormTouched={false}
                          isClearable={true}
                          tipoPredefinido="PRODUTO"
                          value={item.produto}
                          esconderBotao={true}
                        />

                        <Field
                          sm="4"
                          md="12"
                          lg="2"
                          xl="2"
                          label={isMobile || isTablet ? 'CFOP' : null}
                          component={SingleSelectCfop}
                          value={item.cfop}
                          name={'cfop'}
                          onChange={event =>
                            setFieldValue(`itens.${index}.cfop`, {
                              value: event.value,
                              label: event.label,
                              codigo: event.registro?.codigo,
                              descricao: event.registro?.descricao,
                            })
                          }
                          isClearable={false}
                          placeholder="Selecione"
                          errors={
                            props.errors && props.errors.itens?.length > 0 ? props.errors.itens[index]?.cfop : null
                          }
                          touched={isTouched}
                          useFormErrors={false}
                          useFormTouched={false}
                        />

                        <Field
                          sm="4"
                          md="12"
                          lg="2"
                          xl="2"
                          label={isMobile || isTablet ? 'Conversão' : null}
                          component={InputSelectMultiplicacaoOrDivisao}
                          value={item.fatorConversao}
                          name={'fatorConversao'}
                          onChange={event => {
                            setFieldValue(`itens.${index}.fatorConversao`, event);
                          }}
                          valueOperacao={item.operacaoFatorConversao}
                          onChangeOperacao={() => {
                            alterarFatorOperacao(
                              `itens.${index}.operacaoFatorConversao`,
                              values.itens[index].operacaoFatorConversao
                            );
                          }}
                        />

                        <Col
                          style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItens: 'center',
                            width: 'auto',
                          }}
                        >
                          <Field
                            sm="4"
                            md="12"
                            lg="1"
                            xl="1"
                            label={isMobile || isTablet ? 'Cadastrar' : null}
                            component={Checkbox}
                            name={`itens.${index}.cadastrar`}
                            checked={item.cadastrar}
                            onChange={() => setFieldValue(`itens.${index}.cadastrar`, !item.cadastrar)}
                            showClear={false}
                            disabled={item.produto !== null}
                          />
                        </Col>
                      </div>
                    </If>
                  );
                })
              : null}
          </div>
        </FormContent>
      </Form>
    </>
  );
}

const mapStateToProps = state => ({
  isMobile: state.dispositivo.isMobile,
  isTablet: state.dispositivo.isTablet,
});

const FormVinculoForm = withFormik({
  enableReinitialize: true,
  validateOnChange: false,

  mapPropsToValues(props) {
    if (props.itens) {
      return {
        itens: ordenarItens(props.itens),
      };
    }
    return initialValues;
  },

  validationSchema: Yup.object().shape({
    itens: Yup.array().of(
      Yup.object().shape({
        cfop: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
      })
    ),
  }),

  validate: values => {
    let hasError = false;
    let errors = { itens: [] };

    values.itens.forEach((item, index) => {
      errors.itens.push({});
      if (item.produto === null && !item.cadastrar) {
        errors.itens[index].produto = mensagensDeValidacao.OBRIGATORIO;
        hasError = true;
      }
    });

    return hasError ? errors : null;
  },

  handleSubmit: () => {},
})(FormVinculoView);

export default connect(mapStateToProps)(FormVinculoForm);
