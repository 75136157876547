import propTypes from 'prop-types';

function FormContent(props) {
	return <div {...props}>{props.children}</div>;
}

FormContent.propTypes = {
	children: propTypes.any.isRequired,
};

export default FormContent;
