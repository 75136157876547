import { useFormikContext } from 'formik';
import {
  ButtonCancelar,
  ButtonExcluir,
  ButtonNovo,
  ButtonSalvar,
  estadosBotaoCancelar,
  estadosBotaoNovo,
} from 'components';
import { INITIAL_VALUES_CAMPO } from 'views/cadastros/Etiquetas/components/configuracao/Form/Util/constantes';
import { validarFormulario } from 'views/Util';
import { gerarUUID } from 'Common';

function ActionButtons({
  informacoesPermissoes,
  onHide,
  indexCampo,
  addNewCampoModal,
  editCampoModal,
  deleteCampoModal,
}) {
  const { values, dirty, handleReset, handleSubmit, validateForm, resetForm } = useFormikContext();

  function handleClickVoltarCancelar() {
    dirty ? handleReset() : onHide();
  }

  async function handleClickSalvar() {
    handleSubmit();

    if (await validarFormulario({ values: values, validateForm })) {
      const tipoCampo = {
        label: values.campo?.registro?.descricao,
        registro: values.campo?.registro,
        value: values.campo?.registro?.id,
      };

      if (values.id || values.idTemporario) {
        editCampoModal(indexCampo, { ...values, campo: tipoCampo });
      } else {
        addNewCampoModal({ ...values, idTemporario: gerarUUID(), campo: tipoCampo });
      }

      onHide();
    }
  }

  function handleClickNovo() {
    resetForm({ values: INITIAL_VALUES_CAMPO });
  }

  function handleClickExcluir() {
    deleteCampoModal(indexCampo);
    onHide();
  }

  return (
    <>
      <ButtonCancelar
        estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
        onClick={handleClickVoltarCancelar}
        {...informacoesPermissoes}
      />
      <ButtonSalvar disabled={!dirty} onClick={handleClickSalvar} {...informacoesPermissoes} />
      <ButtonNovo
        onClick={handleClickNovo}
        hidden={!dirty && !values.id}
        estadoBotao={dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO}
        {...informacoesPermissoes}
      />
      <ButtonExcluir hidden={!values.id} onClick={handleClickExcluir} {...informacoesPermissoes} />
    </>
  );
}

export { ActionButtons };
