import { get, del, post, put, exibirToast } from '../../../../../../Common/Requisicoes';
import { services } from '../../../../../../Common/Constantes/api';

export async function asyncCreateTransferencia(transferencia, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/transferencias`, transferencia, null, exibirToast(onSuccess, 'Transferência criada com sucesso'), onError);
}

export async function asyncGetTransferencia(idTransferencia, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/transferencias/${idTransferencia}`, null, onSuccess, onError);
}

export async function asyncUpdateTransferencia(transferencia, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/transferencias/${transferencia.id}`,
		transferencia,
		null,
		exibirToast(onSuccess, 'Transferência atualizada com sucesso'),
		onError
	);
}

export async function buscarFormaPagamentoDinheiro(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/transferencias/relacoes/formas_pagamento?query=formaPagamentoFiscal=="DINHEIRO"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncDeleteTransferencia(idTransferencia, onSuccess, onError) {
	await del(`${services.GESTOR}/v1/transferencias/${idTransferencia}`, null, exibirToast(onSuccess, 'Transferência removida com sucesso'), onError);
}
