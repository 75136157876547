export const container = {
	gridReverse: 'dir-rev ',
	nestedGrid: 'nested-grid ',
	directionColumn: 'dir-col ',
	directionColumnReverse: 'dir-col-rev ',
	justifyStart: 'justify-content-start ',
	justifyEnd: 'justify-content-end ',
	justifyCenter: 'justify-content-center ',
	justifyBetween: 'justify-content-between ',
	justifyAround: 'justify-content-around ',
	justifyEven: 'justify-content-evenly ',
	verticalAlignStart: 'align-items-start  ',
	verticalAlignEnd: 'align-items-end  ',
	verticalAlignCenter: 'align-items-center  ',
	verticalAlignStretch: 'align-items-stretch ',
};
