export const tutorialStepsNotaEntrada = [
	{
		target: 'body',
		content: 'Este é o módulo de notas de entrada. Aqui você pode criar, editar e exclui-las.',
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-nota-entrada-inputs',
		content: 'Nesta seção, pode ser informado os dados do cabeçalho da nota.',
		placement: 'bottom',
	},
	{
		target: '.step-nota-entrada-itens',
		content: 'Aqui, é possível adicionar e editar itens da sua nota.',
		placement: 'top',
	},
	{
		target: '.step-nota-entrada-tabs',
		content:
			'Já nesta aba, estão os totalizadores da nota, além disso, nas demais abas, podem ser alterados as informações de pagamentos e transporte.',
		placement: 'top',
	},
	{
		target: '.step-nota-entrada-actions',
		content:
			'Por fim, nessa seção estão os botões de ações. onde é possível criar, excluir e salvar a nota. Além disso, lembre-se de clicar em "Finalizar Nota" após salva-la, somente nessa ação ocorre a movimentação de estoque e financeiro.',
		placement: 'bottom',
	},
];
