import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FaCheckCircle } from 'react-icons/fa';

import { CardTotalizadorListagem } from 'components';
import { getSafeValue } from 'Common';

import { CORES_CARDS } from '../../Utils/constantes';

const styleCard = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-evenly',
};

function CardsTotalizadores({ isMobile, isRecebimento, atualizarTotalizadorTitulos, setAtualizarTotalizadorTitulos }) {
	const { values, setFieldValue, resetForm } = useFormikContext();

	useEffect(() => {
		const newTotais = {
			titulos: 0,
			juros: 0,
			multa: 0,
			desconto: 0,
			quitado: 0,
		};

		values.titulos?.forEach((titulo) => {
			newTotais.titulos += getSafeValue(titulo.quitacao.valor);
			newTotais.juros += getSafeValue(titulo.quitacao.juros);
			newTotais.multa += getSafeValue(titulo.quitacao.multa);
			newTotais.desconto += getSafeValue(titulo.quitacao.desconto);
			newTotais.quitado += getSafeValue(titulo.quitacao.quitado);
		});

		resetForm({ values: { ...values, totais: newTotais } });
		setAtualizarTotalizadorTitulos(false);
	}, []);

	useEffect(() => {
		if (atualizarTotalizadorTitulos) {
			const newTotais = {
				titulos: 0,
				juros: 0,
				multa: 0,
				desconto: 0,
				quitado: 0,
			};

			values.titulos?.forEach((titulo) => {
				newTotais.titulos += getSafeValue(titulo.quitacao.valor);
				newTotais.juros += getSafeValue(titulo.quitacao.juros);
				newTotais.multa += getSafeValue(titulo.quitacao.multa);
				newTotais.desconto += getSafeValue(titulo.quitacao.desconto);
				newTotais.quitado += getSafeValue(titulo.quitacao.quitado);
			});

			setFieldValue('totais', newTotais);
			setAtualizarTotalizadorTitulos(false);
		}
	}, [atualizarTotalizadorTitulos]);

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr 1fr',
				margin: '10px 0 0 0',
				padding: 0,
				gap: '10px',
			}}
		>
			<CardTotalizadorListagem
				styleCard={styleCard}
				name="titulos"
				title="Valor títulos"
				helpMessage="Soma dos valores de todos os títulos selecionados"
				icon={<FaCheckCircle size={22} color={CORES_CARDS.NEUTRAL} />}
				primaryColor={CORES_CARDS.NEUTRAL}
				colors={CORES_CARDS.NEUTRAL}
				value={values.totais?.titulos}
			/>

			<CardTotalizadorListagem
				styleCard={styleCard}
				name="juros"
				title="Juros"
				helpMessage="Soma dos juros dos títulos selecionados"
				icon={<FaCheckCircle size={22} color={CORES_CARDS.DANGER} />}
				primaryColor={CORES_CARDS.DANGER}
				colors={CORES_CARDS.DANGER}
				value={values.totais?.juros}
			/>

			<CardTotalizadorListagem
				styleCard={styleCard}
				name="multa"
				title="Multa"
				helpMessage="Soma das multas dos títulos selecionados"
				icon={<FaCheckCircle size={22} color={CORES_CARDS.DANGER} />}
				primaryColor={CORES_CARDS.DANGER}
				colors={CORES_CARDS.DANGER}
				value={values.totais?.multa}
			/>

			<CardTotalizadorListagem
				styleCard={styleCard}
				name="desconto"
				title="Desconto"
				helpMessage="Soma dos descontos dos títulos selecionados"
				icon={<FaCheckCircle size={22} color={CORES_CARDS.INFO} />}
				primaryColor={CORES_CARDS.INFO}
				colors={CORES_CARDS.INFO}
				value={values.totais?.desconto}
			/>

			<CardTotalizadorListagem
				styleCard={styleCard}
				name="quitado"
				title={`Total ${isRecebimento ? 'recebido' : 'pago'}`}
				helpMessage={`Soma dos valores ${isRecebimento ? 'recebidos' : 'pagos'} dos títulos selecionados`}
				icon={<FaCheckCircle size={22} color={CORES_CARDS.SUCCESS} />}
				primaryColor={CORES_CARDS.SUCCESS}
				colors={CORES_CARDS.SUCCESS}
				value={values.totais?.quitado}
			/>
		</div>
	);
}

export default CardsTotalizadores;
