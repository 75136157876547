export const COLORS = {
  DARK_RED: "#B71C1C",
  LIGHT_RED: "#b71c1c26",
  DARK_BLUE: "#006095",
  LIGHT_BLUE: "#00609526",
};

export const TIPO_CONSULTA = {
  PRODUTO: "PRODUTO",
  NOTA_ENTRADA: "NOTA_ENTRADA",
};

export const OPTIONS_TIPO_CONSULTA = [
  {
    label: "Produtos",
    value: TIPO_CONSULTA.PRODUTO,
  },
  {
    label: "Nota entrada",
    value: TIPO_CONSULTA.NOTA_ENTRADA,
  },
];

export const OPTIONS_CONTORNO = [
  { label: "Sim", value: true },
  { label: "Não", value: false },
];

export const TIPO_PAPEL = {
  BOBINA: "BOBINA",
  FOLHA: "FOLHA",
};
