import propTypes from 'prop-types';
import If from '../If';

function FormGroup(props) {
	const { id, hidden, children, style } = props;

	return (
		<If test={!hidden}>
			<div id={id} className="form-group" style={style}>
				{children}
			</div>
		</If>
	);
}

FormGroup.propTypes = {
	/** Identificador do componente */
	id: propTypes.string,
	/** Filhos do componente */
	children: propTypes.any,
	/** Esconde o componente*/
	hidden: propTypes.bool,
	/** Estilo do componente*/
	style: propTypes.object,
};

export default FormGroup;
