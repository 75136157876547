import { permissoes, recursos } from 'Common';
import { Switch, withRouter } from 'react-router-dom';
import PrivateRoute from 'components/Route';

import Boletos from 'views/financas/Boletos';

import ContasPagar from 'views/financas/ContasPagar';
import ContasReceber from 'views/financas/ContasReceber';

import Lancamentos from 'views/financas/Lancamentos';

import RelatoriosFinancas from 'views/financas/Relatorios';
import PixCobranca from 'views/financas/PixCobranca';

export const pathFinancasRoutes = [
	'/movimentacoes',
	'/contas_receber',
	'/contas_pagar',
	'/boletos',
	'/pix_cobranca',
	'/relatorios_financas',
];

function FinancasRoutes() {
	return (
		<Switch>
			<PrivateRoute
				exact
				path={['/movimentacoes']}
				render={props => <Lancamentos {...props} />}
				recurso={recursos.FINANCAS_LANCAMENTOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/contas_receber']}
				render={props => <ContasReceber {...props} />}
				recurso={recursos.FINANCAS_CONTAS_RECEBER}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/contas_pagar']}
				render={props => <ContasPagar {...props} />}
				recurso={recursos.FINANCAS_CONTAS_PAGAR}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/boletos']}
				render={props => <Boletos {...props} />}
				recurso={recursos.FINANCAS_CONTAS_RECEBER}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/pix_cobranca']}
				render={props => <PixCobranca {...props} />}
				recurso={recursos.FINANCAS_CONTAS_RECEBER}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/relatorios_financas']}
				render={props => <RelatoriosFinancas {...props} />}
				recurso={recursos.FINANCAS_RELATORIOS}
				permissao={permissoes.VISUALIZAR}
			/>
		</Switch>
	);
}

export default withRouter(FinancasRoutes);
