import { formaPagamento } from "views/compras/NotasEntrada/Form/components/TabelaPagamentos/Util/constantes"

export function converterTransferenciaParaApi(values) {
    return {
        id: values.id,
        data: values.data,
        formaPagamento: {
            id: values.formaPagamento.value
        },         
        contaOrigem: {
            id: values.contaOrigem.value
        },
        contaDestino: {
            id: values.contaDestino.value
        },
        valor: values.valor,
        observacao: values.observacao
    }
}

export function converterTransferenciaParaFormulario(values) {
    return {
        id: values.id,
        data: values.data,     
        formaPagamento: { 
            label: values.formaPagamento?.descricao, 
            value: values.formaPagamento?.id 
        },   
        contaOrigem: { 
            label: values.contaOrigem.nome, 
            value: values.contaOrigem.id 
        },
        contaDestino: { 
            label: values.contaDestino.nome, 
            value: values.contaDestino.id
        },
        valor: values.valor,
        observacao: values.observacao,
    }
}