import { exibirToast, get, post, del, put, services } from 'Common';

export async function readCondutores(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function readCondutor(idCondutor, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/transporte/condutores/${idCondutor}`, null, onSuccess, onError);
}

export async function createCondutor(condutor, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/transporte/condutores`,
		condutor,
		null,
		exibirToast(onSuccess, 'Condutor criado com sucesso'),
		onError
	);
}

export async function updateCondutor(condutor, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/transporte/condutores/${condutor.id}`,
		condutor,
		null,
		exibirToast(onSuccess, 'Condutor atualizado com sucesso'),
		onError
	);
}

export async function deleteCondutor(idCondutor, onSuccess, onError) {
	return await del(
		`${services.GESTOR}/v1/transporte/condutores/${idCondutor}`,
		null,
		exibirToast(onSuccess, 'Condutor excluído'),
		onError
	);
}
