import { buscarDadosLoginLocalStorage } from '../../../../Common/Autenticacao';
import { services } from '../../../../Common/Constantes/api';
import { exibirToast, get, post, put } from '../../../../Common/Requisicoes';

export async function asyncGetConfiguracoesEmpresa(onSuccess) {
	await get(`${services.GESTOR}/v1/empresa`, null, onSuccess);
}

export async function asyncGetLogotipoEmpresa(onSuccess, onError) {
	await get(`${services.GESTOR}/v1/empresa/logotipo`, { responseType: 'blob' }, onSuccess, onError, false, false);
}

export async function asyncUploadLogotipoEmpresa(arquivo, onSuccess, onError) {
	const formData = new FormData();
	formData.append('arquivo', arquivo);
	await post(
		`${services.GESTOR}/v1/empresa/logotipo`,
		formData,
		{ headers: { 'content-type': 'multipart/form-data' } },
		onSuccess,
		onError
	);
}

export async function asyncUploadCertificado(arquivo, senha, onSuccess, onError) {
	const formData = new FormData();
	formData.append('arquivo', arquivo);
	formData.append('senha', senha);
	await post(
		`${services.GESTOR}/v1/empresa/certificado`,
		formData,
		{ headers: { 'content-type': 'multipart/form-data' } },
		onSuccess,
		onError
	);
}

export async function asyncUpdateConfiguracoesEmpresa(values, onSuccess) {
	await put(`${services.GESTOR}/v1/empresa`, values, null, onSuccess);
}

export async function asyncExcluirEmpresa(data, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/empresa/excluir`,
		data,
		null,
		exibirToast(onSuccess, 'Todos os seus dados foram apagados'),
		onError
	);
}

export async function asyncSalvarAliquotaSimples(data, onSuccess, onError) {
	const filialId = buscarDadosLoginLocalStorage().filialConectada.id;
	return await post(`${services.GESTOR}/v1/empresa/${filialId}/aliquotasimples`, data, null, onSuccess, onError);
}

export async function asyncBuscarAliquotaSimples(onSuccess, onError) {
	const filialId = buscarDadosLoginLocalStorage().filialConectada.id;
	return await get(`${services.GESTOR}/v1/empresa/${filialId}/aliquotasimples`, null, onSuccess, onError, false, false);
}

export async function asyncBuscarInformacoesCredencial(onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/credenciais/minha_credencial`, null, onSuccess, onError, false);
}

export async function sincronizarEmpresa(onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/empresa/sincronizar`,
		null,
		null,
		exibirToast(onSuccess, 'Empresa sincronizada com sucesso'),
		onError
	);
}
