import { useState } from 'react';
import { GoBookmark } from 'react-icons/go';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { estadosCadastro, removerElemento, copiarObjeto } from '../../../../../../../Common';

import {
  ButtonExcluirTable,
  ButtonNovo,
  Modal,
  If,
  ButtonEditarTable,
  NenhumRegistroEncontrado,
  Grid,
} from '../../../../../../../components';

import PessoaEmailForm from './Form';
import { jaExisteMesmoEmail } from './Util/validacoes.js';

function PessoaEmail(props) {
  const { estadoCadastro, podeEditar, value, appendTo } = props;

  const [registroSelecionado, setRegistroSelecionado] = useState(null);
  const [modalFormVisible, setModalFormVisible] = useState(false);

  const desabilitarCampos = estadoCadastro === estadosCadastro.EDICAO && !podeEditar;

  function novo() {
    if (props.setHideBackground) {
      props.setHideBackground(true);
    }
    setRegistroSelecionado(null);
    setModalFormVisible(true);
  }

  function onEditar(row) {
    if (props.setHideBackground) {
      props.setHideBackground(true);
    }
    setRegistroSelecionado(row);
    setModalFormVisible(true);
  }

  function renderOpcoes(row) {
    return (
      <div>
        <ButtonEditarTable onClick={() => onEditar(row)} />
        <ButtonExcluirTable onClick={() => excluirRegistro(row)} podeExcluir={!desabilitarCampos} />
      </div>
    );
  }

  function hideModal() {
    if (props.setHideBackground) {
      props.setHideBackground(false);
    }

    setRegistroSelecionado(null);
    setModalFormVisible(false);
  }

  function atualizarRegistro(dadosFormulario) {
    const emails = copiarObjeto(props.value);
    const result =
      emails &&
      emails.map(item => {
        if (item.idTemporario || item.id) {
          if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
            return dadosFormulario;
          } else if (item.id && item.id === dadosFormulario.id) {
            return dadosFormulario;
          }

          if (dadosFormulario.favorito) {
            item.favorito = false;
          }

          return item;
        } else {
          return dadosFormulario;
        }
      });
    setRegistroSelecionado(dadosFormulario);
    props.onChange(result);
  }

  function inserirRegistro(dadosFormulario) {
    let emails = copiarObjeto(props.value);
    props.onChange(mergeEmailIntoEmails(dadosFormulario, emails));
    setRegistroSelecionado(dadosFormulario);
  }

  function mergeEmailIntoEmails(email, emails) {
    if (email.favorito) {
      emails.map(tempEmail => {
        return (tempEmail.favorito = false);
      });
    }
    return [...emails, email];
  }

  function excluirRegistro(dadosFormulario) {
    props.onChange(removerElemento(props.value, dadosFormulario));
    setRegistroSelecionado(null);
  }

  function atualizarRegistroSelecionado(registro) {
    setRegistroSelecionado(registro);
  }

  function validarMesmoEmail(email) {
    return jaExisteMesmoEmail(props.value, email);
  }

  function marcarComoFavorito() {
    const emails = copiarObjeto(props.value);
    let existemFavoritos = false;

    emails?.forEach(email => {
      if (email.favorito) {
        existemFavoritos = true;
      }
    });

    return !existemFavoritos;
  }

  function renderEmail(row) {
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {row.email}
        {row.favorito ? (
          <span>
            <GoBookmark size={22} color="#fbc02d" title="E-mail favorito" />
          </span>
        ) : null}
      </span>
    );
  }

  return (
    <>
      <Modal visible={modalFormVisible} header="Cadastro de e-mail" onHide={hideModal} container={appendTo}>
        <If test={modalFormVisible}>
          <PessoaEmailForm
            registroSelecionado={registroSelecionado}
            onNovoClick={() => setRegistroSelecionado(null)}
            inserirRegistro={inserirRegistro}
            atualizarRegistro={atualizarRegistro}
            excluirRegistro={excluirRegistro}
            marcarComoFavorito={marcarComoFavorito}
            podeEditar={!desabilitarCampos}
            podeExcluir={!desabilitarCampos}
            onHide={hideModal}
            validarMesmoEmail={validarMesmoEmail}
          />
        </If>
      </Modal>
      <Grid style={{ padding: '0.5rem 0rem' }}>
        <ButtonNovo label="Novo e-mail" enableShortCut={false} onClick={novo} podeInserir={!desabilitarCampos} />
      </Grid>
      <DataTable
        className="table"
        responsive
        value={value}
        emptyMessage={<NenhumRegistroEncontrado message="Nenhum e-mail encontrado" />}
      >
        <Column field="email" body={renderEmail} header="E-mail" sortable={true} />
        <Column body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
      </DataTable>
    </>
  );
}

export default PessoaEmail;
