import { copiarObjeto, services } from 'Common';
import {
	Divider,
	Grid,
	SingleSelectMunicipio,
	SingleSelectSetor,
	SingleSelectTecnico,
	TextArea,
	SingleSelectVendedorListagem,
} from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextOrdemServico } from 'views/fiscal/servicos/OrdemServico/Context';

export function TabInformacoesComplementares({
	utilizaTabelaPreco,
	utilizaTabelaPrecoServico,
	buscarTabelaPreco,
	buscarMunicipioIdPessoa,
}) {
	const { informacoesPermissoes, functionsForm, disabledFields } = useContextOrdemServico();
	const { values, errors, setFieldValue } = useFormikContext();
	const { vendedor, setor, servico, produto, tecnico } = values.informacoesComplementares;

	function handleChangeMunicipio(event) {
		functionsForm.handleChangeSelect('informacoesComplementares.servico.municipio', event);
	}

	async function onChangeVendedor(event) {
		functionsForm.handleChangeSelect('informacoesComplementares.vendedor', event);

		if (utilizaTabelaPreco || utilizaTabelaPrecoServico) {
			const municipioId = buscarMunicipioIdPessoa(values.tomador);
			await buscarTabelaPreco(
				{
					vendedorId: event?.value,
					clienteId: values.tomador?.value,
					municipioId: municipioId,
				},
				({ data: tabelaPrecoApi }) => {
					functionsForm.handleChangeSelect('informacoesComplementares.tabelaPreco', {
						label: `${tabelaPrecoApi.codigo} - ${tabelaPrecoApi.nome}`,
						registro: tabelaPrecoApi,
						value: tabelaPrecoApi.id,
					});
				}
			);
			if (!event.registro?.descontoMaximoSuperior){
				const servicos = copiarObjeto(values.servicos);		
				const produtos = copiarObjeto(values.produtos);
				servicos.forEach((servico, index) => { 					
						servicos[index].descontoMaximoVendedor = event.registro?.descontoMaximo
						if (servico.descontoMaximoTabela < event.registro?.descontoMaximo){
							servicos[index].descontoMaximoPercentual = servico.descontoMaximoTabela
						}else{
							servicos[index].descontoMaximoPercentual = event.registro?.descontoMaximo;
						}
					}	
				);
				produtos.forEach((produto, index) => { 					
					produtos[index].descontoMaximoVendedor = event.registro?.descontoMaximo
					if (produto.descontoMaximoTabela < event.registro?.descontoMaximo){
						produtos[index].descontoMaximoPercentual = produto.descontoMaximoTabela
					}else{
						produtos[index].descontoMaximoPercentual = event.registro?.descontoMaximo;
					}
				}	
			);
				setFieldValue('servicos', servicos);
				setFieldValue('produtos', produtos);
			}			
		}
	}

	return (
		<Grid>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={SingleSelectVendedorListagem}
				name="vendedor"
				label="Vendedor responsável"
				obrigatorio
				isClearable={false}
				url={`${services.GESTOR}/v1/ordem_servico/relacoes/vendedores`}
				value={vendedor}
				onChange={(value) => onChangeVendedor(value)}
				errors={errors && errors?.informacoesComplementares?.vendedor}
				useFormTouched={false}
				touched
				esconderBotao
				disabled={disabledFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={SingleSelectSetor}
				name="setor"
				label="Setor "
				obrigatorio
				url={`${services.GESTOR}/v1/ordem_servico/relacoes/setores`}
				value={setor}
				onChange={(value) => functionsForm.handleChangeSelect('informacoesComplementares.setor', value)}
				errors={errors && errors?.informacoesComplementares?.setor}
				useFormTouched={false}
				touched
				esconderBotao
				isClearable={false}
				disabled={disabledFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={SingleSelectTecnico}
				name="tecnico"
				label="Técnico "
				url={`${services.GESTOR}/v1/ordem_servico/relacoes/tecnicos`}
				value={tecnico}
				onChange={(value) =>
					value
						? functionsForm.handleChangeSelect('informacoesComplementares.tecnico', value)
						: setFieldValue('informacoesComplementares.tecnico', value)
				}
				errors={errors && errors?.informacoesComplementares?.tecnico}
				useFormTouched={false}
				touched
				esconderBotao={false}
				isClearable
				disabled={disabledFields}
				{...informacoesPermissoes}
			/>
			<Divider
				label="Servicos"
				styleContainer={{ padding: '0 8px', margin: '0 0.5rem' }}
				styleLabel={{ fontSize: '16px', opacity: '0.7' }}
			/>
			<Grid style={{ width: 'inherit', paddingLeft: '8px' }}>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={TextArea}
					placeholder="Escreva informações que sejam importantes para você e seu cliente"
					label="Informações complementares"
					name="informacoesComplementares.servico.informacoesComplementares"
					maxLength={4096}
					rows={4}
					value={servico.informacoesComplementares}
					onChange={(event) => functionsForm.handleChangeInput(event)}
					disabled={disabledFields}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={TextArea}
					label="Observações internas"
					name="informacoesComplementares.servico.observacaoInterna"
					value={servico.observacaoInterna}
					onChange={(event) => functionsForm.handleChangeInput(event)}
					placeholder="Escreva observações para uso interno"
					maxLength={4096}
					rows={4}
					disabled={disabledFields}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={SingleSelectMunicipio}
					name="informacoesComplementares.servico.municipio"
					label="Município de prestação de serviço"
					obrigatorio
					isClearable={false}
					value={servico.municipio}
					onChange={handleChangeMunicipio}
					errors={errors && errors?.informacoesComplementares?.municipio}
					useFormTouched={false}
					touched
					esconderBotao
					disabled={disabledFields}
					{...informacoesPermissoes}
				/>
			</Grid>
			<Divider
				label="Produtos"
				styleContainer={{ padding: '0 8px', margin: '0 0.5rem' }}
				styleLabel={{ fontSize: '16px', opacity: '0.7' }}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={TextArea}
				label="Informações complementares"
				name="informacoesComplementares.produto.informacoesComplementares"
				value={produto.informacoesComplementares}
				onChange={(event) => functionsForm.handleChangeInput(event)}
				placeholder="Escreva informações que sejam importantes para você e seu cliente"
				maxLength={4096}
				rows={4}
				disabled={disabledFields}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={TextArea}
				label="Observações internas"
				name="informacoesComplementares.produto.observacaoInterna"
				value={produto.observacaoInterna}
				onChange={(event) => functionsForm.handleChangeInput(event)}
				placeholder="Escreva observações para uso interno"
				maxLength={4096}
				rows={4}
				disabled={disabledFields}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}
