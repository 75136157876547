import { useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { copiarObjeto, formatarMonetario, getSafeValue, TiposPercentualValor } from 'Common';
import { Button, InputSelectPercentualOrValor, NenhumRegistroEncontrado } from 'components';

import { aplicarPercentual } from 'views/Util';
import { CiWarning } from 'react-icons/ci';
import { STYLE_BUTTONS } from '../../Utils/constantes';
import { InformacoesExtrasPanel } from './components/InformacoesExtrasPanel';

const styleColumnOverflowElipsis = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

function TabelaTitulos({
	isMobile,
	isTablet,
	isRecebimento,
	decimaisPreco,
	url,
	setAtualizarTotalizadorTitulos,
	setAtualizarTotalizadorAlteracaoEmLote,
	informacoesPermissoes,
}) {
	const { setFieldValue, values, errors } = useFormikContext();

	const panelDetalhesTitulo = useRef(null);
	const [currentTituloIndex, setCurrentTituloIndex] = useState(null);

	function renderIdentificadorField(identificador, options) {
		const hasError = errors?.titulos && errors?.titulos[options.rowIndex];

		return (
			<div
				title="Título com um ou mais erros"
				style={{
					...styleColumnOverflowElipsis,
					color: hasError ? '#D32F2F' : '#495057',
					display: 'flex',
					alignItems: 'center',
					fontWeight: 'bold',
				}}
			>
				{hasError ? (
					<CiWarning style={{ width: '18px', height: '18px', marginRight: '8px', color: '#D32F2F' }} />
				) : null}
				{identificador || '-'}
			</div>
		);
	}

	function renderValorField(valor) {
		return <div style={styleColumnOverflowElipsis}>{formatarMonetario(valor)}</div>;
	}

	function renderDescontoField(desconto) {
		return <div style={styleColumnOverflowElipsis}>{formatarMonetario(desconto)}</div>;
	}

	function renderJurosField(juros) {
		return <div style={styleColumnOverflowElipsis}>{formatarMonetario(juros)}</div>;
	}

	function renderMultaField(multa) {
		return <div style={styleColumnOverflowElipsis}>{formatarMonetario(multa)}</div>;
	}

	function renderQuitadoField(quitado, options) {
		const hasError = errors?.titulos && errors?.titulos[options.rowIndex];

		return (
			<div
				style={{
					...styleColumnOverflowElipsis,
					color: hasError && errors?.titulos[options.rowIndex]?.quitado ? '#D32F2F' : '#495057',
					fontWeight: 'bold',
				}}
			>
				{formatarMonetario(quitado)}
			</div>
		);
	}

	function renderAcoesField(_, options) {
		const hasError = errors?.titulos && errors?.titulos[options.rowIndex];

		function handleShowOverlay(e, rowIndex) {
			document
				.getElementById('overlayPanel-detalhes')
				?.getElementsByClassName('p-overlaypanel-close p-link')[0]
				?.click();

			setCurrentTituloIndex(rowIndex);
			panelDetalhesTitulo.current?.toggle(e);
		}

		function handleDeleteItem(index) {
			const titulos = copiarObjeto(values.titulos);

			titulos.splice(index, 1);

			setFieldValue('titulos', titulos);
			setAtualizarTotalizadorAlteracaoEmLote(true);
			setAtualizarTotalizadorTitulos(true);
		}

		function getColorButtonAcao() {
			let color = '#006095';

			if (values.titulos[options?.rowIndex]?.quitacao?.aplicarTaxa > 0) {
				color = '#D89E20';
			}

			if (hasError && (errors?.titulos[options.rowIndex]?.conta || errors?.titulos[options.rowIndex]?.formaPagamento)) {
				color = '#D32F2F';
			}

			return color;
		}

		function getTitleButtonAcao() {
			let title = '';

			if (values.titulos[options?.rowIndex]?.quitacao?.aplicarTaxa > 0) {
				title = 'Título com taxa aplicada, clique para mais ver detalhes';
			}

			if (hasError && (errors?.titulos[options.rowIndex]?.conta || errors?.titulos[options.rowIndex]?.formaPagamento)) {
				title = `Título sem ${
					errors?.titulos[options.rowIndex]?.formaPagamento ? 'Forma de pagamento' : 'Conta'
				} informada`;
			}

			return title;
		}

		return (
			<div style={styleColumnOverflowElipsis}>
				<Button
					style={{
						...STYLE_BUTTONS,
						background: getColorButtonAcao(),
						border: getColorButtonAcao(),
					}}
					className="p-button p-button-primary"
					title={getTitleButtonAcao()}
					icon="fa fa-info"
					disabled={currentTituloIndex !== null}
					onClick={(e) => handleShowOverlay(e, options.rowIndex)}
				/>
				<Button
					style={STYLE_BUTTONS}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					onClick={() => handleDeleteItem(options.rowIndex)}
				/>
			</div>
		);
	}

	function renderEditJuros(options) {
		return (
			<InputSelectPercentualOrValor
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				name="quitacao.juros"
				value={values?.titulos[options.rowIndex]?.quitacao?.juros}
				onChange={(tipo, value, percentual) => onChangeField('juros', options, value, percentual, tipo)}
				size={13}
				allowNegative={false}
				decimalScale={decimaisPreco}
				informacoesPermissoes={informacoesPermissoes}
			/>
		);
	}
	function renderEditMulta(options) {
		return (
			<InputSelectPercentualOrValor
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				name="quitacao.multa"
				value={values?.titulos[options.rowIndex]?.quitacao?.multa}
				onChange={(tipo, value, percentual) => onChangeField('multa', options, value, percentual, tipo)}
				size={13}
				allowNegative={false}
				decimalScale={decimaisPreco}
				informacoesPermissoes={informacoesPermissoes}
			/>
		);
	}
	function renderEditDesconto(options) {
		return (
			<InputSelectPercentualOrValor
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				name="quitacao.desconto"
				value={values?.titulos[options.rowIndex]?.quitacao?.desconto}
				onChange={(tipo, value, percentual) => onChangeField('desconto', options, value, percentual, tipo)}
				size={13}
				allowNegative={false}
				decimalScale={decimaisPreco}
				informacoesPermissoes={informacoesPermissoes}
			/>
		);
	}

	function onChangeField(field, options, value, percentual, tipo = TiposPercentualValor.VALOR) {
		if (tipo === TiposPercentualValor.PERCENTUAL) {
			const valor = parseFloat(options.rowData.quitacao.valor.toFixed(2));
			options.editorCallback({ ...options.rowData.quitacao, [field]: aplicarPercentual(valor, percentual) });
		} else {
			options.editorCallback({ ...options.rowData.quitacao, [field]: value });
		}
	}

	function onBlurColumn(field, options) {
		if (options.rowData?.quitacao[field] !== options.newRowData?.quitacao[field]) {
			const titulo = options.newRowData;
			titulo.quitacao.quitado =
				getSafeValue(titulo.quitacao.valor) +
				getSafeValue(titulo.quitacao.juros) +
				getSafeValue(titulo.quitacao.multa) -
				getSafeValue(titulo.quitacao.desconto);

			setFieldValue(`titulos[${options.rowIndex}]`, titulo);
			setAtualizarTotalizadorTitulos(true);
			setAtualizarTotalizadorAlteracaoEmLote(true);
		}
	}

	return (
		<div style={{ width: !isMobile ? '100%' : '99%' }}>
			<DataTable
				className="table"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				value={values.titulos}
				responsive
				editMode="cell"
				emptyMessage={<NenhumRegistroEncontrado message="Nenhuma conta a receber encontrada" />}
				style={{ height: '339px', overflowY: 'auto' }}
			>
				<Column
					header="Identificador"
					field="identificador"
					body={(row, options) => renderIdentificadorField(row.identificador, options)}
				/>
				<Column
					header="Valor"
					field="quitacao"
					body={(row) => renderValorField(row.quitacao?.valor)}
					style={{ width: '100px' }}
				/>
				<Column
					header="Juros"
					field="quitacao"
					body={(row) => renderJurosField(row.quitacao?.juros)}
					editor={(options) => renderEditJuros(options)}
					onCellEditComplete={(options) => onBlurColumn('juros', options)}
					style={{ width: '140px' }}
				/>
				<Column
					header="Multa"
					field="quitacao"
					body={(row) => renderMultaField(row.quitacao?.multa)}
					editor={(options) => renderEditMulta(options)}
					onCellEditComplete={(options) => onBlurColumn('multa', options)}
					style={{ width: '140px' }}
				/>
				<Column
					header="Desconto"
					field="quitacao"
					body={(row) => renderDescontoField(row.quitacao?.desconto)}
					editor={(options) => renderEditDesconto(options)}
					onCellEditComplete={(options) => onBlurColumn('desconto', options)}
					style={{ width: '140px' }}
				/>
				<Column
					header={isRecebimento ? 'Recebido' : 'Pago'}
					field="quitacao"
					body={(row, options) => renderQuitadoField(row.quitacao?.quitado, options)}
					style={{ width: '100px' }}
				/>
				<Column header="Ações" headerClassName="coluna-acoes-end" body={renderAcoesField} style={{ width: '100px' }} />
			</DataTable>
			<InformacoesExtrasPanel
				panelDetalhesTitulo={panelDetalhesTitulo}
				currentTituloIndex={currentTituloIndex}
				setCurrentTituloIndex={setCurrentTituloIndex}
				isMobile={isMobile}
				url={url}
				informacoesPermissoes={informacoesPermissoes}
			/>
		</div>
	);
}

export default TabelaTitulos;
