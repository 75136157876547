import React from 'react';
import { statusGestaoDocumento } from './constantes';
import { formatarParaPesquisarTiposEnumerados } from '../../../../Common/ManipulacaoDeString';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';

export function renderStatus(row) {
  let strongColor = '#721358';
  let lightColor = '#BFA8C5';
  let texto;
  let paddingSize = '';
  switch (row.status) {
    case statusGestaoDocumento.RESCINDIDO:
      texto = aplicarEstiloArquivado(row.arquivado, 'Rescindido');
      strongColor = '#da1f1f';
      lightColor = '#ffcdd2';
      paddingSize = '0.2rem 1.5rem';
      break;
    case statusGestaoDocumento.PENDENTE:
      texto = aplicarEstiloArquivado(row.arquivado, 'Pendente');
      strongColor = '#01579b';
      lightColor = '#b3e5fc';
      paddingSize = '0.2rem 1.5rem';
      break;
    case statusGestaoDocumento.VIGENCIA_EXPIRADA:
      texto = aplicarEstiloArquivado(row.arquivado, 'Vigência expirada');
      strongColor = '#c16b13';
      lightColor = '#fff7cd';
      paddingSize = '0.2rem 0.9rem';
      break;
    case statusGestaoDocumento.VIGENTE:
      texto = aplicarEstiloArquivado(row.arquivado, 'Vigente');
      strongColor = 'green';
      lightColor = '#dcedc8';
      paddingSize = '0.2rem 1.5rem';
      break;
    default:
      texto = '';
  }
  return (
    <span
      style={{
        backgroundColor: lightColor,
        color: strongColor,
        fontWeight: 'bold',
        fontSize: '13px',
        borderRadius: '20px',
        display: 'flex',
        height: '1.5rem',
        width: '9rem',
        alignItems: 'center',
        justifyContent: 'center',
        padding: paddingSize,
      }}
    >
      <span style={{ width: '100%', textAlign: 'center' }}>{texto}</span>
    </span>
  );
}

export function renderDataCriacao(row) {
  return row.criadoEm
    ? aplicarEstiloArquivado(row.arquivado, format(parseISO(row.criadoEm, new Date()), 'dd/MM/yyyy'))
    : '';
}

export function aplicarEstiloArquivado(arquivado, field, isMobile = false) {
  if (arquivado) {
    return (
      <span
        style={{
          fontStyle: 'italic',
          opacity: '0.8',
          wordBreak: 'break-word',
          maxWidth: isMobile ? '70%' : '100%',
          textAlign: isMobile ? 'end' : 'start',
        }}
      >
        {field}
      </span>
    );
  }
  return (
    <span
      style={{
        wordBreak: 'break-word',
        maxWidth: isMobile ? '70%' : '100%',
        textAlign: isMobile ? 'end' : 'start',
      }}
    >
      {field}
    </span>
  );
}

export function montarRsqlPesquisaDocumentos(valorPesquisa, filtroAvancado, exibirArquivados) {
  filtroAvancado = filtroAvancado || '';
  const enumStatus = formatarParaPesquisarTiposEnumerados(valorPesquisa);

  let urlArquivados = ``;
  if (!exibirArquivados) {
    urlArquivados = `;arquivado==${false}`;
  }

  let urlFiltroAvancado = '';
  if (filtroAvancado) {
    urlFiltroAvancado += `;${filtroAvancado}`;
  }
  let result = `?query=(identificacao=contains="*${valorPesquisa}*",pessoaNome=contains="*${valorPesquisa}*",status=contains="*${enumStatus}*")${urlArquivados}${urlFiltroAvancado}`;

  return result;
}

export function buscarMensagemExcluirDocumento(registroSelecionado) {
  let mensagem = `Tem certeza que deseja excluir o documento ${registroSelecionado.identificacao}?
	Este é um processo irreversível!`;

  return mensagem;
}
