import { useEffect } from 'react';
import useStateCallback from '../../../Common/Hooks/useStateCallback';
import { renderizarValidacao } from '../../../Common/TratamentoDeErro/validacoesDeCampos';
import Col from '../../Col';
import InputDouble from '../InputDouble';
import { FiDivide, FiX } from 'react-icons/fi';
import './Styles/index.css';

export const tipos = {
	MULTIPLICACAO: 'MULTIPLICACAO',
	DIVISAO: 'DIVISAO',
};

const inputStyle = {
	width: 'calc(100% - 45px)',
	padding: '0px',
};

const alteraPercentualOuValor = {
	fontSize: '18px',
	height: '32px',
};

const styleDiv = {
	alignItems: 'flex-end',
};

export default function InputSelectMultiplicacaoOrDivisao(props) {
	const {
		id,
		label,
		name,
		value,
		valueOperacao,
		errors,
		colId,
		colClassName,
		col,
		sm,
		md,
		lg,
		xl,
		style,
		helpMessage,
		labelSize,
		obrigatorio,
		disabled,
		onChangeOperacao,
		onChange,
		colStyle,
	} = props;

	const [tipo, setTipo] = useStateCallback(tipos.MULTIPLICACAO);

	useEffect(() => {
		if (valueOperacao) setTipo(valueOperacao);
	}, []);

	useEffect(() => {
		if (valueOperacao) setTipo(valueOperacao);
	}, [value, valueOperacao]);

	function onChangeTipo(e) {
		if (tipo === tipos.MULTIPLICACAO) {
			setTipo(tipos.DIVISAO, () => onChangeOperacao(tipos.DIVISAO));
		} else {
			setTipo(tipos.MULTIPLICACAO, () => onChangeOperacao(tipos.MULTIPLICACAO));
		}
	}

	function onChangeFatorConversao(e) {
		const valor = e.target.value ?? 0;
		onChange(valor, 0);
	}

	return (
		<Col id={colId} className={colClassName} col={col} sm={sm} md={md} lg={lg} xl={xl} style={colStyle}>
			<div className="p-inputgroup" style={styleDiv}>
				<InputDouble
					id={id}
					colStyle={inputStyle}
					name={name}
					obrigatorio={obrigatorio}
					label={label}
					labelSize={labelSize}
					value={value}
					onChange={onChangeFatorConversao}
					style={style}
					helpMessage={helpMessage}
					touched
					allowNegative={false}
					disabled={disabled}
					decimalScale={4}
					title={tipo === tipos.MULTIPLICACAO ? 'Multiplicação' : 'Divisão'}
					className="input-fator-conversao"
					placeholder="0,0000"
					autoComplete="off"
				/>
				<span
					color="secondary"
					onClick={disabled ? null : onChangeTipo}
					style={style ? style : alteraPercentualOuValor}
					className="altera-multipliacacao-ou-divisao"
					title={`Clique aqui para alternar a operação para ${
						tipo === tipos.MULTIPLICACAO ? 'divisão' : 'multiplicação'
					}`}
				>
					<>
						{tipo === tipos.MULTIPLICACAO ? (
							<span className="altera-multipliacacao-ou-divisao-text">{<FiX />}</span>
						) : (
							<span className="altera-multipliacacao-ou-divisao-text">{<FiDivide />}</span>
						)}
						<i className="fa fa-refresh" aria-hidden="true" />
					</>
				</span>
			</div>
			{renderizarValidacao(errors, errors ? true : false)}
		</Col>
	);
}
