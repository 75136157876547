import { useEffect, useState } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';
import { FaDownload } from 'react-icons/fa';
import { GESTOR_SERVICE, LINKS, mensagensDeValidacao } from 'Common';
import {
	AutoProgressBar,
	ButtonCancelar,
	ButtonSalvar,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	Message,
	Modal,
	ToastTypes,
	estadosBotaoCancelar,
	notify,
} from 'components';
import {
	asyncGetConfiguracoesServicoLocal,
	asyncGetImpressorasServicoLocal,
	asyncPostConfiguracoesServicoLocal,
} from './Requests';
import { converterConfiguracoesToApi, converterConfiguracoesToForm } from './util/configuracoesConverter';

const initialvalues = {
	modeloBalanca: 'Nenhum',
	portaBalanca: 'Nenhuma',
	velocidadeBalanca: 2400,
	tamanhoBalanca: 8,
	stopBitsBalanca: 1,
	paridadeBalanca: 'Nenhum(None)',
	mostrarPreviewImpressao: false,
	impressoraImpressao: 'Padrão do Windows',
	impressoraEtiqueta: 'Padrão do Windows',
};

function ModalConfiguracoesLocaisImpl({ visible, onHide, isMobile }) {
	const { values, dirty, isValid, setFieldValue, resetForm, handleReset, validateForm } = useFormikContext();

	const [servicoLocalRodando, setServicoLocalRodando] = useState(false);
	const [optionsImpresoras, setOptionsImpresoras] = useState([]);

	useEffect(async () => {
		await validateLocalService();

		asyncGetConfiguracoesServicoLocal(({ data: configuracoes }) => {
			resetForm({ values: converterConfiguracoesToForm(configuracoes) });
		});

		asyncGetImpressorasServicoLocal(({ data: impressoras }) => {
			const options = impressoras.map((registro) => ({ label: registro.impressora, value: registro.impressora }));
			setOptionsImpresoras(options);
		});
	}, []);

	async function validateLocalService() {
		setServicoLocalRodando((await GESTOR_SERVICE.isRunning()) && (await GESTOR_SERVICE.validateVersion()));
	}

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			onHide();
		}
	}

	async function handleClickSalvar() {
		await validateForm();

		if (isValid) {
			const dadosFormulario = converterConfiguracoesToApi(values);
			asyncPostConfiguracoesServicoLocal(dadosFormulario, () => {
				notify('Configuração salva com sucesso', ToastTypes.SUCCESS);
				onHide();
			});
		}
	}

	function handleChangeImpressora(event) {
		setFieldValue('impressoraEtiqueta', event.value);
	}

	function renderContentMessage() {
		return (
			<>
				<div style={{ marginBottom: '7px' }}>
					Este painel de configuração destina-se ao Msys Gestor Service que não está em execução na sua máquina ou está
					desatualizado. Para instalá-lo ou atualizá-lo faça o download no link abaixo e efetue a instalação.
				</div>
				<span style={{ cursor: 'pointer' }}>
					<FaDownload size="15px" style={{ marginRight: '5px' }} />
					<span onClick={() => downloadInstalador()} style={{ marginBottom: '5px' }}>
						<b>Baixar Msys Gestor Service</b>
					</span>
				</span>
			</>
		);
	}

	async function downloadInstalador() {
		window.open(LINKS.DOWNLOAD_MSYSGESTOR_SERVICO, '_self');
		onHide();
	}

	return (
		<Modal
			header="Configurações locais"
			visible={visible}
			onHide={onHide}
			styleModal={{
				maxWidth: '580px',
			}}
			isMobile={isMobile}
		>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							paddingBottom: '10px',
							flexWrap: 'wrap',
						}}
					>
						<ButtonCancelar
							hidden={values.id && !dirty}
							estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={handleClickCancelar}
						/>
						<ButtonSalvar disabled={!dirty} onClick={handleClickSalvar} />
					</div>
					<Message
						style={{ marginBottom: '7px' }}
						severity="info"
						text={renderContentMessage()}
						hidden={servicoLocalRodando}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Dropdown}
							obrigatorio
							label="Impressora"
							name="impressoraEtiqueta"
							options={optionsImpresoras}
							onChange={handleChangeImpressora}
							value={values?.impressoraEtiqueta}
							showClear={false}
							disabled={!servicoLocalRodando}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalConfiguracoesLocaisFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (!props.values) {
			return initialvalues;
		}
		return null;
	},

	validate(values) {
		const errors = {};
		if (!values.impressoraEtiqueta) {
			errors.impressoraEtiqueta = mensagensDeValidacao.OBRIGATORIO;
		}
		return errors;
	},
	handleSubmit: () => {},
})(ModalConfiguracoesLocaisImpl);

export const ModalConfiguracoesLocais = ModalConfiguracoesLocaisFormik;
