import { Col } from "components";
import NumeroCard from "./components/NumeroCard";
import { SituacaoCard } from "./components/SituacaoCard";

export function NumeroAndSituacao({ isMobile }) {
  return (
    <Col
      sm="12"
      md="4"
      lg="4"
      xl="4"
      style={{
        padding: "0px",
        width: "100%",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <NumeroCard isMobile={isMobile} />
      <SituacaoCard isMobile={isMobile} />
    </Col>
  );
}
