import { useEffect, useState } from 'react';
import { Field, withFormik } from 'formik';
import { Modal, Form, FormActions, FormContent, Grid, InputField } from '../../../../../../components';
import { mensagensDeValidacao } from '../../../../../../Common';

import { asyncGetmodelosNcm } from './Requests';

import './Styles/index.css';
import ModelosCadastrados from './components/ModelosCadastrados';
import ActionsButtons from './components/ActionsButtons';

const initialValue = {
	nome: '',
};

function ModalNovoModeloNcmView(props) {
	const { visible, onHide, values, setFieldValue, tributacoes, informacoesPermissoes } = props;
	const { nome } = values;

	const [modelos, setModelos] = useState([]);

	useEffect(() => {
		asyncGetmodelosNcm('', ({ data: modelos }) => {
			setModelos(modelos);
		});
	}, []);

	return (
		<Modal
			header="Salvar modelo de tributação"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1200px' }}
			showCloseIcon={true}
			closeOnEsc={false}
		>
			<Form className="card-default screen-max-width">
				<FormActions>
					<ActionsButtons
						modelos={modelos}
						setModelos={setModelos}
						nome={nome}
						tributacoes={tributacoes}
						{...props}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Nome"
							name="nome"
							value={nome}
							obrigatorio
							onChange={event => setFieldValue('nome', event.target.value)}
							size={120}
							{...informacoesPermissoes}
						/>
					</Grid>
					<ModelosCadastrados modelos={modelos} setModelos={setModelos} />
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalNovoModeloNcm = withFormik({
	enableReinitialize: false,
	validateOnChange: false,

	mapPropsToValues(props) {
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (!values.nome) {
			errors.nome = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalNovoModeloNcmView);

export default ModalNovoModeloNcm;
