import { formatarMonetario } from 'Common';
import { moduloUtilizado } from './constantes';

function validateProdutos(produtos, modulo, tabelaPreco) {
	const errorsProdutos = [];

	if (produtos.length > 0) {
		produtos.forEach((produto, index) => {
			if (
				!produto.produto &&
				[moduloUtilizado.ORCAMENTO, moduloUtilizado.PEDIDO_VENDA, moduloUtilizado.NOTA_FISCAL].includes(modulo)
			) {
				errorsProdutos[index] = {
					...errorsProdutos[index],
					produto: 'Produto é obrigatório',
				};
			} else {
				if (produto.produto) {
					if (!produto.quantidade) {
						errorsProdutos[index] = {
							...errorsProdutos[index],
							quantidade: 'Quantidade inválida',
						};
					}

					if (!produto.valor) {
						errorsProdutos[index] = {
							...errorsProdutos[index],
							valor: 'Valor inválido',
						};
					}

					if (produto.desconto === null || produto.desconto === undefined) {
						errorsProdutos[index] = {
							...errorsProdutos[index],
							desconto: 'Desc. inválido',
						};
					} else {
						const totalValor = produto.valor * produto.quantidade + produto.frete + produto.seguro + produto.acessorias;
						if (produto.desconto > totalValor) {
							errorsProdutos[index] = {
								...errorsProdutos[index],
								desconto: 'Desc. inválido',
							};
						}

						if (
							produto.descontoMaximoPercentual &&
							produto.desconto > totalValor * (produto.descontoMaximoPercentual / 100)
						) {
							errorsProdutos[index] = {
								...errorsProdutos[index],
								desconto: 'Desc. inválido',
							};
						}
					}

					if (modulo !== moduloUtilizado.ORCAMENTO) {
						if (!produto.unidadeMedida) {
							errorsProdutos[index] = {
								...errorsProdutos[index],
								produto: 'Unidade de medida é obrigatória.',
							};
						}
						if (!produto.ncm) {
							errorsProdutos[index] = {
								...errorsProdutos[index],
								produto: 'NCM é obrigatória.',
							};
						}
						if (!produto.cfop) {
							errorsProdutos[index] = {
								...errorsProdutos[index],
								produto: 'CFOP é obrigatória.',
							};
						}
						if (!produto.operacaoFiscal) {
							errorsProdutos[index] = {
								...errorsProdutos[index],
								produto: 'Operação Fiscal é obrigatória.',
							};
						}
					}

					if (tabelaPreco) {
						const valorAntesAlteracao = getValorAntesAlteracao(produto);
						if (!tabelaPreco?.registro?.editarPrecoAbaixoValorTabela && produto.alterouValorManualmente) {
							if (produto.valor < valorAntesAlteracao) {
								errorsProdutos[index] = {
									...errorsProdutos[index],
									valor: `Não é permitido alterar o valor abaixo de R$ ${formatarMonetario(valorAntesAlteracao)}`,
								};
							}
						}

						if (!tabelaPreco?.registro?.editarPrecoAcimaValorTabela && produto.alterouValorManualmente) {
							if (produto.valor > valorAntesAlteracao) {
								errorsProdutos[index] = {
									...errorsProdutos[index],
									valor: `Não é permitido alterar o valor acima de R$ ${formatarMonetario(valorAntesAlteracao)}`,
								};
							}
						}
					}
				}
			}
		});
	} else {
		errorsProdutos[0] = {
			...errorsProdutos[0],
			produto: 'Informa pelo menos um produto',
		};
	}

	return errorsProdutos.length > 0 ? errorsProdutos : [];
}

function getValorAntesAlteracao(produto) {
	let valor = produto.valorOriginal;

	if (produto.adicionalTabelaPreco && produto.adicionalTabelaPreco.percentual > 0) {
		if (produto.adicionalTabelaPreco.tipo === 'DESCONTO') {
			valor -= valor * (produto.adicionalTabelaPreco.percentual / 100);
		} else {
			valor += valor * (produto.adicionalTabelaPreco.percentual / 100);
		}
	}

	return parseFloat(valor.toFixed(2));
}

export { validateProdutos };
