import { tipoCampos } from '../../../../components/PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancado = [
	{ label: 'Data', name: 'dataHora', type: tipoCampos.DATE },
	{
		label: 'Tipo',
		name: 'tipo',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Saída', value: 'SAIDA' },
			{ label: 'Entrada', value: 'ENTRADA' },
		],
	},
	{ label: 'Quantidade', name: 'quantidade', type: tipoCampos.DECIMAL },
	{ label: 'Custo', name: 'custo', type: tipoCampos.DECIMAL },
	{ label: 'Observação', name: 'observacao', type: tipoCampos.STRING },
];

export const tipoMovimentacao = {
	ENTRADA: 'ENTRADA',
	SAIDA: 'SAIDA',
};

export const tipoSaldo = {
	ESTOQUE: 'ESTOQUE',
	RESERVADO: 'RESERVADO',
};

export const tipoSaldoOptions = [
	{ label: 'Estoque', value: 'ESTOQUE' },
	{ label: 'Reservado', value: 'RESERVADO' },
];

export const TipoMovimentacaoDocumento = {
	VENDA: 'VENDA',
	DEVOLUCAO_VENDA: 'DEVOLUCAO_VENDA',
	NFCE: 'NFCE',
	NFE: 'NFE',
	MOVIMENTACAO_MANUAL: 'MOVIMENTACAO_MANUAL',
	NOTA_ENTRADA: 'NOTA_ENTRADA',
	ORDEM_SERVICO: 'ORDEM_SERVICO',
};
