export function converterModeloNcmParaApi(cabecalho, tributacoes) {
	return {
		descricao: cabecalho.nome,
		tributacoes: converterTributacoesParaApi(tributacoes),
	};
}

function converterTributacoesParaApi(tributacoes) {
	let listaTributacoes = tributacoes.map(tributacao => {
		return {
			idOperacao: tributacao.operacaoFiscal?.value,
			idTributacao: tributacao.tributacaoEstadual?.value,
		};
	});
	return listaTributacoes;
}
