import {
  ButtonEditarTable,
  ButtonExcluirTable,
  NenhumRegistroEncontrado,
} from "components";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

export function ListagemTributacaoMunicipio(props) {
  const {
    values,
    setSelectTributacaoMunicipio,
    setVisibleModal,
    excluirTributacaoMunicipio,
    validacaoNovaTributacao,
  } = props;

  function renderOpcoes(row) {
    return (
      <div style={{ display: "flex" }}>
        <ButtonEditarTable onClick={() => editarTributacao(row)} />
        <ButtonExcluirTable
          onClick={() => excluirTributacaoMunicipio(row)}
          disabled={validacaoNovaTributacao}
        />
      </div>
    );
  }

  function editarTributacao(tributacao) {
    if (tributacao.id || tributacao.idTemporario) {
      setSelectTributacaoMunicipio(tributacao);
      setVisibleModal(true);
    }
  }

  return (
    <DataTable
      className="table"
      rowClassName="table-row"
      cellClassName="table-row-cell"
      responsive
      value={values}
      emptyMessage={<NenhumRegistroEncontrado />}
    >
      <Column
        field="municipio.registro.nome"
        header="Município"
        body={(row) => row.municipio.label}
        sortable
        style={{ width: "23rem" }}
      />
      <Column
        field="issBase"
        header="Base ISS"
        body={(row) => row.issBase + " %"}
        sortable
        style={{ width: "12rem" }}
      />
      <Column
        field="issPercentual"
        header="ISS"
        body={(row) => row.issPercentual + " %"}
        sortable        
        style={{ width: "12rem" }}
      />
      <Column
        className="step-listagem-acoes"
        header="Ações"
        body={(row) => renderOpcoes(row)}
        style={{ width: "3rem" }}
      />
    </DataTable>
  );
}
