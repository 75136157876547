import { styleStatus } from './styles';
import { styleName } from './styles';

export default function DocumentoStatus({ backgroundColor, color, name, description }) {
	return (
		<div title={description} style={{ ...styleStatus, backgroundColor, color }}>
			<div style={styleName}>{name}</div>
		</div>
	);
}
