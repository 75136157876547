import { construirUrl, estadosCadastro, permissoes, recursos, services, usuarioPossuiPermissao } from 'Common';
import {
	Col,
	confirm,
	DateInterval,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputSearch,
	PesquisaAvancada,
	tipoCampos,
} from 'components';
import { ModalDetalhesPix } from 'components/ModalDetalhesPix';
import { format, formatISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { FaCheckCircle, FaEquals, FaExclamationCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { readFormasPagamento } from 'views/cadastros/CondicaoPagamento/Requests';
import { Financas } from '../../Util/constantes';
import montarMensagemExclusaoTitulos from '../Util/functions';
import CardTotalizadorListagem from '../components/CardTotalizadorListagem';
import ModalQuitacaoTitulos from '../components/ModalQuitacaoTitulos';
import ModalReparcelamentoTitulos from '../components/ModalReparcelamentoTitulos';
import {
	asyncDeleteContaReceber,
	asyncDeleteContaReceberProximasEmAberto,
	asyncDeleteContaReceberTodasEmAberto,
	asyncGetCategoriasReceita,
	asyncGetContaReceber,
	asyncGetContasReceber,
	asyncGetTotalizadoresCards,
} from './Requests';
import { optionsFiltroAvancadoContasReceber } from './Util/constantes';
import { ActionButtons } from './components/ActionButtons';
import ModalContaReceber from './components/ModalContaReceber';
import { ModalExclusaoContasReceber, tiposExclusoesContasReceber } from './components/ModalExclusaoContasReceber';
import ModalRecebimentoTable from './components/ModalRecebimentoTable';
import TabelaContasReceber from './components/TabelaContasReceber';

function ContasReceber(props) {
	const { location } = props;

	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		size,
		setSize,
		totalRecords,
		setTotalRecords,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		selectedCard,
		setSelectedCard,
	} = useContextPesquisa();

	const [exibirModalContaReceber, setExibirModalContaReceber] = useState(false);
	const [exibirModalExclusao, setExibirModalExclusao] = useState(false);
	const [exibirModalConfirmarRecebimento, setExibirModalConfirmarRecebimento] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [valorCard, setValorCard] = useState('');
	const [optionsFiltroAvancado, setOptionsFiltroAvancado] = useState(optionsFiltroAvancadoContasReceber);
	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
	const [exibirModalDetalhesPix, setExibirModalDetalhesPix] = useState(false);
	const [filtroData, setFiltroData] = useState(
		`vencimento>=${formatISO(interval.dataInicial, {
			representation: 'date',
		})};vencimento<=${formatISO(interval.dataFinal, {
			representation: 'date',
		})}`
	);
	const [cards, setCards] = useState({
		recebido: 0,
		aReceber: 0,
		vencidas: 0,
		totalAReceber: 0,
	});
	const [registros, setRegistros] = useState([]);
	const [firstRender, setFirstRender] = useState(true);
	const [openModalCobranca, setOpenModalCobranca] = useState(false);
	const [exibirModalQuitacaoTitulos, setExibirModalQuitacaoTitulos] = useState(false);
	const [exibirModalReparcelamentoTitulos, setExibirModalReparcelamentoTitulos] = useState(false);
	const [documentosSelecionados, setDocumentosSelecionados] = useState([]);

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EXCLUIR));

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: estadosCadastro.EDICAO,
	};

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();

		if (location && location.state) {
			handleChangeInterval({
				dataInicial: location.state.dataInicial,
				dataFinal: location.state.dataFinal,
			});
		}

		setTimeout(() => {
			document.getElementById('ContasReceberInputSearch')?.focus();
		}, 500);
	}, []);

	useUpdateEffect(() => {
		pesquisarCallback();
	}, [page, rows, sortOrder, sortField, filtroAvancado, interval, selectedCard]);

	function handleChangeInterval(interval) {
		setInterval(interval);
		setFiltroData(
			`vencimento>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};vencimento<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	async function pesquisar() {
		const filtro = buscarFiltro();

		let campoOrdenacao = sortField;
		let sentidoOrdenacao = sortOrder;
		if (firstRender) {
			setSortField('vencimento');
			setSortOrder(0);
			campoOrdenacao = 'vencimento';
			sentidoOrdenacao = 0;
		}

		const url = construirUrl(
			`${services.GESTOR}/v1/contas_receber/resumo`,
			filtro || '?',
			size,
			page,
			sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
		);

		asyncGetContasReceber(url, ({ data: contasReceber }) => {
			setRegistros(contasReceber.content);
			setTotalRecords(contasReceber.totalElements);
			setFirstRender(false);
		});

		asyncGetTotalizadoresCards(interval, ({ data: totais }) => {
			setCards({
				recebido: totais.receitasRecebidas,
				aReceber: totais.receitasAReceber,
				vencidas: totais.receitasVencidas,
				totalAReceber: totais.receitasTotal,
			});
		});
	}

	function buscarFiltro() {
		let filtroRSQL = String('?query=(')
			.concat(`descricao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`identificador=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`pessoaCodigo=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`pessoaNome=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`categoriaNome=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`observacao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`valorAReceber=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`valor=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroData) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroData})`);
		}
		if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}
		if (valorCard) {
			filtroRSQL = filtroRSQL.concat(`;(${valorCard})`);
		}

		return filtroRSQL;
	}

	function onPesquisar() {
		pesquisarCallback();
	}

	function onPesquisarFiltroAvancado(e) {
		setFiltroAvancado(e);
		setLimparFiltroPesquisaAvancada(false);
	}

	async function fetchOptionsFiltroAvancado() {
		const options = [...optionsFiltroAvancado];

		function addOption(newOption) {
			let indexOption = 0;
			const hasOption = options.find((e, i) => {
				if (e.name === newOption.name) {
					indexOption = i;
					return true;
				}
				return false;
			});

			if (hasOption) {
				options.splice(indexOption, 1);
			}

			options.push(newOption);
		}

		const promises = [
			readFormasPagamento(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Forma pagamento',
						name: 'formaPagamentoId',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: option.descricao, value: option.id });
					});

					addOption(newOption);
				}
			}),

			asyncGetCategoriasReceita(({ data: catReceita }) => {
				const categorias = {
					label: 'Categoria de receitas',
					name: 'categoriaNome',
					type: tipoCampos.SELECT,
					optionSelect:
						catReceita && catReceita.content.map((categoria) => ({ label: categoria.nome, value: categoria.nome })),
				};
				addOption(categorias);
			}),
		];

		await Promise.all(promises).then(() => {
			setOptionsFiltroAvancado(
				options.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				})
			);
		});
	}

	function onHideModalContaReceber() {
		setExibirModalContaReceber(false);
		setRegistroSelecionado(null);
		pesquisar();
	}

	function handleSelectCardRecebidas(cardName) {
		pesquisarRSQLTotaisCard(cardName, ['RECEBIDA', 'PARCIALMENTE_RECEBIDA']);
	}

	function handleSelectCardAReceber(cardName) {
		pesquisarRSQLTotaisCard(cardName, ['PARCIALMENTE_RECEBIDA', 'NAO_RECEBIDA']);
	}

	function handleSelectCardVencidas(cardName) {
		pesquisarRSQLTotaisCard(cardName, new Date());
	}

	function pesquisarRSQLTotaisCard(cardName, valoresDoFiltro) {
		if (selectedCard === cardName) {
			setSelectedCard('');
			setValorCard('');
			setPage(0);
		} else if (cardName === 'cardVencidas') {
			const dataVencimento = format(valoresDoFiltro, 'yyyy-MM-dd');
			setSelectedCard(cardName);
			setValorCard(`vencimento<${dataVencimento};status!=RECEBIDA`);
		} else {
			setSelectedCard(cardName);
			setValorCard(`status==${valoresDoFiltro.join(' or status==')}`);
		}
	}

	async function handleEditItem(item) {
		await asyncGetContaReceber(item.id, ({ data: contaReceber }) => {
			setRegistroSelecionado({
				...contaReceber,
				vendaId: item.vendaId,
				nfeId: item.nfeId,
				nfceId: item.nfceId,
				ordemId: item.ordemId,
				nfseId: item.nfseId,
			});
			setExibirModalContaReceber(true);
		});
	}

	async function handleEfetuarRecebimentoItem(item) {
		if (item.temPix && item.status !== 'RECEBIDA') {
			await asyncGetContaReceber(item.id, ({ data: contaReceber }) => {
				setRegistroSelecionado(contaReceber);
				setExibirModalDetalhesPix(true);
			});
		} else {
			setExibirModalConfirmarRecebimento(true);
			setRegistroSelecionado(item);
		}
	}

	async function handleRemoveItem(item) {
		if (item.itemRepeticao) {
			setExibirModalExclusao(true);
			setRegistroSelecionado(item);
		} else {
			confirm('Confirmação', montarMensagemExclusaoTitulos(item.status, item.origem), async () => {
				await asyncDeleteContaReceber(item.id);
				pesquisar();
			});
		}
	}

	function onHideModalExclusao(tipoExclusao) {
		if (tipoExclusao) {
			if (tipoExclusao === tiposExclusoesContasReceber.APENAS_SELECIONADA) {
				asyncDeleteContaReceber(registroSelecionado.id, () => {
					setExibirModalExclusao(false);
					setRegistroSelecionado(null);
					pesquisar();
				});
			} else if (tipoExclusao === tiposExclusoesContasReceber.PROXIMAS_EM_ABERTO) {
				asyncDeleteContaReceberProximasEmAberto(registroSelecionado.id, () => {
					setExibirModalExclusao(false);
					setRegistroSelecionado(null);
					pesquisar();
				});
			} else {
				asyncDeleteContaReceberTodasEmAberto(registroSelecionado.id, () => {
					setExibirModalExclusao(false);
					setRegistroSelecionado(null);
					pesquisar();
				});
			}
		} else {
			setExibirModalExclusao(false);
			setRegistroSelecionado(null);
			pesquisar();
		}
	}

	function onHideModalConfirmarRecebimento(recebimentoConfirmado) {
		if (recebimentoConfirmado) {
			onPesquisar();
		}
		setExibirModalConfirmarRecebimento(false);
		setRegistroSelecionado(null);
	}

	async function onHideRecebimentoPix(registro) {
		onHideModalConfirmarRecebimento(false);
		setOpenModalCobranca(true);
		await asyncGetContaReceber(registro.id, ({ data: contaReceber }) => {
			setRegistroSelecionado({
				...contaReceber,
				vendaId: registro.vendaId,
				nfeId: registro.nfeId,
				nfceId: registro.nfceId,
				ordemId: registro.ordemId,
				nfseId: registro.nfseId,
			});

			setExibirModalDetalhesPix(true);
		});
	}

	function montarHeader() {
		return (
			<span
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<span>Contas a receber</span>
			</span>
		);
	}

	return (
		<>
			<Form header={montarHeader()}>
				<FormActions>
					<ActionButtons
						podeInserir={podeInserir}
						setExibirModalContaReceber={setExibirModalContaReceber}
						setRegistroSelecionado={setRegistroSelecionado}
						setExibirModalQuitacaoTitulos={setExibirModalQuitacaoTitulos}
						setExibirModalReparcelamentoTitulos={setExibirModalReparcelamentoTitulos}
						documentosSelecionados={documentosSelecionados}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<span style={{ padding: '12px' }}>
							<DateInterval interval={interval} onChange={handleChangeInterval} />
						</span>
						<InputSearch
							onPesquisar={onPesquisar}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
							removerEComercial={false}
							id="ContasReceberInputSearch"
						/>

						<span style={{ padding: '12px' }}>
							<PesquisaAvancada
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
								limparFiltro={limparFiltroPesquisaAvancada}
								onOpen={fetchOptionsFiltroAvancado}
							/>
						</span>
					</Grid>

					<Grid justifyBetween>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardAReceber"
								title="A receber no período"
								helpMessage="Soma dos valores a receber no período selecionado"
								icon={<FaCheckCircle size={22} color={Financas.cores.cinza} />}
								primaryColor={Financas.cores.cinza}
								value={cards.aReceber}
								selectable
								selected={selectedCard === 'cardAReceber'}
								onSelect={handleSelectCardAReceber}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardRecebidas"
								title="Recebidas no período"
								helpMessage="Soma dos valores recebidos no período selecionado"
								icon={<FaCheckCircle size={22} color={Financas.cores.verde} />}
								primaryColor={Financas.cores.verde}
								value={cards.recebido}
								selectable
								selected={selectedCard === 'cardRecebidas'}
								onSelect={handleSelectCardRecebidas}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardVencidas"
								title="Todas as vencidas"
								helpMessage="Soma dos valores de todas as contas a receber vencidas independente do período"
								icon={<FaExclamationCircle size={22} color={Financas.cores.vermelho} />}
								primaryColor={Financas.cores.vermelho}
								value={cards.vencidas}
								selectable
								selected={selectedCard === 'cardVencidas'}
								onSelect={handleSelectCardVencidas}
							/>
						</Col>

						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardTotalAReceber"
								title="Total no período"
								helpMessage="Total de recebimentos dentro do período selecionado"
								icon={<FaEquals size={22} color={Financas.cores.azul} />}
								primaryColor={Financas.cores.azul}
								value={cards.totalAReceber}
							/>
						</Col>
					</Grid>
					<Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
						<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
						<TabelaContasReceber
							registros={registros}
							totalRecords={totalRecords}
							rows={size}
							page={page}
							sortField={sortField}
							sortOrder={sortOrder}
							setSortField={(sortField) => setSortField(sortField)}
							setSortOrder={(sortOrder) => setSortOrder(sortOrder)}
							onEditItem={handleEditItem}
							onEfetuarRecebimentoItem={handleEfetuarRecebimentoItem}
							onRemoveItem={handleRemoveItem}
							onPageChange={(e) => {
								setSize(e.rows);
								setRows(e.rows);
								setPage(e.page);
							}}
							documentosSelecionados={documentosSelecionados}
							setDocumentosSelecionados={setDocumentosSelecionados}
						/>
					</Grid>
				</FormContent>
			</Form>
			<If test={exibirModalContaReceber}>
				<ModalContaReceber
					onNovoClick={() => setRegistroSelecionado(null)}
					visible={exibirModalContaReceber}
					onHide={onHideModalContaReceber}
					registroSelecionado={registroSelecionado}
				/>
			</If>
			<If test={exibirModalExclusao}>
				<ModalExclusaoContasReceber visible={exibirModalExclusao} onHide={onHideModalExclusao} />
			</If>
			<If test={exibirModalConfirmarRecebimento}>
				<ModalRecebimentoTable
					registroSelecionado={registroSelecionado}
					visible={exibirModalConfirmarRecebimento}
					onHide={onHideModalConfirmarRecebimento}
					onHideRecebimentoPix={onHideRecebimentoPix}
				/>
			</If>
			<If test={exibirModalDetalhesPix}>
				<ModalDetalhesPix
					visible={exibirModalDetalhesPix}
					pixId={registroSelecionado?.pix?.id}
					valor={registroSelecionado?.valor}
					openModalCobranca={openModalCobranca}
					onHide={() => {
						setOpenModalCobranca(false);
						setExibirModalDetalhesPix(false);
						pesquisar();
					}}
				/>
			</If>
			<If test={exibirModalQuitacaoTitulos}>
				<ModalQuitacaoTitulos
					isRecebimento
					titulos={documentosSelecionados}
					visible={exibirModalQuitacaoTitulos}
					onHide={(resetar) => {
						setDocumentosSelecionados([]);
						setExibirModalQuitacaoTitulos(false);
						if (resetar) {
							pesquisar();
						}
					}}
					informacoesPermissoes={informacoesPermissoes}
					pathUrl="contas_receber"
				/>
			</If>
			<If test={exibirModalReparcelamentoTitulos}>
				<ModalReparcelamentoTitulos
					origem="CONTAS_RECEBER"
					documentosSelecionados={documentosSelecionados}
					setDocumentosSelecionados={setDocumentosSelecionados}
					visible={exibirModalReparcelamentoTitulos}
					onHide={(resetar) => {
						setDocumentosSelecionados([]);
						setExibirModalReparcelamentoTitulos(false);
						if (resetar) {
							pesquisar();
						}
					}}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(ContasReceber);
