import { Field, useFormikContext, withFormik } from 'formik';
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';

import { copiarObjeto, manterApenasNumeros, mensagensDeValidacao } from 'Common';
import { Dropdown, FormActions, FormContent, Grid, If, InputDouble, InputField, InputMask, Modal } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { INITIAL_VALUE_VALE_PEDAGIO, MDFE_TIPO_VALE_PEDAGIO_OPTIONS } from 'views/transporte/MDFe/Form/Util/constantes';
import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';

import { ActionButtons } from './components/ActionButtons';

function ModalValePedagioImpl({
	visible,
	indexRegistro,
	valePedagioList,
	isNovoRegistro,
	setFieldValueMDFe,
	onCloseModal,
}) {
	const { errors, values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, isSituacaoFinalMDFe } = useContextMDFe();

	function onChangeTipoPessoa(e) {
		setFieldValue('tipoPessoa', e.value);

		if (e.value === TIPO_PESSOA.JURIDICA) {
			setFieldValue('cpf', null);
		} else {
			setFieldValue('cnpj', null);
		}
	}

	return (
		<Modal
			header={indexRegistro ? 'Cadastro de vale pedágio' : 'Dados do vale pedágio'}
			visible={visible}
			onHide={onCloseModal}
		>
			<FormActions>
				<ActionButtons
					setFieldValueMDFe={setFieldValueMDFe}
					indexRegistro={indexRegistro}
					onCloseModal={onCloseModal}
					valePedagioList={valePedagioList}
					isNovoRegistro={isNovoRegistro}
				/>
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputMask}
						mask="00.000.000/0000-00"
						placeholder="  .   .   /    -  "
						label="CNPJ fornecedora"
						name="fornecedoraCnpj"
						errors={errors.fornecedoraCnpj}
						obrigatorio
						onChange={(e) => setFieldValue('fornecedoraCnpj', e.target.value)}
						disabled={isSituacaoFinalMDFe}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputField}
						label="Número comprovante"
						name="numeroComprovante"
						size={20}
						errors={errors.numeroComprovante}
						obrigatorio
						onChange={(e) => setFieldValue('numeroComprovante', e.target.value)}
						disabled={isSituacaoFinalMDFe}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						label="Tipo vale pedágio"
						name="valePedagioTipo"
						showClear={false}
						onChange={(e) => setFieldValue('valePedagioTipo', e.value)}
						options={MDFE_TIPO_VALE_PEDAGIO_OPTIONS}
						disabled={isSituacaoFinalMDFe}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputDouble}
						label="Valor"
						name="valePedagioValor"
						prefix="R$ "
						decimalScale={2}
						allowNegative={false}
						size={15}
						errors={errors.valePedagioValor}
						obrigatorio
						onChange={(e) => setFieldValue('valePedagioValor', e.target.value)}
						disabled={isSituacaoFinalMDFe}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						label="Tipo pessoa"
						name="tipoPessoa"
						showClear={false}
						onChange={(e) => onChangeTipoPessoa(e)}
						options={[
							{ label: 'Física', value: 'FISICA' },
							{ label: 'Jurídica', value: 'JURIDICA' },
						]}
						disabled={isSituacaoFinalMDFe}
						{...informacoesPermissoes}
					/>
					<If test={values.tipoPessoa === TIPO_PESSOA.JURIDICA}>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputMask}
							mask="00.000.000/0000-00"
							placeholder="  .   .   /    -  "
							label="CNPJ"
							name="cnpj"
							errors={errors.cnpj}
							obrigatorio
							onChange={(e) => setFieldValue('cnpj', e.target.value)}
							disabled={isSituacaoFinalMDFe}
							{...informacoesPermissoes}
						/>
					</If>
					<If test={values.tipoPessoa === TIPO_PESSOA.FISICA}>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputMask}
							mask="000.000.000-00"
							placeholder="   .   .   -  "
							label="CPF "
							name="cpf"
							errors={errors.cnpj}
							obrigatorio
							onChange={(e) => setFieldValue('cpf', e.target.value)}
							disabled={isSituacaoFinalMDFe}
							{...informacoesPermissoes}
						/>
					</If>
				</Grid>
			</FormContent>
		</Modal>
	);
}

const ModalValePedagio = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.valePedagio) {
			return copiarObjeto(props.valePedagio);
		}

		return INITIAL_VALUE_VALE_PEDAGIO;
	},

	validate(values) {
		const errors = {};

		if (!values.fornecedoraCnpj) {
			errors.fornecedoraCnpj = mensagensDeValidacao.OBRIGATORIO;
		} else if (!isValidCNPJ(manterApenasNumeros(values.fornecedoraCnpj))) {
			errors.fornecedoraCnpj = mensagensDeValidacao.CNPJ_INVALIDO;
		}

		if (!values.numeroComprovante) {
			errors.numeroComprovante = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.valePedagioValor) {
			errors.valePedagioValor = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.tipoPessoa === TIPO_PESSOA.JURIDICA) {
			if (!values.cnpj) {
				errors.cnpj = mensagensDeValidacao.OBRIGATORIO;
			} else if (!isValidCNPJ(manterApenasNumeros(values.cnpj))) {
				errors.cnpj = mensagensDeValidacao.CNPJ_INVALIDO;
			}
		} else if (!values.cpf) {
			errors.cpf = mensagensDeValidacao.OBRIGATORIO;
		} else if (!isValidCPF(manterApenasNumeros(values.cpf))) {
			errors.cpf = mensagensDeValidacao.CPF_INVALIDO;
		}

		return errors;
	},
	handleSubmit: () => {},
})(ModalValePedagioImpl);

export { ModalValePedagio };
