import { Modal } from '../../../../../../../../components';
import FormModalTransportadoraForm from './components/FormModalTransportadoraForm';

export default function ModalDadoTransportadora({ onHide, onSave, visible, valuesDadosTransportadora, isFinalizado }) {
	return (
		<Modal
			header="Dados da transportadora"
			onHide={onHide}
			visible={visible}
			styleModal={{
				minWidth: '60%',
				height: 'auto',
			}}
		>
			<FormModalTransportadoraForm
				values={valuesDadosTransportadora}
				onSave={(e) => onSave(e)}
				onHide={onHide}
				isFinalizado={isFinalizado}
			/>
		</Modal>
	);
}
