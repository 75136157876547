import { exibirToast, get, post, services } from '../../../../../../Common';

export async function buscarCategoriaFavoritaReceita(urlModuloUtilizado, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/${urlModuloUtilizado}/relacoes/categorias/receitas?query=favoritaReceita=="true";ativa=="true"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncEstornarParcelaRecebida(tipo, idVenda, parcelaId, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/${
			tipo === 'NOTA_FISCAL' ? 'nfes' : 'pedidos_venda'
		}/${idVenda}/parcelas/${parcelaId}/estornar`,
		null,
		null,
		exibirToast(onSuccess, 'Recebimento estornado com sucesso'),
		onError
	);
}

export async function asyncEmitirBoletos(convenioId, contaReceberIds, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/boletos/convenios/${convenioId}/emitir`,
		contaReceberIds,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncGerarCobrancas(contaId, contaReceberIds, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/pix/cobranca/contas/${contaId}/gerar`,
		contaReceberIds,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetContaFavoritaReceita(emiteBoletos, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_receber/relacoes/contas?query=situacao=="ATIVA"${emiteBoletos === true ? ';emitirBoletos=="true"' : ';recebePix=="true"'}`,
		null,
		onSuccess,
		onError,
		false
	);
}


export async function asyncPostImprimirBoletos(boletos, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/boletos/imprimir`,
		boletos,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncPostImprimirCobrancas(cobrancas, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/pix/cobranca/imprimir`,
		cobrancas,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}
