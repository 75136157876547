import React from 'react';
import 'chartjs-plugin-datalabels';
import { FaList } from 'react-icons/fa';
import Grid from '../../../../../components/Grid';

export default function NenhumaInformacaoEncontrada() {
    return (
        <div style={{ padding: '50px 0px', width: '100%' }}>
            <Grid justifyCenter style={{ opacity: '0.5', margin: '0px' }}>
                <FaList size={32} style={{ margin: '10px' }} />
            </Grid>
            <Grid justifyCenter style={{ margin: '0px' }}>
                <span style={{ fontSize: '14px', textAlign: 'center', opacity: '0.5' }}>
                    Nenhuma informação encontrada
                </span>
            </Grid>
        </div>
    )
}

