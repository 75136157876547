import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import { Col, Grid, InputField, SelectToggle } from 'components';
import { OPTIONS_PAGINA_CAMPOS } from '../../Util/constantes';
import { useContextEtiquetaConfiguracao } from '../../Context';

function InformacoesPrincipaisImpl(props) {
	const { informacoesPermissoes, isMobile } = props;
	const { values } = useFormikContext();
	const { paginaOuCampos, setPaginaOuCampos } = useContextEtiquetaConfiguracao();

	const isMedidasEtiquetasPreenchidas = values.alturaEtiqueta > 0 && values.larguraEtiqueta > 0;

	useEffect(() => {
		if (values.tipo) {
			setPaginaOuCampos(values.tipo);
		}
	}, [values.tipo]);

	function onChangeTipo(e) {
		if (e.value) {
			setPaginaOuCampos(e.value);
		}
	}

	return (
		<Grid verticalAlignCenter style={{ display: 'flex', marginBottom: '1rem' }}>
			<Field
				className="step-etiqueta-configuracao-codigo"
				sm="12"
				md="2"
				lg="1"
				xl="1"
				component={InputField}
				label="Código"
				name="codigo"
				disabled
				{...informacoesPermissoes}
			/>
			<Field
				className="step-etiqueta-configuracao-descricao"
				sm="12"
				md="7"
				lg="5"
				xl="5"
				id="etiquetaDescricaoInputField"
				component={InputField}
				label="Descrição"
				name="descricao"
				{...informacoesPermissoes}
			/>
			<Col
				sm="12"
				md="3"
				lg="3"
				xl="2"
				style={{
					marginTop: '16.8px',
					display: 'flex',
					justifyContent: isMobile ? 'center' : 'flex-start',
				}}
			>
				<SelectToggle
					className="step-etiqueta-configuracao-pagina-ou-campos"
					name="tipo"
					value={paginaOuCampos}
					options={OPTIONS_PAGINA_CAMPOS}
					onChange={onChangeTipo}
					disabled={!isMedidasEtiquetasPreenchidas}
				/>
			</Col>
		</Grid>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export const InformacoesPrincipais = connect(mapStateToProps)(InformacoesPrincipaisImpl);
