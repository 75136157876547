export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é o cadastro de tabela de preço.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-tabela-preco-campos-principais',
		content: (
			<div>
				<div>Nesta área são preenchidas as informações básicas da tabela, como por exemplo:</div>
				<br />
				<div>
					- O campo "Tabela promocional" permite marcar a tabela na categoria especial de promoções, a qual tem
					prioridade na formação do preço.
				</div>
				<div>
					- O campo "Acréscimo/desconto por condição" visa definir se a tabela funcionará com a aplicação percentual
					conforme as condições de pagamento configuradas.'
				</div>
			</div>
		),
	},
	{
		target: '.step-tabela-preco-filtrar-informacoes',
		content:
			'Com o campo "filtrar produtos", é possível filtrar os produtos presentes na tabela de preço, facilitando o uso e atualização das informações.',
	},
	{
		target: '.step-tabela-preco-importacao',
		content:
			'Através do botão "Importação" é possível importar produtos em lote para a tabela de preço, lembre-se que para utilizar essa opção a tabela deve estar previamente salva.',
	},
	{
		target: '.step-tabela-preco-alterar-lote',
		content:
			'Já com a opção "Alterar em lote" é possível atualizar os campos: Lucro %, preço de venda, comissão % e desconto máximo de todos os produtos presentes na tabela de preço.',
	},
	{
		target: '.step-tabela-preco-produtos',
		content:
			'Por fim, nessa área ficam os produtos vinculados a tabela de preço, é possível modificar os valores de cada item ou retirá-lo da lista.',
	},
];
