import { mensagensDeValidacao } from 'Common';
import {
	Button,
	ButtonCancelar,
	Col,
	FormActions,
	FormContent,
	Grid,
	Modal,
	TextArea,
	estadosBotaoCancelar,
} from 'components';
import { Field, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { validarFormulario } from '../../../../../Util';
import { asyncCancelarPedidoOrcamento } from '../../Requests';
import { Tipo } from '../../Util/constantes';

function ModalCancelarVenda(props) {
	const { valuesVenda, visible, onHide, urlVendaOuOrcamento, resetFormForm } = props;

	const { values, errors, setFieldValue, handleSubmit } = useFormikContext();

	const isCanceled = valuesVenda?.status === 'CANCELADA' ? true : false;

	async function onSalvar() {
		handleSubmit();

		if (await validarFormulario(props)) {
			asyncCancelarPedidoOrcamento(urlVendaOuOrcamento, valuesVenda.id, values.motivoCancelamento, () => {
				onHide();
				setTimeout(() => {
					resetFormForm({
						values: { ...valuesVenda, status: 'CANCELADA', motivoCancelamento: values.motivoCancelamento },
					});
				}, 200);
			});
		}
	}

	return (
		<Modal
			header={
				isCanceled
					? 'Motivo do cancelamento'
					: valuesVenda.tipo === Tipo.PEDIDO_VENDA
						? 'Cancelar pedido de venda'
						: 'Cancelar orçamento'
			}
			visible={visible}
			onHide={onHide}
		>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						obrigatorio={isCanceled ? false : true}
						component={TextArea}
						errors={errors.motivoCancelamento}
						touched={true}
						label={isCanceled ? null : 'Justificativa do cancelamento'}
						name="motivoCancelamento"
						disabled={isCanceled}
						onChange={(e) => setFieldValue('motivoCancelamento', e.target.value)}
						value={values.motivoCancelamento}
					/>
				</Grid>
			</FormContent>
			<FormActions>
				<Grid
					style={{
						display: 'flex',
						justifyContent: 'end',
						margin: 0,
					}}
				>
					<Col
						xs="12"
						sm="6"
						md="6"
						lg="4"
						xl="4"
						style={{
							padding: 0,
							margin: 0,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}
					>
						<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
						<Button
							icon="fa fa-paper-plane-o"
							disabled={isCanceled}
							type="submit"
							style={{ marginRight: '0px !important' }}
							label={valuesVenda.tipo === Tipo.PEDIDO_VENDA ? 'Cancelar pedido' : 'Cancelar orçamento'}
							color="danger"
							onClick={onSalvar}
						/>
					</Col>
				</Grid>
			</FormActions>
		</Modal>
	);
}

ModalCancelarVenda = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: true,

	mapPropsToValues(props) {
		return {
			motivoCancelamento: props.valuesVenda?.motivoCancelamento || '',
		};
	},

	validationSchema: Yup.object().shape({
		motivoCancelamento: Yup.string()
			.trim()
			.min(15, 'A descrição deve ter mais de 15 caracteres')
			.required(mensagensDeValidacao.OBRIGATORIO)
			.nullable(),
	}),
	handleSubmit: () => {},
})(ModalCancelarVenda);

export default ModalCancelarVenda;
