import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { format, parseISO } from 'date-fns';
import { useEffect, useReducer } from 'react';
import { atualizarUrl } from 'views/Util';
import { formatarPlaca } from 'views/cadastros/transporte/Veiculos/Util/function';
import { CardList, TYPE_VALUE } from './components/CardList';
import { CardsResultado } from './components/CardsResultado';
import { ChartDocumentosPorDia } from './components/ChartDocumentosPorDia';
import { ChartDocumentosUltimosDozeMeses } from './components/ChartDocumentosUltimosDozeMeses';
import { dataReducer } from './reducer';
import { addDataRankingEstados, addDataRankingMotoristas, addDataRankingVeiculos, setData } from './reducer/actions';
import {
	readDocumentosPorDia,
	readDocumentosUltimosDozeMeses,
	readRankingEstados,
	readRankingMotoristas,
	readRankingVeiculos,
	readResultadoPeriodo,
} from './requests';

const { Col, Button } = require('components');

function DashboardMDFe({ dataInicial, dataFinal, isMobile, history }) {
	const [
		{
			cardsResultado,
			rankingMotoristas,
			rankingVeiculos,
			rankingEstados,
			documentosPorDia,
			documentosUltimosDozeMeses,
			podeInserirMDFe,
			loading,
		},
		dispatch,
	] = useReducer(
		dataReducer,
		{
			cardsResultado: {},
			rankingMotoristas: {},
			rankingVeiculos: {},
			rankingEstados: {},
			documentosPorDia: {},
			documentosUltimosDozeMeses: {},
			podeInserirMDFe: false,
			loading: true,
		},
		(initialState) => ({
			...initialState,
			podeInserirMDFe: usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.INSERIR),
		})
	);

	const styleCards = isMobile ? { padding: '0.5em 0em', margin: '8px', flex: 'auto' } : { padding: '0.5em' };
	const styleContainerButton = {
		display: 'flex',
		justifyContent: isMobile ? 'center' : 'flex-start',
		padding: isMobile ? '15px 0 0 2px' : '0 0 0 2px',
	};
	const styleButton = {
		margin: isMobile ? '3px 5px' : '3px 7px',
		width: '185px',
	};

	useEffect(() => {
		fetchData();
	}, [dataInicial, dataFinal]);

	async function fetchData() {
		let newData = {
			loading: false,
		};
		const promises = [
			readResultadoPeriodo(dataInicial, dataFinal, ({ data }) => {
				const { periodoAtual, periodoAnterior } = data;
				const dataCards = {
					pendentes: {
						valor: periodoAtual.valorPendentes,
						evolucao: calculateEvolution(periodoAtual.valorPendentes, periodoAnterior.valorPendentes),
					},
					finalizados: {
						valor: periodoAtual.valorTransmitidos,
						evolucao: calculateEvolution(periodoAtual.valorTransmitidos, periodoAnterior.valorTransmitidos),
					},
					encerrados: {
						valor: periodoAtual.valorEncerrados,
						evolucao: calculateEvolution(periodoAtual.valorEncerrados, periodoAnterior.valorEncerrados),
					},
					cancelados: {
						valor: periodoAtual.valorCancelados,
						evolucao: calculateEvolution(periodoAtual.valorCancelados, periodoAnterior.valorCancelados),
					},
				};

				newData = {
					...newData,
					cardsResultado: dataCards,
				};
			}),
			readRankingMotoristas(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingMotoristas: data,
				};
			}),
			readRankingVeiculos(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingVeiculos: data,
				};
			}),
			readRankingEstados(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingEstados: data,
				};
			}),
			readDocumentosPorDia(dataInicial, dataFinal, ({ data }) => {
				const dias = [];
				const pendentes = [];
				const transmitidos = [];
				const encerrados = [];
				const cancelados = [];

				data.forEach((day) => {
					dias.push(format(parseISO(day.data), 'dd/MM/yyyy'));
					pendentes.push(day.valorPendentes);
					transmitidos.push(day.valorTransmitidos);
					encerrados.push(day.valorEncerrados);
					cancelados.push(day.valorCancelados);
				});

				newData = {
					...newData,
					documentosPorDia: { dias, pendentes, transmitidos, encerrados, cancelados },
				};
			}),
			readDocumentosUltimosDozeMeses(({ data }) => {
				const meses = [];
				const pendentes = [];
				const transmitidos = [];
				const encerrados = [];
				const cancelados = [];

				data.forEach((day) => {
					meses.push(day.competencia);
					pendentes.push(day.valorPendentes);
					transmitidos.push(day.valorTransmitidos);
					encerrados.push(day.valorEncerrados);
					cancelados.push(day.valorCancelados);
				});

				newData = {
					...newData,
					documentosUltimosDozeMeses: {
						meses,
						pendentes,
						transmitidos,
						encerrados,
						cancelados,
					},
				};
			}),
		];

		await Promise.all(promises).then(() => dispatch(setData(newData)));
	}

	function handleClickNovaOrdem() {
		atualizarUrl(history, '/mdfes/cadastro');
	}

	function calculateEvolution(value, oldValue) {
		if (oldValue === 0) {
			return 0;
		}

		return ((value * 100) / oldValue - 100).toFixed(0);
	}

	async function handleClickVerMaisRankingMotoristas(page) {
		await readRankingMotoristas(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingMotoristas(data));
		});
	}

	async function handleClickVerMaisRankingVeiculos(page) {
		await readRankingVeiculos(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingVeiculos(data));
		});
	}

	async function handleClickVerMaisRankingEstados(page) {
		await readRankingEstados(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingEstados(data));
		});
	}

	function montarLabelPlaca({ placa }) {
		return formatarPlaca(placa);
	}

	function montarLabelEstado({ nome, sigla }) {
		return `${nome} - ${sigla}`;
	}

	return (
		<>
			<Col style={styleContainerButton}>
				<Button
					label="Novo MDF-e"
					onClick={handleClickNovaOrdem}
					style={styleButton}
					disabled={!podeInserirMDFe}
					title={!podeInserirMDFe ? 'Você não possui permissão para inserir um novo MDF-e' : null}
				/>
			</Col>
			<CardsResultado data={cardsResultado} isMobile={isMobile} />
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de motoristas"
					helpMessage="Lista os motoristas com mais MDF-es transmitidos ou encerrados"
					data={rankingMotoristas.content}
					fieldLabel="nome"
					fieldValue="transportesRealizados"
					typeValue={TYPE_VALUE.INTEGER}
					loading={loading}
					visibleButtonVerMais={(rankingMotoristas.number + 1) * 10 < rankingMotoristas.totalElements}
					onClickVerMais={handleClickVerMaisRankingMotoristas}
					page={rankingMotoristas.number}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de veículos"
					helpMessage="Lista os veículos com mais MDF-es transmitidos ou encerrados"
					data={rankingVeiculos.content}
					fieldLabel="placa"
					montarLabel={montarLabelPlaca}
					fieldValue="transportesRealizados"
					typeValue={TYPE_VALUE.INTEGER}
					loading={loading}
					visibleButtonVerMais={(rankingVeiculos.number + 1) * 10 < rankingVeiculos.totalElements}
					onClickVerMais={handleClickVerMaisRankingVeiculos}
					page={rankingVeiculos.number}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de estados"
					helpMessage="Lista os estados com mais MDF-es encerrados"
					data={rankingEstados.content}
					fieldLabel="sigla"
					montarLabel={montarLabelEstado}
					fieldValue="transportesRealizados"
					typeValue={TYPE_VALUE.INTEGER}
					loading={loading}
					visibleButtonVerMais={(rankingEstados.number + 1) * 10 < rankingEstados.totalElements}
					onClickVerMais={handleClickVerMaisRankingEstados}
					page={rankingEstados.number}
				/>
			</Col>
			<Col style={styleCards}>
				<ChartDocumentosPorDia
					title="MDF-es no período selecionado"
					helpMessage="Lista o valor total das MDF-es por situação no período selecionado"
					data={documentosPorDia}
					loading={loading}
					isMobile={isMobile}
				/>
			</Col>
			<Col style={styleCards}>
				<ChartDocumentosUltimosDozeMeses
					title="MDF-es nos últimos 12 meses"
					helpMessage="Lista o valor total das MDF-es agrupadas por mês nos últimos doze meses"
					data={documentosUltimosDozeMeses}
					loading={loading}
					isMobile={isMobile}
				/>
			</Col>
		</>
	);
}

export { DashboardMDFe };
