import { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { copiarObjeto, getSafeValue, TiposPercentualValor } from 'Common';
import { Card, Grid, InputSelectPercentualOrValor, SingleSelectConta, SingleSelectFormaPagamento } from 'components';

import { aplicarPercentual } from 'views/Util';

function AlteracaoEmLote({
	url,
	setAtualizarTotalizadorTitulos,
	atualizarTotalizadorAlteracaoEmLote,
	setAtualizarTotalizadorAlteracaoEmLote,
	informacoesPermissoes,
}) {
	const { values, setFieldValue } = useFormikContext();
	const [activeIndex, setActiveIndex] = useState(null);
	const [atualizarValoresTitulosField, setAtualizarValoresTitulosField] = useState(null);

	useEffect(() => {
		if (atualizarValoresTitulosField !== null) {
			atualizarValoresTitulos(atualizarValoresTitulosField);

			setAtualizarValoresTitulosField(null);
		}
	}, [atualizarValoresTitulosField]);

	useEffect(() => {
		if (values.conta) {
			atualizarSelectsTitulos('conta');
		}
	}, [values.conta]);

	useEffect(() => {
		if (values.formaPagamento) {
			atualizarSelectsTitulos('formaPagamento');
		}
	}, [values.formaPagamento]);

	useEffect(() => {
		if (atualizarTotalizadorAlteracaoEmLote) {
			let juros = 0;
			let multa = 0;
			let desconto = 0;

			values.titulos?.forEach((titulo) => {
				juros += getSafeValue(titulo.quitacao.juros);
				multa += getSafeValue(titulo.quitacao.multa);
				desconto += getSafeValue(titulo.quitacao.desconto);
			});

			setFieldValue('juros', parseFloat(juros.toFixed(2)));
			setFieldValue('multa', parseFloat(multa.toFixed(2)));
			setFieldValue('desconto', parseFloat(desconto.toFixed(2)));

			setAtualizarTotalizadorAlteracaoEmLote(false);
		}
	}, [atualizarTotalizadorAlteracaoEmLote]);

	function atualizarValoresTitulos(field) {
		const titulos = copiarObjeto(values.titulos);
		let somaRateado = 0;

		titulos?.forEach((titulo, index) => {
			const newValue = parseFloat(((titulo.quitacao.valor * values[field]) / values.totais.titulos).toFixed(2));

			titulos[index].quitacao[field] = newValue;
			somaRateado += newValue;

			titulos[index].quitacao.quitado =
				getSafeValue(titulo.quitacao.valor) +
				getSafeValue(titulo.quitacao.juros) +
				getSafeValue(titulo.quitacao.multa) -
				getSafeValue(titulo.quitacao.desconto);
		});

		if (values[field] - somaRateado !== 0) {
			titulos[0].quitacao[field] = parseFloat(
				(titulos[0].quitacao[field] - parseFloat((somaRateado - values[field]).toFixed(2))).toFixed(2)
			);

			titulos[0].quitacao.quitado =
				getSafeValue(titulos[0].quitacao.valor) +
				getSafeValue(titulos[0].quitacao.juros) +
				getSafeValue(titulos[0].quitacao.multa) -
				getSafeValue(titulos[0].quitacao.desconto);
		}

		setFieldValue('titulos', titulos);
		setAtualizarTotalizadorTitulos(true);
	}

	function atualizarSelectsTitulos(field) {
		const titulos = copiarObjeto(values.titulos);

		titulos?.forEach((_, index) => {
			titulos[index].quitacao[field] = values[field];
		});

		setFieldValue('titulos', titulos);
	}

	function onBlurValores(field, value, percentual, tipo = TiposPercentualValor.VALOR) {
		if (tipo === TiposPercentualValor.PERCENTUAL) {
			const valor = parseFloat(values.totais.titulos.toFixed(2));
			setFieldValue(field, aplicarPercentual(valor, percentual));
		} else {
			setFieldValue(field, value);
		}

		if (values[field] !== value) {
			setAtualizarValoresTitulosField(field);
		}
	}

	function onChangeSelect(field, e) {
		setFieldValue(field, e);
	}

	return (
		<Card>
			<Accordion className="tab-accordion-lite" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
				<AccordionTab header="Alterar títulos em lote">
					<Grid>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="2"
							component={InputSelectPercentualOrValor}
							label="Juros"
							name="juros"
							value={values.juros}
							onBlur={(tipo, value, percentual) => onBlurValores('juros', value, percentual, tipo)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="2"
							component={InputSelectPercentualOrValor}
							label="Multa"
							name="multa"
							value={values.multa}
							onBlur={(tipo, value, percentual) => onBlurValores('multa', value, percentual, tipo)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="2"
							component={InputSelectPercentualOrValor}
							label="Desconto"
							name="desconto"
							value={values.desconto}
							onBlur={(tipo, value, percentual) => onBlurValores('desconto', value, percentual, tipo)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="3"
							component={SingleSelectConta}
							label="Conta"
							name="conta"
							value={values.conta}
							esconderBotao
							onChange={(e) => onChangeSelect('conta', e)}
							url={`${url}/relacoes/contas`}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="3"
							component={SingleSelectFormaPagamento}
							label="Forma de pagamento"
							name="formaPagamento"
							value={values.formaPagamento}
							esconderBotao
							onChange={(e) => onChangeSelect('formaPagamento', e)}
							url={`${url}/relacoes/formas_pagamento`}
							{...informacoesPermissoes}
						/>
					</Grid>
				</AccordionTab>
			</Accordion>
		</Card>
	);
}

export default AlteracaoEmLote;
