import { MdAttachMoney, MdMoneyOff } from 'react-icons/md';
import { Col } from 'components';
import { CardResultado } from './components/CardResultado';
import { SERVICOS_CORES } from '../../Util/constantes';

function CardsResultado({ data, isMobile }) {
	const styleCards = isMobile ? { padding: '0.5em 0em', margin: '8px', flex: 'auto' } : { padding: '0.5em' };

	return (
		<>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardResultado
					title="Pendentes no período"
					helpMessage="Valor total de pendentes no período selecionado"
					valor={data.pendentes?.valor}
					percentualEvolucao={data.pendentes?.evolucao}
					colorValor={SERVICOS_CORES.azul}
					colorEvolucao={data.pendentes?.evolucao > 0 ? SERVICOS_CORES.verde : SERVICOS_CORES.vermelho}
					backgroundEvolucao={data.pendentes?.evolucao > 0 ? SERVICOS_CORES.verdeClaro : SERVICOS_CORES.vermelhoClaro}
					icon={<MdAttachMoney size={50} color={SERVICOS_CORES.azul} />}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardResultado
					title="Finalizados no período"
					helpMessage="Valor total de finalizados no período selecionado"
					valor={data.finalizados?.valor}
					percentualEvolucao={data.finalizados?.evolucao}
					colorValor={SERVICOS_CORES.verde}
					colorEvolucao={data.finalizados?.evolucao > 0 ? SERVICOS_CORES.verde : SERVICOS_CORES.vermelho}
					backgroundEvolucao={data.finalizados?.evolucao > 0 ? SERVICOS_CORES.verdeClaro : SERVICOS_CORES.vermelhoClaro}
					icon={<MdAttachMoney size={50} color={SERVICOS_CORES.verde} />}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardResultado
					title="Cancelados no período"
					helpMessage="Valor total de cancelados no período selecionado"
					valor={data.cancelados?.valor}
					percentualEvolucao={data.cancelados?.evolucao}
					colorValor={SERVICOS_CORES.vermelho}
					colorEvolucao={data.cancelados?.evolucao > 0 ? SERVICOS_CORES.verde : SERVICOS_CORES.vermelho}
					backgroundEvolucao={data.cancelados?.evolucao > 0 ? SERVICOS_CORES.verdeClaro : SERVICOS_CORES.vermelhoClaro}
					icon={<MdMoneyOff size={50} color={SERVICOS_CORES.vermelho} />}
				/>
			</Col>
		</>
	);
}

export { CardsResultado };
