import { useState } from 'react';
import Modal from '../Modal';
import AutoProgressBar from '../Loading/AutoProgressBar';
import ButtonImprimir from '../Button/ButtonImprimir';
import FormActions from '../FormActions';
import FormContent from '../FormContent';
import Form from '../Form';
import { ModalLoadingTransmissao } from 'components/ModalLoadingTransmissao';

function ModalRelatorio(props) {
  const { visible, onHide, header, onImprimir, parametros, isValid = true } = props;

  const [visibleLoading, setVisibleLoading] = useState(false);

  async function imprimir() {
    if (isValid) {
      setVisibleLoading(true);
    }
    await onImprimir().then(() => {
      setVisibleLoading(false);
    });
  }

  return (
    <>
      <Modal header={header} visible={visible} onHide={onHide}>
        <AutoProgressBar />
        <Form>
          <FormActions>
            <ButtonImprimir onClick={imprimir} />
          </FormActions>
          <FormContent>{parametros}</FormContent>
        </Form>
      </Modal>
      <ModalLoadingTransmissao visible={visibleLoading} message="Gerando relatório..." />
    </>
  );
}

export default ModalRelatorio;
