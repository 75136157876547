import { useState } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';

import {
	AutoProgressBar,
	Modal,
	FormActions,
	FormContent,
	Grid,
	InputField,
	Dropdown,
	InputMask,
	InputCep,
	If,
	SingleSelectPais,
	SingleSelectMunicipio,
	ButtonCancelar,
	ButtonSalvar,
	estadosBotaoCancelar,
	Divider,
} from 'components';
import { buscarMascaraTelefone } from 'Common';

import { validarFormulario } from 'views/Util';
import { converterTomadorToForm } from '../../Util/NFSeConverter';
import { initialValuesTomador, municipioParaModal, optionsTipoInscricao } from '../../Util/constantes';
import { MessagesErrors } from '../../../Util/constantes';

function ModalTomadorForm(props) {
	const { informacoesPermissoes, onSaveModalTomador, onClose } = props;
	const { dirty, values, errors, setFieldValue, handleReset } = useFormikContext();

	const [submitCount, setSubmitCount] = useState(0);
	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;

	function cancelar() {
		dirty ? handleReset() : onClose();
	}

	function onChangeValores(field, event) {
		setFieldValue(`${field}`, event?.target?.value ?? 0);
	}

	function onChangeSelect(field, event) {
		setFieldValue(`${field}`, event ?? null);
	}

	async function salvar() {
		setSubmitCount(submitCount + 1);

		if (await validarFormulario(props)) {
			onSaveModalTomador(converterTomadorToForm(values));
		}
	}

	function onChangeCep(data) {
		if (data.pesquisou) {
			data.bairro && setFieldValue('endereco.bairro', data.bairro);
			data.complemento && setFieldValue('endereco.complemento', data.complemento);
			data.logradouro && setFieldValue('endereco.logradouro', data.logradouro);
			data.municipio &&
				setFieldValue('endereco.municipio', {
					label: `${data.municipio.nome} - ${data.municipio.estado.sigla}`,
					value: data.municipio.id,
					registro: data.municipio,
				});
			data.pais && setFieldValue('endereco.pais', { label: data.pais.nome, value: data.pais.id, registro: data.pais });
		} else {
			setFieldValue('endereco.cep', data.value);
		}
	}

	return (
		<Modal header="Dados do cliente" visible onHide={() => onClose()}>
			<AutoProgressBar />
			<FormActions>
				<ButtonCancelar onClick={cancelar} estadoBotao={estadoBotaoCancelar} {...informacoesPermissoes} />
				<ButtonSalvar onClick={salvar} disabled={!dirty} {...informacoesPermissoes} />
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="6"
						md="6"
						lg="6"
						xl="6"
						component={InputField}
						label="Nome"
						value={values.nome}
						disabled
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="6"
						lg="6"
						xl="6"
						component={InputField}
						size={60}
						label="E-mail"
						value={values.email}
						onChange={(elemento) => onChangeValores('email', elemento)}
						id="NfeDadosDestInputFieldEmail"
						name="email"
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="6"
						lg="6"
						xl="6"
						name="tipo"
						component={Dropdown}
						obrigatorio
						label="Tipo"
						onChange={(elemento) => setFieldValue('tipo', elemento.value)}
						options={optionsTipoInscricao}
						value={values.tipo}
						errors={errors.tipo}
						showClear={false}
						{...informacoesPermissoes}
					/>
					<If test={values.tipo === 'FISICA'}>
						<Field
							sm="6"
							md="6"
							lg="6"
							xl="6"
							component={InputMask}
							mask="000.000.000-00"
							placeholder="   .   .   -  "
							label="CPF "
							name="cpf"
							value={String(values.cpf)}
							errors={errors.cpf}
							onChange={(elemento) => onChangeValores('cpf', elemento)}
							{...informacoesPermissoes}
						/>
					</If>
					<If test={values.tipo === 'JURIDICA'}>
						<Field
							sm="6"
							md="6"
							lg="6"
							xl="6"
							component={InputMask}
							mask="00.000.000/0000-00"
							placeholder="  .   .   /    -  "
							label="CNPJ"
							name="cnpj"
							value={String(values.cnpj)}
							onChange={(elemento) => onChangeValores('cnpj', elemento)}
							errors={errors.cnpj}
							{...informacoesPermissoes}
						/>
					</If>
					<If test={values.tipo === 'ESTRANGEIRO'}>
						<Field
							sm="6"
							md="6"
							lg="6"
							xl="6"
							component={InputField}
							size={20}
							name="idEstrangeiro"
							label="Identificação do estrangeiro"
							value={String(values.idEstrangeiro)}
							onChange={(elemento) => onChangeValores('idEstrangeiro', elemento)}
							{...informacoesPermissoes}
						/>
					</If>
				</Grid>
				<Divider
					label="Endereço"
					styleContainer={{ margin: '1rem 0' }}
					styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
				/>
				<Grid>
					<Field
						sm="6"
						md="4"
						lg="4"
						xl="4"
						component={InputCep}
						label="CEP"
						name="cep"
						type="tel"
						value={values.endereco?.cep}
						errors={errors.cep}
						disabled={values.municipio?.value === municipioParaModal.EXTERIOR}
						onChange={(elemento) => onChangeCep(elemento)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="4"
						lg="4"
						xl="4"
						component={InputField}
						size={60}
						label="Logradouro"
						name="logradouro"
						obrigatorio
						errors={errors.logradouro}
						value={values.endereco?.logradouro}
						onChange={(elemento) => onChangeValores('endereco.logradouro', elemento)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="4"
						lg="4"
						xl="4"
						component={InputField}
						size={60}
						label="Bairro"
						name="bairro"
						obrigatorio
						errors={errors.bairro}
						value={values.endereco?.bairro}
						onChange={(elemento) => onChangeValores('endereco.bairro', elemento)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="4"
						lg="4"
						xl="4"
						component={InputField}
						size={60}
						label="Número"
						name="numero"
						obrigatorio
						errors={errors.numero}
						value={values.endereco?.numero}
						onChange={(elemento) => onChangeValores('endereco.numero', elemento)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="4"
						lg="4"
						xl="4"
						component={InputField}
						size={60}
						label="Complemento"
						name="complemento"
						value={values.endereco?.complemento}
						onChange={(elemento) => onChangeValores('endereco.complemento', elemento)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="4"
						lg="4"
						xl="4"
						component={InputMask}
						mask={buscarMascaraTelefone(values?.endereco?.telefone)}
						label="Telefone"
						name="telefone"
						placeholder="(  )      -    "
						value={values?.endereco?.telefone}
						onChange={(elemento) => onChangeValores('endereco.telefone', elemento)}
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="4"
						lg="4"
						xl="4"
						component={SingleSelectPais}
						label="País "
						name="pais"
						isClearable={false}
						onChange={(elemento) => onChangeSelect('endereco.pais', elemento)}
						value={values.endereco?.pais}
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="4"
						lg="4"
						xl="4"
						component={SingleSelectMunicipio}
						label="Município "
						name="municipio"
						isClearable={false}
						onChange={(elemento) => onChangeSelect('endereco.municipio', elemento)}
						value={values.endereco?.municipio}
						{...informacoesPermissoes}
					/>
				</Grid>
			</FormContent>
		</Modal>
	);
}

const ModalTomadorView = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: true,

	validate(values) {
		const errors = {};
		const errorsEndereco = {};

		if (!values.tipo) {
			errors.tipo = MessagesErrors.CAMPO_OBRIGATORIO;
		} else if (values.tipo === 'PESSOA_FISICA') {
			if (!values.cpf) {
				errors.cpf = 'CPF obrigatorio';
			}
		} else if (values.tipo === 'PESSOA_JURIDICA') {
			if (!values.cnpj) {
				errors.cnpj = 'CNPJ obrigatorio';
			}
		}

		if (values.endereco) {
			if (!values.endereco?.cep) {
				errorsEndereco.cep = MessagesErrors.CAMPO_OBRIGATORIO;
			}
			if (!values.endereco?.logradouro) {
				errorsEndereco.logradouro = MessagesErrors.CAMPO_OBRIGATORIO;
			}
			if (!values.endereco?.bairro) {
				errorsEndereco.bairro = MessagesErrors.CAMPO_OBRIGATORIO;
			}
			if (!values.endereco?.numero) {
				errorsEndereco.numero = MessagesErrors.CAMPO_OBRIGATORIO;
			}
		}

		if (Object.keys(errorsEndereco).length > 0) {
			errors.endereco = { ...errorsEndereco };
		}

		return errors;
	},

	mapPropsToValues(props) {
		if (props.tomador) {
			return {
				...props.tomador.registro,
			};
		} else {
			return initialValuesTomador;
		}
	},

	handleSubmit: () => {},
})(ModalTomadorForm);

export { ModalTomadorView };
