export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é o cadastro de produtos e serviços`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-produto-principais',
		content: 'Aqui você vai preencher as principais informações dos produtos/serviços',
	},
	{
		target: '.step-produto-tabs',
		content: 'Em seguida, de acordo com os módulos liberados, configurações complementares podem ser realizadas em cada uma das abas',
	},
];
