import { Modal, If } from '../../../../../../components';
import FormRelacao from './Form';

export default function ModalRelacao(props) {
	return (
		<>
			<Modal
				header="Nova referência"
				visible={props.visible}
				onHide={props.onHide}
				styleModal={{ maxWidth: '1200px' }}
			>
				<If test={props.visible}>
					<FormRelacao
						valuesFornecedor={props.valuesFornecedor}
						visible={props.visible}
						onHide={props.onHide}
						styleModal={{ maxWidth: '1200px' }}
						isEdit={props.isEdit}
					/>
				</If>
			</Modal>
		</>
	);
}
