import { useEffect, useState } from 'react';
import { Field } from 'formik';
import { format, parseISO } from 'date-fns';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FaDownload } from 'react-icons/fa';

import {
	Modal,
	Button,
	Grid,
	Col,
	If,
	TextArea,
	Message,
	InputField,
	Fieldset,
	NenhumRegistroEncontrado,
} from '../../../../../../../components';
import { baixarArquivo } from '../../../../../../../Common';

import { imprimirCorrecao, imprimirXMLCorrecao } from '../../../Requests';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

const styleBotaoImprimir = {
	padding: '0px 10px',
	background: '#fff0',
	fontWeight: 'bold',
	fontSize: '15px',
	alignItems: 'center',
	border: 'none',
};

export default function ModalHistoricoCorrecoes(props) {
	const { visible, onHide, idNfe, isMobile, correcoesNfe: correcoes } = props;
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [mostrarModalVisualizacao, setMostrarModalVisualizacao] = useState(false);
	const [correcaoVigente, setCorrecaoVigente] = useState(null);
	const [outrasCorrecoes, setOutrasCorrecoes] = useState([]);

	const correcoesOrdenadas = correcoes.sort((a, b) => b.numeroSequenciaEvento - a.numeroSequenciaEvento);

	useEffect(() => {
		buscarCorrecaoVigenteEAtualizarEstado();
	}, []);

	function buscarCorrecaoVigenteEAtualizarEstado() {
		setCorrecaoVigente(correcoesOrdenadas.length > 0 ? correcoesOrdenadas[0] : {});
		setOutrasCorrecoes(correcoesOrdenadas.length > 0 ? correcoesOrdenadas.slice(1, correcoesOrdenadas.length + 1) : {});
	}

	function renderAcoesField(el) {
		return (
			<Button
				style={styleButton}
				className="p-button p-button-primary"
				icon="fa fa-eye"
				title="Visualizar descrição completa"
				onClick={() => {
					setRegistroSelecionado(el);
					setMostrarModalVisualizacao(true);
				}}
			/>
		);
	}

	function renderDataField(el) {
		if (el.dataHoraEvento) {
			return <span>{format(parseISO(el.dataHoraEvento, new Date()), 'dd/MM/yyyy HH:mm')}</span>;
		}
		return '';
	}

	function renderNumeroField(el) {
		return el.numeroSequenciaEvento;
	}

	function renderDescricaoField(el) {
		return <span title={el.descricao}>{el.descricao}</span>;
	}

	return (
		<Modal header="Histórico de correções" visible={visible} onHide={onHide}>
			<Fieldset legend="Correção vigente" className="fieldset">
				<Grid justifyEnd>
					<span style={{ display: 'flex', alignItems: 'center', fontSize: '16px', cursor: 'pointer' }}>
						<div
							style={styleBotaoImprimir}
							title="Baixar PDF da carta de correção"
							onClick={() =>
								imprimirCorrecao(idNfe, ({ data: arquivo }) => {
									baixarArquivo(arquivo, 'PDF - Carta de correção', 'application/pdf');
								})
							}
						>
							<FaDownload size="15px" style={!isMobile && { marginRight: '5px' }} />
							<span>PDF</span>
						</div>
						<div
							style={styleBotaoImprimir}
							title="Baixar XML da carta de correção"
							onClick={() => {
								imprimirXMLCorrecao(idNfe, ({ data: arquivo }) => {
									baixarArquivo(arquivo, `XML - Carta de correção`, 'application/xml');
								});
							}}
						>
							<FaDownload size="15px" style={!isMobile && { marginRight: '5px' }} />
							<span>XML</span>
						</div>
					</span>
				</Grid>
				<Grid>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						component={InputField}
						label="Data e hora do evento"
						value={
							correcaoVigente && correcaoVigente.dataHoraEvento
								? format(parseISO(correcaoVigente.dataHoraEvento, new Date()), 'dd/MM/yyyy HH:mm')
								: ''
						}
						name="dataHoraEvento"
						disabled
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						component={InputField}
						label="Número"
						value={
							correcaoVigente && correcaoVigente.numeroSequenciaEvento ? correcaoVigente.numeroSequenciaEvento : ''
						}
						name="numeroSequenciaEvento"
						disabled
					/>
					<Field
						col="12"
						component={TextArea}
						label="Descrição do que foi corrigido"
						name="descricao"
						value={correcaoVigente && correcaoVigente.descricao ? correcaoVigente.descricao : ''}
						disabled
					/>
				</Grid>
			</Fieldset>
			<Fieldset legend="Correções anteriores" className="fieldset">
				<Grid>
					<DataTable
						className="table"
						value={outrasCorrecoes}
						responsive
						sortField="numeroSequenciaEvento"
						sortOrder={-1}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							header="Data"
							field="dataHoraEvento"
							body={renderDataField}
							style={{ color: '#333333', width: '150px', opacity: '0.5' }}
						/>
						<Column
							header="Número"
							field="numeroSequenciaEvento"
							body={renderNumeroField}
							style={{ color: '#333333', width: '100px', opacity: '0.5' }}
						/>
						<Column
							header="Descrição"
							field="descricao"
							body={renderDescricaoField}
							style={{
								color: '#333333',
								flex: '1 1 0%',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								opacity: '0.5',
							}}
						/>
						<Column header="" body={renderAcoesField} style={{ width: '75px' }} />
					</DataTable>
				</Grid>
			</Fieldset>
			<If test={registroSelecionado}>
				<Modal
					header="Descrição completa"
					visible={mostrarModalVisualizacao}
					onHide={() => setMostrarModalVisualizacao(false)}
				>
					<Message
						colStyle={{ padding: '0px' }}
						severity="warn"
						text={
							<span>
								Atenção, esta carta de correção não é mais válida.{' '}
								<b>A última correção transmitida substitui as anteriores.</b>
							</span>
						}
						style={{ marginBottom: '10px' }}
					/>
					<Grid>
						<Col sm="12" md="6" lg="6" xl="6">
							<b>Data:</b>{' '}
							{registroSelecionado &&
								format(parseISO(registroSelecionado.dataHoraEvento, new Date()), 'dd/MM/yyyy HH:mm:ss')}
						</Col>
						<Col sm="12" md="6" lg="6" xl="6">
							<b>Número:</b> {registroSelecionado && registroSelecionado.numeroSequenciaEvento}
						</Col>
						<Col>
							<b>Descrição:</b> {registroSelecionado && registroSelecionado.descricao}
						</Col>
					</Grid>
				</Modal>
			</If>
		</Modal>
	);
}
