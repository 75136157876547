import { formatISO, isValid, parseISO } from 'date-fns';
import { useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
	DOCUMENTOFISCAL,
	asyncGetOperacaoFiscalFavorita,
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	calcularImpostosProduto,
	configuracoesUsuario,
	copiarObjeto,
	estadosCadastro,
	gerarUUID,
	mensagensDeValidacao,
	montarCalcularImpostosProduto,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usePrevious,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Col,
	Divider,
	Fieldset,
	Form,
	FormActions,
	FormContent,
	Grid,
	HeaderVendas,
	INITIAL_VALUE_PAGAMENTO,
	If,
	Message,
	ModalLoadingTransmissao,
	Prompt,
	Tutorial,
	confirm,
	tutorialStepsNotaFiscal,
	useGenerateParcelas,
	useRecalculatePagamentos,
	validatePagamentos,
} from 'components';

import BodyVendas from '../../../../../components/body/Vendas';
import { moduloUtilizado } from '../../../../../components/header/Util/constantes';
import { atualizarUrl, metodosAtualizarUrl } from '../../../../Util';
import { TipoPessoa } from '../../../../cadastros/Pessoas/Util/constantes';
import {
	asyncBuscarSetorResponsavel,
	asyncBuscarTabelaPreco,
	asyncGetCondicaoPagamentoFavorita,
	asyncGetCondicaoPagamentoSemPagamento,
	asyncGetContaFavorita,
	asyncGetFormaPagamentoDinheiro,
	asyncGetVenda,
	buscarVendedorInicial,
	consultarNfe,
} from '../Requests';
import { Status } from '../Util/constantes';
import { converterNFeDePedido, converterNFeParaFormulario, converterParaDuplicar } from './Util/NFeConverter';
import { Condicoes } from './Util/constantes';

import ModalPedidoImportacao from '../../../../../components/ModalPedidoImportacao';
import { asyncBuscarPedidoParaImportacao } from '../../../../../components/ModalPedidoImportacao/Requests';
import { ModuloImportacao } from '../../../../../components/ModalPedidoImportacao/Util/Constantes';
import { buscarCategoriaFavoritaReceita } from '../../../../../components/body/Vendas/components/Parcelas/Request';
import ModalCompartilharPorEmail from '../components/ModalCompartilharPorEmail';
import ModalCompartilharPorWhatsapp from '../components/ModalCompartilharPorWhatsapp';
import NfeHistorico from '../components/NfeHistorico';
import ValidateMessages from '../components/ValidateMessages';
import { converterSetorParaFormulario } from './Util/functions';
import ActionButtons from './components/ActionButtons';
import HeaderForm from './components/HeaderForm';
import ModalCancelamento from './components/ModalCancelamento';
import ModalCartaCorrecao from './components/ModalCartaCorrecao';
import ModalHistoricoCorrecoes from './components/ModalHistoricoCorrecoes';
import NfeNumeroCard from './components/NfeNumeroCard';
import { converterValueParaSelect } from 'components/body/Vendas/Util/functions';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/CondicaoPagamento/Util/constantes';
import { NOVO_PRODUTO_VENDA } from 'components/Produtos/Util/constantes';
import { validateProdutos } from 'components/Produtos/Util/validations';

const initialValue = {
	id: null,
	numero: null,
	serie: null,
	chave: '',
	status: 'NAO_ENVIADA',
	tipo: 'NOTA_FISCAL',
	tipoOperacao: 'SAIDA',
	naturezaOperacao: {
		label: 'Venda de mercadoria',
		value: 'VENDA',
	},
	finalidadeDocumento: {
		label: '1 - Normal',
		value: 'NORMAL',
	},
	indicadorPresenca: 'NAO_SE_APLICA',
	pessoa: null,
	dataEmissao: formatISO(new Date()),
	dataSaida: formatISO(new Date()),
	operacaoFiscal: null,
	tipoDesconto: 'VALOR',
	pedidoId: null,
	pedidoNumero: null,
	NFref: null,
	localEmbarque: null,

	produtos: [{ ...copiarObjeto(NOVO_PRODUTO_VENDA), id: null }],

	totalizadores: {
		totalProdutos: 0,
		totalFrete: 0,
		totalSeguro: 0,
		totalTributos: 0,
		totalAcessorias: 0,
		percentualDesconto: null,
		totalDesconto: 0,
		totalIcms: 0,
		totalIcmsSt: 0,
		totalIpi: 0,
		totalPis: 0,
		totalCofins: 0,
		totalFcpSt: 0,
		totalLiquido: 0,
		totalBaseCalculoIcms: 0,
		totalBaseCalculoIcmsSt: 0,
		totalBaseCalculoIpi: 0,
		totalBaseCalculoPis: 0,
		totalBaseCalculoCofins: 0,
		totalIpiDevolvido: 0,
	},
	idCobranca: null,
	pagamentos: [
		{
			id: null,
			tempKey: gerarUUID(),
			sequencial: 1,
			condicaoPagamento: null,
			conta: null,
			formaPagamento: null,
			categoria: null,
			quantidadeParcelas: 0,
			valor: 0,
			valorRecebido: 0,
			parcelas: [],
		},
	],
	informacoesComplementares: {
		vendedor: null,
		usuario: null,
		informacaoComplementar: '',
		observacaoInterna: '',
		setorId: null,
		tabelaPreco: null,
	},

	exportacao: {
		ufEmbarque: null,
		localEmbarque: '',
		localExportacao: '',
	},

	emitente: null,

	transporte: {
		modalidadeFrete: 'SEM_FRETE',
		transportador: null,
		veiculo: {
			placa: null,
			uf: null,
			RNTC: null,
		},
		volumes: {
			quantidade: null,
			tipo: '',
			Marca: '',
			Numeracao: '',
			pesoBruto: null,
			pesoLiquido: null,
		},
	},

	xJustCancelamento: '',
	dataCancelamento: null,

	validacaoRecarregaProdutoPeloCliente: false,
};

function NFeForm(props) {
	const { isMobile, isTablet, location, match, history } = props;
	const { values, dirty, setValues, setFieldValue, resetForm } = useFormikContext();

	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.INSERIR));
	const [podeEditar, setPodeEditar] = useState(usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.EDITAR));
	const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.EXCLUIR));
	const [podeVisualizarPedido] = useState(usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR));
	const [credencial] = useState(buscarDadosLoginLocalStorage());
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_NOTA_FISCAL));
	const [dadosBase, setDadosBase] = useState(copiarObjeto(initialValue));
	const [existeParcelaRecebida, setExisteParcelaRecebida] = useState(false);
	const [tabPagamentoSelecionada, setTabPagamentoSelecionada] = useState(0);

	const [modalHistoricoVisible, setModalHistoricoVisible] = useState(false);
	const [modalCancelamento, setModalCancelamento] = useState(false);
	const [modalCartaCorrecao, setModalCartaCorrecao] = useState(false);
	const [modalHistoricoCorrecoes, setModalHistoricoCorrecoes] = useState(false);
	const [modalPedidoImportacao, setModalPedidoImportacao] = useState(false);
	const [modalEnviarPorEmailVisible, setModalEnviarPorEmailVisible] = useState(false);
	const [modalEnviarPorWhatsappVisible, setModalEnviarPorWhatsappVisible] = useState(false);

	const [atualizarTotal, setAtualizarTotal] = useState(false);
	const [atualizarOperacaoFiscalProdutos, setAtualizarOperacaoFiscalProdutos] = useState(false);
	const [atualizarImpostosProdutos, setAtualizarImpostosProdutos] = useState(false);
	const [totalizadores, setTotalizadores] = useState({
		totalProdutos: values.totalizadores.totalProdutos ?? 0,
		totalFrete: values.totalizadores.totalFrete ?? 0,
		totalSeguro: values.totalizadores.totalSeguro ?? 0,
		totalAcessorias: values.totalizadores.totalAcessorias ?? 0,
		totalDesconto: values.totalizadores.totalDesconto ?? 0,
		totalIcmsSt: values.totalizadores.totalIcmsSt ?? 0,
		totalIpi: values.totalizadores.totalIpi ?? 0,
		totalIcms: values.totalizadores.totalIcms ?? 0,
		totalBaseCalculoIcmsSt: values.totalizadores.totalBaseCalculoIcmsSt ?? 0,
		totalBaseCalculoIcms: values.totalizadores.totalBaseCalculoIcms ?? 0,
		totalBaseCalculoIpi: values.totalizadores.totalBaseCalculoIpi ?? 0,
		totalLiquido: values.totalizadores.totalLiquido ?? 0,
	});

	const [isFirstRender, setIsFirstRender] = useState(true);
	const [controlMessageProdutos, setControlMessageProdutos] = useState(null);
	const [exibirLoadingTransmissao, setExibirLoadingTransmissao] = useState(false);
	const [exibirLoadingImprimir, setExibirLoadingImprimir] = useState(false);
	const [itemInativo, setItemInativo] = useState(null);
	const [favoritosPagamento, setFavoritosPagamento] = useState(null);

	const prevProps = usePrevious(props);

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar && (values.status === Status.REJEITADA || values.status === Status.NAO_ENVIADA),
		podeExcluir: podeExcluir && (values.status === Status.REJEITADA || values.status === Status.NAO_ENVIADA),
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};

	const precoVendaOuAtacado = values.pessoa?.registro.configPrecoPraticado ?? null;
	const notaFiscalTransmitida = values.status !== Status.NAO_ENVIADA && values.status !== Status.REJEITADA;
	const desabilitarBotaoTransmitir =
		dirty || notaFiscalTransmitida || !podeEditar || !organizacaoPodeTransmitirNfe() || !values.id;
	const utilizaTabelaPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosVendas?.utilizaTabelaPreco;

	const [generateParcelas] = useGenerateParcelas({
		valorPagamento: totalizadores?.totalLiquido,
		dataBaseParcela: values.dataEmissao,
		favoritos: favoritosPagamento,
	});

	useRecalculatePagamentos(
		{
			valorTotal: totalizadores?.totalLiquido,
			dataBaseParcela: values.dataEmissao,
			favoritos: favoritosPagamento,
		},
		[totalizadores?.totalLiquido]
	);

	useEffect(async () => {
		const vendaId = location.state?.vendaId;

		getFavoritosPagamento();

		if (vendaId) {
			duplicarVenda(vendaId);
		} else if (match.path === '/nfes/importar_pedido/:id') {
			asyncBuscarPedidoParaImportacao(match.params.id, async ({ data: venda }) => {
				let nfe = converterNFeDePedido(venda);
				nfe.serie = credencial.filialConectada.parametrosFiscalNFe?.serieNfe;

				setValues(nfe);
				atualizarUrl(history, '/nfes/cadastro', null, metodosAtualizarUrl.POP);
				setAtualizarTotal(true);
			});
		} else {
			const uuid = match.params.id;

			validarUUID(uuid) ? await asyncSelectRegistro(uuid) : novo();
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_NOTA_FISCAL, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('VendasSingleSelectCliente')?.getElementsByTagName('input')[0]?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		const isDiffAndDirty = prevProps?.dirty !== dirty && dirty;

		if (isDiffAndDirty && tabPagamentoSelecionada === 1) {
			if (values.condicao !== Condicoes.A_VISTA) {
				setTabPagamentoSelecionada(0);
			}
		}

		if (prevProps?.values.validacaoRecarregaProdutoPeloCliente !== values.validacaoRecarregaProdutoPeloCliente) {
			if (values.validacaoRecarregaProdutoPeloCliente) {
				prevProps?.values.produtos.map((item, index) => {
					ajustarValorVenda(item, index);
				});

				setAtualizarTotal(true);
				setFieldValue('validacaoRecarregaProdutoPeloCliente', false);
			}
		}

		setTimeout(() => {
			if (match.path === '/nfes/importar_pedido/:id') {
				atualizarUrl(history, '/nfes/cadastro', null, metodosAtualizarUrl.POP);

				asyncBuscarPedidoParaImportacao(match.params.id, async ({ data: venda }) => {
					const { setores } = await asyncBuscarSetorResponsavel();
					let vendaComSetor = await { ...copiarObjeto(venda) };
					setores?.map((setor) => {
						if (venda.setor?.Id === setor.id) {
							vendaComSetor = { ...vendaComSetor, setor: setor };
						}
					});
					let nfe = converterNFeDePedido(vendaComSetor);
					nfe.serie = credencial.filialConectada.parametrosFiscalNFe?.serieNfe;

					setValues(nfe);
				});

				document.getElementById('VendasSingleSelectCliente')?.getElementsByTagName('input')[0]?.focus();
			}
		}, 500);
	}, [prevProps]);

	useEffect(() => {
		if (atualizarImpostosProdutos) {
			if (values.pessoa) {
				montarCalcularImpostosTodosProdutos(values.produtos);
			}
		}
		setAtualizarImpostosProdutos(false);
	}, [atualizarImpostosProdutos]);

	useEffect(() => {
		if (!isFirstRender && atualizarOperacaoFiscalProdutos) {
			let produtos = values.produtos;
			values.produtos.forEach((produto, index) => {
				if (prevProps.values?.operacaoFiscal?.value === produto.operacaoFiscal?.value) {
					produtos[index].operacaoFiscal = values.operacaoFiscal;
				}
			});
			montarCalcularImpostosTodosProdutos(produtos);
		}
		setAtualizarOperacaoFiscalProdutos(false);
	}, [atualizarOperacaoFiscalProdutos]);

	async function asyncConsultaRegistro(idVenda) {
		await consultarNfe(
			idVenda,
			async (venda) => {
				let values = converterNFeParaFormulario(venda.data);

				if (values.status === 'AUTORIZADA') {
					setPodeInserir(false);
					setPodeEditar(false);
					setPodeExcluir(false);
					setTabPagamentoSelecionada(1);
				} else {
					setTabPagamentoSelecionada(0);
				}
				setDadosBase(copiarObjeto(values));

				atualizarUrl(history, `/nfes/cadastro/${idVenda}`, null, metodosAtualizarUrl.POP);

				if (!values.operacaoFiscal?.value) {
					asyncGetOperacaoFiscalFavorita('SAIDA', ({ data }) => {
						let operacaoFiscal = data;
						let operacaoFiscalFavorita = {
							label: `${operacaoFiscal.codigo} - ${operacaoFiscal.descricao}`,
							registro: operacaoFiscal,
							value: operacaoFiscal.id,
						};

						if (operacaoFiscalFavorita.registro) {
							values.operacaoFiscal = operacaoFiscalFavorita;
						}
					});
				}
				setFieldValue('emitente', credencial.filialConectada);
				setFieldValue('serie', credencial.filialConectada.parametrosFiscalNFe?.serieNfe);

				resetForm({ values });
				setIsFirstRender(false);
			},
			() => {
				novo();
			}
		);
	}

	async function getFavoritosPagamento(callback) {
		const pagamentos = [
			{
				...INITIAL_VALUE_PAGAMENTO,
				tempKey: gerarUUID(),
				sequencial: 1,
				conta: null,
				categoria: null,
				condicaoPagamento: null,
				formaPagamento: null,
			},
		];
		const favoritos = {};
		const promises = [
			asyncGetContaFavorita(({ data: response }) => {
				const contaFavorita = converterValueParaSelect(response.content[0]);
				pagamentos[0].conta = contaFavorita;
				favoritos.conta = contaFavorita;
			}),
			buscarCategoriaFavoritaReceita('nfes', ({ data: response }) => {
				const categoria = converterValueParaSelect(response.content[0]);
				pagamentos[0].categoria = categoria;
				favoritos.categoria = categoria;
			}),
			asyncGetCondicaoPagamentoFavorita(async ({ data: response }) => {
				const condicaoPagamentoFavorita = converterValueParaSelect(response.content[0]);
				const isAPrazo = condicaoPagamentoFavorita?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
				const parcelaPadraoCondicaoPagamento =
					condicaoPagamentoFavorita?.registro?.parcelaPadrao ?? condicaoPagamentoFavorita?.registro?.parcelaMinima ?? 1;

				pagamentos[0].condicaoPagamento = condicaoPagamentoFavorita;
				favoritos.condicaoPagamento = condicaoPagamentoFavorita;

				function setFormaPagamento(formaPagamento) {
					const formaPagamentoConvertida = converterValueParaSelect(formaPagamento);
					pagamentos[0].formaPagamento = formaPagamentoConvertida;
					favoritos.formaPagamento = formaPagamentoConvertida;

					if (formaPagamento.conta) {
						const contaConvertida = converterValueParaSelect(formaPagamento.conta);
						pagamentos[0].conta = contaConvertida;
						favoritos.conta = contaConvertida;
					}

					if (formaPagamento.categoria) {
						const categoriaConvertida = converterValueParaSelect(formaPagamento.categoria);
						pagamentos[0].categoria = categoriaConvertida;
						favoritos.categoria = categoriaConvertida;
					}
				}

				if (condicaoPagamentoFavorita.registro.formaPagamento) {
					setFormaPagamento(condicaoPagamentoFavorita.registro.formaPagamento);
				} else {
					await asyncGetFormaPagamentoDinheiro(({ data: response }) => {
						setFormaPagamento(response.content[0]);
					});
				}

				if (isAPrazo) {
					pagamentos[0].quantidadeParcelas = {
						value: parcelaPadraoCondicaoPagamento,
						label: `${parcelaPadraoCondicaoPagamento}x`,
					};
					pagamentos[0].parcelas = generateParcelas({
						qtdParcelas: parcelaPadraoCondicaoPagamento,
						favoritos: favoritos,
					});
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFavoritosPagamento(favoritos);
		});

		if (typeof callback === 'function') {
			callback(pagamentos);
		}
	}

	async function asyncSelectRegistro(idVenda) {
		await asyncGetVenda(
			'nfes',
			idVenda,
			async (venda) => {
				const { setores } = await asyncBuscarSetorResponsavel();
				let nfeComSetor = await { ...copiarObjeto(venda.data) };
				setores?.map((setor) => {
					if (venda.data.setorId === setor.id) {
						nfeComSetor = { ...nfeComSetor, setor: setor };
					}
				});

				let values = converterNFeParaFormulario(nfeComSetor);

				if (values.status === 'AUTORIZADA') {
					setPodeInserir(false);
					setPodeEditar(false);
					setPodeExcluir(false);
					setTabPagamentoSelecionada(1);
				} else {
					setTabPagamentoSelecionada(0);
				}
				setDadosBase(copiarObjeto(values));

				atualizarUrl(history, `/nfes/cadastro/${idVenda}`, null, metodosAtualizarUrl.POP);

				if (!values.operacaoFiscal?.value) {
					asyncGetOperacaoFiscalFavorita('SAIDA', ({ data }) => {
						let operacaoFiscal = data;
						let operacaoFiscalFavorita = {
							label: `${operacaoFiscal.codigo} - ${operacaoFiscal.descricao}`,
							registro: operacaoFiscal,
							value: operacaoFiscal.id,
						};

						if (operacaoFiscalFavorita.registro) {
							values.operacaoFiscal = operacaoFiscalFavorita;
						}
					});
				}
				setFieldValue('emitente', credencial.filialConectada);
				setFieldValue('serie', credencial.filialConectada.parametrosFiscalNFe?.serieNfe);

				resetForm({ values });

				setAtualizarTotal(true);
				setIsFirstRender(false);
			},
			() => {
				novo();
			}
		);
	}

	async function novo({ isCancelarNFe = false } = {}) {
		const valoresGet = {
			...copiarObjeto(initialValue),
			emitente: credencial.filialConectada,
			serie: credencial.filialConectada.parametrosFiscalNFe?.serieNfe,
		};

		atualizarUrl(history, '/nfes/cadastro', null, metodosAtualizarUrl.POP);

		const { setor } = await asyncBuscarSetorResponsavel();
		await buscarVendedorInicial((vendedor) => {
			const informacoesComplementares = {
				...valoresGet.informacoesComplementares,
				vendedor: vendedor,
				setor: converterSetorParaFormulario(setor),
				informacaoComplementar: buscarDadosLoginLocalStorage().filialConectada?.parametrosVendas?.observacaoPadraoNfe,
			};

			setExisteParcelaRecebida(false);
			setTabPagamentoSelecionada(0);

			valoresGet.informacoesComplementares = informacoesComplementares;
		});

		const promises = [
			asyncGetOperacaoFiscalFavorita('SAIDA', ({ data }) => {
				const operacaoFiscal = data;
				valoresGet.operacaoFiscal = operacaoFiscal
					? {
							label: `${operacaoFiscal.codigo} - ${operacaoFiscal.descricao}`,
							registro: operacaoFiscal,
							value: operacaoFiscal.id,
						}
					: null;
			}),
			buscarTabelaPreco(
				{ vendedorId: valoresGet.informacoesComplementares?.vendedor?.value, isCancelarNFe: isCancelarNFe },
				({ data: tabelaPrecoApi }) => {
					const tabelaPrecoGet = {
						label: `${tabelaPrecoApi.codigo} - ${tabelaPrecoApi.nome}`,
						registro: tabelaPrecoApi,
						value: tabelaPrecoApi.id,
					};
					valoresGet.informacoesComplementares = {
						...valoresGet.informacoesComplementares,
						tabelaPreco: tabelaPrecoGet,
					};
				}
			),
			getFavoritosPagamento((favoritosPagamentos) => {
				valoresGet.pagamentos = favoritosPagamentos;
			}),
		];

		await Promise.all(promises).then(() => {
			resetForm({ values: valoresGet });

			setAtualizarTotal(true);
			setIsFirstRender(false);
		});
	}

	function ajustarValorVenda(item, index) {
		precoVendaOuAtacado === 'PRECO_ATACADO' && item.produto?.registro?.precoAtacado > 0
			? (values.produtos[index].valor = item.produto?.registro.precoAtacado)
			: (values.produtos[index].valor = item.produto?.registro.preco ?? 0);

		values.produtos[index].subtotal = values.produtos[index].valor * values.produtos[index].quantidade;
	}

	function duplicarVenda(idVenda) {
		asyncGetVenda('nfes', idVenda, async ({ data }) => {
			atualizarUrl(history, '/nfes/cadastro', null, metodosAtualizarUrl.POP);
			const informacoesVenda = converterNFeParaFormulario(data);
			setExisteParcelaRecebida(false);
			setTabPagamentoSelecionada(0);
			setPodeEditar(true);
			setPodeExcluir(true);
			setPodeInserir(true);

			setValues(converterParaDuplicar(informacoesVenda));
		});
	}

	function retornaUfDestinatario() {
		if (values?.pessoa?.registro?.enderecos) {
			let listaEnderecos = values?.pessoa?.registro?.enderecos.filter((r) => r.favorito === true);
			if (listaEnderecos.length > 0) {
				return listaEnderecos[0]?.municipio?.ufId;
			}
			return values?.pessoa?.registro?.enderecos[0]?.municipio?.ufId;
		}
		return '';
	}

	function calcularImpostosTodosProdutos(produtos) {
		let itens = produtos;

		itens.map((item) => {
			item.tributos = calcularImpostosProduto(item, DOCUMENTOFISCAL.NFE);
			item.subtotal = parseFloat(
				(
					item?.quantidade * item?.valor +
					item?.seguro +
					item?.frete +
					item?.acessorias +
					item?.tributos?.icms.valorIcmsSt +
					item?.tributos?.ipi?.valorIpi -
					item?.desconto
				).toFixed(2)
			);
		});

		return itens;
	}

	async function montarCalcularImpostosTodosProdutos(itens) {
		let produtos = itens;
		await values.produtos.forEach(async (produto, index) => {
			produtos[index] = await montarCalcularImpostosProdutoLocal(produtos[index]);
			if (index === produtos.length - 1) {
				setFieldValue('produtos', produtos);
				setTimeout(() => {
					setAtualizarTotal(true);
				}, 250);
			}
		});
	}

	async function montarCalcularImpostosProdutoLocal(item) {
		if (item.produto && item.operacaoFiscal) {
			item = await montarCalcularImpostosProduto(retornaUfDestinatario(), item, DOCUMENTOFISCAL.NFE);
			item.subtotal = parseFloat(
				(
					item?.quantidade * item?.valor +
					item?.seguro +
					item?.frete +
					item?.acessorias +
					item?.tributos?.icms.valorIcmsSt +
					item?.tributos?.ipi?.valorIpi -
					item?.desconto
				).toFixed(2)
			);
		}
		return item;
	}

	function organizacaoPodeTransmitirNfe() {
		if (credencial?.filialConectada?.parametrosFiscalNFe) {
			if (
				!credencial?.filialConectada?.parametrosFiscalCertificado ||
				!credencial.filialConectada.parametrosFiscalNFe.serieNfe
			) {
				return false;
			}
		}
		return true;
	}

	function montarNomeItem() {
		function possuiVirgula(index) {
			if (itemInativo?.length > 1) {
				return index < itemInativo?.length - 1 ? ', ' : '';
			} else {
				return '';
			}
		}
		if (itemInativo) {
			return (
				<span>
					{itemInativo?.length > 1 ? 'Itens ' : 'Item '}
					<span style={{ fontWeight: 'bold' }}>
						{itemInativo?.map((item, index) => {
							return item.codigo + ' - ' + item.nome + possuiVirgula(index);
						})}
					</span>
					{itemInativo?.length > 1
						? ' estão inativos no cadastro de produtos'
						: ' está inativo no cadastro de produtos'}
				</span>
			);
		}
	}

	async function buscarTabelaPreco(dados, onSuccess) {
		function onSuccessWrapper(request) {
			const arrayProdutos = values.produtos.filter((item) => item.produto);
			if (
				!isFirstRender &&
				!dados.isCancelarNFe &&
				values.informacoesComplementares?.tabelaPreco &&
				arrayProdutos?.length > 0 &&
				values.informacoesComplementares?.tabelaPreco?.value !== request?.data?.id
			) {
				confirm(
					'Confirmação',
					'Nova tabela de preço reconhecida. Ao atualizá-la os produtos serão removidos. Deseja atualizar a tabela de preço?',
					() => {
						setFieldValue('produtos', [{ ...copiarObjeto(NOVO_PRODUTO_VENDA), id: gerarUUID() }]);
						onSuccess(request);
					}
				);
			} else if (typeof onSuccess === 'function') {
				onSuccess(request);
			}
		}

		if (utilizaTabelaPreco) {
			await asyncBuscarTabelaPreco(dados, onSuccessWrapper);
		}
	}

	function buscarMunicipioIdPessoa(pessoa) {
		let municipioId = null;
		if (pessoa) {
			const indexEnderecoFavorito = pessoa.registro?.enderecos?.findIndex((endereco) => endereco.favorito);
			if (indexEnderecoFavorito >= 0) {
				municipioId = pessoa.registro?.enderecos[indexEnderecoFavorito]?.municipio?.id;
			} else if (indexEnderecoFavorito === -1 && pessoa.registro?.enderecos?.length > 0) {
				municipioId = pessoa.registro?.enderecos[0]?.municipio?.id;
			}
		}

		return municipioId;
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Tutorial
				steps={tutorialStepsNotaFiscal}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<ModalLoadingTransmissao visible={exibirLoadingTransmissao} message="Transmitindo NF-e..." />
			<ModalLoadingTransmissao visible={exibirLoadingImprimir} message="Carregando NF-e para impressão..." />
			<Form
				header={
					<HeaderForm
						id={values.id}
						chave={values.chave}
						situacao={values.status}
						asyncSelectRegistro={asyncConsultaRegistro}
						setExibirLoadingImprimir={setExibirLoadingImprimir}
						setModalEnviarPorEmailVisible={setModalEnviarPorEmailVisible}
						setModalEnviarPorWhatsappVisible={setModalEnviarPorWhatsappVisible}
						isMobile={isMobile}
					/>
				}
				className="card-default screen-max-width"
			>
				<FormActions className="screen-max-width">
					<ActionButtons
						dadosBase={dadosBase}
						duplicarVenda={duplicarVenda}
						asyncSelectRegistro={asyncSelectRegistro}
						novo={novo}
						setTabPagamentoSelecionada={setTabPagamentoSelecionada}
						setModalHistoricoVisible={setModalHistoricoVisible}
						setModalCancelamento={setModalCancelamento}
						setModalHistoricoCorrecoes={setModalHistoricoCorrecoes}
						setModalCartaCorrecao={setModalCartaCorrecao}
						setModalPedidoImportacao={setModalPedidoImportacao}
						informacoesPermissoes={informacoesPermissoes}
						existeParcelaRecebida={existeParcelaRecebida}
						setAtualizarImpostosProdutos={setAtualizarImpostosProdutos}
						totalizadores={totalizadores}
						podeEditar={podeEditar}
						setIsFirstRender={setIsFirstRender}
						setExibirLoadingTransmissao={setExibirLoadingTransmissao}
						desabilitarBotaoTransmitir={desabilitarBotaoTransmitir}
						credencial={credencial}
						erroProdutoInativo={(item) => setItemInativo(item)}
						organizacaoPodeTransmitirNfe={organizacaoPodeTransmitirNfe}
						atualizarParcelas={async (parcelas) => {
							setFieldValue('parcelas', parcelas);
						}}
						history={history}
						match={match}
						setAtualizarTotal={setAtualizarTotal}
					/>
				</FormActions>
				<FormContent>
					<If test={itemInativo}>
						<Message colStyle={{ padding: '0px' }} severity="error" text={montarNomeItem()} />
					</If>
					<ValidateMessages
						organizacaoPodeTransmitirNfe={organizacaoPodeTransmitirNfe}
						credencial={credencial}
						history={history}
					/>
					<Grid className="step-nfe-status" justifyBetween verticalAlignCenter style={{ margin: '1rem 0px 0px 0px' }}>
						<NfeNumeroCard
							isMobile={isMobile}
							values={values}
							history={history}
							podeVisualizarPedido={podeVisualizarPedido}
						/>
					</Grid>
					<Grid>
						<Col>
							<Fieldset className="fieldset">
								<HeaderVendas
									moduloUtilizado={moduloUtilizado.NOTA_FISCAL}
									tipoPessoa={TipoPessoa.CLIENTE}
									informacoesPermissoes={informacoesPermissoes}
									setAtualizarOperacaoFiscalProdutos={setAtualizarOperacaoFiscalProdutos}
									setControlMessageProdutos={setControlMessageProdutos}
									setAtualizarImpostosProdutos={setAtualizarImpostosProdutos}
									getCondicaoPagamentoSemPagamento={asyncGetCondicaoPagamentoSemPagamento}
									buscarTabelaPreco={buscarTabelaPreco}
									buscarMunicipioIdPessoa={buscarMunicipioIdPessoa}
								/>
							</Fieldset>
						</Col>
						<Divider
							label="Produtos"
							styleContainer={{ margin: '0rem 0.5rem 0.5rem 1rem' }}
							styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
						/>
						<Col>
							<BodyVendas
								moduloUtilizado={moduloUtilizado.NOTA_FISCAL}
								informacoesPermissoes={informacoesPermissoes}
								precoVendaOuAtacado={precoVendaOuAtacado}
								setAtualizarTotal={setAtualizarTotal}
								atualizarTotal={atualizarTotal}
								existeParcelaRecebida={existeParcelaRecebida}
								setExisteParcelaRecebida={setExisteParcelaRecebida}
								tabPagamentoSelecionada={tabPagamentoSelecionada}
								setTabPagamentoSelecionada={setTabPagamentoSelecionada}
								controlMessageProdutos={controlMessageProdutos}
								setControlMessageProdutos={setControlMessageProdutos}
								urlModuloUtilizado="nfes"
								ufDestinatario={retornaUfDestinatario()}
								operacaoFiscalHeader={values.operacaoFiscal}
								comissaoHeader={values.informacoesComplementares?.vendedor?.registro?.comissao}
								descontoMaximoVendedor={values.informacoesComplementares?.vendedor?.registro?.descontoMaximo}
								descontoMaximoSuperior={values.informacoesComplementares?.vendedor?.registro?.descontoMaximoSuperior}
								calcularImpostosTodosProdutos={calcularImpostosTodosProdutos}
								totalizadores={totalizadores}
								setTotalizadores={setTotalizadores}
								isFirstRender={isFirstRender}
								isMobile={isMobile}
								isTablet={isTablet}
								favoritosPagamento={favoritosPagamento}
								podeEditar={podeEditar}
								buscarTabelaPreco={buscarTabelaPreco}
								buscarMunicipioIdPessoa={buscarMunicipioIdPessoa}
							/>
						</Col>
					</Grid>
				</FormContent>
			</Form>
			<If test={modalHistoricoVisible}>
				<NfeHistorico
					idNfe={values.id}
					visible={modalHistoricoVisible}
					onHide={() => setModalHistoricoVisible(false)}
				/>
			</If>
			<If test={modalCancelamento}>
				<ModalCancelamento
					idNfe={values.id}
					visible={modalCancelamento}
					xJust={values.xJustCancelamento}
					dataCancelamento={values.dataCancelamento}
					statusNfe={values.status}
					onHide={(confirmou) => {
						setModalCancelamento(false);
						if (confirmou) asyncSelectRegistro(values.id);
					}}
				/>
			</If>
			<If test={modalHistoricoCorrecoes}>
				<ModalHistoricoCorrecoes
					idNfe={values.id}
					visible={modalHistoricoCorrecoes}
					correcoesNfe={values.correcoes}
					isMobile={isMobile}
					onHide={() => setModalHistoricoCorrecoes(false)}
				/>
			</If>
			<If test={modalCartaCorrecao}>
				<ModalCartaCorrecao
					idNfe={values.id}
					visible={modalCartaCorrecao}
					onHide={(confirmou) => {
						setModalCartaCorrecao(false);
						if (confirmou) asyncSelectRegistro(values.id);
					}}
				/>
			</If>
			<If test={modalPedidoImportacao}>
				<ModalPedidoImportacao
					visible={modalPedidoImportacao}
					onHide={() => setModalPedidoImportacao(false)}
					isMobile={isMobile}
					isTablet={isTablet}
					history={history}
					moduloImportacao={ModuloImportacao.NFE}
				/>
			</If>
			<If test={modalEnviarPorEmailVisible}>
				<ModalCompartilharPorEmail
					idNFe={values.id}
					idPessoa={values.pessoa?.value}
					numero={values.numero}
					visible={modalEnviarPorEmailVisible}
					onHide={() => setModalEnviarPorEmailVisible(false)}
				/>
			</If>
			<If test={modalEnviarPorWhatsappVisible}>
				<ModalCompartilharPorWhatsapp
					idNFe={values.id}
					idPessoa={values.pessoa?.value}
					nomeCliente={values.pessoa?.registro?.nome}
					numero={values.numero}
					telefone={values.pessoa?.registro?.telefone}
					visible={modalEnviarPorWhatsappVisible}
					onHide={() => setModalEnviarPorWhatsappVisible(false)}
				/>
			</If>
		</>
	);
}

NFeForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return copiarObjeto(initialValue);
	},

	validate(values) {
		let errors = {};
		const errorsProdutos = validateProdutos(
			values.produtos,
			values.tipo,
			values.informacoesComplementares?.tabelaPreco
		);
		const errorsPagamentos = validatePagamentos(values.pagamentos, values.totalizadores?.totalLiquido);

		if (values.tipo !== 'ORCAMENTO') {
			if (!values.pessoa) {
				errors.pessoa = mensagensDeValidacao.OBRIGATORIO;
			}

			if (!values.operacaoFiscal) {
				errors.operacaoFiscal = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		if (values.tipo === 'NOTA_FISCAL') {
			if (!!values.pessoa.registro) {
				const enderecoPessoa = values.pessoa.registro?.endereco ?? values.pessoa.registro?.enderecos[0];
				if (
					enderecoPessoa.pais?.id === '34e63b2e-c596-f34f-824d-bfd27eb62fa8' &&
					(!enderecoPessoa.bairro ||
						!enderecoPessoa.logradouro ||
						!enderecoPessoa.numero ||
						!enderecoPessoa.cep ||
						!enderecoPessoa.municipio?.id ||
						!enderecoPessoa.pais?.id)
				) {
					errors.pessoaEndereco = mensagensDeValidacao.OBRIGATORIO;
				}
			}
		}

		if (!values.dataEmissao || (values.dataEmissao && !isValid(parseISO(values.dataEmissao)))) {
			errors.dataEmissao = mensagensDeValidacao.DATA_INVALIDA;
		}
		if (!values.dataSaida || (values.dataSaida && !isValid(parseISO(values.dataSaida)))) {
			errors.dataSaida = mensagensDeValidacao.DATA_INVALIDA;
		}
		if (values.validade && !isValid(parseISO(values.validade))) {
			errors.validade = mensagensDeValidacao.DATA_INVALIDA;
		}
		if (values.previsaoEntrega && !isValid(parseISO(values.previsaoEntrega))) {
			errors.previsaoEntrega = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (errorsProdutos.length > 0) {
			errors.produtos = errorsProdutos;
		}
		if (!values.informacoesComplementares?.vendedor) {
			errors.informacoesComplementares = {
				...errors.informacoesComplementares,
				vendedor: mensagensDeValidacao.OBRIGATORIO,
			};
		}

		if (errorsPagamentos.length > 0) {
			errors.pagamentos = errorsPagamentos;
		}

		return errors;
	},
	handleSubmit: () => {},
})(NFeForm);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(NFeForm);
