import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Col from '../../../../Col';
import {
	buscarDisabledDeAcordoComAsPermissoes,
	buscarHiddenDeAcordoComAsPermissoes,
	buscarTitleCampoDeAcordoComAsPermissoes,
} from '../../../../../Common/Autorizacao/ManipulacaoDeComponentes';
import {
	renderizarValidacao,
	buscarClassNamePelosErros,
} from '../../../../../Common/TratamentoDeErro/validacoesDeCampos';
import { usuarioPossuiModulos } from '../../../../../Common/Autenticacao';
import MaskInput from 'react-maskinput';
import { manterApenasNumeros } from '../../../../../Common/Mascara';
import usePrevious from '../../../../../Common/Hooks/usePrevious';
import Button from '../../../../Button';

//componente: https://github.com/xnimorz/masked-input/tree/master/packages/react-maskinput
export function InternalInputSearchMask(props) {
	const {
		onFocus,
		sm,
		md,
		lg,
		xl,
		id,
		name,
		placeholder,
		mask,
		onBlur,
		errors,
		touched,
		podeVisualizar,
		podeInserir,
		podeEditar,
		estadoCadastro,
		title,
		colId,
		type,
		obrigatorio,
		label,
		labelSize,
		style,
		onClickPesquisar,
	} = props;

	const prevProps = usePrevious(props);
	const [validouModulo, setValidouModulo] = useState(true);

	useEffect(() => {
		if (props.modulosEspecificos) {
			setValidouModulo(usuarioPossuiModulos(props.modulosEspecificos));
		}
	}, []);

	useEffect(() => {
		if (prevProps?.mask !== props.mask && props.value !== prevProps?.value) {
			props.onChange({
				target: {
					value: props.value,
					name: props.name,
				},
			});
		}
	});

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden))
			return { display: 'none', ...props.colStyle };
		return props.colStyle;
	}

	function onChange(e) {
		const value = props.removerMascara ? manterApenasNumeros(e.target.value) : e.target.value;

		props.onChange({
			...e,
			target: {
				...e.target,
				value: e.target.value === '' ? null : value,
			},
		});
	}

	function getValue() {
		return props.value ? props.value : '';
	}

	function montarLabel() {
		if (obrigatorio) {
			return (
				<label style={{ fontSize: labelSize }} title={props.helpMessage}>
					{' '}
					{label} <b style={{ fontSize: labelSize ? labelSize : '18px', lineHeight: '5px' }}> *</b>{' '}
				</label>
			);
		}

		return (
			<label style={{ fontSize: labelSize }} title={props.helpMessage}>
				{' '}
				{label}{' '}
			</label>
		);
	}

	function getStyle() {
		return {
			borderRadius: '3px',
			height: style?.height ? style.height : '32px',
			...style,
		};
	}

	if (!validouModulo) {
		return null;
	}

	const disabled = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, props.disabled);

	return (
		<Col className="p-fluid" id={colId} sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			<div className="p-inputgroup">
				{montarLabel()}
				<MaskInput
					title={buscarTitleCampoDeAcordoComAsPermissoes(
						podeVisualizar,
						podeInserir,
						podeEditar,
						estadoCadastro,
						title
					)}
					id={id}
					className={buscarClassNamePelosErros(
						'p-inputtext p-state-default p-corner-all p-widget ' + (disabled && 'p-disabled'),
						errors,
						touched
					)}
					style={getStyle()}
					name={name}
					placeholder={placeholder}
					value={getValue()}
					onChange={onChange}
					disabled={disabled}
					mask={mask}
					onFocus={onFocus}
					onBlur={onBlur}
					type={type}
					autoComplete="off"
				/>
				<Button
					type="button"
					icon="fa fa-search"
					style={{ padding: '0.3rem' }}
					disabled={disabled}
					onClick={onClickPesquisar}
				/>
			</div>
			{renderizarValidacao(errors, touched)}
		</Col>
	);
}

InternalInputSearchMask.defaultProps = {
	type: 'tel',
	removerMascara: true,
};

InternalInputSearchMask.propTypes = {
	/** Evento disparado ao modificar o componente do componente */
	onChange: propTypes.func,
	/** Label do componente */
	label: propTypes.string,
	/** Identificador do componente */
	id: propTypes.string,
	/** Identificador do id da coluna componente */
	colId: propTypes.string,
	/** Placeholder do componente */
	placeholder: propTypes.string,
	/** Valor do componente do componente */
	value: propTypes.string,
	/** Define se o componente está desabilitado*/
	disabled: propTypes.bool,
	/** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
	errors: propTypes.string,
	/** Especifica se o componente foi 'tocado'*/
	touched: propTypes.bool,
	/** Especifica a máscara do componente. Ex: '111.111.111-11'*/
	mask: propTypes.string,
	/** Evento executado ao sair do campo*/
	onBlur: propTypes.any,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
	/** Estilo da coluna*/
	colStyle: propTypes.object,
	/** Estado em que o cadastro se encontra*/
	estadoCadastro: propTypes.string,
	/** Diz se o usuário possui permissão de visualizar*/
	podeVisualizar: propTypes.bool,
	/** Diz se o usuário possui permissão de editar*/
	podeEditar: propTypes.bool,
	/** Diz se o usuário possui permissão de excluir*/
	podeInserir: propTypes.bool,
	/** Title do componente*/
	title: propTypes.string,
	/** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
	helpMessage: propTypes.string,
	/** Evento ao focar no componente*/
	onFocus: propTypes.func,
	obrigatorio: propTypes.bool,
};
