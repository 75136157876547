import { services } from '../../../../Common/Constantes/api';
import { del, get, exibirToast } from '../../../../Common/Requisicoes';

export async function asyncDeleteOrganizacao(organizacaoId, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/organizacoes/${organizacaoId}`,
		null,
		exibirToast(onSuccess, 'Operação realizada com sucesso'),
		onError
	);
}

export async function asyncGetPesquisaOrganizacoes(url, onSuccess, onError) {
	await get(url, null, exibirToast(onSuccess), onError);
}

export async function asyncGetConsultaPorCnpj(cnpj, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/organizacoes/consultar_cnpj/${cnpj}`, null, onSuccess, onError);
}
