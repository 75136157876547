import {
	DOCUMENTOFISCAL,
	calcularImpostosProduto,
	copiarObjeto,
	formatarDecimais,
	formatarMonetarioDecimais,
	getSafeValue,
	removerElemento,
	usePrevious,
} from 'Common';
import {
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonSumSubtract,
	InputDouble,
	NenhumRegistroEncontrado,
	confirm,
} from 'components';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect } from 'react';
import { useWindowSize } from 'react-use';
import { useContextNFCe } from '../../../../../Context';

function ListaProdutos(props) {
	const {
		values,
		decimaisQtd,
		decimaisPreco,
		setFieldValue,
		setAtualizarTotal,
		setIndexProdutoLista,
		setModalProdutoVisible,
		isMobile,
		isTablet,
		isLessHd,
		isDesktop,
		isFullScreen,
		disabled,
	} = props;

	const { setJogarFocoParaProduto, podeInserir, informacoesPermissoes } = useContextNFCe();

	const prevProps = usePrevious(props);

	useEffect(() => {
		if (values.validacaoRecarregaProdutoPeloCliente) {
			if (
				prevProps?.values.cliente.configPrecoPraticado &&
				prevProps?.values.cliente.configPrecoPraticado !== values.cliente.configPrecoPraticado
			) {
				alterarValorProdutosPeloCliente();
			}

			setFieldValue('validacaoRecarregaProdutoPeloCliente', false);
		}
	}, [prevProps]);

	useEffect(() => {
		const element = document.getElementById('id-lista-produtos');
		element.scrollTop = element.scrollHeight;
	}, [values.produtos]);

	function alterarValorProdutosPeloCliente() {
		confirm(
			'Confirmação',
			'O cliente selecionado apresenta outra configuração de preço, deseja atualizar o valor dos produtos já inclusos? Valores informados de forma manual serão perdidos!',
			() => {
				let produtosTemp = copiarObjeto(values.produtos);

				values.produtos?.forEach((item, index) => {
					values.cliente.configPrecoPraticado === 'PRECO_ATACADO' && item.produto?.registro?.precoAtacado > 0
						? (produtosTemp[index].valor = item.produto?.registro?.precoAtacado)
						: (produtosTemp[index].valor = item.produto?.registro?.preco);

					produtosTemp[index].acessoriasItem = 0;
					produtosTemp[index].acessorias = 0;
					produtosTemp[index].descontoItem = 0;
					produtosTemp[index].desconto = 0;
					produtosTemp[index].subTotal = produtosTemp[index].valor * produtosTemp[index].quantidade;
				});

				produtosTemp = calcularImpostosTodosProdutos(produtosTemp);

				setFieldValue('produtos', produtosTemp);
				setAtualizarTotal(true);
			}
		);
	}
	function calcularImpostosTodosProdutos(produtos) {
		const itens = produtos;

		itens.map((item) => {
			const itemLocal = copiarObjeto(item);
			itemLocal.subTotal = parseFloat((item.quantidade * item.valor + item.acessorias - item.desconto).toFixed(2));
			itemLocal.tributos = calcularImpostosProduto(item, DOCUMENTOFISCAL.NFCE);
			return itemLocal;
		});

		return itens;
	}

	function renderNomeProduto(rowData) {
		const produtoNome = `${rowData.codigo} - ${rowData.descricao}`;
		const nomeResumido = produtoNome.slice(0, 50);
		function getTextoTabelaPromocional() {
			return rowData?.tabelaPreco
				? `Produto presente na tabela promocional: ${rowData?.tabelaPreco?.registro?.codigo} - ${rowData?.tabelaPreco?.registro?.nome}`
				: 'Produto presente em tabela promocional';
		}

		return (
			<span
				title={rowData.utilizouTabelaPromocional ? getTextoTabelaPromocional() : produtoNome}
				style={rowData.utilizouTabelaPromocional ? { color: '#34A835' } : {}}
			>
				{produtoNome.length <= 50 ? nomeResumido : `${nomeResumido}...`}
			</span>
		);
	}

	function renderQuantidade(item, index) {
		return (
			<span
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexDirection: isLessHd ? 'column' : 'row',
				}}
			>
				{formatarDecimais(item.quantidade, decimaisQtd)}
				<ButtonSumSubtract
					styleContainer={{ marginLeft: isLessHd ? '0px' : '8px', marginTop: isLessHd ? '5px' : '0px' }}
					onClickSum={() => alterarQuantidade('sum', item, index)}
					onClickSubtract={() => alterarQuantidade('subtract', item, index)}
					disabled={!podeInserir}
				/>
			</span>
		);
	}

	async function alterarQuantidade(operacao, item, index) {
		let { quantidade } = item;

		switch (operacao) {
			case 'sum':
				quantidade++;
				break;
			case 'subtract':
				if (item.quantidade >= 2) {
					quantidade--;
				}
				break;
			default:
				quantidade++;
				break;
		}

		const subTotal = quantidade * item.valor - getSafeValue(item.desconto);
		const tributos = await calcularImpostosProduto(
			{ ...copiarObjeto(item), quantidade: quantidade, subTotal: subTotal },
			DOCUMENTOFISCAL.NFCE
		);

		setFieldValue(`produtos[${index}].quantidade`, quantidade);
		setFieldValue(`produtos[${index}].tributos`, tributos);
		setFieldValue(`produtos[${index}].baseCalculo`, subTotal);
		setFieldValue(`produtos[${index}].subTotal`, subTotal);
		setAtualizarTotal(true);
	}

	function renderOpcoes(item, index) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => editarProduto(index)} />
				<ButtonExcluirTable onClick={() => excluirRegistro(item)} disabled={!podeInserir} {...informacoesPermissoes} />
			</div>
		);
	}

	function editarProduto(index) {
		setIndexProdutoLista(index);
		setModalProdutoVisible(true);
	}

	function excluirRegistro(item) {
		setFieldValue('produtos', removerElemento(values.produtos, item));
		setJogarFocoParaProduto(true);
		setAtualizarTotal(true);
	}

	function getHeightTableItens() {
		return isFullScreen && isDesktop && !isLessHd
			? 260
			: isLessHd && (values.cliente.nome || values.cliente.cpf || values.cliente.cnpj)
				? 373
				: isLessHd
					? 352
					: isTablet && (values.cliente.nome || values.cliente.cpf || values.cliente.cnpj)
						? 365
						: isTablet
							? 343
							: isDesktop && !isLessHd && (values.cliente.nome || values.cliente.cpf || values.cliente.cnpj)
								? 324
								: 312;
	}

	function renderInputUnitario(options) {
		return (
			<InputDouble
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				name="valor"
				value={options.value}
				placeholder={formatarDecimais(0, decimaisPreco)}
				onChange={(event) => onChangeInputUnitario(event.target.value ?? 0, options)}
				size={13}
				prefix="R$ "
				allowNegative={false}
				decimalScale={decimaisPreco}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
		);
	}

	function onChangeInputUnitario(value, options) {
		options.editorCallback(value);

		setFieldValue(`produtos[${options.rowIndex}].valor`, value);
	}

	function renderInputDesconto(options) {
		return (
			<InputDouble
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				name="desconto"
				value={options.value}
				placeholder={formatarDecimais(0, decimaisPreco)}
				onChange={(event) => onChangeInputDesconto(event.target.value ?? 0, options)}
				size={13}
				prefix="R$ "
				allowNegative={false}
				decimalScale={decimaisPreco}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
		);
	}

	function onChangeInputDesconto(value, options) {
		options.editorCallback(value);

		setFieldValue(`produtos[${options.rowIndex}].desconto`, value);
		setFieldValue(`produtos[${options.rowIndex}].descontoItem`, value);
	}

	function onCompleteEdit(e) {
		const subTotal = e.rowData.quantidade * e.newRowData.valor - getSafeValue(e.newRowData.desconto);
		const tributos = calcularImpostosProduto(
			{ ...copiarObjeto(e.newRowData), subTotal: subTotal },
			DOCUMENTOFISCAL.NFCE
		);

		setFieldValue(`produtos[${e.rowIndex}].baseCalculo`, subTotal);
		setFieldValue(`produtos[${e.rowIndex}].tributos`, tributos);
		setFieldValue(`produtos[${e.rowIndex}].subTotal`, subTotal);

		setAtualizarTotal(true);
	}

	return (
		<DataTable
			id="id-lista-produtos"
			className={`table step-nfce-listagem-produto ${
				values.produtos?.length <= 0 ? 'table-itens-nfce-empty' : 'table-itens-nfce'
			}`}
			responsive
			value={values.produtos}
			emptyMessage={<NenhumRegistroEncontrado message="Nenhum produto incluso na NFC-e" />}
			style={{
				height: `${useWindowSize().height - getHeightTableItens()}px`,
			}}
			tableStyle={{ fontSize: isLessHd ? '10px' : '14px' }}
			sortField="criadoEm"
			sortOrder={1}
			sortable
			editMode="cell"
		>
			<Column
				field="produto"
				header="Produto"
				body={(rowData) => renderNomeProduto(rowData)}
				style={{ width: '20em' }}
			/>
			<Column
				field="quantidade"
				header="Quantidade"
				body={(rowData, index) => renderQuantidade(rowData, index?.rowIndex)}
			/>
			<Column
				field="valor"
				header="Unitário"
				body={(rowData) => formatarMonetarioDecimais(rowData.valor, decimaisPreco)}
				editor={(options) => renderInputUnitario(options)}
				onCellEditComplete={onCompleteEdit}
			/>
			<Column
				field="desconto"
				header="Desconto"
				body={(rowData) => formatarMonetarioDecimais(rowData.desconto, decimaisPreco)}
				editor={(options) => renderInputDesconto(options)}
				onCellEditComplete={onCompleteEdit}
				hidden={isLessHd}
			/>
			<Column field="totalProduto" header="Total" body={(rowData) => formatarMonetarioDecimais(rowData.subTotal)} />
			<Column
				body={(rowData, index) => renderOpcoes(rowData, index?.rowIndex)}
				header="Ações"
				style={{ width: '7em' }}
			/>
		</DataTable>
	);
}

export default ListaProdutos;
