import { OverlayPanel } from "primereact/overlaypanel";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Grid, If, Modal } from "../../../../../../../../../components";
import { asyncGetIdentificacaoPAFNFCe } from "../../../../../Requests";
import ModalControleDavs from "./components/ModalControleDavs";
import ModalRegistrosPafNfce from "./components/ModalRegistrosPafNfce";
import ModalRequisicoesExternas from "./components/ModalRequisicoesExternas";
import ModalSaidasIdentificadas from "./components/ModalSaidasIdentificadas";

export function ModalMenuFiscal(props) {
    const {        
		onHide,
		isMobile,
		visible,
		isFullScreen,
	} = props;

    const panelIdentificacao = useRef(null);
    const [dadosIdentificacao, setDadosIdentificacao] = useState({});
    const [visibleModalRegistrosPafNfce, setVisibleModalRegistrosPafNfce] = useState(false);
    const [visibleModalSaidasIdentificadas, setVisibleModalSaidasIdentificadas] = useState(false);
    const [visibleModalRequisicoesExternas, setVisibleModalRequisicoesExternas] = useState(false);
    const [visibleModalControleDavs, setVisibleModalControleDavs] = useState(false);
    const [hideBackgroud, setHideBackgroud] = useState(false);

    useEffect(()=>{
        if (!visibleModalRegistrosPafNfce && 
            !visibleModalSaidasIdentificadas && 
            !visibleModalRequisicoesExternas && 
            !visibleModalControleDavs){                
            setHideBackgroud(false);
        }else{                     
            setHideBackgroud(true);            
        }
    },[
        visibleModalRegistrosPafNfce, 
        visibleModalSaidasIdentificadas, 
        visibleModalRequisicoesExternas, 
        visibleModalControleDavs
    ]);

    function identificacaoPAFNFCe(event){
        asyncGetIdentificacaoPAFNFCe(({data:identificacao})=>{            
            setDadosIdentificacao(identificacao);
        });
        return panelIdentificacao.current.toggle(event);
    }

    return (
        <>
            <Modal
                header="Menu fiscal"
                onHide={onHide}
                visible={visible}
                styleModal={{
                    maxWidth: '500px',                                    
                }}
                isMobile={isMobile}
                hideBackground={hideBackgroud}
                container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
            >              
                <OverlayPanel								
                    ref={panelIdentificacao}
                    style={{
                        right: `${isMobile ? '12px' : null}`,
                        width: document.getElementById('id-identificacao-button-nfce')?.offsetWidth,
                        margin: `${isMobile ? '0px 8px' : null}`,
                        marginTop: '0px',
                    }}
                    showCloseIcon
                    appendTo={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}				
                >
                    <div
                        style={{width:'100%'}}
                    >   
                        <span>
                            <label><b>CNPJ: </b></label>{dadosIdentificacao.cnpj}<br/>
                            <label><b>Razão social: </b></label>{dadosIdentificacao.razaoSocial}<br/><br/>

                            <label><b>Endereço: </b></label>{`${dadosIdentificacao.endereco?.logradouro} Nº ${dadosIdentificacao.endereco?.numero}`}<br/>
                            <label><b>Bairro: </b></label>{dadosIdentificacao.endereco?.bairro}<br/>
                            <label><b>Telefone: </b></label>{dadosIdentificacao.endereco?.telefone}<br/>
                            <label><b>Contato: </b></label>{dadosIdentificacao.contato}<br/><br/>

                            <label><b>Nome comercial: </b></label>{dadosIdentificacao.aplicativo?.nome}<br/>
                            <label><b>Versão: </b></label>{dadosIdentificacao.aplicativo?.versao}<br/>
                            <label><b>Arquitetura banco de dados: </b></label>{dadosIdentificacao.aplicativo?.bancoDados}<br/>
                            <label><b>Arquitetura aplicação: </b></label>{dadosIdentificacao.aplicativo?.aplicacao}<br/>
                        </span>

                    </div>
                </OverlayPanel>      
                <Grid>
                    <Col
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                    >
                        <Button
                            className="p-button-primary"
                            type="button"
                            label={"Identificação do PAF-NFC-e"}
                            style={{width:"100%", marginTop:"5px"}}
                            icon="fa fa-desktop"          
                            id="id-identificacao-button-nfce"              
                            onClick={identificacaoPAFNFCe}
                        />

                        <Button
                            className="p-button-primary"
                            type="button"
                            label={"Registros do PAF-NFC-e"}
                            style={{marginTop:"5px", width:"100%"}}
                            icon="fa fa-file-code-o"                        
                            onClick={() => setVisibleModalRegistrosPafNfce(true)}                      
                        />

                        <Button
                            className="p-button-primary"
                            type="button"
                            label={"Saídas identificadas pelo CPF/CNPJ"}
                            style={{marginTop:"5px", width:"100%"}}
                            icon="fa fa-file-code-o"                        
                            onClick={() => setVisibleModalSaidasIdentificadas(true)}                      
                        />

                        <Button
                            className="p-button-primary"
                            type="button"
                            label={"Requisições externas registradas"}
                            style={{marginTop:"5px", width:"100%"}}
                            icon="fa fa-file-code-o"
                            onClick={() => setVisibleModalRequisicoesExternas(true)}                      
                        />

                        <Button
                            className="p-button-primary"
                            type="button"
                            label={"Controle dos DAVs"}
                            style={{marginTop:"5px", width:"100%"}}
                            icon="fa fa-file-code-o"                        
                            onClick={() => setVisibleModalControleDavs(true)}                      
                        />

                    </Col>
                </Grid>
            </Modal>
            <If test={visibleModalRegistrosPafNfce}>
                <ModalRegistrosPafNfce
                    onHide={() => setVisibleModalRegistrosPafNfce(false)}
                    visible={visibleModalRegistrosPafNfce}
                    isFullScreen={isFullScreen}
                    isMobile={isMobile}
                />
            </If>
            <If test={visibleModalSaidasIdentificadas}>
                <ModalSaidasIdentificadas
                    onHide={() => setVisibleModalSaidasIdentificadas(false)}
                    visible={visibleModalSaidasIdentificadas}
                    isFullScreen={isFullScreen}
                    isMobile={isMobile}
                />
            </If>
            <If test={visibleModalRequisicoesExternas}>
                <ModalRequisicoesExternas
                    onHide={() => setVisibleModalRequisicoesExternas(false)}
                    visible={visibleModalRequisicoesExternas}
                    isFullScreen={isFullScreen}
                    isMobile={isMobile}
                />
            </If>
            <If test={visibleModalControleDavs}>
                <ModalControleDavs
                    onHide={() => setVisibleModalControleDavs(false)}
                    visible={visibleModalControleDavs}
                    isFullScreen={isFullScreen}
                    isMobile={isMobile}
                />
            </If>

        </>
    );
    
}        
