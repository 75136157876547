import { Field, useFormikContext } from 'formik';

import { buscarDadosLoginLocalStorage, copiarObjeto, services } from '../../../../../Common';

import { Grid, If, InputField, SingleSelectSetor, SingleSelectVendedorListagem, TextArea } from '../../../..';
import { moduloUtilizado as modulo } from '../../../../header/Util/constantes';

export default function InformacoesComplementares(props) {
	const {
		existeParcelaRecebida,
		informacoesPermissoes,
		setFieldValue,
		moduloUtilizado,
		buscarTabelaPreco,
		buscarMunicipioIdPessoa,
	} = props;
	const { errors, values } = useFormikContext();

	const utilizaTabelaPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosVendas?.utilizaTabelaPreco;

	const urlTipoVendaUtilizado =
		moduloUtilizado === modulo.PEDIDO_VENDA
			? 'pedidos_venda'
			: moduloUtilizado === modulo.ORCAMENTO
				? 'orcamentos'
				: 'nfes';

	async function onChangeVendedor(event) {
		setFieldValue('informacoesComplementares.vendedor', event);
		
		if (utilizaTabelaPreco) {
			const municipioId = buscarMunicipioIdPessoa(values.pessoa);
			await buscarTabelaPreco(
				{
					vendedorId: event?.value,
					clienteId: values.pessoa?.value,
					municipioId: municipioId,
				},
				({ data: tabelaPrecoApi }) => {
					setFieldValue('informacoesComplementares.tabelaPreco', {
						label: `${tabelaPrecoApi.codigo} - ${tabelaPrecoApi.nome}`,
						registro: tabelaPrecoApi,
						value: tabelaPrecoApi.id,
					});
				}
			);
			if (!event.registro?.descontoMaximoSuperior){
				let produtos = copiarObjeto(values.produtos);		
				produtos.forEach(produto => { 					
						produto.descontoMaximoVendedor = event.registro?.descontoMaximo
						if (produto.descontoMaximoTabela < event.registro?.descontoMaximo){
							produto.descontoMaximoPercentual = produto.descontoMaximoTabela
						}else{
							produto.descontoMaximoPercentual = event.registro?.descontoMaximo;
						}
					}	
				);
				setFieldValue('produtos', produtos);
			}
		}
	}

	return (
		<Grid>
			<Field
				sm="12"
				md={utilizaTabelaPreco ? '12' : '6'}
				lg={utilizaTabelaPreco ? '4' : '6'}
				xl={utilizaTabelaPreco ? '4' : '6'}
				component={SingleSelectVendedorListagem}
				name="informacoesComplementares.vendedor"
				label="Vendedor responsável"
				obrigatorio
				disabled={existeParcelaRecebida}
				isClearable={false}
				url={`${services.GESTOR}/v1/${urlTipoVendaUtilizado}/relacoes/vendedores`}
				value={values.informacoesComplementares.vendedor}
				errors={errors.informacoesComplementares?.vendedor}
				useFormErrors={false}
				useFormTouched={false}
				touched
				onChange={(event) => onChangeVendedor(event)}
				esconderBotao={true}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md={utilizaTabelaPreco ? '12' : '6'}
				lg={utilizaTabelaPreco ? '4' : '6'}
				xl={utilizaTabelaPreco ? '4' : '6'}
				component={SingleSelectSetor}
				name="informacoesComplementares.setor"
				label="Setor "
				obrigatorio
				url={`${services.GESTOR}/v1/${urlTipoVendaUtilizado}/relacoes/setores`}
				value={values.informacoesComplementares.setor}
				onChange={(e) => setFieldValue('informacoesComplementares.setor', e)}
				esconderBotao={true}
				isClearable={false}
				disabled={existeParcelaRecebida}
				id="AssinaturaSelectSetor"
				{...informacoesPermissoes}
			/>
			<If test={utilizaTabelaPreco}>
				<Field
					sm="12"
					md="12"
					lg="4"
					xl="4"
					component={InputField}
					name="tabelaPreco"
					label="Tabela preço aplicada"
					disabled
					value={values.informacoesComplementares?.tabelaPreco?.label}
					esconderBotao={true}
					isClearable={false}
				/>
			</If>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={TextArea}
				placeholder="Escreva informações que sejam importantes para você e seu cliente"
				label="Informações complementares"
				name="informacoesComplementares.informacaoComplementar"
				maxLength={4096}
				disabled={existeParcelaRecebida}
				rows={4}
				value={values.informacoesComplementares.informacaoComplementar}
				onChange={(event) => setFieldValue('informacoesComplementares.informacaoComplementar', event.target.value)}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={TextArea}
				placeholder="Escreva observações para uso interno"
				label="Observações internas"
				name="informacoesComplementares.observacaoInterna"
				maxLength={4096}
				disabled={existeParcelaRecebida}
				rows={4}
				value={values.informacoesComplementares.observacaoInterna}
				onChange={(event) => setFieldValue('informacoesComplementares.observacaoInterna', event.target.value)}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}
