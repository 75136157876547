import { Message } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';

function ErrosParametrosFiscais({ mensagemParametroFiscal }) {
	const { history } = useContextMDFe();

	function montarMensagemParametroFiscal() {
		return (
			<span>
				{`${mensagemParametroFiscal} `}
				<b
					style={{ cursor: 'pointer' }}
					onClick={() =>
						history.push({
							pathname: '/configuracoes_gerais',
							state: { configurarSerieMdfe: true },
						})
					}
				>
					Clique aqui para configurar
				</b>
			</span>
		);
	}

	return <Message severity="error" text={montarMensagemParametroFiscal()} />;
}

export { ErrosParametrosFiscais };
