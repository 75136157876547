export function converterFormParaApi(regiao) {
	return {
		id: regiao.id ?? null,
		nome: regiao.nome,
		situacao: regiao.situacao ?? 'ATIVO',
		municipios: regiao.municipios?.map((municipio) => ({
			id: regiao.municipios?.id,
			municipio: {
				id: municipio.id,
			},
		})),
		estados: regiao.estados?.map((estado) => ({
			id: regiao.estados?.id ?? null,
			estado: {
				id: estado.value ?? estado.registro?.id ?? null,
			},
		})),
	};
}

export function converterApiParaForm(regiao) {
	return {
		id: regiao.id,
		nome: regiao.nome,
		situacao: regiao.situacao,
		estados: regiao.estados?.map((estado) => ({
			value: estado.id,
			label: estado?.nome,
			registro: estado,
		})),
		municipios: converterMunicipiosParaForm(regiao.municipios),
	};
}

export function converterMunicipiosParaForm(municipios) {
	return municipios?.map((municipio) => ({
		id: municipio.id,
		idRegistro: municipio.municipio?.id,
		municipio: {
			id: municipio.municipio?.id,
			label: municipio.municipio?.nome,
			value: municipio.municipio?.id,
			...municipio.municipio,
		},
	}));
}

export function converterEstadoMunicipioParaForm(estado) {
	return {
		value: estado.id,
		label: estado?.nome,
		registro: estado,
	};
}
