import React from 'react';
import propTypes from 'prop-types';
import ampulheta from '../../../../Images/ampulheta.svg';
import assinaturaEfetuada from '../../../../Images/assinatura_efetuada.svg';
import assinaturaRejeitada from '../../../../Images/assinatura_rejeitada.svg';
import documentoExpirado from '../../../../Images/documento_expirado.svg';
import { statusDocumento } from '../../../Util/constantes';

const styleText = {
	fontSize: '20px',
};

export default function FeedbackResumoAssinaturas({ status, signatarios }) {
	let icon = null;
	let message = '';

	if (status === statusDocumento.REJEITADO) {
		icon = assinaturaRejeitada;
		message = 'Documento rejeitado!';
	} else if (status === statusDocumento.ASSINADO) {
		icon = assinaturaEfetuada;
		message = 'Documento assinado!';
	} else if (status === statusDocumento.EXPIRADO) {
		icon = documentoExpirado;
		message = 'Documento expirado!';
	} else {
		icon = ampulheta;
		let total = signatarios && signatarios.length;
		let respondidos = 0;
		signatarios &&
			signatarios.forEach(
				signatario => signatario.assinatura && signatario.assinatura.status === 'ASSINADO' && respondidos++
			);
		let restantes = total - respondidos;
		if (restantes > 1) {
			message = `${restantes} assinaturas restantes`;
		} else if (restantes === 1) {
			message = `${restantes} assinatura restante`;
		}
	}

	return (
		<center>
			<img src={icon} style={{ width: '50px' }} alt="" />
			<div>
				<span style={styleText}> {message} </span>
			</div>
		</center>
	);
}

FeedbackResumoAssinaturas.propTypes = {
	signatarios: propTypes.array,
	status: propTypes.string,
};
