import { useFormikContext } from 'formik';

import { ButtonAdicionarItem, Card, Col, Grid, NenhumRegistroEncontrado } from 'components';
import { copiarObjeto, gerarUUID } from 'Common';

import { useContextTabelaPreco } from 'views/cadastros/TabelaPreco/Context';
import { AlteracaoPercentualPorQuantidade } from '../AlteracaoPercentualPorQuantidade';

function DescontoPorQuantidade() {
	const { values, setFieldValue, errors } = useFormikContext();
	const { desabilitarCampos, isMobile, isHeaderEditing } = useContextTabelaPreco();

	function deleteItem(index) {
		const arrayTemp = copiarObjeto(values.descontosPorQuantidade);
		arrayTemp?.splice(index, 1);

		arrayTemp.forEach((_, index) => {
			arrayTemp[index].item = index + 1;
		});

		setFieldValue('descontosPorQuantidade', arrayTemp);
		isHeaderEditing.current = true;
	}

	function addItem() {
		const arrayTemp = copiarObjeto(values.descontosPorQuantidade);

		arrayTemp?.push({
			id: null,
			idTemporario: gerarUUID(),
			item: values.descontosPorQuantidade.length + 1,
			valorInicial: null,
			valorFinal: null,
			percentualAplicado: null,
		});

		setFieldValue('descontosPorQuantidade', arrayTemp);
		isHeaderEditing.current = true;
	}

	return (
		<Card>
			<Grid
				style={{
					flexDirection: 'column',
					flexWrap: 'nowrap',
					padding: '8px',
					maxHeight: isMobile ? '320px' : '240px',
					overflowY: 'auto',
					overflowX: 'hidden',
				}}
			>
				{values.descontosPorQuantidade?.length > 0 ? (
					values.descontosPorQuantidade?.map((descontoPorQuantidade, index) => (
						<AlteracaoPercentualPorQuantidade
							key={`${descontoPorQuantidade?.idTemporario ?? descontoPorQuantidade?.id}`}
							isInput
							isAcrescimo={false}
							registro={descontoPorQuantidade}
							onChangeValorInicial={(e) => {
								isHeaderEditing.current = true;
								setFieldValue(`descontosPorQuantidade[${index}].valorInicial`, e);
							}}
							onChangeValorFinal={(e) => {
								isHeaderEditing.current = true;
								setFieldValue(`descontosPorQuantidade[${index}].valorFinal`, e);
							}}
							onChangePercentualAplicado={(e) => {
								isHeaderEditing.current = true;
								setFieldValue(`descontosPorQuantidade[${index}].percentualAplicado`, e);
							}}
							excluirItem={() => deleteItem(index)}
							errors={errors.descontosPorQuantidade && errors.descontosPorQuantidade[index]}
						/>
					))
				) : (
					<NenhumRegistroEncontrado />
				)}
			</Grid>
			<Grid>
				<Col sm="4" md="4" lg="4" xl="4">
					<ButtonAdicionarItem onClick={() => addItem()} disabled={desabilitarCampos} />
				</Col>
			</Grid>
		</Card>
	);
}

export { DescontoPorQuantidade };
