import ProdutosReferenciaFornecedor from './Form';
import { Modal, If } from '../../../../../../components';

export default function ModalProdutos(props) {
	return (
		<Modal
			header="Referência de fornecedor"
			visible={props.visible}
			onHide={props.onHide}
			styleModal={{ maxWidth: '1200px' }}
			container={props.appendTo}
		>
			<If test={props.visible}>
				<ProdutosReferenciaFornecedor valuesFornecedor={props.values} />
			</If>
		</Modal>
	);
}
