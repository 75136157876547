import { Fragment, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import '../../Styles/index.css';

function CardUtilizacao({ planoAtual }) {
	const [labelsUtilizacao, setLabelsUtilizacao] = useState(['-']);
	const prevProps = usePrevious(planoAtual);

	useEffect(() => {
		if (prevProps?.id !== planoAtual?.id) {
			atualizarLabelsUtilizacao();
		}
	}, [planoAtual]);

	useEffect(() => {
		atualizarLabelsUtilizacao();
	}, []);

	function atualizarLabelsUtilizacao() {
		const labelsUtilizacao = [];

		if (planoAtual && planoAtual.utilizado) {
			planoAtual.utilizado.labels.forEach((label) => {
				labelsUtilizacao.push(label);
			});
		}
		setLabelsUtilizacao(labelsUtilizacao);
	}

	return (
		<div className="cardStyle" style={{ overflow: 'scroll', overflowX: 'hidden' }}>
			<p>
				{labelsUtilizacao.map((item) => (
					<Fragment key={item}>
						{item} <br />
					</Fragment>
				))}
			</p>
		</div>
	);
}

export { CardUtilizacao };
