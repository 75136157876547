import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';
import { useEffect } from 'react';

import { TabView } from 'components';

import { useContextMDFe } from 'views/transporte/MDFe/Context';

import { Condutores } from './components/Condutores';
import { Documentos } from './components/Documentos';
import { Reboques } from './components/Reboques';
import { ClassNameTabViewColor, MDFE_SITUACAO } from '../../Util/constantes';

function TabsPrincipais() {
	const { activeTabPrincipais, setActiveTabPrincipais } = useContextMDFe();
	const { values } = useFormikContext();
	const disabled = [MDFE_SITUACAO.ENCERRADO, MDFE_SITUACAO.TRANSMITIDO, MDFE_SITUACAO.CANCELADO].includes(
		values.situacao
	);

	useEffect(() => {
		if (values.informacaoComplementar.permiteCarregamentoPosterior) {
			setActiveTabPrincipais(1);
		} else {
			setActiveTabPrincipais(0);
		}
	}, [values.informacaoComplementar.permiteCarregamentoPosterior]);

	return (
		<div>
			<TabView
				className={`tab-view-no-border ${ClassNameTabViewColor[values.situacao]}`}
				activeIndex={activeTabPrincipais}
				onTabChange={({ index }) => setActiveTabPrincipais(index)}
				renderActiveOnly={false}
			>
				<TabPanel
					header="Documentos"
					headerStyle={values.informacaoComplementar.permiteCarregamentoPosterior ? { display: 'none' } : null}
				>
					<Documentos disabled={disabled} />
				</TabPanel>
				<TabPanel header="Condutores">
					<Condutores disabled={disabled} />
				</TabPanel>
				<TabPanel header="Reboques">
					<Reboques disabled={disabled} />
				</TabPanel>
			</TabView>
		</div>
	);
}

export { TabsPrincipais };
