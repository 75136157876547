import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

import { Col, Grid } from '../../../../../../components';
import { formatarMonetario } from '../../../../../../Common';

import { Financas } from '../../../../../Util/constantes';

const styleCardContent = {
	borderRadius: '5px',
	cursor: 'pointer',
};

const styleCloseIcon = {
	float: 'right',
	margin: '-10px -10px 0px -10px',
	fontSize: '20px',
	backgroundColor: 'white',
	borderRadius: '50%',
	color: Financas.cores.azul,
};

const styleCircleIcon = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '35px',
	height: '35px',
};

const styleTitle = {
	fontSize: '14px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'end',
	padding: '0px',
	cursor: 'pointer',
};

const styleValue = {
	fontSize: '18px',
	marginTop: '5px',
	fontWeight: 'bold',
	overflow: 'hidden',
};

function CardSelectable({ primaryColor, title, icon, value, onSelect, name, selected, helpMessage, titleFiltro }) {
	const getContentStyle = () => {
		if (selected) {
			return {
				...styleCardContent,
				border: `2px solid ${Financas.cores.azul}`,
				boxShadow: `2px 4px 10px 0px ${Financas.cores.cinza}`,
			};
		}
		return styleCardContent;
	};

	const titleFiltrar = !selected ? titleFiltro : `Clique para limpar o filtro`;

	return (
		<div className="p-card p-total-list-cards" onClick={() => onSelect(name)} style={getContentStyle()}>
			{selected ? <AiFillCloseCircle style={styleCloseIcon} /> : null}
			<Grid verticalAlignCenter justifyBetween style={{ margin: '0px' }}>
				<Col col="auto" title={titleFiltrar}>
					<div style={styleCircleIcon}>{icon}</div>
				</Col>
				<Col col="10">
					<Grid justifyEnd verticalAlignCenter style={{ margin: '0px' }}>
						<div className="p-col" style={styleTitle} title={helpMessage}>
							{title}
						</div>
					</Grid>
					<Grid justifyEnd style={{ margin: '0px' }} title={titleFiltrar}>
						<div style={{ ...styleValue, color: primaryColor }}>{formatarMonetario(value)}</div>
					</Grid>
				</Col>
			</Grid>
		</div>
	);
}

export default CardSelectable;
