import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { buscarDadosLoginLocalStorage, formatarMonetario, removerElemento } from '../../../../../../Common';
import { formatarDecimais, formatarMonetarioDecimais } from '../../../../../../Common/Mascara';
import { ButtonEditarTable, ButtonExcluirTable, NenhumRegistroEncontrado } from '../../../../../../components';
import { confirmarExclusao } from '../../../../../Util/ExclusaoDeRegistros';
import { useFormikContext } from 'formik';

const styleColumn = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

function TabelaItens({
	sortField,
	sortOrder,
	isMobile,
	podeExcluir,
	podeEditar,
	isFinalizado,
	setRegistroSelecionado,
	setModalProdutos,
}) {
	const { values, setFieldValue } = useFormikContext();

	const decimaisQtd = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
	const decimaisPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	function renderProduto(row) {
		return (
			<span
				style={{
					display: 'flex',
					alignItems: 'center',
					wordBreak: 'keep-all',
					paddingLeft: isMobile ? '15%' : '0',
				}}
			>
				{row.produto?.nome}
			</span>
		);
	}

	function renderAcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEdit(row)} />
				<ButtonExcluirTable
					onClick={() => onDelete(row)}
					podeExcluir={podeExcluir}
					disabled={isFinalizado || (values.id && !podeEditar)}
				/>
			</div>
		);
	}

	function renderQuantidade(row) {
		return row.quantidadeConvertida !== row.quantidade
			? formatarDecimais(row.quantidade, decimaisQtd) + ` → ` + formatarDecimais(row.quantidadeConvertida, decimaisQtd)
			: formatarDecimais(row.quantidade, decimaisQtd);
	}

	function onEdit(row) {
		setRegistroSelecionado(row);
		setModalProdutos(true);
	}

	function onDelete(item) {
		confirmarExclusao(() => {
			setFieldValue('itens', removerElemento(values.itens, item));
		});
	}

	return (
		<DataTable
			className="table"
			rowClassName="table-row"
			cellClassName="table-row-cell"
			sortField={sortField}
			sortOrder={sortOrder}
			responsive
			value={values.itens}
			style={{
				width: '100%',
				overflowY: values.itens.length > 6 ? 'scroll' : 'hidden',
				overflowX: 'hidden',
				maxHeight: isMobile ? '36.4rem' : '18.2rem',
			}}
			emptyMessage={<NenhumRegistroEncontrado />}
		>
			<Column
				header="Item"
				field="item"
				body={(row) => row.item}
				sortable
				style={{
					...styleColumn,
					width: '5%',
				}}
			/>
			<Column
				header="Produto"
				field="produto"
				body={(row) => renderProduto(row)}
				sortable
				style={{
					...styleColumn,
					width: '50%',
				}}
			/>
			<Column
				header="Quantidade"
				field="quantidade"
				body={(row) => renderQuantidade(row)}
				sortable
				style={{
					...styleColumn,
					width: '15%',
				}}
			/>
			<Column
				header="Unitário"
				field="unitario"
				body={(row) => formatarMonetarioDecimais(row.valorUnitario, decimaisPreco)}
				sortable
				style={{
					...styleColumn,
					width: '15%',
				}}
			/>
			<Column
				header="Total"
				body={(row) => formatarMonetario(row.subTotal)}
				sortable
				style={{
					...styleColumn,
					width: '15%',
				}}
			/>
			<Column
				header="CFOP"
				field="cfop"
				body={(row) => row.cfop?.codigo}
				sortable
				style={{
					...styleColumn,
					width: '10%',
				}}
			/>
			<Column
				header="CST"
				field="cst"
				body={(row) => row.icms?.cst?.codigo ?? row.cst?.codigo}
				sortable
				style={{
					...styleColumn,
					width: '15%',
				}}
			/>
			<Column
				header="Ações"
				body={renderAcoes}
				style={{
					...styleColumn,
					width: 'auto',
				}}
			/>
		</DataTable>
	);
}

export default TabelaItens;
