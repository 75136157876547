import { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import {
	Prompt,
	ButtonExcluirTable,
	ButtonNovo,
	Modal,
	If,
	ButtonEditarTable,
	Message,
	NenhumRegistroEncontrado,
} from 'components';

import { removerElementoPorId, estadosCadastro, formatarTelefone, PAPEL_SIGNATARIOS } from 'Common';

import DocumentoSignatariosForm from './Form';

function DocumentoSignatarios(props) {
	const { dirty, value, informacoesPermissoes, disabled, estadoCadastro, isMobile, isTablet } = props;

	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [modalFormVisible, setModalFormVisible] = useState(false);

	const acoesStyle = !isMobile && { textAlign: 'end' };

	const podeInserir =
		estadoCadastro === estadosCadastro.INCLUSAO ? informacoesPermissoes.podeInserir : informacoesPermissoes.podeEditar;

	function novo() {
		setModalFormVisible(true);
		setRegistroSelecionado(null);
	}

	function onEditar(row) {
		setModalFormVisible(true);
		setRegistroSelecionado(row);
	}

	function renderOpcoes(row) {
		return (
			<div>
				<ButtonEditarTable onClick={() => onEditar(row)} {...informacoesPermissoes} />
				<ButtonExcluirTable
					onClick={() => excluirRegistro(row)}
					disabled={disabled}
					podeExcluir={informacoesPermissoes.podeEditar}
				/>
			</div>
		);
	}

	function hideModal() {
		setModalFormVisible(false);
		setRegistroSelecionado(null);
	}

	function atualizarRegistro(dadosFormulario) {
		const result =
			value &&
			value.map((item) => {
				if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
					return dadosFormulario;
				} else if (item.id && item.id === dadosFormulario.id) {
					return dadosFormulario;
				}
				return item;
			});

		setRegistroSelecionado(dadosFormulario);
		props.onChange(result);
	}

	function inserirRegistro(dadosFormulario) {
		props.onChange([...props.value, dadosFormulario]);
		setRegistroSelecionado(dadosFormulario);
	}

	function excluirRegistro(dadosFormulario) {
		let nomeColunaId = 'id';
		if (dadosFormulario.idTemporario) {
			nomeColunaId = 'idTemporario';
		}
		props.onChange(removerElementoPorId(props.value, dadosFormulario, nomeColunaId));
		setRegistroSelecionado(null);
	}

	function atualizarRegistroSelecionado(registroSelecionado) {
		setRegistroSelecionado(registroSelecionado);
	}

	function renderPapel(row) {
		return PAPEL_SIGNATARIOS?.find((papel) => papel?.value === row?.papel)?.label;
	}

	function renderTelefone(row) {
		return row.telefone ? formatarTelefone(row.telefone) : '';
	}

	function esconderMessageEmail() {
		if (!value) {
			return true;
		}

		for (let i = 0; i < value.length; i++) {
			if (!value[i].email) {
				return false;
			}
		}
		return true;
	}

	return (
		<>
			<Modal
				visible={modalFormVisible}
				header="Cadastro de signatário"
				onHide={() => hideModal()}
				styleModal={{ height: isMobile ? '40rem' : isTablet ? '38rem' : '32rem' }}
			>
				<If test={modalFormVisible}>
					<DocumentoSignatariosForm
						registros={value}
						registroSelecionado={registroSelecionado}
						onNovoClick={() => setRegistroSelecionado(null)}
						excluirRegistro={(e) => excluirRegistro(e)}
						inserirRegistro={(e) => inserirRegistro(e)}
						atualizarRegistro={(e) => atualizarRegistro(e)}
						onHide={() => hideModal()}
						disabled={disabled}
						informacoesPermissoes={informacoesPermissoes}
						atualizarRegistroSelecionado={(e) => atualizarRegistroSelecionado(e)}
						estadoCadastro={estadoCadastro}
						copiarLink={() => props.copiarLink()}
						documentoStatus={props.documentoStatus}
					/>
				</If>
			</Modal>

			<Prompt dirty={dirty} />
			<ButtonNovo
				label="Novo signatário"
				enableShortCut={false}
				onClick={() => novo()}
				disabled={disabled}
				podeInserir={podeInserir}
			/>
			<Message
				hidden={esconderMessageEmail()}
				severity="error"
				text="Informe um e-mail válido para todos os signatários"
			/>
			<DataTable
				className="table"
				responsive
				value={value}
				emptyMessage={<NenhumRegistroEncontrado message="Nenhum signatário encontrado" />}
			>
				<Column field="nome" header="Nome" sortable={true} style={{ width: '25rem' }} />
				<Column field="email" header="E-mail" sortable={true} style={{ width: '20rem' }} />
				<Column
					field="telefone"
					header="Telefone"
					body={(row) => renderTelefone(row)}
					sortable={true}
					style={{ width: '15rem' }}
				/>
				<Column field="papel" body={(row) => renderPapel(row)} header="Assinar como" sortable={true} />
				<Column header="Ações" body={(row) => renderOpcoes(row)} style={{ width: '7em', ...acoesStyle }} />
			</DataTable>
		</>
	);
}

export default DocumentoSignatarios;
