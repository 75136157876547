import { services } from '../../../../../../../Common/Constantes/api';
import { get, post, del, put, exibirToast } from '../../../../../../../Common/Requisicoes';

export async function asyncGetProdutos(onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/produtos/resumo`, null, onSuccess, onError, true);
}

export async function asyncGetRelacao(idRelacao, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/fornecedores/referencia_produto/${idRelacao}`, null, onSuccess, onError);
}

export async function asyncCreateRelacao(referencia, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/fornecedores/referencia_produto`,
		referencia,
		null,
		exibirToast(onSuccess, 'Relação criada com sucesso'),
		onError
	);
}

export async function asyncUpdateRelacao(idRelacao, relacao, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/fornecedores/referencia_produto/${idRelacao}`,
		relacao,
		null,
		exibirToast(onSuccess, 'Relacao atualizada com sucesso'),
		onError
	);
}

export async function asyncDeleteRelacao(idRelacao, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/fornecedores/referencia_produto/${idRelacao}`,
		null,
		exibirToast(onSuccess, 'Relação excluída com sucesso'),
		onError
	);
}
