import propTypes from 'prop-types';
import sub from 'date-fns/sub';
import format from 'date-fns/format';
import add from 'date-fns/add';
import startOfMonth from 'date-fns/startOfMonth';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import isValid from 'date-fns/isValid';
import lastDayOfWeek from 'date-fns/lastDayOfWeek';
import startOfWeek from 'date-fns/startOfWeek';
import isBefore from 'date-fns/isBefore';
import ptLocale from 'date-fns/locale/pt-BR';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import {
	isLastDayOfMonth,
	differenceInDays,
	isDate,
	parseISO,
	isSameDay,
	isSameWeek,
	isSunday,
	isSaturday,
	isSameMonth,
	isFirstDayOfMonth,
	endOfMonth,
	formatISO,
} from 'date-fns/esm';

import { useEffect, useRef, useState } from 'react';
import Grid from '../Grid';
import Col from '../Col';
import InputDate from '../input/InputDate';
import If from '../If';
import useStateCallback from '../../Common/Hooks/useStateCallback';

const styleDataAtual = {
	cursor: 'pointer',
	padding: '3px',
};

const styleMenuItemOption = {
	padding: '7px',
	display: 'block',
	fontWeight: 'bold',
	minWidth: '150px',
};

const styleGridContent = {
	minWidth: '245px',
	maxWidth: '245px',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '2px',
};

const styleButtonStep = {
	borderRadius: '50%',
	padding: '0.4rem 0rem',
};

function ItemSelecionavel({ title, onSelect, selected }) {
	const estiloBotao = selected ? 'primary' : 'secondary';
	return (
		<span
			id={title}
			className={`p-inputgroup-addon p-button p-button-${estiloBotao}`}
			style={styleMenuItemOption}
			onClick={onSelect}
		>
			{title}
		</span>
	);
}

const ESTILO = {
	DIA: 'DIA',
	SEMANA: 'SEMANA',
	MES: 'MES',
	PERSONALIZADO: 'PERSONALIZADO',
};

function DateInterval(props) {
	const { interval, onChange, disabled, appendTo = document.body } = props;

	const [dataInicial, setDataInicial] = useState(interval?.dataInicial ?? startOfMonth(new Date()));
	const [dataFinal, setDataFinal] = useState(interval?.dataFinal ?? endOfMonth(new Date()));

	const [dataInicialPersonalizada, setDataInicialPersonalizada] = useState(null);
	const [dataFinalPersonalizada, setDataFinalPersonalizada] = useState(null);
	const [validateMessage, setValidateMessage] = useState('');
	const [isIntervalValid, setIsIntervalValid] = useState(false);
	const [isConfirmed, setIsConfirmed] = useState(false);
	const overlaySelecao = useRef(null);
	const overlayData = useRef(null);

	useEffect(() => {
		const dataInicial = props.interval?.dataInicial ?? buscarDataInicial();
		const dataFinal = props.interval?.dataFinal ?? buscarDataFinal();

		// const estilo = buscarEstiloData(dataInicial, dataFinal);
		// pesquisarDataComBaseNoEstilo(estilo);
	}, []);

	useEffect(() => {
		if (isIntervalValid) {
			atualizarIntervalo(dataInicialPersonalizada, dataFinalPersonalizada);
			overlayData.current.hide();
		}
		setIsConfirmed(false);
	}, [isConfirmed]);

	useEffect(() => {
		setDataInicial(interval?.dataInicial ?? startOfMonth(new Date()));
		setDataFinal(interval?.dataFinal ?? endOfMonth(new Date()));
	}, [interval]);

	function buscarDataInicial() {
		if (props.interval?.dataInicial) {
			return props.interval?.dataInicial;
		} else {
			return dataInicial;
		}
	}

	function buscarDataFinal() {
		if (props.interval?.dataFinal) {
			return props.interval?.dataFinal;
		} else {
			return dataFinal;
		}
	}

	function atualizarIntervalo(novaDataInicial, novaDataFinal) {
		setDataInicial(novaDataInicial);
		setDataFinal(novaDataFinal);
		if (onChange) {
			onChange({ dataInicial: novaDataInicial, dataFinal: novaDataFinal });
		}
	}

	function buscarEstiloData(dataInicio, dataFim) {
		if (isSameDay(dataInicio, dataFim) && isSameDay(dataInicio, new Date()) && isSameDay(dataFim, new Date())) {
			return ESTILO.DIA;
		} else if (isSameWeek(dataInicio, dataFim) && isSunday(dataInicio) && isSaturday(dataFim)) {
			return ESTILO.SEMANA;
		} else if (isSameMonth(dataInicio, dataFim) && isFirstDayOfMonth(dataInicio) && isLastDayOfMonth(dataFim)) {
			return ESTILO.MES;
		} else {
			return ESTILO.PERSONALIZADO;
		}
	}

	function adicionar() {
		const dataInicial = buscarDataInicial();
		const dataFinal = buscarDataFinal();

		switch (buscarEstiloData(dataInicial, dataFinal)) {
			case ESTILO.DIA: {
				const novoValor = add(dataInicial, { days: 1 });

				atualizarIntervalo(novoValor, novoValor);

				break;
			}
			case ESTILO.SEMANA: {
				const novoValorInicial = add(dataInicial, { weeks: 1 });
				const novoValorFinal = add(dataFinal, { weeks: 1 });

				atualizarIntervalo(novoValorInicial, novoValorFinal);

				break;
			}
			case ESTILO.MES: {
				const novoValorInicial = startOfMonth(add(dataInicial, { months: 1 }));
				const novoValorFinal = lastDayOfMonth(add(dataFinal, { months: 1 }));

				atualizarIntervalo(novoValorInicial, novoValorFinal);

				break;
			}
			case ESTILO.PERSONALIZADO: {
				if (dataInicial && dataFinal) {
					const diffInDays = differenceInDays(dataFinal, dataInicial);
					const daysToAdd = diffInDays === 0 ? 1 : diffInDays;

					const novoValorInicial = add(dataInicial, { days: daysToAdd });
					const novoValorFinal = add(dataFinal, { days: daysToAdd });

					atualizarIntervalo(novoValorInicial, novoValorFinal);
				}
				break;
			}
			default:
				break;
		}
	}

	function buscarTitleBotaoProximo() {
		const formatoData = buscarEstiloData(buscarDataInicial(), buscarDataFinal());

		if (formatoData === ESTILO.DIA) {
			return 'Próximo dia';
		} else if (formatoData === ESTILO.SEMANA) {
			return 'Próxima semana';
		} else if (formatoData === ESTILO.MES) {
			return 'Próximo mês';
		} else {
			return 'Próximo intervalo';
		}
	}

	function buscarTitleBotaoAnterior() {
		const dataInicial = buscarDataInicial();
		const dataFinal = buscarDataFinal();

		const formatoData = buscarEstiloData(dataInicial, dataFinal);

		if (formatoData === ESTILO.DIA) {
			return 'Dia anterior';
		} else if (formatoData === ESTILO.SEMANA) {
			return 'Semana anterior';
		} else if (formatoData === ESTILO.MES) {
			return 'Mês anterior';
		} else {
			return 'Intervalo anterior';
		}
	}

	function reduzir() {
		const dataInicial = buscarDataInicial();
		const dataFinal = buscarDataFinal();

		switch (buscarEstiloData(dataInicial, dataFinal)) {
			case ESTILO.DIA: {
				const novoValor = sub(dataInicial, { days: 1 });

				atualizarIntervalo(novoValor, novoValor);

				break;
			}
			case ESTILO.SEMANA: {
				const novoValorInicial = sub(dataInicial, { weeks: 1 });
				const novoValorFinal = sub(dataFinal, { weeks: 1 });

				atualizarIntervalo(novoValorInicial, novoValorFinal);

				break;
			}
			case ESTILO.MES: {
				const novoValorInicial = startOfMonth(sub(dataInicial, { months: 1 }));
				const novoValorFinal = lastDayOfMonth(sub(dataFinal, { months: 1 }));

				atualizarIntervalo(novoValorInicial, novoValorFinal);

				break;
			}
			case ESTILO.PERSONALIZADO: {
				if (dataInicial && dataFinal) {
					const diffInDays = differenceInDays(dataFinal, dataInicial);
					const daysToAdd = diffInDays === 0 ? 1 : diffInDays;

					const novoValorInicial = sub(dataInicial, { days: daysToAdd });
					const novoValorFinal = sub(dataFinal, { days: daysToAdd });

					atualizarIntervalo(novoValorInicial, novoValorFinal);
				}
				break;
			}
			default:
				break;
		}
	}

	function buscarDataSelecionada() {
		const dataInicial = buscarDataInicial();
		const dataFinal = buscarDataFinal();

		switch (buscarEstiloData(dataInicial, dataFinal)) {
			case ESTILO.DIA: {
				return format(dataInicial, "d 'de' LLL", { locale: ptLocale }).toUpperCase();
			}
			case ESTILO.SEMANA: {
				return `${format(dataInicial, 'dd', { locale: ptLocale })} a ${format(dataFinal, 'dd', {
					locale: ptLocale,
				})} DE ${format(dataFinal, 'LLL', { locale: ptLocale }).toUpperCase()}`;
			}
			case ESTILO.MES: {
				return format(dataInicial, "LLL '-' yyyy", { locale: ptLocale }).toUpperCase();
			}
			case ESTILO.PERSONALIZADO: {
				if (isValid(dataInicial) && isValid(dataFinal)) {
					return `${format(dataInicial, 'dd LLL yy', { locale: ptLocale }).toUpperCase()} à ${format(
						dataFinal,
						'dd LLL yy',
						{ locale: ptLocale }
					).toUpperCase()}`;
				}
				return 'Selecione uma data';
			}
			default:
				break;
		}
	}

	function pesquisarDataComBaseNoEstilo(estilo) {
		switch (estilo) {
			case ESTILO.DIA: {
				atualizarIntervalo(new Date(), new Date());
				overlaySelecao.current.hide();

				break;
			}
			case ESTILO.SEMANA: {
				atualizarIntervalo(startOfWeek(new Date()), lastDayOfWeek(new Date()));
				overlaySelecao.current.hide();

				break;
			}
			case ESTILO.MES: {
				atualizarIntervalo(startOfMonth(new Date()), lastDayOfMonth(new Date()));
				overlaySelecao.current.hide();

				break;
			}
			default:
				break;
		}
	}

	async function datasValidas(dataInicial, dataFinal) {
		if (!dataInicial || !isDate(dataInicial) || !isValid(dataInicial)) {
			setValidateMessage('Intervalo de datas inválido');
			setIsIntervalValid(false);
		} else if (!dataFinal || !isDate(dataFinal) || !isValid(dataFinal)) {
			setValidateMessage('Intervalo de datas inválido');
			setIsIntervalValid(false);
		} else if (isBefore(dataFinal, dataInicial)) {
			setValidateMessage('Data inicial maior que a final');
			setIsIntervalValid(false);
		} else if (differenceInDays(dataFinal, dataInicial) >= 367) {
			setValidateMessage('Intervalo máximo permitido é 1 ano');
			setIsIntervalValid(false);
		} else {
			setValidateMessage('');
			setIsIntervalValid(true);
		}
	}

	async function onConfirm(event) {
		event.preventDefault();
		await datasValidas(dataInicialPersonalizada, dataFinalPersonalizada);
		setIsConfirmed(true);
	}

	const dataInicialSelecionada = buscarDataInicial();
	const dataFinalSelecionada = buscarDataFinal();
	const formatoData = buscarEstiloData(dataInicialSelecionada, dataFinalSelecionada);

	return (
		<>
			<div style={styleGridContent}>
				<Button
					style={styleButtonStep}
					className="p-button p-button-primary"
					icon="fa fa-arrow-left"
					onClick={reduzir}
					disabled={disabled}
					title={buscarTitleBotaoAnterior()}
				/>
				<span
					style={disabled ? { ...styleDataAtual, cursor: 'default' } : styleDataAtual}
					onClick={(e) => {
						if (!disabled) {
							overlayData.current.hide();
							overlaySelecao.current.show(e);
						}
					}}
					aria-controls="overlay_panel"
					aria-haspopup
				>
					<span className="link_to" style={{ fontWeight: 'bold', color: '#006095' }} id="dateIntervalDataSelecionada">
						{buscarDataSelecionada()}
					</span>
				</span>
				<Button
					style={styleButtonStep}
					className="p-button p-button-primary"
					icon="fa fa-arrow-right"
					title={buscarTitleBotaoProximo()}
					onClick={adicionar}
					disabled={disabled}
				/>
			</div>
			<OverlayPanel ref={overlaySelecao} showCloseIcon appendTo={appendTo}>
				<ItemSelecionavel
					title="HOJE"
					onSelect={() => pesquisarDataComBaseNoEstilo(ESTILO.DIA)}
					selected={formatoData === ESTILO.DIA}
				/>
				<ItemSelecionavel
					title="ESTA SEMANA"
					onSelect={() => pesquisarDataComBaseNoEstilo(ESTILO.SEMANA)}
					selected={formatoData === ESTILO.SEMANA}
				/>
				<ItemSelecionavel
					title="ESTE MÊS"
					onSelect={() => pesquisarDataComBaseNoEstilo(ESTILO.MES)}
					selected={formatoData === ESTILO.MES}
				/>
				<ItemSelecionavel
					title="ESCOLHER"
					onSelect={(e) => {
						overlaySelecao.current.hide();
						overlayData.current.show(e, document.getElementById('dateIntervalDataSelecionada'));
					}}
					selected={formatoData === ESTILO.PERSONALIZADO}
				/>
			</OverlayPanel>
			<OverlayPanel ref={overlayData} showCloseIcon dismissable={false} appendTo={appendTo}>
				<form onSubmit={onConfirm} style={{ width: '245px' }}>
					<InputDate
						sm="12"
						md="12"
						lg="12"
						xl="12"
						obrigatorio
						label="Data inicial"
						name="dataInicialPersonalizada"
						onChange={(e) => {
							if (e.target.value) {
								setDataInicialPersonalizada(parseISO(e.target.value));
							} else {
								setDataInicialPersonalizada(null);
							}
						}}
						value={dataInicialPersonalizada ? formatISO(dataInicialPersonalizada) : dataInicialPersonalizada}
						disabled={disabled}
					/>
					<InputDate
						sm="12"
						md="12"
						lg="12"
						xl="12"
						obrigatorio
						label="Data final"
						name="dataFinal"
						onChange={(e) => {
							if (e.target.value) {
								setDataFinalPersonalizada(parseISO(e.target.value));
							} else {
								setDataFinalPersonalizada(null);
							}
						}}
						value={dataFinalPersonalizada ? formatISO(dataFinalPersonalizada) : dataFinalPersonalizada}
						disabled={disabled}
					/>
					<Grid justifyCenter>
						<Col col="12" style={{ display: 'flex', justifyContent: 'space-around' }}>
							<Button
								label="Confirmar"
								disabled={
									!dataInicialSelecionada ||
									!dataFinalSelecionada ||
									!isValid(dataInicialSelecionada) ||
									!isValid(dataFinalSelecionada) ||
									(dataInicialPersonalizada === null && dataFinalPersonalizada === null)
								}
								onClick={onConfirm}
							/>
							<Button
								className="p-button-secondary"
								label="Cancelar"
								onClick={(e) => {
									e.preventDefault(),
										setValidateMessage(''),
										setIsIntervalValid(false),
										setDataInicialPersonalizada(null),
										setDataFinalPersonalizada(null),
										() => {
											overlayData.current.hide();
										};
								}}
							/>
						</Col>
						<If test={!isIntervalValid}>
							<div style={{ color: '#e91224', fontSize: '13px' }}>{validateMessage}</div>
						</If>
					</Grid>
				</form>
			</OverlayPanel>
		</>
	);
}

DateInterval.propTypes = {
	interval: propTypes.shape({
		dataInicial: propTypes.object,
		dataFinal: propTypes.object,
	}),
	disabled: propTypes.bool,
};

export default DateInterval;
