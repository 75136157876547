import propTypes from 'prop-types';
import { SelectableCard, SimpleCard } from '../../../../../../../../components';

function CardTotalizadorListagem(props) {
  const { selectable } = props;
  if (selectable) {
    return <SelectableCard {...props} />;
  } else {
    return <SimpleCard {...props} />;
  }
}

CardTotalizadorListagem.defaultProps = {
  selectable: false,
};

CardTotalizadorListagem.propTypes = {
  selectable: propTypes.bool,
  selected: propTypes.bool,
  onSelect: propTypes.func,
  value: propTypes.number.isRequired,
  colors: propTypes.object.isRequired,
  title: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
};

export default CardTotalizadorListagem;
