import { createContext, useContext, useState } from 'react';
import { METODO_PAGAMENTO } from '../Util/constantes';

const ContextPlanos = createContext();

export default function PlanosProvider(props) {
	const { children } = props;

	const [visibleModalPagarComPix, setVisibleModalPagarComPix] = useState(false);
	const [selecionarMetodo, setSelecionarMetodo] = useState(false);
	const [isFormDadosCartao, setIsFormDadosCartao] = useState(false);
	const [meioPagamento, setMeioPagamento] = useState(METODO_PAGAMENTO.PIX);
	const [activeIndex, setActiveIndex] = useState(0);

	return (
		<ContextPlanos.Provider
			value={{
				meioPagamento,
				setMeioPagamento,
				isFormDadosCartao,
				setIsFormDadosCartao,
				selecionarMetodo,
				setSelecionarMetodo,
				visibleModalPagarComPix,
				setVisibleModalPagarComPix,
				activeIndex,
				setActiveIndex,
			}}
		>
			{children || null}
		</ContextPlanos.Provider>
	);
}

export const useContextPlanos = () => useContext(ContextPlanos);
