import ButtonCancelar, { estadosBotaoCancelar } from 'components/Button/ButtonCancelar';
import ButtonSalvar, { estadosBotaoSalvar } from 'components/Button/ButtonSalvar';
import { createEfetuarPagamento } from 'components/Pagamentos/Requests';
import { converterPagamentoToApi } from 'components/Pagamentos/Util/converter';
import { useFormikContext } from 'formik';
import { validarFormulario } from 'views/Util';

function ActionButtons({ informacoesPermissoes, onHide, urls }) {
	const { values, dirty, isValid, handleSubmit, handleReset, validateForm } = useFormikContext();

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			onHide();
		}
	}

	async function handleClickSalvar() {
		handleSubmit();

		if (await validarFormulario({ validateForm, values })) {
			if (values && isValid) {
				await createEfetuarPagamento(urls.urlBase, values.idOrigem, converterPagamentoToApi(values), () => {
					onHide(values);
				});
			}
		}
	}

	return (
		<div
			style={{
				display: 'flex',
				justifyItems: 'flex-start',
				alignItems: 'flex-start',
				flexWrap: 'wrap',
			}}
		>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={handleClickCancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar estadoBotao={estadosBotaoSalvar.SALVAR} onClick={handleClickSalvar} {...informacoesPermissoes} />
		</div>
	);
}

export { ActionButtons };
