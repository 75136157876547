import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types';

function SingleSelectUsuario(props) {
	function buscarUrlPesquisa(pesquisa, page) {
		let rsqlQuery = `nome=contains="*${pesquisa}*"`;

		if (props.rsql) {
			rsqlQuery += `;${props.rsql}`;
		}

		return `${props.url}?query=${rsqlQuery}&page=${page}&size=25&sort=nome`;
	}

	function montarLabel(row) {
		return row.nome;
	}

	return (
		<>
			<SingleSelect
				esconderBotao={true}
				montarLabel={montarLabel}
				buscarUrlPesquisa={buscarUrlPesquisa}
				onChange={props.onChange}
				{...props}
			/>
		</>
	);
}

SingleSelectUsuario.defaultProps = {
	label: 'Usuário',
};

SingleSelectUsuario.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
};

export default SingleSelectUsuario;
