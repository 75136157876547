import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { format, lastDayOfMonth } from 'date-fns';
import { asyncConsultaUltimoSped, asyncGetFilialConectada } from '../Requests';

import { Form, FormActions, FormContent, Grid, If, ModalLoadingTransmissao } from '../../../../components';
import { mensagensDeValidacao, permissoes, recursos, usuarioPossuiPermissao } from '../../../../Common';

import {
	MessageObrigatorio,
	OptionsBlocoCMovimentacoes,
	OptionsBlocos,
	OptionsClassificacaoContribuinteIpi,
	OptionsIndicacaoAtividade,
	OptionsMotivoInventario,
	OptionsMovimentos,
	OptionsPerfil,
	OptionsTipoCusto,
} from '../Util/constantes';
import { converterSpedParaFormulario } from '../Util/spedConverter';

import { ModalEnviarPorEmail } from './components/ModalEnviarPorEmail';
import ActionButtons from './components/ActionButtons';
import BlocoH from './components/BlocoH';
import InformacoesIniciais from './components/InformacoesIniciais';
import BlocosEMovimentos from './components/BlocosEMovimentos';

const initialValue = {
	id: null,
	filial: null,
	dataInicial: format(new Date(), 'yyyy-MM-01'),
	dataFinal: format(lastDayOfMonth(new Date()), 'yyyy-MM-dd'),
	perfil: OptionsPerfil.find(e => e.value === 'PERFIL_A'),
	indicacaoAtividade: OptionsIndicacaoAtividade.find(e => e.value === 'OUTROS'),
	classificacaoContribuinteIpi: OptionsClassificacaoContribuinteIpi.find(e => e.value === 'INDUSTRIAL_TRANSFORMACAO'),
	blocoC: {
		movimentacoes: OptionsBlocoCMovimentacoes.find(e => e.value === 'C100_C190'),
	},
	blocoH: {
		data: format(lastDayOfMonth(new Date()), 'yyyy-MM-dd'),
		tipoCusto: OptionsTipoCusto.find(e => e.value === 'PRECO_CUSTO'),
		motivo: OptionsMotivoInventario.find(e => e.value === 'FINAL_PERIODO'),
	},
	blocos: OptionsBlocos,
	movimentos: OptionsMovimentos,
};

function SpedFormView(props) {
	const { values, setFieldValue, isMobile, isTablet, resetForm } = props;

	const [exibirLoadingGerarSped, setExibirLoadingGerarSped] = useState(false);
	const [exibirModalEnviarPorEmail, setExibirModalEnviarPorEmail] = useState(false);
	const [podeGerar, setPodeGerar] = useState(usuarioPossuiPermissao(recursos.CONTROLADORIA_SPED, permissoes.GERAR));

	const geraBlocoC = Boolean(values.blocos.find(e => e.value === 'BLOCO_C'));
	const geraBlocoH = Boolean(values.blocos.find(e => e.value === 'BLOCO_H'));

	useEffect(() => {
		consultaUltimoSped();
	}, []);

	async function consultaUltimoSped() {
		await asyncConsultaUltimoSped(({ data: sped }) => {
			if (sped) {
				resetForm({ values: converterSpedParaFormulario(sped) });
			} else {
				asyncGetFilialConectada(({ data: filial }) => {
					resetForm({ values: { ...values, filial: filial } });
				});
			}
		});
	}

	return (
		<Form header="SPED" className="card-default screen-max-width">
			<FormActions className="screen-max-width">
				<ActionButtons
					setExibirLoadingGerarSped={setExibirLoadingGerarSped}
					setExibirModalEnviarPorEmail={setExibirModalEnviarPorEmail}
					consultaUltimoSped={consultaUltimoSped}
					podeGerar={podeGerar}
					{...props}
				/>
			</FormActions>
			<FormContent>
				<Grid>
					<InformacoesIniciais
						values={values}
						podeGerar={podeGerar}
						setFieldValue={setFieldValue}
						isTablet={isTablet}
					/>
					<BlocosEMovimentos
						values={values}
						geraBlocoC={geraBlocoC}
						podeGerar={podeGerar}
						setFieldValue={setFieldValue}
						isMobile={isMobile}
						isTablet={isTablet}
						errors={props.errors}
					/>
					<BlocoH
						values={values}
						geraBlocoH={geraBlocoH}
						podeGerar={podeGerar}
						setFieldValue={setFieldValue}
						errors={props.errors}
					/>
				</Grid>
			</FormContent>
			<If test={exibirLoadingGerarSped}>
				<ModalLoadingTransmissao visible={exibirLoadingGerarSped} message="Gerando SPED..." onHide={() => {}} />
			</If>
			<If test={exibirModalEnviarPorEmail}>
				<ModalEnviarPorEmail
					visible={exibirModalEnviarPorEmail}
					sped={values}
					onHide={() => setExibirModalEnviarPorEmail(false)}
				/>
			</If>
		</Form>
	);
}

const SpedForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValue;
	},

	validate(values, props) {
		const { isTablet } = props;
		let errors = {};

		if (Boolean(values.blocos?.find(e => e.value === 'BLOCO_C')) && !values.blocoC.movimentacoes?.value) {
			errors.movimentacoes = mensagensDeValidacao.OBRIGATORIO;
		}

		if (Boolean(values.blocos?.find(e => e.value === 'BLOCO_H'))) {
			if (!values.blocoH.data) {
				errors.dataBase = isTablet ? MessageObrigatorio : mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values.blocoH.tipoCusto?.value) {
				errors.tipoCusto = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values.blocoH.motivo?.value) {
				errors.motivo = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		dataInicial: Yup.date().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		dataFinal: Yup.date().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		perfil: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		indicacaoAtividade: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		classificacaoContribuinteIpi: Yup.object()
			.nullable()
			.when('indicacaoAtividade', {
				is: indicacaoAtividade => indicacaoAtividade?.value === 'IND_OU_EQUIPARADO_A_IND',
				then: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
			}),
		blocos: Yup.array()
			.nullable()
			.min(1, mensagensDeValidacao.OBRIGATORIO)
			.required(mensagensDeValidacao.OBRIGATORIO),
		movimentos: Yup.array()
			.nullable()
			.min(1, mensagensDeValidacao.OBRIGATORIO)
			.required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(SpedFormView);

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default withRouter(connect(mapStateToProps)(SpedForm));
