import { salvarItemLocalStorage, buscarItemLocalStorage } from '../../../../../../../Common/LocalStorage';
import { DADOSLOCALSTORAGE } from '../../../../../../../Common/Constantes/localStorage';

export function salvarUltimoEmail(values) {
	const { titulo, texto } = values;
	salvarItemLocalStorage(DADOSLOCALSTORAGE.ultimoEmailCompartilharDocumento, { titulo, texto });
}

export function salvarNotificacaoPorEmail(value) {
	salvarItemLocalStorage(DADOSLOCALSTORAGE.notificarPorEmailDocumentoAssinadoRejeitado, value);
}

export function buscarNotificacaoPorEmail() {
	return buscarItemLocalStorage(DADOSLOCALSTORAGE.notificarPorEmailDocumentoAssinadoRejeitado);
}

export function buscarUltimoEmail() {
	return buscarItemLocalStorage(DADOSLOCALSTORAGE.ultimoEmailCompartilharDocumento);
}
