import { useCallback, useEffect, useRef, useState } from 'react';
import './Styles/index.css';
import { connect } from 'react-redux';
import usePrevious from '../../../Common/Hooks/usePrevious';

function AutoCursorWait(props) {
	const [exibirLoading, setExibirLoading] = useState(false);
	const prevProps = usePrevious(props);
	const afterFirstRenderRef = useRef(false);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.actionLoading !== props.actionLoading) {
				if (props.actionLoading && typeof props.actionLoading === 'string') {
					if (props.actionLoading.includes('OCULTAR_LOADING') && exibirLoading) {
						setExibirLoading(false);
						document.removeEventListener('keypress', bloquearKeyPress);
						document.removeEventListener('keydown', bloquearKeyPress);
						document.removeEventListener('keyup', bloquearKeyPress);
					} else if (props.actionLoading.includes('EXIBIR_LOADING') && !exibirLoading) {
						setExibirLoading(true);
						document.addEventListener('keypress', bloquearKeyPress);
						document.addEventListener('keydown', bloquearKeyPress);
						document.addEventListener('keyup', bloquearKeyPress);
					}
				}
			}
		} else {
			afterFirstRenderRef = true;
		}
	}, [props]);

	const bloquearKeyPress = useCallback(e => {
		e.preventDefault();
	}, []);

	if (exibirLoading) {
		return <span id="loading-content" />;
	} else {
		return null;
	}
}

const mapStateToProps = state => ({
	actionLoading: state.loading.actionLoading,
});

export default connect(mapStateToProps)(AutoCursorWait);
