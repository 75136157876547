import { useEffect, useState } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';

import {
  FormGroup,
  estadosBotaoSalvar,
  estadosBotaoCancelar,
  Grid,
  LayoutMenuSuperior,
  MenuSuperior,
  ButtonNovo,
  ButtonSalvar,
  ButtonCancelar,
  InputField,
  ButtonExcluir,
  Checkbox,
  Paper,
  FormActions,
} from 'components';

import { mensagensDeValidacao, estadosCadastro, gerarUUID } from 'Common';

import { converterPessoaEmailParaApi } from './Util/pessoaEmailConverter';
import { validarFormulario } from '../../../../../../../Util';
import { helpPessoaEmailForm } from './Help';

const initialValue = {
  id: '',
  email: '',
  observacao: '',
  favorito: false,
  idTemporario: null,
};

function PessoaEmailForm(props) {
  const { values, dirty } = props;

  const [inserindoNovoRegistro, setInserindoNovoRegistro] = useState(false);
  const informacoesPermissoes = {
    estadoCadastro: inserindoNovoRegistro ? estadosCadastro.INCLUSAO : estadosCadastro.EDICAO,
    podeInserir: props.podeEditar,
    podeEditar: props.podeEditar,
    podeExcluir: props.podeEditar,
  };

  useEffect(() => {
    setInserindoNovoRegistro(!props.registroSelecionado);

    setTimeout(() => {
      if (document.getElementById('pessoaInputEmail')) {
        document.getElementById('pessoaInputEmail').focus();
      }
    }, 500);
  }, []);

  function novo() {
    props.onNovoClick();
    props.resetForm({ values: initialValue });
    setInserindoNovoRegistro(true);
  }

  function excluir() {
    props.excluirRegistro(props.registroSelecionado);
    props.onHide();
  }

  async function salvar() {
    props.handleSubmit();
    if (await validarFormulario(props)) {
      if (!props.validarMesmoEmail(props.values)) {
        if (inserindoNovoRegistro) {
          criarRegistro();
        } else {
          atualizarRegistro();
        }
      } else {
        props.setFieldError('email', 'Este e-mail já existe');
      }
    }
  }

  function atualizarRegistro() {
    const dadosFormulario = converterPessoaEmailParaApi(props.values);
    props.atualizarRegistro(dadosFormulario);
    props.onHide();
  }

  function criarRegistro() {
    const dadosFormulario = converterPessoaEmailParaApi(props.values);
    props.inserirRegistro({ ...dadosFormulario, idTemporario: gerarUUID() });
    props.onHide();
  }

  function cancelar() {
    if (props.dirty) {
      props.handleReset();
    } else {
      props.onHide();
    }
  }

  return (
    <>
      <MenuSuperior isModal={true}>
        <FormActions>
          <ButtonCancelar
            {...informacoesPermissoes}
            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
            onClick={cancelar}
          />
          <ButtonSalvar
            estadoBotao={estadosBotaoSalvar.CONFIRMAR}
            {...informacoesPermissoes}
            disabled={!dirty}
            onClick={salvar}
          />
          <ButtonNovo onClick={novo} hidden={inserindoNovoRegistro} {...informacoesPermissoes} />
          <ButtonExcluir
            hidden={inserindoNovoRegistro}
            {...informacoesPermissoes}
            onClick={excluir}
            podeExcluir={props.podeExcluir}
          />
        </FormActions>
      </MenuSuperior>
      <LayoutMenuSuperior isModal={true}>
        <Paper dirty={dirty} {...informacoesPermissoes} childsOnly={true}>
          <FormGroup>
            <Grid>
              <Field
                sm="12"
                md="12"
                lg="12"
                xl="12"
                component={Checkbox}
                label="E-mail favorito"
                title="Marque esta opção caso o e-mail seja o favorito"
                name="favorito"
                helpMessage={helpPessoaEmailForm.favorito}
                onChange={() => props.setFieldValue('favorito', !props.values.favorito)}
                checked={values.favorito}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="12"
                lg="12"
                xl="12"
                id="pessoaInputEmail"
                component={InputField}
                type="email"
                label="E-mail"
                obrigatorio
                helpMessage={helpPessoaEmailForm.email}
                name="email"
                size={255}
                {...informacoesPermissoes}
              />
              <Field
                sm="12"
                md="12"
                lg="12"
                xl="12"
                component={InputField}
                type="text"
                label="Observação"
                name="observacao"
                {...informacoesPermissoes}
              />
            </Grid>
          </FormGroup>
        </Paper>
      </LayoutMenuSuperior>
    </>
  );
}

PessoaEmailForm = withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,

  mapPropsToValues(props) {
    if (props.registroSelecionado) {
      return { ...props.registroSelecionado };
    }
    return { ...initialValue, favorito: props.marcarComoFavorito() };
  },

  validationSchema: Yup.object().shape({
    email: Yup.string().email('Informe um e-mail válido').nullable().required(mensagensDeValidacao.OBRIGATORIO),
  }),

  handleSubmit: () => {},
})(PessoaEmailForm);

export default PessoaEmailForm;
