import { useEffect, useRef, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import { Card } from 'primereact/card';
import { asyncGetBalancoDiario } from './Requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../Common/Mascara';
import { format, parse } from 'date-fns';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { Financas } from '../../../../../../Util/constantes';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';

Chart.register(...registerables);

const cardHeader = {
	padding: '1rem 0px 0px 0px',
	display: 'flex',
	justifyContent: 'space-between',
};

let graficoBalancoDiario;

export default function GraficoBalancoDiario(props) {
	const { dataInicial, dataFinal, atualizarGraficoNovamente, isMobile } = props;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [data, setData] = useState([]);
	const [valorReceita, setValorReceita] = useState([]);
	const [valorDespesa, setValorDespesa] = useState([]);
	const [valorTotal, setValorTotal] = useState([]);
	const graficoDeDespesasAgrupadasPorDia = useRef();
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	const totalReceitas = valorReceita.reduce((a, b) => a + b, 0);
	const totalDespesas = valorDespesa.reduce((a, b) => a + b, 0);

	useEffect(() => {
		carregarInformacoes();

		return () => {
			if (typeof graficoBalancoDiario !== 'undefined') graficoBalancoDiario.destroy();
		};
	}, [atualizarGraficoNovamente]);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}

			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;
	}, [dataInicial, dataFinal]);

	useEffect(() => {
		asyncGetBalancoDiario(
			dataInicial,
			dataFinal,
			({ data: balancoDiario }) => {
				let dataResponse = [];
				let valorReceitaResponse = [];
				let valorDespesaResponse = [];
				let valorTotalResponse = [];

				balancoDiario.forEach(elemento => {
					dataResponse.push(elemento.data);
					valorReceitaResponse.push(elemento.valorReceita);
					valorDespesaResponse.push(elemento.valorDespesa);
					valorTotalResponse.push(elemento.valorReceita - elemento.valorDespesa);
				});

				setData(dataResponse);
				setValorReceita(valorReceitaResponse);
				setValorDespesa(valorDespesaResponse);
				setValorTotal(valorTotalResponse);
				setLoading(false);
				setError(false);
			},
			() => {
				setLoading(false);
				setError(true);
			}
		);
	}, [loading, atualizarGraficoNovamente]);

	useEffect(() => {
		buildChart();
	}, [valorTotal]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	function buildChart() {
		if (!graficoDeDespesasAgrupadasPorDia.current) {
			return;
		}
		const myChartRef = graficoDeDespesasAgrupadasPorDia.current.getContext('2d');

		if (typeof graficoBalancoDiario !== 'undefined') graficoBalancoDiario.destroy();

		graficoBalancoDiario = new Chart(myChartRef, {
			type: 'line',
			data: {
				labels: data,
				datasets: [
					{
						label: 'Total de receitas no dia',
						data: valorReceita,
						borderColor: Financas.cores.verde,
						backgroundColor: Financas.cores.preto,
					},
					{
						label: 'Total de despesas no dia',
						data: valorDespesa,
						borderColor: Financas.cores.vermelho,
						backgroundColor: Financas.cores.preto,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				aspectRatio: 1,
				animation: {
					animateScale: true,
					animateRotate: true,
				},
				elements: {
					line: {
						tension: 0.5,
					},
				},
				plugins: {
					datalabels: {
						color: Financas.cores.preto,
					},
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							usePointStyle: true,
						},
						onHover: function (e) {
							e.native.target.style.cursor = 'pointer';
						},
						onLeave: event => {
							event.native.target.style.cursor = 'default';
						},
					},
					tooltip: {
						callbacks: {
							title: tooltipItem => {
								return `Data: ${format(
									parse(tooltipItem[0].label, 'yyyy-MM-dd', new Date()),
									'dd/MM/yyyy'
								)}`;
							},
							afterTitle: tooltipItem => {
								return `Valor: ${formatarMonetario(tooltipItem.raw)}`;
							},
							label: tooltipItem => {
								const label = tooltipItem.dataset.label;
								const valor = formatarMonetario(tooltipItem.raw);
								return `${label}: ${valor}`;
							},
						},
					},
				},
				scales: {
					x: {
						ticks: {
							callback: function (value) {
								return format(
									parse(this.getLabelForValue(value), 'yyyy-MM-dd', new Date()),
									'dd/MM/yyyy'
								);
							},
						},
					},
					y: {
						beginAtZero: true,
						ticks: {
							callback: value => formatarMonetario(value),
						},
					},
				},
			},
		});
	}

	function getHeaderCard() {
		return (
			<div style={cardHeader}>
				<label
					title="Lista as receitas e despesas de cada dia. Passando o mouse por cima dos pontos do gráfico é possível visualizar o saldo diário"
					style={{ fontSize: '17px', color: '#333333' }}
				>
					Receitas e despesas efetivadas ao longo do tempo
				</label>
			</div>
		);
	}

	if (loading) {
		return (
			<Card
				header={getHeaderCard()}
				style={{ margin: isMobile ? '8px' : '0px', borderRadius: '5px', padding: '0 1rem 1rem 1rem' }}
			>
				<ProgressBar mode="indeterminate" style={{ height: '3px' }} />
			</Card>
		);
	} else if (error) {
		return (
			<Card
				header={getHeaderCard()}
				style={{
					margin: isMobile ? '8px' : '0px',
					borderRadius: '5px',
					height: '100%',
					padding: '0 1rem 1rem 1rem',
				}}
			>
				<FalhaAoCarregarAsInformacoes />
			</Card>
		);
	} else if (totalReceitas === 0 && totalDespesas === 0) {
		return (
			<Card
				header={getHeaderCard()}
				style={{ margin: isMobile ? '8px' : '0px', borderRadius: '5px', padding: '0 1rem 1rem 1rem' }}
			>
				<NenhumaInformacaoEncontrada />
			</Card>
		);
	} else {
		return (
			<Card
				className="card-canvas-dashboard-financas"
				header={getHeaderCard()}
				style={{ margin: isMobile ? '8px' : '0px', borderRadius: '5px', padding: '0 1rem 1rem 1rem' }}
			>
				<canvas id="graficoDeDespesasAgrupadasPorDia" ref={graficoDeDespesasAgrupadasPorDia} />
			</Card>
		);
	}
}
