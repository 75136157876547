import { gerarUUID } from 'Common';
import { formatISO } from 'date-fns';

export const INITIAL_VALUE_PARCELA = {
	id: null,
	tempKey: gerarUUID(),
	sequencial: null,
	vencimento: new Date(),
	formaPagamento: null,
	conta: null,
	descricao: null,
	valor: 0,
	temBoleto: false,
	temPix: false,
	financeiroIdentificador: null,
	financeiroId: null,
	financeiroStatus: null,
	valorRestante: 0,
	pessoaNome: null,
	pessoaCodigo: null,
	pessoaEmail: null,
};

export const INITIAL_VALUE_PAGAMENTO = {
	id: null,
	tempKey: gerarUUID(),
	idOrigem: null,
	sequencial: null,
	condicaoPagamento: null,
	conta: null,
	formaPagamento: null,
	categoria: null,
	quantidadeParcelas: 0,
	valor: 0,
	valorPago: 0,
	alterouValor: false,
	parcelas: [],
};

export const TABS_PARCELAS = {
	PARCELAS: 0,
	FINANCEIRO: 1,
};

export const FINANCEIRO_TIPO = {
	CONTA_RECEBER: 'CONTA_RECEBER',
	CONTA_PAGAR: 'CONTA_PAGAR',
};

export const INITIAL_VALUES_FINANCEIRO = {
	id: null,
	idOrigem: null,
	data: formatISO(new Date()),
	valor: null,
	multa: 0,
	juros: 0,
	desconto: 0,
	formaPagamento: null,
	conta: null,
	observacao: null,
	financeiroId: null,
};

export const FINANCEIRO_STATUS_CONTA_RECEBER = {
	NAO_RECEBIDA: 'NAO_RECEBIDA',
	PARCIALMENTE_RECEBIDA: 'PARCIALMENTE_RECEBIDA',
	RECEBIDA: 'RECEBIDA',
};

export const FINANCEIRO_STATUS_CONTA_PAGAR = {
	NAO_PAGA: 'NAO_PAGA',
	PARCIALMENTE_PAGA: 'PARCIALMENTE_PAGA',
	PAGA: 'PAGA',
};

export const TIPO_COBRANCA = {
	BOLETO: 'BOLETO',
	PIX: 'PIXF',
};

export const TIPO_COBRANCA_OPTIONS = [
	{ value: TIPO_COBRANCA.BOLETO, label: 'Boleto' },
	{ value: TIPO_COBRANCA.PIX, label: 'Pix' },
];
