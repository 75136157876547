import { tipoCampos } from 'components';

export const optionsFiltroAvancado = [
  { label: 'Código', name: 'codigoServico.codigo', type: tipoCampos.STRING },
  { label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
  {
    label: 'Situação',
    name: 'situacao',
    type: tipoCampos.SELECT,
    optionSelect: [
      { label: 'Ativo', value: 'ATIVO' },
      { label: 'Inativo', value: 'INATIVO' },
    ],
  },
];
