import alertify from 'alertifyjs';

export const ToastTypes = {
	SUCCESS: 'success',
	WARNING: 'warning',
	ERROR: 'error',
};

alertify.defaults.notifier.position = 'top-right';
alertify.defaults.notifier.closeButton = true;
alertify.defaults.glossary.title = 'AlertifyJS';
alertify.defaults.glossary.ok = 'OK';
alertify.defaults.glossary.cancel = 'Voltar';
alertify.defaults.glossary.acccpt = 'Aceitar';
alertify.defaults.glossary.deny = 'Negar';
alertify.defaults.glossary.confirm = 'Confirmar';
alertify.defaults.glossary.decline = 'Recusar';
alertify.defaults.glossary.close = 'Fechar';
alertify.defaults.glossary.maximize = 'Maximizar';
alertify.defaults.glossary.restore = 'Restaurar';

export function notify(message, type = ToastTypes.SUCCESS, wait = 5, callback, autoClose = true) {
	alertify.defaults.notifier.delay = autoClose ? wait : 0;
	alertify.defaults.notifier.closeButton = !autoClose;

	if (message) {
		return alertify.notify(message, type, wait, callback);
	}
}

export function confirm(
	title,
	message,
	onOk = () => {},
	onCancel = () => {},
	okText = 'OK',
	cancelText = 'Voltar',
	defaultFocus = 'cancel'
) {
	alertify
		.confirm(title, message, onOk, onCancel)
		.set('defaultFocus', defaultFocus)
		.set('labels', { ok: okText, cancel: cancelText });
}

export function prompt(
	title,
	message,
	value,
	onok = () => {},
	oncancel = () => {},
	okText = 'OK',
	cancelText = 'Voltar',
	defaultFocus = 'cancel'
) {
	return alertify
		.prompt(title, message, value, onok, oncancel)
		.set('type', 'text')
		.set('closable', true)
		.set('overflow', true)
		.set('defaultFocus', defaultFocus)
		.set('labels', { ok: okText, cancel: cancelText });
}

export function alert(title, message, onOk = () => {}) {
	alertify.alert(title, message, onOk);
}
