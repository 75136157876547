import { buscarDadosLoginLocalStorage, mensagensDeValidacao } from 'Common';
import { fazerLogin } from 'Common/Autenticacao';
import {
	AutoProgressBar,
	ButtonCancelar,
	ButtonSalvar,
	Col,
	FormGroup,
	Grid,
	If,
	InputField,
	InputMask,
	estadosBotaoCancelar,
} from 'components';
import { Field, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import Cards from 'react-credit-cards-2';
import { validarFormulario } from 'views/Util';
import { useContextPlanos } from 'views/configuracao/Planos/Context';
import { dispararAcoesReduxAtualizacaoPlano } from 'views/configuracao/Planos/Util/redux';
import * as Yup from 'yup';
import { helpCartaoForm } from '../Help';
import imgBandeira from '../Images/img_bandeiras.png';
import { asyncUpdateCartao } from '../Requests';
import { converterCartaoParaApi } from '../Util/cartaoConverter';
import { INITIAL_VALUES } from '../Util/constantes';

function FormDadosCartaoImpl({
	onHide,
	dirty,
	informacoesPermissoes,
	values,
	setFieldValue,
	resetForm,
	handleSubmit,
	initialValues,
	isModalConfirmacaoAdesao = false,
	setMetodoPagamento,
	trigger,
	isMobile,
	isTablet,
	isLessHd,
	...props
}) {
	const { setIsFormDadosCartao } = useContextPlanos();
	const [focoCartao, setFocoCartao] = useState('');

	useEffect(() => {
		salvar();
	}, [trigger]);

	useEffect(() => {
		setIsFormDadosCartao(true);
		return () => {
			setIsFormDadosCartao(false);
		};
	}, []);

	function buscarMascaraCartao() {
		switch (values.bandeira) {
			case 'amex':
				return '0000 000000 00000';
			case 'dinersclub':
				return '0000 000000 0000';
			default:
				return '0000 0000 0000 0000';
		}
	}

	function handleNomeCartao(e) {
		const value = e ? e.replace(/[^a-zA-Z ]/g, '').toUpperCase() : '';
		setFieldValue('nomeImpresso', value);
	}

	function onCallbackCard(type, isValid) {
		setFieldValue('bandeira', type.issuer);
		setFieldValue('cartaoValido', isValid);
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			onHide();
		}
	}

	async function salvar() {
		handleSubmit();
		if (dirty && (await validarFormulario(props))) {
			const dadosFormulario = converterCartaoParaApi(values);
			asyncUpdateCartao(dadosFormulario, !isModalConfirmacaoAdesao, () => {
				if (!isModalConfirmacaoAdesao) {
					onHide();
					resetForm({ values: values });
				}
				const dadosLocalStorage = buscarDadosLoginLocalStorage();
				if (dadosLocalStorage.organizacao.bloqueada) {
					fazerLogin(dadosLocalStorage.email, dadosLocalStorage.senha, dadosLocalStorage.manterConectado, () => {
						dispararAcoesReduxAtualizacaoPlano();
					});
				} else {
					dispararAcoesReduxAtualizacaoPlano();
				}
				if (isModalConfirmacaoAdesao) {
					setMetodoPagamento('CARTAO');
				}
			});
		}
	}

	return (
		<>
			<If test={!isModalConfirmacaoAdesao}>
				<Grid style={{ margin: '8px 0 0 0' }}>
					<AutoProgressBar />
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={cancelar}
					/>
					<ButtonSalvar {...informacoesPermissoes} disabled={!dirty} title="Salvar o cartão" onClick={salvar} />
				</Grid>
			</If>
			<Grid style={{ margin: '8px 0 0 0' }}>
				<FormGroup>
					<Grid>
						<Col sm="12" md="6" lg="6" xl="6">
							<Grid>
								<Field
									sm="12"
									md="12"
									lg="12"
									xl="12"
									component={InputMask}
									placeholder="0000 0000 0000 0000"
									mask={buscarMascaraCartao()}
									label="Número do cartão "
									obrigatorio
									name="num"
									onChange={(e) => setFieldValue('num', e.target.value)}
									helpMessage={helpCartaoForm.num}
									onFocus={() => setFocoCartao('number')}
									{...informacoesPermissoes}
								/>
								<Field
									sm="12"
									md="12"
									lg="12"
									xl="12"
									component={InputField}
									label="Nome do titular impresso no cartão "
									obrigatorio
									helpMessage={helpCartaoForm.nomeImpresso}
									name="nomeImpresso"
									onChange={(e) => handleNomeCartao(e.target.value)}
									size={25}
									onFocus={() => setFocoCartao('name')}
									{...informacoesPermissoes}
								/>
								<Field
									sm="12"
									md="6"
									lg="6"
									xl="6"
									component={InputMask}
									placeholder="MM/AAAA"
									mask="00/0000"
									label="Data de expiração "
									obrigatorio
									name="expiracao"
									removerMascara={false}
									helpMessage={helpCartaoForm.expiracao}
									onChange={(e) => setFieldValue('expiracao', e.target.value)}
									onFocus={() => setFocoCartao('expiry')}
									{...informacoesPermissoes}
								/>
								<Field
									sm="12"
									md="6"
									lg="6"
									xl="6"
									component={InputField}
									keyfilter="pnum"
									placeholder="CVV"
									size={3}
									helpMessage={helpCartaoForm.codigoSeguranca}
									label={isTablet || isLessHd ? 'Cód. de segurança' : 'Código de segurança'}
									obrigatorio
									name="codigoSeguranca"
									onFocus={() => setFocoCartao('cvc')}
									{...informacoesPermissoes}
								/>
							</Grid>
						</Col>
						<Col sm="12" md="6" lg="6" xl="6">
							<Cards
								style={{ marginTop: '20px', width: '200px' }}
								number={values.num || ''}
								name={values.nomeImpresso || ''}
								expiry={values.expiracao || ''}
								cvc={values.codigoSeguranca || ''}
								placeholders={{
									name: 'NOME DO TITULAR',
								}}
								locale={{
									valid: 'Expiração',
								}}
								focused={focoCartao}
								callback={onCallbackCard}
								acceptedCards={['mastercard', 'visa', 'amex', 'elo', 'jcb', 'dinersclub', 'discover', 'hipercard']}
							/>
							<center>
								<p style={{ margin: '0', marginTop: '10px', width: '200px' }}>Bandeiras aceitas:</p>
								<img style={{ width: '200px', maxWidth: '100%' }} src={imgBandeira} alt="Bandeiras aceitas" />
							</center>
						</Col>
					</Grid>
				</FormGroup>
			</Grid>
		</>
	);
}

const FormDadosCartao = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: true,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};

		if (!values.cartaoValido) {
			errors.num = 'Número do cartão de crédito inválido.';
		}

		if (values.bandeira === 'unknown') {
			errors.num = 'Número do cartão de crédito inválido.';
		}

		if (values.codigoSeguranca && values.codigoSeguranca.trim().length < 3) {
			errors.codigoSeguranca = 'Código de segurança inválido';
		}

		if (values.expiracao && values.expiracao.substring(0, 2) > 12) {
			errors.expiracao = 'Mês inválido.';
		}

		if (values.expiracao && values.expiracao.trim().length < 7) {
			errors.expiracao = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.expiracao && !errors.expiracao) {
			const expiracaoArray = values.expiracao.split('/');
			const dataLimite = new Date(expiracaoArray[1], expiracaoArray[0] - 1, 30);

			if (dataLimite < new Date()) {
				errors.expiracao = 'A data é inferior ao mês atual.';
			}
		}

		return errors;
	},
	validationSchema: Yup.object().shape({
		num: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		nomeImpresso: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		expiracao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		codigoSeguranca: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(FormDadosCartaoImpl);

export { FormDadosCartao };
