import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Slider } from 'primereact/slider';

import { Card, If } from 'components';

import { converterMilimetrosEmPixel } from '../../../../Util/functions';
import { TIPO_PAPEL_ETIQUETA } from '../../../../Util/constantes';

function CardPreview() {
	const { values } = useFormikContext();
	const isBobina = values.tipoPapel === TIPO_PAPEL_ETIQUETA.BOBINA;
	const [escalaVisualizacao, setEscalaVisualizacao] = useState(0.3);

	useEffect(() => {
		setEscalaVisualizacao(isBobina ? 0.5 : 0.3);
	}, [values.tipoPapel]);

	const medidasPagina = {
		altura: converterMilimetrosEmPixel(
			isBobina ? values.alturaEtiqueta + values.margemSuperior : values.alturaPagina,
			escalaVisualizacao
		),
		largura: converterMilimetrosEmPixel(values.larguraPagina, escalaVisualizacao),

		linhas: values.linhasPorPagina,
		numeroEtiqueta: values.etiquetasPorLinha,

		margemSuperior: converterMilimetrosEmPixel(values.margemSuperior, escalaVisualizacao),
		margemInferior: converterMilimetrosEmPixel(values.margemInferior, escalaVisualizacao),
		margemDireita: converterMilimetrosEmPixel(values.margemDireita, escalaVisualizacao),
		margemEsquerda: converterMilimetrosEmPixel(values.margemEsquerda, escalaVisualizacao),

		espacamentoLinha: converterMilimetrosEmPixel(values.espacamentoLinha, escalaVisualizacao),
		espacamentoColuna: converterMilimetrosEmPixel(values.espacamentoColuna, escalaVisualizacao),
	};

	const medidasEtiqueta = {
		altura: converterMilimetrosEmPixel(values.alturaEtiqueta, escalaVisualizacao),
		largura: converterMilimetrosEmPixel(values.larguraEtiqueta, escalaVisualizacao),
	};

	const previewPagina = montarPreview();

	function montarPreview() {
		const linhasDoPreview = [];

		for (let i = 1; i <= medidasPagina.linhas; i++) {
			linhasDoPreview.push(
				<div
					key={i}
					style={{
						display: 'grid',
						gridTemplateColumns: `repeat(${medidasPagina.numeroEtiqueta}, ${medidasEtiqueta.largura}px)`,
						gap: `0px ${medidasPagina.espacamentoColuna}px`,
					}}
				>
					{buscarNumeroDeEtiquetas(i)}
				</div>
			);
		}

		return <div style={{ display: 'grid', gap: `${medidasPagina.espacamentoLinha}px 0px` }}>{linhasDoPreview}</div>;
	}

	function buscarNumeroDeEtiquetas(numeroLinha) {
		const etiquetas = [];

		for (let i = 1; i <= medidasPagina.numeroEtiqueta; i++) {
			etiquetas.push(
				<div
					key={`${numeroLinha}_${i}`}
					style={{
						background: '#f9f9f9',
						width: `${medidasEtiqueta.largura}px`,
						height: `${medidasEtiqueta.altura}px`,
						border: '1px solid #f0f0f0',
					}}
				/>
			);
		}

		return etiquetas;
	}

	function montarTitlePreview() {
		return (
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<span>Preview</span>

				<span title={`10%-100% (atual:${escalaVisualizacao * 100}%)`}>
					<Slider
						min={0.1}
						max={1}
						step={0.1}
						value={escalaVisualizacao}
						onChange={(e) => setEscalaVisualizacao(e.value)}
						style={{ width: '120px' }}
					/>
				</span>
			</div>
		);
	}

	return (
		<Card
			className="card-etiqueta card-preview step-etiqueta-configuracao-card-preview-pagina"
			title={montarTitlePreview}
			style={{ height: '100%', width: '100%', paddingTop: '1rem' }}
		>
			<div
				style={{
					placeSelf: 'center',
					margin: '2rem 0rem',
				}}
			>
				<If test={medidasPagina.altura && medidasPagina.largura}>
					<div
						style={{
							background: '#D9D9D9',
							height: `${medidasPagina.altura}px`,
							width: `${medidasPagina.largura}px`,

							paddingTop: `${medidasPagina.margemSuperior}px`,
							paddingBottom: `${medidasPagina.margemInferior}px`,
							paddingRight: `${medidasPagina.margemDireita}px`,
							paddingLeft: `${medidasPagina.margemEsquerda}px`,
						}}
					>
						{previewPagina}
					</div>
				</If>
			</div>
		</Card>
	);
}

export { CardPreview };
