import { manterApenasNumeros } from 'Common';
import { asyncGetCodigoServicoByCodigoExtensao } from '../Requests';

export async function jaExisteMesmoCodigo(values) {
	let existeMesmoCodigo = false;

	await asyncGetCodigoServicoByCodigoExtensao(
		manterApenasNumeros(values.codigoServico?.label),
		({ data: codigoServico }) => {
			if (codigoServico.content.length > 0 && codigoServico.content[0].id !== values.id) {
				existeMesmoCodigo = true;
			}
		}
	);

	return existeMesmoCodigo;
}
