import { useState, useEffect, useRef } from 'react';
import { buscarResultadoNoPeriodo } from './Requests';
import Col from '../../../../../../../components/Col';
import CardResultado from './components/CardResultado';
import { MdAttachMoney, MdMoneyOff } from 'react-icons/md';
import { Compras } from '../../../../../../Util/constantes';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';

export default function CardsResultadoNoPeriodo(props) {
	const { dataInicial, dataFinal, atualizarGraficoNovamente, isMobile, dataAtualizacaoMenuLateral } = props;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const [pendente, setPendente] = useState(0);
	const [finalizado, setFinalizado] = useState(0);
	const [cancelada, setCancelada] = useState(0);

	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	const styleCards = isMobile ? { padding: '0.5em 0em', margin: '8px', flex: 'auto' } : { padding: '0.5em' };

	useEffect(() => {
		carregarInformacoes();
	}, [atualizarGraficoNovamente]);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}

			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;
	}, [dataInicial, dataFinal]);

	useEffect(() => {
		buscarResultadoNoPeriodo(
			dataInicial,
			dataFinal,
			({ data }) => {
				const pendenteAtualResponse = data.valorPendentes;
				setPendente(pendenteAtualResponse);

				const finalizadoAtualResponse = data.valorFinalizadas;
				setFinalizado(finalizadoAtualResponse);

				const canceladaAtualResponse = data.valorCanceladas;
				setCancelada(canceladaAtualResponse);

				setLoading(false);
				setError(false);
			},
			() => {
				setLoading(false);
				setError(true);
			}
		);
	}, [loading, dataAtualizacaoMenuLateral]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	return (
		<>
			<Col xs="12" sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardResultado
					title="Pendentes no período"
					helpMessage="Valor total de pendentes no período selecionado"
					valor={pendente}
					loading={loading}
					error={error}
					colorValor={Compras.cores.azul}
					icon={<MdAttachMoney size={50} color={Compras.cores.azul} />}
				/>
			</Col>
			<Col xs="12" sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardResultado
					title="Finalizados no período"
					helpMessage="Valor total de finalizados no período selecionado"
					valor={finalizado}
					loading={loading}
					error={error}
					colorValor={Compras.cores.verde}
					icon={<MdAttachMoney size={50} color={Compras.cores.verde} />}
				/>
			</Col>
			<Col xs="12" sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardResultado
					title="Cancelados no período"
					helpMessage="Valor total de cancelados no período selecionado"
					valor={cancelada}
					loading={loading}
					error={error}
					colorValor={Compras.cores.vermelho}
					icon={<MdMoneyOff size={50} color={Compras.cores.vermelho} />}
				/>
			</Col>
		</>
	);
}
